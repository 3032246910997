import _ from 'lodash';
import moment from 'moment-timezone';
import { ASSET_DELIMITER, BuilderParameters, DEFAULT_WORKBOOK_NAME, ViewMode } from '@/builder/builder.module';
import { DurationActions } from '@/trendData/duration.actions';
import { TrendActions } from '@/trendData/trend.actions';
import { SearchActions } from '@/search/search.actions';
import { WorkbookActions } from '@/workbook/workbook.actions';
import { WorkstepsActions } from '@/worksteps/worksteps.actions';
import { PUSH_WORKSTEP_IMMEDIATE, StateSynchronizerService } from '@/services/stateSynchronizer.service';
import { UtilitiesService } from '@/services/utilities.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { WorksheetsService } from '@/worksheets/worksheets.service';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.module';
import { API_TYPES, SEARCH_TYPES } from '@/main/app.constants';
import {
  DEFAULT_DISPLAY_RANGE_DURATION_DAYS,
  DEFAULT_INVESTIGATE_RANGE_DURATION_DAYS
} from '@/trendData/trendData.module';
import { SEARCH_PANES, SEARCH_PER_PAGE } from '@/search/search.module';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { WORKSHEET_SIDEBAR_TAB } from '@/worksheet/worksheet.module';
import { FoldersApi, ItemsApi, SwapOptionListV1, TreesApi } from '@/sdk';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { IHttpResponse } from 'angular';

export class BuilderService {
  constructor(
    private $q: ng.IQService,
    private $translate: ng.translate.ITranslateService,
    private sqWorkbenchActions: WorkbenchActions,
    private sqHomeScreenActions: HomeScreenActions,
    private sqWorksheetActions: WorksheetActions,
    private sqSearchActions: SearchActions,
    private sqTrendActions: TrendActions,
    private sqDurationActions: DurationActions,
    private sqWorkstepsActions: WorkstepsActions,
    private sqStateSynchronizer: StateSynchronizerService,
    private sqUtilities: UtilitiesService,
    private sqDateTime: DateTimeService,
    private sqWorksheets: WorksheetsService,
    private sqWorkbookActions: WorkbookActions,
    private sqTrendDataHelper: TrendDataHelperService,
    private sqItemsApi: ItemsApi,
    private sqFoldersApi: FoldersApi,
    private sqTreesApi: TreesApi) {
  }

  /**
   * Builds a worksheet in a new or existing workbook based on the parameters.
   *
   * @param {BuilderParameters} params - The parameters that are used to build up the worksheet
   * @returns {Promise} Resolves with an object containing workbookId and worksheetId
   */
  run(params: BuilderParameters) {
    _.defaults(params, { startFresh: true, viewMode: ViewMode.Edit });
    return this.sqWorkbenchActions
      .setCurrentUser() // Must be first so currentUser is available to other actions
      .then(() => this.findOrAddWorkbook(params.workbookName, params.workbookFilter))
      .then(workbookId => this.findOrAddWorksheet(params.worksheetName, workbookId))
      .then((stateParams) => {
        this.sqWorkbookActions.setWorkbookDisplayMode(params.viewMode === ViewMode.Edit ? WORKBOOK_DISPLAY.EDIT :
          WORKBOOK_DISPLAY.VIEW);
        return this.populateWorkstepState(params, stateParams)
          .then(() => params.viewMode === ViewMode.Edit ?
            this.sqStateSynchronizer.push(PUSH_WORKSTEP_IMMEDIATE, stateParams) : undefined)
          .then(_.constant(stateParams));
      });
  }

  /**
   * Finds an existing workbook by name or id. Creates a new workbook if it does not exist.
   *
   * @param {string} [workbookName] - The workbook name or id
   * @param {string} [filter] - The filter for the workbench query
   * @returns {Promise<string>} A promise that resolves with the workbook id
   */
  private findOrAddWorkbook(workbookName, filter = 'mine') {
    if (this.sqUtilities.validateGuid(workbookName)) {
      return this.$q.resolve(workbookName);
    } else {
      return (_.isEmpty(workbookName) ? this.$translate(DEFAULT_WORKBOOK_NAME) : this.$q.resolve(workbookName))
        .then(name => this.sqFoldersApi.getFolders({
            textSearch: `"${name}"`,
            types: [API_TYPES.ANALYSIS, API_TYPES.TOPIC],
            filter,
            limit: 1000
          })
          .then(({ data: { content } }) => _.find(content, { name }))
          .then(workbook => workbook ? workbook.id :
            this.sqHomeScreenActions.addWorkbook({ name, addNewWorksheet: false })
              .then(({ workbookId }) => workbookId)));
    }
  }

  /**
   * Finds an existing worksheet by name or id. Creates a new worksheet if it does not exist.
   *
   * @param {String} worksheetName - The worksheet name or id
   * @param {String} workbookId - The workbook id
   * @returns {Promise} A promise that resolves with an object containing workbookId and worksheetId
   */
  private findOrAddWorksheet(worksheetName, workbookId) {
    if (this.sqUtilities.validateGuid(worksheetName)) {
      return this.$q.resolve({ workbookId, worksheetId: worksheetName });
    } else {
      return this.sqWorksheets.getWorksheets(workbookId)
        .then((worksheets) => {
          const worksheetId = _.get(_.find(worksheets, ['name', worksheetName]), 'worksheetId');

          if (worksheetId) {
            return this.$q.resolve({ workbookId, worksheetId });
          } else {
            return this.sqWorksheets.createWorksheet(workbookId, worksheetName)
              .then(({ worksheetId }) => ({ workbookId, worksheetId }));
          }
        });
    }
  }

  /**
   * Populates the workstep state based on the builder parameters, optionally starting with the current workstep of
   * the specified worksheet.
   *
   * @param {BuilderParameters} params - The builder parameters
   * @param {Object} stateParams - The workbook and worksheet ids
   */
  private populateWorkstepState(params: BuilderParameters, stateParams) {
    let workstepPromise;
    if (params.startFresh) {
      workstepPromise = this.$q.resolve();
    } else {
      this.sqStateSynchronizer.setLoadingWorksheet(stateParams.workbookId, stateParams.worksheetId);
      workstepPromise = this.sqWorkstepsActions.current(stateParams.workbookId, stateParams.worksheetId)
        .then(_.property('current.state'))
        .then(workstepState => this.sqStateSynchronizer.rehydrate(workstepState,
          { workbookId: stateParams.workbookId, worksheetId: stateParams.worksheetId }))
        .catch(_.noop) // New worksheet, can safely ignore
        .finally(() => {
          this.sqStateSynchronizer.unsetLoadingWorksheet();
        });
    }

    return workstepPromise.then(() => {
      this.setInvestigationRange(params.investigateStartTime, params.investigateEndTime);
      this.setDisplayRange(params.displayStartTime, params.displayEndTime);
      if (params.selectedTab && WORKSHEET_SIDEBAR_TAB[params.selectedTab]) {
        this.sqWorksheetActions.tabsetChangeTab('sidebar', WORKSHEET_SIDEBAR_TAB[params.selectedTab]);
      }
      const addItems = this.addTrendItems(params.trendItems, stateParams.workbookId)
        .then(() => this.swapAsset(params.assetSwap, stateParams.workbookId));
      return this.$q.all([this.exploreAsset(params.expandedAsset, stateParams.workbookId), addItems]);
    });
  }

  /**
   * Search for each trend item and add it to the trend on the specified worksheet.
   *
   * @param {string[]} trendItems - An array of item names that will be added to the trend. If the item
   *   exists as part of an asset tree then its location in the Asset tree can be specified using the following
   *   syntax: Full >> Asset >> Path >> Item Name
   * @param {string} workbookId - The id of the workbook; used to limit the scope
   * @returns {Promise} A promise that resolves when all items are added to the trend
   */
  private addTrendItems(trendItems, workbookId) {
    const nonAssetItems = _.chain(trendItems)
      .reject(name => _.includes(name, ASSET_DELIMITER))
      .reject(this.sqUtilities.validateGuid)
      .thru((trendItems) => {
        if (_.isEmpty(trendItems)) {
          return this.$q.resolve();
        }

        return this.sqItemsApi.searchItems({
            filters: trendItems,
            types: SEARCH_TYPES,
            limit: SEARCH_PER_PAGE,
            scope: [workbookId]
          })
          .then(({ data: { items } }) => _.chain(items)
            .filter(this.sqUtilities.isTrendable)
            .sortBy(item => _.toLower(item.name))
            .partition(item => _.includes(trendItems, item.name))
            .flatten()
            .map(item => this.sqTrendActions.addItem(item))
            .thru(this.$q.all)
            .value());
      })
      .value();

    const idItems = _.chain(trendItems)
      .filter(this.sqUtilities.validateGuid)
      .map(id => this.sqItemsApi.getItemAndAllProperties({ id })
        .then(({ data: item }) => this.sqTrendActions.addItem(item)))
      .value();

    const assetItems = _.chain(trendItems)
      .filter(_.partial(_.includes, _, ASSET_DELIMITER))
      .map((nameWithPath) => {
        const parts = _.chain(nameWithPath).split(ASSET_DELIMITER).map(_.trim).value() as any;
        const assetPath = _.initial(parts);
        const itemName = _.last(parts) as string;
        return this.getAssetFromPath(assetPath, workbookId)
          .then((asset) => {
            const assetId = _.get(asset, 'id');
            return assetId ?
              this.sqTreesApi.getTree({ id: assetId, limit: 10000, scopedTo: workbookId })
                .then(({ data: { children } }) => children) : [];
          })
          .then((children) => {
            const item = _.find(children, (child: any) => child.name.toLowerCase() === itemName.toLowerCase());
            if (item) {
              return this.sqTrendActions.addItem(item);
            }
          });
      })
      .value();

    return this.$q.all(assetItems.concat(nonAssetItems).concat(idItems));
  }

  /**
   * Finds the specified asset and sets it as the current asset in search.
   *
   * @param {string} expandedAsset - An asset name, id or full path to an asset that will be explored in the search
   * @param {string} workbookId - The id of the workbook; used to limit the scope
   * @returns {Promise} A promise that resolves when the asset is loaded
   */
  private exploreAsset(expandedAsset, workbookId) {
    return this.getAsset(expandedAsset, workbookId)
      .then(asset => asset ?
        this.sqSearchActions.exploreAsset(SEARCH_PANES.MAIN, asset.id) :
        this.sqSearchActions.initialize(SEARCH_PANES.MAIN, SEARCH_TYPES, false, [workbookId]));
  }

  /**
   * Finds an asset either by name, id or fully specified path
   *
   * @param {string} assetSpecifier - Name, id or full asset path to an asset
   * @param {string} workbookId - The id of the workbook; used to limit the scope
   * @returns {Promise} that resolves with the asset, or empty if not found
   */
  private getAsset(assetSpecifier: string, workbookId: string) {
    if (_.isEmpty(assetSpecifier)) {
      return this.$q.resolve();
    } else if (this.sqUtilities.validateGuid(assetSpecifier)) {
      return this.sqItemsApi.getItemAndAllProperties({ id: assetSpecifier }).then(({ data: item }) => item);
    } else if (_.includes(assetSpecifier, ASSET_DELIMITER)) {
      // Get the asset from the full path provided
      return this.getAssetFromPath(_.chain(assetSpecifier).split(ASSET_DELIMITER).map(_.trim).value(), workbookId);
    } else {
      // Search for the asset by name only
      return this.sqItemsApi.searchItems({
          filters: [assetSpecifier],
          limit: 1,
          types: [API_TYPES.ASSET],
          scope: [workbookId]
        })
        .then(({ data: { items } }) => _.head(items));
    }
  }

  /**
   * Returns an asset from its full asset path. Match is by name case-insensitive at each level.
   *
   * @param {string[]} assetLevels - Array of path tokens for an asset
   * @param {string} workbookId - The id of the workbook; used to limit the scope
   * @returns {Promise} that resolves with the asset or undefined if not found
   */
  private getAssetFromPath(assetLevels, workbookId) {
    return this.sqTreesApi.getTreeRootNodes({ limit: 10000, scopedTo: workbookId })
      .then(({ data: { children } }) => {
        const rootAsset = _.find(children, (item: any) => _.toLower(_.head(assetLevels)) === _.toLower(item.name));

        if (!rootAsset) {
          return this.$q.resolve();
        }

        return _.reduce(_.tail(assetLevels), (parentPromise, assetName) =>
          parentPromise.then(parent => this.getAssetChild(assetName, parent, workbookId)), this.$q.resolve(rootAsset));
      });
  }

  /**
   * Returns a child asset from its name and parent object. Match is case-insensitive.
   *
   * @param {string} assetName - Name of the asset to find
   * @param {Object} parent - Parent asset
   * @param {string} parent.id - ID of the parent asset
   * @param {string} workbookId - The id of the workbook; used to limit the scope
   * @returns {Promise} that resolves with the asset or undefined if not found
   */
  private getAssetChild(assetName, parent, workbookId) {
    if (_.isEmpty(assetName) || _.isEmpty(parent)) {
      return this.$q.resolve();
    }

    return this.sqTreesApi.getTree({ id: parent.id, limit: 10000, scopedTo: workbookId })
      .then(({ data: { children } }) =>
        _.find(children, (child: any) => child.name.toLowerCase() === assetName.toLowerCase()));
  }

  /**
   * Performs an asset swap to show all trend items relative to the provided asset. This assumes that all
   * asset-relative items on the trend are for the same asset. If added items from from more than one asset, then
   * only one of the existing assets will be swapped out; which one is non-deterministic.
   *
   * @param {string} assetSpecifier - Asset to use as the destination of the swap. Can be specified by name, id or
   *   by the full path to the asset, using >> as the path delimiter.
   * @param {string} workbookId - The id of the workbook; used to limit the scope
   * @returns {Promise} that resolves when the asset is found and resolved.
   */
  private swapAsset(assetSpecifier, workbookId) {
    const swapOutItemIds = _.chain(this.sqTrendDataHelper.getAllItems())
      .uniqBy('id')
      .map('id')
      .value();
    return this.getAsset(assetSpecifier, workbookId)
      .then(asset => asset ? this.sqItemsApi.getSwapOptions({ id: asset.id, swapOutItemIds }) :
        this.$q.resolve(<IHttpResponse<SwapOptionListV1>>{ data: {} }))
      .then(({ data: { swapOptions } }) => {
        if (!_.isEmpty(swapOptions)) {
          const swapPairs =
            _.mapValues(_.keyBy(_.first(swapOptions).itemsWithSwapPairs, 'item.id'), 'swapPairs');
          return this.sqTrendActions.swapAssets(swapPairs);
        }
      });
  }

  /**
   * Parses and sets the start and/or end time of a specified range. Start and end are both optional.
   * Each date is a string that can be in either ISO-8601 format or using now-relative notation, i.e. '*' or '*-7d'.
   *
   * @param {string} [startTime=endTime - defaultDuration] - Start date/time
   * @param {string} [endTime=startTime + defaultDuration] - End date/time
   * @param {number} defaultDurationDays - Duration to use if only one date/time is specified, in days
   * @param {Function} updateTimes - Function to call with resulting dates
   */
  private setRange(startTime, endTime, defaultDurationDays, updateTimes) {
    let startMoment = moment.invalid();
    let endMoment = moment.invalid();
    const now = moment.utc();

    if (startTime) {
      startMoment = this.sqDateTime.parseISODate(startTime);
      if (!startMoment.isValid()) {
        startMoment = this.sqDateTime.parseRelativeDate(startTime, now);
      }
    }

    if (endTime) {
      endMoment = this.sqDateTime.parseISODate(endTime);
      if (!endMoment.isValid()) {
        endMoment = this.sqDateTime.parseRelativeDate(endTime, now);
      }
    }

    if (startMoment.isValid() && !endMoment.isValid()) {
      endMoment = moment.utc(startMoment).add(defaultDurationDays, 'days');
    }

    if (!startMoment.isValid() && endMoment.isValid()) {
      startMoment = moment.utc(endMoment).subtract(defaultDurationDays, 'days');
    }

    if (startMoment.isValid() && endMoment.isValid()) {
      updateTimes(startMoment.valueOf(), endMoment.valueOf());
    }
  }

  /**
   * Sets the start and/or end time of the Display Range.
   *
   * @param {string} [startTime=endTime - DEFAULT_DISPLAY_RANGE_DURATION_DAYS] - Start date/time
   * @param {string} [endTime=startTime + DEFAULT_DISPLAY_RANGE_DURATION_DAYS] - End date/time
   */
  private setDisplayRange(startTime, endTime) {
    this.setRange(startTime, endTime, DEFAULT_DISPLAY_RANGE_DURATION_DAYS,
      this.sqDurationActions.displayRange.updateTimes);
  }

  /**
   * Sets the start or end time of the Investigation Range.
   * @param {string} [startTime=endTime - DEFAULT_DISPLAY_RANGE_DURATION_DAYS] - Start date/time
   * @param {string} [endTime=startTime + DEFAULT_DISPLAY_RANGE_DURATION_DAYS] - End date/time
   */
  private setInvestigationRange(startTime, endTime) {
    this.setRange(startTime, endTime, DEFAULT_INVESTIGATE_RANGE_DURATION_DAYS,
      this.sqDurationActions.investigateRange.updateTimes);
  }
}
