import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { FormulaToolActions } from '@/hybrid/tools/formula/formulaTool.actions';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { FormulaToolStore } from '@/hybrid/tools/formula/formulaTool.store';
import { SEARCH } from '@/hybrid/tools/formula/formulaTool.module';
import { FormControl } from 'react-bootstrap';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';

const formulaDocumentationHeaderBindings = bindingsDefinition({
  backEnabled: prop<boolean>(),
  functionFilter: prop<string>(),
  clearHelpText: prop<(searchText: string) => void>(),
  goBack: prop<() => void>(),
  goHome: prop<() => void>(),
  loadFullList: prop<() => void>(),
  formulaHelpToggleFunction: prop.optional<() => void>(),
  sqFormulaToolActions: injected<FormulaToolActions>(),
  sqFormulaToolStore: injected<FormulaToolStore>()
});

export const FormulaDocumentationHeader: SeeqComponent<typeof formulaDocumentationHeaderBindings> = ({
  backEnabled,
  functionFilter,
  clearHelpText,
  goBack,
  goHome,
  loadFullList,
  formulaHelpToggleFunction
}) => {
  const { sqFormulaToolStore } = useInjectedBindings(formulaDocumentationHeaderBindings);
  const { t } = useTranslation();

  const [canGoBack, setCanGoBack] = useState(backEnabled);
  const navigationStack = useFluxPath(sqFormulaToolStore, () => sqFormulaToolStore.navigationStack);

  useEffect(() => {
    setCanGoBack(backEnabled
      && !(navigationStack.length === 1 && _.first(navigationStack) === SEARCH)
      && navigationStack.length > 0);
  }, [backEnabled, navigationStack]);

  return (
    <div className="flexColumnContainer flexSpaceBetween flexNoGrowNoShrink flexCenter" data-testid="formulaDocsHeader">
      <HoverTooltip text="FORMULA.PREVIOUS_HELP">
          <span>
            <Icon
              icon="fa-arrow-left"
              type={canGoBack ? 'theme' : 'gray'}
              large={true}
              extraClassNames="pr10"
              onClick={canGoBack ? goBack : _.noop}
              testId="goBackIcon"
            />
          </span>
      </HoverTooltip>

      <HoverTooltip text="FORMULA.GO_HOME">
          <span>
            <Icon
              icon="fa-home"
              large={true}
              extraClassNames="pr10 cursorPointer sq-text-primary"
              onClick={goHome}
              testId="goHomeIcon"
            />
          </span>
      </HoverTooltip>
      <div>
        <HoverTooltip text="FORMULA.FULL_LIST">
          <span>
            <Icon
              icon="fa-list-ul"
              large={true}
              extraClassNames="pr10 cursorPointer sq-text-primary"
              onClick={loadFullList}
              testId="fullListIcon"
            />
          </span>
        </HoverTooltip>
      </div>

      <FormControl autoComplete="off" type="text"
        placeholder={t('FORMULA.FUNCTION_SEARCH_PROMPT')}
        value={functionFilter ?? ''}
        onFocus={e => e.target.select()}
        onChange={e => clearHelpText(e.target.value)} />

      {_.isFunction(formulaHelpToggleFunction) && <div
        className="text-underline ml15 mr7 cursorPointer sq-text-primary nowrap"
        onClick={formulaHelpToggleFunction}>
        {t('FORMULA.HIDE_FORMULA_HELP')}
      </div>}
    </div>
  );
};
