/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class ProjectsApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Archive a project
   * @param {string} id - ID of the project to archive
   */
  public archiveProject(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/projects/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ArchiveOutputV1>;
  }

  /**
   * 
   * @summary Create a project
   * @param {models.ProjectInputV1} body - Project information
   */
  public createProject(
    body: models.ProjectInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/projects',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ProjectOutputV1>;
  }

  /**
   * 
   * @summary Get a project
   * @param {string} id - ID of the project to retrieve
   */
  public getProject(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/projects/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ProjectOutputV1>;
  }

  /**
   * 
   * @summary List projects
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getProjects(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/projects',
      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.GetProjectsOutputV1>;
  }

  /**
   * 
   * @summary Schedule a Notebook within a Project to run on a set interval
   * @param {string} id - ID of the Project containing the Notebook to schedule
   * @param {models.ScheduledNotebookInputV1} body - The Notebook schedule information
   */
  public scheduleNotebook(
    body: models.ScheduledNotebookInputV1,
    {
      id,
    } : {
      id: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/projects/{id}/scheduleNotebook'
        .replace('{id}', encodeURIComponent(String(id))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ScheduledNotebookOutputV1>;
  }

  /**
   * 
   * @summary Archive Scheduled Notebook(s) within a Project so that it will no longer run
   * @param {string} id - ID of the Project containing the Notebook to unschedule
   * @param {string} filePath - The path to the Notebook file. Using &#39;*&#39; will unschedule all Notebooks for this Project, regardless of their label
   * @param {string} [label] - The label for the Notebook schedule. Using &#39;*&#39; will unschedule all labels for this filePath
   */
  public unscheduleNotebook(
    {
      id,
      filePath,
      label
    } : {
      id: string,
      filePath: string,
      label?: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(filePath)) {
      throw new Error("'filePath' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/projects/{id}/scheduleNotebook'
        .replace('{id}', encodeURIComponent(String(id))),
      params: omitBy({
        ['filePath']: filePath,
        ['label']: label
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ScheduledNotebookListOutputV1>;
  }

}
