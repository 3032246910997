import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import FormulaParametersTable, { FormulaEditorParam } from '@/hybrid/formula/FormulaParametersTable.molecule';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { AssetChild, AssetGroupActions } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { addTextAtCursor, getNextFormulaIdentifier } from '@/hybrid/formula/formula.utilities';
import { AddParameterBtnAndModal } from '@/hybrid/formula/AddParameterBtnAndModal.atom';
import { FormulaEditor, FormulaErrorInterface } from '@/hybrid/formula/FormulaEditor.molecule';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { NotificationsService } from '@/services/notifications.service';
import { UtilitiesService } from '@/services/utilities.service';
import { FormControl } from 'react-bootstrap';
import { AssetGroupStore } from '@/hybrid/assetGroupEditor/assetGroup.store';
import { findAtLeastOneChild } from '@/hybrid/assetGroupEditor/assetGroup.utilities';
import { FormulaDocumentation } from '@/hybrid/formula/FormulaDocumentation.organism';
import { FormulasApi } from '@/sdk';
import { ShowFormulaHelp } from '@/hybrid/formula/ShowFormulaHelp.atom';

const formulaFromScratchBindings = bindingsDefinition({
  existingParameters: prop.optional<any[]>(),
  item: prop.optional<AssetChild>(),
  exposeParametersToParent: prop<(params: FormulaEditorParam[]) => void>(),
  exposeFormulaToParent: prop<(formula: string) => void>(),
  exposeCalculationColumnNameToParent: prop.optional<(name: string) => void>(),
  validateFormula: prop.optional<() => void>(),
  showColumnNameField: prop.optional<boolean>(),
  formulaErrors: prop.optional<FormulaErrorInterface[]>(),
  setFormulaErrorsFn: prop.optional<(formulaErrors: any) => void>(),
  asset: prop.optional<{ name: string, id: string, itemId: string }>(),
  sqAssetGroupActions: injected<AssetGroupActions>(),
  sqAssetGroupStore: injected<AssetGroupStore>(),
  sqNotifications: injected<NotificationsService>(),
  sqUtilities: injected<UtilitiesService>(),
  sqFormulasApi: injected<FormulasApi>()
});

export const FormulaFromScratch: SeeqComponent<typeof formulaFromScratchBindings> = ({
  existingParameters,
  item,
  exposeParametersToParent,
  exposeFormulaToParent,
  exposeCalculationColumnNameToParent,
  validateFormula = _.noop,
  formulaErrors,
  setFormulaErrorsFn,
  showColumnNameField = false
}) => {
  const {
    sqAssetGroupActions,
    sqAssetGroupStore,
    sqNotifications,
    sqUtilities,
    sqFormulasApi
  } = useInjectedBindings(formulaFromScratchBindings);
  const { t } = useTranslation();

  const [editor, setEditor] = useState(undefined);
  const [calculationColumnName, setCalculationColumnName] = useState(sqAssetGroupActions.getDefaultAttributeName());
  const [constants, setConstants] = useState([]);
  const [operators, setOperators] = useState([]);
  const [formulaHelpExpanded, setFormulaHelpExpanded] = useState(true);
  const toggleFormulaHelp = () => setFormulaHelpExpanded(!formulaHelpExpanded);

  const formula = item?.formula ?? '';

  const assets = sqAssetGroupStore.assets;
  const columns = _.filter(sqAssetGroupActions.getColumns(), column => findAtLeastOneChild(assets, column.name));

  const prepAssetGroupColumnsAsInputParams = _.chain(columns)
    .map(column => ({ id: column.name, name: column.name, iconClass: 'fa-columns', selectGroup: 'assetGroupColumn' }))
    .value();

  const itemsFromExisting = _.chain(existingParameters)
    .map('item')
    .map(item => ({ ...item, selectGroup: 'original' }))
    .value();

  // update existing parameters to use the "item" from the columns instead of the original assigned one:
  const columnNames = _.map(prepAssetGroupColumnsAsInputParams, 'name');
  const [parameters, setParameters] = useState([]);

  useEffect(() => {
    const modifiedParameters = _.map(existingParameters, (parameter) => {
      const itemName = parameter.item?.name;
      if (_.includes(columnNames, itemName)) {
        const column = _.find(prepAssetGroupColumnsAsInputParams, { name: itemName });
        return { ...parameter, item: { name: itemName, id: column.id, selectGroup: 'assetGroupColumn' } };
      }
      return parameter;
    });
    // check if the modifiedparameters and parameters are the same to avoid infinite looping
    if (!_.isEqual(modifiedParameters, parameters)) {
      setParameters(modifiedParameters);
      exposeParametersToParent(modifiedParameters);
    }
  }, [existingParameters]);

  const [selectItems, setSelectItems] = useState(
    _.concat(prepAssetGroupColumnsAsInputParams, itemsFromExisting));

  useEffect(() => {
    sqFormulasApi.getConstantNameList()
      .then(({ data }) => setConstants(data));

    sqFormulasApi.getFormulaDocs({})
      .then(({ data }) => setOperators(data.functions));
  }, []);

  // if no parameters are provided we want to create a parameter for each column that isn't a calculation
  // filter those parameters to exclude calculations
  useEffect(() => {
    const newParams = [];
    if (_.isEmpty(existingParameters) && _.isEmpty(parameters)) {
      _.chain(columns)
        .forEach((column) => {
          const namesToAvoid = _.map(newParams, 'identifier');
          let paramName = sqUtilities.getMediumIdentifier(column.name, namesToAvoid);
          if (_.isEmpty(paramName)) {
            paramName = getNextFormulaIdentifier(newParams);
          }
          newParams.push({ name: paramName, identifier: paramName, item: { id: column.name, name: column.name } });
        }).compact()
        .value();
      updateParameters(newParams);
    }
  }, []);

  const insertFormulaSnippet = (snippet: string) => addTextAtCursor(snippet, editor);

  const updateParameters = (params) => {
    setParameters(params);
    exposeParametersToParent(params);
  };

  const addParameter = ({ name, item, identifier }) => {
    if (_.some(parameters as any, { identifier })) {
      sqNotifications.warnTranslate('FORMULA.VARIABLE_UNIQUE');
    } else {
      setSelectItems([...selectItems, { ...item, selectGroup: 'original' }]);
      updateParameters(_.orderBy([...parameters, { name, item, identifier }], 'identifier'));
    }
  };

  const updateParameter = (updatedParameter: FormulaEditorParam, originalParameter: FormulaEditorParam) => {
    setSelectItems([...selectItems, updatedParameter.item]);
    updateParameters(
      _.orderBy([..._.reject(parameters, { identifier: originalParameter.identifier }), updatedParameter],
        'identifier'));
  };

  const removeParameter = (identifier) => {
    updateParameters(_.reject(parameters, { identifier }));
  };

  const onItemSelect = (parameterIndex: number, item: any) => {
    const params = [...parameters];
    params[parameterIndex].item = item.item;
    updateParameters(params);
  };

  const updateColumnName = (e) => {
    const calculationName = e.target.value;
    setCalculationColumnName(calculationName);
    exposeCalculationColumnNameToParent(calculationName);
  };

  return (
    <div className="flexColumnContainer max-height-650 mb10">
      <div className="flexRowContainer flexFill mr15">
        {showColumnNameField &&
        <div className="flexColumnContainer flexCenter">
          <div className="flexRowContainer searchTitleInput flexFill mb15">
            <FormControl
              value={calculationColumnName}
              placeholder={t('ASSET_GROUP_EDITOR.CALCULATION_COLUMN_NAME')}
              name="calculationColumnName"
              onChange={updateColumnName} />
          </div>
          {!formulaHelpExpanded &&
          <ShowFormulaHelp showColumnNameField={showColumnNameField} onClick={toggleFormulaHelp} />}
        </div>
        }
        <div className="flexColumnContainer flexCenter mb15">
          <div className="flexFill">
            <h4>
              {t('ASSET_GROUP_EDITOR.VARIABLES')}
            </h4>
          </div>
          <div>
            <AddParameterBtnAndModal
              tooltip="FORMULA.TOOLTIP_SEARCH"
              parameters={parameters}
              addParameter={addParameter} />
          </div>
          {!showColumnNameField && !formulaHelpExpanded &&
          <ShowFormulaHelp showColumnNameField={showColumnNameField} onClick={toggleFormulaHelp} />}
        </div>


        <div className="max-height-160 overflowYAuto mb15">
          <FormulaParametersTable
            parameters={parameters}
            updateParameterCallback={updateParameter}
            removeParameterCallback={removeParameter}
            insertParameter={param => addTextAtCursor(param, editor)}
            additionalItems={selectItems}
            excludeStoreItems={true}
            includeAddToDisplayPane={false}
            onItemSelect={onItemSelect}
          />
        </div>

        <FormulaEditor
          formula={formula}
          operators={operators}
          constants={constants}
          parameters={parameters}
          exposeFormulaToParent={exposeFormulaToParent}
          exposeEditorToParent={setEditor}
          onSave={validateFormula}
          showLineNumbers={true}
          formulaErrors={formulaErrors}
          setFormulaErrors={setFormulaErrorsFn}
        />
      </div>

      {formulaHelpExpanded && <div className="max-width-360 pb50 formulaHelp">
        <FormulaDocumentation
          formulaHelpToggleFunction={toggleFormulaHelp}
          operators={operators}
          functions={_.filter(operators, operator => operator.name.indexOf('()') !== -1)}
          insertFormulaSnippet={insertFormulaSnippet} />
      </div>}
    </div>
  );
};

