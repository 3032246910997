/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class RequestsApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Cancel all requests
   */
  public cancelAllRequests(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/requests/all',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Cancel all requests made by the invoking user
   */
  public cancelMyRequests(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/requests/me',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Cancel all requests made by the invoking user for a specific session
   * @param {string} sessionId - ID of the current session
   */
  public cancelMyRequestsBySessionId(
    {
      sessionId
    } : {
      sessionId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(sessionId)) {
      throw new Error("'sessionId' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/requests/me/{sessionId}'
        .replace('{sessionId}', encodeURIComponent(String(sessionId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Cancel a request
   * @param {string} requestId - ID of the request to cancel
   */
  public cancelRequest(
    {
      requestId
    } : {
      requestId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(requestId)) {
      throw new Error("'requestId' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/requests/{requestId}'
        .replace('{requestId}', encodeURIComponent(String(requestId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Cancel multiple requests, optionally filtering by a user and/or datasource. If you don't specify any filters, all of the requests currently running in Seeq will be cancelled. If you specify multiple filters, only requests matching all filters will be cancelled.
   * @param {string} [userId] - userId
   * @param {string} [datasourceClass] - datasourceClass
   * @param {string} [datasourceId] - datasourceId
   * @param {string} [dataId] - dataId
   */
  public cancelRequests(
    {
      userId,
      datasourceClass,
      datasourceId,
      dataId
    } : {
      userId?: string,
      datasourceClass?: string,
      datasourceId?: string,
      dataId?: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/requests',
      params: omitBy({
        ['userId']: userId,
        ['datasourceClass']: datasourceClass,
        ['datasourceId']: datasourceId,
        ['dataId']: dataId
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Get progress information for the request identified by the supplied ID
   * @param {string} requestId - ID of the request for which to retrieve progress information
   */
  public getRequest(
    {
      requestId
    } : {
      requestId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(requestId)) {
      throw new Error("'requestId' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/requests/{requestId}'
        .replace('{requestId}', encodeURIComponent(String(requestId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.GetRequestOutputV1>;
  }

  /**
   * Pagination is enabled for this query
   * @summary Get the collection of requests
   * @param {number} [offset=0] - The pagination offset, the index of the first request that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of requests that will be returned in this page of results
   */
  public getRequests(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/requests',
      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.GetRequestsOutputV1>;
  }

  /**
   * 
   * @summary Restart a request, sending it to the back of the line
   * @param {string} requestId - ID of the request to restart
   */
  public restartRequest(
    {
      requestId
    } : {
      requestId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(requestId)) {
      throw new Error("'requestId' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/requests/{requestId}/restart'
        .replace('{requestId}', encodeURIComponent(String(requestId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

}
