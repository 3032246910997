import _ from 'lodash';
import angular from 'angular';
import { browserIsIE } from '@/utilities/browserId';
import jQuery from 'jquery';

const dependencies = [
  'Sq.Vendor'
];

/**
 * @file IE11 doesn't consistently redraw an element after the fa-spin class is removed
 * The result of this is that it will keep spinning after the class has been removed
 * We have to force it to redraw by using jQuery to hide and immediately show the element
 * This causes the element to be redrawn, and the spinning to stop
 */
angular.module('Sq.Core.jqLiteDecorator', dependencies).config(sqliteDecorator);

function sqliteDecorator($provide) {
  $provide.decorator('$$jqLite', function($delegate) {
    const removeClass = $delegate.removeClass;
    $delegate.removeClass = function(node, classes) {
      const result = removeClass(node, classes);
      if (_.includes(classes, 'fa-spin') && browserIsIE) {
        jQuery(node).hide().show(0);
      }

      return result;
    };

    return $delegate;
  });
}
