import _ from 'lodash';
import angular from 'angular';
import tinycolor from 'tinycolor2';
import Highcharts from 'other_components/highcharts';
import template from './tableVisualization.directive.html';
import { NumberHelperService } from '@/core/numberHelper.service';
import { LabelsService } from '@/trendViewer/label.service';
import { TrendTableActions } from '@/trendData/trendTable.actions';
import { DEBOUNCE } from '@/main/app.constants';
import { DEFAULT_AXIS_LABEL_COLOR, DEFAULT_AXIS_LINE_COLOR } from '@/trendViewer/trendViewer.module';
import { TREND_TOOLS } from '@/investigate/investigate.module';

/**
 * @file Visualizes a "Table". Currently only column charts (stacked or not stacked) are supported
 */
angular.module('Sq.TrendViewer').directive('sqTableVisualization', sqTableVisualization);

function sqTableVisualization(
  $timeout: ng.ITimeoutService,
  sqNumberHelper: NumberHelperService,
  sqLabels: LabelsService,
  sqTrendTableActions: TrendTableActions
) {
  return {
    restrict: 'E',
    template,
    scope: {
      table: '<',
      showLaneLabels: '<',
      labelDisplayConfiguration: '<'
    },
    link: linker
  };

  function linker($scope, $element) {
    let processingChanges = false;
    $scope.updateColor = updateColorDelay;
    $scope.toggleSeries = toggleSeries;
    $scope.restoreDefaultColors = restoreDefaultColors;
    $scope.chartElement = $element.find('#' + $scope.table.id);
    $scope.reflowChart = _.debounce(reflowChart, DEBOUNCE.MEDIUM);
    $scope.$watch('showLaneLabels', updateLabels);
    $scope.$watch('labelDisplayConfiguration', updateLabels);
    $scope.$watch('table', function(newTable, oldTable) {
      $scope.lastTable = $scope.lastTable || oldTable;
      $scope.nextTable = newTable;

      if (processingChanges) {
        // there's already an update queued, so skip this one. $scope.lastTable and $scope.newTable are captured
        // appropriately so that no changes fall through the cracks.
        return;
      }

      processingChanges = true;

      $scope.$evalAsync(function() {
        // Running processItems at the end of the current digest cycle gives this cycle a chance for items to settle
        // before we commit to doing a highcharts render. Delaying the render of the chart can sometimes be faster
        // because it is computationally expensive to render the chart and multiple renders in a row are a waste
        // because the browsers UI thread doesn't have enough time to show the intermediate renders.
        processChanges($scope.lastTable, $scope.nextTable);
        $scope.lastTable = $scope.nextTable;
        processingChanges = false;
      });
    });

    const WHITE_LANE_COLOR = '#ffffff';

    /**
     * Creates the chart that displays the table.
     *
     * @param {string} calculationType - the calculation type
     */
    function createChart(calculationType) {
      const series = getSeries();
      const displayingSubgroups = series.length > 1;
      const chartConfig = {
        chart: { type: 'column', animation: false },
        title: { text: null },
        legend: {
          enabled: false
        },
        yAxis: {
          gridLineWidth: 0,
          lineWidth: 1,
          title: {
            enabled: true,
            text: getYValueSignalName(),
            style: {
              fontFamily: 'Source Sans Pro',
              fontWeight: 'normal',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width: '100%'
            }
          },
          color: DEFAULT_AXIS_LINE_COLOR,
          visible: calculationType !== TREND_TOOLS.FFT_TABLE,
          plotBands: [{
            id: 'labelPlotBand',
            color: WHITE_LANE_COLOR
          }]
        },
        xAxis: {
          categories: $scope.table.categories,
          color: DEFAULT_AXIS_LINE_COLOR,
          labels: {
            style: {
              color: DEFAULT_AXIS_LABEL_COLOR
            }
          }
        },
        credits: {
          enabled: false
        },
        tooltip: {
          backgroundColor: 'series',
          formatter: displayingSubgroups ? formatAggregationTooltip : formatTooltip,
          useHTML: true,
          padding: 0,
          shadow: false
        },
        plotOptions: {
          series: {
            animation: false
          },
          column: {
            stacking: $scope.table.stack ? 'normal' : null
          }
        },
        series
      } as any;

      $scope.chartElement = $element.find('#' + $scope.table.id);

      if ($scope.chartElement) {
        $scope.chart = Highcharts.chart($scope.chartElement[0], chartConfig);
        updateLabels();
      }
    }

    /**
     * Processes changes in the table data.
     * If the table data changes all the existing "series" is removed from the chart. Given the few data points that
     * make up a series for histograms this is more efficient than diffing & updating/deleting/adding every entry.
     *
     * @param {Object} oldTable - the "old" table
     * @param {Object} newTable - the "new" updated table
     */
    function processChanges(oldTable, newTable) {
      let series;
      let redraw = false;

      if (!$scope.chart && !_.isEmpty(newTable.data)) {
        createChart(newTable.calculationType);
      } else {
        if ($scope.chart) {
          if (oldTable.data !== newTable.data) {
            removeAllSeries();
            series = getSeries();

            $scope.chart.xAxis[0].setCategories($scope.table.categories, false);

            _.forEach(series, function(singleSeries) {
              $scope.chart.addSeries(singleSeries, false, false);
            });

            redraw = true;
          }

          if (oldTable.color !== newTable.color || oldTable.binConfig !== newTable.binConfig) {
            if (oldTable.color !== newTable.color && !_.isEmpty(newTable.binConfig)) {
              return sqTrendTableActions.resetBins($scope.table.id);
            }

            adjustSeries();
            redraw = true;
          }

          if (oldTable.stack !== newTable.stack) {
            updateStacking(newTable.stack);
            redraw = true;
          }

          if (oldTable.name !== newTable.name || oldTable.outputUnits !== newTable.outputUnits) {
            updateLabels();
            redraw = true;
          }

          if (redraw) {
            $scope.chart.redraw();
          }
        }
      }
    }

    /**
     * Reflows the chart so it sizes properly on resize.
     */
    function reflowChart() {
      if ($scope.chart) {
        $scope.chart.reflow();
      }
    }

    /**
     * Removes all series from the chart.
     * Does not redraw.
     */
    function removeAllSeries() {
      while ($scope.chart.series.length > 0) {
        $scope.chart.series[0].remove(false, false);
      }
    }

    /**
     * Updates the stacking properties of a series.
     * Does not redraw.
     *
     * @param {Boolean} doStack - whether to stack the columns or not.
     */
    function updateStacking(doStack) {
      _.forEach($scope.chart.series as Highcharts.Series[], function(series) {
        series.update({ stacking: doStack ? 'normal' : null } as any, false);
      });
    }

    /**
     * Extracts an Array of Objects from the Table data so it can be rendered.
     *
     * @returns {Object[]} that can be displayed by Highcharts.
     */
    function getSeries() {
      return _.map($scope.table.data, function(entry: any, idx) {
        return {
          id: entry.name,
          data: entry.data,
          color: getSeriesColor(entry.name, idx),
          visible: !_.get($scope.table.binConfig, entry.name + '.hidden'),
          name: entry.name,
          stacking: $scope.table.stack ? 'normal' : null
        };
      });
    }

    /**
     * Returns the factor to use to lighten the color of the "column". The factor is "capped" so that the color does
     * not end up too close to white. This will result in bars using the same color but users have the option to
     * assign a color so this is ok (at least or now).
     *
     * @param {Number} idx - The index of the series (column)
     * @returns {Number} used as the factor to lighten the color.
     */
    function getLightnessFactor(idx) {
      return (Math.min(5 * idx, 50));
    }

    /**
     * Formats the tooltip for single series Histograms.
     *
     * @returns {String} that shows the value.
     */
    function formatTooltip() {
      const value = _.isFinite(this.y) ? sqNumberHelper.formatNumber(this.y) : this.y;
      return '<span class="text-white">' + value + '</span>';
    }

    /**
     * Formats the tooltip for multi-series Histograms.
     *
     * @returns {String} that shows the value as well as the bin they belong to.
     */
    function formatAggregationTooltip() {
      const value = _.isFinite(this.y) ? sqNumberHelper.formatNumber(this.y) : this.y;
      return '<span class="text-white">Bin ' + this.series.name + '</br></br>' + value + '</span>';
    }

    /**
     * Returns the series object for the given name.
     *
     * @param {String} name - the name of the series to find.
     * @returns {Object} the Series object
     */
    function findChartSeries(name): Highcharts.Series {
      if ($scope.chart) {
        return _.find($scope.chart.series, { options: { name } }) as Highcharts.Series;
      }
    }

    /**
     * Calls updateColor with an artificial delay. This is necessary to ensure that the color is properly reflecting
     * the newly selected color. Note: $evalAsync and $applyAsync didn't work so $timeout is leveraged.
     *
     * @param {String} seriesName - the identifier of the column series (aka - the label)
     * @param {String} color - the new color
     */
    function updateColorDelay(seriesName, color) {
      $timeout(function() {
        updateColor(seriesName, color);
      }, 10);
    }

    /**
     * Toggles the visibility of the series. Called when item is clicked in the legend.
     *
     * @param {String} seriesName - the identifier of the column series (aka - the label)
     */
    function toggleSeries(seriesName) {
      const series = _.find($scope.chart.series, { name: seriesName }) as Highcharts.Series;
      const hidden = series.visible; // toggle the current value
      sqTrendTableActions.updateBinVisibility($scope.table.id, seriesName, hidden);
    }

    /**
     * Restores the default coloration scheme.
     */
    function restoreDefaultColors() {
      sqTrendTableActions.resetBins($scope.table.id);
    }

    /**
     * Updates the color of the specified series (column)
     *
     * @param {String} seriesName - the identifier of the column series (aka - the label)
     * @param {String} color - the new color
     */
    function updateColor(seriesName, color) {
      sqTrendTableActions.updateBinColor($scope.table.id, seriesName, color);
    }

    /**
     * Adjust the color and visibility of the Histogram Series.
     * Does not redraw.
     */
    function adjustSeries() {
      _.forEach($scope.table.data, function(entry: any, idx) {
        const series = findChartSeries(entry.name);
        series.update({ color: getSeriesColor(entry.name, idx) } as Highcharts.SeriesOptionsType, false);
        if (_.get($scope.table.binConfig, entry.name + '.hidden')) {
          series.hide();
        } else {
          series.show();
        }
      });
    }

    /**
     * Returns the color for a given series based on the provided index.
     *
     * @param {String} seriesName - the identifier of the column series (aka - the label)
     * @param {Number} idx - the index
     * @returns {String} representing the color for the series.
     */
    function getSeriesColor(seriesName, idx) {
      const defaultColor = tinycolor($scope.table.color).lighten(getLightnessFactor(idx)).toString();
      return _.get($scope.table, 'binConfig.' + seriesName + '.color', defaultColor);
    }

    /**
     * Helper function that returns the display name for the yValue Signal.
     *
     * @returns {String} display name for the y-axis label.
     */
    function getYValueSignalName() {
      return $scope.table.signalName;
    }

    /**
     * This function renders the "Lane Label".
     */
    function updateLabels() {
      if ($scope.chart) {
        $scope.chart.yAxis[0].removePlotBand('labelPlotBand');
        const extremes = $scope.chart.yAxis[0].getExtremes();
        const plotBand = {
          id: 'labelPlotBand',
          from: extremes.min,
          to: extremes.max,
          color: WHITE_LANE_COLOR,
          label: {
            useHTML: true,
            text: sqLabels.getLaneDisplayText([$scope.table], null, $scope.chart.plotWidth),
            align: 'right',
            verticalAlign: 'top',
            x: -15,
            y: 12,
            style: {
              color: DEFAULT_AXIS_LABEL_COLOR
            }
          }
        };

        $scope.chart.yAxis[0].addPlotBand(plotBand);
      }
    }
  }
}
