import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { APP_STATE, HOME_SCREEN_TABS_AND_TRANSLATION_KEYS } from '@/main/app.constants';
import { HOME_SCREEN_TABLE_TYPE } from '@/hybrid/homescreen/homescreen.module';
import { UtilitiesService } from '@/services/utilities.service';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { HomeScreenStore } from '@/hybrid/homescreen/homescreen.store';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';

const homeScreenBreadCrumbBindings = bindingsDefinition({
  sqHomeScreenStore: injected<HomeScreenStore>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  sqUtilities: injected<UtilitiesService>(),
  $state: injected<any>()
});

export const HomeScreenBreadCrumbs: SeeqComponent<typeof homeScreenBreadCrumbBindings> = () => {
  const { sqHomeScreenStore, $state, sqUtilities, sqHomeScreenActions } = useInjectedBindings(
    homeScreenBreadCrumbBindings);
  const { t } = useTranslation();
  const breadcrumbs = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.breadcrumbs);
  const currentTabKey = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.currentTab);

  const rootNodeDisplay = _.get(_.find(HOME_SCREEN_TABS_AND_TRANSLATION_KEYS, { value: currentTabKey }),
    'text');

  const goToFolder = (folderId) => {
    sqHomeScreenActions.setPageNumber(1, HOME_SCREEN_TABLE_TYPE.TAB);
    return $state.go(APP_STATE.FOLDER_EXPANDED, { currentFolderId: folderId });
  };

  const openRootNode = () => {
    sqHomeScreenActions.setPageNumber(1, HOME_SCREEN_TABLE_TYPE.TAB);
    sqHomeScreenActions.clearBreadcrumbs();
    return $state.go(APP_STATE.WORKBOOKS, { t: sqUtilities.generateTabHash(currentTabKey) });
  };

  const hasFolders = !_.isEmpty(breadcrumbs);

  if (!hasFolders) {
    return null;
  }

  return (
    <div id="breadcrumbLinks">
      <span className="homeScreenBreadcrumbLink cursorPointer"
           onClick={openRootNode}>{t(rootNodeDisplay)}
        <span className="mr3 ml3">»</span>
      </span>
      {_.map(breadcrumbs,
        (crumb) => {
          const isLast = crumb === _.last(breadcrumbs);
          return (
            <span
              key={`crumbId_${crumb.id}`}
              className={classNames({ 'homeScreenBreadcrumbLink cursorPointer': !isLast })}
              onClick={() => goToFolder(_.get(crumb, 'id'))}>
              {_.get(crumb, 'name')}
              {!isLast && <span className="mr3 ml3">»</span>}
            </span>);
        })}
    </div>);
};
