import _ from 'lodash';
import angular from 'angular';
import { AggregationBinStore } from '@/investigate/aggregationBins/aggregationBin.store';
import { CalculationRunnerService } from '@/services/calculationRunner.service';
import { CAPSULE_MODES } from '@/investigate/aggregationBins/capsuleMode.component';
import { AGGREGATION_MODES } from '@/investigate/aggregationBins/aggregationBinPanel.controller';
import { Y_VALUE_BIN_MODES } from '@/investigate/aggregationBins/byYValue.controller';

export const COLUMN = {
  Y_VALUE: 'yValueCol',
  TIME: 'timeCol',
  CONDITION: 'conditionCol'
};

angular.module('Sq.Investigate')
  .service('sqAggregationBinActions', sqAggregationBinActions);
export type AggregationBinActions = ReturnType<typeof sqAggregationBinActions>;

function sqAggregationBinActions(
  flux: ng.IFluxService,
  sqAggregationBinStore: AggregationBinStore,
  sqCalculationRunner: CalculationRunnerService
) {
  const service = {
    setBinMode,
    setStat,
    setYValueBinMin,
    setYValueBinMax,
    setConditionProperty,
    setTimeBucket,
    setEmptyBuckets,
    createFormula,
    addAggregation,
    setAggregationMode,
    setAggregationItem,
    setCapsuleMode,
    setBinSize,
    setBinNumber,
    removeAggregation
  };

  return service;

  /**
   * Adds an aggregationConfig to the store. Each section of the form that starts off with "Select aggregation type"
   * is defined by an entry in the aggregationConfigs array.
   */
  function addAggregation() {
    flux.dispatch('AGGREGATION_ADD_AGGREGATION');
  }

  /**
   * Sets the statistic
   *
   * @param {Object} stat - the chosen statistic
   */
  function setStat(stat) {
    flux.dispatch('AGGREGATION_SET_STAT', { stat });
  }

  /**
   * Sets the empty bucket flag that indicates if empty results should be displayed or not
   *
   * @param {Boolean} emptyBucketsAllowed - true if empty ranges should be displayed or false if not
   */
  function setEmptyBuckets(emptyBucketsAllowed) {
    flux.dispatch('AGGREGATION_SET_ALLOW_EMPTY_BUCKETS', { emptyBucketsAllowed });
  }

  /**
   * Sets the bin size for the specified aggregationConfig.
   *
   * @param {Number} binSize - the size of the bin
   * @param {Number} id - the id of the aggregationConfig to update
   */
  function setBinSize(binSize, id) {
    flux.dispatch('AGGREGATION_SET_VALUE_PARAMS',
      { id, binSize });
  }

  /**
   * Sets the number of bins for the specified aggregationConfig.
   *
   * @param {Number} numberOfBins - the number of bins
   * @param {Number} id - the id of the aggregationConfig to update
   */
  function setBinNumber(numberOfBins, id) {
    flux.dispatch('AGGREGATION_SET_VALUE_PARAMS',
      { id, numberOfBins });
  }

  /**
   * Sets the binMode.
   *
   * @param {String} binMode - one of Y_VALUE_BIN_MODES
   * @param {Number} id - the id of the aggregationConfig to update
   */
  function setBinMode(binMode, id) {
    flux.dispatch('AGGREGATION_SET_VALUE_PARAMS', { id, yValueBinMode: binMode });
  }

  /**
   * Sets aggregation mode
   *
   * @param {String} mode - one of AGGREGATION_MODES
   * @param {Number} id - the id of the aggregationConfig to update
   */
  function setAggregationMode(mode, id) {
    flux.dispatch('AGGREGATION_SET_VALUE_PARAMS', { id, mode });
  }

  /**
   * Sets aggregation item
   *
   * @param {Object} item - A signal or condition that can be used for aggregation
   * @param {Number} id - the id of the aggregationConfig to update
   */
  function setAggregationItem(item, id) {
    flux.dispatch('AGGREGATION_SET_VALUE_PARAMS', { id, item });
  }

  /**
   * Sets the minimum value for the y-value bin range
   *
   * @param {Number} value - the minimum
   * @param {Number} id - the id of the aggregationConfig to update
   */
  function setYValueBinMin(value, id) {
    flux.dispatch('AGGREGATION_SET_VALUE_PARAMS', { id, yValueBinMin: value });
  }

  /**
   * Sets the maximum value for the y-value bin range
   *
   * @param {Number} value - the minimum
   * @param {Number} id - the id of the aggregationConfig to update
   */
  function setYValueBinMax(value, id) {
    flux.dispatch('AGGREGATION_SET_VALUE_PARAMS', { id, yValueBinMax: value });
  }

  /**
   * Sets the capsule overlap mode when grouping by condition value.
   *
   * @param {Number} value - capsule overlap mode
   * @param {Number} id - the id of the aggregationConfig to update
   */

  function setCapsuleMode(value, id) {
    flux.dispatch('AGGREGATION_SET_VALUE_PARAMS',
      { id, capsuleMode: value });
  }

  /**
   * Sets the condition property used for the condition based aggregation.
   *
   * @param {String} conditionProperty - the property of the condition to aggregate on.
   * @param {Number} id - the id of the aggregationConfig to update
   */
  function setConditionProperty(conditionProperty, id) {
    flux.dispatch('AGGREGATION_SET_VALUE_PARAMS', { conditionProperty, id });
  }

  /**
   * Sets the selected time bucket to be used for time based aggregation.
   *
   * @param {Object} bucketDef - an object defining the time bucket.
   * @param {String} bucketDef.key - the unique key defining the time bucket
   * @param {String} bucketDef.display - the label used to display the time bucket option
   * @param {String} bucketDef.funct - the formula function that makes it all happen
   * @param {Number} id - the id of the aggregationConfig to update
   */
  function setTimeBucket(bucketDef, id) {
    flux.dispatch('AGGREGATION_SET_VALUE_PARAMS', { timeBucket: bucketDef, id });
  }

  /**
   * Dispatches an event to remove the specified aggregation from the aggregationConfigs.
   *
   * @param {String} id - the id of the aggregation config to remove.
   */
  function removeAggregation(id) {
    flux.dispatch('AGGREGATION_REMOVE', { id });
  }

  /**
   * This function creates the formula that calculates the Histogram.
   * Based on the selected mode the appropriate formula is constructed.
   *
   * @param {Object} signalToAggregate - The signal to aggregate
   * @param {Object} selectedStatistic - The selected statistic
   * @returns Object {{formula: string, parameters: Array}} defining the formula and the required parameters.
   */
  function createFormula(signalToAggregate, selectedStatistic) {
    let unit, binSize, bucketDef;
    const parameters = [];
    let formula = 'conditionTable(';

    const statMethod = sqCalculationRunner.getStatisticFragment(selectedStatistic);
    _.forEach(sqAggregationBinStore.aggregationConfigs as any[], function(param: any, idx) {
      if (idx > 0) {
        formula += ', ';
      }

      if (param.mode === AGGREGATION_MODES.Y_VALUE) {
        if (param.yValueBinMode === Y_VALUE_BIN_MODES.NUMBER) {
          binSize = (param.yValueBinMax - param.yValueBinMin) / param.numberOfBins;
        } else {
          binSize = param.binSize;
        }

        unit = _.get(param.item, 'valueUnitOfMeasure', '');
        // TODO: this is a temporary workaround for inverted units until a larger discussion as part of CRAB-10183
        unit = _.startsWith(unit, '1/') ? unit.replace('1/', '/') : unit;

        const capsulePartitionsFormula = `capsule(${param.yValueBinMin}${unit}, ${param.yValueBinMax}${unit})` +
          `.partition(${binSize}${unit})`;

        formula += `$yValueSignal${param.id}.toStates(${capsulePartitionsFormula})` +
          `.toCondition("${COLUMN.Y_VALUE}${param.id}")` +
          `.toGroup($viewCapsule, CapsuleBoundary.Intersect)` +
          `, "${COLUMN.Y_VALUE}${param.id}"`;
        parameters.push({ unbound: false, name: 'yValueSignal' + param.id, id: param.item.id });

        if (sqAggregationBinStore.includeEmptyBuckets) {
          formula += `, ${capsulePartitionsFormula}.property("value")`;
        }
      } else if (param.mode === AGGREGATION_MODES.CONDITION) {
        const capsuleMode = _.get(_.find(CAPSULE_MODES, { key: param.capsuleMode }), 'formula');
        formula += `$condition${param.id}.toGroup($viewCapsule, ${capsuleMode}), "${param.conditionProperty}"`;
        parameters.push({ unbound: false, name: 'condition' + param.id, id: param.item.id });
      } else if (param.mode === AGGREGATION_MODES.TIME) {
        bucketDef = param.timeBucket;
        const columnName = `${COLUMN.TIME}_${bucketDef.key}`;
        const capsuleMode = _.get(_.find(CAPSULE_MODES, { key: param.capsuleMode }), 'formula',
          CAPSULE_MODES[0].formula);
        formula += bucketDef.funct +
          `.renameProperty("${bucketDef.key}", "${columnName}")` +
          `.toGroup($viewCapsule, ${capsuleMode}), "${columnName}"`;
      }
    });

    formula += ').addStatColumn("signalToAggregate", $signalToAggregate, ' + statMethod + ')';
    parameters.push({ unbound: false, name: 'signalToAggregate', id: signalToAggregate.id });

    return { formula, parameters };
  }
}
