import angular from 'angular';
import template from './aclStatus.component.html';

/**
 * This component displays effective permissions for an item for users without manage permission. It provides a link
 * that allows administrators and users with manage permission to open the ACL modal and view/modify permissions.
 */
angular.module('Sq.Utilities').component('sqAclStatus', {
  controller: 'AclStatusCtrl',
  template,
  bindings: {
    item: '<',
    showLabel: '<'
  }
});
