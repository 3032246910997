import _ from 'lodash';
import angular from 'angular';
import exportExcelModalTemplate from './exportExcelModal.html';
import exportPIVisionModalTemplate from './exportPIVisionModal.html';
import jQuery from 'jquery';
import { TrendActions } from '@/trendData/trend.actions';
import { TrendStore } from '@/trendData/trend.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { DurationStore } from '@/trendData/duration.store';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { TrendScalarStore } from '@/trendData/trendScalar.store';
import { WorkbookStore } from '@/workbook/workbook.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { WorkstepsStore } from '@/worksteps/worksteps.store';
import { NotificationsService } from '@/services/notifications.service';
import { ExportService } from '@/services/export.service';
import { CursorActions } from '@/trendData/cursor.actions';
import { CursorStore } from '@/trendData/cursor.store';
import { UtilitiesService } from '@/services/utilities.service';
import { YAxisActions } from '@/trendData/yAxis.actions';
import { TrendTableStore } from '@/trendData/trendTable.store';
import { InvestigateStore } from '@/investigate/investigate.store';
import { AnnotationActions } from '@/annotation/annotation.actions';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { CapsuleGroupActions } from '@/investigate/customCondition/capsuleGroup.actions';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import {
  EXPORTABLE_ITEM_TYPES,
  ITEM_DATA_STATUS,
  ITEM_TYPES,
  LABEL_LOCATIONS,
  LABEL_PROPERTIES,
  TREND_VIEWS
} from '@/trendData/trendData.module';
import { DEBOUNCE } from '@/main/app.constants';
import { EXPORT_TO_ODATA_TOOL_ID, INVESTIGATE_TOOLS, TREND_TOOLS } from '@/investigate/investigate.module';
import { ModalService } from '@/services/modal.service';
import { RedactionService } from '@/services/redaction.service';
import { TrendChartItemsHelperService } from '@/trendData/trendChartItemsHelper.service';
import { AuthorizationService } from '@/services/authorization.service';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';

angular.module('Sq.TrendViewer').controller('TrendViewerCtrl', TrendViewerCtrl);

function TrendViewerCtrl(
  $scope: ng.IScope,
  $state: ng.ui.IStateService,
  $translate: ng.translate.ITranslateService,
  sqModal: ModalService,
  $q: ng.IQService,
  $timeout: ng.ITimeoutService,
  $element: JQuery,
  $injector: ng.auto.IInjectorService,
  sqTrendActions: TrendActions,
  sqTrendStore: TrendStore,
  sqTrendSeriesStore: TrendSeriesStore,
  sqTrendCapsuleStore: TrendCapsuleStore,
  sqTrendCapsuleSetStore: TrendCapsuleSetStore,
  sqTrendChartItemsHelper: TrendChartItemsHelperService,
  sqDurationStore: DurationStore,
  sqWorkbenchStore: WorkbenchStore,
  sqTrendScalarStore: TrendScalarStore,
  sqWorkbookStore: WorkbookStore,
  sqWorksheetStore: WorksheetStore,
  sqWorkstepsStore: WorkstepsStore,
  sqNotifications: NotificationsService,
  sqTrendDataHelper: TrendDataHelperService,
  sqExport: ExportService,
  sqCursorActions: CursorActions,
  sqCursorStore: CursorStore,
  sqUtilities: UtilitiesService,
  sqYAxisActions: YAxisActions,
  sqTrendTableStore: TrendTableStore,
  sqInvestigateStore: InvestigateStore,
  sqAnnotationActions: AnnotationActions,
  sqWorksheetActions: WorksheetActions,
  sqCapsuleGroupActions: CapsuleGroupActions,
  sqRedaction: RedactionService,
  sqAuthorization: AuthorizationService,
  sqSystemConfiguration: SystemConfigurationService
) {

  const vm = this;
  let initialLoadFinished = false;

  vm.setView = sqTrendActions.setView;
  vm.setPointerValues = sqTrendActions.setPointerValues;
  vm.clearPointerValues = sqTrendActions.clearPointerValues;
  vm.setYExtremes = sqYAxisActions.setYExtremes;
  vm.setSelectedRegion = sqTrendActions.setSelectedRegion;
  vm.removeSelectedRegion = sqTrendActions.removeSelectedRegion;
  vm.pickSelectedRegion = sqCapsuleGroupActions.pickSelectedRegion;
  vm.toggleCapsuleAlignment = sqTrendActions.toggleCapsuleAlignment;
  vm.toggleHideUnselectedItems = sqTrendActions.toggleHideUnselectedItems;
  vm.dimHideOutsideCapsule = sqTrendActions.toggleDimDataOutsideCapsules;
  vm.zoomToSelectedRegion = sqTrendActions.zoomToSelectedRegion;
  vm.setCustomLabel = sqTrendActions.setCustomLabel;
  vm.resetCapsuleTimeOffsets = sqTrendActions.resetCapsuleTimeOffsets;
  vm.selectItems = selectItems;
  vm.isRegionSelected = sqTrendStore.isRegionSelected;
  vm.oneLane = sqYAxisActions.oneLane;
  vm.spreadLanes = sqYAxisActions.resetAllAxes;
  vm.oneYAxis = sqYAxisActions.oneYAxis;
  vm.setLabelDisplayConfiguration = sqTrendActions.setLabelDisplayConfiguration;
  vm.setCapsuleLabels = sqTrendActions.setCapsuleLaneLabels;
  vm.isCapsuleAlignmentSelected = isCapsuleAlignmentSelected;
  vm.exportData = exportData;
  vm.elementHeight = elementHeight;
  vm.setCursorValueFlags = sqCursorActions.setCursorValueFlags;
  vm.cursorDeleteAll = sqCursorActions.deleteAll;
  vm.cursorDeleteSelected = sqCursorActions.deleteSelected;
  vm.canShowChart = canShowChart;
  vm.isPresentationMode = sqUtilities.isPresentationWorkbookMode;
  vm.TREND_TOOLS = TREND_TOOLS;
  vm.viewOptions = [TREND_VIEWS.CALENDAR, TREND_VIEWS.CHAIN, TREND_VIEWS.CAPSULE];
  vm.CALENDAR = TREND_VIEWS.CALENDAR;
  vm.CHAIN = TREND_VIEWS.CHAIN;
  vm.CAPSULE = TREND_VIEWS.CAPSULE;
  vm.LABEL_PROPERTIES = LABEL_PROPERTIES;
  vm.LABEL_LOCATIONS = LABEL_LOCATIONS;
  vm.determineBarChartHeight = determineBarChartHeight;
  vm.newAnnotation = sqAnnotationActions.newAnnotation;
  vm.isAnnotateEnabled = isAnnotateEnabled;
  vm.smallToolbar = false;
  vm.onResize = onResize;
  vm.showToolPanels = showToolPanels;
  vm.isToolPanelsShown = isToolPanelsShown;
  vm.breaks = [];
  vm.getAnnotateButtonTooltip = getAnnotateButtonTooltip;
  vm.capsuleTimeAlignmentOptionsEnabled = sqSystemConfiguration.capsuleTimeAlignmentOptionsEnabled;
  vm.hasCursorsInSelection = false;
  vm.enableRemoveSelectedCursorsButton = false;
  // Exposed for testing
  vm.hasChainViewDimmingError = hasChainViewDimmingError;
  /**
   * The y-alignment options. Note that the "value" is also used to identify the icon filename.
   */
  vm.yAlignmentOptions = [{
    name: 'TOOLBAR.START_POINTS',
    value: 'start'
  }, {
    name: 'TOOLBAR.MIDDLE_POINTS',
    value: 'middle'
  }, {
    name: 'TOOLBAR.END_POINTS',
    value: 'end'
  }, {
    name: 'TOOLBAR.MINIMUM',
    value: 'yMin',
    points: 1
  }, {
    name: 'TOOLBAR.AVERAGE',
    value: 'avg'
  }, {
    name: 'TOOLBAR.MAXIMUM',
    value: 'yMax'
  }];

  $scope.$listenTo(sqTrendStore, syncTrendStore);
  $scope.$listenTo(sqWorksheetStore, syncWorksheetStore);
  $scope.$listenTo(sqDurationStore, ['displayRange'], syncDurationStore);
  $scope.$listenTo(sqCursorStore, ['showValues'], syncCursorStore);
  $scope.$listenTo(sqTrendCapsuleSetStore, syncTrendCapsuleSetStore);
  $scope.$listenTo(sqTrendCapsuleStore, syncTrendCapsuleStore);
  $scope.$listenTo(sqTrendSeriesStore, syncTrendStores);
  $scope.$listenTo(sqTrendScalarStore, syncTrendStores);
  $scope.$listenTo(sqTrendTableStore, syncTrendTableStore);
  $scope.$listenTo(sqInvestigateStore, syncInvestigateStore);

  activate();

  /**
   * Initializes the chart items and captures screenshot upon controller initialization.
   */
  function activate() {
    updateChartItems();
    if (vm.view === TREND_VIEWS.CHAIN) {
      sqTrendActions.createStitchDetails();
    }

    // This timeout avoids the chain view 'no capsules' help screen from showing while the trend is first loading.
    // Since screenshots wait for HTTP requests to finish, it isn't necessary to wait in that case.
    if (sqUtilities.headlessRenderMode()) {
      initialLoadFinished = true;
    } else {
      $timeout(function() {
        initialLoadFinished = true;
      }, 2000);
    }
  }

  function syncWorksheetStore(e) {
    vm.selectedTimezone = sqWorksheetStore.timezone;

    // Need to react to changing of conditionToSeriesGrouping property, because removing last series from this mapping
    // will not produce changes in trend store but only in worksheet store
    if (sqUtilities.propertyChanged(e, 'conditionToSeriesGrouping')) {
      updateChartItems();
    }
    setShowDimmingButton();
  }

  function syncTrendStore(e) {
    vm.view = sqTrendStore.view;
    vm.isCapsuleTime = sqTrendStore.view === TREND_VIEWS.CAPSULE;
    vm.hideIfChainView = vm.view === TREND_VIEWS.CHAIN ? 'd-none' : '';
    vm.selectedRegion = sqTrendStore.selectedRegion;
    vm.capsuleTimeOffsets = sqTrendStore.capsuleTimeOffsets;
    vm.areCapsuleTimeOffsetsChanged = sqTrendStore.areCapsuleTimeOffsetsChanged;
    vm.capsuleAlignment = sqTrendStore.capsuleAlignment;
    vm.isDimmed = sqTrendStore.dimDataOutsideCapsules;
    vm.hideUnselected = sqTrendStore.hideUnselectedItems;
    vm.showChartConfiguration = sqTrendStore.showChartConfiguration;
    vm.showLaneLabels = sqTrendStore.showLaneLabels;
    vm.labelDisplayConfiguration = _.cloneDeep(sqTrendStore.labelDisplayConfiguration);
    vm.showCapsuleLabels = sqTrendStore.showCapsuleLaneLabels;
    vm.isCapsuleTimeLimited = sqTrendStore.isCapsuleTimeLimited;
    vm.dimmingMenuChecked = vm.isDimmed || vm.hideUnselected;
    vm.noCapsulesSelected = vm.isCapsuleTime && vm.hideUnselected;
    vm.summaryLabel = vm.isPresentationMode && sqTrendStore.getSummaryAsString();
    vm.hasCursorsInSelection = sqCursorStore.hasCursorsInSelectedRegion(vm.isCapsuleTime);
    vm.enableRemoveSelectedCursorsButton = vm.hasCursorsInSelection && vm.isRegionSelected();

    setCustomLabelsVars();

    setShowCapsulesPanel();

    if (sqUtilities.propertyChanged(e, ['view', 'hideUnselectedItems'])) {
      updateChartItems();
    }

    setShowDimmingButton();
  }

  /**
   * Determines if "Dimming" button should be displayed.
   * When CRAB-25925 will be implemented then this functions will be not needed.
   * And "Dimming" button can be hidden in trendViewer.directive.html with ng-if="!ctrl.isCapsuleTime"
   */
  function setShowDimmingButton() {
    vm.showDimmingButton = !vm.isCapsuleTime &&
      // Hide dimming button in grouping mode under chain view.
      // TODO CRAB-25925 remove button hiding when signal selection will be available in group mode
      !(sqTrendStore.view === TREND_VIEWS.CHAIN && sqWorksheetStore.capsuleGroupMode);

    // Switch dimming off when we are in grouping mode under chain view
    if (!vm.showDimmingButton && sqTrendStore.hideUnselectedItems && sqTrendStore.view === TREND_VIEWS.CHAIN) {
      sqTrendActions.toggleHideUnselectedItems();
    }
  }

  /**
   * Sets vm.customLabelsLocationTranslate, vm.customLabels, and vm.showCustomLabels
   */
  function setCustomLabelsVars() {
    const existingCustomLabels = vm.labelDisplayConfiguration.customLabels;
    const laneLabels = vm.labelDisplayConfiguration.custom === LABEL_LOCATIONS.LANE;
    const axisLabels = vm.labelDisplayConfiguration.custom === LABEL_LOCATIONS.AXIS;
    vm.customLabelsLocationTranslate = laneLabels ? 'TOOLBAR.LANE' : (axisLabels ? 'TOOLBAR.AXIS' : '');
    if (laneLabels || axisLabels) {
      // Populate the array with all possible lanes/axes in use for display with ng-model regardless of if they exist
      // in `existingCustomLabels`
      vm.customLabels = _.chain(axisLabels ? sqTrendStore.uniqueAlignments : sqTrendStore.uniqueLanes)
        .map((target) => {
          const label = {
            location: vm.labelDisplayConfiguration.custom,
            target
          } as any;

          label.text = _.get(_.find(existingCustomLabels, label), 'text', '');

          return label;
        })
        .value();

      vm.showCustomLabels = true;
    } else {
      vm.customLabels = [];
      vm.showCustomLabels = false;
    }
  }

  function syncTrendCapsuleStore(e) {
    vm.rangeEditingEnabled = !vm.isPresentationMode;

    if (sqUtilities.propertyChanged(e, 'stitchBreaks') || _.isEmpty(vm.breaks)) {
      vm.breaks = sqTrendCapsuleStore.stitchBreaks;
    }

    if (sqUtilities.propertyChanged(e, 'chartItems')) {
      updateChartItems();
    }

    if (sqUtilities.propertyChanged(e, 'items')) {
      if (vm.view === TREND_VIEWS.CHAIN) {
        sqTrendActions.createStitchDetails();
      }
    }
  }

  /**
   * Determines the height for the tableVisualisation directives. Based on the number of lanes the height will vary
   *
   * TODO: Cody Ray Hoeft - a percent could probably be used here instead now that phantomjs is no longer used
   */
  function determineBarChartHeight() {
    const displayedItemsCount = _.get(_.chain(vm.chartItems)
      .map('lane')
      .uniq()
      .value(), 'length', 0);

    const displayedTablesCount = _.get(vm.displayTables, 'length', 0);

    return jQuery('#trendDisplayArea').height() / (displayedItemsCount + displayedTablesCount);
  }

  /**
   * Called when the size (height or width) of the div containing the trend and its toolbar changes.
   *
   * @param {Number} height - new height of the trend area, in pixels
   * @param {Number} width - new width of the trend area, in pixels
   */
  function onResize(height, width) {
    vm.smallToolbar = width < 815;
  }

  function syncTrendStores(e) {
    if (sqUtilities.collectionPropertyChanged(e, 'items', LISTEN_ITEM_PROPERTIES)) {
      updateChartItems();
    }

    if (sqUtilities.propertyChanged(e, ['previewChartItem'])) {
      if (!sqTrendSeriesStore.previewChartItem || !sqTrendSeriesStore.previewChartItem.yAxisMin) {
        sqYAxisActions.updateLaneDisplay();
      }

      updateChartItems();
    }
  }

  /**
   * Syncs the sqTrendCapsuleSetStore with view-model properties.
   */
  function syncTrendCapsuleSetStore() {
    vm.hasCapsuleSetItems = sqTrendCapsuleSetStore.items.length > 0;
    setShowCapsulesPanel();
  }

  function syncCursorStore() {
    vm.cursorShowValues = sqCursorStore.showValues;
    vm.hasCursorsInSelection = sqCursorStore.hasCursorsInSelectedRegion(vm.isCapsuleTime);
    vm.enableRemoveSelectedCursorsButton = vm.hasCursorsInSelection && vm.isRegionSelected();
  }

  function syncInvestigateStore() {
    vm.pickingMode = sqInvestigateStore.isCapsulePickingMode;
    vm.activeTool = sqInvestigateStore.activeTool;
    vm.toolName = sqInvestigateStore.activeToolName || 'CAPSULE_GROUP';
  }

  function updateChartItems() {
    const [displayTables, otherItems] = _.partition(
      sqTrendChartItemsHelper.chartItems(), ['itemType', ITEM_TYPES.TABLE]);
    vm.displayTables = displayTables;
    // The timeout is needed to ensure this happens on the next digest cycle - CRAB-18916
    $timeout(function() {
      vm.chartItems = otherItems;
    }, 0, false);
  }

  /**
   * Updates the start/end dates using the start/end date moment objects and updates the duration.
   */
  function syncDurationStore() {
    vm.trendStart = sqDurationStore.displayRange.start;
    vm.trendEnd = sqDurationStore.displayRange.end;
  }

  /**
   * Determines if the specified capsule alignment option is selected.
   *
   * @param {String} alignment - The alignment option
   * @return {Boolean} True if option is selected, false otherwise
   */
  function isCapsuleAlignmentSelected(alignment) {
    return _.includes(sqTrendStore.capsuleAlignment, alignment);
  }

  /**
   * Select an item
   *
   * @param {Object} item - The item to be selected
   * @param {Object[]} items - The collection that this item belongs to
   * @param {Object} event - The click event
   */
  function selectItems(item, items, e) {
    if (item.notFullyVisible) {
      return;
    }
    sqTrendActions.selectItems(item, items, e);
  }

  /**
   * Determines if the capsules panel should be displayed and sets the showCapsulesPanel variable accordingly
   */
  function setShowCapsulesPanel() {
    vm.showCapsulesPanel = vm.hasCapsuleSetItems && !vm.showPropertiesPanel && (!vm.showChartConfiguration ||
      vm.isCapsuleTime);
  }

  /**
   * Exports all trend data to a specified format. Utilizes the browser 'save' functionality to prompt the
   * user for a filename and location.
   *
   * @param {String} destination - Specifies where to export the data to (e.g. OData, Excel, etc.)
   */
  function exportData(destination) {
    let promise;
    const exportName = 'Export-' + sqWorkbookStore.name + '-' +
      sqWorkbookStore.getWorksheetName($state.params.worksheetId) + '_' + sqWorkstepsStore.current.id;

    vm.exportInProgress = true;
    if (destination === 'excel') {
      promise = sqModal.open({
        animation: true,
        controller: 'ExportExcelModalCtrl',
        controllerAs: 'ctrl',
        template: exportExcelModalTemplate,
        resolve: { exportName: _.constant(exportName) },
        size: 'sm'
      }).result;
    } else if (destination === 'odata') {
      $injector.get<InvestigateActions>('sqInvestigateActions')
        .setActiveTool(_.find(INVESTIGATE_TOOLS, { id: EXPORT_TO_ODATA_TOOL_ID }).id);
      promise = $q.resolve();
    } else if (destination === 'pivision') {
      const items = sqTrendDataHelper.getAllItems({
        workingSelection: sqTrendStore.hideUnselectedItems,
        excludeDataStatus: [ITEM_DATA_STATUS.FAILURE, ITEM_DATA_STATUS.REDACTED],
        itemTypes: EXPORTABLE_ITEM_TYPES
      });
      promise = sqExport.toPIVision(items)
        .then(function(response) {
          sqModal.open({
            animation: true,
            controller: 'ExportPIVisionModalCtrl',
            controllerAs: 'ctrl',
            bindToController: true,
            template: exportPIVisionModalTemplate,
            resolve: { piVisionQueryString: _.constant(response.queryString) }
          });
        });
    } else if (destination === 'powerpoint') {
      $injector.get<InvestigateActions>('sqInvestigateActions')
        .setActiveTool(_.find(INVESTIGATE_TOOLS, { id: 'export-powerpoint' }).id);
      promise = $q.resolve();
    } else {
      throw new TypeError('Format ' + destination + ' not supported');
    }

    promise
      .catch(function(e) {
        // Ignore errors due to backdrop clicks or escape key presses as these are valid ways to close the excel modal
        if (destination !== 'excel' || e !== 'backdrop click' && e !== 'escape key press') {
          sqNotifications.apiError(e);
        }
      })
      .finally(_.partial(_.set, vm, 'exportInProgress', false));
  }

  /**
   * Get the element height for use with the bottom panel
   *
   * @return {Number} - height of the trendviewer component
   */
  function elementHeight() {
    return $element.height();
  }

  /**
   * Determines if the chart should be shown based on the mode and the chart items.
   *
   * @returns {Boolean} True if the chart should be shown, false otherwise
   */
  function canShowChart() {
    if (!initialLoadFinished) {
      // Assume we can show the chart for the first 2000ms (see timeout in activate()).
      // This avoids a 'blue flash' of help text that disappears as soon as capsules load in chain view (see below).
      return true;
    }

    if (vm.isCapsuleTime) {
      if (sqWorksheetStore.capsuleGroupMode && _.isEmpty(sqWorksheetStore.conditionToSeriesGrouping)) {
        return false;
      } else {
        return sqTrendCapsuleStore.hasDisplayedCapsules()
          && (!!sqTrendSeriesStore.capsuleSeries.length || !!sqTrendScalarStore.items.length);
      }
    } else if (vm.view === TREND_VIEWS.CHAIN) {
      return sqTrendStore.hideUnselectedItems ? !vm.hasChainViewDimmingError() : sqTrendCapsuleStore.hasDisplayedCapsules();
    } else {
      return !!vm.chartItems?.length;
    }
  }

  function hasChainViewDimmingError() {
    const hasSelectedConditions = _.some(sqTrendCapsuleStore.chartItems, ['selected', true]);
    const noSelectedConditions = !hasSelectedConditions
      && _.some(sqTrendDataHelper.getAllItems(), ['selected', true]);

    const noCapsulesForSelectedConditions = hasSelectedConditions && _.chain(sqTrendCapsuleStore.chartItems)
      .filter(['selected', true])
      .filter(item => _.some(item.capsules, capsule => capsule.endTime - capsule.startTime > 0))
      .value().length === 0;

    return noSelectedConditions || noCapsulesForSelectedConditions || !sqTrendCapsuleStore.hasDisplayedCapsules();
  }

  /**
   * Syncs the TrendTable Store
   */
  function syncTrendTableStore() {
    updateChartItems();
  }

  /**
   * Determines if annotation creation is allowed.
   *
   * @returns {Boolean} - true if allowed, false otherwise
   */
  function isAnnotateEnabled() {
    const selectedItems = _.filter(sqTrendDataHelper.getAllItems(), 'selected');
    const isRegionSelectedAndAllowed = vm.isRegionSelected() && sqAuthorization.canWriteItem(sqWorkbookStore);
    return (_.some(selectedItems) || _.some(sqTrendCapsuleStore.items, 'selected') || isRegionSelectedAndAllowed)
      && !vm.isCapsuleTime
      && !_.some(selectedItems, item => sqRedaction.isItemRedacted(item));
  }

  /**
   * Helper function that exposes the browse panel and switches to the Tools tab.
   */
  function showToolPanels() {
    sqWorksheetActions.setBrowsePanelCollapsed(false);
    sqWorksheetActions.tabsetChangeTab('sidebar', 'investigate');
  }

  /**
   * Determines if the tool panel is visible
   *
   * @returns {boolean} - true if the browse panel is not collapsed and shows the tools tab
   */
  function isToolPanelsShown() {
    const tabset = sqWorksheetStore.getTabset('sidebar');
    return !sqWorksheetStore.browsePanelCollapsed && tabset.tabs[tabset.activeTabIndex] === 'investigate';
  }

  /**
   * Gets the annotate button tooltip based on current application state
   */
  function getAnnotateButtonTooltip() {
    if (vm.isAnnotateEnabled()) {
      return sqAuthorization.canWriteItem(sqWorkbookStore) ? 'TOOLBAR.ANNOTATE_ENABLED' :
        'TOOLBAR.ANNOTATE_ENABLED_READ_ONLY';
    } else {
      if (vm.isCapsuleTime) {
        return 'TOOLBAR.ANNOTATE_DISABLED_CAPSULE';
      } else {
        return sqAuthorization.canWriteItem(sqWorkbookStore) ? 'TOOLBAR.ANNOTATE_DISABLED' :
          'TOOLBAR.ANNOTATE_DISABLED_READ_ONLY';
      }
    }
  }
}

export const LISTEN_ITEM_PROPERTIES = ['selected', 'yAxisConfig', 'axisVisibility', 'lane', 'yAxisAlign',
  'visible', 'valueUnitOfMeasure'];
