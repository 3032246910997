import angular from 'angular';
import template from './capsulesPanel.component.html';

angular.module('Sq.TrendViewer').component('sqCapsulesPanel', {
  template,
  controller: 'CapsulesPanelCtrl',
  bindings: {
    resizePanelStart: '&',
    resizeEnabled: '<?',
    removeControls: '<?', // Optional, removes buttons for configuring the panel
    singleSelect: '<?' // Optional, only allows a single capsule to be selected
  }
});
