import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { SignalSmoothingActions } from '@/hybrid/tools/signalSmoothing/signalSmoothing.actions';
import { SignalSmoothingStore } from '@/hybrid/tools/signalSmoothing/signalSmoothing.store';
import { InvestigateStore } from '@/investigate/investigate.store';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { ITEM_TYPES, PREVIEW_ID } from '@/trendData/trendData.module';
import { DEBOUNCE, PERIOD_UNITS } from '@/main/app.constants';
import { DISPLAY_MODE } from '@/main/app.constants';
import { TrendActions } from '@/trendData/trend.actions';
import { DateTimeService } from '@/datetime/dateTime.service';
import { InvestigateHelperService } from '@/investigate/investigateHelper.service';
import { SMOOTHING_ALGORITHMS, SMOOTHING_LIMITS } from './signalSmoothing.module';
import { FORM_ERROR, FORM_WARNING, FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { useDebounce } from '@/hybrid/core/useDebounce.hook';
import { TrackService } from '@/track/track.service';

const signalSmoothingBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  sqTrendActions: injected<TrendActions>(),
  sqInvestigateStore: injected<InvestigateStore>(),
  sqSignalSmoothingActions: injected<SignalSmoothingActions>(),
  sqSignalSmoothingStore: injected<SignalSmoothingStore>(),
  sqDateTime: injected<DateTimeService>(),
  sqInvestigateHelper: injected<InvestigateHelperService>(),
  sqTrack: injected<TrackService>()
});

export const SignalSmoothing: SeeqComponent<typeof signalSmoothingBindings> = () => {
  const {
    sqInvestigateActions,
    sqInvestigateStore,
    sqTrendActions,
    sqDateTime,
    sqInvestigateHelper,
    sqSignalSmoothingActions,
    sqSignalSmoothingStore,
    sqTrack
  } = useInjectedBindings(signalSmoothingBindings);

  const [color, setColor] = useState('');
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  const displayMode = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.displayMode);

  const {
    id,
    name,
    inputSignal,
    originalParameters,
    algorithmSelectedValue,
    samplingRate,
    smoothingWindow,
    cutoff,
    polynomialFactor,
    isSamplingRateAuto,
    isCutoffAuto,
    isTau,
    tau,
    alpha,
    isMigratedLowPassFilter
  } = useFlux(sqSignalSmoothingStore);

  // Whenever the input signal is changed we need to re-calculate the default smoothing window (and sample rate) but
  // only if "auto" is checked. If the selected Algorithm is Exponential then we need to trigger a call to
  // getEstimatedSamplePeriod as there's no smoothing window calculation that would trigger that calculation.
  useEffect(() => {
    if (inputSignal?.id) {
      if (isSamplingRateAuto && !id) {
        sqSignalSmoothingActions.setDefaultWindowSize();
      }

      if (algorithmSelectedValue === SMOOTHING_ALGORITHMS.EXPONENTIAL.VALUE && isSamplingRateAuto) {
        sqSignalSmoothingActions.getEstimatedSamplePeriod();
      }
    }
  }, [inputSignal]);

  // Creates the preview
  const generatePreview = useDebounce(() => {
    if (!inProgress && isFormValid && inputSignal?.id) {
      const parameters = { inputSignal: inputSignal.id };
      const { formula } = sqSignalSmoothingActions.getFormulaAndTrackAction(algorithmSelectedValue);
      return sqTrendActions.generatePreviewSeries(formula, parameters, id ? id : PREVIEW_ID, color)
        .catch(() => sqTrendActions.removePreviewSeries());
    } else {
      sqTrendActions.removePreviewSeries();
    }
  }, DEBOUNCE.PREVIEW);

  useEffect(() => {
    const timer = setTimeout(generatePreview, DEBOUNCE.PREVIEW);
    return () => clearTimeout(timer);
  }, [algorithmSelectedValue, samplingRate, smoothingWindow, cutoff, polynomialFactor, inputSignal, tau, isTau, alpha]);

  // Updates the cutoff value only if the user is doing a low pass filter
  useEffect(() => {
    const timer = setTimeout(() => {
      if (isCutoffAuto && algorithmSelectedValue === SMOOTHING_ALGORITHMS.LOW_PASS.VALUE) {
        sqSignalSmoothingActions.recalculateCutoff(samplingRate);
      }
    }, DEBOUNCE.SHORT);

    return () => clearTimeout(timer);
  }, [samplingRate, algorithmSelectedValue]);

  const algorithmOptions = _.map(SMOOTHING_ALGORITHMS, algorithm => ({
    text: algorithm.TEXT,
    value: algorithm.VALUE
  }));

  const executeSmoothingTool = () => {
    const parameters = { inputSignal: inputSignal?.id };
    const { formula, trackAction } = sqSignalSmoothingActions.getFormulaAndTrackAction(algorithmSelectedValue);
    setInProgress(true);
    generatePreview.cancel();
    sqTrendActions.removePreviewSeries();
    sqSignalSmoothingActions.setIsMigratedLowPassFilter(false);

    return sqSignalSmoothingActions.executeSignalSmoothing(formula, parameters, color)
      .then(() => {
        sqTrack.doTrack('Workbench_Tool', trackAction, 'completed');
      })
      .catch((errorMessage) => {
        sqTrack.doTrack('Workbench_Tool', trackAction, 'error');
        setApiErrorMessage(_.replace(errorMessage, /\(.*\)/gm, ''));
      })
      .finally(() => setInProgress(false));
  };

  ///////////////// Errors and Warnings ////////////////////////
  const isAlphaInvalid = (alpha: number) => alpha >= 1 || alpha <= 0 || _.isUndefined(alpha);

  const isPolynomialValid = (factor: number) => {
    return _.isUndefined(factor)
      || sqSignalSmoothingActions.invalidPolynomialFactorRange(factor)
      || sqSignalSmoothingActions.invalidPolynomialFactorPeriods(factor);
  };

  ///////////////// Smoothing Tool Form Setup ////////////////////////
  const smoothingToolFormSetup: FormElement[] = [{
    component: 'SearchTitleFormComponent',
    name: 'signalSmoothingTitle',
    value: name,
    onChange: name => sqInvestigateActions.setSearchName(TREND_TOOLS.SIGNAL_SMOOTHING, name),
    id,
    onColorChange: setColor,
    searchIconClass: 'fc-lowpass',
    defaultName: 'SIGNAL_SMOOTHING.DEFAULT_NAME'
  }, {
    component: 'ErrorMessageFormComponent',
    name: 'migratedLowPassWarning',
    includeIf: isMigratedLowPassFilter,
    type: FORM_WARNING,
    value: 'SIGNAL_SMOOTHING.MIGRATED_LOW_PASS_WARNING',
    failForm: false,
    extraClassNames: "ml15"
  }, {
    component: 'ItemSelectFormComponent',
    name: 'inputSignal',
    displayNumber: true,
    testId: 'signalSmoothingInputSignal',
    value: inputSignal?.id,
    onChange: (item) => {
      // re-calculate the smoothing window (which will re-calculate the sampling rate)
      sqInvestigateActions.setParameterItem(TREND_TOOLS.SIGNAL_SMOOTHING, 'inputSignal', item);
    },
    label: 'SIGNAL_SMOOTHING.SIGNAL_SELECTION',
    itemTypes: [ITEM_TYPES.SERIES],
    includeMetadata: true,
    additionalItems: originalParameters,
    excludedIds: id,
    excludeStringSignals: true
  }, {
    component: 'FormGroup',
    name: 'algorithmSelectGroup',
    displayNumber: true,
    components: [{
      component: 'LabelFormComponent',
      name: 'algorithmSelectLabel',
      value: 'SIGNAL_SMOOTHING.ALGORITHM',
      tooltip: 'SIGNAL_SMOOTHING.TOOLTIPS.ALGORITHM',
      onIconClick: () => open('https://telemetry.seeq.com/support-link/wiki/spaces/KB/pages/512786844', '_blank')
    }, {
      component: 'IconSelectFormComponent',
      name: 'smoothingAlgorithm',
      value: algorithmSelectedValue,
      onChange: (algorithm) => {
        sqSignalSmoothingActions.setAlgorithmSelectedValue(algorithm.value);
      },
      className: 'specSignalSmoothingAlgorithmSelect',
      selectOptions: algorithmOptions
    }]
  }, {
    component: 'ValueWithUnitsFormComponent',
    name: 'smoothingWindow',
    value: smoothingWindow,
    includeIf: algorithmSelectedValue !== SMOOTHING_ALGORITHMS.EXPONENTIAL.VALUE,
    displayNumber: true,
    onChange: sqSignalSmoothingActions.setSmoothingWindow,
    tooltip: 'SIGNAL_SMOOTHING.TOOLTIPS.SMOOTHING_WINDOW',
    label: 'SIGNAL_SMOOTHING.SMOOTHING_WINDOW',
    icon: 'fa-question-circle',
    min: 0,
    minIsExclusive: true,
    testId: 'smoothingWindow',
    validation: smoothingWindow => isSamplingRateAuto ? false : sqSignalSmoothingActions.isWindowRatioInvalid(
      { samplingRate, smoothingWindow }),
    extendValidation: true,
    customErrorText: 'SIGNAL_SMOOTHING.ERROR.SMOOTHING_WINDOW_RANGE_ERROR',
    customErrorParams: sqSignalSmoothingActions.getWindowSizeErrorBounds(samplingRate),
    defaultProvided: !_.isUndefined(inputSignal?.id) && smoothingWindow?.valid
  }, {
    component: 'FormGroup',
    name: 'tauOrAlphaGroup',
    displayNumber: true,
    includeIf: algorithmSelectedValue === SMOOTHING_ALGORITHMS.EXPONENTIAL.VALUE,
    components: [{
      component: 'RadioButtonGroupFormComponent',
      name: 'tauOrAlphaRadioGroup',
      value: isTau,
      onChange: _.noop,
      id: 'tauOrAlphaRadioGroup',
      label: 'SIGNAL_SMOOTHING.TAU_ALPHA_LABEL',
      options: [
        {
          id: 'specValueSearchSimpleButton',
          label: 'SIGNAL_SMOOTHING.TAU',
          checked: isTau,
          onToggle: () => sqSignalSmoothingActions.setIsTau(true)
        },
        {
          id: 'specValueSearchAdvancedButton',
          label: 'SIGNAL_SMOOTHING.ALPHA',
          checked: !isTau,
          onToggle: () => sqSignalSmoothingActions.setIsTau(false),
          tooltip: 'SIGNAL_SMOOTHING.TOOLTIPS.ALPHA'
        }]
    }, {
      component: 'ValueWithUnitsFormComponent',
      name: 'tau',
      value: tau,
      onChange: sqSignalSmoothingActions.setTau,
      min: 0,
      minIsExclusive: true,
      includeIf: algorithmSelectedValue === SMOOTHING_ALGORITHMS.EXPONENTIAL.VALUE && isTau,
      customErrorText: 'SIGNAL_SMOOTHING.ERROR.TAU'
    }, {
      component: 'FormControlFormComponent',
      name: 'alpha',
      includeIf: algorithmSelectedValue === SMOOTHING_ALGORITHMS.EXPONENTIAL.VALUE && !isTau,
      testId: 'alpha',
      value: alpha,
      step: 0.01,
      type: 'number',
      onChange: sqSignalSmoothingActions.setAlpha,
      validation: isAlphaInvalid,
      size: 'sm',
      className: 'flexFill pr3 height-32 mt5 mb10',
      customError: 'SIGNAL_SMOOTHING.ERROR.ALPHA'
    }]
  }, {
    component: 'AdvancedFormGroup',
    name: 'advancedParametersFormGroup',
    toolName: TREND_TOOLS.SIGNAL_SMOOTHING,
    toolId: TREND_TOOLS.SIGNAL_SMOOTHING,
    toolStore: sqSignalSmoothingStore,
    components: [{
      component: 'ValueWithUnitsFormComponent',
      name: 'cutoff',
      value: cutoff,
      includeIf: algorithmSelectedValue === SMOOTHING_ALGORITHMS.LOW_PASS.VALUE,
      displayNumber: true,
      onChange: sqSignalSmoothingActions.setCutoff,
      min: 0,
      minIsExclusive: true,
      availableUnits: PERIOD_UNITS,
      testId: 'cutoff',
      disabled: isCutoffAuto,
      validation: cutoff => !sqInvestigateHelper.checkCutOffRateRatio(cutoff, samplingRate,
        SMOOTHING_LIMITS.CUTOFF_MIN_RATIO, false),
      extendValidation: true,
      label: 'SIGNAL_SMOOTHING.CUTOFF',
      tooltip: 'SIGNAL_SMOOTHING.TOOLTIPS.CUTOFF',
      icon: 'fa-question-circle',
      includeAutoCheckbox: true,
      autoCheckboxId: 'autoCutoffCheckbox',
      autoCheckboxValue: isCutoffAuto,
      autoCheckboxOnChange: () => sqSignalSmoothingActions.setIsCutoffAuto(!isCutoffAuto),
      customErrorText: `SIGNAL_SMOOTHING.ERROR.CUTOFF_${sqDateTime.isFrequency(cutoff.units) ? 'FREQUENCY' : 'PERIOD'}`,
      customErrorParams: {
        limit: sqInvestigateHelper.getCutoffRateErrorLimit(cutoff, samplingRate, SMOOTHING_LIMITS.CUTOFF_MIN_RATIO)
      }
    }, {
      component: 'FormGroup',
      name: 'polynomialFactorGroup',
      includeIf: algorithmSelectedValue === SMOOTHING_ALGORITHMS.SAVITSKY.VALUE,
      displayNumber: true,
      components: [{
        component: 'LabelFormComponent',
        name: 'polynomialFactorLabel',
        value: 'SIGNAL_SMOOTHING.POLYNOMIAL_FACTOR'
      }, {
        component: 'FormControlFormComponent',
        name: 'polynomialFactor',
        testId: 'polynomialFactor',
        type: 'number',
        value: polynomialFactor,
        onChange: sqSignalSmoothingActions.setPolynomialFactor,
        validation: isPolynomialValid,
        size: 'sm',
        className: 'width-110 pr3 height-32',
        customError: `SIGNAL_SMOOTHING.ERROR.POLYNOMIAL_FACTOR`,
        customErrorParams: sqSignalSmoothingActions.getPolynomialFactorUpperBound()
      }]
    }, {
      component: 'ValueWithUnitsFormComponent',
      name: 'samplingRate',
      displayNumber: true,
      value: samplingRate,
      onChange: sqSignalSmoothingActions.setSamplingRate,
      min: 0,
      minIsExclusive: true,
      availableUnits: PERIOD_UNITS,
      testId: 'samplingRate',
      disabled: isSamplingRateAuto,
      label: 'SIGNAL_SMOOTHING.SAMPLING_RATE',
      includeAutoCheckbox: true,
      autoCheckboxId: 'autoSamplingRateCheckbox',
      autoCheckboxValue: isSamplingRateAuto,
      autoCheckboxOnChange: () => sqSignalSmoothingActions.setIsSamplingRateAuto(!isSamplingRateAuto),
      defaultProvided: !_.isUndefined(inputSignal?.id) && samplingRate?.valid
    }]
  }, {
    component: 'ErrorMessageFormComponent',
    name: 'apiError',
    includeIf: apiErrorMessage !== '',
    value: apiErrorMessage,
    type: FORM_ERROR,
    title: 'SIGNAL_SMOOTHING.FAILURE',
    dismissible: true,
    failForm: false,
    onClose: () => setApiErrorMessage('')
  }];

  const smoothingToolBuilder = <ToolPanelFormBuilder
    formDefinition={smoothingToolFormSetup}
    toolName={TREND_TOOLS.SMOOTHING_TOOL}
    toolId={TREND_TOOLS.SMOOTHING_TOOL}
    setIsValid={setIsFormValid}
    submitBtnId="signalSmoothingNowButton"
    submitFn={executeSmoothingTool}
    closeFn={() => {
      sqTrendActions.removePreviewSeries();
      sqInvestigateActions.close();
    }} />;

  return (displayMode === DISPLAY_MODE.NEW || displayMode === DISPLAY_MODE.EDIT) ? smoothingToolBuilder : null;
};

export const sqSignalSmoothing = angularComponent(signalSmoothingBindings, SignalSmoothing);
