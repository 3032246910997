import React from 'react';
import { bindingsDefinition, prop } from '../core/bindings.util';
import { angularComponent } from '../core/react2angular.util';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { JournalReplyIcon } from '@/hybrid/annotation/JournalReplyIcon.atom';
import { Icon } from '@/hybrid/core/Icon.atom';

const journalFooterBindings = bindingsDefinition({
  isExpanded: prop<boolean>(),
  isCommentsExpanded: prop<boolean>(),
  commentsCount: prop<number>(),
  isPresentationMode: prop<boolean>(),
  customExpandComments: prop<(expand: boolean) => void>()
});

export const JournalFooter: SeeqComponent<typeof journalFooterBindings> = (props) => {
  const {
    isExpanded,
    isCommentsExpanded,
    commentsCount,
    isPresentationMode,
    customExpandComments
  } = props;

  const { t } = useTranslation();

  const onExpandComments = (event) => {
    event.stopPropagation();
    customExpandComments(true);
  };

  return <>
    {!isPresentationMode && !isCommentsExpanded && isExpanded &&
      <div className="journalFooter flexFill flexRowContainer height-25">
        <div className="flexColumnContainer flexJustifyEnd flexAlignCenter flexNoGrowNoShrink height-25">
          <HoverTooltip text={t('JOURNAL.TOOLTIP.EXPAND_COMMENTS')}>
            <div className="flexColumnContainer cursorPointer hoverHighlightPanel mr10"
              onClick={onExpandComments}
            >
              <Icon icon="fa-chevron-left" extraClassNames="sq-text-primary mr3 mt2"/>
              <span className="sq-text-primary">
                {t('JOURNAL.ENTRY.COMMENTS')}
              </span>
              <div className="pl3">
                <JournalReplyIcon count={commentsCount} />
              </div>
            </div>
          </HoverTooltip>
        </div>
      </div>
    }
  </>;
};

export const sqJournalFooter = angularComponent(journalFooterBindings, JournalFooter);
