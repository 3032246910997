import angular from 'angular';
import template from './byTime.component.html';

angular.module('Sq.Investigate').component('sqByTime', {
  controller: 'ByTimeCtrl',
  template,
  transclude: false,
  bindings: {
    id: '<',
    startNumber: '<'
  }
});
