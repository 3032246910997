import _ from 'lodash';
import classNames from 'classnames';
import React, { MouseEvent } from 'react';
import { NewLineToBr } from '@/hybrid/core/NewLineToBr.atom';
import { NULL_PLACEHOLDER } from '@/hybrid/tableBuilder/tableBuilderHelper.service';
import { ENUM_REGEX } from '@/trendData/trendData.module';

interface DataCellIF {
  onClick?: (event: MouseEvent<any, any>) => void;
  textValue: string;
  hasUnitColumn?: boolean;
  units?: string;
  columnIndex: number;
  style: Object;
  extraClassNames?: string;
  key?: number;
}

export const TableBuilderDataCell: React.FunctionComponent<DataCellIF> = (props) => {
  const {
    onClick = _.noop,
    textValue,
    hasUnitColumn = false,
    units,
    style,
    extraClassNames
  } = props;

  const getCellValue = () => {
    if (_.isNil(textValue)) {
      return '';
    } else if (_.includes(textValue, '\n')) {
      return <NewLineToBr lineToBreak={textValue} />;
    }

    const match = textValue?.toString().match(ENUM_REGEX);
    if (match) {
      return match[2];
    }

    if (!hasUnitColumn && !_.isEmpty(units) && textValue !== NULL_PLACEHOLDER) {
      return `${textValue} ${units}`;
    } else {
      return textValue;
    }
  };

  return <td
    className={classNames('forceVerticalAlignMiddle nowrap', extraClassNames)}
    data-testid="tableBuilderDataCell"
    onClick={onClick}
    style={style}
  >
    {getCellValue()}
  </td>;
};
