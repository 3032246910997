import _ from 'lodash';
import angular from 'angular';

angular.module('Sq.SystemWarning')
  .service('sqSystemWarningActions', sqSystemWarningActions);
export type SystemWarningActions = ReturnType<typeof sqSystemWarningActions>;

function sqSystemWarningActions(
  flux: ng.IFluxService) {

  const service = {
    setSystemWarnings,
    setSystemMessage,
    disableSystemWarning,
    disableUserLimitWarning
  };

  return service;

  /**
   * Sets the system warnings and errors from the array of serverSpecs objects.
   *
   * @param {Object[]} serverSpecsArray
   * @param {String} serverSpecsArray[].componentName - the name of the hardware component
   * @param {Boolean} serverSpecsArray[].error - true if component is an error
   * @param {Boolean} serverSpecsArray[].warning - true if component is a warning
   */
  function setSystemWarnings(serverSpecsArray) {
    flux.dispatch('SYSTEM_WARNINGS_SET_WARNING_COMPONENTS',
      _.chain(serverSpecsArray)
        .filter((spec: any) => spec.error || spec.warning)
        .map('componentName')
        .value());
  }

  /**
   * Sets the system warnings and errors from the array of serverSpecs objects.
   *
   * @param {string} message
   */
  function setSystemMessage(message) {
    flux.dispatch('SYSTEM_WARNINGS_SET_SYSTEM_MESSAGE', { message });
  }

  /**
   * Stops the system warning from being displayed
   */
  function disableSystemWarning() {
    flux.dispatch('SYSTEM_WARNINGS_DISABLE_SYSTEM_WARNING');
  }

  /**
   * Stops the user limit warning from being displayed
   */
  function disableUserLimitWarning() {
    flux.dispatch('SYSTEM_WARNINGS_SUPPRESS_USER_LIMIT_WARNING');
  }
}
