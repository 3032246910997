import _ from 'lodash';
import angular, { IPromise } from 'angular';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { NotificationsService } from '@/services/notifications.service';
import { SearchStore } from '@/search/search.store';
import { ModalSearchStore } from '@/search/modalSearch.store';
import { TrendActions } from '@/trendData/trend.actions';
import { TreesApi } from 'sdk/api/TreesApi';
import {
  HOME_BREADCRUMB,
  MAX_RETURNED_RESULTS,
  SEARCH_BREADCRUMB,
  SEARCH_CHILDREN_PER_PAGE,
  SEARCH_MODES,
  SEARCH_PANES,
  SEARCH_PER_PAGE,
  SEARCH_RESULT_TYPES
} from '@/search/search.module';
import { PUSH_IGNORE } from '@/services/stateSynchronizer.service';
import { DatasourcesApi, ItemsApi } from '@/sdk';
import { CREATED_BY_SEEQ_WORKBENCH } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { HttpHelpersService } from '@/services/httpHelpers.service';
import { UtilitiesService } from '@/services/utilities.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { WorkbenchStore } from '@/workbench/workbench.store';

angular.module('Sq.Search').service('sqSearchActions', sqSearchActions);

export type SearchActions = ReturnType<typeof sqSearchActions>;

function sqSearchActions(
  $q: ng.IQService,
  $state: ng.ui.IStateService,
  flux: ng.IFluxService,
  sqPendingRequests: PendingRequestsService,
  sqNotifications: NotificationsService,
  sqSearchStore: SearchStore,
  sqModalSearchStore: ModalSearchStore,
  sqTrendActions: TrendActions,
  sqHttpHelpers: HttpHelpersService,
  sqItemsApi: ItemsApi,
  sqTreesApi: TreesApi,
  sqDatasourcesApi: DatasourcesApi,
  sqUtilities: UtilitiesService,
  sqWorkbenchStore: WorkbenchStore
) {

  const cancellationGroup = 'dataSearch';
  const service = {
    searchItems,
    loadNextPage,
    exploreAsset,
    setNameFilter,
    setDescriptionFilter,
    setDocumentFilter,
    toggleType,
    toggleDatasource,
    setSortBy,
    clear,
    setAdvancedMode,
    setMode,
    getStoreForPane,
    initialize,
    setDatasources // exposed for testing
  };
  return service;

  /**
   * Initializes the specified search pain with assets or results and the breadcrumbs.
   */
  function initialize(pane: string, searchTypes: string[], restrictExploration: boolean,
    scopeIds: string []): IPromise<any> {
    flux.dispatch('SEARCH_ASYNC_INITIALIZED', { pane, isAsyncInitialized: true });
    if (sqUtilities.isPresentationWorkbookMode && pane === SEARCH_PANES.MAIN) {
      return $q.resolve();
    }

    const store = getStoreForPane(pane);
    if (store.mode === SEARCH_MODES.SEARCH && !_.isEmpty(store.currentAsset)) {
      const crumbProperties = ['name', 'type', 'archived', 'ancestors', 'id'];
      let breadcrumbs = [HOME_BREADCRUMB] as { id?: string }[];
      sqTreesApi.getTree({ id: store.currentAsset })
        .then(({ data: item }) => {
            const treeNode = item.item;
            if (!_.isEmpty(treeNode.ancestors)) {
              breadcrumbs = _.concat(breadcrumbs, _.map(treeNode.ancestors, (ancestor) => {
                return _.pick(ancestor, crumbProperties);
              }));
            }
            breadcrumbs = _.concat(breadcrumbs, _.pick(treeNode, crumbProperties),
              SEARCH_BREADCRUMB);
            flux.dispatch('SEARCH_SET_BREADCRUMBS', { breadcrumbs, pane: SEARCH_PANES.MAIN }, PUSH_IGNORE);
          }
        );
    }

    return searchOrExplore(pane, store.mode, searchTypes, restrictExploration, scopeIds);
  }

  /**
   * Initiate a new search and fetch the first page of results. If no search terms were entered the asset browser is
   * shown instead.
   *
   * @param pane - The name of the search pane, one of SEARCH_PANES
   * @param searchTypes - The array of Item types to search if the typeFilter is empty
   * @param scopeIds - The array of Item Ids to search in, defaults to current workbook Id
   * @return {Promise} A promise that resolves with the results
   */
  function searchItems(pane: string, searchTypes: string[], scopeIds: string[] = [$state.params.workbookId]) {
    return sqPendingRequests.cancelGroup(cancellationGroup)
      .finally(() => {
        flux.dispatch('SEARCH_INITIATE', { pane });
        return setDatasources(pane).then(() => {
          return fetchItemResults(pane, searchTypes, scopeIds);
        });
      });
  }

  /**
   * Fetches the next batch of results
   *
   * @param pane - The name of the search pane, one of SEARCH_PANES
   * @param searchTypes - The array of Item types to search if the typeFilter is empty
   * @param restrictExploration - Whether exploration should be restricted or not
   * @param scopeIds - The array of Item Ids to search in, defaults to current workbook Id
   * @return {Promise} A promise that resolves with the results
   */
  function loadNextPage(pane: string, searchTypes: string[], restrictExploration?: boolean,
    scopeIds: string[] = [$state.params.workbookId]) {
    flux.dispatch('SEARCH_LOAD_NEXT_PAGE', { pane }, PUSH_IGNORE);
    return getStoreForPane(pane).mode === SEARCH_MODES.TREE
      ? fetchAssetResults(pane, searchTypes, restrictExploration)
      : fetchItemResults(pane, searchTypes, scopeIds);
  }

  /**
   * If datasources aren't set in the store, get them via the API and set datasource
   * and localDatasources in the store
   *
   * @param {String} pane - The name of the search pane, one of SEARCH_PANES
   * @return {Promise<void>} Promise that resolves when datasources are fetched and set, or immediately if
   *          datasources are already set
   */
  function setDatasources(pane): IPromise<void> {
    const store = getStoreForPane(pane);
    if (!_.isEmpty(store.datasources)) {
      return $q.resolve();
    }
    return sqDatasourcesApi.getDatasources({ limit: MAX_RETURNED_RESULTS })
      .then(({ data: { datasources } }) => {
        // Set local datasource sand datasources to show in the datasources dropdown
        flux.dispatch('SEARCH_SET_DATASOURCES', { pane, datasources });
      });
  }

  /**
   * Initiate explore view at the asset and fetch the results
   *
   * @param pane - The name of the search pane, one of SEARCH_PANES
   * @param asset - The id of the asset to show
   * @param searchTypes - The types to search
   * @param restrictExploration - If exploration should be restricted
   * @return {Promise} A promise that resolves with the results
   */
  function exploreAsset(pane: string, asset: string, searchTypes?: string[], restrictExploration?: boolean) {
    return sqPendingRequests.cancelGroup(cancellationGroup)
      .finally(() => {
        return setDatasources(pane).then(() => {
          flux.dispatch('SEARCH_EXPLORE_ASSET', { pane, asset });
          return fetchAssetResults(pane, searchTypes, restrictExploration);
        });
      });
  }

  /**
   * Clears the search state and re-populates the root nodes.
   *
   * @param pane - The name of the search pane, one of SEARCH_PANES
   * @param searchTypes - The types to search
   * @param restrictExploration - Used when exploring an asset
   * @param scopeIds - The array of Item Ids to search in, defaults to current workbook Id
   */
  function clear(pane: string, searchTypes: string[], restrictExploration: boolean = false,
    scopeIds: string[] = [$state.params.workbookId]) {
    return sqPendingRequests.cancelGroup(cancellationGroup)
      .finally(() => {
        flux.dispatch('SEARCH_CLEAR', { pane });
        service.initialize(pane, searchTypes, restrictExploration, scopeIds);
      });
  }

  /**
   * Sets the name filter
   *
   * @param {String} pane - The name of the search pane, one of SEARCH_PANES
   * @param {String} nameFilter - The text used to filter by name
   */
  function setNameFilter(pane, nameFilter) {
    flux.dispatch('SEARCH_SET_NAME_FILTER', { pane, nameFilter }, PUSH_IGNORE);
  }

  /**
   * Sets the description filter used in advanced mode
   *
   * @param {String} pane - The name of the search pane, one of SEARCH_PANES
   * @param {String} descriptionFilter - The text used to filter by description
   */
  function setDescriptionFilter(pane, descriptionFilter) {
    flux.dispatch('SEARCH_SET_DESCRIPTION_FILTER', { pane, descriptionFilter }, PUSH_IGNORE);
  }

  /**
   * Sets the document filter used in advanced mode
   *
   * @param {String} pane - The name of the search pane, one of SEARCH_PANES
   * @param {String} documentFilter - The text used to filter by document
   */
  function setDocumentFilter(pane, documentFilter) {
    flux.dispatch('SEARCH_SET_DOCUMENT_FILTER', { pane, documentFilter }, PUSH_IGNORE);
  }

  /**
   * Toggles an Item type used to filter in advanced mode
   *
   * @param {String} pane - The name of the search pane, one of SEARCH_PANES
   * @param {String} type - An Item type
   */
  function toggleType(pane, type) {
    flux.dispatch('SEARCH_TOGGLE_TYPE', { pane, type });
  }

  /**
   * Toggles a Datasource used to filter in advanced mode
   *
   * @param {String} pane - The name of the search pane, one of SEARCH_PANES
   * @param {Object} datasource - A Datasource
   * @param {String} datasource.id - The guid of the datasource in Seeq
   * @param {String} datasource.datasourceClass - The class of the datasource
   * @param {String} datasource.datasourceId - The datasource's id for itself, unique combined with datasourceClass
   */
  function toggleDatasource(pane, datasource) {
    flux.dispatch('SEARCH_TOGGLE_DATASOURCE', { pane, datasource });
  }

  /**
   * Sets the Sort By option used in advanced mode
   *
   * @param {String} pane - The name of the search pane, one of SEARCH_PANES
   * @param {String} sortBy - The property to sort the results by
   */
  function setSortBy(pane, sortBy) {
    flux.dispatch('SEARCH_SET_SORT_BY', { pane, sortBy }, PUSH_IGNORE);
  }

  /**
   * Sets the search form to advanced or simple mode
   *
   * @param {String} pane - The name of the search pane, one of SEARCH_PANES
   * @param {Boolean} isAdvancedMode - True if form is advanced mode, false for simple mode
   */
  function setAdvancedMode(pane, isAdvancedMode) {
    flux.dispatch('SEARCH_SET_ADVANCED_MODE', { pane, isAdvancedMode });
  }

  /**
   * Sets the mode for search results
   *
   * @param pane - The name of the search pane, one of SEARCH_PANES
   * @param mode - One of SEARCH_MODES
   * @param searchTypes - The types to search
   * @param restrictExploration - Used when exploring an asset
   * @param scopeIds - The array of Item Ids to search in
   */
  function setMode(pane: string, mode: string, searchTypes: string[],
    restrictExploration: boolean, scopeIds: string[]) {
    flux.dispatch('SEARCH_SET_MODE', { pane, mode });
    searchOrExplore(pane, mode, searchTypes, restrictExploration, scopeIds);
  }

  function searchOrExplore(pane: string, mode: string, searchTypes: string[],
    restrictExploration: boolean, scopeIds: string[]) {
    switch (mode) {
      case SEARCH_MODES.SEARCH:
        return service.searchItems(pane, searchTypes, scopeIds).catch(sqNotifications.apiError);
      case SEARCH_MODES.TREE:
      case SEARCH_MODES.OVERVIEW:
        return service.exploreAsset(pane, getStoreForPane(pane).currentAsset, searchTypes, restrictExploration)
          .catch(sqNotifications.apiError);
      default:
        return $q.resolve();
    }
  }

  /**
   * Search for items and dispatch the results. Results are filtered by the different filters provided by the search
   * store. They are further restricted to only those in the global scope or in the provided workbook scopes
   *
   * @param pane - The name of the search pane, one of SEARCH_PANES
   * @param searchTypes - The array of Item types to search if the typeFilter is empty
   * @param scopeIds - The array of Item Ids to search in
   */
  function fetchItemResults(pane: string, searchTypes: string[], scopeIds: string []) {
    const store = getStoreForPane(pane);
    const offset = store.isPaginating ? store.items.length : 0;
    const { filters, types } = store.getSearchFilters(searchTypes);
    const resultType = SEARCH_RESULT_TYPES.ITEMS;

    return sqItemsApi.searchItems({
        filters: filters as string[],
        types: types as string[],
        scope: scopeIds,
        asset: store.currentAsset,
        orderBy: store.sortBy,
        offset,
        limit: SEARCH_PER_PAGE
      }, { cancellationGroup })
      .then(({ data }) => {
        sqHttpHelpers.addAssetsProperty(data);
        flux.dispatch('SEARCH_RESULTS_SUCCESS', {
          pane,
          items: data.items,
          hasNextPage: !_.isUndefined(data.next)
        }, PUSH_IGNORE);
        return data;
      })
      .catch(function(e) {
        sqNotifications.apiError(e);
        return $q.reject(e);
      })
      .finally(() => {
        flux.dispatch('SEARCH_FINISH', { pane, resultType }, PUSH_IGNORE);
      });
  }

  /**
   * Gets a list of children using the tree endpoint
   *
   * @param pane - The name of the search pane, one of SEARCH_PANES
   * @return {Promise} a promise that will resolve when the results have been fetched
   */
  function fetchAssetResults(pane: string, searchTypes: string [], restrictExploration) {
    const store = getStoreForPane(pane);
    const isPaginating = store.isPaginating;
    const offset = isPaginating ? store.items.length : 0;
    const currentAsset = store.currentAsset;
    const resultType = SEARCH_RESULT_TYPES.ASSETS;
    const { types } = store.getSearchFilters(searchTypes);
    const workbookId = sqWorkbenchStore.stateParams.workbookId;

    const getTreePromise = _.isEmpty(currentAsset)
      ? sqTreesApi.getTreeRootNodes({
        scopedTo: workbookId,
        limit: SEARCH_CHILDREN_PER_PAGE,
        offset,
        properties: [`${SeeqNames.Properties.TreeType}!=${CREATED_BY_SEEQ_WORKBENCH}`]
      }, { cancellationGroup })
      : sqTreesApi.getTree({
        scopedTo: workbookId,
        id: currentAsset,
        offset,
        limit: SEARCH_CHILDREN_PER_PAGE
      }, { cancellationGroup });

    return getTreePromise
      .then((response: any) => {
        let breadcrumbs = [HOME_BREADCRUMB];
        const filteredItems = restrictExploration ?
          _.filter(response.data.children, child => _.includes(types, child.type)) : response.data.children;
        if (response.data.item) {
          breadcrumbs = breadcrumbs.concat(response.data.item.ancestors).concat([response.data.item]);
        }

        flux.dispatch('SEARCH_SET_BREADCRUMBS', { pane, breadcrumbs }, PUSH_IGNORE);
        flux.dispatch('SEARCH_RESULTS_SUCCESS', {
          pane,
          items: _.map(filteredItems, child => _.assign(child,
            { ancestors: _.concat(response.data.item?.ancestors ?? [], response.data.item ?? []) })
          ),
          hasNextPage: !_.isUndefined(response.data.next)
        }, PUSH_IGNORE);
        return response.data;
      })
      .catch((e) => {
        sqNotifications.apiError(e);
        return $q.reject(e);
      })
      .finally(() => flux.dispatch('SEARCH_FINISH', { pane, resultType }, PUSH_IGNORE));
  }

  /**
   * Helper function that returns the correct store based on the pane.
   *
   * @param {String} pane - The name of the search pane, one of SEARCH_PANES
   * @return {Object} The search store that is used for the specified pane
   */
  function getStoreForPane(pane) {
    return pane === SEARCH_PANES.MAIN ? sqSearchStore : sqModalSearchStore;
  }
}
