import angular from 'angular';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { UserInputV1, UsersApi } from '@/sdk';

angular.module('Sq.Workbench')
  .factory('sqWorkbench', sqWorkbench);

export type WorkbenchService = ReturnType<typeof sqWorkbench>;

function sqWorkbench(sqWorkbenchStore: WorkbenchStore, sqUsersApi: UsersApi) {
  const service = {
    get,
    set
  };

  return service;

  /**
   * Get the current user's workbench state. If there is no state, it returns an empty object.
   * @returns {Object} state for the current user's workbench.
   */
  function get() {
    if (sqWorkbenchStore.currentUser.workbench) {
      return JSON.parse(sqWorkbenchStore.currentUser.workbench).state;
    }
    return {};
  }

  /**
   * Set the workbench state for the current user
   * @param {Object} state - The workbench state object
   * @returns {Promise} A promise that will resolve when the current user's workbench state has been set.
   */
  function set(state) {
    const workbenchData = {
      workbench: JSON.stringify({
        version: WORKBENCH_SCHEMA_VERSION,
        state
      })
    } as UserInputV1;
    return sqUsersApi.updateUser(workbenchData, { id: sqWorkbenchStore.currentUser.id });
  }
}

export const WORKBENCH_SCHEMA_VERSION = 1;
