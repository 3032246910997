import _ from 'lodash';
import bind from 'class-autobind-decorator';
import { CustomConditionActions } from '@/investigate/customCondition/customCondition.actions';
import { CapsuleGroupCapsule } from '@/investigate/customCondition/customCondition.module';
import { ConditionFormulaService } from '@/investigate/customCondition/conditionFormula.service';
import { NotificationsService } from '@/services/notifications.service';
import { InvestigateStore } from '@/investigate/investigate.store';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { CustomConditionStore } from '@/investigate/customCondition/customCondition.store';
import { TrendActions } from '@/trendData/trend.actions';
import { TrackService } from '@/track/track.service';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { CapsuleGroupStore } from '@/investigate/customCondition/capsuleGroup.store';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { DEBOUNCE, DISPLAY_MODE } from '@/main/app.constants';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { PREVIEW_ID } from '@/trendData/trendData.module';

@bind
export class CustomConditionController {
  color = this['color']; // Created from the title component
  form: ng.IFormController = this['form']; // Created from the template

  id = this['id'];
  name = this['name'];
  scopedTo = this['scopedTo'];
  maximumDuration = this['maximumDuration'];
  isLoading = this['isLoading'];
  capsules: CapsuleGroupCapsule[] = this['capsules'];
  hasAtLeastOneCapsule: boolean = this['hasAtLeastOneCapsule'];
  displayMode = this['displayMode'];

  DISPLAY_MODE = DISPLAY_MODE;
  PREVIEW_ID = PREVIEW_ID;

  constructor(
    public $scope: ng.IScope,
    public $state: ng.ui.IStateService,
    public $q: ng.IQService,
    public $timeout: ng.ITimeoutService,
    public sqTrack: TrackService,
    public sqInvestigateActions: InvestigateActions,
    public sqInvestigateStore: InvestigateStore,
    public sqTrendCapsuleStore: TrendCapsuleStore,
    public sqNotifications: NotificationsService,
    public sqCustomConditionActions: CustomConditionActions,
    public sqCustomConditionStore: CustomConditionStore,
    public sqCapsuleGroupStore: CapsuleGroupStore,
    public sqToolRunner: ToolRunnerService,
    public sqTrendActions: TrendActions,
    public sqConditionFormula: ConditionFormulaService) {

    $scope.$listenTo(sqCustomConditionStore, this.setCustomConditionVars);
    $scope.$listenTo(sqCapsuleGroupStore, this.setCapsuleGroupVars);
    $scope.$listenTo(sqInvestigateStore, ['displayMode'], this.setInvestigateVars);
  }

  toolName = TREND_TOOLS.CUSTOM_CONDITION;
  toolStore = this.sqCustomConditionStore;
  setSearchName = _.partial(this.sqInvestigateActions.setSearchName, TREND_TOOLS.CUSTOM_CONDITION);
  workbookId = this.$state.params.workbookId;
  close = _.flow(this.sqTrendActions.unselectAllCapsules, this.sqInvestigateActions.close);
  onColorChange = color => this.color = color;

  searchInProgress = false;
  runPreview = _.debounce(() => {
    if (!this.searchInProgress && this.sqInvestigateStore.activeTool === this.toolName) {
      if (_.isNil(this.sqTrendCapsuleStore.editingId)) {
        this.sqTrendActions.setEditModeForCapsuleSet(this.id);
      }

      this.preview();
    }
  }, DEBOUNCE.PREVIEW);

  /**
   * Angular component lifecycle hook called before the component is destroyed
   */
  $onDestroy() {
    this.sqTrendActions.removePreviewCapsules();
    this.runPreview.cancel();
    this.sqTrendActions.cancelPreviewCapsules();
    this.sqTrendActions.fetchTableAndChartCapsules();
  }

  /**
   * Syncs sqCustomConditionStore and view-model properties
   */
  setCustomConditionVars() {
    this.id = this.sqCustomConditionStore.id;
    this.name = this.sqCustomConditionStore.name;
    this.maximumDuration = _.pick(this.sqCustomConditionStore.maximumDuration, ['value', 'units']);

    this.$timeout(() => this.runPreview(), 1);
  }

  /**
   * Syncs sqCapsuleGroupStore and view-model properties
   */
  setCapsuleGroupVars() {
    this.isLoading = this.sqCapsuleGroupStore.isLoading;
    this.capsules = this.sqCapsuleGroupStore.capsules;
    this.hasAtLeastOneCapsule = this.sqCapsuleGroupStore.hasAtLeastOneCapsule;

    this.$timeout(() => this.runPreview(), 1);
  }

  /**
   * Syncs sqInvestigateStore and view-model properties
   */
  setInvestigateVars() {
    this.displayMode = this.sqInvestigateStore.displayMode;

    this.$timeout(() => this.runPreview(), 1);
  }

  /**
   * Generates the condition with properties
   */
  execute() {
    this.searchInProgress = true;
    return this.$q.resolve()
      .then(() => this.sqCustomConditionActions.fetchPropertiesAndBuildFormula())
      .then(formula =>
        this.sqToolRunner.panelExecuteCondition(
          this.sqCustomConditionStore.name,
          formula,
          {},
          this.sqCustomConditionStore.configParams,
          this.sqCustomConditionStore.id,
          this.color,
          { closeOnSuccess: false } // Call our close method instead so that all capsules are deselected
        )
      )
      .then(() => {
        this.sqTrack.doTrack('Workbench_Tool', 'Custom Condition', 'completed');
        this.close();
      })
      .catch((e) => {
        this.sqTrack.doTrack('Workbench_Tool', 'Custom Condition', 'error');
        this.$q.reject(_.tap(e, () => this.sqNotifications.error(e.toString())));
      })
      .finally(() => this.searchInProgress = false);
  }

  /**
   * Sends a preview to the chart (without properties)
   *
   * @returns {Promise} when preview is complete
   */
  preview() {
    if (this.form.$invalid || this.capsules.length === 0) {
      this.sqTrendActions.displayEmptyPreviewCapsuleLane(); // Note: the empty lane isn't shown until it succeeds once
      return this.$q.resolve();
    }

    return this.$q.resolve(this.capsules)
      .then(c => _.map(c, ({ startTime, endTime }) => ({ startTime, endTime, properties: [] })))
      .then(c => this.sqConditionFormula.removeInvalidCapsules(c))
      .then(c => this.sqConditionFormula.makeCapsulesUnique(c))
      .then(c => this.sqConditionFormula.conditionFormula(c))
      .then(formula =>
        this.sqTrendActions.generatePreviewCapsules(
          formula, {},
          this.sqCustomConditionStore.id,
          this.color,
          true // usePost - Formulas can become large enough to exceed node's max http header size
        )
      );
  }

  /**
   * Returns true if the execute/submit button should be disabled
   */
  isExecuteDisabled() {
    return this.form.$invalid || this.form.$pending
      || this.searchInProgress
      || !this.hasAtLeastOneCapsule
      || !this.sqInvestigateActions.hasValidName(this.name);
  }
}
