import React from 'react';
import _ from 'lodash';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { SeeqNames } from '@/main/app.constants.seeqnames';

export interface FormulaHelpFromCustomPackageProps {
  packageId: string;
  packageVersion: string;
  packageInstallerName: string;
  packageCreatorName: string;
  packageCreatorContactInfo: string;
  packageCreatedAt: string;
  packageUpdatedAt: string;
  docCreatedAt: string;
  docUpdatedAt: string;
}

export const FormulaHelpFromCustomPackage: React.FunctionComponent<FormulaHelpFromCustomPackageProps> = ({
  packageId,
  packageVersion,
  packageInstallerName,
  packageCreatorName,
  packageCreatorContactInfo,
  packageCreatedAt,
  packageUpdatedAt,
  docCreatedAt,
  docUpdatedAt
}) => {

  const { t } = useTranslation();

  const isHelpTextFromCustomPackage = !_.isUndefined(packageId) && packageId !== SeeqNames.Properties.SeeqPackageName;

  const information = [
    { VALUE: packageId, KEY: 'PACKAGE_NAME' },
    { VALUE: packageVersion, KEY: 'PACKAGE_VERSION' },
    { VALUE: packageInstallerName, KEY: 'PACKAGE_INSTALLER' },
    { VALUE: packageCreatorName, KEY: 'PACKAGE_CREATOR' },
    { VALUE: packageCreatorContactInfo, KEY: 'PACKAGE_CREATOR_CONTACT' },
    { VALUE: packageCreatedAt, KEY: 'PACKAGE_CREATED_AT' },
    { VALUE: packageUpdatedAt, KEY: 'PACKAGE_UPDATED_AT' },
    { VALUE: docCreatedAt, KEY: 'DOCUMENT_CREATED_AT' },
    { VALUE: docUpdatedAt, KEY: 'DOCUMENT_UPDATED_AT' },
  ];

  return (
    isHelpTextFromCustomPackage &&
    <>
      <h2>{t('FORMULA.DOCUMENTATION.PACKAGE_INFORMATION')}</h2>
      {_.map(information, ({ VALUE, KEY }) => (
        !_.isUndefined(VALUE) && <div key={KEY}>{t(`FORMULA.DOCUMENTATION.${KEY}`, { VALUE })}</div>
      ))}
    </>
  );
};
