import _ from 'lodash';
import angular from 'angular';
import jQuery from 'jquery';
import enTranslation from '@/../public/resources/en.json';
import { LOCALES } from '@/main/app.constants';
import 'moment/min/locales.js';
import moment from 'moment';

const dependencies = [
  'flux',
  'ngSanitize',
  'ngAnimate',
  'pascalprecht.translate'
];

export let BROWSER_LOCALE: string;
export let BROWSER_LANG: string;

// We do not want jQuery falling back to using eval() when inserting HTML as that opens us up to all sorts of XSS
// vulnerabilities. See CRAB-22878 for more info.
jQuery.globalEval = _.noop;

function isTranslateKey(maybeTranslateKey: string): boolean {
  return _.startsWith(maybeTranslateKey, 'UNITS_BY_CATEGORY') || /^[A-Z0-9._]+$/.test(maybeTranslateKey);
}

// MessageFormat can break when passed certain character combinations, and it errors out before the
// standard angular-translate handler can catch it, so we catch it here and just return the raw string.
// We will want to rethink this approach when we are rid of angular.
function translateInstantDecorator($provide) {
  $provide.decorator('$translate', function($delegate, $q) {
    const newTranslate = function(translationId, interpolateParams, interpolationId, defaultTranslationText,
      forceLanguage, sanitizationStrategy) {
      if (isTranslateKey(translationId)) {
        return $delegate(translationId, interpolateParams, interpolationId, defaultTranslationText, forceLanguage,
          sanitizationStrategy);
      }

      return $q.resolve(translationId);
    };

    newTranslate.instant = function(translationId, interpolateParams, interpolationId, forceLanguage,
      sanitizeStrategy) {
      if (isTranslateKey(translationId)) {
        return $delegate.instant(translationId, interpolateParams, interpolationId, forceLanguage, sanitizeStrategy);
      }

      return translationId;
    };

    for (const name in $delegate) {
      if (name !== 'instant') {
        newTranslate[name] = $delegate[name];
      }
    }
    return newTranslate;
  });
}

angular.module('Sq.Core.Configuration', dependencies)
  // This will likely need to be somewhere configurable eventually
  .constant('BROWSER_LOCALE', _.head(window.navigator.languages) || window.navigator.language)
  .config(function(
    $provide: ng.auto.IProvideService,
    $translateProvider: ng.translate.ITranslateProvider,
    $compileProvider: ng.ICompileProvider,
    $locationProvider: ng.ILocationProvider,
    $httpProvider: ng.IHttpProvider,
    $animateProvider,
    fluxProvider,
    BROWSER_LOCALE) {
    // For now we always want to use the browser's locale for moment's formatting
    moment.locale(BROWSER_LOCALE);

    // For now we use simplified language files, but at some point we may want a language file per variant
    const BROWSER_LANG = BROWSER_LOCALE.split('-')[0];
    $provide.constant('BROWSER_LANG', BROWSER_LANG);

    fluxProvider.autoInjectStores(true);
    fluxProvider.setImmutableDefaults({
      immutable: process.env.NODE_ENV !== 'production', // Speed up production by not freezing objects
      asynchronous: process.env.NODE_ENV !== 'test', // Changes to the tree emit synchronously
      pure: process.env.NODE_ENV !== 'test' // Allows dispatches to still trigger events even if value does not change
    });

    $translateProvider.useStaticFilesLoader({
      prefix: 'resources/',
      suffix: '.json'
    });
    if (process.env.NODE_ENV !== 'test') {
      // Load the default translation statically to avoid an HTTP request
      $translateProvider.translations(LOCALES.EN, enTranslation);
    }

    $translateProvider.preferredLanguage(BROWSER_LANG);
    $translateProvider.fallbackLanguage(LOCALES.EN);
    $translateProvider.useMessageFormatInterpolation();
    $translateProvider.useSanitizeValueStrategy('sanitizeParameters');

    // Disable Angular debug data when in production mode
    if (process.env.NODE_ENV === 'production') {
      $compileProvider.debugInfoEnabled(false);
    }
    $compileProvider.preAssignBindingsEnabled(true);

    $locationProvider.html5Mode(true);

    // Allow selective disabling of animation via .ng-animate-disabled class and that spinning elements hide quickly
    // (http://stackoverflow.com/questions/24617821/stop-angular-animation-from-happening-on-ng-show-ng-hide)
    $animateProvider.classNameFilter(/^(?:(?!(ng-animate-disabled|fa-spin)).)*$/);

    // Ensure that defaults.transformResponse is an array so that we can always concat to it
    if (!_.isArray($httpProvider.defaults.transformResponse)) {
      $httpProvider.defaults.transformResponse = [];
    }

    $httpProvider.useApplyAsync(process.env.NODE_ENV !== 'test');
  })
  .config(translateInstantDecorator)
  .run(function($injector) {
    BROWSER_LOCALE = $injector.get('BROWSER_LOCALE');
    BROWSER_LANG = $injector.get('BROWSER_LANG');
  });
