import React from 'react';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { CHART_TYPES } from '@/hybrid/tableBuilder/tableViewer/Chart.molecule';
import Select from 'react-select';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { FormControl } from 'react-bootstrap';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';

const chartSettingsBindings = bindingsDefinition({
  tableData: prop<any[][]>(),
  settings: prop.optional<ChartSettingsInterface>(),
  changeCallback: prop<(settings: {}) => void>(),
  seriesNamesOptions: prop<any[]>(),
  xAxisOptions: prop<any[]>(),
  yAxisOptions: prop<any[]>()
});

export interface ChartSettingsInterface {
  title: string;
  legend: boolean;
  dataLabels: boolean;
  categoryLabels: boolean;
  chartType: string;
  categoryColumns: number[];
  rows: number[];
  columns: number[];
  showSettings?: boolean;
  position?: { x: number, y: number };
}

export const defaultSettings = {
  title: '',
  legend: false,
  dataLabels: false,
  categoryLabels: false,
  rows: Array.from(Array(100).fill(1, 0, 100).keys()),
  columns: [1],
  chartType: 'pie',
  categoryColumns: [0],
  showSettings: false,
  position: { x: 100, y: 100 }
};

export const ChartSettings: SeeqComponent<typeof chartSettingsBindings> = (props) => {
  const { tableData, settings, changeCallback, seriesNamesOptions, xAxisOptions, yAxisOptions } = props;
  if (!tableData || tableData.length === 0) {
    return <div />;
  }

  const { t } = useTranslation();
  const setChartTitle = title => changeCallback({ title });
  const setShowLegend = showLegend => changeCallback({ legend: showLegend });
  const setShowDataLabels = showDataLabels => changeCallback({ dataLabels: showDataLabels });
  const setShowCategoryLabels = showCategoryLabels => changeCallback({ categoryLabels: showCategoryLabels });
  const setSelectedRows = selectedOptions => changeCallback({ rows: selectedOptions.map(option => option.value) });
  const setSelectedColumns = selectedOptions => changeCallback({ columns: selectedOptions.map(option => option.value) });
  const setChartType = selectedChartType => changeCallback({ chartType: selectedChartType });
  const setCategoryColumns = selectedOptions => changeCallback({ categoryColumns: selectedOptions.map(option => option.value) });

  const chartTypesOptions = CHART_TYPES.map(singleChart => ({ value: singleChart.id, label: singleChart.label }));

  return <div className="settingsContainer">
    <div className="mb10">
      <label>{t('TABLE_BUILDER.CHART_VIEW_SETTINGS.TYPE')}</label>
      <Select
        defaultValue={chartTypesOptions.find(option => option.value === settings.chartType)}
        options={chartTypesOptions}
        onChange={e => setChartType(e.value)}
        classNamePrefix="chartViewTypeSelector react-select"
        key="chartViewTypeSelector"
      />
    </div>
    <div className="mb10">
      <FormControl placeholder={t('TABLE_BUILDER.CHART_VIEW_SETTINGS.TITLE')} type="text" defaultValue={settings.title} onChange={e => setChartTitle(e.target.value)} />
    </div>
    <div className="mb10">
      <Checkbox id="chartShowLegend" key="chartShowLegend" isChecked={settings.legend} onChange={e => setShowLegend(e.target.checked)} label='TABLE_BUILDER.CHART_VIEW_SETTINGS.SHOW_LEGEND' />
      <Checkbox id="chartShowDataLabels" key="chartShowDataLabels" isChecked={settings.dataLabels} onChange={e => setShowDataLabels(e.target.checked)} label='TABLE_BUILDER.CHART_VIEW_SETTINGS.SHOW_DATA_LABELS' />
      <Checkbox id="chartShowXLabels" key="chartShowCategoryLabels" isChecked={settings.categoryLabels} onChange={e => setShowCategoryLabels(e.target.checked)} label='TABLE_BUILDER.CHART_VIEW_SETTINGS.SHOW_CATEGORY_LABELS' />
    </div>

    <div className="mb10">
      <label>{t('TABLE_BUILDER.CHART_VIEW_SETTINGS.SERIES_NAME')}</label>
      <Select
        isMulti={true}
        value={settings.categoryColumns.map(index => seriesNamesOptions.find(item => item.value === index))}
        options={seriesNamesOptions}
        onChange={setCategoryColumns}
        classNamePrefix="chartViewSeriesNamesSelector react-select"
        key="chartViewSeriesNamesSelector"
      />
    </div>
    <div className="mb10">
      <label>{t('TABLE_BUILDER.CHART_VIEW_SETTINGS.X_AXIS')}</label>
      <Select
        isMulti={true}
        value={settings.columns.map(index => xAxisOptions.find(item => item.value === index))}
        options={xAxisOptions}
        onChange={setSelectedColumns}
        classNamePrefix="chartViewXAxisSelector react-select"
        key="chartViewXAxisSelector"
      />
    </div>
    <div className="mb10">
      <label>{t('TABLE_BUILDER.CHART_VIEW_SETTINGS.Y_AXIS')}</label>
      <Select
        isMulti={true}
        value={settings.rows.map(index => yAxisOptions.find(item => item.value === index))}
        options={yAxisOptions}
        onChange={setSelectedRows}
        classNamePrefix="chartViewYAxisSelector react-select"
        key="chartViewYAxisSelector"
      />
    </div>
  </div>;
};
