import React, { useState } from 'react';
import bind from 'class-autobind-decorator';
import { useLogger } from '@/hybrid/core/useLogger.hook';

interface ErrorBoundaryProps {
  fallback: (error: Error, reset: () => void) => React.ReactNode;
  onError: (error: Error, errorInfo: React.ErrorInfo) => void;
}

interface ErrorBoundaryState {
  error: Error;
}

@bind
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = { error: null };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.props.onError(error, errorInfo);
  }

  reset() {
    this.setState({ error: null });
  }

  render() {
    return this.state.error
      ? (
        <div className='flexColumnContainer flexCenter height-maximum width-maximum'>
          {this.props.fallback(this.state.error, this.reset)}
        </div>
      )
      : this.props.children;
  }
}

/**
 * General purpose mechanism to catch and display React errors
 */
export const ErrorBoundaryWithLogging: React.FunctionComponent<{ fallback: ErrorBoundaryProps['fallback'], onError?: ErrorBoundaryProps['onError'] }> =
  ({ fallback, onError, children }) => {
    const logger = useLogger();
    const logError = (error: Error, { componentStack }: React.ErrorInfo) => {
      logger.error(logger.format`Unhandled React Error: ${error}\nat${componentStack}`);
    };
    return (
      <ErrorBoundary fallback={fallback} onError={(error, reactError) => {
        logError(error, reactError);
        onError?.(error, reactError);
      }}>
        {children}
      </ErrorBoundary>
    );
  };

/**
 * A handy utility component for testing that the boundary is working correctly
 */
export const Bomb: React.FunctionComponent<{ unstable?: boolean, armed?: boolean, message?: string }> =
  ({ unstable = false, armed = true, message = 'There was an explosion!' }) => {
    const [explode, setExplode] = useState(false);
    if (armed && (explode || unstable)) {
      throw new Error(message);
    }
    return (
      <span
        style={{
          backgroundColor: armed ? 'pink' : 'lightgreen',
          padding: '4px',
          margin: `4px`,
          cursor: 'pointer'
        }}
        onClick={() => setExplode(true)}
      >
      {armed ? '💣' : '😇'}
    </span>
    );
  };
