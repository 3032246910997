import _ from 'lodash';
import angular from 'angular';
import moment from 'moment-timezone';
import jQuery from 'jquery';
import template from './timebar.directive.html';
import { D3Service } from '@/services/d3.service';
import { DurationStore } from '@/trendData/duration.store';
import { DateTimeService } from '@/datetime/dateTime.service';
import { DomClassListService } from '@/services/domClasslist.service';
import { DEBOUNCE } from '@/main/app.constants';
import { UtilitiesService } from '@/services/utilities.service';

/**
 * @file The timebar directive displays a graphical representation that shows the relationship
 * between the display range, investigate range, and visible capsules. It also enables adjustment
 * of the display range via mouse drag.
 */
angular.module('Sq.TrendViewer').directive('sqTimebar', sqTimebar);

function sqTimebar(
  sqD3: D3Service,
  sqUtilities: UtilitiesService,
  sqDurationStore: DurationStore,
  sqDateTime: DateTimeService,
  sqDomClassList: DomClassListService) {

  return {
    restrict: 'E',
    template,
    controller: 'TimebarCtrl',
    controllerAs: 'ctrl',
    scope: {},
    bindToController: {
      updateDisplayRangeTimes: '&',
      displayRange: '&',
      investigateRange: '&',
      rangeEditingEnabled: '<',
      regions: '&',
      timezone: '<'
    },
    link: linker
  };

  function linker(scope, element, attrs, ctrl) {
    let timebar, scale, xAxis, svg, mainRect, axisGroup, regionsGroup, rect;
    let numberTicks, regions, selectorGroup, selectorRange, drag, selectorBackground;
    const minDisplayRange = moment.duration(10, 'seconds');
    const minRegionWidth = 2;
    const borderWidth = 2;
    const handleOffset = 3;
    const tickTextDisplayWidth = 125;
    let positionClickInProgress = false;

    // Put resize on scope so that it can be called by resize-notify.
    scope.resize = _.debounce(resize, DEBOUNCE.MEDIUM);
    setup();

    scope.$listenTo(sqDurationStore, update);
    scope.$watch('ctrl.rangeEditingEnabled', update);
    scope.$watch('ctrl.timezone', update);
    scope.$watchCollection('ctrl.regions', update);
    scope.$on('$destroy', ctrl.allRegionUpdateOnResize.cancel);

    function resize() {
      update();
      ctrl.allRegionUpdateOnResize();
    }

    /**
     * Updates the timebar visualization. Should be called whenever the timebar is resized or
     * the underlying data changes.
     */
    function update() {
      updateScale();
      updateRegions();
      updateSelectorRange();
      updateSelectorCenterHandle();
      updateSelectorLeftHandle();
      updateSelectorRightHandle();
    }

    /**
     * Sets up the timebar scale, axis, and appends the SVG elements that never change.
     */
    function setup() {
      timebar = sqD3.select(element.find('div.timebar')[0]);
      scale = sqD3.time.scale();
      xAxis = sqD3.svg.axis().scale(scale).orient('bottom').tickFormat(customTimeFormat());
      svg = timebar.append('svg').attr({ height: 32 });
      svg.on('mousedown', beginPositionClick).on('mouseup', endPositionClick);
      mainRect = svg.append('rect').attr({ class: 'outer', height: 12, x: 1, y: 4, rx: 2, ry: 2 });
      selectorBackground = svg.append('rect').attr({ class: 'background', height: 12, x: 1, y: 4, rx: 2, ry: 2 });
      axisGroup = svg.append('g').attr({ class: 'axis', transform: 'translate(0 12)' });
      regionsGroup = svg.append('g').attr('class', 'regions').append('svg');
      selectorGroup = svg.append('g').attr('class', 'selector');
    }

    /**
     * Updates the axis scale (i.e. the domain and range) based on the investigate range
     * start/end and the current width of the timebar.
     */
    function updateScale() {
      rect = timebar.node().getBoundingClientRect();
      numberTicks = Math.floor(rect.width / tickTextDisplayWidth);
      mainRect.attr('width', Math.max(rect.width - borderWidth, 0));
      scale.domain([ctrl.investigateRange.start, ctrl.investigateRange.end]);
      scale.range([0, rect.width - borderWidth - 1]);
      xAxis.ticks(numberTicks).scale(scale);
      axisGroup.call(xAxis);
      axisGroup.selectAll('g line').attr('transform', 'translate(0, 5) scale(1 0.5)');
      axisGroup.selectAll('g text').attr('transform', 'translate(0, 1)');
    }

    /**
     * Updates the regions displayed on the timebar. Regions are represented by semi-transparent colored
     * rectangles. The width of the region corresponds to its duration, though the minimum width of a region
     * is set to 2 pixels so it will be visible. Currently, only capsules are represented with regions.
     */
    function updateRegions() {
      regions = regionsGroup.selectAll('rect').data(ctrl.regions);

      regions.enter().append('rect').attr({ height: 10, y: 5 });

      regions
        .attr('x', function(d) {
          return scale(d.start);
        })
        .attr('width', function(d) {
          return Math.max(scale(d.end) - scale(d.start), minRegionWidth);
        })
        .attr('fill', function(d) {
          return d.color;
        })
        .attr('opacity', function(d) {
          return d.opacity;
        });

      regions.exit().remove();
    }

    /**
     * Update the range of the selector. If the range is an empty array, then
     * the selector is not displayed.
     */
    function updateSelectorRange() {
      selectorRange = ctrl.rangeEditingEnabled ? [ctrl.displayRange] : [];
    }

    /**
     * Update the center selector handle
     */
    function updateSelectorCenterHandle() {
      const centerHandle = selectorGroup.selectAll('rect.selector').data(selectorRange);

      centerHandle.enter().append('rect').attr({ class: 'selector', height: 12, y: 4 })
        .call(dragBehavior()).on('wheel', zoom);

      centerHandle
        .attr('x', function(d) {
          return scale(d.start);
        })
        .attr('width', function(d) {
          return Math.max(0, scale(d.end) - scale(d.start));
        });

      centerHandle.exit().remove();

      updateSelectorBackground(centerHandle);
    }

    /**
     * Updates the color and location of the selector background rectangle, which is a separate
     * SVG rectangle from the actual selector so it can be displayed behind everything else.
     * @param centerHandle A reference to the center handle d3 selection
     */
    function updateSelectorBackground(centerHandle) {
      const selectorDisplayed = !_.isNull(centerHandle.node());
      const x = selectorDisplayed ? centerHandle.attr('x') : 0;
      const width = selectorDisplayed ? centerHandle.attr('width') : 0;
      selectorBackground.attr({ x, width });
    }

    /**
     * Update the left selector handle
     */
    function updateSelectorLeftHandle() {
      const leftHandle = selectorGroup.selectAll('rect.leftSelector').data(selectorRange);

      leftHandle.enter().append('rect').attr({ class: 'leftSelector', width: 3, height: 18, y: 1 })
        .call(dragBehavior()).on('wheel', zoom);

      leftHandle.attr('x', function(d) {
        return scale(d.start) - handleOffset;
      });

      leftHandle.exit().remove();
    }

    /**
     * Update the right selector handle
     */
    function updateSelectorRightHandle() {
      const rightHandle = selectorGroup.selectAll('rect.rightSelector').data(selectorRange);

      rightHandle.enter().append('rect').attr({ class: 'rightSelector', width: 3, height: 18, y: 1 })
        .call(dragBehavior()).on('wheel', zoom);

      rightHandle.attr('x', function(d) {
        return scale(d.end);
      });

      rightHandle.exit().remove();
    }

    /**
     * This function lazily creates and provides a singleton drag behavior that is used by the
     * left, center, and right drag handles.
     * @returns {Object} A d3 drag behavior
     */
    function dragBehavior() {
      let origDragX, origDragStartX, origDragEndX, dragStart, dragEnd, dragBoth, cursor;

      if (!drag) {
        drag = sqD3.behavior.drag()
          .on('dragstart', function() {
            let classlist;

            // Record drag start x location as well as start and end times when drag began
            origDragX = (<any>sqD3.event).sourceEvent.pageX - jQuery(svg.node()).offset().left;
            origDragStartX = scale(ctrl.displayRange.start);
            origDragEndX = scale(ctrl.displayRange.end);

            // Determine whether start and/or end should be adjusted based on which selector initiated the drag
            classlist = sqDomClassList.classList((<any>sqD3.event).sourceEvent.target);

            dragStart = classlist.contains('leftSelector');
            dragEnd = classlist.contains('rightSelector');
            dragBoth = classlist.contains('selector');

            // Force the cursor globally so it remains on even if the mouse leaves the timebar control
            cursor = dragBoth ? 'globalCursorPointer' : 'globalCursorEastWest';
            forceCursor(cursor);

            sqUtilities.preventIframeMouseEventSwallow();
          })
          .on('dragend', function() {
            clearCursor(cursor);
            sqUtilities.restoreIframeMouseEventBehavior();
          })
          .on('drag', function() {
            let start, end, offset;

            // If the user dragged, then cancel the position click operation
            positionClickInProgress = false;

            // Apply the drag offset to compute new display range start and end values.
            offset = (<any>sqD3.event).x - origDragX;
            start = scale.invert(origDragStartX + ((dragStart || dragBoth) ? offset : 0));
            end = scale.invert(origDragEndX + ((dragEnd || dragBoth) ? offset : 0));

            // Ensure the investigate range is not exceeded
            start = Math.max(start, ctrl.investigateRange.start);
            end = Math.min(end, ctrl.investigateRange.end);

            // Don't let the duration shrink unless explicitly dragging the start handle
            if (!dragStart) {
              start = Math.min(start, ctrl.investigateRange.end - ctrl.displayRange.duration);
            }

            // Don't let the duration shrink unless explicitly dragging the end handle
            if (!dragEnd) {
              end = Math.max(end, ctrl.investigateRange.start + ctrl.displayRange.duration);
            }

            // Ensure the end is always greater than the start by adjusting the side opposite the drag direction
            if (start >= end) {
              if (offset < 0) {
                end = start + minDisplayRange;
              } else {
                start = end - minDisplayRange;
              }
            }

            // Inform the controller that the display range has changed
            scope.$apply(function() {
              ctrl.updateDisplayRangeTimes(start, end);
            });
          });
      }

      return drag;
    }

    function zoom() {
      const zoomFactor = 0.2;
      const unitDelta = ((<any>sqD3.event).deltaY > 0) ? 1 : ((<any>sqD3.event).deltaY < 0) ? -1 : 0;
      const min = ctrl.displayRange.start.valueOf();
      const max = ctrl.displayRange.end.valueOf();
      const dx = max - min;

      // Compute scale factor based on where the mouse is located so the zoom centers on the mouse position
      const offsetX = (<any>sqD3.event).pageX - jQuery(svg.node()).offset().left;
      const mouseXValue = scale.invert(offsetX).valueOf();
      const leftFactor = (mouseXValue - min) / dx;
      const rightFactor = (max - mouseXValue) / dx;

      // Compute new start and end
      let start = min - (dx * zoomFactor * leftFactor * unitDelta);
      let end = max + (dx * zoomFactor * rightFactor * unitDelta);

      // Ensure the investigate range is not exceeded
      start = Math.max(start, ctrl.investigateRange.start);
      end = Math.min(end, ctrl.investigateRange.end);

      if ((end > start) && (end - start > minDisplayRange)) {
        scope.$apply(function() {
          ctrl.updateDisplayRangeTimes(start, end);
        });
      }
    }

    /**
     * Creates a custom time format object that is used to control axis scale time formatting.
     * @returns {Object} A custom time format
     */
    function customTimeFormat() {
      const formats = {
        millisecond: '%I:%M:%S.%L %p',
        second: '%_I:%M:%S %p',
        minute: '%_I:%M %p',
        hour: '%_I:%M %p',
        day: '%b %e',
        week: '%b %e',
        month: '%b \'%y',
        year: '%Y'
      };

      if (!sqDateTime.displayMonthDay()) {
        formats.day = '%e. %b';
        formats.week = '%e. %b';
      }

      if (!sqDateTime.display12HrClock()) {
        formats.millisecond = '%H:%M:%S.%L';
        formats.second = '%H:%M:%S';
        formats.minute = '%H:%M';
        formats.hour = '%H:%M';
      }

      return sqD3.time.format.multi([
        [formats.millisecond, function(d) {
          return d.getMilliseconds();
        }],

        [formats.second, function(d) {
          return d.getSeconds();
        }],

        [formats.minute, function(d) {
          return d.getMinutes();
        }],

        [formats.hour, function(d) {
          return d.getHours();
        }],

        [formats.day, function(d) {
          return d.getDay() && d.getDate() !== 1;
        }],

        [formats.week, function(d) {
          return d.getDate() !== 1;
        }],

        [formats.month, function(d) {
          return d.getMonth();
        }],

        [formats.year, function() {
          return true;
        }]
      ]);
    }

    /**
     * Start the position click operation by flagging it as in-progress when the mouse goes down
     */
    function beginPositionClick() {
      if (ctrl.rangeEditingEnabled) {
        positionClickInProgress = true;
      }
    }

    /**
     * If the position click is in progress when the mouse comes back up, then compute and apply
     * the new location for the display range.
     */
    function endPositionClick() {
      let start, end;

      if (positionClickInProgress) {
        positionClickInProgress = false;

        const clickPixel = (<any>sqD3.event).pageX - jQuery(svg.node()).offset().left;
        const clickVal = scale.invert(clickPixel).valueOf();
        const duration = ctrl.displayRange.duration.valueOf();

        if (clickVal - duration / 2 <= ctrl.investigateRange.start.valueOf()) {
          start = ctrl.investigateRange.start.valueOf();
          end = start + duration;
        } else if (clickVal + duration / 2 >= ctrl.investigateRange.end.valueOf()) {
          end = ctrl.investigateRange.end.valueOf();
          start = end - duration;
        } else {
          start = clickVal - duration / 2;
          end = start + duration;
        }

        scope.$apply(function() {
          ctrl.updateDisplayRangeTimes(start, end);
        });
      }
    }
  }

  /**
   * Force a cursor to be globally applied
   * @param {String} cursor - A valid CSS cursor name
   */
  function forceCursor(cursor) {
    sqDomClassList.classList(jQuery('body')[0]).add(cursor);
  }

  /**
   * Clear a globally applied cursor
   * @param {String} cursor - A valid CSS cursor name
   */
  function clearCursor(cursor) {
    sqDomClassList.classList(jQuery('body')[0]).remove(cursor);
  }
}
