import { AssetChild, PATH_SEPARATOR } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import _ from 'lodash';
import { API_TYPES } from '@/main/app.constants';
import { IconType } from '@/hybrid/core/Icon.atom';
import { FormulaEditorParam } from '@/hybrid/formula/FormulaParametersTable.molecule';

export function getPath(item) {
  return _.join(_.map(_.filter(item?.ancestors, { type: API_TYPES.ASSET }), 'name'), PATH_SEPARATOR);
}

export function getNameWithoutPath(item) {
  return _.indexOf(item.name, PATH_SEPARATOR) < 0
    ? item.name
    : item.name.substring(_.lastIndexOf(item.name, PATH_SEPARATOR) + 1);
}

export function getNameWithPath(name, path) {
  return `${path}${_.isEmpty(path) ? '' : PATH_SEPARATOR}${name}`;
}

export function getDisplayPath(item) {
  return _.join(_.map(item?.ancestors, 'name'), ' » ');
}

export function findChild(asset, column): AssetChild {
  return _.find(asset.children, child => child?.name === column);
}

export function getColumnsHelper(assets) {
  return _.chain(assets)
    .flatMap('children')
    .uniqBy('name')
    .sortBy(child => _.toLower(child.name))
    .value();
}

/**
 * This function returns one child for a given column name if it can find one.
 */
export function findAtLeastOneChild(assets: any, columnName: string) {
  const childrenByName = _.reduce(assets,
    (result, asset) => _.concat(result, _.find(asset.children, { name: columnName })), []);
  return _.chain(childrenByName)
    .filter(child => !_.isNil(child))
    .filter(child => child.columnType === 'Calculation' ? child.id : (child.id || child.itemId))
    .first()
    .value();
}

export function getCellIcon(assetChild): string {
  if (assetChild?.columnType === 'Calculation') {
    return 'fc-formula';
  } else if (!assetChild || (!assetChild.id && !assetChild.itemId)) {
    return 'fa-plus-circle';
  } else {
    return 'fa-check-circle';
  }
}

export function getCellIconType(assetChild): IconType {
  if (assetChild?.columnType === 'Calculation') {
    return 'theme';
  } else if (!assetChild || (!assetChild.id && !assetChild.itemId)) {
    return 'gray';
  } else if (assetChild.manuallyAdded) {
    return 'color';
  }
  return 'theme';
}

/**
 * This function maps the "formula parameters" that contain column names to the actual entities that correspond to
 * the asset and replaces the parameter with the actual itemId. This is done as part of saving the asset group.
 *
 * The API expects a parameter to look like this: {name: <name>, id: <id of item>}  which is different from what
 * we use in "Formula" as there the parameter looks like this: {name: <name>, item: {id: <id>, name: <name of
 * item>}} (FormulaEditorParam)
 */
export function mapCalculationParamsToAssetChild(asset, params: FormulaEditorParam[]) {
  const errors = [];
  const mappings = _.chain(params)
    .map(({ name, item }) => {
      if (item.id === item.name) { // then it's an asset group column and needs to be replaced
        const child: any = findChild(asset, item.name);
        if (child && (child.itemId || child.id)) {
          return ({ id: child.itemId ? child.itemId : child.id, name });
        } else {
          errors.push({ assetName: asset.name, columnName: item.name });
        }
      } else {
        return { name, id: item.id };
      }
    })
    .compact()
    .value();
  return { errors, mappings };
}
