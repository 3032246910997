import React from 'react';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { TrendStore } from '@/trendData/trend.store';
import { TREND_VIEWS } from '@/trendData/trendData.module';
import { TrackService } from '@/track/track.service';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { ToolbarButton } from '@/hybrid/core/ToolbarButton.molecule';

const capsuleTimeGroupButtonBindings = bindingsDefinition({
  isSmall: prop<boolean>(),
  sqWorksheetActions: injected<WorksheetActions>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqTrendStore: injected<TrendStore>(),
  sqTrack: injected<TrackService>()
});

export const CapsuleTimeGroupButton: SeeqComponent<typeof capsuleTimeGroupButtonBindings> = (props) => {
  const { isSmall } = props;
  const {
    sqWorksheetActions,
    sqWorksheetStore,
    sqTrendStore,
    sqTrack
  } = useInjectedBindings(capsuleTimeGroupButtonBindings);
  const { t } = useTranslation();

  const view = useFluxPath(sqTrendStore, () => sqTrendStore.view);
  const { capsuleGroupMode } = useFlux(sqWorksheetStore);

  const toggleCapsuleGroupModeAndTrack = () => {
    sqTrack.doTrack('Trend', 'Toggle Capsule Group Mode');
    sqWorksheetActions.toggleCapsuleGroupMode();
  };

  return (view === TREND_VIEWS.CAPSULE || view === TREND_VIEWS.CHAIN) && (
    <ToolbarButton
      onClick={toggleCapsuleGroupModeAndTrack}
      active={capsuleGroupMode}
      isSmall={isSmall}
      label="TOOLBAR.GROUP"
      icon="fc-group"
      tooltipText="TOOLBAR.GROUP_TOOLTIP"
      tooltipPlacement="top"
    />
  );
};
