import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import { BasePluginDependencies } from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { PluginDependencies } from '@/hybrid/annotation/ckEditorPlugins/plugins/PluginDependencies';
import icon from '@/hybrid/reportEditor/ckIcons/ckeditor5-seeq-fixed-width.svg';

export class FixedWidth extends Plugin {
  [editor: string]: any;
  static setup = {
    name: 'FixedWidth',
    plugin: FixedWidth,
    toolbar: 'FixedWidth'
  };

  init() {
    this.defineToolbar();
  }

  defineToolbar() {
    const editor = this.editor;
    editor.ui.componentFactory.add('FixedWidth', (locale) => {
      const view = new ButtonView(locale);
      const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);
      view.set({
        label: deps.$translate.instant('REPORT.EDITOR.FIXED_WIDTH'),
        icon,
        tooltip: true
      });
      view.on('execute', () => {
        editor.fire('toggleFixedWidth');
      });
      return view;
    });
  }
}
