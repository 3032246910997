import angular from 'angular';
import _ from 'lodash';
import { AnnotationStore } from '@/annotation/annotation.store';
import { UtilitiesService } from '@/services/utilities.service';
import { AnnotationActions } from '@/annotation/annotation.actions';
import { ITEM_ICONS } from '@/main/app.constants';

angular.module('Sq.Annotation').controller('AnnotationIconCtrl', AnnotationIconCtrl);

function AnnotationIconCtrl(
  $scope: ng.IScope,
  sqAnnotationStore: AnnotationStore,
  sqAnnotationActions: AnnotationActions,
  sqUtilities: UtilitiesService
) {
  const vm = this;
  vm.iconClass = ITEM_ICONS.ANNOTATION;

  $scope.$listenTo(sqAnnotationStore, ['annotatedItemIds'], syncAnnotationVars);

  /**
   * Syncs the sqAnnotationStore and view-model properties.
   */
  function syncAnnotationVars() {
    vm.id = sqAnnotationStore.annotatedItemIds[vm.item.id];
    const isCapsuleId = !sqUtilities.validateGuid(vm.item.id);
    if (isCapsuleId) {
      // If the item ID is a capsule ID, then display the annotation for the capsule.
      vm.onClick = sqAnnotationActions.showEntry;
      vm.tooltip = 'JOURNAL.TOOLTIP.ANNOTATION';
    } else {
      // If the item ID is a signal, scalar, or condition ID, then display the annotations overview
      vm.onClick = _.flow(sqAnnotationActions.closeEntry, sqAnnotationActions.showJournalTab);
      vm.tooltip = 'JOURNAL.TOOLTIP.ANNOTATION_LIST';
    }
  }
}
