import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import _ from 'lodash';
import { Icon } from '@/hybrid/core/Icon.atom';
import { LOAD_STATUS, WorkbookStore } from '@/workbook/workbook.store';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { SEARCH_MODES, SEARCH_PANES } from '@/search/search.module';
import { SearchResult } from '@/hybrid/search/SearchResult.molecule';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { ASSET_GROUP_VIEW } from '@/worksheet/worksheet.module';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { AuthorizationService } from '@/services/authorization.service';
import { SearchActions } from '@/search/search.actions';

const searchGroupBindings = bindingsDefinition({
  loadStatus: prop<string>(),
  label: prop<string>(),
  mode: prop<string>(),
  searchMode: prop<string>(),
  searchTypes: prop<string[]>(),
  scopeIds: prop<string[]>(),
  restrictExploration: prop<boolean>(),
  onErrorClick: prop<() => void>(),
  onClickItem: prop<(item) => void>(),
  items: prop<any[]>(),
  pane: prop<string>(),
  indicateSelection: prop.optional<boolean>(),
  selectedItemId: prop.optional<string>(),
  searchResultIcons: prop<(item) => JSX.Element>(),
  noItemsMessage: prop.optional<string>(),
  sqWorksheetActions: injected<WorksheetActions>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqSystemConfiguration: injected<SystemConfigurationService>(),
  sqAuthorization: injected<AuthorizationService>(),
  sqWorkbookStore: injected<WorkbookStore>(),
  sqSearchActions: injected<SearchActions>(),
  isSelectingAsset: prop.optional<boolean>()
});

export const SearchGroup: SeeqComponent<typeof searchGroupBindings> = (props) => {
  const {
    items,
    loadStatus,
    label,
    mode,
    pane,
    onErrorClick,
    onClickItem,
    searchResultIcons,
    searchMode,
    searchTypes,
    scopeIds,
    restrictExploration,
    noItemsMessage,
    indicateSelection = false,
    selectedItemId,
    isSelectingAsset = false
  } = props;

  const {
    sqWorksheetActions,
    sqSystemConfiguration,
    sqWorksheetStore,
    sqSearchActions,
    sqAuthorization,
    sqWorkbookStore
  } = useInjectedBindings(searchGroupBindings);

  const { t } = useTranslation();
  const isAssetGroupView = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.view.key === ASSET_GROUP_VIEW.key);
  const showCreateAssetGroupButton = searchMode === SEARCH_MODES.ASSET_GROUPS
    && sqSystemConfiguration.assetGroupEditorEnabled
    && pane === SEARCH_PANES.MAIN
    && sqAuthorization.canModifyWorkbook(sqWorkbookStore.workbook);

  const renderAssetGroupButton = (
    <TextButton
      testId="createButton"
      label="SEARCH_DATA.CREATE"
      onClick={() => {
        sqWorksheetActions.setReturnViewKey(sqWorksheetStore.view.key);
        sqWorksheetActions.setView(ASSET_GROUP_VIEW.key);
      }}
      disabled={isAssetGroupView}
      size="sm"
      extraClassNames="sq-btn-xs pr5 mr5" />
  );

  const nonClickableHeading = (
    <span className="flexColumnContainer flexSpaceBetween flexAlignCenter heading text-interactive fs15">
      {t(`SEARCH_DATA.${label}`)}
      {showCreateAssetGroupButton && renderAssetGroupButton}
    </span>
  );

  return (
    <div>
      {loadStatus === LOAD_STATUS.LOADING &&
      <div>
        {nonClickableHeading}
        <div className="pt10 pb10 flexNoGrowNoShrink flexColumnContainer flexCenter">
          <IconWithSpinner spinning={true} large={true} />
          <span className="mb5 ml5 mt5">{t((`SEARCH_DATA.LOADING_${label}`))}</span>
        </div>
      </div>}

      {loadStatus === LOAD_STATUS.ERROR &&
      <div>
        {nonClickableHeading}
        <div className="flexColumnContainer sq-text-danger cursorPointer pt3 pb6" onClick={onErrorClick}>
          <Icon icon="fa-exclamation-triangle" type="danger" extraClassNames="flexCenter pl5 pr10 pt2" />
          <div className="flexFill pl5">{t(`SEARCH_DATA.LOAD_${label}_FAILED`)}<br />
            <span className="text-underline">{t(`SEARCH_DATA.RETRY`)}</span>
          </div>
        </div>
      </div>}

      {items.length === 0 && (mode === searchMode || mode === SEARCH_MODES.OVERVIEW) && noItemsMessage && pane === SEARCH_PANES.MAIN &&
      <div>
        {nonClickableHeading}
        <div className="m5">
          {t(noItemsMessage)}
        </div>
      </div>}

      {items.length > 0 && (mode === searchMode || mode === SEARCH_MODES.OVERVIEW) &&
      <div>
        {mode === SEARCH_MODES.OVERVIEW &&
        <div className="flexColumnContainer flexSpaceBetween flexAlignCenter heading">
          <span className="text-interactive flexFill fs15"
            onClick={() => sqSearchActions.setMode(pane, searchMode, searchTypes, restrictExploration, scopeIds)}>
            {t(`SEARCH_DATA.${label}`)}
          </span>
          {showCreateAssetGroupButton
            ? renderAssetGroupButton
            : <Icon
              icon="fa-chevron-right"
              extraClassNames="text-interactive pl5 pr5"
              onClick={() => sqSearchActions.setMode(pane, searchMode, searchTypes, restrictExploration, scopeIds)} />}
        </div>}

        {_.map(items, (item: any) => (
          <SearchResult
            isSelectingAsset={isSelectingAsset}
            key={item.id}
            item={item}
            icons={searchResultIcons(item)}
            onClickItem={() => {
              onClickItem(item);
              sqWorksheetActions.setReturnViewKey(sqWorksheetStore.view.key);
            }}
            isSelected={indicateSelection && selectedItemId === item.id}
          />
        ))}
      </div>}
    </div>
  );
};

