import _ from 'lodash';
import angular from 'angular';

const dependencies = [
  'Sq.Core',
  'Sq.Search',
  'Sq.TrendData',
  'Sq.Services.PendingRequests',
  'Sq.Services.Modal',
  'Sq.PluginHost',
  'Sq.Services.SystemConfiguration'
];

/**
 * Identifies a plugin-supplied icon as being an SVG path instead of an icon class
 */
export const SVG_PATH = 'svgpath:';

/**
 * The worksheet module manages the currently selected tab (i.e. search or assets)
 * as well as the currently visible view. The available worksheet views that are
 * available for display on the worksheet are defined here.
 */
angular.module('Sq.Worksheet', dependencies);

export const WORKSHEET_TABSETS =
  { sidebar: ['search', 'investigate', 'annotate', 'reportComments', 'reportConfig'] };
// Define valid targets for a view
export const WORKSHEET_TARGET = {
  WORKSHEET: 'WORKSHEET',
  DATA_PANEL: 'DATA_PANEL'
};

/**
 * Define available worksheet views here.
 *
 * Optional properties:
 *
 *   useSizeFromRender - an optional boolean property that flags a view as having non-scaling content
 *   (i.e. content that does not expand or collapse to fill the display pane). A view that sets this property
 *   to true should also add a ".useSizeFromRender" CSS class to the container of content in the HTML
 *   template. Doing these two things enables the screenshot service to properly screenshot the content and
 *   the report editor to know when content has a fixed size.
 */
export interface WorksheetView {
  key: string;
  target: string;
  name: string;
  icon: string;
  selectedItemsRealm: string;
  default?: boolean;
  useSizeFromRender?: boolean;
  hideFromViewSelector?: boolean;
}

export const WORKSHEET_VIEWS: WorksheetView[] = [{
  key: 'TREND',
  target: 'DATA_PANEL',
  name: 'TREND',
  icon: 'fc fc-trend',
  selectedItemsRealm: 'TREND',
  default: true
}, {
  key: 'SCATTER_PLOT',
  target: 'DATA_PANEL',
  name: 'SCATTER.XY_PLOT',
  icon: 'fc fc-scatterplot',
  selectedItemsRealm: 'TREND'
}, {
  key: 'TREEMAP',
  target: 'DATA_PANEL',
  name: 'TREEMAP.TITLE',
  icon: 'fc fc-treemap',
  selectedItemsRealm: 'TREND'
}, {
  key: 'TABLE',
  target: 'DATA_PANEL',
  name: 'TABLE_BUILDER.TITLE',
  icon: 'fc fc-table',
  useSizeFromRender: true,
  selectedItemsRealm: 'TABLE'
}];

export const ASSET_GROUP_VIEW: WorksheetView = {
  key: 'ASSET_GROUP_EDITOR',
  target: 'DATA_PANEL',
  name: 'ASSET_GROUP_EDITOR.TITLE',
  icon: 'fa fa-fw fa-cubes',
  selectedItemsRealm: 'TREND',
  hideFromViewSelector: true
};

// Manually constructed from WORKSHEET_VIEWS to give type safety
export const WORKSHEET_VIEW = {
  TREND: 'TREND',
  SCATTER_PLOT: 'SCATTER_PLOT',
  TREEMAP: 'TREEMAP',
  TABLE: 'TABLE',
  ASSET_GROUP_EDITOR: 'ASSET_GROUP_EDITOR'
};
// Create a constant for each available sidebar tab
export const WORKSHEET_SIDEBAR_TAB = _.transform(WORKSHEET_TABSETS.sidebar, (result, tabname: string) => {
  result[_.replace(_.upperCase(tabname), ' ', '_')] = tabname;
}, {});
