import React, { useRef } from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Chart } from '@/hybrid/tableBuilder/tableViewer/Chart.molecule';
import { Overlay } from '@/hybrid/core/Overlay.atom';
import { ChartSettings, ChartSettingsInterface } from './ChartSettings.molecule';
import _ from 'lodash';
import { Icon } from '@/hybrid/core/Icon.atom';

const tableViewerBindings = bindingsDefinition({
  data: prop<any[]>(),
  columns: prop<any[]>(),
  isTransposed: prop<boolean>(),
  setChartViewSettings: prop<(settings: any) => void>(),
  storeSettings: prop<any>(),
  testId: prop<string>()
});

const transpose = matrix => matrix[0].map((x, i) => matrix.map(x => x[i]));

const dataToTable = (data, columns, t) => [columns.map(item => item.header || t(item.shortTitle))].concat(
  data.map(item => item.cells.map(singleCell => ({ value: singleCell.value, uom: singleCell.units })))
);

const convertToNumber = (stringToBeParsed) => {
  if (!_.isNaN(_.toNumber(stringToBeParsed))) {
    return _.toNumber(stringToBeParsed);
  }
  if (stringToBeParsed[stringToBeParsed.length - 1] === '%') {
    return _.toNumber(stringToBeParsed.substr(0, stringToBeParsed.length - 1));
  }
};

const getCategoryName = (data: any[][], index: number, categoryColumns: number[]): string =>
  categoryColumns.map(selectedColumn => data[index][selectedColumn].value).join(' ');

const filterTableData = (data: any[][], settings: ChartSettingsInterface, isTransposed: boolean): any[][] | null => {
  if (!settings.rows.length || !settings.columns.length) return null;
  const filterRows = !isTransposed ? settings.rows : settings.columns;
  const filterColumns = !isTransposed ? settings.columns : settings.rows;

  // -1 is the index used for the ALL option in select so filtering should also account for that
  return data
    .filter((row, rowIndex) => rowIndex === 0 || _.includes(filterRows, -1) || _.includes(filterRows, rowIndex))
    .map(row => row.filter(
      (cell, columnIndex) => columnIndex === 0 || _.includes(filterColumns, -1) || _.includes(filterColumns,
        columnIndex)))
    .map((row, rowIndex) => row.map((cell, columnIndex) => {
      if (rowIndex === 0) return cell;
      return columnIndex === 0 ?
        getCategoryName(data, rowIndex, settings.categoryColumns) || '' :
        { value: convertToNumber(cell.value), uom: cell.uom || '' };
    }));
};

export const TableViewer: SeeqComponent<typeof tableViewerBindings> = (props) => {
  const { data, columns, isTransposed, storeSettings, setChartViewSettings, testId } = props;
  if (!data || data.length === 0) {
    return <div />;
  }

  const headerRef = useRef();
  const { t } = useTranslation();

  const tableData = dataToTable(data, columns, t);
  const columnOptions = columns.map((element, index) => ({
    value: index,
    label: tableData[0][index],
    isText: ['assets', 'string'].includes(element.style) || element.type === 'text'
  }));
  const seriesNamesOptions = columnOptions.filter(element => element.isText);
  let xAxisOptions = [{ value: -1, label: t('TABLE_BUILDER.CHART_VIEW_SETTINGS.ALL') }].concat(
    columnOptions.filter(element => !element.isText));
  let yAxisOptions = [{ value: -1, label: t('TABLE_BUILDER.CHART_VIEW_SETTINGS.ALL') }].concat(
    tableData.slice(1).map((item, index) => ({
      value: index + 1,
      label: getCategoryName(tableData, index + 1, storeSettings.categoryColumns)
    })));
  let filteredData = filterTableData(tableData, storeSettings, isTransposed);
  const { position, showSettings, ...settingsForChart } = storeSettings;

  if (isTransposed) {
    if (filteredData) {
      filteredData = transpose(filteredData);
    }
    const swapValueHolder = xAxisOptions;
    xAxisOptions = yAxisOptions;
    yAxisOptions = swapValueHolder;
  }

  return <Overlay testId={testId} savePosition={position => setChartViewSettings({ position: { ...position } })}
    extraClassNames="tableViewer" moveRef={headerRef.current} initialPosition={storeSettings.position}>
    <div className="header">
      <div className="fillAll" ref={headerRef}>{t('TABLE_BUILDER.CHART_VIEW_TITLE')}</div>
      <div className="alignRight settingsButton"
        onClick={e => setChartViewSettings({ showSettings: !storeSettings.showSettings })}>
        <Icon icon="fc-gear" type="color" color="#FFFFFF" />
      </div>
    </div>
    <div className="flexColumnContainer">
      <div className="content">
        <Chart tableData={filteredData} settings={settingsForChart} />
      </div>
      {storeSettings.showSettings &&
      <ChartSettings
        changeCallback={setChartViewSettings}
        tableData={tableData}
        seriesNamesOptions={seriesNamesOptions}
        yAxisOptions={yAxisOptions}
        xAxisOptions={xAxisOptions}
        settings={storeSettings}
      />}
    </div>
  </Overlay>;
};
