import _ from 'lodash';
import angular from 'angular';
import { BaseItemStoreService } from '@/trendData/baseItemStore.service';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { DurationStore } from '@/trendData/duration.store';
import { UtilitiesService } from '@/services/utilities.service';
import { InvestigateStore } from '@/investigate/investigate.store';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import {
  CAPSULE_PANEL_TREND_COLUMNS,
  ITEM_TYPES,
  PREVIEW_ID,
  TREND_PANELS,
  TREND_VIEWS,
  BREAK_SIZE
} from '@/trendData/trendData.module';
import { NUMBER_CONVERSIONS } from '@/main/app.constants';
import { INITIALIZE_MODE } from '@/services/stateSynchronizer.service';
import { TrendStore } from '@/trendData/trend.store';
import { NumberHelperService } from '@/core/numberHelper.service';
import { ItemDecoratorService } from '@/trendViewer/itemDecorator.service';
import { ColumnHelperService } from '@/trendViewer/columnHelper.service';
import { CapsuleV1 } from '@/sdk';
import { NotificationsService } from '@/services/notifications.service';

/**
 * A store for containing capsules which can be displayed on the chart. A capsule is composed of a start and end
 * time
 *
 * This store is augmented with additional functionality from sqBaseItemStore.
 */
angular.module('Sq.TrendData').store('sqTrendCapsuleStore', sqTrendCapsuleStore);

export type TrendCapsuleStore = ReturnType<typeof sqTrendCapsuleStore>['exports'];

function sqTrendCapsuleStore(
  $injector: ng.auto.IInjectorService,
  $translate: ng.translate.ITranslateService,
  sqBaseItemStore: BaseItemStoreService,
  sqTrendSeriesStore: TrendSeriesStore,
  sqDurationStore: DurationStore,
  sqNumberHelper: NumberHelperService,
  sqColumnHelper: ColumnHelperService,
  sqUtilities: UtilitiesService,
  sqNotifications: NotificationsService
) {
  let totalYValue = 1;
  const store = {
    initialize(initializeMode) {
      const saveState = this.state && initializeMode !== INITIALIZE_MODE.FORCE;
      this.state = this.immutable(_.defaults({
        // Avoid clearing state that is not dehydrated when the store is re-initialized. Async calls will repopulate
        // these properties as needed
        items: saveState ? this.state.get('items') : [],
        chartItems: saveState ? this.state.get('chartItems') : [],
        selectedCapsules: [],
        stitchBreaks: [],
        stitchTimes: [],
        capsulesTimings: [],
        calculatedChartItems: saveState ? this.state.get('calculatedChartItems') : [],
        previewChartItem: saveState ? this.state.get('previewChartItem') : {},
        stitchDetailsSet: false
      }, sqBaseItemStore.COMMON_PROPS));
    },

    exports: {
      /**
       * Returns the capsules for use on the chart.
       */
      get chartItems() {
        return this.state.get('chartItems');
      },

      /**
       * Returns the start, end, and duration of the time periods to be displayed
       */
      get stitchTimes() {
        return this.state.get('stitchTimes');
      },

      /**
       * Returns the start, end and conditionId of capsules that should be displayed
       */
      get capsulesTimings() {
        return this.state.get('capsulesTimings');
      },

      /**
       * Returns the time periods to be excluded in the format required by Highcharts
       */
      get stitchBreaks() {
        return this.state.get('stitchBreaks');
      },

      /**
       * Returns an array containing the capsule items that are selected
       */
      get selectedCapsules() {
        return this.state.get('selectedCapsules');
      },

      /**
       * Returns the Id of the capsule set that is being edited.
       */
      get editingId() {
        return this.state.get('editingId');
      },

      /**
       * Returns an Object describing a capsule set (so that it can be updated upon scrolling)
       */
      get previewCapsuleDefinition() {
        return this.state.get('previewCapsulesDefinition');
      },

      /**
       * Returns an Object describing the preview chart item.
       */
      get previewChartItem() {
        return this.state.get('previewChartItem');
      },

      /**
       * Returns a boolean stating if the stitch details have been set or not
       */
      get stitchDetailsSet() {
        return this.state.get('stitchDetailsSet');
      },

      /**
       * Determines if there are non-zero length capsules to be displayed in the display range
       *
       * @returns {Boolean} True if non-zero length capsules are in displayed in the display range
       */
      hasDisplayedCapsules() {
        const selectedItems = this.state.get('selectedCapsules');
        return _.some(selectedItems.length ? selectedItems : this.state.get('items'),
          capsule => capsule.startTime >= sqDurationStore.displayRange.start.valueOf()
            && capsule.startTime < sqDurationStore.displayRange.end.valueOf()
            && (capsule.endTime - capsule.startTime > 0 || _.isNil(capsule.endTime)));
      },

      /**
       * Finds one of the chart capsules
       *
       * @param {string} id - The id of the chart item
       */
      findChartItem(id) {
        return _.find(this.state.get('chartItems'), { id: sqUtilities.getCertainId(id) });
      },

      /**
       * Finds the capsule that encompasses the time passed in.
       *
       * @param {string} id - The id of the chartItem
       * @param {number} time - The time value that the capsule must encompass
       * @returns {Object} An item suitable for selecting or unselecting. Undefined if not found.
       */
      findChartCapsule(id, time) {
        const capsule = _.find(this.state.get('chartItems', { id: sqUtilities.getCertainId(id) }, 'capsules'),
          (capsule: any) => time >= capsule.startTime && time <= capsule.endTime);

        if (capsule) {
          const selected = this.isSelected(capsule.id) ||
            _.find(this.state.get('selectedCapsules'), (selectedCapsule: any) =>
              splitUniqueId(capsule.id).capsuleSetId === splitUniqueId(selectedCapsule.id).capsuleSetId &&
              (capsule.startTime === selectedCapsule.startTime || capsule.endTime === selectedCapsule.endTime));

          return _.assign({}, capsule, { itemType: ITEM_TYPES.CAPSULE, selected });
        }
      },

      getUniqueId,

      splitUniqueId
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     * @returns {Object} The dehydrated items.
     */
    dehydrate() {
      return _.assign(
        _.omit(this.state.serialize(),
          ['items', 'chartItems', 'stitchBreaks', 'stitchTimes', 'capsulesTimings', 'calculatedChartItems', 'previewChartItem', 'setStitchDetailsSet']),
        {
          // Need to keep a history of uncertain capsules so we can match them up with 'new' uncertain capsules as they
          // grow or turn certain.
          items: _.chain(this.state.get('items'))
            .filter('isUncertain')
            .map(
              item => _.pick(item,
                ['id', 'startTime', 'endTime', 'isChildOf', 'childType', 'isUncertain', 'itemType', 'cursorKey']))
            .value()
        }
      );
    },

    /**
     * Re-creates the dehydrated capsules.
     * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
      this.updateChartItems();
    },

    /**
     * Color is dependent on CapsuleSet store.
     */
    rehydrateWaitFor: ['sqTrendCapsuleSetStore'],

    handlers: {
      TREND_ADD_CAPSULES: 'addCapsules',
      TREND_SET_CHART_CAPSULES: 'setChartItems',
      TREND_ADD_CHART_CAPSULES: 'addChartItems',
      TREND_RECOMPUTE_CHART_CAPSULES: 'updateChartItems',
      TREND_SET_EDITING_CAPSULE_SET_ID: 'setEditingId',
      TREND_SET_CHART_CAPSULES_PREVIEW: 'setPreviewChartItem',
      TREND_REMOVE_CHART_CAPSULES_PREVIEW: 'removePreviewChartItem',
      TREND_DISPLAY_EMPTY_CAPSULE_PREVIEW: 'displayEmptyCapsulePreview',
      TREND_REMOVE_ITEMS: 'removeCapsules',
      TREND_SET_SELECTED: 'setCapsuleSelected',
      TREND_UNSELECT_ALL_CAPSULES: 'unselectAllCapsules',
      TREND_REPLACE_CAPSULE_SELECTION: 'replaceCapsuleSelection',
      TREND_SET_STITCH_BREAKS: 'setStitchBreaks',
      TREND_SET_STITCH_TIMES: 'setStitchTimes',
      TREND_SET_STITCH_DETAILS: 'setStitchDetails',
      TREND_SET_STITCH_DETAILS_SET: 'setStitchDetailsSet'
    },

    /**
     * Adds capsules that are shown in the data table.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} capsules - Array of capsules to add
     * @param {String} capsules[].id - The id used by the backend to identify this capsule. Not guaranteed
     *   to be unique.
     * @param {String} capsules[].isChildOf - Id of the capsule set to which this capsule belongs
     * @param {String} capsules[].capsuleSetName - name of the capsuleset the capsule belongs to
     * @param {Boolean} capsules[].isReferenceCapsule - True if this is the reference capsule for a visual
     *   search
     * @param {Number} capsules[].startTime - Timestamp of the start of the capsule
     * @param {Number} capsules[].endTime - Timestamp of the end of the capsule
     * @param {String} capsules[].similarity - Similarity metric for profile search capsules
     * @param {Object} capsules[].statistics - key/value of user-defined statistics
     * @param {Object} numPixels - number of pixels needed to calculate stitch data
     */
    addCapsules({ capsules, numPixels }) {
      const uncertainCapsuleUpdates = _.chain(this.state.get('items'))
        .filter('isUncertain')
        .transform((map, uncertainCapsule: any) => {
          map[uncertainCapsule.id] = _.chain(capsules)
            .filter(['isChildOf', uncertainCapsule.isChildOf])
            .reject(['id', splitUniqueId(uncertainCapsule.id).capsuleId])
            .find((capsule: any) => uncertainCapsule.startTime === capsule.startTime ||
              uncertainCapsule.endTime === capsule.endTime)
            .thru((capsule: any) => capsule ? { ...capsule, id: getUniqueId(capsule.isChildOf, capsule.id) } : capsule)
            .value();
        }, {})
        .omitBy(_.isUndefined)
        .value();

      const selectedCapsules = _.filter(this.state.get('selectedCapsules'),
        (selectedCapsule: any) => !_.isUndefined(uncertainCapsuleUpdates[selectedCapsule.id]));

      const replacementCapsules = _.map(selectedCapsules, selectedCapsule =>
        _.pick(uncertainCapsuleUpdates[selectedCapsule.id], ['id', 'startTime', 'endTime', 'isUncertain']));

      this.state.set('selectedCapsules',
        _.difference(this.state.get('selectedCapsules'), selectedCapsules).concat(replacementCapsules));

      sqTrendSeriesStore.syncUncertainCapsuleSeries(uncertainCapsuleUpdates);

      this.state.set('items', _.map(capsules, (capsule) => {
        const id = getUniqueId(capsule.isChildOf, capsule.id);

        return this.createItem(id, undefined, ITEM_TYPES.CAPSULE,
          _.assign(_.omit(capsule, ['id']), {
            duration: sqUtilities.getCapsuleDuration(capsule.startTime, capsule.endTime),
            isUncertain: capsule.isUncertain,
            selected: this.isSelected(id),
            cursorKey: capsule.cursorKey
          }));
      }));

      // Chart capsule labels rely on data from the items in the table
      const sqTrendStore = $injector.get<TrendStore>('sqTrendStore');
      if (sqTrendStore.view === TREND_VIEWS.CHAIN) {
        this.setStitchDetails({ numPixels });
      }
      if (!_.isEmpty(sqTrendStore.enabledColumns(TREND_PANELS.CHART_CAPSULES))) {
        this.updateChartItems();
      }
    },

    /**
     * Removes the previewChartItem.
     */
    removePreviewChartItem() {
      if (this.state.get(['previewCapsulesDefinition', 'id'])) {
        this.removeCapsulesByCapsuleSetId(this.state.get(['previewCapsulesDefinition', 'id']));
      }

      this.state.merge({
        editingId: null,
        previewChartItem: {},
        previewCapsulesDefinition: {}
      });

      this.updateChartItems();
    },

    /**
     * Displays an empty Capsule Preview lane.
     */
    displayEmptyCapsulePreview() {
      this.state.set('previewChartItem', { capsules: [], id: this.state.get('editingId') });
      this.updateChartItems();
    },

    /**
     * Sets the preview capsules so they can be displayed. Also stores an Object that defines how to generate those
     * preview capsules so they can be updated when the users scrolls.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.formula - formula used to create the capsules
     * @param {Object[]} payload.parameters - Object array describing the parameters used to create the capsules
     * @param {String} payload.color - the color used to visualize the capsules
     * @param {String} [payload.id] - the id of the capsule set being edited, PREVIEW_ID if it's a new search.
     */
    setPreviewChartItem(payload) {
      const color = payload.color;
      const id = payload.id || PREVIEW_ID;

      this.state.set('previewCapsulesDefinition', {
        formula: payload.formula,
        parameters: payload.parameters,
        color: payload.color,
        id
      });

      this.state.set('previewChartItem', _.assign(payload.capsules, {
        id: _.get(payload.existingCapsuleSet, 'id', PREVIEW_ID),
        color
      }));

      // Preview capsules inherently change a lot so we clear any selected capsules that don't seem to exist any more
      const cursor = this.state.select('selectedCapsules');
      const capsules = _.get(payload, ['capsules', 'capsules'], []);
      _.forEachRight(cursor.get() as any[], (selection, index) => {
        const split = splitUniqueId(selection.id);
        if (split.capsuleSetId === id && !_.some(capsules, c => split.capsuleId === c.id)) {
          cursor.unset(index);
        }
      });

      this.updateChartItems();
    },

    /**
     * Sets the id of the Capsule Set being edited. If any capsules were selected, they are removed.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.id - the id.
     */
    setEditingId(payload) {
      this.state.set('editingId', _.get(payload, 'id', PREVIEW_ID));
      this.state.set('previewCapsulesDefinition', {});
      const selected = this.state.get('selectedCapsules');
      this.removeCapsulesByCapsuleSetId(payload.id);
      const newSelected = this.state.get('selectedCapsules');
      if (_.size(newSelected) !== _.size(selected)) {
        this.updateChartItems();
      }
    },

    /**
     * Sets capsules to be shown in chart.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} capsuleSets - Array of capsule sets and their capsules
     * @param {String} capsuleSets[].id - Id of the capsule set to which this capsules belong
     * @param {String} capsuleSets[].color - Color for this set of capsules
     * @param {Boolean} capsuleSets[].selected - True if the entire series is selected, false otherwise
     * @param {Object[]} capsuleSets[].capsules - Array of capsules to add. Must be ordered by startTime.
     * @param {String} capsuleSets[].capsules[].id - The id used by the backend to identify this capsule.
     *    Not guaranteed to be unique.
     * @param {Number} capsuleSets[].capsules[].start - Timestamp in nanoseconds of the start of the capsule
     * @param {Number} capsuleSets[].capsules[].end - Timestamp in nanoseconds of the end of the capsule
     * @param {Object[]} capsuleSets[].capsules[].properties - Any additional properties for the capsule. If
     *    a capsule has the count property it is assumed to be an aggregate capsule.
     * @param {Object} numPixels - number of pixels needed to calculate stitch data
     */
    setChartItems({ capsuleSets, numPixels }) {
      this.state.set('calculatedChartItems', _.flatten(capsuleSets));
      const sqTrendStore = $injector.get<TrendStore>('sqTrendStore');
      if (sqTrendStore.view === TREND_VIEWS.CHAIN) {
        this.setStitchDetails({ numPixels });
      }
      this.updateChartItems();
    },

    /**
     * Adds capsules to those that are shown in chart.
     *
     * @param {Object[]} capsuleSets - Array of capsule sets and their capsules
     * @param {String} capsuleSets[].id - Id of the capsule set to which this capsules belong
     * @param {String} capsuleSets[].color - Color for this set of capsules
     * @param {Boolean} capsuleSets[].selected - True if the entire series is selected, false otherwise
     * @param {Object[]} capsuleSets[].capsules - Array of capsules to add. Must be ordered by startTime.
     * @param {String} capsuleSets[].capsules[].id - The id used by the backend to identify this capsule.
     *    Not guaranteed to be unique.
     * @param {Number} capsuleSets[].capsules[].start - Timestamp in nanoseconds of the start of the capsule
     * @param {Number} capsuleSets[].capsules[].end - Timestamp in nanoseconds of the end of the capsule
     * @param {Object[]} capsuleSets[].capsules[].properties - Any additional properties for the capsule. If
     *    a capsule has the count property it is assumed to be an aggregate capsule.
     * @param {Object} numPixels - number of pixels needed to calculate stitch details
     */
    addChartItems({ capsuleSets, numPixels }) {
      const calculatedItems = _.unionBy(_.flatten(capsuleSets), this.state.get('calculatedChartItems'), 'id');

      this.state.set('calculatedChartItems', calculatedItems);
      const sqTrendStore = $injector.get<TrendStore>('sqTrendStore');
      if (sqTrendStore.view === TREND_VIEWS.CHAIN) {
        this.setStitchDetails({ numPixels });
      }
      this.updateChartItems();
    },

    /**
     * Overrides the behavior of TREND_SET_COLOR to take preview and chart items into account.
     *
     * Called when the color for an item changes, should set the color on the item as necessary
     *
     * @param {Object} item - serialized item object
     * @param {Object} color - color that should be set
     * @param {string} [parentId] - what was the id of the parent that triggered the change
     */
    onSetColor(item, color, parentId?) {
      this.getItemCursor(item.id).set('color', color);
      if (parentId) {
        _.forEach({ chartItems: 'capsuleSetId', calculatedChartItems: 'id' }, (key, path) => {
          _.forEach(this.state.get(path), (chartItem, i) => {
            if (chartItem[key] === parentId) {
              this.state.set([path, i, 'color'], color);
            }
          });
        });
      }

      if (item.id === PREVIEW_ID) {
        this.state.merge('previewChartItem', { color });
        this.updateChartItems();
      }
    },

    /**
     * Updates the zones and anyCapsulesSelected properties for each chartItem, both of which are based on
     * selectedCapsules. Each set of non-overlapping capsules is its own line series in the
     * chart. Groups capsules together by capsule set, adding small vertical offsets if capsules from the same
     * capsule set overlap, and allowing larger spaces between different capsule sets.  Capsules are only rendered as
     * selected if the are entirely visible in the display range.
     * This function also manages the correct display of preview capsules. If a new Search is
     * performed then the resulting preview capsules are simply appended. If an existing search is edited then the
     * original capsules are omitted and replaced with the preview capsules.
     */
    updateChartItems() {
      let allCapsules;
      let index = -1;
      let calculatedItems = this.state.get('calculatedChartItems');
      const editingId = this.state.get('editingId');
      const previewCapsuleDef = this.state.get('previewCapsulesDefinition');
      const displayPreview = !_.isEmpty(previewCapsuleDef);
      totalYValue = 1;

      if (!_.isUndefined(editingId) && !_.isNull(editingId) && editingId !== PREVIEW_ID && displayPreview) {
        calculatedItems = _.cloneDeep(this.state.get('calculatedChartItems'));
        index = _.findIndex(calculatedItems, { id: editingId });
        calculatedItems.splice(index, 1, this.state.get('previewChartItem'));
        allCapsules = calculatedItems;
      } else if (displayPreview) {
        allCapsules = calculatedItems.concat(this.state.get('previewChartItem'));
      } else {
        allCapsules = calculatedItems;
      }

      const sqTrendCapsuleSetStore = $injector.get<TrendCapsuleSetStore>('sqTrendCapsuleSetStore');
      allCapsules = _.chain(allCapsules)
        .sortBy((condition: any) => sqTrendCapsuleSetStore.findItemIndex(condition.id))
        .sortBy((condition: any) => condition.id === PREVIEW_ID ? 1 : 0)
        .flatMap(condition => prepareConditionForDisplay(condition, this.state.get('items'),
          this.state.get('stitchBreaks'), editingId))
        .compact()
        .value();

      this.state.set('chartItems', allCapsules);
      if (_.isEmpty(allCapsules)) {
        return;
      }

      const fullyVisibleCapsules = _.chain(allCapsules)
        .flatMap('capsules')
        .filter((capsule) => {
          if (!capsule.notFullyVisible) {
            return capsule;
          }
        })
        .value();

      const selectedCapsules = this.state.get('selectedCapsules');
      const selectedIds = _.chain(selectedCapsules)
        .map((selectedCapsule: any) => {
          if (!_.find(fullyVisibleCapsules, { id: selectedCapsule.id })) {
            return;
          }

          const capsuleSetId = splitUniqueId(selectedCapsule.id).capsuleSetId;
          const possibleCapsuleMatches = _.chain(calculatedItems)
            .filter(['id', capsuleSetId])
            .flatMap('capsules')
            .value();

          if (_.find(possibleCapsuleMatches, ['id', splitUniqueId(selectedCapsule.id).capsuleId])) {
            return selectedCapsule.id;
          }

          const maybeCapsule = _.find(possibleCapsuleMatches, (capsule: any) =>
            ((selectedCapsule.startTime * NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND) === capsule.start
              || (selectedCapsule.endTime * NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND) === capsule.end));
          return maybeCapsule ? getUniqueId(capsuleSetId, maybeCapsule.id) : selectedCapsule.id;
        })
        .compact()
        .value();

      this.state.select('chartItems').map((cursor) => {
        cursor.set('anyCapsulesSelected', _.some(selectedIds,
          id => splitUniqueId(id).capsuleSetId === sqUtilities.getCertainId(cursor.get('capsuleSetId'))));

        cursor.set('zones', computeChartZones(cursor.get(), selectedIds));
      });
    },

    /**
     * Augments the sqBaseItemStore functionality of removing items by also handling the removal of selectedCapsules if
     * their parent capsuleSet is also removed.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} payload.items - An array of items to remove
     */
    removeCapsules(payload) {
      this.removeItems(payload);
      // Don't remove child selections in capsule picking mode to avoid the loss of selected capsules
      if (!$injector.get<InvestigateStore>('sqInvestigateStore').isCapsulePickingMode) {
        _.chain(payload.items)
          .filter(['itemType', ITEM_TYPES.CAPSULE_SET])
          .forEach(capsuleSet => this.removeCapsulesByCapsuleSetId(capsuleSet['id']))
          .value();
      }
      const sqTrendStore = $injector.get<TrendStore>('sqTrendStore');
      if (sqTrendStore.view === TREND_VIEWS.CHAIN) {
        this.setStitchDetails({ numPixels: payload.numPixels });
      }

    },

    /**
     * Utility function to remove all selected capsules that belong to the provided capsuleSet
     *
     * @param {String} capsuleSetId - GUID of the capsule set whose capsules should be remove
     */
    removeCapsulesByCapsuleSetId(capsuleSetId) {
      const selectedCapsules = _.clone(this.state.get('selectedCapsules'));
      const removedSelection = _.remove(selectedCapsules, (selectedCapsule: any) =>
        capsuleSetId === splitUniqueId(selectedCapsule.id).capsuleSetId);

      if (this.state.get('selectedCapsules').length !== selectedCapsules.length) {
        this.state.set('selectedCapsules', selectedCapsules);
        _.forEach(removedSelection, c => this.setProperty(c.id, 'selected', false));
      }
    },

    /**
     * Augments the sqBaseItemStore functionality of setting the selected property by also tracking selection status
     * in selectedCapsules and keeping calculatedChartItem selection in sync with capsuleSets.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object} payload.item - The item to select
     * @param {Boolean} payload.selected - Selection status
     */
    setCapsuleSelected(payload) {
      if (payload.item.itemType === ITEM_TYPES.CAPSULE) {
        // In capsule picking mode, the selection is the source of truth for the capsules, not the items (capsule table)
        // array. Otherwise this logic would prevent capsules from being removed from the picked capsules via the
        // [x] buttons in the capsule group component if there happened to be an item in the capsule pane with a start
        // and end time that matches and is from the same condition originally. (It is easy to get into this situation
        // when you pick capsules from a condition derived from the custom condition you are editing). The downside is
        // that this could cause selecting an in progress capsule on the chart could lead to not being selected in the
        // capsules pane (the edge case the following code would otherwise prevent)
        const itemsSourceOfTruth = !$injector.get<InvestigateStore>('sqInvestigateStore').isCapsulePickingMode;
        if (itemsSourceOfTruth && !_.find(this.state.get('items'), ['id', payload.item.id])) {
          // Since the capsule isn't in items (capsule table), `payload.item` must be from `chartCapsules`. If there is
          // a capsule in the items (capsule table) that is almost the same, we will use it as 'the source of truth'.
          // If such a capsule exists then the capsule table and the chart are likely out of sync (i.e., api calls
          // execute at different times) and the current capsule isUncertain or was isUncertain
          const capsuleSetId = splitUniqueId(payload.item.id).capsuleSetId;
          const possibleCapsules = _.filter(this.state.get('items'), ['isChildOf', capsuleSetId]);
          const maybeCapsule = _.find(possibleCapsules, (capsule: any) =>
            payload.item.startTime === capsule.startTime || payload.item.endTime === capsule.endTime);
          payload.item = maybeCapsule || payload.item;
        }

        const index = _.findIndex(this.state.get('selectedCapsules'), ['id', payload.item.id]);
        if (payload.selected && index < 0) {
          this.state.push('selectedCapsules', _.pick(payload.item, ['id', 'startTime', 'endTime', 'isUncertain']));
        } else if (!payload.selected && index >= 0) {
          this.state.splice('selectedCapsules', [index, 1]);
        }

        this.setSelected(payload);
        this.updateChartItems();
      }

      // Keep the selected property of the calculatedChartItems in sync with the capsule series
      // to which they belong. This branch is hit when selecting a capsule set in the series panel only.
      if (payload.item.itemType === ITEM_TYPES.CAPSULE_SET) {
        _.forEach(this.state.get('calculatedChartItems'), (item, i) => {
          if (item.id === payload.item.id) {
            this.state.set(['calculatedChartItems', i, 'selected'], payload.selected);
          }
        });
        this.updateChartItems();
      }
    },

    /**
     * Overwrite the `selectedCapsules` with the provided capsules and update the `items` and `chartItems` to match.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} payload.capsules - Selected capsules
     * @param {String} payload.capsules[].id - capsule's unique id created from `getUniqueId`
     * @param {Number} payload.capsules[].startTime - end of the capsule in ms
     * @param {Number} payload.capsules[].endTime - start of capsule in ms
     * @param {Boolean} payload.capsules[].isUncertain - indicates that the capsule is uncertain
     * @param {Number} payload.capsules[].cursorKey - start of uncertainty
     */
    replaceCapsuleSelection({ capsules }) {
      this.unselectAllCapsules();
      this.state.set('selectedCapsules',
        _.map(capsules, c => _.pick(c, ['id', 'startTime', 'endTime', 'isUncertain', 'cursorKey'])));
      _.forEach(capsules, c => this.setProperty(c.id, 'selected', true));
    },

    /**
     * Unselects all items and chartItems.
     */
    unselectAllCapsules() {
      this.state.set('selectedCapsules', []);
      this.updateChartItems();
      _.forEach(this.state.get('items'), (item, i) => {
        this.state.set(['items', i, 'selected'], false);
      });
    },

    /**
     * Determines if a capsule is selected.
     *
     * @param {String} id - The id of the capsule
     * @returns {Boolean} True if it is selected, false otherwise
     */
    isSelected(id) {
      return _.some(this.state.get('selectedCapsules'), ['id', id]);
    },

    /**
     * Sets the stitch view breaks for the given capsule set
     *
     * @param [Object] stitchBreaks - The array of stitch breaks
     */
    setStitchBreaks({ stitchBreaks }) {
      this.state.set('stitchBreaks', stitchBreaks);
      const sqTrendStore = $injector.get<TrendStore>('sqTrendStore');
      if (!_.isEmpty(sqTrendStore.enabledColumns(TREND_PANELS.CHART_CAPSULES))) {
        this.updateChartItems(); // Chart capsules filter their labels based on stitch breaks
      }
    },

    /**
     * Sets the stitch view times for the given capsule set
     *
     * @param [Object] stitchTimes - The array of stitch times
     */
    setStitchTimes({ stitchTimes }) {
      this.state.set('stitchTimes', stitchTimes);
    },

    /**
     * THIS IS TO EXPOSE THIS STATE FOR TESTING.  IT SHOULD NOT BE USED OUTSIDE OF TESTING PURPOSES
     * @param {Boolean} isSet - the value that states if the stitch details have been set
     */
    setStitchDetailsSet(isSet) {
      this.state.set('stitchDetailsSet', isSet);
    },

    /**
     * Sets the stitch details for a given capsule set
     *
     * @param {Number} numPixels - contains the number of pixels on the the chart
     */
    setStitchDetails({ numPixels }) {
      // payload may be undefined when loading since we don't have the current pixels
      if (!numPixels) {
        this.state.set('stitchDetailsSet', false);
        return;
      }

      // do not create stitch details while capsules are loading as we'd be using old capsules.
      const sqTrendStore = $injector.get<TrendStore>('sqTrendStore');
      if (sqTrendStore.capsulePanelIsLoading) {
        this.state.set('stitchDetailsSet', false);
        return;
      }

      const selectedConditions = _.filter(this.state.get('chartItems'), 'selected');
      const useConditions = selectedConditions.length ? selectedConditions : this.state.get('chartItems');
      const selectedCapsules = _.filter(this.state.get('items'), 'selected');
      const useCapsules = selectedCapsules.length ? selectedCapsules : _.flatMap(useConditions,
        condition => _.map(condition.capsules, capsule => _.assign({ isChildOf: condition.capsuleSetId }, capsule)));

      const times = [];
      const capsulesTimings = _.chain(useCapsules)
        .reject(capsule => capsule.startTime === capsule.endTime)
        .map((capsule: any) => {
          // bound any partially visible capsules to the display range
          let startTime = capsule.startTime;
          if (startTime && startTime < sqDurationStore.displayRange.start.valueOf()) {
            startTime = sqDurationStore.displayRange.start.valueOf();
          }
          let endTime = capsule.endTime;
          if (endTime && endTime > sqDurationStore.displayRange.end.valueOf()) {
            endTime = sqDurationStore.displayRange.end.valueOf();
          }
          return { start: startTime, end: endTime, isChildOf: capsule.isChildOf };
        })
        .sortBy(['start'])
        .forEach((capsule: any) => {
          // If the start time is greater than the last entry, add the start time and the end time to the array
          if (times.length === 0 || capsule.start > times[times.length - 1]) {
            times.push(capsule.start);
            times.push(capsule.end);
          } else if (capsule.end > _.last(times)) {
            times[times.length - 1] = capsule.end;
          }
        })
        .value();

      const stitchTimes = _.transform(_.chunk(times, 2), (result, [start, end]) => {
        result.push({ start, end, duration: end - start });
      });

      if (stitchTimes.length === 0) {
        this.state.merge({
          stitchTimes: [],
          stitchBreaks: [],
          capsulesTimings: [],
          stitchDetailsSet: false
        });
        return;
      }

      if (!_.isEqual(stitchTimes, this.state.get('stitchTimes'))) {
        this.state.set('stitchTimes', stitchTimes);
      }

      if (!_.isEqual(capsulesTimings, this.state.get('capsulesTimings'))) {
        this.state.set('capsulesTimings', capsulesTimings);
      }

      // If the times array doesn't start or end with the display range, make sure that there is a break
      if (_.head(times) !== sqDurationStore.displayRange.start.valueOf()) {
        times.unshift(sqDurationStore.displayRange.start.valueOf());
      } else {
        times.shift();
      }

      if (_.last(times) !== sqDurationStore.displayRange.end.valueOf()) {
        times.push(sqDurationStore.displayRange.end.valueOf());
      } else {
        times.pop();
      }

      const breaks = _.transform(_.chunk(times, 2), function(result, chunk) {
        result.push(_.zipObject(['from', 'to'], chunk));
      });

      if (breaks.length >= numPixels) {
        sqNotifications.warnTranslate('TOO_MANY_BREAKS_CHAIN_VIEW');
        this.state.set('stitchDetailsSet', false);
        return;
      }

      _.forEach(breaks, (thisBreak: any) => thisBreak.breakSize = parseFloat(BREAK_SIZE));

      if (!_.isEqual(breaks, this.state.get('stitchBreaks'))) {
        this.state.set('stitchBreaks', breaks);
      }
      this.state.set('stitchDetailsSet', true);
    }
  };

  return sqBaseItemStore.extend(store);

  /**
   * Generates a unique value that can be used as the id of a capsule.  By combining the capsuleId and capsuleSetId
   * a unique value is guaranteed because a capsule set will not have two capsules with the same capsuleId (which
   * is a hash code of the start time, end time, and properties).
   *
   * @param {String} capsuleSetId - The id of the capsule set to which the capsule belongs
   * @param {String} capsuleId - The id of a capsule that is supplied by the backend
   * @return {String} A unique id
   */
  function getUniqueId(capsuleSetId, capsuleId) {
    return String.prototype.concat(capsuleSetId, '_', capsuleId);
  }

  /**
   * Splits a uniqueID into its constituent parts: capsuleSetId and capsuleId
   *
   * @param id - the uniqueId
   * @see .getUniqueId()
   * @return {Object} object - An object container
   * @return {String} object.capsuleSetId - The id of the capsule set to which the capsule belongs
   * @return {String} object.capsuleId - The id of a capsule that is supplied by the backend
   */
  function splitUniqueId(id) {
    const [capsuleSetId, capsuleId] = id.split('_');
    return { capsuleSetId, capsuleId };
  }

  /**
   * Computes the zones of for a chart item that are selected and therefore colored appropriately.
   *
   * @param {Object} condition - The condition to use for calculating zones
   * @param {String} condition.color - The color of the condition on the trend.
   * @param {Object[]} condition.capsules - The capsules of the condition.
   * @param {String[]} selectedIds - Array of selected capsule ids
   * @return {Object[]} Array of two zones per selected capsule, one zone up to the start time with no special color
   * and then a zone with the item's color going up to the end time of the capsule.
   */

  function computeChartZones(condition, selectedIds) {
    return _.transform(condition.capsules, function(zones, capsule: any) {
      if (_.includes(selectedIds, capsule.id)) {
        zones.push({ value: capsule.startTime });
        zones.push({ value: capsule.endTime, color: condition.color });
      }
    });
  }

  /**
   * Prepares a condition for display on the trend.
   * This function generates a series that is used to visualize the capsule results.
   * Capsules that would otherwise overlap are assigned to new 'rows' to ensure the start and end times of each
   * capsule are properly displayed.
   *
   * To ensure the preview displays properly if no capsules are found an empty row is added to ensure things look
   * right.
   *
   * This function also manages the proper display of uncertain capsules by adding an additional "uncertain" capsule
   * set that overlaps the original capsule set and helps create the start/end boundaries of the outline (Note: the
   * top and bottom outline are achieved by setting the lineHeight for uncertain capsules to a smaller width than the
   * the one of the capsules.)
   *
   * @param {Object} condition - the condition to prepare for display.
   * @param {Object[]} tableCapsules - the capsules that are displayed in the table
   * @param {Object[]} stitchBreaks - the stitch breaks used in chain view
   * @param {String} editingId - the id of the capsule set that is currently being edited.
   * @returns {Object[]} the capsule set prepared for display - as a series with 'zones' to support selection.
   */
  function prepareConditionForDisplay(condition, tableCapsules, stitchBreaks, editingId) {
    const DEFAULT_LINE_WIDTH = 6;
    const TEXT_HEIGHT_IN_PIXELS = 12;
    if (_.isEmpty(condition)) {
      return;
    }

    const isEditing = _.startsWith(condition.id, PREVIEW_ID) || editingId === condition.id;
    const prevEnds = {};
    const displayRangeStart = sqDurationStore.displayRange.start.valueOf();
    const displayRangeEnd = sqDurationStore.displayRange.end.valueOf();

    // add some padding around the preview lane
    if (isEditing) {
      totalYValue += 1;
    }

    const sqItemDecorator = $injector.get<ItemDecoratorService>('sqItemDecorator');
    const sqTrendStore = $injector.get<TrendStore>('sqTrendStore');
    const capsuleColumns = _.chain(CAPSULE_PANEL_TREND_COLUMNS)
      // CAPSULE panel version must be used because they have the property or statistic path
      .concat(sqTrendStore.propertyColumns(TREND_PANELS.CAPSULES))
      .concat(sqTrendStore.customColumns(TREND_PANELS.CAPSULES))
      .filter(column => sqTrendStore.isColumnEnabled(TREND_PANELS.CHART_CAPSULES, column.key))
      .value();

    const capsuleDataLabels = {};
    let lineWidth = DEFAULT_LINE_WIDTH;
    // If data labels are enabled, compute the maximum line height so that all capsules use the same one
    if (capsuleColumns.length) {
      _.chain(condition.capsules as CapsuleV1[])
        // Filter out those capsules that fall inside a break in chain view so that their labels don't leak out
        .reject(capsule => sqTrendStore.view === TREND_VIEWS.CHAIN &&
          _.some(stitchBreaks, ({ from, to }) => _.inRange(
            _.isNil(capsule.start) ? displayRangeStart : capsule.start / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
            from, to)))
        .forEach((capsule) => {
          const tableCapsule = _.find(tableCapsules, ['id', getUniqueId(condition.id, capsule.id)]);
          const dataLabels = _.chain(capsuleColumns)
            // Can only get statistic values from table capsules, but that comes with the caveat that unbounded
            // or uncertain won't be found because their IDs won't match (which is also why those capsules can't be
            // selected in the capsules pane)
            .reject(column => sqColumnHelper.isStatistic(column) && !tableCapsule)
            .map((column) => {
              const capsuleWithData = sqColumnHelper.isStatistic(column) ? tableCapsule :
                {
                  itemType: ITEM_TYPES.CAPSULE,
                  startTime: capsule.start / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
                  endTime: capsule.end / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
                  duration: capsule.start && capsule.end ?
                    (capsule.end - capsule.start) / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND : undefined,
                  similarity: _.find(capsule.properties, ['name', 'Similarity'])?.value,
                  properties: _.transform(capsule.properties, (memo, property) => {
                    memo[property.name] = property.value;
                  }, {}),
                  propertiesUOM: _.transform(capsule.properties, (memo, property) => {
                    memo[property.name] = property.unitOfMeasure;
                  }, {})
                };
              return {
                title: sqColumnHelper.getCapsuleColumnTitle(column),
                value: sqColumnHelper.getColumnValue(column, sqItemDecorator.decorate(capsuleWithData))
              };
            })
            .reject(({ value }) => !_.isNumber(value) && _.isEmpty(value))
            .value();
          if (dataLabels.length) {
            lineWidth = Math.max(TEXT_HEIGHT_IN_PIXELS * dataLabels.length, lineWidth);
            capsuleDataLabels[capsule.id] = {
              label: _.map(dataLabels, 'value').join('<br>'),
              titles: _.map(dataLabels, 'title')
            };
          }
        })
        .value();
    }

    // De-conflict the capsules so as to figure out which row they should go on
    const items = _.chain(condition.capsules as CapsuleV1[])
      // Remove zero-length capsules, but do not reject capsule that have null start and end times as those are
      // capsules that start and end off screen
      .reject(capsule => !_.isNil(capsule.start) && !_.isNil(capsule.end) && capsule.start === capsule.end)
      .transform((rows, capsule: any) => {
        let offset = 0;
        const startMilliseconds = capsule.start / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND;
        const endMilliseconds = capsule.end / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND;
        const cursorKeyMilliseconds = capsule.cursorKey ?
          capsule.cursorKey / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND : undefined;
        const notFullyVisible = !sqUtilities.isCapsuleVisible(startMilliseconds, endMilliseconds);
        const xStart = _.isFinite(startMilliseconds) ? startMilliseconds : displayRangeStart;
        const xEnd = _.isFinite(endMilliseconds) ? endMilliseconds : displayRangeEnd;

        // find the first non-overlapping row
        do {
          const prevEnd = _.get(prevEnds, offset, -Number.MAX_VALUE);

          if (xStart < prevEnd) {
            offset += lineWidth - 1;
          } else {
            prevEnds[offset] = xEnd;
            break;
          }
        } while (true);

        const yValue = totalYValue + offset;

        let row = _.find(rows, ['data.0.y', yValue]);

        if (!row) {
          row = {
            id: `${yValue}-${condition.id}`,
            uniqueId: `${yValue}-${condition.id}`,
            capsuleSetId: condition.id,
            capsuleSetName: condition.name,
            color: condition.color,
            selected: condition.selected,
            itemType: ITEM_TYPES.CAPSULE,
            isAggregated: _.some(capsule.properties, ['name', 'Count']),
            anyCapsulesSelected: false,
            data: [],
            capsules: [],
            zones: [],
            lineWidth,
            dataLabels: {
              enabled: false,
              inside: true,
              crop: false, // This and overflow ensure labels work near the edge of the screen
              overflow: 'allow',
              allowOverlap: true, // Ensures Highcharts does not filter out labels it thinks would overlap
              className: 'highcharts-capsule-label',
              padding: 1,
              labelNames: [], // Used to add labels to lane
              formatter() {
                if (!this.point.dataLabelString) {
                  return null;
                }

                // Constrain the width to ensure long labels don't run outside the capsule
                const nextPoint = _.find(this.series.data, { index: this.point.index + 1 }) as any;
                const widthStyle = nextPoint ? `width: ${nextPoint.plotX - this.point.plotX}px` : '';
                return `<span style="${widthStyle}">${this.point.dataLabelString}</span>`;
              }
            },
            yValue
          };
          rows.push(row);
        }

        const { label: dataLabelString, titles: dataLabelTitles } = capsuleDataLabels[capsule.id] ?? {};
        row.lineWidth = Math.max(lineWidth, row.lineWidth);
        row.dataLabels.enabled = row.dataLabels.enabled || !_.isEmpty(dataLabelString);
        row.dataLabels.labelNames = _.uniq(row.dataLabels.labelNames.concat(dataLabelTitles || []));

        // Create the line segment for this capsule
        row.data.push({
          // Move the x to the edge of the chart if it is off-screen so data labels show up
          ...{ x: Math.max(xStart, displayRangeStart), y: yValue },
          ...dataLabelString && { dataLabelString, labelText: dataLabelString }
        });
        row.data.push({ x: xEnd, y: yValue });
        // Create the space between the capsules.
        row.data.push({ x: xEnd, y: null });
        // Keep a reference to the capsule for selection purposes
        row.capsules.push({
          id: getUniqueId(condition.id, capsule.id),
          isReferenceCapsule: _.get(_.find(capsule.properties, ['name', 'Reference Capsule']), 'value', false),
          isUncertain: capsule.isUncertain,
          cursorKey: cursorKeyMilliseconds,
          startTime: xStart,
          endTime: xEnd,
          notFullyVisible,
          yValue
        });
      }, [])
      .value();

    if (_.isEmpty(condition.capsules)) {
      const emptyRow = {
        id: `${totalYValue}-${condition.id}`,
        uniqueId: `${totalYValue}-${condition.id}`,
        capsuleSetId: condition.id,
        color: condition.color,
        selected: false,
        itemType: ITEM_TYPES.CAPSULE,
        isAggregated: false,
        anyCapsulesSelected: false,
        lineWidth: DEFAULT_LINE_WIDTH,
        data: [],
        capsules: [],
        zones: [],
        yValue: totalYValue
      };
      items.push(emptyRow);
      totalYValue += 1;

    } else {
      if (!_.isEmpty(items)) {
        totalYValue += _.chain(prevEnds).keys().map(Number).max().value() + 1;
      }
    }

    if (isEditing) {
      totalYValue += 1;
    }

    // This is how we get Highcharts to display a start and end line that helps us generate an "outline" for an
    // uncertain capsule. If a capsuleSet contains uncertain capsules we overlay the uncertain capsule with another
    // uncertain capsule that is just a bit shorter than the original capsule to generate the start/end outline
    // boundary. This code clones the existing capsules, filters out the uncertain capsules, adjusts the start and
    // end times for those capsules to generate the start and end lines, and adds new ids for capsules and
    // capsuleSet, as well as the itemType UNCERTAIN_BOUNDED_CAPSULE.
    if (_.some(condition.capsules, 'isUncertain')) {
      const uncertainItems = _.chain(items)
        .map((row) => {
          // the width of the start and end boundary needs to be calculated based on the display range duration to
          // ensure it looks consistent for any given display range. This calculation below has proven to result in
          // the desirable width.
          const firstUncertainCapsule = _.find(row.capsules, 'isUncertain');
          // If the uncertain capsule has a cursorKey, set that as the uncertainty start
          const unboundedCapsuleUncertaintyStart = firstUncertainCapsule ? firstUncertainCapsule.cursorKey : undefined;
          // Make sure that we're only displaying uncertainty if the start of the uncertainty is before the end of the
          // capsule, if there is a cursorKey. If there is not a cursor key, then the capsule is bounded, defaulting to
          // true for the below check.
          const displayUncertainty = unboundedCapsuleUncertaintyStart ?
            unboundedCapsuleUncertaintyStart <= firstUncertainCapsule.endTime : true;
          // We want to display an uncertain box if the capsule exists, and either doesn't have a cursor (bounded) or
          // has a cursor and the cursor is <= end time (unbounded)>
          if (firstUncertainCapsule && displayUncertainty) {
            const newRow = _.cloneDeep(row);
            const lineWidthInTime = (sqDurationStore.displayRange.duration / 1000) * 1.4;
            const uncertainAfter = firstUncertainCapsule.startTime;
            newRow.capsuleSetId = `${newRow.capsuleSetId}_uncertain`;
            newRow.id = `${newRow.yValue}-${newRow.capsuleSetId}`;
            newRow.uniqueId = `${newRow.yValue}-${newRow.capsuleSetId}`;
            newRow.color = '#fff';
            newRow.itemType = unboundedCapsuleUncertaintyStart === firstUncertainCapsule.endTime ?
              ITEM_TYPES.UNCERTAIN_UNBOUNDED_CAPSULE : ITEM_TYPES.UNCERTAIN_BOUNDED_CAPSULE;
            // Shrink the line width so that it shows inside the original line
            newRow.lineWidth -= newRow.itemType === ITEM_TYPES.UNCERTAIN_BOUNDED_CAPSULE ? 4 : 5;
            // Data labels should only be shown for the original row
            delete newRow.dataLabels;
            newRow.data = _.chain(newRow.data)
              .chunk(3)
              .map((group: any[]) => {
                  if (group[0].x >= uncertainAfter) {
                    // Draw a line through each (partially) uncertain capsule to hollow them out.
                    // Note: group[0].x is the x value of the beginning of the capsule
                    //       group[1].x and group[2].x are both the x value of the end of the capsule.

                    const capsuleDuration = group[1].x - group[0].x;

                    if (newRow.itemType === ITEM_TYPES.UNCERTAIN_UNBOUNDED_CAPSULE
                      && group[1].x === unboundedCapsuleUncertaintyStart) {
                      // Display the uncertain-unbounded capsules by creating an open end for the last
                      // 5% of the capsule.
                      // Note: this branch is only hit if the cursor key is exactly at the end of this
                      // unbounded uncertain capsule.
                      group[0].x = Math.max(group[0].x, unboundedCapsuleUncertaintyStart - capsuleDuration * 0.05);
                    } else {
                      // Note: this branch is hit if this capsule is uncertain and there is no cursor key
                      // or the cursor key exists and is _not_ exactly at the end of the capsule.
                      // It could be:
                      //  a. A partially certain or fully uncertain unbounded capsule
                      //      In this case, start the hollowing out at the cursor key
                      //      or the beginning of this capsule, whichever is later.
                      //  b. An uncertain bounded capsule
                      //      Hollow out the whole capsule.
                      const adjustment = (capsuleDuration < lineWidthInTime * 3) ?
                        ((capsuleDuration / 2) / 100) * 15 :
                        lineWidthInTime;

                      // Adjust the end back so we don't draw over the end, but don't go
                      // before the start.
                      const adjustedEnd = Math.max(group[0].x, group[1].x - adjustment);
                      group[1].x = adjustedEnd;
                      group[2].x = adjustedEnd;

                      // Adjust the start, but don't go beyond the adjusted end.
                      const adjustedStart = Math.min(group[0].x + adjustment, adjustedEnd);

                      // A bounded, fully uncertain capsule will be hollowed out and the label made visible
                      if (!unboundedCapsuleUncertaintyStart) {
                        _.chain(row.data)
                          .filter(point => point.dataLabelString && point.x === group[0].x)
                          .forEach((point) => {
                            point.dataLabels = { color: '#000' };
                          })
                          .value();
                      }

                      group[0].x = unboundedCapsuleUncertaintyStart ?
                        // We have a cursor key; if this is a partially certain capsule, start
                        // the hollowing out at the cursor key.
                        // Otherwise this is a fully uncertain, bounded capsule; hollow out the whole thing.
                        Math.min(adjustedEnd, Math.max(adjustedStart, unboundedCapsuleUncertaintyStart)) :
                        adjustedStart;
                    }

                    // Cursor labels should only be on the original rows
                    delete group[0].labelText;
                    delete group[1].labelText;

                    return group;
                  }
                }
              )
              .compact()
              .flatten()
              .value();
            return newRow;
          }
        })
        .compact()
        .value();
      return _.flatten([uncertainItems, items]);
    }

    return items;
  }
}
