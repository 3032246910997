import _ from 'lodash';
import { BaseToolStoreService } from '@/investigate/baseToolStore.service';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { EMPTY_XYREGION, XYRegion } from '@/services/chartHelper.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { NumberHelperService } from '@/core/numberHelper.service';
import { ScatterPlotStore } from '@/scatterPlot/scatterPlot.store';
import { DurationStore } from '@/trendData/duration.store';
import { FrontendDuration } from '@/services/systemConfiguration.service';

export type ScatterConditionStore = ReturnType<typeof sqScatterConditionStore>['exports'];

export const DEFAULT_DURATION: FrontendDuration = { value: 0, units: 'min' };

export function sqScatterConditionStore(
  sqBaseToolStore: BaseToolStoreService,
  sqScatterPlotStore: ScatterPlotStore,
  sqDateTime: DateTimeService,
  sqDurationStore: DurationStore,
  sqNumberHelper: NumberHelperService) {
  const store = {
    initialize() {
      this.state = this.immutable(_.assign({}, sqBaseToolStore.COMMON_PROPS, {
        isBounding: false,
        isCleansing: false,
        minDuration: DEFAULT_DURATION,
        mergeDuration: DEFAULT_DURATION
      }));
    },

    exports: {
      get isBounding() {
        return this.state.get('isBounding');
      },

      get isCleansing() {
        return this.state.get('isCleansing');
      },

      get minDuration() {
        return this.state.get('minDuration');
      },

      get mergeDuration() {
        return this.state.get('mergeDuration');
      },

      getSelectedRegionFromFormula(formula): XYRegion {
        const xMin = sqNumberHelper.toNumber(_.get(/(\$xSignal > (-?[0-9]+([,.]?)[0-9]*))/.exec(formula), '2'));
        const xMax = sqNumberHelper.toNumber(_.get(/(\$xSignal < (-?[0-9]+([,.]?)[0-9]*))/.exec(formula), '2'));
        const yMin = sqNumberHelper.toNumber(_.get(/(\$ySignal > (-?[0-9]+([,.]?)[0-9]*))/.exec(formula), '2'));
        const yMax = sqNumberHelper.toNumber(_.get(/(\$ySignal < (-?[0-9]+([,.]?)[0-9]*))/.exec(formula), '2'));

        const parseForMerge = /merge\(([0-9]+([,.]?)[0-9]*)([a-z]+)/.exec(formula);
        const maybeMergeNumber = _.get(parseForMerge, '1') ? sqNumberHelper.toNumber(_.get(parseForMerge, '1')) : null;
        const maybeMergeUnit = _.get(parseForMerge, '3');

        if (maybeMergeNumber && maybeMergeUnit) {
          this.setMergeDuration({ mergeDuration: { value: maybeMergeNumber, units: maybeMergeUnit } });
          this.setCleansing({ isCleansing: true });
        }

        const parseForMin = /removeShorterThan\(([0-9]+([,.]?)[0-9]*)([a-z]+)/.exec(formula);
        const maybeMinNumber = _.get(parseForMin, '1') ? sqNumberHelper.toNumber(_.get(parseForMin, '1')) : null;
        const maybeMinUnit = _.get(parseForMin, '3');

        if (maybeMinNumber && maybeMinUnit) {
          this.setMinDuration({ minDuration: { value: maybeMinNumber, units: maybeMinUnit } });
          this.setCleansing({ isCleansing: true });
        }

        return { xMin, xMax, yMin, yMax };
      },

      /**
       * Create a condition formula representing a region on the scatter plot.
       * The basic formula consists of two intersecting value searches.
       *
       * @param {XYRegion} region - region on the scatter plot to search within
       * @param { {id: string} } xSeries - signal on the x-axis of the scatter plot
       * @param { {id: string} } ySeries - signal on the y-axis of the scatter plot
       * @param {boolean} isBounding - whether or not to only create capsules within the display range
       * @param {moment} displayRange - time range displayed on the scatter plot. Used if isBounding is true.
       */
      getFormulaAndParameters(region: XYRegion, xSeries, ySeries, isBounding, displayRange, isCleansing, minDuration,
        mergeDuration) {
        if (!xSeries || !ySeries) {
          throw new Error('Expected series to be present on both axes of the scatter plot.');
        }

        if (!region || _.isEqual(region, EMPTY_XYREGION)) {
          throw new Error('Expected a region to be selected on the scatter plot, but found no selected region.');
        }

        if (region.xMax < region.xMin || region.yMax < region.yMin) {
          throw new Error('Expected maximum value to be greater than minimum value.');
        }

        const formulaParameters = {
          xSignal: xSeries.id,
          ySignal: ySeries.id
        };
        const xSignalFormula = '$xSignal';
        const ySignalFormula = '$ySignal';

        let formula = `(${xSignalFormula} > ${region.xMin} `
          + `\nand ${xSignalFormula} < ${region.xMax})`
          + `\nand (${ySignalFormula} > ${region.yMin} `
          + `\nand ${ySignalFormula} < ${region.yMax})`;

        if (isBounding) {
          const capsuleFormula = sqDateTime.getCapsuleFormula(displayRange);
          const conditionFormula = `condition(${displayRange.duration.asMilliseconds()} ms, ${capsuleFormula})`;
          formula = `(${formula})\nand ${conditionFormula}`;
        }

        if (isCleansing) {
          formula = `(${formula})`;
          formula += mergeDuration.value > 0
            ? `\n.merge(${mergeDuration.value}${mergeDuration.units})`
            : '';
          formula += minDuration.value > 0
            ? `\n.removeShorterThan(${minDuration.value}${minDuration.units})`
            : '';
        }
        return { formula, formulaParameters };
      }
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @return {Object} State for the store
     */
    dehydrate() {
      return this.state.serialize();
    },

    /**
     * Sets the references panel state
     *
     * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      SCATTER_CONDITION_SET_BOUNDING: 'setBounding',
      SCATTER_CONDITION_SET_CLEANSING: 'setCleansing',
      SCATTER_CONDITION_SET_MIN_DURATION: 'setMinDuration',
      SCATTER_CONDITION_SET_MERGE_DURATION: 'setMergeDuration'
    },

    /**
     * Set whether or not we want to create capsules only within the display range
     *
     * @param payload - Object container for arguments
     * @param payload.isBounding - whether to limit the condition to within the display range
     */
    setBounding(payload: { isBounding: boolean }) {
      this.state.set('isBounding', payload.isBounding);
    },

    /**
     * Set whether or not we want to ignore short capsules/gaps
     *
     * @param payload - Object container for arguments
     * @param payload.isCleansing - whether to ignore short capsules/gaps
     */
    setCleansing(payload: { isCleansing: boolean }) {
      this.state.set('isCleansing', payload.isCleansing);
    },

    /**
     * Set the minimum capsule duration to allow
     *
     * @param payload - Object container for arguments
     * @param payload.minDuration - minimum allowable capsule duration
     */
    setMinDuration(payload: { minDuration: FrontendDuration }) {
      this.state.set('minDuration', payload.minDuration);
    },

    /**
     * Set the maximum gap duration over which to merge capsules
     *
     * @param payload - Object container for arguments
     * @param payload.mergeDuration - maximum duration to merge capsules over a gap
     */
    setMergeDuration(payload: { mergeDuration: FrontendDuration }) {
      this.state.set('mergeDuration', payload.mergeDuration);
    }
  };

  return sqBaseToolStore.extend(store, TREND_TOOLS.SCATTER_CONDITION);
}
