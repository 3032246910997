import angular from 'angular';
import { sqManualSignalStore } from '@/hybrid/tools/manualSignal/manualSignal.store';
import { sqManualSignalActions } from '@/hybrid/tools/manualSignal/manualSignal.actions';
import { sqManualSignalService } from '@/hybrid/tools/manualSignal/manualSignal.utilities.service';

export default angular.module('Sq.ManualSignal', ['Sq.Investigate'])
  .store('sqManualSignalStore', sqManualSignalStore)
  .service('sqManualSignalActions', sqManualSignalActions)
  .service('sqManualSignalService', sqManualSignalService);

export const INTERPOLATION_TYPES = {
  LINEAR: 'Linear',
  STEP: 'Step',
  PILINEAR: 'PILinear',
  DISCRETE: 'Discrete'
};

export const INTERPOLATION_METHODS = [
  {
    text: 'IMPORTS.INTERPOLATION.LINEAR',
    value: INTERPOLATION_TYPES.LINEAR
  }, {
    text: 'IMPORTS.INTERPOLATION.STEP',
    value: INTERPOLATION_TYPES.STEP
  }, {
    text: 'IMPORTS.INTERPOLATION.PILINEAR',
    value: INTERPOLATION_TYPES.PILINEAR
  }, {
    text: 'IMPORTS.INTERPOLATION.DISCRETE',
    value: INTERPOLATION_TYPES.DISCRETE
  }
];

export const SAMPLE_VALUE_ERROR = {
  EMPTY: 'empty',
  INVALID_TYPE: 'invalidType'
};
