import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import {
  AdvancedFormGroup,
  FormElement,
  FormGroup,
  FormRow,
  RemovableFormGroup,
  supportedComponents
} from '@/hybrid/formbuilder/formBuilder.module';
import {
  AdvancedFormBlock,
  ConditionalFormBlock,
  FormBlockWithCheckmarkAndNumber, FormGroupBlock, FormRowBlock, RemovableFormBlock
} from '@/hybrid/formbuilder/formBuildingBlocks';

export function getFormFieldProps(formState, input, meta, props) {
  const {
    className,
    extraClassNames,
    testId,
    value,
    skipStore = false,
    defaultProvided = false
  } = props;
  const showError = formState.submitFailed ? meta.error : meta.error && (meta.modified || defaultProvided);
  return _.assign({}, props,
    {
      onChange: (value) => {
        props.onChange(value);
        input.onChange(value);
      },
      value: skipStore ? input.value : value,
      'data-testid': testId,
      // temporary until all Forms are converted to FormBuilder (https://seeq.atlassian.net/browse/CRAB-21727)
      fromFormBuilder: true,
      className: classNames(className, { 'error-border': showError }), // TODO CRAB-23602
      extraClassNames: classNames(extraClassNames, { 'error-border': showError }),
      showError
    });
}

export function getValidationFunction(defaultValidation, extendValidation, customValidation) {
  if (extendValidation && _.isFunction(customValidation)) {
    return value => defaultValidation(value) || customValidation(value);
  }
  return _.isFunction(customValidation) ? customValidation : defaultValidation;
}

/**
 * Recursively "flattens" out all form elements into an array of form field names.
 **/
export function flattenFormDefinition(formDefinition: FormElement[]): FormElement[] {
  const flattenFormDefinition = formDefinition => _.map(formDefinition,
    def => (def.component === 'FormGroup' || def.component === 'AdvancedFormGroup' || def.component === 'FormRow' || def.component === 'RemovableFormGroup') && def.components ?
      flattenFormDefinition(def.components) : def);
  return _.flattenDeep(flattenFormDefinition(formDefinition));
}

export function decorateWithFieldNames(formDefinition: FormElement []) {
  const addFieldNames = formDefinition => _.map(formDefinition, (def) => {
    const fieldNames = _.map(flattenFormDefinition([def]), 'name');
    if (!_.isEmpty(def.components)) {
      decorateWithFieldNames(def.components);
    }
    return _.assign({}, def, { fieldNames });
  });
  return addFieldNames(formDefinition);
}

// walks the formConfig and assigns the appropriate numbers for checkmarkAndNumber display
export function decorateWithNumbers(formDefinition: FormElement []) {
  let number = 0;

  const hasDisplayNumber = def => def.displayNumber && (!_.has(def, 'includeIf') || def.includeIf);

  const getDefWithNumber = (def) => {
    number++;
    return _.assign({}, def, { number });
  };

  const addDisplayNumber = formDefinition => _.map(formDefinition, (def) => {
    if (hasDisplayNumber(def)) {
      const decoratedDef = getDefWithNumber(def);
      if (!_.isEmpty(def.components)) {
        _.assign(decoratedDef, { components: addDisplayNumber(def.components) });
      }
      return decoratedDef;
    } else {
      if (!_.isEmpty(def.components) && (!_.has(def, 'includeIf') || def.includeIf)) {
        return _.assign({}, def, { components: addDisplayNumber(def.components) });
      }
      return def;
    }
  });
  return addDisplayNumber(formDefinition);
}

export function renderIt(def: FormElement) {
  if (!def.component) {
    throw new Error('Every FormElement must provide a valid component or it can not be rendered.');
  }
  switch (def.component) {
    case 'FormGroup':
      return renderFormGroup(def as FormGroup);
    case 'FormRow':
      return renderFormRow(def as FormRow);
    case 'AdvancedFormGroup':
      return renderAdvancedFormGroup(def as AdvancedFormGroup);
    case 'RemovableFormGroup':
      return renderRemovableFormGroup(def as RemovableFormGroup);
    default:
      return renderFormComponent(def);
  }
}

function renderFormGroup(def: FormGroup) {
  const { name, components, testId, extraClassNames, showBracket } = def;
  const children = _.map(components, renderIt);
  const content = (
    <FormGroupBlock
      name={name}
      testId={testId}
      extraClassNames={extraClassNames}
      showBracket={showBracket}>
      {children}
    </FormGroupBlock>);

  return finalizeForRender(def, content);
}

function renderAdvancedFormGroup(def: AdvancedFormGroup) {
  const { name, components, testId, toolName, toolId, toolStore, alternateName } = def;
  const children = _.map(components, renderIt);
  const content = (
    <AdvancedFormBlock
      name={name}
      testId={testId}
      toolName={toolName}
      toolId={toolId}
      toolStore={toolStore}
      alternateName={alternateName}>
      {children}
    </AdvancedFormBlock>);
  return finalizeForRender(def, content);
}

function renderRemovableFormGroup(def: RemovableFormGroup) {
  const { name, components, testId, iconAction, hideIcon = false } = def;
  const children = _.map(components, renderIt);
  const content = (
    <RemovableFormBlock
      hideIcon={hideIcon}
      name={name}
      testId={testId}
      iconAction={iconAction}>
      {children}
    </RemovableFormBlock>);
  return finalizeForRender(def, content);
}

function renderFormRow(def: FormRow) {
  const { name, components, testId, extraClassNames } = def;
  const children = _.map(components, renderIt);
  const content = (
    <FormRowBlock
      name={name}
      testId={testId}
      extraClassNames={extraClassNames}>
      {children}
    </FormRowBlock>);

  return finalizeForRender(def, content);
}

function renderFormComponent(def: FormElement) {
  if (!supportedComponents[def.component]) {
    throw new Error(`Please supply an existing FormComponent or add ${def.component} as a supported component.`);
  }

  return renderWrapped(supportedComponents[def.component], def);
}

function renderWrapped(What, props: FormElement) {
  const { name } = props;
  return finalizeForRender(props, <What {...props} key={name} />);
}

function finalizeForRender(def: FormElement, content) {
  const { number, includeIf, name, fieldNames, key, extraClassNames } = def;
  if (number) {
    content = (
      <FormBlockWithCheckmarkAndNumber
        name={name}
        extraClassNames={extraClassNames}
        fieldNames={fieldNames}
        number={number}>
        {content}
      </FormBlockWithCheckmarkAndNumber>
    );
  }

  return <ConditionalFormBlock
    key={key || name}
    isConditional={_.has(def, 'includeIf')}
    show={includeIf}
    name={name}>
    {content}
  </ConditionalFormBlock>;
}

export function canUseMemo(prev, next) {
  // return false if render is necessary
  return !(
    !_.isEqual(prev.value, next.value) ||
    prev.disabled !== next.disabled ||
    prev.extendValidation ||
    prev.extraClassNames !== next.extraClassNames);
}

