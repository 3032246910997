import _ from 'lodash';
import angular from 'angular';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { WorkbenchActions } from '@/workbench/workbench.actions';

angular.module('Sq.TrendViewer').controller('ExportPIVisionModalCtrl', ExportPIVisionModalCtrl);

function ExportPIVisionModalCtrl(
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  $scope: ng.IScope,
  $sce: ng.ISCEService,
  $window: ng.IWindowService,
  sqWorkbenchStore: WorkbenchStore,
  sqWorkbenchActions: WorkbenchActions,
  piVisionQueryString: string) {
  const vm = this;
  vm.piVisionHomeURL = sqWorkbenchStore.piVisionHomeURL;
  vm.formatDisplayUrl = formatDisplayUrl;
  vm.dataItems = piVisionQueryString.lastIndexOf('\\\\') === -1 ? 'No PI Vision-compatible items are available in the current display.' :
    $sce.trustAsHtml(_.chain(['<ul><li>'])
      .concat(_.join(piVisionQueryString.match(/\\\\.*?;/g), '</li><li>'))
      .concat('</li><li>')
      .concat((piVisionQueryString.slice(piVisionQueryString.lastIndexOf('\\\\')).match(/\\\\.*?\u0026/g)[0].slice(0, -1)))
      .concat('</li></ul>')
      .join('')
      .value().replace(/;/g, '').replace('<li></li>', ''));

  vm.close = $uibModalInstance.close;
  vm.exportData = exportData;

  activate();

  function activate() {
    vm.formatDisplayUrl();
  }

  function exportData() {
    sqWorkbenchActions.setPIVisionHomeURL(vm.piVisionHomeURL);
    $window.open(vm.piVisionAdHocDisplayURL, '_blank');
  }

  function isUrl(s) {
    const regexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(s);
  }

  function formatDisplayUrl() {
    if (!vm.piVisionHomeURL) {
      vm.piVisionAdHocDisplayURL = '';
      vm.isValidPIVisionHomeURL = false;
    } else {
      const newHomeUrlPrepended = vm.piVisionHomeURL.match(/^https?:\/\/.*/i) ? vm.piVisionHomeURL : 'http://' + vm.piVisionHomeURL;
      const newHomeUrlPermissive = newHomeUrlPrepended.replace(/\/?#?\/?$/, '/#/');
      vm.isValidPIVisionHomeURL = isUrl(newHomeUrlPermissive) && _.endsWith(newHomeUrlPermissive, '/#/') && newHomeUrlPermissive.indexOf('#') === newHomeUrlPermissive.length - 2;
      vm.piVisionAdHocDisplayURL = newHomeUrlPermissive + piVisionQueryString;
    }
  }
}
