import _ from 'lodash';
import angular from 'angular';
import { InvestigateStore } from '@/investigate/investigate.store';
import { TrendActions } from '@/trendData/trend.actions';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { UtilitiesService } from '@/services/utilities.service';
import { NotificationsService } from '@/services/notifications.service';
import { MetricsApi } from 'sdk/api/MetricsApi';
import { ThresholdMetricStore } from '@/hybrid/tools/thresholdMetric/thresholdMetric.store';
import { AuthorizationService } from '@/services/authorization.service';
import { NotifierService } from '@/services/notifier.service';
import { ItemPropertiesStore } from '@/investigate/itemProperties/propertiesPanel.store';
import { API_TYPES, EDIT_MODE } from '@/main/app.constants';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { SystemApi } from 'sdk/api/SystemApi';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { AUTO_FORMAT, NumberHelperService } from '@/core/numberHelper.service';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { ITEM_TYPES } from '@/trendData/trendData.module';
import { ModalService } from '@/services/modal.service';
import scopeModalTemplate from './scopeModal.html';
import { ItemsApi } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';

angular.module('Sq.Investigate').controller('PropertiesPanelCtrl', PropertiesPanelCtrl);

function PropertiesPanelCtrl(
  $scope: ng.IScope,
  $q: ng.IQService,
  $state: ng.ui.IStateService,
  $translate: ng.translate.ITranslateService,
  sqInvestigateStore: InvestigateStore,
  sqTrendActions: TrendActions,
  sqInvestigateActions: InvestigateActions,
  sqUtilities: UtilitiesService,
  sqNotifications: NotificationsService,
  sqNumberHelper: NumberHelperService,
  sqMetricsApi: MetricsApi,
  sqModal: ModalService,
  sqThresholdMetricStore: ThresholdMetricStore,
  sqSystemApi: SystemApi,
  sqSystemConfiguration: SystemConfigurationService,
  sqItemsApi: ItemsApi,
  sqItemPropertiesStore: ItemPropertiesStore,
  sqAuthorization: AuthorizationService,
  sqNotifier: NotifierService,
  sqTrendDataHelper: TrendDataHelperService
) {

  const vm = this;
  vm.originalDesc = '';
  vm.description = '';
  vm.updateDescription = updateDescription;
  vm.toolId = TREND_TOOLS.PROPERTIES;
  vm.toolName = TREND_TOOLS.PROPERTIES;
  vm.toolStore = sqItemPropertiesStore;
  vm.close = sqInvestigateActions.close;
  vm.setItem = setItem;
  vm.clearCache = clearCache;
  vm.setProperty = setProperty;
  vm.setNumberFormat = setNumberFormat;
  vm.deleteProperty = deleteProperty;
  vm.hasProperty = property => _.some(vm.properties, { name: property });
  vm.setArchived = setArchived;
  vm.setCacheEnabled = setCacheEnabled;
  vm.cacheChangeInProgress = false;
  vm.loadToolForEdit = sqInvestigateActions.loadToolForEdit;
  vm.preparePropertiesListForDisplay = preparePropertiesListForDisplay; // for testing purposes only
  vm.shouldAllowDuplicateItem = shouldAllowDuplicateItem;
  vm.shouldAllowDuplicateItemToFormula = shouldAllowDuplicateItemToFormula;
  vm.getValidatedNumber = getValidatedNumber;
  vm.shouldMaxInterpolationBeEditable = shouldMaxInterpolationBeEditable;
  vm.shouldNumberFormatBeEditable = shouldNumberFormatBeEditable;
  vm.duplicateItem = duplicateItem;
  vm.isViewOnlyWorkbookMode = sqUtilities.isViewOnlyWorkbookMode;
  vm.canWrite = () => sqAuthorization.canWriteItem(vm.item);
  vm.canManage = () => sqAuthorization.canManageItem(vm.item);
  vm.ITEM_PROPS = SeeqNames.Properties;
  vm.defaultNumberFormat = sqSystemConfiguration.defaultNumberFormat;
  vm.numberFormatOverriden = false;
  vm.AUTO_FORMAT = AUTO_FORMAT;
  vm.NUMBER_FORMATS = [
    { name: 'PROPERTIES.NUMBER_FORMAT.AUTO', format: vm.AUTO_FORMAT },
    { name: 'PROPERTIES.NUMBER_FORMAT.NUMBER', format: '#,##0.00' },
    { name: 'PROPERTIES.NUMBER_FORMAT.SCIENTIFIC_NOTATION', format: '0.0000E+0' },
    { name: 'PROPERTIES.NUMBER_FORMAT.SIG_FIG', format: 'SigFig:4' }];

  vm.openScopeModal = openScopeModal;

  $scope.$listenTo(sqInvestigateStore, setInvestigateVars);

  // These properties will be displayed in the Advanced section if they are present. Anything not listed here will
  // appear in the "normal" section.
  const ADVANCED_PROPERTIES = [
    SeeqNames.Properties.DataId,
    SeeqNames.Properties.DataVersionCheck,
    SeeqNames.Properties.DatasourceClass,
    SeeqNames.Properties.DatasourceId,
    SeeqNames.Properties.SwapSourceId,
    SeeqNames.Properties.SwapKey,
    SeeqNames.Properties.SyncToken,
    SeeqNames.Properties.UIConfig,
    SeeqNames.Properties.Unsearchable,
    SeeqNames.Properties.ScopedTo
  ];

  const DUPLICATABLE_NON_FORMULA_TOOLS = [
    TREND_TOOLS.THRESHOLD_METRIC
  ];

  // These properties aren't displayed in either properties list at all because they are handled in a custom way
  const SPECIAL_PROPERTIES = [
    SeeqNames.Properties.CacheEnabled
  ];

  /**
   * Subscribes for notifications of permission changes to the current workbook and worksheet.
   */
  vm.$onInit = () => {
    vm.unsubscribePermissions = sqNotifier.onPermissions((workbookId, worksheetId) => {
      if (workbookId === $state.params.workbookId && worksheetId === $state.params.worksheetId) {
        loadProperties();
      }
    });
  };

  /**
   * Unsubscribes for notifications of permission changes
   */
  vm.$onDestroy = () => {
    vm.unsubscribePermissions();
  };

  /**
   * Updates the description property of the item
   *
   * @return {Promise} Resolves when description has been updated.
   */
  function updateDescription() {
    if (vm.originalDesc !== vm.description) {
      if (!_.isEmpty(vm.description)) {
        return sqItemsApi.setProperty({ value: vm.description },
          { id: vm.item.id, propertyName: SeeqNames.Properties.Description })
          .then(() => sqNotifications.successTranslate('CHANGES_SAVED'))
          .then(() => sqTrendActions.fetchItemAndDependents(vm.item.id))
          .then(() => loadProperties())
          .catch(sqNotifications.apiError);
      } else {
        return sqItemsApi.deleteProperty({ id: vm.item.id, propertyName: SeeqNames.Properties.Description })
          .then(() => sqNotifications.successTranslate('CHANGES_SAVED'))
          .then(() => sqTrendActions.fetchItemAndDependents(vm.item.id))
          .then(() => loadProperties())
          .catch(sqNotifications.apiError);
      }
    } else {
      return $q.resolve();
    }
  }

  /**
   * Finds whether an item can be duplicated.
   *
   * @return {Boolean} True if the item is calculated
   */
  function shouldAllowDuplicateItem() {
    return _.some(_.filter(vm.properties, { name: SeeqNames.Properties.Formula })) ||
      _.indexOf(DUPLICATABLE_NON_FORMULA_TOOLS, getToolType()) > -1;
  }

  /**
   * Finds whether an item can be duplicated to a Formula Power Search Pane.
   *
   * @return {Boolean} True if the item is calculated and would not already open as a Formula
   */
  function shouldAllowDuplicateItemToFormula() {
    if (!vm.shouldAllowDuplicateItem()) {
      // Can't duplicate if it's not calculated
      return false;
    }

    const toolType = getToolType();

    return !((_.isUndefined(toolType) ||
      toolType === TREND_TOOLS.FORMULA ||
      toolType === TREND_TOOLS.AGGREGATION_BINS_TABLE) ||
      _.indexOf(DUPLICATABLE_NON_FORMULA_TOOLS, toolType) > -1);
  }

  /**
   * Determines the tool type from the UIConfig property
   *
   * @returns {string|undefined} the tool type
   */
  function getToolType() {
    return _.chain(vm.propertiesAdvanced)
      .find(['name', SeeqNames.Properties.UIConfig])
      .get('value')
      .thru(value => value ? JSON.parse(value) : undefined)
      .get('type')
      .value();
  }

  /**
   * Finds whether the user should be able to change the maximum interpolation
   *
   * @return {Boolean} True if the item is not a user-created type
   */
  function shouldMaxInterpolationBeEditable() {
    return !sqUtilities.isUserCreatedType(vm.item.type) && vm.canWrite();
  }

  /**
   * Returns true if the number format is editable (overridable) by the current user, false otherwise.
   *
   * @return {Boolean} True if the current user can change the number format, false otherwise.
   */
  function shouldNumberFormatBeEditable() {
    return vm.canWrite();
  }

  /**
   * Duplicates the item which is defined by vm.item.
   *
   * @param {Boolean} toFormula - True if the duplication should be forced to a Formula Power Search Pane
   */
  function duplicateItem(toFormula) {
    const editMode = toFormula ? EDIT_MODE.COPY_TO_FORMULA : EDIT_MODE.COPY;
    vm.loadToolForEdit(vm.item.id, editMode);
  }

  /**
   * Syncs sqInvestigateStore and view-model properties and loads ancillaries for select
   *
   * @param {Object} e - The baobab change event
   */
  function setInvestigateVars(e) {
    setAncillaryVars();

    if (_.get(vm.item, 'id') !== _.get(sqInvestigateStore.item, 'id')) {
      vm.cacheChangeInProgress = false;
    }

    vm.item = sqInvestigateStore.item;
    vm.isItemPresent = !!_.get(vm.item, 'id');
    vm.shouldAllowSelectionChange = vm.isViewOnlyWorkbookMode ||
      _.some(sqTrendDataHelper.getAllItems(), { id: vm.item?.id });

    if (!vm.isItemPresent) {
      clearProperties();
    } else if (_.isEmpty(e) || sqUtilities.propertyChanged(e, 'item')) {
      loadProperties();
    }
  }

  /**
   * Processes all ancillaries for properties dropdown
   */
  function setAncillaryVars() {
    vm.ancillaries = [];
    _.forEach(sqTrendDataHelper.getAllItems({ itemTypes: [ITEM_TYPES.SERIES] }), (item) => {
      _.forEach(item.allAncillaries, (ancillary) => {
        vm.ancillaries = _.concat(vm.ancillaries, {
          color: item.color,
          effectivePermissions: ancillary.effectivePermissions,
          id: ancillary.id,
          name: ancillary.name,
          type: ancillary.type,
          items: ancillary.items,
          datasource: { name: $translate.instant('ANCILLARIES.BOUNDARY') }
        });
      });
    });
  }

  /**
   * Fetches and shows the properties for an item
   *
   * @returns {Promise} that resolves when the properties have been fetched
   */
  function loadProperties() {
    return sqItemsApi.getItemAndAllProperties({ id: vm.item.id })
      .then(({ data }) => data)
      .then(addAdditionalAdvancedProperties)
      .then(buildNumberFormatProperties)
      .then(({ properties, cached, type, additionalAdvancedProperties, effectivePermissions, scopedTo }) => {
        // Unfortunately, we can't rely on the 'Cache Enabled' property to determine the cache status of this item,
        // since that property is only present when the property is explicitly set; it doesn't capture when the
        // cache status is at a default value based on the item type. So the backend now returns a .cached attribute
        // of the item that serves that purpose.
        vm.cached = cached;
        vm.isCacheSupported = !_.isUndefined(vm.cached);

        const allProperties = preparePropertiesListForDisplay(properties);

        // If a value was supplied for the focusPropName argument, then set the .triggerFocus value of the property
        // with that name. We use Date.now() as a convenient way to ensure there will always be a change each time
        // .triggerFocus is updated. This is required so that anyone watching .triggerFocus will see a change.
        if (vm.item.propertiesFocus) {
          _.set(_.find(vm.properties, ['name', vm.item.propertiesFocus]), 'triggerFocus', Date.now());
        }

        vm.isAncillary = type === API_TYPES.ANCILLARY;
        vm.isUserCreatedType = sqUtilities.isUserCreatedType(type);
        vm.item.type = type;
        vm.item.effectivePermissions = effectivePermissions;
        vm.item.scopedTo = scopedTo;

        vm.availableOutsideAnalysis = !scopedTo;
        if (vm.availableOutsideAnalysis) {
          vm.scopeMessage = 'SCOPE.GLOBAL';
        } else if (_.toLower(scopedTo) === _.toLower($state.params.workbookId)) {
          vm.scopeMessage = 'SCOPE.LOCAL';
        } else {
          vm.scopeMessage = 'SCOPE.OTHER';
        }

        [vm.propertiesAdvanced, vm.properties] = _.chain(allProperties)
          .reject(property => _.includes(SPECIAL_PROPERTIES, property.name))
          .reject(property => property.name === SeeqNames.Properties.Description)
          .partition(property => _.includes(ADVANCED_PROPERTIES, property.name))
          .value();

        vm.isMaxInterpolationEditable = vm.shouldMaxInterpolationBeEditable();
        vm.isNumberFormatEditable = vm.shouldNumberFormatBeEditable();

        vm.propertiesAdvanced = vm.propertiesAdvanced.concat(_.compact(additionalAdvancedProperties));

        vm.description = _.find(allProperties, property => property.name === SeeqNames.Properties.Description)?.value;
        vm.originalDesc = vm.description;

        const trackingProperties = ['ID', SeeqNames.Properties.DatasourceClass, SeeqNames.Properties.DatasourceId];
        vm.clearCacheTrackingInfo = _.chain(allProperties)
          .filter((prop: any) => _.includes(trackingProperties, prop.name))
          .map(property => `${property.name}=${property.value}`)
          .value()
          .join(',');
      })
      .catch((response) => {
        clearProperties();
        sqNotifications.apiError(response);
      });
  }

  /**
   * Appends an originalValue to the properties and prepares the display order like this:
   * 1) the property
   * 2) the property override if it exists
   * 3) the property source if it exists.
   * The code works reliably because the properties returned from the backend are sorted by href which
   * includes the property name
   *
   * @param properties - list of item properties
   * @return a sorted list of properties with the original value appended
   */
  function preparePropertiesListForDisplay(properties: any[]): any[] {
    return _.chain(_.cloneDeep(properties))
      .forEach((x: any) => {
        x.originalValue = x.value;
      })
      // Property sort order shall be:
      .sortBy(property =>
        _.startsWith(property.name, 'Source') || _.startsWith(property.name, 'Override') ?
          _.replace(property.name, /Source |Override /, '') + 'z' : property.name)
      .value();
  }

  /**
   * Clears properties to reset panel state
   */
  function clearProperties() {
    delete vm.properties;
    delete vm.propertiesAdvanced;
    delete vm.cached;
    delete vm.clearCacheTrackingInfo;
    vm.isUserCreatedType = false;
  }

  /**
   * Adds an additionalAdvancedProperties array of tool-specific properties for the advanced section
   *
   * @param {any} response - the response
   * @returns {Promise} that resolves with the response with additional advanced properties added
   */
  function addAdditionalAdvancedProperties(response: any) {
    if (response.type !== API_TYPES.THRESHOLD_METRIC) return $q.resolve(response);

    return sqMetricsApi.getMetric({ id: response.id })
      .then(({ data: definition }) => {
        response.additionalAdvancedProperties = [];
        _.forEach(['processType', 'measuredItem', 'boundingCondition'], (propName) => {
          const propValue = _.get(definition, propName);
          if (propValue) {
            response.additionalAdvancedProperties.push({
              name: _.startCase(propName),
              value: _.get(propValue, 'id', propValue)
            });
          }
        });

        const priority = $translate.instant('PRIORITY');
        _.chain(definition)
          .get('thresholds')
          .forEach(threshold => response.additionalAdvancedProperties.push({
            name: `${priority} ${_.get(threshold, 'priority.name')}`,
            value: sqThresholdMetricStore.getThresholdString(threshold)
          }))
          .value();

        return response;
      });
  }

  /**
   * Adds the default number format property if no number format is present.
   *
   * @param {any} response - the response
   * @param response {Promise} that resolves with the response number formats added
   */
  function buildNumberFormatProperties(response: any) {
    const formattableItems = [
      API_TYPES.THRESHOLD_METRIC,
      API_TYPES.CALCULATED_SCALAR,
      API_TYPES.CALCULATED_SIGNAL,
      API_TYPES.STORED_SIGNAL
    ];
    const valueUom = _.find(response.properties, ['name', SeeqNames.Properties.ValueUom]);
    const sourceValueUom = _.find(response.properties, ['name', SeeqNames.Properties.SourceValueUom]);
    if (_.get(valueUom, 'value') === 'string' || _.get(sourceValueUom, 'value') === 'string') {
      _.remove(response.properties, { name: SeeqNames.Properties.NumberFormat });
      return $q.resolve(response);
    }

    if (_.includes(formattableItems, response.type)) {
      const hasNumberFormat = _.some(response.properties, { name: SeeqNames.Properties.NumberFormat });
      if (!hasNumberFormat) {
        const sourceNumberFormat = _.find(response.properties, { name: SeeqNames.Properties.SourceNumberFormat });
        response.properties.push({
          name: SeeqNames.Properties.NumberFormat,
          value: _.get(sourceNumberFormat, 'value') || vm.defaultNumberFormat
        });
        vm.numberFormatOverriden = false;
      } else {
        vm.numberFormatOverriden = true;
      }
    } else {
      vm.numberFormatOverriden = false;
    }
    return $q.resolve(response);
  }

  /**
   * Update the property of an item if it has changed. The change check is done because this is called onBlur.
   *
   * @param {Object} property - The property to update
   * @param {Boolean} [updatePropsOnly] - True to update only the properties
   * @return {Promise} that resolves when the property value has been set
   */
  function setProperty(property, updatePropsOnly = false) {
    return setPropertyInternal(vm.item, property, { updatePropsOnly })
      .then(() => loadProperties())
      .catch(sqNotifications.apiError);
  }

  /**
   * Sets the number format of the item to the one provided.
   *
   * @param format the format to set
   */
  function setNumberFormat(format) {
    if (format === vm.defaultNumberFormat) {
      this.deleteProperty({ name: SeeqNames.Properties.NumberFormat });
    } else {
      this.setProperty({ name: SeeqNames.Properties.NumberFormat, value: format });
    }
  }

  /**
   * Applies the format string to a representative number and returns a formatted representation of that number or an
   * error string detailing a formatting mistake.
   *
   * @param {String} format - A string detailing the format to validate
   */
  function getValidatedNumber(format) {
    const validNumber = 123456789.987654321;
    let error;
    const errorHandler = (err) => {
      error = err;
    };
    const formattedNumber = sqNumberHelper.formatNumber(validNumber, { format }, errorHandler);
    if (error) {
      return error;
    } else {
      return formattedNumber;
    }
  }

  /**
   * Updates a property of a specified item.
   *
   * @access protected
   * @param {Object} item - Item on which to set the property
   * @param {Object} property - Property to update
   * @param {Object} property.originalValue - Previous value of the property, for change detection
   * @param {Object} property.value - Value requesting to set
   * @param {Object} [options] - options object
   * @param {boolean} [options.updatePropsOnly] - True to refresh only the properties of the item
   * @param {boolean} [options.skipFetch] - True to skip the item fetch after the property is set; used when the item is
   *   being Archived, since it will immediately be removed and we don't want the side-effect actions to occur
   * @returns {Promise} that resolves when the property value has been set
   */
  function setPropertyInternal(item, property, { updatePropsOnly = false, skipFetch = false }:

    // If the number format property isn't present on the item, it is filled in with a default. We need to set it
    // when it hasn't been overriden yet.
    { updatePropsOnly?: boolean, skipFetch?: boolean } = {}) {
    if ((property.value === property.originalValue && property.name !== SeeqNames.Properties.NumberFormat) ||
      (property.value === property.originalValue && property.name === SeeqNames.Properties.NumberFormat && vm.numberFormatOverriden)) {
      return $q.resolve();
    }
    property.originalValue = property.value;
    return sqItemsApi.setProperty({ value: property.value }, { id: item.id, propertyName: property.name })
      .then(() => {
        if (skipFetch) return;

        return updatePropsOnly ? sqTrendActions.fetchItemProps(item.id) :
          sqTrendActions.fetchItemAndDependents(item.id);
      });
  }

  /**
   * Deletes a property from the item
   *
   * @param {Object} property - Property to delete
   * @param {String} property.name - Name of property
   * @returns {Promise} that resolves when property has been deleted
   */
  function deleteProperty(property) {
    return sqItemsApi.deleteProperty({ id: vm.item.id, propertyName: property.name })
      .then(() => sqTrendActions.fetchItemAndDependents(vm.item.id))
      .then(() => loadProperties())
      .catch(sqNotifications.apiError);
  }

  /**
   * Set the archived property on the item to true or false. When set to True, item is also removed from the worksheet.
   *
   * @param {Boolean} val - Value for Archived property
   * @returns {Promise} that resolves when property has been set
   */
  function setArchived(val) {
    // Skip fetching updated properties when setting Archived to true
    return setPropertyInternal(vm.item, { name: SeeqNames.Properties.Archived, value: val },
      { updatePropsOnly: true, skipFetch: val })
      .then(() => {
        if (val) {
          const id = vm.item.id;
          const restoreItem = () => sqItemsApi.getItemAndAllProperties({ id })
            .then(({ data }) => sqTrendActions.addItem(data))
            .then(item => setPropertyInternal(item, { name: SeeqNames.Properties.Archived, value: false }))
            .then(() => {
              sqInvestigateActions.setActiveTool(TREND_TOOLS.PROPERTIES);
              sqInvestigateActions.setItem(id);
              sqInvestigateActions.updateDerivedDataTree();
            })
            .catch(sqNotifications.apiError);

          sqTrendActions.removeItem(vm.item, true);
          sqNotifications.custom(
            sqNotifications.success, 'TRASH.ITEM_TRASHED_NOTIFICATION', restoreItem, { ITEM_NAME: vm.item.name },
            { buttonTranslateKey: 'RESTORE' });
        } else {
          sqInvestigateActions.setItem(vm.item.id);
          sqInvestigateActions.updateDerivedDataTree();
        }
      })
      .catch(sqNotifications.apiError);
  }

  /**
   * Set the Cache Enabled property to the specified value
   *
   * @param {boolean} val - True to enable cache; otherwise false
   */
  function setCacheEnabled(val) {
    vm.cacheChangeInProgress = true;
    vm.setProperty({ name: SeeqNames.Properties.CacheEnabled, value: val })
      .finally(() => vm.cacheChangeInProgress = false);
  }

  /**
   * Clear the cache for the specified item
   */
  function clearCache() {
    vm.cacheChangeInProgress = true;
    sqItemsApi.clearCache(vm.item)
      .then(({ data }) => sqNotifications.info(data?.statusMessage))
      .then(() => sqTrendActions.fetchItemAndDependents(vm.item.id))
      .catch(error => sqNotifications.apiError(error))
      .finally(() => vm.cacheChangeInProgress = false);
  }

  /**
   * Sets the current item in the panel
   *
   * @param {Object} item - The selected item
   */
  function setItem(item) {
    if (item && item.type === API_TYPES.ANCILLARY) {
      sqInvestigateActions.setNonStoreItem(item);
    } else {
      sqInvestigateActions.setItem(item.id);
    }
  }

  /**
   * Opens a confirmation modal for making an item globally scoped
   *
   * @returns {Promise} a modal instance
   */
  function openScopeModal() {
    return sqModal.open({
      animation: true,
      controller: 'ScopeModalCtrl',
      controllerAs: '$ctrl',
      resolve: {
        itemId: _.constant(vm.item.id)
      },
      size: 'sm',
      template: scopeModalTemplate,
      backdrop: 'static'
    }).result.then((result) => {
      if (result) {
        loadProperties();
      }
    });
  }
}
