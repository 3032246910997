import angular from 'angular';

export const PLUGIN_CATEGORY = {
  DISPLAY_PANE: 'DisplayPane'
};

export type PluginId = string; // e.g. some GUID

export type PluginIdentifier = string; // e.g. "com.seeq.plugin.parallel-coordinates"

const dependencies = [
  'Sq.Core',
  'Sq.TrendData',
  'Sq.Services.D3'
];

/**
 * The PluginHost module manages lifecycle events and communication for a plugin.
 */
angular
  .module('Sq.PluginHost', dependencies);
