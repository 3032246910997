import classNames from 'classnames';
import React from 'react';
import _ from 'lodash';
import { Field } from 'react-final-form';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { LabelFormComponent } from './LabelFormComponent.atom';

type RadioButtonOption = {
  id: string;
  label: string | React.ReactNode;
  checked: boolean;
  onToggle: () => void;
  tooltip?: string;
  description?: string;
  disabled?: boolean;
};

export interface RadioButtonsIF extends ValidatingFormComponent<boolean> {
  component: 'RadioButtonGroupFormComponent';
  label?: string;
  options: RadioButtonOption[];
  id: string;
  /** If true, the options will be displayed in a 2 column grid instead of a row */
  gridLayout?: boolean;
  verticalLayout?: boolean;
  fieldExtraClassNames?: string;
  checkboxExtraClassNames?: string;
}

/**
 * This component renders multiple checkboxes in a FlexRowContainer.
 * If you prefer to control the layout of your checkboxes you can use CheckboxFormComponent and a combination of
 * FORM_ROW, FORM_GROUP and FORM_COMPONENT.
 */
export const RadioButtonGroupFormComponent: React.FunctionComponent<RadioButtonsIF> = (props) => {
  const {
    id,
    label,
    tooltip,
    options,
    name,
    value,
    testId,
    extraClassNames,
    fieldExtraClassNames,
    checkboxExtraClassNames,
    gridLayout = false,
    verticalLayout
  } = props;
  const { t } = useTranslation();

  return (
    <div
      className={classNames('flexRowContainer', extraClassNames)}
      id={id}
      data-testid={testId}
    >
      {tooltip ? (
        <LabelFormComponent
          component="LabelFormComponent"
          name={`${name}-label`}
          value={label}
          tooltip={t(tooltip)}
        />
      ) : label ? (
        <span className="pb5">{t(label)}</span>
      ) : null}

      <div
        className={classNames(fieldExtraClassNames, {
          flexWrap: gridLayout,
          flexAlignCenter: !verticalLayout,
          flexColumnContainer: !verticalLayout,
          flexRowContainer: verticalLayout
        })}
      >
        <Field name={name}>
          {({ input }) =>
            _.map(options, option => (
              <Checkbox
                key={`${option.id}_checkbox`}
                classes={classNames(
                  'mr15',
                  { 'width-100': gridLayout, mb10: verticalLayout },
                  checkboxExtraClassNames
                )}
                type="radio"
                id={option.id}
                label={option.label}
                isChecked={option.checked}
                disabled={option.disabled}
                tooltip={option.tooltip}
                description={option.description}
                onChange={() => {
                  input.onChange(!value);
                  option.onToggle();
                }}
              />
            ))
          }
        </Field>
      </div>
    </div>
  );
};
