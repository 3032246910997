import React from 'react';
import _ from 'lodash';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

export const LegendWrapper: React.FunctionComponent<{ title?: string }> = ({ title, children }) => {
  const { t } = useTranslation();
  return !_.isEmpty(children) && (
    <div className="mb10 ml25 mr25 flexColumnContainer flexWrap well pl10 pr10 pt0 pb0" data-testid="legend-wrapper">
      {title && <strong className="mr10">{t(title)}:</strong>}
      {children}
    </div>
  );
};
