import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useState, useEffect } from 'react';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { WorkbookStore } from '@/workbook/workbook.store';
import { Icon } from '@/hybrid/core/Icon.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.module';

const worksheetWatchersBindings = bindingsDefinition({
  worksheetId: prop<string>(),
  compactMode: prop<boolean>(),
  classes: prop.optional<string>(),
  sqWorkbenchStore: injected<WorkbenchStore>(),
  sqWorkbookStore: injected<WorkbookStore>()
});

export const MAX_WATCHERS_LIST_SIZE = 10;
export const WorksheetWatchers: SeeqComponent<typeof worksheetWatchersBindings> = (props) => {
  const { worksheetId, compactMode, classes } = props;
  const { sqWorkbenchStore, sqWorkbookStore } = useInjectedBindings(worksheetWatchersBindings);
  const { t } = useTranslation();

  // A list of viewers containing information about the users viewing the page. Elements in the list are of the form:
  // {  name: the user's name, or username if not given
  //    username: the username of the user
  //    worksheetId: the id of the worksheet their viewing
  //    worksheetDisplay: the display mode they're using to view the worksheet
  // }
  const viewers = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.viewers);
  const stateParams = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.stateParams);
  const currentUser = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser);

  const [formattedViewers, setFormattedViewers] = useState([]);
  const [viewerCount, setViewerCount] = useState(0);

  const MODE_MAP = {
    [WORKBOOK_DISPLAY.EDIT]: 'JOURNAL.ENTRY.EDITING',
    [WORKBOOK_DISPLAY.VIEW]: 'JOURNAL.ENTRY.VIEWING',
    [WORKBOOK_DISPLAY.PRESENT]: 'JOURNAL.ENTRY.PRESENTING'
  };

  useEffect(() => {
    setFormattedViewers(formatViewers());
  }, [viewers, worksheetId, stateParams.worksheetId]);

  const formatViewers = () => {
    return _.chain(viewers)
      .filter(['worksheetId', worksheetId])
      .filter(user => (user.username !== currentUser.username || worksheetId !== stateParams.worksheetId))
      .clone()
      // Sort the array so that we can filter users with multiple sessions on the same worksheet with the following
      // priority: EDIT > VIEW > PRESENTATION. That way EDIT users with a VIEW tab of the same worksheet will only
      // see one instance of themselves in the watchers list as an EDIT user, i.e. the one with the most privileges.
      .sortBy('worksheetDisplay', (display) => {
        const rank = {
          'WORKBOOK_DISPLAY.EDIT': 0,
          'WORKBOOK_DISPLAY.VIEW': 1,
          'WORKBOOK_DISPLAY.PRESENTATION': 2
        };
        return rank[display];
      })
      .uniqBy('username')
      .map(viewer => ({ ...viewer, name: _.trim(viewer.name) || viewer.username }))
      .sortBy('name')
      .thru((list) => {
        setViewerCount(list.length);
        return list.length > MAX_WATCHERS_LIST_SIZE ? list.slice(0, MAX_WATCHERS_LIST_SIZE) : list;
      })
      .value();
  };

  const watchersTooltip = (
    <span className="worksheet-watchers-tooltip">
      <p className="textAlignLeft">{t('WATCHERS_HELP')}</p>
      <ul id="specWatcherList" className="pl15">
        {_.map(formattedViewers, viewer => (
          <li key={viewer.username}>{viewer.name} (<span>{t(MODE_MAP[viewer.worksheetDisplay])}</span>)</li>
        ))}
      </ul>
      {viewerCount > MAX_WATCHERS_LIST_SIZE && <p className="text-center">...</p>}
    </span>
  );

  return formattedViewers.length > 0 && (
    <HoverTooltip text={`watchersTooltip_${worksheetId}`} placement="auto" formattedText={watchersTooltip}>
      <span className={classNames('text-nowrap', classes)}>
        <Icon icon="fa-eye" type="white" extraClassNames={!compactMode && 'fa-xlg'} testId="watcherEyeball" />
        <sub data-testid="watcherCount">{viewerCount}</sub>
      </span>
    </HoverTooltip>
  );
};

export const sqWorksheetWatchers = angularComponent(worksheetWatchersBindings, WorksheetWatchers);
