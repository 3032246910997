import React from 'react';
import classNames from 'classnames';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';

const tableBuilderTextInputBindings = bindingsDefinition({
  value: prop<string>(),
  onChange: prop<(value: string) => void>(),
  tabIndex: prop<number>(),
  extraClassNames: prop.optional<string>(),
  style: prop.optional<any>()
});

export const TableBuilderTextInput: SeeqComponent<typeof tableBuilderTextInputBindings> = (props) => {
  const {
    value,
    onChange,
    tabIndex,
    extraClassNames,
    style
  } = props;

  // React will whine about contentEditable, but React/Bootstrap's own text inputs don't word-wrap or
  // automatically resize.
  return <div
    contentEditable={true}
    suppressContentEditableWarning={true}
    onBlur={event => onChange((event.target as HTMLInputElement).textContent)}
    style={style}
    className={classNames('tableBuilderTextInput cursorText lightGreyBorder', extraClassNames)}
    tabIndex={tabIndex}>
    {value}
  </div>;
};
