import angular from 'angular';

const dependencies = [
  'Sq.Core',
  'Sq.Builder',
  'Sq.TrendData',
  'Sq.TrendViewer',
  'Sq.Services.D3'
];

/**
 * @module The Treemap module provides an asset-based treemap that alerts when the selected conditions are present.
 */
angular.module('Sq.Treemap', dependencies);
