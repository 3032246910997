import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/mode/sql/sql';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/edit/matchbrackets';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { FormulaEditorParam } from '@/hybrid/formula/FormulaParametersTable.molecule';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import {
  addTextAtCursor,
  getAutocompleteHints,
  getContextHelp,
  getErrorMessage
} from '@/hybrid/formula/formula.utilities';
import { ButtonWithDropdown } from '@/hybrid/core/ButtonWithDropdown.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import SelectUnit from '@/hybrid/core/SelectUnit.molecule';
import { useCurrentValueRef } from '@/hybrid/core/useCurrentValueRef.hook';
import { FormulaToolActions } from '@/hybrid/tools/formula/formulaTool.actions';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { FormulaToolStore } from '@/hybrid/tools/formula/formulaTool.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { WorksheetActions } from '@/worksheet/worksheet.actions';

const formulaEditorBindings = bindingsDefinition({
  formula: prop<string>(),
  constants: prop<any[]>(),
  operators: prop<any[]>(),
  showLineNumbers: prop<boolean>(),
  exposeFormulaToParent: prop<(formula: string) => void>(),
  exposeEditorToParent: prop<(editor: any) => void>(),
  parameters: prop<FormulaEditorParam[]>(),
  onSave: prop<(formula: string) => any>(),
  formulaErrors: prop<FormulaErrorInterface[]>(),
  setFormulaErrors: prop.optional<(errors: any) => void>(),
  readOnly: prop.optional<boolean>(),
  sqFormulaToolActions: injected<FormulaToolActions>(),
  sqFormulaToolStore: injected<FormulaToolStore>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqWorksheetActions: injected<WorksheetActions>()
});

export interface FormulaErrorInterface {
  column: number;
  line: number;
  message: string;
}

export const FormulaEditorUnwrapped: SeeqComponent<typeof formulaEditorBindings> = ({
  parameters,
  constants,
  operators,
  showLineNumbers,
  formula = '',
  exposeFormulaToParent,
  exposeEditorToParent,
  onSave,
  formulaErrors,
  setFormulaErrors,
  readOnly = false
}) => {
  const {
    sqFormulaToolActions,
    sqFormulaToolStore,
    sqWorksheetStore,
    sqWorksheetActions
  } = useInjectedBindings(formulaEditorBindings);

  const { t } = useTranslation();
  const [editor, setEditor] = useState(null);
  const [code, setCode] = useState(formula);
  const [widgets, setWidgets] = useState([]);

  const clearError = () => {
    if (editor && _.isFunction(setFormulaErrors)) {
      setFormulaErrors([]);
    }
  };

  useEffect(() => {
    if (editor) {
      _.forEach(widgets, widget => editor.removeLineWidget(widget));
      setWidgets([]);

      _.forEach(formulaErrors, ({ message, line }) => {
        const error = getErrorMessage(message, clearError);
        setWidgets([editor.addLineWidget(line > -1 ? line - 1 : 0, error, {})]);
      });
    }
  }, [editor, formulaErrors]);

  useEffect(() => {
    setCode(formula);
  }, [formula]);

  const doAutocompleteHints = () => getAutocompleteHints(editor, constants, operators, parameters);
  const autoCompleteRef = useCurrentValueRef(doAutocompleteHints);

  const contextHelp = () => {
    const operatorMatches = getContextHelp(editor, operators);

    if (_.has(_.first(operatorMatches), 'documentationHref')) {
      // Documentation is available for the operator under the cursor
      sqFormulaToolActions.setNavigationStack(
        _.concat(sqFormulaToolStore.navigationStack, operatorMatches[0].documentationHref));
    } else {
      // Specific documentation wasn't found, so do a search instead
      sqFormulaToolActions.setFormulaFilter(operatorMatches);
    }
    if (!sqFormulaToolStore.helpShown) {
      sqFormulaToolActions.toggleHelp();
    }

    if (!sqWorksheetStore.resizeEnabled) {
      sqWorksheetActions.setDisplayResizeEnabled(true);
    }
  };

  const doSave = () => onSave(code);

  const insertUnit = (unit) => {
    addTextAtCursor(unit, editor);
    // slightly hacky way to close the units dropdown
    _.isFunction(document.body.click) && document.body.click();
    editor.focus();
  };

  const hintOptions = {
    hint: doAutocompleteHints,
    container: document.getElementById('formulaEditor'),
    closeOnUnfocus: true,
    completeSingle: false,
    delay: 500
  };

  return (
    <div className="flexRowContainer flexFill mt0">
      <div className="flexColumnContainer mb2">
        <div className="flexColumnContainer width-maximum">
          <ButtonWithDropdown
            alignRight={false}
            testId="formulaUnits"
            icon={<Icon icon="fc-unit" extraClassNames="fa-fw width-20" type="theme" />}>
            <SelectUnit
              placeholder="UNITS_PLACEHOLDER"
              isClearable={false}
              menuIsOpen={true}
              onChange={insertUnit} />
          </ButtonWithDropdown>
          <Icon
            testId="formulaContextHelp"
            onClick={contextHelp}
            extraClassNames="cursorPointer"
            icon="fa-question-circle" />
        </div>

      </div>
      <div id="formulaEditor" className="formula-border flexFill">
        <CodeMirror
          value={code}
          editorDidMount={(codeMirrorEditor) => {
            setEditor(codeMirrorEditor);
            exposeEditorToParent(codeMirrorEditor);
          }}
          options={{
            placeholder: t('FORMULA.FORMULA_PLACEHOLDER'),
            matchBrackets: true,
            mode: { name: 'application/x-httpd-php', startOpen: true },
            smartIndent: false,
            theme: 'seeq',
            readOnly,
            cursorHeight: readOnly ? 0 : 1,
            lineWrapping: false,
            lineNumbers: showLineNumbers,
            extraKeys: {
              'Ctrl-Space': 'autocomplete',
              'Ctrl-H': contextHelp, // for testing as F1 is finickey
              'Ctrl-S': doSave,
              F1: contextHelp,
              // Enable tabbing through the form by disabling tab and shift tab
              Tab: false,
              'Shift-Tab': false
            },
            hintOptions
          }}
          onInputRead={editor => editor.showHint({
            hint: autoCompleteRef.current,
            container: document.getElementById('formulaEditor')
          })}
          onBeforeChange={(editor, data, value) => setCode(value)}
          onChange={(editor, data, value) => {
            clearError();
            exposeFormulaToParent(value);
          }}
        />
      </div>
    </div>
  );
};

export const FormulaEditor = React.memo(FormulaEditorUnwrapped, (prev, next) => !(
  prev.formula !== next.formula ||
  !_.isEqual(next.formulaErrors, prev.formulaErrors) ||
  !_.isEqual(next.operators, prev.operators) ||
  !_.isEqual(next.constants, prev.constants) ||
  !_.isEqual(next.parameters, prev.parameters)
));
