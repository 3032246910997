import React, { useRef } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Icon } from '../core/Icon.atom';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useLogger } from '@/hybrid/core/useLogger.hook';
import { HomeScreenStore } from '@/hybrid/homescreen/homescreen.store';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';

const homeScreenRightPanelBindings = bindingsDefinition({
  sqSystemConfiguration: injected<SystemConfigurationService>(),
  sqHomeScreenStore: injected<HomeScreenStore>(),
  sqHomeScreenActions: injected<HomeScreenActions>()
});

export const HomeScreenRightPanel: SeeqComponent<typeof homeScreenRightPanelBindings> = () => {
  const { sqSystemConfiguration, sqHomeScreenStore, sqHomeScreenActions } =
    useInjectedBindings(homeScreenRightPanelBindings);
  const logger = useLogger();
  const trainingTarget = useRef(null);
  const showTrainingPopover = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.helpDisplay === 'POPOVER');
  const { t } = useTranslation();

  const trainingOverlay = (
    <Overlay placement="left" show={true} target={trainingTarget.current} transition={false}>
      <Popover id="trainingPopover">
        <Popover.Content className="flexRowContainer flexAlignCenter pb20">
          <div className="picker-close" onClick={() => sqHomeScreenActions.setHelpDisplay('NONE')}>
            <Icon icon="fa-close" extraClassNames="cursorPointer" />
          </div>
          <div className="fs15 m20">
            {t('HOME_SCREEN.RESUME_TRAINING')}
          </div>
          <TextButton
            label="HOME_SCREEN.GOT_IT"
            testId="homeScreenGotIt"
            variant="theme"
            onClick={() => sqHomeScreenActions.setHelpDisplay('NONE')} />
        </Popover.Content>
      </Popover>
    </Overlay>
  );

  return (
    <div>
      {showTrainingPopover && trainingOverlay}
      <div className="homeScreenNotification flexRowContainer">
        <h3>{t('HOME_SCREEN.HELPFUL_LINKS')}</h3>
        <div className="mb5 flexFill">
          <a href="https://telemetry.seeq.com/training" target="_blank"
            className="flexColumnContainer p5 link-no-underline homeScreenHelpfulLink">
            <Icon icon="fc-certificate" extraClassNames="mr10 fa-fw" />
            {t('HOME_SCREEN.ONLINE_CERTIFICATION')}
          </a>
        </div>
        <div className="mb5 flexFill">
          <a href="https://telemetry.seeq.com/support-link/wiki/spaces/KB/overview" target="_blank"
            className="flexColumnContainer p5 link-no-underline homeScreenHelpfulLink">
            <Icon icon="fc-knowledge-base" extraClassNames="mr10 fa-fw" />
            {t('KNOWLEDGE_BASE')}
          </a>
        </div>
        <div className="mb5 flexFill">
          <a href="https://telemetry.seeq.com/community" target="_blank"
            className="flexColumnContainer p5 link-no-underline homeScreenHelpfulLink">
            <Icon icon="fc-user-community" extraClassNames="mr10 fa-fw" />
            {t('HOME_SCREEN.USER_COMMUNITY')}
          </a>
        </div>
        <div className={classNames('mb5 flexFill', { noCoverInBlackout: showTrainingPopover })} ref={trainingTarget}>
          <a href="https://telemetry.seeq.com/interactive-training" target="_blank"
            className="flexColumnContainer p5 link-no-underline homeScreenHelpfulLink">
            <Icon icon="fc-training" extraClassNames="mr10 fa-fw" />
            {t('HOME_SCREEN.INTERACTIVE_TRAINING')}
          </a>
        </div>
        {sqSystemConfiguration.officeHoursEnabled &&
        <div className="mb5 flexFill">
          <a href="https://telemetry.seeq.com/office-hours" target="_blank"
            className="flexColumnContainer p5 link-no-underline homeScreenHelpfulLink">
            <Icon icon="fc-support" extraClassNames="mr10 fa-fw" />
            {t('HOME_SCREEN.OFFICE_HOURS')}
          </a>
        </div>}
      </div>

      {sqSystemConfiguration.customSidebar &&
      <div className="homeScreenNotification mt10 flexRowContainer overflowYScroll" data-testid="customSidebar">
        {cheapMarkdown(sqSystemConfiguration.customSidebar, logger)}
      </div>}
    </div>
  );
};

function cheapMarkdown(s, logger) {
  try {
    return _.chain(s)
      .split('\n')
      .map((s) => {
        let m = /^(#+)(.*)/.exec(s);
        if (m) {
          const [, level, text] = m;
          return React.createElement(`h${level.length + 2}`, {}, text);
        }

        m = /- \[(.*)\]\((http[^)]+)\)/.exec(s);
        if (m) {
          const [, text, href] = m;
          return <div><a href={href} target="_blank">{text}</a></div>;
        }

        m = /- (http[^)]+)/.exec(s);
        if (m) {
          const href = m[1];
          return <div><a href={href} target="_blank">{href}</a></div>;
        }
      })
      .map((element, index) => <div key={index} className="mb5">{element}</div>)
      .value();
  } catch (error) {
    logger.error(error);
  }
}
