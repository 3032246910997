import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Img from 'react-image';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { NotifierService } from '@/services/notifier.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { DEBOUNCE } from '@/main/app.constants';

const worksheetThumbnailBindings = bindingsDefinition({
  worksheetId: prop<string>(),
  workbookId: prop<string>(),
  isActive: prop<boolean>(),
  updatedAt: prop<string>(),
  sqNotifier: injected<NotifierService>()
});

export const WorksheetThumbnail: SeeqComponent<typeof worksheetThumbnailBindings> = (props) => {
  const { worksheetId, workbookId, isActive, updatedAt } = props;
  const { sqNotifier } = useInjectedBindings(worksheetThumbnailBindings);
  const [randomId, setRandomId] = useState(Math.random());
  const [showSpinner, setShowSpinner] = useState(false);

  // Only show the spinner if it's taking longer than 1 second to load the thumbnail
  useEffect(() => {
    const timer = setTimeout(() => setShowSpinner(true), DEBOUNCE.LONG);
    return () => clearTimeout(timer);
  });

  const loadingPlaceholder = showSpinner => (
    <div className="width-100 height-80 text-center lightGreyBorder">
      {showSpinner && <div className="mt35"><IconWithSpinner spinning={true} /></div>}
    </div>
  );

  useEffect(() => {
    return sqNotifier.onThumbnail((updatedWorkbookId, updatedWorksheetId) => {
      if (updatedWorkbookId === workbookId && updatedWorksheetId === worksheetId) {
        setRandomId(Math.random());
      }
    });
  }, [workbookId, worksheetId]);

  return (
    <div>
      <Img id={`${worksheetId}_img`} data-test-id={`${worksheetId}_img`}
        className={classNames('p3', 'lightGreyBorder', { dimmedImage: isActive })}
        src={`/thumbnails/${workbookId}_${worksheetId}.png?t=${updatedAt}&random=${randomId}`}
        alt={`${worksheetId}_img`}
        width={100}
        height={80}
        loader={loadingPlaceholder(showSpinner)}
        unloader={loadingPlaceholder(false)}
      />
      {isActive && <span data-testid="pencilOverlay" className="fa fa-pencil fa-lg worksheetEditingOverlay" />}
    </div>
  );
};
