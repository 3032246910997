import _ from 'lodash';
import angular from 'angular';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { BaseItemStoreService } from '@/trendData/baseItemStore.service';
import { ITEM_DATA_STATUS, ITEM_TYPES } from '@/trendData/trendData.module';

/**
 * A store for containing CapsuleSets. CapsuleSets are used to generate capsules.
 *
 * This store is augmented with additional functionality from sqBaseItemStore.
 */
angular.module('Sq.TrendData').store('sqTrendCapsuleSetStore', sqTrendCapsuleSetStore);

export type TrendCapsuleSetStore = ReturnType<typeof sqTrendCapsuleSetStore>['exports'];

function sqTrendCapsuleSetStore(
  sqTrendCapsuleStore: TrendCapsuleStore,
  sqBaseItemStore: BaseItemStoreService
) {
  const store = {
    exports: { },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @returns {Object} The dehydrated items.
     */
    dehydrate() {
      return {
        items: _.chain(this.state.get('items'))
          .filter(sqBaseItemStore.shouldDehydrateItem)
          .map(item => _.pick(item, sqBaseItemStore.PROPS_TO_DEHYDRATE))
          .value()
      };
    },

    /**
     * Re-creates the capsule sets.
     *
     * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.set('items', _.map(dehydratedState.items, _.bind(function(item) {
        return this.createCapsuleSet(item.id, item.name, _.omit(item, 'id', 'name'));
      }, this)));
    },

    handlers: {
      TREND_ADD_CAPSULE_SET: 'addCapsuleSet'
    },

    /**
     * Adds a CapsuleSet item.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.id - ID of the new CapsuleSet
     * @param {String} payload.name - Name of the new CapsuleSet
     * @param {String} payload.color - Color hex code (e.g. #CCCCCC)
     */
    addCapsuleSet(payload) {
      const props = _.pick(payload, ['color']);
      this.state.push('items', this.createCapsuleSet(payload.id, payload.name, props));
    },

    /**
     * Private helper function to add a capsule set to the store using the specified properties.
     *
     * @param {String} id - ID to use for the new CapsuleSet
     * @param {String} name - Name to use for the new CapsuleSet
     * @param {Object} props - Object containing properties to apply to the new calculation
     * @returns {Object} Newly created CapsuleSet object.
     */
    createCapsuleSet(id, name, props) {
      return this.createItem(id, name, ITEM_TYPES.CAPSULE_SET, _.assign({
        dataStatus: ITEM_DATA_STATUS.INITIALIZING
      }, props));
    }
  };

  return sqBaseItemStore.extend(store);
}
