import angular from 'angular';
import { LicenseManagementStore } from '@/licenseManagement/licenseManagement.store';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { LICENSE_STATUS } from '@/licenseManagement/licenseManagement.module';
import { LicenseManagementActions } from '@/licenseManagement/licenseManagement.actions';

angular.module('Sq.LicenseManagement')
  .controller('LicenseManagementCtrl', LicenseManagementCtrl);

function LicenseManagementCtrl(
  $scope: ng.IScope,
  sqLicenseManagementStore: LicenseManagementStore,
  sqWorkbenchStore: WorkbenchStore,
  sqLicenseManagementActions: LicenseManagementActions) {

  const vm = this;
  vm.showUploadFirst = showUploadFirst;
  vm.headerTranslationKey = headerTranslationKey;
  vm.showSuccess = false;
  vm.uploadComplete = false;
  vm.activate = activate;

  $scope.$listenTo(sqLicenseManagementStore, syncLicenseStore);
  $scope.$listenTo(sqWorkbenchStore, ['currentUser'], syncUser);

  vm.activate();

  /**
   * Initializes controller (re-fetches current license status from backend).
   */
  function activate() {
    sqLicenseManagementActions.fetchLicense();
  }

  /**
   * Syncs the Workbench Store.
   */
  function syncUser() {
    vm.currentUser = sqWorkbenchStore.currentUser;
  }

  /**
   * Syncs the LicenseManagement Store.
   */
  function syncLicenseStore() {
    vm.licenseStatus = sqLicenseManagementStore.licenseStatus;
    vm.isTrialLicense = sqLicenseManagementStore.isTrial;
  }

  /**
   * Determines if the upload or the email text is displayed first. The display changes based on the license status.
   * (NONE, and UNKNOWN will show the email first, all others will show the upload first)
   *
   * @returns {Boolean} true if the upload component should be shown first, false otherwise.
   */
  function showUploadFirst() {
    return vm.licenseStatus !== LICENSE_STATUS.NONE && vm.licenseStatus !== LICENSE_STATUS.UNKNOWN;
  }

  /**
   * Finds the appropriate translation key for the current license status.
   *
   * @returns {String} the translation key to use for the translation.
   */
  function headerTranslationKey() {
    if (!vm.currentUser.isAdmin) {
      if (vm.licenseStatus === LICENSE_STATUS.NONE) {
        return 'LICENSE.THANKS_FOR_INTEREST';
      } else if (vm.licenseStatus === LICENSE_STATUS.EXPIRED) {
        return vm.isTrialLicense ? 'LICENSE.USER_EXPIRED_WARNING_TRIAL' : 'LICENSE.USER_EXPIRED_WARNING';
      }
    } else {
      if (vm.licenseStatus === LICENSE_STATUS.NONE) {
        return 'LICENSE.THANKS_FOR_INSTALLING';
      } else {
        if (vm.licenseStatus === LICENSE_STATUS.UNKNOWN) {
          return 'LICENSE.PROBLEM_HEADER';
        }

        if (vm.isTrialLicense) {
          return 'LICENSE.THANKS_FOR_TRYING';
        } else {
          return 'LICENSE.THANKS_FOR_USING';
        }
      }
    }

  }
}
