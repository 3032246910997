import _ from 'lodash';
import { BaseToolStoreService } from '@/investigate/baseToolStore.service';
import { UtilitiesService } from '@/services/utilities.service';
import { HttpHelpersService } from '@/services/httpHelpers.service';
import { CalculationRunnerService } from '@/services/calculationRunner.service';
import { ProcessTypeEnum } from 'sdk/model/ThresholdMetricOutputV1';
import { ThresholdOutputV1 } from 'sdk/model/ThresholdOutputV1';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { STRING_UOM } from '@/main/app.constants';
import { TableBuilderStore } from '@/hybrid/tableBuilder/tableBuilder.store';
import { TableBuilderMode } from '@/hybrid/tableBuilder/tableBuilder.module';

export type ThresholdMetricStore = ReturnType<typeof sqThresholdMetricStore>['exports'];

export function sqThresholdMetricStore(
  sqBaseToolStore: BaseToolStoreService,
  sqTableBuilderStore: TableBuilderStore,
  sqUtilities: UtilitiesService,
  sqHttpHelpers: HttpHelpersService,
  sqCalculationRunner: CalculationRunnerService,
  sqSystemConfiguration: SystemConfigurationService,
  sqDateTime: DateTimeService
) {
  const store = {
    initialize() {
      this.state = this.immutable(_.assign({}, sqBaseToolStore.COMMON_PROPS, {
        processType: sqTableBuilderStore.mode === TableBuilderMode.Simple ? ProcessTypeEnum.Simple :
          ProcessTypeEnum.Condition,
        aggregationOperator: {
          key: null,
          timeUnits: 's'
        },
        duration: {},
        period: {},
        boundingConditionMaximumDuration: undefined,
        thresholds: {},
        neutralColor: undefined
      }));
    },

    exports: {
      get processType() {
        return this.state.get('processType');
      },

      get boundingConditionMaximumDuration() {
        // Default must be provided here instead of initialize because system config is fetched async
        return this.state.get('boundingConditionMaximumDuration') || sqSystemConfiguration.defaultMaxCapsuleDuration;
      },

      get aggregationOperator() {
        return this.state.get('aggregationOperator');
      },

      get duration() {
        return this.state.get('duration');
      },

      get period() {
        return this.state.get('period');
      },

      get thresholds() {
        return this.state.get('thresholds');
      },

      get neutralColor() {
        return this.state.get('neutralColor');
      },

      /**
       * Utility function that gets the string representation of the threshold, which will be an ID if the threshold
       * is an item, or else a numeric value with optional units if the threshold is a value.
       *
       * @param {ThresholdOutputV1} threshold - the threshold
       * @returns {string} a string representation of the threshold
       */
      getThresholdString(threshold: ThresholdOutputV1) {
        return !!threshold.isGenerated ?
          _.trim(`${threshold.value.value} ${threshold.value.uom === STRING_UOM || !threshold.value.uom ? '' :
            threshold.value.uom}`)
          : _.get(threshold, 'item.id');
      }
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @return {Object} State for the store
     */
    dehydrate() {
      return this.state.serialize();
    },

    /**
     * Sets the scorecard panel state
     *
     * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      THRESHOLD_METRIC_SET_PROCESS_TYPE: 'setProcessType',
      THRESHOLD_METRIC_SET_AGGREGATION_OPERATOR: 'setAggregationOperator',
      THRESHOLD_METRIC_SET_DURATION: 'setDuration',
      THRESHOLD_METRIC_SET_PERIOD: 'setPeriod',
      THRESHOLD_METRIC_SET_BOUNDING_CONDITION_MAXIMUM_DURATION: 'setBoundingConditionMaximumDuration',
      THRESHOLD_METRIC_SET_THRESHOLD: 'setThreshold',
      THRESHOLD_METRIC_SET_THRESHOLD_COLOR: 'setCustomThresholdColor',
      TOOL_REHYDRATE_FOR_EDIT: 'thresholdMetricRehydrateForEdit'
    },

    /**
     * Set the process type to use for this metric
     *
     * @param {Object} payload - Object container
     * @param {ProcessTypeEnum} payload.processType - one of the types
     */
    setProcessType({ processType }) {
      this.state.set('processType', processType);
    },

    /**
     * Set the aggregation operator to use for this metric
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.aggregationOperator - the object from the statistic selector form
     */
    setAggregationOperator({ aggregationOperator }) {
      this.state.set('aggregationOperator', aggregationOperator);
    },

    /**
     * Set the duration for this metric, in the case that it is a continuous process
     *
     * @param {Object} payload - an Object representing state.
     * @param {Number} payload.value - The number that indicates how long the duration is
     * @param {String} payload.units - The units that the value represents
     */
    setDuration({ value, units }) {
      this.state.set('duration', { value, units });
    },

    /**
     * Set the period for this metric, in the case that it is a continuous process
     *
     * @param {Object} payload - an Object representing state.
     * @param {Number} payload.value - The number that indicates how long the period is
     * @param {String} payload.units - The units that the value represents
     */
    setPeriod({ value, units }) {
      this.state.set('period', { value, units });
    },

    /**
     * Sets the maximum capsule duration for the bounding condition.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.value - The number that indicates how long the maximum duration is
     * @param {String} payload.units - The units that the value represents
     */
    setBoundingConditionMaximumDuration({ value, units }) {
      this.state.set('boundingConditionMaximumDuration', { value, units });
    },

    /**
     * Set the transition value for one of the thresholds or removes it.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.level - The priority level for the threshold in the list of thresholds
     * @param {string} payload.threshold - The threshold value
     */
    setThreshold(payload: { level: number, threshold: string }) {
      if (_.isUndefined(payload.threshold)) {
        this.state.unset(['thresholds', payload.level]);
      } else {
        this.state.set(['thresholds', payload.level, 'threshold'], payload.threshold);
      }

      if (_.isEmpty(this.state.get('thresholds'))) {
        this.state.unset('neutralColor');
      }
    },

    /**
     * Set a custom color for one of the thresholds. Handles the special neutral case (level 0) which does not
     * actually have a threshold, by storing it in a separate field.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.level - The priority level
     * @param {string} payload.color - The color to use for the priority level.
     */
    setCustomThresholdColor(payload: { level: number, color: string }) {
      if (payload.level === 0) {
        this.state.set('neutralColor', payload.color);
      } else {
        this.state.set(['thresholds', payload.level, 'color'], payload.color);
      }
    },

    /**
     * Used to rehydrate a threshold metric so it can be edited.
     *
     * @param {Object} payload - An object with the necessary state to populate the edit form.
     * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
     * @param {String} payload.id - the metric ID
     * @param {String} payload.name - The name of the metric item
     * @param {Boolean} payload.advancedParametersCollapsed - whether the advanced panel is collapsed
     * @param {Object[]} payload.parameters - The threshold metric parameters. All threshold metric specific properties
     *   are passed to the store in the parameters array.
     */
    thresholdMetricRehydrateForEdit(payload) {
      if (payload.type !== TREND_TOOLS.THRESHOLD_METRIC) {
        return;
      }

      this.rehydrateForEdit(payload);

      // Set the aggregation operator based on the statistic fragment
      const operator = sqCalculationRunner.getStatisticFromFragment(payload.aggregationFunction);
      if (operator) {
        const aggregationOperator = {
          key: operator.key,
          timeUnits: _.get(operator, 'timeUnits', this.state.get('aggregationOperator').timeUnits),
          percentile: _.get(operator, 'percentile', this.state.get('aggregationOperator').percentile)
        };
        this.setAggregationOperator({ aggregationOperator });
      }

      _.forEach(['duration', 'period', 'boundingConditionMaximumDuration'], (key) => {
        const defaultDuration = _.includes(['boundingConditionMaximumDuration'], key) ?
          sqSystemConfiguration.defaultMaxCapsuleDuration : {};

        this.state.set(key, sqDateTime.convertDuration(payload[key]) || defaultDuration);
      });

      this.setProcessType(payload);

      // Set the thresholds
      _.forEach(payload.thresholds as ThresholdOutputV1[], (threshold) => {
        this.setThreshold({
          level: threshold.priority.level,
          threshold: this.exports.getThresholdString(threshold)
        });
        this.setCustomThresholdColor({ level: threshold.priority.level, color: threshold.priority.color });
      });
    },

    /**
     * Callback from baseToolStore. Prevent the base store from merging properties that require custom processing
     * and are handled by this store.
     */
    migrateSavedConfig(config) {
      return _.omit(config, [
        'aggregationOperator',
        'duration',
        'period',
        'boundingConditionMaximumDuration',
        'thresholds'
      ]);
    },

    /**
     * Callback from baseToolStore to modify config parameters
     */
    modifyConfigParams(config) {
      return _.pick(config, ['type', 'advancedParametersCollapsed']);
    }
  };

  return sqBaseToolStore.extend(store, TREND_TOOLS.THRESHOLD_METRIC, {
    measuredItem: { predicate: ['name', 'measuredItem'] },
    boundingCondition: { predicate: ['name', 'boundingCondition'] }
  });
}
