import _ from 'lodash';
import angular from 'angular';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { LicenseManagementStore } from '@/licenseManagement/licenseManagement.store';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { AuthorizationService } from '@/services/authorization.service';
import { WorkbookStore } from '@/workbook/workbook.store';
import { NotificationsService } from '@/services/notifications.service';
import { WorkbookActions } from '@/workbook/workbook.actions';
import { AuthenticationService } from '@/services/authentication.service';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { NotifierService } from '@/services/notifier.service';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.module';
import { APP_STATE, APPSERVER_API_PREFIX, HOME_SCREEN_TABS } from '@/main/app.constants';
import { LICENSE_STATUS } from '@/licenseManagement/licenseManagement.module';
import { ModalService } from '@/services/modal.service';
import { PdfPreviewModalService } from '@/reportEditor/pdfPreviewModal.service';
import { UtilitiesService } from '@/services/utilities.service';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import screenfullModule from 'screenfull';

// This module has really funky type of false | Screenfull - force it to a reasonable type
const screenfull = screenfullModule as screenfull.Screenfull;

angular.module('Sq.Header').controller('HeaderCtrl', HeaderCtrl);

function HeaderCtrl(
  $rootScope: ng.IRootScopeService,
  $scope: ng.IScope,
  $state: ng.ui.IStateService,
  $window: ng.IWindowService,
  $q: ng.IQService,
  sqModal: ModalService,
  $element: JQuery,
  sqWorksheetActions: WorksheetActions,
  sqWorkbenchStore: WorkbenchStore,
  sqLicenseManagementStore: LicenseManagementStore,
  sqWorkbenchActions: WorkbenchActions,
  sqAuthentication: AuthenticationService,
  sqAuthorization: AuthorizationService,
  sqWorkbookStore: WorkbookStore,
  sqWorkbookActions: WorkbookActions,
  sqNotifications: NotificationsService,
  sqPendingRequests: PendingRequestsService,
  sqNotifier: NotifierService,
  sqPdfPreviewModal: PdfPreviewModalService,
  sqUtilities: UtilitiesService,
  sqHomeScreenUtilities: HomeScreenUtilitiesService,
  sqSystemConfiguration: SystemConfigurationService
) {
  const vm = this;

  vm.APP_STATE = APP_STATE;
  vm.setWorkbookName = setWorkbookName;
  vm.setWorksheetName = setWorksheetName;
  vm.logout = sqAuthentication.logout;
  vm.canModifyWorkbook = sqAuthorization.canModifyWorkbook;
  vm.canManageItem = sqAuthorization.canManageItem;
  vm.canUseAdminTools = sqAuthorization.isAdmin;
  vm.canViewLogs = sqAuthorization.canViewLogs;
  vm.canCancelAllRequests = sqAuthorization.canCancelAllRequests;
  vm.canReadAuditTrail = sqAuthorization.canReadAuditTrail;
  vm.gotoWorkbooks = gotoWorkbooks;
  vm.openApiReference = openApiReference;
  vm.openAdminTools = openAdminTools;
  vm.toggleProfile = toggleProfile;
  vm.isEditing = {};
  vm.currentUserDisplay = '';
  vm.closeProfileWindow = _.partial(sqWorkbenchActions.setUserProfileDisplay, false);
  vm.cancelCurrentUserServerRequests = cancelCurrentUserServerRequests;
  vm.cancelAllServerRequests = cancelAllServerRequests;
  vm.openACLModal = openACLModal;
  vm.breadcrumbs = [];
  vm.hasBreadcrumbs = false;
  vm.exportToPdf = () => sqPdfPreviewModal.showModal();
  vm.showAccessKeyModal = false;
  vm.openAccessKeyModal = () => { vm.showAccessKeyModal = true; };
  vm.closeAccessKeyModal = () => { vm.showAccessKeyModal = false; };
  vm.goToEditMode = goToEditMode;
  vm.modalOpen = false;
  vm.itemId = undefined;
  vm.datasourceManagementEnabled = sqSystemConfiguration.datasourceManagementEnabled;
  vm.translateFolder = sqHomeScreenUtilities.translateBreadcrumb;
  vm.toggleFullScreen = () => {
    screenfull.toggle();
  };
  vm.initialFullScreenState = screenfull.isFullscreen;

  // This is called by react code, so we need to explicitly trigger the digest cycle.
  // Angular will throw an exception if $digest is called in a $digest cycle, where as $evalAsync will wait for the
  // next cycle if one is already running.
  vm.closeModal = () => $scope.$evalAsync(() => vm.modalOpen = false);

  $scope.$listenTo(sqWorkbenchStore, setHeaderVars);
  $scope.$listenTo(sqWorkbookStore, setHeaderVars);
  $scope.$listenTo(sqLicenseManagementStore, syncLicenseStore);
  $scope.$on('$stateChangeSuccess', setHeaderVars);

  /**
   * Rename workbook.
   *
   * @param {String} name - The new name for the workbook
   */
  function setWorkbookName(name) {
    sqWorkbookActions.setName(vm.workbookId, name, sqWorkbookStore.name);
  }

  /**
   * Rename worksheet.
   *
   * @param {String} name - The new name for the worksheet
   */
  function setWorksheetName(name) {
    sqWorkbookActions.renameWorksheet(vm.worksheetId, name);
  }

  /**
   * If the license status is NONE, UNKNOWN or EXPIRED certain actions are not possible.
   */
  function syncLicenseStore() {
    if (sqLicenseManagementStore.licenseStatus === LICENSE_STATUS.NONE ||
      sqLicenseManagementStore.licenseStatus === LICENSE_STATUS.UNKNOWN ||
      sqLicenseManagementStore.licenseStatus === LICENSE_STATUS.EXPIRED) {
      vm.accessibleWithCurrentLicense = false;
    } else {
      vm.accessibleWithCurrentLicense = true;
    }
  }

  /**
   * Go to workbooks view with the currently selected workbook and force reload
   * so we see any updates that have been made by others.
   *
   * If the new home screen is enabled this will take you to the "home" tab
   */
  function gotoWorkbooks() {
    $state.go(APP_STATE.WORKBOOKS, {}, { reload: true });
  }

  /**
   * Workbench-level and $state state
   */
  function setHeaderVars() {
    const emptyWorkbook = { name: '', owner: '', effectivePermissions: {} };
    // immediately remove the title from the header after a new item has been selected or is being opened.
    if (sqWorkbenchStore.loadingItemId || sqWorkbenchStore.openingItemId) {
      vm.workbook = emptyWorkbook;
    }
    // Hold the rest of the workbook vars constant until the state change is complete. Otherwise, the error page will
    // flash because the workbook display has not been set.
    if ($state.transition) {
      return;
    }

    vm.currentUserDisplay = sqWorkbenchStore.currentUser.firstName;
    vm.workbookId = sqWorkbenchStore.stateParams.workbookId;
    const folderId = sqWorkbenchStore.stateParams.currentFolderId;
    const selectedFolder = sqWorkbenchStore.stateParams.folderId;
    vm.worksheetId = sqWorkbenchStore.stateParams.worksheetId;
    vm.worksheetShown = $state.is(APP_STATE.WORKSHEET) || $state.is(APP_STATE.VIEW_WORKSHEET) ||
      $state.is(APP_STATE.PRESENT_WORKSHEET);

    if ((vm.workbookId || folderId || selectedFolder) && !(sqWorkbenchStore.loadingItemId || sqWorkbenchStore.openingItemId)) {
      vm.workbook = {
        name: sqWorkbookStore.name,
        owner: sqWorkbookStore.owner,
        effectivePermissions: sqWorkbookStore.effectivePermissions
      };
    } else {
      vm.workbook = emptyWorkbook;
    }

    vm.isEditMode = vm.worksheetShown && sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.EDIT;
    vm.isViewMode = vm.worksheetShown && sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.VIEW;
    vm.isPresentationMode = vm.worksheetShown && sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.PRESENT;
    vm.worksheetName = vm.worksheetId && sqWorkbookStore.getWorksheetName(vm.worksheetId);
    vm.isReportBinder = sqWorkbookStore.isReportBinder;

    // Handles setting/displaying breadcrumbs in header if in worksheet view
    vm.breadcrumbs = (vm.isEditMode && vm.canModifyWorkbook(vm.workbook)) ? sqWorkbookStore.ancestors : [];
    vm.hasBreadcrumbs = !!vm.breadcrumbs.length;

    // Fixes scrolling reset issue with workbook name
    $element.find('.headerLabel').scrollLeft(0);
  }

  /**
   * Opens the API Reference (swagger-ui) page in a new browser tab.
   */
  function openApiReference() {
    // Construct a new URL to the apiReference page using, using the current protocol/host/port
    const swaggerUrl = '/api-docs/?baseUrl=' + $window.encodeURIComponent(APPSERVER_API_PREFIX + '/swagger.json');
    $window.open(swaggerUrl);
  }

  /**
   * Opens the Administration tools in a new window.
   */
  function openAdminTools() {
    $window.open('/administration', 'SeeqAdmin');
  }

  /**
   * Cancels all server requests for the current user
   */
  function cancelCurrentUserServerRequests() {
    return sqPendingRequests.cancelCurrentUserServerRequests()
      .then(_.partial(sqNotifications.successTranslate, 'REQUEST_CANCELLATION.SUCCESS_USER'))
      .catch(function(result) {
        sqNotifications.warnTranslate('REQUEST_CANCELLATION.FAILURE');
        return result;
      });
  }

  /**
   * Cancels all server requests
   */
  function cancelAllServerRequests() {
    return sqPendingRequests.cancelAllServerRequests()
      .then(sqNotifier.emitAllServerRequestsCanceled)
      .then(_.partial(sqNotifications.successTranslate, 'REQUEST_CANCELLATION.SUCCESS_ALL'))
      .catch(function(result) {
        sqNotifications.warnTranslate('REQUEST_CANCELLATION.FAILURE');
        return result;
      });
  }

  /**
   * Toggles the "Edit your profile" window.
   */
  function toggleProfile() {
    sqWorkbenchActions.setUserProfileDisplay(!sqWorkbenchStore.displayUserProfile);
  }

  /**
   * Opens the ACL modal dialog for the current Workbook.
   */
  function openACLModal() {
    vm.itemId = $state.params.workbookId;
    vm.modalOpen = true;
  }

  /**
   * Opens another tab in edit mode
   *
   * @param {Object} $event - The click event
   */
  function goToEditMode($event) {
    $state.goNewTab(APP_STATE.WORKSHEET, {
      workbookId: vm.workbookId,
      worksheetId: vm.worksheetId
    }, $event);
  }
}
