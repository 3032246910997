import _ from 'lodash';
import angular from 'angular';
import { NotificationsService } from '@/services/notifications.service';
import { UtilitiesService } from '@/services/utilities.service';

angular.module('Sq.TrendData').factory('sqCalculations', sqCalculations);

export type CalculationsService = ReturnType<typeof sqCalculations>;

function sqCalculations(
  sqNotifications: NotificationsService,
  sqUtilities: UtilitiesService) {
  const service = {
    performYAlignment
  };

  return service;

  /**
   * This function calls the alignment functions as specified by types
   * @param {Array} items The series array.
   * @param {Array} types The type(s) of alignment to be performed.
   * @return {Object} An object containing chartMinY, chartMaxY and autoDisabled values
   */
  function performYAlignment(items, typesArray) {
    const itemsWithData = _.filter(items, _.flow(_.property('capsuleSegmentData'), _.negate(_.isEmpty)));

    // The value of the types argument has to match up with the available calculations on the time series item
    // If no alignment options are specified all traces are shown 'as is', without forcing any alignment
    if (_.isEmpty(typesArray)) {
      return noAlignment(itemsWithData);
    } else if (typesArray.length === 2) {
      // two point algorithm
      return twoPoints(itemsWithData, typesArray[0], typesArray[1]);
    } else if (typesArray.length === 1) {
      // one point algorithm
      return singlePoint(itemsWithData, typesArray[0]);
    }
  }

  /*
   * Finds the absolute lowest and highest Y values for the series.
   * @param {Array} items The series array.
   */
  function noAlignment(items) {
    const allCalculations = _.map(items, _.flow(_.property('capsuleSegmentData'), getCalculations));
    const minYEntry = _.minBy(allCalculations, 'yMin');
    const maxYEntry = _.maxBy(allCalculations, 'yMax');

    return _.map(items, function(item: any) {
      return {
        id: item.id,
        autoDisabled: false,
        chartMinY: minYEntry.yMin,
        chartMaxY: maxYEntry.yMax
      };
    });
  }

  /*
   * Finds the lowest and highest Y values for the series chart
   * @param {Array} items The series array.
   * @param {String} type The type of single point calculation.
   */
  function singlePoint(items, type) {
    let yAbove = 0;
    let yBelow = 0;
    let yPadding = 0;
    let paddedYAbove = 0;
    let paddedYBelow = 0;
    const commonYValue = {};

    _.forEach(items, function(item: any) {
      const calculations = getCalculations(item.capsuleSegmentData);
      commonYValue[item.id] = calculations[type];

      // Sets largest difference between the max Y and the common Y value and the largest difference between the
      // the common Y value and min Y.
      if (commonYValue[item.id] - calculations.yMin > yBelow) {
        yBelow = commonYValue[item.id] - calculations.yMin;
      }

      if (calculations.yMax - commonYValue[item.id] > yAbove) {
        yAbove = calculations.yMax - commonYValue[item.id];
      }
    });

    // Sets padding based on the yAbove and yBelow values.
    yPadding = (yAbove + yBelow) * 0.05;
    paddedYBelow = yBelow + yPadding;
    paddedYAbove = yAbove + yPadding;

    return _.map(items, function(item: any) {
      return {
        id: item.id,
        autoDisabled: false,
        chartMinY: commonYValue[item.id] - paddedYBelow,
        chartMaxY: commonYValue[item.id] + paddedYAbove
      };
    });
  }

  /*
   * Finds the lowest and highest Y values for the series chart based on two specified points
   * @param {Array} items The series array.
   * @param {String} type1 The first choice to be used in the calculation (supported are: start, end, avg, yMin, yMax)
   * @param {String} type2 The second choice to be used in the calculation (supported are: start, end, avg, yMin, yMax)
   *
   * @return {Boolean} True if alignment was successful, false otherwise.
   */
  function twoPoints(items, type1, type2) {
    // Buffer to prevent the traces from 'sticking' to the bottom and top of the graph.
    const BUFFER = 0.125;
    let bufferMin;
    let bufferMax;
    let i = 0;

    // Absolute minimum and maximum ranges across all series
    let minMinR = null;
    let maxMaxR = null;

    // Flag to indicate if the alignment is in invalid and if so show an error message
    let alignmentFailed = false;
    let isSlopePositive = false;
    let isPrimarySlopePositive = false;
    let choice1 = null;
    let choice2 = null;
    let point1 = null;
    let point2 = null;
    let range = null;
    let minR = null;
    let maxR = null;
    let calculations;
    let result;
    const results = [];

    for (i; i < items.length; i++) {
      calculations = getCalculations(items[i].capsuleSegmentData);
      result = {};
      results[i] = result;

      // There is no way to two-point align lines with differently signed slopes within the alignment choicese without
      // flipping them which isn't useful, so instead they are hidden from the graph
      // and not included in the calculations for minR and maxR
      isSlopePositive = calculations[type1] < calculations[type2];
      if (i === 0) {
        isPrimarySlopePositive = isSlopePositive;
      }

      result.autoDisabled = isPrimarySlopePositive !== isSlopePositive;
      if (result.autoDisabled) {
        continue;
      }

      choice1 = calculations[type1];
      choice2 = calculations[type2];

      // For right now we make sure that point1 is the lesser of the 2 values
      point1 = choice1 < choice2 ? choice1 : choice2;
      point2 = choice1 < choice2 ? choice2 : choice1;

      range = point2 - point1;
      if (range !== 0) {
        minR = (calculations.yMin - point1) / range;
        maxR = (calculations.yMax - point1) / range;

        // Figure out the absolute min/max variations:
        if (minMinR === null) {
          minMinR = minR;
        } else if (minR < minMinR) {
          minMinR = minR;
        }

        if (maxMaxR === null) {
          maxMaxR = maxR;
        } else if (maxMaxR < maxR) {
          maxMaxR = maxR;
        }
      } else {
        alignmentFailed = true;
        break;
      }

      // Set the values necessary for further calculation on the series;
      result.point1 = point1;
      result.point2 = point2;
      result.range = range;
    }

    if (alignmentFailed) {
      sqNotifications.errorTranslate('NOT_A_VALID_COMBINATION');
      return [];
    }

    bufferMin = minMinR - BUFFER * (maxMaxR - minMinR);
    bufferMax = maxMaxR + BUFFER * (maxMaxR - minMinR);

    return _.map(items, function(item: any, i) {
      return {
        id: item.id,
        autoDisabled: results[i].autoDisabled,

        // This will cause scaling of the trend, as well as translation
        chartMinY: bufferMin * results[i].range + results[i].point1,
        chartMaxY: bufferMax * results[i].range + results[i].point1
      };
    });
  }

  /**
   * Calculates the min/max/avg/middle Y value in a series
   * @param {Array} samples The data array
   * @return {Object} The object that contains the default calculations for reference within a series item
   */
  function getCalculations(samples): any {
    const yValues = _.map(samples, (sample) => { return sqUtilities.getPointAsArray(sample)[1]; });
    const yMin = _.min(yValues) as number;
    const yMax = _.max(yValues) as number;
    const sum = _.sum(yValues) as number;

    // Series may have different number of data points so we must find or interpolate a y-value that matches the middle
    // time
    const middleTime = sqUtilities.getPointAsArray(_.last(samples))[0] / 2;
    return {
      start: _.head(yValues),
      end: _.last(yValues),
      sum,
      avg: sum / yValues.length,
      middle: sqUtilities.getYValue(samples, middleTime).yValue,
      yMin,
      yMax,
      rangeY: yMax - yMin
    };
  }
}
