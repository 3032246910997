import _ from 'lodash';
import Highcharts from 'highcharts';

export type XYRegion = {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
  id?: string;
  dateTime?: string;
};

export const EMPTY_XYREGION: XYRegion = {
  xMin: null,
  xMax: null,
  yMin: null,
  yMax: null,
  id: null,
  dateTime: null
};

export interface XYPixelRegion {
  xMinPixel: number;
  xMaxPixel: number;
  yMinPixel: number;
  yMaxPixel: number;
  id: string;
  dateTime: string;
}

export type pixelTranslationFunction = (chart: Highcharts.Chart, minVal: number,
  maxVal: number) => { minPixel: number, maxPixel: number };

/**
 * detect incorrect regions on trend
 */
export function isInvalidPixel(capsuleRegion: XYPixelRegion) {
  const { xMinPixel, xMaxPixel } = capsuleRegion;
  return !_.isFinite(xMinPixel) || !_.isFinite(xMaxPixel) || xMinPixel >= xMaxPixel;
}

/**
 * translate the XYRegion to a pixel region (with an id and dateTime if it is a capsule)
 */
export function translateRegion(chart, xAxis, yAxis, translate, XYRegion): XYPixelRegion {
  const { xMin, xMax, yMin, yMax } = XYRegion;
  const xPixels = translateAxis(xMin, xMax, xAxis, translate.x);
  const yPixels = translateAxis(yMin, yMax, yAxis, translate.y);

  function translateAxis(min, max, axis, translate) {
    if (translate) {
      return translate(chart, min, max);
    } else {
      const extremes = axis.getExtremes();
      return {
        minPixel: axis.translate(Math.max(min, extremes.min)),
        maxPixel: axis.translate(Math.min(max, extremes.max))
      };
    }
  }

  return {
    xMinPixel: xPixels.minPixel,
    xMaxPixel: xPixels.maxPixel,
    yMinPixel: yPixels.minPixel,
    yMaxPixel: yPixels.maxPixel,
    id: XYRegion.id,
    dateTime: XYRegion.dateTime
  };
}
