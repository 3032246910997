import _ from 'lodash';
import { SEARCH_ITEM_LOCATIONS } from '@/main/app.constants';
import { INITIALIZE_MODE, PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';

export type ExploreFolderModalStore = ReturnType<typeof sqExploreFolderModalStore>['exports'];

export function sqExploreFolderModalStore() {

  return {
    persistenceLevel: PERSISTENCE_LEVEL.WORKSHEET,

    initialize(initializeMode) {
      const saveState = this.state && initializeMode !== INITIALIZE_MODE.FORCE;
      this.state = this.immutable({
        initialFolderId: undefined,
        tableFilter: saveState ? this.state.get('tableFilter') : SEARCH_ITEM_LOCATIONS.MY_FOLDER,
        itemTotalForTable: 0,
        pageSize: saveState ? this.state.get('pageSize') : 5,
        pageNumber: 1,
        searchParams: { types: ['Folder'] },
        items: [],
        loading: false,
        folder: undefined,
        sortAscending: false,
        sortProperty: saveState ? this.state.get('sortProperty') : 'name',
        showResults: false,
        workbookId: '',
        tableRoot: undefined
      });
    },

    exports: {
      get initialFolderId() {
        return this.state.get('initialFolderId');
      },

      get workbookId() {
        return this.state.get('workbookId');
      },

      get tableFilter() {
        return this.state.get('tableFilter');
      },

      get searchParams() {
        return this.state.get('searchParams');
      },

      get items() {
        return this.state.get('items');
      },

      get loading() {
        return this.state.get('loading');
      },

      get folder() {
        return this.state.get('folder');
      },

      get sortAscending() {
        return this.state.get('sortAscending');
      },

      get sortProperty() {
        return this.state.get('sortProperty');
      },

      get showResults() {
        return this.state.get('showResults');
      },

      get tableRoot() {
        return this.state.get('tableRoot');
      },

      getItemTotalForTable() {
        return this.state.get('itemTotalForTable');
      },

      getPageSizeByTable() {
        return this.state.get('pageSize');
      },

      getPageNumberForTable() {
        return this.state.get('pageNumber');
      }
    },

    dehydrate() {
      return _.omit(this.state.serialize(),
        ['effectivePermissions', 'workbookId', 'initialFolderId', 'searchParams', 'items', 'folder',
          'tableFilter', 'itemTotalForTable', 'pageNumber', 'sortAscending', 'sortProperty', 'loading', 'showResults',
          'tableRoot']);
    },

    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      EXPLORE_FOLDER_MODAL_CLEAR: 'clear',
      EXPLORE_FOLDER_MODAL_SET_INITIAL_FOLDER_ID: 'setInitialFolderId',
      EXPLORE_FOLDER_MODAL_SET_WORKBOOK_ID: 'setWorkbookId',
      EXPLORE_FOLDER_MODAL_SET_TABLE_FILTER: 'setTableFilter',
      EXPLORE_FOLDER_MODAL_SET_ITEM_TOTAL_FOR_TABLE: 'setItemTotalForTable',
      EXPLORE_FOLDER_MODAL_SET_PAGE_SIZE: 'setPageSize',
      EXPLORE_FOLDER_MODAL_SET_PAGE_NUMBER: 'setPageNumber',
      EXPLORE_FOLDER_MODAL_SET_SEARCH_PARAMS: 'setSearchParams',
      EXPLORE_FOLDER_MODAL_SET_ITEMS: 'setItems',
      EXPLORE_FOLDER_MODAL_SET_LOADING: 'setLoading',
      EXPLORE_FOLDER_MODAL_SET_FOLDER: 'setFolder',
      EXPLORE_FOLDER_MODAL_SET_SORT: 'setSort',
      EXPLORE_FOLDER_MODAL_SET_SHOW_RESULTS: 'setShowResults',
      EXPLORE_FOLDER_MODAL_SET_TABLE_ROOT: 'setTableRoot'
    },

    /**
     * Clears the state for the currently loaded seeq content image.
     */
    clear() {
      this.initialize();
    },

    setInitialFolderId(payload) {
      this.state.set('initialFolderId', payload.initialFolderId);
    },

    /**
     * Sets the sort field and direction that controls the sorting for workbooks.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.workbooksSortField - One of WORKBOOKS_SORT_FIELDS
     * @param {Boolean} payload.workbooksSortAsc - True to sort ascending, false otherwise
     */
    setWorkbooksSort(payload) {
      this.state.merge(_.pick(payload, ['workbooksSortField', 'workbooksSortAsc']));
    },

    /**
     * Sets the workbook ID
     *
     * @param {Object} payload - Object container
     * @param {String} payload.workbookId - a workbook ID
     */
    setWorkbookId(payload) {
      this.state.set('workbookId', payload.workbookId);
    },

    setTableFilter(payload) {
      this.state.set('tableFilter', payload.tableFilter);
    },

    setItemTotalForTable(payload) {
      this.state.set('itemTotalForTable', payload.itemTotalForTable);
    },

    setPageSize(payload) {
      this.state.set('pageSize', payload.pageSize);
    },

    setPageNumber(payload) {
      this.state.set('pageNumber', payload.pageNumber);
    },

    setItems(payload) {
      this.state.set('items', payload.items);
    },

    setSearchParams({ field, value }) {
      const searchParams = this.state.get('searchParams');

      if (value === '') {
        this.state.set('searchParams', _.omit(searchParams, field));
      } else {
        this.state.set('searchParams', _.assign({}, searchParams, { [field]: value }));
      }
    },

    setLoading(payload) {
      this.state.set('loading', payload.loading);
    },

    setFolder(payload) {
      this.state.set('folder', payload.folder);
    },

    setSort(payload) {
      this.state.merge(_.pick(payload, ['sortProperty', 'sortAscending']));
    },

    setShowResults(payload) {
      this.state.set('showResults', payload.showResults);
    },

    setTableRoot(payload) {
      this.state.set('tableRoot', payload.root);
    }
  };
}

