import _ from 'lodash';
import angular from 'angular';
import HttpCodes from 'http-status-codes';
import { AuthorizationService } from '@/services/authorization.service';
import { UtilitiesService } from '@/services/utilities.service';

angular.module('Sq.Utilities').controller('AclStatusCtrl', AclStatusCtrl);

function AclStatusCtrl(
  $scope: ng.IScope,
  sqAuthorization: AuthorizationService,
  sqUtilities: UtilitiesService,
  SqACLService) {
  const vm = this;
  vm.getLabel = getLabel;
  vm.getAccess = getAccess;
  vm.openACLModal = openACLModal;
  vm.canManage = () => sqAuthorization.canManageItem(vm.item);
  vm.isLoaded = () => !_.isUndefined(vm.exclusiveAccess);
  vm.isAsset = () => sqUtilities.isAsset(vm.item);
  vm.modalOpen = false;
  vm.itemId = undefined;
  // This is called by react code, so we need to explicitly trigger the digest cycle.
  // Angular will throw an exception if $digest is called in a $digest cycle, where as $evalAsync will wait for the
  // next cycle if one is already running.
  vm.closeModal = () => $scope.$evalAsync(() => {
    vm.modalOpen = false;
    loadAcl();
  });

  /**
   * AngularJS lifecycle callback that is called whenever a component binding changes
   */
  vm.$onChanges = (changes) => {
    if (changes.item) {
      loadAcl();
    }
  };

  /**
   * Loads the item (or swap source item) ACL to determine if the user has exclusive access to the item
   */
  function loadAcl() {
    vm.exclusiveAccess = undefined;

    const id = _.get(vm, 'item.id');

    if (!id) {
      return;
    }

    vm.isLoading = true;
    // Look at the swap source's ACL entries if this is a swap
    SqACLService.getSwapSourceIdIfSwap(id)
      .then(sourceId => SqACLService.getItemACL(sourceId))
      .then(({ entries }) => {
        vm.exclusiveAccess = sqAuthorization.userHasExclusiveAccess(entries);
      })
      .catch(({ status }) => {
        if (status === HttpCodes.FORBIDDEN) {
          vm.exclusiveAccess = false;
        }
      })
      .finally(() => {
        vm.isLoading = false;
      });
  }

  /**
   * Gets an appropriate label based on whether the user can manage the item or not
   */
  function getLabel() {
    return `ACL_STATUS.${vm.canManage() ? 'ACCESS_CONTROL' : 'YOUR_PERMISSIONS'}`;
  }

  /**
   * Gets an appropriate access description based on whether the user is an admin, if they can manage the item, if
   * they have exclusive access, and their effective permissions.
   */
  function getAccess() {
    if (vm.canManage()) {
      if (sqAuthorization.isAdmin()) {
        return 'ACL_STATUS.ACCESS.ADMIN';
      } else {
        return `ACL_STATUS.ACCESS.${vm.exclusiveAccess ? 'ONLY_YOU' : 'YOU_AND_OTHERS'}`;
      }
    } else {
      return sqUtilities.prettyPermissions(vm.item.effectivePermissions, 'ACL_STATUS.ACCESS.NO_PERMISSIONS');
    }
  }

  /**
   * Opens the ACL modal and updates exclusive access using the returned value
   */
  function openACLModal() {
    SqACLService.getSwapSourceIdIfSwap(vm.item.id)
      .then((id) => {
        vm.itemId = id;
        vm.modalOpen = true;
      });
  }
}
