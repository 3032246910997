import _ from 'lodash';
import angular from 'angular';
import { NotificationsService } from '@/services/notifications.service';
import { AdministrationActions } from '@/administration/administration.actions';
import { TrackService } from '@/track/track.service';
import { UtilitiesService } from '@/services/utilities.service';
import { UserGroupsApi } from 'sdk/api/UserGroupsApi';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { API_KEY_SUFFIX, EVERYONE_USERGROUP, SYSTEM_USERNAME } from '@/hybrid/administration/Administration.page';

angular.module('Sq.Administration').controller('GroupAddEditCtrl', GroupAddEditCtrl);

function GroupAddEditCtrl(
  $scope: ng.IScope,
  $translate: ng.translate.ITranslateService,
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  $q: ng.IQService,
  sqNotifications: NotificationsService,
  sqAdministrationActions: AdministrationActions,
  sqUtilities: UtilitiesService,
  sqTrack: TrackService,
  sqUserGroupsApi: UserGroupsApi,
  group,
  allUsers,
  allGroups,
  isReadOnly,
  isEveryone
) {

  const vm = this;
  const newGroupStatus = [{ value: true, label: $translate.instant('ADMIN.ENABLED') }];
  let existingGroupStatus = [{ value: false, label: $translate.instant('ADMIN.DISABLED') }];
  existingGroupStatus = existingGroupStatus.concat(newGroupStatus);
  vm.close = $uibModalInstance.close;
  vm.addOrUpdateGroup = addOrUpdateGroup;
  vm.getGroupStatus = getGroupStatus;
  vm.getGroupTitle = getGroupTitle;

  vm.addMembers = addMembers;
  vm.removeMembers = removeMembers;
  vm.updateSelectedAvailableMembers = updateSelectedAvailableMembers;
  vm.isSelectedAvailable = isSelectedAvailable;
  vm.updateSelectedCurrentMembers = updateSelectedCurrentMembers;
  vm.isSelectedCurrent = isSelectedCurrent;
  vm.markAmbiguousIdentities = markAmbiguousIdentities; // used only for testing

  $scope.$on('$destroy', destroyKeyboardListeners);

  vm.onKeyDown = (e) => {
    vm.multiSelect = e.ctrlKey || e.metaKey;
    vm.rangeSelect = e.shiftKey;
  };

  vm.onKeyUp = (e) => {
    vm.multiSelect = false;
    vm.rangeSelect = false;
  };

  attachKeyboardListeners();

  const visibleGroupMembers: any = getVisibleGroupMembers(group);
  vm.group = {
    id: group ? group.id : undefined,
    name: group ? group.name : '',
    description: group ? group.description : '',
    members: renameIdentityType(visibleGroupMembers),  // The list of group members (modified by frontend)
    originalMembers: _.clone(visibleGroupMembers),  // The list of group members (unmodified)
    groupedMembers: _.groupBy(renameIdentityType(visibleGroupMembers), 'type'),
    isEnabled: getEnabledAsObject(),
    removePermissions: false,
    isUsedInAccessControlEntries: group ? group.isUsedInAccessControlEntries : false,
    isRemoteGroupEditable: group ? group.isRemoteGroupEditable : false
  };
  vm.isReadOnly = isReadOnly;
  vm.isEveryone = isEveryone;

  markAmbiguousIdentities(group, allUsers, allGroups);

  vm.allUsersAndGroups = _.chain(renameIdentityType(_.concat(allUsers.users, allGroups.items)))
    .reject((o) => {
      // Remove system user
      return o.username === SYSTEM_USERNAME;
    })
    .reject((o) => {
      // Remove *_api_key users
      return _.endsWith(o.username, API_KEY_SUFFIX);
    })
    .reject((o) => {
      // Remove members already present in the group
      return _.some(vm.group.originalMembers, ['id', o.id]);
    })
    .reject((o) => {
      // Remove the current group
      return vm.group && (vm.group.id === o.id);
    })
    .reject((o: any) => {
      // Remove disabled users and groups
      return !o.isEnabled;
    })
    .reject((o: any) => {
      // Remove archived users and groups
      return o.isArchived;
    })
    .groupBy('type')
    .value();

  /**
   * Decorates all the user objects and the group objects with a isAmbiguous field. It also decorates the users with
   * a datasource object containing the name of the datasource (standardize with group objects).
   * Decorates all the already assigned user and group objects with isAmbiguous and also with
   * the datasource object from the fully loaded corresponding object.
   *
   * @param {Object} group - the group object currently in add/edit mode
   * this object also contains the already assigned users and groups
   * @param {Object[]} allUsers - the list of all the users available in the modal
   * @param {Object[]} allGroups - the list of all the groups available in the modal
   */
  function markAmbiguousIdentities(group, allUsers, allGroups) {
    const markAmbiguousElementAndCopyDatasource = (item, fullItemArray) => {
      item.isAmbiguous = true;
      const fullItem = fullItemArray.find(fullItem => fullItem.id === item.id);
      if (fullItem) {
        item.datasource = fullItem.datasource;
      }
    };
    const ambiguousUsers = sqUtilities.getDuplicateStringsInArray(allUsers.users.map(item => item.name));
    allUsers.users.forEach((item) => {
      item.isAmbiguous = _.includes(ambiguousUsers, item.name);
      item.datasource = { name: item.datasourceName };
    });

    const ambiguousGroupNames = sqUtilities.getDuplicateStringsInArray(allGroups.items.map(item => item.name));

    allGroups.items.forEach((item) => {
        item.isAmbiguous = _.includes(ambiguousGroupNames, item.name);
      }
    );

    if (group && group.members) {
      group.members.forEach((item) => {
        if (item.type === 'Users') {
          if (_.includes(ambiguousUsers, item.name)) {
            markAmbiguousElementAndCopyDatasource(item, allUsers.users);
          }
        } else if (item.type === 'Groups') {
          if (_.includes(ambiguousGroupNames, item.name)) {
            markAmbiguousElementAndCopyDatasource(item, allGroups.items);
          }
        }
      });
    }
  }

  /**
   * Returns the visible members.
   * 1. When the group is not from Seeq datasource, the users may not be yet in Seeq, instead of listing the users a
   * warning is being shown under Users header
   * 2. For normal Seeq groups, all members are presented except the archived ones
   *
   * @param  {Object} group - The groups for which the modal opens
   * @return {Object[]} Visible members
   */
  function getVisibleGroupMembers(group) {
    const getActiveGroupMembers = identities => _.reject(identities, identity => identity.isArchived);
    const isGroupEveryone = group ? _.get(group, 'name') === EVERYONE_USERGROUP : false;

    let visibleGroupMembers: any = getActiveGroupMembers(group ? group.members : []);
    const isRemoteGroup = group ? group.datasourceId !== SeeqNames.LocalDatasources.Authentication.DatasourceId : false;
    if (isRemoteGroup && !group.isRemoteGroupEditable) {
      const getMembersExceptUsers = identities => _.reject(identities, identity => identity.type === 'User');
      // We remove the users and add one user where we display the message
      visibleGroupMembers = _.concat(getMembersExceptUsers(visibleGroupMembers), [{
        name: $translate.instant('ADMIN.GROUP.MODAL.REMOTE_GROUP_WARNING'),
        type: 'User',
        id: -1
      }]);
    } else if (isGroupEveryone) {
      visibleGroupMembers = [{
        name: $translate.instant('ADMIN.GROUP.MODAL.EVERYONE_GROUP_WARNING'),
        type: 'User',
        id: -1
      }];
    }
    return visibleGroupMembers;
  }

  /**
   * Attach Keyboard listeners.
   */
  function attachKeyboardListeners() {
    // attach keydown Event to body or it won't fire
    jQuery('body').on('keydown', vm.onKeyDown);
    jQuery('body').on('keyup', vm.onKeyUp);
  }

  /**
   * Removes Keyboard listeners.
   */
  function destroyKeyboardListeners() {
    jQuery('body').off('keydown', vm.onKeyDown);
    jQuery('body').off('keyup', vm.onKeyUp);
  }

  /**
   * Renames the type attribute of a list of identities (User or UserGroup) for better visual presentation.
   *
   * @param  {Object[]} identities - The list of identities to update
   * @return {Object[]} The updated list of identities
   */
  function renameIdentityType(identities: { id: string, type: string, username: string }[]) {
    return _.forEach(identities, function(identity) {
      if (identity.type === 'UserGroup' || identity.type === 'User') {
        identity.type = $translate.instant('ACCESS_CONTROL.TYPES.' + _.toUpper(identity.type) + 'S');
      }
    });
  }

  /**
   * Updates the group members list so they are grouped by type for use by frontend
   * Needs to be called any time vm.group.members is updated
   */
  function updateGroupMembers() {
    vm.group.groupedMembers = _.groupBy(vm.group.members, 'type');
  }

  /**
   * Redirects to either the add method, if there isn't an assigned group id, or the update method.
   */
  function addOrUpdateGroup() {
    if (vm.group.id) {
      updateGroup();
    } else {
      addGroup();
    }
  }

  /**
   * Helper function to display the possible 'enabled' status for a group.
   * New groups can only be created as 'enabled'
   *
   * @returns {Object[]} Array of Objects of possible enabled status containing a value and a label.
   */
  function getGroupStatus() {
    if (_.isUndefined(vm.group.id)) {
      return newGroupStatus;
    } else {
      return existingGroupStatus;
    }
  }

  /**
   * In order for the enabled drop-down to show the appropriate selected option we need to set the enabled status to
   * corresponding object of the options provider.
   *
   * @returns {Object} representing the selected enabled status.
   */
  function getEnabledAsObject() {
    if (group) {
      return _.find(existingGroupStatus, { value: group.isEnabled });
    } else {
      return newGroupStatus[0];
    }
  }

  /**
   * Submits a request to create a new group and add the list of group members.
   *
   * @returns {Promise} that resolves after the group is created and members have been added.
   */
  function addGroup() {
    const newGroup = {
      name: vm.group.name,
      description: vm.group.description
    };

    // Create group
    return sqUserGroupsApi.createUserGroup(newGroup)
      .then(({ data }) => {
        // Update store/grid with new group
        sqAdministrationActions.saveNewGroup(data);

        // Add group members
        const membersToAdd = vm.group.members;
        return _.chain(membersToAdd)
          .map(identityToAdd => sqUserGroupsApi.addIdentityToUserGroup({
            userGroupId: data.id,
            identityId: identityToAdd.id
          }))
          .thru(promises => $q.all(promises))
          .value();
      })
      .then(() => {
        sqNotifications.successTranslate('ADMIN.GROUP.SAVED');
        vm.close();
      })
      .catch(sqNotifications.apiError);
  }

  /**
   * Updates the changed properties on the group object, including the list of group members.
   *
   * @returns {Promise} that resolves when all the updates are complete.
   */
  function updateGroup() {
    // Update name + description
    const promises = [];
    const payload = {
      name: vm.group.name,
      description: vm.group.description,
      isEnabled: vm.group.isEnabled.value,
      removePermissions: vm.group.removePermissions,
      remoteGroupEditable: vm.group.isRemoteGroupEditable
    };
    promises.push(sqUserGroupsApi.updateUserGroup(payload, { userGroupId: vm.group.id }));

    // Remove members no longer in the group
    const membersToRemove = _.differenceWith<any>(vm.group.originalMembers, vm.group.members);
    _.forEach(membersToRemove, (identityToRemove) => {
      promises.push(sqUserGroupsApi.removeIdentityFromUserGroup({
        userGroupId: vm.group.id,
        identityId: identityToRemove.id
      }));
    });

    // Add new group members
    const membersToAdd = _.differenceWith(vm.group.members, vm.group.originalMembers, _.isEqual);
    _.forEach(membersToAdd, (identityToAdd) => {
      promises.push(sqUserGroupsApi.addIdentityToUserGroup({
        userGroupId: vm.group.id,
        identityId: identityToAdd.id
      }));
    });

    return $q.all(promises)
      // Re-fetch the group to update the grid
      .then(() => sqUserGroupsApi.getUserGroup({ userGroupId: vm.group.id }))
      .then(({ data }) => {
        sqNotifications.successTranslate('ADMIN.GROUP.SAVED');
        sqAdministrationActions.updateGroup(data);
        vm.close();
      })
      .catch(sqNotifications.apiError);
  }

  /**
   * Adds selected members from the list of available members to the current list of group members.
   */
  function addMembers() {
    if (vm.group.selectedAvailableMembers) {
      // Add selected members to list of current members and update frontend grouping
      vm.group.members = vm.group.members.concat(vm.group.selectedAvailableMembers);
      updateGroupMembers();

      // Remove selected members from list of available members
      vm.allUsersAndGroups = _.chain(vm.allUsersAndGroups).values().flatten().value();
      _.forEach(vm.group.selectedAvailableMembers, function(selectedMember: any) {
        const index = _.findIndex(vm.allUsersAndGroups, ['id', selectedMember.id]);
        if (index >= 0) {
          vm.allUsersAndGroups.splice(index, 1);
        }
      });
      // Redo the grouping for the frontend
      vm.allUsersAndGroups = _.groupBy(vm.allUsersAndGroups, 'type');
      // Empty the selected array
      vm.group.selectedAvailableMembers = [];
    }
  }

  /**
   * Removes selected members from the list of current group members and adds them to the list of available members.
   */
  function removeMembers() {
    if (vm.group.selectedCurrentMembers) {
      vm.allUsersAndGroups = _.chain(vm.allUsersAndGroups).values().flatten().value();
      _.forEach(vm.group.selectedCurrentMembers, function(selectedMember: any) {
        const index = _.findIndex(vm.group.members, ['id', selectedMember.id]);
        if (index >= 0) {
          // Remove member from list of members
          const removed = vm.group.members.splice(index, 1);

          // Add removed member to list of available members
          vm.allUsersAndGroups = vm.allUsersAndGroups.concat(removed);
        }
      });
      // Redo the groupings for the frontend
      updateGroupMembers();
      vm.allUsersAndGroups = _.groupBy(vm.allUsersAndGroups, 'type');
      // Empty the selected array
      vm.group.selectedCurrentMembers = [];
    }
  }

  /**
   * Determines the title for the group modal depending upon if the group is readonly, being updated, or added new.
   */
  function getGroupTitle() {
    if (vm.isReadOnly) {
      return 'ADMIN.GROUP.MODAL.TITLE.VIEW';
    } else if (vm.group.id) {
      return 'ADMIN.GROUP.MODAL.TITLE.UPDATE';
    } else {
      return 'ADMIN.GROUP.MODAL.TITLE.ADD';
    }
  }

  /**
   * Due to the fact that we ended up replacing the select/option-group construct for lack of IE support we now have
   * to manage the keyboard supported selection/multi-selection support we previously got out of the box.
   *
   * This function restores the behavior supported by the select/option-group which is as follows:
   *
   * If neither SHIFT nor CTRL key are down the clicking an option will select the clicked option - clicking another
   * option will unselect the previously selected option and select the newly clicked option.
   *
   * If the CTRL key is down then multi-select is enabled (clicking another option will not unselect the previously
   * selected option, clicking an already selected option will unselect that option only)
   *
   * If the SHIFT key is down then range-select is enabled. The determination of which range to select is based on
   * the behavior of the select/option-group:
   *      - if the newly clicked option is earlier in the list than the first selected option, then the selected
   *      range starts with the clicked option and ends with the first selected option.
   *      - if the newly clicked option is later in the list then the selected range starts with the last selected
   *      option and ends with the member
   *      - if the clicked option is already within the selected range then the selected range is updated to end
   *      with the clicked member
   *
   * If neither CTRL or SHIFT key are pressed and an option is clicked then previously selected ranges are lost and
   * only the clicked option is selected.
   *
   * @param {Object[]} selectedMembers - options that are currently selected
   * @param {Object[]} availableMembers - options that are available for selection
   * @param {Object} member - option that was clicked
   */
  function findMembersToSelect(selectedMembers, availableMembers, member) {
    if (vm.rangeSelect && !_.isEmpty(selectedMembers)) {
      const firstSelected = _.head(selectedMembers);
      const lastSelected = _.last(selectedMembers);
      // we need to ensure proper sorting as otherwise the index could  be off
      const allValues = _.flatMap(availableMembers, value => _.sortBy(value,
        [entry => _.toLower(entry.name)], 'desc'));
      const memberIndex = _.indexOf(allValues, member);
      const firstIndex = _.indexOf(allValues, firstSelected);
      const lastIndex = _.indexOf(allValues, lastSelected);

      if (memberIndex < firstIndex) {
        // select everything from the member to the first one
        selectedMembers = _.slice(allValues, memberIndex, firstIndex + 1);
      } else if (memberIndex > lastIndex) {
        // select everything from last member to index
        selectedMembers = _.slice(allValues, lastIndex, memberIndex + 1);
      } else {
        // select from firstIndex to memberIndex
        selectedMembers = _.slice(allValues, firstIndex, memberIndex + 1);
      }
    } else {
      if (_.size(selectedMembers) > 1 && !vm.multiSelect) {
        // coming from multi-select or range select
        selectedMembers = [member];
      } else if (_.isUndefined(_.find(selectedMembers, ['id', member.id]))) {
        if (vm.multiSelect) {
          selectedMembers = _.compact(_.concat(selectedMembers, member));
        } else {
          selectedMembers = [member];
        }
      } else {
        _.remove(selectedMembers, ['id', member.id]);
      }
    }

    if (vm.multiSelect || vm.rangeSelect) {
      sqTrack.doTrack('Access Control Group Modal', 'Keyboard Event',
        vm.multiSelect ? 'Multi-select' : 'Shift Click range selection');
    }
    return selectedMembers;
  }

  /**
   * Updates the array of selected members in the available group when one is selected.
   *
   * @param {Object} member - The group/user to add/remove from the array
   */
  function updateSelectedAvailableMembers(member) {
    vm.group.selectedAvailableMembers = findMembersToSelect(vm.group.selectedAvailableMembers, vm.allUsersAndGroups,
      member);
  }

  /**
   * Updates the array of selected members in the current group when one is selected.
   *
   * @param {Object} member - The group/user to add/remove from the array
   */
  function updateSelectedCurrentMembers(member) {
    vm.group.selectedCurrentMembers = findMembersToSelect(vm.group.selectedCurrentMembers,
      vm.group.groupedMembers, member);
  }

  /**
   * Determines if an item in the available group is selected or not
   *
   * @param {string} id - The id of the member to check for
   * @returns {Object} the member object if the id is in the selected list, undefined if it is not
   */
  function isSelectedAvailable(id) {
    return _.find(vm.group.selectedAvailableMembers, ['id', id]);
  }

  /**
   * Determines if an item in the current group is selected or not
   *
   * @param {string} id - The id of the member to check for
   * @returns {Object} the member object if the id is in the selected list, undefined if it is not
   */
  function isSelectedCurrent(id) {
    return _.find(vm.group.selectedCurrentMembers, ['id', id]);
  }
}
