import _ from 'lodash';
import { BaseToolStoreService } from '@/investigate/baseToolStore.service';
import { TREND_TOOLS } from '@/investigate/investigate.module';

export type CustomConditionStore = ReturnType<typeof sqCustomConditionStore>['exports'];

export function sqCustomConditionStore(sqBaseToolStore: BaseToolStoreService) {
  const store = {
    initialize() {
      this.state = this.immutable(sqBaseToolStore.COMMON_PROPS);
    },

    exports: {},

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @return {Object} State for the store
     */
    dehydrate() {
      return this.state.serialize();
    },

    /**
     * Sets the references panel state
     *
     * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {},

    /**
     * Removes properties from config which are stored as part of the formula.
     *
     * @param {Object} config - The state that will be saved to UIConfig
     * @return {Object} The modified config
     */
    modifyConfigParams(config) {
      return _.omit(config, ['maximumDuration', 'advancedParametersCollapsed']);
    }
  };

  return sqBaseToolStore.extend(store, TREND_TOOLS.CUSTOM_CONDITION);
}
