import _ from 'lodash';
import angular from 'angular';
import { AggregationBinActions } from '@/investigate/aggregationBins/aggregationBin.actions';
import { AggregationBinStore } from '@/investigate/aggregationBins/aggregationBin.store';

angular.module('Sq.Investigate')
  .controller('ByTimeCtrl', ByTimeCtrl);

function ByTimeCtrl(
  $scope: ng.IScope,
  sqAggregationBinActions: AggregationBinActions,
  sqAggregationBinStore: AggregationBinStore
) {

  const vm = this;
  vm.TIME_BUCKETS = TIME_BUCKETS;
  vm.selectTimeBucket = selectTimeBucket;
  vm.setCapsuleMode = sqAggregationBinActions.setCapsuleMode;

  // exposed for testing
  vm.syncAggregationStore = syncAggregationStore;

  $scope.$listenTo(sqAggregationBinStore, syncAggregationStore);

  /**
   * Syncs with the Aggregation Store.
   */
  function syncAggregationStore() {
    const param = _.find(sqAggregationBinStore.aggregationConfigs, { id: vm.id });
    if (!_.isEmpty(param)) {
      vm.selectedTimeBucket = _.get(param, 'timeBucket.key');
      _.assign(vm, _.pick(param, ['capsuleMode']));
    }
  }

  /**
   * Persists the selected time bucket to the store.
   *
   * @param {String} bucketKey - String identifying one of the TIME_BUCKETS options.
   */
  function selectTimeBucket(bucketKey) {
    vm.selectedTimeBucket = bucketKey;
    sqAggregationBinActions.setTimeBucket(_.find(TIME_BUCKETS, { key: bucketKey }), vm.id);
  }
}

export const TIME_BUCKETS = [{
  key: 'Hour',
  display: 'BY_HOUR',
  funct: 'hours()'
}, {
  key: 'Day Of Week',
  display: 'BY_DAY_OF_WEEK',
  funct: 'days()'
}, {
  key: 'Day Of Month',
  display: 'BY_DAY_OF_MONTH',
  funct: 'days()'
}, {
  key: 'Day Of Year',
  display: 'BY_DAY_OF_YEAR',
  funct: 'days()'
}, {
  key: 'Week',
  display: 'BY_WEEK',
  funct: 'weeks(Day.Monday)'
}, {
  key: 'Month',
  display: 'BY_MONTH',
  funct: 'months()'
}, {
  key: 'Quarter',
  display: 'BY_QUARTER',
  funct: 'quarters()'
}, {
  key: 'Year',
  display: 'BY_YEAR',
  funct: 'years()'
}];
