import angular from 'angular';
import _ from 'lodash';
import { sqCompositeSearchActions } from '@/hybrid/tools/compositeSearch/compositeSearch.actions';
import { sqCompositeSearchStore } from '@/hybrid/tools/compositeSearch/compositeSearch.store';

const dependencies = [
  'Sq.Core',
  'Sq.AppConstants',
  'Sq.Services.ToolRunner',
  'Sq.TrendData'
];

export default angular.module('Sq.CompositeSearch', dependencies)
  .store('sqCompositeSearchStore', sqCompositeSearchStore)
  .service('sqCompositeSearchActions', sqCompositeSearchActions);

export type CompositeLogic = {
  key: string;
  operator: string;
  formula: (a: string, b: string, duration: string) => void;
  withMaximumDuration?: boolean
  exclude?: string
};

export const LOGIC: CompositeLogic[] = [
  {
    key: 'UNION',
    operator: 'union',
    formula: (a, b) => `${a}.union(${b})`
  },
  {
    key: 'INTERSECTION',
    operator: 'intersect',
    formula: (a, b) => `${a}.intersect(${b})`
  },
  {
    key: 'JOIN',
    operator: 'join',
    formula: (a, b, duration) => `${a}.join(${b}, ${duration})`,
    withMaximumDuration: true
  },
  {
    // Touches
    key: 'OVERLAPPED_BY',
    operator: 'touches',
    exclude: 'matchesWith',
    formula: (a, b) => `${a}.touches(${b})`
  },
  {
    // Outside
    key: 'NOT_OVERLAPPED_BY',
    operator: 'matchesWith',
    formula: (a, b) => `${a}.matchesWith(${a}.subtract(${a}.touches(${b})), 0)`
  },
  {
    // Inside
    key: 'ENCLOSES',
    operator: 'inside',
    formula: (a, b) => `${a}.inside(${b})`
  },
  {
    // Minus
    key: 'SUBTRACT',
    operator: 'subtract',
    formula: (a, b) => `${a}.subtract(${b})`
  }
];
export const LOGIC_KEY = _.zipObject(_.map(LOGIC, 'key'), _.map(LOGIC, 'key'));
