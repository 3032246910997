import angular from 'angular';
import template from './annotationIcon.component.html';

/**
 * @file Shows an annotation icon for the specified item if the item has an annotation.
 */
angular.module('Sq.Annotation').component('sqAnnotationIcon', {
  template,
  controller: 'AnnotationIconCtrl',
  bindings: {
    item: '<'
  }
});
