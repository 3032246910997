import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';
import { DatasourcesStatusOutputV1 } from '@/sdk';

export type DatasourcesStore = ReturnType<typeof sqDatasourcesStore>['exports'];

export function sqDatasourcesStore() {
  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.NONE,

    initialize() {
      this.state = this.immutable({
        datasources: undefined
      });
    },

    exports: {
      get datasources(): DatasourcesStatusOutputV1 {
        return this.state.get('datasources');
      }
    },

    handlers: {
      DATASOURCES_SET_DATASOURCES: 'setDatasources'
    },

    setDatasources({ datasources }: { datasources: DatasourcesStatusOutputV1 }) {
      this.state.set('datasources', datasources);
    }
  };

  return store;
}
