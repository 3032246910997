import angular from 'angular';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { TrendScalarStore } from '@/trendData/trendScalar.store';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { sqFormulaToolStore } from '@/hybrid/tools/formula/formulaTool.store';
import { sqFormulaToolActions } from '@/hybrid/tools/formula/formulaTool.actions';

const dependencies = [
  'Sq.Vendor',
  'Sq.Core',
  'Sq.AppConstants',
  'Sq.TrendViewer',
  'Sq.Investigate',
  'Sq.Services.CalculationRunner',
  'Sq.Services.Formula',
  'Sq.Search',
  'Sq.Directives.DoubleClick',
  'Sq.Services.Utilities',
  'Sq.Services.ACLService',
  'Sq.Services.Authorization',
  'Sq.LicenseManagement',
  'Sq.Worksheets',
  'Sq.Worksteps',
  'Sq.Workbench',
  'Sq.Services.Modal'
];

export let FORMULA_TOOL_TREND_STORES: [
  TrendSeriesStore,
  TrendScalarStore,
  TrendCapsuleSetStore
];

export default angular
  .module('Sq.FormulaTool', dependencies)
  .service('sqFormulaToolActions', sqFormulaToolActions)
  .store('sqFormulaToolStore', sqFormulaToolStore)
  // Stores that contain items that can be created from the formula tool
  .factory('FORMULA_TOOL_TREND_STORES', (
    sqTrendSeriesStore: TrendSeriesStore,
    sqTrendScalarStore: TrendScalarStore,
    sqTrendCapsuleSetStore: TrendCapsuleSetStore
  ) => [
    sqTrendSeriesStore,
    sqTrendScalarStore,
    sqTrendCapsuleSetStore
  ] as typeof FORMULA_TOOL_TREND_STORES)
  .run(($injector) => {
    FORMULA_TOOL_TREND_STORES = $injector.get('FORMULA_TOOL_TREND_STORES');
  });

export const DEFAULT_FORMULA_PANEL_HEIGHT = 575;
export const MIN_FORMULA_PANEL_HEIGHT = 420;
export const MIN_FORMULA_PANEL_WIDTH = 850;
export const FORMULA_HOME = '/formulas/docs/Seeq/index';
export const FORMULA_LIST = 'Formula List';
export const SEARCH = 'search';

export interface FormulaParameter {
  identifier: string;
  item: { id: string, name: string; };
}

export interface FunctionDocItem {
  name: string;
  shortDescription: string;
  description: string;
  keywords: string[];
}
