import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import React from 'react';
import Select from 'react-select';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Card } from 'react-bootstrap';
import { ClearableInput } from '@/hybrid/core/ClearableInput.molecule';
import _ from 'lodash';
import { FilterParameters } from '@/hybrid/administration/datasources/datasources.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { DatasourceSummaryStatusOutputV1 } from '@/sdk';

const datasourcesFilterHeaderBindings = bindingsDefinition({
  filterParams: prop<FilterParameters>(),
  filterCallback: prop<(field: string, value: string) => void>(),
  datasources: prop<DatasourceSummaryStatusOutputV1[]>()
});

export const DatasourcesFilterHeader: SeeqComponent<typeof datasourcesFilterHeaderBindings> = (props) => {
  const { filterCallback, filterParams, datasources } = props;

  const { t } = useTranslation();

  // sort alphabetically and remove duplicates
  const sortedDatasources = _.chain(datasources)
    .sortBy(item => item.datasourceClass.toLowerCase())
    .uniqBy('datasourceClass')
    .value();

  const selectInputStyle = {
    control: base => ({
      ...base,
      height: 32,
      minHeight: 32
    })
  };

  const connectionStatusFilterOptions = [
    {
      value: SeeqNames.Connectors.Connections.Status.Connecting,
      label: t('ADMIN.DATASOURCES.FILTER.STATUS_CONNECTING')
    },
    {
      value: SeeqNames.Connectors.Connections.Status.Connected,
      label: t('ADMIN.DATASOURCES.FILTER.STATUS_CONNECTED')
    },
    {
      value: SeeqNames.Connectors.Connections.Status.Disconnected,
      label: t('ADMIN.DATASOURCES.FILTER.STATUS_DISCONNECTED')
    },
    {
      value: SeeqNames.Connectors.Connections.Status.Disabled,
      label: t('ADMIN.DATASOURCES.FILTER.STATUS_DISABLED')
    }
  ];

  return (
    <Card data-testid="datasourcesFilterHeader" className="datasourcesFilterCard mb10">
      <Card.Header className="datasourcesFilterCard">
        <div className="flexWrap flexColumnContainer width-99-percent">
          <div className="flexRowContainer mr10 width-auto">
            <label className="control-label min-width-150">{t('ADMIN.DATASOURCES.FILTER.DATASOURCE_CLASS')}</label>
            <div data-testid='specDatasourceClass_select'>
              <Select
                classNamePrefix='spec_datasourceClass_filter react-select'
                value={filterParams['datasourceClass'].length > 0 && {
                  value: filterParams['datasourceClass'],
                  label: filterParams['datasourceClass']
                }}
                options={_.map(sortedDatasources, ds => ({ value: ds.datasourceClass, label: ds.datasourceClass }))}
                onChange={(selected) => {
                  filterCallback('datasourceClass', _.get(selected, 'value', ''));
                }}
                isSearchable={true}
                isClearable={true}
                styles={selectInputStyle}
              />
            </div>
          </div>

          <div className="flexRowContainer mr10 width-auto">
            <label className="control-label min-width-150">{t('ADMIN.DATASOURCES.FILTER.DATASOURCE_NAME')}</label>
            <ClearableInput
              field="name"
              searchValue={filterParams['name']}
              filterTable={filterCallback} />
          </div>

          <div className="flexRowContainer mr10 width-auto">
            <label className="control-label min-width-150">{t('ADMIN.DATASOURCES.FILTER.DATASOURCE_ID')}</label>
            <ClearableInput
              field="datasourceId"
              searchValue={filterParams['datasourceId']}
              filterTable={filterCallback} />
          </div>

          <div className="flexRowContainer mr10 width-auto">
            <label className="control-label min-width-150">{t('ADMIN.DATASOURCES.FILTER.AGENT')}</label>
            <ClearableInput
              field="agentName"
              searchValue={filterParams['agentName']}
              filterTable={filterCallback} />
          </div>

          <div className="flexRowContainer mr10 width-auto">
            <label className="control-label min-width-150">{t('ADMIN.DATASOURCES.FILTER.STATUS')}</label>
            <div data-testid="connectionStatus_select">
              <Select
                classNamePrefix="spec_connectionStatus_filter react-select"
                value={_.find(connectionStatusFilterOptions, { value: filterParams['status'] })}
                options={connectionStatusFilterOptions}
                isSearchable={false}
                isClearable={true}
                onChange={(selectedOption) => {
                  filterCallback('status', selectedOption ? selectedOption.value : '');
                }}
                styles={selectInputStyle}
              />
            </div>
          </div>
        </div>
      </Card.Header>
    </Card>
  );
};
