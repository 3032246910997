import React, { useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import _ from 'lodash';
import { ReportClickActions } from '@/reportEditor/report.module';
import { ReportActions } from '@/reportEditor/report.actions';
import { ReportStore } from '@/reportEditor/report.store';
import { useInjectedBindings } from '../core/useInjectedBindings.hook';
import { useFluxPath } from '../core/useFluxPath.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ConfigureAssetsSelectionModal } from '@/hybrid/reportEditor/ConfigureAssetSelectionModal.molecule';
import { ReportConfigAssetSelection } from '@/hybrid/reportEditor/ReportConfigAssetSelection.atom';
import { ReClickTriggerProperties } from '@/hybrid/reportEditor/ReportConfigDateRangeGroup.molecule';

const reportConfigAssetsBindings = bindingsDefinition({
  viewOnlyMode: prop.optional<boolean>(),
  sqReportStore: injected<ReportStore>(),
  sqReportActions: injected<ReportActions>()
});

export const ReportConfigAssets: SeeqComponent<typeof reportConfigAssetsBindings> = (props) => {
  const {
    sqReportStore,
    sqReportActions
  } = useInjectedBindings(reportConfigAssetsBindings);

  const { viewOnlyMode } = props;
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(null);

  const defaultReClickProperties: ReClickTriggerProperties = {
    action: ReportClickActions.None,
    nullableIndex: null
  };
  const [reClickProps, setReClickProps] = useState(defaultReClickProperties);
  const getOrderedIndex = (selection, assetSelections) => _.findIndex(assetSelections,
    { selectionId: selection.selectionId });

  /**
   * Given a function, this decorates that function with logic that makes the function EITHER execute, or activate
   * sandbox mode instead. Used to give the actions in ReportConfigAssetSelection a way to trigger sandbox mode as well
   * as to setup a re-click for the action in question once the sandbox selections are loaded.
   */
  const actionWrapper = (action: (...args) => any, actionType: ReportClickActions): (...actionArgs) => any => {
    const oldAssetSelections = _.cloneDeep(selections);
    const onSandbox = (...args) => {
      const selection = args[0];
      const index = getOrderedIndex(selection, oldAssetSelections);
      if (actionType === ReportClickActions.ChangeSibling) {
        const asset = args[1];
        setSelectedAsset(asset);
      }
      setReClickProps({
        action: actionType,
        nullableIndex: index
      });
    };
    const onAction = (...args) => {
      setReClickProps(defaultReClickProperties);
      setSelectedAsset(null);
    };
    return sqReportActions.doActionElseActivateSandbox(action, onAction, onSandbox);
  };

  const [selectedAsset, setSelectedAsset] = useState(null);

  const selectionsNotArchived = useFluxPath(sqReportStore, () => sqReportStore.assetSelectionsNotArchived);
  const selections = _.sortBy(selectionsNotArchived, 'name');

  const onClose = () => {
    setShowModal(false);
    setCurrentSelection(null);
  };

  return <div className="flexRowContainer mb15 toolOptions">
    <div className="flexColumnContainer flexSpaceBetween pt10 pb10 pl3">
      <div>
        <Icon
          icon="fa-cube"
          type="text"
          large={true} />
        <span className="mb5 pl5">
          {t('REPORT.CONFIG.ASSET_SELECTIONS')}
          </span>
      </div>
      {!viewOnlyMode && <div className="mr5 pr3 pl5 flexColumnContainer">
        <Icon
          testId="addAssetSelectionTestId"
          icon="fa-fw fa-plus"
          extraClassNames="cursorPointer ml5 mt2"
          large={true}
          type='theme'
          tooltip='REPORT.CONFIG.CONFIGURE_ASSET_SELECTION'
          onClick={() => setShowModal(true)} />
      </div>}
    </div>
    <div className="divider" />
    {selections.length < 1 && !viewOnlyMode && <div className="pt10 pb10 pl3">
      <a
        href="#"
        onClick={() => {
          setShowModal(true);
        }}>
        {t('REPORT.CONFIG.CONFIGURE_ASSET_SELECTION')}
      </a>
    </div>}
    {viewOnlyMode && !sqReportStore.hasInUseAssetSelections() &&
    <span className='text-center'>{t("REPORT.CONFIG.ASSET_SELECTIONS_NOT_USED")}</span>
    }
    {_.map(selections, (selection) => {
      const isInitiatingReClick = reClickProps.nullableIndex === getOrderedIndex(selection, selections);
        return <ReportConfigAssetSelection
          key={selection.selectionId}
          viewOnly={viewOnlyMode}
          selection={selectedAsset && isInitiatingReClick ? { ...selection, asset: selectedAsset } : selection}
          onEdit={(selection) => {
            setCurrentSelection(selection);
            setShowModal(true);
          }}
          actionWrapper={actionWrapper}
          reClickAction={isInitiatingReClick ? reClickProps.action : ReportClickActions.None}
        />;
      }
    )}
    <ConfigureAssetsSelectionModal
      show={showModal}
      onClose={onClose}
      assetSelections={selections}
      currentSelection={currentSelection}
    />
  </div>;
};
