import _ from 'lodash';
import bind from 'class-autobind-decorator';
import { TrendActions } from '@/trendData/trend.actions';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { DurationStore } from '@/trendData/duration.store';
import { FxLineMetadata, ScatterPlotColorRange, ScatterPlotStore } from '@/scatterPlot/scatterPlot.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { DateTimeService } from '@/datetime/dateTime.service';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { UtilitiesService } from '@/services/utilities.service';
import { ScatterPlotFormula } from './scatterPlot.store';
import { EMPTY_XYREGION, XYRegion } from '@/services/chartHelper.service';
import { API_TYPES, DEBOUNCE, NUMBER_CONVERSIONS } from '@/main/app.constants';
import { SCATTER_PLOT_MODES, SCATTER_PLOT_VIEWS } from '@/scatterPlot/scatterPlot.module';
import { ITEM_DATA_STATUS, MAX_SERIES_PIXELS } from '@/trendData/trendData.module';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.module';
import { FormulaService, SPIKECATCHER_PER_PIXEL } from '@/services/formula.service';
import { PUSH_IGNORE } from '@/services/stateSynchronizer.service';
import { SignalsApi } from 'sdk/api/SignalsApi';
import { PredictionHelperService } from '@/services/predictionHelper.service';
import { ConditionsApi } from '@/sdk';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { IPromise } from 'angular';

// This value was determined via trial and error, and seems to be the maximum number of points we can
// display without enabling boost mode, without the plot and browser lagging significantly.
export const MIN_SAMPLES_FOR_BOOST = 8000;
export const MAX_CAPSULE_SCATTER_PLOT_SAMPLES = 1000000; // Too many can cause high CPU usage and diminishing returns
export const FX_LINE_SAMPLE_SPACING = 5; // Number of pixels between each sample of a function of x line

@bind
export class ScatterPlotActions {
  minimapWidth;
  debouncedFetchXYData = _.debounce(this.fetchXYData, DEBOUNCE.MEDIUM);
  debouncedFetchDensityPlot = _.debounce(this.fetchDensityPlot, DEBOUNCE.MEDIUM);

  constructor(
    private flux: ng.IFluxService,
    private $injector: ng.auto.IInjectorService,
    private $q: ng.IQService,
    private $translate: ng.translate.ITranslateService,
    private sqPendingRequests: PendingRequestsService,
    private sqDurationStore: DurationStore,
    private sqScatterPlotStore: ScatterPlotStore,
    private sqTrendSeriesStore: TrendSeriesStore,
    private sqTrendCapsuleStore: TrendCapsuleStore,
    private sqTrendCapsuleSetStore: TrendCapsuleSetStore,
    private sqDateTime: DateTimeService,
    private sqSystemConfiguration: SystemConfigurationService,
    private sqWorksheetStore: WorksheetStore,
    private sqUtilities: UtilitiesService,
    private sqPredictionHelper: PredictionHelperService,
    private sqSignalsApi: SignalsApi,
    private sqConditionsApi: ConditionsApi,
    private sqWorksheetActions: WorksheetActions,
    private sqFormula: FormulaService
  ) {
  }

  /**
   * Sets the series on the x-y plot's x axis
   * @param {Object} xAxisSeries - the series that will be on the x axis
   */
  setXSeries(xAxisSeries) {
    // This method gets called multiple times when loading a Scatterplot. To avoid duplicated fetches of data from the
    // backend, cancel the update if nothing would change.
    if (this.seriesHasSameId(xAxisSeries, this.sqScatterPlotStore.xSeries)) {
      return;
    }

    this.flux.dispatch('SCATTER_PLOT_SET_X_SERIES', { xSeries: xAxisSeries });
    this.clearSelectedRegion();
    this.expandViewRegion();
    this.fetchPlot();
  }

  /**
   * Sets the series on the x-y plot's y axis
   * @param {Object} yAxisSeries - the series that will be on the y axis
   */
  setYSeries(yAxisSeries) {
    // This method gets called multiple times when loading a Scatterplot. To avoid duplicated fetches of data from the
    // backend, cancel the update if nothing would change.
    if (this.seriesHasSameId(yAxisSeries, this.sqScatterPlotStore.ySeries)) {
      return;
    }

    this.flux.dispatch('SCATTER_PLOT_SET_Y_SERIES', { ySeries: yAxisSeries });
    this.clearSelectedRegion();
    this.expandViewRegion();
    this.fetchPlot();
  }

  seriesHasSameId(series1, series2) {
    const neitherExists = !series1 && !series2;
    const bothExistAndIdMatches = series1 && series2 && series1.id === series2.id;
    return neitherExists || bothExistAndIdMatches;
  }

  /**
   * Flips the series on the x-y plot's x and y axes
   */
  flipXAndY() {
    this.flux.dispatch('SCATTER_PLOT_FLIP_AXES');
    this.fetchAllFxLines();
  }

  /**
   * Sets the values of the scatter plot region selectors, as fraction of the display range.
   *
   * @param {Number} low - The value (between 0 and 1)
   * @param {Number} high - The value (between 0 and 1)
   */
  setSelectors(low, high) {
    this.flux.dispatch('SCATTER_PLOT_SELECTORS', { low, high });
  }

  fetchPlot() {
    if (this.sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
      return this.fetchScatterPlot();
    } else if (this.sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
      return this.fetchDensityPlot();
    }
  }

  /**
   * Fetch all of the data needed for the Scatterplot view, and pass along the data so the store and plot are updated.
   */
  fetchScatterPlot() {
    if (this.sqWorksheetStore.view.key !== WORKSHEET_VIEW.SCATTER_PLOT
      || this.sqScatterPlotStore.plotView !== SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
      return;
    }

    this.fetchMinimapSignals();
    return this.fetchXYData();
  }

  /**
   * Fetch the X-Y table data used for the main Scatterplot display, and emit events so the signal data is stored in
   * the same way as it would be in the Trend view. This does not explicitly update the X-Y scatterplot display or the
   * mini-map. They will update automatically (via event listeners).
   */
  fetchXYData() {
    const xItem = this.getXItem();
    const yItem = this.getYItem();

    // If both plot series aren't specified, then clear previous data and return
    if (!this.sqScatterPlotStore.xSeries || !this.sqScatterPlotStore.ySeries
      || !_.isObject(xItem) || !_.isObject(yItem)
      || _.includes([xItem.dataStatus, yItem.dataStatus], ITEM_DATA_STATUS.REDACTED)) {
      this.flux.dispatch('SCATTER_PLOT_CLEAR_DATA', {}, PUSH_IGNORE);
      return this.$q.resolve();
    }

    this.flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id: xItem.id }, PUSH_IGNORE);
    this.flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id: yItem.id }, PUSH_IGNORE);

    const queryRangeCapsule = this.sqDateTime.getCapsuleFormula(this.sqDurationStore.displayRange);
    const params = this.sqScatterPlotStore.getXyFormulaAndParameters(xItem.id, yItem.id, queryRangeCapsule,
      this.sqScatterPlotStore.colorConditionIds, this.sqScatterPlotStore.colorSignalId,
      this.sqScatterPlotStore.colorCapsuleProperty);
    this.sqPendingRequests.cancelGroup(params.cancellationGroup, true);

    return this.getAdjustedSampleLimit(params)
      .then(sampleLimit => this.sqFormula.computeTable({
        formula: _.replace(params.xyTableFormula, '{sampleLimit}', (sampleLimit as any)),
        parameters: params.parameters,
        cancellationGroup: params.cancellationGroup
      }))
      .then((results) => {
        // Dispatch events for the signals so they get stored in the central location, for the details pane, etc.
        // Note that since a single request includes data from two different signals, the timing and meter information
        // is the same for both items used. No samples are stored on the trend items since they aren't needed.
        this.flux.dispatch('TREND_SERIES_RESULTS_SUCCESS', {
          id: xItem.id,
          samples: [],
          timingInformation: results.timingInformation,
          meterInformation: results.meterInformation,
          valueUnitOfMeasure: results.headers[1].units
        }, PUSH_IGNORE);

        this.flux.dispatch('TREND_SERIES_RESULTS_SUCCESS', {
          id: yItem.id,
          samples: [],
          timingInformation: results.timingInformation,
          meterInformation: results.meterInformation,
          valueUnitOfMeasure: results.headers[2].units
        }, PUSH_IGNORE);

        // convert first column form nanoseconds (returned by backend) to milliseconds
        _.map(results.data, row => (row[0] = row[0] / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND));

        this.flux.dispatch('SCATTER_PLOT_SET_DATA', {
          headers: results.headers,
          data: results.data
        }, PUSH_IGNORE);
      })
      // Must fetch X-Y data first since function of x lines requires the min and max
      .then(() => this.fetchAllFxLines())
      .catch((error) => {
        const sqTrendActions = this.$injector.get<TrendActions>('sqTrendActions');
        sqTrendActions.catchItemDataFailure(xItem.id, params.cancellationGroup, error);
        sqTrendActions.catchItemDataFailure(yItem.id, params.cancellationGroup, error);
      });
  }

  /**
   * Determines how big the sample limit should be when fetching the XYTable for the plot. Because the xyTable
   * operator translates the maxSamples parameter into lanes for spike catcher it means the number must be increased
   * based on the percentage of time the signal will be within the capsules. For example, if there are only capsules
   * for 25% of the time, the number of lanes will have to be increased by 4 since 75% of them will be ones that
   * can't be used. Not ideal for performance since it means passing over the signals twice, but we haven't come up
   * with a better algorithm.
   *
   * @param {ScatterPlotFormula} params - The scatter plot formula and parameters
   * @returns {Promise} How many samples to use for the currently visible capsules. If sampleLimitFormula is empty
   * this is immediately resolved with the default limit.
   */
  getAdjustedSampleLimit(params: ScatterPlotFormula): IPromise<number> {
    const maxScatterPlotSamples = this.sqSystemConfiguration.maxScatterPlotSamples;
    return params.sampleLimitFormula ?
      this.sqFormula.computeScalar({
          formula: params.sampleLimitFormula,
          parameters: params.parameters,
          cancellationGroup: params.cancellationGroup
        })
        .then(({ value }) => value / 100)
        .then(capsuleOnFraction => capsuleOnFraction > 0 ?
          Math.min(_.floor(maxScatterPlotSamples / capsuleOnFraction), MAX_CAPSULE_SCATTER_PLOT_SAMPLES) :
          maxScatterPlotSamples)
      : this.$q.resolve(maxScatterPlotSamples);
  }

  /**
   * Fetch all of the data needed for the Density plot view, and pass along the data so the store and plot are updated.
   */
  fetchDensityPlot() {
    if (this.sqWorksheetStore.view.key !== WORKSHEET_VIEW.SCATTER_PLOT
      || this.sqScatterPlotStore.plotView !== SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
      return;
    }
    const xItem = this.getXItem();
    const yItem = this.getYItem();

    // If both plot series aren't specified, then clear previous data and return
    if (!this.sqScatterPlotStore.xSeries || !this.sqScatterPlotStore.ySeries
      || !_.isObject(xItem) || !_.isObject(yItem)
      || _.includes([xItem.dataStatus, yItem.dataStatus], ITEM_DATA_STATUS.REDACTED)) {
      this.flux.dispatch('DENSITY_PLOT_CLEAR_DATA', {}, PUSH_IGNORE);
      return this.$q.resolve();
    }

    this.flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id: xItem.id }, PUSH_IGNORE);
    this.flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id: yItem.id }, PUSH_IGNORE);

    const queryRangeCapsule = this.sqDateTime.getCapsuleFormula(this.sqDurationStore.displayRange);
    this.sqPendingRequests.cancelGroup('densityPlotData', true);
    const params = this.sqScatterPlotStore.getDensityPlotFormula(xItem.id, yItem.id, queryRangeCapsule);

    return this.sqFormula.computeTable(params)
      .then((results) => {
        this.flux.dispatch('TREND_SERIES_RESULTS_SUCCESS', {
          id: xItem.id,
          samples: [],
          timingInformation: results.timingInformation,
          meterInformation: results.meterInformation,
          valueUnitOfMeasure: results.headers[0].units
        }, PUSH_IGNORE);

        this.flux.dispatch('TREND_SERIES_RESULTS_SUCCESS', {
          id: yItem.id,
          samples: [],
          timingInformation: results.timingInformation,
          meterInformation: results.meterInformation,
          valueUnitOfMeasure: results.headers[1].units
        }, PUSH_IGNORE);

        _.map(results.data, row => (row[2] = row[2] / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND));

        this.flux.dispatch('DENSITY_PLOT_SET_DATA', {
          headers: results.headers,
          data: results.data
        }, PUSH_IGNORE);
      })
      .catch((error) => {
        const sqTrendActions = this.$injector.get<TrendActions>('sqTrendActions');
        sqTrendActions.catchItemDataFailure(xItem.id, params.cancellationGroup, error);
        sqTrendActions.catchItemDataFailure(yItem.id, params.cancellationGroup, error);
      });
  }

  calculateData() {
    if (this.sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
      return this.calculateScatterPlotData();
    } else if (this.sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
      return this.calculateDensityPlotData();
    }
  }

  /**
   * Update the Density Plot based on existing data in the sqScatterPlotStore.
   */
  calculateDensityPlotData() {
    // If both plot series aren't specified, then clear previous data and return
    if (!this.sqScatterPlotStore.xSeries || !this.sqScatterPlotStore.ySeries) {
      this.flux.dispatch('DENSITY_PLOT_CLEAR_DATA');
      return;
    }

    this.flux.dispatch('SCATTER_PLOT_REFRESH_VIEW', null, PUSH_IGNORE);
  }

  /**
   * Update the Scatterplot based on existing data in this.sqScatterPlotStore.
   */
  calculateScatterPlotData() {
    // If both plot series aren't specified, then clear previous data and return
    if (!this.sqScatterPlotStore.xSeries || !this.sqScatterPlotStore.ySeries) {
      this.flux.dispatch('SCATTER_PLOT_CLEAR_DATA');
      return;
    }

    this.flux.dispatch('SCATTER_PLOT_REFRESH_VIEW', null, PUSH_IGNORE);
  }

  getXItem() {
    const xItem = this.sqTrendSeriesStore.findItem(_.get(this.sqScatterPlotStore.xSeries, 'id'));

    // If the series is missing from the trend series store, sync up the Scatterplot store
    if (_.isUndefined(xItem) && this.sqScatterPlotStore.xSeries) {
      this.flux.dispatch('SCATTER_PLOT_SET_X_SERIES', { xSeries: null });
    }

    return xItem;
  }

  getYItem() {
    const yItem = this.sqTrendSeriesStore.findItem(_.get(this.sqScatterPlotStore.ySeries, 'id'));

    // If the series is missing from the trend series store, sync up the Scatterplot store
    if (_.isUndefined(yItem) && this.sqScatterPlotStore.ySeries) {
      this.flux.dispatch('SCATTER_PLOT_SET_Y_SERIES', { ySeries: null });
    }

    return yItem;
  }

  /**
   * Updates the width of the investigate range minimap
   *
   * @param {Number} width - The width in pixels
   */
  updateMinimapWidth(width) {
    const newWidth = Math.min(Math.max(0, width), MAX_SERIES_PIXELS);
    if (newWidth !== this.minimapWidth) {
      this.minimapWidth = newWidth;
      this.fetchMinimapSignals();
    }
  }

  /**
   * Sets the capsule set that's used to filter what is displayed on the scatter plot
   *
   * @param {String} plotMode - The desired plot mode (either SCATTER_PLOT_MODES.DISPLAY_RANGE or
   *   SCATTER_PLOT_MODES.CAPSULES)
   */
  setPlotMode(plotMode) {
    if (!_.includes(_.values(SCATTER_PLOT_MODES), plotMode)) {
      throw new TypeError('Invalid plotMode: ' + plotMode);
    }

    if (plotMode !== this.sqScatterPlotStore.plotMode) {
      this.flux.dispatch('SCATTER_PLOT_SET_PLOT_MODE', { plotMode });

      // Clear previous series data to ensure we don't calculate data again until both series have been updated
      _.chain([this.sqScatterPlotStore.xSeries, this.sqScatterPlotStore.ySeries])
        .map('id')
        .compact()
        .forEach(id => this.flux.dispatch('TREND_SERIES_CLEAR_DATA', { id }))
        .value();

      // Then update the timeseries
      this.fetchScatterPlot();
    }
  }

  /**
   * Sets the connect mode
   *
   * @param {boolean} connect - True to connect samples with a line, false to display them disconnected.
   */
  setConnect(connect: boolean) {
    this.flux.dispatch('SCATTER_PLOT_SET_CONNECT', { connect });
  }

  /**
   * Sets whether or not to show tooltips/labels on scatter plot points when a user hovers over them
   *
   * @param {boolean} showTooltips - whether or not ot show tooltips
   */
  setShowTooltips(showTooltips: boolean) {
    this.flux.dispatch('SCATTER_PLOT_SET_SHOW_TOOLTIPS', { showTooltips });
  }

  setMarkerSize(markerSize: number) {
    this.flux.dispatch('SCATTER_PLOT_SET_MARKER_SIZE', { markerSize });
  }

  resetMarkerSize() {
    this.flux.dispatch('SCATTER_PLOT_SET_MARKER_SIZE', { markerSize: undefined });
  }

  /**
   * Sets the user-selected region
   *
   * @param {XYRegion} region
   */
  setSelectedRegion(region: XYRegion) {
    this.flux.dispatch('SCATTER_PLOT_SET_SELECTED_REGION', region);
  }

  /**
   * Clears the user-selected region
   */
  clearSelectedRegion() {
    this.setSelectedRegion(EMPTY_XYREGION);
  }

  /**
   * Sets the view region to the current selected region, and clears the selected region
   */
  zoomToSelectedRegion() {
    this.zoomToRegion(this.sqScatterPlotStore.selectedRegion);
    this.clearSelectedRegion();
  }

  /**
   * Sets the view region to the specified region
   *
   * @param {XYRegion} region - where to zoom to
   */
  zoomToRegion(region: XYRegion) {
    this.flux.dispatch('SCATTER_PLOT_SET_VIEW_REGION', region);
    if (this.sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
      this.debouncedFetchXYData();
    } else if (this.sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
      this.debouncedFetchDensityPlot();
    }
  }

  /**
   * Clears the custom view region, allowing Highcharts to decide how much to show (all included data points)
   */
  expandViewRegion() {
    this.zoomToRegion(EMPTY_XYREGION);
    this.clearSelectedRegion();
  }

  /**
   * When the investigation range updates, this is triggered.
   */
  handleInvestigateRangeUpdate() {
    if (this.sqWorksheetStore.view.key !== WORKSHEET_VIEW.SCATTER_PLOT) {
      return;
    }

    if (this.sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
      if (this.sqScatterPlotStore.plotMode === SCATTER_PLOT_MODES.DISPLAY_RANGE) {
        // In Display Range plot mode, we want to only fetch and process data for the mini-map.
        this.fetchMinimapSignals();
      } else {
        // In Capsules plot mode, we want to fetch and process data for the X-Y plot and the mini-map.
        this.fetchScatterPlot();
      }
    } else if (this.sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
      this.fetchDensityPlot();
    }
  }

  /**
   * Fetches and updates the minimap plot signals
   */
  fetchMinimapSignals() {
    if (_.isUndefined(this.minimapWidth)) {
      return;
    }

    const xItem = this.getXItem();
    const yItem = this.getYItem();
    if (xItem) {
      this.fetchMinimapSignal(xItem.id, xItem.color, 'SCATTER_PLOT_SET_MINIMAP_X_SERIES');
    } else {
      this.flux.dispatch('SCATTER_PLOT_CLEAR_MINIMAP_X_SERIES');
    }

    if (yItem) {
      this.fetchMinimapSignal(yItem.id, yItem.color, 'SCATTER_PLOT_SET_MINIMAP_Y_SERIES');
    } else {
      this.flux.dispatch('SCATTER_PLOT_CLEAR_MINIMAP_Y_SERIES');
    }
  }

  fetchMinimapSignal(id, color, message) {
    const range = this.sqDurationStore.displayRange;
    this.sqFormula.computeSamples({
        id,
        range,
        formula: '$series.spikeCatcher(' +
          this.sqUtilities.getMSPerPixelWidth(range.duration, this.minimapWidth) + 'ms).parallelize()',
        limit: this.minimapWidth * SPIKECATCHER_PER_PIXEL
      })
      .then(({ samples }) => {
        this.flux.dispatch(message, {
          id,
          color,
          data: _.map(samples,
            (sample: any) => [sample.key / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND, _.isNumber(sample.value) ?
              sample.value : null])
        }, PUSH_IGNORE);
      });
  }

  /**
   * Adds a function of x line to the scatter plot chart.
   *
   * @param {Object} item - A signal item from the sqTrendSeriesStore
   */
  addFxLine(item) {
    this.flux.dispatch('SCATTER_PLOT_ADD_FX_LINE', item);
    this.fetchFxLine(item);
  }

  /**
   * Adds a new Prediction of Formula signal as a function of x line to scatter plot if it is a new signal and
   * matches the criteria for display.
   *
   * @param {string} id - The id of the signal
   * @param {boolean} isNew - True if the signal was just created
   */
  autoAddNewSignalAsFxLine(id, isNew) {
    if (isNew && this.sqWorksheetStore.view.key === WORKSHEET_VIEW.SCATTER_PLOT) {
      this.sqSignalsApi.getSignal({ id })
        .then(({ data }) => {
          if (this.sqScatterPlotStore.isValidFxSignal(data.parameters, data.formula)) {
            this.addFxLine(this.sqTrendSeriesStore.findItem(id));
          }
        });
    }
  }

  /**
   * Auto-color points on the scatter plot by a newly calculated condition (created in the Condition from Scatter Plot
   * Selection tool) if it is a new condition and its parameter signals are providing the scatter plot data.
   *
   * @param {string} id - The id of the condition
   * @param {boolean} isNew - True if the condition was just created
   */
  autoAddNewConditionForColoring(id, isNew) {
    if (isNew && this.sqWorksheetStore.view.key === WORKSHEET_VIEW.SCATTER_PLOT) {
      this.sqConditionsApi.getCondition({ id })
        .then(({ data }) => {
          if (this.sqScatterPlotStore.isRelevantCondition(data.parameters)) {
            this.addColorCondition(this.sqTrendCapsuleSetStore.findItem(id));
          }
        });
    }
  }

  /**
   * Fetches all function of x lines.
   */
  fetchAllFxLines() {
    _.forEach(this.sqScatterPlotStore.fxLines, line => this.fetchFxLine(line));
  }

  /**
   * Figures out the correct formula for a function of x line and then requests samples that will span the range of the
   * x-axis.
   *
   * @param {Object} item - The regression line item to fetch
   */
  fetchFxLine(item) {
    if (!this.sqScatterPlotStore.isViewRegionSet() && this.sqScatterPlotStore.isScatterDataEmpty()) {
      return;
    }

    const sqTrendActions = this.$injector.get<TrendActions>('sqTrendActions');
    const cancellationGroup = `fetchRegressionLine-${item.id}`;
    const range = this.sqScatterPlotStore.getXAxisRange();
    // Amount of data requested is based on the range in the X-Axis and the width of the chart
    const maxSamples = sqTrendActions.getChartWidth() / FX_LINE_SAMPLE_SPACING;
    const step = (range.end - range.start) / maxSamples;
    const warnings = [];
    const addWarning = message => warnings.push(
      { formulaLogEntries: { fxLine: { logDetails: [{ message: this.$translate.instant(message) }] } } });
    const metadata = {} as FxLineMetadata;

    this.flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id: item.id }, PUSH_IGNORE);
    this.sqPendingRequests.cancelGroup(cancellationGroup, true);
    this.sqSignalsApi.getSignal({ id: item.id }, { cancellationGroup })
      .then(({ data }) => {
        if (!this.sqScatterPlotStore.isValidFxSignal(data.parameters, data.formula)) {
          return this.$q.reject(
            { data: { statusMessage: this.$translate.instant('SCATTER.FX_LINE_ERROR_INPUT') } });
        }

        let regressionModelFormula = this.sqPredictionHelper.getRegressionModelFormula(data.formula);

        if (regressionModelFormula) {
          if (this.sqScatterPlotStore.isRegressionFormulaOutsideRange(regressionModelFormula,
            this.sqDurationStore.displayRange)) {
            addWarning('SCATTER.FX_LINE_WARNING_TRAINING');
          }

          const parameters = _.transform(data.parameters, (memo, parameter) => {
            memo[parameter.name] = parameter.item.id;
          }, {} as { [key: string]: string });

          if (parameters.a !== this.sqScatterPlotStore.xSeries.id) {
            addWarning('SCATTER.FX_LINE_WARNING_X_AXIS');
          }

          // Multiplication of signals that have % UOM treats them as decimals (e.g. 10% * 10% = 5%) and this throws
          // the scale of the regression line off because timeSince()^2 is unitless. So, for now this workaround to
          // remove multiplication from the the inputs when they are being multiplied (CRAB-16558)
          if (_.find(data.parameters, { name: 'a' }).item.valueUnitOfMeasure === '%') {
            regressionModelFormula = regressionModelFormula.replace(/\$a\^/g, '$a.setUnits("")^');
          }

          return this.sqFormula.computePredictionModel({
              formula: regressionModelFormula,
              parameters,
              cancellationGroup
            })
            .then((model) => {
              metadata.rSquared = model.regressionOutput.rSquared;
              return {
                formula: this.sqScatterPlotStore
                  .getFxLineFormulaFromModel(model, regressionModelFormula, step),
                parameters: {} as { [key: string]: string },
                numberFormat: data.numberFormat
              };
            });
        } else {
          const signalParameter = _.find(data.parameters,
            parameter => parameter.item.type !== API_TYPES.CALCULATED_SCALAR);
          if (signalParameter.item.id !== this.sqScatterPlotStore.xSeries.id) {
            addWarning('SCATTER.FX_LINE_WARNING_X_AXIS');
          }

          const parameters = _.transform(data.parameters,
            (result, parameter) => {
              if (parameter.item.type === API_TYPES.CALCULATED_SCALAR) {
                result[parameter.name] = parameter.item.id;
              }
            }, {} as { [key: string]: string });

          return {
            formula: this.sqScatterPlotStore.getFxLineFormulaFromFunction(data.formula, signalParameter, step),
            parameters,
            numberFormat: data.numberFormat
          };
        }
      })
      .then(({ formula, parameters, numberFormat }) => {
        metadata.formula = formula;
        return this.sqFormula.computeSamples({
            range,
            formula,
            parameters,
            limit: maxSamples,
            cancellationGroup
          })
          .then(computedFormula => ({ computedFormula, numberFormat }));
      })
      .then(({ numberFormat, computedFormula: { samples } }) => {
        this.flux.dispatch('TREND_SET_DATA_STATUS_PRESENT', {
          id: item.id,
          warningCount: warnings.length,
          warningLogs: warnings
        }, PUSH_IGNORE);
        this.flux.dispatch('SCATTER_PLOT_SET_FX_DATA', {
          id: item.id,
          samples,
          metadata,
          numberFormat
        }, PUSH_IGNORE);
      })
      .catch((e) => {
        this.flux.dispatch('SCATTER_PLOT_SET_FX_DATA', {
          id: item.id,
          samples: [],
          metadata: {}
        }, PUSH_IGNORE);
        sqTrendActions.catchItemDataFailure(item.id, cancellationGroup, e);
      });
  }

  /**
   * Removes a function of x line from the scatter plot chart. Also clear any errors or warnings from its data
   * status since it is being removed
   *
   * @param {Object} item - The signal to remove
   */
  removeFxLine(item) {
    this.flux.dispatch('SCATTER_PLOT_REMOVE_FX_LINE', { id: item.id });
    this.flux.dispatch('TREND_SET_DATA_STATUS_PRESENT', { id: item.id });
  }

  /**
   * Adds a condition to use for colorizing the scatter plot chart.
   *
   * @param {Object} item - The item to add
   */
  addColorCondition(item) {
    this.flux.dispatch('SCATTER_PLOT_ADD_COLOR_CONDITION', { id: item.id });
    this.fetchXYData();
  }

  /**
   * Removes a condition from being used for colorizing the scatter plot chart.
   *
   * @param {Object} item - The item to remove
   */
  removeColorCondition(item) {
    this.flux.dispatch('SCATTER_PLOT_REMOVE_COLOR_CONDITION', { id: item.id });
    this.fetchXYData();
  }

  /**
   * Sets a signal to use to colorize the scatter plot chart.
   *
   * @param {Object} item - The item to use
   */
  setColorSignal(item) {
    if (_.isNil(item)) {
      this.flux.dispatch('SCATTER_PLOT_REMOVE_COLOR_SIGNAL');
    } else {
      this.flux.dispatch('SCATTER_PLOT_SET_COLOR_SIGNAL', { id: item.id });
    }
    if (this.sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
      this.fetchXYData();
    }
  }

  /**
   * Sets a capsule property to use to color the points on the scatter plot
   *
   * @param property - capsule property name
   */
  setColorCapsuleProperty(property?: string) {
    if (_.isEmpty(property) || this.sqScatterPlotStore.colorCapsuleProperty === property) {
      this.flux.dispatch('SCATTER_PLOT_REMOVE_COLOR_CAPSULE_PROPERTY');
    } else {
      this.flux.dispatch('SCATTER_PLOT_SET_COLOR_CAPSULE_PROPERTY', { property });
    }
    this.fetchXYData();
  }

  /**
   * Set the base color for the gradient used whe ncoloring points by a numeric capsule property
   *
   * @param color - the color to use as the base color for a numeric capsule property gradient
   */
  setColorForCapsuleProperty(color: string) {
    this.flux.dispatch('SCATTER_PLOT_SET_COLOR_FOR_CAPSULE_PROPERTY', { color });
    this.fetchXYData();
  }

  /**
   * Adds a color and time range to be used for colorizing the scatter plot chart based on the time ranges.
   *
   * @param {ScatterPlotColorRange} colorRange - The color range to add
   */
  addColorRange(colorRange: ScatterPlotColorRange) {
    this.flux.dispatch('SCATTER_PLOT_ADD_COLOR_RANGE', colorRange);
  }

  /**
   * Updates a color range.
   *
   * @param {ScatterPlotColorRange} colorRange - The color range to update
   */
  updateColorRange(colorRange: ScatterPlotColorRange) {
    this.flux.dispatch('SCATTER_PLOT_UPDATE_COLOR_RANGE', colorRange);
  }

  /**
   * Removes a color and time range from the scatter plot chart
   *
   * @param {string} id - The id of the range to remove
   */
  removeColorRange(id) {
    this.flux.dispatch('SCATTER_PLOT_REMOVE_COLOR_RANGE', { id });
  }

  switchToDensityPlot() {
    this.setPlotView(SCATTER_PLOT_VIEWS.DENSITY_PLOT);
    this.fetchDensityPlot();
  }

  switchToScatterPlot() {
    this.setPlotView(SCATTER_PLOT_VIEWS.SCATTER_PLOT);
    this.fetchScatterPlot();
  }

  /**
   * Switch between scatter plot and density plot
   *
   * @param plotView - one of the SCATTER_PLOT_VIEWS
   */
  setPlotView(plotView) {
    this.flux.dispatch('SCATTER_PLOT_SET_PLOT_VIEW', { plotView });
  }

  /**
   * Set the number of bins to use when calculating data for the x-signal
   *
   * @param numXBins - number of bins for the x-signal
   */
  setNumXBins(numXBins: number) {
    this.flux.dispatch('DENSITY_PLOT_SET_NUM_X_BINS', { numXBins });
    this.debouncedFetchDensityPlot();
  }

  /**
   * Set the number of bins to use when calculating data for the y-signal
   *
   * @param numYBins - number of bins for the y-signal
   */
  setNumYBins(numYBins: number) {
    this.flux.dispatch('DENSITY_PLOT_SET_NUM_Y_BINS', { numYBins });
    this.debouncedFetchDensityPlot();
  }

  setShowColorModal(showColorModal: boolean) {
    this.flux.dispatch('SCATTER_PLOT_SET_SHOW_COLOR_MODAL', { showColorModal });
  }
}
