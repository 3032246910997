import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { ReportActions } from '@/reportEditor/report.actions';
import { ReportStore } from '@/reportEditor/report.store';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { Accordion, Card } from 'react-bootstrap';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import { ReportConfigBackupsEntry } from '@/hybrid/reportEditor/ReportConfigBackupsEntry.atom';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import _ from 'lodash';
import { CustomToggle } from '@/hybrid/core/CustomToggle.atom';

const reportConfigBackupsBindings = bindingsDefinition({
  sqReportStore: injected<ReportStore>(),
  sqReportActions: injected<ReportActions>()
});

export const ReportConfigBackups: SeeqComponent<typeof reportConfigBackupsBindings> = () => {
  const {
    sqReportStore,
    sqReportActions
  } = useInjectedBindings(reportConfigBackupsBindings);

  const backups = useFluxPath(sqReportStore, () => sqReportStore.backups);
  const backupPreview = useFluxPath(sqReportStore, () => sqReportStore.backupPreview);
  const { t } = useTranslation();

  return <Accordion className="reportBackups">
    <Card uib-accordion-group="true" className="panel">
      <Card.Header className="panel-heading">
        <CustomToggle eventKey="0" Component={Toggler} />
      </Card.Header>
      <Accordion.Collapse eventKey="0">
        <Card.Body className="panel-body">
          {_.map(backups, (el, index) => (
              <ReportConfigBackupsEntry
                onClickHandler={() => sqReportActions.setBackupPreview(el)}
                key={`bkup_${index}`}
                date={el.formattedBackupDate}
                displayName={el.username}
                selected={backupPreview && el.backupName === backupPreview.backupName}
              />
            )
          )}
          {backups.length === 0 &&
          <div className="text-italic sq-fairly-dark-gray p5">{t('REPORT.CONFIG.HISTORY_EMPTY')}</div>}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  </Accordion>;
};

const togglerBindings = bindingsDefinition({
  isCardOpen: prop<boolean>(),
  onClick: prop<() => void>()
});

const Toggler: SeeqComponent<typeof togglerBindings> = (props) => {
  const { isCardOpen, onClick } = props;
  const { t } = useTranslation();

  return (
    <h4 className="panel-title flexColumnContainer flexSpaceBetween" onClick={onClick}>
      <span>{t('REPORT.CONFIG.HISTORY')}</span>
      <Icon icon={isCardOpen ? 'fa-chevron-down' : 'fa-chevron-right'} />
    </h4>
  );
};
