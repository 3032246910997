import React from 'react';

/**
 * This is a wrapper that captures a variety of clicks
 */
export const EventCapture: React.FunctionComponent = (props) => {
  return (
    <div
      onKeyDown={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      onFocus={e => e.stopPropagation()}
      onMouseOver={e => e.stopPropagation()}>
      {props.children}
    </div>
  );
};
