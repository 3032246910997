import _ from 'lodash';
import angular from 'angular';
import tinycolor from 'tinycolor2';
import moment from 'moment-timezone';
import { CalculationsService } from '@/trendData/calculations.service';
import { YAxisActions } from '@/trendData/yAxis.actions';
import { TrendStore } from '@/trendData/trend.store';
import { TrendActions } from '@/trendData/trend.actions';
import { DurationStore } from '@/trendData/duration.store';
import { BaseItemStoreService } from '@/trendData/baseItemStore.service';
import { UtilitiesService } from '@/services/utilities.service';
import {
  ALPHA_UNCERTAIN,
  DASH_STYLES,
  ENUM_REGEX,
  ITEM_CHILDREN_TYPES,
  ITEM_DATA_STATUS,
  ITEM_TYPES,
  PREVIEW_ID,
  PREVIEW_PREFIX,
  SAMPLE_OPTIONS,
  TREND_VIEWS,
  Y_AXIS_TYPES
} from '@/trendData/trendData.module';
import { DEBOUNCE, ITEM_ICONS, NUMBER_CONVERSIONS, STRING_UOM } from '@/main/app.constants';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';

/**
 * A store for containing timeseries which can be displayed on the chart. A series is composed of an array of data
 * points and their associated timestamps.
 *
 * This store is augmented with additional functionality from sqBaseSignalStore.
 */
angular.module('Sq.TrendData')
  .store('sqTrendSeriesStore', sqTrendSeriesStore);

export type TrendSeriesStore = ReturnType<typeof sqTrendSeriesStore>['exports'];

function sqTrendSeriesStore(
  $injector: ng.auto.IInjectorService,
  sqBaseItemStore: BaseItemStoreService,
  sqCalculations: CalculationsService,
  sqUtilities: UtilitiesService
) {
  const store = {
    initialize() {
      this.state = this.immutable(_.defaults({
        // Avoid clearing state that is not dehydrated when the store is re-initialized. Async calls will repopulate
        // these properties as needed
        previewChartItem: this.state ? this.state.get('previewChartItem') : undefined
      }, sqBaseItemStore.COMMON_PROPS));
    },

    exports: {
      get capsuleSeries() {
        return this.getCapsuleSeries();
      },

      get nonCapsuleSeries() {
        return this.getNonCapsuleSeries();
      },

      get primarySeries() {
        return this.getPrimarySeries();
      },

      /**
       * Gets the longest capsule series duration
       *
       * @return {Number} The longest capsule series duration or zero if there are no capsule series.
       */
      get longestCapsuleSeriesDuration() {
        // Be sure to include in progress capsules
        let longestInProgress = 0;
        const inProgressCapsuleSeries = _.filter(this.getCapsuleSeries(), series => _.isNil(series.duration));
        if (!_.isEmpty(inProgressCapsuleSeries)) {
          const earliestStart = _.min(_.map(inProgressCapsuleSeries, 'startTime'));
          longestInProgress = moment.duration(getAlternateEndTime() - earliestStart).valueOf();
        }
        return _.max([longestInProgress, _.get(_.maxBy(this.getCapsuleSeries(), 'duration'), 'duration', 0)]);
      },

      /**
       * Returns the Id of the series that is being edited.
       */
      get editingId() {
        return this.state.get('editingId');
      },

      /**
       * Returns an Object describing a series (so that it can be updated upon scrolling)
       */
      get previewSeriesDefinition() {
        return this.state.get('previewSeriesDefinition');
      },

      /**
       * Returns the Store.nonCapusleSeries with the preview added
       */
      get nonCapsuleSeriesAndPreview() {
        return this.addPreview(this.getNonCapsuleSeries());
      },

      /**
       * Returns all the Store.items with the preview added
       */
      get itemsAndPreview() {
        return this.addPreview(this.state.get('items'));
      },

      /**
       * Returns the preview series
       */
      get previewChartItem() {
        return this.state.get('previewChartItem');
      },

      /**
       * Updates series from uncertain capsules based on the input map of old uncertain capsule ids to new replace
       * capsules. Id, start, end, and duration of capsules will be updated.
       *
       * @param {Object} map - A map of uncertain capsule ids to their replacement capsules
       */
      syncUncertainCapsuleSeries(map) {
        _.forEach(map, (newCapsule, oldCapsuleId) => {
          const series = _.filter(this.state.get('items'), ['capsuleId', oldCapsuleId]);
          _.forEach(series, (singleSeries) => {
            const index = this.findItemIndex(singleSeries.id);
            if (index > -1) {
              this.state.merge(['items', index], {
                id: createUniqueCapsuleSeriesId(newCapsule.id, singleSeries.interestId),
                capsuleId: newCapsule.id,
                startTime: newCapsule.startTime,
                endTime: newCapsule.endTime,
                duration: sqUtilities.getCapsuleDuration(newCapsule.startTime, newCapsule.endTime),
                otherChildrenOf: [newCapsule.isChildOf, newCapsule.id]
              });
            }
          });
        });
      },
      prepareDataForBarChartDisplay
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @returns {Object} The dehydrated items.
     */
    dehydrate() {
      const extraPropsToDehydrate = ['displayedAncillaryItemIds'];

      return {
        items: _.chain(this.state.get('items'))
          .filter(sqBaseItemStore.shouldDehydrateItem)
          .map(item => sqBaseItemStore.pruneDehydratedItemProps(item, extraPropsToDehydrate))
          .value(),
        editingId: this.state.get('editingId'),
        previewSeriesDefinition: this.state.get('previewSeriesDefinition')
      };
    },

    /**
     * Re-creates the series. Calls the action to rehydrate the data points.
     *
     * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
     * @return {Object} A promise that is fulfilled when items are completely rehydrated.
     */
    rehydrate(dehydratedState) {
      this.state.set('items', _.map(dehydratedState.items, _.bind(function(item) {
        return this.createSeries(item.id, item.name, item.lane, item.alignment,
          _.omit(item, ['id', 'name', 'lane', 'alignment']));
      }, this)));

      this.state.set('editingId', dehydratedState.editingId);
      this.state.set('previewSeriesDefinition', dehydratedState.previewSeriesDefinition);
    },

    handlers: {
      TREND_ADD_SERIES: 'addSeries',
      TREND_ADD_SERIES_FROM_CAPSULE: 'addSeriesFromCapsule',
      TREND_SIGNAL_ADD_ANCILLARY: 'addAncillary',
      TREND_SIGNAL_SET_STRING_ENUM: 'setStringEnum',
      TREND_REMOVE_ITEMS: 'removeItemsAndAncillary',
      TREND_UNSELECT_ALL_CAPSULES: 'unselectAllCapsuleSeries',
      TREND_REMOVE_SERIES_FROM_CAPSULE: 'removeSeriesFromCapsule',
      TREND_SERIES_RESULTS_SUCCESS: 'addData',
      TREND_SERIES_CLEAR_DATA: 'clearData',
      TREND_TOGGLE_CAPSULE_ALIGNMENT: 'updateCapsuleAlignment',
      TREND_SET_EDITING_SERIES_ID: 'setEditingId',
      TREND_SET_CHART_SERIES_PREVIEW: 'setPreviewChartItem',
      TREND_REMOVE_CHART_SERIES_PREVIEW: 'removePreviewChartItem',
      TREND_REMOVE_ALL_CAPSULE_SERIES: 'removeAllCapsuleSeries'
    },

    /**
     * Adds a series item.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.id - ID of the new item
     * @param {String} payload.name - Name of the new item
     * @param {String} payload.lane - Lane of the new item
     * @param {String} payload.alignment - Alignment of the new item
     * @param {String} [payload.color] - Color hex code (e.g. #CCCCCC)
     */
    addSeries(payload) {
      this.state.push('items', this.createSeries(payload.id, payload.name, payload.lane, payload.alignment,
        _.pick(payload, ['color'])));
    },

    /**
     * Creates a series item from a capsule item, and adds the new series item to store. The created
     * series item is created with a new guid and the name property as the capsule name concatenated
     * with the interest (i.e. series) name. Properties from the capsule and other properties copied
     * from the capsule and interest.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object} payload.capsule - Capsule from which to create a series.
     * @param {String} payload.capsule.id - ID of the capsule, to use as the ID of the created series.
     * @param {String} payload.capsule.name - Name of the capsule, to use as the name of the created series.
     * @param {String} payload.capsule.similarity - Profile search capsule property to copy to
     *   the created series.
     * @param {Object} payload.interest - Interest item from the capsule to use as a source for some properties.
     * @param {String} payload.interest.id - ID of the interest.
     */
    addSeriesFromCapsule(payload) {
      this.state.push('items', this.createSeries(createUniqueCapsuleSeriesId(payload.capsule.id, payload.interest.id),
        payload.interest.name + ' - ' + payload.capsule.capsuleSetName,
        payload.interest.lane, payload.interest.axisAlign, {
          startTime: payload.capsule.startTime,
          // Without this, series with no endTime were displayed “invisibly” on the chart. Hover would show points,
          // but the trend line would not appear. Though we had tried to avoid using this big hammer, in the end it
          // was necessary. CRAB-18916
          endTime: payload.capsule.endTime ? payload.capsule.endTime : getAlternateEndTime(),
          duration: payload.capsule.duration,
          capsuleId: payload.capsule.id,
          interestId: payload.interest.id,
          isChildOf: payload.interest.id,
          otherChildrenOf: [payload.capsule.isChildOf, payload.capsule.id],
          childType: ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE,
          similarity: payload.capsule.similarity,
          selected: payload.capsule.selected,
          capsuleSegmentData: [],
          color: payload.interest.color,
          formatOptions: payload.interest.formatOptions,
          sampleDisplayOption: payload.interest.sampleDisplayOption,
          dashStyle: payload.interest.dashStyle,
          axisVisibility: payload.interest.axisVisibility,
          axisAutoScale: payload.interest.axisAutoScale,
          lineWidth: payload.interest.lineWidth,
          rightAxis: payload.interest.rightAxis
        }));
    },

    /**
     * Associates an ancillary to its parent so that it can be redisplayed when the workstep is reloaded
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.parentId - The parent item id, from the sqTrendSeriesStore
     * @param {String[]} payload.ancillaryItemId - The IDs of the ancillary items
     */
    addAncillary(payload) {
      const parentCursor = this.getItemCursor(payload.parentId);
      if (parentCursor.exists()) {
        const displayedAncillaryItemIds = parentCursor.select('displayedAncillaryItemIds');
        if (!displayedAncillaryItemIds.exists()) {
          displayedAncillaryItemIds.set([]);
        }

        if (!_.includes(displayedAncillaryItemIds.get(), payload.ancillaryItemId)) {
          displayedAncillaryItemIds.push(payload.ancillaryItemId);
        }
      }
    },

    /**
     * Removes items. Clears the items from the displayedAncillaryItemIds as needed
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} payload.items - An array of items to remove
     */
    removeItemsAndAncillary(payload) {
      const removedIds = _.chain(payload.items as any[])
        .filter(['childType', ITEM_CHILDREN_TYPES.ANCILLARY])
        .flatMap(
          item => [item.id, item.interestId, _.get(item.shadedAreaLower, 'id'), _.get(item.shadedAreaUpper, 'id')])
        .compact()
        .value();
      this.state.select('items').map((itemCursor) => {
        const displayedAncillaryItemIds = itemCursor.select('displayedAncillaryItemIds');
        if (displayedAncillaryItemIds.exists() && _.intersection(removedIds, displayedAncillaryItemIds.get())) {
          displayedAncillaryItemIds.set(_.without(displayedAncillaryItemIds.get(), ...removedIds));
        }
      });

      this.removeItems(payload);
    },

    /**
     * Overrides the behavior of TREND_SET_COLOR to take zones and series from capsule into account when changing color
     *
     * Called when the color for an item changes, should set the color on the item as necessary
     *
     * @param {Object} item - serialized item object
     * @param {Object} color - color that should be set
     * @param {string} [parentId] - what was the id of the parent that triggered the change
     */
    onSetColor(item, color, parentId?) {
      if (parentId && item.childType === ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE && parentId !== item.interestId) {
        return; // Colors for signal from capsule come from the signal, not the condition
      }

      const cursor = this.getItemCursor(item.id);
      cursor.set('color', color);

      _.forEach(item.zones, (zone, index) => {
        if (_.has(zone, 'color')) {
          cursor.set(['zones', index, 'color'], color);
        }
      });
    },

    /**
     * Overrides the behavior of TREND_SET_SELECTED so that selection from capsules determine the selection of
     * series from capsules
     *
     * Called when the selection for an item change, should set the color on the item as necessary
     *
     * @param {Object} item - serialized item object
     * @param {Object} selected - selection status
     * @param {string} [parentId] - what was the id of the parent that triggered the change
     */
    onSetSelected(item, selected, parentId?) {
      if (parentId && item.childType === ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE && parentId !== item.capsuleId) {
        return; // Selection status for series from capsule come from the capsule, not the condition
      }

      this.state.select('items', this.findItemIndex(item.id)).set('selected', selected);
      if (this.isPreviewItem(item.id)) {
        this.state.select('previewChartItem').set('selected', selected);
      }
    },

    /**
     * Handles unselecting all capsuleSeries. This is done because selection status of capsuleSeries is kept in sync
     * with capsules.
     */
    unselectAllCapsuleSeries() {
      _.forEach(this.getCapsuleSeries(), item => this.setProperty(item.id, 'selected', false));
    },

    /**
     * Removes any series that were added from a capsule item and interest series.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object} payload.capsule - Capsule the series was created.
     * @param {String} payload.capsule.id - ID of the capsule
     * @param {Object} payload.interest - Interest from which the series was created.
     * @param {String} payload.interest.id - ID of the interest.
     */
    removeSeriesFromCapsule(payload) {
      this.removeItems({
        items: _.filter(this.getCapsuleSeries(), {
          capsuleId: payload.capsule.id,
          interestId: payload.interest.id
        })
      });
    },

    /**
     * Handles removing all capsuleSeries.
     */
    removeAllCapsuleSeries() {
      this.removeItems({ items: this.getCapsuleSeries() });
    },

    /**
     * Private helper function to add a series to the store using the specified properties.
     *
     * @param {String} id - ID to use for the new series
     * @param {String} name - Name to use for the new series
     * @param {String} lane - Lane to use for the new series
     * @param {String} alignment - Alignment to use for the new series
     * @param {Object} props - Object containing properties to apply to the new series
     * @returns {Object} Newly created series object.
     */
    createSeries(id, name, lane, alignment, props) {
      return this.createItem(id, name, ITEM_TYPES.SERIES, _.assign({
        data: [],
        samples: [],
        isStringSeries: false,
        yAxisConfig: {},
        yAxisType: Y_AXIS_TYPES.LINEAR,
        dashStyle: _.get(props, 'dashStyle', DASH_STYLES.SOLID),
        statistics: {},
        lane,
        axisAlign: alignment,
        axisVisibility: _.get(props, 'axisVisibility', true),
        axisAutoScale: _.get(props, 'axisAutoScale', true),
        lineWidth: _.get(props, 'lineWidth', 1),
        sampleDisplayOption: props.sampleDisplayOption || SAMPLE_OPTIONS.LINE,
        dataStatus: ITEM_DATA_STATUS.INITIALIZING,
        statusMessage: ''
      }, props));
    },

    /**
     * Adds series data points to a series item.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.id - ID of the item on which to add samples
     * @param {Object[]} payload.samples - Array of data points
     * @param {Number} payload.samples[].key - x-value of a data point
     * @param {Number} payload.samples[].value - y-value of a data point
     * @param {Boolean} payload.samples[].isUncertain - true if this data point is uncertain
     * @param {String} payload.valueUnitOfMeasure - Unit of Measure to be displayed
     * @param {Number} payload.warningCount - the count of warnings that will be passed to the base item store
     * @param {Object[]} payload.warningLogs - the log of warnings that will be passed to the base item store
     */
    addData(payload) {
      const cursor = this.getItemCursor(payload.id);
      let minTime;

      if (!cursor.exists()) {
        return;
      }

      const hasBounds = !_.isNil(cursor.get('shadedAreaLower')) && !_.isNil(cursor.get('shadedAreaUpper'));

      this.setDataStatusPresent(_.pick(payload, ['id', 'warningCount', 'warningLogs']));
      minTime = getMinTime(cursor);
      cursor.merge(this.getDataProps(
        _.assign(payload, { color: cursor.get('color') }),
        cursor.get('sampleDisplayOption'), minTime, hasBounds));

      // Ensure that the trend is displayed within it's lane when it first appears on the chart
      if (_.find(this.addPreview(this.getNonCapsuleSeries()), ['id', payload.id])) {
        cursor.merge($injector.get<YAxisActions>('sqYAxisActions').getYAxisConfig(payload.id));
      }

      if ($injector.get<TrendStore>('sqTrendStore').view === TREND_VIEWS.CAPSULE) {
        this.debouncedUpdateCapsuleAlignmentDisplay();
      }
    },

    /**
     * Used to override the calculated stringEnum for special cases where multiple string series share the same lane
     * (i.e., in capsule time)
     *
     * @param {string[]} ids - ids of string series that should share the enum
     * @param {Object[]} stringEnum - mapping between string values and numeric values
     */
    setStringEnum({ ids, stringEnum }) {
      _.forEach(ids, (id) => {
        const cursor = this.getItemCursor(id);
        if (!cursor.exists() || _.isEqual(cursor.get('stringEnum'), stringEnum)) {
          return;
        }

        const item = cursor.get();
        const minTime = getMinTime(cursor);
        cursor.merge(_.assign(
          transformDataPoints(
            item.samples, item.color, minTime, item.sampleDisplayOption, false, stringEnum
          ),
          {
            stringEnum,
            capsuleSegmentData:
            transformDataPoints(item.capsuleSegmentSamples, item.color, minTime, '', false, stringEnum).data
          }
        ));
      });
    },

    /**
     * This changes the behavior of TREND_SET_CUSTOMIZATIONS to handle changes to the sample display
     *
     * This function ensures that the data property is updated as needed (if signals are displayed
     * as bar charts their data is "prepared" differently than if the signal is visualized as a
     * line - different "compression" and optimizations are applied).
     *
     * Called when the customizations for an item change, should set the customizations on the item as necessary
     *
     * @param {Object} item - serialized item object
     * @param {Object} customizations - customize properties being set
     * @param {String} customizations.sampleDisplayOption - one of SAMPLE_OPTIONS
     * @param {string} [parentId] - what was the id of the parent that triggered the change
     */
    onSetCustomizations(item, customizations, parentId?) {
      const cursor = this.getItemCursor(item.id);
      cursor.merge(customizations);

      if (customizations.sampleDisplayOption) {
        const hasBounds = !_.isNil(item.shadedAreaLower) && !_.isNil(item.shadedAreaUpper);
        cursor.merge(transformDataPoints(
          item.samples, item.color, getMinTime(cursor), customizations.sampleDisplayOption, hasBounds, item.stringEnum
        ));
      }
    },

    /**
     * Internal utility function to generate the correct props for string series and normal series
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} payload.samples - Array of data points
     * @param {string} payload.color - The color of the signal represented as a hex code (e.g. #ffffff)
     * @param {number} payload.samples[].key - x-value of a data point
     * @param {number} [payload.samples[].value] - y-value of a data point (not present for bound)
     * @param {number} [payload.samples[].lower] - y-value of a data point (lower value for bound)
     * @param {number} [payload.samples[].upper] - y-value of a data point (upper value for bound)
     * @param {boolean} payload.samples[].isUncertain - true if this data point is uncertain
     * @param {string} payload.valueUnitOfMeasure - Unit of Measure to be displayed
     * @param {string} sampleDisplayOption - One of SAMPLE_OPTIONS (line, bar, ..)
     * @param {number} [minTime] - startTime if series from capsule
     * @param {boolean} [hasBounds] - true if data has a lower and upper bound instead of just a value
     */
    getDataProps(payload, sampleDisplayOption, minTime?, hasBounds?) {
      const isStringSeries = payload.valueUnitOfMeasure === STRING_UOM;

      const props = {
        valueUnitOfMeasure: payload.valueUnitOfMeasure,
        timingInformation: payload.timingInformation,
        meterInformation: payload.meterInformation,
        data: []
      };

      if (_.get(payload, 'samples.length')) {
        if (!hasBounds && isStringSeries) {
          const stringEnum = createStringEnum(payload.samples);
          const capsuleSegment = transformDataPoints(payload.capsuleSegmentSamples, payload.color, minTime,
            '', false, stringEnum);

          _.assign(props,
            transformDataPoints(payload.samples, payload.color, minTime, sampleDisplayOption, false, stringEnum), {
              isStringSeries,
              iconClass: ITEM_ICONS.STRING_SERIES,
              stringEnum,
              calculatedStringEnum: stringEnum,
              capsuleSegmentSamples: capsuleSegment.samples,
              capsuleSegmentData: capsuleSegment.data,
              originalData: null,
              originalStringEnum: null
            });
        } else {
          const capsuleSegment = transformDataPoints(payload.capsuleSegmentSamples, payload.color, minTime,
            '', hasBounds, null);
          _.assign(props,
            transformDataPoints(payload.samples, payload.color, minTime, sampleDisplayOption, hasBounds, null),
            {
              capsuleSegmentSamples: capsuleSegment.samples,
              capsuleSegmentData: capsuleSegment.data
            });
        }
      }
      return props;
    },

    /**
     * Clears series data.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.id - ID of the item to clear
     */
    clearData(payload) {
      const cursor = this.getItemCursor(payload.id);
      if (cursor.exists()) {
        cursor.set('data', []);
      }
    },

    /**
     * Handles changes based on y-alignment option changes.
     * Waits for the trendStore to ensure the correct alignment options are chosen.
     */
    updateCapsuleAlignment() {
      this.waitFor('sqTrendStore', function() {
        this.updateCapsuleAlignmentDisplay();
      }.bind(this));
    },

    /**
     * Calculates new y-alignment values for capsule time and updates the state.
     */
    updateCapsuleAlignmentDisplay() {
      const capsuleSeries = this.getCapsuleSeries();
      const sqTrendStore = $injector.get<TrendStore>('sqTrendStore');
      // Align the series respective to those that share their lane
      _.forEach(sqTrendStore.uniqueLanes, (lane) => {
        const yAlignmentValues = sqCalculations.performYAlignment(
          _.filter(capsuleSeries, { lane }),
          sqTrendStore.capsuleAlignment);

        _.forEach(yAlignmentValues, (yAlignment) => {
          this.getItemCursor(yAlignment.id).merge({
            yAlignment: _.omit(yAlignment, 'id', 'autoDisabled'),
            visible: !yAlignment.autoDisabled,
            autoDisabled: yAlignment.autoDisabled
          });
        });
      });
    },

    /**
     * Debounced call to updateCapsuleAlignmentDisplay to improve chart performance while scrolling.
     */
    debouncedUpdateCapsuleAlignmentDisplay: _.debounce(function() {
      this.updateCapsuleAlignmentDisplay();
    }, DEBOUNCE.MEDIUM),

    /**
     * Returns all series that are converted from a capsule
     */
    getCapsuleSeries() {
      const hideUnselectedItems = $injector.get<TrendStore>('sqTrendStore').hideUnselectedItems;
      const referenceInterestIds = $injector.get<TrendDataHelperService>('sqTrendDataHelper')
        .getSeriesIdsByCalculationType(TREND_TOOLS.REFERENCE);

      let capsuleStoreItems = $injector.get<TrendCapsuleStore>('sqTrendCapsuleStore').items;
      const someCapsulesSelected = _.some(capsuleStoreItems, ['selected', true]);
      const longestCapsuleFromStore: any = _.maxBy(capsuleStoreItems, 'duration');
      if (someCapsulesSelected) {
        capsuleStoreItems = _.filter(capsuleStoreItems, ['selected', true]);

        // When we aren't dimming, we want to make sure to keep the longest overall capsule so that when we filter out
        // the reference profiles, we show the longest slightly transparent and the selected one with full opacity
        if (!hideUnselectedItems) {
          capsuleStoreItems.push(longestCapsuleFromStore);
        }
      }

      const capsuleIds = _.map(capsuleStoreItems, 'id');

      const [referenceCapsuleSeries, nonReferenceCapsuleSeries] = _.chain(this.state.get('items'))
        .filter(item => item.isChildOf && item.childType === ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE)
        .uniqBy('id')
        .partition(item => _.includes(referenceInterestIds, item.isChildOf))
        .value();

      const filteredAndGroupedReferenceCapsuleSeries = _.chain(referenceCapsuleSeries)
        .filter(item => _.includes(capsuleIds, item.capsuleId))
        .groupBy('idChildOf')
        .map(group => _.groupBy(group, 'name'))
        .value();

      const referenceCapsuleSeriesToKeep = [];

      _.forEach(filteredAndGroupedReferenceCapsuleSeries, referenceGroup =>
        _.forEach(referenceGroup, (capsuleGroup) => {
          // If our longest capsule isn't selected and we aren't in dimming mode, remove it and keep it so
          // below we can figure out the longest of the selected capsules only
          if (someCapsulesSelected && !hideUnselectedItems && !longestCapsuleFromStore.selected) {
            const longestOverall = _.remove(capsuleGroup, series => series.capsuleId === longestCapsuleFromStore.id);
            if (longestOverall.length > 0) {
              referenceCapsuleSeriesToKeep.push(longestOverall[0]);
            }
          }

          referenceCapsuleSeriesToKeep
            .push(_.maxBy(capsuleGroup, series => series.duration || series.endTime - series.startTime));
        })
      );

      return _.concat(nonReferenceCapsuleSeries, referenceCapsuleSeriesToKeep);
    },

    /**
     * Returns all series that are not converted from capsules
     */
    getNonCapsuleSeries() {
      return _.filter(this.state.get('items'),
        item => !item.isChildOf || item.childType !== ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE);
    },

    /**
     * Returns all series that are not ancillary or converted from capsules
     */
    getPrimarySeries() {
      return _.filter(this.state.get('items'), item => !item.isChildOf);
    },

    /**
     * Sets the id of the Series being edited.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.id - the id.
     */
    setEditingId(payload) {
      this.state.set('editingId', payload.id);
      this.state.set('previewSeriesDefinition', {});
    },

    /**
     * Sets the preview series so they can be displayed. Also stores an Object that defines how to generate those
     * preview series so they can be updated when the users scrolls.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.formula - formula used to create the series
     * @param {Object[]} payload.parameters - Object array describing the parameters used to create the series
     * @param {String} payload.id - ID of the existing item
     * @param {String} payload.name - Name to use for the new series
     * @param {Object[]} payload.data - Array of data points
     * @param {Number} payload.samples[].key - x-value of a data point
     * @param {Number} payload.samples[].value - y-value of a data point
     * @param {Boolean} payload.samples[].isUncertain - true if this data point is uncertain
     * @param {String} payload.valueUnitOfMeasure - Unit of Measure to be displayed
     * @param {String} payload.lane - Lane of the new item
     * @param {String} payload.alignment - Alignment of the new item
     * @param {String} payload.color - Color hex code (e.g. #CCCCCC)
     */
    setPreviewChartItem(payload) {
      let previewObject, props;
      const previewPayloadId = _.startsWith(payload.id, PREVIEW_PREFIX) ? payload.id : PREVIEW_PREFIX + payload.id;
      const previewId = payload.id && payload.id !== PREVIEW_ID ? previewPayloadId : PREVIEW_ID;
      const sampleDisplayOption = payload.sampleDisplayOption || SAMPLE_OPTIONS.LINE;
      if (!_.isEmpty(payload.samples)) {
        this.state.set('previewSeriesDefinition', {
          formula: payload.formula,
          parameters: payload.parameters,
          id: previewId,
          color: payload.color,
          sampleDisplayOption
        });

        props = this.getDataProps(payload, sampleDisplayOption);

        if (!_.isNull(payload.color)) {
          props.color = payload.color;
        }

        previewObject = this.createSeries(previewId, payload.name, payload.lane,
          payload.alignment, props);
        this.state.set('previewChartItem', previewObject);
        this.state.merge('previewChartItem', $injector.get<YAxisActions>('sqYAxisActions').getYAxisConfig(previewId));
      } else {
        this.state.set('previewSeriesDefinition', {});
      }
    },

    /**
     * Removes the previewChartItem.
     */
    removePreviewChartItem() {
      const previewChartItem = this.state.get('previewChartItem');
      if (previewChartItem) {
        this.removeColor(previewChartItem.color);
        this.state.merge({
          previewChartItem: undefined,
          previewSeriesDefinition: undefined
        });
      }
    },

    /**
     * This function manages the correct inclusion of preview series. If a new Search is performed then the
     * resulting preview series are simply appended. If an existing search is edited then the original series are
     * omitted and replaced with the preview series.
     */
    addPreview(items) {
      let allSeries;
      const series = _.clone(items);
      let index = -1;
      const editingId = this.state.get('editingId');
      const previewSeriesDef = this.state.get('previewSeriesDefinition');
      const displayPreview = !_.isEmpty(previewSeriesDef);

      if (!_.isUndefined(editingId) && !_.isNull(editingId) && displayPreview) {
        index = _.findIndex(series, { id: editingId });
        series.splice(index, 1, this.state.get('previewChartItem'));
        allSeries = series;
      } else if (displayPreview) {
        allSeries = series.concat(this.state.get('previewChartItem'));
      } else {
        allSeries = series;
      }

      allSeries = _.chain(allSeries)
        .flatten()
        .compact()
        .value();

      return allSeries;
    }
  };

  return sqBaseItemStore.extend(store);

  /**
   * Transform input samples for display. Also used to enable 'singleton' points (points that don't have neighbors).
   *
   * @param {Object[]} rawSamples - the input samples
   * @param {string} color - color of the signal represented as a hex code (e.g. #ffffff)
   * @param {number} minTime - minimum time (in milliseconds) to subtract from all samples, for use in capsule
   *   time
   * @param {string} sampleDisplayOption - one of SAMPLE_OPTIONS (line, bar, ..)
   * @param {boolean} hasBounds - true if data has a lower and upper bound instead of just a value. if a series
   * `hasBounds` it cannot display uncertainty, cannot be a string series, and will not display discrete samples
   * @param {Object[]} [stringEnum] -  an enum to map between strings and numeric values. If provided, the series is
   *   assumed to be a string series where the values are strings (potentially in an enum format).
   * @returns {Object} An object with two lists of data: the unmodified samples and the display-ready data, as well
   * as the Highchart zones to indicate uncertainty.
   */
  function transformDataPoints(rawSamples, color, minTime, sampleDisplayOption, hasBounds, stringEnum?) {
    // Because of the way Highcharts implements zones (https://github.com/highcharts/highcharts/issues/6928) we need
    // to calculate how much time we need to add to the last certain data point to represent a 1 pixel offset.
    const chartWidth = $injector.get<TrendActions>('sqTrendActions').getChartWidth();
    const adjustment = _.get($injector.get<DurationStore>('sqDurationStore'), 'displayRange.duration', 1) / chartWidth;

    // Useful to determine where zones should be drawn to show uncertainty.
    const isLine = sampleDisplayOption === SAMPLE_OPTIONS.LINE || sampleDisplayOption === SAMPLE_OPTIONS.LINE_AND_SAMPLE;

    // We only compress data if the signal is visualized as a LINE; if the signal is visualized other ways we need the
    // indicators of where an actual samples are available.
    const useCompressed = sampleDisplayOption === SAMPLE_OPTIONS.LINE;

    const samples = (!hasBounds && sampleDisplayOption === SAMPLE_OPTIONS.BAR)
      ? prepareDataForBarChartDisplay(rawSamples)
      : rawSamples;

    let compressionInProgress = false;
    const data = [];
    const displayData = [];
    let previousValue, previousUncertain, maximumCertain;
    _.forEach(samples, (point: any) => {
      const time = point.key / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND - (minTime || 0);
      let returnValue;
      if (!hasBounds) {
        returnValue = [time, getYValue(point.value, stringEnum)];
      } else {
        const lower = getYValue(point.lower, stringEnum);
        const upper = getYValue(point.upper, stringEnum);
        if (!_.isNil(lower) && !_.isNil(upper) && lower <= upper) {
          returnValue = [time, lower, upper];
        } else {
          returnValue = [time, null, null];
        }
      }

      if (previousValue && useCompressed) {
        // Use state in the accumulator to figure out when to compress. The form of compression is: if 3 or more
        // consecutive samples have the same value, remove all but the first and last.
        if (previousValue[1] === returnValue[1] && previousValue[2] === returnValue[2]) {
          if (compressionInProgress) {
            displayData.pop();
          } else {
            compressionInProgress = true;
          }
        } else {
          compressionInProgress = false;
        }
      }

      if (previousValue && _.isNil(maximumCertain) && point.isUncertain) {
        // Check if we should start drawing uncertainty here.
        if (isLine && (_.isNil(returnValue[1]) === _.isNil(previousValue[1]))) {
          // A line segment before an uncertain sample should look uncertain.
          // If one xor the other end of the segment is null, there's no line segment and we don't need to worry about
          // it. If both ends have values, we need to move the uncertainty back to the first one. We also do this if
          // both ends are null (invalid) because otherwise a discrete signal won't ever show uncertain samples.
          maximumCertain = previousValue[0] + adjustment;
        } else if (!isLine && !previousUncertain) {
          // For a bar chart or individual samples, we just want to start at the last certain sample, valid or not.
          maximumCertain = previousValue[0] + adjustment;
        }
      }

      data.push(returnValue);
      displayData.push(returnValue);

      if (!hasBounds && sampleDisplayOption !== SAMPLE_OPTIONS.BAR) {
        // Display isolated samples as discrete points on the trend. To know if a point is a 'singleton' we look at
        // it's surrounding points - if they are both null or, if the  point is a beginning or endpoint and the only
        // possible neighbor is null we know we need to add a marker for it to show.
        const markerIndex = getIndexToShowAsMarker(displayData, point === _.last(samples));
        if (markerIndex !== null) {
          const [x, y] = displayData[markerIndex];
          displayData[markerIndex] = {
            x,
            y,
            marker: {
              enabled: true,
              symbol: 'square',
              radius: 1.5
            }
          };
        }
      }

      previousValue = returnValue;
      previousUncertain = point.isUncertain;
    });

    // Check for special case with no certain, valid samples
    const firstValidSample = _.find(samples, sample => !_.isNil(getYValue(sample.value, stringEnum)));
    if (_.get(firstValidSample, 'isUncertain')) {
      // Turn everything uncertain, even though we never saw a certain -> uncertain transition.
      maximumCertain = data[0][0];
    }

    return {
      data: displayData,
      samples: rawSamples,
      zones: (_.isNil(maximumCertain) ? [] : [
        { value: maximumCertain },
        { color: tinycolor(color).setAlpha(ALPHA_UNCERTAIN).toString(), dashStyle: DASH_STYLES.DASH }
      ])
    };
  }

  /**
   * We determine which points need to be shown as markers as we are moving through samples - as we are
   * constructing the data array. This helper, when called after every point is added will return all the indexes
   * that should be shown as markers
   */
  function getIndexToShowAsMarker(datums: [number, number | null][], last: boolean) {
    const datumCount = datums.length;
    const valid = idx => datums[idx][1] !== null;
    if (datumCount === 1 && last && valid(0)) {
      return 0; // the only datum is a valid point
    } else if (datumCount === 2 && valid(0) && !valid(1)) {
      return 0; // the first datum is a valid point and the second is not
    } else if (datumCount >= 2 && last && !valid(datumCount - 2) && valid(datumCount - 1)) {
      return datumCount - 1; // the last datum is a valid point and the second to last is not
    } else if (datumCount >= 3 && !valid(datumCount - 3) && valid(datumCount - 2) && !valid(datumCount - 1)) {
      return datumCount - 2; // valid datum in between two invalid datums
    } else {
      return null;
    }
  }

  /**
   * This helper gets resolves string values and booleans for use on the chart
   */
  function getYValue(value, stringEnum) {
    if (stringEnum && value && (_.isString(value) || _.isNumber(value))) {
      const match = value.toString().match(ENUM_REGEX);
      if (match) {
        return parseInt(_.result(_.find(stringEnum, ['stringValue', match[2]]), 'key'), 10);
      } else {
        return _.result(_.find(stringEnum, ['stringValue', value.toString()]), 'key');
      }
    } else if (_.isBoolean(value)) {
      return value ? 1 : 0;
    } else if (_.isFinite(value)) {
      return value;
    } else {
      return null;
    }
  }

  function createStringEnum(samples) {
    let key, stringValue, stringValueMap;
    const returnStringEnum = [];

    _.forEach(samples, function(point: any) {
      if (_.isString(point.value) || _.isFinite(point.value)) {
        const stringPointValue = (point.value).toString();
        const match = stringPointValue.match(ENUM_REGEX);
        if (match) {
          // If the regex matches, then the sample is an enumerated value
          if (_.isUndefined(_.find(returnStringEnum, ['stringValue', match[2]]))) {
            key = parseInt(match[1], 10);
            stringValue = match[2];
            returnStringEnum.push({
              key,
              stringValue
            });
          }
        } else {
          // Create map object containing sorted string names that have increasing integer values starting at zero
          if (!stringValueMap) {
            stringValueMap = _.chain(samples)
              .map('value')
              .uniq()
              .sort()
              .transform(function(map, val, i) {
                map[val] = i;
              }, {})
              .value();
          }

          // String
          if (_.isUndefined(_.find(returnStringEnum, ['stringValue', stringPointValue]))) {
            returnStringEnum.push({
              key: stringValueMap[stringPointValue],
              stringValue: stringPointValue
            });
          }
        }
      }
    });

    return returnStringEnum;
  }

  /**
   * Finds the minimum timestamp in the associated capsule.
   *
   * @param {Object} itemCursor - Cursor to the item
   * @return {Number} The timestamp in milliseconds of the minimum.
   */
  function getMinTime(itemCursor) {
    if (itemCursor.get('childType') === ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE) {
      return itemCursor.get('startTime');
    } else {
      return null;
    }
  }

  /**
   * Prepares data to display properly as bars.
   * Currently the Backend adds a sample point at the very beginning and at the end of the display range - that's
   * great when you're rendering a series as a line, it looks odd when you try to visualize a series as bars. To
   * prevent this oddness this function removes those artificial data points. In addition, null values, that produce
   * "holes" in series mess up the bar chart display as well - so those get pruned.
   *
   * Also manages step series data to ensure proper bar display of step series.
   *
   * @param {Object[]} data -  an array of samples [{key: x, value: y}, {key: x, value: y, isUncertain:true}, ....]
   *
   * @returns {Object[]} input data, de-duped by x-values. If the first and last datapoint are exact matches for the
   * display range start and end then those data points will be removed (as they are "artificial" points inserted by
   * the backend and don't display well as bars)
   */
  function prepareDataForBarChartDisplay(data: any[]) {
    const sqDurationStore = $injector.get<DurationStore>('sqDurationStore');
    const start = sqDurationStore.displayRange.start.valueOf();
    const end = sqDurationStore.displayRange.end.valueOf();

    data = _.chain(data)
      .filter(sample => _.isFinite(sample[1]) || sample.isUncertain || _.isFinite(sample.value))
      .map(point => _.has(point, 'key') ? point : { key: point[0], value: point[1] })
      .value();

    if (data.length <= 3) {
      return data;
    }

    if (_.first(data).key === start) {
      data = data.slice(1);
    }

    if (_.last(data).key === end) {
      data = data.slice(0, -1);
    }

    const xValues = _.map(data, 'key');

    let barChartData;
    if (xValues.length === _.uniq(xValues).length) {
      barChartData = data;
    } else {
      barChartData = deDupeDataByXValue(data);
    }

    return barChartData;
  }

  /**
   * De-dupes data based on x-value. Data is an Array of Arrays where the first entry corresponds to the x, the
   * second value to the y-axis ([[x,y], [x,y],[x, y]].
   * Step series contain 2 values for the same x-value - this is great if you're rendering them as lines, not so when
   * you want to visualize them as bars.
   *
   * @param {Object[]} data - data to be de-duped.
   * @return {Object[]} without duplicated values
   */
  function deDupeDataByXValue(data) {
    let current;
    let i = 1;
    const deDupedData = [];
    let previous = data[0];

    for (i; i < data.length; i++) {
      current = data[i];
      // for uncertain data the backend will sometimes return the same timestamp twice, without a value however.
      // This can lead to samples "disappear" - so we need to check for a valid value before assigning it.
      if (previous.key !== current.key || (!_.isFinite(current.value) && _.isFinite(previous.value))) {
        deDupedData.push(previous);
      }

      previous = current;
    }

    // check the last one and see if it needs to be added:
    if (previous.key !== _.last(deDupedData).key) {
      deDupedData.push(_.last(data));
    }

    return deDupedData;
  }

  /**
   * Creates a unique id for capsule series
   *
   * @param {string} capsuleId - The id of the capsule
   * @param {string} signalId - The id of the signal
   */
  function createUniqueCapsuleSeriesId(capsuleId, signalId) {
    return capsuleId + '' + signalId;
  }

  function getAlternateEndTime() {
    const displayRangeEnd = $injector.get<DurationStore>('sqDurationStore').displayRange.end.valueOf();
    const now = Date.now();
    return now < displayRangeEnd ? now : displayRangeEnd;
  }
}
