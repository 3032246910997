import React from 'react';
import { bindingsDefinition } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

const densityPlotLegendBindings = bindingsDefinition({});

/**
 * Used in the DensityPlot component to render the legend for coloring by time.
 *
 */
export const DensityPlotLegend: SeeqComponent<typeof densityPlotLegendBindings> = () => {
  const { t } = useTranslation();

  const legendText = t('DENSITY_PLOT.LEGEND_TIME');
  const legendTitle = t('DENSITY_PLOT.LEGEND_TITLE');

  return (
    <div className="densityPlotLegend mb10 ml25 mr25 well pl10 pr10 pt0 pb0 flexFill">
      <strong className="mr10">{legendTitle}:</strong>
      <span>{legendText}</span>
    </div>
  );

};
export default DensityPlotLegend;
export const sqDensityPlotLegend = angularComponent(densityPlotLegendBindings, DensityPlotLegend);
