import React from 'react';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget/src/utils';
import Command from '@ckeditor/ckeditor5-core/src/command';
import { DATE_RANGE_LABEL_ATTRIBUTES, elementWithRoot } from '../CKEditorPlugins.utilities';
import { EditDateRangeLabel } from '@/hybrid/annotation/ckEditorPlugins/components/EditDateRangeLabel.molecule';
import { BasePluginDependencies } from '../CkEditorPlugins.module';
import { PluginDependencies } from '@/hybrid/annotation/ckEditorPlugins/plugins/PluginDependencies';
import _ from 'lodash';

const SCHEMA_MODEL_NAME = 'date_range_label';
const CLASSES:string = 'customCkComponent dateRangeLabel';
const DATA_DATE_RANGE_ID = DATE_RANGE_LABEL_ATTRIBUTES.DATA_DATE_RANGE_ID;
const DATA_DATE_RANGE_FORMAT = DATE_RANGE_LABEL_ATTRIBUTES.DATA_DATE_RANGE_FORMAT;
const DATA_DATE_RANGE_CONTENT = DATE_RANGE_LABEL_ATTRIBUTES.DATA_DATE_RANGE_CONTENT;

/**
 * Plugin that enables the use of DateRangeLabel components inside the CKEditor.
 * The DateRangeLabel is a component that allows displaying information in real-time
 * about a specific DateRange (start time, end time). This can be used for labeling 
 * a group of content pieces that use the same date range. Once the date range changes
 * all the content pieces and the label will update.
 * 
 * This plugin adds the date_range_label command to CKEditor
 */
export class DateRangeLabelPlugin extends Plugin {
  init() {
    this.defineSchema();
    this.defineConverters();

    // add command
    this.editor.commands.add('date_range_label', new DateRangeLabelCommand(this.editor));
    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass('dateRangeLabel'))
    );
  }

  defineSchema() {
    const schema = this.editor.model.schema;
    schema.register(SCHEMA_MODEL_NAME, {
      allowWhere: '$text',
      isInline: true,
      isObject: true,
      allowAttributesOf: '$text',
      allowAttributes: [DATA_DATE_RANGE_ID, DATA_DATE_RANGE_FORMAT, DATA_DATE_RANGE_CONTENT]
    });
  }

  defineConverters() {
    const conversion = this.editor.conversion;

    // converters ((data) view → model)
    conversion.for('upcast').elementToElement({
      view: {
        name: 'span',
        attributes: {
          [DATA_DATE_RANGE_ID]: true,
          [DATA_DATE_RANGE_FORMAT]: true,
          [DATA_DATE_RANGE_CONTENT]: true,
          class: CLASSES
        }
      },
      model: (viewElement, { writer: modelWriter }) => {       
        return modelWriter.createElement(SCHEMA_MODEL_NAME, { 
          [DATA_DATE_RANGE_ID]: viewElement.getAttribute(DATA_DATE_RANGE_ID),
          [DATA_DATE_RANGE_FORMAT]: viewElement.getAttribute(DATA_DATE_RANGE_FORMAT),
          [DATA_DATE_RANGE_CONTENT]: viewElement.getAttribute(DATA_DATE_RANGE_CONTENT)
         });
      },
      converterPriority: 'highest'
    });

    // converters (model → data view)
    conversion.for('dataDowncast').elementToElement({
      model: SCHEMA_MODEL_NAME,
      view: (modelItem, { writer: viewWriter }) => this.createDataElement(modelItem, viewWriter)
    });

    // converters (model → editing view)
    conversion.for('editingDowncast').elementToElement({
      model: SCHEMA_MODEL_NAME,
      view: (modelItem, { writer: viewWriter }) => {
        const widgetElement = this.createViewElement(modelItem, viewWriter);

        // Enable widget handling on a placeholder element inside the editing view.
        return toWidget(widgetElement, viewWriter);
      }
    });
    conversion.for('editingDowncast').add(dispatcher => dispatcher.on('attribute', (evt, data, conversionApi) => {
      if (!_.includes([DATA_DATE_RANGE_ID, DATA_DATE_RANGE_FORMAT, DATA_DATE_RANGE_CONTENT], data.attributeKey)) return;
      const modelElement:ModelElement = data.item;
      conversionApi.consumable.consume(data.item, evt.name);
      const viewElement = conversionApi.mapper.toViewElement(modelElement);
      
      const domElement = this.editor.editing.view.domConverter.mapViewToDom(viewElement.getChild(0));
      if (!domElement) return;
      this.renderElement(modelElement, domElement);
    }));
  }

  createDataElement(modelItem, viewWriter) {
    const placeholderView = viewWriter.createContainerElement('span', {
      [DATA_DATE_RANGE_ID]: modelItem.getAttribute(DATA_DATE_RANGE_ID) || '',
      [DATA_DATE_RANGE_FORMAT]: modelItem.getAttribute(DATA_DATE_RANGE_FORMAT) || '',
      [DATA_DATE_RANGE_CONTENT]: modelItem.getAttribute(DATA_DATE_RANGE_CONTENT) || '',
      class: CLASSES
    },  { isAllowedInsideAttributeElement: true });
    return placeholderView;
  }

  createViewElement(modelItem, viewWriter) {
    const placeholderView = viewWriter.createContainerElement('span', {
      class: CLASSES
    }, { isAllowedInsideAttributeElement: true });
    // This element will host a React <Content /> component.
    const reactWrapper = viewWriter.createRawElement('span', {
      class: 'reactWrapper'
    }, (domElement) => {
      this.renderElement(modelItem, domElement);
    });

    viewWriter.insert(viewWriter.createPositionAt(placeholderView, 0), reactWrapper);

    return placeholderView;
  }

  renderElement(modelItem, domElement) {
    const editorConfiguration = this.editor.config;
    const deps: BasePluginDependencies = editorConfiguration.get(PluginDependencies.pluginName);
    const dateRangeId = modelItem.getAttribute(DATA_DATE_RANGE_ID);
    const dateRangeFormat = modelItem.getAttribute(DATA_DATE_RANGE_FORMAT);
    const dateRangeContent = modelItem.getAttribute(DATA_DATE_RANGE_CONTENT);

    elementWithRoot(deps, domElement, <EditDateRangeLabel 
      dateRangeId={dateRangeId} 
      dateRangeFormat={dateRangeFormat} 
      dateRangeContent={dateRangeContent}
      viewMode={!deps.canModify}
      updateId={this.updateAttribute(modelItem, DATA_DATE_RANGE_ID)}
      updateContent={this.updateAttribute(modelItem, DATA_DATE_RANGE_CONTENT)}
      updateFormat={this.updateAttribute(modelItem, DATA_DATE_RANGE_FORMAT)}
    />);
  }

  updateAttribute(modelItem, attribute) {
    return value => this.editor.model.change((writer) => {
      writer.setAttribute(attribute, value, modelItem);
    });
  }
}

export class DateRangeLabelCommand extends Command {
  execute() {
    const editor = this.editor;
    editor.model.change((writer) => {
      // Create a <placeholder> elment with the "name" attribute (and all the selection attributes)...
      const element = writer.createElement(SCHEMA_MODEL_NAME, []);

      // ... and insert it into the document.
      editor.model.insertContent(element);

      // Put the selection on the inserted element.
      writer.setSelection(element, 'on');
      // we need to focus back on the editor after a short delay
      editor.editing.view.focus();
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;

    const isAllowed = model.schema.checkChild(selection.focus.parent, SCHEMA_MODEL_NAME);
    this.isEnabled = isAllowed;
  }
}
