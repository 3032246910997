import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Form } from 'react-bootstrap';
import { HelpIcon } from '@/hybrid/core/Icon.atom';

interface CheckboxProps {
  id: string;
  isChecked: boolean;
  label: string | React.ReactNode;
  extraLabel?: React.ReactElement;
  onChange?: (event: any) => void;
  value?: any;
  type?: 'radio' | 'checkbox';
  disabled?: boolean;
  classes?: string;
  tooltip?: string;
  description?: string;
  skipMemo?: boolean;
  descriptionClasses?: string;
  onIconClick?: () => any;
}

export const CheckboxUnwrapped: React.FunctionComponent<CheckboxProps> = (props) => {
  const {
    onChange = _.noop,
    isChecked,
    label,
    extraLabel,
    id,
    disabled,
    type = 'checkbox',
    classes,
    tooltip,
    description,
    value,
    descriptionClasses = 'ml20 pl2 mtn5 width-200 font-size-smaller',
    onIconClick
  } = props;

  const { t } = useTranslation();

  return (
    <span className={classNames('flexColumnContainer', 'flexAlignCenter', classes,
      isChecked ? 'specChecked' : 'specNotChecked')}
      data-testid={id}>
      <span className="flexColumnContainer flexAlignCenter">
        <Form.Check
          className="unselectable sq-checkbox checkbox mt0 mb0"
          value={value}
          onChange={onChange}
          checked={isChecked}
          type={type}
          id={id}
          data-testid={id + '-input'}
          disabled={disabled}
          label={typeof label === 'string' ? t(label) : label}
        />
        {extraLabel}
      </span>
      {tooltip && <HelpIcon tooltip={tooltip} extraClassNames="pl5" onClick={onIconClick} />}
      {description &&
      <div className={
        classNames("text-italic sq-fairly-dark-gray", descriptionClasses)
      }>
        {t(description)}
      </div>}
    </span>
  );
};

/**
 * Basic checkbox
 */
export const Checkbox = React.memo(CheckboxUnwrapped, (prev, next) => !(
  prev.isChecked !== next.isChecked ||
  prev.label !== next.label ||
  !_.isEqual(prev.value, next.value) ||
  prev.disabled !== next.disabled ||
  prev.classes !== next.classes ||
  prev.skipMemo
));
