import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import React from 'react';
import { Button, FormControl, InputGroup, Modal, Table } from 'react-bootstrap';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { useEffect, useState } from 'react';
import { CopyableInputField } from '@/hybrid/core/CopyableInputField.molecule';
import { AccessKeysApi } from '@/sdk';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { NotificationsService } from '@/services/notifications.service';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { DateTimeService } from '@/datetime/dateTime.service';
import _ from 'lodash';
import { onEnterKeypress } from '@/hybrid/core/onEnterKeypress.util';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';

const accessKeyBindings = bindingsDefinition({
  onClose: prop<() => void>(),
  show: prop<boolean>(),
  sqAccessKeysApi: injected<AccessKeysApi>(),
  sqNotifications: injected<NotificationsService>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqDateTime: injected<DateTimeService>()
});

export const AccessKeyModal: SeeqComponent<typeof accessKeyBindings> = (props) => {
    const {
      sqAccessKeysApi,
      sqNotifications,
      sqWorksheetStore,
      sqDateTime
    } = useInjectedBindings(accessKeyBindings);

    const { t } = useTranslation();
    const { onClose } = props;
    const [show, setShow] = useState(true);
    const [newCommonName, setNewCommonName] = useState('');
    const [keyPassword, setKeyPassword] = useState('');
    const [keyName, setKeyName] = useState('');
    const [showCreatedKey, setShowCreatedKey] = useState(false);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const [keyDeletionCandidate, setKeyDeletionCandidate] = useState({ name: '', description: '' });
    const [keys, setKeys] = useState([]);
    useEffect(() => { refreshKeys(); }, []);

    /**
     * Closes the modal.
     */
    const closeModal = () => {
      setShow(false);
      onClose();
    };

    /**
     * Fetches all the Access Keys from Seeq and refreshes the list in the modal.
     */
    const refreshKeys = () => {
      sqAccessKeysApi.getKeys({ offset: 0, limit: 1000, getAll: false })
        .then(({ data: keylist }) => setKeys(keylist.keys));
    };

    /**
     * Dispatches a request to create a new Access Key with the given common name.
     *
     * @param {string} label - the common name to be associated with the new token.
     */
    const createKey = (label: string) => {
      setNewCommonName('');
      sqAccessKeysApi.createKey({ name: label })
        .then(({ data: key }) => {
          setKeyPassword(key.password);
          setKeyName(key.name);
          setShowCreatedKey(true);
          refreshKeys();
        });
    };

    /**
     * Deletes the current keyDeletionCandidate and notifies the user.
     */
    const deleteKey = () => {
      return sqAccessKeysApi.deleteKey({ keyName: keyDeletionCandidate.name })
        .then(refreshKeys)
        .then(() => {
            setShowDeleteWarning(false);
            setKeyDeletionCandidate({ name: '', description: '' });
            sqNotifications.success(t('ACCESS_KEY.DELETE_SUCCESS'));
          }
        );
    };

    /**
     * Shows the delete warning for the given token and gives the user the option to confirm deletion.
     *
     * @param {AccessKeyOutputV1} token - The token to warn about deleting.
     */
    const deleteWarning = (token) => {
      setShowDeleteWarning(true);
      setKeyDeletionCandidate(token);
    };

    /**
     * Formats timestamps for the token modal.
     *
     * @param dateTime - the timestamp to format.
     * @param {boolean} summary - generates a short date if true, or a long date if false.
     */
    const formatTime = (dateTime, summary) => {
      const format = summary ? 'MMM D, YYYY' : 'MMM D, YYYY h:mm A';
      if (dateTime) {
        return sqDateTime.formatTime(dateTime, sqWorksheetStore.timezone, format);
      } else {
        return '';
      }
    };

    /**
     * Assembles the table containing access key information to be displayed to the user.
     */
    const buildKeyList = () => _.map(keys, (item) => {
      return (
        <tr data-testid="key-row" key={item.name}>
          <td className="forceVerticalAlignMiddle nowrap">
            <HoverTooltip text="DELETE">
              <TextButton testId="delete-button" size="sm" variant="danger" icon="fa-trash" iconStyle="white"
                onClick={() => deleteWarning(item)} extraClassNames="muted" />
            </HoverTooltip>
          </td>
          <td className="col-sm-4 forceVerticalAlignMiddle">
            {item.description}
          </td>
          <td className="nowrap forceVerticalAlignMiddle">
            <HoverTooltip text={formatTime(item.createdAt, false)}>
              <span>{formatTime(item.createdAt, true)}</span>
            </HoverTooltip>
          </td>
          <td className="nowrap forceVerticalAlignMiddle">
            <HoverTooltip text={formatTime(item.lastUsed, false)}>
              <span>{formatTime(item.lastUsed, true)}</span>
            </HoverTooltip>
          </td>
          <td className="forceVerticalAlignMiddle">
            <CopyableInputField value={item.name} notifyMessage='ACCESS_KEY.COPY_SUCCESS_NAME'
              fieldTooltip="ACCESS_KEY.KEY_NAME" readOnly={true} buttonTooltip="ACCESS_KEY.COPY_TO_CLIPBOARD"
              testId={item.name + 'row'} />
          </td>
        </tr>
      );
    });

    return (
      <Modal show={show} onShow={refreshKeys} onHide={closeModal} animation={false} data-testid="accessKeyModal">
        <Modal.Header closeButton={true}>
          <Modal.Title>{t('ACCESS_KEY.MODAL_TITLE')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb10">
            <InputGroup>
              <FormControl
                data-testid="create-key-field"
                autoComplete="off"
                type="text"
                name="commonName"
                className="width-maximum"
                value={newCommonName}
                onChange={e => setNewCommonName(e.target.value)}
                placeholder={t('ACCESS_KEY.NAME')}
                required={true}
                onKeyUp={onEnterKeypress(createKey)} />
              <InputGroup.Append data-testid="create-key-button" className="input-group-btn">
                <Button className="btn btn-default"
                  disabled={!newCommonName}
                  onClick={() => createKey(newCommonName)}>{t('ACCESS_KEY.CREATE_KEY')}</Button>
              </InputGroup.Append>
            </InputGroup>
          </div>

          <div data-testid="created-key-info" hidden={!showCreatedKey} className="well well-sm mt10 mb10">
            <p>{t('ACCESS_KEY.NEW_KEY_DETAILS')}</p>
            <div className="form-group row">
              <label className=" col-sm-2 col-form-label">{t('ACCESS_KEY.KEY_NAME')}</label>
              <div className="col-sm-8">
                <CopyableInputField testId="new-key-name" readOnly={true}
                  fieldTooltip="ACCESS_KEY.KEY_NAME"
                  buttonTooltip="ACCESS_KEY.COPY_TO_CLIPBOARD" notifyMessage='ACCESS_KEY.COPY_SUCCESS_NAME' value={keyName} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">{t('ACCESS_KEY.KEY_PASSWORD')}</label>
              <div className="col-sm-8">
                <CopyableInputField testId="new-key-password" readOnly={true}
                  fieldTooltip="ACCESS_KEY.KEY_PASSWORD" value={keyPassword}
                  buttonTooltip="ACCESS_KEY.COPY_TO_CLIPBOARD" notifyMessage='ACCESS_KEY.COPY_SUCCESS_PASSWORD'  />
              </div>
            </div>
          </div>
          <div data-testid="delete-banner" hidden={!showDeleteWarning} className="alert alert-danger">
            <p>{t('ACCESS_KEY.DELETE_WARNING')}</p>
            <br />
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">{t('ACCESS_KEY.NAME')}</label>
              <div className="col-sm-8">
                <FormControl data-testid="delete-key-common-name" type="text" className="form-control"
                  readOnly={true}
                  uib-tooltip={t('ACCESS_KEY.NAME')}
                  value={keyDeletionCandidate.description} />
              </div>
            </div>
            <div className=" form-group row">
              <label className=" col-sm-2 col-form-label">{t('ACCESS_KEY.KEY_NAME')}</label>
              <div className=" col-sm-8">
                <FormControl data-testid="delete-key-name" type=" text" className=" form-control"
                  readOnly={true}
                  uib-tooltip={t('ACCESS_KEY.KEY_NAME')} value={keyDeletionCandidate.name} />
              </div>
            </div>
            <br />
            <TextButton variant="danger" onClick={deleteKey} label="DELETE" extraClassNames="mr5" testId="confirmDeleteButton" />
            <TextButton variant="theme" onClick={() => setShowDeleteWarning(false)} label="CANCEL" testId="cancelDeleteButton" />
          </div>
          <div className=" tableWrapper mt15 mb0 small" hidden={keys.length === 0}>
            <Table data-testid="key-table" striped={true} size=" sm">
              <thead>
                <tr>
                  <th />
                  <th>{t('ACCESS_KEY.NAME')}</th>
                  <th>{t('ACCESS_KEY.CREATED_AT')}</th>
                  <th className=" text-nowrap">{t('ACCESS_KEY.LAST_USED')}</th>
                  <th className=" text-nowrap">{t('ACCESS_KEY.KEY_NAME')}</th>
                </tr>
              </thead>
              <tbody>
                {buildKeyList()}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <TextButton size="sm" onClick={closeModal} label="CLOSE" testId="close-button" />
        </Modal.Footer>
      </Modal>
    );
  }
;

export const sqAccessKeyModal = angularComponent(accessKeyBindings, AccessKeyModal);
