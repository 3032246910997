import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ExplorerModalBody } from '@/hybrid/explorer/ExplorerModalBody.molecule';
import { ExploreWorkbookModalStore } from '@/hybrid/explorer/exploreWorkbookModal.store';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { ExploreWorkbookModalActions } from '@/hybrid/explorer/exploreWorkbookModal.actions';
import { NotificationsService } from '@/services/notifications.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { ContentApi, ItemPreviewWithAssetsV1, WorkbooksApi } from '@/sdk';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { HttpHelpersService } from '@/services/httpHelpers.service';
import { SelectWorksheet } from '@/hybrid/worksheets/SelectWorksheet.molecule';
import { WizardModal } from '@/hybrid/core/WizardModal.molecule';

const itemsFromWorksheetModalBindings = bindingsDefinition({
  setItems: prop<(items: ItemPreviewWithAssetsV1[]) => void>(),
  setModalIsOpen: prop<(showModal: boolean) => void>(),
  sqExploreWorkbookModalStore: injected<ExploreWorkbookModalStore>(),
  sqExploreWorkbookModalActions: injected<ExploreWorkbookModalActions>(),
  sqHttpHelpers: injected<HttpHelpersService>(),
  sqNotifications: injected<NotificationsService>(),
  sqWorkbooksApi: injected<WorkbooksApi>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
  sqContentApi: injected<ContentApi>()
});

const auditedItemTypes = [SeeqNames.Types.Asset, 'ConfigurationOption', SeeqNames.Types.CalculatedCondition,
  SeeqNames.Types.StoredCondition, SeeqNames.Types.Condition, SeeqNames.Types.Agent, SeeqNames.Types.Connector,
  SeeqNames.Types.Chart, SeeqNames.Types.AggregatingFormulaFunction, SeeqNames.Types.ThresholdMetric,
  SeeqNames.Types.CalculatedScalar, SeeqNames.Types.CalculatedSignal, SeeqNames.Types.StoredSignal,
  SeeqNames.Types.Signal, SeeqNames.Types.User, SeeqNames.Types.FormulaDoc, SeeqNames.Types.FormulaPackageDoc,
  SeeqNames.Types.FormulaFunction, SeeqNames.Types.UserDefinedFormulaFunction, SeeqNames.Types.FormulaPackage,
  SeeqNames.Types.FormulaPackageDoc, SeeqNames.Types.UserGroup
];

export const ItemsFromWorksheetModal: SeeqComponent<typeof itemsFromWorksheetModalBindings> = (props) => {
  const {
    sqExploreWorkbookModalStore,
    sqExploreWorkbookModalActions,
    sqHomeScreenUtilities,
    sqHttpHelpers,
    sqWorkbooksApi,
    sqNotifications,
    sqContentApi
  } = useInjectedBindings(itemsFromWorksheetModalBindings);

  const { setItems, setModalIsOpen } = props;
  const { t } = useTranslation();

  const [allowedTypes] = useState([SeeqNames.Types.Folder, SeeqNames.Types.Analysis, SeeqNames.Types.Topic]);
  const { workbookId } = useFlux(sqExploreWorkbookModalStore);
  const [workstepIds, setWorkstepIds] = useState([]);
  const [worksheetIds, setWorksheetIds] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [totalWorksheets, setTotalWorksheets] = useState(-1);
  const [stepChanged, setStepChanged] = useState(false);
  const selectAllLabel = allSelected ? 'UNSELECT_ALL' : 'SELECT_ALL';
  const showWorksheetSelection = !!workbookId;
  const noWorksheetsSelected = _.isEmpty(worksheetIds);

  useEffect(() => {
    setAllSelected(workstepIds.length === totalWorksheets);
  }, [workstepIds]);

  useEffect(() => {
    if (!_.isEmpty(workbookId)) {
      selectAll();
    }
  }, [workbookId]);

  const worksheetsCustomHeader = (
    <div className="flexRowContainer">
      <div className="flexColumnContainer flexAlignCenter">
        <h4 className="mr10" id="example-modal-sizes-title-lg">
          {t('ITEMS_FROM_WORKSHEET.SELECT_WORKSHEETS')}
        </h4>
        {showWorksheetSelection &&
        <a href="#" onClick={() => { allSelected ? unselectAll() : selectAll(); }}>
          {t(selectAllLabel)}
        </a>}
      </div>
      {showWorksheetSelection &&
      <div className="alert-light text-italic" role="alert">
        {t('ITEMS_FROM_WORKSHEET.INFO')}
      </div>}
    </div>
  );

  const fetchWorksheetItems = (worksheetIds, workstepIds) => {
    Promise.all(_.map(worksheetIds, (worksheetId, index) =>
        sqWorkbooksApi.getReferencedItems({ worksheetId, workstepId: workstepIds[index] })))
      .then((results: any) => {
        const itemsToAdd = _.chain(results)
          .map('data')
          .flatMap(data => sqHttpHelpers.addAssetsProperty(data).items)
          .reject(item => !_.includes(auditedItemTypes, item.type))
          .value();
        setItems(itemsToAdd);
        return itemsToAdd;
      })
      .then((results) => {
        _.isEmpty(results) ?
          sqNotifications.infoTranslate('ITEMS_FROM_WORKSHEET.NOTIFICATION_NO_ITEMS_FOUND')
          : sqNotifications.successTranslate('ITEMS_FROM_WORKSHEET.NOTIFICATION_SUCCESS');
      })
      .catch(() => {
        setItems([]);
        sqNotifications.errorTranslate('ITEMS_FROM_WORKSHEET.NOTIFICATION_FAILED');
      });
  };

  const fetchTopicItems = () => {
    Promise.all(_.map(worksheetIds, worksheetId => sqWorkbooksApi.getWorksheet({ workbookId, worksheetId })))
      .then(results => Promise.all(_.map(results,
        result => sqContentApi.getContentsWithAllMetadata({ reportId: result.data.report.id })))
        .then((results: any) => {
          const contentItems = _.chain(results)
            .map('data')
            .flatMap('contentItems')
            .reject(result => result.archived === true)
            .value();
          const sourceWorksheetIds = _.chain(contentItems).map('sourceWorksheet').uniq().value();
          const sourceWorkstepIds = _.chain(contentItems).map('sourceWorkstep').uniq().value();
          return { sourceWorksheetIds, sourceWorkstepIds };
        })
        .then(({ sourceWorksheetIds, sourceWorkstepIds }) =>
          fetchWorksheetItems(sourceWorksheetIds, sourceWorkstepIds)));
  };

  const fetchItemsForTopicsOrAnalysis = () => {
    const worksheetId = _.first(worksheetIds);
    sqWorkbooksApi.getWorksheet({ workbookId, worksheetId })
      .then(
        result => _.isNil(result.data.report?.id) ? fetchWorksheetItems(worksheetIds, workstepIds) : fetchTopicItems());
  };

  const unselectAll = () => {
    setWorksheetIds([]);
    setWorkstepIds([]);
    setAllSelected(false);
  };

  const selectAll = () => {
    setAllSelected(true);
    sqHomeScreenUtilities.getWorkbook(workbookId, { includeWorkstepId: true })
      .then((workbook) => {
        setTotalWorksheets(workbook.worksheets.length);
        setWorksheetIds(_.map(workbook.worksheets, 'worksheetId'));
        setWorkstepIds(_.map(workbook.worksheets, 'workstepId'));
      });
  };

  const selectWorksheets = (worksheetId: string, workstepId: string, totalWorksheets: number) => {
    _.includes(worksheetIds, worksheetId)
      ? setWorksheetIds(_.without(worksheetIds, worksheetId))
      : setWorksheetIds(worksheetIds.concat(worksheetId));
    _.includes(workstepIds, workstepId)
      ? setWorkstepIds(_.without(workstepIds, workstepId))
      : setWorkstepIds(workstepIds.concat(workstepId));
    setTotalWorksheets(totalWorksheets);
  };

  const reset = () => {
    sqExploreWorkbookModalActions.clear();
    setModalIsOpen(false);
  };

  const setContent = () => {
    fetchItemsForTopicsOrAnalysis();
    reset();
  };

  const stepDefinitions = [
    {
      title: 'ITEMS_FROM_WORKSHEET.SELECT_WORKBOOK',
      component: <ExplorerModalBody
        show={true}
        next={_.noop}
        store={sqExploreWorkbookModalStore}
        actions={sqExploreWorkbookModalActions}
        allowedTypes={allowedTypes}
      />,
      shouldGoToNext: { canGoToNext: !!workbookId, showActionButton: false },
      modalClassName: 'items-from-worksheet-modal',
      actionButtonLabel: 'SELECT'
    }, {
      customHeader: worksheetsCustomHeader,
      title: 'ITEMS_FROM_WORKSHEET.SELECT_WORKSHEETS',
      component:
        <SelectWorksheet
          workstepIds={workstepIds}
          worksheetIds={worksheetIds}
          setSelectedWorksheet={selectWorksheets}
          workbookId={workbookId}
          next={_.noop} />,
      actionButtonLabel: 'INSERT',
      actionFn: setContent,
      backButtonFn: () => {
        sqExploreWorkbookModalActions.setWorkbookId(undefined);
        setStepChanged(!stepChanged);
      },
      valid: !noWorksheetsSelected,
      modalClassName: 'items-from-worksheet-modal'
    }
  ];

  return (
    <WizardModal
      onClose={reset}
      testId='wizardGetItemsFromWorksheets'
      stepDefinitions={stepDefinitions}
    />
  );
};
