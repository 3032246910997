import _ from 'lodash';
import React, { useEffect } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { TranslationWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { TrendStore } from '@/trendData/trend.store';
import { TREND_VIEWS } from '@/trendData/trendData.module';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { MAX_CAPSULE_TIME_ITEMS } from '@/trendViewer/trendViewer.module';

const trendHelpBindings = bindingsDefinition({
  sqTrendStore: injected<TrendStore>(),
  sqTrendSeriesStore: injected<TrendSeriesStore>(),
  sqTrendCapsuleSetStore: injected<TrendCapsuleSetStore>(),
  sqWorksheetStore: injected<WorksheetStore>()
});

export const TrendHelp: SeeqComponent<typeof trendHelpBindings> = () => {
  const {
    sqTrendStore,
    sqTrendSeriesStore,
    sqTrendCapsuleSetStore,
    sqWorksheetStore
  } = useInjectedBindings(trendHelpBindings);

  const { t } = useTranslation();

  const assetTreeIcon = '<i class=\'sq-fairly-dark-gray pl5 pr10 fa fa-lg fa-cube\'></i>';
  const chartIcon = '<i class=\'fa fa-area-chart pr3\'></i>';
  const dataIcon = '<i class=\'fa fa-database pr3\'></i>';
  const dimmingIcon = '<i class=\'fa fc fc-series-dim\'></i>';
  const groupIcon = '<i class=\'fc fc-group pr3\'></i>';

  const view = useFluxPath(sqTrendStore, () => sqTrendStore.view);
  const hideUnselectedItems = useFluxPath(sqTrendStore, () => sqTrendStore.hideUnselectedItems);
  const isCapsuleTimeLimited = useFluxPath(sqTrendStore, () => sqTrendStore.isCapsuleTimeLimited);
  const capsuleGroupMode = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.capsuleGroupMode);
  const noSignalsInAnyGroup = useFluxPath(sqWorksheetStore, () => _.isEmpty(
    _.chain(sqWorksheetStore.conditionToSeriesGrouping)
      .values()
      .flatten()
      .value()
  ));
  const capsuleSeries = useFluxPath(sqTrendSeriesStore, () => sqTrendSeriesStore.capsuleSeries);
  const nonCapsuleSeriesAndPreview = useFluxPath(sqTrendSeriesStore,
    () => sqTrendSeriesStore.nonCapsuleSeriesAndPreview);
  const capsuleSetItems = useFluxPath(sqTrendCapsuleSetStore, () => sqTrendCapsuleSetStore.items);

  useEffect(() => {
      displayText();
    },
    [view, hideUnselectedItems, isCapsuleTimeLimited, capsuleGroupMode, capsuleSeries, nonCapsuleSeriesAndPreview, capsuleSetItems]);

  const renderAddCapsule = (
    <>
      <p>{t('TREND_HELP.CAPSULE_VIEW_ENABLES')}</p>
      <p><strong>{t('TREND_HELP.ADD_CAPSULE')}</strong></p>
    </>
  );

  const renderAddCapsuleSeries = (
    <p>{t('TREND_HELP.CAPSULE_VIEW_ENABLES')}</p>
  );

  const renderAddCondition = (
    <>
      <p>{t('TREND_HELP.CAPSULE_VIEW_OVERLAYS')}</p>
      <p><strong>{t('TREND_HELP.CAPSULE_ADD_CONDITION')}</strong></p>
    </>
  );

  const renderAddData = (
    <p>
      <TranslationWithHTML translationKey="TREND_HELP.ADD_DATA"
        translationParams={{ DATA_ICON: dataIcon, ASSET_TREE_ICON: assetTreeIcon }} />
    </p>
  );

  const renderAddSignal = (
    <>
      <p>{t('TREND_HELP.CAPSULE_VIEW_ENABLES')}</p>
      <p><strong>
        <TranslationWithHTML translationKey="TREND_HELP.ADD_SIGNAL"
          translationParams={{ CHART_ICON: chartIcon }} />
      </strong></p>
    </>
  );

  const renderCapsuleGroupMode = (
    <>
      <p>{t('TREND_HELP.CAPSULE_GROUP_MODE')}</p>
      <p><strong>
        <TranslationWithHTML translationKey="TREND_HELP.CLICK_GROUP"
          translationParams={{ GROUP_ICON: groupIcon, CHART_ICON: chartIcon }} />
      </strong></p>
    </>
  );

  const renderCapsuleTimePerformance = (
    <>
      <p>
        <TranslationWithHTML translationKey="TREND_HELP.CAPSULE_TIME_LIMITED"
          translationParams={{ COUNT: MAX_CAPSULE_TIME_ITEMS }} />
      </p>
      <p>{t('TREND_HELP.TAKE_ACTION')}</p>
      <ul>
        <li>
          <TranslationWithHTML translationKey="TREND_HELP.REDUCE_SIGNALS_CONDITIONS"
            translationParams={{ CHART_ICON: chartIcon }} />
        </li>
        <li>
          {t('TREND_HELP.REDUCE_CAPSULES')}
        </li>
        <li>
          <TranslationWithHTML translationKey="TREND_HELP.SHOW_ONLY_SELECTED"
            translationParams={{ DIMMING_ICON: dimmingIcon }} />
        </li>
      </ul>
    </>
  );

  const renderChainNoConditionSelected = (
    <>
      <p>{t('TREND_HELP.CHAIN_VIEW_SHOWS')}</p>
      <p><strong>
        <TranslationWithHTML translationKey="TREND_HELP.CHAIN_NO_CONDITION_SELECTED"
          translationParams={{ DIMMING_ICON: dimmingIcon }} />
      </strong></p>
    </>
  );

  const renderChainRequiresCapsules = (
    <>
      <p>{t('TREND_HELP.CHAIN_VIEW_SHOWS')}</p>
      <p><strong>{t('TREND_HELP.CHAIN_REQUIRES_CAPSULES')}</strong></p>
    </>
  );

  const renderChainRequiresCondition = (
    <>
      <p>{t('TREND_HELP.CHAIN_REQUIRES_CONDITION')}</p>
      <p><b>{t('TREND_HELP.CHAIN_ADD_CONDITION')}</b></p>
    </>
  );

  const displayText = () => {
    if (view === TREND_VIEWS.CAPSULE) {
      // The order of evaluation is important
      if (!capsuleSetItems.length) {
        return renderAddCondition;
      } else if (!nonCapsuleSeriesAndPreview.length) {
        return renderAddSignal;
      } else if (isCapsuleTimeLimited) {
        return renderCapsuleTimePerformance;
      } else if (capsuleGroupMode && noSignalsInAnyGroup) {
        return renderCapsuleGroupMode;
      } else if (!capsuleSeries.length ||
        !_.some(capsuleSeries, capsule => capsule.duration > 0)) {
        return renderAddCapsule;
      } else {
        return renderAddCapsuleSeries;
      }
    } else if (view === TREND_VIEWS.CHAIN) {
      if (hideUnselectedItems) {
        return renderChainNoConditionSelected;
      } else if (!capsuleSetItems.length) {
        return renderChainRequiresCondition;
      } else {
        return renderChainRequiresCapsules;
      }
    } else {
      return renderAddData;
    }
  };

  return (<span> {displayText()} </span>);
};

export default TrendHelp;
export const sqTrendHelp = angularComponent(trendHelpBindings, TrendHelp);
