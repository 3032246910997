import _ from 'lodash';
import moment from 'moment-timezone';
import tinygradient from 'tinygradient';
import tinycolor from 'tinycolor2';
import { UtilitiesService } from '../services/utilities.service';
import { TrendCapsuleSetStore } from '../trendData/trendCapsuleSet.store';
import { EMPTY_XYREGION, XYRegion } from '@/services/chartHelper.service';
import {
  SCATTER_PLOT_COLORS,
  SCATTER_PLOT_MODES,
  SCATTER_PLOT_OPACITY,
  SCATTER_PLOT_VIEWS
} from '@/scatterPlot/scatterPlot.module';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';
import { PredictionHelperService } from '@/services/predictionHelper.service';
import { API_TYPES } from '@/main/app.constants';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { DurationStore } from '@/trendData/duration.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { ITEM_DATA_STATUS, ITEM_TYPES, TREND_COLORS } from '@/trendData/trendData.module';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { MIN_SAMPLES_FOR_BOOST } from '@/scatterPlot/scatterPlot.actions';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { SCATTER_PLOT_REDUCED_OPACITY } from './scatterPlot.module';
import { WorksheetStore } from '@/worksheet/worksheet.store';

export type ScatterPlotStore = ReturnType<typeof sqScatterPlotStore>['exports'];

export interface ScatterPlotFormula {
  parameters: { [key: string]: string };
  xyTableFormula: string;
  sampleLimitFormula?: string;
  cancellationGroup: string;
}

export interface FxLineMetadata {
  formula: string;
  rSquared?: number;
}

export interface ScatterPlotColorRange {
  id: string;
  color: string;
  range: { startTime: number, endTime: number };
}

interface BaseCapsulePropertyColorsConfig {
  isStringProperty: boolean;
  propertyIndex: number;
  transformValue: (rawValue: number | string) => number | string;
}

export interface StringCapsulePropertyColorsConfig extends BaseCapsulePropertyColorsConfig {
  valueColorMap: { [propertyValue: string]: string };
}

export interface NumericCapsulePropertyColorsConfig extends BaseCapsulePropertyColorsConfig {
  colors: any;
  minColor: string;
  maxColor: string;
  minValue: number;
  maxValue: number;
  diffValue: number;
}

export type CapsulePropertyColorsConfig = StringCapsulePropertyColorsConfig | NumericCapsulePropertyColorsConfig;

export const MAX_MARKER_SIZE_CALCULATED = 3;
export const MIN_MARKER_SIZE_CALCULATED = 1;
export const MAX_MARKER_SIZE_CUSTOM = 5;
export const MIN_MARKER_SIZE_CUSTOM = 0.5;

export function sqScatterPlotStore(
  sqDurationStore: DurationStore,
  sqTrendSeriesStore: TrendSeriesStore,
  sqTrendCapsuleSetStore: TrendCapsuleSetStore,
  sqTrendCapsuleStore: TrendCapsuleStore,
  sqTrendDataHelper: TrendDataHelperService,
  sqUtilities: UtilitiesService,
  sqPredictionHelper: PredictionHelperService,
  sqDateTime: DateTimeService,
  sqWorksheetStore: WorksheetStore,
  sqSystemConfiguration: SystemConfigurationService
) {
  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.WORKSHEET,
    /**
     * rawData and scatterData are store-level variables and not kept in this.state because,
     * when they contain lots of data, serializing and deserializing these is computationally
     * intensive and slow. This does mean that these variables aren't frozen/immutable.
     */
    rawData: null,
    scatterData: [],

    /**
     * Initializes the store by setting default values.
     */
    initialize() {
      this.state = this.immutable({
        xSeries: null,
        ySeries: null,
        // Tracks (and emits events) when scatterData changes, since scatterData is not in the baobab store
        scatterDataChangeCount: 0,
        selector: {
          low: 0.50,
          high: 0.75
        },
        selectedRegion: EMPTY_XYREGION,
        viewRegion: EMPTY_XYREGION,
        minimapXSeries: null,
        minimapYSeries: null,
        gradientConfig: undefined,
        fxLines: [],
        colorConditionIds: [],
        colorCapsuleProperty: undefined,
        capsulePropertyColorsConfig: undefined,
        colorSignalId: undefined,
        colorRanges: [],
        plotMode: SCATTER_PLOT_MODES.DISPLAY_RANGE,
        plotView: SCATTER_PLOT_VIEWS.SCATTER_PLOT,
        connect: false,
        showTooltips: true,
        markerSize: undefined,
        isMarkerSizeCustom: false,
        minimapSeries: this.monkey(['minimapXSeries'], ['minimapYSeries'], (xSeries, ySeries) =>
          _.compact([xSeries, ySeries])),
        densityPlotData: [],
        // Most of our users have screen resolutions in the 1700-2500 px (x) by 1000-1300 px (y) range.
        // After subtracting off the worksheet, tool, details, and investigate panels, we estimate that a
        // typical chart size would be ~1600 px by ~800 px. To get approximately square default bins, then,
        // we set the default # x bins to 40 and # y bins to 20.
        numXBins: 40,
        numYBins: 20,
        xBinSize: 0,
        yBinSize: 0,
        showColorModal: false
      });
      this.rawData = null;
      this.scatterData = [];
    },

    exports: {
      get state() {
        return this.state.get();
      },

      get xSeries() {
        return this.state.get('xSeries');
      },

      get ySeries() {
        return this.state.get('ySeries');
      },

      get selectedRegion(): XYRegion {
        return this.state.get('selectedRegion');
      },

      get viewRegion(): XYRegion {
        return this.state.get('viewRegion');
      },

      isRegionSelected() {
        return this.isRegionSelected();
      },

      isViewRegionSet() {
        return this.isViewRegionSet();
      },

      isScatterDataEmpty() {
        return this.isScatterDataEmpty();
      },

      calculateMarkerSize() {
        return this.calculateMarkerSize();
      },

      get scatterData() {
        // scatterData is not frozen in the store, so we have to be careful not to change it ourselves.
        // If you need to change scatterData, be sure to reassign it instead of mutating the existing array.
        return this.scatterData;
      },

      isDensityPlotDataEmpty() {
        return this.isDensityPlotDataEmpty();
      },

      get densityPlotData() {
        return this.state.get('densityPlotData');
      },

      get rawData() {
        // rawData is not frozen in the store, so we have to be careful not to change it ourselves.
        // If you need to change rawData, be sure to reassign it instead of mutating th existing object.
        return this.rawData;
      },

      get fxLines() {
        return this.state.get('fxLines');
      },

      get colorConditionIds() {
        return this.state.get('colorConditionIds');
      },

      get colorCapsuleProperty() {
        return this.state.get('colorCapsuleProperty');
      },

      get colorSignalId() {
        return this.state.get('colorSignalId');
      },

      get colorRanges() {
        return _.sortBy(this.state.get('colorRanges'), 'range.startTime');
      },

      get gradientConfig() {
        return this.state.get('gradientConfig');
      },

      get capsulePropertyColorsConfig() {
        return this.state.get('capsulePropertyColorsConfig');
      },

      get selector() {
        return this.state.get('selector');
      },

      get minimapXSeries() {
        return this.state.get('minimapXSeries');
      },

      get minimapYSeries() {
        return this.state.get('minimapYSeries');
      },

      get minimapSeries() {
        return this.state.get('minimapSeries');
      },

      get plotMode() {
        return this.state.get('plotMode');
      },

      get plotView() {
        return this.state.get('plotView');
      },

      get connect() {
        return this.state.get('connect');
      },

      get showTooltips() {
        return this.state.get('showTooltips');
      },

      get markerSize() {
        return this.state.get('markerSize');
      },

      get scatterDataChangeCount() {
        return this.state.get('scatterDataChangeCount');
      },

      get showColorModal() {
        return this.state.get('showColorModal');
      },

      getColorPropertyValueFromCapsule(capsule, timezone?) {
        return this.getColorPropertyValueFromCapsule(capsule, timezone);
      },

      getCapsulePropertyColor(propertyValue, colorsConfig) {
        return this.getCapsulePropertyColor(propertyValue, colorsConfig, false);
      },

      /**
       * Returns the formula for the xyTable operator for the scatter plot visualization with support for capsule
       * mode and being zoomed into a view region. Formulas is constructed to ensure that the data is only passed
       * over once for each signal. Also returns the formula needed to compute the maxSamples if in capsule mode or if
       * the user is zoomed in on a view range. Besides xy data, the table might contain additional columns used
       * later for coloring (color conditions and color signal).
       *
       * @param {string} xId - The ID of the X signal
       * @param {string} yId - The ID of the Y signal
       * @param {string} queryRangeCapsule - The capsule formula for the query range
       * @param {string[]} colorConditionIds - An array containing the id of conditions for coloring. For each
       * condition, a new column will be added in the result table. Cell values will be 0 or 1 (see addCondition
       * operator for details)
       * @param {string} colorSignalId - The id of the signal for coloring. A new column containing the values of this
       * signal will be added in the table (see addSignal operator for details).
       * @return {ScatterPlotFormula} The map of parameters for the xyTableFormula and, if applicable, the
       *   sampleLimitFormula. Note that for the xyTableFormula the sampleLimit parameter is denoted by the
       *   {sampleLimit} token which must be replaced with the actual number.
       */
      getXyFormulaAndParameters(xId: string, yId: string, queryRangeCapsule: string,
        colorConditionIds?: any[], colorSignalId?: any, colorCapsuleProperty?: string | undefined): ScatterPlotFormula {

        const parameters = { xSignal: xId, ySignal: yId };
        const xSignalFormula = '$xSignal';
        const ySignalFormula = '$ySignal';
        let conditionsFormula = '';
        let sampleLimitFormula = undefined;
        const xyTableArgs = [queryRangeCapsule, '{xSignalFormula}', '{ySignalFormula}', '{sampleLimit}', 'true', 'false'];

        let itemIndex = 0;
        if (this.state.get('plotMode') === SCATTER_PLOT_MODES.CAPSULES) {
          const joinedConditions = _.map(sqTrendDataHelper.getAllItems({
            workingSelection: true,
            excludeDataStatus: [ITEM_DATA_STATUS.REDACTED, ITEM_DATA_STATUS.FAILURE],
            itemTypes: [ITEM_TYPES.CAPSULE_SET]
          }), (item: any) => {
            const identifier = sqUtilities.getShortIdentifier(itemIndex++);
            parameters[identifier] = item.id;
            return '$' + identifier;
          }).join(', ');
          conditionsFormula = sampleLimitFormula = 'combineWith(' + joinedConditions + ')';
        }

        if (conditionsFormula) {
          xyTableArgs.push(conditionsFormula);
        }

        if (this.isViewRegionSet()) {
          const region = this.state.get('viewRegion');
          const xSearch = `$xSignal.valueSearch(isBetween(${region.xMin}, ${region.xMax}))`;
          const ySearch = `$ySignal.valueSearch(isBetween(${region.yMin}, ${region.yMax}))`;
          sampleLimitFormula = `intersect(${xSearch}, ${ySearch})`;
          if (conditionsFormula) {
            sampleLimitFormula += `.intersect(${conditionsFormula})`;
          }

          xyTableArgs.push(region.xMin);
          xyTableArgs.push(region.xMax);
          xyTableArgs.push(region.yMin);
          xyTableArgs.push(region.yMax);
        }

        if (sampleLimitFormula) {
          sampleLimitFormula += `.percentDuration(${queryRangeCapsule})`;
        }

        let xyTableFormula = `xyTable(${xyTableArgs
          .join(', ')
          .replace('{xSignalFormula}', xSignalFormula)
          .replace('{ySignalFormula}', ySignalFormula)})`;

        if (colorConditionIds) {
          for (const conditionId of colorConditionIds) {
            const identifier = sqUtilities.getShortIdentifier(itemIndex++);
            parameters[identifier] = conditionId;
            xyTableFormula = xyTableFormula + '.addCondition(\'Condition(' + conditionId + ')\',$' + identifier + ')';
          }
        }

        if (colorCapsuleProperty) {
          const colorConditionShortIdentifiers = [];
          const conditions = _.map(sqTrendDataHelper.getAllItems({
            workingSelection: true,
            excludeDataStatus: [ITEM_DATA_STATUS.REDACTED, ITEM_DATA_STATUS.FAILURE],
            itemTypes: [ITEM_TYPES.CAPSULE_SET]
          }), 'id');
          _.forEach(conditions, (conditionId) => {
            let identifier;
            if (_.includes(_.values(parameters), conditionId)) {
              identifier = _.findKey(parameters, param => param === conditionId);
            } else {
              identifier = sqUtilities.getShortIdentifier(itemIndex++);
              parameters[identifier] = conditionId;
            }
            colorConditionShortIdentifiers.push(`$${identifier}`);
          });
          const colorCapsulePropertyFormula = `.addCondition('Property(${colorCapsuleProperty})', ` +
            `combineWith(${colorConditionShortIdentifiers.join(',')}), '${colorCapsuleProperty}')`;
          xyTableFormula = `${xyTableFormula}${colorCapsulePropertyFormula}`;
        }

        if (colorSignalId) {
          const identifier = sqUtilities.getShortIdentifier(itemIndex++);
          parameters[identifier] = colorSignalId;
          xyTableFormula = xyTableFormula + '.addSignal(\'Signal(' + colorSignalId + ')\',$' + identifier + ')';
        }

        const cancellationGroup = `scatterPlotData`;

        return { parameters, xyTableFormula, sampleLimitFormula, cancellationGroup };
      },

      /**
       * Determines if the signal can be plotted in scatter plot. It must either be a formula with a single input
       * signal (e.g. f(x) formula) or a regression model made using the frontend Prediction tool with only one input
       * signal. Note that if the signal is a formula, then it may contain scalars.
       *
       * @param {Object[]} parameters - The array of parameters of the calculated signal
       * @param {string} formula - The formula of the calculated signal
       * @return {boolean} True if it can be plotted as a function of x line, false otherwise
       */
      isValidFxSignal(parameters, formula) {
        const nonScalarParameters =
          _.reject(parameters, parameter => parameter.item.type === API_TYPES.CALCULATED_SCALAR);
        return (_.size(nonScalarParameters) === 1
          && _.includes([API_TYPES.STORED_SIGNAL, API_TYPES.CALCULATED_SIGNAL],
            _.first(nonScalarParameters).item.type))
          || (_.chain(parameters)
            .reject(parameter => _.includes([API_TYPES.STORED_CONDITION, API_TYPES.CALCULATED_CONDITION],
              parameter.item.type))
            .size()
            .value() === 2 && sqPredictionHelper.getRegressionModelFormula(formula));
      },
      /**
       * Gets the formula to use for plotting a function of x line from a signal with a single input signal variable in
       * its formula. Replaces the variable with timeSince() which will provide evenly spaced X-axis values as the
       * keys and the values will be the Y-axis values.
       *
       * @param {string} functionFormula - The formula of the signal
       * @param {Object} parameter - The parameter in the formula that will be replaced
       * @param {number} step - The increment step along the X-axis.
       * @return {string} The formula to use to get X and Y values for plotting on scatter plot
       */
      getFxLineFormulaFromFunction(functionFormula, parameter, step) {
        let timeSince = `timeSince(0, ${step})`;
        if (parameter.item.valueUnitOfMeasure) {
          timeSince += `.setUnits('${parameter.item.valueUnitOfMeasure}')`;
        }

        return functionFormula.replace(new RegExp(`\\$\\b${_.escapeRegExp(parameter.name)}+\\b`, 'g'),
          timeSince);
      },
      /**
       * Gets the formula to use to show a function of x line based off of a Prediction signal. Figures out the
       * correct mx+b formula based on coefficient type (e.g. polynomial, logarithmic, etc.) and intercept.
       *
       * @param {Object} model - The regression model computed by the backend
       * @param {string} formula - The formula of the Prediction signal
       * @param {number} step - The increment step along the X-axis.
       * @return {string} The formula to use to get X and Y values for plotting on scatter plot
       */
      getFxLineFormulaFromModel(model, formula, step) {
        const isLogPrediction = _.includes(formula, 'ln($a)');
        return _.chain(model.table.data)
          .map(([coefficient], index: number) => {
            if (index === 0) {
              return `${coefficient} * timeSince(0, ${step})`;
            } else if (isLogPrediction) {
              return `${coefficient} * ln(timeSince(0, ${step}))`;
            } else {
              return `${coefficient} * timeSince(0, ${step})^${index + 1}`;
            }
          })
          .push(model.regressionOutput.intercept)
          .join(' + ')
          .value();
      },

      /**
       * Determines if a prediction signal's training window is outside the display range.
       *
       * @param {string} formula - The Prediction signal formula
       * @param {Object} displayRange - The current display range
       * @return {boolean} True if the training window is outside the display range
       */
      isRegressionFormulaOutsideRange(formula, displayRange) {
        const match = formula.match(/capsule\("(.*?)", "(.*?)"\)/);
        if (match) {
          const trainingWindowStart = moment.utc(match[1]);
          const trainingWindowEnd = moment.utc(match[2]);
          if (trainingWindowStart > displayRange.end || trainingWindowEnd < displayRange.start) {
            return true;
          }
        }

        return false;
      },

      /**
       * Used by the Scatter Plot Selection tool.
       * Returns true if the two signals in the condition are displayed on the scatter plot.
       *
       * @param parameters {Object} - the parameters of the condition formula
       */
      isRelevantCondition(parameters) {
        const xSignalParam = parameters.find(x => x.name === 'xSignal');
        const ySignalParam = parameters.find(x => x.name === 'ySignal');
        return (this.state.get('xSeries')?.id === xSignalParam.item.id
          && this.state.get('ySeries')?.id === ySignalParam.item.id)
          || (this.state.get('xSeries')?.id === ySignalParam.item.id
            && this.state.get('ySeries')?.id === xSignalParam.item.id);
      },

      getDensityPlotFormula(xId: string, yId: string, queryRangeCapsule: string) {
        const parameters = {
          xSignal: xId,
          ySignal: yId
        };
        const xSignalFormula = '$xSignal';
        const ySignalFormula = '$ySignal';
        const numXBins = this.state.get('numXBins');
        const numYBins = this.state.get('numYBins');

        let formula = `heatmapTable(${queryRangeCapsule}, ${xSignalFormula}, ${ySignalFormula}, `
          + `${numXBins}, ${numYBins}`;

        if (this.isViewRegionSet()) {
          const view: XYRegion = this.state.get('viewRegion');
          formula = `${formula}, ${view.xMin}, ${view.xMax}, ${view.yMin}, ${view.yMax})`;
        } else {
          formula = `${formula})`;
        }

        const cancellationGroup = 'densityPlotData';

        return { formula, parameters, cancellationGroup };
      },

      /**
       * Determines the range of values on X axis based on view region and heat map data
       *
       * @return {Object} The value range on X axis
       */
      getXAxisRange() {
        return this.getXAxisRange();
      },

      getYAxisRange() {
        return this.getYAxisRange();
      },

      getColorAxisRange() {
        return this.getColorAxisRange();
      },

      get numXBins() {
        return this.state.get('numXBins');
      },

      get numYBins() {
        return this.state.get('numYBins');
      },

      get xBinSize() {
        return this.state.get('xBinSize');
      },

      get yBinSize() {
        return this.state.get('yBinSize');
      }
    },

    /**
     * Dehydrates the item by retrieving the current set parameters in view
     *
     * @returns {Object} An object with the state properties as JSON
     */
    dehydrate() {
      const state = _.omit(this.state.serialize(), 'minimapXSeries', 'minimapYSeries',
        'gradientConfig', 'scatterDataChangeCount', 'showColorModal') as any;
      state.fxLines = _.map(state.fxLines, line => _.pick(line, ['id', 'color']));
      return state;
    },

    /**
     * Rehydrates item from dehydrated state
     * Resets view to scatter plot if the heat map has been disabled
     *
     * @param {Object} dehydratedState State object that should be restored
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      SCATTER_PLOT_SET_X_SERIES: 'setXSeries',
      SCATTER_PLOT_SET_Y_SERIES: 'setYSeries',
      SCATTER_PLOT_FLIP_AXES: 'flipAxes',
      SCATTER_PLOT_SET_DATA: 'setScatterData',
      SCATTER_PLOT_CLEAR_DATA: 'clearScatterData',
      SCATTER_PLOT_ADD_FX_LINE: 'addFxLine',
      SCATTER_PLOT_REMOVE_FX_LINE: 'removeFxLine',
      SCATTER_PLOT_ADD_COLOR_CONDITION: 'addColorCondition',
      SCATTER_PLOT_REMOVE_COLOR_CONDITION: 'removeColorCondition',
      SCATTER_PLOT_SET_COLOR_CAPSULE_PROPERTY: 'setColorCapsuleProperty',
      SCATTER_PLOT_SET_COLOR_FOR_CAPSULE_PROPERTY: 'setColorForCapsuleProperty',
      SCATTER_PLOT_REMOVE_COLOR_CAPSULE_PROPERTY: 'removeColorCapsuleProperty',
      SCATTER_PLOT_SET_COLOR_SIGNAL: 'setColorSignal',
      SCATTER_PLOT_REMOVE_COLOR_SIGNAL: 'removeColorSignal',
      SCATTER_PLOT_ADD_COLOR_RANGE: 'addColorRange',
      SCATTER_PLOT_UPDATE_COLOR_RANGE: 'updateColorRange',
      SCATTER_PLOT_REMOVE_COLOR_RANGE: 'removeColorRange',
      TREND_REMOVE_ITEMS: 'removeItem',
      SCATTER_PLOT_REFRESH_VIEW: 'refreshView',
      TREND_SET_COLOR: 'handleItemColorChange',
      TREND_SWAP_ITEMS: 'swapItems',
      SCATTER_PLOT_SET_FX_DATA: 'setFxLineData',
      SCATTER_PLOT_SET_MINIMAP_X_SERIES: 'setMinimapXSeries',
      SCATTER_PLOT_SET_MINIMAP_Y_SERIES: 'setMinimapYSeries',
      SCATTER_PLOT_CLEAR_MINIMAP_X_SERIES: 'clearMinimapXSeries',
      SCATTER_PLOT_CLEAR_MINIMAP_Y_SERIES: 'clearMinimapYSeries',
      SCATTER_PLOT_SELECTORS: 'setSelectors',
      SCATTER_PLOT_SET_PLOT_MODE: 'setPlotMode',
      SCATTER_PLOT_SET_PLOT_VIEW: 'setPlotView',
      SCATTER_PLOT_SET_CONNECT: 'setConnect',
      SCATTER_PLOT_SET_SELECTED_REGION: 'setSelectedRegion',
      SCATTER_PLOT_SET_VIEW_REGION: 'setViewRegion',
      SCATTER_PLOT_SET_SHOW_TOOLTIPS: 'setShowTooltips',
      SCATTER_PLOT_SET_MARKER_SIZE: 'setMarkerSize',
      SCATTER_PLOT_SET_SHOW_COLOR_MODAL: 'setShowColorModal',
      DENSITY_PLOT_SET_NUM_X_BINS: 'setNumXBins',
      DENSITY_PLOT_SET_NUM_Y_BINS: 'setNumYBins',
      DENSITY_PLOT_SET_DATA: 'setDensityPlotData',
      DENSITY_PLOT_CLEAR_DATA: 'clearDensityPlotData'
    },

    /**
     * Sets the scatter plot mode
     *
     * @param {Object} payload - An object container for the parameters
     * @param {String} payload.plotMode - The desired plot mode (either SCATTER_PLOT_MODES.DISPLAY_RANGE
     * or SCATTER_PLOT_MODES.CAPSULES)
     */
    setPlotMode(payload) {
      this.state.set('plotMode', payload.plotMode);
    },

    setPlotView(payload) {
      this.state.set('plotView', payload.plotView);
    },

    /**
     * Sets whether the samples should be connected by a line on the display
     *
     * @param {Object} payload - An object container for the parameters
     * @param {String} payload.connect- True to connect samples with a line, false to not connect them.
     */
    setConnect(payload) {
      this.state.set('connect', payload.connect);
    },

    /**
     * Sets a region of the chart as selected.
     *
     * @param {XYRegion} payload - region to select
     */
    setSelectedRegion(payload: XYRegion) {
      this.state.set('selectedRegion', payload);
    },

    /**
     * Sets the view region of the chart.
     *
     * @param {XYRegion} payload - region to view
     */
    setViewRegion(payload: XYRegion) {
      this.state.set('viewRegion', payload);
    },

    /**
     * Sets whether or not to show tooltips/labels on scatter plot points when a user hovers over them
     *
     * @param {Object} payload - An object container for the parameters
     * @param {boolean} payload.showTooltips - whether or not ot show tooltips
     */
    setShowTooltips(payload) {
      this.state.set('showTooltips', payload.showTooltips);
    },

    /**
     * Sets the value of the left and right sides of the scatter plot region selectors
     *
     * @param {Object} payload - An object container for the parameters
     * @param {number} payload.low - The value
     * @param {number} payload.high - The value
     */
    setSelectors(payload) {
      this.state.set('selector', { low: payload.low, high: payload.high });
      this.refreshView();
    },

    /**
     * Update the Scatterplot based on a table of values.
     * Table format:
     * time   x    y   Condition(conditionID)    Condition(conditionID)    Signal(signalId)}.
     *    2   1.5  2.5        0                             1                     0.3
     *    2   1.7  2.1        1                             1                     0.5
     * The conditions are used (if selected by user) to determine the color of a point.
     * The values of the signal are used (if selected by user) to determine the gradient color of a point.
     *
     * @param {Object} payload - An object container for the parameters
     * @param {Object[]} payload.headers - contains table header information
     * @param {Object[]} payload.data - contains an array with data
     */
    setScatterData(payload) {
      const tableHeader = payload.headers;
      const tableData = payload.data;
      this.rawData = payload;

      const selectedCapsules = _.filter(sqTrendCapsuleStore.items,
        capsule => capsule.selected && _.isFinite(capsule.startTime) && _.isFinite(capsule.endTime));

      const conditionColorsConfig = this.computeConditionColorsConfig(tableHeader);
      const capsulePropertyColorsConfig = this.computeCapsulePropertyColorsConfig(tableHeader, tableData);
      const capsuleProperty = this.state.get('colorCapsuleProperty');
      const gradientConfig = this.computeGradientConfig(tableHeader, tableData);
      const colorRanges = this.state.get('colorRanges');
      const timeRangeConfig = this.computeTimeRangeConfig();
      const reduceNonSelectedOpacity = !_.isEmpty(selectedCapsules);

      const data = _.chain(tableData)
        .reject(row => _.isNil(row[1]) || _.isNil(row[2])) // Invalid values are not used by the Scatterplot
        .map((row) => {
          // Color is taken by first criteria that match:
          const color = _.reduce([
            row => this.getColorUsingSelectedCapsules(row[0], selectedCapsules, capsuleProperty,
              capsulePropertyColorsConfig),
            row => this.getColorUsingCapsuleProperty(row, capsulePropertyColorsConfig, reduceNonSelectedOpacity),
            row => this.getColorUsingCondition(row, conditionColorsConfig, reduceNonSelectedOpacity),
            row => this.getColorUsingColorRanges(row[0], colorRanges, reduceNonSelectedOpacity),
            row => this.getColorUsingSignalGradient(row, gradientConfig, reduceNonSelectedOpacity),
            row => this.getColorUsingDisplayRange(row[0], timeRangeConfig, reduceNonSelectedOpacity)
          ], (color, getColor) => {
            if (color) {
              return color;
            }
            return getColor(row);
          }, undefined);

          return {
            color: (_.startsWith(color, '#') ? tinycolor(color).setAlpha(SCATTER_PLOT_OPACITY).toString() :
              color) as Highcharts.ColorString,
            time: row[0],
            x: row[1],
            y: row[2]
          };
        })
        .value();

      this.scatterData = data;
      this.incrementScatterDataChangeCount();
      this.state.set('gradientConfig', gradientConfig);
      this.state.set('capsulePropertyColorsConfig', capsulePropertyColorsConfig);

      if (!this.state.get('isMarkerSizeCustom')) {
        this.state.set('markerSize', this.calculateMarkerSize());
      }
    },

    /**
     * Update the Heat Map based on a table of values. Each row contains data for one cell on the plot. The third column
     * contains either duration values or the average values of a third signal while the x and y signals were in a
     * particular cell.
     * Table format:
     * x    y    value
     * 1.5  2.5  0.3
     * 1.7  2.1  0.5
     * The values in the third column are used to determine the color of a point.
     *
     * @param {Object} payload - An object container for the parameters
     * @param {Object[]} payload.data - contains an array with data
     */
    setDensityPlotData(payload) {
      this.rawData = payload;

      this.state.set('densityPlotData', payload.data);

      const xRange = this.getXAxisRange();
      const yRange = this.getYAxisRange();

      const xBinSize = (xRange.end - xRange.start) / this.state.get('numXBins');
      const yBinSize = (yRange.end - yRange.start) / this.state.get('numYBins');
      this.state.set('xBinSize', xBinSize);
      this.state.set('yBinSize', yBinSize);
    },

    /**
     * Compute the configuration for time range coloring. This is used for coloring the points based on sample
     * timestamp.
     *
     * @return {Object} the object with values for attributes split1, split2, selectorHidden.
     */
    computeTimeRangeConfig() {
      const selector = this.state.get('selector');
      const regionSliderRange = sqDurationStore.displayRange;
      const start = regionSliderRange.start;
      const duration = regionSliderRange.end - start;
      const split1 = selector.low * duration + start;
      const split2 = selector.high * duration + start;
      const selectorHidden = (selector.low < 0 && selector.high < 0) || !_.chain(this.state.get('colorRanges'))
        .filter(({ range }) => sqDateTime.overlaps(range,
          { startTime: regionSliderRange.start, endTime: regionSliderRange.end }))
        .isEmpty()
        .value();

      return { split1, split2, selectorHidden };
    },

    /**
     * Compute the configuration for gradient coloring.
     * The base color for gradient is taken from the signal column specified in the header.
     *  - darkest color is the base color and it corresponds to the maximum value of the signal in the table data.
     *  - lightest color corresponds to the minimum value of the signal in the table data. To compute this color we
     * detect the maximum lightness factor that can be applied to the base color and the result is not #ffffff white.
     * Then we apply lightness with 90% of this factor so that the color is easily visible on screen.
     *  - there are 100 intermediate nuances between lightest and darkest color. This will allow fast computation of
     *  color for many values without the need to apply 'tinygradient' algorithm for each point.
     *
     * @param tableHeader - The table header of raw data received from backend. The function looks for a column with
     * format 'Signal(signalId)'
     * @param tableData - The data array from raw data received from backend. This is used to determine the min and
     * max value for the signal and set min and max color for the gradient.
     * @return {Object|undefined} the configuration for gradient coloring
     */
    computeGradientConfig(tableHeader, tableData) {
      const { startIndex, itemIds } = this.findItemsInTableHeader(tableHeader, /Signal\((.*?)\)/);

      // single column expected
      if (itemIds.length === 0) {
        return;
      }

      if (itemIds.length > 1) {
        throw new Error(`Expected one signal column in table header and got ${itemIds.length}`);
      }

      const { minValue, maxValue } = this.findTableMinMaxValues(tableData, startIndex);
      const maxColor = sqTrendSeriesStore.findItem(itemIds[0]).color;

      const gradient = this.computeGradientForColorConfig(minValue, maxValue, maxColor);

      return {
        ...gradient,
        columnIndex: startIndex,
        itemId: itemIds[0]
      };
    },

    /**
     * Compute the configuration for coloring based on the values of the conditions
     *
     * @param tableHeader - The table header of raw data received from backend. The function looks for columns with
     * format 'Condition(conditionId)'
     * @return {Object|undefined} the configuration for coloring
     */
    computeConditionColorsConfig(tableHeader) {
      const { startIndex, endIndex, itemIds } = this.findItemsInTableHeader(tableHeader, /Condition\((.*?)\)/);

      if (itemIds.length > 0) {
        const colors = _.chain(itemIds).map(id => sqTrendCapsuleSetStore.findItem(id).color).value();
        return { colors, startIndex, endIndex, itemIds };
      }
    },

    /**
     * Compute the configuration for coloring based on the values of the capsule properties
     *
     * @param tableHeader - The table header of raw data received from backend. The function looks for a column with
     * format 'Property(propertyName)'
     * @param tableData - The table data. The function determines the colors for different table values, and ignores
     * null property values.
     * @return the configuration for coloring
     */
    computeCapsulePropertyColorsConfig(tableHeader, tableData): CapsulePropertyColorsConfig | undefined {
      const currentColorConfig = this.state.get('capsulePropertyColorsConfig');
      const { itemIds, startIndex } = this.findItemsInTableHeader(tableHeader, /Property\((.*?)\)/);
      if (itemIds.length === 0) {
        return;
      }
      const propertyName = itemIds[0];
      let transformValue = x => x;
      // Convert duration from seconds => milliseconds
      if (propertyName === SeeqNames.CapsuleProperties.Duration) {
        transformValue = value => value * 1000;
      }
      // Save start/end times in the map as a string of the millisecond value
      if (propertyName === SeeqNames.CapsuleProperties.Start || propertyName === SeeqNames.CapsuleProperties.End) {
        transformValue = value => sqDateTime.parseISODate(value, sqWorksheetStore.timezone?.name).valueOf().toString();
      }

      const propertyValues = _.chain(tableData)
        .reject(row => _.isNull(row[startIndex]))
        .map(`[${startIndex}]`)
        .map(transformValue)
        .value();
      if (propertyValues.length === 0) {
        return;
      }

      const isStringProperty = tableHeader[startIndex].type === 'string' && propertyName !== SeeqNames.CapsuleProperties.Duration;
      if (isStringProperty) {
        const sortedUniqueValues = _.chain(propertyValues)
          .uniq()
          .sortBy(value => value.toLowerCase())
          .value();
        const valueColorMap = _.chain(currentColorConfig?.valueColorMap)
          .clone()
          // only keep values that are still in view
          .pickBy((value, key) => _.includes(sortedUniqueValues, key))
          .value()
          ?? {};
        let index = 0;
        // Try to keep colors the same even if the display range is changed
        _.forEach(sortedUniqueValues, (value) => {
          if (!_.includes(_.keys(valueColorMap), value)) {
            let newColor = TREND_COLORS[index % TREND_COLORS.length];
            index++;
            while (_.includes(_.values(valueColorMap), newColor) && index < TREND_COLORS.length) {
              newColor = TREND_COLORS[index % TREND_COLORS.length];
              index++;
            }
            valueColorMap[value] = newColor;
          }
        });
        return {
          isStringProperty,
          propertyIndex: startIndex,
          valueColorMap,
          transformValue
        };
      } else {
        // Compute gradient for numeric properties
        const minValue = _.min(propertyValues);
        const maxValue = _.max(propertyValues);
        // Keep the same color if possible
        const maxColor = currentColorConfig?.maxColor ??
          TREND_COLORS[Math.floor(Math.random() * TREND_COLORS.length)];

        const gradient = this.computeGradientForColorConfig(minValue, maxValue, maxColor);

        return {
          isStringProperty,
          propertyIndex: startIndex,
          transformValue,
          ...gradient
        };
      }
    },

    /**
     * Compute a gradient to use for coloring by capsule property or signal
     *
     * @param minValue - lowest value for gradient
     * @param maxValue - highest value for gradient
     * @param maxColor - base color for the gradient (darkest color used)
     */
    computeGradientForColorConfig(minValue, maxValue, maxColor) {
      let maxDiff = minValue != null && maxValue != null ? maxValue - minValue : null;
      let minColor;
      if (maxDiff === 0) {
        maxDiff = 1;
        minColor = maxColor;
      } else {
        minColor = sqUtilities.computeLightestColor(maxColor, 0.1);
      }

      const colors = tinygradient([
        { color: tinycolor(minColor).setAlpha(SCATTER_PLOT_OPACITY), pos: 0 },
        { color: tinycolor(maxColor).setAlpha(SCATTER_PLOT_OPACITY), pos: 1 }
      ]).rgb(100);

      return { minValue, maxValue, minColor, maxColor, diffValue: maxValue - minValue, colors };
    },

    /**
     * Find the minimum and the maximum value of a table column
     *
     * @param {any[any[]]} table - the table with data.
     * @param {number} columnIndex - the column index
     * @return {Object} the minimum and maximum values for specified column
     */
    findTableMinMaxValues(table, columnIndex: number) {
      const minMax = _.reduce(table, (accumulator, row) => {
        const value = row[columnIndex];
        accumulator[0] = (accumulator[0] === undefined || value < accumulator[0]) ? value : accumulator[0];
        accumulator[1] = (accumulator[1] === undefined || value > accumulator[1]) ? value : accumulator[1];
        return accumulator;
      }, []);

      return { minValue: minMax[0], maxValue: minMax[1] };
    },

    /**
     * Processes a table header and extract the location of item ids that match the regex
     *
     * @param {any[]} tableHeader - table header array like the one below
     * {name: "key", type: "number", units: ""}
     * {name: "x", type: "number", units: "°F"}
     * {name: "y", type: "number", units: "kW"}
     * {name: "Condition(9685D581-0990-4ACB-9EC1-3BF610950428)", type: "number", units: ""}
     * {name: "Condition(0C0BCF3F-1CB7-423B-B41D-27FBEF603585)", type: "number", units: ""}
     * {name: "Signal(4DBAE94D-AE77-421F-BE28-431EB021EAB3)", type: "number", units: "°F"}
     * @param regex - the regex to search for in column name
     * @return {Object} - information about the start and end column matching the regex and collected ids
     */
    findItemsInTableHeader(tableHeader: any[], regex) {
      return _.transform(tableHeader, (memo, cell, index) => {
        const match = regex.exec(cell.name);
        if (!_.isNull(match)) {
          // @ts-ignore
          memo.itemIds.push(match[1]);
          if (memo.startIndex === -1) {
            memo.startIndex = index;
          }
          memo.endIndex = index;
        }
      }, { startIndex: -1, endIndex: -1, itemIds: [] });
    },

    /**
     * Calculates the color for the specified {@code time} based on a selector with three time ranges
     *
     * @param {number} time - the time of the sample for which to calculate the color
     * @param {Object} timeRangeConfig - the time range color configuration
     * @param reduceOpacity - whether the opacity for this color should be reduced from the default
     * @return {string|undefined} the corresponding color for the specified timestamp
     */
    getColorUsingDisplayRange(time, timeRangeConfig, reduceOpacity: boolean = false) {
      if (!timeRangeConfig) {
        return;
      }

      let color;
      if (+time <= timeRangeConfig.split1 || timeRangeConfig.selectorHidden) {
        color = SCATTER_PLOT_COLORS.LOW;
      } else if (+time <= timeRangeConfig.split2) {
        color = SCATTER_PLOT_COLORS.MID;
      } else {
        color = SCATTER_PLOT_COLORS.HIGH;
      }

      if (reduceOpacity) {
        color = tinycolor(color).setAlpha(SCATTER_PLOT_REDUCED_OPACITY).toString();
      }

      return color;
    },

    /**
     * Calculates a color for a point by determining it falls within one of the configured color ranges.
     *
     * @param time - the time of the sample for which to calculate the color
     * @param colorRanges - the color ranges
     * @param reduceOpacity - whether the opacity for this color should be reduced from the default
     * @return {string|undefined} the corresponding color for the specified sample
     */
    getColorUsingColorRanges(time: number, colorRanges: ScatterPlotColorRange[], reduceOpacity: boolean = false) {
      let color = _.chain(colorRanges)
        .find(cr => time >= cr.range.startTime && time <= cr.range.endTime)
        .get('color')
        .value();
      if (color && reduceOpacity) {
        color = tinycolor(color).setAlpha(SCATTER_PLOT_REDUCED_OPACITY).toString();
      }
      return color;
    },

    /**
     * Compute the corresponding color for the specified value
     *
     * @param {Object} row - the data row from the table data received from backend
     * @param {Object} gradientConfig - The gradient color configuration
     * @param reduceOpacity - whether the opacity for this color should be reduced from the default
     * @return {string|undefined} the corresponding color for the specified sample
     */
    getColorUsingSignalGradient(row, gradientConfig, reduceOpacity: boolean = false) {
      if (!gradientConfig) {
        return;
      }
      const value = row[gradientConfig.columnIndex];
      if (value != null) {
        let colorIndex;
        if (gradientConfig.diffValue === 0) {
          colorIndex = 0;
        } else {
          colorIndex = Math.round((value - gradientConfig.minValue) / gradientConfig.diffValue * 99);
        }
        if (reduceOpacity) {
          colorIndex = Math.round(colorIndex / 3);
        }
        return gradientConfig.colors[colorIndex].toString();
      }
    },

    /**
     * Calculates the color of a point based on the values from condition columns. The color is taken from the first
     * condition with value 1.
     *
     * @param {Object} row - the data row from the table data received from backend
     * time,           x                 y          cond1, cond2
     * 1558631487601, 79.65147202067725, 0.0029228,     0,     1
     * @param {Object} colorsConfig - The color configuration with condition information
     * @param reduceOpacity - whether the opacity for this color should be reduced from the default
     * @return {string|undefined} the corresponding color for the specified row
     */
    getColorUsingCondition(row, colorsConfig, reduceOpacity: boolean = false) {
      if (!colorsConfig) {
        return;
      }

      let color;
      for (let i = colorsConfig.startIndex; i <= colorsConfig.endIndex; i++) {
        if (row[i] === 1) {
          color = colorsConfig.colors[i - colorsConfig.startIndex];
          break;
        }
      }

      if (color && reduceOpacity) {
        color = tinycolor(color).setAlpha(SCATTER_PLOT_REDUCED_OPACITY).toString();
      }

      return color;
    },

    /**
     * Calculates the color for a scatter plot point based on capsule property value
     *
     * @param row - row of data
     * @param colorsConfig - if coloring by capsule property, object containing either a map from string values to
     * colors or gradient info
     * @param reduceOpacity - whether the opacity for this color should be reduced from the default
     * @returns if relevant, the point color from the capsule property; otherwise, undefined
     */
    getColorUsingCapsuleProperty(row: any[], colorsConfig: CapsulePropertyColorsConfig | undefined,
      reduceOpacity: boolean = false): string | undefined {
      if (!colorsConfig) {
        return;
      }

      const propertyValue = row[colorsConfig.propertyIndex];
      if (_.isNil(propertyValue)) return;
      return this.getCapsulePropertyColor(colorsConfig.transformValue(propertyValue), colorsConfig, reduceOpacity);
    },

    /**
     * Gets the point color from the capsule property config and property value
     *
     * @param propertyValue - the value of the property for this point
     * @param colorsConfig - the config containing coloring information
     * @param reduceOpacity - whether the opacity of this color should be reduced from the default
     * @returns the color to use for this point, or undefined if the point shouldnt be colored with this method
     */
    getCapsulePropertyColor(propertyValue: number | string, colorsConfig: CapsulePropertyColorsConfig,
      reduceOpacity: boolean = false): string {
      if (!colorsConfig || _.isNil(propertyValue)) return;
      let color;
      if (colorsConfig.isStringProperty) {
        if (!_.isString(propertyValue)) {
          throw Error('Expected string-valued capsule property, but property value is not a string');
        }
        colorsConfig = colorsConfig as StringCapsulePropertyColorsConfig;
        color = colorsConfig.valueColorMap[propertyValue];
        if (reduceOpacity) {
          color = tinycolor(color).setAlpha(SCATTER_PLOT_REDUCED_OPACITY).toString();
        }
      } else if (!colorsConfig.isStringProperty) {
        if (!_.isNumber(propertyValue)) {
          throw Error('Expected numeric capsule property, but property value is not a number');
        }
        colorsConfig = colorsConfig as NumericCapsulePropertyColorsConfig;
        let colorIndex;
        if (colorsConfig.diffValue === 0) {
          colorIndex = 0;
        } else {
          colorIndex = Math.round(((propertyValue as number) - colorsConfig.minValue) / colorsConfig.diffValue * 99);
        }
        if (reduceOpacity) {
          colorIndex = Math.round(colorIndex / 3);
        }
        color = colorsConfig.colors[colorIndex]?.toString();
      }

      return color;
    },

    /**
     * Calculates the color of a point based on selected capsules. The color of the first capsule which contains the
     * {@code time} is returned.
     *
     * @param time - the time of the sample for which we calculate the color
     * @param {Capsule[]} selectedCapsules - an array with selected capsules
     * @param [capsuleProperty] - a capsule property we're using to color, if present
     * @param [capsulePropertyColorsConfig] - the coloring info for the capsule property coloring
     * @return {string|undefined} the corresponding color for the specified timestamp, if found
     */
    getColorUsingSelectedCapsules(time: number, selectedCapsules, capsuleProperty?: string,
      capsulePropertyColorsConfig?: CapsulePropertyColorsConfig) {
      const capsule = _.find(selectedCapsules, capsule => time >= capsule.startTime && time < capsule.endTime);
      if (!capsule) {
        return;
      }
      let color = capsule.color;
      // If we're coloring by a capsule property, use that color for coloring selected capsules
      if (!_.isNil(capsuleProperty) && !!capsulePropertyColorsConfig) {
        const propertyValue = this.getColorPropertyValueFromCapsule(capsule);
        if (propertyValue) {
          color = capsulePropertyColorsConfig.isStringProperty
            ? this.getCapsulePropertyColor(propertyValue, capsulePropertyColorsConfig)
            : (capsulePropertyColorsConfig as NumericCapsulePropertyColorsConfig).maxColor;
        }
      }
      return color;
    },

    /**
     * Clear the scatter plot series
     */
    clearScatterData() {
      this.scatterData = [];
      this.incrementScatterDataChangeCount();
    },

    /**
     * Clear the heat map data
     */
    clearDensityPlotData() {
      this.state.set('densityPlotData', []);
    },

    /**
     * Adds a function of x line for display on the scatter plot chart.
     *
     * @param {Object} payload - An object container for parameters
     * @param {String} payload.id - The item id
     * @param {String} payload.color - The color of the signal
     */
    addFxLine(payload) {
      this.state.push('fxLines', _.pick(payload, ['id', 'color']));
    },

    /**
     * Removes a function of x line from the display.
     *
     * @param {Object} payload - An object container for parameters
     * @param {String} payload.id - The item id
     */
    removeFxLine(payload) {
      const index = _.findIndex(this.state.get('fxLines'), ['id', payload.id]);
      if (index > -1) {
        this.state.splice('fxLines', [index, 1]);
      }
    },

    /**
     * Adds a condition for colorizing the scatter plot chart
     *
     * @param {Object} payload - An object container for parameters
     * @param {String} payload.id - The item id
     */
    addColorCondition(payload) {
      this.state.push('colorConditionIds', payload.id);
    },

    /**
     * Removes a condition for colorizing the scatter plot chart
     *
     * @param {Object} payload - An object container for parameters
     * @param {String} payload.id - The item id
     */
    removeColorCondition(payload) {
      const index = _.indexOf(this.state.get('colorConditionIds'), payload.id);
      if (index > -1) {
        this.state.splice('colorConditionIds', [index, 1]);
      }
    },

    /**
     * Sets the capsule property with which to color scatter plot points
     * Also, resets the color config
     *
     * @param payload - object container for parameters
     * @param payload.property - name of capsule property
     */
    setColorCapsuleProperty(payload: { property: string }) {
      this.state.set('colorCapsuleProperty', payload.property);
      this.state.unset('capsulePropertyColorsConfig');
    },

    setColorForCapsuleProperty(payload: { color: string }) {
      const currentColorsConfig = this.state.get('capsulePropertyColorsConfig');
      if (!currentColorsConfig || currentColorsConfig.isStringProperty
        || payload.color === currentColorsConfig.maxColor) {
        return;
      }

      const gradient = this.computeGradientForColorConfig(currentColorsConfig.minValue, currentColorsConfig.maxValue,
        payload.color);
      const newColorsConfig = { ...currentColorsConfig, ...gradient };
      this.state.set('capsulePropertyColorsConfig', newColorsConfig);
    },

    /**
     * Removes coloring by capsule property
     */
    removeColorCapsuleProperty() {
      this.state.unset('colorCapsuleProperty');
      this.state.unset('capsulePropertyColorsConfig');
    },

    /**
     * Sets a signal to use to colorize the chart.
     *
     * @param {Object} payload - An object container for parameters
     * @param {String} payload.id - The item id
     */
    setColorSignal(payload) {
      this.state.set('colorSignalId', payload.id);
    },

    /**
     * Removes the signal used for colorizing the scatter plot chart
     */
    removeColorSignal() {
      this.state.unset('colorSignalId');
    },

    /**
     * Adds a color and time range to be used for colorizing the scatter plot chart based on the time ranges.
     *
     * @param {ScatterPlotColorRange} payload - The color range
     */
    addColorRange(payload: ScatterPlotColorRange) {
      this.state.push('colorRanges', payload);
      this.refreshView();
    },

    /**
     * Updates a color range.
     *
     * @param {ScatterPlotColorRange} payload - The color range to update
     */
    updateColorRange(payload: ScatterPlotColorRange) {
      const index = _.findIndex(this.state.get('colorRanges'), { id: payload.id });
      if (index > -1) {
        this.state.set(['colorRanges', index], payload);
        this.refreshView();
      }
    },

    /**
     * Removes a color and time range from the scatter plot chart
     *
     * @param {Object} payload - An object container for parameters
     * @param {string} payload.id - The id of the range to remove
     */
    removeColorRange(payload) {
      const index = _.findIndex(this.state.get('colorRanges'), { id: payload.id });
      if (index > -1) {
        this.state.splice('colorRanges', [index, 1]);
        this.refreshView();
      }
    },

    /**
     * Removes items that are used in this store but have been removed from the details pane.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} payload.items - An array of items to remove
     */
    removeItem(payload) {
      let rawDataUpdated = false;
      _.forEach(payload.items, (item) => {
        this.removeFxLine({ id: item.id });
        this.removeColorCondition({ id: item.id });
        if (item.id === this.state.get('colorSignalId')) {
          this.state.unset('colorSignalId');
        }
        ['xSeries', 'ySeries'].forEach((series) => {
          if (item.id === this.state.get(series)?.id) {
            this.state.unset(series);
            this.clearScatterData();
            this.clearDensityPlotData();
          }
        });
        // Item was removed from detail pane, so we remove corresponding data from our raw data
        const rawDataColumnRemoved = this.removeRawDataColumn(item.id);
        rawDataUpdated = rawDataUpdated || rawDataColumnRemoved;
      });

      if (rawDataUpdated) {
        this.refreshView();
      }
    },

    /**
     * Removes the corresponding column and values from the raw data
     *
     * @param itemId - Column identifier. Match is done with include, not exact match
     * @return {boolean} true if the column was found and data removed
     */
    removeRawDataColumn(itemId) {
      const columnIndex = _.findIndex(this.rawData?.headers,
        (column: any) => column.name.includes(itemId));
      if (columnIndex === -1) {
        return false;
      }

      const newRawData = { headers: this.rawData.headers, data: [] };
      newRawData.headers.splice(columnIndex, 1);
      newRawData.data = _.chain(this.rawData.data)
        .cloneDeep()
        .map((row: any[]) => {
          row.splice(columnIndex, 1);
          return row;
        })
        .value();
      this.rawData = newRawData;

      return true;
    },

    /**
     * Changes the color of any items used in scatter plot that reference the item color
     *
     * @param {Object} payload - An object container for parameters
     * @param {String} payload.id - The item id
     * @param {String} payload.color - The color
     */
    handleItemColorChange(payload) {
      const index = _.findIndex(this.state.get('fxLines'), ['id', payload.id]);
      if (index > -1) {
        this.state.set(['fxLines', index, 'color'], payload.color);
      }

      if (this.state.get(['minimapXSeries', 'id']) === payload.id) {
        this.state.set(['minimapXSeries', 'color'], payload.color);
      }
      if (this.state.get(['minimapYSeries', 'id']) === payload.id) {
        this.state.set(['minimapYSeries', 'color'], payload.color);
      }

      if (_.includes(this.state.get('colorConditionIds'), payload.id)
        || this.state.get('colorSignalId') === payload.id) {
        this.refreshView();
      }
    },

    /**
     * Swaps old items with new items in the internal state of the store. This method does NOT refresh data. The
     * action should call fetch data after swapItems to get fresh data from backend.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object} payload.swaps - The items that were swapped where the keys are the swapped out ids and the
     *   values are the corresponding swapped in ids.
     * @param {Object} payload.outAsset - Asset that was swapped out
     * @param {String} payload.outAsset.id - The ID of the asset to swapped out
     * @param {String} payload.inAsset.name - The name of the asset that was swapped out
     * @param {Object} payload.inAsset - Asset that was swapped in
     * @param {String} payload.inAsset.id - The ID of the asset that was swapped in
     * @param {String} payload.inAsset.name - The name of the asset that was swapped in
     */
    swapItems(payload) {
      const swaps = payload.swaps;

      const newXSeriesId = swaps[_.get(this.state.get('xSeries'), 'id')];
      if (newXSeriesId) {
        this.state.set(['xSeries', 'id'], newXSeriesId);
      }

      const newYSeriesId = swaps[_.get(this.state.get('ySeries'), 'id')];
      if (newYSeriesId) {
        this.state.set(['ySeries', 'id'], newYSeriesId);
      }

      const newColorSignalId = swaps[this.state.get('colorSignalId')];
      if (newColorSignalId) {
        this.state.set('colorSignalId', newColorSignalId);
      }

      this.state.set('colorConditionIds',
        _.map(this.state.get('colorConditionIds'), id => swaps[id] ? swaps[id] : id));

      const newFxLines = _.map(this.state.get('fxLines'),
        fxLine => swaps[fxLine.id] ? { ...fxLine, id: swaps[fxLine.id] } : fxLine);
      this.state.set('fxLines', newFxLines);
    },

    /**
     * Adds the sample data for a function of x line in a manner suitable for display on the scatter plot chart.
     *
     * @param {Object} payload - An object container for parameters
     * @param {String} payload.id - The item id
     * @param {Object[]} payload.samples - Samples where the key corresponds to the x-axis and the value corresponds to
     * the y-axis
     * @param {FxLineMetadata} payload.metadata - Additional metadata from the computation
     */
    setFxLineData(payload) {
      const index = _.findIndex(this.state.get('fxLines'), ['id', payload.id]);
      if (index > -1) {
        this.state.set(['fxLines', index, 'data'],
          _.map(payload.samples, sample => [sample.key, sample.value]));
        this.state.set(['fxLines', index, 'metadata'], payload.metadata);
        if (payload.numberFormat) {
          this.state.set(['fxLines', index, 'numberFormat'], payload.numberFormat);
        }
      }
    },

    /**
     * Triggers color recalculation and refresh view
     */
    refreshView() {
      if (!this.rawData) {
        return;
      }

      if (this.state.get('plotView') === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
        this.setScatterData(this.rawData);
      } else if (this.state.get('plotView') === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
        this.setDensityPlotData(this.rawData);
      }
    },

    /**
     * Sets minimap X series
     *
     * @param {Object} payload - An object container for parameters
     * @param {string} payload.id - The series GUID
     * @param {string} payload.color - The series color
     * @param {Object[]} payload.data - The series data
     */
    setMinimapXSeries(payload) {
      this.state.set('minimapXSeries', payload);
    },

    /**
     * Clears the minimap X series
     */
    clearMinimapXSeries() {
      this.state.set('minimapXSeries', null);
    },

    /**
     * Sets minimap Y series
     *
     * @param {Object} payload - An object container for parameters
     * @param {string} payload.id - The series GUID
     * @param {string} payload.color - The series color
     * @param {Object[]} payload.data - The series data
     */
    setMinimapYSeries(payload) {
      this.state.set('minimapYSeries', payload);
    },

    /**
     * Clears the minimap Y series
     */
    clearMinimapYSeries() {
      this.state.set('minimapYSeries', null);
    },

    /**
     * Sets the series on the plot's x-axis
     *
     * @param {Object} payload - An object container for the parameters
     * @param {Object} payload.xSeries - the series to place on the x axis
     */
    setXSeries(payload) {
      this.state.set('xSeries', _.pick(payload.xSeries, ['id', 'formatOptions.format']));
    },

    /**
     * Sets the series on the plot's y-axis
     *
     * @param {Object} payload - An object container for the parameters
     * @param {Object} payload.ySeries - the series to place on the y axis
     */
    setYSeries(payload) {
      this.state.set('ySeries', _.pick(payload.ySeries, ['id', 'formatOptions.format']));
    },

    /**
     * Flips the x and y signals, for both the axes and the data.
     */
    flipAxes() {
      const plotView = this.state.get('plotView');

      if (plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT && this.isScatterDataEmpty()) {
        return;
      }

      if (plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT && this.isDensityPlotDataEmpty()) {
        return;
      }

      const temp = this.state.get('ySeries');
      this.state.set('ySeries', this.state.get('xSeries'));
      this.state.set('xSeries', temp);

      if (plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
        const tempBins = this.state.get('numYBins');
        this.state.set('numYBins', this.state.get('numXBins'));
        this.state.set('numXBins', tempBins);
      }

      if (this.isViewRegionSet()) {
        const view = this.state.get('viewRegion');
        // noinspection JSSuspiciousNameCombination
        this.state.set('viewRegion', { xMin: view.yMin, xMax: view.yMax, yMin: view.xMin, yMax: view.xMax });
      }

      if (this.isRegionSelected()) {
        const region = this.state.get('selectedRegion');
        // noinspection JSSuspiciousNameCombination
        this.state.set('selectedRegion',
          { xMin: region.yMin, xMax: region.yMax, yMin: region.xMin, yMax: region.xMax });
      }

      if (plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
        this.setScatterData({
          headers: this.rawData.headers,
          data: _.chain(this.rawData.data)
            .cloneDeep()
            .map((row: any[]) => {
              [row[1], row[2]] = [row[2], row[1]];
              return row;
            })
            .value()
        });
      } else if (plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
        this.setDensityPlotData({
          headers: this.rawData.headers,
          data: _.chain(this.rawData.data)
            .cloneDeep()
            .map((row: any[]) => {
              [row[0], row[1]] = [row[1], row[0]];
              return row;
            })
            .value()
        });
      }
    },

    /**
     * Helper method to determine if there is a selected region.
     */
    isRegionSelected() {
      return !_.isEqual(this.state.get('selectedRegion'), EMPTY_XYREGION);
    },

    /**
     * Helper method to determine if there is a view region.
     */
    isViewRegionSet() {
      return !_.isEqual(this.state.get('viewRegion'), EMPTY_XYREGION);
    },

    isScatterDataEmpty() {
      return _.isEmpty(this.scatterData);
    },

    isDensityPlotDataEmpty() {
      return _.isEmpty(this.state.get('densityPlotData'));
    },

    setNumXBins(payload) {
      this.state.set('numXBins', payload.numXBins);
    },

    setNumYBins(payload) {
      this.state.set('numYBins', payload.numYBins);
    },

    isValidNumber(value) {
      return _.isNumber(value) && !_.isNaN(value);
    },

    getXAxisRange() {
      const plotView = this.state.get('plotView');
      if (plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
        return this.getScatterPlotXAxisRange();
      } else if (plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
        return this.getDensityPlotXAxisRange();
      }
    },

    getScatterPlotXAxisRange() {
      return this.isViewRegionSet() ?
        {
          start: this.state.get('viewRegion').xMin,
          end: this.state.get('viewRegion').xMax
        }
        :
        _.reduce(this.scatterData, (memo, datum) => ({
          start: !memo.start || memo.start > datum.x ? datum.x : memo.start,
          end: !memo.end || memo.end < datum.x ? datum.x : memo.end
        }), { start: undefined, end: undefined });
    },

    getDensityPlotXAxisRange() {
      if (this.isViewRegionSet()) {
        return {
          start: this.state.get('viewRegion').xMin,
          end: this.state.get('viewRegion').xMax
        };
      } else {
        const dataRange = _.reduce(this.state.get('densityPlotData'), (memo, datum) => ({
          start: !this.isValidNumber(memo.start) || memo.start > datum[0] ? datum[0] : memo.start,
          end: !this.isValidNumber(memo.end) || memo.end < datum[0] ? datum[0] : memo.end
        }), { start: undefined, end: undefined });
        // Since the data contains the min of each bin, we're actually missing one binsize of length
        const endDivisor = this.state.get('numXBins') > 1 ? this.state.get('numXBins') - 1 : 1;
        return {
          start: dataRange.start,
          end: dataRange.end + ((dataRange.end - dataRange.start) / endDivisor)
        };
      }
    },

    getYAxisRange() {
      const plotView = this.state.get('plotView');
      if (plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT) {
        return this.getScatterPlotYAxisRange();
      } else if (plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT) {
        return this.getDensityPlotYAxisRange();
      }
    },

    getScatterPlotYAxisRange() {
      return this.isViewRegionSet() ?
        {
          start: this.state.get('viewRegion').yMin,
          end: this.state.get('viewRegion').yMax
        }
        :
        _.reduce(this.scatterData, (memo, datum) => ({
          start: !memo.start || memo.start > datum.y ? datum.y : memo.start,
          end: !memo.end || memo.end < datum.y ? datum.y : memo.end
        }), { start: undefined, end: undefined });
    },

    getDensityPlotYAxisRange() {
      if (this.isViewRegionSet()) {
        return {
          start: this.state.get('viewRegion').yMin,
          end: this.state.get('viewRegion').yMax
        };
      } else {
        const dataRange = _.reduce(this.state.get('densityPlotData'), (memo, datum) => ({
          start: !this.isValidNumber(memo.start) || memo.start > datum[1] ? datum[1] : memo.start,
          end: !this.isValidNumber(memo.end) || memo.end < datum[1] ? datum[1] : memo.end
        }), { start: undefined, end: undefined });
        // Since the data contains the min of each bin, we're actually missing one binsize of length
        const endDivisor = this.state.get('numYBins') > 1 ? this.state.get('numYBins') - 1 : 1;
        return {
          start: dataRange.start,
          end: dataRange.end + ((dataRange.end - dataRange.start) / endDivisor)
        };
      }
    },

    getColorAxisRange() {
      return _.reduce(this.state.get('densityPlotData'), (memo, datum) => ({
        start: !this.isValidNumber(memo.start) || (memo.start > datum[2] && this.isValidNumber(datum[2])) ?
          datum[2] :
          memo.start,
        end: !this.isValidNumber(memo.end) || (memo.end < datum[2] && this.isValidNumber(datum[2])) ?
          datum[2] :
          memo.end
      }), { start: undefined, end: undefined });
    },

    /**
     * Sets the marker size from user input, and sets a flag to indicate that the marker size is custom.
     * Setting markerSize to undefined will unset the flag and revert control of the
     * marker size back to Seeq.
     *
     * @param {Object} payload - An object container for the parameters
     * @param {number} [payload.markerSize] - the radius of the markers
     */
    setMarkerSize(payload) {
      const markerSize = payload.markerSize;
      if (_.isUndefined(markerSize)) {
        this.state.set('markerSize', this.calculateMarkerSize());
        this.state.set('isMarkerSizeCustom', false);
      } else {
        this.state.set('markerSize', markerSize);
        this.state.set('isMarkerSizeCustom', true);
      }
    },

    /**
     * Calculates a size for the scatter plot markers based on how much data is on the chart.
     * More data points => smaller markers.
     * Let n be the number of samples.
     * If n < MIN_SAMPLES_FOR_BOOST, then this will return a value of MAX_MARKER_SIZE_CALCULATED.
     * If n > MAX_MARKER_SIZE_CALCULATED * MIN_SAMPLES_FOR_BOOST samples,
     *    then this will return a value of MIN_MARKER_SIZE_CALCULATED.
     * If MIN_SAMPLES_PER_BOOST < n < MAX_MARKER_SIZE_CALCULATED * MIN_SAMPLES_FOR_BOOST,
     *     then this will return a value in between MIN_MARKER_SIZE_CALCULATED and
     *     MAX_MARKER_SIZE_CALCULATED that decreases like 1/n.
     *
     *  @returns markerSize
     */
    calculateMarkerSize(): number {
      const numPoints = this.scatterData.length;
      return Math.max(MAX_MARKER_SIZE_CALCULATED *
        Math.min(MIN_SAMPLES_FOR_BOOST / numPoints, 1), MIN_MARKER_SIZE_CALCULATED);
    },

    /**
     * Increments a store variable whenever we update scatterData.
     * Because scatterData is not kept in the baobab store, changes to scatterData
     * won't directly cause an event to be emitted, so we keep a var in the baobab store
     * that we update whenever scatterData changes.
     */
    incrementScatterDataChangeCount() {
      this.state.set('scatterDataChangeCount', this.state.get('scatterDataChangeCount') + 1);
    },

    setShowColorModal(payload) {
      this.state.set('showColorModal', payload.showColorModal);
    },

    /**
     * Get the value of the color capsule property from a capsule
     *
     * @param capsule - capsule to check for the capsule property value
     * @param [timezone] - the user's current timezone, used for start time/end time
     * @returns property value if it exists, or undefined
     */
    getColorPropertyValueFromCapsule(capsule, timezone?) {
      const propertyName = this.state.get('colorCapsuleProperty');
      if (propertyName === SeeqNames.CapsuleProperties.Start && !_.isNil(capsule.startTime)) {
        return capsule.startTime.toString();
      }
      if (propertyName === SeeqNames.CapsuleProperties.End && !_.isNil(capsule.endTime)) {
        return capsule.endTime.toString();
      }
      if (propertyName === SeeqNames.CapsuleProperties.Duration && !_.isNil(capsule.duration)) {
        return capsule.duration;
      }
      if (propertyName === SeeqNames.CapsuleProperties.Similarity && !_.isNil(capsule.similarity)) {
        return capsule.similarity;
      }
      return capsule.properties?.[propertyName];
    }
  };

  return store;
}
