import React, { useState } from 'react';
import { bindingsDefinition } from '@/hybrid/core/bindings.util';
import { ReportTable } from '@/hybrid/administration/ReportTable.page';
import { ReportCharts } from '@/hybrid/administration/ReportCharts.molecule';
import { DEFAULT_TEXT_FILTERS } from '@/hybrid/administration/reportAdmin.utilities';
import { TextButton } from '@/hybrid/core/TextButton.atom';

const reportTabBindings = bindingsDefinition({});

enum ReportAdminView {Table, Chart}

export const ReportTab: SeeqComponent<typeof reportTabBindings> = () => {
  const [showCharts, setShowCharts] = useState(false);
  const [reportTextFilters, setReportTextFilters] = useState(DEFAULT_TEXT_FILTERS);
  const [chartTrigger, setChartTrigger] = useState(false);
  const [tableTrigger, setTableTrigger] = useState(false);

  const toggleHighButton = <TextButton
    id="toggleCharts"
    extraClassNames="mr5 height-32"
    type="button"
    size="sm"
    variant="theme"
    onClick={() => setShowCharts(!showCharts)}
    label="ADMIN.REPORT.TOGGLE_CHART"
  />;

  // The trigger concept is a workaround for two adjacent components not having a way to trigger the other's
  // refresh. The admin store might be a decent place to put this, but I (Ryan) wanted to try and leverage raw React
  // to make it work. Each child component ties its fetching to its respective trigger, allowing one side to poke
  // the other and manually trigger a fetch.
  const triggerOtherFetch = (view: ReportAdminView) => {
    if (view === ReportAdminView.Chart) setTableTrigger(!tableTrigger);
    else if (view === ReportAdminView.Table) setChartTrigger(!chartTrigger);
  };

  return (<>
    <ReportTable
      hide={showCharts}
      toggleHideButton={toggleHighButton}
      reportTextFilters={reportTextFilters}
      setReportTextFilters={setReportTextFilters}
      triggerFetch={() => triggerOtherFetch(ReportAdminView.Table)}
      trigger={tableTrigger}
    />
    <ReportCharts
      hide={!showCharts}
      toggleHideButton={toggleHighButton}
      reportTextFilters={reportTextFilters}
      setReportTextFilters={setReportTextFilters}
      triggerFetch={() => triggerOtherFetch(ReportAdminView.Chart)}
      trigger={chartTrigger}
    />
  </>);
};
