import angular from 'angular';
import template from './seriesPanel.directive.html';

angular.module('Sq.TrendViewer').directive('sqSeriesPanel', sqSeriesPanel);

function sqSeriesPanel() {
  return {
    restrict: 'E',
    template,
    controller: 'SeriesPanelCtrl',
    controllerAs: 'ctrl',
    scope: {},
    bindToController: {
      resizePanelStart: '&',
      resizeEnabled: '='
    }
  };
}
