import _ from 'lodash';
import angular from 'angular';
import HttpCodes from 'http-status-codes';

import workstepAnalyzerTemplate from '@/tools/workstepAnalyzer/workstepAnalyzer.html';
import administrationTemplate from '@/administration/administration.html';
import buildingTemplate from '@/builder/building.html';
import footerTemplate from '@/footer/footer.html';
import headerTemplate from '@/header/header.html';
import licensePageTemplate from '@/licenseManagement/licensePage.html';
import logPageTemplate from './logPage.html';
import auditTrailPageTemplate from '@/auditTrail/auditTrailPage.html';
import workbenchExplorerTemplate from '@/workbenchExplorer/workbenchExplorer.html';
import worksheetTemplate from '@/worksheet/worksheet.html';
import worksheetsTemplate from '@/worksheets/worksheets.html';
import loadErrorTemplate from './loadError.html';
import loginTemplate from './login.html';
import mainTemplate from './main.html';
import unauthorizedTemplate from './unauthorized.html';
import {
  INITIALIZE_MODE,
  PERSISTENCE_LEVEL,
  PUSH_WORKSTEP_IMMEDIATE,
  StateSynchronizerService
} from '@/services/stateSynchronizer.service';
import reactComponentsModule from '@/hybrid/reactComponents.module';
import formBuilderModule from '@/hybrid/formbuilder/formBuilder.module';
import homeScreenModule from '@/hybrid/homescreen/homescreen.module';
import formulaToolModule from '@/hybrid/tools/formula/formulaTool.module';
import importDatafileModule from '@/hybrid/tools/importDatafile/importDatafile.module';
import ckReportModule from '@/hybrid/annotation/ckReport.module';
import tableBuilderModule from '@/hybrid/tableBuilder/tableBuilder.module';
import explorerModule from '@/hybrid/explorer/explorer.module';
import manualSignalModule from '@/hybrid/tools/manualSignal/manualSignal.module';
import assetGroupModule from '@/hybrid/assetGroupEditor/assetGroup.module';
import logTrackerModule from './logTracker.module';
import { WorkbenchService } from '@/workbench/workbench.service';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { AuthorizationService } from '@/services/authorization.service';
import { WorkbookActions } from '@/workbook/workbook.actions';
import { UtilitiesService } from '@/services/utilities.service';
import { TrackService } from '@/track/track.service';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { AuthenticationService } from '@/services/authentication.service';
import { NotificationsService } from '@/services/notifications.service';
import { LoggerService } from '@/services/logger.service';
import { TrendActions } from '@/trendData/trend.actions';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { JournalLinkService } from '@/annotation/journalLink.service';
import { ALLOWED_SCREENSHOT_MODE_PATHS, ScreenshotService } from '@/services/screenshot.service';
import { DurationActions } from '@/trendData/duration.actions';
import { WorkbookService } from '@/workbook/workbook.service';
import { AnnotationActions } from '@/annotation/annotation.actions';
import { AnnotationStore } from '@/annotation/annotation.store';
import { WorkbookStore } from '@/workbook/workbook.store';
import { ReportActions } from '@/reportEditor/report.actions';
import { WorksheetsService } from '@/worksheets/worksheets.service';
import { WorkstepsActions } from '@/worksteps/worksteps.actions';
import { DateTimeService } from '@/datetime/dateTime.service';
import { ReportStore } from '@/reportEditor/report.store';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { FrontendDuration, SystemConfigurationService } from '@/services/systemConfiguration.service';
import { FooterCtrl } from '@/footer/footer.controller';
import { RedactionService } from '@/services/redaction.service';
import { SocketService } from '@/services/socket.service';
import { VisibilityService } from '@/services/visibility.service';
import { AUTH_CHANGE_BROADCAST_CHANNEL, BroadcastChannelService } from '@/services/broadcastChannel.service';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.module';
import { AUTO_UPDATE } from '@/trendData/trendData.module';
import {
  API_TYPES,
  APP_STATE,
  APPSERVER_API_CONTENT_TYPE,
  APPSERVER_API_PREFIX,
  FAVICON,
  JOURNAL_PREFIX_PATH
} from '@/main/app.constants';
import { WORKSHEET_WITH_NO_WORKSTEPS } from '@/worksteps/worksteps.module';
import { HttpHelpersService } from '@/services/httpHelpers.service';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { LicenseManagementActions } from '@/licenseManagement/licenseManagement.actions';
import { HomeScreenStore } from '@/hybrid/homescreen/homescreen.store';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { PluginActions } from '@/hybrid/plugin/plugin.actions';
import { SummarySliderValues } from '@/trendData/trend.store';
import { SummaryTypeEnum } from '@/sdk/model/ContentInputV1';
import datasourcesModule from '@/hybrid/administration/datasources/datasources.module';
import agentsModule from '@/hybrid/administration/agents/agents.module';
import { ItemsApi, TreesApi, ContentApi } from '@/sdk';
import { SearchResultUtilitiesService } from '@/hybrid/search/searchResult.utilities.service';

export const dependencies = [
  reactComponentsModule.name,
  'Sq.Vendor',
  'Sq.Main',
  'Sq.Core',
  'Sq.DateTime',
  'Sq.Administration',
  'Sq.Login',
  'Sq.Investigate',
  'Sq.CompositeSearch',
  'Sq.Search',
  'Sq.Track',
  'Sq.Workbench',
  'Sq.Header',
  formulaToolModule.name,
  homeScreenModule.name,
  formBuilderModule.name,
  explorerModule.name,
  assetGroupModule.name,
  manualSignalModule.name,
  importDatafileModule().name,
  'Sq.Worksheet',
  'Sq.Worksheets',
  'Sq.Workbook',
  'Sq.Worksteps',
  'Sq.Footer',
  'Sq.Builder',
  'Sq.AppConstants',
  tableBuilderModule.name,
  'Sq.Treemap',
  logTrackerModule().name,
  'Sq.LicenseManagement',
  'Sq.SystemWarning',
  'Sq.Directives.AutoFocus',
  'Sq.Directives.DoubleClick',
  'Sq.Directives.Onerror',
  'Sq.Directives.Onload',
  'Sq.Directives.ResizeNotify',
  'Sq.Directives.GreaterThan',
  'Sq.Directives.SelectOnFocus',
  'Sq.Directives.FauxSelect',
  'Sq.Report',
  'Sq.TrendData',
  'Sq.Services.Authentication',
  'Sq.Services.Authorization',
  'Sq.Services.ACLService',
  'Sq.Services.DomClassList',
  'Sq.Services.Logger',
  'Sq.Services.Visibility',
  'Sq.Services.Notifications',
  'Sq.Services.PendingRequests',
  'Sq.Services.BuildConstants',
  'Sq.Services.Socket',
  'Sq.Services.BroadcastChannel',
  'Sq.Services.Notifier',
  'Sq.Services.Redaction',
  'Sq.Services.ChartSelection',
  'Sq.Services.ChartRegion',
  'Sq.Services.AxisControl',
  'Sq.Services.SystemConfiguration',
  'Sq.TrendViewer',
  'Sq.ScatterPlot',
  'Sq.Utilities',
  'Sq.PluginHost',
  datasourcesModule.name,
  agentsModule.name
];

// This is the same module as `Sq.Report`, so we don't want to duplicate it up in the deps, but we have to execute
// it as a function to give the ES6 actions class time to figure itself out before angular can load it as a service.
ckReportModule();

let unsubscribeFromWorkbook = _.noop;
let unsubscribeFromWorkstepChannel = _.noop;
let unsubscribeFromReportUpdatesChannel = _.noop;
let stateBeingTransitionedTo = {} as any;

const app = angular.module('sqApp', dependencies);

app
  .controller('AppCtrl', function(
    $rootScope: ng.IRootScopeService,
    $state: ng.ui.IStateService) {

    const vm = this;
    vm.currentFavIcon = null;
    $rootScope.$watch(() => {
      return _.get($state, 'current.data');
    }, (data) => {
      vm.isAnalysis = _.get(data, 'isAnalysis', false);
      vm.isReport = _.get(data, 'isReport', false);
      const expectedFavIcon = vm.isAnalysis ? FAVICON.GREEN : (vm.isReport ? FAVICON.BLUE : FAVICON.WORKBENCH);
      if (expectedFavIcon !== vm.currentFavIcon) {
        vm.currentFavIcon = expectedFavIcon;
        // ng-href adds an additional '/' at the end of the fav-icon link which prevents it from loading
        jQuery('#favicon').attr('href', vm.currentFavIcon);
      }
    });

    $rootScope.$watch(() => {
      return _.get($state, 'current.data.title');
    }, function(title) {
      vm.title = title || 'Seeq';
      if (vm.title !== 'Seeq') {
        vm.title += ' - Seeq';
      }
      if (process.env.NODE_ENV === 'development') {
        vm.title = '[DEV] ' + vm.title;
      }
    });
  })
  .config(function(
    $urlRouterProvider: ng.ui.IUrlRouterProvider,
    $urlMatcherFactoryProvider: ng.ui.IUrlMatcherFactory,
    $stateProvider: ng.ui.IStateProvider,
    $httpProvider: ng.IHttpProvider,
    $translateProvider: ng.translate.ITranslateProvider,
    $compileProvider: ng.ICompileProvider,
    $locationProvider: ng.ILocationProvider,
    $uibTooltipProvider: ng.ui.bootstrap.ITooltipProvider,
    $animateProvider,
    cfpLoadingBarProvider,
    $provide: ng.auto.IProvideService) {
    // The new home screen opens Analyses and Topics in a new tab. Extend $state to make it easy to do so:
    // https://stackoverflow.com/questions/23516289/angularjs-state-open-link-in-new-tab
    $provide.decorator('$state', ['$delegate', '$window', 'sqWorkbenchStore',
      ($delegate, $window, sqWorkbenchStore) => {
        const extended = {
          goNewTab: (stateName, params, event) => {
            if (sqWorkbenchStore.preferNewTab || event?.ctrlKey || event?.metaKey) {
              $window.open($delegate.href(stateName, params, { absolute: true }), '_blank');
              return true;
            } else {
              $delegate.go(stateName, params);
              return false;
            }
          }
        };
        angular.extend($delegate, extended);
        return $delegate;
      }]);

    cfpLoadingBarProvider.parentSelector = '#loading-bar-container';

    $uibTooltipProvider.options({
      popupDelay: 300,
      appendToBody: true
    });

    // NOTE: Any new routes must also be added as routes in server.js
    $urlRouterProvider.otherwise('/workbooks');
    $stateProvider
      .state('workbench', {
        abstract: true,
        template: mainTemplate,
        controller: 'MainCtrl as main',
        resolve: {
          // Note: workbenchState must be injected into other child states to guarantee it is resolved first
          workbenchState(
            sqStateSynchronizer: StateSynchronizerService,
            $translate: ng.translate.ITranslateService,
            $q: ng.IQService,
            sqWorkbench: WorkbenchService,
            sqWorkbenchStore: WorkbenchStore,
            sqWorkbenchActions: WorkbenchActions,
            sqSystemConfiguration: SystemConfigurationService,
            sqSocket: SocketService,
            sqScreenshot: ScreenshotService,
            sqAuthentication: AuthenticationService,
            sqLicenseManagementActions: LicenseManagementActions,
            sqRedaction: RedactionService,
            sqVisibility: VisibilityService,
            sqPluginActions: PluginActions,
            sqUtilities: UtilitiesService
          ) {
            // Initialize the redaction service before any items load so we can recognize if any items were forbidden
            sqRedaction.initialize();
            return sqVisibility.waitForVisibility()
              .then(() => $q.all([
                // Fetch initial data
                sqSystemConfiguration.fetch(),
                sqSystemConfiguration.fetchEveryoneDisabled(),
                sqPluginActions.load().catch(_.noop),
                sqWorkbenchActions.setCurrentUser(),
                sqLicenseManagementActions.checkLicenseStatus(stateBeingTransitionedTo.name),
                // Miscellaneous setup
                sqScreenshot.fetchHeadlessCaptureMetadata(),
                sqSocket.open(sqWorkbenchStore.interactiveSessionId, sqAuthentication.getCsrfToken())
              ]))
              .then(() => {
                sqStateSynchronizer.rehydrate(sqWorkbench.get(),
                  { persistenceLevel: PERSISTENCE_LEVEL.WORKBENCH, initializeMode: INITIALIZE_MODE.SOFT });
              })
              .then(() => {
                // Set language and force loading of translation files
                // This has to happen AFTER the workbench state is available
                return sqUtilities.switchLanguage(sqWorkbenchStore.userLanguage)
                  .then(() => $translate('WORKBENCH.ROOT'));
              });
          }
        }
      })
      .state('workbench.common', {
        abstract: true,
        data: {
          title: ''
        },
        views: {
          header: {
            template: headerTemplate,
            controller: 'HeaderCtrl as ctrl'
          },
          workbooks: {
            template: workbenchExplorerTemplate
          },
          worksheets: {
            template: worksheetsTemplate
          },
          administration: {
            template: administrationTemplate
          },
          license: {
            template: licensePageTemplate
          },
          logs: {
            template: logPageTemplate
          },
          auditTrail: {
            template: auditTrailPageTemplate
          },
          footer: {
            template: footerTemplate,
            controller: FooterCtrl,
            controllerAs: '$ctrl'
          }
        }
      })
      .state(APP_STATE.LOG_TRACKER, {
        url: '/logs',
        resolve: {
          checkAccess(workbenchState, $q: ng.IQService, sqAuthorization: AuthorizationService) {
            return !sqAuthorization.canViewLogs() ? $q.reject(ROUTE_FORBIDDEN) : undefined;
          }
        }
      })
      .state(APP_STATE.AUDIT_TRAIL, {
        url: '/auditTrail',
        resolve: {
          checkAccess(workbenchState, $q: ng.IQService, sqAuthorization: AuthorizationService) {
            return !sqAuthorization.canReadAuditTrail() ? $q.reject(ROUTE_FORBIDDEN) : undefined;
          }
        }
      })
      .state(APP_STATE.WORKBOOKS, {
        url: '/workbooks?t',
        resolve: {
          rehydratedState(workbenchState,
            $stateParams: ng.ui.IStateParamsService,
            $q: ng.IQService,
            sqHomeScreenActions: HomeScreenActions,
            sqUtilities: UtilitiesService
          ) {
            return $q.resolve()
              .then(() => sqHomeScreenActions.loadFolder(null,
                $stateParams.t ? sqUtilities.getCurrentTabFromHash($stateParams.t) : ''));
          }
        }
      })
      .state(APP_STATE.FOLDER_EXPANDED, {
        // this is the state to display all the contents of a folder
        url: '^/:currentFolderId/folder/?t',
        resolve: {
          // TODO: Cody Ray Hoeft this endpoint seems ambiguous. We should consider combine it with the /workbooks state
          rehydratedState(
            workbenchState,
            $stateParams: ng.ui.IStateParamsService,
            $q: ng.IQService,
            sqHomeScreenActions: HomeScreenActions,
            sqHomeScreenStore: HomeScreenStore,
            sqUtilities: UtilitiesService) {
            return $q.resolve()
              .then(() =>
                sqHomeScreenActions.loadFolder($stateParams.currentFolderId,
                  $stateParams.t ? sqUtilities.getCurrentTabFromHash($stateParams.t) : sqHomeScreenStore.currentTab)
                  .catch(e => checkForNotFoundOrForbidden($q, ROUTE_NO_WORKBOOK, ROUTE_FORBIDDEN, e)));
          }
        }
      })
      .state(APP_STATE.FOLDER, {
        // this is the state that is used to preview the folder in the side panel
        url: '^/:currentFolderId/folder/:folderId',
        params: {
          currentFolderId: {
            value: null,
            squash: true
          }
        },
        resolve: {
          rehydratedState(
            workbenchState,
            $stateParams: ng.ui.IStateParamsService,
            $q: ng.IQService,
            sqHomeScreenActions: HomeScreenActions,
            sqHomeScreenStore: HomeScreenStore) {
            // TODO: Cody Ray Hoeft ui-router calls this endpoint for calls like 'GUID/folder/' because it ignores
            // the empty url parameters. we should find some way to handle that ambiguity or simplify our states
            return sqHomeScreenActions.loadFolder($stateParams.currentFolderId, sqHomeScreenStore.currentTab)
              .catch(
                response => checkForNotFoundOrForbidden($q, ROUTE_NO_WORKBOOK, ROUTE_FORBIDDEN, response));
          }
        }
      })
      .state(APP_STATE.PROJECT, {
        url: '^/:currentFolderId/project/:projectId?open',
        params: {
          currentFolderId: {
            value: null,
            squash: true
          }
        },
        resolve: {
          rehydratedState(
            workbenchState,
            $q: ng.IQService,
            $state: ng.ui.IStateService,
            $location: ng.ILocationService,
            $stateParams: ng.ui.IStateParamsService,
            sqHomeScreenUtilities: HomeScreenUtilitiesService,
            sqHomeScreenActions: HomeScreenActions,
            sqHomeScreenStore: HomeScreenStore
          ) {
            return sqHomeScreenActions.loadFolder($stateParams.currentFolderId, sqHomeScreenStore.currentTab)
              .then(() => {
                // this is for the case where we want to open a project on page load
                if ($stateParams.open === 'true') {
                  sqHomeScreenUtilities.openProject($stateParams.projectId);
                  // this serves to clear the open query parameter state, so that projects don't open when the user
                  // clicks to select them
                  $state.transitionTo(
                    APP_STATE.PROJECT,
                    { projectId: $stateParams.projectId, currentFolderId: $stateParams.currentFolderId },
                    { reload: true, inherit: false }
                  );
                }
              })
              .catch(
                response => checkForNotFoundOrForbidden($q, ROUTE_NO_WORKBOOK, ROUTE_FORBIDDEN, response));
          }
        }
      })
      .state(APP_STATE.WORKSHEET, {
        url: '^/:currentFolderId/workbook/:workbookId/worksheet/:worksheetId?workstepId&displayRangeStart&displayRangeEnd&timezone&assetId',
        params: {
          currentFolderId: {
            value: null,
            squash: true
          },
          archived: null
        },
        views: {
          'worksheet@workbench': {
            template: worksheetTemplate,
            controller: 'WorksheetCtrl as ctrl'
          }
        },
        resolve: {
          rehydratedState(
            workbenchState,
            $injector: ng.auto.IInjectorService,
            $stateParams: ng.ui.IStateParamsService) {

            return loadWorksheet($injector, _.assign({ workbookDisplay: WORKBOOK_DISPLAY.EDIT }, $stateParams));
          }
        }
      })

      /**
       * This endpoint has no view because it just redirects to a more fully qualified url. If it fails then
       * ROUTE_NO_WORKBOOK will be returned and the user will end up at the /workbooks view
       */
      .state(APP_STATE.VIEW, {
        url: '/view/:viewId?workstepId&displayRangeStart&displayRangeEnd&timezone',
        resolve: {
          redirect(
            workbenchState,
            $state: ng.ui.IStateService,
            $stateParams: ng.ui.IStateParamsService,
            $q: ng.IQService,
            $location: ng.ILocationService,
            sqUtilities: UtilitiesService,
            sqTrack: TrackService,
            sqItemsApi: ItemsApi) {
            return sqItemsApi.getItemAndAllProperties({ id: $stateParams.viewId })
              .then(({ data: item }) => {
                if (item.type !== API_TYPES.WORKSHEET) {
                  return $q.reject(ROUTE_NO_WORKBOOK);
                }

                // Makes sure that there is an entry in the history for this state. Rejected state transitions don't
                // update the url, so manually setting it is necessary so that the correct url (/view/{id}) is
                // saved
                $location.url($state.href(APP_STATE.VIEW, $stateParams));
                return $q.reject({
                  redirect: {
                    to: APP_STATE.VIEW_WORKSHEET,
                    toParams: {
                      workbookId: item.workbookId,
                      worksheetId: item.id,
                      workstepId: $stateParams.workstepId,
                      displayRangeStart: $stateParams.displayRangeStart,
                      displayRangeEnd: $stateParams.displayRangeEnd,
                      timezone: $stateParams.timezone
                    },
                    options: {
                      location: false
                    }
                  }
                });
              })
              .catch(e => checkForNotFoundOrForbidden($q, ROUTE_NO_WORKBOOK, ROUTE_FORBIDDEN, e));
          }
        }
      })
      .state(APP_STATE.VIEW_WORKSHEET, {
        url: '^/:currentFolderId/view/worksheet/:workbookId/:worksheetId?workstepId&displayRangeStart&displayRangeEnd&timezone&assetId',
        params: {
          currentFolderId: {
            value: null,
            squash: true
          }
        },
        views: {
          'worksheet@workbench': {
            template: worksheetTemplate,
            controller: 'WorksheetCtrl as ctrl'
          }
        },
        resolve: {
          rehydratedState(
            sqNotifications: NotificationsService,
            workbenchState,
            $injector: ng.auto.IInjectorService,
            $stateParams: ng.ui.IStateParamsService,
            $translate: ng.translate.ITranslateService) {

            return loadWorksheet($injector, _.assign({ workbookDisplay: WORKBOOK_DISPLAY.VIEW }, $stateParams))
              .then(() => sqNotifications.info($translate.instant('VIEWING_ONLY.TOOLTIP')));
          }
        }
      })
      .state(APP_STATE.PRESENT_WORKSHEET, {
        url: '/present/worksheet/:workbookId/:worksheetId?workstepId&displayRangeStart&displayRangeEnd&timezone&requestIdPrefix&summaryType&summaryValue&assetId',
        views: {
          'worksheet@workbench': {
            template: worksheetTemplate,
            controller: 'WorksheetCtrl as ctrl'
          }
        },
        resolve: {
          rehydratedState(
            workbenchState,
            $injector: ng.auto.IInjectorService,
            $stateParams: ng.ui.IStateParamsService) {
            return loadWorksheet($injector, _.assign({ workbookDisplay: WORKBOOK_DISPLAY.PRESENT }, $stateParams));
          }
        }
      })
      .state(APP_STATE.ADMINISTRATION, {
        url: '/administration',
        resolve: {
          rehydratedState(
            workbenchState,
            $q: ng.IQService,
            sqWorkbenchStore: WorkbenchStore) {

            if (!sqWorkbenchStore.currentUser.isAdmin) {
              return $q.reject(ROUTE_FORBIDDEN);
            }
          }
        }
      })
      .state(APP_STATE.LICENSE, {
        url: '/license'
      })
      .state(APP_STATE.BUILDER, {
        url: '/workbook/builder?workbookName&worksheetName&trendItems&expandedAsset&assetSwap&viewMode&startFresh&' +
          'investigateStartTime&investigateEndTime&displayStartTime&displayEndTime&selectedTab&workbookFilter',
        data: { title: 'Loading...' },
        params: { trendItems: { array: true } },
        template: buildingTemplate,
        controller: 'BuilderCtrl as $ctrl',
        resolve: {
          initTools($q, sqUtilities, sqWorkbenchStore, sqSystemConfiguration) {
            return $q.resolve()
              .then(() => sqSystemConfiguration.fetch())
              .then(() => sqUtilities.switchLanguage(sqWorkbenchStore.userLanguage));
          }
        }
      })
      .state(APP_STATE.LOAD_ERROR, {
        url: '/load-error?returnState&returnParams',
        data: { title: 'Error' },
        params: {
          header: 'LOAD_ERROR.SERVER_HEADER',
          message1: 'LOAD_ERROR.SERVER_MESSAGE1',
          message2: 'LOAD_ERROR.SERVER_MESSAGE2',
          showSpinner: true,
          retryInterval: 1000
        },
        template: loadErrorTemplate,
        controller: 'LoadErrorCtrl as ctrl'
      })
      .state(APP_STATE.LOGIN, {
        url: '/login?returnState&returnParams&directoryId&autoLogin&code&state&session_state&error&error_description',
        data: { title: 'Login' },
        template: loginTemplate,
        controller: 'LoginCtrl as ctrl',
        resolve: {
          systemConfiguration(sqSystemConfiguration: SystemConfigurationService) {
            return sqSystemConfiguration.fetch();
          },

          /**
           * Requests a list of supported authentication providers.
           */
          availableDomains(sqAuthentication: AuthenticationService) {
            return sqAuthentication.fetchAuthenticationProviders();
          }
        }
      })
      .state(APP_STATE.UNAUTHORIZED, {
        url: '/unauthorized',
        template: unauthorizedTemplate,
        controller: 'UnauthorizedCtrl as $ctrl',
        data: { title: 'Unauthorized' }
      })
      .state(APP_STATE.HEADLESS_CAPTURE_STANDBY, {
        url: '/headless-capture-standby',
        template: '<div></div>',
        data: { title: 'Blank' }
      })
    ;

    // Allow workstep analyzer to be used if not in the production environment
    if (process.env.NODE_ENV !== 'production') {
      $stateProvider
        .state('workstep-analyzer', {
          url: '/tools/workstep-analyzer',
          template: workstepAnalyzerTemplate,
          // @ts-ignore required in this way so that it isn't loaded in the production environment
          controller: require('@/tools/workstepAnalyzer/workstepAnalyzer.controller').default,
          controllerAs: 'ctrl',
          data: { title: 'Workstep Analyzer' },
          resolve: {
            socketOpen(
              sqSocket: SocketService,
              sqWorkbenchStore: WorkbenchStore,
              sqAuthentication: AuthenticationService
            ) {
              return sqSocket.open(sqWorkbenchStore.interactiveSessionId, sqAuthentication.getCsrfToken());
            }
          }
        });
    }

    $httpProvider.defaults.headers.common.Accept = APPSERVER_API_CONTENT_TYPE;
    $httpProvider.defaults.headers.post['Content-Type'] = APPSERVER_API_CONTENT_TYPE;

    $httpProvider.interceptors.push('versionInterceptor');
    $httpProvider.interceptors.push('authenticationInterceptor');
    $httpProvider.interceptors.push('conflictRetryInterceptor');
    $httpProvider.interceptors.push('sessionIdInterceptor');
    $httpProvider.interceptors.push('cancellationInterceptor');
    $httpProvider.interceptors.push('asyncHttpInterceptor');
    $httpProvider.interceptors.push('forbiddenInterceptor');
    $httpProvider.interceptors.push('requestOriginInterceptor');
  })
  .factory('$exceptionHandler', ($injector: ng.auto.IInjectorService) => (error) => {
    const sqLogger = $injector.get<LoggerService>('sqLogger');
    sqLogger.error(sqLogger.format`Unhandled exception: ${error}`);
  })
  .run(function($rootScope: ng.IRootScopeService,
    $state: ng.ui.IStateService,
    $window: ng.IWindowService,
    $exceptionHandler: ng.IExceptionHandlerService,
    $location: ng.ILocationService,
    sqLogger: LoggerService,
    sqTrendActions: TrendActions,
    sqAuthentication: AuthenticationService,
    sqPendingRequests: PendingRequestsService,
    sqWorkbenchActions: WorkbenchActions,
    sqJournalLink: JournalLinkService,
    sqNotifications: NotificationsService,
    sqScreenshot: ScreenshotService,
    sqDurationActions: DurationActions,
    sqSocket: SocketService,
    sqWorkbookActions: WorkbookActions,
    sqBroadcastChannel: BroadcastChannelService,
    sqHttpHelpers: HttpHelpersService,
    sqUtilities: UtilitiesService,
    sqWorkbenchStore: WorkbenchStore
  ) {

    $window.onerror = function(errorMsg, url, lineNumber, colNumber, errObject) {
      if (errObject && errObject.stack) {
        $exceptionHandler(errObject);
      } else {
        $exceptionHandler(new Error(`${errorMsg} in ${url}, line: ${lineNumber}, column: ${colNumber}`));
      }
    };

    $window.onbeforeunload = function() {
      $rootScope.$broadcast('onBeforeUnload');

      cancelRequests(sqWorkbenchStore, sqAuthentication);
      // Force http calls to flush in IE, see http://stackoverflow.com/a/38251551/1108708
      // Only initiate a digest cycle if we aren't already in one
      if (!$rootScope.$$phase) {
        $rootScope.$digest();
      }
    };

    sqSocket.init();

    $rootScope.$on('$locationChangeStart', function(event, newUrl) {
      if (_.isEqual($location.path(), JOURNAL_PREFIX_PATH) && $state.current.name !== '') {
        sqJournalLink.executeLinkAction($location.search());
        event.preventDefault();
      }

      // Circumvent ui-router for links to /content/sourceUrl, since those will redirect to a new location
      if (newUrl.match(/\/api\/content\/.*\/sourceUrl/)) {
        event.preventDefault();
        window.location = newUrl;
      }
    });

    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams, options) {
      // Needed because $state refers to the previous state during transition
      sqWorkbenchActions.setStateParams(toParams);
      // Needed in workbench common resolve because $state is empty during transition
      stateBeingTransitionedTo = toState;
      if (!!$state.transition || sqPendingRequests.hasVolatilePromises) {
        // If we are already transitioning, it is unsafe to start another transition without canceling or otherwise
        // letting the in progress transition finish normally. It isn't safe because loading most states requires
        // asynchronous requests that change global state (the stores), if two transitions are running at the same
        // time then that leads to race conditions concerning the final global state. The only way to effectively
        // cancel the transition is to cause the page to reload. This isn't ideal for the user experience, but it is
        // better than letting state from two worksheets mingle (CRAB-14011)
        // In addition, there are some promise chains in webserver that can continue to run asynchronously
        // even after we switch states, which can result in incorrect data in the toState and the fromState. We
        // reload the page if any of those volatilePromises in progress, which aborts them.
        const href = $state.href(toState.name, toParams);
        sqLogger.warn(`Preventing parallel transition to ${href}, reloading page instead`);
        event.preventDefault();
        $window.location.href = href;
        return;
      }

      if (_.includes([APP_STATE.WORKSHEET, APP_STATE.VIEW_WORKSHEET, APP_STATE.PRESENT_WORKSHEET], fromState.name)) {
        // Cancels pending requests if transitioning away from a worksheet.
        sqPendingRequests.cancelAll();
        sqDurationActions.cleanup();
        // Can't know if they're changing to a worksheet in the same workbook and so always unsubscribe.
        unsubscribeFromWorkbook();
        unsubscribeFromWorkstepChannel();
        unsubscribeFromReportUpdatesChannel();
      }

      if (sqUtilities.headlessRenderMode()) {
        const href = $state.href(toState.name, toParams) || '';
        if (href && !ALLOWED_SCREENSHOT_MODE_PATHS.test(href.replace(/^#!/, ''))) {
          event.preventDefault();
          sqScreenshot.notifyError(`Could not load path not allowed in screenshot render mode: ${href}`);
          return;
        }
      }

      if (toState.name === APP_STATE.LOGIN) {
        if (sqAuthentication.isAuthenticated()) {
          // We are heading to the login page, but we are already authenticated. This can happen if users bookmark
          // the login page instead of the workbench page; redirect to the correct state instead
          event.preventDefault();
          sqUtilities.returnToState(toParams)
            .catch((error) => {
              sqLogger.error(sqLogger.format`Error transitioning directly to previous state from login url: ${error}`);
            });

          return;
        }
      }
    });

    const unregisterErrorHandler = $rootScope.$on('$stateChangeError',
      function(event, toState, toParams, fromState, fromParams, error) {
        event.preventDefault();
        // Unsubscribe from subscription channel.
        unsubscribeFromWorkbook();
        unsubscribeFromWorkstepChannel();
        unsubscribeFromReportUpdatesChannel();
        if (error === ROUTE_NO_WORKBOOK) {
          $state.go(APP_STATE.WORKBOOKS);
        } else if (error === ROUTE_FORBIDDEN) {
          $state.go(APP_STATE.UNAUTHORIZED);
        } else if (error === ROUTE_NO_WORKSHEET) {
          // This handles the case, where a link to a particular worksheet has been saved/bookmarked but that
          // worksheet has been deleted (deleted as in DELETE in api, archived worksheets load).
          // This isn't the greatest behavior because the action the user should take isn't very clear and there is no
          // button to take the user back to the workbench. However, it is a little bit better than having a link
          // that doesn't work and redirects to the homescreen.
          $state.go(APP_STATE.LOAD_ERROR, {
            returnState: APP_STATE.WORKBOOKS,
            returnParams: JSON.stringify({}),
            header: 'LOAD_ERROR.NO_WORKSHEET_HEADER',
            message1: 'LOAD_ERROR.NO_WORKSHEET_MESSAGE1',
            message2: 'LOAD_ERROR.NO_WORKSHEET_MESSAGE2',
            retryInterval: 0
          });
        } else if (_.get(error, 'status') === HttpCodes.UNAUTHORIZED) {
          sqScreenshot.notifyError(sqLogger.format`Invalid auth token detected: ${error}`);
          sqAuthentication.logout(toState, toParams, false);
        } else if (_.isObject(error) && _.isObject(error.redirect)) {
          $state.go(error.redirect.to, error.redirect.toParams, error.redirect.options);
          if (error.redirect.message) {
            sqNotifications.info(error.redirect.message);
          }
        } else {
          if (_.includes([
              '/api/auth/providers', // The login page
              '/api/users/me' // other pages
            ], _.get(error, 'config.url'))
            && _.includes([-1, HttpCodes.NOT_FOUND, HttpCodes.BAD_GATEWAY], _.get(error, 'status'))) {
            // With the status code of the first api call, /api/users/me, we can infer info about webserver and
            // appserver
            sqLogger.info(`Unable to connect to the Server. Most likely ${({
              [-1]: 'webserver is offline',
              [HttpCodes.NOT_FOUND]: 'appserver is online, but not responding to requests yet (might be upgrading)',
              [HttpCodes.BAD_GATEWAY]: 'appserver is offline'
            }[error.status])}`);
          } else {
            sqLogger.error(sqLogger.format`Error transitioning to '${$state.href(toState, toParams)}': ${error}`);
          }

          // If the reason for the failed transition is because one of the requests was canceled then we can go
          // directly back to the state rather than flashing the error screen and waiting for it to redirect them.
          if (sqHttpHelpers.isCanceled(error)) {
            const href = $state.href(toState.name, toParams);
            sqLogger.warn(`Reloading ${href}, since it was canceled while loading`);
            $window.location.href = href;
          } else {
            $state.go(APP_STATE.LOAD_ERROR, {
              returnState: toState.name,
              returnParams: JSON.stringify(toParams)
            }, {
              reload: true
            });
          }
        }
      });

    // Prevent temporarily navigating to the error page if a problem occurs, such as from cancellation, while unloading
    $rootScope.$on('onBeforeUnload', unregisterErrorHandler);

    // Initialize the screenshot callbacks that puppeteer can call
    sqScreenshot.initializeHeadlessCaptureMode();

    // Redirect to the logout page if auth changed by another tab or redirect from the login page if authenticated
    sqBroadcastChannel.subscribe({
      channelId: AUTH_CHANGE_BROADCAST_CHANNEL,
      onMessage() {
        const isAuthenticated = sqAuthentication.isAuthenticated();
        if (isAuthenticated && $state.current.name === APP_STATE.LOGIN) {
          sqUtilities.returnToState()
            .catch((error) => {
              sqLogger.error(sqLogger.format`Error transitioning to previous state: ${error}`);
            });
        } else if (!isAuthenticated && $state.current.name !== APP_STATE.LOGIN) {
          $window.location.href = $state.href(APP_STATE.LOGIN,
            sqUtilities.getReturnToParams($state.current, $state.params));
        }
      }
    });
  });

/**
 * Loads a worksheet using a specified set of parameters.
 *
 * @param  {Object} $injector - Angular DI service
 * @param  {Object} loadOptions - object container for the workbookId, worksheetId, and workbookDisplay
 * @param  {String} loadOptions.workbookId - ID of the workbook to load
 * @param  {String} loadOptions.worksheetId - ID of the worksheet to load
 * @param  {String} [loadOptions.currentFolderId] - ID of the current folder
 * @param  {String} loadOptions.workbookDisplay - one of WORKBOOK_DISPLAY
 * @param  {String} [loadOptions.workstepId] - ID of the workstep to load (defaults to current workstep for worksheet)
 * @param  {String} [loadOptions.displayRangeStart] - ISO-8601 date/time to use for the display range
 * @param  {String} [loadOptions.displayRangeEnd] - ISO-8601 date/time to use for the display range
 * @return {Promise} - Resolves when the worksheet has been loaded and it's state has been rehydrated
 */
function loadWorksheet(
  $injector: ng.auto.IInjectorService,
  loadOptions) {

  loadOptions = _.omitBy(loadOptions, _.isNil); // Remove any optional undefined query parameters
  return $injector.invoke(function(
    $q: ng.IQService,
    $state: ng.ui.IStateService,
    $timeout: ng.ITimeoutService,
    $translate: ng.translate.ITranslateService,
    flux: ng.IFluxService,
    sqStateSynchronizer: StateSynchronizerService,
    sqWorkbookActions: WorkbookActions,
    sqDurationActions: DurationActions,
    sqScreenshot: ScreenshotService,
    sqWorkbook: WorkbookService,
    sqAnnotationActions: AnnotationActions,
    sqAnnotationStore: AnnotationStore,
    sqWorkbookStore: WorkbookStore,
    sqReportActions: ReportActions,
    sqReportStore: ReportStore,
    sqSystemConfiguration: SystemConfigurationService,
    sqTrack: TrackService,
    sqUtilities: UtilitiesService,
    sqWorksheets: WorksheetsService,
    sqWorksheetActions: WorksheetActions,
    sqLogger: LoggerService,
    sqAuthorization: AuthorizationService,
    sqSocket: SocketService,
    sqHomeScreenUtilities: HomeScreenUtilitiesService,
    sqInvestigateActions: InvestigateActions) {

      const workbookLoadNeeded =
        // Reload because workbook is changed
        loadOptions.workbookId !== _.get($state.params, 'workbookId')
        // also reload if last state is not APP_STATE.WORKSHEET (e.g. coming from home screen)
        || $state.current.name !== APP_STATE.WORKSHEET;

      return $q.resolve()
        .then(() => {
          return workbookLoadNeeded
            ? sqWorkbookActions.load(loadOptions.workbookId, loadOptions.workbookDisplay)
              .catch(e => checkForNotFoundOrForbidden($q, ROUTE_NO_WORKBOOK, ROUTE_FORBIDDEN, e))
            // $timeout is used here to avoid that the whole block here is executed in one dispatch cycle.
            // see
            // https://bitbucket.org/seeq12/crab/pull-requests/9365/crab-19353-suppress-workbook-load-when#comment-145759312
            // for a detailed explanation why
            : $timeout(() => sqWorkbookStore.workbook, 0);
        })
        .then((workbook) => {
          if (loadOptions.workbookDisplay === WORKBOOK_DISPLAY.EDIT &&
            (!sqAuthorization.canWriteItem(workbook) || workbook.isArchived)) {
            // This is the only case where the write permission in the response needs to be checked manually so we
            // can redirect to the view-only worksheet view. All other cases will be handled by the $stateChangeError
            // ROUTE_FORBIDDEN error handler which redirects to the unauthorized route.
            return $q.reject({
              redirect: {
                to: APP_STATE.VIEW_WORKSHEET,
                toParams: {
                  workbookId: loadOptions.workbookId,
                  worksheetId: loadOptions.worksheetId,
                  workstepId: loadOptions.workstepId,
                  currentFolderId: loadOptions.currentFolderId || ''
                },
                // location: 'replace' ensures the browser history gets overwritten and prevents CRAB-15738
                options: { location: 'replace' },
                message: $translate.instant('REDIRECT_WORKSHEET')
              }
            });
          }

          if (!sqUtilities.headlessRenderMode()) {
            sqHomeScreenUtilities.updateOpenedAt(loadOptions.workbookId);
          }
          return workbook;
        })
        .then(() => {
          // If coming from a worksheet then flush any state changes that have not yet been pushed because they
          // were debounced. Note that this check is possible because $state.params points to PREVIOUS params
          if ($state.current.name === APP_STATE.WORKSHEET) {
            return sqStateSynchronizer.push(PUSH_WORKSTEP_IMMEDIATE,
              _.pick($state.params, ['workbookId', 'worksheetId']));
          }
        })
        // If a workbook somehow ends up with no worksheets (and thus no worksheet ID), add a worksheet to avoid an
        // error state.
        .then(() => {
          if (!loadOptions.worksheetId) {
            return sqWorksheets.getWorksheets(loadOptions.workbookId)
              .then(worksheets => _.head(worksheets) || sqWorksheets.createWorksheet(loadOptions.workbookId, '1'))
              .then(worksheet => $q.reject({
                redirect: {
                  to: loadOptions.workbookDisplay === WORKBOOK_DISPLAY.EDIT
                    ? APP_STATE.WORKSHEET
                    : APP_STATE.VIEW_WORKSHEET,
                  toParams: {
                    workbookId: loadOptions.workbookId,
                    worksheetId: worksheet.worksheetId,
                    currentFolderId: loadOptions.currentFolderId || ''
                  },
                  // location: 'replace' ensures the browser history gets overwritten and prevents CRAB-15738
                  options: { location: 'replace' }
                }
              }));
          }
        })
        // The location of this line is important, because it means that all flux.dispatch calls for the old
        // worksheet must have completed, including any that are done as part of $onDestroy or timeouts which do not
        // happen until the sqWorkbookActions.load() finishes. It must be before rehydration starts, however, to ensure
        // that all subsequent flux.dispatch calls will apply to the new worksheet.
        .then(() => sqStateSynchronizer.setLoadingWorksheet(loadOptions.workbookId, loadOptions.worksheetId))
        .then(() => sqWorkbook.get(loadOptions.workbookId))
        .then(workbookState => sqStateSynchronizer.rehydrate(workbookState, {
          persistenceLevel: PERSISTENCE_LEVEL.WORKBOOK,
          initializeMode: INITIALIZE_MODE.FORCE,
          workbookId: loadOptions.workbookId,
          worksheetId: loadOptions.worksheetId
        }))
        .then(() => loadWorkstep($injector, loadOptions))
        .then(() => {
          const loadAddOnTools = sqSystemConfiguration.addOnToolsEnabled && workbookLoadNeeded
          && !sqWorkbookStore.isReportBinder && loadOptions.workbookDisplay !== WORKBOOK_DISPLAY.PRESENT
            ? () => sqInvestigateActions.loadAddOnTools()
            : () => $q.resolve();
          return $q.all([
            sqAnnotationActions.fetchAnnotations(loadOptions.workbookId, loadOptions.worksheetId),
            // Load add-on tools after we have rehydrated the workstep and in parallel with annotations to save time
            loadAddOnTools()
          ]);
        })
        .then(() => {
          if (!sqUtilities.headlessRenderMode()) {
            // Subscribe to this workbook's subscription channel to get updates about who is viewing this workbook.
            // This is a low priority subscription, so we should not wait for it to complete nor should we fail if it
            // doesn't succeed.
            const unsubscribeMethod = sqSocket.subscribe({
              channelId: [
                SeeqNames.Channels.WorkbookChannel,
                loadOptions.workbookId,
                'subscriptions'
              ],
              onMessage: sqWorkbookActions.setViewers,
              subscriberParameters: {
                worksheetId: loadOptions.worksheetId,
                worksheetDisplay: loadOptions.workbookDisplay
              }
            });

            unsubscribeFromWorkbook = () => {
              unsubscribeMethod();
              // The workbook store had an out-of-date list of viewers when transitioning workbooks. This clears the
              // viewers so that the user doesn't see off-by-one user counts on the worksheet they're coming from.
              flux.dispatch('WORKBOOK_SET_VIEWERS', []);
            };

            // Subscribe to this worksheet's workstep channel to follow worksteps.
            unsubscribeFromWorkstepChannel = sqSocket.subscribe({
              channelId: [
                SeeqNames.Channels.WorkstepChannel,
                loadOptions.worksheetId
              ],
              onMessage: sqStateSynchronizer.onWorkstep
            });

            // Subscribe to this reports update channel to follow report updates.
            if (sqWorkbookStore.isReportBinder) {
              unsubscribeFromReportUpdatesChannel = sqSocket.subscribe({
                channelId: [
                  SeeqNames.Channels.ReportUpdateChannel,
                  loadOptions.worksheetId
                ],
                onMessage: sqReportActions.debouncedOnReport,
                onClose: event => sqReportActions.setIsOffline(true),
                onSubscribe: () => sqReportActions.setIsOffline(false)
              });
            }
          }

          if (sqWorkbookStore.isReportBinder) {
            // Try to get the worksheet from the workbook store. It should be there unless it has been trashed.
            const worksheet = _.find(sqWorkbookStore.worksheets, ['worksheetId', loadOptions.worksheetId]);

            // The worksheet can be undefined if a user accesses the topic document using a shared URL after the
            // document has been trashed from the topic. Normally we can avoid an extra call to the backend to get
            // the worksheet but in the case where is has been trashed we need to make it because we want the
            // trashed document to still be viewable via the URL.
            return $q.resolve(
              _.isNil(worksheet)
                ? sqWorksheets.getWorksheet(loadOptions.workbookId, loadOptions.worksheetId, true)
                : worksheet)
              .then(({ reportId }) => (sqReportActions.load(reportId) as any)
                .then(() => sqWorkbookActions.setWorksheetProperty(loadOptions.worksheetId, 'reportId', reportId)));
          } else {
            if (!sqUtilities.headlessRenderMode() && loadOptions.workbookDisplay === WORKBOOK_DISPLAY.EDIT &&
              !sqAnnotationStore.findJournalEntries(sqAnnotationStore.annotations, loadOptions.worksheetId).length) {
              return sqAnnotationActions.save({
                workbookId: loadOptions.workbookId,
                worksheetId: loadOptions.worksheetId,
                name: $translate.instant('UNNAMED')
              });
            }
          }
        })
        .then(() => sqAnnotationActions.displayNewOrExisting(loadOptions.worksheetId))
        .then(() => {
          const displayMode = _.last(loadOptions.workbookDisplay.split('.'));
          if (sqWorkbookStore.isReportBinder) {
            const liveDoc = sqReportStore.hasLiveContent;
            sqTrack.doTrack('Topic', 'Opened',
              `mode=${displayMode}, liveDoc=${liveDoc}, workbookId=${loadOptions.workbookId}, worksheetId=${loadOptions.worksheetId}`);
          } else {
            sqTrack.doTrack('Analysis', 'Opened',
              `mode=${displayMode}, workbookId=${loadOptions.workbookId}, worksheetId=${loadOptions.worksheetId}`);
          }
        })
        .then(sqScreenshot.notifyLoading)
        .then(sqDurationActions.autoUpdate.initialize)
        .then(sqScreenshot.notifyCapture)
        .catch((error) => {
          sqScreenshot.notifyError(sqLogger.format`Could not load worksheet: ${error}`);
          return $q.reject(error);
        })
        .finally(() => {
          sqStateSynchronizer.unsetLoadingWorksheet();
        });
    }
  );
}

/**
 * Loads the  workstep for a worksheet.
 *
 * @param  {Object} $injector - Angular DI service
 * @param  {Object} loadOptions - object container for parameters
 * @param  {String} loadOptions.workbookId - ID of the workbook to load
 * @param  {String} loadOptions.worksheetId - ID of the worksheet to load
 * @param  {String} loadOptions.workbookDisplay - one of WORKBOOK_DISPLAY
 * @param  {String} [loadOptions.workstepId] - ID of the workstep to load otherwise the current workstep will be used
 * @param  {String} [loadOptions.displayRangeStart] - ISO-8601 date/time to use for the display range
 * @param  {String} [loadOptions.displayRangeEnd] - ISO-8601 date/time to use for the display range
 * @param  {String} [loadOptions.summaryType] - Fixed or Auto
 * @param  {String} [loadOptions.summaryValue] - Either a FrontendDuration-esque string, or an arbitrary value
 * @param  {String} [loadOptions.assetId] - ID for the asset to select on
 * @return {Promise} - Resolves when the workstep has been loaded and its state has been rehydrated
 */
function loadWorkstep(
  $injector: ng.auto.IInjectorService,
  loadOptions) {

  return $injector.invoke(function(
    $q: ng.IQService,
    $state: ng.ui.IStateService,
    sqStateSynchronizer: StateSynchronizerService,
    sqSearchResultService: SearchResultUtilitiesService,
    sqItemsApi: ItemsApi,
    sqTreesApi: TreesApi,
    sqWorkstepsActions: WorkstepsActions,
    sqTrendActions: TrendActions,
    sqLogger: LoggerService,
    sqDateTime: DateTimeService,
    sqDurationActions: DurationActions,
    sqWorkbookActions: WorkbookActions,
    sqWorksheetActions: WorksheetActions) {

    // Builder takes care of loading the workstep and in view mode the workstep is ephemeral
    if ($state.current.name === APP_STATE.BUILDER) {
      return $q.resolve();
    }
    return $q.resolve()
      .then(() => {
        if (loadOptions.workstepId) {
          return sqWorkstepsActions.get(loadOptions.workbookId, loadOptions.worksheetId, loadOptions.workstepId);
        } else {
          return sqWorkstepsActions.current(loadOptions.workbookId, loadOptions.worksheetId);
        }
      })
      .catch(e => checkForNotFoundOrForbidden($q, ROUTE_NO_WORKSHEET, ROUTE_FORBIDDEN, e))
      .then(response => _.get(response, 'current.state'))
      .catch((e) => {
        if (e !== WORKSHEET_WITH_NO_WORKSTEPS) {
          return $q.reject(e);
        }

        // If a worksheet does not have any worksteps then we create an initial empty one. This allows the
        // user to hit the previous button and eventually return to a blank slate rather than their first
        // action.
        sqStateSynchronizer.initialize(PERSISTENCE_LEVEL.WORKSHEET);
        return sqStateSynchronizer.push(PUSH_WORKSTEP_IMMEDIATE, _.pick(loadOptions, ['workbookId', 'worksheetId']))
          .then(() => sqWorkstepsActions.current(loadOptions.workbookId, loadOptions.worksheetId))
          .then(response => _.get(response, 'current.state'));
        // Note that we don't call rehydrate again because the state is already accurate because of the call to
        // initialize
      })
      .then((workstepState) => {
        // The worksheet will show a spinner until the promise returned resolved. Don't return the promise here so
        // that all the data on the chart will instead load asynchronously. The stores' state will be restored
        // synchronously, but data will be fetched from the server in the background
        sqStateSynchronizer.rehydrate(workstepState, {
            beforeFetch,
            workbookId: loadOptions.workbookId,
            worksheetId: loadOptions.worksheetId
          })
          .catch((error) => {
            sqLogger.error(sqLogger.format`Error while rehydrating workstep: ${error}`);
          });

        function beforeFetch() {
          return $q.resolve()
            .then(() => {
              const displayStart = _.get(loadOptions, 'displayRangeStart');
              const displayEnd = _.get(loadOptions, 'displayRangeEnd');
              const timezone = _.get(loadOptions, 'timezone');
              const summaryType = _.get(loadOptions, 'summaryType');
              const summaryValue = _.get(loadOptions, 'summaryValue');
              const assetId = _.get(loadOptions, 'assetId');

              if (displayStart && displayEnd) {
                sqDurationActions.displayRange.setParamsInStore(displayStart, displayEnd);
                // Override investigate range as well to avoid it being completely disconnected from the display range
                sqDurationActions.investigateRange.copyFromDisplayRange();
                // Override auto-update to ensure it doesn't override the hard-coded date range given in the URL
                sqDurationActions.autoUpdate.setMode(AUTO_UPDATE.MODES.OFF);
              }

              if (timezone) {
                sqWorksheetActions.setTimezone({ name: timezone });
              }

              if (summaryType && summaryValue) {
                const discreteUnits = sqDateTime.splitDuration(summaryValue);
                const value = discreteUnits ?
                  _.findKey(SummarySliderValues[SummaryTypeEnum.DISCRETE].value, discreteUnits) : summaryValue;
                const discrete: FrontendDuration = discreteUnits ?? { value: 0, units: 'min' };
                sqTrendActions.setSummary({ type: summaryType, value, discreteUnits: discrete, isSlider: true }, false);
              }

              if (assetId) {
                sqTreesApi.getTree({ id: assetId })
                  .then(({ data: tree }) => sqSearchResultService.swapAsset(tree.item));
              }

              if (loadOptions.workbookDisplay !== WORKBOOK_DISPLAY.EDIT) {
                sqWorkbookActions.resetViewingState();
              }
            });
        }
      });
  });
}

/**
 * Helper function that checks if a request returned a NOT_FOUND, BAD_REQUEST, or FORBIDDEN and, if so, returns the
 * the specified route constant which allows the stateChangeError handler to respond correctly. BAD_REQUEST is checked
 * because the backend will return that status code if the guid in the URL is missing or malformed.
 *
 * @param {Object} $q - Angular's promise library
 * @param {String} routeConstantNotFound - Constant to return if response status is NOT_FOUND or BAD_REQUEST
 * @param {String} routeConstantForbidden - Constant to return if response status is FORBIDDEN
 * @param {Object} response - The error response
 * @returns {Promise} that rejects with either the routeConstant {String} or the original error {Object}
 */
function checkForNotFoundOrForbidden($q: ng.IQService, routeConstantNotFound, routeConstantForbidden, response) {

  const isForbidden = response.status === HttpCodes.FORBIDDEN;
  const isNotFoundOrBadRequest = _.includes([HttpCodes.NOT_FOUND, HttpCodes.BAD_REQUEST], response.status);

  if (isForbidden) {
    return $q.reject(routeConstantForbidden);
  } else if (isNotFoundOrBadRequest) {
    return $q.reject(routeConstantNotFound);
  } else {
    return $q.reject(response);
  }
}

/**
 * Helper function that cancels all the requests for a user's specific session
 *
 * @param {Object} sqWorkbenchStore - the workbench store
 * @param {Object} sqAuthentication - the authentication service
 */
function cancelRequests(sqWorkbenchStore: WorkbenchStore, sqAuthentication: AuthenticationService) {
  const headers = new Headers({
    [SeeqNames.API.Headers.Csrf]: sqAuthentication.getCsrfToken(),
    Accept: 'application/vnd.seeq.v1+json'
  });

  const sessionId = sqWorkbenchStore.interactiveSessionId;
  // Fetch is being used here, with the keepalive flag set to true, since several browsers no longer support XHR during
  // the onbeforeunload event
  fetch(APPSERVER_API_PREFIX + '/requests/me/' + sessionId, { method: 'DELETE', keepalive: true, headers });
}

export const ROUTE_NO_WORKBOOK = 'ROUTE_NO_WORKBOOK';
export const ROUTE_NO_WORKSHEET = 'ROUTE_NO_WORKSHEET';
export const ROUTE_FORBIDDEN = 'ROUTE_FORBIDDEN';
