import _ from 'lodash';
import angular from 'angular';
import { CapsuleGroupActions } from '@/investigate/customCondition/capsuleGroup.actions';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { TrendActions } from '@/trendData/trend.actions';
import { InvestigateStore } from '@/investigate/investigate.store';
import { UtilitiesService } from '@/services/utilities.service';
import { DerivedDataTreeService } from '@/investigate/derivedDataTree.service';
import { ValueSearchStore } from '@/trendData/valueSearch.store';
import { ProfileSearchStore } from '@/hybrid/tools/profileSearch/profileSearch.store';
import { SignalFromConditionStore } from '@/hybrid/tools/signalFromCondition/signalFromCondition.store';
import { CompositeSearchStore } from '@/hybrid/tools/compositeSearch/compositeSearch.store';
import { PredictionPanelStore } from '@/hybrid/tools/prediction/predictionPanel.store';
import { ReferencePanelStore } from '@/hybrid/tools/referencePanel/referencePanel.store';
import { AggregationBinStore } from '@/investigate/aggregationBins/aggregationBin.store';
import { FormulaToolStore } from '@/hybrid/tools/formula/formulaTool.store';
import { WorkbookStore } from '@/workbook/workbook.store';
import { FftStore } from '@/hybrid/tools/frequencyAnalysis/fft.store';
import { ItemPropertiesStore } from '@/investigate/itemProperties/propertiesPanel.store';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { ExportODataPanelStore } from '@/investigate/exportOData/exportODataPanel.store';
import { DigitalFilterStore } from '@/hybrid/tools/digitalFilter/digitalFilter.store';
import {
  API_TYPES,
  APPSERVER_API_PREFIX,
  DISPLAY_MODE,
  EDIT_MODE,
  MAX_NAME_LENGTH
} from '@/main/app.constants';
import { InvestigateToolType, PREVIEW_TREND_TOOLS, TREND_TOOLS } from '@/investigate/investigate.module';
import { TREND_STORES } from '@/trendData/trendData.module';
import { PUSH_IGNORE } from '@/services/stateSynchronizer.service';
import { NotificationsService } from '@/services/notifications.service';
import { RedactionService } from '@/services/redaction.service';
import { HttpHelpersService } from '@/services/httpHelpers.service';
import { ImportDatafileActions } from '@/hybrid/tools/importDatafile/importDatafile.actions';
import { ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';
import { ScatterConditionStore } from './scatterCondition/scatterCondition.store';
import { SignalSmoothingStore } from '@/hybrid/tools/signalSmoothing/signalSmoothing.store';
import { ItemsApi, SystemApi } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { WorkstepsStore } from '@/worksteps/worksteps.store';
import { SEEQ_VERSION } from '@/services/buildConstants.service';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { ConfigUpgraderService } from '@/services/configUpgrader.service';
import { sqManualSignalStore } from '@/hybrid/tools/manualSignal/manualSignal.store';
import { FormulaService } from '@/services/formula.service';

/**
 * Service providing investigate actions
 */
angular.module('Sq.Investigate').service('sqInvestigateActions', sqInvestigateActions);
export type InvestigateActions = ReturnType<typeof sqInvestigateActions>;

export const DEFAULT_WINDOW_DETAILS = 'toolbar=1,location=0,scrollbars=1,statusbar=1,menubar=1,resizable=1';

function sqInvestigateActions(
  $q: ng.IQService,
  $http: ng.IHttpService,
  $injector: ng.auto.IInjectorService,
  $sce: ng.ISCEService,
  $window: ng.IWindowService,
  $state: ng.ui.IStateService,
  flux: ng.IFluxService,
  sqWorksheetActions: WorksheetActions,
  sqTrendActions: TrendActions,
  sqScatterPlotActions: ScatterPlotActions,
  sqInvestigateStore: InvestigateStore,
  sqUtilities: UtilitiesService,
  sqNotifications: NotificationsService,
  sqTrendDataHelper: TrendDataHelperService,
  sqDerivedDataTree: DerivedDataTreeService,
  sqValueSearchStore: ValueSearchStore,
  sqProfileSearchStore: ProfileSearchStore,
  sqSignalFromConditionStore: SignalFromConditionStore,
  sqScatterConditionStore: ScatterConditionStore,
  sqSignalSmoothingStore: SignalSmoothingStore,
  sqCompositeSearchStore: CompositeSearchStore,
  sqReferencePanelStore: ReferencePanelStore,
  sqPredictionPanelStore: PredictionPanelStore,
  sqAggregationBinStore: AggregationBinStore,
  sqFormulaToolStore: FormulaToolStore,
  sqWorkbookStore: WorkbookStore,
  sqItemPropertiesStore: ItemPropertiesStore,
  sqCapsuleGroupActions: CapsuleGroupActions,
  sqHttpHelpers: HttpHelpersService,
  sqRedaction: RedactionService,
  sqFftStore: FftStore,
  sqExportODataPanelStore: ExportODataPanelStore,
  sqDigitalFilterStore: DigitalFilterStore,
  sqFormula: FormulaService,
  sqItemsApi: ItemsApi,
  sqSystemApi: SystemApi,
  sqWorkstepsStore: WorkstepsStore,
  sqWorkbenchStore: WorkbenchStore,
  sqConfigUpgrader: ConfigUpgraderService
) {
  const TOOL_STORES = {};
  TOOL_STORES[TREND_TOOLS.SIGNAL_SMOOTHING] = sqSignalSmoothingStore;
  TOOL_STORES[TREND_TOOLS.VALUE_SEARCH] = sqValueSearchStore;
  TOOL_STORES[TREND_TOOLS.PROFILE_SEARCH] = sqProfileSearchStore;
  TOOL_STORES[TREND_TOOLS.SIGNAL_FROM_CONDITION] = sqSignalFromConditionStore;
  TOOL_STORES[TREND_TOOLS.COMPOSITE_SEARCH] = sqCompositeSearchStore;
  TOOL_STORES[TREND_TOOLS.REFERENCE] = sqReferencePanelStore;
  TOOL_STORES[TREND_TOOLS.PREDICTION] = sqPredictionPanelStore;
  TOOL_STORES[TREND_TOOLS.AGGREGATION_BINS_TABLE] = sqAggregationBinStore;
  TOOL_STORES[TREND_TOOLS.FORMULA] = sqFormulaToolStore;
  TOOL_STORES[TREND_TOOLS.FFT_TABLE] = sqFftStore;
  TOOL_STORES[TREND_TOOLS.PROPERTIES] = sqItemPropertiesStore;
  TOOL_STORES[TREND_TOOLS.EXPORT_ODATA] = sqExportODataPanelStore;
  TOOL_STORES[TREND_TOOLS.DIGITAL_FILTER] = sqDigitalFilterStore;
  TOOL_STORES[TREND_TOOLS.MANUAL_SIGNAL] = sqManualSignalStore;
  const addOnToolWindows = {};

  const service = {
    close,
    setActiveTool,
    setToolFilter,
    setItem,
    setNonStoreItem,
    clearItem,
    setDisplayMode,
    setSearchName,
    setParameterItem,
    unsetParameterItem,
    loadToolForEdit,
    setMaximumDuration,
    setAdvancedParametersCollapsedState,
    setDerivedDataPanelOpen,
    updateDerivedDataTree: sqDerivedDataTree.update,
    hasValidName,
    loadAddOnTools,
    // Exposed for test
    executeAddOnTool
  };

  return service;

  /**
   * Sets the active tool.
   *
   * @param {String} toolId - the ID of the tool to display. Must be one of INVESTIGATE_TOOLS.
   * @param {String} [mode] - One of DISPLAY_MODE. If not provided it uses the tool's
   *   defaultDisplayMode, if set, or falls back to DISPLAY_MODE.NEW
   * @param {Boolean} [changeTab] - If true changes to the investigate tab
   */
  function setActiveTool(toolId, mode?, changeTab = true) {
    const tool = _.find(sqInvestigateStore.allTools, ['id', toolId]) as any;
    if (!tool) {
      throw new TypeError(toolId + ' is not a valid tool');
    }

    sqTrendActions.setEditModeForSeries(null);
    sqTrendActions.setEditModeForCapsuleSet(null);
    sqWorksheetActions.setBrowsePanelCollapsed(false);
    sqCapsuleGroupActions.clearCapsules();
    if (!sqWorkbookStore.isReportBinder && changeTab) {
      sqWorksheetActions.tabsetChangeTab('sidebar', 'investigate');
    }

    if (tool.type !== SeeqNames.Types.AddOnTool) {
      flux.dispatch('INVESTIGATE_SET_ACTIVE_TOOL', { tool: toolId });
      service.setDisplayMode(mode || tool.defaultDisplayMode || DISPLAY_MODE.NEW);
    } else {
      service.executeAddOnTool(tool);
    }
  }

  /**
   * Execute the action specified by the add-on tool linkType property
   */
  function executeAddOnTool(tool: InvestigateToolType) {
    const targetUrl = prepareTargetUrl(tool.targetUrl);

    switch (tool.linkType) {
      case SeeqNames.API.AddOnToolLinkType.Window:
        const details = !_.isEmpty(tool.windowDetails) ? tool.windowDetails : DEFAULT_WINDOW_DETAILS;
        if (!!tool.reuseWindow) {
          const windowName = `${sqWorkbenchStore.interactiveSessionId}_${_.snakeCase(tool.name)}`;
          focusOrOpenNamedWindow(targetUrl, windowName, details);
        } else {
          $window.open(targetUrl, '_blank', details);
        }
        break;
      case SeeqNames.API.AddOnToolLinkType.Tab:
        $window.open(targetUrl);
        break;
      case SeeqNames.API.AddOnToolLinkType.None:
        $http.get(targetUrl);
        break;
      default:
        throw new TypeError(`${tool.linkType} is not a valid linkType`);
    }
  }

  /**
   * Parameterize and sanitize target URL
   */
  function prepareTargetUrl(url: string) {
    const parameterizedUrl = _.chain(url)
      .replace('{workbookId}', $state.params.workbookId)
      .replace('{worksheetId}', $state.params.worksheetId)
      .replace('{workstepId}', sqWorkstepsStore.current.id)
      .replace('{seeqVersion}', SEEQ_VERSION)
      .value();

    return $sce.trustAsResourceUrl(parameterizedUrl);
  }

  /**
   * If the named add-on tool window is open and we have a reference to it then set focus to the window. Otherwise
   * open a new named add-on tool window.
   */
  function focusOrOpenNamedWindow(url, windowName, details) {
    if (_.isUndefined(addOnToolWindows[windowName]) || _.get(addOnToolWindows[windowName], 'closed')) {
      addOnToolWindows[windowName] = $window.open(url, windowName, details);
    } else {
      addOnToolWindows[windowName].focus();
    }
  }

  /**
   * Sets the text to use to filter the available tools and categories
   *
   * @param {String} filter - The string by which to filter the list of tools
   */
  function setToolFilter(filter) {
    flux.dispatch('INVESTIGATE_SET_TOOL_FILTER', { filter });
  }

  /**
   * Sets the item that is being investigated using one or more of the tools.
   *
   * @param {String|Object} idOrItem - The ID of the item to be investigated or an actual item
   * @param {Object} [props] - Additional properties to associate with the item. Useful for passing along additional
   *   information to the panel that loads the item.
   */
  function setItem(idOrItem, props?) {
    const item = _.isObject(idOrItem) ? idOrItem : sqTrendDataHelper.findItemIn(TREND_STORES, idOrItem);
    if (!item) {
      service.clearItem();
    } else {
      flux.dispatch('INVESTIGATE_SET_ITEM', { item, props });
    }
  }

  /**
   * Sets an item which does not exist in a store as the investigate item.
   *
   * @param {object} item - the item
   */
  function setNonStoreItem(item) {
    flux.dispatch('INVESTIGATE_SET_ITEM', { item });
  }

  /**
   * Clears the item being investigated.
   */
  function clearItem() {
    flux.dispatch('INVESTIGATE_CLEAR_ITEM');
  }

  /**
   * Sets the display mode.
   *
   * @param {String} mode - One of DISPLAY_MODE
   * @param {String} [option] - Option for dispatch, such as PUSH_IGNORE
   */
  function setDisplayMode(mode, option?) {
    option = _.isUndefined(option) && mode === DISPLAY_MODE.IN_PROGRESS ? PUSH_IGNORE : undefined;
    if (!_.includes(_.values(DISPLAY_MODE), mode)) {
      throw new TypeError(mode + ' is not a valid mode');
    }

    flux.dispatch('INVESTIGATE_SET_DISPLAY_MODE', { mode, type: sqInvestigateStore.activeTool }, option);
  }

  /**
   * Sets the search name.
   *
   * @param {String} tool - The name of the tool, one of TREND_TOOLS.
   * @param {String} name - The name for the search.
   */
  function setSearchName(tool, name) {
    flux.dispatch('TOOL_SET_SEARCH_NAME', { type: tool, name }, PUSH_IGNORE);
  }

  /**
   * Sets the state of the expandable advanced parameters section.
   *
   * @param {String} type - The type of the tool, one of TREND_TOOLS.
   * @param {Boolean} collapsed - True if the advanced section is collapsed.
   */
  function setAdvancedParametersCollapsedState(type, collapsed) {
    flux.dispatch('TOOL_SET_ADVANCED_PARAMETERS_COLLAPSED_STATE', { type, collapsed });
  }

  /**
   * Sets the derived data panel open state
   *
   * @param {Boolean} derivedDataPanelOpen - True if open; false for closed.
   */
  function setDerivedDataPanelOpen(derivedDataPanelOpen) {
    flux.dispatch('INVESTIGATE_SET_DERIVED_DATA_PANEL_OPEN', { derivedDataPanelOpen });
  }

  /**
   * Load, format, and persist add-on tools array
   */
  function loadAddOnTools() {
    return sqSystemApi.getAddOnTools()
      .then(({ data }) => {
        const addOnTools = _.chain(data.addOnTools)
          .map(et => _.omit(et, ['href', 'effectivePermissions', 'isArchived']))
          .map(et => _.assign(et, { parentId: TREND_TOOLS.ADDON_TOOLS_GROUP }))
          .value();

        flux.dispatch('INVESTIGATE_SET_ADDON_TOOLS', { addOnTools });
      })
      .catch(sqNotifications.apiError);
  }

  /**
   * Sets or adds, if multiple, the item for one of the parameters used in the formula.
   *
   * @param {String} type - The type of the tool, one of TREND_TOOLS.
   * @param {String} name - The name of the parameter
   * @param {Object|undefined} item - The item. Undefined is supported for single item parameters since that is what
   * sq-select-item passes when an item is unselected. Multi-select must use the onRemove() callback along with
   * unsetParameterItem.
   */
  function setParameterItem(type, name, item) {
    flux.dispatch('TOOL_SET_PARAMETER_ITEM', { type, name, item });
  }

  /**
   * Unsets or removes, if multiple, the item for one of the parameters used in the formula. Only useful for
   * parameters that are an array of items and used in conjunction with the multi-select functionality of
   * sq-select-item.
   *
   * @param {String} type - The type of the tool, one of TREND_TOOLS.
   * @param {String} name - The name of the parameter
   * @param {Object} item - The item to unset
   */
  function unsetParameterItem(type, name, item) {
    flux.dispatch('TOOL_UNSET_PARAMETER_ITEM', { type, name, item });
  }

  /**
   * Sets the maximum duration that a capsule can be; used when creating a new capsule set.
   *
   * @param {String} type - The type of the tool, one of TREND_TOOLS.
   * @param {Number} value - The number that indicates how long the duration is
   * @param {String} units - The units that the value represents
   */
  function setMaximumDuration(type, value, units, valid?) {
    flux.dispatch('TOOL_SET_MAXIMUM_DURATION', { type, value, units, valid });
  }

  /**
   * Closes the active tool, returning the UI to either the Overview or the investigate item view.
   */
  function close() {
    service.clearItem();
    service.setActiveTool(TREND_TOOLS.OVERVIEW, DISPLAY_MODE.NEW, false);
  }

  /**
   * Retrieves the calculation from the API and dispatches all necessary requests to re-populate the edit form for
   * the calculation.
   *
   * @param {String} id - The ID of the calculated item
   * @param {EDIT_MODE} [editMode=EDIT_MODE.NORMAL] - Duplicate the calculated item instead of loading it, either to
   *   Formula or the same tool as it already is in
   * @returns {Promise} that resolves once the calculation is retrieved
   */
  function loadToolForEdit(id, editMode = EDIT_MODE.NORMAL) {
    const item = sqTrendDataHelper.findItemIn(TREND_STORES, id);
    // For signals/conditions created by a Datafile, it is the Datafile that must be edited
    if (_.get(item, 'calculationType') === TREND_TOOLS.IMPORTDATAFILE) {
      $injector.get<ImportDatafileActions>('sqImportDatafileActions').loadDatafile(_.get(item, 'assets[0].id', id));
      return $q.resolve();
    }

    return sqItemsApi.getItemAndAllProperties({ id })
      .then(({ data: item }) => {
        sqTrendActions.setTrendItemProps(item.id, { scopedTo: item.scopedTo }, PUSH_IGNORE);
        if (!sqUtilities.isUserCreatedType(item.type)) {
          return $q.reject('Only user-created items can be loaded into a tool for edit.');
        }

        // Fetch the tool item formula and parameters
        return $http.get(APPSERVER_API_PREFIX + sqUtilities.getHref(item))
          .then(response => response.data as any)
          // Add swap source to those parameter items that are swaps
          .then(toolItem => _.chain(getFormulaParameters(toolItem))
            .reject('unbound')
            .map((parameter: any) => sqItemsApi.getItemAndAllProperties({ id: parameter.item.id })
              .then(({ data: { properties } }) => {
                const swapSourceId = _.chain(properties)
                  .find(['name', SeeqNames.Properties.SwapSourceId])
                  .get('value')
                  .toUpper()
                  .thru(value => _.isEmpty(value) ? undefined : value)
                  .value();
                const valueUnitOfMeasure = _.chain(properties)
                  .find(['name', SeeqNames.Properties.ValueUom])
                  .get('value')
                  .value();
                const numberFormat = _.chain(properties)
                  .find(['name', SeeqNames.Properties.NumberFormat])
                  .get('value')
                  .value();
                return {
                  ...parameter,
                  item: {
                    ...sqUtilities.decorateItemWithProperties(_.omitBy({
                      ...parameter.item,
                      properties,
                      swapSourceId,
                      valueUnitOfMeasure,
                      numberFormat
                    }, _.isNil)),
                    assets: [sqHttpHelpers.getAssetFromAncestors(parameter.item.ancestors)]
                  }
                };
              })
              .catch((error) => {
                if (sqRedaction.isForbidden(error)) {
                  return {
                    ...parameter,
                    item: {
                      ...parameter.item,
                      redacted: true
                    }
                  };
                }

                return $q.reject(error);
              }))
            .thru(promises => $q.all(promises))
            .value()
            .then(parameters => [{ ...item, formula: toolItem.formula, parameters }, toolItem])
          );
      })
      .then(([item, toolItem]) => {
        const uiConfig = _.find(item.properties, ['name', SeeqNames.Properties.UIConfig]) as any;
        let config = editMode !== EDIT_MODE.COPY_TO_FORMULA && uiConfig ? JSON.parse(uiConfig.value) : { id };
        config.type = editMode !== EDIT_MODE.COPY_TO_FORMULA ? sqUtilities.getToolType(item) : TREND_TOOLS.FORMULA;

        item = sqUtilities.decorateItemWithProperties(item);
        // Set additional properties that are not stored in UIConfig
        _.assign(config,
          _.pick(item, ['id', 'name', 'scopedTo', 'parameters', 'formula', 'signalMetadata', 'conditionMetadata']));

        // Apply the upgrade steps to the config
        config = sqConfigUpgrader.apply(config, config.configVersion);

        if (config.selectedRegion) {
          sqTrendActions.setSelectedRegion(config.selectedRegion.min, config.selectedRegion.max);
        } else if (config.type === TREND_TOOLS.SCATTER_CONDITION) {
          const selectedRegion = sqScatterConditionStore.getSelectedRegionFromFormula(config.formula);
          sqScatterPlotActions.setSelectedRegion(selectedRegion);
        }

        if (config?.formula?.indexOf('.validValues()') > 0) {
          _.assign(config, { useValidValues: true });
        }

        addCustomConfigProperties(config, toolItem);

        if (editMode !== EDIT_MODE.NORMAL) {
          // Copies need name prefix/index to be identified and  will not have an ID yet
          const oldName = config.name;
          delete config.id;
          delete config.name;

          sqFormula.getDefaultName(sqUtilities.getNamePrefix(oldName), sqTrendDataHelper.getTrendItemScopedTo(id))
            .then((indexedName) => {
              config.name = indexedName;
              flux.dispatch('TOOL_REHYDRATE_FOR_EDIT', config);
            });
        } else {
          flux.dispatch('TOOL_REHYDRATE_FOR_EDIT', config);
        }
        service.setActiveTool(config.type, DISPLAY_MODE.EDIT);

        // Edit modes (editingIds) are cleared by setActiveTool above, so set them appropriately for the type now
        if (editMode === EDIT_MODE.NORMAL) {
          if (config.type === TREND_TOOLS.COMPOSITE_SEARCH || config.type === TREND_TOOLS.CUSTOM_CONDITION) {
            sqTrendActions.setEditModeForCapsuleSet(id);
          } else if (_.includes(PREVIEW_TREND_TOOLS, config.type)) {
            sqTrendActions.setEditModeForSeries(id);
          }
        }

        // setActiveTool clears capsules from the capsule group store, so this logic must appear after the above
        // call
        if (config.type === TREND_TOOLS.CUSTOM_CONDITION) {
          return sqCapsuleGroupActions.loadFormulaAndSetSelection(config.formula);
        }
      })
      .catch(error => sqNotifications.apiError(error, { skipRedaction: true, displayForbidden: true }));
  }

  /**
   * Allows non-formula-based API types (e.g. THRESHOLD_METRIC) to encode type-specific item properties as standard
   * parameters. Formula based calculated items just return the item's parameters unchanged.
   *
   * @param {Object} item - the item for which parameters should be returned
   * @returns {Array} an array of item parameters
   */
  function getFormulaParameters(item) {
    switch (item.type) {
      case API_TYPES.THRESHOLD_METRIC:
        // The threshold items supplied by the user (not isGenerated) must be returned here so that they end up in
        // originalParameters and therefore are available as options in item parameter dropdown
        return _.chain(item.thresholds)
          .reject('isGenerated')
          .map(t => ({
            item: t.item,
            name: `threshold${t.priority.level > 0 ? 'P' : 'N'}${Math.abs(t.priority.level)}`,
            unbound: false
          }))
          .concat(_.map(['measuredItem', 'boundingCondition'], key => item[key] && {
            item: item[key],
            name: key,
            unbound: false
          }))
          .compact()
          .value();
      default:
        return item.parameters;
    }
  }

  /**
   * Add non-item properties to the config for tools that are not based on a formula.
   *
   * @param {Object} config - the configuration object
   * @param {Object} item - the item for which the tool is being loaded
   */
  function addCustomConfigProperties(config, item) {
    switch (item.type) {
      case API_TYPES.THRESHOLD_METRIC:
        _.assign(config, _.pick(item, [
          'processType',
          'aggregationFunction',
          'duration',
          'period',
          'neutralColor',
          'boundingConditionMaximumDuration',
          'thresholds'
        ]));
    }
  }

  /**
   * Check to see if a name is valid for the SearchTitle component
   * @param {string} name - the name to check
   * @returns {boolean} - whether or not the name is valid (exists and has proper length)
   *
   * This is a workaround to allow form validation for the SearchTitle component now that it is in react, while parts
   * of the tool panels are still in angular. This can be removed when the tool panels are converted and validation
   * is handled fully in react.
   */
  function hasValidName(name) {
    return !_.isEmpty(_.trim(name)) && name.length <= MAX_NAME_LENGTH.TOOL;
  }
}
