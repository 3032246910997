import angular from 'angular';
import { UtilitiesService } from '@/services/utilities.service';
import { ScreenshotService } from '@/services/screenshot.service';

angular.module('sqApp').controller('LoadErrorCtrl', LoadErrorCtrl);

function LoadErrorCtrl(
  $scope: ng.IScope,
  $translate: ng.translate.ITranslateService,
  $stateParams: ng.ui.IStateParamsService,
  $timeout: ng.ITimeoutService,
  sqUtilities: UtilitiesService,
  sqScreenshot: ScreenshotService) {

  const vm = this;
  vm.header = $stateParams.header;
  vm.message1 = $stateParams.message1;
  vm.message2 = $stateParams.message2;
  vm.retryInterval = $stateParams.retryInterval;

  if (sqUtilities.headlessRenderMode()) {
    sqScreenshot.notifyError('Load Error:', $translate.instant(vm.message1), $translate.instant(vm.message2));
  } else {
    countdown();
  }


  /**
   * Starts a timer to attempt to return to returnState after the timeout interval specified in
   * $stateParams.retryInterval. If .retryInterval is 0, counter is not started.
   */
  function countdown() {
    if (vm.retryInterval) {
      const timeoutId = $timeout(sqUtilities.returnToState, vm.retryInterval);

      // If leaving the page by some means other than `returnToState` the timeout needs to be canceled.
      $scope.$on('$destroy', function() {
        $timeout.cancel(timeoutId);
      });
    }
  }
}
