import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import { NumericCapsulePropertyColorsConfig, ScatterPlotStore } from '@/scatterPlot/scatterPlot.store';
import { NumberHelperService } from '@/core/numberHelper.service';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { DateTimeService } from '@/datetime/dateTime.service';
import { WorksheetStore } from '@/worksheet/worksheet.store';

const scatterPlotLegendGradientBindings = bindingsDefinition({
  sqScatterPlotStore: injected<ScatterPlotStore>(),
  sqNumberHelper: injected<NumberHelperService>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqDateTime: injected<DateTimeService>(),
  displayName: prop<string>(),
  colorsConfig: prop<NumericCapsulePropertyColorsConfig | undefined>()
});

/** Displays scatter plot color information when coloring by signal or numeric capsule property */
export const ScatterPlotLegendGradientItems: SeeqComponent<typeof scatterPlotLegendGradientBindings> = (props) => {
  const { sqNumberHelper, sqDateTime } = useInjectedBindings(scatterPlotLegendGradientBindings);
  const { displayName, colorsConfig } = props;

  const { t } = useTranslation();

  if (!displayName || !colorsConfig || colorsConfig.isStringProperty) {
    return null;
  }

  const formatNumber = displayName === SeeqNames.CapsuleProperties.Duration ?
    value => sqDateTime.formatDuration(value, true) : sqNumberHelper.formatNumber;

  return (
    <span className="mr10 specColorSignalLegend ">
      <strong><span className="mr5">{t('SCATTER.LEGEND_GRADIENT')}:</span></strong>
      <span className="mr5">[</span>
      <Icon icon="fa-circle" type="color" color={colorsConfig.minColor} extraClassNames="mr5 mt2" />
      {formatNumber(colorsConfig.minValue)}
      <span className="mr5">...</span>
      <Icon icon="fa-circle" type="color" color={colorsConfig.maxColor} extraClassNames="mr5 mt2" />
      {formatNumber(colorsConfig.maxValue)}
      <span className="mr5">]</span>
      <span>{displayName}</span>
    </span>
  );
};
