import React, { useEffect, useState } from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import _ from 'lodash';
import { InputGroup, FormControl } from 'react-bootstrap';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { Icon } from '@/hybrid/core/Icon.atom';

/**
 * This component renders a text input field that displays a "delete" icon in the far right once text has been entered.
 */
interface ClearableInputProps {
  /** identifier, provided to the filterTable function as the first parameter */
  field: string;
  /** text displayed in the input field */
  searchValue: string;
  /** called whenever the text in the input field changes */
  filterTable: (field: string, value: string) => void;
  /** use a custom icon instead of checkmark */
  iconClassName?: string;
  /** true if the input box should be greyed out and disabled */
  disabled?: boolean;
  /** if provided, a checkmark or custom icon is displayed in the box, and clicking
   it or pressing enter will trigger the callback */
  enterCallback?: () => void;
  /** if provided, trigger the callback if input lose focus */
  onBlurCallback?: () => void;
}

export const ClearableInput: React.FunctionComponent<ClearableInputProps> = (props) => {
  const {
    field,
    filterTable,
    searchValue,
    disabled = false,
    iconClassName = 'fa-check',
    enterCallback = undefined,
    onBlurCallback = undefined
  } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState(searchValue || '');

  useEffect(() => filterTable(field, _.trim(value)), [value]);

  useEffect(() => {
    setValue(searchValue === undefined ? '' : searchValue);
  }, [searchValue]);

  return <InputGroup className="flexColumnContainer">
    <FormControl
      className="width-50"
      disabled={disabled}
      data-testid={`clearableInput_${field}`}
      value={value}
      type="text"
      placeholder={t('ADMIN.FILTER_BY')}
      onKeyDown={e => e.key === 'Enter' && enterCallback?.()}
      onChange={event => setValue(event.target.value)}
      onBlur={() => onBlurCallback?.()}
    />

    {enterCallback &&
    <InputGroup.Append>
      <TextButton testId={`enterBtn_${field}`} formattedLabel={<Icon icon={iconClassName} type="text" />}
        onClick={enterCallback} />
    </InputGroup.Append>}

    {value &&
    <InputGroup.Append>
      <TextButton testId="clearBtn" formattedLabel={<Icon icon="fa-times" type="text" />} onClick={() => {
        filterTable(field, '');
        enterCallback?.();
      }} />
    </InputGroup.Append>}
  </InputGroup>;
};
