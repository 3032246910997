import React, { useState, useEffect, useRef, useMemo } from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { InvestigateStore } from '@/investigate/investigate.store';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { TrackService } from '@/track/track.service';
import { FftStore } from '@/hybrid/tools/frequencyAnalysis/fft.store';
import { FftActions } from '@/hybrid/tools/frequencyAnalysis/fft.actions';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import {
  FormElement,
  FORM_ERROR
} from '@/hybrid/formbuilder/formBuilder.module';
import {
  LOW_PASS_FILTER_CUTOFF,
  NYQUIST_CUTOFF_RATIO,
  TREND_TOOLS
} from '@/investigate/investigate.module';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import _ from 'lodash';
import { ITEM_TYPES } from '@/trendData/trendData.module';
import { DateTimeService } from '@/datetime/dateTime.service';
import {
  API_TYPES,
  DISPLAY_MODE,
  DURATION_TIME_UNITS,
  FREQUENCY_UNITS
} from '@/main/app.constants';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { NotificationsService } from '@/services/notifications.service';
import { TableHelperService } from '@/trendData/tableHelper.service';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { InvestigateHelperService } from '@/investigate/investigateHelper.service';
import moment from 'moment-timezone';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { DurationStore } from '@/trendData/duration.store';

const frequencyAnalysisBindings = bindingsDefinition({
  sqFftStore: injected<FftStore>(),
  sqFftActions: injected<FftActions>(),
  sqInvestigateStore: injected<InvestigateStore>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqTrack: injected<TrackService>(),
  sqDateTime: injected<DateTimeService>(),
  sqToolRunner: injected<ToolRunnerService>(),
  sqNotifications: injected<NotificationsService>(),
  sqTableHelper: injected<TableHelperService>(),
  sqInvestigateHelper: injected<InvestigateHelperService>(),
  sqDurationStore: injected<DurationStore>()
});

export const FrequencyAnalysis: SeeqComponent<typeof frequencyAnalysisBindings> = () => {
  const {
    sqFftStore,
    sqFftActions,
    sqInvestigateStore,
    sqInvestigateActions,
    sqTrack,
    sqDateTime,
    sqToolRunner,
    sqNotifications,
    sqTableHelper,
    sqInvestigateHelper,
    sqDurationStore
  } = useInjectedBindings(frequencyAnalysisBindings);

  const {
    id,
    name,
    signalToAggregate,
    rate,
    rateType,
    outputUnits,
    useHighPass,
    useLowPass,
    configParams,
    lowPass,
    highPass
  } = useFlux(sqFftStore);
  const displayMode = useFluxPath(
    sqInvestigateStore,
    () => sqInvestigateStore.displayMode
  );
  const displayRange = useFluxPath(
    sqDurationStore,
    () => sqDurationStore.displayRange
  );

  const [color, setColor] = useState('');
  const [rateTypeRelated, setRateTypeRelated] = useState({
    availableUnits: rateType ? DURATION_TIME_UNITS : FREQUENCY_UNITS,
    outputUnitOptions: []
  });
  const lowPassCutoffRateRatioError = useMemo(
    () =>
      useLowPass &&
      !sqInvestigateHelper.checkCutOffRateRatio(
        lowPass,
        rate,
        NYQUIST_CUTOFF_RATIO
      ),
    [useLowPass, lowPass, rate]
  );
  const highPassCutoffRateRatioError = useMemo(
    () =>
      useHighPass &&
      !sqInvestigateHelper.checkCutOffRateRatio(
        highPass,
        rate,
        NYQUIST_CUTOFF_RATIO
      ),
    [useHighPass, highPass, rate]
  );
  const otherErrors = useMemo(() => {
    if (rateType) {
      const units = highPass?.units || lowPass?.units;

      if (sqDateTime.isFrequency(units)) {
        return {
          frequencyError: false,
          periodError: false
        };
      }
    }

    return {
      frequencyError:
        useLowPass &&
        useHighPass &&
        !rateType &&
        (!_.isUndefined(highPass.value) || !_.isUndefined(lowPass.value)) &&
        sqDateTime.convertToFrequencyPerDay(highPass).value >=
        sqDateTime.convertToFrequencyPerDay(lowPass).value,
      periodError:
        useLowPass &&
        useHighPass &&
        rateType &&
        (!_.isUndefined(highPass.value) || !_.isUndefined(lowPass.value)) &&
        moment
          .duration(
            lowPass.value,
            sqDateTime.momentMeasurementStrings(lowPass.units)
          )
          .asMilliseconds() >=
        moment
          .duration(
            highPass.value,
            sqDateTime.momentMeasurementStrings(highPass.units)
          )
          .asMilliseconds()
    };
  }, [useHighPass, useLowPass, rateType, highPass, lowPass]);

  /**
   * Updates the default values based on the series.
   */
  useEffect(() => {
    if (
      !signalToAggregate ||
      !signalToAggregate.id ||
      !_.isUndefined(rate.value)
    ) {
      return;
    }

    const formula = `estimateSamplePeriod($series, ${sqDateTime.getCapsuleFormula(
      {
        start: displayRange.start,
        end: displayRange.end
      }
    )})`;
    const parameters = { series: signalToAggregate.id };

    sqFftActions.fetchRateMin(formula, parameters).then((result) => {
      let suggestedRate = sqDateTime.determineIdealUnits(result);

      if (!rateType) {
        suggestedRate = sqDateTime.determineIdealUnits(
          sqDateTime.convertPeriodToFrequency(suggestedRate)
        );
      }
      sqFftActions.setRate(suggestedRate);
      sqFftActions.setOutputUnits(suggestedRate.units);
    });
  }, [signalToAggregate]);

  useEffect(() => {
    const availableUnits = rateType ? DURATION_TIME_UNITS : FREQUENCY_UNITS;

    if (!_.isUndefined(rate.value) && !_.isUndefined(rate.units)) {
      if (sqDateTime.isFrequency(rate.units) && rateType) {
        sqFftActions.setRate(sqDateTime.convertFrequencyToPeriod(rate));
      } else if (!sqDateTime.isFrequency(rate.units) && !rateType) {
        sqFftActions.setRate(sqDateTime.convertPeriodToFrequency(rate));
      }
    }

    if (outputUnits) {
      if (sqDateTime.isFrequency(outputUnits) && rateType) {
        sqFftActions.setOutputUnits(
          outputUnits === 'Hz' ? 's' : outputUnits.slice(1)
        );
      } else if (!sqDateTime.isFrequency(outputUnits) && !rateType) {
        sqFftActions.setOutputUnits(
          outputUnits === 's' ? 'Hz' : `/${outputUnits}`
        );
      }
    }

    if (!_.isUndefined(lowPass.units)) {
      if (sqDateTime.isFrequency(lowPass.units) && rateType) {
        sqFftActions.setLowPass(sqDateTime.convertFrequencyToPeriod(lowPass));
      } else if (!sqDateTime.isFrequency(lowPass.units) && !rateType) {
        sqFftActions.setLowPass(sqDateTime.convertPeriodToFrequency(lowPass));
      }
    }

    if (!_.isUndefined(highPass.units)) {
      if (sqDateTime.isFrequency(highPass.units) && rateType) {
        sqFftActions.setHighPass(sqDateTime.convertFrequencyToPeriod(highPass));
      } else if (!sqDateTime.isFrequency(highPass.units) && !rateType) {
        sqFftActions.setHighPass(sqDateTime.convertPeriodToFrequency(highPass));
      }
    }

    setRateTypeRelated({
      availableUnits,
      outputUnitOptions: _.map(availableUnits, value => ({
        value: value.unit[0],
        label: value.translationKey
      }))
    });
  }, [rateType]);

  const run = () => {
    const { formula, parameters } = sqFftActions.createFormula();

    return sqToolRunner
      .panelExecuteFormulaFunction(
        API_TYPES.TABLE,
        {
          formula,
          // add the "unbound" parameters
          parameters: _.concat(
            parameters,
            sqTableHelper.getViewCapsuleParameter(),
            sqTableHelper.getNumPixelParameter()
          ),
          name
        },
        configParams,
        id,
        color
      )
      .then(() => {
        sqInvestigateActions.close();
        sqTrack.doTrack('Workbench_Tool', 'FFT', 'completed');
      })
      .catch((e) => {
        sqNotifications.apiError(e);
        sqTrack.doTrack('Workbench_Tool', 'FFT', 'error');
      });
  };

  const formDataSetup: FormElement[] = [
    {
      component: 'SearchTitleFormComponent',
      name: 'frequencyAnalysisSearchTitle',
      value: name,
      onChange: _.partial(
        sqInvestigateActions.setSearchName,
        TREND_TOOLS.FFT_TABLE
      ),
      id,
      onColorChange: setColor,
      searchIconClass: 'fc-bell-curve',
      defaultName: 'INVESTIGATE_TOOLS.FFT.HEADER'
    },
    {
      component: 'ItemSelectFormComponent',
      name: 'signalToAggregate',
      testId: 'signalToAggregate',
      displayNumber: true,
      value: signalToAggregate?.id,
      onChange: item => sqInvestigateActions.setParameterItem(
        TREND_TOOLS.FFT_TABLE,
        'signalToAggregate',
        item
      ),
      label: 'INVESTIGATE_TOOLS.FFT.SIGNAL_TO_ANALYZE',
      itemTypes: [ITEM_TYPES.SERIES]
    },
    {
      component: 'RadioButtonGroupFormComponent',
      name: 'rateType',
      value: rateType,
      onChange: _.noop,
      id: 'rateType',
      displayNumber: true,
      options: [
        {
          id: 'period',
          label: 'INVESTIGATE_TOOLS.FFT.PERIOD',
          checked: rateType,
          onToggle: () => sqFftActions.setRateType(true)
        },
        {
          id: 'frequency',
          label: 'INVESTIGATE_TOOLS.FFT.FREQUENCY',
          checked: !rateType,
          onToggle: () => sqFftActions.setRateType(false)
        }
      ]
    },
    {
      component: 'ValueWithUnitsFormComponent',
      displayNumber: true,
      testId: 'rate',
      name: 'rate',
      min: 0,
      value: rate,
      availableUnits: rateTypeRelated.availableUnits,
      label: 'INVESTIGATE_TOOLS.FFT.SAMPLING_PERIOD',
      onChange: sqFftActions.setRate,
      required: true,
      validation: () => !rate?.value || rate?.value <= 0,
      customErrorText: 'INVESTIGATE_TOOLS.FFT.SAMPLING_PERIOD_ERROR'
    },
    {
      component: 'SelectFormComponent',
      displayNumber: true,
      name: 'outputUnits',
      testId: 'outputUnits',
      value: outputUnits,
      label: 'INVESTIGATE_TOOLS.FFT.OUTPUT_UNITS',
      onChange: sqFftActions.setOutputUnits,
      options: rateTypeRelated.outputUnitOptions,
      required: true
    },
    {
      component: 'FormGroup',
      displayNumber: true,
      name: 'lowPassFilterFormGroup',
      components: [
        {
          component: 'CheckboxFormComponent',
          name: 'useLowPass',
          testId: 'useLowPass',
          value: useLowPass,
          onChange: () => {
            if (!useLowPass) {
              sqFftActions.setLowPass({ value: undefined, units: outputUnits });
            } else {
              sqFftActions.setLowPass(LOW_PASS_FILTER_CUTOFF);
            }

            sqFftActions.setUseLowPass(!useLowPass);
          },
          label: rateType
            ? 'INVESTIGATE_TOOLS.FFT.LOW_PASS_PERIOD'
            : 'INVESTIGATE_TOOLS.FFT.LOW_PASS_FREQUENCY',
          id: 'useLowPass'
        },
        {
          component: 'ValueWithUnitsFormComponent',
          testId: 'specLowPass',
          name: 'specLowPass',
          min: 0,
          value: lowPass,
          availableUnits: rateTypeRelated.availableUnits,
          onChange: sqFftActions.setLowPass,
          required: true,
          includeIf: useLowPass
        },
        {
          component: 'ErrorMessageFormComponent',
          name: 'lowPassCutoffPeriodRateRatioError',
          includeIf: rateType && lowPassCutoffRateRatioError,
          type: FORM_ERROR,
          value: 'INVESTIGATE_TOOLS.FFT.NYQUIST_CUTOFF_PERIOD_RATIO_ERROR',
          failForm: true
        },
        {
          component: 'ErrorMessageFormComponent',
          name: 'lowPassCutoffFrequencyRateRatioError',
          includeIf: !rateType && lowPassCutoffRateRatioError,
          type: FORM_ERROR,
          value: 'INVESTIGATE_TOOLS.FFT.NYQUIST_CUTOFF_FREQUENCY_RATIO_ERROR',
          failForm: true
        }
      ]
    },
    {
      component: 'FormGroup',
      displayNumber: true,
      name: 'highPassFilterFormGroup',
      components: [
        {
          component: 'CheckboxFormComponent',
          name: 'useHighPass',
          testId: 'useHighPass',
          value: useHighPass,
          onChange: () => {
            if (!useHighPass) {
              sqFftActions.setHighPass({
                value: undefined,
                units: outputUnits
              });
            } else {
              sqFftActions.setHighPass(LOW_PASS_FILTER_CUTOFF);
            }

            sqFftActions.setUseHighPass(!useHighPass);
          },
          label: rateType
            ? 'INVESTIGATE_TOOLS.FFT.HIGH_PASS_PERIOD'
            : 'INVESTIGATE_TOOLS.FFT.HIGH_PASS_FREQUENCY',
          id: 'useHighPass'
        },
        {
          component: 'ValueWithUnitsFormComponent',
          testId: 'specHighPass',
          name: 'specHighPass',
          min: 0,
          value: highPass,
          availableUnits: rateTypeRelated.availableUnits,
          onChange: sqFftActions.setHighPass,
          required: true,
          includeIf: useHighPass
        },
        {
          component: 'ErrorMessageFormComponent',
          name: 'highPassCutoffPeriodRateRatioError',
          includeIf: rateType && highPassCutoffRateRatioError,
          type: FORM_ERROR,
          value: 'INVESTIGATE_TOOLS.FFT.NYQUIST_CUTOFF_PERIOD_RATIO_ERROR',
          failForm: true
        },
        {
          component: 'ErrorMessageFormComponent',
          name: 'highPassCutoffFrequencyRateRatioError',
          includeIf: !rateType && highPassCutoffRateRatioError,
          type: FORM_ERROR,
          value: 'INVESTIGATE_TOOLS.FFT.NYQUIST_CUTOFF_FREQUENCY_RATIO_ERROR',
          failForm: true
        }
      ]
    },
    {
      component: 'ErrorMessageFormComponent',
      name: 'periodError',
      includeIf: otherErrors.periodError,
      type: FORM_ERROR,
      value: 'INVESTIGATE_TOOLS.FFT.PERIOD_ERROR',
      failForm: true,
      extraClassNames: 'pl20 pr10 pb10'
    },
    {
      component: 'ErrorMessageFormComponent',
      name: 'frequencyError',
      includeIf: otherErrors.frequencyError,
      type: FORM_ERROR,
      value: 'INVESTIGATE_TOOLS.FFT.FREQUENCY_ERROR',
      failForm: true,
      extraClassNames: 'pl20 pr10 pb10'
    }
  ];

  const frequencyAnalysisBuilder = (
    <ToolPanelFormBuilder
      formDefinition={formDataSetup}
      submitFn={run}
      closeFn={sqInvestigateActions.close}
      toolName={TREND_TOOLS.FFT_TABLE}
      toolId={TREND_TOOLS.FFT_TABLE}
      submitBtnId="executeFrequencyAnalysisTool"
    />
  );

  return (displayMode === DISPLAY_MODE.NEW || displayMode === DISPLAY_MODE.EDIT)
    ? frequencyAnalysisBuilder
    : null;
};

export const sqFrequencyAnalysis = angularComponent(
  frequencyAnalysisBindings,
  FrequencyAnalysis
);
