import React from 'react';
import classNames from 'classnames';
import { IconType } from '@/hybrid/core/Icon.atom';

export interface IconWithSpinnerProps {
  icon?: string;
  spinning: boolean;
  large?: boolean;
  white?: boolean;
  type?: IconType;
  extraClassNames?: string;
  testId?: string;
  correctSpin?: boolean;
  disabled?: boolean;
}

/** Icon that switches to spinner when input parameter is true */
export const IconWithSpinner: React.FunctionComponent<IconWithSpinnerProps> = (props) => {
  const {
    spinning,
    icon,
    large,
    white,
    extraClassNames,
    testId,
    correctSpin = true,
    disabled = false,
    type = 'theme'
  } = props;
  const fontCustom = icon && icon.includes('fc-');
  const colorClass = white ? 'text-white' : `sq-icon-${type}`;
  const spinningIconClasses = classNames('fa', 'fa-spinner', 'fa-spin', { 'fa-lg': large }, { correctSpin });
  const iconClasses = classNames(fontCustom ? 'fc' : 'fa', icon, { 'fa-lg': large });
  return <i className={classNames(extraClassNames, colorClass, spinning ? spinningIconClasses : iconClasses,
    { 'disabledLook cursorNotAllowed': disabled })}
    data-testid={testId} />;
};
