import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { CELL_TYPES } from '@/hybrid/core/Table.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { SystemApi } from '@/sdk';
import { ConfigurationOptionEditController } from '@/administration/configurationOptionEdit.controller';
import configurationOptionEditModalTemplate from '@/administration/configurationOptionEdit.modal.html';
import { ModalService } from '@/services/modal.service';
import { UtilitiesService } from '@/services/utilities.service';
import { ConfigurationPreviewController } from '@/administration/configurationPreview.controller';
import configurationPreviewModalTemplate from '@/administration/configurationPreview.modal.html';
import { NotificationsService } from '@/services/notifications.service';
import angular, { IQService } from 'angular';
import { ConfigurationOutputV1 } from 'sdk/model/ConfigurationOutputV1';
import { ButtonVariant, TextButton } from '@/hybrid/core/TextButton.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { AdminTableWrapper } from '@/hybrid/core/AdminTableWrapper.molecule';

const configurationTableBindings = bindingsDefinition({
  $q: injected<IQService>(),
  sqModal: injected<ModalService>(),
  sqNotifications: injected<NotificationsService>(),
  sqSystemApi: injected<SystemApi>(),
  sqUtilities: injected<UtilitiesService>()
});

export const ConfigurationTable: SeeqComponent<typeof configurationTableBindings> = () => {
  const {
    $q,
    sqModal,
    sqNotifications,
    sqSystemApi,
    sqUtilities
  } = useInjectedBindings(configurationTableBindings);

  const { t } = useTranslation();
  useEffect(() => {
    refreshConfigOptions();
  }, []);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [uneditedConfigOptions, setUneditedConfigOptions] = useState([]);
  const [currentConfigOptions, setCurrentConfigOptions] = useState([]);

  /**
   * Gets the fields that have changed as compared to the API version of the configuration option
   *
   * @param {Object} option - The option from the grid
   */
  const getChangedFields = (option) => {
    const currentOption = _.find(uneditedConfigOptions, { path: option.path });
    return _.filter(['defaultValue', 'value', 'note'],
      field => option && currentOption && option[field] !== currentOption[field]);
  };

  const editConfigurationOption = (item) => {
    return sqModal.open({
      animation: true,
      controller: ConfigurationOptionEditController,
      controllerAs: '$ctrl',
      template: configurationOptionEditModalTemplate,
      resolve: {
        configurationOption: _.constant(_.cloneDeep(item)) // Cloned so it doesn't change until Save is clicked
      }
    }).result
      .then((option) => {
        if (option) {
          _.assign(item, option, { isDirty: getChangedFields(option).length > 0 });

          // Replace the config options with a clone, to make React re-render
          setCurrentConfigOptions(_.cloneDeep(currentConfigOptions));
        }
      })
      .catch(sqUtilities.handleModalOpenErrors);
  };

  const renderEditCell = item => (
    <HoverTooltip delay={500} placement="top" text={item.modifiable ? '' : 'ADMIN.CONFIGURATION.EDIT_DISABLED'}>
      <div className={item.modifiable ? 'cursorPointer' : 'cursorNotAllowed disabledLook'}
        onClick={() => item.modifiable && editConfigurationOption(item)}>
        <Icon icon="fa-pencil" testId={`editConfiguration_${item.path}`} />
        <span className="sq-text-primary pl5">{t('EDIT')}</span>
      </div>
    </HoverTooltip>
  );

  const renderCell = (item, accessor) => {
    // Convert to string in case we have a boolean value so it displays
    const value = _.toString(item[accessor]);

    return <HoverTooltip text={value} delay={500}>
      <div className={classNames('textOverflowEllipsis', 'overflowHidden', 'nowrap', { 'text-bolder': item.isDirty })}>
        {value}
      </div>
    </HoverTooltip>;
  };

  const columns = [
    {
      accessor: 'path',
      searchProperty: 'path',
      header: 'ADMIN.CONFIGURATION.NAME',
      cellStyle: { width: '25%' },
      cellRenderFunction: renderCell
    }, {
      accessor: 'advanced',
      searchProperty: 'advanced',
      header: 'ADVANCED',
      cellType: CELL_TYPES.CHECKMARK,
      cellStyle: { width: '6%', minWidth: 90 }
    }, {
      accessor: 'isOverridden',
      searchProperty: 'isOverridden',
      header: 'ADMIN.CONFIGURATION.OVERRIDDEN',
      cellType: CELL_TYPES.CHECKMARK,
      cellStyle: { width: '6%', minWidth: 90 }
    }, {
      accessor: 'defaultValue',
      searchProperty: 'defaultValue',
      header: 'ADMIN.CONFIGURATION.DEFAULT_VALUE',
      cellStyle: { width: '15%', maxWidth: 125 },
      cellRenderFunction: renderCell
    }, {
      accessor: 'units',
      searchProperty: 'units',
      header: 'ADMIN.CONFIGURATION.UNITS',
      cellStyle: { width: '5%', minWidth: 110 },
      cellRenderFunction: renderCell
    }, {
      accessor: 'value',
      searchProperty: 'value',
      header: 'ADMIN.CONFIGURATION.OVERRIDE_VALUE',
      cellStyle: { width: '15%', maxWidth: 125 },
      cellRenderFunction: renderCell
    }, {
      accessor: 'note',
      searchProperty: 'note',
      header: 'ADMIN.CONFIGURATION.NOTE',
      cellStyle: { width: '20%', maxWidth: 125 },
      cellRenderFunction: renderCell
    }, {
      accessor: 'id',
      sortable: false,
      filterable: false,
      cellStyle: { width: '3%', minWidth: 70 },
      cellRenderFunction: renderEditCell
    }
  ];
  const sortableColumns = _.map(columns, column => _.assign({ sortable: true, filterable: true }, column));

  const refreshConfigOptions = () => {
    setIsRefreshing(true);
    return sqSystemApi.getConfigurationOptions({ offset: 0, limit: 1000 })
      .then(processConfigOptions)
      .finally(() => {
        setIsRefreshing(false);
      });
  };

  const processConfigOptions = ({ data: { configurationOptions } }: angular.IHttpResponse<ConfigurationOutputV1>) => {
    const processedConfigOptions = _.map(configurationOptions, (option) => {
      // Add an "id" field to each config entry, because the Table component requires it
      // And create an 'isOverridden' column
      return _.assign({
        id: option.path,
        isOverridden: _.has(option, 'value')
      }, option);
    });

    // Cloned so that there is an unmodified copy of the data available for preview
    setUneditedConfigOptions(_.cloneDeep(processedConfigOptions));
    setCurrentConfigOptions(processedConfigOptions);
  };

  const renderTableLoadingIndicator = (
    <div className="flexColumnContainer flexCenter pt50 pb50">
      <i className="fa fa-spinner fa-pulse fa-5x sq-text-primary" />
    </div>
  );

  /**
   * Determines if preview button should be enabled.
   *
   * @return {boolean} True if preview should be enabled, false otherwise
   */
  const isPreviewConfigurationEnabled = () => _.some(currentConfigOptions, 'isDirty');

  /**
   * Opens a modal that allows the user to preview the changed configuration options
   */
  function previewConfigurationChanges() {
    const changes = _.chain(currentConfigOptions)
      .filter('isDirty')
      .map(option => ({ path: option.path, value: option.value, note: option.note }))
      .value();
    return sqModal.open({
      animation: true,
      controller: ConfigurationPreviewController,
      controllerAs: '$ctrl',
      template: configurationPreviewModalTemplate,
      resolve: {
        configurationOptions: sqSystemApi.setConfigurationOptions({
            dryRun: true,
            configurationOptions: changes
          })
          .then(({ data: { configurationOptions } }) => _.chain(configurationOptions)
            .filter('modifiable')
            .reduce((accum, option) => {
              const changedFields = getChangedFields(option);
              if (changedFields.length) {
                accum.push({
                  ...option,
                  changedFields: _.zipObject(changedFields, _.fill(Array(changedFields.length), true))
                });
              }
              return accum;
            }, [])
            .sortBy('path')
            .value())
          .catch((e) => {
            sqNotifications.apiError(e);
            return $q.reject(e);
          })
      }
    }).result
      .then((save) => {
        if (save) {
          sqSystemApi.setConfigurationOptions({ configurationOptions: changes })
            .then(processConfigOptions)
            .then(() => {
              sqNotifications.successTranslate('ADMIN.CONFIGURATION.SAVED');
            })
            .catch(sqNotifications.apiError);
        }
      })
      .catch(sqUtilities.handleModalOpenErrors);
  }

  const SaveHelp = () => {
    if (isPreviewConfigurationEnabled()) {
      return <strong className="mr5">{t('ADMIN.CONFIGURATION.SAVE_HELP')}</strong>;
    } else {
      return <div />;
    }
  };

  return (
    <div className="height-maximum">
      <div className="flexColumnContainer flexSpaceBetween flexAlignCenter mb5">
        <strong className="pl3">{t('ADMIN.CONFIGURATION.SUPPORT_HELP')}</strong>
        <div className="flexColumnContainer flexAlignCenter">
          <SaveHelp />
          <TextButton id="previewConfiguration"
            variant={`${isPreviewConfigurationEnabled() ? 'warning' : 'theme'}` as ButtonVariant}
            disabled={!isPreviewConfigurationEnabled()}
            onClick={previewConfigurationChanges}
            icon="fa-save"
            iconStyle="white"
            label="SAVE"
          />
        </div>
      </div>

      <div className="width-maximum height-maximum pb70 overflowAuto">
        <AdminTableWrapper
          testId="configurationAdministrationTable"
          items={currentConfigOptions}
          selectedItems={[]}
          setSelectedItems={([]) => {}}
          columns={sortableColumns}
          defaultSort={{ property: 'path', asc: true }} />
      </div>
      {isRefreshing && renderTableLoadingIndicator}
    </div>
  );
};
