import React from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { InjectorContext } from '@/hybrid/core/useInjector.hook';
import { ErrorBoundaryWithLogging } from '@/hybrid/core/ErrorBoundary.atom';
import { ErrorFallbackIcon } from '@/hybrid/core/ErrorFallback.organism';
import { TranslationContext } from '@/hybrid/core/useTranslation.hook';
import { useTranslatorAngular } from '@/hybrid/core/useTranslatorAngular.hook';

export const rootBindings = bindingsDefinition({
  $injector: injected<ng.auto.IInjectorService>(),
  $rootScope: injected<ng.IRootScopeService>(),
  $translate: injected<ng.translate.ITranslateService>()
});

// Unlike non-root components the injected values of the root component are used as props for the component as well
export type RootProps = PropBindings<typeof rootBindings>
  & InjectedBindings<typeof rootBindings>;

/**
 * This component is the highest component of a branch of React components and is an ideal location to provide
 * contexts that are in use throughout the whole application.
 */
export const Root: React.FunctionComponent<RootProps> = ({ $injector, $rootScope, $translate, children }) => {
  const translator = useTranslatorAngular($translate, $rootScope);
  return (
    <InjectorContext.Provider value={$injector}>
      <TranslationContext.Provider value={translator}>
        <ErrorBoundaryWithLogging fallback={(error, reset) => <ErrorFallbackIcon error={error} retry={reset}/>}>
          {children}
        </ErrorBoundaryWithLogging>
      </TranslationContext.Provider>
    </InjectorContext.Provider>
  );
};

/**
 * This version is used in unit tests where the ErrorBoundary could undesirably suppress errors
 */
export const RootForTests: React.FunctionComponent<RootProps> = ({ $injector, $rootScope, $translate, children }) => {
  const translator = useTranslatorAngular($translate, $rootScope);
  return (
    <InjectorContext.Provider value={$injector}>
      <TranslationContext.Provider value={translator}>
        {children}
      </TranslationContext.Provider>
    </InjectorContext.Provider>
  );
};
