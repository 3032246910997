import _ from 'lodash';
import angular from 'angular';
import { DateTimeService } from '@/datetime/dateTime.service';
import { DURATION_TIME_UNITS_ALL, DurationTimeUnit, STANDARD_TIME_UNIT_PATH } from '@/main/app.constants';

class FixedValueWithUnitsCtrl {
  // Bindings
  public ngModel: ng.INgModelController = this['ngModel'];
  public required: boolean = this['required'];

  // Model Values
  public unitOption: DurationTimeUnit = this['unitOption'];
  public value: number = this['value'];

  availableValues: number[] = [];
  availableUnits: DurationTimeUnit[] = _.reject(DURATION_TIME_UNITS_ALL, ({ translationKey }) => translationKey === 'DURATION_UNITS.YEARS');
  isNil = _.isNil;

  constructor(public $attrs: ng.IAttributes, public sqDateTime: DateTimeService) {
  }

  $onChanges() {
    // Need to rerender after the available units change because the available units are used to determine the unit
    // displayed in the dropdown - without this the unit dropdown could be blank even though a supported unit is in
    // the model.
    this.ngModel.$render();
  }

  $onInit() {
    this.ngModel.$render = () => {
      const valueWithUnits = this.ngModel.$viewValue;
      this.unitOption = this.sqDateTime.getDurationTimeUnit(_.get(valueWithUnits, 'units'), this.availableUnits);
      this.availableValues = availableValuesForUnits(this.unitOption);
      this.value = _.get(valueWithUnits, 'value');
    };

    // Override the standard $isEmpty function with the information about the structure of our valueWithUnits object
    // so that all the built in validators that use it (i.e., ng-required) work correctly.
    this.ngModel.$isEmpty = valueWithUnits =>
      _.isNil(valueWithUnits) || _.isNil(valueWithUnits.value) || _.isNil(valueWithUnits.units);

    // If the field is optional, then we allow the form to be fully filled out or not filled out at all
    this.ngModel.$validators.consistentlyFilled = valueWithUnits =>
      _.isNil(_.get(valueWithUnits, 'value')) === _.isNil(_.get(valueWithUnits, 'units'));

    // The default behavior is to reset the model value to undefined if there is a validation error, however with this
    // higher level component we always want an object to be returned even if it is {value: null, units: null}.
    this.ngModel.$overrideModelOptions({ allowInvalid: true });

    // Manually observe the attributes so that templates can use them with ng-required, ng-disabled
    _.forEach(['required', 'disabled'], (attribute) => {
      this.$attrs.$observe(attribute, () => {
        _.set(this, attribute, !!_.get(this.$attrs, attribute));
      });
    });
  }

  onChange() {
    this.availableValues = availableValuesForUnits(this.unitOption);
    const unit = this.unitOption.unit[0];
    if (this.ngModel.$viewValue.units !== unit && !_.isEmpty(this.availableValues)
      && !_.includes(this.availableValues, this.value)) {
      this.value = this.availableValues[0];
    }
    this.ngModel.$setViewValue({ value: this.value, units: _.get(this.unitOption, STANDARD_TIME_UNIT_PATH) });
  }
}

const AVAILABLE_VALUES_BY_UNIT = {
  s:     [5, 10, 15, 20, 30],
  min:   [1, 5, 10, 15, 20, 30],
  h:     [1, 2, 3, 4, 6, 8, 12],
  day:   _.range(1, 31),
  week:  _.range(1, 4),
  month: _.range(1, 12)
};

function availableValuesForUnits(timeUnit: DurationTimeUnit) {
  const { unit: [firstUnitSynonym = '', ...rest] } = timeUnit || { unit: [] };
  return _.get(AVAILABLE_VALUES_BY_UNIT, firstUnitSynonym, []);
}

angular.module('Sq.Report')
  .controller('FixedValueWithUnitsCtrl', FixedValueWithUnitsCtrl);
