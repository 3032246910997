import React, { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';

export type AssetGroupCalculationCreationOption = 'fromExisting' | 'fromScratch';

interface SelectExistingOrNewInterface {
  onSelection: (option: AssetGroupCalculationCreationOption) => void;
}

export const SelectExistingOrNew: React.FunctionComponent<SelectExistingOrNewInterface> = ({ onSelection }) => {
  const [currentSelection, setCurrentSelection] = useState<AssetGroupCalculationCreationOption>('fromExisting');
  const { t } = useTranslation();
  const options = [{
    id: 'existingCB',
    label: 'ASSET_GROUP_EDITOR.EXISTING_ITEM',
    checked: currentSelection === 'fromExisting',
    onChange: () => {
      setCurrentSelection('fromExisting');
      onSelection('fromExisting');
    }
  }, {
    id: 'scratchCB',
    label: 'ASSET_GROUP_EDITOR.FROM_SCRATCH_ITEM',
    checked: currentSelection === 'fromScratch',
    onChange: () => {
      setCurrentSelection('fromScratch');
      onSelection('fromScratch');
    }
  }];

  return (
    <>
      <h4>{t('ASSET_GROUP_EDITOR.CALCULATION_TYPE')}</h4>
      <div className="flexRowContainer">
        {_.map(options, option => (
          <Checkbox
            data-testid={option.id}
            key={`${option.id}_checkbox`}
            classes={classNames('mr15')}
            type="radio"
            id={option.id}
            label={option.label}
            isChecked={option.checked}
            onChange={option.onChange} />
        ))
        }
      </div>
    </>
  );
};
