import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { ReportStore } from '@/reportEditor/report.store';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { ReportActions } from '@/reportEditor/report.actions';
import { Content, ContentDisplayMetadata } from '@/reportEditor/report.module';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { Overlay, Popover } from 'react-bootstrap';
import { ContentMenu } from '@/hybrid/annotation/ckEditorPlugins/components/ContentMenu.molecule';
import {
  buildAbsoluteContentUrl,
  buildRelativeContentUrl,
  CONTENT_MODEL_ATTRIBUTES,
  getContentSpecificCommand
} from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import { UtilitiesService } from '@/services/utilities.service';
import {
  ContentCallbacks,
  ContentDisplayMode,
  CustomPlugin,
  ImageContentListenerCommand
} from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { useCkListener } from '@/hybrid/core/useCkListener.hook';
import {
  renderContentVisualization,
  Visualization
} from '@/hybrid/annotation/ckEditorPlugins/components/content.utilities';

const refreshingContentBindings = bindingsDefinition({
  contentId: prop<string>(),
  updateContentAttribute: prop<(attribute: string, value: string) => void>(),
  editor: prop<any>(),
  displayMode: prop.optional<ContentDisplayMode>(),
  isExporting: prop.optional<boolean>(),
  sqReportStore: injected<ReportStore>(),
  sqReportActions: injected<ReportActions>(),
  sqUtilities: injected<UtilitiesService>()
});

export const BLANK_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
const toPx = (value: number) => `${value}px`;

/**
 * This monitors the various inputs to a piece of content causes the content to refresh.
 */
export const RefreshingContent: SeeqComponent<typeof refreshingContentBindings> = (props) => {
  const {
    contentId,
    updateContentAttribute,
    editor,
    displayMode = ContentDisplayMode.NORMAL
  } = props;
  const {
    sqReportStore,
    sqReportActions,
    sqUtilities
  } = useInjectedBindings(refreshingContentBindings);

  const [loading, setLoading] = useState(true);
  const [src, setSrc] = useState(sqReportStore.getContentImageUrl(contentId));
  const [showMenu, setShowMenu] = useState(false);
  const [visualization, setVisualization] = useState(undefined);
  const target = useRef(null);

  const contentCallbacks: ContentCallbacks = editor.config.get(CustomPlugin.Content).contentCallbacks;
  const model = contentCallbacks.getCurrentModel(contentId);

  //region useCkListeners
  const useWidthPercent = !!useCkListener(editor,
    getContentSpecificCommand(ImageContentListenerCommand.RESIZE, contentId),
    model?.getAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT));
  const height = _.toNumber(useCkListener(editor,
    getContentSpecificCommand(ImageContentListenerCommand.HEIGHT, contentId),
    model?.getAttribute(CONTENT_MODEL_ATTRIBUTES.HEIGHT)));
  const width = _.toNumber(useCkListener(editor,
    getContentSpecificCommand(ImageContentListenerCommand.WIDTH, contentId),
    model?.getAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH)));
  const border = useCkListener(editor,
    getContentSpecificCommand(ImageContentListenerCommand.BORDER, contentId),
    model?.getAttribute(CONTENT_MODEL_ATTRIBUTES.BORDER));
  const noMargin = useCkListener(editor,
    getContentSpecificCommand(ImageContentListenerCommand.NO_MARGIN, contentId),
    model?.getAttribute(CONTENT_MODEL_ATTRIBUTES.NO_MARGIN));
  //endregion

  const [refreshMeasurements, setRefreshMeasurements] = useState({ height, width });

  const updateMeasurements = (newHeight, newWidth) => {
    if (newHeight && newWidth && (height !== newHeight || width !== newWidth)) {
      updateContentAttribute(CONTENT_MODEL_ATTRIBUTES.HEIGHT, newHeight);
      updateContentAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH, newWidth);
    }
  };

  // The report store has a large number of updates that occur on every document update, but hashCodes only get set
  // every once in a while.
  const hashCode: string = useFluxPath(sqReportStore, () => sqReportStore.getContentById(contentId)?.hashCode);
  const displayMetadata: ContentDisplayMetadata = sqReportStore.getContentDisplayMetadataById(contentId);
  const content: Content = sqReportStore.getContentById(contentId) ?? {};

  //region useEffects
  useEffect(() => () => sqReportActions.removeContentDisplayMetadata(contentId), []);

  useEffect(() => {
    // Store content gets set to what is in the backend frequently, so we want to ignore anytime the hashcode is
    // cleared completely.
    if (hashCode) {
      const upToDateMetadata = sqReportStore.getContentDisplayMetadataById(contentId);
      if (!upToDateMetadata?.errorClass) {
        // The only place that uses deferImageUpdate is the stepToNow logic, which will never be done silently
        if (upToDateMetadata?.refresh?.deferImageUpdate) {
          setLoading(true);
          setSrc(BLANK_IMAGE);
        } else {
          const newSrc = sqReportStore.getContentImageUrl(contentId);
          // onLoad event won't fire if src hasn't changed
          if (newSrc !== src) {
            !upToDateMetadata?.refresh?.silently && setLoading(true);
            setSrc(newSrc);
          }
        }
      }

      !refreshMeasurements && setRefreshMeasurements(
        { width: target.current.clientWidth, height: target.current.clientHeight });
    }
  }, [hashCode]);
  //endregion

  const style = refreshMeasurements
    ? {
      width: toPx(refreshMeasurements.width),
      height: toPx(refreshMeasurements.height)
    }
    : {
      width: '100%',
      height: '100%',
      maxWidth: useWidthPercent ? undefined : toPx(width),
      maxHeight: useWidthPercent ? undefined : toPx(height),
      // We want to make sure that errored and loading content is never super tiny, which can occur in resize
      // situations.
      minHeight: loading || displayMetadata?.errorClass ? '100px' : undefined,
      minWidth: loading || displayMetadata?.errorClass ? '100px' : undefined
    };

  const getContentUrl = () => {
    const content: Content = sqReportStore.getContentById(contentId);
    return displayMode === ContentDisplayMode.PDF
      ? buildAbsoluteContentUrl(sqUtilities.getWorkbenchAddress(),
        content,
        sqReportStore.getDateRangeById(content.dateRangeId),
        sqReportStore.getAssetSelectionById(content.assetSelectionId))
      : buildRelativeContentUrl(contentId);
  };

  const display = renderContentVisualization({
    src,
    contentId,
    noMargin,
    border,
    loading,
    style,
    target,
    errorClass: displayMetadata?.errorClass,
    error: displayMetadata?.error,
    onLoad: (visualization?: Visualization) => {
      setLoading(false);
      setVisualization(visualization);
    },
    onMeasurementsReady: (width, height) => {
      setRefreshMeasurements(undefined);
      sqReportActions.debouncedImageStateChanged();
      updateMeasurements(height, width);
    },
    showMenu: () => setShowMenu(true)
  }, content?.isReact);

  const displayWrapperProps: any = {
    href: getContentUrl(),
    className: 'contentWrapper noCopy',
    onClick: displayMode === ContentDisplayMode.NORMAL ? e => e.preventDefault() : null,
    'data-testid': `content-wrapper-${contentId}`
  };

  // Copying the HTML under an <a> makes all text be styled as links when pasted in another application. Using a div
  // prevents that, but we still want an a tag for view mode and pdfs.
  const displayWrapper = displayMode === ContentDisplayMode.NORMAL
    ? <div {...displayWrapperProps}>{display}</div>
    : <a {...displayWrapperProps}>{display}</a>;

  return <>
    {displayMode === ContentDisplayMode.NORMAL &&
    <Overlay
      transition={false}
      placement="bottom"
      target={target.current}
      rootClose={true}
      show={showMenu}
      onHide={() => setShowMenu(false)}>
      <Popover id={`contentMenu-${contentId}`}>
        <ContentMenu
          contentId={contentId}
          closeMenu={() => setShowMenu(false)}
          isReact={content.isReact}
          visualization={visualization} />
      </Popover>
    </Overlay>}
    {displayWrapper}
  </>;
};
