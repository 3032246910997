import angular from 'angular';
import _ from 'lodash';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';
import { PLUGIN_CATEGORY } from '@/hybrid/plugin/pluginHost.module';
import { PluginOutputV1 } from '@/sdk';

angular.module('Sq.PluginHost').store('sqPluginStore', sqPluginStore);

export type PluginStore = ReturnType<typeof sqPluginStore>['exports'];

function sqPluginStore() {
  const store = {

    persistenceLevel: PERSISTENCE_LEVEL.WORKSHEET,

    initialize() {
      this.state = this.immutable({
        plugins: this.state?.get?.('plugins') || [], // preserve any existing plugins state
        pluginState: {},
        displayPaneRenderComplete: true
      });
    },

    exports: {
      get plugins() {
        return this.state.get('plugins');
      },

      get pluginState() {
        return this.state.get('pluginState');
      },

      get displayPaneRenderComplete() {
        return this.state.get('displayPaneRenderComplete');
      },

      displayPanePlugins() {
        return _.filter(this.state.get('plugins'), { category: PLUGIN_CATEGORY.DISPLAY_PANE });
      },

      /**
       * Gets a plugin by its pluginIdentifier from the store
       *
       * @param {string} identifier - the plugin identifier
       */
      getPlugin(identifier): PluginOutputV1 {
        return _.chain(this.state.get('plugins')).filter({ identifier }).first().value() as PluginOutputV1;
      }
    },

    dehydrate() {
      return _.omit(this.state.serialize(), ['plugins', 'displayPaneRenderComplete']);
    },

    rehydrate(dehydratedState) {
      this.state.deepMerge(dehydratedState);
    },

    handlers: {
      PLUGINS_SET: 'setPlugins',
      PLUGINS_SET_STATE: 'setPluginState',
      PLUGINS_SET_DISPLAY_PANE_RENDER_COMPLETE: 'setDisplayPaneRenderComplete'
    },

    /**
     * Sets the list of plugins into the store
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} payload.plugins - the new list of plugins to be set into the store
     */
    setPlugins(payload) {
      this.state.set('plugins', payload.plugins);
    },

    /**
     * Sets the plugin state for a given plugin into the store
     *
     * @param {Object} payload - Object container for arguments
     * @param {string} payload.pluginIdentifier - the identifier of the plugin
     * @param {Object} payload.pluginState - the new state
     */
    setPluginState(payload) {
      this.state.set(['pluginState', payload.pluginIdentifier], payload.pluginState);
    },

    /**
     * Sets the display pane render complete into the store
     *
     * @param {Object} payload - Object container for arguments
     * @param {boolean} payload.complete - if the display pane render is complete
     */
    setDisplayPaneRenderComplete(payload) {
      this.state.set('displayPaneRenderComplete', payload.complete);
    }
  };

  return store;
}
