import React, { useEffect } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import { DURATION_TIME_UNITS, DurationTimeUnit } from '@/main/app.constants';
import _ from 'lodash';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

const SUPPORTED_VALUES_BY_UNIT = {
  'DURATION_UNITS.SECONDS': [5, 10, 15, 30],
  'DURATION_UNITS.MINUTES': [1, 5, 10, 15, 30],
  'DURATION_UNITS.HOURS': [1, 2, 4, 6, 8, 12]
};
const SUPPORTED_UNIT_LABELS = Object.keys(SUPPORTED_VALUES_BY_UNIT);
const SUPPORTED_UNITS = _.filter(DURATION_TIME_UNITS, unit => SUPPORTED_UNIT_LABELS.includes(unit.translationKey));

interface DurationSelectorProps {
  unit: DurationTimeUnit;
  value: number;
  onChange: (value: number, unit: DurationTimeUnit) => void;
  onInvalidInput: (message: string) => void;
}

/**
 * Select time durations via dropdowns, with fixed set of options
 */
export const DurationSelector: React.FunctionComponent<DurationSelectorProps> = (props) => {
  const { unit, value, onChange, onInvalidInput } = props;
  const { t } = useTranslation();

  useEffect(() => {
    const validOptions = SUPPORTED_VALUES_BY_UNIT[unit.translationKey];
    if (!validOptions?.includes(value)) {
      onInvalidInput('Unable to parse the schedule');
    }
  });

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = _.toInteger(event.target.value);
    onChange(newVal, unit);
  };

  const onChangeUnit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUnit = DURATION_TIME_UNITS.find(unit => unit.translationKey === event.target.value);
    const newValueOptions = SUPPORTED_VALUES_BY_UNIT[newUnit.translationKey];
    let newValue = value;
    if (!newValueOptions?.includes(value)) {
      newValue = newValueOptions[0];
    }
    onChange(newValue, newUnit);
  };

  return (
    <FormGroup className="flexColumnContainer">
      <FormControl
        data-testid="duration-selector__value"
        as="select"
        className="width-100 mr5"
        value={value.toString()}
        onChange={onChangeValue}>
        {_.map(SUPPORTED_VALUES_BY_UNIT[unit.translationKey], value => (
          <option key={value}>{value}</option>
        ))}
      </FormControl>
      <FormControl
        data-testid="duration-selector__unit"
        as="select"
        className="width-100"
        value={unit.translationKey}
        onChange={onChangeUnit}>
        {_.map(SUPPORTED_UNITS, unit => (
          <option key={unit.translationKey} value={unit.translationKey}>{t(unit.translationKey)}</option>
        ))}
      </FormControl>
    </FormGroup>
  );
};
