import React from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

export interface NoResultsRowIF {
  colspan: number;
}

export const TableBuilderNoResultsRow: React.FunctionComponent<NoResultsRowIF> = (props) => {
  const { colspan } = props;
  const { t } = useTranslation();

  return <tr data-testid="tableBuilderNoResultsRow">
    <td colSpan={colspan} className="text-center bg-warning">{t('NO_RESULTS_DISPLAY_RANGE')}</td>
  </tr>;
};
