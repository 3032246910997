import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import {
  SimpleTableCell,
  SimpleTableRow,
  TableBuilderColumnFilter,
  TableBuilderHeaders
} from '@/hybrid/tableBuilder/tableBuilder.store';
import { TableBuilderHeaderRow } from '@/hybrid/tableBuilder/tableComponents/TableBuilderHeaderRow.molecule';
import { TableBuilderTextHeader } from '@/hybrid/tableBuilder/tableComponents/TableBuilderTextHeader.atom';
import { TableBuilderColumnType, TableBuilderHeaderType } from '@/hybrid/tableBuilder/tableBuilder.module';
import { TableBuilderTextCell } from '@/hybrid/tableBuilder/tableComponents/TableBuilderTextCell.atom';
import { TableBuilderDataCell } from '@/hybrid/tableBuilder/tableComponents/TableBuilderDataCell.atom';
import { TableBuilderHelperService } from '@/hybrid/tableBuilder/tableBuilderHelper.service';
import { TableBuilderRow } from '@/hybrid/tableBuilder/tableComponents/TableBuilderRow.molecule';
import { RangeExport } from '@/trendData/duration.store';
import { TableTextFormatterIF } from '@/hybrid/tableBuilder/TableBuilder.page';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.module';
import { TextHeaderMenuActions } from '@/hybrid/tableBuilder/tableComponents/TableBuilderTextHeaderMenu.atom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TableBuilderNoResultsRow } from '@/hybrid/tableBuilder/TableBuilderNoResultsRow.atom';

const tableBuilderSimpleTableBindings = bindingsDefinition({
  sqTableBuilderHelper: injected<TableBuilderHelperService>(),
  simpleTableData: prop<SimpleTableRow[]>(),
  isLoading: prop<boolean>(),
  columns: prop<any[]>(),
  headers: prop<TableBuilderHeaders>(),
  isTransposed: prop<boolean>(),
  isStriped: prop<boolean>(),
  isPresentationMode: prop<boolean>(),
  isViewOnlyMode: prop<boolean>(),
  displayRange: prop<RangeExport>(),
  timezone: prop<{ name: string }>(),
  displayMetricOnTrend: prop<(metricId: string, formulaItemId: string, start: number, end: number,
    event: any) => void>(),
  setCellText: prop<(key: string, value: string, id?: string) => void>(),
  setHeaderText: prop<(columnKey: string, text: string) => void>(),
  setColumnFilter: prop<(key: string, filter: TableBuilderColumnFilter) => void>(),
  hasNumericAndStringSeries: prop<boolean>(),
  hasOnlyStringSeries: prop<boolean>(),
  hasNumericAndStringMetrics: prop<boolean>(),
  hasOnlyStringMetrics: prop<boolean>(),
  columnToThresholds: prop<Object>(),
  distinctStringValueMap: prop<{ [columnKey: string]: string[] }>(),
  textFormatter: prop<TableTextFormatterIF>(),
  moveColumn: prop<(key: string, newKey: string) => void>(),
  canSort: prop<boolean>(),
  maxSortLevel: prop<number>(),
  sortByColumn: prop<(key: string, direction: string) => void>(),
  removeColumn: prop<(key: string) => void>()
});

export const TableBuilderSimpleTable: SeeqComponent<typeof tableBuilderSimpleTableBindings> = (props) => {
  const {
    sqTableBuilderHelper
  } = useInjectedBindings(tableBuilderSimpleTableBindings);

  const {
    simpleTableData,
    isLoading,
    columns,
    headers,
    isStriped,
    isTransposed,
    isPresentationMode,
    isViewOnlyMode,
    displayRange,
    timezone,
    displayMetricOnTrend,
    setCellText,
    setHeaderText,
    setColumnFilter,
    hasNumericAndStringSeries,
    hasOnlyStringSeries,
    hasNumericAndStringMetrics,
    hasOnlyStringMetrics,
    columnToThresholds,
    distinctStringValueMap,
    textFormatter,
    canSort,
    maxSortLevel,
    moveColumn,
    removeColumn,
    sortByColumn
  } = props;

  const { t } = useTranslation();

  const canEdit = !isPresentationMode && !isViewOnlyMode;

  let columnIndex = 0;

  const hasUnitColumn = _.some(columns, column => column.key === COLUMNS_AND_STATS.valueUnitOfMeasure.key);

  const getExtraHeaderProps = (column): { isInput: boolean, isStatic?: boolean, textValue: string } => {
    if (column.headerOverridden) {
      const textValue = sqTableBuilderHelper.formatHeader(headers, column.property, displayRange.start.valueOf(),
        displayRange.end.valueOf(), timezone);
      return { textValue, isInput: false, isStatic: true };
    }
    // check for undefined. Empty string is OK - the user removed the title
    const textValue = column.header ??
      (column.type === TableBuilderColumnType.Property ? column.key : t(column.shortTitle));
    return { textValue, isInput: true };
  };

  const getMenuActions = (columnType: string) => {
    if (isPresentationMode) {
      return [];
    }

    if (columnType === TableBuilderColumnType.Text) {
      return isViewOnlyMode ? [] :
        _.values(TextHeaderMenuActions).filter(action =>
          !_.includes([TextHeaderMenuActions.Filter, TextHeaderMenuActions.Sort], action));
    } else {
      return isViewOnlyMode ? [TextHeaderMenuActions.Filter, TextHeaderMenuActions.Sort] :
        _.values(TextHeaderMenuActions);
    }
  };

  const textHeaders: JSX.Element[] = _.map(columns, (column) => {
    const extraProps = getExtraHeaderProps(column);
    const isStringColumn = _.includes(['string', 'assets', 'fullpath'], column.style)
      || column.isCustomProperty
      || (hasOnlyStringSeries && column.key === COLUMNS_AND_STATS['statistics.endValue'].key)
      || (hasOnlyStringMetrics && column.key === COLUMNS_AND_STATS.metricValue.key);
    const columnThresholds = !!column.stat && !!columnToThresholds[column.stat]
      ? columnToThresholds[column.stat]
      : column.key === 'metricValue' && !!columnToThresholds['metricValue']
        ? columnToThresholds['metricValue']
        : undefined;
    // Don't let users filter
    // - stat columns when string and numeric signals are in the table
    // - the metricValue column when string and numeric metrics are in the table
    // - when we are in presentation mode
    const isFilterDisabled = (column.key === 'metricValue' && hasNumericAndStringMetrics)
      || (column.stat && hasNumericAndStringSeries)
      || isPresentationMode;

    return <TableBuilderTextHeader
      textValue={extraProps.textValue}
      isInput={extraProps.isInput}
      isStatic={extraProps.isStatic}
      onTextChange={value => setHeaderText(column.key, value)}
      columnBackgroundColor={column.backgroundColor}
      columnTextAlign={column.textAlign}
      columnTextColor={column.textColor}
      columnTextStyle={column.textStyle}
      headerBackgroundColor={column.headerBackgroundColor}
      headerTextAlign={column.headerTextAlign}
      headerTextColor={column.headerTextColor}
      headerTextStyle={column.headerTextStyle}
      key={columnIndex}
      columnKey={column.key}
      columnIndex={columnIndex++}
      canEdit={canEdit}
      isTransposed={isTransposed}
      sort={{
        canSort,
        canSortDisabledTooltip: 'TABLE_BUILDER.ONLY_ON_TABLE_WITH_ONE_ITEM',
        maxSortLevel,
        sortDirection: column.sort?.direction,
        sortLevel: column.sort?.level,
        sortByColumn
      }}
      setColumnFilter={setColumnFilter}
      columnFilter={column.filter}
      isFilterDisabled={isFilterDisabled}
      distinctStringValues={distinctStringValueMap?.[column.key]}
      menuActions={getMenuActions(column.type)}
      isStringColumn={isStringColumn}
      thresholds={columnThresholds}
      textFormatter={textFormatter}
      moveColumn={moveColumn}
      removeColumn={removeColumn}
    />;
  });

  const renderTextCell = (row, column, cell, maybeStripedColor, key, columnIndex) => {
    return <TableBuilderTextCell
      columnIndex={columnIndex}
      key={key}
      textValue={column.cells && column.cells[row.itemId]}
      style={sqTableBuilderHelper.computeCellStyle(column.backgroundColor, column.textColor, column.textStyle,
        column.textAlign, cell.priorityColor, maybeStripedColor)}
      onTextChange={value => setCellText(column.key, value, row.itemId)}
      canEditCellText={canEdit}
    />;
  };

  const renderDataCell = (row, column, cell, maybeStripedColor, key, columnIndex) => {
    return <TableBuilderDataCell
      columnIndex={columnIndex}
      extraClassNames={cell.metricId ? 'cursorPointer text-underline-onhover' : ''}
      key={key}
      style={sqTableBuilderHelper.computeCellStyle(column.backgroundColor, column.textColor, column.textStyle,
        column.textAlign, cell.priorityColor, maybeStripedColor)}
      textValue={cell.value}
      hasUnitColumn={hasUnitColumn}
      units={cell.units}
      onClick={event => cell.metricId && displayMetricOnTrend(cell.metricId, row.itemId,
        displayRange.start.valueOf(), displayRange.end.valueOf(), event)}
    />;
  };

  const renderCell = (row, column, cell, maybeStripedColor, key, columnIndex) =>
    column.type === TableBuilderColumnType.Text ?
      renderTextCell(row, column, cell, maybeStripedColor, key, columnIndex) :
      renderDataCell(row, column, cell, maybeStripedColor, key, columnIndex);

  const createTransposedTableRows = () => {
    const rows = _.map(columns, (column, columnIndex) => {
      const headerProps = { ...(textHeaders[columnIndex].props), key: 0 };

      const maybeStripedColor = sqTableBuilderHelper.getStripedColor(isStriped, columnIndex);

      // allocate unique key values to avoid duplicate key error. start with next headerCell.key
      let key = headerProps.key + 1;
      const textCells: any = _.map(simpleTableData, (row) => {
        const cell = row.cells[columnIndex] ?? {} as SimpleTableCell;
        const element = renderCell(row, column, cell, maybeStripedColor, key, columnIndex);
        key++;
        return element;
      });
      return <TableBuilderHeaderRow key={columnIndex}>
        {headers.type !== TableBuilderHeaderType.None && <TableBuilderTextHeader {...headerProps} />}
        {textCells}
      </TableBuilderHeaderRow>;
    });

    if (!isLoading && _.isEmpty(simpleTableData)) {
      rows.push(<TableBuilderNoResultsRow key={columns.length} colspan={1} />);
    }

    return rows;
  };

  const createNonTransposedTableRows = () => {
    const rows = _.map(simpleTableData, (row, rowIndex) => {
      const maybeStripedColor = sqTableBuilderHelper.getStripedColor(isStriped, rowIndex);
      const cells = _.map(columns, (column, columnIndex) => {
        const cell = row.cells[columnIndex] ?? {} as SimpleTableCell;
        return renderCell(row, column, cell, maybeStripedColor, columnIndex, columnIndex);
      });
      return <TableBuilderRow key={rowIndex}>{cells}</TableBuilderRow>;
    });

    if (!isLoading && _.isEmpty(simpleTableData)) {
      rows.push(<TableBuilderNoResultsRow colspan={columns.length} />);
    }

    return rows;
  };

  return <DndProvider backend={HTML5Backend}>
    <table data-testid={isTransposed ? 'simpleTableTransposed' : 'simpleTable'}
      className={classNames('table table-md-condensed width-auto screenshotSizeToContent',
        isTransposed ? 'table-bordered-first-column' : 'table-bordered',
        isTransposed ? 'fixedColumnTable' : 'fixedHeaderTable')}>
      {isTransposed &&
      <tbody>
        {createTransposedTableRows()}
      </tbody>}
      {!isTransposed && <>
        <thead>
          {headers.type !== TableBuilderHeaderType.None &&
          <TableBuilderHeaderRow>
            {textHeaders}
          </TableBuilderHeaderRow>}
        </thead>
        <tbody>
          {createNonTransposedTableRows()}
        </tbody>
      </>}
    </table>
  </DndProvider>;
};
