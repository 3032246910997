import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ButtonWithDropdown } from '@/hybrid/core/ButtonWithDropdown.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { NotificationsService } from '@/services/notifications.service';
import { NotifierService } from '@/services/notifier.service';
import _ from 'lodash';
import { AuthorizationService } from '@/services/authorization.service';
import { Dropdown } from 'react-bootstrap';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { LicenseManagementStore } from '@/licenseManagement/licenseManagement.store';
import { LICENSE_STATUS } from '@/licenseManagement/licenseManagement.module';
import { LogSenderModal } from '@/hybrid/logSender/LogSenderModal';

const hamburgerMenuBindings = bindingsDefinition({
  $window: injected<ng.IWindowService>(),
  sqAuthorization: injected<AuthorizationService>(),
  sqLicenseManagementStore: injected<LicenseManagementStore>(),
  sqPendingRequests: injected<PendingRequestsService>(),
  toggleFullScreen: prop<() => void>(),
  initialFullScreenState: prop<boolean>(),
  sqNotifier: injected<NotifierService>(),
  sqNotifications: injected<NotificationsService>()
});

export const HamburgerMenu: SeeqComponent<typeof hamburgerMenuBindings> = ({
  toggleFullScreen,
  initialFullScreenState
}) => {

  const [isFullScreen, setIsFullScreen] = useState(initialFullScreenState);
  const [showLogSenderModal, setShowLogSenderModal] = useState(false);

  const exitHandler = () => {
    if (!document.fullscreenElement && !document['webkitIsFullScreen'] && !document['mozFullScreen'] && !document['msFullscreenElement']) {
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('webkitfullscreenchange', exitHandler);
    document.addEventListener('mozfullscreenchange', exitHandler);
    document.addEventListener('MSFullscreenChange', exitHandler);
    return () => {
      document.removeEventListener('fullscreenchange', exitHandler);
      document.removeEventListener('webkitfullscreenchange', exitHandler);
      document.removeEventListener('mozfullscreenchange', exitHandler);
      document.removeEventListener('MSFullscreenChange', exitHandler);
    };
  }, []);

  const { t } = useTranslation();
  const {
    $window,
    sqAuthorization,
    sqLicenseManagementStore,
    sqPendingRequests,
    sqNotifier,
    sqNotifications
  } = useInjectedBindings(hamburgerMenuBindings);
  const licenseStatus = useFluxPath(sqLicenseManagementStore, () => sqLicenseManagementStore.licenseStatus);
  const accessibleWithCurrentLicense = !_.includes(
    [LICENSE_STATUS.NONE, LICENSE_STATUS.UNKNOWN, LICENSE_STATUS.EXPIRED], licenseStatus);

  const openApiReference = () => {
    // Construct a new URL to the apiReference page using, using the current protocol/host/port
    const swaggerUrl = '/api-docs/?baseUrl=' + $window.encodeURIComponent(APPSERVER_API_PREFIX + '/swagger.json');
    $window.open(swaggerUrl);
  };

  const cancelCurrentUserServerRequests = () => {
    return sqPendingRequests.cancelCurrentUserServerRequests()
      .then(_.partial(sqNotifications.successTranslate, 'REQUEST_CANCELLATION.SUCCESS_USER'))
      .catch((result) => {
          sqNotifications.warnTranslate('REQUEST_CANCELLATION.FAILURE');
          return result;
        }
      );
  };

  const cancelAllServerRequests = () => {
    return sqPendingRequests.cancelAllServerRequests()
      .then(sqNotifier.emitAllServerRequestsCanceled)
      .then(_.partial(sqNotifications.successTranslate, 'REQUEST_CANCELLATION.SUCCESS_ALL'))
      .catch((result) => {
        sqNotifications.warnTranslate('REQUEST_CANCELLATION.FAILURE');
        return result;
      });
  };

  const openAdminTools = () => {
    $window.open('/administration', 'SeeqAdmin');
  };

  const fullScreenAction = () => {
    toggleFullScreen();
    setIsFullScreen(!isFullScreen);
  };

  const renderDropdownEntry = ({
    href = '#',
    iconClass = '',
    translationKey = '',
    action = _.noop,
    display = false,
    divider = false,
    testId = ''
  }, index) => {
    if (display) {
      return (
        <Dropdown.Item href={href} key={`dropdown_${translationKey}`} onClick={action} target="_blank"
          className="sq-force-text-gray" data-testid={testId}>
          <Icon icon={`${iconClass} fa-fw`} extraClassNames="pr10" type="inherit" />
          <span className="forceFont" data-testid={`moreActions_${translationKey}`}>{t(translationKey)}</span>
        </Dropdown.Item>
      );
    }
    if (divider) {
      return (
        <div className="divider" key={index} />
      );
    }
  };

  const actions = [
    {
      href: '#',
      iconClass: isFullScreen ? 'fa-compress' : 'fa-expand',
      action: fullScreenAction,
      translationKey: isFullScreen ? 'RESTORE' : 'FULLSCREEN',
      display: true,
      testId: 'fullScreen'
    }, {
      divider: true
    }, {
      href: 'http://success.seeq.com',
      iconClass: 'fa-question-circle',
      translationKey: 'LEARN_MORE',
      display: true,
      testId: 'learnMore'
    }, {
      divider: true
    }, {
      href: '#',
      iconClass: 'fa-code',
      translationKey: 'API_REFERENCE',
      action: openApiReference,
      display: accessibleWithCurrentLicense,
      testId: 'apiReference'
    }, {
      href: '#',
      iconClass: 'fa-cog',
      translationKey: 'ADMINISTRATION',
      action: openAdminTools,
      display: sqAuthorization.isAdmin() && accessibleWithCurrentLicense,
      testId: 'administration'
    }, {
      href: 'license',
      iconClass: 'fa-file-archive-o',
      translationKey: 'LICENSE.LICENSE_MANAGEMENT',
      display: sqAuthorization.isAdmin(),
      testId: 'licenseManagement'
    }, {
      href: '#',
      iconClass: 'fa-support',
      translationKey: 'SUPPORT_REQUEST',
      action: () => setShowLogSenderModal(true),
      display: accessibleWithCurrentLicense,
      testId: 'supportRequest'
    }, {
      href: 'logs',
      iconClass: 'fa-file-text-o',
      translationKey: 'LOGS.VIEW_LOGS',
      display: sqAuthorization.canViewLogs() && accessibleWithCurrentLicense,
      testId: 'viewLogs'
    }, {
      href: 'auditTrail',
      iconClass: 'fas fa-th-list',
      translationKey: 'AUDIT_TRAIL.VIEW_ENTRIES',
      display: sqAuthorization.canReadAuditTrail(),
      testId: 'viewEntries'
    }, {
      divider: true
    }, {
      href: '#',
      iconClass: 'fa-user-times',
      translationKey: 'REQUEST_CANCELLATION.CANCEL_USER',
      action: cancelCurrentUserServerRequests,
      display: true,
      testId: 'requestCancellationUser'
    }, {
      href: '#',
      iconClass: 'fa-ban',
      translationKey: 'REQUEST_CANCELLATION.CANCEL_ALL',
      action: cancelAllServerRequests,
      display: sqAuthorization.canCancelAllRequests(),
      testId: 'requestCancellationAll'
    }
  ];

  return <>
    <ButtonWithDropdown
      icon={<Icon
        icon="fa-bars"
        extraClassNames="fa-xlg cursorPointer sq-navbar-default flexNoGrowNoShrink pr12"
        type="white" testId="hamburgerMenu" id="options" />}>
      {_.map(actions, (action, index) => renderDropdownEntry(action, index))}
    </ButtonWithDropdown>
    {showLogSenderModal && <LogSenderModal onClose={() => setShowLogSenderModal(false)} />}
  </>;
};

export const sqHamburgerMenu = angularComponent(hamburgerMenuBindings, HamburgerMenu);

