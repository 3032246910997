import _ from 'lodash';
import { BaseToolStoreService } from '@/investigate/baseToolStore.service';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { DURATION_TIME_UNITS, STRING_UOM } from '@/main/app.constants';
import { UtilitiesService } from '@/services/utilities.service';
import { INTERPOLATION_TYPES } from '@/hybrid/tools/manualSignal/manualSignal.module';

export type ManualSignalStore = ReturnType<typeof sqManualSignalStore>['exports'];

export function sqManualSignalStore(
  sqBaseToolStore: BaseToolStoreService,
  sqUtilities: UtilitiesService
) {
  const store = {
    initialize() {
      this.state = this.immutable(_.assign({}, sqBaseToolStore.COMMON_PROPS, {
        interpolationMethod: INTERPOLATION_TYPES.LINEAR,
        maximumInterpolation: { value: '40', units: DURATION_TIME_UNITS[2].unit[0] },
        uom: undefined,
        samples: [],
        editingSampleId: undefined,
        sort: { asc: false, property: 'dateTime' }
      }));
    },

    exports: {
      get interpolationMethod() {
        return this.state.get('interpolationMethod');
      },

      get maximumInterpolation() {
        return this.state.get('maximumInterpolation');
      },

      get uom() {
        return this.state.get('uom');
      },

      get samples() {
        return this.state.get('samples');
      },

      get editingSampleId() {
        return this.state.get('editingSampleId');
      },

      get sort() {
        return this.state.get('sort');
      }
    },

    dehydrate() {
      return _.omit(this.state.serialize(), 'editingSampleId');
    },

    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      MANUAL_SIGNAL_SET_INTERPOLATION_METHOD: 'setInterpolationMethod',
      MANUAL_SIGNAL_SET_MAXIMUM_INTERPOLATION: 'setMaximumInterpolation',
      MANUAL_SIGNAL_SET_UOM: 'setUom',
      MANUAL_SIGNAL_ADD_SAMPLE: 'addSample',
      MANUAL_SIGNAL_REMOVE_SAMPLE: 'removeSample',
      MANUAL_SIGNAL_UPDATE_SAMPLE: 'updateSample',
      MANUAL_SIGNAL_SET_EDITING_SAMPLE_ID: 'setEditingSampleId',
      MANUAL_SIGNAL_SET_SORT: 'setSort'
    },

    setInterpolationMethod({ interpolationMethod }) {
      this.state.set('interpolationMethod', interpolationMethod);
    },

    setMaximumInterpolation({ maximumInterpolation }) {
      this.state.set('maximumInterpolation', maximumInterpolation);
    },

    setUom({ uom }) {
      this.state.set('uom', uom);
    },

    addSample({ sample }) {
      // Help the user out by setting the Unit of Measure if the first value they enter is a string
      if (this.state.get('samples').length === 0 && _.isUndefined(this.state.get('uom')) &&
        _.isNaN(_.toNumber(sample.value))) {
        this.setUom({ uom: STRING_UOM });
      }

      const newSample = _.assign(sample, { id: sqUtilities.base64guid() });
      this.state.set('samples', [...this.state.get('samples'), newSample]);
    },

    removeSample({ id }) {
      const index = _.findIndex(this.state.get('samples'), ['id', id]);
      if (index > -1) {
        this.state.unset(['samples', index]);
      }
    },

    updateSample({ sample }) {
      const index = _.findIndex(this.state.get('samples'), ['id', sample.id]);
      const existingSample = this.state.select('samples', index);

      if (existingSample.exists()) {
        existingSample.merge(sample);
      }
    },

    setEditingSampleId({ editingSampleId }) {
      this.state.set('editingSampleId', editingSampleId);
    },

    setSort({ sort }) {
      this.state.set('sort', sort);
    }
  };

  return sqBaseToolStore.extend(store, TREND_TOOLS.MANUAL_SIGNAL);
}
