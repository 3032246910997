import React, { useState } from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { Field, useForm } from 'react-final-form';
import {
  getFormFieldProps,
  getValidationFunction
} from '@/hybrid/formbuilder/formbuilder.utilities';
import { FormError } from '@/hybrid/core/FormError.atom';
import classNames from 'classnames';
import { HelpIcon } from '@/hybrid/core/Icon.atom';

export interface PatternIF extends ValidatingFormComponent<string> {
  component: 'PatternFormComponent';
  min: number;
  max: number;
  value: string;
  label: string;
  customErrorText?: string;
}

export const PatternFormComponent: React.FunctionComponent<PatternIF> = (
  props
) => {
  const {
    name,
    testId = 'pattern',
    label,
    tooltip,
    min,
    max,
    validation,
    extendValidation,
    extraClassNames,
    customErrorText
  } = props;

  const defaultValidation = () => min === max;

  const appliedValidation = getValidationFunction(
    defaultValidation,
    extendValidation,
    validation
  );

  const { t } = useTranslation();
  const formState = useForm().getState();
  const [showError, setShowError] = useState(false);

  return (
    <div className="flexRowContainer">
      <div
        className={classNames('mb8', 'flexColumnContainer', extraClassNames)}
        data-testid={testId}
      >
        <Field name={name} validate={appliedValidation}>
          {({ input, meta }) => {
            const formFieldProps = getFormFieldProps(
              formState,
              input,
              meta,
              props
            );
            setTimeout(() => setShowError(formFieldProps.showError), 0);

            return <div className="flexColumnContainer">
              <div>{t(label)}</div>
              {tooltip &&
                <HelpIcon tooltip={tooltip} extraClassNames="text-interactive pl5" />
              }
            </div>;
          }}
        </Field>
      </div>
      {showError && customErrorText && (
        <FormError errorText={t(customErrorText)} dataTestId={`${testId}Error`} />
      )}
    </div>
  );
};
