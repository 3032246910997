import angular from 'angular';
import template from './referenceCapsuleIcon.component.html';

/**
 * @file Shows an pattern icon for the specified item if the item is the profile search reference pattern.
 */
angular.module('Sq.TrendViewer').component('sqReferenceCapsuleIcon', {
  template,
  controller: 'ReferenceCapsuleIconCtrl',
  bindings: {
    item: '<'
  }
});
