import _ from 'lodash';
import angular from 'angular';
import log4javascript from 'log4javascript';
import moment from 'moment-timezone';
import { UtilitiesService } from '@/services/utilities.service';
import { HttpHelpersService } from '@/services/httpHelpers.service';
import { HTTP_MAX_BODY_SIZE_BYTES } from '@/main/app.constants';
import { browserIsFirefox } from '@/utilities/browserId';
import { AuthenticationService } from '@/services/authentication.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';

/**
 * Logging service that sends log messages to the browser console and to server logs.
 */
angular.module('Sq.Services.Logger', ['Sq.Services.Utilities'])
  .factory('sqLogger', sqLogger);

export type LoggerFunction = (message: string, category?: string) => void;

export type LoggerService = ReturnType<typeof sqLogger>;

function sqLogger(
  $injector: ng.auto.IInjectorService,
  $log: ng.ILogService,
  sqUtilities: UtilitiesService) {

  // Callbacks that receive a log message and scrub sensitive information from it
  const SENSITIVE_INFO_SCRUBBERS = [
    text => text.replace($injector.get<AuthenticationService>('sqAuthentication').getCsrfToken(), '****'),
    // Extra precaution to remove from JSON a csrf token that is not the same as what is returned by #getCsrfToken()
    text => text.replace(new RegExp('("' + SeeqNames.API.Headers.Csrf + '"\s*:\s*")(.*?)"', 'g'), '$1****"')
  ];
  const service = {
    trace: _.partial(log, 'trace') as LoggerFunction,
    debug: _.partial(log, 'debug') as LoggerFunction,
    info: _.partial(log, 'info') as LoggerFunction,
    warn: _.partial(log, 'warn') as LoggerFunction,
    error: _.partial(log, 'error') as LoggerFunction,
    fatal: _.partial(log, 'fatal') as LoggerFunction,
    track,
    format
  };

  configureLogger();

  return service;

  /**
   * Configures the logger to log to console as well as an AJAX endpoint so that errors are logged to client.log
   */
  function configureLogger() {
    // Add .trace() and .fatal() to $log
    $log.trace = _.get($log, 'trace', $log.log);
    $log.fatal = _.get($log, 'fatal', $log.error);

    if ((<any>angular).mock) {
      // If we are running unit tests, don't configure the logger. In unit tests this module is mocked, but it will
      // still be instantiated over and over again and we don't want to add thousands of 'AjaxAppender's to the root
      // logger that we are not going to use.
      return;
    }

    const ajaxAppender = new log4javascript.AjaxAppender('/error-log');
    const jsonLayout = new log4javascript.JsonLayout();
    const logger = log4javascript.getLogger(LOG_ROOT_NAME);

    log4javascript.logLog.setQuietMode(true); // Don't throw popups if it has problems

    // Note the ajaxAppender with the JSON layout can also batch requests instead of sending each log individually
    ajaxAppender.addHeader('Content-Type', 'application/json;charset=utf-8');
    ajaxAppender.setLayout(jsonLayout);
    // Configure log4javascript object
    logger.setLevel(log4javascript.Level.DEBUG);
    logger.addAppender(ajaxAppender);

    const usageLogger = log4javascript.getLogger('usage');
    const usageAjaxAppender = new log4javascript.AjaxAppender('/usage');
    usageAjaxAppender.addHeader('Content-Type', 'application/json;charset=utf-8');
    usageAjaxAppender.setLayout(jsonLayout);
    usageLogger.addAppender(usageAjaxAppender);
  }

  /**
   * Tracks an event.
   *
   * @param {String} event - Event to log. Note that tracking events are assumed to be shorter than
   *   HTTP_MAX_BODY_SIZE_BYTES.
   */
  function track(event) {
    const logger = log4javascript.getLogger('usage');
    if (!sqUtilities.headlessRenderMode()) {
      logger['info'](event);
    }
  }

  /**
   * Logs a message at the specified level.
   *
   * @param {String} level - One of the allowed levels provided by log4javascript
   * @param {String|Error} message - Message to log. Note that a message longer than HTTP_MAX_BODY_SIZE_BYTES will be
   *   truncated when POSTed via HTTP.
   * @param {String} [category] - A category that will allow log messages to be grouped (e.g. sqLogger.chart)
   */
  function log(level, message, category?) {
    message = formatValue(message);
    const timestamp = moment().format('HH:mm:ss.SSS');
    const loggerName = _.isUndefined(category) ? LOG_ROOT_NAME : LOG_ROOT_NAME + '.' + category;
    const logger = log4javascript.getLogger(loggerName);
    const ellipses = message.length > HTTP_MAX_BODY_SIZE_BYTES ? ' ...[truncated]' : '';

    _.get($log, level, $log.log)(timestamp + ' [' + loggerName + '] ' + message);

    if (!sqUtilities.headlessRenderMode()) {
      logger[level](message.slice(0, HTTP_MAX_BODY_SIZE_BYTES) + ellipses);
    }
  }

  /**
   * Tagged template literal, formats inputs suitable for logging with the most information
   *
   * @example format `Error with ${object}: ${ex}`
   * @param {string[]} strings - array built of the string components of the template (ex: ['Error with ', ': ', ''])
   * @param {any[]} values - array built of the value components of the template (ex: [object, ex])
   */
  function format(strings: TemplateStringsArray, ...values: any[]) {
    const result: string[] = [];
    for (let x = 0; x < strings.length; x++) {
      if (x !== 0) {
        result.push(formatValue(values[x - 1]));
      }

      result.push(strings[x]);
    }

    return _.join(result, '');
  }

  /**
   * Formats the value in a format suitable for logging with the most information
   *
   * @param {any} value - the value to format as a string
   */
  function formatValue(value: any): string {
    try {
      if (_.isString(value)) {
        return scrubSensitiveData(value);
      } else if (_.isNil(value)) {
        return String(value); // Get a nice string like "null" or "undefined"
      } else if (_.isError(value)) {
        if (browserIsFirefox) {
          // Firefox only includes the stack in Error#stack which loses the error message; also indent the stack
          return [value.toString(), ...value.stack.split('\n')].join('\n  ');
        } else {
          // Use the non-standard 'stack' property to for a more complete message or fallback to a normal toString
          return _.get(value, 'stack', value.toString());
        }
      } else if (_.isArray(value) || _.isPlainObject(value)) {
        const sqHttpHelpers = $injector.get<HttpHelpersService>('sqHttpHelpers');
        // Special case objects that look like they are angularjs http responses
        if (sqHttpHelpers.isHttpResponse(value)) {
          const path = _.split(value.config.url, '?', 1)[0];
          const response = format`${value.config.method} ${path} ${value.status} ${value.statusText}`;
          if (!_.isEmpty(_.get(value, 'data.statusMessage'))) {
            // Appserver includes a statusMessage in error responses
            return format`${value.data.statusMessage} (${response})`;
          } else if (!_.isNil(value.data) && value.status >= 300) {
            // Include the body of the request for errors since we assume the contents will be small
            return format`${value.data} (${response})`;
          } else {
            return response;
          }
        }

        // Special case object that look like they are angularjs http config objects
        if (sqHttpHelpers.isHttpConfig(value)) {
          const path = _.split(value.url, '?', 1)[0];
          const requestId = value.headers[SeeqNames.API.Headers.RequestId];
          if (requestId) {
            return format`${value.method} ${path} (${requestId})`;
          } else {
            return format`${value.method} ${path}`;
          }
        }

        // Use JSON.stringify to represent the object or array; convert things other than an array or object to strings
        return JSON.stringify(value, (replacerKey, replacerValue) => {
          if (!_.isArray(replacerValue) && !_.isPlainObject(replacerValue)) {
            return formatValue(replacerValue);
          } else {
            return replacerValue;
          }
        });
      } else if (_.isFunction(value)) {
        return `[Function] ${value.name || '(anonymous)'}`;
      } else if (_.isObject(value)) {
        const type = value.constructor.name || 'anonymous';
        return `[${type}] ${value.toString()}`;
      } else {
        return scrubSensitiveData(_.toString(value));
      }
    } catch (ex) {
      return `[${ex.toString()} when formatting]`;
    }
  }

  /**
   * Helper that removes sensitive information from a string.
   *
   * @param {string} value - The value to scrub
   * @return {string} The scrubbed string
   */
  function scrubSensitiveData(value) {
    return _.reduce(SENSITIVE_INFO_SCRUBBERS, (memo, scrubber) => scrubber(memo), value);
  }
}

// TODO: Cody Ray Hoeft - log4javascript already has a root logger (i.e., .getRootLogger()) do we still need our own?
export const LOG_ROOT_NAME = 'sqLogger';
