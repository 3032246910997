import _ from 'lodash';
import angular from 'angular';
import { DOCUMENT_LAYOUT, DOCUMENT_MARGIN_UNITS, DOCUMENT_PAPER_SIZE } from '@/reportEditor/report.module';
import { LoggerService } from '@/services/logger.service';

/**
 * Service providing PDF export parameter actions
 */
angular.module('Sq.Report').service('sqPdfExportActions', sqPdfExportActions);
export type PdfExportActions = ReturnType<typeof sqPdfExportActions>;

function sqPdfExportActions(flux: ng.IFluxService, sqLogger: LoggerService) {
  const service = {
    setLayout,
    setPaperSize,
    setMargin,
    setShowModal
  };

  return service;

  /**
   * Sets the page layout for the export.
   *
   * @param {Object} layout - One of DOCUMENT_LAYOUT
   */
  function setLayout(layout) {
    if (!_.some(DOCUMENT_LAYOUT, { value: layout.value })) {
      throw new TypeError(sqLogger.format`'${layout}' is not a supported layout`);
    }

    flux.dispatch('PDF_EXPORT_SET_LAYOUT', layout);
  }

  /**
   * Sets the page size for the export.
   *
   * @param {Object} paperSize - One of DOCUMENT_PAPER_SIZE
   */
  function setPaperSize(paperSize) {
    if (!_.some(DOCUMENT_PAPER_SIZE, { value: paperSize.value })) {
      throw new TypeError(sqLogger.format`'${paperSize}' is not a supported paper size`);
    }

    flux.dispatch('PDF_EXPORT_SET_PAPER_SIZE', paperSize);
  }

  /**
   * Sets the margins for the export.
   *
   * @param {Number} value - Value of the margin.
   * @param {String} unit - Units for the value. One of DOCUMENT_MARGIN_UNITS.
   */
  function setMargin(value, unit) {
    const num = parseFloat(value);
    if (!_.isFinite(num) || num < 0) {
      throw new TypeError(sqLogger.format`'${value}' is not a valid margin value`);
    }

    if (!_.some(DOCUMENT_MARGIN_UNITS, { shortLabel: unit })) {
      throw new TypeError(sqLogger.format`'${unit}' is not a supported unit for margins`);
    }

    flux.dispatch('PDF_EXPORT_SET_MARGIN', { value: num, units: unit });
  }

  function setShowModal(showModal: boolean) {
    flux.dispatch('PDF_EXPORT_SET_SHOW_MODAL', { showModal });
  }
}
