import React, { useState } from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import classNames from 'classnames';
import _ from 'lodash';
import { ReportContentActions } from '@/reportEditor/reportContent.actions';
import { ReportContentStore } from '@/reportEditor/reportContent.store';
import { FroalaReportContentService } from '@/reportEditor/froalaReportContent.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';

const reportContentUrlBindings = bindingsDefinition({
  sqReportContentActions: injected<ReportContentActions>(),
  sqReportContentStore: injected<ReportContentStore>(),
  sqFroalaReportContent: injected<FroalaReportContentService>(),
  sqReportContent: injected<ReportContentService>()
});

export const ReportContentUrl: SeeqComponent<typeof reportContentUrlBindings> = () => {
  const {
    sqReportContentActions,
    sqReportContentStore,
    sqFroalaReportContent,
    sqReportContent
  } = useInjectedBindings(
    reportContentUrlBindings);
  const { t } = useTranslation();
  const { sourceUrl } = useFlux(sqReportContentStore);
  const [invalidReason, setInvalidReason] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [currentContentUrl, setCurrentContentUrl] = useState(sourceUrl);

  const validateContentUrl = (url: string) => {
    setCurrentContentUrl(url);
    setInvalidReason('');
    if (_.isEmpty(url)) {
      setIsInvalid(false);
      return;
    }

    if (!_.isEmpty(url) && !sqFroalaReportContent.isWorksheetUrl(url)) {
      setIsInvalid(true);
      sqReportContentActions.setSourceUrl(undefined);
    } else {
      sqReportContentActions.setSourceUrl(url);
      sqFroalaReportContent.getWorksheetUrlParams(url)
        .then((params) => {
          setIsInvalid(false);
          sqReportContentActions.setWorkbookId(params.workbookId);
          sqReportContentActions.setWorksheetId(params.worksheetId);
          sqReportContentActions.setWorkstepId(params.workstepId);
          sqReportContent.evaluateVisualizationOptions(params.workbookId, params.worksheetId, params.workstepId);
        })
        .catch((e) => {
          setIsInvalid(true);
          setInvalidReason((e?.data?.statusMessage || e) + ' -');
          sqReportContentActions.setSourceUrl(undefined);
        });
    }
  };

  return <div className="reportContentUrl flexRowContainer" data-testid="report-content-link">
    <div className="alert alert-info alert-condensed mt0">
      {t('REPORT.CONTENT.LINK_HELP')}
    </div>
    <div className='flexColumnContainer flexSpaceBetween'>
      {isInvalid ?
        <h4 className="mt0 sq-text-danger">
          {invalidReason} {t('REPORT.CONTENT.LINK_INVALID')}
        </h4> :
        (!_.isEmpty(currentContentUrl)
          && <h4 className="mt0 sq-text-success">
            {t('REPORT.CONTENT.LINK_VALID')}
          </h4>)}
    </div>
    <textarea
      className={classNames('flexFill', {
        'sq-bg-danger': isInvalid,
        'sq-bg-success': !isInvalid && !_.isEmpty(currentContentUrl)
      })}
      placeholder={t('REPORT.CONTENT.LINK_PLACEHOLDER')}
      onChange={e => validateContentUrl(e.target.value)}
      value={currentContentUrl} />
  </div>;
};
