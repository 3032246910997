import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { TrackService } from '@/track/track.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { Modal } from 'react-bootstrap';
import { ReportActions } from '@/reportEditor/report.actions';
import { ReportStore } from '@/reportEditor/report.store';
import { ReportContentBulkEditProperties } from '@/hybrid/reportEditor/ReportContentBulkEditProperties.molecule';
import { ReportContentBulkEditTable } from '@/hybrid/reportEditor/ReportContentBulkEditTable.molecule';
import _ from 'lodash';
import { WorkstepsService } from '@/worksteps/worksteps.service';
import {
  Content, KEEP_CURRENT_ASSET_SELECTION,
  KEEP_CURRENT_DATE_RANGE,
  KEEP_CURRENT_SCALE,
  KEEP_CURRENT_SIZE,
  KEEP_CURRENT_SUMMARY,
  REPORT_CONTENT
} from '@/reportEditor/report.module';
import { ReportContentActions } from '@/reportEditor/reportContent.actions';
import { FroalaReportContentService } from '@/reportEditor/froalaReportContent.service';
import { ReportContentModalButtons } from '@/hybrid/reportEditor/ReportContentModalButtons.molecule';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { NotificationsService } from '@/services/notifications.service';
import { ContentApi, ContentWithMetadataOutputV1 } from '@/sdk';
import { WorksheetViewService } from '@/worksheet/worksheetView.service';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';

const reportContentBulkEditModalBindings = bindingsDefinition({
  show: prop<boolean>(),
  $state: injected<ng.ui.IStateService>(),
  sqTrack: injected<TrackService>(),
  sqReportStore: injected<ReportStore>(),
  sqReportActions: injected<ReportActions>(),
  sqWorksteps: injected<WorkstepsService>(),
  sqReportContentActions: injected<ReportContentActions>(),
  sqFroalaReportContent: injected<FroalaReportContentService>(),
  sqReportContent: injected<ReportContentService>(),
  sqNotifications: injected<NotificationsService>(),
  sqContentApi: injected<ContentApi>(),
  sqWorksheetView: injected<WorksheetViewService>()
});

export const ReportContentBulkEditModal: SeeqComponent<typeof reportContentBulkEditModalBindings> = (props) => {
    const {
      sqTrack,
      sqReportStore,
      sqFroalaReportContent,
      sqReportContent,
      sqReportContentActions,
      sqReportActions,
      sqNotifications,
      sqContentApi,
      sqWorksteps,
      sqWorksheetView
    } = useInjectedBindings(
      reportContentBulkEditModalBindings);
    const { show } = props;
    const { t } = useTranslation();

    const [contentWithAdditionalProperties, setContentWithAdditionalProperties] = useState([]);
    const [contentLoaded, setContentLoaded] = useState(false);

    const loadingSpinner = <div className="flexColumnContainer flexCenter pt50 pb50">
      <i className="fa fa-spinner fa-pulse fa-5x sq-text-primary" />
    </div>;

    const inheritFromWorksheet = t('REPORT.CONFIG.INHERIT_FROM_WORKSHEET');

    const convertSummaryToString = (summary) => {
      if (summary === undefined) {
        return inheritFromWorksheet;
      }
      const summaryName = t(summary.name);
      if (summary.key === REPORT_CONTENT.SUMMARY.DISCRETE.key) {
        return `${summaryName} : ${summary.value.value}${summary.value.units}`;
      } else if (summary.key === REPORT_CONTENT.SUMMARY.AUTO.key) {
        return `${summaryName} : ${summary.value}`;
      } else {
        return summaryName;
      }
    };

    const REDACTED = 'REPORT.CONFIG.BULK_EDIT_TABLE.REDACTED';
    const NOT_APPLICABLE = 'REPORT.CONFIG.BULK_EDIT_TABLE.NOT_APPLICABLE';

    const fetchAttributes = (content: ContentWithMetadataOutputV1) => {
      const contentWithAttributes = {
        ...content,
        sourceView: null,
        workstepStatus: null,
        workbookName: null,
        worksheetName: null,
        currentWorkstepId: null,
        currentView: null
      };

      if (_.isEmpty(content.sourceWorkstepBlob)) {
        contentWithAttributes.sourceView = { name: REDACTED };
        contentWithAttributes.currentView = {};
        contentWithAttributes.workbookName = t(REDACTED);
        contentWithAttributes.worksheetName = t(REDACTED);
        contentWithAttributes.workstepStatus = REDACTED;
        return Promise.resolve(contentWithAttributes);
      } else {
        return Promise.all([
          sqWorksteps.workstepFromResponse(content.sourceWorkstepBlob)
            .then(sqReportContent.getViewFromWorkstep),
          sqWorksteps.workstepFromResponse(content.currentWorkstepBlob)
            .then(sqReportContent.getViewFromWorkstep)
        ]).then((results) => {
          contentWithAttributes.sourceView = results[0];
          contentWithAttributes.currentView = results[1];
          contentWithAttributes.workbookName = content.workbookPreview.name;
          contentWithAttributes.worksheetName = content.worksheetPreview.name;
          contentWithAttributes.workstepStatus = content.sourceWorkstep === content.currentWorkstepBlob.id ?
            'REPORT.CONFIG.WORKSTEP_STATUS.UP_TO_DATE'
            : 'REPORT.CONFIG.WORKSTEP_STATUS.NOT_UP_TO_DATE';
          contentWithAttributes.currentWorkstepId = content.currentWorkstepBlob.id;
          return contentWithAttributes;
        });
      }
    };

    useEffect(() => {
      const selectedIds = sqFroalaReportContent.getContentIdsInSelection();
      const selectedContent = [];
      const contentIdsInDocumentOrder = sqFroalaReportContent.getContentIdsInDocumentOrder();

      sqContentApi.getContentsWithAllMetadata({ reportId: sqReportStore.id })
        .then(({ data: { contentItems } }) => {
          return Promise.all(
            _.chain(contentItems)
              .reject('archived')
              .map(fetchAttributes).value());
        })
        .then((contentItems) => {
        const contentWithAdditionalProperties = _.chain(contentItems)
          .reject(content => !_.includes(contentIdsInDocumentOrder, content.id) || content.archived)
          .map((content) => {
            const { size, shape } = sqReportContentActions.calculateSizeAndShape(content.width, content.height);
            const formattedContent = { ...sqFroalaReportContent.formatContentFromApiOutput(content) };

            let shapeName;
            let sizeName;
            let width;
            let height;
            if (!!content.sourceView.useSizeFromRender) {
              shapeName = NOT_APPLICABLE;
              sizeName = NOT_APPLICABLE;
              width = t(NOT_APPLICABLE);
              height = t(NOT_APPLICABLE);
            } else {
              width = formattedContent.width;
              height = formattedContent.height;
              shapeName = shape ? shape.name : REPORT_CONTENT.SIZE.CUSTOM.name;
              sizeName = size.name;
            }

            const summaryAsString = convertSummaryToString(formattedContent.summary);

            const decoratedContent = {
              ...formattedContent,
              dateRangeName: content.dateRange ? content.dateRange.name : inheritFromWorksheet,
              assetSelectionName: content.assetSelection ? content.assetSelection.name : inheritFromWorksheet,
              scaleName: t(_.find(REPORT_CONTENT.SCALE, { key: content.scale }).name),
              shapeName: t(shapeName),
              sizeName: t(sizeName),
              workstepStatus: t(content.workstepStatus),
              viewName: t(content.sourceView.name),
              sourceViewKey: content.sourceView.key,
              currentViewKey: content.currentView.key,
              position: _.indexOf(contentIdsInDocumentOrder, content.id) + 1,
              summaryAsString,
              width,
              height,
              workbookName: content.workbookName,
              worksheetName: content.worksheetName,
              currentWorkstepId: content.currentWorkstepId
            };
            if (_.includes(selectedIds, decoratedContent.id)) {
              selectedContent.push(decoratedContent);
            }
            return decoratedContent;
          })
          .value();

        if (_.some(contentWithAdditionalProperties, content => content.workstepStatus === REDACTED)) {
          sqNotifications.errorTranslate('REPORT.CONFIG.DETAILS_REDACTED');
        }
        setContentWithAdditionalProperties(contentWithAdditionalProperties);
        sqReportActions.setSelectedBulkContent(selectedContent);
        setContentLoaded(true);
      }).catch((e) => {
        sqNotifications.apiError(e);
        sqReportActions.clearBulkProperties();
      });
    }, []);

    const close = () => {
      sqReportActions.clearBulkProperties();
      setContentWithAdditionalProperties([]);
      setContentLoaded(false);
    };

    const submit = () => {
      sqTrack.doTrack('Topic', 'Topic Bulk Update', 'updated content');
      let updatedDate = false;
      let updatedAssetSelection = false;
      let updatedScale = false;
      let updatedShapeSize = false;
      let updatedWorkstep = false;
      let updatedSummary = false;
      let couldNotUpdateWorkstep = false;
      let anyContentModified = false;

      _.forEach(sqReportStore.selectedBulkContent, (content) => {
        const modifiedContent = { ...content };

        // undefined === inherit date range
        if (_.isUndefined(sqReportStore.bulkDateRange)) {
          modifiedContent.dateRangeId = undefined;
          updatedDate = true;
        } else if (sqReportStore.bulkDateRange.id !== KEEP_CURRENT_DATE_RANGE.id) {
          modifiedContent.dateRangeId = sqReportStore.bulkDateRange.id;
          updatedDate = true;
        }

        if (_.isUndefined(sqReportStore.bulkAssetSelection)) {
          modifiedContent.assetSelectionId = undefined;
          updatedAssetSelection = true;
        } else if (sqReportStore.bulkAssetSelection.selectionId !== KEEP_CURRENT_ASSET_SELECTION.selectionId) {
          modifiedContent.assetSelectionId = sqReportStore.bulkAssetSelection.selectionId;
          updatedAssetSelection = true;
        }

        if (sqReportStore.bulkScale.key !== KEEP_CURRENT_SCALE.key) {
          modifiedContent.scale = sqReportStore.bulkScale.key;
          updatedScale = true;
        }

        // If size is staying the same so is shape
        if (sqReportStore.bulkSize.key !== KEEP_CURRENT_SIZE.key) {
          if (sqReportStore.bulkSize.key === REPORT_CONTENT.SIZE.CUSTOM.key) {
            modifiedContent.width = sqReportStore.bulkWidth;
            modifiedContent.height = sqReportStore.bulkHeight;
          } else {
            const size = sqReportStore.bulkSize;
            const shape = sqReportStore.bulkShape;

            modifiedContent.width = size.width;
            modifiedContent.height = sqReportContentActions.calculateHeight(size.width, shape);
          }

          updatedShapeSize = true;
        }

        // If the source view key is present, the user has access to the source worksheet
        if (sqReportStore.shouldUpdateBulkWorkstep && modifiedContent.sourceViewKey) {
          if (modifiedContent.workstepId !== modifiedContent.currentWorkstepId) {
            modifiedContent.workstepId = modifiedContent.currentWorkstepId;
            const currentView = sqWorksheetView.find(modifiedContent.currentViewKey);

            modifiedContent.useSizeFromRender = !!currentView.useSizeFromRender;
            if (!modifiedContent.useSizeFromRender
              && !!sqWorksheetView.find(modifiedContent.sourceViewKey).useSizeFromRender) {
              modifiedContent.width = REPORT_CONTENT.SIZE.MEDIUM.width;
              modifiedContent.height = sqReportContentActions.calculateHeight(modifiedContent.width,
                REPORT_CONTENT.SHAPE.RECTANGLE);

              updatedShapeSize = true;
            }

            updatedWorkstep = true;
          }
        } else if (sqReportStore.shouldUpdateBulkWorkstep) {
          couldNotUpdateWorkstep = true;
        }

        if (_.isUndefined(sqReportStore.bulkSummary)) {
          modifiedContent.summary = undefined;
          updatedSummary = true;
        } else if (sqReportStore.bulkSummary.key !== KEEP_CURRENT_SUMMARY.key) {
          modifiedContent.summary = sqReportStore.bulkSummary;
          updatedSummary = true;
        }

        if (!_.isEqual(content, modifiedContent)) {
          if (!!modifiedContent.useSizeFromRender) {
            const undecoratedContent = sqReportStore.getContentById(modifiedContent.id);
            modifiedContent.shapeName = undecoratedContent.shapeName;
            modifiedContent.sizeName = undecoratedContent.sizeName;
            modifiedContent.width = undecoratedContent.width;
            modifiedContent.height = undecoratedContent.height;
          }

          anyContentModified = true;
          sqReportActions.saveContent(modifiedContent)
            .then((content: Content) => {
              sqReportContent.insertOrReplaceContent(content.id);
            })
            .catch((error) => {
              sqNotifications.apiError(error);
            });
        }
      });

      // Update button was pressed and no content was modified => refresh selected content pieces
      if (!anyContentModified) {
        const contentIds = _.map(sqReportStore.selectedBulkContent, 'id');
        sqReportContent.forceRefreshMultipleContent(contentIds);
      }

      if (updatedDate) sqTrack.doTrack('Topic', 'Topic Bulk Update', 'update date range');
      if (updatedScale) sqTrack.doTrack('Topic', 'Topic Bulk Update', 'update scale');
      if (updatedShapeSize) sqTrack.doTrack('Topic', 'Topic Bulk Update', 'update size/shape');
      if (updatedWorkstep) sqTrack.doTrack('Topic', 'Topic Bulk Update', 'update workstep');
      if (updatedSummary) sqTrack.doTrack('Topic', 'Topic Bulk Update', 'update summary');
      if (updatedAssetSelection) sqTrack.doTrack('Topic', 'Topic Bulk Update', 'update asset selection');

      if (couldNotUpdateWorkstep) sqNotifications.errorTranslate('REPORT.CONFIG.BULK_EDIT_TABLE.CANT_UPDATE_WORKSTEP');

      close();
    };

    const cancel = () => {
      sqTrack.doTrack('Topic', 'Topic Bulk Update', 'cancel');
      close();
    };

    return (show &&
      <Modal show={show} onHide={cancel} animation={false} backdrop="static"
        dialogClassName="bulkEditModal">
        <Modal.Header closeButton={true}>
          <Modal.Title>
            {t('REPORT.CONTENT.UPDATE_PROPERTIES')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {contentLoaded ? <div className="flexFillOverflow flexColumnContainer bulkEditModalBody">
              <ReportContentBulkEditTable allContent={contentWithAdditionalProperties} />
              <ReportContentBulkEditProperties />
            </div>
            : loadingSpinner
          }
        </Modal.Body>
        <Modal.Footer>
          <ReportContentModalButtons cancel={cancel} update={submit} />
        </Modal.Footer>
      </Modal>);
  };

export const sqReportContentBulkEditModal = angularComponent(reportContentBulkEditModalBindings, ReportContentBulkEditModal);
