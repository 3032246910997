import _ from 'lodash';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import tinycolor from 'tinycolor2';
import { MINIMAP_OPACITY, SCATTER_PLOT_COLORS } from '@/scatterPlot/scatterPlot.module';
import { useDrop } from 'react-dnd';
import { MINIMAP_HANDLE_LOWER, MinimapHandleLane } from '@/hybrid/scatterPlot/minimap/MinimapHandleLane.molecule';
import { ScatterPlotMinimapChart } from '@/hybrid/scatterPlot/minimap/MinimapChart.molecule';
import { useDebounce } from '@/hybrid/core/useDebounce.hook';
import { DEBOUNCE } from '@/main/app.constants';
import { useStateWithRef } from '@/hybrid/core/useStateWithRef.hook';

const minimapInnerBindings = bindingsDefinition({
  trendStart: prop<number>(),
  trendEnd: prop<number>(),
  xValue: prop<number>(),
  plotSeries: prop<Object[]>(),
  regions: prop<{ start: number, end: number, color: string, id: string }[]>(),
  selectorLow: prop<number>(),
  selectorHigh: prop<number>(),
  setSelectors: prop<any>(),
  useSelectors: prop<boolean>(),
  backgroundColor: prop.optional<string>()
});

export const MINIMAP_HANDLE_DRAG_SOURCE_TYPE = 'scatterPlotMinimapHandle';

/**
 * Displays the minimap, including the chart and the draggable handles.
 * Contains data/functions common to the chart and the handles.
 * Is the DropTarget for dragging the handles.
 */
export const MinimapInner: SeeqComponent<typeof minimapInnerBindings> = (props) => {
  const {
    trendStart,
    trendEnd,
    xValue,
    plotSeries,
    regions,
    selectorLow,
    selectorHigh,
    setSelectors,
    useSelectors,
    backgroundColor
  } = props;

  const chartElementRef = useRef(null);
  const [chart, setChart] = useState(null);
  const [lowerMiddleDragX, setLowerMiddleDragX] = useStateWithRef(undefined);
  const [middleUpperDragX, setMiddleUpperDragX] = useStateWithRef(undefined);

  useEffect(() => {
    setLowerMiddleDragX(undefined);
    setMiddleUpperDragX(undefined);
    updateBoxes();
  }, [selectorLow, selectorHigh]);

  /**
   * Calculate the current x-pixel locations for the different regions of the minimap.
   *
   * @returns {{lowerSlider: *, upperSlider: *, rangeStart: *, rangeEnd: *}} Object containing x-pixel locations
   */
  const updateXLocations = useCallback(() => {
    const rangeStartX = trendStart;
    const rangeEndX = trendEnd;
    const sliderWidth = Math.max(0, rangeEndX - rangeStartX);

    return {
      lowerSlider: _.isFinite(lowerMiddleDragX.current) ? lowerMiddleDragX.current :
        rangeStartX + sliderWidth * selectorLow,
      upperSlider: _.isFinite(middleUpperDragX.current) ? middleUpperDragX.current :
        rangeStartX + sliderWidth * selectorHigh,
      rangeStart: rangeStartX,
      rangeEnd: rangeEndX
    };
  }, [trendStart, trendEnd, selectorLow, selectorHigh, lowerMiddleDragX.current, middleUpperDragX]);

  /**
   * Updates the minimap boxes that encompass time-ranges.
   */
  const updateBoxes = useCallback(() => {
    if (!chart) { return; }
    const colorRegions = regions || [];
    let plotBands = [];

    const xLocations = updateXLocations();
    const crossedSliders = xLocations.upperSlider <= xLocations.lowerSlider;

    if (useSelectors && _.isFinite(selectorLow) && _.isFinite(selectorHigh)) {
      if (xLocations.lowerSlider > xLocations.rangeStart) {
        // Use the correct boundaries when the middle region has disappeared
        const to = !crossedSliders ? xLocations.lowerSlider :
          xLocations.upperSlider;
        plotBands.push({
          from: xLocations.rangeStart,
          to,
          color: tinycolor(SCATTER_PLOT_COLORS.LOW).setAlpha(MINIMAP_OPACITY).toString(),
          id: 'lowBand'
        });
      }
      if (!crossedSliders) {
        plotBands.push({
          from: xLocations.lowerSlider,
          to: xLocations.upperSlider,
          color: tinycolor(SCATTER_PLOT_COLORS.MID).setAlpha(MINIMAP_OPACITY).toString(),
          id: 'midBand'
        });
      }
      if (xLocations.rangeEnd > xLocations.upperSlider) {
        // Use the correct boundaries when the middle region has disappeared
        const from = !crossedSliders ? xLocations.upperSlider :
          xLocations.lowerSlider;
        plotBands.push({
          from,
          to: xLocations.rangeEnd,
          color: tinycolor(SCATTER_PLOT_COLORS.HIGH).setAlpha(MINIMAP_OPACITY).toString(),
          id: 'highBand'
        });
      }
    }

    plotBands = plotBands.concat(_.chain(colorRegions)
      .sortBy('opacity')
      .reverse()
      .map(region => ({
        from: region.start,
        to: region.end,
        color: tinycolor(region.color).setAlpha(MINIMAP_OPACITY).toString(),
        id: region.id
      }))
      .value());

    // Sometimes it takes multiple calls to actually destroy the plotBands, unclear why
    while (chart.xAxis[0].plotLinesAndBands.length > 0) {
      _.forEach(chart.xAxis[0].plotLinesAndBands, oldBand => oldBand?.destroy());
    }
    _.forEach(plotBands, plotBand => chart.xAxis[0].addPlotBand(plotBand));
  }, [chart, updateXLocations, useSelectors, selectorLow, selectorHigh, regions]);

  /**
   * On dragging of the lower-middle region slider.
   */
  const dragMoveLowerMiddle = useCallback((x) => {
    if (!chart) return;
    let xOnAxis = chart.xAxis[0].toValue(x);
    const xLocations = updateXLocations();
    xOnAxis = _.clamp(xOnAxis, xLocations.rangeStart, xLocations.rangeEnd);
    setLowerMiddleDragX(xOnAxis);
    if (xLocations.upperSlider < xOnAxis) {
      setMiddleUpperDragX(xOnAxis);
    }
    updateBoxes();
    debouncedDragEnd();
  }, [chart, updateXLocations, updateBoxes]);

  /**
   * On dragging of the middle-upper region slider.
   */
  const dragMoveMiddleUpper = useCallback((x) => {
    if (!chart) return;
    let xOnAxis = chart.xAxis[0].toValue(x);
    const xLocations = updateXLocations();
    xOnAxis = _.clamp(xOnAxis, xLocations.rangeStart, xLocations.rangeEnd);
    setMiddleUpperDragX(xOnAxis);
    if (xLocations.lowerSlider > xOnAxis) {
      setLowerMiddleDragX(xOnAxis);
    }
    updateBoxes();
    debouncedDragEnd();
  }, [chart, updateXLocations, updateBoxes]);

  /**
   * On stop dragging of one of the sliders.
   */
  const dragEnd = useCallback(() => {
    const rangeStartX = trendStart;
    const rangeEndX = trendEnd;
    const widthX = rangeEndX - rangeStartX;
    let newLow, newHigh;

    // calculate breakpoints as percentage of the whole range
    newLow = _.isFinite(lowerMiddleDragX.current) ? (lowerMiddleDragX.current - rangeStartX) / widthX : selectorLow;
    newHigh = _.isFinite(middleUpperDragX.current) ? (middleUpperDragX.current - rangeStartX) / widthX : selectorHigh;
    setSelectors(newLow, newHigh);
  }, [trendStart, trendEnd, lowerMiddleDragX, middleUpperDragX, selectorLow, selectorHigh]);

  const debouncedDragEnd = useDebounce(() => dragEnd(), DEBOUNCE.LONG);

  const renderMinimapHandleLane = useCallback(() => {
      if (!chartElementRef.current) return null;
      return <MinimapHandleLane
        chart={chart}
        updateXLocations={updateXLocations}
        dragMoveLowerMiddle={dragMoveLowerMiddle}
        dragMoveMiddleUpper={dragMoveMiddleUpper}
        useSelectors={useSelectors} />;
    },
    [chart, updateXLocations, dragEnd, dragMoveLowerMiddle, dragMoveMiddleUpper, useSelectors, selectorLow, selectorHigh]);

  const renderMinimapChart = useCallback(() => (
    <ScatterPlotMinimapChart
      chart={chart}
      setChart={setChart}
      chartElementRef={chartElementRef}
      trendStart={trendStart}
      trendEnd={trendEnd}
      xValue={xValue}
      plotSeries={plotSeries}
      regions={regions}
      selectorLow={selectorLow}
      selectorHigh={selectorHigh}
      useSelectors={useSelectors}
      updateBoxes={updateBoxes}
      backgroundColor={backgroundColor} />
  ), [chart, setChart, chartElementRef, trendStart, trendEnd, xValue, plotSeries, regions, selectorLow, selectorHigh,
    useSelectors, backgroundColor]);

  const [, drop] = useDrop({
    accept: MINIMAP_HANDLE_DRAG_SOURCE_TYPE,
    drop: (item, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      const left = Math.round(item.left + delta.x);
      if (item.id === MINIMAP_HANDLE_LOWER) {
        dragMoveLowerMiddle(left);
      } else if (item.id === MINIMAP_HANDLE_LOWER) {
        dragMoveMiddleUpper(left);
      }
      dragEnd();
    }
    // @ts-ignore - this is a hook that accepts a second argument, but ts doesn't think it does
  }, [dragMoveLowerMiddle, dragMoveMiddleUpper, dragEnd]);

  return <div
    className="flexFillOverflow flexRowContainer flexColumnContainer pl25 pr15 mb5" ref={drop}>
    {renderMinimapHandleLane()}
    {renderMinimapChart()}
  </div>;
};
