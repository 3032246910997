import { FroalaPluginsService } from '@/reportEditor/froalaPlugins.service';
import React, { useEffect, useRef, useState } from 'react';
import { bindingsDefinition, injected, prop } from '../core/bindings.util';
import { useInjectedBindings } from '../core/useInjectedBindings.hook';
import { useTranslation } from '../core/useTranslation.hook';
import { useFluxPath } from '../core/useFluxPath.hook';
import { UtilitiesService } from '@/services/utilities.service';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { AuthenticationService } from '@/services/authentication.service';
import { useResizeWatcher } from '@/hybrid/core/useResizeWatcher.hook';
import { ContainerWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { CustomPlugin } from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import classNames from 'classnames';
import { ReportActions } from '@/reportEditor/report.actions';
import { ReportStore } from '@/reportEditor/report.store';
import { PdfExportStore } from '@/reportEditor/pdfExport.store';
import { NotificationsService } from '@/services/notifications.service';
import { AnnotationsApi } from '@/sdk';

/**
 * Initializes the Froala Journal editor
 */
export interface EditorDependencies {
  sqReportEditor: ReportEditorService;
  sqFroalaPlugins: FroalaPluginsService;
  sqUtilities: UtilitiesService;
  $sce: ng.ISCEService;
  sqAuthentication: AuthenticationService;
  sqNotifications: NotificationsService;
  sqAnnotationsApi: AnnotationsApi;
  t: (label) => string;
  $injector: ng.auto.IInjectorService;
  $rootScope: ng.IRootScopeService;
  $translate: ng.translate.ITranslateService;
  sqReportActions: ReportActions;
  sqReportStore: ReportStore;
  sqPdfExportStore: PdfExportStore;
}

export interface EditorProps {
  beforeOnInit: (o: object) => void;
  afterOnInit: (editor: object) => void;
  document: string;
  id: string;
  documentChanged: (d: string, f: boolean) => Promise<object>;
  onDestroy: () => void;
  initialLanguage: string;
  toolbar?: string[];
  plugins?: CustomPlugin[];
  isJournal?: boolean;
}

export const baseEditorBindings = bindingsDefinition({
  afterOnInit: prop<() => any>(),
  beforeOnInit: prop<() => any>(),
  setupEditor: prop<(deps: EditorDependencies, props: EditorProps) => any>(),
  document: prop<any>(),
  documentChanged: prop<() => any>(),
  id: prop<string>(),
  isCommentsExpanded: prop<boolean>(),
  isEditing: prop<boolean>(),
  onDestroy: prop<() => void>(),
  toolbar: prop.optional<string[]>(),
  plugins: prop.optional<CustomPlugin[]>(),
  sqFroalaPlugins: injected<FroalaPluginsService>(),
  sqUtilities: injected<UtilitiesService>(),
  sqWorkbenchStore: injected<WorkbenchStore>(),
  $sce: injected<ng.ISCEService>(),
  sqReportEditor: injected<ReportEditorService>(),
  sqReportStore: injected<ReportStore>(),
  sqPdfExportStore: injected<PdfExportStore>(),
  sqReportActions: injected<ReportActions>(),
  sqNotifications: injected<NotificationsService>(),
  sqAnnotationsApi: injected<AnnotationsApi>(),
  sqAuthentication: injected<AuthenticationService>(),
  $injector: injected<ng.auto.IInjectorService>(),
  $rootScope: injected<ng.IRootScopeService>(),
  $translate: injected<ng.translate.ITranslateService>(),
  isCkEditor: prop.optional<boolean>(),
  backupDocument: prop.optional<string>(),
  showBackup: prop.optional<boolean>(),
  isJournal: prop.optional<boolean>()
});

export const BaseEditor: SeeqComponent<typeof baseEditorBindings> = (props) => {
  const {
    sqFroalaPlugins,
    sqUtilities,
    sqWorkbenchStore,
    $sce,
    sqAuthentication,
    sqNotifications,
    sqAnnotationsApi,
    sqReportEditor,
    $injector,
    $rootScope,
    $translate,
    sqReportActions,
    sqReportStore,
    sqPdfExportStore
  } = useInjectedBindings(baseEditorBindings);
  const { isEditing, document, setupEditor, isCkEditor, backupDocument, showBackup, isJournal } = props;
  const { t } = useTranslation();
  const userLanguage = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.userLanguage);
  const language = sqUtilities.checkLanguage(userLanguage);
  const [isEditorLoaded, setIsEditorLoaded] = useState(null);
  const [editorElement, setEditorElement] = useState(null);
  const editorInstance = useRef(null);

  const resizeEditor = ({ newWidth, newHeight }) => {
    if (isEditorLoaded) {
      editorInstance.current.resize(newHeight, newWidth);
    }
  };
  const removeResizeWatcher = useResizeWatcher({ element: editorElement, callback: resizeEditor, callOnLoad: true });

  useEffect(() => {
    const froalaEditorInstance = setupEditor(
      {
        sqReportEditor,
        sqFroalaPlugins,
        sqUtilities,
        $sce,
        sqAuthentication,
        sqNotifications,
        sqAnnotationsApi,
        t,
        $injector,
        $rootScope,
        $translate,
        sqReportActions,
        sqReportStore,
        sqPdfExportStore
      },
      { ...props, initialLanguage: language });
    editorInstance.current = froalaEditorInstance;
    setIsEditorLoaded(true);

    return () => {
      editorInstance.current.destroy();
      removeResizeWatcher();
    };
  }, []);

  useEffect(() => {
    if (!isEditorLoaded) {
      return;
    }
    if (isEditing || isCkEditor) {
      editorInstance.current.init(isEditing);
    } else {
      editorInstance.current.destroy();
    }
  }, [isEditing, isEditorLoaded]);

  useEffect(() => {
    isEditorLoaded && editorInstance.current.handlePreview?.(showBackup);
  }, [isEditorLoaded, backupDocument, showBackup]);

  if (!isEditorLoaded) {
    return null;
  }

  editorInstance.current.setLanguage(language);

  return (
    <>
      <div id="journalEditorToolbarContainer" className={classNames({ hidden: !isEditing && isJournal && isCkEditor })} />
      <div
        className={classNames({
          flexRowContainer: !isCkEditor,
          flexFill: !isCkEditor,
          editorContainer: isCkEditor,
          // The flexBasisZero class is necessary for the froala editor in edit mode  to stay at a fixed size and
          // have a scroll bar. It's always necessary for the journal editor, but in the report editor it can mess
          // up printing in presentation mode.
          flexBasisZero: !isCkEditor && isEditing
        })}
        ref={ref => setEditorElement(ref)}>
        {(isEditing || isCkEditor) &&
          <div id="journalEditor" />}
        {(!isEditing && !isCkEditor) &&
          <ContainerWithHTML
            className={classNames(
              'flexFillOverflow',
              'overflowYAuto',
              'msOverflowStyleAuto',
              'backgroundColorWhite',
              'p10',
              'fr-view',
              'fr-viewing'
            )}
            id="specJournalEntry"
            isBlock={true}
            content={showBackup ? backupDocument : editorInstance.current.getHtml()}
          />
        }
      </div>
    </>
  );
};
