import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { FormControl, InputGroup } from 'react-bootstrap';
import {
  HOME_SCREEN_TABS,
  SEARCH_LOCATION_OPTIONS,
  SEARCH_ITEM_LOCATIONS,
  DEFAULT_LOCATION
} from '@/main/app.constants';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { useKey } from '@/hybrid/core/useKey.hook';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import {
  ITEM_TYPES, TYPE_OPTIONS, DEFAULT_TYPE, HOME_SCREEN_TABLE_TYPE
} from '@/hybrid/homescreen/homescreen.module';
import { AdvancedSearchSelect } from '@/hybrid/homescreen/AdvancedSearchSelect.atom';
import { SearchResults } from '@/hybrid/homescreen/SearchResults.molecule';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { HomeScreenStore } from '@/hybrid/homescreen/homescreen.store';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { APP_STATE } from '@/main/app.constants';
import { UtilitiesService } from '@/services/utilities.service';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { Checkbox } from '../core/Checkbox.atom';
import { Icon } from '@/hybrid/core/Icon.atom';

const homeScreenSearchBindings = bindingsDefinition({
  sqHomeScreenActions: injected<HomeScreenActions>(),
  sqHomeScreenStore: injected<HomeScreenStore>(),
  isLoading: prop<boolean>(),
  $state: injected<ng.ui.IStateService>(),
  sqUtilities: injected<UtilitiesService>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>()
});

export const HomeScreenSearch: SeeqComponent<typeof homeScreenSearchBindings> = (props) => {
  const { sqHomeScreenActions, sqHomeScreenStore, $state, sqUtilities, sqHomeScreenUtilities } = useInjectedBindings(
    homeScreenSearchBindings);
  const { isLoading } = props;
  useFlux(sqHomeScreenStore);

  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  useEffect(() => setSearchText(_.get(sqHomeScreenStore.searchParams, 'textSearch', '')),
    [sqHomeScreenStore.searchParams]);

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [searchString, setSearchString] = useState(null);
  const enterKey = useKey(13);

  const currentTab = sqHomeScreenStore.currentTab;
  const hasSearchResults = sqHomeScreenStore.getItemsForTable(HOME_SCREEN_TABLE_TYPE.SEARCH) !== null;

  const defaultType = { text: DEFAULT_TYPE.text, label: t(DEFAULT_TYPE.text), value: DEFAULT_TYPE.value };
  const [itemTypeFilter, setItemTypeFilter] = useState(defaultType);

  // For now, we do not allow searching by most recent- CRAB-19839
  const searchLocations = sqHomeScreenUtilities.getFilteredLocations(SEARCH_LOCATION_OPTIONS, false);
  const defaultLocation = {
    text: DEFAULT_LOCATION.text,
    label: t(DEFAULT_LOCATION.text),
    value: DEFAULT_LOCATION.value
  };
  const [itemLocationFilter, setItemLocationFilter] = useState(defaultLocation);

  useEffect(() => {
    if (enterKey && hasSearchText) executeSearch();
  }, [enterKey]);

  useEffect(() => {
    if (hasSearchResults && currentTab !== HOME_SCREEN_TABS.SEARCH) clearSearchResults(false);
  }, [currentTab]);

  const hasSearchText = !_.isEmpty(_.trim(searchText));
  const hasTypeFilter = itemTypeFilter.value !== ITEM_TYPES.ANY && hasSearchText;
  const hasLocationFilter = itemLocationFilter.value !== SEARCH_ITEM_LOCATIONS.ALL && hasSearchText;

  const executeSearch = () => {
    if (!hasSearchText) return;
    setShowAdvanced(false);
    sqHomeScreenActions.resetStore();
    sqHomeScreenActions.setCurrentTab(HOME_SCREEN_TABS.SEARCH);
    const searchParams = {
      textSearch: _.trim(searchText),
      onlyPinned: itemLocationFilter.value === SEARCH_ITEM_LOCATIONS.PINNED,
      filter: itemLocationFilter.value === SEARCH_ITEM_LOCATIONS.PINNED ? SEARCH_ITEM_LOCATIONS.ALL :
        itemLocationFilter.value,
      types: hasTypeFilter ? [itemTypeFilter.value] : undefined,
      isExact: sqHomeScreenStore.isExact
    };

    sqHomeScreenActions.setSearchParams(searchParams);
    return sqHomeScreenActions.loadSearchTable(searchParams)
      .then(() => createSearchString(sqHomeScreenStore.getItemTotalForTable(HOME_SCREEN_TABLE_TYPE.SEARCH)));
  };

  const clearSearchResults = (goToHomeTab = true) => {
    sqHomeScreenActions.setSearchParams({
      textSearch: '',
      onlyPinned: false,
      filter: defaultLocation.value,
      types: defaultType.value,
      isExact: sqHomeScreenActions.setIsExact(false)
    });

    setShowAdvanced(false);
    setSearchString(null);
    sqHomeScreenActions.clearSearchResults();
    if (goToHomeTab) {
      $state.go(APP_STATE.WORKBOOKS, { t: sqUtilities.generateTabHash(HOME_SCREEN_TABS.HOME) }, { reload: true });
    }
  };

  const createSearchString = (itemCount) => {
    const bold = text => <span className='text-bolder'>{text}</span>;
    const resultString = (
      <span>
        {itemCount ? t('HOME_SCREEN.SHOWING_RESULTS_FOR') : t('HOME_SCREEN.NO_RESULTS_FOR')} {bold(searchText)}
        {hasTypeFilter && <span>, {t('HOME_SCREEN.WITH_TYPE')} {bold(t(itemTypeFilter.text))}</span>}
        {hasLocationFilter && <span>, {t('HOME_SCREEN.IN')} {bold(t(itemLocationFilter.text))}</span>}.
      </span>
    );
    setSearchString(resultString);
  };

  return (
    <div className='flexRowContainer height-maximum positionRelative'>
      <InputGroup
        className='homeScreenSearchBar flexColumnContainer lightGreyBorder border-radius-4 form-control flexNoWrap'
        data-testid='searchBar'>
        <FormControl
          className='homeScreenSearchInput hide-ms-clear'
          type='text'
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          placeholder={t('HOME_SCREEN.SEARCH_PLACEHOLDER')}
        />
        <InputGroup.Append>
          <InputGroup.Text className="searchButton cursorPointer advanced-options">
            <HoverTooltip delay={500} placement="top" text="HOME_SCREEN.ADVANCED_SEARCH.TOOLTIP">
            <span data-testid='advancedOptionButton'
              onClick={() => setShowAdvanced(!showAdvanced)}>
            <Icon icon="fa-chevron-down" large={true} type="gray" extraClassNames="p2" />
            </span>
            </HoverTooltip>
          </InputGroup.Text>
          <InputGroup.Text className='searchButton cursorPointer search-icon'>
          <span onClick={executeSearch} data-testid='searchButton'>
            <Icon icon="fc-mag-glass-empty" type="gray" extraClassNames="p2" />
          </span>
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
      {showAdvanced &&
      <div className='lightGreyBorder p10 advancedPopover flexRowContainer' data-testid='advancedPopover'>
        <div id='advancedSearch' className='flexColumnContainer'>
          <div className='flexRowContainer col-sm-6'>
            <AdvancedSearchSelect
              className='itemTypeSelect'
              label='HOME_SCREEN.ADVANCED_SEARCH.CONTENT_TYPE'
              value={itemTypeFilter.value}
              options={TYPE_OPTIONS}
              onChange={setItemTypeFilter} />
          </div>
          <div className='flexRowContainer col-sm-6'>
            <AdvancedSearchSelect
              className='itemLocationSelect'
              label='HOME_SCREEN.ADVANCED_SEARCH.LOCATION'
              value={itemLocationFilter.value}
              options={searchLocations}
              onChange={setItemLocationFilter} />
          </div>
        </div>
        <div className='flexColumnContainer col-sm-6 pt10'>
          <Checkbox
            label="HOME_SCREEN.ADVANCED_SEARCH.EXACT_MATCH_CHECKBOX"
            isChecked={sqHomeScreenStore.isExact}
            id='HOME_SCREEN.ADVANCED_SEARCH.EXACT_MATCH_CHECKBOX'
            onChange={() => sqHomeScreenActions.setIsExact(!sqHomeScreenStore.isExact)}
          />
        </div>
        <div className='mt10 flexColumnContainer flexJustifyCenter'>
          <TextButton onClick={() => setShowAdvanced(false)} label="CANCEL" extraClassNames="mr10" />
          <TextButton onClick={executeSearch} label="SEARCH" disabled={!hasSearchText} variant="theme" />
        </div>
      </div>}
      {currentTab === HOME_SCREEN_TABS.SEARCH &&
      <SearchResults
        extraClassNames="mt20"
        isLoading={isLoading}
        onClear={clearSearchResults}
        resultsString={searchString}
      />}
    </div>
  );
};
