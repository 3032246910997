import React, { useState } from 'react';
import { Dropdown, Overlay, Popover } from 'react-bootstrap';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Placement } from 'react-bootstrap/Overlay';

interface ButtonWithPopoverDropdownProps {
  icon: any;
  dropdownPlacement?: Placement;
  tooltipText?: string;
  tooltipPlacement?: Placement;
  tooltipDelay?: number;
  id?: string;
  testId?: string;
  iconWrapperClasses?: string;
}

/**
 * A button with a dropdown. Content for the dropdown menu should be provided as children to the component.
 * This component is similar with ButtonWithDropdown, but the overlay popover ensures that the dropdown appears
 * over nearby elements. This button can be used in a table and it will display the dropdown over the table cells.
 *
 * @param icon (JSX): the <Icon/> to be used for the button
 * @param (optional) dropdownPlacement (Placement): the position of the dropdown (default="'bottom-start'")
 * @param (optional) tooltipText (string): the text to be displayed on the icon's tooltip
 * @param (optional) tooltipPlacement (Placement): the position of the tooltip on the icon (default="top")
 * @param (optional) id (string): an id that can be placed on the component
 * @param (optional) testId (string): an id that will be used in the data-testid attribute on the button itself
 * @param (optional) iconWrapperClasses: classes to be set on the icon wrapper div
 */
export const ButtonWithPopoverDropdown: React.FunctionComponent<ButtonWithPopoverDropdownProps> = (props) => {
  const {
    icon,
    dropdownPlacement = 'bottom-start',
    tooltipText,
    tooltipPlacement = 'top',
    tooltipDelay = 0,
    id,
    testId,
    iconWrapperClasses
  } = props;

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const onHide = () => setShow(false);
  const onClick = (event) => {
    setShow(true);
    setTarget(event.currentTarget);
  };

  const renderTitle = (
    <HoverTooltip
      placement={tooltipPlacement}
      delay={tooltipDelay}
      text={tooltipText}>
      <div
        data-testid={`${testId}-button`}
        className={iconWrapperClasses}
        onClick={onClick}>
        {icon}
      </div>
    </HoverTooltip>
  );

  return (
    <div>
      {renderTitle}
      <Overlay
        rootClose={true}
        target={target}
        show={show}
        placement={dropdownPlacement}
        transition={false}
        onHide={onHide}>
        <Popover
          id={`${id}-popover`}
          data-testid={`${testId}-popover`}
          onClick={onHide}
          className="popoverDropdown">
          <div>
            <Dropdown show={true}>
              {props.children}
            </Dropdown>
          </div>
        </Popover>
      </Overlay>
    </div>
  );
};
