import angular from 'angular';
import _ from 'lodash';
import { ErrorTypeEnum } from 'sdk/model/FormulaErrorOutputV1';
import bind from 'class-autobind-decorator';
import { FrontendDuration } from '@/services/systemConfiguration.service';

@bind
export class MaxCapsuleDurationCtrl {
  // bindings
  public maxDurationModel: FrontendDuration = this['maxDurationModel'];
  public maxDurationChange = this['maxDurationChange'];
  public inputId: string = this['inputId'];
  public headerTranslationKey: string = this['headerTranslationKey'];
  public tooltipTranslationKey: string = this['tooltipTranslationKey'];
  public valueUnitName: string = this['valueUnitName'];
  public item: any = this['item']; // The item being edited or created
  public minIsExclusive: boolean = this['minIsExclusive'];
  public form: ng.IFormController = this['form']; // Created from the template

  constructor() {
  }

  /**
   * Helper function. Returns true if the item errorType is MAXDURATIONREQUIRED
   *
   * @return {boolean} - true if errorType is MAXDURATIONREQUIRED, false otherwise
   */
  maxDurationRequired() {
    return _.get(this.item, 'errorType') === ErrorTypeEnum.MAXDURATIONREQUIRED;
  }

  /**
   * Invokes the change callback with the updated value.
   */
  onChange(valueObj) {
    this.maxDurationModel = valueObj;
    this.maxDurationChange(this.maxDurationModel);
  }
}

angular.module('Sq.TrendViewer')
  .controller('MaxCapsuleDurationCtrl', MaxCapsuleDurationCtrl);
