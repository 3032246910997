import React from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { CapsuleTimeColorModeButton } from '@/hybrid/trend/CapsuleTimeColorModeButton.molecule';
import { CapsuleTimeGroupButton } from '@/hybrid/trend/CapsuleTimeGroupButton.molecule';
import { TrendGridlinesButton } from '@/hybrid/trend/TrendGridlinesButton.molecule';
import SummarizeData from '@/hybrid/trend/SummarizeData.organism';

const chartToolbarBindings = bindingsDefinition({
  isSmall: prop<boolean>()
});

/**
 * The toolbar that shows above the chart on a worksheet.
 */
export const ChartToolbar: SeeqComponent<typeof chartToolbarBindings> = (props) => {
  const { isSmall } = props;

  return (
    <span className="flexColumnContainer flexAlignBottom">
      <TrendGridlinesButton isSmall={isSmall} />
      <CapsuleTimeGroupButton isSmall={isSmall} />
      <CapsuleTimeColorModeButton isSmall={isSmall} />
      <SummarizeData isSmall={isSmall}/>
    </span>
  );
};

export const sqChartToolbar = angularComponent(chartToolbarBindings, ChartToolbar);
