import React from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { AdministrationActions } from '@/administration/administration.actions';
import { UtilitiesService } from '@/services/utilities.service';
import { angularComponent } from '@/hybrid/core/react2angular.util';

const AdminContactModalBindings = bindingsDefinition({
  name: prop<string>(),
  email: prop<string>(),
  onClose: prop<() => void>(),
  sqAdministrationActions: injected<AdministrationActions>(),
  sqUtilities: injected<UtilitiesService>()
});

export const AdminContactModal: SeeqComponent<typeof AdminContactModalBindings> = ({ name, email, onClose }) => {
  const { sqAdministrationActions, sqUtilities } = useInjectedBindings(AdminContactModalBindings);
  const { t } = useTranslation();

  /**
   * Save the administrator contact information from this form.
   *
   * @returns {Promise} that resolves when the update is complete
   */
  const updateAdminContactInStore = (adminContactInfo) => {
    return sqAdministrationActions.updateAdminContactInfo({
        name: adminContactInfo.name,
        email: adminContactInfo.email
      })
      .then(onClose)
      .catch(sqUtilities.handleModalOpenErrors);
  };

  const formDefinition: FormElement[] = [{
    component: 'FormGroup',
    name: 'adminGroup',
    components: [{
      component: 'LabelFormComponent',
      name: 'nameLabel',
      value: 'USER.NAME',
      extraClassNames: 'text-bolder'
    }, {
      component: 'FormControlFormComponent',
      name: 'name',
      value: name,
      onChange: _.noop,
      className: 'mb20',
      size: 'md',
      testId: 'name'
    }, {
      component: 'LabelFormComponent',
      name: 'emailLabel',
      value: 'USER.EMAIL',
      extraClassNames: 'text-bolder'
    }, {
      component: 'FormControlFormComponent',
      name: 'email',
      value: email,
      onChange: _.noop,
      className: 'mb10',
      size: 'md',
      testId: 'email'
    }]
  }];

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="adminContactModal">
      <Modal.Header closeButton={true}>
        <h3>{t('ADMIN.CONFIGURATION.CONTACT_INFORMATION')}</h3>
      </Modal.Header>
      <Modal.Body>
        <SimpleSaveFormBuilder
          formDefinition={formDefinition}
          submitFn={updateAdminContactInStore}
          closeFn={onClose} />
      </Modal.Body>
    </Modal>);
};

export const sqAdminContactModal = angularComponent(AdminContactModalBindings, AdminContactModal);

