import _ from 'lodash';
import angular from 'angular';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { AuthenticationService } from '@/services/authentication.service';
import { TimezonesService } from '@/datetime/timezone.service';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { PUSH_IGNORE, PUSH_WORKBENCH } from '@/services/stateSynchronizer.service';
import { UtilitiesService } from '@/services/utilities.service';
import { UsersApi } from '@/sdk';

angular.module('Sq.Workbench').service('sqWorkbenchActions', sqWorkbenchActions);
export type WorkbenchActions = ReturnType<typeof sqWorkbenchActions>;

function sqWorkbenchActions(
  $state: ng.ui.IStateService,
  flux: ng.IFluxService,
  sqWorkbenchStore: WorkbenchStore,
  sqAuthentication: AuthenticationService,
  sqTimezones: TimezonesService,
  sqPendingRequests: PendingRequestsService,
  sqUtilities: UtilitiesService,
  sqUsersApi: UsersApi
) {
  const service = {
    toggleWorksheetPanel,
    setPreferNewTab,
    setCurrentUser,
    setUserTimeZone,
    setUserLanguage,
    generateNewSessionId,
    setUserProfileDisplay,
    setStateParams,
    setPIVisionHomeURL,
    addRecentColor,
    setLoadingItemId,
    setOpeningAndLoadingItemId,
    setSystemMessageHash,
    setLicenseExpirationSnooze,
    setTourShown
  };

  return service;

  /**
   * Toggles the worksheet panel
   */
  function toggleWorksheetPanel() {
    const viewOnly = sqUtilities.isViewOnlyWorkbookMode;
    flux.dispatch('WORKSHEET_PANEL_TOGGLE', { viewOnly }, PUSH_WORKBENCH);
  }

  /**
   * Sets the flag determining if links to an Analysis or Topic should open in a new tab.
   *
   * @param preferNewTab - True if the user prefers links to open in a new tab
   */
  function setPreferNewTab(preferNewTab: boolean) {
    flux.dispatch('SET_PREFER_NEW_TAB', { preferNewTab }, PUSH_WORKBENCH);
  }

  /**
   * Sets a flag that indicates whether or not to display the "Edit Profile" dialog.
   *
   * @param {Boolean} display - true if "Edit Profile" should be shown, false otherwise.
   */
  function setUserProfileDisplay(display) {
    flux.dispatch('SET_DISPLAY_USER_PROFILE', { displayUserProfile: display }, PUSH_WORKBENCH);
  }

  /**
   * Passes in a description of the params of the state being transitioned to
   *
   * @param {IStateParamsService} stateParams - the state params from app.module
   */
  function setStateParams(stateParams) {
    flux.dispatch('SET_STATE_PARAMS', { stateParams }, PUSH_IGNORE);
  }

  /**
   * If a user is logged in, it sets the properties of the currently logged in user. It uses the authentication token
   * to retrieve the user id as this is what allows the app to bootstrap when being loaded initially. After this the
   * user id will be available on sqWorkbenchStore.currentUser.
   *
   * @returns {Promise} A promise that resolves when the current user's properties have been updated.
   */
  function setCurrentUser() {
    return sqUsersApi.getMe()
      .then(({ data: user }) => flux.dispatch('SET_CURRENT_USER',
        _.assign({ isAdmin: user.isAdmin, isPasswordSettable: user.isPasswordSettable },
          _.pick(user, ['id', 'firstName', 'lastName', 'name', 'email', 'username', 'workbench'])),
        PUSH_IGNORE)
      );
  }

  /**
   * Sets the user's time zone
   *
   * @param {Object} timeZone - Time zone to set for the current user
   * @param {String} timeZone.name - Name of the time zone to set
   */
  function setUserTimeZone(timeZone) {
    flux.dispatch('SET_USER_TIME_ZONE', timeZone);
  }

  /**
   * Sets the user' language preference
   *
   * @param {string} language - the key identifying the selected language
   */
  function setUserLanguage(language) {
    flux.dispatch('SET_USER_LANGUAGE', language);
  }

  /**
   * Creates a new interactive session ID
   */
  function generateNewSessionId() {
    flux.dispatch('SET_NEW_SESSION');
  }

  /**
   * Sets the PI Vision home URL so the user doesn't have to keep re-entering it each time they do an export.
   *
   * @param {String} piVisionHomeURL - The home URL of a PI Vision server.
   */
  function setPIVisionHomeURL(piVisionHomeURL) {
    flux.dispatch('SET_PIVISION_HOME_URL', { piVisionHomeURL }, PUSH_WORKBENCH);
  }

  /**
   * Dispatches an event to add the provided color to the mostRecentColors array.
   *
   *  @param {String} color - a String representing a color in Hex format (e.g. #cccccc)
   */
  function addRecentColor(color) {
    flux.dispatch('ADD_RECENT_COLOR', { color }, PUSH_WORKBENCH);
  }

  /**
   * Stores the date (as an ISO string) until which the license expiration message has been snoozed.
   *
   * @param {String} snoozeUntil - the date until which the license expiration message has been snoozed
   */
  function setLicenseExpirationSnooze(snoozeUntil) {
    flux.dispatch('SET_LICENSE_EXPIRATION_SNOOZE', { snoozeUntil }, PUSH_WORKBENCH);
  }

  /**
   * Sets the loadingItemId that determines if the loading indicator spinners should be shown for a workbook or folder.
   *
   * @param {String} id - the id.
   */
  function setLoadingItemId(id) {
    flux.dispatch('WORKBOOK_SET_LOADING_ID', { id }, PUSH_WORKBENCH);
  }

  /**
   * Sets the opening ItemId that determines if the opening indicator spinners should be shown for a workbook or folder.
   *
   * @param {String} id - the id.
   */
  function setOpeningAndLoadingItemId(id) {
    flux.dispatch('WORKBOOK_SET_OPENING_LOADING_ID', { id }, PUSH_WORKBENCH);
  }

  /**
   * Stores a hash of the system message that has been dismissed
   *
   * @param messageHash
   */
  function setSystemMessageHash(message) {
    flux.dispatch('SET_SYSTEM_MESSAGE_HASH', { message }, PUSH_WORKBENCH);
  }

  function setTourShown(tourShown) {
    flux.dispatch('SET_TOUR_SHOWN', { tourShown }, PUSH_WORKBENCH);
  }
}
