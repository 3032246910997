import { useEffect, useRef } from 'react';

/**
 * A hook to make setting up a mutable value for use between React renders.
 *
 * @param value - the current value
 * @return the ref to get the current value
 */
export function useCurrentValueRef(value) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref;
}
