import _ from 'lodash';
import angular from 'angular';
import { DOCUMENT_LAYOUT, DOCUMENT_MARGIN_UNITS, DOCUMENT_PAPER_SIZE } from '@/reportEditor/report.module';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';

angular.module('Sq.Report').store('sqPdfExportStore', sqPdfExportStore);

export type PdfExportStore = ReturnType<typeof sqPdfExportStore>['exports'];

function sqPdfExportStore() {
  const store = {
    /**
     * This data store is persisted at the workbench level, so it does not
     * change when an different workbook or worksheet is selected.
     */
    persistenceLevel: PERSISTENCE_LEVEL.WORKBENCH,

    initialize() {
      this.state = this.immutable({
        layout: DOCUMENT_LAYOUT[0], // Portrait
        paperSize: DOCUMENT_PAPER_SIZE[0], // Letter
        margin: {
          value: 0.5,
          units: DOCUMENT_MARGIN_UNITS[0].shortLabel // in
        },
        showModal: false
      });
    },

    exports: {
      get layout() {
        return this.state.get('layout');
      },

      get paperSize() {
        return this.state.get('paperSize');
      },

      get margin() {
        return this.state.get('margin');
      },

      get showModal() {
        return this.state.get('showModal');
      }
    },

    dehydrate() {
      const state = _.pick(this.state.serialize(), 'margin');
      _.assign(state,
        { layout: _.get(this.state.get('layout'), 'value') },
        { paperSize: _.get(this.state.get('paperSize'), 'value') });
      return _.omitBy(state, _.isNil);
    },

    rehydrate(dehydratedState) {
      _.assign(dehydratedState,
        { layout: _.find(DOCUMENT_LAYOUT, { value: dehydratedState.layout }) },
        { paperSize: _.find(DOCUMENT_PAPER_SIZE, { value: dehydratedState.paperSize }) }
      );
      this.state.merge(_.omitBy(dehydratedState, _.isNil));
    },

    handlers: {
      PDF_EXPORT_SET_LAYOUT: 'setLayout',
      PDF_EXPORT_SET_PAPER_SIZE: 'setPaperSize',
      PDF_EXPORT_SET_MARGIN: 'setMargin',
      PDF_EXPORT_SET_SHOW_MODAL: 'setShowModal'
    },

    /**
     * Sets the page layout
     *
     * @param {Object} layout - orientation for the PDF, one of DOCUMENT_LAYOUTS
     */
    setLayout(layout) {
      this.state.set('layout', layout);
    },

    /**
     * Sets the paper size
     *
     * @param {Object} paperSize - page size, one of DOCUMENT_PAPER_SIZE
     */
    setPaperSize(paperSize) {
      this.state.set('paperSize', paperSize);
    },

    /**
     * Sets the margin
     *
     * @param {Object} payload - Object for parameters
     * @param {Number} payload.value - Number component of margin.
     * @param {String} payload.units - Unit of measure for margin value, one of DOCUMENT_MARGIN_UNITS
     */
    setMargin(payload) {
      this.state.set('margin', { value: payload.value, units: payload.units });
    },

    setShowModal(payload: { showModal: boolean }) {
      this.state.set('showModal', payload.showModal);
    }
  };

  return store;
}
