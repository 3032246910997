import React from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

interface ConfirmDeleteModalProps {
  onClose: () => void;
  action: () => void;
  headerTranslationKey?: string;
  bodyTranslationKey?: string;
}

/**
 * Modal dialog with Cancel/Delete buttons and configurable text/actions
 */
export const ConfirmDeleteModal: React.FunctionComponent<ConfirmDeleteModalProps> = (props) => {
  const { onClose, action, headerTranslationKey = '', bodyTranslationKey = '' } = props;
  const { t } = useTranslation();

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="confirmDeleteModal">
      <Modal.Header closeButton={true}>
        <h4>{t(_.isEmpty(headerTranslationKey) ? 'DELETE_MODAL.HEADER' : headerTranslationKey)}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="semi-bold">{t(_.isEmpty(bodyTranslationKey) ? 'DELETE_MODAL.BODY' : bodyTranslationKey)}</div>
        <div className="flexColumnContainer flexFill flexJustifyEnd mt25">
          <TextButton
            onClick={() => {
              action();
              onClose();
            }}
            label="DELETE"
            size="sm"
            testId="confirmDeleteBtn"
            extraClassNames="min-width 100 width-100 max-width-100 mr20"
            id="confirmDeleteBtn"
            variant="danger" />

          <TextButton
            onClick={onClose}
            label="CANCEL"
            size="sm"
            testId="cancelDeleteBtn"
            extraClassNames="min-width 100 width-100 max-width-100"
            id="cancelDeleteBtn" />
        </div>
      </Modal.Body>
    </Modal>);
};

