import _ from 'lodash';
import angular from 'angular';
import { DOUBLE_CLICK_TIME } from '@/datetime/dateTime.service';

/*
* This directive handles a double click and a single click style handler on
* the same element so that the both the single click and the double click
* handlers are not both called when double clicking. A double click is
* defined as two clicks within DOUBLE_CLICK_TIME ms.
*
* See https://stackoverflow.com/q/20444409 for more discussion on the issue
*/

angular.module('Sq.Directives.DoubleClick', [])
  .directive('sqDoubleClick', sqDoubleClick);

function sqDoubleClick($timeout: ng.ITimeoutService, $parse: ng.IParseService) {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      let timeout;

      if (!attrs.sqSingleClick || !attrs.sqDoubleClick) {
        throw new Error('sqDoubleClick directive: sqSingleClick and sqDoubleClick attributes are required');
      }

      element.on('click', function() {
        if (timeout) {
          $timeout.cancel(timeout);
          invoke(attrs.sqDoubleClick);
        } else {
          // Delay invoking so that we can wait and see if there will be another click
          timeout = $timeout(_.partial(invoke, attrs.sqSingleClick), DOUBLE_CLICK_TIME);
        }
      });

      function invoke(callback) {
        timeout = null;

        scope.$apply(function() {
          $parse(callback)(scope);
        });
      }
    }
  };
}
