import React from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Dropdown } from 'react-bootstrap';
import _ from 'lodash';
import DropdownItem from 'react-bootstrap/DropdownItem';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import { KEEP_CURRENT_KEY, KEEP_CURRENT_SIZE, REPORT_CONTENT } from '@/reportEditor/report.module';

const reportContentSizeBindings = bindingsDefinition({
  useSizeFromRender: prop<boolean>(),
  isReact: prop.optional<boolean>(),
  isSizeCustom: prop<boolean>(),
  scales: prop<any>(),
  shapes: prop<any>(),
  sizes: prop<any>(),
  scale: prop<any>(),
  shape: prop<any>(),
  size: prop<any>(),
  setScale: prop<(any) => void>(),
  setShape: prop<(any) => void>(),
  setSize: prop<(any) => void>(),
  width: prop<number>(),
  height: prop<number>(),
  setHeight: prop<(number) => void>(),
  setWidth: prop<(number) => void>()
});

export const ReportContentSize: SeeqComponent<typeof reportContentSizeBindings> = (props) => {
  const {
    sizes,
    scales,
    shapes,
    size,
    scale,
    shape,
    useSizeFromRender,
    isSizeCustom,
    setScale,
    setShape,
    setSize,
    width,
    height,
    setWidth,
    setHeight,
    isReact = false
  } = props;
  const { t } = useTranslation();

  function clampContentSize(value) {
    if (_.isEmpty(value)) {
      return '';
    } else {
      return _.clamp(_.toNumber(value), REPORT_CONTENT.CONTENT_MIN_SIZE, REPORT_CONTENT.CONTENT_MAX_SIZE);
    }
  }

  return <div className='flexRowContainer p10'>
    {!useSizeFromRender && !isReact &&
    <label htmlFor='sizeDropdown'>
      {t('REPORT.SIZE.LABEL')}
    </label>}
    {!useSizeFromRender && !isReact &&
    <div id='sizeDropdown'>
      <Dropdown onClick={e => e.preventDefault()}>
        <DropdownToggle id="sizeDropdownToggle" data-testid="sizeDropdownToggle"
          variant={null}
          className='width-maximum btn-default'>
          {t(size.name)}
          <span className='caret ml-1' />
        </DropdownToggle>
        <DropdownMenu>
          {_.chain(sizes)
            .reject(size => size.key === REPORT_CONTENT.SIZE.CUSTOM.key || size.key === KEEP_CURRENT_KEY)
            .map(size => <DropdownItem href='#' key={size.key} onClick={() => setSize(size)}>
                {t(size.name)}
              </DropdownItem>
            )
            .concat([<Dropdown.Divider key="sizeDivider" />,
              <DropdownItem href="#" key={sizes.CUSTOM.key} onClick={() => setSize(sizes.CUSTOM)}>
                {t(sizes.CUSTOM.name)}
              </DropdownItem>,
              sizes.KEEP &&
              <DropdownItem href="#" key={KEEP_CURRENT_SIZE.key} onClick={() => setSize(KEEP_CURRENT_SIZE)}>
                {t(KEEP_CURRENT_SIZE.name)}
              </DropdownItem>])
            .value()
          }
        </DropdownMenu>
      </Dropdown>
    </div>}

    {!isSizeCustom && !useSizeFromRender && !isReact &&
    <label htmlFor='resolutionDropdown' className='mt15'>
      {t('REPORT.SHAPE.LABEL')}
    </label>}

    {!isSizeCustom && !useSizeFromRender && !isReact &&
    <div id='resolutionDropdown'>
      <Dropdown>
        <DropdownToggle id="resolutionDropdownToggle" data-testid="resolutionDropdownToggle"
          variant={null}
          className='width-maximum btn-default'>

          {shape.width && shape.height && <span className='inlineBlock blackShape text-valign-middle mr5'
            style={{ width: shape.width, height: shape.height }} />
          }
          {t(shape.name)}
          <span className='caret ml-1' />
        </DropdownToggle>
        <DropdownMenu>
          {_.map(shapes, (shape) => {
            return <DropdownItem href='#' key={shape.key} className='flexColumnContainer'
              onClick={() => setShape(shape)}>
              {shape.width && shape.height && <span className='inlineBlock blackShape text-valign-middle mr5'
                style={{ width: shape.width, height: shape.height }} />
              }
              {t(shape.name)}
            </DropdownItem>;
          })}
        </DropdownMenu>
      </Dropdown>
    </div>}

    {isSizeCustom && !useSizeFromRender && !isReact &&
    <div className='flexColumnContainer flexCenter mt15'>
      <div className='flexRowContainer'>
        <label htmlFor='content-width'>
          {t('WIDTH')}
        </label>
        <input autoComplete='off' id='content-width'
          type='number'
          className='form-control width-75'
          value={width || ''}
          onChange={e => setWidth(clampContentSize(e.target.value))}
          min={REPORT_CONTENT.CONTENT_MIN_SIZE}
          max={REPORT_CONTENT.CONTENT_MAX_SIZE} />
      </div>
      <div className='ml10 mt20'>x</div>
      <div className='flexRowContainer ml10'>
        <label htmlFor='content-height'>
          {t('HEIGHT')}
        </label>
        <input autoComplete='off' id='content-height'
          type='number'
          className='form-control width-75'
          value={height || ''}
          onChange={e => setHeight(clampContentSize(e.target.value))}
          min={REPORT_CONTENT.CONTENT_MIN_SIZE}
          max={REPORT_CONTENT.CONTENT_MAX_SIZE} />
      </div>
    </div>}

    {!isReact &&
    <>
      <label htmlFor='scaleDropdown' className='mt15'>
        {t('REPORT.SCALE.LABEL')}
      </label>
      <div id='scaleDropdown'>
        <Dropdown>
          <DropdownToggle id="scaleDropdownToggle" data-testid="scaleDropdownToggle"
            variant={null}
            className='width-maximum btn-default'>
            {t(scale.name)}
            <span className='caret ml-1' />
          </DropdownToggle>

          <DropdownMenu>
            {_.map(scales, (scale) => {
              return <DropdownItem href='#' key={scale.key} onClick={() => setScale(scale)}>
                {t(scale.name)}
              </DropdownItem>;
            })}
          </DropdownMenu>
        </Dropdown>
      </div>
    </>}
  </div>;
};

export const sqReportContentSize = angularComponent(reportContentSizeBindings, ReportContentSize);
