import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { ClearableInput } from '@/hybrid/core/ClearableInput.molecule';
import { SortIcon } from '@/hybrid/core/SortIcon.atom';
import { IconSelect } from '@/hybrid/core/IconSelect.molecule';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { DateCell } from '@/hybrid/homescreen/CellRender.atom';
import { HelpIcon, Icon } from '@/hybrid/core/Icon.atom';

export const CELL_TYPES = {
  CHECKMARK: 'checkmark',
  // every table can only have one column of type "ROW_SELECTION". "ROW_SELECTION" is a checkbox that allows the
  // selection of a row as well as renders a header with a "select all" checkbox
  ROW_SELECTION: 'rowSelection',
  DATE_TIME: 'dateTime',
  ENABLED_DISABLED: 'enabledDisabled',
  DROPDOWN: 'dropdown'
};

export type TableColumn = {
  // the accessor is used to match up cell content with column. so if you have an array of items that looks like
  // this : [{id: '1', name: 'Jane', email: 'jane@seeq.com'}, id: '1=2', name: 'Joe', email: 'joe@seeq.com'}]
  // you would specify accessor: 'name' to add a column to displays Jane and Joe
  accessor: string,
  // optional header - this is the text that is displayed in the table header. Note that ROW_SELECTION columns don't
  // render any text
  header?: string,
  // if your cell is not a text, CHECKMARK or ROW_SELECTION you can provide your own render function
  cellRenderFunction?: (item: object, accessor?: string) => void,
  // if you desire a more interesting header you can render your own by providing a headerRenderFunction
  headerRenderFunction?: (column?: TableColumn) => void,
  // default filters not enough - you can provide your own
  headerFilterFunction?: (column?: TableColumn) => void,
  // cellStyle can be any css - but typically you want to provide things like width/min-width/max-width
  cellStyle?: React.CSSProperties,
  // cellType: one of CELL_TYPES, if none is provided plain text is assumed (unless you provide a custom render
  // function)
  cellType?: string,
  // should this column be filterable?
  filterable?: boolean,
  // should this column be sortable?
  sortable?: boolean,
  // string representing the search term for the field on the backend, they do not necessarily match the accessors
  searchProperty?: string,
  // if present, when pressing enter or the checkmark in the filter field, this callback will be invoked
  enterCallback?: () => void,
  // if present, adds an icon with the specified text as a tooltip
  tooltip?: string
  // options for the header filter if header type is DROPDOWN
  dropdownOptions?: { value: any, text: string, icon?: string }[]
};

interface TableProps {
  testId?: string;
  columns: TableColumn[];
  items: { id: string }[];
  tableClass?: string;
  selectedIds?: string[];
  onRowClickCallback?: Function;
  onRowSelectCallback?: ({ id: string }) => void;
  isRowSelectRadio?: boolean;
  filterTableCallback?: (option: { value: any }, field: string) => void;
  selectAllCallback?: () => void;
  sortTableCallback?: (field: string, order: boolean) => void;
  sortProperty?: string;
  sortAscending?: boolean;
  selectAll?: boolean;
  searchParams?: {};
}

interface ResizerProps {
  thRef: { current: HTMLTableHeaderCellElement };
  onMouseDown: (ref) => void;
  setStartOffset: (offset: number) => void;
}

/**
 * Resizer is a layer that enabled the dragging of the table headers.
 */
export const Resizer: React.FunctionComponent<ResizerProps> = (props) => {
  const { thRef, setStartOffset } = props;
  return (
    <div
      onMouseDown={(e) => {
        props.onMouseDown(thRef);
        setStartOffset(thRef.current.offsetWidth - e.pageX);
      }}
      className="tableHeaderResizer" />
  );
};

interface ResizableTableHeaderProps {
  column: TableColumn;
  idx: number;
  onMouseDown: (event: Event) => void;
  setStartOffset: (offset: number) => void;
  renderTableHeader: (col: TableColumn) => void;
}

/**
 * Table Headers are broken out into their own little component to avoid having to create the useRefs in a loop ...
 */
export const ResizableTableHeader: React.FunctionComponent<ResizableTableHeaderProps> = (props) => {
  const { column, idx, onMouseDown, setStartOffset, renderTableHeader } = props;
  const ref = useRef();

  return (
    <th key={`row_${idx}`} ref={ref}
      data-testid={`tableHeader_${column.searchProperty}`}>
      <div style={{ position: 'relative' }}>
        {_.isFunction(column.headerRenderFunction) ? column.headerRenderFunction(column) :
          renderTableHeader(column)}
        <Resizer thRef={ref} onMouseDown={onMouseDown} setStartOffset={setStartOffset} />
      </div>
    </th>);
};

/** Generic table element with sorting, filtering, searching capabilities */
export const Table: React.FunctionComponent<TableProps> = (props) => {
  const {
    testId,
    tableClass,
    columns,
    items,
    selectedIds,
    sortProperty,
    sortAscending,
    selectAll,
    searchParams,
    onRowClickCallback,
    onRowSelectCallback,
    isRowSelectRadio = false,
    filterTableCallback,
    sortTableCallback,
    selectAllCallback
  } = props;

  const { t } = useTranslation();

  const [th, setTh] = useState(null);
  const [startOffset, setStartOffset] = useState(null);
  const [displayItems, setDisplayItems] = useState(items);
  useEffect(() => setDisplayItems(items), [items]);

  const handleMouseMove = (e) => {
    if (th?.current) {
      th.current.style.width = startOffset + e.pageX + 'px';
    }
  };
  const handleMouseUp = () => setTh(null);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [th]);

  const onMouseDown = th => setTh(th);

  const renderCheckmark = (item, accessor) => (
    <div className="flexColumnContainer flexCenter">
      <span className={classNames(item[accessor] ? 'fa fa-check text-success' : 'fa fa-close')} />
    </div>
  );

  const renderRowSelect = item => (
    <div className="flexColumnContainer flexCenter"
      onClick={() => onRowSelectCallback(item)}
      data-testid={`rowSelect_${item.id}`}>
      <span className={classNames('fa cursorPointer', _.indexOf(selectedIds, item.id) > -1
        ? `fa-check-${isRowSelectRadio ? 'circle' : 'square'}`
        : `fa-${isRowSelectRadio ? 'circle' : 'square'}-o`)} />
    </div>
  );

  const renderEnabledDisabled = (item, accessor) => (
    <div className="flexColumnContainer">
      <span>{t(item[accessor] ? 'ADMIN.ENABLED' : 'ADMIN.DISABLED')}</span>
    </div>
  );

  const tableColumns = _.map(columns, (column) => {
    switch (column.cellType) {
      case CELL_TYPES.CHECKMARK:
        return _.assign(column, { cellRenderFunction: renderCheckmark });
      case CELL_TYPES.ROW_SELECTION:
        return _.assign(column, { cellRenderFunction: renderRowSelect });
      case CELL_TYPES.ENABLED_DISABLED:
        return _.assign(column, { cellRenderFunction: renderEnabledDisabled });
      case CELL_TYPES.DATE_TIME:
        return _.assign(column,
          { cellRenderFunction: (item, accessor) => <DateCell item={item} accessor={accessor} /> });
      default:
        return column;
    }
  });

  const doFilterTable = (field, value) => {
    if (value !== '' || _.has(searchParams, field)) {
      filterTableCallback({ value }, field);
    }
  };

  const renderTextInputFilter = column => (
    <ClearableInput
      field={column.searchProperty}
      searchValue={searchParams[column.searchProperty]}
      filterTable={doFilterTable}
      enterCallback={column.enterCallback} />);

  const checkmarkFilterOptions = [
    { text: '-', value: null },
    { text: 'ADMIN.YES', icon: 'fa fa-check text-success', value: true },
    { text: 'ADMIN.NO', icon: 'fa fa-close', value: false }
  ];

  const enabledDisabledFilterOptions = [
    { text: '-', value: undefined },
    { text: 'ADMIN.ENABLED', value: true },
    { text: 'ADMIN.DISABLED', value: false }
  ];

  const renderDropdownFilter = (column, filterOptions) => (
    <IconSelect
      selectOptions={filterOptions}
      name={column.searchProperty}
      className={`spec_${column.searchProperty}_filter`}
      onChange={filterTableCallback}
      value={searchParams[column.searchProperty]}
    />);

  const renderNoFilter =
    <div className="positionRelative flexFill">
      {_.some(columns, 'filterable') &&
      <div className="width-maximum form-control disabledTableFilter" />
      }
    </div>;

  const renderFilter = (column) => {
    if (!column.filterable) {
      return renderNoFilter;
    } else {
      let filter: React.ReactNode = null;
      if (column.headerFilterFunction) {
        filter = column.headerFilterFunction(column);
      } else if (column.cellType === CELL_TYPES.CHECKMARK) {
        filter = renderDropdownFilter(column, checkmarkFilterOptions);
      } else if (column.cellType === CELL_TYPES.ENABLED_DISABLED) {
        filter = renderDropdownFilter(column, enabledDisabledFilterOptions);
      } else if (column.cellType === CELL_TYPES.DROPDOWN) {
        filter =  renderDropdownFilter(column, [
          { text: '-', value: undefined },
          ...column.dropdownOptions,
        ]);
      } else {
        filter = renderTextInputFilter(column);
      }

      return (
        <>
          <div className="mt5" />
          {filter}
        </>
      );
    }
  };

  const renderTableHeader = (column) => {
    if (column.cellType === CELL_TYPES.ROW_SELECTION) {
      if (_.isNil(selectAllCallback)) {
        return;
      } else {
        return (
          <div className="flexRowContainer flexAlignCenter">
            <div
              onClick={() => selectAllCallback()}
              data-testid="selectAllRows">
              <Icon icon={selectAll ? 'fa-check-square' : 'fa-square-o'} type="text" />
            </div>
          </div>
        );
      }
    } else if (column.header) {
      return (
        <div className="flexRowContainer">
          {column.sortable ?
            <div data-testid={`${column.accessor}_header`} className="flexColumnContainer cursorPointer"
              onClick={() => sortTableCallback(column.accessor, sortAscending)}>
              {t(column.header)}
              {column.tooltip && <HelpIcon tooltip={column.tooltip} extraClassNames="pl5" />}
              <div data-testid={`${column.accessor}_sort`}>
                <SortIcon sortAsc={sortAscending}
                  sortProperty={column.accessor}
                  sortBy={sortProperty} />
              </div>
            </div> :
            <div data-testid={`${column.accessor}_header`} className="flexColumnContainer cursorNotAllowed">
              {t(column.header)}
              {column.tooltip && <HelpIcon tooltip={column.tooltip} extraClassNames="pl5" />}
            </div>
          }
          {renderFilter(column)}
        </div>
      );
    }
  };

  const appliedTableClasses = classNames('table', 'width-99-percent', 'table-striped', 'pb65', tableClass);

  return (
    <table className={appliedTableClasses} data-testid={testId}>
      <thead>
        <tr>
          {_.map(tableColumns, (column, idx) => (
            <ResizableTableHeader
              column={column}
              idx={idx}
              key={idx}
              onMouseDown={onMouseDown}
              renderTableHeader={renderTableHeader}
              setStartOffset={setStartOffset} />
          ))}
        </tr>
      </thead>
      <tbody className="hoverTable">
        {_.map(displayItems, item =>
          (
            <tr
              className='hoverTable specWorkbenchItemRow'
              onClick={e => _.isFunction(onRowClickCallback) ? onRowClickCallback(item, e) : _.noop}
              key={`row_${item.id}`}>
              {_.map(columns, (column, idx) => (
                <td key={`row_${item.id}_col_${idx}`} style={column.cellStyle}
                  className={`specWorkbenchItemCell_${column.accessor}_${item.id}`}>
                  {_.isFunction(column.cellRenderFunction) ? column.cellRenderFunction(item, column.accessor) :
                    _.get(item, column.accessor)}
                </td>)
              )}
            </tr>
          ))}
      </tbody>
    </table>
  );
};
