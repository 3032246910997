import angular from 'angular';
import { browserIsIE } from '@/utilities/browserId';

const dependencies = [
  'Sq.Vendor',
  'Sq.TrendData',
  'Sq.CompositeSearch',
  'Sq.FormulaTool',
  'Sq.Annotation',
  'Sq.Services.Utilities',
  'Sq.Services.Notifications',
  'Sq.Services.PendingRequests',
  'Sq.Services.Export',
  'Sq.Services.DomClassList',
  'Sq.Services.D3',
  'Sq.Services.Modal'
];

export let MAX_CAPSULE_TIME_ITEMS: number;

angular
  .module('Sq.TrendViewer', dependencies)
  .constant('MAX_CAPSULE_TIME_ITEMS', browserIsIE ? 30 : 90)
  .run(($injector) => {
    MAX_CAPSULE_TIME_ITEMS = $injector.get('MAX_CAPSULE_TIME_ITEMS');
  });

// Use common screen resolution widths as thresholds for recomputing chartWidth.
export const CHART_THRESHOLDS = [400, 800, 1280, 1920, 3840, 7680];
export const DEFAULT_AXIS_LABEL_COLOR = '#869298';
export const DEFAULT_AXIS_LINE_COLOR = '#C0D0E0';
// Base config for all lane labels
export const LANE_LABEL_CONFIG = {
  useHTML: true,
  align: 'right',
  verticalAlign: 'top',
  x: 0,
  y: 11,
  style: { color: DEFAULT_AXIS_LABEL_COLOR, whiteSpace: 'normal' }
};
// Buffer so there is a spacer between lanes
export const LANE_BUFFER = 10;
// Buffer so that capsule time start/endOnTick labels don't overlap when multiple lanes display
export const CAPSULE_TIME_LANE_BUFFER = 14;
export const PLOT_BAND = 'plotBand';
export const Z_INDEX = {
  SELECTED_REGION: 1,
  CAPSULE_REGION: 2,
  COVER_XAXIS_OVERFLOW: 3,
  CAPSULE_ICONS: 5,
  SELECTED_REGION_REMOVE: 10,
  CAPSULE_REGION_REMOVE: 11,
  LINE_BREAKS: 100,
  Y_AXIS_DEFINITION: 101
};

export const ANALYSIS_COLORS = {
  // $sq-dark-primary-analysis
  DARK_PRIMARY: '#007960',
  // $sq-light-color-analysis
  LIGHT_COLOR: '#EDF7F6'
};
