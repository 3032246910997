import React from 'react';
import classNames from 'classnames';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Button } from 'react-bootstrap';
import { Icon, IconType } from '@/hybrid/core/Icon.atom';

export type ButtonType = 'button' | 'reset' | 'submit';
export type ButtonSize = 'sm' | 'lg';
export type ButtonVariant = 'outline' | 'theme' | 'warning' | 'danger'; // future variants to add: no border

export interface TextButtonProps {
  /** function to call when clicking the button (takes no parameters) */
  onClick: () => any;
  /** text on the button (i.e. Submit) */
  label?: string;
  /** style of button you want (outline is default, see type definition) */
  variant?: ButtonVariant;
  /** type of button (i.e button/submit/reset, default is button) */
  type?: ButtonType;
  /** size of the button (i.e. sm/lg, default is normal) */
  size?: ButtonSize;
  /** true if button should be disabled */
  disabled?: boolean;
  /** extra class names to be placed on the Icon component */
  extraClassNames?: string;
  /** icon class to be used if an icon should go before the text (i.e. fc-zoom) */
  icon?: string;
  /** icon color option (text, white, theme, color, default is "text") */
  iconStyle?: IconType;
  /** color of the icon if it is custom (required if iconStyle is "color") */
  iconColor?: string;
  /** id to place on the button element */
  id?: string;
  /** id that will be used in the data-testid attribute on the button element */
  testId?: string;
  /** If present, overrides label */
  formattedLabel?: JSX.Element;
}

/**
 * Generic button with text and/or icon
 */
export const TextButton: React.FunctionComponent<TextButtonProps> = ({
  onClick,
  label,
  variant = 'outline',
  type = 'button',
  iconStyle = 'text',
  size,
  disabled,
  extraClassNames,
  icon,
  iconColor,
  id,
  testId,
  formattedLabel
}) => {
  const { t } = useTranslation();

  return (
    <Button className={classNames(extraClassNames, `sq-btn-${variant}`)}
      id={id}
      type={type}
      variant={null}
      size={size}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      disabled={disabled}
      bsPrefix="sq-btn"
      data-testid={testId}>
      <div className="flexColumnContainer flexAlignCenter flexJustifyCenter">
        {icon && <Icon icon={icon} type={iconStyle} color={iconColor} extraClassNames={label ? 'mr5' : ''} />}
        {formattedLabel ? formattedLabel : t(label)}
      </div>
    </Button>
  );
};
