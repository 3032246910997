import _ from 'lodash';
import angular from 'angular';
import { TrendScalarStore } from '@/trendData/trendScalar.store';
import { TreemapStore } from '@/treemap/treemap.store';
import { DurationStore } from '@/trendData/duration.store';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { UtilitiesService } from '@/services/utilities.service';
import { NotificationsService } from '@/services/notifications.service';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.module';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.module';
import { PUSH_IGNORE } from '@/services/stateSynchronizer.service';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { TrendActions } from '@/trendData/trend.actions';
import { TreesApi } from '@/sdk';

angular.module('Sq.Treemap').service('sqTreemapActions', sqTreemapActions);
export type TreemapActions = ReturnType<typeof sqTreemapActions>;

function sqTreemapActions(
  $injector: ng.auto.IInjectorService,
  $q: ng.IQService,
  flux: ng.IFluxService,
  sqTreemapStore: TreemapStore,
  sqDurationStore: DurationStore,
  sqTrendCapsuleSetStore: TrendCapsuleSetStore,
  sqWorksheetStore: WorksheetStore,
  sqTrendSeriesStore: TrendSeriesStore,
  sqTrendScalarStore: TrendScalarStore,
  sqPendingRequests: PendingRequestsService,
  sqDateTime: DateTimeService,
  sqUtilities: UtilitiesService,
  sqTrendDataHelper: TrendDataHelperService,
  sqNotifications: NotificationsService,
  sqSystemConfiguration: SystemConfigurationService,
  sqTreesApi: TreesApi
) {
  const service = {
    setParent,
    setStatistic,
    setDisplayPixels,
    fetchTreemap
  };

  return service;

  /**
   * Sets the parent asset for swapping, the children underneath it will be displayed in the treemap.
   *
   * @param {Object} asset - The parent asset
   * @param {String} asset.id - The ID of the asset
   * @param {String} [option] - A push constant, such as PUSH_IGNORE
   * @return {Promise} - Resolved when the treemap is fetched
   */
  function setParent(asset, option?) {
    flux.dispatch('TREEMAP_SET_PARENT', _.pick(asset, ['id']), option);
    return service.fetchTreemap();
  }

  /**
   * Set a statistic to be displayed on each asset node.
   *
   * @param {Number} index - Which statistic is being set
   * @param {String} [id] - The id of the signal to use for calculating the statistic
   * @param {String} [key] - One of the statistic keys from SAMPLE_FROM_SCALARS.VALUE_METHODS
   * @return {Promise} - Resolved when the treemap is fetched
   */
  function setStatistic(index, id, key) {
    flux.dispatch('TREEMAP_SET_STATISTIC', { id, key, index });
    return service.fetchTreemap();
  }

  /**
   * Updates the number of pixels of the chart so that the auto-update interval calculates correctly.
   *
   * @param {Number} width - The width, in pixels, of the chart
   */
  function setDisplayPixels(width) {
    // A rough estimate of what the width on the trend chart is, just so that the auto-update calculation is roughly
    // the same between views
    const Y_AXIS_WIDTH = 40;
    flux.dispatch('AUTO_UPDATE_SET_DISPLAY_PIXELS', { displayPixels: width + Y_AXIS_WIDTH }, PUSH_IGNORE);
  }

  /**
   * Fetches the treemap based on the configuration settings.
   */
  function fetchTreemap() {
    const options = {} as any;
    const conditions = sqTrendCapsuleSetStore.items;
    const signalValueMethods = _.filter(SAMPLE_FROM_SCALARS.VALUE_METHODS,
      _.flow(_.property('input'), _.partial(_.includes, _, 'sample')));
    if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.TREEMAP) {
      return $q.resolve();
    }

    if (_.isEmpty(sqTreemapStore.priorityColors)) {
      const colors = _.chain(sqSystemConfiguration.priorityColors)
        .filter((priority: any) => priority.level >= 0)
        .map('color')
        .tap((colors) => {
          const neutralColor = _.last(colors);
          // R21 moved to white as the neutral color, but treemap should still use green unless otherwise specified
          colors[colors.length - 1] = _.toLower(neutralColor) === '#ffffff' ? '#068C45' : neutralColor;
        })
        .value();
      flux.dispatch('TREEMAP_SET_COLORS', { colors });
    }

    if (!conditions.length) {
      flux.dispatch('TREEMAP_SET_HELP_KEY', { helpKey: 'addCondition' });
      return $q.resolve();
    }

    if (!allUseSameAsset(conditions)) {
      flux.dispatch('TREEMAP_SET_HELP_KEY', { helpKey: 'mustUseSameAsset' });
      return $q.resolve();
    }

    if (!_.every(conditions, hasPriorityColor)) {
      flux.dispatch('TREEMAP_SET_HELP_KEY', { helpKey: 'selectPriorities' });
      return $q.resolve();
    }

    flux.dispatch('TREEMAP_SET_SWAP', _.pick(conditions[0].assets[0], ['id', 'name']));

    if (_.isEmpty(sqTreemapStore.parent)) {
      return sqTreesApi.getTree({ id: sqTreemapStore.swap.id, offset: 0, limit: 100 })
        .then(({ data: { item } }) => service.setParent(_.last(item.ancestors), PUSH_IGNORE));
    }

    if (_.isEmpty(sqTreemapStore.breadcrumbs)) {
      sqTreesApi.getTree({ id: sqTreemapStore.parent.id, offset: 0, limit: 100 })
        .then(({ data: { item } }) => flux.dispatch('TREEMAP_SET_BREADCRUMBS', { breadcrumbs: item.ancestors.concat([item]) }));
    }

    options.start = sqDurationStore.displayRange.start.toISOString();
    options.end = sqDurationStore.displayRange.end.toISOString();
    options.swapId = sqTreemapStore.swap.id;
    options.parentId = sqTreemapStore.parent.id;
    options.conditionIds = _.chain(conditions)
      .sortBy(item => _.indexOf(sqTreemapStore.priorityColors, item.color))
      .map('id')
      .value();
    options.parameters = {};
    options.formulas = _.map(sqTreemapStore.validStatistics, function(statistic: any, i) {
      const identifier = sqUtilities.getShortIdentifier(i);
      // for now we do not support providing a unit from within the treemap UI so we filter out the paramter to ensure
      // the formulas using a $unit will still compile as expected (the Backend code provides seconds as the default
      // unit)
      const stat = _.find(signalValueMethods, ['key', statistic.key])['stat'].replace('$unit', '');
      const capsule = sqDateTime.getCapsuleFormula(sqDurationStore.displayRange);
      options.parameters[identifier] = statistic.id;
      return `$${identifier}.aggregate(${stat}, ${capsule})`;
    });

    options.parameters = sqUtilities.encodeParameters(options.parameters);
    options.cancellationGroup = 'treemap';

    _.forEach(options.conditionIds, id => flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id }));
    sqPendingRequests.cancelGroup(options.cancellationGroup);
    return sqTreesApi.treemap(options)
      .then(({ data }) => {
        const tree = _.map(data.tree, function(node: any) {
          const color = node.priority === -1 ? sqTreemapStore.neutralColor :
            _.find(conditions, ['id', options.conditionIds[node.priority]])['color'];
          const displayScalars = _.chain(node.displayScalars)
            .map((scalar, i) => _.assign(scalar, { statistic: sqTreemapStore.validStatistics[i] }))
            .filter('statistic') // in case it has been removed since starting fetch
            .map((scalar) => {
              return _.assign(_.omit(scalar, ['statistic']), {
                title: _.find(signalValueMethods, ['key', scalar.statistic.key])['title'],
                signal: sqTrendDataHelper.findItemIn([sqTrendSeriesStore, sqTrendScalarStore], scalar.statistic.id).name
              });
            })
            .value();

          return _.assign(node, { color, displayScalars });
        });

        _.forEach(options.conditionIds, id => flux.dispatch('TREND_SET_DATA_STATUS_PRESENT', { id }));
        flux.dispatch('TREEMAP_SET_TREE', { tree });
        _.forEach(conditions, (condition: any) => {
          flux.dispatch('TREND_SET_DATA_STATUS_PRESENT',
            { id: condition.id, warningCount: data.warningCount, warningLogs: data.warningLogs });
        });
      })
      .catch(function(response) {
        sqNotifications.apiError(response);
        _.forEach(options.conditionIds,
          id => $injector.get<TrendActions>('sqTrendActions')
            .catchItemDataFailure(id, options.cancellationGroup, response));
      });
  }

  /**
   * Determines if a condition is using a priority color.
   *
   * @param {Object} item - The item to check.
   * @return {Boolean} True if it is using a priority color, false otherwise
   */
  function hasPriorityColor(item) {
    return _.includes(sqTreemapStore.priorityColors, item.color);
  }

  /**
   * Determines if all items are asset-based and from the same asset.
   *
   * @param {Object[]} items - The array of items to check.
   * @return {Boolean} True if all items are asset-based and from the same asset, false otherwise
   */
  function allUseSameAsset(items) {
    return _.every(items, 'assets.length') &&
      _.chain(items)
        .flatMap('assets')
        .compact()
        .uniqBy('id')
        .value()
        .length === 1;
  }
}
