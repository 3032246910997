import React, { useState } from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { Field, useForm } from 'react-final-form';
import { FormControl } from 'react-bootstrap';
import {
  getFormFieldProps,
  getValidationFunction
} from '@/hybrid/formbuilder/formbuilder.utilities';
import { FormError } from '@/hybrid/core/FormError.atom';
import _ from 'lodash';

export interface ValueWithSymbolIF extends ValidatingFormComponent<string> {
  component: 'ValueWithSymbolFormComponent';
  symbol: string | JSX.Element;
  label?: string;
  customErrorText?: string;
  id?: string;
  min?: number;
  max?: number;
}

/**
 * Text input field (form component) with symbol/unit
 */
export const ValueWithSymbolFormComponent: React.FunctionComponent<ValueWithSymbolIF> =
  (props) => {
    const {
      name,
      testId = 'valueWithSymbol',
      label,
      symbol,
      onChange,
      validation,
      extendValidation,
      customErrorText,
      min,
      max
    } = props;

    const defaultValidation = (value: string) => {
      if (!value) return true;

      const hasMin = _.isNumber(min), hasMax = _.isNumber(max);
      const numberValue = Number(value);
      if (hasMin || hasMax) {
        return (!_.isNumber(numberValue) || _.isNaN(
          numberValue)) || (hasMin && numberValue < min) || (hasMax && numberValue > max);
      }

      return false;
    };

    const appliedValidation = getValidationFunction(
      defaultValidation,
      extendValidation,
      validation
    );

    const { t } = useTranslation();
    const formState = useForm().getState();
    const [showError, setShowError] = useState(false);

    return (
      <div
        className="flexRowContainer pb15 flexBaseline"
        data-testid={testId}>
        {label && (
          <div className="pb5">
            <span data-testid={`${testId}Label`}>{t(label)}</span>
          </div>
        )}
        <div className="flexRowContainer flexFill">
          <div className="input-group width-80">
            <Field name={name} validate={appliedValidation}>
              {({ input, meta }) => {
                const formFieldProps = getFormFieldProps(
                  formState,
                  input,
                  meta,
                  props
                );
                setTimeout(() => setShowError(formFieldProps.showError));

                return (
                  <FormControl
                    name="value"
                    value={formFieldProps.value}
                    onChange={e => onChange(e.target.value)}
                    data-testid={`${testId}Input`}
                    min={min}
                    max={max}
                    isInvalid={showError}
                  />
                );
              }}
            </Field>
            <span className="input-group-addon" data-testid={`${testId}Symbol`}>{symbol}</span>
          </div>
          {showError && customErrorText && (
            <FormError errorText={customErrorText} dataTestId={`${testId}Error`} extraClassNames='pt5' />
          )}
        </div>
      </div>
    );
  };
