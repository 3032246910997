import _ from 'lodash';
import angular from 'angular';
import { SEARCH_BREADCRUMB, SEARCH_MODES, SEARCH_RESULT_TYPES } from '@/search/search.module';
import { INITIALIZE_MODE, PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';
import { SEEQ_DATASOURCE } from '@/main/app.constants';
import { ANNOTATION_TYPE } from '@/annotation/annotation.module';
import { SeeqNames } from '@/main/app.constants.seeqnames';

/**
 * A base store that adds standard functionality for all stores that are used by the search widget for searching items
 * and assets.
 */
angular.module('Sq.Search').service('sqBaseSearchStore', sqBaseSearchStore);

export type BaseSearchStoreService = ReturnType<typeof sqBaseSearchStore>;

interface SearchFilters {
  filters: string | string[];
  types: string | string[];
}

const LOCAL_DATASOURCE_CLASSES = [SeeqNames.LocalDatasources.FileSignalCache.DatasourceClass,
  SeeqNames.LocalDatasources.FileSignalStorage.DatasourceClass,
  SeeqNames.LocalDatasources.PostgresDatums.DatasourceClass,
  SeeqNames.LocalDatasources.SeeqCalculationItemStorage.DatasourceClass,
  SeeqNames.LocalDatasources.SeeqMetadataItemStorage.DatasourceClass];
const DATASOURCES_TO_EXCLUDE = ['Auth', 'LDAP'];

function sqBaseSearchStore() {
  const service = {
    extend
  };

  return service;

  /**
   * Extends a store with helper methods, exports, and handlers shared by all search stores.
   *
   * Note that each action must be passed the type as part of its payload and ensure that it does nothing if the
   * payload type does not match the type passed in.
   *
   * @param {Object} store - The store being extended.
   * @param {String} pane - The name of the search pane, one of SEARCH_PANES
   * @returns {Object} The original store, augmented with shared functionality.
   */
  function extend<T>(store: ng.IFluxStoreDeclaration<T>, pane) {
    const helpers = {
      persistenceLevel: PERSISTENCE_LEVEL.WORKSHEET,

      /**
       * Marks that this store has had its initial async data fetched.
       *
       * @param {Object} payload - Object container for arguments
       * @param {string} payload.pane - The search pane, one of SEARCH_PANES
       * @param {boolean} payload.isAsyncInitialized - True if the initial async data has been fetched
       */
      setAsyncInitialized(payload) {
        if (payload.pane === pane) {
          this.state.set('isAsyncInitialized', payload.isAsyncInitialized);
        }
      },

      /**
       * Initiates a search.
       *
       * @param {Object} payload - Object container for arguments
       * @param {String} payload.pane - The search pane, one of SEARCH_PANES
       */
      initiateSearch(payload) {
        if (payload.pane === pane) {
          this.state.merge({
            searching: SEARCH_RESULT_TYPES.ITEMS,
            isPaginating: false,
            items: []
          });
          this.state.commit();
          this.setMode({ pane: payload.pane, mode: SEARCH_MODES.SEARCH });
        }
      },

      /**
       * Finishes a search.
       *
       * @param {Object} payload - Object container for arguments
       * @param {String} payload.pane - The search pane, one of SEARCH_PANES
       * */
      finishSearch(payload) {
        if (payload.pane === pane) {
          this.state.merge({ searching: undefined, isPaginating: false });
        }
      },

      /**
       * Begin the proccess of loading the next page by setting paginating to true
       *
       * @param {Object} payload - Object container for arguments
       * @param {String} payload.pane - The search pane, one of SEARCH_PANES
       */
      loadNextPage(payload) {
        if (payload.pane === pane) {
          this.state.set('isPaginating', true);
        }
      },

      /**
       * Sets the asset browser to the specified asset and sets the searching flag
       *
       * @param {Object} payload - Object container for arguments
       * @param {String} payload.pane - The search pane, one of SEARCH_PANES
       * @param {String} payload.asset - the id that specifies an item
       */
      exploreAsset(payload) {
        if (payload.pane === pane) {
          this.state.merge({
            searching: SEARCH_RESULT_TYPES.ASSETS,
            mode: payload.asset === '' ? SEARCH_MODES.OVERVIEW : SEARCH_MODES.TREE,
            currentAsset: payload.asset,
            items: []
          });
          this.state.commit();
          this.clearFilters(payload);
        }
      },

      /**
       * Clears the search filters.
       *
       * @param {Object} payload - Object container for arguments
       * @param {String} payload.pane - The search pane, one of SEARCH_PANES
       */
      clearFilters(payload) {
        if (payload.pane === pane) {
          this.state.merge({
            nameFilter: '',
            descriptionFilter: '',
            documentFilter: '',
            typeFilter: [],
            datasourceFilter: [],
            sortBy: ''
          });
        }
      },

      /**
       * Clears search state.
       *
       * @param {Object} payload - Object container for arguments
       * @param {String} payload.pane - The search pane, one of SEARCH_PANES
       */
      clear(payload) {
        if (payload.pane === pane) {
          this.initialize(INITIALIZE_MODE.SOFT);
        }
      },

      /**
       * Sets the breadcrumbs that should be shown in the asset browser.
       *
       * @param {Object} payload - Object container for arguments
       * @param {String} payload.pane - The search pane, one of SEARCH_PANES
       * @param {Object[]} payload.breadcrumbs - an array of breadcrumbs containing breadcrumb objects
       */
      setBreadcrumbs(payload) {
        if (payload.pane === pane) {
          this.state.set('breadcrumbs', payload.breadcrumbs);
        }
      },

      /**
       * Sets the name filter.
       *
       * @param {Object} payload - Object container for arguments
       * @param {String} payload.pane - The search pane, one of SEARCH_PANES
       * @param {String} payload.nameFilter - The name for which to search
       */
      setNameFilter(payload) {
        if (payload.pane === pane) {
          this.state.set('nameFilter', payload.nameFilter);
        }
      },

      /**
       * Sets the description filter.
       *
       * @param {Object} payload - Object container for arguments
       * @param {String} payload.pane - The search pane, one of SEARCH_PANES
       * @param {String} payload.descriptionFilter - The description for which to search
       */
      setDescriptionFilter(payload) {
        if (payload.pane === pane) {
          this.state.set('descriptionFilter', payload.descriptionFilter);
        }
      },

      /**
       * Sets the document filter.
       *
       * @param {Object} payload - Object container for arguments
       * @param {String} payload.pane - The search pane, one of SEARCH_PANES
       * @param {String} payload.documentFilter - The document for which to search
       */
      setDocumentFilter(payload) {
        if (payload.pane === pane) {
          this.state.set('documentFilter', payload.documentFilter);
        }
      },

      /**
       * Toggles an Item type in the list of type filters. If type is found it is removed, else it is added.
       *
       * @param {Object} payload - Object container for arguments
       * @param {String} payload.pane - The search pane, one of SEARCH_PANES
       * @param {String} payload.type - The type to toggle.
       */
      toggleType(payload) {
        let index;
        if (payload.pane === pane) {
          index = this.state.get('typeFilter').indexOf(payload.type);
          if (index === -1) {
            this.state.push('typeFilter', payload.type);
          } else {
            this.state.splice('typeFilter', [index, 1]);
          }
        }
      },

      /**
       * Toggles a Datasource in the list of datasource filters. If datasource is found it is removed, else it is
       * added.
       *
       * @param {Object} payload - Object container for arguments
       * @param {String} payload.pane - The search pane, one of SEARCH_PANES
       * @param {Object} payload.datasource - The datasource to toggle.
       * @param {String} payload.id - The unique id of the datasource.
       * @param {String} payload.name - The name of the datasource.
       * @param {String} payload.datasourceClass - The datasource class.
       * @param {String} payload.datasourceId - The datasource identifier.
       */
      toggleDatasource(payload) {
        let index;
        if (payload.pane === pane) {
          index = _.findIndex(this.state.get('datasourceFilter'), ['id', payload.datasource.id]);
          if (index === -1) {
            this.state.push('datasourceFilter', payload.datasource);
          } else {
            this.state.splice('datasourceFilter', [index, 1]);
          }
        }
      },

      /**
       * Sets the sort order.
       *
       * @param {Object} payload - Object container for arguments
       * @param {String} payload.pane - The search pane, one of SEARCH_PANES
       * @param {String} payload.sortBy - The property to sort by
       */
      setSortBy(payload) {
        if (payload.pane === pane) {
          this.state.set('sortBy', payload.sortBy);
        }
      },

      /**
       * Process the results of fetching the search results.
       * We will always concatenate results to items as we reset them to an empty array at the beginning of each
       * search.
       *
       * @param {Object} payload - Object container for arguments
       * @param {String} payload.pane - The search pane, one of SEARCH_PANES
       * @param {Object[]} payload.items - The search results
       * @param {Boolean} payload.hasNextPage - True if there are more results that can be fetched
       */
      processResults(payload) {
        if (payload.pane === pane) {
          this.state.set('items', _.uniqBy([...this.state.get('items'), ...payload.items], 'id'));
          this.state.set('hasNextPage', payload.hasNextPage);
        }
      },

      /**
       * Switches to advanced or simple mode. When switching to simple mode the advanced filters are cleared.
       *
       * @param {Object} payload - Object container for arguments
       * @param {String} payload.pane - The search pane, one of SEARCH_PANES
       * @param {Boolean} payload.isAdvancedMode - True for advanced mode, false for simple mode
       */
      setIsAdvancedMode(payload) {
        if (payload.pane === pane) {
          this.state.set('isAdvancedMode', payload.isAdvancedMode);
          if (!payload.isAdvancedMode) {
            this.state.merge({
              descriptionFilter: '',
              documentFilter: '',
              typeFilter: [],
              datasourceFilter: [],
              sortBy: ''
            });
          }
        }
      },

      /**
       * Switches to one of the search result modes.
       *
       * @param {Object} payload - Object container for arguments
       * @param {String} payload.pane - The search pane, one of SEARCH_PANES
       * @param {Boolean} payload.mode - One of SEARCH_MODES
       */
      setMode(payload) {
        if (payload.pane === pane) {

          // Don't need to do anything if we're already in the correct mode
          if (payload.mode === this.state.get('mode')) {
            return;
          }

          // Some modes require setting portions of the state.
          if (payload.mode === SEARCH_MODES.OVERVIEW) {
            // Clear and refresh when going to Overview Mode
            this.state.merge({
              searching: undefined,
              isPaginating: false,
              currentAsset: '',
              breadcrumbs: [],
              items: []
            });
            this.clearFilters(payload);

          } else if (payload.mode === SEARCH_MODES.PINNED) {
            // Set breadcrumbs when going to Pinned Mode
            this.state.set('breadcrumbs', [{
              id: ''
            }, {
              name: 'SEARCH_DATA.PINNED',
              id: 'PINNED',
              type: 'VIEW_MODE'
            }]);
            this.clearFilters(payload);
            this.setIsAdvancedMode({ pane: payload.pane, isAdvancedMode: false });

          } else if (payload.mode === SEARCH_MODES.RECENT) {
            // Set breadcrumbs when going to Recently Accessed Mode
            this.state.set('breadcrumbs', [{
              id: ''
            }, {
              name: 'SEARCH_DATA.RECENTLY_ACCESSED',
              id: 'RECENTLY_ACCESSED',
              type: 'VIEW_MODE'
            }]);
            this.setIsAdvancedMode({ pane: payload.pane, isAdvancedMode: false });
            this.clearFilters(payload);

          } else if (payload.mode === SEARCH_MODES.SEARCH) {

            if (this.state.get('mode') === SEARCH_MODES.TREE) {
              // When going from Asset Tree Mode to Search Mode, add Search Results to the end of the breadcrumbs
              this.state.set('breadcrumbs', this.state.get('breadcrumbs').concat(SEARCH_BREADCRUMB));
            } else {
              // Otherwise, set the breadcrumbs to Home -> Search Results
              this.state.set('breadcrumbs', [{ id: '' }, SEARCH_BREADCRUMB]);
            }
          } else if (payload.mode === SEARCH_MODES.ASSET_GROUPS) {
            // Set breadcrumbs when going to Recently Accessed Mode
            this.state.set('breadcrumbs', [{
              id: ''
            }, {
              name: 'SEARCH_DATA.ASSET_GROUPS',
              id: 'ASSET_GROUPS',
              type: 'VIEW_MODE'
            }]);
            this.setIsAdvancedMode({ pane: payload.pane, isAdvancedMode: false });
            this.clearFilters(payload);
          }

          this.state.set('mode', payload.mode);
        }
      },

      /**
       * Set datasources to show in dropdown, and local datasources to query when we encounter SEEQ_DATASOURCE
       *
       * @param payload - Object container for arguments
       * @param payload.pane - The search pane, one of SEARCH_PANES
       * @param payload.datasources - List of datasources to
       */
      setDatasources(payload) {
        if (payload.pane === pane) {
          const localDatasources = _.filter(payload.datasources, datasource =>
            _.includes(LOCAL_DATASOURCE_CLASSES, datasource.datasourceClass));
          this.state.set('localDatasources', localDatasources);

          const datasourcesToSet = _.chain(payload.datasources)
            .map(({ id, name, datasourceId, datasourceClass }) => ({ id, name, datasourceId, datasourceClass }))
            .reject(datasource => _.includes(DATASOURCES_TO_EXCLUDE, datasource.datasourceClass))
            .reject(datasource => _.includes(LOCAL_DATASOURCE_CLASSES, datasource.datasourceClass))
            .concat(SEEQ_DATASOURCE)
            .value();
          this.state.set('datasources', datasourcesToSet);
        }
      },

      /**
       * Get filters for the ItemsApi search query
       *
       * @param payload - Object container for arguments
       * @param payload.searchTypes - array of item types to search for if the typeFilter is empty
       */
      getSearchFilters(searchTypes): SearchFilters {
        let types, filters;
        const andFilters = _.chain(['name', 'description'])
          .map(filterKey => [filterKey, this.state.get(filterKey + 'Filter')])
          .reject(([filterKey, value]) => _.isEmpty(value))
          .map(([filterKey, value]) => `${filterKey}~=${value}`)
          .join('&&')
          .value();

        // Replace general Seeq local datasource with actual local datasources (e.g. PostgresDatums)
        let datasourceFilter = this.state.get('datasourceFilter');
        if (_.some(datasourceFilter,
          datasource => datasource.datasourceClass === SEEQ_DATASOURCE.datasourceClass)) {
          datasourceFilter = datasourceFilter.concat(this.state.get('localDatasources'));
          datasourceFilter = _.reject(datasourceFilter,
            datasource => datasource.datasourceClass === SEEQ_DATASOURCE.datasourceClass);
        }
        const orFilters = _.map(datasourceFilter, (datasource: any) =>
          `datasource class==${datasource.datasourceClass}&&datasource id==${datasource.datasourceId}`
          + `&&${andFilters}`);

        // If the user has supplied a document filter, then search journal entries
        const documentFilter = this.state.get('documentFilter');
        const typeFilter = this.state.get('typeFilter');
        if (!_.isEmpty(documentFilter)) {
          // Search the 'Plain Text Document' field to avoid returning results for HTML
          filters = 'Plain Text Document~=' + documentFilter;
          types = [ANNOTATION_TYPE.JOURNAL];
        } else {
          filters = orFilters.length ? orFilters : andFilters;
          types = _.isEmpty(typeFilter) ? searchTypes : typeFilter;
        }

        return { filters, types };
      }
    };

    const handlers = {
      SEARCH_ASYNC_INITIALIZED: 'setAsyncInitialized',
      SEARCH_INITIATE: 'initiateSearch',
      SEARCH_FINISH: 'finishSearch',
      SEARCH_SET_NAME_FILTER: 'setNameFilter',
      SEARCH_SET_DESCRIPTION_FILTER: 'setDescriptionFilter',
      SEARCH_SET_DOCUMENT_FILTER: 'setDocumentFilter',
      SEARCH_SET_BREADCRUMBS: 'setBreadcrumbs',
      SEARCH_TOGGLE_TYPE: 'toggleType',
      SEARCH_EXPLORE_ASSET: 'exploreAsset',
      SEARCH_TOGGLE_DATASOURCE: 'toggleDatasource',
      SEARCH_SET_MODE: 'setMode',
      SEARCH_SET_ADVANCED_MODE: 'setIsAdvancedMode',
      SEARCH_RESULTS_SUCCESS: 'processResults',
      SEARCH_LOAD_NEXT_PAGE: 'loadNextPage',
      SEARCH_CLEAR: 'clear',
      SEARCH_SET_DATASOURCES: 'setDatasources',
      SEARCH_SET_SORT_BY: 'setSortBy'
    };

    store.initialize = function(initializeMode) {
      const saveState = this.state && initializeMode === INITIALIZE_MODE.SOFT;
      this.state = this.immutable({
        nameFilter: '',
        descriptionFilter: '',
        documentFilter: '',
        typeFilter: [],
        datasourceFilter: [],
        sortBy: '',
        currentAsset: '',
        breadcrumbs: [],
        items: [],
        mode: SEARCH_MODES.OVERVIEW,
        hasNextPage: false,
        searching: undefined,
        isPaginating: false,
        isAdvancedMode: false,
        isAsyncInitialized: false,
        localDatasources: saveState ? this.state.get('localDatasources') : [],
        datasources: saveState ? this.state.get('datasources') : []
      });
    };

    _.defaults(store, helpers);

    store.handlers = _.assign({}, handlers, store.handlers);

    store.exports = store.exports || {} as T;

    _.forEach(['nameFilter', 'descriptionFilter', 'documentFilter', 'typeFilter', 'datasourceFilter',
      'sortBy', 'currentAsset', 'breadcrumbs', 'items', 'hasNextPage', 'searching', 'isPaginating',
      'isAdvancedMode', 'isAsyncInitialized', 'mode', 'localDatasources', 'datasources'], function(prop) {
      Object.defineProperty(store.exports, prop, {
        configurable: true,
        enumerable: true,
        get() {
          return this.state.get(prop);
        }
      });
    });

    _.assign(store.exports, { getSearchFilters: helpers.getSearchFilters });

    type AddedExports = {
      nameFilter: any,
      descriptionFilter: any,
      documentFilter: any,
      typeFilter: any,
      datasourceFilter: any,
      localDatasources: any,
      datasources: any,
      sortBy: any,
      currentAsset: any,
      breadcrumbs: any,
      items: any,
      hasNextPage: any,
      searching: any,
      isPaginating: any,
      isAdvancedMode: any,
      isAsyncInitialized: boolean,
      mode: any,
      getSearchFilters: (searchTypes: string[]) => SearchFilters
    };

    return store as ng.IFluxStoreDeclaration<T & AddedExports>;
  }
}
