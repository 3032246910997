import angular from 'angular';
import template from './advancedParameters.component.html';

angular.module('Sq.Investigate').component('sqAdvancedParameters', {
  template,
  controller: 'AdvancedParametersCtrl',
  transclude: true,
  bindings: {
    toolName: '@', // used for tracking. This will display as the event action and should match the name of the tool
    toolId: '<', // has to match the ID  of the tool; one of TREND_TOOLS
    toolStore: '<'
  }
});
