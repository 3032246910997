import React, { useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { Modal } from 'react-bootstrap';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { AssetChild, AssetGroupActions } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { FormulaFromScratch } from '@/hybrid/assetGroupEditor/calculations/FormulaFromScratch.atom';
import { FormulaErrorInterface } from '@/hybrid/formula/FormulaEditor.molecule';
import { FormulaEditorParam } from '@/hybrid/formula/FormulaParametersTable.molecule';
import { validateAssetGroupFormula } from '@/hybrid/formula/formula.utilities';
import { AssetGroupStore } from '@/hybrid/assetGroupEditor/assetGroup.store';
import { FormulasApi } from '@/sdk';
import { NotificationsService } from '@/services/notifications.service';

const calculationDetailModalBindings = bindingsDefinition({
  onClose: prop<() => void>(),
  columnName: prop<string>(),
  item: prop<AssetChild>(),
  testId: prop.optional<string>(),
  asset: prop<{ name: string, id: string, itemId: string }>(),
  sqAssetGroupActions: injected<AssetGroupActions>(),
  sqAssetGroupStore: injected<AssetGroupStore>(),
  sqFormulasApi: injected<FormulasApi>(),
  sqNotifications: injected<NotificationsService>()
});

export const CalculationDetailModal: SeeqComponent<typeof calculationDetailModalBindings> = ({
  onClose,
  item,
  asset,
  testId
}) => {
  const {
    sqAssetGroupActions,
    sqAssetGroupStore,
    sqFormulasApi,
    sqNotifications
  } = useInjectedBindings(calculationDetailModalBindings);

  const { t } = useTranslation();

  const [formula, setFormula] = useState(item.formula);
  const [formulaErrors, setFormulaErrors] = useState<FormulaErrorInterface[]>([]);
  const [parameters, setParameters] = useState<FormulaEditorParam[]>(_.map(item.parameters,
    ({ name, item }) => ({ name, identifier: name, item: { name: item.name, id: item.id, type: item.type } })));

  const removeItem = () => {
    sqAssetGroupActions.removeChildFromAsset(item, asset);
    onClose();
  };

  const doValidateFormula = () => {
    return validateAssetGroupFormula(
      sqFormulasApi,
      sqAssetGroupStore.assets,
      formula,
      parameters
    ).then(() => {
      setFormulaErrors([]);
      return true;
    }).catch((formulaErrors) => {
      setFormulaErrors(formulaErrors);
      return false;
    });
  };

  const updateCalculation = () => {
    doValidateFormula()
      .then((valid) => {
        if (valid) {
          sqAssetGroupActions.addCalculationToAsset({ asset, columnName: item.name, formula, parameters });
          onClose();
          sqNotifications.successTranslate('ASSET_GROUP_EDITOR.ADD_CALCULATION_SUCCESS');
        }
      });
  };

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid={testId} dialogClassName="min-width-900">
      <Modal.Header closeButton={true}>
        <h4>{`${asset.name} » ${item.name} ${t('ASSET_GROUP_EDITOR.ITEM_DETAILS')}`}</h4>
      </Modal.Header>
      <Modal.Body>
        <FormulaFromScratch
          existingParameters={parameters}
          item={item}
          validateFormula={doValidateFormula}
          formulaErrors={formulaErrors}
          setFormulaErrorsFn={setFormulaErrors}
          exposeParametersToParent={setParameters}
          exposeFormulaToParent={setFormula}
        />

        <div className="flexColumnContainer mt30">
          <div className="flexColumnContainer flexFill">
            <TextButton
              onClick={removeItem}
              label="ASSET_GROUP_EDITOR.REMOVE_MAPPING"
              size="sm"
              testId="removeAttributeBtn"
              extraClassNames="min-width 100 width-130 max-width-130 mr20"
              id="removeAttributeBtn"
            />
          </div>

          <TextButton
            onClick={onClose}
            label="CLOSE"
            size="sm"
            testId="saveBtn"
            extraClassNames="min-width 100 width-100 max-width-100 mr20"
            id="closeBtn"
          />

          <TextButton
            onClick={updateCalculation}
            disabled={!_.isEmpty(formulaErrors)}
            label="SAVE"
            size="sm"
            testId="saveBtn"
            extraClassNames="min-width 100 width-100 max-width-100"
            id="saveBtn"
            variant="theme" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

