import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { Modal } from 'react-bootstrap';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { AssetChild, AssetGroupActions } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { ItemsApi } from '@/sdk';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { TrackService } from '@/track/track.service';
import { getDisplayPath } from '@/hybrid/assetGroupEditor/assetGroup.utilities';

const assetDetailModalBindings = bindingsDefinition({
  onClose: prop<() => void>(),
  swapItem: prop<() => void>(),
  item: prop<AssetChild>(),
  asset: prop<{ name: string, id: string, itemId: string }>(),
  testId: prop.optional<string>(),
  sqAssetGroupActions: injected<AssetGroupActions>(),
  sqItemsApi: injected<ItemsApi>(),
  sqTrack: injected<TrackService>()
});

export const AssetDetailModal: SeeqComponent<typeof assetDetailModalBindings> = (props) => {
  const {
    sqAssetGroupActions,
    sqItemsApi,
    sqTrack
  } = useInjectedBindings(assetDetailModalBindings);

  const { onClose, item, swapItem, asset, testId } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [originalMapping, setOriginalMapping] = useState(undefined);
  const [currentMapping, setCurrentMapping] = useState(undefined);

  const removeItem = () => {
    sqAssetGroupActions.removeChildFromAsset(item, asset);
    onClose();
  };

  useEffect(() => {
    setLoading(true);
    if (item?.itemId) { // saved item that was updated or never before saved item
      sqItemsApi.getFormulaDependencies({ id: item.itemId })
        .then(response => setCurrentMapping(response.data));
    } else if (item?.id) { // saved item without change
      sqItemsApi.getFormulaDependencies({ id: item.id })
        .then((response) => {
          const { dependencies } = response.data;
          const currentlyAssignedItem = _.head(dependencies);
          setCurrentMapping(currentlyAssignedItem);
        });
    }
  }, [item]);

  // once we have the "current item" we can use that to determine if there was an "original item".
  useEffect(() => {
    if (currentMapping && item?.originalMapping && currentMapping?.id !== item.originalMapping) {
      sqItemsApi.getFormulaDependencies({ id: item.originalMapping })
        .then(response => setOriginalMapping(response.data))
        .finally(() => setLoading(false));
    } else if (!_.isEmpty(currentMapping)) {
      setLoading(false);
    }
  }, [currentMapping]);

  const restoreOriginalMapping = () => {
    sqTrack.doTrack('Asset Group Editor', 'Details Modal', 'Original Mapping Restored');
    sqAssetGroupActions.restoreItem({ asset, columnName: item.name, itemId: originalMapping.id });
    onClose();
  };

  const renderDetails = (
    <div className="flexRowContainer">
      <div className="flexColumnContainer flexAlignCenter">
        <div data-testid="attributeName" className="text-bold font-size-larger mr15">{currentMapping?.name}</div>
        <HoverTooltip text="EDIT">
          <div>
            <Icon
              icon="fa-edit"
              type="theme"
              testId="editAssetMapping"
              extraClassNames="cursorPointer"
              onClick={swapItem}
            />
          </div>
        </HoverTooltip>
      </div>
      <span>{getDisplayPath(currentMapping)}</span>
      {item.manuallyAdded && <span className="mt5"><i>{t('ASSET_GROUP_EDITOR.MANUALLY_ASSIGNED')}</i></span>}
      {!_.isEmpty(originalMapping) && <>
        <hr className="mt30 mb15 width-maximum" />
        <div>
          {t('ASSET_GROUP_EDITOR.ORIGINAL_MAPPING')}&nbsp;
          <b>{`${originalMapping?.name} - ${getDisplayPath(originalMapping)}`}</b>
          <a href="#" className="font-size-smaller nowrap" onClick={restoreOriginalMapping}>
            <Icon
              icon="fa-reply"
              type="theme"
              extraClassNames="ml15 mr5 cursorPointer"
              onClick={restoreOriginalMapping}
            />
            {t('ASSET_GROUP_EDITOR.RESTORE')}
          </a>
        </div>
      </>}
    </div>
  );

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid={testId}>
      <Modal.Header closeButton={true}>
        <h4>{`${asset.name} » ${item.name} ${t('ASSET_GROUP_EDITOR.ITEM_DETAILS')}`}</h4>
      </Modal.Header>
      <Modal.Body>
        {!loading && renderDetails}
        {loading && <div className="flexRowContainer flexAlignCenter mb30 mt40">
          <IconWithSpinner spinning={true} large={true} />
        </div>}

        <div className="flexColumnContainer mt30">
          <div className="flexColumnContainer flexFill">
            <TextButton
              onClick={removeItem}
              label="ASSET_GROUP_EDITOR.REMOVE_MAPPING"
              size="sm"
              testId="removeAttributeBtn"
              disabled={loading}
              extraClassNames="min-width 100 width-130 max-width-130 mr20"
              id="removeAttributeBtn"
            />
          </div>

          <TextButton
            onClick={onClose}
            label="CLOSE"
            size="sm"
            testId="closeBtn"
            extraClassNames="min-width 100 width-100 max-width-100"
            id="closeBtn"
            variant="theme" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

