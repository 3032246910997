import _ from 'lodash';
import angular from 'angular';
import { TrendStore } from '@/trendData/trend.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { TrendScalarStore } from '@/trendData/trendScalar.store';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { ITEM_CHILDREN_TYPES, ITEM_TYPES, PREVIEW_ID, TREND_STORES, TREND_VIEWS } from '@/trendData/trendData.module';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { TrendTableStore } from '@/trendData/trendTable.store';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { WorksheetStore } from "@/worksheet/worksheet.store";

angular.module('Sq.TrendData').factory('sqTrendChartItemsHelper', sqTrendChartItemsHelper);

export type TrendChartItemsHelperService = ReturnType<typeof sqTrendChartItemsHelper>;

function sqTrendChartItemsHelper(
  sqTrendStore: TrendStore,
  sqTrendSeriesStore: TrendSeriesStore,
  sqTrendScalarStore: TrendScalarStore,
  sqTrendCapsuleSetStore: TrendCapsuleSetStore,
  sqTrendCapsuleStore: TrendCapsuleStore,
  sqTrendTableStore: TrendTableStore,
  sqTrendDataHelper: TrendDataHelperService,
  sqWorksheetStore: WorksheetStore
) {

  const service = {
    baseChartItems,
    chartItems,
    isHidden,
    groupedNonCapsuleSeries
  };

  return service;

  /**
   * All the items that could be included depending on the view
   */
  function baseChartItems() {
    const commonItems = [
      // CapsuleSet items aren't really 'chartItems' since the capsule store provides the capsule items for the chart
      // However, `chartItems` isn't populated until late in the load of the page, so we test if a condition is
      // displayed accurately early in the loading of the page. They are filtered out before being returned by
      // chartItems()
      sqTrendCapsuleSetStore.items
    ];
    if (sqTrendStore.view === TREND_VIEWS.CAPSULE) {
      return _.flatten([
        sqTrendSeriesStore.capsuleSeries,
        _.filter(sqTrendScalarStore.items, item => !item.isChildOf),
        ...commonItems
      ]);
    } else {
      return _.flatten([
        sqTrendTableStore.tables,
        groupedNonCapsuleSeries(sqTrendSeriesStore.nonCapsuleSeriesAndPreview),
        getSqTrendCapsuleStoreChartItems(),
        sqTrendScalarStore.items,
        ...commonItems
      ]);
    }
  }

  /**
   * Helper function that helps hide not selected capsules from trend when we are in Chain View
   */
  function getSqTrendCapsuleStoreChartItems() {
    return sqTrendStore.view === TREND_VIEWS.CHAIN && sqWorksheetStore.capsuleGroupMode
      && _.some(sqTrendCapsuleStore.chartItems, item => item.selected) ?
      _.filter(sqTrendCapsuleStore.chartItems, item => item.selected) : sqTrendCapsuleStore.chartItems;
  }

  /**
   * Helper function to filter sqTrendSeriesStore.nonCapsuleSeries in case of grouping mode.
   *
   * @param {Array} nonCapsuleSeries - signals that should be filtered if any grouping
   */
  function groupedNonCapsuleSeries(nonCapsuleSeries) {
    if (sqTrendStore.view === TREND_VIEWS.CHAIN && sqWorksheetStore.capsuleGroupMode) {
      const selectedCapsules = _.filter(sqTrendCapsuleStore.items, 'selected');
      const conditionsIds = _.chain(selectedCapsules.length ? selectedCapsules : sqTrendCapsuleStore.items)
        .map('isChildOf')
        .uniq()
        .value();
      const seriesIds = _.chain(conditionsIds)
        .flatMap(conditionId => sqWorksheetStore.conditionToSeriesGrouping[conditionId])
        .compact()
        .uniq()
        .value();
      // in order to display shadedArea signals we are using item['isChildOf'] for filtering nonCapsuleSeries
      return _.filter(nonCapsuleSeries,
          item => _.includes(seriesIds, item['id']) || _.includes(seriesIds, item['isChildOf']));
    }
    return nonCapsuleSeries;
  }

  /**
   * @returns the list of items that will be shown on the chart
   */
  function chartItems() {
    let chartItems = baseChartItems();

    if (sqTrendStore.hideUnselectedItems && _.some(chartItems, item => shownAsSelected(item))) {
      chartItems = _.chain(chartItems)
        .filter(item => shownInHideUnselectedItemsMode(item))
        .value();
    }

    // Conditions are filtered out here because they aren't used to display capsules (see note in baseChartItems)
    return _.reject(chartItems, ['itemType', ITEM_TYPES.CAPSULE_SET]);
  }

  /**
   * Test if an item that would be shown on the trend is currently hidden because of `hideUnselectedItems` mode
   */
  function isHidden(item) {
    if (!sqTrendStore.hideUnselectedItems) {
      return false;
    }
    const baseChartItems = service.baseChartItems();

    return sqTrendStore.hideUnselectedItems
      // If it isn't possible to be part of chartItems return false
      && _.some(baseChartItems, ['id', item.id])
      // Items are only hidden if at least one of them is selected
      && _.some(baseChartItems, chartItem => shownAsSelected(chartItem))
      // Finally, check if this item is shown
      && !shownInHideUnselectedItemsMode(item);
  }

  /**
   * Helper function that determines if an item should be shown in hideUnselectedItems mode. Preview items are always
   * shown in the mode, but they do not cause items to be filtered
   */
  function shownInHideUnselectedItemsMode(item) {
    return item.id === PREVIEW_ID
      || item.capsuleSetId === PREVIEW_ID
      || shownAsSelected(item)
      || noneOfTypeSelected(item)
      || _.startsWith(item.id, PREVIEW_ID)
      || (sqTrendCapsuleStore.editingId && item.capsuleSetId === sqTrendCapsuleStore.editingId);
  }

  /**
   * Helper function for determining if an item should trigger hiding items in hideUnselectedItems mode
   */
  function shownAsSelected(item) {
    // Capsule series draw their selection from capsules but in this mode we want to hide them if their parent is
    // selected in the details pane. Scalars will still have "selected" set to true if they are selected.
    if (_.get(item, 'childType') === ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE) {
      const parent = sqTrendDataHelper.findItemIn(TREND_STORES, item.isChildOf);
      return _.get(parent, 'selected', false);
    } else {
      return _.get(item, 'selected', false);
    }
  }

  /**
   * Helper function for determining if an item should display in capsule time in hideUnselectedItems mode, where if
   * no items of a given type(conditions vs. all other types) are selected all should be displayed, as in calendar time,
   * non-dimming.  Without this, it can be confusing to the user why nothing is displayed in the trend when
   * conditions are selected since capsules aren't chart items in capsule view.
   */
  function noneOfTypeSelected(item) {
    const selectedConditions = _.some(service.baseChartItems(), { itemType: ITEM_TYPES.CAPSULE_SET, selected: true });
    const selectedOthers = _.chain(sqTrendSeriesStore.nonCapsuleSeriesAndPreview)
      .concat(sqTrendScalarStore.items)
      .some({ selected: true })
      .value();

    if (sqTrendStore.view === TREND_VIEWS.CAPSULE) {
      return item.itemType === ITEM_TYPES.CAPSULE_SET ? !selectedConditions : !selectedOthers;
    } else {
      return false;
    }
  }
}
