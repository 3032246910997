import angular from 'angular';
import { ErrorTypeEnum } from 'sdk/model/FormulaErrorOutputV1';
import bind from 'class-autobind-decorator';

@bind
export class MaxCapsuleDurationRemovedCtrl {
  public item: any = this['item']; // The item being edited or created

  constructor() {
  }

  /**
   * Helper function that returns true if a "MAXDURATIONPROHIBITED" error occurred.
   *
   * @return {boolean} - true if errorType is MAXDURATIONPROHIBITED, false otherwise
   */
  showMessage() {
    return this.item.errorType === ErrorTypeEnum.MAXDURATIONPROHIBITED;
  }
}

angular.module('Sq.TrendViewer')
  .controller('MaxCapsuleDurationRemovedCtrl', MaxCapsuleDurationRemovedCtrl);
