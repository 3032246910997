import _ from 'lodash';
import { BaseToolStoreService } from '@/investigate/baseToolStore.service';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { DIGITAL_FILTER_CUTOFF } from '@/hybrid/tools/digitalFilter/digitalFilter.module';

export type DigitalFilterStore = ReturnType<typeof sqDigitalFilterStore>['exports'];

export function sqDigitalFilterStore(
  sqBaseToolStore: BaseToolStoreService) {
  const store = {
    initialize() {
      this.state = this.immutable(_.assign({}, sqBaseToolStore.COMMON_PROPS, {
        filterType: undefined,
        cutoff: DIGITAL_FILTER_CUTOFF,
        cutoff2: DIGITAL_FILTER_CUTOFF,
        samplingRate: DIGITAL_FILTER_CUTOFF,
        windowSize: DIGITAL_FILTER_CUTOFF,
        isAutoSamplingRate: true,
        isAutoWindowSize: true
      }));
    },

    exports: {
      get filterType() {
        return this.state.get('filterType');
      },

      get cutoff() {
        return this.state.get('cutoff');
      },

      get cutoff2() {
        return this.state.get('cutoff2');
      },

      get samplingRate() {
        return this.state.get('samplingRate');
      },

      get windowSize() {
        return this.state.get('windowSize');
      },

      get isAutoSamplingRate() {
        return this.state.get('isAutoSamplingRate');
      },

      get isAutoWindowSize() {
        return this.state.get('isAutoWindowSize');
      }
    },

    dehydrate() {
      return this.state.serialize();
    },

    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      DIGITAL_FILTER_SET_FILTER_TYPE: 'setFilterType',
      DIGITAL_FILTER_SET_CUTOFF: 'setCutoff',
      DIGITAL_FILTER_SET_CUTOFF_2: 'setCutoff2',
      DIGITAL_FILTER_SET_SAMPLING_RATE: 'setSamplingRate',
      DIGITAL_FILTER_SET_WINDOW_SIZE: 'setWindowSize',
      DIGITAL_FILTER_SET_IS_AUTO_SAMPLING_RATE: 'setIsAutoSamplingRate',
      DIGITAL_FILTER_SET_IS_AUTO_WINDOW_SIZE: 'setIsAutoWindowSize'
    },

    setFilterType(payload) {
      this.state.set('filterType', payload.filterType);
    },

    setCutoff(payload) {
      this.state.set('cutoff', payload.cutoff);
    },

    setCutoff2(payload) {
      this.state.set('cutoff2', payload.cutoff2);
    },

    setSamplingRate(payload) {
      this.state.set('samplingRate', payload.samplingRate);
    },

    setWindowSize(payload) {
      this.state.set('windowSize', payload.windowSize);
    },

    setIsAutoSamplingRate(payload) {
      this.state.set('isAutoSamplingRate', payload.isAutoSamplingRate);
    },

    setIsAutoWindowSize(payload) {
      this.state.set('isAutoWindowSize', payload.isAutoWindowSize);
    }
  };

  return sqBaseToolStore.extend(store, TREND_TOOLS.DIGITAL_FILTER,
    { inputSignal: { predicate: ['name', 'inputSignal'] } });
}
