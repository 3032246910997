import _ from 'lodash';
import angular from 'angular';
import jQuery from 'jquery';
import { DomClassListService } from '@/services/domClasslist.service';
import { TrendActions } from '@/trendData/trend.actions';
import { TrendStore } from '@/trendData/trend.store';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { UtilitiesService } from '@/services/utilities.service';
import { DEBOUNCE } from '@/main/app.constants';
import { TREND_PANELS } from '@/trendData/trendData.module';

angular.module('Sq.TrendViewer').controller('BottomPanelsCtrl', BottomPanelsCtrl);

function BottomPanelsCtrl(
  $scope: ng.IScope,
  $timeout: ng.ITimeoutService,
  $document: ng.IDocumentService,
  sqDomClassList: DomClassListService,
  sqTrendActions: TrendActions,
  sqTrendStore: TrendStore,
  sqTrendCapsuleSetStore: TrendCapsuleSetStore,
  sqUtilities: UtilitiesService
) {
  const vm = this;
  let initializing = true;
  vm.resizePanelStart = resizePanelStart;
  vm.resizeDividerStart = resizeDividerStart;
  vm.panelHeight = panelHeight;
  vm.debouncedConstrainLeftPanelWidth = _.debounce(function() {
    constrainLeftPanelWidth(vm.bottomPanelProps.leftPanelWidth);
  }, DEBOUNCE.SHORT);

  $scope.$listenTo(sqTrendStore, syncWithTrendStore);
  $scope.$listenTo(sqTrendCapsuleSetStore, syncWithTrendCapsuleSetStore);

  // Defer until after first render to ensure bottom panel is fully displayed
  $timeout(adjustDropdownMenus);
  $timeout(_.partial(constrainLeftPanelWidth, vm.bottomPanelProps.leftPanelWidth));
  initializing = false;

  function syncWithTrendStore(e) {
    if (!_.isEmpty(e) && sqUtilities.propertyChanged(e, ['xValue', 'pointValues'])) {
      return;
    }

    vm.showChartConfiguration = sqTrendStore.showChartConfiguration;
    vm.selectedRegion = sqTrendStore.selectedRegion;
    vm.bottomPanelProps = _.cloneDeep(sqTrendStore.getPanelProps(TREND_PANELS.BOTTOM));

    // Convert the height percentage to a number or default to 30%
    vm.targetHeight = vm.bottomPanelProps.height ? Number(_.replace(vm.bottomPanelProps.height, '%', '')) : 30;

    calcShowCapsulesPanel();

    if (!initializing) {
      constrainLeftPanelWidth(vm.bottomPanelProps.leftPanelWidth);
    }
  }

  function syncWithTrendCapsuleSetStore() {
    vm.capsulesExist = sqTrendCapsuleSetStore.items.length > 0;

    calcShowCapsulesPanel();

    if (!initializing) {
      constrainLeftPanelWidth(vm.bottomPanelProps.leftPanelWidth);
    }
  }

  /**
   * Calculate the height of the panels from percentages
   *
   * @return {Number} - height in pixels
   */
  function panelHeight() {
    return (Math.max(vm.targetHeight, minPanelHeight()) / 100) * vm.parentHeight;
  }

  /**
   * Determine if the capsule panel should be shown, store the result in vm.showCapsules
   */
  function calcShowCapsulesPanel() {
    vm.showCapsulesPanel = vm.capsulesExist && !vm.hideCapsulesPanel;
  }

  /**
   * Handles resizing the bottom panel when the user drags the header. Should be invoked by using ng-mousedown on the
   * element that will allow for dragging.
   * @param {Object} e - The event
   */
  function resizePanelStart(e) {
    const throttledResizePanel = _.throttle(resizePanel, 50);
    let startY = e.pageY;

    sqUtilities.prepareDragResize(e, throttledResizePanel);

    /**
     * Figure out how much the mouse has moved and then use that to calculate the new height of the bottom panel. That
     * height is then converted to a percentage height rounded to the nearest 10th. That level of rounding allows the
     * size to change smoothly while also letting us know when the mouse has moved far enough that the startY needs to
     * be updated. To prevent the draggable control from being hidden we don't allow the percentage to drop below the
     * height of the drag bar.
     *
     * @param {Object} e - The event
     */
    function resizePanel(e) {
      const yDelta = e.pageY - startY;
      const bottomPanelHeight = angular.element('.bottomPanel').height();
      const currentPercent = getPercentHeight(bottomPanelHeight, vm.parentHeight);
      let newPercent = getPercentHeight(bottomPanelHeight - yDelta, vm.parentHeight);
      if (newPercent < minPanelHeight()) {
        newPercent = 0;
      }

      if (newPercent !== currentPercent) {
        startY = e.pageY;
      }

      $scope.$evalAsync(function() {
        vm.bottomPanelProps.height = newPercent + '%';
        sqTrendActions.setPanelProps(TREND_PANELS.BOTTOM, vm.bottomPanelProps);
      });

      adjustDropdownMenus();
    }
  }

  /**
   * Helper function that keeps dropdown menus usable by making them scroll instead of displaying off screen.
   */
  function adjustDropdownMenus() {
    let capsuleSignalMenus, tableScrollOffset;
    const PANEL_HEADER_OFFSET = 69;
    const TABLE_HEADER_OFFSET = 25;
    const bottomPanelHeight = angular.element('.bottomPanel').height();

    // Adjust the capsule table signal dropdown menus
    capsuleSignalMenus = angular.element('.bottomPanel .tableWrapper .scrollable-menu');

    if (capsuleSignalMenus.length) {
      tableScrollOffset = _.get(angular.element('#capsulesPanel').find('.tableWrapper'), '0.scrollTop', 0);
      capsuleSignalMenus.css('top', getMenuTop(TABLE_HEADER_OFFSET - tableScrollOffset));
      capsuleSignalMenus.css('max-height', bottomPanelHeight + tableScrollOffset - TABLE_HEADER_OFFSET);
    }

    function getMenuTop(additionalOffset) {
      // Constrain offset so regardless of additional offset, it is never above the panel title bar
      const offset = Math.max(PANEL_HEADER_OFFSET, PANEL_HEADER_OFFSET + additionalOffset);
      return angular.element('.bottomPanel').position().top + offset + 'px';
    }
  }

  /**
   * Handles resizing the bottom panel divider when the user drags the divider.
   * Should be invoked by using ng-mousedown on the element that will allow for dragging.
   *
   * @param {Object} e - The event
   */
  function resizeDividerStart(e) {
    const throttledResizeDivider = _.throttle(resizeDivider, 50);
    const startX = e.pageX;
    const startPanelWidth = vm.bottomPanelProps.leftPanelWidth;

    sqUtilities.prepareDragResize(e, throttledResizeDivider,
      // Force ew-resize cursor during resize
      () => sqDomClassList.classList(jQuery('body')[0]).add('globalCursorEastWest'),
      // Clear ew-resize cursor
      () => sqDomClassList.classList(jQuery('body')[0]).remove('globalCursorEastWest')
    );

    /**
     * Figure out how much the mouse has moved and then use that to calculate the new divider setting.
     *
     * @param {Object} e - The event
     */
    function resizeDivider(e) {
      const xDelta = e.pageX - startX;
      $scope.$evalAsync(function() {
        constrainLeftPanelWidth(startPanelWidth + xDelta);
        sqTrendActions.setPanelProps(TREND_PANELS.BOTTOM, vm.bottomPanelProps);
      });
    }
  }

  /**
   * Sets the left panel display width to be the supplied value, or constrains it to be within the available space
   * if the bottom panel width does not allow enough horizontal space for the supplied width to be set.
   *
   * @param {Number} leftPanelWidth - the desired left panel width
   */
  function constrainLeftPanelWidth(leftPanelWidth) {
    let bottomPanelWidth, minPanelWidth;
    bottomPanelWidth = angular.element('.bottomPanel').width();
    minPanelWidth = (bottomPanelWidth > 650) ? 325 : 250; // Allow smaller width to support 1024x768 resolution

    if (!vm.showCapsulesPanel) {
      vm.bottomPanelProps.leftPanelWidth = bottomPanelWidth;
    } else if (leftPanelWidth >= minPanelWidth && leftPanelWidth <= bottomPanelWidth - minPanelWidth) {
      vm.bottomPanelProps.leftPanelWidth = leftPanelWidth;
    } else if (leftPanelWidth < minPanelWidth || bottomPanelWidth < minPanelWidth * 2) {
      vm.bottomPanelProps.leftPanelWidth = minPanelWidth;
    } else {
      vm.bottomPanelProps.leftPanelWidth = bottomPanelWidth - minPanelWidth;
    }
  }

  /**
   * Computes the height of a child element as a percentage of the container height. Rounds to the nearest tenth.
   *
   * @param {Number} childHeight - Height of child in pixels
   * @param {Number} containerHeight - Height of child's container in pixels
   */
  function getPercentHeight(childHeight, containerHeight) {
    return Math.round(((childHeight / containerHeight) * 100) * 10) / 10;
  }

  /**
   * Calculate the minimum height in a percentage that the view can be set to where just the headers of the panels
   * are visible to create the appearance that the panels are pinned to the bottom.
   *
   * @return {Number} - minimum percentage of the parentHeight
   */
  function minPanelHeight() {
    const DRAG_BAR_PADDING = 2;
    // Assuming that the series panel is always shown we can use it to measure the header
    const dragBarHeight = angular.element('sq-series-panel .header').outerHeight() + DRAG_BAR_PADDING;
    const dragBarHeightPercent = getPercentHeight(dragBarHeight, vm.parentHeight);
    return dragBarHeightPercent;
  }
}
