import React from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { CapsuleTimeColorMode, TrendStore } from '@/trendData/trend.store';
import {
  CAPSULE_PANEL_TREND_COLUMNS, PropertyColumn,
  TREND_PANELS,
  TREND_SIGNAL_STATS,
  TREND_VIEWS
} from '@/trendData/trendData.module';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import _ from 'lodash';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { RainbowLegendItems } from '@/hybrid/trend/RainbowLegendItems.molecule';
import { SignalGradientLegendItems } from '@/hybrid/trend/SignalGradientLegendItems.molecule';
import { ConditionGradientLegendItems } from '@/hybrid/trend/ConditionGradientLegendItems.molecule';
import { ItemDecoratorService } from '@/trendViewer/itemDecorator.service';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { ColumnHelperService } from '@/trendViewer/columnHelper.service';

const capsuleTimeLegendBindings = bindingsDefinition({
  sqTrendStore: injected<TrendStore>(),
  sqTrendSeriesStore: injected<TrendSeriesStore>(),
  sqTrendCapsuleStore: injected<TrendCapsuleStore>(),
  sqItemDecorator: injected<ItemDecoratorService>(),
  sqColumnHelper: injected<ColumnHelperService>()
});

export const CapsuleTimeLegend: SeeqComponent<typeof capsuleTimeLegendBindings> = () => {
  const {
    sqTrendStore,
    sqTrendSeriesStore,
    sqTrendCapsuleStore,
    sqItemDecorator,
    sqColumnHelper
  } = useInjectedBindings(capsuleTimeLegendBindings);
  const { t } = useTranslation();
  const view = useFluxPath(sqTrendStore, () => sqTrendStore.view);
  const capsuleTimeColorMode = useFluxPath(sqTrendStore, () => sqTrendStore.capsuleTimeColorMode);
  const sortBy = useFluxPath(sqTrendStore, () => sqTrendStore.getPanelSort(TREND_PANELS.CAPSULES).sortBy);
  const propertyColumns = useFluxPath(sqTrendStore, () => _.map(sqTrendStore.propertyColumns(TREND_PANELS.CAPSULES),
    column => ({ ...column, style: 'string', title: column.propertyName })));
  const customColumns = useFluxPath(sqTrendStore, () => _.map(sqTrendStore.customColumns(TREND_PANELS.CAPSULES),
    column => _.assign({ series: sqTrendSeriesStore.findItem(column.referenceSeries) },
      _.find(TREND_SIGNAL_STATS, ['key', column.statisticKey]), column)));
  const { items: capsules } = useFlux(sqTrendCapsuleStore);

  const isDisplayed = view === TREND_VIEWS.CAPSULE && capsuleTimeColorMode !== CapsuleTimeColorMode.Signal;
  const allColumns = CAPSULE_PANEL_TREND_COLUMNS.concat(propertyColumns).concat(customColumns);
  const sortedColumn = _.find(allColumns, { key: sortBy }) || {} as PropertyColumn;
  const title = _.compact([_.get(sortedColumn, 'series.name'), t(sortedColumn.title)]).join(' ');
  const getCapsuleName = capsule => sqColumnHelper.getColumnValue(sortedColumn, capsule);

  const renderLegendItems = () => {
    const filteredCapsules = sqItemDecorator.decorate(_.reject(capsules, 'notFullyVisible')) as any[];
    switch (capsuleTimeColorMode) {
      case CapsuleTimeColorMode.Rainbow:
        return <RainbowLegendItems capsules={filteredCapsules} getCapsuleName={getCapsuleName} title={title} />;
      case CapsuleTimeColorMode.SignalGradient:
        return <SignalGradientLegendItems capsules={filteredCapsules} getCapsuleName={getCapsuleName} title={title} />;
      case CapsuleTimeColorMode.ConditionGradient:
        return <ConditionGradientLegendItems capsules={filteredCapsules} />;
    }
  };

  return isDisplayed && renderLegendItems();
};

export const sqCapsuleTimeLegend = angularComponent(capsuleTimeLegendBindings, CapsuleTimeLegend);
