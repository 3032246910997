import React, { useState } from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import classNames from 'classnames';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import ValueWithUnits from '@/hybrid/trend/ValueWithUnits.atom';
import { getFormFieldProps, getValidationFunction, canUseMemo } from '@/hybrid/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { FormError } from '@/hybrid/core/FormError.atom';
import { HelpIcon } from '@/hybrid/core/Icon.atom';

export interface MaxCapsuleDurationIF extends ValidatingFormComponent<{ value: string, units: string }> {
  component: 'MaxCapsuleDurationFormComponent';
  minIsExclusive?: boolean;
  required?: boolean;
  insideModal?: boolean;
  icon?: string;
  label?: string;
  maxDurationRequired: boolean;
}

const MaxCapsuleDurationFormComponentUnwrapped: React.FunctionComponent<MaxCapsuleDurationIF> = (props) => {
  const { t } = useTranslation();
  const {
    label = 'MAXIMUM_CAPSULE_DURATION',
    tooltip = 'MAXIMUM_CAPSULE_DURATION_OVERRIDE_TOOLTIP',
    value,
    name,
    validation,
    required = true,
    testId,
    minIsExclusive = false,
    extendValidation,
    maxDurationRequired,
    extraClassNames
  } = props;

  const [showError, setShowError] = useState(false);
  const min = 0;

  const defaultValidation = (valueWithUnits) => {
    const { units, value } = valueWithUnits || {};
    if (required || !_.isNil(units)) {
      if (!_.isNil(min)) {
        return !(minIsExclusive ? value > min : value >= min);
      } else {
        return false;
      }
    }
    return false;
  };

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();

  return (
    <div className={classNames('flexRowContainer', extraClassNames)} data-testid={testId}>
      <div className="flexColumnContainer">
        <span>{t(label)}</span>
        {tooltip && <HelpIcon tooltip={t(tooltip)} extraClassNames="pl5" />}
      </div>
      <div className="flexColumnContainer flexAlignCenter"
        id={`${testId}_maxCapsuleDuration`}>
        <Field name={name} validate={appliedValidation}>
          {({ input, meta }) => {
            const properProps = getFormFieldProps(formState, input, meta, props);
            setTimeout(() => setShowError(properProps.showError), 0);

            return (
              <ValueWithUnits
                {...properProps}
                min={min}
                required={maxDurationRequired}
                isValid={!showError}
                defaultValue={value} />
            );
          }}
        </Field>

      </div>
      {showError && maxDurationRequired &&
      <FormError errorText="MAXIMUM_DURATION_REQUIRED_DEFAULT_ADDED" extraClassNames="mtn5" />}
    </div>
  );
};

export const MaxCapsuleDurationFormComponent = React.memo(MaxCapsuleDurationFormComponentUnwrapped, canUseMemo);
