import React from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { Modal } from 'react-bootstrap';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { TrackService } from '@/track/track.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';

const unsavedChangesModalBindings = bindingsDefinition({
  onCancel: prop<() => void>(),
  onDiscard: prop<() => void>(),
  sqTrack: injected<TrackService>()
});

export const UnsavedChangesModal: SeeqComponent<typeof unsavedChangesModalBindings> = (props) => {
  const { onCancel, onDiscard } = props;
  const { sqTrack } = useInjectedBindings(unsavedChangesModalBindings);
  const { t } = useTranslation();

  return <Modal animation={false} backdrop="static" show={true}>
    <Modal.Header>
      <Modal.Title>
        {t('ITEM_ACL.UNSAVED_CHANGES.HEADER')}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {t('ITEM_ACL.UNSAVED_CHANGES.MESSAGE')}
    </Modal.Body>
    <Modal.Footer>
      <TextButton
        label="ITEM_ACL.UNSAVED_CHANGES.DISCARD"
        onClick={() => {
          sqTrack.doTrack('Access Control', 'confirmation dialog', 'discard');
          onDiscard();
        }}
      />
      <TextButton
        label="CANCEL"
        variant="theme"
        onClick={() => {
          sqTrack.doTrack('Access Control', 'confirmation dialog', 'cancel');
          onCancel();
        }}
      />
    </Modal.Footer>
  </Modal>;
};
