import _ from 'lodash';
import angular from 'angular';
import { DATASOURCES_TAB_INDEX } from '@/administration/administration.module';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';

/**
 * The administration store handles the known users.
 */
angular.module('Sq.Administration').store('sqAdministrationStore', sqAdministrationStore);

export type AdministrationStore = ReturnType<typeof sqAdministrationStore>['exports'];

function sqAdministrationStore() {
  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.NONE,

    initialize() {
      this.state = this.immutable({
        activeTabIndex: DATASOURCES_TAB_INDEX,
        users: [],
        userCount: 0,
        pageSize: 20,
        pageNumber: 1,
        groups: [],
        adminContactInfo: {
          name: '',
          email: ''
        },
        searchParams: {},
        sortAscending: true,
        sortProperty: 'username',
        userTableLoading: false
      });
    },

    exports: {
      get activeTabIndex() {
        return this.state.get('activeTabIndex');
      },

      get users() {
        return this.state.get('users');
      },

      get groups() {
        return this.state.get('groups');
      },

      get adminContactInfo() {
        return this.state.get('adminContactInfo');
      },

      getItemTotalForTable() {
        return this.state.get('userCount');
      },

      getPageSizeByTable() {
        return this.state.get('pageSize');
      },

      getPageNumberForTable() {
        return this.state.get('pageNumber');
      },

      get searchParams() {
        return this.state.get('searchParams');
      },

      get sortAscending() {
        return this.state.get('sortAscending');
      },

      get sortProperty() {
        return this.state.get('sortProperty');
      },

      get userTableLoading() {
        return this.state.get('userTableLoading');
      },

      get tooManyRequests() {
        return this.state.get('tooManyRequests');
      }
    },

    handlers: {
      ADMINISTRATION_SET_ACTIVE_TAB_INDEX: 'setActiveTabIndex',
      ADMINISTRATION_SET_USERS: 'setUsers',
      ADMINISTRATION_SET_USER_TABLE_LOADING: 'setUserTableLoading',
      ADMINISTRATION_SET_TOTAL_USER_COUNT: 'setUserCount',
      ADMINISTRATION_SET_SEARCH_PARAMS: 'setSearchParams',
      ADMINISTRATION_RESET_SEARCH_PARAMS: 'resetSearchParams',
      ADMINISTRATION_SET_SORT: 'setSort',
      ADMINISTRATION_SET_PAGE_SIZE: 'setPageSize',
      ADMINISTRATION_SET_PAGE_NUMBER: 'setPageNumber',
      ADMINISTRATION_ADD_USER: 'addUser',
      ADMINISTRATION_UPDATE_USER: 'updateUser',
      ADMINISTRATION_ENABLE_USERS: 'enableUsers',
      ADMINISTRATION_REMOVE_USERS: 'removeUsers',
      ADMINISTRATION_CLEAR_GROUPS: 'clearGroups',
      ADMINISTRATION_ADD_GROUP: 'addGroup',
      ADMINISTRATION_UPDATE_GROUP: 'updateGroup',
      ADMINISTRATION_REMOVE_GROUPS: 'removeGroups',
      ADMINISTRATION_UPDATE_CONTACT_INFO: 'updateAdminContactInfo',
      ADMINISTRATION_SET_TOO_MANY_REQUESTS: 'setTooManyRequests'
    },

    /**
     * Save boolean indicating if we have more than one
     * page of requests
     *
     * @param {Object} tooManyRequests - true if we have
     * more than one page of requests
     */
    setTooManyRequests(tooManyRequests) {
      this.state.set('tooManyRequests', tooManyRequests);
    },

    /**
     * Sets the active tab index
     *
     * @param {Object} tab - The new active tab
     */
    setActiveTabIndex(tab) {
      this.state.set('activeTabIndex', tab);
    },

    /**
     * Adds a user to the array of users
     *
     * @param {Object} user - The user to be added
     */
    addUser(user) {
      this.state.push('users', user);
    },

    setUsers(userList) {
      this.state.set('users', userList);
    },

    setUserCount({ userCount }) {
      this.state.set('userCount', userCount);
    },

    setPageSize({ size }) {
      this.state.set('pageSize', size);
    },

    setPageNumber({ pageNumber }) {
      this.state.set('pageNumber', pageNumber);
    },

    setUserTableLoading({ loading }) {
      this.state.set('userTableLoading', loading);
    },

    setSearchParams({ field, value }) {
      const searchParams = this.state.get('searchParams');

      if (value === '') {
        this.state.set('searchParams', _.omit(searchParams, field));
      } else {
        this.state.set('searchParams', _.assign({}, searchParams, { [field]: value }));
      }
    },

    resetSearchParams() {
      this.state.set('searchParams', {});
    },

    setSort({ field, sortAscending }) {
      this.state.merge({ sortProperty: field, sortAscending });
    },

    addGroup(group) {
      this.state.push('groups', group);
    },

    /**
     * Updates a users properties
     *
     * @param {Object} user - The user to be updated
     */
    updateUser(user) {
      const index = _.findIndex(this.state.get('users'), ['id', user.id]);
      if (index > -1) {
        this.state.merge(['users', index], user);
      }
    },

    /**
     * Updates properties for a group
     *
     * @param {Object} group - The group to be updated
     */
    updateGroup(group) {
      const index = _.findIndex(this.state.get('groups'), ['id', group.id]);
      if (index > -1) {
        this.state.merge(['groups', index], group);
      }
    },

    /**
     * Enables/disables users in the array of users
     *
     * @param {Object} payload - Object container for arguments
     * @param {Boolean} payload.enable - Flag for enabling/disabling
     * @param {String[]} payload.ids - The ids of the users to be disabled
     */
    enableUsers(payload) {
      _.forEach(payload.ids, function(id) {
        const index = _.findIndex(this.state.get('users'), ['id', id]);
        if (index > -1) {
          this.state.set(['users', index, 'isEnabled'], payload.enable);
        }
      }.bind(this));
    },

    /**
     * Removes users from the array of users
     *
     * @param {String[]} ids - The ids of the users to be removed
     */
    removeUsers(ids) {
      _.forEach(ids, function(id) {
        const index = _.findIndex(this.state.get('users'), ['id', id]);

        if (index > -1) {
          this.state.splice('users', [index, 1]);
        }
      }.bind(this));
    },

    /**
     * Removes groups from the array of groups
     *
     * @param {String[]} ids - The ids of the groups to be removed
     */
    removeGroups(ids) {
      _.forEach(ids, function(id) {
        const index = _.findIndex(this.state.get('groups'), ['id', id]);

        if (index >= 0) {
          this.state.splice('groups', [index, 1]);
        }
      }.bind(this));
    },

    /**
     * Clears the array of groups
     */
    clearGroups() {
      this.state.set('groups', []);
    },

    /**
     * Update the administrator's contact information in the store
     *
     * @param  {Object} adminContactInfo the contact information of the customer's Seeq administrator
     * @param  {String} adminContactInfo.name the name of the customer's Seeq administrator
     * @param  {String} adminContactInfo.email the email of the customer's Seeq administrator
     */
    updateAdminContactInfo(adminContactInfo) {
      this.state.set('adminContactInfo', adminContactInfo);
    }
  };

  return store;
}
