import { CompositeSearchStore } from '@/hybrid/tools/compositeSearch/compositeSearch.store';
import { ToolRunnerService } from '@/services/toolRunner.service';

export type CompositeSearchActions = ReturnType<typeof sqCompositeSearchActions>;

export function sqCompositeSearchActions(
  flux: ng.IFluxService,
  sqToolRunner: ToolRunnerService,
  sqCompositeSearchStore: CompositeSearchStore) {
  const service = {
    setOperator,
    run,
    setInclusiveA,
    setInclusiveB,
    setConditionAMaximumDurationOverride,
    setConditionBMaximumDurationOverride,
    swapConditions,
    unsetUpgradedUnionCondition
  };
  return service;

  /**
   * Sets the operator that defines how to combine the selected capsule sets.
   *
   * @param {String} operator - the operator to use for the search.
   */
  function setOperator(operator) {
    flux.dispatch('COMPOSITE_SEARCH_SET_OPERATOR', { operator, resetOutputMaximumDuration: true });
  }

  function setInclusiveA(inclusiveA) {
    flux.dispatch('COMPOSITE_SEARCH_INCLUSIVE_A', { inclusiveA });
  }

  function setInclusiveB(inclusiveB) {
    flux.dispatch('COMPOSITE_SEARCH_INCLUSIVE_B', { inclusiveB });
  }

  function setConditionAMaximumDurationOverride(maximumDuration) {
    flux.dispatch('COMPOSITE_SEARCH_SET_CONDITION_A_MAXIMUM_DURATION_OVERRIDE', { maximumDuration });
  }

  function setConditionBMaximumDurationOverride(maximumDuration) {
    flux.dispatch('COMPOSITE_SEARCH_SET_CONDITION_B_MAXIMUM_DURATION_OVERRIDE', { maximumDuration });
  }

  function swapConditions() {
    flux.dispatch('COMPOSITE_SEARCH_SWAP_CONDITIONS');
  }

  function unsetUpgradedUnionCondition() {
    flux.dispatch('COMPOSITE_SEARCH_UNSET_UPGRADED_UNION_CONDITION');
  }

  /**
   * Performs all necessary steps to execute a logic based capsule search
   *
   * @param {String} name - the name to be used for logic capsule set
   * @param {String} formula - the formula to generate a capsule set
   * @param {Object} parameters - Map of parameter name to ID that are the top-level parameters used in the formula
   * @param {String} color - the color to assign
   * @return {Promise} - a promise that resolves when the composite search completes.
   */
  function run(name, formula, parameters, color) {
    return sqToolRunner.panelExecuteCondition(
      name,
      formula,
      parameters,
      sqCompositeSearchStore.configParams,
      sqCompositeSearchStore.id,
      color
    );
  }
}
