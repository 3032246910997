import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { TrackService } from '@/track/track.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { Dropdown } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/DropdownItem';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import _ from 'lodash';
import { DateRangeIcon } from '@/hybrid/reportEditor/DateRangeIcon.molecule';

const reportContentDateRangeBindings = bindingsDefinition({
  dateRange: prop<any>(),
  dateRanges: prop<any[]>(),
  setDateRange: prop<(any) => void>(),
  sqTrack: injected<TrackService>()
});

export const ReportContentDateRange: SeeqComponent<typeof reportContentDateRangeBindings> = (props) => {
  const { sqTrack } = useInjectedBindings(reportContentDateRangeBindings);
  const {
    dateRanges,
    dateRange,
    setDateRange
  } = props;
  const { t } = useTranslation();
  const setDateRangeAndTrack = (dateRange?) => {
    sqTrack.doTrack('Topic', 'Update Content', 'date range changed');
    setDateRange(dateRange);
  };

  const dateRangeName = dateRange ? dateRange?.name : t('REPORT.CONFIG.INHERIT_FROM_WORKSHEET');
  const dateRangeOptions = _.concat([<DropdownItem href='#' onClick={() => setDateRangeAndTrack()} key='from-worksheet'>
    {t('REPORT.CONFIG.INHERIT_FROM_WORKSHEET')}
  </DropdownItem>], _.chain(dateRanges)
    .sortBy(['name', 'id'])
    .map(dateRange => <DropdownItem href='#' key={dateRange.id}
        onClick={() => setDateRangeAndTrack(dateRange)}>
        {dateRange.name}
        <DateRangeIcon dateRange={dateRange} extraClassNames="pl4" />
      </DropdownItem>
    )
    .value());

  return <div className='flexRowContainer p10'>
    <label htmlFor='dateRangeDropdown'>
      {t('REPORT.CONFIG.DATE_RANGE')}
    </label>
    <Dropdown id='dateRangeDropdown'
      onClick={e => e.preventDefault()}
      className='width-maximum'>
      <DropdownToggle id="dateRangeDropdownToggle" data-testid="dateRangeDropdownToggle"
        variant={null}
        className='width-maximum btn-default'>
        {dateRangeName}
        <DateRangeIcon dateRange={dateRange} includeTooltip={true} extraClassNames="pl4" />
        <span className='caret ml-1' />
      </DropdownToggle>
      <DropdownMenu>
        {dateRangeOptions}
      </DropdownMenu>
    </Dropdown>
  </div>;
};

export const sqReportContentDateRange = angularComponent(reportContentDateRangeBindings, ReportContentDateRange);
