import angular from 'angular';
import { BuilderCtrl } from '@/builder/builder.controller';
import { BuilderService } from '@/builder/builder.service';

const dependencies = [
  'Sq.Core',
  'Sq.Workbench',
  'Sq.Worksheet',
  'Sq.Directives.DoubleClick',
  'Sq.Services.Utilities',
  'Sq.Services.ACLService',
  'Sq.Services.Authorization',
  'Sq.LicenseManagement',
  'Sq.Worksheets',
  'Sq.Worksteps',
  'Sq.Vendor',
  'Sq.Services.Modal',
  'Sq.Search',
  'Sq.TrendData'
];

export const DEFAULT_WORKBOOK_NAME = 'GENERATED_ANALYSIS';
export const ASSET_DELIMITER = '>>';

export enum ViewMode {
  View = 'view',
  Present = 'present',
  Edit = 'edit'
}

export interface BuilderParameters {
  /**
   * The name or id of the workbook to find or create. If an id is specified it will used and assumed to already exist.
   * @default DEFAULT_WORKBOOK_NAME
   */
  workbookName?: string;

  /**
   * The filter used when finding the workbook by name. Can be one of ALL, PUBLIC, OWNER, SHARED.
   * @default OWNER
   */
  workbookFilter?: string;

  /**
   * The name or id of the worksheet to find or create. If an id is specified it will used and assumed to already exist.
   * @default DEFAULT_WORKSHEET_NAME
   */
  worksheetName?: string;

  /**
   * If true the workstep will be built from scratch using only the parameters specified to the builder, otherwise it
   * will build upon the worksheet's current workstep.
   * @default true
   */
  startFresh?: boolean;

  /**
   * Controls whether the worksheet is launched in edit, readonly, or presentation mode. One of ViewMode
   * @default ViewMode.Edit
   */
  viewMode?: ViewMode;

  /**
   * An array of item names that will be added to the trend. If the item exists as part of an asset tree, then it can
   * be specified using its full asset path. >> is the delimiter between levels in the tree. Example: Example Data >>
   * Cooling Tower 1 >> Area A >> Temperature. If a name is specified it will be used as a search query and all
   * matching items will be added. The item ID can also be used to identify an item.
   */
  trendItems?: string[];

  /**
   * Date/time string that represents the start time to use for the display range. Can be specified as either an
   * ISO-8601 string or a time relative to now, e.g. "*-7d". These formats apply to all date/time parameters. If only
   * one half of the start/end pair is specified (e.g. start only), then the other end is calculated using the default
   * for that range, DEFAULT_DISPLAY_RANGE_DURATION_DAYS or DEFAULT_INVESTIGATE_RANGE_DURATION_DAYS.
   */
  displayStartTime?: string;

  /**
   * Date/time string to use for the display range end time.
   */
  displayEndTime?: string;

  /**
   * Date/time string to use for the investigate range start time.
   */
  investigateStartTime?: string;

  /**
   * Date/time string to use for the investigate range end time.
   */
  investigateEndTime?: string;

  /**
   * Asset name that will be expanded in the data area. Can be specified using either the asset name, a full asset
   * path (e.g. Example Data >> Cooling Tower 1 >> Area B), or the ID of the asset.
   */
  expandedAsset?: string;

  /**
   * Asset that will be swapped in to all asset-relative items that have been loaded on the trend. Can be specified
   * using either the asset name or a full asset path (e.g. Example Data >> Cooling Tower 1 >> Area B), or the ID of
   * the asset.
   */
  assetSwap?: string;

  /**
   * Sets what tab the worksheet will load on. One of SEARCH, INVESTIGATE, or ANNOTATE. Search is the implicit default.
   */
  selectedTab?: string;
}

/**
 * @module The builder module manages building a worksheet using query parameters.
 */
angular.module('Sq.Builder', dependencies)
  .service('sqBuilder', BuilderService)
  .controller('BuilderCtrl', BuilderCtrl);
