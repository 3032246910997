/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class ConditionsApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * Capsules cannot be added to a calculated condition. If capsules are in the past, the cache should be invalidated via the Items API
   * @summary Add capsules to a stored condition
   * @param {string} id - ID of the stored condition that will receive the capsules
   * @param {models.CapsulesInputV1} body - Capsules to be added to the stored condition
   */
  public addCapsules(
    body: models.CapsulesInputV1,
    {
      id,
    } : {
      id: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/conditions/{id}/capsules'
        .replace('{id}', encodeURIComponent(String(id))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Archive a condition and its capsules
   * @param {string} id - ID of the condition to archive
   */
  public archiveCondition(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/conditions/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ArchiveOutputV1>;
  }

  /**
   * 
   * @summary Create a condition
   * @param {models.ConditionInputV1} body - Condition information
   */
  public createCondition(
    body: models.ConditionInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/conditions',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ConditionOutputV1>;
  }

  /**
   * 
   * @summary Delete all capsules in a condition
   * @param {string} id - The Seeq ID for the signal
   */
  public deleteCapsules(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/conditions/{id}/capsules'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * Pagination is enabled for this query. Capsules are ordered by start, ascending.
   * @summary Get capsules from a condition
   * @param {string} id - ID of the condition from which to retrieve capsules
   * @param {string} start - A string representing the start of the range of the capsules to be returned. The contents vary based on the condition type. For time conditions: a ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm). For numeric (non-time) conditions: a double-precision number, optionally including units. For example: \&quot;2.5ft\&quot; or \&quot;10 °C\&quot;.
   * @param {string} end - A string representing the end of the range of the capsules to be returned. The contents vary based on the condition type. For time conditions: a ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm). For numeric (non-time) conditions: a double-precision number, optionally including units. For example: \&quot;2.5ft\&quot; or \&quot;10 °C\&quot;.
   * @param {string} [unitOfMeasure] - The unit of measure in which to return the condition&#39;s capsules&#39; starts and ends. If none is supplied, the data is returned in the condition&#39;s default unit of measure.
   * @param {boolean} [isoTimestampFormat=true] - The timestamp format, true for ISO-8601 timestamps or false for nanoseconds since the Unix Epoch. Ignored for numeric (non-time) conditions
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=1000] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getCapsules(
    {
      id,
      start,
      end,
      unitOfMeasure,
      isoTimestampFormat,
      offset,
      limit
    } : {
      id: string,
      start: string,
      end: string,
      unitOfMeasure?: string,
      isoTimestampFormat?: boolean,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(start)) {
      throw new Error("'start' parameter required");
    }

    if (isNil(end)) {
      throw new Error("'end' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/conditions/{id}/capsules'
        .replace('{id}', encodeURIComponent(String(id))),
      params: omitBy({
        ['start']: start,
        ['end']: end,
        ['unitOfMeasure']: unitOfMeasure,
        ['isoTimestampFormat']: isoTimestampFormat,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.CapsulesOutputV1>;
  }

  /**
   * 
   * @summary Get a condition
   * @param {string} id - ID of the condition to retrieve
   */
  public getCondition(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/conditions/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ConditionOutputV1>;
  }

  /**
   * Pagination is enabled for this query
   * @summary Get a collection of conditions
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getConditions(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/conditions',
      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ItemPreviewListV1>;
  }

  /**
   * Capsules cannot be added to a calculated condition. If capsules are in the past, the cache should be invalidated via the Items API
   * @summary Replace any capsules in the same time range
   * @param {string} id - ID of the stored condition that will receive the capsules
   * @param {models.CapsulesOverwriteInputV1} body - Capsules to be added to the stored condition
   */
  public putCapsules(
    body: models.CapsulesOverwriteInputV1,
    {
      id,
    } : {
      id: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'PUT',
      url: this.APPSERVER_API_PREFIX + '/conditions/{id}/capsules'
        .replace('{id}', encodeURIComponent(String(id))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Create or update multiple conditions
   * @param {models.ConditionBatchInputV1} [body]
   */
  public putConditions(
    body?: models.ConditionBatchInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/conditions/batch',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ItemBatchOutputV1>;
  }

}
