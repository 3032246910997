import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import Select from 'react-select';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

/**
 * Renders a Select input that may contain icons and plain text options.
 */
interface IconSelectProps {
  /** an array of options. The text property will be displayed as the text of the option. Add a label to the object
   *  and set formattedOptions=true to use a custom label. */
  selectOptions: { text: string, value: any, icon?: string, label?: any }[];
  /** function that is called when an option is selected. The first param is an object with a value key, the second
   * param is optional. If a "name" is provided as part of the IconSelectProps the name is passed back as the
   * property via this function. */
  onChange: (option: { value: any }, property?: string) => void;
  /** true if the 'selectOptions' will come in with custom labels, false to use
   * default the default formatting here. If true, the selectOptions array must contain a 'label' attribute. */
  formattedOptions?: boolean;
  /** string that is provided to the onChange function as the second parameter */
  name?: string;
  /** additional classNames for Select element. This can be useful for targeting selects in system tests in addition to
   *  styling. */
  className?: string;
  /** translation key for placeholder text */
  placeholder?: string;
  /** selected value */
  value?: any;
  testId?: string;
  /** specify true if this element is inside a modal */
  insideModal?: boolean;
  /** defaults to true */
  appendToBody?: boolean;
  /** specify true if element should be disabled */
  disabled?: boolean;
  /** additional classes for parent element of Select */
  wrapperClasses?: string;
  skipMemo?: boolean;
  isMultipleSelect?: boolean;
  isSearchable?: boolean;
  onRemove?: (option: { value: any }) => void;
}

const IconSelectUnwrapped: React.FunctionComponent<IconSelectProps> = (props) => {
  const {
    name,
    value,
    selectOptions,
    formattedOptions,
    className,
    placeholder,
    onChange,
    insideModal,
    appendToBody = true,
    disabled = false,
    wrapperClasses = '',
    isMultipleSelect = false,
    isSearchable = false,
    onRemove = () => {}
  } = props;

  const { t } = useTranslation();

  const menuPortalTarget = appendToBody ? document.body : null;
  const menuPosition = (insideModal || !menuPortalTarget) ? 'absolute' : 'fixed';
  const portalStyle = { menuPortal: base => ({ ...base, zIndex: 9999 }) };

  const makeLabel = (label, icon) => {
    if (_.isUndefined(icon)) {
      return t(label);
    }
    return <span><i className={classNames('p2', 'mr5', icon)} />{t(label)}</span>;
  };

  const MultiValueRemove = (optionProps) => {
    return (
      <div {...optionProps.innerProps} onClick={(e) => {
        onRemove(_.find(options, { value: optionProps.data.value }));
        if (appendToBody) {
          e.stopPropagation();
        }
      }}><i className="fa fa-close" />
      </div>
    );
  };

  const selectBaseClass = classNames('react-select', {
    'react-multiple-select': isMultipleSelect
  });

  const options = formattedOptions ? selectOptions : _.map(selectOptions,
    option => ({ ...option, label: makeLabel(option.text, option.icon) }));

  const getSelectedValue = selectedValue => _.find(options,
    { value: _.has(selectedValue, 'value') ? selectedValue.value : selectedValue });

  const selectedValueForDisplay = isMultipleSelect
    ? _.map(value, eachSelectedValue => getSelectedValue(eachSelectedValue))
    : getSelectedValue(value);

  return (<div data-testid={`${name}_filter`} className={wrapperClasses}>
    <Select
      className={className ?? ''}
      classNamePrefix={`${className ?? ''} ${selectBaseClass}`}
      placeholder={_.isEmpty(placeholder) ? '' : t(placeholder)}
      // only null resets the select to show the placeholder
      value={_.isUndefined(selectedValueForDisplay) ? null : selectedValueForDisplay}
      menuPosition={menuPosition}
      styles={insideModal && portalStyle}
      menuPortalTarget={menuPortalTarget}
      menuPlacement="auto"
      options={options}
      isSearchable={isSearchable}
      onChange={selectedOption => onChange(selectedOption as { value: any }, name)}
      isDisabled={disabled}
      isMulti={isMultipleSelect}
      components={{ MultiValueRemove }}
    />
  </div>);
};

export const IconSelect = React.memo(IconSelectUnwrapped, (prev, next) => !(
  !_.isEqual(prev.selectOptions, next.selectOptions) ||
  !_.isEqual(prev.value, next.value) ||
  prev.className !== next.className ||
  prev.disabled !== next.disabled ||
  prev.placeholder !== next.placeholder ||
  prev.skipMemo));
