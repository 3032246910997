import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { TrackService } from '@/track/track.service';
import { ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';
import { ScatterPlotStore } from '@/scatterPlot/scatterPlot.store';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { Modal } from 'react-bootstrap';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import SelectItem from '@/hybrid/core/SelectItem.organism';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import {
  CAPSULE_PANEL_TREND_COLUMNS,
  ITEM_TYPES,
  PROPERTIES_COLUMN_PREFIX,
  TREND_PANELS
} from '@/trendData/trendData.module';
import { ScatterPlotColorModalColorRange } from '@/hybrid/scatterPlot/ScatterPlotColorModalColorRange.molecule';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { TrendStore } from '@/trendData/trend.store';
import { ColorPicker } from '../workbooks/ColorPicker.organism';
import { CustomPropertySelector, SuggestedPropertiesMode } from '@/hybrid/utilities/CustomPropertySelector.atom';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { UtilitiesService } from '@/services/utilities.service';
import { TrendActions } from '@/trendData/trend.actions';

const scatterPlotColorModalBindings = bindingsDefinition({
  sqScatterPlotActions: injected<ScatterPlotActions>(),
  sqScatterPlotStore: injected<ScatterPlotStore>(),
  sqTrendStore: injected<TrendStore>(),
  sqTrendDataHelper: injected<TrendDataHelperService>(),
  sqTrendActions: injected<TrendActions>(),
  sqUtilities: injected<UtilitiesService>(),
  sqTrack: injected<TrackService>()
});

export const ScatterPlotColorModal: SeeqComponent<typeof scatterPlotColorModalBindings> = () => {
  const {
    sqScatterPlotActions,
    sqScatterPlotStore,
    sqTrendDataHelper,
    sqUtilities,
    sqTrendStore,
    sqTrendActions
  } = useInjectedBindings(scatterPlotColorModalBindings);

  const { t } = useTranslation();

  const {
    colorConditionIds,
    colorSignalId,
    colorCapsuleProperty,
    capsulePropertyColorsConfig,
    showColorModal
  } = useFlux(sqScatterPlotStore);
  const onClose = () => sqScatterPlotActions.setShowColorModal(false);
  const setCapsulePropertyColor = (itemId, color) => sqScatterPlotActions.setColorForCapsuleProperty(color);

  const setCapsuleProperty = (column) => {
    sqScatterPlotActions.setColorCapsuleProperty(column?.propertyName);
    const columnPrefix = _.includes(_.map(CAPSULE_PANEL_TREND_COLUMNS, 'key'), column.propertyName) ? '' :
      PROPERTIES_COLUMN_PREFIX;
    if (_.includes(_.map(CAPSULE_PANEL_TREND_COLUMNS, 'propertyName'), column.propertyName)) {
      // Trend columns (start/end/duration) only need to be toggled (if not enabled), not added as property columns
      const fullColumn = _.find(CAPSULE_PANEL_TREND_COLUMNS, trendColumn =>
        sqUtilities.equalsIgnoreCase(trendColumn.propertyName as string, column.propertyName));
      if (!sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, fullColumn.key)) {
        sqTrendActions.toggleColumn(TREND_PANELS.CAPSULES, fullColumn.key);
      }
    } else if (!sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, columnPrefix + column.propertyName)) {
      sqTrendActions.addPropertiesColumn(TREND_PANELS.CAPSULES, column);
    }
  };

  return (
    <Modal
      className="scatterPlotColorModal"
      show={showColorModal}
      onHide={onClose}
      animation={false}
      size="sm"
      // enforceFocus = false allows the user to type in the ColorPicker popover inputs
      enforceFocus={false}>
      <Modal.Header closeButton={true}>
        <h4>{t('SCATTER.COLOR_MODAL_TITLE')}</h4>
      </Modal.Header>
      <Modal.Body className="p10">
        <div className="mb10">
          <strong>{t('SCATTER.COLOR_CRITERIA_CAPSULE')}</strong>
          <p className="mb0">{t('SCATTER.COLOR_CRITERIA_CAPSULE_HELP')}</p>
        </div>
        <div className="mb10 scatterPlotCapsulePropertiesDropdown">
          <strong>{t('SCATTER.COLOR_CRITERIA_CAPSULE_PROPERTY')}</strong>
          {_.isEmpty(colorCapsuleProperty) &&
          <CustomPropertySelector
            extraProperties={CAPSULE_PANEL_TREND_COLUMNS}
            conditions={sqTrendDataHelper.getAllItems({ itemTypes: [ITEM_TYPES.CAPSULE_SET], workingSelection: true })}
            suggestedPropertiesMode={SuggestedPropertiesMode.Capsules}
            addPropertyColumn={setCapsuleProperty}
            dropdownPlaceholder='CUSTOM_COLUMN_SELECTOR.CUSTOM_PROPERTY' />}
          {!_.isEmpty(colorCapsuleProperty) &&
          <div className="flexColumnContainer flexAlignCenter flexSpaceBetween">
            <Checkbox
              classes='textPrimaryMenuItem mt3 mb3'
              id={colorCapsuleProperty + '-checkbox'}
              key={colorCapsuleProperty + '-checkbox'}
              label={colorCapsuleProperty}
              isChecked={true}
              onChange={() => sqScatterPlotActions.setColorCapsuleProperty(colorCapsuleProperty)}
              skipMemo={true} />
            {!!capsulePropertyColorsConfig && !capsulePropertyColorsConfig.isStringProperty &&
            <ColorPicker
              color={capsulePropertyColorsConfig.maxColor}
              itemId="capsulePropertyColor"
              notifyOnSelect={setCapsulePropertyColor}
              placement="right" />}
          </div>}
        </div>
        <div className="mb10" data-testid="colorConditions">
          <strong>{t('SCATTER.COLOR_CRITERIA_CONDITION')}</strong>
          <SelectItem
            isMultipleSelect={true}
            insideModal={true}
            selectedItemId={colorConditionIds}
            itemTypes={[ITEM_TYPES.CAPSULE_SET]}
            excludeWorkbookItems={true}
            onSelect={sqScatterPlotActions.addColorCondition}
            onRemove={sqScatterPlotActions.removeColorCondition} />
        </div>
        <div className="mb10">
          <strong>{t('SCATTER.COLOR_CRITERIA_TIME')}</strong>
          <ScatterPlotColorModalColorRange />
        </div>
        <div className="mb10" data-testid="colorSignal">
          <strong>{t('SCATTER.COLOR_CRITERIA_SIGNAL')}</strong>
          <SelectItem
            selectedItemId={colorSignalId}
            itemTypes={[ITEM_TYPES.SERIES]}
            excludeStringSignals={true}
            excludeWorkbookItems={true}
            insideModal={true}
            disableAutoSelect={true}
            allowClear={true}
            onSelect={sqScatterPlotActions.setColorSignal} />
        </div>
      </Modal.Body>
      <Modal.Footer className="text-right">
        <TextButton
          size="sm"
          label={t('CLOSE')}
          onClick={onClose} />
      </Modal.Footer>
    </Modal>
  );
};

export const sqScatterPlotColorModal = angularComponent(scatterPlotColorModalBindings,
  ScatterPlotColorModal);
