import _ from 'lodash';
import bind from 'class-autobind-decorator';
import { DateTimeService } from '@/datetime/dateTime.service';
import { LogsApi } from '@/sdk';
import { APPEND_DIRECTION, SORT } from '@/hybrid/logTracker/LogTracker.constants';

export interface FetchLogsOptions {
  log: string;
  startTime: string;
  limit: number;
  endTime?: string;
  sorted?: string;
  level?: string;
  sourceContains?: string;
  messageContains?: string;
  appendToLogs?: boolean;
  appendDirection?: string;
}

export interface LogItem {
  level: string;
  message: string;
  source: string;
  time: string;
}

@bind
export class LogTrackerService {

  constructor(
    private sqLogsApi: LogsApi,
    private sqDateTime: DateTimeService) {}

  /**
   * Retrieves a collection of available log files for querying.
   * @return {Promise} Array of log names
   */
  getAvailableLogs() {
    return this.sqLogsApi.getAvailableLogFiles()
      .then(response => response.data);
  }

  /**
   * Fetch logs, depends on options result will be appended to existing messages or added before messages
   *
   * @param {FetchLogsOptions} options
   * @param {LogItem[]} currentMessages - existing messages
   * @return {Promise} Messages
   */
  fetchLogs(options: FetchLogsOptions, currentMessages: LogItem[]) {
    options.sorted = _.isEmpty(options.startTime) ? SORT.DESC : SORT.ASC;
    return this.sqLogsApi.getLogs(options)
      .then(({ data }) => {
        return _.chain(data)
          .thru((messages) => {
            if (options.sorted === SORT.DESC) {
              return _.reverse(messages);
            }
            return messages;
          })
          .thru((messages) => {
            if (options.appendToLogs && messages.length > 0) {
              const lastSeen = options.appendDirection === APPEND_DIRECTION.DOWN ? _.last(currentMessages) :
                _.head(currentMessages);
              messages = _.reject(messages, _.partial(_.isEqual, lastSeen));
              if (options.appendDirection === APPEND_DIRECTION.DOWN) {
                return _.concat(currentMessages, messages);
              } else {
                return _.concat(messages, currentMessages);
              }
            } else {
              return messages;
            }
          })
          .value();
      });
  }

  /**
   * When a user clicks up or down button, need to generate options based on button, before we make request
   * It takes last or first message time and fills startTime or endTime depending on 'append' property
   *
   * @param {boolean} append
   * @param {LogItem[]} messages - existing messages
   * @return {object} options with startTime, endTime, appendDirection
   */
  generateMoreLogsOptions(append: boolean, messages: LogItem[]) {
    const options = {} as FetchLogsOptions;

    if (!_.isEmpty(messages)) {
      if (append) {
        options.appendDirection = APPEND_DIRECTION.DOWN;
        options.startTime = this.sqDateTime.reformatZuluOffset(_.last(messages).time);
        options.endTime = '';
      } else {
        options.appendDirection = APPEND_DIRECTION.UP;
        options.endTime = this.sqDateTime.reformatZuluOffset(_.head(messages).time);
        options.startTime = '';
      }
      options.appendToLogs = true;
    }
    return options;
  }
}
