import _ from 'lodash';
import angular from 'angular';
import { DISPLAY_MODE } from '@/main/app.constants';

const dependencies = [
  'Sq.Vendor',
  'Sq.TrendData',
  'Sq.Core',
  'Sq.Directives.DoubleClick',
  'Sq.Services.ACLService',
  'Sq.Services.Authorization',
  'Sq.LicenseManagement',
  'Sq.Worksheets',
  'Sq.Worksteps',
  'Sq.Workbench',
  'Sq.Services.Modal',
  'Sq.Services.Utilities',
  'Sq.Services.Notifications',
  'Sq.Services.CalculationRunner',
  'Sq.Services.ToolRunner',
  'Sq.Services.ToolPanelHelper',
  'Sq.Services.ConfigUpgrader',
  'Sq.TrendViewer',
  'Sq.Services.Socket'
];

// Constants for tool ids of tools that can be opened from toolbar buttons
export const SCATTER_CONDITION_TOOL_ID = 'scatter-condition';
export const EXPORT_TO_ODATA_TOOL_ID = 'export-odata';

export type InvestigateToolType = {
  id: string;
  name?: string;
  nameTranslationKey: string;
  parentId?: string;
  iconClass?: string;
  description?: string;
  descriptionTranslationKey?: string;
  hideFromSearch?: boolean;
  isGroup?: boolean
  defaultDisplayMode?: string;
  forAllSeries?: true;
  forAllItems?: true;
  // For add-on tools
  targetUrl?: string;
  linkType?: string;
  windowDetails?: string;
  sortKey?: string;
  type?: string;
  reuseWindow?: boolean;
};

/**
 * List all of all the tools and properties that describe them:
 *
 * @typedef {Object[]} InvestigateTool
 * @property {String} id - A unique identifier for the tool. Should not change as it is stored as the
 *   calculationType in the UIConfig.
 * @property {String} iconClass - The classes that give the tool a unique icon.
 * @property {String} name - The translation key for the name of the tool.
 * @property {String} description - The translation key for the description of the tool.
 * @property {String} parentId - The id of the group to which the tool belongs. Required on all tools except the
 *   root overview group.
 * @property {Boolean} [hideFromSearch=false] - True if it should not be included when filtering the list of
 *   tools, false to include it in the search.
 * @property {Boolean} [isGroup=false] - True if it is not a tool, but a group container for other tools.
 * @property {Boolean} [forAllItems=false] - True if the tool can be used by all types of items, false otherwise.
 * @property {Boolean} [forAllSeries=false] - True if the tool can be used by all signals, false otherwise.
 * @property {String} [defaultDisplayMode=NEW] - The default display mode when switching to the tool.
 */
const BASE_INVESTIGATE_TOOLS: InvestigateToolType[] = [{
  id: 'overview',
  iconClass: 'fc fc-home',
  nameTranslationKey: 'TOOLBAR.HOME',
  descriptionTranslationKey: 'TOOLBAR.HOME',
  hideFromSearch: true,
  isGroup: true
}, {
  id: 'identify',
  parentId: 'overview',
  iconClass: 'fc fc-identify',
  nameTranslationKey: 'INVESTIGATE_TOOLS.IDENTIFY.HEADER',
  descriptionTranslationKey: 'INVESTIGATE_TOOLS.IDENTIFY.DESCRIPTION',
  isGroup: true
}, {
  id: 'quantify',
  parentId: 'overview',
  iconClass: 'fc fc-quantify',
  nameTranslationKey: 'INVESTIGATE_TOOLS.QUANTIFY.HEADER',
  descriptionTranslationKey: 'INVESTIGATE_TOOLS.QUANTIFY.DESCRIPTION',
  isGroup: true
}, {
  id: 'cleanse',
  parentId: 'overview',
  iconClass: 'fc fc-cleanse',
  nameTranslationKey: 'INVESTIGATE_TOOLS.CLEANSE.HEADER',
  descriptionTranslationKey: 'INVESTIGATE_TOOLS.CLEANSE.DESCRIPTION',
  isGroup: true
}, {
  id: 'model-and-predict',
  parentId: 'overview',
  iconClass: 'fc fc-model-and-predict',
  nameTranslationKey: 'INVESTIGATE_TOOLS.MODEL_PREDICT.HEADER',
  descriptionTranslationKey: 'INVESTIGATE_TOOLS.MODEL_PREDICT.DESCRIPTION',
  isGroup: true
}, {
  id: 'import-export',
  parentId: 'overview',
  iconClass: 'fc fc-export',
  nameTranslationKey: 'INVESTIGATE_TOOLS.IMPORT_EXPORT.HEADER',
  descriptionTranslationKey: 'INVESTIGATE_TOOLS.IMPORT_EXPORT.DESCRIPTION',
  isGroup: true
}, {
  id: 'addon-tools-group',
  parentId: 'overview',
  iconClass: 'fa fa-external-link',
  nameTranslationKey: 'ADDON_TOOLS.NAME',
  descriptionTranslationKey: 'ADDON_TOOLS.DESCRIPTION',
  isGroup: true
}, {
  id: 'manual-signal',
  parentId: 'import-export',
  iconClass: 'fc fc-series',
  nameTranslationKey: 'MANUAL_SIGNAL.HEADER',
  descriptionTranslationKey: 'MANUAL_SIGNAL.DESCRIPTION'
}, {
  id: 'value-search',
  parentId: 'identify',
  iconClass: 'fc fc-search-limit',
  nameTranslationKey: 'VALUE_SEARCH.HEADER',
  descriptionTranslationKey: 'VALUE_SEARCH.HEADER_DESCRIPTION'
}, {
  id: 'periodic-condition',
  parentId: 'identify',
  iconClass: 'fc fc-periodic-condition',
  nameTranslationKey: 'PERIODIC_CONDITION.HEADER',
  descriptionTranslationKey: 'PERIODIC_CONDITION.HEADER_DESCRIPTION'
}, {
  id: 'composite-search',
  parentId: 'identify',
  iconClass: 'fc fc-search-logic',
  nameTranslationKey: 'COMPOSITE_SEARCH.HEADER',
  descriptionTranslationKey: 'COMPOSITE_SEARCH.HEADER_DESCRIPTION'
}, {
  id: 'custom-condition',
  parentId: 'identify',
  iconClass: 'fc fc-capsule-set',
  nameTranslationKey: 'INVESTIGATE_TOOLS.CUSTOM_CONDITION.HEADER',
  descriptionTranslationKey: 'INVESTIGATE_TOOLS.CUSTOM_CONDITION.HEADER_DESCRIPTION'
}, {
  id: SCATTER_CONDITION_TOOL_ID,
  parentId: 'identify',
  iconClass: 'fc fc-scatterplot-select',
  nameTranslationKey: 'INVESTIGATE_TOOLS.SCATTER_CONDITION.HEADER',
  descriptionTranslationKey: 'INVESTIGATE_TOOLS.SCATTER_CONDITION.HEADER_DESCRIPTION'
}, {
  id: 'profile-search',
  parentId: 'identify',
  iconClass: 'fc fc-search-pattern',
  nameTranslationKey: 'PROFILE_SEARCH.HEADER',
  descriptionTranslationKey: 'PROFILE_SEARCH.HEADER_DESCRIPTION'
}, {
  id: 'signal-from-condition',
  parentId: 'quantify',
  iconClass: 'fc fc-series-gen',
  nameTranslationKey: 'SIGNAL_FROM_CONDITION.HEADER',
  descriptionTranslationKey: 'SIGNAL_FROM_CONDITION.HEADER_DESCRIPTION'
}, {
  id: 'threshold-metric',
  parentId: 'quantify',
  iconClass: 'fc fc-metric',
  nameTranslationKey: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.HEADER',
  descriptionTranslationKey: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.HEADER_DESCRIPTION'
}, {
  id: 'aggregation-bins-table',
  parentId: 'quantify',
  iconClass: 'fc fc-bar-chart',
  nameTranslationKey: 'AGGREGATIONS.HEADER',
  descriptionTranslationKey: 'AGGREGATIONS.HEADER_DESCRIPTION'
}, {
  id: 'fftTable',
  parentId: 'quantify',
  iconClass: 'fc fc-bell-curve',
  nameTranslationKey: 'INVESTIGATE_TOOLS.FFT.HEADER',
  descriptionTranslationKey: 'INVESTIGATE_TOOLS.FFT.HEADER_DESCRIPTION',
  forAllItems: true
}, {
  id: 'formula',
  parentId: 'overview',
  iconClass: 'fc fc-formula',
  nameTranslationKey: 'FORMULA.HEADER',
  descriptionTranslationKey: 'FORMULA.HEADER_DESCRIPTION'
}, {
  id: 'signal-smoothing',
  parentId: 'cleanse',
  iconClass: 'fc fc-lowpass',
  nameTranslationKey: 'SIGNAL_SMOOTHING.HEADER',
  descriptionTranslationKey: 'SIGNAL_SMOOTHING.HEADER_DESCRIPTION'
}, {
  id: 'ancillaries',
  parentId: 'model-and-predict',
  iconClass: 'fc fc-ancillaries',
  nameTranslationKey: 'ANCILLARIES.HEADER',
  descriptionTranslationKey: 'ANCILLARIES.HEADER_DESCRIPTION',
  defaultDisplayMode: DISPLAY_MODE.RESULTS,
  forAllSeries: true
}, {
  id: 'reference',
  parentId: 'model-and-predict',
  iconClass: 'fc fc-reference',
  nameTranslationKey: 'REFERENCE.HEADER',
  descriptionTranslationKey: 'REFERENCE.HEADER_DESCRIPTION'
}, {
  id: 'prediction',
  parentId: 'model-and-predict',
  iconClass: 'fc fc-prediction',
  nameTranslationKey: 'PREDICTION.HEADER',
  descriptionTranslationKey: 'PREDICTION.HEADER_DESCRIPTION'
}, {
  id: 'importdatafile',
  parentId: 'import-export',
  iconClass: 'fc fc-import',
  nameTranslationKey: 'IMPORTDATAFILE.HEADER',
  descriptionTranslationKey: 'IMPORTDATAFILE.HEADER_DESCRIPTION'
}, {
  id: 'properties',
  parentId: 'overview',
  hideFromSearch: true,
  iconClass: 'fa fa-info-circle',
  nameTranslationKey: 'PROPERTIES.HEADER',
  descriptionTranslationKey: 'PROPERTIES.HEADER_DESCRIPTION',
  forAllItems: true
}, {
  id: 'export-powerpoint',
  parentId: 'import-export',
  iconClass: 'fc fc-powerpoint',
  nameTranslationKey: 'EXPORT_POWERPOINT.HEADER',
  descriptionTranslationKey: 'EXPORT_POWERPOINT.DESCRIPTION'
}, {
  id: EXPORT_TO_ODATA_TOOL_ID,
  parentId: 'import-export',
  iconClass: 'fa fa-database',
  nameTranslationKey: 'EXPORT_ODATA.HEADER',
  descriptionTranslationKey: 'EXPORT_ODATA.DESCRIPTION'
}, {
  id: 'digital-filter',
  parentId: 'cleanse',
  iconClass: 'fc fc-digital-filter',
  nameTranslationKey: 'DIGITAL_FILTER.HEADER',
  descriptionTranslationKey: 'DIGITAL_FILTER.HEADER_DESCRIPTION'
}];

export let INVESTIGATE_TOOLS: typeof BASE_INVESTIGATE_TOOLS;

/**
 * Investigate module provides tools for creating and manipulating signals and conditions
 */
angular.module('Sq.Investigate', dependencies)
  .factory('INVESTIGATE_TOOLS', function() {
    return _.clone(BASE_INVESTIGATE_TOOLS);
  })
  .factory('TREND_TOOLS', function(INVESTIGATE_TOOLS) {
    return _.transform(INVESTIGATE_TOOLS, function(TREND_TOOLS, tool: any) {
      TREND_TOOLS[_.toUpper(_.snakeCase(tool.id))] = tool.id;
    }, {});
  })
  .run(($injector) => {
    INVESTIGATE_TOOLS = $injector.get('INVESTIGATE_TOOLS');
  });

export const TREND_TOOLS = _.transform(BASE_INVESTIGATE_TOOLS, function(TREND_TOOLS, tool) {
  TREND_TOOLS[_.toUpper(_.snakeCase(tool.id))] = tool.id;
}, {}) as _.Dictionary<string>;

// All tools that have a preview
// If when editing a preview tool you see the preview on top of the original signal, add that tool here
export const PREVIEW_TREND_TOOLS = [
  TREND_TOOLS.DIGITAL_FILTER,
  TREND_TOOLS.SIGNAL_FROM_CONDITION,
  TREND_TOOLS.PREDICTION,
  TREND_TOOLS.SIGNAL_SMOOTHING,
  TREND_TOOLS.MANUAL_SIGNAL
];

export const LOW_PASS_FILTER_CUTOFF = { value: undefined, units: undefined };
export const LOW_PASS_FILTER_PERIOD_CUTOFF_RATIO = 5;
export const NYQUIST_CUTOFF_RATIO = 2;
export const COMPARISON_OPERATORS = ['<', '<=', '>', '>=', '=', '!=', '><', '<>'];
export const STRING_COMPARISON_OPERATORS = ['~=', '!~'];
export const COMPARISON_OPPOSITE_OPERATORS = {
  '<': '>=',
  '>': '<=',
  '<=': '>',
  '>=': '<',
  '=': '!=',
  '!=': '=',
  '~=': '!~',
  '!~': '~=',
  '><': '<>',
  '<>': '><'
};
// Corresponds to the ScalarPredicates on the backend
export const PREDICATE_API = {
  '<': 'isLessThan',
  '<=': 'isLessThanOrEqualTo',
  '=': 'isEqualTo',
  '!=': 'isNotEqualTo',
  '~=': 'isMatch',
  '!~': 'isNotMatch',
  '>=': 'isGreaterThanOrEqualTo',
  '>': 'isGreaterThan',
  '><': 'isBetween',
  '<>': 'isNotBetween'
};
// Gets the symbol for an operator by name
export const COMPARISON_OPERATORS_SYMBOLS = {
  IS_LESS_THAN: '<',
  IS_LESS_THAN_OR_EQUAL_TO: '<=',
  IS_EQUAL_TO: '=',
  IS_NOT_EQUAL_TO: '!=',
  IS_MATCH: '~=',
  IS_NOT_MATCH: '!~',
  IS_GREATER_THAN_OR_EQUAL_TO: '>=',
  IS_GREATER_THAN: '>',
  IS_BETWEEN: '><',
  IS_NOT_BETWEEN: '<>'
};
export const DEVIATION_CONDITIONS = {
  ABOVE: 'ABOVE',
  BELOW: 'BELOW',
  OUTSIDE: 'OUTSIDE'
};
// Corresponds to the ReferenceTableStat constants on the backend
export const REFERENCE_STATS = {
  AVERAGE: 'Average',
  MIN: 'Min',
  MAX: 'Max',
  AVE_DEV: 'AveDev',
  MAX_DEV: 'MaxDev',
  STD_DEV: 'StdDev'
};
export const METRIC_COLORS = [
  '#ff0000',
  '#ffdd52',
  '#6263AD',
  '#068C45',
  '#ffffff'
];
export const VALUE_SEARCH_DURATIONS = {
  MERGE: 'mergeDuration',
  MIN: 'minDuration',
  MAX: 'maxDuration',
  ENTRY: 'entryDuration',
  EXIT: 'exitDuration'
};
// When deprecating a tool, make sure to add an upgrade step similar to upgrade27 in the workstepUpgrader (CRAB-22371)
export const DEPRECATED_TOOL_NAMES = {
  LIMITS: 'limits',
  DEVIATION: 'deviation',
  LOW_PASS: 'low-pass-filter',
  VISUAL_SEARCH: 'visual-search',
  CAPSULE_SET_SERIES: 'capsule-set-series',
  POWER_SEARCH: 'power-search'
};
