import _ from 'lodash';
import angular from 'angular';
import { BaseToolStoreService } from '@/investigate/baseToolStore.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { INTERPOLATION_METHODS, SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.module';
import { TREND_TOOLS } from '@/investigate/investigate.module';

angular.module('Sq.Investigate').store('sqSignalFromConditionStore', sqSignalFromConditionStore);

export type SignalFromConditionStore = ReturnType<typeof sqSignalFromConditionStore>['exports'];

function sqSignalFromConditionStore(
  sqBaseToolStore: BaseToolStoreService,
  sqDateTime: DateTimeService,
  sqSystemConfiguration: SystemConfigurationService
) {
  const store = {
    initialize() {
      this.state = this.immutable(_.assign({}, sqBaseToolStore.COMMON_PROPS, {
        params: {
          interpolation: INTERPOLATION_METHODS.DISCRETE,
          stat: {
            key: undefined,
            timeUnits: 's'
          },
          maximumDuration: {
            inputOverride: undefined,
            boundingOverride: undefined
          }
        }
      }));
    },

    exports: {
      get params() {
        // Default must be provided here instead of initialize because system config is fetched async
        const defaults = {
          maximumDuration: {
            inputOverride: sqSystemConfiguration.defaultMaxCapsuleDuration,
            boundingOverride: sqSystemConfiguration.defaultMaxCapsuleDuration
          }
        };
        return _.defaultsDeep(_.cloneDeep(this.state.get('params')), defaults);
      }
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @return {Object} State for the store
     */
    dehydrate() {
      return this.state.serialize();
    },

    /**
     * Sets the state
     *
     * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      SIGNAL_FROM_CONDITION_SET_PARAMS: 'setParams',
      TOOL_REHYDRATE_FOR_EDIT: 'signalFromConditionRehydrateForEdit'
    },

    /**
     * Set the parameters that are used in the form.
     *
     * @param {Object} payload - State information
     * @param {Object} payload.params - The params from the form
     */
    setParams(payload) {
      this.state.set('params', payload.params);
    },

    getStatAndParameters(formula: string) {
      const match = /\.aggregate\((\w*)\((?:'|")?(.*?)(?:'|")?\)/.exec(formula);
      if (!match) {
        return { stat: null, parameters: { timeUnits: 's' } };
      }

      const [full, operator, arg] = match;
      const stat = _.find(SAMPLE_FROM_SCALARS.VALUE_METHODS, valueMethod => _.startsWith(valueMethod.stat, operator));
      if (stat.key === 'percentile') {
        const percentile = parseInt(arg, 10);
        return { stat, parameters: { timeUnits: 's', percentile: _.isNaN(percentile) ? null : percentile } };
      } else {
        const timeUnits = _.chain([arg])
          .reject(text => _.toLower(text) === 'true' || _.toLower(text) === 'false')
          .head()
          .value() || 's';
        return { stat, parameters: { timeUnits, percentile: null } };
      }
    },

    /**
     * Adds the formula and parameters to the config as part of what gets rehydrated when the tool is loaded.
     *
     * @param {Object} payload - An object with the necessary state to populate the edit form.
     * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
     * @param {Object[]} payload.parameters - The parameters used in the formula
     * @param {String} payload.selectedOperator - The selected operator from the UI Config
     */
    signalFromConditionRehydrateForEdit(payload) {
      if (payload.type === TREND_TOOLS.SIGNAL_FROM_CONDITION) {
        this.rehydrateForEdit(payload);

        const inputOverrideDuration = _.get(/\$series\.setMaximumDuration\((.*?)\)/.exec(payload.formula), '1');
        const boundingOverrideDuration = _.get(/\$capsules\.setMaximumDuration\((.*?)\)/.exec(payload.formula), '1');
        const { stat, parameters } = this.getStatAndParameters(payload.formula);
        const keyMethod = _.get(/(\w*?)Key\(\)/.exec(payload.formula), '1');

        let interpolation;
        const isStep = (payload.formula || '').indexOf('toStep()') > -1;
        if (isStep) {
          interpolation = INTERPOLATION_METHODS.STEP;
        } else if (_.get(payload, 'signalMetadata.maxInterpolation.value', 0) > 0) {
          interpolation = INTERPOLATION_METHODS.LINEAR;
        } else {
          interpolation = INTERPOLATION_METHODS.DISCRETE;
        }

        const maxInterpolation = sqDateTime.convertDuration(_.get(payload, 'signalMetadata.maxInterpolation'));
        this.state.set('signalMetadata', { maxInterpolation });

        this.state.merge('params', {
          maximumDuration: {
            inputOverride: sqDateTime.splitDuration(inputOverrideDuration) ||
              sqSystemConfiguration.defaultMaxCapsuleDuration,
            boundingOverride: sqDateTime.splitDuration(boundingOverrideDuration) ||
              sqSystemConfiguration.defaultMaxCapsuleDuration
          },
          stat: {
            key: _.get(stat, 'key'),
            timeUnits: _.get(parameters, 'timeUnits'),
            percentile: _.get(parameters, 'percentile')
          },
          keyMethod,
          interpolation
        });
      }
    },

    /**
     * Removes properties from config which are stored as part of the formula.
     * Signal from condition gets everything it needs from the formula so the config only contains the tool type and
     * whether the advanced section is expanded or not.
     *
     * @param {Object} config - The state that will be saved to UIConfig
     * @return {Object} The modified config
     */
    modifyConfigParams(config) {
      return _.pick(config, ['type', 'advancedParametersCollapsed']);
    }
  };

  return sqBaseToolStore.extend(store, TREND_TOOLS.SIGNAL_FROM_CONDITION,
    { inputItem: { predicate: ['name', 'series'] }, condition: { predicate: ['name', 'capsules'] } });
}
