import React from 'react';
import { DURATION_TIME_UNITS, DurationTimeUnit } from '@/main/app.constants';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { DurationSelector } from '@/hybrid/core/DurationSelector.atom';

export const DEFAULT_LIVE_RATE: LiveUpdateRate = {
  unit: DURATION_TIME_UNITS.find(({ translationKey }) => translationKey === 'DURATION_UNITS.HOURS'),
  value: 1
};

export class LiveUpdateRate {
  unit: DurationTimeUnit;
  value: number;
}

interface LiveScheduleProps {
  rate: LiveUpdateRate;
  onChange: (LiveUpdateRate) => void;
  onInvalidInput: (message: string) => void;
}

export const LiveSchedule: React.FunctionComponent<LiveScheduleProps> = (props) => {
  const { rate: { unit, value }, onChange, onInvalidInput } = props;
  const { t } = useTranslation();

  return (
    <FormGroup data-testid="live-schedule-config">
      <FormLabel>{t('REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.LIVE.RATE.NAME')}</FormLabel>
      <p className="text-italic sq-darkish-gray">{t('REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.LIVE.RATE.DESCRIPTION')}</p>
      <DurationSelector
        unit={unit}
        value={value}
        onChange={(value, unit) => onChange({ value, unit })}
        onInvalidInput={onInvalidInput} />
    </FormGroup>
  );
};
