import React, { useState, useEffect } from 'react';
import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { SEARCH_PANES } from '@/search/search.module';
import { SearchWidget } from '@/hybrid/search/SearchWidget.organism';
import { Icon } from '@/hybrid/core/Icon.atom';
import { getNextFormulaIdentifier } from '@/hybrid/formula/formula.utilities';
import { FormulaEditorParam } from '@/hybrid/formula/FormulaParametersTable.molecule';
import { FormError } from '@/hybrid/core/FormError.atom';

interface AddFormulaParameterModalProps {
  onClose: () => void;
  testId: string;
  parameter?: FormulaEditorParam;
  parameters: FormulaEditorParam[];
  onSave: (item: FormulaEditorParam, original?: FormulaEditorParam) => void;
}

export const AddFormulaParameterModal: React.FunctionComponent<AddFormulaParameterModalProps> = ({
  testId,
  onClose,
  onSave,
  parameters,
  parameter
}) => {
  const { t } = useTranslation();
  const SEARCH_TYPES = ['Asset', 'Condition', 'Signal', 'CalculatedScalar'];
  const emptyItem = { name: '', id: '' };
  const [parameterIdentifier, setParameterIdentifier] = useState(parameter?.identifier ?? '');
  const [item, setItem] = useState(parameter?.item ?? emptyItem);
  const [originalItem] = useState(parameter);
  const [noItemError, setNoItemError] = useState(false);

  useEffect(() => {
    if (!parameterIdentifier) {
      setParameterIdentifier(getNextFormulaIdentifier(parameters));
    }
  }, []);

  const onSaveCallback = () => {
    if (item.id) {
      onSave({ ...parameter, item, identifier: parameterIdentifier }, originalItem);
      onClose();
    } else {
      setNoItemError(true);
    }
  };

  return (
    <Modal size="sm" show={true} onHide={onClose} animation={false} data-testid={testId}>
      <Modal.Header closeButton={true}>
        <h3>{t('FORMULA.VARIABLE_DEFINE')}</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="flexRowContainer mt5">
          <div className="flexRowContainer mb10">
            <label>{t('FORMULA.VARIABLE_IDENTIFIER')}</label>
            <FormControl
              id="parameterIdentifier"
              name="parameterIdentifier"
              type="text"
              value={parameterIdentifier}
              onChange={event => setParameterIdentifier(event.target.value)} />
          </div>
          {item.name
            ?
            <InputGroup>
              <FormControl
                data-testid="parameterIdentifier"
                autoComplete="off"
                type="text"
                name="commonName"
                className="width-maximum"
                value={item.name}
                disabled={true}
              />
              <InputGroup.Append data-testid="create-key-button" className="input-group-btn">
                <Button
                  className="btn btn-default"
                  variant="outline"
                  onClick={() => setItem(emptyItem)}>
                  <Icon icon="fa-close" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
            :
            <SearchWidget
              pane={SEARCH_PANES.MODAL}
              searchTypes={SEARCH_TYPES}
              allowAssetReplacement={false}
              onItemClick={(item) => {
                setItem(item);
                setNoItemError(false);
              }}
            />}

          {noItemError && <FormError errorText="FORMULA.NO_ITEM" extraClassNames="text-center mt10" />}

          <div className="flexColumnContainer flexJustifyEnd mt15">
            <TextButton
              onClick={onClose}
              label="CANCEL"
              size="sm"
              testId="cancelButton"
              extraClassNames="mr20 min-width 100 width-100 max-width-100" />

            <TextButton
              onClick={onSaveCallback}
              label="ADD"
              disabled={noItemError}
              size="sm"
              testId="executeButton"
              extraClassNames="min-width 100"
              variant="theme" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

