import _ from 'lodash';
import angular from 'angular';
import $ from 'jquery';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { AnnotationStore } from '@/annotation/annotation.store';
import { AnnotationActions } from '@/annotation/annotation.actions';
import { UtilitiesService } from '@/services/utilities.service';
import { NotificationsService } from '@/services/notifications.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { WorkbookStore } from '@/workbook/workbook.store';
import { AuthorizationService } from '@/services/authorization.service';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { AnnotationUtilitiesService } from '@/services/annotationUtilities.service';
import { FroalaPluginsService } from '@/reportEditor/froalaPlugins.service';
import { API_TYPES } from '@/main/app.constants';
import { ID_PLACEHOLDER, LINK_TYPE } from '@/annotation/annotation.module';
import { WORKSHEET_SIDEBAR_TAB } from '@/worksheet/worksheet.module';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { CustomPlugin } from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';

angular.module('Sq.Annotation').controller('JournalPanelCtrl', JournalPanelCtrl);

function JournalPanelCtrl(
  $q: ng.IQService,
  $scope: ng.IScope,
  $state: ng.ui.IStateService,
  $translate: ng.translate.ITranslateService,
  $document: ng.IDocumentService,
  $injector: ng.auto.IInjectorService,
  sqAnnotationActions: AnnotationActions,
  sqAnnotationStore: AnnotationStore,
  sqWorksheetStore: WorksheetStore,
  sqUtilities: UtilitiesService,
  sqNotifications: NotificationsService,
  sqWorkbenchStore: WorkbenchStore,
  sqDateTime: DateTimeService,
  sqAuthorization: AuthorizationService,
  sqWorkbookStore: WorkbookStore,
  sqAnnotationUtilities: AnnotationUtilitiesService,
  sqFroalaPlugins: FroalaPluginsService
) {
  const vm = this;
  let savePromise;

  vm.isLoading = true;
  vm.isSaved = false;
  vm.isError = false;
  vm.highlightId = '';
  vm.expand = sqAnnotationActions.setExpanded;
  vm.expandComments = sqAnnotationActions.setCommentsExpanded;
  vm.expandAnnotates = sqAnnotationActions.setAnnotatesExpanded;
  vm.widthChanged = sqAnnotationActions.setWidth;
  vm.heightChanged = sqAnnotationActions.setHeight;
  vm.edit = sqAnnotationActions.setIsEditing;
  vm.delete = sqAnnotationActions.delete;
  vm.deleteComment = sqAnnotationActions.delete;
  vm.newEntry = _.flow(sqAnnotationActions.newEntry, _.partial(_.set, vm, 'isSaved', false));
  vm.showEntry = _.flow(sqAnnotationActions.showEntry, _.partial(_.set, vm, 'isSaved', false));
  vm.showOverview = sqAnnotationActions.closeEntry;
  vm.canManage = sqAuthorization.canManageItem;
  vm.canModifyWorkbook = sqAuthorization.canModifyWorkbook;
  vm.closeEntry = closeEntry;
  vm.documentChanged = documentChanged;
  vm.saveComment = saveComment;
  vm.updateComment = updateComment;
  vm.toggleDiscoverable = toggleDiscoverable;
  vm.isPresentationMode = sqUtilities.isPresentationWorkbookMode;
  vm.beforeEditorOnInit = beforeEditorOnInit;
  vm.afterEditorOnInit = _.noop;
  vm.onEditorDestroy = _.noop;
  vm.workbook = _.pick(sqWorkbookStore, ['owner', 'effectivePermissions']);  // Needed for authorization checks

  const sqReportEditor = $injector.get<ReportEditorService>('sqReportEditor');
  // Exposed for test
  vm.save = save;
  vm.syncAnnotationVars = syncAnnotationVars;
  vm.toolbar = [
    CustomPlugin.JournalLink, 'link', 'uploadImage', 'insertTable', '|',
    'heading', 'fontColor', 'fontBackgroundColor', '|',
    'bold', 'italic', 'underline', 'strikethrough', '|',
    'alignment', 'outdent', 'indent', 'numberedList', 'bulletedList', 'pagebreak', '|',
    'removeFormat', '|',
    'undo', 'redo'
  ];
  vm.plugins = [CustomPlugin.JournalLink];

  $scope.$listenTo(sqWorkbenchStore, ['currentUser'], syncUser);
  $scope.$listenTo(sqAnnotationStore, syncAnnotationVars);
  $scope.$listenTo(sqWorksheetStore, ['selectedSidebarTab'], syncWorksheetVars);

  /**
   * Syncs the Workbench store.
   */
  function syncUser() {
    vm.currentUser = sqWorkbenchStore.currentUser;
  }

  /**
   * Syncs the annotation view-model variables.
   *
   * @param {Object} e - The Baobab event object containing paths that changed
   */
  function syncAnnotationVars() {
    vm.id = sqAnnotationStore.id;
    const entry = _.find(sqAnnotationStore.annotations, ['id', vm.id]);
    vm.isCommentAllowed = sqUtilities.validateGuid(vm.id) && sqAuthorization.canAnnotateItem(entry);
    vm.workbookId = _.get(_.find(_.get(entry, 'interests'), ['item.type', API_TYPES.ANALYSIS, API_TYPES.TOPIC]),
      'item.id');
    vm.worksheetId = _.get(_.find(_.get(entry, 'interests'), ['item.type', API_TYPES.WORKSHEET]), 'item.id');
    vm.createdAt = sqDateTime.formatTime(_.get(entry, 'createdAt'), sqWorksheetStore.timezone);
    vm.updatedAt = sqDateTime.formatTime(_.get(entry, 'updatedAt'), sqWorksheetStore.timezone);
    vm.createdBy = _.get(entry, 'createdBy.name');
    vm.isDeleteAllowed = sqAuthorization.canManageItem(entry);
    vm.isDiscoverable = sqAnnotationStore.isDiscoverable;  // isDiscoverable==true means it's an annotation
    vm.isEditAllowed = sqAuthorization.canWriteItem(entry) ||
      // For new journal entries or annotations, we don't have an entry to use for authorization check, so instead
      // allow editing of annotations if the user has read access to the workbook and only allow editing of journal
      // entries if the user has write access to the workbook.
      (vm.id === ID_PLACEHOLDER &&
        (vm.isDiscoverable ? sqWorkbookStore.effectivePermissions.read : sqWorkbookStore.effectivePermissions.write));
    vm.isEditing = vm.isEditAllowed && sqAnnotationStore.isEditing && !vm.isPresentationMode;
    vm.isExpanded = sqAnnotationStore.isExpanded;
    vm.width = sqAnnotationStore.width;
    vm.height = sqAnnotationStore.height;
    vm.isCommentsExpanded = sqAnnotationStore.isCommentsExpanded;
    vm.isAnnotatesExpanded = sqAnnotationStore.isAnnotatesExpanded;
    vm.document = sqAnnotationStore.document;
    vm.highlightId = sqAnnotationStore.highlightId;
    vm.comments = _.get(entry, 'replies');
    vm.annotations = sqAnnotationStore.annotations;
    vm.hasMultipleJournalEntries = sqAnnotationStore.findJournalEntries(sqAnnotationStore.annotations,
      $state.params.worksheetId).length > 1;
    vm.annotates = _.chain(vm.annotations).find(['id', vm.id]).get('items').value();
    vm.ranges = _.chain(vm.annotates).filter(['type', API_TYPES.CAPSULE]).value();
    vm.interests = _.chain(vm.annotates)
      .filter(sqUtilities.isTrendable)
      .map((item: any) => ({ interestId: item.id }))
      .value();
    const { description, name } = sqAnnotationUtilities.nameAndDescriptionFromDocument(vm.document);
    vm.name = name;
    vm.description = description;
  }

  /**
   * Syncs the worksheet view
   */
  function syncWorksheetVars(e) {
    // If the journal tab is selected and the journal entry is empty then set focus in the editor
    if (!_.isEmpty(e) && sqWorksheetStore.selectedSidebarTab === WORKSHEET_SIDEBAR_TAB.ANNOTATE &&
      !sqAnnotationStore.document) {
      sqReportEditor.focus();
    }
  }

  /**
   * Callback from the journal editor before it executes $onInit
   */
  function beforeEditorOnInit(editorOptions) {
    sqFroalaPlugins.journalLink();

    // Toolbar in small spaces
    const compactToolbarButtons = {
      moreRich: {
        buttons: ['journalLink', 'insertLink', 'insertImage', 'insertTable'],
        buttonsVisible: 4
      },
      moreParagraph: {
        buttons: ['paragraphFormat', 'textColor', 'backgroundColor', 'align', 'outdent', 'indent', 'formatOL', 'formatUL'],
        buttonsVisible: 4
      },
      moreMisc: {
        buttons: ['bold', 'italic', 'underline', 'strikeThrough',
          'clearFormatting',
          'undo', 'redo'],
        align: 'right',
        buttonsVisible: 0
      }
    };

    // Toolbar in larger spaces
    const toolbarButtons = [
      'journalLink', 'insertLink', 'insertImage', 'insertTable', '|',
      'paragraphFormat', 'textColor', 'backgroundColor', '|',
      'bold', 'italic', 'underline', 'strikeThrough', '|',
      'align', 'outdent', 'indent', 'formatOL', 'formatUL', '|',
      'clearFormatting', '|',
      'undo', 'redo'];

    _.assign(editorOptions, {
      theme: 'analysis',
      // The scrollableContainer option is needed by the journal editor to make it function when it is expanded
      scrollableContainer: '.fr-wrapper',
      toolbarMaxWidthXS: 350, // Approximate width of the journal panel when collapsed
      toolbarButtonsXS: compactToolbarButtons,
      toolbarButtonsSM: toolbarButtons,
      toolbarButtonsMD: toolbarButtons,
      toolbarButtons,
      listAdvancedTypes: false,
      placeholderText: $translate.instant('JOURNAL.ENTRY.PLACEHOLDER'),
      imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
      imageEditButtons: [
        'imageReplace', 'linkOpen', 'imageLink', 'imageAlign', 'imageStyle', '-',
        'imageAlt', 'linkEdit', 'imageSize', 'imageRemove'
      ]
    });
  }

  /**
   * Fetches the annotations for the items loaded in the trend as well as those scoped to the workbook.
   *
   * @returns {Promise} Promise that resolves when annotations are fetched
   */
  function fetchAnnotations() {
    vm.isLoading = true;
    return sqAnnotationActions.fetchAnnotations()
      .catch(sqNotifications.apiError)
      .finally(_.partial(_.set, vm, 'isLoading', false));
  }

  /**
   * Closes the editor panel and switches to the panel listing the annotations. Ensures that save is complete before
   * switching to ensure that the list of annotations shown is fresh.
   */
  function closeEntry() {
    (vm.isSaving ? savePromise : $q.resolve())
      .then(() => sqAnnotationActions.fetchAnnotations())
      .then(() => sqAnnotationActions.closeEntry());
  }

  /**
   * Handler for callback notifications that the document has been changed by the user.
   *
   * @param {String} document - the updated document
   * @param {Boolean} forceSave - true to force the document to save
   * @returns {Promise} Promise that resolves when the document has been saved
   */
  function documentChanged(document, forceSave) {
    if (document === vm.document && !forceSave) {
      return $q.resolve();
    }

    const { description, name } = sqAnnotationUtilities.nameAndDescriptionFromDocument(document);
    vm.name = name;
    vm.description = description;

    updateAnnotates(document);

    return save({ document });
  }

  /**
   * Updates the view model interests and ranges by examining all the seeq links in the document.
   *
   * @param {String} document - a string containing the html document
   */
  function updateAnnotates(document) {
    let htmlElements, anchors;
    const interests = [];
    const ranges = [];

    if (vm.isDiscoverable) {
      // Ensure images don't load while parsing: https://stackoverflow.com/a/50194774/1108708
      const ownerDocument = ($document.get(0) as any).implementation.createHTMLDocument('virtual');
      htmlElements = _.filter($.parseHTML(document, ownerDocument), elements => elements.nodeName !== '#text');
      anchors = _.transform(htmlElements, function(anchors, element: any) {
        _.forEach([].slice.call(element.getElementsByTagName('a')), function(a) {
          anchors.push(a);
        });
      }, []);

      _.forEach(anchors, function(a: any) {
        let params;

        if (_.endsWith(a.pathname, '/links')) {
          params = sqUtilities.parseQueryString(a.search);

          if (_.includes(
            [LINK_TYPE.SIGNAL, LINK_TYPE.CONDITION, LINK_TYPE.CAPSULE, LINK_TYPE.TABLE, LINK_TYPE.METRIC],
            params.type
          )) {
            if (sqUtilities.validateGuid(params.item)) {
              interests.push({ interestId: params.item });
            }
          }

          if (params.type === LINK_TYPE.CAPSULE) {
            if (_.isNumber(+params.start) && _.isNumber(+params.end)) {
              ranges.push({ start: +params.start, end: +params.end });
            }
          }
        }
      });
    }

    vm.interests = _.uniqWith(interests, _.isEqual);
    vm.ranges = _.uniqWith(ranges, _.isEqual);
  }

  /**
   * Helper function that saves a journal entry, its interests, and ranges. All arguments are optional because
   * default values from view model will be used for any arguments that are not provided.
   *
   * @param {Object} [args] - Object container
   * @param {String} [args.name] - The name of the journal entry
   * @param {String} [args.id] - The ID of the journal entry if saving an already existing entry.
   * @param {String} [args.description] - The description of the journal entry
   * @param {String} [args.document] - The journal entry document
   * @param {Object[]} [args.inputInterests] - The journal entry interests
   * @param {String} [args.inputInterests[].interestId] - Id of item being annotated
   * @param {String} [args.inputInterests[].detailId] - Id of item inside a set, such as a capsule, being annotated
   * @param {Object[]} [args.ranges] - The start/end pairs of interest. A new interest will be added for each range
   * @returns {Promise} a promise that resolved when the journal entry has been saved
   */
  function save(args?) {
    const defaultArgs = {
      id: sqUtilities.validateGuid(vm.id) ? vm.id : undefined,
      discoverable: vm.isDiscoverable,
      workbookId: vm.workbookId,
      worksheetId: vm.worksheetId,
      name: vm.name,
      description: vm.description,
      document: vm.document,
      inputInterests: vm.interests,
      ranges: vm.ranges
    };

    if (vm.isSaving && !sqUtilities.validateGuid(vm.id)) {
      // We're currently saving, but don't yet have an guid for the journal/annotation, so "queue" the save until a guid
      // has been assigned to avoid creating duplicates.
      return savePromise.finally(_.partial(save, args));
    }

    args = _.merge(defaultArgs, args);

    vm.isSaving = true;
    savePromise = sqAnnotationActions.save(args)
      .then(function(response) {
        vm.id = response.id;
        sqAnnotationActions.setId(response.id);
        if (!sqUtilities.hasLoadingPastedImages(sqReportEditor.getHtml())) {
          vm.isSaving = false;
          vm.isSaved = true;
          vm.isError = false;
        }
        return response;
      })
      .catch((e) => {
        if (e.status === -1) {
          // Ignore local cancellations because another save request has already been made
          return;
        }
        vm.isSaving = false;
        vm.isSaved = false;
        vm.isError = true;
        sqNotifications.apiError(e);
      });

    return savePromise;
  }

  /**
   * Save a new comment
   *
   * @param  {String} id - ID of the journal entry to which this is a comment
   * @param  {String} name - the text of the comment
   * @return {Promise} Promise which is resolved when the comment is saved
   */
  function saveComment(id, name) {
    return sqAnnotationActions.save({ repliesTo: id, name })
      .then(fetchAnnotations)
      .catch(sqNotifications.apiError)
      .finally(_.partial(_.set, vm, 'isCommenting', false));
  }

  /**
   * Update an existing comment
   *
   * @param  {String} id - the ID of the comment to update
   * @param {String} name - the text of the comment
   * @return {Promise} Promise which is resolved when the reply is saved
   */
  function updateComment(id, name) {
    return sqAnnotationActions.save({ id, name })
      .then(fetchAnnotations)
      .catch(sqNotifications.apiError);
  }

  /**
   * Toggles journal entry discoverable property (aka. Annotate linked data).
   *
   * @returns {Promise} a promise that resolves when the discoverable property has been updated on the backend.
   */
  function toggleDiscoverable() {
    if (!vm.canManage || !vm.isEditing) {
      return $q.resolve();
    }

    vm.isDiscoverable = !vm.isDiscoverable;
    sqAnnotationActions.setIsDiscoverable(vm.isDiscoverable);
    updateAnnotates(vm.document);
    return save().then(sqAnnotationActions.fetchAnnotations);
  }
}
