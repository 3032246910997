import jQuery from 'jquery';
import { TOGGLE_CK_SAVING } from '@/hybrid/annotation/ckReport.module';

export function sqReportEditorCKEditor() {
  let globalInstance;
  let presetHtml;

  const service = {
    saveReport,
    triggerDocumentChangedEvent,
    getHtml,
    insertHtml,
    setHtml,
    saveSelection,
    restoreSelection,
    getScrollOffset,
    setScrollOffset,
    setReportViewHtml,
    setGlobalInstance,
    getGlobalInstance,
    setCursorPosition,
    getCursorPosition,
    focus,
    getPresetHtml,
    executeCommand
  };

  return service;

  function saveReport() {
    // no-op for now, as it's not needed on CKEditor
  }

  function triggerDocumentChangedEvent() {
    // no-op for now, as it's not needed on CKEditor
  }

  /**
   * Gets the data from CKEditor
   */
  function getHtml() {
    if (!globalInstance) {
      return;
    }
    return globalInstance.getData();
  }

  function insertHtml(html) {
    // no-op for now, as it's not needed on CKEditor
  }

  /**
   * Replaces all the html with a new html string
   *
   * @param html - the html to set
   * @param forceUpdate - force CK to trigger an update on setting the HTML
   */
  function setHtml(html: string, forceUpdate: boolean = false) {
    if (globalInstance) {
      // CRAB-25207: CK triggers an update event on `setData`, and we don't want fast followers to trigger updates
      // on receiving the latest document
      !forceUpdate && globalInstance.fire(TOGGLE_CK_SAVING, false);
      globalInstance.setData(html ?? '');
      !forceUpdate && globalInstance.fire(TOGGLE_CK_SAVING, true);
    } else {
      presetHtml = html;
    }
  }

  function saveSelection() {
    // no-op for now, as it's not needed on CKEditor
  }

  function restoreSelection() {
    // no-op for now, as it's not needed on CKEditor
  }

  /**
   * Gets the editor scroll offset
   *
   * @returns the current scroll offset
   */
  function getScrollOffset(): number {
    return jQuery('.ck-editor__main').scrollTop();
  }

  /**
   * Sets the editor scroll offset
   *
   * @param offset - a scroll offset
   */
  function setScrollOffset(offset: number) {
    // Setting the scroll offset right after a change to the document can sometimes cause the set to be eaten by
    // something CK does.
    setTimeout(() => jQuery('.ck-editor__main').scrollTop(offset), 1);
  }

  /**
   * Sets the document html of the report when in view mode. In view mode the document is displayed in a simple
   * div. In edit mode, the document displays in the editor.
   *
   * @param {string} document - the html to set as the report view
   */
  function setReportViewHtml(document: string) {
    setHtml(document);
  }

  /**
   * Function to set the global instance of the editor that can be used across app
   */
  function setGlobalInstance(instance) {
    globalInstance = instance;
    return globalInstance;
  }

  /**
   * Function to set the global instance of the editor that can be used across app
   */
  function getGlobalInstance() {
    return globalInstance;
  }

  function getCursorPosition(): any | undefined {
    return getGlobalInstance().model.document.selection.getFirstPosition();
  }

  // CK screams and dies if you try to set the selection (cursor) to an invalid location. This checks the path of
  // the position through the view and makes sure there is a valid element there.
  function isPositionValid(position) {
    const path = position.path;
    if (!path.length) {
      return false;
    }

    let currentChild = getGlobalInstance().model.document.getRoot();
    if (!currentChild) return false;
    for (let i = 0; i < path.length; i++) {
      const pathValue = path[i];
      currentChild = currentChild.getChild(pathValue);
      if (currentChild === null && pathValue > 0 && i === (path.length - 1)) {
        return false;
      }
    }

    return true;
  }

  function setCursorPosition(position) {
    if (isPositionValid(position)) {
      getGlobalInstance().model.change((writer) => {
        writer.setSelection(position);
      });
    }
  }

  function focus() {
    getGlobalInstance().editing.view.focus();
  }

  function getPresetHtml() {
    const a = presetHtml;
    presetHtml = null;
    return a;
  }

  function executeCommand({ command, ...rest }) {
    getGlobalInstance().execute(command, rest);
  }
}
