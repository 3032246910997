import _ from 'lodash';
import angular from 'angular';
import moment from 'moment-timezone';
import { DateTimeService } from '@/datetime/dateTime.service';
import { TimezonesService } from '@/datetime/timezone.service';
import { UtilitiesService } from '@/services/utilities.service';
import { EMPTY_XYREGION } from '@/services/chartHelper.service';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.module';
import { WorksheetViewService } from '@/worksheet/worksheetView.service';
import {
  COLUMNS_AND_STATS,
  SERIES_PANEL_REQUIRED_TREND_COLUMNS,
  TREND_PANELS_SORT
} from '@/trendData/trendData.module';
import { DEPRECATED_TOOL_NAMES } from '@/investigate/investigate.module';
import {
  TableBuilderColumnType,
  TableBuilderHeaderType,
  TableBuilderMode
} from '@/hybrid/tableBuilder/tableBuilder.module';
import { MetricsApi } from '@/sdk';
import { TrendMetricStore } from '@/trendData/trendMetric.store';
import { ProcessTypeEnum } from 'sdk/model/ThresholdMetricOutputV1';
import { SeeqNames } from '@/main/app.constants.seeqnames';

export const WORKSTEP_SCHEMA_VERSION = 43;

/**
 * A service that manages changes to the schema of workstep state (see configUpgrader for uiConfig upgrades)
 * Since workstep state is just the result of dehydrating all stores this means that any store that makes a
 * backwards incompatible change (e.g. changing a property name, changing a data structure, etc.) must do the following:
 * 1. Create a function named `upgradeX` where X is the current WORKSTEP_SCHEMA_VERSION and add it to the upgraders
 * object.
 * 2. Increase WORKSTEP_SCHEMA_VERSION by one
 */
angular.module('Sq.Worksteps')
  .factory('sqWorkstepUpgrader', sqWorkstepUpgrader);

export type WorkstepUpgraderService = ReturnType<typeof sqWorkstepUpgrader>;

function sqWorkstepUpgrader(
  $q: ng.IQService,
  sqDateTime: DateTimeService,
  sqMetricsApi: MetricsApi,
  sqTimezones: TimezonesService,
  sqTrendMetricStore: TrendMetricStore,
  sqUtilities: UtilitiesService,
  sqWorksheetView: WorksheetViewService
) {
  const service = {
    apply
  };

  /**
   * The collection of upgrade functions, one per version. Each accepts state from that version and returns the
   * migrated state. It is acceptable for the function to mutate the object since that can often be more performant
   * on large state objects.
   */
  const upgraders = {
    upgrade1,
    upgrade2,
    upgrade3,
    upgrade4,
    upgrade5,
    upgrade6,
    upgrade7,
    upgrade8,
    upgrade9,
    upgrade10,
    upgrade11,
    upgrade12,
    upgrade13,
    upgrade14,
    upgrade15,
    upgrade16,
    upgrade17,
    upgrade18,
    upgrade19,
    upgrade20,
    upgrade21,
    upgrade22,
    upgrade23,
    upgrade24,
    upgrade25,
    // CRAB-24258: A backport added upgrade26 to R50.3.0 which prevented a different upgrade26 function in R51.0.0
    // and greater from executing. We fix this by ensuring the logic for upgrades 26 - 31 is idempotent and moving
    // those upgrades to later workstep schema versions to ensure they all execute.
    upgrade26: _.identity,
    upgrade27: _.identity,
    upgrade28: _.identity,
    upgrade29: _.identity,
    upgrade30: _.identity,
    upgrade31: _.identity,
    upgrade32, // previously upgrade26
    upgrade33, // previously upgrade27
    upgrade34, // previously upgrade28
    upgrade35, // previously upgrade29
    upgrade36, // previously upgrade30
    upgrade37, // previously upgrade31
    upgrade38,
    upgrade39,
    upgrade40: _.identity, // CRAB-27022 added an upgrade to 53, so this is necessary for forward compatibility
    upgrade41,
    upgrade42
  };

  return service;

  /**
   * Upgrade the state of a workstep from the specified version to the latest version. Runs the state through a series
   * of transform functions, in order, from the state's version to the specified version.
   *
   * @param {Object} state - The workstep state that is result of dehydrating all the stores.
   * @param {Number} fromVersion - The version number from when the state was created
   * @param {Number} [toVersion=WORKSTEP_SCHEMA_VERSION] - The version number up to which the state will be migrated.
   * Useful only for testing.
   * @returns {Object} The transformed state.
   */
  function apply(state, fromVersion, toVersion?) {
    toVersion = toVersion || WORKSTEP_SCHEMA_VERSION;
    return _.reduce(_.range(fromVersion, toVersion, 1), (newState, newVersion) =>
      newState.then(state => upgraders['upgrade' + newVersion](state)), $q.resolve(state));
  }

  /**
   * Migrates `dataView` to `viewKey` on sqWorksheetStore
   *
   * @param {Object} state - The workstep state that is result of dehydrating all the stores.
   */
  function upgrade1(state) {
    const dataView = _.get(state, 'stores.sqWorksheetStore.dataView') as string;
    if (_.includes(['trend', 'explore'], dataView)) {
      state.stores.sqWorksheetStore.viewKey = dataView.toUpperCase();
      delete state.stores.sqWorksheetStore.dataView;
    }

    return state;
  }

  /**
   * Migrates "keywords" to "nameFilter" on sqSearchStore
   *
   * @param {Object} state - The workstep state that is result of dehydrating all the stores.
   */
  function upgrade2(state) {
    const keywords = _.get(state, 'stores.sqSearchStore.keywords');
    if (!_.isEmpty(keywords)) {
      state.stores.sqSearchStore.nameFilter = keywords;
      delete state.stores.sqSearchStore.keywords;
    }

    return state;
  }

  /**
   * Upgrades all 'calculation' properties in sqTrendStore.enabledColumns.CAPSULES to 'formula', changing the
   * contents from '@.' to '$series.'
   *
   * @param {Object} state - The workstep state that is result of dehydrating all the stores.
   */
  function upgrade3(state) {
    const path = ['stores', 'sqTrendStore', 'enabledColumns', 'CAPSULES'];
    const capsuleColumns = _.get(state, path);
    _.forEach(capsuleColumns, function(value, key) {
      if (value.calculation) {
        _.set(state, path.concat(key, 'formula'), _.replace(value.calculation, '@', '$series'));
        _.unset(state, path.concat(key, 'calculation'));
      }
    });

    return state;
  }

  /**
   * Assigns each and every series to its own lane.
   *
   * @param {Object} state - The workstep state that is result of dehydrating all the stores.
   */
  function upgrade4(state) {
    const items = _.get(state, 'stores.sqTrendSeriesStore.items') as any;
    const seriesItems = _.reject(items, 'isSeriesFromCapsule');
    const seriesFromCapsuleItems = _.filter(items, 'isSeriesFromCapsule');
    const alignmentOptions = _.times(26, function(idx) {
      return String.fromCharCode(idx + 65);
    });

    _.forEach(seriesItems, function(item: any, idx) {
      if (_.isUndefined(item.lane)) {
        item.lane = idx + 1;
      }

      if (_.isUndefined(item.axisAlign)) {
        item.axisAlign = alignmentOptions[idx];
      }
    });

    _.set(state, 'stores.sqTrendSeriesStore.items', _.concat(seriesItems, seriesFromCapsuleItems));
    return state;
  }

  /**
   * Migrates to optional 'startTime' column in sqTrendStore
   * Migrates 'anchorId` to `referenceSeries' in sqTrendStore
   * Removes unnecessary properties from enabledColumn definitions
   * Previously, the statistic columns and the property columns would be stored with
   * the entire statistic definition from `TREND_SIGNAL_STATS`. This means that if the
   * statistic column definition changed the data in the store would be stale.
   *
   * @param {Object} state - The workstep state that is result of dehydrating all the stores.
   */
  function upgrade5(state) {
    // propertyColumns is used as an indicator that changes are needed because it did not exist in v3
    if (!_.isUndefined(_.get(state, 'stores.sqTrendStore.propertyColumns'))) {
      return state;
    }

    // _.set will create the `CAPSULES` object if no columns were ever enabled
    _.set(state, 'stores.sqTrendStore.enabledColumns.CAPSULES.startTime', true);
    const enabledColumns = _.get(state, 'stores.sqTrendStore.enabledColumns.CAPSULES');

    _.forEach(_.filter(enabledColumns, _.isObject), function(column) {
      enabledColumns[column.key] = {
        key: column.key,
        statisticKey: _.join(_.split(column.key, '.', 2), '.'), // statistic.max.1234 -> statistic.max
        referenceSeries: column.anchorId
      };
    });

    state.stores.sqTrendStore.propertyColumns = {};

    return state;
  }

  /**
   * Migrates tools that were in RESULTS mode to the new overview tool since RESULTS mode is gone.
   *
   * @param {Object} state - The workstep state that is result of dehydrating all the stores.
   */
  function upgrade6(state) {
    const displayMode = _.get(state, 'stores.sqInvestigateStore.displayMode');
    if (displayMode === 'RESULTS') {
      state.stores.sqInvestigateStore.displayMode = 'NEW';
      state.stores.sqInvestigateStore.activeTool = 'overview';
    }

    return state;
  }

  /**
   * Add an empty autoUpdate state.
   *
   * @param {Object} state - The workstep state that is result of dehydrating all the stores.
   */
  function upgrade7(state) {
    const durationStore = _.get(state, 'stores.sqDurationStore') as any;
    if (!_.isEmpty(durationStore) && _.isUndefined(durationStore.autoUpdate)) {
      state.stores.sqDurationStore.autoUpdate = {};
    }

    return state;
  }

  /**
   * Expands the display range for ScatterPlot Capsules view to the investigate range.
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @return {Object} State to pass to the rehydration cycle
   */
  function upgrade8(state) {
    const durationStore = _.get(state, 'stores.sqDurationStore');
    const worksheetView = _.get(state, 'stores.sqWorksheetStore.viewKey');
    const scatterPlotMode = _.get(state, 'stores.sqScatterPlotStore.plotMode');
    if (!_.isEmpty(durationStore) && worksheetView === 'SCATTER_PLOT' &&
      !_.isEmpty(scatterPlotMode) && scatterPlotMode === 'CAPSULES') {
      state.stores.sqDurationStore.displayRange.start = _.get(state, 'stores.sqDurationStore.investigateRange.start');
      state.stores.sqDurationStore.displayRange.end = _.get(state, 'stores.sqDurationStore.investigateRange.end');
    }

    return state;
  }

  /**
   * Migrates custom histogram bin colors to new store name.
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @return {Object} State to pass to the rehydration cycle
   */
  function upgrade9(state) {
    const colorConfig = _.get(state, 'stores.sqTrendTableStore.colorConfig');
    if (_.isEmpty(colorConfig)) {
      return state;
    }

    state.stores.sqTrendTableStore.binConfig = _.mapValues(colorConfig, function(value) {
      return { color: value };
    });

    _.unset(state, 'stores.sqTrendTableStore.colorConfig');
    return state;
  }

  /**
   * Migrates from selectedIds to selectedCapsules. Renames editingCapsuleSetId to editingId for consistency
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @return {Object} State to pass to the rehydration cycle
   */
  function upgrade10(state) {
    const capsuleStore = _.get(state, 'stores.sqTrendCapsuleStore');

    if (!_.isEmpty(capsuleStore)) {
      if (state.stores.sqTrendCapsuleStore.editingCapsuleSetId) {
        state.stores.sqTrendCapsuleStore.editingId = state.stores.sqTrendCapsuleStore.editingCapsuleSetId;
        delete state.stores.sqTrendCapsuleStore.editingCapsuleSetId;
      }

      delete state.stores.sqTrendCapsuleStore.selectedIds;
    }

    return state;
  }

  /**
   * Migrates the search store mode
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @return {Object} State to pass to the rehydration cycle
   */
  function upgrade11(state) {
    _.forEach(['sqSearchStore', 'sqModalSearchStore'], (store) => {
      if (_.get(state, `stores.${store}.isAssetBrowsing`) && _.get(state, `stores.${store}.currentAsset`)) {
        _.set(state, `stores.${store}.mode`, 'tree');
      } else if (_.get(state, `stores.${store}.nameFilter`)) {
        _.set(state, `stores.${store}.mode`, 'search');
      }

      _.unset(state, `stores.${store}.isAssetBrowsing`);
    });

    return state;
  }

  /**
   * Updates Duration.store to rename 'offset' to 'displayRangeEndOffset' and updates the MODE if the auto update isn't
   * enabled.
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @return {Object} State to pass to the rehydration cycle
   */
  function upgrade12(state) {
    if (!_.isEmpty(state.stores.sqDurationStore)) {
      if (!_.isNil(state.stores.sqDurationStore.autoUpdate.enabled)) {
        if (state.stores.sqDurationStore.autoUpdate.enabled === false) {
          state.stores.sqDurationStore.autoUpdate.mode = 'OFF';
        }
        delete state.stores.sqDurationStore.autoUpdate.enabled;
      }
    }
    return state;
  }

  /**
   * Updates the BaseSignalStore to rename 'samples' to 'sampleDisplayOption'.
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @return {Object} State to pass to the rehydration cycle
   */
  function upgrade13(state) {
    const items = _.get(state, 'stores.sqTrendSeriesStore.items') as any;
    const seriesItems = _.reject(items, 'isSeriesFromCapsule');
    const seriesFromCapsuleItems = _.filter(items, 'isSeriesFromCapsule');
    const updatedSeriesItems = [];
    _.forEach(seriesItems, function(item: any) {
      if (_.has(item, 'samples')) {
        item.sampleDisplayOption = item.samples;
        item = _.omit(item, 'samples');
      }
      updatedSeriesItems.push(item);
    });

    _.set(state, 'stores.sqTrendSeriesStore.items', _.concat(updatedSeriesItems, seriesFromCapsuleItems));

    return state;
  }

  /**
   * Previously, when the custom condition tool was opened the capsules were stored in the custom condition store. This
   * upgrade step moves them to the sqCapsuleGroup store that previously didn't exist.
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @return {Object} State to pass to the rehydration cycle
   */
  function upgrade14(state) {
    if (!_.isEmpty(state.stores.sqCustomConditionStore)) {
      if (!_.isNil(state.stores.sqCustomConditionStore.capsules)) {
        _.set(state.stores, ['sqCapsuleGroupStore', 'capsules'], state.stores.sqCustomConditionStore.capsules);
        delete state.stores.sqCustomConditionStore.capsules;
      }
    }
    return state;
  }

  /**
   * Removes the `displayFavorites` property (which has been replaced in favor of `displaySelector` This will
   * effectively reset to the "All" view
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @return {Object} State to pass to the rehydration cycle
   */
  function upgrade15(state) {
    if (!_.isEmpty(state.stores.sqWorkbenchStore)) {
      if (!_.isNil(_.get(state, 'stores.sqWorkbenchStore.displayFavorites'))) {
        delete state.stores.sqWorkbenchStore.displayFavorites;
      }
    }
    return state;
  }

  /**
   * Updates the sqReportStore duration object to a number
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @return {Object} State to pass to the rehydration cycle
   */
  function upgrade16(state) {

    if (!_.isEmpty(state.stores.sqReportStore)) {
      const path = ['stores', 'sqReportStore', 'dateVariables'];
      const dateVariables = _.get(state, path);

      _.forEach(dateVariables, function(dateVariable) {
        if (!_.isNil(dateVariable.auto)) {
          if (!_.isNil(dateVariable.auto.duration)) {
            if (!_.isNil(dateVariable.auto.duration.value)) {
              const newValue = moment.duration(dateVariable.auto.duration.value,
                sqDateTime.momentMeasurementStrings(dateVariable.auto.duration.units));

              dateVariable.auto.duration = newValue.valueOf();
            }
          }
        }
      });

      _.set(state, 'stores.sqReportStore.dateVariables', dateVariables);

    }

    return state;
  }

  /**
   * Updates sqPeriodicConditionStore timezone property from offset to name
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @returns {Object} State to pass to the rehydration cycle
   */
  function upgrade17(state) {
    const offset = _.get(state.stores, 'sqPeriodicConditionStore.timezone');
    if (offset) {
      state.stores.sqPeriodicConditionStore.timezone = _.get(sqTimezones.findMostCommonTimeZone(offset), 'name');
    }

    return state;
  }

  /**
   * Removes ancillaries from signal stores, the ancillaries used to be persisted. This migrates them
   * to a displayedAncillaryItemIds property. In the future the ancillaries will be loaded dynamically
   * if they exist in this array.
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @returns {Object} State to pass to the rehydration cycle
   */
  function upgrade18(state) {
    const displayedAncillaryItemIdsMapping = {};
    const sqTrendSeriesStore = _.get(state.stores, 'sqTrendSeriesStore');
    const sqTrendScalarStore = _.get(state.stores, 'sqTrendScalarStore');
    _.forEach([sqTrendSeriesStore, sqTrendScalarStore], (sqSignalStore) => {
      if (!sqSignalStore || !sqSignalStore.items) {
        return;
      }

      const [ancillaries, items] = _.partition<any>(sqSignalStore.items, 'isChildOf');
      sqSignalStore.items = items;
      _.forEach(ancillaries, (item) => {
        const displayedAncillaryItemIds = _.get(displayedAncillaryItemIdsMapping, item.isChildOf, []);
        displayedAncillaryItemIds.push(item.id);
        _.set(displayedAncillaryItemIdsMapping, item.isChildOf, displayedAncillaryItemIds);
      });
    });

    // Note: scalars can be ancillaries, but cannot have ancillaries
    _.forEach(_.get(sqTrendSeriesStore, 'items'), (item) => {
      const displayedAncillaryItemIds = displayedAncillaryItemIdsMapping[item.id];
      if (displayedAncillaryItemIds) {
        item.displayedAncillaryItemIds = displayedAncillaryItemIds;
      }
    });

    return state;
  }

  /**
   * Updates topic date ranges to have the "auto" property which came with R20 and live docs.
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @returns {Object} State to pass to the rehydration cycle
   */
  function upgrade19(state) {
    const dateVariables = _.get<any[]>(state.stores, 'sqReportStore.dateVariables', []);
    _.forEach(dateVariables, (variable) => {
      if (!_.has(variable, 'auto')) {
        variable.auto = { enabled: false };
      }
    });

    if (!_.isEmpty(dateVariables)) {
      state.stores.sqReportStore.dateVariables = dateVariables;
    }

    return state;
  }

  /**
   * Removes duplicate items from the details pane after a backend upgrade that replaces duplicates with the keeper
   * in worksteps. See CRAB-13226.
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @returns {Object} State to pass to the rehydration cycle
   */
  function upgrade20(state) {
    if (_.has(state.stores, 'sqTrendSeriesStore.items')) {
      state.stores.sqTrendSeriesStore.items = _.uniqBy(state.stores.sqTrendSeriesStore.items, 'id');
    }

    if (_.has(state.stores, 'sqTrendScalarStore.items')) {
      state.stores.sqTrendScalarStore.items = _.uniqBy(state.stores.sqTrendScalarStore.items, 'id');
    }

    if (_.has(state.stores, 'sqTrendCapsuleSetStore.items')) {
      state.stores.sqTrendCapsuleSetStore.items = _.uniqBy(state.stores.sqTrendCapsuleSetStore.items, 'id');
    }

    return state;
  }

  /**
   * Removes series from capsules that were stored in the trend series store. Before Seeq R21.0.40.00 series from
   * capsule segments needed to be preserved in the workstep because they represented whether one of the capsule
   * time "eyeballs" was selected. Since the "eyeball" mechanism was removed (CRAB-11107), we don't need to the
   * segments to maintain that state.
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @returns {Object} State to pass to the rehydration cycle
   */
  function upgrade21(state) {
    if (_.has(state.stores, 'sqTrendSeriesStore.items')) {
      state.stores.sqTrendSeriesStore.items = _.reject(state.stores.sqTrendSeriesStore.items, 'isChildOf');
    }

    return state;
  }

  /**
   * Renames sqTrendStore.hideDimmedCapsules to sqTrendStore.hideUnselectedItems
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @returns {Object} State to pass to the rehydration cycle
   */
  function upgrade22(state) {
    if (_.has(state.stores, 'sqTrendStore.hideDimmedCapsules')) {
      state.stores.sqTrendStore.hideUnselectedItems = state.stores.sqTrendStore.hideDimmedCapsules;
      delete state.stores.sqTrendStore.hideDimmedCapsules;
    }

    return state;
  }

  /**
   * Adds an id to scorecard columns.
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @returns {Object} State to pass to the rehydration cycle
   */
  function upgrade23(state) {
    if (_.has(state.stores, 'sqTrendMetricStore.scorecardColumns')) {
      state.stores.sqTrendMetricStore.scorecardColumns = _.map(state.stores.sqTrendMetricStore.scorecardColumns,
        column => ({ id: sqUtilities.base64guid(), ...column }));
    }

    return state;
  }

  /**
   * Upgrades empty view-region to the new version of it
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @returns {Object} State to pass to the rehydration cycle
   */
  function upgrade24(state) {
    const oldXyRegion = _.pick(EMPTY_XYREGION, ['xMin', 'xMax', 'yMin', 'yMax']);
    _.forEach(['selectedRegion', 'viewRegion'], (region) => {
      if (_.isEqual(_.get(state.stores, `sqScatterPlotStore.${region}`), oldXyRegion)) {
        state.stores.sqScatterPlotStore[region] = EMPTY_XYREGION;
      }
    });

    return state;
  }

  /**
   * Migrates item detail panes that used the standardDeviation stat to the stdDev stat.
   *
   * @param {Object} state - The workstep state that is result of dehydrating all the stores.
   */
  function upgrade25(state) {
    const stdDev = _.get(state.stores, 'sqTrendStore.enabledColumns.SERIES[\'statistics.standardDeviation\']');
    if (stdDev) {
      delete state.stores.sqTrendStore.enabledColumns.SERIES['statistics.standardDeviation'];
      state.stores.sqTrendStore.enabledColumns.SERIES['statistics.stdDev'] = true;
    }
    return state;
  }

  /**
   * Changes SCORECARD view to TABLE and moves scorecard properties to table builder. If in TABLE view and items are
   * selected it unselects all items so that user will see all the metrics, not just the selected ones (to preserve
   * previous behavior where selection did not influence the table). Moves the scorecardOrder to lane now that order
   * is dictated by the details pane.
   *
   * @param {Object} state - The workstep state that is result of dehydrating all the stores.
   */
  function upgrade32(state) {
    const viewKey = _.get(state.stores, 'sqWorksheetStore.viewKey');
    if (viewKey === 'SCORECARD') {
      state.stores.sqWorksheetStore.viewKey = WORKSHEET_VIEW.TABLE;
      let maxLane = 0;
      const selectedIds = [];
      _.forEach([
        'sqTrendSeriesStore',
        'sqTrendCapsuleSetStore',
        'sqTrendMetricStore',
        'sqTrendScalarStore',
        'sqTrendTableStore',
        'sqTrendCapsuleStore'
      ], (storeName) => {
        _.forEach(_.get(state.stores[storeName], 'items'), (item, index) => {
          if (storeName !== 'sqTrendMetricStore' && item.lane > maxLane) {
            maxLane = item.lane;
          }

          if (item.selected) {
            selectedIds.push(item.id);
            state.stores[storeName].items[index].selected = false;
          }
        });
      });
      if (selectedIds.length) {
        state.stores.sqWorksheetStore.selectedIdsForView = { [sqWorksheetView.getDefault().selectedItemsRealm]: selectedIds };
      }

      _.forEach(state.stores.sqTrendMetricStore?.items, (item, index) => {
        state.stores.sqTrendMetricStore.items[index].lane = item.scorecardOrder + maxLane + 1;
      });

      if (!_.isUndefined(state.stores.sqTrendStore?.panelSorters)) {
        state.stores.sqTrendStore.panelSorters.SERIES = TREND_PANELS_SORT.SERIES;
      }
    }

    if (_.isUndefined(state.stores.sqTableBuilderStore)) {
      state.stores.sqTableBuilderStore = {};
    }

    if (_.has(state.stores, 'sqTrendMetricStore.scorecardColumns')) {
      state.stores.sqTableBuilderStore.columns = state.stores.sqTrendMetricStore.scorecardColumns;
      delete state.stores.sqTrendMetricStore.scorecardColumns;
    }

    if (_.has(state.stores, 'sqTrendMetricStore.scorecardHeaders')) {
      state.stores.sqTableBuilderStore.headers = state.stores.sqTrendMetricStore.scorecardHeaders;
      delete state.stores.sqTrendMetricStore.scorecardHeaders;
    }

    return state;
  }

  /**
   * Sets the activeTool to "overview" for deprecated tools
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @returns {Object} State to pass to the rehydration cycle
   */
  function upgrade33(state) {
    if (_.includes(DEPRECATED_TOOL_NAMES, state?.stores?.sqInvestigateStore?.activeTool)) {
      state.stores.sqInvestigateStore.activeTool = 'overview';
    }

    return state;
  }

  /**
   * Upgrades the table builder store state to the new structure which supports both simple and condition tables
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @returns {Object} State to pass to the rehydration cycle
   */
  function upgrade34(state) {
    if (!_.isUndefined(state.stores.sqTableBuilderStore) && !_.isEmpty(state.stores.sqTableBuilderStore)) {
      state.stores.sqTableBuilderStore.mode = TableBuilderMode.Condition;
      if (_.has(state.stores, 'sqTableBuilderStore.columns') &&
        !_.has(state.stores, `sqTableBuilderStore.columns.${TableBuilderMode.Condition}`)) {
        state.stores.sqTableBuilderStore.columns = {
          [TableBuilderMode.Condition]: state.stores.sqTableBuilderStore.columns,
          [TableBuilderMode.Simple]: _.map(SERIES_PANEL_REQUIRED_TREND_COLUMNS, key => ({ key }))
        };
      }
      if (_.has(state.stores, 'sqTableBuilderStore.isTransposed') &&
        !_.has(state.stores, `sqTableBuilderStore.isTransposed.${TableBuilderMode.Condition}`)) {
        state.stores.sqTableBuilderStore.isTransposed = {
          [TableBuilderMode.Condition]: state.stores.sqTableBuilderStore.isTransposed,
          [TableBuilderMode.Simple]: false
        };
      }
    }
    return state;
  }

  /**
   * Upgrades the table builder store state to include the new variations in header options
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @returns {Object} State to pass to the rehydration cycle
   */
  function upgrade35(state) {
    if (!_.isUndefined(state.stores.sqTableBuilderStore) && !_.isEmpty(state.stores.sqTableBuilderStore)) {
      // skip if the table already have the new header format.
      // This upgrade was not executed until WORKSTEP_SCHEMA_VERSION = 37
      if (_.has(state.stores, 'sqTableBuilderStore.headers') &&
        _.isNil(state.stores.sqTableBuilderStore.headers[TableBuilderMode.Simple])) {
        state.stores.sqTableBuilderStore.headers = {
          [TableBuilderMode.Condition]: state.stores.sqTableBuilderStore.headers,
          [TableBuilderMode.Simple]: {
            type: TableBuilderHeaderType.StartEnd,
            format: 'lll'
          }
        };
      }
    }
    return state;
  }

  /**
   * Switches from condition to simple mode if the table builder store contains simple metrics and migrate the
   * content which can be displayed in the simple table. If the old table contains only simple metrics the user
   * should see no change after migration.
   * The decision to switch to simple mode is done based on the type of first metric. Checking metric type is an
   * expensive operation because it triggers an API request. Checking the type of all conditions might take too much
   * time and worksheet load will be slow every time until the user is changing it and a new workstep is created. This
   * will have a bad impact on tables used it documents (screenshots).
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @returns {Object} State to pass to the rehydration cycle
   */
  function upgrade36(state) {
    const worksheetView = _.get(state, 'stores.sqWorksheetStore.viewKey');
    const tableStore = _.get(state, 'stores.sqTableBuilderStore');

    // Convert capsule property Value header to Value (Original)
    const capsuleProperty = tableStore && _.get(state.stores,
      `sqTableBuilderStore.headers[${TableBuilderMode.Condition}].property`);
    if (sqUtilities.equalsIgnoreCase(capsuleProperty, SeeqNames.CapsuleProperties.Value)) {
      state.stores.sqTableBuilderStore.headers[TableBuilderMode.Condition].property = `${SeeqNames.CapsuleProperties.Value} (Original)`;
    }

    if (worksheetView === 'TABLE' && tableStore && tableStore.mode === TableBuilderMode.Condition) {
      const trendMetricItems = _.get(state, 'stores.sqTrendMetricStore.items');
      const firstMetric = _.first(_.orderBy(trendMetricItems, 'selected'));
      if (!firstMetric) {
        return state;
      }

      return sqMetricsApi.getMetric({ id: firstMetric.id }).then(({ data: definition }) => {
        if (definition?.processType === ProcessTypeEnum.Simple) {
          tableStore.mode = TableBuilderMode.Simple;
          tableStore.headers[TableBuilderMode.Simple] = tableStore.headers[TableBuilderMode.Condition];

          // if isTransposed was not initialized until now, add the field in the store.
          if (_.has(state.stores, 'sqTableBuilderStore.isTransposed')) {
            tableStore.isTransposed[TableBuilderMode.Simple] = tableStore.isTransposed[TableBuilderMode.Condition];
          } else {
            tableStore.isTransposed = {
              [TableBuilderMode.Condition]: false,
              [TableBuilderMode.Simple]: false
            };
          }

          let nameColumnFound = false;
          // transfer only the first 'name' column and all free text columns to simple mode
          tableStore.columns[TableBuilderMode.Simple] = _.chain(tableStore.columns[TableBuilderMode.Condition])
            .filter((column) => {
              if (column.type === COLUMNS_AND_STATS.name.key) {
                if (!nameColumnFound) {
                  nameColumnFound = true;
                  return true;
                }
                return false;
              } else {
                return true;
              }
            })
            .map((column) => {
              switch (column.type) {
                case COLUMNS_AND_STATS.name.key:
                  return { ..._.pick(column, ['backgroundColor', 'header']), key: COLUMNS_AND_STATS.name.key };
                case TableBuilderColumnType.Text:
                  return { ..._.pick(column, ['backgroundColor', 'header', 'cells', 'type']), key: column.id };
              }
            })
            .value();

          tableStore.columns[TableBuilderMode.Condition] = [];

          // add metric value column as the last column and set headerOverridden flag
          tableStore.columns[TableBuilderMode.Simple].push(
            {
              key: 'metricValue',
              headerOverridden: true
            });

          // Select all metrics if none is selected and we have other items in the details pane.
          // When upgrading from condition to simple mode we want to see only metrics in the table by default and no
          // other item.
          const noMetricSelected = !_.some(trendMetricItems, { selected: true });
          const otherItemsPresent = _.get(state, 'stores.sqTrendCapsuleSetStore.items.length')
            || _.get(state, 'stores.sqTrendScalarStore.items.length')
            || _.get(state, 'stores.sqTrendSeriesStore.items.length');
          if (noMetricSelected && otherItemsPresent) {
            _.forEach(trendMetricItems, item => item.selected = true);
          }
        }
        return state;
      });
    }
    return state;
  }

  /**
   * Adds the default style to all headers in simple and condition mode. and converts columns to the new format.
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @returns {Object} State to pass to the rehydration cycle
   */
  function upgrade37(state) {
    const tableStore = _.get(state, 'stores.sqTableBuilderStore');
    if (!_.isUndefined(tableStore) && !_.isEmpty(tableStore)) {
      if (_.has(tableStore, ['columns', TableBuilderMode.Simple])) {
        _.forEach(tableStore.columns[TableBuilderMode.Simple], (column, index) => {
          state.stores.sqTableBuilderStore.columns[TableBuilderMode.Simple][index] = column.isCustomProperty ? {
            ..._.omit(column, ['isCustomProperty']),
            type: TableBuilderColumnType.Property,
            headerTextAlign: 'center',
            headerTextStyle: ['bold']
          } : {
            ...column,
            headerTextAlign: 'center',
            headerTextStyle: ['bold']
          };
        });
      }

      if (_.has(tableStore, ['columns', TableBuilderMode.Condition])) {
        _.forEach(state.stores.sqTableBuilderStore.columns[TableBuilderMode.Condition], (column, index) => {
          state.stores.sqTableBuilderStore.columns[TableBuilderMode.Condition][index] =
            column.type === TableBuilderColumnType.Text ? {
              ..._.omit(column, ['id', 'shortTitle']),
              key: column.id || column.key,
              headerTextAlign: 'center',
              headerTextStyle: ['bold']
            } : {
              ..._.omit(column, ['id', 'shortTitle', 'type']),
              key: column.type || column.key,
              headerTextAlign: 'center',
              headerTextStyle: ['bold']
            };
        });
      }
    }

    return state;
  }

  /**
   * Accommodates the new ability for table builder to include conditions. Selects only metrics if user is in condition
   * table builder to ensure it looks the same.
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @returns {Object} State to pass to the rehydration cycle
   */
  function upgrade38(state) {
    const tableStoreMode = _.get(state, 'stores.sqTableBuilderStore.mode');
    const viewKey = _.get(state.stores, 'sqWorksheetStore.viewKey');
    if (viewKey === WORKSHEET_VIEW.TABLE && tableStoreMode === TableBuilderMode.Condition) {
      const trendMetricItems = _.get(state, 'stores.sqTrendMetricStore.items');
      const metricSelected = _.some(trendMetricItems, 'selected');
      const conditionItems = _.get(state, 'stores.sqTrendCapsuleSetStore.items');
      if (!metricSelected && !_.isEmpty(conditionItems)) {
        _.forEach(trendMetricItems, (item, index) => {
          state.stores.sqTrendMetricStore.items[index].selected = true;
        });
      } else if (metricSelected && _.some(conditionItems, 'selected')) {
        _.forEach(conditionItems, (item, index) => {
          state.stores.sqTrendCapsuleSetStore.items[index].selected = false;
        });
      }
    }

    return state;
  }

  /**
   * Adds the new sort and filter criteria to existing table builder worksteps.
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @returns {Object} State to pass to the rehydration cycle
   */
  function upgrade39(state) {
    if (!_.isUndefined(state.stores.sqTableBuilderStore) && !_.isEmpty(state.stores.sqTableBuilderStore)) {
      if (_.isNil(state.stores.sqTableBuilderStore.itemFilters)) {
        state.stores.sqTableBuilderStore.itemFilters = {};
      }
      if (_.isNil(state.stores.sqTableBuilderStore.itemSorts)) {
        state.stores.sqTableBuilderStore.itemSorts = {};
      }
    }
    return state;
  }

  /**
   * Removes condition and signal entries from the conditionToSeriesGrouping if those conditions are not present
   * in the details pane. Previously there were cases where conditions and signals that were removed from the details
   * pane were not also removed from the conditionToSeriesGrouping. See CRAB-25536.
   *
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @returns {Object} State to pass to the rehydration cycle
   */
  function upgrade41(state) {
    const conditionToSeriesGrouping = _.get(state, 'stores.sqWorksheetStore.conditionToSeriesGrouping');

    if (!_.isEmpty(conditionToSeriesGrouping)) {
      const detailsPaneConditionIds = _.chain(state)
        .get('stores.sqTrendCapsuleSetStore.items')
        .map('id')
        .value();

      const detailsPaneSignalIds = _.chain(state)
        .get('stores.sqTrendSeriesStore.items')
        .map('id')
        .value();

      const cleanedConditionToSeriesGrouping = _.chain(conditionToSeriesGrouping)
        .pickBy((signals, conditionId) => _.includes(detailsPaneConditionIds, conditionId))
        .mapValues(signals => _.intersection(signals, detailsPaneSignalIds))
        .value();

      _.set(state, 'stores.sqWorksheetStore.conditionToSeriesGrouping', cleanedConditionToSeriesGrouping);
    }

    return state;
  }

  /**
   * Sets conditionTable.isTransposed to false on existing worksteps to prevent changing existing tables
   * @param {Object} state - Workstep state that is the result of dehydrating all of the stores.
   * @returns {Object} State to pass to the rehydration cycle
   */
  function upgrade42(state) {
    if (_.isUndefined(state.stores.sqTableBuilderStore?.isTransposed?.[TableBuilderMode.Condition])) {
      _.set(state, `stores.sqTableBuilderStore.isTransposed.${[TableBuilderMode.Condition]}`, false);
    }
    return state;
  }
}
