import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { TrackService } from '@/track/track.service';
import { InvestigateStore } from '@/investigate/investigate.store';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { ITEM_TYPES } from '@/trendData/trendData.module';
import { ToolPanelHelperService } from '@/services/toolPanelHelper.service';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { DEBOUNCE, DISPLAY_MODE } from '@/main/app.constants';
import { FORM_ERROR, FORM_WARNING, FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { CompositeSearchActions } from '@/hybrid/tools/compositeSearch/compositeSearch.actions';
import { CompositeSearchStore } from '@/hybrid/tools/compositeSearch/compositeSearch.store';
import { TrendActions } from '@/trendData/trend.actions';
import { LOGIC, LOGIC_KEY } from '@/hybrid/tools/compositeSearch/compositeSearch.module';
import { useDebounce } from '@/hybrid/core/useDebounce.hook';
import { ErrorTypeEnum } from 'sdk/model/FormulaErrorOutputV1';

const compositeSearchBindings = bindingsDefinition({
  sqTrendCapsuleStore: injected<TrendCapsuleStore>(),
  sqCompositeSearchActions: injected<CompositeSearchActions>(),
  sqCompositeSearchStore: injected<CompositeSearchStore>(),
  sqToolPanelHelper: injected<ToolPanelHelperService>(),
  sqInvestigateStore: injected<InvestigateStore>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqTrack: injected<TrackService>(),
  sqTrendActions: injected<TrendActions>()
});

export const CompositeSearch: SeeqComponent<typeof compositeSearchBindings> = () => {
  const {
    sqInvestigateActions,
    sqCompositeSearchActions,
    sqCompositeSearchStore,
    sqInvestigateStore,
    sqTrendCapsuleStore,
    sqTrendActions,
    sqTrack
  } = useInjectedBindings(compositeSearchBindings);
  const { t } = useTranslation();

  const [color, setColor] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  const displayMode = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.displayMode);
  const item = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.item);

  const {
    id,
    name,
    originalParameters,
    maximumDuration,
    conditionA,
    conditionB,
    inclusiveA,
    inclusiveB,
    maximumDurationOverrideA,
    maximumDurationOverrideB,
    maximumDurationOverrideRequiredA,
    maximumDurationOverrideRequiredB,
    maximumDurationRequired,
    selectedOperator,
    isUpgradedUnionCondition,
    formulaWithParameters
  } = useFlux(sqCompositeSearchStore);

  const uniqueConditionViolation = conditionA && conditionB && conditionA.id === conditionB.id;
  const selectedLogic = _.find(LOGIC, { key: selectedOperator });
  const showInclusivityParameters = selectedOperator === LOGIC_KEY.JOIN;
  const upgradedUnionCondition = isUpgradedUnionCondition && selectedOperator === LOGIC_KEY.UNION;

  const runPreview = useDebounce(() => {
    if (!isSearching && isFormValid && !_.isUndefined(formulaWithParameters)
      && sqInvestigateStore.activeTool === TREND_TOOLS.COMPOSITE_SEARCH) {
      if (_.isNil(sqTrendCapsuleStore.editingId)) {
        sqTrendActions.setEditModeForCapsuleSet(id);
      }

      runCompositeSearch(true);
    }
  }, DEBOUNCE.PREVIEW);

  useEffect(() => {
    return () => {
      sqTrendActions.removePreviewCapsules();
      sqTrendActions.cancelPreviewCapsules();
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(runPreview, DEBOUNCE.PREVIEW);
    return () => clearTimeout(timer);
  }, [
    id,
    name,
    originalParameters,
    maximumDuration,
    conditionA,
    conditionB,
    inclusiveA,
    inclusiveB,
    maximumDurationOverrideA,
    maximumDurationOverrideB,
    maximumDurationOverrideRequiredA,
    maximumDurationOverrideRequiredB,
    maximumDurationRequired,
    selectedOperator,
    isUpgradedUnionCondition
  ]);

  const runCompositeSearch = (preview) => {
    const { formula, parameters } = formulaWithParameters;

    if (preview) {
      return sqTrendActions.generatePreviewCapsules(formula, parameters, id, color);
    } else {
      setIsSearching(true);
      runPreview.cancel();
      sqTrendActions.removePreviewCapsules();
      return sqCompositeSearchActions.run(name, formula, parameters, color)
        .then(() => {
          sqTrack.doTrack('Workbench_Tool', 'Composite Condition', 'completed');
        })
        .catch(() => {
          sqTrack.doTrack('Workbench_Tool', 'Composite Condition', 'error');
        })
        .finally(() => setIsSearching(false));
    }
  };

  const formDataSetup: FormElement[] = [{
    component: 'SearchTitleFormComponent',
    name: 'compositeSearchTitle',
    value: name,
    onChange: name => sqInvestigateActions.setSearchName(TREND_TOOLS.COMPOSITE_SEARCH, name),
    id,
    onColorChange: setColor,
    searchIconClass: 'fc-search-logic',
    defaultName: 'COMPOSITE_SEARCH.HEADER'
  }, {
    component: 'FormGroup',
    name: 'conditionsFormGroup',
    displayNumber: true,
    components: [{
      component: 'LabelFormComponent',
      name: 'conditionsLabel',
      value: 'COMPOSITE_SEARCH.SELECT_CONDITIONS'
    }, {
      component: 'FormRow',
      name: 'conditionAFormRow',
      extraClassNames: 'mb10',
      components: [{
        component: 'LabelFormComponent',
        name: 'conditionALabel',
        value: 'A',
        extraClassNames: 'flexAlignCenter mr10'
      }, {
        component: 'FormGroup',
        name: 'conditionAFormGroup',
        showBracket: true,
        components: [{
          component: 'ItemSelectFormComponent',
          name: 'conditionA',
          value: conditionA?.id,
          itemTypes: [ITEM_TYPES.CAPSULE_SET],
          additionalItems: originalParameters,
          excludedIds: id,
          extraClassNames: 'forceNoBottomMargin',
          includeMetadata: true,
          onChange: item => sqInvestigateActions.setParameterItem(TREND_TOOLS.COMPOSITE_SEARCH, 'conditionA', item)
        }, {
          component: 'MaxCapsuleDurationFormComponent',
          name: 'maximumDurationOverrideA',
          testId: 'maximumDurationOverrideA',
          includeIf: maximumDurationOverrideRequiredA,
          maxDurationRequired: true,
          value: maximumDurationOverrideA,
          extraClassNames: 'indented',
          onChange: (duration) => {
            sqCompositeSearchActions.setConditionAMaximumDurationOverride(duration);
            sqTrack.doTrack('Workbench_Tool', 'Composite Condition', 'Override $a Maximum Duration');
          }
        }]
      }]
    }, {
      component: 'IconFormComponent',
      value: 'fa-exchange',
      name: 'swap',
      testId: 'swapIcon',
      tooltip: 'COMPOSITE_SEARCH.SWAP',
      iconClasses: 'fa-rotate-90 cursorPointer swapButton',
      extraClassNames: 'flexColumnContainer mtn10 width-10',
      onIconClick: () => {
        sqCompositeSearchActions.swapConditions();
        sqTrack.doTrack('Workbench_Tool', 'Composite Condition', 'Swap Input Conditions');
      }
    }, {
      component: 'FormRow',
      name: 'conditionBFormRow',
      components: [{
        component: 'LabelFormComponent',
        name: 'conditionBLabel',
        value: 'B',
        extraClassNames: 'flexAlignCenter mr10'
      }, {
        component: 'FormGroup',
        name: 'conditionBFormGroup',
        showBracket: true,
        components: [{
          component: 'ItemSelectFormComponent',
          name: 'conditionB',
          value: conditionB?.id,
          itemTypes: [ITEM_TYPES.CAPSULE_SET],
          additionalItems: originalParameters,
          excludedIds: id,
          extraClassNames: 'forceNoBottomMargin',
          includeMetadata: true,
          onChange: item => sqInvestigateActions.setParameterItem(TREND_TOOLS.COMPOSITE_SEARCH, 'conditionB', item)
        }, {
          component: 'MaxCapsuleDurationFormComponent',
          name: 'maximumDurationOverrideB',
          includeIf: maximumDurationOverrideRequiredB,
          maxDurationRequired: true,
          value: maximumDurationOverrideB,
          extraClassNames: 'indented',
          testId: 'maximumDurationOverrideB',
          onChange: (duration) => {
            sqCompositeSearchActions.setConditionBMaximumDurationOverride(duration);
            sqTrack.doTrack('Workbench_Tool', 'Composite Condition', 'Override $b Maximum Duration');
          }
        }]
      }]
    }, {
      component: 'ErrorMessageFormComponent',
      name: 'uniqueViolationError',
      includeIf: uniqueConditionViolation,
      type: FORM_ERROR,
      value: 'FORM.TWO_CONDITIONS',
      failForm: true
    }]
  }, {
    component: 'FormGroup',
    name: 'logicFormGroup',
    displayNumber: true,
    components: [{
      component: 'CompositesSelectorFormComponent',
      name: 'logicSelector',
      label: 'COMPOSITE_SEARCH.SELECT_LOGIC_PROMPT',
      logicFormulas: LOGIC,
      value: selectedLogic,
      onChange: sqCompositeSearchActions.setOperator
    }, {
      component: 'FormGroup',
      name: 'inclusivityFormGroup',
      includeIf: showInclusivityParameters,
      components: [{
        component: 'CheckboxFormComponent',
        name: 'inclusiveA',
        id: 'inclusiveA',
        label: t('COMPOSITE_SEARCH.INCLUSIVE_A'),
        value: inclusiveA,
        onChange: () => sqCompositeSearchActions.setInclusiveA(!inclusiveA)
      }, {
        component: 'CheckboxFormComponent',
        name: 'inclusiveB',
        id: 'inclusiveB',
        label: t('COMPOSITE_SEARCH.INCLUSIVE_B'),
        value: inclusiveB,
        onChange: () => sqCompositeSearchActions.setInclusiveB(!inclusiveB)
      }]
    }]
  }, {
    component: 'FormGroup',
    name: 'outputMaximumDurationFormGroup',
    testId: 'outputMaximumDurationFormGroup',
    includeIf: maximumDurationRequired || upgradedUnionCondition,
    displayNumber: true,
    components: [{
      component: 'ValueWithUnitsFormComponent',
      onChange: ({ value, units }) => sqInvestigateActions.setMaximumDuration(TREND_TOOLS.COMPOSITE_SEARCH, value,
        units),
      min: 0,
      name: 'maximumDuration',
      testId: 'maximumDuration',
      label: 'MAXIMUM_CAPSULE_DURATION',
      tooltip: 'MAXIMUM_CAPSULE_DURATION_TOOLTIP',
      icon: 'fa-question-circle',
      value: maximumDuration,
      customErrorText: 'MAXIMUM_CAPSULE_DURATION_ERROR',
      includeRemoveButton: upgradedUnionCondition,
      removeIconOnClick: () => {
        if (upgradedUnionCondition) {
          sqInvestigateActions.setMaximumDuration(TREND_TOOLS.COMPOSITE_SEARCH, null, 'day');
          sqCompositeSearchActions.unsetUpgradedUnionCondition();
        }
      }
    }, {
      component: 'ErrorMessageFormComponent',
      name: 'upgradedUnionConditionWarning',
      includeIf: upgradedUnionCondition,
      type: FORM_WARNING,
      value: 'MAXIMUM_CAPSULE_DURATION_UNION_DEPRECATION',
      failForm: false
    }]
  }, {
    component: 'ErrorMessageFormComponent',
    name: 'capsuleDurationNoLongerRequiredWarning',
    extraClassNames: 'pl50 pr50 text-center',
    testId: 'capsuleDurationNoLongerRequiredWarning',
    includeIf: item.errorType === ErrorTypeEnum.MAXDURATIONPROHIBITED,
    type: FORM_ERROR,
    value: 'CAPSULE_DURATION.NO_LONGER_REQUIRED',
    failForm: false
  }];

  const compositeSearchBuilder = <ToolPanelFormBuilder
    formDefinition={formDataSetup}
    submitFn={() => runCompositeSearch(false)}
    closeFn={() => {
      sqTrendActions.removePreviewCapsules();
      sqTrendActions.cancelPreviewCapsules();
      sqInvestigateActions.close();
    }}
    toolName={TREND_TOOLS.COMPOSITE_SEARCH}
    toolId={TREND_TOOLS.COMPOSITE_SEARCH}
    setIsValid={setIsFormValid}
    submitBtnId="executeCompositeTool" />;

  return (displayMode === DISPLAY_MODE.NEW || displayMode === DISPLAY_MODE.EDIT) ? compositeSearchBuilder : null;
};

export const sqCompositeSearch = angularComponent(compositeSearchBindings, CompositeSearch);
