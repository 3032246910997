import React from 'react';
import _ from 'lodash';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { FormulaTextWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';

interface FormulaFamilyMember {
  documentationHref: string;
  title: string;
}

interface FormulaChild extends FormulaFamilyMember {
  children: FormulaFamilyMember[];
  shortDescription: any;
}

export interface FormulaFamilyProps {
  parents: FormulaFamilyMember[];
  children: FormulaChild[];
  requestDocumentation: (href: string) => any;
}

export const FormulaFamily: React.FunctionComponent<FormulaFamilyProps> = ({
  parents,
  children,
  requestDocumentation
}) => {
  const { t } = useTranslation();

  const isLastParent = index => _.toNumber(index) === parents.length - 1;
  const isLastChild = index => _.toNumber(index) === children.length - 1;
  const isLastGrandchild = (index, grandchildren) => _.toNumber(index) === grandchildren.length - 1;

  return (
    <>
      {/* Parents */}
      {parents?.length > 0 &&
      <>
        {parents.length === 1 && <h2>{t('FORMULA.DOCUMENTATION.OVERVIEW_PAGE')}</h2>}
        {parents.length > 1 && <h2>{t('FORMULA.DOCUMENTATION.OVERVIEW_PAGES')}</h2>}
        <ul className="pl20">
          {_.map(parents, (parent, index) => (
            <li key={index} className={isLastParent(index) ? 'mb10' : 'mb5'}>
              <a href="#" onClick={() => requestDocumentation(parent.documentationHref)}>
                {parent.title}
              </a>
            </li>
          ))}
        </ul>
      </>}

      {/* Children */}
      {children?.length > 0 &&
      <>
        {children.length === 1 && <h2>{t('FORMULA.DOCUMENTATION.DETAIL_PAGE')}</h2>}
        {children.length > 1 && <h2>{t('FORMULA.DOCUMENTATION.DETAIL_PAGES')}</h2>}
        <ul className="pl20">
          {_.map(children, (child: FormulaChild, index) => (
            <React.Fragment key={index}>
              <li className={isLastChild(index) ? 'mb10' : 'mb5'}>
                <a href="#" onClick={() => requestDocumentation(child.documentationHref)}>
                  {child.title}
                </a>
                <span>&nbsp;-&nbsp;<FormulaTextWithHTML requestDocumentation={requestDocumentation} content={child.shortDescription} /></span>
              </li>

              <ul className="pl20">
                {_.map(child.children, (grandchild, index) => (
                  <li key={index} className={isLastGrandchild(index, child.children) ? 'mb10' : 'mb5'}>
                    <a href="#" onClick={() => requestDocumentation(grandchild.documentationHref)}>
                      {grandchild.title}
                    </a>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ))}
        </ul>
      </>}
    </>
  );
};
