import _ from 'lodash';
import bind from 'class-autobind-decorator';
import { ITEM_TYPES } from '@/trendData/trendData.module';
import { ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { ScatterPlotStore } from '@/scatterPlot/scatterPlot.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { UtilitiesService } from '@/services/utilities.service';
import { FormulaToolActions } from '@/hybrid/tools/formula/formulaTool.actions';

@bind
export class ScatterPlotFxLineModalController {
  ITEM_TYPES = ITEM_TYPES;
  fxLines = [];

  constructor(
    public $scope: ng.IScope,
    public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    public sqScatterPlotActions: ScatterPlotActions,
    public sqFormulaToolActions: FormulaToolActions,
    public sqInvestigateActions: InvestigateActions,
    public sqScatterPlotStore: ScatterPlotStore,
    public sqTrendSeriesStore: TrendSeriesStore,
    public sqUtilities: UtilitiesService,
    public signalIdsToExclude) {

    $scope.$listenTo(this.sqScatterPlotStore, this.syncScatterPlotStore);
  }

  close = this.$uibModalInstance.close;
  itemIconClass = this.sqUtilities.itemIconClass;

  /**
   * Syncs the view-model with sqScatterPlotStore
   */
  syncScatterPlotStore() {
    this.fxLines = _.map(this.sqScatterPlotStore.fxLines,
      line => this.sqTrendSeriesStore.findItem(line.id));
  }

  /**
   * Adds a function of x line to the chart and closes the modal.
   *
   * @param {Object} item - The signal to add
   */
  addFxLine(item) {
    this.sqScatterPlotActions.addFxLine(item);
    this.close();
  }

  /**
   * Removes a function of x line from the chart and the list of excluded signals.
   *
   * @param {Object} item - The signal to remove
   */
  removeFxLine(item) {
    this.sqScatterPlotActions.removeFxLine(item);
    this.signalIdsToExclude = _.without(this.signalIdsToExclude, item.id);
  }

  /**
   * Closes the modal and opens the prediction tool.
   */
  openPredictionTool() {
    this.sqInvestigateActions.setActiveTool(TREND_TOOLS.PREDICTION);
    if (this.sqScatterPlotStore.xSeries) {
      this.sqInvestigateActions.setParameterItem(TREND_TOOLS.PREDICTION, 'inputSignals',
        this.sqScatterPlotStore.xSeries);
    }
    if (this.sqScatterPlotStore.ySeries) {
      this.sqInvestigateActions.setParameterItem(TREND_TOOLS.PREDICTION, 'targetSignal',
        this.sqScatterPlotStore.ySeries);
    }

    this.close();
  }

  /**
   * Closes the modal and opens the formula tool.
   */
  openFormulaTool() {
    this.sqInvestigateActions.setActiveTool(TREND_TOOLS.FORMULA);
    if (this.sqScatterPlotStore.xSeries) {
      this.sqFormulaToolActions.removeAllParameters();
      this.sqFormulaToolActions.addParameter({ identifier: 'x', item: this.sqScatterPlotStore.xSeries });
    }

    this.close();
  }
}
