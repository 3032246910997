import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { IconSelect } from '@/hybrid/core/IconSelect.molecule';
import { getFormFieldProps } from '@/hybrid/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import classNames from 'classnames';

export interface IconSelectIF extends ValidatingFormComponent <any> {
  component: 'IconSelectFormComponent';
  selectOptions: { text: string, value: any, icon?: string, label?: any }[];
  onChange: (option: { value: any }, property?: string) => void;
  onBlur?: any;
  formattedOptions?: boolean;
  className?: string;
  placeholder?: string;
  testId?: string;
  insideModal?: boolean;
  appendToBody?: boolean;
  disabled?: boolean;
  fixedWidth?: boolean;
  wrapperClasses?: string;
  isMultipleSelect?: boolean;
  isSearchable?: boolean;
  onRemove?: (option: { value: any }) => void;
  // if true component will not be memoized. this should be used SPARINGLY. Should only be necessary if you need to
  // access state that would be stale if your component is memoized (see PeriodicCondition)
  skipMemo?: boolean;
}

export const IconSelectFormComponent: React.FunctionComponent<IconSelectIF> = (props) => {
  const { name, validation, testId, fixedWidth = true } = props;

  const defaultValidation = value => _.isEmpty(value);
  const formState = useForm().getState();
  return <div className={classNames('mb8', { 'width-100percent': fixedWidth })} data-testid={testId}>
    <Field name={name} validate={validation || defaultValidation}>
      {({ input, meta }) => (
        <IconSelect
          {...getFormFieldProps(formState, input, meta, props)}
          insideModal={true} />
      )}
    </Field>
  </div>;
};

