import _ from 'lodash';
import angular from 'angular';
import workbookAccessWarningModalTemplate from '../workbook/workbookAccessWarningModal.html';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { TrackService } from '@/track/track.service';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { WorkbookStore } from '@/workbook/workbook.store';
import { TrendActions } from '@/trendData/trend.actions';
import { UtilitiesService } from '@/services/utilities.service';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { RedactionService } from '@/services/redaction.service';
import { ASSET_GROUP_VIEW, WORKSHEET_TARGET, WORKSHEET_VIEW } from '@/worksheet/worksheet.module';
import { SEARCH_TYPES, DEBOUNCE } from '@/main/app.constants';
import { SEARCH_PANES } from '@/search/search.module';
import { ModalService } from '@/services/modal.service';
import { FroalaReportContentService } from '../reportEditor/froalaReportContent.service';
import { NotificationsService } from '@/services/notifications.service';
import { PluginStore } from '@/hybrid/plugin/plugin.store';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { ReportStore } from '@/reportEditor/report.store';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';

angular.module('Sq.Worksheet').controller('WorksheetCtrl', WorksheetCtrl);

function WorksheetCtrl(
  $scope: ng.IScope,
  $state: ng.ui.IStateService,
  $timeout: ng.ITimeoutService,
  sqModal: ModalService,
  sqPendingRequests: PendingRequestsService,
  sqWorksheetStore: WorksheetStore,
  sqWorksheetActions: WorksheetActions,
  sqTrack: TrackService,
  sqWorkbenchStore: WorkbenchStore,
  sqWorkbenchActions: WorkbenchActions,
  sqWorkbookStore: WorkbookStore,
  sqTrendActions: TrendActions,
  sqInvestigateActions: InvestigateActions,
  sqUtilities: UtilitiesService,
  sqRedaction: RedactionService,
  sqHomeScreenUtilities: HomeScreenUtilitiesService,
  sqFroalaReportContent: FroalaReportContentService,
  sqSystemConfiguration: SystemConfigurationService,
  sqReportStore: ReportStore,
  sqNotifications: NotificationsService,
  sqReportEditor: ReportEditorService,
  $translate: ng.translate.ITranslateService,
  sqPluginStore: PluginStore
) {
  const vm = this;

  vm.WORKSHEET_VIEW = WORKSHEET_VIEW;
  vm.WORKSHEET_TARGET = WORKSHEET_TARGET;
  vm.SEARCH_TYPES = SEARCH_TYPES;
  vm.SEARCH_PANES = SEARCH_PANES;

  vm.setChartWidth = sqTrendActions.setChartWidth;
  vm.canModify = sqFroalaReportContent.canModifyDocument();
  vm.toggleBrowsePanel = sqWorksheetActions.toggleBrowsePanel;
  vm.changeSidebarTab = changeSidebarTab;
  vm.toggleWorksheetPanel = sqWorkbenchActions.toggleWorksheetPanel;
  vm.searchTabIndex = sqWorksheetStore.getTabset('sidebar').tabs.indexOf('search');
  vm.investigateTabIndex = sqWorksheetStore.getTabset('sidebar').tabs.indexOf('investigate');
  vm.annotateTabIndex = sqWorksheetStore.getTabset('sidebar').tabs.indexOf('annotate');
  vm.reportCommentsTabIndex = sqWorksheetStore.getTabset('sidebar').tabs.indexOf('reportComments');
  vm.reportConfigTabIndex = sqWorksheetStore.getTabset('sidebar').tabs.indexOf('reportConfig');
  vm.shouldShowRedactionBanner = shouldShowRedactionBanner;
  vm.addItem = trackAndAddItem;
  vm.displayArchivedModal = displayArchivedModal;
  vm.onResize = (height, width) => vm.smallToolbar = width < 815;
  vm.isViewMode = sqUtilities.isViewOnlyWorkbookMode;

  $scope.$listenTo(sqWorkbookStore, setWorkbookVars);
  $scope.$listenTo(sqWorksheetStore, setWorksheetVars);
  $scope.$listenTo(sqWorkbenchStore, setWorkbookVars);
  $scope.$listenTo(sqPluginStore, setWorkbookVars);
  $scope.$on('$stateChangeSuccess', setWorkbookVars);
  vm.isSelectedView = isSelectedView;
  vm.displayRedactionBanner = false;

  vm.reload = sqUtilities.reload;
  vm.smallToolbar = false;

  // Timeout is needed on the redaction warning to prevent the Data/Tools/Journal tabs from displaying vertically
  $timeout(() => vm.displayRedactionBanner = true, DEBOUNCE.SHORT);

  displayArchivedModal();

  /**
   * Determines if a particular worksheet view is currently selected for display
   * @param {String} target - A worksheet target (i.e. WORKSHEET_TARGET.DATA_PANEL or WORKSHEET_TARGET.WORKSHEET).
   * @param {String} key - A worksheet key (e.g. WORKSHEET_VIEW.TREND, WORKSHEET_VIEW.EXPLORE, etc.). If this
   *                       value is not defined, then only the target parameter is used to determine if
   *                       the view is selected.
   * @returns {boolean} True if a view matching the supplied target and key is selected, false otherwise or
   *                    if transitioning to a worksheet.
   */
  function isSelectedView(target, key) {
    const currentTarget = _.get(sqWorksheetStore.view, 'target');
    const currentKey = _.get(sqWorksheetStore.view, 'key');
    return (currentTarget === target) && (!key || (currentKey === key)) && !(vm.transitioningToWorksheet() && vm.hasWorksheets);
  }

  /**
   * Called whenever a new "tab" in the side panel is selected
   *
   * @param {string} activeTab - the selected tab, one of WORKSHEET_TABSETS
   */
  function changeSidebarTab(activeTab) {
    if (activeTab === 'investigate') {
      sqInvestigateActions.updateDerivedDataTree();
    }

    if (activeTab === 'annotate') {
      sqReportEditor.focus();
    }

    sqWorksheetActions.tabsetChangeTab('sidebar', activeTab);
  }

  /**
   * State needs to be synced to the view-model so that it can be reflected in the view
   */
  function setWorkbookVars() {
    // Hold the workbook vars constant until the state change is complete. Otherwise, the error page will flash
    // because the workbook display has not been set.
    if ($state.transition) {
      return;
    }

    vm.workbookOwner = _.get(sqWorkbookStore.owner, 'name', '');
    vm.isReportBinder = sqWorkbookStore.isReportBinder;
    vm.hasWorksheets = !!sqWorkbookStore.worksheets.length;
    vm.isViewMode = sqUtilities.isViewOnlyWorkbookMode;
    vm.isSandboxMode = sqReportStore.sandboxMode.enabled;
    vm.isPresentationMode = sqUtilities.isPresentationWorkbookMode;
    vm.worksheetPanelCollapsed = vm.isViewMode
      ? sqWorkbenchStore.viewOnlyWorksheetPanelCollapsed
      : sqWorkbenchStore.worksheetPanelCollapsed;
    vm.displayPanePlugins = sqPluginStore.displayPanePlugins();
    vm.isEditMode = sqUtilities.isEditWorkbookMode;
    if (vm.isViewMode && vm.isReportBinder) {
      vm.activeTabIndex = vm.reportConfigTabIndex;
    }
  }

  /**
   * Checks if we are transitioning (i.e. routing ) to a worksheet
   * @returns {boolean} True if transitioning, false otherwise
   */
  vm.transitioningToWorksheet = function() {
    return !!$state.transition;
  };

  /**
   * State needs to be synced to the view-model so that it can be reflected in the view
   * Angular-Bootstrap Tabs mutates sidebarTabset in its internal select method.
   */
  function setWorksheetVars() {
    vm.view = sqWorksheetStore.view;
    vm.browsePanelCollapsed = sqWorksheetStore.browsePanelCollapsed;
    vm.activeTabIndex = sqWorksheetStore.getTabset('sidebar').activeTabIndex;
    vm.isAssetGroupEditorView = vm.view.key === ASSET_GROUP_VIEW.key;
  }

  /**
   * When the workbook is archived, show a handy modal that will allow the user to restore or duplicate the workbook.
   */
  function displayArchivedModal() {
    if (sqWorkbookStore.isArchived && !sqUtilities.headlessRenderMode()) {
      sqModal.safeOpen({
        animation: true,
        controller: 'WorkbookAccessWarningModalCtrl',
        controllerAs: '$ctrl',
        size: 'sm',
        template: workbookAccessWarningModalTemplate,
        resolve: {
          workbook: () => sqHomeScreenUtilities.getWorkbook(sqWorkbookStore.workbookId),
          isReportBinder: () => vm.isReportBinder
        }
      });
    }
  }

  /**
   * Tracks the adding of an item as well as actually adds the item.
   *
   * @param (Object} item - the item to be added.
   */
  function trackAndAddItem(item) {
    sqTrack.doTrack('Worksheets', 'Item added', item.type);
    sqTrendActions.addItem(item);
  }

  /**
   * Returns true if the Redaction banner at the top of the trend view should be shown
   */
  function shouldShowRedactionBanner() {
    return !sqUtilities.headlessRenderMode() && sqRedaction.isRedacted() && vm.displayRedactionBanner;
  }
}
