import React, { useState } from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { FormControl } from 'react-bootstrap';

/**
 * Text input field with a default value and an edit mode to avoid overwriting the
 * value typed by the user. This is useful for formatted number inputs.
 */
const inputWithFormatterProps = bindingsDefinition({
  /** value to display in the input box prior to editing */
  value: prop<any>(),
  /** text to set for the "data-testid" property */
  testId: prop<string>(),
  /** class name(s) for the FormControl component */
  inputClassName: prop<string>(),
  /** type of the input (e.g. "number") */
  inputType: prop<string>(),
  /** action that takes an input (same type as value) and returns a string with the desired formatting applied */
  formatter: prop<(inputValue: any) => string>(),
  /** action to take when the input value changes (i.e. the user types into the field) */
  onChange: prop<(event: React.FormEvent<HTMLInputElement>) => void>(),
  maxLength: prop.optional<number>()
});

export const InputWithFormatter: SeeqComponent<typeof inputWithFormatterProps> = (props) => {
  const { value, testId, inputClassName, inputType, formatter, onChange, maxLength } = props;

  const [isEditing, setIsEditing] = useState(false);

  return (
    <FormControl
      className={'input ' + inputClassName}
      name={testId}
      type={inputType}
      size="sm"
      value={isEditing ? undefined : formatter(value)}
      onChange={event => onChange(event as any)}
      onFocus={(event) => {
        event.target.select();
        setIsEditing(true);
      }}
      onBlur={() => setIsEditing(false)}
      data-testid={testId}
      maxLength={maxLength} />
  );
};
