import React, { useState } from 'react';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { JournalComment } from '@/hybrid/annotation/JournalComment.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { TrackService } from '@/track/track.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { ReportStore } from '@/reportEditor/report.store';
import { ReportActions } from '@/reportEditor/report.actions';
import { AuthorizationService } from '@/services/authorization.service';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';

const reportCommentsBindings = bindingsDefinition({
  isPresentationMode: prop<boolean>(),
  sqTrack: injected<TrackService>(),
  sqReportStore: injected<ReportStore>(),
  sqReportActions: injected<ReportActions>(),
  sqAuthorization: injected<AuthorizationService>()
});

export const ReportComments: SeeqComponent<typeof reportCommentsBindings> = (props) => {
  const {
    sqTrack,
    sqReportStore,
    sqReportActions,
    sqAuthorization
  } = useInjectedBindings(reportCommentsBindings);

  const [commentText, setCommentText] = useState('');
  const [isCommenting, setIsCommenting] = useState(false);
  useFluxPath(sqReportStore, () => sqReportStore.comments);

  const { id, comments = [] } = sqReportStore;
  const { updateComment, deleteComment, addComment } = sqReportActions;
  const { canManageItem } = sqAuthorization;
  const { t } = useTranslation();

  const {
    isPresentationMode
  } = props;

  const onSaveComment = () => {
    addComment(id, commentText);
    setIsCommenting(false);
    setCommentText('');
    sqTrack.doTrack('Topic', 'Comments', 'save');
  };

  const onCancel = () => {
    setIsCommenting(false);
    setCommentText('');
    sqTrack.doTrack('Topic', 'Comments', 'cancel');
  };

  const onNewComment = () => {
    setCommentText('');
    setIsCommenting(true);
    sqTrack.doTrack('Journal', 'Comments', 'new');
  };

  return <>
    <div
      className={classNames(
        'flexFillOverflow msOverflowStyleAuto flexRowContainer overflowAuto flexBasisZero pl5 pr5 mt10', {
          flexCenter: !comments.length
        })}>
      {!comments.length &&
      <div className="col-sm width-250 text-center">
        {t('REPORT.COMMENTS.NO_COMMENTS_PLACEHOLDER')}
      </div>}
      {comments.map(comment =>
        <div key={comment.id}>
          <JournalComment
            comment={comment}
            isPresentationMode={isPresentationMode}
            updateComment={updateComment}
            deleteComment={deleteComment}
            canManage={canManageItem} />
        </div>)}
    </div>
    {isCommenting && <div className="mt7 pb5 lightGreyBorderTop" />}
    {isCommenting && !isPresentationMode &&
    <div
      className="panel panel-primary flexRowContainer flexNoGrowNoShrink pl5 pr5"
      data-testid="specNewCommentEditor"
    >
      <div className="panel-heading pl8 pt8 flexNoGrowNoShrink flexRowContainer">
        <div className="commentNameEdit flexNoGrowNoShrink flexRowContainer">
          <Form.Group controlId="specNewCommentEditor">
            <Form.Control
              autoFocus={true}
              as="textarea"
              data-testid="specTextarea"
              rows={3}
              className="flexNoGrowNoShrink form-control textAreaResizeNone overflowYAuto"
              value={commentText}
              onChange={event => setCommentText(event.currentTarget.value)}
            />
          </Form.Group>
          <div className="panel-body pt5 pr0 pb0 text-right">
            <TextButton
              testId="specCancelNewCommentBtn"
              extraClassNames="mr5 min-width-50"
              onClick={onCancel}
              label="CANCEL"
              size="sm"
            />
            <TextButton
              testId="specSaveNewCommentBtn"
              extraClassNames="min-width-50"
              onClick={onSaveComment}
              disabled={!commentText}
              label="SAVE"
              size="sm"
              variant="theme"
            />
          </div>
        </div>
      </div>
    </div>}
    <div className="flexColumnContainer flexNoGrowNoShrink flexAlignEnd animateHeight125Linear p5">
      <div className="flexFill" />
      {!isPresentationMode &&
      <a data-testid="specNewCommentBtn" href="#" onClick={onNewComment}>
        <HoverTooltip
          text={t('JOURNAL.TOOLTIP.ADD_COMMENT')} placement="left">
          <div>
            {t('JOURNAL.ENTRY.NEW_COMMENT')}
            <Icon icon="fc-comment" extraClassNames="small pl5" />
          </div>
        </HoverTooltip>
      </a>}
    </div>
  </>;
};

export const sqReportComments = angularComponent(reportCommentsBindings, ReportComments);
