import _ from 'lodash';
import React from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

interface ControlledTooltipProps {
  target: HTMLElement;
  id: string;
  show: boolean;
  text?: string;
  formattedText?: JSX.Element;
  placement?: Placement;
  extraClassNames?: string;
}

/**
 * Tooltip with placement, translation capabilities
 */
export const ControlledTooltip: React.FunctionComponent<ControlledTooltipProps> = (props) => {
  const { text = '', id, target, show, placement, formattedText = undefined, extraClassNames } = props;
  const { t } = useTranslation();

  if (_.isEmpty(text) && _.isUndefined(formattedText)) {
    return null;
  }

  return (
    <Overlay placement={placement} show={show} target={target}>
      <Tooltip id={id} className={extraClassNames}>
        {formattedText ? formattedText : t(text)}
      </Tooltip>
    </Overlay>
  );
};
