import _ from 'lodash';
import React from 'react';
import { TableBuilderDataCell } from '@/hybrid/tableBuilder/tableComponents/TableBuilderDataCell.atom';
import { TableBuilderTextCell } from '@/hybrid/tableBuilder/tableComponents/TableBuilderTextCell.atom';
import { TableBuilderTextHeader } from '@/hybrid/tableBuilder/tableComponents/TableBuilderTextHeader.atom';

const ALLOWED_COMPONENTS: React.FunctionComponent[] = [TableBuilderTextHeader, TableBuilderTextCell, TableBuilderDataCell];

export const TableBuilderRow: React.FunctionComponent = (props) => {
  const { children } = props;

  return <tr data-testid="tableBuilderRow">{children}</tr>;
};

TableBuilderRow.propTypes = {
  children: (props, propName) => {
    const prop = props[propName];
    return _.find(React.Children.toArray(prop),
      child => !_.includes(ALLOWED_COMPONENTS, (child as JSX.Element).type)) && new TypeError(
      'TableBuilderRow cells must be either TextCell or DataCell');
  }
};
