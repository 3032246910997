import React from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import {
  DetailTableAce
} from '@/hybrid/accessControl/itemAclModal.utilities';
import { Table } from '@/hybrid/core/Table.atom';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';

interface AclModalDetailTabProps {
  loadingDetails: boolean;
  detailTableEntries: DetailTableAce[];
}

export const AclModalDetailTab: React.FunctionComponent<AclModalDetailTabProps> = (props) => {
  const {
    loadingDetails,
    detailTableEntries
  } = props;
  const { t } = useTranslation();

  const columns = [
    {
      accessor: 'name',
      sortable: false,
      filterable: false,
      cellStyle: { width: 100 },
      header: 'ACCESS_CONTROL.NAME'
    }, {
      accessor: 'permissions',
      sortable: false,
      filterable: false,
      cellStyle: { width: 100 },
      header: 'ACCESS_CONTROL.PERMISSIONS'
    }, {
      accessor: 'inheritedFrom',
      sortable: false,
      filterable: false,
      cellStyle: { width: 100 },
      header: 'ACCESS_CONTROL.INHERITED_FROM'
    }
  ];

  return loadingDetails ? <div className='flexRowContainer flexCenter mt30 mb15'>
      <span className="mb5">
        {t('ACCESS_CONTROL.LOADING_DETAILS')}
      </span>
      <IconWithSpinner spinning={true} />
    </div>
    :
    <div className="flexRowContainer flexFill mt15" id="aclGrid">
      <div className="flexFill aclGrid">
        <Table
          columns={columns}
          items={detailTableEntries}
        />
      </div>
    </div>;
};
