import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { RefreshingContent } from '@/hybrid/annotation/ckEditorPlugins/components/RefreshingContent.molecule';
import { CkReportContentService } from '@/hybrid/annotation/ckReportContent.service';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { ReportStore } from '@/reportEditor/report.store';
import { AssetSelection, Content as ContentItem, CONTENT_LOADING_CLASS, DateRange } from '@/reportEditor/report.module';
import { HtmlPortalNode } from 'react-reverse-portal';
import { ReportActions } from '@/reportEditor/report.actions';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { NotificationsService } from '@/services/notifications.service';
import {
  ContentCallbacks,
  ContentDisplayMode,
  CustomPlugin
} from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import {
  CONTENT_MODEL_ATTRIBUTES,
  isContentInDocument
} from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import { UtilitiesService } from '@/services/utilities.service';

const contentBindings = bindingsDefinition({
  // The below three props should be used in the initial render/useEffect. After that point, they may be out of
  // date due to duplication changing data behind the scenes. If additional functionality is added, make sure to use
  // `actualId` when referencing stores and the like.
  contentId: prop<string>(),
  modelElement: prop<any>(),
  portalNode: prop<HtmlPortalNode>(),
  displayMode: prop<ContentDisplayMode>(),
  sqCkReportContent: injected<CkReportContentService>(),
  sqReportContent: injected<ReportContentService>(),
  sqReportEditor: injected<ReportEditorService>(),
  sqReportStore: injected<ReportStore>(),
  sqReportActions: injected<ReportActions>(),
  sqNotifications: injected<NotificationsService>(),
  sqUtilities: injected<UtilitiesService>()
});

const MAX_TRIES = 100;

const minWidth = 100;
const maxWidth = 100;
const minHeight = 100;
const maxHeight = 100;

/**
 * Insertion in CKEditor in a synchronous process, so to copy or unarchive content we need to do it after the
 * component is inserted. This component either renders, duplicates, or unarchives the Content depending on the
 * below set of conditions.
 */
export const DuplicatingContent: SeeqComponent<typeof contentBindings> = (props) => {
  const {
    contentId,
    modelElement,
    portalNode,
    displayMode
  } = props;
  const {
    sqCkReportContent,
    sqReportEditor,
    sqReportStore,
    sqReportActions,
    sqReportContent,
    sqNotifications,
    sqUtilities
  } = useInjectedBindings(contentBindings);

  const editor: any = sqReportEditor.getGlobalInstance();
  const contentCallbacks: ContentCallbacks = editor.config.get(CustomPlugin.Content).contentCallbacks;

  // A = In store, B = Archived, C = has content display metadata (is visible)
  // !A, Content has been pasted from another document, needs duplication
  // A + B, Content has been cut and pasted, needs unarchival
  // A + !B + C, Content has been copied from and pasted to its current document, needs duplication
  // A + !B + !C, Content has not yet been displayed. Pass ID to actual Content component
  const canSkipDuplication = (sqReportStore.getContentById(contentId)
      && !sqReportStore.getContentById(contentId)?.isArchived
      && !isContentInDocument(contentId))
    || sqUtilities.headlessRenderMode();
  const [error, setError] = useState(undefined);
  const [actualId, setActualId] = useState(canSkipDuplication && contentId);
  const [showRealContent, setShowRealContent] = useState(false);

  const duplicateOrUnarchiveContent = (content: ContentItem, dateRange: DateRange, assetSelection: AssetSelection) => {
    sqCkReportContent.duplicateOrUnarchiveContent(content, isContentInDocument(contentId), dateRange, assetSelection)
      .then((content) => {
        if (content) {
          sqReportActions.setContentDisplayMetadata({ contentId: content.id, refresh: undefined, errorClass: undefined }
          );
          contentCallbacks.updateContentModelAndNode(editor, content.id, modelElement,
            portalNode,
            contentId !== content.id);
          setActualId(content.id);
          setShowRealContent(true);
          sqReportActions.resetContentErrors();
        }
      })
      .catch(error => setError(sqCkReportContent.duplicateContentError(contentId, error).errorClass));
  };

  const waitUntilDependenciesCopied = (content: ContentItem, dateRange: DateRange, assetSelection: AssetSelection,
    currentTry = 0) => {
    if (currentTry > MAX_TRIES) {
      sqNotifications.error('REPORT.CONTENT.DEPENDENCY_TIMEOUT');
      setError(CONTENT_LOADING_CLASS.ERROR);
    } else {
      setTimeout(() => {
        if (sqReportContent.isDateRangeBeingCopied(dateRange)
          || sqReportContent.isAssetSelectionBeingCopied(assetSelection)) {
          waitUntilDependenciesCopied(content, dateRange, assetSelection, currentTry++);
        } else {
          duplicateOrUnarchiveContent(content, dateRange, assetSelection);
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (actualId) {
      sqReportActions.setContentDisplayMetadata({ contentId: actualId, refresh: undefined, errorClass: undefined });
      contentCallbacks.updateContentModelAndNode(editor, actualId, modelElement,
        portalNode);
      setShowRealContent(true);
    } else {
      contentCallbacks.updateContentAttributeWithoutSaving(CONTENT_MODEL_ATTRIBUTES.PENDING, contentId, modelElement);
      sqReportActions.fetchContent(contentId, false)
        .then(({ content, dateRange, assetSelection }) => {
          if (sqReportContent.isDateRangeBeingCopied(dateRange)
            || sqReportContent.isAssetSelectionBeingCopied(assetSelection)) {
            waitUntilDependenciesCopied(content, dateRange, assetSelection);
          } else {
            duplicateOrUnarchiveContent(content, dateRange, assetSelection);
          }
        })
        .catch(error => setError(sqCkReportContent.duplicateContentError(contentId, error).errorClass));
    }
  }, []);

  const displayClass = error ?? CONTENT_LOADING_CLASS.SPINNER;

  return actualId && showRealContent
    ? <RefreshingContent
      contentId={actualId}
      editor={editor}
      updateContentAttribute={(attribute, value) => contentCallbacks.updateContentAttributeById(
        editor, attribute, value, actualId, true)}
      displayMode={displayMode}
    />
    : <img data-testid={`content-wrapper-${contentId}`} style={{ minHeight, minWidth, maxHeight, maxWidth }}
      className={displayClass} />;
};
