import _ from 'lodash';
import { BaseToolStoreService } from '@/investigate/baseToolStore.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { FrontendDuration, SystemConfigurationService } from '@/services/systemConfiguration.service';
import { LOGIC, LOGIC_KEY } from '@/hybrid/tools/compositeSearch/compositeSearch.module';
import { TREND_TOOLS } from '@/investigate/investigate.module';

export type CompositeSearchStore = ReturnType<typeof sqCompositeSearchStore>['exports'];

export function sqCompositeSearchStore(
  sqBaseToolStore: BaseToolStoreService,
  sqDateTime: DateTimeService,
  sqSystemConfiguration: SystemConfigurationService
) {

  const store = {
    initialize() {
      this.state = this.immutable(_.assign({}, sqBaseToolStore.COMMON_PROPS, {
        selectedOperator: undefined,
        maximumDuration: undefined,
        isUpgradedUnionCondition: false,
        inclusiveA: true,
        inclusiveB: true,
        maximumDurationOverrideA: undefined,
        maximumDurationOverrideB: undefined,
        maximumDurationOverrideRequiredA: this.monkey(['selectedOperator'], ['conditionA'],
          (selectedOperator, condition) =>
            this.maximumDurationOverrideRequired('A', selectedOperator, !!condition,
              this.getMaximumDuration(condition))),
        maximumDurationOverrideRequiredB: this.monkey(['selectedOperator'], ['conditionB'],
          (selectedOperator, condition) =>
            this.maximumDurationOverrideRequired('B', selectedOperator, !!condition,
              this.getMaximumDuration(condition))),
        maximumDurationRequired: this.monkey(['selectedOperator'], ['isUpgradedUnionCondition'],
          (selectedOperator, isUpgradedUnionCondition) => {
            const logic = _.find(LOGIC, ({ key }) => key === selectedOperator);
            // to ensure the maximumDurationRequired property is correct the currently selected operator must be
            // considered (CRAB-14553)
            return _.get(logic, 'withMaximumDuration', isUpgradedUnionCondition &&
              selectedOperator === LOGIC_KEY.UNION);
          })
      }));
    },

    exports: {
      get selectedOperator() {
        return this.state.get('selectedOperator');
      },

      get inclusiveA() {
        return this.state.get('inclusiveA');
      },

      get inclusiveB() {
        return this.state.get('inclusiveB');
      },

      get maximumDurationConditionA() {
        return this.getMaximumDuration(this.state.get('conditionA'));
      },

      get maximumDurationConditionB() {
        return this.getMaximumDuration(this.state.get('conditionB'));
      },

      get maximumDurationOverrideA() {
        return this.getMaximumDurationOverrideOrDefault('maximumDurationOverrideA');
      },

      get maximumDurationOverrideB() {
        return this.getMaximumDurationOverrideOrDefault('maximumDurationOverrideB');
      },

      get maximumDurationOverrideRequiredA() {
        return this.state.get('maximumDurationOverrideRequiredA');
      },

      get maximumDurationOverrideRequiredB() {
        return this.state.get('maximumDurationOverrideRequiredB');
      },

      get isUpgradedUnionCondition() {
        return this.state.get('isUpgradedUnionCondition');
      },

      get maximumDurationRequired() {
        return this.state.get('maximumDurationRequired');
      },

      get formulaWithParameters() {
        return this.getFormula();
      }
    },

    /**
     * Determines whether an input maximum duration override is required for the given parameter
     *
     * @param {string} slot - the input condition in question ('A' or 'B')
     * @param {string} logicType - the selected logic operator
     * @param {boolean} conditionPresent - whether there is an input condition selected
     * @param {Object} originalMaximumDuration - the selected input condition's original maximum duration
     * @returns {boolean}
     */
    maximumDurationOverrideRequired(slot, logicType, conditionPresent, originalMaximumDuration = { value: null }) {
      const { value } = originalMaximumDuration;
      if (!conditionPresent) {
        return false;
      }

      switch (logicType) {
        case LOGIC_KEY.UNION:
          return false;
        case LOGIC_KEY.INTERSECTION:
          return false;
        case LOGIC_KEY.JOIN:
          return false;
        case LOGIC_KEY.OVERLAPPED_BY:     // Touches
          return slot === 'A' && _.isNil(value);
        case LOGIC_KEY.NOT_OVERLAPPED_BY: // Outside
          return _.isNil(value);
        case LOGIC_KEY.ENCLOSES:          // Inside
          return slot === 'B' && _.isNil(value);
        case LOGIC_KEY.SUBTRACT:          // Minus
          return false;
        default:
          return false;
      }
    },

    /**
     * Gets the maximum duration from the given item's condition metadata
     *
     * @param {Object} item
     * @returns {Object} The item's maximum duration
     */
    getMaximumDuration(item) {
      return _.chain(item)
        .get('conditionMetadata.maximumDuration')
        .thru(md => md ? ({ value: md.value, units: md.uom }) : undefined)
        .value();
    },

    getMaximumDurationOverrideOrDefault(override) {
      return this.state.get(override) || sqSystemConfiguration.defaultMaxCapsuleDuration;
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @return {Object} logicChain
     */
    dehydrate() {
      return this.state.serialize();
    },

    /**
     * Rehydrates the store.
     *
     * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      COMPOSITE_SEARCH_SET_OPERATOR: 'setOperator',
      COMPOSITE_SEARCH_INCLUSIVE_A: 'setInclusiveA',
      COMPOSITE_SEARCH_INCLUSIVE_B: 'setInclusiveB',
      COMPOSITE_SEARCH_SET_CONDITION_A_MAXIMUM_DURATION_OVERRIDE: 'setConditionAMaximumDurationOverride',
      COMPOSITE_SEARCH_SET_CONDITION_B_MAXIMUM_DURATION_OVERRIDE: 'setConditionBMaximumDurationOverride',
      COMPOSITE_SEARCH_SWAP_CONDITIONS: 'swapConditions',
      COMPOSITE_SEARCH_UNSET_UPGRADED_UNION_CONDITION: 'unsetUpgradedUnionCondition',
      TOOL_REHYDRATE_FOR_EDIT: 'compositeSearchRehydrateForEdit'
    },

    /**
     * Sets the operator to use to generate the Composite Search result.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.operator - A String representing the key that specifies the search algorithm.
     * @param {boolean} [payload.resetOutputMaximumDuration] - Whether to reset maximum duration to its default value
     */
    setOperator(payload) {
      this.state.set('selectedOperator', payload.operator);
      if (payload.resetOutputMaximumDuration) {
        this.state.set('maximumDuration', sqSystemConfiguration.defaultMaxCapsuleDuration);
      }
    },

    /**
     * Control whether to include A's capsule during joins.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.inclusiveA - A boolean indicating that joins should be inclusive of A
     */
    setInclusiveA(payload) {
      this.state.set('inclusiveA', payload.inclusiveA);
    },

    /**
     * Control whether to include B's capsule during joins.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.inclusiveA - A boolean indicating that joins should be inclusive of B
     */
    setInclusiveB(payload) {
      this.state.set('inclusiveB', payload.inclusiveB);
    },

    /**
     * Sets the condition A maximum duration override
     *
     * @param payload - an object containing the maximum duration
     */
    setConditionAMaximumDurationOverride(payload: { maximumDuration: FrontendDuration }) {
      this.state.set('maximumDurationOverrideA', payload.maximumDuration);
    },

    /**
     * Sets the condition B maximum duration override
     *
     * @param payload - an object containing the maximum duration
     */
    setConditionBMaximumDurationOverride(payload: { maximumDuration: FrontendDuration }) {
      this.state.set('maximumDurationOverrideB', payload.maximumDuration);
    },

    /**
     * Swap conditions A and B. Input maximum duration overrides go with their conditions.
     */
    swapConditions() {
      const conditionA = this.state.get('conditionA');
      const conditionB = this.state.get('conditionB');
      this.state.set('conditionA', conditionB);
      this.state.set('conditionB', conditionA);

      const conditionAMaximumDurationOverride = this.state.get('maximumDurationOverrideA');
      const conditionBMaximumDurationOverride = this.state.get('maximumDurationOverrideB');
      this.setConditionAMaximumDurationOverride({ maximumDuration: conditionBMaximumDurationOverride });
      this.setConditionBMaximumDurationOverride({ maximumDuration: conditionAMaximumDurationOverride });
    },

    /**
     * Sets the isUpgradedUnionCondition flag to false.
     */
    unsetUpgradedUnionCondition() {
      this.state.set('isUpgradedUnionCondition', false);
    },

    /**
     * Adds the formula and parameters to the config as part of what gets rehydrated when the tool is loaded.
     *
     * @param {Object} payload - An object with the necessary state to populate the edit form.
     * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
     * @param {Object[]} payload.parameters - The parameters used in the formula
     * @param {String} payload.selectedOperator - The selected operator from the UI Config
     */
    compositeSearchRehydrateForEdit(payload) {
      if (payload.type !== TREND_TOOLS.COMPOSITE_SEARCH) {
        return;
      }

      this.correctLegacySelectOperators(payload);

      this.rehydrateForEdit(payload);

      // Get this composite search item maximum duration from the calculated item condition metadata
      let maximumDuration = _.get(payload, 'conditionMetadata.maximumDuration');
      maximumDuration = maximumDuration ? { value: maximumDuration.value, units: maximumDuration.uom } : undefined;
      this.state.set('maximumDuration', maximumDuration);

      if (payload.formula) {
        // Get logic operator from formula
        const operator = _.chain(LOGIC)
          .filter(logic => this.matchesOperator(payload.formula, logic))
          .map('key')
          .first()
          .value();
        this.setOperator({ operator, resetOutputMaximumDuration: false });

        switch (operator) {
          // Restore inclusivity of Join conditions
          case LOGIC_KEY.JOIN:
            this.setInclusiveA({ inclusiveA: !payload.formula.match(/\$a.afterEnd\(0ns\)/) });
            this.setInclusiveB({ inclusiveB: !payload.formula.match(/\$b.beforeStart\(0ns\)/) });
            break;

          // Determine if this is an upgraded pre-Conditions 2.0 Union
          case LOGIC_KEY.UNION:
            this.state.set('isUpgradedUnionCondition', !!payload.formula.match(/\.removeLongerThan\(.*\)/));
            break;
        }
      }

      // Get maximum duration overrides from the formula
      const maxDurA = sqDateTime.splitDuration(_.get(/\$a\.setMaximumDuration\((\w.*?)\)/.exec(payload.formula), '1'));
      if (maxDurA) {
        this.state.set('maximumDurationOverrideA', maxDurA);
      }
      const maxDurB = sqDateTime.splitDuration(_.get(/\$b\.setMaximumDuration\((\w.*?)\)/.exec(payload.formula), '1'));
      if (maxDurB) {
        this.state.set('maximumDurationOverrideB', maxDurB);
      }
    },

    matchesOperator(formula, logic) {
      const includesOperator = formula.indexOf(logic.operator) > -1;
      const notExcluded = !logic.exclude || formula.indexOf(logic.exclude) === -1;
      return includesOperator && notExcluded;
    },

    /**
     * We correct all the legacy selectedOperators that were the inverse of another. By convention, those
     * ended in _INVERSE, and were identical to the primary operator except for the placement of $a and $b.
     *
     * @param {Object} payload - An object that is mutated to correct legacy select operators
     */
    correctLegacySelectOperators(payload) {
      if (_.endsWith(payload.selectedOperator, '_INVERSE')) {
        const temp = payload.parameters[0].item;
        payload.parameters[0].item = payload.parameters[1].item;
        payload.parameters[1].item = temp;
        payload.selectedOperator = _.replace(payload.selectedOperator, '_INVERSE', '');
      }
    },

    /**
     * Compute the formula that this tool represents.
     *
     * @returns {Object} Object with properties:
     *  {string} formula - the generated formula
     *  {string} parameters.a - ID of condition A in the formula
     *  {string} parameters.b - ID of condition B in the formula
     */
    getFormula() {
      const selectedOperator = this.state.get('selectedOperator');
      const inputConditions = { a: this.state.get('conditionA'), b: this.state.get('conditionB') };
      const maximumDurations = {
        output: this.state.get('maximumDuration'),
        conditionA: this.getMaximumDuration(inputConditions.a),
        conditionB: this.getMaximumDuration(inputConditions.b),
        overrideA: this.getMaximumDurationOverrideOrDefault('maximumDurationOverrideA'),
        overrideB: this.getMaximumDurationOverrideOrDefault('maximumDurationOverrideB')
      };

      if (this.canGenerateFormula(inputConditions, maximumDurations, selectedOperator)) {
        const inclusive = { a: this.state.get('inclusiveA'), b: this.state.get('inclusiveB') };
        const overridesRequired = {
          a: this.maximumDurationOverrideRequired('A', selectedOperator, !!inputConditions.a,
            maximumDurations.conditionA),
          b: this.maximumDurationOverrideRequired('B', selectedOperator, !!inputConditions.b,
            maximumDurations.conditionB)
        };
        const isUpgradedUnionCondition =
          this.state.get('isUpgradedUnionCondition') && selectedOperator === LOGIC_KEY.UNION;

        return {
          formula: this.makeFormula(
            maximumDurations,
            selectedOperator,
            isUpgradedUnionCondition,
            inclusive,
            overridesRequired
          ),
          parameters: {
            a: inputConditions.a.id,
            b: inputConditions.b.id
          }
        };
      }
    },

    /**
     * Indicates whether the store has all the required parameters to generate a formula
     *
     * @param {Object} inputConditions - An object bundling the input conditions together
     * @param {Object} maximumDurations - An object bundling the various types of maximum durations together
     * @param {String} selectedOperator - The selected logic type
     * @returns {boolean}
     */
    canGenerateFormula(inputConditions, maximumDurations, selectedOperator) {
      const { value: conditionAMaximumDurationValue = null } = maximumDurations.conditionA || {};
      const { value: conditionBMaximumDurationValue = null } = maximumDurations.conditionB || {};
      const { value: conditionAMaximumDurationOverrideValue = null } = maximumDurations.overrideA || {};
      const { value: conditionBMaximumDurationOverrideValue = null } = maximumDurations.overrideB || {};
      const { value: outputMaximumDurationValue = null } = maximumDurations.output || {};

      const isConditionABounded = !_.isNil(conditionAMaximumDurationValue);
      const isConditionBBounded = !_.isNil(conditionBMaximumDurationValue);
      const conditionAMaximumDurationHasOverride = !_.isNil(conditionAMaximumDurationOverrideValue);
      const conditionBMaximumDurationHasOverride = !_.isNil(conditionBMaximumDurationOverrideValue);
      const haveMaximumDuration = !_.isNil(outputMaximumDurationValue);

      const haveMaximumDurationForA = isConditionABounded || conditionAMaximumDurationHasOverride;
      const haveMaximumDurationForB = isConditionBBounded || conditionBMaximumDurationHasOverride;

      let operatorRequirementsSatisfied = false;
      switch (selectedOperator) {
        case LOGIC_KEY.JOIN:
          operatorRequirementsSatisfied = haveMaximumDuration;
          break;
        case LOGIC_KEY.OVERLAPPED_BY:     // Touches
          operatorRequirementsSatisfied = haveMaximumDurationForA;
          break;
        case LOGIC_KEY.NOT_OVERLAPPED_BY: // Outside
          operatorRequirementsSatisfied = haveMaximumDurationForA && haveMaximumDurationForB;
          break;
        case LOGIC_KEY.ENCLOSES:          // Inside
          operatorRequirementsSatisfied = haveMaximumDurationForB;
          break;
        case LOGIC_KEY.UNION:
        case LOGIC_KEY.INTERSECTION:
        case LOGIC_KEY.SUBTRACT:          // Minus
          operatorRequirementsSatisfied = true;
          break;
      }

      return inputConditions.a && inputConditions.b && selectedOperator && operatorRequirementsSatisfied;
    },

    /**
     * @param {string} arg - The Formula argument representing a condition
     * @param {Object} maximumDuration - The maximum duration override to use, or null if unneeded
     * @param {string} exclusiveFragment - A prefix operator fragment to apply; used for Join
     * @returns {string} the substitution for arg
     */
    formulaArgument(arg, maximumDuration, exclusiveFragment) {
      const maximumDurationFragment = !_.isNil(_.get(maximumDuration, 'value'))
        ? `.setMaximumDuration(${this.formulaBuilder.duration(maximumDuration)})`
        : '';
      return `${arg}${exclusiveFragment}${maximumDurationFragment}`;
    },

    /**
     * @param {Object} maximumDurations - An object bundling the various types of maximum durations together
     * @param {string} selectedOperator - The selected logic type
     * @param {boolean} isUpgradedUnionCondition - Whether this formula is for a pre-Conditions 2.0 Union condition
     * @param {Object} inclusive - An object indicating whether $a and $b should be inclusive; used for Join
     * @param {Object} overridesRequired - Object indicating whether maximum duration overrides are needed for $a or $b
     * @returns {string} the complete formula
     */
    makeFormula(maximumDurations, selectedOperator, isUpgradedUnionCondition, inclusive, overridesRequired) {
      const stringOutputMaximumDuration = !_.isNil(_.get(maximumDurations, 'output.value'))
        ? this.formulaBuilder.duration(maximumDurations.output)
        : '';

      const overrideA = overridesRequired.a ? maximumDurations.overrideA : undefined;
      const overrideB = overridesRequired.b ? maximumDurations.overrideB : undefined;
      const exclusiveFragmentA = !inclusive.a && selectedOperator === LOGIC_KEY.JOIN ? '.afterEnd(0ns)' : '';
      const exclusiveFragmentB = !inclusive.b && selectedOperator === LOGIC_KEY.JOIN ? '.beforeStart(0ns)' : '';

      const argumentA = this.formulaArgument('$a', overrideA, exclusiveFragmentA);
      const argumentB = this.formulaArgument('$b', overrideB, exclusiveFragmentB);

      const { formula, withMaximumDuration } = _.find(LOGIC, ({ key }) => key === selectedOperator);
      const formulaArguments = withMaximumDuration
        ? [argumentA, argumentB, stringOutputMaximumDuration]
        : [argumentA, argumentB];
      const operatorFragment = formula.apply(null, formulaArguments);

      // If this is a pre-Conditions 2.0 Union composite condition (and thus still has a maximum duration) we need
      // to preserve that maximum duration in the generated formula.
      const upgradedUnionMaxDurationFragment = isUpgradedUnionCondition
        ? `.removeLongerThan(${stringOutputMaximumDuration})`
        : '';

      return `${operatorFragment}${upgradedUnionMaxDurationFragment}`;
    },

    /**
     * Removes properties from config which are stored as part of the formula.
     * Composite search gets everything it needs from the formula so the config only contains the tool type.
     *
     * @param {Object} config - The state that will be saved to UIConfig
     * @return {Object} The modified config
     */
    modifyConfigParams(config) {
      return _.pick(config, ['type']);
    }
  };

  return sqBaseToolStore.extend(store, TREND_TOOLS.COMPOSITE_SEARCH,
    { conditionA: { predicate: ['name', 'a'] }, conditionB: { predicate: ['name', 'b'] } });
}
