import _ from 'lodash';
import angular from 'angular';
import { NotificationsService } from '@/services/notifications.service';
import { AdministrationActions } from '@/administration/administration.actions';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { DEBOUNCE, PASSWORD_MIN_LENGTH } from '@/main/app.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { UsersApi } from '@/sdk';

angular.module('Sq.Administration').controller('UserAddCtrl', UserAddCtrl);

function UserAddCtrl(
  availableDomains,
  $translate: ng.translate.ITranslateService,
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  sqNotifications: NotificationsService,
  sqAdministrationActions: AdministrationActions,
  sqWorkbenchActions: WorkbenchActions,
  sqWorkbenchStore: WorkbenchStore,
  sqUsersApi: UsersApi
) {
  const vm = this;
  vm.PASSWORD_MIN_LENGTH = PASSWORD_MIN_LENGTH;
  vm.PASSWORD_DEBOUNCE = DEBOUNCE.MEDIUM;
  vm.close = $uibModalInstance.close;
  vm.addUser = addUser;
  vm.availableDomains = availableDomains;
  vm.seeqDomain = _.find(vm.availableDomains, ['datasourceId', SeeqNames.LocalDatasources.Authentication.DatasourceId]);
  vm.user = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    isEnabled: { value: true, label: $translate.instant('ADMIN.USER.ENABLED') },
    domain: vm.seeqDomain,
    username: '',
    isAdmin: false
  };

  /**
   * Submits a request to generate a new user.
   * NOTE: username, email and password are required but not part of this story.
   * They will be added in a follow  on - but they are mandatory to create a user so we have them here.
   * @returns {Promise} that resolves after the user was created.
   */
  function addUser() {
    let newUser;
    if (vm.user.domain === vm.seeqDomain) {
      newUser = {
        firstName: vm.user.firstName,
        lastName: vm.user.lastName,
        email: vm.user.email,
        username: vm.user.email,
        password: vm.newPassword,
        isAdmin: vm.user.isAdmin
      };
    } else {
      newUser = {
        firstName: vm.user.firstName,
        lastName: vm.user.lastName,
        email: vm.user.email,
        datasourceClass: vm.user.domain.datasourceClass,
        datasourceId: vm.user.domain.datasourceId,
        username: vm.user.username,
        isAdmin: vm.user.isAdmin
      };
    }

    return sqUsersApi.createUser(newUser)
      .then(({ data }) => {
        sqAdministrationActions.saveNewUser(data);
        sqNotifications.successTranslate('USER.ADDED');
        vm.close();
      })
      .catch(sqNotifications.apiError);
  }
}
