import React from 'react';
import _ from 'lodash';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import classNames from 'classnames';
import { getFormFieldProps } from '@/hybrid/formbuilder/formbuilder.utilities';
import { Field, useForm } from 'react-final-form';

export interface CheckboxIF extends ValidatingFormComponent<boolean> {
  component: 'CheckboxFormComponent';
  id: string;
  onChange: () => void;
  label: string | React.ReactNode;
  translateLabel?: boolean;
  type?: 'radio' | 'checkbox';
  disabled?: boolean;
  classes?: string;
  onIconClick?: () => any;
}

export const CheckboxFormComponent: React.FunctionComponent<CheckboxIF> = (props) => {
  const {
    name,
    value,
    validation,
    testId,
    classes,
    extraClassNames,
    skipStore,
    displayNumber,
    onIconClick,
  } = props;

  const formState = useForm().getState();
  const defaultValidation = value => _.isEmpty(_.trim(value));
  const appliedValidation = _.isFunction(validation) ? validation : defaultValidation;

  return (
    <div data-testid={testId} className={extraClassNames}>
      <Field name={name} validate={appliedValidation} type="checkbox">
        {({ input, meta }) => (
          <Checkbox
            {...getFormFieldProps(formState, input, meta, props)}
            classes={classNames('flexColumnContainer', 'flexFill', classes)}
            isChecked={skipStore ? input.checked : value}
            onIconClick={onIconClick}
          />
        )}
      </Field>
    </div>
  );
};

