import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { InvestigateStore } from '@/investigate/investigate.store';
import { DISPLAY_MODE } from '@/main/app.constants';
import { ToolPanelHelperService } from '@/services/toolPanelHelper.service';
import { TrackService } from '@/track/track.service';
import { TrendStore } from '@/trendData/trend.store';
import { ITEM_TYPES } from '@/trendData/trendData.module';
import { ProfileSearchActions } from '@/hybrid/tools/profileSearch/profileSearch.actions';
import { ProfileSearchStore } from '@/hybrid/tools/profileSearch/profileSearch.store';
import React, { useState } from 'react';

const profileSearchBindings = bindingsDefinition({
  sqProfileSearchStore: injected<ProfileSearchStore>(),
  sqProfileSearchActions: injected<ProfileSearchActions>(),
  sqInvestigateStore: injected<InvestigateStore>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqTrendStore: injected<TrendStore>(),
  sqTrack: injected<TrackService>()
});

export const ProfileSearch: SeeqComponent<typeof profileSearchBindings> =
  () => {
    const {
      sqProfileSearchStore,
      sqInvestigateStore,
      sqTrendStore,
      sqInvestigateActions,
      sqProfileSearchActions,
      sqTrack
    } = useInjectedBindings(profileSearchBindings);

    const [color, setColor] = useState('');

    const { id, name, inputSignal, normalizeAmplitude, normalizeLocation, similarity } = useFlux(sqProfileSearchStore);
    const { min, max } = useFluxPath(sqTrendStore, () => sqTrendStore.selectedRegion);
    const displayMode = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.displayMode);

    const formDataSetup: FormElement[] = [
      {
        component: 'SearchTitleFormComponent',
        name: 'profileSearchTitle',
        value: name,
        onChange: name =>
          sqInvestigateActions.setSearchName(TREND_TOOLS.PROFILE_SEARCH, name),
        id,
        onColorChange: setColor,
        searchIconClass: 'fc-search-pattern',
        defaultName: 'PROFILE_SEARCH.HEADER'
      },
      {
        component: 'ItemSelectFormComponent',
        name: 'inputSignal',
        displayNumber: true,
        testId: 'profileSearchInputSignal',
        value: inputSignal?.id,
        onChange: item =>
          sqInvestigateActions.setParameterItem(
            TREND_TOOLS.PROFILE_SEARCH,
            'inputSignal',
            item
          ),
        label: 'PROFILE_SEARCH.SELECT_SINGLE_SERIES',
        itemTypes: [ITEM_TYPES.SERIES],
        includeMetadata: true
      },
      {
        component: 'PatternFormComponent',
        name: 'searchPattern',
        displayNumber: true,
        tooltip: 'PROFILE_SEARCH.SELECT_THE_PATTERN_TOOLTIP',
        label: 'PROFILE_SEARCH.SELECT_THE_PATTERN',
        value: `${min} - ${max}`, /* This `value` property is needed here just for validation (it is never displayed in the form) */
        min,
        max,
        customErrorText: 'PROFILE_SEARCH.SEARCH_PATTERN_VALIDATE'
      },
      {
        component: 'ValueWithSymbolFormComponent',
        name: 'similarity',
        testId: 'similarity',
        displayNumber: true,
        value: similarity,
        onChange: sqProfileSearchActions.setSimilarity,
        label: 'PROFILE_SEARCH.SIMILARITY',
        symbol: '%',
        customErrorText: 'PROFILE_SEARCH.SIMILARITY_VALIDATE',
        min: 0,
        max: 100
      },
      {
        component: 'CheckboxFormComponent',
        name: 'normalizeAmplitude',
        displayNumber: true,
        value: normalizeAmplitude,
        onChange: () => sqProfileSearchActions.setNormalizeAmplitude(!normalizeAmplitude),
        tooltip: 'PROFILE_SEARCH.NORMALIZE_AMPLITUDE_TOOLTIP',
        label: 'PROFILE_SEARCH.NORMALIZE_AMPLITUDE',
        id: 'profileSearchNormalizeAmplitude'
      },
      {
        component: 'CheckboxFormComponent',
        name: 'normalizeLocation',
        displayNumber: true,
        value: normalizeLocation,
        onChange: () => sqProfileSearchActions.setNormalizeLocation(!normalizeLocation),
        tooltip: 'PROFILE_SEARCH.NORMALIZE_LOCATION_TOOLTIP',
        label: 'PROFILE_SEARCH.NORMALIZE_LOCATION',
        id: 'profileSearchNormalizeLocation'
      }
    ];

    /**
     * Start a profile search for the selected region.
     */
    const search = () => {
      return sqProfileSearchActions
        .search(inputSignal?.id, color)
        .then(() => {
          sqTrack.doTrack('Workbench_Tool', '  Search', 'completed');
        })
        .catch(() => {
          sqTrack.doTrack('Workbench_Tool', 'Profile Search', 'error');
        });
    };

    const profileSearchBuilder = (
      <ToolPanelFormBuilder
        formDefinition={formDataSetup}
        submitFn={search}
        closeFn={sqInvestigateActions.close}
        toolName={TREND_TOOLS.PROFILE_SEARCH}
        toolId={TREND_TOOLS.PROFILE_SEARCH}
        submitBtnId="profileSearchButton"
      />
    );

    return (displayMode === DISPLAY_MODE.NEW || displayMode === DISPLAY_MODE.EDIT) ? profileSearchBuilder : null;
  };

export const sqProfileSearch = angularComponent(
  profileSearchBindings,
  ProfileSearch
);
