import React from 'react';
import classNames from 'classnames';
import { bindingsDefinition, prop } from "@/hybrid/core/bindings.util";
import { angularComponent } from "@/hybrid/core/react2angular.util";
import { Icon } from "@/hybrid/core/Icon.atom";

const journalReplyIconBindings = bindingsDefinition({
  small: prop.optional<boolean>(),
  count: prop<number>()
});

export const JournalReplyIcon: SeeqComponent<typeof journalReplyIconBindings> = (props) => {
  const { count, small } = props;
  return (
    <div data-testid="specJournalReplyIcon" className={classNames("flexColumnContainer flexCenter", {
      'sq-text-primary': !!count,
      'sq-fairly-dark-gray': !count
    })}>
      <span className={classNames("specCommentCount", { xsmall: !!small })}>{count}</span>
      <Icon testId="specCommentIcon" icon={classNames("ml3 fc fc-comment", { xsmall: !!small })} />
    </div>
  );
};

export const sqJournalReplyIcon = angularComponent(journalReplyIconBindings, JournalReplyIcon);
