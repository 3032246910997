import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { TrackService } from '@/track/track.service';
import { AuthorizationService } from '@/services/authorization.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { ConditionFormulaService } from '@/investigate/customCondition/conditionFormula.service';
import { DateTimeEntry } from '@/hybrid/core/DateTimeEntry.atom';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { Capsule } from '@/datetime/datetime.module';

const capsuleInputBindings = bindingsDefinition({
  setCapsuleInputValidStatus: prop.optional<(isValid: boolean) => void>(),
  onChange: prop.optional<(capsuleWindow: Capsule) => void>(),
  trackInformation: prop.optional<string>(),
  trackAction: prop<string>(),
  trackCategory: prop<string>(),
  dateFormat: prop.optional<string>(),
  capsuleWindow: prop<Capsule>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqAuthorization: injected<AuthorizationService>(),
  sqConditionFormula: injected<ConditionFormulaService>(),
  sqTrack: injected<TrackService>()
});

export const CapsuleInput: SeeqComponent<typeof capsuleInputBindings> = (props) => {
  const {
    sqConditionFormula,
    sqWorksheetStore
  } = useInjectedBindings(capsuleInputBindings);

  const {
    setCapsuleInputValidStatus,
    capsuleWindow = {
      startTime: moment().subtract(1, 'hour').valueOf(),
      endTime: moment().valueOf(),
      properties: []
    },
    onChange,
    trackInformation,
    trackAction,
    trackCategory,
    dateFormat
  } = props;

  const { timezone } = useFlux(sqWorksheetStore);

  const { t } = useTranslation();
  const isValidCapsule = capsule => sqConditionFormula.isValidCapsule(capsule);

  useEffect(() => {
    setCapsuleWindowStatus(isValidCapsule(capsuleWindow));
  }, []);

  const setCapsuleWindow = (windowMoment, windowPeriod) => {
    const capsule = {
      ...capsuleWindow,
      [windowPeriod]: windowMoment.valueOf()
    };
    _.isFunction(onChange) && onChange(capsule);
    setCapsuleWindowStatus(isValidCapsule(capsule));
  };

  const setCapsuleWindowStatus = (isValid) => {
    _.isFunction(setCapsuleInputValidStatus) && setCapsuleInputValidStatus(isValid);
  };

  return (
    <div className="flexRowContainer">
      <div className="flexColumnContainer text-center flexCenter">
        <DateTimeEntry
          testId="rangeStart"
          extraClassName="specRange specRangeStart width-115"
          textExtraClassNames="sq-text-primary flexRowContainer"
          date={moment(capsuleWindow.startTime)}
          updateDate={windowMoment => setCapsuleWindow(windowMoment, 'startTime')}
          dateFormat={dateFormat}
          timezone={timezone}
          inputExtraClassNames="width-100"
          hideTimezone={true}
          fieldIdentifier="INVESTIGATE_WINDOW_START"
          trackCategory={trackCategory}
          trackAction={trackAction}
          trackInformation={`Start. ${trackInformation}`}
        />
        -
        <DateTimeEntry
          testId="rangeEnd"
          extraClassName="specRange specRangeEnd width-115"
          textExtraClassNames="sq-text-primary flexRowContainer"
          date={moment(capsuleWindow.endTime)}
          updateDate={windowMoment => setCapsuleWindow(windowMoment, 'endTime')}
          dateFormat={dateFormat}
          timezone={timezone}
          inputExtraClassNames="width-100"
          hideTimezone={true}
          fieldIdentifier="INVESTIGATE_WINDOW_END"
          trackCategory={trackCategory}
          trackAction={trackAction}
          trackInformation={`End. ${trackInformation}`}
        />
      </div>
      {capsuleWindow && !isValidCapsule(capsuleWindow) &&
      <p className="help-block sq-text-danger text-center">{t('FORM.START_BEFORE_END')}</p>
      }
    </div>
  );
};

export const sqCapsuleInput = angularComponent(capsuleInputBindings, CapsuleInput);
