import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { AssetGroupEditorToolbar } from '@/hybrid/assetGroupEditor/AssetGroupEditorToolbar.organism';
import { AssetGroupEditor } from '@/hybrid/assetGroupEditor/AssetGroupEditor.page';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { UtilitiesService } from '@/services/utilities.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';

const assetGroupBindings = bindingsDefinition({
  isSmall: prop<boolean>(),
  sqUtilities: injected<UtilitiesService>()
});

export const AssetGroupView: SeeqComponent<typeof assetGroupBindings> = ({ isSmall }) => {
  const { sqUtilities } = useInjectedBindings(assetGroupBindings);
  const { t } = useTranslation();

  const isEditMode = sqUtilities.isEditWorkbookMode;

  const renderAssetGroupBanner = (
    <div className="assetGroupHeader text-center flexRowContainer flexAlignCenter flexJustifyCenter height-50">
      <div className="text-bold fs15">{t('ASSET_GROUP_EDITOR.HEADER')}</div>
    </div>
  );

  return (
    <div className="flexFill flexRowContainer resizablePanelContainer">
      {isEditMode && renderAssetGroupBanner}
      <AssetGroupEditorToolbar isSmall={isSmall} />
      <AssetGroupEditor />
    </div>
  );
};

export const sqAssetGroupView = angularComponent(assetGroupBindings, AssetGroupView);
