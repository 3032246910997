import _ from 'lodash';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import {
  BasePluginDependencies,
  CustomPlugin,
  CustomPluginSetup
} from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { PluginDependencies } from '@/hybrid/annotation/ckEditorPlugins/plugins/PluginDependencies';
import { SeeqImageUpload, SeeqImageUploadAdapter } from '@/hybrid/annotation/ckEditorPlugins/plugins/SeeqImageUpload';
import { SeeqImageBorder } from '@/hybrid/annotation/ckEditorPlugins/plugins/SeeqImageBorder';
import { NestedTables } from '@/hybrid/annotation/ckEditorPlugins/plugins/NestedTables';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import Clipboard from '@ckeditor/ckeditor5-clipboard/src/clipboard';
import Pagination from '@ckeditor/ckeditor5-pagination/src/pagination';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import { StripedTables } from '@/hybrid/annotation/ckEditorPlugins/plugins/StripedTables';
import { DateRangeLabelPlugin } from '@/hybrid/annotation/ckEditorPlugins/plugins/DateRangeLabel';

const isExternalUrl = (urlToTest) => {
  // consider internal if there's no window (some tests) OR urls start with "/" 
  if (!window || !window.location || urlToTest.startsWith('/')) {
    return false;
  }
  // check domain if we have a full url
  try {
    const urlObj = new URL(urlToTest);
    if (urlObj.hostname === window.location.hostname) {
      return false;
    }
  } catch (error) {
    // the URL could not be extracted - consider external to be on the safe side
    // no-op
  }
  return true;
};

export const DefaultCKConfig = {
  language: 'en',
  plugins: [
    Alignment,
    AutoLink,
    Bold,
    Clipboard,
    CodeBlock,
    Essentials,
    Font,
    Heading,
    Image,
    ImageInsert,
    ImageStyle,
    ImageResize,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    ListStyle,
    PageBreak,
    Pagination,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    Strikethrough,
    Table,
    TableCellProperties,
    TableProperties,
    TableToolbar,
    Underline,
    // custom plugins
    StripedTables,
    PluginDependencies,
    SeeqImageUpload,
    SeeqImageUploadAdapter,
    SeeqImageBorder,
    NestedTables,
    DateRangeLabelPlugin
  ],
  toolbar: [
    'imageInsert', '|',
    'link', 'insertTable', 'pagebreak', '|',
    'heading', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', '|',
    'bold', 'italic', 'underline', 'strikethrough', '|',
    'alignment', 'outdent', 'indent', 'numberedList', 'bulletedList', '|',
    'removeFormat', '|',
    'undo', 'redo'
  ],
  pagination: {},
  licenseKey: process.env.NODE_ENV === 'production' ? 'Srp8l4J6iTvd4B1+qa/xcBr8OJuJm2kCTXnNaUcBs/UPNfI=' :
    'lv+SltpI77EUMxpEk1lFaoiKZTemMAW8cEPduLpdWjxb6EA=',
  table: {
    contentToolbar: ['tableRow', 'tableColumn', 'mergeTableCells', 'tableProperties', 'tableCellProperties', StripedTables.pluginName]
  },
  seeqUpload: {
    withCredentials: true
  },
  image: {
    upload: {
      types: ['jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff', 'svg+xml']
    },
    toolbar: [
      'imageStyle:inline', 'imageStyle:block', '|',
      'imageResize', '|',
      'imageTextAlternative', '|',
      'linkImage', '|',
      SeeqImageBorder.pluginName
    ],
    options: {
      styles: [
        'inline', 'block'
      ]
    }
  },
  link: {
    defaultProtocol: 'http://',
    decorators: {
      isExternal: {
        mode: 'automatic',
        callback: isExternalUrl,
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer'
        }
      },
    }
  }
};

export function createCustomConfig(customPlugins: CustomPlugin[], basePluginDependencies: BasePluginDependencies,
  customToolbar: string[] = undefined) {
  const config = { ...DefaultCKConfig };
  if (customToolbar) config.toolbar = customToolbar;

  _.chain(customPlugins)
    .map(plugin => CustomPluginSetup[plugin])
    .forEach((setup) => {
      config.plugins = [...config.plugins, setup.plugin];
      if (setup.config) {
        config[setup.name] = setup.config;
      }
      if (setup.toolbar && !_.includes(config.toolbar, setup.toolbar)) {
        throw new Error(`Plugin ${setup.name} has toolbar options and was not included in the toolbar.`);
      }
    })
    .value();

  config[PluginDependencies.pluginName] = { ...basePluginDependencies };

  return config;
}
