import angular from 'angular';
import { sqDatasourcesStore } from './datasources.store';
import { sqDatasourcesActions } from '@/hybrid/administration/datasources/datasources.actions';
import { DatasourcesService } from '@/hybrid/administration/datasources/datasources.service';

const dependencies = [
  'Sq.Services.Socket'
];

export default angular.module('Sq.Administration.Datasources', dependencies)
  .service('sqDatasourcesActions', sqDatasourcesActions)
  .store('sqDatasourcesStore', sqDatasourcesStore)
  .service('sqDatasourcesService', DatasourcesService);

export enum DetailsLevel {
  Counts = 'Counts',
  Summary = 'Summary',
  Complete = 'Complete'
}
