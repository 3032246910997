import React from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { UtilitiesService } from '@/services/utilities.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { Ace } from '@/hybrid/accessControl/itemAclModal.utilities';

const acePopoverBindings = bindingsDefinition({
  ace: prop<Ace>(),
  sqUtilities: injected<UtilitiesService>()
});

export const AcePopover: SeeqComponent<typeof acePopoverBindings> = (props) => {
  const {
    ace
  } = props;

  const {
    sqUtilities
  } = useInjectedBindings(acePopoverBindings);

  const { t } = useTranslation();

  const redactedMessage = <div className="flexRowContainer">
    {t('ITEM_ACL.TOOLTIP_REDACTED_IDENTITY')}
  </div>;

  const disabledMessage = <div className="flexRowContainer">
    {t('ITEM_ACL.TOOLTIP_DISABLED_IDENTITY')}
  </div>;

  const username = () => <div className="aggressiveWordBreak">
    <span className="text-bolder">
      {t('ITEM_ACL.USERNAME')}
    </span>
    <span className="ml5">
      {sqUtilities.readableIdentifier(ace.identity)}
    </span>
  </div>;

  const aceDetails = () => <div className="flexRowContainer">
    {ace.identity.type === 'User' && sqUtilities.readableIdentifier(ace.identity) && username()}
    <div className="textAlignLeft">
      <span className="text-bolder">
        {t('ITEM_ACL.DATASOURCE')}
      </span>
      <span className="ml5">
        {ace.identity.datasource.name}
      </span>
    </div>
  </div>;

  return <div className="p5">
    {ace.identity.isRedacted && redactedMessage}
    {!ace.identity.isRedacted && !ace.identity.isEnabled && disabledMessage}
    {!ace.identity.isRedacted && ace.identity.isEnabled && aceDetails()}
  </div>;
};
