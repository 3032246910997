import angular from 'angular';
import _ from 'lodash';
import { UsersApi } from 'sdk/api/UsersApi';
import { AdministrationActions } from '@/administration/administration.actions';
import { NotificationsService } from '@/services/notifications.service';
import { API_TYPES } from '@/main/app.constants';

angular.module('Sq.Administration').controller('UserRemoveCtrl', UserRemoveCtrl);

function UserRemoveCtrl(
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  $q: ng.IQService,
  ids: string[],
  sqAdministrationActions: AdministrationActions,
  sqNotifications: NotificationsService,
  sqUsersApi: UsersApi) {

  const vm = this;
  vm.ids = ids;
  vm.close = $uibModalInstance.close;
  vm.removeUsers = removeUsers;
  vm.getMatches = getMatches;
  vm.validUserSelected = validUserSelected;
  vm.newOwner = null;
  vm.processing = false;
  vm.ACCESS_CONTROL = {
    TRANSFER: 'transfer',
    REMOVE: 'remove'
  };
  vm.aclHandling = vm.ACCESS_CONTROL.TRANSFER;

  /**
   * Removes the users in vm.ids and transfers ownership of workbooks to the new owner (vm.newOwner), then closes
   * the modal.
   *
   * @returns {Promise} that resolves when the update is complete
   */
  function removeUsers() {
    vm.processing = true;
    return sqAdministrationActions.removeUsers(vm.ids, vm.newOwner.id,
      vm.aclHandling === vm.ACCESS_CONTROL.TRANSFER).then(vm.close);
  }

  /**
   * Returns list of any users which match the specified input/query.
   *
   * @param  {String} input - The (part of a) name to search for.
   *
   * @return {Object[]} List of users that match the search query.
   */
  function getMatches(input) {
    return sqUsersApi.autocompleteUsersAndGroups({
      query: input
    }).then(({ data: { items } }) => _.chain(items)
      // Filter out user groups
      .filter({ type: API_TYPES.USER })
      // Filter out users that are being deleted
      .filter(i => !_.includes(vm.ids, i.id))
      // Append the datasource name to the user's name
      .map((i) => {
        const datasourceName = _.get(i, 'datasource.name');
        if (datasourceName) {
          i.name = `${i.name} (${datasourceName})`;
        }
        return i;
      })
      .value()
    );
  }

  /**
   * Returns true if a valid user has been selected, false otherwise.
   *
   * @return {Boolean} True if a valid user has been selected, false otherwise.
   */
  function validUserSelected() {
    return _.has(vm.newOwner, 'id');
  }
}
