import angular from 'angular';
import template from './DisplayPanePluginHost.component.html';

angular.module('Sq.PluginHost').component('sqDisplayPanePluginHost', {
  template,
  controller: 'DisplayPanePluginHostCtrl',
  bindings: {
    plugin: '<'
  }
});
