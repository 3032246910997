/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class ExportApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Archive an export
   * @param {string} id - ID of the export to archive
   */
  public archiveExport(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/export/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ExportItemsOutputV1>;
  }

  /**
   * 
   * @summary Archive any exports that have not been updated or used in the timeframe provided. This may only be performed by administrators.
   * @param {string} duration=90 days - Archive any exports older than &#39;duration&#39;
   */
  public archiveExportsOlderThan(
    {
      duration
    } : {
      duration: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(duration)) {
      throw new Error("'duration' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/export/cleanup',
      params: omitBy({
        ['duration']: duration
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Export data in the specified output format. Currently only xlsx and odata are supported.
   * @param {models.ExportItemsV1} body - ExportItem defining the export output format as well a list of ExportItems to be included in the Export
   */
  public createExport(
    body: models.ExportItemsV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/export',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<{}>;
  }

  /**
   * 
   * @summary Get the details of an export
   * @param {string} id - ID of the export to retrieve
   */
  public getExport(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/export/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ExportItemsOutputV1>;
  }

  /**
   * Pagination is enabled for this query
   * @summary Get a collection of exports
   * @param {boolean} [includeArchived=false] - Whether archived exports should be included
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getExports(
    {
      includeArchived,
      offset,
      limit
    } : {
      includeArchived?: boolean,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/export',
      params: omitBy({
        ['includeArchived']: includeArchived,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ExportPreviewListV1>;
  }

  /**
   * 
   * @summary Update the details of an export
   * @param {string} id - ID of the export to update
   * @param {models.ExportItemsV1} body - Settings defining the export
   */
  public updateExport(
    body: models.ExportItemsV1,
    {
      id,
    } : {
      id: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/export/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ExportItemsOutputV1>;
  }

}
