import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { Icon } from '../core/Icon.atom';
import { ReportConfigMeta } from './ReportConfigMeta.molecule';
import { WorkbookStore } from '@/workbook/workbook.store';
import { angularComponent } from '../core/react2angular.util';
import { useFluxPath } from '../core/useFluxPath.hook';
import { useInjectedBindings } from '../core/useInjectedBindings.hook';
import { ReportConfigDates } from './ReportConfigDates.molecule';
import { ReportConfigBackups } from './ReportConfigBackups.molecule';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { ReportStore } from '@/reportEditor/report.store';
import { MigrationService } from '@/services/migration.service';
import { CkMigrationModal } from '@/hybrid/annotation/CKMigrationModal.molecule';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ReportConfigAssets } from '@/hybrid/reportEditor/ReportConfigAssets.molecule';
import { NotificationsService } from '@/services/notifications.service';
import { ReportActions } from '@/reportEditor/report.actions';
import { Alert } from 'react-bootstrap';

const reportConfigBindings = bindingsDefinition({
  viewOnlyMode: prop.optional<boolean>(),
  $state: injected<ng.ui.IStateService>(),
  sqWorkbookStore: injected<WorkbookStore>(),
  sqReportEditor: injected<ReportEditorService>(),
  sqReportStore: injected<ReportStore>(),
  sqReportActions: injected<ReportActions>(),
  sqMigration: injected<MigrationService>(),
  sqNotifications: injected<NotificationsService>()
});

export const ReportConfig: SeeqComponent<typeof reportConfigBindings> = (props) => {
  const { viewOnlyMode = false } = props;
  const {
    $state,
    sqWorkbookStore,
    sqReportEditor,
    sqReportStore,
    sqReportActions,
    sqNotifications
  } = useInjectedBindings(reportConfigBindings);

  const { t } = useTranslation();

  const [showMigrateModal, setShowMigrateModal] = useState(false);

  // force the props to be updated so we can get the new name
  useFluxPath(sqWorkbookStore, () => sqWorkbookStore.worksheets);
  useFluxPath(sqReportStore, () => sqReportStore.contentErrors);

  const { sandboxOriginalCreatorName, contentErrors } = sqReportStore;
  const worksheetName: string = sqWorkbookStore.getWorksheetName($state.params.worksheetId);
  const isCKEditor = sqReportEditor.isCkEditor();
  const toCk = !isCKEditor;
  const canUpgrade = sqReportEditor.canUpgradeToCk();
  const canRevertToFroala = useFluxPath(sqReportStore, () => sqReportStore.canRevertToFroala);

  useEffect(() => {
    if (canUpgrade && toCk) {
      sqNotifications.custom(
        sqNotifications.info,
        'REPORT.EDITOR.MIGRATION.TO_CK.NOTICE',
        () => { setShowMigrateModal(true); },
        { delay: 1200 },
        { faIcon: null, buttonTranslateKey: t('REPORT.EDITOR.MIGRATION.TO_CK.BUTTON') }
      );
    }
  }, []);

  const viewOnlyModeConfig =
    <div className="panel-body">
      {!!contentErrors.length &&
      <Alert data-testid="specPermissionError" className='sq-alert-danger mb15'>
        <p>
          {t('REPORT.CONFIG.CONTENT_FAILED_TO_LOAD_1',
            { number: contentErrors.length, name: sandboxOriginalCreatorName })}
        </p>
        <p>
          {t('REPORT.CONFIG.CONTENT_FAILED_TO_LOAD_2')}
          <a href="#" onClick={sqReportActions.exitSandboxMode}>{t('REPORT.CONFIG.CONTENT_FAILED_TO_LOAD_3')}</a>
          {t('REPORT.CONFIG.CONTENT_FAILED_TO_LOAD_4')}
        </p>
      </Alert>
      }
      {isCKEditor && <ReportConfigAssets viewOnlyMode={viewOnlyMode} />}
      <ReportConfigDates />
    </div>;

  const editModeConfig = <>
    <div className="panel-heading">
      <h4 className="panel-title flexColumnContainer">
        <Icon large={true} icon="fc-journal" extraClassNames="pr10" />
        <span title={worksheetName} className="flexFill truncatedLabel">{worksheetName}</span>
      </h4>
    </div>
    {canUpgrade && toCk &&
    <TextButton
      extraClassNames="width-150 flexSelfCenter sq-btn-organizer-migration"
      label={t(`REPORT.EDITOR.MIGRATION.TO_CK.BUTTON`)}
      size="sm"
      variant="outline"
      onClick={() => setShowMigrateModal(true)}
    />}
    <div className="panel-body">
      <div className="flexRowContainer">
        <ReportConfigMeta isCKEditor={isCKEditor} />
        {isCKEditor && <ReportConfigAssets viewOnlyMode={viewOnlyMode} />}
        <ReportConfigDates />
        <ReportConfigBackups />
        {!toCk && canRevertToFroala &&
        <a className="mt10"
          href="#"
          onClick={() => setShowMigrateModal(true)}>
          {t('REPORT.EDITOR.MIGRATION.TO_FROALA.BUTTON')}
        </a>}
      </div>
      {showMigrateModal &&
      <CkMigrationModal annotationId={sqReportStore.id} onClose={() => setShowMigrateModal(false)}
        ckDisabled={!canUpgrade}
        toCk={toCk} />}
    </div>
  </>;

  return (
    <div className="reportConfig flexRowContainer panel panel-primary">
      {viewOnlyMode && viewOnlyModeConfig}
      {!viewOnlyMode && editModeConfig}
    </div>);
};

/**
 * Export it for usage in AngularJS
 */
export const sqReportConfig = angularComponent(reportConfigBindings, ReportConfig);
