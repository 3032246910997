import React, { useEffect, useMemo, useRef, useState } from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { InvestigateStore } from '@/investigate/investigate.store';
import { TrendStore } from '@/trendData/trend.store';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { TrackService } from '@/track/track.service';
import { ToolPanelHelperService } from '@/services/toolPanelHelper.service';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { SignalFromConditionStore } from '@/hybrid/tools/signalFromCondition/signalFromCondition.store';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { ITEM_TYPES, PREVIEW_ID, PREVIEW_PREFIX, SAMPLE_OPTIONS } from '@/trendData/trendData.module';
import { SignalFromConditionActions } from '@/hybrid/tools/signalFromCondition/signalFromCondition.actions';
import _ from 'lodash';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { INTERPOLATION_METHODS, SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.module';
import { DEBOUNCE, DURATION_TIME_UNITS_ALL } from '@/main/app.constants';
import { ErrorTypeEnum } from 'sdk/model/FormulaErrorOutputV1';
import { useDebounce } from '@/hybrid/core/useDebounce.hook';
import { TrendActions } from '@/trendData/trend.actions';
import { DateTimeService } from '@/datetime/dateTime.service';
import { FrontendDuration, SystemConfigurationService } from '@/services/systemConfiguration.service';
import { UtilitiesService } from '@/services/utilities.service';

const DURATION_KEY_METHOD = 'duration';

const signalFromConditionBindings = bindingsDefinition({
  sqSignalFromConditionStore: injected<SignalFromConditionStore>(),
  sqSignalFromConditionActions: injected<SignalFromConditionActions>(),
  sqInvestigateStore: injected<InvestigateStore>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqTrendStore: injected<TrendStore>(),
  sqTrendActions: injected<TrendActions>(),
  sqTrack: injected<TrackService>(),
  sqToolPanelHelper: injected<ToolPanelHelperService>(),
  sqDateTime: injected<DateTimeService>(),
  sqSystemConfiguration: injected<SystemConfigurationService>(),
  sqUtilities: injected<UtilitiesService>()
});

export const SignalFromCondition: SeeqComponent<typeof signalFromConditionBindings> = () => {
  const {
    sqSignalFromConditionStore,
    sqSignalFromConditionActions,
    sqInvestigateStore,
    sqInvestigateActions,
    sqTrendActions,
    sqDateTime,
    sqTrack,
    sqSystemConfiguration,
    sqUtilities
  } = useInjectedBindings(signalFromConditionBindings);

  const { id, name, originalParameters, inputItem, params, condition } = useFlux(sqSignalFromConditionStore);
  const { item, activeTool } = useFlux(sqInvestigateStore);
  const inProgress = useRef(false);

  const showInputMaximumDurationOverride = !!inputItem && inputItem.itemType === ITEM_TYPES.CAPSULE_SET && !_.get(
    inputItem, 'conditionMetadata.maximumDuration');
  const showBoundingMaximumDurationOverride = condition && !condition.conditionMetadata?.maximumDuration;

  const [color, setColor] = useState('');
  const [maxInterpolation, setMaxInterpolation] = useState<FrontendDuration>();

  const timestampLocationOptions = useMemo(() => {
    if (!(inputItem?.id && inputItem?.itemType)) {
      return [];
    }

    const items =
      inputItem.itemType === ITEM_TYPES.CAPSULE_SET ||
      (inputItem.itemType === ITEM_TYPES.SERIES &&
        sqUtilities.isStringSeries(inputItem))
        ? _.reject(SAMPLE_FROM_SCALARS.KEY_METHODS, 'requiresSignal')
        : SAMPLE_FROM_SCALARS.KEY_METHODS;

    return _.map(items, value => ({
      value: value.key,
      label: value.title
    }));
  }, [SAMPLE_FROM_SCALARS.KEY_METHODS, inputItem, inputItem?.itemType]);

  const isComputedMaximumInterpolation = () => !!_.get(condition, 'condition.conditionMetadata.maxInterpolation') ||
    params.interpolation === INTERPOLATION_METHODS.DISCRETE ||
    params.keyMethod === DURATION_KEY_METHOD;

  const updateParams = (path: string, value: unknown) => {
    const newParams = _.set(_.cloneDeep(params), path, value);
    if (newParams.keyMethod === DURATION_KEY_METHOD) {
      newParams.interpolation = INTERPOLATION_METHODS.LINEAR;
    }

    sqSignalFromConditionActions.setParams(newParams);
  };

  const getFormulaMaxInterpolation = () => {
    const boundingConditionMaxInterpolation =
      sqDateTime.convertDuration(condition?.conditionMetadata?.maxInterpolation);

    if (params.keyMethod === DURATION_KEY_METHOD) {
      return undefined;
    } else if (boundingConditionMaxInterpolation) {
      return boundingConditionMaxInterpolation;
    } else {
      return maxInterpolation; // previous, default, or user-set value;
    }
  };

  const generate = (preview: boolean) => {
    const maxInterpolation = getFormulaMaxInterpolation();
    const sampleDisplayOption = params.interpolation === INTERPOLATION_METHODS.DISCRETE
      ? SAMPLE_OPTIONS.BAR
      : SAMPLE_OPTIONS.LINE;

    if (!params?.stat?.key) return;

    if (preview) {
      sqTrendActions.generatePreviewSeries(
        sqSignalFromConditionActions.getFormula(params, maxInterpolation),
        sqSignalFromConditionActions.getParameters(condition.id, inputItem.id),
        id || PREVIEW_ID,
        color
      ).then(() => {
        const newId = (id === PREVIEW_ID || _.startsWith(id, PREVIEW_PREFIX)) ? id : PREVIEW_PREFIX + id;
        sqTrendActions.setCustomizationProps([{ id: newId, sampleDisplayOption }]);
      });
    } else {
      inProgress.current = true;
      runPreview.cancel();
      sqTrendActions.removePreviewSeries();

      return sqSignalFromConditionActions.generate(condition.id, inputItem.id, color, maxInterpolation)
        .then((id) => {
          sqTrendActions.setCustomizationProps([{ id, sampleDisplayOption }]);
          sqTrack.doTrack('Workbench_Tool', 'Signal from Condition', 'completed');
        })
        .catch(() => {
          sqTrack.doTrack('Workbench_Tool', 'Signal from Condition', 'error');
        })
        .finally(function() {
          inProgress.current = false;
        });
    }
  };

  const runPreview = useDebounce(() => {
    if (!inProgress.current && activeTool === TREND_TOOLS.SIGNAL_FROM_CONDITION) {
      generate(true);
    }
  }, DEBOUNCE.PREVIEW);

  useEffect(() => {
    const previousMaxInterpolation = _.get(sqSignalFromConditionStore, 'signalMetadata.maxInterpolation');
    if (previousMaxInterpolation && !isComputedMaximumInterpolation()) {
      setMaxInterpolation(previousMaxInterpolation);
    } else {
      setMaxInterpolation(sqSystemConfiguration.defaultMaxInterpolation);
    }

    return () => {
      sqTrendActions.removePreviewSeries();
      runPreview.cancel && runPreview.cancel();
      sqTrendActions.cancelPreviewSeries();
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(runPreview, DEBOUNCE.PREVIEW);

    return () => clearTimeout(timer);
  }, [inputItem, condition, params]);

  const formDataSetup: FormElement[] = [
    {
      component: 'SearchTitleFormComponent',
      name: 'signalFromConditionTitle',
      value: name,
      onChange: name =>
        sqInvestigateActions.setSearchName(TREND_TOOLS.SIGNAL_FROM_CONDITION, name),
      id,
      onColorChange: setColor,
      searchIconClass: 'fc-series-gen',
      defaultName: 'SIGNAL_FROM_CONDITION.HEADER'
    },
    {
      component: 'FormGroup',
      name: 'signalConditionFormGroup',
      displayNumber: true,
      components: [
        {
          component: 'LabelFormComponent',
          name: 'signalConditionLabel',
          value: 'SIGNAL_FROM_CONDITION.SELECT_SIGNAL_OR_CONDITION'
        },
        {
          component: 'FormGroup',
          name: 'signalConditionGroup',
          showBracket: true,
          components: [
            {
              component: 'ItemSelectFormComponent',
              name: 'inputItem',
              testId: 'signalConditionInput',
              value: inputItem?.id,
              onChange: item => sqInvestigateActions.setParameterItem(TREND_TOOLS.SIGNAL_FROM_CONDITION, 'inputItem',
                item),
              itemTypes: [ITEM_TYPES.SERIES, ITEM_TYPES.CAPSULE_SET],
              additionalItems: originalParameters,
              excludedIds: id,
              includeMetadata: true,
              extraClassNames: 'forceNoBottomMargin'
            },
            {
              component: 'MaxCapsuleDurationFormComponent',
              name: 'inputMaximumDurationOverride',
              testId: 'inputMaximumDurationOverride',
              includeIf: showInputMaximumDurationOverride,
              maxDurationRequired: true,
              value: params.maximumDuration.inputOverride,
              onChange: (maximumDuration) => {
                sqSignalFromConditionActions.setParams(_.set(params, 'maximumDuration.inputOverride', maximumDuration));
              },
              label: 'MAXIMUM_CAPSULE_DURATION',
              tooltip: 'MAXIMUM_CAPSULE_DURATION_TOOLTIP',
              extraClassNames: 'mt10'
            }
          ]
        }
      ]
    },
    {
      component: 'FormGroup',
      name: 'statisticFormGroup',
      displayNumber: true,
      components: [
        {
          component: 'LabelFormComponent',
          name: 'statisticFormGroupLabel',
          value: 'SIGNAL_FROM_CONDITION.SELECT_STATISTIC'
        },
        {
          component: 'StatisticSelectorFormComponent',
          name: 'statistic',
          testId: 'selectStatistic',
          extraClassNames: 'flexFillOverflow',
          isRequired: true,
          outputType: ['sample'],
          item: inputItem,
          onChange: (value) => {
            updateParams('stat', value);
          },
          value: params.stat,
          onValidate: () => {},
          validation: value => !inputItem || !value?.key
        }
      ]
    },
    {
      component: 'FormGroup',
      name: 'boundingConditionFormGroup',
      displayNumber: true,
      components: [
        {
          component: 'LabelFormComponent',
          name: 'signalConditionLabel',
          value: 'SIGNAL_FROM_CONDITION.SELECT_BOUNDS_CONDITION'
        },
        {
          component: 'FormGroup',
          name: 'boundingConditionGroup',
          showBracket: true,
          components: [
            {
              component: 'ItemSelectFormComponent',
              name: 'condition',
              testId: 'boundingConditionInput',
              value: condition?.id,
              onChange: item => sqInvestigateActions.setParameterItem(TREND_TOOLS.SIGNAL_FROM_CONDITION, 'condition',
                item),
              itemTypes: [ITEM_TYPES.CAPSULE_SET],
              additionalItems: originalParameters,
              includeMetadata: true,
              extraClassNames: 'forceNoBottomMargin'
            },
            {
              component: 'MaxCapsuleDurationFormComponent',
              name: 'boundingMaximumDurationOverride',
              testId: 'boundingMaximumDurationOverride',
              includeIf: showBoundingMaximumDurationOverride,
              maxDurationRequired: true,
              value: params.maximumDuration.boundingOverride,
              onChange: (maximumDuration) => {
                updateParams('maximumDuration.boundingOverride', maximumDuration);
              },
              label: 'MAXIMUM_CAPSULE_DURATION',
              tooltip: 'MAXIMUM_CAPSULE_DURATION_TOOLTIP',
              extraClassNames: 'mt10'
            }
          ]
        }
      ]
    },
    {
      component: 'SelectFormComponent',
      displayNumber: true,
      name: 'keyMethod',
      testId: 'keyMethod',
      value: params.keyMethod,
      label: 'SIGNAL_FROM_CONDITION.SELECT_TIMESTAMP_LOCATION',
      placeholder: 'SIGNAL_FROM_CONDITION.SELECT_TIMESTAMP_LOCATION_PLACEHOLDER',
      onChange: (value) => {
        updateParams('keyMethod', value);
      },
      options: timestampLocationOptions,
      required: true,
      popoverContent: <img src="/img/timeValueHelp.png" alt="Time Value Help" className="width-175 height-175" />
    },
    {
      component: 'ButtonGroupFormComponent',
      displayNumber: true,
      name: 'interpolation',
      testId: 'interpolation',
      value: params.interpolation,
      label: 'SIGNAL_FROM_CONDITION.INTERPOLATION_METHOD',
      onChange: (value) => {
        updateParams('interpolation', value);
      },
      includeIf: params.keyMethod !== DURATION_KEY_METHOD,
      buttonItems: [
        {
          label: 'SIGNAL_FROM_CONDITION.DISCRETE',
          id: 'signalFromConditionInterpolateDiscrete',
          value: INTERPOLATION_METHODS.DISCRETE
        },
        {
          label: 'SIGNAL_FROM_CONDITION.LINEAR',
          id: 'signalFromConditionInterpolateLinear',
          value: INTERPOLATION_METHODS.LINEAR
        },
        {
          label: 'SIGNAL_FROM_CONDITION.STEP',
          id: 'signalFromConditionInterpolateStep',
          value: INTERPOLATION_METHODS.STEP
        }
      ]
    },
    {
      component: 'ValueWithUnitsFormComponent',
      includeIf: !isComputedMaximumInterpolation(),
      displayNumber: true,
      testId: 'maxInterpolation',
      name: 'maxInterpolation',
      min: 0,
      value: maxInterpolation,
      availableUnits: DURATION_TIME_UNITS_ALL,
      label: 'IMPORTS.INTERPOLATION.MAXGAP',
      tooltip: 'SIGNAL_FROM_CONDITION.MAX_INTERPOLATION_HELP',
      onChange: (value) => {
        setMaxInterpolation(value);
        runPreview();
      }
    },
    {
      component: 'LabelFormComponent',
      value: 'CAPSULE_DURATION.NO_LONGER_REQUIRED',
      includeIf: item.errorType === ErrorTypeEnum.MAXDURATIONPROHIBITED,
      name: 'durationNotRequired',
      testId: 'durationNotRequired',
      extraClassNames: 'sq-text-danger fs12 ml20 mb20'
    }
  ];

  const handleSubmit = () => {
    generate(false);
  };

  return (
    <ToolPanelFormBuilder
      formDefinition={formDataSetup}
      submitFn={handleSubmit}
      closeFn={() => {
        sqInvestigateActions.close();
      }}
      toolName={TREND_TOOLS.SIGNAL_FROM_CONDITION}
      toolId={TREND_TOOLS.SIGNAL_FROM_CONDITION}
      submitBtnId="executeSignalFromConditionTool"
    />
  );
};

export const sqSignalFromCondition = angularComponent(
  signalFromConditionBindings,
  SignalFromCondition
);
