import angular from 'angular';

const dependencies = [
  'Sq.Services.CalculationRunner'
];

/**
 * @file Module for service that facilitates creating a items from tool panels.
 */
angular.module('Sq.Services.ToolRunner', dependencies);

