import React from 'react';
import { Placement } from 'react-bootstrap/Overlay';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

const toolbarDropdownTitleBindings = bindingsDefinition({
  /** icon class to be used with the dropdown (i.e. 'fc-zoom') */
  icon: prop<string>(),
  /** label for the toolbar item (shown only when toolbar is wide enough, pass in the translation key) */
  label: prop<string>(),
  /** text to be displayed on the icon's tooltip (pass in the translation key) */
  tooltipText: prop.optional<string>(),
  tooltipPlacement: prop.optional<Placement>(),
  /** true to render an icon-only dropdown button */
  isSmall: prop.optional<boolean>(),
  testId: prop.optional<string>()
});

/**
 * Toolbar dropdown title, for use by ToolbarDropdownButton
 */
export const ToolbarDropdownTitle: SeeqComponent<typeof toolbarDropdownTitleBindings> = (props) => {
  const {
    icon,
    label,
    tooltipText,
    tooltipPlacement = 'top',
    isSmall = false,
    testId
  } = props;

  const { t } = useTranslation();

  return (
    <HoverTooltip placement={tooltipPlacement} text={tooltipText}>
      <span className="flexRowContainer flexAlignCenter" data-testid={testId}>
        <span className="nowrap">
          <Icon icon={icon} type="text" />
          <Icon icon="fa-caret-down" extraClassNames="pl3 small" type="text" />
        </span>
        {!isSmall && <small>{t(label)}</small>}
      </span>
    </HoverTooltip>
  );
};
