import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import React from 'react';
import _ from 'lodash';
import { useRef } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { NotificationsService } from '@/services/notifications.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

/**
 * Renders an input field with a button to copy the contents of the field to the clipboard.
 */
const inputSelectOnFocusBindings = bindingsDefinition({
  /** an identifier to be used in tests */
  testId: prop<string>(),
  /** true if the field should be read only, false otherwise */
  readOnly: prop<boolean>(),
  /** string to display in the tooltip when hovering the input field */
  fieldTooltip: prop<string>(),
  /** string to display in the tooltip when hovering the button */
  buttonTooltip: prop<string>(),
  /** value to show in the input field */
  value: prop<string>(),
  /** notification message sent to user */
  notifyMessage: prop.optional<string>(),
  sqNotifications: injected<NotificationsService>()
});

export const CopyableInputField: SeeqComponent<typeof inputSelectOnFocusBindings> = (props) => {
  const {
    testId,
    readOnly,
    fieldTooltip,
    buttonTooltip,
    value,
    notifyMessage
  } = props;

  const inputValue = useRef(null);

  const onFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const { sqNotifications } = useInjectedBindings(inputSelectOnFocusBindings);
  const { t } = useTranslation();

  const copyToClipboard = () => {
    inputValue.current.select();
    document.execCommand('copy');

    if (!_.isNil(notifyMessage)) {
      sqNotifications.success(`${t(notifyMessage)}`);
    }
  };

  return (
    <InputGroup>
      <HoverTooltip text={fieldTooltip}>
        <FormControl data-testid={testId} autoComplete="off" type="text"
          readOnly={readOnly}
          ref={inputValue}
          value={value}
          onFocus={onFocus} />
      </HoverTooltip>
      <HoverTooltip text={buttonTooltip}>
        <div className="input-group-btn">
          <button
            className="btn btn-default"
            data-testid={`${testId}-copyButton`}
            onClick={copyToClipboard}>
            <Icon icon="fa-copy" />
          </button>
        </div>
      </HoverTooltip>
    </InputGroup>
  );
};

export const sqCopyableInputField = angularComponent(inputSelectOnFocusBindings, CopyableInputField);
