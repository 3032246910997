import _ from 'lodash';
import angular from 'angular';
import moment from 'moment-timezone';
import tinycolor from 'tinycolor2';
import { Force, Node } from 'labella';
import { CursorActions } from '@/trendData/cursor.actions';
import { CursorStore } from '@/trendData/cursor.store';
import { DurationStore } from '@/trendData/duration.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { UtilitiesService } from '@/services/utilities.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { TrendStore } from '@/trendData/trend.store';
import { TrendScalarStore } from '@/trendData/trendScalar.store';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { NumberHelperService } from '@/core/numberHelper.service';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import {
  AUTO_UPDATE,
  ITEM_TYPES,
  SAMPLE_OPTIONS,
  SHADED_AREA_CURSORS,
  TREND_STORES,
  TREND_VIEWS
} from '@/trendData/trendData.module';
import { STRING_UOM } from '@/main/app.constants';

angular
  .module('Sq.TrendViewer')
  .service('sqCursors', sqCursors);

export type CursorsService = ReturnType<typeof sqCursors>;

function sqCursors(
  sqCursorActions: CursorActions,
  sqCursorStore: CursorStore,
  sqDurationStore: DurationStore,
  sqTrendSeriesStore: TrendSeriesStore,
  sqUtilities: UtilitiesService,
  sqDateTime: DateTimeService,
  sqTrendStore: TrendStore,
  sqTrendScalarStore: TrendScalarStore,
  sqTrendCapsuleStore: TrendCapsuleStore,
  sqWorksheetStore: WorksheetStore,
  sqNumberHelper: NumberHelperService,
  sqTrendDataHelper: TrendDataHelperService
) {
  let hoverCursor = {} as any;
  let storeCursors = [];
  let nowCursor = {} as any;
  const POINT_MARKER_SIZE = 4;
  const LABEL_HEIGHT = 15; // Forcing the height prevents IE from adding extra padding to the bottom
  const LABEL_X_OFFSET = 4;
  const LABEL_CAPSULE_LINE_HEIGHT = 6;
  const Z_INDEX = 10001;

  const service = {
    syncCursorsWithStore,
    updateCursorItems,
    drawCursors,
    drawNowCursor,
    calculatePointerValues,
    drawHoverCursor,
    clearHoverCursor,
    createCursor,
    formatXLabel,
    // The following are exposed for test
    drawXLabel
  };

  return service;

  /**
   @typedef cursor
   @type {Object}
   @property {Number} xValue - x-value of the cursor location
   @property {Number} xPixel - x-pixel location of the cursor
   @property {SVGElement} crosshair - SVG element for the vertical crosshair
   @property {SVGElement} anchor - SVG element for the named anchor
   @property {SVGElement} xLabel - SVG element for the x-label flag
   @property {Object[]} points - Object map of all items for this cursor, with the item id as the key
   @property {Array} points[id] - array of all the poinst associated with this item
   @property {Number} points[id][].yValue - y-value for the id specified at the cursor location
   @property {Number} points[id][].yPixel - y-pixel for the id specified at the cursor location
   @property {SVGElement} points[id][].circle - SVG element for the point indicator for the id specified
   @property {SVGElement} points[id][].label - SVG element for the point flag for the id specified
   */

  /**
   * Sync the sqCursorStore into the local cursors object, pruning out any cursors which have been deleted
   *
   * @param {Boolean} isCapsuleTime - If true, the capsuletime cursors will sync; otherwise, calendar time cursors
   *   will sync
   */
  function syncCursorsWithStore(isCapsuleTime) {
    const currentCursors = isCapsuleTime ? sqCursorStore.capsuleCursors : sqCursorStore.calendarCursors;
    const orphanedXValues = _.difference(_.map(storeCursors, 'xValue'), _.map(currentCursors, 'xValue'));

    _.forEach(orphanedXValues, (orphanX) => {
      const orphan = _.find(storeCursors, ['xValue', orphanX]);
      if (orphan) {
        deleteCursor(orphan);
        _.remove(storeCursors, orphan);
      }
    });

    _.forEach(currentCursors, (cursor: any) => {
      let storeCursor = _.find(storeCursors, ['xValue', cursor.xValue]);

      if (!storeCursor) {
        storeCursor = {
          xValue: cursor.xValue,
          points: {}
        };
      } else if (!_.isEqual(_.mapValues(cursor.points, 'length'), _.mapValues(storeCursor.points, 'length'))) {
        // y values have changed
        deleteCursor(storeCursor);
        _.remove(storeCursors, storeCursor);
        storeCursor = {
          xValue: cursor.xValue,
          points: {}
        };
      }
      _.merge(storeCursor, cursor);
      storeCursors.push(storeCursor);
    });

    // if we are in chain view we need to ensure we filter out all the cursors that are dropped outside a capsule
    storeCursors = _.reject(storeCursors, cursor => sqTrendStore.view === TREND_VIEWS.CHAIN &&
      _.some(sqTrendCapsuleStore.stitchBreaks, ({ from, to }) => _.inRange(cursor.xValue, from, to)));
  }

  /**
   * Redraw all cursors using the current chart extents, recalculating the pixel values from the x/y-values in each
   * cursor.
   *
   * @param {Highchart} chart - Highcharts chart on which to draw cursors
   * @param {Number} capsuleLaneHeight - Number of Pixels taken up by Capsules
   */
  function drawCursors(chart, capsuleLaneHeight) {
    // Make sure that the chart has been created
    if (!chart) {
      return;
    }
    const capsuleTime = sqTrendStore.view === TREND_VIEWS.CAPSULE;
    drawNowCursor(chart);

    // draw selected cursors after un-selected ones so they appear in the foreground
    const [selectedDisplayCursors, unselectedDisplayCursors] = _.partition(storeCursors, 'selected');

    _.forEach(unselectedDisplayCursors,
      cursor => drawCursor(chart, capsuleLaneHeight, cursor, capsuleTime));
    _.forEach(selectedDisplayCursors,
      cursor => drawCursor(chart, capsuleLaneHeight, cursor, capsuleTime)
    );
  }

  /**
   * Redraw the specified cursor using the current chart extents, recalculating the pixel values from the x/y-values
   * in the cursor. For series that are displayed as bars or series that are displayed "samples only" cursors are
   * only shown when there is actual data visible (aka, the mouse is over the bar or over the sample).
   *
   * @param {Highcharts.Chart} chart - Highcharts chart on which to draw cursors
   * @param {Number} capsuleLaneHeight - Number of Pixels taken up by Capsules
   * @param {cursor} cursor - Cursor to redraw
   * @param {boolean} capsuleTime - flag indicating capsule time
   * @param {boolean} [showXLabel] - flag to show or hide x value label (default true)
   * @param {String} [crosshairColor] - what color the crosshair cursor line should be
   */
  function drawCursor(chart, capsuleLaneHeight, cursor, capsuleTime, showXLabel = true, crosshairColor = '#C0D0E0') {
    if (updateCursorPixels(chart, cursor, capsuleLaneHeight)) {
      if (showXLabel) {
        drawXLabel(chart, cursor);
      }
      drawCrosshair(chart, cursor, capsuleLaneHeight, crosshairColor);
      _.forEach(cursor.points, function(points, id) {
        const item = sqTrendSeriesStore.findItem(id) || sqTrendScalarStore.findItem(id) ||
          sqTrendCapsuleStore.findChartItem(id);
        _.forEach(points, (point) => {
          if (item && (_.isNumber(point.yValue) || !_.isEmpty(point.yValue))) {
            if (item.sampleDisplayOption === SAMPLE_OPTIONS.SAMPLES || item.sampleDisplayOption === SAMPLE_OPTIONS.BAR) {
              if (point.showIndicator) {
                drawCursorPointLabel(chart, cursor, point, item);
                if (item.sampleDisplayOption === SAMPLE_OPTIONS.SAMPLES) {
                  drawCursorPointCircle(chart, cursor, point, item);
                }
              }
            } else {
              if (point.showIndicator) {
                drawCursorPointCircle(chart, cursor, point, item);
              }

              drawCursorPointLabel(chart, cursor, point, item);
            }

          } else {
            deleteCursorPoint(point);
          }
        });
      });

      deconflictLabels(cursor, chart, capsuleLaneHeight, showXLabel);
      // anchors are drawn last to ensure they are displayed on top of the value labels so they remain selectable.
      drawAnchor(chart, cursor, capsuleLaneHeight, capsuleTime);
    } else {
      deleteCursor(cursor);
    }
  }

  /**
   * Translates the x/y value locations to x/y pixel locations for the specified cursor
   *
   * @param {Highchart} chart - Highcharts chart on which to draw cursors
   * @param {cursor} cursor - the cursor to update
   * @param {Number} capsuleLaneHeight - the number of pixels taken up by the capsule lane
   * @returns True if cursor is within the bounds of the current trend display and had its xPixel and yPixel
   *   values updated, otherwise false
   */
  function updateCursorPixels(chart, cursor, capsuleLaneHeight) {
    if (!chart) {
      return false;
    }

    const xAxis = chart.xAxis[0];

    // Skip updating anything that is off screen
    if (cursor.xValue < getStartValue() || cursor.xValue > getEndValue()) {
      _.forEach(cursor.points, function(points) {
        _.forEach(points, (point) => {
          point.yPixel = undefined;
        });
      });

      return false;
    }

    cursor.xPixel = xAxis.translate(cursor.xValue) + chart.plotBox.x;

    const laneBuffer = sqUtilities.getLaneBuffer(sqTrendStore.view === TREND_VIEWS.CAPSULE);
    // Look up the item in the store because the properties on the userOptions are not always up to date
    const trendItems = _.chain(chart.series)
      .map('userOptions.id')
      .map(id => sqTrendDataHelper.findItemIn(TREND_STORES, id))
      .value();
    const lanes = _.reverse(sqUtilities.getUniqueOrderedValuesByProperty(trendItems, 'lane', sqTrendStore.lanes));
    const laneCount = lanes.length;
    const chartHeightWithoutCapsules = chart.plotBox.height - capsuleLaneHeight - (laneCount - 1) * laneBuffer;
    const laneHeight = chartHeightWithoutCapsules / laneCount;

    _.forEach(cursor.points, function(points, id) {
      const item = sqTrendSeriesStore.findItem(id) || sqTrendScalarStore.findItem(id) ||
        sqTrendCapsuleStore.findChartItem(id);
      _.forEach(points, (point) => {
        let yValue = point.yValue;

        // Skip any series that are no longer on the chart; the chart graphical items for this point will be removed in
        // .drawCursor()
        if (!item) {
          point.yPixel = undefined;
          return;
        }

        if (item.isStringSeries) {
          // use the stringEnum to figure out the yValue to use with the axis
          yValue = _.chain(item.stringEnum)
            .find(['stringValue', yValue])
            .get('key', '0')
            .value();
        }

        const yAxis = chart.getItemYAxis(item);

        if (!yAxis) {
          // Skip points without an axis
          point.yPixel = undefined;
        } else if (item.itemType === ITEM_TYPES.CAPSULE) {
          // Capsules don't need to worry about being outside their lane, so can use the built-in positioner
          point.yPixel = yAxis.toPixels(yValue);
        } else {
          let yPixelRelative = yAxis.translate(yValue);
          // For points outside of the lane show them at the edge of the lane
          if (yPixelRelative < 0) {
            point.showIndicator = false;
            yPixelRelative = 0;
          } else if (yPixelRelative > laneHeight) {
            point.showIndicator = false;
            yPixelRelative = laneHeight;
          }

          const laneIndex = lanes.indexOf(_.get(item, 'lane', 1));
          point.yPixel = chart.plotBox.height - yPixelRelative - laneIndex * (laneHeight + laneBuffer);
        }

      });
    });

    return true;
  }

  function cursorSVGPrefix(cursor) {
    return cursor.name && !cursor.selected ? 'cursor' : 'hover';
  }

  /**
   * Draw a vertical crosshair at the specified cursor location
   *
   * @param {Highchart} chart - Highcharts chart on which to draw cursors
   * @param {cursor} cursor - Information about the cursor to draw
   * @param {Number} capsuleLaneHeight - Number of Pixels taken up by Capsules
   * @param {String} [crosshairColor] - what color the crosshair cursor line should be
   */
  function drawCrosshair(chart, cursor, capsuleLaneHeight, crosshairColor = '#C0D0E0') {
    _.result(cursor, 'crosshair.destroy');
    cursor.crosshair = chart.renderer.rect(cursor.xPixel, chart.plotBox.y, 1,
      chart.plotBox.height, 0)
      .attr({
        zIndex: 1,
        class: cursorSVGPrefix(cursor) + '-crosshair',
        stroke: crosshairColor,
        'stroke-width': 0.5,
        fill: crosshairColor,
        'pointer-events': 'none'
      })
      .add();
  }

  /**
   * Draw the anchor icon at the top of a named cursor. If the cursor does not have a name, nothing is drawn.
   *
   * @param {Highchart} chart - Highcharts chart on which to draw
   * @param {cursor} cursor - Information about the cursor to draw
   * @param {Number} capsuleLaneHeight - Number of Pixels taken up by Capsules
   */
  function drawAnchor(chart, cursor, capsuleLaneHeight, capsuleTime) {
    cursor.anchor = _.result(cursor, 'anchor.destroy');
    cursor.deleteIcon = _.result(cursor, 'deleteIcon.destroy');

    if (cursor.name) {
      // vertically offset the cursor label to ensure it is not covered by the lane labels and can be clicked
      cursor.anchor = chart.renderer.label(cursor.name, cursor.xPixel, chart.plotBox.y + 14, 'rect',
        null, null, false, false, cursorSVGPrefix(cursor) + '-anchor')
        .attr({
          fill: '#e5e4e2',
          zIndex: Z_INDEX
        })
        .css({ fontSize: '11px' })
        .on('click', e => sqCursorActions.toggleCursorSelection(cursor, false, e.ctrlKey || e.metaKey))
        .add();

      if (cursor.selected) {
        const normalState = {
          zIndex: Z_INDEX + 10,
          fill: null,
          'stroke-width': 0,
          style: { color: '#999999', 'font-size': '10px' }
        };

        const hoverState = {
          ...normalState,
          style: { color: '#D9534F', 'font-size': '10px', 'font-weight': 'bold' }
        };

        cursor.deleteIcon = chart.renderer.button('x',
          cursor.xPixel + _.get(cursor.anchor, 'width', 0) - 12,
          chart.plotBox.y, () => sqCursorActions.deleteCursor(cursor.xValue, capsuleTime),
          normalState, hoverState, hoverState, hoverState)
          .add();
      }
    }
  }

  /**
   * Draw the x-value label at the bottom of a named cursor. If the cursor does not have a value, nothing is drawn.
   *
   * @param {Highchart} chart - Highcharts chart on which to draw
   * @param {cursor} cursor - Information about the cursor to draw
   */
  function drawXLabel(chart, cursor) {
    const offset = 9;

    cursor.xLabel = _.result(cursor, 'xLabel.destroy');

    if (sqCursorStore.showValues) {
      cursor.xLabel = chart.renderer.label(formatXLabel(cursor.xValue), cursor.xPixel,
        chart.plotBox.height + chart.plotBox.y - offset, 'rect', null, null, false, false,
        cursorSVGPrefix(cursor) + '-x-label')
        .attr({
          fill: '#EAF3F4',
          zIndex: Z_INDEX + 1,
          height: LABEL_HEIGHT,
          r: 2,
          padding: 1
        })
        .add();

      // Center it under the cursor if there is enough room
      const midpointX = cursor.xPixel - cursor.xLabel.width / 2;
      const chartRightX = chart.plotBox.x + chart.plotBox.width;
      if (midpointX < chartRightX && midpointX > chart.plotBox.x) {
        if (midpointX + cursor.xLabel.width < chartRightX) {
          cursor.xLabel.xSetter(midpointX);
        } else {
          cursor.xLabel.xSetter(cursor.xPixel - cursor.xLabel.width);
        }
      }
    }
  }

  /**
   * Format the x-label from the xValue of the cursor. xValue is formatted as either a date/time or a duration
   * as appropriate.
   *
   * @param {Number} xValue - xValue of the cursor
   * @return {String} Formatted date/time or duration
   */
  function formatXLabel(xValue) {
    // Use a "magic number" here to determine if the number should be formatted as an absolute time or relative.
    // The magic number is the value of Jan 1, 1971 - 1 year from the epoch.
    const breakpoint = 31536000000;

    if (xValue > breakpoint) {
      return moment(xValue).tz(sqWorksheetStore.timezone.name).format('l') + ' ' +
        moment(xValue).tz(sqWorksheetStore.timezone.name).format('LTS');
    } else {
      return sqDateTime.formatDuration(xValue);
    }
  }

  /**
   * Redraw the point circle for a specific point in a cursor.
   *
   * @param {Highchart} chart - Highcharts chart on which to draw cursors
   * @param {cursor} cursor - Cursor in which the point exists
   * @param {Number} cursor.xPixel - x-pixel location of this cursor
   * @param {Object} point - Point to redraw
   * @param {Number} point.yPixel - y-pixel location of this cursor
   * @param {Object} item - Series item for this point
   * @param {Color} item.color - Color for this point
   */
  function drawCursorPointCircle(chart, cursor, point, item) {
    point.circle = _.result(point, 'circle.destroy');

    if (_.isFinite(point.yPixel)) {
      point.circle = chart.renderer.circle(cursor.xPixel, point.yPixel, POINT_MARKER_SIZE)
        .attr({
          class: 'highcharts-' + cursorSVGPrefix(cursor) + '-point',
          fill: item.color,
          'pointer-events': 'none',
          stroke: 'white',
          'stroke-width': 1,
          zIndex: Z_INDEX
        })
        .add();
    }
  }

  /**
   * Redraw the point label for a specific point in a cursor.
   *
   * @param {Highchart} chart - Highcharts chart on which to draw cursors
   * @param {cursor} cursor - Cursor in which the point exists
   * @param {Number} cursor.xPixel - x-pixel location of this cursor
   * @param {Object} point - Point to redraw
   * @param {String} point.yValue - y-value to draw in the label
   * @param {Number} point.yPixel - y-pixel location of this cursor
   * @param {Object} item - Series item for this point
   * @param {String} item.color - Color for this point
   */
  function drawCursorPointLabel(chart, cursor, point, item) {
    point.label = _.result(point, 'label.destroy');

    if (sqCursorStore.showValues && _.isFinite(point.yPixel)) {
      if (!_.isUndefined(point.labelText)) {
        if (point.labelText) {
          const labelText = `<span style="border-color: ${item.color}">${point.labelText}</span>`;
          const lineCount = (point.labelText.match(/<br>/g) || []).length + 1;
          const yPixel = point.yPixel - lineCount * LABEL_CAPSULE_LINE_HEIGHT;
          point.label = chart.renderer.label(labelText, cursor.xPixel + LABEL_X_OFFSET, yPixel, 'rect',
            cursor.xPixel, point.yPixel, true, false, 'cursor-capsule-label')
            .attr({
              padding: 0
            })
            .css({
              fontSize: '10px',
              fontFamily: 'inherit'
            })
            .add();
        }
      } else {
        let labelText = _.isFinite(point.yValue) ? sqNumberHelper.formatNumber(point.yValue, item.formatOptions) :
          point.yValue;
        if (labelText && (point.valueUnitOfMeasure || point.sourceValueUnitOfMeasure)) {
          const unitOfMeasure = point.valueUnitOfMeasure
            ? point.valueUnitOfMeasure
            : `<span style="font-style: italic;">${point.sourceValueUnitOfMeasure}</span>`;
          labelText = `${labelText} ${unitOfMeasure}`;
        }

        point.label = chart.renderer.label(labelText, cursor.xPixel + LABEL_X_OFFSET, point.yPixel, 'rect',
          cursor.xPixel, point.yPixel, false, true, cursorSVGPrefix(cursor) + '-y-label')
          .attr({
            fill: item.color,
            zIndex: Z_INDEX,
            height: LABEL_HEIGHT,
            padding: 2,
            r: 2
          })
          .css({
            color: tinycolor(item.color).isDark() ? '#fff' : '#000'
          })
          .add();
      }
    }
  }

  /**
   * Adjust the location of a set of labels for a cursor to keep them from overlapping. The algorithm attempts to
   * put them as close to the ideal Y position as possible without overlapping the X-value label. If there is no
   * room for a label it is hidden.
   *
   * @param {cursor} cursor - Cursor with the points that have SVG labels
   * @param {Highchart} chart - Highcharts chart on which to draw cursors
   * @param {Number} capsuleLaneHeight - Number of Pixels taken up by Capsules
   * @param [boolean] showXLabel - flag to show or hide x value label (default true)
   */
  function deconflictLabels(cursor, chart, capsuleLaneHeight, showXLabel = true) {
    const LABEL_PADDING = 2;
    if (sqCursorStore.showValues) {
      const labels = _.chain(cursor.points).values().flatten().filter('label').map('label').sortBy('y').value();
      const isBeyondRight = _.some(labels, label =>
        label.x + label.width > chart.plotBox.x + chart.plotBox.width);
      const nodes = _.chain(labels)
        .filter('padding') // Filter to remove capsule labels which don't need deconflicting
        .map(label => new Node(label.y, label.height, label))
        .value();
      let forceInput;
      if (showXLabel) {
        forceInput = new Force({
          algorithm: 'none',
          minPos: LABEL_PADDING,
          maxPos: cursor.xLabel?.y,
          nodeSpacing: 1
        });
      } else {
        forceInput = new Force();
      }
      const force = forceInput
        .nodes(nodes)
        .compute();
      _.forEach(force.nodes(), (node: any) => {
        if (isBeyondRight) {
          node.data.xSetter(node.data.x - node.data.width - LABEL_X_OFFSET);
        }

        if (node.currentPos + node.data.height - LABEL_PADDING > chart.plotBox.height ||
          node.currentPos < capsuleLaneHeight) {
          // Remove those that would bleed onto the capsules lane or out the bottom
          node.data.css({ display: 'none' });
        } else if (node.currentPos !== node.idealPos) {
          node.data.ySetter(node.currentPos);
        }
      });
    }
  }

  /**
   * Draws the vertical crosshair on the chart at the x-location of the pointer
   *
   * @param {Highchart} chart - Highcharts chart on which to draw cursors
   * @param {Number} xPixel - x-pixel coordinate location, in the reference frame of the plot area
   * @param {Number} xValue - value of the cursor along the x-axis
   * @param {Object[]} yValues - array of objects for all series on the chart, with the id as the key
   * @param {String} yValues[].id - id of the series
   * @param {Number} yValues[].pointValue - interpolated y-value of this series at the specified x-pixel location
   * @param {String} yValues[].valueUnitOfMeasure - Unit of measure for the y-value
   * @param {Number} capsuleLaneHeight - Number of Pixels taken up by Capsules
   * @param {boolean} [showXLabel] - flag to show or hide x value label (default true)
   * @param {String} [crosshairColor] - what color the crosshair cursor line should be
   */
  function drawHoverCursor(chart, xPixel, xValue, yValues, capsuleLaneHeight, showXLabel = true,
    crosshairColor = '#C0D0E0') {
    service.clearHoverCursor();
    const capsuleTime = sqTrendStore.view === TREND_VIEWS.CAPSULE;
    hoverCursor.xValue = xValue;
    hoverCursor.xPixel = xPixel;
    hoverCursor.points = _.chain(yValues)
      .groupBy('id')
      .mapValues(vals => _.map(vals, val => ({
        yValue: val.pointValue,
        valueUnitOfMeasure: val.valueUnitOfMeasure,
        sourceValueUnitOfMeasure: val.sourceValueUnitOfMeasure,
        labelText: val.labelText,
        showIndicator: val.showIndicator
      })))
      .value();

    drawCursor(chart, capsuleLaneHeight, hoverCursor, capsuleTime, showXLabel, crosshairColor);
  }

  /**
   * Draws the now cursor (vertical dotted line at current time)
   *
   * @param {Highchart} chart - Highcharts chart on which to draw cursors
   */
  function drawNowCursor(chart) {
    let path;
    const xAxis = chart.xAxis[0];
    _.result(nowCursor, 'nowLine.destroy');
    nowCursor = {};

    if (sqDurationStore.autoUpdate.mode !== AUTO_UPDATE.MODES.OFF) {
      nowCursor.xValue = sqDurationStore.autoUpdate.now;
      // ensure that we not draw a now cursor if it should be hidden by right aligned axis.
      if (nowCursor.xValue < getStartValue() || nowCursor.xValue > getEndValue()) {
        return;
      }
      // We subtract one pixel to ensure the now line is visible on the trend when now mode is turned on and the
      // now line is located at the extreme right side of the trend.
      nowCursor.xPixel = xAxis.translate(nowCursor.xValue) + chart.plotBox.x - 1;
      path = [
        'M', nowCursor.xPixel, chart.plotBox.y,
        'L', nowCursor.xPixel, chart.plotBox.height
      ];
      nowCursor.nowLine = chart.renderer.path(path)
        .attr({
          zIndex: 1,
          stroke: 'black',
          'stroke-width': '1.0',
          'stroke-dasharray': '1, 2',
          'pointer-events': 'none',
          'shape-rendering': 'crispEdges'
        })
        .add();
    }
  }

  /**
   * Determines whether two x-values are close enough to be shown as the value under a cursor.
   *
   * @param {Highchart} chart - Highcharts chart on which to draw cursors
   * @param {Number} xPoint1 - First x-value
   * @param {Number} xPoint2 - Second x-value
   * @return {Boolean} Returns true if the two values are close enough; otherwise, false
   */
  function xValueCloseEnough(chart, xPoint1, xPoint2, series) {
    if (series.itemType === ITEM_TYPES.SCALAR) {
      return true;
    }

    const xAxis = chart.xAxis[0];
    const delta = Math.abs(xAxis.translate(xPoint1) - xAxis.translate(xPoint2));

    if (series.sampleDisplayOption === SAMPLE_OPTIONS.BAR) {
      return delta < series.lineWidth / 2;
    } else {
      return (delta < POINT_MARKER_SIZE / 2);
    }

  }

  /**
   * Creates a new cursor using the current hoverCursor properties
   */
  function createCursor(isCapsuleTime) {
    const points = {};

    if (hoverCursor.xValue >= getStartValue()) {
      _.forEach(hoverCursor.points, function(vals, id) {
        if (vals) {
          points[id] = _.map(vals, (val) => {
            // Never display 'string's value unit of Measure - CRAB-15373
            return _.pickBy(val, (value, key) =>
              _.includes(['yValue', 'labelText'], key) ||
              (_.includes(['sourceValueUnitOfMeasure', 'valueUnitOfMeasure'], key) &&
                value !== STRING_UOM));
          });
        }
      });

      sqCursorActions.addCursor(hoverCursor.xValue, points, isCapsuleTime);
    }
  }

  /**
   * Gets the start value based on the current trend view mode. In capsule time, it will be the lower
   * capsule time offset. In other trend view modes, it will be the display range start value.
   *
   * @returns {Number} The start value
   */
  function getStartValue() {
    return sqTrendStore.view === TREND_VIEWS.CAPSULE ? 0 + sqTrendStore.capsuleTimeOffsets.lower :
      sqDurationStore.displayRange.start.valueOf();
  }

  /**
   * Gets the end value based on the current trend view mode. In capsule time, it will be the upper
   * capsule time offset. In other trend view modes, it will be the display range end value.
   *
   * @returns {Number} The end value
   */
  function getEndValue() {
    return sqTrendStore.view === TREND_VIEWS.CAPSULE ? sqTrendSeriesStore.longestCapsuleSeriesDuration +
      sqTrendStore.capsuleTimeOffsets.upper : sqDurationStore.displayRange.end.valueOf();
  }

  /**
   * Updates points in all existing cursors with the current set of series
   *
   * @param {Highchart} chart - Highcharts chart on which to draw cursors
   * @param {Object[]} items - List of items currently in the chart
   * @param {Boolean} isCapsuleTime - If true, capsule time cursors will be updated; otherwise, calendar time cursors
   *   will be updated
   */
  function updateCursorItems(chart, items, isCapsuleTime) {
    const cursors = isCapsuleTime ? sqCursorStore.capsuleCursors : sqCursorStore.calendarCursors;
    _.chain(cursors)
      .filter((cursor: any) => cursor.xValue >= getStartValue() && cursor.xValue <= getEndValue())
      .forEach((cursor) => {
        const yValues = service.calculatePointerValues(chart, cursor.xValue, items);
        const points = _.chain(yValues)
          .groupBy('id')
          .mapValues(vals => _.map(vals, val => ({
            yValue: val.pointValue,
            // Never display 'string's value unit of Measure - CRAB-15373
            sourceValueUnitOfMeasure: val.sourceValueUnitOfMeasure !== STRING_UOM ? val.sourceValueUnitOfMeasure : '',
            valueUnitOfMeasure: val.valueUnitOfMeasure !== STRING_UOM ? val.valueUnitOfMeasure : '',
            labelText: val.labelText
          })))
          .value();

        sqCursorActions.addCursor(cursor.xValue, points, isCapsuleTime);
      })
      .value();

    service.drawNowCursor(chart);
  }

  /**
   * Returns an array of point values for the specified x position. Values are interpolated as appropriate using the
   * appropriate interpolation method (step, linear).
   *
   * @param {Highchart} chart - Highcharts chart on which to draw cursors
   * @param {Number} xValue - xValue location of pointer
   * @param {Object[]} items - List of items currently in the chart
   * @returns {Object[]} Array of objects, one for each cursor to display. Each object contains the ID of the item,
   *   pointer value, whether to show the indicator, and the x/y values of the closestPoint on the item.
   */
  function calculatePointerValues(chart, xValue, items: any[]) {
    const cursorItems = _.filter(items,
      item => _.includes([ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR, ITEM_TYPES.CAPSULE], item.itemType));
    const selected = _.some(cursorItems, 'selected');

    // Retrieve or interpolate the corresponding y-values as needed
    return _.chain(cursorItems)
      .filter(item => !selected || (selected && item.selected))
      .flatMap((series) => {
        const data = _.result(_.find(chart.series, { userOptions: { id: series.id } }), 'data', []);
        if (!series.shadedAreaUpper || !series.shadedAreaLower) {
          return calculatePointerValue(chart, series, data, xValue, point => _.get(point, 'x'),
            point => _.get(point, 'y'));
        } else {
          const values = [];
          if (_.includes([SHADED_AREA_CURSORS.BOTH, SHADED_AREA_CURSORS.LOWER], series.shadedAreaCursors)) {
            values.push(calculatePointerValue(chart, series, data, xValue, point => _.get(point, 'x'),
              point => _.get(point, 'low')));
          }

          if (_.includes([SHADED_AREA_CURSORS.BOTH, SHADED_AREA_CURSORS.UPPER], series.shadedAreaCursors)) {
            values.push(calculatePointerValue(chart, series, data, xValue, point => _.get(point, 'x'),
              point => _.get(point, 'high')));
          }
          return values;
        }
      })
      .value();
  }

  /**
   * Helper method for calculating a pointer value for a single series. Some series like shadedArea series have
   * multiple points produce by a single series so this function is called multiple times to get the upper and lower
   * values for the series.
   */
  function calculatePointerValue(chart, series, seriesDataArray, xValue, getX, getY) {
    const result = sqUtilities.getYValue(seriesDataArray, xValue, getX, getY);
    let pointValue: number | string = '';
    if (sqTrendStore.view === TREND_VIEWS.CAPSULE && !sqTrendStore.dimDataOutsideCapsules &&
      _.isFinite(result.closestPoint[0]) && (result.closestPoint[0] < 0 || result.closestPoint[0] > series.duration)) {
      // If capsule time and not dimming, then don't show a value outside of a capsule since the samples are not visible
      result.yValue = null;
      result.closestPoint = [null, null];
    }
    const closeEnough = xValueCloseEnough(chart, result.closestPoint[0], xValue, series);

    if (series.itemType === ITEM_TYPES.CAPSULE) {
      return {
        pointValue: result.closestPoint[1],
        id: series.id,
        closestPoint: result.closestPoint,
        showIndicator: closeEnough,
        labelText: _.chain(seriesDataArray)
          .chunk(3)
          // Find the capsule that overlaps the xValue
          .find((group: any[]) => xValue >= group[0].x && xValue <= group[1].x)
          .head()
          .get('labelText', '')
          .value()
      };
    } else if (!series.isStringSeries) {
      // For Bar Charts and Samples only we don't want to get interpolated values as we move across the bar's
      // width, so we use the actual value
      if (_.includes([SAMPLE_OPTIONS.BAR, SAMPLE_OPTIONS.SAMPLES],
        _.get(series, 'sampleDisplayOption', SAMPLE_OPTIONS.LINE)) && closeEnough) {
        pointValue = _.isFinite(result.closestPoint[1]) ? result.closestPoint[1] : '';
      } else if (_.isFinite(result.yValue) && seriesDataArray.length > 1) {
        // If only one point in the series array, then only display a value when the cursor is close enough
        pointValue = result.yValue;
      } else if (closeEnough) {
        /**
         * If we show a hover indicator point in the chart we also expect to see a y-value in the details pane
         * Interpolation doesn't work in that case as with Singleton points there are no neighbors to
         * interpolate with. So, in case there is no y-value but a closestPoint we check to see if that point
         * falls within the delta that displays a hover indicator, and if so, we pull the y-value from that
         * pane to display in the details pane.
         */
        pointValue = _.isFinite(result.closestPoint[1]) ? result.closestPoint[1] : '';
      }

      const { valueUnitOfMeasure, sourceValueUnitOfMeasure } = series;
      return {
        pointValue,
        id: series.id,
        closestPoint: result.closestPoint,
        showIndicator: closeEnough,
        sourceValueUnitOfMeasure,
        valueUnitOfMeasure
      };
    } else {
      if (series.stringEnum) {
        pointValue = _.result(_.find(series.stringEnum, ['key', result.yValue]), 'stringValue');
      } else {
        pointValue = result.yValue;
      }

      return {
        pointValue,
        id: series.id,
        closestPoint: result.closestPoint,
        showIndicator: closeEnough
      };

    }
  }

  /**
   * Destroy the cursor specified from the chart
   *
   * @param  {cursor} cursor - Cursor to delete
   */
  function deleteCursor(cursor) {
    cursor.crosshair = _.result(cursor, 'crosshair.destroy');
    cursor.anchor = _.result(cursor, 'anchor.destroy');
    cursor.xLabel = _.result(cursor, 'xLabel.destroy');
    cursor.deleteIcon = _.result(cursor, 'deleteIcon.destroy');

    _.forEach(cursor.points, points => _.forEach(points, deleteCursorPoint));
  }

  /**
   * Destroy a single cursor point from the chart
   *
   * @param {Object} point - Point to be destroyed
   * @param {SVGElement} point.circle - SVG circle for this point
   * @param {SVGElement} point.label - SVG label for this point
   */
  function deleteCursorPoint(point) {
    point.circle = _.result(point, 'circle.destroy');
    point.label = _.result(point, 'label.destroy');
  }

  /**
   * Removes the vertical crosshair from the chart
   */
  function clearHoverCursor() {
    deleteCursor(hoverCursor);
    hoverCursor = {};
  }
}
