import angular from 'angular';

/**
 * @file Actions to manipulate cursors displayed on the trend.
 */
angular
  .module('Sq.TrendData')
  .service('sqCursorActions', sqCursorActions);
export type CursorActions = ReturnType<typeof sqCursorActions>;

function sqCursorActions(
  flux: ng.IFluxService) {
  const service = {
    addCursor,
    deleteCursor,
    deleteAll,
    deleteSelected,
    setCursorValueFlags,
    toggleCursorSelection
  };

  return service;

  /**
   * Adds a new cursor. If an existing cursor exists with the xValue and isCapsuleTime, it is replaced.
   *
   * @param {number} xValue - x-value of the new cursor.
   * @param {Object} points - Contains all points for this cursor
   * @param {string} points[key] - ID of the series with points
   * @param {number} points[key][].yValue - y-value for the series with the specified ID at the specified x-value
   * @param {Boolean} isCapsuleTime - True if this is a capsule-time cursor, false for calendar-time
   */
  function addCursor(xValue, points, isCapsuleTime) {
    flux.dispatch('TREND_CURSOR_ADD', {
      xValue,
      capsuleTime: isCapsuleTime,
      points
    });
  }

  /**
   * Toggles the selected property on the cursor object.
   *
   * @param {object} cursor - Object representing a cursor.
   * @param {boolean} capsuleTime - True if this is a capsule-time cursor, false for calendar-time
   * @param {boolean} multiSelect - True if multi-selection (by holding CTRL key)
   */
  function toggleCursorSelection(cursor, capsuleTime, multiSelect) {
    flux.dispatch('TREND_CURSOR_TOGGLE_SELECTION', { ...cursor, selected: !cursor.selected, capsuleTime, multiSelect });
  }

  /**
   * Deletes a cursor with the specified parameters.
   *
   * @param {Number} xValue - x-value for the cursor to delete
   * @param {Boolean} isCapsuleTime - True if this is a capsule-time cursor, false for calendar-time
   */
  function deleteCursor(xValue, isCapsuleTime) {
    flux.dispatch('TREND_CURSOR_DELETE', {
      xValue,
      capsuleTime: isCapsuleTime
    });
  }

  /**
   * Deletes all cursors pf the type specified.
   *
   * @param {Boolean} isCapsuleTime - True to delete all capsule-time cursors, false to delete all calendar-time
   *   cursors.
   */
  function deleteAll(isCapsuleTime) {
    flux.dispatch('TREND_CURSOR_DELETE_ALL', { capsuleTime: isCapsuleTime });
  }

  function deleteSelected(isCapsuleTime) {
    flux.dispatch('TREND_CURSOR_DELETE_SELECTED', { capsuleTime: isCapsuleTime });
  }

  /**
   * Sets the display of cursor y-values on/off.
   *
   * @param {Boolean} show - Whether or not to display cursor values
   */
  function setCursorValueFlags(show) {
    flux.dispatch('TREND_CURSOR_SET_VALUE_FLAGS', show);
  }
}
