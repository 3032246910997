import React from 'react';
import classNames from 'classnames';

import { OverlayTrigger, Popover } from 'react-bootstrap';

import classes from './ErrorFallback.module.scss';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { useRandomId } from '@/hybrid/core/useRandomId.hook';

interface ErrorFallbackProps {
  error: Error;
  /** Attempt to re-render the original content */
  retry: () => void;
}

export const ErrorFallback: React.FunctionComponent<ErrorFallbackProps> = ({ error, retry }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}><img src="/img/Seeq_logo_darkBlue_sm.png" /></div>
      <p className={classNames(classes.title, 'h4')}>{t('ERROR_FALLBACK.TITLE')}</p>
      <p>{t('ERROR_FALLBACK.BODY')}</p>
      <p><code>{error.toString()}</code></p>
      <p>{t('ERROR_FALLBACK.RENDER_OR_REFRESH')}</p>
      <button className={classNames(classes.retryButton, 'btn btn-sm btn-primary')} onClick={retry}>
        {t('ERROR_FALLBACK.RETRY')}
      </button>
    </div>
  );
};

export const ErrorFallbackIcon: React.FunctionComponent<ErrorFallbackProps> = ({ error, retry }) => {
  const popoverId = useRandomId();

  const popover = (
    <Popover id={popoverId} bsPrefix='greyPopover'>
        <ErrorFallback error={error} retry={retry}/>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" overlay={popover} transition={false}>
      <i
        data-testid='show-error-fallback-popover'
        className='fa fa-exclamation-triangle fa-lg sq-text-danger cursorPointer'/>
    </OverlayTrigger>
  );
};
