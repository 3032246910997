import React from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { UtilitiesService } from '@/services/utilities.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { ButtonWithDropdown } from '@/hybrid/core/ButtonWithDropdown.atom';
import { Icon } from '@/hybrid/core/Icon.atom';

const basicInfoDropdownBindings = bindingsDefinition({
  sqUtilities: injected<UtilitiesService>(),
  item: prop<any>(),
  setIsOpen: prop<(isOpen: boolean) => void>()
});

export const BasicInfoDropdown: SeeqComponent<typeof basicInfoDropdownBindings> = (props) => {
  const { item, setIsOpen } = props;
  const { sqUtilities } = useInjectedBindings(basicInfoDropdownBindings);
  const { t } = useTranslation();

  return (
    <div className="mr10">
      <ButtonWithDropdown
        icon={<Icon icon="fa-info-circle" extraClassNames="fa-fw fa-xlg folderAction" />}
        iconWrapperClasses="pt10 pb10 pr8 pl6 sq-icon-hover"
        onToggle={setIsOpen}
        tooltipText="HOME_SCREEN.INFO_POPOVER.TOOLTIP"
        tooltipDelay={500}
        id={`itemInfo-${item.workbookId}`}
        testId="basicInfoDropdownButton">
        <div className="forceFont p10 min-width-200 max-height-250 overflowYAuto overflowXHidden"
          data-testid="basicInfoDropdown">
          <div className="text-bolder sq-text-primary pb5 aggressiveWordBreak">
            {sqUtilities.truncate(item?.name, 80, 35)}
          </div>
          <div>
            {item?.description ? item.description : <span className="text-italic">{t('WORKBOOK_NO_DESCRIPTION')}</span>}
          </div>
        </div>
      </ButtonWithDropdown>
    </div>
  );
};
