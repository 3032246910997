import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import { Icon } from '@/hybrid/core/Icon.atom';
import { TableBuilderHelperService } from '@/hybrid/tableBuilder/tableBuilderHelper.service';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';

interface SortIconIF {
  active: boolean;
  columnKey: string;
  direction: string;
  headerBackgroundColor: string | undefined;
  level?: number;
  showLevel?: boolean;
  setDirection: (key: string, direction: string) => void;
}

const tableBuilderSortIconBindings = bindingsDefinition({
  sqTableBuilderHelper: injected<TableBuilderHelperService>()
});

export const TableBuilderSortIcon: React.FunctionComponent<SortIconIF> = (props) => {
  const { sqTableBuilderHelper } = useInjectedBindings(tableBuilderSortIconBindings);

  const {
    active,
    direction,
    columnKey,
    headerBackgroundColor,
    level = undefined,
    showLevel = false,
    setDirection
  } = props;

  const reverseSort = direction === 'asc' ? 'desc' : 'asc';
  const sortIcon = `fa fa-sort-amount-${direction}`;
  const extraClassNames = active ? 'sq-icon-hover cursorPointer' : '';
  const onClick = active ? () => setDirection(columnKey, reverseSort) : () => {};

  const iconType = sqTableBuilderHelper.getMostReadableIconType(headerBackgroundColor);

  return <>
    {!_.isUndefined(direction) &&
    <div
      className={`flexColumnContainer ${extraClassNames}`}
      data-testid='sortIconWrapper'>
      <Icon
        extraClassNames='fa-fw mt3'
        testId='sortIcon'
        icon={sortIcon}
        onClick={onClick}
        type={iconType} />
      {level && showLevel &&
      // using a <p> to correctly align the <sup> when the table has scrollbar
      <p className="mb0"><sup className={classNames('sq-text-primary', `sq-icon-${iconType}`)}>{level}</sup></p>}
    </div>}
  </>;
};
