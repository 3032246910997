import template from './scatterPlot.component.html';
import { ScatterPlotController } from '@/scatterPlot/scatterPlot.controller';

/**
 * @file Directive that displays the scatter plot worksheet view
 */
export const sqScatterPlot = {
  controller: ScatterPlotController,
  template
};
