import React, { useState } from 'react';
import { FormControlFormComponent } from '@/hybrid/formbuilder/FormControlFormComponent.atom';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';

export interface FormControlJsonIF extends ValidatingFormComponent<string | number> {
  component: 'JsonTextAreaFormComponent';
  placeholder?: string;
  onBlur?: (value: string) => void;
  customError?: string;
  customErrorParams?: {};
  type?: string;
  step?: number;
  fixedWidth?: boolean;
  size: string;
  rows?: number;
  value: string;
  disabled?: boolean;
  className?: string;
}

export const JsonTextAreaFormComponent: React.FunctionComponent<FormControlJsonIF> = (props) => {
  const {
    name,
    extendValidation,
    validation,
    placeholder,
    onChange,
    onBlur,
    customError,
    customErrorParams = {},
    type = 'textarea',
    step,
    fixedWidth = true,
    size,
    rows,
    value,
    testId,
    disabled,
    className
  } = props;

  const [error, setError] = useState('');

  const jsonValidation = (value) => {
    try {
      JSON.parse(value.toString());
    } catch (e) {
      setError(e);
      return true;
    }
    return false;
  };

  const jsonCustomError = () => {
    if (customError !== null && customError !== undefined) {
      return customError;
    }
    return error.toString();
  };

  return (
    <FormControlFormComponent
      component="FormControlFormComponent"
      name={name}
      extendValidation={extendValidation}
      validation={(value) => {
        if (validation !== null && validation !== undefined) {
          return validation(value);
        }
        return jsonValidation(value);
      }}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      customError={jsonCustomError()}
      customErrorParams={customErrorParams}
      type={type}
      step={step}
      size={size}
      rows={rows}
      value={value}
      as="textarea"
      fixedWidth={fixedWidth}
      testId={testId}
      disabled={disabled}
      className={className}
    />);
};
