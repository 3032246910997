import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { TrackService } from '@/track/track.service';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { SearchActions } from '@/search/search.actions';
import { SEARCH_PANES, SORT_BY_OPTIONS } from '@/search/search.module';
import { UtilitiesService } from '@/services/utilities.service';
import { IconSelect } from '@/hybrid/core/IconSelect.molecule';
import { FormControl } from 'react-bootstrap';
import { CheckboxSelect } from '@/hybrid/core/CheckboxSelect.molecule';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { FormInput } from '../core/FormInput.atom';

const searchFiltersBindings = bindingsDefinition({
  pane: prop<string>(),
  searchTypes: prop<string[]>(),
  scopeIds: prop<string[]>(),
  sqSearchActions: injected<SearchActions>(),
  sqUtilities: injected<UtilitiesService>(),
  sqTrack: injected<TrackService>()
});

export const SearchFilters: SeeqComponent<typeof searchFiltersBindings> = (props) => {
  const { pane, searchTypes, scopeIds } = props;
  const { sqSearchActions, sqUtilities, sqTrack } = useInjectedBindings(searchFiltersBindings);
  const { t } = useTranslation();

  const nameFilterRef = useRef(null);
  const store = sqSearchActions.getStoreForPane(pane);

  const focusNameInput = () => nameFilterRef.current.focus();
  useEffect(focusNameInput, []);

  const {
    nameFilter,
    descriptionFilter,
    searching,
    documentFilter,
    currentAsset,
    datasourceFilter,
    typeFilter,
    sortBy,
    isAdvancedMode,
    datasources
  } = useFlux(store);

  const search = () => {
    sqTrack.doTrack('Search', 'Search started');
    return sqSearchActions.searchItems(pane, searchTypes, scopeIds)
      .then(() => {
        const searchSummary = {
          advancedMode: isAdvancedMode,
          nameSpecified: !!nameFilter,
          descriptionSpecified: !!descriptionFilter,
          documentSpecified: !!documentFilter,
          assetSpecified: !!currentAsset,
          datasourceFilterCount: _.size(datasourceFilter),
          sortBy
        };
        _.forEach(searchTypes,
          searchType => searchSummary['typeFilter-' + searchType] = _.includes(typeFilter, searchType));

        sqTrack.doTrack('Search', 'Search Complete', JSON.stringify(searchSummary));
      });
  };

  const typeFilterOptions = _.map(searchTypes, type => ({ text: sqUtilities.getTypeTranslation(type), value: type }));
  
  return (
    <div className="panel panel-default">
      <div className="panel-body" data-testid="searchPanel">
        <FormInput className="mb10 height-34" id="searchInput" name="nameFilter" data-testid="nameFilter"
          placeholder={t('SEARCH_DATA.NAME_CONTAINS')}
          value={nameFilter}
          ref={nameFilterRef}
          onChange={e => sqSearchActions.setNameFilter(pane, e.target.value)}
          onKeyUp={e => e.keyCode === 13 && search()} />

        {isAdvancedMode &&
        <span className="advancedFormWrapper">
            <FormInput className="mb10 height-34" name="descriptionFilter" data-testid="descriptionFilter"
              placeholder={t('SEARCH_DATA.DESCRIPTION_CONTAINS')}
              value={descriptionFilter}
              onKeyUp={e => e.keyCode === 13 && search()}
              onChange={e => sqSearchActions.setDescriptionFilter(pane, e.target.value)} />

          {pane === SEARCH_PANES.MAIN &&
          <FormInput className="mb10 height-34" name="documentFilter" data-testid="documentFilter"
            placeholder={t('SEARCH_DATA.DOCUMENT_CONTAINS')}
            value={documentFilter}
            onChange={e => sqSearchActions.setDocumentFilter(pane, e.target.value)}
            onKeyUp={e => e.keyCode === 13 && search()} />}

          <CheckboxSelect
            testId="typeFilter"
            value={typeFilter}
            selectOptions={typeFilterOptions}
            isMultipleSelect={true}
            insideModal={pane === SEARCH_PANES.MODAL}
            wrapperClasses="mb10"
            label="SEARCH_DATA.ALL_TYPES"
            placeholder="SEARCH_DATA.ALL_TYPES"
            onChange={type => sqSearchActions.toggleType(pane, type.value)} />

            <CheckboxSelect
              testId="datasourceFilter"
              value={datasourceFilter}
              selectOptions={_.chain(datasources)
                .map(datasource => ({ text: datasource.name, value: datasource }))
                .sortBy(datasource => datasource.text.toLowerCase())
                .value() as { text: string, value: any }[]}
              isMultipleSelect={true}
              insideModal={pane === SEARCH_PANES.MODAL}
              wrapperClasses="mb10"
              label="SEARCH_DATA.ALL_DATASOURCES"
              placeholder="SEARCH_DATA.ALL_DATASOURCES"
              onChange={datasource => sqSearchActions.toggleDatasource(pane, datasource.value)} />

            <IconSelect
              name="sortByFilter"
              value={sortBy}
              insideModal={pane === SEARCH_PANES.MODAL}
              wrapperClasses="mb10"
              selectOptions={_.map(SORT_BY_OPTIONS,
                (option, key) => ({ text: `SEARCH_DATA.SORT_BY_OPTIONS.${key}`, value: option }))}
              onChange={sort => sqSearchActions.setSortBy(pane, sort.value)} />
          </span>}

        <div className="flexColumnContainer flexSpaceBetween">
          <TextButton
            label="SEARCH_DATA.RESET"
            onClick={() => {
              sqSearchActions.clear(pane, searchTypes, false, scopeIds);
              nameFilterRef.current.focus();
            }}
            testId="resetButton" />
          <div>
            <a className="force-link-color mr10 cursorPointer" data-testid="moreFiltersButton"
              onClick={() => {
                sqSearchActions.setAdvancedMode(pane, !isAdvancedMode);
                sqTrack.doTrack('Search', 'Search advanced mode');
              }}>
              {t(`SEARCH_DATA.${isAdvancedMode ? 'FEWER_OPTIONS' : 'MORE_OPTIONS'}`)}
              <Icon icon={isAdvancedMode ? 'fa-angle-up' : 'fa-angle-down'} extraClassNames="ml2" />
            </a>
            <TextButton id="spec-search-btn"
              label="SEARCH"
              variant="theme"
              icon={searching ? 'fa-spinner fa-spin' : 'fa-search'}
              disabled={searching}
              iconStyle="white"
              type="submit"
              onClick={search} />
          </div>
        </div>
      </div>
    </div>
  );
};
