
/*
    ====================================================================================================================
    | **NOTICE**
    | THIS IS A GENERATED FILE USING THE SOURCE FILE:
    |  crab\utilities\java\seeq-utilities\src\main\java\com\seeq\utilities\SeeqNames.java
    ====================================================================================================================
*/
export const SeeqNames = {
    Injection: {
        EveryoneUserGroup: 'EveryoneUserGroup',
        SystemState: 'SystemState',
        SystemUser: 'SystemUser',
    },
    Connectors: {
        ConnectionsJsonKey: 'Connections',
        DatasourceManagedJsonKey: 'DatasourceManaged',
        Connections: {
            NameJsonKey: 'Name',
            DatasourceIdJsonKey: 'Id',
            EnabledJsonKey: 'Enabled',
            IndexingJsonKey: 'Indexing',
            FrequencyJsonKey: 'Frequency',
            NextIndexingAtJsonKey: 'Next',
            OnStartupAndConfigChangeJsonKey: 'OnStartupAndConfigChange',
            TransformsJsonKey: 'Transforms',
            MaxConcurrentRequestsJsonKey: 'MaxConcurrentRequests',
            MaxResultsPerRequestJsonKey: 'MaxResultsPerRequest',
            Status: {
                Connecting: 'CONNECTING',
                Connected: 'CONNECTED',
                Disconnected: 'DISCONNECTED',
                Disabled: 'DISABLED',
            },
            SyncMode: {
                None: 'NONE',
                Incremental: 'INCREMENTAL',
                Full: 'FULL',
            },
            SyncResult: {
                Success: 'SUCCESS',
                Failed: 'FAILED',
                Interrupted: 'INTERRUPTED',
            },
        },
    },
    TreeType: {
        SeeqWorkbench: 'Seeq Workbench',
    },
    OData: {
        ExportEntitySetSuffix: '_DataSet',
    },
    CleanUpJobsGroup: {
        AnnotationImages: 'AnnotationOrphanedImageCleanerJob',
        WorksheetMaintenance: 'WorksheetMaintenanceJob',
    },
    JobGroup: {
        Screenshot: 'ScreenshotCapture',
        DateRange: 'DateRangeJobs',
        Report: 'ReportJobs',
        Notebook: 'NotebookJobs',
        CleanUp: 'CleanUpJobs',
    },
    Logging: {
        MigrationsCompleteFile: 'migrations_complete_',
        RemoteAgents: {
            MDCFilenameKey: 'agentlogfilename',
        },
        Appserver: {
            MDCFilenameKey: 'logfilename',
            DefaultFile: 'appserver',
            StartupFile: 'appserver-startup',
            UpgradeFile: 'appserver-upgrade',
            AsyncUpgradeFile: 'appserver-async-upgrade',
            LicensingFile: 'appserver-licensing',
            Requests: 'appserver-requests',
            RequestMonitors: 'appserver-requestmonitors',
            Units: 'appserver-units',
            Users: 'appserver-users',
            IntervalAnalysis: {
                FetchCritic: 'appserver-intervalanalysis-fetchcritic',
            },
        },
    },
    RemoteDatasourceClasses: {
        LDAP: 'LDAP',
        OAuth2: 'OAuth 2.0',
        WindowsAuth: 'Windows Auth',
        ADX: 'ADX',
        BigQuery: 'BigQuery',
        ChaosMonkey: 'Chaos Monkey',
        CygNet: 'CygNet',
        ExampleData: 'Time Series CSV Files',
        ExternalCalcPython: 'External Calculation Python',
        ExternalCalcExcel: 'External Calculation Excel VBA',
        FileFolders: 'FileFolders',
        GEProficy: 'GE Proficy',
        InfluxDB: 'InfluxDB',
        IP21: 'AspenTech IP21',
        MetNet: 'MetNet',
        NOAAWeather: 'NOAA Weather Service',
        OPCHDA: 'OPC-HDA',
        OPCUA: 'OPC-UA',
        OpenTSDB: 'OpenTSDB',
        OPH: 'Ovation Process Historian',
        OSIsoftAF: 'OSIsoft AF',
        OSIsoftAFEventFrames: 'OSIsoft AF Event Frames',
        OSIsoftPI: 'OSIsoft PI',
        SQL: 'SQL',
        StressTest: 'Stress Test',
        TimeStream: 'TimeStream',
        TreeFile: 'Tree File',
        WITSML: 'WITSML',
        Wonderware: 'Wonderware',
        XHQ: 'XHQ',
    },
    LocalDatasources: {
        SeeqMetadataItemStorage: {
            DatasourceClass: 'Seeq Metadata',
            DatasourceId: 'Seeq Metadata',
            DatasourceName: 'Seeq Metadata',
        },
        SeeqCalculationItemStorage: {
            DatasourceClass: 'Seeq Calculations',
            DatasourceId: 'Seeq Calculations',
            DatasourceName: 'Seeq Calculations',
        },
        Monitors: {
            DatasourceClass: 'Monitors',
            DatasourceId: 'monitors',
            DatasourceName: 'Seeq Monitors',
        },
        Authentication: {
            DatasourceClass: 'Auth',
            DatasourceId: 'Seeq',
            DatasourceName: 'Seeq',
        },
        FileSignalStorage: {
            DatasourceClass: 'Seeq - Signal Datasource',
            DatasourceId: 'Signal Datasource',
            DatasourceName: 'Seeq Signal Datasource',
        },
        FileSignalCache: {
            DatasourceClass: 'Seeq - FileSignal',
            DatasourceId: 'default',
            DatasourceName: 'Seeq Cache',
        },
        PostgresDatums: {
            DatasourceClass: 'Postgres Datums',
            DatasourceId: 'default',
            DatasourceName: 'Seeq Conditions Database',
        },
        Datafile: {
            DatasourceClass: 'Imported CSV Files',
            DatasourceId: 'Imported CSV Files',
            DatasourceName: 'Imported CSV Files',
        },
    },
    SwaggerApiNames: {
        Agents: 'Agents',
        Ancillaries: 'Ancillaries',
        Audit: 'Audit',
        Auth: 'Auth',
        Assets: 'Assets',
        Folders: 'Folders',
        Networks: 'Networks',
        Items: 'Items',
        Reports: 'Reports',
        Jobs: 'Jobs',
        Logs: 'Logs',
        Trees: 'Trees',
        SampleSeries: 'SampleSeries',
        Signals: 'Signals',
        Capsules: 'Capsules',
        Conditions: 'Conditions',
        Metrics: 'Metrics',
        Formulas: 'Formulas',
        Functions: 'Functions',
        Requests: 'Requests',
        Tables: 'Tables',
        Workbooks: 'Workbooks',
        Worksheets: 'Worksheets',
        Export: 'Export',
        Annotations: 'Annotations',
        Datasources: 'Datasources',
        Datafiles: 'Datafiles',
        Users: 'Users',
        UserGroups: 'UserGroups',
        AccessKeys: 'AccessKeys',
        DataConsumption: 'DataConsumption',
        Scalars: 'Scalars',
        Subscriptions: 'Subscriptions',
        System: 'System',
        Import: 'Import',
        Monitors: 'Monitors',
        Projects: 'Projects',
        Content: 'Content',
        Plugins: 'Plugins',
    },
    Channels: {
        AgentsStatus: 'agents-status',
        DatasourcesStatus: 'datasources-status',
        Broadcast: 'broadcast',
        LiveScreenshot: 'live-screenshot',
        AsyncResponse: 'async-response',
        RequestsProgress: 'requests-progress',
        WorkbookChannel: 'workbook',
        WorkstepChannel: 'workstep',
        ReportUpdateChannel: 'report-update',
        DatasourcesStatusDetailsLevel: {
            Counts: 'Counts',
            Summary: 'Summary',
            Complete: 'Complete',
        },
        SubscriberParameters: {
            DetailsLevel: 'detailsLevel',
        },
    },
    API: {
        Ace: '/ace',
        Agents: '/agents',
        Audit: '/audit',
        Auth: '/auth',
        Ancillaries: '/ancillaries',
        Assets: '/assets',
        Folders: '/folders',
        Networks: '/networks',
        Items: '/items',
        Reports: '/reports',
        Jobs: '/jobs',
        Logs: '/logs',
        Batch: '/batch',
        Trees: '/trees',
        AssetTrees: '/trees/assets',
        SampleSeries: '/sample-series',
        Signals: '/signals',
        Samples: '/samples',
        PlotSamples: '/plot-samples',
        Capsules: '/capsules',
        Conditions: '/conditions',
        Metrics: '/metrics',
        Thresholds: '/thresholds',
        Formulas: '/formulas',
        Docs: '/docs',
        Functions: '/functions',
        Packages: '/packages',
        Scalars: '/scalars',
        Properties: '/properties',
        Requests: '/requests',
        Tables: '/tables',
        Workbooks: '/workbooks',
        Worksheets: '/worksheets',
        Worksteps: '/worksteps',
        Export: '/export',
        Annotations: '/annotations',
        Datasources: '/datasources',
        Datafiles: '/datafiles',
        Users: '/users',
        UserGroups: '/usergroups',
        AccessKeys: '/accesskeys',
        OData: '/odata.svc',
        System: '/system',
        Import: '/import',
        Status: '/status',
        Screenshots: '/screenshots',
        Subscriptions: '/subscriptions',
        Monitors: '/system/monitors',
        Configuration: '/system/configuration',
        Installer: '/system/installer',
        Projects: '/projects',
        Content: '/content',
        DateRanges: '/date-ranges',
        AssetSelections: '/asset-selections',
        Plugins: '/plugins',
        ErrorMessages: {
            AttemptedToSetScopeOnAGloballyScopedItem: 'Attempted to set scope on a globally scoped item',
        },
        AddOnToolLinkType: {
            Window: 'window',
            Tab: 'tab',
            None: 'none',
        },
        PropertyIds: {
            RequestInformation: 'com.seeq.requestinformation',
        },
        Cookies: {
            Auth: 'sq-auth',
            Csrf: 'sq-csrf',
        },
        QueryParams: {
            ClientInteractiveSessionId: 'interactiveId',
            requestId: 'requestId',
        },
        Headers: {
            Async: 'x-sq-async',
            Auth: 'x-sq-auth',
            Csrf: 'x-sq-csrf',
            ForwardedFor: 'x-forwarded-for',
            InteractiveSessionId: 'Session-Id',
            RequestId: 'x-sq-request-id',
            RequestOrigin: 'x-sq-origin',
            Server: 'Server',
            ServerMeters: 'Server-Meters',
            ServerTiming: 'Server-Timing',
            Expect: 'Expect',
            DateRangeStart: 'x-sq-daterange-start',
            DateRangeEnd: 'x-sq-daterange-end',
            Timings: {
                Datasource: 'Datasource',
                Cache: 'Cache',
                Processing: 'Processing',
                GC: 'GC',
                RequestQueue: 'Request Queue',
                CalcEngineQueue: 'Calc Engine Queue',
                SeeqDatabase: 'Seeq Database',
                Total: 'Total',
            },
            Meters: {
                DatasourceSamplesRead: 'Datasource Samples Read',
                DatasourceCapsulesRead: 'Datasource Capsules Read',
                CacheSamplesRead: 'Cache Samples Read',
                CacheCapsulesRead: 'Cache Capsules Read',
                CacheInMemorySamplesRead: 'Cache In-Memory Samples Read',
                CacheInMemoryCapsulesRead: 'Cache In-Memory Capsules Read',
                DatabaseItemsRead: 'Database Items Read',
                DatabaseRelationshipsRead: 'Database Relationships Read',
            },
        },
    },
    UnitTest: {
        Timestamp1: '1990-03-07T09:58:20.888888Z',
        Timestamp2: '1990-04-08T10:59:21.999999Z',
        NonExistentGuid: '1CB64296-FC3E-4221-9A7F-947A0AB99807',
    },
    Edges: {
        Owns: 'owns',
        Created: 'created',
        Installed: 'installed',
        Contains: 'contains',
        MemberOf: 'member of',
        InterestedIn: 'interested in',
        ReportsOn: 'reports on',
        RepliesTo: 'replies to',
        RecentlyAccessed: 'recently accessed',
        HasWorksheet: 'has worksheet',
        HasWorkstep: 'has workstep',
        CurrentWorkstep: 'current workstep',
        HasParameter: 'has parameter',
        UsesFunction: 'uses function',
        HasAncillary: 'has ancillary',
        HasAncillaries: 'has ancillaries',
        AssociatedTo: 'associated to',
        /* DEPRECATED, Deprecated. See SeeqNames.java for more info */
        AccessFor: 'access for',
        /* DEPRECATED, Deprecated. See SeeqNames.java for more info */
        HasAce: 'has access control entry',
        HasPinned: 'has pinned',
        Measuring: 'measuring',
        HasAggregationSignal: 'has aggregation signal',
        HasAggregationCondition: 'has aggregation condition',
        HasAggregationFormulaFunction: 'has aggregation formula function',
        BoundedBy: 'bounded by',
        HasThreshold: 'has threshold',
        HasConditionThreshold: 'has condition threshold',
        HomeFolder: 'home folder',
        PackageContainsUDF: 'package contains user defined function',
        PackageContainsDoc: 'package contains formula doc',
        DocumentedBy: 'documented by',
        AgentContainsConnector: 'agent contains connector',
        ConnectionCreatedByConnector: 'connection created by connector',
        Renders: 'renders',
        RunsNotebook: 'runs notebook',
        SourceWorksheet: 'source worksheet',
        SourceWorkstep: 'source workstep',
        HasAsset: 'has asset',
        HasAssetSelection: 'has asset selection',
        HasAssetSelections: 'has asset selections',
        HasCondition: 'has condition',
        HasContent: 'has content',
        HasDateRange: 'has date range',
        HasDateRanges: 'has date ranges',
        HasScheduledNotebook: 'has scheduled notebook',
    },
    Types: {
        Asset: 'Asset',
        AssetSelection: 'AssetSelection',
        AccessControlEntry: 'AccessControlEntry',
        StoredSignal: 'StoredSignal',
        Datasource: 'Datasource',
        /* DEPRECATED, Deprecated. See SeeqNames.java for more info */
        TableDatasource: 'TableDatasource',
        Datafile: 'Datafile',
        CalculatedSignal: 'CalculatedSignal',
        Signal: 'Signal',
        TreeDefinition: 'TreeDefinition',
        Ancillary: 'Ancillary',
        Identity: 'Identity',
        User: 'User',
        UserGroup: 'UserGroup',
        AccessKey: 'AccessKey',
        SystemState: 'SystemState',
        Export: 'Export',
        ProjectLink: 'ProjectLink',
        AddOnTool: 'AddOnTool',
        BaseAnnotation: 'BaseAnnotation',
        Report: 'Report',
        Journal: 'Journal',
        Reply: 'Reply',
        Condition: 'Condition',
        Capsule: 'Capsule',
        StoredCondition: 'StoredCondition',
        CalculatedCondition: 'CalculatedCondition',
        CalculatedScalar: 'CalculatedScalar',
        FormulaFunction: 'FormulaFunction',
        AggregatingFormulaFunction: 'AggregatingFormulaFunction',
        Chart: 'Chart',
        UserDefinedFormulaFunction: 'UserDefinedFormulaFunction',
        FormulaPackage: 'FormulaPackage',
        FormulaDoc: 'FormulaDoc',
        FormulaPackageDoc: 'FormulaPackageDoc',
        ThresholdMetric: 'ThresholdMetric',
        Folder: 'Folder',
        Project: 'Project',
        Content: 'Content',
        ImageContent: 'ImageContent',
        ReactJsonContent: 'ReactJsonContent',
        DateRange: 'DateRange',
        ScheduledNotebook: 'ScheduledNotebook',
        Plugin: 'Plugin',
        Connection: 'Connection',
        Connector: 'Connector',
        Agent: 'Agent',
        Workbook: 'Workbook',
        Analysis: 'Analysis',
        Topic: 'Topic',
        Worksheet: 'Worksheet',
        Workstep: 'Workstep',
        Item: 'Item',
        ItemWithOwner: 'ItemWithOwner',
        TypeDefinition: 'TypeDefinition',
    },
    TopicDocumentAttributes: {
        DataSeeqContent: 'data-seeq-content',
        DataSeeqContentPending: 'data-seeq-content-pending',
        DataSeeqDateRangeId: 'data-date-range-id',
        DataSeeqDateRangeFormat: 'data-date-range-format',
        DataSeeqDateRangeContent: 'data-date-range-content',
        DataSeeqContentPercentWidth: 'data-seeq-content-percent-width',
        DataSeeqContentNoMargin: 'data-seeq-content-no-margin',
        DataSeeqContentBorder: 'data-seeq-content-border',
        DataSeeqContentHeight: 'data-seeq-content-height',
        DataSeeqContentWidth: 'data-seeq-content-width',
    },
    ActivityProperties: {
        Interval: 'Interval',
        CategoryDatasourceRead: 'Datasource Read',
        RestartedRequest: 'Restarted Request',
    },
    CapsuleProperties: {
        Start: 'Start',
        End: 'End',
        Duration: 'Duration',
        Middle: 'Middle',
        Count: 'Count',
        Value: 'Value',
        Unit: 'Unit',
        Distance: 'Distance',
        Similarity: 'Similarity',
        StandardDeviation: 'Standard Deviation',
        SamplePeriod: 'Sample Period',
        ReferenceCapsule: 'Reference Capsule',
        ConditionId: 'Condition ID',
        CapsuleId: 'Capsule ID',
        CapsuleIdSafe: 'Capsule SortKey',
        OriginalUncertainty: 'Original Uncertainty',
    },
    Properties: {
        Guid: 'GUID',
        Name: 'Name',
        Id: 'ID',
        Description: 'Description',
        Unsearchable: 'Unsearchable',
        Archived: 'Archived',
        ArchivalId: 'Archival ID',
        ArchivedAt: 'Archived At',
        ArchivedReason: 'Archived Reason',
        SyncToken: 'Sync Token',
        NumberFormat: 'Number Format',
        SourceNumberFormat: 'Source Number Format',
        StorageLocation: 'Storage Location',
        CreatorId: 'Creator ID',
        CreatorFirstName: 'Creator First Name',
        CreatorLastName: 'Creator Last Name',
        DatasourceCdc: 'Datasource CDC',
        ManualCdc: 'Manual CDC',
        ScopedTo: 'Scoped To',
        ImportItemType: 'Import Item Type',
        Filename: 'Filename',
        NameRow: 'Name Row',
        NamePrefix: 'Name Prefix',
        NameSuffix: 'Name Suffix',
        ConditionName: 'Condition Name',
        FirstDataRow: 'First Data Row',
        DescriptionRow: 'Description Row',
        InterpolationMethodRow: 'Interpolation Method Row',
        MaximumInterpolationRow: 'Maximum Interpolation Row',
        ValueUomRow: 'Value Unit of Measure Row',
        FieldDelimiter: 'Field Delimiter',
        DayFirstDefault: 'Day First Default',
        KeyColumnName: 'Key Column Name',
        KeyColumnIndex: 'Key Column Index',
        EndColumnName: 'End Column Name',
        EndColumnIndex: 'End Column Index',
        KeyFormat: 'Key Format',
        TimeZone: 'Time Zone',
        ValueColumnNames: 'Value Column Names',
        ValueColumnIndices: 'Value Column Indices',
        Append: 'Append',
        ValidationMode: 'Validation Mode',
        LenientDaylightSavings: 'Lenient Daylight Savings',
        DatasourceClass: 'Datasource Class',
        DatasourceName: 'Datasource Name',
        DatasourceId: 'Datasource ID',
        DataId: 'Data ID',
        DataVersionCheck: 'Data Version Check',
        StoredInSeeq: 'Stored In Seeq',
        ConnectionRequestTimeout: 'Connection Request Timeout',
        MaxRequests: 'Max Requests',
        WindowsAuthMode: 'Windows Auth Mode',
        LdapAuthMode: 'LDAP Auth Mode',
        UsernamePasswordCompatible: 'Username/Password Compatible',
        UsernameHumanReadable: 'Username Human Readable',
        ExpectDuplicatesDuringIndexing: 'Expect Duplicates During Indexing',
        ReferencedDatasourceId: 'Referenced Datasource ID',
        ReferencedDatasourceClass: 'Referenced Datasource Class',
        AdditionalGroupsToSync: 'Additional Groups To Sync',
        SignalLocation: 'Signal Location',
        ConditionLocation: 'Condition Location',
        CurrentIndexAt: 'Current Index At',
        PreviousIndexAt: 'Previous Index At',
        SyncResult: 'Sync Result',
        IndexingScheduleSupported: 'Indexing Schedule Supported',
        AssetProgress: 'Asset Progress',
        AssetCount: 'Asset Count',
        PreviousAssetCount: 'Previous Asset Count',
        SignalProgress: 'Signal Progress',
        SignalCount: 'Signal Count',
        PreviousSignalCount: 'Previous Signal Count',
        ConditionProgress: 'Condition Progress',
        ConditionCount: 'Condition Count',
        PreviousConditionCount: 'Previous Condition Count',
        ScalarProgress: 'Scalar Progress',
        ScalarCount: 'Scalar Count',
        PreviousScalarCount: 'Previous Scalar Count',
        RelationshipProgress: 'Relationship Progress',
        RelationshipCount: 'Relationship Count',
        PreviousRelationshipCount: 'Previous Relationship Count',
        UserGroupProgress: 'UserGroup Progress',
        UserGroupCount: 'UserGroups Count',
        PreviousUserGroupCount: 'Previous UserGroups Count',
        InterpolationMethod: 'Interpolation Method',
        SourceMaximumInterpolation: 'Source Maximum Interpolation',
        OverrideMaximumInterpolation: 'Override Maximum Interpolation',
        MaximumInterpolation: 'Maximum Interpolation',
        KeyUom: 'Key Unit Of Measure',
        ValueUom: 'Value Unit Of Measure',
        SourceValueUom: 'Source Value Unit Of Measure',
        MaximumDuration: 'Maximum Duration',
        CacheEnabled: 'Cache Enabled',
        UncertaintyOverride: 'Uncertainty Override',
        MetadataProperties: 'Metadata Properties',
        LastKeyWritten: 'Last Key Written',
        RequestIntervalClamp: 'Request Interval Clamp',
        CacheId: 'Cache ID',
        CachedByService: 'Cached By Service',
        Uom: 'Unit Of Measure',
        Formula: 'Formula',
        SwapSourceId: 'Swap Source ID',
        SwapKey: 'Swap Key',
        CacheValue: 'CacheValue',
        FormulaParameters: 'FormulaParameters',
        ExpectedProvidedType: 'ExpectedProvidedType',
        VersionString: 'Version String',
        CreatorName: 'Creator Name',
        CreatorContactInfo: 'Creator Contact Info',
        Title: 'Title',
        Examples: 'Examples',
        SearchKeywords: 'Search Keywords',
        ExperimentalPackageName: 'Experimental',
        SeeqPackageName: 'Seeq',
        IndexDocName: 'index',
        AggregationFunction: 'AggregationFunction',
        Duration: 'Duration',
        Period: 'Period',
        MeasuredItemMaximumDuration: 'MeasuredItemMaximumDuration',
        BoundingConditionMaximumDuration: 'BoundingConditionMaximumDuration',
        NeutralColor: 'NeutralColor',
        IsGenerated: 'Is Generated',
        TreeType: 'Tree Type',
        ManuallyAdded: 'Manually Added',
        CreatedBy: 'Created By',
        ExportData: 'Export Data',
        IsCapsuleView: 'Is Capsule View',
        IsExportCapsuleTable: 'Is Export Capsule Table',
        IsChainView: 'Is Chain View',
        IsAutoupdateTimeRange: 'Is Autoupdate Time Range',
        GridSize: 'Grid Size',
        UserEmail: 'User Email',
        Username: 'Username',
        FirstName: 'First Name',
        LastName: 'Last Name',
        PasswordHash: 'Password Hash',
        PasswordHashMethod: 'Password Hash Method',
        PasswordSetDate: 'Password Set Date',
        LastLoginAt: 'Last Login At',
        SessionDuration: 'Session Duration',
        Enabled: 'Enabled',
        Admin: 'Admin',
        Workbench: 'Workbench',
        RemoteGroupEditable: 'Remote Group Editable',
        Data: 'Data',
        CreatedAt: 'Created At',
        UpdatedAt: 'Updated At',
        Order: 'Order',
        Document: 'Document',
        PlainTextDocument: 'Plain Text Document',
        Discoverable: 'Discoverable',
        PublishedAt: 'Published At',
        LastViewedAt: 'Last Viewed At',
        SourceSecurityString: 'Source Security String',
        SecurityString: 'Security String',
        PermissionsFromDatasource: 'Permissions From Datasource',
        PermissionInheritanceDisabled: 'Permission Inheritance Disabled',
        AccessControlParent: 'Access Control Parent',
        FirstAdminCreated: 'First Admin Created',
        UIConfig: 'UIConfig',
        DirtyCache: 'Dirty Cache',
        Unmodifiable: 'Unmodifiable',
        Height: 'Height',
        Width: 'Width',
        Scale: 'Scale',
        Timezone: 'Timezone',
        Selector: 'Selector',
        LastScreenshotDuration: 'Last Screenshot Duration',
        AverageScreenshotDuration: 'Average Screenshot Duration',
        SummaryType: 'Summary Type',
        SummaryValue: 'Summary Value',
        CurrentFilename: 'Current Filename',
        AssetPathDepth: 'Asset Path Depth',
        CronSchedule: 'Cron Schedule',
        Background: 'Background',
        ConditionFormulaNow: 'Condition Formula Now',
        NotebookPath: 'Notebook Path',
        PreviousRunTime: 'Previous Run Time',
        CronSchedulesToIndices: 'Cron Schedules To Indices',
        Label: 'Label',
        TotalRunTime: 'Total Run Time',
        AverageRunTime: 'Average Run Time',
        IsFixedWith: 'Is Fixed Width',
        IsCkEnabled: 'Is CK Enabled',
        FroalaBackup: 'Froala Backup',
        OriginalHasWorkstepGuid: 'Original HasWorkstep GUID',
        IconClass: 'Icon Class',
        TargetUrl: 'Target URL',
        LinkType: 'Link Type',
        WindowDetails: 'Window Details',
        ReuseWindow: 'Reuse Window',
        SortKey: 'Sort Key',
        Category: 'Category',
        Identifier: 'Identifier',
        Location: 'Location',
        EntryPoint: 'Entry Point',
        Icon: 'Icon',
        LastUpdateTime: 'Last Update Time',
        Host: 'Host',
        Options: 'Options',
        Version: 'Version',
        Json: 'Json',
        PropagationPending: 'Propagation Pending',
        DatasourceManaged: 'Datasource Managed',
        ConnectionId: 'ConnectionId',
        IndexingConnection: 'Indexing Conection',
        PullConnection: 'Pull Conection',
        MaxConcurrentRequests: 'Max Concurrent Requests',
        MaxResultsPerRequests: 'Max Results Per Requests',
        Transforms: 'Transforms',
        IndexingFrequency: 'Indexing Frequency',
        NextScheduledIndexAt: 'Next Scheduled Index At',
        DisabledAt: 'Disabled At',
        DisabledBy: 'Disabled By',
        IndexingOnStartupAndConfigChange: 'Indexing On Startup And Config Change',
    },
};


