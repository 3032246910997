import _ from 'lodash';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { RedactionService } from '@/services/redaction.service';
import { UtilitiesService } from '@/services/utilities.service';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import {
  COLOR_COLUMN_NAME,
  PRIORITY_COLUMN_NAME,
  STRIPED_CELL_COLOR,
  TableBuilderColumnType,
  TableBuilderHeaderType,
  TableBuilderMode
} from '@/hybrid/tableBuilder/tableBuilder.module';
import {
  COLUMNS_AND_STATS,
  ENUM_REGEX,
  ITEM_TYPES,
  PropertyColumn,
  StatisticColumn,
  TREND_CONDITION_STATS,
  TREND_PANELS, TREND_SIGNAL_STATS
} from '@/trendData/trendData.module';
import {
  BuildAdditionalCapsuleTableFormulaCallback,
  BuildConditionFormulaCallback, BuildStatFormulaCallback, FormulaService,
  PropertyColumn as FormulaPropertyColumn,
  StatColumn,
  TableSortParams
} from '@/services/formula.service';
import { TrendStore } from '@/trendData/trend.store';
import { DurationStore } from '@/trendData/duration.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { ProcessTypeEnum } from 'sdk/model/ThresholdMetricOutputV1';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { STRING_UOM } from '@/main/app.constants';
import { TableColumnOutputV1, ThresholdOutputV1 } from '@/sdk';
import { NULL_PLACEHOLDER, TableBuilderHelperService } from '@/hybrid/tableBuilder/tableBuilderHelper.service';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { NotificationsService } from '@/services/notifications.service';
import { defaultSettings } from '@/hybrid/tableBuilder/tableViewer/ChartSettings.molecule';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.module';
import { InvestigateHelperService } from '@/investigate/investigateHelper.service';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';

export type TableBuilderStore = ReturnType<typeof sqTableBuilderStore>['exports'];

export interface TableBuilderHeaders {
  type: TableBuilderHeaderType;
  format: string;
  property: string;
}

// itemGuid -> function -> metricId
interface MetricsMap {
  [itemGuid: string]: { [aggregationFunction: string]: string };
}

interface ItemReference {
  /**
   * The ID of the actual item on the backend. May not correspond to an item in the details pane when run across assets.
   */
  itemId: string;
  /**
   * The ID of the item in the details pane. Useful for knowing which item this corresponds to in the details pane
   * when run across assets.
   */
  formulaItemId: string;
}

export interface SimpleTableRow extends ItemReference {
  /**
   * The cells with the data from running the formula, ordered the same as the columns that the user has enabled.
   */
  cells: SimpleTableCell[];
}

export interface ConditionTableData {
  headers: ConditionTableHeader[];
  capsules: ConditionTableCapsule[];
}

export interface ConditionTableHeader {
  /**
   * The unique identifier for the header column, such as the item id or property name
   */
  key: string;
  /**
   * The name of the item or statistic
   */
  name: string;
  /**
   * The unit of measure for the values
   */
  units?: string;
  /**
   * Is this a column of string values?
   */
  isStringColumn?: boolean;
}

export interface ConditionTableCapsule {
  /**
   * The start of the capsule
   */
  startTime: number;
  /**
   * The end of the capsule
   */
  endTime: number;
  /**
   * Custom property for display in the header
   */
  property: any;
  /**
   * The asset that the capsule comes from. Only applicable when running across assets.
   */
  asset?: string;
  /**
   * The values for each statistic computed for the capsule
   */
  values: ConditionTableValue[];
}

export interface ConditionTableValue extends Partial<ItemReference> {
  /**
   * The statistic value
   */
  value: number | string | undefined;
  /**
   * The priority color if it is a metric
   */
  priorityColor?: string;
}

export interface SimpleTableCell {
  value: undefined | string;
  units?: string;
  priorityColor?: string;
  metricId?: string;
}

export interface ColumnPosition {
  itemId: string;
  key: string;
  metricId?: string;
  index: number;
}

interface MetricPropertyColumn extends FormulaPropertyColumn {
  /**
   * The name of the property on the metric capsule.
   */
  metricProperty?: string;
  /**
   * The parameter expression used to get information about the metric item
   */
  expression?: string;
  /** A filter on the metric property column (optional) */
  filter?: TableBuilderColumnFilter;
}

export type ItemColumnsMap = { [id: string]: { [key: string]: MetricPropertyColumn | StatisticColumn } };

export interface TableBuilderColumnFilter {
  operator: string;
  values: (number | string | undefined)[];
  usingSelectedValues?: boolean;
}

interface ColumnToThresholdsSimple {
  [aggregationFunction: string]: ThresholdOutputV1[];
}

interface ColumnToThresholdsCondition {
  [id: string]: ThresholdOutputV1[];
}

export interface TableBuilderSort {
  sort: { direction: string, level: number };
}

export const SIMPLE_TABLE_ID_COLUMN = 'itemId';

export const ITEM_UOM = 'itemUom';

export const SIMPLE_TABLE_BUILDER_EXTRA_CUSTOMIZATION_COLUMNS = ['metricValue'];
const SIMPLE_METRIC_FALLBACK_STATISTIC = { key: 'statistics.endValue' };

export const CONDITION_EXTRA_COLUMNS = ['startTime', 'endTime', 'asset'];

// Prefix added to columns in the formula so that they don't conflict with the names of actual properties added by
// the user
export const COLUMN_PREFIX = '__';

export function sqTableBuilderStore(
  $translate: ng.translate.ITranslateService,
  sqDateTime: DateTimeService,
  sqDurationStore: DurationStore,
  sqNotifications: NotificationsService,
  sqWorksheetStore: WorksheetStore,
  sqTrendSeriesStore: TrendSeriesStore,
  sqTrendCapsuleSetStore: TrendCapsuleSetStore,
  sqUtilities: UtilitiesService,
  sqRedaction: RedactionService,
  sqTrendStore: TrendStore,
  sqTrendDataHelper: TrendDataHelperService,
  sqTableBuilderHelper: TableBuilderHelperService,
  sqInvestigateHelper: InvestigateHelperService,
  sqFormula: FormulaService
) {
  const withDefaultFormatting = column => ({
    ...column,
    headerTextAlign: 'center',
    headerTextStyle: ['bold']
  });

  const SIMPLE_TABLE_DEFAULT_COLUMNS = [
    withDefaultFormatting(COLUMNS_AND_STATS.name),
    withDefaultFormatting(COLUMNS_AND_STATS['statistics.average'])
  ];

  const PREDEFINED_COLUMN_INDEX = { [COLUMNS_AND_STATS.name.key]: 0, [COLUMNS_AND_STATS.asset.key]: 1 };

  const CONDITION_TABLE_DEFAULT_COLUMNS = [withDefaultFormatting({ key: COLUMNS_AND_STATS.name.key })];

  const CONDITION_METRIC_COLUMNS: MetricPropertyColumn[] = [
    {
      key: 'value',
      propertyName: SeeqNames.CapsuleProperties.Value,
      metricProperty: SeeqNames.CapsuleProperties.Value,
      invalidsFirst: true
    },
    { key: 'priorityColor', propertyName: COLOR_COLUMN_NAME, metricProperty: COLOR_COLUMN_NAME },
    { key: 'priority', propertyName: PRIORITY_COLUMN_NAME, metricProperty: PRIORITY_COLUMN_NAME },
    { key: 'itemId', propertyName: 'itemId', expression: `property('${SeeqNames.Properties.Id}')` }
  ];

  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.WORKSHEET,

    /**
     * Initializes the store by setting default values to the stored state
     */
    initialize() {
      this.state = this.immutable({
        mode: TableBuilderMode.Simple,
        headers: {
          [TableBuilderMode.Condition]: {
            type: TableBuilderHeaderType.StartEnd,
            format: 'lll'
          },
          [TableBuilderMode.Simple]: {
            type: TableBuilderHeaderType.StartEnd,
            format: 'lll'
          }
        },
        columns: {
          [TableBuilderMode.Condition]: CONDITION_TABLE_DEFAULT_COLUMNS,
          [TableBuilderMode.Simple]: SIMPLE_TABLE_DEFAULT_COLUMNS
        },
        tableData: {
          [TableBuilderMode.Condition]: { headers: [], capsules: [] },
          [TableBuilderMode.Simple]: []
        },
        isTransposed: {
          [TableBuilderMode.Condition]: true,
          [TableBuilderMode.Simple]: false
        },
        assetId: {
          [TableBuilderMode.Condition]: undefined,
          [TableBuilderMode.Simple]: undefined
        },
        isHomogenizeUnits: {
          [TableBuilderMode.Condition]: false,
          [TableBuilderMode.Simple]: false
        },
        distinctStringValueMap: {
          [TableBuilderMode.Condition]: {},
          [TableBuilderMode.Simple]: {}
        },
        itemFilters: {},
        itemSorts: {},
        fetchFailedMessage: undefined,
        isMigrating: false,
        clipboardStyle: {},
        chartView: {
          enabled: false,
          settings: defaultSettings
        }
      });
    },

    exports: {
      /**
       * Returns the table builder mode (Condition or Simple)
       */
      get mode(): TableBuilderMode {
        return this.state.get('mode');
      },

      /**
       * The header settings for the table
       */
      get headers() {
        return this.state.get('headers', this.state.get('mode'));
      },

      /**
       * The columns for a table, with both their settings and custom text
       */
      get columns() {
        return this.getColumnsWithDefinition();
      },

      get conditionTableColumns() {
        const [propertyAndStatColumns, columns] = _.partition(this.getColumnsWithDefinition(),
          column => this.isPropertyOrStatColumn(column));
        return { propertyAndStatColumns, columns };
      },

      /**
       * Custom item/capsule properties used in the table.
       */
      get propertyColumns(): PropertyColumn[] {
        return this.getPropertyColumns();
      },

      /**
       * @returns the value of the headerOverridden flag for the specified column
       */
      get overriddenHeaderColumn() {
        return _.find(this.state.get('columns', this.state.get('mode')), 'headerOverridden');
      },

      /**
       * Data generated for the condition table display. This is accessed and set separately from simpleTableData
       * because the asynchronous nature of fetching table data means that there's a chance data could be written
       * in the wrong location if we get/set dependent only on the TableBuilderMode. See CRAB-22970.
       */
      get conditionTableData(): ConditionTableData {
        return this.state.get('tableData', TableBuilderMode.Condition);
      },

      /**
       * Data generated for the simple table display. This is accessed and set separately from conditionTableData
       * because the asynchronous nature of fetching table data means that there's a chance data could be written
       * in the wrong location if we get/set dependent only on the TableBuilderMode. See CRAB-22970.
       */
      get simpleTableData(): SimpleTableRow[] {
        return this.state.get('tableData', TableBuilderMode.Simple);
      },

      /**
       * @returns true if the unit of measure column is enabled in the table.
       */
      get isUomColumnEnabled(): boolean {
        return _.some(this.state.get('columns', this.state.get('mode')),
          { key: COLUMNS_AND_STATS.valueUnitOfMeasure.key });
      },

      get isTransposed() {
        return this.state.get('isTransposed', this.state.get('mode'));
      },

      get assetId() {
        return this.getAssetId();
      },

      get isHomogenizeUnits() {
        return this.isHomogenizeUnits();
      },

      get isMigrating() {
        return this.state.get('isMigrating');
      },

      get isTableStriped() {
        return this.state.get('isTableStriped', this.state.get('mode'));
      },

      get fetchFailedMessage() {
        return this.state.get('fetchFailedMessage');
      },

      /**
       * It can be used only in simple mode. Returns the maximum sort level for the table. Zero if no sort criteria
       * was set by the user.
       */
      get maxSortLevel(): number {
        return this.getMaxSortLevel();
      },

      /**
       * Used in condition mode to track filtering on items that are columns in the table
       */
      get itemFilters(): { [id: string]: { filter: TableBuilderColumnFilter } } {
        return this.state.get('itemFilters');
      },

      /**
       * Used in condition mode to track sorting on items that are columns in the table
       */
      get itemSorts(): { [id: string]: TableBuilderSort } {
        return this.state.get('itemSorts');
      },

      get distinctStringValueMap(): { [mode: string]: { [columnKey: string]: string[] } } {
        return this.state.get('distinctStringValueMap');
      },

      /**
       * See if the chartView is enabled or not
       */
      get showChartView(): boolean {
        return this.state.get('chartView', 'enabled');
      },

      /**
       * Get the chart view settings
       */
      get chartViewSettings() {
        return this.state.get('chartView', 'settings');
      },

      /**
       * @returns true if simple table mode is active and false is condition table mode is active
       */
      isSimpleMode(): boolean {
        return this.isSimpleMode();
      },

      /**
       * @returns the items to be displayed in the table filtered based on simple/condition mode.
       */
      getTableItems(): any[] {
        return this.getTableItems();
      },

      /**
       * Gets the striped color based on the current state of isTableStriped and the row index passed in
       */
      getStripedColor(rowIndex: number): string | undefined {
        return rowIndex % 2 === 0 && this.state.get('isTableStriped', this.state.get('mode')) ? STRIPED_CELL_COLOR :
          undefined;
      },

      /**
       * It can be used only in simple mode. Returns true when we are in simple mode and the table contains just one
       * signal. False otherwise.
       */
      isUserSortAllowed(): boolean {
        return this.isUserSortAllowed();
      },

      /**
       * Builds the necessary information to fetch the simple table.
       *
       * @returns An object containing the formula, parameters and the positions of each column which can be used in
       * conjunction with #setSimpleTableData()
       */
      getSimpleTableFetchParams() {
        this.updateSortAndFilters();
        const items = this.getTableItems();
        if (items.length === 0) {
          return { formula: '', parameters: {}, root: undefined, columnPositions: [] };
        }

        const isRunAcrossAssets = !!this.getAssetId();
        const isHomogenizeUnits = this.isHomogenizeUnits();
        let resultIndex = 2; // First two columns are start and end time
        let identifierIndex = 0;
        const parameters = {};
        const columns = _.reject(this.getColumnsWithDefinition(), { type: TableBuilderColumnType.Text }) as any[];
        const columnPositions: ColumnPosition[] = [];
        const columnFormulas: string[] = [];
        const filterFormulas: string[] = [];
        const sortCriteria: { columnName: string, direction: string, level: number }[] = [];

        // Gets the non-conflicting simple metrics for each item. Multiple metrics can be returned for the same item, as
        // long as their aggregation function is different
        const metricsMap: MetricsMap = this.findNonConflictingSimpleMetrics(_.map(items, 'id'));

        _.forEach(items, (item) => {
          const isStringSeries = sqUtilities.isStringSeries(item);
          const isSignal = item.itemType === ITEM_TYPES.SERIES;
          const isCondition = item.itemType === ITEM_TYPES.CAPSULE_SET;
          const statColumns = [];
          const itemIdentifier = sqUtilities.getShortIdentifier(identifierIndex++);
          parameters[itemIdentifier] = item.id;

          // All items need a column with their ID so that there is a unique identifier when run across assets
          const idIdentifier = `${itemIdentifier}_id`;
          parameters[idIdentifier] = `$${itemIdentifier}.property('${SeeqNames.Properties.Id}')`;
          columnPositions.push({ itemId: item.id, key: SIMPLE_TABLE_ID_COLUMN, index: resultIndex++ });
          columnFormulas.push(`.addColumn('${itemIdentifier}.${COLUMN_PREFIX}ID', $${idIdentifier})`);
          // The column name for a metric value is the itemId, but the name of a metric column that
          // is associated with a stat column is: <measuredItemShortId>.<metricId>
          const buildConvertUnitsFormulaFragment = (itemId: string, columnName?: string): string => {
            let convertUnits = '';
            if (isRunAcrossAssets) {
              let uom;
              if (isHomogenizeUnits) {
                const fixedMetricUOMIdentifier = sqUtilities.getShortIdentifier(identifierIndex++);
                parameters[fixedMetricUOMIdentifier] = `$${itemId}.property('${SeeqNames.Properties.ValueUom}')`;
                uom = `$${fixedMetricUOMIdentifier}`;
              } else {
                // remove units when we run across assets and homogenize units is not possible
                uom = '\'\'';
              }
              convertUnits = `.convertUnits('${columnName ?? itemId}', ${uom})`;
            }
            return convertUnits;
          };

          _.forEach(columns, (column) => {
            if (column.style === 'metric') {
              if (!_.isNil(item.definition)) {
                const aggregationFunction = item.definition.aggregationFunction ??
                  _.find(TREND_SIGNAL_STATS, { key: 'statistics.endValue' })?.stat;
                const metricIdentifier = sqUtilities.getShortIdentifier(identifierIndex++);
                const convertUnits = buildConvertUnitsFormulaFragment(item.id);
                columnFormulas.push(`.addSimpleMetricColumn('${item.id}', $${metricIdentifier})${convertUnits}`);
                parameters[metricIdentifier] = item.id;
                columnPositions.push({ itemId: item.id, key: column.key, index: resultIndex, metricId: item.id });
                if (column.filter) {
                  filterFormulas.push(sqFormula.buildSimpleTableFilterFormulaFragment(item.id, column.filter));
                }
                if (column.sort) {
                  const columnName = `${item.id}`;
                  sortCriteria.push({ columnName, direction: column.sort.direction, level: column.sort.level });
                }
                // Metrics add two columns, one for value, and one for color
                resultIndex += 2;
              }
            } else if (column.stat) {
              const isMetricColumn = !_.isNil(metricsMap[item.id]?.[column.stat]);
              const isStatColumn = (isSignal && (!isStringSeries || column.isStringCompatible)) ||
                (isCondition && _.some(TREND_CONDITION_STATS, ['stat', column.stat]));

              if (isMetricColumn) {
                const metricIdentifier = sqUtilities.getShortIdentifier(identifierIndex++);
                const metricId = metricsMap[item.id][column.stat];
                const metricColumnName = `${itemIdentifier}.${metricId}`;
                const convertUnits = buildConvertUnitsFormulaFragment(metricId, metricColumnName);
                columnFormulas.push(
                  `.addSimpleMetricColumn('${metricColumnName}', $${metricIdentifier})${convertUnits}`);
                parameters[metricIdentifier] = metricId;
                columnPositions.push({ itemId: item.id, key: column.key, index: resultIndex, metricId });
                if (column.filter) {
                  filterFormulas.push(
                    sqFormula.buildSimpleTableFilterFormulaFragment(metricColumnName, column.filter));
                }
                if (column.sort) {
                  sortCriteria.push(
                    { columnName: metricColumnName, direction: column.sort.direction, level: column.sort.level });
                }
                // Metrics add two columns, one for value, and one for color
                resultIndex += 2;
              } else if (isStatColumn) {
                statColumns.push(column);
              }
            } else {
              const propertyFormula = this.getSimpleTablePropertyColumnFormula(column, item, itemIdentifier);
              const propertyIdentifier = sqUtilities.getShortIdentifier(identifierIndex++);
              parameters[propertyIdentifier] = propertyFormula;
              columnFormulas.push(`.addColumn('${itemIdentifier}.${column.key}', $${propertyIdentifier})`);
              const columnName = `${itemIdentifier}.${column.key}`;
              if (column.filter) {
                filterFormulas.push(sqFormula.buildSimpleTableFilterFormulaFragment(columnName, column.filter));
              }
              if (column.sort) {
                sortCriteria.push({ columnName, direction: column.sort.direction, level: column.sort.level });
              }
              columnPositions.push({ itemId: item.id, key: column.key, index: resultIndex++ });
            }
          });

          // Add all the statistic columns as a group for best backend performance
          if (statColumns.length > 0) {
            _.forEach(statColumns, (column) => {
              columnPositions.push({ itemId: item.id, key: column.key, index: resultIndex++ });
              const columnName = `${itemIdentifier} ${column.columnSuffix}`;
              if (column.filter) {
                filterFormulas.push(
                  sqFormula.buildSimpleTableFilterFormulaFragment(columnName, column.filter));
              }
              if (column.sort) {
                sortCriteria.push({ columnName, direction: column.sort.direction, level: column.sort.level });
              }
            });
            const stats = _.chain(statColumns).map('stat').join(', ').value();
            let itemReference = `$${itemIdentifier}`;
            // convertUnits does not accept conditions as input. Not an issue because our current condition stats are
            // unitless.
            if (isSignal && isRunAcrossAssets) {
              if (!isStringSeries) {
                if (isHomogenizeUnits) {
                  const fixedItemUOMIdentifier = sqUtilities.getShortIdentifier(identifierIndex++);
                  parameters[fixedItemUOMIdentifier] = `$${item.id}.property('${SeeqNames.Properties.ValueUom}')`;
                  itemReference = `$${itemIdentifier}.convertUnits($${fixedItemUOMIdentifier})`;
                } else {
                  itemReference = `$${itemIdentifier}.convertUnits('')`;
                }
              }
            }
            columnFormulas.push(`.addStatColumn('${itemIdentifier}', ${itemReference}, ${stats})`);
          }
        });

        const viewCapsule = sqDateTime.getCapsuleFormula(sqDurationStore.displayRange);
        const sortParams = _.sortBy(sortCriteria, 'level').map(c => `'${c.columnName}','${c.direction}'`).join(',');
        const reduceFormula = !!this.getAssetId() && this.isUserSortAllowed() && sortCriteria.length > 0 ?
          `$result.sort(${sortParams})` : undefined;

        const formula = `group(${viewCapsule}).toTable('simple')${columnFormulas.join('')}${filterFormulas.join('')}`;
        return { formula, reduceFormula, parameters, root: this.getAssetId(), columnPositions };
      },

      /**
       * Builds the variables necessary for fetching the condition table.
       *
       * @returns An object containing the item necessary for fetch and used in conjunction with #setConditionTableData
       */
      getConditionTableFetchParams() {
        this.updateSortAndFilters();
        const items = this.getTableItems() as any[];
        const assetId = this.getAssetId();
        const isRunAcrossAssets = !!this.getAssetId();
        const isHomogenizeUnits = this.isHomogenizeUnits();
        const itemFilters = this.state.get('itemFilters');
        const allColumns = this.getColumnsWithDefinition();
        const metricFilterFormulas = [];
        const convertUnitsIds = [];
        const itemColumnsMap = _.chain(items)
          .filter({ itemType: ITEM_TYPES.METRIC })
          .transform((memo, { id }) => {
            memo[id] = _.chain(CONDITION_METRIC_COLUMNS)
              .transform((columnMap, column) => {
                columnMap[column.key] = {
                  ...column,
                  propertyName: `${id}_${column.key}`,
                  key: `${id}_${column.key}`
                };
              }, {} as { [key: string]: MetricPropertyColumn })
              .value();
            convertUnitsIds.push(id);
            if (itemFilters[id]?.filter) {
              metricFilterFormulas.push(
                sqFormula.buildConditionTableFilterFormulaFragment(`${id}_value`, itemFilters[id].filter));
            }
          }, {} as ItemColumnsMap)
          .value();

        const maybeAssetColumn = _.find(allColumns, { key: COLUMNS_AND_STATS.asset.key }) as any;

        const itemColumns: MetricPropertyColumn[] = _.chain(itemColumnsMap)
          .values()
          .flatMap(_.values)
          .value();

        const propertyColumns = (itemColumns as any[])
          .concat(_.find(allColumns, { key: COLUMNS_AND_STATS.startTime.key }) || COLUMNS_AND_STATS.startTime)
          .concat(_.find(allColumns, { key: COLUMNS_AND_STATS.endTime.key }) || COLUMNS_AND_STATS.endTime)
          .concat(this.getPropertyColumns());

        const statColumns: StatColumn[] = _.sortBy(this.getStatColumns(), 'signalId');

        const buildConditionFormula: BuildConditionFormulaCallback = this.getBuildConditionFormulaFunction(items,
          itemColumnsMap);

        const propertyFilterFormulas = _.chain(propertyColumns)
          .filter(column => !!column.filter)
          .map(column => sqFormula.buildConditionTableFilterFormulaFragment(column.key, column.filter))
          .value();

        const buildAdditionalFormula: BuildAdditionalCapsuleTableFormulaCallback = (ids, parameters) => {
          const idToShortName = _.invert(parameters);
          const filters = _.chain(statColumns)
            .filter(column => !!column.filter)
            .map(column => sqFormula.buildConditionTableFilterFormulaFragment(
              `${column.signalId} ${column.columnSuffix}`, column.filter))
            .concat(propertyFilterFormulas)
            .concat(metricFilterFormulas)
            .value();

          let assetColumn = '';
          if (maybeAssetColumn) {
            const itemIdentifier = _.chain(sqTrendDataHelper.getAllItems())
              .find(item => idToShortName[item.id] && !_.isEmpty(item.assets))
              .get('id', _.first(_.keys(idToShortName)))
              .thru(id => idToShortName[id])
              .value();
            parameters[maybeAssetColumn.key] = `$${itemIdentifier}.parentProperty('${SeeqNames.Properties.Name}')`;
            assetColumn = `.addColumn('${maybeAssetColumn.key}', $${maybeAssetColumn.key})`;
            if (maybeAssetColumn.filter) {
              filters.push(sqFormula.buildConditionTableFilterFormulaFragment(maybeAssetColumn.key,
                maybeAssetColumn.filter));
            }
          }

          let convertUnitsFormula = '';
          if (isRunAcrossAssets) {
            if (isHomogenizeUnits) {
              _.forEach(convertUnitsIds, (id) => {
                const itemReference = idToShortName[id];
                const itemUomReference = `fixed_${itemReference}_${ITEM_UOM}`;
                parameters[itemUomReference] = `$${id}.property('${SeeqNames.Properties.ValueUom}')`;
                convertUnitsFormula += `.convertUnits('${id}_value', $${itemUomReference})`;
              });
            } else {
              _.forEach(convertUnitsIds, (id) => {
                convertUnitsFormula += `.convertUnits('${id}_value', '')`;
              });
            }
          }
          return `.mergeRows()${assetColumn}${convertUnitsFormula}${filters.join('')}`;
        };

        const buildStatFormula: BuildStatFormulaCallback = this.getBuildStatFormulaFunction(statColumns);

        const headers = this.state.get('headers', TableBuilderMode.Condition);
        let customPropertyName;
        if (headers.type === TableBuilderHeaderType.CapsuleProperty) {
          customPropertyName = headers.property;
          // Account for properties that are already in the table but may have different names/keys
          // (e.g. the property Start uses the key startTime)
          const customPropertyColumn = _.find(propertyColumns,
            column => sqUtilities.equalsIgnoreCase(column.propertyName, customPropertyName));
          if (!customPropertyColumn) {
            propertyColumns.push({ key: customPropertyName, invalidsFirst: true, propertyName: customPropertyName });
          } else {
            customPropertyName = customPropertyColumn.key;
          }
        }

        const ids = _.map(items, 'id');
        const sortParams: TableSortParams = _.chain(statColumns as any[])
          .concat(propertyColumns)
          .concat(maybeAssetColumn ? maybeAssetColumn : [])
          .concat(_.map(this.state.get('itemSorts'), (itemSort, id) => ({
            ...itemSort,
            key: `${id}_value`
          })))
          .filter('sort')
          .sortBy('sort.level')
          .thru((sortColumns) => {
            if (_.isEmpty(sortColumns)) {
              return { sortBy: COLUMNS_AND_STATS.startTime.key, sortAsc: true, orderedAdditionalSortPairs: [] };
            } else {
              const isAsc = ({ direction }) => direction === 'asc';
              const primarySort = sortColumns.shift();
              return {
                sortBy: primarySort.key,
                sortAsc: isAsc(primarySort.sort),
                isCustomColumn: primarySort.key === COLUMNS_AND_STATS.asset.key,
                orderedAdditionalSortPairs: _.map(sortColumns, sortColumn => ({
                  sortBy: sortColumn.key, sortAsc: isAsc(sortColumn.sort)
                }))
              };
            }
          })
          .value();
        // If going across assets the sort must be done on all the results and so must use reduceFormula
        let reduceFormula;
        if (assetId && maybeAssetColumn) {
          if (_.isEmpty(maybeAssetColumn.sort)) {
            sortParams.orderedAdditionalSortPairs.push({ sortBy: maybeAssetColumn.key, sortAsc: true });
          }
          reduceFormula = `$result${sqFormula.buildCapsuleTableSortFragment(sortParams, propertyColumns,
            statColumns)}`;
        }

        return {
          ids,
          assetId,
          propertyColumns,
          statColumns,
          customPropertyName,
          reduceFormula,
          sortParams,
          buildAdditionalFormula,
          itemColumnsMap,
          buildConditionFormula,
          buildStatFormula
        };
      },

      /**
       * Gets the necessary information to fetch distinct string values that appear in different Simple Table columns.
       * Each set of fetch params returned corresponds to one column in the displayed Simple Table.  The fetch params
       * correspond to a table that has only the necessary information to get the column's string values.
       *
       * @returns obj.fetchParamsList: array of fetch param objects, each with a table formula
       *          obj.columnKeysNamesList: array of {columnKey, columnNames} objects where columnKey is the key of
       *            the displayed frontend table, and columnNames contains the names of the corresponding columns in
       *            the computed table returned from the backend, since those columns are combined to form the
       *            displayed Simple Table.
       */
      getSimpleTableDistinctStringColumnFetchParams(): { fetchParamsList: any[], columnKeysNamesList: any[] } {
        const items = this.getTableItems();
        const signals = _.filter(items, item => item.itemType === ITEM_TYPES.SERIES);
        const metrics = _.filter(items, item => item.itemType === ITEM_TYPES.METRIC);
        const root = this.getAssetId();
        const isRunAcrossAssets = !!root;
        const columns = _.reject(this.getColumnsWithDefinition(), { type: TableBuilderColumnType.Text }) as any[];
        const columnKeysNamesList = [];
        const fetchParamsList = [];
        const hasOnlyStringSeries = !_.isEmpty(signals) && _.every(signals,
          signal => sqUtilities.isStringSeries(signal));
        const hasOnlyStringMetrics = !_.isEmpty(metrics) && _.every(metrics,
          metric => sqUtilities.isStringSeries(metric));
        const viewCapsule = sqDateTime.getCapsuleFormula(sqDurationStore.displayRange);
        const baseFormula = `group(${viewCapsule}).toTable('simple')`;
        let identifierIndex = 0;

        _.forEach(columns, (column) => {
          if (hasOnlyStringSeries && column.stat && column.key === 'statistics.endValue') {
            let formula = baseFormula;
            const columnNames = [];
            const parameters = {};
            _.forEach(signals, (signal) => {
              const signalIdentifier = sqUtilities.getShortIdentifier(identifierIndex++);
              parameters[signalIdentifier] = signal.id;
              columnNames.push(`${signalIdentifier} ${column.columnSuffix}`);
              formula = `${formula}.addStatColumn('${signalIdentifier}', $${signalIdentifier}, ${column.stat})`;
            });
            const additionalFormula = `.distinctColumnValues(${_.map(columnNames, name => `'${name}'`).join(', ')})`;
            formula = `${formula}${isRunAcrossAssets ? '' : additionalFormula}`;
            const reduceFormula = isRunAcrossAssets ? `$result${additionalFormula}` : undefined;
            fetchParamsList.push({
              formula,
              parameters,
              root,
              reduceFormula
            });
            columnKeysNamesList.push({
              columnKey: column.key,
              columnNames
            });
          } else if (hasOnlyStringMetrics && column.key === COLUMNS_AND_STATS.metricValue.key) {
            // metric value column for string metrics
            let formula = baseFormula;
            const columnNames = [];
            const parameters = {};
            _.forEach(metrics, (metric) => {
              const metricIdentifier = sqUtilities.getShortIdentifier(identifierIndex++);
              parameters[metricIdentifier] = metric.id;
              columnNames.push(metric.id);
              formula = `${formula}.addSimpleMetricColumn('${metric.id}', $${metricIdentifier})`;
            });
            const additionalFormula = `.distinctColumnValues(${_.map(columnNames, name => `'${name}'`).join(', ')})`;
            formula = `${formula}${isRunAcrossAssets ? '' : additionalFormula}`;
            const reduceFormula = isRunAcrossAssets ? `$result${additionalFormula}` : undefined;
            fetchParamsList.push({
              formula,
              parameters,
              root,
              reduceFormula
            });
            columnKeysNamesList.push({
              columnKey: column.key,
              columnNames
            });
          } else if (_.includes(['string', 'assets', 'fullpath'], column.style) || column.isCustomProperty) {
            // property columns
            let formula = baseFormula;
            const columnNames = [];
            const parameters = {};
            _.forEach(items, (item) => {
              const itemIdentifier = sqUtilities.getShortIdentifier(identifierIndex++);
              parameters[itemIdentifier] = item.id;
              const propertyFormula = this.getSimpleTablePropertyColumnFormula(column, item, itemIdentifier);
              const propertyIdentifier = sqUtilities.getShortIdentifier(identifierIndex++);
              parameters[propertyIdentifier] = propertyFormula;
              columnNames.push(`${itemIdentifier}.${column.key}`);
              formula = `${formula}.addColumn('${itemIdentifier}.${column.key}', $${propertyIdentifier})`;
            });
            const additionalFormula = `.distinctColumnValues(${_.map(columnNames, name => `'${name}'`).join(', ')})`;
            formula = `${formula}${isRunAcrossAssets ? '' : additionalFormula}`;
            const reduceFormula = isRunAcrossAssets ? `$result${additionalFormula}` : undefined;
            fetchParamsList.push({
              formula,
              parameters,
              root,
              reduceFormula
            });
            columnKeysNamesList.push({
              columnKey: column.key,
              columnNames
            });
          }
        });

        return { fetchParamsList, columnKeysNamesList };
      },

      /**
       * Gets the necessary information to fetch distinct string values that appear in different Condition Table
       * columns. The fetch params correspond to a capsule table that has only the necessary information to get the
       * column's string values.
       * Each set of fetch params returned corresponds to one column in the displayed Condition Table.
       *
       * @returns obj.fetchParamList: array of fetch param objects, each with a table formula
       *          obj.columnKeysNamesList: array of {columnKey, columnNames} objects where columnKey is the key of
       *            the displayed frontend table, and columnNames contains the names of the corresponding columns in
       *            the computed table returned from the backend, since those columns are combined to form the
       *            displayed Simple Table.
       */
      getConditionTableDistinctStringColumnFetchParams(): Promise<{ fetchParamsList: any[], columnKeysNamesList: any[] }> {
        const sortParams = { sortBy: COLUMNS_AND_STATS.startTime.key, sortAsc: true, orderedAdditionalSortPairs: [] };
        const items = this.getTableItems();
        const assetId = this.getAssetId();
        const isRunAcrossAssets = !!this.getAssetId();
        const allColumns = this.getColumnsWithDefinition();
        const maybeAssetColumn = _.find(allColumns, { key: COLUMNS_AND_STATS.asset.key }) as any;
        const columnKeysNamesList = [];
        const assetColumnParams = [];

        // String-valued metrics only
        const metrics: any[] = _.filter(items, { itemType: ITEM_TYPES.METRIC });
        const stringMetrics: any[] = _.filter(metrics, metric => sqUtilities.isStringSeries(metric));
        const itemColumnsMap = _.transform(metrics, (memo, { id }) => {
          memo[id] = _.chain(CONDITION_METRIC_COLUMNS)
            .transform((columnMap, column) => {
              columnMap[column.key] = {
                ...column,
                propertyName: `${id}_${column.key}`,
                key: `${id}_${column.key}`
              };
            }, {} as { [key: string]: MetricPropertyColumn })
            .value();
        }, {} as ItemColumnsMap);

        if (maybeAssetColumn) {
          const buildAssetColumnFormula = (ids, parameters) => {
            const idToShortName = _.invert(parameters);
            const itemIdentifier = _.chain(sqTrendDataHelper.getAllItems())
              .find(item => idToShortName[item.id] && !_.isEmpty(item.assets))
              .get('id', _.first(_.keys(idToShortName)))
              .thru(id => idToShortName[id])
              .value();
            parameters[maybeAssetColumn.key] = `$${itemIdentifier}.parentProperty('${SeeqNames.Properties.Name}')`;
            return `.addColumn('${maybeAssetColumn.key}', $${maybeAssetColumn.key})`;
          };
          const distinctColumnValuesFormula = `.distinctColumnValues('${maybeAssetColumn.key}')`;
          columnKeysNamesList.push({ columnKey: maybeAssetColumn.key, columnName: maybeAssetColumn.key });
          assetColumnParams.push({
            ids: _.map(items, 'id'),
            assetId,
            propertyColumns: [],
            statColumns: [],
            sortParams,
            itemColumnsMap,
            buildAdditionalFormula: isRunAcrossAssets ? buildAssetColumnFormula
              : (ids, parameters) => `${buildAssetColumnFormula(ids, parameters)}${distinctColumnValuesFormula}`,
            reduceFormula: isRunAcrossAssets ? `$result${distinctColumnValuesFormula}` : undefined,
            buildConditionFormula: this.getBuildConditionFormulaFunction(items, itemColumnsMap)
          });
        }

        const metricTableParams = _.map(itemColumnsMap, (columns, itemId) => {
          const item = _.find(stringMetrics, { id: itemId });
          if (_.isUndefined(item)) return;
          const propertyColumns = (_.values(columns) as any[])
            .concat(_.find(allColumns, { key: COLUMNS_AND_STATS.startTime.key }) || COLUMNS_AND_STATS.startTime)
            .concat(_.find(allColumns, { key: COLUMNS_AND_STATS.endTime.key }) || COLUMNS_AND_STATS.endTime);
          const buildAdditionalFormula = () => `.distinctColumnValues('${columns.value.key}')`;
          columnKeysNamesList.push({ columnName: columns.value.key, columnKey: item.id });
          return {
            ids: [item.id],
            assetId,
            propertyColumns,
            statColumns: [],
            sortParams,
            itemColumnsMap,
            buildAdditionalFormula: isRunAcrossAssets ? undefined : buildAdditionalFormula,
            reduceFormula: isRunAcrossAssets ? `$result${buildAdditionalFormula()}` : undefined,
            buildConditionFormula: this.getBuildConditionFormulaFunction([item], itemColumnsMap)
          };
        });

        // string stats
        const statTableParams = [];
        const statColumns: StatColumn[] = _.sortBy(this.getStatColumns(), 'signalId');
        _.forEach(statColumns, (column: any) => {
          // Out of all the selectable stats, only endValue will produce a string result
          if (column.statisticKey === 'statistics.endValue') {
            const item = sqTrendSeriesStore.findItem(column.signalId);
            if (sqUtilities.isStringSeries(item)) {
              const buildAdditionalFormula = () => `.distinctColumnValues('${column.signalId} ${column.columnSuffix}')`;
              columnKeysNamesList.push({ columnKey: column.key, columnName: column.key });
              // create fetch params for stat column
              const paramsForColumn = {
                ids: _.map(items, 'id'),
                assetId,
                propertyColumns: [
                  _.find(allColumns, { key: COLUMNS_AND_STATS.startTime.key }) || COLUMNS_AND_STATS.startTime,
                  _.find(allColumns, { key: COLUMNS_AND_STATS.endTime.key }) || COLUMNS_AND_STATS.endTime
                ],
                statColumns: [column],
                reduceFormula: isRunAcrossAssets ? `$result${buildAdditionalFormula()}` : undefined,
                buildAdditionalFormula: isRunAcrossAssets ? undefined : buildAdditionalFormula,
                buildConditionFormula: this.getBuildConditionFormulaFunction(items, itemColumnsMap),
                sortParams,
                itemColumnsMap: undefined,
                buildStatFormula: this.getBuildStatFormulaFunction([column])
              };
              statTableParams.push(paramsForColumn);
            }
          }
        });

        // capsule properties
        return this.getStringCapsulePropertiesForConditions(_.filter(items, { itemType: ITEM_TYPES.CAPSULE_SET }))
          .then((capsuleProperties) => {
            const propertyTableParams = [];
            _.forEach(capsuleProperties, (capsuleProperty) => {
              const propertyColumn = _.find(this.getPropertyColumns(), { key: capsuleProperty.name });
              const buildAdditionalFormula = () => `.distinctColumnValues('${propertyColumn.key}')`;
              columnKeysNamesList.push({ columnKey: propertyColumn.key, columnName: propertyColumn.key });
              const paramsForProperty = {
                ids: [capsuleProperty.conditionId],
                assetId,
                propertyColumns: [propertyColumn],
                statColumns: [],
                buildAdditionalFormula: isRunAcrossAssets ? undefined : buildAdditionalFormula,
                reduceFormula: isRunAcrossAssets ? `$result${buildAdditionalFormula()}` : undefined,
                sortParams,
                itemColumnsMap: undefined
              };
              propertyTableParams.push(paramsForProperty);
            });

            return {
              fetchParamsList: _.reject(assetColumnParams
                  .concat(metricTableParams)
                  .concat(statTableParams)
                  .concat(propertyTableParams),
                _.isUndefined),
              columnKeysNamesList
            };
          });
      },

      /**
       * Create a map between table columns and thresholds that correspond to metrics that are relevant to the column.
       * For stat columns, the map key is the aggregation function, and the value is a list of thresholds belonging
       * to any metrics that use that aggregation function.
       * For the metricValue column, the map key is 'metricValue', and the value is a list of all of the thresholds
       * for any relevant (i.e. simple) metrics.
       * This is used in table filtering to give the user the option of picking an existing threshold to use as a
       * filter.
       *
       * @returns the map between table columns and arrays of thresholds
       */
      getColumnToThresholdsForSimple(): ColumnToThresholdsSimple {
        const columns = this.getColumnsWithDefinition();
        const columnStats = _.chain(columns)
          .filter((column: { stat?: string }) => !!column.stat)
          .map('stat')
          .value();
        const hasMetricValueColumn = _.some(columns, { key: 'metricValue' });
        const itemIds = _.map(this.getTableItems(), 'id');
        return _.chain(sqTrendDataHelper.getAllItems({
            workingSelection: false,
            itemTypes: [ITEM_TYPES.METRIC]
          }))
          .reject(metric => sqRedaction.isItemRedacted(metric))
          .filter(metric =>
            metric.definition?.processType === ProcessTypeEnum.Simple)
          // filter out the conflictual metrics (multiple metrics with the same aggregation function for the same item)
          .thru(metrics => _.reject(metrics, metric => _.some(metrics, otherMetric =>
            metric.id !== otherMetric.id &&
            metric.definition.measuredItem.id === otherMetric.definition.measuredItem.id &&
            metric.definition.aggregationFunction === otherMetric.definition.aggregationFunction)))
          .transform((columnToThresholds, metric) => {
            const aggregationFunction = metric.definition.aggregationFunction;
            if (_.includes(columnStats, aggregationFunction) && _.includes(itemIds,
              metric.definition.measuredItem.id)) {
              columnToThresholds[aggregationFunction] = columnToThresholds[aggregationFunction] ?? [];
              const valueThresholds = _.reject(metric.definition.thresholds, thresh => _.isUndefined(thresh.value));
              columnToThresholds[aggregationFunction].push(...valueThresholds);
            }
            if (hasMetricValueColumn && _.includes(itemIds, metric.id)) {
              columnToThresholds['metricValue'] = columnToThresholds['metricValue'] ?? [];
              const valueThresholds = _.reject(metric.definition.thresholds, thresh => _.isUndefined(thresh.value));
              columnToThresholds['metricValue'].push(...valueThresholds);
            }
          }, {} as ColumnToThresholdsSimple)
          .value();
      },

      /**
       * Create a map between table columns and thresholds that correspond to metrics in the table. Used for
       * filtering the condition table.
       *
       * @returns the map between metrics and arrays of thresholds
       */
      getColumnToThresholdsForCondition(): ColumnToThresholdsCondition {
        const metrics = _.filter(this.getTableItems(), { itemType: ITEM_TYPES.METRIC });
        const thresholdMap = {};
        _.forEach(metrics, (metric: any) => {
          thresholdMap[metric.id] = _.isEmpty(metric.definition?.thresholds) ? undefined : metric.definition.thresholds;
        });
        return thresholdMap;
      },

      /**
       * Checks if the table contains a particular column
       * @param column - The column to check. One of COLUMNS_AND_STATS
       * @param [signalId] - The series if it is a statistic column for condition table
       * @returns true if the table has the column, false otherwise
       */
      isColumnEnabled(column: PropertyColumn | StatisticColumn, signalId: string = null) {
        return this.isColumnEnabled(this.getColumnKey(column, signalId));
      },

      /**
       * Gets the unique key for a column
       * @param column - The column to use. One of COLUMNS_AND_STATS
       * @param [signalId] - The series if it is a statistic column for condition table
       * @returns The unique key
       */
      getColumnKey(column: PropertyColumn | StatisticColumn, signalId: string = null) {
        return this.getColumnKey(column, signalId);
      }
    },

    /**
     * @returns true if simple table mode is active and false is condition table mode is active
     */
    isSimpleMode(): boolean {
      return this.state.get('mode') === TableBuilderMode.Simple;
    },

    /**
     * @returns the items to be displayed in the table filtered based on simple/condition mode.
     */
    getTableItems(): any[] {
      const simpleMode = this.isSimpleMode();
      const itemTypes = simpleMode ? [ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR, ITEM_TYPES.CAPSULE_SET, ITEM_TYPES.METRIC] :
        [ITEM_TYPES.METRIC, ITEM_TYPES.CAPSULE_SET];
      const { sortBy, sortAsc } = sqTrendStore.getPanelSort(TREND_PANELS.SERIES);
      return _.chain(sqTrendDataHelper.getAllItems({ workingSelection: true, itemTypes }))
        .reject(item => sqRedaction.isItemRedacted(item))
        .filter((item) => {
          if (item.definition?.processType) {
            return simpleMode
              ? item.definition.processType === ProcessTypeEnum.Simple
              : item.definition.processType !== ProcessTypeEnum.Simple;
          } else {
            return true;
          }
        })
        .orderBy([sortBy], [sortAsc ? 'asc' : 'desc'])
        .value();
    },

    /**
     * @return true when in condition mode or when in simple mode and the table contains just one item.
     */
    isUserSortAllowed(): boolean {
      if (this.isSimpleMode()) {
        const items = this.getTableItems();
        return items.length === 1;
      } else {
        return true;
      }
    },

    /**
     * Returns the maximum sort level for the table. Zero if no sort criteria
     * was set by the user.
     */
    getMaxSortLevel(): boolean {
      return _.chain(this.getColumns())
        .concat(_.values(this.state.get('itemSorts')))
        .maxBy('sort.level')
        .value()?.sort?.level ?? 0;
    },

    /**
     * Finds non-conflicting simple metrics for each item. Multiple metrics can be returned for the same item, as
     * long as their aggregation function is different
     *
     * @param itemIds - the list of items to look for metrics
     * @returns a map with item ids and non-conflicting metrics
     */
    findNonConflictingSimpleMetrics(itemIds: string[]): MetricsMap {
      return _.chain(sqTrendDataHelper.getAllItems({ workingSelection: false, itemTypes: [ITEM_TYPES.METRIC] }))
        .reject(metric => sqRedaction.isItemRedacted(metric))
        .filter(metric =>
          metric.definition?.processType === ProcessTypeEnum.Simple)
        .filter(metric => _.includes(itemIds, metric.definition.measuredItem.id))
        // filter out the conflictual metrics (multiple metrics with the same aggregation function for the same item)
        .thru(metrics => _.reject(metrics, metric => _.some(metrics, otherMetric =>
          metric.id !== otherMetric.id &&
          metric.definition.measuredItem.id === otherMetric.definition.measuredItem.id &&
          metric.definition.aggregationFunction === otherMetric.definition.aggregationFunction)))
        .transform((metricsMap, metric) => {
          const measuredItemId = metric.definition.measuredItem.id;
          const measuredItemType = sqTrendDataHelper.findItemIn(
            [sqTrendSeriesStore, sqTrendCapsuleSetStore, sqTrendStore], measuredItemId)?.itemType;
          const aggregationFunction = metric.definition.aggregationFunction ??
            // Fallback to last value for signals, undefined for conditions
            (measuredItemType === ITEM_TYPES.CAPSULE_SET ? undefined
              : _.find(TREND_SIGNAL_STATS, SIMPLE_METRIC_FALLBACK_STATISTIC)?.stat);
          metricsMap[measuredItemId] = metricsMap[measuredItemId] ?? {};
          metricsMap[measuredItemId][aggregationFunction] = metric.id;
        }, {} as MetricsMap)
        .value();
    },

    /**
     * Checks if the table contains a particular column
     * @param key - Column key
     * @returns true if the table has the column, false otherwise
     */
    isColumnEnabled(key: string): boolean {
      return this.getColumnIndex(key, true) > -1;
    },

    /**
     * Gets the unique key for a column
     * @param column - The column being toggled. One of COLUMNS_AND_STATS
     * @param [signalId] - The series if it is a statistic column for condition table
     */
    getColumnKey(column: any, signalId: string = null) {
      return signalId ? `${column.statisticKey || column.key}_${signalId}` : column.key;
    },

    /**
     * Returns the columns for a table as persisted in the store (key, custom text, color). Other attributes like
     * accessor, transformResponse (present in TREND_COLUMNS) are excluded.
     */
    getColumns() {
      return this.state.get('columns', this.state.get('mode'));
    },

    /**
     * Returns the columns for a table, with their definition if it is a statistic or property
     */
    getColumnsWithDefinition(): any[] {
      return _.map(this.getColumns(), column => ({
        ...(COLUMNS_AND_STATS[column.statisticKey || column.key] || {}), ...column
      }));
    },

    /**
     * Get the callback to pass to the formula service, that creates condition formulas from table items.
     * Used for computing the Condition Table.
     *
     * @param items - list of items in the table
     * @param itemColumnsMap - map between metrics and the multiple columns they correspond to in the computed table
     * @returns callback that gets the condition formulas for conditions/metrics
     */
    getBuildConditionFormulaFunction(items: any[], itemColumnsMap): BuildConditionFormulaCallback {
      return (ids, parameters) => {
        const idToShortName = _.invert(parameters);
        const formula = _.chain(ids)
          .map((id) => {
            const identifier = idToShortName[id];
            if (_.find(items, { id }).itemType === ITEM_TYPES.METRIC) {
              const columns = _.values(itemColumnsMap[id]);
              const renamedProperties = _.chain(columns)
                .filter('metricProperty')
                .map((column: MetricPropertyColumn) =>
                  `renameProperty('${column.metricProperty}', '${column.propertyName}')`)
                .join('.')
                .value();
              const addedProperties = _.chain(columns)
                .filter('expression')
                .map((column: MetricPropertyColumn) => {
                  const propIdentifier = `${identifier}_${column.key.split('_')[1]}`;
                  parameters[propIdentifier] = `$${identifier}.${column.expression}`;
                  return `setProperty('${column.propertyName}', $${propIdentifier})`;
                })
                .join('.')
                .value();

              return `$${identifier}.toCondition().${renamedProperties}.${addedProperties}`;
            } else {
              return `$${identifier}`;
            }
          })
          .join(', ')
          .value();
        return { formula, parameters };
      };
    },

    /**
     * Get the callback to pass to the formula service, that creates formulas for signal statistic columns
     * Used for computing the Condition Table.
     *
     * @param statColumns - list of statistic columns in the table
     * @returns callback that gets the condition formulas for conditions/metrics
     */
    getBuildStatFormulaFunction(statColumns: any[]): BuildStatFormulaCallback {
      return (statsList: StatColumn[], parameters: { [key: string]: string }) => {

        const isRunAcrossAssets = !!this.getAssetId();
        const isHomogenizeUnits = this.isHomogenizeUnits();

        const mapIdsToShortIdentifiers = _.invert(parameters);

        const statColumnsFormula = _.chain(statColumns)
          .transform((memo, column: StatColumn) => {
            memo[column.signalId] = (memo[column.signalId] ?? []).concat(column.stat);
          }, {} as { [key: string]: string[] })
          .flatMap((statsList: string[], signalId) => {
            let itemReference = `$${mapIdsToShortIdentifiers[signalId]}`;
            const commaSeparatedStats = _.join(statsList, ', ');
            if (isRunAcrossAssets) {
              const item = sqTrendSeriesStore.findItem(signalId);
              if (!sqUtilities.isStringSeries(item)) {
                const itemIdentifier = mapIdsToShortIdentifiers[signalId];
                if (isHomogenizeUnits) {
                  const fixedItemUOMIdentifier = `fixed_${itemIdentifier}_${ITEM_UOM}`;
                  parameters[fixedItemUOMIdentifier] = `$${signalId}.property('${SeeqNames.Properties.ValueUom}')`;
                  itemReference = `$${itemIdentifier}.convertUnits($${fixedItemUOMIdentifier})`;
                } else {
                  itemReference = `$${itemIdentifier}.convertUnits('')`;
                }
              }
            }
            return `.addStatColumn('${signalId}', ${itemReference}, ${commaSeparatedStats})`;
          })
          .join('')
          .value();

        return `${statColumnsFormula}`;
      };
    },

    /**
     * Get only the string-valued capsule properties for a set of conditions.
     *
     * @param conditions - conditions to request capsule properties for
     * @returns promises that resolve to the string-valued capsule properties for each condition
     */
    getStringCapsulePropertiesForConditions(conditions: { id: string }[]):
      Promise<{ name?: string, conditionId?: string, unitOfMeasure?: string }[]> {
      const propertyColumns = this.getPropertyColumns();
      return Promise.all(_.chain(conditions)
          .map((condition) => {
            return sqInvestigateHelper.requestCapsuleProperties(condition.id, [])
              .then((capsuleProperties) => {
                return _.chain(capsuleProperties)
                  .filter(property => property.unitOfMeasure === STRING_UOM)
                  .filter(property => _.includes(_.map(propertyColumns, 'key'), property.name))
                  .map(property => ({ ...property, conditionId: condition.id }))
                  .value();
              });
          })
          .value())
        .then(props => _.flatten(props));
    },

    /**
     * Get the formula fragment for a property column for a particular item.
     * Used when computing the Simple Table.
     *
     * @param column - the column we're trying to add to the formula
     * @param item - the item we're adding the column for
     * @param itemIdentifier - the short id for the item to use in the formula
     */
    getSimpleTablePropertyColumnFormula(column: PropertyColumn, item, itemIdentifier: string): string {
      return _.cond([
        [
          _.matches({ key: COLUMNS_AND_STATS.asset.key }),
          () => `$${itemIdentifier}.parentProperty('${SeeqNames.Properties.Name}')`
        ],
        [
          _.matches({ key: 'fullpath' }),
          () => `$${itemIdentifier}.ancestors(' >> ')`],
        [
          _.property('propertyName'),
          ({ propertyName }) => `$${itemIdentifier}.property('${
            _.isFunction(propertyName) ? propertyName(item.itemType) : propertyName}')`
        ],
        [
          _.matches({ type: TableBuilderColumnType.Property }),
          ({ key }) => `$${itemIdentifier}.property('${key}')`
        ],
        [
          _.stubTrue,
          ({ key }) => {
            throw new TypeError(`${key} column is not supported in simple table formula`);
          }
        ]
      ])(column);
    },

    /**
     * Custom item/capsule properties used in the table.
     */
    getPropertyColumns(): PropertyColumn[] {
      return _.chain(this.getColumns())
        .filter({ type: TableBuilderColumnType.Property })
        .map((column: any) => ({
          key: column.key,
          propertyName: column.key,
          shortTitle: column.key,
          style: 'string',
          filter: column.filter,
          sort: column.sort
        }))
        .value();
    },
    /**
     * Condition-mode columns that generate a statistic for a signal over a capsule
     */
    getStatColumns(): (StatColumn & StatisticColumn)[] {
      return _.chain(this.getColumnsWithDefinition())
        .filter('signalId')
        .reject(column => _.isUndefined(sqTrendSeriesStore.findItem(column.signalId)))
        .reject(column => sqRedaction.isItemRedacted(sqTrendSeriesStore.findItem(column.signalId)))
        .value() as (StatColumn & StatisticColumn)[];
    },

    isPropertyOrStatColumn(column): boolean {
      return column.type === TableBuilderColumnType.Property || column.signalId ||
        _.includes(CONDITION_EXTRA_COLUMNS, column.key);
    },

    /**
     * Finds the index of a column
     * @param key - Column key
     * @param isNotFoundAllowed - If true, does not throw if the column does not exist
     * @returns the index of the column. If the column is not found, it returns -1
     */
    getColumnIndex(key: string, isNotFoundAllowed = false): number {
      const index = _.findIndex(this.getColumns(), { key });
      if (!isNotFoundAllowed && index === -1) {
        throw new TypeError(`Column key '${key}' does not exist`);
      }
      return index;
    },

    /**
     * Returns the column with the specified index.
     * @param key - Column key
     * @returns the column with the specified index.
     */
    getColumn(key: string): any {
      return _.find(this.state.get(['columns', this.state.get('mode')]), { key });
    },

    /**
     * Gets the striped color based on the current state of isTableStriped and the row index passed in
     */
    getStripedColor(rowIndex: number): string | undefined {
      return rowIndex % 2 === 0 && this.state.get('isTableStriped', this.state.get('mode')) ? STRIPED_CELL_COLOR :
        undefined;
    },

    /**
     * Dehydrates the item by retrieving the current set parameters in view
     * @returns {Object} An object with the state properties as JSON
     */
    dehydrate() {
      return _.omit(this.state.serialize(),
        ['tableData', 'clipboardStyle', 'distinctStringValueMap', 'fetchFailedMessage']);
    },

    /**
     * Rehydrates item from dehydrated state
     *
     * @param {Object} dehydratedState - State object that should be restored
     */
    rehydrate(dehydratedState) {
      this.state.deepMerge(dehydratedState);
    },

    handlers: {
      SIMPLE_THRESHOLD_METRIC_CREATED: 'handleSimpleMetricCreated',
      TABLE_BUILDER_SET_MODE: 'setMode',
      TABLE_BUILDER_ADD_COLUMN: 'addColumn',
      TABLE_BUILDER_REMOVE_COLUMN: 'removeColumn',
      TABLE_BUILDER_MOVE_COLUMN: 'moveColumn',
      TABLE_BUILDER_SET_COLUMN_BACKGROUND: 'setColumnBackground',
      TABLE_BUILDER_SET_COLUMN_TEXT_ALIGN: 'setColumnTextAlign',
      TABLE_BUILDER_SET_COLUMN_TEXT_COLOR: 'setColumnTextColor',
      TABLE_BUILDER_SET_COLUMN_TEXT_STYLE: 'setColumnTextStyle',
      TABLE_BUILDER_SET_HEADER_BACKGROUND: 'setHeaderBackground',
      TABLE_BUILDER_SET_HEADER_TEXT_ALIGN: 'setHeaderTextAlign',
      TABLE_BUILDER_SET_HEADER_TEXT_COLOR: 'setHeaderTextColor',
      TABLE_BUILDER_SET_HEADER_TEXT_STYLE: 'setHeaderTextStyle',
      TABLE_BUILDER_SET_STYLE_TO_ALL_COLUMNS: 'setStyleForAllColumns',
      TABLE_BUILDER_SET_STYLE_TO_ALL_HEADERS: 'setStyleForAllHeaders',
      TABLE_BUILDER_SET_STYLE_TO_ALL_HEADERS_AND_COLUMNS: 'setStyleForAllHeadersAndColumns',
      TABLE_BUILDER_COPY_STYLE: 'copyStyle',
      TABLE_BUILDER_PASTE_STYLE_ON_HEADER: 'pasteStyleOnHeader',
      TABLE_BUILDER_PASTE_STYLE_ON_COLUMN: 'pasteStyleOnColumn',
      TABLE_BUILDER_PASTE_STYLE_ON_HEADER_AND_COLUMN: 'pasteStyleOnHeaderAndColumn',
      TABLE_BUILDER_SET_COLUMN_FILTER: 'setColumnFilter',
      TABLE_BUILDER_SORT_BY_COLUMN: 'sortByColumn',
      TABLE_BUILDER_SET_CELL_TEXT: 'setCellText',
      TABLE_BUILDER_SET_HEADER_TEXT: 'setHeaderText',
      TABLE_BUILDER_SET_HEADERS_TYPE: 'setHeadersType',
      TABLE_BUILDER_SET_HEADERS_FORMAT: 'setHeadersFormat',
      TABLE_BUILDER_SET_HEADERS_PROPERTY: 'setHeadersProperty',
      TABLE_BUILDER_PUSH_CONDITION_DATA: 'setConditionTableData',
      TABLE_BUILDER_PUSH_SIMPLE_DATA: 'setSimpleTableData',
      TABLE_BUILDER_SET_HEADER_OVERRIDE: 'setHeaderOverridden',
      TABLE_BUILDER_SET_IS_TRANSPOSED: 'setIsTransposed',
      TABLE_BUILDER_SET_ASSET_ID: 'setAssetId',
      TABLE_BUILDER_SET_HOMOGENIZE_UNITS: 'setIsHomogenizeUnits',
      TABLE_BUILDER_SET_IS_MIGRATING: 'setIsMigrating',
      TABLE_BUILDER_SET_IS_TABLE_STRIPED: 'setIsTableStriped',
      TABLE_BUILDER_SET_FETCH_FAILED_MESSAGE: 'setFetchFailedMessage',
      TABLE_BUILDER_SET_CHART_VIEW: 'setChartView',
      TABLE_BUILDER_SET_CHART_VIEW_SETTINGS: 'setChartViewSettings',
      TABLE_BUILDER_SET_SIMPLE_DISTINCT_STRING_VALUES: 'setSimpleDistinctStringValues',
      TABLE_BUILDER_SET_CONDITION_DISTINCT_STRING_VALUES: 'setConditionDistinctStringValues',
      TREND_SET_PANEL_SORT: 'setSortedTableData',
      TREND_SET_CUSTOMIZATIONS: 'setSortedTableData',
      TREND_REMOVE_ITEMS: 'removeItems',
      TREND_SWAP_ITEMS: 'swapItems'
    },

    /**
     * Sets the mode of the table builder
     *
     * @param payload - Object container for arguments
     * @param payload.mode - The mode
     */
    setMode(payload: { mode: TableBuilderMode }) {
      this.state.set('mode', payload.mode);
    },

    /**
     * Adds a new table column.
     *
     * @param {Object} payload - Object container. Can either be a special type or one of the predefined columns.
     * @param {TableBuilderColumnType} [payload.type] - The column type
     * @param {string} [payload.propertyName] - The property name, required if type is TableBuilderColumnType.Property
     * @param {PropertyColumn} [payload.column] - One of the predefined columns
     */
    addColumn(payload) {
      const columnDefinition = withDefaultFormatting(_.cond([
        [
          _.matches({ type: TableBuilderColumnType.Text }),
          () => ({ key: sqUtilities.base64guid(), type: payload.type })
        ],
        [
          _.matches({ type: TableBuilderColumnType.Property }),
          () => ({ key: payload.propertyName, type: payload.type })
        ],
        [
          _.property('signalId'),
          () => ({
            // Can add the same statistic for many series, so a unique key must be created
            key: this.getColumnKey(payload.column, payload.signalId),
            statisticKey: payload.column.key,
            signalId: payload.signalId
          })
        ],
        [
          _.property('column'),
          // column key is enough for a predefined column. We don't want to store and persist unnecessary attributes
          () => ({ key: payload.column.key })
        ],
        [
          _.stubTrue,
          () => { throw new TypeError(`Unknown column type ${payload}`); }
        ]
      ])(payload));

      if (!this.isColumnEnabled(columnDefinition.key)) {
        const cursor = this.state.select('columns', this.state.get('mode'));
        // check if we should add the column to a predefined position
        if (PREDEFINED_COLUMN_INDEX[columnDefinition.key] >= 0) {
          cursor.splice([PREDEFINED_COLUMN_INDEX[columnDefinition.key], 0, columnDefinition]);
        } else {
          cursor.push(columnDefinition);
        }
      }
    },

    /**
     * Removes the specified table column.
     *
     * @param {Object} payload - Object container
     * @param {string} payload.key - The key that identifies the column
     */
    removeColumn({ key }) {
      const columnIndex = this.getColumnIndex(key);
      const column = this.getColumn(key);
      // remove sort first so that we can update the sort level of the remaining columns
      this.removeSort(key);
      this.state.splice(['columns', this.state.get('mode')], [columnIndex, 1]);
      if (this.isSimpleMode()) {
        _.forEach(this.state.get('tableData', TableBuilderMode.Simple), (row, rowIndex) => {
          this.state.splice(['tableData', TableBuilderMode.Simple, rowIndex, 'cells'], [columnIndex, 1]);
        });
      } else {
        if (this.isPropertyOrStatColumn(column)) {
          const dataIndex = _.findIndex(this.state.get(['tableData', TableBuilderMode.Condition, 'headers']),
            { key: column.key });
          this.state.splice(['tableData', TableBuilderMode.Condition, 'headers'], [dataIndex, 1]);
          _.forEach(this.state.get('tableData', TableBuilderMode.Condition, 'capsules'), (capsules, i) => {
            this.state.splice(['tableData', TableBuilderMode.Condition, 'capsules', i, 'values'], [dataIndex, 1]);
          });
        }
      }
    },

    /**
     * Moves the specified table column to a new position.
     *
     * @param {Object} payload - Object container
     * @param {string} payload.key - The key that identifies the column
     * @param {string} payload.newKey - The key that specifies the column of the new position
     */
    moveColumn(payload) {
      const cursor = this.state.select('columns', this.state.get('mode'));
      const index = this.getColumnIndex(payload.key);
      const newIndex = this.getColumnIndex(payload.newKey);
      const column = cursor.get(index);
      cursor.splice([index, 1]);
      cursor.splice([newIndex, 0, column]);
      if (this.isSimpleMode()) {
        _.forEach(this.state.get('tableData', TableBuilderMode.Simple), (row, rowIndex) => {
          const cellCursor = this.state.select('tableData', TableBuilderMode.Simple, rowIndex, 'cells');
          const cellValue = cellCursor.get(index);
          cellCursor.splice([index, 1]);
          cellCursor.splice([newIndex, 0, cellValue]);
        });
      } else if (this.isPropertyOrStatColumn(column)) {
        const headerCursor = this.state.select('tableData', TableBuilderMode.Condition, 'headers');
        const headerIndex = _.findIndex(headerCursor.get(), { key: payload.key });
        const newHeaderIndex = _.findIndex(headerCursor.get(), { key: payload.newKey });
        const headerValue = headerCursor.get(headerIndex);
        headerCursor.splice([headerIndex, 1]);
        headerCursor.splice([newHeaderIndex, 0, headerValue]);
        _.forEach(this.state.get('tableData', TableBuilderMode.Condition, 'capsules'), (row, rowIndex) => {
          const valueCursor = this.state.select('tableData', TableBuilderMode.Condition, 'capsules', rowIndex,
            'values');
          const value = valueCursor.get(headerIndex);
          valueCursor.splice([headerIndex, 1]);
          valueCursor.splice([newHeaderIndex, 0, value]);
        });
      }
    },

    /**
     * Sets the background color for a table column (header excluded).
     *
     * @param {Object} payload - Object container
     * @param {string} payload.key - The key of the column
     * @param {String} payload.color - Background color for the column
     */
    setColumnBackground(payload) {
      this.state.set(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'backgroundColor'],
        payload.color);
    },

    setColumnTextAlign(payload) {
      this.state.set(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'textAlign'], payload.align);
    },

    setColumnTextColor(payload) {
      this.state.set(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'textColor'], payload.color);
    },

    setColumnTextStyle(payload) {
      this.state.set(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'textStyle'], payload.style);
    },

    /**
     * Sets the background color for a table header.
     *
     * @param {Object} payload - Object container
     * @param {string} payload.key - The key of the column
     * @param {String} payload.color - Background color for the header
     */
    setHeaderBackground(payload) {
      this.state.set(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'headerBackgroundColor'],
        payload.color);
    },

    setHeaderTextAlign(payload) {
      this.state.set(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'headerTextAlign'],
        payload.align);
    },

    setHeaderTextColor(payload) {
      this.state.set(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'headerTextColor'],
        payload.color);
    },

    setHeaderTextStyle(payload) {
      this.state.set(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'headerTextStyle'],
        payload.style);
    },

    setStyleForAllColumns(payload) {
      const sourceColumn = this.getColumn(payload.key);
      _.forEach(this.getColumns(), (column, index) => {
        this.state.merge(['columns', this.state.get('mode'), index], {
          backgroundColor: sourceColumn.backgroundColor,
          textAlign: sourceColumn.textAlign,
          textColor: sourceColumn.textColor,
          textStyle: sourceColumn.textStyle
        });
      });
    },

    setStyleForAllHeaders(payload) {
      const sourceColumn = this.getColumn(payload.key);
      _.forEach(this.getColumns(), (column, index) => {
        this.state.merge(['columns', this.state.get('mode'), index], {
          headerBackgroundColor: sourceColumn.headerBackgroundColor,
          headerTextAlign: sourceColumn.headerTextAlign,
          headerTextColor: sourceColumn.headerTextColor,
          headerTextStyle: sourceColumn.headerTextStyle
        });
      });
    },

    setStyleForAllHeadersAndColumns(payload) {
      this.setStyleForAllColumns(payload);
      this.setStyleForAllHeaders(payload);
    },

    copyStyle(payload) {
      const sourceColumn = this.getColumn(payload.key);
      this.state.set('clipboardStyle', {
        backgroundColor: sourceColumn.backgroundColor,
        textAlign: sourceColumn.textAlign,
        textColor: sourceColumn.textColor,
        textStyle: sourceColumn.textStyle,
        headerBackgroundColor: sourceColumn.headerBackgroundColor,
        headerTextAlign: sourceColumn.headerTextAlign,
        headerTextColor: sourceColumn.headerTextColor,
        headerTextStyle: sourceColumn.headerTextStyle

      });
    },

    pasteStyleOnHeader(payload) {
      const clipboardStyle = this.state.get('clipboardStyle');
      this.state.merge(['columns', this.state.get('mode'), this.getColumnIndex(payload.key)], {
        headerBackgroundColor: clipboardStyle.headerBackgroundColor,
        headerTextAlign: clipboardStyle.headerTextAlign,
        headerTextColor: clipboardStyle.headerTextColor,
        headerTextStyle: clipboardStyle.headerTextStyle
      });
    },

    pasteStyleOnColumn(payload) {
      const clipboardStyle = this.state.get('clipboardStyle');
      this.state.merge(['columns', this.state.get('mode'), this.getColumnIndex(payload.key)], {
        backgroundColor: clipboardStyle.backgroundColor,
        textAlign: clipboardStyle.textAlign,
        textColor: clipboardStyle.textColor,
        textStyle: clipboardStyle.textStyle
      });
    },

    pasteStyleOnHeaderAndColumn(payload) {
      this.pasteStyleOnColumn(payload);
      this.pasteStyleOnHeader(payload);
    },

    /**
     * Set (or unset) a filter on a column. If payload.filter is undefined, any existing filter is removed.
     *
     * @param payload - object container for args
     * @param payload.key - column key (simple or condition property/stat column) or item id (condition metric)
     * @param payload.filter - column filter (can be undefined)
     */
    setColumnFilter(payload: { key: string, filter: TableBuilderColumnFilter }) {
      const maybeColumn = this.getColumn(payload.key);
      if (this.isSimpleMode() || (maybeColumn && this.isPropertyOrStatColumn(maybeColumn))) {
        if (payload.filter) {
          this.state.set(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'filter'],
            payload.filter);
        } else {
          this.state.unset(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'filter']);
        }
      } else {
        if (_.some(this.getTableItems(), { id: payload.key }) && payload.filter) {
          this.state.set(['itemFilters', payload.key, 'filter'], payload.filter);
        } else {
          this.state.unset(['itemFilters', payload.key]);
        }
      }
    },

    /**
     * Set (or unset) a sort criterion on a column. If payload.direction is 'none', the sort criterion is removed.
     * @param payload - object container for args
     * @param payload.key - column key
     * @param payload.direction - column sort order
     */
    sortByColumn(payload: { key: string, direction: string }) {
      if (_.isUndefined(payload.direction)) {
        this.removeSort(payload.key);
      } else {
        this.addOrUpdateSort(payload.key, payload.direction);
      }
    },

    /**
     * Set or update the sort criterion on the specified column. When sorting on multiple levels, the last column
     * added is the most important.
     * @param key - column key or itemId
     * @param direction - column sort order
     */
    addOrUpdateSort(key: string, direction: string) {
      const maybeColumn = this.getColumn(key);
      const isItem = _.some(this.getTableItems(), { id: key });
      if (!_.isUndefined(maybeColumn)) {
        const columnIndex = this.getColumnIndex(key);
        if (_.has(this.getColumn(key), 'sort')) {
          this.state.set(['columns', this.state.get('mode'), columnIndex, 'sort', 'direction'], direction);
        } else {
          this.incrementSortLevels();
          this.state.set(['columns', this.state.get('mode'), columnIndex, 'sort'], { direction, level: 1 });
        }
      } else if (isItem) {
        if (!_.isUndefined(this.state.get(['itemSorts', key, 'sort']))) {
          this.state.set(['itemSorts', key, 'sort', 'direction'], direction);
        } else {
          this.incrementSortLevels();
          this.state.set(['itemSorts', key], { sort: { direction, level: 1 } });
        }
      }
    },

    /**
     * Increments the sort level on all sorts
     */
    incrementSortLevels() {
      _.chain(this.getColumns())
        .filter(column => !!column.sort?.level)
        .forEach((column) => {
          const index = this.getColumnIndex(column.key);
          this.state.set(['columns', this.state.get('mode'), index, 'sort', 'level'], column.sort.level + 1);
        })
        .value();
      _.forEach(this.state.get('itemSorts'), (itemSort, key) => {
        this.state.set(['itemSorts', key, 'sort', 'level'], itemSort.sort.level + 1);
      });
    },

    /**
     * Removes the sort criterion from the specified column.
     * @param key - column key or itemId
     */
    removeSort(key: string) {
      // we may not have a sort level. This function is also called from removeColumn
      const sortLevel: number = this.getColumn(key)?.sort?.level
        ?? this.state.get(['itemSorts', key, 'sort', 'level'])
        ?? Infinity;
      const maybeColumn = this.getColumn(key);
      const isItem = _.some(this.getTableItems(), { id: key });
      if (!_.isUndefined(maybeColumn)) {
        const columnIndex = this.getColumnIndex(key);
        this.state.unset(['columns', this.state.get('mode'), columnIndex, 'sort']);
      } else if (isItem) {
        this.state.unset(['itemSorts', key]);
      }
      _.forEach(this.getColumns(), (column, index) => {
        if (column.sort?.level > sortLevel) {
          this.state.set(['columns', this.state.get('mode'), index, 'sort', 'level'], column.sort.level - 1);
        }
      });
      _.forEach(this.state.get('itemSorts'), (itemSort, key) => {
        if (itemSort.sort.level > sortLevel) {
          this.state.set(['itemSorts', key, 'sort', 'level'], itemSort.sort.level - 1);
        }
      });
    },

    /**
     * Removes sort criteria and/or filters if not supported after adding another item in the table.
     */
    updateSortAndFilters() {
      const someFiltersCleared = this.clearFiltersIfNotSupported();
      const someSortCleared = this.clearSortCriteriaIfNotSupported();
      if (someFiltersCleared && someSortCleared) {
        sqNotifications.infoTranslate('TABLE_BUILDER.ALL_FILTERS_AND_SORT_REMOVED');
      } else if (someFiltersCleared) {
        sqNotifications.infoTranslate('TABLE_BUILDER.ALL_FILTERS_REMOVED');
      } else if (someSortCleared) {
        sqNotifications.infoTranslate(this.isSimpleMode() ?
          'TABLE_BUILDER.ALL_SORT_CRITERIA_REMOVED_SIMPLE' : 'TABLE_BUILDER.ALL_SORT_CRITERIA_REMOVED_CONDITION');
      }
    },

    /**
     * Remove sort criteria from all columns if not supported on current table content.
     * @return true if any sort criterion was removed. False otherwise.
     */
    clearSortCriteriaIfNotSupported(): boolean {
      if (!this.isUserSortAllowed() && this.getMaxSortLevel() > 0) {
        _.forEach(this.getColumns(), (column, index) => {
          this.state.unset(['columns', this.state.get('mode'), index, 'sort']);
        });
        _.forEach(this.state.get('itemSorts'), (itemSort, key) => {
          this.state.unset(['itemSorts', key]);
        });
        return true;
      }
      return false;
    },

    /**
     * Remove filters from all columns if we have numeric and string series or metrics
     * @return true if any filter was removed. False otherwise.
     */
    clearFiltersIfNotSupported(): boolean {
      if (!this.isSimpleMode()) {
        return false;
      }
      const items = this.getTableItems();
      const hasNumericAndStringItems = sqTableBuilderHelper.hasNumericAndStringItems(items, ITEM_TYPES.SERIES) ||
        sqTableBuilderHelper.hasNumericAndStringItems(items, ITEM_TYPES.METRIC);
      if (_.some(this.getColumns(), 'filter') && hasNumericAndStringItems) {
        _.forEach(this.getColumns(), (column, index) => {
          this.state.unset(['columns', this.state.get('mode'), index, 'filter']);
        });
        return true;
      }
      return false;
    },

    /**
     * Sets the text for a table column cell.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.key - Column key
     * @param {String} payload.text - Text for the cell
     * @param {String} [payload.cellKey] - The identifier for the cell. If not specified the column header text
     * will be set.
     */
    setCellText(payload) {
      const index = this.getColumnIndex(payload.key);
      if (payload.cellKey && this.state.get(['columns', this.state.get('mode'), index, 'type']) !==
        TableBuilderColumnType.Text) {
        throw new TypeError('Can only set text on a column of type text');
      }

      if (payload.cellKey) {
        this.state.set(['columns', this.state.get('mode'), index, 'cells', payload.cellKey],
          _.trim(payload.text));
      } else {
        this.state.set(['columns', this.state.get('mode'), index, 'header'], _.trim(payload.text));
      }
    },

    /**
     * Sets the header override flag for a column and disables the overridden flag for other columns.
     *
     * @param payload - Object container
     * @param payload.columnKey - The column key.
     */
    setHeaderOverridden({ columnKey }: { columnKey: string }) {
      _.forEach(this.state.get('columns', this.state.get('mode')), (column, columnIndex) => {
        if (columnKey === column.key) {
          this.state.set(['columns', this.state.get('mode'), columnIndex, 'headerOverridden'], true);
        } else {
          this.state.unset(['columns', this.state.get('mode'), columnIndex, 'headerOverridden']);
        }
      });
    },

    /**
     * Sets the text for a table column header.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.columnKey - Column key
     * @param {String} payload.text - Text for the header
     */
    setHeaderText(payload) {
      const columnIndex = this.getColumnIndex(payload.columnKey);
      this.state.set(['columns', this.state.get('mode'), columnIndex, 'header'], _.trim(payload.text));
    },

    /**
     * Sets the header type for table columns that display static headers (all columns in condition mode, and the name
     * column in simple mode).
     *
     * @param {Object} payload - Object container
     * @param {TableBuilderHeaderType} payload.type - The type of header to display
     */
    setHeadersType(payload) {
      this.state.set(['headers', this.state.get('mode'), 'type'], payload.type);
    },

    /**
     * Sets the date format used for headers of static headers (all columns in condition mode, and the name column
     * in simple mode).
     *
     * @param {Object} payload - Object container
     * @param {String} payload.format - A string that can be passed to moment's format()
     */
    setHeadersFormat(payload) {
      this.state.set(['headers', this.state.get('mode'), 'format'], payload.format);
    },

    /**
     * Sets the name of the capsule property used for headers of metric value columns when the type is CapsuleProperty.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.property - The capsule property name
     */
    setHeadersProperty(payload) {
      if (_.isEmpty(payload.property)) {
        this.setHeadersType({ type: TableBuilderHeaderType.StartEnd });
        this.state.unset(['headers', this.state.get('mode'), 'property']);
      } else {
        this.state.set(['headers', this.state.get('mode'), 'property'], payload.property);
      }
    },

    /**
     * Sorts the table data and sets it in the store.
     */
    setSortedTableData() {
      this.waitFor(['sqTrendStore', 'sqTrendMetricStore', 'sqTrendCapsuleSetStore', 'sqTrendSeriesStore'], () => {
        if (!this.isSimpleMode()) {
          const itemIds = _.map(this.getTableItems(), 'id');
          const sortByItemIndex = field => (cell) => {
            const index = _.indexOf(itemIds, cell[field]);
            return index === -1 ? itemIds.length : index;
          };
          this.state.set(['tableData', TableBuilderMode.Condition, 'headers'],
            _.sortBy(this.state.get('tableData', TableBuilderMode.Condition, 'headers'), [sortByItemIndex('key')]));
          _.forEach(this.state.get('tableData', TableBuilderMode.Condition, 'capsules'), (capsules, i) => {
            this.state.set(['tableData', TableBuilderMode.Condition, 'capsules', i, 'values'],
              _.sortBy(capsules.values, [sortByItemIndex('formulaItemId')]));
          });
        } else {
          const tableData = this.maybeApplyDefaultSort(
            this.state.get('tableData', TableBuilderMode.Simple) as SimpleTableRow[], this.getTableItems(),
            this.getColumns());
          this.state.set(['tableData', TableBuilderMode.Simple], tableData);
        }
      });
    },

    /**
     * Removes metric information from all columns. Used to clear metric information before pushing new data into
     * the table.
     */
    removeAllMetricInfo() {
      const columnCount = this.getColumns().length;
      for (let i = 0; i < columnCount; i++) {
        this.state.unset(['columns', this.state.get('mode'), i, 'metrics']);
      }
    },

    /**
     * Builds the simple table data for presentation using the data returned from the API and the map of column
     * positions.
     *
     * @param data - The result of the formula. An array of rows that each contain an array of cells
     * @param headers - The headers of the formula result
     * @param columnPositions - The mapping of which cell corresponds to which column. This is what is returned by
     * #getSimpleTableFormulaAndParameters()
     */
    setSimpleTableData({ data, headers, columnPositions }:
      { data: [[]], headers: TableColumnOutputV1[], columnPositions: ColumnPosition[] }) {
      const columns = this.getColumnsWithDefinition();
      const items = this.getTableItems();
      const tableData: SimpleTableRow[] = _.chain(items)
        // Handles the edge case that the item has been added since the formula ran
        .filter(item => _.some(_.find(columnPositions, { itemId: item.id, key: SIMPLE_TABLE_ID_COLUMN })))
        .flatMap(item => _.flatMap(data, (resultRow) => {
          let ignoreRow = false;
          const formattedRow = {
            itemId: resultRow[_.find(columnPositions, { itemId: item.id, key: SIMPLE_TABLE_ID_COLUMN }).index],
            formulaItemId: item.id,
            cells: _.map(columns, (column) => {
              const getValue = (index) => {
                const value = sqTableBuilderHelper.formatMetricValue(resultRow[index], item.formatOptions, column);
                const headerUnit = headers[index].units;
                const units = !_.isNil(headerUnit) && !_.includes([STRING_UOM, '%'], headerUnit) ? headerUnit :
                  undefined;
                return { value, units };
              };

              const columnIndex = _.find(columnPositions, { itemId: item.id, key: column.key });
              if (!!column.filter && (_.isNil(columnIndex) || _.isNil(resultRow[columnIndex.index]))) {
                ignoreRow = true;
              }

              return _.cond([
                [_.isNil, () => ({ value: sqTableBuilderHelper.formatMetricValue(null, item.formatOptions) })],
                [_.property('metricId'), ({ index, metricId }) =>
                  ({ ...getValue(index), priorityColor: resultRow[index + 1], metricId })],
                // a simple metric does not have uom property, but we can still find it's unit if the 'valueMetric' is
                // displayed. In this case the aggregation function returns the value plus the unit in the 'headers'
                [_.matches({ key: COLUMNS_AND_STATS.valueUnitOfMeasure.key }), (({ index }) => {
                  const uom = resultRow[index];
                  if (uom === STRING_UOM) {
                    return { value: '' };
                  } else if (_.isNil(uom)) {
                    const maybeMetricIndex = _.find(columnPositions, { itemId: item.id, metricId: item.id })?.index;
                    if (!_.isNil(maybeMetricIndex)) {
                      return { value: headers[maybeMetricIndex].units };
                    }
                  }
                  return { value: uom };
                })],
                [_.stubTrue, ({ index }) => getValue(index)]
              ])(columnIndex);
            })
          };
          return ignoreRow ? [] : formattedRow;
        }))
        .thru(rows => this.maybeApplyDefaultSort(rows, items, columns))
        .value();

      this.state.set('fetchFailedMessage', undefined);
      this.state.set(['tableData', TableBuilderMode.Simple], tableData);
    },

    /**
     * Returns the data unchanged if we have user sort criteria. Otherwise, sort the data first by the item's position
     * in the details pane and then by asset.
     *
     * @param tableData - The data to sort
     * @param items - The items in the details pane
     * @param columns - The columns in the table
     */
    maybeApplyDefaultSort(tableData: SimpleTableRow[], items: any[], columns: any[]): SimpleTableRow[] {
      return this.isUserSortAllowed() && this.getMaxSortLevel() > 0 ? tableData :
        this.sortSimpleDataByItemsAndAsset(tableData, items, columns);
    },

    /**
     * Sorts the data first by the item's position in the details pane and then by asset.
     *
     * @param tableData - The data to sort
     * @param items - The items in the details pane
     * @param columns - The columns in the table
     */
    sortSimpleDataByItemsAndAsset(tableData: SimpleTableRow[], items: any[], columns: any[]): SimpleTableRow[] {
      const itemIds = _.map(items, 'id');
      const sortByItemIndex = (row) => {
        const index = _.indexOf(itemIds, row.formulaItemId);
        return index === -1 ? itemIds.length : index;
      };

      if (this.getAssetId()) {
        const assetIndex = _.findIndex(columns, ({ key }) => key === 'asset' || key === 'fullpath');
        if (assetIndex > -1) {
          return _.sortBy(tableData, [sortByItemIndex, `cells[${assetIndex}].value`]);
        }
      }

      return _.sortBy(tableData, [sortByItemIndex]);
    },

    /**
     * Builds the table data for display.
     *
     * @param headers - The headers for each column
     * @param table - The capsules and their values
     * @param itemColumnsMap - A mapping of which columns go to which items
     * @param customPropertyName - The name of a custom property on each capsule
     */
    setConditionTableData({ headers: rawHeaders, table, itemColumnsMap, customPropertyName }:
      { headers: any[], table: any[], itemColumnsMap: ItemColumnsMap, customPropertyName: undefined | string }) {
      // Filter prevents error if item was added while formula was running
      const items = _.filter(this.getTableItems(),
        item => itemColumnsMap[item.id] && item.itemType === ITEM_TYPES.METRIC);
      const [startAndEndColumns, columns] = _.chain(this.getColumnsWithDefinition() as any[])
        .filter(column => this.isPropertyOrStatColumn(column))
        .map(column => column.signalId ? { ...column, item: sqTrendSeriesStore.findItem(column.signalId) } : column)
        .partition(column => _.includes([COLUMNS_AND_STATS.startTime.key, COLUMNS_AND_STATS.endTime.key], column.key))
        .value();
      const headers: ConditionTableHeader[] = _.map(startAndEndColumns, column => ({
          key: column.key,
          name: $translate.instant(column.shortTitle)
        }))
        .concat(_.map(items, item => ({
          name: item.name,
          key: item.id,
          units: _.find(rawHeaders, { name: itemColumnsMap[item.id].value.key }).units,
          isStringColumn: sqUtilities.isStringSeries(item)
        })))
        .concat(_.map(columns, column => ({
          key: column.key,
          name: column.signalId ? `${column.item.name} ${$translate.instant(column.shortTitle)}` :
            (_.includes(CONDITION_EXTRA_COLUMNS, column.key) ? $translate.instant(column.shortTitle) : column.key),
          units: column.signalId ? _.find(rawHeaders, { name: column.key }).units : undefined,
          isStringColumn: _.find(rawHeaders, { name: column.key }) ?
            _.find(rawHeaders, { name: column.key }).type === 'string' : undefined
        })));
      const formatValue = (rawValue, item) => {
        // replace enums with the string representation
        const match = rawValue ? rawValue.toString().match(ENUM_REGEX) : false;
        return match ? match[2] : sqTableBuilderHelper.formatMetricValue(rawValue, item?.formatOptions ?? {});
      };
      const getDuration = (row) => {
        // Duration is a "hidden" property on capsules that we expose, but it is more accurate to compute it
        const duration = sqUtilities.getCapsuleDuration(row.startTime, row.endTime);
        return _.isNil(duration) ? NULL_PLACEHOLDER : sqDateTime.formatDuration(duration);
      };

      const capsules: ConditionTableCapsule[] = _.map(table, (row) => {
        // Start and end use the values from the capsule
        const values = _.map(startAndEndColumns, () => ({ value: null }))
          .concat(_.map(items, (item) => {
            const itemColumns = itemColumnsMap[item.id];
            const formulaItemId = item.id;
            const itemId = row[itemColumns.itemId.key];
            const value = formatValue(row[itemColumns.value.key], item);
            const priorityColor = row[itemColumns.priorityColor.key];
            return { itemId, formulaItemId, value, priorityColor } as ConditionTableValue;
          }))
          .concat(_.map(columns, column => ({
            value: column.key === SeeqNames.Properties.Duration ? getDuration(row) :
              formatValue(row[column.key], column.item)
          })));

        return {
          startTime: row.startTime,
          endTime: row.endTime,
          property: row[customPropertyName],
          values
        };
      });

      const tableData: ConditionTableData = { headers, capsules };

      this.state.set('fetchFailedMessage', undefined);
      this.state.set(['tableData', TableBuilderMode.Condition], tableData);
    },

    /**
     *  Sets table to be transposed (or not).
     *  If the table is not transposed (the initial default), then the time ranges will be shown in a row on top of
     *  the table.
     *  If the table is transposed, then the time ranges will be shown in a column on the left side of the table.
     *
     * @param {Object} payload - Object container
     * @param {boolean} payload.transposed - true if table is transposed, false if not
     */
    setIsTransposed(payload) {
      this.state.set(['isTransposed', this.state.get('mode')], payload.isTransposed);
    },

    /**
     *  Sets the asset over which the table will be run. If set it will be passed to the backend and the table
     *  formula will then run for each child asset and the results aggregated into a single table.
     *
     * @param {Object} payload - Object container
     * @param {string|undefined} payload.assetId - The ID of the parent asset or undefined to unset
     */
    setAssetId(payload) {
      this.state.set(['assetId', this.state.get('mode')], payload.assetId);
      if (_.isUndefined(payload.assetId)) {
        this.updateSortAndFilters();
      }
    },

    getAssetId() {
      return this.state.get('assetId', this.state.get('mode'));
    },

    isHomogenizeUnits() {
      return this.state.get('isHomogenizeUnits', this.state.get('mode'));
    },

    setIsHomogenizeUnits(payload) {
      this.state.set(['isHomogenizeUnits', this.state.get('mode')], payload.homogenizeUnits);
    },

    setIsMigrating(payload) {
      this.state.set('isMigrating', payload.isMigrating);
    },

    setIsTableStriped(payload) {
      this.state.set(['isTableStriped', this.state.get('mode')], payload.isTableStriped);
    },

    /**
     * Sets a message to indicate an error fetching the data. Clears the existing data since it is assumed to be
     * invalid.
     *
     * @param fetchFailedMessage - The error message
     * @param mode - The mode in which it failed
     */
    setFetchFailedMessage({ fetchFailedMessage, mode }) {
      this.state.set('fetchFailedMessage', fetchFailedMessage);
      if (mode === TableBuilderMode.Simple) {
        this.state.set(['tableData', TableBuilderMode.Simple], []);
      } else {
        this.state.set(['tableData', TableBuilderMode.Condition], { headers: [], capsules: [] });
      }
    },

    /**
     * Removes items.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} payload.items - An array of items to remove
     */
    removeItems(payload) {
      const removedIds = _.map(payload.items, 'id');
      if (this.isSimpleMode()) {
        this.updateSortAndFilters();

        this.state.set(['tableData', TableBuilderMode.Simple],
          _.chain(this.state.get('tableData', TableBuilderMode.Simple) as SimpleTableRow[])
            .reject(({ itemId, formulaItemId }) => _.intersection(removedIds, [itemId, formulaItemId]).length > 0)
            .map((row) => {
              const cells = _.map(row.cells,
                cell => _.includes(removedIds, cell.metricId) ? _.omit(cell, ['priorityColor', 'metricId']) : cell);
              return { ...row, cells };
            })
            .value());
      } else {
        const removedIndices = _.transform(
          this.state.get('tableData', TableBuilderMode.Condition, 'headers') as ConditionTableHeader[],
          (accum, header, index) => {
            if (_.includes(removedIds, header.key)) {
              accum.push(index);
            }
          },
          [] as number[]
        );
        this.state.set(['tableData', TableBuilderMode.Condition, 'headers'],
          _.reject(this.state.get('tableData', TableBuilderMode.Condition, 'headers') as any[],
            (v, k) => _.includes(removedIndices, k)));
        _.forEach(this.state.get('tableData', TableBuilderMode.Condition, 'capsules'), (capsules, i) => {
          this.state.set(['tableData', TableBuilderMode.Condition, 'capsules', i, 'values'],
            _.reject(capsules.values as any[], (v, k) => _.includes(removedIndices, k)));
        });
      }

      _.forEach(removedIds, (id) => {
        this.state.unset(['itemFilters', id]);
        this.state.unset(['itemSorts', id]);
      });

      const removeRelatedData = () => {
        if (_.isEmpty(_.reject(this.getTableItems(), ({ id }) => _.includes(removedIds, id)))) {
          this.state.unset(['assetId', this.state.get('mode')]);
        }

        const columnsToRemove = [];
        _.forEach(this.getColumns(), (column, index) => {
          if (column.cells) {
            this.state.set(['columns', this.state.get('mode'), index, 'cells'],
              _.pickBy(column.cells, (cell, id) => !_.includes(removedIds, id)));
          }
          if (_.includes(removedIds, column.signalId)) {
            columnsToRemove.push(column.key);
          }
          this.removeColumnMetricInfo(removedIds, index);
        });

        _.forEach(columnsToRemove, (key) => {
          this.removeColumn({ key });
        });
      };

      removeRelatedData();
      const originalMode = this.state.get('mode');
      const otherMode = originalMode === TableBuilderMode.Simple ? TableBuilderMode.Condition : TableBuilderMode.Simple;
      try {
        this.state.set('mode', otherMode);
        removeRelatedData();
      } finally {
        this.state.set('mode', originalMode);
      }
    },

    /**
     * Swaps out the specified items from one asset for the variants based off another asset.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object} payload.swaps - The items that were swapped where the keys are the swapped out ids and the
     *   values are the corresponding swapped in ids.
     * @param {Object} payload.outAsset - Asset that was swapped out
     * @param {String} payload.outAsset.id - The ID of the asset to swapped out
     * @param {String} payload.inAsset.name - The name of the asset that was swapped out
     * @param {Object} payload.inAsset - Asset that was swapped in
     * @param {String} payload.inAsset.id - The ID of the asset that was swapped in
     * @param {String} payload.inAsset.name - The name of the asset that was swapped in
     */
    swapItems(payload) {
      _.forEach(payload.swaps, (swappedInId, swappedOutId) => {
        _.forEach(['itemFilters', 'itemSorts'], (itemProperty) => {
          _.forEach(this.state.get(itemProperty), (obj, id) => {
            if (id === swappedOutId) {
              this.state.set([itemProperty, swappedInId], obj);
              this.state.unset([itemProperty, id]);
            }
          });
        });

        _.forEach([TableBuilderMode.Condition, TableBuilderMode.Simple], (mode) => {
          _.forEach(this.state.get('columns', mode), (column, index) => {
            if (column.signalId === swappedOutId) {
              this.state.merge(['columns', mode, index], {
                signalId: swappedInId,
                key: this.getColumnKey(column, swappedInId)
              });
            }
          });
        });
      });
    },

    /**
     * Enables the Metric Value column if a new simple metric was created and its statistic is not already displayed
     * in the table. It only happens if the table view is active and table mode is simple.
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.id - Simple metric identifier
     * @param {ThresholdMetricInputV1} payload.definition - Simple metric definition
     */
    handleSimpleMetricCreated(payload) {
      const isMetricValueEnabled = _.some(this.getColumnsWithDefinition(), ['key', COLUMNS_AND_STATS.metricValue.key]);
      const isAggregationFunctionAlreadyDisplayed = _.some(this.getColumnsWithDefinition(),
        ['stat', payload.definition.aggregationFunction]);

      if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.TABLE || !this.isSimpleMode() || isMetricValueEnabled ||
        isAggregationFunctionAlreadyDisplayed) {
        return;
      }

      this.addColumn({ column: { key: COLUMNS_AND_STATS.metricValue.key } });
      sqNotifications.infoTranslate('TABLE_BUILDER.METRIC_VALUE_COLUMN_AUTOMATICALLY_ENABLED');
    },

    /**
     * Removes metric information from the specified column. If an item is removed, all metric info entries are
     * removed. If a metric is removed, the corresponding metric info is removed.
     * @param removedIds - the item ids to look for
     * @param columnIndex - the column index
     */
    removeColumnMetricInfo(removedIds: string[], columnIndex: number) {
      const cursor = this.state.select('columns', this.state.get('mode'), columnIndex, 'metrics');
      _.forEach(cursor.get(), (metricInfo, itemId) => {
        if (_.includes(removedIds, metricInfo.id) || _.includes(removedIds, itemId)) {
          cursor.unset(itemId);
        }
      });
    },

    /**
     * Sets the chart view to be enabled or not
     * @param payload - payload.enabled boolean if chart view is enabled
     */
    setChartView(payload) {
      this.state.set(['chartView', 'enabled'], payload.enabled);
    },

    /**
     * Settings for the chart view
     * @param payload - payload.settings object with settings for the chart view
     */
    setChartViewSettings(payload) {
      this.state.select(['chartView', 'settings']).merge(payload.settings);
    },

    /**
     *  Set the distinctStringValueMap from data for the Simple Table
     *
     * @param payload object container for arguments
     * @param payload.stringValueTables: array of the tables, each containing distinct string values for a column
     * @param payload.columnKeysNamesList: list of objects containing columnKeys (for the displayed table) and
     *  columnNames (for the tables we got back from the calc engine) - one columnKey may correspond to more than
     *  one columnName
     */
    setSimpleDistinctStringValues(payload: { stringValueTables: any[], columnKeysNamesList: any[] }) {
      if (_.isEmpty(payload.stringValueTables) || !this.isSimpleMode()) {
        return;
      }
      const distinctStringValueMap = _.cloneDeep(this.state.get('distinctStringValueMap', TableBuilderMode.Simple));
      for (let i = 0; i < payload.stringValueTables.length; i++) {
        const tableData = payload.stringValueTables[i].data;
        const tableHeaders = payload.stringValueTables[i].headers;
        const { columnKey, columnNames } = payload.columnKeysNamesList[i];
        let distinctValues = [];
        _.forEach(columnNames, (columnName) => {
          const index = _.findIndex(tableHeaders, { name: columnName });
          distinctValues = distinctValues.concat(_.chain(tableData)
            .map(`[${index}]`)
            .reject(_.isNil)
            .value());
        });
        distinctStringValueMap[columnKey] = distinctValues;
      }
      _.forEach(_.keys(distinctStringValueMap), (existingKey) => {
        if (!_.includes(_.map(payload.columnKeysNamesList, 'columnKey'), existingKey)) {
          delete distinctStringValueMap[existingKey];
        }
      });
      this.state.set(['distinctStringValueMap', TableBuilderMode.Simple], distinctStringValueMap);
    },

    /**
     *  Set the distinctStringValueMap from data for the Condition Table
     *
     * @param payload object container for arguments
     * @param payload.stringValueTables: array of the tables, each containing distinct string values for a column
     * @param payload.columnKeysNamesList: list of objects containing columnKeys (for the displayed table) and
     *  columnNames (for the tables we got back from the calc engine)
     */
    setConditionDistinctStringValues(payload: { stringValueTables: any[], columnKeysNamesList: any[] }) {
      const distinctStringValueMap = _.cloneDeep(this.state.get('distinctStringValueMap', TableBuilderMode.Condition));
      for (let i = 0; i < payload.stringValueTables.length; i++) {
        const tableData = payload.stringValueTables[i].data.table;
        const { columnKey, columnName } = payload.columnKeysNamesList[i];
        distinctStringValueMap[columnKey] = _.map(tableData, columnName);
      }
      _.forEach(_.keys(distinctStringValueMap), (existingKey) => {
        if (!_.includes(_.map(payload.columnKeysNamesList, 'columnKey'), existingKey)) {
          delete distinctStringValueMap[existingKey];
        }
      });
      this.state.set(['distinctStringValueMap', TableBuilderMode.Condition], distinctStringValueMap);
    }
  };

  return store;
}
