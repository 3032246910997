import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { AssetGroupActions, CREATED_BY_SEEQ_WORKBENCH } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { API_TYPES } from '@/main/app.constants';
import { UtilitiesService } from '@/services/utilities.service';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { IconWithManagedSpinner } from '@/hybrid/core/IconWithManagedSpinner.atom';
import { AssetGroupStore } from '@/hybrid/assetGroupEditor/assetGroup.store';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { AuthorizationService } from '@/services/authorization.service';
import { NotificationsService } from '@/services/notifications.service';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { SeeqNames } from '@/main/app.constants.seeqnames';

const searchResultAssetIconsBindings = bindingsDefinition({
  item: prop<any>(),
  isAssetGroupView: prop<boolean>(),
  sqAssetGroupActions: injected<AssetGroupActions>(),
  sqUtilities: injected<UtilitiesService>(),
  sqSystemConfiguration: injected<SystemConfigurationService>(),
  sqAssetGroupStore: injected<AssetGroupStore>(),
  sqAuthorization: injected<AuthorizationService>(),
  sqNotifications: injected<NotificationsService>(),
  sqWorksheetActions: injected<WorksheetActions>(),
  sqWorksheetStore: injected<WorksheetStore>()
});

export const SearchResultAssetIcons: SeeqComponent<typeof searchResultAssetIconsBindings> = (props) => {
  const {
    sqAssetGroupActions,
    sqUtilities,
    sqSystemConfiguration,
    sqAssetGroupStore,
    sqAuthorization,
    sqNotifications,
    sqWorksheetActions,
    sqWorksheetStore
  } = useInjectedBindings(searchResultAssetIconsBindings);

  const { item, isAssetGroupView } = props;
  const storeAssets = useFluxPath(sqAssetGroupStore, () => sqAssetGroupStore.assets);
  const isEditingAssetGroup = useFluxPath(sqAssetGroupStore, () => sqAssetGroupStore.id === item.id);
  const isEditAllowed = sqUtilities.isAsset(item) &&
    sqSystemConfiguration.assetGroupEditorEnabled &&
    sqAuthorization.canWriteItem(item) &&
    (_.find(item.properties, { name: SeeqNames.Properties.TreeType }) as any)?.value === CREATED_BY_SEEQ_WORKBENCH &&
    _.isEmpty(item.ancestors);

  const addAsset = (addMatchingItemsOnly = false, addChildrenOfItemsOfItems = false) => {
    sqAssetGroupActions.setIsLoading(true);
    // TODO CRAB-23867 remove error message if/when we support metrics
    const promise = item.type === API_TYPES.THRESHOLD_METRIC
      ? sqNotifications.errorTranslate('ASSET_GROUP_EDITOR.ERROR_ADDING_METRIC')
      : sqAssetGroupActions.addAssetOrItem(item, addMatchingItemsOnly, addChildrenOfItemsOfItems);

    return promise.finally(() => sqAssetGroupActions.setIsLoading(false));
  };

  const loadAssetGroupForEdit = (): any => {
    sqWorksheetActions.setReturnViewKey(sqWorksheetStore.view.key);
    return sqAssetGroupActions.editAssetGroup(item);
  };
  return (
    <>
      {isEditAllowed &&
      <IconWithManagedSpinner
        icon="fa-pencil"
        testId="editAssetGroupIcon"
        disabled={isEditingAssetGroup}
        large={true}
        correctSpin={false}
        extraClassNames="searchBtn"
        action={loadAssetGroupForEdit}
        actionOnEnter={true}
        tooltip={!isEditingAssetGroup && 'SEARCH_DATA.EDIT_ASSET_GROUP_TOOLTIP'}
        tooltipDelay={500}
      />
      }

      {isAssetGroupView &&
      <>
        {/* assets and "items of items'" parents */}
        {(sqUtilities.isAsset(item) || (!sqUtilities.isAsset(item) && item.hasChildren)) &&
        <IconWithManagedSpinner
          icon="fc-plus-assets"
          extraClassNames="searchBtn"
          action={() => addAsset(false, !sqUtilities.isAsset(item))}
          actionOnEnter={true}
          correctSpin={false}
          large={true}
          disabled={isEditingAssetGroup}
          testId="addAssetIcon"
          tooltip={!isEditingAssetGroup && 'SEARCH_DATA.ADD_ASSET_TO_GROUP_TOOLTIP'}
          tooltipDelay={500}
        />
        }

        {/* assets and "items of items'" parents */}
        {(sqUtilities.isAsset(item) || (!sqUtilities.isAsset(item) && item.hasChildren))
        && !_.isEmpty(storeAssets) &&
        <IconWithManagedSpinner
          icon="fc-plus-selected"
          extraClassNames="searchBtn"
          action={() => addAsset(true)}
          actionOnEnter={true}
          correctSpin={false}
          large={true}
          disabled={isEditingAssetGroup}
          testId="addAssetIconSameChildrenOnly"
          tooltip={!isEditingAssetGroup && 'SEARCH_DATA.ADD_ASSET_TO_GROUP_CHILDREN_MATCH_TOOLTIP'}
          tooltipDelay={500}
        />
        }

        {/* items (including items of items' parents) */}
        {!sqUtilities.isAsset(item) &&
        <IconWithManagedSpinner
          icon="fa-plus-circle"
          extraClassNames="searchBtn"
          action={addAsset}
          actionOnEnter={true}
          correctSpin={false}
          large={true}
          testId="addItemToAssetIcon"
          tooltip="SEARCH_DATA.ADD_ASSET_ADD_ITEM_TOOLTIP"
          tooltipDelay={500}
        />
        }
      </>
      }
    </>
  );
};

