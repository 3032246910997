import React from 'react';
import { DropdownButton } from 'react-bootstrap';
import classNames from 'classnames';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Placement } from 'react-bootstrap/Overlay';

/**
 * An icon button with a dropdown. Content for the dropdown menu should be provided as children to the component.
 */
interface ButtonWithDropdownProps {
  /** <Icon/> to be used for the button */
  icon: JSX.Element;
  /** function to call on toggle (input whether or not the dropdown is open) */
  onToggle?: (open: boolean) => any;
  /** true if the button should be disabled */
  disabled?: boolean;
  /** text to display on the icon's tooltip */
  tooltipText?: string;
  /** position of the tooltip on the icon */
  tooltipPlacement?: Placement;
  /** tooltip delay in milliseconds */
  tooltipDelay?: number;
  /** true to align the menu to the ride side of the toggle */
  alignRight?: boolean;
  /** id that can be placed on the DropdownButton component */
  id?: string;
  /** extra class names to be placed on the DropdownButton component */
  extraClassNames?: string;
  /** id that will be used in the data-testid attribute on the button itself */
  testId?: string;
  /** extra class names to be placed on the icon element */
  iconWrapperClasses?: string;
}

export const ButtonWithDropdown: React.FunctionComponent<ButtonWithDropdownProps> = (props) => {
  const {
    icon,
    onToggle = () => {},
    tooltipText,
    tooltipPlacement = 'top',
    tooltipDelay = 0,
    alignRight = true,
    disabled,
    id,
    extraClassNames,
    testId,
    iconWrapperClasses
  } = props;

  const renderTitle = (
    <HoverTooltip placement={tooltipPlacement} delay={tooltipDelay} text={tooltipText}>
      <div data-testid={testId} className={iconWrapperClasses}>{icon}</div>
    </HoverTooltip>
  );

  return (
    <DropdownButton
      title={renderTitle}
      disabled={disabled}
      data-toggle="dropdown"
      className={classNames(extraClassNames, 'sq-btn-dropdown')}
      variant={null}
      bsPrefix="sq-btn"
      size="sm"
      alignRight={alignRight}
      onToggle={open => onToggle(open)}
      id={id}
      rootCloseEvent="click"
      onClick={e => e.stopPropagation()}>
      {props.children}
    </DropdownButton>
  );
};
