import bind from 'class-autobind-decorator';
import _ from 'lodash';
import { SignalSmoothingStore } from '@/hybrid/tools/signalSmoothing/signalSmoothing.store';
import { DateTimeService } from '@/datetime/dateTime.service';
import { DurationStore } from '@/trendData/duration.store';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { TrackService } from '@/track/track.service';
import { DURATION_TIME_UNITS } from '@/main/app.constants';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { SMOOTHING_ALGORITHMS, SMOOTHING_DEFAULTS, SMOOTHING_LIMITS } from './signalSmoothing.module';
import { InvestigateHelperService } from '@/investigate/investigateHelper.service';
import { FormulaService } from '@/services/formula.service';

@bind
export class SignalSmoothingActions {
  constructor(
    private flux: ng.IFluxService,
    private $translate: ng.translate.ITranslateService,
    public sqToolRunner: ToolRunnerService,
    public sqTrack: TrackService,
    public sqDateTime: DateTimeService,
    public sqDurationStore: DurationStore,
    public sqFormula: FormulaService,
    public sqInvestigateActions: InvestigateActions,
    public sqInvestigateHelper: InvestigateHelperService,
    private sqSignalSmoothingStore: SignalSmoothingStore) {
  }

  getFormulaAndTrackAction(algorithm) {
    let formula = '$inputSignal';
    let trackAction = '';
    const format = valueWithUnits => `${valueWithUnits.value}${valueWithUnits.units}`;

    const samplingRate = format(this.sqSignalSmoothingStore.samplingRate);
    const cutoff = format(this.sqSignalSmoothingStore.cutoff);
    const smoothingWindow = format(this.sqSignalSmoothingStore.smoothingWindow);
    const tauOrAlpha = this.sqSignalSmoothingStore.isTau
      ? format(this.sqSignalSmoothingStore.tau)
      : this.sqSignalSmoothingStore.alpha;

    switch (algorithm) {
      case SMOOTHING_ALGORITHMS.SEEQ_AGILE.VALUE:
        formula += `.agileFilter(${samplingRate},${smoothingWindow})`;
        trackAction = 'Seeq Agile Filter';
        break;
      case SMOOTHING_ALGORITHMS.SAVITSKY.VALUE:
        formula += `.sgFilter(${samplingRate},${smoothingWindow},${this.sqSignalSmoothingStore.polynomialFactor})`;
        trackAction = 'Savitsky-Golay Filter';
        break;
      case SMOOTHING_ALGORITHMS.LOW_PASS.VALUE:
        formula += `.lowPassFilter(${cutoff},${samplingRate},${this.calculateTaps()})`;
        trackAction = 'Low Pass Filter';
        break;
      case SMOOTHING_ALGORITHMS.MOVING_AVERAGE.VALUE:
        formula += `.movingAverageFilter(${samplingRate},${smoothingWindow})`;
        trackAction = 'Moving Average Filter';
        break;
      case SMOOTHING_ALGORITHMS.EXPONENTIAL.VALUE:
        formula += `.exponentialFilter(${tauOrAlpha},${samplingRate})`;
        trackAction = 'Exponential Filter';
        break;
    }

    return { formula, trackAction };
  }

  setAlgorithmSelectedValue(algorithmSelectedValue) {
    if (algorithmSelectedValue === SMOOTHING_ALGORITHMS.EXPONENTIAL.VALUE) {
      this.getEstimatedSamplePeriod();
    }
    this.flux.dispatch('SIGNAL_SMOOTHING_SET_ALGORITHM_SELECTED_VALUE', { algorithmSelectedValue });
  }

  setSamplingRate(samplingRate) {
    this.flux.dispatch('SIGNAL_SMOOTHING_SET_SAMPLING_RATE', { samplingRate });
  }

  setSmoothingWindow(smoothingWindow) {
    this.flux.dispatch('SIGNAL_SMOOTHING_SET_SMOOTHING_WINDOW', { smoothingWindow });
    // if isSamplingRateAuto is true then update the sampling rate whenever the smoothing window changes
    if (this.sqSignalSmoothingStore.isSamplingRateAuto) {
      this.recalculateSamplingRate(smoothingWindow);
    }
  }

  setCutoff(cutoff) {
    this.flux.dispatch('SIGNAL_SMOOTHING_SET_CUTOFF', { cutoff });
  }

  setPolynomialFactor(polynomialFactor) {
    this.flux.dispatch('SIGNAL_SMOOTHING_SET_POLYNOMIAL_FACTOR', { polynomialFactor });
  }

  setIsSamplingRateAuto(isSamplingRateAuto) {
    this.flux.dispatch('SIGNAL_SMOOTHING_SET_IS_SAMPLE_RATE_AUTO', { isSamplingRateAuto });

    if (isSamplingRateAuto) {
      this.recalculateSamplingRate(this.sqSignalSmoothingStore.smoothingWindow);
    }
  }

  setIsCutoffAuto(isCutoffAuto) {
    this.flux.dispatch('SIGNAL_SMOOTHING_SET_IS_CUTOFF_AUTO', { isCutoffAuto });

    if (isCutoffAuto) {
      this.recalculateCutoff(this.sqSignalSmoothingStore.samplingRate);
    }
  }

  setIsTau(isTau) {
    this.flux.dispatch('SIGNAL_SMOOTHING_SET_IS_TAU', { isTau });
  }

  setTau(tau) {
    this.flux.dispatch('SIGNAL_SMOOTHING_SET_TAU', { tau });
  }

  setAlpha(alpha) {
    this.flux.dispatch('SIGNAL_SMOOTHING_SET_ALPHA', { alpha });
  }

  setIsMigratedLowPassFilter(isMigratedLowPassFilter) {
    this.flux.dispatch('SIGNAL_SMOOTHING_SET_IS_MIGRATED_LOW_PASS_FILTER', { isMigratedLowPassFilter });
  }

  calculateTaps() {
    const windowDuration = this.sqDateTime.updateUnits(this.sqSignalSmoothingStore.smoothingWindow.value, 'ms',
      this.sqSignalSmoothingStore.smoothingWindow.units).value;
    const rateDuration = this.sqDateTime.updateUnits(this.sqSignalSmoothingStore.samplingRate.value, 'ms',
      this.sqSignalSmoothingStore.samplingRate.units).value;
    const taps = 2 * Math.floor((windowDuration / rateDuration) / 2) + 1;
    return taps > 3 ? taps : SMOOTHING_LIMITS.TAPS_MIN;
  }

  recalculateSamplingRate(smoothingWindow) {
    if (smoothingWindow.valid) {
      const windowMS = this.sqDateTime.updateUnits(smoothingWindow.value, 'ms', smoothingWindow.units).value;
      const newSamplingRate = windowMS / SMOOTHING_DEFAULTS.TAPS;
      const samplingRate = this.sqDateTime.determineIdealUnits({ value: newSamplingRate, units: 'ms' });
      this.setSamplingRate({ value: Math.round(samplingRate.value), units: samplingRate.units, valid: true });
    }
  }

  recalculateCutoff(samplingRate) {
    if (samplingRate.valid) {
      this.setCutoff(
        { value: SMOOTHING_DEFAULTS.CUTOFF_RATIO * samplingRate.value, units: samplingRate.units, valid: true });
    }
  }

  executeSignalSmoothing(formula, parameters, color?) {
    return this.sqToolRunner.panelExecuteSignal(
      this.sqSignalSmoothingStore.name,
      formula,
      parameters,
      this.sqSignalSmoothingStore.configParams,
      this.sqSignalSmoothingStore.id,
      color,
      { notifyOnError: false }
    );
  }

  setDefaultWindowSize() {
    // We don't want to override the window size when migrating existing low pass filters to the smoothing tool
    if (this.sqSignalSmoothingStore.isMigratedLowPassFilter) {
      return Promise.resolve();
    }

    return this.fetchEstimatedSamplePeriod()
      .then((result) => {
        const defaultSmoothingWindow = this.sqDateTime.determineIdealUnits({
          value: result.value * SMOOTHING_DEFAULTS.TAPS,
          units: result.uom
        });
        this.setSmoothingWindow(
          { units: defaultSmoothingWindow.units, value: Math.round(defaultSmoothingWindow.value), valid: true });
      });
  }

  fetchEstimatedSamplePeriod() {
    const parameters = { inputSignal: this.sqSignalSmoothingStore.inputSignal?.id };
    const formula = `estimateSamplePeriod($inputSignal, ${this.sqDateTime.getCapsuleFormula({
      start: this.sqDurationStore.displayRange.start,
      end: this.sqDurationStore.displayRange.end
    })})`;
    return this.sqFormula.computeScalar({ formula, parameters, cancellationGroup: 'estimateSamplePeriod' })
      .then(result => result);
  }

  getEstimatedSamplePeriod() {
    return this.fetchEstimatedSamplePeriod()
      .then((result) => {
        const samplingRate = this.sqDateTime.determineIdealUnits({
          value: result.value,
          units: result.uom
        });
        this.setSamplingRate(samplingRate);
      });
  }

  // Polynomial Factor must be greater than or equal to 1 and less than or equal to 7
  invalidPolynomialFactorRange(factor) {
    return factor < SMOOTHING_LIMITS.POLYNOMIAL_FACTOR_MIN || factor > SMOOTHING_LIMITS.POLYNOMIAL_FACTOR_MAX;
  }

  // Polynomial Factor must be less than the number of periods in a window
  invalidPolynomialFactorPeriods(factor) {
    const smoothingWindow = this.sqSignalSmoothingStore.smoothingWindow;
    const samplingRate = this.sqSignalSmoothingStore.samplingRate;
    if (samplingRate.valid && smoothingWindow.valid && !this.invalidPolynomialFactorRange(factor)) {
      const samplingRateMS = this.sqDateTime.updateUnits(samplingRate.value, 'ms', samplingRate.units).value;
      const smoothingWindowMS = this.sqDateTime.updateUnits(smoothingWindow.value, 'ms', smoothingWindow.units).value;
      return factor >= (smoothingWindowMS / samplingRateMS);
    }
    return false;
  }

  getPolynomialFactorUpperBound() {
    const smoothingWindow = this.sqSignalSmoothingStore.smoothingWindow;
    const samplingRate = this.sqSignalSmoothingStore.samplingRate;

    if (!smoothingWindow.valid || !samplingRate.valid) {
      return { limit: SMOOTHING_LIMITS.POLYNOMIAL_FACTOR_MAX };
    }

    const samplingRateMS = this.sqDateTime.updateUnits(samplingRate.value, 'ms', samplingRate.units).value;
    const smoothingWindowMS = this.sqDateTime.updateUnits(smoothingWindow.value, 'ms', smoothingWindow.units).value;

    const ratioLimit = smoothingWindowMS / samplingRateMS;
    // Make sure the ratio is in between the limits before returning
    return {
      limit: this.invalidPolynomialFactorRange(ratioLimit) ? SMOOTHING_LIMITS.POLYNOMIAL_FACTOR_MAX : ratioLimit
    };
  }

  // Smoothing Window must be greater than or equal to 3 times the Sampling Rate and less than or equal to 101 times
  // the Sampling Rate
  isWindowRatioInvalid({ samplingRate, smoothingWindow }) {
    const lowerBoundRatioValid = this.sqInvestigateHelper.checkWindowSizeRatio(samplingRate, smoothingWindow,
      SMOOTHING_LIMITS.WINDOW_SIZE_MIN_RATIO);
    const upperBoundRatioValid = this.sqInvestigateHelper.checkWindowSizeRatio(samplingRate, smoothingWindow,
      SMOOTHING_LIMITS.WINDOW_SIZE_MAX_RATIO, false);
    return smoothingWindow.valid && !lowerBoundRatioValid || !upperBoundRatioValid;
  }

  getWindowSizeErrorBounds(samplingRate) {
    const rate = this.sqDateTime.isFrequency(samplingRate.units)
      ? this.sqDateTime.convertFrequencyToPeriod(samplingRate)
      : samplingRate;
    const unit = _.find(DURATION_TIME_UNITS, unit => unit?.unit[0] === rate.units)?.unit[0] ?? rate?.units;

    const lowerBound = this.sqDateTime.determineIdealUnits(
      { value: rate.value * SMOOTHING_LIMITS.WINDOW_SIZE_MIN_RATIO, units: unit });
    const upperBound = this.sqDateTime.determineIdealUnits(
      { value: rate.value * SMOOTHING_LIMITS.WINDOW_SIZE_MAX_RATIO, units: unit });

    const getDisplayUnits = timeUnit =>
      this.$translate.instant(
        _.find(DURATION_TIME_UNITS, unit => unit.unit[0] === timeUnit.units)?.translationKey) ?? rate?.units;

    return {
      lowerBound: `${lowerBound.value} ${getDisplayUnits(lowerBound)}`,
      upperBound: `${upperBound.value} ${getDisplayUnits(upperBound)}`
    };
  }
}
