import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Resizable } from 're-resizable';
import { Icon } from '@/hybrid/core/Icon.atom';
import { MIN_FORMULA_PANEL_HEIGHT, MIN_FORMULA_PANEL_WIDTH } from '@/hybrid/tools/formula/formulaTool.module';
import { useResizeWatcher } from '@/hybrid/core/useResizeWatcher.hook';

export interface ResizableToolPanelProps {
  /** initial height of the panel */
  height: number;
  /** initial width of the panel */
  width: number;
  /** callback function on height change */
  setHeight?: (height: number) => void;
  /** callback function on width change */
  setWidth?: (height: number) => void;
  minimumWidth?: number;
  minimumHeight?: number;
  /** Ref to element that contains the ResizablePanel */
  refLink: React.RefObject<HTMLInputElement>;
}

const RightHandle = () => (
  <div className="height-maximum flexRowContainer width-12 flexCenter handle-right">
    <Icon icon='fa-angle-right' type="gray" />
  </div>
);

const BottomHandle = () => (
  <div className="height-12 flexRowContainer width-maximum flexCenter handle-bottom ">
    <Icon icon='fa-angle-down' type="gray" />
  </div>
);

/** User-Resizable ToolPanel as seen in Formula **/
export const ResizableToolPanel: React.FunctionComponent<ResizableToolPanelProps> = ({
  children,
  height: initialHeight = 500,
  width: initialWidth = 500,
  setHeight: setHeightInStore,
  setWidth: setWidthInStore,
  minimumWidth,
  minimumHeight,
  refLink
}) => {
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);
  const resizePanel = () => {
    const maxAvailableHeight = document.body.getBoundingClientRect().height - 130;
    const maxAvailableWidth = document.body.getBoundingClientRect().width - refLink.current.getBoundingClientRect().left;

    // Only adjust size of panel if the size of the browser is smaller than the size of the window and the size of
    // the window is bigger than the minimum

    if (maxAvailableHeight < height && maxAvailableHeight >= MIN_FORMULA_PANEL_HEIGHT) {
      setHeightInStore(maxAvailableHeight);
    }

    if (maxAvailableWidth < width && maxAvailableWidth >= MIN_FORMULA_PANEL_WIDTH) {
      setWidthInStore(maxAvailableWidth);
    }
  };

  const removeResizeWatcher = useResizeWatcher({ element: document.body, callback: resizePanel, callOnLoad: false });

  useEffect(() => () => {
    removeResizeWatcher();
  }, []);

  useEffect(() => {
    if (initialHeight !== height) {
      setHeight(initialHeight);
    }
    if (initialWidth !== width) {
      setWidth(initialWidth);
    }
  }, [initialWidth, initialHeight]);

  return <Resizable
    bounds="window"
    size={{ width, height }}
    minWidth={minimumWidth}
    minHeight={minimumHeight}
    handleClasses={{ right: 'mr7', bottom: 'mb7' }}
    handleComponent={{ right: <RightHandle />, bottom: <BottomHandle /> }}
    className="panel panel-primary pt15 pr25 pb15 resizablePanel"
    enable={{ top: false, left: false, right: true, bottom: true, bottomRight: true }}
    onResizeStop={(e, direction, ref, d) => {
      // this duplication of setting it in state and store is necessary to avoid unsightly flickering
      setWidth(width + d.width);
      setHeight(height + d.height);
      _.isFunction(setWidthInStore) && setWidthInStore(width + d.width);
      _.isFunction(setHeightInStore) && setHeightInStore(height + d.height);
    }}>
    {children}
  </Resizable>;
};
