import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { FormControl } from 'react-bootstrap';
import { getFormFieldProps, getValidationFunction } from '@/hybrid/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { FormError } from '@/hybrid/core/FormError.atom';
import classNames from 'classnames';
import { HelpIcon } from '@/hybrid/core/Icon.atom';

export interface FormControlIF extends ValidatingFormComponent <string | number> {
  component: 'FormControlFormComponent';
  size: string;
  placeholder?: string;
  className?: string;
  type?: string;
  step?: number;
  maxLength?: number;
  max?: number;
  min?: number;
  rows?: number;
  as?: string;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;
  onKeyDown?: (key: string, value: string) => void;
  customError?: string;
  customErrorParams?: {};
  disabled?: boolean;
  fixedWidth?: boolean;
  skipMemo?: boolean;
  onIconClick?: () => any;
}

export const FormControlFormComponent: React.FunctionComponent<FormControlIF> = (props) => {
  const {
    name,
    extendValidation,
    validation,
    placeholder,
    onChange,
    onBlur,
    onKeyDown,
    customError,
    customErrorParams = {},
    type = 'text',
    step,
    fixedWidth = true,
    extraClassNames,
    tooltip,
    onIconClick
  } = props;

  const { t } = useTranslation();
  const defaultValidation = value => _.isEmpty(_.trim(value));

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();

  return <Field name={name} validate={appliedValidation}>
    {({ input, meta }) => {
      const properProps = _.assign({},
        getFormFieldProps(formState, input, meta, props),
        {
          placeholder: t(placeholder),
          value: input.value,
          onChange: (e) => {
            input.onChange(e.target.value);
            onChange(e.target.value);
          },
          onBlur: e => _.isFunction(onBlur) && onBlur(e.target.value),
          onKeyDown: e => onKeyDown?.(e.key, e.target.value),
          onFocus: e => e.target.select()
        });

      return (
        <div
          className={classNames('flexRowContainer', extraClassNames, {
            'width-100percent': fixedWidth,
          })}
        >
          <div className="flexColumnContainer flexAlignCenter">
            <FormControlMemo
              {..._.omit(properProps, [
                'fromFormBuilder',
                'testId',
                'component',
                'elementType',
                'includeIf',
                'customErrorParams',
                'extendValidation',
                'validation',
                'customError',
                'showError',
                'customErrorParams',
                'fixedWidth',
                'extraClassNames',
              ])}
              type={type}
              step={step}
            />
            {tooltip && (
              <HelpIcon
                tooltip={tooltip}
                extraClassNames="pl5"
                onClick={onIconClick}
              />
            )}
          </div>
          {properProps.showError && customError && (
            <FormError
              errorText={customError}
              errorParameters={customErrorParams}
              extraClassNames="mt10"
            />
          )}
        </div>
      );
    }}
  </Field>;
};

export const FormControlMemo = React.memo(FormControl,
  (prev, next) => !(
    !_.isEqual(prev.value, next.value) ||
    prev.disabled !== next.disabled ||
    prev.placeholder !== next.placeholder ||
    prev.className !== next.className ||
    prev.skipMemo
  ));


