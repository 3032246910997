import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { ASSET_PATH_SEPARATOR } from '@/main/app.constants';
import { angularComponent } from '@/hybrid/core/react2angular.util';

const pathComponentsBindings = bindingsDefinition({
  asset: prop<any>(),
  actionOnComponent: prop<(any) => void>()
});

export const PathComponents: SeeqComponent<typeof pathComponentsBindings> = (props) => {
  const { asset, actionOnComponent } = props;

  const pathNames = _.split(asset.formattedName, ASSET_PATH_SEPARATOR);
  const pathComponents = _.zipWith(pathNames, asset.pathComponentIds, asset.pathComponentDisabled,
    (name, id, disabled) => ({ name, id, disabled }));

  const getPathComponentSpan = (component: any) => (
      <span className={component.disabled ? 'sq-fairly-dark-gray disabledBehavior' : 'force-link-look cursorPointer'}
        data-stoppropagation="true"
        onClick={() => { actionOnComponent(component); }}>
        {component.name}
      </span>
  );

  return (
    <span data-testid="pathComponents" data-stoppropagation="true">
      {
        pathComponents
          .map(component => getPathComponentSpan(component))
          .reduce((acc, c) => acc === null ?
            c : <>{acc} {<span className="sq-fairly-dark-gray" data-stoppropagation="true">{ASSET_PATH_SEPARATOR}</span>} {c} </>)
      }
    </span>
  );
};

export const sqPathComponents = angularComponent(pathComponentsBindings, PathComponents);
