import angular from 'angular';
import { BaseSearchStoreService } from '@/search/baseSearchStore.service';
import { SEARCH_PANES } from '@/search/search.module';

/**
 * Provides an instance of search state used when the search widget is used in a modal. Does not dehyrdate any state
 * since the tool is shown in a modal.
 */
angular.module('Sq.Search').store('sqModalSearchStore', sqModalSearchStore);

export type ModalSearchStore = ReturnType<typeof sqModalSearchStore>['exports'];

function sqModalSearchStore(sqBaseSearchStore: BaseSearchStoreService) {

  return sqBaseSearchStore.extend({}, SEARCH_PANES.MODAL);
}
