import React from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { Icon } from '@/hybrid/core/Icon.atom';

const minimapHandleBindings = bindingsDefinition({
  color: prop<string>(),
  id: prop<string>()
});

/**
 * Base minimap handle (just an icon)
 */
export const MinimapHandle: SeeqComponent<typeof minimapHandleBindings> = (props) => {
  const {
    color,
    id
  } = props;

  return <Icon icon="fa-caret-down" large={true} type="color" color={color} testId={id} />;
};
