import angular from 'angular';
import { DurationActions } from '@/trendData/duration.actions';
import { DurationStore } from '@/trendData/duration.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { UtilitiesService } from '@/services/utilities.service';

angular.module('Sq.TrendViewer').controller('RangeSelectorCtrl', RangeSelectorCtrl);

function RangeSelectorCtrl(
  $scope: ng.IScope,
  sqDurationActions: DurationActions,
  sqDurationStore: DurationStore,
  sqWorksheetStore: WorksheetStore,
  sqUtilities: UtilitiesService) {
  const vm = this;

  activate();

  function activate() {
    vm.updateDisplayRangeStart = sqDurationActions.displayRange.updateStart;
    vm.updateDisplayRangeEnd = sqDurationActions.displayRange.updateEnd;
    vm.updateDisplayRangeTimes = sqDurationActions.displayRange.updateTimes;
    vm.updateDisplayRangeDuration = sqDurationActions.displayRange.updateDuration;
    vm.displayRangeStepBack = sqDurationActions.displayRange.stepBack;
    vm.displayRangeStepBackHalf = sqDurationActions.displayRange.stepBackHalf;
    vm.displayRangeStepForward = sqDurationActions.displayRange.stepForward;
    vm.displayRangeStepForwardHalf = sqDurationActions.displayRange.stepForwardHalf;
    vm.displayRangeStepToEnd = sqDurationActions.displayRange.stepToEnd;
    vm.updateInvestigateRangeStart = sqDurationActions.investigateRange.updateStart;
    vm.updateInvestigateRangeEnd = sqDurationActions.investigateRange.updateEnd;
    vm.updateInvestigateRangeTimes = sqDurationActions.investigateRange.updateTimes;
    vm.updateInvestigateRangeDuration = sqDurationActions.investigateRange.updateDuration;
    vm.copyDisplayRangeToInvestigateRange = sqDurationActions.investigateRange.copyFromDisplayRange;
    vm.copyInvestigateRangeToDisplayRange = sqDurationActions.displayRange.copyFromInvestigateRange;
    vm.isPresentationMode = sqUtilities.isPresentationWorkbookMode;

    $scope.$listenTo(sqWorksheetStore, ['timezone'], syncWithWorksheetStore);
    $scope.$listenTo(sqDurationStore, syncWithDurationStore);
  }

  /**
   * Updates view model with properties from the trend store
   */
  function syncWithWorksheetStore() {
    vm.timezone = sqWorksheetStore.timezone;
  }

  /**
   * Updates view model with properties from the duration store
   */
  function syncWithDurationStore() {
    vm.displayRange = sqDurationStore.displayRange;
    vm.investigateRange = sqDurationStore.investigateRange;
  }
}
