import _ from 'lodash';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';

export type ScorecardStore = ReturnType<typeof sqScorecardStore>['exports'];

export function sqScorecardStore() {
  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.WORKSHEET,
    initialize() {
      this.state = this.immutable({
        metrics: []
      });
    },

    exports: {
      get metrics() {
        return this.state.get('metrics');
      }
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @return {Object} State for the store
     */
    dehydrate() {
      const data = this.state.serialize();
      _.forEach(data.metrics, function(m) {
        _.unset(m, 'valueResult');
        _.unset(m, 'foregroundColor');
        _.unset(m, 'backgroundColor');
      });

      return data;
    },

    /**
     * Sets the scorecard view state
     *
     * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      // as part of parameterized stats in R21 we migrated to a more robust statistic selector
      // Note: these should not be updated, even if the operator is renamed, because they are embedded in the
      // workstep and thus are immutable.
      const legacyMap = {
        '$series.min($capsule).getValue()': 'min',
        '$series.max($capsule).getValue()': 'max',
        '$series.average($capsule)': 'average',
        '$series.standardDeviation($capsule)': 'standardDeviation',
        '$series.totalized($capsule)': 'totalized',
        '$series.rate($capsule)': 'rate',
        '$series.getValue($capsule.getStart())': 'valueStart',
        '$series.getValueAtOrBefore($capsule.getEnd())': 'valueEnd',
        '$series.delta($capsule)': 'delta',
        '$series.range($capsule)': 'range',
        '$series.sum($capsule)': 'sum',
        '$series.count($capsule)': 'count',
        '$series.getEnds().count($capsule)': 'count.ends',
        '$series.getStarts().count($capsule)': 'count.starts',
        '$series.percentDuration($capsule)': 'percentDuration',
        '$series.totalDuration($capsule)': 'totalDuration'
      };
      const metrics = _.map(dehydratedState.metrics, (metric: any) => {
        if (_.isUndefined(metric.stat)) {
          metric.stat = { key: _.get(legacyMap, metric.formula), timeUnits: metric.timeUnits };
        }

        if (_.isUndefined(metric.stat.timeUnits)) {
          metric.stat.timeUnits = 's';
        }

        return metric;
      });

      this.state.set('metrics', metrics);
    },

    handlers: {
      SCORECARD_ADD_METRIC: 'addMetric',
      SCORECARD_REMOVE_METRIC: 'removeMetric',
      SCORECARD_VALUE_RESULT: 'setValueResult',
      TREND_SWAP_ITEMS: 'swapItems'
    },

    /**
     * Add or update a metric in the scorecard. If an existing metric with the same metricId exists, it will be
     * replaced with the new values.
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.metric - The metric information
     * @param {String} payload.metric.metricId - The GUID of the metric.
     * @param {String} payload.metric.name - The name of the metric.
     * @param {String} payload.metric.type - The tool type of the metric. Currently 'metric-signal'
     * @param {String} payload.metric.itemType - The ITEM_TYPE of the series
     * @param {String} payload.metric.itemId - The identifier of the series to compute the statistic against
     * @param {Object} payload.metric.stat - The stat of the metric

     */
    addMetric(payload) {
      const metric = _.cloneDeep(payload.metric);
      const index = _.findIndex(this.state.get('metrics'), { metricId: metric.metricId });
      if (index < 0) {
        this.state.push('metrics', metric);
      } else {
        this.state.set(['metrics', index], metric);
      }
    },

    /**
     * Remove metric.
     *
     * @param {Object} payload - Object container
     * @param {string} payload.metricId - The id of the metric
     */
    removeMetric(payload) {
      this.state.set('metrics', _.reject(this.state.get('metrics'), { metricId: payload.metricId }));
    },

    /**
     * Capture the value and color for an execution of the metric.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.metricId - The id of the metric (not index)
     * @param {String} payload.valueResult - The formatted result of the metric computation
     * @param {String} payload.backgroundColor - The color of the for the value, as computed from the thresholds
     * @param {String} payload.foregroundColor - A contrasting color for the text
     */
    setValueResult(payload) {
      const index = _.findIndex(this.state.get('metrics'), { metricId: payload.metricId });
      this.state.set(['metrics', index, 'valueResult'], payload.valueResult);
      this.state.set(['metrics', index, 'backgroundColor'], payload.backgroundColor);
      this.state.set(['metrics', index, 'foregroundColor'], payload.foregroundColor);
    },

    /**
     * Swaps out the loaded id for the one of a new variant based off an asset swap.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object} payload.swaps - The items that were swapped where the keys are the swapped out ids and the
     *   values are the corresponding swapped in ids.
     */
    swapItems(payload) {
      _.forEach(this.state.get('metrics'), (metric: any, index) => {
        const swappedId = payload.swaps[metric.itemId];
        if (swappedId) {
          this.state.set(['metrics', index, 'itemId'], swappedId);
        }
      });
    }
  };

  return store;
}
