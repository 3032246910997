import _ from 'lodash';
import angular from 'angular';
import { AggregationBinActions } from '@/investigate/aggregationBins/aggregationBin.actions';
import { InvestigateHelperService } from '@/investigate/investigateHelper.service';
import { AggregationBinStore } from '@/investigate/aggregationBins/aggregationBin.store';
import { ITEM_TYPES } from '@/trendData/trendData.module';
import { ToolPanelHelperService } from '@/services/toolPanelHelper.service';

export const Y_VALUE_BIN_MODES = { SIZE: 'size', NUMBER: 'number' };
export const BIN_ERRORS =  {
  MIN_EXCEEDS_MAX: 'MIN_EXCEEDS_MAX',
  INT_REQUIRED: 'INT_REQUIRED',
  MIN_NOT_A_NUMBER: 'MIN_NOT_A_NUMBER',
  MAX_NOT_A_NUMBER: 'MAX_NOT_A_NUMBER',
  INVALID_BIN_SIZE: 'INVALID_BIN_SIZE'
};

angular.module('Sq.Investigate')
  .controller('ByYValueCtrl', ByYValueCtrl);

function ByYValueCtrl(
  $scope: ng.IScope,
  sqAggregationBinActions: AggregationBinActions,
  sqAggregationBinStore: AggregationBinStore,
  sqInvestigateHelper: InvestigateHelperService,
  sqToolPanelHelper: ToolPanelHelperService
) {

  const vm = this;
  vm.Y_VALUE_BIN_MODES = Y_VALUE_BIN_MODES;
  vm.ERRORS = BIN_ERRORS;
  vm.ITEM_TYPES = ITEM_TYPES;
  vm.setBinCreationMode = setBinCreationMode;
  vm.setYValueBinMax = setYValueBinMax;
  vm.setYValueBinMin = setYValueBinMin;
  vm.setSignal = setSignal;
  vm.setBinSize = setBinSize;
  vm.setBinNumber = setBinNumber;
  vm.displayBinConfigCheck = displayBinConfigCheck;
  vm.hasValidItemParameters = sqToolPanelHelper.hasValidItemParameters;
  // exposed for testing
  vm.syncAggregationStore = syncAggregationStore;

  $scope.$listenTo(sqAggregationBinStore, syncAggregationStore);

  /**
   * Syncs sqAggregationBinStore.
   */
  function syncAggregationStore() {
    vm.param = _.find(sqAggregationBinStore.aggregationConfigs, { id: vm.id });
    if (!_.isEmpty(vm.param)) {
      vm.error = sqInvestigateHelper.validateBinConfig(vm.param);
      _.assign(vm, _.pick(vm.param, ['yValueBinMin', 'yValueBinMax', 'binSize', 'numberOfBins',
        'yValueBinMode', 'item']));
    } else {
      vm.yValueBinMode = Y_VALUE_BIN_MODES.SIZE;
    }
  }

  /**
   * Sets the yValueBinMode.
   * The yValueBinMode is used to determine if bins should be created by size or if a specific number of bins should
   * be created. Also resets the value for the not selected option.
   *
   * @param {String} mode - one of Y_VALUE_BIN_MODES
   */
  function setBinCreationMode(mode) {
    vm.yValueBinMode = mode;
    sqAggregationBinActions.setBinMode(vm.yValueBinMode, vm.id);
    if (mode === Y_VALUE_BIN_MODES.NUMBER) {
      sqAggregationBinActions.setBinSize(undefined, vm.id);
    } else {
      sqAggregationBinActions.setBinNumber(undefined, vm.id);
    }
  }

  /**
   * Persists the bin size to the store.
   */
  function setBinSize() {
    sqAggregationBinActions.setBinSize(vm.binSize, vm.id);
  }

  /**
   * Persists the number of bins to the store.
   */
  function setBinNumber() {
    sqAggregationBinActions.setBinNumber(vm.numberOfBins, vm.id);
  }

  /**
   * Persists the minimum range used for the y-value bin definition to the store.
   */
  function setYValueBinMax() {
    sqAggregationBinActions.setYValueBinMax(vm.yValueBinMax, vm.id);
  }

  /**
   * Persists the maximum range used for the y-value bin definition to the store.
   */
  function setYValueBinMin() {
    sqAggregationBinActions.setYValueBinMin(vm.yValueBinMin, vm.id);
  }

  /**
   * Whenever a new series to define bins is selected the min and max input fields are automatically
   * pre-populated with the rounded min/max values in the given display range.
   * The rounding ensures "nice" labels.
   *
   * @param {Object} item - the selected Signal
   */
  function setSignal(item) {
    vm.yValueBinMin = Math.floor(_.get(item, 'yAxisMin', 0));
    vm.yValueBinMax = Math.ceil(_.get(item, 'yAxisMax'));

    vm.setYValueBinMin();
    vm.setYValueBinMax();
    sqAggregationBinActions.setAggregationItem(item, vm.id);
  }

  /**
   * Helper function that determines if the checkmark should be shown for the bin definition section.
   *
   * @returns {Boolean} true if all entries are valid, false otherwise
   */
  function displayBinConfigCheck() {
    return sqInvestigateHelper.validateBinConfig(vm.param) === '';
  }
}
