import React from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import _ from 'lodash';
import { DateRange } from '@/reportEditor/report.module';
import { ReportActions } from '@/reportEditor/report.actions';
import { ReportStore } from '@/reportEditor/report.store';
import { LoggerService } from '@/services/logger.service';
import { ModalService } from '@/services/modal.service';
import { TrackService } from '@/track/track.service';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { useInjectedBindings } from '../core/useInjectedBindings.hook';
import { useFluxPath } from '../core/useFluxPath.hook';
import reportDateModalTemplate from '@/reportEditor/reportDateModal.html';
import { useFlux } from '../core/useFlux.hook';
import { ReportConfigDateRangeTable } from './ReportConfigDateRangeTable.molecule';
import { ConfigureAutoUpdateModal } from './ConfigureAutoUpdateModal.organism';
import { AutoUpdateSection } from './AutoUpdateSection.molecule';
import { UtilitiesService } from '@/services/utilities.service';

/**
 * Returns true if date ranges should not be edited because we are previewing a backup or already updating a date
 * range
 */
function areDateRangesReadOnly(sqReportStore): boolean {
  return sqReportStore.backupPreview || sqReportStore.dateRangeUpdating;
}

/**
 * Error handling helper for managing the `dateRangeUpdating` flag. It ensures that two actions don't try
 * and manipulate the date ranges at the same time.
 *
 */
function withDateRangeUpdating(sqReportStore, sqReportActions, sqLogger) {
  return (action) => {
    if (areDateRangesReadOnly(sqReportStore)) {
      return;
    }
    sqReportActions.setDateRangeUpdating(true);
    return Promise.resolve()
      .then(action)
      .catch(e => sqLogger.error(sqLogger.format`Error updating date range from topic left panel: ${e}`))
      .finally(() => {
        sqReportActions.setDateRangeUpdating(false);
      });
  };
}

/**
 * Opens the date selector modal.
 *
 */
function openDateSelector(sqModal, sqReportStore, sqReportActions, sqLogger): (dateRange: DateRange) => void {
  return (dateRange) => {
    const dr = dateRange ? _.cloneDeep(dateRange) : { auto: { enabled: false } };
    withDateRangeUpdating(sqReportStore, sqReportActions, sqLogger)(() =>
      sqModal.open({
        animation: false,
        controller: 'ReportDateModalCtrl',
        controllerAs: '$ctrl',
        template: reportDateModalTemplate,
        resolve: {
          dateRange: () => dr
        }
      }).result
        .then((next) => {
          if (next && !sqReportStore.hasReportSchedule && dr.auto.enabled) {
            sqReportActions.setShowConfigureAutoUpdateModal(true, true);
          }
        })
    );
  };
}

function onTzClose(sqReportActions) {
  sqReportActions.setShowConfigureAutoUpdateModal(false);
}

const reportConfigDatesBindings = bindingsDefinition({
  sqReportStore: injected<ReportStore>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqReportActions: injected<ReportActions>(),
  sqModal: injected<ModalService>(),
  sqLogger: injected<LoggerService>(),
  sqTrack: injected<TrackService>(),
  sqUtilities: injected<UtilitiesService>()
});

export const ReportConfigDates: SeeqComponent<typeof reportConfigDatesBindings> = () => {
  const {
    sqReportStore,
    sqWorksheetStore,
    sqReportActions,
    sqModal,
    sqLogger,
    sqTrack,
    sqUtilities
  } = useInjectedBindings(reportConfigDatesBindings);

  // force the props to be updated so we can get the new name
  const timezoneName = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.timezone?.name);
  const { backupPreview, sandboxMode, dateRangeUpdating, dateRangesNotArchived } = useFlux(sqReportStore);
  const viewOnlyWorkbookMode = sqUtilities.isViewOnlyWorkbookMode;
  const isSandboxMode = sandboxMode.enabled;
  const readOnly = viewOnlyWorkbookMode && !isSandboxMode;

  return (
    <>
      <ReportConfigDateRangeTable
        isSandboxMode={isSandboxMode}
        viewOnly={viewOnlyWorkbookMode}
        backupPreview={backupPreview}
        dateRanges={dateRangesNotArchived}
        dateRangeUpdating={dateRangeUpdating}
        doTrack={sqTrack.doTrack}
        hasAutoDateRanges={sqReportStore.hasAutoDateRanges}
        hasFixedDateRanges={sqReportStore.hasFixedDateRanges}
        openDateSelector={openDateSelector(sqModal, sqReportStore, sqReportActions, sqLogger)}
        timezone={timezoneName}
        withDateRangeUpdating={withDateRangeUpdating(sqReportStore, sqReportActions, sqLogger)}
      />
      {sqReportStore.showConfigureAutoUpdateModal &&
      <ConfigureAutoUpdateModal
        schedule={sqReportStore.reportSchedule}
        onClose={onTzClose.bind(null, sqReportActions)}
        reportScheduleOverride={sqReportStore.reportScheduleOverride}
        show={sqReportStore.showConfigureAutoUpdateModal && !readOnly}
      />
      }
      {sqReportStore.hasAutoDateRanges &&
      <div className="flexRowContainer toolOptions mb20">
        <AutoUpdateSection timezone={timezoneName} viewOnly={viewOnlyWorkbookMode} />
      </div>
      }
    </>
  );
};
