import _ from 'lodash';
import angular from 'angular';
import groupAddEditModalTemplate from './group.addEdit.modal.html';
import { UtilitiesService } from '@/services/utilities.service';
import { ModalService } from '@/services/modal.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { UserGroupsApi, UsersApi } from '@/sdk';
import { EVERYONE_USERGROUP } from '@/hybrid/administration/Administration.page';

/**
 * The group administration service handles the data and methods needed for group administration
 */
angular.module('Sq.Administration')
  .service('sqGroupAdmin', sqGroupAdmin);

export type GroupAdminService = ReturnType<typeof sqGroupAdmin>;

function sqGroupAdmin(
  sqModal: ModalService,
  sqUtilities: UtilitiesService,
  sqUsersApi: UsersApi,
  sqUserGroupsApi: UserGroupsApi,
) {

  const service = {
    openGroupAddEditModal,
    isGroupReadOnly,
    isGroupEveryone,
    formatGroups
  };

  return service;


  function openGroupAddEditModal(group) {
    return sqModal.open({
      animation: true,
      controller: 'GroupAddEditCtrl',
      controllerAs: 'ctrl',
      template: groupAddEditModalTemplate,
      resolve: {
        group: group ? sqUserGroupsApi.getUserGroup({ userGroupId: group.id }).then(({ data }) => data) : undefined,
        allUsers: sqUsersApi.getUsers({ limit: 5000 }).then(({ data }) => data),
        allGroups: sqUserGroupsApi.getUserGroups({ limit: 5000 }).then(({ data }) => data),
        isReadOnly: service.isGroupReadOnly(group),
        isEveryone: service.isGroupEveryone(group)
      }
    }).result
      .catch(sqUtilities.handleModalOpenErrors);
  }

  /**
   * Determines if a group is readonly. All groups not from the Seeq datasource ID are readonly because they are
   * updated by a connector except the ones having 'Remote Group Editable' set to true.
   *
   * @param {Object} group - a group
   */
  function isGroupReadOnly(group) {
    if (_.isNil(group)) {
      return false;
    }

    return group.datasourceId !== SeeqNames.LocalDatasources.Authentication.DatasourceId && !group.isRemoteGroupEditable;
  }

  function isGroupEveryone(group) {
    return group?.name === EVERYONE_USERGROUP;
  }

  function formatGroups(groups) {
    return _.chain(groups)
      .map((group) => {
        const datasourceName = group?.datasource?.name || group?.datasourceName;
        const datasourceId = group?.datasource?.datasourceId || group?.datasourceId;
        // Add datasource name and ID so they can be referenced as a column definition field
        return _.omitBy({ ...group, datasourceName, datasourceId }, _.isUndefined);
      })
      .value();
  }
}
