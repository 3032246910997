import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { ReportStore } from '@/reportEditor/report.store';
import { FroalaReportContentService } from '@/reportEditor/froalaReportContent.service';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { QUARTZ_CRON_PENDING_INPUT } from '@/reportEditor/report.module';

const dateRangeIconBindings = bindingsDefinition({
  dateRange: prop<any>(),
  includeTooltip: prop.optional<boolean>(),
  tooltipDelay: prop.optional<number>(),
  extraClassNames: prop.optional<string>(),
  sqReportStore: injected<ReportStore>(),
  sqFroalaReportContent: injected<FroalaReportContentService>()
});

export const DateRangeIcon: SeeqComponent<typeof dateRangeIconBindings> = (props) => {
  const { sqReportStore, sqFroalaReportContent } = useInjectedBindings(dateRangeIconBindings);
  const { dateRange, includeTooltip = false, tooltipDelay = 500, extraClassNames } = props;
  const { t } = useTranslation();

  const reportSchedule = useFluxPath(sqReportStore, () => sqReportStore.reportSchedule);
  const hasReportSchedule = useFluxPath(sqReportStore, () => sqReportStore.hasReportSchedule);
  const hasDateSchedule = !!dateRange?.auto?.cronSchedule && !_.isEqual(dateRange?.auto?.cronSchedule, [QUARTZ_CRON_PENDING_INPUT]);
  const cronHelper = sqFroalaReportContent.quartzCronExpressionHelper();

  if (!dateRange?.auto?.enabled) return null;

  const effectiveSchedule = hasReportSchedule ? reportSchedule?.cronSchedule : dateRange?.auto?.cronSchedule;
  const rate = cronHelper.cronScheduleToRate(effectiveSchedule);
  const hasRate = !!rate?.value;

  const isLive = (hasReportSchedule && !reportSchedule.background) || (!hasReportSchedule && !dateRange?.auto?.background);
  const isScheduled = (hasReportSchedule && reportSchedule.background) || (!hasReportSchedule && dateRange?.auto?.background);
  const isError = !hasReportSchedule && !hasDateSchedule;

  let icon;
  let classes;
  let testId;

  if (isError) {
    icon = 'fa-calendar-times-o';
    classes = `verticalAlignBottom error-text`;
    testId = 'autoEnabledIconError';
  } else if (isLive) {
    icon = 'fa fc-refresh-on';
    classes = `verticalAlignMiddle`;
    testId = 'autoEnabledIconLive';
  } else {
    icon = 'fa-calendar-check-o';
    classes = `verticalAlignBottom`;
    testId = 'autoEnabledIconScheduled';
  }

  let tooltip;
  if (!includeTooltip) {
    tooltip = '';
  } else if (!hasReportSchedule && !hasDateSchedule) {
    tooltip = 'REPORT.CONFIG.AUTO_UPDATE_NOT_CONFIGURED';
  } else if (isScheduled) {
    tooltip = 'REPORT.CONFIG.AUTO_UPDATE_CONFIGURED';
  } else if (isLive && hasRate) {
    tooltip = t('REPORT.CONFIG.LIVE_RATE', { rate: rate.value + rate.units });
  } else if (isLive && !hasRate) {
    tooltip = t('REPORT.CONFIG.SCHEDULE', { schedule: effectiveSchedule });
  }

  return <HoverTooltip text={tooltip} delay={tooltipDelay}>
    <span>
      <Icon type="inherit"
        icon={icon}
        extraClassNames={classNames(classes, extraClassNames)}
        testId={testId} />
    </span>
  </HoverTooltip>;
};
