/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class FormulasApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * Only admins can clear caches. This may take awhile!
   * @summary Clear all formula caches
   */
  public clearCache(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/formulas/clearCache',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Compile a Seeq Formula
   * @param {string} [formula] - A formula to be applied to the data from the series. The formula should reference the series using the $series variable. Formulas can be used to retrieve an aggregate. Examples: return $series.min(), return $series.sum(). Ignored for numeric (non-time) series.
   * @param {Array<string>} [parameters] - Parameters for the formula. Each parameter should have a format of &#39;name&#x3D;id&#39; where &#39;name&#39; is the variable identifier, without the leading $ sign, and &#39;id&#39; is the ID of the item referenced by the variable.
   */
  public compileFormula(
    {
      formula,
      parameters
    } : {
      formula?: string,
      parameters?: Array<string>
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/formulas/compile',
      params: omitBy({
        ['formula']: formula,
        ['parameters']: parameters
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.FormulaCompileOutputV1>;
  }

  /**
   * Functions have input parameters that are formulas or items, though at least one of the inputs must be 'unbound,' meaning that the function takes a dynamic formula fragment when run. If a function doesn't have an unbound parameter, it's better to use the other api endpoints such as Conditions or Signals.
   * @summary Create a function
   * @param {models.FunctionInputV1} body - Function information
   */
  public createFunction(
    body: models.FunctionInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/formulas/functions',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.CalculatedItemOutputV1>;
  }

  /**
   * Functions have input parameters that are formulas or items, though at least one of the inputs must be 'unbound,' meaning that the function takes a dynamic formula fragment when run. If a function doesn't have an unbound parameter, it's better to use the other api endpoints such as Conditions or Signals.
   * @summary Create a formula item
   * @param {models.FormulaItemInputV1} body - Item information
   */
  public createItem(
    body: models.FormulaItemInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/formulas/items',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.FormulaItemOutputV1>;
  }

  /**
   * 
   * @summary Permanently delete the specified formula doc
   * @param {string} packageName - The name of the package
   * @param {string} docName - The name of the formula doc
   */
  public deleteFormulaDoc(
    {
      packageName,
      docName
    } : {
      packageName: string,
      docName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(packageName)) {
      throw new Error("'packageName' parameter required");
    }

    if (isNil(docName)) {
      throw new Error("'docName' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/formulas/docs/{packageName}/{docName}'
        .replace('{packageName}', encodeURIComponent(String(packageName)))
        .replace('{docName}', encodeURIComponent(String(docName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Permanently delete a specific package
   * @param {string} packageName - The name of the package
   */
  public deletePackage(
    {
      packageName
    } : {
      packageName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(packageName)) {
      throw new Error("'packageName' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/formulas/packages/{packageName}'
        .replace('{packageName}', encodeURIComponent(String(packageName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Get a list of the constants available for use in a calculation
   */
  public getConstantNameList(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/formulas/docs/constants',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<Array<string>>;
  }

  /**
   * 
   * @summary Get documentation details about a specific function group or index
   * @param {string} packageName - The name of the package
   * @param {string} docName - The name of the document for the desired function or index
   */
  public getFormulaDoc(
    {
      packageName,
      docName
    } : {
      packageName: string,
      docName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(packageName)) {
      throw new Error("'packageName' parameter required");
    }

    if (isNil(docName)) {
      throw new Error("'docName' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/formulas/docs/{packageName}/{docName}'
        .replace('{packageName}', encodeURIComponent(String(packageName)))
        .replace('{docName}', encodeURIComponent(String(docName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.FormulaDocOutputV1>;
  }

  /**
   * 
   * @summary Get a list of the functions available for use in a calculation
   */
  public getFormulaDocs(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/formulas/docs',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.FormulaDocSummariesOutputV1>;
  }

  /**
   * Pagination is enabled for this query
   * @summary Get a collection of Formula Functions
   * @param {string} [type=FormulaFunction] - The type of Formula Functions that should be included. Valid options are FormulaFunction, AggregatingFormulaFunction, Chart, and UserDefinedFormulaFunction
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getFormulaFunctions(
    {
      type,
      offset,
      limit
    } : {
      type?: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/formulas/functions',
      params: omitBy({
        ['type']: type,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ItemPreviewListV1>;
  }

  /**
   * 
   * @summary Get a Formula Function
   * @param {string} id - ID of the function
   */
  public getFunction(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/formulas/functions/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.CalculatedItemOutputV1>;
  }

  /**
   * 
   * @summary Get a Formula Item
   * @param {string} id - ID of the item
   */
  public getItem(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/formulas/items/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.FormulaItemOutputV1>;
  }

  /**
   * 
   * @summary Get details of a specific package
   * @param {string} packageName - The name of the package
   */
  public getPackage(
    {
      packageName
    } : {
      packageName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(packageName)) {
      throw new Error("'packageName' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/formulas/packages/{packageName}'
        .replace('{packageName}', encodeURIComponent(String(packageName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.FormulaPackageOutputV1>;
  }

  /**
   * Pagination is enabled for this query
   * @summary Get a collection of packages
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getPackages(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/formulas/packages',
      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ItemPreviewListV1>;
  }

  /**
   * 
   * @summary Create or update a package of UDFs with documentation.
   * @param {models.FormulaPackageImportInputV1} body - Complete package specifications to sync
   */
  public importPackage(
    body: models.FormulaPackageImportInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/formulas/packages/import',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.FormulaPackageImportOutputV1>;
  }

  /**
   * 
   * @summary Create or update the specified formula doc
   * @param {string} packageName - The name of the package
   * @param {string} docName - The name of the document for the desired function or index
   * @param {models.FormulaDocInputV1} body - The details of the formula doc to create or update
   */
  public putFormulaDoc(
    body: models.FormulaDocInputV1,
    {
      packageName,
      docName,
    } : {
      packageName: string,
      docName: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(packageName)) {
      throw new Error("'packageName' parameter required");
    }

    if (isNil(docName)) {
      throw new Error("'docName' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/formulas/docs/{packageName}/{docName}'
        .replace('{packageName}', encodeURIComponent(String(packageName)))
        .replace('{docName}', encodeURIComponent(String(docName))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.FormulaDocOutputV1>;
  }

  /**
   * 
   * @summary Create or update a package
   * @param {string} packageName - The name of the package
   * @param {models.FormulaPackageInputV1} body
   */
  public putPackage(
    body: models.FormulaPackageInputV1,
    {
      packageName,
    } : {
      packageName: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(packageName)) {
      throw new Error("'packageName' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/formulas/packages/{packageName}'
        .replace('{packageName}', encodeURIComponent(String(packageName))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.FormulaPackageOutputV1>;
  }

  /**
   * 
   * @summary Run a Seeq Formula
   * @param {string} [start] - A string representing the starting index of the data to be returned. The contents and whether or not it is required depends on the result type. For time series: a ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm). For numeric (non-time) series: a double-precision number, optionally including units. For example: \&quot;2.5ft\&quot; or \&quot;10 °C\&quot;.
   * @param {string} [end] - A string representing the starting index of the data to be returned. The contents and whether or not it is required depends on the result type. For time series: a ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm). For numeric (non-time) series: a double-precision number, optionally including units. For example: \&quot;2.5ft\&quot; or \&quot;10 °C\&quot;.
   * @param {string} [formula] - The formula to be applied. This or function is required.
   * @param {string} [_function] - The ID of the function item to be computed. This or formula is required.
   * @param {Array<string>} [parameters] - Parameters for the formula. Each parameter should have a format of &#39;name&#x3D;value&#39; where &#39;name&#39; is the variable identifier, without the leading $ sign, and &#39;value&#39; is the ID of an item or one of the following parameter expressions that can be used to access the properties of other items that are parameters: $signal.property(&#39;name&#39;) to access any property on an item, $signal.parentProperty(&#39;name&#39;) can be used if an item is in a tree to to access any property on the parent, and $signal.ancestors(&#39;, &#39;) to return a list of all the ancestors, separated by the specified separator. In all of the above examples &#39;signal&#39; would need to be another parameter that references an item using an ID.
   * @param {Array<string>} [fragments] - Formula fragments for unbound inputs the function. Each parameter should have a format of &#39;name&#x3D;formula&#39; where &#39;name&#39; is the variable identifier, without the leading $ sign, and &#39;formula&#39; is a self-contained formula fragment
   * @param {string} [root] - Used to run a formula across assets, this is the ID of the root asset whose immediate children will be iterated. The formula must produce a table.
   * @param {string} [reduceFormula] - Used when running a formula across assets, this is a formula that can further reduce the results of each asset result. The variable $result must be used to reference the data. Example of sorting the aggregated results: $result.sort(&#39;temperature&#39;)&#39;
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=1000] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public runFormula(
    {
      start,
      end,
      formula,
      _function,
      parameters,
      fragments,
      root,
      reduceFormula,
      offset,
      limit
    } : {
      start?: string,
      end?: string,
      formula?: string,
      _function?: string,
      parameters?: Array<string>,
      fragments?: Array<string>,
      root?: string,
      reduceFormula?: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/formulas/run',
      params: omitBy({
        ['start']: start,
        ['end']: end,
        ['formula']: formula,
        ['function']: _function,
        ['parameters']: parameters,
        ['fragments']: fragments,
        ['root']: root,
        ['reduceFormula']: reduceFormula,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.FormulaRunOutputV1>;
  }

  /**
   * 
   * @summary Run a Seeq Formula.
   * @param {models.FormulaRunInputV1} body - Seeq Formula information
   */
  public runFormula_1(
    body: models.FormulaRunInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/formulas/run',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.FormulaRunOutputV1>;
  }

  /**
   * 
   * @summary Update a function
   * @param {string} id - ID of the function to update
   * @param {models.FunctionInputV1} body - Function information
   */
  public updateFunction(
    body: models.FunctionInputV1,
    {
      id,
    } : {
      id: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/formulas/functions/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.CalculatedItemOutputV1>;
  }

}
