import React, { useState } from 'react';
import classNames from 'classnames';
import { DateTimeService } from '@/datetime/dateTime.service';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { UtilitiesService } from '@/services/utilities.service';
import { TrackService } from '@/track/track.service';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { JournalReplyIcon } from '@/hybrid/annotation/JournalReplyIcon.atom';
import { JournalMetadata } from '@/hybrid/annotation/JournalMetadata.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ContainerWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';
import { Button, Card } from 'react-bootstrap';
import { EllipsisDropdownMenu } from '@/hybrid/core/EllipsisDropdownMenu.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';

const journalCardBindings = bindingsDefinition({
  entry: prop<{
    id: string,
    name: string,
    description: string,
    replies: any[],
    createdBy: any,
    updatedAt: string
  }>(),
  highlightId: prop<string>(),
  delete: prop<(id: string) => void>(),
  display: prop<(id: string) => void>(),
  canManage: prop<(entry: object) => boolean>(),
  sqDateTime: injected<DateTimeService>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqUtilities: injected<UtilitiesService>(),
  sqTrack: injected<TrackService>()
});

export const JournalCard: SeeqComponent<typeof journalCardBindings> = (props) => {
  const {
    sqDateTime,
    sqWorksheetStore,
    sqUtilities,
    sqTrack
  } = useInjectedBindings(journalCardBindings);

  const {
    entry,
    highlightId,
    display,
    canManage,
    delete: deleteEntry
  } = props;

  const { t } = useTranslation();
  const isPresentationMode = sqUtilities.isPresentationWorkbookMode;
  const formatDate = date => sqDateTime.formatTime(date, sqWorksheetStore.timezone);
  const [queryRemove, setQueryRemove] = useState(false);
  const menuClass = canManage(entry) ? 'sq-text-primary' : 'sq-fairly-dark-gray-always';

  const manageMenuItems = [
    {
      classes: classNames({ cursorNotAllowed: !canManage(entry) }),
      onClick: () => canManage(entry) && setQueryRemove(true),
      icon: <Icon testId="specCommentDeleteBtn" icon="fa-fw fa-remove" type="inherit" />,
      label: 'DELETE',
      testid: 'commentDelete'
    }
  ];

  return (
    <Card
      onClick={() => display(entry.id)}
      data-testid="journalCardContainer"
      className={classNames(
        'panel panel-default panel-annotation cursorPointer',
        { 'panel-primary': highlightId === entry.id })}>
      <Card.Body className="pt0 pb0">
        <Card.Title className="flexColumnContainer flexSpaceBetween flexAlignCenter mb2">
          <ContainerWithHTML
            data-testid="specJournalCardTitle"
            content={entry.name}
            isBlock={true}
            className="text-bolder flexFill" />
          {!isPresentationMode &&
          <EllipsisDropdownMenu itemsList={manageMenuItems} iconExtraClass={menuClass} />
          }
        </Card.Title>
        <Card.Text as="div" className="panel-body pt0 pr0 pl0 pb5">
          <ContainerWithHTML
            content={entry.description}
            isBlock={true}
            className="commentName max-height-4em journal-card-overflow" />
        </Card.Text>
        <Card.Text as="div" className="flexColumnContainer flexSpaceBetween pb10">
          <JournalMetadata
            name={entry.createdBy.name}
            date={formatDate(entry.updatedAt)} />
          <JournalReplyIcon count={entry.replies.length} small={true} />
        </Card.Text>
        {queryRemove &&
        <Card.Text as="div" className="text-center pb10">
          <hr className="mt10 mb10" />
          <Button
            type="button"
            size="sm"
            variant={null}
            bsPrefix="sq-btn"
            data-testid="specConfirmDeleteBtn"
            className="btn btn-sm btn-danger specConfirmDeleteBtn"
            onClick={(event) => {
              sqTrack.doTrack('Journal', 'delete', '');
              deleteEntry(entry.id);
              setQueryRemove(false);
              event.stopPropagation();
            }}>
            {t('DELETE')}
          </Button>
          <span
            data-testid="specCancelDeleteBtn"
            className="btn btn-sm btn-link specCancelDeleteBtn"
            onClick={(event) => {
              setQueryRemove(false);
              sqTrack.doTrack('Journal', 'cancel', '');
              event.stopPropagation();
              event.preventDefault();
            }}>
            {t('CANCEL')}
          </span>
        </Card.Text>
        }
      </Card.Body>
    </Card>
  );
};

export const sqJournalCard = angularComponent(journalCardBindings, JournalCard);
