import _ from 'lodash';
import React, { useEffect, useCallback } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { UtilitiesService } from '@/services/utilities.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useResizeWatcher } from '@/hybrid/core/useResizeWatcher.hook';
import { CursorsService } from '@/trendViewer/cursors.service';

const scatterPlotMinimapChartBindings = bindingsDefinition({
  chart: prop<Highcharts.Chart | null>(),
  setChart: prop<(any) => void>(),
  chartElementRef: prop<{ current: any }>(),
  trendStart: prop<number>(),
  trendEnd: prop<number>(),
  xValue: prop<number>(),
  plotSeries: prop<any[]>(),
  regions: prop<any[]>(),
  selectorLow: prop<number>(),
  selectorHigh: prop<number>(),
  useSelectors: prop<boolean>(),
  updateBoxes: prop<() => void>(),
  backgroundColor: prop.optional<string>(),
  sqUtilities: injected<UtilitiesService>(),
  sqCursors: injected<CursorsService>()
});

/**
 * The Highcharts Chart that is the minimap. A line chart with plotBands.
 */
export const ScatterPlotMinimapChart: SeeqComponent<typeof scatterPlotMinimapChartBindings> = (props) => {
  const {
    chart,
    setChart,
    chartElementRef,
    trendStart,
    trendEnd,
    xValue,
    plotSeries,
    regions,
    useSelectors,
    updateBoxes,
    backgroundColor
  } = props;
  const {
    sqUtilities,
    sqCursors
  } = useInjectedBindings(scatterPlotMinimapChartBindings);

  const CURSOR_COLOR = '#6d6f70';

  const updateChart = useCallback((newChartConfig) => {
    if (!chart) { return; }
    const clonedConfig = sqUtilities.cloneDeepOmit(newChartConfig, ['data']);

    // Avoid the expensive re-setting of data if it hasn't changed. Can use simple equality check because data is
    // immutable.
    _.forEach(clonedConfig.series, (series) => {
      const chartSeries = _.find(chart.series as Highcharts.Series[], { options: { id: series.id } });
      if (series.data === _.get(chartSeries, 'userOptions.data')) {
        delete series.data;
      } else if (chartSeries) {
        // We call setData() separately from update() because passing false as the fourth argument to
        // setData() prevents Highcharts from trying to modify the data array we pass in. There's no corresponding
        // option for update().
        // The second arg (redraw) has to be true or errors get thrown sometimes
        //   (https://github.com/highcharts/highcharts/issues/8795)
        chartSeries.setData(series.data, true, false, false);
        delete series.data;
      }
    });

    chart.update(clonedConfig, true, true, false);
  }, [chart]);

  /**
   * Removes the chart.
   */
  const destroyChart = useCallback(() => {
    removeResizeWatcher();

    if (chart && chart.destroy) {
      // Highcharts-React should destroy the chart itself
      setChart(null);
    }

    if (chartElementRef.current) {
      chartElementRef.current.innerHTML = '';
    }
  }, [chart, chartElementRef.current]);

  /**
   * Filter and format plot series so that they are on different y-axis scales and the chart spans the expected range
   */
  const getDisplaySeries = useCallback((series) => {
    const displaySeries = _.chain(series)
      .filter('data')
      .cloneDeep()
      .value();
    for (let i = 0; i < displaySeries.length; i++) {
      displaySeries[i].yAxis = i;
      const seriesData = displaySeries[i].data;
      if (seriesData?.length < 1) {
        continue;
      }
      // Insert null samples if necessary so the chart shows the whole display range
      if (seriesData[0][0] > trendStart) {
        displaySeries[i].data.unshift([trendStart, null]);
      }
      if (seriesData[seriesData.length - 1][0] < trendEnd) {
        displaySeries[i].data.push([trendEnd, null]);
      }
    }

    return displaySeries;

  }, [trendStart, trendEnd]);

  /**
   * Updates the trend lines displayed on the minimap.
   */
  const updateLines = useCallback(() => {
    updateChart({ series: getDisplaySeries(plotSeries) });
  }, [plotSeries]);

  /**
   * Update X position pointer line
   */
  const updatePointer = useCallback(() => {
    if (!chart) { return; }
    if (_.isFinite(xValue)) {
      const xPixel = chart.xAxis[0].toPixels(xValue, true);
      sqCursors.drawHoverCursor(chart, xPixel, xValue, [], 0, false, CURSOR_COLOR);
    } else {
      sqCursors.clearHoverCursor();
    }
  }, [chart, xValue]);

  const reflowChart = useCallback(() => {
    updateLines();
    updateBoxes();
  }, [updateLines, updateBoxes]);

  useEffect(() => {
    updateBoxes();
  }, [regions, useSelectors]);

  useEffect(() => {
    updatePointer();
  }, [xValue, updatePointer]);

  useEffect(() => {
    updateLines();
  }, [plotSeries, updateLines]);

  useEffect(() => {
    if (!chart) { return; }
    chart.update({ chart: { backgroundColor } }, true);
  }, [chart, backgroundColor]);

  // Once we have the chart, create the cleanup
  useEffect(() => {
    if (!chart) { return; }
    // cleanup
    return () => destroyChart();
  }, [chart, destroyChart]);

  const getInitialChartConfig = useCallback(() => ({
    chart: {
      height: 35,
      borderWidth: 2,
      spacing: [0, 0, 0, 0],
      borderColor: '#7BAAAF',
      backgroundColor: backgroundColor ?? '#ffffff',
      alignTicks: false,
      animation: false,
      ignoreHiddenSeries: false,
      zoomType: 'none',
      boost: {
        enabled: false
      },
      resetZoomButton: {
        theme: {
          display: 'none'
        }
      },
      events: {}
    },
    legend: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        type: 'line',
        lineWidth: 1,
        turboThreshold: 0,
        animation: false,
        marker: { enabled: false },
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            enabled: false
          }
        },
        borderWidth: 0
      },
      line: {
        marker: {
          state: {
            hover: { enabled: false }
          }
        }
      }
    },
    credits: { enabled: false },
    title: { text: null },
    xAxis: {
      ordinal: false,
      type: 'datetime',
      maxPadding: 0,
      minPadding: 0,
      visible: false
    },
    // We need a separate y-axis for each of the signals
    yAxis: [{
      title: {
        enabled: true,
        text: ''
      },
      gridLineWidth: 0,
      maxPadding: 0,
      minPadding: 0,
      visible: false
    }, {
      title: {
        enabled: true,
        text: ''
      },
      gridLineWidth: 0,
      maxPadding: 0,
      minPadding: 0,
      visible: false
    }],
    series: getDisplaySeries(plotSeries)
  }), [getDisplaySeries, plotSeries]);

  const removeResizeWatcher = useResizeWatcher({
    element: chartElementRef.current,
    callback: reflowChart,
    callOnLoad: true
  });

  /**
   * Callback method to be passed into the HighchartsReact object so we have access to the Highcharts Chart
   *
   * @param highchartsChart - the chart object from Highcharts
   */
  const afterChartCreated = (highchartsChart: Highcharts.Chart) => {
    setChart(highchartsChart);

    updateBoxes();
    updateLines();
  };

  return <div className="minimap" ref={chartElementRef} data-testid="minimapChart">
    <HighchartsReact
      highcharts={Highcharts}
      options={getInitialChartConfig()}
      // Don't let the chart auto-update because we have to handle updates in a custom way (see updateChart())
      allowChartUpdate={false}
      callback={afterChartCreated}
      containerProps={{ className: 'flexFill' }}
    />
  </div>;
};
