
import React from 'react';
import { useForceUpdate } from '@/hybrid/core/useForceUpdate.hook';
import { Translator } from '@/hybrid/core/useTranslation.hook';

/**
 * Builds a Translator that uses $translate to resolve translations. In a post-angularjs world react-i18next could
 * be used for this instead.
 */
export const useTranslatorAngular = ($translate: ng.translate.ITranslateService, $rootScope: ng.IRootScopeService) => {
  const forceUpdate = useForceUpdate();
  React.useEffect(() => {
    // Use https://github.com/angular-translate/angular-translate/blob/master/docs/content/guide/en/18_events.ngdoc
    // to keep the translations dynamic
    return $rootScope.$on('$translateChangeSuccess', () => {
      forceUpdate();
    });
  }, [$translate, $rootScope]);
  // To reduce renders we want the object identity of our return value to change
  // when $translateChangeSuccess is raised but otherwise remain constant
  // The trick here is that bind will return a reference to a new function which will
  // force an update of the context in all components that are using it. Force it
  // to update on demand by passing forceUpdate.reference which will change between
  // each forced update. forceUpdate.reference can be thought of as a stand in for
  // all the state inside of $translate that changes when translations change. We
  // don't have a reference to all that internal state so we have to represent it
  // indirectly so that we have something to pass into `useMemo`
  return React.useMemo(() => $translate.instant.bind($translate), [$translate, forceUpdate.reference]) as Translator;
};
