import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { ReportStore } from '@/reportEditor/report.store';
import { ReportActions } from '@/reportEditor/report.actions';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import React, { useState, useEffect } from 'react';
import { WorkbookActions } from '@/workbook/workbook.actions';
import { ItemsApi } from '@/sdk';
import { APP_STATE } from '@/main/app.constants';
import { NotificationsService } from '@/services/notifications.service';
import { WorkbookStore } from '@/workbook/workbook.store';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { TrackService } from '@/track/track.service';
import { TEMP_TOPIC_NAME } from '@/reportEditor/report.module';
import { SeeqNames } from '@/main/app.constants.seeqnames';

const sandboxModeBannerBindings = bindingsDefinition({
  sqReportStore: injected<ReportStore>(),
  sqWorkbookActions: injected<WorkbookActions>(),
  sqWorkbookStore: injected<WorkbookStore>(),
  sqReportActions: injected<ReportActions>(),
  sqTrack: injected<TrackService>(),
  sqItemsApi: injected<ItemsApi>(),
  sqNotifications: injected<NotificationsService>(),
  $state: injected<ng.ui.IStateService>()
});

export const SandboxModeBanner: SeeqComponent<typeof sandboxModeBannerBindings> = () => {
  const {
    sqReportStore,
    sqWorkbookActions,
    sqWorkbookStore,
    sqReportActions,
    sqTrack,
    sqNotifications,
    sqItemsApi,
    $state
  } = useInjectedBindings(sandboxModeBannerBindings);

  const { t } = useTranslation();
  const sandboxMode = useFluxPath(sqReportStore, () => sqReportStore.sandboxMode);

  const [originalWorksheetName, setOriginalWorksheetName] = useState(null);
  const [saveTooltip, setSaveTooltip] = useState(null);
  useEffect(() => {
    const getSaveTooltip = () => {
      return t('SANDBOX_MODE.SAVE_COPY_TOOLTIP', {
        workbook: buildNewWorkbookName()
      });
    };

    const loadOldWorksheetName = () => {
      return sqItemsApi.getProperty({ id: sandboxMode.originalWorksheetId, propertyName: 'Name' })
        .then(results => setOriginalWorksheetName(results.data.value));
    };

    if (sandboxMode.enabled) {
      loadOldWorksheetName()
        .then(() => setSaveTooltip(getSaveTooltip()));
    }
  });

  const buildNewWorkbookName = () => {
    const oldWorkbookName = sqWorkbookStore.name;
    return t('SANDBOX_MODE.NEW_WORKBOOK_NAME', { workbook: oldWorkbookName });
  };

  const buildNewWorksheetName = () => {
    return t('SANDBOX_MODE.NEW_WORKSHEET_NAME', { worksheet: originalWorksheetName });
  };

  const onClickSave = () => {
    const newWorkbookName = buildNewWorkbookName();
    sqItemsApi.setProperty({ value: false },
      { id: sandboxMode.sandboxedWorkbookId, propertyName: SeeqNames.Properties.Archived })
      .then(() => sqWorkbookActions.setName(sandboxMode.sandboxedWorkbookId, newWorkbookName, TEMP_TOPIC_NAME))
      .then(() => $state.go(APP_STATE.WORKSHEET,
        {
          workbookId: sandboxMode.sandboxedWorkbookId,
          worksheetId: sandboxMode.sandboxedWorksheetId
        })
        .then(() => sqWorkbookActions.renameWorksheet(sandboxMode.sandboxedWorksheetId, buildNewWorksheetName()))
        .then(() => {
          return sqNotifications.success(t('SANDBOX_MODE.SUCCESS_MESSAGE', {
            newWorkbook: newWorkbookName
          }));
        }));
    sqTrack.doTrack('Sandbox Mode', 'Save a Copy');
  };

  return (<>
    {sandboxMode.enabled &&
    <div className="flexColumnContainer flexCenter">
      <HoverTooltip text="SANDBOX_MODE.REVERT_TOOLTIP" placement="bottom">
          <span className="p3">
            <Icon icon="fc-eraser" onClick={sqReportActions.exitSandboxMode} type="theme-light" large={true}
              testId="sandboxExit" />
          </span>
      </HoverTooltip>
      <HoverTooltip text={saveTooltip} placement="bottom">
        <span className="p3">
          <Icon icon="fa-copy" onClick={onClickSave} type="theme-light" large={true} testId="sandboxSave" />
        </span>
      </HoverTooltip>
    </div>}
  </>);
};
