import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { CELL_TYPES } from '@/hybrid/core/Table.atom';
import { AdministrationActions } from '@/administration/administration.actions';
import { ButtonWithManagedSpinner } from '@/hybrid/core/ButtonWithManagedSpinner.atom';
import { AdminTableWrapper } from '@/hybrid/core/AdminTableWrapper.molecule';
import { ExportPreviewListV1, ExportPreviewV1 } from '@/sdk';
import { Icon } from '@/hybrid/core/Icon.atom';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';

const exportsTabBindings = bindingsDefinition({
  sqAdministrationActions: injected<AdministrationActions>(),
  $window: injected<ng.IWindowService>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>()
});

export const ExportsTab: SeeqComponent<typeof exportsTabBindings> = () => {
  const { sqAdministrationActions, $window, sqHomeScreenUtilities } = useInjectedBindings(exportsTabBindings);
  const { t } = useTranslation();

  const [exports, setExports] = useState<ExportPreviewV1[]>([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    refreshExports();
  }, []);

  const openScopedWorkbook = (exportItem) => {
    const workbookId = exportItem?.scopedTo;
    sqHomeScreenUtilities.getWorkbook(workbookId).then((workbook) => {
      const worksheetId = workbook?.worksheets[0]?.worksheetId;
      $window.open(`/workbook/${workbookId}/worksheet/${worksheetId}`);
    });
  };

  const renderWorkbookLinkCell = (exportItem) => {
    const isGloballyScoped = _.isNil(exportItem?.scopedTo);

    return isGloballyScoped
      ? <span>{t('ADMIN.EXPORT.UNKNOWN_ANALYSIS')}</span>
      : <a onClick={() => openScopedWorkbook(exportItem)}>
        <Icon icon="fc-analysis" tooltip="ADMIN.EXPORT.OPEN_ANALYSIS" type="text" testId="openAnalysis" />
      </a>;
  };

  const refreshExports = () => sqAdministrationActions.refreshExports()
    .then((data: ExportPreviewListV1) => setExports(data.items))
    .finally(() => setSelectedItems([]));

  const archiveExports = () => sqAdministrationActions.archiveExports(_.map(selectedItems, 'id'))
    .then(refreshExports)
    .finally(() => setSelectedItems([]));

  const columns = [{
    accessor: 'id',
    sortable: false,
    filterable: false,
    cellType: CELL_TYPES.ROW_SELECTION,
    cellStyle: { width: 40, minWidth: 40, maxWidth: 40 }
  }, {
    accessor: 'name',
    searchProperty: 'name',
    header: 'ADMIN.EXPORT.NAME',
    sortable: true,
    filterable: true
  }, {
    accessor: 'createdAt',
    searchProperty: 'createdAt',
    header: 'ADMIN.EXPORT.CREATED_AT',
    sortable: true,
    filterable: false,
    cellType: CELL_TYPES.DATE_TIME
  }, {
    accessor: 'updatedAt',
    searchProperty: 'updatedAt',
    header: 'ADMIN.EXPORT.LAST_USED_AT',
    sortable: true,
    filterable: false,
    cellType: CELL_TYPES.DATE_TIME
  }, {
    accessor: 'autoupdateTimeRange',
    searchProperty: 'autoupdateTimeRange',
    header: 'ADMIN.EXPORT.AUTO_UPDATE',
    cellType: CELL_TYPES.CHECKMARK,
    sortable: true,
    filterable: true
  }, {
    accessor: 'scopedTo',
    searchProperty: 'scopedTo',
    header: 'ADMIN.EXPORT.ANALYSIS',
    sortable: true,
    filterable: false,
    cellRenderFunction: renderWorkbookLinkCell
  }];

  return (
    <div className="height-maximum">
      <div className="flexColumnContainer flexSpaceBetween flexAlignCenter mb5">
        <ButtonWithManagedSpinner
          buttonProps={{ id: 'trashSelectedExports', disabled: _.isEmpty(selectedItems) }}
          spinnerIconProps={{ type: 'text' }}
          label="ADMIN.EXPORT.TRASH_SELECTED"
          action={archiveExports} />

        <ButtonWithManagedSpinner
          buttonProps={{ id: 'refreshExports' }}
          spinnerIconProps={{ type: 'text' }}
          label="ADMIN.EXPORT.REFRESH_LIST"
          action={refreshExports}
          icon="fa-repeat" />
      </div>

      <div className="overflowAuto width-maximum height-maximum pb70">
        <AdminTableWrapper
          testId="exportsAdministrationTable"
          defaultSort={{ property: 'name', asc: true }}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          items={exports}
          columns={columns} />
      </div>
    </div>
  );
};
