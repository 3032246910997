import React from 'react';
import classNames from 'classnames';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Button } from 'react-bootstrap';
import { Icon } from '@/hybrid/core/Icon.atom';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Placement } from 'react-bootstrap/Overlay';

const toolbarButtonBindings = bindingsDefinition({
  /** function to call when clicking the button (takes no parameters) */
  onClick: prop.optional<() => any>(),
  /** icon class to be used for the button (i.e. 'fc-zoom') */
  icon: prop<string>(),
  /** label for the toolbar item (shown only when toolbar is wide enough) */
  label: prop<string>(),
  /** true to give the button the "pressed" look */
  active: prop.optional<boolean>(),
  /** true to render an icon-only dropdown button */
  isSmall: prop.optional<boolean>(),
  /** true to render button as disabled */
  disabled: prop.optional<boolean>(),
  /** extra class names to be placed on the Button component */
  extraClassNames: prop.optional<string>(),
  /** an id that can be placed on the Button component */
  id: prop.optional<string>(),
  testId: prop.optional<string>(),
  tooltipText: prop.optional<string>(),
  tooltipPlacement: prop.optional<Placement>()
});

/**
 * Toolbar button for single click action (e.g. trend toolbar zoom)
 */
export const ToolbarButton: SeeqComponent<typeof toolbarButtonBindings> = (props) => {
  const {
    onClick,
    label,
    active,
    disabled,
    extraClassNames,
    icon,
    isSmall = false,
    id,
    testId,
    tooltipText,
    tooltipPlacement = 'bottom'
  } = props;
  const { t } = useTranslation();

  return (
    <HoverTooltip text={tooltipText} placement={tooltipPlacement}>
      <div className={classNames({ buttonDisabledTooltip: !!disabled })}>
        <Button
          className={classNames(extraClassNames, 'flexRowContainer', 'flexAlignCenter', 'sq-btn-toolbar', 'btn',
            { 'sq-btn-toolbar-sm flexJustifyCenter': isSmall })}
          onClick={onClick}
          disabled={disabled}
          active={active}
          type="button"
          size="sm"
          variant={null}
          bsPrefix="sq-btn"
          id={id}
          data-testid={testId}>
          <Icon icon={icon} type="text" />
          {!isSmall && <small className="mt2">{t(label)}</small>}
        </Button>
      </div>
    </HoverTooltip>
  );
};
