import angular, { IPromise } from 'angular';
import _ from 'lodash';
import jQuery from 'jquery';
import { AnnotationInputV1, AnnotationOutputV1, AnnotationsApi, ItemsApi } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { NotificationsService } from '@/services/notifications.service';
import { CONTENT_MODEL_ATTRIBUTES } from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import { ReportStore } from '@/reportEditor/report.store';

angular.module('Sq.Services.Utilities').service('sqMigration', sqMigration);

export type MigrationService = ReturnType<typeof sqMigration>;

function sqMigration(
  sqAnnotationsApi: AnnotationsApi,
  sqItemsApi: ItemsApi,
  sqNotifications: NotificationsService,
  sqReportStore: ReportStore,
  $translate: ng.translate.ITranslateService
) {
  const service = {
    toggleEditor,

    // exposed for testing
    migrateDocumentFromFroalaToCK
  };

  return service;

  function annotationInputFromOutput(output: AnnotationOutputV1, type: string): AnnotationInputV1 {
    return {
      description: output.description,
      interests: _.map(output.interests, interest => ({ interestId: interest.item.id })),
      name: output.name,
      type,
      reportInput: type === 'Report' ? {
        cronSchedule: sqReportStore.reportSchedule?.cronSchedule,
        background: !!sqReportStore.reportSchedule?.background,
        enabled: !!sqReportStore.reportSchedule?.enabled
      } : undefined
    };
  }

  /**
   * Toggles the editor to the other version, saving or loading backups as needed so that when the page is refreshed
   * it is a good state.
   * @param annotationId - The id of the annotation being migrated
   * @return The migrated document HTML
   */
  function toggleEditor(annotationId: string): IPromise<string> {
    const handleError = error => sqNotifications.error(
      $translate.instant('REPORT.EDITOR.MIGRATION.FAILED') + error.data.statusMessage);

    return Promise.all(
        // We need to get the item properties to get the type to provide some additional metadata data to the
        // annotation input object
        [sqAnnotationsApi.getAnnotation({ id: annotationId }), sqItemsApi.getItemAndAllProperties({ id: annotationId })])
      .then(([{ data }, { data: { type } }]) => {
        if (data.ckEnabled) {
          return sqItemsApi.setProperty({ value: false },
              { id: annotationId, propertyName: SeeqNames.Properties.IsCkEnabled })
            .then(() => sqItemsApi.getProperty({ id: annotationId, propertyName: SeeqNames.Properties.FroalaBackup }))
            .catch((error) => {
              // If the document was empty on conversion we don't want to produce an error
              if (error.status === 404 && _.includes(error.data.statusMessage, SeeqNames.Properties.FroalaBackup)) {
                return Promise.resolve({ data: { value: undefined } });
              }
              return Promise.reject(error);
            })
            .then(({ data: { value } }) => sqAnnotationsApi.updateAnnotation(
                { ...annotationInputFromOutput(data, type), document: value ?? '' },
                { id: annotationId })
              .then(() => value ?? ''))
            .catch(handleError);
        } else {
          return (data.document
            ? sqItemsApi.setProperty({ value: data.document },
              { id: annotationId, propertyName: SeeqNames.Properties.FroalaBackup })
            : Promise.resolve() as any)
            .then(() => migrateDocumentFromFroalaToCK(data.document))
            .then(migratedDocument => (migratedDocument
              ? sqAnnotationsApi.updateAnnotation(
                { ...annotationInputFromOutput(data, type), document: migratedDocument },
                { id: annotationId })
              : Promise.resolve() as any)
              .then(() => sqItemsApi.setProperty({ value: true },
                { id: annotationId, propertyName: SeeqNames.Properties.IsCkEnabled }))
              .then(() => migratedDocument))
            .catch(handleError);
        }
      });
  }

  /**
   * Migrates a document from Froala to CK.
   * TODO: CRAB-23908 Will actually add the migration
   *
   * @param document - The Froala document to migrate
   */
  function migrateDocumentFromFroalaToCK(document: string): string {
    if (!document) {
      return undefined;
    }

    // Adding a wrapper div makes getting the actual html easier at the end
    const jqueryDocument: any = jQuery(jQuery.parseHTML(`<div>${document}</div>`));

    // Convert Froala page breaks to CK page breaks
    jqueryDocument.find('hr.fr-page-break')
      .replaceWith(
        '<div class="page-break" style="page-break-after:always;"><span style="display:none;">&nbsp;</span></div>');

    // Add inner code element to pre tags to let CK parse it correctly
    jqueryDocument.find('pre')
      .contents()
      .wrap('<code class="language-plaintext"></code>');

    //region table styles
    // The below styles were found by creating an equivalent table in CK and copying the styles it used
    // We don't have to remove the froala specific classes because CK will remove any class it doesn't have a
    // converter for.

    const dashedBorders = '1px dashed #DDD';
    const dashedBordersStyles = {
      'border-bottom': dashedBorders,
      'border-left': dashedBorders,
      'border-right': dashedBorders,
      'border-top': dashedBorders
    };

    const noBorders = '1px solid hsl(0, 0%, 100%)';
    const noBordersStyles = {
      'border-bottom': noBorders,
      'border-left': noBorders,
      'border-right': noBorders,
      'border-top': noBorders
    };

    jqueryDocument.find('table').wrap('<figure class="table"></figure>');

    // In Froala, dashed and no borders could be on at the same time, but it would create a weird super thick dashed
    // border. Here, we just pick one to win out based on ordering.

    // No Borders
    const noBorderTable = jqueryDocument.find('table.table-no-border').css(noBordersStyles);
    noBorderTable.find('td').css(noBordersStyles);

    // Dashed Borders
    const dashedTable = jqueryDocument.find('table.fr-dashed-borders');
    dashedTable.css(dashedBordersStyles);
    dashedTable.find('td').css(dashedBordersStyles);

    // Highlighted Cells
    jqueryDocument.find('td.fr-highlighted').css('border', '1px double red');

    // Thick Cells
    jqueryDocument.find('td.fr-thick').css('border-width', '2px');

    // Striping
    jqueryDocument.find('table.fr-alternate-rows').parent().addClass('seeqTableStriped');

    // Certain ways of adding alignment and other stylings add extra divs to tables. This copies any relevant styles
    // to the parent td of the div and removes the div
    jqueryDocument.find('td > div')
      .each((index, div) => {
        const divStyle = div.getAttribute('style') ?? '';
        const parentStyle = div.parentElement.getAttribute('style') ?? '';
        div.parentElement.setAttribute('style', `${divStyle} ${parentStyle}`);
      });

    // Removes extraneous divs that add extra spacing in CK
    jqueryDocument.find('td > div')
      .contents()
      .unwrap();

    // Froala cell colors propagated to children that didn't have a color assigned. In CK each cell requires
    // specific colors. jQuery ordering is consistent, so we can go in reverse to apply the styles from the
    // innermost styled td first
    const backgroundColorKey = 'background-color';
    jQuery(jqueryDocument.find(`td[style*="${backgroundColorKey}"]`).get().reverse()).each((index, td) => {
      const backgroundColor = td.style[backgroundColorKey];
      jQuery(td).find('td').each((index, innerTd) => {
        if (!innerTd.style[backgroundColorKey]) {
          innerTd.style[backgroundColorKey] = backgroundColor;
        }
      });
    });

    //endregion

    //region content migration

    // Remove surrounding a tags
    jqueryDocument.find(`a [${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`)
      .parent()
      .contents()
      .unwrap();

    // Migrate border attribute
    jqueryDocument.find(`[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}].report-image-border`)
      .attr(CONTENT_MODEL_ATTRIBUTES.BORDER, true);

    // Migrate no margin
    jqueryDocument.find(`[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}].report-no-margin`)
      .attr(CONTENT_MODEL_ATTRIBUTES.NO_MARGIN, true);

    // height, width, and width percent
    jqueryDocument.find(`[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`)
      .each((index, content) => {
        if (!_.isEmpty(content.style['max-width'])) {
          content.setAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH, parseFloat(content.style['max-width']));
        }
        if (!_.isEmpty(content.style['max-height'])) {
          content.setAttribute(CONTENT_MODEL_ATTRIBUTES.HEIGHT, parseFloat(content.style['max-height']));
        }
        if (!_.isEmpty(content.style['width'])) {
          content.setAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT, content.style['width']);
        }
      });

    //endregion

    return jqueryDocument.get(0).innerHTML;
  }
}
