import React from 'react';
import _ from 'lodash';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useState } from 'react';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { WorksheetThumbnail } from '@/hybrid/worksheets/WorksheetThumbnail.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { WorkbookActions } from '@/workbook/workbook.actions';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { AuthorizationService } from '@/services/authorization.service';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { UtilitiesService } from '@/services/utilities.service';
import { WorksheetsService } from '@/worksheets/worksheets.service';
import { APP_STATE } from '@/main/app.constants';
import { ButtonWithDropdown } from '@/hybrid/core/ButtonWithDropdown.atom';
import { Icon } from '@/hybrid/core/Icon.atom';

const worksheetDropdownBindings = bindingsDefinition({
  sqAuthorization: injected<AuthorizationService>(),
  sqWorkbookActions: injected<WorkbookActions>(),
  sqWorkbenchActions: injected<WorkbenchActions>(),
  sqPendingRequests: injected<PendingRequestsService>(),
  sqUtilities: injected<UtilitiesService>(),
  sqWorksheets: injected<WorksheetsService>(),
  workbook: prop<any>(),
  setIsOpen: prop<(isOpen: boolean) => void>(),
  $state: injected<ng.ui.IStateService>()
});

export const WorksheetDropdown: SeeqComponent<typeof worksheetDropdownBindings> = (props) => {
  const { workbook, setIsOpen } = props;
  const {
    sqWorkbenchActions,
    sqWorkbookActions,
    sqPendingRequests,
    sqAuthorization,
    sqUtilities,
    $state,
    sqWorksheets
  } = useInjectedBindings(worksheetDropdownBindings);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [worksheets, setWorksheets] = useState([]);

  const handleIconClick = (isOpen) => {
    setIsOpen(isOpen);
    if (isOpen) loadWorksheets();
  };

  const loadWorksheets = () => {
    setIsLoading(true);
    sqWorksheets.getWorksheets(workbook.workbookId)
      .then((worksheets) => {
        const formattedWorksheets = _.map(worksheets, (worksheet: any) =>
          _.assign({}, worksheet, { updatedAt: sqWorkbookActions.formatDate(worksheet.updatedAt) }));
        setWorksheets(formattedWorksheets);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const gotoWorksheet = (worksheetId, e) => {
    setIsOpen(false);
    sqPendingRequests.cancelAll();
    sqWorkbenchActions.setOpeningAndLoadingItemId(null);
    $state.goNewTab(sqAuthorization.canModifyWorkbook(workbook) ? APP_STATE.WORKSHEET : APP_STATE.VIEW_WORKSHEET,
      { workbookId: workbook.workbookId, worksheetId, currentFolderId: $state.params.currentFolderId }, e);
  };

  return (
    <div className="mr10">
      <ButtonWithDropdown
        icon={<Icon icon="fa-info-circle" extraClassNames="fa-xlg fa-fw folderAction" />}
        iconWrapperClasses="pt10 pb10 pr8 pl6 sq-icon-hover"
        testId="worksheetDropdownButton"
        tooltipDelay={500}
        tooltipText="HOME_SCREEN.WORKSHEET_POPOVER.TOOLTIP"
        onToggle={handleIconClick}
        id={`itemInfo-${workbook.workbookId}`}>
        <div className="forceFont p10 max-height-250 overflowYAuto overflowXHidden min-width-500 width-500"
          data-testid="worksheetDropdown">
          <div className="text-bolder sq-text-primary pb5 aggressiveWordBreak">
            {sqUtilities.truncate(_.get(workbook, 'name'), 80, 35)}
          </div>
          <div className="mb10">
            <div className="simple-word-break">{_.get(workbook, 'description') ? workbook.description :
              <span className="text-italic">{t('WORKBOOK_NO_DESCRIPTION')}</span>}
            </div>
          </div>
          {isLoading &&
          <div className="text-center mt10">
            <div>{t('HOME_SCREEN.WORKSHEET_POPOVER.LOADING')}</div>
            <IconWithSpinner spinning={true} />
          </div>}
          {!isLoading &&
          <div className="flexColumnContainer flexWrap flexJustifyCenter flexAlignStart flexGrowShrink">
            {_.map(worksheets, (worksheet: any) => (
              <div id={worksheet.worksheetId} key={worksheet.worksheetId} data-testid="worksheetInfo"
                className="flexNoGrowNoShrink worksheetContainer homeScreenWorksheetContainer col-sm-4 cursorPointer text-center mb10 p10 min-width-130 max-width-130 mr10"
                onClick={e => gotoWorksheet(worksheet.worksheetId, e)}>
                <WorksheetThumbnail workbookId={workbook.workbookId}
                  worksheetId={worksheet.worksheetId}
                  isActive={false}
                  updatedAt={worksheet.updatedAt} />
                <div className="mt5">
                  <HoverTooltip placement="top" text={worksheet.name}>
                    <div className="text-bolder sq-text-info">
                      {sqUtilities.truncate(worksheet.name, 16, 6)}
                    </div>
                  </HoverTooltip>
                  <div className="sq-text-info font-size-smaller">{worksheet.updatedAt}</div>
                </div>
              </div>
            ))}
          </div>}
        </div>
      </ButtonWithDropdown>
    </div>
  );
};
