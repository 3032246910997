import _ from 'lodash';
import angular from 'angular';
import { BaseToolStoreService } from '@/investigate/baseToolStore.service';
import {
  PROFILE_SEARCH_NORMALIZE_AMPLITUDE,
  PROFILE_SEARCH_NORMALIZE_LOCATION,
  PROFILE_SEARCH_SIMILARITY
} from '@/trendData/trendData.module';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { INITIALIZE_MODE } from '@/services/stateSynchronizer.service';

angular.module('Sq.TrendData').store('sqProfileSearchStore', sqProfileSearchStore);

export type ProfileSearchStore = ReturnType<typeof sqProfileSearchStore>['exports'];

function sqProfileSearchStore(sqBaseToolStore: BaseToolStoreService) {
  const store = {
    initialize(initializeMode) {
      const saveState = this.state && initializeMode !== INITIALIZE_MODE.FORCE;
      this.state = this.immutable(_.assign({}, sqBaseToolStore.COMMON_PROPS, {
        similarity: PROFILE_SEARCH_SIMILARITY,
        normalizeAmplitude: PROFILE_SEARCH_NORMALIZE_AMPLITUDE,
        normalizeLocation: PROFILE_SEARCH_NORMALIZE_LOCATION,
        selectedRegion: saveState ? this.state.get('selectedRegion') : {}
      }));
    },

    exports: {
      get selectedRegion() {
        return this.state.get('selectedRegion');
      },

      get similarity() {
        return this.state.get('similarity');
      },

      get normalizeAmplitude() {
        return this.state.get('normalizeAmplitude');
      },

      get normalizeLocation() {
        return this.state.get('normalizeLocation');
      }
    },

    /**
     * Exports the state that is not ephemeral (such as progress indicators).
     * @returns {Object} The dehydrated state.
     */
    dehydrate() {
      return this.state.serialize();
    },

    /**
     * Re-creates the profile search results.
     *
     * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      PROFILE_SEARCH_SET_SIMILARITY: 'setSimilarity',
      PROFILE_SEARCH_SET_NORMALIZE_AMPLITUDE: 'setNormalizeAmplitude',
      PROFILE_SEARCH_SET_NORMALIZE_LOCATION: 'setNormalizeLocation',
      TREND_SET_SELECTED_REGION: 'setSelectedRegion'
    },

    /**
     * Sets the similarity for the profile search.
     * @param {Object} payload - Object containing state information
     * @param {String} payload.similarity - the similarity for the profile search.
     */
    setSimilarity(payload) {
      this.state.set('similarity', payload.similarity);
    },

    /**
     * Ignore amplitude of candidates in the profile search.
     * @param {Object} payload - Object containing state information
     * @param {Boolean} payload.normalizeAmplitude - normalize amplitude for profile search.
     */
    setNormalizeAmplitude(payload) {
      this.state.set('normalizeAmplitude', payload.normalizeAmplitude);
    },

    /**
     * Ignore location of candidates in the profile search.
     * @param {Object} payload - Object containing state information
     * @param {Boolean} payload.normalizeLocation - normalize location for profile search.
     */
    setNormalizeLocation(payload) {
      this.state.set('normalizeLocation', payload.normalizeLocation);
    },

    /**
     * Sets the selected region
     * @param {Object} payload - Object containing state information
     * @param {Number} payload.min - Lower x-value of selected region.
     * @param {Number} payload.max - Upper x-value of selected region.
     */
    setSelectedRegion(payload) {
      this.state.set('selectedRegion', payload);
    }
  };

  return sqBaseToolStore.extend(store, TREND_TOOLS.PROFILE_SEARCH,
    { inputSignal: { predicate: ['name', 'a'] } });
}
