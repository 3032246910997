import _ from 'lodash';
import angular from 'angular';
import { YAxisActions } from '@/trendData/yAxis.actions';
import { TrendStore } from '@/trendData/trend.store';
import { BaseItemStoreService } from '@/trendData/baseItemStore.service';
import { DASH_STYLES, ITEM_DATA_STATUS, ITEM_TYPES, Y_AXIS_TYPES } from '@/trendData/trendData.module';
import { BEGINNING_OF_TIME, END_OF_TIME } from '@/datetime/datetime.module';

export const NON_NUMERICAL_SCALAR_CHART_VALUE = 1;

/**
 * A store for containing scalars which can be displayed on the chart. A scalar is composed of a single y-axis value.
 *
 * This store is augmented with additional functionality from sqBaseSignalStore.
 */
angular.module('Sq.TrendData')
  .store('sqTrendScalarStore', sqTrendScalarStore);

export type TrendScalarStore = ReturnType<typeof sqTrendScalarStore>['exports'];

function sqTrendScalarStore($injector: ng.auto.IInjectorService, sqBaseItemStore: BaseItemStoreService) {

  const store = {
    exports: {},

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @returns {Object} The dehydrated items.
     */
    dehydrate() {
      return {
        items: _.chain(this.state.get('items'))
          .filter(sqBaseItemStore.shouldDehydrateItem)
          .map(item => sqBaseItemStore.pruneDehydratedItemProps(item))
          .value()
      };
    },

    /**
     * Re-creates the scalars. All necessary data needed to rehydrate is persisted so no actions have to be called.
     *
     * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
     * @return {Object} A promise that is fulfilled when items are completely rehydrated.
     */
    rehydrate(dehydratedState) {
      this.state.set('items', _.map(dehydratedState.items,
        item => this.createScalar(item.id, item.name, item.lane, item.alignment,
          _.omit(item, ['id', 'name', 'lane', 'alignment']))
      ));
    },

    handlers: {
      TREND_ADD_SCALAR: 'addScalar',
      TREND_SCALAR_RESULTS_SUCCESS: 'addValue',
      TREND_TOGGLE_CAPSULE_ALIGNMENT: 'setVisiblity'
    },

    /**
     * Adds a scalar item.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.id - ID of the new item
     * @param {String} payload.name - Name of the new item
     * @param {String} payload.lane - Lane of the new item
     * @param {String} payload.alignment - Alignment of the new item
     * @param {String} [payload.color] - Color hex code (e.g. #CCCCCC)
     */
    addScalar(payload) {
      this.state.push('items', this.createScalar(payload.id, payload.name, payload.lane, payload.alignment,
        _.pick(payload, ['color'])));
    },

    /**
     * Adds additional data to an item.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.id - ID of the item
     * @param {Number} payload.value - Value of the scalar
     * @param {Number} payload.lower - the lower bound if this scalar has a shadedArea
     * @param {Number} payload.upper - the upper bound if this scalar has a shadedArea
     * @param {Number} payload.warningCount - the count of warnings that will be passed to the base item store
     * @param {Object[]} payload.warningLogs - the log of warnings that will be passed to the base item store
     */
    addValue(payload) {
      const isStringSeries = _.isString(payload.value) || _.isBoolean(payload.value);
      const cursor = this.getItemCursor(payload.id);
      if (cursor.exists()) {
        this.setDataStatusPresent(
          _.pick(payload, ['id', 'warningCount', 'warningLogs', 'timingInformation', 'meterInformation']));

        if (isStringSeries || _.isFinite(payload.value)) {
          const value = isStringSeries ? NON_NUMERICAL_SCALAR_CHART_VALUE : payload.value;
          cursor.merge({
            pointValue: payload.value,
            isStringSeries,
            data: [[BEGINNING_OF_TIME.valueOf(), value], [END_OF_TIME.valueOf(), value]]
          });
        } else if (_.isFinite(payload.lower) && _.isFinite(payload.upper) && payload.lower < payload.upper) {
          cursor.merge({
            pointValueLower: payload.lower,
            pointValueUpper: payload.upper,
            isStringSeries: false,
            data: [[BEGINNING_OF_TIME.valueOf(), payload.lower, payload.upper], [END_OF_TIME.valueOf(), payload.lower, payload.upper]]
          });
        } else {
          cursor.merge({
            isStringSeries: false,
            data: []
          });
        }

        cursor.merge(_.assign($injector.get<YAxisActions>('sqYAxisActions').getYAxisConfig(payload.id), payload));

        if (isStringSeries) {
          cursor.set('stringEnum', [{ key: NON_NUMERICAL_SCALAR_CHART_VALUE, stringValue: payload.value }]);
        }
      }
    },

    /**
     * Private helper function to add a scalar to the store using the specified properties.
     *
     * @param {String} id - ID to use for the new scalar
     * @param {String} name - Name to use for the new scalar
     * @param {String} lane - Lane to use for the new series
     * @param {String} alignment - Alignment to use for the new series
     * @param {Object} props - Object containing properties to apply to the new scalar
     * @returns {Object} Newly created scalar object.
     */
    createScalar(id, name, lane, alignment, props) {
      return this.createItem(id, name, ITEM_TYPES.SCALAR, _.assign({
        data: [],
        dashStyle: DASH_STYLES.DASH,
        isStringSeries: false,
        yAxisConfig: {},
        statistics: {},
        lane,
        axisAlign: alignment,
        axisVisibility: true,
        axisAutoScale: true,
        visible: true,
        lineWidth: 1,
        yAxisType: Y_AXIS_TYPES.LINEAR,
        dataStatus: ITEM_DATA_STATUS.INITIALIZING,
        statusMessage: ''
      }, props));
    },

    /**
     * Called when alignment changes in capsule time. Scalars are only visible in capsule time if the signals are not
     * y-aligned.
     */
    setVisiblity() {
      this.waitFor('sqTrendStore', () => {
        const visible = $injector.get<TrendStore>('sqTrendStore').capsuleAlignment.length === 0;
        _.forEach(this.state.get('items'), (item, i) => {
          this.state.set(['items', i, 'visible'], visible);
        });
      });
    }
  };

  return sqBaseItemStore.extend(store);
}
