import React from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

interface NewLinetoBrProps {
  lineToBreak: string;
}

/** Converts newline characters in specified string to <br> elements */
export const NewLineToBr: React.FunctionComponent<NewLinetoBrProps> = (props) => {
  const { lineToBreak } = props;
  const { t } = useTranslation();

  return (<>{
    lineToBreak.split(/\r?\n/).map(
      (t, i) => (<React.Fragment key={i}>{t}<br /></React.Fragment>))
  }</>);
};
