import jQuery from 'jquery';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import WidgetResize from '@ckeditor/ckeditor5-widget/src/widgetresize';
import {
  BasePluginDependencies,
  CK_PRIORITY,
  ImageContentListenerCommand
} from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { PluginDependencies } from '@/hybrid/annotation/ckEditorPlugins/plugins/PluginDependencies';
import Observer from '@ckeditor/ckeditor5-engine/src/view/observer/observer';
import Rect from '@ckeditor/ckeditor5-utils/src/dom/rect';
import {
  CONTENT_MODEL_ATTRIBUTES,
  getContentSpecificCommand, getContentWidgetViewElement
} from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.utilities';

const CONTENT_LOADED_EVENT = 'seeqContentLoaded';
const RESIZED_CLASS = 'contentResized';

export class ContentResizeHandles extends Plugin {
  static get requires() {
    return [WidgetResize];
  }

  init() {
    const editor = this.editor;
    this.setupResizerCreator();
  }

  setupResizerCreator() {
    const editor = this.editor;
    const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);
    if (!deps.canModify || deps.isPDF) {
      return;
    }

    const editingView = editor.editing.view;

    this.listenTo(editingView.document, CONTENT_LOADED_EVENT, (event, domEvent) => {
      const widgetView = getContentWidgetViewElement(domEvent.target, editor);
      let resizer = editor.plugins.get(WidgetResize).getResizerByViewElement(widgetView);

      if (resizer) {
        // If the image refetches, we don't want to generate new resizers
        resizer.redraw();
        return;
      }

      const mapper = editor.editing.mapper;
      const imageModel = mapper.toModelElement(widgetView);
      const contentId = imageModel.getAttribute(CONTENT_MODEL_ATTRIBUTES.DATA_SEEQ_CONTENT);

      let isResizing = false;
      resizer = editor.plugins.get(WidgetResize)
        .attachTo({
          unit: '%',
          modelElement: imageModel,
          viewElement: widgetView,
          editor,
          isCentered() {
            return false;
          },

          getHandleHost(domWidgetElement) {
            return domWidgetElement.querySelector('img');
          },

          getResizeHost(domWidgetElement) {
            return domWidgetElement.querySelector('img');
          },

          onCommit(newWidth) {
            isResizing = false;
            this.editor.editing.view.change((writer) => {
              writer.setStyle('width', newWidth, this.viewElement);
              writer.removeClass(RESIZED_CLASS, this.viewElement);
            });
            this.editor.model.change(
              writer => writer.setAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT, newWidth, this.modelElement));
          }
        });

      // This is mostly the same as the default `updateSize` function that the resizer uses, but it also sets some
      // styles on the image via jQuery, and stops the default event from firing.
      resizer.on('updateSize', function(event, [domEventData]) {
        const domHandleHost = this._getHandleHost();
        if (!this._initialViewWidth) {
          // Fixes issues when the content has never been resized before and randomly growing to 100%
          this.state.originalWidthPercents = domHandleHost.width / jQuery('#journalEditor').width() * 100;
        } else if (this.state.originalWidthPercents === 100
          && parseFloat(this._initialViewWidth) !== 100 && !isResizing) {
          // Fixes issue where resizing content after its been resized causes it to start at 100%
          this.state.originalWidthPercents = parseFloat(this._initialViewWidth);
        }
        const newSize = this._proposeNewSize(domEventData);
        const editingView = this._options.editor.editing.view;

        if (!widgetView.hasClass(RESIZED_CLASS)) {
          isResizing = true;
          editingView.change((writer) => {
            writer.addClass(RESIZED_CLASS, widgetView);
          });

          // Need to fire this here as opposed to listening to model because no model changes occur until the
          // resizing is finished
          editor.fire(getContentSpecificCommand(ImageContentListenerCommand.RESIZE, contentId), true);
        }

        editingView.change((writer) => {
          const unit = this._options.unit || '%';
          const newWidth = (unit === '%' ? newSize.widthPercents : newSize.width) + unit;

          writer.setStyle('width', newWidth, this._options.viewElement);
        });

        // Get an actual image width, and:
        // * reflect this size to the resize wrapper
        // * apply this **real** size to the state
        const domHandleHostRect = new Rect(domHandleHost);

        newSize.handleHostWidth = Math.round(domHandleHostRect.width);
        newSize.handleHostHeight = Math.round(domHandleHostRect.height);

        // Handle max-width limitation.
        const domResizeHostRect = new Rect(domHandleHost);

        newSize.width = Math.round(domResizeHostRect.width);
        newSize.height = Math.round(domResizeHostRect.height);

        this.redraw(domHandleHostRect);

        this.state.update(newSize);
        event.stop();
      }, { priority: CK_PRIORITY.HIGH });

      resizer.bind('isEnabled').to(this);
    });
  }
}

export class ContentLoadObserver extends Observer {
  observe(domRoot) {
    // This is the browser's load event, which gets triggered when images finish loading.
    this.listenTo(domRoot, 'load', (event, domEvent) => {
      const domElement = domEvent.target;

      if (this.checkShouldIgnoreEventFromTarget(domElement)) {
        return;
      }

      if (domElement.tagName === 'IMG' && domElement.getAttribute(CONTENT_MODEL_ATTRIBUTES.DATA_SEEQ_CONTENT)) {
        this.document.fire(CONTENT_LOADED_EVENT, domEvent);
      }
    }, { useCapture: true });
  }
}
