import _ from 'lodash';
import angular from 'angular';
import { NotificationsService } from '@/services/notifications.service';
import { PluginsApi } from '@/sdk';
import { PluginsAdminStore } from '@/administration/pluginsAdmin.store';

angular.module('Sq.Administration').service('sqPluginsAdminActions', sqPluginsAdminActions);

export type PluginsAdminActions = ReturnType<typeof sqPluginsAdminActions>;

export function sqPluginsAdminActions(
  $q: ng.IQService,
  flux: ng.IFluxService,
  sqNotifications: NotificationsService,
  sqPluginsApi: PluginsApi,
  sqPluginsAdminStore: PluginsAdminStore
) {
  const service = {
    loadPlugins,
    setFilter,
    setSort,
    resetPageNumber,
    removePlugins,
    setPageSizeForTable,
    setPageNumberAndGo
  };

  return service;

  /**
   * Loads the plugins table using the pageSize and currentPageNumber to fetch the proper page content.
   */
  function loadPlugins() {
    const limit = sqPluginsAdminStore.getPageSizeByTable();
    const currentPageNumber = sqPluginsAdminStore.getPageNumberForTable();
    const offset = (currentPageNumber - 1) * limit;
    const sortOrder = `${sqPluginsAdminStore.sortProperty} ${sqPluginsAdminStore.sortAscending ? 'asc' : 'desc'}`;
    const searchParams = sqPluginsAdminStore.searchParams;
    flux.dispatch('PLUGINSADMIN_SET_TABLE_LOADING', { isLoading: true });

    return sqPluginsApi.getPlugins(
      _.assign({}, searchParams, { limit, offset, sortOrder }))
      .then((result) => {
        flux.dispatch('PLUGINSADMIN_SET_PLUGINS', { plugins: result.data.plugins });
        flux.dispatch('PLUGINSADMIN_SET_PLUGINS_TOTAL', { count: result.data.totalResults });
      })
      .finally(() => flux.dispatch('PLUGINSADMIN_SET_TABLE_LOADING', { isLoading: false }));
  }

  /**
   * Sets the filter used as searchParams in the store. Also resets the pageNumber to 1.
   *
   * @param {string} field - the field to search on
   * @param {string} value - the value to search on
   */
  function setFilter(field: string, value: string) {
    flux.dispatch('PLUGINSADMIN_SET_SEARCH_PARAMS', { field, value });
    flux.dispatch('PLUGINSADMIN_SET_PAGE_NUMBER', { pageNumber: 1 });
  }

  /**
   * Enables sorting. Also resets the pageNumber to 1.
   *
   * @param {string} field - the field to search on
   * @param {boolean} sortAsc - true for asc, false otherwise
   */
  function setSort(field: string, sortAsc: boolean) {
    flux.dispatch('PLUGINSADMIN_SET_SORT', { field, sortAscending: sortAsc });
    flux.dispatch('PLUGINSADMIN_SET_PAGE_NUMBER', { pageNumber: 1 });
  }

  /**
   * Resets the page number to 1.
   */
  function resetPageNumber() {
    flux.dispatch('PLUGINSADMIN_SET_PAGE_NUMBER', { pageNumber: 1 });
  }

  /**
   * Remove plugins
   * If no plugins are selected a warning to select plugins is displayed.
   *
   * @param {String[]} ids - The ids of the groups to remove
   * @returns {Promise} that resolves when the removal is complete
   */
  function removePlugins(ids) {
    const removed = [];
    if (_.isEmpty(ids)) {
      sqNotifications.warnTranslate('ADMIN.PLUGIN.SELECTION_REQUIRED');
      return $q.resolve();
    }
    _.forEach(ids, function(id) {
      removed.push(sqPluginsApi.deletePlugin({ id }));
    });
    return $q.all(removed)
      .then(() => {
        flux.dispatch('PLUGINSADMIN_REMOVE_PLUGINS', ids);
        sqNotifications.successTranslate('ADMIN.PLUGIN.REMOVED');
      })
      .catch((error) => {
        sqNotifications.apiError(error, { displayForbidden: true });
        service.loadPlugins();
      });
  }

  /**
   * Sets the number of plugins per page
   *
   * @param {number} size - the new page size
   */
  function setPageSizeForTable({ size }) {
    flux.dispatch('PLUGINSADMIN_SET_PAGE_SIZE', { size });
  }

  /**
   * Sets the page number and loads the plugin table.
   *
   * @param {number} pageNumber - the page number
   */
  function setPageNumberAndGo(pageNumber: number) {
    flux.dispatch('PLUGINSADMIN_SET_PAGE_NUMBER', { pageNumber });
    service.loadPlugins();
  }
}
