import React from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { TrendCapsulePropertiesDropdown } from '@/hybrid/trend/TrendCapsulePropertiesDropdown.molecule';

const trendLabelsPopoverBindings = bindingsDefinition({});

/**
 * The popover in the chart for configuring chart labels.
 */
export const TrendLabelsPopover: SeeqComponent<typeof trendLabelsPopoverBindings> = () => {
  const { t } = useTranslation();

  return (
    <div className="flexColumnContainer flexSpaceBetween flexBaseline pl20 pb5">
      <div>{t('CAPSULES')}</div>
      <TrendCapsulePropertiesDropdown />
    </div>
  );
};

export const sqTrendLabelsPopover = angularComponent(trendLabelsPopoverBindings, TrendLabelsPopover);
