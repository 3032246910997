import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { Icon } from '@/hybrid/core/Icon.atom';
import { NotificationsService } from '@/services/notifications.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

export type DisplayType = 'button' | 'icon';

const copyButtonGivenTextBindings = bindingsDefinition({
  id: prop.optional<string>(),
  tooltip: prop<string>(),
  textToCopy: prop<string>(),
  // display as an icon or as a button
  asType: prop.optional<DisplayType>(),
  // icon classname to display, default is fa-copy
  iconClass: prop.optional<string>(),
  testId: prop.optional<string>(),
  // notification message sent to user
  notifyMessage: prop.optional<string>(),
  extraClassNames: prop.optional<string>(),
  // HoverTooltip requires containing div, set its class here
  containingClassNames: prop.optional<string>(),
  // Optional function to execute once copy is complete. Default is noop
  onCopyFinish: prop.optional<() => void>(),
  // Defaults to false
  disableButton: prop.optional<boolean>(),
  sqNotifications: injected<NotificationsService>()
});

/**
 * Button to place specified text on the clipboard
 */
export const CopyButtonGivenText: SeeqComponent<typeof copyButtonGivenTextBindings> = (props) => {
  const {
    id,
    tooltip,
    textToCopy,
    asType = 'button',
    notifyMessage,
    testId,
    iconClass = 'fa-copy',
    extraClassNames,
    containingClassNames,
    onCopyFinish = _.noop,
    disableButton = false
  } = props;

  const { sqNotifications } = useInjectedBindings(copyButtonGivenTextBindings);
  const { t } = useTranslation();

  const copyToClipboard = () => {
    const element = document.createElement('textarea');
    element.innerHTML = textToCopy;
    element.setAttribute('readonly', '');
    document.body.appendChild(element);
    element.select();
    document.execCommand('copy');
    document.body.removeChild(element);

    if (!_.isNil(notifyMessage)) {
      sqNotifications.success(`${t(notifyMessage)}`);
    }
    onCopyFinish();
  };

  const renderButton = (
    <button
      disabled={disableButton}
      data-testid={testId}
      className={extraClassNames}
      onClick={copyToClipboard}>
      <Icon icon={iconClass} id={id} />
    </button>
  );

  const renderIcon = (
    <div data-testid={testId} className={containingClassNames}>
      <Icon
        icon={iconClass}
        extraClassNames={classNames('cursorPointer', extraClassNames)}
        onClick={copyToClipboard} id={id} />
    </div>
  );

  const render = (asType === 'button') ? renderButton : renderIcon;

  return (
    <HoverTooltip text={tooltip}>
      {render}
    </HoverTooltip>
  );
};

export const sqCopyButtonGivenText = angularComponent(copyButtonGivenTextBindings, CopyButtonGivenText);
