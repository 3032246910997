import React from 'react';
import { Icon } from '@/hybrid/core/Icon.atom';
import { ContainerWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';

export const FormulaErrorMessage: React.FunctionComponent<{ message: string, clearError: () => void }> = ({
  message,
  clearError
}) => (
  <div className="flexRowContainer alert-danger pt5 pr5 pb5 pl5 text-pre-wrap">
    <div className="flexColumnContainer">
      <div className="flexFill" />
      <div>
        <Icon
          icon="fa-close"
          extraClassNames="cursorPointer"
          type="danger"
          onClick={clearError} />
      </div>
    </div>
    <div>
      <ContainerWithHTML content={message} />
    </div>
  </div>
);

