import angular from 'angular';
import template from './resizablePanel.component.html';

/**
 * A resizable panel component for the journal. Not yet completely reusable, but close and could be made
 * reusable in the future.
 */
angular.module('Sq.Annotation').component('sqResizablePanel', {
  controller: 'ResizablePanelCtrl',
  template,
  transclude: true,
  bindings: {
    minWidth: '@',
    minHeight: '@',
    defaultWidth: '@',
    isExpanded: '<',
    width: '<',
    height: '<',
    widthChanged: '&',
    heightChanged: '&',
    isPresentationMode: '<'
  }
});
