import _ from 'lodash';
import { SEARCH_ITEM_LOCATIONS } from '@/main/app.constants';
import { tabFolders } from '@/hybrid/homescreen/homescreen.module';
import { ExploreFolderModalStore } from './exploreFolderModal.store';
import { FoldersApi, ItemsApi } from '@/sdk';
import { HomeScreenStore } from '@/hybrid/homescreen/homescreen.store';
import { UtilitiesService } from '@/services/utilities.service';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { AuthorizationService } from '@/services/authorization.service';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';

export type ExploreFolderModalActions = ReturnType<typeof sqExploreFolderModalActions>;

export function sqExploreFolderModalActions(
  flux: ng.IFluxService,
  sqItemsApi: ItemsApi,
  $q: ng.IQService,
  $state: ng.ui.IStateService,
  $translate: ng.translate.ITranslateService,
  sqAuthorization: AuthorizationService,
  sqFoldersApi: FoldersApi,
  sqHomeScreenStore: HomeScreenStore,
  sqExploreFolderModalStore: ExploreFolderModalStore,
  sqHomeScreenUtilities: HomeScreenUtilitiesService,
  sqUtilities: UtilitiesService,
  sqSystemConfiguration: SystemConfigurationService
) {

  const service = {
    setInitialFolderId,
    setWorkbookId,
    setSort,
    clear,
    setTableFilter,
    setPageSizeForTable,
    setPageNumberAndGo,
    setSearchParams,
    loadItems,
    setFolder,
    getRootAndUpdateFilter,
    setShowResults,
    setTableRoot,
    getInitialFolder
  };

  return service;

  /**
   * Sets the ID of the folder where the user started
   *
   * @param {String} initialFolderId - ID of a folder
   */
  function setInitialFolderId(initialFolderId) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_INITIAL_FOLDER_ID', { initialFolderId });
  }

  /**
   * Sets the workbook ID
   *
   * @param {String} workbookId - a workbook ID
   */
  function setWorkbookId(workbookId) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_WORKBOOK_ID', { workbookId });

  }

  /**
   * Sets the sort field that controls the sorting of the workbooks and toggles the direction if the field
   * was already set to the specified one.
   *
   * @param {String} sortProperty - One of WORKBOOKS_SORT_FIELDS
   * @param {boolean} sortAscending - True for asc, false for desc
   * @param {boolean} forceSortDirection=false - True if the given sort direction must not be flipped when sorting
   *   on the currently sorted property (normally used to toggle sort direction)
   */
  function setSort(sortProperty, sortAscending: boolean, forceSortDirection = false) {
    sortAscending = !forceSortDirection && sortProperty === sqExploreFolderModalStore.sortProperty
      ? !sortAscending : sortAscending;
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_SORT', { sortProperty, sortAscending });
  }

  /**
   * Clears the data for the currently loaded seeq content image.
   */
  function clear() {
    flux.dispatch('EXPLORE_FOLDER_MODAL_CLEAR');
  }

  function setTableFilter(tableFilter) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_TABLE_FILTER', { tableFilter });
  }

  function setSearchParams(field, value) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_SEARCH_PARAMS', { field, value });
  }

  function setPageSizeForTable({ size }) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_PAGE_SIZE', { pageSize: size });
  }

  function setPageNumberAndGo(page: number, loadTable: Function) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_PAGE_NUMBER', { pageNumber: page });
    loadTable();
  }

  function setFolder(folder) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_FOLDER', { folder });
  }

  function setShowResults(showResults) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_SHOW_RESULTS', { showResults });
  }

  function setTableRoot(root) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_TABLE_ROOT', { root });
  }

  function loadItems() {
    if (!sqExploreFolderModalStore.initialFolderId) {
      return;
    }

    const limit = sqExploreFolderModalStore.getPageSizeByTable();
    const currentPageNumber = sqExploreFolderModalStore.getPageNumberForTable();
    const offset = (currentPageNumber - 1) * limit;
    const sortDirection = sqExploreFolderModalStore.sortAscending ? 'asc' : 'desc';
    const sortOrder = `${sqExploreFolderModalStore.sortProperty} ${sortDirection}`;
    const root = sqExploreFolderModalStore.tableRoot;
    const params = _.assign({}, sqExploreFolderModalStore.searchParams, { limit, offset, sortOrder, root });

    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_LOADING', { loading: true });
    return sqFoldersApi.getFolders(params)
      .then((response) => {
          flux.dispatch('EXPLORE_FOLDER_MODAL_SET_ITEMS', { items: response.data.content });
          flux.dispatch('EXPLORE_FOLDER_MODAL_SET_ITEM_TOTAL_FOR_TABLE',
            { itemTotalForTable: response.data.totalResults });
        }
      )
      .finally(() => flux.dispatch('EXPLORE_FOLDER_MODAL_SET_LOADING', { loading: false }));
  }

  function getRootAndUpdateFilter(folderId) {
    if (folderId) {
      return sqFoldersApi.getAncestors({ folderId })
        .then((folderTree) => {
          const itemFolderRoot = folderTree.data.id;
          if (_.includes(
            [SEARCH_ITEM_LOCATIONS.CORPORATE, SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC, SEARCH_ITEM_LOCATIONS.MY_FOLDER],
            itemFolderRoot)) {
            service.setTableFilter(itemFolderRoot);
            service.setSearchParams('filter', itemFolderRoot);
          }
        });
    } else {
      return Promise.resolve({});
    }
  }

  function getInitialFolder(currentTab, item) {
    let tabFolderPromise;
    let tableFilter = currentTab;
    const tabFolder =
      _.includes(tabFolders(sqAuthorization.isAdmin(), sqSystemConfiguration.everyoneGroupEnabled), currentTab)
        ? currentTab
        : undefined;
    sqHomeScreenUtilities.getTabFolder(tabFolder)
      .then((folder) => {
        if (!sqUtilities.equalsIgnoreCase(item.parentFolderId, folder?.id)) {
          tabFolderPromise = sqHomeScreenUtilities.getTabFolderName(item.ancestors[0].id);
          tabFolderPromise.then((tabName) => {
            tableFilter = item.ancestors[0].id ? tabName : tableFilter;
            folder = _.assign({}, _.last(item.ancestors),
              { ancestors: _.slice(item.ancestors, 0, item.ancestors.length - 1) });
          });
        } else {
          tabFolderPromise = Promise.resolve();
        }

        tabFolderPromise.then(() => {
          service.setFolder(folder);
          service.setTableFilter(SEARCH_ITEM_LOCATIONS[tableFilter]);
          service.setSearchParams('folderId', item.parentFolderId);
          service.setInitialFolderId(item.parentFolderId);
        });
      });
  }
}
