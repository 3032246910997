import React from 'react';
import { DEBOUNCE, JOURNAL_PREFIX_PATH } from '@/main/app.constants';
import {
  BaseEditor,
  baseEditorBindings,
  EditorDependencies,
  EditorProps
} from '@/hybrid/annotation/BaseEditor.organism';
import { createCustomConfig, DefaultCKConfig } from '@/hybrid/annotation/ckConfiguration';
import DecoupledEditor from '@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { TOGGLE_CK_SAVING } from '@/hybrid/annotation/ckReport.module';
import CKEditorInspector from '@ckeditor/ckeditor5-inspector';

const setupCKEditor = function(deps: EditorDependencies, props: EditorProps) {
  const {
    sqUtilities,
    $sce,
    sqReportEditor,
    $injector,
    $rootScope,
    $translate,
    sqReportActions,
    sqReportStore,
    sqPdfExportStore
  } = deps;
  const {
    document,
    id: docId,
    documentChanged,
    initialLanguage,
    toolbar = DefaultCKConfig.toolbar,
    plugins = [],
    isJournal
  } = props;

  let editor;
  let trustedDocument;
  let savedHtml = document;
  let activeConfiguration;
  let languageToUse = initialLanguage;
  const canModify = $injector.get<ReportContentService>('sqReportContent').canModify();

  const setInitialDocument = (e) => {
    editor = e;
    savedHtml = sqReportEditor.getPresetHtml() || savedHtml;
    if (savedHtml) {
      editor.setData(savedHtml);
    }
    return e;
  };

  /**
   * this function will later be using the await import method of async importing modules, once we split
   * the build into multiple separate bundles.
   */
  const loadLanguages = () => new Promise((resolve, reject) => {
    if (window.document.getElementById('ckLanguagesScript')) {
      resolve(true);
      return;
    }

    const s = window.document.createElement('script');
    s.setAttribute('src', `/resources/ckeditor-languages.js?t=${Date.now()}`);
    s.setAttribute('id', 'ckLanguagesScript');
    s.onerror = reject;
    s.onload = resolve;
    window.document.body.appendChild(s);
  });

  const changePaginationStatus = (isEnabled) => {
    let displayStatus = 'none';
    if (isEnabled) {
      editor.plugins.get('Pagination').clearForceDisabled('FixedPagePagination');
      displayStatus = '';
    } else {
      editor.plugins.get('Pagination').forceDisabled('FixedPagePagination');
    }
    // change display status for the pagination buttons
    if (activeConfiguration.toolbar.indexOf('previousPage') === -1) return;
    const toolbarItems = editor.ui.view.toolbar.items;
    toolbarItems.get(activeConfiguration.toolbar.indexOf('previousPage')).element.style.display = displayStatus;
    toolbarItems.get(activeConfiguration.toolbar.indexOf('nextPage')).element.style.display = displayStatus;
    toolbarItems.get(activeConfiguration.toolbar.indexOf('pageNavigation')).element.style.display = displayStatus;
  };

  const addCKEventHandlers = () => {
    editor.model.document.on('change:data', defaultHandleChange);
    editor.on(TOGGLE_CK_SAVING, (event, toggleOn) => toggleOn
      ? editor.model.document.on('change:data', defaultHandleChange)
      : editor.model.document.off('change:data', defaultHandleChange));
    editor.on('toggleFixedWidth', () => {
      if (sqReportStore.isFixedWidth) {
        changePaginationStatus(false);
      } else {
        changePaginationStatus(true);
      }
      sqReportActions.toggleFixedWidth();
    });
  };

  const addToolbarToDOM = () => {
    if (!editor) return;
    window.document.getElementById('journalEditorToolbarContainer').appendChild(editor.ui.view.toolbar.element);
  };

  const removeToolbarFromDOM = () => {
    if (!editor) return;
    window.document.getElementById('journalEditorToolbarContainer').removeChild(editor.ui.view.toolbar.element);
  };

  const init = (isEditMode = true) => {
    if (editor) {
      sqReportStore.backupPreview ? removeToolbarFromDOM() : addToolbarToDOM();
      editor.isReadOnly = !isEditMode;
      isEditMode && editor.editing.view.focus();
      return;
    }
    loadLanguages()
      .then(() => sqUtilities.headlessRenderMode()
        ? sqUtilities.headlessJobFormat()
          .then(format => format === 'PDF')
        : false)
      .then((isPDF) => {
        activeConfiguration = createCustomConfig(plugins, {
            $injector,
            $rootScope,
            $translate,
            annotationId: docId,
            canModify,
            isPDF
          },
          toolbar as any);
        activeConfiguration.language = languageToUse;
        const marginValue = `${sqPdfExportStore.margin.value}${sqPdfExportStore.margin.units}`;
        activeConfiguration.pagination = {
          pageWidth: sqPdfExportStore.paperSize.width,
          pageHeight: sqPdfExportStore.paperSize.height,
          pageMargins: {
            top: marginValue,
            bottom: marginValue,
            right: marginValue,
            left: marginValue
          }
        };
        activeConfiguration.placeholder = $translate.instant('REPORT.EDITOR.PLACEHOLDER');
        return activeConfiguration;
      })
      .then(ckConfig => DecoupledEditor.create(window.document.getElementById('journalEditor'), ckConfig))
      .then(sqReportEditor.setGlobalInstance)
      .then((editor) => {
        const useInspector = false; // Toggle to true if you need to debug CK
        if (process.env.NODE_ENV === 'development' && useInspector) {
          CKEditorInspector.attach(editor);
        }
        return editor;
      })
      .then(setInitialDocument)
      .then(() => {
        editor.isReadOnly = !canModify || (isJournal && !isEditMode);
        if (canModify) {
          addToolbarToDOM();
          addCKEventHandlers();
          editor.editing.view.focus();
        }

        if (!sqReportStore.isFixedWidth) {
          changePaginationStatus(false);
        }

        // Block link popup when it is a special journal link that the user should not be able to edit
        editor.editing.view.document.on('click', (event, data) => {
          const model = editor.model;
          const doc = model.document;
          const sel = doc.selection;

          // This will get attribute from closest range only e.g. span but not paragraph
          const attrValue = sel.getAttribute('linkHref');
          const linkCommand = editor.commands.get('link');

          if (attrValue && attrValue.indexOf(JOURNAL_PREFIX_PATH) === 0) {
            // disable link button from toolbar
            linkCommand.forceDisabled('link');
            // prevent default panel being shown
            event.stop();
          } else {
            linkCommand.clearForceDisabled('link');
          }
        }, { priority: 'high' });

      })
      .catch((error) => {
        console.error('Failed initializing CKEDitor: ', error.stack);
      });
  };

  /**
   * Calls the documentChanged() callback to save the current state of the document in the editor.
   */
  const updated = (forceSave: boolean): Promise<object | void> => {
    if (!editor) return Promise.resolve();

    const document = updateTrustedDocument();
    savedHtml = document;

    return Promise.resolve(documentChanged(document, forceSave));
  };

  /**
   * We need to use the isAllowedDuringTest parm here as the _.debounce is mocked in system tests.
   * This causes issues with the saving of the document as it happens on all characters instead of
   * latest character typed in the document. When system tests will check if the document is saved,
   * it will actually be triggered by previous characters (based on delays) and the last characters
   * from the text will not be saved
   */
  // @ts-ignore
  const handleChange = _.debounce(updated, DEBOUNCE.MEDIUM, { isAllowedDuringTest: true });
  const defaultHandleChange = () => handleChange(false);

  /**
   * Helper function that removes encoded image data and updates the trustedDocument view model property.
   * When pasting an image, froala will initially encode the image data in the src property. To prevent
   * this, we remove the encoded image data from the src tag, which is fine because the URL of the image
   * file saved to the backend will be updated momentarily and the image will display.
   */
  const updateTrustedDocument = (): string => {
    if (!editor) return '';

    const htmlContent = sqUtilities.removeEncodedImageData(editor.getData());
    trustedDocument = $sce.trustAsHtml(htmlContent);
    return htmlContent;
  };

  const setLanguage = (language) => {
    if (languageToUse === language) return;
    languageToUse = language;

    if (!editor) return;
    destroy();
    init();
  };

  /**
   * Destroys the Journal editor
   */
  const destroy = () => {
    if (!editor) return;

    editor.destroy();
    editor = null;
    // remove toolbar elements from DOM
    if (canModify) {
      const toolbar = window.document.getElementById('journalEditorToolbarContainer');
      if (toolbar) {
        toolbar.textContent = '';
      }
    }
  };

  /**
   * This resize function is needed to set the height of the ck editor
   * so it will fill the available vertical space.
   */
  const resize = (height, width, forceRefresh = false) => {
    // not needed for CKEditor
  };

  const getHtml = (): string => savedHtml;

  const handlePreview = (showBackup: boolean) => {
    sqReportEditor.setHtml(showBackup ? sqReportStore.backupPreview.document : sqReportStore.document);
  };

  return {
    init,
    handleChange,
    handlePreview,
    setLanguage,
    resize,
    getHtml,
    updated,
    destroy
  };
};

export const CKEditor: SeeqComponent<typeof baseEditorBindings> = props => <BaseEditor
  {...props} setupEditor={setupCKEditor} isCkEditor={true} />;
