import angular from 'angular';
import template from './investigateRangeSelector.directive.html';

angular
  .module('Sq.TrendViewer')
  .directive('sqInvestigateRangeSelector', sqInvestigateRangeSelector);

function sqInvestigateRangeSelector() {
  const directive = {
    restrict: 'EA',
    template,
    scope: {},
    controller: 'RangeSelectorCtrl',
    controllerAs: 'ctrl',
    bindToController: {
      updateDisplayRangeStart: '&',
      updateDisplayRangeEnd: '&',
      updateDisplayRangeTimes: '&',
      displayRange: '&',
      investigateRange: '&',
      displayRangeEditingEnabled: '<',
      investigateRangeEditingEnabled: '<'
    }
  };

  return directive;
}

