import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { FolderNavEntry } from '@/hybrid/homescreen/FolderNavEntry.molecule';
import { APP_STATE } from '@/main/app.constants';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { HomeScreenStore } from '@/hybrid/homescreen/homescreen.store';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';

const folderTreeBindings = bindingsDefinition({
  sqHomeScreenActions: injected<HomeScreenActions>(),
  sqHomeScreenStore: injected<HomeScreenStore>(),
  $state: injected<ng.ui.IStateService>(),
  tableType: prop.optional<string>(),
  topLevel: prop.optional<Boolean>(),
  root: prop.optional<string>()
});

export const FolderTree: SeeqComponent<typeof folderTreeBindings> = (props) => {
  const { sqHomeScreenActions, $state, sqHomeScreenStore } = useInjectedBindings(folderTreeBindings);
  const { tableType, topLevel, root } = props;
  const folders = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.folderTree);
  const expandedFolderIds = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.expandedFolderIds);

  const toggleFolder = (folderId) => {
    _.indexOf(expandedFolderIds, folderId) > -1
      ? sqHomeScreenActions.collapseFolder(folderId)
      : sqHomeScreenActions.expandFolder(folderId, root);
  };

  const openFolder = (folderId) => {
    if (folderId !== $state.params.currentFolderId) {
      sqHomeScreenActions.setPageNumber(1, tableType);
      return $state.go(APP_STATE.FOLDER_EXPANDED, { currentFolderId: folderId });
    }
  };

  return (
    <div className={classNames('max-height-20em', 'overflowYScroll', { ml15: !topLevel })}>
      {_.map(folders,
        folder => <FolderNavEntry
          key={`folderTree_${folder.id}`}
          folder={folder}
          expandedFolderIds={expandedFolderIds}
          toggleAction={toggleFolder}
          openFolder={openFolder} />)}
    </div>
  );
};
