import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { AdministrationStore } from '@/administration/administration.store';
import { AdministrationActions } from '@/administration/administration.actions';
import { ButtonWithManagedSpinner } from '@/hybrid/core/ButtonWithManagedSpinner.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { CELL_TYPES, TableColumn } from '@/hybrid/core/Table.atom';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import { GroupAdminService } from '@/administration/groupAdmin.service';
import { FormWarning } from '@/hybrid/core/FormWarning.atom';
import { AdminTableWrapper } from '@/hybrid/core/AdminTableWrapper.molecule';

const groupsTabBindings = bindingsDefinition({
  sqAdministrationStore: injected<AdministrationStore>(),
  sqAdministrationActions: injected<AdministrationActions>(),
  sqGroupAdmin: injected<GroupAdminService>()
});

export const GroupsTab: SeeqComponent<typeof groupsTabBindings> = () => {

  const { sqAdministrationStore, sqAdministrationActions, sqGroupAdmin } = useInjectedBindings(groupsTabBindings);
  const [viewOnlyGroupSelected, setViewOnlyGroupSelected] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const { t } = useTranslation();
  const unformattedGroups = useFluxPath(sqAdministrationStore, () => sqAdministrationStore.groups);

  useEffect(() => {
    sqAdministrationActions.load();
  }, []);

  useEffect(() => {
    setViewOnlyGroupSelected(_.some(selectedItems, group => sqGroupAdmin.isGroupReadOnly(group)));
  }, [groups, selectedItems]);

  useEffect(() => {
    setGroups(sqGroupAdmin.formatGroups(unformattedGroups));
  }, [unformattedGroups]);

  const renderViewOrEdit = (group) => {
    const isReadOnly = sqGroupAdmin.isGroupReadOnly(group);
    return (
      <div className="flexColumnContainer specAdminEditGroup flexAlignCenter cursorPointer"
        onClick={() => sqGroupAdmin.openGroupAddEditModal(group)}>
        <Icon icon={isReadOnly ? 'fa-list-alt' : 'fa-pencil'} />
        <span className="pl5 sq-text-primary">{t(isReadOnly ? 'VIEW' : 'EDIT')}</span>
      </div>
    );
  };

  const columns: TableColumn[] = [
    {
      accessor: 'id',
      sortable: false,
      filterable: false,
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 40, maxWidth: 40 }
    }, {
      accessor: 'name',
      searchProperty: 'name',
      header: 'ADMIN.GROUP.NAME',
      cellStyle: { minWidth: 150, maxWidth: 250, wordWrap: 'break-word', whiteSpace: 'normal', wordBreak: 'break-all' },
      sortable: true,
      filterable: true
    }, {
      accessor: 'datasourceName',
      searchProperty: 'datasourceName',
      header: 'ADMIN.GROUP.DIRECTORY',
      cellStyle: { minWidth: 75 },
      sortable: true,
      filterable: true
    }, {
      accessor: 'isEnabled',
      searchProperty: 'isEnabled',
      header: 'ADMIN.GROUP.STATUS',
      cellType: CELL_TYPES.ENABLED_DISABLED,
      cellStyle: { width: 150, maxWidth: 150 },
      sortable: true,
      filterable: true
    }, {
      accessor: 'edit',
      filterable: false,
      sortable: false,
      cellStyle: { width: 75, maxWidth: 75 },
      cellRenderFunction: renderViewOrEdit
    }];

  return (
    <div className="height-maximum">
      <div className="flexColumnContainer flexSpaceBetween mb5">
        <ButtonWithManagedSpinner
          buttonProps={{ id: 'removeSelectedGroup', disabled: viewOnlyGroupSelected || _.isEmpty(selectedItems) }}
          spinnerIconProps={{ white: true }}
          action={() => sqAdministrationActions.removeGroups(_.map(selectedItems, 'id'))
            .finally(() => setSelectedItems([]))}
          label="ADMIN.GROUP.REMOVE_SELECTED" />

        {viewOnlyGroupSelected && <FormWarning warningText="ADMIN.GROUP.REMOVE_WARNING" extraClassNames="mb5" />}

        <div className="flexColumnContainer">
          <ButtonWithManagedSpinner
            buttonProps={{ id: 'refreshGroups', extraClassNames: 'addGroup action mr10' }}
            spinnerIconProps={{ type: 'text' }}
            action={() => sqAdministrationActions.load().finally(() => setSelectedItems([]))}
            icon="fa-repeat"
            label="ADMIN.GROUP.REFRESH_LIST" />

          <TextButton
            id="addGroup"
            variant="theme"
            onClick={() => sqGroupAdmin.openGroupAddEditModal(undefined)}
            icon="fa-plus"
            iconStyle="white"
            extraClassNames="mr15"
            label="ADMIN.GROUP.ADD_GROUP" />
        </div>
      </div>

      <div className="overflowAuto width-maximum height-maximum pb70">
        <AdminTableWrapper
          testId="groupsTable"
          items={groups}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          defaultSort={{ property: 'name', asc: true }}
          columns={columns} />
      </div>
    </div>
  );
};
