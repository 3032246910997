import React from 'react';
import classNames from 'classnames';
import { CONTENT_LOADING_CLASS, IMAGE_BORDER_CLASS } from '@/reportEditor/report.module';
import { BLANK_IMAGE } from '@/hybrid/annotation/ckEditorPlugins/components/RefreshingContent.molecule';
import { ContentVisualizationProps } from '@/hybrid/annotation/ckEditorPlugins/components/content.utilities';

export const ImageContent: React.FunctionComponent<ContentVisualizationProps> = ({
  contentId,
  noMargin,
  border,
  errorClass,
  style,
  loading,
  target,
  src,
  onLoad,
  onMeasurementsReady,
  showMenu,
  error
}) => {
  return <img
    data-testid={`content-${contentId}`}
    data-seeq-content={contentId}
    ref={target}
    style={style}
    className={classNames(
      noMargin ? '' : 'm2',
      border ? IMAGE_BORDER_CLASS : '',
      errorClass ?? '', {
        [CONTENT_LOADING_CLASS.SPINNER]: loading && !errorClass,
        [CONTENT_LOADING_CLASS.LOADED]: !loading && !errorClass
      })}
    title={error}
    src={errorClass ? BLANK_IMAGE : src}
    onLoad={(ref) => {
      // Initial load will return a 1x1 empty image. Subsequent loads shouldn't.
      if (ref.currentTarget.naturalWidth !== 1 && ref.currentTarget.naturalHeight !== 1) {
        onLoad();
        onMeasurementsReady(ref.currentTarget.naturalWidth, ref.currentTarget.naturalHeight);
      }
    }}
    onClick={showMenu}
  />;
};
