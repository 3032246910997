/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class LogsApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Download the log files in zipped form.
   * @param {string} [downloadLogsCookie] - The value to return as a cookie.
   */
  public downloadLogs(
    {
      downloadLogsCookie
    } : {
      downloadLogsCookie?: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/logs/download',
      params: omitBy({
        ['downloadLogsCookie']: downloadLogsCookie
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<{}>;
  }

  /**
   * 
   * @summary Get a list of available log files.
   */
  public getAvailableLogFiles(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/logs',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<Array<string>>;
  }

  /**
   * 
   * @summary Get a collection of log messages
   * @param {string} log - ID of the log to retrieve
   * @param {string} startTime - Starting time to filter log messages on.
   * @param {number} limit - The (soft) limit for the number of log messages to return.
   * @param {string} [endTime] - Ending time to filter log messages on. It will default to the current time if none is specified.
   * @param {string} [sorted=ASC] - Whether log messages should be sorted in ascending order, &#39;ASC&#39;, by their time or descending order, &#39;DESC&#39;.
   * @param {string} [level=DEBUG] - The minimum log level to include.
   * @param {string} [sourceContains] - A substring that the source field of the log messages must contain to be returned.
   * @param {string} [messageContains] - A substring that the message field of the log messages must contain to be returned.
   */
  public getLogs(
    {
      log,
      startTime,
      limit,
      endTime,
      sorted,
      level,
      sourceContains,
      messageContains
    } : {
      log: string,
      startTime: string,
      limit: number,
      endTime?: string,
      sorted?: string,
      level?: string,
      sourceContains?: string,
      messageContains?: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(log)) {
      throw new Error("'log' parameter required");
    }

    if (isNil(startTime)) {
      throw new Error("'startTime' parameter required");
    }

    if (isNil(limit)) {
      throw new Error("'limit' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/logs/{log}'
        .replace('{log}', encodeURIComponent(String(log))),
      params: omitBy({
        ['startTime']: startTime,
        ['endTime']: endTime,
        ['limit']: limit,
        ['sorted']: sorted,
        ['level']: level,
        ['sourceContains']: sourceContains,
        ['messageContains']: messageContains
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<Array<models.LogMessage>>;
  }

  /**
   * 
   * @summary Send the log files to Seeq.
   * @param {models.SendLogsInputV1} body - Log information
   */
  public sendLogs(
    body: models.SendLogsInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/logs/send',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<{}>;
  }

}
