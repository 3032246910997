import _ from 'lodash';
import angular from 'angular';
import FileSaver from 'file-saver';
import { ExportService } from '@/services/export.service';
import { WorkbookStore } from '@/workbook/workbook.store';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { InvestigateStore } from '@/investigate/investigate.store';
import { NotificationsService } from '@/services/notifications.service';
import { APP_STATE, DISPLAY_MODE, MIME_TYPES } from '@/main/app.constants';

angular.module('Sq.Investigate')
  .controller('ExportPowerpointPanelCtrl', ExportPowerpointPanelCtrl);

function ExportPowerpointPanelCtrl(
  $scope: ng.IScope,
  $state: ng.ui.IStateService,
  sqExport: ExportService,
  sqWorkbookStore: WorkbookStore,
  sqInvestigateActions: InvestigateActions,
  sqInvestigateStore: InvestigateStore,
  sqNotifications: NotificationsService
) {
  const vm = this;

  $scope.$listenTo(sqInvestigateStore, ['displayMode'], setInvestigateVars);

  activate();

  function activate() {
    vm.DISPLAY_MODE = DISPLAY_MODE;
    vm.WORKSHEETS = {
      ALL: 'ALL',
      CURRENT: 'CURRENT'
    };
    vm.LINK_TYPES = [{
      text: 'EXPORT_POWERPOINT.NONE',
      value: 'none'
    }, {
      text: 'EXPORT_POWERPOINT.VIEW_ONLY',
      value: 'viewOnly'
    }, {
      text: 'EXPORT_POWERPOINT.FULL',
      value: 'full'
    }, {
      text: 'EXPORT_POWERPOINT.PRESENTATION',
      value: 'presentation',
      hide: true
    }];

    vm.displayMode = vm.DISPLAY_MODE.NEW;
    vm.worksheetsOption = vm.WORKSHEETS.CURRENT;
    vm.linkType = _.find(vm.LINK_TYPES, { value: 'full' });
    vm.worksheetId = $state.params.worksheetId;
    vm.workbookId = $state.params.workbookId;
    vm.close = sqInvestigateActions.close;
    vm.execute = execute;
  }

  /**
   * Syncs sqInvestigateStore and view-model properties
   */
  function setInvestigateVars() {
    vm.displayMode = sqInvestigateStore.displayMode;
  }

  /**
   * Starts the export using the parameters from the model.
   */
  function execute() {
    sqInvestigateActions.setDisplayMode(DISPLAY_MODE.IN_PROGRESS);

    sqExport.toPowerpoint(sqWorkbookStore.name, generateWorksheetsArgument())
      .then((response: any) => new Blob([response.data], { type: MIME_TYPES.POWERPOINT }))
      .then(blob => FileSaver.saveAs(blob, 'SeeqExport.pptx'))
      .catch(sqNotifications.apiError)
      .finally(() => sqInvestigateActions.setDisplayMode(DISPLAY_MODE.NEW));
  }

  /**
   * Generates the array of worksheet objects to send to the backend based on the selected options
   */
  function generateWorksheetsArgument() {
    const presentationLinkType = _.find(vm.LINK_TYPES, { value: 'presentation' });
    if (vm.worksheetsOption === vm.WORKSHEETS.CURRENT) {
      return [{
        worksheetId: $state.params.worksheetId,
        screenshotURL: makeWorksheetURL(vm.workbookId, vm.worksheetId, presentationLinkType, true),
        linkURL: makeWorksheetURL(vm.workbookId, vm.worksheetId, vm.linkType, true)
      }];
    } else {
      return _.map(sqWorkbookStore.worksheets, function(worksheet: any) {
        return {
          worksheetId: worksheet.worksheetId,
          screenshotURL: makeWorksheetURL(vm.workbookId, worksheet.worksheetId, presentationLinkType, true),
          linkURL: makeWorksheetURL(vm.workbookId, worksheet.worksheetId, vm.linkType, true)
        };
      });
    }
  }

  /**
   * Generate a Workbench URL to a specified Worksheet, with either full or view-only access
   *
   * @param {String} workbookId - ID of the Workbook
   * @param {String} worksheetId - ID of the Worksheet
   * @param {Object} linkType - One of vm.LINK_TYPES
   * @param {Boolean} absolute - true if an absolute URL should be returned, false otherwise
   * @returns {String} Returns a relative URL, or undefined if linkType is 'none'
   */
  function makeWorksheetURL(workbookId, worksheetId, linkType, absolute) {
    let state = APP_STATE.WORKSHEET;

    if (linkType.value === 'none') {
      return;
    }

    if (linkType.value === 'viewOnly') {
      state = APP_STATE.VIEW_WORKSHEET;
    } else if (linkType.value === 'presentation') {
      state = APP_STATE.PRESENT_WORKSHEET;
    }

    return $state.href(state, {
      workbookId,
      worksheetId
    }, {
      absolute
    });
  }
}
