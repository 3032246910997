import angular from 'angular';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';

angular.module('Sq.TrendViewer').controller('ReferenceCapsuleIconCtrl', ReferenceCapsuleIconCtrl);

function ReferenceCapsuleIconCtrl(
  sqInvestigateActions: InvestigateActions,
  sqTrendCapsuleSetStore: TrendCapsuleSetStore) {
  const vm = this;
  vm.iconClass = 'icon fc fc-search-pattern';
  vm.editItem = editItem;

  /**
   * Edits the item
   */
  function editItem() {
    sqInvestigateActions.loadToolForEdit(sqTrendCapsuleSetStore.findItem(vm.item.isChildOf).id);
  }
}
