import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { IconWithPopoverTooltip } from '@/hybrid/core/IconWithPopoverTooltip.molecule';

const reportContentVisualizationToggleBindings = bindingsDefinition({
  isReact: prop<boolean>(),
  setIsReact: prop<(react: boolean) => void>()
});

/**
 * Provides checkboxes to switch content between different visualization types
 */
export const ReportContentVisualizationToggle: SeeqComponent<typeof reportContentVisualizationToggleBindings> = (props) => {
  const { isReact, setIsReact } = props;
  const { t } = useTranslation();

  return <div className="flexRowContainer pl10 pr10 pt10">
    <div className="flexColumnContainer">
      {t('REPORT.CONTENT.INTERACTIVE')}
      <IconWithPopoverTooltip content={t('REPORT.CONTENT.INTERACTIVE_TOOLTIP')} />
    </div>
    <div className="tn-group flexColumnContainer flexSpaceBetween ml10">
      <Checkbox
        id="reactOff"
        isChecked={!isReact}
        label="NO"
        onChange={() => setIsReact(false)}
        type="radio"
      />
      <Checkbox
        id="reactOn"
        isChecked={isReact}
        label="YES"
        onChange={() => setIsReact(true)}
        type="radio"
      />
    </div>
  </div>;
};
