import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { AdministrationStore } from '@/administration/administration.store';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { CELL_TYPES, TableColumn } from '@/hybrid/core/Table.atom';
import { ModalService } from '@/services/modal.service';
import { UtilitiesService } from '@/services/utilities.service';
import { LEGACY_GUEST_USERNAME } from '@/administration/administration.module';
import { NotificationsService } from '@/services/notifications.service';
import { AdministrationActions } from '@/administration/administration.actions';
import userRemoveModalTemplate from '@/administration/user.remove.modal.html';
import userAddModalTemplate from '@/administration/user.add.modal.html';
import { AuthenticationService } from '@/services/authentication.service';
import { ServerSideFilteringAndPaginatingTable } from '@/hybrid/core/ServerSideFilteringAndPaginatingTable.molecule';
import { LicenseManagementStore } from '@/licenseManagement/licenseManagement.store';
import { LicenseManagementActions } from '@/licenseManagement/licenseManagement.actions';
import { ButtonWithManagedSpinner } from '@/hybrid/core/ButtonWithManagedSpinner.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { EditUserModal } from '@/hybrid/administration/EditUserModal.molecule';

const userTableBindings = bindingsDefinition({
  sqAdministrationStore: injected<AdministrationStore>(),
  sqModal: injected<ModalService>(),
  sqUtilities: injected<UtilitiesService>(),
  sqNotifications: injected<NotificationsService>(),
  sqAdministrationActions: injected<AdministrationActions>(),
  sqAuthentication: injected<AuthenticationService>(),
  sqLicenseManagementActions: injected<LicenseManagementActions>(),
  sqLicenseManagementStore: injected<LicenseManagementStore>()
});

export const UserTable: SeeqComponent<typeof userTableBindings> = () => {
  const {
    sqAdministrationStore,
    sqModal,
    sqUtilities,
    sqNotifications,
    sqAdministrationActions,
    sqAuthentication,
    sqLicenseManagementActions,
    sqLicenseManagementStore
  } = useInjectedBindings(userTableBindings);

  const { t } = useTranslation();
  const [selectedIds, setSelectedIds] = useState([]);

  useFlux(sqAdministrationStore);
  const users = sqAdministrationStore.users;
  useFlux(sqLicenseManagementStore);
  const canAddUsers = sqLicenseManagementStore.canAddUsers();

  const tableLoading = sqAdministrationStore.userTableLoading;

  useEffect(() => {
    sqAdministrationActions.loadTable();
  }, []);

  type ModalType = 'editUser' | 'none';
  const [modalShown, setModalShown] = useState<ModalType>('none');
  const [editUser, setEditUser] = useState();
  const closeModal = () => setModalShown('none');

  const openUserEditModal = (user) => {
    setModalShown('editUser');
    setEditUser(user);
  };

  const renderEditCell = item => (
    <div className="cursorPointer" onClick={() => openUserEditModal(item)}>
      <Icon icon="fa-pencil" testId={`editUser_${item.id}`} />
      <span className="sq-text-primary pl5">{t('EDIT')}</span>
    </div>
  );

  const columns: TableColumn[] = [
    {
      accessor: 'id',
      sortable: false,
      filterable: false,
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 40, maxWidth: 40 }
    }, {
      accessor: 'email',
      searchProperty: 'emailSearch',
      header: 'ADMIN.USER.EMAIL',
      cellStyle: { wordWrap: 'break-word' }
    }, {
      accessor: 'firstName',
      searchProperty: 'firstNameSearch',
      header: 'ADMIN.USER.FIRST_NAME',
      cellStyle: { minWidth: 75, wordWrap: 'break-word' }
    }, {
      accessor: 'lastName',
      searchProperty: 'lastNameSearch',
      header: 'ADMIN.USER.LAST_NAME',
      cellStyle: { minWidth: 75, wordWrap: 'break-word' }
    }, {
      accessor: 'datasourceName',
      searchProperty: 'datasourceNameSearch',
      header: 'ADMIN.USER.DIRECTORY',
      cellStyle: { minWidth: 75, wordWrap: 'break-word' }
    }, {
      accessor: 'isAdmin',
      sortable: false,
      searchProperty: 'isAdmin',
      header: 'ADMIN.USER.ADMINISTRATOR',
      cellStyle: { width: 85, maxWidth: 95 },
      cellType: CELL_TYPES.CHECKMARK
    }, {
      accessor: 'isEnabled',
      sortable: false,
      searchProperty: 'isEnabled',
      header: 'ADMIN.USER.STATUS',
      cellStyle: { width: 100, maxWidth: 105 },
      cellType: CELL_TYPES.ENABLED_DISABLED
    }, {
      accessor: 'isActive',
      sortable: false,
      searchProperty: 'isLoggedIn',
      header: 'ADMIN.USER.LOGGED_IN',
      cellStyle: { width: 85, maxWidth: 95 },
      cellType: CELL_TYPES.CHECKMARK
    }, {
      accessor: 'id',
      cellStyle: { width: 70, maxWidth: 70 },
      cellRenderFunction: renderEditCell
    }];

  const manageSelectedIds = (item) => {
    const itemIndex = _.indexOf(selectedIds, item.id);
    if (itemIndex > -1) {
      setSelectedIds([..._.pull(selectedIds, item.id)]);
    } else {
      setSelectedIds([...selectedIds, item.id]);
    }
  };

  const isLegacyGuestUser = user => user.username === LEGACY_GUEST_USERNAME;

  const enableUsers = (enable) => {
    const [legacyUsers, ids] = _.partition<any>(selectedIds,
      userId => enable && isLegacyGuestUser(_.find(users, { id: userId })));
    if (_.some(legacyUsers)) {
      sqNotifications.infoTranslate('ADMIN.USER.LEGACY_GUEST_ENABLE_NOT_ALLOWED');
      if (!_.some(users)) {
        return;
      }
    }

    sqAdministrationActions.enableUsers(enable, ids)
      .then(() => sqLicenseManagementActions.fetchLicense())
      .finally(() => setSelectedIds([]));
  };

  const filterUsers = (option, field) => {
    sqAdministrationActions.setFilter(field, option.value);
    sqAdministrationActions.loadTable();
  };

  const sortUsers = (field, asc) => {
    sqAdministrationActions.setSort(field, asc);
    sqAdministrationActions.loadTable();
  };

  const removeUsers = () => {
    if (_.isEmpty(selectedIds)) {
      sqNotifications.warnTranslate('ADMIN.USER.SELECTION_REQUIRED');
      return Promise.resolve();
    }

    return sqModal.open({
      animation: true,
      controller: 'UserRemoveCtrl',
      controllerAs: 'ctrl',
      template: userRemoveModalTemplate,
      resolve: {
        ids: _.constant(selectedIds)
      }
    }).result
      .catch(sqUtilities.handleModalOpenErrors)
      .then(() => sqLicenseManagementActions.fetchLicense())
      .finally(() => setSelectedIds([]));
  };

  const refreshUsers = () => {
    setSelectedIds([]);
    sqAdministrationActions.resetTable();
    return sqAdministrationActions.loadTable()
      .then(() => sqLicenseManagementActions.fetchLicense());
  };

  const addUser = () => {
    return sqModal.open({
      animation: true,
      controller: 'UserAddCtrl',
      controllerAs: 'ctrl',
      template: userAddModalTemplate,
      resolve: {
        availableDomains: sqAuthentication.fetchAuthenticationProviders()
      }
    }).result
      .catch(sqUtilities.handleModalOpenErrors)
      .then(() => sqLicenseManagementActions.fetchLicense());
  };

  const renderTableLoadingIndicator = (
    <div className="flexColumnContainer flexCenter pt50 pb50">
      <i className="fa fa-spinner fa-pulse fa-5x sq-text-primary" />
    </div>);

  const nothingSelected = _.isEmpty(selectedIds);

  return (
    <div className="height-maximum">
      <div className="flexColumnContainer mb5 flexSpaceBetween flexAlignCenter">
        <div>
          <TextButton
            id="enableSelected"
            extraClassNames="mr5"
            onClick={() => enableUsers(true)}
            disabled={!canAddUsers || nothingSelected}
            label={canAddUsers ? 'ADMIN.USER.ENABLE_SELECTED' : 'ADMIN.USER.ADD_USER_OVER_LIMIT'} />

          <TextButton
            id="disableSelected"
            extraClassNames="mr5"
            onClick={() => enableUsers(false)}
            disabled={nothingSelected}
            label="ADMIN.USER.DISABLE_SELECTED" />

          <TextButton
            id="removeSelected"
            onClick={removeUsers}
            disabled={nothingSelected}
            label="ADMIN.USER.REMOVE_SELECTED" />
        </div>
        <div className="flexColumnContainer">
          <ButtonWithManagedSpinner
            buttonProps={{ id: 'refreshUsers', extraClassNames: 'mr5' }}
            action={refreshUsers}
            icon="fa-repeat"
            label="ADMIN.USER.REFRESH_LIST"
            spinnerIconProps={{ large: true, type: 'text' }} />

          <TextButton
            id="addUser"
            variant="theme"
            onClick={addUser}
            disabled={!canAddUsers}
            label={canAddUsers ? 'ADMIN.USER.ADD_USER' : 'ADMIN.USER.ADD_USER_OVER_LIMIT'}
            iconStyle="white"
            icon="fa-plus fa-lg" />
        </div>
      </div>

      <div className="height-maximum overflowAuto pb100 width-maximum flexRowContainer flexSpaceBetween">
        <ServerSideFilteringAndPaginatingTable
          testId="userAdministrationTable"
          tableClass="fixedHeaderTable"
          rowSelectionCallback={manageSelectedIds}
          filterCallback={filterUsers}
          selectAllCallback={setSelectedIds}
          selectedIds={selectedIds}
          sortCallback={sortUsers}
          columns={columns}
          items={users}
          store={sqAdministrationStore}
          actions={sqAdministrationActions}
          loadTable={sqAdministrationActions.loadTable}
        />
        {tableLoading && renderTableLoadingIndicator}
      </div>

      {modalShown === 'editUser' &&
      <EditUserModal
        user={editUser}
        onClose={closeModal}
      />}

    </div>);
};
