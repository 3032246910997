import _ from 'lodash';
import angular from 'angular';
import { BaseItemStoreService } from '@/trendData/baseItemStore.service';
import { ITEM_DATA_STATUS, ITEM_TYPES } from '@/trendData/trendData.module';
import { API_TYPES } from '@/main/app.constants';

angular.module('Sq.TrendData').store('sqTrendTableStore', sqTrendTableStore);

export type TrendTableStore = ReturnType<typeof sqTrendTableStore>['exports'];

function sqTrendTableStore(sqBaseItemStore: BaseItemStoreService) {
  const store = {
    exports: {
      get tables() {
        return this.state.get('items');
      }
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @returns {Object} The dehydrated items.
     */
    dehydrate() {
      const props = sqBaseItemStore.PROPS_TO_DEHYDRATE.concat(['binConfig', 'outputUnits', 'stack']);
      return {
        items: _.chain(this.state.get('items'))
          .filter(sqBaseItemStore.shouldDehydrateItem)
          .map(item => _.pick(item, props))
          .value()
      };
    },

    /**
     * Rehydrates the tables.
     *
     * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.set('items', _.map(dehydratedState.items, _.bind(function(item) {
        return this.createTable(item.id, item.name, item.binConfig, _.omit(item, ['id', 'name']));
      }, this)));
    },

    handlers: {
      TREND_ADD_TABLE: 'addTable',
      TREND_TABLE_RESULTS_SUCCESS: 'addData',
      TREND_TABLE_SET_BIN_PROPERTIES: 'setBinProperties',
      TREND_TABLE_RESET_BINS: 'resetBins',
      TREND_TABLE_SET_OUTPUT_UNITS: 'setOutputUnits'
    },

    /**
     * Adds a Table item to the specified panel.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.id- The id of the table
     * @param {String} payload.binConfig - binConfig for tables with more than one series
     * @param {String} payload.calculationType =- calculation type for this table
     * @param {String} [payload.color] - optional color.
     */
    addTable(payload) {
      this.state.push('items',
        this.createTable(payload.id, payload.name, _.get(payload, 'binConfig', {}),
          _.pick(payload, ['color', 'calculationType', 'stack'])));
    },

    /**
     * Creates a Table item.
     *
     * @param {String} id - The id of the table
     * @param {String} name - The name of the table
     * @param {Object} [binConfig] - Object of properties for bins (sub-groups), if present
     * @param {Object[]} [props] - Array of properties to be set on the item.
     * @return {Object} an table item.
     */
    createTable(id, name, binConfig, props) {
      _.assign(props, { type: API_TYPES.TABLE });
      return this.createItem(id, name, ITEM_TYPES.TABLE, _.assign({
        data: [],
        dataStatus: ITEM_DATA_STATUS.INITIALIZING,
        statusMessage: '',
        stack: _.get(props, 'stack', false),
        binConfig
      }, props));
    },

    /**
     * Adds data once it's available.
     *
     * @param {Object} payload - Object container
     * @param {Number[]} payload.tableData - The data
     * @param {Number[]} payload.signalName - The name of the signal that is being aggregated (the y-axis)
     */
    addData(payload) {
      const cursor = this.getItemCursor(payload.id);
      cursor.set('data', payload.tableData);
      cursor.set('signalName', payload.signalName);
      cursor.set('categories', payload.categories);
    },

    /**
     * Sets properties on one or more table bins (aka sub-groups)
     *
     * @param {Object} payload - Object container
     * @param {String} payload.id - The id of the table
     * @param {String} payload.bins - Subgroups and associated properties to set
     * @param {String} payload.bins.id - name of bin
     * @param {String} payload.bins.color - color to set
     * @param {Boolean} payload.bins.visible - whether the bin is visible
     */
    setBinProperties(payload) {
      const cursor = this.getItemCursor(payload.id);
      cursor.set('binConfig', _.merge({}, cursor.get('binConfig'), payload.bins));
    },

    /**
     * Resets all bin configuration properties for a table
     *
     * @param {Object} payload - Object container
     * @param {String} payload.id - The id of the table
     */
    resetBins(payload) {
      const cursor = this.getItemCursor(payload.id);
      cursor.set('binConfig', {});
    },

    /**
     * Sets output units for the table
     *
     * @param {Object} payload - Object container
     * @param {String} payload.id - The id of the table
     * @param {String} payload.outputUnits - The output units
     */
    setOutputUnits(payload) {
      const cursor = this.getItemCursor(payload.id);
      cursor.set('outputUnits', payload.outputUnits);
    }
  };

  return sqBaseItemStore.extend(store);
}
