import angular from 'angular';
import jQuery from 'jquery';
import moment from 'moment-timezone';
import _ from 'lodash';
import { AuthenticationService } from '@/services/authentication.service';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { LicenseManagementStore } from '@/licenseManagement/licenseManagement.store';
import { APP_STATE, APPSERVER_API_CONTENT_TYPE } from '@/main/app.constants';
import { LICENSE_STATUS } from '@/licenseManagement/licenseManagement.module';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { UtilitiesService } from '@/services/utilities.service';
import { LicenseManagementActions } from '@/licenseManagement/licenseManagement.actions';

angular.module('Sq.LicenseManagement')
  .controller('LicenseUploadController', LicenseUploadController);

function LicenseUploadController(
  $scope: ng.IScope,
  $state: ng.ui.IStateService,
  $translate: ng.translate.ITranslateService,
  FileUploader,
  sqAuthentication: AuthenticationService,
  sqWorkbenchStore: WorkbenchStore,
  sqLicenseManagementActions: LicenseManagementActions,
  sqLicenseManagementStore: LicenseManagementStore,
  sqSystemConfiguration: SystemConfigurationService,
  sqUtilities: UtilitiesService
) {

  const vm = this;
  vm.doDisplay = doDisplay;
  vm.infoTextTranslation = infoTextTranslation;
  vm.launchWorkbench = launchWorkbench;
  vm.uploadLicense = uploadLicense;
  vm.LICENSE_STATUS = LICENSE_STATUS;
  vm.selectFile = selectFile;
  // Set up file uploader
  vm.uploader = new FileUploader({
    url: '/api/system/license',
    autoUpload: false,
    removeAfterUpload: true
  });
  vm.uploader.onAfterAddingFile = onAfterAddingFile;
  vm.uploader.onSuccessItem = onSuccessItem;
  vm.uploader.onErrorItem = onErrorItem;

  vm.adminContactName = sqSystemConfiguration.adminContactName;
  vm.adminContactEmail = sqSystemConfiguration.adminContactEmail;

  $scope.$listenTo(sqLicenseManagementStore, syncLicenseStore);
  $scope.$listenTo(sqWorkbenchStore, ['currentUser'], syncUser);

  /**
   * Syncs the Workbenchstore. If we don't have a user we call setCurrentUser to restore
   * the user from the token.
   */
  function syncUser() {
    vm.currentUser = sqWorkbenchStore.currentUser;
  }

  /**
   * Syncs the LicenseManagement Store.
   */
  function syncLicenseStore() {
    let date;

    vm.licenseStatus = sqLicenseManagementStore.licenseStatus;

    if (sqLicenseManagementStore.license) {
      vm.licenseLevel = sqLicenseManagementStore.license.level;
      vm.licenseValidity = sqLicenseManagementStore.license.validity;
      vm.days = sqLicenseManagementStore.license.daysToExpiration;
      date = moment(sqLicenseManagementStore.license.validThrough, 'YYYY-MM-DD');
      vm.expirationDate = date.format('LL');
      vm.host = sqLicenseManagementStore.license.hostname;
      vm.additionalFeatures = sqLicenseManagementStore.license.additionalFeatures;
      vm.hasAdditionalFeatures = !_.isEmpty(vm.additionalFeatures);
      vm.userLimitRestrict = sqLicenseManagementStore.license.userLimitRestrict;
    }

    vm.isTrialLicense = sqLicenseManagementStore.isTrial;

    if (vm.licenseStatus === LICENSE_STATUS.VALID || vm.licenseStatus === LICENSE_STATUS.EXPIRED) {
      vm.uploadTextTranslationKey = 'LICENSE.UPLOAD_OTHER';
    }

    if (vm.uploadComplete) {
      if (_.includes([LICENSE_STATUS.NONE, LICENSE_STATUS.UNKNOWN, LICENSE_STATUS.EXPIRED], vm.licenseStatus)) {
        vm.displayErrorStatus = true;
        vm.uploadComplete = false;
      } else {
        vm.showSuccess = true;
      }
    }
  }

  /**
   * Takes the user to the workbench view.
   */
  function launchWorkbench() {
    $state.go(APP_STATE.WORKBOOKS);
  }

  /**
   * Helper to determine if the upload area should be shown
   * @returns {boolean|*}
   */
  function doDisplay() {
    return vm.currentUser.isAdmin && !vm.showSuccess;
  }

  /**
   * Finds the appropriate translation key for the current license status.
   *
   * @returns {String} the translation key to use for the translation.
   */
  function infoTextTranslation() {
    let emailLink = sqUtilities.buildEmailLink(vm.adminContactEmail, vm.adminContactName,
      'LICENSE.EMAIL_LICENSE_REQUEST.SUBJECT', 'LICENSE.EMAIL_LICENSE_REQUEST.BODY');
    const showWarning = vm.licenseStatus === LICENSE_STATUS.SHOW_ADMIN_LICENSE_WARNING || vm.licenseStatus === LICENSE_STATUS.SHOW_LICENSE_WARNING;
    if (!vm.currentUser.isAdmin) {
      if (vm.licenseStatus === LICENSE_STATUS.NONE) {
        const notSetUp = $translate.instant('LICENSE.NOT_SET_UP');
        return notSetUp.concat('<br>', $translate.instant('LICENSE.NO_LICENSE_INFO_TEXT', { CONTACT: emailLink }));
      }

      if (vm.licenseStatus === LICENSE_STATUS.EXPIRED) {
        return $translate.instant('LICENSE.EXPIRED_LICENSE_INFO_TEXT', { CONTACT: emailLink });
      }

      if (vm.licenseStatus === LICENSE_STATUS.UNKNOWN) {
        emailLink = sqUtilities.buildEmailLink(vm.adminContactEmail, vm.adminContactName,
          'LICENSE.EMAIL_LICENSE_REQUEST.SUBJECT', 'LICENSE.EMAIL_LICENSE_REQUEST.CHECK');
        const returnString = $translate.instant('LICENSE.LICENSE_PROBLEM');
        return returnString.concat('<br>',
          $translate.instant('LICENSE.USER_INSTRUCTIONS_UNKNOWN', { CONTACT: emailLink }));
      }
    } else {
      if (showWarning) {
        if (vm.showSuccess) {
          return $translate.instant('LICENSE.STATUS',
            { LEVEL: vm.licenseLevel, LICENSE_VALIDITY: vm.licenseValidity, DAYS: vm.days }) + userLimitInfo(
            'LICENSE.USER_LIMIT');
        } else {
          return $translate.instant('LICENSE.EXPIRING_LICENSE_INFO_TEXT_PART1',
            { LEVEL: vm.licenseLevel, DAYS: vm.days }) + userLimitInfo('LICENSE.USER_LIMIT_TRIAL');
        }
      }

      if (vm.licenseStatus === LICENSE_STATUS.NONE || vm.licenseStatus === LICENSE_STATUS.UNKNOWN) {
        return $translate.instant('LICENSE.HAVE_LICENSE');
      }

      if (vm.licenseStatus === LICENSE_STATUS.EXPIRED) {
        return $translate.instant('LICENSE.EXPIRED_LICENSE_UPLOAD', { LEVEL: vm.licenseLevel });
      }

      if (vm.licenseStatus === LICENSE_STATUS.VALID) {
        if (vm.showSuccess) {
          return $translate.instant('LICENSE.STATUS', {
            LEVEL: vm.licenseLevel,
            LICENSE_VALIDITY: vm.licenseValidity,
            EXPIRATION: vm.expirationDate,
            DAYS: vm.days
          }) + userLimitInfo('LICENSE.USER_LIMIT');
        } else {
          emailLink = sqUtilities.buildEmailLink('support@seeq.com', 'support@seeq.com');
          return $translate.instant('LICENSE.VALID_LICENSE_INFO',
            {
              LEVEL: vm.licenseLevel,
              DAYS: vm.days,
              EXPIRATION: vm.expirationDate,
              CONTACT: emailLink
            }) +
            userLimitInfo('LICENSE.USER_LIMIT') +
            '<br>' + $translate.instant('LICENSE.VALID_LICENSE_QUESTIONS', { CONTACT: emailLink });
        }
      }
    }
  }

  function userLimitInfo(key) {
    return vm.userLimitRestrict ? ' ' + $translate.instant(key, { LIMIT: vm.userLimitRestrict }) : '';
  }

  /**
   * Called when clicking the drag'n'drop file area to make sure the "Choose file" dialog opens.
   */
  function selectFile() {
    $scope.$applyAsync(function() {
      jQuery('#selectFile').click();
    });
  }

  /**
   * Uploader callback which is called when a file is either selected or dropped on the drop-area.
   * The callback will set the necessary request headers for the file and upload it.
   *
   * @param {Object} fileItem - The item to be uploaded
   */
  function onAfterAddingFile(fileItem) {
    vm.uploadError = false;
    vm.displayErrorStatus = false;
    fileItem.headers.Accept = APPSERVER_API_CONTENT_TYPE;
    sqAuthentication.addCsrfHeader(fileItem.headers);
    vm.fileName = fileItem._file.name;
    vm.fileItem = fileItem;
  }

  /**
   * Executes the license upload.
   */
  function uploadLicense() {
    vm.uploader.uploadItem(vm.fileItem);
  }

  /**
   * Uploader callback which is called when an item is uploaded successfully.
   * Once the license was uploaded, the license status is requested to ensure a valid
   * license is now available. Once we verify a valid license workbench can be launched.
   *
   */
  function onSuccessItem() {
    vm.uploadComplete = true;
    vm.fileName = undefined;
    sqLicenseManagementActions.fetchLicense();
  }

  /**
   * Uploader callback which is called when an item is uploaded successfully.
   * The callback will extract file names and headers from the response object.
   *
   * @param {Object} fileItem - The item which was uploaded
   * @param {Object} response - The response for the upload request
   */
  function onErrorItem(fileItem, response) {
    vm.uploadError = true;
    vm.fileName = undefined;
    vm.uploadErrorMessage = response.statusMessage;
  }
}
