import React, { useState } from 'react';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { AddFormulaParameterModal } from '@/hybrid/formula/AddFormulaParameterModal.molecule';
import { FormulaEditorParam } from '@/hybrid/formula/FormulaParametersTable.molecule';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';

interface AddParameterBtnAndModalInterface {
  addParameter: (input: FormulaEditorParam, originalParameter: FormulaEditorParam) => void;
  parameters: FormulaEditorParam[];
  tooltip: string;
  testId?: string;
}

export const AddParameterBtnAndModal: React.FunctionComponent<AddParameterBtnAndModalInterface> = ({
  parameters,
  addParameter,
  tooltip,
  testId = 'addVariableBtn'
}) => {
  const [showFunctionParamModal, setShowFunctionParamModal] = useState(false);
  return (
    <>
      <HoverTooltip text={tooltip}>
        <div>
          <TextButton
            testId={testId}
            icon="fa-plus"
            size="sm"
            label="ASSET_GROUP_EDITOR.ADD_VARIABLE"
            onClick={() => setShowFunctionParamModal(true)} />
        </div>
      </HoverTooltip>
      {showFunctionParamModal &&
      <AddFormulaParameterModal
        parameters={parameters}
        onSave={addParameter}
        onClose={() => setShowFunctionParamModal(false)}
        testId="functionParamModal" />}
    </>);
};

