import _ from 'lodash';
import moment from 'moment-timezone';

import tinycolor from 'tinycolor2';
import { STRIPED_CELL_COLOR, TableBuilderHeaderType } from '@/hybrid/tableBuilder/tableBuilder.module';
import { FormatOptions, NumberHelperService } from '@/core/numberHelper.service';
import { ITEM_TYPES } from '@/trendData/trendData.module';
import { UtilitiesService } from '@/services/utilities.service';
import { ANALYSIS_COLORS } from '@/trendViewer/trendViewer.module';
import { NotificationsService } from '@/services/notifications.service';

export type TableBuilderHelperService = ReturnType<typeof sqTableBuilderHelper>;
export const NULL_PLACEHOLDER = '-';

export function sqTableBuilderHelper(
  $injector: ng.auto.IInjectorService,
  $translate: ng.translate.ITranslateService,
  sqNumberHelper: NumberHelperService,
  sqUtilities: UtilitiesService,
  sqNotifications: NotificationsService
) {
  const service = {
    formatHeader,
    formatMetricValue,
    computeCellColors,
    computeCellStyle,
    getStripedColor,
    hasNumericAndStringItems,
    getMostReadableIconType,
    copyToClipboard
  };

  return service;

  /**
   * Formats the text for a column header.
   *
   * @param headerInfo - Header formatting information
   * @param headerInfo.type - One of TableBuilderHeaderType enumeration
   * @param headerInfo.format - Format string to use if .type is a format involving date/times
   * @param property - Custom property
   * @param startTime - Start time for the cell
   * @param endTime - End time for the cell
   * @param timezone - timezone to run the table for
   * @returns The formatted header
   */
  function formatHeader(headerInfo: { type: TableBuilderHeaderType, format: string },
    property: string | undefined, startTime: number, endTime: number, timezone: { name: string }): string {
    const formatDate = (date, isStart) => _.isNil(date) ?
      $translate.instant(`TABLE_BUILDER.${isStart ? 'STARTS_OUT_OF_RANGE' : 'ENDS_OUT_OF_RANGE'}`) :
      moment.utc(date).tz(timezone.name).format(headerInfo.format);
    if (headerInfo.type === TableBuilderHeaderType.None) {
      return '';
    } else if (headerInfo.type === TableBuilderHeaderType.CapsuleProperty) {
      return property ?? '';
    } else if (headerInfo.type === TableBuilderHeaderType.Start) {
      return formatDate(startTime, true);
    } else if (headerInfo.type === TableBuilderHeaderType.End) {
      return formatDate(endTime, false);
    } else {
      return formatDate(startTime, true) + ' - ' + formatDate(endTime, false);
    }
  }

  /**
   * Formats a metric value for display in the scorecard table.
   *
   * @param value - The value of the metric
   * @param formatOptions - the format options to be used when formatting the cell
   * @param [column] - One of the columns from COLUMNS
   * @returns The formatted value
   */
  function formatMetricValue(value: any, formatOptions: FormatOptions, column: any = {}): string {
    if (_.isNil(value)) {
      return column.style === 'string' ? '' : NULL_PLACEHOLDER;
    } else if (_.isNumber(value)) {
      return sqNumberHelper.formatNumber(value, {
        ...formatOptions,
        format: column.format || formatOptions?.format,
        isPercent: column.style === 'percent'
      });
    } else {
      return value;
    }
  }

  /**
   * Computes the style for cell.
   *
   * @param backgroundColor - Background color. Ignored if #ffffff.
   * @param textColor - Text color
   * @param textStyle - An array with text styles
   * @param textAlign - Text align
   * @param priorityBackgroundColor - Overwrites the backgroundColor. Ignored if #ffffff.
   * @param fallbackBackgroundColor - Fallback background color. It is used if backgroundColor and
   *   priorityBackgroundColor are not present
   * @returns An object containing HTML styles attributes and values
   */
  function computeCellStyle(
    backgroundColor: string = undefined,
    textColor: string = '#000',
    textStyle: string[] = [],
    textAlign: string = 'left',
    priorityBackgroundColor: string = undefined,
    fallbackBackgroundColor: string = undefined
  ): {
    backgroundColor: string,
    color: string,
    fontWeight: string,
    fontStyle: string,
    textDecoration: string,
    textAlign: string
  } {
    const hasPriorityColor = !_.isUndefined(priorityBackgroundColor) && priorityBackgroundColor !== '#ffffff';

    const resultBackgroundColor = hasPriorityColor ? priorityBackgroundColor :
      !_.isUndefined(backgroundColor) && backgroundColor !== '#ffffff' ? backgroundColor :
        fallbackBackgroundColor ?? '#fff';

    // check readability when we have metric color (priorityBackgroundColor) and overwrite text color if necessary
    const resultTextColor = hasPriorityColor && !tinycolor.isReadable(priorityBackgroundColor, textColor) ?
      (tinycolor(priorityBackgroundColor).isDark() ? '#fff' : '#000') : textColor;

    return {
      backgroundColor: resultBackgroundColor,
      color: resultTextColor,
      // 'bold' as 'bold' to avoid TS2322: Type 'string' is not assignable to type 'FontWeightProperty'.
      fontWeight: _.includes(textStyle, 'bold') ? 'bold' as 'bold' : 'normal' as 'normal',
      fontStyle: _.includes(textStyle, 'italic') ? 'italic' : 'normal',
      textDecoration: _.filter(textStyle, textDecoration =>
        _.includes(['overline', 'line-through', 'underline'], textDecoration)).join(' '),
      textAlign
    };
  }

  /**
   * Computes a foreground that contrasts with that color so that it is readable.
   *
   * @param backgroundColor - The background color. Default to white if not specified.
   * @return Styles for the background and foreground colors
   */
  function computeCellColors(backgroundColor: string): { backgroundColor: string, color: string } {
    backgroundColor = backgroundColor ?? '#fff';
    return {
      backgroundColor,
      color: tinycolor(backgroundColor).isDark() ? '#fff' : '#000'
    };
  }

  /**
   * Gets the striped color based on the current state of isTableStriped and the row index passed in
   */
  function getStripedColor(isStriped: boolean, rowIndex: number): string | undefined {
    return isStriped && rowIndex % 2 === 0 ? STRIPED_CELL_COLOR : undefined;
  }

  function hasNumericAndStringItems(items: { itemType: string }[], itemType: ITEM_TYPES) {
    return _.chain(items)
      .filter(item => item.itemType === itemType)
      .partition(signal => sqUtilities.isStringSeries(signal))
      .every(group => !_.isEmpty(group))
      .value();
  }

  function getMostReadableIconType(background: string = '#fff') {
    const mostReadable = tinycolor.mostReadable(background,
      [ANALYSIS_COLORS.DARK_PRIMARY, ANALYSIS_COLORS.LIGHT_COLOR]);
    return mostReadable.toString() === ANALYSIS_COLORS.DARK_PRIMARY ? 'theme' : 'theme-light';
  }

  function copyToClipboard(tableBuilderRef: any) {
    try {
      if (!tableBuilderRef) {
        sqNotifications.errorTranslate('TABLE_BUILDER.COPY_TO_CLIPBOARD_EMPTY');
      } else {
        const selection = window.getSelection();
        selection.removeAllRanges();
        const range = document.createRange();
        range.selectNodeContents(tableBuilderRef);
        selection.addRange(range);
        document.execCommand('copy');
        selection.removeAllRanges();
        sqNotifications.successTranslate('TABLE_BUILDER.COPY_TO_CLIPBOARD_SUCCESS');
      }
    } catch (err) {
      sqNotifications.errorTranslate('TABLE_BUILDER.COPY_TO_CLIPBOARD_ERROR');
    }
  }
}
