import angular from 'angular';
import template from './byYValue.component.html';

angular.module('Sq.Investigate').component('sqByYValue', {
  controller: 'ByYValueCtrl',
  template,
  bindings: {
    id: '<',
    startNumber: '<',
    originalParameters: '<'
  }
});
