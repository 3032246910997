import angular, { IPromise } from 'angular';
import _ from 'lodash';
import bind from 'class-autobind-decorator';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { ProjectsApi } from 'sdk/api/ProjectsApi';
import { WorkbookActions } from '@/workbook/workbook.actions';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { UtilitiesService } from '@/services/utilities.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { WorkbookService } from '@/workbook/workbook.service';
import { WorksheetsService } from '@/worksheets/worksheets.service';
import { HttpHelpersService } from '@/services/httpHelpers.service';
import { DEFAULT_WORKBOOK_STATE, WORKBOOK_DISPLAY } from '@/workbook/workbook.module';
import { APPSERVER_API_PREFIX, HOME_SCREEN_TABS, SEARCH_ITEM_LOCATIONS } from '@/main/app.constants';
import { ITEM_TYPES, tabFolders } from '@/hybrid/homescreen/homescreen.module';
import { Feature, LicenseManagementStore } from '@/licenseManagement/licenseManagement.store';
import { NotificationsService } from '@/services/notifications.service';
import { ItemsApi } from 'sdk/api/ItemsApi';
import moment from 'moment';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { FoldersApi } from 'sdk/api/FoldersApi';
import { HomeScreenStore } from '@/hybrid/homescreen/homescreen.store';
import { SeeqNames } from '@/main/app.constants.seeqnames';

@bind
export class HomeScreenUtilitiesService {
  constructor(
    private sqAuthorization,
    private $injector: ng.auto.IInjectorService,
    private $translate: ng.translate.ITranslateService,
    private $q: ng.IQService,
    private $http: ng.IHttpService,
    private $window: ng.IWindowService,
    private sqSystemConfiguration: SystemConfigurationService,
    private sqWorkbenchStore: WorkbenchStore,
    private sqUtilities: UtilitiesService,
    private sqDateTime: DateTimeService,
    private sqWorkbook: WorkbookService,
    private sqWorksheets: WorksheetsService,
    private sqHttpHelpers: HttpHelpersService,
    private sqLicenseManagementStore: LicenseManagementStore,
    private sqProjectsApi: ProjectsApi,
    private sqItemsApi: ItemsApi,
    private sqWorksheetStore: WorksheetStore,
    private sqFoldersApi: FoldersApi,
    private sqHomeScreenStore: HomeScreenStore,
    private flux: ng.IFluxService
  ) {
  }

  /**
   * Returns a placeholder folder object representing the "root" workbench level.
   *
   * @returns {Promise} that resolves with an object representing the root folder.
   */
  getWorkbenchFolderPlaceholder() {
    return this.$translate('WORKBENCH.ROOT')
      .then(name => ({
        name,
        id: null,
        workbookId: null
      }));
  }

  /**
   * Returns the expected icon for the provided item.
   *
   * @param {Object} item - and object representing a workbench explorer item (Analysis, Topic or Folder)
   * @returns {String} representing the icon css class.
   */
  getItemIcon(item) {
    let iconClass = 'fc-analysis';

    if (_.get(item, 'type') === ITEM_TYPES.FOLDER) {
      iconClass = 'fc-folder';
    } else if (_.get(item, 'type') === ITEM_TYPES.PROJECT) {
      iconClass = 'fc-seeq-datalab';
    } else if (_.get(item, 'type') === ITEM_TYPES.TOPIC) {
      iconClass = 'fc-report';
    }
    if (!this.sqAuthorization.canModifyWorkbook(item, false)) {
      iconClass += '-lock';
    }
    return iconClass;
  }

  /**
   * Returns a translated breadcrumb name if translationKey is available.
   *
   * @param {String} crumb - a folder from the breadcrumbs for translating
   * @returns {String} representing a translated/original folder name in the breadcrumb.
   */
  translateBreadcrumb(crumb) {
    if (!crumb) {
      return;
    }
    if (crumb.translationKey) {
      return this.$translate.instant(`HOME_SCREEN.TABS.${crumb.translationKey}`);
    } else {
      return crumb.name;
    }
  }

  /**
   * Get the collection of workbench items (folders, topics, analyses).
   *
   * @param {String} [filter] - Specify what to be returned: 'owner' for all workbooks owned by the current user,
   *   'shared' for all workbooks shared with the current user or 'all' (or undefined) to return workbooks for all users
   * @param {String} [sortOrder] - Specify the column and sort direction by which to sort the workbooks. Format is the
   *   field name followed by "asc" or "desc". For example, "updatedAt desc"
   * @param {String} [folderId] - Id of the folder
   * @param {Boolean} [isArchived] - True to return only archived items, false to return only unarchived items
   * @param {String} [cancellationGroup] - Id to use for request cancellation
   * @return {Promise} Resolves with the collection of names and ids for the workbooks
   */
  getWorkbenchItems(filter?, sortOrder?, folderId?, isArchived = false, cancellationGroup?) {
    const params = {
      filter,
      sortOrder,
      limit: 10000, // Currently pagination is not supported in workbooks
      cancellationGroup
    };

    if (folderId) {
      _.assign(params, { folderId });
    }
    if (isArchived) {
      _.assign(params, { isArchived });
    }

    return this.$http.get<any>(APPSERVER_API_PREFIX + '/folders', { params })
      .then(response => _.map(response.data.content, this.toWorkbook));
  }

  /**
   * Helper function that creates a workbook object to be dispatched to a store
   *
   * @param {Object} workbook - The workbook from the API
   * @returns {Object} Workbook for a store
   */
  toWorkbook(workbook) {
    return _.assign(workbook, {
      workbookId: workbook.id,
      createdAt: this.sqDateTime.parseISODate(workbook.createdAt).valueOf(),
      updatedAt: this.sqDateTime.parseISODate(workbook.updatedAt).valueOf()
    });
  }

  /**
   * Creates a workbook for the current user with default workbook state initialized.
   *
   * @param {String} name - The workbook name
   * @param {String} data - A stringified object to be stored on the workbook data property
   * @param {String} [branchFrom] - The ID of the workbook from which to branch if wanting to copy an existing
   *   workbook
   * @param {String} [folderId] - Id of the folder
   */
  createWorkbook(name, branchFrom, folderId, type) {
    return this.$http.post<any>(APPSERVER_API_PREFIX + '/workbooks', { name, branchFrom, folderId, type })
      .then(response => response.data)
      .then(data => this.sqWorkbook.set(data.id, DEFAULT_WORKBOOK_STATE).then(() => data))
      .then((workbook) => {
        this.$injector.get<WorkbookActions>('sqWorkbookActions').setWorkbookDisplayMode(WORKBOOK_DISPLAY.EDIT);
        return this.toWorkbook(workbook);
      });
  }

  /**
   * Returns the specified folder object. Note that this does not return the contents of the folder.
   *
   * @param {String} folderId - id of the folder to get
   * @returns {angular.IPromise<any>} that resolves with the folder object. Also ensures that the folder object is
   *   assigned a workbookId as every item is assumed to have a workbookId. Ideally we'd just refactor this to id or
   *   itemId but that seems a little overwhelming right now.
   */
  getFolder(folderId) {
    return this.$http.get<any>(APPSERVER_API_PREFIX + '/folders/' + folderId)
      .then(response => response.data)
      .then((data) => {
        return _.assign(data, { workbookId: data.id });
      });
  }

  /**
   * Get the properties of a Workbook and the Workbook's worksheets
   *
   * @param {String} workbookId - The GUID of the workbook to be fetched
   * @param {Object} [options] - object container for options
   * @param {Boolean} [options.skipWorksheets = false] - true if this workbook is in presentation mode
   * @param {Boolean} [options.includeWorkstepId = false] - true to have the returned worksheets include the current
   * workstep ID
   * @param {Boolean} [options.includeArchivedWorksheets = false] - true to return the workbook's archived worksheets
   * @return {promise} resolves with the name and worksheets of the workbook
   */
  getWorkbook(workbookId, { skipWorksheets = false, includeWorkstepId = false, includeArchivedWorksheets = false }:
    { skipWorksheets?: boolean, includeWorkstepId?: boolean, includeArchivedWorksheets?: boolean } = {}) {

    return this.getWorkbookOnly(workbookId)
      .then((workbook) => {
        if (_.get(workbook, 'isArchived', false)) {
          includeArchivedWorksheets = true;
        }
        return workbook;
      })
      .then((workbook) => {
        const worksheetsPromise = skipWorksheets ? this.$q.resolve([]) :
          this.sqWorksheets.getWorksheets(workbookId, includeWorkstepId, includeArchivedWorksheets);

        return worksheetsPromise
          .then((worksheets) => {
            // Workbooks that were archived prior to R53 didn't have their unarchived worksheets archived along with
            // them. If a workbook existed before R53 and we open it from the trash folder, we need to return its
            // unarchived worksheets instead
            if (_.isEmpty(worksheets) && !skipWorksheets && includeArchivedWorksheets) {
              return this.sqWorksheets.getWorksheets(workbookId, includeWorkstepId, false)
                .then((worksheets) => {
                  return _.assign(this.toWorkbook(workbook), { worksheets });
                });
            }
            return _.assign(this.toWorkbook(workbook), { worksheets });
          });
      });
  }

  /**
   * Gets a Workbook without its worksheets
   *
   * @param {String} workbookId - The GUID of the workbook to be fetched
   * @return {promise} resolves with the workbook
   */
  getWorkbookOnly(workbookId) {
    return this.$http.get(APPSERVER_API_PREFIX + '/workbooks/' + workbookId).then(_.property('data'));
  }

  getWorkbenchItem(itemId: string) {
    return this.sqFoldersApi.getFolders({ ids: [itemId] })
      .then(response => _.get(response, 'data.content[0]'));
  }

  /**
   * Creates a new folder.
   *
   * @param {String} name - the name of the folder.
   * @param {String} [parentFolderId] - the id of the parent folder. If no parent is provided the folder is created at
   *   the workbench level.
   * @param {String} [branchFrom] - the id of the folder to duplicate, if desired.
   * @returns {Promise} that resolves once the folder has been created and the response was converted to
   *   a proper "workbook" object
   */
  createFolder(name, parentFolderId, branchFrom = undefined, ownerId = undefined) {
    return this.$http.post(APPSERVER_API_PREFIX + '/folders', { name, parentFolderId, branchFrom, ownerId })
      .then(_.property('data'))
      .then(this.toWorkbook);
  }

  createProject(name, folderId) {
    return this.sqProjectsApi.createProject({ name, folderId })
      .then(_.property('data'))
      .then(this.toWorkbook);
  }

  /**
   * Moves an item to the specified folder.
   *
   * @param {String} itemId - id of the item to move.
   * @param {String} destinationFolderId - the id of the folder the item should be moved to.
   * @returns {Promise} that resolves when the item was moved.
   */
  moveItem(itemId, destinationFolderId) {
    return this.$http.post(APPSERVER_API_PREFIX + '/folders/' + destinationFolderId + '/' + itemId, undefined);
  }

  /**
   * This function is used to move an item to the root level "folder" aka "home".
   *
   * @param {String} assignedFolder - id of the folder the item currently is assigned to.
   * @param {String} itemId - the id of the item to move.
   */
  removeContentFromFolder(assignedFolder, itemId) {
    return this.$http.delete(APPSERVER_API_PREFIX + '/folders/' + assignedFolder + '/' + itemId);
  }

  /**
   * Sets a property on a workbook
   *
   * @param {String} property - The name of the property
   * @param {String} workbookId - The ID of the workbook
   * @param {*} value - The value of the property
   * @returns {Promise} - Resolves when the property is set
   */
  setProperty(property, workbookId, value) {
    // Description is an optional property
    if (property === 'Description' && _.isEmpty(value)) {
      return this.sqItemsApi.deleteProperty({ id: workbookId, propertyName: property })
        .catch(_.noop);
    } else {
      return this.sqItemsApi.setProperty({ value }, { id: workbookId, propertyName: property });
    }
  }

  /**
   * Sets the archive property on a workbook.
   *
   * @param {String} workbookId - The ID of the workbook
   * @param {Boolean} isArchived - The value of the property
   * @returns {Promise} - Resolves when the property is set
   */
  setArchived(workbookId, isArchived) {
    return this.setProperty(SeeqNames.Properties.Archived, workbookId, isArchived);
  }

  /**
   * Toggles an item's favorite status.
   *
   * @param {Object} item - representing a workbench explorer item.
   * @param {String} item.workbookId - id of the item.
   * @returns {Promise} - resolves when the favorite status was set.
   */
  toggleIsPinned(item) {
    if (_.get(item, 'isPinned')) {
      return this.$http.delete(APPSERVER_API_PREFIX + '/items/pinned/' + item.workbookId);
    } else {
      return this.$http.post(APPSERVER_API_PREFIX + '/items/pinned/' + item.workbookId, undefined);
    }
  }

  /**
   * Gets the recently accessed list for the workbook.
   *
   * @param {String} workbookId - The ID of the workbook
   * @return {Promise} Resolves with the list of recently accessed items.
   */
  getRecentlyAccessed(workbookId) {
    return this.$http.get<any>(`${APPSERVER_API_PREFIX}/workbooks/${workbookId}/recently-accessed`,
      { transformResponse: _.concat(this.$http.defaults.transformResponse, this.sqHttpHelpers.addAssetsProperty) })
      .then(response => response.data);
  }

  /**
   * Adds an item to the recently accessed list for the workbook.
   *
   * @param {String} workbookId - The ID of the workbook
   * @param {String} id - The ID of the item that was recently accessed
   * @return {Promise} Resolves with the new list of recently accessed items.
   */
  addRecentlyAccessed(workbookId, id) {
    return this.$http.post(`${APPSERVER_API_PREFIX}/workbooks/${workbookId}/recently-accessed/${id}`, {},
      { transformResponse: _.concat(this.$http.defaults.transformResponse, this.sqHttpHelpers.addAssetsProperty) })
      .then(response => response.data);
  }

  /**
   * Returns true if item is a folder, otherwise false (for workbooks/topics)
   *
   * @param {Object} item - Object to test
   * @param {String} item.type - Property containing the item type
   * @returns {boolean} True if folder, otherwise false
   */
  isFolder(item) {
    return _.get(item, 'type') === ITEM_TYPES.FOLDER;
  }

  isProject(item) {
    return _.get(item, 'type') === ITEM_TYPES.PROJECT;
  }

  /**
   * Opens a project in a new window.
   * @param {String} projectId - the ID of the project to open
   * @returns {Promise} a promise that resolves once the new project window has been opened or once an error
   * notification has been displayed
   */
  openProject(projectId: string): IPromise<any> {
    if (this.sqLicenseManagementStore.hasValidFeature(Feature.Data_Lab)) {
      return this.$q.resolve(this.$window.open(`/data-lab/${projectId.toUpperCase()}`))
        .then(() => this.updateOpenedAt(projectId));
    } else {
      return this.$injector.get<NotificationsService>('sqNotifications')
        .warnTranslate('WORKBENCH.DATA_LAB_DISABLED');
    }
  }

  /**
   * Logs the current time the time when the user last accessed the item.
   * @param {String} id - the ID of the item that was opened
   */
  updateOpenedAt(id: string) {
    if (id) {
      this.sqItemsApi.setItemUserAttributes(
        { openedAt: moment().toISOString() },
        { id });
    }
  }

  /**
   * Filters out:
   * 'Corporate' when the Everyone group is disabled
   * admin only options for non-admins
   * 'Recent' if recent is false
   * @param {Object} constToFilter - either of the constants HOME_SCREEN_TABS_AND_TRANSLATION_KEYS or
   *   SEARCH_LOCATION_OPTIONS
   * @param {Boolean} recent - filter out 'recent'
   */
  getFilteredLocations(constToFilter, recent = true) {
    if (_.isArray(constToFilter)) {
      let filteredConst = this.sqSystemConfiguration.everyoneGroupEnabled ? constToFilter :
        _.reject(constToFilter, (option) => {
          return _.toUpper(option.value) === HOME_SCREEN_TABS.CORPORATE;
        });
      filteredConst = this.sqAuthorization.isAdmin() ? filteredConst : _.reject(filteredConst, { adminOnly: true });
      filteredConst = recent ? filteredConst : _.reject(filteredConst, (option) => {
        return _.toUpper(option.value) === HOME_SCREEN_TABS.RECENT;
      });

      return filteredConst;
    }
    return constToFilter;
  }

  /**
   * Creates a default folder name.
   */
  getDefaultFolderName() {
    return `${this.$translate.instant('ITEM_TYPES.FOLDER')} ${this.sqDateTime.formatTime(new Date(),
      this.sqWorksheetStore.timezone)}`;
  }

  getTabFolder(folderId) {
    if (!_.isUndefined(folderId) && (folderId !== HOME_SCREEN_TABS.USERS || this.sqAuthorization.isAdmin())) {
      if (this.sqHomeScreenStore.tabFolders[folderId]) {
        return Promise.resolve(this.sqHomeScreenStore.tabFolders[folderId]) as any;
      } else {
        return this.sqFoldersApi.getFolder({ folderId: this.getFolderRoot(folderId) })
          .then((response: any) => {
            // response.data returns the string "null" when the folder doesn't exist
            if (response.data !== 'null') {
              const name = this.getFolderName(folderId);
              const folder = _.assign({}, response.data,
                { name: `${this.$translate.instant(name)}` });
              this.flux.dispatch('SET_TAB_FOLDER', { key: folderId, folder });
              return folder;
            }
          })
          .finally(() => this.sqHomeScreenStore.tabFolders[folderId]);
      }
    } else {
      return Promise.resolve(undefined);
    }
  }

  getTabFolderName(id) {
    if (_.isNil(id)) {
      return Promise.resolve(undefined);
    }

    let folderPromise;
    const tabFolderKeys = _.keys(this.sqHomeScreenStore.tabFolders);
    const tabKey = _.findKey(this.sqHomeScreenStore.tabFolders,
      tabFolder => tabFolder ? this.sqUtilities.equalsIgnoreCase(tabFolder.id, id) : undefined);

    if (!_.isUndefined(tabKey)) {
      return Promise.resolve(tabKey);
    } else {
      const useTabFolders = tabFolders(this.sqAuthorization.isAdmin(), this.sqSystemConfiguration.everyoneGroupEnabled);
      if (useTabFolders.length > tabFolderKeys.length) {
        const missingTabFolders = _.difference(useTabFolders, tabFolderKeys);
        folderPromise = Promise.all(_.map(missingTabFolders, tabFolderName => this.getTabFolder(tabFolderName)));
      } else {
        folderPromise = Promise.resolve();
      }

      return folderPromise.then(() => {
        return _.findKey(this.sqHomeScreenStore.tabFolders,
          tabFolder => tabFolder ? this.sqUtilities.equalsIgnoreCase(tabFolder.id, id) : undefined);
      });
    }
  }

  getFolderRoot(currentTab) {
    switch (currentTab) {
      case HOME_SCREEN_TABS.USERS:
        return SEARCH_ITEM_LOCATIONS.USERS;
      case HOME_SCREEN_TABS.MY_FOLDER:
        return SEARCH_ITEM_LOCATIONS.MY_FOLDER;
      case HOME_SCREEN_TABS.SHARED:
        return SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC;
      case HOME_SCREEN_TABS.CORPORATE:
        return SEARCH_ITEM_LOCATIONS.CORPORATE;
      default:
        return null;
    }
  }

  getFolderName(currentTab) {
    switch (currentTab) {
      case HOME_SCREEN_TABS.USERS:
        return 'HOME_SCREEN.LOCATION.USERS';
      case HOME_SCREEN_TABS.MY_FOLDER:
        return 'HOME_SCREEN.LOCATION.MY_FOLDER';
      case HOME_SCREEN_TABS.SHARED:
        return 'HOME_SCREEN.LOCATION.SHARED_OR_PUBLIC';
      case HOME_SCREEN_TABS.CORPORATE:
        return 'HOME_SCREEN.LOCATION.CORPORATE';
      default:
        return null;
    }
  }
}
