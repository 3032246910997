import React, { useState } from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import ValueWithUnits, {
  ValueWithUnitsItem
} from '@/hybrid/trend/ValueWithUnits.atom';
import { getFormFieldProps, getValidationFunction } from '@/hybrid/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { DurationTimeUnit } from '@/main/app.constants';
import { FormError } from '@/hybrid/core/FormError.atom';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import classNames from 'classnames';

export interface ValueWithUnitsIF extends ValidatingFormComponent<ValueWithUnitsItem> {
  component: 'ValueWithUnitsFormComponent';
  min: number;
  propName?: string;
  minIsExclusive?: boolean;
  required?: boolean;
  availableUnits?: DurationTimeUnit[];
  insideModal?: boolean;
  customError?: boolean;
  disabled?: boolean;
  icon?: string;
  label?: string;
  step?: number;
  inlineLabel?: string;
  warningMessage?: string;
  customErrorText?: string;
  customErrorParams?: {};
  includeAutoCheckbox?: boolean;
  autoCheckboxValue?: boolean;
  largeValueInput?: boolean;
  fullWidth?: boolean;
  autoCheckboxOnChange?: (value: boolean) => any;
  autoCheckboxId?: string;
  includeRemoveButton?: boolean;
  removeIconOnClick?: () => any;
}

export const ValueWithUnitsFormComponent: React.FunctionComponent<ValueWithUnitsIF> = (props) => {
  const { t } = useTranslation();
  const {
    label,
    value,
    tooltip,
    icon,
    name,
    validation,
    inlineLabel,
    required = true,
    min,
    testId,
    minIsExclusive = false,
    warningMessage = '',
    extendValidation,
    customErrorText,
    customErrorParams,
    includeAutoCheckbox = false,
    autoCheckboxValue = false,
    autoCheckboxOnChange = _.noop,
    autoCheckboxId,
    includeRemoveButton = false,
    removeIconOnClick,
    extraClassNames
  } = props;

  const [showError, setShowError] = useState(false);

  const defaultValidation = (valueWithUnits) => {
    const { units, value } = valueWithUnits || {};
    if (required || !_.isNil(units)) {
      if (!_.isNil(min)) {
        return !(minIsExclusive ? value > min : value >= min);
      } else {
        return false;
      }
    }
    return false;
  };

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();

  return (
    <div className={classNames('flexRowContainer', extraClassNames)} data-testid={testId}>
      {(label || tooltip) && (
        <div className="flexColumnContainer">
          {label && <span>{t(label)}</span>}
          {tooltip &&
          <HoverTooltip text={tooltip} delay={500} placement="top">
          <span>
            <Icon icon={icon || 'fa-question-circle'} large={true} extraClassNames="text-interactive pl5" />
          </span>
          </HoverTooltip>}
        </div>
      )}
      <div className={classNames('flexColumnContainer',
        { pb15: warningMessage || (showError && customErrorText) })}
        id={`${testId}_valueWithUnits`}>
        {inlineLabel && <div className="mr8">{t(inlineLabel)}</div>}
        <Field name={name} validate={appliedValidation}>
          {({ input, meta }) => {
            const properProps = getFormFieldProps(formState, input, meta, props);
            setTimeout(() => setShowError(properProps.showError));

            return (
              <ValueWithUnits {...properProps} isValid={!showError} defaultValue={value} />
            );
          }}
        </Field>
        {includeAutoCheckbox &&
        <Checkbox
          id={autoCheckboxId}
          isChecked={autoCheckboxValue}
          classes="ml10"
          label="AUTO"
          onChange={autoCheckboxOnChange}
        />
        }
        {includeRemoveButton &&
        <HoverTooltip text="REMOVE">
          <span data-testid="removeMaxDurationButton" onClick={removeIconOnClick}>
            <Icon icon="fa-close" extraClassNames="ml10 cursorPointer fa-xlg" />
          </span>
        </HoverTooltip>
        }
      </div>
      {warningMessage &&
      <HoverTooltip text={warningMessage} placement="top">
          <span>
            <Icon icon="fa-exclamation-circle" large={true} extraClassNames="fw sq-text-warning pl5" />
          </span>
      </HoverTooltip>}
      {showError && customErrorText &&
      <FormError errorText={customErrorText} extraClassNames="mtn5" errorParameters={customErrorParams} />}
    </div>
  );
};
