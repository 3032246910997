import _ from 'lodash';
import angular from 'angular';
import { WORKSTEP_SCHEMA_VERSION, WorkstepUpgraderService } from '@/worksteps/workstepUpgrader.service';
import { UtilitiesService } from '@/services/utilities.service';
import { WORKSHEET_WITH_NO_WORKSTEPS, WORKSTEP_PUSH_DISABLED } from '@/worksteps/worksteps.module';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

angular.module('Sq.Worksteps')
  .factory('sqWorksteps', sqWorksteps);

export type WorkstepsService = ReturnType<typeof sqWorksteps>;

function sqWorksteps(
  $q: ng.IQService,
  $http: ng.IHttpService,
  sqWorkstepUpgrader: WorkstepUpgraderService,
  sqUtilities: UtilitiesService
) {

  // All workstep requests are tracked so that they can be canceled when navigating to another worksheet so as to
  // ensure the response doesn't leak over to the new worksheet.
  const cancellationOptions = {
    cancellationGroup: 'worksteps',
    cancelOnServer: false // Still want the workstep request to finish on the appserver side
  };

  const service = {
    get,
    push,
    getCurrentWorkstepId,
    workstepFromResponse
  };

  return service;

  /**
   * Get workstep state and set it as the current workstep on the server, if specified.
   *
   * @param {String} workbookId - The workbook ID
   * @param {String} worksheetId - The worksheet ID
   * @param {String} [workstepId] - The workstep ID. If undefined then the current workstep will be found.
   * @param {Boolean} [setAsCurrent] - True if it should be set as the current workstep on the server. Only
   *   applicable if `workstepId` is set.
   * @returns {Promise} A promise that will resolve with state for the workstep or reject if workstep state is not
   *   available
   */
  function get(workbookId, worksheetId, workstepId?, setAsCurrent = false) {
    let promise;
    if (workstepId) {
      if (setAsCurrent) {
        promise = setCurrentWorkstep(workbookId, worksheetId, workstepId);
      } else {
        promise = $q.resolve(workstepId);
      }
    } else {
      promise = getCurrentWorkstepId(workbookId, worksheetId);
    }

    return promise
      .then(function(currentWorkstepId) {
        return $http.get(APPSERVER_API_PREFIX + '/workbooks/' + workbookId + '/worksheets/' + worksheetId +
          '/worksteps/' + currentWorkstepId, cancellationOptions);
      })
      .then(response => workstepFromResponse(response.data));
  }

  /**
   * Push workstep state and make it the current workstep. If state is pushed when the current
   * workstep is not the last workstep, then the current workstep is first pushed, followed
   * by the workstep state.
   *
   * @param {String} workbookId - The workbook ID
   * @param {String} worksheetId - The worksheet ID
   * @param {Object} worksheetState - The worksheet state object
   * @returns {Promise} A promise that will resolve with state for the pushed workstep or reject if workstep state
   *   could not be pushed
   */
  function push(workbookId, worksheetId, worksheetState) {
    if (sqUtilities.headlessRenderMode() || !sqUtilities.workbookLoaded()
      || sqUtilities.isViewOnlyWorkbookMode || sqUtilities.isPresentationWorkbookMode) {
      return $q.reject(WORKSTEP_PUSH_DISABLED);
    }

    return $http.post(APPSERVER_API_PREFIX + '/workbooks/' + workbookId + '/worksheets/' + worksheetId + '/worksteps',
      {
        data: JSON.stringify({
          version: WORKSTEP_SCHEMA_VERSION,
          state: worksheetState
        })
      }, cancellationOptions)
      .then(response => workstepFromResponse(response.data));
  }

  /**
   * Gets the ID of a worksheet's current workstep.
   *
   * @param {String} workbookId - a workbook ID
   * @param {String} worksheetId - a worksheet ID
   * @returns {Promise} a promise that resolves with the workstep ID or WORKSHEET_WITH_NO_WORKSTEPS if the worksheet
   *   has no worksteps.
   */
  function getCurrentWorkstepId(workbookId, worksheetId) {
    return $http
      .get(APPSERVER_API_PREFIX + '/workbooks/' + workbookId + '/worksheets/' + worksheetId, cancellationOptions)
      .then(function(response: any) {
        if (response.data.workstep) {
          return response.data.workstep;
        } else {
          return $q.reject(WORKSHEET_WITH_NO_WORKSTEPS);
        }
      });
  }

  /**
   * Sets the current workstep.
   *
   * @param {String} workbookId - The workbook ID
   * @param {String} worksheetId - The worksheet ID
   * @param {String} workstepId - The workstep ID
   * @returns {Promise} A promise that resolves with the workstepId
   */
  function setCurrentWorkstep(workbookId, worksheetId, workstepId) {
    if (sqUtilities.headlessRenderMode() || !sqUtilities.workbookLoaded()
      || sqUtilities.isViewOnlyWorkbookMode || sqUtilities.isPresentationWorkbookMode) {
      return $q.resolve(workstepId);
    }

    return $http.post(APPSERVER_API_PREFIX + '/workbooks/' + workbookId + '/worksheets/' + worksheetId +
      '/worksteps/' + workstepId + '/current', {}, cancellationOptions)
      .then(_.property('data.id'));
  }

  /**
   * Extracts the state, next, and previous steps from a workstep response. Rejects with an error if the JSON state
   * cannot be parsed.
   *
   * @param {Object} workstep - Response from an API call that returns workstep information
   * @returns {Object} The parsed workstep information or a rejected promise if state cannot be parsed.
   */
  function workstepFromResponse(workstep) {
    const parsedData = _.attempt(JSON.parse, workstep.data);
    if (!_.isError(parsedData)) {
      return sqWorkstepUpgrader.apply(parsedData.state, parsedData.version)
        .then(newState => (
          {
            previous: workstep.previous,
            current: {
              id: workstep.id,
              state: newState
            },
            next: workstep.next,
            last: workstep.last
          }));
    } else {
      return $q.reject(parsedData.message);
    }
  }
}
