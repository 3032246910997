import _ from 'lodash';
import angular from 'angular';
import { CapsuleTimeColorMode, TrendStore } from '@/trendData/trend.store';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { DurationStore } from '@/trendData/duration.store';
import { ItemDecoratorService } from '@/trendViewer/itemDecorator.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { NumberHelperService } from '@/core/numberHelper.service';
import { TrendActions } from '@/trendData/trend.actions';
import { InvestigateStore } from '@/investigate/investigate.store';
import { UtilitiesService } from '@/services/utilities.service';
import {
  CAPSULE_PANEL_TREND_COLUMNS,
  TREND_PANELS,
  TREND_SIGNAL_STATS,
  TREND_VIEWS
} from '@/trendData/trendData.module';
import { RedactionService } from '@/services/redaction.service';
import { ColumnHelperService } from '@/trendViewer/columnHelper.service';
import { CAPSULES_PER_PAGE } from '@/services/formula.service';
import { ScatterPlotStore } from '@/scatterPlot/scatterPlot.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.module';
import { SCATTER_PLOT_VIEWS } from '@/scatterPlot/scatterPlot.module';

angular.module('Sq.TrendViewer').controller('CapsulesPanelCtrl', CapsulesPanelCtrl);

function CapsulesPanelCtrl(
  $scope: ng.IScope,
  sqTrendStore: TrendStore,
  sqTrendCapsuleSetStore: TrendCapsuleSetStore,
  sqTrendCapsuleStore: TrendCapsuleStore,
  sqTrendSeriesStore: TrendSeriesStore,
  sqDurationStore: DurationStore,
  sqItemDecorator: ItemDecoratorService,
  sqDateTime: DateTimeService,
  sqNumberHelper: NumberHelperService,
  sqTrendActions: TrendActions,
  sqInvestigateStore: InvestigateStore,
  sqUtilities: UtilitiesService,
  sqRedaction: RedactionService,
  sqColumnHelper: ColumnHelperService,
  sqScatterPlotStore: ScatterPlotStore,
  sqWorksheetStore: WorksheetStore
) {
  const vm = this;
  const spacer = { key: 'spacer', type: 'spacer' };

  vm.TREND_SIGNAL_STATS = TREND_SIGNAL_STATS;
  vm.INACTIVE_COLOR = 'gray';
  vm.camelCase = _.camelCase;
  vm.trendColumns = CAPSULE_PANEL_TREND_COLUMNS;
  vm.toggleItemSelected = toggleItemSelected;
  vm.selectItems = selectItems;
  vm.toggleColumn = handleToggleColumn;
  vm.toggleStatisticsColumn = sqTrendActions.toggleStatisticsColumn;
  vm.PREVIOUS = 'previous';
  vm.NEXT = 'next';
  vm.toggleSort = sortBy => sqTrendActions.togglePanelSort(TREND_PANELS.CAPSULES, sortBy);
  vm.isColumnEnabled = column => sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, column.key);
  vm.zoomOutToCapsules = sqTrendActions.zoomOutToCapsules;
  vm.unselectAllCapsules = sqTrendActions.unselectAllCapsules;
  vm.isStatisticsColumnEnabled = isStatisticsColumnEnabled;
  vm.getCellStyle = getCellStyle;
  vm.getColumnClass = getColumnClass;
  vm.enabledColumns = enabledColumns;
  vm.getColumnValue = sqColumnHelper.getColumnValue;
  vm.getColumnTooltip = getColumnTooltip;
  vm.seriesFromCapsuleExists = seriesFromCapsuleExists;
  vm.anyInTableSelected = items => _.some(items, 'selected');
  vm.gotoPage = gotoPage;
  vm.addPropertiesColumn = column => sqTrendActions.addPropertiesColumn(TREND_PANELS.CAPSULES, column);
  vm.removePropertiesColumn = column => sqTrendActions.removePropertiesColumn(TREND_PANELS.CAPSULES, column);
  vm.isPresentationMode = sqUtilities.isPresentationWorkbookMode;
  vm.isColumnRedacted = column => isColumnRedacted(column);
  vm.isStringSignal = series => sqUtilities.isStringSeries(series);
  vm.getColorFromCapsuleProperty = getColorFromCapsuleProperty;
  vm.showColorCapsulePropertyDot = showColorCapsulePropertyDot;

  $scope.$listenTo(sqTrendStore, syncPanelVars);
  $scope.$listenTo(sqTrendSeriesStore, syncPanelVars);
  $scope.$listenTo(sqTrendCapsuleStore, syncPanelVars);
  $scope.$listenTo(sqInvestigateStore, ['activeTool'], syncPanelVars);
  $scope.$listenTo(sqScatterPlotStore, ['colorCapsuleProperty', 'capsulePropertyColorsConfig', 'plotView'],
    syncPanelVars);

  /**
   * Syncs sqTrendStore, sqTrendSeriesStore, sqTrendSeriesStore, and sqTrendCapsuleStore with view-model properties.
   */
  function syncPanelVars() {
    vm.sort = sqTrendStore.getPanelSort(TREND_PANELS.CAPSULES);
    vm.isTrendViewCapsuleTime = sqTrendStore.isTrendViewCapsuleTime();
    vm.xAxisTimestamp = sqDateTime.formatDuration(sqTrendStore.xValue);
    vm.pickingMode = sqInvestigateStore.isCapsulePickingMode;
    vm.activeTool = sqInvestigateStore.activeTool;
    vm.toolName = sqInvestigateStore.activeToolName || 'CAPSULE_GROUP';
    vm.items = sqItemDecorator.decorate(sqTrendCapsuleStore.items,
      { singleSelect: vm.singleSelect, pickingMode: vm.pickingMode });
    vm.anySelected = sqTrendCapsuleStore.selectedCapsules.length > 0;
    vm.isChainView = sqTrendStore.view === TREND_VIEWS.CHAIN;
    vm.seriesColumns = _.chain(sqTrendSeriesStore.primarySeries)
      .thru(series => sqItemDecorator.decorate(series))
      .map(series => ({ key: series.id, title: series.name, type: 'series', series }))
      .value();
    vm.showCapsuleTimeChildIcon = vm.isTrendViewCapsuleTime && sqTrendStore.capsuleTimeColorMode !== CapsuleTimeColorMode.Signal;
    vm.showScatterPlotChildIcon = sqWorksheetStore.view.key === WORKSHEET_VIEW.SCATTER_PLOT
      && sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT
      && !!sqScatterPlotStore.colorCapsuleProperty;

    vm.isLoading = sqTrendStore.capsulePanelIsLoading;
    vm.offset = sqTrendStore.capsulePanelOffset;
    vm.hasNext = sqTrendStore.capsulePanelHasNext;
    vm.hasPrevious = vm.offset > 0;
    vm.isPaginated = vm.items.length && (vm.hasPrevious || vm.hasNext);

    vm.propertyColumns = _.map(sqTrendStore.propertyColumns(TREND_PANELS.CAPSULES), (column: any) =>
      ({ ...column, title: column.propertyName, shortTitle: column.propertyName }));
    vm.customColumns = _.map(sqTrendStore.customColumns(TREND_PANELS.CAPSULES), (column: any) =>
      _.assign({ series: sqTrendSeriesStore.findItem(column.referenceSeries) },
        _.find(TREND_SIGNAL_STATS, ['key', column.statisticKey]), column));
    vm.conditions = sqTrendCapsuleSetStore.items;
  }

  /**
   * Returns all enabled columns, grouped by series so that the statistic for a given series are displayed next to
   * that series header.
   *
   * @return {Object[]} Array of enabled columns
   */
  function enabledColumns() {
    return _.filter(vm.trendColumns, vm.isColumnEnabled)
      .concat(_.filter(vm.propertyColumns, vm.isColumnEnabled))
      .concat(spacer as any)
      .concat(_.flatMap(vm.seriesColumns,
        column => _.filter(vm.customColumns, ['referenceSeries', column.key]).concat([column])));
  }

  /**
   * Toggle selection of the item specified.
   *
   * @param {object} item - Item on which to toggle selection
   */
  function toggleItemSelected(item, $event) {
    if (item.notFullyVisible) {
      return;
    }

    if (vm.singleSelect) {
      vm.selectItems(item, $event);
    } else {
      sqTrendActions.toggleItemSelected(item);
    }
  }

  /**
   * Get the tooltip constant to display for a specified cell (column/item)
   *
   * @param {object} column - Column information
   * @param {string} [column.accessor] - When present, the key name to use for the value in object
   * @param {string} column.key - The key name to use for the value in object, when .accessor is not present
   * @param {object} item - Item for this to get a tooltip
   * @returns {string} translation key to use for the tooltip, or undefined if no tooltip
   */
  function getColumnTooltip(column, item) {
    const notFullyVisible = _.get(item, 'notFullyVisible');

    if (notFullyVisible) {
      return sqTrendStore.isTrendViewCapsuleTime() ?
        'CAPSULES_PANEL.ZOOM_OUT_TO_VIEW_MORE_CAPSULE_VIEW' :
        'CAPSULES_PANEL.ZOOM_OUT_TO_VIEW_MORE';
    }

    const value = _.get(item, column.accessor || column.key);
    if (_.isNil(value)) {
      return 'CAPSULES_PANEL.NO_VALUE';
    }
  }

  /**
   * Determines if a series from capsule exists for the supplied series and capsule
   *
   * @param {Object} series - The series of interest
   * @param {Object} capsule - The capsule of interest
   * @returns {Boolean} True if it exists
   */
  function seriesFromCapsuleExists(series, capsule) {
    if (!series || !capsule) {
      return false;
    }

    return _.some(sqTrendSeriesStore.capsuleSeries, {
      interestId: series.id,
      capsuleId: capsule.id
    });
  }

  /**
   * Goes to either the previous or next page in the list of results.
   *
   * @param {String} direction - Either previous or next
   */
  function gotoPage(direction) {
    if ((direction === vm.PREVIOUS && !vm.hasPrevious) || (direction === vm.NEXT && !vm.hasNext)) {
      return;
    }

    sqTrendActions.setCapsulePanelOffset(vm.offset +
      (direction === vm.PREVIOUS ? -CAPSULES_PER_PAGE : CAPSULES_PER_PAGE));
  }

  /**
   * Returns the style attributes for a cell. If the 'eye' icon is enabled then the series style will be used.
   *
   * @param {Object} series - The series for a cell
   * @param {Object} capsule - The capsule for a cell
   * @returns {Object} Contains the color style
   */
  function getCellStyle(series, capsule) {
    return vm.seriesFromCapsuleExists(series, capsule) ? series.style : {};
  }

  /**
   * Get the CSS class for a column so it can be accessed via system tests. If it is a custom column it uses the
   * translation key since key does not have the calculation in it.
   *
   * @param {Object} column - One of the columns from .enabledColumns()
   * @returns {String} Formatted value
   */
  function getColumnClass(column) {
    return (column.series ? _.camelCase(_.get(column, 'title', '').split('.')[1]) :
      column.key.split('.').pop()) + 'Column';
  }

  /**
   * Determines if a given statics columns is enabled for a given series
   *
   * @param {Object} statistic - One of TREND_SIGNAL_STATS
   * @param {Object} series - The series to be paired with the statistics column
   * @returns {Boolean} True if the statistic is enabled, false if not
   */
  function isStatisticsColumnEnabled(statistic, series) {
    return sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, statistic.key + '.' + series.id);
  }

  function handleToggleColumn(column) {
    // remove if custom property column. The user may easily add it back using properties dropdown
    if (sqTrendActions.isPropertyColumn(column)) {
      sqTrendActions.removePropertiesColumn(TREND_PANELS.CAPSULES, column);
    } else {
      sqTrendActions.toggleColumn(TREND_PANELS.CAPSULES, column.key);
    }
  }

  /**
   * Helper method that does not pass on any event information if in singleSelect mode so as to ignore multi-select
   * mode.
   *
   * @param {Object} item - The item to select
   * @param {Object} $event - The click event
   */
  function selectItems(item, $event) {
    if (item.notFullyVisible) {
      return;
    }

    $event = vm.singleSelect ? {} : $event;
    sqTrendActions.selectItems(item, vm.items, $event);
  }

  /**
   * Checks if this capsule is used for coloring the scatter plot by capsule property
   *
   * @param item - the capsule to check for a capsule property
   * @returns true if there is a capsule property to color by and the capsule has that property, false otherwise
   */
  function showColorCapsulePropertyDot(item) {
    const propertyValue = sqScatterPlotStore.getColorPropertyValueFromCapsule(item);
    return !!sqScatterPlotStore.capsulePropertyColorsConfig
      && !_.isNil(propertyValue)
      && !_.isUndefined(
        sqScatterPlotStore.getCapsulePropertyColor(propertyValue, sqScatterPlotStore.capsulePropertyColorsConfig));
  }

  /**
   * Gets the color to use for the capsule property color dot in the capsules pane when coloring the
   * scatter plot by a capsule property
   *
   * @param item - the capsule to check for a capsule property
   */
  function getColorFromCapsuleProperty(item) {
    const colorsConfig = sqScatterPlotStore.capsulePropertyColorsConfig;
    const propertyValue = sqScatterPlotStore.getColorPropertyValueFromCapsule(item);
    return sqScatterPlotStore.getCapsulePropertyColor(propertyValue, colorsConfig);
  }

  /**
   * Checks if the column's series is redacted
   *
   * @param {Object} column - The column
   * @returns {boolean} - Whether or not the column's series is redacted
   */
  function isColumnRedacted(column) {
    const series = column.series;
    return _.isNil(series) ? false : sqRedaction.isItemRedacted(series);
  }
}
