import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { TrackService } from '@/track/track.service';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { SearchActions } from '@/search/search.actions';
import { NotificationsService } from '@/services/notifications.service';
import { WorkbookStore } from '@/workbook/workbook.store';
import { WorkbookActions } from '@/workbook/workbook.actions';
import { SEARCH_MODES } from '@/search/search.module';
import { FormControl } from 'react-bootstrap';
import { UtilitiesService } from '@/services/utilities.service';
import { Icon } from '@/hybrid/core/Icon.atom';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { ErrorWithBackground } from '@/hybrid/core/ErrorWithBackground.atom';
import { SearchResult } from '@/hybrid/search/SearchResult.molecule';
import { SearchGroup } from '@/hybrid/search/SearchGroup.molecule';
import InfiniteScroll from 'react-infinite-scroller';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { ASSET_GROUP_VIEW } from '@/worksheet/worksheet.module';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { SearchResultAssetIcons } from '@/hybrid/assetGroupEditor/SearchResultAssetIcons.molecule';
import { SearchResultIcons } from '@/hybrid/search/SearchResultIcons.molecule';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.module';
import { SearchFilters } from '@/hybrid/search/SearchFilters.molecule';
import { TextButton } from '@/hybrid/core/TextButton.atom';

const searchWidgetBindings = bindingsDefinition({
  pane: prop<string>(),
  searchTypes: prop<string[]>(),
  iconClasses: prop.optional<string>(),
  scopeIds: prop.optional<string[]>(),
  restrictExploration: prop.optional<boolean>(),
  indicateSelection: prop.optional<boolean>(),
  selectedItemId: prop.optional<string>(),
  onItemClick: prop.optional<(item) => any>(),
  searchResultIcons: prop.optional<(item) => JSX.Element>(),
  showOnlyResults: prop.optional<boolean>(),
  allowAssetReplacement: prop<boolean>(),
  sqSearchActions: injected<SearchActions>(),
  sqWorkbookStore: injected<WorkbookStore>(),
  sqNotifications: injected<NotificationsService>(),
  sqWorkbookActions: injected<WorkbookActions>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqUtilities: injected<UtilitiesService>(),
  sqTrack: injected<TrackService>(),
  sqSystemConfiguration: injected<SystemConfigurationService>(),
  isSelectingAsset: prop.optional<boolean>()
});

export const SearchWidget: SeeqComponent<typeof searchWidgetBindings> = (props) => {
  const {
    pane,
    searchTypes,
    iconClasses,
    scopeIds,
    onItemClick = _.noop,
    allowAssetReplacement,
    searchResultIcons = defaultSearchResultIcons,
    showOnlyResults = false,
    restrictExploration = false,
    indicateSelection = false,
    selectedItemId = undefined,
    isSelectingAsset = false
  } = props;
  const {
    sqSearchActions,
    sqWorkbookStore,
    sqNotifications,
    sqWorksheetStore,
    sqWorkbookActions,
    sqUtilities,
    sqSystemConfiguration,
    sqTrack
  } = useInjectedBindings(searchWidgetBindings);

  const { t } = useTranslation();
  const isAssetGroupView = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.view.key === ASSET_GROUP_VIEW.key);
  const [currentItems, setCurrentItems] = useState([]);
  const [placeholderCategory, setPlaceholderCategory] = useState(undefined);
  const [nameFilter, setNameFilter] = useState('');
  const LIMIT_IN_OVERVIEW = 8;
  const store = sqSearchActions.getStoreForPane(pane);
  const scrollParentRef = useRef(null);

  // NOTE: please do not "simplify" the below to useFlux instead. We're intentionally using useFluxPath to avoid
  // performance hits when typing into the search field.
  const hasNextPage = useFluxPath(store, store => store.hasNextPage);
  const mode = useFluxPath(store, store => store.mode);
  const searching = useFluxPath(store, store => store.searching);
  const isPaginating = useFluxPath(store, store => store.isPaginating);
  const currentAsset = useFluxPath(store, store => store.currentAsset);
  const breadcrumbs = useFluxPath(store, store => store.breadcrumbs);
  const items = useFluxPath(store, store => store.items);
  const isAsyncInitialized = useFluxPath(store, store => store.isAsyncInitialized);

  const {
    pinned,
    recentlyAccessed,
    recentlyAccessedLoadStatus,
    pinnedLoadStatus,
    assetGroups,
    assetGroupsLoadStatus,
    workbookId,
    workbookDisplay
  } = useFlux(sqWorkbookStore);

  useEffect(() => {
    switch (mode) {
      case SEARCH_MODES.TREE:
      case SEARCH_MODES.SEARCH:
        setPlaceholderCategory(_.get(_.last(_.reject(breadcrumbs, ['type', 'VIEW_MODE'])), 'name'));
        setCurrentItems(items);
        break;
      case SEARCH_MODES.PINNED:
        setPlaceholderCategory('SEARCH_DATA.PINNED');
        setCurrentItems(pinned);
        break;
      case SEARCH_MODES.RECENT:
        setPlaceholderCategory('SEARCH_DATA.RECENTLY_ACCESSED');
        setCurrentItems(recentlyAccessed);
        break;
      case SEARCH_MODES.ASSET_GROUPS:
        setPlaceholderCategory('SEARCH_DATA.ASSET_GROUPS');
        setCurrentItems(assetGroups);
        break;
      default:
        setPlaceholderCategory(undefined);
        setCurrentItems(items);
        break;
    }
  }, [mode, items, pinned, recentlyAccessed, assetGroups]);

  useEffect(() => {
    if (!isAsyncInitialized) {
      // Not ideal to initiate the async fetch from a useEffect, but there are so many modals that open this widget
      // up that this seems better than the alternative of having all the modals call it when the modal opens.
      // Note that this only gets called for modals, stateSynchronizer handles the initialization for the main panel
      // to enable fast follow to work (CRAB-26634)
      sqSearchActions.initialize(pane, searchTypes, restrictExploration, scopeIds)
        .catch(sqNotifications.apiError);
    }
  }, []);

  const limit = mode === SEARCH_MODES.OVERVIEW ? LIMIT_IN_OVERVIEW : undefined;
  const hasNoResults = () => !searching && !isPaginating && currentItems.length === 0;
  const isDoneLoadingResults = () => !searching && !isPaginating;

  const filterByRestriction = (item) => {
    return restrictExploration ? _.includes(searchTypes, item.type) : true;
  };

  const filterByName = (item) => {
    return _.includes([SEARCH_MODES.OVERVIEW, SEARCH_MODES.SEARCH, SEARCH_MODES.TREE], mode) ?
      true : item?.name.toLowerCase().includes(nameFilter.toLowerCase());
  };

  const limitAndFilterItems = items => _.chain(items)
    .filter(filterByName)
    .filter(filterByRestriction)
    .slice(0, limit)
    .value();

  const exploreAsset = (asset) => {
    sqTrack.doTrack('Search', 'explore Asset');
    return sqSearchActions.exploreAsset(pane, asset.id, searchTypes, restrictExploration);
  };

  const viewItem = (item) => {
    if (sqUtilities.isAsset(item) || sqUtilities.isDatafile(item) || item.hasChildren) {
      exploreAsset(item);
    } else {
      onItemClick(item);
    }
  };

  const loadNextPage = () => {
    if (!searching && !isPaginating && hasNextPage) {
      return sqSearchActions.loadNextPage(pane, searchTypes, restrictExploration, scopeIds);
    }
  };

  function defaultSearchResultIcons(item) {
    return (
      <>
        {allowAssetReplacement &&
        <SearchResultAssetIcons item={item} isAssetGroupView={isAssetGroupView} />}
        {!isAssetGroupView &&
        <SearchResultIcons
          item={item}
          pinned={pinned}
          workbookDisplay={workbookDisplay}
          workbookId={workbookId}
          onItemClick={onItemClick}
          allowAssetReplacement={allowAssetReplacement}
        />
        }
      </>
    );
  }

  // In pinned, recently accessed or asset group mode, users can filter by the name, pulling this out of the
  // <SearchFilters/> component allows us to not have to rerender every time a user types in an input (CRAB-24095)
  const renderOnlyNameFilter = (
    <div className="panel panel-default">
      <div className="panel-body" data-testid="searchPanel">
        <FormControl className="mb10 height-34" id="searchInput" name="nameFilter" data-testid="nameOnlyFilter"
          placeholder={t('SEARCH_DATA.NAME_CONTAINS')}
          onChange={name => setNameFilter(name.target.value)}
          value={nameFilter} />
        <TextButton
          label="SEARCH_DATA.RESET"
          onClick={() => {
            sqSearchActions.clear(pane, searchTypes, restrictExploration, scopeIds);
            setNameFilter('');
          }}
          testId="resetButton" />
      </div>
    </div>
  );

  return (
    <>
      {_.includes([SEARCH_MODES.TREE, SEARCH_MODES.OVERVIEW, SEARCH_MODES.SEARCH], mode)
        ? <SearchFilters pane={pane} searchTypes={searchTypes} scopeIds={scopeIds} />
        : renderOnlyNameFilter}

      {mode !== SEARCH_MODES.OVERVIEW &&
      <ul className="unstyled-list searchBreadcrumbs flexColumnContainer flexWrap flexNoGrowNoShrink mb0 heading">
        {_.map(breadcrumbs, (breadcrumb, index) => {
          const isLast = breadcrumbs.length - 1 === _.toNumber(index);
          return <li className="ml2" tabIndex={isLast ? undefined : 0}
            onKeyUp={e => e.keyCode === 13 && !isLast && !breadcrumb.isLoading && exploreAsset(breadcrumb)}
            onClick={() => !breadcrumb.isLoading && !isLast && exploreAsset(breadcrumb)}
            key={breadcrumb.id}>
            {breadcrumb.isLoading && <IconWithSpinner spinning={true} />}
            {!breadcrumb.id && <Icon icon="fa-home" large={true} extraClassNames="pl5" testId="breadcrumbHomeIcon" />}
            <span className="fs15">{t(breadcrumb.name)} </span>
            {!breadcrumb.isLoading && breadcrumbs.length === 1 &&
            <span className="fs15 pl5">{t('SEARCH_DATA.ASSET_TREES')}</span>}
          </li>;
        })}
      </ul>}

      {isDoneLoadingResults() && hasNoResults() && !currentAsset &&
      <ErrorWithBackground errorText="SEARCH_DATA.NO_RESULTS" dismissible={false} type="Warning" />}

      <div
        id="spec-search-results"
        data-testid="spec-search-results"
        className="flexRowContainer overflowAuto"
        ref={scrollParentRef}>

        {!showOnlyResults &&
        <SearchGroup
          isSelectingAsset={isSelectingAsset}
          loadStatus={pinnedLoadStatus}
          label="PINNED"
          pane={pane}
          searchMode={SEARCH_MODES.PINNED}
          searchTypes={searchTypes}
          scopeIds={scopeIds}
          restrictExploration={restrictExploration}
          onErrorClick={() => sqWorkbookActions.fetchPinnedItems(workbookId)}
          onClickItem={viewItem}
          searchResultIcons={searchResultIcons}
          mode={mode}
          items={limitAndFilterItems(pinned)}
          indicateSelection={indicateSelection}
          selectedItemId={selectedItemId}
        />}

        <SearchGroup
          isSelectingAsset={isSelectingAsset}
          loadStatus={assetGroupsLoadStatus}
          label="ASSET_GROUPS"
          searchMode={SEARCH_MODES.ASSET_GROUPS}
          searchTypes={searchTypes}
          scopeIds={scopeIds}
          restrictExploration={restrictExploration}
          onErrorClick={() => sqWorkbookActions.fetchAssetGroups(workbookId)}
          pane={pane}
          onClickItem={viewItem}
          searchResultIcons={searchResultIcons}
          mode={mode}
          noItemsMessage={sqSystemConfiguration.assetGroupEditorEnabled && sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.EDIT && 'SEARCH_DATA.NO_ASSET_GROUPS'}
          items={limitAndFilterItems(assetGroups)}
          indicateSelection={indicateSelection}
          selectedItemId={selectedItemId}
        />

        {!showOnlyResults &&
        <SearchGroup
          isSelectingAsset={isSelectingAsset}
          loadStatus={recentlyAccessedLoadStatus}
          label="RECENTLY_ACCESSED"
          searchMode={SEARCH_MODES.RECENT}
          searchTypes={searchTypes}
          scopeIds={scopeIds}
          restrictExploration={restrictExploration}
          pane={pane}
          onErrorClick={() => sqWorkbookActions.fetchRecentlyAccessedItems(workbookId)}
          onClickItem={viewItem}
          searchResultIcons={searchResultIcons}
          mode={mode}
          items={limitAndFilterItems(recentlyAccessed)}
          indicateSelection={indicateSelection}
          selectedItemId={selectedItemId}
        />}

        {mode === SEARCH_MODES.OVERVIEW &&
        <div className="flexColumnContainer flexWrap flexNoGrowNoShrink ml2 mb0 heading">
          <span className="fs15 text-interactive">{t('SEARCH_DATA.ASSET_TREES')}</span>
        </div>}
        {mode !== SEARCH_MODES.PINNED && mode !== SEARCH_MODES.RECENT && mode !== SEARCH_MODES.ASSET_GROUPS &&
        <>
          <InfiniteScroll
            pageStart={0}
            hasMore={hasNextPage}
            loadMore={loadNextPage}
            useWindow={false}
            initialLoad={false}
            getScrollParent={() => scrollParentRef.current}>
            {_.map(currentItems, item => (
              <SearchResult
                isSelectingAsset={isSelectingAsset}
                customIconClasses={iconClasses}
                key={item.id}
                icons={searchResultIcons(item)}
                item={item}
                onClickItem={() => viewItem(item)}
                isSelected={indicateSelection && selectedItemId === item.id}
              />
            ))}
          </InfiniteScroll>

          {(isPaginating || searching) &&
          <div className="pt10 pb10 flexNoGrowNoShrink flexColumnContainer flexCenter">
            <IconWithSpinner spinning={true} large={true} />
          </div>}
        </>}
      </div>

      {isDoneLoadingResults() &&
      ((!hasNoResults() && _.filter(currentItems, filterByName).length === 0) || (hasNoResults() && currentAsset)) &&
      <ErrorWithBackground
        type="Warning"
        dismissible={false}
        errorText="SEARCH_DATA.NO_RESULTS_WITHIN_CATEGORY"
        errorParameters={{ category: t(placeholderCategory) }} />}
    </>
  );
};

export const sqSearchWidget = angularComponent(searchWidgetBindings, SearchWidget);
