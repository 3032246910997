import angular from 'angular';
import { TrackService } from '@/track/track.service';
import { PdfExportActions } from '@/reportEditor/pdfExport.actions';

/**
 * @file This service allows the report content modal to be opened in either the workbook, link, or update state.
 */
angular.module('Sq.Report').factory('sqPdfPreviewModal', sqPdfPreviewModal);

export type PdfPreviewModalService = ReturnType<typeof sqPdfPreviewModal>;

function sqPdfPreviewModal(
  sqPdfExportActions: PdfExportActions,
  sqTrack: TrackService) {
  const service = {
    showModal,
    closeModal
  };

  return service;

  function showModal() {
    sqTrack.doTrack('Export', 'PDF', 'displayed');
    sqPdfExportActions.setShowModal(true);
  }

  function closeModal() {
    sqPdfExportActions.setShowModal(false);
  }
}
