import _ from 'lodash';
import angular from 'angular';
import { TrendActions } from '@/trendData/trend.actions';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { TrendTableActions } from '@/trendData/trendTable.actions';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { ErrorTypeEnum } from 'sdk/model/FormulaErrorOutputV1';
import { ITEM_CHILDREN_TYPES, ITEM_DATA_STATUS, ITEM_TYPES } from '@/trendData/trendData.module';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.module';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { SeeqNames } from '@/main/app.constants.seeqnames';

angular.module('Sq.TrendViewer')
  .controller('DataStatusIconCtrl', DataStatusIconCtrl);

function DataStatusIconCtrl(
  $translate: ng.translate.ITranslateService,
  sqTrendActions: TrendActions,
  sqInvestigateActions: InvestigateActions,
  sqPendingRequests: PendingRequestsService,
  sqTrendTableActions: TrendTableActions,
  sqTrendDataHelper: TrendDataHelperService,
  sqWorksheetStore: WorksheetStore
) {

  const vm = this;
  vm.onClick = onClick;
  vm.cancelRequest = cancelRequest;
  vm.hasWarnings = hasWarnings;
  vm.hasMetadataError = hasMetadataError;
  vm.$onChanges = onChanges;
  vm.toggleRequestDetails = toggleRequestDetails;
  vm.getTooltip = getTooltip;
  vm.displayRequestDetails = false;
  vm.displayRequestDetailsIcon = false;
  vm.displayMetadataError = false;
  vm.displayWarning = false;
  vm.displayError = false;
  vm.displayWrench = false;
  vm.getRequestDetailsLegend = getRequestDetailsLegend;
  vm.timingLegend = [];
  vm.samplesLegend = [];
  vm.colorAssignment = [];
  vm.timingLookup = [];
  vm.meterLookup = [];
  vm.progress = undefined;
  vm.isInProgress = false;
  vm.options = {
    scaleColor: false,
    trackColor: '#d9d9d9',
    barColor: '#2D7BB8',
    lineWidth: 2,
    size: 19
  };

  /**
   * When the bindings change, this function is called to refresh our state including if there is something to
   * show, which icon to show, and the tooltip to show.
   */
  function onChanges() {
    vm.translateKeys = {};
    vm.isInProgress = false;
    vm.displayRequestDetailsIcon = false;
    vm.displayError = false;
    vm.canRetry = true;
    vm.displayWarning = false;
    vm.displayMetadataError = false;
    vm.displayWrench = false;
    vm.progress = undefined;

    if (vm.hasMetadataError(vm.items)) {
      // if the error is related to maximum capsule duration a wrench displays instead of the error triangle as well as
      // a prompt to edit the failing condition.
      vm.displayMetadataError = true;
      vm.displayWrench = true;
      vm.iconClass = 'sq-text-danger fa-wrench';
      vm.translateKeys = {
        hasError: _.some(vm.items, 'statusMessage'),
        message: _.chain(vm.items).find('statusMessage').get('statusMessage').value()
      };
      vm.tooltip = 'DATA_STATUS_ICON_TOOLTIP.FAILURE_METADATA';
      const item = vm.items[0];
      vm.translateKeys = _.assign(vm.translateKeys, {
        prompt: $translate.instant('DATA_STATUS_ICON_TOOLTIP.FAILURE_METADATA_CLICK_PROMPT',
          {
            clickPrompt: item.errorType === ErrorTypeEnum.MAXDURATIONREQUIRED ? 'addMaxDuration' :
              'maxDurationRemoved'
          })
      });
    } else if (_.some(vm.items, ['dataStatus', ITEM_DATA_STATUS.FAILURE])) {
      vm.displayError = true;
      vm.iconClass = 'fa-exclamation-triangle sq-text-danger';
      vm.tooltip = 'DATA_STATUS_ICON_TOOLTIP.FAILURE';
      vm.translateKeys = {
        hasError: _.some(vm.items, 'statusMessage'),
        message: _.chain(vm.items).find('statusMessage').get('statusMessage').value()
      };
    } else if (vm.items.length > 0 && _.every(vm.items, ['dataStatus', ITEM_DATA_STATUS.REDACTED])) {
      // Note `_.every` used here intentionally so that an overall error isn't shown at the top of the column
      vm.displayError = true;
      vm.canRetry = false;
      vm.iconClass = 'fa-exclamation-triangle sq-text-danger';
      vm.tooltip = _.some(vm.items, 'statusMessage')
        ? _.chain(vm.items).find('statusMessage').get('statusMessage').value()
        : 'DATA_STATUS_ICON_TOOLTIP.REDACTED';
    } else if (_.some(vm.items, ['dataStatus', ITEM_DATA_STATUS.LOADING])) {
      vm.iconClass = 'fa-lg fa-circle-o-notch fa-spin sq-text-primary';
      vm.tooltip = vm.header ? 'DATA_STATUS_ICON_TOOLTIP.LOADING' : 'DATA_STATUS_ICON_TOOLTIP.LOADING_ITEM';
      // in order to make the progress indicators fit better with the other icons the size was optimized to fit 99 -
      // not 100. As 100 means it's completed anyways we'll just show the rocket instead.
      vm.isInProgress = true;

      if (vm.items.length === 1) {
        vm.progress = vm.items[0].progress ? vm.items[0].progress : 0;
        vm.isInProgress = vm.progress < 100;
      } else {
        vm.progress = 0;
      }
    } else if (vm.hasWarnings(vm.items)) {
      const clickPrompt = getClickPrompt();
      vm.iconClass = 'fa-exclamation-circle sq-text-warning';
      vm.tooltip = 'DATA_STATUS_ICON_TOOLTIP.WARNING';
      vm.displayWarning = true;
      vm.displayWrench = clickPrompt !== '';
      vm.translateKeys = {
        warningCount: _.first(vm.items)['warningCount'],
        clickPrompt,
        message: (_.chain(vm.items)
          .first() as any)
          .get('warningLogs[0].formulaLogEntries')
          .values()
          .first()
          .get('logDetails[0].message')
          .value()
      };
    } else if (_.some(vm.items, ['dataStatus', ITEM_DATA_STATUS.CANCELED])) {
      vm.iconClass = 'fa-exclamation-triangle sq-text-danger';
      vm.tooltip = 'DATA_STATUS_ICON_TOOLTIP.CANCELED';
      vm.displayError = true;
    } else if (!_.includes([WORKSHEET_VIEW.SCATTER_PLOT, WORKSHEET_VIEW.TABLE], sqWorksheetStore.view.key) &&
      someNonConditionItemHasNoData(vm.items)) {
      vm.iconClass = 'fa-info-circle sq-text-warning';
      vm.tooltip = 'DATA_STATUS_ICON_TOOLTIP.NO_DATA';
      vm.displayWarning = true;
    } else {
      // only display the Request Details for the individual items, not rolled up.
      vm.displayRequestDetailsIcon = !vm.header;
      vm.iconClass = '';
      vm.tooltip = '';
    }

    if (!vm.header) {
      // Metrics should get their info from their display item child
      const timedItem = _.find(vm.items, { childType: ITEM_CHILDREN_TYPES.METRIC_DISPLAY })
        || _.first(vm.items as any[]);
      vm.rawTimingData = timedItem.timingInformation;
      vm.rawSamplesData = timedItem.meterInformation;
    }
  }

  /**
   * Tests if any non-condition item in the items array with data present has no valid data points.
   *
   * @param {Object} items - an array of items
   * @returns {boolean} true if there is a non-condition item with no data in the supplied
   *   array of items, false otherwise
   */
  function someNonConditionItemHasNoData(items) {
    const nonConditionItemsWithDataPresent = _.chain(items)
      .reject(['itemType', ITEM_TYPES.CAPSULE_SET])
      .reject(['itemType', ITEM_TYPES.TABLE])
      .reject(['itemType', ITEM_TYPES.METRIC])
      .filter(['dataStatus', ITEM_DATA_STATUS.PRESENT])
      .value();
    const numberNonConditionItemsWithDataPresent = nonConditionItemsWithDataPresent.length;
    const someNonConditionItemsHaveNoData = _.some(nonConditionItemsWithDataPresent, _.flow(
      _.partial(_.get, _, 'data'),
      _.partial(_.map, _, datum => _.isArray(datum) ? _.last(datum) : _.get(datum, 'y')),
      _.partial(_.reject, _, _.isNil),
      _.partial(_.get, _, 'length'),
      _.partial(_.isEqual, _, 0)));

    return someNonConditionItemsHaveNoData && numberNonConditionItemsWithDataPresent > 0;
  }

  /**
   * Returns the click prompt that should be displayed based on the type of item
   *
   * @returns {String} a string to be displayed to the user. One of:
   *     '' = No click prompt is displayed
   *     'MORE_INFO' = Click to open the formula editor.
   *     'OPEN_TOOL' = Click to open tool and modify the Max Interpolation.
   *     'MODIFY_GAP' = Click to modify the signal's Max Interpolation.
   *     'MODIFY_DURATION' = Click to modify the condition's Max Duration.
   */
  function getClickPrompt() {
    if (_.get(vm, 'items.length') > 1) {
      return '';
    }

    let name = '';
    const calcType = _.first(vm.items)['calculationType'];
    const itemType = _.first(vm.items)['itemType'];
    const maxInterpExceeded = vm.hasWarnings(vm.items, 'EXCEEDS_MAXIMUM_INTERPOLATION');

    if (calcType === TREND_TOOLS.FORMULA) {
      name = 'DATA_STATUS_ICON_TOOLTIP.MORE_INFO';
    } else if (!_.isUndefined(calcType) && itemType === ITEM_TYPES.SERIES) {
      name = 'DATA_STATUS_ICON_TOOLTIP.OPEN_TOOL';
    } else if (_.isUndefined(calcType) && itemType === ITEM_TYPES.CAPSULE_SET) {
      name = 'DATA_STATUS_ICON_TOOLTIP.MODIFY_DURATION';
    } else if (_.isUndefined(calcType) && maxInterpExceeded) {
      name = 'DATA_STATUS_ICON_TOOLTIP.MODIFY_GAP';
    }

    return $translate.instant(name);
  }

  /**
   * Determines if there are warnings for the items. Only single items will be considered to have warnings.
   *
   * @param {Object[]} items - Array of items to check
   * @param {string} ofType - optional warning type for greater specificity
   * @return {Boolean} - true if the item has a warning otherwise false
   */
  function hasWarnings(items: Object[], ofType: string = undefined): boolean {
    const item = _.first(items) as any;
    return items.length === 1 &&
      item.dataStatus === ITEM_DATA_STATUS.PRESENT &&
      item.warningCount > 0 && !_.isEmpty(item.warningLogs) &&
      (_.isUndefined(ofType) || _.some(item.warningLogs, log => _.has(log, 'formulaLogEntries.' + ofType)));
  }

  /**
   * Determines if a user fixable error (missing or superfluous max capsule duration) has occurred.
   *
   * @param {Object[]} items - Array of items to check
   * @return {Boolean} - true if the item has a missing or superfluous max capsule duration, otherwise false
   */
  function hasMetadataError(items) {
    return _.some(items, item => item.dataStatus === ITEM_DATA_STATUS.FAILURE &&
      (item.errorType === ErrorTypeEnum.MAXDURATIONREQUIRED ||
        item.errorType === ErrorTypeEnum.MAXDURATIONPROHIBITED));
  }

  /**
   * Call the click handler for each item with status of 'failure', 'cancelled', or with warnings.
   */
  function onClick() {
    // clean up the request details panel:
    vm.displayRequestDetails = false;
    vm.rawTimingData = undefined;
    vm.rawSamplesData = undefined;
    vm.timingLegend = [];
    vm.samplesLegend = [];

    if (vm.hasWarnings(vm.items) || vm.hasMetadataError(vm.items)) {
      const item = _.first(vm.items) as any;
      // Open the properties panel for store series and load the appropriate tool for other items
      if (_.isUndefined(item.calculationType) && item.itemType === ITEM_TYPES.SERIES) {
        sqInvestigateActions.setItem(item.id, { propertiesFocus: SeeqNames.Properties.MaximumInterpolation });
        sqInvestigateActions.setActiveTool(TREND_TOOLS.PROPERTIES);
      } else if (_.isUndefined(item.calculationType) && item.itemType === ITEM_TYPES.CAPSULE_SET) {
        sqInvestigateActions.setItem(item.id, { propertiesFocus: SeeqNames.Properties.MaximumDuration });
        sqInvestigateActions.setActiveTool(TREND_TOOLS.PROPERTIES);
      } else {
        sqInvestigateActions.setItem(item.id, { errorType: item.errorType, errorCategory: item.errorCategory });
        sqInvestigateActions.loadToolForEdit(item.id);
      }
    }

    _.chain(vm.items as any[])
      .filter(item => item.dataStatus === ITEM_DATA_STATUS.FAILURE || item.dataStatus === ITEM_DATA_STATUS.CANCELED)
      .reject(item => vm.hasMetadataError([item]))
      .tap((erroredItems) => {
        if (!_.isEmpty(erroredItems)) {
          sqTrendActions.fetchItems(erroredItems, { fetchFailed: true, fetchCapsulesLater: true });
        }
      })
      .value();
  }

  /**
   * Cancel the request for each item.
   */
  function cancelRequest() {
    _.chain(vm.items as any[])
      .filter(['dataStatus', ITEM_DATA_STATUS.LOADING])
      .forEach(item => sqPendingRequests.cancelGroup(item.id))
      .value();
  }

  /**
   * Toggles the Request Details display popover.
   */
  function toggleRequestDetails() {
    vm.displayRequestDetails = !vm.displayRequestDetails;
  }

  /**
   * Returns the combined de-duped legend for the Request Details Panel. Also ensures that "Other" is always the
   * last entry in the legend.
   *
   * @returns {any[]} representing the legend.
   */
  function getRequestDetailsLegend() {
    let doAddOthers = false;
    let others;

    if (!vm.rawTimingData && !vm.rawSamplesData) {
      return [];
    }
    if (_.get(_.last(vm.timingLegend), 'label') === 'Other') {
      others = vm.timingLegend.pop();
      doAddOthers = true;
    }
    if (_.get(_.last(vm.samplesLegend), 'label') === 'Other') {
      doAddOthers = false;
    }
    return _.chain(vm.timingLegend)
      .concat(vm.samplesLegend)
      .uniqBy('label')
      .sortBy('label')
      .concat(doAddOthers ? others : [])
      .value();
  }

  /**
   * Returns the formatted tooltip. This ensures that everything that falls under the same "label" is displayed
   * with the correct source values.
   *
   * @param {Object} entry - the Object containing progress information.
   * @param {String} entry.label - the label for the data (like Webserver).
   * @param {Object[]} lookup - source  object for tooltip lookup. When this function is called from a hover on a
   *   progress component the lookup consist only of the data available in that component.
   * @returns {String} display-ready tooltip.
   */
  function getTooltip(entry, lookup) {
    const tooltip = (current) => {
      return current.source + ': ' + current.displayValue;
    };

    let prepareForDisplay;

    if (_.isEmpty(entry)) {
      return;
    }

    if (!lookup) {
      lookup = _.concat(vm.timingLookup, vm.meterLookup);
    }

    if (entry.label === 'Other') {
      const displayed = _.chain(getRequestDetailsLegend())
        .map('label')
        .without('Other')
        .value();
      prepareForDisplay = _.chain(lookup)
        .filter((current: any) => {
          if (_.indexOf(displayed, current.label) < 0) {
            return current;
          }
        }).compact()
        .value();
    } else {
      prepareForDisplay = _.filter(lookup, { label: entry.label });
    }
    return _.map(prepareForDisplay, (e) => {
      return tooltip(e);
    });
  }
}
