import _ from 'lodash';
import bind from 'class-autobind-decorator';
import { LICENSE_STATUS, WARNING_FLAGS } from '@/licenseManagement/licenseManagement.module';
import { APP_STATE } from '@/main/app.constants';
import { SystemApi } from '@/sdk';
import { ValidityEnum } from '@/sdk/model/LicenseStatusOutputV1';
import { LicenseManagementStore } from '@/licenseManagement/licenseManagement.store';

@bind
export class LicenseManagementActions {
  constructor(
    private $q: ng.IQService,
    private flux: ng.IFluxService,
    private $state: ng.ui.IStateService,
    private sqSystemApi: SystemApi,
    private sqLicenseManagementStore: LicenseManagementStore
  ) {
  }

  /**
   * Fetch the license for the current install of Seeq, determine the frontend-version of the status, and set both
   * status and license data in the store.
   *
   * @returns {Promise<void>} resolves when the license has been retrieved and the status set.
   */
  fetchLicense() {
    return this.sqSystemApi.getLicense()
      .then(({ data: license }) => {
        const cutOffs = license.level === 'Trial' ? WARNING_FLAGS.TRIAL : WARNING_FLAGS.STANDARD;
        const isValid = _.matches({ validity: ValidityEnum.Valid });
        const needsNoWarning = _.conforms({ daysToExpiration: d => d > cutOffs.SHOW_ADMIN_WARNING_DAYS_COUNT });
        const needsAdminWarning = _.conforms({ daysToExpiration: d => d > cutOffs.SHOW_WARNING_DAYS_COUNT });
        const status = _.cond([
          [input => isValid(input) && needsNoWarning(input), _.constant(LICENSE_STATUS.VALID)],
          [input => isValid(input) && needsAdminWarning(input), _.constant(LICENSE_STATUS.SHOW_ADMIN_LICENSE_WARNING)],
          [isValid, _.constant(LICENSE_STATUS.SHOW_LICENSE_WARNING)],
          [_.matches({ validity: ValidityEnum.Expired }), _.constant(LICENSE_STATUS.EXPIRED)],
          [_.matches({ validity: ValidityEnum.NoLicense }), _.constant(LICENSE_STATUS.NONE)],
          [_.stubTrue, _.constant(LICENSE_STATUS.UNKNOWN)]
        ])(license);

        this.flux.dispatch('LICENSE_SET_LICENSE', license);
        this.flux.dispatch('LICENSE_SET_STATUS', { status });
      });
  }

  /**
   * Check if the license is invalid and, if not, redirect to the license page. Since it is assumed that this will be
   * invoked when the state changes it does not re-fetch the license if it has already been loaded so as to avoid
   * unnecessary fetches.
   *
   * @param {string} stateName - The state name being loaded
   * @returns {Promise<void>} resolves with the check is complete
   */
  checkLicenseStatus(stateName) {
    return this.$q.resolve(this.sqLicenseManagementStore.licenseStatus ? undefined : this.fetchLicense())
      .then(() => {
        const INVALID_STATUSES = [LICENSE_STATUS.NONE, LICENSE_STATUS.UNKNOWN, LICENSE_STATUS.EXPIRED];
        if (stateName !== APP_STATE.LICENSE &&
          _.includes(INVALID_STATUSES, this.sqLicenseManagementStore.licenseStatus)) {
          this.$state.go(APP_STATE.LICENSE);
        }
      });
  }
}
