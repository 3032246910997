import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import _ from 'lodash';

const reportConfigContentInfoBindings = bindingsDefinition({
  isContentLoading: prop<boolean>(),
  showBulkEditModal: prop<() => void>(),
  refreshErrorContent: prop<() => void>(),
  contentTotal: prop<number>(),
  contentInError: prop<number>(),
  isCKEditor: prop.optional<boolean>(),
  contentProgress: prop<{ count: number, key: string, class: string, percent: number }[]>()
});

export const ReportConfigContentInfo: SeeqComponent<typeof reportConfigContentInfoBindings> = (props) => {
  const {
    isContentLoading,
    showBulkEditModal,
    refreshErrorContent,
    contentTotal,
    contentInError,
    contentProgress,
    isCKEditor = false
  } = props;
  const { t } = useTranslation();

  let insideContent;
  if (isContentLoading) {
    insideContent =
      <ProgressBar className="progress mb0">
        {_.map(contentProgress, (cp, index) => {
          return cp.count === 0 ? null : (
            <HoverTooltip key={`progress_bar_tooltip_${index}`}
              text={t(`REPORT.CONFIG.CONTENT_STATUS_TOOLTIP.TOTAL`, { TOTAL: contentTotal })
              + ' '
              + t(`REPORT.CONFIG.CONTENT_STATUS_TOOLTIP.${cp.key}`, { COUNT: cp.count })}>
              {/*
                // @ts-ignore */}
              <ProgressBar isChild={true} key={index} now={cp.percent} striped={true} animated={true} label={cp.count}
                variant={cp.class}
              />
            </HoverTooltip>
          );
        })}
      </ProgressBar>;
  } else {
    const error = contentInError
      ? <span>
        <span>{t('REPORT.CONFIG.CONTENT_ERROR', { TOTAL: contentTotal, FAILED: contentInError })}</span>
        <a className="ml2 cursorPointer" onClick={refreshErrorContent}>
          <HoverTooltip
            text="REPORT.CONFIG.CONTENT_ERROR_TOOLTIP"
            formattedText={<span>{t('REPORT.CONFIG.CONTENT_ERROR_TOOLTIP', { COUNT: contentInError })}</span>}>
            <i className="fa fa-warning sq-text-danger" />
          </HoverTooltip>
        </a>
        {isCKEditor && <div className="mt2">{t('REPORT.CONFIG.CONTENT_ERROR_HELP_MESSAGE')}</div>}
      </span>
      : null;
    insideContent =
      <>
        <HoverTooltip text="REPORT.EDITOR.BULK_EDIT">
          <a onClick={showBulkEditModal} href="#">
            <span>
              {t('REPORT.CONFIG.CONTENT_LOADED', { TOTAL: contentTotal })}
            </span>
            <i className="fa fa-edit ml2" />
          </a>
        </HoverTooltip>
        {error}
      </>;
  }

  return (
    insideContent
  );
};
