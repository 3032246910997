import angular from 'angular';
import { StateSynchronizerService } from '@/services/stateSynchronizer.service';

angular.module('Sq.Core.FluxDecorator', []).config(fluxDecorator);

function fluxDecorator($provide) {
  $provide.decorator('flux', function($delegate, $injector: ng.auto.IInjectorService) {
    const dispatch = $delegate.dispatch;
    $delegate.dispatch = function() {
      dispatch.apply(this, arguments);
      const sqStateSynchronizer = $injector.get<StateSynchronizerService>('sqStateSynchronizer');
      const option = arguments[2];
      sqStateSynchronizer.push(option);
    };

    return $delegate;
  });
}
