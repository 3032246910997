import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from "@/hybrid/core/bindings.util";
import { useTranslation } from "@/hybrid/core/useTranslation.hook";
import { DateTimeService } from "@/datetime/dateTime.service";
import { useInjectedBindings } from "@/hybrid/core/useInjectedBindings.hook";
import { ControlledTooltip } from "@/hybrid/core/ControlledTooltip.atom";
import { EditableText } from "@/hybrid/core/EditableText.atom";
import { angularComponent } from "@/hybrid/core/react2angular.util";

const durationEntryBindings = bindingsDefinition({
  startDate: prop<moment>(),
  endDate: prop<moment>(),
  updateDuration: prop<(duration: moment | number) => void>(),
  updateDates: prop<(start: moment | number, end: moment | number) => void>(),
  timezone: prop<{ name: string, displayName: string, offset: string, offsetMinutes: number }>(),
  readOnly: prop.optional<boolean>(),
  extraClassName: prop.optional<string>(),
  textExtraClassNames: prop.optional<string>(),
  inputExtraClassNames: prop.optional<string>(),
  sqDateTime: injected<DateTimeService>()
});

export const DurationEntry: SeeqComponent<typeof durationEntryBindings> = (props) => {
  const {
    startDate,
    endDate,
    updateDates,
    updateDuration,
    timezone,
    readOnly,
    textExtraClassNames,
    inputExtraClassNames
  } = props;

  const {
    sqDateTime
  } = useInjectedBindings(durationEntryBindings);

  const [isError, setIsError] = useState(false);
  const [parseError, setParseError] = useState('');
  const target = useRef(null);

  const { t } = useTranslation();

  const formatNewDuration = () => sqDateTime.formatSimpleDuration(moment.duration(endDate.diff(startDate)));
  const formatDuration = () => {
    const newDuration = formatNewDuration();
    if (endDate.isSameOrBefore(startDate)) {
      showError(newDuration);
      return originalText;
    } else {
      return newDuration;
    }
  };

  const [editText, setEditText] = useState(formatNewDuration());
  const [originalText, setOriginalText] = useState(formatNewDuration());

  const updateDisplayValue = () => {
    if (startDate.isValid() && endDate.isValid() && !isError) {
      const newEditText = formatDuration();
      setEditText(newEditText);
      setOriginalText(newEditText);
    } else {
      setEditText(originalText);
    }
  };

  const revertCorrectValue = () => {
    setEditText(originalText);
    setOriginalText(originalText);
  };

  useEffect(() => {
    updateDisplayValue();
  }, [startDate.valueOf(), endDate.valueOf(), timezone]);


  const onDurationChange = (value) => {
    setOriginalText(editText);
    if (value.length > 50) {
      showError(value.substring(0, 40) + '...');
      return;
    }
    let parser;
    let newDuration, newDates;
    let trimmedInput = _.trim(value);
    const inputValueNumberOnly = !_.isEmpty(trimmedInput) && !_.isNaN(_.toNumber(trimmedInput));
    const origUnits = editText.replace(/^[-+]?[\d.]+\W+/, '');

    // If no units were supplied and we have original units, then use the original units
    if (inputValueNumberOnly && !_.isEmpty(origUnits)) {
      trimmedInput = trimmedInput + ' ' + origUnits;
    }

    // attempt to parse as an anchored duration offset
    if (!parser) {
      newDates = sqDateTime.parseDurationIntoDates(trimmedInput, moment.utc(), undefined, undefined);
      if (newDates.start.isValid() && newDates.end.isValid()) {
        updateDates(newDates.start, newDates.end);
        parser = 'parseDurationIntoDates';
      }
    }

    // attempt to parse as a duration offset
    if (!parser) {
      newDuration = sqDateTime.parseDurationOffset(trimmedInput);
      if (newDuration.valueOf() !== 0) {
        updateDates(moment.utc(startDate).add(newDuration), moment.utc(endDate).add(newDuration));
        parser = 'parseDurationOffset';
      }
    }

    // attempt to parse as a duration
    if (!parser) {
      newDuration = sqDateTime.parseDuration(trimmedInput);
      if (newDuration.valueOf() !== 0) {
        updateDuration(newDuration);
        parser = 'parseDuration';
      }
    }

    if (!parser) {
      showError(trimmedInput);
    } else {
      clearError();
    }
  };

  const clearError = () => {
    setIsError(false);
    setParseError('');
  };

  const showError = (input) => {
    revertCorrectValue();
    setIsError(true);
    setParseError(input);
    setTimeout(clearError, 3000);
  };

  const errorTooltip = (
    <span>
      {t('PARSE_DURATION_ERROR')}
      <span className="text-bolder">{parseError}</span>
    </span>
  );

  return (
    <div className="nowrap durationTimeEntry small text-center pl5 pr5" data-testid="durationTimeEntry">
      {readOnly &&
      <span className="readOnly" data-testid="durationEntryReadOnly">
          {editText}
        </span>
      }
      {!readOnly &&
      <>
        <ControlledTooltip
          extraClassNames="errorTooltip"
          target={target.current}
          show={isError}
          id="error-tooltip-duration"
          formattedText={errorTooltip} />
        <span ref={target}>
          <EditableText
            id="durationEntryField"
            testId="durationEntryField"
            textClasses={classNames('overflowHidden', 'inlineFlex', textExtraClassNames)}
            inputClasses={classNames('overflowHidden', 'inlineFlex', 'max-width-100', 'width-auto', inputExtraClassNames)}
            value={editText}
            autoWidth={true}
            onUpdate={onDurationChange}
          />
        </span>
      </>
      }
    </div>
  );
};

export const sqDurationEntry = angularComponent(durationEntryBindings, DurationEntry);
