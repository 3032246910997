import React, { useState } from 'react';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';

export interface IconWithManagedSpinnerProps {
  icon?: string;
  action: (e?: any) => Promise<any>;
  tooltip: string;
  tooltipDelay?: number;
  actionOnEnter?: boolean;
  large?: boolean;
  white?: boolean;
  extraClassNames?: string;
  disabled?: boolean;
  testId?: string;
  correctSpin?: boolean;
}

/** Icon that shows spinner when click action is in progress */
export const IconWithManagedSpinner: React.FunctionComponent<IconWithManagedSpinnerProps> = (props) => {
  const { action, tooltip, tooltipDelay = 0, disabled = false } = props;
  const [spinning, setSpinning] = useState(false);

  const clickFunction = (e) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    setSpinning(true);
    return action()
      .finally(() => setSpinning(false));
  };

  return (
    <HoverTooltip text={tooltip} delay={tooltipDelay}>
      <div onClick={clickFunction} onKeyUp={e => e.keyCode === 13 && clickFunction(e)}>
        <IconWithSpinner {...props} spinning={spinning} />
      </div>
    </HoverTooltip>
  );
};
