import bind from 'class-autobind-decorator';
import { UtilitiesService } from '@/services/utilities.service';

@bind
export class ScorecardController {
  constructor(
    public $element: JQuery,
    public sqUtilities: UtilitiesService) {
  }

  isPresentationMode = this.sqUtilities.isPresentationWorkbookMode;

  /**
   * Get the element height for use with the bottom panel
   *
   * @return {Number} - height of the treemap component
   */
  elementHeight() {
    return this.$element.height();
  }
}
