import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import React from 'react';
import { JournalCategory } from '@/hybrid/annotation/JournalCategory.molecule';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { UtilitiesService } from '@/services/utilities.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { AnnotationActions } from '@/annotation/annotation.actions';
import { AnnotationStore } from '@/annotation/annotation.store';
import _ from 'lodash';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { angularComponent } from '@/hybrid/core/react2angular.util';

const journalOverviewBindings = bindingsDefinition({
  name: prop<string>(),
  placeholder: prop<string>(),
  entries: prop<any[]>(),
  highlightId: prop<string>(),
  display: prop<(id: string) => void>(),
  delete: prop<(id: string) => void>(),
  canManage: prop<(entry: object) => boolean>(),
  sqUtilities: injected<UtilitiesService>(),
  sqAnnotationActions: injected<AnnotationActions>(),
  sqAnnotationStore: injected<AnnotationStore>(),
  $state: injected<ng.ui.IStateService>()
});

export const JournalOverview: SeeqComponent<typeof journalOverviewBindings> = (props) => {
    const {
      entries, highlightId, delete: deleteEntry, canManage
    } = props;
    const { sqUtilities, sqAnnotationActions, sqAnnotationStore, $state } = useInjectedBindings(journalOverviewBindings);
    const { t } = useTranslation();
    const isPresentationMode = sqUtilities.isPresentationWorkbookMode;
    const worksheetEntries = entries ? sqAnnotationStore.findJournalEntries(entries, $state.params.worksheetId) : [];
    const annotationEntries = entries ? _.filter(entries, _.property('discoverable')) : [];

    /**
     * When the overview is closed either redisplay the single existing journal entry or a new entry if one does not
     * already exist. The close overview button is not displayed if there are more than one journal entries on the
     * worksheet so the user must choose which journal entry they want to view.
     */
    const closeOverview = () => {
      if (worksheetEntries.length === 1) {
        sqAnnotationActions.showEntry(worksheetEntries[0].id);
      } else {
        sqAnnotationActions.newEntry();
      }
    };

    return <div className="overflowAuto scrollAreaTop msOverflowStyleAuto flexFillOverflow">
      <div className="flexRowContainer flexFillOverflow">
        {/*The JournalCategory below is here for backwards compatibility, and is not thoroughly tested as a result.
         Also, the placeholder never appears.*/}
        {worksheetEntries.length > 1 &&
        <JournalCategory
          name={t('JOURNAL.CATEGORY.WORKSHEET.HEADER')}
          placeholder={isPresentationMode
            ? 'JOURNAL.CATEGORY.WORKSHEET.PLACEHOLDER_NOEDIT'
            : 'JOURNAL.CATEGORY.WORKSHEET.PLACEHOLDER'}
          entries={worksheetEntries}
          highlightId={highlightId}
          display={sqAnnotationActions.showEntry}
          delete={deleteEntry}
          canManage={canManage} />}

        <JournalCategory
          name={t('JOURNAL.CATEGORY.ANNOTATIONS.HEADER')}
          placeholder={t('JOURNAL.CATEGORY.ANNOTATIONS.PLACEHOLDER')}
          entries={annotationEntries}
          highlightId={highlightId}
          display={sqAnnotationActions.showEntry}
          delete={deleteEntry}
          canManage={canManage}>
          {worksheetEntries.length < 2 &&
          <TextButton
            testId="specJournalCloseBtn"
            id="specJournalCloseBtn"
            onClick={closeOverview}
            size="sm"
            extraClassNames="annotationsCloseBtn"
            label="CLOSE"
          />}
        </JournalCategory>
      </div>
    </div>;
  }
;

export const sqJournalOverview = angularComponent(journalOverviewBindings, JournalOverview);
