import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import moment from 'moment-timezone';
import { getValidationFunction } from '@/hybrid/formbuilder/formbuilder.utilities';
import { Field } from 'react-final-form';
import { Icon } from '@/hybrid/core/Icon.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { SampleInput } from '@/hybrid/tools/manualSignal/SampleInput.molecule';
import { SortIcon } from '@/hybrid/core/SortIcon.atom';

export type InputTableColumn = {
  headerKey: string,
  type: 'dateTime' | 'value',
  name: string // the store property used to access the column
};

export interface InputTableIF extends ValidatingFormComponent<any> {
  component: 'InputTableFormComponent';
  setEditingId: (editingId: string) => void;
  editingId: string;
  removeRow: (id) => void;
  timezone: { name: string, displayName: string, offset: string, offsetMinutes: number };
  inputType: 'sample' | 'capsule';
  columns: [InputTableColumn, InputTableColumn];
  sort: { asc: boolean, property };
  updateSort: ({ asc: boolean, property }) => void;
  hasValueTypeError?: boolean;
  isStringSignal?: boolean;
}

const InputTableFormComponentUnwrapped: React.FunctionComponent<InputTableIF> = (props) => {
  const {
    name,
    removeRow,
    extraClassNames,
    timezone,
    setEditingId,
    editingId,
    validation,
    extendValidation,
    inputType,
    hasValueTypeError,
    columns,
    sort,
    updateSort,
    isStringSignal = false,
    value // value is the array of rows for the table
  } = props;
  const { t } = useTranslation();

  const defaultValidation = value => value?.length === 0;

  const sortedRows = _.orderBy(value, (sample) => {
    return sort.property === 'dateTime'
      ? moment(sample.dateTime)
      : isStringSignal ? _.toUpper(sample.value) : _.toNumber(sample.value);
  }, [sort.asc ? 'asc' : 'desc']);

  const adjustSort = (property) => {
    const asc = property === sort.property ? !sort.asc : true;
    updateSort({ asc, property });
  };

  const renderCell = (column, row) => {
    if (column.type === 'dateTime') {
      return <p className="mb0 pt1 pb1 width-115" key={row.id}>
        {moment.utc(row[column.name]).tz(timezone.name).format('l LT')}
      </p>;
    } else {
      return <p key={row.id} className={classNames('mb0 pt1 pb1 width-75 aggressiveWordBreak',
        { 'sq-text-danger': hasValueTypeError && _.isNaN(_.toNumber(row[column.name])) })}>
        {row[column.name]}
      </p>;
    }
  };

  return (
    <Field name={name} validate={getValidationFunction(defaultValidation, validation, extendValidation)}>
      {() => (
        <>
          {/* Input to add new rows */}
          {inputType === 'sample' && <SampleInput timezone={timezone} testId="newSampleInput" />}

          {sortedRows.length > 0 &&
          <div className="overflowYAuto max-height-800 overflowXHidden mt10">
            <table
              id={name}
              className={classNames('table table-striped table-condensed mb10 fixedHeaderTable', extraClassNames)}
              data-testid={name}>
              <tr>
                <th />
                {_.map(columns, column => (
                  <th onClick={() => adjustSort(column.name)} key={column.name}>
                    {t(column.headerKey)}
                    <SortIcon sortAsc={sort.asc}
                      sortProperty={column.name}
                      sortBy={sort.property} />
                  </th>
                ))}
                <th />
              </tr>
              <tbody>
                {_.map(sortedRows, row => (
                  <>
                    <tr key={row.id} className="cursorPointer" data-testid="inputTableRow">
                      <td>
                        <Icon icon="fa-edit" onClick={() => setEditingId(row.id)} testId={`editIcon-${row.id}`} />
                      </td>
                      {_.map(columns, column => <td key={`${column.name}-${row.id}`}>{renderCell(column, row)}</td>)}
                      <td>
                        <HoverTooltip text="REMOVE" delay={500}>
                        <span>
                          <Icon icon="fa-remove" onClick={() => removeRow(row.id)} testId={`removeIcon-${row.id}`} />
                        </span>
                        </HoverTooltip>
                      </td>
                    </tr>
                    {row.id === editingId &&
                    <tr>
                      <td colSpan={4}>
                        {inputType === 'sample' &&
                        <SampleInput sample={row} timezone={timezone} testId="editSampleInput" />}
                      </td>
                    </tr>}
                  </>
                ))}
              </tbody>
            </table>
          </div>}
        </>
      )}
    </Field>
  );
};

export const InputTableFormComponent = React.memo(InputTableFormComponentUnwrapped, (prev, next) => {
  return !(prev.editingId !== next.editingId ||
    !_.isEqual(prev.timezone, next.timezone) ||
    !_.isEqual(prev.sort, next.sort) ||
    !_.isEqual(prev.value, next.value) ||
    prev.name !== next.name ||
    !_.isEqual(prev.columns, next.columns) ||
    prev.hasValueTypeError !== next.hasValueTypeError);
});
