import React, { useEffect, useRef, useState } from 'react';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { PluginsAdminStore } from '@/administration/pluginsAdmin.store';
import { NotificationsService } from '@/services/notifications.service';
import { ACLService } from '@/services/acl.service';
import { PluginsApi } from '@/sdk';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { CELL_TYPES, TableColumn } from '@/hybrid/core/Table.atom';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import _ from 'lodash';
import { ServerSideFilteringAndPaginatingTable } from '@/hybrid/core/ServerSideFilteringAndPaginatingTable.molecule';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { PluginsAdminActions } from '@/administration/pluginsAdmin.actions';
import { API_VERSION } from '@/hybrid/plugin/pluginApiVersion';
import { ItemAclModal } from '@/hybrid/accessControl/ItemAclModal.molecule';
import { ButtonWithManagedSpinner } from '@/hybrid/core/ButtonWithManagedSpinner.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';

const pluginTableBindings = bindingsDefinition({
  sqPluginsAdminActions: injected<PluginsAdminActions>(),
  sqPluginsAdminStore: injected<PluginsAdminStore>(),
  sqNotifications: injected<NotificationsService>(),
  SqACLService: injected<ACLService>(),
  sqPluginsApi: injected<PluginsApi>()
});

export const PluginTable: SeeqComponent<typeof pluginTableBindings> = () => {
  const {
    sqPluginsAdminActions,
    sqPluginsAdminStore,
    sqNotifications,
    sqPluginsApi
  } = useInjectedBindings(pluginTableBindings);

  const { t } = useTranslation();
  const [selectedIds, setSelectedIds] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [aclItemId, setAclItemId] = useState<string | undefined>(undefined);
  const plugins = useFluxPath(sqPluginsAdminStore, () => sqPluginsAdminStore.plugins);

  const tableLoading = useFluxPath(sqPluginsAdminStore, () => sqPluginsAdminStore.isTableLoading);

  useEffect(() => {
    sqPluginsAdminActions.loadPlugins();
  }, []);

  const pluginInputFileRef = useRef<HTMLInputElement>(null);

  const renderEditAcls = item => (
    <div
      className="btn btn-sm"
      onClick={() => setAclItemId(item.id)}
      data-testid={`openACLModal_${item.id}`}>
      <i className="pr5 fc fc-share" />
    </div>
  );

  const columns: TableColumn[] = [
    {
      accessor: 'id',
      sortable: false,
      filterable: false,
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 40, maxWidth: 40 }
    }, {
      accessor: 'name',
      searchProperty: 'nameSearch',
      header: 'ADMIN.PLUGIN.NAME',
      cellStyle: { minWidth: 120, wordWrap: 'break-word' }
    }, {
      accessor: 'identifier',
      searchProperty: 'identifierSearch',
      header: 'ADMIN.PLUGIN.IDENTIFIER',
      cellStyle: { wordWrap: 'break-word' }
    }, {
      accessor: 'category',
      searchProperty: 'categorySearch',
      header: 'ADMIN.PLUGIN.CATEGORY',
      cellStyle: { wordWrap: 'break-word' }
    }, {
      accessor: 'version',
      header: 'ADMIN.PLUGIN.VERSION',
      filterable: false,
      sortable: false,
      cellStyle: { width: 100, maxWidth: 100, wordWrap: 'break-word' }
    }, {
      accessor: 'id',
      header: 'ADMIN.PLUGIN.ID',
      filterable: false,
      sortable: false,
      cellStyle: { width: 250, maxWidth: 300 }
    }, {
      accessor: 'id',
      header: 'ADMIN.PLUGIN.ACCESS_CONTROL',
      cellStyle: { width: 100, maxWidth: 100 },
      cellRenderFunction: renderEditAcls,
      filterable: false,
      sortable: false
    }
  ];

  const toggleItemSelected = (item) => {
    const itemIndex = _.indexOf(selectedIds, item.id);
    if (itemIndex > -1) {
      setSelectedIds([..._.pull(selectedIds, item.id)]);
    } else {
      setSelectedIds([...selectedIds, item.id]);
    }
  };

  const filterUsers = (option, field) => {
    sqPluginsAdminActions.setFilter(field, option.value);
    sqPluginsAdminActions.loadPlugins();
  };

  const sortPlugins = (newSortProperty, oldSortOrder) => {
    const oldSortProperty = sqPluginsAdminStore.sortProperty;
    sqPluginsAdminActions.setSort(newSortProperty, oldSortProperty === newSortProperty ? !oldSortOrder : true);
    sqPluginsAdminActions.loadPlugins();
  };

  const refreshPlugins = () => {
    setIsRefreshing(true);
    setSelectedIds([]);
    sqPluginsAdminActions.resetPageNumber();
    return sqPluginsAdminActions.loadPlugins()
      .finally(() => setIsRefreshing(false));
  };

  const deletePlugins = () => {
    sqPluginsAdminActions.removePlugins(selectedIds)
      .then(() => {
        setSelectedIds([]);
      });
  };

  const renderTableLoadingIndicator = (
    <div className="flexColumnContainer flexCenter pt50 pb50">
      <i className="fa fa-spinner fa-pulse fa-5x sq-text-primary" />
    </div>);

  const nothingSelected = _.isEmpty(selectedIds);

  const openFileUploader = () => {
    pluginInputFileRef.current.click();
  };

  const onUploadFileSelected = (event) => {
    const pluginFile = event.target.files[0];
    sqPluginsApi.uploadPlugin({ file: pluginFile }, {
        transformRequest: _.identity,
        headers: { 'Content-Type': undefined }
      })
      .then(() => {
        refreshPlugins();
        sqNotifications.successTranslate('ADMIN.PLUGIN.UPLOADED');
      }).catch(sqNotifications.apiError)
      .finally(() => {
        pluginInputFileRef.current.value = '';
      });
  };

  return (<>
    <div className="flexColumnContainer flexSpaceBetween mb5">
      <TextButton
        id="deleteSelectedPlugins"
        onClick={deletePlugins}
        testId="deleteSelectedPlugins"
        disabled={nothingSelected}
        label="ADMIN.PLUGIN.DELETE_SELECTED" />

      <span className="mt5 text-muted">{t('ADMIN.PLUGIN.API_VERSION', { version: API_VERSION })}</span>

      <div className="flexColumnContainer">
        <ButtonWithManagedSpinner
          buttonProps={{ id: 'refreshPlugins', extraClassNames: 'mr5' }}
          spinnerIconProps={{ type: 'text', large: true }}
          label="ADMIN.PLUGIN.REFRESH_LIST"
          action={refreshPlugins}
          icon="fa-repeat" />

        <TextButton
          id="uploadPlugin"
          variant="theme"
          onClick={openFileUploader}
          icon="fa-upload"
          iconStyle="white"
          extraClassNames="mr15"
          label="ADMIN.PLUGIN.UPLOAD" />

        <input id="pluginFileInput" type="file" multiple={false} accept=".plugin"
          style={{ display: 'none' }} onChange={onUploadFileSelected} ref={pluginInputFileRef} />
      </div>
    </div>

    <ServerSideFilteringAndPaginatingTable
      testId="userAdministrationTable"
      rowSelectionCallback={toggleItemSelected}
      filterCallback={filterUsers}
      selectAllCallback={setSelectedIds}
      selectedIds={selectedIds}
      sortCallback={sortPlugins}
      columns={columns}
      items={plugins}
      store={sqPluginsAdminStore}
      actions={sqPluginsAdminActions}
      loadTable={sqPluginsAdminActions.loadPlugins}
    />
    {tableLoading && renderTableLoadingIndicator}
    {aclItemId && <ItemAclModal itemId={aclItemId} closeModal={() => setAclItemId(undefined)} />}
  </>);
};
