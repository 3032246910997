import SelectItem from '@/hybrid/core/SelectItem.organism';
import React, { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Popover } from 'react-bootstrap';
import {
  CAPSULE_PANEL_TREND_COLUMNS,
  COLUMNS_AND_STATS,
  ITEM_TYPES,
  PropertyColumn,
  StatisticColumn,
  TREND_SIGNAL_STATS
} from '@/trendData/trendData.module';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import {
  CustomPropertySelector,
  METRIC_CONDITION_PROPERTIES,
  SuggestedPropertiesMode
} from '@/hybrid/utilities/CustomPropertySelector.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Capsule } from '../../../../plugin/sdk/seeq';
import { ToolbarPopoverButton } from '@/hybrid/core/ToolbarPopoverButton.molecule';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { CONDITION_EXTRA_COLUMNS } from '@/hybrid/tableBuilder/tableBuilder.store';

interface ConditionStatsPropertiesButtonProps {
  isTransposed: boolean;
  propertyColumns: PropertyColumn[];
  conditions: Capsule[];
  isColumnEnabled: (column: StatisticColumn | PropertyColumn, referenceSeries: string) => boolean;
  toggleColumn: (column: StatisticColumn | PropertyColumn, referenceSeries: string) => void;
  addPropertyColumn: (propertyName: string) => void;
  removeColumn: (key: string) => void;
  isStringSignal: (signal: any) => boolean;
  isSmallButton?: boolean;
  extraClassNames?: string;
}

export const ConditionStatsPropertiesButton: React.FunctionComponent<ConditionStatsPropertiesButtonProps> = (props) => {
  const {
    isTransposed,
    propertyColumns,
    conditions,
    isColumnEnabled,
    toggleColumn,
    addPropertyColumn,
    removeColumn,
    isStringSignal,
    isSmallButton,
    extraClassNames
  } = props;
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState(null);
  const signalStats = isStringSignal(selectedItem) ? _.filter(TREND_SIGNAL_STATS, 'isStringCompatible') :
    TREND_SIGNAL_STATS;
  const capsulePropertyColumns = _.reject(propertyColumns, column =>
    _.some(CONDITION_EXTRA_COLUMNS, builtInColumn => column.key === builtInColumn));

  return (
    <ToolbarPopoverButton
      extraClassNames={classNames('mr6', extraClassNames)}
      icon={isTransposed ? 'fc-add-column' : 'fc-add-row'}
      label={isTransposed ? 'TABLE_BUILDER.INSERT_COLUMN' : 'TABLE_BUILDER.INSERT_ROW'}
      tooltipText={t('TABLE_BUILDER.INSERT_STATS_PROPERTIES_TOOLTIP')}
      tooltipPlacement="top"
      testId="conditionStatsPropertiesButton"
      onHide={() => setSelectedItem(null)}
      isSmall={isSmallButton}
    >
      <Popover className="max-width-450" id="condition-stats-properties-popover">
        <Popover.Title>{t('TABLE_BUILDER.INSERT_STATS_PROPERTIES_TOOLTIP')}</Popover.Title>
        <Popover.Content>
          <div className="flexColumnContainer">
            <div className="min-width-250">
              <SelectItem
                excludeWorkbookItems={true}
                showAddToDisplayPane={false}
                disableAutoSelect={true}
                includeMetadata={true}
                insideModal={true}
                itemTypes={[ITEM_TYPES.SERIES]}
                selectedItemId={selectedItem?.id}
                selectPlaceholder="SELECT_SIGNAL"
                onSelect={(item: any) => setSelectedItem(item)} />
              {_.map(signalStats, column => (
                <Checkbox
                  classes={selectedItem ? 'textPrimaryMenuItem' : ''}
                  id={column.key + '-checkbox'}
                  key={column.key + '-checkbox'}
                  disabled={!selectedItem}
                  label={t(column.title)}
                  isChecked={selectedItem && isColumnEnabled(column, selectedItem.id)}
                  onChange={() => toggleColumn(column, selectedItem.id)}
                  skipMemo={true} />
              ))}
            </div>
            <div className="ml10 min-width-150">
              <CustomPropertySelector
                suggestedPropertiesMode={SuggestedPropertiesMode.Capsules}
                conditions={conditions}
                dropdownPlaceholder='TABLE_BUILDER.HEADER_CAPSULE_PROPERTY'
                excludedProperties={_.map(propertyColumns, 'key').concat(METRIC_CONDITION_PROPERTIES)}
                extraProperties={[_.find(CAPSULE_PANEL_TREND_COLUMNS,
                  column => column.propertyName === SeeqNames.Properties.Duration)]}
                addPropertyColumn={({ propertyName }) => addPropertyColumn(propertyName)} />
              {_.map(capsulePropertyColumns, column => (
                <Checkbox
                  classes='textPrimaryMenuItem'
                  id={column.key + '-checkbox'}
                  key={column.key + '-checkbox'}
                  label={column.shortTitle}
                  isChecked={true}
                  onChange={() => removeColumn(column.key)}
                  skipMemo={true} />
              ))}
              <hr className="mt3 mb3" />
              {_.map(CONDITION_EXTRA_COLUMNS, key => (
                <Checkbox
                  classes='textPrimaryMenuItem'
                  id={key + '-checkbox'}
                  key={key + '-checkbox'}
                  label={COLUMNS_AND_STATS[key].shortTitle}
                  isChecked={isColumnEnabled(COLUMNS_AND_STATS[key], null)}
                  onChange={() => toggleColumn(COLUMNS_AND_STATS[key], null)}
                  skipMemo={true} />
              ))}
            </div>
          </div>
        </Popover.Content>
      </Popover>
    </ToolbarPopoverButton>
  );
};
