import { HOME_SCREEN_TABS } from '@/main/app.constants';
import { HOME_SCREEN_SORT, HOME_SCREEN_TABLE_TYPE } from '@/hybrid/homescreen/homescreen.module';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';
import _ from 'lodash';

export type HomeScreenStore = ReturnType<typeof sqHomeScreenStore>['exports'];
export type HelpDisplay = 'NONE' | 'EXPANDED' | 'COLLAPSED' | 'POPOVER';

export function sqHomeScreenStore() {

  return {
    persistenceLevel: PERSISTENCE_LEVEL.WORKBENCH,

    initialize() {
      this.state = this.immutable({
        isExact: false,
        currentTab: HOME_SCREEN_TABS.HOME,
        currentFolderId: '',
        currentlyLoading: '',
        tabFolders: {},
        pageNumberByTable: [],
        expandedFolderIds: [],
        folderTree: [],
        searchParams: {},
        loadingTable: [],
        itemsByTable: [],
        breadcrumbs: [],
        sortByTable: [
          { key: HOME_SCREEN_TABLE_TYPE.TAB, sortProperty: HOME_SCREEN_SORT.UPDATED_AT, sortAsc: true },
          { key: HOME_SCREEN_TABLE_TYPE.PINNED, sortProperty: HOME_SCREEN_SORT.NAME, sortAsc: true },
          { key: HOME_SCREEN_TABLE_TYPE.RECENT, sortProperty: HOME_SCREEN_SORT.OPENED_AT, sortAsc: false },
          { key: HOME_SCREEN_TABLE_TYPE.SEARCH, sortProperty: HOME_SCREEN_SORT.NAME, sortAsc: true }
        ],
        pageSizeByTable: [
          { key: HOME_SCREEN_TABLE_TYPE.TAB, size: 15 },
          { key: HOME_SCREEN_TABLE_TYPE.SEARCH, size: 15 },
          { key: HOME_SCREEN_TABLE_TYPE.PINNED, size: 5 },
          { key: HOME_SCREEN_TABLE_TYPE.RECENT, size: 5 }
        ],
        helpDisplay: 'EXPANDED' as HelpDisplay
      });
    },

    exports: {
      get currentTab() {
        return this.state.get('currentTab');
      },

      get currentFolderId() {
        return this.state.get('currentFolderId');
      },

      get tabFolders() {
        return this.state.get('tabFolders');
      },

      get expandedFolderIds() {
        return this.state.get('expandedFolderIds');
      },

      get folderTree() {
        return this.state.get('folderTree');
      },

      get breadcrumbs() {
        return this.state.get('breadcrumbs');
      },

      get searchParams() {
        return this.state.get('searchParams');
      },

      get isExact() {
        return this.state.get('isExact');
      },

      get helpDisplay() {
        return this.state.get('helpDisplay');
      },

      getPageNumberForTable(table) {
        return _.get(_.find(this.state.get('pageNumberByTable'), { key: table }), 'pageNumber', 1);
      },

      getSortForTable(table) {
        return _.find(this.state.get('sortByTable'), { key: table });
      },

      getPageSizeByTable(table) {
        return _.get(_.find(this.state.get('pageSizeByTable'), { key: table }), 'size', 15);
      },

      getItemsForTable(table) {
        return _.get(_.find(this.state.get('itemsByTable'), { key: table }), 'items', null);
      },

      getItemTotalForTable(table) {
        return _.get(_.find(this.state.get('itemsByTable'), { key: table }), 'totalResults', 0);
      },

      isTableLoading(table) {
        return _.indexOf(this.state.get('loadingTable'), table) > -1;
      }
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @returns {Object} The dehydrated state.
     */
    dehydrate() {
      return _.pick(this.state.serialize(), ['sortByTable', 'pageSizeByTable', 'helpDisplay']);
    },

    /**
     * Re-creates the workbench state.
     *
     * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
     * @return {Object} A promise that is fulfilled when the workbench is completely rehydrated.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      SET_CURRENT_HOME_SCREEN_TAB: 'setCurrentTab',
      SET_CURRENT_HOME_SCREEN_FOLDER: 'setCurrentFolderId',
      SET_TAB_FOLDER: 'setTabFolder',
      ADD_EXPANDED_FOLDER_IDS: 'addExpandedFolderIds',
      REMOVE_EXPANDED_FOLDER_IDS: 'removeExpandedFolderIds',
      MERGE_HOME_SCREEN_FOLDER_TREE: 'mergeFolderTree',
      SET_HOME_SCREEN_TABLE_SORT: 'setHomeScreenTableSort',
      SET_HOME_SCREEN_TABLE_LOADING: 'setHomeScreenTableLoading',
      SET_HOME_SCREEN_ITEMS_FOR_TABLE: 'setHomeScreenItemsForTable',
      SET_HOME_SCREEN_PAGE_NUMBER: 'setPageNumberForTable',
      SET_HOME_SCREEN_SEARCH_PARAMS: 'setHomeScreenSearchParams',
      SET_PAGE_SIZE_FOR_TABLE: 'setPageSizeForTable',
      CLEAR_HOME_SCREEN_ITEMS: 'clearHomeScreenItems',
      SET_BREADCRUMBS: 'setBreadcrumbs',
      CLEAR_BREADCRUMBS: 'clearBreadcrumbs',
      RESET_HOME_SCREEN_STORE: 'resetStore',
      SET_HOME_SCREEN_SEARCH_IS_EXACT: 'setIsExact',
      SET_HOME_SCREEN_HELP_DISPLAY: 'setHelpDisplay'
    },

    resetStore() {
      const stateToSave = _.pick(this.state.serialize(), ['sortByTable', 'pageSizeByTable']);
      this.state.merge(_.assign(stateToSave, { pageNumberByTable: [], searchParams: { textSearch: '' } }));
    },

    setBreadcrumbs({ crumbs }) {
      this.state.set('breadcrumbs', crumbs);
    },

    clearBreadcrumbs() {
      this.state.set('breadcrumbs', []);
    },

    clearHomeScreenItems() {
      this.state.set('folderTree', []);
      this.state.set('expandedFolderIds', []);
      this.state.set('itemsByTable', []);
    },

    setHomeScreenSearchParams({ searchParams }) {
      this.state.set('searchParams', searchParams);
    },

    setPageNumberForTable({ pageNumber, table }) {
      const idx = _.findIndex(this.state.get('pageNumberByTable'), { key: table });
      const cursor = this.state.select('pageNumberByTable', idx);

      if (cursor.exists()) {
        cursor.merge({ key: table, pageNumber });
      } else {
        this.state.set('pageNumberByTable', [...this.state.get('pageNumberByTable'), { key: table, pageNumber }]);
      }
    },

    setPageSizeForTable({ table, size }) {
      const idx = _.findIndex(this.state.get('pageSizeByTable'), { key: table });
      const cursor = this.state.select('pageSizeByTable', idx);
      if (cursor.exists()) {
        cursor.merge({ key: table, size });
      } else {
        this.state.set('pageSizeByTable', [...this.state.get('pageSizeByTable'), { key: table, size }]);
      }
    },

    setHomeScreenItemsForTable({ table, items, totalResults }) {
      const idx = _.findIndex(this.state.get('itemsByTable'), { key: table });
      const cursor = this.state.select('itemsByTable', idx);
      if (cursor.exists()) {
        cursor.merge({ items, totalResults });
      } else {
        this.state.set('itemsByTable', [...this.state.get('itemsByTable'), { key: table, items, totalResults }]);
      }

      const pageNumber = _.get(_.find(this.state.get('pageNumberByTable'), { key: table }), 'pageNumber');
      if (items.length === 0 && pageNumber > 0) {
        this.setPageNumberForTable({ pageNumber: pageNumber - 1, table });
      }
    },

    setHomeScreenTableLoading({ table }) {
      const currentlyLoading = [...this.state.get('loadingTable')];
      const tableIndex = _.indexOf(currentlyLoading, table);
      if (tableIndex > -1) {
        currentlyLoading.splice(tableIndex, 1);
      } else {
        currentlyLoading.push(table);
      }
      this.state.set('loadingTable', currentlyLoading);
    },

    setHomeScreenTableSort(payload) {
      const idx = _.findIndex(this.state.get('sortByTable'), { key: payload.table });
      const cursor = this.state.select('sortByTable', idx);
      if (cursor.exists()) {
        cursor.merge(_.omit(payload, 'table'));
      }
    },

    setCurrentTab(payload) {
      this.state.set('currentTab', payload.tab);
    },

    setCurrentFolderId(payload) {
      this.state.set('currentFolderId', payload.folderId);
    },

    setTabFolder({ key, folder }) {
      this.state.set('tabFolders', { ...this.state.get('tabFolders'), [key]: folder });
    },

    setIsExact(payload) {
      this.state.set('isExact', payload.isExact);
    },

    setHelpDisplay({ helpDisplay }) {
      this.state.set('helpDisplay', helpDisplay);
    },

    addExpandedFolderIds(payload) {
      this.state.set('expandedFolderIds', _.chain(this.state.get('expandedFolderIds'))
        .concat(payload.expandedFolderIds)
        .uniq()
        .value());
    },

    removeExpandedFolderIds(payload) {
      this.state.set('expandedFolderIds',
        _.without(this.state.get('expandedFolderIds'), ...payload.collapsedFolderIds));
    },

    mergeFolderTree(payload) {
      const tree = _.cloneDeep(this.state.get('folderTree'));
      _.forEach(payload.tree, (folder) => {
        const existing = findFolder(tree, folder.id);
        if (existing) {
          _.merge(existing, folder);
        } else {
          tree.push(folder);
        }
      });
      this.state.set('folderTree', _.sortBy(tree, [folder => _.lowerCase(folder.name), 'name']));
    }
  };

  function findFolder(folders, folderId) {
    if (!folders) return;
    for (const folder of folders) {
      if (_.toLower(folder.id) === _.toLower(folderId)) {
        return folder;
      }
      const candidate = findFolder(folder.subfolders, folderId);
      if (candidate) return candidate;
    }
  }
}
