import angular from 'angular';
import { ProfileSearchStore } from '@/hybrid/tools/profileSearch/profileSearch.store';
import { CalculationRunnerService } from '@/services/calculationRunner.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { ToolRunnerService } from '@/services/toolRunner.service';

angular.module('Sq.TrendData').service('sqProfileSearchActions', sqProfileSearchActions);
export type ProfileSearchActions = ReturnType<typeof sqProfileSearchActions>;

function sqProfileSearchActions(
  flux: ng.IFluxService,
  sqProfileSearchStore: ProfileSearchStore,
  sqToolRunner: ToolRunnerService,
  sqCalculationRunner: CalculationRunnerService,
  sqDateTime: DateTimeService) {
  const service = {
    search,
    setSimilarity,
    setNormalizeAmplitude,
    setNormalizeLocation
  };

  return service;

  /**
   * Initiate a profile search, using the specified parameters.
   * The parameters patternItem, patternStart, and patternEnd describe the reference pattern,
   * while searchSeries, rangeStart, and rangeEnd describe the search space.
   *
   * @param {String} inputSignalId - ID of item on which the pattern occurs.
   * @param {String} [color] - The desired result color, in hex code (e.g. #CCCCCC)
   */
  function search(inputSignalId, color) {
    const patternStart = sqDateTime.getMoment(sqProfileSearchStore.selectedRegion.min);
    const patternEnd = sqDateTime.getMoment(sqProfileSearchStore.selectedRegion.max);
    const { formula, parameters } = sqCalculationRunner.profileSearch(
      inputSignalId,
      patternStart,
      patternEnd,
      sqProfileSearchStore.similarity,
      sqProfileSearchStore.normalizeAmplitude,
      sqProfileSearchStore.normalizeLocation);

    return sqToolRunner.panelExecuteCondition(
      sqProfileSearchStore.name,
      formula,
      parameters,
      sqProfileSearchStore.configParams,
      sqProfileSearchStore.id,
      color
    );
  }

  /**
   * Sets the similarity for the profile search.
   * @param {String} similarity - The similarity for the search.
   */
  function setSimilarity(similarity) {
    flux.dispatch('PROFILE_SEARCH_SET_SIMILARITY', { similarity });
  }

  /**
   * Sets the choice to normalize amplitude for the profile search.
   * @param {String} normalizeAmplitude - True to enable.
   */
  function setNormalizeAmplitude(normalizeAmplitude) {
    flux.dispatch('PROFILE_SEARCH_SET_NORMALIZE_AMPLITUDE', { normalizeAmplitude });
  }

  /**
   * Sets the choice to normalize location for the profile search.
   * @param {String} normalizeLocation - True to enable.
   */
  function setNormalizeLocation(normalizeLocation) {
    flux.dispatch('PROFILE_SEARCH_SET_NORMALIZE_LOCATION', { normalizeLocation });
  }
}
