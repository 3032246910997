/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class AuthApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Get a collection of authentication providers
   */
  public getAuthProviders(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/auth/providers',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AuthProvidersOutputV1>;
  }

  /**
   * 
   * @summary Login
   * @param {models.AuthInputV1} body - Login information
   */
  public login(
    body: models.AuthInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/auth/login',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.UserOutputV1>;
  }

  /**
   * 
   * @summary Invalidate the session to be completely unusable
   */
  public logout(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/auth/logout',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<string>;
  }

}
