/**
 * FormBuilder generates forms based on the provided form definition Array.
 **/
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Form } from 'react-final-form';
import CancelAndExecute from '@/hybrid/core/CancelAndExecute.molecule';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import {
  decorateWithFieldNames,
  decorateWithNumbers,
  flattenFormDefinition,
  renderIt
} from '@/hybrid/formbuilder/formbuilder.utilities';
import { CancelAndSave } from '@/hybrid/core/CancelAndSave.molecule';

interface FormBuilderProps {
  // the formDefinition Array of FormElements defines the form to be rendered
  formDefinition: FormElement[];
  // the function that is called when the form is submitted
  submitFn: (any) => void | Promise<any>;
  // the function that is called when the cancel button is clicked
  closeFn: () => void;
  // name of the tool (legacy - may no longer apply)
  toolName?: string;
  // id of the tool (legacy - may no longer apply)
  toolId?: string;
  // id of the submitBtnId (legacy - may no longer apply)
  submitBtnId?: string;
  // if debug is enabled then the form values and form errors are rendered below this form;
  debug?: boolean;
  // by default we assume we're creating a tool panel, however if we want to use a CancelAndSave as the button
  // component we can do so by setting this to true
  saveAndCancel?: boolean;
  // option to save the valid state in the parent component
  setIsValid?: (isValid: boolean) => void;
  // option to have the cancel button say something other than 'Cancel'
  cancelBtnLabel?: string;
  // option to have the submit button say something other than 'Execute'
  submitBtnLabel?: string;
  // option to hide the cancel button
  hideCancel?: boolean;
  // wrap the form in a panel
  wrapInPanel?: boolean;
  // extra classnames applied if not wrapped in panel
  extraClassNamesUnwrapped?: string;
}

export const FormBuilder: React.FunctionComponent<FormBuilderProps> = (props) => {
  const {
    formDefinition,
    submitFn,
    closeFn,
    toolName,
    toolId,
    cancelBtnLabel,
    submitBtnId,
    submitBtnLabel,
    debug = false,
    saveAndCancel = false,
    hideCancel = false,
    wrapInPanel = true,
    setIsValid = _.noop,
    extraClassNamesUnwrapped
  } = props;

  const [formData, setFormData] = useState([]);

  const flatFormDefinition: FormElement[] = flattenFormDefinition(formDefinition);
  const withFieldNames = decorateWithFieldNames(formDefinition);
  const withDisplayNumbers = decorateWithNumbers(withFieldNames);

  useEffect(() => {
    const formData = _.chain(flatFormDefinition)
      .keyBy('name')
      .mapValues('value')
      .value();
    setFormData(formData as any);
  }, [formDefinition]);

  const renderToolPanelForm = (handleSubmit, enableSubmitBtn) => (
    <div className={wrapInPanel ? 'panel panel-primary pb10' : extraClassNamesUnwrapped}>
      {_.map(withDisplayNumbers, def => renderIt(def))}
      <div className={wrapInPanel ? 'pb10' : ''}>
        <CancelAndExecute
          cancelAction={closeFn}
          submitAction={() => Promise.resolve()
            .then(handleSubmit)
          }
          toolName={toolName}
          toolId={toolId}
          btnDisabled={!enableSubmitBtn}
          cancelBtnLabel={cancelBtnLabel}
          submitBtnLabel={submitBtnLabel}
          hideCancel={hideCancel}
          submitBtnId={submitBtnId} />
      </div>
    </div>);

  const renderSaveAndCancelForm = (handleSubmit, enableSubmitBtn, values) => (<>
      {_.map(withDisplayNumbers, def => renderIt(def))}
      <div className="pb10 mt30 flexColumnContainer flexCenter">
        <CancelAndSave submitFn={() => Promise.resolve()
          .then(handleSubmit)
        } cancelFn={closeFn}
          values={values}
          btnDisabled={!enableSubmitBtn}
          cancelBtnLabel={cancelBtnLabel}
          submitBtnLabel={submitBtnLabel} />
      </div>
    </>
  );

  const renderForm = () => {
    return (
      <Form
        onSubmit={submitFn}
        initialValues={formData}
        render={({ handleSubmit, values, errors, submitFailed, modified, form }) => {
          /**
           * the submit button should be enabled if:
           *  - the form is pristine (no fields have been modified)
           *  - if the form has already been submitted once, then the button should only be enabled
           *    if there are no errors
           *  - if the form has not been submitted, and all modified fields are valid
           **/
          const modifiedFields = _.filter(_.keys(modified), key => modified[key] ? key : null);
          const defaultProvidedFields = _.map(
            _.filter(flatFormDefinition, item => item.defaultProvided ? item.name : null), 'name');
          const modifiedFieldsWithErrors = _.filter(_.keys(errors),
            error => _.includes(modifiedFields, error) ? error : null);
          const defaultProvidedFieldsWithErrors = _.filter(_.keys(errors),
            error => _.includes(defaultProvidedFields, error) ? error : null);
          const enableSubmitBtn = submitFailed ? _.size(errors) < 1 :
            (_.size(modifiedFieldsWithErrors) < 1 && _.size(defaultProvidedFieldsWithErrors) < 1);
          setIsValid(enableSubmitBtn);

          return <form onSubmit={handleSubmit}>
            {!saveAndCancel && renderToolPanelForm(handleSubmit, enableSubmitBtn)}
            {saveAndCancel && renderSaveAndCancelForm(handleSubmit, enableSubmitBtn, values)}
            {debug && <>
              <pre>{JSON.stringify(values, null, 2)}</pre>
              <pre>{JSON.stringify(errors, null, 2)}</pre>
            </>
            }
          </form>;
        }} />
    );
  };

  return renderForm();
};

