import _ from 'lodash';
import React from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';

const tableBuilderOldMetricTableBindings = bindingsDefinition({
  oldMetrics: prop<OldMetric[]>()
});

interface OldMetric {
  metricId: string;
  name: string;
  backgroundColor: string;
  foregroundColor: string;
  valueResult: string;
}

export const TableBuilderOldMetricTable: SeeqComponent<typeof tableBuilderOldMetricTableBindings> = (props) => {

  const {
    oldMetrics
  } = props;

  return <>
    {oldMetrics.length > 0
    && <table className="table-striped screenshotSizeToContent"
      data-testid="oldScorecardTable">
      <tbody>
        {_.map(oldMetrics, item =>
          <tr className="height-30" data-testid="scorecardRowOld" key={item.metricId}>
            <td className={item.name !== '' ? 'p7' : ''}
              key={`${item.metricId}_name`}
              data-testid="scorecardNameCellOld">
              {item.name}
            </td>
            <td className="p7"
              style={{ backgroundColor: item.backgroundColor, color: item.foregroundColor }}
              key={`${item.metricId}_value`}
              data-testid="scorecardDataCellOld">
              {item.valueResult}
            </td>
          </tr>)}
      </tbody>
    </table>}
  </>;
};
