import _ from 'lodash';
import angular from 'angular';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { UtilitiesService } from '@/services/utilities.service';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import {
  LABEL_LOCATIONS,
  LABEL_PROPERTIES,
  TREND_PANELS,
  TREND_PANELS_SORT,
  TREND_VIEWS
} from '@/trendData/trendData.module';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.module';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';
import { NotificationsService } from '@/services/notifications.service';
import { FrontendDuration } from '@/services/systemConfiguration.service';
import { SummaryTypeEnum } from 'sdk/model/ContentInputV1';

angular.module('Sq.TrendData').store('sqTrendStore', sqTrendStore);

export type TrendStore = ReturnType<typeof sqTrendStore>['exports'];

export enum CapsuleTimeColorMode {
  Signal = 'signal',
  Rainbow = 'rainbow',
  SignalGradient = 'signalGradient',
  ConditionGradient = 'conditionGradient'
}

export const SummarySliderValues = {
  [SummaryTypeEnum.DISCRETE]: {
    value: {
      0: { value: 0, units: 'min' },
      1: { value: 1, units: 'min' },
      2: { value: 5, units: 'min' },
      3: { value: 15, units: 'min' },
      4: { value: 30, units: 'min' },
      5: { value: 1, units: 'h' },
      6: { value: 4, units: 'h' },
      7: { value: 8, units: 'h' },
      8: { value: 12, units: 'h' },
      9: { value: 1, units: 'day' },
      10: { value: 1, units: 'week' }
    }
  },
  [SummaryTypeEnum.AUTO]: { value: { 0: -1, 1: .8, 2: .6, 3: .4, 4: .2, 5: 0 } }
};

export const SummaryTypeSettings = {
  [SummaryTypeEnum.DISCRETE]: { min: 0, max: 10, step: 1 },
  [SummaryTypeEnum.AUTO]: { min: 0, max: 5, step: 1 },
  [SummaryTypeEnum.NONE]: { min: 0, max: 0, step: 0 }
};

function sqTrendStore(
  sqUtilities: UtilitiesService,
  sqWorksheetStore: WorksheetStore,
  sqTrendDataHelper: TrendDataHelperService,
  sqNotifications: NotificationsService,
  sqTrendSeriesStore: TrendSeriesStore,
  $translate: ng.translate.ITranslateService
) {

  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.WORKSHEET,

    /**
     * Initializes the store by setting default values to the stored state
     */
    initialize() {
      const enabledColumns = {};
      enabledColumns[TREND_PANELS.CAPSULES] = { startTime: true };

      this.state = this.immutable({
        view: TREND_VIEWS.CALENDAR,
        enabledColumns,
        propertyColumns: {},
        selectedRegion: {
          min: 0,
          max: 0
        },
        capsuleTimeOffsets: {
          lower: 0,
          upper: 0
        },
        dataSummary: {
          type: SummaryTypeEnum.AUTO,
          value: 0,
          discreteUnits: { value: 0, units: 'min' },
          isSlider: true
        },
        isCapsuleTimeLimited: false,
        capsuleTimeColorMode: CapsuleTimeColorMode.Signal,
        capsuleAlignment: [],
        capsulePreview: false,
        showChartConfiguration: false,
        dimDataOutsideCapsules: false,
        hideUnselectedItems: false,
        labelDisplayConfiguration: {
          name: LABEL_LOCATIONS.OFF,
          asset: LABEL_LOCATIONS.OFF,
          assetPathLevels: 1,
          unitOfMeasure: LABEL_LOCATIONS.OFF,
          custom: LABEL_LOCATIONS.OFF,
          customLabels: []
        },
        showCapsuleLaneLabels: false,
        xValue: null,
        panelProps: {},
        panelSorters: TREND_PANELS_SORT,
        capsulePanelOffset: 0,
        capsulePanelHasNext: false,
        capsulePanelIsLoading: false,
        pointValues: {},
        showGridlines: false
      });
    },

    exports: {
      get view() {
        return this.state.get('view');
      },

      get selectedRegion() {
        return this.state.get('selectedRegion');
      },

      get capsuleTimeOffsets() {
        return this.state.get('capsuleTimeOffsets');
      },

      get areCapsuleTimeOffsetsChanged() {
        return this.state.get('capsuleTimeOffsets', 'lower') !== 0 ||
          this.state.get('capsuleTimeOffsets', 'upper') !== 0;
      },

      get isCapsuleTimeLimited() {
        return this.state.get('isCapsuleTimeLimited');
      },

      get capsuleTimeColorMode() {
        return this.state.get('capsuleTimeColorMode');
      },

      get capsuleAlignment() {
        return this.state.get('capsuleAlignment');
      },

      get dimDataOutsideCapsules() {
        return this.state.get('dimDataOutsideCapsules');
      },

      get hideUnselectedItems() {
        return this.state.get('hideUnselectedItems');
      },

      get xValue() {
        return this.state.get('xValue');
      },

      get annotationId() {
        return this.state.get('annotationId');
      },

      get showChartConfiguration() {
        return this.state.get('showChartConfiguration');
      },

      get labelDisplayConfiguration() {
        return this.state.get('labelDisplayConfiguration');
      },

      get showLaneLabels() {
        return this.state.get('showLaneLabels');
      },

      get showCapsuleLaneLabels() {
        return this.state.get('showCapsuleLaneLabels');
      },

      get pointValues() {
        return this.state.get('pointValues');
      },

      get capsulePanelOffset() {
        return this.state.get('capsulePanelOffset');
      },

      get capsulePanelHasNext() {
        return this.state.get('capsulePanelHasNext');
      },

      get capsulePanelIsLoading() {
        return this.state.get('capsulePanelIsLoading');
      },

      get capsulePreview() {
        return this.state.get('capsulePreview');
      },

      get showGridlines() {
        return this.state.get('showGridlines');
      },

      get dataSummary() {
        return this.state.get('dataSummary');
      },

      /**
       * Property columns are custom columns whose values come from a property of the item. The `propertyColumns`
       * property contains all the enabled and disabled columns.
       */
      propertyColumns(panel) {
        return _.sortBy(this.state.get('propertyColumns', panel), 'title');
      },

      getPanelProps(panel) {
        return this.state.get('panelProps', panel) || {};
      },

      getPanelSort(panel) {
        return this.state.get('panelSorters', panel);
      },

      isColumnEnabled(panel, column) {
        return this.state.exists('enabledColumns', panel, column);
      },

      customColumns(panel) {
        return _.chain(this.state.get('enabledColumns', panel)).pickBy(_.isObject).values().value();
      },

      enabledColumns(panel) {
        return this.state.get('enabledColumns', panel);
      },

      isRegionSelected() {
        return this.state.get('selectedRegion', 'min') !== this.state.get('selectedRegion', 'max');
      },

      isTrendViewCapsuleTime() {
        return this.state.get('view') === TREND_VIEWS.CAPSULE && sqWorksheetStore.view.key === WORKSHEET_VIEW.TREND;
      },
      isTrendViewChainView() {
        return this.state.get('view') === TREND_VIEWS.CHAIN && sqWorksheetStore.view.key === WORKSHEET_VIEW.TREND;
      },

      get uniqueLanes() {
        return this.getUniqueLanes();
      },

      get lanes() {
        return this.getLanes(sqTrendDataHelper.getAlignableItems().length);
      },

      get nextLane() {
        return this.getNextLane();
      },

      get alignments() {
        return this.getAlignments(sqTrendDataHelper.getAlignableItems().length);
      },

      get nextAlignment() {
        return this.getNextAlignment();
      },

      get uniqueAlignments() {
        return this.getUniqueAlignments();
      },

      shouldGridlinesBeShown(alignedItems) {
        return this.shouldGridlinesBeShown(alignedItems);
      },

      /**
       * Fetches the formula being used to summarize the plot data, in the form needed
       * to attach it to another signal
       */
      getDiscreteSummaryFormula() {
        return this.getDiscreteSummaryFormula();
      },

      isSummarizationActive(): boolean {
        return this.isSummarizationActive();
      },

      /**
       * Retrieves the summary mode and level as a string to use a label or informative tooltip
       */
      getSummaryAsString() {
        const summary = this.state.get('dataSummary');
        if (!this.isSummarizationActive()) {
          return '';
        } else if (summary.type === SummaryTypeEnum.DISCRETE) {
          return `${$translate.instant(
            'TOOLBAR.SUMMARY.DISCRETE_SUMMARY_AS_STRING_LABEL')} ${summary.discreteUnits.value} ${summary.discreteUnits.units}`;
        } else if (summary.type === SummaryTypeEnum.AUTO) {
          return `${$translate.instant(`TOOLBAR.SUMMARY.AUTO_SUMMARY_AS_STRING_LABEL`)} ${summary.value}`;
        }
      },

      /**
       * Builds the formula to be used to summarize the data if DataSummarizer is activated
       * @param seriesID - Used to filter out String signals
       */
      buildSummarizeFormula(seriesID) {
        const summary = this.state.get('dataSummary');
        const isString = sqUtilities.isStringSeries(sqTrendSeriesStore.findItem(seriesID));
        if (isString || summary.type === SummaryTypeEnum.NONE || summary.discreteUnits.value <= 0) {
          return '';
        } else {
          return this.getDiscreteSummaryFormula();
        }
      },

      /**
       * Builds either the spikeCatcher formula or the trendSummarizer formula needed to downsample a particular trend
       * @param lanewidth - The width of a lane of a pixels given in time
       *                                    [Example: '14ms']
       * @param seriesId - Id for the series being downsampled
       * @param stats - string af stats to calculate for the series with leading comma
       *                                    [Example: ', minValue(), range(), average(), stdDev()' ]
       * @param condition - a condition to check against to decide what to display in the downsampled data
       *                                    [Example: 'condition(19ms, capsule(15ms, 15ms))']
       *
       * @returns downsampleFormula : string - the string representation of the Formula we will be chaining to the
       * signal                             [Example: `.spikeCatcher(14ms)` ]
       */
      buildDownsampleFormula(lanewidth: string, seriesId: any, condition?: string, stats?: string) {
        const summary = this.state.get('dataSummary');
        condition = !condition ? '' : `, ${condition}`; // condition does not come in with a leading comma
        stats = !stats ? '' : stats; // Stats come in with a leading comma
        const isString = sqUtilities.isStringSeries(sqTrendSeriesStore.findItem(seriesId));
        const isSpikeCatcher = summary.type !== SummaryTypeEnum.AUTO || summary.value === 0 || isString;
        const beginFormula = isSpikeCatcher ? '.spikeCatcher(' : '.trendSummarizer(';
        const trendSummarizerFactor = !isSpikeCatcher ?
          `, ${SummarySliderValues[SummaryTypeEnum.AUTO].value[summary.value]}` : '';
        const endFormula = `${lanewidth}${condition}${trendSummarizerFactor}${stats})`;
        return `${beginFormula}${endFormula}`;
      }
    },

    /**
     * Dehydrates the item by retrieving the current set parameters in view
     * @returns {Object} An object with the state properties as JSON
     */
    dehydrate() {
      return _.omit(this.state.serialize(), ['xValue', 'pointValues', 'capsulePanelIsLoading',
        'isCapsuleTimeLimited']);
    },

    /**
     * Rehydrates item from dehydrated state
     *
     * @param {Object} dehydratedState - State object that should be restored
     */
    rehydrate(dehydratedState) {
      this.state.deepMerge(dehydratedState);
    },

    /**
     * sqTrendSeriesStore and sqTrendScalar store are required to calculate lanes and alignments
     */
    rehydrateWaitFor: ['sqTrendSeriesStore', 'sqTrendScalarStore'],

    handlers: {
      TREND_SET_VIEW: 'setView',
      TREND_SET_COLUMN_ENABLED: 'setColumnEnabled',
      TREND_ADD_PROPERTY_COLUMN: 'addPropertiesColumn',
      TREND_REMOVE_PROPERTY_COLUMN: 'removePropertiesColumn',
      TREND_REMOVE_ITEMS: 'removeItemColumns',
      TREND_SET_PANEL_PROPS: 'setPanelProps',
      TREND_SET_PANEL_SORT: 'setPanelSort',
      TREND_SET_CAPSULE_PANEL_OFFSET: 'setCapsulePanelOffset',
      TREND_SET_CAPSULE_PANEL_HAS_NEXT: 'setCapsulePanelHasNext',
      TREND_SET_CAPSULE_PANEL_IS_LOADING: 'setCapsulePanelIsLoading',
      TREND_SET_SELECTED_REGION: 'setSelectedRegion',
      TREND_SET_CAPSULE_TIME_OFFSETS: 'setCapsuleTimeOffsets',
      TREND_TOGGLE_CAPSULE_ALIGNMENT: 'toggleCapsuleAlignment',
      TREND_SET_CAPSULE_TIME_LIMITED: 'setCapsuleTimeLimited',
      TREND_SET_CAPSULE_TIME_COLOR_MODE: 'setCapsuleTimeColorMode',
      TREND_TOGGLE_HIDE_UNSELECTED_ITEMS: 'toggleHideUnselectedItems',
      TREND_CLEAR_CAPSULE_ALIGNMENT: 'clearCapsuleAlignment',
      TREND_TOGGLE_DIM_DATA_OUTSIDE_CAPSULES: 'toggleDimDataOutsideCapsules',
      TREND_SET_X_VALUE: 'setXValue',
      TREND_SET_ANNOTATION_ID: 'setAnnotationId',
      TREND_SET_SHOW_CHART_CONFIG: 'setShowChartConfig',
      TREND_SET_POINT_VALUE: 'setPointValue',
      TREND_CLEAR_POINT_VALUES: 'clearPointValues',
      TREND_SET_LABEL_DISPLAY_CONFIG: 'setLabelDisplayConfiguration',
      TREND_SET_GRIDLINES: 'setGridlines',

      TREND_SET_SUMMARY: 'setSummary',
      TREND_SET_SHOW_CAPSULE_LABELS: 'setShowCapsuleLabels',
      TREND_SWAP_ITEMS: 'swapReferences',
      TREND_SET_CUSTOM_LABEL: 'setCustomLabel',
      TREND_REMOVE_CUSTOM_LABEL: 'removeCustomLabel',
      TREND_SET_CAPSULE_PREVIEW: 'setCapsulePreview'
    },

    /**
     * Sets the current value of the X-axis, which is the x-value under the current cursor location.
     * @param {Object} payload - Object container for arguments
     * @param {Number} payload.xValue - Value of x-axis location, in milliseconds.
     */
    setXValue(payload) {
      this.state.set('xValue', payload.xValue);
    },

    /**
     * Turns the display of the chart configuration columns on or off
     *
     * @param {Object} payload - Object container for arguments
     * @param {Boolean} payload.showConfig - A flag indicating whether to show or hide the chart config columns
     */
    setShowChartConfig(payload) {
      this.state.set('showChartConfiguration', payload.showConfig);
    },

    /**
     * Sets the view of the trend to be displayed to the user.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Boolean} payload.view - The view of the trend to be displayed to the user.
     */
    setView(payload) {
      this.state.set('view', payload.view);
    },

    /**
     * Turns the display of a particular column on or off.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.panel - The name of the panel to which the column belongs
     * @param {String} payload.column - The key that identifies the column.
     * @param {Object} [payload.columnDefinition] - A custom column definition
     * @param {String} payload.columnDefinitions.key - A unique combination of statistic key and item id
     * @param {String} payload.columnDefinitions.referenceSeries - The item id that this column references
     * @param {String} payload.columnDefinitions.statisticKey - The statistic key
     * @param {Boolean} payload.enabled - Whether or not it is enabled
     */
    setColumnEnabled(payload) {
      const cursor = this.state.select('enabledColumns', payload.panel);
      const columnDefinition = payload.columnDefinition || true;

      if (payload.enabled) {
        cursor.set(payload.column, columnDefinition);
      } else {
        cursor.unset(payload.column);
      }
    },

    /**
     * Adds to the list of property columns
     *
     * @param  {Object} payload - argument container
     * @param  {String} payload.panel - The name of the panel to which the column belongs
     * @param  {Object} payload.property - column definition for the property
     */
    addPropertiesColumn(payload) {
      this.state.set(['propertyColumns', payload.panel, payload.property.key], payload.property);
    },

    /**
     * Removes from the list of property columns
     *
     * @param  {Object} payload - argument container
     * @param  {String} payload.panel - The name of the panel to which the column belongs
     * @param  {Object} payload.property - column definition for the property
     */
    removePropertiesColumn(payload) {
      const cursor = this.state.select('propertyColumns', payload.panel);
      cursor.unset(payload.property.key);
    },

    /**
     * Sets visibiity of gridlines on the chart.
     * Does not show gridlines if we have multiple unaligned y axes in any lane, and instead displays a warning.
     * @param payload - argument container
     * @param payload.showGridlines - true if we want to turn on gridlines, false otherwise
     * @param payload.skipWarning - true if we want to skip the "Gridlines can not be shown..." warning
     *    (i.e. if the caller has already shown teh warning), false/undefined otherwise
     */
    setGridlines(payload: { showGridlines: boolean, skipWarning: boolean }): void {
      let showGridlines = payload.showGridlines;
      const alignableItems = sqTrendDataHelper.getAlignableItems({ workingSelection: true });

      // If we're calling setGridlines solely to remove the gridlines, assume we've already determined
      // that there are multiple y axes in one lane so we don't show a second warning and do unnecessary computation
      if (!payload.skipWarning && !this.shouldGridlinesBeShown(alignableItems)) {
        sqNotifications.infoTranslate('NO_GRIDLINES_FOR_MULTIPLE_Y_AXES_ONE_LANE');
        showGridlines = false;
      }

      this.state.set('showGridlines', showGridlines);
    },

    setSummary(payload: { type: string, value: number, isSlider: boolean, discreteUnits: FrontendDuration }) {
      payload.value = _.toNumber(payload.value);
      this.state.set('dataSummary', payload);
    },

    isSummarizationActive() {
      const summary = this.state.get('dataSummary');
      return summary.value > 0 || summary.discreteUnits.value > 0;
    },

    /**
     * Checks if we have multiple Y axes in any lane of the chart. Since gridlines are only shown in one color,
     * it can be confusing/look ugly if we show gridlines for multiple axes in the same lane.
     * @param alignedItems: items that could be aligned on the chart
     * @return true if no lane has multiple Y axes, false otherwise
     */
    shouldGridlinesBeShown(alignedItems: { lane: number, axisAlign, string }[]) {
      // Filter out items in the same lane with different Y axis alignments
      const numUniqueLaneAlignments = _.chain(alignedItems)
        .uniqWith((item1, item2) => item1.lane === item2.lane && item1.axisAlign !== item2.axisAlign)
        .size()
        .value();

      // If we didn't filter any items out above, we're good to go with gridlines
      return numUniqueLaneAlignments === _.size(alignedItems);
    },

    /**
     * Removes any columns that are related to the items being removed. It looks for any columns that have an
     * referenceSeries that matches one of the ids being removed.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} payload.ids - Array of item ids
     */
    removeItemColumns(payload) {
      let ids = payload.ids;
      if (!payload.ids && _.isArray(payload.items)) {
        ids = _.map(payload.items, _.property('id'));
      }

      _.forEach(this.state.get('enabledColumns'), (columns, panel) => {
        _.forEach(columns, (columnDefinition, column) => {
          if (_.isObject(columnDefinition) && _.includes(ids, columnDefinition.referenceSeries)) {
            this.setColumnEnabled({ panel, column, enabled: false });
          }
        });
      });
    },

    /**
     * Swaps out any column definitions that reference items that have been swapped.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object} payload.swaps - The items that were swapped where the keys are the swapped out ids and the
     *   values are the corresponding swapped in ids.
     */
    swapReferences(payload) {
      _.forEach(this.state.get('enabledColumns'), (columns, panel) => {
        _.forEach(columns, (columnDefinition, column) => {
          const swapInId = _.isObject(columnDefinition) ? payload.swaps[columnDefinition.referenceSeries] : undefined;
          if (swapInId) {
            this.state.unset(['enabledColumns', panel, column]);
            this.state.set(['enabledColumns', panel, column.replace(columnDefinition.referenceSeries, swapInId)],
              _.assign({}, columnDefinition, {
                referenceSeries: swapInId,
                key: columnDefinition.key.replace(columnDefinition.referenceSeries, swapInId)
              }));
          }
        });
      });
    },

    /**
     * Sets the offset for the capsule panel.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Number} payload.offset - The offset in table rows being retrieved.
     */
    setCapsulePanelOffset(payload) {
      this.state.set('capsulePanelOffset', payload.offset);
    },

    /**
     * Sets whether there are more capsules available to fetch.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Boolean} payload.hasNext - True if there are more to fetch, false otherwise
     */
    setCapsulePanelHasNext(payload) {
      this.state.set('capsulePanelHasNext', payload.hasNext);
    },

    /**
     * Sets whether data is loading for the capsules panel.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Boolean} payload.isLoading - True if data is loading, false otherwise
     */
    setCapsulePanelIsLoading(payload) {
      this.state.set('capsulePanelIsLoading', payload.isLoading);
    },

    /**
     * Sets the column and direction for sorting data in a panel.
     *
     * @param {Object} payload - An object container for arguments
     * @param {String} payload.panel - The name of the panel to sort
     * @param {String} payload.sortBy - The name of the column by which to sort
     * @param {Boolean} payload.sortAsc - True if sorting in ascending order, false otherwise
     */
    setPanelSort(payload) {
      this.state.set(['panelSorters', payload.panel], _.omit(payload, 'panel'));
    },

    /**
     * Sets the specified CSS style properties on a panel.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.panel - Name of the panel.
     * @param {String} payload.props - Properties used by the panel, such as height, color, etc.
     */
    setPanelProps(payload) {
      this.state.set(['panelProps', payload.panel], payload.props);
      this.state.commit(); // Ensures a smooth drag since the value changes onMouseMove
    },

    /**
     * Sets a region of the chart as selected, specified by two x-axis values. Specifying 0 values for min/max
     * will clear any previously selected region.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Number} payload.min - Lower x-value of selected region.
     * @param {Number} payload.max - Upper x-value of selected region.
     */
    setSelectedRegion(payload) {
      const cursor = this.state.select('selectedRegion');
      if (cursor.get('min') !== payload.min || cursor.get('max') !== payload.max) {
        cursor.set({ min: payload.min, max: payload.max });
      }
    },

    /**
     * Sets the offsets used for the x-axis in capsule time.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Number} payload.lower - Lower offset in capsule time.
     * @param {Number} payload.upper - Upper offset in capsule time.
     */
    setCapsuleTimeOffsets(payload) {
      this.state.set('capsuleTimeOffsets', payload);
    },

    /**
     * Toggles the specified capsule alignment method used in capsule time. Up to two alignment methods may be
     * used at a time, enabled using separate calls to this function. Adding a third alignment method will
     * remove the first method specified. If a specified alignment is already enabled, it will be toggled off.
     *
     * @param   {Object} payload - Object container for arguments
     * @param   {String} payload.capsuleAlignment - Alignment method to toggle.
     */
    toggleCapsuleAlignment(payload) {
      const cursor = this.state.select('capsuleAlignment');
      const index = cursor.get().indexOf(payload.capsuleAlignment);

      if (index > -1) {
        cursor.splice([index, 1]);
      } else {
        cursor.push(payload.capsuleAlignment);
      }

      // Filter null and repeat values and keep only the last two
      cursor.set(_.chain(cursor.get()).compact().uniq().takeRight(2).value());
    },

    /**
     * Toggles the visibility of dimmed capsules
     */
    toggleHideUnselectedItems() {
      this.state.set('hideUnselectedItems', !this.state.get('hideUnselectedItems'));
    },

    /**
     * Sets whether capsule time is limiting the number of signals shown in order to maintain performance.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Boolean} payload.isCapsuleTimeLimited - True if it is limited, false otherwise
     */
    setCapsuleTimeLimited(payload) {
      this.state.set('isCapsuleTimeLimited', payload.isCapsuleTimeLimited);
    },

    /**
     * Sets the color mode for the lines in each lane of capsule time
     *
     * @param {Object} payload - Object container for arguments
     * @param {CapsuleTimeColorMode} payload.mode - One of the color modes
     */
    setCapsuleTimeColorMode(payload) {
      this.state.set('capsuleTimeColorMode', payload.mode);
    },

    /**
     * Set capsuleAlignment array to be empty in the state
     */
    clearCapsuleAlignment() {
      this.state.set('capsuleAlignment', []);
    },

    /**
     * Toggles the dimming of data outside of the capsules
     */
    toggleDimDataOutsideCapsules() {
      this.state.set('dimDataOutsideCapsules', !this.state.get('dimDataOutsideCapsules'));
    },

    /**
     * Possible lanes if `max` alignable items had their own lane
     */
    getLanes(max) {
      return _.times(max, idx => idx + 1);
    },

    /**
     * Possible alignments if `max` alignable items had their own axis
     */
    getAlignments(max) {
      return _.times(max, idx => _.toUpper(sqUtilities.getShortIdentifier(idx)));
    },

    /**
     * Fetches the formula being used to summarize the plot data, in the form needed
     * to attach it to another signal
     */
    getDiscreteSummaryFormula() {
      const timeUnit = this.state.get('dataSummary', 'discreteUnits');
      return timeUnit.value <= 0 ? '' :
        `.aggregate(average(), periods(${timeUnit.value}${timeUnit.units}), startKey())`;
    },

    /**
     * Finds a list of unique lane designations used.
     *
     * @returns {[String]} an Array of unique lane designations
     */
    getUniqueLanes() {
      const items = sqTrendDataHelper.getAlignableItems();
      return sqUtilities.getUniqueOrderedValuesByProperty(items, 'lane', this.getLanes(items.length));
    },

    /**
     * Returns an Array of assigned y-axis alignment designations.
     *
     * @returns {[String]} - an Array of assigned y-axis alignment designations
     */
    getUniqueAlignments() {
      const items = sqTrendDataHelper.getAlignableItems();
      return sqUtilities.getUniqueOrderedValuesByProperty(items, 'axisAlign', this.getAlignments(items.length));
    },

    /**
     * Returns the next available lane
     */
    getNextLane() {
      const maximumCount = sqTrendDataHelper.getAlignableItems().length + 1;
      return _.first(_.difference(this.getLanes(maximumCount), this.getUniqueLanes()));
    },

    /**
     * Returns the next availbale alignment
     */
    getNextAlignment() {
      const maximumCount = sqTrendDataHelper.getAlignableItems().length + 1;
      return _.first(_.difference(this.getAlignments(maximumCount), this.getUniqueAlignments()));
    },

    /**
     * Sets the point value of an array of items.
     *
     * @param payload {Object} - Container for arguments
     * @param payload.yValues {Array} - Set of objects specifing current values for a point in multiple series
     * @param payload.yValues[].id - An id to specify the series that will be updated
     * @param payload.yValues[].pointValue - The value that should be displayed for this point on the series
     * @param payload.yValues[].pointSelected {Boolean} - Indicates that the current value should be emphasized when
     * true, deemphasized when false, and remain neutral when undefined
     */
    setPointValue(payload) {
      this.state.set('pointValues', _.keyBy(payload.yValues, 'id'));
    },

    /**
     * Clears all point values for all items.
     */
    clearPointValues() {
      this.state.set('pointValues', {});
    },

    /**
     * Controls the display of the signal names/units
     *
     * @param {Object} payload - Object container for arguments
     * @param {Boolean} payload.property - The property to set
     * @param {Boolean} payload.value - The value to set the property too, must be one of LABEL_LOCATIONS
     */
    setLabelDisplayConfiguration(payload) {
      if (this.state.get(['labelDisplayConfiguration', payload.property]) !== payload.value) {
        // When the user changes this option reset the columns so that we keep the store clean
        if (payload.property === LABEL_PROPERTIES.CUSTOM) {
          this.state.set(['labelDisplayConfiguration', 'customLabels'], []);
        }

        this.state.set(['labelDisplayConfiguration', payload.property], payload.value);
      }
    },

    /**
     * Turns the display of the capsule set names in the capsule lanes on or off
     *
     * @param {Object} payload - Object container for arguments
     * @param {Boolean} payload.showCapsuleLabels - A flag indicating whether to show or hide the capsule name
     */
    setShowCapsuleLabels(payload) {
      this.state.set('showCapsuleLaneLabels', payload.showCapsuleLabels);
    },

    /**
     * Adds or updates a custom label
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.location - the location of the target (lane or axis) one of LABEL_LOCATIONS
     * @param {String|Number} payload.target - the name of the axis or lane
     * @param {String} payload.text - the text to set the custom label
     */
    setCustomLabel(payload) {
      const index = _.findIndex(this.state.get(['labelDisplayConfiguration', 'customLabels']),
        _.pick(payload, ['location', 'target']));
      if (index === -1) {
        this.state.push(['labelDisplayConfiguration', 'customLabels'], payload);
      } else if (index >= 0) {
        this.state.set(['labelDisplayConfiguration', 'customLabels', index], payload);
      }
    },

    /**
     * Removes a custom label
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.location - the location of the target (lane or axis) one of LABEL_LOCATIONS
     * @param {String|Number} payload.target - the name of the axis or lane
     */
    removeCustomLabel(payload) {
      const index = _.findIndex(this.state.get(['labelDisplayConfiguration', 'customLabels']), payload);
      if (index >= 0) {
        this.state.splice(['labelDisplayConfiguration', 'customLabels'], [index, 1]);
      }
    },

    /**
     * Sets capsule preview
     *
     * @param {Object} payload - Object container for arguments
     * @param {boolean} payload.capsulePreview - whether or not to display investigate range capsules
     */
    setCapsulePreview(payload) {
      this.state.set('capsulePreview', payload.capsulePreview);
    }
  };

  return store;
}
