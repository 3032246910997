import { TableBuilderSimpleTable } from '@/hybrid/tableBuilder/TableBuilderSimpleTable.organism';
import { TableBuilderConditionTable } from '@/hybrid/tableBuilder/TableBuilderConditionTable.organism';
import { Alert } from 'react-bootstrap';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { Icon } from '@/hybrid/core/Icon.atom';
import React, { useEffect } from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import {
  provideVisualizationData,
  Visualization
} from '@/hybrid/annotation/ckEditorPlugins/components/content.utilities';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { UtilitiesService } from '@/services/utilities.service';
import {
  TableBuilderColumnFilter,
  TableBuilderHeaders,
  TableBuilderSort
} from '@/hybrid/tableBuilder/tableBuilder.store';
import { RangeExport } from '@/trendData/duration.store';
import { TableTextFormatterIF } from '@/hybrid/tableBuilder/TableBuilder.page';
import { TableViewer } from '@/hybrid/tableBuilder/tableViewer/TableViewer.molecule';
import { ChartSettingsInterface } from './tableViewer/ChartSettings.molecule';
import { TableBuilderMode } from '@/hybrid/tableBuilder/tableBuilder.module';

const tableBuilderPropsOnlyBindings = bindingsDefinition({
  canEdit: prop<boolean>(),
  isSimpleMode: prop<boolean>(),
  startMode: prop<boolean>(),
  isLoading: prop<boolean>(),
  hasOnlyStringSeries: prop<boolean>(),
  hasNumericAndStringSeries: prop<boolean>(),
  hasOnlyStringMetrics: prop<boolean>(),
  hasNumericAndStringMetrics: prop<boolean>(),
  setCellText: prop<(key: string, value: string, id?: string) => void>(),
  setHeaderText: prop<(columnKey: string, text: string) => void>(),
  setColumnFilter: prop<(key: string, filter: TableBuilderColumnFilter) => void>(),
  simpleTableData: prop<any>(),
  conditionTableData: prop<any>(),
  headers: prop<TableBuilderHeaders>(),
  simpleColumns: prop<any>(),
  conditionColumns: prop<any>(),
  isTransposed: prop<boolean>(),
  isTableStriped: prop<boolean>(),
  isPresentationMode: prop<boolean>(),
  isViewOnlyMode: prop<boolean>(),
  displayRange: prop<RangeExport>(),
  timezone: prop<{ name: string }>(),
  displayMetricOnTrend: prop<(metricId: string, formulaItemId: string, start: number, end: number,
    event: any) => void>(),
  columnToThresholdsForCondition: prop<Object>(),
  columnToThresholdsForSimple: prop<Object>(),
  distinctStringValueMap: prop<{ [mode: string]: { [columnKey: string]: string[] } }>(),
  textFormatter: prop<TableTextFormatterIF>(),
  moveColumn: prop<(key: string, newKey: string) => void>(),
  canSort: prop<boolean>(),
  maxSortLevel: prop<number>(),
  sortByColumn: prop<(key: string, direction: string) => void>(),
  removeColumn: prop<(key: string) => void>(),
  itemFilters: prop<{ [id: string]: { filter: TableBuilderColumnFilter } }>(),
  itemSorts: prop<{ [id: string]: TableBuilderSort }>(),
  setActiveTool: prop<(toolName: string) => void>(),
  hasFetchError: prop<boolean>(),
  fetchFailedMessage: prop<string>(),
  isBadRequestError: prop<boolean>(),
  sanitizeBadRequestError: prop<(error: string) => string>(),
  setActiveRefElement: prop<any>(),
  showChartView: prop<boolean>(),
  chartViewSettings: prop<ChartSettingsInterface>(),
  setChartViewSettings: prop<(property: any) => void>(),
  onContentLoad: prop.optional<() => void>(),
  sqUtilities: injected<UtilitiesService>()
});

export let TableBuilderPropsOnly: SeeqComponent<typeof tableBuilderPropsOnlyBindings> = (props) => {
  const {
    sqUtilities
  } = useInjectedBindings(tableBuilderPropsOnlyBindings);

  const {
    isSimpleMode,
    startMode,
    canEdit,
    setCellText,
    setColumnFilter,
    setHeaderText,
    isLoading,
    hasOnlyStringMetrics,
    hasNumericAndStringMetrics,
    hasNumericAndStringSeries,
    hasOnlyStringSeries,
    simpleTableData,
    conditionTableData,
    headers,
    canSort,
    displayMetricOnTrend,
    displayRange,
    isPresentationMode,
    isTransposed,
    maxSortLevel,
    textFormatter,
    itemFilters,
    isTableStriped,
    columnToThresholdsForCondition,
    columnToThresholdsForSimple,
    distinctStringValueMap,
    moveColumn,
    sortByColumn,
    removeColumn,
    isViewOnlyMode,
    itemSorts,
    conditionColumns,
    simpleColumns,
    timezone,
    hasFetchError,
    fetchFailedMessage,
    setActiveRefElement,
    setActiveTool,
    sanitizeBadRequestError,
    isBadRequestError,
    showChartView,
    chartViewSettings,
    setChartViewSettings,
    onContentLoad
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    onContentLoad?.();
  }, []);

  if (sqUtilities.headlessRenderMode()) {
    provideVisualizationData({ ...props, visualization: Visualization.TABLE });
  }

  return <>
    <div className={'flexRowContainer flexFill' + (isPresentationMode ? '' : ' p20')}>
      {isSimpleMode && !startMode && !hasFetchError &&
      <div className="overflowYAuto" ref={setActiveRefElement}>
        <TableBuilderSimpleTable
          simpleTableData={simpleTableData}
          isLoading={isLoading}
          columns={simpleColumns}
          headers={headers}
          isTransposed={isTransposed}
          isStriped={isTableStriped}
          isPresentationMode={isPresentationMode}
          isViewOnlyMode={isViewOnlyMode}
          displayRange={displayRange}
          timezone={timezone}
          displayMetricOnTrend={displayMetricOnTrend}
          setCellText={setCellText}
          setHeaderText={setHeaderText}
          setColumnFilter={setColumnFilter}
          columnToThresholds={columnToThresholdsForSimple}
          hasOnlyStringSeries={hasOnlyStringSeries}
          hasNumericAndStringSeries={hasNumericAndStringSeries}
          hasOnlyStringMetrics={hasOnlyStringMetrics}
          hasNumericAndStringMetrics={hasNumericAndStringMetrics}
          distinctStringValueMap={distinctStringValueMap?.[TableBuilderMode.Simple]}
          textFormatter={textFormatter}
          canSort={canSort}
          maxSortLevel={maxSortLevel}
          moveColumn={moveColumn}
          removeColumn={removeColumn}
          sortByColumn={sortByColumn}
        />
        {showChartView &&
        <TableViewer
          testId="tableChartViewer"
          storeSettings={chartViewSettings}
          data={simpleTableData}
          columns={simpleColumns}
          isTransposed={isTransposed}
          setChartViewSettings={setChartViewSettings} />}
      </div>}
      {!isSimpleMode && !startMode && !hasFetchError &&
      <div className="overflowYAuto" ref={setActiveRefElement}>
        <TableBuilderConditionTable
          tableData={conditionTableData}
          isLoading={isLoading}
          headers={headers}
          columns={conditionColumns}
          isTransposed={isTransposed}
          isStriped={isTableStriped}
          isPresentationMode={isPresentationMode}
          canEdit={canEdit}
          timezone={timezone}
          setCellText={setCellText}
          setHeaderText={setHeaderText}
          setColumnFilter={setColumnFilter}
          itemFilters={itemFilters}
          columnToThresholds={columnToThresholdsForCondition}
          distinctStringValueMap={distinctStringValueMap?.[TableBuilderMode.Condition]}
          textFormatter={textFormatter}
          canSort={canSort}
          itemSorts={itemSorts}
          maxSortLevel={maxSortLevel}
          moveColumn={moveColumn}
          removeColumn={removeColumn}
          displayMetricOnTrend={displayMetricOnTrend}
          sortByColumn={sortByColumn}
        />
      </div>}
      {hasFetchError && !startMode &&
      <div className="flexColumnContainer flexCenter flexFill">
        <Alert
          variant={isBadRequestError ? 'info' : 'danger'}
          className="max-width-400 screenshotSizeToContent"
          transition={false}
        >
          {sanitizeBadRequestError(fetchFailedMessage)}
        </Alert>
      </div>}
      {startMode &&
      <div className="flexRowContainer flexCenter flexFill">
        <Alert
          variant="info"
          className="width-400 fs16 p25 screenshotSizeToContent"
          transition={false}
        >
          {!canEdit && <p>{t('TABLE_BUILDER.START_MODE_NO_CONTENT')}</p>}
          {canEdit &&
          <p>
            <p>{t('TABLE_BUILDER.START_MODE')}</p>
            <a className="cursorPointer"
              onClick={() => setActiveTool(TREND_TOOLS.THRESHOLD_METRIC)}>
              <Icon
                icon="fa-plus-circle"
                extraClassNames="p5"
                type="theme"
                testId="addFirstMetricButton" />
              <span>{t('TABLE_BUILDER.ADD_METRIC')}</span>
            </a>
          </p>}
        </Alert>
      </div>}
    </div>
  </>;
};
