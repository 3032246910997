import React, { useState } from 'react';
import { NotificationsService } from '@/services/notifications.service';
import { Form, FormCheck, Modal } from 'react-bootstrap';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ScheduleType, ScheduleTypeName, SelectScheduleType } from '@/hybrid/reportEditor/SelectScheduleType.molecule';
import {
  AutoUpdateTimeScheduleEntry,
  DEFAULT_TIME_ENTRY
} from '@/hybrid/reportEditor/ReportAutoUpdateTimeScheduleProperties.molecule';
import _ from 'lodash';
import {
  areTimeEntriesValid,
  ReportAutoUpdateTimeSchedule
} from '@/hybrid/reportEditor/ReportAutoUpdateTimeSchedule.molecule';
import {
  DayOfTheWeek,
  isMonthlyScheduleValid,
  MonthlyScheduleData,
  MonthlyScheduleTypeName
} from '@/hybrid/reportEditor/MonthlySchedule.atom';
import { DateRangeAutoRate, ReportSchedule } from '@/reportEditor/report.module';
import { DailyScheduleType, isDailyScheduleValid } from '@/hybrid/reportEditor/DailySchedule.atom';
import { FroalaReportContentService } from '@/reportEditor/froalaReportContent.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { isWeeklyScheduleValid, WeeklyScheduleData } from '@/hybrid/reportEditor/WeeklySchedule.atom';
import { ReportActions } from '@/reportEditor/report.actions';
import { DURATION_TIME_UNITS } from '@/main/app.constants';
import { DEFAULT_LIVE_RATE, LiveSchedule, LiveUpdateRate } from '@/hybrid/reportEditor/LiveSchedule.atom';
import { IrregularSchedule } from '@/hybrid/reportEditor/IrregularSchedule.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { TrackService } from '@/track/track.service';

interface IScheduleParser {
  /**
   * Determines if the provided schedule corresponds to the schedule type
   *
   * @param {string} cronSchedule - the cronSchedule to check
   * @param {Object} quartzCronExpressionHelper - an instance of the {@link quartCronExpressionHelper}
   *
   * @returns {boolean} true the provided schedule map to the schedule type; false otherwise
   */
  isType: (cronSchedule: string, quartzCronExpressionHelper) => boolean;

  /**
   * Checks if all of the schedules correspond to the schedule type, differing only in time entries
   *
   * @param {string[]} cronSchedules - the cron schedules to check
   * @param {Object} quartzCronExpressionHelper - an instance of the {@link quartCronExpressionHelper}
   *
   * @returns {boolean} true the provided all schedules map to the schedule typea; false otherwise
   */
  allMatchesType: (cronSchedules: string[], quartzCronExpressionHelper) => boolean;

  /**
   * Generates the state for the schedule type
   *
   * @param {string[]} cronSchedules - the cron schedules used to initialize the schedule
   * @param {Object} quartzCronExpressionHelper - an instance of the {@link quartCronExpressionHelper}
   *
   * @returns {@link ScheduleType} object that describes non-live schedules
   * @throws {Error} if there's an error parsing the schedule
   */
  initializeSchedule: (cronSchedules: string[], quartzCronExpressionHelper) => ScheduleType;
}

/**
 * Helper function to assert that all of a given array of values are numbers.
 *
 * @param {string[]} values - The values to check
 * @returns {boolean} true if all the values are finite and numeric; false otherwise
 */
const areAllElementsANumber: (values: string[]) => boolean = values =>
  _.every(values, el => _.isFinite(_.toNumber(el)));

const defaultScheduleType = () => ({
  selectedType: ScheduleTypeName.DAILY,
  data: {
    [ScheduleTypeName.DAILY]: DailyScheduleType.EVERY_DAY,
    [ScheduleTypeName.WEEKLY]: new WeeklyScheduleData(),
    [ScheduleTypeName.MONTHLY]: new MonthlyScheduleData()
  }
});

class ScheduleParser {
  static Daily: IScheduleParser = {
    isType(cronSchedule, quartzCronExpressionHelper) {
      const cronData = quartzCronExpressionHelper.parse(cronSchedule);
      const { seconds, daysOfMonth, months, daysOfWeek, years } = cronData;
      const EVERY_DAY: string[] = _.map(quartzCronExpressionHelper.EVERY_DAY, ele => ele.toString());

      if (_.isEmpty(cronData) || seconds.join(',') !== '0' || !_.isNil(years)) {
        return false;
      }

      //  Daily (Every day)                         0   0   8   ?   *   1-7   *
      return (daysOfMonth.join(',') === '?'
        && months.join(',') === '*'
        && _.isEqual(EVERY_DAY, daysOfWeek));
    },

    allMatchesType(cronSchedules, quartzCronExpressionHelper) {
      return _.every(cronSchedules, schedule => ScheduleParser.Daily.isType(schedule, quartzCronExpressionHelper));
    },

    initializeSchedule(cronSchedule, quartzCronExpressionHelper) {
      return _.merge(defaultScheduleType(), {
        selectedType: ScheduleTypeName.DAILY,
        data: {
          [ScheduleTypeName.DAILY]: DailyScheduleType.EVERY_DAY
        }
      });
    }
  };

  static Weekdays: IScheduleParser = {
    isType(cronSchedule, quartzCronExpressionHelper) {
      const cronData = quartzCronExpressionHelper.parse(cronSchedule);
      const { seconds, daysOfMonth, months, daysOfWeek, years } = cronData;
      const EVERY_WEEKDAY: string[] = _.map(quartzCronExpressionHelper.EVERY_WEEKDAY, ele => ele.toString());

      if (_.isEmpty(cronData) || seconds.join(',') !== '0' || !_.isNil(years)) {
        return false;
      }

      //  Daily (Every weekday)                     0   0   8   ?   *   2-6   *
      return (daysOfMonth.join(',') === '?'
        && months.join(',') === '*'
        && _.isEqual(EVERY_WEEKDAY, daysOfWeek));
    },

    allMatchesType(cronSchedules, quartzCronExpressionHelper) {
      return _.every(cronSchedules, schedule => ScheduleParser.Weekdays.isType(schedule, quartzCronExpressionHelper));
    },

    initializeSchedule(cronSchedule, quartzCronExpressionHelper) {
      return _.merge(defaultScheduleType(), {
        selectedType: ScheduleTypeName.DAILY,
        data: {
          [ScheduleTypeName.DAILY]: DailyScheduleType.EVERY_WEEKDAY
        }
      });
    }
  };

  static Weekly: IScheduleParser = {
    isType(cronSchedule, quartzCronExpressionHelper) {
      const cronData = quartzCronExpressionHelper.parse(cronSchedule);
      const { seconds, daysOfMonth, months, daysOfWeek, years } = cronData;
      if (_.isEmpty(cronData) || seconds.join(',') !== '0' || !_.isNil(years)) {
        return false;
      }

      //  Weekly (specific days):                   0   0   8   ?   *   1,2   *
      return (daysOfMonth.join(',') === '?'
        && months.join(',') === '*'
        && daysOfWeek.length > 0 && areAllElementsANumber(daysOfWeek)
      );
    },

    allMatchesType(cronSchedules, quartzCronExpressionHelper) {
      const isSameScheduleType = _.every(cronSchedules,
        schedule => ScheduleParser.Weekly.isType(schedule, quartzCronExpressionHelper));
      if (!isSameScheduleType) return false;

      const cronDataForAllSchedules = _.map(cronSchedules, schedule => quartzCronExpressionHelper.parse(schedule));
      const matchDaysOfWeek = cronDataForAllSchedules[0].daysOfWeek;
      return _.reduce(cronDataForAllSchedules,
        (result, { daysOfWeek }) => (result && _.isEqual(daysOfWeek, matchDaysOfWeek)), true);
    },

    initializeSchedule(cronSchedule, quartzCronExpressionHelper) {
      const cronData = quartzCronExpressionHelper.parse(cronSchedule[0]);
      const { daysOfWeek } = cronData;
      const days = {
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false
      };

      const CRON_TO_DAY_OF_THE_WEEK = {
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.SUN]: 'sunday',
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.MON]: 'monday',
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.TUE]: 'tuesday',
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.WED]: 'wednesday',
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.THU]: 'thursday',
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.FRI]: 'friday',
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.SAT]: 'saturday'
      };

      _.forEach(daysOfWeek, (day) => {
        if (!(_.toInteger(day) in CRON_TO_DAY_OF_THE_WEEK)) {
          return false;
        }
        days[CRON_TO_DAY_OF_THE_WEEK[_.toInteger(day)]] = true;
      });

      return _.merge(defaultScheduleType(), {
        selectedType: ScheduleTypeName.WEEKLY,
        data: {
          [ScheduleTypeName.WEEKLY]: {
            ...days
          }
        }
      });
    }
  };

  static MonthlyByDaysOfWeek: IScheduleParser = {
    isType(cronSchedule, quartzCronExpressionHelper) {
      const cronData = quartzCronExpressionHelper.parse(cronSchedule);
      const { seconds, daysOfMonth, months, daysOfWeek, years } = cronData;
      if (_.isEmpty(cronData) || seconds.join(',') !== '0' || !_.isNil(years)) {
        return false;
      }

      //  Monthly (nth day of week every n months): 0   0   8   ?   1/2 1#1   *
      return (daysOfMonth.join(',') === '?'
        && months.join(',').match(/^(\*|\d\/\d+)$/)
        && daysOfWeek.join(',').match(/^\d#\d$/));
    },

    allMatchesType(cronSchedules, quartzCronExpressionHelper) {
      const isSameScheduleType = _.every(cronSchedules,
        schedule => ScheduleParser.MonthlyByDaysOfWeek.isType(schedule, quartzCronExpressionHelper));
      if (!isSameScheduleType) return false;

      const cronDataForAllSchedules = _.map(cronSchedules, schedule => quartzCronExpressionHelper.parse(schedule));
      const matchMonths = cronDataForAllSchedules[0].months;
      const matchDaysOfWeek = cronDataForAllSchedules[0].daysOfWeek;
      return _.reduce(cronDataForAllSchedules, (result, { months, daysOfWeek }) =>
          (result && _.isEqual(months, matchMonths) && _.isEqual(daysOfWeek, matchDaysOfWeek)),
        true);
    },

    initializeSchedule(cronSchedule, quartzCronExpressionHelper) {
      const cronData = quartzCronExpressionHelper.parse(cronSchedule[0]);
      const { months, daysOfWeek } = cronData;
      const [cronDayOfWeek, nth] = daysOfWeek[0].split('#');
      const [startingMonth, repeatEveryNMonths = 1] = _.map(months[0].split('/'), ele => _.toInteger(ele));
      const CRON_TO_DAY_OF_THE_WEEK = {
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.SUN]: DayOfTheWeek.SUNDAY,
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.MON]: DayOfTheWeek.MONDAY,
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.TUE]: DayOfTheWeek.TUESDAY,
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.WED]: DayOfTheWeek.WEDNESDAY,
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.THU]: DayOfTheWeek.THURSDAY,
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.FRI]: DayOfTheWeek.FRIDAY,
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.SAT]: DayOfTheWeek.SATURDAY
      };

      const day = _.toInteger(cronDayOfWeek);
      if (!(day in CRON_TO_DAY_OF_THE_WEEK)) {
        throw new Error('Unable to parse the days of the week from the schedule');
      }

      const dayOfWeek = CRON_TO_DAY_OF_THE_WEEK[day];

      return _.merge(defaultScheduleType(), {
        selectedType: ScheduleTypeName.MONTHLY,
        data: {
          [ScheduleTypeName.MONTHLY]: {
            selectedType: MonthlyScheduleTypeName.BY_DAY_OF_WEEK,
            data: {
              [MonthlyScheduleTypeName.BY_DAY_OF_WEEK]: {
                nth: _.toInteger(nth),
                dayOfWeek,
                numberOfMonths: repeatEveryNMonths
              }
            }
          }
        }
      });
    }
  };

  static MonthlyByDaysOfMonth: IScheduleParser = {
    isType(cronSchedule, quartzCronExpressionHelper) {
      const cronData = quartzCronExpressionHelper.parse(cronSchedule);
      const { seconds, daysOfMonth, months, daysOfWeek, years } = cronData;
      if (_.isEmpty(cronData) || seconds.join(',') !== '0' || !_.isNil(years)) {
        return false;
      }

      //  Monthly (day of month d every m months):  0   0   8   9   1/2 ?     *
      return (daysOfMonth.length === 1 && daysOfMonth.join(',').match(/^\d+$/)
        && months.join(',').match(/^(\*|\d\/\d+)$/)
        && daysOfWeek.join(',') === '?'
      );
    },

    allMatchesType(cronSchedules, quartzCronExpressionHelper) {
      const isSameScheduleType = _.every(cronSchedules,
        schedule => ScheduleParser.MonthlyByDaysOfMonth.isType(schedule, quartzCronExpressionHelper));
      if (!isSameScheduleType) return false;

      const cronDataForAllSchedules = _.map(cronSchedules, schedule => quartzCronExpressionHelper.parse(schedule));
      const matchDaysOfMonth = cronDataForAllSchedules[0].daysOfMonth;
      const matchMonths = cronDataForAllSchedules[0].months;
      return _.reduce(cronDataForAllSchedules, (result, { daysOfMonth, months }) =>
          (result && _.isEqual(daysOfMonth, matchDaysOfMonth) && _.isEqual(months, matchMonths)),
        true);
    },

    initializeSchedule(cronSchedule, quartzCronExpressionHelper) {
      const cronData = quartzCronExpressionHelper.parse(cronSchedule[0]);
      const { daysOfMonth, months } = cronData;
      // This will need to be adjusted if we support multiple days of the month in the future
      const day = _.toInteger(daysOfMonth[0]);
      const [startingMonth, repeatEveryNMonths = 1] = _.map(months[0].split('/'), ele => _.toInteger(ele));

      return _.merge(defaultScheduleType(), {
        selectedType: ScheduleTypeName.MONTHLY,
        data: {
          [ScheduleTypeName.MONTHLY]: {
            selectedType: MonthlyScheduleTypeName.BY_DAY_OF_MONTH,
            data: {
              [MonthlyScheduleTypeName.BY_DAY_OF_MONTH]: {
                day,
                numberOfMonths: repeatEveryNMonths
              }
            }
          }
        }
      });
    }
  };

  /**
   * Uses various information extracted from a cron schedule to populate the state used for the UI
   * NOTE: This function only has limited support for cron schedules. The function and helpers will
   * take arrays so that functionality can be expanded in the future.
   *
   * @param {string[]} cronSchedules - all cron expression strings for schedule
   *
   * @returns {@link ScheduleType} object that describes non-live schedules
   * @throws {Error} if there's an error parsing the schedule
   */
  static determineScheduleType(cronSchedules: string[], quartzCronExpressionHelper): ScheduleType {
    const scheduleParsers: IScheduleParser[] = [
      ScheduleParser.Daily,
      ScheduleParser.Weekdays,
      ScheduleParser.Weekly,
      ScheduleParser.MonthlyByDaysOfWeek,
      ScheduleParser.MonthlyByDaysOfMonth
    ];

    // Since multiple cron schedules are supported, we only treat the schedule as valid if all cron schedules match
    // that specific type of schedule
    const initializedSchedule = _.chain(scheduleParsers)
      .map((parser) => {
        if (parser.allMatchesType(cronSchedules, quartzCronExpressionHelper)) {
          return parser.initializeSchedule(cronSchedules, quartzCronExpressionHelper);
        }
      })
      .reject(type => _.isUndefined(type))
      .head()
      .value();

    if (initializedSchedule) return initializedSchedule;

    throw new Error('Unable to parse the schedule');
  }
}

const configureAutoUpdateModalBindings = bindingsDefinition({
  schedule: prop<ReportSchedule>(),
  reportScheduleOverride: prop.optional<boolean>(),
  show: prop<boolean>(),
  onClose: prop<() => void>(),
  sqNotifications: injected<NotificationsService>(),
  sqReportActions: injected<ReportActions>(),
  sqFroalaReportContent: injected<FroalaReportContentService>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqTrack: injected<TrackService>()
});

export const ConfigureAutoUpdateModal: SeeqComponent<typeof configureAutoUpdateModalBindings> = (props) => {
  const {
    sqNotifications, sqReportActions, sqFroalaReportContent, sqWorksheetStore, sqTrack
  } = useInjectedBindings(configureAutoUpdateModalBindings);
  const { schedule, reportScheduleOverride, show, onClose } = props;
  const { t } = useTranslation();

  const [isScheduleEnabled, setScheduleEnabled] = useState(
    () => reportScheduleOverride ? reportScheduleOverride : schedule.enabled);
  const [irregularSchedule, setIrregularSchedule] = useState(undefined);

  const [selectedScheduleType, setSelectedScheduleType] = useState(() => {
    if (isFirstTimeConfiguring()) {
      return defaultScheduleType();
    }

    try {
      if (!schedule?.background) {
        return _.merge(defaultScheduleType(), { selectedType: ScheduleTypeName.LIVE });
      } else {
        return ScheduleParser.determineScheduleType(schedule.cronSchedule,
          sqFroalaReportContent.quartzCronExpressionHelper());
      }
    } catch (e) {
      setIrregularSchedule(schedule.cronSchedule);
    }
    return defaultScheduleType();
  });

  const [timezone, setTimezone] = useState<any>(sqWorksheetStore.timezone);
  const [timeEntries, setTimeEntries] = useState<AutoUpdateTimeScheduleEntry[]>(() => {
    // "Live" date range does not have time entries
    if (!schedule?.background || isFirstTimeConfiguring()) {
      return [DEFAULT_TIME_ENTRY];
    }

    try {
      const allTimeEntries = [];
      _.forEach(schedule.cronSchedule, (schedule) => {
        const { minutes, hours } = sqFroalaReportContent.quartzCronExpressionHelper().parse(schedule);
        allTimeEntries.push(...determineTimeEntries(minutes, hours));
      });
      return allTimeEntries;
    } catch (e) {
      setIrregularSchedule(schedule.cronSchedule);
    }
    return [DEFAULT_TIME_ENTRY];
  });

  const [liveRate, setLiveRate] = useState((): LiveUpdateRate => {
    if (schedule?.background || isFirstTimeConfiguring()) {
      return DEFAULT_LIVE_RATE;
    }

    const rate: DateRangeAutoRate = sqFroalaReportContent.quartzCronExpressionHelper().cronScheduleToRate(
      schedule.cronSchedule);

    if (_.isNil(rate)) {
      setIrregularSchedule(schedule.cronSchedule);
      return DEFAULT_LIVE_RATE;
    } else {
      return {
        value: rate.value,
        unit: DURATION_TIME_UNITS.find(({ unit }) => unit.includes(rate.units))
      };
    }
  });

  /**
   * Determine whether the schedule has been configured or not.
   *
   * @returns {boolean} false if a cronSchedule is missing or set to the pending schedule true otherwise
   */
  function isFirstTimeConfiguring() {
    return _.isEmpty(schedule?.cronSchedule);
  }

  /**
   * Determine the times at which updates based on this schedule will be run.
   *
   * @param {string[]} minutes - An array of the minutes specified by the cron schedule
   * @param {string[]} hours - An array of the hours specified by the cron schedule
   * @returns {AutoUpdateTimeScheduleEntry[]} corresponding to the source cron schedule
   * @throws {Error} if there's an error parsing the schedule
   */
  function determineTimeEntries(minutes: string[], hours: string[]): AutoUpdateTimeScheduleEntry[] {
    const results = [];
    if (!areAllElementsANumber(minutes) || !areAllElementsANumber(hours)) {
      throw new Error('Unable to parse the schedule');
    }

    _.forEach(hours, (hour) => {
      const paddedHour = hour.padStart(2, '0');
      _.forEach(minutes, (minute) => {
        const paddedMinute = minute.padStart(2, '0');
        results.push(paddedHour + ':' + paddedMinute);
      });
    });

    // If we support more strategies in the future, we will have to modify this
    return _.map(results, time => ({ time }));
  }

  /**
   * Return a Quartz cron schedule for a {@link ScheduleType} object describing the auto update schedule.
   *
   * @param {ScheduleType} schedule - The auto update schedule for which a cron expression is desired
   * @param {string} time - The time to schedule
   * @returns {string} The cron schedule
   */
  function entriesToQuartzCronExpression(schedule: ScheduleType, timeEntry: AutoUpdateTimeScheduleEntry): string {
    if (_.isEmpty(schedule)) return;

    const quartzCronExpressionHelper = sqFroalaReportContent.quartzCronExpressionHelper();
    const DAYS_OF_THE_WEEK_TO_CRON = {
      sunday: quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.SUN,
      monday: quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.MON,
      tuesday: quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.TUE,
      wednesday: quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.WED,
      thursday: quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.THU,
      friday: quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.FRI,
      saturday: quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.SAT,
      SUNDAY: quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.SUN,
      MONDAY: quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.MON,
      TUESDAY: quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.TUE,
      WEDNESDAY: quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.WED,
      THURSDAY: quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.THU,
      FRIDAY: quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.FRI,
      SATURDAY: quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.SAT
    };

    const { selectedType, data } = schedule;
    const times = [timeEntry.time];

    if (selectedType === ScheduleTypeName.DAILY) {
      return data[ScheduleTypeName.DAILY] === DailyScheduleType.EVERY_DAY
        ? quartzCronExpressionHelper.createDailySchedule(times)
        : quartzCronExpressionHelper.createWeekdaySchedule(times);

    } else if (selectedType === ScheduleTypeName.WEEKLY) {
      const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } = data[ScheduleTypeName.WEEKLY];
      const daysOfWeek = [];

      if (sunday) daysOfWeek.push(DAYS_OF_THE_WEEK_TO_CRON.sunday);
      if (monday) daysOfWeek.push(DAYS_OF_THE_WEEK_TO_CRON.monday);
      if (tuesday) daysOfWeek.push(DAYS_OF_THE_WEEK_TO_CRON.tuesday);
      if (wednesday) daysOfWeek.push(DAYS_OF_THE_WEEK_TO_CRON.wednesday);
      if (thursday) daysOfWeek.push(DAYS_OF_THE_WEEK_TO_CRON.thursday);
      if (friday) daysOfWeek.push(DAYS_OF_THE_WEEK_TO_CRON.friday);
      if (saturday) daysOfWeek.push(DAYS_OF_THE_WEEK_TO_CRON.saturday);

      return quartzCronExpressionHelper.createWeeklySchedule(daysOfWeek, times);

    } else if (selectedType === ScheduleTypeName.MONTHLY) {
      const typeOfMonthly = data[ScheduleTypeName.MONTHLY].selectedType;
      if (typeOfMonthly === MonthlyScheduleTypeName.BY_DAY_OF_MONTH) {
        const subData = data[ScheduleTypeName.MONTHLY].data[MonthlyScheduleTypeName.BY_DAY_OF_MONTH];
        return quartzCronExpressionHelper.createMonthlyScheduleByDayOfMonth(subData.day, subData.numberOfMonths, times);

      } else if (typeOfMonthly === MonthlyScheduleTypeName.BY_DAY_OF_WEEK) {
        const subData = data[ScheduleTypeName.MONTHLY].data[MonthlyScheduleTypeName.BY_DAY_OF_WEEK];
        return quartzCronExpressionHelper.createMonthlyScheduleByDayOfWeek(subData.nth,
          DAYS_OF_THE_WEEK_TO_CRON[subData.dayOfWeek], subData.numberOfMonths, times);

      } else {
        throw new Error('Cannot map cron expression due to unknown monthly schedule');
      }
    } else if (selectedType === ScheduleTypeName.LIVE) {
    } else {
      throw new Error('Cannot map cron expression due to unknown schedule');
    }
  }

  /**
   * Determine whether the current schedule is valid
   *
   * @param {ScheduleType} scheduleType - The current schedule
   * @returns {boolean} true if the schedule is valid; false otherwise
   */
  const isScheduleValid = (scheduleType: ScheduleType): boolean => {
    if (_.isNil(scheduleType) || !_.isNil(irregularSchedule)) return false;

    const { selectedType, data } = scheduleType;
    switch (selectedType) {
      case ScheduleTypeName.DAILY:
        return isDailyScheduleValid(data[ScheduleTypeName.DAILY]) && areTimeEntriesValid(timeEntries);
      case ScheduleTypeName.WEEKLY:
        return isWeeklyScheduleValid(data[ScheduleTypeName.WEEKLY]) && areTimeEntriesValid(timeEntries);
      case ScheduleTypeName.MONTHLY:
        return isMonthlyScheduleValid(data[ScheduleTypeName.MONTHLY]) && areTimeEntriesValid(timeEntries);
      case ScheduleTypeName.LIVE:
        // The live update rate should always be valid, since unit and value choices are constrained by the UI.
        return true;
      default:
        return false;
    }
  };

  /**
   * Save the auto update schedule by generating a cron schedule persisting it to the backend.
   */
  function save() {
    const { selectedType } = selectedScheduleType;
    let background;
    const cronSchedule = [];

    if (selectedType !== ScheduleTypeName.LIVE) {
      _.forEach(timeEntries, (timeEntry) => {
        cronSchedule.push(entriesToQuartzCronExpression(selectedScheduleType, timeEntry));
      });
      background = true;
    } else {
      const rate: DateRangeAutoRate = { value: liveRate.value, units: liveRate.unit.unit[0] };
      cronSchedule.push(sqFroalaReportContent.quartzCronExpressionHelper().rateToCronSchedule(rate));
      background = false;
    }

    const output: ReportSchedule = {
      cronSchedule,
      background,
      enabled: isScheduleEnabled
    };

    let scheduleAction;
    let scheduleInfo;
    if (selectedScheduleType.selectedType === ScheduleTypeName.LIVE) {
      scheduleAction = ScheduleTypeName.LIVE;
      scheduleInfo = liveRate.value + liveRate.unit.translationKey;
    } else {
      scheduleAction = selectedScheduleType.selectedType;
      scheduleInfo = `${timeEntries.length}`;
    }

    sqTrack.doTrack('Topic', scheduleAction + ' schedule', scheduleInfo);

    if (output.background) {
      // Only save timezones if the schedule is a background schedule
      return sqReportActions.updateTimezone(timezone)
        .then(() => sqReportActions.saveReportSchedule(output))
        .then(() => onClose())
        .catch(sqNotifications.apiError);
    } else {
      return sqReportActions.saveReportSchedule(output)
        .then(() => onClose())
        .catch(sqNotifications.apiError);
    }

  }

  return (
    <Modal show={show} onHide={onClose} animation={false} data-testid="configureAutoUpdateModal">
      <Modal.Header>
        <Modal.Title>
          {t('REPORT.MODAL.AUTO_UPDATE.HEADER')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {!_.isNil(irregularSchedule) ?
            <IrregularSchedule
              schedules={irregularSchedule}
              onConvertIt={() => setIrregularSchedule(undefined)} />
            :
            <React.Fragment>
              <SelectScheduleType scheduleType={selectedScheduleType} setScheduleType={setSelectedScheduleType} />
              {selectedScheduleType.selectedType !== ScheduleTypeName.LIVE &&
              <ReportAutoUpdateTimeSchedule
                timezone={timezone}
                setTimezone={setTimezone}
                entries={timeEntries}
                setEntries={setTimeEntries} />}
              {selectedScheduleType.selectedType === ScheduleTypeName.LIVE &&
              <LiveSchedule
                rate={liveRate}
                onChange={setLiveRate}
                onInvalidInput={(_message) => {
                  setLiveRate(DEFAULT_LIVE_RATE);
                  setIrregularSchedule(schedule.cronSchedule);
                }} />}
            </React.Fragment>
          }
        </Form>
      </Modal.Body>
      <Modal.Footer className="flexJustifyInitial">
        <div className="flexColumnContainer width-maximum">
          <div className="textAlignLeft flexGrow">
            <FormCheck
              id="configureAutoUpdateModal__scheduleEnabled"
              data-testid="configureAutoUpdateModal__scheduleEnabled"
              className="pl0"
              type="checkbox"
              label={t('REPORT.MODAL.AUTO_UPDATE.ENABLED')}
              checked={isScheduleEnabled}
              onChange={() => setScheduleEnabled(!isScheduleEnabled)} />
          </div>
          <div className="textAlignRight flexGrow">
            <TextButton label="CANCEL" onClick={onClose} extraClassNames="mr5" />
            <TextButton
              testId="configure-auto-update-schedule-save-button"
              label="SAVE"
              variant="theme"
              disabled={!isScheduleValid(selectedScheduleType)}
              onClick={save} />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export const sqConfigureAutoUpdateModal = angularComponent(configureAutoUpdateModalBindings, ConfigureAutoUpdateModal);
