import React from 'react';
import _ from 'lodash';
import { HOME_SCREEN_TABS_AND_TRANSLATION_KEYS } from '@/main/app.constants';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { NavigationEntry } from '@/hybrid/homescreen/NavigationEntry.atom';
import { FolderTree } from '@/hybrid/homescreen/FolderTree.organism';
import { HomeScreenStore } from '@/hybrid/homescreen/homescreen.store';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { HOME_SCREEN_TABLE_TYPE } from '@/hybrid/homescreen/homescreen.module';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';

const homeScreenNavigationBindings = bindingsDefinition({
  sqHomeScreenStore: injected<HomeScreenStore>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>()
});

export const HomeScreenNavigation: SeeqComponent<typeof homeScreenNavigationBindings> = () => {
  const { sqHomeScreenStore, sqHomeScreenUtilities } = useInjectedBindings(homeScreenNavigationBindings);
  const currentTab = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.currentTab);
  const isCurrentTab = tab => tab === currentTab;
  const availableTabs = sqHomeScreenUtilities.getFilteredLocations(HOME_SCREEN_TABS_AND_TRANSLATION_KEYS);
  return (
    <div className="flexRowContainer height-maximum overflowAuto pb70">
      <div className="overflowAuto">
        {_.map(availableTabs, tab => (
          <div key={tab.value}>
            <NavigationEntry
              tabKey={tab.value}
              displayValue={tab.text}
              current={isCurrentTab(tab.value)}
              id={tab.id} />
            {isCurrentTab(tab.value) && tab.folderRoot ?
              <FolderTree tableType={HOME_SCREEN_TABLE_TYPE.TAB} topLevel={true} root={tab.folderRoot} /> : ''}
          </div>))}
      </div>
    </div>
  );
};
