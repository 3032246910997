import _ from 'lodash';
import angular from 'angular';
import { SocketService } from '@/services/socket.service';
import { UtilitiesService } from '@/services/utilities.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';

export const UPDATE_NAMES = {
  THUMBNAIL: 'thumbnail',
  WORKBOOKS: 'workbooks',
  WORKBOOK: 'workbook',
  WORKSTEP: 'workstep',
  COMMENT: 'comment',
  REPORT: 'report',
  PERMISSIONS: 'permissions',
  SYSTEM_MESSAGE: 'systemMessage',
  ALL_SERVER_REQUESTS_CANCELED: 'allServerRequestsCanceled'
};

const dependencies = [
  'Sq.Services.Socket'
];

/**
 * @file Notifier service that uses sqSocket to send/receive updates to/from remote clients about fast follow events.
 */
angular.module('Sq.Services.Notifier', dependencies)
  .factory('sqNotifier', sqNotifier);

export type NotifierService = ReturnType<typeof sqNotifier>;

function sqNotifier(
  $rootScope: ng.IRootScopeService,
  $window: ng.IWindowService,
  $q: ng.IQService,
  sqSocket: SocketService,
  sqUtilities: UtilitiesService
) {

  let _active = true;

  const service = {
    emitWorkbooks,
    onWorkbooks,
    emitWorkbook,
    onWorkbook,
    emitComment,
    onComment,
    onThumbnail,
    emitAllServerRequestsCanceled,
    onAllServerRequestsCanceled,
    emitPermissions,
    onPermissions,
    onSystemMessage,
    active
  };

  addEventListeners();

  return service;

  /**
   * Adds listeners for mouse and keyboard events that activate the notifier. Once activated in response
   * to a user input event, the state will remain active until a websocket message is received, indicating
   * that a change has been made on another browser and that another browser is now active. Whichever
   * browser makes the last user input is the active browser.
   */
  function addEventListeners() {
    if (!sqUtilities.headlessRenderMode()) {
      _.forEach(['mousedown', 'keydown', 'wheel'], _.partial($window.addEventListener, _, activate, true));
    }
  }

  /**
   * Activates the notifier
   */
  function activate() {
    _active = true;
  }

  /**
   * Deactivates the notifier
   */
  function deactivate() {
    _active = false;
  }

  /**
   * Websocket messages are allowed to be emitted only while the state is active.
   *
   * @returns {boolean} - The active state
   */
  function active() {
    return _active;
  }

  /**
   * Emits a message indicating a workbook has been updated (i.e. added, removed, renamed, or restored)
   */
  function emitWorkbooks() {
    if (service.active()) {
      emit({ name: UPDATE_NAMES.WORKBOOKS });
    }
  }

  /**
   * Registers a callback function that is called whenever the contents of a remote workbook is updated
   *
   * @param {Function} callback - A callback function (e.g. function() {})
   * @return {Function} The unsubscribe function for the callback
   */
  function onWorkbooks(callback) {
    return subscribe(({ data }) => {
      if (data.name === UPDATE_NAMES.WORKBOOKS) {
        deactivate();
        $rootScope.$evalAsync(callback);
      }
    });
  }

  /**
   * Emits a message indicating the contents of a workbook have been updated
   *
   * @param {String} workbookId - A workbookId
   */
  function emitWorkbook(workbookId) {
    if (service.active()) {
      emit({ name: UPDATE_NAMES.WORKBOOK, workbookId });
    }
  }

  /**
   * Registers a callback function that is called whenever the contents of a remote workbook is updated
   *
   * @param {Function} callback - A callback function (e.g. function(workbookId) {})
   * @return {Function} The unsubscribe function for the callback
   */
  function onWorkbook(callback) {
    return subscribe(({ data }) => {
      if (data.name === UPDATE_NAMES.WORKBOOK) {
        deactivate();
        $rootScope.$evalAsync(function() {
          callback(data.workbookId);
        });
      }
    });
  }

  /**
   * Emits a message indicating a comment has been updated (i.e. added, removed, or edited). `activate()` is not
   * checked since it is not part of the fast-follow flow.
   *
   * @param {String[]} interests - ids of interest that should be updated. The listener will check if the one of the
   * interests for the worksheet is one of the interests.
   */
  function emitComment(interests) {
    emit({ name: UPDATE_NAMES.COMMENT, interests });
  }

  /**
   * Registers a callback function that is called whenever a comment is updated (i.e. added, removed, or edited).
   * `deactivate()` is not called since it is not part of the fast-follow flow.
   *
   * @param {Function} callback - A callback function (e.g. function(interests) {})
   * @return {Function} The unsubscribe function for the callback
   */
  function onComment(callback) {
    return subscribe(({ data }) => {
      if (data.name === UPDATE_NAMES.COMMENT) {
        $rootScope.$evalAsync(function() {
          callback(data.interests);
        });
      }
    });
  }

  /**
   * Registers a callback function that is called whenever the server updates a screenshot thumbnail. This
   * event is emitted when a screenshot is captured, then bounced off appserver back to all other clients.
   *
   * @param {Function} callback - A callback function (e.g. function(workbookId, worksheetId) {})
   * @return {Function} The unsubscribe function for the callback
   */
  function onThumbnail(callback) {
    return subscribe(({ data }) => {
      if (data.name === UPDATE_NAMES.THUMBNAIL) {
        $rootScope.$evalAsync(function() {
          callback(data.workbookId, data.worksheetId);
        });
      }
    });
  }

  /**
   * Emits a message indicating all server requests have been canceled by an admin user
   */
  function emitAllServerRequestsCanceled() {
    if (service.active()) {
      emit({ name: UPDATE_NAMES.ALL_SERVER_REQUESTS_CANCELED });
    }
  }

  /**
   * Registers a callback function that is called whenever the all server requests have been canceled by an admin user
   *
   * @param {Function} callback - A callback function (e.g. function() {})
   * @return {Function} The unsubscribe function for the callback
   */
  function onAllServerRequestsCanceled(callback) {
    return subscribe(({ data }) => {
      if (data.name === UPDATE_NAMES.ALL_SERVER_REQUESTS_CANCELED) {
        deactivate();
        $rootScope.$evalAsync(callback);
      }
    });
  }

  /**
   * Emits a message indicating the permissions have been changed by the local client
   *
   * @param {String} workbookId - The workbook ID
   * @param {String} worksheetId - The worksheet ID
   * @param {String} itemId - The ID of the item for which permissions are being changed
   */
  function emitPermissions(workbookId, worksheetId, itemId?) {
    if (service.active()) {
      emit({ name: UPDATE_NAMES.PERMISSIONS, workbookId, worksheetId, itemId });
    }
  }

  /**
   * Registers a callback function that is called whenever a remote client changes permissions
   *
   * @param {Function} callback - A callback function (e.g. function(workbookId, worksheetId, itemId) {})
   * @return {Function} The unsubscribe function for the callback
   */
  function onPermissions(callback) {
    return subscribe(({ data }) => {
      if (data.name === UPDATE_NAMES.PERMISSIONS) {
        deactivate();
        $rootScope.$evalAsync(function() {
          callback(data.workbookId, data.worksheetId, data.itemId);
        });
      }
    });
  }

  /**
   * Registers a callback function that is called when the system message changes
   * The change is emitted on the backend via MessageCenter, which listens to the config option
   *
   * @param {Function} callback
   * @return {Function} The unsubscribe function for the callback
   */
  function onSystemMessage(callback) {
    return subscribe(({ data }) => {
      if (data.name === UPDATE_NAMES.SYSTEM_MESSAGE) {
        $rootScope.$evalAsync(() => callback(data.systemMessage));
      }
    });
  }

  /**
   * Emits an event on the broadcast channel when not in screenshot render mode
   *
   * @param {Object} data - data to pass to sqSocket
   */
  function emit(data) {
    if (!sqUtilities.headlessRenderMode()) {
      sqSocket.emit([SeeqNames.Channels.Broadcast], data);
    }
  }

  /**
   * Subscribes to the broadcast channel if not in screenshot render mode. Note that if the subscription will
   * be invoking an async call as part of the callback it should probably use the sqUtilities.debounceAsync method
   * to ensure order-of-operation and avoid unnecessary fetches if the publish events come faster than the async calls.
   *
   * @param {Function} callback - function to be called when an event arrives from the broadcast channel
   * @return {Function} The unsubscribe function for the callback
   */
  function subscribe(callback) {
    if (!sqUtilities.headlessRenderMode()) {
      return sqSocket.subscribe({
        channelId: [SeeqNames.Channels.Broadcast],
        onMessage: callback,
        useSubscriptionsApi: false // Channel is auto-subscribed by the backend
      });
    } else {
      return () => {
      };
    }
  }
}
