import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

interface InputRangeSliderProps {
  min?: number;
  max?: number;
  step?: number;
  value: number;
  label: string;
  onChange: (number) => void;
  onMouseUp?: (number) => void;
  id?: string;
  testId?: string;
}

/** Slider element for visually choosing a number within a range */
export const InputRangeSlider: React.FunctionComponent<InputRangeSliderProps> = (props) => {
  const { min = 1, max = 10, id, label, onChange, onMouseUp = () => {}, step = 1, testId, value } = props;
  const { t } = useTranslation();

  return (
    <Form.Group data-testid={testId} controlId={id}>
      <div className="slider-no-padding">
        <Form.Control
          className="sq-slider"
          type="range"
          id={id}
          min={min}
          max={max}
          title={t(label)}
          step={step}
          value={String(value)}
          onChange={e => onChange((e.target as any)?.valueAsNumber)}
          onPointerUp={e => onMouseUp((e.target as any)?.valueAsNumber)} />
      </div>
    </Form.Group>
  );
};


