import React, { useState } from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { Field, useForm } from 'react-final-form';
import {
  getFormFieldProps,
  getValidationFunction,
} from '@/hybrid/formbuilder/formbuilder.utilities';
import { FormError } from '@/hybrid/core/FormError.atom';
import classNames from 'classnames';
import _ from 'lodash';
import {
  ImageSelect,
  ImageSelectOption,
} from '@/hybrid/core/ImageSelect.molecule';

export interface ImageSelectIF extends ValidatingFormComponent<string> {
  component: 'ImageSelectFormComponent';
  label: string;
  selectOptions: ImageSelectOption[];
  placeholder: string;
  imageSelectClassName?: string;
  customErrorText?: string;
}

export const ImageSelectFormComponent: React.FunctionComponent<ImageSelectIF> =
  (props) => {
    const {
      name,
      testId = 'image-select',
      label,
      placeholder,
      value,
      selectOptions,
      validation,
      extendValidation,
      extraClassNames,
      customErrorText,
      imageSelectClassName,
    } = props;

    const defaultValidation = () => _.isUndefined(value);

    const appliedValidation = getValidationFunction(
      defaultValidation,
      extendValidation,
      validation
    );

    const { t } = useTranslation();
    const formState = useForm().getState();
    const [showError, setShowError] = useState(false);

    return (
      <div className="flexRowContainer flexFill">
        <span className="pb5" data-testid={`${testId}Label`}>
          {t(label)}
        </span>
        <div
          className={classNames('mb8', extraClassNames)}
          data-testid={testId}>
          <Field name={name} validate={appliedValidation}>
            {({ input, meta }) => {
              const formFieldProps = getFormFieldProps(
                formState,
                input,
                meta,
                props
              );
              // Update showError state after rendering is complete
              setTimeout(() => setShowError(formFieldProps.showError), 0);

              return (
                <ImageSelect
                  name={name}
                  placeholder={placeholder}
                  value={formFieldProps.value}
                  selectOptions={selectOptions}
                  className={classNames(
                    imageSelectClassName,
                    formFieldProps.className
                  )}
                  onChange={formFieldProps.onChange}
                />
              );
            }}
          </Field>
        </div>
        {showError && customErrorText && (
          <FormError
            errorText={customErrorText}
            dataTestId={`${testId}Error`}
          />
        )}
      </div>
    );
  };
