export const APPEND_DIRECTION = {
  UP: 'up',
  DOWN: 'down'
};

export const SORT = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const LEVELS = ['TRACE', 'DEBUG', 'INFO', 'WARN', 'ERROR'];
export const LIMITS = [20, 100, 500, 1000, 10000, 100000];
