import _ from 'lodash';
import angular from 'angular';
import { ReportContentStore } from '@/reportEditor/reportContent.store';
import {
  AssetSelection,
  CONTENT_STATE,
  REPORT_CONTENT,
  ReportContentShape,
  ReportContentSize, ReportContentSummary
} from '@/reportEditor/report.module';
import { PUSH_IGNORE } from '@/services/stateSynchronizer.service';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';

angular.module('Sq.Report').service('sqReportContentActions', sqReportContentActions);
export type ReportContentActions = ReturnType<typeof sqReportContentActions>;

function sqReportContentActions(
  flux: ng.IFluxService,
  $injector: ng.auto.IInjectorService,
  sqReportContentStore: ReportContentStore
) {

  const service = {
    setWorkbookId,
    setWorksheetId,
    setWorkstepId,
    setContent,
    setContentId,
    setWidth,
    setHeight,
    setSize,
    setShape,
    setScale,
    setDateRangeId,
    setSourceUrl,
    setSummary,
    setAssetSelectionId,
    clear,
    setUseSizeFromRender,
    setCanUseReact,
    setIsReact,
    calculateSizeAndShape,
    calculateHeight,
    setModalName
  };

  return service;

  /**
   * Sets all the parameters of a piece of report content to the store at once
   *
   * @param {Object} attrs - container for content attributes
   * @param {String} attrs.workbookId - value to set as the workbook id
   * @param {String} attrs.worksheetId - value to set as the worksheet id
   * @param {String} attrs.workstepId - value to set as the workstep id
   * @param {String} [attrs.id] - ID for the content. May be empty if new and hasn't been saved to API
   * @param {number} [attrs.height] - a height in pixels
   * @param {number} [attrs.width] - a width in pixels
   * @param {number} [attrs.scale] - scale for font size
   * @param {String} [attrs.dateRangeId] - ID of the date range used by this content
   * @param {String} [attrs.assetSelectionId] - ID of the asset selection used by this content
   * @param {Boolean} [attrs.useSizeFromRender] - true if the size of the screenshot is not defined by the user
   * @returns - A promise that resolves when the report content store is populated
   */
  function setContent(attrs): Promise<void> {
    return $injector.get<ReportContentService>('sqReportContent')
      .evaluateVisualizationOptions(attrs.workbookId, attrs.worksheetId, attrs.workstepId)
      .then(() => {
        service.setContentId(attrs.id);
        service.setWorkbookId(attrs.workbookId);
        service.setWorksheetId(attrs.worksheetId);
        service.setWorkstepId(attrs.workstepId);
        service.setSourceUrl(attrs.sourceUrl);
        service.setDateRangeId(attrs.dateRangeId);
        service.setScale(attrs.scale);
        service.setSummary(attrs.summary);
        service.setAssetSelectionId(attrs.assetSelectionId);
        service.setIsReact(attrs.isReact);

        // Pick the correct size/shape key from the width/height
        const width = attrs.width;
        const height = attrs.height;

        const { size, shape } = calculateSizeAndShape(width, height);

        if (shape) {
          service.setShape(shape.key, false);
        }
        service.setSize(size.key);

        service.setWidth(width);
        service.setHeight(height);
      });
  }

  /**
   * @param {string} contentId - ID of this content
   */
  function setContentId(contentId: string) {
    flux.dispatch('REPORT_CONTENT_SET_CONTENT_ID', contentId, PUSH_IGNORE);
  }

  /**
   * @param {string} workbookId - a workbook ID
   */
  function setWorkbookId(workbookId: string) {
    flux.dispatch('REPORT_CONTENT_SET_WORKBOOK_ID', workbookId, PUSH_IGNORE);
  }

  /**
   * @param {CONTENT_STATE} modalName - modal name that should be opened
   */
  function setModalName(modalName: CONTENT_STATE | '') {
    flux.dispatch('REPORT_CONTENT_SET_MODAL_NAME', modalName, PUSH_IGNORE);
  }

  /**
   * @param {string} worksheetId - a worksheet ID
   */
  function setWorksheetId(worksheetId: string) {
    flux.dispatch('REPORT_CONTENT_SET_WORKSHEET_ID', worksheetId, PUSH_IGNORE);
  }

  /**
   * Set the report content scale. One of REPORT_CONTENT.SCALE keys
   *
   * @param {String} scale - a scale key
   */
  function setScale(scale) {
    flux.dispatch('REPORT_CONTENT_SET_SCALE', { scale }, PUSH_IGNORE);
  }

  /**
   * Sets the summary for the content. One of REPORT_CONTENT.SUMMARY, with a potentially modified value
   *
   * @param summary
   */
  function setSummary(summary: ReportContentSummary) {
    flux.dispatch('REPORT_CONTENT_SET_SUMMARY', { summary }, PUSH_IGNORE);
  }

  function setAssetSelectionId(id: string) {
    flux.dispatch('REPORT_CONTENT_SET_ASSET_SELECTION_ID', id , PUSH_IGNORE);
  }

  /**
   * @param {string} workstepId - a workstep ID
   */
  function setWorkstepId(workstepId: string) {
    flux.dispatch('REPORT_CONTENT_SET_WORKSTEP_ID', workstepId, PUSH_IGNORE);
  }

  /**
   * @param {number} width - width of content, in pixels
   */
  function setWidth(width: number) {
    flux.dispatch('REPORT_CONTENT_SET_WIDTH', width, PUSH_IGNORE);
  }

  /**
   * @param {number} height - height of content, in pixels
   */
  function setHeight(height: number) {
    flux.dispatch('REPORT_CONTENT_SET_HEIGHT', height, PUSH_IGNORE);
  }

  /**
   * @param {string} sizeKey - key from REPORT_CONTENT.SIZE object
   */
  function setSize(sizeKey: string) {
    flux.dispatch('REPORT_CONTENT_SET_SIZE_KEY', sizeKey, PUSH_IGNORE);
    updateWidthHeightFromSizeShape();
  }

  /**
   * @param {string} shapeKey - key from REPORT_CONTENT.SHAPE object
   * @param {boolean} updateHeightWidth - whether or not to update the height and width
   */
  function setShape(shapeKey: string, updateHeightWidth: boolean = true) {
    flux.dispatch('REPORT_CONTENT_SET_SHAPE_KEY', shapeKey, PUSH_IGNORE);
    if (updateHeightWidth) updateWidthHeightFromSizeShape();
  }

  /**
   * Helper function to automatically update the width/height properties based on the current store settings for
   * size/shape.
   */
  function updateWidthHeightFromSizeShape() {
    const size = sqReportContentStore.sizeConstant;
    if (size.key === REPORT_CONTENT.SIZE.CUSTOM.key) return;

    const shape = sqReportContentStore.shapeConstant;
    setWidth(size.width);
    setHeight(calculateHeight(size.width, shape));
  }

  /**
   * Sets the id of the date range used for this content
   *
   * @param {string} id - id of a dateRange
   */
  function setDateRangeId(id: string) {
    flux.dispatch('REPORT_CONTENT_SET_DATE_RANGE_ID', id, PUSH_IGNORE);
  }

  /**
   * Sets the source URL
   *
   * @param {string} sourceUrl - a URL to the worksheet from which the content was generated
   */
  function setSourceUrl(sourceUrl: string) {
    flux.dispatch('REPORT_CONTENT_SET_SOURCE_URL', sourceUrl, PUSH_IGNORE);
  }

  /**
   * Set the boolean flag that indicates whether or not the content screenshot should be based on the size of the
   * content.
   *
   * @param {Boolean} useSizeFromRender - true if the content screenshot should be based on the size of the
   *   content. See worksheet.module.js for a description of how a view can be configured to support screenshots
   *   that are sized to the content.
   */
  function setUseSizeFromRender(useSizeFromRender: boolean) {
    flux.dispatch('REPORT_CONTENT_SET_USE_SIZE_FROM_RENDER', useSizeFromRender, PUSH_IGNORE);
  }

  /**
   * Sets whether or not the specific content can use react JSON
   * @param canUseReact
   */
  function setCanUseReact(canUseReact: boolean) {
    flux.dispatch('REPORT_CONTENT_SET_CAN_USE_REACT', canUseReact, PUSH_IGNORE);
  }

  /**
   * Sets if the content will use the React JSON blob for visualizations as opposed to the image
   * @param isReact
   */
  function setIsReact(isReact: boolean) {
    flux.dispatch('REPORT_CONTENT_SET_IS_REACT', isReact, PUSH_IGNORE);
  }

  /**
   * Clears the data for the currently loaded seeq content image.
   */
  function clear() {
    flux.dispatch('REPORT_CONTENT_CLEAR', undefined, PUSH_IGNORE);
  }

  /**
   * Calculates the size and shape from the height and width
   *
   * @param {number} width
   * @param {number} height
   * @returns {Object} - an object containing the size and shape
   */
  function calculateSizeAndShape(width, height): { size: ReportContentSize, shape: ReportContentShape | undefined } {
    let size = _.find(REPORT_CONTENT.SIZE, { width });
    let shape;
    if (!size) {
      // Non-matching width will always be calculated as custom
      size = REPORT_CONTENT.SIZE.CUSTOM;
    } else {
      shape = _.find(REPORT_CONTENT.SHAPE,
        shape => calculateHeight(size.width, shape) === height);
      if (!shape) {
        // If there's no shape, then the content has a custom height
        size = REPORT_CONTENT.SIZE.CUSTOM;
      }
    }

    return { size, shape };
  }

  /**
   * Calculates the matching height for the given width/shape combo
   *
   * @param {number} width
   * @param {object} shape
   * @returns {number} the height of the given width/shape combo
   */
  function calculateHeight(width, shape): number {
    return Math.round(width * shape.height / shape.width);
  }
}
