import _ from 'lodash';
import bind from 'class-autobind-decorator';
import { browserIsIE } from '@/utilities/browserId';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { SocketService } from '@/services/socket.service';
import { SEEQ_VERSION } from '@/services/buildConstants.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';

@bind
export class FooterCtrl {
  private unsubscribe: () => void = _.noop;
  private readonly getLabelClass = _.cond([
    [_.conforms({ loadPercentage: (n: number) => n < 1 }), _.constant('label-success')],
    [_.conforms({ loadPercentage: (n: number) => n >= 1 && n < 2 }), _.constant('label-warning')],
    [_.stubTrue, _.constant('label-danger')]
  ]);

  SEEQ_VERSION = SEEQ_VERSION;
  isIE = browserIsIE;
  isDev = process.env.NODE_ENV === 'development';
  serverLoad: any;

  constructor(
    public $scope: ng.IScope,
    public sqWorkbenchStore: WorkbenchStore,
    public sqSocket: SocketService) {
    $scope.$listenTo(sqWorkbenchStore, ['interactiveSessionId'], this.subscribeToRequestsProgress);
  }

  /**
   * Angular component lifecycle hook called before the component is destroyed
   */
  $onDestroy() {
    this.unsubscribe();
  }

  /**
   * Subscribes to the requests-progress channel whenever the interactive session ID changes. Because the backend
   * auto-subscribes all sessions to the channel, this just needs to register a local callback.
   */
  subscribeToRequestsProgress() {
    this.unsubscribe();
    this.unsubscribe = this.sqSocket.subscribe({
      channelId: [
        SeeqNames.Channels.RequestsProgress,
        this.sqWorkbenchStore.interactiveSessionId
      ],
      onMessage: this.setServerLoad,
      useSubscriptionsApi: false
    });
  }

  /**
   * Sets the server load percentage and message.
   *
   * @param {Object} message - Data received from websocket
   */
  setServerLoad(message) {
    this.serverLoad = {
      ...message.serverLoad,
      description: message.serverLoad.description + message.consumption,
      loadPercentage: Math.round(message.serverLoad.loadPercentage * 100),
      labelClass: this.getLabelClass(message.serverLoad)
    };
  }
}
