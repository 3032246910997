import _ from 'lodash';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import boost from 'highcharts/modules/boost.js';
import { ChartSelectionService } from '@/services/chartSelection.service';
import { AxisControlService, AxisExtremeChange } from '@/services/axisControl.service';
import { NumberHelperService } from '@/core/numberHelper.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { UtilitiesService } from '@/services/utilities.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { MIN_SAMPLES_FOR_BOOST } from '@/scatterPlot/scatterPlot.actions';
import { SCATTER_PLOT_ID } from '@/scatterPlot/scatterPlot.module';
import { XYRegion } from '@/services/chartHelper.service';
import { Z_INDEX } from '@/trendViewer/trendViewer.module';
import { DEBOUNCE } from '@/main/app.constants';
import { useResizeWatcher } from '@/hybrid/core/useResizeWatcher.hook';
import { useDebounce } from '@/hybrid/core/useDebounce.hook';

const scatterPlotChartBindings = bindingsDefinition({
  chartConfig: prop<Object>(),
  seriesX: prop<{ id: string }>(),
  seriesY: prop<{ id: string }>(),
  viewRegion: prop<XYRegion>(),
  selectedRegion: prop<XYRegion>(),
  timezone: prop<{ name: string }>(),
  setPointerValues: prop<(xValue: number, yValues: any[]) => void>(),
  clearPointerValues: prop<() => void>(),
  updateMinimapWidth: prop<(width: number) => void>(),
  setSelectedRegion: prop<(region: XYRegion) => void>(),
  clearSelectedRegion: prop<() => void>(),
  zoomToRegion: prop<(region: XYRegion) => void>(),
  fetchScatterPlot: prop<() => void>(),
  sqChartSelection: injected<ChartSelectionService>(),
  sqAxisControl: injected<AxisControlService>(),
  sqNumberHelper: injected<NumberHelperService>(),
  sqDateTime: injected<DateTimeService>(),
  sqUtilities: injected<UtilitiesService>()
});

boost(Highcharts);

export const ScatterPlotChart: SeeqComponent<typeof scatterPlotChartBindings> = (props) => {
  const {
    chartConfig,
    seriesX,
    seriesY,
    viewRegion,
    selectedRegion,
    timezone,
    setPointerValues,
    clearPointerValues,
    updateMinimapWidth,
    setSelectedRegion,
    clearSelectedRegion,
    zoomToRegion,
    fetchScatterPlot
  } = props;

  const {
    sqChartSelection,
    sqAxisControl,
    sqNumberHelper,
    sqDateTime,
    sqUtilities
  } = useInjectedBindings(scatterPlotChartBindings);

  const Y_AXIS_WIDTH = 55;

  const [chart, setChart] = useState(null);
  const chartElementRef = useRef(null);
  const [axisControl, setAxisControl] = useState(null);
  // Chart selection service should probably be converted to a hook
  const drawSelectedRegionRef = useRef((selectedRegion: XYRegion) => {});
  const deactivateScrollZoomRef = useRef(() => {});

  const reflowChart = useCallback(useDebounce(() => {
    const element = chartElementRef.current;
    if (!element || !chart) {
      return;
    }
    updateMinimapWidth(element.offsetWidth - Y_AXIS_WIDTH);

    // Highcharts has cached the chart position, and it won't be updated by a reflow in our Highcharts version.
    // This forces the chart position to update, which makes sure the pointer (+ labels) show up in the right place
    // https://github.com/highcharts/highcharts/issues/13220
    // https://github.com/highcharts/highcharts/pull/14810/commits/d1e83cf503946918363d4a4a5c81c543acd12cdf
    chart.pointer.chartPosition = undefined;
    chart.reflow();
    element.className = element.className.replace(/\binvisible\b/g, '');
    drawSelectedRegionRef.current(selectedRegion);
  }, DEBOUNCE.MEDIUM), [chart, selectedRegion]);

  const removeResizeWatcher = useResizeWatcher({
    element: chartElementRef.current,
    callback: reflowChart,
    callOnLoad: true
  });

  /**
   * Removes the chart.
   */
  const destroyChart = useCallback(() => {
    deactivateScrollZoomRef.current();

    removeResizeWatcher();

    clearPointerValuesIfLeft(null, chart);
    if (chart && chart.destroy) {
      // Highcharts-React should destroy the chart itself
      setChart(null);
    }

    if (chartElementRef.current) {
      chartElementRef.current.innerHTML = '';
    }
  }, [chart, chartElementRef.current]);

  // Once we have the chart, set the drawSelectedRegion method and the axis control service, and create the cleanup
  useEffect(() => {
    if (!chart) {
      return;
    }
    setAxisControl(sqAxisControl.createAxisControl({
      x: {
        updateExtremes(extremesChanges: AxisExtremeChange[]) {
          zoomToRegion({
            xMin: extremesChanges[0].oldExtremes.min + extremesChanges[0].changeInLow,
            xMax: extremesChanges[0].oldExtremes.max + extremesChanges[0].changeInHigh,
            yMin: chart.yAxis[0].min,
            yMax: chart.yAxis[0].max
          });
        }
      },
      y: {
        updateExtremes(extremesChanges: AxisExtremeChange[]) {
          zoomToRegion({
            xMin: chart.xAxis[0].min,
            xMax: chart.xAxis[0].max,
            yMin: extremesChanges[0].oldExtremes.min + extremesChanges[0].changeInLow,
            yMax: extremesChanges[0].oldExtremes.max + extremesChanges[0].changeInHigh
          });
        }
      }
    }));

    // cleanup
    return () => destroyChart();
  }, [chart, destroyChart]);

  useEffect(() => {
    if (!chart) {
      return;
    }

    drawSelectedRegionRef.current = sqChartSelection.drawSelectedRegion(
      () => chart,
      {
        selection: Z_INDEX.SELECTED_REGION,
        button: Z_INDEX.SELECTED_REGION_REMOVE
      },
      {
        clearSelection: clearSelectedRegion
      }
    );
  }, [chart]);

  // Add events to the chart DOM element and update the minimap once the chart DOM element exists
  useEffect(() => {
    if (!chartElementRef.current || !chart) {
      return;
    }
    chartElementRef.current.addEventListener('mouseleave', e => clearPointerValuesIfLeft(e, chart));
    chartElementRef.current.addEventListener('mousemove', e => clearPointerValuesIfLeft(e, chart));
    updateMinimapWidth(chartElementRef.current.offsetWidth - Y_AXIS_WIDTH);

    return () => {
      chartElementRef.current?.removeEventListener('mouseleave', e => clearPointerValuesIfLeft(e, chart));
      chartElementRef.current?.removeEventListener('mousemove', e => clearPointerValuesIfLeft(e, chart));
    };
  }, [chartElementRef.current, chart]);

  // When we have an axis control, activate the zoom and collect the deactivateScrollZoom method
  useEffect(() => {
    if (!chart || !chartElementRef.current || !axisControl) {
      return;
    }
    deactivateScrollZoomRef.current = axisControl.activateScrollZoom(chart, chartElementRef.current);
  }, [axisControl, chart, chartElementRef.current]);

  /**
   * Create a function which sets the current point values as the mouse moves over the chart. Highcharts populates
   * 'this' with information about the point we are hovering over ({time, x, y}).
   * Returns a function that will be used to generate the method we keep as a ref.
   *
   * @param xSeries - signal on the x-axis (current value of seriesX)
   * @param ySeries - series on the y-axis (current value of seriesY)
   */
  const getOnMouseOver = (xSeries, ySeries) => ({ target: point }) => {
    const time = point.time;
    const chartOrRegressionId = _.get(point, 'series.userOptions.id');
    const pointerValues = _.filter([{
      id: xSeries.id,
      pointValue: point.x,
      pointSelected: true
    }, {
      id: chartOrRegressionId === SCATTER_PLOT_ID
        ? ySeries.id : chartOrRegressionId,
      pointValue: point.y,
      pointSelected: true
    }], pointerValue => _.isFinite(pointerValue.pointValue) && !_.isEmpty(pointerValue.id));

    if (!_.isEmpty(pointerValues)) {
      setPointerValues(time, pointerValues);
    }
  };

  const onMouseOver = useRef(getOnMouseOver(seriesX, seriesY));

  useEffect(() => {
    onMouseOver.current = getOnMouseOver(seriesX, seriesY);
  }, [seriesX?.id, seriesY?.id]);

  /**
   * Processes changes to the chart configuration.
   *
   * @param highChart - the current chart object
   * @param chartConfig - Highcharts configuration settings to update on the chart
   */
  const updateChart = useCallback((chartConfig: Object) => {
    if (!chart) {
      return;
    }

    const clonedConfig = sqUtilities.cloneDeepOmit(chartConfig, ['data']);

    _.set(clonedConfig, 'plotOptions.scatter.point.events.mouseOver', onMouseOver.current);
    _.set(clonedConfig, 'plotOptions.line.events.mouseOver', onMouseOver.current);

    // Avoid the expensive re-setting of data if it hasn't changed. Can use simple equality check because data is
    // immutable.
    _.forEach(clonedConfig.series, (series) => {
      const chartSeries = findChartSeries(chart, series.id);
      if (series.data === _.get(chartSeries, 'userOptions.data')) {
        delete series.data;
      } else if (chartSeries) {
        // We call setData() separately from update() because passing false as the fourth argument to
        // setData() prevents Highcharts from trying to modify the data array we pass in. There's no corresponding
        // option for update().
        chartSeries.setData(series.data, false, false, false);
        delete series.data;
      }
      // Directly remove the path if we're setting lineWidth to 0
      // See https://github.com/highcharts/highcharts/issues/13816
      if (chartSeries && _.get(series, 'lineWidth') === 0) {
        chartSeries.graph?.destroy();
        chartSeries.graph = undefined;
      }
    });

    chart.update(clonedConfig, true, true, false);
  }, [chart]);

  // Only call updateChart (which calls chart.update()) if the chartConfig has changed
  useEffect(() => {
    updateChart(chartConfig);
  }, [chartConfig, updateChart]);

  useEffect(() => {
    drawSelectedRegionRef.current(selectedRegion);
  }, [selectedRegion, chartConfig]);

  useEffect(() => {
    if (!chart) {
      return;
    }
    updateViewRegion(viewRegion);
  }, [viewRegion]);

  /**
   * Transforms a pair of Highcharts X and Y selection objects with min and max into a matching XYRegion
   *
   * @param xAxis - axis for min and max x points
   * @param yAxis - axis for min and max y points
   * @return
   */
  const getXYRegion = (xAxis: Highcharts.Axis, yAxis: Highcharts.Axis): XYRegion => {
    return {
      xMin: xAxis.min,
      xMax: xAxis.max,
      yMin: yAxis.min,
      yMax: yAxis.max
    };
  };

  /**
   * Creates the initial scatter plot chart configuration to feed to Highcharts. The options set here will either
   * persist through the chart's lifetime, or get replaced when the chart gets updated.
   */
  const getInitialChartConfig = () => ({
    chart: {
      animation: false,
      type: 'scatter',
      zoomType: 'xy',
      boost: {
        seriesThreshold: 1
      },
      alignTicks: false,
      events: {
        selection(e) {
          setSelectedRegion(getXYRegion(e.xAxis[0], e.yAxis[0]));
          return false;
        }
      }
    },
    plotOptions: {
      series: {
        animation: false,
        turboThreshold: 0,
        states: {
          hover: {
            lineWidthPlus: 0,
            marker: { enabled: false }
          },
          inactive: {
            enabled: false
          }
        }
      },
      scatter: {
        boostThreshold: MIN_SAMPLES_FOR_BOOST,
        marker: {
          symbol: 'circle',
          radius: 3,
          states: {
            hover: {
              enabled: true,
              lineColor: 'rgb(100,100,100)'
            }
          }
        },
        point: {
          events: { mouseOver: onMouseOver.current }
        },
        tooltip: {
          enabled: false,
          animation: false,
          headerFormat: '',
          pointFormatter() {
            const xFormatted = sqNumberHelper.formatNumber(this.x,
              { format: this.series.userOptions.xNumberFormat });
            const yFormatted = sqNumberHelper.formatNumber(this.y,
              { format: this.series.userOptions.yNumberFormat });
            const timeFormatted = sqDateTime.formatTime(this.time, timezone);
            return `${timeFormatted}<br/>`
              + `x: <strong>${xFormatted}</strong><br/>`
              + `y: <strong>${yFormatted}</strong>`;
          }
        }
      },
      line: {
        boostThreshold: 0,
        lineWidth: 1,
        marker: { enabled: false },
        point: {
          events: { mouseOver: onMouseOver.current }
        },
        states: {
          hover: {
            halo: { size: 0 },
            lineWidthPlus: 0
          }
        },
        tooltip: {
          enabled: false,
          animation: false,
          headerFormat: '',
          pointFormatter() {
            const xFormatted = sqNumberHelper.formatNumber(this.x,
              { format: this.series.userOptions.xNumberFormat });
            const yFormatted = sqNumberHelper.formatNumber(this.y,
              { format: this.series.userOptions.numberFormat });
            return `x: <strong>${xFormatted}</strong><br/>y: <strong>${yFormatted}</strong><br/>`;
          }
        }
      }
    },
    legend: { enabled: false },
    credits: { enabled: false },
    title: { text: null },
    xAxis: {
      title: {
        enabled: true,
        text: ''
      },
      lineWidth: 1,
      gridLineWidth: 1
    },
    yAxis: {
      title: {
        enabled: true,
        text: ''
      },
      // The default tick behavior (for the y-axis only) causes the axis to snap extremes to the nearest tick every
      // time you change it, which causes very weird behavior when panning and zooming on it
      startOnTick: false,
      endOnTick: false,
      lineWidth: 1,
      gridLineWidth: 1
    }
  });

  /**
   * Callback method to be passed into the HighchartsReact object so we have access to the Highcharts Chart
   *
   * @param highchartsChart - the chart object from Highcharts
   */
  const afterChartCreated = (highchartsChart: Highcharts.Chart) => {
    setChart(highchartsChart);
    // Fetch the data so scatterData updates in the store and the chart will update on load
    fetchScatterPlot();
  };

  /**
   * Updates the visible region displayed in the chart to a specific XY region
   *
   * @param viewRegion - the XYRegion we want to view on the chart
   */
  const updateViewRegion = (viewRegion: XYRegion) => {
    chart.xAxis[0].setExtremes(viewRegion.xMin, viewRegion.xMax, false);
    chart.yAxis[0].setExtremes(viewRegion.yMin, viewRegion.yMax, false);
    chart.redraw();
    drawSelectedRegionRef.current(selectedRegion);
  };

  /**
   * Clears the axis values when mouse leaves the chart
   *
   * @param e - The mouse event
   * @param highChart - the current chart object
   */
  function clearPointerValuesIfLeft(e: MouseEvent, highChart: Highcharts.Chart) {
    if (mouseLeftActualChartArea(e, highChart)) {
      clearPointerValues();
    }
  }

  /**
   * Checks to see if the mouse has left the plot area and resets the zoomInProgess flag to reset the selection
   * marker
   *
   * @param e - The mouse event
   * @param highChart - the current chart object
   */
  function mouseLeftActualChartArea(e: MouseEvent, highChart: Highcharts.Chart) {
    let plotBox;
    let downXPixels;
    let downYPixels;

    if (e && highChart && highChart.pointer) {
      downXPixels = highChart.pointer.normalize(e).chartX;
      downYPixels = highChart.pointer.normalize(e).chartY;
      // @ts-ignore
      plotBox = highChart.plotBox;
      return downYPixels <= plotBox.y ||
        downYPixels > plotBox.y + plotBox.height ||
        downXPixels < plotBox.x ||
        downXPixels > plotBox.x + plotBox.width;
    } else {
      return true;
    }
  }

  /**
   * Find the chart series object that matches the id specified
   *
   * @param highChart - the current chart object
   * @param  id - ID value for which to search
   * @return Chart series object; null if not found
   */
  const findChartSeries = (highChart: Highcharts.Chart, id: string): Highcharts.Series => {
    return _.find(highChart?.series as Highcharts.Series[], { options: { id } });
  };

  return <div
    className="scatterPlot flexFill flexRowContainer flexColumnContainer mr10"
    ref={chartElementRef}>
    <HighchartsReact
      highcharts={Highcharts}
      options={getInitialChartConfig()}
      // Don't let the chart auto-update because we have to handle updates in a custom way (see updateChart())
      allowChartUpdate={false}
      callback={afterChartCreated}
      containerProps={{ className: 'flexFill' }} />
  </div>;
};

export const sqScatterPlotChart = angularComponent(scatterPlotChartBindings,
  ScatterPlotChart);
