import angular from 'angular';

const dependencies = [
  'Sq.AppConstants',
  'Sq.Core',
  'Sq.Directives.DoubleClick',
  'Sq.Services.Utilities',
  'Sq.Services.ACLService',
  'Sq.Services.Authorization',
  'Sq.LicenseManagement',
  'Sq.Worksheets',
  'Sq.Worksteps',
  'Sq.Vendor',
  'Sq.Services.Modal',
  'Sq.HomeScreen',
  'Sq.Services.Authentication'
];

/**
 * The Sq.Workbench module contains all functionality related to the workbench
 */
angular.module('Sq.Workbench', dependencies);

export const WORKBENCH_TABSETS = { dataSources: ['connections', 'agents'] };
