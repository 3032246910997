import angular from 'angular';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { NotificationsService } from '@/services/notifications.service';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { AuthorizationService } from '@/services/authorization.service';
import { APP_STATE, HOME_SCREEN_TABS } from '@/main/app.constants';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { UtilitiesService } from '@/services/utilities.service';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import _ from 'lodash';
import { StateSynchronizerService } from '@/services/stateSynchronizer.service';

angular.module('Sq.Workbook')
  .controller('WorkbookAccessWarningModalCtrl', WorkbookAccessWarningModalCtrl);

function WorkbookAccessWarningModalCtrl(
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  $state: ng.ui.IStateService,
  $translate: ng.translate.ITranslateService,
  sqWorkbenchActions: WorkbenchActions,
  sqNotifications: NotificationsService,
  sqWorkbenchStore: WorkbenchStore,
  sqAuthorization: AuthorizationService,
  sqHomeScreenActions: HomeScreenActions,
  sqUtilities: UtilitiesService,
  sqSystemConfiguration: SystemConfigurationService,
  sqStateSynchronizer: StateSynchronizerService,
  workbook: any,
  isReportBinder: boolean
) {
  const vm = this;
  vm.canRestoreWorkbook = sqAuthorization.canWriteItem(workbook);
  vm.close = $uibModalInstance.close;
  vm.restore = restore;
  vm.copy = copy;

  /**
   * Restores a workbook and optionally redirects the user to the workbook explorer
   */
  function restore() {
    vm.close();

    return sqHomeScreenActions.restoreWorkbook(workbook)
      .then(() => {
        return $state.go(APP_STATE.WORKBOOKS, { t: sqUtilities.generateTabHash(HOME_SCREEN_TABS.MY_FOLDER) },
          { reload: true });
      })
      .then(() => sqNotifications.successTranslate('TRASH.ITEM_RESTORED_NOTIFICATION', { ITEM_NAME: workbook.name }))
      .catch(sqNotifications.apiError);
  }

  /**
   * Duplicates a workbook and redirects the user to the workbook explorer
   */
  function copy() {
    vm.close();
    return sqHomeScreenActions.addWorkbook({
        name: workbook.name + ' - ' + $translate.instant('COPY'),
        addNewWorksheet: false,
        branchFrom: workbook.workbookId,
        isReportBinder,
        folderId: $state.params.currentFolderId
      })
      .then((item) => {
        const worksheetId = _.get(item, 'worksheets[0].worksheetId');
        // set loading worksheet so that differences between View only and Edit worksheet states are ignored
        sqStateSynchronizer.setLoadingWorksheet(item.id, worksheetId);
        $state.go(
          APP_STATE.WORKSHEET, { workbookId: item.id, worksheetId, currentFolderId: '' }
        );
      })
      .catch(error => sqNotifications.apiError(error));
  }
}
