import React, { useState } from 'react';
import { useAccordionToggle } from 'react-bootstrap';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';

const customToggleBindings = bindingsDefinition({
  eventKey: prop<string>(),
  Component: prop<Function>()
});

/**
 * Custom toggle handler for react-bootstrap accordion
 */
export const CustomToggle: SeeqComponent<typeof customToggleBindings> = ({ eventKey, Component }) => {
  const [isCardOpen, setCardOpen] = useState(false);

  const decoratedOnClick = useAccordionToggle(eventKey, () => setCardOpen(!isCardOpen));

  return Component({ onClick: decoratedOnClick, isCardOpen });
};
