import { useRef } from 'react';

/**
 * A hook watch for any resize event for an element
 * It uses the ResizeObserver functions that work in most browsers with the exception of IE
 *
 * @param {Object} resizeConfig - the configuration object
 * @param {HTMLElement} element - the DOM element to check for resize events
 * @param {Function} callback - the callback function to be used
 * @param {boolean} callOnLoad - true if the callback should be called on load as well
 * @return callback for the cleanup
 */
interface ResizeConfigParms {
  element: HTMLElement;
  callback: (o: { oldWidth: number, oldHeight: number, newWidth: number, newHeight: number }) => void;
  callOnLoad: boolean;
  fallbackInterval?: number;
}

export function useResizeWatcher({ element, callback, callOnLoad, fallbackInterval = 100 }: ResizeConfigParms): () => void {
  const width = useRef(-1);
  const height = useRef(-1);
  const resizeObserver = useRef(null);
  const interval = useRef(null);

  const compareAndCallback = (w, h, forceCallback = false) => {
    if (forceCallback || w !== width.current || h !== height.current) {
      callback({ oldWidth: width.current, oldHeight: height.current, newWidth: w, newHeight: h });
      width.current = w;
      height.current = h;
    }
  };

  if (element && !resizeObserver.current) {
    if (typeof window['ResizeObserver'] !== 'undefined') {
      // @ts-ignore
      resizeObserver.current = new ResizeObserver(entries => compareAndCallback(entries[0].contentRect.width, entries[0].contentRect.height));
      resizeObserver.current.observe(element);
    } else {
      interval.current = setInterval(() => {
        if (!element) return;
        compareAndCallback(element.offsetWidth, element.offsetHeight);
      }, fallbackInterval);

      resizeObserver.current = {
        disconnect: () => clearInterval(interval.current)
      };
    }
    if (callOnLoad) {
      compareAndCallback(element.offsetWidth, element.offsetHeight, true);
    }
  }
  return () => resizeObserver.current && resizeObserver.current.disconnect();
}
