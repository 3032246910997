import angular from 'angular';
import template from './maxCapsuleDuration.component.html';

angular.module('Sq.TrendViewer').component('sqMaxCapsuleDuration', {
  template,
  controller: 'MaxCapsuleDurationCtrl',
  bindings: {
    maxDurationChange: '&',
    maxDurationModel: '<',
    inputId: '@',
    headerTranslationKey: '@',
    tooltipTranslationKey: '@',
    valueUnitName: '@',
    item: '<',
    minIsExclusive: '<',
    insideModal: '<',
    form: '<'
  }
});
