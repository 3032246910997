import React, { useState } from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useDatasources } from '@/hybrid/administration/datasources/datasources.hook';
import { DetailsLevel } from '@/hybrid/administration/datasources/datasources.module';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { FormWarning } from '@/hybrid/core/FormWarning.atom';
import {
  DatasourcesService,
  FilterParameters,
  ManageDatasourceParams
} from '@/hybrid/administration/datasources/datasources.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { AdministrationActions } from '@/administration/administration.actions';
import { AGENTS_TAB_INDEX } from '@/administration/administration.module';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import _ from 'lodash';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { DatasourcesFilterHeader } from '@/hybrid/administration/datasources/DatasourcesFilterHeader.molecule';
import { DatasourceCard } from '@/hybrid/administration/datasources/DatasourceCard.molecule';
import { ItemAclModal } from '@/hybrid/accessControl/ItemAclModal.molecule';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { ManageConnectionModal } from '@/hybrid/administration/datasources/ManageConnectionModal';
import { ConnectionStatusOutputV1 } from '@/sdk';
import { ManageDatasourceModal } from '@/hybrid/administration/datasources/ManageDatasourceModal.molecule';

const datasourcesTabBindings = bindingsDefinition({
  sqDatasourcesService: injected<DatasourcesService>(),
  sqAdministrationActions: injected<AdministrationActions>()
});

export const DatasourcesTab: SeeqComponent<typeof datasourcesTabBindings> = () => {
  const { sqDatasourcesService, sqAdministrationActions } = useInjectedBindings(datasourcesTabBindings);

  const datasources = useDatasources(DetailsLevel.Complete);
  const { t } = useTranslation();

  const [filterParams, setFilterParams] = useState<FilterParameters>({
    name: '',
    datasourceClass: '',
    datasourceId: '',
    agentName: '',
    status: ''
  });
  const [datasourceAclId, setDatasourceAclId] = useState<string | undefined>(undefined);
  const [datasourcesExpansionStatus, setDatasourcesExpansionStatus] = useState(new Map());
  const [manageDatasourceParams, setManageDatasourceParams] = useState<ManageDatasourceParams | void>(undefined);
  const [connectionModalShown, setConnectionModalShown] = useState(false);
  const [isNewConnection, setIsNewConnection] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState<ConnectionStatusOutputV1>();

  const updateDatasourcesExpansionStatus = (datasourceIdKey, expansionState) => {
    setDatasourcesExpansionStatus(prev => new Map(prev).set(datasourceIdKey, expansionState));
  };

  // Handlers
  const setFilterParam = (field, value) => {
    if (_.isEmpty(value)) {
      value = '';
    }

    setFilterParams(_.assign({}, filterParams, { [field]: value }));
  };

  const manageDatasource = (id: string) => {
    return sqDatasourcesService.fetchManageDatasourceParams(id)
      .then((params) => {
        setManageDatasourceParams(params);
      });
  };

  const manageConnection = (connection) => {
    const isConnectionNotProvided = _.isNull(connection);
    if (isConnectionNotProvided) {
      connection = { agentName: '', connectionId: '', name: '' };
    }
    setIsNewConnection(isConnectionNotProvided);
    setSelectedConnection(connection);
    setConnectionModalShown(true);
  };

  // Renderers
  const renderDatasourcesTable = () => {
    const disconnectedAgentsCount = sqDatasourcesService.countDisconnectedAgents(datasources.agents);
    const filteredDatasources = sqDatasourcesService.filterAndSortDatasources(datasources.datasources, filterParams);

    const renderDatasourcesCounts = () => (<>
      <div
        className="col-form-label font-weight-bold mr5"
        data-testid="datasourcesTabDatasourcesLabel">
        {t('ADMIN.DATASOURCES.DATASOURCES')}
      </div>
      <div
        className="col-form-label mr5"
        data-testid="datasourcesTabDatasourcesCounts">
        {datasources.connectedDatasourcesCount} / {datasources.totalDatasourcesCount}
      </div>
    </>);

    const renderConnectionsCounts = () => {
      if ((datasources.connectedConnectionsCount === datasources.connectedDatasourcesCount) &&
        (datasources.totalConnectionsCount === datasources.totalDatasourcesCount)) {
        return null;
      }

      return (<>
        <div
          className="col-form-label font-weight-bold mr5"
          data-testid="datasourcesTabConnectionsLabel">
          {t('ADMIN.DATASOURCES.CONNECTIONS')}
        </div>
        <div
          className="col-form-label mr5"
          data-testid="datasourcesTabConnectionsCounts">
          {datasources.connectedConnectionsCount} / {datasources.totalConnectionsCount}
        </div>
      </>);
    };

    // TODO CRAB-24382 - Remove when development finishes
    // const stateForDebug = JSON.stringify(datasources, null, 2);

    return <>
      {disconnectedAgentsCount > 0 && (
        <div className="mb10">
          <FormWarning
            warningText={t('ADMIN.DATASOURCES.AGENTS_DISCONNECTED_WARNING', { COUNT: disconnectedAgentsCount })}
            onClick={() => {
              sqAdministrationActions.setActiveTabIndex(AGENTS_TAB_INDEX);
              sqAdministrationActions.setSearchParams({
                field: 'status',
                value: SeeqNames.Connectors.Connections.Status.Disconnected
              });
            }}
            extraClassNames="sq-alert-warning p5 cursorPointer agentsWarning"
          />
        </div>)}

      <div className="flexColumnContainer mb10">
        {renderDatasourcesCounts()}

        {renderConnectionsCounts()}

        <div className="flexFill" />

        <TextButton
          testId="addConnectionButton"
          size="sm"
          label="ADMIN.DATASOURCES.ADD_CONNECTION"
          type="button"
          variant="theme"
          onClick={() => manageConnection(null)}
          icon="fa-plus"
          iconStyle="white"
          extraClassNames="btn btn-primary" />
      </div>

      <DatasourcesFilterHeader
        filterParams={filterParams}
        filterCallback={setFilterParam}
        datasources={datasources.datasources}
      />

      <div className="flexRowContainer flexFillOverflow overflowAuto pr2">{
        _.map(filteredDatasources, ds =>
          <DatasourceCard
            key={ds.id}
            datasource={ds}
            agents={datasources.agents}
            onAclManage={() => setDatasourceAclId(ds.id)}
            onDatasourceManage={() => manageDatasource(ds.id)}
            onConnectionManage={manageConnection}
            onExpand={() => updateDatasourcesExpansionStatus(ds.id, !datasourcesExpansionStatus.get(ds.id))}
            expanded={datasourcesExpansionStatus.get(ds.id)}
          />)}
      </div>

      {/*TODO CRAB-24382 - Remove when development finishes*/}
      {/*<pre>{JSON.stringify(filteredDatasources, null, 2)}</pre>*/}
      {/*<pre data-testid="datasourcesTabState">{stateForDebug}</pre>*/}

      {datasourceAclId && <ItemAclModal itemId={datasourceAclId} closeModal={() => setDatasourceAclId(undefined)} />}

      {manageDatasourceParams &&
      <ManageDatasourceModal
        id={manageDatasourceParams.id}
        name={manageDatasourceParams.name}
        indexingScheduleSupported={manageDatasourceParams.indexingScheduleSupported}
        indexingFrequency={manageDatasourceParams.indexingFrequency}
        nextScheduledIndexAt={manageDatasourceParams.nextScheduledIndexAt}
        onClose={() => setManageDatasourceParams(undefined)} />
      }

      {connectionModalShown &&
      <ManageConnectionModal
        selectedConnection={selectedConnection}
        agents={datasources.agents}
        isNew={isNewConnection}
        onClose={() => setConnectionModalShown(false)} />}
    </>;
  };

  return datasources != null ?
    renderDatasourcesTable() :
    <div className="flexColumnContainer flexCenter pt50 pb50">
      <IconWithSpinner spinning={true} testId="datasourcesTabSpinner" />
    </div>;
};

export const sqDatasourcesTab = angularComponent(datasourcesTabBindings, DatasourcesTab);
