import React from 'react';
import _ from 'lodash';
import { TrendActions } from '@/trendData/trend.actions';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { TrendStore } from '@/trendData/trend.store';
import { CAPSULE_PANEL_TREND_COLUMNS, TREND_PANELS } from '@/trendData/trendData.module';
import { TrackService } from '@/track/track.service';
import { ItemPropertiesSelector } from '@/hybrid/utilities/ItemPropertiesSelector.molecule';
import { SuggestedPropertiesMode } from '@/hybrid/utilities/CustomPropertySelector.atom';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { ColumnHelperService } from '@/trendViewer/columnHelper.service';

const trendLabelsPopoverBindings = bindingsDefinition({
  sqTrendActions: injected<TrendActions>(),
  sqTrendStore: injected<TrendStore>(),
  sqTrendCapsuleSetStore: injected<TrendCapsuleSetStore>(),
  sqColumnHelper: injected<ColumnHelperService>(),
  sqTrack: injected<TrackService>()
});

/**
 * A dropdown menu for selecting capsule properties for labeling chart capsules
 */
export const TrendCapsulePropertiesDropdown: SeeqComponent<typeof trendLabelsPopoverBindings> = () => {
  const {
    sqTrendActions,
    sqTrendStore,
    sqTrendCapsuleSetStore,
    sqColumnHelper,
    sqTrack
  } = useInjectedBindings(trendLabelsPopoverBindings);

  const { items } = useFlux(sqTrendCapsuleSetStore);
  const propertyColumns = useFluxPath(sqTrendStore, () => sqTrendStore.propertyColumns(TREND_PANELS.CHART_CAPSULES));

  const customColumns = _.map(sqTrendStore.customColumns(TREND_PANELS.CAPSULES),
    property => ({ ...property, name: sqColumnHelper.getCapsuleColumnTitle(property) }));

  const toggleCapsuleColumnAndTrack = (column) => {
    sqTrack.doTrack('Trend', 'Toggle Capsule Label');
    const wasColumnEnabled = sqTrendStore.isColumnEnabled(TREND_PANELS.CHART_CAPSULES, column.key);
    sqTrendActions.toggleColumn(TREND_PANELS.CHART_CAPSULES, column.key);
    if (wasColumnEnabled) {
      sqTrendActions.removePropertiesColumn(TREND_PANELS.CHART_CAPSULES, column);
    }
  };

  const isCapsuleColumnEnabled = column => sqTrendStore.isColumnEnabled(TREND_PANELS.CHART_CAPSULES, column.key);
  const addPropertiesColumn = column => sqTrendActions.addPropertiesColumn(TREND_PANELS.CHART_CAPSULES, column);

  return <>
    <ItemPropertiesSelector
      extraSelectorProperties={customColumns}
      propertyColumns={_.map(propertyColumns, (column: any) =>
          ({ ...column, title: column.propertyName, shortTitle: column.propertyName }))
        .concat(CAPSULE_PANEL_TREND_COLUMNS)}
      conditions={items}
      suggestedPropertiesMode={SuggestedPropertiesMode.Capsules}
      isColumnEnabled={isCapsuleColumnEnabled}
      toggleColumn={toggleCapsuleColumnAndTrack}
      addPropertyColumn={addPropertiesColumn}
      maxHeightPropertyColumns={130} />
  </>;
};
