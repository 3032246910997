import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  Visualization, VisualizationContentProps
} from '@/hybrid/annotation/ckEditorPlugins/components/content.utilities';
import { TableBuilderPropsOnly } from '@/hybrid/tableBuilder/TableBuilderPropsOnly.organism';
import { CONTENT_LOADING_CLASS } from '@/reportEditor/report.module';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { ContentApi } from '@/sdk';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';

const getComponentForVisualization = (visualization: Visualization) => {
  if (visualization === Visualization.TABLE) {
    return TableBuilderPropsOnly;
  } else {
    throw new Error('No visualization type found in React JSON blob');
  }
};

const reactJsonContentBindings = bindingsDefinition({
  contentId: prop<string>(),
  style: prop<any>(),
  loading: prop<boolean>(),
  target: prop<any>(),
  src: prop<string>(),
  onLoad: prop<(visualization: Visualization) => void>(),
  onMeasurementsReady: prop<(width: number, height: number) => void>(),
  showMenu: prop<() => void>(),
  error: prop.optional<string>(),
  errorClass: prop.optional<string>(),
  sqContentApi: injected<ContentApi>()
});

/**
 * Handles React JSON visualizations. The blob state object is expected to the props to a props only version of a
 * Analysis visualization.
 */
export const ReactJsonContent: SeeqComponent<typeof reactJsonContentBindings> = ({
  contentId,
  errorClass,
  style,
  loading,
  target,
  src,
  onLoad,
  onMeasurementsReady,
  showMenu,
  error
}) => {
  const {
    sqContentApi
  } = useInjectedBindings(reactJsonContentBindings);

  const [blob, setBlob] = useState<any | undefined>(undefined);

  const setMeasurements = () => {
    onMeasurementsReady(target.current.clientWidth, target.current.clientHeight);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(src.substr(src.indexOf('?')));
    const params = {
      hash: queryParams.get('hash')
    };
    sqContentApi.getReactBlob({ id: contentId }, { params, useManualAsync: true })
      .then(({ data }) => {
        const newBlob = (data as any);
        if (newBlob.visualization) {
          setBlob({
            ...newBlob,
            onContentLoad: setMeasurements(),
            isContent: true,
            ...VisualizationContentProps[newBlob.visualization]
          });
          onLoad(newBlob.visualization);
        }
      });
  }, [src]);

  const getStyle = () => {
    const styleOverrides: any = {
      maxHeight: undefined,
      maxWidth: undefined
    };
    if (!loading) {
      styleOverrides.height = 'auto';
      styleOverrides.width = 'auto';
    }

    return {
      ...style,
      ...styleOverrides
    };
  };

  return <div
    data-testid={`content-${contentId}`}
    data-seeq-content={contentId}
    data-visualization={blob?.visualization ?? 'none'}
    style={getStyle()}
    onClick={showMenu}
    ref={target}
    title={error}
    className={classNames(
      errorClass ?? '', {
        [CONTENT_LOADING_CLASS.SPINNER]: loading && !errorClass,
        [CONTENT_LOADING_CLASS.LOADED]: !loading && !errorClass
      })}
  >
    {!loading && !errorClass && blob &&
    <>
      {React.createElement(getComponentForVisualization(blob.visualization), blob, null)}
    </>}
  </div>;
};
