import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { AdministrationActions } from '@/administration/administration.actions';
import { CELL_TYPES } from '@/hybrid/core/Table.atom';
import { ButtonWithManagedSpinner } from '@/hybrid/core/ButtonWithManagedSpinner.atom';
import { AdminTableWrapper } from '@/hybrid/core/AdminTableWrapper.molecule';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { IconCell } from '@/hybrid/homescreen/CellRender.atom';
import { SubscribersModal } from '@/hybrid/administration/SubscribersModal.molecule';
import { ChannelOutputV1 } from 'sdk/model/models';

const subscriptionsTabBindings = bindingsDefinition({
  sqAdministrationActions: injected<AdministrationActions>()
});

export const SubscriptionsTab: SeeqComponent<typeof subscriptionsTabBindings> = () => {

  const { sqAdministrationActions } = useInjectedBindings(subscriptionsTabBindings);
  const { t } = useTranslation();

  const [channels, setChannels] = useState<ChannelOutputV1[]>([]);
  const [modalChannel, setModalChannel] = useState(undefined);

  useEffect(() => {
    refreshSubscriptions();
  }, []);

  const columns = [
    {
      accessor: 'id',
      searchProperty: 'id',
      header: 'ADMIN.SUBSCRIPTION.ID',
      sortable: true,
      filterable: true,
      cellStyle: { width: '30%' }
    }, {
      accessor: 'publishedCount',
      searchProperty: 'publishedCount',
      header: 'ADMIN.SUBSCRIPTION.PUBLISHED_COUNT',
      sortable: true,
      filterable: false
    }, {
      accessor: 'type',
      searchProperty: 'type',
      header: 'ADMIN.SUBSCRIPTION.TYPE',
      sortable: true,
      filterable: true
    }, {
      accessor: 'createdAt',
      searchProperty: 'createdAt',
      header: 'ADMIN.SUBSCRIPTION.CREATED_AT',
      cellType: CELL_TYPES.DATE_TIME,
      sortable: true,
      filterable: false
    }, {
      accessor: 'lastPublishedAt',
      searchProperty: 'lastPublishedAt',
      header: 'ADMIN.SUBSCRIPTION.LAST_PUBLISHED_AT',
      cellType: CELL_TYPES.DATE_TIME,
      sortable: true,
      filterable: false
    }, {
      accessor: 'subscribers',
      header: 'ADMIN.SUBSCRIPTION.SUBSCRIPTIONS.SUBSCRIBERS',
      cellStyle: { width: 50, minWidth: 50, maxWidth: 50 },
      cellRenderFunction: channel => <IconCell
        icon="fa-info-circle"
        type="theme"
        large={true}
        onClick={() => setModalChannel(channel)} />
    }];

  const refreshSubscriptions = () => sqAdministrationActions.refreshSubscriptions()
    .then(data => setChannels(data.channels));

  return (
    <div className="height-maximum">
      <div className="flexColumnContainer flexJustifyEnd mb5">
        <ButtonWithManagedSpinner
          buttonProps={{ id: 'refreshSubscriptions' }}
          icon="fa-repeat"
          action={refreshSubscriptions}
          spinnerIconProps={{ type: 'text', large: true }}
          label="ADMIN.SUBSCRIPTION.REFRESH_LIST" />
      </div>

      {modalChannel &&
      <SubscribersModal channel={modalChannel} onClose={() => setModalChannel(undefined)} />}

      <div className="overflowAuto width-maximum height-maximum pb70">
        <AdminTableWrapper
          testId="subscriptionsTable"
          defaultSort={{ property: 'id', asc: true }}
          selectedItems={[]}
          setSelectedItems={() => {}}
          columns={columns}
          noItemsComponent={<span>{t('ADMIN.SUBSCRIPTION.NONE')}</span>}
          items={channels} />
      </div>
    </div>
  );
};
