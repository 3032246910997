import _ from 'lodash';
import angular from 'angular';
import { TREND_TOOLS } from '@/investigate/investigate.module';

angular.module('Sq.Investigate')
  .store('sqItemPropertiesStore', sqItemPropertiesStore);

export type ItemPropertiesStore = ReturnType<typeof sqItemPropertiesStore>['exports'];

function sqItemPropertiesStore(sqBaseToolStore) {
  const store = {
    initialize() {
      this.state = this.immutable(_.defaults({},
        { advancedParametersCollapsed: this.state ? this.state.get('advancedParametersCollapsed') : undefined },
        _.pick(sqBaseToolStore.COMMON_PROPS, 'advancedParametersCollapsed'))
      );
    },

    exports: {},

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @return {Object} State for the store
     */
    dehydrate() {
      return this.state.serialize();
    },

    /**
     * Sets the references panel state
     *
     * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {}

  };

  return sqBaseToolStore.extend(store, TREND_TOOLS.PROPERTIES);
}
