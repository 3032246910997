/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class ScalarsApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Archive a scalar
   * @param {string} id - ID of the scalar to archived
   */
  public archiveScalar(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/scalars/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ArchiveOutputV1>;
  }

  /**
   * 
   * @summary Create a calculated scalar
   * @param {models.ScalarInputV1} body - Calculated scalar information
   */
  public createCalculatedScalar(
    body: models.ScalarInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/scalars',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.CalculatedItemOutputV1>;
  }

  /**
   * 
   * @summary Get a scalar
   * @param {string} id - ID of the scalar to retrieve
   */
  public getScalar(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/scalars/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.CalculatedItemOutputV1>;
  }

  /**
   * 
   * @summary Get the resulting value from a scalar
   * @param {string} id - ID of the scalar to find the value of
   */
  public getScalarValue(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/scalars/{id}/getValue'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ScalarEvaluateOutputV1>;
  }

  /**
   * Pagination is enabled for this query
   * @summary Get a collection of scalars
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getScalars(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/scalars',
      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ItemPreviewListV1>;
  }

  /**
   * 
   * @summary Create or update multiple scalars at one time
   * @param {models.PutScalarsInputV1} [body]
   */
  public putScalars(
    body?: models.PutScalarsInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/scalars/batch',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ItemBatchOutputV1>;
  }

}
