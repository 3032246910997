import angular from 'angular';
import template from './capsuleMode.component.html';

angular.module('Sq.Investigate')
  .component('sqCapsuleMode', {
    controller: 'CapsuleModeCtrl',
    template,
    bindings: {
      aggregationOptId: '<',
      startNumber: '<',
      isUnbounded: '<',
      capsuleMode: '<'
    }
  });

export const CAPSULE_MODES = [
  {
    key: 'overlap',
    formula: 'CapsuleBoundary.Overlap',
    display: 'AGGREGATIONS.CAPSULE_MODE.OVERLAP',
    image: 'capsuleModeOverlap.png',
    boundedOnly: true
  },
  {
    key: 'intersect',
    formula: 'CapsuleBoundary.Intersect',
    display: 'AGGREGATIONS.CAPSULE_MODE.INTERSECT',
    image: 'capsuleModeIntersect.png',
    boundedOnly: false
  },
  {
    key: 'enclosedBy',
    formula: 'CapsuleBoundary.EnclosedBy',
    display: 'AGGREGATIONS.CAPSULE_MODE.ENCLOSED_BY',
    image: 'capsuleModeEnclosedBy.png',
    boundedOnly: false
  },
  {
    key: 'start',
    formula: 'CapsuleBoundary.StartsIn',
    display: 'AGGREGATIONS.CAPSULE_MODE.STARTS_IN',
    image: 'capsuleModeStart.png',
    boundedOnly: true
  },
  {
    key: 'end',
    formula: 'CapsuleBoundary.EndsIn',
    display: 'AGGREGATIONS.CAPSULE_MODE.ENDS_IN',
    image: 'capsuleModeEnd.png',
    boundedOnly: true
  }
];
