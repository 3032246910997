import _ from 'lodash';
import angular from 'angular';
import { AggregationBinActions } from '@/investigate/aggregationBins/aggregationBin.actions';
import { CAPSULE_MODES } from '@/investigate/aggregationBins/capsuleMode.component';

angular.module('Sq.Investigate')
  .controller('CapsuleModeCtrl', CapsuleModeCtrl);

function CapsuleModeCtrl($scope: ng.IScope, sqAggregationBinActions: AggregationBinActions) {

  const vm = this;
  vm.setCapsuleMode = sqAggregationBinActions.setCapsuleMode;
  vm.capsuleModes = CAPSULE_MODES;
  vm.$onChanges = onChanges;

  /**
   * Syncs the attributes of the components
   */
  function onChanges() {
    if (vm.isUnbounded) {
      vm.capsuleModes = _.filter(CAPSULE_MODES, { boundedOnly: false });
      // validate previously set capsuleMode
      if (!_.find(vm.capsuleModes, { key: vm.capsuleMode })) {
        vm.capsuleMode = undefined;
      }
    } else {
      vm.capsuleModes = CAPSULE_MODES;
    }
    if (_.isUndefined(vm.capsuleModes)) {
      vm.capsuleMode = undefined;
    }
    vm.capsuleModeObject = _.find(vm.capsuleModes, { key: vm.capsuleMode });
  }
}
