import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Overlay, Popover } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import { Icon } from '@/hybrid/core/Icon.atom';

const priorityPickerBindings = bindingsDefinition({
  onChange: prop<(value) => void>(),
  placement: prop<Placement>(),
  buttonTooltip: prop.optional<string>(),
  buttonTestId: prop<string>(),
  buttonClasses: prop<string>(),
  value: prop<any>()
});

export const PriorityPicker: SeeqComponent<typeof priorityPickerBindings> = (props) => {
  const {
    onChange,
    buttonTooltip,
    placement,
    buttonTestId,
    buttonClasses,
    value: priorities
  } = props;

  const { t } = useTranslation();
  const [target, setTarget] = useState(null);

  const [showPriorityPicker, setShowPriorityPicker] = useState(false);

  useEffect(() => {
    // handler used to close the picker on outside click
    const handleClick = (e) => {
      if (e.target.getAttribute('data-itemid') === buttonTestId) {
        return;
      }
      closePriorityPicker();
    };

    // add when mounted
    document.querySelector('#mainView').addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.querySelector('#mainView')?.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const closePriorityPicker = () => setShowPriorityPicker(false);
  const selectPriority = (event, priority) => {
    onChange(priority.level);
    isPriorityAvailable();
    event.stopPropagation();
  };

  /**
   * Determines if a priority is is still available (i.e. they are not already all in use)
   *
   * @returns true if available, false otherwise.
   */
  const isPriorityAvailable = (): boolean => _.chain(priorities)
    .reject({ level: 0 })
    .some({ inUse: false })
    .value();

  const togglePriorityPicker = event => showPriorityPicker ? closePriorityPicker() : openPriorityPicker(event);

  const openPriorityPicker = (event) => {
    setShowPriorityPicker(true);
    setTarget(event.currentTarget);
  };

  return (
    <div className="width-25 cursorPointer">
      <HoverTooltip text={buttonTooltip}>
        <div data-testid={buttonTestId}>
          <a href="#" onClick={togglePriorityPicker}>
            <Icon icon={buttonClasses} extraClassNames="mr5" />
          </a>
        </div>
      </HoverTooltip>

      <Overlay target={target} show={showPriorityPicker} placement={placement} transition={false}
        popperConfig={{ modifiers: { preventOverflow: { boundariesElement: 'window' } } as any }}>
        <Popover id="priorityPickerPopover" data-testid="priorityPickerPopover" className="popover priorityPopover"
          arrowProps={{ ref: null, style: { bottom: '-20px', left: '6px' } }}>
          <Popover.Title className="popover-title">
            {t('INVESTIGATE_TOOLS.THRESHOLD_METRIC.CHOOSE_PRIORITY_LEVEL')}
          </Popover.Title>
          <Popover.Content>
            <div className="picker-close" onClick={closePriorityPicker}>
              <span className="fa fa-close cursorPointer" />
            </div>
            <div className="flexColumnContainer text-bolder dividerBorderBottom">
              <div className="text-nowrap">{t('INVESTIGATE_TOOLS.THRESHOLD_METRIC.DEFAULT_COLOR')}</div>
              <div className="flexFill text-center">{t('NAME')}</div>
            </div>
            <div className="flexRowContainer positionRelative overflowYAuto msOverflowStyleAuto max-height-275">
              {_.map(priorities.filter(priority => priority.level), priority => (
                  <div
                    key={priority.level}
                    data-testid={`${priority.name}ThresholdPriority`}
                    className={classNames(
                      'positionRelative flexColumnContainer flexNoGrowNoShrink lightGreyBorder',
                      { 'cursorPointer hoverBorder focusBorder': !priority.inUse }
                    )} onClick={event => selectPriority(event, priority)}>
                    <div className={classNames({ selectedOverlay: priority.inUse })} />
                    <div style={{ backgroundColor: priority.color }} className="width-70" />
                    <div className="flexFill aggressiveWordBreak pl5">{priority.name}</div>
                  </div>
                )
              )}
              {!isPriorityAvailable() &&
              <div className="positionAbsoluteFill flexRowContainer flexCenter">
                <div className="mt5 sq-fairly-dark-gray text-italic">
                  {t('INVESTIGATE_TOOLS.THRESHOLD_METRIC.ALL_PRIORITIES_IN_USE')}
                </div>
              </div>
              }
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};
