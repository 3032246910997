import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getPieOptions, getBarOptions, getColumnOptions, getColumnStackedOptions, getBarStackedOptions } from '@/hybrid/tableBuilder/tableViewer/chartConfigs';
import { ChartSettingsInterface } from './ChartSettings.molecule';
import _ from 'lodash';

interface ChartProps {
  tableData: any[][];
  settings?: ChartSettingsInterface;
}

export const CHART_TYPES = [
  { id: 'pie', label: 'Pie Chart', config: getPieOptions },
  { id: 'column', label: 'Column Chart', config: getColumnOptions },
  { id: 'bar', label: 'Bar Chart', config: getBarOptions },
  { id: 'column_stacked', label: 'Column Chart Stacked', config: getColumnStackedOptions },
  { id: 'bar_stacked', label: 'Bar Chart Stacked', config: getBarStackedOptions },
];

export const Chart: React.FunctionComponent<ChartProps> = React.memo((props) => {
  const { tableData, settings } = props;
  if (!tableData || tableData.length === 0 || tableData[0].length < 2) {
    return <div />;
  }

  const options = CHART_TYPES.find(item => item.id === settings.chartType).config(tableData, settings);
  
  return <HighchartsReact
    immutable={true}
    highcharts={Highcharts}
    options={options} />;
}, _.isEqual);
