import angular from 'angular';
import template from './selectAllIcon.component.html';

angular.module('Sq.TrendViewer').component('sqSelectAllIcon', {
  template,
  controller: 'SelectAllIconCtrl',
  bindings: {
    items: '<',
    rejectProperty: '<?'
  }
});
