import _ from 'lodash';
import angular from 'angular';
import BroadcastChannel from 'broadcast-channel';

/**
 * A [BroadcastChannel](https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API)
 * allows communication between windows and tabs within the same browsing context (same browser).
 * The 'broadcast-channel' package is used to provide support to IE 11.
 *
 * Unlike the websocket connection, the broadcast channel is only shared within tabs in the
 * same browser and doesn't require participation from the backend - this makes it ideal for
 * indicating that localStorage has changed
 *
 * Note that messages emitted from a browser tab will not trigger handlers for the originating browser tab
 */
angular.module('Sq.Services.BroadcastChannel', [])
  .factory('sqBroadcastChannel', sqBroadcastChannel);

export type BroadcastChannelService = ReturnType<typeof sqBroadcastChannel>;

export const AUTH_CHANGE_BROADCAST_CHANNEL = 'auth-change-broadcast-channel';

interface BroadcastChannelSubscription {
  /** The name of the channel to listen subscribe to */
  channelId: string;
  /** The callback to invoke when a message is received */
  onMessage: (message: any) => void;
}

function sqBroadcastChannel($rootScope: ng.IRootScopeService) {
  const channels = {} as { [channelId: string]: BroadcastChannel };
  const service = {
    getChannel, // exposed to aid testing
    subscribe,
    emit
  };

  // Close the channels so no more messages are emitted after the app is destroyed
  $rootScope.$on('$destroy', () => {
    _.chain(channels)
      .keys()
      .forEach((channelId) => {
        channels[channelId].close();
        delete channels[channelId];
      })
      .value();
  });

  return service;

  /**
   * Get or create a channel identified by {@param channelId}
   */
  function getChannel(channelId: string) {
    if (!channels[channelId]) {
      channels[channelId] = new BroadcastChannel(`seeq-${channelId}`, { webWorkerSupport: true });
    }

    return channels[channelId];
  }

  /**
   * Send a message, with json serializable contents of {@param data}, to the channel identified by {@param channelId}
   */
  function emit(channelId: string, data?) {
    const channel = service.getChannel(channelId);
    channel.postMessage(data);
  }

  /**
   * Subscribe to a channel, the returned callback should be called to unsubscribe when no longer needed
   */
  function subscribe({ channelId, onMessage } : BroadcastChannelSubscription): () => void {
    const channel = service.getChannel(channelId);
    function messageHandler(data) {
      // Ensure that the callback is called within a digest cycle
      $rootScope.$evalAsync(() => onMessage(data));
    }
    channel.addEventListener('message', messageHandler);
    return () => channel.removeEventListener('message', messageHandler);
  }
}
