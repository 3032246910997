import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { LegendWrapper } from '@/hybrid/trend/LegendWrapper.atom';

const signalGradientLegendItemsBindings = bindingsDefinition({
  getCapsuleName: prop<(any) => string>(),
  capsules: prop<any[]>(),
  title: prop<string>()
});

export const SignalGradientLegendItems: SeeqComponent<typeof signalGradientLegendItemsBindings> = (props) => {
  const { getCapsuleName, capsules, title } = props;

  return (
    <LegendWrapper title={title}>
      {_.chain([_.first(capsules), _.last(capsules)])
        .compact()
        .uniqBy('id')
        .reject(capsule => _.isEmpty(getCapsuleName(capsule)))
        .map((capsule, i, collection) => (
          <div className="text-nowrap" key={capsule.id}>
            <i className="pr5 fa fc fc-series" style={{ color: capsule.childColor }}/>
            {getCapsuleName(capsule)}
            {i === 0 && collection.length > 1 && <span className="ml5 mr5">...</span>}
          </div>
        ))
        .value()}
    </LegendWrapper>
  );
};
