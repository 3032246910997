/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class PluginsApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Delete a plugin
   * @param {string} id - The ID of the plugin
   */
  public deletePlugin(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/plugins/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Get a plugin file
   * @param {string} id - The ID of the plugin
   * @param {string} fileName - The name of the file from the plugin
   */
  public getFile(
    {
      id,
      fileName
    } : {
      id: string,
      fileName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(fileName)) {
      throw new Error("'fileName' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/plugins/{id}/files/{fileName}'
        .replace('{id}', encodeURIComponent(String(id)))
        .replace('{fileName}', encodeURIComponent(String(fileName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<{}>;
  }

  /**
   * Pagination is enabled for this query
   * @summary Get a collection of plugins
   * @param {string} [nameSearch] - Search text by which to filter plugins&#39; names.
   * @param {string} [identifierSearch] - Search text by which to filter plugins&#39; identifiers.
   * @param {string} [categorySearch] - Search text by which to filter plugins&#39; categories.
   * @param {string} [sortOrder=name asc] - A field by which to order the plugins followed by a space and &#39;asc&#39; or &#39;desc&#39;. Field name can be one of: name, identifier, category
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getPlugins(
    {
      nameSearch,
      identifierSearch,
      categorySearch,
      sortOrder,
      offset,
      limit
    } : {
      nameSearch?: string,
      identifierSearch?: string,
      categorySearch?: string,
      sortOrder?: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/plugins',
      params: omitBy({
        ['nameSearch']: nameSearch,
        ['identifierSearch']: identifierSearch,
        ['categorySearch']: categorySearch,
        ['sortOrder']: sortOrder,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.PluginOutputListV1>;
  }

  /**
   * 
   * @summary Get an SDK file
   * @param {string} fileName - The name of the file from the plugin
   */
  public getSdk(
    {
      fileName
    } : {
      fileName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(fileName)) {
      throw new Error("'fileName' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/plugins/sdk/{fileName}'
        .replace('{fileName}', encodeURIComponent(String(fileName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<{}>;
  }

  /**
   * 
   * @summary Upload a plugin
   * @param {any} file - Plugin to upload
   */
  public uploadPlugin(
    {
      file
    } : {
      file: File
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(file)) {
      throw new Error("'file' parameter required");
    }

    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/plugins',
      data: formData,
      headers: {
        // Prevents the default Content-Type. Because FormData is used,
        // ends up being 'multipart/form-data' with the boundary specified.
        ['Content-Type']: undefined
      },
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

}
