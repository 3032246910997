/* istanbul ignore file */

import React from 'react';
import _ from 'lodash';
import jQuery from 'jquery';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import Command from '@ckeditor/ckeditor5-core/src/command';
import icon from '@/hybrid/reportEditor/ckIcons/ckeditor5-seeq-content.svg';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import {
  CONTENT_MODEL_ATTRIBUTES,
  elementWithRoot,
  getContentDisplayMode,
  getContentSpecificCommand,
  moveNode,
  renderElementWithPortal,
  replacementImageUtils
} from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import {
  AttributeToListenTo,
  BasePluginDependencies,
  CK_PRIORITY,
  ContentCallbacks,
  ImageContentListenerCommand
} from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { DuplicatingContent } from '@/hybrid/annotation/ckEditorPlugins/components/DuplicatingContent.molecule';
import ArrowlessDropdownButton from '@/hybrid/annotation/ckEditorPlugins/views/ArrowlessDropdownButton';
import { InsertContentDropdown } from '@/hybrid/annotation/InsertContentDropdown.molecule';
import { IGNORE_CK_PAGINATION_VIEW_SELECTOR, ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { HtmlPortalNode } from 'react-reverse-portal';
import { ReportStore } from '@/reportEditor/report.store';
import { PluginDependencies } from '@/hybrid/annotation/ckEditorPlugins/plugins/PluginDependencies';
import { isLocalImage as isBase64Image } from '@ckeditor/ckeditor5-image/src/imageupload/utils';
import {
  ContentLoadObserver,
  ContentResizeHandles
} from '@/hybrid/annotation/ckEditorPlugins/plugins/content/ContentResize';
import ReactDOM from 'react-dom';
import { TOGGLE_CK_SAVING } from '@/hybrid/annotation/ckReport.module';
import { CONTENT_LOADING_CLASS } from '@/reportEditor/report.module';
import { exportedBase64guid as base64guid } from '@/services/utilities.service';

type CkContentMetadata = { modelElement: any, portalNode: HtmlPortalNode };

const DATA_SEEQ_CONTENT = CONTENT_MODEL_ATTRIBUTES.DATA_SEEQ_CONTENT;
const PASTE_ID = 'data-paste-id';
const jQueryDataSeeqContent = (contentId: string) => `${IGNORE_CK_PAGINATION_VIEW_SELECTOR} [${DATA_SEEQ_CONTENT}="${contentId}"]`;

export class Content extends Plugin {
  static pluginName = 'Content';
  static setup = {
    name: Content.pluginName,
    plugin: Content,
    toolbar: Content.pluginName
  };

  // A unique ID generated at plugin generation time that allows copy pasted content in a fast follow situation to
  // know whether its being rendered in the editor it was copied from or another editor, allowing us to dedupe
  // requests for Content and instead wait for the source document to finish the duplication request.
  private pasteId = base64guid();
  private contents = new Map<string, CkContentMetadata>();
  private contentIdsBeingCopied = new Set<string>();
  private useCkCopyLogic = true;
  private isSettingHtml = false;
  private portalWrapperElements: HTMLElement[] = [];

  updateContentModelAndNode(editor: any, contentId: string, modelElement: any, portalNode: HtmlPortalNode,
    contentIdChanged = false) {
    this.contents[contentId] = { modelElement, portalNode };

    // If this method is called, it means the content is 100% no longer pending
    Content.updateContentAttributeWithoutSaving(CONTENT_MODEL_ATTRIBUTES.PENDING, undefined, modelElement);
    if (contentIdChanged) {
      Content.updateContentAttribute(editor, DATA_SEEQ_CONTENT, contentId, this.contents[contentId].modelElement);
    }
  }

  private updateContentAttributeById(editor: any, attribute: string, value: string, contentId: string, save = true) {
    save
      ? Content.updateContentAttribute(editor, attribute, value, this.contents[contentId].modelElement)
      : Content.updateContentAttributeWithoutSaving(attribute, value, this.contents[contentId].modelElement);
  }

  private static updateContentAttributeWithoutSaving(attribute: string, value: string, modelElement: any) {
    modelElement._setAttribute(attribute, value);
  }

  private static updateContentAttribute(editor: any, attribute: string, value: string, modelElement: any) {
    editor.model.change((writer) => {
      writer.setAttribute(attribute, value, modelElement);
    });
  }

  private getContentModel(contentId: string): any | undefined {
    return this.contents[contentId]?.modelElement;
  }

  // Users can't conveniently copy Content from Seeq due to the security on the endpoint stopping the image from
  // being fetched outside of the Seeq environment. Img srcs can point at data blobs which it renders as an image
  // though. This goes through each piece of Seeq content, generates a canvas for each image, and sets Content's
  // src to said blob, allowing this to be copy pasted into external applications.
  private modifyAttributesForAllContentOnCopyCut(children: any[], editor: any, sqReportContent: ReportContentService,
    sqReportStore: ReportStore, appendPasteId = false) {
    _.forEach(children, (child: any) => {
      if (child.childCount > 0) {
        this.modifyAttributesForAllContentOnCopyCut(Array.from(child.getChildren()), editor, sqReportContent,
          sqReportStore, appendPasteId);
      } else {
        if (child.name === 'content') {
          const contentId = child.getAttribute(DATA_SEEQ_CONTENT);
          const model = this.contents[contentId].modelElement;
          if (!sqReportStore.getContentById(contentId).isReact) {
            // Selection models are different from the models used by the document for some reason
            Content.updateContentAttribute(editor, 'src',
              sqReportContent.getImageDataURL(jQuery(jQueryDataSeeqContent(contentId))[0]), model);
            setTimeout(() => Content.updateContentAttribute(editor, 'src', undefined, model));
          }
          appendPasteId && Content.updateContentAttributeWithoutSaving(PASTE_ID, this.pasteId, model);
          this.contentIdsBeingCopied.add(contentId);
        }
      }
    });
  }

  private onCopyCut(editor: any, sqReportContent: ReportContentService, sqReportStore: ReportStore,
    appendPasteId = false) {
    this.contentIdsBeingCopied.clear();
    this.modifyAttributesForAllContentOnCopyCut(
      Array.from(editor.model.getSelectedContent(editor.model.document.selection).getChildren()), editor,
      sqReportContent, sqReportStore, appendPasteId);
  }

  private setUseCkCopyLogic(useCkCopyLogic: boolean) {
    this.useCkCopyLogic = useCkCopyLogic;
  }

  static get requires() {
    return [Widget, ContentResizeHandles];
  }

  init() {
    this.defineSchema();
    this.defineConverters();
    this.defineToolbarButton();
    this.defineClipboardHandlers();
    this.defineAttributeEvents();

    // Expose insert content command
    const editor = this.editor;
    editor.commands.add('insertContent', new (InsertContentCommand as any)(this.editor));

    // Because of the inline-ness of our content widget, the internal model can sometimes fall out of place with the
    // view. This helps prevent that.
    editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(editor.model, viewElement => viewElement.hasClass('ck-widget')));

    // This guarantees the resize content load observer is registered before any content is inserted
    const editingView = editor.editing.view;
    editingView.addObserver(ContentLoadObserver);

    const updateContentModelAndNode = this.updateContentModelAndNode.bind(this);
    const updateContentAttributeById = this.updateContentAttributeById.bind(this);
    const getCurrentModel = this.getContentModel.bind(this);
    const setUseCkCopyLogic = this.setUseCkCopyLogic.bind(this);
    editor.config.define(Content.pluginName, {
      contentCallbacks: {
        updateContentAttributeById,
        updateContentModelAndNode,
        getCurrentModel,
        updateContentAttributeWithoutSaving: Content.updateContentAttributeWithoutSaving,
        setUseCkCopyLogic
      } as ContentCallbacks
    });

    // CRAB-26236: CK isn't smart about clearing its current content if you set new data (fast follow), so we tell the
    // Content plugin to reuse the existing components to save on component generation when saving is disabled, which is
    // called as part of fast follow
    editor.on(TOGGLE_CK_SAVING, (event, toggle) => this.isSettingHtml = !toggle);
  }

  // CK will call `destroy` on every plugin when `editor.destroy()` is called. We call it in `CKEditor.organism`
  destroy() {
    this.portalWrapperElements.forEach(ReactDOM.unmountComponentAtNode);
  }

  defineAttributeEvents() {
    const editor = this.editor;
    _.forEach(ImageContentListenerCommand, (command) => {
      const attribute = AttributeToListenTo[command];
      editor.conversion.for('downcast').add(dispatcher =>
        // Dedicated converter to fire content specific event for each attribute.
        dispatcher.on(`attribute:${attribute}:content`, (evt, data, conversionApi) => {
          if (!conversionApi.consumable.consume(data.item, evt.name)) {
            return;
          }
          editor.fire(getContentSpecificCommand(command, data.item.getAttribute(DATA_SEEQ_CONTENT)),
            data.attributeNewValue);
        })
      );
    });
  }

  defineClipboardHandlers() {
    const editor = this.editor;
    const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);
    const sqReportContent: ReportContentService = deps.$injector.get<ReportContentService>('sqReportContent');
    const sqReportStore: ReportStore = deps.$injector.get<ReportStore>('sqReportStore');

    // We don't need to handle pasting because we generate the src dynamically.
    editor.editing.view.document.on('copy', (event) => {
      // CK's default copy logic will override an html range copy
      if (!this.useCkCopyLogic) {
        event.stop();
        return;
      }
      this.onCopyCut(editor, sqReportContent, sqReportStore, true);
    });

    editor.editing.view.document.on('cut', () => {
      this.onCopyCut(editor, sqReportContent, sqReportStore);
    });

    // Since Seeq content is saved as imgs, CK's default image processors try to interfere with the base 64 blob on
    // paste. This sets an attribute which CK uses internally and skips the normal paste process that images go through.
    editor.plugins.get('ClipboardPipeline').on('inputTransformation', (evt, data) => {
      _.forEach(Array.from(editor.editing.view.createRangeIn(data.content)), (value: any) => {
        if (isBase64Image(replacementImageUtils, value.item) && value.item.getAttribute(DATA_SEEQ_CONTENT)) {
          value.item._setAttribute('uploadProcessed', '1');
        }
      });
    }, {
      priority: CK_PRIORITY.HIGH
    });
  }

  defineSchema() {
    this.editor.model.schema.register('content', {
      isObject: true,
      isInline: true,
      allowWhere: '$text',
      allowAttributes: [
        DATA_SEEQ_CONTENT,
        ..._.values(CONTENT_MODEL_ATTRIBUTES),
        PASTE_ID,
        'class']
    });
  }

  /**
   * Attempts to get attribute from elementToGet and sets the value an attribute on elementToSet
   */
  private static maybeSetElement(elementToGet: any, elementToSet: any, attribute: string) {
    const value = elementToGet.getAttribute(attribute);
    value && elementToSet._setAttribute(attribute, value);
  }

  defineConverters() {
    const editor = this.editor;
    const conversion = editor.conversion;
    const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);
    const sqReportStore: ReportStore = deps.$injector.get<ReportStore>('sqReportStore');

    // <content> converters ((data) view → model)
    conversion.for('upcast').elementToElement({
      // Explanation:
      // https://ckeditor.com/docs/ckeditor5/latest/api/module_engine_view_matcher-MatcherPattern.html
      view: {
        name: 'img',
        attributes: {
          [DATA_SEEQ_CONTENT]: true
        }
      },
      model: (viewElement, { writer: modelWriter }) => {
        // Read the "data-seeq-content" attribute from the view and set it as the "data-seeq-content" in the
        // model.
        const modelElement = modelWriter.createElement('content', {
          [DATA_SEEQ_CONTENT]: viewElement.getAttribute(DATA_SEEQ_CONTENT)
        });
        _.forEach({ ...CONTENT_MODEL_ATTRIBUTES, pasteId: PASTE_ID },
          attribute => Content.maybeSetElement(viewElement, modelElement, attribute));
        return modelElement;
      },
      // This will get run before all other matches, skipping normal image upcasting.
      converterPriority: 'highest'
    });

    // <content> converters (model → data view)
    conversion.for('dataDowncast').elementToElement({
      model: 'content',
      view: (modelElement, conversionApi) => {
        // In the data view, the model <content> corresponds to:
        // <img data-seeq-content="..."/>
        const viewElement = conversionApi.writer.createEmptyElement('img', {
          [DATA_SEEQ_CONTENT]: modelElement.getAttribute(DATA_SEEQ_CONTENT),
          src: modelElement.getAttribute('src')
        });
        _.forEach({ ...CONTENT_MODEL_ATTRIBUTES, PASTE_ID },
          attribute => Content.maybeSetElement(modelElement, viewElement, attribute));
        return viewElement;
      }
    });

    // <content> converters (model → editing view)
    conversion.for('editingDowncast').elementToElement({
      model: 'content',
      view: (modelElement, { writer: viewWriter }) => {
        // In the editing view, the model <content> corresponds to:
        //
        //  <div class="inlineBlock seeqContentWrapper">
        //    <div class="contentWrapper">
        //      <Content dataSeeqContent="..." /> (React component)
        //    </div>
        //  </div>
        const widthPercent = modelElement.getAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT)
          ? `width: ${modelElement.getAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT)};`
          : '';
        const outerWrapper = viewWriter.createContainerElement('div', {
          class: 'inlineBlock seeqContentWrapper',
          style: widthPercent
        });
        const contentId = modelElement.getAttribute(DATA_SEEQ_CONTENT);

        // This element will host a React <Content /> component.
        const reactWrapper = viewWriter.createRawElement('div', {
          class: 'reactWrapper'
        }, (domElement) => {
          // This the place where React renders the actual content hosted
          // by a UIElement in the view.
          const content = this.contents[contentId];

          // Since Content is inline, it can get deleted and reinserted when it gets moved down a line from someone
          // hitting enter. To prevent us having to rerender the content, we use REVERSE PORTALS to simply move
          // around the node referencing the Content to a new place.
          // See https://github.com/httptoolkit/react-reverse-portal and
          // CKEditorPlugins.utilities.tsx::renderElementWithPortal for an overview.

          const pasteId = modelElement.getAttribute(PASTE_ID);
          if (content
            && ((jQuery(jQueryDataSeeqContent(contentId)).length === 0 || (!this.contentIdsBeingCopied.has(
                contentId)) && !pasteId)
              || (this.isSettingHtml && !pasteId))) {
            moveNode(domElement, content.portalNode);
            this.contents[contentId] = { ...content, modelElement };
          } else if (this.isSettingHtml && modelElement.getAttribute(PASTE_ID)
            && modelElement.getAttribute(PASTE_ID) !== this.pasteId) {
            // Show a pending piece of content while waiting for real Content to come from the other documents paste
            domElement.classList.add(CONTENT_LOADING_CLASS.SPINNER);
            domElement.style.height = `${modelElement.getAttribute(CONTENT_MODEL_ATTRIBUTES.HEIGHT)}px`;
            domElement.style.width = `${modelElement.getAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH)}px`;
          } else {
            this.portalWrapperElements.push(
              renderElementWithPortal(deps, domElement, DuplicatingContent, {
                modelElement,
                contentId,
                displayMode: getContentDisplayMode(deps.canModify, deps.isPDF)
              })
            );
            Content.updateContentAttributeWithoutSaving(PASTE_ID, undefined, modelElement);
          }
        });

        viewWriter.insert(viewWriter.createPositionAt(outerWrapper, 0), reactWrapper);

        return toWidget(outerWrapper, viewWriter, { label: 'content widget' });
      }
    });
  }

  defineToolbarButton() {
    const editor = this.editor;
    editor.ui.componentFactory.add('Content', (locale) => {
      const dropdown = createDropdown(locale, ArrowlessDropdownButton);
      const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);

      dropdown.buttonView.set({
        label: deps.$translate.instant('REPORT.EDITOR.CONTENT_BUTTON_TITLE'),
        icon,
        tooltipPosition: 'e',
        tooltip: true,
        class: 'contentBtn'
      });

      dropdown.panelView.on('render', (eventInfo) => {
        elementWithRoot(deps, eventInfo.source.element, <InsertContentDropdown
          onClick={() => dropdown.isOpen = false} />);
      });

      return dropdown;
    });
  }
}

export class InsertContentCommand extends Command {
  isEnabled: boolean;

  execute(ignored, contentId) {
    const editor = this.editor;
    const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);
    const content = deps.$injector.get<ReportStore>('sqReportStore').getContentById(contentId);
    editor.model.change((writer) => {
      // Insert <content id="...">*</content> at the current selection position
      // in a way which will result in creating a valid model structure.
      editor.model.insertContent(writer.createElement('content', {
        [DATA_SEEQ_CONTENT]: contentId,
        [CONTENT_MODEL_ATTRIBUTES.BORDER]: !content.useSizeFromRender,
        [CONTENT_MODEL_ATTRIBUTES.NO_MARGIN]: false,
        [CONTENT_MODEL_ATTRIBUTES.HEIGHT]: content.height,
        [CONTENT_MODEL_ATTRIBUTES.WIDTH]: content.width
      }));
      // we need to focus back on the editor after a short delay
      setTimeout(() => {
        // @ts-ignore
        editor.editing.view.focus();
      }, 500);
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'content');

    this.isEnabled = allowedIn !== null;
  }
}
