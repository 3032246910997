import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { HomeScreenStore } from '@/hybrid/homescreen/homescreen.store';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';

const folderNavEntryBindings = bindingsDefinition({
  folder: prop<{ id, subfolders, name }>(),
  expandedFolderIds: prop<string[]>(),
  toggleAction: prop<(folderId: string) => void>(),
  openFolder: prop<(folderId: string) => any>(),
  sqHomeScreenStore: injected<HomeScreenStore>()
});

export const FolderNavEntry: SeeqComponent<typeof folderNavEntryBindings> = (props) => {
  const { sqHomeScreenStore } = useInjectedBindings(folderNavEntryBindings);
  const { folder, expandedFolderIds, toggleAction, openFolder } = props;
  const renderChildren = _.indexOf(expandedFolderIds, folder.id) > -1;

  const currentFolderId = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.currentFolderId);

  const classes = classNames('fa', 'mr5', 'mt2', 'gray-text', renderChildren ? 'fa-chevron-down' : 'fa-chevron-right');
  const labelClasses = classNames('gray-text', { 'text-bolder': currentFolderId === folder.id });

  return (
    <div className={classNames('flexRowContainer', 'folderNav', { folderNavExpanded: renderChildren })}>
      <div className="cursorPointer flexColumnContainer">
        <i data-testid={`folderToggle_${folder.id}`} className={classes} onClick={() => toggleAction(folder.id)} />
        <span className={labelClasses} onClick={() => openFolder(folder.id)}>{folder.name}</span>
      </div>

      {renderChildren && (
        <div className="ml15">
          {_.map(folder.subfolders,
            child => (<FolderNavEntry key={`folderSubTree_${child.id}`}
              folder={child}
              toggleAction={toggleAction}
              expandedFolderIds={expandedFolderIds}
              openFolder={openFolder} />))}
        </div>
      )}
    </div>
  );
};
