import React, { useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { ConfigureAutoUpdateModal } from '@/hybrid/reportEditor/ConfigureAutoUpdateModal.organism';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ReportStore } from '@/reportEditor/report.store';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { NextScheduledUpdate } from '@/hybrid/reportEditor/NextScheduledUpdate.molecule';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import classNames from 'classnames';
import _ from 'lodash';

const autoUpdateSectionBindings = bindingsDefinition({
  sqReportStore: injected<ReportStore>(),
  timezone: prop<string>(),
  viewOnly: prop.optional<boolean>()
});

export const AutoUpdateSection: SeeqComponent<typeof autoUpdateSectionBindings> = (props) => {
  const { sqReportStore } = useInjectedBindings(autoUpdateSectionBindings);

  const { timezone, viewOnly = false } = props;

  const [showAutoUpdateModal, setShowAutoUpdateModal] = useState(false);

  const { t } = useTranslation();

  const hasReportSchedule = useFluxPath(sqReportStore, () => sqReportStore.hasReportSchedule);
  const hasMultipleSchedules = useFluxPath(sqReportStore, () => sqReportStore.hasMultipleSchedules);
  const isScheduleEnabled = useFluxPath(sqReportStore, () => sqReportStore.isScheduleEnabled);

  const viewOnlyAutoUpdateSection =  <div className="flexRowContainer pt10 pb10 pl3">
   <NextScheduledUpdate timezone={timezone} onClick={_.noop} viewOnly={viewOnly}/>
  </div>;

  const editModeAutoUpdateSection = <div className="flexRowContainer pt10 pb10 pl3">
    {showAutoUpdateModal &&
    <ConfigureAutoUpdateModal
      schedule={sqReportStore.reportSchedule}
      show={showAutoUpdateModal}
      onClose={() => setShowAutoUpdateModal(false)} />}

    {!(sqReportStore.hasAutoDateRanges && hasReportSchedule && isScheduleEnabled) &&
    <div className="flexColumnContainer mt5 mr5">
      <label htmlFor="autoUpdateSection" className="flexFill">
        {t('REPORT.MODAL.AUTO_UPDATE.HEADER')}
      </label>
      <a href="#"
        data-testid="openConfigureAutoUpdateModalButton"
        className="flexColumnContainer link-no-focus link-no-underline"
        onClick={() => setShowAutoUpdateModal(true)}>
        <i className={classNames('fa', 'fa-fw', 'fa-edit', 'mr3', 'mt2',
          !hasReportSchedule ? 'error-text' : '')}
          data-testid={'editAutoConfig' + (!hasReportSchedule ? 'Error' : '')} />
      </a>
    </div>}
    {!isScheduleEnabled &&
    <div className="mr5">
      {t('REPORT.CONFIG.AUTO_UPDATE_SCHEDULE_DISABLED')}
    </div>}
    {!hasReportSchedule &&
    <div className="mr5">
      {t('REPORT.CONFIG.AUTO_UPDATE_SCHEDULE_NOT_CONFIGURED')}
    </div>}
    {sqReportStore.hasAutoDateRanges && hasReportSchedule && isScheduleEnabled &&
    <div>
      <NextScheduledUpdate timezone={timezone} onClick={() => setShowAutoUpdateModal(true)} />
    </div>}
    {hasMultipleSchedules &&
    <div className="mr5">
      {t('REPORT.CONFIG.AUTO_UPDATE_SCHEDULE_MULTIPLE_SCHEDULES')}
    </div>}
  </div>;

  return viewOnly ? viewOnlyAutoUpdateSection : editModeAutoUpdateSection;
};
