import React, { useState } from 'react';
import { SearchWidget } from '@/hybrid/search/SearchWidget.organism';
import { SEARCH_PANES } from '@/search/search.module';

interface SearchForExistingConditionOrCalculatedSignalInterface {
  setSelectedItem: (input: { name: string, item: { name: string, id: string } }) => void;
}

export const SearchForExistingConditionOrCalculatedSignal: React.FunctionComponent<SearchForExistingConditionOrCalculatedSignalInterface> = ({ setSelectedItem }) => {
  const SEARCH_TYPES = ['Asset', 'Condition', 'CalculatedSignal', 'CalculatedScalar', 'CalculatedCondition'];
  const [selected, setSelected] = useState(undefined);

  return (
    <div className="flexRowContainer max-height-600 overflowHidden">
      <SearchWidget
        pane={SEARCH_PANES.MODAL}
        searchTypes={SEARCH_TYPES}
        allowAssetReplacement={false}
        onItemClick={(item) => {
          setSelectedItem(item);
          setSelected(item);
        }}
        indicateSelection={true}
        selectedItemId={selected?.id}
        restrictExploration={true}
      />
    </div>
  );
};
