import bind from 'class-autobind-decorator';
import exportODataModalTemplate from '@/trendViewer/exportODataModal.html';
import _ from 'lodash';
import { ExportService } from '@/services/export.service';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { InvestigateStore } from '@/investigate/investigate.store';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { TrendStore } from '@/trendData/trend.store';
import { DurationStore } from '@/trendData/duration.store';
import { WorkbookStore } from '@/workbook/workbook.store';
import { WorkstepsStore } from '@/worksteps/worksteps.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { ExportODataPanelStore, GRID_OPTION } from '@/investigate/exportOData/exportODataPanel.store';
import { DurationActions } from '@/trendData/duration.actions';
import { ExportODataPanelActions } from '@/investigate/exportOData/exportODataPanel.actions';
import { Feature, LicenseManagementStore } from '@/licenseManagement/licenseManagement.store';
import { NotificationsService } from '@/services/notifications.service';
import { ITEM_TYPES } from '@/trendData/trendData.module';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { ToolPanelHelperService } from '@/services/toolPanelHelper.service';
import { ModalService } from '@/services/modal.service';
import { UtilitiesService } from '@/services/utilities.service';
import { APP_STATE } from '@/main/app.constants';

@bind
export class ExportODataPanelController {
  public activeTool: string = this['activeTool'];
  public toolName: string = this['toolName'];
  public autoupdateTimeRange: boolean = this['autoupdateTimeRange'];
  public autoupdateDisabled: boolean = this['autoupdateDisabled'];
  public name: string = this['name'];
  public timezone = this['timezone'];
  public timeRange = this['timeRange'];
  public exportTimeZone = this['exportTimeZone'];
  public exportSignals = this['exportSignals'];
  public exportSignalIds = this['exportSignalIds'];
  public exportConditions = this['exportConditions'];
  public exportConditionIds = this['exportConditionIds'];
  public gridOption = this['gridOption'];
  public gridSize = this['gridSize'];
  public gridOrigin = this['gridOrigin'];
  public gridOriginEnabled = this['gridOriginEnabled'];
  public customGridValid = this['customGridValid'];
  public isCapsuleTime = this['isCapsuleTime'];
  public isChainView = this['isChainView'];
  ITEM_TYPES = ITEM_TYPES;
  GRID_OPTION = GRID_OPTION;

  constructor(
    private $scope: ng.IScope,
    private $state: ng.ui.IStateService,
    private sqModal: ModalService,
    private sqInvestigateStore: InvestigateStore,
    private sqInvestigateActions: InvestigateActions,
    private sqExport: ExportService,
    private sqTrendDataHelper: TrendDataHelperService,
    private sqTrendStore: TrendStore,
    private sqDurationStore: DurationStore,
    private sqDurationActions: DurationActions,
    private sqExportODataPanelActions: ExportODataPanelActions,
    private sqExportODataPanelStore: ExportODataPanelStore,
    private sqWorkbookStore: WorkbookStore,
    private sqWorkstepsStore: WorkstepsStore,
    private sqWorksheetStore: WorksheetStore,
    private sqLicenseManagementStore: LicenseManagementStore,
    private sqUtilities: UtilitiesService,
    private sqNotifications: NotificationsService,
    private sqToolPanelHelper: ToolPanelHelperService) {

    $scope.$listenTo(sqExportODataPanelStore, this.setExportODataVars);
    $scope.$listenTo(sqInvestigateStore, this.setInvestigateVars);
    $scope.$listenTo(sqLicenseManagementStore, this.setLicenseVars);
    $scope.$listenTo(sqWorksheetStore, ['timezone'], this.setWorksheetVars);
    $scope.$listenTo(sqTrendStore, ['view'], this.setTrendVars);

    this.toolName = TREND_TOOLS.EXPORT_ODATA;
    this.customGridValid = true;
  }

  close = this.sqInvestigateActions.close;
  setTimeRangeStart = this.sqExportODataPanelActions.setTimeRangeStart;
  setTimeRangeEnd = this.sqExportODataPanelActions.setTimeRangeEnd;
  setDuration = this.sqExportODataPanelActions.setDuration;
  setExportTimeZone = this.sqExportODataPanelActions.setExportTimeZone;
  setAutoupdateTimeRange = this.sqExportODataPanelActions.setAutoupdateTimeRange;
  setExportName = name => this.sqInvestigateActions.setSearchName(TREND_TOOLS.EXPORT_ODATA, name);
  addExportSignal = _.partial(this.sqInvestigateActions.setParameterItem, TREND_TOOLS.EXPORT_ODATA,
    'exportSignals');
  removeExportSignal = _.partial(this.sqInvestigateActions.unsetParameterItem, TREND_TOOLS.EXPORT_ODATA,
    'exportSignals');
  addExportCondition = _.partial(this.sqInvestigateActions.setParameterItem, TREND_TOOLS.EXPORT_ODATA,
    'exportConditions');
  removeExportCondition = _.partial(this.sqInvestigateActions.unsetParameterItem, TREND_TOOLS.EXPORT_ODATA,
    'exportConditions');
  setGridOption = this.sqExportODataPanelActions.setGridOption;
  setGridOrigin = this.sqExportODataPanelActions.setGridOrigin;
  setGridOriginEnabled = this.sqExportODataPanelActions.setGridOriginEnabled;
  hasValidItemParameters = this.sqToolPanelHelper.hasValidItemParameters;

  /**
   * Syncs the sqExportODataPanelStore
   */
  setExportODataVars() {
    this.name = this.sqExportODataPanelStore.name;
    this.exportSignals = this.sqExportODataPanelStore.exportSignals;
    this.exportSignalIds = _.map(this.exportSignals, 'id');
    this.exportConditions = this.sqExportODataPanelStore.exportConditions;
    this.exportConditionIds = _.map(this.exportConditions, 'id');
    this.timeRange = this.sqExportODataPanelStore.timeRange;
    this.exportTimeZone = this.sqExportODataPanelStore.exportTimeZone;
    this.autoupdateTimeRange = this.sqExportODataPanelStore.autoupdateTimeRange;
    this.gridOption = this.sqExportODataPanelStore.gridOption;
    this.gridSize = this.sqExportODataPanelStore.gridSize;
    this.gridOrigin = this.sqExportODataPanelStore.gridOrigin;
    this.gridOriginEnabled = this.sqExportODataPanelStore.gridOriginEnabled;
  }

  /**
   * Sets a custom grid size.
   *
   * @param {Object} gridSizeObj
   * @param (number) maximumDuration.value - The maximum duration value
   * @param (string) maximumDuration.units - The maximum duration units
   * @param (boolean) maximumDuration.valid - The validity of the maximum duration
   */
  setGridSize(gridSizeObj) {
    this.customGridValid = gridSizeObj.valid;
    this.sqExportODataPanelActions.setGridSize(gridSizeObj);
  }

  /**
   * Syncs the sqInvestigateStore
   */
  setInvestigateVars() {
    this.activeTool = this.sqInvestigateStore.activeTool;
  }

  /**
   * Syncs the sqInvestigateStore
   */
  setLicenseVars() {
    this.autoupdateDisabled = !this.sqLicenseManagementStore.hasValidFeature(Feature.ODATA_Realtime_Export);
  }

  /**
   * Syncs the sqWorksheetStore
   */
  setWorksheetVars() {
    this.timezone = this.sqWorksheetStore.timezone;
  }

  /**
   * Syncs the sqTrendStore
   */
  setTrendVars() {
    this.isCapsuleTime = this.sqTrendStore.isTrendViewCapsuleTime();
    this.isChainView = this.sqTrendStore.isTrendViewChainView();
  }

  /**
   * Generate a Workbench URL to current Worksheet, with view-only access
   *
   * @returns {String} Returns a absolute URL
   */
  makeWorksheetURL() {
    return this.$state.href(APP_STATE.VIEW_WORKSHEET, {
      workbookId: this.$state.params.workbookId,
      worksheetId: this.$state.params.worksheetId
    }, {
      absolute: true
    });
  }

  execute() {
    const items = this.exportSignals.concat(this.exportConditions);
    const exportName = `${this.name}-Export-${this.sqWorkbookStore.getWorksheetName(
      this.$state.params.worksheetId)}_${this.sqWorkstepsStore.current.id}`;
    const gridSizeEnabled = this.gridOption === GRID_OPTION.CUSTOM;
    const gridOriginString = gridSizeEnabled && this.gridOriginEnabled && !this.isCapsuleTime ?
      // Format the origin so it's output as a localized time string
      this.gridOrigin.format('YYYY-MM-DD[T]HH:mm:ss') : null;

    return this.sqExport.toOData(
      exportName,
      items,
      this.timeRange,
      this.autoupdateTimeRange,
      true,
      this.isCapsuleTime,
      this.isChainView,
      false,
      gridSizeEnabled && (this.gridSize.value + this.gridSize.units),
      gridOriginString,
      this.exportTimeZone.name,
      this.sqWorkbookStore.workbookId,
      this.makeWorksheetURL())
      .then(({ data: { href: generalExportLink } }) => this.sqExport.toOData(
        `${exportName}Capsule`,
        items,
        this.timeRange,
        this.autoupdateTimeRange,
        true,
        this.isCapsuleTime,
        this.isChainView,
        true,
        gridSizeEnabled && (this.gridSize.value + this.gridSize.units),
        gridOriginString,
        this.exportTimeZone.name,
        this.sqWorkbookStore.workbookId,
        this.makeWorksheetURL())
        .then(({ data: { href: odataCapsulePath } }) => this.sqModal.open({
          animation: true,
          controller: 'ExportODataModalCtrl',
          controllerAs: 'ctrl',
          template: exportODataModalTemplate,
          resolve: {
            odataPath: _.constant(generalExportLink),
            odataCapsulePath: _.constant(odataCapsulePath)
          }
        }).result)
      )
      .catch(this.sqUtilities.handleModalOpenErrors);
  }
}
