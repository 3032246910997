import angular from 'angular';
import { PluginsApi } from '@/sdk';
import { NotificationsService } from '@/services/notifications.service';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { PluginOutputV1 } from '@/sdk/model/PluginOutputV1';

angular.module('Sq.PluginHost').service('sqPluginActions', sqPluginActions);

export type PluginActions = ReturnType<typeof sqPluginActions>;

function sqPluginActions(
  flux: ng.IFluxService,
  sqPluginsApi: PluginsApi,
  sqNotifications: NotificationsService,
  sqSystemConfiguration: SystemConfigurationService
) {
  const service = {
    load,
    setPlugins,
    setPluginState,
    setDisplayPaneRenderComplete
  };

  function load() {
    return sqPluginsApi.getPlugins({ offset: 0, limit: 5000 })
      .then(({ data: { plugins } }) => flux.dispatch('PLUGINS_SET', { plugins }));
  }

  function setPlugins(plugins: PluginOutputV1[]) {
    flux.dispatch('PLUGINS_SET', { plugins });
  }

  function setPluginState(pluginIdentifier, pluginState) {
    flux.dispatch('PLUGINS_SET_STATE', { pluginIdentifier, pluginState });
  }

  function setDisplayPaneRenderComplete(complete) {
    flux.dispatch('PLUGINS_SET_DISPLAY_PANE_RENDER_COMPLETE', { complete });
  }

  return service;
}
