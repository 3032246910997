import bind from 'class-autobind-decorator';
import { ScatterConditionStore } from '@/investigate/scatterCondition/scatterCondition.store';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { FrontendDuration } from '@/services/systemConfiguration.service';

@bind
export class ScatterConditionActions {
  constructor(
    private flux: ng.IFluxService,
    private sqToolRunner: ToolRunnerService,
    private sqScatterConditionStore: ScatterConditionStore) {
  }

  /**
   * Generate a condition from a formula
   */
  generate(name, formula, formulaParameters, color) {
    return this.sqToolRunner.panelExecuteCondition(
      name,
      formula,
      formulaParameters,
      this.sqScatterConditionStore.configParams,
      this.sqScatterConditionStore.id,
      color,
      { closeOnSuccess: false });
  }

  /**
   * Set whether or not to bound the generated condition by the display range
   *
   * @param isBounding - true to create condition only display range, false to create condition over all time
   */
  setBounding(isBounding: boolean) {
    this.flux.dispatch('SCATTER_CONDITION_SET_BOUNDING', { isBounding });
  }

  /**
   * Set whether or not to ignore short capsules/gaps
   *
   * @param isCleansing - true to ignore short capsules/gaps, false to include them
   */
  setCleansing(isCleansing: boolean) {
    this.flux.dispatch('SCATTER_CONDITION_SET_CLEANSING', { isCleansing });
  }

  /**
   * Set the minimum capsule duration to allow
   *
   * @param minDuration - minimum allowable capsule duration
   */
  setMinDuration(minDuration: FrontendDuration) {
    this.flux.dispatch('SCATTER_CONDITION_SET_MIN_DURATION', { minDuration });
  }

  /**
   * Set the maximum gap duration over which to merge capsules
   *
   * @param mergeDuration - maximum duration to merge capsules over a gap
   */
  setMergeDuration(mergeDuration: FrontendDuration) {
    this.flux.dispatch('SCATTER_CONDITION_SET_MERGE_DURATION', { mergeDuration });
  }
}
