import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { FormulaToolActions } from '@/hybrid/tools/formula/formulaTool.actions';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { FormulaTextWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';

type FormulaFunction = {
  name: string,
  fluent: boolean,
  parameters: FunctionParameters[],
  returnType: string,
  description: string
};

type FunctionParameters = {
  name: string,
  optional: boolean,
  type: string
};

const formulaFunctionsBindings = bindingsDefinition({
  insertFormulaSnippet: prop<(snippet: string) => void>(),
  functions: prop<FormulaFunction[]>(),
  sqFormulaToolActions: injected<FormulaToolActions>(),
  requestDocumentation: prop<(href: string) => any>()
});

const functionParameterBindings = bindingsDefinition({
  parameters: prop<FunctionParameters[]>(),
  hideFirst: prop.optional<boolean>(),
  sqFormulaToolActions: injected<FormulaToolActions>()
});

// Exported for testing purposes only
export const FunctionParameters: SeeqComponent<typeof functionParameterBindings> = ({
  parameters,
  hideFirst = false
}) => {
  const { sqFormulaToolActions } = useInjectedBindings(functionParameterBindings);

  return (
    <>
      {_.chain(hideFirst ? _.tail(parameters) : parameters)
        .map(param => (
          <HoverTooltip key={param.name} text={param.optional ? 'FORMULA.PARAM_OPTIONAL' : ''}>
            <span key={`${param.name}-name`} className={classNames({ 'text-italic': param.optional },
              sqFormulaToolActions.formulaTypeClass(param.type))}>
              {param.name}
            </span>
          </HoverTooltip>
        ))
        .reduce((previous, current) => [previous, ', ', current])
        .value()}
    </>
  );
};

export const FormulaFunctions: SeeqComponent<typeof formulaFunctionsBindings> = ({
  functions,
  insertFormulaSnippet,
  requestDocumentation
}) => {
  const { sqFormulaToolActions } = useInjectedBindings(formulaFunctionsBindings);
  const { t } = useTranslation();

  const isLast = index => _.toNumber(index) === functions.length - 1;

  const addToFormula = (formula) => {
    const parameters = _.reduce(formula.parameters, (parameters, parameter) =>
      _.concat(parameters, parameter.name + (_.endsWith(parameter.type, '...') ? '...' : '')), []);
    const fancyFormula = `${formula.name}(${_.join(parameters, ', ')})`;
    insertFormulaSnippet(fancyFormula);
  };

  return (
    functions?.length > 0 &&
    <>
      <h2>{t('FORMULA.DOCUMENTATION.VARIATIONS')}</h2>
      {_.map(functions, (operator, index) => (
        <React.Fragment key={`operator_${index}`}>
          <div className={classNames({ mb15: isLast(index) })}>
            <div className="mt5 flexColumnContainer flexSpaceBetween">
              {!operator.fluent &&
              <div data-testid="nonFluentOperator">
                <b className="highlightedText">{operator.name}</b>
                (<FunctionParameters parameters={operator.parameters} />):
                <span className={classNames(sqFormulaToolActions.formulaTypeClass(operator.returnType), 'pl2')}>
                  {operator.returnType}
                </span>
              </div>}
              {operator.fluent &&
              <div data-testid="fluentOperator">
                {operator.parameters.length > 0 &&
                <>
                  <span
                    className={classNames(sqFormulaToolActions.formulaTypeClass(operator.parameters[0].type), 'pl2')}>
                    {operator.parameters[0].name}.
                  </span>
                  <b className="highlightedText">{operator.name}</b>
                </>}
                (<FunctionParameters parameters={operator.parameters} hideFirst={true} />):
                <span className={sqFormulaToolActions.formulaTypeClass(operator.returnType)}>
                  {operator.returnType}
                </span>
              </div>}
              <HoverTooltip text="COPY">
                <span>
                  <Icon icon="fc-copy"
                    testId="addFunctionIcon"
                    extraClassNames="cursorPointer pt3 pr5"
                    onClick={() => addToFormula(operator)} />
                </span>
              </HoverTooltip>
            </div>
            <div className="pl10 functionDescription">
              <FormulaTextWithHTML content={operator.description} requestDocumentation={requestDocumentation} />
            </div>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};
