import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Select, { createFilter } from 'react-select';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { Config } from 'react-select/src/filters';

/**
 * Select input to choose from a list of units of measure
 */
const selectUnitBindings = bindingsDefinition({
  onChange: prop<(unit: string) => void>(),
  value: prop.optional<string>(),
  height: prop.optional<string>(),
  menuIsOpen: prop.optional<boolean>(),
  focusOnOpen: prop.optional<boolean>(),
  isDisabled: prop.optional<boolean>(),
  appendToBody: prop.optional<boolean>(),
  isClearable: prop.optional<boolean>(),
  placeholder: prop.optional<string>(),
  sqSystemConfiguration: injected<SystemConfigurationService>()
});

export const SelectUnit: SeeqComponent<typeof selectUnitBindings> = (props) => {
  const { t } = useTranslation();
  const { sqSystemConfiguration } = useInjectedBindings(selectUnitBindings);
  const {
    onChange,
    value,
    height,
    menuIsOpen,
    focusOnOpen = false,
    isDisabled = false,
    isClearable = true,
    appendToBody = false,
    placeholder
  } = props;
  const [options, setOptions] = useState([]);
  useEffect(() => {
    sqSystemConfiguration.supportedUnits.then((units) => {
      setOptions(_.reduce(units, (result, unitsForCategory, category) => result.concat({
        label: t('UNIT_CATEGORIES.' + category),
        options: _.map(unitsForCategory, unit => ({
          searchString: `${t(`UNITS_BY_CATEGORY.${category}.${unit}`)} ${unit}`,
          value: unit,
          label: (
            <>
              <strong className="pr5" data-testid="optionText">{unit}</strong>
              <small>{t(`UNITS_BY_CATEGORY.${category}.${unit}`)}</small>
            </>
          )
        }))
      }), []));
    });
  }, []);

  const customStyles = {
    menuList: base => ({
      ...base,
      ...(height && { minHeight: 'fit-content', maxHeight: height })
    })
  };

  const selectedValue = _.chain(options)
    .flatMap('options')
    .find({ value })
    .value();

  const filterConfig: Config = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: false,
    matchFrom: 'any',
    stringify: option => option.data.searchString
  };

  return (
    <div data-testid="selectUnit">
      <Select
        className="selectUnit"
        placeholder={placeholder ? t(placeholder) : undefined}
        isClearable={isClearable}
        options={options}
        isSearchable={true}
        onChange={data => onChange(data?.value)}
        value={selectedValue}
        classNamePrefix="react-select"
        styles={customStyles}
        menuIsOpen={menuIsOpen}
        menuPortalTarget={appendToBody ? document.body : undefined}
        isDisabled={isDisabled}
        filterOption={createFilter(filterConfig)}
        ref={(ref) => {
          if (ref && focusOnOpen) {
            ref.select.focus();
          }
        }}
      />
    </div>);
};

export default SelectUnit;
