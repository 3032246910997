import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { RedactionService } from '@/services/redaction.service';
import { UtilitiesService } from '@/services/utilities.service';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { NotificationsService } from '@/services/notifications.service';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { InvestigateStore } from '@/investigate/investigate.store';
import { TrendScalarStore } from '@/trendData/trendScalar.store';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { WorkbookStore } from '@/workbook/workbook.store';
import { ItemDecoratorService } from '@/trendViewer/itemDecorator.service';
import { IE } from '@/main/app.constants';
import { DETAILS_PANE_ITEM_TYPES } from '@/trendData/trendData.module';
import { AddToDisplayPane } from '@/hybrid/workbooks/AddToDisplayPane.molecule';
import { ItemsApi } from '@/sdk';
import { SelectItemWrapper } from '@/hybrid/core/SelectItemWrapper.organism';
import { TrendTableStore } from '@/trendData/trendTable.store';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { TrendMetricStore } from '@/trendData/trendMetric.store';
import { FormControl } from 'react-bootstrap';

const selectItemBindings = bindingsDefinition({
  // true if the select can be toggled between a text input field and a select.
  textEntryAllowed: prop.optional<boolean>(),
  textPlaceholder: prop.optional<string>(),
  textToggleDisabled: prop.optional<boolean>(),
  selectPlaceholder: prop.optional<string>(),
  insideModal: prop.optional<boolean>(),
  excludeStoreItems: prop.optional<boolean>(),
  includeAddToDisplayPane: prop.optional<boolean>(),
  excludeWorkbookItems: prop.optional<boolean>(),
  // true does not automatically select even if there's only one item
  disableAutoSelect: prop.optional<boolean>(),
  showAddToDisplayPane: prop.optional<boolean>(),
  includeMetadata: prop.optional<boolean>(),
  excludeStringSignals: prop.optional<boolean>(),
  isMultipleSelect: prop.optional<boolean>(),
  selectedItemId: prop<string | string[]>(),
  excludedIds: prop.optional<string[]>(),
  additionalItems: prop.optional<object[]>(),
  // to limit items to only certain item types
  itemTypes: prop.optional<string[]>(),
  // index used to set the threshold in scorecard
  thresholdLevel: prop.optional<string>(),
  // used by formula, the name
  identifier: prop.optional<string>(),
  // used by formula, index
  paramIndex: prop.optional<string>(),
  items: prop.optional<object []>(),
  allowClear: prop.optional<boolean>(),
  className: prop.optional<any>(),
  // for Asset Groups we need to compare the items to the group as well as the item ids will be the same for asset
  // column items
  selectByIdAndGroup: prop.optional<boolean>(),
  // Determines if the select element is allowed to wrap when the name is long
  noWrap: prop.optional<boolean>(),
  onSelect: prop<((selectedItemOrValue: object | string, level?: number | string) => void)>(),
  onRemove: prop.optional<(selectedItem: object) => void>(),
  // called when input is toggled from text input to signal select or the other way around. used for formBuilder
  onToggle: prop.optional<() => void>(),
  sqNotifications: injected<NotificationsService>(),
  sqTrendSeriesStore: injected<TrendSeriesStore>(),
  sqInvestigateStore: injected<InvestigateStore>(),
  sqTrendScalarStore: injected<TrendScalarStore>(),
  sqTrendTableStore: injected<TrendTableStore>(),
  sqTrendCapsuleSetStore: injected<TrendCapsuleSetStore>(),
  sqTrendMetricStore: injected<TrendMetricStore>(),
  sqTrendDataHelper: injected<TrendDataHelperService>(),
  sqWorkbookStore: injected<WorkbookStore>(),
  sqItemDecorator: injected<ItemDecoratorService>(),
  sqItemsApi: injected<ItemsApi>(),
  sqRedaction: injected<RedactionService>(),
  sqUtilities: injected<UtilitiesService>()
});

/** Select dropdown for searching for and selecting Seeq Items */
export const SelectItemUnwrapped: SeeqComponent<typeof selectItemBindings> = (props) => {
  const { t } = useTranslation();

  const {
    sqNotifications,
    sqUtilities,
    sqTrendDataHelper,
    sqWorkbookStore,
    sqItemDecorator,
    sqItemsApi
  } = useInjectedBindings(selectItemBindings);

  const {
    textEntryAllowed,
    textPlaceholder,
    textToggleDisabled,
    selectPlaceholder,
    disableAutoSelect,
    showAddToDisplayPane = true,
    selectedItemId = undefined,
    onRemove,
    onSelect,
    isMultipleSelect,
    excludeStoreItems,
    excludeWorkbookItems,
    additionalItems,
    excludeStringSignals,
    itemTypes,
    includeMetadata,
    identifier,
    thresholdLevel,
    paramIndex,
    allowClear,
    onToggle,
    insideModal,
    className,
    noWrap = false,
    selectByIdAndGroup = false
  } = props;

  useEffect(() => {
    const validItemSelected = sqUtilities.validateGuid(selectedItemId);
    if (textEntryAllowed) {
      setDisplayTextEntry(!validItemSelected);
      if (!validItemSelected) {
        setTextInputText(selectedItemId ?? '');
      }
    }
  }, [selectedItemId]);

  const [loadingMetadata, setLoadingMetadata] = useState(false);
  const [displayTextEntry, setDisplayTextEntry] = useState(false);
  const [previousValue, setPreviousValue] = useState<string | string[]>('');

  // This is a bit of a hack to work around the fact that text input is too slow when running system tests.
  // To prevent errors, we store values from ongoing typing in the state and only update the store when the onBlur
  // is triggered. Once all forms use FormBuilder the Form can be used to store the state and we won't need to keep
  // values in local state.
  const textInputValue = sqUtilities.validateGuid(selectedItemId) || _.isUndefined(selectedItemId) ? '' :
    selectedItemId;
  const [textInputText, setTextInputText] = useState(textInputValue);
  const cancellationGroup = `selectItemMetadata_${sqUtilities.base64guid()}`;

  if (textEntryAllowed && isMultipleSelect) {
    throw new Error('Item Select can not be isMultipleSelect and textEntryAllowed');
  }

  const displayAddToDisplayPane = showAddToDisplayPane && !textEntryAllowed && !_.isNil(selectedItemId) &&
    !isMultipleSelect;

  const trendItems = _.concat(sqTrendDataHelper.getAllItems(), additionalItems || []);

  const excludedIds = _.chain(trendItems)
    .flatMap(items => _.chain(items)
      .filter('swapSourceId')
      .map('swapSourceId')
      .value()
    )
    .concat(props.excludedIds as any)
    .compact()
    .value();

  const displayItems = excludeStoreItems
    ? additionalItems
    : _.chain(trendItems)
      .concat(excludeWorkbookItems ? [] :
        _.map(sqWorkbookStore.pinned, item => ({ ...item, selectGroup: 'pinned' })))
      .concat(excludeWorkbookItems ? [] :
        _.map(sqWorkbookStore.recentlyAccessed, item => ({ ...item, selectGroup: 'recentlyAccessed' })))
      .map(item => sqUtilities.addItemType(item))
      .map(item =>
        _.includes(DETAILS_PANE_ITEM_TYPES, item.itemType) ? sqItemDecorator.decorate(item) : item
      )
      .reject(item => excludeStringSignals ? sqUtilities.isStringSeries(item) : false)
      .reject(item => _.includes(excludedIds, item.id))
      .filter(item => _.isEmpty(itemTypes) ? true : _.includes(_.castArray(itemTypes), item.itemType))
      .uniqBy('id')
      .value();

  // This is necessary to ensure the dropdown options render in the correct width
  const charCount: number = _.chain(displayItems)
    .map(item => item?.name?.length)
    .max()
    .value();

  const dropdownWidth = (IE ? 9 : 8) * charCount + 30 + 'px';

  const toggleTextEntryDisplay = () => {
    if (_.isFunction(onToggle)) {
      onToggle();
    }
    const previouslyEntered = previousValue;
    const toggleToDisplayText = !displayTextEntry;

    setPreviousValue(selectedItemId);

    const item = toggleToDisplayText ? previouslyEntered : _.find(displayItems, { id: previouslyEntered });

    onItemSelect(item);

    setDisplayTextEntry(toggleToDisplayText);
  };

  const onItemSelect = (item) => {
    if (!_.isFunction(onSelect)) {
      return;
    }

    if (_.isNil(item) && allowClear) {
      onSelect(null);
    }

    // Retrieving metadata is not supported if multiple selection is enabled or if item is a text value or
    // undefined
    if (!_.isObject(item) || !includeMetadata || isMultipleSelect) {
      if (isMultipleSelect) {
        const test = _.reject(item, obj => !_.has(obj, 'label'));
        onSelect(_.first(test).value);
      } else if (textEntryAllowed) {
        if (!_.isNil(item)) {
          onSelect(item, thresholdLevel);
        }
      } else if (identifier) {
        // formula
        onSelect(paramIndex, { identifier, item });
      } else {
        onSelect(item);
      }

    } else {
      setLoadingMetadata(true);

      sqItemsApi.getItemAndAllProperties({ id: item.id }, { cancellationGroup })
        .then(({ data: fetchedItem }) => {
          const decoratedItem = fetchedItem ? sqUtilities.decorateItemWithProperties(fetchedItem) : item;
          textEntryAllowed ? onSelect(decoratedItem, thresholdLevel) : onSelect(decoratedItem);
        })
        .catch((e) => {
          sqNotifications.apiError(e);
        })
        .finally(() => {
          setLoadingMetadata(false);
        });
    }
  };

  const setThreshold = event => setTextInputText(event.target.value);
  const updateThresholdInStore = event => onSelect(event.target.value, thresholdLevel);

  const renderTextInput = (
    <FormControl
      type="text"
      value={textInputText}
      onChange={setThreshold}
      onBlur={updateThresholdInStore}
      className={classNames('selectItemTextInput', 'textEntryInput', 'transitionNone', 'flexFill', 'pl10', 'btlr4' +
        'bblr4' + 'borderStyleSolid', 'borderColorGray', 'height-34', className)}
      size="sm"
      placeholder={t(textPlaceholder ? textPlaceholder : 'ENTER_VALUE')}
    />
  );

  const selectedItem = isMultipleSelect ? _.difference(selectedItemId, excludedIds) :
    _.find(displayItems, { id: selectedItemId });

  const selectBaseClass = classNames('react-select', {
    'react-multiple-select': isMultipleSelect,
    'react-select-text-entry': textEntryAllowed
  });

  const renderSelect = (
    <SelectItemWrapper
      cssClassName={classNames(className, selectBaseClass)}
      selectPlaceholder={selectPlaceholder}
      insideModal={insideModal}
      selected={selectedItem}
      isMultipleSelect={isMultipleSelect}
      items={displayItems}
      onChange={onItemSelect}
      onRemove={onRemove}
      loadingMetadata={loadingMetadata}
      dropdownWidth={dropdownWidth}
      allowClear={allowClear}
      disableAutoSelect={disableAutoSelect}
      noWrap={noWrap || textEntryAllowed}
      selectByIdAndGroup={selectByIdAndGroup}
    />);

  const textInputTooltip = textToggleDisabled ? 'VALUE_SEARCH.STRING_SIGNAL_TOOLTIP' : 'USE_ITEM_SELECTOR';
  const renderTextInputToggle = (
    <HoverTooltip text={displayTextEntry ? textInputTooltip : 'USE_VALUE_ENTRY'}>
      <span className="d-inline-block">
        <button type="button"
          data-testid="item-select-toggle"
          onClick={toggleTextEntryDisplay}
          disabled={textToggleDisabled}
          className={classNames('btn btn-default flexRowContainer flexCenter height-34 pl4 pr4 btlr0 bblr0',
            { disabledBehavior: textToggleDisabled })}>
          <div className={classNames(displayTextEntry ? 'fc fc-scalar' : 'fc fc-all-items')} />
        </button>
      </span>
    </HoverTooltip>
  );

  return (
    <div className="flexFillOverflow flexColumnContainer flexAlignCenter" data-testid="itemSelect">
      {textEntryAllowed && displayTextEntry && renderTextInput}
      {!displayTextEntry && renderSelect}
      {textEntryAllowed && renderTextInputToggle}
      {displayAddToDisplayPane && <AddToDisplayPane itemId={selectedItemId as string} tooltipPlacement='left' />}
    </div>
  );
};

export const SelectItem = React.memo(SelectItemUnwrapped, (prev, next) => !(
  prev.selectedItemId !== next.selectedItemId ||
  next.textPlaceholder !== prev.textPlaceholder ||
  prev.className !== next.className
));
export default SelectItem;
export const sqSelectItem = angularComponent(selectItemBindings, SelectItem);
