import React from 'react';
import _ from 'lodash';
import Select, { components } from 'react-select';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

interface CheckboxSelectProps {
  selectOptions: { text: string, value: any }[];
  onChange: (option: { value: any }, property?: string) => void;
  placeholder?: string;
  value?: any;
  testId?: string;
  insideModal?: boolean;
  isMultipleSelect?: boolean; // radio buttons are used by default, checkboxes will be used if this is true
  selectClasses?: string;
  wrapperClasses?: string;
  label: string;
}

/**
 * Multi-select dropdown with item checkboxes
 */
export const CheckboxSelect: React.FunctionComponent<CheckboxSelectProps> = (props) => {
  const {
    testId,
    value,
    label,
    selectOptions,
    placeholder,
    onChange,
    insideModal,
    isMultipleSelect,
    selectClasses,
    wrapperClasses
  } = props;

  const { t } = useTranslation();

  const menuPosition = insideModal ? 'absolute' : 'fixed';
  const portalStyle = { menuPortal: base => ({ ...base, zIndex: 9999 }) };

  const makeLabel = (option) => {
    const isSelectedValue = isMultipleSelect ? _.includes(value, option.value) : value === option.value;

    const icon = (isSelected) => {
      if (isMultipleSelect) {
        return isSelected ? 'fa-check-square' : 'fa-square-o';
      } else {
        return isSelected ? 'fa-check-circle' : 'fa-circle-thin';
      }
    };

    return (
      <span className="flexColumnContainer flexAlignCenter">
        <span className="flexColumnContainer flexAlignCenter">
          <Icon large={true}
            icon={icon(isSelectedValue)}
            type={isMultipleSelect && isSelectedValue ? 'theme' : 'text'} />
          <span className="ml10">{t(option.text)}</span>
        </span>
      </span>
    );
  };

  const options = _.map(selectOptions, option => ({ ...option, label: makeLabel(option) }));
  const selectedOptions = _.map(value, value => t(_.find(options, { value })?.text));

  // This enables a different label to display for the selected item once the menu is closed
  const SingleValue = props => (
    <components.SingleValue {...props}>
      <span>
        {(value.length === 0 || _.isUndefined(value))
          ? <span>{t(label)}</span>
          : <span>{_.join(selectedOptions, ', ')}</span>}
      </span>
    </components.SingleValue>
  );

  return (
    <div data-testid={testId} className={wrapperClasses}>
      <Select
        className={selectClasses}
        classNamePrefix={`${selectClasses} react-select`}
        placeholder={_.isEmpty(placeholder) ? '' : t(placeholder)}
        value={_.find(options, { value })}
        menuPosition={menuPosition}
        styles={insideModal && portalStyle}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        options={options}
        isSearchable={false}
        closeMenuOnSelect={!isMultipleSelect}
        components={{ SingleValue }}
        onChange={selectedOption => onChange(selectedOption)} />
    </div>
  );
};
