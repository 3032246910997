import React from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { TranslationWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';

const treeMapHelpBindings = bindingsDefinition({
  type: prop<string>()
});

export const TreeMapHelp: SeeqComponent<typeof treeMapHelpBindings> = ({ type }) => {
  const { t } = useTranslation();

  const capsuleSetIcon = '<i class=\'fc fc-capsule-set pr3\'></i>';
  const chartIcon = '<i class=\'fa fa-area-chart pr3\'></i>';
  const dataIcon = '<i class=\'fa fa-database pr3\'></i>';
  const toolsIcon = '<i class=\'fa fa-wrench pr3\'></i>';

  const renderAddCondition = (
    <>
      <p>
        <TranslationWithHTML translationKey="TREEMAP.USES_CAPSULES"
          translationParams={{ CAPSULE_SET_ICON: capsuleSetIcon }} />
      </p>
      <p><strong>
        <TranslationWithHTML translationKey="TREEMAP.ADD_CONDITION"
          translationParams={{ CHART_ICON: chartIcon, DATA_ICON: dataIcon, TOOLS_ICON: toolsIcon }} />
      </strong></p>
    </>
  );

  const renderMustUseSameAsset =
    (
      <p>
        <TranslationWithHTML translationKey="TREEMAP.MUST_USE_SAME_ASSET"
          translationParams={{ CAPSULE_SET_ICON: capsuleSetIcon, CHART_ICON: chartIcon }} />
      </p>
    );

  const renderSelectPriorities = (
    <>
      <p><strong>
        <TranslationWithHTML translationKey="TREEMAP.SELECT_PRIORITIES"
          translationParams={{ CAPSULE_SET_ICON: capsuleSetIcon }} />
      </strong></p>
    </>
  );

  const displayText = () => {
    switch (type) {
      case 'addCondition':
        return renderAddCondition;
      case 'mustUseSameAsset':
        return renderMustUseSameAsset;
      case 'selectPriorities':
        return renderSelectPriorities;
      default:
        return;
    }
  };

  return (<> {displayText()} </>);
};

export default TreeMapHelp;
export const sqTreeMapHelp = angularComponent(treeMapHelpBindings, TreeMapHelp);
