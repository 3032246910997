import { TreesApi } from 'sdk/api/TreesApi';
import _ from 'lodash';
import { CalculationRunnerService } from '@/services/calculationRunner.service';
import { AssetGroupStore } from '@/hybrid/assetGroupEditor/assetGroup.store';
import { AssetsApi, ItemsApi } from '@/sdk';
import { NotificationsService } from '@/services/notifications.service';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { SearchActions } from '@/search/search.actions';
import { SEARCH_PANES } from '@/search/search.module';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { UtilitiesService } from '@/services/utilities.service';
import { API_TYPES, SEARCH_TYPES } from '@/main/app.constants';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.module';
import { WorkbookActions } from '@/workbook/workbook.actions';
import {
  getColumnsHelper,
  getNameWithPath,
  getPath,
  mapCalculationParamsToAssetChild
} from '@/hybrid/assetGroupEditor/assetGroup.utilities';
import { TrackService } from '@/track/track.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { TrendActions } from '@/trendData/trend.actions';

export type AssetGroupActions = ReturnType<typeof sqAssetGroupActions>;

export const CREATED_BY_SEEQ_WORKBENCH = 'Seeq Workbench';
export const PATH_SEPARATOR = '/';
export type AssetChild = {
  name: string,
  id: string, // id of the item created. The formula is not loaded by default so we're adding itemId if necessary
  itemId: string, // id of the item the formula points to
  // if items are assigned via the plus icon then this will be set to true and stored as an
  // item property
  manuallyAdded?: string,
  originalMapping?: string,
  path: string,
  formula?: string
  parameters?: { name: string, item: { name?: string, id: string, type?: string } }[]
};

export type AssetGroupAsset = {
  name: string,
  assetPath: string;
  children?: [AssetChild];
  id?: string; // not the id of the original asset used to create the row but the id of the asset within this Group!
};

export function sqAssetGroupActions(
  flux: ng.IFluxService,
  $state: ng.ui.IStateService,
  $translate: ng.translate.ITranslateService,
  sqTreesApi: TreesApi,
  sqAssetsApi: AssetsApi,
  sqItemsApi: ItemsApi,
  sqTrack: TrackService,
  sqAssetGroupStore: AssetGroupStore,
  sqCalculationRunner: CalculationRunnerService,
  sqNotifications: NotificationsService,
  sqWorkbenchStore: WorkbenchStore,
  sqSearchActions: SearchActions,
  sqWorksheetActions: WorksheetActions,
  sqUtilities: UtilitiesService,
  sqWorkbookActions: WorkbookActions,
  sqTrendActions: TrendActions
) {
  const service = {
    getColumns,
    getColumn,
    addAssetOrItem,
    setName,
    setDescription,
    saveOrUpdateAssetGroup,
    addNewAsset,
    addItemToAsset,
    addNewColumn,
    updateColumnName,
    updateAssetName,
    removeChildFromAsset,
    editAssetGroup,
    deleteAssetGroup,
    reset,
    removeAttribute,
    removeAsset,
    restoreItem,
    setIsLoading,
    setHasUnsavedChanges,
    getAssetGroupSummary,
    dispatchAndSetChanged,
    addCalculationColumn,
    addCalculationToAsset,
    itemTypesAreEqual,
    getColumnType,
    getDefaultAttributeName,
    getAssetForAssetGroup
  };

  return service;

  function setName(name) {
    service.dispatchAndSetChanged('ASSET_GROUP_SET_NAME', { name });
    validateName(name);
  }

  function setDescription(description) {
    service.dispatchAndSetChanged('ASSET_GROUP_SET_DESCRIPTION', { description });
  }

  function getColumns() {
    return getColumnsHelper(sqAssetGroupStore.assets);
  }

  function getColumn(name) {
    return _.find(service.getColumns(), ['name', name]);
  }

  function reset() {
    flux.dispatch('ASSET_GROUP_RESET_STORE');
  }

  function setIsLoading(isLoading) {
    flux.dispatch('ASSET_GROUP_SET_IS_LOADING', { isLoading });
  }

  function setHasUnsavedChanges(hasUnsavedChanges) {
    flux.dispatch('ASSET_GROUP_SET_HAS_UNSAVED_CHANGES', { hasUnsavedChanges });
  }

  function editAssetGroup(root) {
    sqWorksheetActions.setView(WORKSHEET_VIEW.ASSET_GROUP_EDITOR);
    service.reset();
    service.setIsLoading(true);

    // fetch the group based on the root:
    return sqTreesApi.getTree({ id: root.id, offset: 0, limit: 5000, includeDescendants: true })
      .then((response) => {
        /**
         * Asset Group are returned in the following format:
         *
         * -- Asset Group Name (as item.name)
         *    -- children: [
         *       -- ROW 1 Name (as child[0].name
         *            -- children: [
         *                -- column name as child[0].name
         *                -- column name as child[1].name
         *                ...
         *                -- column name as child[X].name
         *            ]
         *       -- ROW 2 Name (as child[1].name
         *            -- children: [
         *                 -- column name as child[0].name
         *                -- column name as child[1].name
         *                ...
         *                -- column name as child[X].name
         *            ]
         *      -- ....
         *      -- ROW X Name (as child[X-1].name
         *            -- children: [
         *                 -- column name as child[0].name
         *                -- column name as child[1].name
         *                ...
         *                -- column name as child[X].name
         *            ]
         *    ]
         *
         * The root reflects the Asset Group Root - the immediate children are the "rows" and the columns are created
         * based on the children of the "rows".
         */
        const decorateItem = (item) => {
          const decorated = {
            id: item.id,
            name: item.name,
            manuallyAdded: (_.find(item.properties, { name: 'manuallyAdded' }) as any)?.value,
            originalMapping: (_.find(item.properties, { name: 'originalMapping' }) as any)?.value,
            assetPath: (_.find(item.properties, { name: 'assetPath' }) as any)?.value,
            formula: (_.find(item.properties, { name: 'Formula' }) as any)?.value,
            columnType: (_.find(item.properties, { name: 'columnType' }) as any)?.value,
            type: item.type,
            params: item.parameters
          };
          if (decorated.columnType === 'Calculation') {
            const parametersToColumns = JSON.parse(
              (_.find(item.properties, { name: 'parameterToColumn' }) as any)?.value);
            const parameters = _.map(parametersToColumns,
              ({ parameterName, columnName, type, id }) => ({
                name: parameterName,
                item: { id: id ? id : columnName, name: columnName, type }
              }));
            _.assign(decorated, { parameters });
          }

          return decorated;
        };

        const { item, children, description } = response.data;
        // the "root" node contains the asset group name
        flux.dispatch('ASSET_GROUP_SET_ROOT', { id: item.id, name: item.name, description });
        // the immediate children are the "assets" that display as rows
        _.forEach(children, (asset) => {
          const children = _.map(asset.children, decorateItem);
          flux.dispatch('ASSET_GROUP_ADD_STORED_ASSET', { asset: { ...decorateItem(asset), children } });
        });
      })
      .finally(() => {
        service.setIsLoading(false);
        service.setHasUnsavedChanges(false);
      });
  }

  function removeAttribute(name) {
    service.dispatchAndSetChanged('ASSET_GROUP_REMOVE_ATTRIBUTE', { name });
  }

  function removeAsset(name) {
    service.dispatchAndSetChanged('ASSET_GROUP_REMOVE_ASSET', { name });
  }

  function deleteAssetGroup() {
    return sqTreesApi.removeNodeFromTree({ id: sqAssetGroupStore.id })
      .then(() => {
        sqNotifications.successTranslate('ASSET_GROUP_EDITOR.GROUP_DELETED');
        reloadSidePanel();
      })
      .catch(() => sqNotifications.errorTranslate('ASSET_GROUP_EDITOR.ERROR_DELETING_ASSET_GROUP'));
  }

  function updateColumnName(originalName, newName) {
    const columns = service.getColumns();
    const isUnique = !_.some(columns, { name: newName });
    if (isUnique) {
      service.dispatchAndSetChanged('ASSET_GROUP_UPDATE_COLUMN_NAME', { originalName, newName });
      // we also need to update all the calculation columns to ensure we can build the correct formula!
      _.forEach(sqAssetGroupStore.assets, (asset) => {
        _.forEach(asset.children, (child) => {
          if (child.columnType === 'Calculation') {
            const parameterIndex = _.findIndex(child.parameters, (param: any) => param.item.name === originalName);
            if (parameterIndex > -1) {
              const paramToUpdate = _.assign({}, child.parameters[parameterIndex], {
                item: {
                  name: newName, id: newName
                }
              });
              const tempParams = [...child.parameters];
              _.pullAt(tempParams, parameterIndex);
              const updatedParameters = [...tempParams, paramToUpdate];

              addCalculationToAsset(
                { asset, columnName: child.name, formula: child.formula, parameters: updatedParameters });
            }
          }
        });
      });
    } else {
      sqNotifications.errorTranslate('ASSET_GROUP_EDITOR.MUST_BE_UNIQUE');
    }
  }

  function updateAssetName(original, newName) {
    const isUnique = !_.some(sqAssetGroupStore.assets, { name: newName });
    if (!isUnique) {
      sqNotifications.errorTranslate('ASSET_GROUP_EDITOR.MUST_BE_UNIQUE');
    } else if (newName.includes('/')) {
      sqNotifications.errorTranslate('ASSET_GROUP_EDITOR.CANNOT_CONTAIN_SLASH');
    } else {
      service.dispatchAndSetChanged('ASSET_GROUP_UPDATE_ASSET_NAME', { original, newName });
    }
  }

  function getDefaultAssetName() {
    const existingNames = _.map(sqAssetGroupStore.assets, 'name');
    return sqUtilities.getNextDefaultName(existingNames, 'ASSET_GROUP_EDITOR.ASSET');
  }

  function getDefaultAttributeName() {
    const existingNames = _.map(getColumns(), 'name');
    return sqUtilities.getNextDefaultName(existingNames, 'ASSET_GROUP_EDITOR.COLUMN');
  }

  function addNewAsset() {
    // if there is no data yet we'll also add an attribute:
    const noAsset = _.isEmpty(sqAssetGroupStore.assets);
    service.dispatchAndSetChanged('ASSET_GROUP_ADD_ASSET',
      {
        asset: {
          name: getDefaultAssetName(),
          manuallyAdded: sqWorkbenchStore.currentUser.id,
          children: []
        }
      });

    if (noAsset) {
      addNewColumn();
    }
  }

  function addNewColumn() {
    // if there is no data yet we'll also add an attribute:
    const noAsset = _.isEmpty(sqAssetGroupStore.assets);
    if (noAsset) {
      addNewAsset();
    } else {
      service.dispatchAndSetChanged('ASSET_GROUP_ADD_ATTRIBUTE',
        { name: getDefaultAttributeName(), manuallyAdded: sqWorkbenchStore.currentUser.id });
    }
  }

  function addCalculationToAsset({ asset, columnName, formula, parameters }) {
    service.dispatchAndSetChanged('ASSET_GROUP_ADD_CALCULATION_CHILD_TO_ASSET',
      { asset, formula, params: parameters, name: columnName, manuallyAdded: sqWorkbenchStore.currentUser.id });
  }

  function ensureAssetExists() {
    const noAsset = _.isEmpty(sqAssetGroupStore.assets);
    if (noAsset) {
      service.dispatchAndSetChanged('ASSET_GROUP_ADD_ASSET',
        {
          asset: {
            name: getDefaultAssetName(),
            manuallyAdded: sqWorkbenchStore.currentUser.id,
            children: []
          }
        });
    }
  }

  function addCalculationColumn(formula, parameters, calculationColumnName) {
    const newColumnName = calculationColumnName ? calculationColumnName : getDefaultAttributeName();
    ensureAssetExists();
    service.dispatchAndSetChanged('ASSET_GROUP_ADD_ATTRIBUTE',
      {
        name: newColumnName,
        columnType: 'Calculation',
        formula,
        parameters,
        manuallyAdded: sqWorkbenchStore.currentUser.id
      });
  }

  function removeChildFromAsset(child, asset) {
    service.dispatchAndSetChanged('ASSET_GROUP_REMOVE_CHILD_FROM_ASSET', { asset, child });
    sqNotifications.successTranslate('ASSET_GROUP_EDITOR.ITEM_REMOVED');
  }

  // assets must have a name and a path. sometimes we pre-fix the name with part of the path
  function getAssetForAssetGroup(asset): AssetGroupAsset {
    // we care about the asset name and the parents:
    let assetName = asset.name;
    const assetPath = getPath(asset);
    if (assetName.includes('/')) {
      assetName = asset.name.replace('/', '');
      sqNotifications.infoTranslate('ASSET_GROUP_EDITOR.FORWARD_SLASH_REMOVED');
    }
    return ({
      name: assetName,
      assetPath
    });
  }

  /**
   * This function is called when an asset is added to the asset group via the data panel.
   */
  function addAssetOrItem(assetOrItem, addMatchingItemsOnly = false, addItemsOfItemsChildren = false) {
    if (sqUtilities.isAsset(assetOrItem) || addItemsOfItemsChildren) {
      return addAsset(assetOrItem, addMatchingItemsOnly);
    } else {
      // if the user clicked on a signal or a condition we need to determine the parent, add the parent (if needed) and
      // that one child that the user selected.
      let parentPromise;
      const parent: any = _.last(_.filter(assetOrItem?.ancestors, { type: API_TYPES.ASSET }));
      // fetch parent so that we have the ancestors needed to determine a unique path:
      if (_.isEmpty(parent)) {
        parentPromise = Promise.resolve({ data: { name: getDefaultAssetName() } });
      } else {
        parentPromise = sqItemsApi.getFormulaDependencies({ id: parent.id });
      }

      return parentPromise
        .then((response) => {
          const parent = response.data;
          let childName = assetOrItem.name;
          if (assetOrItem.type !== API_TYPES.ASSET) {
            // for items of items we want the name to include all the non-asset parents:
            const itemAncestors = _.filter(assetOrItem.ancestors, ancestor => ancestor.type !== API_TYPES.ASSET);
            const path = _.join(_.map(itemAncestors, 'name'), PATH_SEPARATOR);
            childName = getNameWithPath(childName, path);
          }

          const column = service.getColumn(childName);
          if (!service.itemTypesAreEqual(assetOrItem.type, column)) {
            const type = service.getColumnType(column.type);
            sqNotifications.errorTranslate('ASSET_GROUP_EDITOR.ERROR_INCORRECT_ITEM_TYPE', { type });
          } else {
            // if the group already contains the asset then add the child only
            if (_.includes(_.map(sqAssetGroupStore.assets, 'name'), parent.name)) {
              service.dispatchAndSetChanged('ASSET_GROUP_ADD_CHILD_TO_ASSET', {
                asset: getAssetForAssetGroup(parent),
                childId: assetOrItem.id,
                name: childName,
                type: assetOrItem.type
              });
            } else {
              const asset = getAssetForAssetGroup(parent);
              service.dispatchAndSetChanged('ASSET_GROUP_ADD_ASSET',
                {
                  asset: {
                    ...asset
                  }
                });
              service.dispatchAndSetChanged('ASSET_GROUP_ADD_CHILD_TO_ASSET', {
                asset,
                childId: assetOrItem.id,
                name: childName,
                type: assetOrItem.type
              });
            }
          }
        });
    }
  }

  function addAsset(rowAsset: AssetGroupAsset, addMatchingItemsOnly) {

    const getChildren = (item, path) => {
      if (item.hasChildren) {
        return _.chain(item.children)
          .flatMap(child => getChildren(child, `${path}${_.isEmpty(path) ? '' : PATH_SEPARATOR}${item.name}`))
          // items of items are a special use case as they are an item in and itself that needs to be added in
          // addition to their children.
          .concat(item.type !== API_TYPES.ASSET
            ? { name: getNameWithPath(item.name, path) }
            : null)
          .compact()
          .value();
      } else {
        return {
          path,
          name: getNameWithPath(item.name, path),
          originalMapping: item.id,
          itemId: item.id,
          type: item.type
        };
      }
    };

    return sqTreesApi.getTree(
      { id: rowAsset.id, offset: 0, limit: 5000, includeDescendants: true, scopedTo: getWorkbookId() })
      .then((response) => {
        let children = _.flatMap(response.data.children, firstChild => getChildren(firstChild, ''));
        if (addMatchingItemsOnly) {
          const itemsInAssetGroup = _.map(service.getColumns(), 'name');
          children = _.filter(children, child => _.includes(itemsInAssetGroup, child.name));
        }

        let itemsDidNotMatch = false;
        children = _.reduce(children, (results, child) => {
          const column = service.getColumn(child.name);
          if (!service.itemTypesAreEqual(child.type, column)) {
            itemsDidNotMatch = true;
          } else {
            results.push(child);
          }
          return results;
        }, []);

        if (itemsDidNotMatch) {
          sqNotifications.errorTranslate('ASSET_GROUP_EDITOR.ERROR_COLUMN_TYPE');
        }
        service.dispatchAndSetChanged('ASSET_GROUP_ADD_ASSET',
          { asset: { ...getAssetForAssetGroup(rowAsset), children } });
      });
  }

  function getAssetGroupSummary() {
    const assets = sqAssetGroupStore.assets;
    const columnNames = _.map(service.getColumns(), 'name');
    const manuallyAddedAssets = _.reduce(assets, (count, asset) => asset.manuallyAdded ? ++count : count, 0);

    const assetAssigned = child => child.id || child.itemId;
    const manuallyAssigned = child => assetAssigned(child) && child.manuallyAdded;

    const summaryStatistics = {
      unassigned: {
        isApplicable: child => !assetAssigned(child),
        count: 0
      },
      manuallyAdded: {
        isApplicable: child => manuallyAssigned(child) && _.some([child.id, child.itemId], child.originalMapping),
        count: 0
      },
      manualOverride: {
        isApplicable: child => manuallyAssigned(child) && !_.some([child.id, child.itemId], child.originalMapping),
        count: 0
      },
      assetAssigned: {
        isApplicable: assetAssigned,
        count: 0
      }
    };

    _.forEach(assets, (asset) => {
      // count the + icons for manually added asset rows
      if (asset.children.length < columnNames.length) {
        summaryStatistics.unassigned.count += columnNames.length - asset.children.length;
      }

      _.forEach(asset.children, (child) => {
        _.forEach(summaryStatistics, stat => stat.count = stat.isApplicable(child) ? ++stat.count : stat.count);
      });
    });

    return _.chain(summaryStatistics).mapValues('count').assign({ manuallyAddedAssets }).value();
  }

  function validateName(name: string) {
    if (_.isEmpty(_.trim(name))) {
      flux.dispatch('ASSET_GROUP_SET_NAME_ERROR', { error: true });
      return false;
    } else {
      flux.dispatch('ASSET_GROUP_SET_NAME_ERROR', { error: false });
      return true;
    }
  }

  function saveOrUpdateAssetGroup() {
    flux.dispatch('ASSET_GROUP_SET_ERRORS', []);

    if (validateName(sqAssetGroupStore.name)) {
      const childAssets: any = _.chain(sqAssetGroupStore.assets)
        .map((asset) => {
          const children = _.chain(asset.children)
            .map((child) => {
              // do not attempt to persist empty "slots"
              if (!child.id && !child.itemId && child.columnType !== 'Calculation') {
                return null;
              }
              const { mappings } = mapCalculationParamsToAssetChild(asset, child.parameters);
              if (!_.isEmpty(mappings)) {
                return ({
                  id: child.id === '' ? null : child.id,
                  name: child.name,
                  scopedTo: getWorkbookId(),
                  formula: child.formula,
                  parameters: mappings,
                  additionalProperties: getAdditionalProperties(child)
                });
              }
            })
            .compact()
            .value();

          if (_.isEmpty(children)) {
            return null;
          }

          return ({
            id: asset.id === '' ? null : asset.id,
            name: asset.name,
            scopedTo: getWorkbookId(),
            additionalProperties: getAdditionalProperties(asset),
            children
          });
        })
        .compact()
        .value();

      const rootAsset = {
        id: sqAssetGroupStore.id === '' ? null : sqAssetGroupStore.id,
        name: sqAssetGroupStore.name,
        description: sqAssetGroupStore.description,
        additionalProperties: [
          { name: SeeqNames.Properties.TreeType, value: CREATED_BY_SEEQ_WORKBENCH },
          { name: SeeqNames.Properties.CreatedBy, value: sqWorkbenchStore.currentUser.id }
        ],
        scopedTo: getWorkbookId()
      };

      if (!_.isEmpty(childAssets)) {
        const createOrUpdatePromise = sqAssetGroupStore.id
          ? sqTreesApi.updateTree({
            rootAsset,
            childAssets
          })
          : sqTreesApi.createTree({
            rootAsset,
            childAssets
          });

        return createOrUpdatePromise
          .then(() => {
            sqTrendActions.fetchPropsForAllItems();
            reloadSidePanel();
          }).catch((error) => {

            sqNotifications.errorTranslate(
              error?.errorCategory === 'SYNTAX' ? 'ASSET_GROUP_EDITOR.ERROR_SAVING_ASSET_GROUP' :
                'ASSET_GROUP_EDITOR.DELETED_COLUMNS');

            sqTrack.doTrack('Asset Group', 'Error saving Asset Group');
            return Promise.reject() as any;
          });
      } else {
        sqNotifications.errorTranslate('ASSET_GROUP_EDITOR.EMPTY_NOT_ALLOWED');
        return Promise.reject() as any;
      }
    } else {
      sqNotifications.errorTranslate('ASSET_GROUP_EDITOR.NO_NAME');
      return Promise.reject() as any;
    }
  }

  function getAdditionalProperties(item) {
    const propertiesArray = [];
    if (item.manuallyAdded) {
      propertiesArray.push({ name: 'manuallyAdded', value: item.manuallyAdded });
    }
    if (item.originalMapping) {
      propertiesArray.push({ name: 'originalMapping', value: item.originalMapping });
    }
    if (item.assetPath) {
      propertiesArray.push({ name: 'assetPath', value: item.assetPath });
    }
    if (item.columnType === 'Calculation') {
      propertiesArray.push({ name: 'columnType', value: 'Calculation' });

      // save the column that is mapped so we can re-hydrate properly:
      const parametersToColumn = _.map(item.parameters,
        ({ name, item }) => {
          // if it's a static item make sure we preserve the item id so we can properly re-hydrate
          if (item?.name !== item?.id) {
            return { parameterName: name, columnName: item.name, id: item.id, type: item.type };
          } else {
            return { parameterName: name, columnName: item?.name };
          }
        }
      );

      propertiesArray.push({ name: 'parameterToColumn', value: JSON.stringify(parametersToColumn) });
    }

    propertiesArray.push({ name: SeeqNames.Properties.TreeType, value: CREATED_BY_SEEQ_WORKBENCH });

    return propertiesArray;
  }

  function getWorkbookId() {
    return $state.params?.workbookId;
  }

  function reloadSidePanel() {
    flux.dispatch('ASSET_GROUP_RESET_STORE');
    // reload data tab and ensure data tab root is selected
    sqWorksheetActions.tabsetChangeTab('sidebar', 'search');
    sqWorkbookActions.fetchAssetGroups(getWorkbookId())
      .then(() => sqSearchActions.clear(SEARCH_PANES.MAIN, SEARCH_TYPES));
  }

  function restoreItem({ asset, columnName, itemId }) {
    service.dispatchAndSetChanged('ASSET_GROUP_ADD_CHILD_TO_ASSET', { asset, childId: itemId, name: columnName });
  }

  function addItemToAsset({ asset, columnName, itemId, type }) {
    // add a new child with the columnName, pointing to the fillerID
    const column = service.getColumn(columnName);
    if (!service.itemTypesAreEqual(type, column)) {
      const type = service.getColumnType(column.type);
      sqNotifications.errorTranslate('ASSET_GROUP_EDITOR.ERROR_INCORRECT_ITEM_TYPE', { type });
    } else {
      const cellId = _.find(asset.children, child => child.name === columnName)?.id;
      service.dispatchAndSetChanged('ASSET_GROUP_ADD_CHILD_TO_ASSET',
        { asset, childId: itemId, id: cellId, name: columnName, manuallyAdded: sqWorkbenchStore.currentUser.id, type });
    }
  }

  function dispatchAndSetChanged(handler, payload) {
    flux.dispatch(handler, payload);
    service.setHasUnsavedChanges(true);
  }

  function itemTypesAreEqual(itemType, column) {
    if (_.isUndefined(column?.type)) {
      return true;
    }

    switch (column?.type) {
      case SeeqNames.Types.CalculatedSignal:
      case SeeqNames.Types.StoredSignal:
        return _.isEqual(itemType, SeeqNames.Types.CalculatedSignal) || _.isEqual(itemType,
          SeeqNames.Types.StoredSignal);
      case SeeqNames.Types.CalculatedCondition:
      case SeeqNames.Types.StoredCondition:
        return _.isEqual(itemType, SeeqNames.Types.CalculatedCondition) || _.isEqual(itemType,
          SeeqNames.Types.StoredCondition);
      default:
        return _.isEqual(column?.type, itemType);
    }
  }

  function getColumnType(type) {
    if (_.isEqual(type, SeeqNames.Types.CalculatedSignal) || _.isEqual(type, SeeqNames.Types.StoredSignal)) {
      return 'Signal';
    } else if (_.isEqual(type,
      SeeqNames.Types.CalculatedCondition) || _.isEqual(type, SeeqNames.Types.StoredCondition)) {
      return 'Condition';
    } else {
      return type;
    }
  }
}
