export enum MESSAGES {
  COMMAND_OPEN = 'cmdSocketOpen',
  COMMAND_CLOSE = 'cmdSocketClose',
  COMMAND_TRANSMIT = 'cmdSocketTransmit',
  COMMAND_LOG = 'cmdLog',
  ON_OPEN_COMPLETE = 'onSocketOpen',
  ON_CLOSE_COMPLETE = 'onSocketClose',
  ON_ERROR = 'onSocketError',
  ON_WEBSOCKET_DATA = 'onSocketData'
}
