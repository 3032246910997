import _ from 'lodash';
import angular from 'angular';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { UtilitiesService } from '@/services/utilities.service';
import { CapsuleTimeColorMode, TrendStore } from '@/trendData/trend.store';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { ItemDecoratorService } from '@/trendViewer/itemDecorator.service';
import {
  ITEM_TYPES,
  LABEL_LOCATIONS,
  PREVIEW_HIGHLIGHT_COLOR,
  PREVIEW_ID,
  TREND_BUFFER_FACTOR_LABEL,
  TREND_STORES,
  TREND_TOP_Y_AXIS_ID,
  TREND_VIEWS,
  Y_AXIS_TYPES
} from '@/trendData/trendData.module';
import { DEFAULT_AXIS_LABEL_COLOR, PLOT_BAND, Z_INDEX } from '@/trendViewer/trendViewer.module';
import { ASSET_PATH_SEPARATOR } from '@/main/app.constants';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { AUTO_FORMAT, NumberHelperService } from '@/core/numberHelper.service';

export const Y_AXIS_LABEL_CHARACTER_WIDTH = 7;

angular.module('Sq.TrendViewer')
  .factory('sqLabels', sqLabels);

export type LabelsService = ReturnType<typeof sqLabels>;

interface LabelDefinition {
  html: string;
  text: string;
  isListItem?: boolean;
}

function sqLabels(
  $sanitize: ng.sanitize.ISanitizeService,
  $translate: ng.translate.ITranslateService,
  sqTrendCapsuleSetStore: TrendCapsuleSetStore,
  sqUtilities: UtilitiesService,
  sqTrendStore: TrendStore,
  sqTrendDataHelper: TrendDataHelperService,
  sqItemDecorator: ItemDecoratorService,
  sqNumberHelper: NumberHelperService
) {
  // Array to store tickAttributes for lookup
  const tickAttributesConfig = [];
  // Max number of tickAttributes that will be stored
  const maxStoredTickAttributes = 50;

  const service = {
    getCapsuleLaneLabelDisplayText,
    getLaneDisplayText,
    getAxisDisplayText,
    getNumericTickPositions,
    fetchTickAttributes,
    formatYAxisTick,
    createPlotBandAxisDefinition,
    getCapsuleAxisId,
    createYAxisDefinition,
    getCapsuleAxisDefinition,
    getLabelWidth,
    formatStringYAxisLabel,
    getAssetNames,
    calculateTickAttributesForChart,
    getGridlineWidth
  };

  /**
   * Returns the html-formatted label for a capsule lane label or an empty string if no label is enabled.
   *
   * @param capsuleSetId - the id of the capsule set
   * @param dataLabelTitles - the titles of any data labels that are shown
   * @returns HTML for the capsule label.
   */
  function getCapsuleLaneLabelDisplayText(capsuleSetId: string, dataLabelTitles: string[]): string {
    const condition = _.find(sqTrendCapsuleSetStore.items, { id: capsuleSetId });
    const conditionName = _.get(condition, 'name');

    if (sqTrendStore.showCapsuleLaneLabels && !_.isNil(conditionName)) {
      return [
        `<span style="color: ${_.get(condition, 'color', DEFAULT_AXIS_LABEL_COLOR)}" class="text-with-shadow pr5">`,
        $sanitize(conditionName),
        dataLabelTitles.length ? ` (${$sanitize(dataLabelTitles.join(', '))})` : '',
        '</span>'
      ].join('');
    } else {
      return '';
    }
  }

  /**
   * Helper function that returns nicely formatted asset names for the specified signal.
   *
   * @param {Object} signal - The signal to return assets for
   * @param {Number} assetPathLevels - The number of parent assets to return
   * @returns {string} A nicely formatted string of parent assets for the signal
   */
  function getAssetNames(signal, assetPathLevels) {
    return _.chain(signal.assets)
      .map(function(asset) {
        const pathElements = _.map(_.split(asset.formattedName, ASSET_PATH_SEPARATOR), $sanitize);
        return _.takeRight(pathElements, assetPathLevels).join(ASSET_PATH_SEPARATOR);
      })
      .compact()
      .join(', ')
      .value();
  }

  /**
   * Helper function that returns an HTML string that is labels of all the items in the lane and the custom label.
   * A wrapper div with a hard-coded width that is the same as the chart width allows the labels to be wrapped if
   * they can't fit in a single line. Each individual label is expected to be wrapped in a span with a
   * text-with-shadow class so that it can further styled via CSS.
   *
   * @param items - Array of items displayed by the chart
   * @param lane - the current lane
   * @param width - the width of only the chart lane, excluding the width of the axis
   * @returns The label text for each item in the lane, wrapped in a container div.
   */
  function getLaneDisplayText(items: LabelDefinition[], lane: number, width: number): string {
    const labelsHtml = _.chain([
        getLaneCustomLabel(lane),
        _.map(getLaneSeriesLabels(items, lane), 'html').join(', '),
        getLaneConfigLabel(lane)
      ])
      .reject(_.isEmpty)
      .join(' ')
      .value();
    return `<div class="text-right pl5 pr5" style="width: ${width}px;">${labelsHtml}</div>`;
  }

  /**
   * Helper function that returns the axis label display String. Based on the settings chosen by the user. Including
   * any/all of { signal names, units, custom labels } or an empty string.
   *
   * @param {Object[]} items - Array of items displayed by the chart
   * @param {String} alignment - the current axis
   * @param {Object[]} series - Array of Series objects
   * @param {Number} height - the height of the axis as determined by axis.height
   * @returns {String} display for the Axis
   */
  function getAxisDisplayText(items, alignment, series, height) {
    const labels = []
      .concat(getAxisCustomLabel(alignment))
      .concat(getAxisSeriesLabels(items, series));
    const charLength = _.chain(labels)
      .flatMap(label => [label.text, label.unitOfMeasure ? label.unitOfMeasure.value : ''])
      .map(text => text.length)
      .sum()
      .value();
    return _.chain(labels)
      .reduce(function(htmlLabels, label, index) {
        let numChars;
        const allowedChar = height / Y_AXIS_LABEL_CHARACTER_WIDTH;
        const uomChars = label.unitOfMeasure ? label.unitOfMeasure.value.length : 0;
        // Most units are less than 5 chars, so we allow short labels without truncation
        if (charLength > allowedChar && label.text.length + uomChars > 4) {
          // Divide allowed chars by num series, then divide by two to end up with the number of chars we can
          // display at either end Subtract one so that we leave a buffer
          numChars = Math.max(2,
            Math.floor(height / Y_AXIS_LABEL_CHARACTER_WIDTH / labels.length / 2 - 1));
          if (label.text.length + uomChars > numChars * 2) {
            if (label.unitOfMeasure && label.text) {
              // give units one more character than text
              const newText = label.text.slice(0, numChars - 1);
              const numSliced = label.text.length - newText.length;
              const extraToSlice = numSliced < numChars ? numChars + (numChars - numSliced) : 0;
              label.text = `${newText}..`;
              label.unitOfMeasure.value = label.unitOfMeasure.value.slice(-(numChars + extraToSlice + 1));
            } else if (label.unitOfMeasure) {
              const uomVal = label.unitOfMeasure.value;
              label.unitOfMeasure.value = `${uomVal.slice(0, numChars - 1)}..${uomVal.slice(-(numChars + 1))}`;
            } else {
              label.text = `${label.text.slice(0, numChars - 1)}..${label.text.slice(-(numChars + 1))}`;
            }
          }
        }

        const notLast = index < labels.length - 1;
        if (label.isListItem && notLast && labels[index + 1].isListItem) {
          if (label.unitOfMeasure) {
            label.unitOfMeasure.value = label.unitOfMeasure.value + ',';
          } else {
            label.text = label.text + ',';
          }
        }

        // It would be nice to just call `join(' ')` later on, but highcharts does not display spaces on their own -
        // they have to be alongside other text (inside the span, in this case).
        if (notLast) {
          if (label.unitOfMeasure) {
            label.unitOfMeasure.value = label.unitOfMeasure.value + ' ';
          } else {
            label.text = label.text + ' ';
          }
        }
        return [...htmlLabels, displayWithUnits(label).html];
      }, [])
      .compact()
      .join('')
      .value();
  }

  /**
   * Returns a list of one custom label for a lane if the lane is configured to show a custom label.
   *
   * @param lane - the lane for which to return the custom label
   * @returns The html for the custom label, or an empty string
   */
  function getLaneCustomLabel(lane: number): string {
    if (!_.isNull(lane) && sqTrendStore.labelDisplayConfiguration.custom === LABEL_LOCATIONS.LANE) {
      const labelText = _.get(_.find(sqTrendStore.labelDisplayConfiguration.customLabels, {
        location: LABEL_LOCATIONS.LANE,
        target: lane
      }), 'text');

      if (labelText) {
        return `<span class="text-with-shadow">${$sanitize(labelText)}</span>`;
      }
    }
    return '';
  }

  /**
   * Returns a list of one custom label for the axis if the axis is configured to show a custom label.
   *
   * @param {string} alignment - the axis for which to return the custom label
   * @returns {{text: string}[]} an array of one custom label or an empty array
   */
  function getAxisCustomLabel(alignment): { text: string }[] {
    if (sqTrendStore.labelDisplayConfiguration.custom === LABEL_LOCATIONS.AXIS) {
      const customLabel = _.get(_.find(sqTrendStore.labelDisplayConfiguration.customLabels, {
        location: LABEL_LOCATIONS.AXIS,
        target: alignment
      }), 'text');

      if (customLabel) {
        return [{ text: customLabel }];
      }
    }
    return [];
  }

  /**
   * Returns a list of labels for the items in a lane.
   *
   * @param {Object[]} items - Array of items displayed by the chart
   * @param {number} lane - the lane for which to return labels
   * @returns {LabelDefinition[]} an array of labels to display in the lane
   */
  function getLaneSeriesLabels(items, lane): LabelDefinition[] {
    const seriesInLane = _.chain(items)
      .filter((item: any) => _.includes([ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR, ITEM_TYPES.TABLE], item.itemType))
      .filter(item => !_.isNull(lane) ? _.get(item, 'lane') === lane : item)
      .map(item => item.childType ? sqTrendDataHelper.findItemIn(TREND_STORES, item.isChildOf) : item)
      .map(item => sqItemDecorator.decorate(item))
      .uniqBy('id')
      .value();

    if (
      sqTrendStore.labelDisplayConfiguration.unitOfMeasure === LABEL_LOCATIONS.LANE
      && sqTrendStore.labelDisplayConfiguration.asset !== LABEL_LOCATIONS.LANE
      && sqTrendStore.labelDisplayConfiguration.name !== LABEL_LOCATIONS.LANE
      && seriesInLane.length > 1
      && _.every(
      seriesInLane, item => item.displayUnitOfMeasure.value === seriesInLane[0].displayUnitOfMeasure.value
      )
      && seriesInLane[0].displayUnitOfMeasure.value
    ) {
      const { value } = seriesInLane[0].displayUnitOfMeasure;
      return [{
        text: value,
        html: `<span class="text-with-shadow">${value}</span>`,
        isListItem: true
      }];
    } else if (
      sqTrendStore.labelDisplayConfiguration.name === LABEL_LOCATIONS.LANE
      || sqTrendStore.labelDisplayConfiguration.asset === LABEL_LOCATIONS.LANE
      || sqTrendStore.labelDisplayConfiguration.unitOfMeasure === LABEL_LOCATIONS.LANE
    ) {
      const seriesLabels = _.chain(seriesInLane)
        .map(function(s: any) {
          let unitOfMeasure = null;
          let laneDisplayText = '';
          const name = s.name || $translate.instant('PREVIEW');

          if (sqTrendStore.labelDisplayConfiguration.name === LABEL_LOCATIONS.LANE) {
            laneDisplayText = $sanitize(name);
          }

          if (sqTrendStore.labelDisplayConfiguration.asset === LABEL_LOCATIONS.LANE && !_.isEmpty(s.assets)) {
            const assetNames = service.getAssetNames(s, sqTrendStore.labelDisplayConfiguration.assetPathLevels);
            if (laneDisplayText) {
              laneDisplayText = assetNames.concat(ASSET_PATH_SEPARATOR, laneDisplayText);
            } else {
              laneDisplayText = assetNames;
            }
          }

          if (sqTrendStore.labelDisplayConfiguration.unitOfMeasure === LABEL_LOCATIONS.LANE) {
            let displayUnitOfMeasure = s.displayUnitOfMeasure;
            if (s.calculationType && s.calculationType === TREND_TOOLS.FFT_TABLE) {
              displayUnitOfMeasure = { isRecognized: true, value: s.outputUnits };
            }

            if (displayUnitOfMeasure && displayUnitOfMeasure.value) {
              unitOfMeasure = laneDisplayText
                ? { ...displayUnitOfMeasure, value: ` (${displayUnitOfMeasure.value})` }
                : displayUnitOfMeasure;
            }
          }

          if (!laneDisplayText && !unitOfMeasure) {
            return;
          }

          const returnLabel = displayWithUnits(
            { text: laneDisplayText, unitOfMeasure, color: getLabelColorFromItem(s) },
            { attributes: 'class="text-with-shadow"' }
          );

          return { ...returnLabel, isListItem: true };
        })
        .compact()
        .value();
      return seriesLabels;
    }
    return [];
  }

  /**
   * Returns a list of labels for the items on an axis.
   *
   * @param {Object[]} items - Array of items displayed by the chart
   * @param {Object[]} series - Array of Series objects
   * @returns {Object[]} an array of labels to display on the axis
   */
  function getAxisSeriesLabels(items, series) {
    const seriesLabels = _.chain(series)
      .map(item => item.childType ? sqTrendDataHelper.findItemIn(TREND_STORES, item.isChildOf) : item)
      .map(item => sqItemDecorator.decorate(item))
      .uniqBy('id')
      .map(function(s) {
        let axisDisplayText = '';
        const name = s.name || $translate.instant('PREVIEW');

        if (sqTrendStore.labelDisplayConfiguration.name === LABEL_LOCATIONS.AXIS) {
          axisDisplayText = name;
        }

        if (sqTrendStore.labelDisplayConfiguration.asset === LABEL_LOCATIONS.AXIS && !_.isEmpty(s.assets)) {
          const assetNames = service.getAssetNames(s, sqTrendStore.labelDisplayConfiguration.assetPathLevels);
          if (axisDisplayText) {
            axisDisplayText = assetNames.concat(ASSET_PATH_SEPARATOR, axisDisplayText);
          } else {
            axisDisplayText = assetNames;
          }
        }

        let unitOfMeasure = null;
        if (sqTrendStore.labelDisplayConfiguration.unitOfMeasure === LABEL_LOCATIONS.AXIS) {
          const displayUnitOfMeasure = s.displayUnitOfMeasure;

          if (displayUnitOfMeasure && displayUnitOfMeasure.value) {
            unitOfMeasure = axisDisplayText && displayUnitOfMeasure
              ? { ...displayUnitOfMeasure, value: ` (${displayUnitOfMeasure.value})` }
              : displayUnitOfMeasure;
          }
        }

        if (axisDisplayText || unitOfMeasure) {
          return { text: axisDisplayText, unitOfMeasure, isListItem: true, color: getLabelColorFromItem(s) };
        }
      })
      .compact()
      .value();

    // If we have identical labels from all the series, combine them and remove their colors to simplify things
    if (
      seriesLabels.length > 1
      && _.every(
      seriesLabels,
      label => _.isEqual(_.omit(label, ['color']), _.omit(seriesLabels[0], ['color']))
      )
    ) {
      return [_.omit(seriesLabels[0], ['color'])];
    }
    return seriesLabels;
  }

  /**
   * Returns a list of one configuration label for a lane if the lane is configured to show its configuration.
   *
   * @param lane - the lane for which to return the configuration label
   * @returns the configuration label html or an empty string
   */
  function getLaneConfigLabel(lane): string {
    if (!_.isNull(lane) && sqTrendStore.showChartConfiguration) {
      const text = '(Lane ' + $sanitize(lane) + ')';
      return `<span class="text-with-shadow">${text}</span>`;
    }

    return '';
  }

  /**
   * Returns a label with HTML for the passed in label without HTML.
   *
   * @param {Object} label - a label without HTML defined
   * @param {Object} options - Additional attributes to put in the html of the label
   * @returns {LabelDefinition} the label with defined HTML
   */
  function displayWithUnits(label, options = { attributes: '' }): LabelDefinition {
    let unitOfMeasure = '';
    if (label.unitOfMeasure) {
      const style = label.unitOfMeasure.isRecognized
        ? `color: ${label.color};`
        : `font-style: italic; color: ${label.color};`;
      unitOfMeasure = `<span style="${style}">${label.unitOfMeasure.value}</span>`;
    }
    return {
      html: (
        `<span ${options.attributes}${
          label.color ? ` style="color: ${label.color};"` : ''
        }>${label.text}${unitOfMeasure}</span>`
      ),
      text: `${label.text}${label.unitOfMeasure ? label.unitOfMeasure.value : ''}`
    };
  }

  /**
   * Returns the tick positions to be used for the Highcharts Axis labels.
   *
   * @param {Number} min - the y-axis min property
   * @param {Number} max - the y-axis max property
   * @param {Object} item - Object representing an item
   * @param {Number} item.yAxisMin - the yAxisMin set either via user input (customize) or based on the lane count.
   *   This is the cut-off used for the lower y-axis value.
   * @param {Object} item.yAxisMax - the yAxisMax set either via user input (customize) or based on the lane count.
   *   This is the cut-off used for the lower y-axis value.
   * @param {Number} laneHeight - the height of the lane that is available for signal display in pixels.
   * @param {Number} laneCount - the number of lanes displayed.
   * @returns {Array} of tick positions.
   */
  function getNumericTickPositions(min, max, item, laneHeight, laneCount) {
    const collapseLabelsLaneHeight = 130;
    const tickPositions = [];
    const labelBuffer = TREND_BUFFER_FACTOR_LABEL * laneCount;
    const range = max - min;

    // Return to let highcharts handle the tick positions for logarithmic axes
    if (!item || item.yAxisType === Y_AXIS_TYPES.LOGARITHMIC) {
      return;
    }

    const showExtremes = laneHeight < collapseLabelsLaneHeight;
    const constantSpacing = showExtremes;

    const { stepStart, stepSize, numberOfTicks, precision } = service.fetchTickAttributes(min, max, item.formatOptions,
      laneHeight);
    const padding = labelBuffer * range;

    const minTickPosition = Number(item.yAxisMin);
    const maxTickPosition = Number(item.yAxisMax);

    if (showExtremes) {
      tickPositions.push(minTickPosition);
    }

    for (let i = 0; i <= numberOfTicks; i++) {
      let tickPosition;
      if (constantSpacing) {
        tickPosition = minTickPosition + i * (maxTickPosition - minTickPosition) / numberOfTicks;
      } else {
        tickPosition = sqNumberHelper.roundWithPrecision(stepStart + i * stepSize, precision);
      }

      if (showExtremes && laneCount === 1) {
        if (tickPosition <= minTickPosition ||
          tickPosition >= maxTickPosition) {
          continue;
        }

        if (Math.abs(tickPosition - minTickPosition) < stepSize * 0.5 ||
          Math.abs(tickPosition - maxTickPosition) < stepSize * 0.5) {
          continue;
        }
      }

      if ((tickPosition >= (minTickPosition - padding) && tickPosition <= (maxTickPosition + padding))) {
        tickPositions.push(tickPosition);
      }
    }

    if (showExtremes) {
      tickPositions.push(maxTickPosition);
    }
    return tickPositions;
  }

  /**
   * Fetches the tickAttributes that describe how labels should be formatted.
   * tickAttributes are cached up to maxStoredTickAttributes.
   *
   * @param {Number} min - the axis minimum
   * @param {Number} max - the axis maximum
   * @param {FormatOptions} formatOptions - the number formatting options to be used with the label
   * @param {Number} laneHeight - the height of the lane
   * @returns {Object} an Object describing how the axis labels (ticks) should be rendered.
   */
  function fetchTickAttributes(min, max, formatOptions, laneHeight) {
    const storedTickAttributes = _.find(tickAttributesConfig, { min, max, laneHeight });

    let tickAttributes;
    if (_.isEmpty(storedTickAttributes)) {
      tickAttributes = service.calculateTickAttributesForChart(laneHeight, min, max);
      tickAttributesConfig.push({ tickAttributes, min, max, laneHeight });
    } else {
      tickAttributes = storedTickAttributes.tickAttributes;
    }

    if (tickAttributesConfig.length > maxStoredTickAttributes) {
      tickAttributesConfig.shift();
    }
    tickAttributes.formatOptions = formatOptions;
    return tickAttributes;
  }

  /**
   * Formats a given value according to the tickAttributes definition.
   *
   * @param {Number} value - the yaxis value
   * @param {Object} tickAttributes - Object defining how to display the value
   * @returns {String} formatted, display ready y-axis label.
   */
  function formatYAxisTick(value, tickAttributes) {
    if (!_.isFinite(value)) {
      return '';
    }

    let number;
    if (_.get(tickAttributes.formatOptions, 'format', AUTO_FORMAT) === AUTO_FORMAT) {
      if (tickAttributes.precision > 0) {
        number = value.toFixed(tickAttributes.precision);
      } else {
        number = sqNumberHelper.roundWithPrecision(value, 0);
        if (Math.abs(number) >= 1000000) {
          // Large numbers displayed using exponential notation in powers of 3
          number = sqNumberHelper.formatNumber(number, { format: '##0.0E+0' });
        }
      }
    } else {
      number = sqNumberHelper.formatNumber(value, tickAttributes.formatOptions);
    }

    if (number !== undefined) {
      // We don't need to sanitize this since it is immediately rendered as svg text through highcharts
      return number.toString();
    }
  }

  /**
   * Returns the appropriate String display value for a given y-axis value.
   *
   * @param {Number} value - the y-axis value
   * @param {Object} series - the series the axis belongs to
   * @param {Object} series.stringEnum - Array of Objects defining the string value pairs
   * @returns {String} formatted, display ready y-axis label.
   */
  function formatStringYAxisLabel(value, series) {
    let stringValue;

    stringValue = _.result(_.find(series.stringEnum, { key: value }), 'stringValue', '');
    if (stringValue.length > Y_AXIS_LABEL_CHARACTER_WIDTH) {
      stringValue = stringValue.slice(0, 2) + '...' + stringValue.slice(-3);
    }

    // We don't need to sanitize this since it is immediately rendered as svg text through highcharts
    return stringValue;
  }

  /**
   * Create an axis that can be used for the lanes 'back drop'. This is it's own axis
   * as the background shouldn't be turned off with the axis visibility.
   *
   * @returns {Object} defining a Highcharts y-axis.
   */
  function createPlotBandAxisDefinition() {
    return {
      id: 'yAxis-' + PLOT_BAND,
      // NOTE: don't use the 'top' property or the bug logged in CRAB-4044 will return.
      gridLineWidth: 0,
      lineWidth: 0,
      zIndex: Z_INDEX.Y_AXIS_DEFINITION, // ensure vertical axis line is on top of the chain view drawings
      visible: true,
      opposite: false,
      labels: {
        enabled: false
      },
      startOnTick: false,
      endOnTick: false,
      title: {
        enabled: false
      },
      offset: 0
    };
  }

  /**
   * Returns an id for a capsule axis.
   *
   * @param {String} id - the id of the capsule set
   * @returns {String} to be used to identify a capsule axis
   */
  function getCapsuleAxisId(id) {
    return TREND_TOP_Y_AXIS_ID + '_' + sqUtilities.getCertainId(id);
  }

  /**
   * Helper function that creates a y-axis definition for the given item.
   *
   * @param {Object} item - the item we need to get a y-axis for
   * @param {Function} yAxisStringFormatter - yAxisStringFormatter function.
   * @param {Function} yAxisFormatter - yAxisFormatter function
   * @param {String} [PREVIEW_ID] - preview id (optional)
   * @param {String} [PREVIEW_HIGHLIGHT_COLOR] - color for the preview (optional)
   * @returns {any}
   */
  function createYAxisDefinition(item, yAxisStringFormatter, yAxisFormatter, tickPositioner) {
    let labels;
    const visible = _.get(item, 'axisVisibility', true);

    const labelColor = getLabelColorFromItem(item);
    if (item.isStringSeries) {
      labels = {
        formatter: yAxisStringFormatter,
        enabled: visible,
        align: 'right',
        x: -5,
        y: -2,
        style: {
          color: labelColor,
          overflow: 'visible',
          textOverflow: 'none',
          whiteSpace: 'nowrap',
          minWidth: '10px'
        },
        useHTML: false // never set to true as we rely on this to prevent XSS attacks on labels
      };
    } else {
      labels = {
        formatter: yAxisFormatter,
        enabled: visible,
        align: 'right',
        x: -5,
        style: {
          color: labelColor,
          minWidth: '10px'
        },
        useHTML: false // never set to true as we rely on this to prevent XSS attacks on labels
      };
    }

    const yAxis = {
      id: 'yAxis-' + item.id,
      lane: item.lane,
      // NOTE: don't use the 'top' property or the bug logged in CRAB-4044 will return.
      gridLineWidth: getGridlineWidth(),
      lineColor: labelColor,
      zIndex: Z_INDEX.Y_AXIS_DEFINITION, // ensure that vertical axis line is on top of chain view drawings
      startOnTick: false,
      endOnTick: false,
      title: {
        enabled: false
      },
      labels,
      visible,
      opposite: false,
      yAxisMin: item.yAxisMin,
      yAxisMax: item.yAxisMax,
      custom: {
        allowNegativeLog: true
      }
    };

    item.yAxis = yAxis.id;

    if (item.isStringSeries) {
      _.assign(yAxis, { tickPositions: _.map(item.stringEnum, 'key').sort() });
    } else {
      _.assign(yAxis, { tickPositioner, type: item.yAxisType });
    }

    if (_.startsWith(item.id, PREVIEW_ID)) {
      _.assign(yAxis, {
        plotBands: [{
          color: PREVIEW_HIGHLIGHT_COLOR,
          from: item.yAxisMin - 0.8,
          to: item.yAxisMax + 0.8
        }]
      });
    }
    return yAxis;
  }

  /**
   * Helper function that returns a capsule set axis definition.
   *
   * @param {Object} item - the item we need an axis for
   * @param {String} axisId - the id for the axis.
   * @returns {Object} describing a Highcharts axis.
   */
  function getCapsuleAxisDefinition(item, axisId) {
    return {
      id: axisId,
      lineWidth: 0,
      endOnTick: false,
      startOnTick: false,
      reversed: true,
      seeqDisallowZoom: true,
      capsuleSetId: item.capsuleSetId,
      customValue: item.yValue,
      offset: 0,
      title: {
        text: null
      },
      labels: {
        enabled: false
      },
      gridLineWidth: 0,
      min: 0,
      max: item.yValue
    };
  }

  /**
   * Returns the width the labels of an axis will take.
   * This returns the width of the axis ticks with appropriate padding.
   *
   * @param  {Array} tickPositions - array of where to place the ticks
   * @param {Object} axis - Highcharts axis Object
   * @param {number} laneHeight - height of the lane available for trend display
   * @param {number} padding - padding to be added to the label display.
   * @param {boolean} isStringSeries - True if it is a string series
   * @param {Object} series - The series
   * @returns {Number} width of an axis with labels. This can be used to set the axis offset.
   */
  function getLabelWidth(tickPositions, axis, laneHeight, padding, isStringSeries, series?) {
    let chars = 1;
    if (!_.isEmpty(tickPositions)) {
      chars = _.chain(tickPositions)
        .map((pos) => {
          if (isStringSeries) {
            return service.formatStringYAxisLabel(pos, series);
          } else {
            return service.formatYAxisTick(pos,
              service.fetchTickAttributes(axis.userOptions.yAxisMin,
                axis.userOptions.yAxisMax, axis.userOptions.formatOptions, laneHeight));
          }

        }).reduce((maxChars, label) => {
          label = (!label || label === 'undefined') ? '' : label;
          return maxChars > label.length ? maxChars : label.length;
        }, 0).value();
    } else if (axis.logarithmic) {
      chars = _.chain([axis.userMax, axis.userMin])
        .map(num => sqNumberHelper.roundWithPrecision(num, 2).toString().length)
        .max()
        .value();
    }

    return chars * Y_AXIS_LABEL_CHARACTER_WIDTH + padding;
  }

  /**
   * Calculates the ideal tick attributes based on the chartHeight, the min and max axis values of
   * the item and the overall number of lanes displayed,
   *
   * @param {Number} laneHeight - the height of a lane on the chart in pixels
   * @param {Number} yAxisMin - yAxis Minimum. Value in Axis Units that represents the lower boundary
   * of the lane the item is displayed on
   * @param {Number} yAxisMax - yAxis Maximum. Value in Axis Units that represents the upper boundary
   * of the lane the item is displayed on.
   * @return { {stepSize: number, precision: number, stepStart: number, numberOfTicks: number} } - an Object that
   * provides necessary information to properly place labels.
   */
  function calculateTickAttributesForChart(laneHeight, yAxisMin, yAxisMax) {
    let stepStart;
    const tickSpacing = 35;
    const numberOfTicks = Math.round(laneHeight / tickSpacing);
    const range = yAxisMax - yAxisMin;
    const tickAttributes = calculateTickAttributes(range, numberOfTicks);

    stepStart = yAxisMin - fmod(yAxisMin, tickAttributes.stepSize);

    return {
      stepSize: tickAttributes.stepSize,
      precision: tickAttributes.precision,
      stepStart,
      numberOfTicks: _.isFinite(numberOfTicks) ? numberOfTicks : 1
    };
  }

  /**
   * Calculates an optimal step size and precision given a range and  the desired number of steps (aka ticks)
   * Taken from http://stackoverflow.com/a/15071978 but modified to some degree
   *
   * @param {Number} range - the range of the y-axis (max-min)
   * @param {Number} targetSteps - the number of ticks to display
   * @return { stepSize, precision } - Object that returns step size and precision
   */
  function calculateTickAttributes(range, targetSteps) {
    let stepSize;
    // calculate an initial guess at step size
    const tempStep = range / targetSteps;
    const ln10 = Math.log(10);
    // get the magnitude of the step size
    const mag = Math.floor(Math.log(tempStep) / ln10);
    const magPow = Math.pow(10, mag);
    let precision = mag * -1;

    // calculate most significant digit of the new step size
    let magMsd = Math.round(tempStep / magPow + 0.5);

    // promote the MSD to either 2, 5, or 10
    if (magMsd > 5.0) {
      magMsd = 10.0;
    } else if (magMsd > 2.0) {
      magMsd = 5.0;
    } else if (magMsd > 1.0) {
      magMsd = 2.0;
    }

    stepSize = magMsd * magPow;
    stepSize = _.isFinite(stepSize) ? stepSize : 1;
    precision = _.isFinite(precision) ? precision : 1;
    return { stepSize, precision };
  }

  /**
   * Get a value for gridline width
   *
   * @return {number} returns 1 if we want to show gridlines, 0 if we don't
   */
  function getGridlineWidth() {
    return sqTrendStore.showGridlines ? 1 : 0;
  }

  /**
   * Floating-point modulo function.
   * Taken from https://gist.github.com/wteuber/6241786
   *
   * @param {Number} a - the dividend
   * @param {Number} b - the divisor
   * @return {Number} returns the remainder of a divided by b.
   */
  function fmod(a, b) {
    return Number((a - (Math.floor(a / b) * b)).toPrecision(8));
  }

  /**
   * Determines the color for the label based on the item. If in capsule mode and in a coloring mode where it
   * doesn't make sense to show the item's color it returns undefined so that it will show as the default neutral color.
   *
   * @param {Object} item - The item assigned to the lane or axis
   * @return {string|undefined} - The item's color or undefined if in a specific capsule time color mode
   */
  function getLabelColorFromItem(item) {
    return sqTrendStore.view === TREND_VIEWS.CAPSULE && _.includes(
      [CapsuleTimeColorMode.Rainbow, CapsuleTimeColorMode.ConditionGradient], sqTrendStore.capsuleTimeColorMode)
      ? undefined : item.color;
  }

  return service;
}
