import React from 'react';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { NotificationsService } from '@/services/notifications.service';
import { LoggerService } from '@/services/logger.service';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { ReportContentActions } from '@/reportEditor/reportContent.actions';
import { CONTENT_STATE } from '@/reportEditor/report.module';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { ContentCallbacks, CustomPlugin } from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import {
  CONTENT_MODEL_ATTRIBUTES,
  getContentJQuery,
  getContentWidgetViewElement
} from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import { Visualization } from '@/hybrid/annotation/ckEditorPlugins/components/content.utilities';
import { TableBuilderHelperService } from '@/hybrid/tableBuilder/tableBuilderHelper.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';

const contentMenuBindings = bindingsDefinition({
  contentId: prop<string>(),
  closeMenu: prop<() => void>(),
  isReact: prop<boolean>(),
  visualization: prop<Visualization>(),
  sqReportContent: injected<ReportContentService>(),
  sqReportContentActions: injected<ReportContentActions>(),
  sqNotifications: injected<NotificationsService>(),
  sqLogger: injected<LoggerService>(),
  sqReportEditor: injected<ReportEditorService>(),
  sqTableBuilderHelper: injected<TableBuilderHelperService>()
});

/**
 * A menu containing extra functionality for Content related to updating, refreshing, and formatting content.
 */
export const ContentMenu: SeeqComponent<typeof contentMenuBindings> = (props) => {
  const {
    contentId,
    closeMenu,
    isReact,
    visualization
  } = props;
  const {
    sqReportContent,
    sqReportContentActions,
    sqNotifications,
    sqLogger,
    sqReportEditor,
    sqTableBuilderHelper
  } = useInjectedBindings(contentMenuBindings);

  const url = `/api/content/${contentId}/sourceUrl`;
  const iconClasses = 'editorBtn fa-2x';

  const editor: any = sqReportEditor.getGlobalInstance();
  const contentCallbacks: ContentCallbacks = editor.config.get(CustomPlugin.Content).contentCallbacks;

  return <div className="backgroundColorWhite flexColumnContainer flexSpaceBetween min-width-120">
    <HoverTooltip text="REPORT.EDITOR.UPDATE_BUTTON_TITLE">
      <span>
        <Icon
          icon="fc-seeq-content fc-inverse"
          extraClassNames={classNames(iconClasses, 'contentBtn')}
          testId="contentEdit"
          onClick={() => {
            try {
              sqReportContent.setStoreFromContent(contentId)
                .then(() => sqReportContentActions.setModalName(CONTENT_STATE.PROPERTIES));
            } catch (error) {
              // If we get here our content is probably corrupted somehow, best to ask the user to
              // recreate the content
              sqNotifications.errorTranslate('REPORT.EDITOR.UPDATE_FAILED');
              sqLogger.error(error);
            }
            closeMenu();
          }} />
        </span>
    </HoverTooltip>
    <HoverTooltip text="OPEN_LINK">
      <span>
        <Icon
          extraClassNames={iconClasses}
          icon="fa-external-link"
          onClick={() => window.open(url)}
          testId="contentLink"
        />
      </span>
    </HoverTooltip>
    <HoverTooltip text="REPORT.EDITOR.REFRESH_CONTENT">
      <span>
        <Icon
          extraClassNames={iconClasses}
          icon="fc-redo"
          onClick={() => sqReportContent.forceRefreshContent(contentId)}
          testId="contentRefresh"
        />
      </span>
    </HoverTooltip>
    {isReact && visualization === Visualization.TABLE &&
    <HoverTooltip text="TABLE_BUILDER.COPY_TO_CLIPBOARD_TOOLTIP">
      <span>
        <Icon
          extraClassNames={iconClasses}
          icon="fa-copy"
          testId="contentTableCopy"
          onClick={() => {
            // We can share most of the copy logic by using sqTableBuilderHelper, but we have to disable some
            // CK/organizer specific state to be able to do it. Namely, taking off the noCopy class which prevents
            // copying of elements (which in CK can make copying a single element result in actually copying more
            // than 1) and turning off the content specific copy logic, which would otherwise result in the content
            // being pasted as an image.
            const contentSelector = `[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}="${contentId}"]`;
            const tableWrapper = document.querySelector(
              `${contentSelector} table`
            ).parentElement;

            contentCallbacks.setUseCkCopyLogic(false);
            const contentWrapper = document.querySelector(contentSelector).parentElement;
            contentWrapper.classList.remove('noCopy');
            sqTableBuilderHelper.copyToClipboard(tableWrapper);
            contentCallbacks.setUseCkCopyLogic(true);
            contentWrapper.classList.add('noCopy');
          }}
        />
      </span>
    </HoverTooltip>}
    {!isReact &&
    <>
      <HoverTooltip text="REPORT.EDITOR.CLEAR_RESIZE">
        <span>
          <Icon
            extraClassNames={iconClasses}
            icon="fa-window-close-o"
            testId="contentResizeClear"
            onClick={() => {
              //region rant
              /*
              Overall CK is a very good editing system when the work you're doing is raw HTML heavy. However, the
              system was clearly not designed for managing complex React components. Because its generally well
              designed wrt customization, its totally doable, but this is one of those cases where its true focus
              rears its ugly head. Essentially, we need to keep track of the resizing in 3 places: the view, the model
              and the react component that is rendering MOST (but not all) of the view.

              In most cases, the view and the model should be close to 1:1, but due to Content being a Widget, CK
              explicitly does not update the view based on what's in the model, nor does it update the model
              based on changes in the view, but it does occasionally need to refresh the widgets's view (due to changes in
              spacing) and so refreshes the view with the classes/styles originally given to it, NOT what is in the
              model. Thankfully, CK exposes the view so we can handle this ourselves, resulting in us having to clear
              the state in the model, the state in the view, AND the state in the react component to keep everything
              in sync. Ideally we'd have a way to tell CK "if these model attributes change propagate the changes to the
              view". We partially do that with the the `editor.model.change`, as that hooks into an event defined in
              `Content.tsx::defineAttributeEvents` which fires a content specific event which `Content.molecule` is
              listening for. But we still have to listen in the content for that event, which is kind of annoying.
               */
              //endregion

              // Removes the width element from the parent div view that actually has the width style.
              editor.editing.view.change(writer => writer.removeStyle('width',
                getContentWidgetViewElement(getContentJQuery(contentId)[0], editor)));
              // Removes the attribute from the model
              editor.model.change(writer => writer.removeAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT,
                contentCallbacks.getCurrentModel(contentId)));
            }}
          />
        </span>
      </HoverTooltip>
      <HoverTooltip text="REPORT.EDITOR.TOGGLE_BORDER">
        <span>
          <Icon
            extraClassNames={iconClasses}
            icon="fc-border-style"
            testId="contentBorderToggle"
            onClick={() => {
              const model = contentCallbacks.getCurrentModel(contentId);
              editor.model.change(writer => writer.setAttribute(CONTENT_MODEL_ATTRIBUTES.BORDER,
                !model.getAttribute(CONTENT_MODEL_ATTRIBUTES.BORDER),
                model));
            }}
          />
        </span>
      </HoverTooltip>
      <HoverTooltip text="REPORT.EDITOR.NO_MARGIN">
        <span>
          <Icon
            extraClassNames={iconClasses}
            icon="fc-capsule-bar"
            testId="contentMarginToggle"
            onClick={() => {
              const model = contentCallbacks.getCurrentModel(contentId);
              editor.model.change(writer => writer.setAttribute(CONTENT_MODEL_ATTRIBUTES.NO_MARGIN,
                !model.getAttribute(CONTENT_MODEL_ATTRIBUTES.NO_MARGIN),
                model));
            }}
          />
        </span>
      </HoverTooltip>
    </>}
  </div>;
};
