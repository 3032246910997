import React, { useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import _ from 'lodash';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { DURATION_TIME_UNITS, DurationTimeUnit, UnitWithLabel } from '@/main/app.constants';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { FormError } from '@/hybrid/core/FormError.atom';
import { IconSelect } from '@/hybrid/core/IconSelect.molecule';
import classNames from 'classnames';

export type ValueWithUnitsItem = { value: number; units: string };

const valueWithUnitsBindings = bindingsDefinition({
  onChange: prop<(ValueWithUnitsItem, property: string) => void>(),
  min: prop<number>(),
  className: prop.optional<any>(),
  defaultValue: prop<ValueWithUnitsItem>(),
  propName: prop.optional<string>(),
  isValid: prop.optional<boolean>(),
  minIsExclusive: prop.optional<boolean>(),
  required: prop.optional<boolean>(),
  availableUnits: prop.optional<(DurationTimeUnit | UnitWithLabel)[]>(),
  unitOptionIndex: prop.optional<number>(),
  insideModal: prop.optional<boolean>(),
  customError: prop.optional<boolean>(),
  disabled: prop.optional<boolean>(),
  fromFormBuilder: prop.optional<boolean>(),
  largeValueInput: prop.optional<boolean>(),
  step: prop.optional<number>(),
  appendToBody: prop.optional<boolean>(),
  extraClassNames: prop.optional<string>(),
  fullWidth: prop.optional<boolean>()
});

export const ValueWithUnits: SeeqComponent<typeof valueWithUnitsBindings> = (props) => {
  const {
    propName,
    onChange,
    defaultValue,
    min,
    minIsExclusive,
    required,
    availableUnits,
    insideModal,
    customError,
    disabled = false,
    largeValueInput = false,
    step,
    isValid,
    unitOptionIndex,
    className,
    fromFormBuilder = false,
    appendToBody = true,
    extraClassNames,
    fullWidth = false
  } = props;

  const [selectedUnit, setSelectedUnit] = useState({});
  const [selectedValue, setSelectedValue] = useState('');
  const [valid, setValid] = useState(true);
  const [displayOptions, setDisplayOptions] = useState([]);
  const [pristine, setPristine] = useState(true);

  useEffect(() => {
    const availableOptions = (availableUnits || DURATION_TIME_UNITS);
    const updatedOptions = _.map(availableOptions,
      (option: any) => ({
        value: _.isNil(unitOptionIndex) ? option.unit[0] : option.unit[unitOptionIndex],
        text: option.translationKey ? option.translationKey : option.shortLabel
      }));
    setDisplayOptions(updatedOptions);
    if (defaultValue) {
      // sometimes the backend returns a different unit than we send along when we create a condition so we need to
      // do some extra work to ensure we select the correct unit
      let unit = _.find(updatedOptions, { value: defaultValue.units as any });
      if (!unit) {
        _.forEach(availableOptions, (option) => {
          if (_.includes(option.unit, defaultValue.units)) {
            unit = _.find(updatedOptions, { value: option.unit[0] as any });
          }
        });
      }
      setSelectedUnit(unit);
      setSelectedValue(_.trim(defaultValue?.value?.toString()) ?? '');
    }
  }, [defaultValue, availableUnits]);

  const validate = ({ value, unit }) => {
    console.log('minim:', min);
    if (required || !_.isNil(unit)) {
      if (!_.isNil(min) || !_.isNil(min)) {
        return minIsExclusive ? value > min : value >= min;
      } else {
        return true;
      }
    }
    return true;
  };

  const triggerChange = (newValue, newUnit) => {
    let unit = selectedUnit;
    let value = selectedValue;
    setPristine(false);

    if (!_.isNaN(newValue) && !_.isNil(newValue)) {
      setSelectedValue(newValue);
      value = newValue;
    }

    if (!_.isNil(newUnit)) {
      setSelectedUnit(newUnit);
      unit = newUnit;
    }

    if (_.isFunction(onChange)) {
      const isValid = validate({ value, unit });
      setValid(isValid);

      onChange(_.assign({}, {
        value: value !== '' ? _.toNumber(value) : '', units: (unit as any)?.value, valid: isValid,
        propName
      }));
    }
  };

  const renderErrorMessage = () => {
    if (pristine || customError || fromFormBuilder) {
      return null;
    } else if (required && (_.isNaN(selectedValue) || _.isNil(selectedUnit))) {
      return <FormError errorText="FORM.REQUIRED_FIELD" extraClassNames="help-block" />;
    } else if (!valid) {
      return (
        <FormError
          extraClassNames="help-block"
          errorText={minIsExclusive ? 'VALUE_WITH_UNITS.INVALID_MIN_EXCLUSIVE' : 'VALUE_WITH_UNITS.INVALID'}
          errorParameters={{ min }} />);
    }
  };

  return (
    <>
      <div className={classNames("flexColumnContainer mt5 specValueWithUnits", extraClassNames, { flexFill: fullWidth })} data-testid={propName}>
        <FormControl
          className={classNames('value-with-units-value-input', 'height-30',
            { 'error-border': ((_.isNil(isValid) ? !valid : !isValid)) },
            { flexFill: largeValueInput })}
          data-testid="valueWithUnitsInput"
          name="value"
          placeholder=""
          type="number"
          step={step ? step : 1}
          value={selectedValue}
          onChange={(e) => {
            e.stopPropagation();
            setSelectedValue(e.target.value);
            triggerChange(e.target.value, null);
          }}
          disabled={disabled}
        />
        <div className="width-100">
          <IconSelect
            disabled={disabled}
            name="valueWithUnitsUnits"
            className={classNames('value-with-units', className)}
            value={selectedUnit}
            appendToBody={appendToBody}
            selectOptions={displayOptions}
            onChange={val => triggerChange(null, val)}
            insideModal={insideModal}
            skipMemo={true}
          />
        </div>
      </div>
      {renderErrorMessage()}
    </>
  );
};
export default ValueWithUnits;
export const sqValueWithUnits = angularComponent(valueWithUnitsBindings, ValueWithUnits);
