import _ from 'lodash';
import angular from 'angular';
import { BaseToolStoreService } from '@/investigate/baseToolStore.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { LOW_PASS_FILTER_CUTOFF, TREND_TOOLS } from '@/investigate/investigate.module';
import { DURATION_TIME_UNITS } from '@/main/app.constants';

angular.module('Sq.Investigate').store('sqFftStore', sqFftStore);

export type FftStore = ReturnType<typeof sqFftStore>['exports'];

function sqFftStore(sqBaseToolStore: BaseToolStoreService, sqDateTime: DateTimeService) {
  const store = {
    initialize() {
      this.state = this.immutable(_.assign({}, sqBaseToolStore.COMMON_PROPS, {
        rateType: true,
        rate: LOW_PASS_FILTER_CUTOFF,
        outputUnits: sqDateTime.getDurationTimeUnit('min', DURATION_TIME_UNITS).unit[0],
        highPass: LOW_PASS_FILTER_CUTOFF,
        lowPass: LOW_PASS_FILTER_CUTOFF,
        useHighPass: false,
        useLowPass: false
      }));
    },

    exports: {
      get rateType() {
        return this.state.get('rateType');
      },

      get rate() {
        return this.state.get('rate');
      },

      get outputUnits() {
        return this.state.get('outputUnits');
      },

      get highPass() {
        return this.state.get('highPass');
      },

      get lowPass() {
        return this.state.get('lowPass');
      },

      get useHighPass() {
        return this.state.get('useHighPass');
      },

      get useLowPass() {
        return this.state.get('useLowPass');
      }
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @return {Object} State for the store
     */
    dehydrate() {
      return this.state.serialize();
    },

    /**
     * Sets the fft panel state
     *
     * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      FFT_SET_RATE_TYPE: 'setRateType',
      FFT_SET_RATE: 'setRate',
      FFT_SET_OUTPUT_UNITS: 'setOutputUnits',
      FFT_SET_FILTER_HIGH: 'setHighPass',
      FFT_SET_FILTER_LOW: 'setLowPass',
      FFT_SET_USE_FILTER_HIGH: 'setUseHighPass',
      FFT_SET_USE_FILTER_LOW: 'setUseLowPass'
    },

    /**
     * Sets the rate type flag
     *
     * @param {Object} payload - Object container
     * @param {Bolean} payload.rateType - True if period rate type, false if frequency
     */
    setRateType(payload) {
      this.state.set('rateType', payload.rateType);
    },

    /**
     * Sets the sampling rate
     *
     * @param {Object} payload - Object container
     * @param {String} payload.rate - the rate to sample at
     */
    setRate(payload) {
      let rate = payload.rate;

      // If the rate is undefined, populate the display rate.  This usually occurs when the user selects a series,
      // as the payload units are generally not human friendly

      if (_.isUndefined(this.state.get('rate').value)) {
        rate = sqDateTime.determineIdealUnits(payload.rate);
      }

      this.state.set('rate', rate);
    },

    /**
     * Sets the output units of the fft
     *
     * @param {Object} payload - Object container
     * @param {String} payload.outputUnits - The units to format the bucket labels with
     */
    setOutputUnits(payload) {
      this.state.set('outputUnits', payload.outputUnits);
    },

    /**
     * Sets the high pass filter
     *
     * @param {Object} payload - Object container
     * @param {String} payload.highPass - the high pass filter
     */
    setHighPass(payload) {
      this.state.set('highPass', payload.highPass);
    },

    /**
     * Sets the low pass filter
     *
     * @param {Object} payload - Object container
     * @param {String} payload.lowPass - the low pass filter
     */
    setLowPass(payload) {
      this.state.set('lowPass', payload.lowPass);
    },

    /**
     * Sets the use of the high pass filter
     * @param {Object} payload - Object container
     * @param {Bolean} payload.useHighPass - the flag for use of high pass filter
     */
    setUseHighPass(payload) {
      this.state.set('useHighPass', payload.useHighPass);
    },

    /**
     * Sets the use of the low pass filter
     *
     * @param {Object} payload - Object container
     * @param {Bolean} payload.useLowPass - the flag for use of low pass filter
     */
    setUseLowPass(payload) {
      this.state.set('useLowPass', payload.useLowPass);
    }
  };

  return sqBaseToolStore.extend(store, TREND_TOOLS.FFT_TABLE, {
    signalToAggregate: { predicate: ['name', 'signalToAggregate'] }
  });
}
