import _ from 'lodash';
import angular from 'angular';
import { AggregationBinStore } from '@/investigate/aggregationBins/aggregationBin.store';
import { AggregationBinActions } from '@/investigate/aggregationBins/aggregationBin.actions';
import { InvestigateHelperService } from '@/investigate/investigateHelper.service';
import { ITEM_TYPES } from '@/trendData/trendData.module';
import { CAPSULE_MODES } from '@/investigate/aggregationBins/capsuleMode.component';
import { ToolPanelHelperService } from '@/services/toolPanelHelper.service';

angular.module('Sq.Investigate')
  .controller('ByConditionCtrl', ByConditionCtrl);

function ByConditionCtrl(
  $scope: ng.IScope,
  sqAggregationBinActions: AggregationBinActions,
  sqAggregationBinStore: AggregationBinStore,
  sqInvestigateHelper: InvestigateHelperService,
  sqToolPanelHelper: ToolPanelHelperService
) {

  const vm = this;
  vm.ITEM_TYPES = ITEM_TYPES;
  vm.CAPSULE_MODES = CAPSULE_MODES;
  vm.setConditionProperty = sqAggregationBinActions.setConditionProperty;
  vm.setCondition = setCondition;
  vm.setCapsuleMode = sqAggregationBinActions.setCapsuleMode;
  vm.hasValidItemParameters = sqToolPanelHelper.hasValidItemParameters;

  // exposed for testing:
  vm.syncAggregationStore = syncAggregationStore;

  $scope.$listenTo(sqAggregationBinStore, syncAggregationStore);
  $scope.$watch('$ctrl.item', requestCapsuleProperties);

  /**
   * Syncs with the Aggregation Store.
   */
  function syncAggregationStore() {
    const params = _.find(sqAggregationBinStore.aggregationConfigs, ['id', vm.id]);
    _.assign(vm, _.pick(params, ['item', 'conditionProperty', 'capsuleMode']));
    vm.isUnbounded = _.isUndefined(_.get(vm, ['item', 'conditionMetadata', 'maximumDuration']));
  }

  /**
   * Requests all available properties for the selected condition. Requests will fail silently.
   */
  function requestCapsuleProperties(newItem) {
    if (vm.detectedPropertiesLoading || _.isEmpty(newItem)) {
      return;
    }

    vm.detectedPropertiesLoading = true;
    sqInvestigateHelper.requestCapsuleProperties(newItem.id, [])
      .then(detectedProperties => vm.detectedProperties = _.map(detectedProperties, 'name'))
      .finally(() => vm.detectedPropertiesLoading = false);
  }

  /**
   * Sets the selected condition in the store.
   *
   * @param {Object} item - the selected condition.
   */
  function setCondition(item) {
    sqAggregationBinActions.setAggregationItem(item, vm.id);
  }
}
