import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { TrackService } from '@/track/track.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';

const reportContentWorkstepBindings = bindingsDefinition({
  contentDiffers: prop<boolean>(),
  accessDenied: prop<boolean>(),
  canModifyWorkbook: prop<boolean>(),
  updateFromSourceWorksheet: prop<() => void>(),
  editSourceWorksheet: prop<any>(),
  sqTrack: injected<TrackService>()
});

export const ReportContentWorkstep: SeeqComponent<typeof reportContentWorkstepBindings> = (props) => {
  const { sqTrack } = useInjectedBindings(reportContentWorkstepBindings);
  const {
    contentDiffers,
    accessDenied,
    canModifyWorkbook,
    updateFromSourceWorksheet,
    editSourceWorksheet
  } = props;
  const { t } = useTranslation();

  const updateFromSourceWorksheetAndTrack = () => {
    sqTrack.doTrack('Topic', 'Seeq Link - Worksheet', 'update from worksheet');
    updateFromSourceWorksheet();
  };

  const editSourceWorksheetAndTrack = () => {
    sqTrack.doTrack('Topic', 'Seeq Link - Worksheet', 'edit source');
    editSourceWorksheet();
  };

  const contentSameDiv = !contentDiffers && !accessDenied &&
    <div className='sq-fairly-dark-gray text-center'>
      {t('REPORT.CONTENT.SAME')}
    </div>;

  const contentDiffersDiv = contentDiffers && !accessDenied &&
    <div className='sq-text-warning text-center'>
      {t('REPORT.CONTENT.DIFFERS')}
    </div>;

  const editDisabledDiv = !canModifyWorkbook &&
    <div className='sq-fairly-dark-gray text-center sq-text-warning mt5'>
      {t('REPORT.CONTENT.EDIT_DISABLED_TOOLTIP')}
    </div>;

  return <div className='flexRowContainer p10'>
    {contentSameDiv}
    {contentDiffersDiv}
    <button disabled={!contentDiffers} type='button' className='btn btn-default width-maximum mt5'
      onClick={updateFromSourceWorksheetAndTrack}>
      {t('REPORT.CONTENT.UPDATE')}
    </button>
    {editDisabledDiv}
    <button disabled={!canModifyWorkbook} type='button' className='btn btn-default width-maximum mt5'
      onClick={editSourceWorksheetAndTrack}>
      {t('REPORT.CONTENT.EDIT_WORKSHEET')}
    </button>
  </div>;
};

export const sqReportContentWorkstep = angularComponent(reportContentWorkstepBindings, ReportContentWorkstep);
