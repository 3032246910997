import angular from 'angular';
import _ from 'lodash';
import { UtilitiesService } from '@/services/utilities.service';
import { PluginActions } from '@/hybrid/plugin/plugin.actions';
import { generatePluginApi } from '@/hybrid/plugin/generatedPluginApi';

/**
 * @file Controller for plugin host
 */
angular.module('Sq.PluginHost').controller('DisplayPanePluginHostCtrl', DisplayPanePluginHostCtrl);

function DisplayPanePluginHostCtrl(
  $scope: ng.IScope,
  $element: JQuery,
  $injector: ng.auto.IInjectorService,
  sqUtilities: UtilitiesService,
  sqPluginActions: PluginActions) {

  const vm = this;

  vm.$scope = $scope;
  vm.isPresentationMode = sqUtilities.isPresentationWorkbookMode;
  vm.rangeEditingEnabled = !vm.isPresentationMode;
  vm.elementHeight = () => $element.height();
  vm.createApi = port => generatePluginApi(vm.plugin.identifier, $injector, port);

  vm.$onDestroy = onDestroy;
  vm.$onChanges = onChanges;
  sqPluginActions.setDisplayPaneRenderComplete(false);

  function onDestroy() {
    sqPluginActions.setDisplayPaneRenderComplete(true);
  }

  function onChanges() {
    vm.showDisplayRangeSelector = !_.get(vm.plugin, 'host.hideDisplayRangeSelector', false);
    vm.showInvestigateRangeSelector = !_.get(vm.plugin, 'host.hideInvestigateRangeSelector', false);
    vm.showBottomPanels = !_.get(vm.plugin, 'host.hideBottomPanels', false);
  }
}
