import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { ClearableInput } from '@/hybrid/core/ClearableInput.molecule';
import { useDebounce } from '@/hybrid/core/useDebounce.hook';
import { DEBOUNCE } from '@/main/app.constants';
import { LogTrackerService } from '@/hybrid/logTracker/LogTracker.service';
import { NotificationsService } from '@/services/notifications.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { APPEND_DIRECTION, LEVELS, LIMITS } from '@/hybrid/logTracker/LogTracker.constants';
import { UtilitiesService } from '@/services/utilities.service';

const logsFiltersBindings = bindingsDefinition({
  onShowModal: prop<(boolean) => void>(),
  onModifyFilters: prop<(object) => void>(),
  isFetching: prop<(boolean)>(),
  setFetching: prop<(boolean) => void>(),
  endTime: prop<(string)>(),
  setEndTime: prop<(string) => void>(),
  limit: prop<(number)>(),
  setLimit: prop<(number) => void>(),
  sqLogTrackerService: injected<LogTrackerService>(),
  sqNotifications: injected<NotificationsService>(),
  sqDateTime: injected<DateTimeService>(),
  sqUtilities: injected<UtilitiesService>()
});

export const LogTrackerFilters: SeeqComponent<typeof logsFiltersBindings> = (props) => {
  const { onShowModal, onModifyFilters, isFetching, setFetching, endTime, setEndTime, limit, setLimit } = props;
  const { t } = useTranslation();

  const {
    sqLogTrackerService,
    sqNotifications,
    sqDateTime,
    sqUtilities
  } = useInjectedBindings(logsFiltersBindings);

  const [isLogsPageLoaded, setIsLogsPageLoaded] = useState<boolean>(false);
  const [logsIds, setLogsIds] = useState<string[]>([]); // app-server
  const [logId, setLogId] = useState<string>('');
  const [source, setSource] = useState<string>(sqUtilities.getURIArgument(location.search, 'threadContains'));
  const [message, setMessage] = useState<string>(sqUtilities.getURIArgument(location.search, 'messageContains'));
  const [level, setLevel] = useState<string>(sqUtilities.getURIArgument(location.search, 'level', 'TRACE'));

  const selectInputStyle = {
    control: base => ({
      ...base,
      height: 32,
      minHeight: 32
    })
  };
  useEffect(() => {
    if (isLogsPageLoaded) {
      return;
    }
    getAvailableLogs();
  }, []);

  useEffect(() => {
    if (isLogsPageLoaded) {
      debounceFetchLogs();
    }
  }, [endTime, logId, limit, level]);

  /**
   * Retrieves a collection of available log files for querying.
   */
  const getAvailableLogs = () => {
    setIsLogsPageLoaded(true);
    setFetching(true);
    sqLogTrackerService.getAvailableLogs().then((logs: string[]) => {
      setLogsIds(logs);
      setLogId(sqUtilities.getURIArgument(location.search, 'log', _.head(logs)));
    }).catch(error => sqNotifications.apiError(error, { skipRedaction: true, displayForbidden: true }))
      .finally(() => {
        setFetching(false);
      });
  };

  /**
   * Update URL query based on filters
   */
  const updateUrlQuery = () => {
    const logIdEscaped = encodeURI(logId);
    const messageEscaped = encodeURI(message);
    const sourceEscaped = encodeURI(source);
    const endTimeEscaped = encodeURI(endTime);

    let filterUrlQuery = `/logs?log=${logIdEscaped}`;
    if (message.length > 0) {
      filterUrlQuery += `&messageContains=${messageEscaped}`;
    }
    if (source.length > 0) {
      filterUrlQuery += `&threadContains=${sourceEscaped}`;
    }
    if (endTime.length > 0) {
      filterUrlQuery += `&endTime=${endTimeEscaped}`;
    }
    filterUrlQuery += `&level=${level}`;
    filterUrlQuery += `&limit=${limit}`;
    history.replaceState(null, null, filterUrlQuery);
  };

  /**
   * Trigger fetch logs event.
   */
  const debounceFetchLogs = useDebounce(() => {
    const fetchOptions = {
      log: logId,
      startTime: '',
      endTime: sqDateTime.reformatZuluOffset(endTime),
      limit: _.toNumber(limit),
      level: _.toString(level),
      sourceContains: source,
      messageContains: message,
      appendToLogs: false,
      appendDirection: APPEND_DIRECTION.DOWN
    };
    updateUrlQuery();
    onModifyFilters(fetchOptions);
  }, DEBOUNCE.MEDIUM);

  return (
    <div id='specFilterContainer' data-testid='LogFiltersContainer'
      className='logFiltersContainer flexColumnContainer lightGreyBorderBottom p10'>
      <div className='flexRowContainer flexBasis250 pr10 min-width-60'>
        <label className='control-label flexFill'>{t('LOGS.LOG')}</label>
        <div data-testid='specLog_select'>
          <Select
            id='specLogName'
            classNamePrefix='spec_Log_filter react-select'
            value={{ value: logId, label: logId, logId }}
            options={_.map(logsIds, logId => ({ value: logId, label: logId, logId }))}
            isDisabled={isFetching}
            isSearchable={true}
            onChange={e => setLogId(e.value)}
            styles={selectInputStyle}
          />
        </div>
      </div>
      <div className='flexRowContainer pr10 flexBasis100 min-width-60'>
        <label className='control-label flexFill'>{t('LOGS.MIN_LEVEL')}</label>
        <div data-testid='specLogSeverity_select'>
          <Select
            id='specLogSeverity'
            classNamePrefix='spec_LogSeverity_filter react-select'
            value={{ value: level, label: level, level }}
            options={_.map(LEVELS, level => ({ value: level, label: level, level }))}
            isDisabled={isFetching}
            isSearchable={false}
            onChange={e => setLevel(e.value)}
            styles={selectInputStyle}
          />
        </div>
      </div>
      <div className='flexRowContainer pr10 flexFillOverflow min-width-125'>
        <label className='control-label'>{t('LOGS.SOURCE_CONTAINS')}</label>
        <div>
          <ClearableInput
            field='specThread'
            searchValue={source}
            disabled={isFetching}
            filterTable={(field, value) => setSource(value)}
            iconClassName='fa-search'
            onBlurCallback={debounceFetchLogs}
            enterCallback={debounceFetchLogs}
          />
        </div>
      </div>
      <div className='flexRowContainer pr10 flexFillOverflow min-width-125'>
        <label>{t('LOGS.MESSAGE_CONTAINS')}</label>
        <ClearableInput
          field='specMessage'
          searchValue={message}
          disabled={isFetching}
          filterTable={(field, value) => setMessage(value)}
          iconClassName='fa-search'
          onBlurCallback={debounceFetchLogs}
          enterCallback={debounceFetchLogs}
        />
      </div>
      <div className='flexRowContainer pr10 flexBasis200 min-width-125'>
        <label>{t('LOGS.END_TIME')}</label>
        <ClearableInput
          field='specEndTime'
          searchValue={endTime}
          disabled={isFetching}
          filterTable={(field, value) => setEndTime(value)}
          iconClassName='fa-search'
          onBlurCallback={debounceFetchLogs}
          enterCallback={debounceFetchLogs}
        />
      </div>
      <div className='flexRowContainer pr10 flexBasis100 min-width-60'>
        <label className='flexFill'>{t('LOGS.QUANTITY')}</label>
        <div data-testid='specLogLimit_select'>
          <Select
            id='specLogQuantity'
            classNamePrefix='spec_LogLimit_filter react-select'
            value={{ value: limit, label: limit, limit }}
            options={_.map(LIMITS, limit => ({ value: limit, label: limit, limit }))}
            isDisabled={isFetching}
            isSearchable={false}
            onChange={e => setLimit(e.value)}
            styles={selectInputStyle}
          />
        </div>
      </div>
      <div className='flexRowContainer flexBasis100'>
        <label className='flexFill'>&nbsp;</label>
        <button
          id='openModalButton'
          type='button'
          className='btn btn-primary btn-sm mr5 height-32'
          data-testid='openModal_button'
          onClick={() => onShowModal(true)}
          disabled={isFetching}>
          <span>{t('LOGS.SEND_LOGS')}</span>
        </button>
      </div>
    </div>
  );
};
