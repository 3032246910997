import _ from 'lodash';
import angular from 'angular';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { TrendScalarStore } from '@/trendData/trendScalar.store';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { INITIALIZE_MODE, PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';

angular.module('Sq.Treemap').store('sqTreemapStore', sqTreemapStore);

export type TreemapStore = ReturnType<typeof sqTreemapStore>['exports'];

function sqTreemapStore(
  sqTrendSeriesStore: TrendSeriesStore,
  sqTrendScalarStore: TrendScalarStore,
  sqTrendDataHelper: TrendDataHelperService
) {
  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.WORKSHEET,

    /**
     * Initializes the store by setting default values to the stored state
     */
    initialize(initializeMode) {
      const saveState = this.state && initializeMode !== INITIALIZE_MODE.FORCE;
      this.state = this.immutable({
        swap: {},
        parent: {},
        breadcrumbs: [],
        tree: [],
        isFetching: false,
        helpKey: undefined,
        statistics: [],
        priorityColors: saveState ? this.state.get('priorityColors') : [],
        neutralColor: saveState ? this.state.get('neutralColor') : undefined
      });
    },

    exports: {
      get swap() {
        return this.state.get('swap');
      },

      get parent() {
        return this.state.get('parent');
      },

      get breadcrumbs() {
        return this.state.get('breadcrumbs');
      },

      get tree() {
        return this.state.get('tree');
      },

      get helpKey() {
        return this.state.get('helpKey');
      },

      get statistics() {
        return this.state.get('statistics');
      },

      /**
       * Returns an array that is ordered by index of only those statistics that have both id and key.
       */
      get validStatistics() {
        return _.chain(this.state.get('statistics'))
          .reject(statistic => _.isNil(statistic.key) || _.isNil(statistic.id) ||
            _.isEmpty(sqTrendDataHelper.findItemIn([sqTrendSeriesStore, sqTrendScalarStore], statistic.id)))
          .sortBy(['index'])
          .value();
      },

      get priorityColors() {
        return this.state.get('priorityColors');
      },

      get neutralColor() {
        return this.state.get('neutralColor');
      }
    },

    /**
     * Dehydrates the item by retrieving the current set parameters in view.
     *
     * @returns {Object} An object with the state properties as JSON
     */
    dehydrate() {
      return _.pick(this.state.serialize(), ['parent', 'statistics']);
    },

    /**
     * Rehydrates the store from dehydrated state.
     *
     * @param {Object} dehydratedState - State object that should be restored
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      TREEMAP_SET_SWAP: 'setSwap',
      TREEMAP_SET_PARENT: 'setParent',
      TREEMAP_SET_BREADCRUMBS: 'setBreadcrumbs',
      TREEMAP_SET_TREE: 'setTree',
      TREEMAP_SET_HELP_KEY: 'setHelpKey',
      TREEMAP_SET_STATISTIC: 'setStatistic',
      TREEMAP_SET_COLORS: 'setColors',
      TREND_SWAP_ITEMS: 'swapItems'
    },

    /**
     * Sets the asset that is being swapped out. Invalidates the parent when it changes since a new swap out asset
     * means a new hierarchy.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.id - The ID of the asset
     * @param {String} payload.name - The name of the asset
     */
    setSwap(payload) {
      if (!_.isEmpty(this.state.get('swap')) && payload.id !== this.state.get('swap').id) {
        this.state.set('parent', {});
      }

      this.state.set('swap', payload);
    },

    /**
     * Sets the parent asset for swapping, the children underneath it will be displayed in the treemap. Since setting
     * the parent changes the breadcrumbs those are cleared.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.id - The ID of the asset
     */
    setParent(payload) {
      this.state.set('parent', payload);
      this.state.set('breadcrumbs', []);
    },

    /**
     * Sets the breadcrumbs that help the user navigate to the current parent.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} payload.breadcrumbs - An array of assets that lead up to the current parent.
     */
    setBreadcrumbs(payload) {
      this.state.set('breadcrumbs', payload.breadcrumbs);
    },

    /**
     * Sets the tree data for generating the treemap.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} payload.tree - An array of data for each asset.
     * @param {Object} payload.tree[].asset - The asset to which this result corresponds.
     * @param {String} payload.tree[].color - The color of the box. Should be one of the treemap colors.
     * @param {Number} payload.tree[].size - The relative box size.
     * @param {Boolean} payload.tree[].isLeafAsset - True if it is a leaf asset, false otherwise.
     */
    setTree(payload) {
      this.state.set('tree', payload.tree);
      this.state.set('helpKey', undefined);
    },

    /**
     * Sets the translation key that tells the user why the treemap cannot be loaded.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Boolean} payload.helpKey - A translation key that tells the user why the treemap can't be loaded.
     */
    setHelpKey(payload) {
      this.state.set('helpKey', payload.helpKey);
    },

    /**
     * Set a statistic to be displayed on each asset node.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Number} payload.index - Which statistic is being set
     * @param {String} [payload.id] - The id of the signal to use for calculating the statistic
     * @param {String} [payload.key] - One of the statistic keys from SAMPLE_FROM_SCALARS.VALUE_METHODS
     */
    setStatistic(payload) {
      this.state.set(['statistics', payload.index], payload);
    },

    /**
     * Sets the colors for the treemap priorities.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String[]} payload.colors - The list of colors, ordered by priority from highest to lowest, with the
     *   neutral color last.
     */
    setColors(payload) {
      this.state.set('priorityColors', _.initial(payload.colors));
      this.state.set('neutralColor', _.last(payload.colors));
    },

    /**
     * Updates the selected statistics when items in the details are asset swapped.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object} payload.swaps - The items that were swapped where the keys are the swapped out ids and the
     *   values are the corresponding swapped in ids.
     * @param {Object} payload.outAsset - Asset that was swapped out
     * @param {String} payload.outAsset.id - The ID of the asset to swapped out
     * @param {String} payload.inAsset.name - The name of the asset that was swapped out
     * @param {Object} payload.inAsset - Asset that was swapped in
     * @param {String} payload.inAsset.id - The ID of the asset that was swapped in
     * @param {String} payload.inAsset.name - The name of the asset that was swapped in
     */
    swapItems({ swaps }) {
      const statistics = this.state.get(['statistics']);
      _.forEach(statistics, (stat, index) => {
        if (swaps[stat.id]) {
          this.state.set(['statistics', index, 'id'], swaps[stat.id]);
        }
      });
    }
  };

  return store;
}
