export type ManualSignalActions = ReturnType<typeof sqManualSignalActions>;

export function sqManualSignalActions(flux: ng.IFluxService) {
  const service = {
    setInterpolationMethod,
    setMaximumInterpolation,
    setUom,
    addSample,
    removeSample,
    updateSample,
    setEditingSampleId,
    setSort
  };

  return service;

  function setInterpolationMethod(interpolationMethod) {
    flux.dispatch('MANUAL_SIGNAL_SET_INTERPOLATION_METHOD', { interpolationMethod });
  }

  function setMaximumInterpolation(maximumInterpolation) {
    flux.dispatch('MANUAL_SIGNAL_SET_MAXIMUM_INTERPOLATION', { maximumInterpolation });
  }

  function setUom(uom) {
    flux.dispatch('MANUAL_SIGNAL_SET_UOM', { uom });
  }

  function addSample(sample) {
    flux.dispatch('MANUAL_SIGNAL_ADD_SAMPLE', { sample });
  }

  function removeSample(id) {
    flux.dispatch('MANUAL_SIGNAL_REMOVE_SAMPLE', { id });
  }

  function updateSample(sample) {
    flux.dispatch('MANUAL_SIGNAL_UPDATE_SAMPLE', { sample });
  }

  function setEditingSampleId(editingSampleId) {
    flux.dispatch('MANUAL_SIGNAL_SET_EDITING_SAMPLE_ID', { editingSampleId });
  }

  function setSort(sort) {
    flux.dispatch('MANUAL_SIGNAL_SET_SORT', { sort });
  }
}
