import _ from 'lodash';
import angular from 'angular';
import jQuery from 'jquery';
import { UtilitiesService } from '@/services/utilities.service';

/**
 * Controller for the resizable panel component for the journal. Not yet completely reusable, but close and could be
 * made reusable in the future.
 */
angular.module('Sq.Annotation').controller('ResizablePanelCtrl', ResizablePanelCtrl);

function ResizablePanelCtrl(
  $scope: ng.IScope,
  $window: ng.IWindowService,
  sqUtilities: UtilitiesService) {
  const vm = this;

  vm.$onInit = onInit;
  vm.$onChanges = update;

  // Register for the $window resize event and unregister when the scope is destroyed
  angular.element($window).on('resize', compute);
  $scope.$on('$destroy', function() {
    angular.element($window).off('resize');
  });

  sqUtilities.preventIframeMouseEventSwallowForAngularResizable($scope);

  /**
   * Initializes the controller
   */
  function onInit() {
    vm.sizeStyle = {};
    $scope.$on('angular-resizable.resizeEnd', userResizePanel);
    $scope.$on('angular-resizable.resizing', userResizePanel);
    compute();
    update();
  }

  /**
   * Handler for user resize events.
   *
   * @param {Object} event - not used
   * @param {Object} args - object container
   * @param {Number} args.width - width of panel
   * @param {Number} args.height - height of panel
   */
  function userResizePanel(event, args) {
    $scope.$apply(function() {
      vm.tryWidth = args.width || vm.tryWidth;
      vm.tryHeight = args.height || vm.tryHeight;
      compute();
    });
  }

  /**
   * Updates the size style based on view model properties.
   */
  function update() {
    _.assign(vm.sizeStyle, { width: vm.width + 'px', height: vm.height + 'px' });
    _.assign(vm.sizeStyle, { 'min-width': +vm.minWidth + 'px', 'min-height': +vm.minHeight + 'px' });
    _.assign(vm.sizeStyle, { 'max-width': vm.maxWidth + 'px', 'max-height': vm.maxHeight + 'px' });
  }

  /**
   * Called when the browser window is resized. Constrains the panel width and height if it would be too big to fit.
   */
  function compute() {
    let width, height;

    if (!vm.isExpanded) {
      return;
    }

    vm.maxWidth = getMaxWidth();
    vm.maxHeight = getMaxHeight();

    width = Math.min(Math.max((vm.tryWidth || vm.width || +vm.defaultWidth), +vm.minWidth), vm.maxWidth);
    height = Math.min(Math.max((vm.tryHeight || vm.height), +vm.minHeight), vm.maxHeight);

    if (width !== vm.width) {
      vm.widthChanged({ width });
    }

    if (height !== vm.height) {
      vm.heightChanged({ height });
    }
  }

  /**
   * Returns the maximal possible width of the panel
   *
   * @returns {Number} a number representing the available max-width
   */
  function getMaxWidth() {
    const resizeableBoundingRect = getBoundingRect('.browseColumn');
    if (resizeableBoundingRect !== null) {
      return document.documentElement.clientWidth - resizeableBoundingRect.left;
    }

    return 0;
  }

  /**
   * Returns the maximal possible height of the Journal panel
   *
   * @returns {Number} a number representing the available max-height
   */
  function getMaxHeight() {
    let maxHeight;
    const resizeableBoundingRect = getBoundingRect('.browseColumn');
    const footerBoundingRect = getBoundingRect('.mainFooter');
    if (resizeableBoundingRect !== null && footerBoundingRect !== null) {
      maxHeight = footerBoundingRect.top - resizeableBoundingRect.top - 35;
      return Math.max(maxHeight, vm.minHeight);
    }

    return 0;
  }

  /**
   * Returns the bounding rectangle for a given selector
   *
   * @param {String} selector - the selector (id or class name of the element)
   * @returns {Object} - the bounding rectangle ({top, left, height, width})
   */
  function getBoundingRect(selector) {
    if (!_.isEmpty(jQuery(selector))) {
      return jQuery(selector)[0].getBoundingClientRect();
    }

    return null;
  }
}
