import _ from 'lodash';
import React from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { FormCheck, FormGroup } from 'react-bootstrap';

export enum DailyScheduleType {
  EVERY_DAY = 'daily',
  EVERY_WEEKDAY = 'weekdays'
}

const SCHEDULE_TYPE_TRANSLATIONS = {
  [DailyScheduleType.EVERY_DAY]: 'REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.DAILY.EVERY_DAY',
  [DailyScheduleType.EVERY_WEEKDAY]: 'REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.DAILY.WEEKDAYS'
};

interface DailyScheduleBindings {
  selectedType: DailyScheduleType;
  onSelect: (DailyScheduleType) => void;
}

export const isDailyScheduleValid = (dailyScheduleType?: DailyScheduleType) =>
  _.includes(Object.values(DailyScheduleType), dailyScheduleType);

/**
 * Selector for day frequency
 */
export const DailySchedule: React.FunctionComponent<DailyScheduleBindings> = (props) => {
  const { selectedType, onSelect } = props;
  const { t } = useTranslation();

  return (
    <FormGroup data-testid="daily-schedule-config">
      {_.map(Object.entries(SCHEDULE_TYPE_TRANSLATIONS), ([type, translation]) => {
        const id = `schedule-type-${type}`;
        const isCurrent = type === selectedType;
        return <FormCheck
          id={id}
          data-testid={id}
          key={type}
          type="radio"
          label={t(translation)}
          checked={isCurrent}
          onChange={() => !isCurrent && onSelect(type)} />;
      })}
    </FormGroup>
  );
};
