import _ from 'lodash';
import angular from 'angular';
import jQuery from 'jquery';
import template from './treemapChart.directive.html';
import tinycolor from 'tinycolor2';
import { D3Service } from '@/services/d3.service';
import { TreemapActions } from '@/treemap/treemap.actions';
import { TrendActions } from '@/trendData/trend.actions';
import { TreemapStore } from '@/treemap/treemap.store';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { NumberHelperService } from '@/core/numberHelper.service';
import { DEBOUNCE } from '@/main/app.constants';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.module';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';

angular.module('Sq.Treemap').directive('sqTreemapChart', sqTreemapChart);

function sqTreemapChart(
  $translate: ng.translate.ITranslateService,
  sqD3: D3Service,
  sqTreemapActions: TreemapActions,
  sqTrendActions: TrendActions,
  sqTrendDataHelper: TrendDataHelperService,
  sqTreemapStore: TreemapStore,
  sqWorksheetActions: WorksheetActions,
  sqNumberHelper: NumberHelperService) {
  const NODE_PADDING = 21;
  const MIN_FONT_SIZE = 8;
  return {
    restrict: 'E',
    template,
    scope: {
      tree: '<'
    },
    link: linker
  };

  function linker($scope, $element) {
    const $chart = angular.element($element.children().get(0));
    $scope.onResize = _.debounce(onResize, DEBOUNCE.MEDIUM); // Debounced to avoid a bounce when switching tools
    $scope.$watch('tree', _.debounce(draw, DEBOUNCE.MEDIUM));

    /**
     * Called whenever the chart is resized. Dispatches the width of the chart so that the auto-update calculation
     * works.
     */
    function onResize() {
      sqTreemapActions.setDisplayPixels($chart.width());
      draw();
    }

    /**
     * Draws the treemap using HTML instead of SVG. Called whenever the container size or data changes.
     */
    function draw() {
      let root, div, divs;
      let stats, treemap;
      $chart.empty();

      if (_.isEmpty($scope.tree)) {
        return;
      }

      root = { name: 'root', children: _.cloneDeep($scope.tree) };
      div = sqD3.select($chart.get(0))
        .append('div')
        .style('position', 'relative')
        .attr('class', 'flexFill');

      treemap = sqD3.layout.treemap()
        .size([$chart.width(), $chart.height()])
        // Largest in the top-left and sort those with identical size by name
        .sort((a: any, b: any) => a.value - b.value || b.asset.name.localeCompare(a.asset.name))
        .value(_.property('size'));

      divs = div.datum(root)
        .selectAll('.node')
        .data(treemap.nodes, _.property('asset.id'))
        .enter()
        .append('div')
        .filter(d => d.name !== 'root')
        .attr('class', d => 'node cursorPointer ' + (d.isUncertain ? 'uncertain' : ''))
        .call(function position() {
          this.style('left', d => d.x + 'px')
            .style('top', d => d.y + 'px')
            .style('width', d => Math.max(0, d.dx - 1) + 'px')
            .style('height', d => Math.max(0, d.dy - 1) + 'px');
        })
        .on('click', function(d) {
          (<any>sqD3.event).preventDefault();
          $scope.$apply(function() {
            if (d.isLeafAsset) {
              // Called with fetchAll=false here because `swapAssets` will call fetchAll for us
              sqWorksheetActions.setView(WORKSHEET_VIEW.TREND, false);
              const swapOutItemIds = _.chain(sqTrendDataHelper.getAllItems())
                .uniqBy('id')
                .map('id')
                .value();
              const swapPairs =
                _.mapValues(_.keyBy(swapOutItemIds), () => [{ swapOut: sqTreemapStore.swap.id, swapIn: d.asset.id }]);
              sqTrendActions.swapAssets(swapPairs);
            } else {
              sqTreemapActions.setParent(d.asset);
            }
          });
        })
        .style('background-color', _.property('color'));

      divs.append('h4')
        .text(_.property('asset.name'))
        .each(function(d) {
          let fontSize;
          const containerWidth = d.dx - NODE_PADDING;
          d.textColor = tinycolor(d.color).isLight() ? '#000' : '#fff';
          if (jQuery(this).width() >= containerWidth) {
            fontSize = parseInt(jQuery(this).css('font-size'), 10);
            while (jQuery(this).width() >= containerWidth && fontSize > MIN_FONT_SIZE) {
              jQuery(this).css('font-size', --fontSize);
            }

            d.statsFontSize = Math.max(MIN_FONT_SIZE, fontSize - 2) + 'px';
          }
        })
        .style('color', _.property('textColor'));

      stats = divs
        .filter(d => !_.isEmpty(d.displayScalars))
        .append('div')
        .attr('class', 'cursorDefault')
        .style('color', _.property('textColor'))
        .style('font-size', _.property('statsFontSize'))
        .on('click', () => (<any>sqD3.event).stopPropagation())
        .selectAll('.stat')
        .data(d => sqD3.nest().key(_.property('signal')).entries(d.displayScalars))
        .enter()
        .append('div')
        .attr('class', 'stat');

      stats.append('strong')
        .text(_.property('key'));

      stats.selectAll('div')
        .data(_.property('values'))
        .enter()
        .append('div')
        .attr('class', 'pl10')
        .text(d => `${$translate.instant(d.title)}: ${sqNumberHelper.formatNumber(d.value)} ${d.uom}`);
    }
  }
}
