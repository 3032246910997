import React, { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { SelectItem } from '@/hybrid/core/SelectItem.organism';
import { Field, useForm } from 'react-final-form';
import { getFormFieldProps, getValidationFunction } from '@/hybrid/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { FormError } from '../core/FormError.atom';

export interface ItemSelectIF extends ValidatingFormComponent<{ id: string } | string> {
  component: 'ItemSelectFormComponent';
  label?: string;
  /** true if the select can be toggled between a text input field and a select */
  textEntryAllowed?: boolean;
  textPlaceholder?: string;
  /** if true then only text input will be displayed - so this only applies if textEntryAllowed is set to true */
  textToggleDisabled?: boolean;
  selectPlaceholder?: string;
  insideModal?: boolean;
  excludeWorkbookItems?: boolean;
  /** true does not automatically select even if there's only one item */
  disableAutoSelect?: boolean;
  includeMetadata?: boolean;
  excludeStringSignals?: boolean;
  isMultipleSelect?: boolean;
  excludedIds?: string[];
  additionalItems?: object[];
  /** to limit items to only certain item types */
  itemTypes?: string[];
  /** index used to set the threshold in scorecard */
  thresholdLevel?: string;
  /** used by formula, the name */
  identifier?: string;
  /** used by formula, index */
  paramIndex?: string;
  items?: object [];
  allowClear?: boolean;
  /** Determines if the select element is allowed to wrap when the name is long */
  noWrap?: boolean;
  value: { id: string } | string;
  onChange?: ((selectedItemOrValue: { id: string } | string, level?: number | string) => void);
  onRemove?: (selectedItem: object) => void;
  customErrorText?: string;
}

export const ItemSelectFormComponent: React.FunctionComponent<ItemSelectIF> = (props) => {
  const {
    name,
    validation,
    label,
    testId,
    value,
    textPlaceholder,
    selectPlaceholder,
    extendValidation,
    extraClassNames,
    customErrorText,
    isMultipleSelect
  } = props;
  const { t } = useTranslation();
  const defaultValidation = value => _.isEmpty(value) && value?.id !== '';
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const form = useForm();
  const formState = form.getState();
  const [showError, setShowError] = useState(false);

  const resetField = () => {
    form.change(name, undefined);
    form.resetFieldState(name);
  };

  const getSelectedItem = (value: any, inputValue: any) => {
    // in some cases the value is a number, in some cases it's an id and sometimes it's an object - so we do this
    return value !== '' && inputValue !== ''
      ? _.isObject(inputValue)
        ? inputValue?.id
        : inputValue
      : value;
  };

  return (
    <div className={classNames('flexFill mb8', extraClassNames)} data-testid={testId} id={name}>
      {label && <div className="pb5">{t(label)}</div>}
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const selectedValue = isMultipleSelect
            ? _.map(value, (item, index) =>
                getSelectedItem(item, input.value[index])
              )
            : getSelectedItem(value, input.value);
          const formFieldProps = getFormFieldProps(formState, input, meta, props);
          setTimeout(() => setShowError(formFieldProps.showError), 0);

          return (
            <SelectItem {...formFieldProps}
              onSelect={(item: string | { id: string }, level?: number | string) => {
                props.onChange(item, level);
                input.onChange(item);
              }}
              selectedItemId={selectedValue}
              selectPlaceholder={selectPlaceholder}
              textPlaceholder={textPlaceholder}
              onToggle={() => resetField()}
              isMultipleSelect={isMultipleSelect}
            />);
        }}
      </Field>
      {showError && customErrorText && (
        <FormError errorText={customErrorText} dataTestId={`${testId}Error`} extraClassNames='pt5' />
      )}
    </div>);
};
