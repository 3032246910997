import React from 'react';
import _ from 'lodash';

interface AssetGroupCellDebugInterface {
  formula: string;
  parameters: { item: { id: string } }[];
}

export const AssetGroupCellDebug: React.FunctionComponent<AssetGroupCellDebugInterface> = ({ formula, parameters }) => (
  <div className="flexRowContainer">
    <span>{formula}</span><br />
    {_.map(parameters, param => <span key={`debugParam_${param?.item?.id}`}>{param?.item?.id}</span>)}
  </div>
);

