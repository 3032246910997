import angular from 'angular';
import _ from 'lodash';
import { TrendActions } from '@/trendData/trend.actions';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { InvestigateStore } from '@/investigate/investigate.store';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { UtilitiesService } from '@/services/utilities.service';
import { ItemsApi } from '@/sdk';

angular.module('Sq.Investigate').controller('DerivedDataTreeCtrl', DerivedDataTreeCtrl);

function DerivedDataTreeCtrl(
  $scope: ng.IScope,
  sqInvestigateActions: InvestigateActions,
  sqInvestigateStore: InvestigateStore,
  sqTrendActions: TrendActions,
  sqUtilities: UtilitiesService,
  sqItemsApi: ItemsApi
) {
  const vm = this;

  vm.loading = true;
  vm.isViewMode = sqUtilities.isViewOnlyWorkbookMode;
  vm.openItemProperties = openItemProperties;
  vm.loadToolForEdit = sqInvestigateActions.loadToolForEdit;

  $scope.$listenTo(sqInvestigateStore, ['derivedDataTree'], updateInvestigateStoreVars);

  function updateInvestigateStoreVars() {
    vm.loading = _.isNil(sqInvestigateStore.derivedDataTree);
    vm.derivedDataTree = sqInvestigateStore.derivedDataTree;
  }

  /**
   * Add the specified item to the trend and open it on the Item Properties pane
   *
   * @param {String} id - ID of the item to open
   */
  function openItemProperties({ id }) {
    sqItemsApi.getItemAndAllProperties({ id })
      .then(({ data: item }) => sqTrendActions.addItem(item))
      .then(() => {
        sqInvestigateActions.setActiveTool(TREND_TOOLS.PROPERTIES);
        sqInvestigateActions.setItem(id);
      });
  }
}
