/* istanbul ignore file */

import React from 'react';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ArrowlessDropdownButton from '@/hybrid/annotation/ckEditorPlugins/views/ArrowlessDropdownButton';
import { BasePluginDependencies } from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { PluginDependencies } from '@/hybrid/annotation/ckEditorPlugins/plugins/PluginDependencies';
import icon from '@/hybrid/reportEditor/ckIcons/ckeditor5-seeq-link.svg';
import {
  CK_DROPDOWN_BUTTON_CLASS,
  elementWithRoot,
  toggleCkResetClass
} from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import { JournalLink as JournalLinkMolecule } from '@/hybrid/annotation/JournalLink.molecule';

export class JournalLink extends Plugin {
  static pluginName = 'JournalLink';
  static setup = {
    name: JournalLink.pluginName,
    plugin: JournalLink,
    toolbar: JournalLink.pluginName
  };

  init() {
    this.defineToolbarButton();
  }

  defineToolbarButton() {
    let dropdown;
    const editor = (this as any).editor;
    const onLink = (link) => {
      if (editor.model.document.selection.isCollapsed) {
        const viewFragment = editor.data.processor.toView(link);
        const modelFragment = editor.data.toModel(viewFragment);
        editor.model.insertContent(modelFragment);
      } else {
        editor.execute('link', link.match('href="(.*)">')[1]);
      }
      dropdown.panelView.set('isVisible', false);
    };

    editor.ui.componentFactory.add(JournalLink.pluginName, (locale) => {
      dropdown = createDropdown(locale, ArrowlessDropdownButton);
      const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);

      dropdown.buttonView.set({
        label: deps.$translate.instant('REPORT.EDITOR.CONTENT_BUTTON_TITLE'),
        icon,
        tooltipPosition: 'e',
        tooltip: true,
        class: 'contentBtn'
      });

      dropdown.panelView.on('render', (eventInfo) => {
        elementWithRoot(deps, eventInfo.source.element, <JournalLinkMolecule onLink={onLink} />);
      });

      dropdown.panelView.on('change:isVisible', () => toggleCkResetClass());

      dropdown.class = CK_DROPDOWN_BUTTON_CLASS;

      return dropdown;
    });
  }
}
