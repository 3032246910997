import React, { useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { Icon } from '@/hybrid/core/Icon.atom';
import { findChild, getCellIcon, getCellIconType } from '@/hybrid/assetGroupEditor/assetGroup.utilities';
import { AssetGroupCellDebug } from '@/hybrid/assetGroupEditor/AssetGroupCellDebug.atom';
import { AssetDetailModal } from '@/hybrid/assetGroupEditor/AssetDetailModal';
import { AssetFinderModal } from '@/hybrid/assetGroupEditor/AssetFinderModal';
import { CalculationDetailModal } from '@/hybrid/assetGroupEditor/CalculationDetailModal';
import { TrackService } from '@/track/track.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';

const assetGroupCellBindings = bindingsDefinition({
  asset: prop<{}>(),
  columnName: prop<string>(),
  debug: prop.optional<boolean>(),
  sqTrack: injected<TrackService>()
});

export const AssetGroupCell: SeeqComponent<typeof assetGroupCellBindings> = ({
  asset,
  columnName,
  debug = false
}) => {
  type ModalType = 'details' | 'finder' | 'none';
  const [modalConfig, setModalConfig] = useState({ modalShown: 'none', returnToOnClose: 'none' });
  const [currentItem, setCurrentItem] = useState(
    { asset: { name: undefined, id: undefined, itemId: undefined }, columnName: '' });
  const { sqTrack } = useInjectedBindings(assetGroupCellBindings);

  const child = findChild(asset, columnName);
  const icon = getCellIcon(child);
  const type = getCellIconType(child);
  const color = type === 'color' ? '#30ad8e' : undefined;

  const openItemFinder = (asset, columnName) => {
    setCurrentItem({ asset, columnName });
    setModalConfig({ modalShown: 'finder', returnToOnClose: 'none' });
  };

  const openCalculationDetails = (asset, columnName) => {
    sqTrack.doTrack('Asset Group Editor', 'Calculation Details Modal', 'Opened');
    setCurrentItem({ asset, columnName });
    setModalConfig({ modalShown: 'calculationDetails', returnToOnClose: 'none' });
  };

  const openDetails = (asset, columnName) => {
    sqTrack.doTrack('Asset Group Editor', 'Details Modal', 'Opened');
    setCurrentItem({ asset, columnName });
    setModalConfig({ modalShown: 'details', returnToOnClose: 'none' });
  };

  const closeModal = () => {
    const properModal = modalConfig.returnToOnClose !== 'none' ? modalConfig.returnToOnClose : 'none';
    setModalConfig({ modalShown: properModal, returnToOnClose: 'none' });
  };

  // avoid returning to details panel if item is assigned
  const closeAfterAssignment = () => {
    setModalConfig({ modalShown: 'none', returnToOnClose: 'none' });
  };

  const closeCurrentModalAndOpenAnother = (modalToOpen: ModalType, returnToOnClose: ModalType) => {
    setModalConfig({ modalShown: modalToOpen, returnToOnClose });
  };

  const getCellIconClickHandler = (asset, columnName, child) => {
    if (child?.columnType === 'Calculation') {
      openCalculationDetails(asset, columnName);
    } else if (!child || (!child.id && !child.itemId)) {
      openItemFinder(asset, columnName);
    } else {
      openDetails(asset, columnName);
    }
  };

  return (
    <>
      <Icon
        testId={`assetGroupCell_${columnName}`}
        icon={icon}
        type={type}
        color={color}
        extraClassNames="cursorPointer"
        large={true}
        onClick={() => getCellIconClickHandler(asset, columnName, child)} />
      {debug && <AssetGroupCellDebug formula={child?.formula} parameters={child?.parameters} />}


      {modalConfig.modalShown === 'details' &&
      <AssetDetailModal
        testId="assetDetailsModal"
        item={findChild(currentItem.asset, currentItem.columnName)}
        asset={currentItem.asset}
        swapItem={() => closeCurrentModalAndOpenAnother('finder', 'details')}
        onClose={closeModal} />
      }

      {modalConfig.modalShown === 'finder' &&
      <AssetFinderModal
        testId="assetFinderModal"
        asset={currentItem.asset}
        onCloseAfterAssignment={closeAfterAssignment}
        columnName={currentItem.columnName}
        onClose={closeModal} />
      }

      {modalConfig.modalShown === 'calculationDetails' &&
      <CalculationDetailModal
        testId="calculationDetailModal"
        item={findChild(currentItem.asset, currentItem.columnName)}
        asset={currentItem.asset}
        columnName={currentItem.columnName}
        onClose={closeModal} />
      }
    </>
  );
}
;

