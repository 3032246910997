import React, { useEffect, useState } from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';

const pluginHostBindings = bindingsDefinition({
  entryPoint: prop<string>(),
  createApi: prop<(messagePort: MessagePort) => { destroy: () => void }>()
});

export const PluginHost: SeeqComponent<typeof pluginHostBindings> = (props) => {
  const { entryPoint, createApi } = props;
  const [api, setApi] = useState<ReturnType<typeof createApi>>();

  useEffect(() => {
    return () => api && api.destroy();
  }, [api]);

  return (
    <iframe 
      src={entryPoint} 
      sandbox="allow-scripts" 
      className="pluginContainer" 
      onLoad={(event) => {
        const iframe = event.target as HTMLIFrameElement;
        const { port1, port2 } = new MessageChannel();
        setApi(createApi(port1));
        iframe.contentWindow.postMessage('init', '*', [port2]);
      }} 
    />
  );
};

export const sqPluginHost = angularComponent(pluginHostBindings, PluginHost);
