import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

interface WizardModalProps {
  onClose: () => void;
  testId: string;
  stepDefinitions: {
    component: any,
    title: string,
    // use customHeader if provided otherwise put title as a header
    customHeader?: any,
    actionButtonLabel?: string,
    actionFn?: () => void,
    actionButtonId?: string,
    backButtonFn?: () => void,
    // option to progress to the next state without having to click next
    shouldGoToNext?: { canGoToNext: boolean, showActionButton: boolean }
    valid?: boolean,
    modalClassName?: string
  }[];
}

export const WizardModal: React.FunctionComponent<WizardModalProps> = ({
  testId,
  onClose,
  stepDefinitions
}) => {
  const defaultModalSize = 'min-width-600';
  const { t } = useTranslation();
  const [modalClassName, setModalClassName] = useState(defaultModalSize);
  const defaultSubmitBtnLabel = 'NEXT';
  const defaultSubmitBtnId = 'goToNext';

  const goToNextStep = () => {
    if (currentStep < _.size(stepDefinitions) - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const performActionAndGoToNextStep = (actionFn) => {
    return Promise.resolve()
      .then(actionFn)
      .then(goToNextStep);
  };

  const submitBtnDefaults = {
    label: defaultSubmitBtnLabel,
    id: defaultSubmitBtnId,
    onClick: goToNextStep,
    disabled: false
  };

  const [submitBtnDetails, setSubmitBtnDetails] = useState(submitBtnDefaults);
  const [currentStep, setCurrentStep] = useState(0);

  const goToPreviousStep = () => {
    if (_.isFunction(stepDefinitions[currentStep]?.backButtonFn)) {
      stepDefinitions[currentStep].backButtonFn();
    }
    setCurrentStep(currentStep - 1);
  };

  const renderCurrentComponent = () => {
    return stepDefinitions[currentStep].component;
  };

  useEffect(() => {
    if (stepDefinitions[currentStep]?.shouldGoToNext?.canGoToNext) {
      goToNextStep();
    }
  }, [stepDefinitions[currentStep]?.shouldGoToNext?.canGoToNext]);

  useEffect(() => {
    const currentStepDetails = stepDefinitions[currentStep];
    const submitBtnDetails = { ...submitBtnDefaults, onClick: goToNextStep };
    if (currentStepDetails.actionButtonLabel) {
      _.assign(submitBtnDetails, { label: currentStepDetails.actionButtonLabel });
    }
    if (currentStepDetails.actionFn) {
      _.assign(submitBtnDetails, {
        onClick: () => {
          performActionAndGoToNextStep(currentStepDetails.actionFn);
        }
      });
    }
    if (_.has(currentStepDetails, 'valid')) {
      _.assign(submitBtnDetails, { disabled: !currentStepDetails.valid });
    }
    if (_.has(currentStepDetails, 'modalClassName')) {
      setModalClassName(currentStepDetails.modalClassName);
    } else {
      setModalClassName(defaultModalSize);
    }
    setSubmitBtnDetails(submitBtnDetails);
  }, [currentStep, stepDefinitions]);

  return <Modal show={true} onHide={onClose} animation={false} data-testid={testId} dialogClassName={modalClassName}>
    <Modal.Header closeButton={true}>
      {stepDefinitions[currentStep]?.customHeader ??
      <Modal.Title> {t(stepDefinitions[currentStep]?.title)} </Modal.Title>}
    </Modal.Header>

    <Modal.Body>
      {renderCurrentComponent()}
    </Modal.Body>

    <Modal.Footer className="flexColumnContainer mt5">
      <div className="flexRowContainer flexFill">
        {currentStep > 0 &&
        <TextButton
          onClick={goToPreviousStep}
          label="BACK"
          size="sm"
          testId="backButton"
          extraClassNames="mr20 min-width 100 width-100 max-width-100" />}
      </div>

      <div className="flexColumnContainer flexAlignEnd">
        <TextButton
          onClick={onClose}
          label="CANCEL"
          size="sm"
          testId="cancelButton"
          extraClassNames="mr20 min-width 100 width-100 max-width-100" />
        {(stepDefinitions[currentStep]?.shouldGoToNext?.showActionButton ?? true) &&
        <TextButton
          onClick={submitBtnDetails.onClick}
          label={submitBtnDetails.label}
          disabled={submitBtnDetails.disabled}
          size="sm"
          testId="executeButton"
          extraClassNames="min-width 100 btn-primary"
          id={submitBtnDetails.id}
          variant="theme" />}
      </div>
    </Modal.Footer>
  </Modal>;
};

