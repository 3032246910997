import React, { useEffect, useState } from 'react';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { Card, OverlayTrigger, Popover } from 'react-bootstrap';
import _ from 'lodash';
import Select from 'react-select';
import SelectIdentity from '@/hybrid/core/SelectIdentity.molecule';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { IdentityPreviewV1, ItemsApi } from '@/sdk';
import moment from 'moment-timezone';
import { DateTimeEntry } from '@/hybrid/core/DateTimeEntry.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import SelectItemWrapper from '@/hybrid/core/SelectItemWrapper.organism';
import { UtilitiesService } from '@/services/utilities.service';
import { TrackService } from '@/track/track.service';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { FormBuilder } from '@/hybrid/formbuilder/FormBuilder.page';
import { ChangeTypeEnum } from 'sdk/model/AuditOutputV1';
import { SearchParametersIF } from '@/hybrid/auditTrail/AuditTrail.page';
import { ItemsFromWorksheetModal } from '@/hybrid/auditTrail/ItemsFromWorksheetModal.molecule';
import { NotificationsService } from '@/services/notifications.service';
import { FormulaService } from '@/services/formula.service';

const itemTypesCondensed = [
  { key: 'ITEM_TYPES.ASSET', types: [SeeqNames.Types.Asset] },
  { key: 'ITEM_TYPES.CONFIGURATION_OPTION', types: ['ConfigurationOption'] },
  {
    key: 'ITEM_TYPES.CONDITION',
    types: [SeeqNames.Types.CalculatedCondition, SeeqNames.Types.StoredCondition, SeeqNames.Types.Condition]
  },
  { key: 'ITEM_TYPES.BASE_CONFIGURATION', types: [SeeqNames.Types.Agent, SeeqNames.Types.Connector] },
  { key: 'ITEM_TYPES.CHART', types: [SeeqNames.Types.Chart, SeeqNames.Types.AggregatingFormulaFunction] },
  { key: 'ITEM_TYPES.THRESHOLD_METRIC', types: [SeeqNames.Types.ThresholdMetric] },
  { key: 'SCALAR', types: [SeeqNames.Types.CalculatedScalar] },
  {
    key: 'ITEM_TYPES.SIGNAL',
    types: [SeeqNames.Types.CalculatedSignal, SeeqNames.Types.StoredSignal, SeeqNames.Types.Signal]
  },
  { key: 'ITEM_TYPES.USER', types: [SeeqNames.Types.User] },
  {
    key: 'ITEM_TYPES.USER_DEFINED_FUNCTION', types: [SeeqNames.Types.FormulaDoc, SeeqNames.Types.FormulaPackageDoc,
      SeeqNames.Types.FormulaFunction, SeeqNames.Types.UserDefinedFormulaFunction, SeeqNames.Types.FormulaPackage,
      SeeqNames.Types.FormulaPackageDoc]
  },
  { key: 'ITEM_TYPES.USER_GROUP', types: [SeeqNames.Types.UserGroup] }
];

const SELECT_ALL = 'ALL';

const auditTableSearchPanelBindings = bindingsDefinition({
  searchCallback: prop<(searchParameters: SearchParametersIF) => void>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqItemsApi: injected<ItemsApi>(),
  sqFormula: injected<FormulaService>(),
  sqUtilities: injected<UtilitiesService>(),
  sqTrack: injected<TrackService>(),
  sqNotifications: injected<NotificationsService>()
});

export const AuditTableSearchPanel: SeeqComponent<typeof auditTableSearchPanelBindings> = (props) => {
  const { searchCallback } = props;
  const {
    sqWorksheetStore,
    sqItemsApi,
    sqFormula,
    sqUtilities,
    sqTrack
  } = useInjectedBindings(auditTableSearchPanelBindings);

  const { t } = useTranslation();
  const [start, setStart] = useState(moment().utc().subtract(1, 'hour').valueOf());
  const [end, setEnd] = useState(moment.utc().valueOf());
  const [itemId, setItemId] = useState('');
  const [items, setItems] = useState([]);
  const [itemsFromWorksheet, setItemsFromWorksheet] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [changeTypes, setChangeTypes] = useState<ChangeTypeEnum[]>([]);
  const [userIdentities, setUserIdentities] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const { timezone } = useFlux(sqWorksheetStore);
  const [showModal, setShowModal] = useState(false);

  const itemTypeOptions = _.map(itemTypesCondensed, ({ key }) => ({ value: key, label: t(key), key }));

  useEffect(() => {
    setUserIds(_.map(userIdentities, 'id'));
  }, [userIdentities]);

  useEffect(() => {
    if (!!itemsFromWorksheet) {
      setSelectedItems(_.uniq(selectedItems.concat(_.map(itemsFromWorksheet, 'id'))));
      setItems(_.chain(itemsFromWorksheet)
        .reject(item => !_.chain(itemTypesCondensed)
          .flatMap('types')
          .includes(item.type)
          .value())
        .concat(items)
        .uniqBy('id')
        .value());
    }
  }, [itemsFromWorksheet]);

  const fetchItems = () => {
    const id = itemId.toUpperCase();
    if (_.includes(selectedItems, id)) {
      setItemId('');
      return;
    } else if (_.some(items, { id })) {
      setSelectedItems(selectedItems.concat(id));
      setItemId('');
      return;
    }

    Promise.all([sqItemsApi.getItemAndAllProperties({ id }), sqFormula.getDependencies({ id })])
      .then(([{ data: item }, dependencies]) => {
        setSelectedItems(selectedItems.concat(item.id));
        setItems(items.concat({
          id: item.id,
          name: item.name,
          type: item.type,
          assets: dependencies.assets
        }));
      })
      .catch(() => {
        if (sqUtilities.validateGuid(id)) {
          setSelectedItems(selectedItems.concat(id));
          setItems(items.concat({ id, name: id }));
        }
      })
      .finally(() => setItemId(''));
  };

  const searchAndTrack = () => {
    sqTrack.doTrack('AuditTrail', 'Search Panel', 'Search clicked', true);
    searchCallback({
      range: { start, end },
      itemIds: selectedItems,
      userIds,
      itemTypes: _.chain(itemTypes)
        .map('value')
        .flatMap(key => _.find(itemTypesCondensed, { key }).types)
        .value(),
      changeTypes: _.map(changeTypes, 'value')
    });
  };

  const itemsOnChange = (selected: { value }[]) => {
    _.isEmpty(selected) ? setSelectedItems([]) : setSelectedItems(selectedItems.concat(_.last(selected).value.id));
  };

  const itemsOnRemove = (e) => {
    setSelectedItems(_.without(selectedItems, e.id));
  };

  const validateItemId = id => !_.isEmpty(id) && !sqUtilities.validateGuid(id);

  const selectItemTypes = (newSelectedType) => {
    if (_.isNil(newSelectedType)) {
      setItemTypes([]);
    } else if (newSelectedType.value === SELECT_ALL) {
      setItemTypes(itemTypeOptions);
    } else {
      setItemTypes(newSelectedType);
    }
  };

  const itemIdForm: FormElement[] = [{
    component: 'FormGroup',
    name: 'itemIdForm',
    extraClassNames: 'flexAlignCenter',
    testId: 'itemIdForm',
    components: [{
      component: 'FormRow',
      name: 'itemIdInputRow',
      testId: 'itemIdInputRow',
      components: [{
        component: 'FormControlFormComponent',
        placeholder: 'AUDIT_TRAIL.SEARCH_ITEMS_BY_ID',
        name: 'itemIdInput',
        className: 'width-250 mr5 ml5 mt10',
        validation: validateItemId,
        customError: 'AUDIT_TRAIL.SEARCH_ERROR',
        value: itemId,
        onChange: (value: string) => setItemId(value),
        size: 'md',
        testId: 'itemIdInputBox'
      }]
    }]
  }];

  const helpIconPopover = (
    <Popover id='itemsIdPopover' className="p10 advancedPopover" bsPrefix='greyPopover'>
      <span className="width-250">{t('AUDIT_TRAIL.HELP')}</span>
      <a href="https://telemetry.seeq.com/support-link/wiki/spaces/KB/pages/740851908" target="_blank">
        {t('KNOWLEDGE_BASE')}
      </a>.
    </Popover>
  );

  const itemsPopover = (<Popover id='itemsIdPopover' className="advancedPopover p10">
    <FormBuilder
      formDefinition={itemIdForm}
      submitFn={() => fetchItems()}
      closeFn={_.noop}
      hideCancel={true}
      wrapInPanel={false}
      submitBtnLabel={t('AUDIT_TRAIL.ADD_ITEM_ID')}
    />
  </Popover>);

  const selectWorksheetModal = (
    <ItemsFromWorksheetModal
      setItems={setItemsFromWorksheet}
      setModalIsOpen={setShowModal}
    />);

  return (
    <Card data-testid="auditTableSearchPanel" className="auditTrailCard">
      <Card.Header className="panel-heading auditTrailCard">
        <div className="flexWrap flexColumnContainer">
          <HoverTooltip text={t('AUDIT_TRAIL.TIMEZONE', { TIMEZONE: timezone.name })}>
            <div className="flexRowContainer mr10 width-auto">
              <label className="col-form-label">{t('START')}</label>
              <DateTimeEntry
                testId="startTimeSearch"
                date={moment.utc(start)}
                otherDate={moment.utc(end)}
                updateDate={d => setStart(d.valueOf())}
                updateBothDates={() => {}}
                timezone={timezone}
                timeZoneReadOnly={true}
                hideTimezone={true}
                readOnly={false}
                dateFormat={undefined}
                fieldIdentifier='RangeSearch'
                trackCategory='Audit Trail'
                trackInformation='Start search parameter'
                trackAction='search by start'
                inputExtraClassNames='overflowHidden dateTimeEntryAudit'
                textExtraClassNames='overflowHidden width-150 dateTimeEntryAudit'
              />
            </div>
          </HoverTooltip>

          <HoverTooltip text={t('AUDIT_TRAIL.TIMEZONE', { TIMEZONE: timezone.name })}>
            <div className="flexRowContainer mr10 width-auto">
              <label className="col-form-label">{t('END')}</label>
              <DateTimeEntry
                testId="endTimeSearch"
                date={moment.utc(end)}
                otherDate={moment.utc(start)}
                updateDate={d => setEnd(d.valueOf())}
                updateBothDates={() => {}}
                timezone={timezone}
                timeZoneReadOnly={true}
                readOnly={false}
                dateFormat={undefined}
                hideTimezone={true}
                fieldIdentifier='RangeSearch'
                trackCategory='Audit Trail'
                trackInformation='End search parameter'
                trackAction='search by end'
                inputExtraClassNames='overflowHidden dateTimeEntryAudit'
                textExtraClassNames='overflowHidden width-150 dateTimeEntryAudit'
              />
            </div>
          </HoverTooltip>


          <div data-testid="itemsFilter" className="flexRowContainer multiSelectTwoLine mr10 width-275">
            <div className="flexColumnContainer flexAlignCenter">
              <HoverTooltip text={t('AUDIT_TRAIL.SEARCH_ITEMS_TOOLTIP')}>
                <div className="flexColumnContainer flexAlignCenter">
                  <label className='col-form-label mr5'>{t('AUDIT_TRAIL.SEARCH_ITEMS')}</label>
                  <div>
                    {selectedItems.length > 0
                      ? t('AUDIT_TRAIL.ITEMS_SELECTED_COUNT', { COUNT: selectedItems.length }) : ''}
                  </div>
                </div>
              </HoverTooltip>
              <div className="flexFill" />
              <OverlayTrigger trigger="click" rootClose={true} placement='bottom' overlay={itemsPopover}
                transition={false}>
                <a href="#" className="mr5">{t('AUDIT_TRAIL.SEARCH_ITEMS_BY_ID')}</a>
              </OverlayTrigger>
              <a onClick={() => setShowModal(true)} href="#">{t('AUDIT_TRAIL.SEARCH_ITEMS_BY_WORKSHEET')}</a>
              {showModal && selectWorksheetModal}
            </div>

            <div>
              <SelectItemWrapper
                items={items}
                selected={selectedItems}
                allowClear={true}
                isMultipleSelect={true}
                loadingMetadata={null}
                dropdownWidth='150'
                cssClassName='react-select'
                noWrap={false}
                onRemove={itemsOnRemove}
                onChange={itemsOnChange}
                isGrouped={false} />
            </div>
          </div>

          <div className="flexRowContainer mr10">
            <HoverTooltip text={t('AUDIT_TRAIL.SEARCH_ITEM_TYPES_TOOLTIP')}>
              <div className="flexColumnContainer flexAlignCenter">
                <label className="col-form-label mr5">{t('AUDIT_TRAIL.SEARCH_ITEM_TYPES')}</label>
                (<a href="#"
                onClick={() => selectItemTypes({ value: 'ALL' })}
                className="ml">{t('SELECT_ALL')}
              </a>)
              </div>
            </HoverTooltip>
            <div className="multiSelectTwoLine width-250" data-testid="itemTypeFilter">
              <Select
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                classNamePrefix='react-select'
                options={itemTypeOptions}
                placeholder={t('AUDIT_TRAIL.SEARCH_ITEM_TYPES_PLACEHOLDER')}
                isMulti={true}
                value={itemTypes}
                isSearchable={true}
                onChange={selectItemTypes} />
            </div>
          </div>

          <div className="flexRowContainer  mr10">
            <label className="col-form-label">{t('AUDIT_TRAIL.SEARCH_CHANGE_TYPES')}</label>
            <div className="width-250 multiSelectTwoLine" data-testid="changeTypeFilter">
              <Select
                data-testid="changeTypeSelect"
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                classNamePrefix="react-select"
                options={[
                  { label: t(ChangeTypeEnum.CREATE.toString()), value: ChangeTypeEnum.CREATE },
                  { label: t(ChangeTypeEnum.UPDATE.toString()), value: ChangeTypeEnum.UPDATE },
                  { label: t(ChangeTypeEnum.DELETE.toString()), value: ChangeTypeEnum.DELETE }
                ]}
                isMulti={true}
                placeholder={t('AUDIT_TRAIL.SEARCH_CHANGE_TYPES_PLACEHOLDER')}
                isSearchable={true}
                onChange={(types: any[]) => setChangeTypes(types)} />
            </div>
          </div>

          <div className="mr10 flexRowContainer">
            <HoverTooltip text={t('AUDIT_TRAIL.SEARCH_USERS_TOOLTIP')}>
              <label className="col-form-label">{t('AUDIT_TRAIL.SEARCH_USERS')}</label>
            </HoverTooltip>
            <div className="multiSelectTwoLine width-250" data-testid="usernameFilter">
              <SelectIdentity
                idForLabel="identityName"
                setIdentity={setUserIdentities}
                allowGroups={false}
                identity={userIdentities as Partial<IdentityPreviewV1>}
                includeAllProperties={true}
                startEditable={true}
                placeholder="ACCESS_CONTROL.USER_PLACEHOLDER"
                multiple={true} />
            </div>
          </div>

          <div className="flexColumnContainer flexSpaceBetween flexAlignCenter flexFill ml10 mr10">
            <TextButton
              label={t('SEARCH')}
              variant="theme"
              icon="fa-search"
              disabled={false}
              iconStyle="white"
              type="submit"
              extraClassNames="mr5 mt5 ml10"
              size="sm"
              onClick={searchAndTrack} />
            <div className="flexAlignCenter flexColumnContainerContainer flexSpaceBetween mr5">
              <OverlayTrigger trigger="click" rootClose={true} placement='bottom'
                overlay={helpIconPopover} transition={false}>
                <div data-testid="auditTrailHelp"
                  className="fa fa-question-circle fa-xlg text-interactive pl8 mt5 cursorPointer" />
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </Card.Header>
    </Card>
  );
};
