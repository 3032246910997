import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { AgentsApi, SystemApi } from '@/sdk';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { NotificationsService } from '@/services/notifications.service';
import _ from 'lodash';


const stageVersionModalBindings = bindingsDefinition({
  sqSystemApi: injected<SystemApi>(),
  sqAgentsApi: injected<AgentsApi>(),
  sqNotifications: injected<NotificationsService>(),
  onClose: prop<() => void>()
});

export const StageVersionModal: SeeqComponent<typeof stageVersionModalBindings> = (props) => {
  const { sqSystemApi, sqNotifications , sqAgentsApi } = useInjectedBindings(stageVersionModalBindings);
  const { t } = useTranslation();
  const { onClose } = props;
  const [installer, setInstaller] = useState(undefined);
  const [displayInstallers, setDisplayInstallers] = useState([]);


  const formatInstallers = installers => _.map(installers, installer => ({
    text: installer.marketingName,
    value: installer.name,
  }));

  useEffect(() => {
      sqAgentsApi.configuredDirectives().then(({ data }) => {
        setDisplayInstallers(formatInstallers(data.installers));
        setInstaller(data.stageInstaller);
      }).catch(sqNotifications.apiError);
  }, [setDisplayInstallers]);

  const updateStageVersion = (installer) => {
    const directiveInput = { directiveName: 'StageInstaller' };
    const requestBody = {
      configurationOptions: [
        {
          path: 'Features/RemoteAgentUpdates/StageInstaller',
          value: installer
        }]
    };
    const promises = [];
    promises.push(sqSystemApi.setConfigurationOptions(requestBody));
    promises.push(sqAgentsApi.directiveUpdated(directiveInput));
    Promise.all(promises).then(()=>{
      sqNotifications.success(t('ADMIN.AGENTS.INSTALLER_STAGED'));
      onClose();
    }).catch(sqNotifications.apiError);
  };

  const formDefinition: FormElement[] = [
  {
    key: 'nameLabel',
    component: 'LabelFormComponent',
    value: 'ADMIN.AGENTS.STAGE_VERSION_DESC',
    name: 'nameLabel',
    testId: 'labelStageVersion'
  },
  {
    key: 'nameLabel2',
    component: 'LabelFormComponent',
    value: 'ADMIN.AGENTS.STAGE_VERSION_DESC2',
    name: 'nameLabel2',
    testId: 'labelStageVersion2'
  },
  {
    key: 'nameLabel3',
    component: 'LabelFormComponent',
    value: 'ADMIN.AGENTS.STAGE_VERSION_DESC3',
    name: 'nameLabel3',
    testId: 'labelStageVersion3'
  },
  {
    key: 'selectStageVersion',
    component: 'IconSelectFormComponent',
    name: 'selectStageVersion',
    testId: 'selectStageVersion',
    placeholder: 'ADMIN.AGENTS.STAGE_VERSION',
    insideModal: true,
    selectOptions: displayInstallers,
    value: installer,
    onChange: installer => setInstaller(installer.value)
  }];

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="stageVersionModal"
      className="modalSelectFormComponent">
      <Modal.Header closeButton={true} className="flexColumnContainer flexSpaceBetween">
        <h3>{t('ADMIN.AGENTS.STAGE_VERSION_MODAL')}</h3>
      </Modal.Header>
      <Modal.Body>
        <SimpleSaveFormBuilder
          formDefinition={formDefinition}
          submitBtnLabel={t('ADMIN.AGENTS.STAGE')}
          submitFn={() => updateStageVersion(installer)}
          closeFn={onClose} />
      </Modal.Body>
    </Modal>);
};
