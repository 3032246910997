import _ from 'lodash';
import angular from 'angular';
import moment from 'moment-timezone';
import { DurationStore } from '@/trendData/duration.store';
import { DateTimeService } from '@/datetime/dateTime.service';
import { UtilitiesService } from '@/services/utilities.service';
import { BIN_ERRORS } from '@/investigate/aggregationBins/byYValue.controller';
import { INTERNAL_CAPSULE_PROPERTY_PREFIX } from '@/trendData/trendData.module';
import { DURATION_TIME_UNITS } from '@/main/app.constants';
import { ConditionsApi } from '@/sdk';

angular.module('Sq.Investigate').factory('sqInvestigateHelper', sqInvestigateHelper);

export type InvestigateHelperService = ReturnType<typeof sqInvestigateHelper>;

function sqInvestigateHelper(
  $q: ng.IQService,
  sqDurationStore: DurationStore,
  sqDateTime: DateTimeService,
  sqUtilities: UtilitiesService,
  sqConditionsApi: ConditionsApi,
  $translate
) {
  const service = {
    requestCapsuleProperties,
    validateBinConfig,
    checkCutOffRateRatio,
    checkWindowSizeRatio,
    getCutoffRateErrorLimit
  };

  return service;

  /**
   * Sets the detectedProperties for all displayed capsule sets excluding already assigned ones.
   * Requests will fail silently.
   */
  function requestCapsuleProperties(conditionId, excludedCapsuleProperties) {
    const start = sqDurationStore.displayRange.start.toISOString();
    const end = sqDurationStore.displayRange.end.toISOString();
    // TODO CRAB-27161: Remove getCapsules() invocation once all connectors push properties to conditions
    return $q.all([
        sqConditionsApi.getCapsules({ id: conditionId, start, end })
          .then(({ data: { capsules } }) => _.chain(capsules)
            .flatMap('properties')
            .value()),
        sqConditionsApi.getCondition({ id: conditionId })
          .then(({ data: { capsuleProperties } }) => _.flatten(capsuleProperties))
      ])
      .then(([capsules1, capsules2]) => _.chain(capsules1)
        .concat(capsules2)
        .reject(property => _.startsWith(property.name.toLowerCase(), INTERNAL_CAPSULE_PROPERTY_PREFIX.toLowerCase()))
        .reject(property => _.includes(excludedCapsuleProperties, property.name))
        .uniqBy('name')
        .sortBy('name')
        .value())
      .finally(() => []);
  }

  /**
   * Validates the bin configuration for bins by y-value. Returns one of BIN_ERRORS or '' if the param is valid.
   *
   * @param {Object} param - param defining a bin configuration
   * @param {Number} [payload.yValueBinMin] -  minimum y-value to be used for creating bins by y-value. Only
   *   applies to AGGREGATION_MODES.Y_VALUE
   * @param {Number} [payload.yValueBinMax] -  maximum y-value to be used for creating bins by y-value. Only
   *   applies to AGGREGATION_MODES.Y_VALUE
   * @param {Number} [payload.binSize] - size of the bin. Only applies to AGGREGATION_MODES.Y_VALUE
   * @param {Number} [payload.numberOfBins] - number of bins. Only applies to AGGREGATION_MODES.Y_VALUE
   * @returns {String} on of BIN_ERRORS if the param is invalid, or an empty String if the param is valid
   */
  function validateBinConfig(param) {
    const min = _.toNumber(param.yValueBinMin);
    const max = _.toNumber(param.yValueBinMax);
    const binSize = _.toNumber(param.binSize);
    const numberOfBins = _.toNumber(param.numberOfBins);

    if (!_.isNil(param.numberOfBins) && !_.isFinite(numberOfBins) || numberOfBins < 1) {
      return BIN_ERRORS.INT_REQUIRED;
    }

    if (!_.isFinite(min)) {
      return BIN_ERRORS.MIN_NOT_A_NUMBER;
    } else if (!_.isFinite(max)) {
      return BIN_ERRORS.MAX_NOT_A_NUMBER;
    } else if (min > max) {
      return BIN_ERRORS.MIN_EXCEEDS_MAX;
    } else if (binSize <= 0) {
      return BIN_ERRORS.INVALID_BIN_SIZE;
    }

    if (sqUtilities.validateNumber(param.yValueBinMin) && sqUtilities.validateNumber(param.yValueBinMax) &&
      (sqUtilities.validateNumber(param.numberOfBins) || sqUtilities.validateNumber(param.binSize))) {
      return '';
    }
  }

  /**
   * Checks for the correct ratio of filter value to period value
   * @param filter {Object} - The high or low pass filter to be tested
   * @param period {Object} - The period to be used for testing
   * @param ratio {Number} - The ratio required for this filter
   * @param requireSameType {boolean} - False if the filter and period can be opposite types (one period, one frequency)
   * @returns {boolean} - Whether the ratio requirements have been met
   */
  function checkCutOffRateRatio(filter, period, ratio, requireSameType = true) {
    const filterIsFrequency = sqDateTime.isFrequency(filter.units);
    const periodIsFrequency = sqDateTime.isFrequency(period.units);

    if (_.isNil(filter.value) || _.isNil(filter.units) || _.isNil(period.value) || _.isNil(period.units) ||
      (requireSameType && filterIsFrequency !== periodIsFrequency)) {
      // Data is not complete, return true to avoid showing warning messages before incorrect data is entered
      return true;
    }

    filter = filterIsFrequency ? sqDateTime.convertFrequencyToPeriod(filter) : filter;
    period = periodIsFrequency ? sqDateTime.convertFrequencyToPeriod(period) : period;
    const filterDuration = moment.duration(filter.value,
      sqDateTime.momentMeasurementStrings(filter.units)).asMilliseconds();
    const periodDuration = moment.duration(period.value,
      sqDateTime.momentMeasurementStrings(period.units)).asMilliseconds();

    return (filterDuration / periodDuration) >= ratio;
  }

  /**
   * Checks for the correct ratio of sampling period to window size (taps)
   * @param period {Object} - The period to be used for testing
   * @param window {Object} - The window size to be used for testing
   * @param ratio {Number} - The ratio required for this filter
   * @param ratioIsMin {boolean} - False to check that the values are less than or equal to the ratio (true checks >=)
   * @returns {boolean} - True if the ratio requirements have been met
   */
  function checkWindowSizeRatio(period, window, ratio, ratioIsMin = true) {
    if (_.isNil(window.value) || _.isNil(window.units) || _.isNil(period.value) ||
      _.isNil(period.units) || period.value === 0) {
      return true;
    }

    if (sqDateTime.isFrequency(period.units)) {
      period = sqDateTime.convertFrequencyToPeriod(period);
    }

    const windowDuration = moment.duration(window.value,
      sqDateTime.momentMeasurementStrings(window.units)).asMilliseconds();
    const periodDuration = moment.duration(period.value,
      sqDateTime.momentMeasurementStrings(period.units)).asMilliseconds();

    return ratioIsMin ? (windowDuration / periodDuration) >= ratio : (windowDuration / periodDuration) <= ratio;
  }

  /**
   * Calculates the error limit in units that match the cutoff
   * @param cutoff {Object} - The cutoff (used to know if we should return period or frequency units)
   * @param rate {Object} - The sampling rate to calculate the error limit from
   * @param ratio {Number} - The ratio required between the cutoff and sampling rate
   * @returns {string} - The value and units of the limit (i.e. '2 minute(s)')
   */
  function getCutoffRateErrorLimit(cutoff, rate, ratio) {
    const cutoffIsFrequency = sqDateTime.isFrequency(cutoff.units);
    const rateIsFrequency = sqDateTime.isFrequency(rate.units);

    if (cutoffIsFrequency) {
      const rateAsFrequency = rateIsFrequency ? rate :
        sqDateTime.convertFrequencyToHertz(sqDateTime.convertPeriodToFrequency(rate));
      return `${rateAsFrequency.value / ratio} ${rateAsFrequency.units}`;
    } else {
      const rateAsPeriod = rateIsFrequency ? sqDateTime.convertFrequencyToPeriod(rate) : rate;
      const unit = _.find(DURATION_TIME_UNITS, unit => unit.unit[0] === rateAsPeriod.units);
      return `${rateAsPeriod.value * ratio} ${unit ? $translate.instant(unit.translationKey) : rateAsPeriod.units}`;
    }
  }
}
