import _ from 'lodash';
import React from 'react';
import { angularComponent } from '../core/react2angular.util';
import { FroalaEditor as FroalaEditorConstruct } from 'other_components/froalaEditor';
import {
  APPSERVER_API_CONTENT_TYPE,
  APPSERVER_API_PREFIX,
  DEBOUNCE,
  FROALA_KEY,
  GUID_REGEX_PATTERN
} from '@/main/app.constants';
import jQuery from 'jquery';
import {
  baseEditorBindings,
  BaseEditor,
  EditorDependencies,
  EditorProps
} from '@/hybrid/annotation/BaseEditor.organism';

const setupFroalaEditor = function(deps: EditorDependencies, props: EditorProps) {
  const { sqFroalaPlugins, sqUtilities, $sce, sqAuthentication, sqNotifications, t, sqAnnotationsApi } = deps;
  const { beforeOnInit, afterOnInit, document, id: docId, documentChanged, onDestroy, initialLanguage } = props;
  const debouncedNotFoundNotification = _.debounce(() => sqNotifications.error('Resource could not be found'),
    DEBOUNCE.MEDIUM);
  let editor;
  let createdId;
  let trustedDocument;
  let options;
  let savedHtml = document;
  let lastHeight;
  let lastWidth;
  let element;
  const imageCORSProxy = `${APPSERVER_API_PREFIX}/annotations/image/cors`;

  sqFroalaPlugins.journalLink();
  sqFroalaPlugins.resizeBasedOnToolbarSize();

  const init = () => {
    options = {
      key: FROALA_KEY,
      attribution: false,
      charCounterCount: false,
      imageResizeWithPercent: true,
      fileAllowedTypes: [],
      fileInsertButtons: [],
      toolbarContainer: '#journalEditorToolbarContainer',
      imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif', 'webp', 'svg+xml'],
      imageDefaultWidth: 0, // Default to natural size of image
      imageDefaultAlign: 'none',
      imageDefaultDisplay: 'inline',
      imageCORSProxy,
      imageStyles: {
        'report-image-border': t('REPORT.EDITOR.BORDER'),
        'report-no-margin': t('REPORT.EDITOR.NO_MARGIN')
      },
      language: null,
      tableStyles: {
        'center-froala-table': t('REPORT.EDITOR.CENTER_TABLE'),
        'fr-dashed-borders': t('REPORT.EDITOR.DASHED_BORDERS'),
        'fr-alternate-rows': t('REPORT.EDITOR.ALTERNATE_ROWS'),
        'table-no-border': t('REPORT.EDITOR.NO_TABLE_BORDER')
      },
      events: {
        'image.beforeUpload': handleImageUpload,
        'image.beforePasteUpload': onImageBeforePasteUpload,
        'image.uploaded': () => updated(false),
        'paste.afterCleanup': onPasteAfterCleanup,
        contentChanged: () => updated(false),
        'html.inserted': () => updated(false),
        'html.set': updateTrustedDocument,
        'table.inserted': table => jQuery(table).find('td').css('vertical-align', 'top'),
        'sq.custom.imageCORSRequestFailed': onImageCORSRequestError
      }
    };
    options.language = options.language || initialLanguage;
    element = $('journalEditorToolbarContainer');
    // required for both journals and topics so that the link list includes only the Seeq Customer Success Center link
    if (beforeOnInit) {
      beforeOnInit(options);
    }
    // @ts-ignore
    editor = new FroalaEditorConstruct('#journalEditor', options, onFroalaInitialized);
  };

  const onFroalaInitialized = () => {
    editor.html.set(savedHtml);
    layout();
    afterOnInit(editor);
  };

  /**
   * Handles image upload to the backend using the froala insert image tool and related client side infrastructure.
   */
  const handleImageUpload = (images) => {
    const id = createdId || (sqUtilities.validateGuid(docId) ? docId : undefined);

    // If the journal entry doesn't exist yet (because it has never been saved), first we have to manually force
    // it to save so an ID will be created/assigned and then when the ID is returned, we make a call to
    // editor.image.upload(), which causes froala to call the handleImageUpload() function back with the images.
    if (!id) {
      // The images FileList gets cleared after we return false, so make a copy of it.
      const imagesClone = _.map(images, _.identity);

      documentChanged('', true)
        .then(function(response: { id: string }) {
          // After creating the annotation, upload the image, but use the ID of the new annotation
          if (response && response.id) {
            createdId = response.id;
            editor.image.upload(imagesClone);
          }
        });

      return false; // Don't upload the images until we have created the annotation
    }
    createdId = undefined;
    configureImageUpload(editor, id);
  };

  /**
   * Calls the documentChanged() callback to save the current state of the document in the editor.
   *
   * @param {Boolean} forceSave - true to force save
   * @returns {Promise} a promise that resolves when the document has been saved
   */
  const updated = (forceSave: boolean): Promise<object | void> => {
    if (!editor) return Promise.resolve();

    const document = updateTrustedDocument();
    savedHtml = document;
    // CRAB-22106, CRAB-23608 - Strip out pasted images that are still loading. If the pasted image came from another
    // journal, we could end up with a cross-referenced image in a backup.
    const documentWithoutLoadingPastedImages = sqUtilities.removeLoadingPastedImages(document);
    return documentChanged(documentWithoutLoadingPastedImages, forceSave);
  };

  /**
   * Helper function that removes encoded image data and updates the trustedDocument view model property.
   * When pasting an image, froala will initially encode the image data in the src property. To prevent
   * this, we remove the encoded image data from the src tag, which is fine because the URL of the image
   * file saved to the backend will be updated momentarily and the image will display.
   *
   * @returns {String} the HTML document with encoded image data removed
   */
  const updateTrustedDocument = () => {
    if (!editor) return '';

    const htmlContent = sqUtilities.removeEncodedImageData(editor.html.get());
    trustedDocument = $sce.trustAsHtml(htmlContent);
    return htmlContent;
  };

  /**
   * Configures the image upload URL and Authorization header
   *
   * @param {Object} editor - a froala editor reference
   * @param {String} id - the GUID of the backend annotation item that stores the document
   */
  const configureImageUpload = (editor, id) => {
    editor.opts.imageUploadURL = APPSERVER_API_PREFIX + '/annotations/' + id + '/images';
    editor.opts.requestHeaders = { Accept: APPSERVER_API_CONTENT_TYPE };
    sqAuthentication.addCsrfHeader(editor.opts.requestHeaders);
  };

  /**
   * We allow image paste so users can copy/cut and paste images in the document, but we have to ensure
   * that if the image data is embedded in the image src property, we upload it to the server and replace the
   * src with the resulting URL. Froala will do the upload and replacement as long as we supply a valid
   * imageUploadURL and an Authorization request header.
   */
  const onImageBeforePasteUpload = () => {
    configureImageUpload(editor, docId);
  };

  /**
   * Converts absolute workbench URLs back into relative URLs.
   * The clipboardHtml returned by froala has all URLs converted to be absolute, so we remove the protocol,
   * host, and port if it matches our local server to maintain relative URLs after the paste operation.
   *
   * @param {String} clipboardHtml - "clean" html from froala to be pasted
   * @returns {String} that Froala will use as the string to paste
   */
  const onPasteAfterCleanup = (clipboardHtml) => {
    // Wrap the input in a div so that we ensure it is only a single element. The extra div will be excluded when
    // .html() is called
    const newContent = window.document.createElement('div');
    newContent.innerHTML = clipboardHtml;

    _.map(newContent.querySelectorAll('img'), (img) => {
      const anchor = img.parentElement;
      img.setAttribute('src', _.replace(img.getAttribute('src'), sqUtilities.getWorkbenchAddress(), ''));
      if (anchor) {
        anchor.setAttribute('href', _.replace(anchor.getAttribute('href'), sqUtilities.getWorkbenchAddress(), ''));
      }

      // Remove height attribute of pasted images so they will scale proportionally when the view window is narrow
      img.removeAttribute('height');

      // CRAB-22106: When a user copies text out of the editor, Froala saves the selection in localStorage (see
      // https://github.com/froala/wysiwyg-editor/issues/3806).  When pasting into the editor, Froala checks
      // localStorage for previously-pasted content and, if found, uses it verbatim rather than the clipboard contents.
      // If this happens, Froala skips the image upload process.  In order to avoid cross-referenced annotation images,
      // we can force Froala to proceed with the upload by adding the proper data attribute.
      img.setAttribute('data-fr-image-pasted', 'true');
    });

    return newContent.innerHTML;
  };

  const setLanguage = (language) => {
    if (!editor) {
      return;
    }
    if (options.language !== language) {
      options.language = sqUtilities.checkLanguage(language);
      destroy();
      init();
    }
  };

  /**
   * Destroys the Journal editor
   */
  const destroy = () => {
    if (_.isFunction(onDestroy)) {
      onDestroy();
    }
    if (editor) {
      editor.destroy();
    }
    element = null;
    editor = null;

    // reset the size as well so the editor is resized again after reinit
    lastHeight = 0;
  };

  /**
   * This resize function is needed to set the height of the froala editor
   * so it will fill the available vertical space.
   */
  const resize = (height, width) => {
    if (height === lastHeight && width === lastWidth) {
      return;
    }
    // During transition from workbench to topic the resize callback can get stuck in a loop (CRAB-14676). The only
    // safe way to know that the transition is finished is when the workbench explorer is no longer in the DOM
    // because $state.transition becomes false before all the ng-if events have settled.
    const inTransition = jQuery('sq-home-screen').length;

    lastHeight = height;
    lastWidth = width;

    if (editor && !inTransition) {
      editor.opts.height = height;
      if (editor.size && editor.size.refresh) {
        editor.size.refresh();
      }
    }
  };

  /**
   * Updates the editor layout
   */
  const layout = () => {
    if (!editor) return;

    element.find('.fr-toolbar').removeClass('d-none');
    element.find('.fr-box').removeClass('d-none');
    focus();

    resize(lastHeight, lastWidth);
  };

  /**
   * Focuses the cursor in the editor document
   */
  const focus = () => {
    if (!editor) return;

    editor.el.focus();
  };

  const elementFromHtml = (htmlContent) => {
    const d = window.document.createElement('div');
    d.innerHTML = htmlContent;

    return d;
  };

  /**
   * Workaround for wonky Froala behavior; clears the data-fr-image-pasted attribute from <img>s in the document so that
   * we can save this journal.
   */
  function onImageCORSRequestError(error) {
    // We can't just blindly remove the attribute from all images in the document, since others may be in-progress and
    // eventually succeed.  We also can't manipulate error.target, since that will be the detached Image created by
    // Froala and not the to-be-replaced <img> in the document.  However, we *can* search for <img>s with the same src
    // attribute, after stripping off the proxy URL.  We also need to handle absolute (remotely stored) and relative
    // (locally stored) URLs.
    const img = error.target;
    const proxyAbsoluteUrl = new URL(imageCORSProxy, sqUtilities.getWorkbenchAddress()).href;
    const srcWithoutProxyURL = new URL(_.replace(img.src, `${proxyAbsoluteUrl}/`, ''));
    const imageIsLocal = srcWithoutProxyURL.host === location.host;
    const originalSrc = imageIsLocal
      ? `${srcWithoutProxyURL.pathname}${srcWithoutProxyURL.searchParams.toString() === '' ? '' :
        `?${srcWithoutProxyURL.searchParams.toString()}`}`
      : srcWithoutProxyURL.toString();

    const originalDocument = editor.html.get();
    const editorDocument = elementFromHtml(originalDocument);
    const annotationId = srcWithoutProxyURL.href.match(GUID_REGEX_PATTERN)[0];
    const promises = _.map(editorDocument.querySelectorAll(`img[src="${originalSrc}"][data-fr-image-pasted]`),
      (img) => {
        img.removeAttribute('data-fr-image-pasted');
        if (imageIsLocal) {
          // Since we copy relative links in Seeq, if this is a link from another installation, we won't know that. But
          // we don't want to treat such images as cross-referenced, so to avoid this, we will check if this image
          // exists, and if it does not, we will replace the URL with one that does not trigger cross-referenced
          // issues.
          return sqAnnotationsApi.getAnnotation({ id: annotationId })
            .then(() => {
              const wrapper = window.document.createElement('div');
              wrapper.classList.add('crossReferencedImage');
              img.parentNode.insertBefore(wrapper, img);
              img.parentNode.removeChild(img);
              wrapper.appendChild(img);
            })
            .catch(() => {
              // Then this annotation does not exist, so neither does the image
              img.setAttribute('src', '-could-not-be-found');
              debouncedNotFoundNotification();
            });
        } else {
          return Promise.resolve();
        }
      });
    Promise.all(promises).then(() => {
      const modifiedDocument = editorDocument.innerHTML;

      // If the above hasn't made any changes, there's no need to save anything.  The original document needs to be
      // passed through the same transformation we made for the modified document, since escaping might differ.
      if (modifiedDocument !== elementFromHtml(originalDocument).innerHTML) {
        editor.html.set(editorDocument.innerHTML);
        return updated(false);
      }
    });
  }

  /**
   * This is needed since the useState hook doesn't work well with objects when
   * an external function updates the object without calling state. In this case
   * the editor will update it's savedHtml variable, but any react component that
   * uses this will not get an update value of editor.savedHtml. By using a function
   * getHtml it will always check the latest value
   */
  const getHtml = () => savedHtml;

  return {
    init,
    onFroalaInitialized,
    handleImageUpload,
    updateTrustedDocument,
    updated,
    onImageBeforePasteUpload,
    onPasteAfterCleanup,
    setLanguage,
    destroy,
    resize,
    getHtml,
    onImageCORSRequestError,
    imageCORSProxy
  };
};

// setup the Editor to use the Froala Editor
// once we want to also add CKEditor we will be able to create a new component and export it as another
// angular component
export const FroalaEditor: SeeqComponent<typeof baseEditorBindings> = props => <BaseEditor
  {...props} setupEditor={setupFroalaEditor} />;

export const sqFroalaEditor = angularComponent(baseEditorBindings, FroalaEditor);
