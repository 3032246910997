import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { TrackService } from '@/track/track.service';
import { InvestigateStore } from '@/investigate/investigate.store';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { ITEM_TYPES, PREVIEW_ID } from '@/trendData/trendData.module';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import {
  DEBOUNCE,
  DISPLAY_MODE,
  DURATION_TIME_UNITS,
  PERIOD_UNITS
} from '@/main/app.constants';
import { DigitalFilterStore } from '@/hybrid/tools/digitalFilter/digitalFilter.store';
import { DigitalFilterActions } from '@/hybrid/tools/digitalFilter/digitalFilter.actions';
import {
  DIGITAL_FILTER_IMAGES,
  DIGITAL_FILTER_MIN_CUTOFF_RATIO,
  DIGITAL_FILTER_TAPS_MIN,
  DIGITAL_FILTER_VALUES
} from '@/hybrid/tools/digitalFilter/digitalFilter.module';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { InvestigateHelperService } from '@/investigate/investigateHelper.service';
import { TrendActions } from '@/trendData/trend.actions';
import { DateTimeService } from '@/datetime/dateTime.service';
import {
  FormElement,
  FORM_ERROR
} from '@/hybrid/formbuilder/formBuilder.module';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';

const digitalFilterBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  sqTrendActions: injected<TrendActions>(),
  sqDigitalFilterStore: injected<DigitalFilterStore>(),
  sqDigitalFilterActions: injected<DigitalFilterActions>(),
  sqInvestigateStore: injected<InvestigateStore>(),
  sqInvestigateHelper: injected<InvestigateHelperService>(),
  sqDateTime: injected<DateTimeService>(),
  sqTrack: injected<TrackService>()
});

export const DigitalFilter: SeeqComponent<typeof digitalFilterBindings> =
  () => {
    const {
      sqInvestigateActions,
      sqTrendActions,
      sqDigitalFilterStore,
      sqDigitalFilterActions,
      sqInvestigateStore,
      sqInvestigateHelper,
      sqDateTime,
      sqTrack
    } = useInjectedBindings(digitalFilterBindings);

    const displayMode = useFluxPath(
      sqInvestigateStore,
      () => sqInvestigateStore.displayMode
    );

    const [color, setColor] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState('');
    const [isFormValid, setIsFormValid] = useState(true);

    const {
      filterType,
      cutoff,
      cutoff2,
      samplingRate,
      isAutoSamplingRate,
      windowSize,
      isAutoWindowSize,
      inputSignal,
      id,
      name,
      originalParameters
    } = useFlux(sqDigitalFilterStore);

    const showCutoffError = !sqInvestigateHelper.checkCutOffRateRatio(
      cutoff,
      samplingRate,
      DIGITAL_FILTER_MIN_CUTOFF_RATIO,
      false
    );
    const showCutoff2Error = !sqInvestigateHelper.checkCutOffRateRatio(
      cutoff2,
      samplingRate,
      DIGITAL_FILTER_MIN_CUTOFF_RATIO,
      false
    );
    const showWindowSizeError = !sqInvestigateHelper.checkWindowSizeRatio(
      samplingRate,
      windowSize,
      DIGITAL_FILTER_TAPS_MIN
    );
    const isBand = _.includes(
      [DIGITAL_FILTER_VALUES.BAND_PASS, DIGITAL_FILTER_VALUES.BAND_STOP],
      filterType
    );

    useEffect(() => {
      // return function to be called when unmounted
      return () => {
        sqTrendActions.removePreviewSeries();
        generatePreview.cancel();
        sqTrendActions.cancelPreviewSeries();
      };
    }, []);

    useEffect(() => {
      if (!id && inputSignal?.id) {
        sqDigitalFilterActions.fetchAndSetDefaultValues();
      }
    }, [inputSignal]);

    useEffect(() => {
      const timer = setTimeout(() => {
        if (isAutoWindowSize) {
          sqDigitalFilterActions.setWindowSizeFromCutoff();
        }
      }, DEBOUNCE.PREVIEW);

      return () => clearTimeout(timer);
    }, [cutoff, cutoff2, filterType]);

    const generatePreview = _.debounce(() => {
      if (
        !inProgress &&
        sqInvestigateStore.activeTool === TREND_TOOLS.DIGITAL_FILTER &&
        isFormValid
      ) {
        const { formula, parameters } =
          sqDigitalFilterActions.getParametersAndFormula();
        sqTrendActions.generatePreviewSeries(
          formula,
          parameters,
          id ? id : PREVIEW_ID,
          color
        );
      } else {
        sqTrendActions.removePreviewSeries();
      }
    }, DEBOUNCE.PREVIEW);

    useEffect(() => {
      const timer = setTimeout(generatePreview, DEBOUNCE.PREVIEW);

      return () => clearTimeout(timer);
    }, [
      cutoff,
      cutoff2,
      windowSize,
      samplingRate,
      inputSignal,
      filterType,
      showWindowSizeError,
      showCutoffError,
      showCutoff2Error
    ]);

    const filterTypeOptions = _.map(
      ['LOW_PASS', 'HIGH_PASS', 'BAND_PASS', 'BAND_STOP'],
      filterType => ({
        text: `DIGITAL_FILTER.TYPES.${filterType}`,
        value: DIGITAL_FILTER_VALUES[filterType],
        imagePath: DIGITAL_FILTER_IMAGES[filterType]
      })
    );

    const execute = () => {
      setInProgress(true);
      generatePreview.cancel();
      sqTrendActions.removePreviewSeries();

      return sqDigitalFilterActions
        .execute(color)
        .then(() =>
          sqTrack.doTrack(
            'Workbench_Tool',
            'Digital Signal Filter',
            'completed'
          )
        )
        .catch((errorMessage) => {
          sqTrack.doTrack('Workbench_Tool', 'Digital Signal Filter', 'error');
          setApiErrorMessage(_.replace(errorMessage, /\(.*\)/gm, ''));
        })
        .finally(() => setInProgress(false));
    };

    const formDataSetup: FormElement[] = [
      {
        component: 'SearchTitleFormComponent',
        name: 'digitalFilterSearchTitle',
        value: name,
        onChange: name =>
          sqInvestigateActions.setSearchName(TREND_TOOLS.DIGITAL_FILTER, name),
        id,
        onColorChange: setColor,
        searchIconClass: 'fc-digital-filter',
        defaultName: 'DIGITAL_FILTER.HEADER'
      },
      {
        component: 'ItemSelectFormComponent',
        name: 'digitalFilterInputSignal',
        testId: 'digitalFilterInputSignal',
        displayNumber: true,
        value: inputSignal?.id,
        onChange: item =>
          sqInvestigateActions.setParameterItem(
            TREND_TOOLS.DIGITAL_FILTER,
            'inputSignal',
            item
          ),
        label: 'DIGITAL_FILTER.SELECT_SIGNAL',
        itemTypes: [ITEM_TYPES.SERIES],
        includeMetadata: true,
        excludeStringSignals: true,
        excludedIds: id,
        additionalItems: originalParameters
      },
      {
        component: 'ImageSelectFormComponent',
        name: 'digitalFilterType',
        displayNumber: true,
        label: 'DIGITAL_FILTER.SELECT_FILTER_TYPE',
        placeholder: 'DIGITAL_FILTER.SELECT_TYPE',
        value: filterType,
        selectOptions: filterTypeOptions,
        imageSelectClassName: 'specDigitalFilterTypeSelect',
        onChange: (option: any) =>
          sqDigitalFilterActions.setFilterType(option.value)
      },
      {
        component: 'ValueWithUnitsFormComponent',
        displayNumber: true,
        testId: 'cutoff',
        name: 'cutoff',
        min: 0,
        value: cutoff,
        availableUnits: PERIOD_UNITS,
        minIsExclusive: true,
        largeValueInput: true,
        step: sqDateTime.isFrequency(cutoff.units) ? 0.0001 : 1,
        label: isBand ? 'DIGITAL_FILTER.CUTOFF1' : 'DIGITAL_FILTER.CUTOFF',
        onChange: sqDigitalFilterActions.setCutoff,
        required: true,
        validation: () => !cutoff.valid || showCutoffError,
        extendValidation: true,
        fullWidth: true,
        extraClassNames: 'flexFill',
        defaultProvided: !_.isUndefined(inputSignal?.id) && cutoff?.valid,
        customErrorText: sqDateTime.isFrequency(cutoff.units)
          ? 'DIGITAL_FILTER.CUTOFF_ERROR_FREQUENCY'
          : 'DIGITAL_FILTER.CUTOFF_ERROR_PERIOD',
        customErrorParams: {
          limit: sqInvestigateHelper.getCutoffRateErrorLimit(
            cutoff,
            samplingRate,
            DIGITAL_FILTER_MIN_CUTOFF_RATIO
          )
        }
      },
      {
        component: 'ValueWithUnitsFormComponent',
        displayNumber: true,
        testId: 'cutoff2',
        name: 'cutoff2',
        min: 0,
        value: cutoff2,
        availableUnits: PERIOD_UNITS,
        minIsExclusive: true,
        largeValueInput: true,
        step: sqDateTime.isFrequency(cutoff2.units) ? 0.0001 : 1,
        label: 'DIGITAL_FILTER.CUTOFF2',
        onChange: sqDigitalFilterActions.setCutoff2,
        required: true,
        validation: () => !cutoff2.valid || showCutoff2Error,
        extendValidation: true,
        fullWidth: true,
        includeIf: isBand,
        extraClassNames: 'flexFill',
        defaultProvided: !_.isUndefined(inputSignal?.id) && cutoff2?.valid,
        customErrorText: sqDateTime.isFrequency(cutoff2.units)
          ? 'DIGITAL_FILTER.CUTOFF_ERROR_FREQUENCY'
          : 'DIGITAL_FILTER.CUTOFF_ERROR_PERIOD',
        customErrorParams: {
          limit: sqInvestigateHelper.getCutoffRateErrorLimit(
            cutoff2,
            samplingRate,
            DIGITAL_FILTER_MIN_CUTOFF_RATIO
          )
        }
      },
      {
        component: 'AdvancedFormGroup',
        name: 'advancedDigitalFilterParametersFormGroup',
        toolId: TREND_TOOLS.DIGITAL_FILTER,
        toolName: TREND_TOOLS.DIGITAL_FILTER,
        toolStore: sqDigitalFilterStore,
        components: [
          {
            component: 'ValueWithUnitsFormComponent',
            displayNumber: true,
            testId: 'windowSize',
            name: 'windowSize',
            min: 0,
            value: windowSize,
            availableUnits: DURATION_TIME_UNITS,
            minIsExclusive: true,
            onChange: sqDigitalFilterActions.setWindowSize,
            disabled: isAutoWindowSize,
            validation: () => !windowSize.valid || showWindowSizeError,
            label: 'DIGITAL_FILTER.WINDOW_SIZE',
            tooltip: 'DIGITAL_FILTER.TOOLTIPS.WINDOW_SIZE',
            includeAutoCheckbox: true,
            autoCheckboxId: 'autoWindowSizeCheckbox',
            autoCheckboxValue: isAutoWindowSize,
            autoCheckboxOnChange: () =>
              sqDigitalFilterActions.setIsAutoWindowSize(!isAutoWindowSize),
            customErrorText: 'DIGITAL_FILTER.WINDOW_SIZE_ERROR',
            defaultProvided:
              !_.isUndefined(inputSignal?.id) && windowSize?.valid
          },
          {
            component: 'ValueWithUnitsFormComponent',
            displayNumber: true,
            testId: 'samplingRate',
            name: 'samplingRate',
            min: 0,
            value: samplingRate,
            availableUnits: PERIOD_UNITS,
            minIsExclusive: true,
            onChange: sqDigitalFilterActions.setSamplingRate,
            disabled: isAutoSamplingRate,
            validation: () => !samplingRate.valid,
            label: 'DIGITAL_FILTER.SAMPLING_RATE',
            tooltip: 'DIGITAL_FILTER.TOOLTIPS.SAMPLING_RATE',
            includeAutoCheckbox: true,
            autoCheckboxId: 'autoSamplingRateCheckbox',
            autoCheckboxValue: isAutoSamplingRate,
            extendValidation: true,
            autoCheckboxOnChange: () =>
              sqDigitalFilterActions.setIsAutoSamplingRate(!isAutoSamplingRate)
          }
        ]
      },
      {
        component: 'ErrorMessageFormComponent',
        dismissible: true,
        name: 'apiError',
        includeIf: apiErrorMessage !== '',
        type: FORM_ERROR,
        title: 'DIGITAL_FILTER.FAILURE',
        value: apiErrorMessage,
        failForm: true,
        onClose: () => setApiErrorMessage('')
      }
    ];

    const digitalFilterBuilder = (
      <ToolPanelFormBuilder
        formDefinition={formDataSetup}
        submitFn={execute}
        closeFn={() => {
          generatePreview.cancel();
          sqTrendActions.removePreviewSeries();
          sqInvestigateActions.close();
        }}
        setIsValid={setIsFormValid}
        toolName={TREND_TOOLS.DIGITAL_FILTER}
        toolId={TREND_TOOLS.DIGITAL_FILTER}
        submitBtnId="digitalFilterExecuteBtn"
      />
    );

    return displayMode === DISPLAY_MODE.NEW || displayMode === DISPLAY_MODE.EDIT
      ? digitalFilterBuilder
      : null;
  };

export const sqDigitalFilter = angularComponent(
  digitalFilterBindings,
  DigitalFilter
);
