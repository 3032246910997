import React from 'react';
import Select, { components } from 'react-select';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { TimezonesService } from '@/datetime/timezone.service';

const timeZoneSelectBindings = bindingsDefinition({
  /** Selected time zone */
  timezone: prop<any>(),
  /** Alignment for dropdown */
  direction: prop.optional<'top' | 'bottom' | 'auto'>(),
  /** Specify True to make selection of a time zone optional */
  optional: prop.optional<boolean>(),
  /** Function to call when time zone is selected */
  onSelect: prop<(any) => void>(),
  /** Specify True to present time zone selector as disabled */
  disabled: prop.optional<boolean>(),
  /** CSS class names to apply to parent element around <select> */
  extraClassNames: prop.optional<string>(),
  /** CSS class name to apply to <select> element */
  className: prop.optional<string>(),
  sqTimezones: injected<TimezonesService>()
});

/**
 * Searchable dropdown selector for selecting a time zone
 */
export const TimeZoneSelectorUnwrapped: SeeqComponent<typeof timeZoneSelectBindings> = (props) => {
  const {
    timezone,
    direction = 'auto',
    optional = false,
    onSelect,
    disabled = false,
    extraClassNames = '',
    className
  } = props;
  const { sqTimezones } = useInjectedBindings(timeZoneSelectBindings);

  const Menu = props => (
    <components.Menu className='timeZoneMenu' {...props} />
  );

  const groupedZones: any[] = _.chain(sqTimezones.timezones)
    .map(zone => ({ value: zone, label: zone.displayName }))
    .sortBy('label')
    .groupBy(option => option.value.offsetMinutes)
    .map((options: any[]) => ({ label: options[0].value.offset, options }))
    .sortBy(group => group.options[0].value.offsetMinutes)
    .value();

  return (
    <div id="timeZoneSelector" data-testid="timeZoneSelector" className={extraClassNames}>
      <Select
        value={{ label: timezone?.displayName, value: timezone }}
        options={groupedZones}
        className={className ?? ''}
        classNamePrefix={`${className ?? ''} timeZoneSelector react-select`}
        components={{ Menu }}
        isClearable={optional}
        onChange={newZone => onSelect(newZone?.value)}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        menuPlacement={direction}
        isDisabled={disabled}
      />
    </div>
  );
};

export const TimeZoneSelector = React.memo(TimeZoneSelectorUnwrapped, (prev, next) => !(
  !_.isEqual(prev.timezone, next.timezone)
  || prev.disabled !== next.disabled
  || prev.extraClassNames !== next.extraClassNames)
);

export default TimeZoneSelector;
export const sqTimeZoneSelector = angularComponent(timeZoneSelectBindings, TimeZoneSelector);
