import _ from 'lodash';
import angular from 'angular';
import { BaseItemStoreService } from '@/trendData/baseItemStore.service';
import { DASH_STYLES, ITEM_DATA_STATUS, ITEM_TYPES, SAMPLE_OPTIONS, Y_AXIS_TYPES } from '@/trendData/trendData.module';
import { TREND_TOOLS } from '@/investigate/investigate.module';

angular.module('Sq.TrendData').store('sqTrendMetricStore', sqTrendMetricStore);

export type TrendMetricStore = ReturnType<typeof sqTrendMetricStore>['exports'];

function sqTrendMetricStore(
  sqBaseItemStore: BaseItemStoreService
) {
  const store = {
    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @returns {Object} The dehydrated items.
     */
    dehydrate() {
      return {
        items: _.chain(this.state.get('items'))
          .filter(sqBaseItemStore.shouldDehydrateItem)
          .map(item => _.pick(item, sqBaseItemStore.PROPS_TO_DEHYDRATE))
          .value()
      };
    },

    /**
     * Rehydrates the metric items
     *
     * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(_.omit(dehydratedState, ['items']));
      this.state.set('items', _.map(dehydratedState.items,
        item => this.createMetric(item.id, item.name, item.lane, item.alignment, _.omit(item, ['id', 'name']))));
    },

    handlers: {
      TREND_ADD_METRIC: 'addMetric'
    },

    /**
     * Adds a Metric item to the specified panel.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.id- The id of the metric
     * @param {String} payload.name - The name of the metric
     * @param {String} payload.lane - Lane of the new item
     * @param {String} payload.alignment - Alignment of the new item
     * @param {String} [payload.color] - optional color.
     */
    addMetric(payload) {
      this.state.push('items',
        this.createMetric(payload.id, payload.name, payload.lane, payload.alignment, _.pick(payload, ['color'])));
    },

    /**
     * Creates a metric item.
     *
     * @param {String} id - The id of the metric
     * @param {String} name - The name of the metric
     * @param {String} lane - Lane to use for the new series
     * @param {String} alignment - Alignment to use for the new series
     * @param {Object[]} [props] - Array of properties to be set on the item.
     * @return {Object} an table item.
     */
    createMetric(id, name, lane, alignment, props) {
      return this.createItem(id, name, ITEM_TYPES.METRIC, _.assign({
        calculationType: TREND_TOOLS.THRESHOLD_METRIC,
        yAxisConfig: {},
        dashStyle: _.get(props, 'dashStyle', DASH_STYLES.SOLID),
        lane,
        axisAlign: alignment,
        axisVisibility: _.get(props, 'axisVisibility', true),
        axisAutoScale: _.get(props, 'axisAutoScale', true),
        lineWidth: _.get(props, 'lineWidth', 1),
        sampleDisplayOption: props.sampleDisplayOption || SAMPLE_OPTIONS.LINE,
        dataStatus: ITEM_DATA_STATUS.INITIALIZING,
        yAxisType: Y_AXIS_TYPES.LINEAR,
        data: [],
        statusMessage: ''
      }, props));
    },
  };

  return sqBaseItemStore.extend(store);
}
