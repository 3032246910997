import React, { useMemo } from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import classNames from 'classnames';
import _ from 'lodash';

export interface ButtonItem {
  label: string;
  id: string;
  value: string;
}

/**
 * An input component with a group of buttons corresponding to selectable options
 */
export interface ButtonGroupProps {
  buttonItems: ButtonItem[];
  name: string;
  value: string;
  onChange: (value: string) => void;
  testId?: string;
}

export const ButtonGroup: React.FunctionComponent<ButtonGroupProps> = (
  { buttonItems, name, value, onChange, testId }
) => {
  const selected = useMemo(() => value || buttonItems[0]?.value, [value, buttonItems]);
  const { t } = useTranslation();

  const handleItemClick = (buttonItem: ButtonItem) => () => {
    onChange(buttonItem.value);
  };

  return (
    <div className="btn-group mt5" data-testid={testId}>
      {_.map(buttonItems, buttonItem => (
        <label
          data-name={name}
          key={buttonItem.id}
          id={buttonItem.id}
          onClick={handleItemClick(buttonItem)}
          className={classNames('btn btn-default btn-xs pt3 min-height-25 min-width-70 default-layer',
            buttonItem.value === selected ? 'active' : '')}
        >
          {t(buttonItem.label)}
        </label>
      ))}
    </div>
  );
};
