import _ from 'lodash';
import * as angular from 'angular';
import { FieldDelimiterEnum, ItemTypeEnum, ValidationModeEnum } from 'sdk/model/DatafileInputV1';
import { ImportDatafileActions } from '@/hybrid/tools/importDatafile/importDatafile.actions';
import { sqImportDatafileStore } from '@/hybrid/tools/importDatafile/importDatafile.store';

export const FILE_OUTPUT_TYPES = [
  {
    text: 'IMPORTS.FILE_OUTPUT_TYPES.REPLACE',
    value: 'Replace'
  }, {
    text: 'IMPORTS.FILE_OUTPUT_TYPES.APPEND',
    value: 'Append'
  }
];

export const VALIDATION_MODES = [
  {
    text: 'IMPORTS.VALIDATION_MODE_TYPES.FAIL',
    value: ValidationModeEnum.Fail
  }, {
    text: 'IMPORTS.VALIDATION_MODE_TYPES.SKIP',
    value: ValidationModeEnum.Skip
  }, {
    text: 'IMPORTS.VALIDATION_MODE_TYPES.INVALID',
    value: ValidationModeEnum.Invalid
  }
];

export const INTERPOLATION_METHODS = [
  {
    text: 'IMPORTS.INTERPOLATION.LINEAR',
    value: 'Linear'
  }, {
    text: 'IMPORTS.INTERPOLATION.STEP',
    value: 'Step'
  }, {
    text: 'IMPORTS.INTERPOLATION.PILINEAR',
    value: 'PILinear'
  }, {
    text: 'IMPORTS.INTERPOLATION.DISCRETE',
    value: 'None'
  }
];

export const DELIMITERS = [
  { text: ',', value: FieldDelimiterEnum.Comma },
  { text: ';', value: FieldDelimiterEnum.Semicolon },
  { text: 'Tab', value: FieldDelimiterEnum.Tab }
];

export const DAY_FIRST = { text: 'IMPORTS.FIRST.DAY', value: true };
export const MONTH_FIRST = { text: 'IMPORTS.FIRST.MONTH', value: false };
export const DAY_MONTH_FIRST_OPTIONS = [DAY_FIRST, MONTH_FIRST];

export const COLUMN_INDEX = { text: 'IMPORTS.VALUE_COLUMN.INDEX', value: 'Index' };
export const COLUMN_NAME = { text: 'IMPORTS.VALUE_COLUMN.NAME', value: 'Name' };

export const SIGNAL_VALUE_COLUMN_OPTIONS = [
  { text: 'IMPORTS.VALUE_COLUMN.ALL', value: 'All' },
  COLUMN_INDEX,
  COLUMN_NAME
];

export const CONDITION_VALUE_COLUMN_OPTIONS = _.concat(SIGNAL_VALUE_COLUMN_OPTIONS,
  { text: 'IMPORTS.VALUE_COLUMN.NONE', value: 'None' });

export const APPEND = 'Append';

export const EMPTY_ROW_IDENTIFIER = 0;

export const DATAFILE_ITEM_TYPES = [
  { text: 'IMPORTS.SIGNAL', value: ItemTypeEnum.Signal },
  { text: 'IMPORTS.CONDITION', value: ItemTypeEnum.Condition }
];

// This is a function as opposed to a default import due to angular trying to load this module before the ES6 class
// for ImportDatafileActions is loaded by JS, which results in the injected actions being undefined. By making this
// a function, the ES6 class will be loaded by the time angular loads the module.
export default () => angular.module('Sq.ImportDatafile', ['Sq.Investigate'])
  .store('sqImportDatafileStore', sqImportDatafileStore)
  .service('sqImportDatafileActions', ImportDatafileActions);

