import angular from 'angular';
import { LicenseManagementStore } from '@/licenseManagement/licenseManagement.store';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { LICENSE_STATUS } from '@/licenseManagement/licenseManagement.module';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';

angular.module('Sq.LicenseManagement')
  .controller('EmailLicenseCtrl', EmailLicenseCtrl);

function EmailLicenseCtrl(
  $scope: ng.IScope,
  $window: ng.IWindowService,
  $translate: ng.translate.ITranslateService,
  sqWorkbenchStore: WorkbenchStore,
  sqLicenseManagementStore: LicenseManagementStore,
  sqSystemConfiguration: SystemConfigurationService
) {

  const vm = this;
  vm.LICENSE_STATUS = LICENSE_STATUS;
  vm.infoTextPart2TranslationKey = infoTextPart2TranslationKey;
  vm.emailLicenseRequest = emailLicenseRequest;
  vm.getEmailTemplate = getEmailTemplate;
  vm.doDisplay = doDisplay;

  vm.adminContactName = sqSystemConfiguration.adminContactName;
  vm.adminContactEmail = sqSystemConfiguration.adminContactEmail;

  $scope.$listenTo(sqLicenseManagementStore, syncLicenseStore);
  $scope.$listenTo(sqWorkbenchStore, ['currentUser'], syncUser);

  /**
   * Helper to determine if the email area should be shown.
   *
   * @returns {Boolean} true if it should display, false otherwise.
   */
  function doDisplay() {
    return vm.currentUser.isAdmin;
  }

  /**
   * Finds the appropriate translation key for the current license status.
   *
   * @returns {String} the translation key to use for the translation.
   */
  function infoTextPart2TranslationKey() {
    if (vm.isTrialLicense) {
      return 'LICENSE.PURCHASE_TRIAL';
    } else {
      if (vm.licenseStatus === LICENSE_STATUS.UNKNOWN) {
        return 'LICENSE.UNKNOWN_LICENSE_ADMIN';
      } else if (vm.licenseStatus === LICENSE_STATUS.NONE) {
        return 'LICENSE.NO_LICENSE_INFO_TEXT_ADMIN';
      } else {
        return 'LICENSE.EXPIRING_LICENSE_INFO_TEXT_PART2';
      }
    }
  }

  /**
   * Syncs the Workbenchstore.
   */
  function syncUser() {
    vm.currentUser = sqWorkbenchStore.currentUser;
  }

  /**
   * Syncs the LicenseManagement Store.
   */
  function syncLicenseStore() {
    vm.licenseStatus = sqLicenseManagementStore.licenseStatus;
    if (sqLicenseManagementStore.license) {
      vm.licenseLevel = sqLicenseManagementStore.license.level;
      vm.licenseValidity = sqLicenseManagementStore.license.validity;
      vm.host = sqLicenseManagementStore.license.hostname;
    }

    vm.isTrialLicense = sqLicenseManagementStore.isTrial;
  }

  /**
   * Builds the email template to be used for the email message based on the current license status.
   *
   * @returns {String} the email template.
   */
  function getEmailTemplate() {
    const br = '<br>';
    let template = $translate.instant('LICENSE.ADMIN_EMAIL_TEMPLATE.HELLO');
    template = template.concat(br);

    if (vm.licenseStatus === LICENSE_STATUS.UNKNOWN) {
      template = template.concat(
        $translate.instant('LICENSE.ADMIN_EMAIL_TEMPLATE.TALK_ISSUE',
          { LEVEL: vm.licenseLevel, LICENSE_HOST: vm.host }),
        ' ',
        $translate.instant('LICENSE.ADMIN_EMAIL_TEMPLATE.ISSUE_DETAILS', { LICENSE_VALIDITY: vm.licenseValidity }));
    } else if (vm.licenseStatus === LICENSE_STATUS.SHOW_ADMIN_LICENSE_WARNING ||
      vm.licenseStatus === LICENSE_STATUS.SHOW_LICENSE_WARNING || vm.licenseStatus === LICENSE_STATUS.EXPIRED) {
      if (vm.isTrialLicense) {
        template = template.concat(
          $translate.instant('LICENSE.ADMIN_EMAIL_TEMPLATE.TALK_PURCHASE', { LICENSE_HOST: vm.host }));
      } else {
        template = template.concat(
          $translate.instant('LICENSE.ADMIN_EMAIL_TEMPLATE.TALK_RENEWAL', { LICENSE_HOST: vm.host }));
      }
    } else if (vm.licenseStatus === LICENSE_STATUS.VALID) {
      if (vm.isTrialLicense) {
        template = template.concat(
          $translate.instant('LICENSE.ADMIN_EMAIL_TEMPLATE.TALK_PURCHASE', { LICENSE_HOST: vm.host }));
      } else {
        template = template.concat(
          $translate.instant('LICENSE.ADMIN_EMAIL_TEMPLATE.TALK_NEW', { LICENSE_HOST: vm.host }));
      }
    } else {
      template = template.concat(
        $translate.instant('LICENSE.ADMIN_EMAIL_TEMPLATE.TALK_REQUEST', { LICENSE_HOST: vm.host }));
    }

    template = template.concat(br, $translate.instant('LICENSE.ADMIN_EMAIL_TEMPLATE.THANKS'), br, vm.adminContactName,
      br, vm.adminContactEmail);

    return template;
  }

  /**
   * Opens the Email Editor with the anticipated email text.
   */
  function emailLicenseRequest() {
    const emailBody = getEmailTemplate();
    vm.licenseRequestSent = true;
    $window.open('mailto:support@seeq.com?subject=License Request&body=' + emailBody.replace(/<br>/g, '%0D%0A'),
      '_self');
  }
}
