import React from 'react';
import _ from 'lodash';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import classNames from 'classnames';
import { HelpIcon } from '@/hybrid/core/Icon.atom';

export interface LabelIF extends ValidatingFormComponent<string> {
  component: 'LabelFormComponent';
  noMarginBottom?: boolean;
  onIconClick?: () => any;
}

const LabelFormComponentUnwrapped: React.FunctionComponent<LabelIF> = (props) => {
  const {
    value,
    tooltip,
    onIconClick,
    testId,
    extraClassNames,
    noMarginBottom,
    tooltipDelay
  } = props;
  const { t } = useTranslation();

  return (
    <div className={classNames('flexColumnContainer', extraClassNames, { mb8: !noMarginBottom })} data-testid={testId}>
      {t(value)}
      {tooltip &&
      <HelpIcon
        tooltip={tooltip}
        tooltipDelay={tooltipDelay}
        extraClassNames="pl5"
        testId={`${testId}_icon`}
        large={true}
        onClick={_.isFunction(onIconClick) ? onIconClick : _.noop}
      />}
    </div>);
};

export const LabelFormComponent = React.memo(LabelFormComponentUnwrapped,
  (prev, next) => !(
    prev.value !== next.value ||
    prev.tooltip !== next.tooltip ||
    next.extraClassNames !== prev.extraClassNames
  ));

