import angular from 'angular';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';

angular.module('Sq.SystemWarning')
  .store('sqSystemWarningStore', sqSystemWarningStore);

export type SystemWarningStore = ReturnType<typeof sqSystemWarningStore>['exports'];

function sqSystemWarningStore() {
  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.NONE,
    initialize() {
      this.state = this.immutable({
        warningComponents: [],
        systemMessage: '',
        showSystemWarning: true,
        suppressUserLimitWarning: false
      });
    },

    exports: {
      get warningComponents() {
        return this.state.get('warningComponents');
      },
      get systemMessage() {
        return this.state.get('systemMessage');
      },
      get showSystemWarning() {
        return this.state.get('showSystemWarning');
      },
      get suppressUserLimitWarning() {
        return this.state.get('suppressUserLimitWarning');
      }
    },

    handlers: {
      SYSTEM_WARNINGS_SET_WARNING_COMPONENTS: 'setWarningComponents',
      SYSTEM_WARNINGS_SET_SYSTEM_MESSAGE: 'setSystemMessage',
      SYSTEM_WARNINGS_DISABLE_SYSTEM_WARNING: 'disableSystemWarning',
      SYSTEM_WARNINGS_SUPPRESS_USER_LIMIT_WARNING: 'suppressUserLimitWarning'
    },

    /**
     * Sets the components that are in a warning state
     *
     * @param {String[]} components - an array of component names that are in a warning state.
     * For instance, ['CPU', 'Memory', 'Disk'].
     */
    setWarningComponents(components) {
      this.state.set('warningComponents', components);
    },

    /**
     * Sets the system message.
     *
     * @param {Object} payload
     * @param {string} payload.message - the system admin message.
     */
    setSystemMessage(payload) {
      this.state.set('systemMessage', payload.message);
    },

    /**
     * Stops the system warning from being displayed
     */
    disableSystemWarning() {
      this.state.set('showSystemWarning', false);
    },

    /**
     * Hides the user limit warning
     */
    suppressUserLimitWarning() {
      this.state.set('suppressUserLimitWarning', true);
    }
  };

  return store;
}
