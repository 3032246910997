import _ from 'lodash';
import { BaseToolStoreService } from '@/investigate/baseToolStore.service';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { DURATION_TIME_UNITS } from '@/main/app.constants';

export type PeriodicConditionStore = ReturnType<typeof sqPeriodicConditionStore>['exports'];

export function sqPeriodicConditionStore(
  sqBaseToolStore: BaseToolStoreService,
  sqWorkbenchStore: WorkbenchStore,
) {
  const store = {
    initialize() {
      this.state = this.immutable(_.assign({}, sqBaseToolStore.COMMON_PROPS, {
        timezone: sqWorkbenchStore.userTimeZone?.name,
        duration: undefined,
        frequencies: [],
        weekStart: 'Day.Sunday',
        quarterStart: { month: 'Month.January', day: 1 },
        shift: { offset: 8, duration: 8 },
        offset: { value: 0, units: DURATION_TIME_UNITS[1].unit[0] }
      }));
    },

    exports: {
      get duration() {
        return this.state.get('duration');
      },

      get timezone() {
        return this.state.get('timezone');
      },

      get frequencies() {
        return this.state.get('frequencies');
      },

      get shift() {
        return this.state.get('shift');
      },

      get weekStart() {
        return this.state.get('weekStart');
      },

      get quarterStart() {
        return this.state.get('quarterStart');
      },

      get offset() {
        return this.state.get('offset');
      },

      /**
       * Compute the formula that this tool represents.
       *
       * @returns {Object} Object with properties of "formula" which is the string for the formula and
       *   "parameters" which are all parameters used in the formula (none)
       */
      getFormula() {
        const duration = this.state.get('duration');
        const frequencies = this.state.get('frequencies');
        let operatorName = duration;
        let move = '';
        let args = [];
        const fb = this.formulaBuilder;
        if (duration === 'shifts') {
          args.push(this.state.get('shift').offset);
          args.push(this.state.get('shift').duration);
        } else if (duration === 'weeks') {
          args.push(this.state.get('weekStart'));
        } else if (duration === 'quarters') {
          args.push(this.state.get('quarterStart').month);
          args.push(this.state.get('quarterStart').day);
        }

        const tz = this.state.get('timezone');
        if (tz) {
          args.push(`"${tz}"`);
        }

        if (frequencies.length === 1) {
          // put the specific enum at the first argument
          args.unshift(_.head(frequencies));
        } else if (frequencies.length > 1) {
          // make a list of conditions, one per frequency
          args = _.map(frequencies, function(frequency) {
            return fb.operator(operatorName, [frequency].concat(args));
          });
          // now switch to the operator that combines all these
          operatorName = 'combineWith';
        }

        if (this.state.get('offset')?.value !== 0) {
          move = '.' + fb.operator('move', [fb.duration(this.state.get('offset'))]);
        }

        return {
          formula: fb.operator(operatorName, args) + move,
          parameters: {}
        };
      }
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @return {Object} State for the store
     */
    dehydrate() {
      return this.state.serialize();
    },

    /**
     * Sets the references panel state
     *
     * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      PERIODIC_CONDITION_DURATION: 'setDuration',
      PERIODIC_CONDITION_TIMEZONE: 'setTimezone',
      PERIODIC_CONDITION_TOGGLE_FREQUENCY: 'toggleFrequency',
      PERIODIC_CONDITION_WEEK_START: 'setWeekStart',
      PERIODIC_CONDITION_QUARTER_START: 'setQuarterStart',
      PERIODIC_CONDITION_SHIFT: 'setShift',
      PERIODIC_CONDITION_OFFSET: 'setOffset'
    },

    /**
     * Set the period of the condition. Resets the frequency choices if the duration changes.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.duration - One of the keys from STANDARD_DURATIONS
     */
    setDuration(payload) {
      if (this.state.get('duration') !== payload.duration) {
        this.state.set('frequencies', []);
      }

      this.state.set('duration', payload.duration);
    },

    /**
     * Set the timezone of the condition.
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.timezone - Name of the timezone
     */
    setTimezone(payload) {
      this.state.set('timezone', payload.timezone);
    },

    /**
     * Set the day that the week starts on. Only useful for duration of weeks()
     *
     * @param {Object} payload - Object container
     * @param {String} payload.weekStart - The day enum of the week start
     */
    setWeekStart(payload) {
      this.state.set('weekStart', payload.weekStart);
    },

    /**
     * Set the day that the quarter starts on. Only useful for duration of quarters()
     *
     * @param {Object} payload - Object container
     * @param {String} payload.month - The month enum of the quarter start
     * @param {String} payload.day - The number of the first day in the month of the quarter
     */
    setQuarterStart(payload) {
      this.state.set('quarterStart', { month: payload.month, day: payload.day });
    },

    /**
     * Set the parameters of the shift operator
     *
     * @param {Object} payload - Object container
     * @param {String} payload.offset - The number of hours after midnight to start
     * @param {String} payload.duration - The number of the hours in the shift. Must be an integer
     */
    setShift(payload) {
      this.state.set('shift', { offset: payload.offset, duration: payload.duration });
    },

    /**
     * Adds or removes the specified frequency from the frequencies list.
     *
     * @param {Object} payload - Object container
     * @param {String} [payload.frequency] - The frequency to toggle. If not provided the list will be cleared.
     */
    toggleFrequency(payload) {
      const index = _.indexOf(this.state.get('frequencies'), payload.frequency);
      if (!payload.frequency) {
        this.state.set('frequencies', []);
      } else if (index >= 0) {
        this.state.splice('frequencies', [index, 1]);
      } else {
        this.state.push('frequencies', payload.frequency);
      }
    },

    /**
     * Set an offset that can be used to shift the start of the of the capsules.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.value - The number that indicates how long the offset is
     * @param {String} payload.units - The units that the value represents
     */
    setOffset(payload) {
      if (_.isNil(payload.value) && _.isNil(payload.units)) {
        this.state.set('offset', {});
      } else {
        this.state.set('offset', { value: payload.value, units: payload.units });
      }
    }
  };

  return sqBaseToolStore.extend(store, TREND_TOOLS.PERIODIC_CONDITION);
}
