import React from 'react';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { TrendScalarStore } from '@/trendData/trendScalar.store';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import _ from 'lodash';
import { ItemsApi } from 'sdk/api/ItemsApi';
import { TrendActions } from '@/trendData/trend.actions';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { TrendTableStore } from '@/trendData/trendTable.store';
import { TrendMetricStore } from '@/trendData/trendMetric.store';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { Placement } from 'react-bootstrap/Overlay';

const addToDisplayPaneBindings = bindingsDefinition({
  itemId: prop<string>(),
  tooltip: prop.optional<string>(),
  tooltipPlacement: prop.optional<Placement>(),
  afterAdd: prop.optional<(itemId: string) => Promise<void> | void>(),
  sqTrendActions: injected<TrendActions>(),
  sqItemsApi: injected<ItemsApi>(),
  sqTrendSeriesStore: injected<TrendSeriesStore>(),
  sqTrendScalarStore: injected<TrendScalarStore>(),
  sqTrendCapsuleSetStore: injected<TrendCapsuleSetStore>(),
  sqTrendTableStore: injected<TrendTableStore>(),
  sqTrendMetricStore: injected<TrendMetricStore>(),
  sqTrendDataHelper: injected<TrendDataHelperService>()
});

export const AddToDisplayPane: SeeqComponent<typeof addToDisplayPaneBindings> = (props) => {
  const {
    itemId,
    tooltip,
    tooltipPlacement,
    afterAdd
  } = props;
  const {
    sqTrendActions,
    sqItemsApi,
    sqTrendSeriesStore,
    sqTrendScalarStore,
    sqTrendCapsuleSetStore,
    sqTrendTableStore,
    sqTrendMetricStore,
    sqTrendDataHelper
  } = useInjectedBindings(addToDisplayPaneBindings);

  // getAllItems uses these stores to source items, so we must respond to changes in those stores
  useFlux(sqTrendSeriesStore);
  useFlux(sqTrendScalarStore);
  useFlux(sqTrendCapsuleSetStore);
  useFlux(sqTrendTableStore);
  useFlux(sqTrendMetricStore);

  const trendItems = sqTrendDataHelper.getAllItems();

  if (_.some(trendItems, { id: itemId })) {
    return null;
  }

  const addToDisplay = (event) => {
    sqItemsApi.getItemAndAllProperties({ id: itemId })
      .then(({ data: item }) => sqTrendActions.addItem(item))
      .then(() => afterAdd ? afterAdd(itemId) : undefined);
  };

  return (
    <HoverTooltip placement={tooltipPlacement || 'right'} text={tooltip || 'ADD_TO_DISPLAY'}>
      <a data-testid="addToDisplay"
        className="displayIcon a fa fa-area-chart link-no-underline animated animate-if-opacity pt2 pl5 force-link-color"
        onClick={addToDisplay} />
    </HoverTooltip>
  );
};

export const sqAddToDisplayPane = angularComponent(addToDisplayPaneBindings, AddToDisplayPane);
