import _ from 'lodash';
import angular, { IPromise } from 'angular';
import moment from 'moment-timezone';
import { SOCKET_LIVELINESS_TIMEOUT, SocketService } from '@/services/socket.service';
import { AsyncResponsesService } from '@/services/asyncResponses.service';
import { LoggerService } from '@/services/logger.service';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';
import { PUSH_IGNORE } from '@/services/stateSynchronizer.service';
import { HttpHelpersService } from '@/services/httpHelpers.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';

const dependencies = [
  'Sq.AppConstants',
  'Sq.Services.Socket',
  'Sq.Services.AsyncResponses',
  'Sq.Services.Utilities',
  'Sq.Services.HttpHelpers'
];

export const CANCELLATION_GROUP_GUID_SEPARATOR = ':';
angular.module('Sq.Services.PendingRequests', dependencies)
  .factory('sqPendingRequests', sqPendingRequests)
  .factory('cancellationInterceptor', cancellationInterceptor);

export type PendingRequestsService = ReturnType<typeof sqPendingRequests>;

function sqPendingRequests(
  $injector: ng.auto.IInjectorService,
  $q: ng.IQService,
  flux: ng.IFluxService,
  sqSocket: SocketService,
  sqAsyncResponses: AsyncResponsesService,
  sqLogger: LoggerService,
  sqHttpHelpers: HttpHelpersService
) {
  // Mapping of unique request id to an object that holds a promise that can be resolved to cancel a request and the
  // group of requests with which it is associated
  const cancelers = {} as any;
  let unsubscribe: () => void = _.noop;
  // Array of promises that must either complete or abort before we can switch worksheets.
  let volatilePromises = [];
  const service = {
    add,
    get,
    getAllRequestIds,
    remove,
    cancel,
    cancelAll,
    cancelGroup,
    cancelCurrentUserServerRequests,
    cancelAllServerRequests,
    updateProgress,
    evaluateMissingAsyncResponse,
    count,
    subscribe,
    registerVolatilePromise,
    get hasVolatilePromises() {
      return volatilePromises.length > 0;
    }
  };

  return service;

  /**
   * Gets the total number of active cancellable requests.
   *
   * @param {String} [group] - if provided only count requests within that group
   * @returns {Number} - the total number of active cancellable requests
   */
  function count(group?) {
    return _.isNil(group) ? _.keys(cancelers).length : _.chain(cancelers)
      .pickBy(request => _.includes(request.groups, group))
      .keys()
      .thru(requestIds => requestIds.length)
      .value();
  }

  /**
   * Returns an array of all IDs for all outstanding requests
   *
   * @returns {String[]} of IDs for all outstanding requests
   */
  function getAllRequestIds() {
    return _.keys(cancelers);
  }

  /**
   * Creates a new deferred instance and adds it to the cancelers map.
   *
   * @param {String} id - Unique request identifier for the http request
   * @param {Object} config - The $http config
   * @param {Boolean} [config.cancelOnServer=true] - If false then request will not be canceled on the backend when it
   *                 is canceled in the browser.
   * @param {Object[]} [groups] - One or more groups to which the canceler belongs. For use with cancelGroup()
   * @return {Promise} Unresolved promise that can be used as the `timeout` parameter for an http request.
   */
  function add(id, config, groups?) {
    _.defaults(config, { cancelOnServer: true });

    cancelers[id] = { groups, deferred: $q.defer(), config };
    return cancelers[id].deferred.promise;
  }

  /**
   * Returns a canceler object for the specified request
   *
   * @param  {String} id - Unique request identifier
   * @return {Object[]} The deferred and groups for the request
   */
  function get(id) {
    return cancelers[id];
  }

  /**
   * Removes the canceler identified by the specified id. Should be called when a request returns.
   * Updates the progress only to ensure we do not over-write the timing and meter Information that is still
   * required to display request details.
   *
   * @param  {String} id - Unique request identifier
   */
  function remove(id) {
    service.updateProgress(id, { progress: undefined });

    delete cancelers[id];
  }

  /**
   * Resolves the canceler for the specified request. Suppresses 404 errors, which indicate that the request is no
   * longer running.
   *
   * @param  {String} id - Unique request identifier
   * @param {Boolean} [localOnly=false] - optionally cancel browser requests only
   * @param {Boolean} [refetching=false] - caller should supply true for items that display a cancelled indication
   * to the user when the cancellation call is just prior to another call to fetch the group again (e.g. signals in
   * trend actions). The additional context allows us to guard against erroneously setting the trend item data
   * status to cancelled (see sqTrendActions.catchItemDataFailure() for more details).
   */
  function cancel(id, localOnly = false, refetching = false) {
    const canceler = service.get(id);
    if (canceler) {
      canceler.config.refetching = refetching;
      canceler.deferred.resolve();
      sqAsyncResponses.cancelWait(id);
      service.remove(id);

      if (canceler.config.cancelOnServer && !localOnly) {
        return sqHttpHelpers.cancelRunningRequest(id);
      }
    }
  }

  /**
   * Cancels all registered requests
   *
   * @param {Boolean} [localOnly=false] - optionally cancel browser requests only
   * @param {Boolean} [refetching=false] - caller should supply true for items that display a cancelled indication
   * @return {Promise} Resolves when all the requests have been cancelled
   */
  function cancelAll(localOnly = false, refetching = false) {
    return _.chain(cancelers)
      .keys()
      .map(requestId => service.cancel(requestId, localOnly, refetching))
      .thru(requests => $q.all(requests))
      .value();
  }

  /**
   * Cancels all registered requests for the specified group
   *
   * @param {String} group - The group to which the request belongs
   * @param {Boolean} [refetching=false] - caller should supply true for items that display a cancelled indication
   * to the user when the cancellation call is just prior to another call to fetch the group again (e.g. signals in
   * trend actions). The additional context allows us to guard against erroneously setting the trend item data
   * status to cancelled (see sqTrendActions.catchItemDataFailure() for more details).
   * @return {Promise} Resolves when all the requests have been cancelled
   */
  function cancelGroup(group, refetching = false) {
    return _.chain(cancelers)
      .pickBy(request => _.includes(request.groups, group))
      .keys()
      .map(requestId => service.cancel(requestId, false, refetching))
      .thru(requests => $q.all(requests))
      .value();
  }

  /**
   * Cancels all server requests from the current user
   *
   * @return {Promise} a promise that gets fulfilled when request completes
   */
  function cancelCurrentUserServerRequests() {
    service.cancelAll(true);
    return $injector.get<ng.IHttpService>('$http').delete(APPSERVER_API_PREFIX + '/requests/me');
  }

  /**
   * Cancels all server requests
   * @param {Boolean} [refetching=false] - caller should supply true for items that display a cancelled indication
   * @return {Promise} a promise that gets fulfilled when request completes
   */
  function cancelAllServerRequests(refetching = false) {
    service.cancelAll(true, refetching);
    return $injector.get<ng.IHttpService>('$http').delete(APPSERVER_API_PREFIX + '/requests');
  }

  /**
   * Updates the progress and if requested the timing and meter information of the request in the items stores. Also
   * resets the canceler expiresAt property to be the current time plus a grace period.
   *
   * @param  {String} id - Unique request identifier
   * @param  {Object} info - Object containing properties to update
   * @param  {Number|undefined} [info.progress] - the percent progress of the request
   * @param  {String} [info.timingInformation] - String providing information displayed in the "Time" section of the
   *   Request Details Panel.
   * @param  {String} [info.meterInformation] - String providing information displayed in the "Samples Read" section
   *   of the Request Details Panel.
   */
  function updateProgress(id, info) {
    const canceler = service.get(id);
    if (canceler) {
      _.chain(canceler.groups)
        .flatMap(group => _.split(group, CANCELLATION_GROUP_GUID_SEPARATOR))
        .forEach((group) => {
          flux.dispatch('TREND_SET_PROGRESS', _.assign({}, { id: group }, info), PUSH_IGNORE);
        })
        .value();

      // If we've received progress for an async pending request then update the missing response expiration time
      if (sqAsyncResponses.isOutstandingRequest(id)) {
        canceler.expiresAt = moment.utc().add(SOCKET_LIVELINESS_TIMEOUT);
      }
    }
  }

  /**
   * Determines if an async request that is known to this service but is missing from the received progress message is
   * actually still in progress or not. If it is not still in progress, then it needs to be cancelled or it will remain
   * forever in the current state (e.g. loading). We wait for a grace period to expire before making an http call to
   * the backend to determine if the request is still in progress. This is required because backend garbage collection
   * or other slowdown may cause the server to stall for a short interval and then we can receive multiple progress
   * messages that don't yet contain request IDs for requests that have been made but not yet processed by the backend.
   *
   * @param {String} requestId - ID of the request that is being evaluated.
   */
  function evaluateMissingAsyncResponse(requestId: string) {
    const canceler = service.get(requestId);

    // Only continue processing if the request is known and asynchronous
    if (!canceler || !sqAsyncResponses.isOutstandingRequest(requestId)) {
      return;
    }

    // Set the missing response expiration time if it does not already exist. This expiration time is also updated in
    // .updateProgress() whenever we receive a valid progress update for the asynchronous request.
    if (_.isUndefined(canceler.expiresAt)) {
      canceler.expiresAt = moment.utc().add(SOCKET_LIVELINESS_TIMEOUT);
    }

    // If the grace period has expired then query the backend to see if the request is still in progress
    if (moment.utc() > canceler.expiresAt) {
      // Clear response expiration time so we won't check with the backend again until after another grace period
      delete canceler.expiresAt;

      // Make a call to the backend to see if the request has been cancelled
      $injector.get<ng.IHttpService>('$http').get(`${APPSERVER_API_PREFIX}/requests/${encodeURIComponent(requestId)}`)
        .catch(() => {
          service.cancel(requestId, true);
          sqLogger.error('Cancelled missing request: ' + requestId);
        });
    }
  }

  /**
   * Subscribes to request messages for this session. Also unsubscribes from the subscription to the previous
   * interactive session, if present.
   *
   * @param {String} sessionId - Interactive session ID that identifies this client connection.
   */
  function subscribe(sessionId) {
    unsubscribe();
    unsubscribe = sqSocket.subscribe({
      channelId: [
        SeeqNames.Channels.RequestsProgress,
        sessionId
      ],
      onMessage: onProgressMessage,
      useSubscriptionsApi: false // Channel is auto-subscribed by the backend
    });
  }

  /**
   * Internal handler for request messages. Updates the progress of associated requests or evaluates if an
   * outstanding pending async request is missing.
   *
   * @param {Object} message - Data received from websocket
   */
  function onProgressMessage(message) {
    const outstandingRequests = service.getAllRequestIds();
    _.forEach(outstandingRequests, (requestId) => {
      const requestUpdate = _.find(message.requests, { requestId }) as any;
      if (requestUpdate) {
        const payload = _.pick(requestUpdate, ['timingInformation', 'meterInformation']) as any;
        if (requestUpdate.totalCount) {
          payload.progress = (requestUpdate.completedCount / requestUpdate.totalCount * 100).toFixed(0);
        }
        service.updateProgress(requestId, payload);
      } else {
        service.evaluateMissingAsyncResponse(requestId);
      }
    });
  }

  /**
   * Keep track of promises that could corrupt the state if we switch worksheets before the promises resolve.
   * This method adds the given promise to volatilePromises, which can be accessed elsewhere (i.e. in app.module).
   * When a promise finishes, that promise is removed from volatilePromises.
   *
   * @param promise - the promise to keep track of
   */
  function registerVolatilePromise(promise: IPromise<any>) {
    volatilePromises.push(promise);
    promise.finally(() => {
      volatilePromises = _.pull(volatilePromises, promise);
    });
  }
}

function cancellationInterceptor($q, sqUtilities, sqPendingRequests, sqHttpHelpers) {
  return sqHttpHelpers.wrapInterceptor(cancellationInterceptor.name, { request, response, responseError });

  /**
   * A $http request interceptor that looks for a custom configuration parameter, `cancellationGroup`, and adds a
   * cancellation promise to the request if it is present. This promise is used in the .response and .responseError
   * handlers to clear the request from sqPendingRequests.
   * For requests that area cancelable this interceptor also ensures that the headers are available as part of the
   * response. Header details are necessary to display the Request Details pop-up for items.
   *
   * @param {Object} config - The $http config
   * @param {String} config.cancellationGroup - The group of which this request is part. If specified a unique request
   *                 id will be associated with the request and a promise added to the timeout property. The group
   *                 prefix can be combined with a guid using CANCELLATION_GROUP_GUID_SEPARATOR to allow tracking
   *                 the progress of an item that does not have the guid embedded in the URL, such as the measured
   *                 item of a threshold metric.
   * @param {Boolean} [config.cancelOnServer=true] - If false then request will not be canceled on the backend when it
   *                 is canceled in the browser.
   * @returns {Object} The $http config
   */
  function request(config) {
    if (config.cancellationGroup) {
      let requestId = config.headers[SeeqNames.API.Headers.RequestId];
      if (!requestId) {
        requestId = sqUtilities.generateRequestId();
        config.headers[SeeqNames.API.Headers.RequestId] = requestId;
      }
      config.timeout = sqPendingRequests.add(
        requestId,
        config,
        sqUtilities.extractGuids(config.url)
          .concat(sqUtilities.extractGuids(JSON.stringify(config.params)))
          .concat([config.cancellationGroup]));
    }

    return config;
  }

  function response(response) {
    removeRequestCancellation(response);
    return response;
  }

  function responseError(response) {
    removeRequestCancellation(response);
    return $q.reject(response);
  }

  function removeRequestCancellation(response) {
    const requestId = response.config.headers[SeeqNames.API.Headers.RequestId];
    if (requestId && response.config.cancellationGroup) {
      sqPendingRequests.remove(requestId);
    }
  }
}
