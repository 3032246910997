import angular from 'angular';
import template from './licenseUpload.component.html';

angular.module('Sq.LicenseManagement')
  .component('sqLicenseUpload', {
    template,
    controller: 'LicenseUploadController',
    bindings: {
      first: '<',
      showSuccess: '=',
      uploadComplete: '='
    }
  });
