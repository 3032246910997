import angular from 'angular';

/**
 * @file
 *
 * The sq-faux-select directive enables replacing a traditional <select> element with custom elements (for example using
 * uib-dropdown). At first glance it might seem like it doesn't do much, however this directive acts like glue between
 * ng-model and whatever is represented in the dropdown. This enables all of the goodness that goes with ng-model
 * (participation in form validation, `ng-change`, etc) with all the flexibility of styling the components
 *
 * To use the component include the sq-faux-select attribute on the element that you want to use ng-model with and
 * include the sq-faux-select-option attribute with the value representing the option that should be set to ng-model
 * when the user clicks on or presses enter on the value. (note that the value of the option doesn't need to be a
 * string, it could also be any shape of object (i.e., { key: 'uniqueId', label: 'Awesome Option' })).
 *
 * Note that for ng-model, null is interpreted as no value provided. Create an additional option with
 * `sq-faux-select-option="null"` to allow the user to deselect the value This will result in the correct behavior with
 * `required` validation.
 *
 * @example
 *   <div sq-faux-select
 *     name="units"
 *     ng-model="$ctrl.value"
 *     ng-change="$ctrl.change()"
 *     ng-required="$ctrl.required"
 *     uib-dropdown dropdown-append-to-body keyboard-nav>
 *     <button uib-dropdown-toggle class="btn btn-default" ng-disabled="$ctrl.disabled">
 *       <span class="min-width-10" ng-if="$ctrl.value">{{ $ctrl.value }}</span>
 *       <i class="fa fa-caret-down" />
 *     </button>
 *     <ul uib-dropdown-menu class="dropdown-menu-right cursorPointer">
 *       <li ng-repeat="option in $ctrl.allValues track by option">
 *         <a href sq-faux-select-option="option">{{option}}</a>
 *       </li>
 *     </ul>
 *   </div>
 */

function sqFauxSelect() {
  interface IFauxSelectScope extends ng.IScope {
    $ctrl: FauxSelectCtrl;
  }

  return {
    restrict: 'A',
    controller: 'FauxSelectCtrl',
    controllerAs: '$ctrl',
    require: 'ngModel',
    scope: {},
    link: (
      $scope: IFauxSelectScope,
      $element: JQuery,
      $attrs: ng.IAttributes,
      ngModel: ng.INgModelController) => {

      $scope.$ctrl.ngModel = ngModel;
    }
  };
}

function sqFauxSelectOption() {
  interface IFauxSelectOptionScope extends ng.IScope {
    sqFauxSelectOption: any;
  }

  return {
    restrict: 'A',
    require: '^^sqFauxSelect',
    scope: {
      sqFauxSelectOption: '<'
    },
    link: ($scope: IFauxSelectOptionScope, $element, $attrs,
      fauxSelectCtrl: FauxSelectCtrl) => {
      $element.on('click', selectOption);
      $element.on('keyup', function($event) {
        if ($event.keyCode === 13) {
          selectOption();
        }
      });

      function selectOption() {
        fauxSelectCtrl.setSelected($scope.sqFauxSelectOption);
      }
    }
  };
}

class FauxSelectCtrl {
  public ngModel: ng.INgModelController = this['ngModel'];

  setSelected(value: any) {
    this.ngModel.$setViewValue(value);
    this.ngModel.$setDirty();
  }
}

angular.module('Sq.Directives.FauxSelect', [])
  .directive('sqFauxSelect', sqFauxSelect)
  .directive('sqFauxSelectOption', sqFauxSelectOption)
  .controller('FauxSelectCtrl', FauxSelectCtrl);
