import angular from 'angular';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';

/**
 * The Flux store for workstep state
 */
angular.module('Sq.Worksteps').store('sqWorkstepsStore', sqWorkstepsStore);

export type WorkstepsStore = ReturnType<typeof sqWorkstepsStore>['exports'];

function sqWorkstepsStore() {
  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.NONE,

    initialize() {
      this.state = this.immutable({
        previous: undefined,
        current: {
          id: undefined,
          state: {}
        },
        next: undefined,
        last: undefined,
        isError: false
      });
    },

    exports: {
      /**
       * Gets the unique ID of the previous workstep
       *
       * @returns {String} The previous workstep or undefined there is no previous workstep
       */
      get previous() {
        return this.state.get('previous');
      },
      /**
       * Gets the id and worksheet state of the current workstep
       *
       * @returns {Object} object - A container object
       *  - {String} object.id - The unique ID of the current workstep
       *  - {Object} object.state - The current state of the worksheet
       */
      get current() {
        return this.state.get('current');
      },
      /**
       * Gets the unique ID of the next workstep
       *
       * @returns {String} The next unique ID workstep or undefined there is no previous workstep
       */
      get next() {
        return this.state.get('next');
      },
      /**
       * Gets the unique ID of the last workstep
       *
       * @returns {String} The last unique ID workstep
       */
      get last() {
        return this.state.get('last');
      },
      /**
       * Returns true if the workstep subsystem is in an error state.
       *
       * @returns {Boolean} True if this store is empty because of an error
       */
      get isError() {
        return !!this.state.get('isError');
      }
    },

    handlers: {
      WORKSTEPS_SET: 'set'
    },

    /**
     * Sets workstep state
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.previous - The ID of the previous workstep
     * @param {Object} payload.current - Object container for current state
     * @param {String} payload.current.id - The ID of the current workstep
     * @param {Object} payload.current.state - The current workstep state
     * @param {String} payload.next - The ID of the next workstep
     * @param {String} payload.last - The ID of the last workstep
     * @param {Boolean} payload.isError - True if data is empty due to an error
     */
    set(payload) {
      this.state.set(payload);
    }
  };

  return store;
}
