import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { WorkbookActions } from '@/workbook/workbook.actions';
import { WorkbookStore } from '@/workbook/workbook.store';
import { NotificationsService } from '@/services/notifications.service';
import { Icon } from '@/hybrid/core/Icon.atom';
import { TrackService } from '@/track/track.service';
import { WorksheetThumbnail } from '@/hybrid/worksheets/WorksheetThumbnail.atom';
import { WorksheetWatchers } from '@/hybrid/worksheets/WorksheetWatchers.atom';
import { ButtonWithDropdown } from '@/hybrid/core/ButtonWithDropdown.atom';
import { Dropdown } from 'react-bootstrap';
import { EditableText } from '@/hybrid/core/EditableText.atom';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';

const worksheetPaneTileBindings = bindingsDefinition({
  worksheet: prop<{ worksheetId: string, updatedAt: string, name: string }>(),
  workbookId: prop<string>(),
  isActive: prop<boolean>(),
  index: prop<number>(),
  gotoWorksheet: prop<(worksheetId: string, viewOnly?: boolean) => any>(),
  sqWorkbookActions: injected<WorkbookActions>(),
  sqWorkbookStore: injected<WorkbookStore>(),
  sqNotifications: injected<NotificationsService>(),
  sqTrack: injected<TrackService>()
});

export const WorksheetPaneTile: SeeqComponent<typeof worksheetPaneTileBindings> = (props) => {
  const { worksheet, isActive, workbookId, gotoWorksheet, index } = props;
  const {
    sqWorkbookActions,
    sqWorkbookStore,
    sqNotifications,
    sqTrack
  } = useInjectedBindings(worksheetPaneTileBindings);

  const { t } = useTranslation();
  const [forceEdit, setForceEdit] = useState(false);
  const isReportBinder = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.isReportBinder);
  const worksheetId = worksheet.worksheetId;

  const branchWorksheet = (worksheetId) => {
    sqTrack.doTrack(isReportBinder ? 'Topic' : 'Analysis', 'Worksheet copied');

    const options = isReportBinder ? { prefix: t('ITEM_TYPES.DOCUMENT') } : {};
    return sqWorkbookActions.branchWorksheet(workbookId, worksheetId, options);
  };

  const archiveWorksheet = (worksheetId) => {
    const worksheetName = sqWorkbookStore.getWorksheetName(worksheetId);
    const worksheetIndex = sqWorkbookStore.getWorksheetIndex(worksheetId);

    const action = () => {
      return sqWorkbookActions.restoreWorksheet(workbookId, worksheetId, worksheetIndex)
        .then(() => gotoWorksheet(worksheetId))
        .catch(sqNotifications.apiError);
    };

    sqTrack.doTrack(isReportBinder ? 'Topic' : 'Analysis', 'Worksheet deleted');
    return sqWorkbookActions.archiveWorksheet(workbookId, worksheetId)
      .then(() => sqNotifications.custom(sqNotifications.success, 'WORKSHEET_DELETE_CONFIRMATION', action, {
        WORKSHEET_NAME: worksheetName,
        buttonTranslateKey: 'RESTORE'
      }))
      .catch(sqNotifications.apiError);
  };

  const worksheetMenuOptions = [{
    icon: 'fa-i-cursor',
    action: setForceEdit,
    label: 'RENAME'
  }, {
    icon: 'fa-copy',
    action: branchWorksheet,
    label: 'DUPLICATE'
  }, {
    divider: true
  }, {
    icon: 'fa-remove',
    action: archiveWorksheet,
    label: 'TRASH.TRASH'
  }];

  const renderDropdownOption = (option, worksheetId) => {
    if (option.divider) {
      return <Dropdown.Divider key={`divider_${worksheetId}`} />;
    }

    const actionParameter = option.label === 'RENAME' ? true : worksheetId;

    return (
      <Dropdown.Item onClick={() => option.action(actionParameter)} className="sq-force-text-gray"
        key={`${option.icon}_${worksheetId}`}>
        <Icon icon={option.icon} extraClassNames="fa-fw" type="inherit" />
        <span className="forceFont">{t(option.label)}</span>
      </Dropdown.Item>
    );
  };

  return (
    <div id={worksheetId} className="worksheetContainer"
      data-testid="worksheetContainer">
      {isActive && <div className="activeWorksheetIndicator" data-testid="activeIndicator" />}
      <div className="worksheetSlide" id={`thumb_${index}`}>
        <div onClick={() => gotoWorksheet(worksheetId, false)}
          data-testid={`${worksheetId}_thumbnailContainer`}
          className="cursorPointer">
          <WorksheetThumbnail
            workbookId={workbookId}
            worksheetId={worksheetId}
            isActive={isActive}
            updatedAt={worksheet.updatedAt} />
        </div>
        <div className="worksheetWell flexColumnContainer flexSpaceBetween">
          <EditableText
            id={`worksheetLabel_${index}`}
            testId={`worksheetLabel_${index}`}
            value={worksheet.name}
            textClasses="flexFill breakWord"
            inputClasses="flexFill mb7"
            forceEdit={forceEdit}
            onUpdate={(newName) => {
              setForceEdit(false);
              return sqWorkbookActions.renameWorksheet(worksheetId, newName);
            }} />
          <WorksheetWatchers
            classes="cursorPointer text-opaque-onhover"
            worksheetId={worksheetId}
            compactMode={true} />
          <ButtonWithDropdown
            iconWrapperClasses="cursorPointer text-opaque-onhover specWorksheetDropdownTrigger"
            extraClassNames="worksheetMenuDropdown"
            alignRight={true}
            testId="worksheetOptionsMenu"
            icon={<Icon icon="fa-chevron-down" type="white" extraClassNames="pt3 pl1 pr1" />}>
            {_.map(worksheetMenuOptions, option => renderDropdownOption(option, worksheetId))}
          </ButtonWithDropdown>
        </div>
      </div>
    </div>
  );
};
