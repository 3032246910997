import _ from 'lodash';
import angular from 'angular';
import { TrendActions } from '@/trendData/trend.actions';

angular.module('Sq.TrendViewer').controller('SelectAllIconCtrl', SelectAllIconCtrl);

function SelectAllIconCtrl(
  sqTrendActions: TrendActions) {
  const vm = this;
  vm.selectAll = selectAll;
  vm.anySelected = anySelected;

  /**
   * Selects all items
   */
  function selectAll() {
    _.chain(vm.items)
      .thru(items => vm.rejectProperty ? _.reject(items, vm.rejectProperty) : items)
      .forEach(item => sqTrendActions.setItemSelected(item, !vm.anySelected()))
      .value();
  }

  /**
   * Determine if any items are selected. Excludes any items that have a truthy value for rejectProperty
   *
   * @return {Boolean} Whether any items are selected, excluding any that have a truthy value for rejectProperty
   */
  function anySelected() {
    return _.some(vm.rejectProperty ? _.reject(vm.items, vm.rejectProperty) : vm.items, 'selected');
  }
}
