import _ from 'lodash';
import angular from 'angular';
import '@/../other_components/piwik/piwik';
import hash from 'object-hash';
import moment from 'moment-timezone';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { UtilitiesService } from '@/services/utilities.service';
import { LoggerService } from '@/services/logger.service';
import { DurationStore } from '@/trendData/duration.store';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { SEEQ_VERSION } from '@/services/buildConstants.service';
import { BROWSER_LANG } from '@/core/coreConfiguration.module';
import { FORMULA_TOOL_TREND_STORES } from '@/hybrid/tools/formula/formulaTool.module';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { LicenseManagementStore } from '@/licenseManagement/licenseManagement.store';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';

/**
 * @module Sq.Track module
 */
const dependencies = [
  'Sq.Workbench',
  'Sq.Services.Logger',
  'Sq.Services.SystemConfiguration',
  'Sq.Services.Utilities'
];

angular
  .module('Sq.Track', dependencies)
  .factory('sqTrack', sqTrack);

export type TrackService = ReturnType<typeof sqTrack>;
export const PIWIK_HEARTBEAT_SECONDS = 60;

function sqTrack(
  $http: ng.IHttpService,
  $injector: ng.auto.IInjectorService,
  sqWorkbenchStore: WorkbenchStore,
  sqUtilities: UtilitiesService,
  sqLicenseManagementStore: LicenseManagementStore,
  sqLogger: LoggerService
) {
  let sqStateSynchronizer; // Loaded later to avoid circular dependency

  let piwikTracker = null;

  const service = {
    doTrack,
    getCurrentItemType,
    trackHelp,
    dateParse,
    durationParse,
    trackDateParse, // Testing
    trackDurationParse, // Testing,
    processPiwikRequest, // Testing
    trackPowerSearchCompletedInfo
  };

  return service;

  /**
   * This function calls the function that sends tracking events to Piwik. If we don't know if tracking is enabled or
   * not we make a call to initialize, otherwise we call the executeDoTrack function directly.
   *
   * @param {String} category    - displayed as the 'Category' in the Piwik Console
   * @param {String} action      - displayed as the 'Action' in the Piwik Console
   * @param {String} [information] - displayed as the 'Event' in the Piwik Console
   * @param {boolean} [includeDateRange] - optional, indicator whether or not to include the display and investigate
   *   range in the information field.
   */
  function doTrack(category, action, information = '', includeDateRange = false) {
    if (sqUtilities.headlessRenderMode()) {
      // Don't pollute the tracking database with events generated by the screenshot/thumbnail process
      return;
    }

    if (includeDateRange) {
      const datePattern = 'MM-DD-YYYY HH:mm:ss.SSS';
      const sqDurationStore = $injector.get<DurationStore>('sqDurationStore');

      information += 'Investigation Range: ' + sqDurationStore.investigateRange.start.format(
        datePattern) + '-' + sqDurationStore.investigateRange.end.format(datePattern);

      information += 'Display Range: ' + sqDurationStore.displayRange.start.format(
        datePattern) + '-' + sqDurationStore.displayRange.end.format(datePattern);
    }

    executeDoTrack(category, action, information);
  }

  /**
   * This function sends tracking events to Piwik.
   *
   * @param {String} category    - displayed as the 'Category' in the Piwik Console
   * @param {String} action      - displayed as the 'Action' in the Piwik Console
   * @param {String} information - displayed as the 'Event' in the Piwik Console
   */
  function executeDoTrack(category, action, information) {
    sqStateSynchronizer = $injector.get('sqStateSynchronizer');
    // Since the event is logged both locally and directly to piwik a unique event id is stored so they can be de-duped
    const uniqueEventId = sqUtilities.randomInt();
    if (!sqStateSynchronizer.isRehydrating) {
      getPiwikTracker().trackEvent(category, action, information, uniqueEventId);
    }
  }

  /**
   * Helper function that determines if events should be logged on the remote Piwik server.
   *
   * @return {boolean} True if events should be sent to the Piwik server, false otherwise.
   */
  function isRemoteTelemetryEnabled() {
    const sqSystemConfiguration = $injector.get<SystemConfigurationService>('sqSystemConfiguration');
    return sqSystemConfiguration.isTelemetryEnabled && !_.endsWith(sqSystemConfiguration.adminContactEmail,
      '@seeq.com');
  }

  /**
   * A Piwik callback that is called whenever a request is about to be made. Always logs the event to a local log
   * with the timestamp so that it can be imported later in case telemetry is disabled or something like an
   * ad-blocker is blocking the Piwik URL.
   *
   * @param {string} request - The request params for recording a Piwik event
   * @return {string} If remote telemetry is disabled then an empty string which will cause Piwik to not issue any
   * request. Otherwise the original request params.
   */
  function processPiwikRequest(request) {
    sqLogger.track(`?${request}&cdt=${getUnixTimestamp()}`);
    if (!isRemoteTelemetryEnabled()) {
      return '';
    }

    return request;
  }

  /**
   * Helper function for generating a UNIX timestamp that can be passed to Piwik, such as for overriding the
   * datetime of the request.
   *
   * @return {number} A UNIX timestamp
   */
  function getUnixTimestamp() {
    return Math.round(Date.now() / 1000);
  }

  /**
   * Helper function to obtain an instance of the Piwik Tracker. Exposed for unit testing
   *
   * @returns an instance of the Piwik Tracker
   */
  function getPiwikTracker() {
    const sqSystemConfiguration = $injector.get<SystemConfigurationService>('sqSystemConfiguration');
    const guardedHash = object => (_.isUndefined(object) || object === '') ? '' : hash(object);
    const variableTransformer = sqSystemConfiguration.isTelemetryAnonymized ? guardedHash : _.identity;
    if (piwikTracker === null) {
      piwikTracker = Piwik.getTracker(PIWIK_URL, PIWIK_SITE_ID);
      piwikTracker.enableLinkTracking();
      piwikTracker.setCustomVariable(1, 'companyName', sqLicenseManagementStore.license.companyName, 'visit');
      const [user = '', domain = ''] = _.split(sqSystemConfiguration.adminContactEmail, '@');
      const serverEmail = (!_.isEmpty(user) || !_.isEmpty(domain)) ? `${variableTransformer(user)}@${domain}` : '';
      piwikTracker.setCustomVariable(2, 'serverEmail', serverEmail, 'visit');
      piwikTracker.setCustomVariable(3, 'serverVersion', SEEQ_VERSION, 'visit');
      // Essential to ensure accurate page visit time. Otherwise visit time is only tracked when events fire, and many
      // parts of our app do not fire tracking events.
      piwikTracker.enableHeartBeatTimer(PIWIK_HEARTBEAT_SECONDS);
      piwikTracker.setCustomRequestProcessing(service.processPiwikRequest);
    }
    piwikTracker.setCustomVariable(4, 'userName', variableTransformer(sqWorkbenchStore.currentUser.username), 'visit');
    piwikTracker.setCustomVariable(5, 'contractNumber', sqLicenseManagementStore.license.contractNumber, 'visit');
    piwikTracker.setUserId(variableTransformer(sqWorkbenchStore.currentUser.email));

    return piwikTracker;
  }

  /**
   * Helper function that returns a friendly string for the currently selected item.
   * Used for tracking.
   *
   * @param {Object} item - Object representing either a Folder, and Analysis or a Topic
   * @param {String} item.type - Folder or Workbook
   *
   * @returns {String} item type.
   */
  function getCurrentItemType(item) {
    const sqHomeScreenUtilities = $injector
      .get<HomeScreenUtilitiesService>('sqHomeScreenUtilities');
    if (sqHomeScreenUtilities.isFolder(item)) {
      return 'Folder';
    } else if (sqHomeScreenUtilities.isProject(item)) {
      return 'Project';
    } else if (_.get(item, 'type') === 'Topic') {
      return 'Topic';
    } else if (_.get(item, 'type') === 'Analysis') {
      return 'Analysis';
    }
  }

  /**
   * Dispatch an Event to track the tour start/close/complete actions
   *
   * @param {String} tourType - A String representing the Tour that was started/completed/closed
   * @param {String} action - A String representing the action taken: started/completed/closed
   */
  function trackHelp(tourType, action) {
    service.doTrack(tourType || 'Tour', action, null);
  }

  /**
   * Track a date parsing attempt.
   *
   * @param {String} field - Identifying string for the input field
   * @param {Moment} originalDate - The previous Moment that was being replaced.
   * @param {Moment} originalPairDate - The previous Moment for the paired field, or undefined if there is
   *   no pair
   * @param {String} enteredString - The string that was entered and parsed.
   * @param {Moment} result - Moment object that was parsed from the string, or undefined if parsing was
   *   not successful.
   * @param {Moment} pairResult - Moment object that was parsed from the string for the paired field, or
   *   undefined if the result did not include a value for the pair
   * @param {String} parser - Name of the parsing function that was successful, or undefined if parsing was not
   *   successful.
   * @param {Object} timezone - Timezone used for parsing.
   */
  function dateParse(field, originalDate, originalPairDate, enteredString, result, pairResult, parser, timezone) {
    const success = !_.isUndefined(result) && moment.isMoment(result) && result.isValid();
    service.trackDateParse({
      field,
      originalDate,
      originalPairDate,
      enteredString,
      newDate: success ? result : undefined,
      newPairDate: success ? pairResult : undefined,
      success,
      parser: success ? parser : undefined,
      tz: timezone.name,
      lang: BROWSER_LANG,
      locale: moment.locale()
    });
  }

  /**
   * Tracks a date/time parsing action.
   *
   * @param {Object} payload - Payload containing parameters
   * @param {moment} payload.originalDate - Moment object prior to the parsing attempt
   * @param {String} payload.enteredString - String input that was parsed
   * @param {boolean} payload.success - True if parsing resulted in a successful date; otherwise false
   * @param {moment} payload.newDate - Moment object that was parsed; may be undefined if parsing failed
   * @param {String} payload.parser - Name of the parser which succeeded; may be undefined if parsing failed
   * @param {String} payload.locale - Locale used for parsing
   * @param {String} payload.lang - language used for parsing
   * @param {String} payload.tz - Timezone used for parsing
   */
  function trackDateParse(payload) {
    let info, action;

    if (payload.success) {
      action = 'Parse Date: ' + payload.parser;
      info = [
        '"' + payload.enteredString + '"',
        '->',
        '"' + payload.newDate.toISOString() + '"'
      ];
    } else {
      action = 'Parse Date: Failed';
      info = [payload.enteredString];
    }

    info = info.concat([
      '[ originalDate:',
      '"' + payload.originalDate.toISOString() + '",',
      'lang:',
      payload.lang,
      ', locale:',
      payload.locale,
      ', tz:',
      payload.tz,
      ']'
    ]);

    service.doTrack('Date', action, info.join(' '));
  }

  /**
   * Track a duration parsing attempt.
   *
   * @param {String} originalDuration - String displayed for the previous duration
   * @param {Moment} originalStart - The pre-existing Moment for the start time of the duration.
   * @param {Moment} originalEnd - The pre-existing Moment for the end time of the duration.
   * @param {String} enteredString - The string that was entered and parsed.
   * @param {Moment.Duration} newDuration - Duration that was parsed from the input string, or undefined if no duration
   *   was parsed.
   * @param {Moment} newStart - New start time that was parsed from the input string, or undefined if no start time was
   *   parsed.
   * @param {Moment} newEnd - New end time that was parsed from the input string, or undefined if no end time was
   *   parsed.
   * @param {String} parser - Name of the parsing function that was successful, or undefined if parsing was not
   *   successful.
   * @param {Object} timezone - Timezone used for parsing.
   */
  function durationParse(originalDuration, originalStart, originalEnd, enteredString, newDuration, newStart, newEnd,
    parser, timezone) {
    const durationSuccess = !_.isUndefined(newDuration) && newDuration.valueOf() !== 0;
    const startEndSuccess = (!_.isUndefined(newStart) && newStart.isValid() && !_.isUndefined(
      newEnd) && newEnd.isValid());
    service.trackDurationParse({
      originalDuration,
      originalStart,
      originalEnd,
      enteredString,
      newDuration: durationSuccess ? newDuration : undefined,
      newStart: startEndSuccess ? newStart : undefined,
      newEnd: startEndSuccess ? newEnd : undefined,
      durationSuccess,
      startEndSuccess,
      parser: (durationSuccess || startEndSuccess) ? parser : undefined,
      tz: timezone.name,
      lang: BROWSER_LANG,
      locale: moment.locale()
    });
  }

  /**
   * Tracks a duration parsing action.
   *
   * @param {Object} payload - Payload containing parameters
   * @param {String} payload.originalDuration - String displayed for the previous duration
   * @param {Moment} payload.originalStart - The pre-existing Moment for the start time of the duration.
   * @param {Moment} payload.originalEnd - The pre-existing Moment for the end time of the duration.
   * @param {String} payload.enteredString - The string that was entered and parsed.
   * @param {Moment.Duration} payload.newDuration - Duration that was parsed from the input string, or undefined if no
   *   duration was parsed.
   * @param {Moment} payload.newStart - New start time that was parsed from the input string, or undefined if no start
   *   time was parsed.
   * @param {Moment} payload.newEnd - New end time that was parsed from the input string, or undefined if no end time
   *   was parsed.
   * @param {Boolean} payload.durationSuccess - True if a duration was successfully parsed
   * @param {Boolean} payload.startEndSuccess - True if a start and end date were successfully parsed
   * @param {String} payload.parser - Name of the parsing function that was successful, or undefined if parsing was not
   *   successful.
   * @param {String} payload.locale - Locale used for parsing
   * @param {String} payload.lang - language used for parsing
   * @param {String} payload.tz - Timezone used for parsing
   */
  function trackDurationParse(payload) {
    let info, action;

    if (payload.durationSuccess) {
      action = 'Parse Duration: ' + payload.parser;
      info = [
        '"' + payload.enteredString + '"',
        '->',
        '"' + payload.newDuration.asMilliseconds() + 'ms"'
      ];
    } else if (payload.startEndSuccess) {
      action = 'Parse Duration: ' + payload.parser;
      info = [
        '"' + payload.enteredString + '"',
        '->',
        '("' + payload.newStart.toISOString() + '"',
        ', "' + payload.newEnd.toISOString() + '")'
      ];
    } else {
      action = 'Parse Duration: Failed';
      info = [payload.enteredString];
    }

    info = info.concat([
      '[ originalStart:',
      '"' + payload.originalStart.toISOString() + '",',
      'originalEnd:',
      '"' + payload.originalEnd.toISOString() + '",',
      'originalDuration:',
      '"' + payload.originalDuration + '",',
      'lang:',
      payload.lang,
      ', locale:',
      payload.locale,
      ', tz:',
      payload.tz,
      ']'
    ]);

    service.doTrack('Date', action, info.join(' '));
  }

  /**
   * Gets a JSON blob of data to be used as the 'information' field for tracking formula results
   *
   * @param {Object}  payload - container for arguments
   * @param {String}  payload.id - id of the item (maybe undefined if the formula is new and fails)
   * @param {Boolean} payload.success - true if this was a successful search
   * @param {String}  payload.name - name of the formula
   * @param {String}  payload.formula - text of the formula
   * @param {Object[]} payload.parameters - arguments to the formula
   * @param {String} payload.parameters[].identifier - name of the parameter in the formula
   * @param {Object} payload.parameters[].item - information about the item
   * @param {String} payload.parameters[].item.assets - the list of assets
   * @param {String} payload.parameters[].item.id - item's id
   * @param {String} payload.parameters[].item.name - the name property of the item
   * @param {Boolean} payload.isNew - true if this is a new item (otherwise an update)
   * @param {String} [payload.errorMessage] - if success is false contains the rejection message
   * @return {String} JSON encoded string containing info for tracking
   */
  function trackPowerSearchCompletedInfo(payload) {
    const sqTrendDataHelper = $injector.get<TrendDataHelperService>('sqTrendDataHelper');
    const parameters = _.map(payload.parameters, function(parameter: any) {
      const item = parameter.item;
      const type = _.get(sqTrendDataHelper.findItemIn(FORMULA_TOOL_TREND_STORES, item.id), 'itemType');
      return [
        '$',
        parameter.identifier,
        ' = ',
        _.isEmpty(item.assets) ? item.name : (_.get(item.assets, '[0].formattedName') + ' > ' + item.name),
        // If the item is on the chart we know its type otherwise we wont show this info
        type ? ' (type:' + type + ')' : ''
      ].join('');
    });

    service.doTrack('Workbench_Tool', 'Formula',
      JSON.stringify(_.assign(_.omit(payload, ['success']), { parameters })));
  }
}

export const PIWIK_SITE_ID = '1';
export const PIWIK_URL = 'https://telemetry.seeq.com/piwik/piwik.php';

