import angular from 'angular';
import { browserIsIE } from '@/utilities/browserId';
import { SeeqNames } from './app.constants.seeqnames';

export interface DurationTimeUnit {
  unit: string[];
  momentUnit?: string;
  translationKey: string;
}

export interface UnitWithLabel {
  unit: string[];
  shortLabel: string;
}

export const APP_STATE = {
  ADMINISTRATION: 'workbench.common.administration',
  LOG_TRACKER: 'workbench.common.logs',
  AUDIT_TRAIL: 'workbench.common.auditTrail',
  WORKBOOKS: 'workbench.common.workbooks',
  HOME: 'workbench.common.homeScreen',
  FOLDER: 'workbench.common.folder',
  FOLDER_EXPANDED: 'workbench.common.expanded',
  WORKSHEET: 'workbench.common.worksheet',
  VIEW: 'workbench.common.view',
  VIEW_WORKSHEET: 'workbench.common.viewworksheet',
  PRESENT_WORKSHEET: 'workbench.common.presentworksheet',
  PROJECT: 'workbench.common.project',
  LOGIN: 'login',
  LOAD_ERROR: 'loadError',
  LICENSE: 'workbench.common.license',
  UNAUTHORIZED: 'unauthorized',
  BUILDER: 'builder',
  HEADLESS_CAPTURE_STANDBY: 'headlessCaptureStandby'
};
export const FAVICON =
  process.env.NODE_ENV === 'development' ? {
    GREEN: '/img/dev_seeq_green.ico',
    BLUE: '/img/dev_seeq_blue.ico',
    WORKBENCH: '/img/dev_favicon.ico'
  } : {
    GREEN: '/img/seeq_green.ico',
    BLUE: '/img/seeq_blue.ico',
    WORKBENCH: 'favicon.ico'
  };

export const JOURNAL_PREFIX_PATH = '/links';

export const API_TYPES = {
  ANCILLARY: 'Ancillary',
  ASSET: 'Asset',
  CALCULATED_CONDITION: 'CalculatedCondition',
  CALCULATED_SIGNAL: 'CalculatedSignal',
  DATASOURCE: 'Datasource',
  DATAFILE: 'Datafile',
  CAPSULE: 'SimpleCapsule',
  CALCULATED_SCALAR: 'CalculatedScalar',
  STORED_CONDITION: 'StoredCondition',
  STORED_SIGNAL: 'StoredSignal',
  TABLE_DATASOURCE: 'TableDatasource',
  USER: 'User',
  ANALYSIS: 'Analysis',
  TOPIC: 'Topic',
  WORKSHEET: 'Worksheet',
  TABLE: 'Chart',
  JOURNAL: 'Journal',
  REPORT: 'Report',
  THRESHOLD_METRIC: 'ThresholdMetric',
  FORMULA_FUNCTION: 'AggregatingFormulaFunction'
};
export const ASSET_PATH_SEPARATOR = ' » ';
// Make IE Wait a little longer to compensate for slow javascript execution
export const IE = browserIsIE;
export const DEBOUNCE = {
  // Suitable for deduping multiple calls that are expected to happen in quick succession, but too short for use
  // debouncing user input
  SHORT: !IE ? 100 : 200,
  // Suitable for user input/dragging/scrolling/resizing events. When in doubt use this one
  MEDIUM: !IE ? 300 : 500,
  // Much the same as MEDIUM but for especially expensive api calls (pdf preview, investigate range capsules)
  LONG: 1000,
  // The amount of debounce to use for workstep creation
  WORKSTEP: 300,
  // The amount of debounce to use for live tool previews
  PREVIEW: 500
};
export const ITEM_METADATA = {
  valueUnitOfMeasure: 'Value Unit Of Measure',
  interpolationMethod: 'Interpolation Method',
  keyUnitOfMeasure: 'Key Unit Of Measure',
  maxInterpolation: 'Maximum Interpolation',
  maxCapsuleDuration: 'Maximum Capsule Duration'
};
export const STRING_UOM = 'string';
export const SEARCH_TYPES = ['Asset', 'Condition', 'Signal', 'CalculatedScalar', 'Chart', 'ThresholdMetric', 'Datafile'];
export const TRENDABLE_TYPES = ['Annotation', 'CalculatedCondition', 'StoredCondition', 'CalculatedSignal',
  'StoredSignal', 'CalculatedScalar', 'ThresholdMetric'];
export const PARAMETER_TYPES = ['Asset', 'Datasource', 'Condition', 'Signal', 'CalculatedScalar', 'ThresholdMetric'];
export const ITEM_ICONS = {
  SERIES: 'fc fc-series',
  STRING_SERIES: 'fa fa-font',
  CAPSULE: 'fc fc-capsule',
  CAPSULE_SET: 'fc fc-capsule-set',
  ANNOTATION: 'fc fc-annotate',
  ASSET: 'fa fa-cube',
  SCALAR: 'fc fc-scalar',
  TABLE: 'fc fc-bar-chart',
  METRIC: 'fc fc-metric',
  ANCILLARY: 'fc fc-ancillaries',
  DATAFILE: 'fc fc-import'
};
export const NUMBER_CONVERSIONS = {
  NANOSECONDS_PER_MILLISECOND: 1000000,
  MILLISECONDS_PER_SECOND: 1000
};
export const DISPLAY_MODE = {
  NEW: 'NEW',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  IN_PROGRESS: 'IN_PROGRESS',
  RESULTS: 'RESULTS'
};
export const EDIT_MODE = {
  NORMAL: 'NORMAL',
  COPY: 'COPY',
  COPY_TO_FORMULA: 'COPY_TO_FORMULA'
};
export const APPSERVER_API_PREFIX = '/api';
export const APPSERVER_PATHS = {
  WEBSOCKET_EVENTS: '/events',
  JUPYTER_EVENTS: '/data-lab'
};
export const APPSERVER_API_CONTENT_TYPE = 'application/vnd.seeq.v1+json';
export const MAX_INTERPOLATION_WARNING_MSG_PATH =
  'warningLogs[0].formulaLogEntries.EXCEEDS_MAXIMUM_INTERPOLATION.logDetails[0].message';
export const ACCESS_LEVELS = {
  FULL_CONTROL: 'FULL_CONTROL',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  NO_ACCESS: 'NO_ACCESS'
};
export const MAX_DURATION_WARNING_MSG_PATH =
  'warningLogs[0].formulaLogEntries.EXCEEDS_MAXIMUM_DURATION.logDetails[0].message';
// max duration we recommend for advanced searches, in months. Maximum Capsule Durations that exceed this limit will
// display a warning prompt that encourages users to switch to a simple condition.
export const RECOMMENDED_MAX_CAPSULE_DURATION = 3;
export const GUID_REGEX_PATTERN = '[A-Fa-f0-9]{8}-(?:[A-Fa-f0-9]{4}-){3}[A-Fa-f0-9]{12}';
// Use with lodash, _.get(timeUnit, STANDARD_TIME_UNIT_PATH) to get the standard unit out.
export const STANDARD_TIME_UNIT_PATH = 'unit[0]';
// The standard unit label must be first in the 'unit' array so that the path above works. The standard unit
// should be the only unit that is dehydrated as part of the `UIConfig` and worksteps. Other units are aliases
// that we use at runtime to handle a variety of units from the backend.
export const DURATION_TIME_UNITS: DurationTimeUnit[] = [
  {
    unit: ['s', 'second', 'seconds'],
    momentUnit: 's',
    translationKey: 'DURATION_UNITS.SECONDS'
  },
  {
    unit: ['min', 'minute', 'minutes'],
    momentUnit: 'm',
    translationKey: 'DURATION_UNITS.MINUTES'
  },
  {
    unit: ['h', 'hr', 'hrs', 'hour', 'hours'],
    momentUnit: 'h',
    translationKey: 'DURATION_UNITS.HOURS'
  },
  { unit: ['day', 'd', 'days'], momentUnit: 'd', translationKey: 'DURATION_UNITS.DAYS' }
];
export const DURATION_TIME_UNITS_ALL = DURATION_TIME_UNITS.concat([
  { unit: ['week', 'wk', 'weeks'], momentUnit: 'w', translationKey: 'DURATION_UNITS.WEEKS' },
  { unit: ['month', 'mo', 'months'], momentUnit: 'M', translationKey: 'DURATION_UNITS.MONTHS' },
  { unit: ['year', 'y', 'yr', 'years'], momentUnit: 'y', translationKey: 'DURATION_UNITS.YEARS' }
]);
export const FREQUENCY_UNITS: DurationTimeUnit[] = [
  { unit: ['Hz', 'hertz'], translationKey: 'FREQUENCY_UNITS.HERTZ' },
  { unit: ['/min'], translationKey: 'FREQUENCY_UNITS.PER_MINUTE' },
  { unit: ['/h'], translationKey: 'FREQUENCY_UNITS.PER_HOUR' },
  { unit: ['/day'], translationKey: 'FREQUENCY_UNITS.PER_DAY' }
];
export const FROALA_KEY = '1CC3kA5C7E4F5D4D4bHIMFF1EWBXIJb1BZLZFh1i1MXQLjE4C3F3I3B4D6C4E3D3F2==';
export const PASSWORD_MIN_LENGTH = 8; // Should be kept the same as the minimum on the backend
export const MIME_TYPES = {
  EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  POWERPOINT: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  PDF: 'application/pdf'
};
export const HTTP_MAX_BODY_SIZE_BYTES = 95000;
export const PARAMETER_VALIDATION_REGEX = '[A-Za-z_]\\w*';
export const PARAMETER_SANITATION_REGEX = /[^A-Za-z0-9_]/g;
export const NG_IF_WAIT = 100;
export const MAX_NAME_LENGTH = {
  WORKBOOK: 250,    // allows topics, analyses and folders to have longer and more descriptive names
  WORKSHEET: 100,   // shorter names for worksheets to allow for better readability on side panel
  TOOL: 100,        // shorter names for tools to allow for better UI experience where tool names are seen
  NAME: 35          // suggested length limit for a first or last name from stack overflow
};
export const PERIOD_UNITS = DURATION_TIME_UNITS.concat(
  { unit: ['Hz', 'hertz'], translationKey: 'FREQUENCY_UNITS.HERTZ' }
);

export let IS_PROTRACTOR: boolean; // From angular.js

export const DEFAULT_PERMISSIONS = {
  read: false,
  write: false,
  manage: false
};

export const PERMISSIONS = {
  READ: 'read',
  WRITE: 'write',
  MANAGE: 'manage'
};

export const SEEQ_DATASOURCE = {
  id: 'Seeq',
  name: 'Seeq Databases',
  datasourceClass: 'Seeq Local',
  datasourceId: 'Collected'
};

export const SMALL_SIZE_SCREEN_LIMIT = 815;

export const HOME_SCREEN_TABS = {
  USERS: 'USERS',
  CORPORATE: 'CORPORATE',
  HOME: 'HOME',
  MY_FOLDER: 'MY_FOLDER',
  RECENT: 'RECENT',
  SEARCH: 'SEARCH',
  SHARED: 'SHARED',
  TRASH: 'TRASH'
};

// This determines the order of the tabs
export const HOME_SCREEN_TABS_AND_TRANSLATION_KEYS = [
  {
    value: HOME_SCREEN_TABS.HOME,
    text: 'HOME_SCREEN.TABS.HOME',
    adminOnly: false,
    id: 'specHomeTab'
  },
  {
    value: HOME_SCREEN_TABS.MY_FOLDER,
    text: 'HOME_SCREEN.TABS.MY_FOLDER',
    folderRoot: 'all',
    adminOnly: false,
    id: 'myFolder'
  },
  {
    value: HOME_SCREEN_TABS.SHARED,
    text: 'HOME_SCREEN.TABS.SHARED',
    folderRoot: 'shared',
    adminOnly: false,
    id: 'specSharedTab'
  },
  {
    value: HOME_SCREEN_TABS.CORPORATE,
    text: 'HOME_SCREEN.TABS.CORPORATE',
    folderRoot: 'corporate',
    adminOnly: false,
    id: 'specCorporateTab'
  },
  {
    value: HOME_SCREEN_TABS.USERS,
    text: 'HOME_SCREEN.TABS.USERS',
    folderRoot: 'users',
    adminOnly: true,
    id: 'specUsersTab'
  },
  {
    value: HOME_SCREEN_TABS.TRASH,
    text: 'HOME_SCREEN.TABS.TRASH',
    adminOnly: false,
    id: 'specTrashTab'
  }
];

export const SEARCH_ITEM_LOCATIONS = {
  ALL: 'all',
  USERS: 'users',
  CORPORATE: 'corporate',
  MY_FOLDER: 'mine',
  PINNED: 'pinned',
  RECENT: 'recent', // We don't use this for homescreen advanced search  CRAB-19839
  SHARED_OR_PUBLIC: 'sharedOrPublic'
};

export const DEFAULT_LOCATION = {
  text: 'HOME_SCREEN.LOCATION.ALL',
  value: SEARCH_ITEM_LOCATIONS.ALL
};

// This determines the order of the search location options
export const SEARCH_LOCATION_OPTIONS = [{
  text: 'HOME_SCREEN.LOCATION.RECENT',
  value: SEARCH_ITEM_LOCATIONS.RECENT
}, {
  text: 'HOME_SCREEN.LOCATION.PINNED',
  value: SEARCH_ITEM_LOCATIONS.PINNED
}, {
  text: 'HOME_SCREEN.LOCATION.MY_FOLDER',
  value: SEARCH_ITEM_LOCATIONS.MY_FOLDER
}, {
  text: 'HOME_SCREEN.LOCATION.SHARED',
  value: SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC
}, {
  text: 'HOME_SCREEN.LOCATION.CORPORATE',
  value: SEARCH_ITEM_LOCATIONS.CORPORATE
}, {
  text: 'HOME_SCREEN.LOCATION.USERS',
  value: SEARCH_ITEM_LOCATIONS.USERS,
  adminOnly: true
}, {
  text: 'HOME_SCREEN.LOCATION.ALL',
  value: SEARCH_ITEM_LOCATIONS.ALL
}];

/**
 * @file Constants that are shared by multiple modules.
 */
angular.module('Sq.AppConstants', [])
  .constant('IS_PROTRACTOR', false) // Overridden in system tests
  .constant('APPSERVER_API_PREFIX', APPSERVER_API_PREFIX) // Used by sdk
  .run((_IS_PROTRACTOR_) => {
    IS_PROTRACTOR = _IS_PROTRACTOR_;
  });

export const LOCALES = {
  EN: 'en',
  DE: 'de'
};

export const SUPPORTED_LANGUAGES = [
  { key: LOCALES.EN, translationKey: 'USER.LANGUAGE.ENGLISH' },
  { key: LOCALES.DE, translationKey: 'USER.LANGUAGE.GERMAN' }
];
