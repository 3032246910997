import _ from 'lodash';
import angular from 'angular';

angular.module('Sq.Search').controller('AssetReplaceModalCtrl', AssetReplaceModalCtrl);

function AssetReplaceModalCtrl(
  selectedAsset,
  availableAssets,
  likelySwapOuts,
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) {

  const vm = this;
  vm.showFullPaths = _.uniqBy(likelySwapOuts, 'name').length < likelySwapOuts.length;
  vm.dismiss = $uibModalInstance.dismiss;
  vm.hasSelectedAsset = !!selectedAsset;
  vm.selectedAsset = _.get(selectedAsset, 'name');
  vm.availableAssets = availableAssets;
  vm.likelySwapOuts = likelySwapOuts;
  vm.replaceAsset = $uibModalInstance.close;
}
