import React from 'react';
import classNames from 'classnames';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

interface ShowFormulaHelpInterface {
  showColumnNameField?: boolean;
  onClick: () => void;
}

/** Displays "Show Help" text and toggles the store setting to reflect user's choice **/
export const ShowFormulaHelp: React.FunctionComponent<ShowFormulaHelpInterface> = ({
  showColumnNameField = false,
  onClick
}) => {
  const { t } = useTranslation();
  return <div className={classNames('flexColumnContainer', showColumnNameField ? 'mb15' : '')}>
      <span
        className="text-underline ml15 cursorPointer sq-text-primary nowrap"
        onClick={onClick}>
        {t('FORMULA.SHOW_FORMULA_HELP')}
      </span>
  </div>;
};
