import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { TrackService } from '@/track/track.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { Dropdown } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/DropdownItem';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import _ from 'lodash';
import { AssetSelection } from '@/reportEditor/report.module';

const reportContentAssetBindings = bindingsDefinition({
  selection: prop<AssetSelection>(),
  assetSelections: prop<AssetSelection[]>(),
  setAssetSelection: prop<(any) => void>(),
  sqTrack: injected<TrackService>()
});

export const ReportContentAssetSelection: SeeqComponent<typeof reportContentAssetBindings> = (props) => {
  const { sqTrack } = useInjectedBindings(reportContentAssetBindings);
  const { assetSelections, selection, setAssetSelection } = props;
  const { t } = useTranslation();

  function setAssetSelectionAndTrack(assetSelection: AssetSelection | undefined) {
    sqTrack.doTrack('Topic', 'Update Content', 'asset selection changed');
    setAssetSelection(assetSelection);
  }

  const assetSelectionName = selection ? t(selection.name) : t('REPORT.CONFIG.INHERIT_FROM_WORKSHEET');

  const createDropdownItem = (selection: AssetSelection | undefined, key, name) =>
    <DropdownItem
      href="#"
      key={key}
      onClick={() => setAssetSelectionAndTrack(selection)}>
      {t(name)}
    </DropdownItem>;

  const assetSelectionOptions = _.concat(
    [createDropdownItem(undefined, 'from-worksheet', 'REPORT.CONFIG.INHERIT_FROM_WORKSHEET')],
    _.chain(assetSelections)
      .sortBy(['name', 'id'])
      .map(assetSelection => createDropdownItem(assetSelection, assetSelection.selectionId, assetSelection.name))
      .value());

  return <div className='flexRowContainer p10'>
    <label htmlFor='assetSelectionDropdown'>
      {t('REPORT.CONFIG.ASSET_SELECTION')}
    </label>
    <Dropdown id='assetSelectionDropdown'
      onClick={e => e.preventDefault()}
      className='width-maximum'>
      <DropdownToggle id="assetSelectionDropdownToggle" data-testid="assetSelectionDropdownToggle"
        variant={null}
        className='width-maximum btn-default'>
        {t(assetSelectionName)}
        <span className='caret ml-1' />
      </DropdownToggle>
      <DropdownMenu>
        {assetSelectionOptions}
      </DropdownMenu>
    </Dropdown>
  </div>;
};
