import angular from 'angular';
import moment from 'moment-timezone';
import d3 from 'd3';

/**
 * @file Expose d3 as an angular service
 */
angular.module('Sq.Services.D3', [])
  .service('sqD3', sqD3);

export type D3Service = ReturnType<typeof sqD3>;

function sqD3() {
  localize(d3);

  return d3;
}

/**
 * Localize the d3 service time formatting using as much formatting
 * information from moment as possible.
 * @param {Object} d3Instance - The application d3 instance
 */
function localize(d3Instance) {
  const locale = {
    dateTime: '%a %b %e %X %Y',
    date: '%m/%d/%Y',
    time: '%H:%M:%S',
    periods: ['am', 'pm'],
    days: moment.weekdays(),
    shortDays: moment.weekdaysShort(),
    months: moment.months(),
    shortMonths: moment.monthsShort()
  };

  d3Instance.time.format = d3Instance.locale(locale).timeFormat;
}
