/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class SystemApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Create an add-on tool
   * @param {models.AddOnToolInputV1} body - Add-on tool information
   */
  public createAddOnTool(
    body: models.AddOnToolInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/system/configuration/addOnTools',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AddOnToolOutputV1>;
  }

  /**
   * 
   * @summary Delete an add-on tool
   * @param {string} id - ID of the project to delete
   */
  public deleteAddOnTool(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/system/configuration/addOnTools/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Disable a licensed feature until the next server shutdown.
   * @param {string} name - The name of the licensed feature
   */
  public disableLicensedFeature(
    {
      name
    } : {
      name: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(name)) {
      throw new Error("'name' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/system/license/features/{name}'
        .replace('{name}', encodeURIComponent(String(name))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<{}>;
  }

  /**
   * 
   * @summary Re-enable a licensed feature that was previously disabled. If the feature does not have a valid license or was not previously disabled, this will do nothing.
   * @param {string} name - The name of the licensed feature
   */
  public enableLicensedFeature(
    {
      name
    } : {
      name: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(name)) {
      throw new Error("'name' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/system/license/features/{name}'
        .replace('{name}', encodeURIComponent(String(name))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<{}>;
  }

  /**
   * 
   * @summary Gets a list of all add-on tools.
   */
  public getAddOnTools(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/system/configuration/addOnTools',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.GetAddOnToolsOutputV1>;
  }

  /**
   * 
   * @summary Get the system administrator's contact information
   */
  public getAdministratorContactInformation(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/system/contactInformation',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AdministratorContactInformationV1>;
  }

  /**
   * 
   * @summary Used to retrieve the value of the Seeq server agent.key
   */
  public getAgentKey(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/system/agentKey',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AgentKeyOutputV1>;
  }

  /**
   * 
   * @summary Get the list of configuration options and their details, including the current and default value
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getConfigurationOptions(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/system/configuration/options',
      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ConfigurationOutputV1>;
  }

  /**
   * 
   * @summary Download installer images
   * @param {string} fileName - The name of the installation image
   */
  public getInstallerFile(
    {
      fileName
    } : {
      fileName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(fileName)) {
      throw new Error("'fileName' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/system/installer/{fileName}'
        .replace('{fileName}', encodeURIComponent(String(fileName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<string>;
  }

  /**
   * 
   * @summary Get the current server license, if any
   */
  public getLicense(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/system/license',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.LicenseStatusOutputV1>;
  }

  /**
   * If status is OK, you are authenticated and server is up
   * @summary Used to determine server readiness; available without authentication
   */
  public getOpenPing(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/system/open-ping',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * If status is OK, you are authenticated and server is up
   * @summary Used to determine server readiness, but only available with authentication
   */
  public getPing(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/system/ping',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Used to determine the current load the server is experiencing, where a high load indicates a lot of concurrent activity within the server.
   */
  public getServerLoad(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/system/server-load',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ServerLoadOutputV1>;
  }

  /**
   * If status is OK, you can start executing queries.
   * @summary Used to determine server readiness, telemetry configuration, and other server variables
   * @param {boolean} [includeInternal=false] - whether to include internal statuses
   */
  public getServerStatus(
    {
      includeInternal
    } : {
      includeInternal?: boolean
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/system/server-status',
      params: omitBy({
        ['includeInternal']: includeInternal
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ServerStatusOutputV1>;
  }

  /**
   * 
   * @summary Get list of commonly used and supported units
   */
  public getSupportedUnits(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/system/supportedUnits',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.SupportedUnitsOutputV1>;
  }

  /**
   * 
   * @summary Get a threaddump of the server as a logfile and send the information to the logs
   * @param {number} [maxFrameCount=50] - Maximum frames to output for each thread.
   */
  public getThreadState(
    {
      maxFrameCount
    } : {
      maxFrameCount?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/system/threadState.log',
      params: omitBy({
        ['maxFrameCount']: maxFrameCount
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<{}>;
  }

  /**
   * 
   * @summary Import a license file
   * @param {any} file - File to upload
   */
  public importLicense(
    {
      file
    } : {
      file: File
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(file)) {
      throw new Error("'file' parameter required");
    }

    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/system/license',
      data: formData,
      headers: {
        // Prevents the default Content-Type. Because FormData is used,
        // ends up being 'multipart/form-data' with the boundary specified.
        ['Content-Type']: undefined
      },
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.LicenseImporterOutputV1>;
  }

  /**
   * 
   * @summary Set the system administrator's contact information
   * @param {models.AdministratorContactInformationV1} body - The system administrator&#39;s contact information
   */
  public setAdministratorContactInformation(
    body: models.AdministratorContactInformationV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/system/contactInformation',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Write one or more configuration options
   * @param {models.ConfigurationInputV1} body - Configuration options to write
   */
  public setConfigurationOptions(
    body: models.ConfigurationInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/system/configuration/options',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ConfigurationOutputV1>;
  }

  /**
   * 
   * @summary Translate an input list to canonical unit of measure strings
   * @param {models.UnitsOfMeasureBatchInputV1} body - A list of strings representing the UOMs to test against recognized UOMs
   */
  public uOMs(
    body: models.UnitsOfMeasureBatchInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/system/uoms',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.UnitsOfMeasureOutputV1>;
  }

  /**
   * 
   * @summary Update the attributes of an add-on tool
   * @param {string} id - ID of the add-on tool to update
   * @param {models.AddOnToolInputV1} body - New add-on tool information
   */
  public updateAddOnTool(
    body: models.AddOnToolInputV1,
    {
      id,
    } : {
      id: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/system/configuration/addOnTools/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AddOnToolOutputV1>;
  }

}
