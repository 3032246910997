import React, { useState } from 'react';
import { ButtonVariant, TextButton } from '@/hybrid/core/TextButton.atom';

interface CancelAndSaveProps {
  submitFn: (values) => any;
  cancelFn: () => void;
  values: any [];
  btnDisabled: boolean;
  cancelBtnLabel?: string;
  submitBtnLabel?: string;
  cancelClassNames?: string;
  submitClassNames?: string;
  cancelBtnTestId?: string;
  submitBtnTestId?: string;
  submitBtnVariant?: ButtonVariant;
}

/**
 * Cancel and Save buttons
 */
export const CancelAndSave: React.FunctionComponent<CancelAndSaveProps> = (props) => {
  const {
    submitFn,
    cancelFn,
    btnDisabled,
    values,
    cancelBtnLabel = 'CANCEL',
    submitBtnLabel,
    cancelClassNames = '',
    submitClassNames = '',
    cancelBtnTestId = 'cancelButton',
    submitBtnTestId = 'executeButton',
    submitBtnVariant = 'theme'
  } = props;

  const [processing, setProcessing] = useState(false);

  const manageSubmission = () => {
    setProcessing(true);
    return submitFn(values).finally(() => setProcessing(false));
  };

  return (
    <>
      <TextButton
        onClick={cancelFn}
        label={cancelBtnLabel}
        size="sm"
        testId={cancelBtnTestId}
        extraClassNames={cancelClassNames || 'mr20 min-width 100 width-100 max-width-100'} />

      <TextButton
        onClick={manageSubmission}
        label={submitBtnLabel ? submitBtnLabel : 'SAVE'}
        disabled={btnDisabled || processing}
        size="sm"
        icon={processing ? 'fa fa-spinner fa-spin white' : ''}
        testId={submitBtnTestId}
        extraClassNames={submitClassNames || 'min-width 100 width-100 max-width-100'}
        variant={submitBtnVariant} />
    </>
  );
};
