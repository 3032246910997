import _ from 'lodash';
import angular from 'angular';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { INITIALIZE_MODE, PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';

/**
 * A store for containing annotations which are listed in the panel. An annotation is composed of a description
 * and a collection of interests that it annotates.
 */
angular.module('Sq.Annotation').store('sqAnnotationStore', sqAnnotationStore);

export type AnnotationStore = ReturnType<typeof sqAnnotationStore>['exports'];

function sqAnnotationStore(sqTrendCapsuleStore: TrendCapsuleStore) {
  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.WORKBOOK,

    initialize(initializeMode) {
      const saveState = this.state && initializeMode !== INITIALIZE_MODE.FORCE;
      this.state = this.immutable({
        id: undefined,
        document: '',
        isEditing: false,
        isDiscoverable: false,
        isExpanded: false,
        width: 0,
        height: 0,
        isCommentsExpanded: false,
        isAnnotatesExpanded: false,
        annotations: saveState ? this.state.get('annotations') : [],
        annotatedItemIds: this.monkey(['annotations'], function(annotations) {
          return _.transform(annotations, function(map, annotation: any) {
            _.forEach(annotation.interests, function(interest: any) {
              map[interest.item.id] = annotation.id;
              if (interest.capsule) {
                map[sqTrendCapsuleStore.getUniqueId(interest.item.id, interest.capsule.id)] = annotation.id;
              }
            });
          }, {});
        }),

        annotationCapsuleSet: undefined,
        highlightId: ''
      });
    },

    exports: {
      get id() {
        return this.state.get('id');
      },

      get document() {
        return this.state.get('document');
      },

      get isEditing() {
        return this.state.get('isEditing');
      },

      get isDiscoverable() {
        return this.state.get('isDiscoverable');
      },

      get isExpanded() {
        return this.state.get('isExpanded');
      },

      get width() {
        return this.state.get('width');
      },

      get height() {
        return this.state.get('height');
      },

      get isCommentsExpanded() {
        return this.state.get('isCommentsExpanded');
      },

      get isAnnotatesExpanded() {
        return this.state.get('isAnnotatesExpanded');
      },

      get annotations() {
        return this.state.get('annotations');
      },

      get annotatedItemIds() {
        return this.state.get('annotatedItemIds');
      },

      get annotationCapsuleSet() {
        return this.state.get('annotationCapsuleSet');
      },

      get highlightId() {
        return this.state.get('highlightId');
      },

      get isCkEnabled() {
        return this.state.get('isCkEnabled');
      },

      /**
       * Filters a collection of annotations to only those that are a journal entry for the specified worksheet.
       *
       * @param {Object[]} annotations - The collection to filter
       * @param {String} worksheetId - The worksheet
       * @returns The filtered collection of annotations
       */
      findJournalEntries(annotations, worksheetId) {
        return _.filter(annotations, (annotation: any) =>
          !annotation.discoverable && _.some(annotation.interests, ['item.id', worksheetId]));
      }
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @returns {Object} The dehydrated items.
     */
    dehydrate() {
      return _.omit(this.state.serialize(),
        ['annotations', 'annotationCapsuleSet', 'document', 'highlightId', 'isDiscoverable']);
    },

    /**
     * Re-creates the annotations. All necessary data needed to rehydrate is persisted so no actions have to be
     * called.
     *
     * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      ANNOTATION_SET_ID: 'setId',
      ANNOTATION_SET_DOCUMENT: 'setDocument',
      ANNOTATION_SET_IS_EDITING: 'setIsEditing',
      ANNOTATION_SET_IS_DISCOVERABLE: 'setIsDiscoverable',
      ANNOTATION_SET_IS_EXPANDED: 'setIsExpanded',
      ANNOTATION_SET_WIDTH: 'setWidth',
      ANNOTATION_SET_HEIGHT: 'setHeight',
      ANNOTATION_SET_IS_COMMENTS_EXPANDED: 'setIsCommentsExpanded',
      ANNOTATION_SET_IS_ANNOTATES_EXPANDED: 'setIsAnnotatesExpanded',
      ANNOTATION_SET_ANNOTATIONS: 'setAnnotations',
      ANNOTATION_SET_ANNOTATION: 'setAnnotation',
      ANNOTATION_SET_CAPSULE_SET: 'setAnnotationCapsuleSet',
      ANNOTATION_SET_HIGHLIGHT_ID: 'setHighlightId',
      ANNOTATION_SET_IS_CK_ENABLED: 'setIsCkEnabled'
    },

    /**
     * Sets the id of the loaded annotation.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.id - The id
     */
    setId(payload) {
      this.state.set('id', payload.id);
    },

    /**
     * Sets the document of the loaded annotation.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.document - The document
     */
    setDocument(payload) {
      this.state.set('document', payload.document);
    },

    /**
     * Sets the isEditing mode flag
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.isEditing - The isEditing mode flag
     */
    setIsEditing(payload) {
      this.state.set('isEditing', payload.isEditing);
    },

    /**
     * Sets the isDiscoverable mode flag
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.isDiscoverable - The isDiscoverable mode flag
     */
    setIsDiscoverable(payload) {
      this.state.set('isDiscoverable', payload.isDiscoverable);
    },

    /**
     * Sets the isExpanded state
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.expand - The isExpanded state
     */
    setIsExpanded(payload) {
      this.state.set('isExpanded', payload.expand);
    },

    /**
     * Sets the width state
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.expand - The width state
     */
    setWidth(payload) {
      this.state.set('width', payload.width);
      this.state.commit();
    },

    /**
     * Sets the height state
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.expand - The height state
     */
    setHeight(payload) {
      this.state.set('height', payload.height);
      this.state.commit();
    },

    /**
     * Sets the isCommentsExpanded state
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.expand - The isCommentsExpanded state
     */
    setIsCommentsExpanded(payload) {
      this.state.set('isCommentsExpanded', payload.expand);
    },

    /**
     * Sets the isAnnotatesExpanded state
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.expand - The isAnnotatesExpanded state
     */
    setIsAnnotatesExpanded(payload) {
      this.state.set('isAnnotatesExpanded', payload.expand);
    },

    /**
     * Sets the list of annotations.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} payload.annotations - The annotations
     */
    setAnnotations(payload) {
      this.state.set('annotations', payload.annotations);
    },

    /**
     * Replaces an annotation in this list of annotations, or adds it to the list if not already present.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.id - The ID of the annotation to set
     * @param {Object} payload.annotation - The annotation
     */
    setAnnotation(payload) {
      const cursor = this.state.select('annotations');
      const index = _.findIndex(cursor.get(), ['id', payload.id]);
      if (index >= 0) {
        cursor.splice([index, 1, payload.annotation]);
      } else {
        cursor.push(payload.annotation);
      }
    },

    /**
     * Sets the capsule set that is used to store annotations in this worksheet
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.id - ID of the capsule set
     * @param {Number} payload.maximumDuration - Duration of the largest annotation that has been stored in
     *   this capsule set, in milliseconds
     */
    setAnnotationCapsuleSet(payload) {
      this.state.set('annotationCapsuleSet', { id: payload.id, maximumDuration: payload.maximumDuration });
    },

    /**
     * Sets the id of the annotation that should be highlighted
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.id - ID of the annotation to be highlighted
     */
    setHighlightId(payload) {
      this.state.set('highlightId', payload.id);
    },

    setIsCkEnabled(payload: { isCkEnabled: boolean }) {
      this.state.set('isCkEnabled', payload.isCkEnabled);
    }
  };

  return store;
}
