import _ from 'lodash';
import angular, { IPromise } from 'angular';
import moment from 'moment-timezone';
import { DateTimeService } from '@/datetime/dateTime.service';
import { TrendActions } from '@/trendData/trend.actions';
import { AnnotationStore } from '@/annotation/annotation.store';
import { NotifierService } from '@/services/notifier.service';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { UtilitiesService } from '@/services/utilities.service';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { TrendStore } from '@/trendData/trend.store';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { JournalLinkService } from '@/annotation/journalLink.service';
import { WorkbookStore } from '@/workbook/workbook.store';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { RedactionService } from '@/services/redaction.service';
import { ItemsApi } from 'sdk/api/ItemsApi';
import { AuthorizationService } from '@/services/authorization.service';
import { ANNOTATION_TYPE, ID_PLACEHOLDER } from '@/annotation/annotation.module';
import { API_TYPES, APP_STATE } from '@/main/app.constants';
import { PUSH_IGNORE, PUSH_WORKBOOK } from '@/services/stateSynchronizer.service';
import { AnnotationInputV1, AnnotationOutputV1, AnnotationsApi, ConditionsApi } from '@/sdk';
import { CapsuleV1 } from 'sdk/model/CapsuleV1';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { FormulaService } from '@/services/formula.service';
import { MigrationService } from '@/services/migration.service';

angular.module('Sq.Annotation').service('sqAnnotationActions', sqAnnotationActions);

export type AnnotationActions = ReturnType<typeof sqAnnotationActions>;

function sqAnnotationActions(
  flux: ng.IFluxService,
  $q: ng.IQService,
  $state: ng.ui.IStateService,
  $injector: ng.auto.IInjectorService,
  $interval: ng.IIntervalService,
  sqAnnotationStore: AnnotationStore,
  sqWorksheetActions: WorksheetActions,
  sqNotifier: NotifierService,
  sqUtilities: UtilitiesService,
  sqTrendStore: TrendStore,
  sqTrendDataHelper: TrendDataHelperService,
  sqTrendCapsuleSetStore: TrendCapsuleSetStore,
  sqTrendCapsuleStore: TrendCapsuleStore,
  sqJournalLink: JournalLinkService,
  sqWorkbookStore: WorkbookStore,
  sqReportEditor: ReportEditorService,
  sqRedaction: RedactionService,
  sqAuthorization: AuthorizationService,
  sqFormula: FormulaService,
  sqItemsApi: ItemsApi,
  sqConditionsApi: ConditionsApi,
  sqAnnotationsApi: AnnotationsApi,
  sqPendingRequests: PendingRequestsService,
  sqMigration: MigrationService
) {

  let timer;
  const CAPSULE_SET_PREFIX = 'Annotation Capsules';
  let highlightCanceller;
  const service = {
    newEntry,
    newAnnotation,
    showEntry,
    closeEntry,
    save,
    setId,
    setDocument,
    setExpanded,
    setWidth,
    setHeight,
    setCommentsExpanded,
    setAnnotatesExpanded,
    setIsEditing,
    setIsDiscoverable,
    setIsCkEnabled,
    getAnnotates,
    delete: deleteFn,
    fetchAnnotations,
    displayNewOrExisting,
    fetchDocument,
    showJournalTab,
    getUserCapsuleSetInterests // exposed for test
  };

  setupNotifier();

  return service;

  /**
   * Displays a new blank journal entry ready for editing
   */
  function newEntry() {
    service.setId(ID_PLACEHOLDER);
    service.setDocument('');
    service.setIsDiscoverable(false);
    service.setIsEditing(true);
  }

  /**
   * Creates a new annotation, which is effectively a new journal entry with signal, condition, and capsule
   * links automatically inserted, and sets focus to the start of the entry.
   */
  function newAnnotation() {
    let promise = $q.resolve();
    const links = [];

    links.push('<p><br></p>');

    _.forEach(_.filter(sqTrendDataHelper.getAllItems(), 'selected'), function(item) {
      links.push('<p>' + sqJournalLink.createItemLink(item) + '</p>');
    });

    _.forEach(_.filter(sqTrendCapsuleStore.items, 'selected'), function(capsule: any) {
      links.push('<p>' + sqJournalLink.createCapsuleLink(capsule.isChildOf, capsule.startTime,
        capsule.endTime).link + '</p>');
    });

    if (sqTrendStore.isRegionSelected()) {
      promise = service.getUserCapsuleSetInterests([{
          start: moment.utc(sqTrendStore.selectedRegion.min).toISOString(),
          end: moment.utc(sqTrendStore.selectedRegion.max).toISOString()
        }])
        .then(function(interests) {
          _.forEach(interests, function(interest: any) {
            links.push('<p>' + sqJournalLink.createCapsuleLink(interest.interestId, sqTrendStore.selectedRegion.min,
              sqTrendStore.selectedRegion.max).link + '</p>');
          });
        });
    }

    promise.then(function() {
      const document = links.join('');
      service.setId(ID_PLACEHOLDER);
      service.setDocument(document, true);
      service.setIsDiscoverable(true);
      service.setIsEditing(true);
      service.showJournalTab();
      $interval(sqReportEditor.focus, 100, 1); // Delay so tab appears before trying to set focus
    });
  }

  /**
   * Displays a journal entry in view mode
   *
   * @param {String} id - The id of the annotation
   * @param {Boolean} [exposeJournalTab = true] - optional argument that allows the default behavior of exposing the
   * journal tab to be overridden.
   */
  function showEntry(id, exposeJournalTab = true) {
    service.setDocument('');
    service.setIsEditing(false);
    return service.fetchDocument(id).then((result) => {
        if (sqReportEditor.canUpgradeToCk() && !result.ckEnabled) {
          return sqMigration.toggleEditor(id)
            .then(document => ({ ...result, document, ckEnabled: true }));
        } else {
          return result;
        }
      })
      .then((result) => {
        service.setId(id);
        service.setIsDiscoverable(result.discoverable);
        service.setDocument(result.document);
        service.setIsCkEnabled(result.ckEnabled);

        // If the document contents have been removed by the user, then default the view to editing
        if (!result.document) {
          service.setIsEditing(true);
        }

        if (exposeJournalTab) {
          service.showJournalTab();
        }
      });
  }

  /**
   * Helper function that exposes the browse panel and switches to the Journal tab.
   */
  function showJournalTab() {
    sqWorksheetActions.setBrowsePanelCollapsed(false);
    sqWorksheetActions.tabsetChangeTab('sidebar', 'annotate');
  }

  /**
   * Closes a journal entry and displays the overview
   */
  function closeEntry() {
    service.setId('');
    service.setIsEditing(false);
    highlightEntry(sqAnnotationStore.id);
    service.setId();
  }

  /**
   * Sets the highlightId and then clears it after a short time interval. This is used to highlight the journal entry
   * that had just been open in the journal overview.
   *
   * @param {String} id - a journal entry ID
   */
  function highlightEntry(id) {
    // highlighting should be temporary, so we automatically clear the ID after 3 seconds.
    if (highlightCanceller) {
      $interval.cancel(highlightCanceller);
      highlightCanceller = undefined;
    }

    if (!_.isUndefined(id)) {
      flux.dispatch('ANNOTATION_SET_HIGHLIGHT_ID', { id });
      highlightCanceller = $interval(function() {
        flux.dispatch('ANNOTATION_SET_HIGHLIGHT_ID', {});
      }, 1500, 1, true);
    }
  }

  /**
   * Creates an annotation (aka journal entry) with its name and interests. If a selected region is provided then
   * that is used to create a capsule that also becomes one of the interests. The current workbookId and worksheetId
   * are added as interests to all annotations to make it possible to find all annotations created in a particular
   * workbook and worksheet. If repliesTo is supplied, then the annotation is considered a comment on a journal entry
   * and only the name field must be supplied.
   *
   * @param {Object} args - Object container
   * @param {String} args.name - The name of the journal entry
   * @param {String} [args.id] - The ID of the journal entry if saving an already existing entry
   * @param {String} [args.workbookId] - The ID of the journal entry's workbook
   * @param {String} [args.worksheetId] - The ID of the journal entry's worksheet
   * @param {String} [args.description] - The description of the journal entry
   * @param {String} [args.document] - The journal entry document
   * @param {Object[]} [args.inputInterests] - The journal entry interests
   * @param {String} [args.inputInterests[].interestId] - Id of item being annotated
   * @param {String} [args.inputInterests[].detailId] - Id of item inside a set, such as a capsule, being annotated
   * @param {Object[]} [args.ranges] - The start/end pairs of interest. A new interest will be added for each range
   * @param {String} [args.repliesTo] - The ID of the journal entry that this one replies to (only used by comments)
   * @param {Boolean} [args.discoverable] - the annotation's discoverable setting. Used by the frontend to determine
   *   if the links in a journal entry should be parsed and set as actual backend annotation interests.
   * @return {Promise} Promise that resolves with the new annotation
   */
  function save(args) {
    const id = args.id;
    const name = args.name;
    const description = args.description;
    const document = args.document;
    let inputInterests = args.inputInterests;
    let ranges = args.ranges;
    const repliesTo = args.repliesTo;
    const discoverable = args.discoverable;

    inputInterests = inputInterests || [];
    ranges = _.map(ranges, function(range: any) {
      return {
        start: moment.utc(range.start).toISOString(),
        end: moment.utc(range.end).toISOString()
      };
    });

    const cancellationGroup = `annotationSave-${id}`;
    const cancelOnServer = true;

    const func = sqUtilities.validateGuid(id) ?
      body => sqAnnotationsApi.updateAnnotation(body, { id }, { cancellationGroup, cancelOnServer }) :
      body => sqAnnotationsApi.createAnnotation(body);

    return sqPendingRequests.cancelGroup(cancellationGroup, cancelOnServer)
      .then(() => getUserCapsuleSetInterests(ranges))
      .then(function(additionalInterests) {
        const interests = (_.chain(inputInterests)
          .concat([{ interestId: args.workbookId || $state.params.workbookId }])
          .concat([{ interestId: args.worksheetId || $state.params.worksheetId }])
          .concat(additionalInterests) as any) // See https://github.com/DefinitelyTyped/DefinitelyTyped/issues/6586
          .uniqWith(_.isEqual)
          .value();
        return func(_.omitBy({
          name,
          description,
          document,
          interests,
          repliesTo,
          discoverable,
          type: ANNOTATION_TYPE.JOURNAL
        }, _.isNil) as AnnotationInputV1)
          .then(({ data: annotation }) => {
            flux.dispatch('ANNOTATION_SET_ANNOTATION', { id, annotation }, PUSH_WORKBOOK);
            sqNotifier.emitComment(_.chain(interests)
              .map('interestId')
              .uniq()
              .value()
            );
            return annotation;
          });
      });
  }

  /**
   * Calls getCapsules with a start and end time that is large enough to include all of the supplied ranges
   *
   * @param  ranges - list of start/end pairs
   * @param  conditionId - Guid of the capsule series to request
   * @return {Promise} - resolves with the capsules
   */
  function getCapsulesOverRanges(ranges: { start: string, end: string }[], conditionId: string) {
    return sqConditionsApi.getCapsules({
        id: conditionId,
        start: _.minBy(ranges, 'start').start,
        end: _.maxBy(ranges, 'end').end
      })
      .then(({ data: { capsules } }) => {
        // This removes minor date formatting issues between moment and appserver. Namely corrects
        // "2016-10-31T08:25:00Z" to "2016-10-31T08:25:00.000Z
        capsules.forEach((capsule) => {
          capsule.end = moment.utc(capsule.end).toISOString();
          capsule.start = moment.utc(capsule.start).toISOString();
        });

        return capsules;
      });
  }

  /**
   * Finds the comment capsule series when needed and then creates or finds the capsules that represent them. Then
   * return the list of interests for the capsules matching the ranges provided
   *
   * @param  ranges - list of start/end pairs
   * @return {Promise} - resolves with the interests that should be added to the annotation
   */
  function getUserCapsuleSetInterests(ranges: { start: string; end: string; }[]) {
    const sqDateTime = $injector.get<DateTimeService>('sqDateTime');
    // If the analysis is read only, then don't add selected ranges because the user does not have permission to
    // create new capsule(s) on the Annotation Capsules condition to represent the selected region.
    if (_.isEmpty(ranges) || !sqAuthorization.canWriteItem(sqWorkbookStore)) {
      return $q.resolve([]);
    }

    return findOrCreateUserCapsuleSet()
      .then((conditionId: string) => {
        return getCapsulesOverRanges(ranges, conditionId)
          .then((existingCapsules: CapsuleV1[]) => {
            // Because lodash propagates undefined, the undefined detail ids were the ones we couldn't find
            const newRanges = _.reject(ranges, _.partial(_.find, existingCapsules));

            return _.isEmpty(newRanges) ? existingCapsules :
              _.chain(newRanges as CapsuleV1 [])
                .reduce((result, range: any) => {
                  const milliseconds = sqDateTime.parseISODate(range.end).diff(sqDateTime.parseISODate(range.start));
                  return (milliseconds > result ? milliseconds : result);
                }, 0)
                .thru(_.partial(adjustCapsuleSetMaxDuration, conditionId,
                  _.get(sqAnnotationStore, 'annotationCapsuleSet.maximumDuration', 0)))
                .value()
                .then(() => sqConditionsApi.addCapsules({ capsules: newRanges }, { id: conditionId })
                  .then(_.partial(getCapsulesOverRanges, ranges, conditionId))
                  .then((capsules) => {
                    const sqTrendActions = $injector.get<TrendActions>('sqTrendActions');
                    sqTrendActions.fetchTableAndChartCapsules();
                    sqTrendActions.fetchChartCapsules();
                    sqTrendActions.fetchTimebarCapsules(conditionId);
                    return capsules;
                  })
                );
          })
          .then(function(capsules) {
            const interests = _.map(ranges, function(range) {
              return {
                interestId: conditionId,
                detailId: _.get(_.find(capsules, range), 'id')
              };
            });

            if (!_.every(interests, 'detailId')) {
              // if the second call to getCapsulesOverRanges doesn't return all the capsules that were created,
              // then _.find wont find all the ranges in `capsules` and we will end up with undefined detailIds
              return $q.reject('Could not add a comment capsule as a new interest');
            } else {
              return interests;
            }
          });
      });
  }

  /**
   * Increases the maximumDuration property if it is smaller than a specified interval.
   *
   * @param  {String} id - ID of the capsule set to update
   * @param  {Number} currentDuration - Current max duration for the capsule set, in milliseconds
   * @param  {Number} duration - Duration to test, in milliseconds
   * @return {Promise} Promise that resolves when any property changes have been saved
   */
  function adjustCapsuleSetMaxDuration(id, currentDuration, duration) {
    if (duration <= currentDuration) {
      return $q.resolve();
    }

    return sqItemsApi.setProperty({ value: duration + ' ms' }, { id, propertyName: 'Maximum Duration' })
      .then(() => {
        dispatchAnnotationCapsuleSet({ id, maximumDuration: duration });
      });
  }

  /**
   * Deletes the annotation from the backend and sets mode to RESULTS.
   * NOTE: User-created capsules are not removed if it annotates a time range because backend does not support that.
   *
   * @param {String} id - An annotation id
   * @returns {Promise} Promise that resolves when the annotation has been deleted
   */
  function deleteFn(id) {
    return sqAnnotationsApi.archiveAnnotation({ id })
      .then(() => {
        if (id === sqAnnotationStore.id) {
          service.setId();
        }

        service.fetchAnnotations();
        sqNotifier.emitComment([id]);
      });
  }

  /**
   * Sets the annotation ID
   *
   * @param {String} [id] - the ID
   */
  function setId(id?) {
    flux.dispatch('ANNOTATION_SET_ID', { id }, PUSH_WORKBOOK);
  }

  /**
   * Sets the annotation document (which is a string containing sanitized html) in the editor and the store.
   * @param document - the document
   * @param  forceUpdate - If the editor supports it, the editor will trigger an update when the document is set
   */
  function setDocument(document: string, forceUpdate: boolean = false) {
    sqReportEditor.setHtml(document, forceUpdate);
    flux.dispatch('ANNOTATION_SET_DOCUMENT', { document }, PUSH_WORKBOOK);
  }

  /**
   * Sets the annotation isEditing flag
   *
   * @param {Boolean} isEditing - true if editing, false otherwise.
   */
  function setIsEditing(isEditing) {
    flux.dispatch('ANNOTATION_SET_IS_EDITING', { isEditing }, PUSH_WORKBOOK);
  }

  /**
   * Sets the isDiscoverable flag, indicating that the Journal entry is being used to annotate something. If
   * isDiscoverable is true, then Journal links in the document will be analyzed and supplied to the /annotations
   * endpoint as the interests and ranges of the annotation.
   *
   * @param {Boolean} isDiscoverable - true if discoverable, false otherwise.
   */
  function setIsDiscoverable(isDiscoverable) {
    flux.dispatch('ANNOTATION_SET_IS_DISCOVERABLE', { isDiscoverable }, PUSH_WORKBOOK);
  }

  /**
   * Sets the annotation isExpanded flag, which indicates if the journal editor should be displayed in the
   * expanded (i.e. resizeable) view or not.
   *
   * @param {Boolean} expand - true if expanded, false otherwise.
   */
  function setExpanded(expand) {
    flux.dispatch('ANNOTATION_SET_IS_EXPANDED', { expand }, PUSH_WORKBOOK);
  }

  /**
   * Sets the width of the expanded view
   *
   * @param {Number} width - the width
   */
  function setWidth(width) {
    flux.dispatch('ANNOTATION_SET_WIDTH', { width }, PUSH_WORKBOOK);
  }

  /**
   * Sets the height of the expanded view
   *
   * @param {Number} height - the height
   */
  function setHeight(height) {
    flux.dispatch('ANNOTATION_SET_HEIGHT', { height }, PUSH_WORKBOOK);
  }

  /**
   * Sets the isCommentsExpanded flag, which indicates if the comments area of the journal editor should be
   * displayed as expanded or not.
   *
   * @param {Boolean} expand - true if expanded, false otherwise.
   */
  function setCommentsExpanded(expand) {
    scheduleAnimationDigests(275);
    flux.dispatch('ANNOTATION_SET_IS_COMMENTS_EXPANDED', { expand }, PUSH_WORKBOOK);
  }

  /**
   * Sets the isAnnotatesExpanded flag, which indicates if the annotations area of the journal editor should be
   * displayed as expanded or not.
   *
   * @param {Boolean} expand - true if expanded, false otherwise.
   */
  function setAnnotatesExpanded(expand) {
    scheduleAnimationDigests(275);
    flux.dispatch('ANNOTATION_SET_IS_ANNOTATES_EXPANDED', { expand }, PUSH_WORKBOOK);
  }

  /**
   * Creates and starts an $interval timer that fires every 15ms for the supplied duration. The timer callback is a
   * noop, but it forces angular to digest, which allows components that use directives like resizeNotify to animate
   * smoothly.
   *
   * @param {Number} durationInMs - the duration in milliseconds during which animation digests should be triggered
   */
  function scheduleAnimationDigests(durationInMs) {
    const INTERVAL = 15;

    if (timer) {
      $interval.cancel(timer);
      timer = null;
    }

    timer = $interval(_.noop, INTERVAL, durationInMs / INTERVAL);
  }

  /**
   * Fetches the annotations that annotate the specified items and dispatches them.
   *
   * @param [workbookId = $state.params.workbookId] - optional workbookId. Supplied when worksheet is initially loaded
   *   by router.
   * @param [worksheetId = $state.params.worksheetId] - optional worksheetId. Supplied when worksheet is initially
   * loaded by router.
   * @returns {Promise} Promise that is resolved when the annotations have been fetched
   */
  function fetchAnnotations(workbookId = $state.params.workbookId, worksheetId = $state.params.worksheetId) {
    // workbookId and worksheetId should always be populated, but may sometimes end up being empty if
    // $state.params is not fully initialized. This can happen when reloading a page. This safeguard prevents a
    // second annotation from being created in the case where workbookId and worksheetId are not populated.
    if (!sqUtilities.workbookLoaded() || sqUtilities.isPresentationWorkbookMode || !workbookId || !worksheetId) {
      return $q.resolve([]);
    }

    const annotates = _.chain(service.getAnnotates(workbookId, worksheetId))
      .compact()
      .uniq()
      .value();

    return sqAnnotationsApi.getAnnotations({ annotates, type: ANNOTATION_TYPE.JOURNAL, limit: 1000 })
      .then(({ data }) => {
        const annotations = _.filter(data.items, (annotation: any) => {
          return annotation.discoverable ||
            _.intersection(
              _.map(annotation.interests, 'item.id'),
              _.map(sqWorkbookStore.worksheets, 'worksheetId')
            ).length > 0;
        });

        flux.dispatch('ANNOTATION_SET_ANNOTATIONS', { annotations }, PUSH_IGNORE);

        return annotations;
      })
      .catch(response => sqRedaction.handleForbidden(response, []));
  }

  /**
   * If no annotation is in the store and none exist for the current worksheet, then open a new annotation and
   * make it display in edit mode on the Journal tab. If there is a single existing annotation, then
   * automatically display it on the Journal tab. If there are more than one journal entries for the worksheet,
   * then close the entry and allow the journal overview to display.
   *
   * @param {any} [worksheetId = $state.params.worksheetId] - the worksheet ID
   * @returns {Promise} a promise that resolves when the display operation is complete
   */
  function displayNewOrExisting(worksheetId = $state.params.worksheetId) {
    const worksheetJournalEntries = sqAnnotationStore.findJournalEntries(sqAnnotationStore.annotations, worksheetId);
    if (worksheetJournalEntries.length === 0) {
      // Only display a new entry in edit mode if the user has permission to do so
      if (sqWorkbookStore.effectivePermissions.write) {
        service.newEntry();
      }
    } else if (worksheetJournalEntries.length === 1) {
      const id = _.get(worksheetJournalEntries, '0.id');
      return service.showEntry(id, false);
    } else {
      service.closeEntry();
    }

    return $q.resolve();
  }

  /**
   * Fetches the journal document
   *
   * @param {String} id - the ID
   * @returns {Promise} a promise that resolves when the document has been retrieved
   */
  function fetchDocument(id): IPromise<AnnotationOutputV1> {
    if (!sqUtilities.validateGuid(id)) {
      return $q.resolve({} as AnnotationOutputV1);
    }

    return sqAnnotationsApi.getAnnotation({ id })
      .then(({ data }) => data)
      .catch(() => ({} as AnnotationOutputV1)); // Can happen if annotation was deleted
  }

  /**
   * Finds the capsule set that is used to store user-created capsules from when a user annotates a selected time
   * region. If it does not exist it is created, and the resulting ID and maximumDuration are stored in
   * sqAnnotationStore.annotationCapsuleSet.
   *
   * @returns {Promise} Promise that is resolved with the ID of the user capsule set
   */
  function findOrCreateUserCapsuleSet() {
    let promise;

    if (sqAnnotationStore.annotationCapsuleSet) {
      promise = $q.resolve(sqAnnotationStore.annotationCapsuleSet.id);
    } else {
      promise = sqItemsApi.searchItems({
          filters: ['name==/' + CAPSULE_SET_PREFIX + '.*/'],
          scope: [$state.params.workbookId.toString()],
          types: [API_TYPES.STORED_CONDITION],
          offset: 0,
          limit: 1
        })
        .then(({ data }) => {
          if (!_.isEmpty(data.items)) {
            // Fetch the metadata, so that we know the current maximumDuration and can increase it as needed
            return sqConditionsApi.getCondition({ id: _.head(data.items)['id'] })
              .then(({ data }) => dispatchAnnotationCapsuleSet(data))
              .then(_.property('id'));
          } else {
            // Note: a capsule series is required to have a maximum duration, so we start from a relatively short
            // 1-day duration. The duration is increased as needed each time a new comment is added to this capsule
            // set, such that it is always at least as large as the duration of the longest comment.
            return sqFormula.getDefaultName(CAPSULE_SET_PREFIX, $state.params.workbookId)
              .then((name: string) => sqConditionsApi.createCondition({
                name,
                scopedTo: $state.params.workbookId,
                maximumDuration: '1 day'
              }))
              .then(({ data }) => dispatchAnnotationCapsuleSet(data))
              .then(_.property('id'));
          }
        });
    }

    return promise.then(foundOrCreatedCapsuleSetId =>
      $injector.get<TrendActions>('sqTrendActions').addItem({
          id: foundOrCreatedCapsuleSetId,
          type: API_TYPES.STORED_CONDITION
        })
        .then(() => foundOrCreatedCapsuleSetId)
    );
  }

  /**
   * Gets the ids of all of the items that we want to get annotations for
   *
   * @param [workbookId] - optional workbookId
   * @param [worksheetId] - optional worksheetId
   * @return {String[]} list of ids that can be passed to the Annotations endpoint
   */
  function getAnnotates(workbookId = $state.params.workbookId, worksheetId = $state.params.worksheetId) {
    return _.chain(sqTrendDataHelper.getAllItems())
      .map('id')
      .concat([workbookId])
      .concat([worksheetId])
      .compact() // Handles the case $state.params.workbookId is undefined
      .value();
  }

  /**
   * Attaches a listener to the Socket that handles updating the comments if they changes
   */
  function setupNotifier() {
    sqNotifier.onComment(function(interests) {
      let targetInterests;
      if ($state.current.name !== APP_STATE.WORKSHEET && $state.current.name !== APP_STATE.VIEW_WORKSHEET) {
        return;
      }

      targetInterests = _.chain(service.getAnnotates())
        .concat(_.map(sqAnnotationStore.annotations, 'id'))
        .concat(_.chain(sqAnnotationStore.annotations)
          .flatMap('replies')
          .map('id')
          .value()
        )
        .value();

      if (!_.isEmpty(_.intersection(targetInterests, interests))) {
        service.fetchAnnotations();

        if (interests.indexOf($state.params.worksheetId) >= 0) {
          service.fetchDocument(sqAnnotationStore.id).then((result) => {
            service.setDocument(result.document);
          });
        }
      }
    });
  }

  /**
   * Dispatches the annotation capsule set information to the store.
   *
   * @param  {Object} capsuleSet - Annotation Capsule Set
   * @param  {String} capsuleSet.id - ID of the item
   * @param  {String|Number} capsuleSet.maximumDuration - The maximum length of a capsule in the capsule set.
   *   If this is a number, it is in milliseconds. If a string, it should have a suffix with the units.
   * @returns {Object} Returns the provided capsuleSet object
   */
  function dispatchAnnotationCapsuleSet(capsuleSet) {
    const milliseconds = _.isFinite(capsuleSet.maximumDuration) ? capsuleSet.maximumDuration :
      $injector.get<DateTimeService>('sqDateTime').parseDuration(capsuleSet.maximumDuration).asMilliseconds();

    flux.dispatch('ANNOTATION_SET_CAPSULE_SET', {
      id: capsuleSet.id,
      maximumDuration: milliseconds
    }, PUSH_IGNORE);

    return capsuleSet;
  }

  function setIsCkEnabled(isCkEnabled: boolean) {
    flux.dispatch('ANNOTATION_SET_IS_CK_ENABLED', { isCkEnabled }, PUSH_WORKBOOK);
  }
}
