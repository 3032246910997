import React from 'react';
import _ from 'lodash';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { Field } from 'react-final-form';
import FormulaParametersTable, { FormulaEditorParam } from '@/hybrid/formula/FormulaParametersTable.molecule';

export interface FormulaVariablesIF extends ValidatingFormComponent<string> {
  component: 'FormulaVariablesFormComponent';
  resizeEnabled?: boolean;
  parameters: FormulaEditorParam[];
  updateParameterCallback: (updated: FormulaEditorParam, originalParam: FormulaEditorParam) => void;
  removeParameterCallback: (identifier: string) => void;
  insertParameter: (param: string) => void;
  additionalItems?: any[];
  excludeStoreItems?: boolean;
  onItemSelect: (idx: any, item: any) => void;
  includeAddToDisplayPane?: boolean;
}

/**
 * A wrapper around the `FormulaParametersTable` component for use with `FormBuilder`
 */
export const FormulaVariablesFormComponentUnwrapped: React.FunctionComponent<FormulaVariablesIF> = ({
  name,
  testId = 'formulaVariables',
  resizeEnabled,
  parameters,
  updateParameterCallback,
  removeParameterCallback,
  insertParameter,
  additionalItems = [],
  onItemSelect,
  includeAddToDisplayPane,
  excludeStoreItems = false
}) => {
  const appliedValidation = () => false;// bypass validation as it's not helpful in this case
  return (
    <div className="flexRowContainer overflowYAuto mb-0" data-testid={testId}>
      <div className="tableWrapper">
        <Field name={name} validate={appliedValidation}>
          {({}) => (
            <FormulaParametersTable
              resizeEnabled={resizeEnabled}
              parameters={parameters}
              updateParameterCallback={updateParameterCallback}
              removeParameterCallback={removeParameterCallback}
              insertParameter={insertParameter}
              additionalItems={additionalItems}
              onItemSelect={onItemSelect}
              includeAddToDisplayPane={includeAddToDisplayPane}
              excludeStoreItems={excludeStoreItems}
            />
          )}
        </Field>
      </div>
    </div>
  );
};

export const FormulaVariablesFormComponent = React.memo(FormulaVariablesFormComponentUnwrapped,
  (prev, next) => _.isEqual(prev.parameters, next.parameters) && _.isEqual(prev.insertParameter, next.insertParameter));
