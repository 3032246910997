import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

interface OverlayProps {
  extraClassNames: string;
  moveRef?: JSX.Element;
  initialPosition?: { x: number, y: number };
  savePosition?: ({ x, y }) => void;
  testId?: string;
}

export const Overlay: React.FunctionComponent<OverlayProps> = (props) => {
  const { extraClassNames, moveRef, children, initialPosition = { x: 0, y: 0 }, savePosition, testId } = props;

  const [currentPosition, setCurrentPosition] = useState({ x: initialPosition.x, y: initialPosition.y });
  const overlayStartPosition = useRef({ x: 0, y: 0 });
  const mouseStartPosition = useRef({ x: 0, y: 0 });


  const startDrag = (e) => {
    if (moveRef && e.target !== moveRef) return;

    overlayStartPosition.current = { ...currentPosition };
    mouseStartPosition.current = { x: e.clientX, y: e.clientY };
    window.document.addEventListener('mousemove', dragOverlay.current);
  };
  const stopDrag = (e) => {
    if (savePosition) {
      savePosition(currentPosition);
    }
    window.document.removeEventListener('mousemove', dragOverlay.current);
  };
  const dragOverlay = useRef((e) => {
    e.preventDefault();
    // calculate the new cursor position:
    setCurrentPosition({
      x: overlayStartPosition.current.x + e.clientX - mouseStartPosition.current.x,
      y: overlayStartPosition.current.y + e.clientY - mouseStartPosition.current.y,
    });
  });

  useEffect(() => {
    return () => window.document.removeEventListener('mousemove', dragOverlay.current);
  }, []);

  return <div
    data-testid={testId}
    className={classNames("customOverlay", extraClassNames.split(' '))}
    style={{ left: currentPosition.x, top: currentPosition.y }}
    onMouseDown={startDrag}
    onMouseUp={stopDrag}>
    {children}
  </div>;
};
