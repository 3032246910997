import _ from 'lodash';
import angular from 'angular';
import { BaseToolStoreService } from '@/investigate/baseToolStore.service';
import { DurationStore } from '@/trendData/duration.store';
import { PredictionHelperService } from '@/services/predictionHelper.service';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { PREDICTION } from './prediction.constants';

angular.module('Sq.Investigate').store('sqPredictionPanelStore', sqPredictionPanelStore);

export type PredictionPanelStore = ReturnType<typeof sqPredictionPanelStore>['exports'];

function sqPredictionPanelStore(
  sqBaseToolStore: BaseToolStoreService,
  sqDurationStore: DurationStore,
  sqPredictionHelper: PredictionHelperService
) {
  const store = {
    initialize() {
      this.state = this.immutable(_.assign({}, sqBaseToolStore.COMMON_PROPS, {
        option: PREDICTION.SCALE.LINEAR,
        polynomialValue: 2,
        mustGoThroughZero: false,
        lambda: 0,
        windowStart: sqDurationStore.displayRange.start.valueOf(),
        windowEnd: sqDurationStore.displayRange.end.valueOf(),
        window: this.monkey(['windowStart'], ['windowEnd'], (startTime, endTime) => ({ startTime, endTime })),
        modelExpanded: false,
        regressionMethod: PREDICTION.REGRESSION_METHODS.ORDINARY_LEAST_SQUARES,
        variableSelection: 0.05,
        variableSelectionEnabled: false
      }));
    },

    exports: {
      get option() {
        return this.state.get('option');
      },

      get polynomialValue() {
        return this.state.get('polynomialValue');
      },

      get mustGoThroughZero() {
        return this.state.get('mustGoThroughZero');
      },

      get lambda() {
        return this.state.get('lambda');
      },

      get principalComponentsToKeep() {
        return sqPredictionHelper.adjustPrincipalComponentsToKeep(this.state.get('inputSignals'),
          this.state.get('option'),
          this.state.get('polynomialValue'),
          this.state.get('principalComponentsToKeepByUser'));
      },

      get window() {
        return this.state.get('window');
      },

      get modelExpanded() {
        return this.state.get('modelExpanded');
      },

      get regressionMethod() {
        return this.state.get('regressionMethod');
      },

      get variableSelection() {
        return this.state.get('variableSelection');
      },

      get variableSelectionEnabled() {
        return this.state.get('variableSelectionEnabled');
      }
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @return {Object} State for the store
     */
    dehydrate() {
      return this.state.serialize();
    },

    /**
     * Sets the prediction panel state
     *
     * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      PREDICTION_SET_OPTION: 'setOption',
      PREDICTION_SET_POLYNOMIAL_VALUE: 'setPolynomialValue',
      PREDICTION_SET_MUST_GO_THROUGH_ZERO: 'setMustGoThroughZero',
      PREDICTION_SET_LAMBDA: 'setLambda',
      PREDICTION_SET_PRINCIPAL_COMPONENTS_TO_KEEP: 'setPrincipalComponentsToKeepByUser',
      PREDICTION_SET_TRAINING_WINDOW: 'setTrainingWindow',
      PREDICTION_SET_MODEL_EXPANDED: 'setModelExpanded',
      PREDICTION_SET_REGRESSION_METHOD: 'setRegressionMethod',
      PREDICTION_SET_VARIABLE_SELECTION: 'setVariableSelection',
      PREDICTION_SET_VARIABLE_SELECTION_ENABLED: 'setVariableSelectionEnabled'
    },

    /**
     * Set the prediction option that is used in the form.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.option- The prediction option; one of PREDICTION.SCALE
     */
    setOption(payload) {
      this.state.set('option', payload.option);
    },

    /**
     * Set the polynomial value that is used in the form.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.polynomialValue - The polynomial value
     */
    setPolynomialValue(payload) {
      this.state.set('polynomialValue', payload.polynomialValue);
    },

    /**
     * Set the 'must go through zero' value that is used in the form.
     *
     * @param {Object} payload - Object container
     * @param {Boolean} payload.mustGoThroughZero - the 'must go through zero' options value
     */
    setMustGoThroughZero(payload) {
      this.state.set('mustGoThroughZero', payload.mustGoThroughZero);
    },

    /**
     * Set the lambda value that is used in the form.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.lambda - the lambda options value
     */
    setLambda(payload) {
      this.state.set('lambda', payload.lambda);
    },

    /**
     * Set the 'principal components to keep' value that is used in the form.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.principalComponentsToKeep - the 'principal components to keep' options value
     */
    setPrincipalComponentsToKeepByUser(payload) {
      // We need the state change to always be called in case the saved principalComponentsToKeepByUser value is
      // no longer a valid value for the current inputs.
      this.state.set('principalComponentsToKeepByUser', null);
      this.state.set('principalComponentsToKeepByUser', payload.principalComponentsToKeep);
    },

    /**
     * Set the reference window start value that is used in the form.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.window.startTime - the start of the reference training window
     * @param {Number} payload.window.endTime - the end of the reference training window
     */
    setTrainingWindow(payload) {
      this.state.set('windowStart', _.get(payload.window, 'startTime'));
      this.state.set('windowEnd', _.get(payload.window, 'endTime'));
    },

    /**
     * Set the model expanded state that is used in the form.
     *
     *
     * @param {Object} payload - Object container
     * @param {Boolean} payload.modelExpanded - the model expanded state
     */
    setModelExpanded(payload) {
      this.state.set('modelExpanded', payload.modelExpanded);
    },

    /**
     * Set the regression method that is used in the form.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.regressionMethod - the regression method (one of REGRESSION_METHODS).
     */
    setRegressionMethod(payload) {
      this.state.set('regressionMethod', payload.regressionMethod);
    },

    /**
     * Set the variable selection that is used in the form.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.variableSelection - the variable selection (0-1)
     */
    setVariableSelection(payload) {
      this.state.set('variableSelection', payload.variableSelection);
    },

    /**
     * Set whether variable selection is enabled or not.
     *
     * @param {Object} payload - Object container
     * @param {Boolean} payload.variableSelectionEnabled - true of variable selection is enabled, false otherwise
     */
    setVariableSelectionEnabled(payload) {
      this.state.set('variableSelectionEnabled', payload.variableSelectionEnabled);
    }
  };

  return sqBaseToolStore.extend(store, TREND_TOOLS.PREDICTION, {
    targetSignal: { predicate: ['name', 'target'] },
    condition: { predicate: ['name', 'condition'] },
    inputSignals: {
      predicate: parameter => !_.includes(['target', 'condition'], parameter.name),
      multiple: true
    }
  });
}
