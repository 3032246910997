import _ from 'lodash';
import angular from 'angular';
import { ASSET_GROUP_VIEW, WORKSHEET_TARGET, WORKSHEET_VIEWS, WorksheetView } from '@/worksheet/worksheet.module';
import { PluginStore } from '@/hybrid/plugin/plugin.store';
import { PluginOutputV1 } from '@/sdk';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';

/**
 * @file A service that exposes the views that are available to be displayed on a worksheet.
 * This service abstracts the WORKSHEET_VIEWS constant so we can easily add dynamic view
 * filtering in future.
 */

angular.module('Sq.Worksheet')
  .factory('sqWorksheetView', sqWorksheetView);

export type WorksheetViewService = ReturnType<typeof sqWorksheetView>;

function sqWorksheetView(
  sqPluginStore: PluginStore,
  sqSystemConfiguration: SystemConfigurationService
) {
  const service = {
    views,
    getDefault,
    find
  };
  return service;

  /**
   * Returns all the views that are available for display on a worksheet.
   *
   * @returns {Object[]]} The collection of views
   */
  function views() {
    return _.compact(
      _.concat(
        WORKSHEET_VIEWS,
        sqSystemConfiguration.assetGroupEditorEnabled ? ASSET_GROUP_VIEW : null,
        pluginViews()
      ));
  }

  /**
   * Returns the default view.
   *
   * @returns {Object} The default view
   */
  function getDefault() {
    return _.find(WORKSHEET_VIEWS, 'default');
  }

  /**
   * Returns the view for the given key, or the default if it is not found.
   *
   * @param {String} key - A key that corresponds to WORKSHEET_VIEWS.key
   * @returns {Object} The view that corresponds to the key or the default
   */
  function find(key) {
    return _.find(views(), ['key', key]) || service.getDefault();
  }

  function pluginViews(): WorksheetView[] {
    return _.chain(sqPluginStore.displayPanePlugins() as any)
      .map(createPluginWorksheetView)
      .sortBy(['name'])
      .value();
  }

  function createPluginWorksheetView(plugin: PluginOutputV1): WorksheetView {
    return {
      key: plugin.identifier,
      selectedItemsRealm: 'TREND',
      target: WORKSHEET_TARGET.DATA_PANEL,
      name: plugin.name,
      icon: plugin.icon
    };
  }
}
