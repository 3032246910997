import angular from 'angular';
import { sqSearchResultService } from '@/hybrid/search/searchResult.utilities.service';

const dependencies = [
  'Sq.Vendor',
  'Sq.Core',
  'Sq.Workbook',
  'Sq.Worksheet',
  'Sq.Services.Notifications',
  'Sq.Services.Modal',
  'Sq.AssetGroups'
];

angular
  .module('Sq.Search', dependencies)
  .service('sqSearchResultService', sqSearchResultService);

export const SEARCH_PER_PAGE = 35;
export const SEARCH_CHILDREN_PER_PAGE = 500; // Set high so that user can filter on the results
export const MAX_RETURNED_RESULTS = 250;
export const SEARCH_PANES = {
  MAIN: 'main',
  MODAL: 'modal'
};
export const SEARCH_MODES = {
  OVERVIEW: 'overview',
  SEARCH: 'search',
  TREE: 'tree',
  PINNED: 'pinned',
  RECENT: 'recent',
  ASSET_GROUPS: 'asset-groups'
};
export const SORT_BY_OPTIONS = {
  DEFAULT: '',
  NAME: 'Name',
  DESCRIPTION: 'Description'
};
export const SEARCH_BREADCRUMB = {
  name: 'SEARCH_DATA.SEARCH_RESULTS',
  id: 'SEARCH',
  type: 'VIEW_MODE'
};
export const HOME_BREADCRUMB = { id: '' };
export const SEARCH_RESULT_TYPES = {
  ITEMS: 'items',
  ASSETS: 'assets'
};
