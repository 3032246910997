import React from 'react';
import _ from 'lodash';
import { UtilitiesService } from '@/services/utilities.service';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { DurationStore } from '@/trendData/duration.store';
import { WorkstepsStore } from '@/worksteps/worksteps.store';
import { TrendStore } from '@/trendData/trend.store';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { JournalLinkService } from '@/annotation/journalLink.service';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { TrendScalarStore } from '@/trendData/trendScalar.store';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { TrendTableStore } from '@/trendData/trendTable.store';
import { TrendMetricStore } from '@/trendData/trendMetric.store';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { ITEM_TYPES, TREND_VIEWS } from '@/trendData/trendData.module';
import { makeLink } from '@/hybrid/annotation/Journal.utilities';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';

const journalLinkBindings = bindingsDefinition({
  onLink: prop<(link: string) => void>()
});

export const JournalLink: SeeqComponent<typeof journalLinkBindings> = ({ onLink }) => <div
  className="flexNoGrowNoShrink flexRowContainer width-425">
  <WorkstepJournalLink onLink={onLink} />
  <ItemJournalLink onLink={onLink} />
  <TimeRangeJournalLink onLink={onLink} />
  <CapsuleJournalLink onLink={onLink} />
</div>;

export const sqJournalLink = angularComponent(journalLinkBindings, JournalLink);

//region JournalLink sub-components
const workstepJournalLinkBindings = bindingsDefinition({
  onLink: prop<(link: string) => void>(),
  sqWorkstepsStore: injected<WorkstepsStore>(),
  sqJournalLink: injected<JournalLinkService>(),
  sqUtilities: injected<UtilitiesService>()
});

const WorkstepJournalLink: SeeqComponent<typeof workstepJournalLinkBindings> = (props) => {
  const { onLink } = props;
  const {
    sqWorkstepsStore,
    sqJournalLink,
    sqUtilities
  } = useInjectedBindings(workstepJournalLinkBindings);

  const { t } = useTranslation();
  useFlux(sqWorkstepsStore);

  const isViewMode = sqUtilities.isViewOnlyWorkbookMode;
  const currentWorkstep = sqJournalLink.createWorkstepLink();

  return <>
    {!isViewMode && <>
      <div className="p2 pl5 backgroundColorOffWhite">
        {t('JOURNAL.ENTRY.LINK_WORKSTEP')}
      </div>
      <div className="flexColumnContainer flexNoGrowNoShrink flexWrap pl5 pr5 pt1 pb1">
        {makeLink(currentWorkstep, onLink)}
      </div>
    </>}
  </>;
};

const timeRangeJournalLinkBindings = bindingsDefinition({
  onLink: prop<(link: string) => void>(),
  sqJournalLink: injected<JournalLinkService>(),
  sqDurationStore: injected<DurationStore>(),
  sqTrendStore: injected<TrendStore>()
});

const TimeRangeJournalLink: SeeqComponent<typeof timeRangeJournalLinkBindings> = (props) => {
  const { onLink } = props;
  const {
    sqJournalLink,
    sqDurationStore,
    sqTrendStore
  } = useInjectedBindings(timeRangeJournalLinkBindings);

  const { t } = useTranslation();

  const { displayRange, investigateRange } = useFlux(sqDurationStore);
  const selectedRegion = useFluxPath(sqTrendStore, () => sqTrendStore.selectedRegion);
  const isCapsuleTime = useFluxPath(sqTrendStore, () => sqTrendStore.view === TREND_VIEWS.CAPSULE);

  const displayRangeName = t('DISPLAY_RANGE');
  const investigateRangeName = t('INVESTIGATE_RANGE');
  const selectedRegionName = t('SELECTED_REGION');

  const timeRanges = [
    sqJournalLink.createTimeRangeLink(displayRangeName, displayRange.start.valueOf(), displayRange.end.valueOf()),
    sqJournalLink.createTimeRangeLink(investigateRangeName, investigateRange.start.valueOf(),
      investigateRange.end.valueOf())
  ];

  if (sqTrendStore.isRegionSelected() && !isCapsuleTime) {
    timeRanges.push(
      sqJournalLink.createTimeRangeLink(selectedRegionName, selectedRegion.min.valueOf(), selectedRegion.max.valueOf())
    );
  }

  return <>
    <div className="p2 pl5 backgroundColorOffWhite">
      {t('JOURNAL.ENTRY.LINK_RANGES')}
    </div>
    <div className="flexColumnContainer flexNoGrowNoShrink flexWrap pl5 pr5 pt1 pb1">
      {_.map(timeRanges, timeRange => makeLink(timeRange, onLink))}
    </div>
    {timeRanges?.length < 3 && <div className="p2 pl5 sq-darkish-gray">
      {t('JOURNAL.ENTRY.LINK_NO_SELECTED_RANGE')}
    </div>}
  </>;
};

const capsuleJournalLinkBindings = bindingsDefinition({
  onLink: prop<(link: string) => void>(),
  sqJournalLink: injected<JournalLinkService>(),
  sqTrendCapsuleStore: injected<TrendCapsuleStore>(),
  sqTrendCapsuleSetStore: injected<TrendCapsuleSetStore>()
});

const CapsuleJournalLink: SeeqComponent<typeof capsuleJournalLinkBindings> = (props) => {
  const { onLink } = props;
  const {
    sqJournalLink,
    sqTrendCapsuleSetStore,
    sqTrendCapsuleStore
  } = useInjectedBindings(capsuleJournalLinkBindings);

  const { t } = useTranslation();

  useFlux(sqTrendCapsuleStore);
  const { items: conditions } = useFlux(sqTrendCapsuleSetStore);

  const capsules = _.chain(sqTrendCapsuleStore.items)
    .filter('selected')
    .map(capsule => sqJournalLink.createCapsuleLink(capsule.isChildOf, capsule.startTime, capsule.endTime))
    .value();

  return <>
    <div className="p2 pl5 backgroundColorOffWhite">
      {t('JOURNAL.ENTRY.LINK_CAPSULES')}
    </div>
    <div className="flexColumnContainer flexNoGrowNoShrink flexWrap pl5 pr5 pt1 pb1">
      {_.map(capsules, item => makeLink(item, onLink))}
    </div>
    {!capsules?.length && conditions?.length > 0 && <div className="p2 pl5 sq-darkish-gray">
      {t('JOURNAL.ENTRY.LINK_NO_CAPSULES')}
    </div>}
    {!capsules?.length && conditions?.length === 0 && <div className="p2 pl5 sq-darkish-gray">
      {t('JOURNAL.ENTRY.LINK_NO_CONDITIONS_OR_CAPSULES')}
    </div>}
  </>;
};

const itemJournalLinkBindings = bindingsDefinition({
  onLink: prop<(link: string) => void>(),
  sqJournalLink: injected<JournalLinkService>(),
  sqTrendDataHelper: injected<TrendDataHelperService>(),
  sqTrendCapsuleSetStore: injected<TrendCapsuleSetStore>(),
  sqTrendSeriesStore: injected<TrendSeriesStore>(),
  sqTrendScalarStore: injected<TrendScalarStore>(),
  sqTrendTableStore: injected<TrendTableStore>(),
  sqTrendMetricStore: injected<TrendMetricStore>()
});

const ItemJournalLink: SeeqComponent<typeof itemJournalLinkBindings> = (props) => {
  const { onLink } = props;
  const {
    sqJournalLink,
    sqTrendDataHelper,
    sqTrendSeriesStore,
    sqTrendScalarStore,
    sqTrendCapsuleSetStore,
    sqTrendMetricStore,
    sqTrendTableStore
  } = useInjectedBindings(itemJournalLinkBindings);

  const { t } = useTranslation();

  useFlux(sqTrendCapsuleSetStore);
  useFlux(sqTrendSeriesStore);
  useFlux(sqTrendScalarStore);
  useFlux(sqTrendMetricStore);
  useFlux(sqTrendTableStore);

  const items = _.chain(sqTrendDataHelper.getAllItems())
    .groupBy(item => item.itemType)
    .mapValues(items => _.map(items, item => ({
      name: sqJournalLink.createItemLinkName(item),
      link: sqJournalLink.createItemLink(item)
    })))
    .value();

  return <>
    <div className="p2 pl5 backgroundColorOffWhite">
      {t('JOURNAL.ENTRY.LINK_DETAILS_ITEMS')}
    </div>
    <div className="flexColumnContainer flexNoGrowNoShrink flexWrap pl5 pr5 pt1 pb1">
      {_.map(items[ITEM_TYPES.SERIES], item => makeLink(item, onLink))}
      {_.map(items[ITEM_TYPES.SCALAR], item => makeLink(item, onLink))}
      {_.map(items[ITEM_TYPES.CAPSULE_SET], item => makeLink(item, onLink))}
      {_.map(items[ITEM_TYPES.TABLE], item => makeLink(item, onLink))}
      {_.map(items[ITEM_TYPES.METRIC], item => makeLink(item, onLink))}
    </div>
    {_.isEmpty(items[ITEM_TYPES.SERIES]) && <div className="p2 pl5 sq-darkish-gray">
      {t('JOURNAL.ENTRY.LINK_NO_SIGNALS')}
    </div>}
    {_.isEmpty(items[ITEM_TYPES.CAPSULE_SET]) && <div className="p2 pl5 sq-darkish-gray">
      {t('JOURNAL.ENTRY.LINK_NO_CONDITIONS')}
    </div>}
  </>;
};

//endregion
