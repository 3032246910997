import _ from 'lodash';
import angular from 'angular';
import { RedactionService } from '@/services/redaction.service';
import { UtilitiesService } from '@/services/utilities.service';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';

angular.module('Sq.Services.ToolPanelHelper', []).factory('sqToolPanelHelper', sqToolPanelHelper);

export type ToolPanelHelperService = ReturnType<typeof sqToolPanelHelper>;

function sqToolPanelHelper(
  sqRedaction: RedactionService,
  sqUtilities: UtilitiesService,
  sqTrendDataHelper: TrendDataHelperService
) {

  const service = {
    hasValidItemParameters,
    isValidItemOrValue
  };

  return service;

  /**
   * Checks if the item or items have defined, non-redacted parameters
   */
  function hasValidItemParameters(...items) {
    return _.every(
      _.flatten(items),
      item => item && !sqRedaction.isItemRedacted(item)
    );
  }

  function isValidItemOrValue(value) {
    if (!sqUtilities.validateGuid(value)) return !!value;
    const item = _.find(sqTrendDataHelper.getAllExtendedItems(), { id: value });
    return item && !sqRedaction.isItemRedacted(item);
  }
}
