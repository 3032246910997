import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { MAX_NAME_LENGTH } from '@/main/app.constants';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';

interface AssetPropertiesModalProps {
  name: string;
  description: string;
  onClose: () => void;
  onSave: ({ name, description }) => void;
}

export const AssetPropertiesModal: React.FunctionComponent<AssetPropertiesModalProps> = (props) => {
  const { onClose, onSave, name, description } = props;
  const { t } = useTranslation();

  const [assetGroupName, setAssetGroupName] = useState(name);
  const [assetGroupDescription, setAssetGroupDescription] = useState(description);

  const formDefinition: FormElement[] = [{
    component: 'FormGroup',
    name: 'nameFormGroup',
    components: [{
      component: 'LabelFormComponent',
      value: 'NAME',
      name: 'nameLabel'
    }, {
      component: 'FormControlFormComponent',
      name: 'assetGroupName',
      value: assetGroupName,
      size: 'lg',
      className: 'mb10',
      placeholder: 'ASSET_GROUP_EDITOR.NAME_PLACEHOLDER',
      maxLength: MAX_NAME_LENGTH.TOOL,
      onChange: name => setAssetGroupName(name.toString()),
      testId: 'assetGroupModalNameInput'
    }]
  }, {
    component: 'FormGroup',
    name: 'descriptionFormGroup',
    components: [{
      component: 'LabelFormComponent',
      value: 'DESCRIPTION',
      name: 'descriptionLabel'
    }, {
      component: 'FormControlFormComponent',
      name: 'assetGroupDescription',
      value: assetGroupDescription,
      testId: 'assetGroupModalDescriptionInput',
      size: 'lg',
      onChange: description => setAssetGroupDescription(description.toString()),
      as: 'textarea',
      rows: 5,
      validation: () => false
    }]
  }];

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="assetGroupPropertiesModal">
      <Modal.Header closeButton={true}>
        <h3>{t('ASSET_GROUP_EDITOR.EDIT_GROUP')}</h3>
      </Modal.Header>
      <Modal.Body>
        <SimpleSaveFormBuilder
          formDefinition={formDefinition}
          submitFn={() =>onSave({ name: assetGroupName, description: assetGroupDescription })}
          submitBtnLabel="APPLY"
          closeFn={onClose} />
      </Modal.Body>
    </Modal>);
};

