import React from 'react';
import { JournalCard } from './JournalCard.molecule';
import _ from 'lodash';

interface JournalCategoryProperties {
  name: string;
  placeholder: string;
  entries: any[];
  highlightId: string;
  display: (id: string) => void;
  delete: (id: string) => void;
  canManage: (entry: object) => boolean;
}

export const JournalCategory: React.FunctionComponent<JournalCategoryProperties> = ({
  name, placeholder, entries, highlightId, display, delete: deleteEntry, canManage, children
}) => {
  return <div className="flexRowContainer mb10">
    <div className="flexColumnContainer flexSpaceBetween borderBottomPrimary sq-text-primary pb2 mb5">
      {name}
      {children}
    </div>
    {!entries.length &&
    <div className="p15 sq-fairly-dark-gray text-center">
      {placeholder}
    </div>
    }
    {_.chain(entries)
      .orderBy('updatedAt', 'desc')
      .map(entry => <JournalCard
        key={entry.id}
        entry={entry}
        highlightId={highlightId}
        display={display}
        delete={deleteEntry}
        canManage={canManage} />)
      .value()}
  </div>;
};
