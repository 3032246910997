import _ from 'lodash';
import { useCallback } from 'react';
import { useCurrentValueRef } from './useCurrentValueRef.hook';

/**
 * This hook wraps lodash's debounce function, so that we can use debounce in functional components. The reason we
 * can't use debounce directly is that we need to make sure that the callback we're calling is the current value of the
 * callback function. See more info about the general issue here:
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/.
 *
 * @param callback - the callback to debounce
 * @param wait - the number of milliseconds to wait before making another call to callback
 * @param options - options passed through to lodash's debounce
 * @return the debounced callback
 */
export function useDebounce(callback, wait = 0, options = {}) {
  const { leading, trailing, maxWait } = _.defaults(options, { leading: false, trailing: true, maxWait: undefined });
  const callbackRef = useCurrentValueRef(callback);
  return useCallback(
    _.debounce((...args: any[]) => callbackRef.current(...args), wait, { leading, trailing, maxWait }),
    [wait, leading, trailing, maxWait]
  );
}
