import angular from 'angular';
import { TableBuilderActions } from '@/hybrid/tableBuilder/tableBuilder.actions';
import { sqTableBuilderStore } from '@/hybrid/tableBuilder/tableBuilder.store';
import { sqTableBuilderHelper } from '@/hybrid/tableBuilder/tableBuilderHelper.service';

const dependencies = [
  'Sq.AppConstants',
  'Sq.Investigate'
];

export enum TableBuilderMode {
  Simple = 'simple',
  Condition = 'condition'
}

export enum TableBuilderColumnType {
  Text = 'text', // Column that will be filled with custom text per metric
  Property = 'property' // Column that will be filled with the value of a specified item/capsule property
}

export enum TableBuilderHeaderType {
  None = 'none',
  Start = 'start',
  End = 'end',
  StartEnd = 'startEnd',
  CapsuleProperty = 'capsuleProperty'
}

export const STRIPED_CELL_COLOR = '#f9f9f9';

export const PRIORITY_COLUMN_NAME = 'Priority';
export const COLOR_COLUMN_NAME = 'Color';

export default angular.module('Sq.TableBuilder', dependencies)
  .store('sqTableBuilderStore', sqTableBuilderStore)
  .service('sqTableBuilderHelper', sqTableBuilderHelper)
  .service('sqTableBuilderActions', TableBuilderActions);

export const TABLE_BUILDER = 'Table Builder';
