/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class JobsApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * The interval will use the report's time zone if available, the content's time zone if available, or default to UTC
   * @summary Create a job to capture a content screenshot at a regular interval
   * @param {string} body - ID of the content
   */
  public createContentJob(
    body: string,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/jobs/content',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.JobOutputV1>;
  }

  /**
   * The interval will use the report's time zone if available or default to UTC
   * @summary Create a job to update a date range and its content at a regular interval
   * @param {string} body - ID for the Date Range
   */
  public createDateRangeJob(
    body: string,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/jobs/dateRange',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.JobOutputV1>;
  }

  /**
   * Only administrators are permitted to perform this action
   * @summary Delete a scheduled job and cancel any currently running instance
   * @param {string} group - Group to which the job belongs
   * @param {string} id - ID of the job to delete
   */
  public deleteJob(
    {
      group,
      id
    } : {
      group: string,
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(group)) {
      throw new Error("'group' parameter required");
    }

    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/jobs/{group}/{id}'
        .replace('{group}', encodeURIComponent(String(group)))
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * Pagination is enabled for this query. Only administrators are permitted to perform this action
   * @summary List of scheduled jobs
   * @param {number} [offset=0] - The pagination offset, the index of the first job that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of jobs that will be returned in this page of results
   * @param {string} [schedulerType=User] - Which scheduler to query for jobs. The options are User (default), System, and AsyncMigration.
   */
  public getJobs(
    {
      offset,
      limit,
      schedulerType
    } : {
      offset?: number,
      limit?: number,
      schedulerType?: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/jobs',
      params: omitBy({
        ['offset']: offset,
        ['limit']: limit,
        ['schedulerType']: schedulerType
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.GetJobsOutputV1>;
  }

  /**
   * Does not rely on websockets, but instead expects the consumer to poll this endpoint for the latest image. Returns the binary content of the image directly along with appropriate image headers. Also includes an Expires header that indicates the time the next request should be made. If a period is not supplied, then the image is saved on the server, a URL to the image is returned, and the Expires header is not included
   * @summary A simplified endpoint for fetching a screenshot of Seeq content at a regular interval.
   * @param {string} id - The content id to capture
   */
  public getScreenshotFromContent(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/jobs/content/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ScreenshotOutputV1>;
  }

  /**
   * 
   * @summary Validate a list of Quartz cron expressions
   * @param {models.ValidateCronListInputV1} body - The list of cron schedules to evaluate
   */
  public validateCron(
    body: models.ValidateCronListInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/jobs/validateCron',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ValidateCronListOutputV1>;
  }

}
