import _ from 'lodash';
import bind from 'class-autobind-decorator';

@bind
export class ConfigurationOptionEditController {
  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    public configurationOption
  ) { }

  close = this.$uibModalInstance.close;

  /**
   * Determines the HTML input type for the value.
   *
   * @return {string} The form input type
   */
  getInputType() {
    if (_.isBoolean(this.configurationOption.defaultValue)) {
      return 'checkbox';
    } else if (_.isNumber(this.configurationOption.defaultValue)) {
      return 'number';
    } else {
      return 'text';
    }
  }

  isInputType() {
    return this.getInputType() !== 'text';
  }

  isTextareaType() {
    return this.getInputType() === 'text';
  }

  /**
   * Updates the override value when user changes the isOverridden flag
   */
  onOverriddenChanged() {
    this.configurationOption.value = this.configurationOption.isOverridden ?
      this.configurationOption.defaultValue : undefined;
  }

  save() {
    this.close(this.configurationOption);
  }
}
