import angular from 'angular';
import template from './exportODataPanel.component.html';
import { sqExportODataPanelStore } from '@/investigate/exportOData/exportODataPanel.store';
import { ExportODataPanelController } from '@/investigate/exportOData/exportODataPanel.controller';
import { ExportODataPanelActions } from '@/investigate/exportOData/exportODataPanel.actions';

angular.module('Sq.Investigate')
  .store('sqExportODataPanelStore', sqExportODataPanelStore)
  .service('sqExportODataPanelActions', ExportODataPanelActions)
  .component('sqExportODataPanel', {
    controller: ExportODataPanelController,
    template
  });
