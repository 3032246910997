import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import React from 'react';
import { Icon } from '@/hybrid/core/Icon.atom';
import classNames from 'classnames';

interface ToggleSwitchProps {
  isOn: boolean;
  onChange: (newValue: boolean) => void;
  label?: string;
  formattedLabel?: string;
  disabled?: boolean;
  testId?: string;
}

/**
 * Generic two-state toggle button (on/off)
 */
export const ToggleSwitch: React.FunctionComponent<ToggleSwitchProps> = (props) => {
  const { isOn, onChange, label, formattedLabel, disabled = false, testId } = props;

  const { t } = useTranslation();

  return (
    <div
      data-testid={testId}
      className={classNames('flexColumnContainer', {
        'disabledLook disabledBehavior': disabled
      })}
      onClick={() => !disabled && onChange(!isOn)}>
      {formattedLabel ? formattedLabel : t(label)}
      <Icon
        icon={isOn ? 'fa-toggle-on' : 'fa-toggle-off'}
        large={true}
        extraClassNames={classNames('mt3', 'cursorPointer', {
          ml7: label || formattedLabel,
          disabled
        })}
      />
    </div>
  );
};
