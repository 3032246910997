import _ from 'lodash';
import Highcharts from 'other_components/highcharts';
import template from './densityPlotChart.directive.html';
import { XYRegion } from '@/services/chartHelper.service';
import { ChartSelectionService } from '@/services/chartSelection.service';
import { AxisControlService, AxisExtremeChange } from '@/services/axisControl.service';
import { DEBOUNCE } from '@/main/app.constants';
import { Z_INDEX } from '@/trendViewer/trendViewer.module';
import { UtilitiesService } from '@/services/utilities.service';
import { NumberHelperService } from '@/core/numberHelper.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import moment from 'moment-timezone';
import { DENSITY_PLOT_ID } from '@/scatterPlot/scatterPlot.module';
import { BIN_SIZE_MULTIPLIER, TIME_COLOR_STOPS_WITH_ZERO } from '@/scatterPlot/densityPlot.selectors';

export function sqDensityPlotChart(
  $timeout: ng.ITimeoutService,
  sqChartSelection: ChartSelectionService,
  sqAxisControl: AxisControlService,
  sqNumberHelper: NumberHelperService,
  sqDateTime: DateTimeService,
  sqUtilities: UtilitiesService
) {
  return {
    restrict: 'E',
    scope: {
      chartConfig: '<',
      selectedRegion: '<',
      viewRegion: '<',
      setPointerValues: '&',
      clearPointerValues: '&',
      setSelectedRegion: '<',
      clearSelectedRegion: '<',
      zoomToSelectedRegion: '<',
      zoomToRegion: '<'
    },
    link: linker,
    template
  };

  function linker($scope, $element) {

    let deactivateScrollZoom;
    const axisControl = sqAxisControl.createAxisControl({
      x: {
        updateExtremes(extremesChanges: AxisExtremeChange[]) {
          $scope.zoomToRegion({
            xMin: extremesChanges[0].oldExtremes.min + extremesChanges[0].changeInLow,
            xMax: extremesChanges[0].oldExtremes.max + extremesChanges[0].changeInHigh,
            yMin: $scope.chart.yAxis[0].min,
            yMax: $scope.chart.yAxis[0].max
          });
        }
      },
      y: {
        updateExtremes(extremesChanges: AxisExtremeChange[]) {
          $scope.zoomToRegion({
            xMin: $scope.chart.xAxis[0].min,
            xMax: $scope.chart.xAxis[0].max,
            yMin: extremesChanges[0].oldExtremes.min + extremesChanges[0].changeInLow,
            yMax: extremesChanges[0].oldExtremes.max + extremesChanges[0].changeInHigh
          });
        }
      }
    });

    $scope.chartElement = $element.find('.densityPlot');
    createChart();

    $scope.$on('$destroy', destroyChart);
    $scope.$watch('chartConfig', updateChart);
    const drawSelectedRegion = sqChartSelection.drawSelectedRegion(
      _.constant($scope.chart),
      {
        selection: Z_INDEX.SELECTED_REGION,
        button: Z_INDEX.SELECTED_REGION_REMOVE
      },
      {
        clearSelection: $scope.clearSelectedRegion
      }
    );
    $scope.$watch('selectedRegion', () => drawSelectedRegion($scope.selectedRegion));
    $scope.$watch('viewRegion', updateViewRegion);

    /**
     * Reflow the chart so that it correctly fills its container. Only needs to be called when the chart container has
     * its dimensions changed because another element on the page changed.  Debounced to avoid repeated calls during
     * resizing.
     */
    $scope.reflowChart = _.debounce(function() {
      $scope.chart.reflow();
      // Rescale the colorbar legend relative to the plot size
      $scope.chart.legend.update({ symbolHeight: Math.round($scope.chart.chartHeight / 2.5) });
      $scope.chartElement.removeClass('invisible');
      drawSelectedRegion($scope.selectedRegion);
    }, DEBOUNCE.MEDIUM);

    /**
     * Transforms a pair of Highcharts X and Y selection objects with min and max into a matching XYRegion
     *
     * @param {Highcharts.SelectDataObject} xAxis axis for min and max x points
     * @param {Highcharts.SelectDataObject} yAxis axis for min and max y points
     * @return {XYRegion}
     */
    function getXYRegion(xAxis, yAxis): XYRegion {
      return {
        xMin: xAxis.min,
        xMax: xAxis.max,
        yMin: yAxis.min,
        yMax: yAxis.max
      };
    }

    /**
     * Create basic scatter plot chart. Actual data and series get fed in via #updateChart()
     */
    function createChart() {
      const chartConfig = {
        chart: {
          animation: false,
          type: 'heatmap',
          zoomType: 'xy',
          boost: {
            enable: false
          },
          alignTicks: false,
          events: {
            selection(e) {
              $scope.$apply(() => $scope.setSelectedRegion(getXYRegion(e.xAxis[0], e.yAxis[0])));
              return false;
            },
            load() {
              deactivateScrollZoom = axisControl.activateScrollZoom(
                this,
                $scope.chartElement
              );
            }
          }
        },
        tooltip: {
          animation: false,
          hideDelay: 100,
          headerFormat: '',
          formatter() {
            // turn off tooltips for null or 0 values
            if (_.isNil(this.point.value) || this.point.value === 0) {
              return false;
              // custom tooltip for the real values
            } else {
              return formatTooltip(this.point);
            }
          }
        },
        plotOptions: {
          series: {
            turboThreshold: 0,
            animation: true,
            states: {
              hover: {
                lineWidthPlus: 0,
                marker: { enabled: false }
              },
              inactive: {
                enabled: false
              }
            },
            borderWidth: 0
          },
          heatmap: {
            point: {
              events: { mouseOver: onMouseOver }
            },
            pointPadding: 0,
            minPadding: 0,
            maxPadding: 0,
            crisp: false
          }
        },
        colorAxis: {
          startOnTick: false,
          endOnTick: false,
          type: 'linear',
          stops: TIME_COLOR_STOPS_WITH_ZERO,
          labels: {
            formatter() {
              return sqDateTime.formatSimpleDuration(moment.duration(this.value));
            },
            rotation: 15
          }
        },
        credits: { enabled: false },
        title: { text: null },
        xAxis: {
          title: {
            enabled: true,
            text: ''
          },
          lineWidth: 1,
          tickWidth: 1,
          maxPadding: 0,
          minPadding: 0
        },
        yAxis: {
          title: {
            enabled: true,
            text: ''
          },
          // The default tick behavior (for the y-axis only) causes the axis to snap extremes to the nearest tick every
          // time you change it, which causes very weird behavior when panning and zooming on it
          startOnTick: false,
          endOnTick: false,
          lineWidth: 1,
          tickWidth: 1,
          gridLineWidth: 0,
          maxPadding: 0,
          minPadding: 0
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
          width: 100
        }
      } as any;

      // Prevents flickering since the size is not known until the initial reflow
      $scope.chartElement.addClass('invisible');
      $scope.chart = Highcharts.chart($scope.chartElement[0], chartConfig);

      $scope.chartElement.on('mouseleave', clearPointerValues);
      $scope.chartElement.on('mousemove', clearPointerValues);
    }

    /**
     * Processes changes to the chart configuration.
     *
     * @param {String} chartConfig - Highcharts configuration settings to update on the chart
     */
    function updateChart(chartConfig) {
      const clonedConfig = sqUtilities.cloneDeepOmit(chartConfig, ['data']);

      // Avoid the expensive re-setting of data if it hasn't changed. Can use simple equality check because data is
      // immutable.
      _.forEach(clonedConfig.series, (series) => {
        const chartSeries = findChartSeries(series.id);
        if (series.data === _.get(chartSeries, 'userOptions.data')) {
          delete series.data;
        } else if (chartSeries) {
          // We call setData() separately from update() because passing false as the fourth argument to
          // setData() prevents Highcharts from trying to modify the data array we pass in. There's no corresponding
          // option for update().
          chartSeries.setData(series.data, false, false, false);
          delete series.data;
        }
      });

      $scope.chart.update(clonedConfig, true, true, false);
    }

    /**
     * Updates the visible region displayed in the chart to a specific XY region
     * @param {XYRegion} viewRegion
     */
    function updateViewRegion(viewRegion) {
      $scope.chart.xAxis[0].setExtremes(viewRegion.xMin, viewRegion.xMax, false);
      $scope.chart.yAxis[0].setExtremes(viewRegion.yMin, viewRegion.yMax, false);
      $scope.chart.redraw();
      drawSelectedRegion($scope.selectedRegion);
    }

    /**
     * Sets the current point values as the mouse moves over the chart. Highcharts populates 'this' with information
     * about the point we are hovering over ({time, x, y})
     */
    function onMouseOver() {
      const time = this.time;
      const chartOrRegressionId = _.get(this.series.userOptions, 'id');
      const pointerValues = _.filter([{
        id: _.get($scope.chart.xAxis, '[0].userOptions.signalId'),
        pointValue: this.x,
        pointSelected: true
      }, {
        id: chartOrRegressionId === DENSITY_PLOT_ID
          ? _.get($scope.chart.yAxis, '[0].userOptions.signalId') : chartOrRegressionId,
        pointValue: this.y,
        pointSelected: true
      }], pointerValue => _.isFinite(pointerValue.pointValue) && !_.isEmpty(pointerValue.id));

      if (!_.isEmpty(pointerValues)) {
        // EvalAsync to avoid digest during Highcharts callback which causes errors in Highcharts
        $scope.$evalAsync(() => {
          $scope.setPointerValues()(time, pointerValues);
        });
      }
    }

    function formatTooltip(point) {
      const seriesUserOptions = point.series.userOptions;

      const xRange = seriesUserOptions.colsize / BIN_SIZE_MULTIPLIER;
      const yRange = seriesUserOptions.rowsize / BIN_SIZE_MULTIPLIER;

      const xMinFormatted = sqNumberHelper.formatNumber(point.x - (xRange / 2),
        { format: seriesUserOptions.xNumberFormat });
      const xMaxFormatted = sqNumberHelper.formatNumber(point.x + (xRange / 2),
        { format: seriesUserOptions.xNumberFormat });
      const yMinFormatted = sqNumberHelper.formatNumber(point.y - (yRange / 2),
        { format: seriesUserOptions.yNumberFormat });
      const yMaxFormatted = sqNumberHelper.formatNumber(point.y + (yRange / 2),
        { format: seriesUserOptions.yNumberFormat });
      const tooltipFormatted = `x: <strong>${xMinFormatted} - ${xMaxFormatted}</strong><br/>`
        + `y: <strong>${yMinFormatted} - ${yMaxFormatted}</strong><br/>`;
      const timeFormatted = sqDateTime.formatSimpleDuration(moment.duration(point.value));
      return tooltipFormatted + `duration: <strong>${timeFormatted}</strong>`;
    }

    /**
     * Removes the chart.
     */
    function destroyChart() {
      deactivateScrollZoom();

      $scope.clearPointerValues();
      if ($scope.chart && $scope.chart.destroy) {
        $scope.chart.destroy();
        $scope.chart = null;
      }

      if ($scope.chartElement) {
        $scope.chartElement.off();
        $scope.chartElement.html('');
      }
    }

    /**
     * Clears the axis values when mouse leaves the chart
     *
     * @param {Event} e The mouse event
     */
    function clearPointerValues(e) {
      if (mouseLeftActualChartArea(e)) {
        $scope.$apply(function() {
          $scope.clearPointerValues();
        });
      }
    }

    /**
     * Checks to see if the mouse has left the plot area and resets the zoomInProgess flag to reset the selection
     * marker
     *
     * @param {Event} e The mouse event
     */
    function mouseLeftActualChartArea(e) {
      let plotBox;
      let downXPixels;
      let downYPixels;

      if (e && $scope.chart && $scope.chart.pointer) {
        downXPixels = $scope.chart.pointer.normalize(e).chartX;
        downYPixels = $scope.chart.pointer.normalize(e).chartY;
        plotBox = $scope.chart.plotBox;
        return downYPixels <= plotBox.y ||
          downYPixels > plotBox.y + plotBox.height ||
          downXPixels < plotBox.x ||
          downXPixels > plotBox.x + plotBox.width;
      } else {
        return true;
      }
    }

    /**
     * Find the chart series object that matches the id specified
     *
     * @param  {string} id        ID value for which to search
     * @return {Object}           Chart series object; null if not found
     */
    function findChartSeries(id): Highcharts.Series {
      return _.find($scope.chart.series as Highcharts.Series[], { options: { id } });
    }
  }
}
