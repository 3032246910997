import React, { useRef, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { NativeTypes, HTML5Backend } from 'react-dnd-html5-backend';
import { useDrop, DndProvider } from 'react-dnd';
import { Icon } from '@/hybrid/core/Icon.atom';

export interface FileDropperProps {
  text: string;
  fileDropCallback: (file: File) => void;
  clearFileCallback: () => void;
  id: string;
  isValid?: boolean;
  filename?: string;
  fileExtension?: string;
  extraClassNames?: string;
}

const InnerFileDropper: React.FunctionComponent<FileDropperProps> = (props) => {
  const {
    text,
    fileDropCallback,
    clearFileCallback,
    extraClassNames,
    fileExtension,
    id,
    filename,
    isValid = true
  } = props;
  const [hovering, setHovering] = useState(false);
  const { t } = useTranslation();
  const [{}, drop] = useDrop({
    accept: [NativeTypes.FILE],
    hover(item: any) {
      setHovering(true);
    },
    drop(item) {
      fileDropCallback((item as any).files[0]);
      setHovering(false);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const inputFileRef = useRef<HTMLInputElement>(null);

  return (
    <div className={classNames(filename ? 'file-drop-zone-uploaded' : 'file-drop-zone', extraClassNames,
      { 'file-drop-zone-over': hovering, 'file-drop-zone-error': !filename && !isValid })}
      ref={drop}
      onClick={() => !filename && inputFileRef.current.click()}>
      {!filename &&
      <input
        data-testid={id}
        multiple={false}
        type='file'
        accept={fileExtension}
        style={{ display: 'none' }}
        ref={inputFileRef}
        onChange={event => fileDropCallback(event.target.files[0])} />}
      <div
        className={classNames('flexColumnContainer', 'flexSpaceBetween', 'flexAlignCenter',
          filename ? 'min-height-75' : 'min-height-60')}>
        {filename &&
        <>
          <div className='flexRowContainer flexAlignStart pl5'>
            <span>{t('FILE_UPLOADED_AS')}</span>
            <span className='aggressiveWordBreak'>{filename}</span>
          </div>
          <Icon icon="fa-close" extraClassNames='cursorPointer pr5' testId={`${id}-remove`}
            onClick={clearFileCallback} />
        </>}
        {!filename &&
        <div className='flexColumnContainer flexAlignCenter pl10 pr10'>
          <span className="fa-3x">+</span>
          <span className="ml10 text-left">{t(text)}</span>
        </div>
        }
      </div>
    </div>
  );
};

const FileDropperUnwrapped: React.FunctionComponent<FileDropperProps> = props =>
  <DndProvider backend={HTML5Backend}>
    <InnerFileDropper {...props} />
  </DndProvider>;

/**
 * Drag-and-drop element to receive file upload
 */
export const FileDropper = React.memo(FileDropperUnwrapped, (prev, next) => _.isEqual(prev, next));
