/* istanbul ignore file */

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export class NestedTables extends Plugin {
  init() {
    const editor = this.editor;

    editor.model.schema.on('checkChild', (event, args) => {
      const context = args[0];
      const childDefinition = args[1];

      if (context.endsWith('tableCell') && childDefinition && childDefinition.name === 'table') {
        // Prevent next listeners from being called.
        event.stop();
        // Set the checkChild()'s return value.
        event.return = true;
      }
    }, { priority: 'highest' });
  }
}
