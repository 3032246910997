import React from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import _ from 'lodash';
import { ScatterPlotStore } from '@/scatterPlot/scatterPlot.store';
import { LegendWrapper } from '@/hybrid/trend/LegendWrapper.atom';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { ScatterPlotLegendColorConditionItems } from '@/hybrid/scatterPlot/ScatterPlotLegendColorConditionItems.molecule';
import { ScatterPlotLegendFxLinesItems } from '@/hybrid/scatterPlot/ScatterPlotLegendFxLines.molecule';
import { ScatterPlotLegendGradientItems } from '@/hybrid/scatterPlot/ScatterPlotLegendGradientItems.molecule';
import { DurationStore } from '@/trendData/duration.store';
import { DateTimeService } from '@/datetime/dateTime.service';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { ScatterPlotLegendColorRainbowCapsuleProperty } from '@/hybrid/scatterPlot/ScatterPlotLegendColorRainbowCapsuleProperty.molecule';

const scatterPlotLegendBindings = bindingsDefinition({
  sqDurationStore: injected<DurationStore>(),
  sqDateTime: injected<DateTimeService>(),
  sqScatterPlotStore: injected<ScatterPlotStore>(),
  sqTrendSeriesStore: injected<TrendSeriesStore>(),
  sqTrendCapsuleSetStore: injected<TrendCapsuleSetStore>()
});

/**
 * Used in the ScatterPlot component to render the legend for color conditions, color ranges,
 * f(x) lines, and colorSignals.
 *
 */
export const ScatterPlotLegend: SeeqComponent<typeof scatterPlotLegendBindings> = () => {
  const {
    sqDurationStore,
    sqDateTime,
    sqScatterPlotStore,
    sqTrendSeriesStore,
    sqTrendCapsuleSetStore
  } = useInjectedBindings(scatterPlotLegendBindings);

  const { displayRange } = useFlux(sqDurationStore);
  const {
    colorConditionIds,
    colorSignalId,
    gradientConfig,
    fxLines,
    colorRanges,
    colorCapsuleProperty,
    capsulePropertyColorsConfig
  } = useFlux(sqScatterPlotStore);

  const namedFxLines = _.map(fxLines, line => ({
    ...line,
    name: sqTrendSeriesStore.findItem(line.id)?.name
  }));

  const validColorRanges = _.chain(colorRanges)
    .filter(({ range }) => sqDateTime.overlaps(range, { startTime: displayRange.start, endTime: displayRange.end }))
    .map(colorRange => ({
      id: colorRange.id,
      color: colorRange.color,
      start: colorRange.range.startTime,
      end: colorRange.range.endTime
    }))
    .value();

  const colorConditions = _.chain(colorConditionIds)
    .map(id => sqTrendCapsuleSetStore.findItem(id))
    .compact()
    .value();
  const colorSignalName = sqTrendSeriesStore.findItem(colorSignalId)?.name;
  const isDisplayed = !!(fxLines.length || colorSignalName || validColorRanges.length || colorConditions.length
    || (colorCapsuleProperty && capsulePropertyColorsConfig));

  return isDisplayed && (
    <LegendWrapper>
      <div className="scatterPlotLegend overflowYAuto">
        <ScatterPlotLegendFxLinesItems fxLines={namedFxLines} />
        <ScatterPlotLegendGradientItems displayName={colorCapsuleProperty} colorsConfig={capsulePropertyColorsConfig} />
        <ScatterPlotLegendColorRainbowCapsuleProperty propertyName={colorCapsuleProperty}
          colorsConfig={capsulePropertyColorsConfig} />
        <ScatterPlotLegendColorConditionItems colorRanges={validColorRanges} colorConditions={colorConditions} />
        <ScatterPlotLegendGradientItems displayName={colorSignalName} colorsConfig={gradientConfig} />
      </div>
    </LegendWrapper>
  );

};
export default ScatterPlotLegend;
export const sqScatterPlotLegend = angularComponent(scatterPlotLegendBindings, ScatterPlotLegend);

