import _ from 'lodash';
import angular from 'angular';
import moment from 'moment-timezone';
import { CAPSULE_SELECTION, CONDITION_TOOLS, DEFAULT_DATE_RANGE, OFFSET_DIRECTION } from '@/reportEditor/report.module';
import { UtilitiesService } from '@/services/utilities.service';
import { ReportActions } from '@/reportEditor/report.actions';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { DateTimeService } from '@/datetime/dateTime.service';
import { ReportStore } from '@/reportEditor/report.store';
import { DurationActions } from '@/trendData/duration.actions';
import { DurationStore } from '@/trendData/duration.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { FroalaReportContentService } from './froalaReportContent.service';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { DURATION_TIME_UNITS_ALL, NUMBER_CONVERSIONS } from '@/main/app.constants';
import { SEARCH_PANES } from '@/search/search.module';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { NotificationsService } from '@/services/notifications.service';
import { TrackService } from '@/track/track.service';
import { PUSH_IGNORE } from '@/services/stateSynchronizer.service';
import { ItemsApi } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';

angular.module('Sq.Report').controller('ReportDateModalCtrl', ReportDateModalCtrl);

function ReportDateModalCtrl(
  dateRange,
  $q: ng.IQService,
  $scope: ng.IScope,
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  $translate: ng.translate.ITranslateService,
  $interval: ng.IIntervalService,
  sqUtilities: UtilitiesService,
  sqNotifications: NotificationsService,
  sqReportActions: ReportActions,
  sqInvestigateActions: InvestigateActions,
  sqDateTime: DateTimeService,
  sqReportStore: ReportStore,
  sqReportEditor: ReportEditorService,
  sqDurationActions: DurationActions,
  sqDurationStore: DurationStore,
  sqWorksheetStore: WorksheetStore,
  sqFroalaReportContent: FroalaReportContentService,
  sqWorkbenchStore: WorkbenchStore,
  sqSystemConfiguration: SystemConfigurationService,
  sqTrack: TrackService,
  sqItemsApi: ItemsApi
) {

  const vm = this;

  // Constants
  vm.OFFSET_DIRECTION = OFFSET_DIRECTION;
  vm.CAPSULE_SELECTION = CAPSULE_SELECTION;
  vm.TRANS = {
    [vm.OFFSET_DIRECTION.PAST]: 'REPORT.CONFIG.IN_THE_PAST',
    [vm.OFFSET_DIRECTION.FUTURE]: 'REPORT.CONFIG.IN_THE_FUTURE',
    [vm.CAPSULE_SELECTION.STRATEGY.CLOSEST_TO]: 'REPORT.CONFIG.CLOSEST_TO',
    [vm.CAPSULE_SELECTION.STRATEGY.OFFSET_BY]: 'REPORT.CONFIG.OFFSET_BY',
    [vm.CAPSULE_SELECTION.REFERENCE.START]: 'START',
    [vm.CAPSULE_SELECTION.REFERENCE.END]: 'END'
  };
  vm.DEFAULT_DURATION = moment.duration(1, 'day');
  vm.SEARCH_PANE = SEARCH_PANES.MODAL;
  vm.SEARCH_TYPES = ['Condition'];
  vm.DURATION_TIME_UNITS_ALL = DURATION_TIME_UNITS_ALL;
  vm.DURATION_TIME_UNITS_RATE = DURATION_TIME_UNITS_ALL;
  vm.TREND_TOOLS = TREND_TOOLS;
  vm.CONDITION_TOOLS = CONDITION_TOOLS;
  vm.DATE_RANGE_TRACK_ACTION = 'Date Range';

  const start = moment().utc();
  vm.updateAutoUpdateDurationDates = {
    start,
    end: start.clone().add(1, 'day'),
    timezone: sqWorksheetStore.timezone
  };
  vm.updateAutoUpdateDuration = (duration) => {
    vm.dateRange.auto.duration = duration.valueOf();
    vm.updateDisplayRange();
  };
  vm.autoUpdateIsConfigured = false;
  vm.isSelectingRelative = false;
  vm.scopeIds = sqReportStore.contentWorkbookIds;

  // Supplied to modal by caller
  vm.dateRange = dateRange;
  vm.cronSchedule = dateRange?.auto?.cronSchedule;
  vm.dateRangeRate = sqFroalaReportContent.quartzCronExpressionHelper().cronScheduleToRate(vm.cronSchedule);

  // Functions
  vm.close = $uibModalInstance.close;
  vm.remove = remove;
  vm.save = save;
  vm.reset = reset;
  vm.getSaveText = () => vm.canSave() && vm.dateRange.auto.enabled && !vm.autoUpdateIsConfigured ? 'NEXT' : 'SAVE';
  vm.isConfiguredAutoUpdate = () => vm.dateRange.auto.enabled && vm.autoUpdateIsConfigured;
  vm.isConditionSelected = () => sqUtilities.validateGuid(vm.selectedCondition?.id);
  vm.editCondition = editCondition;
  vm.removeCondition = removeCondition;
  vm.canSave = canSave;
  vm.verifyUniqueName = verifyUniqueName;
  vm.updateDisplayRange = updateDisplayRange;
  vm.setDurationOffset = setDurationOffset;
  vm.computeCapsule = computeCapsule;
  vm.onConditionSelected = onConditionSelected;
  vm.getTimeWindowHeader = getTimeWindowHeader;
  vm.getDurationTimeUnit = sqDateTime.getDurationTimeUnit;
  vm.updateConditionHover = updateConditionHover;
  vm.onMaximumDurationChange = onMaximumDurationChange;
  vm.isMaximumDurationRequiredForItem = isMaximumDurationRequiredForItem;
  vm.updateDateRangeFromCapsule = updateDateRangeFromCapsule;

  // Exposed for test
  vm.initialize = initialize;
  vm.syncWithDurationStore = syncWithDurationStore;
  vm.syncWithWorkbenchStore = syncWithWorkbenchStore;

  vm.durationOffsetValid = true;
  vm.maximumDurationValid = true;
  vm.dateRangeIsSaving = false;
  vm.capsuleIsLoading = true;

  initialize();

  $scope.$listenTo(sqReportStore, ['dateRanges'], syncWithReportStore);
  $scope.$listenTo(sqDurationStore, ['displayRange'], syncWithDurationStore);
  $scope.$listenTo(sqWorksheetStore, ['timezone'], syncWithWorksheetStore);
  $scope.$listenTo(sqWorkbenchStore, ['displayUserProfile'], syncWithWorkbenchStore);

  /**
   * Initializes the modal
   */
  function initialize() {
    sqInvestigateActions.setActiveTool(TREND_TOOLS.PERIODIC_CONDITION);

    if (!vm.dateRange.name) {
      vm.dateRange.name = sqUtilities.getNextName(_.map(sqReportStore.dateRanges, 'name'),
        $translate.instant('REPORT.CONFIG.DATE_RANGE'));
    }

    _.defaultsDeep(vm.dateRange, DEFAULT_DATE_RANGE);

    updateSelectedCondition(vm.dateRange.condition);

    // Expand the advanced selection UI by default if a condition is selected or an offset is set
    vm.advancedSectionExpanded = vm.isConditionSelected() || vm.dateRange?.auto?.offset.value > 0;
    // Default to not show capsule properties
    vm.showCapsuleProperties = false;

    vm.updateDisplayRange();
    if (dateRange.auto.enabled) {
      computeCapsule();
    }

    if (vm.dateRange.condition.isCapsuleFromTable === undefined) {
      vm.isSelectingRelative = false;
    } else {
      vm.isSelectingRelative = !vm.dateRange.condition.isCapsuleFromTable;
    }
  }

  /**
   * Syncs the sqReportStore with the view-model
   */
  function syncWithReportStore() {
    vm.dateRanges = sqReportStore.dateRanges;
    vm.autoUpdateIsConfigured = sqReportStore.hasReportSchedule;
    vm.hasMultipleSchedules = sqReportStore.hasMultipleSchedules;
  }

  /**
   * Updates view model with properties from the duration store
   */
  function syncWithDurationStore(e?) {
    vm.updateAutoUpdateDurationDates.start = sqDurationStore.displayRange.start;
    vm.updateAutoUpdateDurationDates.end = sqDurationStore.displayRange.end;
    if (!vm.dateRange.condition.id) {
      vm.dateRange.range = {
        start: sqDurationStore.displayRange.start.valueOf(),
        end: sqDurationStore.displayRange.end.valueOf()
      };
      vm.dateRange.auto.duration = vm.dateRange.range.end - vm.dateRange.range.start;
    }

    vm.dateRange.condition.range = {
      start: sqDurationStore.displayRange.start.valueOf(),
      end: sqDurationStore.displayRange.end.valueOf()
    };

    if (!_.isEmpty(e)) {
      vm.computeCapsule();
    }
  }

  /**
   * Updates view model with properties from the trend store. Recompute the capsule date range if the time zone changed.
   */
  function syncWithWorksheetStore(e?) {
    vm.timezone = sqWorksheetStore.timezone;

    if (!_.isEmpty(e) && dateRange.auto.enabled) {
      vm.computeCapsule();
    }
  }

  /**
   * Evaluates when the sqWorkbenchStore displayUserProfile value goes from false to true, indicating that the user
   * has requested the user profile popup be displayed, in which case we close the report date modal so the user
   * profile popup will be visible and accessible.
   */
  function syncWithWorkbenchStore(e) {
    if (_.get(e, 'data.currentData') && !_.get(e, 'data.previousData')) {
      vm.close();
    }
  }

  /**
   * Determines if the date variable can be saved.
   *
   * @returns {Boolean} True if it can be saved, false otherwise.
   */
  function canSave() {
    if (vm.dateRange.irregularFormula || vm.dateRangeIsSaving) {
      return false;
    }

    // If not auto-updating then if a condition is selected a valid range must also be selected
    if (!vm.dateRange.auto.enabled && vm.isConditionSelected() &&
      (!_.get(vm.dateRange, 'range') || vm.isMaximumDurationRequiredForItem && !vm.maximumDurationValid)) {
      return false;
    }

    if (!vm.dateRange.auto.enabled && vm.isConditionSelected() && (!vm.computedCapsuleDisplay
      && vm.isSelectingRelative)) {
      return false;
    }

    if (!vm.dateRange.auto.enabled && !vm.isSelectingRelative && !vm.dateRange.condition.columns
      && vm.isConditionSelected()) {
      return false;
    }

    if (vm.dateRange.auto.enabled && !vm.durationOffsetValid) {
      return false;
    }

    return vm.form.$valid;
  }

  function setDurationOffset(durationOffset) {
    vm.dateRange.auto.offset = durationOffset;
    vm.durationOffsetValid = durationOffset.valid;
    vm.updateDisplayRange();
  }

  function trackDateRangeMetadata(info: string) {
    sqTrack.doTrack('Topic', vm.DATE_RANGE_TRACK_ACTION, `${vm.dateRange.auto.enabled ? 'Auto-Updating' :
      'Static'}`);
  }

  /**
   * Remove the dateRange, saves the report, and closes the modal
   *
   * @param {Object} dateRange - date range to remove
   */
  function remove(dateRange) {
    sqReportActions.removeDateRange(dateRange)
      .then(() => vm.close())
      .catch(sqNotifications.apiError);
  }

  /**
   * Reset the date range to default values, preserving name/id/description.
   */
  function reset() {
    vm.dateRange = {
      ..._.cloneDeep(DEFAULT_DATE_RANGE),
      id: vm.dateRange.id,
      name: vm.dateRange.name,
      description: vm.dateRange.description
    };
    vm.updateDisplayRange();
  }

  /**
   * Sets dateRange values based on a capsule picked from capsuleTable
   * saves table columns and sorting to restore for editing the date range
   */
  function updateDateRangeFromCapsule(capsule: { start, end }, columns: string[], sortBy: string, sortAsc: boolean) {
    vm.capsuleStart = sqDateTime.getMoment(capsule.start).valueOf();
    vm.capsuleEnd = sqDateTime.getMoment(capsule.end).valueOf();
    vm.dateRange.condition.columns = columns;
    vm.dateRange.condition.sortBy = sortBy;
    vm.dateRange.condition.sortAsc = sortAsc;
    $scope.$evalAsync();
  }

  /**
   * Saves the dateRange, saves the report, and closes the modal
   */
  function save() {
    vm.dateRangeIsSaving = true;

    if (vm.dateRange.condition.id) {
      _.set(vm.dateRange, 'range.start', vm.capsuleStart);
      _.set(vm.dateRange, 'range.end', vm.capsuleEnd);

      vm.dateRange.condition.range = {
        start: sqDurationStore.displayRange.start.valueOf(),
        end: sqDurationStore.displayRange.end.valueOf()
      };
    }

    vm.dateRange.condition.isCapsuleFromTable = !vm.isSelectingRelative;

    if (vm.dateRange.auto.enabled && sqReportStore.hasReportSchedule && !sqReportStore.hasMultipleSchedules) {
      vm.dateRange.auto.cronSchedule = sqReportStore.reportSchedule?.cronSchedule;
      vm.dateRange.auto.background = sqReportStore.reportSchedule?.background;
    }

    return sqReportActions.saveDateRange(vm.dateRange)
      .then(() => {
        trackDateRangeMetadata('Date Range Saved');
        vm.close(true);
      })
      .catch(sqNotifications.apiError)
      .finally(() => vm.dateRangeIsSaving = false);
  }

  /**
   * Updates the duration store display range based on the date variable
   */
  function updateDisplayRange() {
    const now = moment().utc().valueOf();

    if (vm.dateRange.auto.enabled) {
      const momentOffsetUnit = sqDateTime.momentMeasurementStrings(vm.dateRange.auto.offset.units);
      const offset = moment.duration(vm.dateRange.auto.offset.value, momentOffsetUnit) *
        (vm.dateRange.auto.offsetDirection === vm.OFFSET_DIRECTION.FUTURE ? 1 : -1);
      sqDurationActions.displayRange.updateTimes(now + offset - vm.dateRange.auto.duration,
        now + offset, PUSH_IGNORE);
    } else if (vm.dateRange?.condition?.range?.start) {
      sqDurationActions.displayRange.updateTimes(
        vm.dateRange.condition.range.start, vm.dateRange.condition.range.end, PUSH_IGNORE);
    } else if (vm.dateRange?.range?.start) {
      sqDurationActions.displayRange.updateTimes(vm.dateRange.range.start, vm.dateRange.range.end, PUSH_IGNORE);
    } else {
      // This is a new date range, so initialize it with the current time
      vm.dateRange.range.start = moment.utc(now).subtract(vm.dateRange.auto.duration).valueOf();
      vm.dateRange.range.end = moment.utc(now).valueOf();
      sqDurationActions.displayRange.updateTimes(vm.dateRange.range.start, vm.dateRange.range.end, PUSH_IGNORE);
    }
  }

  /**
   * Handler for user selection of either an existing condition or new periodic condition
   *
   * @param {Object} item - an item
   * @param {string} item.id - item ID
   * @param {string} item.name - item name
   */
  function onConditionSelected(item) {
    vm.editPeriodic = false;
    vm.capsuleIsLoading = true;
    vm.dateRange.condition.columns = undefined;
    vm.dateRange.condition.sortAsc = undefined;
    vm.dateRange.condition.sortBy = undefined;
    clearCapsuleDisplay();
    updateSelectedCondition(item);
  }

  /**
   * Gets the text for the time window header based on if auto update is enabled and/or a condition is selected.
   *
   * @returns {string} text for time window header
   */
  function getTimeWindowHeader() {
    if (vm.dateRange.auto.enabled) {
      return vm.isConditionSelected() ?
        'REPORT.CONFIG.TIME_WINDOW_AUTO_UPDATE_CAPSULE_HEADER' : 'REPORT.CONFIG.TIME_WINDOW_AUTO_UPDATE_HEADER';
    } else {
      return vm.isConditionSelected() ?
        'REPORT.CONFIG.TIME_WINDOW_CAPSULE_HEADER' : 'REPORT.CONFIG.TIME_WINDOW_HEADER';
    }
  }

  /**
   * Sets the index of the hovered item
   *
   * @param (Number) index - Index of the hovered item
   */
  function updateConditionHover(index) {
    vm.conditionHovered = index;
  }

  /**
   * Sets the maximum duration
   *
   * @param (Object) maximumDuration - The maximum duration
   * @param (Number) maximumDuration.value - The maximum duration value
   * @param (String) maximumDuration.units - The maximum duration units
   * @param (boolean) maximumDuration.valid - The validity of the maximum duration
   */
  function onMaximumDurationChange(maximumDuration) {
    vm.maximumDurationValid = maximumDuration.valid;
    vm.dateRange.condition.maximumDuration = vm.selectedCondition.maximumDuration = maximumDuration;
    if (!_.isNil(vm.selectedCondition.maximumDuration.value) && !_.isNil(vm.selectedCondition.maximumDuration.units)) {
      vm.computeCapsule();
    }
  }

  /**
   * Determines if the maximum capsule duration input field should be displayed for the specified item. It is only
   * required if item is an unbounded condition.
   *
   * @param {Object} item - The item to check
   * @returns {boolean} True if the duration should be shown, false otherwise
   */
  function isMaximumDurationRequiredForItem(item) {
    return !vm.selectedCondition.hasMaximumDuration;
  }

  /**
   * Updates the view model selected condition.
   *
   * @param {Object} item - an item
   * @param {string} item.id - item ID
   * @param {string} item.name - item name
   * @return {Promise} Promise which is resolved when the item is updated
   */
  function updateSelectedCondition(item) {
    if (!sqUtilities.validateGuid(item.id)) {
      return $q.resolve();
    }

    vm.selectedCondition = {
      ..._.pick(item, ['id', 'name']), description: 'REPORT.CONFIG.SELECTED_CONDITION'
    };
    vm.selectedCondition.hasMaximumDuration = true;

    return sqItemsApi.getItemAndAllProperties({ id: item.id })
      .then(({ data: item }) => {
        // Annotate periodic conditions with isPeriodic to flag that they can be edited if desired
        _.set(vm, 'selectedCondition.isPeriodic', (sqUtilities.getToolType(item) === TREND_TOOLS.PERIODIC_CONDITION));

        // Determine if the condition already has a maximum duration
        const conditionMaxDuration: any = _.find(item.properties, { name: SeeqNames.Properties.MaximumDuration });
        if (!conditionMaxDuration) {
          _.set(vm, 'selectedCondition.hasMaximumDuration', false);
          _.set(vm, 'selectedCondition.maximumDuration',
            vm.dateRange.condition.maximumDuration ? vm.dateRange.condition.maximumDuration :
              sqSystemConfiguration.defaultMaxCapsuleDuration);
        }
        _.assign(vm.dateRange.condition, vm.selectedCondition);
        vm.computeCapsule();
      });
  }

  /**
   * Determines the capsule that defines the auto update time range given the user selected condition and offset
   * parameters. Updates the capsule display if a capsule is found, clears it otherwise.
   */
  function computeCapsule() {
    if (vm.isConditionSelected()) {
      // Show spinner if capsule loading takes longer than a second
      const loadingDelayPromise = $interval(() => {
        vm.capsuleIsLoading = true;
      }, 1000, 1);
      const range = {
        start: sqDurationStore.displayRange.start.valueOf(),
        end: sqDurationStore.displayRange.end.valueOf()
      };
      const offset = sqFroalaReportContent.computeCapsuleOffset(vm.dateRange.condition);
      $q.all([sqReportActions.computeCapsule(vm.selectedCondition, range, offset),
          sqReportActions.computeCapsuleCount(vm.selectedCondition, range)])
        .then(([capsule, count]) => {
          vm.capsuleStart = capsule.start / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND;
          vm.capsuleEnd = capsule.end / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND;

          vm.computedCapsuleDisplay = formatCapsuleForDisplay(vm.capsuleStart, vm.capsuleEnd);
          vm.computedCapsuleProperties = capsule.properties;
          vm.capsuleCount = count;
          vm.capsuleCountLeft = vm.dateRange.condition.reference === CAPSULE_SELECTION.REFERENCE.START
            ? Math.min(Math.abs(offset) - 1, count) : Math.max(0, count - Math.abs(offset));
          vm.capsuleCountRight = count - vm.capsuleCountLeft - 1;
        })
        .catch(clearCapsuleDisplay)
        .finally(() => {
          $interval.cancel(loadingDelayPromise);
          vm.capsuleIsLoading = false;
        });
      return;
    }

    if (vm.dateRange.irregularFormula) {
      vm.computedCapsuleDisplay = formatCapsuleForDisplay(vm.dateRange.range?.start, vm.dateRange.range?.end);
      return;
    }

    clearCapsuleDisplay();
  }

  /**
   * Format the computed start and end for display
   *
   * @param start - start time, in milliseconds
   * @param end - end time, in milliseconds
   * @returns Formatted string of the capsule start/end/time zone/duration, for display
   */
  function formatCapsuleForDisplay(start: number, end: number): string {
    const format = 'l LT';
    const startDisplay = sqDateTime.formatTime(start, sqWorksheetStore.timezone, format);
    const endDisplay = sqDateTime.formatTime(end, sqWorksheetStore.timezone, format);
    const durationDisplay = sqDateTime.formatDuration(end - start, true);
    return `${startDisplay} - ${endDisplay} (${durationDisplay})`;
  }

  /**
   * Clears capsule display properties on the view model
   */
  function clearCapsuleDisplay() {
    vm.capsuleCount = undefined;
    vm.capsuleCountLeft = undefined;
    vm.capsuleCountRight = undefined;
    vm.computedCapsuleDisplay = undefined;
    vm.dateRange.capsule = undefined;
  }

  /**
   * Moves the modal to a state where the existing condition can be edited or replaced. If the selected condition is a
   * periodic one then the tool is loaded for edit, otherwise the search tab is activated.
   */
  function editCondition() {
    if (vm.editPeriodic) {
      vm.editPeriodic = false;
    } else {
      sqInvestigateActions.loadToolForEdit(vm.selectedCondition.id)
        .then(() => {
          vm.editPeriodic = true;
        });
    }
  }

  /**
   * Removes the selected condition
   */
  function removeCondition() {
    vm.selectedCondition = undefined;
    vm.dateRange.condition.id = undefined;
    vm.dateRange.condition.name = undefined;
    vm.dateRange.condition.maximumDuration = undefined;
    vm.editPeriodic = false;
    vm.dateRange.condition.columns = undefined;
  }

  /**
   * Verifies that the name for the date variable is unique among other date variables used by the report.
   *
   * @param {String} name - The name to be checked.
   */
  function verifyUniqueName(name) {
    vm.form.name.$setValidity('unique', !_.chain(vm.dateRanges)
      .reject(['id', vm.dateRange.id])
      .map('name')
      .map(_.toLower)
      .includes(_.toLower(name))
      .value());
  }
}
