import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { CELL_TYPES, Table, TableColumn } from '@/hybrid/core/Table.atom';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { CapsuleV1, ConditionsApi } from '@/sdk';
import _ from 'lodash';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import moment from 'moment-timezone';
import { Icon } from '@/hybrid/core/Icon.atom';
import { DateTimeService } from '@/datetime/dateTime.service';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { ItemPropertiesSelectorButton } from '@/hybrid/utilities/ItemPropertiesSelectorButton.molecule';
import { SuggestedPropertiesMode } from '@/hybrid/utilities/CustomPropertySelector.atom';
import { Capsule } from '../../../../plugin/sdk/seeq';
import { TrendStore } from '@/trendData/trend.store';
import { TrendActions } from '@/trendData/trend.actions';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

const capsuleTableBindings = bindingsDefinition({
  condition: prop<Capsule>(),
  start: prop<string>(),
  end: prop<string>(),
  savedColumns: prop.optional<string[]>(),
  savedSortAsc: prop.optional<boolean>(),
  savedSortBy: prop.optional<string>(),
  savedStart: prop<string>(),
  savedEnd: prop<string>(),
  updateDateRange: prop<(capsule, columns, sortBy, sortAsc) => void>(),
  sqDateTime: injected<DateTimeService>(),
  sqPendingRequests: injected<PendingRequestsService>(),
  sqConditionsApi: injected<ConditionsApi>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqTrendStore: injected<TrendStore>(),
  sqTrendActions: injected<TrendActions>()
});

export enum CapsuleSort {
  Start = 'start',
  End = 'end',
  Duration = 'duration',
}

export const CapsuleTable: SeeqComponent<typeof capsuleTableBindings> = (props) => {
  const {
    condition,
    start,
    end,
    updateDateRange,
    savedColumns = [],
    savedSortBy = CapsuleSort.Start,
    savedSortAsc = true,
    savedStart,
    savedEnd
  } = props;

  const {
    sqPendingRequests,
    sqConditionsApi,
    sqDateTime,
    sqWorksheetStore
  } = useInjectedBindings(capsuleTableBindings);

  const [selectedId, setSelectedId] = useState('');
  const [capsules, setCapsules] = useState([]);
  const [columnKeys, setColumnKeys] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [sort, setSort] = useState({ sortBy: savedSortBy, sortAsc: savedSortAsc });
  const hasSavedColumns = !_.isEmpty(savedColumns);

  const cancellationGroup = 'capsuleTable';
  const { t } = useTranslation();

  const renderInitialColumn = (column, columnName) => {
    const renderingColumn = columnName.toLowerCase();
    let formattedColumn;
    if (renderingColumn === 'duration') {
      formattedColumn = sqDateTime.formatDuration(column[columnName.toLowerCase()]);
    } else {
      formattedColumn = sqDateTime.formatTime(column[columnName.toLowerCase()], sqWorksheetStore.timezone);
    }
    return renderColumn(formattedColumn, columnName);
  };

  const initialColumns: TableColumn[] = [{
    header: '',
    accessor: 'id',
    sortable: false,
    filterable: false,
    cellType: CELL_TYPES.ROW_SELECTION,
    cellStyle: { width: 30, maxWidth: 30 }
  }, {
    accessor: 'start',
    header: t('START'),
    filterable: false,
    sortable: true,
    cellStyle: { minWidth: 130, maxWidth: 150 },
    cellRenderFunction: renderInitialColumn
  }, {
    accessor: 'end',
    header: t('END'),
    sortable: true,
    filterable: false,
    cellStyle: { minWidth: 130, maxWidth: 150 },
    cellRenderFunction: renderInitialColumn
  }, {
    accessor: 'duration',
    header: t('DURATION'),
    sortable: true,
    filterable: false,
    cellStyle: { minWidth: 80, maxWidth: 80 },
    cellRenderFunction: renderInitialColumn
  }];

  const getPropertyValue = (column, propertyName) => {
    const propertyValue = _.chain(column.properties)
      .filter({ name: propertyName })
      .map('value')
      .value();
    return renderColumn(propertyValue, propertyName);
  };

  const propertyColumns = _.chain(columnKeys)
    .reject(key => _.some(initialColumns, { accessor: key }))
    .map(key => ({
      accessor: key,
      header: t(key),
      key,
      title: t(key),
      sortable: true,
      filterable: false,
      cellStyle: { minWidth: 80, maxWidth: 130 },
      cellRenderFunction: getPropertyValue
    }))
    .value();

  const extraColumns = _.chain(initialColumns)
    .reject({ accessor: 'id' })
    .map(column => ({ key: column.accessor, title: column.header }))
    .value();

  useEffect(() => {
    initializeColumns();
  }, []);

  useEffect(() => {
    setCapsules(sortCapsules(capsules, sort.sortBy, sort.sortAsc));
  }, [sort]);

  useEffect(() => {
    fetchCapsules();
  }, [start, end]);

  const renderColumn = (formattedColumn, columnName) => {
    return (
      <div data-testid={`column-${columnName}`}>
        {formattedColumn}
      </div>
    );
  };

  // If creating new date range sets default initial columns
  // If editing an existing date range, sets initial columns and property columns as saved
  const initializeColumns = () => {
    if (hasSavedColumns) {
      setColumnKeys(savedColumns);
    } else {
      setColumnKeys(_.map(initialColumns, 'accessor'));
    }
  };

  // fetches capsules and filters out capsules with missing or invalid start/end
  const fetchCapsules = () => {
    setTableLoading(true);
    sqPendingRequests.cancelGroup(cancellationGroup)
      .then(() => sqConditionsApi.getCapsules({
          id: condition.id,
          start: moment.utc(start).toISOString(),
          end: moment.utc(end).toISOString()
        }, { cancellationGroup })
        .then(({ data: { capsules } }) => {
          setValidCapsules(capsules);
        }))
      .finally(() => {
        setTableLoading(false);
      });
  };

  // Rejects invalid capsules, adds duration attribute, remembers saved capsule when editing
  const setValidCapsules = (capsules) => {
    const isInvalid = property => _.isNil(property) || _.endsWith(property, '?');
    const filteredCapsules = _.chain(capsules)
      .reject(capsule => isInvalid(capsule.start) || isInvalid(capsule.end))
      .map(capsule => ({
        ...capsule,
        duration: (sqDateTime.getMoment(capsule.end).valueOf() - sqDateTime.getMoment(capsule.start).valueOf())
      }))
      .value();
    if (hasSavedColumns) {
      setSelectedId(_.find(filteredCapsules, capsule =>
        sqDateTime.getMoment(capsule.start).valueOf() === savedStart &&
        sqDateTime.getMoment(capsule.end).valueOf() === savedEnd)?.id);
    }
    setCapsules(sortCapsules(filteredCapsules, sort.sortBy, sort.sortAsc));
  };

  const selectCallback = (item: CapsuleV1) => {
    updateDateRange(item, columnKeys, sort.sortBy, sort.sortAsc);
    setSelectedId(item.id);
  };

  const toggleColumn = (column) => {
    if (isColumnEnabled(column)) {
      setColumnKeys(_.without(columnKeys, column.key));
    } else {
      addColumn(column.key);
    }
  };

  const addColumn = (key) => {
    setColumnKeys(columnKeys.concat(key));
  };

  const sortClick = (sortBy, sortAscArg) => {
    setSort({ sortBy, sortAsc: !sortAscArg });
  };

  const sortCapsules = (capsules, sortBy, sortAsc) => {
    let sortArray;
    if (_.includes(['start', 'end', 'duration'], sort.sortBy)) {
      sortArray = [sort.sortBy, CapsuleSort];
    } else {
      sortArray = [(capsule: CapsuleV1) => _.find(capsule.properties, { name: sort.sortBy })?.value];
    }
    return _.orderBy(capsules, sortArray, sortAsc ? 'asc' : 'desc');
  };

  const isColumnEnabled = column => _.includes(columnKeys, column.key);

  return (
    <div className="flexRowContainer max-height-400 mb5">
      <div className="flexColumnContainer flexSpaceBetween flexAlignStart mt10">
        <label>{t('REPORT.CONFIG.SELECT_THE_CAPSULE')}</label>
        <div data-testid="addColumns">
          <ItemPropertiesSelectorButton
            conditions={[condition]}
            suggestedPropertiesMode={SuggestedPropertiesMode.Capsules}
            extraColumns={extraColumns}
            propertyColumns={propertyColumns}
            isColumnEnabled={isColumnEnabled}
            toggleColumn={toggleColumn}
            addPropertyColumn={({ propertyName }) => addColumn(propertyName)}
            iconClasses='cursorPointer fa-lg sq-text-primary'
          /></div>
      </div>
      <div className="tableWrapper">
        <Table
          onRowClickCallback={selectCallback}
          onRowSelectCallback={selectCallback}
          isRowSelectRadio={true}
          selectedIds={[selectedId]}
          sortProperty={sort.sortBy}
          sortAscending={sort.sortAsc}
          sortTableCallback={sortClick}
          columns={_.chain(initialColumns)
            .reject(column => !_.includes(columnKeys, column.accessor))
            .concat(propertyColumns)
            .value()}
          items={capsules}
        />
        {_.isEmpty(capsules) && !tableLoading &&
        <div className="flexColumnContainer flexCenter">
          <div className="help-block sq-text-danger small">
            {t('REPORT.CONFIG.CAPSULES_VALIDATE')}
          </div>
        </div>}
        {tableLoading &&
        <div className="flexColumnContainer flexCenter pt50 pb50">
          <Icon icon="fa-spinner fa-pulse fa-5x" extraClassNames="sq-text-primary" />
        </div>}
      </div>
    </div>
  );
};

export const sqCapsuleTable = angularComponent(capsuleTableBindings, CapsuleTable);
