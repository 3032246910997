import angular from 'angular';
import { DurationStore } from '@/trendData/duration.store';
import { DateTimeService } from '@/datetime/dateTime.service';
import { TrendActions } from '@/trendData/trend.actions';
import { MAX_SERIES_PIXELS } from '@/trendData/trendData.module';

angular.module('Sq.TrendData').factory('sqTableHelper', sqTableHelper);

export type TableHelperService = ReturnType<typeof sqTableHelper>;

function sqTableHelper(
  $injector: ng.auto.IInjectorService,
  sqDurationStore: DurationStore,
  sqDateTime: DateTimeService
) {
  const service = {
    getViewCapsuleParameter,
    getNumPixelParameter
  };

  return service;

  /**
   * Returns the viewCapsule object that represents the "unbound" parameter required to execute a table.
   * The backend expects an unbound parameter to be defined by the following JSON:
   * {unbound: true, name: '<name>', formula: 'capsule(<start>, <end>)'}
   *
   * @returns {Object} defining and unbound parameter called viewCapsule.
   */
  function getViewCapsuleParameter() {
    return {
      unbound: true,
      name: 'viewCapsule',
      formula: sqDateTime.getCapsuleFormula(sqDurationStore.displayRange)
    };
  }

  /**
   * Returns the numPixels object that represents the "unbound" parameter required to execute a fft table.
   * The backend expects an unbound parameter to be defined by the following JSON:
   * {unbound: true, name: 'numPixels', formula: chartWidth}
   *
   * @returns {Object} defining and unbound parameter called numPixels.
   */
  function getNumPixelParameter() {
    const sqTrendActions = $injector.get<TrendActions>('sqTrendActions');

    return {
      unbound: true,
      name: 'numPixels',
      formula: Math.min(sqTrendActions.getChartWidth(), MAX_SERIES_PIXELS)
    };
  }
}
