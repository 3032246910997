import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import _ from 'lodash';
import { AssetSelection } from '@/reportEditor/report.module';
import { ReportActions } from '@/reportEditor/report.actions';
import { ReportStore } from '@/reportEditor/report.store';
import { TrackService } from '@/track/track.service';
import { useInjectedBindings } from '../core/useInjectedBindings.hook';
import { UtilitiesService } from '@/services/utilities.service';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Form, FormControl, Modal } from 'react-bootstrap';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { SelectAssetModal } from '@/hybrid/core/SelectAssetModal.molecule';
import { FormulaService } from '@/services/formula.service';
import { TreesApi } from '@/sdk';
import { IPromise } from 'angular';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { onEventPreventPropagation } from '@/hybrid/core/onEnterKeypress.util';

const configureAssetSelectionModalBindings = bindingsDefinition({
  show: prop<boolean>(),
  onClose: prop<() => void>(),
  assetSelections: prop<AssetSelection []>(),
  currentSelection: prop.optional<AssetSelection | null>(),
  sqReportStore: injected<ReportStore>(),
  sqReportActions: injected<ReportActions>(),
  sqFormula: injected<FormulaService>(),
  sqTreesApi: injected<TreesApi>(),
  sqTrack: injected<TrackService>(),
  sqUtilities: injected<UtilitiesService>()
});

export const ConfigureAssetsSelectionModal: SeeqComponent<typeof configureAssetSelectionModalBindings> = (props) => {
  const {
    sqReportStore,
    sqReportActions,
    sqFormula,
    sqTrack,
    sqTreesApi,
    sqUtilities
  } = useInjectedBindings(configureAssetSelectionModalBindings);

  const { show, onClose, assetSelections, currentSelection = null } = props;
  const { t } = useTranslation();
  const getDefaultName = () => sqUtilities.getNextDefaultName(
    _.map(assetSelections, selection => selection.name), 'REPORT.CONFIG.ASSET_SELECTION');

  const [selectionName, setSelectionName] = useState('');
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedDepth, setSelectedDepth] = useState(1);
  const [currentPath, setCurrentPath] = useState(null);
  const [canSave, setCanSave] = useState(false);
  const selectionId = currentSelection ? currentSelection.selectionId : undefined;
  const [isNew, setIsNew] = useState(!currentSelection);
  const checkThatSelectedItemHasAtLeastOneNonAssetChild = (item): IPromise<boolean> =>
    sqTreesApi.getTree({ id: item.id })
      .then(({ data }) => _.some(data.children, child => !sqUtilities.isAsset(child)));

  useEffect(() => {
    if(show){
      if (selectedAsset) {
        sqFormula.getAssetPath(selectedAsset, selectedDepth)
          .then(path => setCurrentPath(path));
        checkThatSelectedItemHasAtLeastOneNonAssetChild(selectedAsset)
          .then(isValid => setCanSave(isValid && !!selectionName));
      } else {
        setCanSave(false);
      }
      if (isNew && !currentSelection) {
        setIsNew(false);
        setSelectionName(getDefaultName());
      }
    }
  });

  useEffect(() => {
    if (currentSelection) {
      setIsNew(false);
      setSelectedAsset(currentSelection.asset);
      setSelectionName(currentSelection.name);
      setSelectedDepth(currentSelection.assetPathDepth);
      sqFormula.getAssetPath(currentSelection.asset, currentSelection.assetPathDepth)
        .then(path => setCurrentPath(path));
    } else {
      selectedAsset && setSelectedAsset(null);
      setSelectionName(getDefaultName());
    }
  }, [currentSelection]);

  const clearAndClose = () => {
    setSelectedAsset(null);
    setSelectedDepth(1);
    setCurrentPath(null);
    setSelectionName('');
    onClose();
    setIsNew(true);
  };

  const onSave = () => {
    sqTrack.doTrack('Topic Asset Selection', `Selection ${currentSelection ? 'Updated' : 'Created'}`);
    sqReportActions.saveAssetSelection({
        asset: selectedAsset,
        name: selectionName,
        selectionId,
        isArchived: currentSelection ? currentSelection.isArchived : false,
        reportId: sqReportStore.id,
        assetPathDepth: selectedDepth
      })
      .then(() => clearAndClose());
  };

  const onDelete = (selection) => {
    sqReportActions.removeAssetSelection(selection)
      .then(() => clearAndClose());
  };

  const onSelect = (item) => {
    setSelectedAsset(item);
    sqFormula.getAssetPath(item, selectedDepth).then(path => setCurrentPath(path));
  };

  const onChangePathDepth = event => setSelectedDepth(_.toNumber(event.target.value));
  const saveButtonTooltip = canSave ? null : selectionName ? 'REPORT.CONFIG.ASSET_SELECTION_NOT_VALID_TO_SAVE' :
    'REPORT.CONFIG.ASSET_SELECTION_MUST_HAVE_NAME';
  const saveButtonExtraClass = canSave ? '' : 'noMouse';

  const searchResultIcons = (item) => {
    const isSelected = item.id === selectedAsset?.id;
    return sqUtilities.isAsset(item) && (
      <>
        <HoverTooltip text='REPORT.CONFIG.ASSET_TOOLTIP' delay={500}>
          <span onClick={onEventPreventPropagation(() => onSelect(item))}>
            <Icon testId="assetSelect" extraClassNames="searchBtn" large={true}
              icon={isSelected ? 'fa-check-circle' : 'fa-circle-o'} type='theme'
            />
          </span>
        </HoverTooltip>
      </>
    );
  };

  const pathElement = <Form className="flexRowContainer alert alert-info">
    <div className="flexColumnContainer flexSpaceBetween ">
      <div className="flexRowContainer flexAlignStart">
        <label>{t('REPORT.ASSET_SELECTION_PATH')}</label>
        <span className="flexWrap textAlignLeft">{currentPath}</span>
      </div>
      <div className="flexRowContainer flexAlignEnd pl2">
        <label className="nowrap" htmlFor="assetSelectionPathLevelInput">
          {t('REPORT.ASSET_SELECTION_PATH_LEVEL')}
        </label>
        <FormControl className="max-width-70"
          id="assetSelectionPathLevelInput"
          name="value"
          type="number"
          value={selectedDepth}
          onChange={onChangePathDepth}
          min={1}
        />
      </div>
    </div>
  </Form>;

  const footer = <Modal.Footer>
    <div className="flexRowContainer flexFill">
      {currentPath && pathElement}
      <div className="flexColumnContainer flexSpaceBetween pt2">
        <div>
          {currentSelection &&
          <TextButton
            onClick={() => onDelete(currentSelection)}
            label="DELETE"
            variant="danger"
            testId="assetSelectionDelete"
          />}
        </div>
        <div>
          <TextButton
            onClick={clearAndClose}
            label="REPORT.CANCEL"
            extraClassNames="mr5"
            testId="assetSelectionCancel"
          />
          <HoverTooltip text={saveButtonTooltip}>
            <span data-testid={saveButtonTooltip}>
              <TextButton
                testId="assetSelectionSave"
                extraClassNames={saveButtonExtraClass}
                onClick={onSave}
                label="SAVE"
                variant="theme"
                disabled={!canSave}
              />
            </span>
          </HoverTooltip>
        </div>
      </div>
    </div>
  </Modal.Footer>;

  const body = <div className="alert alert-info">
    {t('REPORT.ASSET_SELECTION_HELP_TEXT')}
  </div>;

  const header = <Form.Group className="flexColumnContainer flexCenter flexFill mb0">
    <Icon icon='fa-cube' extraClassNames="fa-2x mt2 mr5" />
    <Form.Control
      autoComplete="off"
      type="text"
      value={selectionName}
      required={true}
      isInvalid={!selectionName}
      onChange={e => setSelectionName(e.target.value)}
      placeholder={t('REPORT.ASSET_SELECTION_NAME')}
      className="input-lg"
    />
  </Form.Group>;

  return <SelectAssetModal
    header={header}
    footer={footer}
    body={body}
    onClose={clearAndClose}
    show={show}
    showHeaderCloseButton={false}
    searchResultIcons={searchResultIcons}
    scopeIds={sqReportStore.contentWorkbookIds}
  />;
};
