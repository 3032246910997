import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { ALL } from '@/hybrid/tools/periodicCondition/periodicCondition.module';
import { getFormFieldProps, getValidationFunction, canUseMemo } from '@/hybrid/formbuilder/formbuilder.utilities';
import { Field, useForm } from 'react-final-form';

export interface CheckboxTableIF extends ValidatingFormComponent<any> {
  component: 'CheckboxTableFormComponent';
  options: { key: string, display: string }[];
  includeAll?: boolean;
}

export const CheckboxTableFormComponentUnwrapped: React.FunctionComponent<CheckboxTableIF> = (props) => {
  const {
    name,
    extraClassNames,
    onChange,
    options,
    includeAll = false,
    validation,
    extendValidation,
    value // value is the array of the keys of the selected options
  } = props;

  const tableOptions = includeAll ? _.concat(ALL, options) : options;

  const isOptionSelected = (option) => {
    return (option === 'all' && includeAll && _.isEmpty(value)) ||
      _.includes(value, option);
  };

  const defaultValidation = value => _.isEmpty(value) && (includeAll && !isOptionSelected('all'));
  const formState = useForm().getState();

  return (
    <Field name={name} validate={getValidationFunction(defaultValidation, validation, extendValidation)}>
      {({ input, meta }) => (
        <table className={classNames('table', 'table-striped', 'table-condensed', 'mb0', extraClassNames)} id={name}
          data-testid={name}>
          <tbody>
            {_.map(tableOptions, option => (
              <tr key={option.key} className="cursorPointer">
                <td className="checkboxTable" onClick={(e) => {
                  e.preventDefault();
                  onChange(option.key);
                }}>
                  <Checkbox
                    {...getFormFieldProps(formState, input, meta, props)}
                    isChecked={isOptionSelected(option.key)}
                    id={`${option.key}Checkbox`}
                    label={option.display} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Field>
  );
};

export const CheckboxTableFormComponent = React.memo(CheckboxTableFormComponentUnwrapped, canUseMemo);
