import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { Modal } from 'react-bootstrap';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { FORM_ERROR, FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { NotificationsService } from '@/services/notifications.service';
import { UserInputV1, UsersApi } from '@/sdk';
import { AdministrationActions } from '@/administration/administration.actions';

const editUserModalBindings = bindingsDefinition({
  onClose: prop<() => void>(),
  user: prop<{
    id: string,
    firstName: string,
    lastName: string,
    username: string,
    email: string,
    password: string,
    isEnabled: string,
    isAdmin: boolean,
    isPasswordSettable: boolean
  }>(),
  sqWorkbenchStore: injected<WorkbenchStore>(),
  sqUsersApi: injected<UsersApi>(),
  sqWorkbenchActions: injected<WorkbenchActions>(),
  sqAdministrationActions: injected<AdministrationActions>(),
  sqNotifications: injected<NotificationsService>()
});

export const EditUserModal: SeeqComponent<typeof editUserModalBindings> = ({ onClose, user }) => {
  const {
    sqWorkbenchStore,
    sqUsersApi,
    sqWorkbenchActions,
    sqNotifications,
    sqAdministrationActions
  } = useInjectedBindings(editUserModalBindings);

  const { t } = useTranslation();

  const [editingPassword, setEditingPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMismatch, setPasswordsMismatch] = useState(false);
  const [formProcessing, setFormProcessing] = useState(false);

  const userStatusOptions = [
    { value: true, text: 'ADMIN.USER.ENABLED' },
    { value: false, text: 'ADMIN.USER.DISABLED' }
  ];

  const PASSWORD_MIN_LENGTH = 8;
  const validateMinLength = value => _.isEmpty(value) || _.size(value) < PASSWORD_MIN_LENGTH;

  useEffect(() => {
    setPasswordsMismatch(!_.isEmpty(newPassword) && !_.isEmpty(confirmPassword) && newPassword !== confirmPassword);
  }, [newPassword, confirmPassword]);

  const updateUser = (values) => {
    const { firstName, lastName, email, isAdmin, isEnabled } = values;
    const userProps = {
      firstName,
      lastName,
      email,
      isAdmin,
      isEnabled: isEnabled.value
    };

    const name = `${_.trim(firstName)} ${_.trim(lastName)}`;

    setFormProcessing(true);
    _.assign(userProps, { name });

    /**
     * For SEEQ users we allow them to update their email, but their email is also their username. So, when they
     * update their email, then we have to also update their username (as email and username seem to be one to the
     * end
     * user, at least right now). If a user is however an Active Directory user then they really just edit an email,
     * not their username. IsPasswordSettable is the flag that tells us if they can modify all their properties or
     * not.
     */

    if (user.isPasswordSettable) {
      _.assign(userProps, { username: email });
    }

    return sqUsersApi.updateUser(userProps as UserInputV1, { id: user.id })
      .then(({ data }) => {
        sqAdministrationActions.updateUser(data);

        if (data.id === sqWorkbenchStore.currentUser.id) {
          sqWorkbenchActions.setCurrentUser();
        }
      })
      .then(() => sqNotifications.successTranslate('USER.UPDATED'))
      .then(onClose)
      .catch(sqNotifications.apiError)
      .finally(() => setFormProcessing(false));
  };

  const updatePassword = () => {
    setFormProcessing(true);
    return sqUsersApi.updatePassword({ currentPassword, newPassword }, { id: user.id })
      // We assume that authProvider is Seeq because only those users can change their password.
      .then(() => sqNotifications.successTranslate('USER.PASSWORD_CHANGED'))
      .then(onClose)
      .catch(sqNotifications.apiError)
      .finally(() => setFormProcessing(false));
  };

  const displayEditUser = () => setEditingPassword(false);

  const passwordChangeForm: FormElement = {
    component: 'FormGroup',
    name: 'editPasswordFormGroup',
    includeIf: editingPassword,
    components: [{
      component: 'LabelFormComponent',
      name: 'newPasswordLabel',
      value: 'USER.NEW_PASSWORD',
      extraClassNames: 'mt8 text-bolder'
    }, {
      component: 'FormControlFormComponent',
      name: 'password',
      value: newPassword,
      onChange: (value: string) => setNewPassword(value),
      placeholder: 'USER.PASSWORD_PROMPT',
      validation: validateMinLength,
      customError: 'USER.PASSWORD_MIN_LENGTH',
      customErrorParams: { min: PASSWORD_MIN_LENGTH },
      className: 'mb10',
      size: 'md',
      type: 'password',
      testId: 'password'
    }, {
      component: 'LabelFormComponent',
      name: 'confirmPasswordLabel',
      value: 'USER.PASSWORD_CHECK',
      extraClassNames: 'mt8 text-bolder'
    }, {
      component: 'FormControlFormComponent',
      name: 'confirmPassword',
      value: confirmPassword,
      onChange: (value: string) => setConfirmPassword(value),
      placeholder: 'USER.PASSWORD_CHECK_PROMPT',
      validation: validateMinLength,
      customError: 'USER.PASSWORD_MIN_LENGTH',
      customErrorParams: { min: PASSWORD_MIN_LENGTH },
      className: 'mb10',
      size: 'md',
      type: 'password',
      testId: 'confirmPassword'
    }, {
      component: 'ErrorMessageFormComponent',
      name: 'mismatchedPasswords',
      type: FORM_ERROR,
      failForm: true,
      includeIf: passwordsMismatch,
      value: 'USER.PASSWORD_MISMATCH'
    }]
  };

  const formDefinition: FormElement[] = [{
    component: 'FormGroup',
    name: 'userProfile',
    includeIf: !editingPassword,
    components: [{
      component: 'LabelFormComponent',
      name: 'userNameLabel',
      value: 'USER.USERNAME',
      extraClassNames: 'mt8 text-bolder'
    },
      {
        component: 'LabelFormComponent',
        name: 'username',
        value: user.username,
        testId: 'username',
        extraClassNames: 'mt8 text-italic'
      },
      {
        component: 'LabelFormComponent',
        name: 'firstNameLabel',
        value: 'USER.FIRST_NAME',
        extraClassNames: 'mt8 text-bolder'
      }, {
        component: 'FormControlFormComponent',
        name: 'firstName',
        value: user.firstName,
        onChange: _.noop,
        placeholder: 'USER.FIRST_NAME_PROMPT',
        className: 'mb10',
        size: 'md',
        testId: 'firstName'
      }, {
        component: 'LabelFormComponent',
        name: 'lastNameLabel',
        value: 'USER.LAST_NAME',
        extraClassNames: 'mt8 text-bolder'
      }, {
        component: 'FormControlFormComponent',
        name: 'lastName',
        value: user.lastName,
        onChange: _.noop,
        placeholder: 'USER.LAST_NAME_PROMPT',
        className: 'mb10',
        size: 'md',
        testId: 'lastName'
      },
      {
        component: 'LabelFormComponent',
        name: 'emailLabel',
        value: 'USER.EMAIL',
        extraClassNames: 'mt8 text-bolder'
      }, {
        component: 'FormControlFormComponent',
        name: 'email',
        value: user.email,
        onChange: _.noop,
        placeholder: 'USER.EMAIL_PROMPT',
        className: 'mb10',
        size: 'md',
        testId: 'email'
      },
      {
        component: 'LabelFormComponent',
        name: 'isEnabledLabel',
        value: 'USER.ENABLED',
        extraClassNames: 'mt8 text-bolder'
      }, {
        component: 'IconSelectFormComponent',
        validation: _.isUndefined,
        name: 'isEnabled',
        value: user ? user.isEnabled : true,
        onChange: _.noop,
        selectOptions: userStatusOptions,
        insideModal: true,
        skipStore: true,
        testId: 'isEnabled'
      }, {
        component: 'CheckboxFormComponent',
        id: 'isAdmin',
        name: 'isAdmin',
        onChange: _.noop,
        skipStore: true,
        label: 'ADMIN.USER.ADMINISTRATOR',
        value: user.isAdmin,
        extraClassNames: 'mt8 mb10'
      }, {
        component: 'ClickableLinkFormComponent',
        name: 'resetPassword',
        includeIf: user.isPasswordSettable,
        onChange: _.noop,
        value: 'ADMIN.USER.RESET_PASSWORD',
        icon: 'fa-lock',
        linkAction: () => setEditingPassword(true),
        extraClassNames: 'mt8 ml5'
      }]
  }, passwordChangeForm];

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="editUserModal">
      <Modal.Header closeButton={true}>
        <h3>{t('ADMIN.USER.MODAL.TITLE.UPDATE')}</h3>
      </Modal.Header>
      <Modal.Body>
        <div data-testid="editUserModalBody">
          <SimpleSaveFormBuilder
            formDefinition={formDefinition}
            formSaveProcessing={formProcessing}
            submitFn={editingPassword ? updatePassword : updateUser}
            closeFn={editingPassword ? displayEditUser : onClose}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
