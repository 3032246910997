import _ from 'lodash';
import { CapsuleGroupCapsule } from '@/investigate/customCondition/customCondition.module';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { InvestigateStore } from '@/investigate/investigate.store';
import { PREVIEW_ID } from '@/trendData/trendData.module';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';

export type CapsuleGroupStore = ReturnType<typeof sqCapsuleGroupStore>['exports'];

export function sqCapsuleGroupStore(
  sqTrendCapsuleStore: TrendCapsuleStore,
  sqInvestigateStore: InvestigateStore
) {
  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.WORKSHEET,

    initialize() {
      this.state = this.immutable({
        capsules: [],
        isLoading: false,
        hasAtLeastOneCapsule: this.monkey(['capsules'],
          capsules => _.isArray(capsules) && capsules.length > 0
        )
      });
    },

    exports: {
      get isLoading() {
        return this.state.get('isLoading');
      },

      get capsules() {
        return this.state.get('capsules');
      },

      get hasAtLeastOneCapsule() {
        return this.state.get('hasAtLeastOneCapsule');
      }
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @return {Object} State for the store
     */
    dehydrate() {
      return _.omit(this.state.serialize(), ['isLoading']);
    },

    /**
     * Sets the references panel state
     *
     * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      CAPSULE_GROUP_SET_LOADING: 'setLoading',
      CAPSULE_GROUP_SET_CAPSULES: 'setCapsules',
      CAPSULE_GROUP_ADD_CAPSULE: 'addCapsule',

      // Handle all the events that can change the selection
      TREND_UNSELECT_ALL_CAPSULES: 'takeCapsulesFromSelection',
      TREND_REPLACE_CAPSULE_SELECTION: 'takeCapsulesFromSelection',
      TREND_ADD_CAPSULES: 'takeCapsulesFromSelection',
      TREND_SET_SELECTED: 'takeCapsulesFromSelection',
      TREND_SET_EDITING_CAPSULE_SET_ID: 'takeCapsulesFromSelection',
      TREND_REMOVE_ITEMS: 'takeCapsulesFromSelection'
    },

    /**
     * Handler for events that can change the selection
     */
    takeCapsulesFromSelection() {
      this.waitFor(['sqTrendCapsuleStore', 'sqInvestigateStore'], () => {
        if (!sqInvestigateStore.isCapsulePickingMode) {
          return;
        }

        const selections = sqTrendCapsuleStore.selectedCapsules as
          { id: string, startTime: number, endTime: number, isUncertain: boolean }[];

        const [otherCapsules, selectedCapsules] = _.partition(this.state.get('capsules') as CapsuleGroupCapsule[],
          capsule => _.isNil(capsule.id)
        );

        const newSelectedCapsules = _.chain(selections)
          // Preview capsules should not be copied even if they are selected
          .reject(
            s => sqTrendCapsuleStore.splitUniqueId(s.id).capsuleSetId === (sqTrendCapsuleStore.editingId || PREVIEW_ID))
          .map((selection) => {
            const split = sqTrendCapsuleStore.splitUniqueId(selection.id);
            return _.find(selectedCapsules, ['id', selection.id]) || {
              id: selection.id,
              startTime: selection.startTime,
              endTime: selection.endTime,
              propertiesSource: {
                ...selection,
                capsuleId: split.capsuleId,
                capsuleSetId: split.capsuleSetId
              }
            } as CapsuleGroupCapsule;
          })
          .value();

        this.setCapsules({ capsules: _.concat(newSelectedCapsules, otherCapsules) });
      });
    },

    setLoading() {
      this.state.set('isLoading', true);
    },

    /**
     * Adds the capsule to the capsule group
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.capsule - array of capsule objects
     * @param {String} payload.capsule.startTime - start date of capsule in ms
     * @param {String} payload.capsule.endTime - end date of capsule in ms
     * @param {Object[]} payload.capsule.properties - array of property objects
     * @param {Object} payload.capsules[].propertiesSource - key value mapping between the properties and their values
     */
    addCapsule(payload) {
      const capsule = _.pick(payload.capsule, ['id', 'startTime', 'endTime', 'properties', 'propertiesSource']);
      this.state.concat('capsules', [capsule]);
    },

    /**
     * Sets the capsules for the capsule group
     *
     * @param {Object} payload - Object container
     * @param {Object[]} payload.capsules - array of capsule objects
     * @param {String} payload.capsules[].startTime - start date of capsule in ms
     * @param {String} payload.capsules[].endTime - end date of capsule in ms
     * @param {Object[]} payload.capsules[].properties - array of property objects
     * @param {Object} payload.capsules[].propertiesSource - key value mapping between the properties and their values
     */
    setCapsules(payload) {
      this.state.set('capsules', _.map(payload.capsules,
        capsule => _.pick(capsule, ['id', 'startTime', 'endTime', 'properties', 'propertiesSource'])
      ));
      this.state.set('isLoading', false);
    }
  };

  return store;
}
