import React, { useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { AssetGroupStore } from '@/hybrid/assetGroupEditor/assetGroup.store';
import { EditableText } from '@/hybrid/core/EditableText.atom';
import { AssetGroupActions } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ButtonWithDropdown } from '@/hybrid/core/ButtonWithDropdown.atom';
import { Dropdown } from 'react-bootstrap';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { TrackService } from '@/track/track.service';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { AssetGroupCell } from '@/hybrid/assetGroupEditor/AssetGroupCell.atom';

const assetGroupEditorToolbarBindings = bindingsDefinition({
  debug: prop.optional<boolean>(),
  sqAssetGroupStore: injected<AssetGroupStore>(),
  sqAssetGroupActions: injected<AssetGroupActions>(),
  sqTrack: injected<TrackService>()
});

export const AssetGroupEditor: SeeqComponent<typeof assetGroupEditorToolbarBindings> = ({ debug = false }) => {
  const MAX_COLUMN_CHARACTERS = 20;
  const MAX_ASSET_CHARACTERS = 21;
  const {
    sqAssetGroupStore,
    sqAssetGroupActions,
    sqTrack
  } = useInjectedBindings(assetGroupEditorToolbarBindings);

  // DO NOT useFlux here or asset group name changes will trigger a re-render!
  const assets = useFluxPath(sqAssetGroupStore, () => sqAssetGroupStore.assets);
  const isLoading = useFluxPath(sqAssetGroupStore, () => sqAssetGroupStore.isLoading);
  const hasUnsavedChanges = useFluxPath(sqAssetGroupStore, () => sqAssetGroupStore.hasUnsavedChanges);
  const id = useFluxPath(sqAssetGroupStore, () => sqAssetGroupStore.id);

  const { t } = useTranslation();
  const [forceEditName, setForceEditName] = useState(undefined);

  const columns = sqAssetGroupActions.getColumns();

  const updateColumnName = (oldName, newName) => sqAssetGroupActions.updateColumnName(oldName, newName);

  const getNameAsIdString = (prefix, name) => `${prefix}${_.replace(name, ' ', '_')}`;

  const renderDropdownEntry = ({ iconClass, translationKey, action }, itemName) => (
    <Dropdown.Item key={`dropdown_${translationKey}`} onClick={() => action(itemName)}
      className="sq-force-text-gray">
      <Icon icon={iconClass} extraClassNames="pr10 fa-fw" type="inherit" />
      <span className="forceFont" data-testid={`moreActions_${translationKey}`}>{t(translationKey)}</span>
    </Dropdown.Item>
  );

  const assetActions = [{
    iconClass: 'fa-edit',
    translationKey: 'ASSET_GROUP_EDITOR.RENAME_ASSET',
    action: setForceEditName
  }, {
    iconClass: 'fa-times',
    translationKey: 'ASSET_GROUP_EDITOR.DELETE_ASSET',
    action: sqAssetGroupActions.removeAsset
  }];

  const attributeActions = [{
    iconClass: 'fa-edit',
    translationKey: 'ASSET_GROUP_EDITOR.RENAME_COLUMN',
    action: setForceEditName
  }, {
    iconClass: 'fa-times',
    translationKey: 'ASSET_GROUP_EDITOR.DELETE_COLUMN',
    action: (attributeName) => {
      sqTrack.doTrack('Asset Group Editor', 'Attribute Deleted');
      sqAssetGroupActions.removeAttribute(attributeName);
    }
  }];

  const headerRenderFunction = column => (
    <div data-testid="columnHeader" className="flexColumnContainer flexSpaceBetween flexAlignCenter width-150">
      <EditableText
        testId="columnName"
        allowEditing={true}
        value={column.name}
        forceEdit={column.name === forceEditName}
        inputClasses="flexFill"
        textClasses="textAlignLeft"
        maxDisplayChars={MAX_COLUMN_CHARACTERS}
        onUpdate={newText => updateColumnName(column.name, newText)} />

      <ButtonWithDropdown
        icon={<Icon icon="fc-more" extraClassNames="fa-fw width-20" type="gray" />}
        iconWrapperClasses="mb9 sq-icon-hover"
        testId="columnMoreActions"
        id={getNameAsIdString('dropdown-', column.name)}>
        {_.map(attributeActions, action => renderDropdownEntry(action, column.name))}
      </ButtonWithDropdown>
    </div>
  );

  const assetNameRenderFunction = item => (
    <div className="flexColumnContainer flexSpaceBetween flexAlignCenter width-150">
      <EditableText
        testId="rowName"
        value={item.name}
        forceEdit={item.name === forceEditName}
        allowEditing={true}
        inputClasses="flexFill"
        textClasses="textAlignLeft"
        maxDisplayChars={MAX_ASSET_CHARACTERS}
        onUpdate={newText => sqAssetGroupActions.updateAssetName(item, newText)} />

      <ButtonWithDropdown
        icon={<Icon icon="fc-more" extraClassNames="fa-fw width-20" type="gray" />}
        iconWrapperClasses="mb9 sq-icon-hover"
        testId="rowMoreActions"
        id={getNameAsIdString('dropdown-', item.name)}>
        {_.map(assetActions, action => renderDropdownEntry(action, item.name))}
      </ButtonWithDropdown>
    </div>
  );

  return (
    <>
      <div className="m10 height-18 flexColumnContainer flexJustifyEnd">
        {isLoading && !_.isEmpty(assets) &&
        <>
          <span className="mr5 text-muted text-italic">{t('ASSET_GROUP_EDITOR.LOADING_ASSET')}</span>
          <IconWithSpinner spinning={true} extraClassNames="text-muted sq-icon-text mr5" />
        </>
        }
        {!isLoading && (!_.isEmpty(assets) || id) && (
          hasUnsavedChanges
            ? <div className="flexColumnContainer flexAlignCenter">
              <Icon icon="fa-exclamation-triangle" type="warning" extraClassNames="" />
              <span className="ml5">{t('ASSET_GROUP_EDITOR.UNSAVED_CHANGES')}</span>
            </div>
            : <span className="mr5 text-muted text-italic">{t('ASSET_GROUP_EDITOR.ALL_CHANGES_SAVED')}</span>
        )}
      </div>
      <div className="flexRowContainer pl10 pr20 overflowAuto height-maximum">
        {!_.isEmpty(assets) &&
        <div>
          <table data-testid="assetGroupEditorTable"
            className="table table-bordered width-auto screenshotSizeToContent">
            <thead>
              <tr>
                {/*Asset Column*/}
                <th />
                {_.map(columns, column => (
                  <th key={`header_${column.name}`} className="text-center">
                    {headerRenderFunction(column)}
                  </th>))}
              </tr>
            </thead>
            <tbody>
              {_.map(assets, asset =>
                (<tr key={`row_${asset.name}`}>
                  <td key={`assetColumn_${asset.name}`}>{assetNameRenderFunction(asset)}</td>
                  {_.map(columns, column => (
                    <td key={`assetColumn_${asset.name}_${column.name}`}
                      className="min-width-150 max-width-200 text-center">
                      <AssetGroupCell
                        asset={asset}
                        columnName={column.name}
                        debug={debug} />
                    </td>)
                  )}
                </tr>)
              )}
            </tbody>
          </table>
        </div>
        }

        {_.isEmpty(assets) && (
          isLoading
            ? <div className="alert alert-info fs16 p25 flexSelfCenter">
              <strong>{t('ASSET_GROUP_EDITOR.LOADING_ASSET_GROUP')}</strong>
              <div className="mt20 fs24 text-center font-size-xlarge">
                <IconWithSpinner spinning={true} correctSpin={false} extraClassNames="fa-xlg" />
              </div>
            </div>
            : <div className="fs16 p25 flexSelfCenter flexRowContainer">
              <div className="flexCenter flexRowContainer mb20">
                <h2>{t('ASSET_GROUP_EDITOR.SELECT_ASSET')}</h2>
              </div>
              <div className="flexColumnContainer">
                <div className="flexRowContainer mr30 width-300">
                  <div className="ml20 mr20 flexCenter text-center">
                    <strong>{t('ASSET_GROUP_EDITOR.PROMPT_LEFT')}</strong>
                  </div>
                </div>
                <div className="flexFill" />
                <div className="flexRowContainer ml30 width-300">
                  <div className="ml20 mr20 flexCenter text-center">
                    <strong>{t('ASSET_GROUP_EDITOR.PROMPT_RIGHT')}</strong>
                  </div>
                </div>
              </div>

              <div className="flexColumnContainer">
                <div className="flexRowContainer mr30 width-300 flexCenter">
                  <img
                    data-testid="assetGroupInfoImgLeft"
                    src="/img/assetGroupInstructionsLeft.png"
                  />
                </div>

                <div className="flexRowContainer flexCenter flexFill">
                  <div className="circle sq-fairly-dark-gray p10 splashScreenLight">
                    <strong>{t('ASSET_GROUP_EDITOR.PROMPT_OR')}</strong>
                  </div>
                </div>

                <div className="flexRowContainer ml30 mt20 width-300 flexCenter">
                  <img
                    data-testid='assetGroupInfoImgRight'
                    src='/img/assetGroupInstructionsRight.png' />
                </div>
              </div>
            </div>
        )}
      </div>
    </>
  );
};
