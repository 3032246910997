import angular from 'angular';

const dependencies = [
  'Sq.Core',
  'Sq.TrendData',
  'Sq.Services.Modal'
];

/**
 * @module The Utilities module provides utilities like the color picker.
 */
angular.module('Sq.Utilities', dependencies);
