import _ from 'lodash';
import bind from 'class-autobind-decorator';
import { SocketService } from '@/services/socket.service';
import { SubscriptionsApi } from 'sdk/api/SubscriptionsApi';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { AgentsApi } from 'sdk/api/AgentsApi';
import { TrackService } from '../track/track.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';

const CONNECTION_ITEM_TYPES = ['signal', 'condition', 'scalar', 'asset', 'relationship', 'userGroup'];
const STATUS_MAP = {
  // Statuses that can be returned by the syncStatus field
  SYNC_UNKNOWN: { classes: 'fa-check sq-text-warning', tooltip: 'AGENTS.SYNC_UNKNOWN' },
  SYNC_INITIALIZING: { classes: 'fa-spinner fa-spin', tooltip: 'AGENTS.SYNC_INITIALIZING' },
  SYNC_IN_PROGRESS: { classes: 'fa-refresh fa-spin', tooltip: 'AGENTS.SYNC_IN_PROGRESS' },
  SYNC_ARCHIVING_DELETED_ITEMS: { classes: 'fa-refresh fa-spin', tooltip: 'AGENTS.SYNC_ARCHIVING_DELETED_ITEMS' },
  SYNC_COMPLETE: { classes: 'fa-check sq-text-success', tooltip: 'AGENTS.SYNC_COMPLETE' },
  SYNC_SUCCESS: { classes: 'fa-check sq-text-success', tooltip: 'AGENTS.SYNC_SUCCESS' },
  SYNC_FAILED: { classes: 'fa-warning sq-text-warning', tooltip: 'AGENTS.SYNC_FAILED' },

  // Statuses that can be returned by the status field
  DISCONNECTED: { classes: 'fa-warning sq-text-warning', tooltip: 'AGENTS.SYNC_DISCONNECTED' },
  CONNECTING: { classes: 'fa-warning sq-text-warning', tooltip: 'AGENTS.SYNC_CONNECTING' },
  OTHER: { classes: 'fa-warning sq-text-warning', tooltip: 'AGENTS.SYNC_OTHER' }
};

@bind
export class AgentsStatusController {
  private readonly unsubscribe: () => void = _.noop;
  isDataReady = false;
  agents = [];
  connections = [];
  changeTab = this['changeTab'];
  activeTabIndex = this['activeTabIndex'];
  connectionsTabIndex = this['connectionsTabIndex'];
  agentsTabIndex = this['agentsTabIndex'];

  constructor(
    public $scope: ng.IScope,
    public $interval: ng.IIntervalService,
    public sqSocket: SocketService,
    public sqTrack: TrackService,
    public sqSubscriptionsApi: SubscriptionsApi,
    public sqWorkbenchStore: WorkbenchStore,
    public sqWorksheetActions: WorksheetActions,
    public sqAgentsApi: AgentsApi) {

    this.connectionsTabIndex = sqWorkbenchStore.getTabset('dataSources').tabs.indexOf('connections');
    this.agentsTabIndex = sqWorkbenchStore.getTabset('dataSources').tabs.indexOf('agents');
    this.changeTab = tab => sqWorksheetActions.tabsetChangeTab('dataSources', tab);

    $scope.$listenTo(sqWorkbenchStore, this.syncWorkbenchStore);
    sqAgentsApi.getAgentStatus().then(({ data: agents }) => this.setStatus({ agents }));
    this.unsubscribe = sqSocket.subscribe({
      channelId: [SeeqNames.Channels.AgentsStatus],
      onMessage: this.setStatus
    });

    // This is a stop-gap fix requested by @Shamus. This information is requested by Sales and we'd like to have a
    // way to get this information "live" rather than from the data consumption logs.
    // We only want to track this Event once, and we don't really care about 100% accuracy on synced items so we
    // decided to send one event, 1 minute after instantiation.
    // NOTE: all System Tests will timeout if $timout is used here
    $interval(() => sqTrack.doTrack('Workbench', 'Connections', JSON.stringify(this.connections)), 60000, 1);
  }

  /**
   * Angular component lifecycle hook called before the component is destroyed
   */
  $onDestroy() {
    this.unsubscribe();
  }

  /**
   * Syncs the sqWorkbenchStore with the view-model
   */
  syncWorkbenchStore() {
    this.activeTabIndex = this.sqWorkbenchStore.getTabset('dataSources').activeTabIndex;
  }

  /**
   * Sets the agent and connections status
   *
   * @param {object} agentsStatus - The status object published by the API
   */
  setStatus(agentsStatus) {
    this.isDataReady = true;
    this.agents = agentsStatus.agents;
    this.connections = _.chain(this.agents)
      .flatMap('connections')
      .filter({ status: 'CONNECTED' })
      .map(connection => _.assign(connection,
        STATUS_MAP[connection.syncStatus] || STATUS_MAP[connection.status] || STATUS_MAP.OTHER,
        {
          uniqueId: [connection.id, connection.agentId, connection.datasourceClass].join('-'),
          syncSummary: this.getSyncSummary(connection),
          syncProgressSummary: this.getProgressSummary(connection),
          syncProgressDetails: this.getConnectionProgress(connection)
        }))
      .value();

  }

  /**
   * Returns the summary of what has been indexed by metadata sync for the connection.
   *
   * @param {object} connection - The connection (e.g. Example Data connection from jvm-link agent)
   * @return {object} Translation key and args that summarizes what the metadata sync has completed
   */
  getSyncSummary(connection) {
    const syncTotalCount = this.sumSyncProgress(connection.syncProgress, { isTotal: true });
    return _.cond([
      [
        _.matches({ syncStatus: 'SYNC_INITIALIZING' }),
        _.constant({ key: 'AGENTS.SYNC_INITIALIZING' })
      ], [
        _.matches({ syncStatus: 'SYNC_ARCHIVING_DELETED_ITEMS' }),
        _.constant({ key: 'AGENTS.INDEX_SUMMARY_ARCHIVING' })
      ], [
        _.stubTrue,
        _.constant({ key: 'AGENTS.INDEX_SUMMARY', args: { count: syncTotalCount } })
      ]
    ])(connection);
  }

  /**
   * Returns the summary of metadata sync progress for the connection.
   *
   * @param {object} connection - The connection (e.g. Example Data connection from jvm-link agent)
   * @return {object} Translation key and args that summarizes the progress of the metadata sync
   */
  getProgressSummary(connection) {
    if (connection.syncStatus === 'SYNC_IN_PROGRESS') {
      return {
        key: 'AGENTS.INDEX_PROGRESS',
        args: { count: this.sumSyncProgress(connection.syncProgress, { isTotal: false }) }
      };
    }
  }

  /**
   * Returns an array of strings that give details on how many of each type of item have been synced.
   *
   * @param {object} connection - The connection (e.g. Example Data connection from jvm-link agent)
   * @return {object[]} Translations that give current progress and expected total for each type of item
   */
  getConnectionProgress(connection) {
    const isSyncInProgress = _.includes(['SYNC_IN_PROGRESS', 'SYNC_ARCHIVING_DELETED_ITEMS'], connection.syncStatus);
    return _.transform(CONNECTION_ITEM_TYPES, (progress, syncType) => {
      if (connection.syncProgress) {
        const progressCount = connection.syncProgress[`${syncType}Progress`];
        const totalCount = connection.syncProgress[`${syncType}Count`];
        if (progressCount > 0 || totalCount > 0) {
          progress.push({
            indexSummary: { key: 'AGENTS.INDEX_SUMMARY', args: { count: totalCount, syncType } },
            indexProgress: isSyncInProgress ?
              { key: 'AGENTS.INDEX_PROGRESS', args: { count: progressCount, syncType } } : {}
          });
        }
      }
    }, []);
  }

  /**
   * Helper method that sums up the progress for all item types
   *
   * @param {object} syncProgress - Progress and total count for each type of item
   * @param {object} [options] - Additional options
   * @param {boolean} [options.isTotal] - If true, then sums the total counts for the sync, else the current progress
   */
  sumSyncProgress(syncProgress, options = { isTotal: false }) {
    const properties = _.map(CONNECTION_ITEM_TYPES, syncType => `${syncType}${options.isTotal ? 'Count' : 'Progress'}`);
    return _.chain(syncProgress).pick(properties).values().sum().value();
  }
}
