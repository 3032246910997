import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Card } from 'react-bootstrap';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { SearchTitle } from '@/hybrid/workbooks/SearchTitle.organism';
import CancelAndExecute from '@/hybrid/core/CancelAndExecute.molecule';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { InputWithFormatter } from '@/hybrid/core/InputWithFormatter.molecule';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { NotificationsService } from '@/services/notifications.service';
import { ScatterConditionActions } from '@/investigate/scatterCondition/scatterCondition.actions';
import { ScatterConditionStore } from '@/investigate/scatterCondition/scatterCondition.store';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';
import { ScatterPlotStore } from '@/scatterPlot/scatterPlot.store';
import { DurationStore } from '@/trendData/duration.store';
import { NumberHelperService } from '@/core/numberHelper.service';
import { TrackService } from '@/track/track.service';
import { TREND_TOOLS, VALUE_SEARCH_DURATIONS } from '@/investigate/investigate.module';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import ValueWithUnits from '@/hybrid/trend/ValueWithUnits.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.module';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { Icon } from '@/hybrid/core/Icon.atom';

const scatterConditionToolCardBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  sqNotifications: injected<NotificationsService>(),
  sqScatterConditionActions: injected<ScatterConditionActions>(),
  sqScatterConditionStore: injected<ScatterConditionStore>(),
  sqToolRunner: injected<ToolRunnerService>(),
  sqScatterPlotActions: injected<ScatterPlotActions>(),
  sqScatterPlotStore: injected<ScatterPlotStore>(),
  sqDurationStore: injected<DurationStore>(),
  sqWorksheetActions: injected<WorksheetActions>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqNumberHelper: injected<NumberHelperService>(),
  sqTrack: injected<TrackService>()
});

/**
 * This is the tool card for the tool which creates a condition from a region selected on the
 * scatter plot, or values input by the user. The scatter plot selected region will change based on values
 * input to this tool, and the values in the input boxes of the tool will change based on updates to the selected
 * region.
 */
export const ScatterConditionToolCard: SeeqComponent<typeof scatterConditionToolCardBindings> = () => {
  const {
    sqInvestigateActions,
    sqNotifications,
    sqScatterConditionActions,
    sqScatterConditionStore,
    sqScatterPlotActions,
    sqScatterPlotStore,
    sqDurationStore,
    sqWorksheetActions,
    sqWorksheetStore,
    sqNumberHelper,
    sqTrack
  } = useInjectedBindings(scatterConditionToolCardBindings);
  const { t } = useTranslation();

  const toolName = TREND_TOOLS.SCATTER_CONDITION;
  const worksheetView = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.view.key);

  const { id, name, isBounding, isCleansing, minDuration, mergeDuration } = useFlux(sqScatterConditionStore);
  const isNew = !id;
  const { xSeries, ySeries, selectedRegion } = useFlux(sqScatterPlotStore);

  const displayRange = useFluxPath(sqDurationStore, () => sqDurationStore.displayRange);

  const setSearchName = _.partial(sqInvestigateActions.setSearchName, TREND_TOOLS.SCATTER_CONDITION);

  const setScatterPlotView = () => sqWorksheetActions.setView(WORKSHEET_VIEW.SCATTER_PLOT);

  const [color, setColor] = useState('');

  /**
   * Handle changes to the x-y region. If the new typed value doesn't change the numerical value,
   * or if it could only be the (incomplete) start of a different numerical value, then don't
   * update the numerical value. Otherwise, update the numerical value and reformat the string value.
   *
   * @param event - the React event containing the changed value
   * @param field - either 'xMin', 'xMax', 'yMin', or 'yMax'
   */
  const handleInputChange = (newValue, field) => {
    const parsedValue = parseFloat(newValue);
    sqScatterPlotActions.setSelectedRegion({ ...selectedRegion, [field]: _.isNaN(parsedValue) ? null : parsedValue });
  };

  const isExecuteDisabled = () => {
    return !isRegionValid(selectedRegion)
      || missingSeries()
      || !sqInvestigateActions.hasValidName(name);
  };

  const isRegionValid = (region) => {
    return !_.isEmpty(region)
      && _.isNumber(region.xMin)
      && _.isNumber(region.xMax)
      && _.isNumber(region.yMin)
      && _.isNumber(region.yMax)
      && region.xMin < region.xMax
      && region.yMin < region.yMax;
  };

  const missingSeries = () => _.isEmpty(xSeries) || _.isEmpty(ySeries);

  /**
   * Generates the condition with properties and color by it on the scatter plot
   */
  const execute = () => {
    const { formula, formulaParameters } = sqScatterConditionStore.getFormulaAndParameters(selectedRegion, xSeries,
      ySeries, isBounding, displayRange, isCleansing, minDuration, mergeDuration);
    return sqScatterConditionActions.generate(name, formula, formulaParameters, color)
      .then((realId) => {
        sqTrack.doTrack('Workbench_Tool', toolName, 'completed');
        sqScatterPlotActions.autoAddNewConditionForColoring(realId, isNew);
        sqInvestigateActions.close();
      })
      .catch((e) => {
        sqTrack.doTrack('Workbench_Tool', toolName, 'error');
        sqNotifications.apiError(e);
      });
  };

  return (
    <Card>
      <form className="panel panel-primary" data-testid="scatterConditionForm">
        <Card.Header className="panel-heading">
          <SearchTitle
            name={name}
            setSearchName={setSearchName}
            defaultName="INVESTIGATE_TOOLS.SCATTER_CONDITION.DEFAULT_NAME"
            onColorChange={newColor => setColor(newColor)}
            id={id}
            searchIconClass="fc-scatterplot-select" />
        </Card.Header>

        <Card.Body className="form panel-body">
          <span className="mb12" data-testid="scatterConditionHowTo">{t(
            'INVESTIGATE_TOOLS.SCATTER_CONDITION.HOW_TO')}</span>
          <table className="table table-fixed table-striped mt10 mb10" data-testid="scatterConditionTable">
            <tbody>
              <tr>
                <th>{t('INVESTIGATE_TOOLS.SCATTER_CONDITION.AXIS_COLUMN')}</th>
                <th>{t('INVESTIGATE_TOOLS.SCATTER_CONDITION.MIN_COLUMN')}</th>
                <th>{t('INVESTIGATE_TOOLS.SCATTER_CONDITION.MAX_COLUMN')}</th>
              </tr>
              <tr>
                <th className="verticalAlignMiddle">{t('INVESTIGATE_TOOLS.SCATTER_CONDITION.X_AXIS')}</th>
                <td>
                  <InputWithFormatter
                    onChange={event => handleInputChange((event.target as HTMLInputElement).value, 'xMin')}
                    value={selectedRegion.xMin}
                    formatter={sqNumberHelper.formatNumber}
                    inputType="number"
                    inputClassName="input input-number-no-spin"
                    testId="xMinInput"
                    maxLength={7}
                  />
                </td>
                <td>
                  <InputWithFormatter
                    onChange={event => handleInputChange((event.target as HTMLInputElement).value, 'xMax')}
                    value={selectedRegion.xMax}
                    formatter={sqNumberHelper.formatNumber}
                    inputType="number"
                    inputClassName="input input-number-no-spin"
                    testId="xMaxInput"
                    maxLength={7}
                  />
                </td>
              </tr>
              <tr>
                <th className="verticalAlignMiddle">{t('INVESTIGATE_TOOLS.SCATTER_CONDITION.Y_AXIS')}</th>
                <td>
                  <InputWithFormatter
                    onChange={event => handleInputChange((event.target as HTMLInputElement).value, 'yMin')}
                    value={selectedRegion.yMin}
                    formatter={sqNumberHelper.formatNumber}
                    inputType="number"
                    inputClassName="input input-number-no-spin"
                    testId="yMinInput"
                    maxLength={7}
                  />
                </td>
                <td>
                  <InputWithFormatter
                    onChange={event => handleInputChange((event.target as HTMLInputElement).value, 'yMax')}
                    value={selectedRegion.yMax}
                    formatter={sqNumberHelper.formatNumber}
                    inputType="number"
                    inputClassName="input input-number-no-spin"
                    testId="yMaxInput"
                    maxLength={7}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <div className="flexRowContainer ml20">
            <Checkbox
              label="INVESTIGATE_TOOLS.SCATTER_CONDITION.BOUNDING_CHECKBOX"
              id="isBoundingCheckbox"
              onChange={() => sqScatterConditionActions.setBounding(!isBounding)}
              isChecked={isBounding} />
          </div>

          <div className="flexRowContainer ml20">
            <Checkbox
              label="INVESTIGATE_TOOLS.SCATTER_CONDITION.CLEANSING_CHECKBOX"
              id="isCleansingCheckbox"
              onChange={() => sqScatterConditionActions.setCleansing(!isCleansing)}
              isChecked={isCleansing} />
          </div>

          {isCleansing &&
          <div>
            <HoverTooltip text="INVESTIGATE_TOOLS.SCATTER_CONDITION.IGNORE_SHORT_CAPSULE_DURATION_TOOLTIP">
              <div className="flexRowContainer ml70 mb10">
                {t('INVESTIGATE_TOOLS.SCATTER_CONDITION.IGNORE_SHORT_CAPSULE_DURATION')}
                <ValueWithUnits
                  min={0}
                  propName={VALUE_SEARCH_DURATIONS.MIN}
                  defaultValue={minDuration}
                  required={isCleansing}
                  onChange={sqScatterConditionActions.setMinDuration} />
              </div>
            </HoverTooltip>

            <HoverTooltip text="INVESTIGATE_TOOLS.SCATTER_CONDITION.IGNORE_SHORT_CAPSULE_GAPS_TOOLTIP">
              <div className="flexRowContainer ml70 mb10">
                {t('INVESTIGATE_TOOLS.SCATTER_CONDITION.IGNORE_SHORT_CAPSULE_GAPS')}
                <ValueWithUnits
                  min={0}
                  propName={VALUE_SEARCH_DURATIONS.MERGE}
                  defaultValue={mergeDuration}
                  required={isCleansing}
                  onChange={sqScatterConditionActions.setMergeDuration} />
              </div>
            </HoverTooltip>
          </div>}

          {worksheetView !== WORKSHEET_VIEW.SCATTER_PLOT &&
          <div className="flexColumnContainer pb15 flexCenter"
            onClick={() => setScatterPlotView()}>
            <Icon
              icon="fc-scatterplot"
              extraClassNames="sq-text-primary p5"
            />
            <a href="#">{t('INVESTIGATE_TOOLS.SCATTER_CONDITION.SWITCH_VIEW')}</a>
          </div>}

          <CancelAndExecute
            cancelAction={sqInvestigateActions.close}
            submitAction={execute}
            toolId={toolName}
            toolName={toolName}
            btnDisabled={isExecuteDisabled()} />
        </Card.Body>
      </form>
    </Card>
  );
};

export const sqScatterConditionToolCard = angularComponent(scatterConditionToolCardBindings, ScatterConditionToolCard);
