import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { TrackService } from '@/track/track.service';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';

export type ToolStore = {
  advancedParametersCollapsed: boolean;
};

const advancedParametersBindings = bindingsDefinition({
  toolId: prop<string>(),
  toolName: prop<string>(),
  alternateName: prop.optional<string>(),
  toolStore: prop<ToolStore>(),
  sqTrack: injected<TrackService>(),
  sqInvestigateActions: injected<InvestigateActions>()
});

export const AdvancedParameters: SeeqComponent<typeof advancedParametersBindings> = (props) => {
  const { toolName, toolId, toolStore, alternateName = undefined } = props;
  const { sqTrack, sqInvestigateActions } = useInjectedBindings(advancedParametersBindings);
  const { t } = useTranslation();

  const collapsed = useFluxPath(toolStore, () => toolStore['advancedParametersCollapsed']);

  const trackAndClick = () => {
    sqTrack.doTrack(toolName, 'Advanced Toggle', `Advanced ${collapsed ? 'expanded' : 'collapsed'}`);
    sqInvestigateActions.setAdvancedParametersCollapsedState(toolId, !collapsed);
  };

  return (
    <>
      <div className="cursorPointer pt5 pb5 sq-text-primary" onClick={trackAndClick}>
        <Icon icon={collapsed ? 'fa-plus' : 'fa-minus'} testId="advancedParametersIcon" />
        <a className="ml10 link-no-underline">{t(alternateName ? alternateName : 'ADVANCED')}</a>
      </div>
      {!collapsed &&
      <>
        <div className="divider" />
        {props.children}
      </>}
    </>
  );
};
