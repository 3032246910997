import angular from 'angular';
import _ from 'lodash';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';

angular.module('Sq.TrendData').factory('sqAncillariesHelper', sqAncillariesHelper);

export type AncillariesHelperService = ReturnType<typeof sqAncillariesHelper>;

function sqAncillariesHelper(sqTrendSeriesStore: TrendSeriesStore) {
  return {
    displayedAncillaries
  };

  /**
   * Gets all the ancillaries that should be displayed for the item. An ancillary should be displayed if any of it's
   * item ids is listed in item.displayedAncillaryItemIds
   *
   * @param {string} itemId - the id of the item with ancillaries
   * @returns {Object[]} ancillaries that are displayed on the item
   */
  function displayedAncillaries(itemId) {
    const item = sqTrendSeriesStore.findItem(itemId);
    const displayedAncillaryItemIds = _.get(item, 'displayedAncillaryItemIds', []);
    const allAncillaries = _.get(item, 'allAncillaries', []);
    return _.filter(allAncillaries, (ancillary) => {
      const ids = _.map(ancillary.items, 'id');
      return _.some(_.intersection(ids, displayedAncillaryItemIds));
    });
  }
}
