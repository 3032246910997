import _ from 'lodash';
import { BaseToolStoreService } from '@/investigate/baseToolStore.service';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { SMOOTHING_ALGORITHMS, SMOOTHING_DEFAULTS } from './signalSmoothing.module';

export type SignalSmoothingStore = ReturnType<typeof sqSignalSmoothingStore>['exports'];

export function sqSignalSmoothingStore(
  sqBaseToolStore: BaseToolStoreService) {
  const store = {
    initialize() {
      this.state = this.immutable(_.assign({}, sqBaseToolStore.COMMON_PROPS, {
        algorithmSelectedValue: SMOOTHING_ALGORITHMS.SEEQ_AGILE.VALUE,
        samplingRate: { value: undefined, units: undefined },
        smoothingWindow: { value: undefined, units: undefined },
        cutoff: { value: undefined, units: undefined },
        polynomialFactor: SMOOTHING_DEFAULTS.POLYNOMIAL_FACTOR,
        isSamplingRateAuto: true,
        isCutoffAuto: true,
        isTau: true,
        tau: SMOOTHING_DEFAULTS.TAU,
        alpha: SMOOTHING_DEFAULTS.ALPHA,
        isMigratedLowPassFilter: false
      }));
    },

    exports: {
      get algorithmSelectedValue() {
        return this.state.get('algorithmSelectedValue');
      },

      get samplingRate() {
        return this.state.get('samplingRate');
      },

      get smoothingWindow() {
        return this.state.get('smoothingWindow');
      },

      get cutoff() {
        return this.state.get('cutoff');
      },

      get polynomialFactor() {
        return this.state.get('polynomialFactor');
      },

      get isSamplingRateAuto() {
        return this.state.get('isSamplingRateAuto');
      },

      get isCutoffAuto() {
        return this.state.get('isCutoffAuto');
      },

      get isTau() {
        return this.state.get('isTau');
      },

      get tau() {
        return this.state.get('tau');
      },

      get alpha() {
        return this.state.get('alpha');
      },

      get isMigratedLowPassFilter() {
        return this.state.get('isMigratedLowPassFilter');
      }
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @return {Object} State for the store
     */
    dehydrate() {
      return this.state.serialize();
    },

    /**
     * Sets the references panel state
     *
     * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      SIGNAL_SMOOTHING_SET_ALGORITHM_SELECTED_VALUE: 'setAlgorithmSelectedValue',
      SIGNAL_SMOOTHING_SET_SAMPLING_RATE: 'setSamplingRate',
      SIGNAL_SMOOTHING_SET_SMOOTHING_WINDOW: 'setSmoothingWindow',
      SIGNAL_SMOOTHING_SET_CUTOFF: 'setCutoff',
      SIGNAL_SMOOTHING_SET_POLYNOMIAL_FACTOR: 'setPolynomialFactor',
      SIGNAL_SMOOTHING_SET_IS_SAMPLE_RATE_AUTO: 'setIsSamplingRateAuto',
      SIGNAL_SMOOTHING_SET_IS_CUTOFF_AUTO: 'setIsCutoffAuto',
      SIGNAL_SMOOTHING_SET_IS_TAU: 'setIsTau',
      SIGNAL_SMOOTHING_SET_TAU: 'setTau',
      SIGNAL_SMOOTHING_SET_ALPHA: 'setAlpha',
      SIGNAL_SMOOTHING_SET_IS_MIGRATED_LOW_PASS_FILTER: 'setIsMigratedLowPassFilter'
    },

    setAlgorithmSelectedValue({ algorithmSelectedValue }) {
      this.state.set('algorithmSelectedValue', algorithmSelectedValue);
    },

    setSamplingRate({ samplingRate }) {
      this.state.set('samplingRate', samplingRate);
    },

    setSmoothingWindow({ smoothingWindow }) {
      this.state.set('smoothingWindow', smoothingWindow);
    },

    setCutoff({ cutoff }) {
      this.state.set('cutoff', cutoff);
    },

    setPolynomialFactor({ polynomialFactor }) {
      this.state.set('polynomialFactor', polynomialFactor);
    },

    setIsSamplingRateAuto({ isSamplingRateAuto }) {
      this.state.set('isSamplingRateAuto', isSamplingRateAuto);
    },

    setIsCutoffAuto({ isCutoffAuto }) {
      this.state.set('isCutoffAuto', isCutoffAuto);
    },

    setIsTau({ isTau }) {
      this.state.set('isTau', isTau);
    },

    setTau({ tau }) {
      this.state.set('tau', tau);
    },

    setAlpha({ alpha }) {
      this.state.set('alpha', alpha);
    },

    setIsMigratedLowPassFilter({ isMigratedLowPassFilter }) {
      this.state.set('isMigratedLowPassFilter', isMigratedLowPassFilter);
    }
  };

  return sqBaseToolStore.extend(store, TREND_TOOLS.SIGNAL_SMOOTHING,
    { inputSignal: { predicate: ['name', 'inputSignal'] } });
}
