import React, { useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { APP_STATE, HOME_SCREEN_TABS } from '@/main/app.constants';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { HomeScreenItemActions } from '@/hybrid/homescreen/HomeScreenItemActions.atom';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { SortIcon } from '@/hybrid/core/SortIcon.atom';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { AuthorizationService } from '@/services/authorization.service';
import { HomeScreenStore } from '@/hybrid/homescreen/homescreen.store';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { HOME_SCREEN_SORT, HOME_SCREEN_TABLE_TYPE } from '@/hybrid/homescreen/homescreen.module';
import { CELL_TYPES, Table, TableColumn } from '@/hybrid/core/Table.atom';
import Pagination from '@/hybrid/core/Pagination.organism';
import { useLogger } from '@/hybrid/core/useLogger.hook';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { HomeScreenItemIconCell, NameCell } from '@/hybrid/homescreen/CellRender.atom';

export type HomeScreenItemType = { id: string, name: string, createdAt: string, updatedAt: string, owner: { name: string } };

const homeScreenTableBindings = bindingsDefinition({
  loadTable: prop<() => void>(),
  items: prop<HomeScreenItemType[]>(),
  tableType: prop.optional<string>(),
  isLoading: prop<boolean>(),
  showBreadcrumbs: prop<boolean>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
  sqWorkbenchActions: injected<WorkbenchActions>(),
  sqWorkbenchStore: injected<WorkbenchStore>(),
  sqHomeScreenStore: injected<HomeScreenStore>(),
  sqPendingRequests: injected<PendingRequestsService>(),
  sqAuthorization: injected<AuthorizationService>(),
  $state: injected<ng.ui.IStateService>()
});

export const HomeScreenTable: SeeqComponent<typeof homeScreenTableBindings> = (props) => {
  const {
    sqHomeScreenStore,
    sqHomeScreenActions,
    sqHomeScreenUtilities,
    sqAuthorization,
    sqWorkbenchActions,
    sqWorkbenchStore,
    sqPendingRequests,
    $state
  } = useInjectedBindings(homeScreenTableBindings);
  const { isLoading, items, showBreadcrumbs, tableType, loadTable } = props;
  const { t } = useTranslation();
  const logger = useLogger();
  const sortConfig: any = useFluxPath(sqHomeScreenStore,
    () => sqHomeScreenStore.getSortForTable(tableType || HOME_SCREEN_TABLE_TYPE.TAB));

  const sortField = sortConfig.sortProperty;
  const sortAsc = sortConfig.sortAsc;
  const supportSorting = tableType !== HOME_SCREEN_TABLE_TYPE.RECENT;
  const openingItemId = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.openingItemId);
  const isDisplayingTrash = useFluxPath(sqWorkbenchStore,
    () => sqHomeScreenStore.currentTab === HOME_SCREEN_TABS.TRASH);
  const currentFolderId = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.currentFolderId);
  const resetLoadingId = () => sqWorkbenchActions.setOpeningAndLoadingItemId(null);
  const pageNumber = useFluxPath(sqHomeScreenStore,
    () => sqHomeScreenStore.getPageNumberForTable(tableType || HOME_SCREEN_TABLE_TYPE.TAB));
  const isTableLoading = useFluxPath(sqHomeScreenStore,
    () => sqHomeScreenStore.isTableLoading(tableType || HOME_SCREEN_TABLE_TYPE.TAB));

  useEffect(() => {
    if (!openingItemId && !isTableLoading) {
      loadTable();
    }
  }, [pageNumber]);

  const open = (homeScreenItem, event?) => {
    sqPendingRequests.cancelAll();
    sqWorkbenchActions.setOpeningAndLoadingItemId(homeScreenItem.id);

    if (sqHomeScreenUtilities.isFolder(homeScreenItem)) {
      sqHomeScreenActions.setPageNumber(1, tableType);
      const folderId = homeScreenItem.id;
      if (folderId === null) {
        return $state.go(APP_STATE.WORKBOOKS)
          .finally(resetLoadingId);
      }

      if (folderId !== $state.params.currentFolderId) {
        return $state.go(APP_STATE.FOLDER_EXPANDED, { currentFolderId: folderId }, { reload: true })
          .finally(resetLoadingId);
      }

      logger.warn('Unhandled folder navigation for folder ID ' + folderId);
      return resetLoadingId();
    } else if (sqHomeScreenUtilities.isProject(homeScreenItem)) {
      return sqHomeScreenUtilities.openProject(homeScreenItem.id)
        .finally(resetLoadingId);
    } else {
      return sqHomeScreenUtilities.getWorkbook(homeScreenItem.id,
        { includeArchivedWorksheets: homeScreenItem.isArchived })
        .then((workbook) => {
          const worksheetId = _.get(workbook, 'worksheets[0].worksheetId');
          const canModify = sqAuthorization.canModifyWorkbook(workbook);
          $state.goNewTab(
            canModify ? APP_STATE.WORKSHEET : APP_STATE.VIEW_WORKSHEET,
            { workbookId: homeScreenItem.id, worksheetId, currentFolderId: $state.params.currentFolderId },
            event
          );
        }).finally(resetLoadingId);
    }
  };

  const renderActions = workbenchItem => (
    !workbenchItem.isUnmodifiable &&
    <div className="positionRelative nowrap" data-testid={`homeScreenActions_${workbenchItem.id}`}>
      <div className="homeScreenActions flexColumnContainer positionAbsolute">
        <HomeScreenItemActions
          item={workbenchItem}
          currentFolderId={currentFolderId}
          openAction={open}
        />
      </div>
    </div>
  );

  const renderHeader = (title, sortProperty) => {
    return (
      <div
        onClick={() =>
          supportSorting
            ? sqHomeScreenActions.getSortedTableContents(sortProperty, tableType || HOME_SCREEN_TABLE_TYPE.TAB)
            : _.noop()}
        className={classNames('th', { cursorPointer: supportSorting }, { 'sq-text-info': sortField === sortProperty })}>
        {t(title)}
        {supportSorting && <SortIcon sortProperty={sortProperty} sortBy={sortField} sortAsc={sortAsc} />}
      </div>
    );
  };

  const renderTableLoadingIndicator = (
    <div className="flexColumnContainer flexCenter pt50 pb50">
      <i className="fa fa-spinner fa-pulse fa-5x sq-text-primary" />
    </div>);

  const renderRedactedOwner = owner => (
    <HoverTooltip placement="top" delay={500} text="HOME_SCREEN.REDACTED_OWNER_TOOLTIP">
      <div>
        <span className="fa fa-exclamation-triangle text-warning mr5" />
        {owner?.name}
      </div>
    </HoverTooltip>
  );

  const renderNonRedactedOwner = owner => (
    <div>
      {owner?.name}
    </div>
  );

  const renderOwner = (owner) => {
    return owner?.isRedacted ? renderRedactedOwner(owner) : renderNonRedactedOwner(owner);
  };

  const onRowClick = (row, e) => {
    if (isDisplayingTrash && sqHomeScreenUtilities.isFolder(row)) {
      return;
    }
    open(row, e);
  };

  const columns: TableColumn[] = [{
    accessor: 'id',
    header: '',
    cellRenderFunction: (item: any) => <HomeScreenItemIconCell item={item} spinning={item.id === openingItemId} />,
    cellStyle: { width: 40 }
  }, {
    accessor: 'name',
    headerRenderFunction: () => renderHeader('WORKBOOKS_SORT.NAME', HOME_SCREEN_SORT.NAME),
    cellRenderFunction: item => <NameCell item={item} showBreadcrumbs={showBreadcrumbs}
      extraClassNames={(!isDisplayingTrash || !sqHomeScreenUtilities.isFolder(item)) && 'cursorPointer'} />,
    cellStyle: { maxWidth: 150, minWidth: 120, wordWrap: 'break-word' }
  }, {
    accessor: 'owner.name',
    headerRenderFunction: () => renderHeader('WORKBOOKS_SORT.OWNER', HOME_SCREEN_SORT.OWNER),
    cellRenderFunction: (row: any) => renderOwner(row.owner)
  }, {
    accessor: 'createdAt',
    headerRenderFunction: () => renderHeader('WORKBOOKS_SORT.CREATED_AT', HOME_SCREEN_SORT.CREATED_AT),
    cellType: CELL_TYPES.DATE_TIME,
    cellStyle: { maxWidth: 100, minWidth: 70 }
  }, {
    accessor: 'updatedAt',
    headerRenderFunction: () => renderHeader('WORKBOOKS_SORT.UPDATED_AT', HOME_SCREEN_SORT.UPDATED_AT),
    cellType: CELL_TYPES.DATE_TIME,
    cellStyle: { maxWidth: 100, minWidth: 70 }
  }, {
    accessor: 'unused',
    header: '',
    cellRenderFunction: renderActions,
    cellStyle: { maxWidth: 100, minWidth: 100, padding: 0 }
  }];

  const renderNoItems = isLoading ||
  (_.includes([HOME_SCREEN_TABLE_TYPE.PINNED, HOME_SCREEN_TABLE_TYPE.RECENT], tableType) && _.isEmpty(items))
    ? null
    : <div className="flexRowContainer flexCenter homeScreenNoContent">{t('HOME_SCREEN.NO_TABLE_CONTENT')}</div>;

  const renderTable = (
    <div className="flexRowContainer min-height-200 height-maximum">
      <div>
        <Table
          columns={columns}
          items={items}
          tableClass="homeScreenTable"
          onRowClickCallback={onRowClick} />
      </div>
      <Pagination
        store={sqHomeScreenStore}
        actions={sqHomeScreenActions}
        tableType={tableType}
        loadTable={loadTable} />
    </div>
  );

  if (isLoading) {
    return renderTableLoadingIndicator;
  } else if (items) {
    return _.isEmpty(items) ? renderNoItems : renderTable;
  } else {
    return null;
  }
};
