import _ from 'lodash';
import angular from 'angular';
import HttpCodes from 'http-status-codes';
import { LoggerService } from '@/services/logger.service';
import { UtilitiesService } from '@/services/utilities.service';
import { RedactionService } from '@/services/redaction.service';
import { AsyncResponseMessage, AsyncResponsesService } from '@/services/asyncResponses.service';
import { APP_STATE, APPSERVER_API_PREFIX, ASSET_PATH_SEPARATOR, GUID_REGEX_PATTERN } from '@/main/app.constants';
import { SEEQ_VERSION } from '@/services/buildConstants.service';
import { ScreenshotService } from '@/services/screenshot.service';
import { AuthenticationService } from '@/services/authentication.service';
import { RequestsApi } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';

const dependencies = [
  'Sq.Vendor',
  'Sq.Services.AsyncResponses',
  'Sq.Services.Socket',
  'Sq.Services.Visibility',
  'Sq.Services.Utilities'
];

angular.module('Sq.Services.HttpHelpers', dependencies)
  .service('sqHttpHelpers', sqHttpHelpers)
  .factory('sessionIdInterceptor', sessionIdInterceptor)
  .factory('authenticationInterceptor', authenticationInterceptor)
  .factory('asyncHttpInterceptor', asyncHttpInterceptor)
  .factory('conflictRetryInterceptor', conflictRetryInterceptor)
  .factory('versionInterceptor', versionInterceptor)
  .factory('forbiddenInterceptor', forbiddenInterceptor)
  .factory('requestOriginInterceptor', requestOriginInterceptor);

export type HttpHelpersService = ReturnType<typeof sqHttpHelpers>;

interface IRequestConfigWithAsyncPromise extends ng.IRequestConfig {
  asyncPromise: Promise<AsyncResponseMessage>;
}

interface IHttpResponseWithAsyncPromise extends ng.IHttpResponse<{}> {
  config: IRequestConfigWithAsyncPromise;
}

function sqHttpHelpers(
  $injector: ng.auto.IInjectorService,
  $q: ng.IQService,
  sqLogger: LoggerService
) {

  const service = {
    addAssetsProperty,
    getAssetFromAncestors,
    wrapInterceptor,
    isHttpResponse,
    isHttpConfig,
    isCanceled,
    formatAsQueryString,
    cancelRunningRequest
  };

  return service;

  /**
   * Extracts the ancestors information from the provided response and adds a property to each item with the
   * assets, including the formatted name that includes the ancestors.
   *
   * @param {Object} data - Response from an endpoint that includes the ancestors property
   * @returns {Object} Transformed data with items containing a new assets property that is the list of assets the
   * item belongs to.
   */
  function addAssetsProperty(data) {
    _.forEach(_.get(data, 'items'), (item, k) => {
      data.items[k].assets = [service.getAssetFromAncestors(item.ancestors)];
    });
    return data;
  }

  /**
   * Returns an asset object with id, name, and formattedName based on its ancestors.
   *
   * @param {Object[]} ancestors - The array of ancestors
   * @returns {Object} Transformed object ancestors new properties.
   *                   {String} .id - ID of asset dependency
   *                   {String} .name - Name of asset dependency
   *                   {String} .formattedName - Name that includes the full asset path
   */
  function getAssetFromAncestors(ancestors) {
    return _.assign({}, _.pick(_.last(ancestors), ['id', 'name']),
      { formattedName: _.map(ancestors, 'name').join(ASSET_PATH_SEPARATOR) },
      { pathComponentIds: _.map(ancestors, 'id') });
  }

  /**
   * Helper function to take an object and format all of its key/value pairs into a single query parameter string
   *
   * @param {Object} params - Object containing the key/value pairs to use as query parameters
   * @returns {string} containing all owned properties
   */
  function formatAsQueryString(params) {
    const searchParams = new URLSearchParams();
    _.forEach(params, (value, key) => {
      if (value !== undefined) searchParams.append(key, value);
    });
    return searchParams.toString();
  }

  /**
   * Cancels any running request after the preview modal has been closed.
   *
   * @param {string} requestId - The ID of the request to cancel
   * @returns {Promise} that resolves when the request is cancelled
   */
  function cancelRunningRequest(requestId) {
    return $injector.get<RequestsApi>('sqRequestsApi').cancelRequest({ requestId })
      .catch(function(response) {
        if (_.get(response, 'status') !== HttpCodes.NOT_FOUND) {
          return $q.reject(response);
        }
      });
  }

  /**
   * Adds broken interceptors error handling to the interceptor. The error handling follows a pattern: If the input
   * to the handler is invalid (indicates that another interceptor is broken) the interceptor will be skipped
   * (delegated handler will not be called). If the handler returns an invalid result, the result will be logged but
   * the output of the handler will be the resolved/rejected input value.
   *
   * @param {String} interceptorName - name of the interceptor (used for logging)
   * @param {Object} interceptor - the interceptor interface
   * @param {Function} [interceptor.request] - handles transforming request before it is sent
   * @param {Function} [interceptor.requestError] - handles errors that occurred before the request was sent
   * @param {Function} [interceptor.response] - handles transforming request after it is sent
   * @param {Function} [interceptor.responseError] - handles any errors that occurred during the request
   * @returns {Object} an interceptor interface that delegates to the provided interceptor interface
   */
  function wrapInterceptor(interceptorName: string, interceptor: ng.IHttpInterceptor): ng.IHttpInterceptor {
    return { request, requestError, response, responseError };

    function request(inputConfig: ng.IRequestConfig) {
      if (!inputConfig) {
        sqLogger.error(
          sqLogger.format`Skipping ${interceptorName}.request because of broken interceptor: config not supplied, ${inputConfig}`);
        return $q.resolve(inputConfig);
      }

      return $q.resolve(inputConfig)
        .then(interceptor.request)
        .then((outputConfig) => {
          if (!outputConfig) {
            sqLogger.error(
              sqLogger.format`${interceptorName}.request interceptor broken: config object not supplied, ${outputConfig}`);
            return $q.resolve(inputConfig);
          }

          return outputConfig;
        })
        .catch((outputRejection) => {
          if (!outputRejection || !outputRejection.config) {
            sqLogger.error(
              sqLogger.format`${interceptorName}.request interceptor broken: config object not supplied in rejection, ${outputRejection}`);
            return $q.resolve(inputConfig);
          }

          return $q.reject(outputRejection);
        });
    }

    function requestError(inputRejection) {
      if (!inputRejection || !inputRejection.config) {
        sqLogger.error(
          sqLogger.format`Skipping ${interceptorName}.requestError because of broken interceptor: config not supplied in rejection, ${inputRejection}`);
        return $q.reject(inputRejection);
      }

      return $q.reject(inputRejection)
        .catch(interceptor.requestError)
        .then((outputConfig) => {
          if (!outputConfig) {
            sqLogger.error(
              sqLogger.format`${interceptorName}.requestError interceptor broken: config object not supplied, ${outputConfig}`);
            return $q.reject(inputRejection);
          }

          return outputConfig;
        })
        .catch((outputRejection) => {
          if (!outputRejection || !outputRejection.config) {
            sqLogger.error(
              sqLogger.format`${interceptorName}.requestError interceptor broken: config object not supplied in rejection, ${outputRejection}`);
            return $q.reject(inputRejection);
          }

          return $q.reject(outputRejection);
        });

    }

    function response(inputResponse: ng.IHttpResponse<any>) {
      if (!inputResponse || !inputResponse.config) {
        sqLogger.error(
          sqLogger.format`Skipping ${interceptorName}.response because of broken interceptor: config not supplied, ${inputResponse}`);
        return $q.resolve(inputResponse);
      }

      return $q.resolve(inputResponse)
        .then(interceptor.response)
        .then((outputResponse) => {
          if (!outputResponse || !outputResponse.config) {
            sqLogger.error(
              sqLogger.format`${interceptorName}.response interceptor broken: config object not supplied in response, ${outputResponse}`);
            return $q.resolve(inputResponse);
          }

          return outputResponse;
        })
        .catch((outputRejection) => {
          if (!outputRejection || !outputRejection.config) {
            sqLogger.error(
              sqLogger.format`${interceptorName}.response interceptor broken: config object not supplied in rejection, ${outputRejection}`);
            return $q.resolve(inputResponse);
          }

          return $q.reject(outputRejection);
        });

    }

    function responseError(inputRejection) {
      if (!inputRejection || !inputRejection.config) {
        sqLogger.error(
          sqLogger.format`Skipping ${interceptorName}.responseError because of broken interceptor: config not supplied in rejection, ${inputRejection}`);
        return $q.reject(inputRejection);
      }

      return $q.reject(inputRejection)
        .catch(interceptor.responseError)
        .then((outputResponse) => {
          if (!outputResponse || !outputResponse.config) {
            sqLogger.error(
              sqLogger.format`${interceptorName}.responseError interceptor broken: config object not supplied in response, ${outputResponse}`);
            return $q.reject(inputRejection);
          }

          return outputResponse as ng.IHttpResponse<any>;
        })
        .catch((outputRejection) => {
          if (!outputRejection || !outputRejection.config) {
            sqLogger.error(
              sqLogger.format`${interceptorName}.responseError interceptor broken: config object not supplied in rejection, ${outputRejection}`);
            return $q.reject(inputRejection);
          }

          return $q.reject(outputRejection);
        });
    }
  }

  /**
   * Test if the argument looks like it is an angularjs http response object
   */
  function isHttpResponse(response: any): response is ng.IHttpResponse<any> {
    return _.has(response, 'status') && service.isHttpConfig(_.get(response, 'config'));
  }

  /**
   * Test if the argument looks like it is an angularjs http config objects
   */
  function isHttpConfig(config: any): config is ng.IRequestConfig {
    return _.has(config, 'url') && _.has(config, 'method') && _.has(config, 'headers');
  }

  /**
   * Test if the argument represents a http response object that is the result of a canceled seeq request. If the
   * argument doesn't appear to be a http response object false is returned
   */
  function isCanceled(response: any) {
    return _.has(response, 'status') && (
      // -1 status indicates that a response didn't come back from the server - this can happen during cancellation
      //   because we hang up on the server rather than waiting for it to respond with a 503. For non-async requests
      //   or the initial part of the async request the underlying xhr request is aborted leaving xhrStatus=abort. For
      //   an async request waiting for a websocket response the cancellation will not change the xhrStatus since
      //   the initial request to the server was successful (xhrStatus=complete). xhrStatus=error likely indicates
      //   a network related problem on the client side
      // 503 'Service Unavailable' is used by the backend to indicate canceled requests, but it can also be used
      //   to indicate other errors such as the server being overloaded (CRAB-14207). For cancellations the server will
      //   respond with a 'The request was canceled.' statusMessage that we can test against.
      (response.status === -1
        && (response.xhrStatus === 'abort' || response.xhrStatus === 'complete') || response.status === HttpCodes.SERVICE_UNAVAILABLE
        && _.includes(_.get(response, 'data.statusMessage', ''), 'canceled'))
    );
  }
}

function conflictRetryInterceptor($q, $injector, $timeout, sqHttpHelpers) {
  const MAXIMUM_RETRIES = 5;

  return sqHttpHelpers.wrapInterceptor(conflictRetryInterceptor.name, {
    responseError
  });

  function responseError(rejection) {
    if (rejection.status !== HttpCodes.CONFLICT) {
      return $q.reject(rejection);
    }

    rejection.config.retry = rejection.config.retry || 0;

    if (rejection.config.retry < MAXIMUM_RETRIES) {
      rejection.config.retry++;
      // Slight linear delay to give conflicting requests a better chance of not crashing into each other
      return $timeout(() => $injector.get('$http')(rejection.config), rejection.config.retry * 100);
    } else {
      return $q.reject(rejection);
    }
  }
}

/**
 * HTTP interceptor that adds the current SessionID as a header to all outgoing HTTP requests
 */
function sessionIdInterceptor($injector, $q, sqHttpHelpers) {
  return sqHttpHelpers.wrapInterceptor(sessionIdInterceptor.name, {
    request(config) {
      config.headers[SeeqNames.API.Headers.InteractiveSessionId] =
        $injector.get('sqWorkbenchStore').interactiveSessionId;
      return config;
    }
  });
}

/**
 * HTTP interceptor that adds request origin as a header to all outgoing HTTP requests
 */
function requestOriginInterceptor($injector, $q, sqHttpHelpers) {
  return sqHttpHelpers.wrapInterceptor(requestOriginInterceptor.name, {
    request(config) {
      const sqUtilities = $injector.get('sqUtilities');
      config.headers[SeeqNames.API.Headers.RequestOrigin] =
        sqUtilities.headlessRenderMode() ? sqUtilities.headlessRenderCategory() : 'Analysis';
      return config;
    }
  });
}

/**
 * HTTP interceptor that handles requests that may send responses asynchronously. We specify a header that allows
 * all requests to be sent asynchronously. The backend has a allowlist of URLs that are allowed to be sent back
 * asynchronously. When responses are returned, this interceptor automatically handles HTTP responses
 * of status 202, leaving the response promises unsatisfied until the async responses are received via the websocket
 * connection. If the response is sent synchronously, all async handlers are cleaned up automatically.
 *
 * @returns {Promise} that resolves when the response is available or determined to be missing/orphaned. Returns a
 *   rejected promise if HTTP status code indicates a failure.
 */
function asyncHttpInterceptor(
  $q: ng.IQService,
  $location: ng.ILocationService,
  sqAsyncResponses: AsyncResponsesService,
  sqUtilities: UtilitiesService,
  sqLogger: LoggerService,
  sqHttpHelpers: HttpHelpersService,
  sqRedaction: RedactionService) {
  // @ts-ignore
  return sqHttpHelpers.wrapInterceptor(asyncHttpInterceptor.name, { request, response, responseError });

  function request(config: ng.IRequestConfig) {
    if (!_.get($location.search(), 'async-disabled', false)) {
      // Allow all requests to be async; the backend will determine whether to return sync or async
      config.headers[SeeqNames.API.Headers.Async] = true;

      let requestId = config.headers[SeeqNames.API.Headers.RequestId];
      // make sure a request ID is specified; otherwise generate one, since we need it to wait for possible async
      // response
      if (!requestId) {
        requestId = sqUtilities.generateRequestId();
        config.headers[SeeqNames.API.Headers.RequestId] = requestId;
      }

      // Setup the async wait now so that there's no possibility of a race condition where the response arrives before
      // the wait is registered
      // @ts-ignore
      config.asyncPromise = sqAsyncResponses.waitForResponse(requestId);
    }

    return config;
  }

  function response<T>(rawResponse: ng.IHttpResponse<T>) {
    const response = rawResponse as unknown as IHttpResponseWithAsyncPromise;
    const requestId = response.config.headers[SeeqNames.API.Headers.RequestId];

    // ACCEPTED (202) == http async
    if (response.status === HttpCodes.ACCEPTED && !response.config.useManualAsync) {
      sqAsyncResponses.accepted(requestId);

      return response.config.asyncPromise
        .then((asyncData: AsyncResponseMessage) => {
          // Roughly match the angularjs 'headerGetter' behavior with case insensitivity
          const mergedHeaders = _.mapKeys(_.assign({}, response.headers(), asyncData.headers),
            (value, key: string) => _.toLower(key));
          // @ts-ignore
          response.headers = (headerName?: string) => _.isString(headerName)
            ? mergedHeaders[_.toLower(headerName)]
            : mergedHeaders;
          response.status = asyncData.status;
          response.statusText = asyncData.statusText;
          response.data = asyncData.body;

          if (response.status >= 400 || response.status < 0) {
            // With async requests it is much harder to see failing requests in devtools -- especially if we
            // silently ignore the error downstream
            if (!sqHttpHelpers.isCanceled(response)) {
              sqRedaction.handleForbidden(response);
              sqLogger.warn(sqLogger.format`Async Response: ${response}`);
            }

            return $q.reject(response);
          } else {
            return $q.resolve(response);
          }
        });
    } else if (requestId && response.config.asyncPromise) {
      // Response not async; cleanup async handlers and return the sync response directly
      sqAsyncResponses.cancelWait(requestId);
    }

    return response;
  }

  function responseError(response) {
    const requestId = response.config.headers[SeeqNames.API.Headers.RequestId];
    if (requestId && response.config.asyncPromise) {
      sqAsyncResponses.cancelWait(requestId);
    }

    return $q.reject(response);
  }
}

/**
 * An HTTP interceptor that will inspect the 'Server' header for the Seeq version running on the server.
 * If the version doesn't match the frontend version, the frontend will perform a force reload.
 */
function versionInterceptor(
  sqLogger: LoggerService,
  sqUtilities: UtilitiesService,
  sqHttpHelpers: HttpHelpersService
) {
  return sqHttpHelpers.wrapInterceptor(versionInterceptor.name, {
    response(response) {
      const serverHeader = response.headers(SeeqNames.API.Headers.Server);
      if (serverHeader && _.includes(serverHeader, 'Seeq/')) {
        // Extract only the version part of the header (also removes any extra data added by proxies, CRAB-15461)
        const serverVersion = _.replace(serverHeader, /^(.*\s)*Seeq\/(.*?)(\s.*)*$/, '$2');
        if (serverVersion !== SEEQ_VERSION) {
          // Server and client version don't match (e.g. server has been upgraded), so force a reload of the client
          sqLogger.info(
            `Server and client version mismatch ('${serverVersion}' vs '${SEEQ_VERSION}'), forcing reload of client`);
          sqUtilities.reload(true);
        }
      }

      return response;
    }
  });
}

/**
 * An HTTP interceptor to handle the authentication of HTTP requests. For outgoing requests it will set the necessary
 * information to ensure the requests are authenticated if the user is logged in. Since the primary authentication
 * token is set as a httpOnly cookie by the backend, to ensure it is protected from XSS attacks, only the Cross Site
 * Forgery token needs to be set. See
 * https://cheatsheetseries.owasp.org/cheatsheets/Cross-Site_Request_Forgery_Prevention_Cheat_Sheet.html for an
 * explanation of why this is needed. This interceptor also takes care of sending the user to the login page if a
 * response code indicates they are no longer logged in.
 */
function authenticationInterceptor(
  $q: ng.IQService,
  $injector: ng.auto.IInjectorService,
  sqHttpHelpers: HttpHelpersService,
  sqLogger: LoggerService
) {
  return sqHttpHelpers.wrapInterceptor(authenticationInterceptor.name, {
    request(config) {
      $injector.get<AuthenticationService>('sqAuthentication').addCsrfHeader(config.headers);
      return config;
    },

    responseError(rejection) {
      const $state = $injector.get<ng.ui.IStateService>('$state');

      // Route to the login page if we're not in the login state, we get a 401, and the state is not transitioning
      if ($state.current.name !== APP_STATE.LOGIN && rejection.status === HttpCodes.UNAUTHORIZED && !$state.current.abstract) {
        $injector.get<ScreenshotService>('sqScreenshot').notifyError(
          sqLogger.format`Invalid authentication detected: ${rejection}`);
        $injector.get<AuthenticationService>('sqAuthentication').logout($state.current, $state.params);

        return $q.reject(rejection);
      }

      // If in the login state and the server does not respond, then route to the load error state
      if ($state.current.name === APP_STATE.LOGIN && HttpCodes.GATEWAY_TIMEOUT === rejection.status) {
        $state.go(APP_STATE.LOAD_ERROR, {
          returnState: APP_STATE.LOGIN,
          returnParams: JSON.stringify($state.params)
        });
      }

      return $q.reject(rejection);
    }
  });
}

/**
 * An HTTP interceptor that routes to the unauthorized state if a user receives a 403 Forbidden response as the
 * result of trying to update an annotation or get/set worksteps. This should not typically happen, but can happen
 * if a user's permissions to a workbook are removed while the user is already viewing the workbook.
 */
function forbiddenInterceptor(
  $q: ng.IQService,
  $injector: ng.auto.IInjectorService,
  sqHttpHelpers: HttpHelpersService
) {
  const ANNOTATION_REGEX = new RegExp(`^${APPSERVER_API_PREFIX}/annotations/${GUID_REGEX_PATTERN}/update`, 'i');
  const WORKSTEP_REGEX = new RegExp(
    `^${APPSERVER_API_PREFIX}/workbooks/${GUID_REGEX_PATTERN}/worksheets/${GUID_REGEX_PATTERN}/worksteps`, 'i');

  return sqHttpHelpers.wrapInterceptor(forbiddenInterceptor.name, { responseError });

  function responseError(rejection) {
    if (rejection.status === HttpCodes.FORBIDDEN) {
      const isMonitoredUrl = _.chain([ANNOTATION_REGEX, WORKSTEP_REGEX])
        .map(regex => regex.test(_.get(rejection, 'config.url')))
        .some()
        .value();

      if (isMonitoredUrl) {
        $injector.get<ng.ui.IStateService>('$state').go(APP_STATE.UNAUTHORIZED);
      }
    }

    return $q.reject(rejection);
  }
}
