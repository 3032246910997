import React, { useEffect, useState } from 'react';
import pdfobject from 'pdfobject';
import { Modal } from 'react-bootstrap';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { IconSelect } from '@/hybrid/core/IconSelect.molecule';
import { PdfExportActions } from '@/reportEditor/pdfExport.actions';
import { PdfExportStore } from '@/reportEditor/pdfExport.store';
import { PdfPreviewModalService } from '@/reportEditor/pdfPreviewModal.service';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { ExportService } from '@/services/export.service';
import { NotificationsService } from '@/services/notifications.service';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { DOCUMENT_LAYOUT, DOCUMENT_MARGIN_UNITS, DOCUMENT_PAPER_SIZE } from '@/reportEditor/report.module';
import { useDebounce } from '@/hybrid/core/useDebounce.hook';
import { DEBOUNCE } from '@/main/app.constants';
import ValueWithUnits from '@/hybrid/trend/ValueWithUnits.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { TrackService } from '@/track/track.service';
import { CancelAndSave } from '@/hybrid/core/CancelAndSave.molecule';
import { angularComponent } from '@/hybrid/core/react2angular.util';

const pdfPreviewModalBindings = bindingsDefinition({
  sqPdfExportActions: injected<PdfExportActions>(),
  sqPdfExportStore: injected<PdfExportStore>(),
  sqPdfPreviewModal: injected<PdfPreviewModalService>(),
  sqExport: injected<ExportService>(),
  sqNotifications: injected<NotificationsService>(),
  sqPendingRequests: injected<PendingRequestsService>(),
  sqTrack: injected<TrackService>(),
  $state: injected<ng.ui.IStateService>()
});

export const PdfPreviewModal: SeeqComponent<typeof pdfPreviewModalBindings> = () => {
  const {
    sqPdfExportActions,
    sqPdfExportStore,
    sqPdfPreviewModal,
    sqExport,
    sqNotifications,
    sqPendingRequests,
    sqTrack,
    $state
  } = useInjectedBindings(pdfPreviewModalBindings);

  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [generationPromise, setGenerationPromise] = useState(undefined);
  const debouncedSetLayout = useDebounce(sqPdfExportActions.setLayout, DEBOUNCE.MEDIUM);
  const debouncedSetPaperSize = useDebounce(sqPdfExportActions.setPaperSize, DEBOUNCE.MEDIUM);
  const marginChange = valueAndUnits => sqPdfExportActions.setMargin(valueAndUnits.value, valueAndUnits.units);
  const debouncedSetMargin = useDebounce(marginChange, DEBOUNCE.LONG);

  const {
    layout,
    paperSize,
    margin,
    showModal
  } = useFlux(sqPdfExportStore);

  useEffect(() => {
    if (showModal) {
      generatePdf(
        `${window.location.origin}/present/worksheet/${$state.params.workbookId}/${$state.params.worksheetId}`);
    }
  }, [layout, paperSize, margin, showModal]);

  useEffect(() => {
    pdfUrl && pdfobject.embed(pdfUrl, '#pdf-embed', {
      assumptionMode: false,
      fallbackLink: `<div class="m20">${t('PDF_PREVIEW.EMBED_FALLBACK')}</div>`
    });
  }, [pdfUrl]);

  const setPending = () => {
    setPdfUrl('');
    setError(false);
  };

  const hasError = () => {
    setPdfUrl('');
    setError(true);
  };

  const close = sqPdfPreviewModal.closeModal;

  const save = () => {
    sqTrack.doTrack('Export', 'PDF', 'start');
    return generationPromise
      .then(() => {
        window.open(pdfUrl, '_blank');
      });
  };

  const generatePdf = (destinationUrl) => {
    const cancellationId = `PDF Export ${destinationUrl}`;
    sqPendingRequests.cancel(cancellationId);
    setPending();

    setGenerationPromise(sqExport.toPDF(destinationUrl, {
        layout: layout.value,
        paperSize: paperSize.value,
        margin: margin.value + margin.units,
        cancellationGroup: cancellationId
      })
      .then((pdfUrl) => {
        setPdfUrl(pdfUrl);
      })
      .catch((error) => {
        sqNotifications.apiError(error);
        hasError();
      })
    );
  };

  return showModal &&
    <Modal show={true} onHide={close} animation={false} backdrop="static" data-testid="pdfPreviewModal"
      dialogClassName="pdfPreviewModal">
      <Modal.Header closeButton={true}>
        <Modal.Title>
          {t('PDF_PREVIEW.TITLE')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="flexColumnContainer">
        <div className="flexRowContainer width-25percent mr10">
          <label>{t('PDF_PREVIEW.LAYOUT.LABEL')}</label>
          <IconSelect
            name="layout"
            insideModal={true}
            value={layout}
            onChange={debouncedSetLayout}
            selectOptions={DOCUMENT_LAYOUT}
          />

          <label className="mt10">{t('PDF_PREVIEW.PAPER_SIZE.LABEL')}</label>
          <IconSelect
            name="paperSize"
            insideModal={true}
            value={paperSize}
            onChange={debouncedSetPaperSize}
            selectOptions={DOCUMENT_PAPER_SIZE}
          />

          <label className="mt10">{t('PDF_PREVIEW.MARGIN.LABEL')}</label>
          <ValueWithUnits
            min={0}
            required={true}
            insideModal={true}
            defaultValue={margin}
            onChange={debouncedSetMargin}
            availableUnits={DOCUMENT_MARGIN_UNITS} />
        </div>

        <div className="flexCenter flexFill min-width-400 min-height-400 lightGreyBorder">
          {pdfUrl &&
          <div id="pdf-embed" className="width-maximum height-maximum" data-testid="pdf-embed" />}
          {!pdfUrl && !error && <div
            className="flexRowContainer flexCenter placeholder height-maximum sq-darkish-gray text-center">
            <Icon icon="fa-spinner fa-pulse" />
            {t('REPORT.CONFIG.PREVIEW')}
          </div>}
          {!pdfUrl && error && <div
            className="flexRowContainer flexCenter placeholder height-maximum sq-text-danger text-center">
            <Icon icon="fa-exclamation-triangle" large={true} />
            <span className="mt3">{t('ERROR_LOADING_CONTENT')}</span>
          </div>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CancelAndSave
          values={undefined}
          cancelClassNames="min-width-60"
          submitClassNames="min-width-60"
          cancelBtnLabel="CLOSE"
          cancelFn={close}
          btnDisabled={!pdfUrl}
          submitFn={save}
        />
      </Modal.Footer>
    </Modal>;
};

export const sqPdfPreviewModal = angularComponent(pdfPreviewModalBindings, PdfPreviewModal);
