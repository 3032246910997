import _ from 'lodash';
import angular from 'angular';
import { TrendActions } from '@/trendData/trend.actions';
import { UtilitiesService } from '@/services/utilities.service';
import { NumberHelperService } from '@/core/numberHelper.service';
import { ItemsApi } from 'sdk/api/ItemsApi';
import { FormulasApi } from 'sdk/api/FormulasApi';
import { SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.module';
import { COMPARISON_OPERATORS_SYMBOLS, PREDICATE_API } from '@/investigate/investigate.module';
import { PUSH_IGNORE } from '@/services/stateSynchronizer.service';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { TrendScalarStore } from '@/trendData/trendScalar.store';
import { API_TYPES_TO_ITEM_TYPES, ITEM_TYPES } from '@/trendData/trendData.module';
import {
  ConditionsApi,
  ScalarsApi,
  SignalsApi
} from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';

/**
 * @file Service that facilitates creating a calculation and then using that calculation to create results, such as
 * generated capsules or markers.
 */
angular.module('Sq.Services.CalculationRunner').factory('sqCalculationRunner', sqCalculationRunner);

export type CalculationRunnerService = ReturnType<typeof sqCalculationRunner>;

function sqCalculationRunner(
  $q: ng.IQService,
  sqTrendActions: TrendActions,
  sqUtilities: UtilitiesService,
  sqNumberHelper: NumberHelperService,
  sqItemsApi: ItemsApi,
  sqFormulasApi: FormulasApi,
  sqConditionsApi: ConditionsApi,
  sqSignalsApi: SignalsApi,
  sqScalarsApi: ScalarsApi,
  sqTrendSeriesStore: TrendSeriesStore,
  sqTrendScalarStore: TrendScalarStore
) {
  const service = {
    profileSearch,
    simpleValueSearch,
    advancedValueSearch,
    getStatisticFragment,
    getStatisticFromFragment,
    createCalculatedItem,
    createCondition: createFormulaItem(SeeqNames.Types.Condition),
    createSignal: createFormulaItem(SeeqNames.Types.Signal),
    createScalar: createFormulaItem(SeeqNames.Types.CalculatedScalar),
    updateFormulaItem
  };

  return service;

  /**
   * Searches one or more series based on a visual reference pattern and generates capsules from the matches.
   *
   * @param {String} inputSignalId - ID of the series containing the pattern
   * @param {Moment} referenceStart - Time marking the start of the search pattern
   * @param {Moment} referenceEnd - Time marking the end of the search pattern
   * @param {String} similarity - Threshold of matches, in terms of how similar (0-1) they are
   * @param {String} normalizeAmplitude - True if amplitude should be normalized.
   * @param {String} normalizeLocation - True ifr location should be normalized
   * @returns {{ formula: String, parameters: Object}} an object containing the formula and parameters
   */
  function profileSearch(inputSignalId, referenceStart, referenceEnd, similarity, normalizeAmplitude,
    normalizeLocation) {
    const DEFAULT_AMPLITUDE_FACTOR = 0.3;
    const DEFAULT_LOCATION_FACTOR = 0.3;
    const parameters = { a: inputSignalId };
    const start = 'toTime("' + referenceStart.toISOString() + '")';
    const end = 'toTime("' + referenceEnd.toISOString() + '")';
    const amplitudeFactor = normalizeAmplitude ? DEFAULT_AMPLITUDE_FACTOR : -1;
    const locationFactor = normalizeLocation ? DEFAULT_LOCATION_FACTOR : -1;
    const params = ['$a', start, end, similarity, 0.5, amplitudeFactor, locationFactor];
    const formula = 'profileSearch(' + params.join(', ') + ')';

    return { formula, parameters };
  }

  function simpleValueSearch({
    inputSignal,
    operator,
    value,
    upperInclusivity,
    lowerValue,
    lowerInclusivity,
    isCleansing,
    useValidValues,
    minDuration,
    mergeDuration
  }) {
    // Helper function to put the value in the correct format for scalars, signals and string series
    function getValue(value, param) {
      return sqUtilities.validateGuid(value) ? `$${param}` : isStringSeries ? `"${value}"` : value;
    }

    const isStringSeries = sqUtilities.isStringSeries(inputSignal);
    const parameters = { a: inputSignal.id };

    const isValueItem = sqUtilities.validateGuid(value);
    const isLowerValueItem = sqUtilities.validateGuid(lowerValue);
    if (isValueItem) _.assign(parameters, { b: value });
    if (isLowerValueItem) _.assign(parameters, { c: lowerValue });

    let formula;
    if (operator === COMPARISON_OPERATORS_SYMBOLS.IS_NOT_BETWEEN) {
      // $a < b || $a > c
      formula = `$a ${lowerInclusivity} ${getValue(lowerValue, 'c')} || $a ${upperInclusivity} ${getValue(value, 'b')}`;
    } else if (operator === COMPARISON_OPERATORS_SYMBOLS.IS_BETWEEN) {
      // b > $a > c
      formula = `$a ${upperInclusivity} ${getValue(value, 'b')} && $a ${lowerInclusivity} ${getValue(lowerValue, 'c')}`;
    } else if (operator === COMPARISON_OPERATORS_SYMBOLS.IS_EQUAL_TO) {
      formula = `$a == ${getValue(value, 'b')}`;
    } else {
      formula = `$a ${operator} ${getValue(value, 'b')}`;
    }

    if (isCleansing) {
      formula = `(${formula})`;
      formula += `\n.merge(${mergeDuration.value}${mergeDuration.units})`;

      // only call removeShorterThan if we have a positive value (0 can be accepted in the UI)
      if (minDuration.value > 0) {
        formula += `\n.removeShorterThan(${minDuration.value}${minDuration.units})`;
      }
    }

    const promises = [];
    if (useValidValues) {
      function getParameterType(id, name) {
        const item = _.find(_.concat(sqTrendScalarStore.items, sqTrendSeriesStore.primarySeries), { id });
        if (_.isUndefined(item)) {
          return sqItemsApi.getItemAndAllProperties({ id })
            .then(item => ({ name, itemType: API_TYPES_TO_ITEM_TYPES[item.data.type] }));
        } else {
          return $q.resolve({ name, itemType: item.itemType });
        }
      }

      if (isValueItem) {
        promises.push(getParameterType(value, 'b'));
      }

      if (isLowerValueItem) {
        promises.push(getParameterType(lowerValue, 'c'));
      }
    }

    return $q.all(promises).then((params) => {
      if (useValidValues) {
        // add the .validValues() to each parameter throughout the formula (only if it is NOT a calculated scalar type)
        _.chain(params)
          .concat({ name: 'a', itemType: ITEM_TYPES.SERIES })
          .filter(({ itemType }) => itemType === ITEM_TYPES.SERIES)
          .forEach(({ name }) => formula = _.replace(formula, new RegExp(`\\$${name}`, 'g'), `$${name}.validValues()`))
          .value();
      }
      return { formula, parameters };
    });
  }

  function advancedValueSearch({ inputSignal, entry, exit, maximumDuration, useValidValues }) {
    const parameters = { a: inputSignal.id };
    let formula = '$a';
    if (useValidValues) formula += '.validValues()';
    formula += `.valueSearch(${maximumDuration.value}${maximumDuration.units}, ` +
      `${PREDICATE_API[entry.operator]}(${getFormulaValue(entry, inputSignal)}), ` +
      `${entry.duration.value}${entry.duration.units}, ` +
      `${PREDICATE_API[exit.operator]}(${getFormulaValue(exit, inputSignal)}), ` +
      `${exit.duration.value}${exit.duration.units})`;

    return { formula, parameters };

    function getFormulaValue(condition, inputSignal) {
      const values = _.compact([condition.value, condition.lowerValue]).join(',');
      return sqUtilities.isStringSeries(inputSignal) ? `"${values}"` : values;
    }
  }

  /**
   * Runs a formula that produces an auto-selecting type. Saves or updates that calculation based on compile type.
   *
   * @param {String} name - Name for calculation
   * @param {String|undefined} scopedTo - ID of a workbook to which the item will be scoped or undefined for global
   * @param {String} formula - The formula to pass to the Calculation Engine
   * @param {Object} parameters - Map of parameter name to ID that are the top-level parameters used in the formula
   * @returns {Promise} Resolves when the item has been generated. If successful the promise will resolve with the
   * return type in a container object
   */
  function createCalculatedItem(name, scopedTo, formula, parameters, additionalProperties = []) {
    return sqFormulasApi.createItem({
      name,
      scopedTo,
      formula,
      parameters: _.map(parameters, (v, k) => { return { name: k, id: v }; }),
      additionalProperties
    }).then(function(response) {
      return response.data;
    });
  }

  /**
   * Creates a function that runs a formula that produces the type that matches the creator function. Saves or updates a
   * calculation
   *
   * @param itemType - the type of item to create. Condition, Signal, or CalculatedScalar
   * @returns the function that can be used to create the item
   */
  function createFormulaItem(itemType: string) {
    // The APIs are slightly different. /signals uses formulaParameters, while others use parameters
    const parametersKey = itemType === SeeqNames.Types.Signal ? 'formulaParameters' : 'parameters';
    const isSignal = () => SeeqNames.Types.Signal === itemType;
    const isCondition = () => SeeqNames.Types.Condition === itemType;
    const isScalar = () => SeeqNames.Types.CalculatedScalar === itemType;
    const creator = _.cond([
      [isCondition, body => sqConditionsApi.createCondition(body as any)],
      [isSignal, body => sqSignalsApi.createSignal(body as any)],
      [isScalar, body => sqScalarsApi.createCalculatedScalar(body as any)]
    ]);

    /**
     * Runs a formula that produces the type that matches the creator function. Saves or updates a calculation
     *
     * @param name - Name for item
     * @param scopedTo - ID of a workbook to which the item will be scoped or undefined for global
     * @param formula - The formula to pass to the Calculation Engine
     * @param parameters - Map of parameter name to ID that are the top-level parameters used in the formula
     */
    return (name: string, scopedTo: string | undefined, formula: string, parameters: { [key: string]: string }) => {
      const body = {
        name,
        formula,
        [parametersKey]: sqUtilities.encodeParameters(parameters),
        scopedTo
      };
      if (isCondition()) {
        body.maximumDuration = null;
      }

      return creator(body)
        .then(results => results.data);
    };
  }

  /**
   * Update a calculated series or capsule set by setting the name, formula and UIConfig properties. Properties are
   * updated in sequential order to ensure that if the formula is bad the others are not updated. The backend takes
   * care of invalidating the cached data whenever the `formula` property is updated.
   *
   * @param {String} id - The id of the existing calculation
   * @param {String} name - The name of the calculation
   * @param {String} formula - The formula to pass to the Calculation Engine
   * @param {Object} parameters - Map of parameter name to ID that are the top-level parameters used in the formula
   * @returns {Promise} Resolves when all the properties are updated
   */
  function updateFormulaItem(id, name, formula, parameters) {
    return sqItemsApi.setFormula({ formula, parameters: sqUtilities.encodeParameters(parameters) }, { id })
      .then(() => {
        sqTrendActions.setTrendItemProps(id, { name }, PUSH_IGNORE);
        return sqItemsApi.setProperty({ value: name }, { id, propertyName: SeeqNames.Properties.Name });
      });
  }

  /**
   * Compute the parameterized stat from the statistic collector
   *
   * @param {Object} statistic - The statistic object
   * @param {String} statistic.key - The key into the SAMPLES_FROM_SCALARS.VALUE_METHOD object
   * @param {String} statistic.timeUnits - for stats that are outputInTimeUnits=true, this is the selected time unit
   * @param {Integer} statistic.percentile - for stats that needsPercentile=true, this is the specified percentile
   * @return {String} The formula fragment that is usable in the aggregate operator.
   */
  function getStatisticFragment(statistic) {
    const statObject = _.find(SAMPLE_FROM_SCALARS.VALUE_METHODS, ['key', _.get(statistic, 'key')]);
    if (!_.isNil(statObject)) {
      return _.chain(statObject.stat)
        .replace('$unit', '"' + _.get(statistic, 'timeUnits') + '"')
        .replace('$percentile', _.get(statistic, 'percentile'))
        .value();
    } else {
      throw new TypeError(`Unknown statistic key ${_.get(statistic, 'key')} `);
    }
  }

  /**
   * Computes a statistic from a statistic fragment
   *
   * @param statisticFragment - a statisticFragment (e.g. average() or totalized("min"))
   * @returns {Object} - a statistic or undefined if a statistic could not be determined from the fragment
   */
  function getStatisticFromFragment(statisticFragment: string) {
    const result = /^(\w*)\(['"]?(\w*)?['"]?\)$/.exec(statisticFragment);
    const fragFuncName = _.get(result, '1');
    const stat = _.find(SAMPLE_FROM_SCALARS.VALUE_METHODS,
      valueMethod => _.startsWith(valueMethod.stat, fragFuncName));
    if (stat) {
      const statData = { key: stat.key };
      if (stat.needsPercentile) {
        const percentile = parseInt(_.get(result, 2), 10);
        _.assign(statData, { percentile: _.isNaN(percentile) ? null : percentile });
      } else if (stat.outputInTimeUnits) {
        _.assign(statData, { timeUnits: _.get(result, 2) });
      }
      return statData;
    }
  }
}
