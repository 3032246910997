import angular from 'angular';
import 'sdk/api.module';

const dependencies = [
  'api',
  'Sq.Vendor',
  'Sq.Core.Configuration',
  'Sq.Core.FluxDecorator',
  'Sq.Core.jqLiteDecorator',
  'Sq.Core.StateSynchronizer',
  'Sq.DateTime',
  'Sq.Services.Utilities',
  'Sq.Services.Storage',
  'Sq.Services.Screenshot'
];

angular.module('Sq.Core', dependencies);

export const NUMBERS_ONLY_REGEX = /^((?!\s*$)[-+]?[0-9]*\.?[0-9]*([eE][-+]?[0-9]+)?)$/;
