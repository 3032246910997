import React from 'react';
import classNames from 'classnames';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';

const sortIconBindings = bindingsDefinition({
  sortBy: prop<string>(),
  sortAsc: prop<boolean>(),
  sortProperty: prop<string>()
});

/** Icon to indicate sorting, e.g. table column headers */
export const SortIcon: SeeqComponent<typeof sortIconBindings> = (props) => {
  const { sortProperty, sortBy, sortAsc } = props;
  return (
    <i
      data-testid={`sortProperty_${sortProperty}`}
      className={classNames('fa', 'ml5', 'cursorPointer', {
        'sq-text-info': sortBy === sortProperty,
        'fa-sort-asc': sortBy === sortProperty && sortAsc,
        'fa-sort-desc': sortBy === sortProperty && !sortAsc,
        'sq-fairly-dark-gray fa-sort': sortBy !== sortProperty
      })}
    />
  );
};

export const sqSortIcon = angularComponent(sortIconBindings, SortIcon);
