import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { Modal } from 'react-bootstrap';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { AssetGroupActions } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { SearchWidget } from '@/hybrid/search/SearchWidget.organism';
import { SEARCH_PANES } from '@/search/search.module';
import { SearchActions } from '@/search/search.actions';
import { NotificationsService } from '@/services/notifications.service';
import { API_TYPES } from '@/main/app.constants';

const assetFinderModalBindings = bindingsDefinition({
  // 2 different functions for closing here as on regular close we close the modal and return to the details modal
  // if applicable, if we added an item however we always entirely close the modal.
  onClose: prop<() => void>(),
  onCloseAfterAssignment: prop.optional<() => void>(),
  asset: prop<{ name: string }>(),
  columnName: prop<string>(),
  testId: prop.optional<string>(),
  sqAssetGroupActions: injected<AssetGroupActions>(),
  sqSearchActions: injected<SearchActions>(),
  sqNotifications: injected<NotificationsService>()
});
export const SEARCH_TYPES_ASSET_GROUP = ['Asset', 'Condition', 'Signal', 'CalculatedScalar'];
export const AssetFinderModal: SeeqComponent<typeof assetFinderModalBindings> = ({
  onClose,
  asset,
  columnName,
  onCloseAfterAssignment,
  testId
}) => {
  const {
    sqAssetGroupActions,
    sqSearchActions,
    sqNotifications
  } = useInjectedBindings(assetFinderModalBindings);

  const { t } = useTranslation();

  const closeAndReset = (didAddItem) => {
    sqSearchActions.clear(SEARCH_PANES.MODAL, SEARCH_TYPES_ASSET_GROUP);
    didAddItem && _.isFunction(onCloseAfterAssignment) ? onCloseAfterAssignment() : onClose();
  };

  return (
    <Modal show={true} onHide={() => closeAndReset(false)} animation={false} data-testid={testId}>
      <Modal.Header closeButton={true}>
        <div className="flexRowContainer">
          <h4>{t('ASSET_GROUP_EDITOR.FIND_SIGNAL')}</h4>
          <span>{`${asset.name} » ${columnName}`}</span>
        </div>
      </Modal.Header>

      <Modal.Body>
        <SearchWidget
          pane={SEARCH_PANES.MODAL}
          searchTypes={SEARCH_TYPES_ASSET_GROUP}
          allowAssetReplacement={false}
          onItemClick={(item) => {
            // TODO CRAB-23867 remove error message if/when we support metrics
            item.type === API_TYPES.THRESHOLD_METRIC
              ? sqNotifications.errorTranslate('ASSET_GROUP_EDITOR.ERROR_ADDING_METRIC')
              : sqAssetGroupActions.addItemToAsset({ asset, columnName, itemId: item.id, type: item.type });
            closeAndReset(true);
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

