import React, { useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { onEnterKeypress, onEventPreventPropagation } from '@/hybrid/core/onEnterKeypress.util';
import { UtilitiesService } from '@/services/utilities.service';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.module';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { Icon } from '@/hybrid/core/Icon.atom';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { ImportDatafileActions } from '@/hybrid/tools/importDatafile/importDatafile.actions';
import { SearchResultUtilitiesService } from '@/hybrid/search/searchResult.utilities.service';
import { TrackService } from '@/track/track.service';
import { NotificationsService } from '@/services/notifications.service';
import { WorkbookActions } from '@/workbook/workbook.actions';

const searchResultIconsBindings = bindingsDefinition({
  item: prop<any>(),
  pinned: prop<any[]>(),
  workbookDisplay: prop<string>(),
  workbookId: prop<string>(),
  onItemClick: prop<(item) => any>(),
  allowAssetReplacement: prop<boolean>(),
  sqUtilities: injected<UtilitiesService>(),
  sqWorkbookActions: injected<WorkbookActions>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqImportDatafileActions: injected<ImportDatafileActions>(),
  sqSearchResultService: injected<SearchResultUtilitiesService>(),
  sqTrack: injected<TrackService>(),
  sqNotifications: injected<NotificationsService>()
});

export const SearchResultIcons: SeeqComponent<typeof searchResultIconsBindings> = (props) => {
  const {
    sqUtilities,
    sqWorkbookActions,
    sqInvestigateActions,
    sqImportDatafileActions,
    sqSearchResultService,
    sqTrack,
    sqNotifications
  } = useInjectedBindings(searchResultIconsBindings);

  const {
    item,
    pinned,
    workbookDisplay,
    workbookId,
    onItemClick,
    allowAssetReplacement
  } = props;

  const [isSwapping, setIsSwapping] = useState(false);
  const [isPinning, setIsPinning] = useState(false);

  const isDatafile = sqUtilities.isDatafile(item);
  const showInfoButton = sqUtilities.isAsset(item) || isDatafile || item?.hasChildren;
  const showTrendIcon = !sqUtilities.isAsset(item) && !isDatafile && item?.hasChildren;
  const showSwapIcon = sqUtilities.isAsset(item) || item?.hasChildren;
  const pinnedIds = _.map(pinned, 'id');
  const isEdit = workbookDisplay === WORKBOOK_DISPLAY.EDIT;
  const displayParentProperties = () => {
    sqInvestigateActions.setActiveTool(TREND_TOOLS.PROPERTIES);
    sqInvestigateActions.setNonStoreItem(item);
  };

  const swapAsset = () => {
    sqTrack.doTrack('Search results', 'Swap Assets');
    setIsSwapping(true);

    sqSearchResultService.swapAsset(item)
      .catch(e => sqNotifications.apiError(e))
      .finally(() => setIsSwapping(false));
  };

  const togglePin = () => {
    sqTrack.doTrack('Search results', 'pin to Workbook');
    const pinFunction = _.includes(pinnedIds, item.id) ? sqWorkbookActions.unpin : sqWorkbookActions.pin;
    setIsPinning(true);
    return pinFunction(workbookId, item.id)
      .finally(() => setIsPinning(false));
  };

  return (
    <>
      {showInfoButton &&
      <HoverTooltip text={isDatafile ? 'PROPERTIES.DATAFILE' : 'PROPERTIES.ASSET'}>
        <span onClick={onEventPreventPropagation(displayParentProperties)}>
          <Icon icon="fa-info-circle" extraClassNames="searchBtn" testId="infoIcon" />
        </span>
      </HoverTooltip>}

      {showTrendIcon &&
      <HoverTooltip text="SEARCH_DATA.TREND_ITEM" placement="top">
        <span onClick={onEventPreventPropagation(() => isSwapping || onItemClick(item))}
          onKeyUp={onEnterKeypress(() => isSwapping || onItemClick(item))}>
          <IconWithSpinner
            icon="fa-area-chart"
            extraClassNames="searchBtn"
            spinning={isSwapping}
            testId="trendIcon"
            correctSpin={false} />
        </span>
      </HoverTooltip>}

      {isDatafile &&
      <HoverTooltip text="SEARCH_DATA.EDIT_CSV">
        <span onClick={onEventPreventPropagation(() => sqImportDatafileActions.loadDatafile(item.id))}
          onKeyUp={onEnterKeypress(() => sqImportDatafileActions.loadDatafile(item.id))}>
          <Icon icon="fa-pencil-square-o" extraClassNames="searchBtn" testId="editDatafileIcon" />
        </span>
      </HoverTooltip>}

      {allowAssetReplacement && showSwapIcon &&
      <HoverTooltip text="ASSET_REPLACE.TOOLTIP" placement="top">
        <span onClick={onEventPreventPropagation(() => !isSwapping && swapAsset())}
          onKeyUp={onEnterKeypress(() => !isSwapping && swapAsset())}>
          <IconWithSpinner
            icon="fa-exchange"
            extraClassNames="searchBtn"
            spinning={isSwapping}
            testId="assetSwapIcon"
            correctSpin={false} />
        </span>
      </HoverTooltip>}

      {isEdit &&
      <HoverTooltip text={_.includes(pinnedIds, item.id) ? 'SEARCH_DATA.UNPIN' : 'SEARCH_DATA.PIN'}>
        <span onClick={onEventPreventPropagation(() => !isPinning && togglePin())}
          onKeyUp={onEnterKeypress(() => !isPinning && togglePin())}>
          <IconWithSpinner
            icon={_.includes(pinnedIds, item.id) ? 'fc-pin' : 'fc-pin-o'}
            extraClassNames="searchBtn"
            testId="pinIcon"
            spinning={isPinning}
            correctSpin={false} />
        </span>
      </HoverTooltip>}
    </>);
};

