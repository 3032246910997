import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { Dropdown } from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownItem from 'react-bootstrap/DropdownItem';
import React, { useState } from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import _ from 'lodash';
import DataSummarySlider from '@/hybrid/trend/DataSummarySlider.molecule';
import {
  KEEP_CURRENT_SUMMARY,
  ReportContentSummaries,
  ReportContentSummary as SummaryType
} from '@/reportEditor/report.module';
import { SummaryTypeEnum } from 'sdk/model/ContentInputV1';
import { SummarySliderValues, SummaryTypeSettings } from '@/trendData/trend.store';

const reportContentSummaryBindings = bindingsDefinition({
  summary: prop<SummaryType>(),
  summaries: prop<ReportContentSummaries | any>(),
  setSummary: prop<(summaryType: SummaryType | undefined) => void>()
});

export const ReportContentSummary: SeeqComponent<typeof reportContentSummaryBindings> = (props) => {
  const {
    summary,
    summaries,
    setSummary
  } = props;
  const { t } = useTranslation();

  const calculateStartingSliderValue = (): number => {
    if (!summary || summary.key as any === KEEP_CURRENT_SUMMARY.key) {
      return 0;
    }
    if (summary.key === SummaryTypeEnum.AUTO) {
      return _.toNumber(summary.value);
    }
    const key = _.findKey(SummarySliderValues[summary.key]?.value, (summary.value as any));
    const sliderValue = key !== undefined ? _.toNumber(key) : -1;
    return sliderValue !== -1 ? sliderValue : SummaryTypeSettings[summary.key]?.max / 2 ?? 0;
  };

  const [sliderValue, setSliderValue] = useState(calculateStartingSliderValue);

  const updateSlider = (level) => {
    const value = summary.key === SummaryTypeEnum.AUTO ? level : SummarySliderValues[summary.key].value[level];
    setSummary({ ...summary, value });
    setSliderValue(level);
  };

  const updateSpecificValue = (update) => {
    setSummary({ ...summary, value: { value: update.value, units: update.units } });
  };

  const currentSliderSettings = summary ? SummaryTypeSettings[summary.key] : undefined;
  const summaryName = summary ? t(summary?.name) : t('REPORT.CONFIG.INHERIT_FROM_WORKSHEET');
  const slider = summary && _.chain(summaries)
    .filter({ variable: true })
    .some({ key: summary.key })
    .value() &&
    <DataSummarySlider
      label=''
      min={currentSliderSettings.min}
      max={currentSliderSettings.max}
      step={currentSliderSettings.step}
      value={sliderValue}
      onChange={updateSlider}
      updateValueWithUnits={updateSpecificValue}
      showCopyButton={false}
      testId="report-summary-slider"
      summary={{
        value: sliderValue,
        type: summary.key,
        discreteUnits: summary.value,
        isSlider: true
      }}
    />;

  const summaryOptions = _.concat([<DropdownItem href='#' onClick={() => setSummary(undefined)} key="from-worksheet">
    {t('REPORT.CONFIG.INHERIT_FROM_WORKSHEET')}
  </DropdownItem>], _.map(summaries, summary => <DropdownItem href='#' key={summary.key}
      onClick={() => {
        setSummary(summary);
        setSliderValue(SummaryTypeSettings[summary.key].min);
      }}>
      {t(summary.name)}
    </DropdownItem>
  ));

  const dropdown =
    <div className="flexRowContainer">
      <label htmlFor="summaryDropdown">
        {t('REPORT.CONFIG.SUMMARY')}
      </label>
      <Dropdown id="summaryDropdown"
        onClick={e => e.preventDefault()}
        className="width-maximum">
        <DropdownToggle id="summaryDropdownToggle" data-testid="summaryDropdownToggle"
          variant={null}
          className="width-maximum btn-default">
          {summaryName}
          <span className="caret ml-1" />
        </DropdownToggle>
        <DropdownMenu>
          {summaryOptions}
        </DropdownMenu>
      </Dropdown>
    </div>;

  return (
    <div className='p10'>
      {dropdown}
      {slider}
    </div>
  );
};

export const sqReportContentSummary = angularComponent(reportContentSummaryBindings, ReportContentSummary);
