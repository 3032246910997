import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ReportConfigContentInfo } from '@/hybrid/reportEditor/ReportConfigContentInfo.molecule';
import { ReportTimeZone } from '@/hybrid/reportEditor/ReportTimeZone.atom';
import { ReportConfigMetaRow } from './ReportConfigMetaRow.atom';
import { ReportEditingState, ReportStore } from '@/reportEditor/report.store';
import { DateTimeService } from '@/datetime/dateTime.service';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { ReportActions } from '@/reportEditor/report.actions';
import { ModalService } from '@/services/modal.service';
import { useFlux } from '../core/useFlux.hook';
import { useFluxPath } from '../core/useFluxPath.hook';
import { useInjectedBindings } from '../core/useInjectedBindings.hook';
import timezoneModalTemplate from '@/trendViewer/timezoneModal.html';
import classNames from 'classnames';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';

/**
 * Returns the class for the editing state icon based on the current editing state of the report.
 */
function editingIconClass(editingState: ReportEditingState) {
  const classes = {
    [ReportEditingState.Saving]: 'fa fa-spinner fa-spin text-muted',
    [ReportEditingState.Saved]: 'fa fc fc-circle-check text-muted',
    [ReportEditingState.Stale]: 'fa fc fc-circle_warning text-danger',
    [ReportEditingState.Offline]: 'fa fc fc-circle_warning text-danger'
  };
  return classes[editingState];
}

/**
 * Returns the text describing the current editing state of the report.
 */
function editingStateText(editingState: ReportEditingState) {
  const editStateText = {
    [ReportEditingState.Saving]: 'REPORT.CONFIG.EDIT_SAVING',
    [ReportEditingState.Saved]: 'REPORT.CONFIG.EDIT_SAVED',
    [ReportEditingState.Stale]: 'REPORT.CONFIG.EDIT_STALE',
    [ReportEditingState.Offline]: 'REPORT.CONFIG.EDIT_OFFLINE'
  };
  return editStateText[editingState];
}

function editingStateTextClass(editingState: ReportEditingState) {
  return _.includes([ReportEditingState.Offline, ReportEditingState.Stale], editingState)
    ? 'text-bolder'
    : '';
}

/**
 * Updates information on the status of each piece of Seeq content in the document
 */
function updateContentProgress(status) {
  const totalContent = _.reduce(status, (result, value) => result + value, 0);
  return {
    contentProgress: [{
      key: 'LOADED',
      count: status.loaded,
      percent: 100 * status.loaded / totalContent,
      class: 'success'
    }, {
      key: 'IN_PROGRESS',
      count: status.inProgress,
      percent: 100 * status.inProgress / totalContent,
      class: 'info'
    }, {
      key: 'FAILED',
      count: status.failed,
      percent: 100 * status.failed / totalContent,
      class: 'danger'
    }],
    contentTotal: totalContent,
    contentLoaded: status.loaded,
    contentInError: status.failed,
    isContentLoading: !!status.inProgress
  };
}

/**
 * Returns true if date ranges should not be edited because we are previewing a backup or already updating a date
 * range
 */
function areDateRangesReadOnly(sqReportStore): boolean {
  return sqReportStore.backupPreview || sqReportStore.dateRangeUpdating;
}

/**
 * Triggered when the timezone is clicked.
 */
function onTzClick(sqReportStore, sqReportActions, sqModal) {
  if (areDateRangesReadOnly(sqReportStore)) return;

  if (sqReportStore.hasReportSchedule && sqReportStore.reportSchedule?.background && sqReportStore.hasAutoDateRanges) {
    // Force configuration of timezone via Configure Autoupdate Modal for reports that have a background schedule
    sqReportActions.setShowConfigureAutoUpdateModal(true);
  } else {
    sqReportActions.setShowConfigureAutoUpdateModal(false);
    sqModal.safeOpen({
      animation: true,
      controller: 'TimezoneModalCtrl',
      controllerAs: 'ctrl',
      template: timezoneModalTemplate,
      size: 'sm',
      resolve: {
        options: () => ({
          userFallback: false
        })
      }
    });
  }
}

/**
 * Updates any content to match the time zone information selected on this report
 */
function updateContentWithTimeZone(sqReportActions, timezone?) {
  sqReportActions.updateTimezone(timezone);
}

// create the container for the reportConfigMeta
const reportConfigMetaBindings = bindingsDefinition({
  isCKEditor: prop.optional<boolean>(),
  sqReportStore: injected<ReportStore>(),
  sqDateTime: injected<DateTimeService>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqReportContent: injected<ReportContentService>(),
  sqReportActions: injected<ReportActions>(),
  sqModal: injected<ModalService>()
});

export const ReportConfigMeta: SeeqComponent<typeof reportConfigMetaBindings> = (props) => {
  const {
    sqReportStore,
    sqDateTime,
    sqWorksheetStore,
    sqReportContent,
    sqReportActions,
    sqModal
  } = useInjectedBindings(reportConfigMetaBindings);
  const { isCKEditor = false } = props;

  // force the props to be updated so we can get the new name
  const timezoneFixed = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.timezoneFixed);
  const timezone = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.timezone);
  const timezoneName = timezone?.name;

  // force an update when the contentProgress has changed
  useFlux(sqReportStore);

  const initialLoad = useRef(false);
  useEffect(() => {
    if (initialLoad.current) {
      updateContentWithTimeZone(sqReportActions, timezoneFixed ? timezone : undefined);
    } else {
      initialLoad.current = true;
    }
  }, [timezoneFixed, timezone]);

  const editingState: ReportEditingState = sqReportStore.editingState;
  const { name: createdByName } = sqReportStore.createdBy || { name: '' };
  const createdAt: string = sqDateTime.formatTime(sqReportStore.createdAt, sqWorksheetStore.timezone);
  const updatedAt: string = sqDateTime
    .formatTime(sqReportStore.updatedAt, sqWorksheetStore.timezone);
  const onTimezoneClick = onTzClick.bind(null, sqReportStore, sqReportActions, sqModal);
  const contentProgressInfo = updateContentProgress(sqReportContent.getAllContentStatus());

  const { t } = useTranslation();

  return <div className="flexRowContainer mb15">
    <ReportConfigMetaRow label={t('REPORT.CONFIG.CREATOR')} value={createdByName} />
    <ReportConfigMetaRow label={t('REPORT.CONFIG.CREATED')} value={createdAt} />
    <ReportConfigMetaRow label={t('REPORT.CONFIG.UPDATED')} value={updatedAt} />
    <ReportConfigMetaRow
      label={t('REPORT.CONFIG.EDITING_STATE')}
      extraValueClasses={classNames('specSaveStatus', editingStateTextClass(editingState))}>
      <i className={classNames('pr5', editingIconClass(editingState))} />
      {t(editingStateText(editingState))}
    </ReportConfigMetaRow>

    <ReportConfigMetaRow label={t('REPORT.CONFIG.CONTENT')} extraValueClasses="flexFill">
      <ReportConfigContentInfo
        isCKEditor={isCKEditor}
        showBulkEditModal={() => sqReportActions.setShowBulkEditModal(true)}
        refreshErrorContent={() => sqReportContent.refreshAllContent(true)}
        contentTotal={contentProgressInfo.contentTotal}
        contentInError={contentProgressInfo.contentInError}
        contentProgress={contentProgressInfo.contentProgress}
        isContentLoading={contentProgressInfo.isContentLoading}
      />
    </ReportConfigMetaRow>

    <ReportConfigMetaRow label={t('REPORT.CONFIG.TIME_ZONE')}>
      <ReportTimeZone
        onTzClick={onTimezoneClick}
        isDisabled={areDateRangesReadOnly(sqReportStore)}
        timezoneFixed={timezoneFixed}
        timezoneName={timezoneName}
      />
    </ReportConfigMetaRow>
  </div>;
};
