import React, { useState, useEffect, useRef } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { Cookies } from 'react-cookie';
import { Button, FormControl, InputGroup, Modal, Alert, Form } from 'react-bootstrap';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { APPSERVER_API_PREFIX, DEBOUNCE } from '@/main/app.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { NotificationsService } from '@/services/notifications.service';
import { UtilitiesService } from '@/services/utilities.service';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { LogsApi } from '@/sdk';

const logSenderModalDialogBindings = bindingsDefinition({
  onClose: prop<() => void>(),
  sqWorkbenchStore: injected<WorkbenchStore>(),
  sqNotifications: injected<NotificationsService>(),
  sqUtilities: injected<UtilitiesService>(),
  sqLogsApi: injected<LogsApi>()
});

const DOWNLOAD_PATH = `${APPSERVER_API_PREFIX}${SeeqNames.API.Logs}/download`;
const COOKIE_ID = 'downloadLogsCookie';

export const LogSenderModal: SeeqComponent<typeof logSenderModalDialogBindings> = ({ onClose }) => {
  const {
    sqWorkbenchStore,
    sqNotifications,
    sqUtilities,
    sqLogsApi
  } = useInjectedBindings(logSenderModalDialogBindings);

  const cookies = new Cookies();
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(true);
  const [busy, setBusy] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>(
    `${sqWorkbenchStore.currentUser.firstName} ${sqWorkbenchStore.currentUser.lastName}`);
  const [userEmail, setUserEmail] = useState<string>(sqWorkbenchStore.currentUser.email);
  const [description, setDescription] = useState<string>('');
  const [showAlert, setShowAlert] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setValidated(!formRef.current.checkValidity());
  }, [userName, userEmail, description]);

  const closeModal = () => {
    setShow(false);
    onClose();
  };

  /**
   * Requests that appserver zip and transmit the logs to Seeq.
   */
  const sendLogs = () => {
    if (!busy) {
      setBusy(true);
      setShowAlert('');
      sqLogsApi.sendLogs({ name: userName, email: userEmail, description }).then(() => {
          sqNotifications.successTranslate('LOGS.SENT');
          closeModal();
        })
        .catch((e) => {
          setShowAlert('danger');
          sqNotifications.apiError(e, { skipRedaction: true, displayForbidden: true });
        })
        .finally(() => {
          setBusy(false);
        });
    }
  };

  /**
   * Downloads the zipped logs from appserver by setting the window's href to download the logs in place. It passes
   * down a tag as part of the download, checking every second for that tag to in the cookies, at which point it
   * knows the logs are downloaded and stops the loading icon and shows extra details.
   */
  const downloadLogs = () => {
    if (!busy) {
      setBusy(true);
      setShowAlert('');
      const tag = Math.abs(sqUtilities.randomInt()).toString();
      const handlerId = setInterval(() => {
        if (cookies.get(COOKIE_ID) === tag) {
          setBusy(false);
          setShowAlert('success');
          clearInterval(handlerId);
        }
      }, DEBOUNCE.LONG);
      window.location.href = `${DOWNLOAD_PATH}?${COOKIE_ID}=${tag}`;
    }
  };

  return (
    <Modal show={show} onHide={closeModal} animation={false} data-testid='logSenderModal'>
      <Modal.Header closeButton={true}>
        <Modal.Title>{t('SUPPORT_REQUEST')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form name='ctrl.form' validated={validated} ref={formRef}>
          <div className='mb10'>
            <Form.Control
              data-testid='user_name'
              autoComplete='off'
              type='text'
              name='userName'
              className='width-maximum'
              value={userName}
              onChange={e => setUserName(e.target.value)}
              placeholder={t('USER.NAME')}
              required={true}
            />
            <Form.Control.Feedback type="invalid">
              {t('INVALID_NAME')}
            </Form.Control.Feedback>
          </div>
          <div className='mb10'>
            <Form.Group>
              <FormControl
                data-testid='user_email'
                autoComplete='off'
                type='email'
                name='userEmail'
                className='width-maximum'
                value={userEmail}
                onChange={e => setUserEmail(e.target.value)}
                placeholder={t('USER.EMAIL')}
                required={true}
              />
              <Form.Control.Feedback type="invalid">
                {t('USER.EMAIL_INVALID')}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className='mb10'>
            <Form.Group>
              <FormControl
                data-testid='description'
                autoComplete='off'
                as='textarea'
                name='description'
                className='width-maximum'
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder={t('LOGS.DESCRIPTION')}
                required={true}
              />
            </Form.Group>
          </div>
          <Alert
            className={`sq-alert-${showAlert}`}
            variant={showAlert}
            show={showAlert.length > 0}
            dismissible={false}>
            {showAlert === 'success' && (
              <div>
                <span>{t('LOGS.DOWNLOAD_SUCCESS_PART_1')} </span>
                <Alert.Link
                  href={`https://www.seeq.com/support?form[Email]=${userEmail}&form[Name]=${userName}&form[Message]=${description}`}
                  target='_blank'> {t('LOGS.DOWNLOAD_SUCCESS_PART_2')}</Alert.Link>
                <span>{t('LOGS.DOWNLOAD_SUCCESS_PART_3')}</span>
              </div>)}
            {showAlert === 'danger' && <span>t('LOGS.SEND_FAILED')</span>}
          </Alert>
          <div className='text-center mt10'>
            <InputGroup>
              <Button
                className='btn btn-default mr15'
                data-testid='download_logs_button'
                disabled={busy}
                onClick={downloadLogs}>
                <IconWithSpinner icon="fa-download mr5" large={true} spinning={busy} white={true} />
                <span>{t('LOGS.DOWNLOAD')}</span>
              </Button>
              <Button
                className='btn btn-default'
                data-testid='send_logs_button'
                disabled={busy || validated}
                onClick={sendLogs}>{t('LOGS.SEND')}</Button>
            </InputGroup>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
