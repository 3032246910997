import angular from 'angular';
import template from './progress.component.html';
import { ProgressCtrl } from './progress.controller';

/*
 * This component renders the progress bars used by the request details pop-over.
 */
angular.module('Sq.TrendViewer').component('sqProgressBar', {
  template,
  controller: ProgressCtrl,
  bindings: {
    informationString: '<', // raw string with information to be displayed
    existingColors: '<?', // colors that have already been assigned (this value is passed as color assignments to
    // the second component)
    tooltipLookup: '=', // Object[] that contains the information to display tooltips with values from more than one bar
    displayTime: '<', // true if the values are time based and should be formatted as such
    labelKey: '<', // translation key for the description
    legend: '=', // legend
    colorAssignment: '=?', // colors that are assigned and used by this component. only provided to  the second
    // instance of the progress bar to avoid conflicting colors
    getTooltipFn: '&' // function that prepares the tooltip
  }
});
