import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { FormulaDocSummaryOutputV1 } from '@/sdk';
import { FormulaErrorInterface } from '@/hybrid/formula/FormulaEditor.molecule';
import { FormulaErrorMessage } from '@/hybrid/formula/FormulaErrorMessage';
import { findAtLeastOneChild, mapCalculationParamsToAssetChild } from '@/hybrid/assetGroupEditor/assetGroup.utilities';

const LETTERS = _.map(_.range('a'.charCodeAt(0), 'z'.charCodeAt(0) + 1), _.ary(String.fromCharCode, 1)) as string[];

export function getNextFormulaIdentifier(parameters) {
  let identifier;
  let i = 0;

  while (!identifier) {
    identifier = getShortIdentifier(i++);
    if (_.some(parameters, ['identifier', identifier])) {
      identifier = undefined;
    }
  }

  return identifier;
}

/**
 * Computes a short variable identifier given an index number.
 *
 * @param {Number} num - The sequential number of the variable, used to avoid duplicating identifiers.
 * @returns {String} The variable name
 */
function getShortIdentifier(num: number): string {
  const index = num % LETTERS.length;
  const repeat = Math.floor(num / LETTERS.length) + 1;
  return _.repeat(LETTERS[index], repeat);
}

/**
 * Returns the start, middle and end position for the term/word under/by the cursor.
 * Example:
 *   Month.JA
 *   ^ Start
 *        ^ Middle
 *          ^ End
 *
 * @param {CodeMirror} editor - Formula editor
 * @param {Boolean} matchStartingParenthesis - Whether to match any starting parenthesis after the term
 * @param {number} [cursorPosition] - Index on current line to use as the cursor position, rather than the
 *   editor's current cursor position
 * @returns {Object} Object containing the start, middle and end position of the term under/by the cursor
 */
function getCursorTermStartMiddleAndEnd(editor, matchStartingParenthesis, cursorPosition?) {
  const editorPosition = getEditorPositionObject(editor);
  const line = editorPosition.line;
  if (_.isUndefined(cursorPosition)) {
    cursorPosition = editorPosition.cursor.ch;
  }

  let start;
  let middle = cursorPosition;
  let end = cursorPosition;

  // Move middle position to encompass entire term before cursor
  while (middle && /\w/.test(line.charAt(middle - 1))) {
    --middle;
  }

  // Move middle position to encompass any . or $ before term
  while (middle && /[\.\$]/.test(line.charAt(middle - 1))) {
    --middle;
  }

  // Move start position to encompass entire term before '.' or '$'
  start = middle;
  while (start && /[\$\w]/.test(line.charAt(start - 1))) {
    --start;
  }

  // Move end position to encompass entire term after/by cursor
  while (end < line.length && /\w/.test(line.charAt(end))) {
    ++end;
  }

  if (matchStartingParenthesis) {
    // Move end position to encompass any parenthesis after term
    while (end < line.length && /\(/.test(line.charAt(end))) {
      ++end;
    }
  }

  return {
    start,
    middle,
    end
  };
}

export function getEditorPositionObject(editor) {
  const cursor = editor.getCursor();

  return {
    cursor,
    line: editor.getLine(cursor.line),
    ch: cursor.ch
  };
}

export function getAutocompleteHints(editor, constants, operators, parameters) {
  if (!editor) return;
  const editorPosition = getEditorPositionObject(editor);
  const { start, middle, end } = getCursorTermStartMiddleAndEnd(editor, true);
  let suggestionList = [];
  const containsSuggestions = [];
  const prefix = editorPosition.line.slice(start, middle).toLowerCase();
  let term = editorPosition.line.slice(middle, end).toLowerCase();
  let startReplacementPos = start;

  // Attempt constant autocompletion first (based on the term before the '.')
  if (_.startsWith(term, '.')) {
    _.forEach(constants, (constant) => {
      if (_.startsWith(constant, _.toUpper(prefix + term))) {
        suggestionList.push(constant);
      }
      const constantSuffix = constant.split('.')[1];
      if (_.startsWith(constant, _.toUpper(prefix)) && prefix.length > 0 &&
        constantSuffix.includes(_.toUpper(term.substring(1)))) {
        containsSuggestions.push(constant);
      }
    });
  }

  if (_.size(suggestionList) === 0 && _.size(containsSuggestions) === 0) {
    // Fall back to function and variable autocompletion if no constants matched
    startReplacementPos = middle;
    if (_.startsWith(term, '.')) {
      // Function autocompletion
      term = term.substring(1);  // Remove '.' from operator
      _.forEach(operators, (operator: any) => {
        // It's an operator (contains ()) and it matches our search term, so add it as a suggestion
        if (_.startsWith(operator.name.toLowerCase(), term) && operator.name.indexOf('()') !== -1) {
          suggestionList.push('.' + operator.name.substring(0, operator.name.indexOf('()') + 1));
        }
        if (term.length > 0 && operator.name.toLowerCase().includes(term) && operator.name.indexOf('()') !== -1) {
          containsSuggestions.push('.' + operator.name.substring(0, operator.name.indexOf('()') + 1));
        }
      });

    } else if (_.startsWith(term, '$')) {
      // Variable autocompletion
      term = term.substring(1);  // Remove '$' from variable name
      _.forEach(parameters, (item: any) => {
        if (_.startsWith(item.identifier.toLowerCase(), term)) {
          suggestionList.push('$' + item.identifier);
        }
        if (term.length > 0 && item.identifier.toLowerCase().includes(term)) {
          containsSuggestions.push('$' + item.identifier);
        }
      });
    }
  }

  suggestionList = suggestionList.concat(containsSuggestions);

  return {
    list: _.uniq(suggestionList),
    from: {
      line: editorPosition.cursor.line,
      ch: startReplacementPos
    },
    to: {
      line: editorPosition.cursor.line,
      ch: end
    }
  };
}

export function getContextHelp(editor, operators) {
  if (!editor) return;

  const editorPosition = getEditorPositionObject(editor);
  const startMiddleAndEnd = getCursorTermStartMiddleAndEnd(editor, false);
  let term = editorPosition.line.substring(startMiddleAndEnd.middle, startMiddleAndEnd.end);

  // If term is empty, search backwards until we find a term to use
  if (term === '') {
    let position = startMiddleAndEnd.middle;
    let cursorTermPositions;
    while (term === '' && position >= 0) {
      position = position - 1;
      cursorTermPositions = getCursorTermStartMiddleAndEnd(editor, false, position);
      term = editorPosition.line.substring(cursorTermPositions.middle, cursorTermPositions.end);
    }
  }

  if (_.startsWith(term, '.')) {
    term = term.substring(1); // Remove '.' from operator
  }

  const searchTerm = term.toLowerCase() + '()';
  const operatorsSuggestions = operators as FormulaDocSummaryOutputV1[];
  const matches = _.filter(operatorsSuggestions, o => o.name.toLowerCase().startsWith(searchTerm));
  return _.size(matches) > 1 ? term : matches;
}

export function getErrorMessage(message: string, clearError) {
  const error = document.createElement('div'); // this needs to be a node
  ReactDOM.render(<FormulaErrorMessage message={message} clearError={clearError} />, error);
  return error;
}

export function runFormula(sqFormulasApi, formula: string, parameters: string[]) {
  return sqFormulasApi.compileFormula({
      formula,
      parameters
    })
    .catch(({ data }) => {
      if (_.has(data, 'errors')) {
        const formError: FormulaErrorInterface = _.pick(_.first(data.errors), ['message', 'column', 'line']);
        const message = `${_.repeat(' ', formError.column - 1)}⬆\n${_.escape(
          formError.message.replace(/(.*), line=\\d+, column=\\d+.*/, '$1'))}`;
        return Promise.reject([{ ...formError, message }]);
      } else if (_.has(data, 'statusMessage')) {
        return Promise.reject([{ message: data.statusMessage, column: -1, line: -1 }]);
      }
    });
}

export function validateAssetGroupFormula(sqFormulasApi, assets, formula, parameters) {
  // before we run the formula we need to validate that the formula can execute - so we need to use inputs of an
  // asset and use its children to just run the formula; we need to find a valid child asset that can be used for
  // this validation - we do not have to use children from only one asset, we can just use any child that was assigned.
  // this prevents un-necessary errors if an asset group has "holes" (does not show green checkmarks for every cell)
  const children = _.chain(parameters)
    .map(parameter => parameter.item.name)
    .map(columnName => findAtLeastOneChild(assets, columnName))
    .value();

  const formulaParams = mapCalculationParamsToAssetChild({ children }, parameters)?.mappings;
  const formulaParamsForFormulaRun = _.map(formulaParams, param => `${param.name}=${param.id}`);
  return runFormula(
    sqFormulasApi,
    formula,
    formulaParamsForFormulaRun
  );
}

export function validateFormula(sqFormulasApi, formula, parameters) {
  const formulaParamsForFormulaRun = _.map(parameters, param => `${param.identifier}=${param.item.id}`);
  return runFormula(
    sqFormulasApi,
    formula,
    formulaParamsForFormulaRun
  );
}

export function addTextAtCursor(text, editor) {
  if (editor) {
    const selection = editor.getSelection();

    if (selection.length > 0) {
      // Replace current selection
      editor.replaceSelection(text);
    } else {
      // Nothing was selected, so insert at current cursor position
      const doc = editor.getDoc();
      const cursor = doc.getCursor();

      doc.replaceRange(text, { line: cursor.line, ch: cursor.ch });
    }
    getEditorPositionObject(editor);
    editor.focus();
  }
}



