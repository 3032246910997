import angular from 'angular';
import template from './investigate.directive.html';

angular.module('Sq.Investigate').directive('sqInvestigateWidget', sqInvestigateWidget);

function sqInvestigateWidget() {
  const directive = {
    controller: 'InvestigateCtrl',
    controllerAs: 'ctrl',
    restrict: 'EA',
    scope: {},
    template
  };

  return directive;
}
