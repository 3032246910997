import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { AgentsApi, SystemApi } from '@/sdk';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { NotificationsService } from '@/services/notifications.service';


const updateVersionModalBindings = bindingsDefinition({
  sqSystemApi: injected<SystemApi>(),
  sqAgentsApi: injected<AgentsApi>(),
  sqNotifications: injected<NotificationsService>(),
  onClose: prop<() => void>()
});

export const UpdateVersionModal: SeeqComponent<typeof updateVersionModalBindings> = (props) => {
  const { sqSystemApi, sqNotifications, sqAgentsApi } = useInjectedBindings(updateVersionModalBindings);
  const { t } = useTranslation();
  const { onClose } = props;
  const [runVersion, setRunVersion] = useState(undefined);
  const [isStaged, setIsStaged] = useState(false);
  const regex = /R\d+\.\d+\.\d+(?:-v\d{12})?(?:-[A-Z]+)?/g;

  useEffect(() => {
      sqAgentsApi.configuredDirectives().then(({ data }) => {
        setRunVersion(data.stageInstaller.match(regex)?.[0] ?? '');
        setIsStaged(runVersion !== null);
    }).catch(sqNotifications.apiError);
  }, []);

  const updateToStageVersion = (runVersion) => {
    if (runVersion !== null && runVersion.length > 0) {
      const directiveInput = {
        directiveName: 'RunVersion',
        version: runVersion
      };
      const requestBody = {
        configurationOptions: [
          {
            path: 'Features/RemoteAgentUpdates/RunVersion',
            value: runVersion
          }]
      };
      const promises = [];
      promises.push(sqSystemApi.setConfigurationOptions(requestBody));
      promises.push(sqAgentsApi.directiveUpdated(directiveInput));
      Promise.all(promises).then(() => {
        sqNotifications.success(t('ADMIN.AGENTS.SAVE_UPDATE_VERSION'));
        onClose();
      }).catch(sqNotifications.apiError);
    } else {
      sqNotifications.warn(t('ADMIN.AGENTS.NO_VERSION_SET'));
      onClose();
    }
  };

  const formDefinition: FormElement[] = [
  {
    key: 'nameLabel',
    component: 'LabelFormComponent',
    value: 'ADMIN.AGENTS.UPDATE_VERSION_DESC',
    name: 'nameLabel',
    testId: 'labelUpdateVersion'
  },
  {
    key: 'nameLabel2',
    component: 'LabelFormComponent',
    value: 'ADMIN.AGENTS.UPDATE_VERSION_DESC2',
    name: 'nameLabel2',
    testId: 'labelUpdateVersion2'
  },
  {
    key: 'nameLabel3',
    component: 'LabelFormComponent',
    value: 'ADMIN.AGENTS.UPDATE_VERSION_DESC3',
    name: 'nameLabel3',
    testId: 'labelUpdateVersion3'
  }];

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="updateVersionModal"
      className="modalSelectFormComponent">
      <Modal.Header closeButton={true} className="flexColumnContainer flexSpaceBetween">
        <h3>{t('ADMIN.AGENTS.UPDATE_VERSION_MODAL')}</h3>
      </Modal.Header>
      <Modal.Body>
        <div
          className="col-form-label font-weight-bold mr5">
          {isStaged ? t('ADMIN.AGENTS.UPDATE_VERSION_DESC4') : t('ADMIN.AGENTS.UPDATE_VERSION_NOT_STAGED')} {runVersion}
        </div>
        <SimpleSaveFormBuilder
          formDefinition={formDefinition}
          submitBtnLabel={t('ADMIN.AGENTS.UPDATE')}
          submitFn={() => updateToStageVersion(runVersion)}
          closeFn={onClose} />
      </Modal.Body>
    </Modal>);
};
