import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { ManualSignalActions } from '@/hybrid/tools/manualSignal/manualSignal.actions';
import { EditableText } from '@/hybrid/core/EditableText.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { DateTimeEntry } from '@/hybrid/core/DateTimeEntry.atom';
import { ManualSignalStore } from '@/hybrid/tools/manualSignal/manualSignal.store';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { FormError } from '@/hybrid/core/FormError.atom';
import { STRING_UOM } from '@/main/app.constants';
import { SAMPLE_VALUE_ERROR } from '@/hybrid/tools/manualSignal/manualSignal.module';

const sampleInputBindings = bindingsDefinition({
  testId: prop<string>(),
  sample: prop.optional<{ dateTime: any, value: any, id: string }>(),
  timezone: prop<{ name: string, displayName: string, offset: string, offsetMinutes: number }>(),
  sqManualSignalActions: injected<ManualSignalActions>(),
  sqManualSignalStore: injected<ManualSignalStore>()
});

export const SampleInput: SeeqComponent<typeof sampleInputBindings> = ({ sample, timezone, testId }) => {
    const { sqManualSignalActions, sqManualSignalStore } = useInjectedBindings(sampleInputBindings);
    const { t } = useTranslation();

    const samples = useFluxPath(sqManualSignalStore, () => sqManualSignalStore.samples);
    const isStringUom = useFluxPath(sqManualSignalStore, () => sqManualSignalStore.uom === STRING_UOM);
    const isStringSignal = isStringUom || _.isNaN(_.toNumber(sqManualSignalStore.samples[0]?.value));
    const [dateTime, setDateTime] = useState(
      sample?.dateTime ? moment.utc(sample.dateTime) : moment.utc().startOf('minute'));
    const [value, setValue] = useState(sample?.value ?? '');
    const [valueError, setValueError] = useState(undefined);
    const [dateError, setDateError] = useState(false);

    useEffect(() => {
      const newDateTimeValue = dateTime.valueOf();
      const sampleId = sample?.id;
      const hasError = _.some(samples, sample => sample.dateTime === newDateTimeValue && sample.id !== sampleId);
      setDateError(hasError && !_.isEmpty(value));
    }, [samples, dateTime, value]);

    useEffect(() => {
      const hasValueTypeError = !isStringSignal && _.isNaN(_.toNumber(value));
      setValueError(hasValueTypeError ? SAMPLE_VALUE_ERROR.INVALID_TYPE : undefined);
    }, [value, isStringSignal, samples, isStringUom]);

    const resetState = () => {
      setValue('');
      setDateTime(moment.utc().startOf('minute'));
      setValueError(undefined);
      setDateError(false);
    };

    const addSample = () => {
      if (_.isEmpty(value)) {
        setValueError(SAMPLE_VALUE_ERROR.EMPTY);
        return;
      }
      if (dateError || !_.isUndefined(valueError)) return;

      sqManualSignalActions.addSample({ dateTime: dateTime.valueOf(), value });
      resetState();
    };

    const updateSample = () => {
      if (dateError || !_.isUndefined(valueError)) return;

      sqManualSignalActions.updateSample({ id: sample.id, dateTime: dateTime.valueOf(), value });
      sqManualSignalActions.setEditingSampleId(undefined);
      resetState();
    };

    return (
      <div className="sampleInput flexRowContainer pt10 pb10" data-testid={testId}>
        <div className="flexColumnContainer flexFill text-bold pl10 pr10 text-center">
          <div className="flexFillEqually">{t('MANUAL_SIGNAL.DATE_TIME')}</div>
          <div className="flexFillEqually">{t('MANUAL_SIGNAL.VALUE')}</div>
        </div>
        <div className="flexColumnContainer flexFill sq-text-primary pl10 pr10 text-center">
          <div className="flexFillEqually flexSelfCenter">
            <DateTimeEntry
              testId="manualSignalDateTimeEntry"
              hideTimezone={true}
              fieldIdentifier="MANUAL_SIGNAL_TOOL_DATE_TIME"
              trackCategory="Workbench_Tool"
              trackAction="Manual_Signal"
              trackInformation="Date time changed"
              textExtraClassNames={classNames({ 'sq-text-danger': dateError })}
              updateDate={setDateTime}
              timezone={timezone}
              date={dateTime} />
          </div>
          <div className="flexFillEqually flexSelfCenter">
            <div className="nowrap">
              <EditableText
                value={_.isEmpty(value) ? t('MANUAL_SIGNAL.VALUE_PLACEHOLDER') : value}
                inputClasses="text-center"
                testId={sample?.id ? `sampleValue-${sample.id}` : 'newSampleValue'}
                maxDisplayChars={15}
                onUpdate={(newValue) => {
                  setValueError(_.isEmpty(newValue) ? SAMPLE_VALUE_ERROR.EMPTY : undefined);
                  setValue(newValue);
                }}
                textClasses={classNames('pt1 pb1', { 'text-italic': _.isEmpty(value) },
                  { 'sq-text-danger': !_.isUndefined(valueError) })} />
            </div>
          </div>
        </div>

        {sample?.id
          ? <div className="flexJustifyCenter flexColumnContainer mt5">
            <TextButton
              label="CANCEL"
              variant="outline"
              extraClassNames="inlineBlock mr10"
              onClick={() => sqManualSignalActions.setEditingSampleId(undefined)} />
            <TextButton
              testId="saveSampleButton"
              label="SAVE"
              variant="theme"
              disabled={_.isEmpty(value)}
              extraClassNames="inlineBlock"
              onClick={updateSample} />
          </div>
          : <div className="text-center mt5">
            <TextButton
              testId="addSampleButton"
              label="MANUAL_SIGNAL.ADD_SAMPLE"
              variant="outline"
              extraClassNames="inlineBlock"
              onClick={addSample} />
          </div>}

        {dateError &&
        <FormError extraClassNames="mt5 ml10 mr1 mb0" errorText="MANUAL_SIGNAL.DATE_TIME_ERROR" />}

        {valueError === SAMPLE_VALUE_ERROR.INVALID_TYPE &&
        <FormError extraClassNames="mt5 ml10 mr1 mb0" errorText="MANUAL_SIGNAL.VALUE_TYPE_ERROR" />}
      </div>
    );
  }
;
