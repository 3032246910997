import _ from 'lodash';
import angular from 'angular';
import { FftStore } from '@/hybrid/tools/frequencyAnalysis/fft.store';
import { DateTimeService } from '@/datetime/dateTime.service';
import { TrendActions } from '@/trendData/trend.actions';
import { NUMBER_CONVERSIONS } from '@/main/app.constants';
import { FormulaService } from '@/services/formula.service';

angular.module('Sq.Investigate')
  .service('sqFftActions', sqFftActions);

export type FftActions = ReturnType<typeof sqFftActions>;

function sqFftActions(
  flux: ng.IFluxService,
  sqFftStore: FftStore,
  sqDateTime: DateTimeService,
  sqTrendActions: TrendActions,
  sqFormula: FormulaService
) {
  const service = {
    createFormula,
    setRateType,
    setRate,
    setOutputUnits,
    setHighPass,
    setLowPass,
    setUseHighPass,
    setUseLowPass,
    fetchRateMin
  };

  return service;

  /**
   * Sets the rate type for sampling
   *
   * @param {Boolean} rateType - the sampling rate type, period: true, frequency: false
   */
  function setRateType(rateType) {
    flux.dispatch('FFT_SET_RATE_TYPE', { rateType });
  }

  /**
   * Sets the rate for sampling
   *
   * @param {Object} rate - the sampling rate
   */
  function setRate(rate) {
    flux.dispatch('FFT_SET_RATE', { rate });
  }

  /**
   * Sets the output units
   *
   * @param {String} outputUnits - the output units
   */
  function setOutputUnits(outputUnits) {
    flux.dispatch('FFT_SET_OUTPUT_UNITS', { outputUnits });
  }

  /**
   * Sets the high pass filter
   *
   * @param {Object} highPass - the high pass filter
   */
  function setHighPass(highPass) {
    flux.dispatch('FFT_SET_FILTER_HIGH', { highPass });
  }

  /**
   * Sets the low pass filter
   *
   * @param {Object} lowPass - the low pass filter
   */
  function setLowPass(lowPass) {
    flux.dispatch('FFT_SET_FILTER_LOW', { lowPass });
  }

  /**
   * Sets the use of the high pass filter
   *
   * @param {Boolean} useHighPass - the flag denoting the use of the high pass filter
   */
  function setUseHighPass(useHighPass) {
    flux.dispatch('FFT_SET_USE_FILTER_HIGH', { useHighPass });
  }

  /**
   * Sets the use of the low pass filter
   *
   * @param {Boolean} useLowPass - the flag denoting the use of the low pass filter
   */
  function setUseLowPass(useLowPass) {
    flux.dispatch('FFT_SET_USE_FILTER_LOW', { useLowPass });
  }

  /**
   * This function creates the formula that calculates the FFT.
   *
   * @returns Object {{formula: string, parameters: Array}} defining the formula and the required parameters.
   */
  function createFormula() {
    const rate = sqFftStore.rate.value + sqFftStore.rate.units;

    let fftFormula = `.fft($viewCapsule, ${rate}, "${sqFftStore.outputUnits}")`;

    if (!_.isUndefined(sqFftStore.highPass.value)) {
      const highPass = sqFftStore.highPass.value + sqFftStore.highPass.units;

      if (sqDateTime.isFrequency(sqFftStore.highPass.units)) {
        fftFormula = fftFormula + `.keepRows("frequency", isGreaterThan(${highPass}))`;

      } else {
        fftFormula = fftFormula + `.keepRows("frequency", isLessThan(${highPass}))`;
      }
    }

    if (!_.isUndefined(sqFftStore.lowPass.value)) {
      const lowPass = sqFftStore.lowPass.value + sqFftStore.lowPass.units;

      if (sqDateTime.isFrequency(sqFftStore.lowPass.units)) {
        fftFormula = fftFormula + `.keepRows("frequency", isLessThan(${lowPass}))`;
      } else {
        fftFormula = fftFormula + `.keepRows("frequency", isGreaterThan(${lowPass}))`;
      }
    }

    const formula = `$signalToAggregate` + fftFormula + `.fftGroup($numPixels)`;

    const parameters = [];
    parameters.push({ unbound: false, name: 'signalToAggregate', id: sqFftStore.signalToAggregate.id });

    return { formula, parameters };
  }

  /**
   * Performs all necessary steps to estimate sample rate
   *
   * @param {String} formula - the formula to use to get the estimated rate
   * @param {Object} parameters - Map of parameter name to ID that are the top-level parameters used in the formula
   * @return {Promise} - a promise that resolves when the cleanse completes.
   */
  function fetchRateMin(formula, parameters) {
    const cancellationGroup = 'estimateSampleRate';
    return sqFormula.computeScalar({
      formula,
      parameters,
      cancellationGroup
    }).then(function(result) {
      return {
        // Convert the seconds we get from the backend to ms
        value: result.value * NUMBER_CONVERSIONS.MILLISECONDS_PER_SECOND,
        units: 'ms'
      };
    });
  }
}
