import { NumberHelperService } from '@/core/numberHelper.service';
import { TableColumn } from '@/hybrid/core/Table.atom';
import _ from 'lodash';

type ModelTableItemData = {
  columns: TableColumn[];
  items: { id: string; [key: string]: any }[];
};

export type ModelTableData = {
  legend: ModelTableItemData;
  coefficients: ModelTableItemData;
  stats: ModelTableItemData;
};

/**
 * Helper function that returns CSV formatted coefficient data as the clipboard text.
 */
export const getCoefficientClipboardText = (model, translate) => {
  if (model.coefficients.length === 0) {
    return '';
  }

  // Add coefficients
  const { name, coefficient, standardError, pValue } = translate;
  let csvTable = `${[name, coefficient, standardError, pValue].join('\t')}\n`;
  _.forEach(
    model.coefficients,
    ({ name, coefficient, error, pValue }) => {
      csvTable += `${[name, coefficient, error, pValue].join('\t')}\n`;
    }
  );

  // Add legend
  csvTable += `\n${translate.legend}\n`;
  _.forEach(model.legend, (entry: any) => {
    csvTable += `${entry.variable} = ${entry.name}\n`;
  });

  return csvTable;
};

/**
 * Helper function that returns CSV formatted statistics data as the clipboard text.
 */
export const getStatisticsClipboardText = (model, translate) => {
  if (model.stats.length === 0) {
    return '';
  }

  const { name, value } = translate;
  let statsCsvTable = `${[name, value].join('\t')}\n`;
  _.forEach(model.stats, ({ name, value }) => {
    statsCsvTable += `${[name, value].join('\t')}\n`;
  });

  return statsCsvTable;
};

const getColumnProps = ({
  accessor,
  header,
}: Pick<TableColumn, 'accessor' | 'header'>): TableColumn => ({
  accessor,
  header,
  sortable: true,
  cellStyle: {
    maxWidth: '70px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const getModelTableData = (
  model,
  sqNumberHelper: NumberHelperService
): ModelTableData => ({
  legend: {
    columns: [
      getColumnProps({ accessor: 'variable', header: 'Variable' }),
      getColumnProps({ accessor: 'name', header: 'Name' }),
    ],
    items: _.map(model.legend, (entry, index) => ({
      ...entry,
      id: index,
    })),
  },
  coefficients: {
    columns: [
      getColumnProps({ accessor: 'name', header: 'Name' }),
      getColumnProps({ accessor: 'coefficient', header: 'Coefficient' }),
      getColumnProps({ accessor: 'error', header: 'Std. Error' }),
      getColumnProps({ accessor: 'pValue', header: 'P-Value' }),
    ],
    items: _.map(model.coefficients, (entry, index) => ({
      ...entry,
      id: index,
      coefficient: sqNumberHelper.formatNumber(entry.coefficient),
      error: sqNumberHelper.formatNumber(entry.error),
      pValue: sqNumberHelper.formatNumber(entry.pValue),
    })),
  },
  stats: {
    columns: [
      getColumnProps({ accessor: 'name', header: 'Name' }),
      getColumnProps({ accessor: 'value', header: 'Value' }),
    ],
    items: _.map(model.stats, (entry, index) => ({
      ...entry,
      id: index,
      value: sqNumberHelper.isNumeric(entry.value) ? sqNumberHelper.formatNumber(entry.value) : entry.value
    })),
  },
});

export const openTooltipLink = () =>
  window.open(
    'https://telemetry.seeq.com/support-link/wiki/spaces/KB/pages/143163422/Prediction'
  );

export const generateDefaultSortValues = (defaultValue: any) => ({
  legend: defaultValue,
  coefficients: defaultValue,
  stats: defaultValue,
});
