import React, { useEffect, useRef, useState, useCallback } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { ResizableToolPanel } from '@/hybrid/core/ResizableToolPanel.atom';
import { FormulaDocumentation } from '@/hybrid/formula/FormulaDocumentation.organism';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { ShowFormulaHelp } from '@/hybrid/formula/ShowFormulaHelp.atom';
import { FormulaToolActions } from '@/hybrid/tools/formula/formulaTool.actions';
import { FormulasApi } from '@/sdk';
import { addTextAtCursor, validateFormula } from '@/hybrid/formula/formula.utilities';
import { AddParameterBtnAndModal } from '@/hybrid/formula/AddParameterBtnAndModal.atom';
import { NotificationsService } from '@/services/notifications.service';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { MIN_FORMULA_PANEL_HEIGHT, MIN_FORMULA_PANEL_WIDTH } from '@/hybrid/tools/formula/formulaTool.module';
import { FormulaToolStore } from '@/hybrid/tools/formula/formulaTool.store';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';

const formulaToolBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  sqFormulaToolStore: injected<FormulaToolStore>(),
  sqFormulaToolActions: injected<FormulaToolActions>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqWorksheetActions: injected<WorksheetActions>(),
  sqFormulasApi: injected<FormulasApi>(),
  sqNotifications: injected<NotificationsService>()
});

export const FormulaTool: SeeqComponent<typeof formulaToolBindings> = ({}) => {
  const {
    sqInvestigateActions,
    sqFormulaToolStore,
    sqFormulaToolActions,
    sqWorksheetStore,
    sqWorksheetActions,
    sqFormulasApi,
    sqNotifications
  } = useInjectedBindings(formulaToolBindings);

  const {
    id,
    formula,
    parameters,
    originalParameters,
    name,
    helpShown: formulaHelpExpanded
  } = useFlux(sqFormulaToolStore);

  const resizeEnabled = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.resizeEnabled);
  const panelDisplayHeight = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.displayHeight);
  const panelDisplayWidth = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.displayWidth);

  const ref = useRef(null);
  const [color, setColor] = useState('');
  const [constants, setConstants] = useState([]);
  const [operators, setOperators] = useState([]);
  const [formulaErrors, setFormulaErrors] = useState([]);
  const [generalFormulaError, setGeneralFormulaError] = useState('');
  const [editor, setEditor] = useState(undefined);

  const setSearchName = searchName => sqInvestigateActions.setSearchName(TREND_TOOLS.FORMULA, searchName);
  const insertTextInFormula = useCallback((text: string) => addTextAtCursor(text, editor), [editor]);
  const togglePanel = () => sqWorksheetActions.setDisplayResizeEnabled(!sqWorksheetStore.resizeEnabled);

  const addParameter = ({ name, item, identifier }) => {
    if (_.some(parameters as any, { identifier })) {
      sqNotifications.warnTranslate('FORMULA.VARIABLE_UNIQUE');
    } else {
      sqFormulaToolActions.addParameter({ name, identifier, item });
    }
  };

  const onSave = (formula: string) => {
    return validateFormula(sqFormulasApi, formula, parameters)
      .then(() => {
        setFormulaErrors([]);
        return true;
      }).catch((formulaErrors) => {
        setFormulaErrors(formulaErrors);
        return false;
      });
  };

  const saveOrUpdate = () => {
    return onSave(formula)
      .then(valid => valid && sqFormulaToolActions.search(color))
      .catch(e => setGeneralFormulaError(e));
  };

  useEffect(() => {
    sqFormulasApi.getConstantNameList()
      .then(({ data }) => setConstants(data));

    sqFormulasApi.getFormulaDocs({})
      .then(({ data }) => setOperators(data.functions));
  }, []);

  ///////////////// Form Fields ////////////////////////
  const formDataSetup: FormElement[] = [{
    component: 'FormRow',
    name: 'formulaSearchNameRow',
    components: [
      {
        component: 'SearchTitleFormComponent',
        name: 'profileSearchName',
        value: name,
        onChange: setSearchName,
        id,
        onColorChange: setColor,
        searchIconClass: 'fc-formula',
        defaultName: 'FORMULA.HEADER',
        extraClassNames: resizeEnabled ? 'mtn10 mb0' : 'mb0'
      }, {
        component: 'DisplayOnlyFormElementWrapper',
        name: 'expandIcon',
        includeIf: !resizeEnabled,
        extraClassNames: 'width-30',
        children: <Icon
          testId="expandFormulaPanel"
          icon="fc-expand-open"
          large={true}
          type="theme"
          onClick={togglePanel}
          extraClassNames="cursorPointer sq-text-primary pt25" />
      },
      {
        component: 'DisplayOnlyFormElementWrapper',
        name: 'showHelp',
        includeIf: resizeEnabled && !formulaHelpExpanded,
        extraClassNames: 'formulaShowHelp',
        children: <ShowFormulaHelp onClick={sqFormulaToolActions.toggleHelp} />
      }
    ]
  }, {
    component: 'FormGroup',
    name: 'variableGroupWrapper',
    displayNumber: true,
    extraClassNames: 'mt-0',
    components: [
      {
        component: 'FormRow',
        name: 'variableFormRowHolder',
        components: [
          {
            component: 'LabelFormComponent',
            value: 'FORMULA.VARIABLES',
            name: 'variablesHeader'
          }, {
            component: 'DisplayOnlyFormElementWrapper',
            extraClassNames: 'flexColumnContainer flexFill mb0',
            children: <>
              <div className="flexFill" />
              <AddParameterBtnAndModal
                tooltip="FORMULA.TOOLTIP_SEARCH"
                parameters={parameters}
                addParameter={addParameter} />
              <HoverTooltip text="FORMULA.TOOLTIP_DETAILS">
                <div>
                  <TextButton
                    icon="fa-area-chart"
                    extraClassNames="ml8"
                    size="sm"
                    label="DETAILS"
                    onClick={sqFormulaToolActions.addDetailsPaneParameters} />
                </div>
              </HoverTooltip>
            </>,
            name: 'buttonsWrapper'
          }
        ]
      }, {
        component: 'FormulaVariablesFormComponent',
        name: 'parametersTable',
        resizeEnabled,
        parameters,
        additionalItems: originalParameters,
        updateParameterCallback: sqFormulaToolActions.updateParameter,
        removeParameterCallback: sqFormulaToolActions.removeParameter,
        insertParameter: insertTextInFormula,
        onItemSelect: sqFormulaToolActions.selectParameter,
        value: ''
      }
    ]
  }, {
    component: 'FormGroup',
    name: 'formulaEditorGroupWrapper',
    displayNumber: true,
    extraClassNames: 'flexFillOverflow min-height-130 mt-0',
    components: [{
      component: 'LabelFormComponent',
      name: 'formulaHeader',
      value: 'FORMULA.HEADER',
      extraClassNames: 'mb-0'
    }, {
      component: 'FormulaEditorFormComponent',
      name: 'formulaEditorFormComponent',
      value: _.isEmpty(formulaErrors) + '',
      formula,
      constants,
      operators,
      showLineNumbers: sqWorksheetStore.resizeEnabled,
      exposeEditorToParent: setEditor,
      exposeFormulaToParent: sqFormulaToolActions.setFormula,
      parameters,
      onSave,
      formulaErrors,
      generalFormulaError,
      clearGeneralFormulaError: () => setGeneralFormulaError(''),
      setFormulaErrors,
      readOnly: false
    }]
  }];

  const renderToolPanel = (wrapInPanel: boolean) => (
    <ToolPanelFormBuilder
      wrapInPanel={wrapInPanel}
      extraClassNamesUnwrapped="flexRowContainer flexFill"
      formDefinition={formDataSetup}
      submitFn={saveOrUpdate}
      closeFn={sqInvestigateActions.close}
      toolName={TREND_TOOLS.FORMULA}
      toolId={TREND_TOOLS.FORMULA}
      submitBtnId='formulaButton'
    />);

  return resizeEnabled ?
    <div className="positionAbsolute" id="formulaContainer" ref={ref}>
      <ResizableToolPanel
        refLink={ref}
        width={panelDisplayWidth}
        height={panelDisplayHeight}
        minimumWidth={MIN_FORMULA_PANEL_WIDTH}
        minimumHeight={MIN_FORMULA_PANEL_HEIGHT}
        setHeight={sqWorksheetActions.setDisplayHeight}
        setWidth={sqWorksheetActions.setDisplayWidth}>
        <div className="flexColumnContainer flexFill">
          <div className="flexFill">
            {renderToolPanel(false)}
          </div>
          {formulaHelpExpanded &&
          <div className="flexFill max-width-400 min-width-400 formulaHelp" data-testid="formulaHelp">
            <FormulaDocumentation
              formulaHelpToggleFunction={sqFormulaToolActions.toggleHelp}
              insertFormulaSnippet={insertTextInFormula}
              operators={operators}
              functions={_.filter(operators, operator => operator.name.indexOf('()') !== -1)} />
          </div>
          }
        </div>
        <Icon
          tooltip="FORMULA.PANEL_COLLAPSE"
          icon="fc-expand-close"
          large={true}
          type="theme"
          onClick={togglePanel}
          testId="collapseFormulaPanel"
          extraClassNames="positionAbsolute cursorPointer sq-text-primary ml15 pt10 right-5" />
      </ResizableToolPanel>
    </div>
    :
    renderToolPanel(true);
};

export const sqFormulaTool = angularComponent(formulaToolBindings, FormulaTool);
