import React from 'react';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import classNames from 'classnames';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import _ from 'lodash';
import { Icon } from '@/hybrid/core/Icon.atom';

const paginatorBindings = bindingsDefinition({
  gotoPage: prop<(number) => void>(),
  pageCount: prop<number>(),
  pageNumber: prop<number>(),
  pageSize: prop<number>(),
  setPageSize: prop<(number) => void>(),
  insideModal: prop.optional<boolean>(),
  total: prop.optional<number>(),
  pageSizeOptionsCustom: prop.optional<number[]>()
});

export const Paginator: SeeqComponent<typeof paginatorBindings> = (props) => {
  const { gotoPage, pageCount, pageNumber, pageSize, setPageSize, insideModal, total, pageSizeOptionsCustom } = props;
  const { t } = useTranslation();
  const pageSizeOptions = pageSizeOptionsCustom ?? [5, 10, 15, 20, 50];

  const formattedOptions = _.map(pageSizeOptions, size => ({ label: size, value: size }));
  const formattedGoToPageOptions = _.map(_.range(1, pageCount + 1, 1), size => ({ label: size, value: size }));
  const menuPosition = insideModal ? 'absolute' : 'fixed';
  const portalStyle = { menuPortal: base => ({ ...base, zIndex: 9999 }) };

  return (
    <div className="paginatorWrapper">
      <div className={classNames('paginator flexColumnContainer flexAlignCenter mr10',
        insideModal ? 'flexSpaceBetween' : 'pull-right')}
        data-testid='paginator'>
        <div className="flexColumnContainer ml10">
          <div id="goToPageWrapper" className="flexColumnContainer flexAlignCenter">
            {total && <span className="pr10">
              {t('PAGINATOR.TOTAL')}
              <strong>{total}</strong>
            </span>}
            <span className="pr5">
              {t('PAGINATOR.PAGE')}
            </span>
            <Select
              menuPlacement="auto"
              placeholder=""
              menuPosition={menuPosition}
              styles={insideModal && portalStyle}
              menuPortalTarget={document.body}
              className="width-75 pr10"
              classNamePrefix="react-select"
              value={_.find(formattedGoToPageOptions, { value: pageNumber })}
              options={formattedGoToPageOptions}
              isSearchable={true}
              onChange={option => gotoPage(option.value)}
            />
          </div>
          <div id="itemCountWrapper" className="flexColumnContainer flexAlignCenter">
        <span className="pl10">
          {t('PAGINATOR.SHOW')}
        </span>
            <Select
              menuPlacement="auto"
              menuPosition={menuPosition}
              styles={insideModal && portalStyle}
              menuPortalTarget={document.body}
              className="width-75 pl5 pr10"
              classNamePrefix="react-select"
              value={_.find(formattedOptions, { value: pageSize })}
              options={formattedOptions}
              isSearchable={false}
              onChange={option => setPageSize(option.value)}
            />
          </div>
        </div>
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={insideModal ? 1 : 2}
          marginPagesDisplayed={insideModal ? 1 : 2}
          previousLabel={insideModal ? <Icon icon="fa-caret-left" /> : t('PAGINATOR.PREVIOUS')}
          nextLabel={insideModal ? <Icon icon="fa-caret-right" /> : t('PAGINATOR.NEXT')}
          breakLabel={t('PAGINATOR.BREAK')}
          forcePage={pageNumber - 1}
          onPageChange={data => gotoPage(data.selected + 1)}
          breakClassName='break-me'
          containerClassName='pagination'
          subContainerClassName='pages pagination'
          activeClassName='active'
        />
      </div>
    </div>
  );
};

export default Paginator;
