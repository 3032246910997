import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import { DateTimeService } from '@/datetime/dateTime.service';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { CAPSULE_GROUP_DATE_FORMAT } from '@/datetime/datetime.module';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

const scatterPlotLegendColorConditionItemsBindings = bindingsDefinition({
  sqDateTime: injected<DateTimeService>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  colorConditions: prop<any[]>(),
  colorRanges: prop<any[]>()
});

export const ScatterPlotLegendColorConditionItems: SeeqComponent<typeof scatterPlotLegendColorConditionItemsBindings> = (props) => {
  const {
    sqDateTime,
    sqWorksheetStore
  } = useInjectedBindings(scatterPlotLegendColorConditionItemsBindings);
  const { colorConditions, colorRanges } = props;
  const { t } = useTranslation();

  const formatTime = time => sqDateTime.formatTime(time, sqWorksheetStore.timezone, CAPSULE_GROUP_DATE_FORMAT);

  return !!(colorConditions.length || colorRanges.length) && (
    <span>
      <strong className="mr10">{t('SCATTER.LEGEND_COLOR')}:</strong>
      {_.chain(colorConditions)
        .uniqBy('id')
        .map(condition => (
          <span className="mr10 text-nowrap specColorConditionLegend" key={condition.id}
            data-testid="color-condition-span">
            <Icon icon="fa-circle" type="color" color={condition.color} extraClassNames="pr5" />
            {condition.name}
          </span>
        ))
        .value()
      }
      {_.chain(colorRanges)
        .uniqBy('id')
        .map(colorRange => (
          <span className="mr10 text-nowrap specColorRangeLegend" key={colorRange.id} data-testid='color-range-span'>
             <Icon icon="fa-circle" type="color" color={colorRange.color} extraClassNames="pr5" />
            {formatTime(colorRange.start)} - {formatTime(colorRange.end)}
          </span>
        ))
        .value()
      }
    </span>
  );
};
