import _ from 'lodash';
import { createSelector, createStructuredSelector } from 'reselect';

const makeTrendSignalSelector = () => createStructuredSelector({
  id: item => _.get(item, 'id', ''),
  name: item => _.get(item, 'name', ''),
  color: item => _.get(item, 'color', 'transparent'),
  valueUnitOfMeasure: item => _.get(item, 'valueUnitOfMeasure'),
  format: item => _.get(item, 'formatOptions.format')
});

const scatterPlotSelector = createStructuredSelector({
  connect: (state: any) => state.connect,
  fxLines: (state: any) => state.fxLines,
  showTooltips: (state: any) => state.showTooltips,
  markerSize: (state: any) => state.markerSize
});

const scatterDataSelector = createStructuredSelector({
  scatterData: (scatterData: any) => scatterData
});

const getNameForAxis = item => [item.name, item.valueUnitOfMeasure ? ` (${item.valueUnitOfMeasure})` : ''].join('');
const getColorForAxis = item => item.color ? item.color : 'transparent';

/**
 * A selector that takes in the scatter plot state and the X and Y signals and produces Highcharts config object
 * that it can use to re-render.
 *
 * @param {Object} scatterPlotState - The state from the sqScatterPlotStore
 * @param {Object} xSignalState - The state from trendSignalSelector(xSignal)
 * @param {Object} ySignalState - The state from trendSignalSelector(ySignal)
 * @return {Function => Object} Highcharts config for the axes, the scatter plot series, and regression lines.
 */
export const getScatterPlotChartConfig = createSelector(
  createSelector(({ state }) => state, scatterPlotSelector),
  createSelector(({ scatterData }) => scatterData, scatterDataSelector),
  createSelector(({ xSignal }) => xSignal, makeTrendSignalSelector()),
  createSelector(({ ySignal }) => ySignal, makeTrendSignalSelector()),
  createSelector(({ fxLines }) => fxLines, fxLines => fxLines),
  (scatterPlot, scatterData, xSignal, ySignal, fxLines) => ({
    xAxis: {
      id: `${xSignal.id}-x`, // Suffix needed to support swapping X and Y
      signalId: xSignal.id,
      title: {
        text: getNameForAxis(xSignal),
        style: { color: getColorForAxis(xSignal) }
      }
    },
    yAxis: {
      id: `${ySignal.id}-y`,
      signalId: ySignal.id,
      title: {
        text: getNameForAxis(ySignal),
        style: { color: getColorForAxis(ySignal) }
      }
    },
    tooltip: {
      enabled: scatterPlot.showTooltips
    },
    series: _.chain(scatterPlot.fxLines)
      .map(line => ({
        ...line,
        type: 'line',
        lineWidth: 1,
        xNumberFormat: xSignal.format
      }))
      .push({
        type: 'scatter',
        id: 'scatterPlotChart',
        data: scatterData.scatterData,
        lineWidth: scatterPlot.connect ? 1 : 0,
        marker: {
          radius: scatterPlot.markerSize
        },
        xNumberFormat: xSignal.format,
        yNumberFormat: ySignal.format
      })
      .reverse() // Highcharts expects first series to be the scatter one
      .value()
  })
);
