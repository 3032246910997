import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { FormulaDocumentationHeader } from '@/hybrid/formula/FormulaDocumentationHeader.molecule';
import { FORMULA_HOME, FORMULA_LIST, FunctionDocItem, SEARCH } from '@/hybrid/tools/formula/formulaTool.module';
import { FormulaToolActions } from '@/hybrid/tools/formula/formulaTool.actions';
import { FormulaToolStore } from '@/hybrid/tools/formula/formulaTool.store';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';
import { FormulaFullListView } from '@/hybrid/formula/FormulaFullListView.molecule';
import { FormulaPageView } from '@/hybrid/formula/FormulaPageView.organism';
import { FormulaService } from '@/services/formula.service';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';

const formulaDocumentationBindings = bindingsDefinition({
  insertFormulaSnippet: prop<(snippet: string) => void>(),
  operators: prop<any>(),
  functions: prop<any>(),
  formulaHelpToggleFunction: prop.optional<() => void>(),
  sqFormula: injected<FormulaService>(),
  sqFormulaToolActions: injected<FormulaToolActions>(),
  sqFormulaToolStore: injected<FormulaToolStore>(),
  $http: injected<ng.IHttpService>()
});

export const FormulaDocumentation: SeeqComponent<typeof formulaDocumentationBindings> = ({
  insertFormulaSnippet,
  formulaHelpToggleFunction,
  operators,
  functions
}) => {
  const {
    sqFormulaToolStore, sqFormulaToolActions, $http
  } = useInjectedBindings(formulaDocumentationBindings);

  const [helpText, setHelpText] = useState(undefined);
  const [fullListView, setFullListView] = useState(false);
  const [backEnabled, setBackEnabled] = useState(true);
  const [functionFilter, setFunctionFilter] = useState(undefined);
  const [functionsList, setFunctionsList] = useState([]);
  const [functionHasNoHelp, setFunctionHasNoHelp] = useState(false);
  const [displayedNavStack, setDisplayedNavStack] = useState(undefined);

  const navigationStack = useFluxPath(sqFormulaToolStore, () => sqFormulaToolStore.navigationStack);
  const functionFilterFromStore = useFluxPath(sqFormulaToolStore, () => sqFormulaToolStore.formulaFilter);

  // context-sensitive help for more than one match
  useEffect(() => {
    if (functionFilterFromStore !== undefined && functionFilterFromStore !== functionFilter) {
      setFunctionFilter(functionFilterFromStore);
      clearHelpText(functionFilterFromStore);
      sqFormulaToolActions.setFormulaFilter(undefined);
    }
  }, [functionFilterFromStore]);

  // context-sensitive help for exact match
  useEffect(() => {
    if (_.last(displayedNavStack) !== _.last(navigationStack)) {
      setFunctionFilter('');
      navigateByStep(_.last(navigationStack));
    }
  }, [navigationStack]);

  useEffect(() => {
    navigateByStep(_.last(navigationStack));
  }, []);

  const loadFullList = () => {
    setFunctionFilter(undefined);
    setFunctionsList(functions);
    setHelpText('');
    setFullListView(true);

    addToNavigationStack(FORMULA_LIST);
  };

  const updateNavigationStack = (newNavigationStack) => {
    setDisplayedNavStack(newNavigationStack);
    sqFormulaToolActions.setNavigationStack(newNavigationStack);
  };

  const navigateByStep = (step) => {
    switch (step) {
      case undefined:
        return navigate(FORMULA_HOME);
      case FORMULA_LIST:
        return loadFullList();
      case SEARCH:
        return goBack();
      default:
        return navigate(step);
    }
  };

  const goBack = () => {
    setFunctionFilter(undefined);
    let tempNavigationStack = _.dropRight(navigationStack);
    // in theory navigateByStep should take care of this but there is a delay in getting the updated navigationStack
    // from the store that results in a stack overflow exception. Filtering out the search step here prevents this
    // and ensures expected behavior
    while (_.last(tempNavigationStack) === SEARCH) {
      tempNavigationStack = _.dropRight(tempNavigationStack);
    }

    updateNavigationStack(tempNavigationStack);
    navigateByStep(_.last(tempNavigationStack));
  };

  const goHome = () => {
    setFunctionFilter(undefined);
    setFullListView(false);

    if (navigationStack.length !== 0 && helpText?.documentationHref !== FORMULA_HOME) {
      updateNavigationStack(_.concat(navigationStack, FORMULA_HOME));
    }
    return navigate(FORMULA_HOME);
  };

  const navigate = (documentationHref, goBack = false) => {
    setBackEnabled(false);
    return $http.get(APPSERVER_API_PREFIX + documentationHref)
      .then((data) => {
        $('.formulaHelp .overflowAuto').animate({ scrollTop: 0 }, 'fast');
        setHelpText(data.data);
        setFunctionHasNoHelp(false);
      })
      .catch(() => {
        setHelpText(undefined);
        setFunctionHasNoHelp(true);
        // Best to restart with a clean navigationStack because the top page is invalid
        updateNavigationStack([]);
      })
      .finally(() => {
        setBackEnabled(true);
        setFullListView(false);
      });
  };

  const requestDocumentation = (documentationHref) => {
    setFunctionFilter(undefined);
    updateNavigationStack(_.concat(navigationStack, documentationHref));
    return navigate(documentationHref);
  };

  const clearHelpText = (searchText) => {
    setFunctionFilter(searchText);
    setHelpText(undefined);
    setFunctionHasNoHelp(false);
    setFullListView(true);
    setFunctionsList(searchText ? getFilteredFunctions(searchText) : operators);
    addToNavigationStack(SEARCH);
  };

  const addToNavigationStack = (item) => {
    if (_.last(navigationStack) !== item) {
      updateNavigationStack(_.concat(navigationStack, item));
    }
  };

  const getFilteredFunctions = (searchText) => {
    searchText = searchText.toLowerCase();

    const search = (pred: ((FunctionDocItem) => boolean)): string[] =>
      _.chain(operators)
        .filter(pred)
        .orderBy((item: FunctionDocItem) => item.name.toLowerCase())
        .value();

    const excelSynonymEquals = search(
      item => _.some(item.excelSynonyms, syn => _.startsWith(syn.toLowerCase(), searchText)));
    const nameStartMatches = search(item => _.startsWith(item.name.toLowerCase(), searchText));
    const nameMatches = search(item => _.includes(item.name.toLowerCase(), searchText));
    const shortDescriptionMatches = search(item => _.includes(item.shortDescription.toLowerCase(), searchText));
    const keywordMatches = search(item => _.some(item.keywords, kw => _.includes(kw.toLowerCase(), searchText)));
    const descriptionMatches = search(item => _.includes(item.description.toLowerCase(), searchText));

    return _.chain([])
      .concat(excelSynonymEquals)
      .concat(nameStartMatches)
      .concat(nameMatches)
      .concat(shortDescriptionMatches)
      .concat(keywordMatches)
      .concat(descriptionMatches)
      .uniqWith(_.isEqual)
      .value();
  };

  return (
    <>
      <FormulaDocumentationHeader
        formulaHelpToggleFunction={formulaHelpToggleFunction}
        backEnabled={backEnabled}
        functionFilter={functionFilter}
        clearHelpText={clearHelpText}
        loadFullList={loadFullList}
        goBack={goBack}
        goHome={goHome} />

      <div className="overflowAuto mt15 pr5 height-maximum">
        {!helpText && !functionHasNoHelp && fullListView &&
        <FormulaFullListView
          functions={functionsList}
          functionFilter={functionFilter}
          requestDocumentation={requestDocumentation} />}

        {helpText && !fullListView &&
        <FormulaPageView
          insertFormulaSnippet={insertFormulaSnippet}
          helpText={helpText}
          functionHasNoHelp={functionHasNoHelp}
          requestDocumentation={requestDocumentation} />}
      </div>
    </>
  );
};
