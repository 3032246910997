import React, { useEffect, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { TableBuilderActions } from '@/hybrid/tableBuilder/tableBuilder.actions';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { onEventPreventPropagation } from '@/hybrid/core/onEnterKeypress.util';
import { Icon } from '@/hybrid/core/Icon.atom';
import { UtilitiesService } from '@/services/utilities.service';
import { ASSET_PATH_SEPARATOR } from '@/main/app.constants';
import { TABLE_BUILDER } from '@/hybrid/tableBuilder/tableBuilder.module';
import { TrackService } from '@/track/track.service';
import { FormulaService } from '@/services/formula.service';
import { SearchActions } from '@/search/search.actions';
import { SelectAssetModal } from '@/hybrid/core/SelectAssetModal.molecule';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';

const selectAssetForTableModalBindings = bindingsDefinition({
  show: prop<boolean>(),
  onClose: prop<() => void>(),
  assetId: prop<string>(),
  homogenizeUnits: prop<boolean>(),
  sqTableBuilderActions: injected<TableBuilderActions>(),
  sqSearchActions: injected<SearchActions>(),
  sqUtilities: injected<UtilitiesService>(),
  sqFormula: injected<FormulaService>(),
  sqTrack: injected<TrackService>()
});

export const SelectAssetForTableModal: SeeqComponent<typeof selectAssetForTableModalBindings> = (props) => {
  const {
    sqTableBuilderActions,
    sqUtilities,
    sqFormula,
    sqTrack
  } = useInjectedBindings(selectAssetForTableModalBindings);

  const { show, onClose, assetId, homogenizeUnits } = props;
  const { t } = useTranslation();
  const [assetName, setAssetName] = useState(null);

  useEffect(() => {
    if (assetId) {
      sqFormula.getAssetPath({ id: assetId }, undefined)
        .then(path => setAssetName(path));
    } else {
      setAssetName(null);
    }
  }, [assetId]);

  const onAssetSelect = (item) => {
    sqTableBuilderActions.setHomogenizeUnits(true);
    sqTableBuilderActions.setAssetId(item.id);
    sqTrack.doTrack(TABLE_BUILDER, 'asset', 'enabled');
    onClose();
  };

  const clearAsset = () => {
    sqTableBuilderActions.setAssetId(undefined);
    sqTrack.doTrack(TABLE_BUILDER, 'asset', 'disabled');
    onClose();
  };

  const toggleHomogenizeUnits = () => {
    sqTableBuilderActions.setHomogenizeUnits(!homogenizeUnits, true);
    onClose();
  };

  const searchResultIcons = (item) => {
    const isSelected = assetId ? item.id === assetId : false;
    return sqUtilities.isAsset(item) && (
      <>
        <HoverTooltip text="TABLE_BUILDER.ASSET_TOOLTIP" delay={500}>
          <span onClick={onEventPreventPropagation(() => onAssetSelect(item))}>
            <Icon extraClassNames="searchBtn" large={true} icon={isSelected ? 'fa-check-circle' : 'fa-circle-o'} />
          </span>
        </HoverTooltip>
      </>
    );
  };

  const body = <>
    {_.isNil(assetName) &&
    <Alert
      className="p5"
      variant="info"
      // @ts-ignore
      transition={false}
    >
      {t('TABLE_BUILDER.ASSET_HELP')}
    </Alert>}
    {!_.isNil(assetName) &&
    <div className="well well-xs">
      <div className="flexColumnContainer flexSpaceBetween">
        <div>
          <Icon
            icon={sqUtilities.itemIconClass({ type: SeeqNames.Types.Asset })}
            type="inherit"
            extraClassNames="sq-fairly-dark-gray pl2 pr2"
            large={true}
          />
          {assetName}
        </div>
        <HoverTooltip text="CLEAR">
            <span>
              <Icon
                onClick={clearAsset}
                icon="fa-remove"
                extraClassNames="ml5 cursorPointer"
                type="theme"
                testId="clearAssetId"
                large={true}
              />
            </span>
        </HoverTooltip>
      </div>
      <div>
        <Checkbox
          id="homogenizeUnitsCheckbox"
          isChecked={homogenizeUnits}
          label="TABLE_BUILDER.HOMOGENIZE_UNITS"
          onChange={toggleHomogenizeUnits}
        />
      </div>
    </div>}
  </>;

  const title = <Modal.Title>{t('TABLE_BUILDER.ASSET_HEADER')}</Modal.Title>;

  return <SelectAssetModal
    show={show}
    onClose={onClose}
    searchResultIcons={searchResultIcons}
    body={body}
    header={title}
    footer={null}
  />;
};

