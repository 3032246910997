import moment from 'moment-timezone';
import _ from 'lodash';
import bind from 'class-autobind-decorator';
import { UtilitiesService } from '@/services/utilities.service';
import { LoggerService } from '@/services/logger.service';
import { BEGINNING_OF_TIME, Capsule, END_OF_TIME } from '@/datetime/datetime.module';
import {
  CAPSULE_SOURCE_ID_PROPERTY_NAME,
  CAPSULE_UNIQUE_PROPERTY_NAME
} from '@/investigate/customCondition/customCondition.module';
import { NUMBER_CONVERSIONS } from '@/main/app.constants';
import { FormulaService } from '@/services/formula.service';

@bind
export class ConditionFormulaService {
  constructor(
    private sqUtilities: UtilitiesService,
    private sqLogger: LoggerService,
    private sqFormula: FormulaService) {
  }

  /**
   * Compute a condition formula containing the provided capsules.
   *
   * @param {Object[]} capsules - array of capsules
   * @returns {String} the string for the formula
   */
  conditionFormula(capsules: Capsule[]) {
    if (!_.isArray(capsules) || capsules.length === 0) {
      throw new Error('A condition built using a `condition` formula requires at least one capsule');
    }

    const args = _.map(capsules, this.capsuleFormula);

    // Adding 1 second (presumably) fixes some rounding problems
    const maxDuration = _.max(_.map(capsules, capsule => capsule.endTime - capsule.startTime)) + 1000;

    return `condition(${maxDuration}ms,\n  ${_.join(args, ',\n  ')})`;
  }

  /**
   * Compute a capsule formula for the provided capsule.
   *
   * @param {Object} capsule - the capsule
   * @returns {String} the string for the formula
   */
  capsuleFormula(capsule: Capsule) {
    // Add the property for the id if needed
    const properties = _.isNil(capsule.id) ? capsule.properties : _.uniqBy([{
      name: CAPSULE_SOURCE_ID_PROPERTY_NAME,
      value: capsule.id,
      unitOfMeasure: 'string'
    }, ...capsule.properties], 'name');

    const propertiesDefinition = _.map(properties, ({ name, value, unitOfMeasure }) => {
      let valueStr: string;
      if ((_.isNumber(value) && _.isNil(unitOfMeasure)) || _.isBoolean(value)) {
        valueStr = value.toString();
      } else if (_.isNumber(value) && _.isString(unitOfMeasure)) {
        valueStr = `${value} ${unitOfMeasure}`;
      } else if (_.isString(value)) {
        valueStr = `'${value}'`;
      } else {
        throw new Error(this.sqLogger.format`Unhandled property '${{ name, value, unitOfMeasure }}'`);
      }

      return `setProperty('${name}', ${valueStr})`;
    });

    return _.join([`capsule(${capsule.startTime}ms, ${capsule.endTime}ms)`].concat(propertiesDefinition), '\n    .');
  }

  /**
   * Takes a `condition` formula and runs it so that the backend can tell us what capsules are inside.
   *
   * @param {String} formula - a `condition` formula
   * @returns {Promise} resolves with a list of capsules
   */
  requestCapsulesFromFormula(formula: string) {
    return this.sqFormula.computeCapsules({
        range: { start: BEGINNING_OF_TIME, end: END_OF_TIME },
        formula,
        parameters: {},
        usePost: true  // Formulas can become large enough to exceed node's max http header size
      })
      .then(results => results.capsules)
      .then(capsules =>
        _.map(capsules as any[], (c) => {
          const property = _.find(c.properties as any[], ['name', CAPSULE_SOURCE_ID_PROPERTY_NAME]);
          const capsule = {
            startTime: c.start / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
            endTime: c.end / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
            properties: property ? _.without(c.properties, property) : c.properties
          };

          return !property ? capsule : {
            ...capsule,
            id: property.value
          } as Capsule;
        })
      );
  }

  /**
   * The backend does not allow a condition to contain identical capsules; a capsule is identical if it has the same
   * start, end, and properties. If it is desirable for a condition to contain identical capsules, this function can
   * be used to ensure that each capsule is unique. If necessary, it will introduce the `UNIQUE_PROPERTY_NAME` property
   * to the capsule with a random guid.
   *
   * @param {Object[]} capsules - the capsule that should be made unique
   * @returns {Object[]} the capsules with an added `UNIQUE_PROPERTY_NAME` property if necessary.
   */
  makeCapsulesUnique(capsules: Capsule[]): Capsule[] {
    if (capsules.length < 2) {
      // Need at least 2 capsules to have a collision
      return capsules;
    }
    return _.chain(capsules)
      .sortBy(['startTime', 'endTime'])
      .map((capsule, index, collection) => {
        const adjacent = collection[capsule === _.head(collection) ? index + 1 : index - 1];
        const isSimilar = _.isNil(capsule.id) && _.isNil(adjacent.id) && // Assume if they have ids they will be unique
          Math.round(capsule.startTime) === Math.round(adjacent.startTime) &&
          Math.round(capsule.endTime) === Math.round(adjacent.endTime);
        return !isSimilar ? capsule : {
          ...capsule,
          properties: _.uniqBy([{
            name: CAPSULE_UNIQUE_PROPERTY_NAME,
            value: this.sqUtilities.base64guid(),
            unitOfMeasure: 'string'
          }, ...(capsule.properties || [])], 'name')
        };
      })
      .value();
  }

  /**
   * We filter out invalid capsules before making a condition formula because if the backend fails to run the
   * formula then we will be unable to get the capsules back out of the formula since we rely on the backend to
   * parse and return results for us. Invalid capsules are logged, but otherwise will be removed silently
   *
   * @param {Object[]} capsules - the capsule that should checked for validity
   * @returns {Object[]} the valid capsules.
   */
  removeInvalidCapsules(capsules: Capsule[]) {
    const [valid, invalid] = _.partition(capsules, this.isValidCapsule);

    _.forEach(invalid, (invalidCapsule) => {
      this.sqLogger.warn(`Removing an invalid capsule, ${this.capsuleFormula(invalidCapsule)}`);
    });

    return valid;
  }

  /**
   * Check if a capsule is valid. An invalid capsule is one that the backend will reject. Currently, this will
   * happen if the capsule's end is before the start.
   *
   * @param {Object} capsule - the capsule to check
   * @returns {Boolean} true if the capsule is valid
   */
  isValidCapsule(capsule: Capsule): boolean {
    return capsule.startTime && capsule.endTime && moment(capsule.startTime).isBefore(moment(capsule.endTime));
  }
}
