import angular from 'angular';

const dependencies = [
  'Sq.Core',
  'Sq.AppConstants',
  'Sq.Workbook',
  'Sq.Report'
];

/**
 * The Sq.Worksheets module contains the directive and associated controller that display
 * the list of worksheets on the left side of the Workbench user interface.
 */
angular.module('Sq.Worksheets', dependencies);
