import bind from 'class-autobind-decorator';
import { ProcessTypeEnum } from 'sdk/model/ThresholdMetricOutputV1';
import { FrontendDuration } from '@/services/systemConfiguration.service';
import { ThresholdMetricInputV1 } from 'sdk/model/ThresholdMetricInputV1';

@bind
export class ThresholdMetricActions {
  constructor(
    private flux: ng.IFluxService
  ) {}

  /**
   * Announces all listeners that a new simple metric was succesfully created
   * @param id - the identifier of the newly created simple metric
   * @param definition - the definition of the simple metric
   */
  broadcastSimpleMetricCreated(id: string, definition: ThresholdMetricInputV1) {
    this.flux.dispatch('SIMPLE_THRESHOLD_METRIC_CREATED', { id, definition });
  }

  /**
   * Set the process type to use for this metric
   *
   * @param {ProcessTypeEnum} processType - one of the metric types
   */
  setProcessType(processType: ProcessTypeEnum) {
    this.flux.dispatch('THRESHOLD_METRIC_SET_PROCESS_TYPE', { processType });
  }

  /**
   * Set the aggregation operator to evaluate for this metric
   *
   * @param {Object} aggregationOperator - the data structure from the statistic selector.
   */
  setAggregationOperator(aggregationOperator: { key: string, timeUnits: string }) {
    this.flux.dispatch('THRESHOLD_METRIC_SET_AGGREGATION_OPERATOR', { aggregationOperator });
  }

  /**
   * Set the duration window for this metric, if it is continuous
   *
   * @param {number} value - The number that indicates how long the duration is
   * @param {string} units - The units that the value represents
   */
  setDuration(duration: FrontendDuration) {
    this.flux.dispatch('THRESHOLD_METRIC_SET_DURATION', duration);
  }

  /**
   * Set the period for this metric, if it is continuous
   *
   * @param {number} value - The number that indicates how long the period is
   * @param {string} units - The units that the value represents
   */
  setPeriod(period: FrontendDuration) {
    this.flux.dispatch('THRESHOLD_METRIC_SET_PERIOD', period);
  }

  /**
   * Sets the maximum capsule duration for the bounding condition if it is unbounded.
   *
   * @param {number} value - The number that indicates how long the duration is
   * @param {string} units - The units that the value represents
   */
  setBoundingConditionMaximumDuration(value, units) {
    this.flux.dispatch('THRESHOLD_METRIC_SET_BOUNDING_CONDITION_MAXIMUM_DURATION', { value, units });
  }

  /**
   * Set a threshold value.
   *
   * @param {number} level - The priority level
   * @param {string} threshold - The boundary of the threshold. A scalar formula or signal/condition item ID.
   */
  setThreshold(level: number, threshold: string) {
    this.flux.dispatch('THRESHOLD_METRIC_SET_THRESHOLD', { level, threshold });
  }

  /**
   * Set a custom color for a threshold, including the special neutral case (level 0).
   *
   * @param {number} level - The priority level
   * @param {string} color - The color to use for the priority level.
   */
  setCustomThresholdColor(level: number, color: string) {
    this.flux.dispatch('THRESHOLD_METRIC_SET_THRESHOLD_COLOR', { level, color });
  }
}
