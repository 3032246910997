import _ from 'lodash';
import angular from 'angular';
import moment from 'moment-timezone';
import { DateTimeService } from '@/datetime/dateTime.service';
import {
  AUTO_UPDATE,
  DEFAULT_DISPLAY_RANGE_DURATION_DAYS,
  DEFAULT_INVESTIGATE_RANGE_DURATION_DAYS
} from '@/trendData/trendData.module';
import { INITIALIZE_MODE, PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';

angular.module('Sq.TrendData').store('sqDurationStore', sqDurationStore);

export type DurationStore = ReturnType<typeof sqDurationStore>['exports'];

export interface RangeExport {
  start: moment.Moment;
  end: moment.Moment;
  duration: moment.Duration;
}

function sqDurationStore(sqDateTime: DateTimeService) {

  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.WORKSHEET,

    initialize(initializeMode) {
      const now = moment.utc().valueOf();
      const saveState = this.state && initializeMode !== INITIALIZE_MODE.FORCE;

      this.state = this.immutable({
        // Avoid clearing state that is not dehydrated when the store is re-initialized. Async calls will repopulate
        // these properties as needed
        autoUpdate: {
          mode: AUTO_UPDATE.MODES.OFF,
          offset: 0,
          interval: saveState ? this.state.get(['autoUpdate', 'interval']) : AUTO_UPDATE.MIN_INTERVAL,
          manualInterval: AUTO_UPDATE.DEFAULT_INTERVAL,
          autoInterval: saveState ? this.state.get(['autoUpdate', 'autoInterval']) : 0,
          displayPixels: saveState ? this.state.get(['autoUpdate', 'displayPixels']) : 0,
          now
        },

        displayRange: {
          start: moment.utc(now).subtract(DEFAULT_DISPLAY_RANGE_DURATION_DAYS, 'days').valueOf(),
          end: now,
          duration: this.monkey(
            ['displayRange', 'start'],
            ['displayRange', 'end'],
            function(start, end) {
              return moment.duration(end - start);
            },

            { immutable: false } // Moment mutates the object even if it doesn't change valueOf
          ),
          startMoment: this.monkey(['displayRange', 'start'], moment.utc, { immutable: false }),
          endMoment: this.monkey(['displayRange', 'end'], moment.utc, { immutable: false })
        },
        investigateRange: {
          start: moment.utc(now).subtract(DEFAULT_INVESTIGATE_RANGE_DURATION_DAYS, 'days').valueOf(),
          end: now,
          duration: this.monkey(
            ['investigateRange', 'start'],
            ['investigateRange', 'end'],
            function(start, end) {
              return moment.duration(end - start);
            },

            { immutable: false } // Moment mutates the object even if it doesn't change valueOf
          ),
          startMoment: this.monkey(['investigateRange', 'start'], moment.utc, { immutable: false }),
          endMoment: this.monkey(['investigateRange', 'end'], moment.utc, { immutable: false })
        }
      }, {
        asynchronous: false // This store emits instantly to ensure the chart scrolls smoothly
      });
    },

    exports: {
      get autoUpdate() {
        return this.state.get('autoUpdate');
      },

      get displayRange(): RangeExport {
        const state = this.state;
        return {
          duration: state.get('displayRange', 'duration') as moment.duration,
          end: state.get('displayRange', 'endMoment') as moment,
          start: state.get('displayRange', 'startMoment') as moment
        };
      },

      get investigateRange(): RangeExport {
        const state = this.state;
        return {
          duration: state.get('investigateRange', 'duration') as moment.duration,
          end: state.get('investigateRange', 'endMoment') as moment,
          start: state.get('investigateRange', 'startMoment') as moment
        };
      }
    },

    dehydrate() {
      const state = this.state.serialize();
      state.autoUpdate = _.omit(state.autoUpdate, ['now', 'interval', 'autoInterval', 'displayPixels']);
      return state;
    },

    rehydrate(newState) {
      this.state.merge('autoUpdate', newState.autoUpdate);
      this.autoUpdateComputeIntervals();

      if (newState.investigateRange) {
        this.updateInvestigateRangeTimes({
          end: newState.investigateRange.end,
          start: newState.investigateRange.start
        });
      }

      if (newState.displayRange) {
        this.updateDisplayRangeTimes({
          end: newState.displayRange.end,
          start: newState.displayRange.start
        });
      }
    },

    handlers: {
      AUTO_UPDATE_SET_NOW: 'autoUpdateSetNow',
      AUTO_UPDATE_SET_MODE: 'autoUpdateSetMode',
      AUTO_UPDATE_COMPUTE_OFFSET: 'autoUpdateComputeOffset',
      AUTO_UPDATE_CLEAR_OFFSET: 'autoUpdateClearOffset',
      AUTO_UPDATE_SET_MANUAL_INTERVAL: 'autoUpdateSetManualInterval',
      AUTO_UPDATE_SET_DISPLAY_PIXELS: 'autoUpdateSetDisplayPixels',
      UPDATE_DISPLAY_RANGE_TIMES: 'updateDisplayRangeTimes',
      UPDATE_INVESTIGATE_RANGE_TIMES: 'updateInvestigateRangeTimes'
    },

    /*********************************************** Auto Update ***************************************************/

    /**
     * Helper function that computes autoInterval and interval values.
     */
    autoUpdateComputeIntervals() {
      let interval, autoInterval;
      const mode = this.state.get(['autoUpdate', 'mode']);
      const displayPixels = this.state.get(['autoUpdate', 'displayPixels']);
      const manualInterval = this.state.get(['autoUpdate', 'manualInterval']);
      const displayDuration = this.state.get(['displayRange', 'duration']);

      // Compute the auto interval based on the display range duration and number of display pixels
      if (displayPixels && displayDuration.valueOf()) {
        autoInterval = Math.round(Math.max(AUTO_UPDATE.MIN_INTERVAL, displayDuration.valueOf() / displayPixels));
      } else {
        autoInterval = sqDateTime
          .parseDuration(AUTO_UPDATE.DEFAULT_INTERVAL.value + AUTO_UPDATE.DEFAULT_INTERVAL.units).valueOf();
      }

      this.state.set(['autoUpdate', 'autoInterval'], autoInterval);

      if (mode === AUTO_UPDATE.MODES.AUTO) {
        interval = autoInterval;
      } else if (mode === AUTO_UPDATE.MODES.MANUAL) {
        interval = sqDateTime.parseDuration(manualInterval.value + manualInterval.units).valueOf();
      } else {
        interval = AUTO_UPDATE.MIN_INTERVAL;
      }

      this.state.set(['autoUpdate', 'interval'], interval);
    },

    /**
     * Sets the auto update now time.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Number} payload.now - now unix timestamp
     */
    autoUpdateSetNow(payload) {
      this.state.set(['autoUpdate', 'now'], payload.now);
    },

    /**
     * Sets the auto update mode.
     *
     * @param {Object} payload - Object container for arguments
     * @param {AUTO_UPDATE.MODES} payload.mode - the auto update mode (AUTO or MANUAL)
     */
    autoUpdateSetMode(payload) {
      this.state.set(['autoUpdate', 'mode'], payload.mode);
      this.autoUpdateComputeIntervals();
    },

    /**
     * Computes and then sets the auto update offset.
     */
    autoUpdateComputeOffset() {
      const offset = this.state.get('displayRange', 'end') - this.state.get('autoUpdate', 'now');
      this.state.set(['autoUpdate', 'offset'], offset);
    },

    /**
     * Clears the auto update offset.
     */
    autoUpdateClearOffset() {
      this.state.set(['autoUpdate', 'offset'], 0);
    },

    /**
     * Sets the auto update manual interval.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Boolean} payload.manualInterval - the manual interval container object
     * @param {Number} payload.manualInterval.value - the manual interval value (e.g. 1)
     * @param {String} payload.manualInterval.units  - the manual interval units (e.g. 's')
     */
    autoUpdateSetManualInterval(payload) {
      this.state.set(['autoUpdate', 'manualInterval'], payload.manualInterval);
      this.autoUpdateComputeIntervals();
    },

    /**
     * Sets the auto update display pixels.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Number} payload.displayPixels - the display pixels
     */
    autoUpdateSetDisplayPixels(payload) {
      this.state.set(['autoUpdate', 'displayPixels'], payload.displayPixels);
      this.autoUpdateComputeIntervals();
    },

    /********************************************* Display Range *************************************************/

    /**
     * Updates display range start and end times. Duration is automatically updated to reflect the new range.
     *
     * @param {Object} payload - Object container for arguments
     * @param {moment} payload.start - New display range start time
     * @param {moment} payload.end - New display range end time
     */
    updateDisplayRangeTimes(payload) {
      const endMoment = moment.utc(payload.end);
      const startMoment = moment.utc(payload.start);

      this.state.merge('displayRange', { start: startMoment.valueOf(), end: endMoment.valueOf() });
      this.autoUpdateComputeIntervals();
    },

    /******************************************** Investigate Range ************************************************/

    /**
     * Updates investigate range start and end times. Duration is automatically updated to reflect the new range.
     *
     * @param {Object} payload - Object container for arguments
     * @param {moment} payload.start - New investigate range start time
     * @param {moment} payload.end - New investigate range end time
     */
    updateInvestigateRangeTimes(payload) {
      const endMoment = moment.utc(payload.end);
      const startMoment = moment.utc(payload.start);

      this.state.merge('investigateRange', { start: startMoment.valueOf(), end: endMoment.valueOf() });
    }
  };

  return store;
}
