import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { LegendWrapper } from '@/hybrid/trend/LegendWrapper.atom';

const rainbowLegendItemsBindings = bindingsDefinition({
  getCapsuleName: prop<(any) => string>(),
  capsules: prop<any[]>(),
  title: prop<string>()
});

export const RainbowLegendItems: SeeqComponent<typeof rainbowLegendItemsBindings> = (props) => {
  const { getCapsuleName, capsules, title } = props;

  return (
    <LegendWrapper title={title}>
      {_.chain(capsules)
        .uniqBy('childColor')
        .reject(capsule => _.isEmpty(getCapsuleName(capsule)))
        .map(capsule => (
          <div className="mr10 text-nowrap" key={capsule.id}>
            <i className="pr5 fa fc fc-series" style={{ color: capsule.childColor }} />
            {getCapsuleName(capsule)}
          </div>
        ))
        .value()}
    </LegendWrapper>
  );
};
