import React from 'react';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { APP_STATE, DEBOUNCE } from '@/main/app.constants';
import { HomeScreenStore } from '@/hybrid/homescreen/homescreen.store';
import { UtilitiesService } from '@/services/utilities.service';
import { useDebounce } from '@/hybrid/core/useDebounce.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

const navigationEntryBindings = bindingsDefinition({
  displayValue: prop<string>(),
  current: prop.optional<boolean>(),
  tabKey: prop<string>(),
  displaySelector: prop.optional<string>(),
  $state: injected<ng.ui.IStateService>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  id: prop<string>(),
  sqHomeScreenStore: injected<HomeScreenStore>(),
  sqUtilities: injected<UtilitiesService>()
});

export const NavigationEntry: SeeqComponent<typeof navigationEntryBindings> = (props) => {
  const { $state, sqHomeScreenActions, sqUtilities } = useInjectedBindings(navigationEntryBindings);
  const { displayValue, current, tabKey, id } = props;
  const classes = classNames('cursorPointer', 'homeScreenNav', { current });
  const { t } = useTranslation();

  const loadItems = useDebounce(() => {
    sqHomeScreenActions.resetStore();
    $state.go(APP_STATE.WORKBOOKS, { t: sqUtilities.generateTabHash(tabKey) }, { reload: true });
  }, DEBOUNCE.LONG, { leading: true, trailing: false });

  return (
    <div className={classes} onClick={loadItems} id={id}>
      {t(displayValue)}
    </div>
  );
};
