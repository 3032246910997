import angular from 'angular';
import { RequestsTabUtilities } from '@/hybrid/administration/requestsTab.utilities';
import { AdministrationUtilities } from '@/hybrid/administration/administration.utilities';

const dependencies = [
  'Sq.Services.Notifications',
  'Sq.Workbench',
  'Sq.Services.Modal',
  'Sq.Services.ACLService',
  'Sq.Administration.Datasources',
  'Sq.Administration.Agents'
];

/**
 * The Sq.Administration module contains all functionality related to the administration panel
 */
angular.module('Sq.Administration', dependencies)
  .service('sqRequestsTabUtilities', RequestsTabUtilities)
  .service('sqAdministrationUtilities', AdministrationUtilities);

export const USERS_TAB_INDEX = 0;
export const GROUPS_TAB_INDEX = 1;
export const REQUESTS_TAB_INDEX = 2;
export const DATASOURCES_TAB_INDEX = 3;
export const AGENTS_TAB_INDEX = 4;
export const SUBSCRIPTIONS_TAB_INDEX = 5;
export const JOBS_TAB_INDEX = 6;
export const CONFIGURATION_TAB_INDEX = 7;
export const EXPORTS_TAB_INDEX = 8;
export const ACCESS_KEY_TAB_INDEX = 9;
export const PLUGINS_TAB_INDEX = 10;
export const REPORT_TAB_INDEX = 11;
export const LEGACY_GUEST_USERNAME = 'guest';
