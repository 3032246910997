import _ from 'lodash';
import React from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { FormCheck, FormControl, FormGroup, FormLabel } from 'react-bootstrap';

export enum DayOfTheWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}

export enum MonthlyScheduleTypeName {
  BY_DAY_OF_MONTH = 'by-day-of-month',
  BY_DAY_OF_WEEK = 'by-day-of-week'
}

export class DayOfMonthSchedule {
  day: number;
  numberOfMonths: number;
}

export class DayOfWeekSchedule {
  nth: number;
  dayOfWeek: DayOfTheWeek;
  numberOfMonths: number;
}

export class MonthlyScheduleData {
  selectedType: MonthlyScheduleTypeName;
  data: {
    [MonthlyScheduleTypeName.BY_DAY_OF_MONTH]: DayOfMonthSchedule;
    [MonthlyScheduleTypeName.BY_DAY_OF_WEEK]: DayOfWeekSchedule;
  };

  constructor() {
    this.selectedType = MonthlyScheduleTypeName.BY_DAY_OF_MONTH;
    this.data = {
      [MonthlyScheduleTypeName.BY_DAY_OF_MONTH]: {
        day: 1,
        numberOfMonths: 1
      },
      [MonthlyScheduleTypeName.BY_DAY_OF_WEEK]: {
        nth: 1,
        dayOfWeek: DayOfTheWeek.MONDAY,
        numberOfMonths: 1
      }
    };
  }
}

export const isMonthlyScheduleValid = ({ selectedType, data }: MonthlyScheduleData) => {
  if (_.isNil(selectedType)) {
    return false;
  }

  if (selectedType === MonthlyScheduleTypeName.BY_DAY_OF_MONTH) {
    const { numberOfMonths, day } = data[MonthlyScheduleTypeName.BY_DAY_OF_MONTH];
    return _.isFinite(numberOfMonths) && numberOfMonths >= MIN_MONTH && numberOfMonths <= MAX_MONTH &&
      _.isFinite(day) && day >= MIN_DAY && day <= MAX_DAY;
  } else {
    const { numberOfMonths } = data[MonthlyScheduleTypeName.BY_DAY_OF_WEEK];
    return _.isFinite(numberOfMonths) && numberOfMonths >= MIN_MONTH && numberOfMonths <= MAX_MONTH;
  }
};

interface MonthlyScheduleBindings {
  monthlySchedule: MonthlyScheduleData;
  setMonthlySchedule: (MonthlyScheduleType) => void;
}

const NTH_OPTIONS = [
  { value: 1, label: 'REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.MONTHLY.NTH.FIRST' },
  { value: 2, label: 'REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.MONTHLY.NTH.SECOND' },
  { value: 3, label: 'REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.MONTHLY.NTH.THIRD' },
  { value: 4, label: 'REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.MONTHLY.NTH.FOURTH' }
];
const MIN_DAY = 1;
const MAX_DAY = 31;
const MIN_MONTH = 1;
const MAX_MONTH = 12;
const BY_DAY_OF_MONTH_ID = 'monthly-schedule-config__by-day-of-month';
const BY_DAY_OF_WEEK_ID = 'monthly-schedule-config__by-day-of-week';

export const MonthlySchedule: React.FunctionComponent<MonthlyScheduleBindings> = (props) => {
  const { monthlySchedule, setMonthlySchedule } = props;
  const { selectedType } = monthlySchedule;
  const dayOfMonthData = monthlySchedule.data[MonthlyScheduleTypeName.BY_DAY_OF_MONTH];
  const dayOfWeekData = monthlySchedule.data[MonthlyScheduleTypeName.BY_DAY_OF_WEEK];

  const { t } = useTranslation();

  const isByDayOfMonth = selectedType === MonthlyScheduleTypeName.BY_DAY_OF_MONTH;
  const isByDayOfWeek = selectedType === MonthlyScheduleTypeName.BY_DAY_OF_WEEK;

  const selectType = (selectedType) => {
    setMonthlySchedule(_.extend({}, monthlySchedule, { selectedType }));
  };

  const updateDayOfMonthDays = (event: React.ChangeEvent<HTMLInputElement>) => {
    const day = _.clamp(_.toInteger(event.target.value), MIN_DAY, MAX_DAY);
    const nextMonthlySchedule = { ...monthlySchedule };
    nextMonthlySchedule.data[MonthlyScheduleTypeName.BY_DAY_OF_MONTH].day = day;
    setMonthlySchedule(nextMonthlySchedule);
  };

  const updateDayOfMonthNumberOfMonths = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numberOfMonths = _.clamp(_.toInteger(event.target.value), MIN_MONTH, MAX_MONTH);
    const nextMonthlySchedule = { ...monthlySchedule };
    nextMonthlySchedule.data[MonthlyScheduleTypeName.BY_DAY_OF_MONTH].numberOfMonths = numberOfMonths;
    setMonthlySchedule(nextMonthlySchedule);
  };

  const selectNthDayOfWeek = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextMonthlySchedule = { ...monthlySchedule };
    nextMonthlySchedule.data[MonthlyScheduleTypeName.BY_DAY_OF_WEEK].nth = _.toInteger(event.target.value);
    setMonthlySchedule(nextMonthlySchedule);
  };

  const selectDayOfWeek = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextMonthlySchedule = { ...monthlySchedule };
    nextMonthlySchedule.data[MonthlyScheduleTypeName.BY_DAY_OF_WEEK].dayOfWeek = event.target.value as DayOfTheWeek;
    setMonthlySchedule(nextMonthlySchedule);
  };

  const updateDayOfWeekNumberOfMonths = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numberOfMonths = _.clamp(_.toInteger(event.target.value), MIN_MONTH, MAX_MONTH);
    const nextMonthlySchedule = { ...monthlySchedule };
    nextMonthlySchedule.data[MonthlyScheduleTypeName.BY_DAY_OF_WEEK].numberOfMonths = numberOfMonths;
    setMonthlySchedule(nextMonthlySchedule);
  };

  return (
    <FormGroup data-testid="monthly-schedule-config" className="flexRowContainer">
      <FormGroup className="flexColumnContainer flexNoGrowNoShrink">
        <FormCheck
          id={BY_DAY_OF_MONTH_ID}
          data-testid={BY_DAY_OF_MONTH_ID}
          type="radio"
          className="mt5 mb5 mr5"
          label={t('REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.MONTHLY.DAY')}
          checked={isByDayOfMonth}
          onChange={() => !isByDayOfMonth && selectType(MonthlyScheduleTypeName.BY_DAY_OF_MONTH)} />
        <FormControl
          data-testid="monthly-schedule-config__by-day-of-month__day"
          as="input"
          type="number"
          required={true}
          min={MIN_DAY}
          max={MAX_DAY}
          isInvalid={_.isNaN(dayOfMonthData.day)}
          className="width-55 noValidationIcons"
          value={dayOfMonthData.day.toString()}
          onChange={updateDayOfMonthDays} />
        <FormLabel className="m5">{t('REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.MONTHLY.OF_EVERY')}</FormLabel>
        <FormControl
          data-testid="monthly-schedule-config__by-day-of-month__number-of-months"
          as="input"
          type="number"
          required={true}
          min={MIN_MONTH}
          max={MAX_MONTH}
          isInvalid={_.isNaN(dayOfMonthData.numberOfMonths)}
          className="width-55 noValidationIcons"
          value={dayOfMonthData.numberOfMonths.toString()}
          onChange={updateDayOfMonthNumberOfMonths} />
        <FormLabel className="m5">{t('REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.MONTHLY.MONTHS')}</FormLabel>
      </FormGroup>
      <FormGroup className="flexColumnContainer flexNoGrowNoShrink">
        <FormCheck
          id={BY_DAY_OF_WEEK_ID}
          data-testid={BY_DAY_OF_WEEK_ID}
          type="radio"
          className="mt5 mb5 mr5"
          label={t('REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.MONTHLY.THE')}
          checked={isByDayOfWeek}
          onChange={() => !isByDayOfWeek && selectType(MonthlyScheduleTypeName.BY_DAY_OF_WEEK)} />
        <FormControl
          data-testid="monthly-schedule-config__by-day-of-week__nth"
          as="select"
          className="width-90 mr5"
          value={dayOfWeekData.nth.toString()}
          onChange={selectNthDayOfWeek}>
          {_.map(NTH_OPTIONS, ({ value, label }) => <option key={value} value={value}>{t(label)}</option>)}
        </FormControl>
        <FormControl
          data-testid="monthly-schedule-config__by-day-of-week__day"
          as="select"
          className="width-110"
          value={dayOfWeekData.dayOfWeek}
          onChange={selectDayOfWeek}>
          {_.map(Object.keys(DayOfTheWeek), day =>
            <option key={day} value={day}>{t(`REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.WEEKLY.${day}`)}</option>)}
        </FormControl>
        <FormLabel className="m5">{t('REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.MONTHLY.OF_EVERY')}</FormLabel>
        <FormControl
          data-testid="monthly-schedule-config__by-day-of-week__number-of-months"
          as="input"
          type="number"
          required={true}
          min={MIN_MONTH}
          max={MAX_MONTH}
          isInvalid={_.isNaN(dayOfWeekData.numberOfMonths)}
          className="width-55 noValidationIcons"
          value={dayOfWeekData.numberOfMonths.toString()}
          onChange={updateDayOfWeekNumberOfMonths} />
        <FormLabel className="m5">{t('REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.MONTHLY.MONTHS')}</FormLabel>
      </FormGroup>
    </FormGroup>
  );
};

