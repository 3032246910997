import _ from 'lodash';
import angular, { IPromise } from 'angular';
import { ReportStore } from '@/reportEditor/report.store';
import { UtilitiesService } from '@/services/utilities.service';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { ReportActions } from '@/reportEditor/report.actions';
import { FroalaReportContentService } from './froalaReportContent.service';
import { FroalaPluginsService } from '@/reportEditor/froalaPlugins.service';
import { NG_IF_WAIT } from '@/main/app.constants';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { CustomPlugin } from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { ContentBorder } from '@/hybrid/annotation/ckEditorPlugins/plugins/content/ContentBorder';

angular.module('Sq.Report').controller('ReportEditorCtrl', ReportEditorCtrl);

function ReportEditorCtrl(
  $q: ng.IQService,
  $element: JQuery,
  $scope: ng.IScope,
  $translate: ng.translate.ITranslateService,
  $sce: ng.ISCEService,
  $interval: ng.IIntervalService,
  sqReportStore: ReportStore,
  sqUtilities: UtilitiesService,
  sqWorkbenchStore: WorkbenchStore,
  sqReportActions: ReportActions,
  sqReportContent: ReportContentService,
  sqReportEditor: ReportEditorService,
  sqFroalaPlugins: FroalaPluginsService,
  sqFroalaReportContent: FroalaReportContentService,
  sqSystemConfiguration: SystemConfigurationService
) {
  const vm = this;
  vm.canModify = sqFroalaReportContent.canModifyDocument();
  vm.documentChanged = documentChanged;
  vm.beforeEditorOnInit = beforeEditorOnInit;
  vm.afterEditorOnInit = afterEditorOnInit;
  vm.cancelRestoreBackup = sqReportActions.clearBackupPreview;
  vm.restoreBackup = sqReportActions.restoreBackup;
  vm.isSandboxMode = sqReportStore.sandboxMode.enabled;
  // Exposed for test
  vm.$onDestroy = onDestroy;
  vm.initialize = initialize;
  vm.areDocumentsEqual = sqFroalaReportContent.areDocumentsEqual;
  vm.isEditMode = sqUtilities.isEditWorkbookMode;
  vm.isFixedWidth = sqReportStore.isFixedWidth;
  vm.isCkEditor = sqReportEditor.isCkEditor();
  vm.toolbar = [CustomPlugin.Content, 'link', 'imageUpload', 'insertTable', CustomPlugin.FixedWidth, 'pagebreak', 'previousPage', 'nextPage', 'pageNavigation', '|',
    'heading', 'fontColor', 'fontBackgroundColor', 'fontFamily', 'fontSize', 'codeBlock', '|',
    'bold', 'italic', 'underline', 'strikethrough', '|',
    'alignment', 'outdent', 'indent', 'numberedList', 'bulletedList', '|',
    'removeFormat', '|',
    CustomPlugin.PdfExport, '|',
    'undo', 'redo', '|',
    ContentBorder.pluginName];
  vm.plugins = [CustomPlugin.Content, CustomPlugin.PdfExport, CustomPlugin.FixedWidth, CustomPlugin.SeeqPageBreak, CustomPlugin.ContentBorder];

  $scope.$listenTo(sqReportStore, syncReportStore);
  $scope.$listenTo(sqWorkbenchStore, ['currentUser'], syncUser);

  initialize();

  /**
   * Initializes the report editor
   */
  function initialize() {
    if (vm.canModify || vm.isCkEditor) {
      // Initial document must be supplied to Froala via binding. Not used by froala after initialization.
      vm.document = sqReportStore.document;
    } else {
      // Delay one cycle to enable the dom to render
      $interval(() => {
        sqReportEditor.setReportViewHtml(sqReportStore.document);
        sqReportContent.refreshAllContent();
        if (sqUtilities.headlessRenderMode()) {
          $q.resolve(sqUtilities.headlessJobFormat())
            .then((format) => {
              if (format === 'PDF') {
                // We have to replace the urls because we want to make sure that the screenshots we're returning are
                // from the point in time that the PDF Export was initiated, and not at the current point in time --
                // the links should match the content images in the PDF.
                sqFroalaReportContent.setAllContentUrlsToFullUrls();
              }
            });
        }
      }, 0, 1);
    }

    // Delay one cycle to enable the dom to render
    $interval(() => {
      !sqReportEditor.isCkEditor() && sqFroalaReportContent.loadAllPendingContent();
      sqReportContent.subscribeToReport(sqReportStore.id);
    }, 0, 1);

    sqFroalaReportContent.postReportViewed();
    vm.viewInterval = $interval(() => sqFroalaReportContent.postReportViewed(), 1000 * 60 * 10);

    document.addEventListener('copy', event => sqFroalaReportContent.handleCopyHtml());
    document.addEventListener('cut', event => sqFroalaReportContent.handleCopyHtml());
  }

  /**
   * Syncs the Workbenchstore.
   */
  function syncUser() {
    vm.currentUser = sqWorkbenchStore.currentUser;
  }

  /**
   * Sync report store
   */
  function syncReportStore(e) {
    vm.id = sqReportStore.id;
    vm.showBulkEditModal = sqReportStore.showBulkEditModal;
    vm.isScheduleEnabled = sqReportStore.isScheduleEnabled;
    vm.isFixedWidth = sqReportStore.isFixedWidth;
    vm.isSandboxMode = sqReportStore.sandboxMode.enabled;

    if (!_.isEmpty(e) && sqUtilities.propertyChanged(e, 'backupPreview')) {
      vm.backupPreview = _.cloneDeep(sqReportStore.backupPreview);

      // Trust html document so it can be displayed in ng-bind-html without angular sanitizing seeq content
      if (vm.backupPreview) {
        vm.backupPreview.document = $sce.trustAsHtml(vm.backupPreview.document);
      }

      // Delay to enable the dom to render. This needs to be outside of the if statement above because it is what
      // updates the report editor if you close the recent version history display without restoring
      $interval(() => {
        sqFroalaReportContent.cleanup();
      }, NG_IF_WAIT, 1);
    }
  }

  /**
   * Handler for callback notifications that the document has been changed by the user.
   *
   * @param {string} _document - not used, but callback expects this argument
   * @param {boolean} forceSave - true to force the document to save
   * @returns {Promise} Promise that resolves when the document has been saved
   */
  function documentChanged(_document: string, forceSave: boolean): IPromise<any> {
    // We're setting this here to make sure that if a restore backup preview is cancelled, we retain the most
    // recent document changes
    vm.document = sqReportEditor.getHtml();
    if (vm.areDocumentsEqual(sqReportStore.document, sqReportEditor.getHtml()) && !forceSave) {
      return $q.resolve();
    }

    if (sqReportStore.isLoadingReport) {
      return sqReportActions.load(vm.id);
    }

    return sqReportActions.update(vm.id, vm.document);
  }

  /**
   * Callback that handles initialization that must be done before the editor is created
   *
   * @param {Object} editorOptions - object container for editor options
   */
  function beforeEditorOnInit(editorOptions) {
    sqFroalaPlugins.topicDocumentContent();
    sqFroalaPlugins.refreshImage();
    sqFroalaPlugins.pdfExport();
    sqFroalaPlugins.pageBreak();

    const toolbarButtons = [
      'insertSeeqContent', 'insertLink', 'insertImage', 'insertTable', 'pageBreak', '|',
      'paragraphFormat', 'textColor', 'backgroundColor', '|',
      'bold', 'italic', 'underline', 'strikeThrough', '|',
      'align', 'outdent', 'indent', 'formatOL', 'formatUL', '|',
      'clearFormatting', '|',
      'pdfExport', '|',
      'undo', 'redo', '|',
      'seeqContentBorders', 'seeqFixedWidth'];
    _.assign(editorOptions, {
      attribution: false,
      theme: 'topic',
      placeholderText: $translate.instant('REPORT.EDITOR.PLACEHOLDER'),
      toolbarButtons,
      listAdvancedTypes: false,
      imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
      imageEditButtons: [
        'updateSeeqContent', 'imageReplace', 'linkOpen', 'imageLink', 'refreshImage', 'imageAlign', 'imageStyle', '-',
        'imageAlt', 'filteredLinkEdit', 'imageSize', 'imageRemove'
      ]
    });
  }

  /**
   * Callback that handles registration that must be done after the editor is created
   *
   * @param {Object} editor - an editor reference
   */
  function afterEditorOnInit(editor) {
    vm.editor = editor;
    vm.editor.events.on('paste.afterCleanup', html => sqFroalaReportContent.handlePastedHtml(html));
    vm.editor.events.on('paste.beforeCleanup', html => sqFroalaReportContent.beforePasteCleanup(html));
    vm.editor.events.on('image.removed', img => sqFroalaReportContent.handleImageRemoved(img));

    // Froala specific events are also triggered when a single image selected in the editor. This does not happen for
    // listeners added with document.addEventListener.
    // Theoretically it would be enough to only add the listeners below but during testing, when only a part of a
    // table with images was selected, the 'cut' listener was not called at all. Registering listeners here and on the
    // document is not a problem, handleCopyHtml will not add seeq-src twice.
    vm.editor.events.on('window.copy', () => sqFroalaReportContent.handleCopyHtml());
    vm.editor.events.on('window.cut', () => sqFroalaReportContent.handleCopyHtml());

    sqFroalaReportContent.cleanup();
  }

  /**
   * Called by Angular when the controller is destroyed
   */
  function onDestroy() {
    sqReportContent.unsubscribeFromReport();
    $interval.cancel(vm.viewInterval);
    vm.viewInterval = undefined;
    if (vm.isSandboxMode && sqReportStore.reportSchedule) {
      sqReportActions.saveReportSchedule(
        { enabled: false, background: false, cronSchedule: sqReportStore.reportSchedule?.cronSchedule });
    }
  }
}
