import React, { LegacyRef, SyntheticEvent } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Icon } from '@/hybrid/core/Icon.atom';

/**
 * Dropdown menu button with vertical ellipses and dropdown menu items
 */
interface EllipsisDropdownMenuProps {
  iconExtraClass?: string;
  itemsList: {
    classes?: string,
    testid?: string,
    onClick: (event) => void,
    icon?: React.ReactElement,
    label: string
  }[];
}

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef((toggleProps: any, ref: LegacyRef<HTMLAnchorElement>) => (
  <span
    data-testid={`${toggleProps.testId || 'customToggleMenu'}`}
    className={classNames('pl10 btn btn-sm btn-link', { cursorNotAllowed: toggleProps.disabled })}
    ref={ref}
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      if (!toggleProps.disabled) {
        toggleProps.onClick(e);
      }
    }}
  >
    {toggleProps.children}
  </span>
));

export const EllipsisDropdownMenu: React.FunctionComponent<EllipsisDropdownMenuProps> = (props) => {
  const {
    iconExtraClass = '',
    itemsList
  } = props;

  const { t } = useTranslation();

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={CustomToggle}
        testId="specCommentEllipsis">
        <Icon icon={`fc fc-more ${iconExtraClass}`} />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-sm cursorPointer dropdown-menu">
        {itemsList.map((item, index) => (
          <Dropdown.Item
            key={`${index}`}
            className={item.classes}
            eventKey={`${index}`}
            data-testid={`${item.testid || _.camelCase(item.label)}Btn`}
            onClick={(event) => {
              item.onClick(event);
              event.stopPropagation();
              event.preventDefault();
            }}>
            <span>
              {item.icon}
              {t(item.label)}
            </span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
