/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class DatasourcesApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Archive a datasource
   * @param {string} id - ID of the datasource to delete
   */
  public archiveDatasource(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/datasources/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Archive stale hosted items
   * @param {string} id - ID of the datasource to clean up
   * @param {models.DatasourceCleanUpInputV1} body - Input parameters for the Datasource clean-up process
   */
  public cleanUp(
    body: models.DatasourceCleanUpInputV1,
    {
      id,
    } : {
      id: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/datasources/{id}/cleanup'
        .replace('{id}', encodeURIComponent(String(id))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.DatasourceCleanUpOutputV1>;
  }

  /**
   * 
   * @summary Create a datasource
   * @param {models.DatasourceInputV1} body - Datasource information
   */
  public createDatasource(
    body: models.DatasourceInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/datasources',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.DatasourceOutputV1>;
  }

  /**
   * 
   * @summary Get a Datasource
   * @param {string} id - ID of the datasource to retrieve
   */
  public getDatasource(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/datasources/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.DatasourceOutputV1>;
  }

  /**
   * Pagination is enabled for this query
   * @summary Get a collection of datasources
   * @param {string} [datasourceClass] - The datasource class, the name of the underlying source, &#39;CSV File&#39; for example, not specifying this parameter will return datasources of any class
   * @param {string} [datasourceId] - The datasource ID, an identifier that distinguishes a datasource from others of the same datasource class, &#39;timeseries/salt&#39; for example, not specifying this parameter will return datasources of any ID
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   * @param {boolean} [includeArchived=false] - Whether archived datasources should be included
   */
  public getDatasources(
    {
      datasourceClass,
      datasourceId,
      offset,
      limit,
      includeArchived
    } : {
      datasourceClass?: string,
      datasourceId?: string,
      offset?: number,
      limit?: number,
      includeArchived?: boolean
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/datasources',
      params: omitBy({
        ['datasourceClass']: datasourceClass,
        ['datasourceId']: datasourceId,
        ['offset']: offset,
        ['limit']: limit,
        ['includeArchived']: includeArchived
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.DatasourceOutputListV1>;
  }

  /**
   * Pagination is enabled for this query
   * @summary Get a list of the items hosted by this datasource
   * @param {string} id - ID of the datasource
   * @param {string} [dataId] - The dataId for a specific hosted item
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getItemsHostedByDatasource(
    {
      id,
      dataId,
      offset,
      limit
    } : {
      id: string,
      dataId?: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/datasources/{id}/items'
        .replace('{id}', encodeURIComponent(String(id))),
      params: omitBy({
        ['dataId']: dataId,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ItemPreviewListV1>;
  }

  /**
   * 
   * @summary Get statistics describing the health of remote datasources
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getStatus(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/datasources/status',
      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.DatasourceStatusOutputV1>;
  }

  /**
   * 
   * @summary Recount item totals for a datasource. Do not run while datasource is indexing
   * @param {string} id - ID of the datasource to recount
   */
  public recount(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/datasources/{id}/recount'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.DatasourceOutputV1>;
  }

}
