import _ from 'lodash';
import angular from 'angular';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { InvestigateStore } from '@/investigate/investigate.store';
import { TrackService } from '@/track/track.service';
import { AggregationBinActions } from '@/investigate/aggregationBins/aggregationBin.actions';
import { AggregationBinStore } from '@/investigate/aggregationBins/aggregationBin.store';
import { TrendTableActions } from '@/trendData/trendTable.actions';
import { TableHelperService } from '@/trendData/tableHelper.service';
import { NotificationsService } from '@/services/notifications.service';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { ITEM_TYPES } from '@/trendData/trendData.module';
import { API_TYPES, DISPLAY_MODE } from '@/main/app.constants';
import { TIME_BUCKETS } from '@/investigate/aggregationBins/byTime.controller';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { ToolPanelHelperService } from '@/services/toolPanelHelper.service';

export const AGGREGATION_MODES = { Y_VALUE: 'by_y_value', TIME: 'by_time', CONDITION: 'by_condition' };

angular.module('Sq.Investigate')
  .controller('AggregationBinsPanelCtrl', AggregationBinsPanelCtrl);

function AggregationBinsPanelCtrl(
  $scope: ng.IScope,
  $state: ng.ui.IStateService,
  sqInvestigateActions: InvestigateActions,
  sqInvestigateStore: InvestigateStore,
  sqTrack: TrackService,
  sqAggregationBinActions: AggregationBinActions,
  sqAggregationBinStore: AggregationBinStore,
  sqTrendTableActions: TrendTableActions,
  sqTableHelper: TableHelperService,
  sqNotifications: NotificationsService,
  sqToolRunner: ToolRunnerService,
  sqToolPanelHelper: ToolPanelHelperService
) {

  const vm = this;
  vm.DISPLAY_MODE = DISPLAY_MODE;
  vm.paramsValid = false;
  vm.AGGREGATION_MODES = AGGREGATION_MODES;
  vm.TIME_BUCKETS = TIME_BUCKETS;
  vm.ITEM_TYPES = ITEM_TYPES;
  vm.OUTPUT_TYPE = ['histogram'];
  vm.mode = AGGREGATION_MODES.Y_VALUE;
  vm.toolName = TREND_TOOLS.AGGREGATION_BINS_TABLE;
  vm.setSearchName = _.partial(sqInvestigateActions.setSearchName, TREND_TOOLS.AGGREGATION_BINS_TABLE);
  vm.setSignalToAggregate = _.partial(sqInvestigateActions.setParameterItem, TREND_TOOLS.AGGREGATION_BINS_TABLE,
    'signalToAggregate');
  vm.hasValidItemParameters = sqToolPanelHelper.hasValidItemParameters;
  vm.close = sqInvestigateActions.close;
  vm.setStat = sqAggregationBinActions.setStat;
  vm.addAggregation = sqAggregationBinActions.addAggregation;
  vm.setAggregationMode = sqAggregationBinActions.setAggregationMode;
  vm.setAggregationItem = sqAggregationBinActions.setAggregationItem;
  vm.removeAggregation = sqAggregationBinActions.removeAggregation;
  vm.show = show;
  vm.canSubmit = canSubmit;
  vm.run = run;
  vm.setEmptyBuckets = setEmptyBuckets;
  vm.workbookId = $state.params.workbookId;
  vm.getStartNumber = getStartNumber;
  vm.onColorChange = color => vm.color = color;
  vm.isStatisticValid = false;
  vm.setStatisticValidity = isValid => this.isStatisticValid = isValid;

  $scope.$listenTo(sqInvestigateStore, ['displayMode'], setInvestigateVars);
  $scope.$listenTo(sqAggregationBinStore, syncAggregationStore);

  /**
   * Syncs with the Aggregation Store.
   */
  function syncAggregationStore() {
    vm.id = sqAggregationBinStore.id;
    vm.name = sqAggregationBinStore.name;
    vm.originalParameters = sqAggregationBinStore.originalParameters;
    vm.stat = sqAggregationBinStore.stat;
    vm.includeEmptyBuckets = sqAggregationBinStore.includeEmptyBuckets;
    vm.signalToAggregate = sqAggregationBinStore.signalToAggregate;
    vm.aggregationSelections = _.cloneDeep(sqAggregationBinStore.aggregationConfigs);
    vm.paramsValid = _.every(vm.aggregationSelections, 'valid');
  }

  /**
   * Syncs sqInvestigateStore and view-model properties
   */
  function setInvestigateVars() {
    vm.displayMode = sqInvestigateStore.displayMode;
  }

  /**
   * Persists flag that determines if empty buckets should be included in the results display to the store.
   */
  function setEmptyBuckets() {
    sqAggregationBinActions.setEmptyBuckets(vm.includeEmptyBuckets);
  }

  /**
   * Checks to ensure that all the required parameters are provided. Used to activate the "execute" button in the UI.
   */
  function canSubmit() {
    const aggregationItems = _.chain(vm.aggregationSelections)
      .map(_.property('item'))
      .reject(_.isNil)
      .value();
    return !vm.form.$invalid
      && !vm.form.$pending
      && vm.isStatisticValid
      && vm.paramsValid
      && sqInvestigateActions.hasValidName(vm.name)
      && sqToolPanelHelper.hasValidItemParameters(vm.signalToAggregate, ...aggregationItems);
  }

  /**
   * Generates and runs the formula function that creates the appropriate aggregation formula.
   * This function also calls saveUIConfig and ensures that the result is added to the details panel.
   *
   * The formulas that are created are "special" as they support "unbound" parameters, aka parameters that vary based
   * on the view range. This enables a re-run of the same formula for a new display range (instead of re-creation of
   * a new formula). To validate the formula unbound parameters must be also supplied when creating the formula, not
   * just when running it.
   */
  function run() {
    const { formula, parameters } = sqAggregationBinActions.createFormula(vm.signalToAggregate, vm.stat);

    // TODO: Cody Ray Hoeft [Metrics] - Verify that colors on histograms work
    return sqToolRunner.panelExecuteFormulaFunction(API_TYPES.TABLE, {
        formula,
        // add the "unbound" parameters
        parameters: _.concat(parameters, sqTableHelper.getViewCapsuleParameter()),
        name: vm.name
      }, sqAggregationBinStore.configParams, vm.id, vm.color)
      .then(() => {
        sqTrack.doTrack('Workbench_Tool', 'Histogram', 'completed');
      })
      .catch(() => {
        sqTrack.doTrack('Workbench_Tool', 'Histogram', 'error');
      });
  }

  /**
   * Determines if a component should be visible. This is used to switch out the value, condition and time components
   * based on the radio selection.
   *
   * @param {String} mode - one of AGGREGATION_MODES
   * @param {String} id - the id identifying the component
   * @returns {Boolean} true if the component is visible, false otherwise.
   */
  function show(mode, id) {
    return _.get(_.find(vm.aggregationSelections, { id }), 'mode') === mode;
  }

  /**
   * This function returns the first number to be used by the checkMarkOrNumber directive.
   * As all the different aggregation types are separated into their own components this is necessary for everything
   * to "look right"
   *
   * @param {Number} id - the id identifying the component the number is requested for
   * @returns {Number} to use as the first number
   */
  function getStartNumber(id) {
    const idx = _.findIndex(vm.aggregationSelections, { id });
    let i;
    let startNumber = 4;

    for (i = 0; i < idx; i++) {
      if (_.get(vm.aggregationSelections[i], 'mode') !== AGGREGATION_MODES.TIME) {
        startNumber += 2;
      } else {
        startNumber++;
      }
    }

    return startNumber;
  }
}
