import _ from 'lodash';
import bind from 'class-autobind-decorator';
import angular from 'angular';
import { SystemApi } from 'sdk/api/SystemApi';
import { UserGroupsApi } from '@/sdk/api/UserGroupsApi';
import { DateTimeService } from '@/datetime/dateTime.service';
import { SystemWarningActions } from '@/systemWarning/systemWarning.actions';
import { NotificationsService } from '@/services/notifications.service';
import { NotifierService } from '@/services/notifier.service';
import { PriorityV1 } from 'sdk/model/PriorityV1';

export interface BackendDuration {
  value: number;
  uom: string;
}

export interface FrontendDuration {
  value: number;
  units: string;
}

/**
 * Fetches the system configuration upon initialization and caches the result.
 */
@bind
export class SystemConfigurationService {
  private _data = undefined;
  private _supportedUnits = undefined;
  private _everyoneGroupEnabled = true;

  constructor(
    private $q: ng.IQService,
    private sqSystemApi: SystemApi,
    private sqUserGroupsApi: UserGroupsApi,
    private sqDateTime: DateTimeService,
    private sqSystemWarningActions: SystemWarningActions,
    private sqNotifications: NotificationsService,
    private sqNotifier: NotifierService) {
    sqNotifier.onSystemMessage(this.sqSystemWarningActions.setSystemMessage);
  }

  fetch() {
    return this.sqSystemApi.getServerStatus({ includeInternal: false })
      .then(({ data }) => {
        const updatedConfigurationOptions = _.transform(data.configurationOptions, (memo, option) => {
          memo[option.path] = option.value;
        }, {});

        this._data = {
          ...data,
          configurationOptions: updatedConfigurationOptions
        };
        this.sqSystemWarningActions.setSystemWarnings(data.serverSpecs);
        this.sqSystemWarningActions.setSystemMessage(this.systemMessage);
      })
      .catch(this.sqNotifications.apiError);
  }

  isLoaded() {
    return this._data !== undefined;
  }

  // Because we need the user groups API, this has to be done after login
  fetchEveryoneDisabled() {
    return this.sqUserGroupsApi.getUserGroups({ nameSearch: 'Everyone' })
      .then(({ data }) => {
        const everyoneGroup = _.find(data.items, { name: 'Everyone' });
        this._everyoneGroupEnabled = !_.isUndefined(everyoneGroup) && everyoneGroup.isEnabled;
      });
  }

  get assetGroupEditorEnabled() {
    return this.getConfigurationOption('Features/AssetGroupEditor/Enabled');
  }

  // Memoized since it is not expected to change, but only needed in some tools
  get supportedUnits(): ng.IPromise<{ [key: string]: string[]; }> {
    if (_.isUndefined(this._supportedUnits)) {
      this._supportedUnits = this.sqSystemApi.getSupportedUnits().then(({ data: { units } }) => units);
    }

    return this._supportedUnits;
  }

  get serverConfiguration() {
    if (_.isUndefined(this._data)) {
      throw new Error('Configuration must be fetched before being accessed');
    }

    return this._data;
  }

  get defaultMaxInterpolation(): FrontendDuration {
    return this.sqDateTime.convertDuration(this.serverConfiguration.defaultMaxInterpolation);
  }

  get defaultMaxCapsuleDuration(): FrontendDuration {
    return this.sqDateTime.convertDuration(this.serverConfiguration.defaultMaxCapsuleDuration);
  }

  get priorityColors(): PriorityV1[] {
    return this.serverConfiguration.priorities;
  }

  get maxScatterPlotSamples() {
    return this.getConfigurationOption('Features/ScatterPlot/MaxPoints');
  }

  get serverSpecs() {
    return this.serverConfiguration.serverSpecs;
  }

  get restrictLogs() {
    return this.getConfigurationOption('Features/RestrictLogs/Enabled');
  }

  get registrationEnabled() {
    return this.getConfigurationOption('Features/UserRegistration/Enabled');
  }

  get authDefaultProviderId() {
    return this.getConfigurationOption('Authentication/DefaultProviderId');
  }

  get authAutoLogin() {
    return this.getConfigurationOption('Authentication/AutoLogin');
  }

  get isAuthOnContentImageEnabled() {
    return this.getConfigurationOption('Authentication/ContentImage/Enabled');
  }

  get isTelemetryEnabled() {
    return this.getConfigurationOption('Features/Telemetry/Enabled');
  }

  get isTelemetryAnonymized() {
    return this.getConfigurationOption('Features/Telemetry/Anonymized');
  }

  get adminContactName() {
    return this.getConfigurationOption('Installation/AdminContact/Name');
  }

  get adminContactEmail() {
    return this.getConfigurationOption('Installation/AdminContact/Email');
  }

  get defaultNumberFormat() {
    return this.getConfigurationOption('Features/NumberFormat');
  }

  get addOnToolsEnabled() {
    return this.getConfigurationOption('Features/AddOnTools/Enabled');
  }

  get systemMessage() {
    return this.getConfigurationOption('Features/Messages/SystemMessage');
  }

  get capsuleTimeAlignmentOptionsEnabled() {
    return this.getConfigurationOption('Features/CapsuleTimeAlignmentOptions/Enabled');
  }

  get auditingEnabled() {
    return this.getConfigurationOption('Features/Auditing/Enabled');
  }

  get dataLabHostname() {
    return this.getConfigurationOption('Network/DataLab/Hostname');
  }

  get auditingAllUsersCanRead() {
    return this.getConfigurationOption('Features/Auditing/AllUsersCanRead');
  }

  get dayFirstDefault() {
    return this.getConfigurationOption('Features/Datafiles/DayFirstDefault') as boolean;
  }

  get datasourceManagementEnabled() {
    return this.getConfigurationOption('Features/DatasourceManagement/Enabled');
  }

  get delimiterDefault() {
    return this.getConfigurationOption('Features/Datafiles/DelimiterDefault').toLowerCase();
  }

  get officeHoursEnabled() {
    return this.getConfigurationOption('Features/HomeScreen/OfficeHours/Enabled');
  }

  get customSidebar() {
    return this.getConfigurationOption('Features/HomeScreen/CustomSidebar');
  }

  get pluginsEnabled() {
    return this.getConfigurationOption('Features/Plugins/Enabled');
  }

  get hardwareWarningsEnabled() {
    return this.getConfigurationOption('Features/HardwareWarnings/Enabled');
  }

  get everyoneGroupEnabled() {
    return this._everyoneGroupEnabled;
  }

  get isCkEditorEnabled() {
    return !this.getConfigurationOption('Features/DeprecatedIE/Enabled')
      && this.getConfigurationOption('Features/CKEditor/Enabled');
  }

  get isIeEnabled() {
    return this.getConfigurationOption('Features/DeprecatedIE/Enabled');
  }

  get atUserLimit() {
    return this._data?.atUserLimit || false;
  }

  get isTablesChartViewEnabled() {
    return this.getConfigurationOption('Features/TablesChartView/Enabled');
  }

  private getConfigurationOption(path) {
    if (!_.has(this.serverConfiguration.configurationOptions, path)) {
      throw new TypeError(`${path} is not a valid configuration option`);
    }

    return this.serverConfiguration.configurationOptions[path];
  }
}

const dependencies = [
  'Sq.DateTime',
  'Sq.SystemWarning',
  'Sq.Services.Notifications'
];

angular.module('Sq.Services.SystemConfiguration', dependencies)
  .service('sqSystemConfiguration', SystemConfigurationService);
