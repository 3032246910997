import _ from 'lodash';
import tinycolor from 'tinycolor2';
import { MouseEvent } from 'react';
import bind from 'class-autobind-decorator';
import { TableBuilderColumnFilter, TableBuilderStore } from '@/hybrid/tableBuilder/tableBuilder.store';
import {
  TableBuilderColumnType,
  TableBuilderHeaderType,
  TableBuilderMode
} from '@/hybrid/tableBuilder/tableBuilder.module';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.module';
import { CalculationRunnerService } from '@/services/calculationRunner.service';
import { PUSH_IGNORE } from '@/services/stateSynchronizer.service';
import { TrendActions } from '@/trendData/trend.actions';
import { DateTimeService } from '@/datetime/dateTime.service';
import { DurationStore } from '@/trendData/duration.store';
import { NumberHelperService } from '@/core/numberHelper.service';
import { ScorecardStore } from '@/investigate/scorecard/scorecard.store';
import { TrendMetricStore } from '@/trendData/trendMetric.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { ProcessTypeEnum } from 'sdk/model/ThresholdMetricOutputV1';
import { TrendStore } from '@/trendData/trend.store';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { COLUMNS_AND_STATS, ITEM_TYPES, PropertyColumn, StatisticColumn } from '@/trendData/trendData.module';
import { SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.module';
import { METRIC_COLORS } from '@/investigate/investigate.module';
import { ItemsApi, MetricsApi } from '@/sdk';
import { NotificationsService } from '@/services/notifications.service';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { UtilitiesService } from '@/services/utilities.service';
import { HttpHelpersService } from '@/services/httpHelpers.service';
import { SearchResultUtilitiesService } from '@/hybrid/search/searchResult.utilities.service';
import { FormulaService } from '@/services/formula.service';

@bind
export class TableBuilderActions {
  constructor(
    private $q: ng.IQService,
    private $state: ng.ui.IStateService,
    private $injector: ng.auto.IInjectorService,
    private $translate: ng.translate.ITranslateService,
    private flux: ng.IFluxService,
    private sqDateTime: DateTimeService,
    private sqDurationStore: DurationStore,
    private sqNumberHelper: NumberHelperService,
    private sqScorecardStore: ScorecardStore,
    private sqMetricsApi: MetricsApi,
    private sqTrendStore: TrendStore,
    private sqTrendDataHelper: TrendDataHelperService,
    private sqTrendMetricStore: TrendMetricStore,
    private sqTableBuilderStore: TableBuilderStore,
    private sqWorksheetActions: WorksheetActions,
    private sqWorksheetStore: WorksheetStore,
    private sqPendingRequests: PendingRequestsService,
    private sqItemsApi: ItemsApi,
    private sqNotifications: NotificationsService,
    private sqSystemConfiguration: SystemConfigurationService,
    private sqUtilities: UtilitiesService,
    private sqHttpHelpers: HttpHelpersService,
    private sqFormula: FormulaService,
    private AUTO_ERROR_CLOSE_INTERVAL) {
  }

  DATA_CANCELLATION_GROUP = 'tableBuilder';
  HOMOGENIZE_UNIT_FORMULA_EXCEPTIONS =
    ['is not compatible with', 'non-linear, cannot convert', 'Rows with different units are not allowed'];

  /**
   * Sets the mode of the table builder
   *
   * @param mode - The mode
   */
  setMode(mode: TableBuilderMode) {
    this.flux.dispatch('TABLE_BUILDER_SET_MODE', { mode });
    this.fetchTable();
  }

  /**
   * Adds a column to the table that the user can use to input free-form text.
   */
  addTextColumn() {
    this.flux.dispatch('TABLE_BUILDER_ADD_COLUMN', { type: TableBuilderColumnType.Text });
  }

  /**
   * Adds an item property column to the table
   *
   * @param propertyName - The property name
   */
  addPropertyColumn(propertyName: string) {
    this.flux.dispatch('TABLE_BUILDER_ADD_COLUMN', { type: TableBuilderColumnType.Property, propertyName });
    this.fetchTable();
  }

  /**
   * Removes the specified column from the table
   *
   * @param key - The key that identifies the column
   */
  removeColumn(key: string) {
    if (_.has(_.find(this.sqTableBuilderStore.columns, { key }), 'filter')) {
      this.setColumnFilter(key, undefined);
    }
    this.flux.dispatch('TABLE_BUILDER_REMOVE_COLUMN', { key });
  }

  /**
   * Adds or removes a particular column into the table builder store.
   *
   * @param column - The column being toggled. One of COLUMNS_AND_STATS
   * @param [signalId] - The series if it is a statistic column for condition table
   */
  toggleColumn(column: PropertyColumn | StatisticColumn, signalId: string = null) {
    if (this.sqTableBuilderStore.isColumnEnabled(column, signalId)) {
      this.removeColumn(this.sqTableBuilderStore.getColumnKey(column, signalId));
    } else {
      const uom = COLUMNS_AND_STATS.valueUnitOfMeasure;
      const disableUnitHomogenization = column.key === uom.key && !_.isUndefined(this.sqTableBuilderStore.assetId)
        && this.sqTableBuilderStore.isHomogenizeUnits;
      if (disableUnitHomogenization) {
        this.sqNotifications.infoTranslate('TABLE_BUILDER.UNIT_HOMOGENIZATION_DISABLED');
        this.setHomogenizeUnits(false, false);
      }

      this.flux.dispatch('TABLE_BUILDER_ADD_COLUMN', { column, signalId });
      this.fetchTable();
    }
  }

  /**
   * Moves the specified column to a new position
   *
   * @param key - The key that identifies the column.
   * @param newKey - The key that identifies the column that will be the new position
   */
  moveColumn(key: string, newKey: string) {
    this.flux.dispatch('TABLE_BUILDER_MOVE_COLUMN', { key, newKey });
  }

  /**
   * Sets the background color for a table column (header excluded).
   *
   * @param key - The key that identifies the column.
   * @param color - Background color for the column
   */
  setColumnBackground(key: string, color: string) {
    this.flux.dispatch('TABLE_BUILDER_SET_COLUMN_BACKGROUND', { key, color });
  }

  /**
   * Sets the text alignment for a table column (header excluded).
   *
   * @param key - The key that identifies the column.
   * @param align - CSS text-align value
   */
  setColumnTextAlign(key: string, align: string) {
    this.flux.dispatch('TABLE_BUILDER_SET_COLUMN_TEXT_ALIGN', { key, align });
  }

  /**
   * Sets the text color for a table column (header excluded).
   *
   * @param key - The key that identifies the column.
   * @param color - Text color
   */
  setColumnTextColor(key: string, color: string) {
    this.flux.dispatch('TABLE_BUILDER_SET_COLUMN_TEXT_COLOR', { key, color });
  }

  /**
   * Sets the text style for a table column (header excluded).
   *
   * @param key - The key that identifies the column.
   * @param style - zero or more text style attributes
   */
  setColumnTextStyle(key: string, style: string[]) {
    this.flux.dispatch('TABLE_BUILDER_SET_COLUMN_TEXT_STYLE', { key, style });
  }

  /**
   * Sets the background color for a table header.
   *
   * @param key - The key that identifies the column.
   * @param color - Background color for the header
   */
  setHeaderBackground(key: string, color: string) {
    this.flux.dispatch('TABLE_BUILDER_SET_HEADER_BACKGROUND', { key, color });
  }

  /**
   * Sets the text alignment for a table header.
   *
   * @param key - The key that identifies the column.
   * @param align - CSS text-align value
   */
  setHeaderTextAlign(key: string, align: string) {
    this.flux.dispatch('TABLE_BUILDER_SET_HEADER_TEXT_ALIGN', { key, align });
  }

  /**
   * Sets the text color for a table header.
   *
   * @param key - The key that identifies the column.
   * @param color - Text color
   */
  setHeaderTextColor(key: string, color: string) {
    this.flux.dispatch('TABLE_BUILDER_SET_HEADER_TEXT_COLOR', { key, color });
  }

  /**
   * Sets the text style for a table header.
   *
   * @param key - The key that identifies the column.
   * @param style - zero or more text style attributes
   */
  setHeaderTextStyle(key: string, style: string[]) {
    this.flux.dispatch('TABLE_BUILDER_SET_HEADER_TEXT_STYLE', { key, style });
  }

  /**
   * Applies the formatting of the specified column to all columns (headers excluded)
   * @param key - The key that identifies the column.
   */
  setStyleToAllColumns(key: string) {
    this.flux.dispatch('TABLE_BUILDER_SET_STYLE_TO_ALL_COLUMNS', { key });
  }

  /**
   * Applies the formatting of the specified column to all headers
   * @param key - The key that identifies the column.
   */
  setStyleToAllHeaders(key: string) {
    this.flux.dispatch('TABLE_BUILDER_SET_STYLE_TO_ALL_HEADERS', { key });
  }

  /**
   * Applies the formatting of the specified column&header to all columns and headers
   * @param key - The key that identifies the column.
   */
  setStyleToAllHeadersAndColumns(key: string) {
    this.flux.dispatch('TABLE_BUILDER_SET_STYLE_TO_ALL_HEADERS_AND_COLUMNS', { key });
  }

  /**
   * Copies the formatting of the specified column&header.
   * @param key - The key that identifies the column.
   */
  copyStyle(key: string) {
    this.flux.dispatch('TABLE_BUILDER_COPY_STYLE', { key });
  }

  /**
   * Applies the copied formatting to the specified column header
   * @param key - The key that identifies the column.
   */
  pasteStyleOnHeader(key: string) {
    this.flux.dispatch('TABLE_BUILDER_PASTE_STYLE_ON_HEADER', { key });
  }

  /**
   * Applies the copied formatting to the specified column (header excluded)
   * @param key - The key that identifies the column.
   */
  pasteStyleOnColumn(key: string) {
    this.flux.dispatch('TABLE_BUILDER_PASTE_STYLE_ON_COLUMN', { key });
  }

  /**
   * Applies the copied formatting to the specified column&header
   * @param key - The key that identifies the column.
   */
  pasteStyleOnHeaderAndColumn(key: string) {
    this.flux.dispatch('TABLE_BUILDER_PASTE_STYLE_ON_HEADER_AND_COLUMN', { key });
  }

  /**
   * Filter a column in the Simple Table
   *
   * @param key - The key that identifies the column.
   * @param filter - filter to apply to the column
   */
  setColumnFilter(key: string, filter: TableBuilderColumnFilter) {
    this.flux.dispatch('TABLE_BUILDER_SET_COLUMN_FILTER', { key, filter });
    this.fetchTable();
  }

  sortByColumn(key: string, direction: string) {
    this.flux.dispatch('TABLE_BUILDER_SORT_BY_COLUMN', { key, direction });
    this.fetchTable();
  }

  /**
   * Sets the text for a scorecard column cell or header.
   *
   * @param key - The key that identifies the column.
   * @param text - Text for the cell
   * @param [cellKey] - The identifier for the cell. If not specified the column header text will be set.
   */
  setCellText(key: string, text: string, cellKey?: string) {
    this.flux.dispatch('TABLE_BUILDER_SET_CELL_TEXT', { key, text, cellKey });
  }

  /**
   * Sets the text for a table builder column header.
   *
   * @param columnKey - The key that identifies the column.
   * @param text - Text for the header
   */
  setHeaderText(columnKey: string, text: string) {
    this.flux.dispatch('TABLE_BUILDER_SET_HEADER_TEXT', { columnKey, text });
  }

  /**
   * Sets the header override flag for a column. Other column overrides will be disabled.
   *
   * @param columnKey - The key that identifies the column.
   */
  setHeaderOverridden(columnKey: string) {
    this.flux.dispatch('TABLE_BUILDER_SET_HEADER_OVERRIDE', { columnKey });
  }

  /**
   * Sets the header type for either scorecard columns that display the metric values or the name column for simple
   * tables.
   *
   * @param type - The type of header to display
   */
  setHeadersType(type: TableBuilderHeaderType) {
    this.flux.dispatch('TABLE_BUILDER_SET_HEADERS_TYPE', { type });
    if (type === TableBuilderHeaderType.CapsuleProperty) {
      this.fetchTable();
    }
  }

  /**
   * Sets the date format used for headers of metric value columns/name column when the type is one of the date types.
   *
   * @param format - A string that can be passed to moment's format()
   */
  setHeadersFormat(format: string) {
    this.flux.dispatch('TABLE_BUILDER_SET_HEADERS_FORMAT', { format });
  }

  /**
   * Sets the name of the capsule property used for headers of metric value columns when the type is CapsuleProperty.
   *
   * @param property - The capsule property name
   */
  setHeadersProperty(property: string) {
    this.flux.dispatch('TABLE_BUILDER_SET_HEADERS_PROPERTY', { property });
    this.fetchTable();
  }

  setIsTransposed(isTransposed: boolean) {
    this.flux.dispatch('TABLE_BUILDER_SET_IS_TRANSPOSED', { isTransposed });
  }

  /**
   * Sets or clears the asset id so the table can be run across all the child assets of the asset.
   *
   * @param assetId - The root asset to run the formula across or undefined to clear.
   */
  setAssetId(assetId: string | undefined) {
    this.flux.dispatch('TABLE_BUILDER_SET_ASSET_ID', { assetId });
    if (assetId) {
      this.flux.dispatch('TABLE_BUILDER_ADD_COLUMN', { column: { key: 'asset' } });
    } else {
      this.flux.dispatch('TABLE_BUILDER_REMOVE_COLUMN', { key: 'asset' });
    }

    this.fetchTable();
  }

  /**
   * Sets the homogenizeUnits attribute and removes the UOM column when the units are homogenized. In this case, we do
   * not want to show the UOM column. The unit of some values might be different than the unit of the item
   * @param homogenizeUnits - The homogenize units value
   * @param fetchTable - When true, it fetches the table
   * @return void if only homogenizeUnits is set. When fetch table is needed it returns a promise resolves when the
   * table has been been fetched
   */
  setHomogenizeUnits(homogenizeUnits: boolean, fetchTable: boolean = false): void | ng.IPromise<void | any[]> {
    this.flux.dispatch('TABLE_BUILDER_SET_HOMOGENIZE_UNITS', { homogenizeUnits });
    if (homogenizeUnits && this.sqTableBuilderStore.isSimpleMode() &&
      this.sqTableBuilderStore.isColumnEnabled(COLUMNS_AND_STATS.valueUnitOfMeasure)) {
      const key = COLUMNS_AND_STATS.valueUnitOfMeasure.key;
      this.flux.dispatch('TABLE_BUILDER_REMOVE_COLUMN', { key });
      this.sqNotifications.infoTranslate('TABLE_BUILDER.UNIT_COLUMN_REMOVED');
    }

    if (fetchTable) {
      return this.fetchTable();
    }
  }

  /**
   * Changes to the specified the asset id only if a current asset is already set and the new one is different.
   *
   * @param assetId - The asset to change to
   */
  changeAssetId(assetId: string) {
    if (this.sqTableBuilderStore.assetId && this.sqTableBuilderStore.assetId !== assetId) {
      this.setAssetId(assetId);
    }
  }

  setIsMigrating(isMigrating: boolean) {
    this.flux.dispatch('TABLE_BUILDER_SET_IS_MIGRATING', { isMigrating });
  }

  setIsTableStriped(isTableStriped: boolean) {
    this.flux.dispatch('TABLE_BUILDER_SET_IS_TABLE_STRIPED', { isTableStriped });
  }

  setShowChartView(showChart: boolean) {
    this.flux.dispatch('TABLE_BUILDER_SET_CHART_VIEW', { enabled: showChart });
  }

  setChartViewSettings(settings: any) {
    this.flux.dispatch('TABLE_BUILDER_SET_CHART_VIEW_SETTINGS', { settings: { ...settings } });
  }

  /**
   * Remove the v1 metric.
   *
   * @param {string} metricId - The id of the metric
   */
  removeOldMetric(metricId) {
    this.flux.dispatch('SCORECARD_REMOVE_METRIC', { metricId });
  }

  /**
   * Fetches and dispatches the table data.
   *
   * @return {Promise} A promise that resolves when the table has been been fetched
   */
  fetchTable(): ng.IPromise<void | any[]> {
    if (this.sqWorksheetStore.view.key !== WORKSHEET_VIEW.TABLE) {
      return this.$q.resolve();
    }
    this.sqPendingRequests.cancelGroup(this.DATA_CANCELLATION_GROUP, false);
    if (this.sqScorecardStore.metrics.length) {
      return this.fetchOldMetrics();
    } else if (this.sqTableBuilderStore.isSimpleMode()) {
      return this.fetchSimpleTableData();
    } else {
      return this.fetchConditionTableData();
    }
  }

  /**
   * Fetches and dispatches the simple table data.
   *
   * @return A promise that resolves when the table has been been fetched
   */
  fetchSimpleTableData(): ng.IPromise<void | any[]> {
    const {
      formula,
      reduceFormula,
      parameters,
      root,
      columnPositions
    } = this.sqTableBuilderStore.getSimpleTableFetchParams();
    if (_.isEmpty(formula)) {
      return this.$q.resolve();
    }

    const sqTrendActions = this.$injector.get<TrendActions>('sqTrendActions');
    const itemIds = _.chain(parameters).values().filter(this.sqUtilities.validateGuid).value();
    _.forEach(itemIds, (id) => {
      this.flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id }, PUSH_IGNORE);
    });

    return this.sqFormula.computeTable({
        formula,
        parameters,
        reduceFormula,
        root,
        cancellationGroup: this.DATA_CANCELLATION_GROUP,
        usePost: true // Formula can be very long
      })
      .then((results) => {
        this.flux.dispatch('TABLE_BUILDER_PUSH_SIMPLE_DATA',
          { data: results.data, headers: results.headers, columnPositions }, PUSH_IGNORE);
        _.forEach(itemIds, (id) => {
          this.flux.dispatch('TREND_SET_DATA_STATUS_PRESENT',
            { id, warningCount: results.warningCount, warningLogs: results.warningLogs }, PUSH_IGNORE);
        });
      })
      .then(() => this.fetchSimpleTableDistinctStringValues())
      .catch((e) => {
        const message = e?.data?.message ? e.data.message : e?.data?.statusMessage;
        const shouldFallbackToUnitless =
          !!this.sqTableBuilderStore.assetId &&
          this.HOMOGENIZE_UNIT_FORMULA_EXCEPTIONS.some(err => message.includes(err));

        if (shouldFallbackToUnitless) {
          this.sqNotifications.warnTranslate('TABLE_BUILDER.INCOMPATIBLE_UNITS_ACROSS_ASSETS', { message });
          this.flux.dispatch('TABLE_BUILDER_ADD_COLUMN', { column: COLUMNS_AND_STATS.valueUnitOfMeasure });
          return this.setHomogenizeUnits(false, true);
        } else {
          this.setErrorUponFormulaFailure(e, TableBuilderMode.Simple);
          return this.$q.all(_.map(itemIds, id =>
            sqTrendActions.catchItemDataFailure(id, this.DATA_CANCELLATION_GROUP, e)));
        }
      });
  }

  /**
   * Fetch the distinct string values for each string-valued column in the Simple Table.
   * Noops in presentation mode since the filters can't be changed.
   */
  fetchSimpleTableDistinctStringValues(): Promise<void> {
    if (this.sqUtilities.isPresentationWorkbookMode) {
      return Promise.resolve();
    }

    const {
      fetchParamsList,
      columnKeysNamesList
    } = this.sqTableBuilderStore.getSimpleTableDistinctStringColumnFetchParams();
    return Promise.all(_.map(fetchParamsList, tableFetchParam =>
        this.sqFormula.computeTable({
          formula: tableFetchParam.formula,
          parameters: tableFetchParam.parameters,
          reduceFormula: tableFetchParam.reduceFormula,
          root: tableFetchParam.root,
          cancellationGroup: this.DATA_CANCELLATION_GROUP
        })))
      .then((stringValueTables) => {
        this.flux.dispatch('TABLE_BUILDER_SET_SIMPLE_DISTINCT_STRING_VALUES',
          { stringValueTables, columnKeysNamesList });
      });
  }

  /**
   * Fetches and dispatches the condition table data.
   *
   * @return A promise that resolves when the table has been been fetched
   */
  fetchConditionTableData(): ng.IPromise<void | any[]> {
    const {
      ids: itemIds,
      assetId,
      propertyColumns,
      statColumns,
      customPropertyName,
      reduceFormula,
      sortParams,
      buildAdditionalFormula,
      itemColumnsMap,
      buildConditionFormula,
      buildStatFormula
    } = this.sqTableBuilderStore.getConditionTableFetchParams();

    if (_.isEmpty(itemIds)) {
      return this.$q.resolve();
    }

    _.forEach(itemIds, (id) => {
      this.flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id }, PUSH_IGNORE);
    });

    return this.sqFormula.computeCapsuleTable({
        columns: { propertyColumns, statColumns },
        range: this.sqDurationStore.displayRange,
        itemIds,
        buildConditionFormula,
        sortParams,
        root: assetId,
        reduceFormula,
        buildAdditionalFormula,
        buildStatFormula,
        offset: 0,
        limit: 10000,
        cancellationGroup: this.DATA_CANCELLATION_GROUP
      })
      .then(({ warningCount, warningLogs, data: { headers, table } }) => {
        this.flux.dispatch('TABLE_BUILDER_PUSH_CONDITION_DATA', { headers, table, itemColumnsMap, customPropertyName },
          PUSH_IGNORE);
        _.forEach(itemIds, (id) => {
          this.flux.dispatch('TREND_SET_DATA_STATUS_PRESENT', { id, warningCount, warningLogs }, PUSH_IGNORE);
        });
      })
      .then(() => this.fetchConditionTableDistinctStringValues())
      .catch((e) => {
        const message = e?.data?.statusMessage;
        const shouldFallbackToUnitless =
          !!this.sqTableBuilderStore.assetId &&
          this.HOMOGENIZE_UNIT_FORMULA_EXCEPTIONS.some(err => message.includes(err));

        if (shouldFallbackToUnitless) {
          this.sqNotifications.warnTranslate('TABLE_BUILDER.INCOMPATIBLE_UNITS_ACROSS_ASSETS', { message });
          return this.setHomogenizeUnits(false, true);
        } else {
          this.setErrorUponFormulaFailure(e, TableBuilderMode.Condition);
          return this.$q.all(_.map(itemIds, id => this.$injector.get<TrendActions>('sqTrendActions')
            .catchItemDataFailure(id, this.DATA_CANCELLATION_GROUP, e)));
        }
      });
  }

  /**
   * Fetch the distinct string values for each string-valued column in the Condition Table.
   * Noops in presentation mode since the filters can't be changed.
   */
  fetchConditionTableDistinctStringValues(): Promise<void> {
    if (this.sqUtilities.isPresentationWorkbookMode) {
      return Promise.resolve();
    }

    let distinctStringValueColumnKeysNames;
    return this.sqTableBuilderStore.getConditionTableDistinctStringColumnFetchParams()
      .then(({ fetchParamsList, columnKeysNamesList }) => {
        distinctStringValueColumnKeysNames = columnKeysNamesList;
        return this.$q.all(_.map(fetchParamsList, (tableFetchParam) => {
          return this.sqFormula.computeCapsuleTable({
              columns: { propertyColumns: tableFetchParam.propertyColumns, statColumns: tableFetchParam.statColumns },
              range: this.sqDurationStore.displayRange,
              itemIds: tableFetchParam.ids,
              buildConditionFormula: tableFetchParam.buildConditionFormula,
              sortParams: tableFetchParam.sortParams,
              root: tableFetchParam.assetId,
              reduceFormula: tableFetchParam.reduceFormula,
              buildAdditionalFormula: tableFetchParam.buildAdditionalFormula,
              buildStatFormula: tableFetchParam.buildStatFormula,
              offset: 0,
              limit: 10000,
              cancellationGroup: this.DATA_CANCELLATION_GROUP
            })
            // CRAB-27265: Clears the provided filters, which defaults to matching by provided string
            .catch(() => []);
        }));
      })
      .then((stringValueTables) => {
        const indicesToRemove = [];
        const filteredValueTables = _.chain(stringValueTables)
          .forEach((valueTable, index) => {
            if (_.isEmpty(valueTable)) {
              indicesToRemove.push(index);
            }
          })
          .reject(_.isEmpty)
          .value();
        _.pullAt(distinctStringValueColumnKeysNames, indicesToRemove);
        this.flux.dispatch('TABLE_BUILDER_SET_CONDITION_DISTINCT_STRING_VALUES',
          { stringValueTables: filteredValueTables, columnKeysNamesList: distinctStringValueColumnKeysNames });
      });
  }

  /**
   * Sets an error in the store if there is an error running the formula
   *
   * @param error - The error from running the formula
   * @param mode - The mode in which it occurred
   */
  setErrorUponFormulaFailure(error, mode) {
    if (!this.sqHttpHelpers.isCanceled(error)) {
      const fetchFailedMessage = this.sqUtilities.formatApiError(error) ||
        this.$translate.instant('LOGIN_PANEL.FRONTEND_ERROR');
      this.flux.dispatch('TABLE_BUILDER_SET_FETCH_FAILED_MESSAGE', { fetchFailedMessage, mode });
    }
  }

  /**
   * Performs all necessary steps to execute all v1 metrics using the current display range.
   *
   * @return Promise that resolves when the cell is computed
   */
  fetchOldMetrics(): ng.IPromise<void> {
    return _.chain(this.sqScorecardStore.metrics)
      .map((metric) => {
        const statFragment = this.$injector.get<CalculationRunnerService>('sqCalculationRunner')
          .getStatisticFragment(metric.stat);
        const formula = `$series.aggregate(${statFragment}, ${this.sqDateTime.getCapsuleFormula(
          this.sqDurationStore.displayRange)})`;
        return this.sqFormula.computeScalar({ formula, parameters: { series: metric.itemId } })
          .then((result) => {
            const payload = _.assign({
                metricId: metric.metricId,
                valueResult: this.sqNumberHelper.formatNumber(result.value) + ' ' + result.uom
              },
              this.computeColorForOldMetric(metric, result.value));

            this.flux.dispatch('SCORECARD_VALUE_RESULT', payload);
          });
      })
      .thru(promises => this.$q.all(promises))
      .value()
      // Noop at the end so we have a void return type
      .then(() => { });
  }

  /**
   * Compute the color for a given value. This finds the maximum threshold value that is less or equal to the value,
   * and returns that. It also computes the contrasting color for the foreground.
   *
   * @param {Object} metric - The metric object
   * @param {Object} metric.thresholds - Array of threshold objects
   * @param {Number} value - The value to choose colors for
   * @returns {{backgroundColor: string, foregroundColor: string}} - Map of background and foreground colors
   */
  computeColorForOldMetric(metric, value) {
    const color = (_.chain(metric.thresholds)
      .filter(function(t: any) {
        return !_.isUndefined(t.isMinimum) || t.threshold <= value;
      })
      .head() as any)
      .get('color', '#ddd')
      .value();
    return {
      backgroundColor: color,
      foregroundColor: tinycolor(color).isDark() ? '#fff' : '#000'
    };
  }

  /**
   * Displays a metric on the trend with a specific time region of the chart highlighted. It also takes care of
   * swapping to the new asset if the table is going across assets and the row that the user is clicking on is from a
   * different asset than the one currently being shown.
   *
   * @param metricId - The metric identifier
   * @param itemId - The id of the actual item in the row. This can be different from the metricId when swapping
   * @param start - The start time of the window to highlight
   * @param end - The end time of the window to highlight
   * @param event - The angular click event
   */
  displayMetricOnTrend(metricId: string, itemId: string, start: number, end: number, event: MouseEvent) {
    // @ts-ignore
    if (event.view?.getSelection().toString().length > 0) {
      return; // noop if the user is selecting the value
    }

    let metric = _.find(this.sqTrendDataHelper.getAllItems({ itemTypes: [ITEM_TYPES.METRIC] }), { id: metricId });
    const isItemPresent = _.some(this.sqTrendDataHelper.getAllItems(), { id: itemId });
    let promise;
    if (!this.sqTableBuilderStore.assetId || isItemPresent) {
      promise = this.$q.resolve();
    } else {
      // User clicked on a metric whose item is not in the details pane, so swap to its parent
      promise = this.sqFormula.getDependencies({ id: itemId })
        .then(({ assets }) => {
          if (assets.length && assets[0].pathComponentIds.length) {
            return this.$injector.get<SearchResultUtilitiesService>('sqSearchResultService')
              .swapAsset({ id: _.last(assets[0].pathComponentIds) })
              .then(() => {
                // Actual item clicked should now be in the details pane
                metric = _.find(this.sqTrendDataHelper.getAllItems({ itemTypes: [ITEM_TYPES.METRIC] }), { id: itemId });
              });
          }
        });
    }

    promise
      .then(() => {
        const sqTrendActions = this.$injector.get<TrendActions>('sqTrendActions');
        this.sqWorksheetActions.setView(WORKSHEET_VIEW.TREND);

        const isSimpleMetric = _.get(metric, 'definition.processType') === ProcessTypeEnum.Simple;
        const isBatchMetric = _.get(metric, 'definition.processType') === ProcessTypeEnum.Condition;
        const boundingCondition = _.get(metric, 'definition.boundingCondition', {});
        if (isBatchMetric && boundingCondition.id) {
          sqTrendActions.addItem(boundingCondition).then(bc => sqTrendActions.setItemSelected(bc, true));
        }

        _.forEach(this.sqTrendDataHelper.getAllItems(),
          item => sqTrendActions.setItemSelected(item, item.id === metric.id));

        if (isSimpleMetric) {
          sqTrendActions.addItem(metric.definition.measuredItem)
            .then(() => sqTrendActions.alignMeasuredItemWithMetric(metric));
        }

        if (this.sqDurationStore.displayRange.start.valueOf() !== start ||
          this.sqDurationStore.displayRange.end.valueOf() !== end) {
          sqTrendActions.setSelectedRegion(start, end);
        }

        if (!this.sqTrendStore.hideUnselectedItems) {
          sqTrendActions.toggleHideUnselectedItems();
        }
      });
  }

  /**
   * Migrates old scorecard to be backend threshold metric items.
   */
  migrate() {
    this.setIsMigrating(true);
    this.setMode(TableBuilderMode.Simple);
    this.setHeadersType(TableBuilderHeaderType.None);
    this.removeColumn(COLUMNS_AND_STATS['statistics.average'].key);
    this.toggleColumn(COLUMNS_AND_STATS.metricValue);
    _.chain(this.sqScorecardStore.metrics)
      .map((metric: any) => this.getName(metric)
        .then(name => this.sqMetricsApi.createThresholdMetric({
          name,
          measuredItem: metric.itemId,
          aggregationFunction: this.$injector.get<CalculationRunnerService>(
            'sqCalculationRunner').getStatisticFragment(
            metric.stat),
          thresholds: this.getThresholds(metric)
        }))
        .then(({ data: item }) => {
          this.removeOldMetric(metric.metricId);
          return item;
        })
        .catch(this.sqNotifications.apiError))
      .thru(promises => this.$q.all(promises))
      .value()
      // Important that they are added in order to preserve the sort
      .then(items => this.$q.all(_.map(items, (item) => {
        const promise = this.$injector.get<TrendActions>('sqTrendActions').addItem(item);
        this.$injector.get<TrendActions>('sqTrendActions').setItemSelected(item, true);
        return promise;
      })))
      .finally(() => {
        this.setIsMigrating(false);
        this.sqNotifications.successTranslate('TABLE_BUILDER.MIGRATION_SUCCESS', {},
          { delay: this.AUTO_ERROR_CLOSE_INTERVAL });
      });
  }

  /**
   * Returns a name for the metric. Specifically handles the case of metric that had an empty name. It is not
   * guaranteed to be unique. Instead, since the backend does not enforce uniqueness, it assumes the user will figure
   * out the best way to disambiguate duplicate names when they edit it.
   *
   * @param {Object} metric - The metric
   * @return {Promise<String>} - Promise that resolves with a name for the metric
   */
  getName(metric) {
    return this.$q.resolve(_.trim(metric.name))
      .then((name) => {
        if (_.isEmpty(name)) {
          return this.sqItemsApi.getItemAndAllProperties({ id: metric.itemId })
            .then(({ data: item }) => {
              const statTitle = this.$translate.instant(
                _.get(_.find(SAMPLE_FROM_SCALARS.VALUE_METHODS, ['key', _.get(metric.stat, 'key')]),
                  'title'));
              return `${statTitle} ${item.name}`;
            });
        } else {
          return name;
        }
      })
      .then((name) => {
        return this.sqFormula.getDefaultName(name, this.$state.params.workbookId);
      })
      .then((defaultName) => {
        // getDefaultName name will add a suffix, but if that suffix is 1 the name is unique so we don't need the
        // number
        if (_.endsWith(defaultName, ' 1')) {
          return defaultName.substr(0, defaultName.length - 2);
        } else {
          return defaultName;
        }
      });
  }

  /**
   * Gets the thresholds for a metric, mapped to the new priority levels. Makes no assumptions that the colors are
   * the same, but instead makes the assumption that the user ordered their metrics with the same priority order as
   * the order presented by METRIC_COLORS. It has the following known limitations:
   *  - It can assign the same level to two different thresholds if there is no corresponding new priority. This
   *  could happen, for example, if user used both the green and blue as thresholds, since blue was removed in the
   *  new scorecard.
   *  - If the thresholds have the colors in a random order, that order will not be preserved since the order cannot
   *  be changed for the new priorities.
   *  - If the user defined more thresholds than the number of new priorities then some of the old thresholds will
   *  be lost.
   *
   * @param {Object} metric - The metric
   * @return {String[]} Array of thresholds in the format of priorityLevel=value
   */
  getThresholds(metric): string[] {
    const highPriorities = _.filter(this.sqSystemConfiguration.priorityColors, priority => priority.level > 0);
    const lowercaseMetricColors = _.map(METRIC_COLORS, _.toLower);
    const priorityConversionMap = _.chain(lowercaseMetricColors)
      .initial() // discard the last color (white) since neutral is not included in priorities
      .reverse() // reverse it so that the indices correspond with the priority levels
      .transform((result, color, i) => {
        // Use the index to find the corresponding priority. Green's index is zero and with this algorithm that
        // means it ends up as the neutral priority, but that is ok since R21 moved green to a neutral color. The
        // rest of the colors will then map to their new corresponding priority level.
        result[color] = _.get(_.find(highPriorities, { level: i }), 'level', _.last(highPriorities).level);
      }, {} as { [s: string]: number })
      .value();

    // Needed because some old scorecards somehow have some of their colors as strings instead of hex codes
    const thresholds = _.map(metric.thresholds, (threshold: any) => {
      // yellow yields a different hex code
      const colorObj = threshold.color === 'yellow' ? tinycolor(METRIC_COLORS[1]) : tinycolor(threshold.color);
      const color = colorObj.isValid() ? colorObj.toHexString() : threshold.color;
      return { ...threshold, color };
    });

    return _.chain(thresholds)
      .transform((result, threshold: any, i) => {
        // Last one will not have a threshold value
        if (i === thresholds.length - 1) {
          return;
        }

        const currentColorIndex = _.indexOf(lowercaseMetricColors, threshold.color);
        const nextColorIndex = _.indexOf(lowercaseMetricColors, thresholds[i + 1].color);
        // Can tell if the priorities are high or low since the old METRIC_COLORS went from high to low
        const isHigh = currentColorIndex <= nextColorIndex;
        const color = lowercaseMetricColors[isHigh ? currentColorIndex : nextColorIndex];
        // White thresholds that were not used as neutral will not be in the map
        if (_.has(priorityConversionMap, color)) {
          const level = priorityConversionMap[color] * (isHigh ? 1 : -1);
          result.push([level, threshold.threshold]);
        }
      }, [])
      .uniqBy(_.head)
      .map(([level, threshold]) => `${level}=${threshold}`)
      .value();
  }
}
