import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { JOBS_TAB_INDEX } from '@/administration/administration.module';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { AdministrationActions } from '@/administration/administration.actions';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';

const requestDetailsModalBindings = bindingsDefinition({
  item: prop<any>(),
  onClose: prop<() => void>(),
  sqAdministrationActions: injected<AdministrationActions>()
});

export const RequestDetailsModal: SeeqComponent<typeof requestDetailsModalBindings> = ({ item, onClose }) => {
  const { sqAdministrationActions } = useInjectedBindings(requestDetailsModalBindings);
  const { t } = useTranslation();

  const [requestDetails, setRequestDetails] = useState({});

  useEffect(() => {
    const parameterDetails = _.map(item?.parameters, (value, key) => ({ property: key, value: _.join(value, ', ') }));

    const details = _.chain(item)
      .pick(['requested duration', 'methodAndUri', 'status', 'duration', 'percentProgress'])
      .map((value, key) => ({ property: key, value }))
      .concat(parameterDetails)
      .value();

    setRequestDetails(_.sortBy(details, 'property'));
  }, [item]);

  const isRequestIdWithInvocation = (key, value) => key === 'request ID' && _.includes(value, 'Invocation^');

  const inspectJob = (value) => {
    const prefix = _.chain(value).split('*').head().split('^').last().value();
    sqAdministrationActions.setActiveTabIndex(JOBS_TAB_INDEX);
    sqAdministrationActions.setSearchParams({ field: 'id', value: prefix });
    onClose();
  };

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="requestDetailsModal">
      <Modal.Header closeButton={true}>
        <h3>{t('ADMIN.REQUEST.REQUEST_DETAILS')}</h3>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-condensed table-striped">
          <thead>
            <tr>
              <th>{t('ADMIN.REQUEST.PROPERTY')}</th>
              <th>{t('ADMIN.REQUEST.VALUE')}</th>
            </tr>
          </thead>
          <tbody>
            {_.map(requestDetails, ({ property, value }) => (
              <tr key={property}>
                <td className="text-bold">{property}</td>
                <td>
                  {isRequestIdWithInvocation(property, value)
                    ? <span className="force-link-look cursorPointer" onClick={() => inspectJob(value)}>{value}</span>
                    : value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};
