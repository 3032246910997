import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { FormulaDisplayOnly } from '@/hybrid/formula/FormulaDisplayOnly.atom';
import { FormulaTextWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';

export interface FormulaExamplesProps {
  examples: { description: string, formula: string }[];
  insertFormulaSnippet: (snippet: string) => void;
  requestDocumentation: (href: string) => any;
}

export const FormulaExamples: React.FunctionComponent<FormulaExamplesProps> = ({
  examples,
  insertFormulaSnippet,
  requestDocumentation
}) => {
  const { t } = useTranslation();

  const isLast = index => _.toNumber(index) === examples.length - 1;

  const addExampleToFormula = (formula) => {
    insertFormulaSnippet(formula);
  };

  return (
    examples?.length > 0 &&
    <>
      <h2>{t('FORMULA.DOCUMENTATION.EXAMPLES')}</h2>
      {_.map(examples, (example, index) => (
        <div className={classNames(isLast(index) ? 'mb15' : 'mb10')} key={index}>
          <div><FormulaTextWithHTML requestDocumentation={requestDocumentation} content={example.description} /></div>
          <div className="flexColumnContainer flexSpaceBetween">
            <div className="flexFill formulaExample">
              <FormulaDisplayOnly formula={example.formula} />
            </div>
            <HoverTooltip text="COPY">
              <span>
                <Icon icon="fc-copy" extraClassNames="cursorPointer pl5 pt5 pr5" testId="exampleCopyBtn"
                  onClick={() => addExampleToFormula(example.formula)} />
              </span>
            </HoverTooltip>
          </div>
        </div>
      ))}
    </>
  );
};
