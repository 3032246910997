import moment from 'moment-timezone';
import HttpCodes from 'http-status-codes';
import _ from 'lodash';
import { FroalaReportContentService } from '@/reportEditor/froalaReportContent.service';
import bind from 'class-autobind-decorator';
import { CkReportContentService } from '@/hybrid/annotation/ckReportContent.service';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import {
  AssetSelection,
  Content,
  CONTENT_LOADING_CLASS,
  DateRange,
  isContentMessage,
  isDateRangeMessage,
  LiveScreenshotMessage,
  LiveScreenshotMessageError,
  QUARTZ_CRON_PENDING_INPUT, REACT_JSON_VIEWS
} from '@/reportEditor/report.module';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { SocketService } from '@/services/socket.service';
import { ReportStore } from '@/reportEditor/report.store';
import { UtilitiesService } from '@/services/utilities.service';
import { ReportActions } from '@/reportEditor/report.actions';
import { ReportContentActions } from '@/reportEditor/reportContent.actions';
import { ContentApi } from '@/sdk';
import { IPromise } from 'angular';
import { AuthorizationService } from '@/services/authorization.service';
import { WorkbookStore } from '@/workbook/workbook.store';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { WorkstepsService } from '@/worksteps/worksteps.service';
import { WorksheetViewService } from '@/worksheet/worksheetView.service';

const NO_CAPSULE_FOUND_ERROR = /No Capsule found at index -?\d+ at 'pick'/;
const CONTENT_TYPES = [SeeqNames.Types.ImageContent, SeeqNames.Types.ReactJsonContent];
export const IGNORE_CK_PAGINATION_VIEW_SELECTOR = 'div#journalEditor ';

@bind
export class ReportContentService {
  constructor(
    private $injector: ng.auto.IInjectorService,
    private $translate: ng.translate.ITranslateService,
    private sqFroalaReportContent: FroalaReportContentService,
    private sqCkReportContent: CkReportContentService,
    private sqSystemConfiguration: SystemConfigurationService,
    private sqSocket: SocketService,
    private sqReportStore: ReportStore,
    private sqUtilities: UtilitiesService,
    private sqReportContentActions: ReportContentActions,
    private sqContentApi: ContentApi,
    private sqAuthorization: AuthorizationService,
    private sqWorkbookStore: WorkbookStore,
    private sqReportEditor: ReportEditorService,
    private sqWorksteps: WorkstepsService,
    private sqWorksheetView: WorksheetViewService
  ) {}

  // Function to call to unsubscribe from the report
  private reportUnsubscribeHandle: () => void;

  private service = () => this.sqReportEditor.isCkEditor() ? this.sqCkReportContent :
    this.sqFroalaReportContent;

  insertOrReplaceContent(contentId: string) {
    this.service().insertOrReplaceContent(contentId);
  }

  refreshAllContent(errorsOnly = false, deferImageUpdate = false, silently = false) {
    this.service().refreshAllContent(errorsOnly, deferImageUpdate, silently);
  }

  refreshContentUsingDate(dateRangeId: string, deferImageUpdate = false) {
    this.service().refreshContentUsingDate(dateRangeId, deferImageUpdate);
  }

  replaceContentIfExists(contentId: string, silently = false, deferImageUpdate = false) {
    return this.service().replaceContentIfExists(contentId, silently, deferImageUpdate);
  }

  contentError(contentId: string, error?: string) {
    this.service().contentError(contentId, error);
  }

  displayError(error: any) {
    this.service().displayError(error);
  }

  /**
   * Subscribes to any updates to the content in this report.
   *
   * @param {string} reportId - ID of the report
   */
  subscribeToReport(reportId) {
    this.unsubscribeFromReport();
    this.reportUnsubscribeHandle = this.sqSocket.subscribe({
      channelId: [
        SeeqNames.Channels.LiveScreenshot,
        reportId
      ],
      onMessage: this.handleLiveScreenshotMessage,
      onError: error => this.displayError(error)
    });
  }

  /**
   * Unsubscribes from updates for the current report
   */
  unsubscribeFromReport() {
    if (this.reportUnsubscribeHandle) {
      this.reportUnsubscribeHandle();
      this.reportUnsubscribeHandle = undefined;
    }
  }

  handleLiveScreenshotMessageForContent(contentId: string, hashCode: string): { isNewContent: boolean } {
    return this.service().handleLiveScreenshotMessageForContent(contentId, hashCode);
  }

  // Visible for testing
  /**
   * Handler for subscriptions to screenshot jobs.
   *
   * @param {LiveScreenshotMessage} payload - subscription message
   */
  handleLiveScreenshotMessage(payload: LiveScreenshotMessage): void {
    const sqReportActions = this.$injector.get<ReportActions>('sqReportActions');
    if (isContentMessage(payload)) {
      sqReportActions.incrementScheduledUpdateCount();
      const { contentId, hashCode } = payload.content;
      this.handleLiveScreenshotMessageForContent(contentId, hashCode);
    } else if (isDateRangeMessage(payload)) {
      const { dateRangeId, start, end } = payload.dateRange;
      // Added due to the possibility of a user deleting content during the time the server spends to process it
      if (!_.isEmpty(dateRangeId)) {
        sqReportActions.updateDateRangeStartAndEnd(dateRangeId, moment.utc(start).valueOf(),
          moment.utc(end).valueOf());
      }
    } else {
      this.handleAutoUpdateError(payload, sqReportActions);
    }
  }

  // Visible for testing
  /**
   * Handles published screenshot error messages. Adds error display to the document.
   *
   * @param error - error payload containing screenshot error status and statusMessage
   * @param sqReportActions - the report actions
   */
  handleAutoUpdateError(error: LiveScreenshotMessageError, sqReportActions: ReportActions) {
    if (error.status === HttpCodes.BAD_REQUEST && NO_CAPSULE_FOUND_ERROR.test(error.statusMessage)) {
      const contentId = error.itemId.toUpperCase();
      const dateRangeId = this.sqReportStore.getContentById(contentId).dateRangeId;
      sqReportActions.setNoCapsuleFound(dateRangeId);
      this.contentError(contentId, this.$translate.instant('REPORT.CONTENT.NO_CAPSULE_FOUND'));
    } else if (error.status === HttpCodes.GONE) {
      if (error.itemType === SeeqNames.Types.Report && this.sqReportStore.isScheduleEnabled) {
        sqReportActions.fetchReport();
      } else if (error.itemType === SeeqNames.Types.DateRange) {
        const dateRangeId = error.statusMessage.split(' ')[1].toUpperCase();
        const dateRange = this.sqReportStore.getDateRangeById(dateRangeId);
        if (dateRange?.enabled) {
          sqReportActions.fetchDateRange(dateRangeId, true);
        }
      } else if (CONTENT_TYPES.includes(error.itemType)) {
        this.contentError(error.itemId, error.statusMessage);
      } else {
        this.displayError(error);
      }
    } else if (error.itemId && error.itemType) {
      const itemId = error.itemId.toUpperCase();

      if (CONTENT_TYPES.includes(error.itemType)) {
        this.contentError(itemId, error.statusMessage);
      } else if (error.itemType === SeeqNames.Types.DateRange) {
        _.forEach(this.sqReportStore.contentUsingDateRange(itemId),
          content => this.contentError(content.id, error.statusMessage));
      } else if (error.itemType === SeeqNames.Types.Report) {
        _.forEach(this.sqReportStore.liveOrScheduledContent,
          content => this.contentError(content.id, error.statusMessage));
      }
      this.displayError(error);
    } else {
      this.displayError(error);
    }
  }

  /**
   * Sets the properties in sqReportContentStore from a specific Seeq Content
   *
   * @param id - content ID
   * @return - A promise that resolves when the store is populated
   */
  setStoreFromContent(id: string): Promise<void> {
    return this.sqReportContentActions.setContent(this.sqReportStore.getContentById(id));
  }

  /**
   * Clears the cache and replaces the seeq content images with new screenshots
   *
   * @param contentIds - List of content IDs to refresh
   */
  forceRefreshMultipleContent(contentIds: string[]) {
    _.forEach(contentIds, contentId => this.forceRefreshContent(contentId));
  }

  /**
   * Clears the cache and replaces the seeq content images that use the specified dateRange with new screenshots based
   * on the latest values from the dateRange.
   *
   * @param dateRangeId - Id of the dateRange. If undefined, no image will be refreshed.
   */
  forceRefreshContentUsingDate(dateRangeId: string) {
    if (_.isUndefined(dateRangeId)) {
      return;
    }
    _.forEach(this.sqReportStore.contentUsingDateRange(dateRangeId), content => this.forceRefreshContent(content.id));
  }

  /**
   * Clears the cache and replaces the seeq content images that use the specified assetSelection with new
   * screenshots based on the latest values from the assetSelection
   *
   * @param assetSelectionId
   */
  forceRefreshContentUsingAssetSelection(assetSelectionId: string | undefined) {
    if (_.isUndefined(assetSelectionId)) {
      return;
    }
    _.forEach(this.sqReportStore.contentUsingAssetSelection(assetSelectionId),
      content => this.forceRefreshContent(content.id));
  }

  /**
   * Clears the cache and replaces the seeq content image with new screenshot
   *
   * @param contentId - Content IDs to refresh
   */
  forceRefreshContent(contentId: string) {
    this.sqContentApi.clearImageCache({ id: contentId })
      .finally(() => {
        this.$injector.get<ReportActions>('sqReportActions').setContentHashCode(contentId,
          this.sqUtilities.base64guid());
        this.replaceContentIfExists(contentId);
      });
  }

  private dateRangesBeingCopied = {};

  isDateRangeBeingCopied(dateRange: DateRange): boolean {
    return !!_.find(this.dateRangesBeingCopied,
      dateRange2 => this.sqUtilities.areDateRangesSimilar(dateRange, dateRange2));
  }

  private assetSelectionsBeingCopied = {};

  isAssetSelectionBeingCopied(assetSelection: AssetSelection): boolean {
    return !!_.find(this.assetSelectionsBeingCopied,
      assetSelection2 => this.sqUtilities.areAssetSelectionsSimilar(assetSelection, assetSelection2));
  }

  /**
   * Copies a pasted content item's date range, if necessary.
   *
   * @param dateRange - the date range in question
   * @param promiseResolver - The promise resolver in use by the current component
   * @returns promise that resolves to the ID of the date range, if it exists
   */
  copyDateRangeForPendingContent(dateRange: DateRange, promiseResolver: { resolve: any }): IPromise<string> {
    const sqReportActions = this.$injector.get<ReportActions>('sqReportActions');
    if (dateRange?.id) {
      if (dateRange.reportId !== this.sqReportStore.id) {
        const existingDateRange = this.sqReportStore.findSimilarDateRange(dateRange);
        if (_.isUndefined(existingDateRange)) {
          // Content uses dateRange which needs to be duplicated to this report
          if (dateRange.auto.enabled && _.isEmpty(dateRange.auto.cronSchedule)) {
            // We need a cron schedule so here is a default
            dateRange.auto.cronSchedule = [QUARTZ_CRON_PENDING_INPUT];
          }
          const idlessDateRange = _.omit(dateRange, ['id']);
          this.dateRangesBeingCopied[dateRange.id] = idlessDateRange;
          return sqReportActions.saveDateRange(idlessDateRange)
            .then((dateRangeId: string) => {
              delete this.dateRangesBeingCopied[dateRange.id];
              return dateRangeId;
            });
        } else {
          return promiseResolver.resolve(existingDateRange.id);
        }
      } else {
        // Make sure this date range is updated and in the store
        sqReportActions.setDateRange(dateRange);
      }
    }
    return promiseResolver.resolve(dateRange?.id);
  }

  /**
   * Copies a pasted content item's asset selection, if necessary.
   *
   * @param assetSelection - the asset selection
   * @param promiseResolver - The promise resolver in use by the current component
   * @returns promise that resolves to the ID of the asset selection, if it exists
   */
  copyAssetSelectionForPendingContent(assetSelection: AssetSelection,
    promiseResolver: { resolve: any }): IPromise<string> {
    const sqReportActions = this.$injector.get<ReportActions>('sqReportActions');
    if (assetSelection?.selectionId) {
      if (assetSelection.reportId !== this.sqReportStore.id) {
        const existingAssetSelection = this.sqReportStore.findSimilarAssetSelection(assetSelection);
        if (_.isUndefined(existingAssetSelection)) {
          // Content uses assetSelection which needs to be duplicated to this report
          const idlessAssetSelection = _.omit(assetSelection, ['selectionId']) as AssetSelection;
          idlessAssetSelection.name =
            this.sqReportStore.computeNonConflictingAssetSelectionName(idlessAssetSelection.name);
          this.assetSelectionsBeingCopied[assetSelection.selectionId] = idlessAssetSelection;
          return sqReportActions.saveAssetSelection(idlessAssetSelection)
            .then((assetSelectionId: string) => {
              delete this.assetSelectionsBeingCopied[assetSelection.selectionId];
              return assetSelectionId;
            });
        } else {
          return promiseResolver.resolve(existingAssetSelection.selectionId);
        }
      } else {
        // Make sure this asset selection is updated and in the store
        sqReportActions.setAssetSelection(assetSelection);
      }
    }
    return promiseResolver.resolve(assetSelection?.selectionId);
  }

  /**
   * If a pasted content item is already in the document, is part of a different report, or uses a different date
   * range, duplicate it. If not, but it is archived, unarchive it. Otherwise, return the same content.
   *
   * @param content - content that was pasted/is pending
   * @param isContentInDocument - is the content already in the document?
   * @param [dateRangeId] - id of the date range to associate with the content
   * @param [assetSelectionId] - id of the asset selection to associate with the content
   * @param promiseResolver - The promise resolver in use by the current component
   * @returns promise that resolves to the content item we are actually adding to the document
   */
  duplicateOrUnarchivePendingContent(content: Content, isContentInDocument: boolean, promiseResolver: { resolve: any },
    dateRangeId?: string, assetSelectionId?: string): IPromise<Content> {
    const sqReportActions = this.$injector.get<ReportActions>('sqReportActions');

    if (isContentInDocument || content.reportId !== this.sqReportStore.id || content.dateRangeId !== dateRangeId
      || content.assetSelectionId !== assetSelectionId) {

      // Content needs to be duplicated before continuing
      content.dateRangeId = dateRangeId;
      content.assetSelectionId = assetSelectionId;
      return sqReportActions.saveContent(_.omit(content, ['id']) as Content);
    }
    if (content.isArchived) {
      // Content needs to be unarchived before continuing
      return sqReportActions.saveContent({ ...content, isArchived: false });
    }
    return promiseResolver.resolve(content);
  }

  /**
   * Creates the dataURL of the image. It can be used as value for a html image src
   *
   * @param img {Image} - the input image
   * @returns dataURL of the image
   */
  getImageDataURL(img): string {
    const canvas = document.createElement('canvas');
    // naturalWidth&height because width and height does not work for us. To show the complete image, Froala is
    // resizing them. Using with&height results in cropped images on paste.
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);

    return canvas.toDataURL('image/png');
  }

  canModify() {
    return this.sqUtilities.workbookLoaded() && this.sqAuthorization.canModifyWorkbook(this.sqWorkbookStore) &&
      !this.sqUtilities.isPresentationWorkbookMode && !this.sqUtilities.isViewOnlyWorkbookMode;
  }

  /**
   * Returns a jQuery object of all pieces of Seeq content that are currently in an error state.
   *
   * @returns {JQuery<TElement extends Node>}
   */
  getContentInErrorState() {
    return jQuery(`${IGNORE_CK_PAGINATION_VIEW_SELECTOR} [${SeeqNames.TopicDocumentAttributes.DataSeeqContent}].${CONTENT_LOADING_CLASS.ERROR}`);
  }

  /**
   * Returns a jQuery object of all pieces of Seeq content that are currently loading.
   *
   * @returns {jQuery} object of all Seeq content elements that are still loading
   */
  getLoadingContent(): JQuery {
    return jQuery(`${IGNORE_CK_PAGINATION_VIEW_SELECTOR} [${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`)
      .not(`.${CONTENT_LOADING_CLASS.LOADED}`)
      .not(`.${CONTENT_LOADING_CLASS.ERROR}`)
      .not(`.${CONTENT_LOADING_CLASS.NO_CAPSULE_ERROR}`);
  }

  /**
   * Returns a jQuery object of all pieces of Seeq content, regardless of their state.
   *
   * @returns {jQuery} object of all Seeq content elements
   */
  getAllContent(): JQuery {
    return jQuery(`${IGNORE_CK_PAGINATION_VIEW_SELECTOR} [${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`);
  }

  /**
   * Returns the status of all Seeq content in the document.
   *
   * @returns containing a key value pair for each state, where the keys are loaded,
   *  inProgress, and failed, and the values are the count of pieces of Seeq content in that state.
   */
  getAllContentStatus(): { failed: number, inProgress: number, loaded: number } {
    const failed = this.getContentInErrorState().length;
    const inProgress = this.getLoadingContent().length;
    const loaded = this.getAllContent().length - failed - inProgress;
    return { failed, inProgress, loaded };
  }

  /**
   * Returns false if any content in the browser is still being loaded, otherwise true. Content that failed to load
   * is considered finished loading for the purposes of this check. Used by the screenshot service to determine when
   * the document is ready to be captured.
   *
   * @returns {boolean} indicating whether all content has finished loading
   */
  isAllContentFinishedLoading() {
    return this.getLoadingContent().length === 0;
  }

  /**
   * Evaluates the properties specific to particular visualizations.
   * See worksheet.module.js for details on the useSizeFromRender optional property.
   *
   * @param workbookId - a workbook ID
   * @param worksheetId - a worksheet ID
   * @param workstepId - a workstep ID
   * @param currentOptions - The current options for a given piece of content. Depending on the difference between
   * the provided options and the evaluated options, this may reject
   * @returns - a promise that resolves when evaluation is complete and the store has been updated or rejects with
   * an untranslated error should the evaluation fail.
   */
  evaluateVisualizationOptions(workbookId: string, worksheetId: string, workstepId: string,
    currentOptions?: { isReact: boolean }): Promise<void> {
    return this.sqWorksteps.get(workbookId, worksheetId, workstepId)
      .then((response) => {
        const view = this.getViewFromWorkstep(response);
        const canUseReact = REACT_JSON_VIEWS.includes(view.key);

        // We only need to check react -> not react as all visualizations support not being react.
        if (currentOptions?.isReact && !canUseReact) {
          throw new Error('REPORT.CONTENT.INTERACTIVE_CANNOT_SWITCH');
        }

        this.sqReportContentActions.setUseSizeFromRender(!!view?.useSizeFromRender);
        this.sqReportContentActions.setCanUseReact(canUseReact);
      });
  }

  /**
   * Gets the view from a workstep response
   *
   * @param {Object} workstepResponse
   * @returns {Object} one of WORKSHEET_VIEWS
   */
  getViewFromWorkstep(workstepResponse) {
    const key = _.get(workstepResponse, 'current.state.stores.sqWorksheetStore.viewKey');
    return this.sqWorksheetView.find(key);
  }
}
