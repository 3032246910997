import _ from 'lodash';
import angular from 'angular';
import { BaseToolStoreService } from '@/investigate/baseToolStore.service';
import { TREND_TOOLS } from '@/investigate/investigate.module';

angular.module('Sq.TrendData')
  .store('sqValueSearchStore', sqValueSearchStore);

const VERSION_TWO = 'V2';
const VERSION_THREE = 'V3';

export const DEFAULT_DURATION = { value: 0, units: 'min' };

export type ValueSearchStore = ReturnType<typeof sqValueSearchStore>['exports'];

function sqValueSearchStore(sqBaseToolStore: BaseToolStoreService) {
  const store = {
    initialize() {
      this.state = this.immutable(_.assign({}, sqBaseToolStore.COMMON_PROPS, {
        isSimple: true,
        useValidValues: false,
        isCleansing: false,
        simpleOperator: undefined,
        simpleValue: null,
        simpleUpperValueInclusivity: undefined,
        simpleLowerValue: null,
        simpleLowerValueInclusivity: undefined,
        minDuration: DEFAULT_DURATION,
        mergeDuration: DEFAULT_DURATION,
        advancedEntryOperator: undefined,
        advancedEntryValue: null,
        advancedEntryLowerValue: null,
        advancedEntryDuration: DEFAULT_DURATION,
        advancedExitOperator: undefined,
        advancedExitValue: null,
        advancedExitDuration: DEFAULT_DURATION,
        isMigratedDeviationSearch: false,
        version: VERSION_THREE
      }));
    },

    exports: {
      get isSimple() {
        return this.state.get('isSimple');
      },
      get isCleansing() {
        return this.state.get('isCleansing');
      },
      get useValidValues() {
        return this.state.get('useValidValues');
      },
      get simpleOperator() {
        return this.state.get('simpleOperator');
      },
      get simpleValue() {
        return this.state.get('simpleValue');
      },
      get simpleUpperValueInclusivity() {
        return this.state.get('simpleUpperValueInclusivity');
      },
      get simpleLowerValue() {
        return this.state.get('simpleLowerValue');
      },
      get simpleLowerValueInclusivity() {
        return this.state.get('simpleLowerValueInclusivity');
      },
      get minDuration() {
        return this.state.get('minDuration');
      },
      get mergeDuration() {
        return this.state.get('mergeDuration');
      },
      get advancedEntryOperator() {
        return this.state.get('advancedEntryOperator');
      },
      get advancedEntryValue() {
        return this.state.get('advancedEntryValue');
      },
      get advancedEntryLowerValue() {
        return this.state.get('advancedEntryLowerValue');
      },
      get advancedEntryDuration() {
        return this.state.get('advancedEntryDuration');
      },
      get advancedExitOperator() {
        return this.state.get('advancedExitOperator');
      },
      get advancedExitValue() {
        return this.state.get('advancedExitValue');
      },
      get advancedExitDuration() {
        return this.state.get('advancedExitDuration');
      },
      get isMigratedDeviationSearch() {
        return this.state.get('isMigratedDeviationSearch');
      }

    },

    /**
     * Exports the state that is not ephemeral (such as progress indicators).
     *
     * @returns {Object} The dehydrated parameters.
     */
    dehydrate() {
      return this.state.serialize();
    },

    /**
     * Re-creates the value search store.
     *
     * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      VALUE_SEARCH_SET_SEARCH_TYPE: 'setSearchType',
      VALUE_SEARCH_TOGGLE_CLEANSING: 'toggleCleansing',
      VALUE_SEARCH_TOGGLE_VALID_VALUES: 'toggleValidValues',
      VALUE_SEARCH_SET_SIMPLE_OPERATOR: 'setSimpleOperator',
      VALUE_SEARCH_SET_SIMPLE_VALUE: 'setSimpleValue',
      VALUE_SEARCH_SET_SIMPLE_UPPER_VALUE_INCLUSIVITY: 'setSimpleUpperValueInclusivity',
      VALUE_SEARCH_SET_SIMPLE_LOWER_VALUE: 'setSimpleLowerValue',
      VALUE_SEARCH_SET_SIMPLE_LOWER_VALUE_INCLUSIVITY: 'setSimpleLowerValueInclusivity',
      VALUE_SEARCH_SET_MIN_DURATION: 'setMinDuration',
      VALUE_SEARCH_SET_MERGE_DURATION: 'setMergeDuration',
      VALUE_SEARCH_SET_ADVANCED_ENTRY_OPERATOR: 'setAdvancedEntryOperator',
      VALUE_SEARCH_SET_ADVANCED_ENTRY_VALUE: 'setAdvancedEntryValue',
      VALUE_SEARCH_SET_ADVANCED_ENTRY_LOWER_VALUE: 'setAdvancedEntryLowerValue',
      VALUE_SEARCH_SET_ADVANCED_ENTRY_DURATION: 'setAdvancedEntryDuration',
      VALUE_SEARCH_SET_ADVANCED_EXIT_OPERATOR: 'setAdvancedExitOperator',
      VALUE_SEARCH_SET_ADVANCED_EXIT_VALUE: 'setAdvancedExitValue',
      VALUE_SEARCH_SET_ADVANCED_EXIT_DURATION: 'setAdvancedExitDuration',
      VALUE_SEARCH_SET_IS_MIGRATED_DEVIATION_SEARCH: 'setIsMigratedDeviationSearch',
      TOOL_REHYDRATE_FOR_EDIT: 'valueSearchRehydrateForEdit'
    },

    setSimpleOperator(payload) {
      this.state.set('simpleOperator', payload.simpleOperator);
    },

    setSimpleValue(payload) {
      this.state.set('simpleValue', payload.simpleValue);
    },

    setSimpleUpperValueInclusivity(payload) {
      this.state.set('simpleUpperValueInclusivity', payload.simpleUpperValueInclusivity);
    },

    setSimpleLowerValue(payload) {
      this.state.set('simpleLowerValue', payload.simpleLowerValue);
    },

    setSimpleLowerValueInclusivity(payload) {
      this.state.set('simpleLowerValueInclusivity', payload.simpleLowerValueInclusivity);
    },

    setAdvancedEntryOperator(payload) {
      this.state.set('advancedEntryOperator', payload.advancedEntryOperator);
    },

    setAdvancedEntryValue(payload) {
      this.state.set('advancedEntryValue', payload.advancedEntryValue);
    },

    setAdvancedEntryLowerValue(payload) {
      this.state.set('advancedEntryLowerValue', payload.advancedEntryLowerValue);
    },

    setAdvancedEntryDuration(payload) {
      this.state.set('advancedEntryDuration', payload.advancedEntryDuration);
    },

    setAdvancedExitOperator(payload) {
      this.state.set('advancedExitOperator', payload.advancedExitOperator);
    },

    setAdvancedExitValue(payload) {
      this.state.set('advancedExitValue', payload.advancedExitValue);
    },

    setAdvancedExitDuration(payload) {
      this.state.set('advancedExitDuration', payload.advancedExitDuration);
    },

    setMinDuration(payload) {
      this.state.set('minDuration', payload.minDuration);
    },

    setMergeDuration(payload) {
      this.state.set('mergeDuration', payload.mergeDuration);
    },

    setIsMigratedDeviationSearch(payload) {
      this.state.set('isMigratedDeviationSearch', payload.isMigratedDeviationSearch);
    },

    /**
     * Sets the isSimple property (true if simple search, false if advanced)
     *
     * @param {Object} payload - state information
     * @param {boolean} payload.isSimple - whether we are setting it to simple or advanced
     */
    setSearchType(payload) {
      this.state.set('isSimple', payload.isSimple);
    },

    /**
     * Toggles the isCleansing property
     */
    toggleCleansing() {
      this.state.set('isCleansing', !this.state.get('isCleansing'));
    },

    /**
     * Toggles the isCleansing property
     */
    toggleValidValues() {
      this.state.set('useValidValues', !this.state.get('useValidValues'));
    },

    /**
     * Update UI config property when its loaded
     * @param  {Object} oldConfig - containing the conditions to remap
     * @return {Object} result of the remapping (if it was necessary)
     */
    migrateSavedConfig(oldConfig) {
      const newConfig = migrateSavedConfig1(oldConfig);

      // using switch statement so future upgrades can be added here
      switch (oldConfig.version) {
        case undefined:
          const v2Config = updateToSaveConfigV2(newConfig);
          return updateToSaveConfigV3(v2Config);
        case VERSION_TWO:
          return updateToSaveConfigV3(newConfig);
        default:
          return newConfig;
      }
    },

    valueSearchRehydrateForEdit(payload) {
      if (payload.type !== TREND_TOOLS.VALUE_SEARCH) {
        return;
      }

      this.rehydrateForEdit(payload);

      const simpleValueParam = _.find(payload.parameters, { name: 'b' }) as any;
      if (simpleValueParam) {
        this.state.set('simpleValue', simpleValueParam.item.id);
      }

      const simpleLowerValueParam = _.find(payload.parameters, { name: 'c' }) as any;
      if (simpleLowerValueParam) {
        this.state.set('simpleLowerValue', simpleLowerValueParam.item.id);
      }
    }
  };

  /**
   * Remaps the entry and exit conditions from the store in the format { durationValue, durationUnits, duration }
   * to { duration: { value, units } } to maintain compatibility with older versions. This function is called by the
   * baseToolStore as it rehydrates the payload
   * @param  {Object} oldConfig - containing the conditions to remap
   * @return {Object} result of the remapping (if it was necessary)
   */
  function migrateSavedConfig1(oldConfig) {
    // Keeping this old config just to be safe, but bypassing it if we have a V3 config already
    if (oldConfig.version === VERSION_THREE) {
      return oldConfig;
    }

    _.forEach([oldConfig.limitsParams.entryCondition, oldConfig.limitsParams.exitCondition], function(condition) {
      if (!_.isUndefined(condition.durationValue) && !_.isUndefined(condition.durationUnits)) {
        condition.duration = {
          units: condition.durationUnits,
          value: condition.durationValue
        };
        delete condition.durationUnits;
        delete condition.durationValue;
      }
    });

    return oldConfig;
  }

  /**
   * Remaps config version to version 2
   *
   * @param  {Object} oldConfig - containing the conditions to remap
   * @return {Object} result of the remapping (if it was necessary)
   */
  function updateToSaveConfigV2(oldConfig) {
    oldConfig.version = VERSION_TWO;

    if (oldConfig.limitsParams.entryCondition.operator === '=') {
      oldConfig.limitsParams.entryCondition.operator = '~=';
    } else if (oldConfig.limitsParams.entryCondition.operator === '!=') {
      oldConfig.limitsParams.entryCondition.operator = '!~';
    }

    if (oldConfig.limitsParams.exitCondition.operator === '=') {
      oldConfig.limitsParams.exitCondition.operator = '~=';
    } else if (oldConfig.limitsParams.exitCondition.operator === '!=') {
      oldConfig.limitsParams.exitCondition.operator = '!~';
    }

    // If we have an existing value search, we want it to display in advanced mode
    if (_.isUndefined(oldConfig.isSimple)) {
      oldConfig.isSimple = false;
    }

    return oldConfig;
  }

  function updateToSaveConfigV3(v2Config) {
    v2Config.version = VERSION_THREE;

    if (v2Config.isSimple) {
      v2Config.simpleOperator = v2Config.limitsParams.entryCondition.operator;
      v2Config.simpleValue = v2Config.limitsParams.entryCondition.value;
      v2Config.simpleLowerValue = v2Config.limitsParams.entryCondition.value2;
      v2Config.minDuration = v2Config.limitsParams.entryCondition.duration;
      v2Config.mergeDuration = v2Config.limitsParams.exitCondition.duration;
    } else {
      v2Config.advancedEntryOperator = v2Config.limitsParams.entryCondition.operator;
      v2Config.advancedEntryValue = v2Config.limitsParams.entryCondition.value;
      v2Config.advancedEntryLowerValue = v2Config.limitsParams.entryCondition.value2;
      v2Config.advancedEntryDuration = v2Config.limitsParams.entryCondition.duration;
      v2Config.advancedExitOperator = v2Config.limitsParams.exitCondition.operator;
      v2Config.advancedExitValue = v2Config.limitsParams.exitCondition.value;
      v2Config.advancedExitDuration = v2Config.limitsParams.exitCondition.duration;
    }

    delete v2Config.limitsParams;
    return v2Config;
  }

  return sqBaseToolStore.extend(store, TREND_TOOLS.VALUE_SEARCH, { inputSignal: { predicate: ['name', 'a'] } });
}
