import _ from 'lodash';
import bind from 'class-autobind-decorator';
import { CapsuleGroupActions } from '@/investigate/customCondition/capsuleGroup.actions';
import { CapsuleGroupCapsule } from '@/investigate/customCondition/customCondition.module';
import { ConditionFormulaService } from '@/investigate/customCondition/conditionFormula.service';
import { CapsuleGroupStore } from '@/investigate/customCondition/capsuleGroup.store';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { DurationStore } from '@/trendData/duration.store';
import { TrendStore } from '@/trendData/trend.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { TrendActions } from '@/trendData/trend.actions';
import { DateTimeService } from '@/datetime/dateTime.service';
import { ITEM_TYPES } from '@/trendData/trendData.module';
import { Capsule, CAPSULE_GROUP_DATE_FORMAT } from '@/datetime/datetime.module';

type Selection = { id: string, startTime: number, endTime: number };

@bind
export class CapsuleGroupController {
  form: ng.IFormController = this['form']; // Added by angular's form controller in the template
  previewId: string = this['previewId']; // Binding from component

  capsules: CapsuleGroupCapsule[] = this['capsules'];
  isLoading: boolean = this['isLoading'];
  formCapsule: Capsule = this['formCapsule'];
  selectionDrawn: boolean = this['selectionDrawn'];
  selectedCapsules: Selection[] = this['selectedCapsules'];
  displayRange: Capsule = this['displayRange'];
  editingOriginalCapsule: CapsuleGroupCapsule = this['editingOriginalCapsule'];
  editingCapsule: CapsuleGroupCapsule = this['editingCapsule'];

  constructor(public $scope: ng.IScope,
    public sqCapsuleGroupActions: CapsuleGroupActions,
    public sqConditionFormula: ConditionFormulaService,
    public sqCapsuleGroupStore: CapsuleGroupStore,
    public sqTrendCapsuleStore: TrendCapsuleStore,
    public sqDurationStore: DurationStore,
    public sqTrendStore: TrendStore,
    public sqWorksheetStore: WorksheetStore,
    public sqTrendActions: TrendActions,
    public sqDateTime: DateTimeService) {

    $scope.$listenTo(sqCapsuleGroupStore, this.setCapsuleGroupVars);
    $scope.$listenTo(sqTrendCapsuleStore, ['selectedCapsules'], _.flow(this.setTrendCapsuleVars, this.setCombinedVars));
    $scope.$listenTo(sqDurationStore, ['displayRange'], _.flow(this.setDurationVars, this.setCombinedVars));
    $scope.$listenTo(sqTrendStore, ['selectedRegion'], _.flow(this.setTrendVars, this.setCombinedVars));
  }

  removeSelectedRegion = this.sqTrendActions.removeSelectedRegion;
  pickSelectedRegion = this.sqCapsuleGroupActions.pickSelectedRegion;

  setCapsuleGroupVars() {
    this.capsules = this.sqCapsuleGroupStore.capsules;
    this.isLoading = this.sqCapsuleGroupStore.isLoading;
  }

  /**
   * Syncs sqTrendStore and view-model properties
   */
  setTrendVars() {
    // The form capsule is the same as the selection, but we must take care in updating the formCapsule so that a
    // partially filled capsule in the form is not overwritten by the empty selection.
    this.selectionDrawn = this.sqTrendStore.isRegionSelected();
    if (this.selectionDrawn) {
      this.formCapsule = {
        startTime: this.sqTrendStore.selectedRegion.min,
        endTime: this.sqTrendStore.selectedRegion.max,
        properties: []
      };
    }
  }

  /**
   * Syncs sqTrendCapsuleStore and view-model properties
   */
  setTrendCapsuleVars() {
    this.selectedCapsules = _.map(this.sqTrendCapsuleStore.selectedCapsules as Selection[],
      capsule => ({
        ...capsule,
        // Add the itemType property so that the capsule can be deselected easily
        itemType: ITEM_TYPES.CAPSULE
      }));
  }

  /**
   * Syncs properties that are derived from several stores
   */
  setCombinedVars() {
    if (!this.selectionDrawn && this.displayRange) {
      // Provide a reasonable default so that the field is filled even when the user clears the selection.
      this.formCapsule = {
        startTime: this.displayRange.startTime + ((this.displayRange.endTime - this.displayRange.startTime) / 4),
        endTime: this.displayRange.endTime - ((this.displayRange.endTime - this.displayRange.startTime) / 4),
        properties: []
      };
    }
  }

  /**
   * Syncs sqDurationStore and view-model properties
   */
  setDurationVars() {
    this.displayRange = {
      startTime: this.sqDurationStore.displayRange.start.valueOf(),
      endTime: this.sqDurationStore.displayRange.end.valueOf(),
      properties: []
    };
  }

  isValidCapsule() {
    return this.sqConditionFormula.isValidCapsule(this.formCapsule);
  }
  /**
   * Replaces the selected region with the capsule at the top of the form, this acts as a preview of the capsule in the
   * form.
   */
  syncFormCapsuleWithSelection(capsule: Capsule) {
    // NOTE: We should be able to just check `this.form.formCapsule.$invalid` here, but it appears that $invalids
    // aren't updated until after ng-change handlers have been fired. So `$invalid` would always indicate whether
    // the last value of the form capsule was valid instead of the current capsule (CRAB-11812)
    this.formCapsule = capsule;
    if (this.sqConditionFormula.isValidCapsule(capsule)) {
      this.sqTrendActions.setSelectedRegion(capsule.startTime, capsule.endTime);
    }
  }

  /**
   * Removes a capsule. If the capsule is from selection it is deselected, otherwise it is removed from the capsule.
   *
   * @param {Object} capsule - capsule to remove
   */
  removeCapsule(capsule: CapsuleGroupCapsule) {
    if (capsule.id) {
      const selection = _.find(this.selectedCapsules, ['id', capsule.id]);
      this.sqTrendActions.setItemSelected(selection, false);
    } else {
      this.setCapsules(_.without(this.capsules, capsule));
    }
  }

  /**
   * Updates a capsule by replacing the beforeCapsule with the afterCapsule
   *
   * @param {Object} beforeCapsule - original capsule that exists in the capsules list
   * @param {Object} afterCapsule - the capsule that should replace the beforeCapsule
   */
  updateCapsule(beforeCapsule: CapsuleGroupCapsule, afterCapsule: CapsuleGroupCapsule) {
    // If capsule has an id it is linked to the selection, deselect the capsule to maintain consistency
    if (beforeCapsule.id) {
      const selection = _.find(this.selectedCapsules, ['id', beforeCapsule.id]);
      this.sqTrendActions.setItemSelected(selection, false);
    }

    _.chain(this.capsules)
      .without(beforeCapsule)
      // drop id since it isn't the same as the selection anymore.
      .concat(_.omit(afterCapsule, ['id']) as CapsuleGroupCapsule)
      .tap(capsules => this.setCapsules(capsules))
      .value();
  }

  /**
   * Sets the ng-model view value
   *
   * @param {Object} capsules - list of capsules
   */
  setCapsules(capsules: CapsuleGroupCapsule[]) {
    this.sqCapsuleGroupActions.setCapsules(capsules);
  }

  /**
   * Enter inline editing mode for the capsule
   *
   * @param {Object} capsule - capsule to edit from capsules list
   */
  editCapsule(capsule: CapsuleGroupCapsule) {
    this.editingOriginalCapsule = capsule;
    this.editingCapsule = _.cloneDeep(capsule);
  }

  /**
   * Sync capsule on focus out
   *
   */
  syncEditingCapsule(capsule: Capsule) {
    this.editingCapsule = capsule;
  }

  /**
   * Test if the capsule is being edited inline
   *
   * @param {Object} capsule - capsule from the capsules list
   * @returns {Boolean} true if the capsule is being edited
   */
  isEditingCapsule(capsule: CapsuleGroupCapsule) {
    return capsule === this.editingOriginalCapsule;
  }

  /**
   * Exit inline editing mode
   *
   * @param {Boolean} save - if true commit the edit, otherwise discard changes
   */
  closeEditCapsule(save: boolean) {
    const changed = this.editingOriginalCapsule.startTime !== this.editingCapsule.startTime ||
      this.editingOriginalCapsule.endTime !== this.editingCapsule.endTime;
    if (save && changed) {
      this.updateCapsule(this.editingOriginalCapsule, this.editingCapsule);
    }

    delete this.editingOriginalCapsule;
    delete this.editingCapsule;
  }

  /**
   * Tests if the selection is a preview capsule for the series that we are editing.
   * Note: the start and end times are rounded then compared because sometimes the chart capsules seem to be rounded
   *
   * @param {Object} capsule - capsule that is potentially selected
   * @param {Object} selection - selection that is potentially for the capsule's preview
   * @returns {Boolean} true if the selection is a preview capsule for the capsule
   */
  isSelectionPreviewCapsule(capsule: CapsuleGroupCapsule, selection: Selection) {
    return this.sqTrendCapsuleStore.splitUniqueId(selection.id).capsuleSetId === this.previewId &&
      Math.round(selection.startTime) === Math.round(capsule.startTime) &&
      Math.round(selection.endTime) === Math.round(capsule.endTime);
  }

  /**
   * Test if the capsule's preview is selected
   *
   * @param {Object} capsule - capsule that could be selected
   * @returns {Boolean} true if the capsule is selected in the preview
   */
  isSelected(capsule: CapsuleGroupCapsule) {
    return _.some(this.selectedCapsules, s => this.isSelectionPreviewCapsule(capsule, s));
  }

  /**
   * Toggles the capsule preview's selection
   *
   * @param {Object} capsule - capsule that should have its preview capsule selection status toggled
   */
  togglePreviewSelection(capsule: CapsuleGroupCapsule) {
    const selected = this.isSelected(capsule);
    if (selected) {
      _.chain(this.selectedCapsules)
        .filter(s => this.isSelectionPreviewCapsule(capsule, s))
        .forEach(s => this.sqTrendActions.setItemSelected(s, false))
        .value();
    } else {
      _.chain(this.sqTrendCapsuleStore.chartItems)
        .flatMap('capsules')
        .filter((s: Selection) => this.isSelectionPreviewCapsule(capsule, s))
        .forEach(
          (s: Selection) => this.sqTrendActions.setItemSelected({ ...s, itemType: ITEM_TYPES.CAPSULE }, true))
        .value();
    }
  }

  /**
   * formats a time as CAPSULE_GROUP_DATE_FORMAT
   *
   * @param {Number} time - time in ms
   */
  formatTime(time: number) {
    return this.sqDateTime.formatTime(time,
      this.sqWorksheetStore.timezone,
      CAPSULE_GROUP_DATE_FORMAT
    );
  }

  /**
   * formats a duration
   *
   * @param {Number} startTime - start in ms
   * @param {Number} endTime - end in ms
   */
  formatDuration(startTime, endTime) {
    return this.sqDateTime.formatDuration(endTime - startTime, true);
  }
}
