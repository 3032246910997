import React from 'react';
import { Dropdown } from 'react-bootstrap';
import _ from 'lodash';
import classNames from 'classnames';
import { TrendActions } from '@/trendData/trend.actions';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { CapsuleTimeColorMode, TrendStore } from '@/trendData/trend.store';
import { TREND_VIEWS } from '@/trendData/trendData.module';
import { TrackService } from '@/track/track.service';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { ToolbarDropdownButton } from '@/hybrid/core/ToolbarDropdownButton.molecule';

const capsuleTimeColorModeButtonBindings = bindingsDefinition({
  isSmall: prop<boolean>(),
  sqTrendActions: injected<TrendActions>(),
  sqTrendStore: injected<TrendStore>(),
  sqTrack: injected<TrackService>()
});

export const CapsuleTimeColorModeButton: SeeqComponent<typeof capsuleTimeColorModeButtonBindings> = (props) => {
  const { isSmall } = props;
  const {
    sqTrendActions,
    sqTrendStore,
    sqTrack
  } = useInjectedBindings(capsuleTimeColorModeButtonBindings);
  const { t } = useTranslation();

  const view = useFluxPath(sqTrendStore, () => sqTrendStore.view);
  const capsuleTimeColorMode = useFluxPath(sqTrendStore, () => sqTrendStore.capsuleTimeColorMode);

  const setCapsuleTimeColorModeAndTrack = (mode) => {
    sqTrack.doTrack('Trend', `${mode} Capsule Time Color`);
    sqTrendActions.setCapsuleTimeColorMode(mode);
  };

  const dropdownItems = _.map(CapsuleTimeColorMode, mode => (
    <Dropdown.Item
      key={mode}
      onClick={() => setCapsuleTimeColorModeAndTrack(mode)}
      data-testid={`color-mode-${mode}`}>
              <span className="flexColumnContainer flexFill">
                <i className={classNames('fa fa-fw mr5',
                  mode === capsuleTimeColorMode ? 'fa-check-circle' : 'fa-circle-thin')} />
                <span>{t(`TOOLBAR.${mode.toUpperCase()}`)}</span>
              </span>
    </Dropdown.Item>
  ));

  return view === TREND_VIEWS.CAPSULE && (
    <ToolbarDropdownButton
      icon="fa-tint"
      label="TOOLBAR.COLOR"
      isSmall={isSmall}
      extraClassNames="mtn1"
      dropdownItems={dropdownItems}
      tooltipText="TOOLBAR.COLOR_TOOLTIP"
      tooltipPlacement="top"
      id="toolbar-capsule-time-colors" />
  );
};
