import { CapsuleGroupController } from '@/investigate/customCondition/capsuleGroup.controller';
import capsuleGroupTemplate from './capsuleGroup.component.html';

/**
 * The `sq-capsule-group` component allows users to pick capsules from selections and by manually defining capsules
 */

export const sqCapsuleGroup = {
  controller: CapsuleGroupController,
  template: capsuleGroupTemplate,
  bindings: {
    previewId: '<'
  }
};
