import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useDatasources } from '@/hybrid/administration/datasources/datasources.hook';
import { DetailsLevel } from '@/hybrid/administration/datasources/datasources.module';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import {
  DatasourcesService,
  DatasourceStatus,
  FilterParameters
} from '@/hybrid/administration/datasources/datasources.service';
import { Overlay, Popover } from 'react-bootstrap';
import { DatasourcesStatusOutputV1, DatasourceSummaryStatusOutputV1, SyncProgressOutputV1 } from '@/sdk';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { AuthorizationService } from '@/services/authorization.service';
import { Icon } from '@/hybrid/core/Icon.atom';

const datasourcesStatusBindings = bindingsDefinition({
  sqDatasourcesService: injected<DatasourcesService>(),
  sqAuthorization: injected<AuthorizationService>()
});

export const DatasourcesStatus: SeeqComponent<typeof datasourcesStatusBindings> = () => {
  const {
    sqDatasourcesService,
    sqAuthorization
  } = useInjectedBindings(datasourcesStatusBindings);

  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const datasourcesContainer = useDatasources(DetailsLevel.Summary);
  const target = useRef(null);

  const datasourcesStatusIcons = new Map([
    [DatasourceStatus.Unknown, 'fa-exclamation-triangle sq-text-danger'],
    [DatasourceStatus.Error, 'fa-exclamation-triangle sq-text-danger'],
    [DatasourceStatus.Warning, 'fa-exclamation-circle sq-text-warning'],
    [DatasourceStatus.Indexing, 'fa-refresh fa-spin'],
    [DatasourceStatus.Happy, 'fa-check sq-text-success']
  ]);

  const datasourcesStatusMessages = new Map([
    [DatasourceStatus.Unknown, 'DATASOURCES.DISCONNECTED'],
    [DatasourceStatus.Error, 'DATASOURCES.DISCONNECTED'],
    [DatasourceStatus.Warning, 'DATASOURCES.INDEX_SUMMARY'],
    [DatasourceStatus.Indexing, 'DATASOURCES.INDEX_PROGRESS'],
    [DatasourceStatus.Happy, 'DATASOURCES.INDEX_SUMMARY']
  ]);

  const emptyFilters: FilterParameters = {
    name: '',
    datasourceClass: '',
    datasourceId: '',
    agentName: '',
    status: ''
  };

  const emptyDatasourceContainer: DatasourcesStatusOutputV1 = {
    connectedConnectionsCount: 0,
    connectedDatasourcesCount: 0,
    totalConnectionsCount: 0,
    totalDatasourcesCount: 0,
    datasources: [],
    agents: []
  };

  const renderAdministrationLink = () => {
    if (sqAuthorization.isAdmin()) {
      return (
        <div className='text-center pt7'>
          <a href='/administration' target='_blank'>
            <span>{t('DATASOURCES.MANAGEMENT')}</span>
          </a>
        </div>
      );
    }
  };

  const getDatasourceItemsCount = (syncProgress: SyncProgressOutputV1) => {
    return syncProgress.signalCount
      + syncProgress.conditionCount
      + syncProgress.scalarCount
      + syncProgress.assetCount
      + syncProgress.relationshipCount
      + syncProgress.userGroupCount;
  };

  const renderDatasourcesTray = (dsContainer: DatasourcesStatusOutputV1) => {
    const datasourcesStatuses = new Map();
    _.forEach(dsContainer.datasources, ds =>
      datasourcesStatuses.set(ds, sqDatasourcesService.getDatasourceStatus(ds)));

    const datasourcesStatus = _.reduce(Array.from(datasourcesStatuses.values()), (status1, status2) => {
      if (status1 === DatasourceStatus.Unknown || status2 === DatasourceStatus.Unknown) {
        return DatasourceStatus.Unknown;
      } else if (status1 === DatasourceStatus.Error || status2 === DatasourceStatus.Error) {
        return DatasourceStatus.Error;
      } else if (status1 === DatasourceStatus.Warning || status2 === DatasourceStatus.Warning) {
        return DatasourceStatus.Warning;
      } else {
        return DatasourceStatus.Happy;
      }
    }, DatasourceStatus.Happy);

    const datasourcesStatusIcon = datasourcesStatusIcons.get(datasourcesStatus);

    const getDatasourceStatusMessages = (ds: DatasourceSummaryStatusOutputV1) => {
      const status = datasourcesStatuses.get(ds);
      const messagePlaceholder = datasourcesStatusMessages.get(status);
      const args = {};
      if (status === DatasourceStatus.Happy || status === DatasourceStatus.Warning) {
        args['count'] = getDatasourceItemsCount(ds.syncProgress);
      }
      return t(messagePlaceholder, args);
    };

    const renderDatasource = (ds: DatasourceSummaryStatusOutputV1) => {
      const status = datasourcesStatuses.get(ds);
      const icon = datasourcesStatusIcons.get(status);
      const message = getDatasourceStatusMessages(ds);
      return (
        <div className='fakeTableRow fakeTableStriped flexColumnContainer flexNoGrowNoShrink' key={ds.id}>
          <div className='width-300 p7'>{ds.datasourceClass}: {ds.name}</div>
          <div className='p7'>
            <Icon icon={icon} type='inherit' />
            <span className='ml5'>{message}</span>
          </div>
        </div>);
    };

    const renderDatasources = () => {
      const filteredDatasources = sqDatasourcesService
        .filterAndSortDatasources(dsContainer.datasources, emptyFilters);
      return (
        <div className='flexRowContainer fakeTableWrapper' data-testid='datasourcesList'>
          {_.map(filteredDatasources, renderDatasource)}
        </div>
      );
    };

    const renderHeader = () => {
      return (
        <div className='flexColumnContainer fakeTableHeader'>
          <div className='width-300 pt7 pr7 pb2 pl7'>{t('NAME')}</div>
          <div className='nowrap pt7 pr7 pb2 pl7'>{t('STATUS')}</div>
        </div>
      );
    };

    const datasourcesList = (
      <Overlay
        show={show}
        target={target.current}
        rootClose={true}
        onHide={() => setShow(false)}
        placement='bottom-start'
        transition={false}>
        <Popover id='dataSourcesPopup' className='min-width-500 overflowAuto'>
          <div className='flexRowContainer p10'>
            {dsContainer.totalConnectionsCount !== 0 && renderHeader()}
            {dsContainer.totalConnectionsCount !== 0 && renderDatasources()}
            {renderAdministrationLink()}
          </div>
        </Popover>
      </Overlay>
    );

    return (
      <div>
        <div
          className='sq-navbar-default mt0 mb0 p5'
          id='toggleDatasources'
          onClick={() => setShow(!show)}
          ref={target}>
          <Icon icon={datasourcesStatusIcon} type='inherit' testId='datasourcesTrayIcon' />
          <span className='d-none d-sm-inline-block list-group-item-text ml3'>
            {t('DATASOURCES.NUM_CONNECTED',
              {
                connected: dsContainer.connectedDatasourcesCount,
                total: dsContainer.totalDatasourcesCount
              })}
          </span>
        </div>
        {datasourcesList}
      </div>
    );
  };

  return datasourcesContainer
    ? renderDatasourcesTray(datasourcesContainer)
    : renderDatasourcesTray(emptyDatasourceContainer);
};

export const sqDatasourcesStatus = angularComponent(datasourcesStatusBindings, DatasourcesStatus);
