import React from 'react';
import { bindingsDefinition, prop } from '../core/bindings.util';


const journalMetadataBindings = bindingsDefinition({
  name: prop<string>(),
  date: prop<string>(),
});

export const JournalMetadata: SeeqComponent<typeof journalMetadataBindings> = (props) => {
  const { name, date } = props;

  return (
    <div className="flexColumnContainer xsmall sq-fairly-dark-gray">
      <span className="specCreatedBy">{name}</span>
      <span className="pl3 pr3">&middot;</span>
      <span className="specUpdatedAt">{date}</span>
    </div>
  );
};
