import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { ToggleSwitch } from '@/hybrid/core/ToggleSwitch.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { AgentStatusOutputV1, ConnectionStatusOutputV1 } from '@/sdk';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { DateTimeService } from '@/datetime/dateTime.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { ConnectionStatus, DatasourcesService } from '@/hybrid/administration/datasources/datasources.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { AGENTS_TAB_INDEX } from '@/administration/administration.module';
import { AdministrationActions } from '@/administration/administration.actions';
import classNames from 'classnames';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';

const connectionCardBindings = bindingsDefinition({
  connection: prop<ConnectionStatusOutputV1>(),
  onConnectionManage: prop<(connection: ConnectionStatusOutputV1) => void>(),
  agents: prop<AgentStatusOutputV1[]>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqDateTime: injected<DateTimeService>(),
  sqDatasourcesService: injected<DatasourcesService>(),
  sqAdministrationActions: injected<AdministrationActions>()
});

export const ConnectionCard: SeeqComponent<typeof connectionCardBindings> = (props) => {
  const {
    connection,
    onConnectionManage,
    agents
  } = props;

  const {
    sqWorksheetStore,
    sqDateTime,
    sqDatasourcesService,
    sqAdministrationActions
  } = useInjectedBindings(connectionCardBindings);

  const { t } = useTranslation();
  const { timezone } = useFlux(sqWorksheetStore);

  const connectionStatusIconMap = new Map([
    [ConnectionStatus.Unknown, 'fa-exclamation-triangle sq-status-error width-17'],
    [ConnectionStatus.Disconnected, 'fa-exclamation-triangle sq-status-error width-17'],
    [ConnectionStatus.Connecting, 'fa-circle-o-notch fa-spin sq-status-progress width-17'],
    [ConnectionStatus.Connected, 'fa-check-circle sq-status-good width-17'],
    [ConnectionStatus.Indexing, 'fa-refresh fa-spin sq-status-progress width-17'],
    [ConnectionStatus.Disabled, 'fa-minus-circle disabledLook width-17']
  ]);

  const formatTime = (input: string): string => {
    const timeFormat = 'LL LTS'; // Example: June 1, 2020 8:00:00 AM
    return input != null
      ? sqDateTime.formatTime(sqDateTime.parseISODate(input), { name: timezone.name }, timeFormat)
      : t('ADMIN.DATASOURCES.NO_LAST_SUCCESSFUL_DATE');
  };

  const viewLogs = (connection: ConnectionStatusOutputV1) => {
    const logUrl = sqDatasourcesService.computeLogUrl(connection.agentName, agents, connection);
    window.open(logUrl, '_blank');
  };

  const viewAgent = (agentName) => {
    sqAdministrationActions.setActiveTabIndex(AGENTS_TAB_INDEX);
    sqAdministrationActions.setSearchParams({ field: 'name', value: agentName });
  };

  return (
    <Card key={connection.connectionId} className="mt10 datasourceConnectionCard">
      <Card.Header className="datasourceConnectionCard">
        <div className="flexColumnContainer">
          <div className="pt2 mr5">
            <Icon
              testId={`conStatusIcon_${connection.connectionId}`}
              icon={connectionStatusIconMap.get(sqDatasourcesService.getConnectionStatus(connection))}
              large={true} />
          </div>

          <div className="flexFill flexRowContainer">
            <div className="flexColumnContainer">
              <div className="font-size-larger font-weight-lighter">
                {t('ADMIN.DATASOURCES.CONNECTION_SERVED_BY')}
                <a
                  data-testid={`viewAgents_${connection.connectionId}`}
                  onClick={() => viewAgent(connection.agentName)}
                  href="#"
                  className="ml5 cursorPointer link-underline-hover">{connection.agentName}
                </a>
              </div>
              <div className="flexFill" />
              <HoverTooltip text={connection.datasourceManaged ?
                t('ADMIN.DATASOURCES.CONNECTION_ENABLE_DATASOURCE_MANAGED_TOOLTIP',
                  { agentName: connection.agentName }) : ''}>
                {/* Next div is added to be able to show the tooltip when button is disabled */}
                <div>
                  <ToggleSwitch
                    testId={`connEnable_${connection.connectionId}`}
                    isOn={connection.status !== SeeqNames.Connectors.Connections.Status.Disabled}
                    onChange={() => !connection.datasourceManaged &&
                      sqDatasourcesService.toggleConnectionEnable(connection)}
                    label="ADMIN.DATASOURCES.ENABLED"
                    disabled={connection.datasourceManaged}
                  />
                </div>
              </HoverTooltip>
            </div>
            <div className="flexFill flexColumnContainer">
              <div className="flexFill flexRowContainer">
                {connection.status === SeeqNames.Connectors.Connections.Status.Disconnected && <>
                  <div className="flewRowContainer">
                    <div className="flexColumnContainer truncatedLabel min-width-0">
                      <div className="truncatedLabel" title={connection.connectionMessage}>
                        {t('ADMIN.DATASOURCES.DISCONNECT_ERROR')} {connection.connectionMessage}
                      </div>
                    </div>
                    <div>
                      {t('ADMIN.DATASOURCES.LAST_SUCCESSFUL_CONNECTION', {
                        date: formatTime(connection.lastSuccessfulConnectedAt)
                      })}
                    </div>
                  </div>
                </>}
                {connection.status === SeeqNames.Connectors.Connections.Status.Disabled && <>
                  <div>
                    {t('ADMIN.DATASOURCES.DISABLED_BY_ON', {
                      disabledBy: connection.disabledBy,
                      disabledAt: formatTime(connection.disabledAt)
                    })}
                  </div>
                </>}
              </div>

              <div
                onClick={() => viewLogs(connection)}
                className="flexColumnContainer flexAlignStart flexJustifyEnd mr10 cursorPointer min-width-80"
                data-testid={`viewLogs_${connection.connectionId}`}>
                <Icon icon="fa fa-file-text-o fa-fw mt3" extraClassNames="mr5" />
                <span>{t('ADMIN.DATASOURCES.VIEW_LOGS')}</span>
              </div>

              <HoverTooltip text={connection.datasourceManaged ?
                t('ADMIN.DATASOURCES.CONNECTION_MANAGE_DATASOURCE_MANAGED_TOOLTIP',
                  { agentName: connection.agentName }) : ''}>
                {/* Next div is added to be able to show the tooltip when button is disabled */}
                <div>
                  <div
                    onClick={() => !connection.datasourceManaged && onConnectionManage(connection)}
                    className={classNames('flexColumnContainer', 'flexAlignStart', 'flexJustifyEnd', 'cursorPointer',
                      { 'disabledLook disabledBehavior': connection.datasourceManaged })}
                    data-testid={`connManage_${connection.connectionId}`}>
                    <Icon icon="fa fa-cog fa-fw mt3" extraClassNames="mr5" />
                    <span>{t('ADMIN.DATASOURCES.MANAGE')}</span>
                  </div>
                </div>
              </HoverTooltip>
            </div>
          </div>
        </div>
      </Card.Header>
    </Card>);
};
