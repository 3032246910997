import React from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { LOCALES } from '@/main/app.constants';

const englishOnlyWarningBindings = bindingsDefinition({
  sqWorkbenchStore: injected<WorkbenchStore>()
});

/**
 * Displays message that content is only available in English
 */
export const EnglishOnlyWarning: SeeqComponent<typeof englishOnlyWarningBindings> = () => {
  const { sqWorkbenchStore } = useInjectedBindings(englishOnlyWarningBindings);
  const { t } = useTranslation();

  const isEnglish = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.userLanguage === LOCALES.EN);

  return (
    !isEnglish &&
    <div className="xsmall text-italic">
      {t('ENGLISH_ONLY_WARNING')}
    </div>
  );
};
