import _ from 'lodash';
import angular from 'angular';
import { BaseSearchStoreService } from '@/search/baseSearchStore.service';
import { SEARCH_PANES } from '@/search/search.module';

angular.module('Sq.Search').store('sqSearchStore', sqSearchStore);

export type SearchStore = ReturnType<typeof sqSearchStore>['exports'];

/**
 * Provides an instance of search state used when the search widget is used in the data tab.
 */
function sqSearchStore(sqBaseSearchStore: BaseSearchStoreService) {

  const store = {
    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @return {Object} The state that is serialized
     */
    dehydrate() {
      return _.pick(this.state.serialize(), ['nameFilter', 'descriptionFilter', 'typeFilter', 'datasourceFilter',
        'sortBy', 'isAdvancedMode', 'mode', 'currentAsset']);
    },

    /**
     * Sets store from dehydratedState. If there are filters it fetches the results.
     *
     * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    }
  };
  return sqBaseSearchStore.extend(store, SEARCH_PANES.MAIN);
}
