import _ from 'lodash';
import moment from 'moment';
import angular, { IPromise } from 'angular';
import jQuery from 'jquery';
import HttpCodes from 'http-status-codes';
import {
  AssetSelection,
  CAPSULE_SELECTION,
  Content,
  CONTENT_LOADING_CLASS,
  DateRange,
  DateRangeAutoRate,
  DateRangeCondition,
  DEFAULT_DATE_RANGE,
  IMAGE_BORDER_CLASS,
  OFFSET_DIRECTION, REACT_JSON_VIEWS,
  REPORT_CONTENT,
  ReportContentSummary,
  SCREENSHOT_SIZE_TO_CONTENT,
  SummaryValue
} from './report.module';
import { ReportContentActions } from '@/reportEditor/reportContent.actions';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { ReportStore } from '@/reportEditor/report.store';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { ReportContentStore } from '@/reportEditor/reportContent.store';
import { WorksheetViewService } from '@/worksheet/worksheetView.service';
import { WorkstepsService } from '@/worksteps/worksteps.service';
import { UtilitiesService } from '@/services/utilities.service';
import { NotificationsService } from '@/services/notifications.service';
import { SocketService } from '@/services/socket.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { WorkbookStore } from '@/workbook/workbook.store';
import { AuthorizationService } from '@/services/authorization.service';
import { LoggerService } from '@/services/logger.service';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { JobsApi } from 'sdk/api/JobsApi';
import { HttpHelpersService } from '@/services/httpHelpers.service';
import { NumberHelperService } from '@/core/numberHelper.service';
import { SubscriptionsApi } from 'sdk/api/SubscriptionsApi';
import { VisibilityService } from '@/services/visibility.service';
import { DURATION_TIME_UNITS, GUID_REGEX_PATTERN, NUMBER_CONVERSIONS } from '@/main/app.constants';
import { ScreenshotService } from '@/services/screenshot.service';
import {
  AssetSelectionInputV1,
  AssetSelectionOutputV1,
  ContentApi,
  ContentInputV1,
  ContentOutputV1,
  DateRangeInputV1,
  DateRangeOutputV1,
  ItemsApi
} from '@/sdk';
import { ReportActions } from '@/reportEditor/report.actions';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { FrontendDuration, SystemConfigurationService } from '@/services/systemConfiguration.service';
import { SummaryTypeEnum } from 'sdk/model/ContentInputV1';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';

angular.module('Sq.Report').factory('sqFroalaReportContent', sqFroalaReportContent);

export type FroalaReportContentService = ReturnType<typeof sqFroalaReportContent>;

function sqFroalaReportContent(
  $q: ng.IQService,
  $http: ng.IHttpService,
  $state: ng.ui.IStateService,
  $rootScope: ng.IRootScopeService,
  $interval: ng.IIntervalService,
  $injector: ng.auto.IInjectorService,
  $translate: ng.translate.ITranslateService,
  $document: ng.IDocumentService,
  sqContentApi: ContentApi,
  sqScreenshot: ScreenshotService,
  sqReportContentStore: ReportContentStore,
  sqReportContentActions: ReportContentActions,
  sqPendingRequests: PendingRequestsService,
  sqReportStore: ReportStore,
  sqReportEditor: ReportEditorService,
  sqWorksheetView: WorksheetViewService,
  sqWorksteps: WorkstepsService,
  sqUtilities: UtilitiesService,
  sqNotifications: NotificationsService,
  sqSocket: SocketService,
  sqDateTime: DateTimeService,
  sqWorkbookStore: WorkbookStore,
  sqAuthorization: AuthorizationService,
  sqLogger: LoggerService,
  sqSystemConfiguration: SystemConfigurationService,
  sqWorksheetStore: WorksheetStore,
  sqJobsApi: JobsApi,
  sqHttpHelpers: HttpHelpersService,
  sqNumberHelper: NumberHelperService,
  sqSubscriptionsApi: SubscriptionsApi,
  sqVisibility: VisibilityService,
  sqItemsApi: ItemsApi
) {
  const BLANK_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

  // N.B.: On the way "out" of the API, we don't worry about 'week's since we convert them to days before creating
  // the date range.
  const SCHEDULE_REGEXES = {
    s: /^\*\/(\d+) \* \* \* \* \?$/,
    min: /^0 \*\/(\d+) \* \* \* \?$/,
    h: /^0 0 \*\/(\d+) \* \* \?$/,
    day: /^0 0 0 \*\/(\d+) \* \?$/,
    month: /^0 0 0 1 \*\/(\d+) \?$/,
    year: /^0 0 0 1 1 ? \*\/(\d+)$/
  };

  const compileContent = _.template(
    `<a href="\${worksheetUrl}"><img ${SeeqNames.TopicDocumentAttributes.DataSeeqContent}="\${id}"
      class="\${borderStyle} ${CONTENT_LOADING_CLASS.SPINNER}"
      style="min-height: \${height}px; max-height: \${height}px; min-width: \${width}px; max-width: \${width}px;"></a>`);

  const compilePendingContent = _.template(`<img ${SeeqNames.TopicDocumentAttributes.DataSeeqContentPending}="\${id}"
      class="${CONTENT_LOADING_CLASS.PENDING}" style="min-height: 100px; min-width: 100px;">`);

  // This regex handles both workbench URLs and API hrefs (the latter don't have protocol/host/port and use plural
  // workbooks/worksheets)
  const worksheetRegex = new RegExp(
    '^\\s*(?:http.*)?/(?:workbooks?|(?:view|present)/worksheets?)/(' + GUID_REGEX_PATTERN + ')/.*?(' + GUID_REGEX_PATTERN + ').*?\\s*$',
    'i');
  const viewRegex = new RegExp('^\\s*http.*?/view/(' + GUID_REGEX_PATTERN + ')\\s*$', 'i');
  const service = {
    getWorksheetUrlParams,
    isWorksheetUrl,
    insertOrReplaceContent,
    replaceContentIfExists,
    refreshMultipleContent,
    refreshContentUsingDate,
    refreshAllContent,
    handleImageRemoved,
    handleCopyHtml,
    handlePastedHtml,
    beforePasteCleanup,
    cleanup,
    computeCapsuleOffset,
    formatContentFromApiOutput,
    formatContentToApiInput,
    formatAssetSelectionFromApiOutput,
    formatAssetSelectionToApiInput,
    formatDateRangeToApiInput,
    formatDateRangeFromApiOutput,
    canModifyDocument,
    areNodesEqual,
    areDocumentsEqual,
    parseHtmlToNodes,
    parseHtmlToNodesForComparison,
    getStrippedAndValidatedDocument,
    getAllContent,
    setAllContentUrlsToFullUrls,
    quartzCronExpressionHelper,
    parseSummaryToTypeAndValue,
    postReportViewed,
    BLANK_IMAGE,
    toggleContentBorders,
    getContentIdsInSelection,
    getContentIdsInDocumentOrder,
    loadAllPendingContent,
    handleLiveScreenshotMessageForContent,

    // Exposed for test
    createDateRangeFormula,
    contentError,
    displayError,
    insertNewContent,
    attachEventHandlers,
    extractDurationFromFormula,
    extractOffsetAndDirectionFromFormula,
    filterOutCommonAttributesForCompareOrSave,
    convertSummaryValueBasedOnType,
    compileContent,
    setPendingContent
  };

  return service;

  function getCurrentWorkstep(workbookId, worksheetId) {
    return sqItemsApi.getItemAndAllProperties({ id: workbookId })
      .then(({ data: item }) => {
        if (item.type === 'Topic') {
          return $q.reject('REPORT.CONTENT.LINK_TOPIC_DOCUMENT_NOT_ALLOWED');
        }
      })
      .then(() => sqWorksteps.getCurrentWorkstepId(workbookId, worksheetId));
  }

  /**
   * Extracts the workthing parameters from a content URL. Can handle normal worksheet URLs, presentation URLs, and
   * view-only URLs.
   *
   * @param {String} url - The url from which to extract the parameters.
   * @returns {Promise} A promise that resolves with the workbookId, worksheetId, and workstepId. If URL is not a valid
   *   link, rejects promise with a translation key suitable for display
   */
  function getWorksheetUrlParams(url) {
    let workbookId, worksheetId;
    // NOTE: consider ui-router once upgraded to 1.x (https://github.com/angular-ui/ui-router/issues/3174)
    if (viewRegex.test(url)) {
      [, worksheetId] = url.match(viewRegex);
      return sqItemsApi.getItemAndAllProperties({ id: worksheetId })
        .then(({ data: { workbookId } }) => getCurrentWorkstep(workbookId, worksheetId)
          .then(workstepId => ({ workbookId, worksheetId, workstepId })));
    } else if (worksheetRegex.test(url)) {
      [, workbookId, worksheetId] = url.match(worksheetRegex);
      return getCurrentWorkstep(workbookId, worksheetId)
        .then(workstepId => ({ workbookId, worksheetId, workstepId }));
    } else {
      return $q.reject('REPORT.CONTENT.LINK_INVALID');
    }
  }

  /**
   * Determines if a URL is a valid seeq content URL that can be used to create an image.
   *
   * @param {String} url - The URL to test.
   * @returns {Boolean} True if it a seeq content URL, false otherwise
   */
  function isWorksheetUrl(url) {
    return viewRegex.test(url) || worksheetRegex.test(url);
  }

  function contentError(contentId: string) {
    const $image = getContentImage(contentId);
    const content = sqReportStore.getContentById(contentId);
    const dateRange = sqReportStore.getDateRangeById(content?.dateRangeId);
    const errorClass = dateRange?.auto.noCapsuleFound ? CONTENT_LOADING_CLASS.NO_CAPSULE_ERROR :
      CONTENT_LOADING_CLASS.ERROR;
    removeEventHandlers($image);
    $image.attr('src', BLANK_IMAGE);
    $image.addClass(errorClass);
    $image.removeClass(CONTENT_LOADING_CLASS.LOADED);
    if (errorClass === CONTENT_LOADING_CLASS.NO_CAPSULE_ERROR) {
      $image.attr('title', $translate.instant('REPORT.CONTENT.NO_CAPSULE_FOUND'));
    }

    // Resize the image to ensure that we show the error icon; use the actual size of the content if we know it,
    // or 100x100 if we don't.
    $image.css('min-width', content?.width || 100);
    $image.css('max-width', content?.width || 100);
    $image.css('min-height', content?.height || 100);
    $image.css('max-height', content?.height || 100);

    finishLoading($image);
  }

  function finishLoading($image) {
    $image.removeClass(CONTENT_LOADING_CLASS.SPINNER);
    removeEventHandlers($image);
    $injector.get<ReportActions>('sqReportActions').debouncedImageStateChanged();
  }

  function removeEventHandlers($image) {
    $image.off('load');
    $image.off('error');
  }

  /**
   * Attaches load and error handlers to a Seeq content image.
   *
   * @param contentId
   * @param [silently] - set to true to skip setting the loading spinner class
   */
  function attachEventHandlers(contentId: string, silently = false) {
    const $image = getContentImage(contentId);
    // Remove any pre-existing event handlers first, since we may be replacing content that's currently loading.
    removeEventHandlers($image);

    if (!silently) {
      $image.addClass(CONTENT_LOADING_CLASS.SPINNER);
      $image.removeClass(CONTENT_LOADING_CLASS.LOADED);
      $image.removeClass(CONTENT_LOADING_CLASS.ERROR);
      $image.removeClass(CONTENT_LOADING_CLASS.NO_CAPSULE_ERROR);
      $image.attr('title', '');
    }

    $injector.get<ReportActions>('sqReportActions').debouncedImageStateChanged();

    $image.one({
      load() {
        const $image = getContentImage(contentId);
        const rawImage = $image.get(0) as HTMLImageElement;
        // 1x1 image is what the backend sends to indicate the image is being generated asynchronously
        if (rawImage.naturalWidth === 1 && rawImage.naturalHeight === 1) {
          return;
        }

        $image.addClass(CONTENT_LOADING_CLASS.LOADED);
        $image.removeClass(CONTENT_LOADING_CLASS.ERROR);
        $image.removeClass(CONTENT_LOADING_CLASS.NO_CAPSULE_ERROR);

        $image.attr('title', '');
        $image.css('min-width', '');
        $image.css('max-width', '');
        $image.css('min-height', '');
        $image.css('max-height', '');

        updateSizeAfterRender(rawImage);
        finishLoading($image);
      },
      error() {
        service.contentError(contentId);
      }
    });
  }

  /**
   * For content that is sized based on a css selector (.useSizeFromRender=true), this function will get the size of
   * the rendered image, set it in the store, and update the size/width of the content in the DOM.
   *
   * @param {HTMLImageElement} imageElement
   */
  function updateSizeAfterRender(imageElement) {
    const contentId = imageElement.getAttribute(SeeqNames.TopicDocumentAttributes.DataSeeqContent);
    if (!contentId) return;

    const content = sqReportStore.getContentById(contentId);
    if (content && content.useSizeFromRender &&
      (imageElement.naturalWidth !== content.width || imageElement.naturalHeight !== content.height)) {
      const sqReportActions = $injector.get<ReportActions>('sqReportActions');
      // .naturalWidth and .naturalHeight give you the size of the image as returned, not as displayed in the
      // browser
      sqReportActions.setContentRenderSize(contentId, imageElement.naturalWidth, imageElement.naturalHeight);
    }
  }

  /**
   * Inserts Seeq content into the document. For content items that are already in the document, the HTML element is
   * replaced at its current location. For content items that are not in the document, a new element is inserted at
   * the current cursor location or at the location of elementToReplace, if specified.
   *
   * @param {string} contentId - The id of the content.
   * @param {jQuery} elementToReplace - if specified, new content element will replace this element
   * @returns {object} { isNewContent: boolean } - True if new content was inserted
   */
  function insertOrReplaceContent(contentId: string, elementToReplace?: JQuery): { isNewContent: boolean } {
    const $image = getContentImage(contentId);
    const isNewContent = $image.length === 0;

    if (isNewContent) {
      service.insertNewContent(contentId, elementToReplace);
    } else {
      service.replaceContentIfExists(contentId);
    }

    return { isNewContent };
  }

  function insertNewContent(contentId: string, elementToReplace?: JQuery) {
    const content: Content = sqReportStore.getContentById(contentId);
    if (_.isUndefined(content)) {
      throw new Error(`Content with id ${contentId} not found in store.`);
    }
    const newElement = compileContent({
      id: contentId,
      worksheetUrl: sqReportStore.getWorksheetUrl(contentId),
      height: content.height,
      width: content.width,
      borderStyle: !content.useSizeFromRender ? IMAGE_BORDER_CLASS : ''
    });

    if (elementToReplace) {
      elementToReplace.replaceWith(newElement);
    } else {
      sqReportEditor.insertHtml(newElement);
    }
    const $image = getContentImage(contentId);
    attachEventHandlers(contentId);
    $image.attr('src', sqReportStore.getContentImageUrl(contentId));
  }

  /**
   * Updates Seeq content in the document. Uses the current contents of sqReportContentStore and
   * the current location of the content (for existing content items)
   *
   * @param contentId - The id of the content.
   * @param [silently] - If true, replaces existing image with no spinners or progress bars
   * @param [deferImageUpdate] - If true, avoids requesting image directly and waits for a message from a job
   * @returns { isNewContent: boolean } - True if new content was inserted
   */
  function replaceContentIfExists(contentId: string, silently = false,
    deferImageUpdate = false): { isNewContent: boolean } {
    const $image = getContentImage(contentId);
    if ($image.length === 0) return { isNewContent: true };

    // The anchor tag doesn't need to change so we only reload the image. Also there may be instances where the image
    // doesn't have a rendered width or height (e.g. when the document is being set from another event), so ensure that
    // we set the min/max-width and min/max-height so that the loading spinner will render into an image element with a
    // fixed size
    if (!silently) {
      const content = sqReportStore.getContentById(contentId);
      const renderedWidth = $image.width();
      const renderedHeight = $image.height();
      const width = renderedWidth > 0 ? renderedWidth : content.width;
      const height = renderedHeight > 0 ? renderedHeight : content.height;
      $image.css('min-width', width);
      $image.css('max-width', width);
      $image.css('min-height', height);
      $image.css('max-height', height);
      $image.removeAttr('src');
    }

    attachEventHandlers(contentId, silently);

    if (!deferImageUpdate) {
      $image.attr('src', sqReportStore.getContentImageUrl(contentId));
    }

    return { isNewContent: false };
  }

  /**
   * Returns a jQuery object of all pieces of Seeq content that are currently in an error state.
   *
   * @returns {JQuery<TElement extends Node>}
   */
  function getContentInErrorState() {
    return jQuery(`img[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}].${CONTENT_LOADING_CLASS.ERROR}`);
  }

  /**
   * Returns a jQuery object of all pieces of Seeq content, regardless of their state.
   *
   * @returns {jQuery} object of all Seeq content elements
   */
  function getAllContent(): JQuery {
    return jQuery(`[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`);
  }

  /**
   * This function updates all content in the document. This function is useful for reloading all the data if the
   * timezone changes.
   *
   * @param {boolean} [errorsOnly] - When true, only update content that is currently in an error state
   * @param {boolean} [deferImageUpdate] - If true, avoids requesting image directly and waits for a message from a job
   * @param {boolean} [silently] - If true, replaces existing image with no spinners or progress bars
   */
  function refreshAllContent(errorsOnly = false, deferImageUpdate = false, silently = false) {
    const contentList = errorsOnly ? getContentInErrorState() : getAllContent();
    _.forEach(contentList,
      content => service.replaceContentIfExists(
        jQuery(content).attr(SeeqNames.TopicDocumentAttributes.DataSeeqContent), silently, deferImageUpdate));
  }

  /**
   * Replaces the seeq content images with new screenshots
   *
   * @param contentIds - List of content IDs to update
   */
  function refreshMultipleContent(contentIds: string[]) {
    _.forEach(contentIds, contentId => service.replaceContentIfExists(contentId));
  }

  /**
   * Replaces the seeq content images that use the specified dateRange with new screenshots based on the latest
   * values from the dateRange.
   *
   * @param {String} [dateRangeId] - Id of the dateRange. If undefined, content with no dateRange
   *   (i.e. determined by worksheet) will be updated.
   * @param {boolean} [deferImageUpdate] - If true, avoids requesting image directly and waits for a message from a job
   */
  function refreshContentUsingDate(dateRangeId, deferImageUpdate = false) {
    _.forEach(sqReportStore.contentUsingDateRange(dateRangeId),
      content => service.replaceContentIfExists(content.id, false, deferImageUpdate));
  }

  /**
   * Returns the JQuery instance for the content image.
   */
  function getContentImage(id: string) {
    return jQuery(`img[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}="${id}"]`);
  }

  /**
   * If the image removed is Seeq Content, remove the enclosing <a tag as well
   *
   * @param {JQuery} img
   */
  function handleImageRemoved(img: JQuery): void {
    const image = img[0];
    if (image.hasAttribute(SeeqNames.TopicDocumentAttributes.DataSeeqContent)) {
      const parent = image.parentElement;
      if (parent && parent.hasAttribute('href')) {
        jQuery(parent).remove();
      }
    }
  }

  /**
   * If authentication is required on the /api/content/{id}/image endpoint
   *   then it checks copied HTML and if Seeq Content is present, it prepares it for pasting into an external
   * application. otherwise no modifications to the copied HTML is necessary
   */
  function handleCopyHtml() {
    if (!sqSystemConfiguration.isAuthOnContentImageEnabled) {
      return;
    }

    const selection = window.getSelection();
    if (selection.rangeCount !== 1) {
      return '';
    }

    const sqReportContent = $injector.get<ReportContentService>('sqReportContent');
    // Temporarily set image data URL as src. The browser will copy it into the clipboard and the user successfully
    // paste such images into a native application without any additional request to Seeq.
    const range = selection.getRangeAt(0);
    jQuery('img:not([seeq-src])').each(function() {
      if (range.intersectsNode(this)) {
        const img = jQuery(this);
        img.attr('seeq-src', img.attr('src'));
        img.attr('src', sqReportContent.getImageDataURL(this));
      }
    });
    // On the next tick, remove the added data URL and put back the original src
    setTimeout(() => {
      jQuery('img[seeq-src]').each(function() {
        const img = jQuery(this);
        img.attr('src', img.attr('seeq-src'));
        img.removeAttr('seeq-src');
      });
    }, 1);
  }

  /**
   * If authentication is required on the /api/content/{id}/image endpoint
   *   then it reverts the changes done by {@code handleCopyHtml} so that we can paste the html into the editor
   * otherwise no modifications to the pasted HTML is necessary
   *
   * @param pastedHtml - the original pasted html
   * @return an html which can be pasted into Seeq
   */
  function beforePasteCleanup(pastedHtml: string): string {
    if (!sqSystemConfiguration.isAuthOnContentImageEnabled) {
      return pastedHtml;
    }

    // overwrites image src with original value stored in seeq-src
    return pastedHtml.replace(/<img.*?seeq-src=.*?>/g,
      match => match.replace(/\ssrc=".*?"/, '').replace(/seeq-src=/, 'src=')
    );
  }

  /**
   * Checks pasted HTML to see if Seeq Content is present, and copies into a new content item (and associated
   * dateRange) as necessary so that any given piece of Seeq Content appears in no more than one topic document,
   * and appears no more than once in that document.
   *
   * @param pastedHtml - "clean" HTML to be pasted
   * @returns to use as the pasted content, including any temporary elements that will be replaced once
   *  the content has been copied
   */
  function handlePastedHtml(pastedHtml: string): string {
    const documentContentIds = _.map(getAllContent().toArray(),
      el => el.getAttribute(SeeqNames.TopicDocumentAttributes.DataSeeqContent));

    // Wrap the input in a div so that we ensure it is only a single element. The extra div will be excluded when
    // .html() is called
    const newContent = angular.element(`<div>${pastedHtml}</div>`);

    let copyPromise = $q.resolve();
    newContent.find(`[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`)
      .each((index, content) => {
        // For each content item, insert a placeholder element using the original content id that can be returned
        // immediately from this function. Any content or date ranges that need to be copied will be started to run
        // asynchronously, replacing the temporary element whenever they complete.
        const pastedId = content.getAttribute(SeeqNames.TopicDocumentAttributes.DataSeeqContent);
        // Don't remove the content's parent if it's the bare <div> that we added above.
        const elementToRemove =
          _.startsWith(content.parentElement.outerHTML, '<div>') ? content : content.parentElement;
        angular.element(compilePendingContent({ id: pastedId })).insertAfter(elementToRemove);
        elementToRemove.parentElement?.removeChild(elementToRemove); // IE11 doesn't support element.remove()
        const isContentInDocument = _.includes(documentContentIds, pastedId);
        // Must be done sequentially so that newly created date ranges can be re-used
        copyPromise = copyPromise.finally(
          () => fetchAndSetPendingContent(pastedId, isContentInDocument));
        sqPendingRequests.registerVolatilePromise(copyPromise);
      });

    copyPromise.finally(() => sqReportEditor.triggerDocumentChangedEvent());

    return newContent[0].innerHTML;
  }

  /**
   * Loads pending content items (and associated date ranges) when the document is opened.
   */
  function loadAllPendingContent() {
    const sqReportActions = $injector.get<ReportActions>('sqReportActions');

    const document = sqReportStore.document;
    const loadedContentIds = sqReportActions.parseSeeqContentIdsFromHtml(document);
    const pendingContentIds = sqReportActions.parsePendingSeeqContentIdsFromHtml(document);

    let loadPromise = $q.resolve();
    _.forEach(pendingContentIds, (pendingId) => {
      const isContentInDocument = _.includes(loadedContentIds, pendingId);
      // Must be done sequentially so that newly created date ranges can be re-used
      loadPromise = loadPromise.finally(
        () => fetchAndSetPendingContent(pendingId, isContentInDocument, true));
      sqPendingRequests.registerVolatilePromise(loadPromise);
    });

    loadPromise.finally(() => sqReportEditor.triggerDocumentChangedEvent());
  }

  function handleLiveScreenshotMessageForContent(contentId: string, hashCode: string): { isNewContent: boolean } {
    $injector.get<ReportActions>('sqReportActions').setContentHashCode(contentId, hashCode);
    return service.replaceContentIfExists(contentId, true);
  }

  /**
   * Gets and loads content, duplicating/unarchiving if necessary, to replace a pending content item (from a paste)
   * Checks the date range associated with the content, and if necessary, copies that date range. If there is
   * already a matching dateRange in the target document, then use that instead, so we do not duplicate.
   * Two date ranges match if they have the same condition, the same duration, and both are auto or both are not auto.
   *
   * @param pendingId - id of the content that is pending (i.e. content that was pasted but hasn't finished loading)
   * @param isContentInDocument - is the specified content already in the content? (i.e. was the content pasted from
   *            the same document?)
   * @param [onLoad] - are we loading pending content when the document has just opened?
   * @returns promise that completes when the content has been set in the document
   */
  function fetchAndSetPendingContent(pendingId: string, isContentInDocument: boolean,
    onLoad: boolean = false): IPromise<any> {
    const sqReportActions = $injector.get<ReportActions>('sqReportActions');
    const sqReportContent = $injector.get<ReportContentService>('sqReportContent');
    let apiContent;
    let contentId = pendingId;

    return sqReportActions.fetchContent(pendingId, false)
      .then(({ content, dateRange }) => {
        apiContent = content;
        return sqReportContent.copyDateRangeForPendingContent(dateRange, $q);
      })
      .then(dateRangeId => sqReportContent.duplicateOrUnarchivePendingContent(apiContent, isContentInDocument, $q,
        dateRangeId))
      .then((content: Content) => {
        contentId = content.id;
        return setPendingContent(content, pendingId);
      })
      .catch((err) => {
        // If pending content needs to be duplicated, and the current user doesn't have access to the content's
        // worksheet, then the user won't be able to finish loading the content.
        if (onLoad && err.status === HttpCodes.FORBIDDEN) {
          const additionalMessage = $translate.instant('REPORT.CONTENT.COULD_NOT_GENERATE');
          err.data.statusMessage = err.data.statusMessage.concat(`. ${additionalMessage}: ${contentId}`);
        }
        handlePendingContentError(contentId, err);
      });
  }

  /**
   * Sets the id of the pending content to the correct id in the html document.
   * Then, sets the content in the store and fetches the content image.
   *
   * @param content - content item that was pasted/is pending
   * @param pendingId - id on the pending content item in the html document (probably the id of the content itme
   *            that was copied)
   */
  function setPendingContent(content: Content, pendingId: string): void {
    const contentId = content.id;
    // Replace placeholder pending content with pending content that matches the right content Id
    if (contentId !== pendingId) {
      jQuery(`img[${SeeqNames.TopicDocumentAttributes.DataSeeqContentPending}=${pendingId}]`).get(0)
        .setAttribute(SeeqNames.TopicDocumentAttributes.DataSeeqContentPending, contentId);
    }
    // Set the content in the store and fetch the content image
    $injector.get<ReportActions>('sqReportActions').setContent(content);
    updatePendingContent(contentId, false);
  }

  /**
   * When fetching/setting pending content, handle errors by replacing the pending image with an error icon, and
   * display the error.
   *
   * @param contentId
   * @param err
   */
  function handlePendingContentError(contentId: string, err) {
    const $errorElement = jQuery(`img[${SeeqNames.TopicDocumentAttributes.DataSeeqContentPending}=${contentId}]`);
    if ($errorElement) {
      $errorElement.attr('src', BLANK_IMAGE);
      $errorElement.attr('width', '100px');
      $errorElement.attr('height', '100px');
      $errorElement.addClass(CONTENT_LOADING_CLASS.ERROR);
      $errorElement.removeClass(CONTENT_LOADING_CLASS.SPINNER);
      $errorElement.removeClass(CONTENT_LOADING_CLASS.PENDING);
    }
    service.displayError(err?.data ?? err);
  }

  /**
   * Check the DOM for any pending Seeq elements (or the Seeq element with the passed in id) and replace them with a
   * corresponding content element.
   *
   * @param pendingId - If present, the id of the content to attempt updating
   * @param updateDocument - Whether or not to update the document after updating the pending elements
   */
  function updatePendingContent(pendingId: string = undefined, updateDocument = true) {
    if (pendingId) {
      const pendingElement: JQuery<HTMLElement> = jQuery(
        `img[${SeeqNames.TopicDocumentAttributes.DataSeeqContentPending}=${pendingId}]`);
      if (pendingElement.length === 1) {
        service.insertOrReplaceContent(pendingId, pendingElement);
      }
    } else {
      const pendingElements = jQuery(`img[${SeeqNames.TopicDocumentAttributes.DataSeeqContentPending}]`);
      pendingElements.each((index, element) => {
        const tempId = element.getAttribute(SeeqNames.TopicDocumentAttributes.DataSeeqContentPending);
        // @ts-ignore: JQuery <=> HTMLElement typing gets weird, but this works fine
        service.insertOrReplaceContent(tempId, element);
      });
    }

    // Froala sometimes does not fire contentChanged events until some additional input (such as [un]focusing another
    // element after making a change), so save the report here just in case
    if (updateDocument) sqReportEditor.triggerDocumentChangedEvent();
  }

  /**
   * Called when the document loads. Ensures that any loading-related states are removed, i.e. all classes that
   * indicate whether a content image finished loading or generated an error are cleared so that they can be
   * updated on the current load.
   */
  function cleanup() {
    if (sqUtilities.headlessRenderMode()) {
      return;
    }

    for (const image of getAllContent()) {
      const $image = jQuery(image);
      const contentId = $image.attr(SeeqNames.TopicDocumentAttributes.DataSeeqContent);
      attachEventHandlers(contentId);
      $image.attr('src', sqReportStore.getContentImageUrl(contentId));
    }
  }

  /**
   * Computes a capsule offset number based on the date variable condition
   *
   * @param condition - The date range condition
   * @returns the capsule offset
   */
  function computeCapsuleOffset(condition: DateRangeCondition): number {
    const { strategy, reference, offset = 1 } = condition;
    const offsetValue = (strategy === CAPSULE_SELECTION.STRATEGY.CLOSEST_TO) ? 1 :
      (sqNumberHelper.toNumber(offset) + 1);
    const signValue = (reference === CAPSULE_SELECTION.REFERENCE.START) ? 1 : -1;
    return offsetValue * signValue;
  }

  /**
   * Generate a formula based on the dateRange information. Formula can be executed manually (via /formula/run
   * endpoint) or added to a dateRange definition.
   *
   * @param {Object} dateRange - dateRange information to use
   * @returns {string} executable Seeq formula
   */
  function createDateRangeFormula(dateRange) {
    // Fixed range, no condition
    if (!dateRange.auto.enabled && !dateRange.condition.id) {
      return `capsule(${dateRange.range.start}ms, ${dateRange.range.end}ms)`;
    }

    const capsuleOffset = service.computeCapsuleOffset(dateRange.condition);
    const maximumDuration = dateRange.condition?.maximumDuration
      ? `${dateRange.condition.maximumDuration.value}${dateRange.condition.maximumDuration.units}`
      : '';

    // Fixed range, condition
    // For this configuration, we save the search range and other parameters used to find the capsule in a formula
    // comment, so that we can present it back to the user in the UI when editing the dateRange.
    if (!dateRange.auto.enabled && dateRange.condition.id && dateRange.condition.isCapsuleFromTable) {
      return `// searchStart=${dateRange.condition.range.start}ms
        // searchEnd=${dateRange.condition.range.end}ms
        // columns=${dateRange.condition.columns.join(',')}
        // sortBy=${dateRange.condition.sortBy}
        // sortAsc=${dateRange.condition.sortAsc}
        capsule(${dateRange.range.start}ms, ${dateRange.range.end}ms)`;
    } else if (!dateRange.auto.enabled && dateRange.condition.id && !dateRange.condition.isCapsuleFromTable) {
      return `// searchStart=${dateRange.condition.range.start}ms
        // searchEnd=${dateRange.condition.range.end}ms
        // capsuleOffset=${capsuleOffset}
        // maxDuration=${maximumDuration}
        capsule(${dateRange.range.start}ms, ${dateRange.range.end}ms)`;
    }

    // Auto-update range
    const sign = (dateRange.auto.offsetDirection === OFFSET_DIRECTION.FUTURE) ? '+' : '-';
    const offset = `${dateRange.auto.offset.value}${dateRange.auto.offset.units}`;
    const duration = `${dateRange.auto.duration}ms`;
    const rangeFormula = `capsule($now ${sign} ${offset} - ${duration}, $now ${sign} ${offset})`;

    if (dateRange.condition.id) {
      const maximumDurationSnippet = maximumDuration ? `.removeLongerThan(${maximumDuration})` : '';
      return `$condition${maximumDurationSnippet}.setCertain().toGroup(${rangeFormula}).pick(${capsuleOffset})`;
    } else {
      return rangeFormula;
    }
  }

  /**
   * Extracts the date range's duration from the capsule formula.
   *
   * @param formula
   * @returns duration in milliseconds
   */
  function extractDurationFromFormula(formula): number {
    const durationMatch = formula.match(/\$now\s*[+-]\s*(.*?)\s*[+-]\s*([\d\.]+[a-z]+),/i)?.[2];
    return sqDateTime.parseDuration(durationMatch).valueOf();
  }

  /**
   * Extracts the date range's offset and offset direction from the capsule formula.
   *
   * @param formula
   * @returns [{{ value: Number, units: string}}, offsetDirection: string]
   */
  function extractOffsetAndDirectionFromFormula(formula) {
    const formulaOffset = formula.match(/[+-](.*?)[-](.*?)(\$now)(.*)/)[4];
    const value = sqNumberHelper.toNumber(formulaOffset.match(/(\d+)(\w+)/)[1]);
    const units = formulaOffset.match(/(\d+)(\w+)/)[2];
    const offset = { value, units };

    const offsetDirection = (formulaOffset.match(/[+-]/)[0] === '-')
      ? OFFSET_DIRECTION.PAST : OFFSET_DIRECTION.FUTURE;

    return [offset, offsetDirection];
  }

  /**
   * Extract content parameters from API response.
   *
   * @param {ContentOutputV1} contentOutput - Output of /content endpoint call
   * @returns {Object} storeContent - Object in format for store
   */
  function formatContentFromApiOutput(contentOutput: ContentOutputV1): Content {
    const content: any = _.pick(contentOutput, ['name', 'id', 'height', 'width', 'scale', 'timezone', 'hashCode']);
    content.workbookId = contentOutput.sourceWorkbook;
    content.worksheetId = contentOutput.sourceWorksheet;
    content.workstepId = contentOutput.sourceWorkstep;
    content.useSizeFromRender = !!contentOutput.selector;
    content.dateRangeId = contentOutput.dateRange?.id;
    content.reportId = contentOutput.report?.id;
    content.isArchived = contentOutput.archived;
    content.isReact = contentOutput.react;
    if (contentOutput.summaryType) {
      content.summary = {
        ..._.find(REPORT_CONTENT.SUMMARY, { key: contentOutput.summaryType })
      };
      content.summary.value = convertSummaryValueBasedOnType(content.summary.key, contentOutput.summaryValue);
    }
    content.assetSelectionId = contentOutput.assetSelection ? contentOutput.assetSelection.id : undefined;

    return content;
  }

  /**
   * Converts the given summary type and value into a SummaryValue
   *
   * @param type - The type of the summary given by the backend
   * @param value - The value of the summary given by the backend
   * @return - The frontend representation of the SummaryValue
   */
  function convertSummaryValueBasedOnType(type: SummaryTypeEnum, value: string): SummaryValue {
    if (SummaryTypeEnum.NONE === type) {
      return undefined;
    }
    return type === SummaryTypeEnum.DISCRETE ? sqDateTime.splitDuration(value) : Number(value);
  }

  /**
   * Construct a ContentInputV1 object from the frontend content
   *
   * @param {Object} content
   * @returns {ContentInputV1}
   */
  function formatContentToApiInput(content) {
    // TODO CRAB-20427 - For now, we do not persist the content timezone at all since the user has no way
    // to set the timezone via the UI.  There were certain circumstances where the content would be persisted
    // with a timezone (e.g. restoring content) or removed (e..g creating/modifying content, CRAB-20426), leading
    // to inconsistent renders since the content timezone has the highest priority.
    // The behavior that we want for scheduled docs is for the report timezone to have priority with a fallback
    // on the worksheet timezone. Therefore, never persist a content timezone until we work on CRAB-20427. -Che & Mike
    const contentInput: ContentInputV1 = _.pick(content,
      ['name', 'height', 'width', 'scale', 'worksheetId', 'workstepId', 'dateRangeId', 'summaryType', 'summaryValue', 'assetSelectionId']);
    contentInput.selector = content.useSizeFromRender ? SCREENSHOT_SIZE_TO_CONTENT.SELECTOR : undefined;
    contentInput.reportId = sqReportStore.id;
    contentInput.archived = content.isArchived;
    contentInput.react = content.isReact;
    _.assign(contentInput, parseSummaryToTypeAndValue(content.summary));
    return contentInput;
  }

  function formatAssetSelectionFromApiOutput(assetSelectionOutput: AssetSelectionOutputV1): AssetSelection {
    if (assetSelectionOutput.asset.isRedacted){
      assetSelectionOutput.asset.name = $translate.instant("ACCESS_CONTROL.REDACTED");
      assetSelectionOutput.asset.id = `redacted_${assetSelectionOutput.id}`;
    }
    return {
      asset: assetSelectionOutput.asset,
      name: assetSelectionOutput.name,
      selectionId: assetSelectionOutput.id,
      isArchived: assetSelectionOutput.archived,
      reportId: assetSelectionOutput.report.id,
      assetPathDepth: _.isNil(assetSelectionOutput.assetPathDepth) ? null: _.toNumber(assetSelectionOutput.assetPathDepth)
    };
  }

  function formatAssetSelectionToApiInput(assetSelection: AssetSelection): AssetSelectionInputV1 {
    return {
      reportId: assetSelection.selectionId ? assetSelection.reportId : sqReportStore.id,
      name: assetSelection.name,
      assetId: assetSelection.asset.id,
      selectionId: assetSelection.selectionId ? assetSelection.selectionId : null,
      archived: assetSelection.isArchived,
      assetPathDepth: assetSelection.assetPathDepth
    };
  }

  /**
   * Extract date range parameters from API response
   *
   * @param dateRangeOutput - Output from /content endpoint call
   * @returns storeDateRange - Object in format for store
   */
  function formatDateRangeFromApiOutput(dateRangeOutput: DateRangeOutputV1): DateRange {
    let formulaFormValid = false;
    const dateRange: any = {};
    _.defaultsDeep(dateRange, DEFAULT_DATE_RANGE);
    _.assign(dateRange, _.pick(dateRangeOutput, ['name', 'id', 'description']));
    _.assign(dateRange.condition, _.pick(dateRangeOutput.condition, ['name', 'id', 'isRedacted']));
    _.assign(dateRange.auto, { enabled: dateRangeOutput.formula.includes('$now') });
    dateRange.enabled = dateRangeOutput.enabled;
    dateRange.reportId = dateRangeOutput.report?.id;
    dateRange.isArchived = dateRangeOutput.archived;

    // The backend gives us back ISO8601 timestamps, but expects milliseconds back.
    if (dateRangeOutput.dateRange?.start) {
      dateRange.range.start = moment.utc(dateRangeOutput.dateRange.start).valueOf();
    }

    if (dateRangeOutput.dateRange?.end) {
      dateRange.range.end = moment.utc(dateRangeOutput.dateRange.end).valueOf();
    }

    try {
      // See .createDateRangeFormula() for expected formula formats

      // Fixed, no condition
      if (!dateRangeOutput.condition?.id && dateRangeOutput.formula.match(/^capsule\(.*\)$/)) {
        // Nothing additional needs to be extracted from the formula
        formulaFormValid = true;
      }

      const setConditionProperties = (searchStart?, searchEnd?, maxDuration?, columns?, sortBy?, sortAsc?) => {
        dateRange.condition.range = {
          start: sqNumberHelper.toNumber(searchStart),
          end: sqNumberHelper.toNumber(searchEnd)
        };

        if (columns) {
          dateRange.condition.columns = columns.split(',');
          dateRange.condition.sortBy = sortBy;
          dateRange.condition.sortAsc = sortAsc === 'true';
          dateRange.condition.isCapsuleFromTable = true;
        } else {
          dateRange.condition.isCapsuleFromTable = false;
        }

        if (maxDuration) {
          const maximumDuration = sqDateTime.splitDuration(maxDuration);
          if (!maximumDuration) {
            throw new Error(`Could not parse ${maxDuration} as a maximum duration`);
          }

          if (!_.find(DURATION_TIME_UNITS, time => time.unit.includes(maximumDuration.units))) {
            throw new Error(
              `Invalid maximum duration unit ${maximumDuration.units} in ${maxDuration} as a maximum duration`);
          }

          dateRange.condition.maximumDuration = maximumDuration;
        }
      };

      // auto enabled
      const setOffsetProperties = (offset) => {
        const pick = sqNumberHelper.toNumber(offset);
        if (pick === 1) {
          dateRange.condition.strategy = CAPSULE_SELECTION.STRATEGY.CLOSEST_TO;
          dateRange.condition.reference = CAPSULE_SELECTION.REFERENCE.START;
          dateRange.condition.offset = 1;
        } else if (pick > 1) {
          dateRange.condition.strategy = CAPSULE_SELECTION.STRATEGY.OFFSET_BY;
          dateRange.condition.reference = CAPSULE_SELECTION.REFERENCE.START;
          dateRange.condition.offset = pick - 1;
        } else if (pick === -1) {
          dateRange.condition.strategy = CAPSULE_SELECTION.STRATEGY.CLOSEST_TO;
          dateRange.condition.reference = CAPSULE_SELECTION.REFERENCE.END;
          dateRange.condition.offset = 1;
        } else if (pick < -1) {
          dateRange.condition.strategy = CAPSULE_SELECTION.STRATEGY.OFFSET_BY;
          dateRange.condition.reference = CAPSULE_SELECTION.REFERENCE.END;
          dateRange.condition.offset = Math.abs(pick) - 1;
        }
      };

      if (!dateRange.auto.enabled && !_.includes(dateRangeOutput.formula, 'Offset')) {
        // Fixed, with condition capsule selected from table
        const fixedMatchPattern =
          /^\s*\/\/ searchStart=(.*?)ms\n\s*\/\/ searchEnd=(.*?)ms\n\s*\/\/ columns=(.*?)\n\s*\/\/ sortBy=(.*?)\n\s*\/\/ sortAsc=(.*?)\n\s* capsule\(.+\)$/m;
        const fixedMatches = dateRangeOutput.formula.match(fixedMatchPattern);
        if (dateRangeOutput.condition?.id && fixedMatches) {
          const [notUsed, parsedStart, parsedEnd, columns, sortBy, sortAsc] = fixedMatches;
          setConditionProperties(parsedStart, parsedEnd, undefined, columns, sortBy, sortAsc);
          formulaFormValid = true;
        }
      } else if (!dateRange.auto.enabled && dateRangeOutput.formula.includes('Offset')) {
        // Fixed, with selected relative capsule
        const fixedConfigMatchPattern =
          /^\s*\/\/ searchStart=(.*?)ms\n\s*\/\/ searchEnd=(.*?)ms\n\s*\/\/ capsuleOffset=(.*?)\n\s*\/\/ maxDuration=(.*?)\n\s*capsule\(.+\)$/m;
        const fixedMatches = dateRangeOutput.formula.match(fixedConfigMatchPattern);
        if (dateRangeOutput.condition?.id && fixedMatches) {
          const [notUsed, parsedStart, parsedEnd, capsuleOffset, parsedMaxDuration] = fixedMatches;
          setConditionProperties(parsedStart, parsedEnd, parsedMaxDuration);
          setOffsetProperties(capsuleOffset);
          formulaFormValid = true;
        }
      }

      // Auto-update with condition
      const autoConditionMatchPattern = /^\$condition(.removeLongerThan\(.+\))?.setCertain\(\).toGroup\(capsule\(.+\)\).pick\((-?[1-9]+[0-9]*)\)$/;
      const autoMatches = dateRangeOutput.formula.match(autoConditionMatchPattern);
      if (dateRangeOutput.condition?.id && autoMatches) {
        // Closest to Start =>  $condition.setCertain().toGroup(capsule(start, end)).pick(1)
        // Closest to End => $condition.setCertain().toGroup(capsule(start, end)).pick(-1)
        // Offset by 1 from Start => $condition.setCertain().toGroup(capsule(start, end)).pick(2)
        // Offset by 2 from Start => $condition.setCertain().toGroup(capsule(start, end)).pick(3)
        // Offset by 1 from End => $condition.setCertain().toGroup(capsule(start, end)).pick(-2)
        const parsedPick = dateRangeOutput.formula.match(/.*\.pick\((-?[1-9]+[0-9]*)\)$/)[1];
        const parsedMaximumDuration = dateRangeOutput.formula.match(/removeLongerThan\((.+)\)/)?.[1];
        setConditionProperties(undefined, undefined, parsedMaximumDuration);
        setOffsetProperties(parsedPick);
        formulaFormValid = true;
      }

      // Auto-update, condition and non-condition
      if (dateRange.auto.enabled) {
        const background = dateRangeOutput.background;
        const cronSchedule = dateRangeOutput.cronSchedule;
        const duration = extractDurationFromFormula(dateRangeOutput.formula);
        const offsetAndDirection = extractOffsetAndDirectionFromFormula(dateRangeOutput.formula);
        const offset = offsetAndDirection[0];
        const offsetDirection = offsetAndDirection[1];

        dateRange.auto = {
          enabled: true,
          duration,
          offset,
          offsetDirection,
          background,
          cronSchedule
        };
      }
    } catch (e) {
      sqLogger.warn(e);
      formulaFormValid = false;
    }

    if (!formulaFormValid) {
      sqLogger.warn(
        `Failed to parse date range formula "${dateRangeOutput.formula}" [${dateRangeOutput.id}]`);
      dateRange.irregularFormula = dateRangeOutput.formula;
    }
    return dateRange;
  }

  /**
   * Construct a DateRangeInputV1 object from the frontend dateRange
   *
   * @param {DateRange} dateRange
   * @returns {DateRangeInputV1}
   */
  function formatDateRangeToApiInput(dateRange) {
    const dateRangeInput: DateRangeInputV1 = _.pick(dateRange, ['name', 'description', 'formula', 'updatePeriod']);
    dateRangeInput.background = false;
    // Only add sqReportStore.reportId if this is a new date range (CRAB-24551)
    dateRangeInput.reportId = dateRange.id ? dateRange.reportId : sqReportStore.id;
    dateRangeInput.formula = service.createDateRangeFormula(dateRange);
    dateRangeInput.conditionId = dateRange.condition?.id;
    dateRangeInput.archived = dateRange.isArchived;

    if (dateRange.auto.enabled) {
      dateRangeInput.background = dateRange.auto.background;
      dateRangeInput.cronSchedule = dateRange.auto.cronSchedule;
    }

    return dateRangeInput;
  }

  /**
   * Determines if the topic document can be modified based on the current user, document, and view mode.
   *
   * @returns {boolean} true if it can be modified, false otherwise
   */
  function canModifyDocument() {
    return sqUtilities.workbookLoaded() && sqAuthorization.canModifyWorkbook(sqWorkbookStore) &&
      !sqUtilities.isPresentationWorkbookMode && !sqUtilities.isViewOnlyWorkbookMode;
  }

  /**
   * Hides content and displays an error. Shows the user a notification when possible.
   *
   * @param {Object} error - the error object
   */
  function displayError(error) {
    const { statusMessage } = error;
    if (statusMessage) {
      sqNotifications.error(statusMessage);
    } else {
      sqLogger.warn(sqLogger.format`Error generating content ${error}`);
    }
  }

  /**
   * Compares two lists of nodes for equality
   *
   * @param {Element[]} nodes1 - first set of nodes
   * @param {Element[]} nodes2 - second set of nodes
   */
  function areNodesEqual(nodes1: Element[], nodes2: Element[]): boolean {
    if (nodes1.length !== nodes2.length) {
      return false;
    }

    let isEqual = true;
    for (let i = 0; i < nodes1.length && isEqual; ++i) {
      isEqual = nodes1[i].isEqualNode(nodes2[i]);
    }
    return isEqual;
  }

  /**
   * Compares two documents for equality
   *
   * @param {string} document1 - first document
   * @param {string} document2 - second document
   */
  function areDocumentsEqual(document1: string, document2: string): boolean {
    if (document1 === document2) return true;
    const strippedPrevDocAsNodes = parseHtmlToNodesForComparison(document1) || [];
    const strippedNewDocAsNodes = parseHtmlToNodesForComparison(document2) || [];

    return areNodesEqual(strippedPrevDocAsNodes, strippedNewDocAsNodes);
  }

  /**
   * Parses HTML to nodes
   *
   * @param {string} document - html document
   * @returns {Element[]} array of nodes created from the provided HTML string
   */
  function parseHtmlToNodes(document: string): Element[] {
    if (!document) return [];
    // Ensure images don't load while parsing: https://stackoverflow.com/a/50194774/1108708
    const ownerDocument = ($document.get(0) as any).implementation.createHTMLDocument('virtual');
    return jQuery
      .parseHTML(jQuery.trim(document), ownerDocument)
      .filter(node => node instanceof Element)
      .map(node => node as Element);
  }

  /**
   * Strips the document of front end related classes that aren't applicable for API users. Also protects against
   * an incomplete document caused by transitional states on the front end
   *
   * @param {string} document - html document
   * @returns {string} updated document
   */
  function getStrippedAndValidatedDocument(document: string): string {
    // Note: The order of filtering matters since each step has side effects
    const docAsNodes = parseHtmlToNodes(document) || [];
    const filter = filterHelpers();
    filter.applyTransform(docAsNodes, `img[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`, (node) => {
      replaceSeeqImageSourceWithBaseUrl(node);
    });
    filterOutCommonAttributesForCompareOrSave(docAsNodes);

    return docAsNodes.map(ele => ele.outerHTML).join('');

    function replaceSeeqImageSourceWithBaseUrl(node: Element) {
      const obj = $(node);
      const contentId = obj.attr(SeeqNames.TopicDocumentAttributes.DataSeeqContent);
      const contentImageUrlNoFragment = sqReportStore.getContentImageUrl(contentId).replace(/\?.*/, '');
      obj.attr('src', contentImageUrlNoFragment);
    }
  }

  /**
   * Parses HTML to nodes while removing attributes that are irrelevant for comparing documents
   *
   * @param {string} document - html document
   * @return {Element[]} array of Elements
   */
  function parseHtmlToNodesForComparison(document: string): Element[] {
    const filteredDocAsNodes = filterOutCommonAttributesForCompareOrSave(document);
    const filter = filterHelpers();
    filter.applyTransform(filteredDocAsNodes, `img[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`, (node) => {
      filterOutSrc(node);
    });

    return filteredDocAsNodes;

    function filterOutSrc(node: Element): void {
      jQuery(node).removeAttr('src');
    }
  }

  /**
   * Parses HTML to nodes while removing attributes that are irrelevant for comparing or saving documents
   *
   * @param {string} document - html document
   * @return {Element[]} array of Elements
   */
  function filterOutCommonAttributesForCompareOrSave(document: string | Element[]): Element[] {
    const docAsNodes = typeof document === 'string'
      ? parseHtmlToNodes(document) || []
      : document || [];
    const filter = filterHelpers();
    filter.applyTransform(docAsNodes, 'img', (node) => {
      filter.filterOutImageLoadingClasses(node);
      filter.filterPropertiesFromStyleAttribute(node, ['minWidth', 'maxWidth', 'minHeight', 'maxHeight']);
      filter.filterOutFroalaClasses(node);
      filter.orderPropertiesFromStyleAttributeAlphabetically(node);
    });
    filter.applyTransform(docAsNodes, 'a', (node) => {
      filter.filterOutAttribute(node, ['rel']);
    });
    return docAsNodes;
  }

  /**
   * Iterates over the DOM and calls content/id/sourceUrl for each piece of content within the DOM and replaces the
   * current href with the full url returned from the API call.
   *
   * @returns a promise that resolves when all content URLs are replaced with the full URL
   */
  function setAllContentUrlsToFullUrls() {
    const ID_FROM_SOURCE_URL_REGEX = /\/api\/content\/(.+?)\/sourceUrl$/;
    const $links = _.map(getAllContent().parent().closest('a').toArray());

    if ($links.length === 0) {
      return $q.resolve();
    }

    return sqContentApi.getContentsWithAllMetadata({ reportId: sqReportStore.id })
      .then((response) => {
        const idToContent = _.keyBy(response?.data?.contentItems, 'id');
        $links.forEach((element) => {
          const contentUrl = element.getAttribute('href');
          const id = contentUrl?.match(ID_FROM_SOURCE_URL_REGEX)?.[1];
          const sourceUrl = idToContent?.[id]?.sourceUrl;
          if (!_.isNil(sourceUrl)) {
            element.setAttribute('href', sourceUrl);
          } else {
            sqLogger.warn(`Could not match sourceUrl for element [href=${contentUrl}] with parsed id: ${id}`);
          }
        });
      });
  }

  /**
   * A utility function returning a collection of filter functions that can run on an array of Elements
   */
  function filterHelpers() {
    return {
      filterOutClasses,
      filterOutAttribute,
      filterPropertiesFromStyleAttribute,
      orderPropertiesFromStyleAttributeAlphabetically,
      filterOutFroalaClasses,
      filterOutImageLoadingClasses,
      applyTransform
    };

    function filterOutClasses(node: Element, remove: string[]): void {
      jQuery(node).removeClass(remove.join(' '));
      if (node.classList.length === 0) {
        node.removeAttribute('class');
      }
    }

    function filterOutAttribute(node: Element, remove: string[]): void {
      remove.forEach(attribute => node.removeAttribute(attribute));
    }

    function filterPropertiesFromStyleAttribute(node: Element, jsCssPropertiesToRemove: string[]): void {
      if (node instanceof HTMLElement) {
        jsCssPropertiesToRemove.forEach(property => node.style[property] = null);
        // If we've filtered out every property in the style attribute, remove it altogether
        if (!node.style.cssText) filterOutAttribute(node, ['style']);
      }
    }

    function orderPropertiesFromStyleAttributeAlphabetically(node: Element): void {
      if (node instanceof HTMLElement) {
        const sortedProperties = node.style.cssText.split(/;\s*/).filter(property => !!property).sort();
        if (sortedProperties.length === 0) return;
        node.style.cssText = sortedProperties.join('; ') + ';';
      }
    }

    function filterOutFroalaClasses(node: Element): void {
      const toRemove = _.chain(node.classList).filter(clz => clz.startsWith('fr-')).value();
      filterOutClasses(node, toRemove);
    }

    function filterOutImageLoadingClasses(node: Element): void {
      filterOutClasses(node, [CONTENT_LOADING_CLASS.LOADED, CONTENT_LOADING_CLASS.ERROR,
        CONTENT_LOADING_CLASS.NO_CAPSULE_ERROR, CONTENT_LOADING_CLASS.SPINNER]);
    }

    function applyTransform(nodes: Element[], selector: string, transform: (node: Element) => void): void {
      nodes.forEach((node) => {
        if (jQuery(node).is(selector)) {
          transform(node);
        }
        const children = jQuery(node).find(selector);
        for (let i = 0; i < children.length; ++i) {
          transform(children[i]);
        }
      });
    }
  }

  /**
   * Various utility functions for working with cron expressions
   */
  function quartzCronExpressionHelper() {
    enum CRON_DAYS_OF_WEEK {SUN = 1, MON, TUE, WED, THU, FRI, SAT}

    enum CRON_MONTHS {JAN = 1, FEB, MAR, APR, MAY, JUN, JUL, AUG, SEP, OCT, NOV, DEC}

    const EVERY_DAY: CRON_DAYS_OF_WEEK[] = [CRON_DAYS_OF_WEEK.SUN, CRON_DAYS_OF_WEEK.MON, CRON_DAYS_OF_WEEK.TUE,
      CRON_DAYS_OF_WEEK.WED, CRON_DAYS_OF_WEEK.THU, CRON_DAYS_OF_WEEK.FRI, CRON_DAYS_OF_WEEK.SAT];
    const EVERY_WEEKDAY: CRON_DAYS_OF_WEEK[] = [CRON_DAYS_OF_WEEK.MON, CRON_DAYS_OF_WEEK.TUE, CRON_DAYS_OF_WEEK.WED,
      CRON_DAYS_OF_WEEK.THU, CRON_DAYS_OF_WEEK.FRI];

    interface CronData {
      seconds: string[];
      minutes: string[];
      hours: string[];
      daysOfMonth: string[];
      months: string[];
      daysOfWeek: string[];
      years?: string[];
    }

    return {
      CRON_DAYS_OF_WEEK,
      CRON_MONTHS,
      EVERY_DAY,
      EVERY_WEEKDAY,
      parse,
      createDailySchedule,
      createWeekdaySchedule,
      createWeeklySchedule,
      createMonthlyScheduleByDayOfMonth,
      createMonthlyScheduleByDayOfWeek,
      rateToCronSchedule,
      cronScheduleToRate
    };

    /**
     * Parses a cron expression into an object. It has support for various special characters and will expand the
     * expression into a 'long' form (e.g. parse('1,3,5-8') returns [1,3,5,6,7,8])
     * NOTE: No validation is done, so a valid expression is expected
     */
    function parse(expression: string): CronData {
      const [parseSeconds, parseMin, parseHour, parseDayOfMonth, parseMonth, parseDayOfWeek, parseYear] = expression.trim().split(
        ' ');
      const seconds = expand(parseSeconds);
      const minutes = expand(parseMin);
      const hours = expand(parseHour);
      const daysOfMonth = expand(parseDayOfMonth);
      const months = expand(parseMonth);
      const daysOfWeek = expand(parseDayOfWeek);
      const years = !_.isNil(parseYear) ? expand(parseYear) : undefined;

      return {
        seconds,
        minutes,
        hours,
        daysOfMonth,
        months,
        daysOfWeek,
        years
      };

      function expand(expression: string): string[] {
        const SPECIAL_CHARACTER_FUNCTION_MAP = {
          ',': (expression: string): string[] => expression.split(','),
          '-': (expression: string): string[] => {
            const [start, end] = expression.split('-').map(ele => _.toInteger(ele.trim()));
            const range = [];
            for (let i = start; i <= end; ++i) {
              range.push(i);
            }
            return range.map(ele => ele.toString());
          }
        };

        const specialCharacters = Object.keys(SPECIAL_CHARACTER_FUNCTION_MAP);
        let additionalExpressions = [];
        specialCharacters.forEach((c) => {
          if (expression.includes(c) && _.isEmpty(additionalExpressions)) {
            const more: string[] = SPECIAL_CHARACTER_FUNCTION_MAP[c](expression);
            additionalExpressions = more.reduce((prev, cur) => [...prev, ...expand(cur)], []);
          }
        });
        return _.isEmpty(additionalExpressions) ? [expression] : additionalExpressions;
      }
    }

    function createDailySchedule(times: string[]): string {
      return createWeeklySchedule(EVERY_DAY, times);
    }

    function createWeekdaySchedule(times: string[]): string {
      return createWeeklySchedule(EVERY_WEEKDAY, times);
    }

    function createWeeklySchedule(daysOfWeek: CRON_DAYS_OF_WEEK[], times: string[]): string {
      const { minutes, hours } = timeToCronData(times);
      const data: CronData = {
        seconds: ['0'],
        minutes,
        hours,
        daysOfMonth: ['?'],
        months: ['*'],
        daysOfWeek: daysOfWeek.map(ele => ele.toString())
      };

      return build(data);
    }

    function createMonthlyScheduleByDayOfMonth(dayOfMonth: number, everyNMonth: number, times: string[]): string {
      const { minutes, hours } = timeToCronData(times);
      const months = (everyNMonth === 1) ? ['*'] : [`1/${everyNMonth}`];
      const data: CronData = {
        seconds: ['0'],
        minutes,
        hours,
        daysOfMonth: [dayOfMonth.toString()],
        months,
        daysOfWeek: ['?']
      };

      return build(data);
    }

    function createMonthlyScheduleByDayOfWeek(nth: number, dayOfWeek: CRON_DAYS_OF_WEEK, everyNMonth: number,
      times: string[]): string {
      const { minutes, hours } = timeToCronData(times);
      const months = (everyNMonth === 1) ? ['*'] : [`1/${everyNMonth}`];
      const data: CronData = {
        seconds: ['0'],
        minutes,
        hours,
        daysOfMonth: ['?'],
        months,
        daysOfWeek: [dayOfWeek.toString() + '#' + nth]
      };

      return build(data);
    }

    function timeToCronData(times: string[]) {
      // Convert to number then back to string to remove padded 0's
      const timeToHoursAndMinutes = times.map(time => time.split(':').map(ele => _.toInteger(ele)));
      const hours = [...new Set<string>(timeToHoursAndMinutes.map(([hours, minutes]) => hours.toString()))];
      const minutes = [...new Set<string>(timeToHoursAndMinutes.map(([hours, minutes]) => minutes.toString()))];

      return {
        hours,
        minutes
      };
    }

    function build(data: CronData): string {
      const {
        seconds = ['0'],
        minutes = ['*'],
        hours = ['*'],
        daysOfMonth = ['?'],
        months = ['*'],
        daysOfWeek = ['?'],
        years = undefined
      } = data;

      const cron = seconds.join(',') + ' ' +
        minutes.join(',') + ' ' +
        hours.join(',') + ' ' +
        daysOfMonth.join(',') + ' ' +
        months.join(',') + ' ' +
        daysOfWeek.join(',');

      return !_.isNil(years)
        ? cron + ' ' + years.join(',')
        : cron;
    }

    /**
     * Creates a cron expression from a rate object
     *
     * @param {{value: Number, units: string}} rate
     * @returns The cron expression to pass along to the backend
     */
    function rateToCronSchedule(rate) {
      let value = rate.value;
      let units = rate.units;

      // We don't need to promote units (because the UI constrains unit choices to things the backend can support),
      // except for 'weeks'.
      if (units === 'week') {
        if (value * 7 > 31) {
          ({ value, units } = sqDateTime.updateUnits(value, 'month', units));
          value = Math.round(value);
        } else {
          value *= 7;
          units = 'day';
        }
      }

      switch (units) {
        case 's':
          return `*/${value} * * * * ?`;
        case 'min':
          return `0 */${value} * * * ?`;
        case 'h':
          return `0 0 */${value} * * ?`;
        case 'day':
          return `0 0 0 */${value} * ?`;
        case 'week':
          return `0 0 0 */${value} * ?`;
        case 'month':
          return `0 0 0 1 */${value} ?`;
        default:
          throw new Error(`Unknown unit ${units}`);
      }
    }

    /**
     * Creates a rate object from a cron schedule, or undefined if the cron schedule does not correspond to an expected
     *  number of regular units.
     *
     * @param schedule
     * @returns {DateRangeAutoRate} || undefined
     */
    function cronScheduleToRate(schedule): DateRangeAutoRate | undefined {
      for (const [units, regex] of _.toPairs(SCHEDULE_REGEXES)) {
        const matches = regex.exec(schedule);
        if (!_.isNil(matches)) {
          const value = _.toInteger(matches[1]);
          return { value, units };
        }
      }
    }
  }

  /**
   * Parses the given summary out into it's backend representation
   *
   * @param summary - The summary being parsed
   * @return - an object containing the type and value of the given summary
   */
  function parseSummaryToTypeAndValue(summary: ReportContentSummary): {
    summaryType: SummaryTypeEnum | undefined, summaryValue: String | undefined
  } {
    const undefinedSummary = _.constant({ summaryType: undefined, summaryValue: undefined });
    return _.cond([
      [summary => _.isUndefined(summary), undefinedSummary],
      [(summary: ReportContentSummary) => summary.key === REPORT_CONTENT.SUMMARY.NONE.key,
        _.constant({ summaryType: SummaryTypeEnum.NONE, summaryValue: '0' })],
      [(summary: ReportContentSummary) => summary.key === REPORT_CONTENT.SUMMARY.DISCRETE.key,
        ({ value }) => ({
          summaryType: SummaryTypeEnum.DISCRETE,
          summaryValue: `${(<FrontendDuration>value).value}${(<FrontendDuration>value).units}`
        })],
      [(summary: ReportContentSummary) => summary.key === REPORT_CONTENT.SUMMARY.AUTO.key,
        ({ value }) => ({
          summaryType: SummaryTypeEnum.AUTO,
          summaryValue: value
        })],
      [_.stubTrue, undefinedSummary]
    ])(summary);
  }

  /**
   * Tells appserver the current report is being looked at
   */
  function postReportViewed() {
    sqItemsApi.setProperty(
      { value: moment.utc().valueOf() * NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND, unitOfMeasure: 'ns' },
      { id: sqReportStore.id, propertyName: SeeqNames.Properties.LastViewedAt },
      { ignoreLoadingBar: true })
      // If we can't post it's likely due to network problems, so just noop.
      .catch(() => _.noop());
  }

  /**
   * Returns all of the content Ids currently in the user's highlight/selection
   *
   * @return the list of selected content ids
   */
  function getContentIdsInSelection(): string[] {
    const selection = window.getSelection();
    if (selection.rangeCount === 0) {
      return [];
    }

    return _.chain(selection.getRangeAt(0).cloneContents().querySelectorAll(
      `[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`))
      .map(content => content.getAttribute(SeeqNames.TopicDocumentAttributes.DataSeeqContent))
      .filter(content => !_.isEmpty(content))
      .value();
  }

  /**
   * Toggles an image border class around all content in the current selection. If content in the selection differ
   * in border status, all content will swap to the opposite of the first content in the selection.
   */
  function toggleContentBorders() {
    const selectedContent = _.map(service.getContentIdsInSelection(),
      id => jQuery(`[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}=${id}]`));

    if (selectedContent.length > 0) {
      const op = selectedContent[0].hasClass(IMAGE_BORDER_CLASS) ?
        content => content.removeClass(IMAGE_BORDER_CLASS) :
        content => content.addClass(IMAGE_BORDER_CLASS);

      _.forEach(selectedContent, op);
      sqReportEditor.triggerDocumentChangedEvent();
    }
  }

  /**
   * Returns all of the content Ids in document order
   *
   * @return the list of content ids
   */
  function getContentIdsInDocumentOrder() {
    return _.map(getAllContent(),
      content => content.getAttribute(SeeqNames.TopicDocumentAttributes.DataSeeqContent));
  }

}
