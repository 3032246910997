import _ from 'lodash';
import angular from 'angular';
import {
  ALL_CUSTOMIZATIONS_PROPERTIES,
  CHILD_CLONED_PROPERTIES,
  ITEM_CUSTOMIZATIONS,
  ITEM_DATA_STATUS,
  ITEM_TYPES, PREVIEW_ID,
  TREND_COLORS, TREND_NO_COLOR
} from '@/trendData/trendData.module';
import { ITEM_ICONS } from '@/main/app.constants';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';

/**
 * A base store that adds standard functionality for all stores that represent an item that can show up on the
 * worksheet.
 */
angular.module('Sq.TrendData').service('sqBaseItemStore', sqBaseItemStore);

export type BaseItemStoreService = ReturnType<typeof sqBaseItemStore>;

function sqBaseItemStore() {
  const iconClasses = {};
  const translateKeys = {};
  const types = _.values(ITEM_TYPES);
  const colors = {
    useCount: _.zipObject(TREND_COLORS, _.map(TREND_COLORS, _.constant(0))),
    lastRemoved: null
  };

  iconClasses[ITEM_TYPES.SERIES] = ITEM_ICONS.SERIES;
  iconClasses[ITEM_TYPES.CAPSULE] = ITEM_ICONS.CAPSULE;
  iconClasses[ITEM_TYPES.ANNOTATION] = ITEM_ICONS.ANNOTATION;
  iconClasses[ITEM_TYPES.CAPSULE_SET] = ITEM_ICONS.CAPSULE_SET;
  iconClasses[ITEM_TYPES.SCALAR] = ITEM_ICONS.SCALAR;
  iconClasses[ITEM_TYPES.TABLE] = ITEM_ICONS.TABLE;
  iconClasses[ITEM_TYPES.METRIC] = ITEM_ICONS.METRIC;

  translateKeys[ITEM_TYPES.SERIES] = 'SERIES';
  translateKeys[ITEM_TYPES.CAPSULE] = 'CAPSULE';
  translateKeys[ITEM_TYPES.CAPSULE_SET] = 'CAPSULE_SERIES';
  translateKeys[ITEM_TYPES.ANNOTATION] = 'ANNOTATION';
  translateKeys[ITEM_TYPES.SCALAR] = 'SCALAR';
  translateKeys[ITEM_TYPES.TABLE] = 'TABLE';
  translateKeys[ITEM_TYPES.METRIC] = 'METRIC';

  const service = {
    COMMON_PROPS: {
      items: [],
      dataStatus: ITEM_DATA_STATUS.PRESENT,
      warningCount: 0,
      warningLogs: [],
      timingInformation: '',
      meterInformation: ''
    },
    extend,
    shouldDehydrateItem,
    pruneDehydratedItemProps,

    /**
     * Properties from createItem() that should always be dehydrated.
     */
    PROPS_TO_DEHYDRATE: ALL_CUSTOMIZATIONS_PROPERTIES
      .concat(['childType', 'id', 'name', 'selected', 'autoDisabled', 'color'])
  };

  return service;

  /**
   * Extends a store with helper methods, exports, and handlers shared by all item stores.
   *
   * NOTE: All handlers provided by this store must ensure that they can be called without side-effects even if the
   * item does not exist. This allows all the augmented stores to be listening for the same actions, such as
   * TREND_REMOVE_ITEMS, but only the store(s) that contain the item will modify state. This works
   * because we can be sure that all items have GUIDs and so every store's item ids will be unique.
   *
   * @param {Object} store The store being extended.
   * @returns {Object} The original store, augmented with shared functionality.
   */
  function extend<T>(store: ng.IFluxStoreDeclaration<T>) {
    const originalInitialize = store.initialize;
    const helpers = {
      persistenceLevel: PERSISTENCE_LEVEL.WORKSHEET,

      /**
       * Find an item by ID
       *
       * @param {String} id The ID of the item to find
       * @returns {Object} The item; or undefined if the item could not be found
       */
      findItem(id) {
        return this.findItemByProp('id', id);
      },

      /**
       * Find an item by the value of a property on the item
       *
       * @param {String} prop The property name
       * @param {String} val The property value
       * @returns {Object} The item; or undefined if the item could not be found
       */
      findItemByProp(prop, val) {
        let item;
        const filter = {};
        filter[prop] = val;
        item = this.state.get('items', filter);

        if (_.isUndefined(item) && !_.isEmpty(this.state.get('previewChartItem')) &&
          this.state.get('previewChartItem')[prop] === val) {
          item = this.state.get('previewChartItem');
        }

        return item;
      },

      /**
       * Get an item's cursor by ID. NOTE: the dynamic cursor method "select('items', { id: id })" is specifically
       * not used because it creates listeners that are extra overhead and can lead to memory leaks if not explicitly
       * released.
       *
       * @param {String} id - The ID of the item to find
       * @returns {Object} The cursor to the item
       */
      getItemCursor(id) {
        return this.isPreviewItem(id) ? this.state.select('previewChartItem') :
          this.state.select('items', this.findItemIndex(id));
      },

      findItemIndex(id) {
        return _.findIndex(this.state.get('items'), ['id', id]);
      },

      findChildren(id) {
        if (!id) {
          return [];
        }

        return _.filter(this.state.get('items'), function(item: any) {
          return item.isChildOf === id || (item.otherChildrenOf && _.includes(item.otherChildrenOf, id));
        });
      },

      /**
       * Determines if there is a preview series and id is preview or this is the object being updated
       *
       * @param {String} id - The ID of the item to test
       * @returns {boolean} - True if the item is the preview item or the object being updated
       */
      isPreviewItem(id) {
        return !_.isEmpty(this.state.get('previewChartItem')) &&
          (_.startsWith(id, PREVIEW_ID) || _.endsWith(this.state.get('previewChartItem').id, id));
      },

      removeColor(color) {
        removeColor(color);
      },

      /**
       * Creates an item object that can be stored in `state.items`. It adds properties shared by all items.
       *
       * @param {String} id - The guid
       * @param {String} name - The name
       * @param {String} itemType - The type of object. One of ITEM_TYPES
       * @param {Object} props - Any additional properties to add to the object
       * @returns {Object} An object with the shared properties
       */
      createItem(id, name, itemType, props) {
        if (_.includes(types, itemType)) {
          return _.merge({}, {
            id,
            name,
            itemType,
            iconClass: iconClasses[itemType],
            translateKey: translateKeys[itemType],
            selected: false,
            autoDisabled: false,
            isArchived: false,
            color: getItemColor(_.get(props, 'color')),
            lastFetchRequest: ''
          }, props);
        } else {
          throw new TypeError('Not a valid itemType.' + itemType);
        }
      },

      /**
       * Removes items.
       *
       * @param {Object} payload - Object container for arguments
       * @param {Object[]} payload.items - An array of items to remove
       */
      removeItems(payload) {
        let mutableItems;
        const items = _.chain(payload.items)
          .map('id')
          .map(id => this.findItem(id))
          .compact()
          .value();

        if (items.length) {
          // For performance reasons we acquire a writable items array, mutate it, and then set it back
          mutableItems = this.state.deepClone('items');
          _.forEach(items, function(item: any) {
            const index = _.findIndex(mutableItems, ['id', item.id]);
            if (index >= 0) {
              mutableItems.splice(index, 1);
              removeColor(item.color);
            }
          });

          this.state.set('items', mutableItems);
        }
      },

      /**
       * Sets property values of an existing item
       *
       * @param payload {Object} Object that has an id property for the item to be updated
       *   along with any other properties that should be updated.
       */
      setProperties(payload) {
        const cursor = this.getItemCursor(payload.id);
        if (cursor.exists()) {
          _.forEach(_.omit(payload, ['id']), (value, key) => {
            if (_.isNil(value)) {
              cursor.unset(key);
            } else {
              cursor.set(key, value);
            }
          });
        }
      },

      /**
       * Sets customizations as property values of an existing item
       *
       * @param itemsPayload.items {Object[]} Objects that have an id property for the item to be updated
       *   along with any other properties that should be updated.
       */
      setCustomizations(itemsPayload) {
        _.forEach(itemsPayload.items, (payload) => {
          const item = this.findItem(payload.id);
          if (item) {
            const customizations = _.pick(payload, ITEM_CUSTOMIZATIONS[item.itemType]);
            this.onSetCustomizations(item, customizations);
          }

          // Handle propagating cloned customizations to children
          // NOTE: children and parents can exist in different stores; the parent may not exist in the child's store
          _.forEach(this.findChildren(payload.id), (item) => {
            const customizations = _.pick(payload, _.intersection(
              ITEM_CUSTOMIZATIONS[item.itemType] as string[],
              CHILD_CLONED_PROPERTIES[item.childType] as string[]
            ));

            if (!_.isEmpty(customizations)) {
              this.onSetCustomizations(item, customizations);
            }
          });
        });
      },

      /**
       * This function should be overridden if the store needs to change the behavior of TREND_SET_CUSTOMIZATIONS
       *
       * Called when the customizations for an item change, should set the customizations on the item as necessary
       *
       * @param {Object} item - serialized item object
       * @param {Object} customizations - customize properties being set
       * @param {string} [parentId] - what was the id of the parent that triggered the change
       */
      onSetCustomizations(item, customizations, parentId?) {
        this.getItemCursor(item.id).merge(customizations);
      },

      /**
       * Sets a property on an item if it finds it, but only if the property actually changed.
       *
       * @param {String} id The id of the item.
       * @param {String} property The property to set.
       * @param {*} value The value to set.
       */
      setProperty(id, property, value) {
        const cursor = this.getItemCursor(id);
        if (cursor.exists() && cursor.get(property) !== value) {
          cursor.set(property, value);
        }
      },

      /**
       * Sets the statistics for an item.
       *
       * @param {Object} payload - Object container for arguments
       * @param {Number} payload.id - Id of the item
       * @param {Object} payload.statistics - key: value for each stat
       */
      setStatistics(payload) {
        this.setProperty(payload.id, 'statistics', payload.statistics);
      },

      /**
       * Sets the progress for an item.
       * Only set the meterInformation and timingInformation if the payload provides them to avoid
       * unintended clearing on cancellation!
       *
       * @param {Object} payload - Object container for arguments
       * @param {Number} payload.id - Id of the item
       * @param {Object} payload.progress - the progress percentage for item requests
       * @param {String} [payload.timingInformation] - String providing information for timing display in the
       * requests details panel
       * @param {String} [payload.meterInformation] - String providing information for Data Read display in the
       * requests details panel
       */
      setProgress(payload) {
        this.setProperty(payload.id, 'progress', payload.progress);
        if (_.has(payload, 'timingInformation')) {
          this.setProperty(payload.id, 'timingInformation', payload.timingInformation);
        }
        if (_.has(payload, 'meterInformation')) {
          this.setProperty(payload.id, 'meterInformation', payload.meterInformation);
        }
      },

      /**
       * Set the color on an item.
       *
       * @param {Object} payload - Object container for arguments
       * @param {String} payload.id - The id of the item to change
       * @param {String} payload.color - The color
       */
      setColor(payload) {
        const item = this.findItem(payload.id);
        if (item) {
          removeColor(item.color);
          this.onSetColor(item, payload.color);
          updateColorUsage(payload.color);
        }

        // Handle propagating colors to children
        _.forEach(this.findChildren(payload.id), (item) => {
          if (_.includes(CHILD_CLONED_PROPERTIES[item.childType], 'color')) {
            this.onSetColor(item, payload.color, payload.id);
          }
        });
      },

      /**
       * This function should be overridden if the store needs to change the behavior of TREND_SET_COLOR
       *
       * Called when the color for an item change, should set the color on the item as necessary
       *
       * @param {Object} item - serialized item object
       * @param {Object} color - color that should be set
       * @param {string} [parentId] - what was the id of the parent that triggered the change
       */
      onSetColor(item, color, parentId?) {
        this.getItemCursor(item.id).set('color', color);
      },

      /**
       * Set the selected property on an item.
       *
       * @param {Object} payload - Object container for arguments
       * @param {Object} payload.item - The item to select
       * @param {boolean} payload.selected - Selection status
       */
      setSelected(payload) {
        const item = this.findItem(payload.item.id);
        if (item) {
          this.onSetSelected(item, payload.selected);
        }

        // Handle propagating selection to children
        _.forEach(this.findChildren(payload.item.id), (item) => {
          if (_.includes(CHILD_CLONED_PROPERTIES[item.childType], 'selected')) {
            this.onSetSelected(item, payload.selected, payload.item.id);
          }
        });
      },

      /**
       * This function should be overridden if the store needs to change the behavior of TREND_SET_SELECTED
       *
       * Called when the selection for an item change, should set the color on the item as necessary
       *
       * @param {Object} item - serialized item object
       * @param {boolean} selected - selection status
       * @param {string} [parentId] - what was the id of the parent that triggered the change
       */
      onSetSelected(item, selected, parentId?) {
        this.getItemCursor(item.id).set('selected', selected);
      },

      /**
       * Swaps out the specified items from one asset for the variants based off another asset.
       *
       * @param {Object} payload - Object container for arguments
       * @param {Object} payload.swaps - The items that were swapped where the keys are the swapped out ids and the
       *   values are the corresponding swapped in ids.
       * @param {Object} payload.outAsset - Asset that was swapped out
       * @param {String} payload.outAsset.id - The ID of the asset to swapped out
       * @param {String} payload.inAsset.name - The name of the asset that was swapped out
       * @param {Object} payload.inAsset - Asset that was swapped in
       * @param {String} payload.inAsset.id - The ID of the asset that was swapped in
       * @param {String} payload.inAsset.name - The name of the asset that was swapped in
       */
      swapItems(payload) {
        _.forEach(payload.swaps, (swappedInId, swappedOutId) => {
          const cursor = this.getItemCursor(swappedOutId);
          if (cursor.exists()) {
            cursor.set('id', swappedInId);
          }
        });
      },

      /**
       * Updates lastFetchRequest with the current time as an ISO string
       */
      updateLastFetchRequest(payload: { id: string }) {
        this.setProperty(payload.id, 'lastFetchRequest', new Date().toISOString());
      },

      setDataStatusPresent: _.partial(setDataStatusTo, ITEM_DATA_STATUS.PRESENT),
      setDataStatusLoading: _.partial(setDataStatusTo, ITEM_DATA_STATUS.LOADING),
      setDataStatusFailure: _.partial(setDataStatusTo, ITEM_DATA_STATUS.FAILURE),
      setDataStatusRedacted: _.partial(setDataStatusTo, ITEM_DATA_STATUS.REDACTED),
      setDataStatusCanceled: _.partial(setDataStatusTo, ITEM_DATA_STATUS.CANCELED),
      setDataStatusHiddenFromTrend: _.partial(setDataStatusTo, ITEM_DATA_STATUS.HIDDEN_FROM_TREND),
      setDataStatusNotRequired: _.partial(setDataStatusTo, ITEM_DATA_STATUS.NOT_REQUIRED),
      setDataStatusAborted: _.partial(setDataStatusTo, ITEM_DATA_STATUS.ABORTED)
    };

    const handlers = {
      TREND_REMOVE_ITEMS: 'removeItems',
      TREND_SET_CUSTOMIZATIONS: 'setCustomizations',
      TREND_SET_SELECTED: 'setSelected',
      TREND_SET_COLOR: 'setColor',
      TREND_SET_PROPERTIES: 'setProperties',
      TREND_SET_STATISTICS: 'setStatistics',
      TREND_SET_PROGRESS: 'setProgress',
      TREND_SET_DATA_STATUS_PRESENT: 'setDataStatusPresent',
      TREND_SET_DATA_STATUS_LOADING: 'setDataStatusLoading',
      TREND_SET_DATA_STATUS_FAILURE: 'setDataStatusFailure',
      TREND_SET_DATA_STATUS_REDACTED: 'setDataStatusRedacted',
      TREND_SET_DATA_STATUS_CANCELED: 'setDataStatusCanceled',
      TREND_SET_DATA_STATUS_HIDDEN_FROM_TREND: 'setDataStatusHiddenFromTrend',
      TREND_SET_DATA_STATUS_NOT_REQUIRED: 'setDataStatusNotRequired',
      TREND_SET_DATA_STATUS_ABORTED: 'setDataStatusAborted',
      TREND_SWAP_ITEMS: 'swapItems',
      TREND_UPDATE_LAST_FETCH_REQUEST: 'updateLastFetchRequest'
    };

    _.defaults(store, helpers);

    store.handlers = _.assign({}, handlers, store.handlers);

    store.exports = store.exports || {} as T;
    Object.defineProperty(store.exports, 'items', {
      configurable: true,
      enumerable: true,
      get() {
        return this.state.get('items');
      }
    });

    const addedExports = _.chain(helpers)
      .pick(['findItem', 'findItemIndex', 'findItemByProp', 'findChildren', 'isPreviewItem'])
      .assign({ removeColor, findNextColor })
      .value();

    type AddedExports = Readonly<typeof addedExports & {
      items: any
    }>;

    _.assign(store.exports, addedExports);

    const requiredProperties = {
      items: value => _.isArray(value),
      dataStatus: value => _.includes(ITEM_DATA_STATUS, value),
      warningCount: value => _.isNumber(value),
      warningLogs: value => _.isArray(value)
    };

    store.initialize = function(initializeMode) {
      if (this.state) {
        _.forEach(this.state.get('items'), item => removeColor(item.color));
        colors.lastRemoved = null;
      }

      if (_.isFunction(originalInitialize)) {
        originalInitialize.call(this, initializeMode);
        _.forEach(requiredProperties, (test, property) => {
          if (!test(this.state.get(property))) {
            throw new Error('Custom initialize must set the `' + property + '` property');
          }
        });
      } else {
        this.state = this.immutable(service.COMMON_PROPS);
      }
    };

    /**
     * Sets the status of the item specified by the payload id to the value specified by the status parameter and
     * sets the statusMessage.
     *
     * @param {String} status - one of ITEM_DATA_STATUS, intended to be partially applied
     * @param {Object} payload - object container for arguments
     * @param {String} payload.id - guid of item
     * @param {String} [payload.message] - an optional error message to set, if none is specified the statusMessage
     *                                     is set to ''
     * @param {Number} [payload.warningCount] - an optional count of warnings returned by the backend
     * @param {Object[]} [payload.warningLogs] - an optional array of warning details returned by the backend. Note
     *                                           that warningLogs.length does not always equal warningCount
     * @param {String} [payload.timingInformation] - String providing information for timing display in the
     * requests details panel
     * @param {String} [payload.meterInformation] - String providing information for Data Read display in the
     * requests details panel
     */
    function setDataStatusTo(status, payload) {
      const index = this.findItemIndex(payload.id);
      if (index > -1) {
        this.state.merge(['items', index], {
          dataStatus: status,
          statusMessage: _.escape(payload.message),
          warningCount: _.isNumber(payload.warningCount) ? payload.warningCount : 0,
          warningLogs: _.isArray(payload.warningLogs) ? payload.warningLogs : [],
          errorType: payload.errorType,
          errorCategory: payload.errorCategory,
          timingInformation: payload.timingInformation,
          meterInformation: payload.meterInformation
        });

        if (_.includes([
            ITEM_DATA_STATUS.FAILURE,
            ITEM_DATA_STATUS.NOT_REQUIRED,
            ITEM_DATA_STATUS.REDACTED,
            ITEM_DATA_STATUS.HIDDEN_FROM_TREND,
            ITEM_DATA_STATUS.ABORTED
          ], status)
          && _.has(this.state.get(['items', index]), 'data')) {
          this.state.set(['items', index, 'data'], []);
        }
      }
    }

    /**
     * Gets the next available color. We prefer reassigning the last used color because it prevents the color from
     * changing unexpectedly when the user removes and then re-adds an item such as a capsuleSeries. Otherwise we
     * find the next color that is the least-used.
     *
     * @returns {String} The next color
     */
    function findNextColor() {
      let leastUsedColor = TREND_NO_COLOR;
      let minUseCount = Number.MAX_VALUE;
      if (colors.lastRemoved && !colors.useCount[colors.lastRemoved]) {
        return colors.lastRemoved;
      }

      _.forOwn(colors.useCount, function(count, color) {
        if (count < minUseCount) {
          leastUsedColor = color;
          minUseCount = count;
        }
      });

      return leastUsedColor;
    }

    /**
     * Finds a color for an item using either the specified color or the last color that was removed or the least
     * used color if the last color that was removed has already been reassigned.
     *
     * @param {String} [color] - The existing color, if not provided then the next available is found.
     *
     * @returns {String} A color
     */
    function getItemColor(color) {
      color = color || findNextColor();
      colors.lastRemoved = null;
      updateColorUsage(color);
      return color;
    }

    /**
     * Updates the map of which colors are in use with the specified color. Custom colors are not updated since they
     * will have been specifically chosen by the user and should not be included by findNextColor().
     *
     * @param {String} color - The color.
     */
    function updateColorUsage(color) {
      if (_.includes(TREND_COLORS, color)) {
        colors.useCount[color] += 1;
      }
    }

    /**
     * Decrements the color usage count and sets the last removed color.
     *
     * @param {String} color - The color.
     */
    function removeColor(color) {
      if (_.includes(TREND_COLORS, color)) {
        const colorUseCount = colors.useCount[color];
        colors.lastRemoved = color;

        if (colorUseCount) {
          colors.useCount[color] = colorUseCount - 1;
        }
      }
    }

    return store as ng.IFluxStoreDeclaration<T & AddedExports>;
  }

  /**
   * Child stores should call this function to decide if individual items should be dehydrated.
   *
   * @param {Object} item - an item to test.
   * @returns {Boolean}
   */
  function shouldDehydrateItem(item) {
    return _.isNil(_.get(item, 'childType'));
  }

  /**
   *  Returns a dehydrated item with unneeded properties removed.
   *
   * @param {Object} item - the dehydrated item to prune.
   * @param {Array} extraPropsToDehydrate - props to dehydrate in addition to service.PROPS_TO_DEHYDRATE
   * @returns {Object} a dehydrated item without unneeded properties.
   */
  function pruneDehydratedItemProps(item, extraPropsToDehydrate = []) {
    const propsToDehydrate = _.concat(service.PROPS_TO_DEHYDRATE, extraPropsToDehydrate);
    if (item.axisAutoScale) {
      return _.pick(item, _.without(propsToDehydrate, 'yAxisConfig', 'yAxisMin', 'yAxisMax'));
    } else {
      return _.pick(item, propsToDehydrate);
    }
  }
}
