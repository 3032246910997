import _ from 'lodash';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { DigitalFilterStore } from '@/hybrid/tools/digitalFilter/digitalFilter.store';
import { CalculationRunnerService } from '@/services/calculationRunner.service';
import { NUMBER_CONVERSIONS } from '@/main/app.constants';
import {
  DIGITAL_FILTER_PERIOD_CUTOFF2_RATIO,
  DIGITAL_FILTER_PERIOD_CUTOFF_RATIO, DIGITAL_FILTER_PRECISION, DIGITAL_FILTER_TAPS_MIN,
  DIGITAL_FILTER_VALUES, DIGITAL_FILTER_WINDOW_TO_CUTOFF_RATIO
} from '@/hybrid/tools/digitalFilter/digitalFilter.module';
import { DateTimeService } from '@/datetime/dateTime.service';
import { DurationStore } from '@/trendData/duration.store';
import moment from 'moment-timezone';
import { InvestigateHelperService } from '@/investigate/investigateHelper.service';
import { FormulaService } from '@/services/formula.service';

export type DigitalFilterActions = ReturnType<typeof sqDigitalFilterActions>;

export function sqDigitalFilterActions(
  flux: ng.IFluxService,
  sqCalculationRunner: CalculationRunnerService,
  sqDurationStore: DurationStore,
  sqToolRunner: ToolRunnerService,
  sqDigitalFilterStore: DigitalFilterStore,
  sqDateTime: DateTimeService,
  sqInvestigateHelper: InvestigateHelperService,
  sqFormula: FormulaService
) {
  const service = {
    getParametersAndFormula,
    execute,
    setFilterType,
    setCutoff,
    setCutoff2,
    setSamplingRate,
    setWindowSize,
    setIsAutoSamplingRate,
    setIsAutoWindowSize,
    fetchSamplingRate,
    fetchAndSetDefaultValues,
    isBandFilter,
    determineMaxCutoffInPeriodUnits,
    setWindowSizeFromCutoff
  };

  return service;

  function getParametersAndFormula() {
    const parameters = { inputSignal: sqDigitalFilterStore.inputSignal.id };
    let formula = '$inputSignal';

    const format = valueWithUnits => `${valueWithUnits.value}${valueWithUnits.units}`;

    const cutoff = format(sqDigitalFilterStore.cutoff);
    const cutoff2 = format(sqDigitalFilterStore.cutoff2);
    const samplingRate = format(sqDigitalFilterStore.samplingRate);
    const windowSize = format(sqDigitalFilterStore.windowSize);
    const taps = `${windowSize}/${samplingRate}`;

    switch (sqDigitalFilterStore.filterType) {
      case DIGITAL_FILTER_VALUES.BAND_STOP:
        formula += `.bandStopFilter(${cutoff},${cutoff2},${samplingRate},${windowSize})`;
        break;
      case DIGITAL_FILTER_VALUES.BAND_PASS:
        formula += `.bandPassFilter(${cutoff},${cutoff2},${samplingRate},${windowSize})`;
        break;
      case DIGITAL_FILTER_VALUES.LOW_PASS:
        formula += `.lowPassFilter(${cutoff},${samplingRate},${taps})`;
        break;
      case DIGITAL_FILTER_VALUES.HIGH_PASS:
        formula += `.highPassFilter(${cutoff},${samplingRate},${taps})`;
        break;
    }
    return { formula, parameters };
  }

  function execute(color?) {
    const { parameters, formula } = service.getParametersAndFormula();

    return sqToolRunner.panelExecuteSignal(
      sqDigitalFilterStore.name,
      formula,
      parameters,
      sqDigitalFilterStore.configParams,
      sqDigitalFilterStore.id,
      color,
      { notifyOnError: false }
    );
  }

  function setFilterType(filterType) {
    flux.dispatch('DIGITAL_FILTER_SET_FILTER_TYPE', { filterType });
  }

  function setCutoff(cutoff) {
    flux.dispatch('DIGITAL_FILTER_SET_CUTOFF', { cutoff });
  }

  function setCutoff2(cutoff2) {
    flux.dispatch('DIGITAL_FILTER_SET_CUTOFF_2', { cutoff2 });
  }

  function setSamplingRate(samplingRate) {
    flux.dispatch('DIGITAL_FILTER_SET_SAMPLING_RATE', { samplingRate });
  }

  function setWindowSize(windowSize) {
    flux.dispatch('DIGITAL_FILTER_SET_WINDOW_SIZE', { windowSize });
  }

  function setIsAutoSamplingRate(isAutoSamplingRate) {
    flux.dispatch('DIGITAL_FILTER_SET_IS_AUTO_SAMPLING_RATE', { isAutoSamplingRate });

    if (isAutoSamplingRate) {
      return service.fetchSamplingRate()
        .then((period) => {
          const samplingRate = sqDateTime.determineIdealUnits(period);
          service.setSamplingRate(_.assign(samplingRate, { valid: true }));
        });
    }
  }

  function setIsAutoWindowSize(isAutoWindowSize) {
    flux.dispatch('DIGITAL_FILTER_SET_IS_AUTO_WINDOW_SIZE', { isAutoWindowSize });

    if (isAutoWindowSize) {
      service.setWindowSizeFromCutoff();
    }
  }

  function fetchSamplingRate() {
    const parameters = { inputSignal: sqDigitalFilterStore.inputSignal.id };
    const formula = `estimateSamplePeriod($inputSignal, ${sqDateTime.getCapsuleFormula({
      start: sqDurationStore.displayRange.start,
      end: sqDurationStore.displayRange.end
    })})`;

    return sqFormula.computeScalar({ formula, parameters, cancellationGroup: 'estimateSamplePeriod' })
      .then((result) => {
        // Convert seconds to milliseconds so it's ready for use in sqDateTime.determineIdealUnits()
        return {
          value: result.value * NUMBER_CONVERSIONS.MILLISECONDS_PER_SECOND,
          units: 'ms'
        };
      });
  }

  function fetchAndSetDefaultValues() {
    return service.fetchSamplingRate()
      .then((period) => {
        const samplingRate = sqDateTime.determineIdealUnits(period);

        // Window size is DIGITAL_FILTER_WINDOW_TO_CUTOFF_RATIO times the largest cutoff in period units
        const windowSizeBaseValue = service.isBandFilter()
          ? period.value * DIGITAL_FILTER_PERIOD_CUTOFF2_RATIO
          : period.value * DIGITAL_FILTER_PERIOD_CUTOFF_RATIO;
        const windowSize = sqDateTime.determineIdealUnits(
          { value: windowSizeBaseValue * DIGITAL_FILTER_WINDOW_TO_CUTOFF_RATIO, units: 'ms' });

        const cutoff = sqDateTime.convertPeriodToFrequency({
          value: (period.value * DIGITAL_FILTER_PERIOD_CUTOFF_RATIO) / NUMBER_CONVERSIONS.MILLISECONDS_PER_SECOND,
          units: 's'
        });
        const cutoff2 = sqDateTime.convertPeriodToFrequency({
          value: (period.value * DIGITAL_FILTER_PERIOD_CUTOFF2_RATIO) / NUMBER_CONVERSIONS.MILLISECONDS_PER_SECOND,
          units: 's'
        });

        if (sqDigitalFilterStore.isAutoSamplingRate) {
          service.setSamplingRate(_.assign(samplingRate, { valid: true }));
        }

        if (sqDigitalFilterStore.isAutoWindowSize) {
          service.setWindowSize(_.assign(windowSize, { valid: true }));
        }

        if (_.isUndefined(sqDigitalFilterStore.cutoff.value) || _.isUndefined(sqDigitalFilterStore.cutoff.units)) {
          service.setCutoff(_.assign(cutoff, { valid: true, value: _.round(cutoff.value, DIGITAL_FILTER_PRECISION) }));
        }

        if (_.isUndefined(sqDigitalFilterStore.cutoff2.value) || _.isUndefined(sqDigitalFilterStore.cutoff2.units)) {
          service.setCutoff2(
            _.assign(cutoff2, { valid: true, value: _.round(cutoff2.value, DIGITAL_FILTER_PRECISION) }));
        }
      });
  }

  function isBandFilter() {
    return _.includes([DIGITAL_FILTER_VALUES.BAND_PASS, DIGITAL_FILTER_VALUES.BAND_STOP],
      sqDigitalFilterStore.filterType);
  }

  function determineMaxCutoffInPeriodUnits() {
    const { cutoff, cutoff2 } = sqDigitalFilterStore;
    const isBand = service.isBandFilter();

    if (!cutoff.valid || (isBand && !cutoff2.valid)) {
      return undefined;
    }

    const cutoffInPeriodUnits = sqDateTime.isFrequency(cutoff.units)
      ? sqDateTime.convertFrequencyToPeriod(cutoff)
      : cutoff;
    const cutoff2InPeriodUnits = sqDateTime.isFrequency(cutoff2.units)
      ? sqDateTime.convertFrequencyToPeriod(cutoff2)
      : cutoff2;

    const cutoffDuration = moment.duration(cutoffInPeriodUnits.value,
      sqDateTime.momentMeasurementStrings(cutoffInPeriodUnits.units)).asMilliseconds();
    const cutoff2Duration = moment.duration(cutoff2InPeriodUnits.value,
      sqDateTime.momentMeasurementStrings(cutoff2InPeriodUnits.units)).asMilliseconds();

    return (!isBand || cutoffDuration > cutoff2Duration) ? cutoffInPeriodUnits : cutoff2InPeriodUnits;
  }

  function setWindowSizeFromCutoff() {
    const maxCutoff = service.determineMaxCutoffInPeriodUnits();
    if (!_.isUndefined(maxCutoff)) {
      const windowSize = sqDateTime.determineIdealUnits(
        { value: maxCutoff.value * DIGITAL_FILTER_WINDOW_TO_CUTOFF_RATIO, units: maxCutoff.units });

      // Only update the value if it meets the ratio requirements
      if (sqInvestigateHelper.checkWindowSizeRatio(sqDigitalFilterStore.samplingRate, windowSize,
        DIGITAL_FILTER_TAPS_MIN)) {
        service.setWindowSize(_.assign(windowSize, { valid: true }));
      }
    }
  }
}
