import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import drilldown from 'highcharts/modules/drilldown.js';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { TimezonesService } from '@/datetime/timezone.service';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ContentApi } from '@/sdk';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import {
  getAxisForStat,
  getDrilldownFromOutput,
  getSeriesFromOutput, getTitleForStat,
  ReportChartStat,
  TextFilters
} from '@/hybrid/administration/reportAdmin.utilities';
import { ClearableInput } from '@/hybrid/core/ClearableInput.molecule';

const cancellationGroup = 'reportAdminChart';

const reportChartsBindings = bindingsDefinition({
  hide: prop<boolean>(),
  toggleHideButton: prop<JSX.Element>(),
  reportTextFilters: prop<TextFilters>(),
  setReportTextFilters: prop<(filters: TextFilters) => void>(),
  triggerFetch: prop<() => void>(),
  trigger: prop<boolean>(),
  sqWorkbenchStore: injected<WorkbenchStore>(),
  sqTimezones: injected<TimezonesService>(),
  sqPendingRequests: injected<PendingRequestsService>(),
  sqContentApi: injected<ContentApi>()
});

drilldown(Highcharts);

export const ReportCharts: SeeqComponent<typeof reportChartsBindings> = (props) => {
  const {
    hide,
    toggleHideButton,
    reportTextFilters,
    setReportTextFilters,
    triggerFetch,
    trigger
  } = props;

  const {
    sqTimezones,
    sqWorkbenchStore,
    sqPendingRequests,
    sqContentApi
  } = useInjectedBindings(reportChartsBindings);

  const timezone = sqWorkbenchStore.userTimeZone || sqTimezones.defaultTimezone;

  const { t } = useTranslation();
  const [chartLoading, setChartLoading] = useState(false);
  const [start, setStart] = useState(moment().tz(timezone.name).startOf('day'));
  const [stat, setStat] = useState(ReportChartStat.AverageRunTime);
  const [chart, setChart] = useState(undefined);
  const [filterFired, setFilterFired] = useState(false);

  const [options, setOptions] = useState({
    chart: {
      type: 'column',
      events: {
        drilldown: e => setOptions({ title: { text: getTitleForStat(stat, true, t, e.point.drilldown) } }),
        drillup: e => setOptions({ title: { text: getTitleForStat(stat, false, t) } })
      }
    },
    title: {
      text: getTitleForStat(stat, false, t)
    },
    subtitle: {
      text: t('ADMIN.REPORT.CHARTS.SUBTITLE')
    },
    accessibility: {
      announceNewData: {
        enabled: true
      }
    },
    xAxis: {
      type: 'category'
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  } as any);

  const fetchSummaryForChart = () => {
    setChartLoading(true);
    sqPendingRequests.cancelGroup(cancellationGroup)
      .then(() => {
        if (chart?.drilldownLevels?.length > 0) {
          chart?.drillUp();
        }
      })
      .then(() => sqContentApi.getReportSummary(
        { start: start.format(), stat, ownerFilter: reportTextFilters.owner }, { cancellationGroup })
        .then(({ data: { results } }) => setOptions({
            series: [{
              name: getTitleForStat(stat, false, t),
              colorByPoint: true,
              data: getSeriesFromOutput(stat, results, t)
            }],
            drilldown: {
              series: getDrilldownFromOutput(stat, results, t)
            },
            yAxis: {
              title: {
                text: getAxisForStat(stat, t)
              }
            },
            tooltip: {
              enabled: false
            }
          })
        ));
  };

  useEffect(() => {
    fetchSummaryForChart();
  }, [trigger, filterFired]);

  return <div style={{ display: hide ? 'none' : 'block' }} className="flexRowContainer flexFill height-800">
    <div className="flexColumnContainer flexSpaceBetween">
      <div className="flexColumnContainer">
        <strong className="m-2">{t('ADMIN.REPORT.OWNER')}</strong>
        <div className="width-150">
          <ClearableInput
            field="owner"
            searchValue={reportTextFilters.owner}
            filterTable={(option, field) => setReportTextFilters({ ...reportTextFilters, owner: field })}
            enterCallback={() => {
              // Workaround for a race condition in which the filter isn't set by the time the fetch actually occurs.
              // Forces React to process its new props before fetching.
              setFilterFired(!filterFired);
              triggerFetch();
            }} />
        </div>
      </div>
      {toggleHideButton}
    </div>

    <div className="width-auto height-maximum">
      {chartLoading
      && <HighchartsReact
        highcharts={Highcharts}
        options={options}
        callback={setChart}
      />}
    </div>
  </div>;
};
