import angular from 'angular';
import template from './journalHeader.component.html';

/**
 * Provides the toolbar seen at the top of a journal entry.
 */
angular.module('Sq.Annotation').component('sqJournalHeader', {
  template,
  bindings: {
    id: '<',
    isDeleteAllowed: '<',
    isEditAllowed: '<',
    isEditing: '<',
    isSaving: '<',
    isSaved: '<',
    isError: '<',
    createdBy: '<',
    updatedAt: '<',
    isExpanded: '<',
    isPresentationMode: '<',
    isDiscoverable: '<',
    hasMultipleJournalEntries: '<',
    edit: '&',
    delete: '&', // id
    expand: '&',
    close: '&',
    showOverview: '&'
  }
});
