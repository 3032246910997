import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { getFormFieldProps } from '@/hybrid/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import TimeZoneSelector from '@/hybrid/core/TimeZoneSelector.molecule';
import classNames from 'classnames';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';

export interface TimeZoneSelectorIF extends ValidatingFormComponent <any> {
  component: 'TimeZoneSelectorFormComponent';
  onChange: ({ value: string }) => void;
  value: { label: string, value: any };
  optional?: boolean;
  disabled?: boolean;
  timezoneSet?: boolean;
  defaultTimeZone?: { name: string };
  direction?: 'top' | 'bottom' | 'auto';
  enableAutoDetect?: boolean;
}

export const TimeZoneSelectorFormComponent: React.FunctionComponent<TimeZoneSelectorIF> = (props) => {
  const { name, validation, onChange, defaultTimeZone, skipStore, value, testId, enableAutoDetect = true } = props;
  const { t } = useTranslation();
  const defaultValidation = timezoneObj => _.isEmpty(timezoneObj?.name);
  const formState = useForm().getState();
  const timezoneSet = !_.isEmpty(formState.values[name]);

  return <div className="mb8 width-100percent" data-testid={testId}>
    <Field name={name} validate={validation || defaultValidation}>
      {({ input, meta }) => (<>
          <TimeZoneSelector
            {...getFormFieldProps(formState, input, meta, props)}
            onSelect={(timezone) => {
              input.onChange(timezone);
              onChange(timezone);
            }}
            timezone={skipStore ? input.value : value}
          />

          {enableAutoDetect &&
          <div className="ml5 mt5 mb8 flexColumnContainer">
            <HoverTooltip placement="left" text="USER.RESET_TOOLTIP">
              <a className={classNames('cursorPointer', 'text-italic',
                {
                  'font-size-smaller': timezoneSet,
                  'sq-text-danger': !timezoneSet,
                  'text-underline': !timezoneSet,
                  'force-link-look': timezoneSet
                })}
                onClick={() => input.onChange(defaultTimeZone)}>{t('USER.RESET')}</a>
            </HoverTooltip>
          </div>}
        </>
      )}
    </Field>
  </div>;
};
