import _ from 'lodash';
import angular from 'angular';
import { LICENSE_STATUS } from '@/licenseManagement/licenseManagement.module';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';

angular.module('Sq.LicenseManagement')
  .store('sqLicenseManagementStore', sqLicenseManagementStore);

export type LicenseManagementStore = ReturnType<typeof sqLicenseManagementStore>['exports'];

/*
 * IMPORTANT: Always sync this with
 *  - Python licensing.py:Feature.salesforce_to_seeq_mapping
 *  - LicensedFeature.java:Feature
 */
export enum Feature {
  ODATA_Realtime_Export,
  Data_Lab,
  WITSML_Connector,
  AF_Data_Reference,
  Audit_Trail,
  Seeq_Connector_Plus_PI_EF,
  Asset_Group,
  Write_To_PI
}

function sqLicenseManagementStore() {
  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.NONE,
    initialize() {
      this.state = this.immutable({
        licenseStatus: undefined,
        license: {}
      });
    },

    exports: {
      get licenseStatus() {
        return this.state.get('licenseStatus');
      },

      get license() {
        return this.state.get('license');
      },

      get isTrial() {
        return _.get(this.state.get('license'), 'level') === 'Trial';
      },

      hasValidFeature(feature) {
        return _.some(this.state.get(['license', 'additionalFeatures']),
          licensedFeature => licensedFeature.name === Feature[feature] && licensedFeature.validity === 'Valid');
      },

      get displayLicenseWarning() {
        const warningLevels = [LICENSE_STATUS.SHOW_ADMIN_LICENSE_WARNING, LICENSE_STATUS.SHOW_LICENSE_WARNING];
        return _.includes(warningLevels, this.state.get('licenseStatus'));
      },

      canAddUsers() {
        const license = this.state.get('license');
        return !license.userLimitRestrict || license.userLimitRestrict > license.userCount;
      }
    },

    handlers: {
      LICENSE_SET_LICENSE: 'setLicense',
      LICENSE_SET_STATUS: 'setLicenseStatus'
    },

    /**
     * Sets the license Object
     *
     * @param {Object} payload - the licenseObject
     * @param {String} payload.hostname - the hostname the license is issued for
     * @param {String} payload.level - the license level (Standard or Trial)
     * @param {String} payload.validity - the license validity
     * @param {Number} payload.daysToExpiration - the number of days the license will remain valid for
     * @param {Number} payload.validThrough - the date of the last day the license is valid on
     * @param {String} payload.companyName - the company/organization linked to this license
     * @param {String} payload.contractNumber - the Seeq Contract Number associated with this license
     */
    setLicense(payload) {
      this.state.set('license', payload);
    },

    /**
     * Sets the licenseStatus
     *
     * @param {Object} payload - the status object
     * @param {String} payload.status - one of LICENSE_STATUS
     */
    setLicenseStatus(payload) {
      this.state.set('licenseStatus', payload.status);
    }
  };

  return store;
}
