/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class TreesApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Add the specified items as child nodes of the specified parents within the specified tree. If an item is already a member of this tree, it will move from its current parent to the specified parent. The children of the items being moved will move with each parent.
   * @param {models.AssetTreeBatchInputV1} [body]
   */
  public batchMoveNodesToParents(
    body?: models.AssetTreeBatchInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/trees/assets/batch',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ItemBatchOutputV1>;
  }

  /**
   * 
   * @summary Create or update an asset tree
   * @param {models.AssetGroupInputV1} [body]
   */
  public createTree(
    body?: models.AssetGroupInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/trees/assets/createTree',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AssetGroupOutputV1>;
  }

  /**
   * Pagination is enabled for this query
   * @summary Get a collection of all available tree types
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getAllTreeTypes(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/trees',
      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ItemPreviewListV1>;
  }

  /**
   * Pagination is enabled for this query
   * @summary Get the ancestors and children of the specified item within the context of this tree
   * @param {string} id - ID of the parent asset
   * @param {string} [scopedTo] - The ID of the workbook to which the items are scoped&lt;br&gt;If the scopedTo is set to the Empty GUID then the scoped items are excluded
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   * @param {boolean} [includeDescendants=false] - If the children of the children should be retrieved
   */
  public getTree(
    {
      id,
      scopedTo,
      offset,
      limit,
      includeDescendants
    } : {
      id: string,
      scopedTo?: string,
      offset?: number,
      limit?: number,
      includeDescendants?: boolean
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/trees/assets/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      params: omitBy({
        ['scopedTo']: scopedTo,
        ['offset']: offset,
        ['limit']: limit,
        ['includeDescendants']: includeDescendants
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AssetTreeOutputV1>;
  }

  /**
   * Pagination is enabled for this query
   * @summary Get a collection of root nodes for the asset tree
   * @param {string} [scopedTo] - The ID of the workbook to which the items are scoped&lt;br&gt;If the scopedTo is set to the Empty GUID, then the scoped items are excluded
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   * @param {Array<string>} [properties] - A list of property names and values to filter on.  Arguments should be passed in in the following format Property&#x3D;Value, with each new property-value pair on a new line. The option to filter out a node by a property can be done by using !&#x3D;.
   */
  public getTreeRootNodes(
    {
      scopedTo,
      offset,
      limit,
      properties
    } : {
      scopedTo?: string,
      offset?: number,
      limit?: number,
      properties?: Array<string>
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/trees/assets',
      params: omitBy({
        ['scopedTo']: scopedTo,
        ['offset']: offset,
        ['limit']: limit,
        ['properties']: properties
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AssetTreeOutputV1>;
  }

  /**
   * 
   * @summary Add the specified items as child nodes of the specified parent within the specified tree. If an item is already a member of this tree, it will move from its current parent to the specified parent. The children of the item being moved will move with it.
   * @param {string} parentId - ID of the parent node
   * @param {models.ItemIdListInputV1} body - Item list
   */
  public moveNodesToParent(
    body: models.ItemIdListInputV1,
    {
      parentId,
    } : {
      parentId: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(parentId)) {
      throw new Error("'parentId' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/trees/assets/{parentId}'
        .replace('{parentId}', encodeURIComponent(String(parentId))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Add the specified items as root nodes of the specified tree. If an item is already a member of this tree, it will move to the root and its children will move with it
   * @param {models.ItemIdListInputV1} body - Item list
   */
  public moveNodesToRootOfTree(
    body: models.ItemIdListInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/trees/assets',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Remove an item and its children from the tree. (These items will still exist but not as part of this tree.)
   * @param {string} id - ID of the node to remove
   */
  public removeNodeFromTree(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/trees/assets/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Build a treemap
   * @param {string} start - The start time under which to compute conditions for the treemap.
   * @param {string} end - The end time under which to compute conditions for the treemap.
   * @param {Array<string>} conditionIds - The IDs of the conditions that will be used to create the treemap; must be in order of priority, where the first condition is the highest priority item.
   * @param {string} swapId - The ID of the asset to swap each treemap leaf node with.
   * @param {string} parentId - The ID of the parent asset to build the treemap from.
   * @param {Array<string>} [parameters] - Parameters for the display formulas. Each parameter should have a format of &#39;name&#x3D;id&#39; where &#39;name&#39; is the variable identifier, without the leading $ sign, and &#39;id&#39; is the ID of the item referenced by the variable
   * @param {Array<string>} [formulas] - A list of display scalar formulas
   */
  public treemap(
    {
      start,
      end,
      conditionIds,
      swapId,
      parentId,
      parameters,
      formulas
    } : {
      start: string,
      end: string,
      conditionIds: Array<string>,
      swapId: string,
      parentId: string,
      parameters?: Array<string>,
      formulas?: Array<string>
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(start)) {
      throw new Error("'start' parameter required");
    }

    if (isNil(end)) {
      throw new Error("'end' parameter required");
    }

    if (isNil(conditionIds)) {
      throw new Error("'conditionIds' parameter required");
    }

    if (isNil(swapId)) {
      throw new Error("'swapId' parameter required");
    }

    if (isNil(parentId)) {
      throw new Error("'parentId' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/trees/treemap',
      params: omitBy({
        ['start']: start,
        ['end']: end,
        ['conditionIds']: conditionIds,
        ['swapId']: swapId,
        ['parentId']: parentId,
        ['parameters']: parameters,
        ['formulas']: formulas
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.TreemapOutputV1>;
  }

  /**
   * 
   * @summary Update an asset tree
   * @param {models.AssetGroupInputV1} [body]
   */
  public updateTree(
    body?: models.AssetGroupInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/trees/assets/updateTree',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AssetGroupOutputV1>;
  }

}
