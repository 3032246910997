import * as api from './api/api';
import angular from 'angular';

const apiModule = angular.module('api', [])
  .service('sqAccessKeysApi', api.AccessKeysApi)
  .service('sqAgentsApi', api.AgentsApi)
  .service('sqAncillariesApi', api.AncillariesApi)
  .service('sqAnnotationsApi', api.AnnotationsApi)
  .service('sqAssetsApi', api.AssetsApi)
  .service('sqAuditApi', api.AuditApi)
  .service('sqAuthApi', api.AuthApi)
  .service('sqConditionsApi', api.ConditionsApi)
  .service('sqContentApi', api.ContentApi)
  .service('sqDatafilesApi', api.DatafilesApi)
  .service('sqDatasourcesApi', api.DatasourcesApi)
  .service('sqExportApi', api.ExportApi)
  .service('sqFoldersApi', api.FoldersApi)
  .service('sqFormulasApi', api.FormulasApi)
  .service('sqItemsApi', api.ItemsApi)
  .service('sqJobsApi', api.JobsApi)
  .service('sqLogsApi', api.LogsApi)
  .service('sqMetricsApi', api.MetricsApi)
  .service('sqMonitorsApi', api.MonitorsApi)
  .service('sqNetworksApi', api.NetworksApi)
  .service('sqPluginsApi', api.PluginsApi)
  .service('sqProjectsApi', api.ProjectsApi)
  .service('sqReportsApi', api.ReportsApi)
  .service('sqRequestsApi', api.RequestsApi)
  .service('sqSampleSeriesApi', api.SampleSeriesApi)
  .service('sqScalarsApi', api.ScalarsApi)
  .service('sqSignalsApi', api.SignalsApi)
  .service('sqSubscriptionsApi', api.SubscriptionsApi)
  .service('sqSystemApi', api.SystemApi)
  .service('sqTreesApi', api.TreesApi)
  .service('sqUserGroupsApi', api.UserGroupsApi)
  .service('sqUsersApi', api.UsersApi)
  .service('sqWorkbooksApi', api.WorkbooksApi);

export default apiModule;
