import _ from 'lodash';
import angular from 'angular';
import moment from 'moment-timezone';
import FileSaver from 'file-saver';
import { ExportService } from '@/services/export.service';
import { TrendStore } from '@/trendData/trend.store';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { LoggerService } from '@/services/logger.service';
import { NotificationsService } from '@/services/notifications.service';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { DurationStore } from '@/trendData/duration.store';
import { EXPORTABLE_ITEM_TYPES } from '@/trendData/trendData.module';
import { APP_STATE, MIME_TYPES } from '@/main/app.constants';
import { GRID_OPTION } from '@/investigate/exportOData/exportODataPanel.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';

angular.module('Sq.TrendViewer').controller('ExportExcelModalCtrl', ExportExcelModalCtrl);

function ExportExcelModalCtrl(
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  $state: ng.ui.IStateService,
  sqTrendDataHelper: TrendDataHelperService,
  sqExport: ExportService,
  sqTrendStore: TrendStore,
  sqDurationStore: DurationStore,
  sqPendingRequests: PendingRequestsService,
  sqLogger: LoggerService,
  sqNotifications: NotificationsService,
  sqWorksheetStore: WorksheetStore,
  exportName: string
) {

  const vm = this;
  vm.options = { statistics: true, data: true };
  vm.dataOptions = {
    gridOption: GRID_OPTION.AUTOMATIC,
    units: 'min',
    customOriginEnabled: false,
    exportTimeZone: sqWorksheetStore.timezone,
    customOrigin: moment().tz(sqWorksheetStore.timezone.name).startOf('year')
  };
  vm.worksheetTimeZone = sqWorksheetStore.timezone;
  vm.units = ['sec', 'min', 'hour', 'day', 'week', 'month', 'year'];
  vm.anyOptionsSelected = anyOptionsSelected;
  vm.setExportTimeZone = setExportTimeZone;
  vm.setCustomOrigin = setCustomOrigin;
  vm.close = $uibModalInstance.close;
  vm.exportData = exportData;
  vm.isCapsuleTime = sqTrendStore.isTrendViewCapsuleTime;
  vm.isChainView = sqTrendStore.isTrendViewChainView;
  vm.GRID_OPTION = GRID_OPTION;
  vm.worksheetId = $state.params.worksheetId;
  vm.workbookId = $state.params.workbookId;

  /**
   * Determines if any options are selected
   * @returns {Boolean} True if options are selected, false otherwise
   */
  function anyOptionsSelected() {
    return _.some(vm.options, _.identity);
  }

  /**
   * Updates the time zone for the export
   */
  function setExportTimeZone(timeZone) {
    vm.dataOptions.exportTimeZone = timeZone;
  }

  /**
   * Updates the origin for the export to what the user set
   */
  function setCustomOrigin(origin) {
    vm.dataOptions.customOrigin = origin;
  }

  /**
   * Generate a Workbench URL to current Worksheet, with view-only access
   *
   * @returns {String} Returns an absolute URL
   */
  function makeWorksheetURL() {
    return $state.href(APP_STATE.VIEW_WORKSHEET, {
      workbookId: vm.workbookId,
      worksheetId: vm.worksheetId
    }, {
      absolute: true
    });
  }

  /**
   * Begins the export process and closes the modal with the promise that will resolve when the export completes.
   */
  function exportData() {
    const cancellationGroup = 'excelExport';
    const gridSizeEnabled = vm.dataOptions.gridOption === GRID_OPTION.CUSTOM;
    const originalTimestampsEnabled = vm.dataOptions.gridOption === 'original';
    const gridOriginString = gridSizeEnabled && vm.dataOptions.customOriginEnabled ?
      // Format the origin so it's output as a localized time string
      vm.dataOptions.customOrigin.format('YYYY-MM-DD[T]HH:mm:ss') : null;
    sqPendingRequests.cancelGroup(cancellationGroup);

    const items = sqTrendDataHelper.getAllItems({
      workingSelection: sqTrendStore.hideUnselectedItems,
      itemTypes: EXPORTABLE_ITEM_TYPES
    });
    vm.close(sqExport.toExcel(exportName, items, sqDurationStore.displayRange, vm.options.statistics, vm.options.data,
      gridSizeEnabled && (vm.dataOptions.gridSize + vm.dataOptions.units), gridOriginString, vm.isCapsuleTime(),
      vm.isChainView(), cancellationGroup, originalTimestampsEnabled, vm.dataOptions.exportTimeZone.name, makeWorksheetURL())
      .then(response => new Blob([response.data], { type: MIME_TYPES.EXCEL }))
      .then(blob => FileSaver.saveAs(blob, 'SeeqExport.xlsx'))
      .catch((error) => {
        // Excel Exports can run long and can be cancelled. In that case, we want to show a visible error in the UI.
        // If we called sqNotifications.apiError it would be silent, because we usually do not notify on cancellations.
        const message = sqLogger.format`${_.isError(error) ? error.toString() : error}`;
        sqNotifications.error(message);
      }));
  }
}
