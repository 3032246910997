import bind from 'class-autobind-decorator';
import _ from 'lodash';
import { NUMBER_CONVERSIONS } from '@/main/app.constants';
import { DateTimeService } from '@/datetime/dateTime.service';
import { UtilitiesService } from '@/services/utilities.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';

@bind
export class RequestsTabUtilities {
  constructor(
    private sqDateTime: DateTimeService,
    private sqUtilities: UtilitiesService
  ) {}

  getAggregatedRequestDetails(requests) {
    return _.chain(requests)
      .map(request => _.get(_.last(request.activities), 'properties'))
      .filter(props => props && props[SeeqNames.Properties.DatasourceClass] && props[SeeqNames.Properties.DatasourceId])
      .countBy((props) => {
        /*
         * Collection.countBy(Iteratee) creates an object composed of keys generated from the results of
         * running each element of collection thru iteratee. In this case, iteratee contains an array of
         * properties. By overriding the standard toString() function of the countAndKeys array, we cause
         * countBy to return a key that contains custom formatting. This formatting is propagated to the
         * frontend.
         */
        const countAndKey = [props[SeeqNames.Properties.DatasourceClass], props[SeeqNames.Properties.DatasourceId]];
        (countAndKey as String[]).toString = () => props[SeeqNames.Properties.DatasourceName] + ' (' + countAndKey[0] + ', ' + countAndKey[1] + ')';
        return countAndKey;
      })
      .map((count, key) => [count, key])
      .sortBy(countAndKey => -countAndKey[0])
      .take(10)
      .value();
  }

  formatRequests(requests) {
    _.forEach(requests, (request) => {
      _.set(request?.parameters, 'requested duration', this.getRequestedDuration(request));
      _.set(request, 'methodAndUri', this.getMethodAndUri(request));
      _.set(request, 'status', _.get(_.last(request?.activities), 'description'));
      _.set(request, 'duration', this.getDuration(request));
      _.set(request, 'percentProgress', this.getPercentProgress(request));
      _.set(request, 'id', this.sqUtilities.base64guid()); // needed to handle selection in the table
      _.set(request, 'userFullName', _.head(request?.parameters?.['user name']));
    });

    return requests;
  }

  getRequestedDuration(request) {
    const requestedDuration = request?.parameters?.['requested duration'];
    if (!_.isUndefined(requestedDuration)) {
      return [this.sqDateTime.humanizeInterval(requestedDuration[0] / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND)];
    }
    return undefined;
  }

  getMethodAndUri(request) {
    const { requestMethod, requestPath } = request;
    return _.chain([requestMethod || '', requestPath || ''])
      .filter(item => !_.isEmpty(item))
      .join(' ')
      .value();
  }

  getDuration(request) {
    return _.round(request.runDuration /
      NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND /
      NUMBER_CONVERSIONS.MILLISECONDS_PER_SECOND,
      1);
  }

  getPercentProgress(request) {
    return request.progress.totalCount === 0
      ? 0
      : Math.round(request.progress.completedCount / request.progress.totalCount * 100);
  }
}
