import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '../core/bindings.util';
import { angularComponent } from '../core/react2angular.util';
import { TrackService } from '@/track/track.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ReportContentActions } from '@/reportEditor/reportContent.actions';
import { CONTENT_STATE } from '@/reportEditor/report.module';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';

const insertContentDropdownBindings = bindingsDefinition({
  sqTrack: injected<TrackService>(),
  sqReportContentActions: injected<ReportContentActions>(),
  sqReportEditor: injected<ReportEditorService>(),
  onClick: prop.optional<() => void>()
});

export const InsertContentDropdown: SeeqComponent<typeof insertContentDropdownBindings> = (props) => {
  const { onClick } = props;
  const { sqTrack, sqReportContentActions, sqReportEditor } = useInjectedBindings(insertContentDropdownBindings);
  const { t } = useTranslation();

  const options = [{
    action: 'worksheet',
    title: 'REPORT.EDITOR.CHOOSE_CONTENT',
    description: 'REPORT.EDITOR.CHOOSE_CONTENT_DESCRIPTION'
  }, {
    action: 'link',
    title: 'REPORT.EDITOR.FROM_LINK',
    description: 'REPORT.EDITOR.FROM_LINK_DESCRIPTION'
  }];
  if (sqReportEditor.isCkEditor()) {
    options.push({
      action: 'date_range_label',
      title: 'REPORT.EDITOR.DATE_RANGE_LABEL',
      description: 'REPORT.EDITOR.DATE_RANGE_LABEL_DESCRIPTION'
    });
  }

  const onOptionClick = (option) => {
    switch (option) {
      case 'worksheet':
        sqTrack.doTrack('Topic', 'Seeq Link - Worksheet', 'displayed');
        sqReportContentActions.clear();
        sqReportContentActions.setModalName(CONTENT_STATE.WORKBOOK);
        break;
      case 'link':
        sqTrack.doTrack('Topic', 'Seeq Link - Link', 'displayed');
        sqReportContentActions.clear();
        sqReportContentActions.setModalName(CONTENT_STATE.LINK);
        break;
      case 'date_range_label':
        sqTrack.doTrack('Topic', 'Seeq Link - Date Range Label', 'displayed');
        sqReportEditor.executeCommand({ command: 'date_range_label' });
        break;
    }
    if (onClick) {
      onClick();
    }
  };

  return <div className="flexNoGrowNoShrink flexRowContainer">
    {_.map(options, option => <div
        key={option.action}
        className={classNames('seeqContentDropdown fr-command', option.action)}
        onClick={() => onOptionClick(option.action)}>
        {t(option.title)}
        <div>
          {t(option.description)}
        </div>
      </div>
    )}
  </div>;
};

export const sqInsertContentDropdown = angularComponent(insertContentDropdownBindings, InsertContentDropdown);
