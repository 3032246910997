import _ from 'lodash';
import angular from 'angular';
import { WORKSHEET_TABSETS } from '@/worksheet/worksheet.module';
import { DEFAULT_FORMULA_PANEL_HEIGHT, MIN_FORMULA_PANEL_WIDTH } from '@/hybrid/tools/formula/formulaTool.module';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';
import { WorksheetViewService } from '@/worksheet/worksheetView.service';
import { TimezonesService } from '@/datetime/timezone.service';
import { WorkbookStore } from '@/workbook/workbook.store';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { ITEM_TYPES } from '@/trendData/trendData.module';

angular.module('Sq.Worksheet').store('sqWorksheetStore', sqWorksheetStore);

export type WorksheetStore = ReturnType<typeof sqWorksheetStore>['exports'];

function sqWorksheetStore(
  $injector: ng.auto.IInjectorService,
  sqWorksheetView: WorksheetViewService,
  sqTimezones: TimezonesService,
  sqWorkbookStore: WorkbookStore,
  sqWorkbenchStore: WorkbenchStore
) {
  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.WORKSHEET,

    initialize() {
      this.state = this.immutable({
        tabsets: {
          sidebar: sqWorkbookStore.isReportBinder ?
            WORKSHEET_TABSETS.sidebar.indexOf('reportConfig') :
            WORKSHEET_TABSETS.sidebar.indexOf('search')
        },
        viewKey: sqWorksheetView.getDefault().key,
        returnViewKey: undefined,
        selectedIdsForView: {},
        browsePanelCollapsed: false,
        displayWidth: MIN_FORMULA_PANEL_WIDTH,
        displayHeight: DEFAULT_FORMULA_PANEL_HEIGHT,
        resizeEnabled: true,
        timezone: undefined,
        conditionToSeriesGrouping: {},
        capsuleGroupMode: false,
        pluginShownColumns: this.state ? this.state.get('pluginShownColumns') : {},
        selectedSidebarTab: this.monkey(['tabsets'], (tabsets) => {
          return _.get(WORKSHEET_TABSETS, ['sidebar', tabsets.sidebar]);
        })
      });
    },

    exports: {
      /**
       * @property {Object} tabsets - An object map of the tabsets managed by the worksheet store
       * @param {String} name - The name of the tabset
       */
      getTabset(name) {
        return {
          activeTabIndex: this.state.get('tabsets', name),
          tabs: WORKSHEET_TABSETS[name]
        };
      },

      get view() {
        return sqWorksheetView.find(this.state.get('viewKey'));
      },

      get returnView() {
        return this.state.get('returnViewKey');
      },

      get browsePanelCollapsed() {
        return this.state.get('browsePanelCollapsed');
      },

      get displayWidth() {
        return this.state.get('displayWidth');
      },

      get displayHeight() {
        return this.state.get('displayHeight');
      },

      get resizeEnabled() {
        return this.state.get('resizeEnabled');
      },

      get capsuleGroupMode() {
        return this.state.get('capsuleGroupMode');
      },

      /**
       * Time zone to use when displaying data on this worksheet. If .timezoneFixed is true, then the time zone to use
       * is stored with the worksheet. Otherwise, time zone to use is the default timezone for this user. If neither
       * are defined, the default timezone is used.
       */
      get timezone() {
        return this.state.get('timezone') || sqWorkbenchStore.userTimeZone || sqTimezones.defaultTimezone;
      },

      get timezoneFixed() {
        return !_.isUndefined(this.state.get('timezone'));
      },

      get selectedSidebarTab() {
        return this.state.get('selectedSidebarTab');
      },

      get conditionToSeriesGrouping() {
        return this.state.get('conditionToSeriesGrouping');
      },

      getPluginShownColumns(identifier: string, trendPanel: string): string[] {
        return _.get(_.get(this.state.get('pluginShownColumns'), identifier, {}), trendPanel, []);
      },

      selectedIdsForView(key) {
        return this.state.get('selectedIdsForView', key);
      }
    },

    dehydrate() {
      const state = _.omit(this.state.serialize(), 'pluginShownColumns');
      state.timezone = _.get(state.timezone, 'name'); // only save the name
      return state;
    },

    rehydrate(dehydratedState) {
      if (dehydratedState.timezone) {
        dehydratedState.timezone = _.find(sqTimezones.timezones, { name: dehydratedState.timezone });
      }

      this.state.merge(dehydratedState);
    },

    handlers: {
      TABSET_CHANGE_TAB: 'setTabActive',
      WORKSHEET_CHANGE_VIEW: 'setView',
      WORKSHEET_RETURN_VIEW: 'setReturnView',
      WORKSHEET_SET_SELECTED_IDS: 'setSelectedIds',
      SET_BROWSE_PANEL_COLLAPSED: 'setBrowsePanelCollapsed',
      WORKSHEET_FORMULA_DISPLAY_RESIZE_ENABLED: 'setDisplayResizeEnabled',
      WORKSHEET_FORMULA_DISPLAY_WIDTH: 'setDisplayWidth',
      WORKSHEET_FORMULA_DISPLAY_HEIGHT: 'setDisplayHeight',
      WORKSHEET_SET_TIME_ZONE: 'setTimezone',
      SET_USER_TIME_ZONE: 'handleUserTimeZone',
      GROUP_SIGNAL_TO_CONDITION: 'groupSignalToCondition',
      REMOVE_SIGNAL_TO_CONDITION_GROUP: 'removeGrouping',
      TOGGLE_CAPSULE_GROUP_MODE: 'toggleCapsuleGroupMode',
      REMOVE_SIGNAL_FROM_GROUPINGS: 'removeSignalFromGroupings',
      SET_PLUGIN_SHOWN_COLUMNS: 'setPluginShownColumns',
      TREND_SWAP_ITEMS: 'swapGroupings',
      WORKSHEET_SWAP_GROUPINGS: 'swapGroupings',
      TREND_REMOVE_ITEMS: 'removeGroupingItems'
    },

    /**
     * Sets the active tab of a tabset
     *
     * @param {Object} payload Object container for properties
     * @param {String} payload.tabset The tabset name
     * @param {String} payload.activeTab The name of the tab that should be activated
     */
    setTabActive(payload) {
      if (_.has(WORKSHEET_TABSETS, payload.tabset)) {
        this.state.set(['tabsets', payload.tabset], WORKSHEET_TABSETS[payload.tabset].indexOf(payload.activeTab));
      }
    },

    /**
     * Sets the active (i.e. visible) view.
     *
     * @param {Object} payload Object container for properties
     * @param {string} payload.key The unique key of the view to activate
     */
    setView({ key: viewKey }) {
      this.state.set('viewKey', viewKey);
    },

    /**
     * Saves the "view" key into the returnViewKey so that we can return to that view
     * (currently used by asset groups to return the user to where they were before opening asset groups)
     *
     * @param {Object} payload Object container for properties
     * @param {string} payload.key The unique key of the view to activate
     */
    setReturnView({ key }) {
      this.state.set('returnViewKey', key);
    },

    /**
     * Sets the item ids that are selected for a specific view.
     *
     * @param {Object} payload Object container for properties
     * @param {WorksheetView.selectedItemsRealm} payload.realm The key that identifies the view to which the selected
     *   items belong
     * @param {string[]} payload.ids The ids of the items that are selected in that view
     */
    setSelectedIds({ realm, ids }) {
      if (_.isEmpty(ids)) {
        this.state.unset(['selectedIdsForView', realm]);
      } else {
        this.state.set(['selectedIdsForView', realm], ids);
      }
    },

    /**
     * Sets the display of the browse panel.
     *
     * @param {Object} payload Object container for properties
     * @param {Boolean} payload.isCollapsed True if the panel is collapsed, false otherwise
     */
    setBrowsePanelCollapsed(payload) {
      this.state.set('browsePanelCollapsed', payload.isCollapsed);
    },

    /**
     * Sets the display width of the formula form
     *
     * @param {Object} payload - an Object representing state.
     * @param {Number} payload.displayWidth - the width
     */
    setDisplayWidth(payload) {
      this.state.set('displayWidth', payload.displayWidth);
    },

    /**
     * Sets the display width of the formula form
     *
     * @param {Object} payload - an Object representing state.
     * @param {Number} payload.displayHeight - the height
     */
    setDisplayHeight(payload) {
      this.state.set('displayHeight', payload.displayHeight);
    },

    /**
     * Sets a flag that indicates whether or not resizing the formula form is enabled.
     *
     * @param {Object} payload - an Object representing state.
     * @param {Boolean} payload.resizeEnabled - the resizeEnabled flag
     */
    setDisplayResizeEnabled(payload) {
      this.state.set('resizeEnabled', payload.resizeEnabled);
    },

    /**
     * Sets a fixed time zone to use for this worksheet.
     *
     * @param {Object} [timezone] - Time zone to fix for this worksheet. If undefined, no fixed time zone
     *   will be saved with this worksheet
     * @param {String} [timezone.name] - Name for the time zone.
     */
    setTimezone(timezone: { name: string }) {
      this.state.set('timezone', timezone);
    },

    /**
     * Triggers a change event if the user time zone changes and no specific time zone is set on this worksheet.
     * This enables listeners to listen only to this store and automatically be notified of time zone changes.
     */
    handleUserTimeZone() {
      // This comparison is the same as '!timezoneFixed', but neither '!this.timezoneFixed' or
      // '!this.exports.timezoneFixed' gives us access to that accessor, so we just duplicate it here
      if (_.isUndefined(this.state.get('timezone'))) {
        // set this to a dummy value to trigger the change detection without changing the resulting value
        this.state.set('timezone', '');
        this.state.set('timezone', undefined);
      }
    },

    /**
     * Maintains a map that stores groupings of signals to condition.
     * The condition id is used as the key, the signal ids are stored in an array.
     *
     * If a mapping already exists it is removed, otherwise it is added.
     *
     * @param {string} signalId - the id of the signal
     * @param {string} conditionId - the id of the condition
     */
    groupSignalToCondition({ signalId, conditionId }) {
      let groupings = _.cloneDeep(this.state.get('conditionToSeriesGrouping'));
      if (groupings[conditionId]) {
        if (!_.includes(groupings[conditionId], signalId)) {
          groupings[conditionId].push(signalId);
        } else {
          _.pull(groupings[conditionId], signalId);
        }
      } else {
        groupings[conditionId] = [signalId];
      }
      groupings = _.omitBy(groupings, _.isEmpty);
      this.state.set('conditionToSeriesGrouping', groupings);
    },

    /**
     * Swaps grouping conditions and signals
     */
    swapGroupings({ swaps }) {
      const groupings = _.cloneDeep(this.state.get('conditionToSeriesGrouping'));

      _.forEach(swaps, (swappedInId, swappedOutId) => {
        if (groupings[swappedOutId]) {
          groupings[swappedInId] = groupings[swappedOutId];
          delete groupings[swappedOutId];
        }
        _.forEach(_.values(groupings), (signals) => {
          if (_.includes(signals, swappedOutId)) {
            const index = _.indexOf(signals, swappedOutId);
            signals[index] = swappedInId;
          }
        });
      });
      this.state.set('conditionToSeriesGrouping', groupings);
    },

    /**
     * Remove conditions and signals that are used for signal grouping in this store
     * but have been removed from the details pane.
     */
    removeGroupingItems({ items }) {
      _.forEach(items, (item) => {
        if (item.itemType === ITEM_TYPES.CAPSULE_SET) {
          this.removeGrouping({ conditionId: item.id });
        } else if (item.itemType === ITEM_TYPES.SERIES) {
          this.removeSignalFromGroupings(item);
        }
      });
    },

    /**
     * Removes all the groupings assigned to a given condition.
     *
     * @param {string} conditionId - id of the condition.
     */
    removeGrouping({ conditionId }) {
      this.state.unset(['conditionToSeriesGrouping', conditionId]);
    },

    /**
     * Toggles the capsuleGroupMode flag.
     * If capsuleGroupMode is enabled then only the capsuleSeriesSegments of signals mapped to a given condition are
     * displayed.
     */
    toggleCapsuleGroupMode() {
      this.state.set('capsuleGroupMode', !this.state.get('capsuleGroupMode'));
    },

    /**
     * Removes the provided id from all conditionToSeriesGrouping entries.
     *
     * @param {string} id - id of the signal to remove.
     */
    removeSignalFromGroupings({ id }) {
      const groupings = _.chain(_.cloneDeep(this.state.get('conditionToSeriesGrouping')))
        .mapValues(groupingIds => _.without(groupingIds, id))
        .value();

      this.state.set('conditionToSeriesGrouping', groupings);
    },

    /**
     * Sets the columns a plugin wants to be shown
     *
     * @param {String} identifier - the plugin identifier
     * @param {String} trendPanel - one of TREND_PANELS
     * @param {String[]} keys - an array of column keys that should be shown
     */
    setPluginShownColumns({ identifier, trendPanel, keys }) {
      this.state.merge('pluginShownColumns', { [identifier]: { [trendPanel]: [] } });
      const state = _.cloneDeep(this.state.get('pluginShownColumns'));
      state[identifier][trendPanel] = keys;
      this.state.set('pluginShownColumns', state);
    }
  };

  return store;
}
