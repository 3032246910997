import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { bindingsDefinition } from '@/hybrid/core/bindings.util';
import { AgentsCommandBar } from '@/hybrid/administration/agents/AgentsCommandBar.molecule';
import { AgentsTable } from '@/hybrid/administration/agents/AgentsTable.molecule';
import { ManageAgentModal } from '@/hybrid/administration/agents/ManageAgentModal';
import { useDatasources } from '@/hybrid/administration/datasources/datasources.hook';
import { DetailsLevel } from '@/hybrid/administration/datasources/datasources.module';
import { StageVersionModal } from '@/hybrid/administration/agents/StageVersionModal';
import { UpdateVersionModal } from '@/hybrid/administration/agents/UpdateVersionModal';

const agentsTabBindings = bindingsDefinition({});

export const AgentsTab: SeeqComponent<typeof agentsTabBindings> = () => {
  const datasources = useDatasources(DetailsLevel.Complete);
  const [agents, setAgents] = useState([]);
  const [modalShown, setModalShown] = useState(false);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [stageVersionModalShown, setStageVersionModalShown] = useState(false);
  const [updateVersionModalShown, setUpdateVersionModalShown] = useState(false);

  useEffect(() => {
    const agentsWithIds = _.map(datasources.agents, (agent) => {
      return { ...agent, id: agent.name };
    });
    setAgents(agentsWithIds);
  }, [datasources]);

  const showManageAgents = () => setModalShown(true);
  const closeModal = () => setModalShown(false);

  const showStageVersionModal = () => setStageVersionModalShown(true);
  const closeStageVersionModal = () => setStageVersionModalShown(false);
  const showUpdateVersionModal = () => setUpdateVersionModalShown(true);
  const closeUpdateVersionModal = () => setUpdateVersionModalShown(false);

  return (<div className="flexRowContainer flexFill">
    <AgentsCommandBar
      selectedAgents={selectedAgents}
      showManageAgents={showManageAgents}
      datasources={datasources}
      showStageVersionModal={showStageVersionModal}
      showUpdateVersionModal={showUpdateVersionModal}
    />
    <div className="flexRowContainer flexFillOverflow overflowAuto">
      <AgentsTable
        setSelectedAgents={setSelectedAgents}
        selectedAgents={selectedAgents}
        agents={agents}
      />
    </div>
    {modalShown &&
    <ManageAgentModal
      agentName={_.first(selectedAgents).name}
      onClose={closeModal} />}
    {stageVersionModalShown &&
    <StageVersionModal
      onClose={closeStageVersionModal} />}
    {updateVersionModalShown &&
    <UpdateVersionModal
      onClose={closeUpdateVersionModal} />}
  </div>);
};
