import _ from 'lodash';

export function intelligentlyTruncate(name: string, maxLength: number, separator: string = '...') {
  if (_.size(name) <= maxLength) {
    return name;
  }
  const correctMaxLength = maxLength - _.size(separator);
  const beforeCharCount = correctMaxLength / 3 * 2;
  const endCharCount = correctMaxLength / 3;
  const totalCharCount = _.size(name);
  return `${name.substring(0, beforeCharCount)}${separator}${name.substring(totalCharCount - endCharCount,
    totalCharCount)}`;
}
