import React from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { IconSelect } from '@/hybrid/core/IconSelect.molecule';

const advancedSearchSelectBindings = bindingsDefinition({
  className: prop<string>(),
  onChange: prop<(option: any) => void>(),
  value: prop<any>(),
  options: prop<any[]>(),
  label: prop<string>()
});

export const AdvancedSearchSelect: SeeqComponent<typeof advancedSearchSelectBindings> = (props) => {
  const { className, onChange, value, options, label } = props;
  const { t } = useTranslation();
  return (
    <div>
      <div className="mb5">{t(label)}</div>
      <IconSelect
        className={className}
        selectOptions={options}
        name="advancedSearch"
        placeholder=""
        value={value}
        onChange={onChange}
        insideModal={true}
      />
    </div>
  );
};
