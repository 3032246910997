import _ from 'lodash';
import angular from 'angular';
import { TreemapStore } from '@/treemap/treemap.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { TrendScalarStore } from '@/trendData/trendScalar.store';
import { StateSynchronizerService } from '@/services/stateSynchronizer.service';
import { UtilitiesService } from '@/services/utilities.service';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { TreemapActions } from '@/treemap/treemap.actions';
import { ITEM_TYPES } from '@/trendData/trendData.module';
import { SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.module';

angular.module('Sq.Treemap').controller('TreemapCtrl', TreemapCtrl);

function TreemapCtrl(
  $scope: ng.IScope,
  $element: JQuery,
  $timeout: ng.ITimeoutService,
  sqTreemapActions: TreemapActions,
  sqTreemapStore: TreemapStore,
  sqTrendSeriesStore: TrendSeriesStore,
  sqTrendScalarStore: TrendScalarStore,
  sqStateSynchronizer: StateSynchronizerService,
  sqUtilities: UtilitiesService,
  sqTrendDataHelper: TrendDataHelperService
) {
  const vm = this;
  const STATISTICS_COUNT = 2;
  let syncTimeout;

  vm.STATISTICS = _.filter(SAMPLE_FROM_SCALARS.VALUE_METHODS,
    stat => _.includes(stat.input, 'sample') && !stat.needsPercentile);

  vm.statisticsRange = _.range(0, STATISTICS_COUNT);
  vm.setParent = sqTreemapActions.setParent;
  vm.setStatistic = sqTreemapActions.setStatistic;
  vm.elementHeight = elementHeight;
  vm.isPresentationMode = sqUtilities.isPresentationWorkbookMode;

  $scope.$listenTo(sqTreemapStore, syncTreemapVars);
  $scope.$listenTo(sqTrendSeriesStore, syncSignalVars);
  $scope.$listenTo(sqTrendScalarStore, syncSignalVars);

  /**
   * Syncs the sqTreemapStore with view-model properties.
   *
   * @param {Object} e - The emit event
   */
  function syncTreemapVars(e) {
    vm.breadcrumbs = sqTreemapStore.breadcrumbs;
    vm.parent = sqTreemapStore.parent;
    vm.tree = sqTreemapStore.tree;
    vm.helpKey = sqTreemapStore.helpKey;
    vm.statistics = _.cloneDeep(sqTreemapStore.statistics); // for use with ng-model
    vm.swap = sqTreemapStore.swap;

    if (sqUtilities.propertyChanged(e, 'swap')) {
      syncSignalVars();
    }
  }

  /**
   * Syncs the stores that produce signals that are based off the same asset as the conditions. Because this is used
   * in ng-options there is some hackery involved to ensure it works with fast-follow and navigating worksteps.
   * The ngModel on the select will set the value to null if the option is removed, which happens during rehydrate
   * since the asset property is async, so we prevent the option being falsely removed while the workstep is
   * rehydrating by pushing the sync out until it is done.
   */
  function syncSignalVars() {
    $timeout.cancel(syncTimeout);
    if (sqStateSynchronizer.isRehydrating) {
      syncTimeout = $timeout(syncSignalVars, 100);
    } else {
      vm.signals = _.chain(sqTrendDataHelper.getAllItems({ itemTypes: [ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR] }))
        .filter(function(signal: any) {
          return _.some(signal.assets, ['id', sqTreemapStore.swap.id]);
        })
        .thru(_.cloneDeep) // cloned because ng-options does not allow use of both "track by" and "select as"
        .value();
    }
  }

  /**
   * Get the element height for use with the bottom panel
   *
   * @return {Number} - height of the treemap component
   */
  function elementHeight() {
    return $element.height();
  }
}
