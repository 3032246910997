import angular from 'angular';
import { ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';
import { sqScatterPlotStore } from '@/scatterPlot/scatterPlot.store';
import { sqScatterPlot } from '@/scatterPlot/scatterPlot.component';
import { sqDensityPlotChart } from '@/scatterPlot/densityPlotChart.directive';

const dependencies = [
  'Sq.Core',
  'Sq.TrendData',
  'Sq.Services.D3',
  'Sq.Services.ChartSelection',
  'Sq.Services.AxisControl'
];

/**
 * @module The scatterPlot module provides the scatterPlot top level view directive, scatterPlotChart, and
 * the regionsSlider directive.
 */
angular
  .module('Sq.ScatterPlot', dependencies)
  .service('sqScatterPlotActions', ScatterPlotActions)
  .store('sqScatterPlotStore', sqScatterPlotStore)
  .component('sqScatterPlot', sqScatterPlot as any)
  .directive('sqDensityPlotChart', sqDensityPlotChart);

export const SCATTER_PLOT_MODES = {
  DISPLAY_RANGE: 'DISPLAY_RANGE',
  CAPSULES: 'CAPSULES'
};

export const SCATTER_PLOT_VIEWS = {
  SCATTER_PLOT: 'SCATTER_PLOT',
  DENSITY_PLOT: 'HEAT_MAP'
};

export const SCATTER_PLOT_ID = 'scatterPlotChart';
export const SCATTER_PLOT_COLORS = {
  LOW: 'rgba(132, 132, 130, 0.7)',
  MID: 'rgba(70, 73, 250, 0.7)',
  HIGH: 'rgba(243, 132, 0, 0.7)'
};
export const SCATTER_PLOT_OPACITY = 0.7;
export const SCATTER_PLOT_REDUCED_OPACITY = 0.3;
export const MINIMAP_OPACITY = 0.5;

export const DENSITY_PLOT_ID = 'heatMapChart';
