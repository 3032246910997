import React from 'react';
import { ImageContent } from '@/hybrid/annotation/ckEditorPlugins/components/ImageContent.molecule';
import { ReactJsonContent } from '@/hybrid/annotation/ckEditorPlugins/components/ReactJsonContent.molecule';

export enum Visualization {
  TABLE = 'table'
}

export const VisualizationContentProps: { [key in Visualization]: any } = {
  [Visualization.TABLE]: {
    displayMetricOnTrend: () => {}
  }
};

export interface ContentVisualizationProps {
  src: string;
  contentId: string;
  noMargin?: boolean;
  border?: boolean;
  style: any;
  target: any;
  loading: boolean;
  onLoad: () => void;
  onMeasurementsReady: (height, width) => void;
  showMenu: () => void;
  errorClass?: string;
  error?: string;
}

export function renderContentVisualization(props: ContentVisualizationProps, react: boolean) {
  return react ? <ReactJsonContent {...props} /> : <ImageContent {...props} />;
}

export function provideVisualizationData(data: any) {
  window.seeqGetVisualizationData = () => Promise.resolve(data);
}
