import _ from 'lodash';
import angular from 'angular';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { StorageService } from '@/services/storage.service';
import { UtilitiesService } from '@/services/utilities.service';
import { SocketService } from '@/services/socket.service';
import { AuthApi } from 'sdk/api/AuthApi';
import { AUTH_CHANGE_BROADCAST_CHANNEL, BroadcastChannelService } from '@/services/broadcastChannel.service';
import { ScreenshotService } from '@/services/screenshot.service';
import { LoggerService } from '@/services/logger.service';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { DatasourceOutputV1 } from 'sdk/model/DatasourceOutputV1';
import { AuthInputV1 } from 'sdk/model/AuthInputV1';
import { SeeqNames } from '@/main/app.constants.seeqnames';

const dependencies = [
  'Sq.Vendor',
  'Sq.AppConstants',
  'Sq.Services.Storage',
  'Sq.Services.Utilities',
  'Sq.Services.Socket',
  'Sq.Services.BroadcastChannel',
  'Sq.Workbook',
  'Sq.Workbench'
];

/**
 * @module Authentication module handles logging in and out
 */
angular
  .module('Sq.Services.Authentication', dependencies)
  .factory('sqAuthentication', sqAuthentication);

export type AuthenticationService = ReturnType<typeof sqAuthentication>;

function sqAuthentication(
  $http: ng.IHttpService,
  $state: ng.ui.IStateService,
  $window: ng.IWindowService,
  $injector: ng.auto.IInjectorService,
  $cookies: ng.cookies.ICookiesService,
  $q: ng.IQService,
  sqSystemConfiguration: SystemConfigurationService,
  sqAuthApi: AuthApi,
  sqStorage: StorageService,
  sqUtilities: UtilitiesService,
  sqLogger: LoggerService,
  sqScreenshot: ScreenshotService,
  sqSocket: SocketService,
  sqBroadcastChannel: BroadcastChannelService) {

  const service = {
    login,
    logout,
    isAuthenticated,
    getCsrfToken,
    addCsrfHeader,
    fetchAuthenticationProviders
  };

  return service;

  /**
   * Logs in to the Seeq application.
   *
   * @param  {AuthInputV1} authCredentials - The authentication credentials
   * @param  {String} authProviderClass - The authProviderClass property of the AuthProvider
   * @param  {String} authProviderId - The authProviderId property of the AuthProvider
   * @return {Promise} A promise that resolves if login was successful
   */
  function login(authCredentials: AuthInputV1, authProviderClass: string, authProviderId: string) {
    if (sqUtilities.headlessRenderMode()) {
      sqScreenshot.notifyError('Authentication Issue: Prevented login request');
      sqLogger.error(`Screenshot mode should not make requests to login`);
      return $q.resolve();
    }

    const sqWorkbenchActions = $injector.get<WorkbenchActions>('sqWorkbenchActions');

    sqWorkbenchActions.generateNewSessionId();

    // See the note in AuthQueriesV1 for why it needs to be in the URL as well as the body
    const params = (authProviderClass && authProviderId) ?
      { authProviderClass, authProviderId, sequenceId: sqUtilities.base64guid() } : {};

    return sqAuthApi.login({ ...authCredentials, authProviderClass, authProviderId }, { params })
      .then((response) => {
        sqBroadcastChannel.emit(AUTH_CHANGE_BROADCAST_CHANNEL);
        return sqWorkbenchActions.setCurrentUser();
      });
  }

  /**
   * Fetches a list of all available Authentication Providers.
   *
   * @returns {Promise} that resolves with a list of available Authentication Providers
   */
  function fetchAuthenticationProviders(): angular.IPromise<DatasourceOutputV1[]> {
    return sqAuthApi.getAuthProviders().then(({ data }) => data.authProviders);
  }

  /**
   }

   /**
   * Logs out and redirect to the login page. It is important that a full redirect be done to ensure that all
   * in-memory state in services and stores is removed.
   *
   * @param {Object} [returnState] - if present then the provided state will be stored in the return parameters
   * @param {Object} [returnParams] - if present then the provided params will be stored in the return parameters
   * @param {Boolean} [userInitiated=true] - if true prevent immediate auto-login
   */
  function logout(returnState, returnParams, userInitiated = true) {
    if (sqUtilities.headlessRenderMode()) {
      sqScreenshot.notifyError('Authentication Issue: Prevented invalidation of provided auth token');
      sqLogger.error(`Screenshot mode should not make requests to logout`);
      return $q.resolve();
    }

    const params = sqUtilities.getReturnToParams(returnState, returnParams);

    return sqAuthApi.logout()
      .finally(() => {
        $cookies.remove(SeeqNames.API.Cookies.Csrf);
        sqSocket.close();
        sqBroadcastChannel.emit(AUTH_CHANGE_BROADCAST_CHANNEL);
        // Prevent immediate auto re-login if the user logs out on a system that is configured to automatically login
        if (sqSystemConfiguration.authAutoLogin && userInitiated) {
          params.autoLogin = false;
        }

        $window.location.href = $state.href('login', params);
      });

  }

  /**
   * Determines if the user is currently authenticated.
   *
   * @return {boolean} True if the user is authenticated
   */
  function isAuthenticated() {
    return !!service.getCsrfToken();
  }

  /**
   * Gets the locally-stored Cross Site Request Forgery token from local storage or from a cookie. Local storage is
   * how it is stored when then token comes back from the login request as a header, cookie is how the screenshot
   * system sets it for each render.
   *
   * @return {String} A CSRF token or undefined if a token doesn't exist
   */
  function getCsrfToken() {
    return $cookies.get(SeeqNames.API.Cookies.Csrf);
  }

  /**
   * Adds the CSRF token to the header. Needed for all requests that require authentication.
   *
   * @param {Object} headers - a key value pair of header names to header values. It will be mutated with an auth
   *                           header if necessary
   */
  function addCsrfHeader(headers) {
    const token = service.getCsrfToken();
    if (!_.isEmpty(token)) {
      headers[SeeqNames.API.Headers.Csrf] = token;
    }
  }
}
