import React from 'react';
import { Modal } from 'react-bootstrap';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { SearchWidget } from '@/hybrid/search/SearchWidget.organism';
import { SEARCH_PANES } from '@/search/search.module';
import { SeeqNames } from '@/main/app.constants.seeqnames';

const selectAssetModalBindings = bindingsDefinition({
  show: prop<boolean>(),
  onClose: prop<() => void>(),
  showHeaderCloseButton: prop.optional<boolean>(),
  searchResultIcons: prop.optional<(item) => JSX.Element>(),
  scopeIds: prop.optional<string[]>(),
  header: prop<JSX.Element | HTMLElement>(),
  /** HTML that should appear above search widget, but below title **/
  body: prop<JSX.Element | HTMLElement | null>(),
  /** HTML that should appear below the search widget **/
  footer: prop<JSX.Element | HTMLElement | null>(),
});

export const SelectAssetModal: SeeqComponent<typeof selectAssetModalBindings> = (props) => {
  const {
    show,
    onClose,
    header,
    body,
    footer,
    showHeaderCloseButton = true,
    searchResultIcons,
    scopeIds
  } = props;
  const SEARCH_TYPES = [SeeqNames.Types.Asset];

  return (
    <Modal show={show} onHide={onClose} animation={false} size="sm" scrollable={true}>
      <Modal.Header closeButton={showHeaderCloseButton}>
        {header}
      </Modal.Header>
      <Modal.Body>
        {body}
        <SearchWidget
          isSelectingAsset={true}
          iconClasses="sq-text-darkest-gray"
          pane={SEARCH_PANES.MODAL}
          searchTypes={SEARCH_TYPES}
          searchResultIcons={searchResultIcons}
          allowAssetReplacement={false}
          showOnlyResults={true}
          scopeIds={scopeIds}
        />
      </Modal.Body>
      {footer}
    </Modal>
  );
};
