import angular from 'angular';
import { IModalInstanceService } from 'angular-ui-bootstrap';

angular.module('Sq.Services.Modal').service('sqModalActions', sqModalActions);

export type ModalActions = ReturnType<typeof sqModalActions>;

function sqModalActions(flux: ng.IFluxService) {
  const service = {
    setOpenModal,
    clearOpenModal
  };

  function setOpenModal(modal: IModalInstanceService) {
    flux.dispatch('MODAL_SET_OPEN_MODAL', modal);
  }

  function clearOpenModal() {
    flux.dispatch('MODAL_SET_OPEN_MODAL', null);
  }

  return service;
}
