import _ from 'lodash';
import angular from 'angular';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { TrendScalarStore } from '@/trendData/trendScalar.store';
import { TrendMetricStore } from '@/trendData/trendMetric.store';
import { TrendTableStore } from '@/trendData/trendTable.store';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { API_TYPES, NUMBER_CONVERSIONS, STRING_UOM } from '@/main/app.constants.ts';
import tableBuilderModule from '@/hybrid/tableBuilder/tableBuilder.module';
import formulaToolModule from  '@/hybrid/tools/formula/formulaTool.module';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { BackwardsCompatibilityColumn } from '@/services/formula.service';
import { SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.module';

export let MAX_SERIES_PIXELS: number;
export let TREND_STORES: [
  TrendSeriesStore,
  TrendScalarStore,
  TrendCapsuleStore,
  TrendCapsuleSetStore,
  TrendTableStore,
  TrendMetricStore
];

const dependencies = [
  'Sq.Core',
  'Sq.AppConstants',
  'Sq.Annotation',
  'Sq.Investigate',
  'Sq.ScatterPlot',
  'Sq.Search',
  'Sq.Treemap',
  'Sq.Services.Logger',
  'Sq.Services.ToolRunner',
  'Sq.Services.Formula',
  'Sq.Services.Utilities',
  'Sq.Services.Notifications',
  'Sq.Services.PendingRequests',
  'Sq.DateTime',
  'Sq.Workbook',
  tableBuilderModule.name,
  formulaToolModule.name
];

angular
  .module('Sq.TrendData', dependencies)
  // Currently set to a very high number so as not to limit data the user receives.
  // However, it is used by system tests to speed things up.
  .constant('MAX_SERIES_PIXELS', 5000)
  // Stores that contain items that can be shown in the details pane
  .factory('TREND_STORES', (
    sqTrendSeriesStore: TrendSeriesStore,
    sqTrendScalarStore: TrendScalarStore,
    sqTrendCapsuleStore: TrendCapsuleStore,
    sqTrendCapsuleSetStore: TrendCapsuleSetStore,
    sqTrendTableStore: TrendTableStore,
    sqTrendMetricStore: TrendMetricStore
  ) => [
    sqTrendSeriesStore,
    sqTrendScalarStore,
    sqTrendCapsuleStore,
    sqTrendCapsuleSetStore,
    sqTrendTableStore,
    sqTrendMetricStore
  ] as typeof TREND_STORES)
  .run(($injector) => {
    MAX_SERIES_PIXELS = $injector.get('MAX_SERIES_PIXELS');
    TREND_STORES = $injector.get('TREND_STORES');
  });

/**
 * Specifies the properties associated with a column in the series or capsule pane.
 */
export interface StatisticColumn extends BackwardsCompatibilityColumn {
  key: string;
  title: string;
  shortTitle: string;
  style: string;
  format?: string;
  stat: string;
  isStringCompatible?: boolean;
  prefix?: any;
  columnSuffix: string;
}

export interface PropertyColumn {
  key: string;
  accessor?: string;
  propertyName?: Function | string;
  title?: string;
  shortTitle: string;
  style: string;
  transformResponse?: (any) => string;
  hideSort?: boolean;
  invalidsFirst?: boolean;
  maxLength?: number;
  format?: string;
}

export const DEFAULT_DISPLAY_RANGE_DURATION_DAYS = 1;
export const DEFAULT_INVESTIGATE_RANGE_DURATION_DAYS = 7;
export const BREAK_SIZE = '0.000';
export const TREND_COLORS = [
  '#4055A3', '#068C45', '#9D248F', '#CE561B', '#00A2DD', '#E1498E', '#996442', '#4B7ABD', '#AC4625', '#D3AA4C',
  '#6263AD', '#F47B37', '#AE6A8B', '#36937E', '#2DBBA7', '#F27295', '#767538', '#542B7C', '#AE752A', '#59BC73',
  '#67B148', '#A1849B', '#115B6B', '#46733C', '#992542', '#B0D035', '#78955F', '#BEB12B', '#7AC0B5', '#7197B7'
];
export const ALPHA_UNSELECTED = 0.1;
export const ALPHA_CAPSULE_UNSELECTED = 0.2;
export const ALPHA_UNCERTAIN = 0.65;
export const STANDARD_COLORS =
  ['#C00000', '#FF0000', '#FFC000', '#FFFF00', '#92D050', '#00B050', '#00B0F0', '#0070C0', '#002060', '#7030A0'];
export const COLORS_PER_ROW = 15;
export const MAX_RECENT_COLORS = 15 * 4; // make this a multiple of COLORS_PER_ROW
export const PREVIEW_HIGHLIGHT_COLOR = '#FBF9D8';

export enum ITEM_TYPES {
  SERIES = 'seriesDisplayType',
  CAPSULE = 'capsuleDisplayType',
  UNCERTAIN_BOUNDED_CAPSULE = 'uncertainBoundedCapsuleDisplayType',
  UNCERTAIN_UNBOUNDED_CAPSULE = 'uncertainUnboundedCapsuleDisplayType',
  ANNOTATION = 'annotationDisplayType',
  CAPSULE_SET = 'capsuleSetDisplayType',
  SCALAR = 'scalarDisplayType',
  TABLE = 'tableDisplayType',
  METRIC = 'metricDisplayType'
}

export enum ITEM_CHILDREN_TYPES {
  ANCILLARY = 'itemChildrenTypeAncillary',
  CAPSULE = 'itemChildrenTypeCapsule',
  SERIES_FROM_CAPSULE = 'itemChildrenTypeSeriesFromCapsule',
  METRIC_DISPLAY = 'itemChildrenTypeMetricDisplay',
  METRIC_THRESHOLD = 'itemChildrenTypeMetricThreshold'
}

export enum DASH_STYLES {
  SOLID = 'Solid',
  SHORT_DASH = 'ShortDash',
  SHORT_DASH_DOT = 'ShortDashDot',
  SHORT_DASH_DOT_DOT = 'ShortDashDotDot',
  DOT = 'Dot',
  DASH = 'Dash',
  LONG_DASH = 'LongDash',
  DASH_DOT = 'DashDot',
  LONG_DASH_DOT = 'LongDashDot',
  LONG_DASH_DOT_DOT = 'LongDashDotDot'
}

export enum SAMPLE_OPTIONS {
  LINE = 'line',
  SAMPLES = 'sample',
  LINE_AND_SAMPLE = 'lineAndSample',
  BAR = 'bar'
}

export enum LABEL_PROPERTIES {
  NAME = 'name',
  ASSET = 'asset',
  ASSET_PATH_LEVELS = 'assetPathLevels',
  UOM = 'unitOfMeasure',
  CUSTOM = 'custom'
}

export enum LABEL_LOCATIONS {
  OFF = 'off',
  LANE = 'lane',
  AXIS = 'axis'
}

export enum Y_AXIS_TYPES {
  LOGARITHMIC = 'logarithmic',
  LINEAR = 'linear'
}

export enum TREND_VIEWS {
  CALENDAR = 'calendar',
  CHAIN = 'chain',
  CAPSULE = 'capsule'
}

export const TREND_TOP_Y_AXIS_ID = 'yAxis-top';
export const TREND_NO_COLOR = 'transparent';
export const TREND_CAPSULE_INFLATION = 0.1;

export enum ITEM_DATA_STATUS {
  INITIALIZING = 'itemDataInitializing',
  PRESENT = 'itemDataPresent',
  LOADING = 'itemDataLoading',
  FAILURE = 'itemDataFailure',
  REDACTED = 'itemDataRedacted',
  CANCELED = 'itemDataCanceled',
  /*
   * This status is related to 'sqTrendStore.hideUnselectedItems'. We attempt to preserve the data of unselected
   * items that we have already fetched, but try to avoid fetching any more data. If the data of an unselected item
   * would have been re-fetched (or fetched for the first time), then its status is set to this value. Items marked
   * with this status will need to have their data re-fetched when they become visible again.
   */
  HIDDEN_FROM_TREND = 'itemDataHiddenFromTrend',
  NOT_REQUIRED = 'itemDataNotRequired', // Cancelled and switched to a view that doesn't require the data
  /* The ABORTED status is set when an the item was accessible but the overall request failed with a FORBIDDEN
   response due to a lack of permissions on another item that was a parameter to the request. By setting the ABORTED
   status, the details pane will not show an error icon on the parameter(s) for which we have permission to access.
   */
  ABORTED = 'itemDataAborted'
}

export const TREND_BUFFER_FACTOR = 0.035;
export const TREND_BUFFER_FACTOR_STRING = 0.3;
export const TREND_BUFFER_FACTOR_LABEL = 0.026;
export const PROFILE_SEARCH_SIMILARITY = 98.0;
export const PROFILE_SEARCH_NORMALIZE_AMPLITUDE = true;
export const PROFILE_SEARCH_NORMALIZE_LOCATION = true;
export const CHART_CAPSULES_LIMIT = 500;
export const SERIES_PANEL_REQUIRED_TREND_COLUMNS = [
  'name',
  'valueUnitOfMeasure'
];
export const SERIES_PANEL_EXTRA_TREND_COLUMNS = [
  'asset', 'description', 'datasourceName', 'fullpath'
];
export const SERIES_PANEL_EXTRA_TREND_CUSTOMIZATION_COLUMNS = [
  'lane'
];
export const CAPSULE_TIME_COLUMNS = [{
  key: 'group',
  title: 'TOOLBAR.GROUP',
  shortTitle: 'TOOLBAR.GROUP',
  style: 'group',
  onClass: 'fa fa-group',
  offClass: 'fa fa-group'
}];
export const SIGNAL_CUSTOMIZATIONS = [
  'axisAlign', 'axisAutoScale', 'lane', 'rightAxis',
  'dashStyle', 'lineWidth', 'autoDisabled', 'axisVisibility',
  'yAxisConfig', 'yAxisMin', 'yAxisMax', 'yAxisType', 'sampleDisplayOption', 'group'
];
export const CAPSULE_SET_CUSTOMIZATIONS = [
  'group'
];
export const BAR_CHART_LINE_WIDTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 40, 50];
export const PROPERTIES_COLUMN_PREFIX = 'properties.';
export const PROPERTIES_UOM_COLUMN_PREFIX = 'propertiesUOM.';
export const INTERNAL_CAPSULE_PROPERTY_PREFIX = '_SeeqInternal_';

const integerNumberFormat = '#,##0';

export const TREND_SIGNAL_STATS: StatisticColumn[] = [{
  key: 'statistics.maximum',
  title: 'STATISTICS.MAXIMUM.LONG',
  shortTitle: 'STATISTICS.MAXIMUM.SHORT',
  style: 'decimal',
  stat: 'maxValue()',
  invalidsFirst: true,
  columnSuffix: 'Max'
}, {
  key: 'statistics.minimum',
  title: 'STATISTICS.MINIMUM.LONG',
  shortTitle: 'STATISTICS.MINIMUM.SHORT',
  style: 'decimal',
  stat: 'minValue()',
  invalidsFirst: true,
  columnSuffix: 'Min'
}, {
  key: 'statistics.range',
  title: 'STATISTICS.RANGE.LONG',
  shortTitle: 'STATISTICS.RANGE.SHORT',
  style: 'decimal',
  isStringCompatible: false,
  stat: 'range()',
  invalidsFirst: true,
  columnSuffix: 'Range'
}, {
  key: 'statistics.average',
  title: 'STATISTICS.AVERAGE.LONG',
  shortTitle: 'STATISTICS.AVERAGE.SHORT',
  style: 'decimal',
  stat: 'average()',
  invalidsFirst: true,
  columnSuffix: 'Average'
}, {
  key: 'statistics.stdDev',
  title: 'STATISTICS.STANDARD_DEVIATION.LONG',
  shortTitle: 'STATISTICS.STANDARD_DEVIATION.SHORT',
  style: 'decimal',
  stat: 'stdDev()',
  invalidsFirst: true,
  columnSuffix: 'StdDev'
}, {
  key: 'statistics.percentGood',
  title: 'STATISTICS.PERCENT_GOOD.LONG',
  shortTitle: 'STATISTICS.PERCENT_GOOD.SHORT',
  style: 'percent',
  isStringCompatible: true,
  stat: 'percentGood()',
  invalidsFirst: true,
  columnSuffix: 'PercentGood'
}, {
  key: 'statistics.count',
  title: 'STATISTICS.COUNT.LONG',
  shortTitle: 'STATISTICS.COUNT.SHORT',
  style: 'decimal',
  format: integerNumberFormat,
  isStringCompatible: true,
  stat: 'count()',
  invalidsFirst: true,
  columnSuffix: 'Count'
}, {
  key: 'statistics.endValue',
  title: 'STATISTICS.LAST_VALUE.LONG',
  shortTitle: 'STATISTICS.LAST_VALUE.SHORT',
  style: 'decimal',
  isStringCompatible: true,
  stat: 'endValue(true)',
  invalidsFirst: true,
  columnSuffix: 'EndValue'
}, {
  key: 'statistics.delta',
  title: 'STATISTICS.DELTA.LONG',
  shortTitle: 'STATISTICS.DELTA.SHORT',
  style: 'decimal',
  isStringCompatible: false,
  stat: 'delta()',
  invalidsFirst: true,
  columnSuffix: 'Delta'
}];
export const TREND_CONDITION_STATS: StatisticColumn[] = [{
  key: 'statistics.count',
  title: 'STATISTICS.COUNT.LONG',
  shortTitle: 'STATISTICS.COUNT.SHORT',
  style: 'decimal',
  format: integerNumberFormat,
  columnSuffix: 'Count',
  invalidsFirst: true,
  isStringCompatible: true,
  stat: 'count()'
}];

export const TREND_METRIC_STATS: StatisticColumn[] = _.map(SAMPLE_FROM_SCALARS.VALUE_METHODS, (valueMethod) => {
  const isStringCompatible = _.includes(valueMethod.input, 'string');
  let prefix;
  if (valueMethod.stat.includes('$')) {
    prefix = valueMethod.stat.slice(0, valueMethod.stat.indexOf('(') + 1);
  }
  const getColumnSuffix: (string) => string = _.cond([
    [input => input === 'valueStart', () => 'StartValue'],
    [input => input === 'valueEnd', () => 'EndValue'],
    [_.stubTrue, input => `${input[0].toUpperCase()}${input.slice(1)}`]
  ]);
  return {
    key: `statistics.${valueMethod.key}`,
    stat: valueMethod.stat,
    title: valueMethod.title,
    shortTitle: valueMethod.title,
    isStringCompatible,
    style: 'decimal',
    invalidsFirst: true,
    prefix,
    columnSuffix: getColumnSuffix(valueMethod.key)
  };
});

export enum TREND_PANELS {
  BOTTOM = 'BOTTOM',
  CAPSULES = 'CAPSULES',
  CHART = 'CHART',
  CHART_CAPSULES = 'CHART_CAPSULES',
  SERIES = 'SERIES',
  INVESTIGATE_ITEMS = 'INVESTIGATE_ITEMS'
}

export const TREND_PANELS_SORT = {
  CAPSULES: { sortBy: 'startTime', sortAsc: true },
  CAPSULE_SERIES: { sortBy: 'startTime', sortAsc: true },
  SERIES: { sortBy: 'lane', sortAsc: true },
  INVESTIGATE_ITEMS: { sortBy: 'name', sortAsc: true }
};
export const AUTO_UPDATE = {
  MIN_INTERVAL: 5000,
  DEFAULT_INTERVAL: { value: 1, units: 'min' },
  MODES: {
    AUTO: 'AUTO',
    MANUAL: 'MANUAL',
    OFF: 'OFF'
  }
};
export const PREVIEW_ID = 'preview';
export const PREVIEW_PREFIX = 'preview-';
export const FORMULA_FRAGMENT_TYPE = {
  DISPLAY_RANGE: 'displayRange'
};
export const SHADED_AREA_DIRECTION = {
  UP: 'up',
  DOWN: 'down'
};
export const SHADED_AREA_CURSORS = {
  UPPER: 'upper',
  LOWER: 'lower',
  BOTH: 'both',
  NONE: 'none'
};
export const API_TYPES_TO_ITEM_TYPES = _.fromPairs(
  [[API_TYPES.STORED_SIGNAL, ITEM_TYPES.SERIES],
    [API_TYPES.CALCULATED_SIGNAL, ITEM_TYPES.SERIES],
    [API_TYPES.STORED_CONDITION, ITEM_TYPES.CAPSULE_SET],
    [API_TYPES.CALCULATED_CONDITION, ITEM_TYPES.CAPSULE_SET],
    [API_TYPES.CALCULATED_SCALAR, ITEM_TYPES.SCALAR],
    [API_TYPES.TABLE, ITEM_TYPES.TABLE],
    [API_TYPES.THRESHOLD_METRIC, ITEM_TYPES.METRIC]]);
export const ITEM_TYPES_TO_API_TYPES = _.fromPairs(
  [[ITEM_TYPES.SERIES, API_TYPES.STORED_SIGNAL],
    [ITEM_TYPES.CAPSULE, API_TYPES.CAPSULE],
    [ITEM_TYPES.CAPSULE_SET, API_TYPES.STORED_CONDITION],
    [ITEM_TYPES.SCALAR, API_TYPES.CALCULATED_SCALAR],
    [ITEM_TYPES.METRIC, API_TYPES.THRESHOLD_METRIC]]);
// Items that can be passed into an odata or excel export
export const EXPORTABLE_ITEM_TYPES = [ITEM_TYPES.SERIES, ITEM_TYPES.CAPSULE_SET];
// Items that can be displayed in the details pane
export const DETAILS_PANE_ITEM_TYPES = [ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR, ITEM_TYPES.CAPSULE_SET, ITEM_TYPES.TABLE, ITEM_TYPES.METRIC];
export const TREND_COLUMNS: PropertyColumn[] = [
  {
    key: 'valueUnitOfMeasure',
    accessor: 'properties',
    propertyName: (itemType: ITEM_TYPES) => itemType === ITEM_TYPES.SCALAR
      ? SeeqNames.Properties.Uom : SeeqNames.Properties.ValueUom,
    title: 'VALUE_UOM',
    shortTitle: 'VALUE_UOM',
    style: 'string',
    transformResponse: (val) => {
      const uom = _.find(val,
        // Scalars have the 'Unit Of Measure' property instead of 'Value Unit Of Measure'
        ({ name }) => name === SeeqNames.Properties.ValueUom || name === SeeqNames.Properties.Uom)?.value;
      return uom === STRING_UOM ? '' : uom;
    }
  }, {
    key: 'name',
    accessor: 'name',
    propertyName: SeeqNames.Properties.Name,
    style: 'string',
    title: 'NAME',
    shortTitle: 'NAME'
  }, {
    key: 'color',
    style: 'color',
    shortTitle: 'TOOLBAR.COLOR'
  }, {
    key: 'asset',
    accessor: 'assets',
    style: 'assets',
    hideSort: true,
    title: 'ASSET_HEADER',
    shortTitle: 'ASSET_HEADER',
    transformResponse: val => _(val).map('name').join(', ')
  }, {
    key: 'fullpath',
    accessor: 'assets',
    hideSort: true,
    style: 'fullpath',
    title: 'FULL_PATH',
    shortTitle: 'FULL_PATH',
    transformResponse: val => _(val).map('formattedName').join(', ')
  }, {
    key: 'description',
    accessor: 'description',
    propertyName: SeeqNames.Properties.Description,
    style: 'string',
    title: 'DESCRIPTION',
    shortTitle: 'DESCRIPTION'
  }, {
    key: 'startTime',
    accessor: 'formattedStartTime',
    style: 'string',
    title: 'START',
    shortTitle: 'START',
    propertyName: SeeqNames.CapsuleProperties.Start,
    invalidsFirst: true,
    transformResponse: val => _.isFinite(val) ? val / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND : val
  }, {
    key: 'endTime',
    accessor: 'formattedEndTime',
    style: 'string',
    title: 'END',
    shortTitle: 'END',
    propertyName: SeeqNames.CapsuleProperties.End,
    transformResponse: val => _.isFinite(val) ? val / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND : val
  }, {
    key: 'duration',
    accessor: 'formattedDuration',
    style: 'string',
    title: 'DURATION',
    shortTitle: 'DURATION',
    propertyName: SeeqNames.CapsuleProperties.Duration,
    invalidsFirst: true
  }, {
    key: 'similarity',
    style: 'percent',
    title: 'PROFILE_SEARCH.SIMILARITY',
    shortTitle: 'PROFILE_SEARCH.SIMILARITY',
    maxLength: 5,
    propertyName: SeeqNames.CapsuleProperties.Similarity,
    invalidsFirst: true
  }, {
    key: 'lane',
    title: 'TOOLBAR.LANE',
    shortTitle: 'TOOLBAR.LANE',
    style: 'decimal',
    format: integerNumberFormat
  }, {
    key: 'datasourceName',
    accessor: 'datasource.name',
    propertyName: SeeqNames.Properties.DatasourceName,
    title: 'TOOLBAR.DATASOURCE_NAME',
    shortTitle: 'TOOLBAR.DATASOURCE_NAME',
    style: 'string'
  }, {
    key: 'metricValue',
    accessor: 'metricValue',
    title: 'TOOLBAR.METRIC_VALUE',
    shortTitle: 'TOOLBAR.METRIC_VALUE',
    style: 'metric'
  }
];
export const CAPSULE_PANEL_TREND_COLUMNS = _.filter(TREND_COLUMNS,
  ({ key }) => _.includes(['startTime', 'endTime', 'duration', 'similarity'], key));
export const CONDITION_TABLE_ALLOWED_COLUMNS = _.filter(TREND_COLUMNS,
  ({ key }) => _.includes(['name', 'valueUnitOfMeasure'], key));
export const TREND_COLOR_COLUMN = _.find(TREND_COLUMNS, ['key', 'color']);

type columns_and_stats_keys =
  'valueUnitOfMeasure'
  | 'name'
  | 'color'
  | 'asset'
  | 'fullpath'
  | 'description'
  | 'startTime'
  | 'endTime'
  | 'duration'
  | 'similarity'
  | 'lane'
  | 'datasourceName'
  | 'metricValue'
  | 'statistics.maximum'
  | 'statistics.minimum'
  | 'statistics.range'
  | 'statistics.average'
  | 'statistics.stdDev'
  | 'statistics.percentGood'
  | 'statistics.count'
  | 'statistics.endValue'
  | 'statistics.delta';
export const COLUMNS_AND_STATS = _.chain(TREND_COLUMNS as any)
  .concat(TREND_SIGNAL_STATS)
  .concat(TREND_CONDITION_STATS)
  .transform((columns, column) => {
    columns[column.key] = column;
  }, {})
  .value() as { [P in columns_and_stats_keys]: StatisticColumn | PropertyColumn };

// Special properties which are allowed on items based on their type and may be copied to child items
export const ITEM_CUSTOMIZATIONS = {
  [ITEM_TYPES.SERIES]: SIGNAL_CUSTOMIZATIONS,
  [ITEM_TYPES.SCALAR]: _.without(SIGNAL_CUSTOMIZATIONS, 'sampleDisplayOption'),
  [ITEM_TYPES.CAPSULE]: [],
  [ITEM_TYPES.CAPSULE_SET]: CAPSULE_SET_CUSTOMIZATIONS,
  [ITEM_TYPES.TABLE]: ['stack'],
  [ITEM_TYPES.METRIC]: SIGNAL_CUSTOMIZATIONS
};
export const ALL_CUSTOMIZATIONS_PROPERTIES = _.chain(ITEM_CUSTOMIZATIONS).values().flatten().uniq().value();
export const PROPERTIES = _.concat(['selected', 'color', 'formatOptions'], ALL_CUSTOMIZATIONS_PROPERTIES);
// Special properties of a child which are copied from the parent when set on the parent item
export const CHILD_CLONED_PROPERTIES = {
  [ITEM_CHILDREN_TYPES.ANCILLARY]: _.without(PROPERTIES, 'dashStyle', 'axisVisibility', 'lineWidth',
    'sampleDisplayOption', 'formatOptions'),
  [ITEM_CHILDREN_TYPES.CAPSULE]: ['color'],
  [ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE]: PROPERTIES,
  [ITEM_CHILDREN_TYPES.METRIC_DISPLAY]: PROPERTIES,
  [ITEM_CHILDREN_TYPES.METRIC_THRESHOLD]: _.without(PROPERTIES, 'color', 'dashStyle', 'axisVisibility', 'lineWidth',
    'sampleDisplayOption')
};

// CUSTOMIZATIONS_COLUMNS support small bits of logic. For options you can specify a `_.iteratee` that will be
// evaluated against the item to determine if it should be displayed for that item. This enables showing the dashed
// line style as only an option for scalars hiding the bar option for string series, and disabling the min and
// max controls when auto mode is disabled.
//
// `COLUMNS[].disabledIf` can be used to show the column disabled when the condition is true
// `COLUMNS[].disabledIfNot` can be used to only show the column as enabled unless the condition is met
// `COLUMNS[].options[].if` can be used to only include the dropdown option only when the condition is true
// `COLUMNS[].options[].ifNot` can be used to exclude the dropdown option when the condition is true
//
// The series panel takes care of calling the `_.iteratee`s as necessary
export const CUSTOMIZATIONS_COLUMNS = [{
  key: 'stack',
  shortTitle: 'TOOLBAR.STACK',
  style: 'icon',
  onClass: 'fa fa-check-square',
  offClass: 'fa fa-square-o',
  hideSort: true,
  doToggle: true
}, {
  key: 'sampleDisplayOption',
  shortTitle: 'TOOLBAR.SAMPLES',
  style: 'iconSelect',
  options: [{
    value: SAMPLE_OPTIONS.LINE,
    iconClass: 'fc-solid'
  }, {
    value: SAMPLE_OPTIONS.LINE_AND_SAMPLE,
    iconClass: 'fc-samples-and-line'
  }, {
    value: SAMPLE_OPTIONS.SAMPLES,
    iconClass: 'fc-samples-only'
  }, {
    value: SAMPLE_OPTIONS.BAR,
    iconClass: 'fc-line-bars',
    ifNot: 'isStringSeries'
  }]
}, {
  key: 'dashStyle',
  shortTitle: 'TOOLBAR.LINE',
  style: 'iconSelect',
  disabledIfNot: ['sampleDisplayOption', SAMPLE_OPTIONS.LINE],
  options: _.chain(DASH_STYLES)
    .values()
    .map(value => ({
      value,
      iconClass: `fc-${_.kebabCase(value)}`,
      if: value === DASH_STYLES.DASH ? ['itemType', ITEM_TYPES.SCALAR] : undefined
    }))
    .value()
}, {
  key: 'lineWidth',
  shortTitle: 'TOOLBAR.WIDTH',
  style: 'select',
  disabledIf: item => item.itemType !== ITEM_TYPES.SCALAR && !_.includes([SAMPLE_OPTIONS.LINE, SAMPLE_OPTIONS.BAR],
    item.sampleDisplayOption),
  options: [..._.map(BAR_CHART_LINE_WIDTHS,
    width => ({ value: width, if: ['sampleDisplayOption', SAMPLE_OPTIONS.BAR] })), ..._.map(_.range(5, 105, 5),
    width => ({ value: width / 10, ifNot: ['sampleDisplayOption', SAMPLE_OPTIONS.BAR] }))]
}, {
  key: 'lane',
  shortTitle: 'TOOLBAR.LANE',
  tooltip: 'TOOLBAR.LANE_TOOLTIP',
  style: 'select',
  options: [] // synced from sqTrendStore.lanes in the controller
}, {
  key: 'axisAlign',
  shortTitle: 'TOOLBAR.AXIS',
  tooltip: 'TOOLBAR.AXIS_TOOLTIP',
  style: 'select',
  options: [] // synced from sqTrendStore.alignments in the controller
}, {
  key: 'rightAxis',
  shortTitle: 'TOOLBAR.ALIGN',
  tooltip: 'TOOLBAR.AXIS_RIGHT',
  style: 'stringSelect',
  options: [{ value: false, display: 'AXIS.LEFT' }, { value: true, display: 'AXIS.RIGHT' }],
  applyToAxis: true
}, {
  key: 'axisVisibility',
  shortTitle: 'TOOLBAR.SHOW',
  tooltip: 'TOOLBAR.SHOW_TOOLTIP',
  style: 'icon',
  onClass: 'fa fa-check-square',
  offClass: 'fa fa-square-o',
  hideSort: true,
  doToggle: true
}, {
  key: 'axisAutoScale',
  shortTitle: 'TOOLBAR.AUTOSCALE',
  tooltip: 'TOOLBAR.AUTOSCALE_TOOLTIP',
  style: 'icon',
  onClass: 'fa fa-check-square',
  offClass: 'fa fa-square-o',
  hideSort: true,
  doToggle: true,
  offFn: '',
  onFn: '',
  applyToAxis: true
}, {
  key: 'yAxisMin',
  shortTitle: 'TOOLBAR.MIN',
  tooltip: 'TOOLBAR.MIN_TOOLTIP',
  style: 'input',
  applyToAxis: true,
  disabledIf: 'axisAutoScale'
}, {
  key: 'yAxisMax',
  shortTitle: 'TOOLBAR.MAX',
  tooltip: 'TOOLBAR.MAX_TOOLTIP',
  style: 'input',
  applyToAxis: true,
  disabledIf: 'axisAutoScale'
}, {
  key: 'yAxisType',
  shortTitle: 'TOOLBAR.AXIS_TYPE',
  tooltip: 'TOOLBAR.LOGARITHMIC_TOOLTIP',
  style: 'stringSelect',
  applyToAxis: true,
  options: [
    { value: Y_AXIS_TYPES.LINEAR, display: 'AXIS.LINEAR' },
    { value: Y_AXIS_TYPES.LOGARITHMIC, display: 'AXIS.LOGARITHMIC' }
  ]
}];
export const SHADED_AREA_TYPES = [ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR];

export const ENUM_REGEX = /ENUM{{(\d+)\|(.+?)}}/;
