import angular from 'angular';
import { TrackService } from '@/track/track.service';

const dependencies = [
  'Sq.Vendor',
  'Sq.Services.BuildConstants',
  'Sq.AppConstants',
  'Sq.Core.StateSynchronizer'
];

angular.module('Sq.Track', dependencies)
  .run(['sqTrack', setTrackService]);

let trackService: TrackService;

/**
 * Export trackService so it can be easily be used anywhere needed within react (e.g. hooks where we can't inject it)
 */
export { trackService };

function setTrackService(sqTrackService) {
  trackService = sqTrackService;
}

