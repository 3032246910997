import angular from 'angular';

const dependencies = [
  'Sq.Core',
  'Sq.AppConstants',
  'Sq.Services.Notifications',
  'Sq.Workbook',
  'Sq.Directives.DoubleClick',
  'Sq.Services.Utilities',
  'Sq.Services.ACLService',
  'Sq.Services.Authorization',
  'Sq.LicenseManagement',
  'Sq.Worksheets',
  'Sq.Workbench',
  'Sq.Vendor',
  'Sq.Services.Modal'
];

/**
 * The Sq.Worksteps module contains all functionality related to worksteps
 */
angular.module('Sq.Worksteps', dependencies);

// Requests to get worksteps will be rejected with this value when there are no worksteps to get
export const WORKSHEET_WITH_NO_WORKSTEPS = 'WORKSHEET_WITH_NO_WORKSTEPS';
// Requests to push worksteps will be rejected with this value when those requests are disabled for our viewing mode
export const WORKSTEP_PUSH_DISABLED = 'WORKSTEP_PUSH_DISABLED';
