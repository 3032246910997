import React from 'react';
import classNames from 'classnames';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

const searchResultsBindings = bindingsDefinition({
  isLoading: prop<boolean>(),
  onClear: prop<any>(),
  resultsString: prop<any>(),
  extraClassNames: prop.optional<string>()
});

export const SearchResults: SeeqComponent<typeof searchResultsBindings> = (props) => {
  const { isLoading, onClear, resultsString, extraClassNames } = props;
  const { t } = useTranslation();

  return (
    <div className={classNames("width-maximum", "flexRowContainer", extraClassNames)}>
      <h4>{t('HOME_SCREEN.SEARCH_RESULTS')}</h4>
      {!isLoading &&
      <div className='specActiveFilters text-italic mb10'>
        {resultsString}
        <span className='specClearAllButton sq-text-primary cursorPointer text-interactive text-underline-onhover ml10'
              onClick={onClear}>
              <span>{t('HOME_SCREEN.CLEAR_ALL')} &times;</span>
          </span>
      </div>}
    </div>
  );
};
