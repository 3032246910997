import _ from 'lodash';
import angular from 'angular';
import { DISPLAY_MODE } from '@/main/app.constants';
import { INITIALIZE_MODE, PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';
import { InvestigateToolType, TREND_TOOLS } from '@/investigate/investigate.module';

angular.module('Sq.Investigate').store('sqInvestigateStore', sqInvestigateStore);

export type InvestigateStore = ReturnType<typeof sqInvestigateStore>['exports'];

// Inject INVESTIGATE_TOOLS because if imported it will not have been set when initialize() is called
function sqInvestigateStore(INVESTIGATE_TOOLS, $translate) {
  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.WORKSHEET,

    initialize(initializeMode) {
      const saveState = this.state && initializeMode !== INITIALIZE_MODE.FORCE;
      this.state = this.immutable({
        item: {},
        activeTool: TREND_TOOLS.OVERVIEW,
        toolFilter: '',
        displayMode: DISPLAY_MODE.NEW,
        derivedDataPanelOpen: true,
        // Add-on tools need to maintain their state across soft and hard initialization so reloading previous
        // worksteps does not clear add-on tools. Add-on tools are set whenever a new Analysis worksheet is
        // loaded so they're guaranteed to be updated for the current user whenever the user displays a worksheet.
        addOnTools: this.state && this.state.get('addOnTools') || [],
        // Save asynchronously loaded data to prevent fast follow flicker
        derivedDataTree: saveState ? this.state.get('derivedDataTree') : null,
        investigateTools: this.translateTools(),
        allTools: this.monkey(['addOnTools'], ['investigateTools'],
          addOnTools => this.state && this.state.get('investigateTools').concat(addOnTools)),
        filteredTools: this.monkey(['item'], ['toolFilter'], ['activeTool'], ['addOnTools'], ['allTools'],
          ['investigateTools'],
          (item, toolFilter, activeTool, addOnTools, allTools) => {
            const filterByParent = tool => tool.parentId === activeTool;
            const filterByText = tool => _.includes(_.toLower(tool.name), _.toLower(toolFilter)) ||
              _.includes(_.toLower(tool.description), _.toLower(toolFilter));
            const toolFilterFunc = _.isEmpty(toolFilter) ? filterByParent : filterByText;
            const isAddOnToolsGroupWithNoAddOnTools =
              tool => tool.id === TREND_TOOLS.ADDON_TOOLS_GROUP && addOnTools.length === 0;

            return _.chain(allTools)
              .reject('hideFromSearch')
              .filter(toolFilterFunc)
              .reject(isAddOnToolsGroupWithNoAddOnTools)
              .value();
          }, { immutable: false }),

        breadcrumbs: this.monkey(['activeTool'], ['toolFilter'], ['allTools'], (activeTool, toolFilter, allTools) => {
          const breadcrumbs = [];
          let crumb = _.find(allTools, ['id', activeTool]) as any;

          if (toolFilter) {
            return [];
          }

          while (crumb) {
            breadcrumbs.push(crumb);
            crumb = _.find(allTools, ['id', crumb.parentId]);
          }

          return _.reverse(breadcrumbs);
        }),
        isCapsulePickingMode: this.monkey(['activeTool'],
          activeTool => _.includes([TREND_TOOLS.CUSTOM_CONDITION], activeTool)
        ),
        activeToolName: this.monkey(['activeTool'], ['allTools'], (activeTool, allTools) => {
            return _.get(_.find(allTools, ['id', activeTool]), 'name');
          }
        )
      }, { immutable: false });
    },

    exports: {
      get item() {
        return this.state.get('item');
      },

      get activeTool() {
        return this.state.get('activeTool');
      },

      get activeToolName() {
        return this.state.get('activeToolName');
      },

      get filteredTools() {
        return this.state.get('filteredTools');
      },

      get breadcrumbs() {
        return this.state.get('breadcrumbs');
      },

      get toolFilter() {
        return this.state.get('toolFilter');
      },

      get displayMode() {
        return this.state.get('displayMode');
      },

      get derivedDataPanelOpen() {
        return this.state.get('derivedDataPanelOpen');
      },

      get derivedDataTree() {
        return this.state.get('derivedDataTree');
      },

      get isCapsulePickingMode() {
        return this.state.get('isCapsulePickingMode');
      },

      get allTools() {
        return this.state.get('allTools');
      },

      get addOnTools() {
        return this.state.get('addOnTools');
      }
    },

    dehydrate() {
      return _.omit(this.state.serialize(), ['toolFilter', 'derivedDataTree', 'addOnTools', 'investigateTools']);
    },

    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      INVESTIGATE_SET_ITEM: 'setItem',
      INVESTIGATE_CLEAR_ITEM: 'clearItem',
      INVESTIGATE_SET_ACTIVE_TOOL: 'setActiveTool',
      INVESTIGATE_SET_TOOL_FILTER: 'setToolFilter',
      INVESTIGATE_SET_DISPLAY_MODE: 'setDisplayMode',
      INVESTIGATE_SET_DERIVED_DATA_PANEL_OPEN: 'setDerivedDataPanelOpen',
      INVESTIGATE_SET_DERIVED_DATA_TREE: 'setDerivedDataTree',
      INVESTIGATE_SET_ADDON_TOOLS: 'setAddOnTools',
      TREND_SET_COLOR: 'updateDerivedDataTreeColor',
      INVESTIGATE_TRIGGER_INVESTIGATE_TOOL_TRANSLATION: 'triggerInvestigateToolTranslation'
    },

    /**
     * Sets the item to be investigated by using one or more of the tools.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Item} payload.item - The item to be investigated
     * @param {String} payload.item.id - The ID of the item
     * @param {String} payload.item.name - The name of the item
     * @param {String} payload.item.itemType - One of ITEM_TYPES
     * @param {String} payload.item.iconClass - The icon class
     * @param {Boolean} payload.item.isArchived - Archived state of the item
     * @param {String} payload.item.calculationType - The type of calculation, one of TREND_TOOLS
     * @param {Object} [payload.props] - Additional properties to associate with the item. Useful for passing along
     *   additional information to the panel that loads the item.
     */
    setItem(payload) {
      this.state.set('item', _.assign({}, payload.props,
        _.pick(payload.item, ['id', 'name', 'itemType', 'iconClass', 'calculationType', 'isArchived'])));
    },

    /**
     * Clears the item being investigated.
     */
    clearItem() {
      this.state.set('item', {});
    },

    /**
     * Sets the active tool and clears the tool filter.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.tool - Tool to make active, one of TREND_TOOLS.
     */
    setActiveTool(payload) {
      this.state.set('activeTool', payload.tool);
      this.state.set('toolFilter', '');
    },

    /**
     * Sets the tool filter which is used to filter the list of tools.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.filter - The text to use to filter the available tools and categories.
     */
    setToolFilter(payload) {
      this.state.set('toolFilter', payload.filter);
      this.clearItem();
      this.state.commit(); // called via ng-change
    },

    /**
     * Sets the display mode.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.mode - Mode, one of DISPLAY_MODE.
     */
    setDisplayMode(payload) {
      this.state.set('displayMode', payload.mode);
    },

    /**
     * Sets the open state of the derived data panel.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.derivedDataPanelOpen - true if the panel is open, false if closed
     */
    setDerivedDataPanelOpen(payload) {
      this.state.set('derivedDataPanelOpen', payload.derivedDataPanelOpen);
    },

    /**
     * Sets the derived data tree. The tree is not persisted as part of the workstep.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} payload.derivedDataTree - Array of items that form the derived data tree
     */
    setDerivedDataTree(payload) {
      this.state.set('derivedDataTree', payload.derivedDataTree);
    },

    /**
     * Sets the array of add-on tools and validates the active tool. Must be called after rehydrate.
     */
    setAddOnTools(payload: { addOnTools: InvestigateToolType[] }) {
      this.state.set('addOnTools', payload.addOnTools);

      // Since tools can change a lot, only keep the persisted active tool if it is still valid
      const isActiveToolInvalid = !_.chain(this.state.get('allTools'))
        .map('id')
        .includes(this.state.get('activeTool'))
        .value();

      if (isActiveToolInvalid) {
        this.state.set('activeTool', TREND_TOOLS.OVERVIEW);
      }
    },

    /**
     * Reflects color changes to items in the derived data tree
     *
     * @param {string} id - id representing the item
     * @param {string} color - hex code representing the color
     */
    updateDerivedDataTreeColor({ id, color }) {
      if (_.find(this.state.get('derivedDataTree'), { id })) {
        const dependencyTree = [...this.state.get('derivedDataTree')];
        const entry: any = _.find(dependencyTree, { id });
        entry.color = color;
        this.state.set('derivedDataTree', dependencyTree);
      }
    },

    /**
     * Utility function that translates the investigate tools headline and description.
     */
    translateTools() {
      return _.map(INVESTIGATE_TOOLS,
        tool => _.assign({}, tool, {
          name: $translate.instant(tool.nameTranslationKey),
          description: $translate.instant(tool.descriptionTranslationKey)
        }));
    },

    /**
     * Translates the investigate tools. Triggered after a locale change.
     */
    triggerInvestigateToolTranslation() {
      const translatedTools = this.translateTools();
      this.state.set('investigateTools', translatedTools);
    }
  };

  return store;
}
