import React from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { DateTimeService } from '@/datetime/dateTime.service';
import { SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.module';
import { ITEM_TYPES } from '@/trendData/trendData.module';
import { DURATION_TIME_UNITS, STANDARD_TIME_UNIT_PATH } from '@/main/app.constants';
import { FormControl } from 'react-bootstrap';
import { UtilitiesService } from '@/services/utilities.service';

const statisticSelectorBindings = bindingsDefinition({
  name: prop<string>(),
  item: prop<any>(),
  isRequired: prop<boolean>(),
  outputType: prop<string[]>(),
  statistic: prop<StoredStatistic>(),
  appendToBody: prop.optional<boolean>(),
  onSelectStatistic: prop<(statistic: StoredStatistic) => void>(),
  onValidate: prop.optional<(isValid: boolean) => void>(),
  sqDateTime: injected<DateTimeService>(),
  sqUtilities: injected<UtilitiesService>()
});

export type StoredStatistic = {
  key: string | null;
  timeUnits: string | null;
  percentile: number | null;
};
type StatChoice = {
  key: string,
  formula: string,
  stat: string,
  title: string,
  description: string,
  input: string[],
  output: string[],
  outputInTimeUnits: boolean
};
type DurationTimeUnit = { translationKey: string, unit: string[] };

export const StatisticSelector: SeeqComponent<typeof statisticSelectorBindings> = (props) => {
  const {
    name,
    isRequired,
    outputType,
    item,
    statistic,
    onSelectStatistic,
    onValidate,
    appendToBody = false
  } = props;
  const { sqDateTime, sqUtilities } = useInjectedBindings(statisticSelectorBindings);
  const { t } = useTranslation();

  let selectedStatistic: StatChoice | null = null;
  let statChoices: StatChoice[] = [];
  let timeUnit: DurationTimeUnit | null = null;
  let isTimeUnitConversionDisplayed = false;
  let isPercentileDisplayed = false;
  const menuPortalTarget = appendToBody ? document.body : null;

  if (item) {
    let statChoicesChain = _.chain(SAMPLE_FROM_SCALARS.VALUE_METHODS)
      .sortBy('key')
      .filter((method: any) => _.intersection(method.output, outputType).length > 0);
    if (item.itemType === ITEM_TYPES.CAPSULE_SET) {
      // Conditions can only output at start/middle/end
      statChoicesChain = statChoicesChain.filter(method => _.includes(method.input, 'capsule'));
    } else if (item.itemType === ITEM_TYPES.SERIES) {
      const inputType = sqUtilities.isStringSeries(item) ? 'string' : 'sample';
      statChoicesChain = statChoicesChain.filter(method => _.includes(method.input, inputType));
    }

    statChoices = statChoicesChain.value();
    timeUnit = sqDateTime.getDurationTimeUnit(statistic?.timeUnits);
    selectedStatistic = _.find(statChoices, ['key', statistic?.key]) || null; // null is what Select uses for unselected
    isTimeUnitConversionDisplayed = _.get(selectedStatistic, 'outputInTimeUnits', false);
    isPercentileDisplayed = _.get(selectedStatistic, 'needsPercentile', false);

    // Clear the selected statistic if it is not supported by the new item
    if (statistic?.key && !_.some(statChoices, ['key', statistic.key])) {
      clearSelectedStatistic();
    }
  } else if (statistic?.key) {
    clearSelectedStatistic();
  }

  const isEmpty = _.isNil(statistic)
    || _.isNil(statistic.key)
    || (isTimeUnitConversionDisplayed && _.isNil(statistic.timeUnits))
    || (isPercentileDisplayed && _.isNil(statistic.percentile));
  const isPercentileInvalid = isPercentileDisplayed && !_.inRange(statistic.percentile, 0, 101);
  const isInvalid = (isRequired && isEmpty) || isPercentileInvalid;
  onValidate(!isInvalid);

  /**
   * Formats the label for the option
   *
   * @param option - One of the statistic options
   * @return The HTML for the option
   */
  const getOptionLabel: any = (option: StatChoice) => (
    <>
      <div data-testid="optionText"><strong>{t(option.title)}</strong></div>
      <small>{t(option.description)}</small>
    </>
  );

  /**
   * Filters the options based on what the user has typed. Searches both title and description.
   *
   * @param data - One of the statistic options
   * @param input - What the user has typed
   * @return True if the option matches, false otherwise.
   */
  const matchesFilter = ({ data }: { data: StatChoice }, input: string): boolean => input === '' ||
    _.includes(_.toLower(t(data.title) + ' ' + t(data.description)), _.toLower(input));

  const onChangePercentile = (event: React.ChangeEvent<HTMLInputElement>) => {
    let percentile = null;
    if (_.isFinite(event.target.value)) {
      percentile = event.target.value;
    } else if (event.target.value !== '') {
      percentile = parseInt(event.target.value, 10);
    }
    onSelectStatistic({ key: statistic?.key, timeUnits: statistic?.timeUnits, percentile });
  };

  const onChangeTimeUnit = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onSelectStatistic({
      key: statistic?.key,
      timeUnits: event.target.value,
      percentile: statistic?.percentile
    });
  };

  const getUnitKey = unit => _.get(unit, STANDARD_TIME_UNIT_PATH);

  function clearSelectedStatistic() {
    onSelectStatistic({ key: null, timeUnits: null, percentile: null });
  }

  const onChangeSelectedStatistic = (key) => {
    onSelectStatistic({ key, timeUnits: statistic?.timeUnits, percentile: statistic?.percentile });
  };

  return (
    <div className="displayBlock mt5" data-testid={name}>
      {!item && <p className="help-block sq-text-danger">{t('STATISTIC_SELECTOR.SELECT_ITEM_TO_VIEW')}</p>}
      {item && <Select
        placeholder={t(isRequired ? 'STATISTIC_SELECTOR.SELECT_STATISTIC' : 'STATISTIC_SELECTOR.NO_STATISTIC')}
        className="reactSelectWrapper"
        classNamePrefix="react-select"
        isClearable={!isRequired}
        isSearchable={true}
        value={selectedStatistic}
        options={statChoices}
        menuPosition={!menuPortalTarget ? 'fixed' : undefined}
        menuPortalTarget={menuPortalTarget}
        getOptionLabel={getOptionLabel}
        getOptionValue={option => option.key}
        filterOption={matchesFilter}
        onChange={option => option ? onChangeSelectedStatistic(option.key) : clearSelectedStatistic()}
      />}
      {isTimeUnitConversionDisplayed &&
      <div className="flexColumnContainer flexAlignCenter mt10">
        <div className="nowrap">{t('STATISTIC_SELECTOR.CONVERT_TIME_UNITS')}</div>
        <FormControl
          as="select"
          size="sm"
          className="ml5"
          value={getUnitKey(timeUnit)}
          onChange={onChangeTimeUnit}
        >
          {_.map(DURATION_TIME_UNITS, unit => (
            <option value={getUnitKey(unit)} key={getUnitKey(unit)}>{t(unit.translationKey)}</option>
          ))}
        </FormControl>
      </div>}

      {isPercentileDisplayed &&
      <div className="flexColumnContainer flexAlignCenter mt10">
        <div className="nowrap">{t('STATISTIC_SELECTOR.PERCENTILE')}</div>
        <FormControl
          type="number"
          min="0"
          max="100"
          size="sm"
          className="ml5"
          value={statistic?.percentile ?? '' as any}
          onChange={onChangePercentile}
        />
      </div>}
    </div>
  );
};

export const sqStatisticSelector = angularComponent(statisticSelectorBindings, StatisticSelector);
