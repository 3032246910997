import _ from 'lodash';
import HttpCodes from 'http-status-codes';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import bind from 'class-autobind-decorator';
import { ReportStore } from '@/reportEditor/report.store';
import { UtilitiesService } from '@/services/utilities.service';
import { ContentApi } from '@/sdk';
import {
  AssetSelection,
  Content,
  CONTENT_LOADING_CLASS,
  ContentDisplayMetadata,
  DateRange
} from '@/reportEditor/report.module';
import { ReportActions } from '@/reportEditor/report.actions';
import { NotificationsService } from '@/services/notifications.service';
import { LoggerService } from '@/services/logger.service';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { IPromise } from 'angular';
import { isContentInDocument } from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.utilities';

@bind
export class CkReportContentService {
  constructor(
    private $injector: ng.auto.IInjectorService,
    private $translate: ng.translate.ITranslateService,
    private sqReportEditor: ReportEditorService,
    private sqReportStore: ReportStore,
    private sqUtilities: UtilitiesService,
    private sqContentApi: ContentApi,
    private sqNotifications: NotificationsService,
    private sqLogger: LoggerService
  ) {}

  insertOrReplaceContent(contentId: string) {
    if (!isContentInDocument(contentId)) {
      // Insert
      const editor: any = this.sqReportEditor.getGlobalInstance();
      editor.execute('insertContent', editor.model.document.selection, contentId);
    } else {
      this.replaceContentIfExists(contentId);
    }
  }

  /**
   * A soft refresh differs from a forced refresh in that it doesn't clear the cached image in the backend. If the
   * image is already cached in the backend, we will receive the already cached image and not have to wait as long
   * for the image to generate.
   */
  private softRefreshContent(contentId: string, refresh: { silently?: boolean, deferImageUpdate?: boolean }) {
    const sqReportActions = this.$injector.get<ReportActions>('sqReportActions');

    // Order of operations is important here. Setting the display metadata first lets the Content molecule grab it
    // to see what kind of refresh we need to do.
    sqReportActions.setContentDisplayMetadata({ contentId, refresh });
    sqReportActions.setContentHashCode(contentId, this.sqUtilities.base64guid());
  }

  refreshAllContent(errorsOnly = false, deferImageUpdate = false, silently = false) {
    _.forEach(this.sqReportStore.contentNotArchived, (content) => {
      const metadata = this.sqReportStore.getContentDisplayMetadataById(content.id);
      if (!errorsOnly || metadata?.errorClass) {
        this.softRefreshContent(content.id, { silently, deferImageUpdate });
      }
    });
  }

  replaceContentIfExists(contentId: string, silently = false, deferImageUpdate = false): { isNewContent: boolean } {
    const isNewContent = !isContentInDocument(contentId);
    this.softRefreshContent(contentId, { silently, deferImageUpdate });
    return { isNewContent };
  }

  refreshContentUsingDate(dateRangeId: string, deferImageUpdate = false) {
    _.forEach(this.sqReportStore.contentUsingDateRange(dateRangeId), (content) => {
      this.softRefreshContent(content.id, { deferImageUpdate });
    });
  }

  contentError(contentId: string, error?: string): ContentDisplayMetadata {
    const sqReportActions = this.$injector.get<ReportActions>('sqReportActions');
    const content = this.sqReportStore.getContentById(contentId);
    const errorClass = this.sqReportStore.getDateRangeById(content?.dateRangeId)?.auto.noCapsuleFound
      ? CONTENT_LOADING_CLASS.NO_CAPSULE_ERROR
      : CONTENT_LOADING_CLASS.ERROR;
    const displayMetadata = { contentId, errorClass, error };
    sqReportActions.setContentDisplayMetadata(displayMetadata);
    // Need to trigger a refresh of the content
    sqReportActions.setContentHashCode(contentId, this.sqUtilities.base64guid());
    sqReportActions.debouncedImageStateChanged();
    return displayMetadata;
  }

  displayError(error) {
    const { statusMessage } = error;
    if (statusMessage) {
      this.sqNotifications.error(statusMessage);
    } else {
      this.sqLogger.warn(this.sqLogger.format`Error generating content ${error}`);
    }
  }

  duplicateOrUnarchiveContent(content: Content, isContentInDocument: boolean,
    maybeDateRange?: DateRange, maybeAssetSelection?: AssetSelection): IPromise<any> {
    const sqReportContent = this.$injector.get<ReportContentService>('sqReportContent');

    return Promise.all([sqReportContent.copyDateRangeForPendingContent(maybeDateRange, Promise),
        sqReportContent.copyAssetSelectionForPendingContent(maybeAssetSelection, Promise)])
      .then(result => Promise.resolve([content, result[0], result[1]]))
      .then(([content, dateRangeId, assetSelectionId]) => sqReportContent.duplicateOrUnarchivePendingContent(
        content as Content, isContentInDocument, Promise, dateRangeId as string, assetSelectionId as string));

  }

  duplicateContentError(contentId: string, error: any): ContentDisplayMetadata {
    const sqReportActions = this.$injector.get<ReportActions>('sqReportActions');
    // If pending content needs to be duplicated, and the current user doesn't have access to the content's
    // worksheet, then the user won't be able to finish loading the content.
    if (error.status === HttpCodes.FORBIDDEN) {
      const additionalMessage = this.$translate.instant('REPORT.CONTENT.COULD_NOT_GENERATE');
      error.data.statusMessage = error.data.statusMessage.concat(`. ${additionalMessage}: ${contentId}`);
    }
    // If the user does not have access to the worksheet, display custom error
    if (error?.data?.statusMessage.includes('you do not have access to the source worksheet')) {
      sqReportActions.addContentError(contentId);
    } else {
      this.displayError(error?.data ?? error);
    }
    return this.contentError(contentId);
  }

  handleLiveScreenshotMessageForContent(contentId: string, hashCode: string): { isNewContent: boolean } {
    const isNewContent = !isContentInDocument(contentId);
    const sqReportActions = this.$injector.get<ReportActions>('sqReportActions');

    sqReportActions.setContentDisplayMetadata({ contentId, refresh: {} });
    sqReportActions.setContentHashCode(contentId, hashCode);
    return { isNewContent };
  }
}
