import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { TrackService } from '@/track/track.service';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { WorkbookActions } from '@/workbook/workbook.actions';
import { ImportDatafileStore } from '@/hybrid/tools/importDatafile/importDatafile.store';
import { NotificationsService } from '@/services/notifications.service';
import { ImportDatafileActions } from '@/hybrid/tools/importDatafile/importDatafile.actions';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { SearchActions } from '@/search/search.actions';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { WORKSHEET_SIDEBAR_TAB } from '@/worksheet/worksheet.module';
import { SEARCH_PANES } from '@/search/search.module';
import {
  CONDITION_VALUE_COLUMN_OPTIONS,
  DATAFILE_ITEM_TYPES, DAY_MONTH_FIRST_OPTIONS, DELIMITERS,
  FILE_OUTPUT_TYPES,
  INTERPOLATION_METHODS, SIGNAL_VALUE_COLUMN_OPTIONS, VALIDATION_MODES
} from '@/hybrid/tools/importDatafile/importDatafile.module';
import { DatafilesApi } from '@/sdk';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { getLabelFromItemType, getTooltipFromItemType } from '@/hybrid/tools/importDatafile/importDatafile.utilities';

const importDatafileBindings = bindingsDefinition({
  $state: injected<ng.ui.IStateService>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqWorkbookActions: injected<WorkbookActions>(),
  sqImportDatafileStore: injected<ImportDatafileStore>(),
  sqNotifications: injected<NotificationsService>(),
  sqImportDatafileActions: injected<ImportDatafileActions>(),
  sqWorksheetActions: injected<WorksheetActions>(),
  sqSearchActions: injected<SearchActions>(),
  sqTrack: injected<TrackService>(),
  sqDatafilesApi: injected<DatafilesApi>()
});

const makeIndexInput = (baseName: string, label: string, tooltip: string, value: number,
  setter: (number) => void, displayNumber: boolean, includeIf: boolean, required: boolean, disabled: boolean) => ({
  component: 'FormRow',
  name: `datafile${baseName.charAt(0).toUpperCase() + baseName.slice(1)}`,
  includeIf,
  displayNumber,
  extraClassNames: `flexSpaceBetween flexFill ${displayNumber ? '' : SPACED_CLASSES}`,
  components: [{
    component: 'LabelFormComponent',
    name: `${baseName}Label`,
    tooltip,
    value: label
  }, {
    component: 'FormControlFormComponent',
    name: `${baseName}Input`,
    testId: `${baseName}Input`,
    value,
    onChange: setter,
    className: 'ml0 width-50',
    size: 'md',
    type: 'number',
    disabled,
    validation: value => (required ? true : value) && value < 0,
    fixedWidth: false
  }]
} as FormElement);

const makeTextInput = (baseName: string, label: string, tooltip: string | undefined, value: number,
  setter: (number) => void, displayNumber: boolean, includeIf: boolean, required: boolean,
  validation: (value) => boolean, disabled: boolean) =>
  ({
    component: 'FormRow',
    name: `datafile${baseName.charAt(0).toUpperCase() + baseName.slice(1)}`,
    includeIf,
    displayNumber,
    extraClassNames: `flexSpaceBetween flexFill ${displayNumber ? '' : SPACED_CLASSES}`,
    components: [{
      component: 'LabelFormComponent',
      name: `${baseName}Label`,
      tooltip,
      value: label
    }, {
      component: 'FormControlFormComponent',
      name: `${baseName}Input`,
      testId: `${baseName}Input`,
      value,
      onChange: setter,
      className: 'mt5 ml0 width-75',
      size: 'md',
      disabled,
      validation: validation ? validation : () => required ? undefined : false,
      fixedWidth: false
    }]
  } as FormElement);

const SPACED_CLASSES = 'mb5 mt5';

export const ImportDatafile: SeeqComponent<typeof importDatafileBindings> = () => {
  const {
    $state,
    sqInvestigateActions,
    sqWorkbookActions,
    sqImportDatafileStore,
    sqImportDatafileActions,
    sqWorksheetActions,
    sqSearchActions,
    sqDatafilesApi,
    sqNotifications
  } = useInjectedBindings(importDatafileBindings);

  const {
    id,
    name,
    filename,
    uploadFilename,
    fileOutputType,
    validationMode,
    lenientDaylightSavings,
    fieldDelimiter,
    nameRow,
    firstDataRow,
    descriptionRow,
    valueUomRow,
    keyColumnIndex,
    keyColumnName,
    valueColumnIndices,
    valueColumnNames,
    namePrefix,
    nameSuffix,
    timezone,
    description,
    valueColumnOption,
    valueUom,
    itemType,
    dayFirstDefault,

    // Signals
    interpolationMethod,
    maxInterpolation,
    maxInterpolationRow,
    interpolationMethodRow,

    // Conditions
    maxDuration,
    endColumnIndex,
    endColumnName,
    conditionName
  } = useFlux(sqImportDatafileStore);

  const disabled = !uploadFilename;

  const isSignalSelected = sqImportDatafileStore.isSignalSelected();
  const valueColumnOptions = isSignalSelected ? SIGNAL_VALUE_COLUMN_OPTIONS : CONDITION_VALUE_COLUMN_OPTIONS;
  const setSearchName = _.partial(sqInvestigateActions.setSearchName, TREND_TOOLS.IMPORTDATAFILE);

  const { t } = useTranslation();

  const putDatafile = () => {
    return sqImportDatafileActions[id ? 'updateDatafile' : 'createDatafile']()
      .then(({ data: { id } }) => {
        if (id) {
          sqImportDatafileActions.refreshDatafileItems(id);
        }
        sqInvestigateActions.close();
        sqNotifications.successTranslate('IMPORTS.IMPORTED_MESSAGE');
        sqWorksheetActions.tabsetChangeTab('sidebar', WORKSHEET_SIDEBAR_TAB.SEARCH);
        sqSearchActions.exploreAsset(SEARCH_PANES.MAIN, id);
        sqWorkbookActions.addRecentlyAccessed($state.params.workbookId, id);
      })
      .catch((e) => {
        sqNotifications.apiError(e, { skipRedaction: true, displayForbidden: true });
      });
  };

  ///////////////// Form Fields ////////////////////////
  const formDataSetup: FormElement[] = [{
    component: 'SearchTitleFormComponent',
    name: 'datafileName',
    value: name,
    onChange: setSearchName,
    id,
    onColorChange: _.noop,
    searchIconClass: 'fc-import',
    defaultName: 'IMPORTDATAFILE.HEADER'
  }, {
    component: 'FormGroup',
    name: 'datafileFileUpload',
    displayNumber: true,
    components: [{
      component: 'LabelFormComponent',
      name: 'fileUploadLabel',
      value: 'IMPORTDATAFILE.SELECT_FILE',
      includeIf: filename === undefined || filename === ''
    }, {
      component: 'FileDropperFormComponent',
      value: (id && !uploadFilename) ? true : uploadFilename,
      filename: (id && !uploadFilename) ? undefined : filename,
      name: 'fileUploadInput',
      id: 'fileUploadInput',
      text: 'IMPORTDATAFILE.DROP_TEXT',
      fileExtension: '.csv',
      fileDropCallback: file => sqDatafilesApi.importCSV({ file })
        .then(({ data: { filename } }) => {
          sqImportDatafileActions.setFilename(file.name);
          sqImportDatafileActions.setUploadFilename(filename);

          if (!id) {
            const extensionlessFilename = file.name.substring(0, file.name.lastIndexOf('.'));
            if (_.isUndefined(name) || name.includes(t('IMPORTDATAFILE.HEADER'))) {
              setSearchName(extensionlessFilename);
            }

            if (_.isEmpty(conditionName)) {
              sqImportDatafileActions.setConditionName(extensionlessFilename);
            }
          }
        })
        .catch(e => sqNotifications.apiError(e)),
      clearFileCallback: () => {
        sqImportDatafileActions.setFilename(undefined);
        sqImportDatafileActions.setUploadFilename(undefined);
      },
      extendValidation: false,
      validation: value => !value
    }]
  }, {
    component: 'RadioButtonGroupFormComponent',
    name: 'datafileItemType',
    value: itemType,
    onChange: _.noop,
    id: 'datafileItemType',
    displayNumber: true,
    label: 'IMPORTS.IMPORT_FILE_AS',
    options: _.map(DATAFILE_ITEM_TYPES, type => ({
      id: `datafileItemType${type.value}`,
      label: type.text,
      checked: sqImportDatafileStore.itemType.value === type.value,
      onToggle: () => sqImportDatafileActions.setItemType(type),
      disabled
    }))
  }, {
    component: 'RadioButtonGroupFormComponent',
    name: 'datafileAppendOrReplace',
    value: fileOutputType,
    onChange: _.noop,
    includeIf: id,
    displayNumber: true,
    id: 'datafileOutputType',
    label: 'IMPORTS.IMPORT_FILE_AS',
    options: _.map(FILE_OUTPUT_TYPES, type => ({
      id: `datafileOutputType${type.value}`,
      label: type.text,
      checked: sqImportDatafileStore.fileOutputType.value === type.value,
      onToggle: () => sqImportDatafileActions.setFileOutputType(type),
      disabled
    }))
  }, {
    component: 'FormGroup',
    name: 'datafileConditionName',
    displayNumber: true,
    includeIf: !isSignalSelected,
    components: [{
      component: 'LabelFormComponent',
      name: 'conditionNameLabel',
      value: 'IMPORTS.CONDITION_NAME'
    }, {
      component: 'FormControlFormComponent',
      testId: 'conditionNameInput',
      name: 'conditionNameInput',
      value: conditionName,
      onChange: sqImportDatafileActions.setConditionName,
      className: 'mt5',
      size: 'md',
      disabled
    }]
  },
    makeIndexInput('nameRow', 'IMPORTS.NAME_ROW', getTooltipFromItemType(isSignalSelected, 'NAME_ROW'), nameRow,
      sqImportDatafileActions.setNameRow, true, true, true, disabled),
    makeIndexInput('firstDataRow', 'IMPORTS.FIRST_DATA_ROW', 'IMPORTS.TOOLTIPS.FIRST_DATA_ROW', firstDataRow,
      sqImportDatafileActions.setFirstDataRow, true, true, true, disabled),
    {
      component: 'RadioButtonGroupFormComponent',
      name: 'datafileInterpolationMethod',
      value: interpolationMethod,
      includeIf: isSignalSelected,
      id: 'datafileInterpolationMethod',
      displayNumber: true,
      label: 'IMPORTS.INTERPOLATION.METHOD',
      gridLayout: true,
      options: _.map(INTERPOLATION_METHODS, method => ({
        id: `datafileInterpolationMethod${method.value}`,
        label: method.text,
        checked: sqImportDatafileStore.interpolationMethod.value === method.value,
        onToggle: () => sqImportDatafileActions.setInterpolationMethod(method),
        disabled
      }))
    }, {
      component: 'FormGroup',
      name: 'datafileMaxInterpolation',
      includeIf: isSignalSelected,
      displayNumber: true,
      components: [{
        component: 'LabelFormComponent',
        name: 'maxInterpolationLabel',
        value: 'IMPORTS.INTERPOLATION.MAXGAP'
      }, {
        component: 'ValueWithUnitsFormComponent',
        name: 'maxInterpolationInput',
        value: maxInterpolation,
        onChange: sqImportDatafileActions.setMaxInterpolation,
        min: 0,
        disabled
      }]
    }, {
      component: 'FormGroup',
      name: 'datafileMaxDuration',
      includeIf: !isSignalSelected,
      displayNumber: true,
      components: [{
        component: 'LabelFormComponent',
        name: 'maxDurationLabel',
        value: 'IMPORTS.MAX_DURATION'
      }, {
        component: 'ValueWithUnitsFormComponent',
        name: 'maxDurationInput',
        value: maxDuration,
        onChange: sqImportDatafileActions.setMaxDuration,
        min: 0,
        disabled
      }]
    }, {
      component: 'FormRow',
      name: 'datafileSelectUom',
      displayNumber: true,
      extraClassNames: 'flexSpaceBetween flexFill',
      components: [{
        component: 'LabelFormComponent',
        name: 'selectUomLabel',
        value: 'IMPORTS.VALUE_UOM'
      }, {
        component: 'SelectUnitFormComponent',
        name: 'selectUomInput',
        value: valueUom,
        onChange: sqImportDatafileActions.setValueUom,
        validation: () => false,
        disabled,
        extraClassNames: 'width-120'
      }]
    },
    makeIndexInput('keyColumnIndex', getLabelFromItemType(isSignalSelected, 'KEY_COLUMN_INDEX'),
      getTooltipFromItemType(isSignalSelected, 'KEY_COLUMN_INDEX'), keyColumnIndex,
      sqImportDatafileActions.setKeyColumnIndex, true, true, false, disabled),
    makeTextInput('keyColumnName', getLabelFromItemType(isSignalSelected, 'KEY_COLUMN_NAME'),
      getTooltipFromItemType(isSignalSelected, 'KEY_COLUMN_NAME'),
      keyColumnName, sqImportDatafileActions.setKeyColumnName, true, true, false, undefined, disabled),
    makeIndexInput('endColumnIndex', 'IMPORTS.END_COLUMN_INDEX', 'IMPORTS.TOOLTIPS.END_COLUMN_INDEX', endColumnIndex,
      sqImportDatafileActions.setEndColumnIndex, true, !isSignalSelected, false, disabled),
    makeTextInput('endColumnName', 'IMPORTS.END_COLUMN_NAME', 'IMPORTS.TOOLTIPS.END_COLUMN_NAME', endColumnName,
      sqImportDatafileActions.setEndColumnName, true, !isSignalSelected, false, undefined, disabled),
    {
      component: 'FormGroup',
      name: 'datafileTimezone',
      displayNumber: true,
      components: [{
        component: 'LabelFormComponent',
        name: 'timezoneLabel',
        tooltip: 'IMPORTS.TOOLTIPS.TIMEZONE',
        value: 'IMPORTS.TIMEZONE'
      }, {
        component: 'TimeZoneSelectorFormComponent',
        name: 'timezoneInput',
        value: timezone,
        onChange: sqImportDatafileActions.setTimezone,
        enableAutoDetect: false,
        defaultTimeZone: undefined,
        disabled,
        extendValidation: false,
        validation: () => false
      }]
    }, {
      component: 'FormRow',
      name: 'datafileFieldDelimiter',
      displayNumber: true,
      extraClassNames: 'flexSpaceBetween flexFill',
      components: [{
        component: 'LabelFormComponent',
        name: 'fieldDelimiterLabel',
        value: 'IMPORTS.FIELD_DELIMITER'
      }, {
        component: 'IconSelectFormComponent',
        name: 'fieldDelimiterInput',
        value: fieldDelimiter,
        onChange: sqImportDatafileActions.setFieldDelimiter,
        wrapperClasses: 'width-80',
        selectOptions: DELIMITERS,
        disabled,
        fixedWidth: false
      }]
    }, {
      component: 'FormRow',
      name: 'datafileDayFirstDefault',
      displayNumber: true,
      extraClassNames: 'flexSpaceBetween flexFill',
      components: [{
        component: 'LabelFormComponent',
        name: 'dayFirstDefaultLabel',
        tooltip: 'IMPORTS.TOOLTIPS.DAY_MONTH_FIRST',
        value: 'IMPORTS.DAY_MONTH_FIRST'
      }, {
        component: 'IconSelectFormComponent',
        name: 'dayFirstDefaultInput',
        value: dayFirstDefault,
        onChange: sqImportDatafileActions.setDayFirstDefault,
        wrapperClasses: 'width-100',
        selectOptions: DAY_MONTH_FIRST_OPTIONS,
        disabled,
        fixedWidth: false
      }]
    }, {
      component: 'AdvancedFormGroup',
      name: 'optionalParametersGroup',
      toolName: TREND_TOOLS.IMPORTDATAFILE,
      toolId: TREND_TOOLS.IMPORTDATAFILE,
      toolStore: sqImportDatafileStore,
      alternateName: 'IMPORTS.OPTIONAL',
      components: [{
        component: 'FormGroup',
        name: 'datafileValidationModes',
        extraClassNames: SPACED_CLASSES,
        components: [{
          component: 'LabelFormComponent',
          name: 'validationModesLabel',
          tooltip: 'IMPORTS.TOOLTIPS.VALIDATION_MODE',
          value: 'IMPORTS.VALIDATION_MODE'
        }, {
          component: 'RadioButtonGroupFormComponent',
          name: 'validationModesInput',
          value: validationMode,
          id: 'validationModesInput',
          label: '',
          options: _.map(VALIDATION_MODES, mode => ({
            id: `datafileValidationMode${mode.value}`,
            label: mode.text,
            checked: sqImportDatafileStore.validationMode.value === mode.value,
            onToggle: () => sqImportDatafileActions.setValidationMode(mode),
            disabled
          }))
        }]
      }, {
        component: 'CheckboxFormComponent',
        name: 'datafileLenientDaylightSavings',
        id: 'datafileLenientDaylightSavings',
        value: lenientDaylightSavings,
        onChange: () => sqImportDatafileActions.setLenientDaylightSavings(!lenientDaylightSavings),
        label: 'IMPORTS.LENIENT_DST',
        tooltip: 'IMPORTS.TOOLTIPS.LENIENT_DST',
        disabled
      },
        makeTextInput('description', 'IMPORTS.DESCRIPTION', undefined, description,
          sqImportDatafileActions.setDescription, false, true, false, undefined, disabled),
        makeTextInput('prefix', getLabelFromItemType(isSignalSelected, 'NAME_PREFIX'), undefined, namePrefix,
          sqImportDatafileActions.setNamePrefix, false, true, false, undefined, disabled),
        makeTextInput('suffix', getLabelFromItemType(isSignalSelected, 'NAME_SUFFIX'), undefined, nameSuffix,
          sqImportDatafileActions.setNameSuffix, false, true, false, undefined, disabled),
        {
          component: 'RadioButtonGroupFormComponent',
          name: 'datafileValueColumnOptions',
          value: valueColumnOption,
          id: 'datafileValueColumnOptions',
          label: getLabelFromItemType(isSignalSelected, 'VALUE_COLUMN_OPTIONS'),
          extraClassNames: SPACED_CLASSES,
          options: _.map(valueColumnOptions, option => ({
            id: `datafileValueColumnOption${option.value}`,
            label: option.text,
            checked: sqImportDatafileStore.valueColumnOption.value === option.value,
            onToggle: () => sqImportDatafileActions.setValueColumnOption(option),
            disabled
          }))
        },
        makeTextInput('valueColumnNames', getLabelFromItemType(isSignalSelected, 'VALUE_COLUMN_NAMES'),
          getTooltipFromItemType(isSignalSelected, 'VALUE_COLUMN_NAMES'), valueColumnNames,
          sqImportDatafileActions.setValueColumnNames, true, valueColumnOption.value === 'Name', false,
          value => value !== '' && !/^[^,]+(,[^,]+)*$/.test(value as string), disabled),
        makeTextInput('valueColumnIndices', getLabelFromItemType(isSignalSelected, 'VALUE_COLUMN_INDICES'),
          getTooltipFromItemType(isSignalSelected, 'VALUE_COLUMN_INDICES'), valueColumnIndices,
          sqImportDatafileActions.setValueColumnIndices, true, valueColumnOption.value === 'Index', false,
          value => value !== '' && !/^(\d+\s*)(,\s*(\d+\s*-\s*\d+)\s*|,\s*(\d+)\s*)*$/.test(value as string), disabled),
        makeIndexInput('descriptionRow', 'IMPORTS.DESCRIPTION_ROW', 'IMPORTS.TOOLTIPS.DESCRIPTION_ROW',
          descriptionRow,
          sqImportDatafileActions.setDescriptionRow, false, isSignalSelected, false, disabled),
        makeIndexInput('maxInterpolationRow', 'IMPORTS.MAX_INTERPOLATION_ROW',
          'IMPORTS.TOOLTIPS.MAX_INTERPOLATION_ROW',
          maxInterpolationRow, sqImportDatafileActions.setMaxInterpolationRow, false, isSignalSelected, false,
          disabled),
        makeIndexInput('interpolationMethodRow', 'IMPORTS.INTERPOLATION_METHOD_ROW',
          'IMPORTS.TOOLTIPS.INTERPOLATION_METHOD_ROW', interpolationMethodRow,
          sqImportDatafileActions.setInterpolationMethodRow, false, isSignalSelected, false, disabled),
        makeIndexInput('uomRow', 'IMPORTS.VALUE_UOM_ROW', getTooltipFromItemType(isSignalSelected, 'VALUE_UOM_ROW'),
          valueUomRow, sqImportDatafileActions.setValueUomRow, false, true, false, disabled)
      ]
    }];

  return <ToolPanelFormBuilder
    formDefinition={formDataSetup}
    submitFn={putDatafile}
    closeFn={sqInvestigateActions.close}
    toolName={TREND_TOOLS.IMPORTDATAFILE}
    toolId={TREND_TOOLS.IMPORTDATAFILE}
    submitBtnId="importdatafileButton"
  />;
};

export const sqImportDatafile = angularComponent(importDatafileBindings, ImportDatafile);
