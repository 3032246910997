/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class WorkbooksApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Put the specified item at the top of the recently accessed list for the workbook
   * @param {string} workbookId - ID of the workbook
   * @param {string} itemId - Item to be added
   */
  public addRecentlyAccessed(
    {
      workbookId,
      itemId
    } : {
      workbookId: string,
      itemId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/workbooks/{workbookId}/recently-accessed/{itemId}'
        .replace('{workbookId}', encodeURIComponent(String(workbookId)))
        .replace('{itemId}', encodeURIComponent(String(itemId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ItemSearchPreviewListV1>;
  }

  /**
   * 
   * @summary Archive a workbook by id
   * @param {string} id - ID of the workbook to archive
   */
  public archiveWorkbook(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/workbooks/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ArchiveOutputV1>;
  }

  /**
   * 
   * @summary Archive a worksheet
   * @param {string} workbookId - ID of the workbook on which the worksheet exists
   * @param {string} worksheetId - ID of the worksheet to archive
   */
  public archiveWorksheet(
    {
      workbookId,
      worksheetId
    } : {
      workbookId: string,
      worksheetId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/workbooks/{workbookId}/worksheets/{worksheetId}'
        .replace('{workbookId}', encodeURIComponent(String(workbookId)))
        .replace('{worksheetId}', encodeURIComponent(String(worksheetId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ArchiveOutputV1>;
  }

  /**
   * 
   * @summary Create a workbook
   * @param {models.WorkbookInputV1} body - Workbook information
   */
  public createWorkbook(
    body: models.WorkbookInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/workbooks',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.WorkbookOutputV1>;
  }

  /**
   * 
   * @summary Create a worksheet
   * @param {string} workbookId - ID of the workbook on which to create the worksheet
   * @param {models.WorksheetInputV1} body - Worksheet information
   */
  public createWorksheet(
    body: models.WorksheetInputV1,
    {
      workbookId,
    } : {
      workbookId: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/workbooks/{workbookId}/worksheets'
        .replace('{workbookId}', encodeURIComponent(String(workbookId))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.WorksheetOutputV1>;
  }

  /**
   * 
   * @summary Append a new workstep to the worksheet
   * @param {string} workbookId - ID of the workbook on which the worksheet exists
   * @param {string} worksheetId - ID of the worksheet on which to append the new workstep
   * @param {models.WorkstepInputV1} body - Workstep information
   * @param {boolean} [noWorkstepMessage=false] - Option to not send a workstep message notifying clients that the current workstep of the worksheet has been updated. Should only be used when multiple worksteps are being pushed in quick succession and a subsequent call will be made to set the current workstep.
   */
  public createWorkstep(
    body: models.WorkstepInputV1,
    {
      workbookId,
      worksheetId,
      noWorkstepMessage
    } : {
      workbookId: string,
      worksheetId: string,
      noWorkstepMessage?: boolean
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/workbooks/{workbookId}/worksheets/{worksheetId}/worksteps'
        .replace('{workbookId}', encodeURIComponent(String(workbookId)))
        .replace('{worksheetId}', encodeURIComponent(String(worksheetId))),
      data: body,
      params: omitBy({
        ['noWorkstepMessage']: noWorkstepMessage
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.WorkstepOutputV1>;
  }

  /**
   * 
   * @summary Get the recently accessed list for the workbook
   * @param {string} workbookId - ID of the workbook
   */
  public getRecentlyAccessed(
    {
      workbookId
    } : {
      workbookId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/workbooks/{workbookId}/recently-accessed'
        .replace('{workbookId}', encodeURIComponent(String(workbookId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ItemSearchPreviewListV1>;
  }

  /**
   * 
   * @summary Get items referenced by workstep or a journal
   * @param {string} worksheetId - ID of the worksheet associated with journal that references items to return
   * @param {string} workstepId - ID of the workstep that references items to return
   */
  public getReferencedItems(
    {
      worksheetId,
      workstepId
    } : {
      worksheetId: string,
      workstepId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    if (isNil(workstepId)) {
      throw new Error("'workstepId' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/workbooks/worksheets/{worksheetId}/worksteps/{workstepId}/referenced-items'
        .replace('{worksheetId}', encodeURIComponent(String(worksheetId)))
        .replace('{workstepId}', encodeURIComponent(String(workstepId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ReferencedItemsOutputV1>;
  }

  /**
   * 
   * @summary Get a workbook
   * @param {string} id - ID of the workbook to retrieve
   */
  public getWorkbook(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/workbooks/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.WorkbookOutputV1>;
  }

  /**
   * Pagination is enabled for this query. Note that this endpoint only returns workbooks that a user has access to and is not optimized for performance. To get folders and workbooks with better performance and more filtering options, please see the Folders API.
   * @summary Get a collection of workbooks
   * @param {string} [userId] - The user ID to return workbooks for, defaults to the current user if not specified. Only an admin user is allowed to specify a user ID.
   * @param {boolean} [isArchived=false] - True to filter results to only workbooks that have been archived, false to filter results to workbooks that are not archived.
   * @param {string} [sortOrder=createdAt asc] - A field by which to order the workbooks followed by a space and &#39;asc&#39; or &#39;desc&#39;. Field name can be one of: createdAt, updatedAt, name
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getWorkbooks(
    {
      userId,
      isArchived,
      sortOrder,
      offset,
      limit
    } : {
      userId?: string,
      isArchived?: boolean,
      sortOrder?: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/workbooks',
      params: omitBy({
        ['userId']: userId,
        ['isArchived']: isArchived,
        ['sortOrder']: sortOrder,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.WorkbookOutputListV1>;
  }

  /**
   * 
   * @summary Get a worksheet
   * @param {string} workbookId - ID of the workbook for which to retrieve the worksheets
   * @param {string} worksheetId - ID of the worksheet to retrieve
   */
  public getWorksheet(
    {
      workbookId,
      worksheetId
    } : {
      workbookId: string,
      worksheetId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/workbooks/{workbookId}/worksheets/{worksheetId}'
        .replace('{workbookId}', encodeURIComponent(String(workbookId)))
        .replace('{worksheetId}', encodeURIComponent(String(worksheetId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.WorksheetOutputV1>;
  }

  /**
   * 
   * @summary Get a list of reports that reference a given worksheet
   * @param {string} workbookId - The ID of the workbook for containing the worksheet
   * @param {string} worksheetId - The ID of the worksheet
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getWorksheetUsages(
    {
      workbookId,
      worksheetId,
      offset,
      limit
    } : {
      workbookId: string,
      worksheetId: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/workbooks/{workbookId}/worksheets/{worksheetId}/usages'
        .replace('{workbookId}', encodeURIComponent(String(workbookId)))
        .replace('{worksheetId}', encodeURIComponent(String(worksheetId))),
      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AnnotationListOutputV1>;
  }

  /**
   * Pagination is enabled for this query
   * @summary Get a collection of worksheets
   * @param {string} workbookId - The ID of the workbook for which to retrieve the worksheets
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   * @param {boolean} [isArchived=false] - Whether to return archived worksheets
   */
  public getWorksheets(
    {
      workbookId,
      offset,
      limit,
      isArchived
    } : {
      workbookId: string,
      offset?: number,
      limit?: number,
      isArchived?: boolean
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/workbooks/{workbookId}/worksheets'
        .replace('{workbookId}', encodeURIComponent(String(workbookId))),
      params: omitBy({
        ['offset']: offset,
        ['limit']: limit,
        ['isArchived']: isArchived
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.WorksheetOutputListV1>;
  }

  /**
   * 
   * @summary Get a workstep
   * @param {string} workbookId - ID of the workbook for which to retrieve the worksteps
   * @param {string} worksheetId - ID of the worksheet for which to retrieve the worksteps
   * @param {string} workstepId - ID of the workstep to retrieve
   */
  public getWorkstep(
    {
      workbookId,
      worksheetId,
      workstepId
    } : {
      workbookId: string,
      worksheetId: string,
      workstepId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    if (isNil(workstepId)) {
      throw new Error("'workstepId' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/workbooks/{workbookId}/worksheets/{worksheetId}/worksteps/{workstepId}'
        .replace('{workbookId}', encodeURIComponent(String(workbookId)))
        .replace('{worksheetId}', encodeURIComponent(String(worksheetId)))
        .replace('{workstepId}', encodeURIComponent(String(workstepId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.WorkstepOutputV1>;
  }

  /**
   * 
   * @summary Move a Worksheet to a new location in the same Workbook
   * @param {string} workbookId - ID of the workbook on which the worksheet exists
   * @param {string} worksheetId - ID of the worksheet on which the workstep exists
   * @param {string} [nextWorksheetId] - ID of the worksheet that the specified worksheet should precede. If not supplied then the worksheet will be moved to be the last worksheet in the workbook.
   */
  public moveWorksheet(
    {
      workbookId,
      worksheetId,
      nextWorksheetId
    } : {
      workbookId: string,
      worksheetId: string,
      nextWorksheetId?: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/workbooks/{workbookId}/worksheets/{worksheetId}/next'
        .replace('{workbookId}', encodeURIComponent(String(workbookId)))
        .replace('{worksheetId}', encodeURIComponent(String(worksheetId))),
      params: omitBy({
        ['nextWorksheetId']: nextWorksheetId
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.WorksheetOutputV1>;
  }

  /**
   * 
   * @summary Set the current workstep
   * @param {string} workbookId - ID of the workbook on which the worksheet exists
   * @param {string} worksheetId - ID of the worksheet on which the workstep exists
   * @param {string} workstepId - ID of the workstep to set as the current one
   */
  public setCurrentWorkstep(
    {
      workbookId,
      worksheetId,
      workstepId
    } : {
      workbookId: string,
      worksheetId: string,
      workstepId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(workbookId)) {
      throw new Error("'workbookId' parameter required");
    }

    if (isNil(worksheetId)) {
      throw new Error("'worksheetId' parameter required");
    }

    if (isNil(workstepId)) {
      throw new Error("'workstepId' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/workbooks/{workbookId}/worksheets/{worksheetId}/worksteps/{workstepId}/current'
        .replace('{workbookId}', encodeURIComponent(String(workbookId)))
        .replace('{worksheetId}', encodeURIComponent(String(worksheetId)))
        .replace('{workstepId}', encodeURIComponent(String(workstepId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.WorkstepOutputV1>;
  }

  /**
   * 
   * @summary Set the topic's renderer.
   * @param {string} id - ID of the topic to change the renderer of
   * @param {string} identityId - The user or group to make the topic&#39;s renderer
   */
  public setRenderer(
    {
      id,
      identityId
    } : {
      id: string,
      identityId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(identityId)) {
      throw new Error("'identityId' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/workbooks/{id}/renderer'
        .replace('{id}', encodeURIComponent(String(id))),
      params: omitBy({
        ['identityId']: identityId
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

}
