import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import {
  CAPSULE_SOURCE_ID_PROPERTY_NAME,
  CAPSULE_UNIQUE_PROPERTY_NAME
} from '@/investigate/customCondition/customCondition.module';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { InvestigateHelperService } from '@/investigate/investigateHelper.service';
import { Capsule } from '../../../../plugin/sdk/seeq';
import { ItemsApi } from '@/sdk';
import { StylesConfig } from 'react-select';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { COLOR_COLUMN_NAME, PRIORITY_COLUMN_NAME } from '@/hybrid/tableBuilder/tableBuilder.module';
import { Icon } from '@/hybrid/core/Icon.atom';
import { PropertyColumn } from '@/trendData/trendData.module';

export enum SuggestedPropertiesMode {
  Series = 'series',
  Capsules = 'capsules'
}

const customPropertySelectorBindings = bindingsDefinition({
  excludedProperties: prop.optional<string[]>(),
  extraProperties: prop.optional<PropertyColumn[]>(),
  suggestedPropertiesMode: prop.optional<SuggestedPropertiesMode>(),
  conditions: prop.optional<Capsule[]>(),
  itemSet: prop.optional<any[]>(),
  customStyles: prop.optional<StylesConfig<any, any>>(),
  dropdownPlaceholder: prop<string>(),
  addPropertyColumn: prop<(column: any) => void>(),
  sqTrendDataHelper: injected<TrendDataHelperService>(),
  sqInvestigateHelper: injected<InvestigateHelperService>(),
  sqItemsApi: injected<ItemsApi>()
});

// Excluded properties are properties not useful for the user or are already exposed to the user
const EXCLUDED_PROPERTIES = [
  'Similarity',
  'Sample Period',
  'Reference Capsule',
  CAPSULE_SOURCE_ID_PROPERTY_NAME,
  CAPSULE_UNIQUE_PROPERTY_NAME
];
// Properties that are on the underlying backing condition of metrics and thus aren't useful to the user
export const METRIC_CONDITION_PROPERTIES = [
  SeeqNames.CapsuleProperties.Value,
  COLOR_COLUMN_NAME,
  PRIORITY_COLUMN_NAME
];

/**
 * Dropdown with selectable, searchable list of item properties
 */
export const CustomPropertySelector: SeeqComponent<typeof customPropertySelectorBindings> = (props) => {
  const {
    sqTrendDataHelper,
    sqInvestigateHelper,
    sqItemsApi
  } = useInjectedBindings(customPropertySelectorBindings);
  const {
    excludedProperties = [],
    extraProperties = [],
    suggestedPropertiesMode = SuggestedPropertiesMode.Series,
    conditions,
    itemSet,
    addPropertyColumn,
    customStyles,
    dropdownPlaceholder
  } = props;

  const { t } = useTranslation();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [allSuggestedProperties, setAllSuggestedProperties] = useState([]);
  useEffect(() => {
    requestSuggestedProperties();
  }, []);

  function getSuggestedProperties() {
    return _.chain(allSuggestedProperties)
      .reject(property => _.includes(EXCLUDED_PROPERTIES.concat(excludedProperties), property.name)
        && !_.some(extraProperties, { propertyName: property.name ?? property.propertyName }))
      .map(property => ({
        value: property.key ?? property.name ?? property.propertyName,
        label: property.name ?? property.propertyName
      }))
      .value();
  }

  /**
   * Gets a list of properties to suggest to the user and appends them to the `detectedProperties`
   * array excluding already assigned columns. If a request fails, then the request will fail silently
   */
  function requestSuggestedProperties() {
    return Promise.resolve()
      .then(() => {
        switch (suggestedPropertiesMode) {
          case SuggestedPropertiesMode.Series :
            return requestSeriesProperties();
          case SuggestedPropertiesMode.Capsules :
            return requestCapsuleProperties();
          default:
            return [];
        }
      })
      .then((properties) => {
        const uniqueProperties = _.chain(properties)
          .concat(extraProperties)
          .uniqBy(property => property.name ?? property.propertyName)
          .sortBy(property => property.name ?? property.propertyName)
          .value();
        setAllSuggestedProperties(uniqueProperties);
      });
  }

  /**
   * Sets the detectedProperties for all displayed series excluding already assigned ones.
   * Requests will fail silently.
   */
  function requestSeriesProperties() {
    return _.chain(itemSet ?? sqTrendDataHelper.getAllItems())
      .map(item => sqItemsApi.getItemAndAllProperties({ id: item.id }))
      .thru(results => Promise.all(results))
      .value()
      .then(responses => _.chain(responses)
        .map('data')
        .flatMap('properties')
        .value()
      );
  }

  /**
   * Sets the detectedProperties for all displayed capsule sets excluding already assigned ones.
   * Requests will fail silently.
   */
  function requestCapsuleProperties() {
    return _.chain(conditions)
      .map(condition => sqInvestigateHelper.requestCapsuleProperties(condition.id, []))
      .thru(results => Promise.all(results))
      .value()
      .then(responses => _.flatten(responses));
  }

  // region keep menu open so that user can select several options without opening the menu again
  const DropdownIndicator = (props) => {
    return components.DropdownIndicator &&
      <components.DropdownIndicator {...props}>
        {menuIsOpen && <Icon icon='fa fa-caret-up' large={true} />}
        {!menuIsOpen && <Icon icon='fa fa-caret-down' large={true} />}
      </components.DropdownIndicator>;
  };

  const IndicatorsContainer = (props) => {
    return components.IndicatorsContainer &&
      <div onClick={(e) => {
        setMenuIsOpen(!menuIsOpen);
        // We have to stop the event from propagating because otherwise angular popovers will interpret a click inside
        // this selector as being outside of the popover (and close the popover immediately).
        e.stopPropagation();
      }}>
        <components.IndicatorsContainer {...props} />
      </div>;
  };

  const ValueContainer = (props) => {
    return components.ValueContainer &&
      <div className="flexFill" onClick={(e) => {
        setMenuIsOpen(!menuIsOpen);
        // We have to stop the event from propagating because otherwise angular popovers will interpret a click inside
        // this selector as being outside of the popover (and close the popover immediately).
        e.stopPropagation();
      }}>
        <components.ValueContainer {...props} />
      </div>;
  };
  // endregion

  const addProperty = (column) => {
    // Keep the column key only for statistic columns
    if (column.value.startsWith('statistics.')) {
      addPropertyColumn({ propertyName: column.label, key: column.value });
    } else {
      addPropertyColumn({ propertyName: column.label });
    }
  };

  return (
    <div data-testid="customPropertySelector" onBlur={() => setMenuIsOpen(false)}>
      <CreatableSelect
        components={{ ValueContainer, IndicatorsContainer, DropdownIndicator }}
        menuIsOpen={menuIsOpen}
        styles={customStyles}
        // Shows the placeholder value and clears the value after adding the property column
        value={null}
        autoFocus={true}
        isSearchable={true}
        className='flexFillOverflow'
        classNamePrefix='react-select'
        placeholder={t(dropdownPlaceholder)}
        options={getSuggestedProperties()}
        inputId='customPropertyInput'
        onChange={addProperty}
      />
    </div>
  );
};
