import { TREND_COLORS } from "@/trendData/trendData.module";

const getPieOptions = (tableData, settings) => {
  const seriesData = [];
  tableData.slice(1).forEach((row) => {
    seriesData.push({
      name: row[0],
      y: Number(row[1].value)
    });
  });

  const options: Highcharts.Options = {
    chart: {
      type: 'pie',
      animation: false
    },
    title: {
      text: settings.title
    },
    tooltip: {
      backgroundColor: '#FFFFFF',
      headerFormat: '<b>{point.key}</b><br />',
      pointFormat: '{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>',
      footerFormat: '',
      shared: false,
      useHTML: true,
      valueSuffix: ''
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: settings.legend
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
          rotation: 0
        },
        events: {
          mouseOver() {
            
          },
          mouseOut() {
            
          }
        }
      }
    },
    yAxis: {
      title: {
        text: null
      }
    },
    colors: TREND_COLORS,
    series: [{
      name: tableData[0][1],
      data: seriesData,
      type: 'pie',
      tooltip: {
        valueSuffix: ''
      },
    }]
  };

  if (settings.dataLabels) {
    Object.assign(options.plotOptions.pie.dataLabels, { enabled: true });
  }

  return options;
};

export default getPieOptions;
