import { createContext, useContext } from 'react';

export type Translator = {
  (translationId: string, interpolateParams?: any): string;
  (translationId: string[], interpolateParams?: any): { [key: string]: string };
};

export const TranslationContext = createContext<Translator>(null);

/**
 * This hook is used to enable i18n in react components using angular-translate so that two i18n frameworks need not
 * be maintained.
 *
 * @example
 * const Component = () => {
 *   const { t } = useTranslation();
 *   return <p>{t('WORKBOOK.HELP_TEXT')}</p>
 * }
 *
 * This api was chosen because it is very similar to the api used by react-i18next's useTranslation hook and should make
 * a migration to i18next straightforward in the future
 */
export function useTranslation() {
  const t = useContext(TranslationContext);
  if (!t) {
    throw new Error(`Translation provider is unavailable. ${
      process.env.NODE_ENV === 'test'
        ? 'Ensure that "withRootComponent" is used with @testing-library/react\'s render method'
        : (process.env.NODE_ENV === 'development'
          ? 'Ensure that `.addDecorator(withTranslations())` is used with storybook'
          : '')
    }`);
  }
  const returnValue = [t];
  returnValue['t'] = t;
  return returnValue as [typeof t] & { t: typeof t };
}
