export * from './AccessKeysApi';
import { AccessKeysApi } from './AccessKeysApi';
export * from './AgentsApi';
import { AgentsApi } from './AgentsApi';
export * from './AncillariesApi';
import { AncillariesApi } from './AncillariesApi';
export * from './AnnotationsApi';
import { AnnotationsApi } from './AnnotationsApi';
export * from './AssetsApi';
import { AssetsApi } from './AssetsApi';
export * from './AuditApi';
import { AuditApi } from './AuditApi';
export * from './AuthApi';
import { AuthApi } from './AuthApi';
export * from './ConditionsApi';
import { ConditionsApi } from './ConditionsApi';
export * from './ContentApi';
import { ContentApi } from './ContentApi';
export * from './DatafilesApi';
import { DatafilesApi } from './DatafilesApi';
export * from './DatasourcesApi';
import { DatasourcesApi } from './DatasourcesApi';
export * from './ExportApi';
import { ExportApi } from './ExportApi';
export * from './FoldersApi';
import { FoldersApi } from './FoldersApi';
export * from './FormulasApi';
import { FormulasApi } from './FormulasApi';
export * from './ItemsApi';
import { ItemsApi } from './ItemsApi';
export * from './JobsApi';
import { JobsApi } from './JobsApi';
export * from './LogsApi';
import { LogsApi } from './LogsApi';
export * from './MetricsApi';
import { MetricsApi } from './MetricsApi';
export * from './MonitorsApi';
import { MonitorsApi } from './MonitorsApi';
export * from './NetworksApi';
import { NetworksApi } from './NetworksApi';
export * from './PluginsApi';
import { PluginsApi } from './PluginsApi';
export * from './ProjectsApi';
import { ProjectsApi } from './ProjectsApi';
export * from './ReportsApi';
import { ReportsApi } from './ReportsApi';
export * from './RequestsApi';
import { RequestsApi } from './RequestsApi';
export * from './SampleSeriesApi';
import { SampleSeriesApi } from './SampleSeriesApi';
export * from './ScalarsApi';
import { ScalarsApi } from './ScalarsApi';
export * from './SignalsApi';
import { SignalsApi } from './SignalsApi';
export * from './SubscriptionsApi';
import { SubscriptionsApi } from './SubscriptionsApi';
export * from './SystemApi';
import { SystemApi } from './SystemApi';
export * from './TreesApi';
import { TreesApi } from './TreesApi';
export * from './UserGroupsApi';
import { UserGroupsApi } from './UserGroupsApi';
export * from './UsersApi';
import { UsersApi } from './UsersApi';
export * from './WorkbooksApi';
import { WorkbooksApi } from './WorkbooksApi';
export const APIS = [AccessKeysApi, AgentsApi, AncillariesApi, AnnotationsApi, AssetsApi, AuditApi, AuthApi, ConditionsApi, ContentApi, DatafilesApi, DatasourcesApi, ExportApi, FoldersApi, FormulasApi, ItemsApi, JobsApi, LogsApi, MetricsApi, MonitorsApi, NetworksApi, PluginsApi, ProjectsApi, ReportsApi, RequestsApi, SampleSeriesApi, ScalarsApi, SignalsApi, SubscriptionsApi, SystemApi, TreesApi, UserGroupsApi, UsersApi, WorkbooksApi];
