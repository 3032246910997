import React, { useRef, useState } from 'react';
import { Button, Overlay } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import classNames from 'classnames';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { ToolbarDropdownTitle } from '@/hybrid/core/ToolbarDropdownTitle.molecule';
import _ from 'lodash';

const toolbarPopoverButtonBindings = bindingsDefinition({
  /** icon class to be used with the dropdown (i.e. 'fc-zoom') */
  icon: prop<string>(),
  /** label for the toolbar item (shown only when toolbar is wide enough, pass in the translation key) */
  label: prop<string>(),
  /** text to be displayed on the icon's tooltip (pass in the translation key) */
  tooltipText: prop.optional<string>(),
  tooltipPlacement: prop.optional<Placement>(),
  /** true to render an icon-only dropdown button */
  isSmall: prop.optional<boolean>(),
  extraClassNames: prop.optional<string>(),
  /** true if popover is currently open/active */
  active: prop.optional<boolean>(),
  testId: prop.optional<string>(),
  disabled: prop.optional<boolean>(),
  /** function called when popover is hidden */
  onHide: prop.optional<() => void>()
});

/**
 * Toolbar button that renders a popover element when clicked
 */
export const ToolbarPopoverButton: SeeqComponent<typeof toolbarPopoverButtonBindings> = (props) => {
  const {
    children,
    icon,
    label,
    tooltipText,
    tooltipPlacement = 'top',
    isSmall = false,
    active = false,
    disabled = false,
    testId,
    extraClassNames,
    onHide = _.noop
  } = props;
  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  return (
    <div ref={ref}>
      <Overlay
        placement="bottom"
        rootClose={true}
        transition={false}
        show={showPopover}
        target={target}
        onHide={() => {
          setShowPopover(false);
          onHide();
        }}
      >
        {children as any}
      </Overlay>
      <div
        className={classNames(extraClassNames, 'sq-btn-toolbar-dropdown', { 'sq-btn-toolbar-dropdown-sm': isSmall })}>
        <Button
          className={classNames({ 'btn-lg-text': !isSmall, 'btn-sm-text': isSmall })}
          type="button"
          size="sm"
          active={active}
          disabled={disabled}
          variant={null}
          bsPrefix="sq-btn"
          data-testid={testId}
          onClick={(e) => {
            e.stopPropagation();
            setTarget(ref.current);
            setShowPopover(!showPopover);
          }}
        >
          <ToolbarDropdownTitle
            label={label}
            isSmall={isSmall}
            tooltipText={tooltipText}
            tooltipPlacement={tooltipPlacement}
            icon={icon}
          />
        </Button>
      </div>
    </div>

  );
};
