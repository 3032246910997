import angular from 'angular';
import _ from 'lodash';
import { NotifierService } from '@/services/notifier.service';
import { UtilitiesService } from '@/services/utilities.service';
import { SocketService } from '@/services/socket.service';
import { LoggerService } from '@/services/logger.service';
import { StorageService } from '@/services/storage.service';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { PERSISTENCE_LEVEL, StateSynchronizerService } from '@/services/stateSynchronizer.service';
import { APP_STATE } from '@/main/app.constants';

/**
 * @file Requests and manages screenshots.
 *
 * This service has some functions which only run when the app is running in the headless browser used by
 *   NodeJS to capture screenshots. The 'callback' mechanism of that browser is used to send
 *   messages to NodeJS to inform it when the screenshot is ready to be taken.
 */

  // Screenshot rendering only supports a small subset of paths within the application, if the headless browser is
  // redirected to a page that isn't allowed the capture should fail. The load-error page is allowed because it has
  // special handling for failing the headless capture with a relevant message.

export const ALLOWED_SCREENSHOT_MODE_PATHS = /^\/(present|headless-capture-standby|load-error)/;

export enum HeadlessCategory {
  Screenshot = 'Screenshot',
  Thumbnail = 'Thumbnail'
}

/**
 * Test if {@param category} is a HeadlessCategory
 */
function isHeadlessCategory(category: unknown): category is HeadlessCategory {
  return _.isString(category) && Object.values<string>(HeadlessCategory).includes(category);
}

const dependencies = [
  'Sq.Services.Notifier'
];

angular.module('Sq.Services.Screenshot', dependencies).service('sqScreenshot', sqScreenshot);

export type ScreenshotService = ReturnType<typeof sqScreenshot>;

function sqScreenshot(
  $rootScope: ng.IRootScopeService,
  $http: ng.IHttpService,
  $state: ng.ui.IStateService,
  $q: ng.IQService,
  $interval: ng.IIntervalService,
  $location: ng.ILocationService,
  $injector: ng.auto.IInjectorService,
  sqNotifier: NotifierService,
  sqUtilities: UtilitiesService,
  sqSocket: SocketService,
  sqLogger: LoggerService,
  sqStorage: StorageService,
  sqPendingRequests: PendingRequestsService
) {

  const service = {
    disable,
    notifyCapture,
    notifyLoading,
    notifyError,
    notifyCancellation,
    generate,
    fetchHeadlessCaptureMetadata,
    headlessCaptureMetadata,
    initializeHeadlessCaptureMode,

    // Exposed for test
    onStateExit
  };

  let enabled = true;
  let captureMetadata: { category?: HeadlessCategory } = {};
  let deferredWorkbookId;
  let deferredWorksheetId;

  activate();

  return service;

  function activate() {
    $rootScope.$on('$stateChangeStart', onStateExit);
    $rootScope.$on('$destroy', onStateExit);
    $rootScope.$on('onBeforeUnload', onStateExit);
  }

  /**
   * Checks whether any deferred thumbnail generation requests were made that should be replaced with a 'forced'
   * (non-deferred) request, in preparation for leaving the worksheet.
   */
  function onStateExit() {
    // If we deferred any thumbnail generation, we make one more thumbnail generation
    // request with deferral set to False so that it is processed immediately without waiting for a timeout.
    // We still require that data come from the nodejs cache; if it isn't in that cache anymore, then the previous
    // thumbnail request will have already expired and generated the thumbnail.
    if ($state.current.name === APP_STATE.WORKSHEET
      && $state.params.workbookId === deferredWorkbookId
      && $state.params.worksheetId === deferredWorksheetId
      && $state.params.archived !== true) {
      service.generate(deferredWorkbookId, deferredWorksheetId, false);
    }
  }

  /**
   * Disables screenshots from being taken
   */
  function disable() {
    enabled = false;
  }

  /**
   * Tells headless capture browser that the app is ready to capture a screenshot.
   * This function does nothing unless the app is being run in the headless capture browser.
   */
  function notifyCapture() {
    function waitForQuiescence() {
      return $q.all([sqUtilities.waitForAppQuiescence(), sqUtilities.waitForPluginQuiescence()]);
    }

    if (sqUtilities.headlessRenderMode()) {
      // Wait for the app to settle (i.e. no more http requests or timers to fire), then wait 100ms and wait for
      // the app to settle again. This is an attempt to handle views that request data asynchronously some
      // (short) time after the view is first loaded.
      waitForQuiescence()
        .then(function() {
          return $q(function(resolve) {
            $interval(resolve, 100, 1);
          });
        })
        .then(waitForQuiescence)
        .then(function() {
          window.seeqHeadlessCapture();
        });
    }
  }

  /**
   * Tells the headless capture browser that the page is loading.
   * This function does nothing unless the app is being run in the headless capture browser.
   */
  function notifyLoading() {
    if (sqUtilities.headlessRenderMode()) {
      window.seeqHeadlessLoading();
    }
  }

  /**
   * Tells the headless capture browser that the page encountered an unrecoverable error
   * This function does nothing unless the app is being run in the headless capture browser.
   */
  function notifyError(...errors: string[]) {
    if (sqUtilities.headlessRenderMode()) {
      window.seeqHeadlessError(...errors);
    }
  }

  /**
   * Tells the headless capture browser that the currently-loading workbook has been cancelled by a user or
   * administrator.  This function does nothing unless the app is being run in the headless capture browser.
   */
  function notifyCancellation(...errors: string[]) {
    if (sqUtilities.headlessRenderMode()) {
      window.seeqHeadlessCancellation(...errors);
    }
  }

  /**
   * Calls the node server to trigger a screenshot generation. Does nothing if page is in the process of taking a
   * screenshot. Upon successful generation it updates the screenshot src for the specified worksheet.
   *
   * @param {String} workbookId - id of the workbook
   * @param {String} worksheetId - id of the worksheet
   * @param {Boolean} [defer] - True to tell the server to defer generating a thumbnail for this worksheet until a
   *   deferral time has elapsed where no other thumbnail requests have been received
   */
  function generate(workbookId, worksheetId, defer = true) {
    if (defer) {
      deferredWorkbookId = workbookId;
      deferredWorksheetId = worksheetId;
    } else {
      deferredWorkbookId = undefined;
      deferredWorksheetId = undefined;
    }

    if (!sqUtilities.headlessRenderMode() && workbookId && worksheetId && enabled) {
      $http.post('/thumbnails', {
          workbookId,
          worksheetId,
          defer
        },
        { ignoreLoadingBar: true })
        .catch(e => sqLogger.error(sqLogger.format`Error starting thumbnail generation: ${e}`));
    }
  }

  /**
   * Fetches the category of headless capture being requested so that it can be accessed synchronously in the page load
   */
  function fetchHeadlessCaptureMetadata() {
    if (!sqUtilities.headlessRenderMode()) {
      return $q.resolve();
    }

    // `resolve` here converts the browser Promise into $q promise
    return $q.resolve(window.seeqHeadlessCategory())
      .then((category) => {
        if (!isHeadlessCategory(category)) {
          throw new Error(`Error: '${category}' category is unexpected; the frontend enum likely needs to be updated`);
        }
        captureMetadata = {
          category
        };
      });
  }

  /**
   * Gets the category of headless capture being requested
   */
  function headlessCaptureMetadata() {
    return captureMetadata;
  }

  /**
   * Attaches callbacks to window that puppeteer can call as well as a state change handler that closes the socket
   * and clears local storage. Does nothing if not in screenshot render mode. See custom-typings.d.ts (Window) for
   * more detailed description of the callbacks attached
   */
  function initializeHeadlessCaptureMode() {
    if (!sqUtilities.headlessRenderMode()) {
      return;
    }

    // Provides a hook that the headless capture browser can use to cancel all requests from this browser
    window.seeqInternalPageCleanup = () => {
      sqLogger.info(`Cleaning up internal state via 'window.seeqInternalCleanup'`);
      return sqPendingRequests.cancelAll()
        .catch(e => sqLogger.error(sqLogger.format`Error cancelling all requests: ${e}`))
        // Not all screenshots require the socket to be open, but the socket service will get into a bad state if
        // the socket is closed before it is fully established - to prevent this wait for the socket to be open before
        // capturing the screenshot
        .then(() => sqSocket.waitForOpen())
        .catch(e => sqLogger.error(sqLogger.format`Error waiting for socket to open: ${e}`))
        .then(() => {
          sqSocket.close();
          sqStorage.store.clear();

          // Re-initialize all stores. This is necessary since some stores have special logic to retain certain
          // information even when they are rehydrated; search for 'saveState' for examples.
          const sqStateSynchronizer = $injector.get<StateSynchronizerService>('sqStateSynchronizer');
          // This prevents any outstanding calls to fetch state to be ignored if they finish after initialize()
          sqStateSynchronizer.unsetLoadingWorksheet();
          _.forEach(PERSISTENCE_LEVEL, (persistenceLevel) => {
            sqStateSynchronizer.initialize(persistenceLevel);
          });
        })
        .catch(e => sqLogger.error(sqLogger.format`Error cleaning up page state: ${e}`));
    };

    // Provides a hook that the headless capture browser can use to change states without reloading the page
    window.seeqNavigate = (url) => {
      if (!_.isString(url) || !_.startsWith(url, '/')) {
        throw new Error(`seeqNavigate: A relative url is required, but got '${url}'`);
      }

      $rootScope.$apply(() => {
        sqLogger.info(`Navigating to '${url}' via 'window.seeqNavigate'`);
        $location.url(url);
      });
    };

    sqNotifier.onAllServerRequestsCanceled(() => {
      service.notifyError('Administrator canceled all requests');
    });
  }
}
