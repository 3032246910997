import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import classNames from 'classnames';
import React from 'react';

interface TrendingCountProps {
  /** Number representing current value */
  currentCount: number;
  /** Number representing previous value, to determine direction of trend */
  otherCount: number;
  /** Translation key for label */
  label: string;
  /** True to show even if both values are 0 */
  showWhenZeros?: boolean;
  /** False to hide visual trend triangle */
  showIndicator?: boolean;
  testId?: string;
}

/**
 * Displays a number with label and (optional) visual up/down/blank triangle when greater/less/equal than a second
 * number, indicating the direction of trend (up/down/equal)
 */
export const TrendingCount: React.FunctionComponent<TrendingCountProps> = (props) => {
  const {
    currentCount,
    otherCount,
    label,
    showWhenZeros = false,
    showIndicator = true,
    testId
  } = props;

  const { t } = useTranslation();

  return (
    (currentCount !== 0 || otherCount !== 0 || showWhenZeros) ? (
      showIndicator ?
        <div className="flexColumnContainer" data-testid="trendingCountIndicator">
          <i
            data-testid={testId}
            className={classNames('fa', 'ml5', 'mr5', {
              'fa-caret-up sq-text-success': (currentCount > otherCount),
              'fa-caret-down sq-text-danger': (currentCount < otherCount),
              pr7: (currentCount === otherCount)
            })}
          />
          <div>{t(label, { count: currentCount })}</div>
        </div>
        : <div>{t(label, { count: currentCount })}</div>
    ) : null
  );
};
