import _ from 'lodash';
import angular from 'angular';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { ReferencePanelActions } from '@/hybrid/tools/referencePanel/referencePanel.actions';
import { AncillariesPanelStore } from '@/investigate/ancillariesPanel.store';
import { DISPLAY_MODE } from '@/main/app.constants';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { ANCILLARY_PAIR_TYPES, ANCILLARY_TYPES } from '@/investigate/ancillariesPanel.controller';
import { ItemsApi } from '@/sdk';

angular.module('Sq.Investigate').service('sqAncillariesPanelActions', sqAncillariesPanelActions);
export type AncillariesPanelActions = ReturnType<typeof sqAncillariesPanelActions>;

function sqAncillariesPanelActions(
  $translate: ng.translate.ITranslateService,
  flux: ng.IFluxService,
  sqInvestigateActions: InvestigateActions,
  sqReferencePanelActions: ReferencePanelActions,
  sqAncillariesPanelStore: AncillariesPanelStore,
  sqItemsApi: ItemsApi
) {
  const service = {
    setName,
    setType,
    setItem1,
    setItem2,
    gotoReferencePanel,
    returnToForm,
    edit,
    fetchAncillaries
  };

  return service;

  /**
   * Set the name of the ancillary.
   *
   * @param {String} name - the name of the ancillary
   */
  function setName(name) {
    flux.dispatch('ANCILLARIES_SET_NAME', { name });
  }

  /**
   * Set the type of the ancillary.
   *
   * @param {String} type - the type of the ancillary, one of ANCILLARY_TYPES
   */
  function setType(type) {
    flux.dispatch('ANCILLARIES_SET_TYPE', { type });
  }

  /**
   * Set the upper boundary signal for pair type or the primary item for other type.
   *
   * @param {Object} item - the item
   */
  function setItem1(item) {
    flux.dispatch('ANCILLARIES_SET_ITEM_1', { item });
  }

  /**
   * Set the lower boundary signal for pair type.
   *
   * @param {Object} item - the item
   */
  function setItem2(item) {
    flux.dispatch('ANCILLARIES_SET_ITEM_2', { item });
  }

  /**
   * Loads the reference panel with either a paired version or a new item with default name. Sets the inProgress flag
   * that indicates to the reference panel to return to this panel after the new item is created.
   *
   * @param {Object} item - The item to use as the input signal
   * @param {Object} ancillary - The ancillary that is being used as the template
   * @param {String} pairType - The type of ancillary item. One of ANCILLARY_PAIR_TYPES
   */
  function gotoReferencePanel(item, ancillary, pairType) {
    let other;
    let name = _.trim(item.name + ' ' + ancillary.name);
    let inProgressItem = 'item1';
    if (ancillary.type === ANCILLARY_TYPES.PAIR) {
      name += ' ' + $translate.instant(pairType === ANCILLARY_PAIR_TYPES.UPPER ?
        'ANCILLARIES.UPPER' : 'ANCILLARIES.LOWER');
      other = pairType === ANCILLARY_PAIR_TYPES.UPPER ? ancillary.item2 : ancillary.item1;
      inProgressItem = pairType === ANCILLARY_PAIR_TYPES.UPPER ? 'item1' : 'item2';
    }

    flux.dispatch('ANCILLARIES_SET_IN_PROGRESS', { inProgressItem });
    if (other && other.calculationType === TREND_TOOLS.REFERENCE) {
      sqInvestigateActions.loadToolForEdit(other.id)
        .then(_.partial(_.unary(sqReferencePanelActions.createPairing), name));
    } else {
      sqInvestigateActions.setActiveTool(TREND_TOOLS.REFERENCE);
      sqInvestigateActions.setSearchName(TREND_TOOLS.REFERENCE, name);
      sqInvestigateActions.setParameterItem(TREND_TOOLS.REFERENCE, 'inputSignal', item);
    }
  }

  /**
   * Called by other panels when an ancillary is in progress. Returns to the ancillary form and fills in the item
   * that was created.
   *
   * @param {Object} [item] - The item that was created.
   */
  function returnToForm(item?) {
    sqInvestigateActions.setActiveTool(TREND_TOOLS.ANCILLARIES, DISPLAY_MODE.EDIT);
    if (item) {
      service['set' + _.upperFirst(sqAncillariesPanelStore.inProgressItem)](item);
    }
  }

  /**
   * Changes the form state for an existing ancillary to be edited.
   *
   * @param {Object} ancillary - The the ancillary to be edited.
   */
  function edit(ancillary) {
    flux.dispatch('ANCILLARIES_EDIT', { ancillary });
    sqInvestigateActions.setDisplayMode(DISPLAY_MODE.EDIT);
  }

  /**
   * Fetches the ancillaries for an item.
   *
   * @param {String} id - The id of the item for which to fetch ancillaries.
   * @returns {Promise} Resolves when the data has been fetched.
   */
  function fetchAncillaries(id) {
    return sqItemsApi.getItemAndAllProperties({ id })
      .then(({ data }) => {
        flux.dispatch('ANCILLARIES_RESULTS', { ancillaries: data.ancillaries });
      });
  }
}
