import _ from 'lodash';
import angular from 'angular';
import { TrendStore } from '@/trendData/trend.store';
import { NotificationsService } from '@/services/notifications.service';
import { NumberHelperService } from '@/core/numberHelper.service';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { TrendChartItemsHelperService } from '@/trendData/trendChartItemsHelper.service';
import { UtilitiesService } from '@/services/utilities.service';
import {
  ITEM_CHILDREN_TYPES,
  ITEM_TYPES,
  TREND_BUFFER_FACTOR,
  TREND_BUFFER_FACTOR_STRING, TREND_STORES
} from '@/trendData/trendData.module';
import { DEBOUNCE } from '@/main/app.constants';
import { PUSH_IGNORE } from '@/services/stateSynchronizer.service';
import { LabelsService } from '@/trendViewer/label.service';
import { TrendActions } from '@/trendData/trend.actions';

angular.module('Sq.TrendData').service('sqYAxisActions', sqYAxisActions);
export type YAxisActions = ReturnType<typeof sqYAxisActions>;

function sqYAxisActions(
  flux: ng.IFluxService,
  $injector: ng.auto.IInjectorService,
  sqUtilities: UtilitiesService,
  sqTrendStore: TrendStore,
  sqNotifications: NotificationsService,
  sqNumberHelper: NumberHelperService,
  sqTrendDataHelper: TrendDataHelperService,
  sqTrendChartItemsHelper: TrendChartItemsHelperService,
  sqLabels: LabelsService
) {

  const service = {
    getYAxisConfig,
    oneYAxis,
    oneLane,
    resetAllAxes,
    // debounced because it is expensive due to iterating over all items
    updateLaneDisplay: _.debounce(updateLaneDisplay, DEBOUNCE.SHORT),
    setYExtremes,
    removeGaps
  };

  return service;

  /**
   * Returns the y axis config values for the item, based on all the items in the lane.
   *
   * @param {String} id - Id of the item
   */
  function getYAxisConfig(id) {
    const seriesToAlign = getYAxisItems();
    const item = sqTrendDataHelper.findItemIn(TREND_STORES, id);
    const laneCount = getDisplayedLanesCount();

    return getYAxisConfigValues(item, seriesToAlign, laneCount);
  }

  /**
   * Assigns all (selected) trends to the same y-axis scale.
   */
  function oneYAxis() {
    let seriesToAlign = sqTrendDataHelper.getAlignableItems({ workingSelection: true });

    if (_.some(seriesToAlign, 'isStringSeries') && seriesToAlign.length > 1) {
      sqNotifications.infoTranslate('NO_STRING_DATA_Y_AXIS_SHARING');
      seriesToAlign = _.reject(seriesToAlign, 'isStringSeries');
    }

    flux.dispatch('TREND_SET_CUSTOMIZATIONS', {
      items: _.map(seriesToAlign, ({ id }) => ({ id, axisAlign: undefined }))
    }, PUSH_IGNORE);

    const nextAlignment = sqTrendStore.nextAlignment;

    flux.dispatch('TREND_SET_CUSTOMIZATIONS', {
      items: _.map(seriesToAlign, ({ id }) => ({ id, axisAlign: nextAlignment, yAxisType: seriesToAlign[0].yAxisType }))
    });

    service.updateLaneDisplay();
  }

  /**
   * "Flattens" the trend by placing every series on the same lane, and an individual axis.
   * If series are selected then only the selected series will be placed on the same lane.
   * If there are unaligned y axes we're trying to put into one lane, removes gridlines if they exist.
   */
  function oneLane() {
    const seriesToAlign = sqTrendDataHelper.getAlignableItems({ workingSelection: true });

    flux.dispatch('TREND_SET_CUSTOMIZATIONS', {
      items: _.map(seriesToAlign, ({ id }) => ({ id, lane: undefined }))
    }, PUSH_IGNORE);

    const nextLane = sqTrendStore.nextLane;

    flux.dispatch('TREND_SET_CUSTOMIZATIONS', {
      items: _.map(seriesToAlign, ({ id }) => ({ id, lane: nextLane }))
    });

    service.updateLaneDisplay();
  }

  /**
   * Resets all lane and axis assignments and places each series on its own lane and axis.
   */
  function resetAllAxes() {
    flux.dispatch('TREND_SET_CUSTOMIZATIONS', {
      items: _.map(sqTrendDataHelper.getAlignableItems(), (item, index) => ({
        id: item.id,
        lane: sqTrendStore.lanes[index],
        axisAlign: sqTrendStore.alignments[index],
        axisVisibility: true,
        axisAutoScale: true,
        rightAxis: false
      }))
    });

    service.updateLaneDisplay();
  }

  /**
   * Sets the Y-extremes for a collection of items.
   *
   * @param {Object} extremes - Array of extremes
   * @param {Number} extremes[].min - y-axis lower bound
   * @param {Number} extremes[].max - y-axis upper bound
   * @param {String} extremes[].axisAlign - the y-axis to set the extremes for
   */
  function setYExtremes(extremes) {
    const fullItemList = sqTrendDataHelper.getAlignableItems();
    const laneCount = getDisplayedLanesCount();

    _.forEach(extremes, function(extreme: any) {
      const lanesOnSameAxis = _.filter(fullItemList, { axisAlign: extreme.axisAlign });

      const extremesToUpdate = _.map(lanesOnSameAxis, function(item: any) {
        const minAndMax = getBufferedExtremes({
          min: extreme.min,
          max: extreme.max
        }, item.isStringSeries, laneCount);

        return {
          id: item.id,
          yAxisConfig: {
            min: minAndMax.min,
            max: minAndMax.max
          },
          axisAutoScale: false,
          yAxisMin: extreme.min,
          yAxisMax: extreme.max
        };
      });

      flux.dispatch('TREND_SET_CUSTOMIZATIONS', { items: extremesToUpdate });
    });
  }

  /**
   * Handles the removal of items.
   * This function ensures that 'gaps' in alignment and lane assignments are corrected so proper rendering is
   * ensured.
   *
   */
  function removeGaps() {
    findGapAndAdjust('axisAlign', sqTrendStore.alignments);
    findGapAndAdjust('lane', sqTrendStore.lanes);
    service.updateLaneDisplay();

    function findGapAndAdjust(property, availableOptions) {
      const items = sqTrendDataHelper.getAlignableItems();
      const remaining = _.chain(items)
        .map(property)
        .sortBy()
        .uniq()
        .value();
      let lastUsedIndex = 0;

      let assignmentsToAdjust = [];
      for (let i = 0; i < remaining.length; i++) {
        if (remaining[i] !== availableOptions[i]) {
          // we found a gap - everything from here on out needs to be corrected:
          assignmentsToAdjust = _.slice(remaining, i);
          break;
        } else {
          lastUsedIndex = i + 1;
        }
      }

      const itemsToAdjust = [];
      if (!_.isEmpty(assignmentsToAdjust)) {
        _.forEach(assignmentsToAdjust, function(oldPropertyValue) {
          itemsToAdjust.push(
            _.filter(items, item => item[property] === oldPropertyValue)
          );
        });
      }

      const dispatchItems = [];
      _.forEach(itemsToAdjust, function(items) {
        const newOptionValue = availableOptions[lastUsedIndex];
        _.forEach(items, function(item: any) {
          dispatchItems.push({
            id: item.id,
            [property]: newOptionValue
          });
        });

        lastUsedIndex++;
      });

      flux.dispatch('TREND_SET_CUSTOMIZATIONS', { items: dispatchItems });
    }
  }

  /**
   * Adjusts the y-axes of all of the selected trends so that they each take up their own horizontal "lane" on the
   * chart.
   * For each series re-compute the min and max y-value based on the lane the series is displayed within.
   * If the series is a straight line then a range is artificially created so that the series can be spread properly.
   * String series are not considered for the range calculation as it doesn't make sense to "align" axis scale for
   * Strings.
   */
  function updateLaneDisplay() {
    // Must be done before we evaluate the min and the max of the y-axis
    mergeCapsuleTimeStringSeriesEnums();

    const seriesToAlign = getYAxisItems();
    const laneCount = getDisplayedLanesCount();

    const yAxisValues = _.chain(sqTrendDataHelper.getAlignableItems())
      .groupBy('lane')
      .flatMap(seriesInLane => _.map(seriesInLane,
        series => _.assign({ id: series.id }, getYAxisConfigValues(series, seriesToAlign, laneCount))
      ))
      .value();

    // Remove gridlines and show a warning if we're putting multiple y axes in one lane
    if (sqTrendStore.showGridlines && !sqTrendStore.shouldGridlinesBeShown(seriesToAlign)) {
      sqNotifications.infoTranslate('NO_GRIDLINES_FOR_MULTIPLE_Y_AXES_ONE_LANE');
      $injector.get<TrendActions>('sqTrendActions').setGridlines(false, true);
    }

    flux.dispatch('TREND_SET_CUSTOMIZATIONS', { items: yAxisValues }, PUSH_IGNORE);
  }

  /**
   * Merges the string enum for string series in capsule time so that they display correctly. Without enum merging,
   * string series that share the same axis could display in a misleading way because a string value from each
   * signal could map to a different value on the chart.
   */
  function mergeCapsuleTimeStringSeriesEnums() {
    _.chain(sqTrendDataHelper.getAllChildItems({ itemTypes: [ITEM_TYPES.SERIES] }))
      .filter('isStringSeries')
      .groupBy('isChildOf')
      .forEach((series) => {
        // Prefer larger enums first since they are more likely to contain all the values needed - this leads to the
        // enum being more consistent with calendar view most of the time
        const sortedSeries = _.orderBy(series, [s => s.calculatedStringEnum.length], ['desc']);
        const mergedStringEnum = _.reduce(sortedSeries, (stringEnum, item: any) => {
          const unusedKeys = _.filter(
            _.range(stringEnum.length + item.calculatedStringEnum.length),
            i => !_.find(stringEnum, ['key', i])
          );
          const missingEnum = _.chain(item.calculatedStringEnum)
            .filter(({ stringValue }) => !_.find(stringEnum, ['stringValue', stringValue]))
            .map(({ stringValue, key }) => {
              if (!_.find(stringEnum, ['key', key])) {
                return { stringValue, key };
              } else {
                const key = unusedKeys.shift();
                return { stringValue, key };
              }
            })
            .value();
          return _.concat(stringEnum, missingEnum);
        }, []);

        flux.dispatch('TREND_SIGNAL_SET_STRING_ENUM', { ids: _.map(series, 'id'), stringEnum: mergedStringEnum },
          PUSH_IGNORE);
      })
      .value();
  }

  /**
   * Given the minimum and maximum y-values from across a set of series, return a set of y-axis extremes that
   * will show the range from the minimum to maximum y-values plus a buffer on each end.
   *
   * @param {Object} minAndMaxYValues - An object containing the min and max y-values from a set of series
   * @param {Number|String} minAndMaxYValues.min - The minimum y-value found in a set of series
   * @param {Number|String} minAndMaxYValues.max - The maxiumum y-value found in a set of series
   * @param {Boolean} isStringSeries - Flag indicating if the series is a String Series (they get a bigger buffer to
   * ensure the labels are not cut off)
   * @param {Number} laneCount - Number of lanes displayed. Used to adjust the buffer dynamically.
   *
   * @return {Object} An object containing the calculated extremes as newMin, newMax
   */
  function getBufferedExtremes(minAndMaxYValues, isStringSeries, laneCount) {
    let newRange;
    const buffer = isStringSeries ? TREND_BUFFER_FACTOR_STRING : TREND_BUFFER_FACTOR * laneCount;

    newRange = Number(minAndMaxYValues.max) - Number(minAndMaxYValues.min);
    return {
      min: Number(minAndMaxYValues.min) - buffer * newRange,
      max: Number(minAndMaxYValues.max) + buffer * newRange
    };
  }

  /**
   * Given a set of series, return the minimum and maximum y-value across all series.
   * @param {Object} item - The current item that contains the configuration for the axis
   * @param {Object[]} seriesToAlign - An array of series that contain data for this lane
   * @return {Object} An object containing the calculated min and max
   */
  function getMinAndMaxYValue(item, seriesToAlign) {
    // Add item to the list before we filter for those that are not autoScaled.  Sometimes statistics wil not be
    // included in the seriesToAlign list, but we will use their min/max
    const scaledSeries = _.filter(_.concat(seriesToAlign, [item]), ['axisAutoScale', false]);
    let autoScale = scaledSeries.length === 0;

    if (item.isStringSeries) {
      autoScale = true; // we can never not auto-scale string series as that will just look really bad.
    }

    if (!autoScale) {
      const minValues = [];
      const maxValues = [];
      _.forEach(scaledSeries, function(s: any) {
        if (sqNumberHelper.isNumeric(s.yAxisMin) && sqNumberHelper.isNumeric(s.yAxisMax)) {
          minValues.push(s.yAxisMin);
          maxValues.push(s.yAxisMax);
        }
      });
      if (minValues.length > 0 && maxValues.length > 0) {
        return {
          min: _.min(minValues),
          max: _.max(maxValues)
        };
      }
    }

    let returnMin = Infinity;
    let returnMax = -Infinity;
    for (const series of seriesToAlign) {
      for (const datum of series.data) {
        if (_.isArray(datum) && _.isFinite(datum[1])) {
          returnMin = Math.min(returnMin, datum[1]);
          returnMax = Math.max(returnMax, datum[1]);
        }

        if (_.isArray(datum) && _.isFinite(datum[2])) { // upper value for shaded area
          returnMin = Math.min(returnMin, datum[2]);
          returnMax = Math.max(returnMax, datum[2]);
        }

        if (!_.isArray(datum) && _.isFinite(datum.y)) { // marker for discrete sample
          returnMin = Math.min(returnMin, datum.y);
          returnMax = Math.max(returnMax, datum.y);
        }
      }
    }

    if (!_.isFinite(returnMin) || !_.isFinite(returnMax)) {
      // When there is no data or no series to align then use 0 so that we get a blank axis from -1 to 1
      returnMin = returnMax = 0;
    }

    if (returnMax - returnMin === 0) {
      // Artificially manipulate the min and max values for a flat line
      const mag = Math.floor(Math.log(Math.abs(returnMin)) / Math.log(10));
      const magPow = returnMin !== 0 ? Math.pow(10, mag) : 1;
      returnMin = returnMin - magPow;
      returnMax = returnMax + magPow;
    }

    return {
      min: returnMin,
      max: returnMax
    };
  }

  function getYAxisConfigValues(item, seriesToAlign, laneCount) {
    // find all the series with the same alignment designation:
    const seriesInAlignmentGroup = _.chain(seriesToAlign)
      .filter(['axisAlign', item.axisAlign])
      .uniq()
      .value();

    const rawMinMax = getMinAndMaxYValue(item, seriesInAlignmentGroup);
    const minAndMax = getBufferedExtremes(rawMinMax, item.isStringSeries, laneCount);

    return {
      yAxisConfig: {
        min: minAndMax.min,
        max: minAndMax.max
      },
      gridLineWidth: sqLabels.getGridlineWidth(),
      yAxisMin: rawMinMax.min,
      yAxisMax: rawMinMax.max,
      yAxisType: item.yAxisType,
      rightAxis: _.get(item, 'rightAxis', false)
    };
  }

  /**
   * Gets the number of displayed lanes on the trend
   */
  function getDisplayedLanesCount() {
    return sqUtilities.getUniqueOrderedValuesByProperty(sqTrendDataHelper.getAlignableItems({
      workingSelection: sqTrendStore.hideUnselectedItems
    }), 'lane', sqTrendStore.lanes).length;
  }

  /**
   * Gets all the signals that will be shown in the axis, this must include previews and children
   * items so that the extremes take into account those items
   *
   * @return {Object[]} A subset of series on which the user is currently acting
   */
  function getYAxisItems() {
    // TODO Cody Ray Hoeft [Metrics] - Ancillaries are not shown in capsule time! (CRAB-12176)
    // If we need to make it work for Metrics, we might as well make it work for Ancillaries
    // It is unclear how we would make that work with the current system where each
    // SERIES_FROM_CAPSULE is an independent item in store. It is also unclear how valuable
    // capsule time would be for metrics (especially wouldn't thresholds be a mess?)
    return _.reject(sqTrendDataHelper.getAllItems({
      includeSignalPreview: true,
      itemTypes: [ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR],
      itemChildrenTypes: _.compact([
        !sqTrendStore.isTrendViewCapsuleTime() && ITEM_CHILDREN_TYPES.ANCILLARY,
        !sqTrendStore.isTrendViewCapsuleTime() && ITEM_CHILDREN_TYPES.METRIC_DISPLAY,
        !sqTrendStore.isTrendViewCapsuleTime() && ITEM_CHILDREN_TYPES.METRIC_THRESHOLD,
        sqTrendStore.isTrendViewCapsuleTime() && ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE
      ])
    }), item => sqTrendChartItemsHelper.isHidden(item));
  }
}
