import _ from 'lodash';
import angular from 'angular';
import { NotificationsService } from '@/services/notifications.service';
import { ItemsApi } from 'sdk/api/ItemsApi';
import { UsersApi } from 'sdk/api/UsersApi';
import { ModalService } from '@/services/modal.service';
import { UtilitiesService } from '@/services/utilities.service';
import { EVERYONE_USERGROUP } from '@/hybrid/administration/Administration.page';

const dependencies = [
  'Sq.Services.Modal',
  'Sq.Services.Utilities'
];

export type ACLService = ReturnType<typeof SqACLService>;

/**
 * @file Provides easy access to item ACL and functions to manage ACL.
 */
angular.module('Sq.Services.ACLService', dependencies).factory('SqACLService', SqACLService);

function SqACLService(
  $q: ng.IQService,
  sqModal: ModalService,
  sqNotifications: NotificationsService,
  sqItemsApi: ItemsApi,
  sqUsersApi: UsersApi,
  sqUtilities: UtilitiesService) {

  const service = {
    getItemACL,
    getItemACLDetails,
    getItemName,
    isEveryoneAvailable,
    isAsset,
    getSwapSourceIdIfSwap
  };
  return service;

  /**
   * Retrieves the ACL for a given item.
   *
   * @param {String} id - the id of the item
   * @returns {Promise} that resolves with the ACL for the item.
   */
  function getItemACL(id) {
    return sqItemsApi.getAccessControl({ id })
      .then(({ data: { entries, permissionsInheritanceDisabled, permissionsManagedByDatasource } }) => {
        return {
          entries,
          permissionsInheritanceDisabled,
          permissionsManagedByDatasource
        };
      })
      .catch((error) => {
        sqNotifications.apiError(error, { skipRedaction: true });
        return $q.reject(error);
      });
  }

  /**
   * Retrieves the ACL Details for a given item. The ACL Details include information on  were permissions are inherited
   * from.
   *
   * @param {String} id - the id of the item
   * @param {boolean} forceInheritance - if true, the api call will be made as if the item has acl inheritance enabled
   * @returns {Promise} that resolves with the ACL Details for the item.
   */
  function getItemACLDetails(id, forceAclInheritance = false) {
    return sqItemsApi.getAccessControlDetails({ id, forceAclInheritance })
      .then(({ data: { entries } }) => ({ entries }))
      .catch((error) => {
        sqNotifications.apiError(error, { skipRedaction: true });
        return $q.reject(error);
      });
  }

  /**
   * Gets the name of the item.
   *
   * @param {string} id - the item id for which the name should be retrieved
   * @returns {Promise} - a promise that resolves with the item name
   */
  function getItemName(id) {
    return sqItemsApi.getProperty({ id, propertyName: 'Name' })
      .then(result => _.get(result, 'data.value'))
      .catch(sqNotifications.apiError);
  }

  /**
   * Determines if the user has permission to see the Everyone group.
   *
   * @returns {Promise} - a promise that resolves with true if the Everyone group is enabled and the user has
   * permission to see it, false otherwise.
   */
  function isEveryoneAvailable() {
    return sqUsersApi.autocompleteUsersAndGroups({ query: EVERYONE_USERGROUP })
      .then((result) => {
        return _.chain(result)
          .get('data.items')
          .some(item => item.name === EVERYONE_USERGROUP && item.type === 'UserGroup')
          .value();
      })
      .catch(sqNotifications.apiError);
  }

  /**
   * Determines if the item we are getting the ACL for is an asset, in which case we do not allow the user to modify it.
   *
   * @returns {Promise} - a promise that resolves with true if the item is an asset, false otherwise.
   */
  function isAsset(id) {
    return sqItemsApi.getItemAndAllProperties({ id })
      .then(result => _.get(result, 'data'))
      .then(item => sqUtilities.isAsset(item) && !sqUtilities.isAssetGroup(item))
      .catch(sqNotifications.apiError);
  }

  /**
   * Determines if the item we are getting the ACL for is a swapped item, in which case we read/write from the source
   * item.
   *
   * @returns {Promise} - a promise that resolves with the id of the swap source if the item id is from a swap, or
   * the id unchanged if the id was not from a swap.
   */
  function getSwapSourceIdIfSwap(id) {
    return sqItemsApi.getProperty({ id, propertyName: 'Swap Source ID' })
      .then(result => _.get(result, 'data.value'))
      .catch(() => id);
  }
}
