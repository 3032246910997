type Cleanup = () => void;
type AddCleanup = (c: Cleanup) => void;

export type CleanupOnDestroyOnce = AddCleanup;
export type CleanupOnDestroy = AddCleanup;
export type CleanupBeforeNextInvocation = AddCleanup;

export function getCleanupLogic() {
  const cleanupOnDestroyOnceStore = {};
  function cleanupOnDestroyOnce(id: string): AddCleanup {
    return function(c: Cleanup): void {
      cleanupOnDestroyOnceStore[id] = c;
    };
  }

  const cleanupOnDestroyStore = {};
  function cleanupOnDestroy(id: string): AddCleanup {
    return function(c: Cleanup): void {
      if (cleanupOnDestroyStore[id] === undefined) {
        cleanupOnDestroyStore[id] = [];
      }
      cleanupOnDestroyStore[id].push(c);
    };
  }

  const cleanupBeforeNextInvocationStore = {};
  function cleanupBeforeNextInvocation(id: string): { store: AddCleanup, cleanup: Cleanup } {
    function store(c: Cleanup): void {
      cleanupBeforeNextInvocationStore[id] = c;
    }

    function cleanup(): void {
      if (cleanupBeforeNextInvocationStore[id] !== undefined) {
        cleanupBeforeNextInvocationStore[id]();
      }
    }

    return { store, cleanup };
  }

  function destroy() {
    Object.keys(cleanupOnDestroyOnceStore).forEach(id => cleanupOnDestroyOnceStore[id]());
    Object.keys(cleanupOnDestroyStore).forEach(id => cleanupOnDestroyStore[id].reverse().forEach(c => c()));
    Object.keys(cleanupBeforeNextInvocationStore).forEach(id => cleanupBeforeNextInvocationStore[id]());
  }

  return {
    cleanupOnDestroyOnce,
    cleanupOnDestroy,
    cleanupBeforeNextInvocation,
    destroy
  };
}
