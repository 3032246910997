import React, { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import SelectItem from '@/hybrid/core/SelectItem.organism';
import { Icon } from '@/hybrid/core/Icon.atom';
import { RedactionService } from '@/services/redaction.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { AddFormulaParameterModal } from '@/hybrid/formula/AddFormulaParameterModal.molecule';
import { EditableText } from '@/hybrid/core/EditableText.atom';
import { NotificationsService } from '@/services/notifications.service';

export interface FormulaEditorParam {
  name: string;
  item: { id: string, name: string, type?: string };
  identifier?: string;
}

const formulaParametersTableBindings = bindingsDefinition({
  resizeEnabled: prop.optional<boolean>(),
  parameters: prop<FormulaEditorParam[]>(),
  updateParameterCallback: prop<(updated: FormulaEditorParam, originalParam: FormulaEditorParam) => void>(),
  removeParameterCallback: prop<(identifier: string) => void>(),
  insertParameter: prop.optional<(param: string) => void>(),
  additionalItems: prop.optional<any[]>(),
  excludeStoreItems: prop.optional<boolean>(),
  onItemSelect: prop<(idx: any, item: any) => void>(),
  includeAddToDisplayPane: prop.optional<boolean>(),
  sqRedaction: injected<RedactionService>(),
  sqNotifications: injected<NotificationsService>()
});

export const FormulaParametersTable: SeeqComponent<typeof formulaParametersTableBindings> = ({
  resizeEnabled = true,
  additionalItems,
  insertParameter,
  parameters,
  onItemSelect,
  excludeStoreItems,
  updateParameterCallback,
  removeParameterCallback,
  includeAddToDisplayPane = true
}) => {
  const { sqRedaction, sqNotifications } = useInjectedBindings(formulaParametersTableBindings);
  const { t } = useTranslation();
  const [showItemModal, setShowItemModal] = useState(undefined);

  const updateParameter = (updatedParameter: FormulaEditorParam, originalParameter: FormulaEditorParam) => {
    if (updatedParameter.name !== originalParameter.name && _.some(parameters as any,
      { identifier: updatedParameter.identifier })) {
      sqNotifications.warnTranslate('FORMULA.VARIABLE_UNIQUE');
    } else {
      updateParameterCallback(updatedParameter, originalParameter);
    }
  };
  const iconClass = resizeEnabled ? 'ml10' : 'ml5';
  return (
    <>
      <table className="table table-striped table-condensed mb3">
        <thead>
          <tr>
            <th className="min-width-47">{t('NAME')}</th>
            <th className="max-width-370">{t('ITEM')}</th>
            <th className="width-minimum" />
          </tr>
        </thead>
        <tbody>
          {_.isEmpty(parameters) &&
          <tr className="text-center text-italic">
            <td colSpan={3} className="flexCenter flexFill">{t('FORMULA.NO_VARIABLES')}</td>
          </tr>}
          {_.map(parameters, (parameter, idx) => (
            <tr key={parameter.identifier} data-testid={`formulaParameter_${idx}`}>
              <td className="text-valign-middle flexColumnContainer">
                <strong>$</strong>
                <EditableText
                  testId="rowName"
                  value={parameter.identifier}
                  allowEditing={true}
                  inputClasses="flexFill text-bold width-50"
                  textClasses="textAlignLeft text-bold width-50"
                  maxDisplayChars={10}
                  onUpdate={identifier =>
                    updateParameter({ ...parameter, identifier, name: identifier }, parameter)
                  } />
              </td>
              <td className={classNames(resizeEnabled ? 'min-width-200' : 'flexFill')}>
                {resizeEnabled
                  ?
                  <SelectItem
                    selectedItemId={parameter.item.id}
                    excludeStoreItems={excludeStoreItems}
                    showAddToDisplayPane={includeAddToDisplayPane}
                    additionalItems={additionalItems}
                    paramIndex={idx + ''}
                    identifier={parameter.identifier}
                    className={`select_${parameter.name}`}
                    onSelect={onItemSelect}
                    selectByIdAndGroup={true} />
                  :
                  <span>
                    {sqRedaction.isItemRedacted(parameter.item) &&
                    <Icon tooltip="NO_ITEM_ACCESS" icon="fa-exclamation-triangle" extraClassNames="sq-text-danger" />
                    }{parameter.item.name}
                  </span>
                }
              </td>
              <td className="nowrap text-valign-middle">
                <Icon
                  icon="fa-plus"
                  testId={`insertParam_${parameter.identifier}`}
                  extraClassNames={iconClass}
                  onClick={() => insertParameter(`$${parameter.identifier}`)}
                  tooltip="ADD" />
                <Icon
                  icon="fa-pencil"
                  testId={`editParam_${parameter.identifier}`}
                  extraClassNames={iconClass}
                  onClick={() => setShowItemModal(parameter)}
                  tooltip="EDIT" />
                <Icon
                  icon="fa-remove"
                  testId={`removeParam_${parameter.identifier}`}
                  extraClassNames={iconClass}
                  onClick={() => removeParameterCallback(parameter.identifier)}
                  tooltip="REMOVE" />
              </td>
            </tr>))}
        </tbody>
      </table>
      {showItemModal &&
      <AddFormulaParameterModal
        parameters={parameters}
        parameter={showItemModal}
        onSave={updateParameter}
        onClose={() => setShowItemModal(undefined)}
        testId="functionParamModal" />}
    </>
  );
};
export default FormulaParametersTable;
export const sqFormulaInputTable = angularComponent(formulaParametersTableBindings, FormulaParametersTable);
