import React, { useState } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { EditWorkbookModal } from '@/hybrid/homescreen/EditWorkbookModal.organism';
import { FolderExplorerModal } from '@/hybrid/explorer/FolderExplorerModal.organism';
import _ from 'lodash';
import { APP_STATE, HOME_SCREEN_TABS } from '@/main/app.constants';
import { PinnedActionIcon } from '@/hybrid/homescreen/PinnedActionIcon.molecule';
import { NotificationsService } from '@/services/notifications.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { AuthorizationService } from '@/services/authorization.service';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { HOME_SCREEN_SORT, HOME_SCREEN_TABLE_TYPE, ITEM_TYPES } from '@/hybrid/homescreen/homescreen.module';
import { HomeScreenInfoDropdown } from '@/hybrid/homescreen/HomeScreenInfoDropdown.organism';
import { HomeScreenStore } from '@/hybrid/homescreen/homescreen.store';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { UtilitiesService } from '@/services/utilities.service';
import { EventCapture } from '@/hybrid/core/EventCapture.atom';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { ButtonWithDropdown } from '@/hybrid/core/ButtonWithDropdown.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { ItemAclModal } from '@/hybrid/accessControl/ItemAclModal.molecule';

const homeScreenItemActionBindings = bindingsDefinition({
  item: prop<any>(),
  openAction: prop<(object: { id: string }) => void>(),
  currentFolderId: prop<string>(),
  sqNotifications: injected<NotificationsService>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
  sqHomeScreenStore: injected<HomeScreenStore>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  sqAuthorization: injected<AuthorizationService>(),
  sqUtilities: injected<UtilitiesService>(),
  $state: injected<ng.ui.IStateService>()
});

export const HomeScreenItemActions: SeeqComponent<typeof homeScreenItemActionBindings> = (props) => {
  const {
    sqHomeScreenStore,
    sqHomeScreenUtilities,
    sqNotifications,
    sqHomeScreenActions,
    sqAuthorization,
    sqUtilities,
    $state
  } = useInjectedBindings(homeScreenItemActionBindings);

  const { currentFolderId, openAction } = props;

  let { item } = props;
  // legacy code that gets called relies on a 'workbookId'
  item = _.assign({}, item, { workbookId: item.id });

  const { t } = useTranslation();

  const [showFolderExplorerModal, setShowFolderExplorerModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showItemAclModal, setShowItemAclModal] = useState(false);
  const [workbookId, setWorkbookId] = useState('');
  const [worksheetId, setWorksheetId] = useState('');

  const [, setIsCopying] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const isReportBinder = _.get(item, 'type') === ITEM_TYPES.TOPIC;
  const baseClasses = classNames('sq-text-primary', 'fa', 'fa-fw', 'correctSpin', 'mr10',
    { folderActions: !(isMenuOpen || isInfoOpen) });

  const handleEditNameClose = () => setShowEditModal(false);
  const handleFolderExplorerClose = () => setShowFolderExplorerModal(false);
  const handleItemAclClose = () => setShowItemAclModal(false);
  const currentTab = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.currentTab);
  let corporateTab;

  const restoreWorkbook = () => {
    // Make the workbook visible in the all items view, this works only because the workbook is always restored to
    // the top level of the workbench (never to a parent folder). We also don't need to update the filter because
    // the user needed to have the workbook displayed to restore it.
    // Note that we do this before restoring the workbook so as to avoid race conditions where the User doesn't yet have
    // the right display selector saved by the time we're ready to show the workbooks.

    return sqHomeScreenActions.restoreWorkbook(item)
      .then(() => sqHomeScreenUtilities.getTabFolder(HOME_SCREEN_TABS.CORPORATE))
      .then(tabFolder => corporateTab = tabFolder)
      .then(() => sqHomeScreenUtilities.getWorkbenchItem(item.id))
      .then((item) => {
        const parentId = _.chain(item).get('ancestors').last().get('id').value();
        if (_.isNil(parentId)) {
          return $state.go(APP_STATE.WORKBOOKS, { t: sqUtilities.generateTabHash(HOME_SCREEN_TABS.MY_FOLDER) },
            { reload: true });
        } else {
          const tab = sqUtilities.equalsIgnoreCase(parentId, corporateTab.id)
            ? HOME_SCREEN_TABS.CORPORATE
            : HOME_SCREEN_TABS.MY_FOLDER;
          return $state.go(APP_STATE.FOLDER_EXPANDED, {
              currentFolderId: parentId,
              t: sqUtilities.generateTabHash(tab)
            },
            { reload: true });
        }
      })
      .then(() => sqNotifications.successTranslate('TRASH.ITEM_RESTORED_NOTIFICATION', { ITEM_NAME: item.name }))
      .catch(error => sqNotifications.apiError(error, { displayForbidden: true }));
  };

  const trashItem = () => {
    sqHomeScreenActions.removeWorkbook(item)
      .then(() => {
        // Try to keep the user in the same view/folder they were in before they removed the item
        if (sqHomeScreenStore.currentFolderId) {
          return $state.go(APP_STATE.FOLDER_EXPANDED, { currentFolderId }, { reload: true });
        } else {
          return $state.go(APP_STATE.WORKBOOKS, {}, { reload: true });
        }
      })
      .then(() => {
        if (sqAuthorization.canManageItem(item)) {
          return sqNotifications.custom(sqNotifications.success, 'TRASH.ITEM_TRASHED_NOTIFICATION',
            () => restoreWorkbook(), { ITEM_NAME: item.name }, { buttonTranslateKey: 'RESTORE' });
        } else {
          return sqNotifications.success(t('TRASH.ITEM_TRASHED_NOTIFICATION', { ITEM_NAME: item.name }));
        }
      })
      .catch(error => sqNotifications.apiError(error, { displayForbidden: true }));
  };

  const duplicate = () => {
    let duplicate;
    let myFolderTab;
    const parentFolderId = item.parentFolderId;
    setIsCopying(true);

    const getAllowedParentPromise = sqHomeScreenUtilities.getTabFolder(HOME_SCREEN_TABS.MY_FOLDER)
      .then(tabFolder => myFolderTab = tabFolder)
      .then(() => sqHomeScreenActions.canCreateItemInFolder(parentFolderId))
      .then(
        canWrite => canWrite ? parentFolderId : myFolderTab.id);

    const navigateToItem = (item) => {
      if (item.parentFolderId !== myFolderTab.id) {
        return $state.go(APP_STATE.FOLDER_EXPANDED, { currentFolderId: parentFolderId },
          { reload: true });
      } else {
        return $state.go(APP_STATE.WORKBOOKS, { t: sqUtilities.generateTabHash(HOME_SCREEN_TABS.MY_FOLDER) },
          { reload: true });
      }
    };

    if (item.type === ITEM_TYPES.FOLDER) {
      return getAllowedParentPromise
        .then((parentFolderId) => {
          sqHomeScreenActions.addFolder({
              name: `${item.name} - ${t('COPY')}`,
              parentFolderId,
              branchFrom: item.id
            })
            .then(folder => navigateToItem(folder))
            .catch(error => sqNotifications.apiError(error, { displayForbidden: true }))
            .finally(() => setIsCopying(false));
        });
    } else {
      return getAllowedParentPromise
        .then((folderId) => {
          sqHomeScreenActions.addWorkbook({
            name: `${item.name} - ${t('COPY')}`,
            branchFrom: item.id,
            isReportBinder,
            folderId
          }).then((item) => {
            duplicate = item;
            sqHomeScreenActions.setTableSort(HOME_SCREEN_SORT.CREATED_AT, false, HOME_SCREEN_TABLE_TYPE.TAB);
            navigateToItem(item);
          }).then(() => {
              sqNotifications.custom(sqNotifications.success, 'HOME_SCREEN.DUPLICATION_SUCCESS',
                () => openAction(duplicate), { ITEM_NAME: duplicate.name }, { buttonTranslateKey: 'OPEN', faIcon: null });
            })
            .catch(error => sqNotifications.apiError(error, { displayForbidden: true }))
            .finally(() => setIsCopying(false));
        });
    }
  };

  const renderDropdownEntry = ({ iconClass, translationKey, action, display = false }) => {
    if (display) {
      return (
        <Dropdown.Item key={`dropdown_${translationKey}`} onClick={action} className="sq-force-text-gray">
          <i className={classNames('fa', 'pr10', iconClass)} />
          <span className="forceFont" data-testid={`moreActions_${translationKey}`}>{t(translationKey)}</span>
        </Dropdown.Item>
      );
    }
  };

  const displayingTrash = currentTab === HOME_SCREEN_TABS.TRASH;

  const actions = [
    {
      iconClass: 'fa-edit',
      translationKey: 'WORKBENCH.EDIT',
      action: () => setShowEditModal(true),
      display: sqAuthorization.canModifyWorkbook(item) && !displayingTrash
    },
    {
      iconClass: 'fa-copy',
      translationKey: 'WORKBENCH.DUPLICATE',
      action: duplicate,
      display: sqAuthorization.canReadItem(item) && !sqHomeScreenUtilities.isProject(item)
    },
    {
      iconClass: 'fc fc-share',
      translationKey: 'WORKBENCH.ACCESS_CONTROL',
      action: () => setShowItemAclModal(true),
      display: sqAuthorization.canManageItem(item) && !displayingTrash
    },
    {
      iconClass: 'fc fc-folder-move',
      translationKey: 'WORKBENCH.MOVE_TO_FOLDER',
      action: () => setShowFolderExplorerModal(true),
      display: sqAuthorization.canManageItem(item) && !displayingTrash
    },
    {
      iconClass: 'fa-trash',
      translationKey: 'WORKBENCH.DELETE',
      action: trashItem,
      display: sqAuthorization.canModifyWorkbook(item) && !displayingTrash
    },
    {
      iconClass: 'fc fc-restore',
      translationKey: 'RESTORE',
      action: restoreWorkbook,
      // Restoring an item may move it to the user's home folder, which would grant them manage permission, so they
      // need to already have it.
      display: sqAuthorization.canManageItem(item) && displayingTrash
    }
  ];

  const onPinnedSet = (state) => {
    item.isPinned = state;
    if (currentTab === HOME_SCREEN_TABS.HOME) {
      sqHomeScreenActions.loadFolder(currentFolderId, currentTab);
    }
  };

  return (
    <div className="flexColumnContainer positionRelative">
      <EventCapture>
        {!displayingTrash &&
        <PinnedActionIcon
          item={item}
          onChange={onPinnedSet}
          activeWorkbook={isMenuOpen || isInfoOpen} />}

        <div className={baseClasses} data-testid='homeScreenInfoDropdown'>
          <HomeScreenInfoDropdown item={item} setIsOpen={isOpen => setIsInfoOpen(isOpen)} />
        </div>

        {_.some(actions, _.property('display')) &&
        <div className={classNames(baseClasses, 'ml6')}>
          <ButtonWithDropdown
            icon={<Icon icon="fc-more" extraClassNames="fa-fw width-20" />}
            iconWrapperClasses="pt10 pb10 pr6 pl6 sq-icon-hover"
            onToggle={setIsMenuOpen}
            testId="homeScreenActionMore"
            id={`dropdown-basic-${item.id}`}>
            {_.map(actions, action => renderDropdownEntry(action))}
          </ButtonWithDropdown>
        </div>}

        {showEditModal &&
        <EditWorkbookModal
          onClose={handleEditNameClose}
          name={item.name}
          show={true}
          type={item.type}
          description={item.description}
          owner={item.owner}
          renderer={item.renderer}
          id={item.id}
          isCorporate={item.isCorporate} />}

        {/* Do NOT rely on show property here as it will still render and execute the useEffect function that
         request the current folder!! */}
        {showFolderExplorerModal &&
        <FolderExplorerModal
          onClose={handleFolderExplorerClose}
          item={item}
          show={true}
          currentFolderId={currentFolderId}
          currentTab={currentTab} />}

        {showItemAclModal &&
        <ItemAclModal
          itemId={item.id}
          closeModal={handleItemAclClose} />}
      </EventCapture>
    </div>
  );
};
