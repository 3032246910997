import angular from 'angular';
import { sqExploreFolderModalStore } from '@/hybrid/explorer/exploreFolderModal.store';
import { sqExploreFolderModalActions } from '@/hybrid/explorer/exploreFolderModal.actions';
import { sqExploreWorkbookModalStore } from '@/hybrid/explorer/exploreWorkbookModal.store';
import { sqExploreWorkbookModalActions } from '@/hybrid/explorer/exploreWorkbookModal.actions';

const dependencies = [
  'Sq.Core',
  'Sq.AppConstants',
  'Sq.Services.Socket',
  'Sq.TrendData',
  'Sq.Search',
  'Sq.Investigate',
  'Sq.Worksheet'
];

/**
 * The Sq.Explorer module contains all functionality related to the Explorer Modal
 */
export default angular.module('Sq.Explorer', dependencies)
  .store('sqExploreFolderModalStore', sqExploreFolderModalStore)
  .store('sqExploreWorkbookModalStore', sqExploreWorkbookModalStore)
  .service('sqExploreFolderModalActions', sqExploreFolderModalActions)
  .service('sqExploreWorkbookModalActions', sqExploreWorkbookModalActions);

export const WORKBOOKS_SORT_FIELDS = {
  UPDATED_AT: 'UPDATED_AT',
  CREATED_AT: 'CREATED_AT',
  NAME: 'NAME',
  OWNER: 'OWNER'
};
