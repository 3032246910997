import _ from 'lodash';
import bind from 'class-autobind-decorator';

@bind
export class ConfigurationPreviewController {
  restartRequiredOptions = [];

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    public configurationOptions
  ) {
    this.restartRequiredOptions = _.filter(this.configurationOptions, { registration: 'Restart' });
  }

  close = this.$uibModalInstance.close;

  save() {
    this.close(true);
  }
}
