import React, { useImperativeHandle, useRef } from 'react';
import { ConnectDropTarget, ConnectDragSource, DropTargetMonitor, DragSourceMonitor } from 'react-dnd';
import { DragSource, DropTarget, DropTargetConnector, DragSourceConnector } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { WorksheetPaneTile } from '@/hybrid/worksheets/WorksheetPaneTile.molecule';

const DRAG_TYPE = {
  WORKSHEET: 'worksheet'
};

interface DraggableWorksheetPaneTileProps {
  index: number;
  worksheet: { worksheetId: string, updatedAt: string, name: string };
  workbookId: string;
  isActive: boolean;
  gotoWorksheet: (worksheetId: string, viewOnly?: boolean) => any;
  moveWorksheet: (oldIndex: number, newIndex: number) => void;
  isDragging: boolean;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
}

interface WorksheetTileInstance {
  getNode(): HTMLDivElement | null;
}

const DraggableWorksheetPaneTile = React.forwardRef<HTMLDivElement, DraggableWorksheetPaneTileProps>(
  ({ worksheet, workbookId, isActive, index, gotoWorksheet, isDragging, connectDragSource, connectDropTarget },
    ref) => {
    const worksheetTileRef = useRef(null);
    connectDragSource(worksheetTileRef);
    connectDropTarget(worksheetTileRef);

    useImperativeHandle<any, WorksheetTileInstance>(ref, () => ({
      getNode: () => worksheetTileRef.current
    }));

    return (
      <div ref={worksheetTileRef} className={isDragging ? 'text-half-opacity' : 'text-opaque'}>
        <WorksheetPaneTile
          key={worksheet.worksheetId}
          worksheet={worksheet}
          workbookId={workbookId}
          gotoWorksheet={gotoWorksheet}
          isActive={isActive}
          index={index}
        />
      </div>
    );
  }
);

// This logic is from the React-dnd simple examples: https://react-dnd.github.io/react-dnd/examples/sortable/simple
export default DropTarget(
  DRAG_TYPE.WORKSHEET,
  {
    hover(
      props: DraggableWorksheetPaneTileProps,
      monitor: DropTargetMonitor,
      component: WorksheetTileInstance
    ) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }

      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      props.moveWorksheet(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    }
  },
  (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget()
  })
)(
  DragSource(
    DRAG_TYPE.WORKSHEET,
    {
      beginDrag: (props: DraggableWorksheetPaneTileProps) => ({
        index: props.index
      })
    },
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    })
  )(DraggableWorksheetPaneTile)
);
