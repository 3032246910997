import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { APP_STATE, HOME_SCREEN_TABS, SEARCH_ITEM_LOCATIONS } from '@/main/app.constants';
import { FoldersApi } from 'sdk/api/FoldersApi';
import { WorkbookActions } from '@/workbook/workbook.actions';
import { NotificationsService } from '@/services/notifications.service';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { UtilitiesService } from '@/services/utilities.service';
import { AuthorizationService } from '@/services/authorization.service';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { WorkbenchStore } from '@/workbench/workbench.store';
import _ from 'lodash';
import HttpCodes from 'http-status-codes';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { HomeScreenStore } from '@/hybrid/homescreen/homescreen.store';
import { ExplorerModalBody } from '@/hybrid/explorer/ExplorerModalBody.molecule';
import { ExploreFolderModalStore } from '@/hybrid/explorer/exploreFolderModal.store';
import { ExploreFolderModalActions } from '@/hybrid/explorer/exploreFolderModal.actions';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';

const folderExplorerModalBindings = bindingsDefinition({
  onClose: prop<any>(),
  item: prop<any>(),
  show: prop<boolean>(),
  currentFolderId: prop<string>(),
  currentTab: prop<string>(),
  sqFoldersApi: injected<FoldersApi>(),
  sqWorkbookActions: injected<WorkbookActions>(),
  sqNotifications: injected<NotificationsService>(),
  sqWorkbenchStore: injected<WorkbenchStore>(),
  sqUtilities: injected<UtilitiesService>(),
  sqAuthorization: injected<AuthorizationService>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  sqHomeScreenStore: injected<HomeScreenStore>(),
  sqExploreFolderModalStore: injected<ExploreFolderModalStore>(),
  sqExploreFolderModalActions: injected<ExploreFolderModalActions>(),
  $state: injected<any>()
});

export const FolderExplorerModal: SeeqComponent<typeof folderExplorerModalBindings> = (props) => {
  const { currentTab, item, onClose, show } = props;
  const {
    $state,
    sqUtilities,
    sqAuthorization,
    sqFoldersApi,
    sqHomeScreenUtilities,
    sqNotifications,
    sqHomeScreenActions,
    sqHomeScreenStore,
    sqExploreFolderModalStore,
    sqExploreFolderModalActions
  } = useInjectedBindings(folderExplorerModalBindings);
  const { t } = useTranslation();
  const folder = useFluxPath(sqExploreFolderModalStore, () => sqExploreFolderModalStore.folder);
  const initialFolderId = useFluxPath(sqExploreFolderModalStore, () => sqExploreFolderModalStore.initialFolderId);
  const localizedFolder = sqHomeScreenUtilities.translateBreadcrumb(folder);

  useEffect(() => {
    sqExploreFolderModalActions.getInitialFolder(currentTab, item);
  }, []);

  const close = (movedItem = false) => {
    if (movedItem) {
      sqHomeScreenActions.clearItems();
    }
    sqExploreFolderModalActions.clear();
    sqHomeScreenActions.setCurrentTab(currentTab);
    onClose();
  };

  const moveItemToFolder = () => {
    const currentFolderId = folder ? folder.id : SEARCH_ITEM_LOCATIONS.MY_FOLDER;
    sqHomeScreenUtilities.moveItem(item.id, currentFolderId)
      .then((response: any) => {
        close(true);
        let folderId;
        let currentTab;
        let tabFolderPromise;
        if (!folder) {
          tabFolderPromise = sqHomeScreenUtilities.getTabFolder(HOME_SCREEN_TABS.MY_FOLDER);
        } else {
          tabFolderPromise = sqHomeScreenUtilities.getTabFolderName(currentFolderId)
            .then((tabFolder) => {
              folderId = tabFolder ? null : currentFolderId;
              currentTab = tabFolder || null;
            });
        }

        return tabFolderPromise.then(() => {
          return sqHomeScreenActions.loadFolderTree(folderId, currentTab)
            .then((response: any) => {
              return $state.go(APP_STATE.FOLDER_EXPANDED, { currentFolderId }, { reload: true })
                .then(() => sqNotifications.successTranslate('WORKBENCH.ITEM_MOVED', {
                  item: item.name,
                  folder: folder.name
                })).catch((response) => {
                  const errorMsg = response?.status === HttpCodes.FORBIDDEN ? 'WORKBENCH.ITEM_MOVED_FORBIDDEN' :
                    'WORKBENCH.ITEM_MOVED_ERROR';
                  return sqNotifications.errorTranslate(errorMsg, {
                    item: item.name,
                    folder: folder.name
                  });
                });
            });
        });
      });
  };

  // If folder is undefined it could be that we are trying to move to the "My Folder" that has yet to be created
  const canMoveItem = !folder
  && sqExploreFolderModalStore.tableFilter !== SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC
  && sqExploreFolderModalStore.tableFilter !== SEARCH_ITEM_LOCATIONS.PINNED
    ? true
    : sqAuthorization.canModifyWorkbook(folder, false)
    && folder
    && !sqUtilities.equalsIgnoreCase(folder?.id, initialFolderId)
    && !sqUtilities.equalsIgnoreCase(folder?.id, item?.id)
    && _.isUndefined(
      _.find(folder?.ancestors, (ancestor) => {
        return sqUtilities.equalsIgnoreCase(ancestor.id, item?.id);
      }));

  const renderButtons = (
    <div className="flexColumnContainer flexJustifyEnd pt15">
      <button type="button" data-testid="cancelMoveBtn" className="btn btn-default mr20" onClick={() => close()}>
        <span>{t('CANCEL')}</span>
      </button>
      <button type="submit" id="moveToBtn" data-testid="moveToBtn" disabled={!canMoveItem} onClick={moveItemToFolder}
        className={classNames('btn', 'btn-primary', { 'disabledLook disabledBehavior': !canMoveItem })}>
        <span>{t('WORKBENCH.MOVE_HERE')}</span>
      </button>
    </div>
  );

  return (
    <Modal show={show} onHide={close} animation={false} backdrop="static" data-testid="folderExplorerModal">
      <Modal.Header closeButton={true}>
        <h4 className="modal-title flexColumnContainer flexAlignCenter">
          <HoverTooltip text={localizedFolder || ''} delay={500}>
          <span data-testid="folderExplorerTitle">
            <span>{t('WORKBENCH.MOVE_TO_FOLDER')}: </span>
            <span>
              {sqUtilities.truncate(localizedFolder, 50, 20)}
            </span>
          </span>
          </HoverTooltip>
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className=" max-height-475 overflowAuto">
          <ExplorerModalBody show={true} store={sqExploreFolderModalStore} actions={sqExploreFolderModalActions}
            allowedTypes={['Folder']} />
        </div>
        {renderButtons}
      </Modal.Body>
    </Modal>
  );
};
