import angular from 'angular';
import template from './dataStatusIcon.component.html';

angular.module('Sq.TrendViewer').component('sqDataStatusIcon', {
  template,
  controller: 'DataStatusIconCtrl',
  bindings: {
    items: '<',
    header: '<'
  }
});
