import _ from 'lodash';
import bind from 'class-autobind-decorator';
import { CapsuleGroupCapsule } from '@/investigate/customCondition/customCondition.module';
import { ConditionFormulaService } from '@/investigate/customCondition/conditionFormula.service';
import { NotificationsService } from '@/services/notifications.service';
import { TrendActions } from '@/trendData/trend.actions';
import { TrendStore } from '@/trendData/trend.store';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { InvestigateStore } from '@/investigate/investigate.store';

@bind
export class CapsuleGroupActions {
  constructor(
    private $q: ng.IQService,
    private flux: ng.IFluxService,
    private sqTrendActions: TrendActions,
    private sqTrendStore: TrendStore,
    private sqTrendCapsuleStore: TrendCapsuleStore,
    private sqConditionFormula: ConditionFormulaService,
    private sqNotifications: NotificationsService,
    private sqInvestigateStore: InvestigateStore) {
  }

  /**
   * Sets the capsules in the capsule group store
   *
   * @param {Object} capsules - array of capsule objects
   */
  setCapsules(capsules: CapsuleGroupCapsule[]) {
    this.flux.dispatch('CAPSULE_GROUP_SET_CAPSULES', { capsules });
  }

  /**
   * Removes all the capsules from the capsule group store
   */
  clearCapsules() {
    this.setCapsules([]);
  }

  /**
   * Requests the capsules for the `capsuleSeries` formula and restores any of those capsules that are from selection
   * and sets the capsules back into the store. A notification is shown to inform the users that the selection was
   * cleared with the option to merge it with the existing selection.
   *
   * @param {String} formula - a `capsuleSeries` formula
   * @returns {Promise} resolves when the formula is loaded and selection is restored
   */
  loadFormulaAndSetSelection(formula: string) {
    return this.$q.resolve()
      .then(() => this.flux.dispatch('CAPSULE_GROUP_SET_LOADING'))
      .then(() => this.sqConditionFormula.requestCapsulesFromFormula(formula))
      .then(c => _.tap(c, this.setCapsules))
      .then((capsules) => {
        const conditionSelection = _.filter(capsules, 'id');
        const existingSelection = this.sqTrendCapsuleStore.selectedCapsules;
        this.sqTrendActions.replaceCapsuleSelection(conditionSelection);

        // Give the user the option of adding their selection to the condition instead of having it be lost
        if (_.differenceBy(existingSelection, conditionSelection, 'id').length > 0) {
          const toolName = this.sqInvestigateStore.activeToolName || 'CAPSULE_GROUP';
          const addPreviousSelection = () => {
            this.sqTrendActions.replaceCapsuleSelection(
              _.unionBy(this.sqTrendCapsuleStore.selectedCapsules, existingSelection, 'id')
            );
          };
          this.sqNotifications.custom(this.sqNotifications.info, 'CAPSULE_GROUP_PICKING.SELECTION_CLEARED',
            addPreviousSelection, {
              toolName
            }, {
              buttonTranslateKey: 'CAPSULE_GROUP_PICKING.SELECTION_CLEARED_ADD_INSTEAD',
              buttonTranslateValues: { toolName },
              faIcon: 'fa-plus-circle',
              delay: 120000
            });
        }
      })
      .catch(e => this.sqNotifications.apiError(e));
  }

  /**
   * Adds the capsule represented by the selection to the capsule group store and removes the selection.
   */
  pickSelectedRegion() {
    const capsule = {
      startTime: this.sqTrendStore.selectedRegion.min,
      endTime: this.sqTrendStore.selectedRegion.max,
      properties: []
    };

    this.flux.dispatch('CAPSULE_GROUP_ADD_CAPSULE', { capsule });
    this.sqTrendActions.removeSelectedRegion();
  }
}
