import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { WizardModal } from '@/hybrid/core/WizardModal.molecule';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { AssetGroupActions } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import {
  AssetGroupCalculationCreationOption,
  SelectExistingOrNew
} from '@/hybrid/assetGroupEditor/calculations/SelectExistingOrNew.atom';
import { SearchForExistingConditionOrCalculatedSignal } from '@/hybrid/assetGroupEditor/calculations/SearchForExistingConditionOrCalculatedSignal.atom';
import { FormulaFromScratch } from '@/hybrid/assetGroupEditor/calculations/FormulaFromScratch.atom';
import { FormulaEditorParam } from '@/hybrid/formula/FormulaParametersTable.molecule';
import { validateAssetGroupFormula } from '@/hybrid/formula/formula.utilities';
import { FormulasApi } from '@/sdk';
import { AssetGroupStore } from '@/hybrid/assetGroupEditor/assetGroup.store';
import { FormulaErrorInterface } from '@/hybrid/formula/FormulaEditor.molecule';
import { NotificationsService } from '@/services/notifications.service';
import { SEARCH_PANES } from '@/search/search.module';
import { SearchActions } from '@/search/search.actions';
import { SEARCH_TYPES_ASSET_GROUP } from '@/hybrid/assetGroupEditor/AssetFinderModal';

const assetColumnCalculationModalBindings = bindingsDefinition({
  onClose: prop<() => void>(),
  sqAssetGroupActions: injected<AssetGroupActions>(),
  sqAssetGroupStore: injected<AssetGroupStore>(),
  sqFormulasApi: injected<FormulasApi>(),
  sqNotifications: injected<NotificationsService>(),
  sqSearchActions: injected<SearchActions>()
});

export const AssetColumnCalculationModal: SeeqComponent<typeof assetColumnCalculationModalBindings> =
  ({ onClose }) => {
    const {
      sqAssetGroupActions,
      sqAssetGroupStore,
      sqFormulasApi,
      sqNotifications,
      sqSearchActions
    } = useInjectedBindings(assetColumnCalculationModalBindings);

    const [currentSelection, setCurrentSelection] = useState<AssetGroupCalculationCreationOption>('fromExisting');
    const [selectedItem, setSelectedItem] = useState(null);
    const [formula, setFormula] = useState('');
    const [parameters, setParameters] = useState<FormulaEditorParam[]>([]);
    const [formulaErrors, setFormulaErrors] = useState<FormulaErrorInterface[]>([]);
    const [calculationColumnName, setCalculationColumnName] = useState('');

    const doValidateFormula = () => {
      return validateAssetGroupFormula(
        sqFormulasApi,
        sqAssetGroupStore.assets,
        formula,
        parameters
      ).then(() => {
        setFormulaErrors([]);
        return true;
      }).catch((formulaErrors) => {
        setFormulaErrors(formulaErrors);
        return false;
      });
    };

    const saveCalculation = () =>
      doValidateFormula()
        .then((valid) => {
          if (valid) {
            sqAssetGroupActions.addCalculationColumn(formula, parameters, calculationColumnName);
            onClose();
            sqNotifications.successTranslate('ASSET_GROUP_EDITOR.ADD_CALCULATION_SUCCESS');
          }
        });

    const typeSelectionDef = {
      title: 'ASSET_GROUP_EDITOR.ADD_CALCULATION',
      component: <SelectExistingOrNew onSelection={setCurrentSelection} />,
      valid: true
    };

    const resetSearchModal = () => sqSearchActions.clear(SEARCH_PANES.MODAL, SEARCH_TYPES_ASSET_GROUP);

    const existingCalculationSteps = [
      typeSelectionDef,
      {
        title: 'ASSET_GROUP_EDITOR.SEARCH_CONDITION_OR_CALC_ITEM',
        component: <SearchForExistingConditionOrCalculatedSignal setSelectedItem={setSelectedItem} />,
        valid: selectedItem !== null,
        backButtonFn: resetSearchModal,
        actionFn: () => {
          return sqFormulasApi.getItem({ id: selectedItem.id })
            .then(({ data }) => {
              const { formula, parameters: fnParams } = data;
              const formulaParams = _.map(fnParams,
                ({ name, item }) => ({
                  name,
                  identifier: name,
                  item: { name: item.name, id: item.id, type: item.type }
                }));
              setSelectedItem(data);
              setFormula(formula);
              setParameters(formulaParams);
            })
            .finally(resetSearchModal);
        }
      }, {
        title: 'ASSET_GROUP_EDITOR.MAP_EXISTING',
        component: <FormulaFromScratch
          item={selectedItem}
          existingParameters={parameters}
          validateFormula={doValidateFormula}
          formulaErrors={formulaErrors}
          setFormulaErrorsFn={setFormulaErrors}
          exposeFormulaToParent={setFormula}
          exposeParametersToParent={setParameters}
          exposeCalculationColumnNameToParent={setCalculationColumnName}
          showColumnNameField={true}
        />,
        actionButtonLabel: 'ASSET_GROUP_EDITOR.ADD_CALCULATION',
        valid: _.isEmpty(formulaErrors),
        actionFn: saveCalculation,
        modalClassName: 'min-width-900'
      }];

    const fromScratchSteps = [
      typeSelectionDef,
      {
        title: 'ASSET_GROUP_EDITOR.FORMULA',
        component: <FormulaFromScratch
          validateFormula={doValidateFormula}
          formulaErrors={formulaErrors}
          exposeFormulaToParent={setFormula}
          setFormulaErrorsFn={setFormulaErrors}
          exposeParametersToParent={setParameters}
          exposeCalculationColumnNameToParent={setCalculationColumnName}
          showColumnNameField={true}
        />,
        backButtonFn: () => setCurrentSelection('fromExisting'),
        actionButtonLabel: 'ASSET_GROUP_EDITOR.ADD_CALCULATION',
        valid: _.isEmpty(formulaErrors),
        actionFn: saveCalculation,
        modalClassName: 'min-width-900'
      }];

    const [steps, setSteps] = useState(existingCalculationSteps);

    useEffect(() => {
      setSteps(currentSelection === 'fromExisting' ? existingCalculationSteps : fromScratchSteps);
    }, [currentSelection, selectedItem, formula, parameters, formulaErrors, calculationColumnName]);

    return (
      <WizardModal
        testId="assetGroupCalculations"
        onClose={onClose}
        stepDefinitions={steps}
      />);
  };

