import _ from 'lodash';
import angular from 'angular';
import { WorkstepsActions } from '@/worksteps/worksteps.actions';
import { TrendActions } from '@/trendData/trend.actions';
import { UtilitiesService } from '@/services/utilities.service';
import { WorkstepsStore } from '@/worksteps/worksteps.store';
import { ScreenshotService } from '@/services/screenshot.service';
import { WorkbookService } from '@/workbook/workbook.service';
import { WorkbenchService } from '@/workbench/workbench.service';
import { ReportActions } from '@/reportEditor/report.actions';
import { LoggerService } from '@/services/logger.service';
import { RedactionService } from '@/services/redaction.service';
import { APP_STATE, DEBOUNCE, SEARCH_TYPES } from '@/main/app.constants';
import { WorkbookStore } from '@/workbook/workbook.store';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { NotificationsService } from '@/services/notifications.service';
import { SearchActions } from '@/search/search.actions';
import { SEARCH_PANES } from '@/search/search.module';

const dependencies = [
  'Sq.Worksteps',
  'Sq.Workbench',
  'Sq.TrendData',
  'Sq.Search',
  'Sq.Services.Notifier'
];

/**
 * @constant PERSISTENCE_LEVEL
 * Different stores are persisted at different locations. Each persistence level is a group of stores that are
 * stored together
 *
 * NONE      - not saved, stored in memory only
 * WORKBENCH - per-user data store
 * WORKBOOK  - per-workbook data store
 * WORKSHEET - per-worksheet data store (in workstep)
 *
 * Every store should have a property called 'persistenceLevel' assigned one of PERSISTENCE_LEVEL.
 */
export const PERSISTENCE_LEVEL = {
  NONE: 'PERSISTENCE_LEVEL_NONE',
  WORKBENCH: 'PERSISTENCE_LEVEL_WORKBENCH',
  WORKBOOK: 'PERSISTENCE_LEVEL_WORKBOOK',
  WORKSHEET: 'PERSISTENCE_LEVEL_WORKSHEET'
};

export const PUSH_IGNORE = 'PUSH_IGNORE';

export const PUSH_WORKSTEP_IMMEDIATE = 'PUSH_WORKSTEP_IMMEDIATE';

export const PUSH_WORKBOOK = 'PUSH_WORKBOOK';

export const PUSH_WORKBENCH = 'PUSH_WORKBENCH';

export const INITIALIZE_MODE = {
  FORCE: 'InitializeModeForce',
  SOFT: 'InitializeModeSoft'
};

angular
  .module('Sq.Core.StateSynchronizer', dependencies)
  .service('sqStateSynchronizer', sqStateSynchronizer);

export type StateSynchronizerService = ReturnType<typeof sqStateSynchronizer>;

function sqStateSynchronizer(
  flux: ng.IFluxService,
  $q: ng.IQService,
  $rootScope: ng.IRootScopeService,
  $state: ng.ui.IStateService,
  $window: ng.IWindowService,
  sqWorkstepsActions: WorkstepsActions,
  sqTrendActions: TrendActions,
  sqSearchActions: SearchActions,
  sqUtilities: UtilitiesService,
  sqWorkstepsStore: WorkstepsStore,
  sqScreenshot: ScreenshotService,
  sqWorkbook: WorkbookService,
  sqWorkbench: WorkbenchService,
  sqWorkbookStore: WorkbookStore,
  sqWorkbenchStore: WorkbenchStore,
  sqNotifications: NotificationsService,
  sqReportActions: ReportActions,
  sqLogger: LoggerService,
  sqRedaction: RedactionService
) {

  let isRehydrating = false;
  let currentPush;
  let deferredPush;
  let currentWorkbenchState = {};
  let currentWorkbookState = {};
  let currentWorksheetState = {};
  let loadingWorksheet = undefined;
  const debouncedWorksheetPush = _.debounce(pushWorksheetState, DEBOUNCE.WORKSTEP);
  const debouncedLoadWorkstep = sqUtilities.debounceAsync(rehydrateWorkstep);

  const service = {
    push,
    rehydrate,
    initialize,
    onWorkstep,
    fetchRehydrateData, // Exposed for testing
    setLoadingWorksheet: (workbookId, worksheetId) => {
      if (loadingWorksheet) {
        sqLogger.warn('Attempted to setLoadingWorksheet, but a worksheet is already loading');
        return;
      }

      loadingWorksheet = { workbookId, worksheetId };
    },
    unsetLoadingWorksheet: () => {
      loadingWorksheet = undefined;
    },
    isLoadingWorksheet: () => {
      return !!loadingWorksheet;
    },
    get isRehydrating() {
      return isRehydrating;
    }
  };

  return service;

  /**
   * Invokes the correct push method based on the specified pushMode.
   *
   * @param {String} [pushMode] - One of the PUSH constants. If not specified it defaults to debounced worksheet push
   * @param {Object} [options] - Additional push options
   * @param {String} [options.workbookId=$state.params.workbookId] - The workbook id to push
   * @param {String} [options.worksheetId=$state.params.worksheetId] - The worksheet id to push
   * @returns {Promise} if push immediate then resolves when the push is complete otherwise resolves immediately
   */
  function push(pushMode?, options?) {
    if (pushMode === PUSH_IGNORE) {
      return $q.resolve();
    }

    options = _.defaults(options || {}, {
      workbookId: $state.params.workbookId,
      worksheetId: $state.params.worksheetId
    });

    return $q.resolve()
      .then(() => {
        if (pushMode === PUSH_WORKBENCH) return saveWorkbenchState();

        if (service.isRehydrating) return;
        if (sqUtilities.headlessRenderMode()) return;
        // No workbook means there won't be a push, and checking here prevents console errors for the calls below
        if (!options.workbookId) return;
        if (sqUtilities.isViewOnlyWorkbookMode) return;
        if (sqUtilities.isPresentationWorkbookMode) return;

        if (options.workbookId && sqWorkbookStore.workbookId === options.workbookId && pushMode === PUSH_WORKBOOK) {
          return saveWorkbookState(options.workbookId);  // Only save workbook state if we're in the same workbook
        }

        if (options.workbookId && options.worksheetId) {
          if (service.isLoadingWorksheet() && pushMode !== PUSH_WORKSTEP_IMMEDIATE) {
            // If we are loading a worksheet, we force any worksheet push to be immediately pushed to the worksheet
            // that is in the process of loading to avoid the potential for leaking workstep information between
            // worksheets.
            pushMode = PUSH_WORKSTEP_IMMEDIATE;
            options.workbookId = loadingWorksheet.workbookId;
            options.worksheetId = loadingWorksheet.worksheetId;
          }

          if (pushMode === PUSH_WORKSTEP_IMMEDIATE) {
            if (_.isFunction(debouncedWorksheetPush.cancel)) {
              debouncedWorksheetPush.cancel();
            }
            return pushWorksheetState(options.workbookId, options.worksheetId);
          } else {
            if (!service.isLoadingWorksheet()) {
              debouncedWorksheetPush(options.workbookId, options.worksheetId);
            }
          }
        }
      });
  }

  /**
   * Persists workbench state
   */
  function saveWorkbenchState() {
    let newWorkbenchState;
    const newState = flux.dispatcher.dehydrate();

    newWorkbenchState = filterStoresWithPersistenceLevel(newState, PERSISTENCE_LEVEL.WORKBENCH);
    if (_.isEqual(JSON.stringify(currentWorkbenchState), JSON.stringify(newWorkbenchState))) {
      return $q.resolve();
    }
    currentWorkbenchState = newWorkbenchState;
    return sqWorkbench.set(newWorkbenchState);
  }

  /**
   * Persists workbook state
   */
  function saveWorkbookState(id) {
    let newWorkbookState;
    const newState = flux.dispatcher.dehydrate();

    newWorkbookState = filterStoresWithPersistenceLevel(newState, PERSISTENCE_LEVEL.WORKBOOK);
    if (!_.isEqual(JSON.stringify(currentWorkbookState), JSON.stringify(newWorkbookState))) {
      currentWorkbookState = newWorkbookState;
      sqWorkbook.set(id, newWorkbookState);
    }
  }

  /**
   * Persists the current worksheet state by pushing as a workstep
   *
   * @param {String} workbookId - The workbook id to push.
   * @param {String} worksheetId - The worksheet id to push.
   * @return {Promise|undefined} - Promise if it pushes, undefined if not
   */
  function pushWorksheetState(workbookId, worksheetId) {
    if (currentPush) {
      deferredPush = () => {
        if (workbookId === $state.params.workbookId && worksheetId === $state.params.worksheetId) {
          return service.push(PUSH_WORKSTEP_IMMEDIATE, { workbookId, worksheetId });
        } else {
          sqLogger.error('Not performing enqueued push because $state has changed. This should not happen!');
        }
      };
      return currentPush;
    }

    const newState = flux.dispatcher.dehydrate();
    const newWorksheetState = filterStoresWithPersistenceLevel(newState, PERSISTENCE_LEVEL.WORKSHEET);

    if (!_.isEqual(JSON.stringify(currentWorksheetState), JSON.stringify(newWorksheetState))) {
      currentPush = $q.resolve();

      // If we have a next workstep, that means we're currently on a previous workstep, so we need to push
      // the state of that workstep before pushing the new state. This provides a nice transition when going
      // backwards through the workstep history. (e.g. If the history is 1 2 3 and users goes back to 2 and
      // then new step 4 is added, the history will now be 1 2 3 2 4)
      if (sqWorkstepsStore.next) {
        currentPush = currentPush.then(() => sqWorkstepsActions.push(workbookId, worksheetId, currentWorksheetState)
          // Even if it fails the current workstep should be pushed
          .catch(_.noop));
      }

      // Ensure the current workstep is pushed after the previous one (if there is a previous workstep)
      currentPush = currentPush
        .then(() => sqWorkstepsActions.push(workbookId, worksheetId, newWorksheetState))
        .then((response: any) => {
          currentWorksheetState = newWorksheetState;
          sqScreenshot.generate(workbookId, worksheetId);
        })
        // Do not want to fail if the push fails for some reason (usually cancellation)
        .catch(_.noop)
        .finally(() => {
          currentPush = undefined;
          if (deferredPush) {
            const response = deferredPush();
            deferredPush = undefined;
            return response;
          }
        });
    }

    return currentPush;
  }

  function filterStoresWithPersistenceLevel(newState, persistenceLevel) {
    // Get an array containing all the store names that apply at the requested persistence level
    const storeNames = _.chain(flux.dispatcher.storeInstances)
      .pickBy(function(store, storeName) {
        if (!store.persistenceLevel) {
          throw new Error(storeName + ' has no PERSISTENCE_LEVEL');
        }

        return store.persistenceLevel === persistenceLevel;
      })
      .keys()
      .value();

    // Return an object containing only the applicable stores for the requested persistence level
    return {
      stores: _.pickBy(newState.stores, function(value, key) {
        return _.includes(storeNames, key);
      })
    };
  }

  /**
   * Handle workstep messages received over websocket for the current worksheet which is what enables fast-follow
   * (where updates from another user are reflected for the current user). Several guards are in place to ensure that
   * worksteps never get applied to the wrong worksheet or at the wrong time:
   * - The worksheet is presentation-mode, so fast follow does not apply.
   * - The current worksheet is in the process of loading, which indicates the user is transitioning somewhere else
   * - The current worksheet does not match the workstep's worksheet, which could indicate a workstep channel was
   * not properly closed or a race condition (CRAB-18940).
   *
   * @param {Object} data an object describing a workstep
   * @param {Object} data.workstepData a JSON string to containing workstep details
   */
  function onWorkstep(data) {
    const workstepData = _.attempt(JSON.parse, data.workstepData);
    if (sqUtilities.isPresentationWorkbookMode || service.isLoadingWorksheet() ||
      data.workbookId !== $state.params.workbookId || data.worksheetId !== $state.params.worksheetId) {
      return;
    }

    // Handle the view-only case.
    if (sqUtilities.isViewOnlyWorkbookMode) {
      onViewOnlyWorkstep();
    } else {
      debouncedLoadWorkstep(data.workbookId, data.worksheetId, workstepData);
    }
  }

  /**
   * Handle a workstep received in view only mode.
   */
  function onViewOnlyWorkstep() {
    function reloadPageWithShortURL() {
      // $state.reload() almost works but changes the shorter url into a longer url
      $state.go($state.current, $state.params, { location: false, reload: true, notify: false });
    }

    sqNotifications.custom(sqNotifications.info, 'RELOAD_MESSAGE', reloadPageWithShortURL, {}, {
      buttonTranslateKey: 'RELOAD'
    });
  }

  /**
   * Rehydrates workstep data associated with a particular worksheet
   *
   * @param workbookId {String} the id of the workbook
   * @param worksheetId {String} the id of the worksheet
   * @param workstepData {Object} the workstep data do rehydrate
   */
  function rehydrateWorkstep(workbookId, worksheetId, workstepData) {
    service.setLoadingWorksheet(workbookId, worksheetId);
    return service.rehydrate(workstepData.state, {
        persistenceLevel: PERSISTENCE_LEVEL.WORKSHEET,
        initializeMode: INITIALIZE_MODE.SOFT,
        workbookId,
        worksheetId
      })
      .finally(() => {
        service.unsetLoadingWorksheet();
      });
  }

  /**
   * Initializes all stores and rehydrates their previous state if present.
   *
   * Stores can specify an array of other stores which must first rehydrate by adding the `rehydrateWaitFor`
   * property. Note that rehydrateWaitFor can not be used to wait for a store that is not part of its
   * persistenceLevel. Also note that while it supports a chain of dependencies (storeA -> storeB -> storeC), there is
   * no circular dependency checking, but you'll figure that out soon enough if you create one :)
   *
   * @param {Object} [dehydratedState] - An object with a `stores` property. Usually the result of the
   *   `dispatcher.dehydrate` method.
   * @param {Object} [options] - Additional options for rehydrate
   * @param {String} [options.persistenceLevel=PERSISTENCE_LEVEL.WORKSHEET] - one of PERSISTENCE_LEVEL
   * @param {Boolean} [options.initializeMode=INITIALIZE_MODE.FORCE] - one of INITIALIZE_MODE
   * @param {String} [options.workbookId] - ID of the workbook from which this state originates. If persistenceLevel
   *   is WORKBOOK or WORKSHEET this value is required and setLoadingWorksheet() must be called first.
   * @param {String} [options.worksheetId] - ID of the worksheet from which this state originates. If persistenceLevel
   *   is WORKBOOK or WORKSHEET this value is required and setLoadingWorksheet() must be called first.
   * @param {Function} [options.beforeFetch] - hook to change the state before making requests; may return a promise.
   *   For example changing the display range requires refreshing most data, so if the display range has been changed
   *   via a url parameter it should be changed after the synchronous data is present in the store but before all the
   *   data is requested from the backend.
   * @returns {Promise} A promise that is resolved when all the rehydrate stores finish rehydrating.
   */
  function rehydrate(dehydratedState?, options?) {
    options = _.defaults(options, {
      persistenceLevel: PERSISTENCE_LEVEL.WORKSHEET,
      initializeMode: INITIALIZE_MODE.FORCE
    });

    const areParametersGuarded = _.includes([PERSISTENCE_LEVEL.WORKBOOK, PERSISTENCE_LEVEL.WORKSHEET],
      options.persistenceLevel);

    if (areParametersGuarded && !(options.workbookId && options.worksheetId)) {
      return $q.reject('workbookId and worksheetId are required when rehydrating workbooks or worksheets');
    }

    const isLoadingCorrectState = loadingWorksheet && options.workbookId === loadingWorksheet.workbookId &&
      options.worksheetId === loadingWorksheet.worksheetId;
    // If state is mismatched or it is still rehydrating, likely because of race conditions that occur when
    // transitioning between worksheets while another rehydrate is still going, then it is not safe to proceed because
    // the worksheet data will be overwritten. Since the existing promise can't be interrupted the safest thing is
    // to reload the page with the specified worksheet. There is similar logic in app.module.
    if (areParametersGuarded && (!isLoadingCorrectState || isRehydrating)) {
      $window.location.href = $state.href(APP_STATE.WORKSHEET, _.pick(options, ['workbookId', 'worksheetId']));
      const message = !isLoadingCorrectState ?
        'workbookId and worksheetId do not match those from setLoadingWorksheet()' :
        'rehydrate already in process';
      sqLogger.warn(`Preventing rehydrate and reloading worksheet because ${message}`);
      return $q.resolve();
    }

    try {
      rehydrateSynchronous(dehydratedState, _.omit(options, ['beforeFetch']));
    } catch (e) {
      return $q.reject(e);
    }

    if (options.persistenceLevel !== PERSISTENCE_LEVEL.WORKSHEET) {
      return $q.resolve();
    }

    // While this dynamic data is coming in we don't want extra worksteps created which is why isRehydrating is not
    // set to false until after it finishes.
    isRehydrating = true;
    return $q.resolve()
      .then(options.beforeFetch || _.noop)
      .then(service.fetchRehydrateData)
      .finally(() => {
        isRehydrating = false;
      });
  }

  /**
   * Internal method for the rehydrate method
   *
   * @see rehydrate
   */
  function rehydrateSynchronous(dehydratedState, options) {
    const rehydrateCalled = {};

    // Reset redaction monitor before we start rehydration so we can recognise if any items on the worksheet failed to
    // load during rehydration because of insufficient permissions.
    if (options.persistenceLevel === PERSISTENCE_LEVEL.WORKSHEET) {
      sqRedaction.reset();
    }

    const storeInstances = getStoresToRehydrate(dehydratedState, options);

    _.chain(storeInstances)
      .values()
      .filter('initialize')
      .invokeMap('initialize', options.initializeMode)
      .value();

    const setState = (state) => {
      if (options.persistenceLevel === PERSISTENCE_LEVEL.WORKBENCH) {
        currentWorkbenchState = state;
      } else if (options.persistenceLevel === PERSISTENCE_LEVEL.WORKBOOK) {
        currentWorkbookState = state;
      } else if (options.persistenceLevel === PERSISTENCE_LEVEL.WORKSHEET) {
        currentWorksheetState = state;
      }
    };

    // If there is no dehydratedState then it is the special case where internal state is being reset
    if (_.isUndefined(dehydratedState)) {
      setState(undefined);
    } else {
      _.forEach(storeInstances, function callRehydrate(store: any, name: string) {
        if (rehydrateCalled[name]) {
          return;
        }

        if (store.rehydrateWaitFor) {
          _.forEach(store.rehydrateWaitFor, function(dependencyName) {
            if (storeInstances[dependencyName]) {
              callRehydrate(storeInstances[dependencyName], dependencyName);
            }
          });
        }

        if (store.rehydrate && dehydratedState.stores && dehydratedState.stores[name]) {
          store.rehydrate(dehydratedState.stores[name]);
        }

        rehydrateCalled[name] = true;
      });

      setState(filterStoresWithPersistenceLevel(flux.dispatcher.dehydrate(), options.persistenceLevel));
    }
  }

  /**
   * Gets stores that need to be rehydrated given a dehydratedState. Stores that have differing state from the
   * dehydrated state are included in the output while stores that have matching state are not included. If
   * options.initializeMode is FORCE, then all stores of the appropriate persistence level are included.
   *
   * @param {Object} [dehydratedState] the dehydrated state used to determine what stores to rehydrate
   * @param {String} [options.persistenceLevel=PERSISTENCE_LEVEL.WORKSHEET] - one of PERSISTENCE_LEVEL
   * @param {Boolean} [options.initializeMode=INITIALIZE_MODE.FORCE] - one of INITIALIZE_MODE
   * @return {Array} An array of the stores that need to be rehydrated
   */
  function getStoresToRehydrate(dehydratedState, options) {
    let currentState;
    let storeInstances;
    const changedStores = {};

    // Filter by persistence level
    storeInstances = _.pickBy(flux.dispatcher.storeInstances,
      instance => instance.persistenceLevel === options.persistenceLevel);

    /*
     * If we have dehydrated state, then filter so we only rehydrate those stores that have actually changed. However,
     * if we are forcing initialization then we want all the stores to be reinitialized
     */
    if (dehydratedState && options.initializeMode !== INITIALIZE_MODE.FORCE) {
      // Determine which stores have changed
      currentState = flux.dispatcher.dehydrate();
      _.forEach(storeInstances, function(store, key) {
        changedStores[key] = dehydratedState.stores && dehydratedState.stores[key] &&
          JSON.stringify(dehydratedState.stores[key]) !== JSON.stringify(currentState.stores[key]);
      });

      // Filter so only changed stores and stores that depend on changed stores are rehydrated
      storeInstances = _.pickBy(storeInstances, _.rearg(function hasStoreChanged(storeName) {
        return changedStores[storeName] || _.some(storeInstances[storeName].rehydrateWaitFor, hasStoreChanged);
      }, 1));
    }

    return storeInstances;
  }

  /**
   * Initialize all the states at the provided persistenceLevel using the initializeMode.
   *
   * @param {String} persistenceLevel - the group of stores to initialize
   */
  function initialize(persistenceLevel) {
    return rehydrateSynchronous(undefined, { persistenceLevel, initializeMode: INITIALIZE_MODE.FORCE });
  }

  /**
   * Fetch all items for the details pane and the search pane
   *
   * @return {Promise} resolves when all of the items have been fetched.
   */
  function fetchRehydrateData(): ng.IPromise<any> {
    return $q.all([
      sqTrendActions.fetchAllItems(),
      sqSearchActions.initialize(SEARCH_PANES.MAIN, SEARCH_TYPES, false, undefined)
    ]);
  }
}

