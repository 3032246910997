import angular from 'angular';
import { ItemSelectFormComponent, ItemSelectIF } from '@/hybrid/formbuilder/ItemSelectFormComponent.atom';
import { ValueWithUnitsFormComponent, ValueWithUnitsIF } from '@/hybrid/formbuilder/ValueWithUnitsFormComponent';
import { ValueWithSymbolFormComponent, ValueWithSymbolIF } from '@/hybrid/formbuilder/ValueWithSymbolFormComponent';
import { SearchTitleFormComponent, SearchTitleIF } from '@/hybrid/formbuilder/SearchTitleFormComponent';
import { LabelFormComponent, LabelIF } from '@/hybrid/formbuilder/LabelFormComponent.atom';
import { PatternFormComponent, PatternIF } from '@/hybrid/formbuilder/PatternFormComponent';
import { SelectFormComponent, SelectIF } from '@/hybrid/formbuilder/SelectFormComponent';
import { RadioButtonGroupFormComponent, RadioButtonsIF } from '@/hybrid/formbuilder/RadioButtonGroupFormComponent.atom';
import { CheckboxFormComponent, CheckboxIF } from '@/hybrid/formbuilder/CheckboxFormComponent.atom';
import { IconSelectFormComponent, IconSelectIF } from '@/hybrid/formbuilder/IconSelectFormComponent.atom';
import { OperatorSelectFormComponent, OperatorSelectIF } from '@/hybrid/formbuilder/OperatorSelectFormComponent.atom';
import { FormControlFormComponent, FormControlIF } from '@/hybrid/formbuilder/FormControlFormComponent.atom';
import { JsonTextAreaFormComponent, FormControlJsonIF } from '@/hybrid/formbuilder/JsonTextAreaFormComponent.atom';
import { ErrorMessageFormComponent, ErrorMessageIF } from '@/hybrid/formbuilder/ErrorMessageFormComponent.atom';
import {
  TimeZoneSelectorFormComponent,
  TimeZoneSelectorIF
} from '@/hybrid/formbuilder/TimeZoneSelectorFormComponent.atom';
import { ClickableLinkFormComponent, ClickableLinkIF } from '@/hybrid/formbuilder/ClickableLinkFormComponent.atom';
import { ToolStore } from '@/hybrid/core/AdvancedParameters.atom';
import { CheckboxTableFormComponent, CheckboxTableIF } from '@/hybrid/formbuilder/CheckboxTableFormComponent.atom';
import { ButtonGroupFormComponent, ButtonGroupIF } from '@/hybrid/formbuilder/ButtonGroupFormComponent';
import { SelectUnitFormComponent, SelectUnitIF } from '@/hybrid/formbuilder/SelectUnitFormComponent.atom';
import { FileDropperIF } from '@/hybrid/formbuilder/FileDropperFormComponent.atom';
import { FileDropperFormComponent } from '@/hybrid/formbuilder/FileDropperFormComponent.atom';
import {
  CompositesSelectorFormComponent,
  CompositesSelectorIF
} from '@/hybrid/formbuilder/CompositeLogicSelectorFormComponent.molecule';
import {
  MaxCapsuleDurationFormComponent,
  MaxCapsuleDurationIF
} from '@/hybrid/formbuilder/MaxCapsuleDurationFormComponent';
import { IconFormComponent, IconIF } from '@/hybrid/formbuilder/IconFormComponent.atom';
import {
  CapsuleInputFormComponent,
  CapsuleInputIF
} from '@/hybrid/formbuilder/CapsuleInputFormComponent.molecule';
import { InputTableFormComponent, InputTableIF } from '@/hybrid/formbuilder/InputTableFormComponent.molecule';
import {
  StatisticSelectorFormComponent,
  StatisticSelectorIF
} from '@/hybrid/formbuilder/StatisticSelectorFormComponent.atom';
import { ColorPickerFormComponent, ColorPickerIF } from '@/hybrid/formbuilder/ColorPickerFormComponent.molecule';
import {
  PriorityPickerFormComponent,
  PriorityPickerIF
} from '@/hybrid/formbuilder/PriorityPickerFormComponent.molecule';
import { FormulaVariablesFormComponent, FormulaVariablesIF } from '@/hybrid/formbuilder/FormulaVariablesFormComponent';
import { FormulaEditorFormComponent, FormulaEditorIF } from '@/hybrid/formbuilder/FormulaEditorFormComponent';
import { DisplayOnlyFormElementWrapper } from '@/hybrid/formbuilder/formBuildingBlocks';
import { ImageSelectFormComponent, ImageSelectIF } from '@/hybrid/formbuilder/ImageSelectFormComponent';

const dependencies = [];

export default angular.module('Sq.FormBuilder', dependencies);

export const FORM_ERROR = 'Error';
export const FORM_WARNING = 'Warning';

export const supportedComponents = {
  CheckboxFormComponent,
  ErrorMessageFormComponent,
  FormControlFormComponent,
  JsonTextAreaFormComponent,
  IconSelectFormComponent,
  ItemSelectFormComponent,
  LabelFormComponent,
  OperatorSelectFormComponent,
  RadioButtonGroupFormComponent,
  SearchTitleFormComponent,
  ValueWithUnitsFormComponent,
  TimeZoneSelectorFormComponent,
  ClickableLinkFormComponent,
  CheckboxTableFormComponent,
  SelectUnitFormComponent,
  FileDropperFormComponent,
  CompositesSelectorFormComponent,
  MaxCapsuleDurationFormComponent,
  IconFormComponent,
  CapsuleInputFormComponent,
  InputTableFormComponent,
  StatisticSelectorFormComponent,
  ColorPickerFormComponent,
  PriorityPickerFormComponent,
  ValueWithSymbolFormComponent,
  PatternFormComponent,
  SelectFormComponent,
  ButtonGroupFormComponent,
  DisplayOnlyFormElementWrapper,
  ImageSelectFormComponent,
  FormulaVariablesFormComponent,
  FormulaEditorFormComponent
};

interface BaseFormElement {
  name: string;
  component: string;
  includeIf?: boolean;
  displayNumber?: boolean;
  // number will be generated by FormBuilder - if you want a number to display set displayNumber to true
  number?: number;
  testId?: string;
  // Setting this to true enables forms to use the form state only. This is for forms that are not fast-followed (like the Edit User Modal)
  skipStore?: boolean;
  extraClassNames?: string;
  // if the field comes with a default value be sure to set this flag to true (once you have the default value) - to ensure that form errors will display properly
  defaultProvided?: boolean;
  fieldNames?: string[];
  customStyles?: any;
  key?: any;
}

export interface ValidatingFormComponent<T> extends BaseFormElement {
  value: T;
  onChange?: (value: T) => void;
  // return true if invalid, false if valid
  validation?: (value: T) => boolean;
  // true to use default and custom validation, false to only use custom validation
  extendValidation?: boolean;
  // number of milliseconds to wait before showing a tooltip on-hover
  tooltipDelay?: number;
  // text to display on icon tooltip
  tooltip?: string;
}

export interface FormRow extends BaseFormElement {
  component: 'FormRow';
  components: FormElement[];
}

export interface FormGroup extends BaseFormElement {
  component: 'FormGroup';
  components: FormElement[];
  showBracket?: boolean;
}

export interface AdvancedFormGroup extends BaseFormElement {
  component: 'AdvancedFormGroup';
  components: FormElement[];
  toolStore: ToolStore;
  toolName: string;
  toolId: string;
  alternateName?: string;
}

export interface RemovableFormGroup extends BaseFormElement {
  component: 'RemovableFormGroup';
  components: FormElement[];
  hideIcon?: boolean;
  iconAction: () => void;
}

/**
 * this wrapper allows adding any child to a form and render it within form groups, rows, or blocks
 * it can only support "display only" children; no validation will be applied.
 * a typical use case for this are tables that only display information, or buttons that require no validation
 */
export interface DisplayOnlyFormElementWrapperIF extends BaseFormElement {
  component: 'DisplayOnlyFormElementWrapper';
  children: React.ReactNode;
}

export type FormElement =
  | FormGroup
  | FormRow
  | AdvancedFormGroup
  | ItemSelectIF
  | ValueWithUnitsIF
  | SearchTitleIF
  | LabelIF
  | IconIF
  | RadioButtonsIF
  | CheckboxIF
  | IconSelectIF
  | CompositesSelectorIF
  | OperatorSelectIF
  | FormControlIF
  | FormControlJsonIF
  | ErrorMessageIF
  | TimeZoneSelectorIF
  | CheckboxTableIF
  | ClickableLinkIF
  | SelectUnitIF
  | FileDropperIF
  | MaxCapsuleDurationIF
  | CapsuleInputIF
  | InputTableIF
  | RemovableFormGroup
  | StatisticSelectorIF
  | ColorPickerIF
  | PriorityPickerIF
  | ValueWithSymbolIF
  | PatternIF
  | SelectIF
  | ButtonGroupIF
  | ImageSelectIF
  | FormulaVariablesIF
  | FormulaEditorIF
  | DisplayOnlyFormElementWrapperIF;
