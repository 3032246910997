import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import { FroalaEditor } from 'other_components/froalaEditor';
import { JournalLinkService } from '@/annotation/journalLink.service';
import { FroalaReportContentService } from './froalaReportContent.service';
import { TrackService } from '@/track/track.service';
import { PdfPreviewModalService } from '@/reportEditor/pdfPreviewModal.service';
import { NotificationsService } from '@/services/notifications.service';
import { LoggerService } from '@/services/logger.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { ReportActions } from '@/reportEditor/report.actions';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { ReportContentActions } from '@/reportEditor/reportContent.actions';
import { CONTENT_STATE } from '@/reportEditor/report.module';
import { JOURNAL_PREFIX_PATH } from '@/main/app.constants';

angular.module('Sq.Annotation').factory('sqFroalaPlugins', sqFroalaPlugins);

export type FroalaPluginsService = ReturnType<typeof sqFroalaPlugins>;

function sqFroalaPlugins(
  $translate: ng.translate.ITranslateService,
  $location: ng.ILocationService,
  $window: ng.IWindowService,
  $compile: ng.ICompileService,
  $rootScope: ng.IRootScopeService,
  sqJournalLink: JournalLinkService,
  sqFroalaReportContent: FroalaReportContentService,
  sqReportContent: ReportContentService,
  sqReportActions: ReportActions,
  sqTrack: TrackService,
  sqReportContentActions: ReportContentActions,
  sqPdfPreviewModal: PdfPreviewModalService,
  sqNotifications: NotificationsService,
  sqLogger: LoggerService) {

  /*
   * NOTE: There should be no unpleasant side effects from installing plugins multiple times. To ensure that
   * plugins are installed call the functions before instancing the editor.
   */
  const service = {
    customIconTemplates,
    journalLink,
    resizeBasedOnToolbarSize,
    topicDocumentContent,
    refreshImage,
    pdfExport,
    pageBreak
  };

  return service;

  function customIconTemplates() {
    FroalaEditor.DefineIconTemplate('fc',
      '<i class="fc fc-[NAME]"></i>');
    FroalaEditor.DefineIconTemplate('fc-bold',
      '<i class="fc fc-[NAME]"></i>');
    FroalaEditor.DefineIconTemplate('fc-bold-text',
      '<span>[NAME]</span>');
    FroalaEditor.DefineIconTemplate('fc-inverse',
      '<i class="fc fc-inverse fc-[NAME]"></i>');
    FroalaEditor.DefineIconTemplate('fa',
      '<i class="fa fa-[NAME] mt-2"></i>');
  }

  /**
   * Globally adds a 'journalLink' plugin to the Froala editor. The plugin handles modifications to Froala for journal
   * links so that they do not open in a new tab. When the 'journalLink' command is placed on the toolbar, it
   * will allow the user to select links from the 'sq-journal-link' component and have them inserted into the document
   */
  function journalLink() {
    service.customIconTemplates();

    // Froala Custom Plugin Documentation: https://www.froala.com/wysiwyg-editor/docs/concepts/custom/plugin
    FroalaEditor.DEFAULTS = $.extend(FroalaEditor.DEFAULTS, {
      /**
       * Show these links in the create/edit link area as options in a dropdown.
       */
      linkList: [{ text: 'Seeq Customer Success Center', href: 'https://success.seeq.com', target: '_blank' }]
    });

    FroalaEditor.DefineIcon('journalLinkIcon', {
      NAME: 'link-seeq',
      TEXT: 'Link',
      template: 'fc-inverse'
    });

    FroalaEditor.RegisterCommand('journalLink', {
      title: $translate.instant('JOURNAL.ENTRY.LINK_DROPDOWN_TITLE'),
      focus: false,
      undo: false,
      icon: 'journalLinkIcon',

      callback() {
        this.journalLink.showPopup();
      }
    });

    FroalaEditor.RegisterTemplate('journalLink.popup', '[_CUSTOM_]');

    FroalaEditor.PLUGINS.journalLink = function(editor) {
      return {
        _init,
        showPopup,
        hidePopup
      };

      // Create custom popup.
      function initPopup() {
        editor.popups.create('journalLink.popup', {
          custom: `<sq-journal-link id="journalLinkPopup-${editor.id}" on-link="onLink"></sq-journal-link>`
        });

        const $scope = $rootScope.$new(true) as ng.IScope & { onLink: typeof onLink };
        $scope.onLink = onLink;
        $compile($(`#journalLinkPopup-${editor.id}`))($scope);
        // Trigger a digest so that angularjs populates the dom, without this the element will have no width on the
        // first render and froala will poorly position the popover
        $scope.$evalAsync();

        editor.events.on('destroy', () => {
          $scope.$destroy();
        });
      }

      // Show the popup
      function showPopup() {
        if (!editor.popups.get('journalLink.popup')) {
          initPopup();
        }

        showPopupOverButton(editor, 'journalLink.popup', 'journalLink');
      }

      // Hide the custom popup.
      function hidePopup() {
        editor.popups.hide('journalLink.popup');
      }

      /**
       * Called by Froala when the editor is created and ready
       */
      function _init() {
        editor.events.on('commands.before', handleJournalLinks);
      }

      /**
       * Handler for Froala's froalaEditor.commands.before event to handle changes in behavior in the editor with
       * journal links.
       *
       * @param cmd - the name of the command that froala is about to execute
       * @returns false if and only if we want to cancel the command
       */
      function handleJournalLinks(cmd: string): boolean {
        const link = editor?.link?.get?.();
        const url = link?.getAttribute?.('href') as string;
        const isJournalLink = _.startsWith(url, JOURNAL_PREFIX_PATH);
        // We want most pages to open in a new tab, except for journal links which should open in the current tab.
        if (cmd === 'linkOpen') {
          if (isJournalLink) {
            $location.url(url);
            return false;
          } else {
            window.open(url);
            return false;
          }
        } else if (cmd === 'linkEdit') {
          // The editing-journal-link class causes element in the edit window to be hidden
          if (isJournalLink) {
            $('.fr-box').addClass('editing-journal-link');
          } else {
            $('.fr-box').removeClass('editing-journal-link');
          }
        }
      }

      /**
       * Handler for the Seeq link dropdown component
       *
       * @param {String} link - an anchor tag string to insert
       */
      function onLink(link) {
        const selection = editor.selection.text().toString();

        // If text is selected, then replace the link name with the selected text
        if (!_.isEmpty(selection)) {
          link = sqJournalLink.replaceLinkName(link, selection);
        }

        editor.html.insert(link, true);
        editor.undo.saveStep();
        editor.journalLink.hidePopup();
      }

    };

  }

  /**
   * Froala uses the width of the screen to trigger different toolbar icons, however for our use cases the width of
   * the editor is a more convenient and useful trigger. By overriding the `screenSize` method to measure the width
   * of the element and artificially triggering the window resize event when the width changes froala will respond
   * to changes in the elemnent width instead.
   */
  function resizeBasedOnToolbarSize() {
    FroalaEditor.DEFAULTS = $.extend(FroalaEditor.DEFAULTS, {
      // Froala normally determines these points using css media queries, but that isn't possible for a single element
      toolbarMaxWidthMD: 1200,
      toolbarMaxWidthSM: 992,
      toolbarMaxWidthXS: 768
    });

    FroalaEditor.PLUGINS.resizeBasedOnToolbarSize = function(editor) {
      function _init() {
        if (editor.opts.toolbarContainer) {
          editor.helpers.screenSize = () => {
            const width = $(editor.opts.toolbarContainer).width();
            if (width < editor.opts.toolbarMaxWidthXS) {
              return FroalaEditor.XS;
            } else if (width < editor.opts.toolbarMaxWidthSM) {
              return FroalaEditor.SM;
            } else if (width < editor.opts.toolbarMaxWidthMD) {
              return FroalaEditor.MD;
            } else {
              return FroalaEditor.LG;
            }
          };

          resize(); // screenSize may have already been called
        }
      }

      /**
       * Resize events are only triggered on the window object, not individual elements. So we have our
       * sqResizeNotify directive call this function to tell the editor to resize.
       */
      function resize() {
        let event;
        if (typeof (Event) === 'function') {
          // every browser except...
          event = new Event('resize');
        } else {
          // IE11. :(
          event = document.createEvent('Event');
          event.initEvent('resize', true, false);
        }

        // Cause froala to recalculate the screenSize (which is now the div size!)
        editor.o_win.dispatchEvent(event);
      }

      return { _init, resize };
    };
  }

  /**
   * Encapsulates code that adds the page break icon to the froala toolbar
   */
  function pageBreak() {
    service.customIconTemplates();

    FroalaEditor.DefineIcon('pageBreakIcon', { NAME: 'page-break', template: 'fc' });

    FroalaEditor.RegisterCommand('pageBreak', {
      title: $translate.instant('REPORT.EDITOR.INSERT_PAGE_BREAK'),
      icon: 'pageBreakIcon',
      callback() {
        this.html.insert('<hr class="fr-page-break" /><p></p>');
      }
    });
  }

  /**
   * Encapsulates code that adds the pdf export icon to the froala toolbar
   */
  function pdfExport() {
    service.customIconTemplates();

    FroalaEditor.DefineIcon('pdfExportIcon', { NAME: 'pdf-export', template: 'fc-bold' });

    FroalaEditor.RegisterCommand('pdfExport', {
      title: $translate.instant('REPORT.EDITOR.SAVE_AS_PDF_BUTTON_TITLE'),
      icon: 'pdfExportIcon',
      callback: sqPdfPreviewModal.showModal
    });
  }

  /**
   * Encapsulates code that adds the Seeq content icon to the froala toolbar and image toolbar
   */
  function topicDocumentContent() {
    service.customIconTemplates();
    insertOrUpdateSeeqContent();
    filteredLinkEdit();
    toggleSeeqContentBorders();
    toggleFixedWidth();

    function insertOrUpdateSeeqContent() {
      FroalaEditor.RegisterTemplate('insertSeeqContent.popup', '[_CUSTOM_]');

      FroalaEditor.PLUGINS.insertSeeqContent = function(editor) {
        // Create custom popup.
        function initPopup() {
          editor.popups.create('insertSeeqContent.popup', {
            custom: `<sq-insert-content-dropdown id="insertSeeqContentPopup-${editor.id}">` +
              `</sq-insert-content-dropdown>`
          });

          const $scope = $rootScope.$new(true);
          $compile($(`#insertSeeqContentPopup-${editor.id}`))($scope);
          // Trigger a digest so that angularjs populates the dom, without this the element will have no width on the
          // first render and froala will poorly position the popover
          $scope.$evalAsync();

          editor.events.on('destroy', () => {
            $scope.$destroy();
          });
        }

        // Show the popup
        function showPopup() {
          if (!editor.popups.get('insertSeeqContent.popup')) {
            initPopup();
          }

          showPopupOverButton(editor, 'insertSeeqContent.popup', 'insertSeeqContent');
        }

        // Hide the custom popup.
        function hidePopup() {
          editor.popups.hide('insertSeeqContent.popup');
        }

        // Methods visible outside the plugin.
        return {
          showPopup,
          hidePopup
        };
      };

      FroalaEditor.DefineIcon('seeqContentIcon', { NAME: 'seeq-content', template: 'fc-inverse' });

      // Register button that expands and displays the configuration tab
      FroalaEditor.RegisterCommand('insertSeeqContent', {
        title: $translate.instant('REPORT.EDITOR.CONTENT_BUTTON_TITLE'),
        icon: 'seeqContentIcon',
        focus: false,
        undo: false,
        callback() {
          this.insertSeeqContent.showPopup();
        }
      });

      FroalaEditor.RegisterCommand('updateSeeqContent', {
        title: $translate.instant('REPORT.EDITOR.UPDATE_BUTTON_TITLE'),
        icon: 'seeqContentIcon',
        callback() {
          const id = this.image.get().attr(SeeqNames.TopicDocumentAttributes.DataSeeqContent);

          try {
            sqReportContent.setStoreFromContent(id)
              .then(() => sqReportContentActions.setModalName(CONTENT_STATE.PROPERTIES));
          } catch (error) {
            // If we get here our content is probably corrupted somehow, best to ask the user to
            // recreate the content
            sqNotifications.errorTranslate('REPORT.EDITOR.UPDATE_FAILED');
            sqLogger.error(error);
          }
        },

        refresh() {
          // Only show the Seeq content button if the image is Seeq content
          const imageEditPopup = this.popups.get('image.edit');
          const image = _.get(this.image.get(), 0);
          const isSeeqContent = image?.hasAttribute?.('data-seeq-content');
          if (imageEditPopup) {
            if (isSeeqContent) {
              imageEditPopup.find('button[data-cmd="updateSeeqContent"]').css('display', 'inline-block');
              imageEditPopup.find('button[data-cmd="imageReplace"]').css('display', 'none');
            } else {
              imageEditPopup.find('button[data-cmd="updateSeeqContent"]').css('display', 'none');
              imageEditPopup.find('button[data-cmd="imageReplace"]').css('display', 'inline-block');
            }
          }
        }
      });
    }

    function filteredLinkEdit() {
      FroalaEditor.DefineIcon('filteredLinkEdit', {
        NAME: 'filteredLinkEdit',
        SVG_KEY: 'editLink'
      });
      FroalaEditor.RegisterCommand('filteredLinkEdit', {
        title: 'Edit Link',
        undo: false,
        refreshAfterCallback: false,
        popup: true,
        callback: function callback() {
          this.commands.exec('linkEdit');
        },
        refresh: function refresh($btn) {
          const link = this.link.get();
          const isWorksheetLink = $(link).find(`img[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`).length > 0;

          if (link && !isWorksheetLink) {
            $btn.removeClass('fr-hidden');
          } else {
            $btn.addClass('fr-hidden');
          }
        }
      });
    }

    function toggleSeeqContentBorders() {
      FroalaEditor.DefineIcon('seeqContentBorders', {
        NAME: 'window-maximize',
        template: 'fa'
      });

      FroalaEditor.RegisterCommand('seeqContentBorders', {
        title: $translate.instant('REPORT.EDITOR.BORDERS'),
        icon: 'seeqContentBorders',
        undo: true,
        popup: false,
        callback() { sqFroalaReportContent.toggleContentBorders(); },
        refresh: function refresh($btn) {
          sqFroalaReportContent.getContentIdsInSelection().length > 0 ?
            $btn.removeClass('fr-disabled') :
            $btn.addClass('fr-disabled');
        }
      });
    }

    function toggleFixedWidth() {
      FroalaEditor.DefineIcon('seeqFixedWidth', {
        NAME: 'arrows-h',
        template: 'fa'
      });

      FroalaEditor.RegisterCommand('seeqFixedWidth', {
          title: $translate.instant('REPORT.EDITOR.FIXED_WIDTH'),
          icon: 'seeqFixedWidth',
          undo: false,
          popup: false,
          callback() { sqReportActions.toggleFixedWidth(); }
        }
      );
    }
  }

  /**
   * Encapsulates code that adds the refresh content icon to the froala image toolbar
   */
  function refreshImage() {
    service.customIconTemplates();

    FroalaEditor.DefineIcon('refreshImageIcon', { NAME: 'redo', template: 'fc' });
    FroalaEditor.RegisterCommand('refreshImage', {
      title: $translate.instant('REPORT.EDITOR.REFRESH_CONTENT'),
      icon: 'refreshImageIcon',
      focus: false,
      undo: false,
      callback() { this.refreshImage.regenerateContentPiece(); }
    });

    FroalaEditor.PLUGINS.refreshImage = function(editor) {
      function regenerateContentPiece() {
        const contentId = editor.image.get().attr(SeeqNames.TopicDocumentAttributes.DataSeeqContent);
        sqReportContent.forceRefreshContent(contentId);
      }

      return {
        regenerateContentPiece
      };
    };
  }

  /**
   * Shared code for positioning a popup identified by id over the command button on the tooltip
   */
  function showPopupOverButton(editor, id, command) {
    // Set the editor toolbar as the popup's container.
    editor.popups.setContainer(id, editor.$tb);

    // This custom popup is opened by pressing a button from the editor's toolbar.
    // Get the button's object in order to place the popup relative to it.
    const $btn = editor.$tb.find(`.fr-command[data-cmd="${command}"]`);

    // Set the popup's position.
    const left = $btn.offset().left + $btn.outerWidth() / 2;
    const top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);
    editor.popups.show(id, left, top, $btn.outerHeight());
  }
}
