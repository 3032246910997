import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { HOME_SCREEN_TABS, APP_STATE } from '@/main/app.constants';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import _ from 'lodash';
import { EditWorkbookModal } from '@/hybrid/homescreen/EditWorkbookModal.organism';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { HomeScreenStore } from '@/hybrid/homescreen/homescreen.store';
import { ITEM_TYPES } from '@/hybrid/homescreen/homescreen.module';
import { TrackService } from '@/track/track.service';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { Feature, LicenseManagementStore } from '@/licenseManagement/licenseManagement.store';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { UtilitiesService } from '@/services/utilities.service';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { Icon } from '@/hybrid/core/Icon.atom';

const homeScreenNavigationBindings = bindingsDefinition({
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
  sqPendingRequests: injected<PendingRequestsService>(),
  sqTrack: injected<TrackService>(),
  sqUtilities: injected<UtilitiesService>(),
  $state: injected<ng.ui.IStateService>(),
  sqHomeScreenStore: injected<HomeScreenStore>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  sqLicenseManagementStore: injected<LicenseManagementStore>(),
  sqSystemConfiguration: injected<SystemConfigurationService>(),
  sqWorkbenchStore: injected<WorkbenchStore>()
});

export const HomeScreenNewButton: SeeqComponent<typeof homeScreenNavigationBindings> = () => {
  const {
    sqTrack,
    sqLicenseManagementStore,
    sqHomeScreenStore,
    sqHomeScreenActions,
    sqHomeScreenUtilities,
    sqPendingRequests,
    sqUtilities,
    sqSystemConfiguration,
    sqWorkbenchStore,
    $state
  } = useInjectedBindings(homeScreenNavigationBindings);
  const [disableNewWorkbook, setDisableNewWorkbook] = useState(false);
  const [disableNewReport, setDisableNewReport] = useState(false);
  const [disableNewFolder, setDisableNewFolder] = useState(false);
  const [disableNewDataLab, setDisableNewDataLab] = useState(false);
  const [disableNewButton, setDisableNewButton] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const dataLabLicensed = useFlux(sqLicenseManagementStore).hasValidFeature(Feature.Data_Lab);
  const dataLabEnabled = dataLabLicensed && !_.isEmpty(sqSystemConfiguration.dataLabHostname);
  const currentFolderId = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.currentFolderId);
  const { t } = useTranslation();

  useEffect(() => {
    sqHomeScreenActions.canCreateItemInFolder(currentFolderId).then(
      canCreateItem => setDisableNewButton(!canCreateItem));
  }, [currentFolderId]);

  const getFolderId = () => {
    const folderId = _.get(sqHomeScreenStore, 'currentFolderId', null);
    return folderId === '' ? null : folderId;
  };

  const updateView = (reload = true) => {
    const tab = sqHomeScreenStore.currentTab === HOME_SCREEN_TABS.CORPORATE
      ? HOME_SCREEN_TABS.CORPORATE
      : HOME_SCREEN_TABS.MY_FOLDER;
    if (!getFolderId()) {
      return $state.go(APP_STATE.WORKBOOKS, { t: sqUtilities.generateTabHash(tab) }, { reload });
    } else {
      return $state.go(APP_STATE.FOLDER_EXPANDED, {
        currentFolderId: sqHomeScreenStore.currentFolderId,
        t: sqUtilities.generateTabHash(tab)
      }, { reload });
    }
  };

  const newWorkbook = (e) => {
    setDisableNewWorkbook(true);
    sqPendingRequests.cancelAll();
    sqTrack.doTrack('HomeScreen 2.0', ITEM_TYPES.ANALYSIS, 'added');
    return sqHomeScreenActions.addWorkbook({ folderId: getFolderId() })
      .then((workbook) => {
        const openedInNewTab = $state.goNewTab(APP_STATE.WORKSHEET, {
          workbookId: workbook.workbookId,
          worksheetId: workbook.worksheetId,
          currentFolderId: workbook.parentFolderId || ''
        }, e);
        if (openedInNewTab) {
          return updateView();
        }
      })
      .finally(() => setDisableNewWorkbook(false));
  };

  const newFolder = () => {
    sqPendingRequests.cancelAll();
    sqTrack.doTrack('HomeScreen 2.0', ITEM_TYPES.FOLDER, 'added');
    setDisableNewFolder(true);
    setShowEditModal(true);
  };

  const newReportBinder = (e) => {
    setDisableNewReport(true);
    sqPendingRequests.cancelAll();
    sqTrack.doTrack('HomeScreen 2.0', ITEM_TYPES.TOPIC, 'added');
    return sqHomeScreenActions.addWorkbook({ folderId: getFolderId(), isReportBinder: true })
      .then((workbook) => {
        const openedInNewTab = $state.goNewTab(APP_STATE.WORKSHEET, {
          workbookId: workbook.workbookId,
          worksheetId: workbook.worksheetId,
          currentFolderId: workbook.parentFolderId || ''
        }, e);
        if (openedInNewTab) {
          return updateView();
        }
      })
      .finally(() => setDisableNewReport(false));
  };

  const newDataLab = () => {
    setDisableNewDataLab(true);
    sqPendingRequests.cancelAll();
    sqTrack.doTrack('HomeScreen 2.0', ITEM_TYPES.PROJECT, 'added');

    return sqHomeScreenActions.addProject({ folderId: getFolderId(), name: null })
      .then((workbook) => {
        sqHomeScreenUtilities.openProject(workbook.workbookId);
        updateView();
      })
      .finally(() => { setDisableNewDataLab(false); });
  };

  const options = [{
    display: 'NEW_WORKBOOK',
    text: 'WORKBOOK_DESCRIPTION',
    icon: 'mr5 fc-analysis',
    id: 'newWorkbook',
    enabled: !disableNewWorkbook,
    action: newWorkbook
  }, {
    display: 'NEW_REPORTBINDER',
    text: 'REPORTBINDER_DESCRIPTION',
    icon: 'mr5 fc-report',
    id: 'newReportBinder',
    enabled: !disableNewReport,
    action: newReportBinder
  }, {
    display: 'NEW_DATALAB',
    text: 'DATALAB_DESCRIPTION',
    icon: 'mr5 fc-seeq-datalab',
    id: 'newDataLab',
    enabled: dataLabEnabled && !disableNewDataLab,
    action: newDataLab
  }, {
    divider: true
  }, {
    display: 'WORKBENCH.FOLDER',
    icon: 'fc-folder force-color',
    id: 'newFolder',
    enabled: !disableNewFolder,
    action: newFolder
  }];

  const renderDropdownEntry = ({
    display = '',
    id = null,
    text = '',
    icon = '',
    enabled = false,
    action = _.noop,
    divider = false
  }) =>
    divider ? <Dropdown.Divider key="divider" /> :
      (
        <Dropdown.Item key={`dropdown_${display}`} onClick={e => action(e)} className="newHomeScreenItem"
          disabled={!enabled}>
          <button
            className="flexRowContainer p10 pl20 cursorPointer btn width-600 noBorder"
            id={id}
            data-testid={id}
            disabled={!enabled}>
          <span className="text-left">
            <Icon icon={icon} extraClassNames="workbookIconLarge workbenchDisplay" />
            <span className="h4">{t(display)}</span>
          </span>
            <div className="wrapText">{t(text)}</div>
          </button>
        </Dropdown.Item>);

  return (
    <>
      <div className="mt30 mb30 newHomeScreenItemWrapper">
        <DropdownButton
          className={classNames('btn p0 workbenchBtn border-radius-4 dropdownCaret',
            { disabledLook: disableNewButton })}
          disabled={disableNewButton}
          variant="theme"
          bsPrefix="sq-btn"
          title={<span className="pr20"><i className='fa fa-plus fa-lg pr5' /> {t('HOME_SCREEN.NEW')}</span>}
          key="newWorkbenchItem"
          alignRight={false}
          data-testid="homeScreenNewButton"
          id="newWorkbenchItem">
          {
            _.map(options, option => renderDropdownEntry(option))
          }
        </DropdownButton>
      </div>

      {/* The EditWorkbookModal is shown when a new Folder is created. */}
      {showEditModal &&
      <EditWorkbookModal
        onClose={() => {
          setShowEditModal(false);
          setDisableNewFolder(false);
        }}
        name={sqHomeScreenUtilities.getDefaultFolderName()}
        show={true}
        parentFolderId={getFolderId()}
        type={ITEM_TYPES.FOLDER}
        description=''
        id={undefined}
        owner={sqWorkbenchStore.currentUser}
        isCorporate={sqHomeScreenStore.currentTab === HOME_SCREEN_TABS.CORPORATE} />}
    </>
  );
};
