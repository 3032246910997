import angular from 'angular';
import _ from 'lodash';
import HttpCodes from 'http-status-codes';
import { NotificationsService } from '@/services/notifications.service';
import { NotifierService } from '@/services/notifier.service';
import { UtilitiesService } from '@/services/utilities.service';
import { ITEM_DATA_STATUS, TREND_STORES } from '@/trendData/trendData.module';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';

angular.module('Sq.Services.Redaction', []).service('sqRedaction', sqRedaction);

export type RedactionService = ReturnType<typeof sqRedaction>;

function sqRedaction(
  $q: ng.IQService,
  $injector: ng.auto.IInjectorService) {

  let redacted = false;

  const service = {
    initialize,
    isForbidden,
    handleForbidden,
    isRedacted,
    reset,
    isItemRedacted
  };

  return service;

  /**
   * Registers for and handles websocket permissions change notifications
   */
  function initialize() {
    const $state = $injector.get<ng.ui.IStateService>('$state');
    const sqNotifier = $injector.get<NotifierService>('sqNotifier');
    const sqNotifications = $injector.get<NotificationsService>('sqNotifications');
    const sqUtilities = $injector.get<UtilitiesService>('sqUtilities');

    sqNotifier.onPermissions((workbookId, worksheetId) => {
      if (workbookId === $state.params.workbookId && worksheetId === $state.params.worksheetId) {
        if (sqUtilities.isViewOnlyWorkbookMode) {
          sqNotifications.displayReloadNotification();
        } else {
          $state.go($state.current, $state.params, { location: false, reload: true, notify: false });
        }
      }
    });
  }

  /**
   * Returns true if the response represents a forbidden (403) respones
   *
   * @param {object} response - the response object
   * @param {number} response.status - the response status code
   * @returns {boolean}
   */
  function isForbidden(response) {
    return response.status === HttpCodes.FORBIDDEN;
  }

  /**
   * Handles forbidden responses by setting redacted to true. The worksheet watches the redacted state and displays
   * a banner if redacted is true.  Returns a caller-supplied deniedResponse in place of the forbidden response so
   * promise chains can continue and subsequent processing can take place.
   *
   * @param {object} response - the response object
   * @param {number} response.status - the response status code
   * @param {string} response.data.statusMessage - the response message
   * @param {any} deniedResponse - the value to be returned in place of the error for forbidden responses. If the
   * status code was not 403, then the original error response is returned.
   */
  function handleForbidden(response, deniedResponse = {}) {
    if (isForbidden(response)) {
      redacted = true;
      return deniedResponse;
    } else {
      return $q.reject(response);
    }
  }

  /**
   * Whether something has been redacted or not
   *
   * @returns {boolean} true if something has been redacted since the last reset() call
   */
  function isRedacted() {
    return redacted;
  }

  /**
   * Resets the redaction state to false.
   */
  function reset() {
    redacted = false;
  }

  /**
   * Returns true if the item from the details pane or an existing item parameter is redacted
   */
  function isItemRedacted(item) {
    // Sometimes properties from the item get picked off, so look up the item in the store to be certain
    const maybeStoreItem = $injector.get<TrendDataHelperService>('sqTrendDataHelper')
      .findItemIn(TREND_STORES, _.get(item, 'id'));
    return _.get(item, 'dataStatus') === ITEM_DATA_STATUS.REDACTED // Item from details pane / stores
      || _.get(maybeStoreItem, 'dataStatus') === ITEM_DATA_STATUS.REDACTED
      || !_.get(item, 'effectivePermissions.read', true) // Used for ancillaries and items w/o dataStatus
      || _.get(item, 'redacted', false); // An 'originalParameter' from a tool pane item
  }
}

