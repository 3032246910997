import angular from 'angular';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';
import { IModalInstanceService } from 'angular-ui-bootstrap';

/**
 */
angular.module('Sq.Services.Modal').store('sqModalStore', sqModalStore);

export type ModalStore = ReturnType<typeof sqModalStore>['exports'];

function sqModalStore() {
  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.NONE,

    initialize() {
      this.state = this.immutable({
        isModalOpen: false
      });

      // This state needs to be mutable for this store because bootstrap mutates properties of the modal
      this.mutableState = {
        currentOpenModal: null
      };
    },

    exports: {
      get isModalOpen(): boolean {
        return this.state.get('isModalOpen');
      },

      get currentOpenModal(): IModalInstanceService {
        if (!this.state.get('isModalOpen')) {
          throw new Error('Check if there is a modal open before accessing the currently open modal');
        }
        return this.mutableState.currentOpenModal;
      }
    },

    handlers: {
      MODAL_SET_OPEN_MODAL: 'setOpenModal'
    },

    /**
     * Indicate that a modal is currently open.
     *
     * @param modal - the modal that's currently open
     */
    setOpenModal(modal: IModalInstanceService) {
      this.state.set('isModalOpen', !!modal);
      this.mutableState.currentOpenModal = modal;
    }
  };

  return store;
}
