import _ from 'lodash';
import { ASSET_PATH_SEPARATOR } from '@/main/app.constants';
import { NotificationsService } from '@/services/notifications.service';
import { ItemsApi } from 'sdk/api/ItemsApi';
import { SwapOptionListV1, SwapOptionV1, TreesApi } from '@/sdk';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import assetReplaceModalTemplate from '@/search/assetReplaceModal.html';
import { TrendActions } from '@/trendData/trend.actions';
import { ModalService } from '@/services/modal.service';
import { TableBuilderActions } from '@/hybrid/tableBuilder/tableBuilder.actions';
import { ScreenshotService } from '@/services/screenshot.service';
import { UtilitiesService } from '@/services/utilities.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';

export type SearchResultUtilitiesService = ReturnType<typeof sqSearchResultService>;

export function sqSearchResultService(
  $translate: ng.translate.ITranslateService,
  sqTrendDataHelper: TrendDataHelperService,
  sqTrendActions: TrendActions,
  sqTableBuilderActions: TableBuilderActions,
  sqItemsApi: ItemsApi,
  sqModal: ModalService,
  sqScreenshot: ScreenshotService,
  sqNotifications: NotificationsService,
  sqUtilities: UtilitiesService,
  sqTreesApi: TreesApi
) {
  const service = {
    swapAsset,
    // exposed for testing
    getAutoSwap
  };

  return service;

  function getAutoSwap(swapOptionList: SwapOptionListV1): SwapOptionV1 | undefined {
    const itemsWithAssets = _.filter(sqTrendDataHelper.getAllItems(), item => !_.isEmpty(item.assets));
    const hasOptions = !_.isEmpty(swapOptionList.swapOptions);
    const onlyOneOptionHasSwaps = hasOptions && swapOptionList.swapOptions
      .filter(option => !_.isEmpty(option.itemsWithSwapPairs)).length === 1;
    const firstOptionSwapsPerfectly = hasOptions &&
      _.first(swapOptionList.swapOptions).itemsWithSwapPairs.length === itemsWithAssets.length &&
      _.every(_.first(swapOptionList.swapOptions).itemsWithSwapPairs, ['parameterMatch', 1.0]);
    if (onlyOneOptionHasSwaps || firstOptionSwapsPerfectly) {
      return _.first(swapOptionList.swapOptions);
    }
  }

  function getSwapPairs(swapOption: SwapOptionV1) {
    return _.mapValues(_.keyBy(swapOption.itemsWithSwapPairs, 'item.id'), 'swapPairs');
  }

  function getSwapRootChoices(swapOptionList: SwapOptionListV1, itemId) {
    // The path length is one more than the ancestor count since we count the item itself
    const items = sqTrendDataHelper.getAllItems();
    const validSwapOptionIds = _.chain(swapOptionList.swapOptions)
      .filter(option => !_.isEmpty(option.itemsWithSwapPairs))
      .map('swapRootCandidate.id')
      .value();
    // Available assets are the immediate parents of the items or their parameters
    const availableAssets = _.chain(items)
      .flatMap('assets')
      .reject(['id', itemId])
      .map(asset => _.assign(
        { pathComponentDisabled: _.map(asset.pathComponentIds, id => _.indexOf(validSwapOptionIds, id) === -1) },
        asset))
      .reject(asset => _.every(asset.pathComponentDisabled))
      .sortBy(['formattedName'])
      .uniqBy('id')
      .value();
    const likelySwapOuts = _.chain(availableAssets)
      .map((asset) => {
        // The likely swap-out is the path component that ranks highest in the pre-sorted swapOptions
        const id: string = _.head(_.sortBy(asset.pathComponentIds,
          id => _.indexOf(validSwapOptionIds, id) === -1 ? Infinity : _.indexOf(validSwapOptionIds, id)));
        const name: string = _.nth(_.split(asset.formattedName, ASSET_PATH_SEPARATOR),
          _.indexOf(asset.pathComponentIds, id));
        const disabled: boolean = asset.pathComponentDisabled[_.indexOf(asset.pathComponentIds, id)];
        return { id, name, disabled };
      })
      .uniqBy('id')
      .value();
    return { availableAssets, likelySwapOuts };
  }

  function swapAsset(item: { id: string, ancestors?: any[] }) {
    const swapOutItemIds = _.chain(sqTrendDataHelper.getAllItems())
      .uniqBy('id')
      .map('id')
      .value();

    return sqItemsApi.getSwapOptions({ id: item.id, swapOutItemIds })
      .then(({ data: swapOptionList }) => {
        const autoSwap = getAutoSwap(swapOptionList);
        if (autoSwap) {
          if (!_.isEmpty(item.ancestors)) {
            sqTableBuilderActions.changeAssetId((_.last(item.ancestors) as any).id);
          }
          if (autoSwap.invalidSwapOuts?.length > 0) {
            sqScreenshot.notifyError(
              $translate.instant('ASSET_REPLACE.INVALID') + '\n' +
              autoSwap.invalidSwapOuts.map(invalidSwap => invalidSwap.item.id).join('\n'));
          }
          return sqTrendActions.swapAssets(getSwapPairs(autoSwap));
        } else if (swapOptionList.swapOptions.length > 0) {
          sqScreenshot.notifyError($translate.instant('ASSET_REPLACE.MULTI_SWAP_COMING_LATER'));
          const { availableAssets, likelySwapOuts } = getSwapRootChoices(swapOptionList, item.id);
          const modalSize = _.max(_.map(availableAssets, 'formattedName.length')) > 50 ? 'lg' : 'sm';
          return sqModal.open({
            animation: true,
            controller: 'AssetReplaceModalCtrl',
            controllerAs: '$ctrl',
            template: assetReplaceModalTemplate,
            size: modalSize,
            resolve: {
              selectedAsset: _.constant(item),
              availableAssets: _.constant(availableAssets),
              likelySwapOuts: _.constant(likelySwapOuts)
            }
          }).result.then(({ id }) => {
            const swapOption = swapOptionList.swapOptions
              .find(option => option.swapRootCandidate.id === id);
            return sqTrendActions.swapAssets(getSwapPairs(swapOption));
          });
        } else {
          sqTreesApi.getTree({ id: item.id })
            .then(({ data }) => {
              let error = $translate.instant('ASSET_REPLACE.NOT_POSSIBLE');
              const nonAssetChildrenOfGivenAsset = _.reject(data.children, { type: SeeqNames.Types.Asset });
              const allItems = sqTrendDataHelper.getAllItems();
              const noSimilarChildren = _.isEmpty(_.intersection(
                _.map(nonAssetChildrenOfGivenAsset, 'name'),
                _.map(allItems, 'name'))
              );
              const presentAssetIds = _.chain(allItems)
                .map('assets')
                .flatten()
                .map('id')
                .uniq()
                .value();
              const effectivelyOnAsset = presentAssetIds.length === 1 && presentAssetIds[0] === item.id;
              if (allItems.length === 0) {
                error = 'ASSET_REPLACE.NO_SIGNALS_ERROR';
              } else if (noSimilarChildren && !effectivelyOnAsset) {
                error = 'ASSET_REPLACE.NO_SIMILAR_CHILDREN_ERROR';
              } else if (!sqUtilities.headlessRenderMode() && effectivelyOnAsset) {
                // We don't want to error in Topics when swapping to the asset that the items already belong to
                error = 'ASSET_REPLACE.ALREADY_PRESENT_ERROR';
              } else if (sqUtilities.headlessRenderMode()) {
                error = undefined;
              }

              if (sqUtilities.headlessRenderMode()) {
                error && sqScreenshot.notifyError($translate.instant(error));
              } else {
                sqNotifications.warn($translate.instant(error));
              }
            });
        }
      });
  }
}

