import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { FORM_ERROR, FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import {
  AgentStatusOutputV1, ConnectionOutputV1, ConnectionStatusOutputV1, ConnectorOutputV1
} from '@/sdk';
import { DatasourcesService } from '@/hybrid/administration/datasources/datasources.service';
import { useRandomId } from '@/hybrid/core/useRandomId.hook';

const connectionModalBindings = bindingsDefinition({
  sqDatasourcesService: injected<DatasourcesService>(),
  selectedConnection: prop<ConnectionStatusOutputV1>(),
  agents: prop<AgentStatusOutputV1[]>(),
  isNew: prop<boolean>(),
  onClose: prop<() => void>()
});

export const ManageConnectionModal: SeeqComponent<typeof connectionModalBindings> = ({
  selectedConnection,
  agents,
  isNew,
  onClose
}) => {
  const { sqDatasourcesService } = useInjectedBindings(connectionModalBindings);
  const { t } = useTranslation();

  const defaultConnection = {
    id: '', type: '', name: '', connectorName: '', agentName: '', enabled: true
  };

  const [agentOptions, setAgentOptions] = useState([]);
  const [connectionOptions, setConnectionOptions] = useState([]);
  const [connectorOptions, setConnectorOptions] = useState([]);
  const [connection, setConnection] = useState<ConnectionOutputV1>(defaultConnection);
  const [connectionNameErrorMessage, setConnectionNameErrorMessage] = useState('');
  const [agentName, setAgentName] = useState(selectedConnection.agentName);
  const [connectorName, setConnectorName] = useState(selectedConnection.connectorName);
  const [connectorNameErrorMessage, setConnectorNameErrorMessage] = useState('');
  const [connectionName, setConnectionName] = useState(selectedConnection.name);
  const [datasourceId, setDatasourceId] = useState(useRandomId());
  const [transformError, setTransformError] = useState('');
  const [loading, setLoading] = useState(false);

  const validateMinLength = value => _.isEmpty(value) || _.size(_.trim(value)) < 4;

  useEffect(() => {
    if (!isNew) {
      loadConnection(connectionName);
    }
    // We must clear the default transform error in order to set a custom error.
    // Set it here after the default error in the transform JsonTextAreaFormComponent is initialized.
    setTransformError(null);
    setAgentOptions(generateAgentOptions());
  }, []);

  const changeConnectionField = useCallback((key, value) => {
    setConnection((prevState) => {
      return { ...prevState, [key]: value };
    });
  }, [connection]);

  const createOrUpdateConnection = () => {
    sqDatasourcesService.createOrUpdateConnection(isNew, agentName, datasourceId, connection).then(onClose);
  };

  const onSave = () => {
    if (isNew) {
      sqDatasourcesService.getConnection(
        {
          connectorName: connection.connectorName,
          agentName,
          name: connection.name
        })
        .then((connection) => {
          if (connection.isArchived) {
            createOrUpdateConnection();
          } else {
            setConnectionNameErrorMessage(t('ADMIN.DATASOURCES.CONNECTION_MODAL.ERROR_MESSAGE_DATASOURCE_NAME_EXIST'));
          }
        })
        .catch(createOrUpdateConnection);
    } else {
      createOrUpdateConnection();
    }
  };

  function loadConnection(name) {
    setLoading(true);
    sqDatasourcesService.getConnection(
      {
        connectorName,
        agentName,
        name
      })
      .then((data: ConnectionOutputV1) => {
        if (isNew) {
          data.enabled = true;
          data.name = '';
        }
        setConnection(data);
      })
      .catch(() => setConnectionNameErrorMessage(
        t('ADMIN.DATASOURCES.CONNECTION_MODAL.ERROR_MESSAGE_DATASOURCE_NAME_ERROR')))
      .finally(() => setLoading(false));
  }

  function loadConnectionOptions(agentName: string, connectorName: string) {
    setLoading(true);
    setConnectorNameErrorMessage('');
    sqDatasourcesService.getConnectionNames(agentName, connectorName)
      .then((connections: []) => setConnectionOptions(connections))
      .catch((reason) => {
        setConnectorName('');
        setConnectorNameErrorMessage(reason);
      })
      .finally(() => setLoading(false));
  }

  function loadConnectorOptions(agentName: string) {
    setLoading(true);
    sqDatasourcesService.getConnectorNames(agentName)
      .then((connectors: []) => setConnectorOptions(connectors))
      .finally(() => setLoading(false));
  }

  const generateAgentOptions = () => {
    return _.map(agents, agent => ({
      value: agent.name,
      label: agent.name
    }));
  };

  const formDefinition: FormElement[] = [{
    component: 'FormGroup',
    name: 'editConnection',
    key: 'editConnection',
    components: [
      // agent name
      {
        includeIf: isNew,
        component: 'LabelFormComponent',
        value: t('ADMIN.DATASOURCES.CONNECTION_MODAL.AGENT_NAME'),
        name: 'agentNameLabel',
        extraClassNames: 'text-bolder'
      },
      {
        includeIf: isNew,
        component: 'SelectFormComponent',
        displayNumber: false,
        name: 'agentName',
        testId: 'agentNameSelectorTestId',
        value: agentName,
        placeholder: 'ADMIN.DATASOURCES.CONNECTION_MODAL.SELECT_AGENT_PLACEHOLDER',
        popoverContent: null,
        disabled: loading,
        onChange: (value) => {
          setConnectorName('');
          setConnectorOptions([]);
          setConnectionName('');
          setConnectionOptions([]);
          setAgentName(value);
          changeConnectionField('agentName', value);
          loadConnectorOptions(value);
        },
        options: agentOptions,
        required: true,
        insideModal: true,
        extraClassNames: 'agentNameSelector_select mb20'
      },
      // connector name
      {
        component: 'LabelFormComponent',
        value: t('ADMIN.DATASOURCES.CONNECTION_MODAL.CONNECTOR_NAME'),
        name: 'connectorsNameLabel',
        extraClassNames: 'text-bolder'
      },
      {
        includeIf: isNew,
        component: 'SelectFormComponent',
        displayNumber: false,
        name: 'connectorsName',
        testId: 'connectorNameSelectorTestId',
        value: connectorName,
        placeholder: 'ADMIN.DATASOURCES.CONNECTION_MODAL.SELECT_CONNECTOR_PLACEHOLDER',
        popoverContent: null,
        disabled: loading || agentName.length === 0,
        onChange: (value) => {
          setConnectorName(value);
          setConnectionName('');
          setConnectionOptions([]);
          changeConnectionField('connectorName', value);
          loadConnectionOptions(agentName, value);
        },
        options: connectorOptions,
        required: true,
        insideModal: true,
        extraClassNames: 'connectorSelector_select'
      },
      {
        includeIf: !_.isEmpty(connectorNameErrorMessage),
        component: 'ErrorMessageFormComponent',
        name: 'connectorNameError',
        key: 'connectorNameError',
        testId: 'connectorNameErrorTestId',
        value: connectorNameErrorMessage,
        type: FORM_ERROR,
        dismissible: false,
        failForm: false,
        extraClassNames: 'mb20'
      },
      {
        includeIf: !isNew,
        component: 'LabelFormComponent',
        value: connectorName,
        name: 'connectorsNameValue',
        testId: 'connectorNameValueTestId',
        extraClassNames: 'text-italic'
      },
      // template
      {
        includeIf: isNew,
        component: 'LabelFormComponent',
        value: t('ADMIN.DATASOURCES.CONNECTION_MODAL.TEMPLATE_CONNECTION'),
        name: 'templateConnectionNameLabel',
        extraClassNames: 'text-bolder'
      },
      {
        includeIf: isNew,
        component: 'SelectFormComponent',
        displayNumber: false,
        name: 'templateConnectionName',
        testId: 'connectionNameSelectorTestId',
        value: connectionName,
        placeholder: 'ADMIN.DATASOURCES.CONNECTION_MODAL.SELECT_CONNECTION_PLACEHOLDER',
        popoverContent: null,
        disabled: loading || agentName.length === 0 || connectorName.length === 0,
        onChange: (value) => {
          changeConnectionField('name', '');
          loadConnection(value);
        },
        options: connectionOptions,
        required: false,
        insideModal: true,
        extraClassNames: 'connectionSelector_select mb20'
      },
      // datasource name
      {
        includeIf: isNew,
        component: 'LabelFormComponent',
        value: t('ADMIN.DATASOURCES.CONNECTION_MODAL.DATASOURCE_NAME'),
        name: 'datasourceNameLabel',
        extraClassNames: 'text-bolder'
      },
      {
        includeIf: isNew,
        key: 'connectionNameKey',
        component: 'FormControlFormComponent',
        name: 'connectionName',
        value: connection.name,
        onChange: (value) => {
          setConnectionNameErrorMessage('');
          changeConnectionField('name', value);
        },
        testId: 'connectionNameTestId',
        disabled: loading,
        size: 'md',
        required: true,
        extendValidation: true,
        validation: validateMinLength,
        placeholder: 'ADMIN.DATASOURCES.CONNECTION_MODAL.DATASOURCE_NAME_PLACEHOLDER',
        customError: t('ADMIN.DATASOURCES.CONNECTION_MODAL.ERROR_MESSAGE_DATASOURCE_NAME_VALIDATION'),
        extraClassNames: !_.isEmpty(connectionNameErrorMessage) ? 'mb10' : 'mb20'
      },
      {
        includeIf: !_.isEmpty(connectionNameErrorMessage),
        component: 'ErrorMessageFormComponent',
        name: 'connectionNameExistingError',
        key: 'errorConnectionNameExistsKey',
        value: connectionNameErrorMessage,
        type: FORM_ERROR,
        dismissible: false,
        failForm: false
      },
      // datasource id
      {
        includeIf: isNew,
        component: 'LabelFormComponent',
        value: t('ADMIN.DATASOURCES.CONNECTION_MODAL.DATASOURCE_ID'),
        name: 'connectionDatasourceIdLabel',
        extraClassNames: 'text-bolder'
      },
      {
        includeIf: isNew,
        key: 'connectionDatasourceId',
        component: 'FormControlFormComponent',
        name: 'connectionDatasourceId',
        value: datasourceId,
        onChange: (value: string) => setDatasourceId(value),
        testId: 'connectionDatasourceTestId',
        disabled: loading,
        placeholder: 'ADMIN.DATASOURCES.CONNECTION_MODAL.DATASOURCE_ID_PLACEHOLDER',
        size: 'md',
        required: true,
        className: 'mb20'
      },
      // enabled
      {
        includeIf: isNew,
        component: 'FormRow',
        name: 'connectionEnabled',
        extraClassNames: 'mb20',
        components: [
          {
            component: 'LabelFormComponent',
            value: t('ADMIN.DATASOURCES.CONNECTION_MODAL.ENABLED'),
            name: 'enabledConnectionLabel',
            extraClassNames: 'text-bolder pt3 mr5'
          },
          {
            id: 'connectionEnabledId',
            key: 'connectionEnabledComponentKey',
            component: 'CheckboxFormComponent',
            label: '',
            onChange: () => changeConnectionField('enabled', !connection.enabled),
            testId: 'connectionEnabledTestId',
            disabled: loading,
            value: connection.enabled,
            type: 'checkbox',
            name: 'connectorEnabled'
          }
        ]
      },
      // max concurrent requests
      {
        includeIf: connection?.pullConnection,
        component: 'LabelFormComponent',
        value: t('ADMIN.DATASOURCES.CONNECTION_MODAL.MAX_CONCURRENT_REQUESTS'),
        name: 'maxConcurrentLabel',
        extraClassNames: 'text-bolder'
      },
      {
        includeIf: connection?.pullConnection,
        key: 'connectionMaxConcurrentKey',
        component: 'FormControlFormComponent',
        name: 'connectionMaxConcurrent',
        type: 'number',
        value: connection?.maxConcurrentRequests,
        onChange: (value: number) => changeConnectionField('maxConcurrentRequests', value),
        validation: ((value) => {
          if (value === null || value === undefined || value === '') return false;
          if (value < 1) return true;
          return isNaN(value);
        }),
        testId: 'connectionMaxConcurrentId',
        disabled: loading,
        size: 'md',
        required: false,
        className: 'mb20'
      },
      // max results per request
      {
        includeIf: connection?.pullConnection,
        component: 'LabelFormComponent',
        value: t('ADMIN.DATASOURCES.CONNECTION_MODAL.MAX_RESULTS_PER_REQUEST'),
        name: 'maxResultsPerRequestsLabel',
        extraClassNames: 'text-bolder'
      },
      {
        includeIf: connection?.pullConnection,
        key: 'connectionMaxResultsPerRequestsKey',
        component: 'FormControlFormComponent',
        name: 'connectionMaxResultsPerRequests',
        value: _.toString(connection?.maxResultsPerRequests),
        type: 'number',
        onChange: (value: number) => changeConnectionField('maxResultsPerRequests', value),
        testId: 'connectionMaxResultsPerRequestsId',
        validation: ((value) => {
          if (value === null || value === undefined || value === '') return false;
          if (value < 1) return true;
          return isNaN(value);
        }),
        disabled: loading,
        size: 'md',
        required: false,
        className: 'mb20'
      },
      // transforms
      {
        includeIf: connection?.indexingConnection,
        component: 'LabelFormComponent',
        value: t('ADMIN.DATASOURCES.CONNECTION_MODAL.TRANSFORMS'),
        name: 'transformsLabel',
        tooltip: 'ADMIN.DATASOURCES.CONNECTION_MODAL.TRANSFORMS_TOOLTIP',
        onIconClick: () => open('https://telemetry.seeq.com/support-link/wiki/spaces/KB/pages/127009211', '_blank'),
        extraClassNames: 'text-bolder'
      },
      {
        includeIf: connection?.indexingConnection,
        key: 'transformsConnectionConfigKey',
        component: 'JsonTextAreaFormComponent',
        name: 'transformsConfig',
        value: JSON.stringify(connection?.transforms, null, 4),
        testId: 'transformsConnectionConfig',
        disabled: loading,
        size: 'md',
        onChange: (value: string) => {
          try {
            value = _.isEmpty(value) ? undefined : JSON.parse(value);
            changeConnectionField('transforms', value);
          } catch (e) {
            // We need to save only valid JSON.
            // On change triggers before the validation method, we need to
            // catch all errors, and then it rechecks the value in the validation method. If the value is not correct,
            // it provides an error message.
          }
        },
        validation: ((value) => {
          if (value === null || value === undefined || value === '') return false;
          try {
            const transformsObj = JSON.parse(value);
            if (!Array.isArray(transformsObj)) {
              setTransformError(t('ADMIN.DATASOURCES.CONNECTION_MODAL.TRANSFORMS_VALIDATION_ARRAY_ERROR'));
              return true;
            }
          } catch (e) {
            setTransformError(e.toString());
            return true;
          }
          return false;
        }),
        customError: transformError,
        rows: connection.transforms ? 4 : 2,
        required: false,
        className: 'min-height-30'
      },
      // additional configuration
      {
        component: 'LabelFormComponent',
        value: t('ADMIN.DATASOURCES.CONNECTION_MODAL.ADDITIONAL_CONFIGURATION'),
        name: 'additionalConfigurationLabel',
        extraClassNames: connection.indexingConnection ? 'text-bolder mt20' : 'text-bolder'
      },
      {
        key: 'connectionConfigKey',
        component: 'JsonTextAreaFormComponent',
        name: 'jsonConfig',
        value: JSON.stringify(connection.json, null, 4),
        onChange: ((value) => {
          try {
            changeConnectionField('json', JSON.parse(value));
          } catch (e) {
            // We need to save only valid JSON.
            // On change triggers before the validation method, we need to
            // catch all errors, and then it rechecks the value in the validation method. If the value is not correct,
            // it provides an error message.
          }
        }),
        testId: 'jsonConfigTestId',
        disabled: false,
        size: 'md',
        rows: connection.json ? 4 : 2,
        required: true,
        className: 'min-height-30'
      }
    ]
  }];

  const renderSubTitle = () => (
    <span>{connectionName} - {agentName}</span>
  );

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid='manageConnectionsModal'>
      <Modal.Header closeButton={true}>
        <div className="flexRowContainer">
          <div className="flexColumnContainer flexFill">
            <Modal.Title>
              {isNew ?
                t('ADMIN.DATASOURCES.CONNECTION_MODAL.CREATE_NEW_TITLE') :
                t('ADMIN.DATASOURCES.CONNECTION_MODAL.MODIFY_TITLE')}
            </Modal.Title>
          </div>
          <div data-testid="modal-subtitle" className="sq-fairly-dark-gray text-italic">
            {!isNew && renderSubTitle()}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <SimpleSaveFormBuilder
          formDefinition={formDefinition}
          submitFn={() => onSave()}
          submitBtnLabel={isNew ? 'CREATE' : 'UPDATE'}
          closeFn={onClose} />
      </Modal.Body>
    </Modal>);
};
