import angular from 'angular';

angular.module('Sq.Directives.AutoFocus', []).directive('sqAutoFocus', sqAutoFocus);

function sqAutoFocus() {
  return {
    restrict: 'A', // only activate on element attribute
    link(scope, element, attributes) {
      // monitor the "visible" property of the linked element and focus only when it changes to true
      scope.$watch(function() {
        return element.is(':visible');
      }, function(vis) {

        if (vis) {
          // Check both the current element and all descendant elements to find the first one that can receive focus,
          // using the ':focusable' selector. .find looks in descendants, and .addBack looks at the current element.
          // It should never be the case that an element can receive focus and also has descendants that can, but
          // this simplifies the code.
          const focusableElement = element.find(':focusable').addBack(':focusable').first();
          if (focusableElement.length === 1) {
            focusableElement.select();
            focusableElement.focus();
          }
        }
      });

      if (attributes.sqAutoFocusOn) {
        // If sq-auto-focus-on attribute is set, it'll listen for a $scope.$broadcast
        // containing the set value and then focus.
        scope.$on(attributes.sqAutoFocusOn, function() {
          element.focus();
        });
      }
    }
  };
}
