import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';
import _ from 'lodash';
import { AssetChild, AssetGroupAsset, PATH_SEPARATOR } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { getColumnsHelper, getNameWithoutPath, getPath } from '@/hybrid/assetGroupEditor/assetGroup.utilities';

export type AssetGroupStore = ReturnType<typeof sqAssetGroupStore>['exports'];

export function sqAssetGroupStore() {
  return {
    persistenceLevel: PERSISTENCE_LEVEL.WORKSHEET,

    initialize() {
      this.state = this.immutable({
        id: '',
        assets: [],
        name: '',
        description: '',
        errors: [],
        isLoading: false,
        hasUnsavedChanges: false,
        nameError: false
      });
    },

    exports: {
      get id() {
        return this.state.get('id');
      },
      get assets() {
        return this.state.get('assets');
      },
      get name() {
        return this.state.get('name');
      },
      get nameError() {
        return this.state.get('nameError');
      },
      get description() {
        return this.state.get('description');
      },
      get errors() {
        return this.state.get('errors');
      },
      get isLoading() {
        return this.state.get('isLoading');
      },
      get hasUnsavedChanges() {
        return this.state.get('hasUnsavedChanges');
      }
    },

    dehydrate() {
      return _.omit(this.state.serialize(), 'nameError');
    },

    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      ASSET_GROUP_ADD_ASSET: 'addAsset',
      ASSET_GROUP_ADD_STORED_ASSET: 'addStoredAsset',
      ASSET_GROUP_SET_ROOT: 'setRoot',
      ASSET_GROUP_ADD_CHILD_TO_ASSET: 'addChildToAsset',
      ASSET_GROUP_ADD_CALCULATION_CHILD_TO_ASSET: 'addCalculationChildToAsset',
      ASSET_GROUP_SET_NAME: 'setName',
      ASSET_GROUP_SET_NAME_ERROR: 'setNameError',
      ASSET_GROUP_SET_DESCRIPTION: 'setDescription',
      ASSET_GROUP_ADD_ATTRIBUTE: 'addAttribute',
      ASSET_GROUP_UPDATE_COLUMN_NAME: 'updateColumnName',
      ASSET_GROUP_UPDATE_ASSET_NAME: 'updateAssetName',
      ASSET_GROUP_RESET_STORE: 'reset',
      ASSET_GROUP_REMOVE_CHILD_FROM_ASSET: 'removeChildFromAsset',
      ASSET_GROUP_REMOVE_ASSET: 'removeAsset',
      ASSET_GROUP_REMOVE_ATTRIBUTE: 'removeAttribute',
      ASSET_GROUP_SET_IS_LOADING: 'setIsLoading',
      ASSET_GROUP_SET_HAS_UNSAVED_CHANGES: 'setHasUnsavedChanges'
    },

    setRoot({ name, id, description }) {
      this.state.merge({ name, id, description });
    },

    reset() {
      this.state.set({ assets: [], name: '', description: '', hasUnsavedChanges: false });
    },

    updateAssetName({ original, newName }) {
      const assets = _.map([...this.state.get('assets')],
        asset => asset.name === original.name && getPath(asset) === getPath(original) ?
          { ...asset, name: newName } : asset);

      this.state.set('assets', assets);
    },

    updateColumnName({ originalName, newName }) {
      const assets = _.map([...this.state.get('assets')], asset => ({
        ...asset,
        children: _.map(asset.children,
          child => child.name === originalName ? { ...child, name: newName } : child)
      }));

      this.state.set('assets', assets);
    },

    removeAsset({ name }) {
      const assets = [...this.state.get('assets')];
      _.remove(assets, ['name', name]);
      this.state.set('assets', assets);
    },

    removeAttribute({ name }) {
      const assets = _.map([...this.state.get('assets')], asset => ({
        ...asset,
        children: _.chain(asset.children).map(child => child.name !== name ? child : null).compact().value()
      }));

      this.state.set('assets', assets);
    },

    removeChildFromAsset({ asset, child }) {
      const existingAsset: any = findExistingAsset(this.state.get('assets'), asset);
      const idx = _.findIndex(this.state.get('assets'), existingAsset);
      const cursor = this.state.select('assets', idx);
      if (cursor.exists()) {
        const newChildren = [..._.filter([...existingAsset.children as AssetChild[]],
          childEntry => childEntry.name !== child.name)];
        cursor.merge({
          ...existingAsset,
          children: newChildren
        });
      }
    },

    addChild({ asset, columnType, childId, id, name, manuallyAdded = false, type, formula, params }) {
      if (columnType === 'Calculation') {
        this.addCalculationChildToAsset({ asset, name, manuallyAdded, formula, params, childId, id });
      } else {
        this.addChildToAsset({ asset, childId, id, name, manuallyAdded, type, params });
      }
    },

    // This DOES NOT add Calculations - this just adds "pass-thru" signals
    addChildToAsset({ asset, childId, id, name, manuallyAdded, type, params }) {
      const idx = _.findIndex(this.state.get('assets'), asset);
      const cursor = this.state.select('assets', idx);
      const formula = '$signal';
      const parameters = params === undefined ? [{ name: 'signal', item: { id: childId } }] : params;
      if (cursor.exists()) {
        const children = cursor.get('children');
        const existingChild = _.find(asset.children, child => child.name === name);
        // manually added attributes that get added for the first time should be treated like brand new children
        // for children that are being updated with a new signal we need to keep track so we can update the
        // underlying formula not the asset
        cursor.merge(
          {
            ...asset,
            children: [..._.filter(children, child => child.name !== name),
              _.has(existingChild, 'id')
                ? { ...existingChild, id, itemId: childId, manuallyAdded, formula, parameters, type }
                : {
                  id,
                  itemId: childId,
                  name,
                  type,
                  manuallyAdded,
                  formula,
                  parameters
                }]
          });
      }
    },

    addCalculationChildToAsset({ asset, name, manuallyAdded, formula, params, childId }) {
      const idx = _.findIndex(this.state.get('assets'), asset);
      const cursor = this.state.select('assets', idx);
      if (cursor.exists()) {
        const children = cursor.get('children');
        const existingChild = _.find(children, child => child.name === name);
        // manually added attributes that get added for the first time should be treated like brand new children
        // for children that are being updated with a new signal we need to keep track so we can update the
        // underlying formula not the asset
        cursor.merge(
          {
            ...asset,
            children: [..._.filter(children, child => child.name !== name),
              _.has(existingChild, 'id')
                ? { ...existingChild, formula, parameters: params }
                : {
                  columnType: 'Calculation',
                  formula,
                  parameters: params,
                  name,
                  manuallyAdded,
                  id: childId
                }]
          });
      }
    },

    addAttribute(newChild) {
      this.state.set('assets', _.map([...this.state.get('assets')], asset => ({
        ...asset, children: [...asset.children, newChild]
      })));
    },

    // adds an asset that is already part of an asset group - called when opening an asset tree for editing
    addStoredAsset({ asset }) {
      this.state.set('assets',
        [...this.state.get('assets'), { name: asset.name, children: [], assetPath: asset.assetPath, id: asset.id }]);
      const existingAsset: any = findExistingAsset(this.state.get('assets'), asset);
      _.forEach(asset.children, (child) => {
        this.addChild({
          ...child,
          childId: child.id ? child.id : child.itemId,
          asset: existingAsset
        });
      });
    },

    addAsset({ asset }) {
      const assetsInStoreBeforeAdd = this.state.get('assets');
      const existingAsset: any = findExistingAsset(assetsInStoreBeforeAdd, asset);
      if (!_.isEmpty(existingAsset)) {
        _.forEach(asset.children, (child) => {
            this.addChild({
              ...child,
              childId: child.id ? child.id : child.itemId,
              asset: existingAsset
            });
          }
        );
      } else {
        // adds a new asset entry. this will add all the "children" of the assets as well as calculation children
        this.state.set('assets',
          [...this.state.get('assets'), { ..._.omit(asset, 'children'), children: [] }]);
        const existingAsset: any = findExistingAsset(this.state.get('assets'), asset);

        _.forEach(asset.children, (child) => {
          this.addChildToAsset({
            ...child,
            childId: child.id ? child.id : child.itemId,
            asset: existingAsset
          });
        });

        // we also want to add all the calculations to this newly added asset!
        const calculationColumns = _.filter(getColumnsHelper(this.state.get('assets')), { columnType: 'Calculation' });
        _.forEach(calculationColumns, ({ name, formula, parameters }) =>
          this.addCalculationChildToAsset({ asset: existingAsset, name, formula, parameters }));
      }

      // to ensure unique names we prefix asset names with as much of their path as required to make them "unique"
      this.state.commit();
      const assetsInStore = this.state.get('assets');

      let assetsWithSameName = _.chain(assetsInStore)
        .filter(storeAsset => getNameWithoutPath(storeAsset) === asset.name)
        .omit('path')
        .value();

      if (_.size(assetsWithSameName) > 1) {
        while (_.size(assetsWithSameName) > 0) {
          assetsWithSameName = getOneLevelUpPath(assetsWithSameName);
          const paths = _.map(assetsWithSameName, 'path');
          // now every asset has a "path" property - make sure all those paths are unique:
          assetsWithSameName = _.filter(assetsWithSameName, (current) => {
            const dupePaths = _.filter(paths, p => p === current.path);
            if (_.size(dupePaths) === 1) {
              const originalName = getNameWithoutPath(current);
              this.updateAssetName({ original: current, newName: `${current.path}${PATH_SEPARATOR}${originalName}` });
              return false;
            }
            return true;
          });
        }
      }
    },

    setName({ name }) {
      this.state.set('name', name);
    },

    setNameError({ error }) {
      this.state.set('nameError', error);
    },

    setDescription({ description }) {
      this.state.set('description', description);
    },

    setIsLoading({ isLoading }) {
      this.state.set('isLoading', isLoading);
    },

    setHasUnsavedChanges({ hasUnsavedChanges }) {
      this.state.set('hasUnsavedChanges', hasUnsavedChanges);
    }
  };

  function findExistingAsset(assets: [AssetGroupAsset], newAsset: AssetGroupAsset) {
    return _.chain(assets)
      .filter((asset) => {
        if (getNameWithoutPath(asset) === newAsset?.name) {
          return newAsset.assetPath === asset.assetPath;
        }
        return false;
      })
      .head()
      .value();
  }

  function getOneLevelUpPath(assets) {
    return _.map(assets, (current) => {
      const startingPath = getPath(current);
      if (current.path) {
        // trim the path to "cut off" the path we've already extracted
        const remainingPath = startingPath.substring(0, (startingPath.indexOf(current.path)) - 1);
        const path = remainingPath.substring(_.lastIndexOf(remainingPath, PATH_SEPARATOR) + 1);
        return _.assign({}, current, { path: `${path}${PATH_SEPARATOR}${current.path}` });
      } else {
        const path = startingPath.substring(_.lastIndexOf(startingPath, PATH_SEPARATOR) + 1);
        return _.assign({}, current, { path });
      }
    });
  }
}
