import React from 'react';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MinimapInner } from '@/hybrid/scatterPlot/minimap/MinimapInner.molecule';
import { MinimapDragLayer } from '@/hybrid/scatterPlot/minimap/MinimapDragLayer.atom';

const scatterPlotMinimapBindings = bindingsDefinition({
  trendStart: prop<number>(),
  trendEnd: prop<number>(),
  xValue: prop<number>(),
  plotSeries: prop<any[]>(),
  regions: prop<{ start: number, end: number, color: string, id: string }[]>(),
  selectorLow: prop<number>(),
  selectorHigh: prop<number>(),
  setSelectors: prop<any>(),
  useSelectors: prop<boolean>(),
  backgroundColor: prop.optional<string>()
});

/**
 * Wraps the Minimap with a DnDProvider and incorporates the MinimapDragLayer at the same level as the
 *  ScatterPlotMinimap, which is the drag container.
 **/
export const ScatterPlotMinimap: SeeqComponent<typeof scatterPlotMinimapBindings> = (props) => {
  const {
    trendStart,
    trendEnd,
    xValue,
    plotSeries,
    regions,
    selectorLow,
    selectorHigh,
    setSelectors,
    useSelectors,
    backgroundColor
  } = props;

  return <DndProvider backend={HTML5Backend}>
    <div className="flexFill flexRowContainer">
      <div className="minimapDragLayer">
        <MinimapDragLayer />
      </div>
      <MinimapInner
        trendStart={trendStart}
        trendEnd={trendEnd}
        xValue={xValue}
        plotSeries={plotSeries}
        regions={regions}
        selectorLow={selectorLow}
        selectorHigh={selectorHigh}
        setSelectors={setSelectors}
        useSelectors={useSelectors}
        backgroundColor={backgroundColor} />
    </div>
  </DndProvider>;
};

export const sqScatterPlotMinimap = angularComponent(scatterPlotMinimapBindings,
  ScatterPlotMinimap);
