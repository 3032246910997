import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import _ from 'lodash';
import { default as React, useState } from 'react';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { UsersApi } from 'sdk/api/UsersApi';
import { IdentityPreviewV1 } from 'sdk/model/models';
import { AuthorizationService } from '@/services/authorization.service';
import { Icon } from '@/hybrid/core/Icon.atom';

const selectIdentityBindings = bindingsDefinition({
  idForLabel: prop.optional<string>(),
  setIdentity: prop<(object: any) => void>(),
  identity: prop<Partial<IdentityPreviewV1>>(),
  allowGroups: prop.optional<boolean>(),
  includeAllProperties: prop.optional<boolean>(),
  startEditable: prop.optional<boolean>(),
  placeholder: prop.optional<string>(),
  tooltip: prop.optional<string>(),
  unauthorizedTooltip: prop.optional<string>(),
  multiple: prop.optional<boolean>(),
  clearIdentityWhenEmpty: prop.optional<boolean>(),
  sqUsersApi: injected<UsersApi>(),
  sqAuthorization: injected<AuthorizationService>()
});

/** Input field for searching and selecting Seeq Identities (Users or Groups) */
export const SelectIdentity: SeeqComponent<typeof selectIdentityBindings> = (props) => {
  const { sqUsersApi, sqAuthorization } = useInjectedBindings(selectIdentityBindings);
  const {
    setIdentity,
    identity,
    placeholder,
    tooltip,
    unauthorizedTooltip,
    idForLabel,
    allowGroups = false,
    includeAllProperties = false,
    startEditable = false,
    multiple = false,
    clearIdentityWhenEmpty = false
  } = props;
  const { t } = useTranslation();

  const [loadingIdentities, setLoadingIdentities] = useState(false);
  const [options, setOptions] = useState([]);
  const [editingIdentity, setEditingIdentity] = useState(startEditable);
  const [typeahead, setTypeahead] = useState(null);

  React.useEffect(() => {
    if (_.isEmpty(identity) && clearIdentityWhenEmpty) {
      typeahead?.getInstance().clear();
    }
  }, [identity]);

  const renderMenu = (option, props) => (
    <div>
      {option.type === 'User' && <i className="fa fa-user width-18" />}
      {option.type === 'UserGroup' && <i className="fa fa-users width-18" />}
      <Highlighter search={props.text}>
        {option.name}
      </Highlighter>
      {option.type === 'User' &&
      <div>
        <small>{option.email || option.username}</small>
      </div>
      }
    </div>
  );

  const handleIdentitiesSearch = (query) => {
    setLoadingIdentities(true);
    setIdentity(multiple ? [] : {});
    const toArrayIfMultiple = object => multiple ? [object] : object;

    return sqUsersApi.autocompleteUsersAndGroups({
        query
      })
      // Filter out user groups
      .then((response) => {
        const items = allowGroups ? response.data.items : _.filter(response.data.items, { type: 'User' });
        const filtered = includeAllProperties ? items :
          _.map(items, item => _.pick(item, ['id', 'name', 'username', 'type', 'email']));
        setOptions(filtered as any);
        if (filtered.length === 1) {
          setIdentity(toArrayIfMultiple(filtered[0]));
        }
        setLoadingIdentities(false);
      });
  };

  const onInputChange = selected => multiple ? setIdentity(selected)
    : setIdentity(selected.length === 1 ? _.first(selected) : {});

  const editIdentity = () => {
    setEditingIdentity(true);
    setIdentity({});
  };

  const renderIdentity = (option) => {
    if (option?.email) {
      return `${option.name} (${option.email})`;
    } else if (option?.username) {
      return `${option.name} (${option.username})`;
    } else {
      return option?.name;
    }
  };

  const renderEditable = (
    <div className="input-group width-maximum" data-testid={`selectIdentityInput_${idForLabel}`}>
      <AsyncTypeahead
        id={idForLabel}
        ref={setTypeahead}
        labelKey={option => renderIdentity(option)}
        allowNew={false}
        multiple={multiple}
        options={options}
        isLoading={loadingIdentities}
        onSearch={handleIdentitiesSearch}
        onChange={onInputChange}
        renderMenuItemChildren={renderMenu}
        placeholder={t(placeholder)} />
    </div>);

  const renderDisplayMode = (
    <div className="input-group width-maximum" data-testid={`selectIdentityViewMode_${idForLabel}`}>
      <input id={idForLabel} className="form-control" type="text" disabled={true}
        value={renderIdentity(identity)} />
      <HoverTooltip text={tooltip}>
        <span className="input-group-btn input-group-addon cursorPointer fs15"
          data-testid={`selectIdentityEditIdentity_${idForLabel}`}
          onClick={editIdentity}>
              <Icon icon="fc-edit" type="text" />
        </span>
      </HoverTooltip>
    </div>
  );

  const renderViewOnly = (
    <div className="input-group width-maximum" data-testid={`selectIdentityViewMode_${idForLabel}`}>
      <HoverTooltip text={unauthorizedTooltip} placement="top">
        <input id={idForLabel} className="form-control" type="text" disabled={true}
          value={renderIdentity(identity)} />
      </HoverTooltip>
    </div>
  );

  return editingIdentity ? renderEditable : (sqAuthorization.isAdmin() ? renderDisplayMode : renderViewOnly);
};

export default SelectIdentity;
