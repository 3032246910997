import angular from 'angular';
import _ from 'lodash';
import { TrackService } from '@/track/track.service';
import { LoggerService } from '@/services/logger.service';

/**
 * This component tracks clicks.
 * You can provide the following attributes:
 * - track-category: this will show up as the category in Piwik
 * - track-action: this will show up as the action in Piwik
 * - track-information: this will show up as the information
 * - track-date-range: including this will add the display and investigate range to the information field.
 * - track-change: this attribute should be provided if a "change" event should be tracked
 * - track-enter: this attribute should be provided if an "enter" event should be tracked (this will also track the
 * event on blur)
 */
angular.module('Sq.Track').directive('sqTrackIt', function(
  sqTrack: TrackService,
  sqLogger: LoggerService) {
  return {
    restrict: 'A',
    priority: 1,
    link: ($scope, element, attr) => {
      element.bind('click', function() {
        if (_.isUndefined(attr.trackChange) && _.isUndefined(attr.trackEnter) && checkMinimumRequiredInformation()) {
          sqTrack.doTrack(attr.trackCategory, attr.trackAction, attr.trackInformation, attr.trackDateRange);
        }
      });

      element.bind('keyup', function(e) {
        if (!_.isUndefined(attr.trackEnter) && checkMinimumRequiredInformation()) {
          if (e.which === 13) {
            sqTrack.doTrack(attr.trackCategory, attr.trackAction, attr.trackInformation, attr.trackDateRange);
          }
        }
      });

      element.bind('change', function() {
        if (!_.isUndefined(attr.trackChange) && checkMinimumRequiredInformation()) {
          sqTrack.doTrack(attr.trackCategory, attr.trackAction, attr.trackInformation, attr.trackDateRange);
        }
      });

      element.bind('blur', function() {
        if (!_.isUndefined(attr.trackEnter) && checkMinimumRequiredInformation()) {
          sqTrack.doTrack(attr.trackCategory, attr.trackAction, attr.trackInformation, attr.trackDateRange);
        }
      });

      /**
       * Checks if a track-category and track-action attribute have been provided.
       * No events will be tracked if they are not
       *
       * @returns {Boolean} true if the attributes have been provided, false if not.
       */
      function checkMinimumRequiredInformation() {
        if (_.isUndefined(attr.trackCategory) || _.isUndefined(attr.trackAction) ||
          _.trim(attr.trackCategory) === '' || _.trim(attr.trackAction) === '') {
          sqLogger.warn('sq-track-it directive requires a track-category and a track-action attribute.');
          return false;
        }
        return true;
      }
    }
  };
});
