import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { getFormFieldProps } from '@/hybrid/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { CompositesSelector } from '@/hybrid/tools/CompositeLogicSelector.molecule';
import { CompositeLogic } from '@/hybrid/tools/compositeSearch/compositeSearch.module';

export interface CompositesSelectorIF extends ValidatingFormComponent <any> {
  component: 'CompositesSelectorFormComponent';
  logicFormulas: CompositeLogic[];
  onChange: (logic: string) => void;
  label?: string;
  className?: string;
  testId?: string;
  insideModal?: boolean;
  appendToBody?: boolean;
  disabled?: boolean;
}

export const CompositesSelectorFormComponent: React.FunctionComponent<CompositesSelectorIF> = (props) => {
  const { name, value, validation, testId, onChange, label } = props;
  const { t } = useTranslation();

  const defaultValidation = value => _.isEmpty(value);
  const formState = useForm().getState();
  return <div className="mb8 width-100percent" data-testid={testId}>
    {label && <div className="pb5">{t(label)}</div>}
    <Field name={name} validate={validation || defaultValidation}>
      {({ input, meta }) => (
        <CompositesSelector
          {...getFormFieldProps(formState, input, meta, props)}
          onChangeLogic={onChange}
          selectedLogic={value}
          insideModal={true} />
      )}
    </Field>
  </div>;
};
