import angular from 'angular';

/**
 * These constants get precompiled each time ./sq build is run and an updated buildConstants.service.js file
 * will be generated in the webserver/src/services folder. The source data for these constants can be found
 * in the variables.ini at the root of the crab project.
 */
angular.module('Sq.Services.BuildConstants', []);

/**
 * The current version string.
 */
export const SEEQ_VERSION = 'R54.1.1-v202110152006';
