import React from 'react';
import _ from 'lodash';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useEffect, useState } from 'react';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useStateWithRef } from '@/hybrid/core/useStateWithRef.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import tinycolor from 'tinycolor2';
import { TrendActions } from '@/trendData/trend.actions';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { TrackService } from '@/track/track.service';
import { HueSaturationPicker } from '@/hybrid/core/HueSaturationPicker.molecule';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { SwatchGroup } from '@/hybrid/core/SwatchGroup.molecule';
import { Overlay, Popover } from 'react-bootstrap';
import { TREND_COLORS, STANDARD_COLORS, PREVIEW_ID } from '@/trendData/trendData.module';
import { Placement } from 'react-bootstrap/Overlay';

const colorPickerBindings = bindingsDefinition({
  sqTrendActions: injected<TrendActions>(),
  sqWorkbenchActions: injected<WorkbenchActions>(),
  sqWorkbenchStore: injected<WorkbenchStore>(),
  sqTrack: injected<TrackService>(),
  color: prop<string>(),
  itemId: prop<string>(),
  colors: prop.optional<string[]>(),
  heading: prop.optional<string>(),
  notifyOnSelect: prop.optional<(itemId: string | number, color: string) => void>(),
  limitToSwatches: prop.optional<boolean>(),
  linkText: prop.optional<string>(),
  linkFunction: prop.optional<() => void>(),
  placement: prop<Placement>(),
  dataTestId: prop.optional<string>()
});

export const ColorPicker: SeeqComponent<typeof colorPickerBindings> = (props) => {
  const { sqTrendActions, sqWorkbenchActions, sqWorkbenchStore, sqTrack } = useInjectedBindings(colorPickerBindings);
  const {
    color,
    itemId,
    colors,
    heading,
    notifyOnSelect,
    limitToSwatches,
    linkText,
    linkFunction,
    placement,
    dataTestId = 'colorPickerButton'
  } = props;
  const [originalColor, setOriginalColor] = useStateWithRef(color);
  const [currentColor, setCurrentColor] = useStateWithRef(color);
  const [rgbObj, setRGBObj] = useState(tinycolor(color).toRgb());
  const [displayColor, setDisplayColor] = useState(tinycolor(color).toHexString());
  const [pickerColor, setPickerColor] = useState(tinycolor(color).toHexString());
  const [showPickerPopover, setShowPickerPopover] = useState(false);
  const [target, setTarget] = useState(null);
  const swatchColors = colors ? colors : TREND_COLORS;
  const recentColors = _.reverse(_.clone(sqWorkbenchStore.recentColors as any));
  const { t } = useTranslation();

  useEffect(() => {
    const handleClick = (e) => {
      if (e.target.getAttribute('data-itemid') === itemId) {
        return;
      }
      closeColorPicker();
    };

    // add when mounted
    document.querySelector('#mainView').addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.querySelector('#mainView')?.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  const openColorPicker = (event) => {
    if (showPickerPopover) {
      closeColorPicker();
      return;
    }
    setShowPickerPopover(true);
    setTarget(event.currentTarget);
    setOriginalColor(color);
    setCurrentColor(color);
    updateColor(currentColor.current, false, false, false);
  };

  /**
   * Closes the color picker popup and adds color to recent list
   *
   * @param {boolean} addToRecent - whether or not to add the current color to the recent list on close
   */
  const closeColorPicker = (addToRecent = true) => {
    setShowPickerPopover(false);
    if (addToRecent) {
      addRecentColor(currentColor.current);
    }
  };

  /**
   * Adds the most recent color to the workbenchStore recent colors array
   */
  const addRecentColor = (color) => {
    if (!tinycolor.equals(color, originalColor.current)) {
      sqWorkbenchActions.addRecentColor(color);
    }
  };

  /**
   * Returns the HexCode for white or black based on the selected color. If the color is a dark color it returns white
   * and otherwise black (used by the cog overlay)
   *
   * @returns {String} hexcode for white or black
   */
  const getGearColor = () => {
    return tinycolor(currentColor.current).isLight() ? '#000' : '#fff';
  };

  /**
   * This function updates the item color and sets it to the provided color.
   * Based on which color input triggers the color selection we ensure that the origin does not get modified via this
   * function as there seem to be some "autocorrection" of color that results in undesired overwrites of user input
   * otherwise.
   *
   * @param {String} color - the color to set for the item represented as a Hex String e.g. #445544
   * @param {Boolean} rgbChange - true if the color change is triggered by changing one of the RGB input fields
   * @param {Boolean} hexChange - true if the color change is triggered by changing the hex input field
   * @param {Boolean} pickerChange - true if the color change is triggered by selecting a color via the color picker
   */
  const updateColor = (color, rgbChange, hexChange, pickerChange) => {
    const colorCandidate = tinycolor(color);
    if (colorCandidate.isValid()) {
      if (!rgbChange) {
        setRGBObj(colorCandidate.toRgb());
      }

      if (!hexChange) {
        setDisplayColor(colorCandidate.toHexString());
      }

      if (!pickerChange) {
        setPickerColor(colorCandidate.toHexString());
      }

      if (color !== currentColor.current) {
        setColor(colorCandidate.toHexString());
      }
    }
  };

  /**
   * This function is triggered when the color picker color changes.
   * Calls updateColor to ensure the color is set on the item.
   */
  const setHueSaturationPickerColor = (color) => {
    sqTrack.doTrack('Trend', 'Signal color changed', 'Hue Saturation Picker');
    const newColor = tinycolor(color.hex).toHexString();
    setPickerColor(newColor);
    updateColor(newColor, false, false, true);
  };

  /**
   * This function is triggered when the RGB entry fields are changed.
   * Calls updateColor to ensure the color is set on the item.
   */
  const setRGBColor = (value, rgbComponent) => {
    sqTrack.doTrack('Trend', 'Signal color changed', `RGB (${rgbComponent})`);
    const rgbTemp = _.clone(rgbObj);
    rgbTemp[rgbComponent.toLowerCase()] = value;
    setRGBObj(rgbTemp);
    updateColor(rgbTemp, true, false, false);
  };

  /**
   * This function is triggered when the hex color field is changed.
   * Calls updateColor to ensure the color is set on the item.
   */
  const setHexColor = (color) => {
    sqTrack.doTrack('Trend', 'Signal color changed', 'input hex');
    setDisplayColor(color);
    updateColor(color, false, true, false);
  };

  /**
   * Sets the item's color to the specified value. If an $event is provided the popup is closed.
   * Note: Setting this to the preview by default is fine, even for a tool that has not created a preview item,
   * as that will be checked prior to setting it in the store.
   *
   * If a function is passed in the notify-on-select attribute then that function is called. If no function is
   * provided, then sqTrendActions.setItemColor is called.
   * Note: all functions passed in via notify-on-select need to have uniform parameters of an itemId and a color.
   *
   * @param {String} color - Color hex code (e.g. #CCCCCC)
   * @param {Object} [event] - The click event
   */
  const setColor = (color, event?) => {
    setCurrentColor(color);
    if (event) {
      closeColorPicker();
    }

    // PREVIEW_ID is used to set the color of items that have not yet been created
    const id = _.isUndefined(itemId) ? PREVIEW_ID : itemId;
    notifyOnSelect ? notifyOnSelect(id, color) : sqTrendActions.setItemColor(id, color);
  };

  const callLinkFunction = () => {
    linkFunction();
    closeColorPicker(false);
  };

  return (
    <div>
      <div className="colorPickerSwatch" onClick={openColorPicker} data-testid={dataTestId} data-itemid={itemId}
        style={{ backgroundColor: currentColor.current }} id="specColorPickerButton" />
      <Overlay target={target} show={showPickerPopover} placement={placement} transition={false}
        popperConfig={{ modifiers: { preventOverflow: { boundariesElement: 'window' } } as any }}>
        <Popover id="colorPickerPopover" data-testid="colorPickerPopover">
          <div id="colorPickerContainer" className="p10">
            {/*Swatches only*/}
            {limitToSwatches &&
            <SwatchGroup colors={colors} onSelectColor={setColor} heading={heading}
              limitToSwatches={limitToSwatches} testId='swatches' />
            }
            {/*Full color picker*/}
            {!limitToSwatches &&
            <div>
              <div className="picker-close" onClick={() => closeColorPicker()} data-testid="colorPickerCloseButton">
                <span className="fa fa-close cursorPointer" />
              </div>
              {/*Picker, Hex and RGB Inputs*/}
              <div className="flexRowContainer">
                <span className="mb3">{t('COLOR_PICKER.CURRENT_COLOR')}</span>
                <div className="flexColumnContainer flexAlignCenter">
                  {/*Hue Saturation Picker*/}
                  <HoverTooltip text="COLOR_PICKER.PICKER">
                    <div>
                      <div className="flexRowContainer cursorPointer large-color-swatch">
                        <div className="colorswatch-overlay">
                          <i className="fa fa-cog fa-lg" style={{ color: getGearColor() }} data-testid="gear" />
                        </div>
                        <HueSaturationPicker onChange={setHueSaturationPickerColor} color={pickerColor} />
                      </div>
                    </div>
                  </HoverTooltip>
                  {/*Hex and RGB Inputs*/}
                  <div className="flexRowContainer pl8">
                    <div className="flexColumnContainer flexAlignCenter">
                      Hex
                      <input id="hexInput" autoComplete="off" type="text" className="form-control input-xs mb5 ml5 mr8"
                        value={displayColor} onChange={e => setHexColor(e.target.value)} data-testid="hexInput" />
                    </div>
                    <div className="flexColumnContainer flexAlignCenter">
                      R:
                      <input autoComplete="off" type="text" className="form-control color-rgb-input input-xs ml5 mr8"
                        value={rgbObj.r} onChange={e => setRGBColor(e.target.value, 'R')} data-testid="rInput" />
                      G:
                      <input autoComplete="off" type="text" className="form-control color-rgb-input input-xs ml5 mr8"
                        value={rgbObj.g} onChange={e => setRGBColor(e.target.value, 'G')} data-testid="gInput" />
                      B:
                      <input autoComplete="off" type="text" className="form-control color-rgb-input input-xs ml5 mr8"
                        value={rgbObj.b} onChange={e => setRGBColor(e.target.value, 'B')} data-testid="bInput" />
                    </div>
                  </div>
                </div>
              </div>
              {/*Swatches*/}
              <SwatchGroup colors={swatchColors} onSelectColor={setColor} heading='COLOR_PICKER.SEEQ_COLORS'
                limitToSwatches={limitToSwatches} testId='seeqColors' />
              <SwatchGroup colors={STANDARD_COLORS} onSelectColor={setColor} heading='COLOR_PICKER.STANDARD_COLORS'
                limitToSwatches={limitToSwatches} testId='standardColors' />
              <SwatchGroup colors={recentColors} onSelectColor={setColor} heading='COLOR_PICKER.RECENT_COLORS'
                limitToSwatches={limitToSwatches} testId='recentColors' />
              {linkText &&
              <div className="pt5">
                <span className="cursorPointer text-underline" onClick={callLinkFunction} data-testid='linkFunction'>
                  {t(linkText)}
                </span>
              </div>}
            </div>}
          </div>
        </Popover>
      </Overlay>
    </div>
  );
};

export const sqColorPicker = angularComponent(colorPickerBindings, ColorPicker);
