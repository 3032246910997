/* tslint:disable */
import { CleanupOnDestroyOnce } from 'plugin/pluginApiCleanupLogic';
import { DurationStore, RangeExport } from '@/trendData/duration.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { UtilitiesService } from '@/services/utilities.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { PUSH_IGNORE } from '@/services/stateSynchronizer.service';
import { TrendActions } from '@/trendData/trend.actions';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { FormulaToolActions } from '@/hybrid/tools/formula/formulaTool.actions';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { ITEM_TYPES, TREND_PANELS, TREND_STORES } from '@/trendData/trendData.module';
import { PluginIdentifier } from './pluginHost.module';
import { PluginActions } from './plugin.actions';
import { PluginStore } from './plugin.store';
import { API_VERSION } from './pluginApiVersion';
import { TrendScalarStore } from '@/trendData/trendScalar.store';
import { TrendMetricStore } from '@/trendData/trendMetric.store';
import { TrendTableStore } from '@/trendData/trendTable.store';
import { FormulasApi, MetricsApi } from '@/sdk';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { LoggerService } from '@/services/logger.service';
import { DurationActions } from '@/trendData/duration.actions';

import { isEqual } from 'lodash';
import { getCleanupLogic } from 'plugin/pluginApiCleanupLogic';

interface SeeqErrorData {
  statusMessage: string;
  errorType: string;
  errorCategory: string;
  inaccessible: string[];
}

// Error object returned from Seeq Workbench when rejecting a promise
interface SeeqError {
  data: SeeqErrorData;
  status: number;
  xhrStatus: string;
}

interface DateRange {
  start: number; // as unix timestamp in milliseconds
  end: number; // as unix timestamp in milliseconds
  duration: string; // as shown in the workbench
}

interface Asset {
  id: string;
  name: string;
  formattedName: string;
}

interface TrendItem {
  id: string;
  name: string;
  dataStatus?: string;
  lastFetchRequest?: string;
  selected: boolean;
  color: string;
  assets: Asset[];
}

interface TrendSignal extends TrendItem {
  isStringSignal: boolean;
  autoScale: boolean;
  axis: string;
  axisMin: number;
  axisMax: number;
  valueUnitOfMeasure: string;
}

interface TrendScalar extends TrendItem {
  isStringScalar: boolean;
  autoScale: boolean;
  axis: string;
  axisMin: number;
  axisMax: number;
  valueUnitOfMeasure: string;
}

interface TrendCondition extends TrendItem {
}

interface TrendMetric extends TrendItem {
  autoScale: boolean;
  axis: string;
  axisMin: number;
  axisMax: number;
}

interface TrendTable extends TrendItem {
  stack: boolean;
}

interface TrendSample {
  key: number;
  value: number | string;
  isUncertain: boolean;
}

interface DataStatusResults {
  id: string;
  samples?: TrendSample[];
  value?: number | string;
  timingInformaton?: string;
  meterInformation?: string;
  valueUnitOfMeasure?: string;
  warningCount?: number;
  warningLogs?: object;
}

type LogSeverity = 'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'FATAL';

type PluginState = object;

type FormulaArgs = object;

interface CapsuleProperty {
  /** name of the property (first argument of `setProperty`) */
  name: string;
  /** value of the property in a scalar type */
  value: number | string | boolean;
  /** an optional unit, this is omitted if there is no units or the value is a boolean */
  unitOfMeasure?: string;
}

/** A capsule used in a `condition` formula */
interface TrendCapsule {
  /** An id may be encoded as a property (CAPSULE_SOURCE_ID_PROPERTY_NAME) in the capsule */
  id?: string;
  startTime: number;
  endTime: number;
  properties: CapsuleProperty[];
  isUncertain: boolean;
}

interface RunFormulaArgs {
  start?: string;
  end?: string;
  formula?: string;
  _function?: string;
  parameters?: {
    [key: string]: string;
  };
  fragments?: {
    [key: string]: string;
  };
  offset?: number;
  limit?: number;
  cancellationGroup?: string;
}

interface FormulaParameter {
  identifier: string;
  item: {
    id: string;
    name: string;
  };
}

/** A YValue element used in setPointerValues yValues Array */
interface YValue {
  id: string;
  pointValue: string | number;
}

interface PluginResponse<T> {
  data: T;
  status: number;
  info: PluginResponseInfo;
}

interface PluginResponseInfo {
  timingInformation: string;
  meterInformation: string;
}

type DetailsPaneColumnKey = 'axis' | 'autoScale' | 'axisMin' | 'axisMax';

/**
 * The following section contains API types extracted from our Seeq typescript SDK
 */
interface FormulaRunOutput {
  /**
   * Capsules from the formula result
   */
  'capsules'?: CapsulesOutput;
  /**
   * Metadata describing the compiled formula's result
   */
  'metadata'?: {
    [key: string]: string;
  };
  /**
   * Regression output from the formula result. Note that the `table` will also contain values.
   */
  'regressionOutput'?: RegressionOutput;
  /**
   * The data type of the compiled formula's result
   */
  'returnType'?: string;
  /**
   * Samples from the formula result
   */
  'samples'?: SeriesSamplesOutput;
  /**
   * Scalar from the formula result
   */
  'scalar'?: ScalarValueOutput;
  /**
   * A plain language status message with information about any issues that may have been encountered during an
   * operation. Null if the status message has not been set.
   */
  'statusMessage'?: string;
  /**
   * Table from the formula result
   */
  'table'?: GenericTableOutput;
  /**
   * Contains upgrade information if the formula contains legacy syntax that was automatically updated
   */
  'upgradeDetails'?: FormulaUpgradeOutput;
  /**
   * Errors (if any) from the formula
   */
  'errors'?: Array<FormulaCompilerErrorOutput>;
  /**
   * The total number of warnings that have occurred
   */
  'warningCount'?: number;
  /**
   * The Formula warning logs, which includes the text, line number, and column number where the warning occurred in
   * addition to the warning details
   */
  'warningLogs'?: Array<FormulaLog>;
}

interface FormulaCompilerErrorOutput {
  /**
   * The column of the formula that resulted in an error
   */
  'column'?: number;
  /**
   * The category of the formula error, i.e. when it was encountered
   */
  'errorCategory'?: string;
  /**
   * The type of formula error that occurred
   */
  'errorType'?: string;
  /**
   * The function where the error occurred
   */
  'functionId'?: string;
  /**
   * The line of the formula that resulted in an error
   */
  'line'?: number;
  /**
   * An error message for the compiled formula
   */
  'message'?: string;
  /**
   * The itemId that is the cause of the error
   */
  'itemId'?: string;
}

interface CapsulesOutput {
  /**
   * The list of capsules
   */
  'capsules': Array<Capsule>;
  'graphLimit'?: number;
  /**
   * The unit of measure for the capsule starts and ends. If left empty, input is assumed to be in ISO8601 format.
   */
  'keyUnitOfMeasure'?: string;
  /**
   * The pagination limit, the total number of collection items that will be returned in this page of results
   */
  'limit'?: number;
  /**
   * The href of the next set of paginated results
   */
  'next'?: string;
  /**
   * The pagination offset, the index of the first collection item that will be returned in this page of results
   */
  'offset'?: number;
  /**
   * The href of the previous set of paginated results
   */
  'prev'?: string;
  /**
   * A plain language status message with information about any issues that may have been encountered during an
   * operation. Null if the status message has not been set.
   */
  'statusMessage'?: string;
  /**
   * The total number of warnings that have occurred
   */
  'warningCount'?: number;
  /**
   * The Formula warning logs, which includes the text, line number, and column number where the warning occurred in
   * addition to the warning details
   */
  'warningLogs'?: Array<FormulaLog>;
}

interface Capsule {
  /**
   * The point at which the capsule becomes uncertain. For a time, an ISO 8601 date string
   * (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm), or a whole number of nanoseconds since the unix epoch (if the units are
   * nanoseconds). For a numeric (non-time), a double-precision number.
   */
  'cursorKey'?: any;
  /**
   * The end of the capsule. For a time, an ISO 8601 date string (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm), or a whole
   * number of nanoseconds since the unix epoch (if the units are nanoseconds). For a numeric (non-time), a
   * double-precision number.
   */
  'end'?: any;
  /**
   * The id of the capsule
   */
  'id': string;
  /**
   * True if this capsule is fully or partially uncertain
   */
  'isUncertain'?: boolean;
  /**
   * A list of the capsule's properties
   */
  'properties'?: Array<ScalarProperty>;
  /**
   * The start of the capsule. For a time, an ISO 8601 date string (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm), or a whole
   * number of nanoseconds since the unix epoch (if the units are nanoseconds). For a numeric (non-time), a
   * double-precision number.
   */
  'start': any;
}

interface ScalarProperty {
  /**
   * Human readable name.  Null or whitespace names are not permitted
   */
  'name': string;
  /**
   * The unit of measure to apply to this property's value. If no unit of measure is set and the value is numeric, it
   * is assumed to be unitless
   */
  'unitOfMeasure'?: string;
  /**
   * The value to assign to this property. If the value is surrounded by quotes, it is interpreted as a string and no
   * units are set
   */
  'value': any;
}

interface ItemPreviewList {
  'graphLimit'?: number;
  /**
   * The list of items requested
   */
  'items'?: Array<ItemPreview>;
  /**
   * The pagination limit, the total number of collection items that will be returned in this page of results
   */
  'limit'?: number;
  /**
   * The href of the next set of paginated results
   */
  'next'?: string;
  /**
   * The pagination offset, the index of the first collection item that will be returned in this page of results
   */
  'offset'?: number;
  /**
   * The href of the previous set of paginated results
   */
  'prev'?: string;
  /**
   * A plain language status message with information about any issues that may have been encountered during an
   * operation. Null if the status message has not been set.
   */
  'statusMessage'?: string;
  /**
   * The total number of items
   */
  'totalResults': number;
}

interface ItemPreview {
  /**
   * The ID that can be used to interact with the item
   */
  'id': string;
  /**
   * Whether item is archived
   */
  'isArchived'?: boolean;
  /**
   * Whether item is redacted
   */
  'isRedacted'?: boolean;
  /**
   * The human readable name
   */
  'name': string;
  /**
   * The type of the item
   */
  'type': string;
  /**
   * The item's translation key, if any
   */
  'translationKey'?: string;
}

interface RegressionOutput {
  /**
   * The measure of how close the data is to the regression line, adjusted for the number of input signals and samples
   */
  'adjustedRSquared': number;
  /**
   * The standard error for the sum squares
   */
  'errorSumSquares': number;
  /**
   * The constant offset to add. 0 if forceThroughZero was true. This is the intercept for the output signal rather
   * than the individual coefficients.
   */
  'intercept': number;
  /**
   * The standard error for the intercept
   */
  'interceptStandardError': number;
  /**
   * True if this regression is uncertain
   */
  'isUncertain': boolean;
  /**
   * The measure of how well the model matches the target
   */
  'regressionSumSquares': number;
  'rsquared'?: number;
  /**
   * The value which the regression method suggests for ignoring coefficients
   */
  'suggestedPValueCutoff': number;
}

interface SeriesSamplesOutput {
  'graphLimit'?: number;
  /**
   * The unit of measure for the series keys
   */
  'keyUnitOfMeasure'?: string;
  /**
   * The pagination limit, the total number of collection items that will be returned in this page of results
   */
  'limit'?: number;
  /**
   * The href of the next set of paginated results
   */
  'next'?: string;
  /**
   * The pagination offset, the index of the first collection item that will be returned in this page of results
   */
  'offset'?: number;
  /**
   * The href of the previous set of paginated results
   */
  'prev'?: string;
  /**
   * The list of samples
   */
  'samples': Array<SeriesSample>;
  /**
   * A plain language status message with information about any issues that may have been encountered during an
   * operation. Null if the status message has not been set.
   */
  'statusMessage'?: string;
  /**
   * The unit of measure for the series values
   */
  'valueUnitOfMeasure'?: string;
  /**
   * The total number of warnings that have occurred
   */
  'warningCount'?: number;
  /**
   * The Formula warning logs, which includes the text, line number, and column number where the warning occurred in
   * addition to the warning details
   */
  'warningLogs'?: Array<FormulaLog>;
}

interface SeriesSample {
  /**
   * True if this sample is uncertain
   */
  'isUncertain'?: boolean;
  /**
   * The key of the sample. For a time series, an ISO 8601 date string(YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm). For a
   * numeric (non-time) series, a double-precision number.
   */
  'key': any;
  /**
   * The value of the sample
   */
  'value': any;
}

interface ScalarValueOutput {
  /**
   * True if this scalar is uncertain
   */
  'isUncertain'?: boolean;
  /**
   * The unit of measure of the scalar
   */
  'uom': string;
  /**
   * The value of the scalar
   */
  'value': any;
}

interface ScalarEvaluateOutput {
  /**
   * True if this scalar is uncertain
   */
  'isUncertain'?: boolean;
  /**
   * Metadata describing the compiled formula's result
   */
  'metadata'?: {
    [key: string]: string;
  };
  /**
   * The data type of the compiled formula's result
   */
  'returnType'?: string;
  /**
   * A plain language status message with information about any issues that may have been encountered during an
   * operation. Null if the status message has not been set.
   */
  'statusMessage'?: string;
  /**
   * The unit of measure of the scalar
   */
  'uom': string;
  /**
   * Contains upgrade information if the formula contains legacy syntax that was automatically updated
   */
  'upgradeDetails'?: FormulaUpgradeOutput;
  /**
   * The value of the scalar
   */
  'value': any;
  /**
   * Violations (if any) from the formula
   */
  'errors'?: Array<FormulaCompilerErrorOutput>;
  /**
   * The total number of warnings that have occurred
   */
  'warningCount'?: number;
  /**
   * The Formula warning logs, which includes the text, line number, and column number where the warning occurred in
   * addition to the warning details
   */
  'warningLogs'?: Array<FormulaLog>;
}

interface GenericTableOutput {
  /**
   * The list of data rows, each row being a list of cell contents.
   */
  'data': Array<Array<any>>;
  /**
   * The list of headers.
   */
  'headers': Array<TableColumnOutput>;
}

interface TableColumnOutput {
  /**
   * The name of the column
   */
  'name': string;
  /**
   * The type of the column. Valid values include 'string', 'number', and 'date'. Booleans are reported as 'number'
   */
  'type': string;
  /**
   * The units of the column. Only provided if type is 'number'
   */
  'units'?: string;
}

interface FormulaUpgradeOutput {
  /**
   * The resulting changed formula
   */
  'afterFormula'?: string;
  /**
   * The original input formula
   */
  'beforeFormula'?: string;
  /**
   * Details about the specific changes
   */
  'changes'?: Array<FormulaUpgradeChange>;
}

interface FormulaUpgradeChange {
  /**
   * Description of the change
   */
  'change'?: string;
  /**
   * A link to the Knowledge Base for more explanation of why this was applied
   */
  'moreDetailsUrl'?: string;
}

interface FormulaLog {
  /**
   * The detailed Formula log entries which occurred at this token
   */
  'formulaLogEntries': {
    [key: string]: FormulaLogEntry;
  };
  /**
   * The token where the event took place in the Formula
   */
  'formulaToken': FormulaToken;
}

interface FormulaLogEntry {
  'logDetails'?: Array<FormulaLogEntryDetails>;
  'logTypeCount'?: number;
}

interface FormulaLogEntryDetails {
  'context'?: string;
  'message'?: string;
}

interface FormulaToken {
  'column'?: number;
  'line'?: number;
  'text'?: string;
}

interface ThresholdMetricOutputV1 {
  /**
   * Additional properties of the item
   */
  'additionalProperties'?: Array<ScalarPropertyV1>;
  /**
   * The ID of the aggregation condition representing metric value information
   */
  'aggregationConditionId'?: string;
  /**
   * Aggregation formula that aggregates the measured item
   */
  'aggregationFunction'?: string;
  /**
   * The condition that, if present, will be used to aggregate the measured item
   */
  'boundingCondition'?: ItemPreviewWithAssetsV1;
  /**
   * The maximum capsule duration that is applied to the bounding condition if it does not have one
   */
  'boundingConditionMaximumDuration'?: ScalarValueOutputV1;
  /**
   * The data ID of this asset. Note: This is not the Seeq ID, but the unique identifier that the remote datasource
   * uses.
   */
  'dataId'?: string;
  /**
   * The datasource class, which is the type of system holding the item, such as OSIsoft PI
   */
  'datasourceClass'?: string;
  /**
   * The datasource identifier, which is how the datasource holding this item identifies itself
   */
  'datasourceId'?: string;
  /**
   * Clarifying information or other plain language description of this item
   */
  'description'?: string;
  /**
   * A signal or formula function that evaluates to a signal that can be used to visualize the metric
   */
  'displayItem': ItemPreviewV1;
  /**
   * The duration over which to calculate a moving aggregation
   */
  'duration'?: ScalarValueOutputV1;
  /**
   * The permissions the current user has to the item.
   */
  'effectivePermissions'?: PermissionsV1;
  /**
   * The ID that can be used to interact with the item
   */
  'id': string;
  /**
   * Whether item is archived
   */
  'isArchived'?: boolean;
  /**
   * Whether item is redacted
   */
  'isRedacted'?: boolean;
  /**
   * The input Signal or Condition to measure
   */
  'measuredItem': ItemPreviewWithAssetsV1;
  /**
   * The human readable name
   */
  'name': string;
  /**
   * Either the custom-set neutral color for this metric or the color of the neutral Priority
   */
  'neutralColor'?: string;
  /**
   * The format string used for numbers associated with this signal.
   */
  'numberFormat'?: string;
  /**
   * The period at which to sample when creating the moving aggregation
   */
  'period'?: ScalarValueOutputV1;
  /**
   * The process type of threshold metric. Will be Continuous if duration and period are specified, Condition if
   * boundingCondition is specified, and otherwise Simple.
   */
  'processType': ProcessTypeEnum;
  /**
   * The ID of the workbook to which this item is scoped or null if it is in the global scope.
   */
  'scopedTo'?: string;
  /**
   * A plain language status message with information about any issues that may have been encountered during an
   * operation
   */
  'statusMessage'?: string;
  /**
   * The list of thresholds that are scalars, signals, or conditions along with the associated priority. These
   * thresholds are those that were used as inputs and which are used to generate the condition thresholds
   */
  'thresholds'?: Array<ThresholdOutputV1>;
  /**
   * The type of the item
   */
  'type': string;
  /**
   * The unit of measure of the metric
   */
  'valueUnitOfMeasure'?: string;
  /**
   * The item's translation key, if any
   */
  'translationKey'?: string;
}

interface ScalarPropertyV1 {
  /**
   * Human readable name.  Null or whitespace names are not permitted
   */
  'name': string;
  /**
   * The unit of measure to apply to this property's value. If no unit of measure is set and the value is numeric, it
   * is assumed to be unitless
   */
  'unitOfMeasure'?: string;
  /**
   * The value to assign to this property. If the value is surrounded by quotes, it is interpreted as a string and no
   * units are set
   */
  'value': any;
}

interface ItemPreviewWithAssetsV1 {
  /**
   * The list of ancestors in the asset tree, ordered with the root ancestor first, if the item is in an asset tree. If
   * an item is in more than one asset tree an arbitrary one will be chosen.
   */
  'ancestors'?: Array<ItemPreviewV1>;
  /**
   * A boolean indicating whether or not child items exist for this item in the asset tree; the value will be true even
   * if the child items are archived unless the tree for this item is deleted.
   */
  'hasChildren'?: boolean;
  /**
   * The ID that can be used to interact with the item
   */
  'id': string;
  /**
   * Whether item is archived
   */
  'isArchived'?: boolean;
  /**
   * Whether item is redacted
   */
  'isRedacted'?: boolean;
  /**
   * The human readable name
   */
  'name': string;
  /**
   * The type of the item
   */
  'type': string;
  /**
   * The item's translation key, if any
   */
  'translationKey'?: string;
}

interface ScalarValueOutputV1 {
  /**
   * True if this scalar is uncertain
   */
  'isUncertain'?: boolean;
  /**
   * The unit of measure of the scalar
   */
  'uom': string;
  /**
   * The value of the scalar
   */
  'value': any;
}

interface ThresholdOutputV1 {
  'isGenerated'?: boolean;
  /**
   * The threshold item
   */
  'item'?: ItemPreviewV1;
  /**
   * The priority associated with the threshold. If a custom color has been specified for this threshold it will be set
   * as the color
   */
  'priority'?: PriorityV1;
  /**
   * The scalar value, only if the item is a scalar
   */
  'value'?: ScalarValueOutputV1;
}

interface ItemPreviewV1 {
  /**
   * The ID that can be used to interact with the item
   */
  'id': string;
  /**
   * Whether item is archived
   */
  'isArchived'?: boolean;
  /**
   * Whether item is redacted
   */
  'isRedacted'?: boolean;
  /**
   * The human readable name
   */
  'name': string;
  /**
   * The type of the item
   */
  'type': string;
  /**
   * The item's translation key, if any
   */
  'translationKey'?: string;
}

interface PermissionsV1 {
  'manage'?: boolean;
  'read'?: boolean;
  'write'?: boolean;
}

enum ProcessTypeEnum {
  // This is required because the typescript SDK doesn't properly handle enums.
  // @ts-ignore TS1066 (In ambient enum declarations member initializer must be constant expression)
  Simple = 'Simple' as any,
  // @ts-ignore TS1066
  Condition = 'Condition' as any,
  // @ts-ignore TS1066
  Continuous = 'Continuous' as any
}

interface FormulaLogV1 {
  /**
   * The detailed Formula log entries which occurred at this token
   */
  'formulaLogEntries': {
    [key: string]: FormulaLogEntry;
  };
  /**
   * The token where the event took place in the Formula
   */
  'formulaToken': FormulaToken;
}

interface PriorityV1 {
  /**
   * A hex code (including pound sign) representing the color assigned to this priority
   */
  'color': string;
  /**
   * An integer representing the priority level. 0 is used for neutral, positive numbers are used for high thresholds
   * and negative numbers for low thresholds
   */
  'level': number;
  /**
   * The name of this priority
   */
  'name': string;
}

const exportSignal = (s: any): TrendSignal => {
  const { id, name, dataStatus, lastFetchRequest, selected, color, assets, isStringSeries: isStringSignal, axisAutoScale: autoScale, axisAlign: axis, yAxisMin: axisMin, yAxisMax: axisMax, valueUnitOfMeasure } = s;
  return {
    id,
    name,
    dataStatus,
    lastFetchRequest,
    selected,
    color,
    assets,
    isStringSignal,
    autoScale,
    axis,
    axisMin,
    axisMax,
    valueUnitOfMeasure
  };
};

const exportScalar = (s: any): TrendScalar => {
  const { id, name, dataStatus, lastFetchRequest, selected, color, assets, isStringSeries: isStringScalar, axisAutoScale: autoScale, axisAlign: axis, yAxisMin: axisMin, yAxisMax: axisMax, valueUnitOfMeasure } = s;
  return {
    id,
    name,
    dataStatus,
    lastFetchRequest,
    selected,
    color,
    assets,
    isStringScalar,
    autoScale,
    axis,
    axisMin,
    axisMax,
    valueUnitOfMeasure
  };
};

const exportCondition = (s: any): TrendCondition => {
  const { id, name, dataStatus, lastFetchRequest, selected, color, assets } = s;
  return {
    id,
    name,
    dataStatus,
    lastFetchRequest,
    selected,
    color,
    assets
  };
};

const exportMetric = (s: any): TrendMetric => {
  const { id, name, dataStatus, lastFetchRequest, selected, color, assets, axisAutoScale: autoScale, axisAlign: axis, yAxisMin: axisMin, yAxisMax: axisMax } = s;
  return {
    id,
    name,
    dataStatus,
    lastFetchRequest,
    selected,
    color,
    assets,
    autoScale,
    axis,
    axisMin,
    axisMax
  };
};

const exportTable = (s: any): TrendTable => {
  const { id, name, dataStatus, lastFetchRequest, selected, color, assets, stack } = s;
  return {
    id,
    name,
    dataStatus,
    lastFetchRequest,
    selected,
    color,
    assets,
    stack
  };
};

function mapDetailsPaneColumnKey(key: DetailsPaneColumnKey) {
  switch (key) {
    case 'autoScale':
      return 'axisAutoScale';
    case 'axis':
      return 'axisAlign';
    case 'axisMin':
      return 'yAxisMin';
    case 'axisMax':
      return 'yAxisMax';
    default:
      throw new Error('Invalid column key: ' + key);
  }
}

interface DataExport {
  dataExport: string;
  path: string;
  functionName: string;

  transform(data: any): any;
}

export function generatePluginApi(
  pluginIdentifier: PluginIdentifier,
  $injector: ng.auto.IInjectorService,
  messagePort: MessagePort,
  onInitialized?: () => void) {

  let initialized = false;
  // can replace this with 'console' to get debug logs to console
  const debug = { log(...x) { }, warn(...x) { } };

  const sendMessage = (type: string, payload?: any, nonce?: string, transfer?: ArrayBuffer[]) => {
    if (initialized) {
      messagePort.postMessage({ type, payload, nonce }, transfer);
      debug.log('message sent to plugin', { type, payload, nonce });
    } else {
      debug.warn('trying to send message on a not initialized connection');
    }
  };

  const sqDateTime = $injector.get<DateTimeService>('sqDateTime');
  const sqDurationActions = $injector.get<DurationActions>('sqDurationActions');
  const sqDurationStore = $injector.get<DurationStore>('sqDurationStore');
  const sqFormulaToolActions = $injector.get<FormulaToolActions>('sqFormulaToolActions');
  const sqFormulasApi = $injector.get<FormulasApi>('sqFormulasApi');
  const sqLogger = $injector.get<LoggerService>('sqLogger');
  const sqMetricsApi = $injector.get<MetricsApi>('sqMetricsApi');
  const sqPluginActions = $injector.get<PluginActions>('sqPluginActions');
  const sqPluginStore = $injector.get<PluginStore>('sqPluginStore');
  const sqTrendActions = $injector.get<TrendActions>('sqTrendActions');
  const sqTrendCapsuleSetStore = $injector.get<TrendCapsuleSetStore>('sqTrendCapsuleSetStore');
  const sqTrendCapsuleStore = $injector.get<TrendCapsuleStore>('sqTrendCapsuleStore');
  const sqTrendDataHelper = $injector.get<TrendDataHelperService>('sqTrendDataHelper');
  const sqTrendMetricStore = $injector.get<TrendMetricStore>('sqTrendMetricStore');
  const sqTrendScalarStore = $injector.get<TrendScalarStore>('sqTrendScalarStore');
  const sqTrendSeriesStore = $injector.get<TrendSeriesStore>('sqTrendSeriesStore');
  const sqTrendTableStore = $injector.get<TrendTableStore>('sqTrendTableStore');
  const sqUtilities = $injector.get<UtilitiesService>('sqUtilities');
  const sqWorksheetActions = $injector.get<WorksheetActions>('sqWorksheetActions');

  const flux = $injector.get<ng.IFluxService>('flux');

  const cleanupLogic = getCleanupLogic();

  const storeListenersUnsubscribes = {};

  function destroy() {
    Object.keys(storeListenersUnsubscribes).forEach(storeId => storeListenersUnsubscribes[storeId]());
    cleanupLogic.destroy();
  }

  const unsubscribes = {};

  function unsubscribe(callName: string) {
    unsubscribes[callName]();
  }

  const API = { clientConfig: { properties: [], functions: [] }, destroy, unsubscribe };

  API.clientConfig.properties.push({ path: 'ROOT', name: 'version', value: API_VERSION });
  API.clientConfig.properties.push({
    path: 'ROOT', name: 'isPresentationWorkbookMode', value: (function(): boolean {
      return sqUtilities.isPresentationWorkbookMode;
    })()
  });

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setDisplayRange' });
  API.clientConfig['ROOT -> setDisplayRange'] = { type: 'invokeVoid' };
  API['ROOT -> setDisplayRange'] = function(start: number, end: number): void {
    sqDurationActions.displayRange.updateTimes(start, end);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setInvestigateRange' });
  API.clientConfig['ROOT -> setInvestigateRange'] = { type: 'invokeVoid' };
  API['ROOT -> setInvestigateRange'] = function(start: number, end: number): void {
    sqDurationActions.investigateRange.updateTimes(start, end);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'selectItem' });
  API.clientConfig['ROOT -> selectItem'] = { type: 'invokeVoid' };
  API['ROOT -> selectItem'] = function(id: string, selected: boolean) {
    const item = sqTrendDataHelper.findItemIn(TREND_STORES, id);
    if (item) {
      sqTrendActions.setItemSelected(item, selected);
    }
    else {
      sqLogger.error(`An item with ID "${id}" was not found in the details pane`, pluginIdentifier);
    }
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'log' });
  API.clientConfig['ROOT -> log'] = { type: 'invokeVoid' };
  API['ROOT -> log'] = function(severity: LogSeverity, message: string): void {
    const logger: {
      [message in LogSeverity]: (message: string, category?: string) => void;
    } = {
      DEBUG: sqLogger.debug,
      ERROR: sqLogger.error,
      FATAL: sqLogger.fatal,
      INFO: sqLogger.info,
      TRACE: sqLogger.trace,
      WARN: sqLogger.warn
    };
    logger[severity](message, pluginIdentifier);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setPluginState' });
  API.clientConfig['ROOT -> setPluginState'] = { type: 'invokeVoid' };
  API['ROOT -> setPluginState'] = function(pluginState: PluginState): void {
    sqPluginActions.setPluginState(pluginIdentifier, pluginState);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'runFormula' });
  API.clientConfig['ROOT -> runFormula'] = { type: 'invokeAsync' };
  API['ROOT -> runFormula'] = function({ start, end, formula, _function, parameters, fragments, offset, limit, cancellationGroup }: RunFormulaArgs): Promise<PluginResponse<FormulaRunOutput>> {
    const parametersEncoded = sqUtilities.encodeParameters(parameters);
    const fragmentsEncoded = sqUtilities.encodeParameters(fragments);
    return sqFormulasApi.runFormula({
      start,
      end,
      formula,
      _function,
      parameters: parametersEncoded,
      fragments: fragmentsEncoded,
      offset,
      limit
    }, {
      cancellationGroup
    })
      .then((result) => {
        const { data, status } = result;
        const { 'server-timing': timingInformation, 'server-meters': meterInformation } = result.headers();
        return {
          data,
          status,
          info: { timingInformation, meterInformation }
        };
      }) as Promise<PluginResponse<FormulaRunOutput>>;
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'fetchMetric' });
  API.clientConfig['ROOT -> fetchMetric'] = { type: 'invokeAsync' };
  API['ROOT -> fetchMetric'] = function(id: string): Promise<PluginResponse<ThresholdMetricOutputV1>> {
    return sqMetricsApi.getMetric({ id })
      .then((result) => {
        const { data, status } = result;
        const { 'server-timing': timingInformation, 'server-meters': meterInformation } = result.headers();
        return {
          data,
          status,
          info: { timingInformation, meterInformation }
        };
      }) as Promise<PluginResponse<ThresholdMetricOutputV1>>;
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setTrendDataStatusLoading' });
  API.clientConfig['ROOT -> setTrendDataStatusLoading'] = { type: 'invokeVoid' };
  API['ROOT -> setTrendDataStatusLoading'] = function(id: string) {
    flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id }, PUSH_IGNORE);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setTrendDataStatusSuccess' });
  API.clientConfig['ROOT -> setTrendDataStatusSuccess'] = { type: 'invokeVoid' };
  API['ROOT -> setTrendDataStatusSuccess'] = function(results: DataStatusResults) {
    const trendedItem = sqTrendDataHelper.findItemIn(TREND_STORES, results.id);
    if (trendedItem) {
      switch (trendedItem.itemType) {
        case ITEM_TYPES.SERIES:
          flux.dispatch('TREND_SERIES_RESULTS_SUCCESS', results, PUSH_IGNORE);
          break;
        case ITEM_TYPES.SCALAR:
          flux.dispatch('TREND_SCALAR_RESULTS_SUCCESS', results, PUSH_IGNORE);
          break;
        default:
          flux.dispatch('TREND_SET_DATA_STATUS_PRESENT', results, PUSH_IGNORE);
      }
    }
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'pluginRenderComplete' });
  API.clientConfig['ROOT -> pluginRenderComplete'] = { type: 'invokeVoid' };
  API['ROOT -> pluginRenderComplete'] = function(): void {
    sqPluginActions.setDisplayPaneRenderComplete(true);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setPointerValues' });
  API.clientConfig['ROOT -> setPointerValues'] = { type: 'invokeVoid' };
  API['ROOT -> setPointerValues'] = (function() {
    const onDestroy = cleanupLogic.cleanupOnDestroyOnce('ROOT -> setPointerValues');
    return function(xValue: number, yValues: YValue[]): void {
      sqTrendActions.setPointerValues(xValue, yValues);
      onDestroy(() => sqTrendActions.clearPointerValues());
    };
  })();

  API.clientConfig.functions.push({ path: 'ROOT', name: 'clearPointerValues' });
  API.clientConfig['ROOT -> clearPointerValues'] = { type: 'invokeVoid' };
  API['ROOT -> clearPointerValues'] = function(): void {
    sqTrendActions.clearPointerValues();
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setYAxis' });
  API.clientConfig['ROOT -> setYAxis'] = { type: 'invokeVoid' };
  API['ROOT -> setYAxis'] = function(id: string, axisMin: number, axisMax: number): void {
    sqTrendActions.setCustomizationProps([
      { id, yAxisMin: axisMin },
      { id, yAxisMax: axisMax }
    ]);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setYAxisAutoScale' });
  API.clientConfig['ROOT -> setYAxisAutoScale'] = { type: 'invokeVoid' };
  API['ROOT -> setYAxisAutoScale'] = function(id: string, autoScale: boolean): void {
    sqTrendActions.setCustomizationProps([{ id, axisAutoScale: autoScale }]);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'catchItemDataFailure' });
  API.clientConfig['ROOT -> catchItemDataFailure'] = { type: 'invokeVoid' };
  API['ROOT -> catchItemDataFailure'] = function(id: string, cancellationGroup: string, error: SeeqError) {
    // provide a default value, otherwise it will reject angular internal promise
    sqTrendActions.catchItemDataFailure(id, cancellationGroup, error, '');
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'editNewFormula' });
  API.clientConfig['ROOT -> editNewFormula'] = { type: 'invokeVoid' };
  API['ROOT -> editNewFormula'] = function(formula: string, parameters: FormulaParameter[]) {
    sqFormulaToolActions.editNewFormula(formula, parameters);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'showDetailsPaneColumns' });
  API.clientConfig['ROOT -> showDetailsPaneColumns'] = { type: 'invokeVoid' };
  API['ROOT -> showDetailsPaneColumns'] = function(keys: DetailsPaneColumnKey[]) {
    sqWorksheetActions.pluginShowColumn(pluginIdentifier, TREND_PANELS.SERIES, keys.map(mapDetailsPaneColumnKey));
  };

  function createStoreListener(exports: DataExport[], storeId: string, store: any, fields?: string[]) {
    const listeners = {};

    const lastValues = {};

    function sendExportData(dataExport) {
      const callName = `${dataExport.path} -> ${dataExport.functionName}`;
      if (listeners[callName]) {
        const data = store[dataExport.dataExport];
        const value = dataExport.transform(data);
        if (value !== undefined && !isEqual(lastValues[callName], value)) {
          sendMessage('listenerUpdate', { name: callName, value });
          lastValues[callName] = value;
        }
      }
    }

    function changeHandler() {
      exports.forEach(sendExportData);
    }

    function listen() {
      if (storeListenersUnsubscribes[storeId] !== undefined) {
        return;
      }
      const unsubscribe = fields ? flux.listenTo(store, fields, changeHandler) : flux.listenTo(store, changeHandler);
      storeListenersUnsubscribes[storeId] = unsubscribe;
    }

    exports.forEach(function(dataExport) {
      const callName = `${dataExport.path} -> ${dataExport.functionName}`;
      API[callName] = function() {
        listen();
        listeners[callName] = true;
        sendExportData(dataExport);
      };
      API.clientConfig.functions.push({ path: dataExport.path, name: dataExport.functionName });
      API.clientConfig[callName] = {
        type: 'listenTo'
      };
      unsubscribes[callName] = function() {
        delete listeners[callName];
        if (Object.keys(listeners).length === 0 && storeListenersUnsubscribes[storeId] !== undefined) {
          storeListenersUnsubscribes[storeId]();
          delete storeListenersUnsubscribes[storeId];
          delete lastValues[callName];
        }
      };
    });
  }

  const exportRange = function(rangeExport: RangeExport): DateRange {
    return {
      start: rangeExport.start.valueOf(),
      end: rangeExport.end.valueOf(),
      duration: sqDateTime.formatSimpleDuration(rangeExport.duration)
    };
  };

  const durationStore_displayRange_exports = [
    { dataExport: 'displayRange', path: 'ROOT', functionName: 'subscribeToDisplayRange', transform: exportRange }
  ];
  createStoreListener(durationStore_displayRange_exports, 'durationStore_displayRange', sqDurationStore, ['displayRange']);

  const durationStore_investigateRange_exports = [
    { dataExport: 'investigateRange', path: 'ROOT', functionName: 'subscribeToInvestigationRange', transform: exportRange }
  ];
  createStoreListener(durationStore_investigateRange_exports, 'durationStore_investigateRange', sqDurationStore, ['investigateRange']);

  const exportSignals = (signals: any[]): TrendSignal[] => signals
    .filter(item => !item.isChildOf)
    .map(exportSignal);

  const trendSeriesStore_items_exports = [
    { dataExport: 'items', path: 'ROOT', functionName: 'subscribeToSignals', transform: exportSignals }
  ];
  createStoreListener(trendSeriesStore_items_exports, 'trendSeriesStore_items', sqTrendSeriesStore, ['items']);

  const exportScalars = (scalars: any[]): TrendScalar[] => scalars
    .filter(item => !item.isChildOf)
    .map(exportScalar);

  const trendScalarStore_items_exports = [
    { dataExport: 'items', path: 'ROOT', functionName: 'subscribeToScalars', transform: exportScalars }
  ];
  createStoreListener(trendScalarStore_items_exports, 'trendScalarStore_items', sqTrendScalarStore, ['items']);

  const exportConditions = (conditions: any[]): TrendCondition[] => conditions
    .filter(item => !item.isChildOf)
    .map(exportCondition);

  const trendCapsuleSetStore_items_exports = [
    { dataExport: 'items', path: 'ROOT', functionName: 'subscribeToConditions', transform: exportConditions }
  ];
  createStoreListener(trendCapsuleSetStore_items_exports, 'trendCapsuleSetStore_items', sqTrendCapsuleSetStore, ['items']);

  const exportMetrics = (metrics: any[]): TrendMetric[] => metrics
    .filter(item => !item.isChildOf)
    .map(exportMetric);

  const trendMetricStore_items_exports = [
    { dataExport: 'items', path: 'ROOT', functionName: 'subscribeToMetrics', transform: exportMetrics }
  ];
  createStoreListener(trendMetricStore_items_exports, 'trendMetricStore_items', sqTrendMetricStore, ['items']);

  const exportTables = (tables: any[]): TrendTable[] => tables
    .filter(item => !item.isChildOf)
    .map(exportTable);

  const trendTableStore_items_exports = [
    { dataExport: 'items', path: 'ROOT', functionName: 'subscribeToTables', transform: exportTables }
  ];
  createStoreListener(trendTableStore_items_exports, 'trendTableStore_items', sqTrendTableStore, ['items']);

  const exportPluginState = function(pluginState: object): PluginState {
    return pluginState && pluginState[pluginIdentifier] ? pluginState[pluginIdentifier] : {};
  };

  const pluginStore_pluginState_exports = [
    { dataExport: 'pluginState', path: 'ROOT', functionName: 'subscribeToPluginState', transform: exportPluginState }
  ];
  createStoreListener(pluginStore_pluginState_exports, 'pluginStore_pluginState', sqPluginStore, ['pluginState']);

  const exportSelectedCapsules = function(): TrendCapsule[] {
    return sqTrendCapsuleStore.selectedCapsules;
  };

  const trendCapsuleStore_selectedCapsules_exports = [
    { dataExport: 'selectedCapsules', path: 'ROOT', functionName: 'subscribeToSelectedCapsules', transform: exportSelectedCapsules }
  ];
  createStoreListener(trendCapsuleStore_selectedCapsules_exports, 'trendCapsuleStore_selectedCapsules', sqTrendCapsuleStore, ['selectedCapsules']);

  const exportCapsules = function(): TrendCapsule[] {
    return sqTrendCapsuleStore.items;
  };

  const trendCapsuleStore_items_exports = [
    { dataExport: 'items', path: 'ROOT', functionName: 'subscribeToCapsules', transform: exportCapsules }
  ];
  createStoreListener(trendCapsuleStore_items_exports, 'trendCapsuleStore_items', sqTrendCapsuleStore, ['items']);

  function extractErrorFields(error) {
    const { data, status, xhrStatus } = error;
    const { statusMessage, errorType, errorCategory, inaccessible } = data;
    return { data: { statusMessage, errorType, errorCategory, inaccessible }, status, xhrStatus };
  }

  messagePort.onmessage = (event) => {
    if (event.isTrusted && event.data.type) {
      debug.log('message received from plugin', event.data);
      const { type, payload, nonce } = event.data;
      if (!initialized && type === 'init successful') {
        initialized = true;
        onInitialized && onInitialized();
        sendMessage('apiConfig', API.clientConfig);
      } else if (initialized && type === 'invokeVoid' && payload && API[payload.function]) {
        if (payload.unsubscribe === true) {
          API.unsubscribe(payload.function);
        } else {
          API[payload.function].apply(this, payload.args);
        }
      } else if (initialized && type === 'invoke' && payload && API[payload.function]) {
        const result = API[payload.function].apply(this, payload.args);
        sendMessage('invocationResult', { result }, nonce);
      } else if (initialized && type === 'invokeAsync' && payload && API[payload.function]) {
        API[payload.function].apply(this, payload.args)
          .then((result) => {
            sendMessage('invocationResult', { result }, nonce);
          })
          .catch((error) => {
            sendMessage('invocationResult', { error: extractErrorFields(error) }, nonce);
          });
      } else {
        debug.warn('unexpected message', event.data);
      }
      debug.log('message received from plugin', event.data);
    } else {
      debug.warn('untrusted message or malformed message discarded');
    }
  };

  return API;
}
