import bind from 'class-autobind-decorator';
import _ from 'lodash';
import angular from 'angular';
import { NumberHelperService } from '@/core/numberHelper.service';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { STRING_UOM } from '@/main/app.constants';
import { ENUM_REGEX, TREND_SIGNAL_STATS } from '@/trendData/trendData.module';

@bind
export class ColumnHelperService {
  constructor(
    private $translate: ng.translate.ITranslateService,
    private sqTrendSeriesStore: TrendSeriesStore,
    private sqNumberHelper: NumberHelperService) {
  }

  /**
   * Get the value of a column for an item
   *
   * @param {Object} column - One of the columns from COLUMNS
   * @param {Object} item - Item for which to get a value
   * @returns {String} Formatted value
   */
  getColumnValue(column, item) {
    const isStartTime = column.key === 'startTime';
    const isEndTime = column.key === 'endTime';
    const isUncertain = item?.isUncertain;

    if (isStartTime && _.isNil(item.startTime)) {
      return this.$translate.instant('CAPSULES_PANEL.STARTS_OFF_SCREEN');
    } else if (isEndTime && _.isNil(item.endTime)) {
      return this.$translate.instant('CAPSULES_PANEL.ENDS_OFF_SCREEN');
    } else if (isEndTime && isUncertain) {
      return this.$translate.instant('CAPSULES_PANEL.IN_PROGRESS');
    }

    const formatOptions = item.formatOptions ?? column.series?.formatOptions ??
      this.sqTrendSeriesStore.findItem(column.referenceSeries)?.formatOptions ?? {};
    const rawValue = _.get(item, column.accessor || column.key);
    const value = (column.style === 'string' || this.isProperty(column) ||
      (column.isStringCompatible && _.isString(rawValue)))
      ? (this.parseEnum(rawValue) || rawValue)
      : this.sqNumberHelper.formatNumber(_.get(item, column.key),
        {
          ...formatOptions,
          format: column.format || formatOptions.format,
          isPercent: column.style === 'percent'
        });
    const uom = _.get(item, column.uomKey, STRING_UOM);
    return `${_.isNil(value) ? '' : value}${_.isNil(uom) || uom === STRING_UOM ? '' : uom}`;
  }

  /**
   * Determines if the column is a statistic (e.g. Max of a series)
   *
   * @param {Object} column - One of the columns from COLUMNS
   * @return {boolean} True if it is a custom statistic column
   */
  isStatistic(column) {
    return _.includes(column.key, 'statistics');
  }

  /**
   * Determines if the column is a custom property on the item (e.g. Batch ID)
   *
   * @param {Object} column - One of the columns from COLUMNS
   * @return {boolean} True if it is a custom statistic column
   */
  isProperty(column) {
    return _.includes(column.key, 'properties');
  }

  /**
   * Gets the title for a column from the capsules pane
   *
   * @param {Object} column - One of the columns
   * @return {string} The title of the column
   */
  getCapsuleColumnTitle(column) {
    return this.$translate.instant(column.title) || column.propertyName ||
      `${this.sqTrendSeriesStore.findItem(column.referenceSeries)?.name} ${
        this.$translate.instant(_.find(TREND_SIGNAL_STATS, ['key', column.statisticKey])?.title)}`;
  }

  parseEnum(value) {
    const match = value?.toString().match(ENUM_REGEX);
    return match ? match[2] : undefined;
  }
}

angular
  .module('Sq.TrendViewer')
  .service('sqColumnHelper', ColumnHelperService);
