import angular from 'angular';
import { sqWorkbookStore } from '@/workbook/workbook.store';

const dependencies = [
  'Sq.Core',
  'Sq.Directives.DoubleClick',
  'Sq.Services.Utilities',
  'Sq.Services.ACLService',
  'Sq.Worksheets',
  'Sq.Worksteps'
];

/**
 * @module The workbook module manages available worksheets as
 * well as the currently active worksheet for the currently active
 * workbook.
 */
angular.module('Sq.Workbook', dependencies)
  .store('sqWorkbookStore', sqWorkbookStore);

export const WORKBOOK_DISPLAY = {
  EDIT: 'WORKBOOK_DISPLAY.EDIT',
  VIEW: 'WORKBOOK_DISPLAY.VIEW',
  PRESENT: 'WORKBOOK_DISPLAY.PRESENT'
};

export const DEFAULT_WORKBOOK_STATE = { stores: {} };
