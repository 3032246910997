import moment from 'moment-timezone';
import React from 'react';
import ReactDom from 'react-dom';
import { Root } from '@/hybrid/core/Root';
import {
  BasePluginDependencies,
  ContentDisplayMode,
  ImageContentListenerCommand
} from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import * as portals from 'react-reverse-portal';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import jQuery from 'jquery';
import { AssetSelection, Content, DateRange } from '@/reportEditor/report.module';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

const CK_RESET_CLASS = 'ck-reset_all';

export const CK_DROPDOWN_BUTTON_CLASS = 'ckEditorDropdownButton';

export const EDITABLE_CONTENT_MODEL_ATTRIBUTES = {
  WIDTH: SeeqNames.TopicDocumentAttributes.DataSeeqContentWidth,
  HEIGHT: SeeqNames.TopicDocumentAttributes.DataSeeqContentHeight,
  BORDER: SeeqNames.TopicDocumentAttributes.DataSeeqContentBorder,
  NO_MARGIN: SeeqNames.TopicDocumentAttributes.DataSeeqContentNoMargin,
  WIDTH_PERCENT: SeeqNames.TopicDocumentAttributes.DataSeeqContentPercentWidth
};

export const CONTENT_MODEL_ATTRIBUTES = {
  ...EDITABLE_CONTENT_MODEL_ATTRIBUTES,
  DATA_SEEQ_CONTENT: SeeqNames.TopicDocumentAttributes.DataSeeqContent,
  PENDING: SeeqNames.TopicDocumentAttributes.DataSeeqContentPending
};

export const DATE_RANGE_LABEL_ATTRIBUTES = {
  DATA_DATE_RANGE_ID: SeeqNames.TopicDocumentAttributes.DataSeeqDateRangeId,
  DATA_DATE_RANGE_FORMAT: SeeqNames.TopicDocumentAttributes.DataSeeqDateRangeFormat,
  DATA_DATE_RANGE_CONTENT: SeeqNames.TopicDocumentAttributes.DataSeeqDateRangeContent,
};

export function elementWithRoot(pluginDependencies: BasePluginDependencies, domElement,
  componentToRender: JSX.Element) {
  ReactDom.render(<Root {...pluginDependencies}>{componentToRender}</Root>, domElement);
}

/**
 * Renders the given react component inside an InPortal, passing the portalNode the rendered element, and then
 * rendering to the node to an OutPortal at [domElement].
 *
 * @param pluginDependencies - Editor plugin dependencies for the Root that the element is rendered in
 * @param domElement - The dom element the OutPortal will be rendered to
 * @param reactComponent - The actual component being rendered
 * @param props - The props for said component
 * @returns - The floating element the InPortal is created on, allowing for cleanup of the InPortal later on
 */
export function renderElementWithPortal(pluginDependencies: BasePluginDependencies, domElement,
  reactComponent: SeeqComponent<any>, props: any): HTMLElement {
  const portalNode = portals.createHtmlPortalNode();
  const portalDomElement = document.createElement('div');

  ReactDom.render(<portals.InPortal node={portalNode}>
    <Root {...pluginDependencies}>
      {React.createElement(reactComponent, { ...props, portalNode }, null)}
    </Root>
  </portals.InPortal>, portalDomElement);

  ReactDom.render(<portals.OutPortal node={portalNode} />, domElement);

  return portalDomElement;
}

export function moveNode(domElement, portalNode: portals.HtmlPortalNode) {
  ReactDom.render(<portals.OutPortal node={portalNode} />, domElement);
}

// Some CK image functions needs an imageUtils helper that is annoyingly difficult to grab. We only need one
// function from the helper, so this is it. Confusingly, they use this function for both inline and block, so it
// seems to just be poorly named.
export const replacementImageUtils = {
  isInlineImageView: (node: any) => node && node.is('element', 'img')
};

export function isNonContentImage(node: any) {
  return node.is('element', 'img') && node.getAttribute('src') && !node.getAttribute(
    CONTENT_MODEL_ATTRIBUTES.DATA_SEEQ_CONTENT);
}

/**
 * CK adds the ck-reset_all class to its toolbar which adds a bunch of styles that are hard to override in the
 * dropdowns. When toggling it off, make to sure to re-toggle so that other CK styles work as expected.
 */
export function toggleCkResetClass() {
  const toolbar = jQuery('#journalEditorToolbarContainer > .ck-toolbar');
  toolbar.hasClass(CK_RESET_CLASS)
    ? toolbar.removeClass(CK_RESET_CLASS)
    : toolbar.addClass(CK_RESET_CLASS);
}

export function buildRelativeContentUrl(contentId: string): string {
  return `${APPSERVER_API_PREFIX}/content/${contentId}/sourceUrl`;
}

export function buildAbsoluteContentUrl(baseUrl: string, content: Content, maybeDateRange?: DateRange,
  maybeAssetSelection?: AssetSelection): string {
  const url = new URL(`${baseUrl}/view/worksheet/${content.workbookId}/${content.worksheetId}`);
  url.searchParams.append('workstepId', content.workstepId);
  if (maybeDateRange) {
    url.searchParams.append('displayRangeStart', moment.utc(maybeDateRange.range.start).toISOString());
    url.searchParams.append('displayRangeEnd', moment.utc(maybeDateRange.range.end).toISOString());
  }
  if (content.timezone) {
    url.searchParams.append('timezone', content.timezone);
  }
  if (maybeAssetSelection) {
    url.searchParams.append('assetId', maybeAssetSelection.asset.id);
  }
  return url.toString();
}

export function getContentSpecificCommand(command: ImageContentListenerCommand, contentId: string) {
  return `${command}-${contentId}`;
}

/**
 * Uses jQuery to find out if content is in the document, because it is possible that contentMetadata is out of
 * sync, and using that metadata to determine if content is in the document can fail. We know that jQuery will give
 * us the most up to date information because it checks what is actually visible to the user.
 *
 * @param contentId for the piece of content we are checking
 */
export function isContentInDocument(contentId: string): boolean {
  return getContentJQuery(contentId).length > 0;
}

export function getContentJQuery(contentId: string): JQuery {
  return jQuery(`[${CONTENT_MODEL_ATTRIBUTES.DATA_SEEQ_CONTENT}="${contentId}"]`);
}

export function getContentWidgetViewElement(contentImage: EventTarget, editor: Editor): ViewElement {
  const imageView = editor.editing.view.domConverter.domToView(contentImage);
  return imageView.findAncestor({ name: 'div', classes: 'seeqContentWrapper' });
}

export function getContentDisplayMode(canModify: boolean, isPDF: boolean): ContentDisplayMode {
  if (isPDF) {
    return ContentDisplayMode.PDF;
  } else if (!canModify) {
    return ContentDisplayMode.VIEW_ONLY;
  } else {
    return ContentDisplayMode.NORMAL;
  }
}
