import angular from 'angular';
import { sqCustomConditionStore } from './customCondition.store';
import { CustomConditionActions } from './customCondition.actions';
import { ConditionFormulaService } from '@/investigate/customCondition/conditionFormula.service';
import { sqCustomCondition } from '@/investigate/customCondition/customCondition.component';
import { sqCapsuleGroup } from '@/investigate/customCondition/capsuleGroup.component';
import { CapsuleGroupActions } from '@/investigate/customCondition/capsuleGroup.actions';
import { sqCapsuleGroupStore } from '@/investigate/customCondition/capsuleGroup.store';
import { CapsuleProperty } from '@/datetime/datetime.module';

/** A capsule that is stored in the capsule group store  */
export interface CapsuleGroupCapsule {
  /** If this capsule was copied from another condition the id is the unique id of the source capsule*/
  id?: string;
  /** Beginning datetime for the capsule in ms */
  startTime: number;
  /** End datetime for the capsule in ms */
  endTime: number;
  /** An array of properties, only present if the properties are already known */
  properties?: CapsuleProperty[];

  /**
   * Information about the source capsule which is used to request the properties of the capsule. This field and
   * properties are mutually exclusive
   */
  propertiesSource?: {
    startTime: number;
    endTime: number;
    id: string;
    capsuleSetId: string;
    capsuleId: string;
    isUncertain: boolean;
  };
}

angular.module('Sq.Investigate')
  .store('sqCustomConditionStore', sqCustomConditionStore)
  .store('sqCapsuleGroupStore', sqCapsuleGroupStore)
  .service('sqCustomConditionActions', CustomConditionActions)
  .service('sqConditionFormula', ConditionFormulaService)
  .service('sqCapsuleGroupActions', CapsuleGroupActions)
  .component('sqCustomCondition', sqCustomCondition)
  .component('sqCapsuleGroup', sqCapsuleGroup);

export const CAPSULE_SOURCE_ID_PROPERTY_NAME = 'Source Unique Id';
export const CAPSULE_UNIQUE_PROPERTY_NAME = 'Unique Tag';
