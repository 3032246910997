import React from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Modal } from 'react-bootstrap';
import { Icon } from '@/hybrid/core/Icon.atom';

export interface VideoModalProps {
  onClose: () => any;
  videoSource: string;
  testId: string;
  backupLink?: string;
}

export const VideoModal: React.FunctionComponent<VideoModalProps> = ({ onClose, videoSource, testId, backupLink }) => {
  const { t } = useTranslation();
  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid={testId} size="lg">
      <Modal.Body>
        <button type="button" className="close mb5" onClick={onClose}>
          <Icon icon="fa-close" type="text" />
        </button>
        <video controls={true} autoPlay={true} disablePictureInPicture={true} controlsList="nodownload" width="100%">
          <source src={videoSource} />
        </video>

        {backupLink &&
        <div className="mt5 xsmall text-italic">
          {t('VIDEO.TROUBLE_VIEWING')}
          <a className="ml5" href={backupLink} target="_blank" rel="noopener noreferrer">
            {t('VIDEO.WATCH_HERE')}
          </a>
        </div>}
      </Modal.Body>
    </Modal>
  );
};
