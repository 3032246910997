import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { ReportStore } from '@/reportEditor/report.store';
import { DateTimeService } from '@/datetime/dateTime.service';
import { ReportActions } from '@/reportEditor/report.actions';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { WorksheetStore } from '@/worksheet/worksheet.store';

const NextScheduledUpdateBindings = bindingsDefinition({
  sqDateTime: injected<DateTimeService>(),
  sqReportActions: injected<ReportActions>(),
  sqReportStore: injected<ReportStore>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  timezone: prop<string>(),
  onClick: prop<() => void>(),
  viewOnly: prop.optional<boolean>()
});

export const NextScheduledUpdate: SeeqComponent<typeof NextScheduledUpdateBindings> = (props) => {
  const {
    sqDateTime,
    sqReportActions,
    sqReportStore,
    sqWorksheetStore
  } = useInjectedBindings(NextScheduledUpdateBindings);

  const { timezone, onClick, viewOnly = false } = props;
  const { t } = useTranslation();

  const scheduledUpdateCount = useFluxPath(sqReportStore, () => sqReportStore.scheduledUpdateCount);
  const reportSchedule = useFluxPath(sqReportStore, () => sqReportStore.reportSchedule);
  const nextRunTime = useFluxPath(sqReportStore, () => sqReportStore.nextRunTime);
  const hasLiveOrScheduledContent = useFluxPath(sqReportStore, () => sqReportStore.hasLiveOrScheduledContent);
  const timezoneFixed = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.timezoneFixed);
  const timezoneName = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.timezone?.name);

  const fetchNextScheduledUpdateTime = () => {
    // We request a next run time but we do not wait for the promise to set any local state so that we can avoid
    // a race condition where the throttled function resolves prior to the latest call, thus returning stale data
    sqReportActions.throttledUpdateNextRunTime(sqReportStore.id);
  };

  useEffect(fetchNextScheduledUpdateTime, [
    scheduledUpdateCount, reportSchedule, nextRunTime, hasLiveOrScheduledContent, timezoneFixed, timezoneName
  ]);

  const formatTime = (input: string): string => {
    const timeFormat = 'LL LTS'; // Example: June 1, 2020 8:00:00 AM
    return sqDateTime.formatTime(sqDateTime.parseISODate(input), { name: timezone }, timeFormat);
  };

  const autoDateRangeRequired = !sqReportStore.hasAutoDateRanges;
  const pendingUserInput = !sqReportStore.hasReportSchedule;
  const manualRefreshRequired = !pendingUserInput && sqReportStore.hasLiveOrScheduledContent &&
    _.isEmpty(nextRunTime);
  const scheduleConfigured = !autoDateRangeRequired && !pendingUserInput && !manualRefreshRequired &&
    sqReportStore.hasLiveOrScheduledContent;

  return (
    <div className="flexRowContainer min-height-45">
      {!viewOnly &&
      <div className="flexColumnContainer mr5">
        <label htmlFor="nextScheduledUpdate" className="flexFill">
          {t('REPORT.CONFIG.NEXT_SCHEDULED_UPDATE')}
        </label>
        <a href="#" className="flexColumnContainer link-no-focus link-no-underline" onClick={onClick}
          data-testid="openConfigureAutoUpdateModalButton">
          <i className="fa fa-fw fa-edit mr3 mt2" />
        </a>
      </div>}
      {viewOnly &&
      <div className="flexColumnContainer mr5">
        <label className="flexFill">
          {t('REPORT.CONFIG.NEXT_SCHEDULED_UPDATE')}
        </label>
      </div>
      }
      <div
        id="nextScheduledUpdate"
        data-testid="next-scheduled-update__display">
        {autoDateRangeRequired &&
        <span>{t('REPORT.CONFIG.NEXT_SCHEDULED_UPDATE_AUTO_DATE_RANGE_REQUIRED')}</span>}
        {manualRefreshRequired &&
        <a href="#" onClick={fetchNextScheduledUpdateTime}>
          {t('REPORT.CONFIG.NEXT_SCHEDULED_UPDATE_TRY_AGAIN')}
        </a>}
        {!sqReportStore.hasLiveOrScheduledContent &&
        <span className="error-text">{t('REPORT.CONFIG.NEXT_SCHEDULED_UPDATE_NO_LIVE_OR_SCHEDULED_CONTENT')}</span>}
        {scheduleConfigured && nextRunTime && !viewOnly &&
        <a href="#" onClick={onClick}>
          {formatTime(nextRunTime)} ({timezone})
        </a>}
        {scheduleConfigured && nextRunTime && viewOnly &&
        <span>{formatTime(nextRunTime)} ({timezone})</span>
        }
      </div>
    </div>
  );
};
