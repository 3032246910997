import _ from 'lodash';
import { useInjector } from '@/hybrid/core/useInjector.hook';
import { useMemo } from 'react';

export function useInjectedBindings<T extends BindingsDefinition>(bindings: T): InjectedBindings<T> {
  const $injector = useInjector();
  return useMemo(() => {
    return _.chain(bindings)
      .pickBy((b: Binding<BindingType, BindingRequired, unknown>) => b.scope === 'injected')
      .mapValues((b, name) => $injector.get(name))
      .value() as InjectedBindings<T>;
  }, [$injector, bindings]);
}
