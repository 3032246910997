import React from 'react';
import { WorksheetThumbnail } from '@/hybrid/worksheets/WorksheetThumbnail.atom';

interface ReadOnlyWorksheetPaneTileProps {
  worksheet: any;
  workbookId: string;
  isActive: boolean;
  index: number;
  gotoWorksheet: (worksheetId: string, viewOnly?: boolean) => any;
}

export const ReadOnlyWorksheetPaneTile: React.FunctionComponent<ReadOnlyWorksheetPaneTileProps> = (props) => {
  const { worksheet, isActive, workbookId, gotoWorksheet, index } = props;
  const worksheetId = worksheet.worksheetId;

  return (
    <div id={worksheetId} className="worksheetContainer" data-testid="worksheetContainer">
      {isActive && <div className="activeWorksheetIndicator" data-testid="activeIndicator" />}
      <div className="worksheetSlide" id={`thumb_${index}`}>
        <div onClick={() => gotoWorksheet(worksheetId, true)}
          data-testid={`${worksheetId}_thumbnailContainer`}
          className="cursorPointer">
          <WorksheetThumbnail
            workbookId={workbookId}
            worksheetId={worksheetId}
            isActive={false} // never show as active since the thumbnail won't be changed by view mode
            updatedAt={worksheet.updatedAt} />
        </div>
        <div className="worksheetWell flexColumnContainer flexSpaceBetween">
          <p className="flexFill breakWord" data-testid={`${worksheetId}_name`}>
            {worksheet.name}
          </p>
        </div>
      </div>
    </div>
  );
};
