import _ from 'lodash';
import angular from 'angular';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';

angular.module('Sq.TrendViewer').store('sqTimebarStore', sqTimebarStore);

export type TimebarStore = ReturnType<typeof sqTimebarStore>['exports'];

/**
 * This store does not dehydrate or rehydrate. It is used to communicate
 * capsule regions. The timebar controller computes and updates the regions
 * in this store and the scatter plot controller listens for changes so it
 * can put the regions on scope for the minimap.
 */
function sqTimebarStore() {
  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.NONE,

    initialize() {
      this.state = this.immutable({ regions: [] });
    },

    exports: {
      get regions() {
        return this.state.get('regions');
      }
    },

    handlers: {
      TIMEBAR_SET_REGIONS_FOR_CONDITION: 'setRegionsForCondition',
      TIMEBAR_CLEAR_REGIONS: 'clearRegions',
      TREND_SET_COLOR: 'updateColors'
    },

    /**
     * Updates the color of regions belonging to an updated set
     *
     * @param {Object} payload - Object container for parameters
     * @param {String} payload.id - ID of the capsule whose color is being updated
     * @param {String} payload.color - The color of the recently updated set
     */
    updateColors(payload) {
      _.forEach(this.state.get('regions'), (region: any, index) => {
        if (region.capsuleSetId === payload.id) {
          this.state.set(['regions', index, 'color'], payload.color);
        }
      });
    },

    /**
     * Clears all the regions
     */
    clearRegions() {
      this.state.set('regions', []);
    },

    /**
     * Sets the regions for a specified condition, overwriting any previous regions for that condition.
     *
     * @param {Object} payload - Object container for parameters
     * @param {String} payload.id - ID of the condition whose regions are being replaced
     * @param {Object[]} payload.regions - Regions to add for the specified condition
     */
    setRegionsForCondition(payload) {
      this.state.set('regions', _.reject(this.state.get('regions'), ['capsuleSetId', payload.id]));
      this.state.concat('regions', payload.regions);
    }
  };

  return store;
}
