import _ from 'lodash';
import angular from 'angular';

angular.module('Sq.Services.Visibility', [])
  .factory('sqVisibility', sqVisibility);

export type VisibilityService = ReturnType<typeof sqVisibility>;

function sqVisibility($q: ng.IQService, $rootScope: ng.IRootScopeService) {
  let visible = !document.hidden;
  let resolve = _.noop;
  const visibilityPromise: ng.IPromise<any> = visible ? $q.resolve() : new $q((r) => {
    resolve = r;
  });

  document.addEventListener('visibilitychange', handleVisibilityChange);

  $rootScope.$on('$destroy', () => {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  });

  function handleVisibilityChange() {
    if (!visible && !document.hidden) {
      $rootScope.$evalAsync(function() {
        visible = true;
        resolve();
      });
    }
  }

  return {
    get isVisible() {
      return visible;
    },
    waitForVisibility() {
      return visibilityPromise;
    }
  };
}
