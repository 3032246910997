import React from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import classNames from 'classnames';

const reportConfigMetaRowBindings = bindingsDefinition({
  label: prop.optional<string>(),
  value: prop.optional<string>(),
  extraValueClasses: prop.optional<string>()
});

export const ReportConfigMetaRow: SeeqComponent<typeof reportConfigMetaRowBindings> = (props) => {
  const { label, value, extraValueClasses = '', children } = props;

  return (
    <div className="flexColumnContainer">
      {label && <label>{label}</label>}
      <span className={classNames('pl5', extraValueClasses)}>
        {value}
        {children}
      </span>
    </div>
  );
};
