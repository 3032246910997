import React from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { ReportContentDateRange } from '@/hybrid/reportEditor/ReportContentDateRange.molecule';
import { ReportContentSize } from '@/hybrid/reportEditor/ReportContentSize.molecule';
import { ReportStore } from '@/reportEditor/report.store';
import _ from 'lodash';
import {
  KEEP_CURRENT_ASSET_SELECTION,
  KEEP_CURRENT_DATE_RANGE,
  KEEP_CURRENT_SCALE,
  KEEP_CURRENT_SHAPE,
  KEEP_CURRENT_SIZE, KEEP_CURRENT_SUMMARY, KEEP_NAME,
  REPORT_CONTENT
} from '@/reportEditor/report.module';
import { ReportContentStore } from '@/reportEditor/reportContent.store';
import { ReportActions } from '@/reportEditor/report.actions';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { ReportContentSummary } from '@/hybrid/reportEditor/ReportContentSummary.organism';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { ReportContentAssetSelection } from '@/hybrid/reportEditor/ReportContentAssetSelection.molecule';

const reportContentBulkEditPropertiesBindings = bindingsDefinition({
  sqReportActions: injected<ReportActions>(),
  sqReportStore: injected<ReportStore>(),
  sqReportContentStore: injected<ReportContentStore>(),
  $state: injected<ng.ui.IStateService>()
});

export const ReportContentBulkEditProperties: SeeqComponent<typeof reportContentBulkEditPropertiesBindings> = (props) => {
  const {
    sqReportActions,
    sqReportStore
  } = useInjectedBindings(reportContentBulkEditPropertiesBindings);
  const { t } = useTranslation();

  const keepDateRange = { ...KEEP_CURRENT_DATE_RANGE };
  keepDateRange.name = t(keepDateRange.name);
  const sizes = _.assign({}, REPORT_CONTENT.SIZE, { KEEP: KEEP_CURRENT_SIZE });
  const shapes = _.assign({}, REPORT_CONTENT.SHAPE, { KEEP: KEEP_CURRENT_SHAPE });
  const scales = _.assign({}, REPORT_CONTENT.SCALE, { KEEP: KEEP_CURRENT_SCALE });
  const dateRanges = _.concat(sqReportStore.dateRangesNotArchived, [keepDateRange]);
  const assetSelections = _.concat(sqReportStore.assetSelectionsNotArchived, { ...KEEP_CURRENT_ASSET_SELECTION });
  const summaries = _.assign({}, REPORT_CONTENT.SUMMARY, { KEEP: KEEP_CURRENT_SUMMARY });

  const {
    bulkSize,
    bulkShape,
    bulkScale,
    bulkHeight,
    bulkWidth,
    bulkSummary,
    bulkAssetSelection,
    shouldUpdateBulkWorkstep
  } = useFlux(sqReportStore);

  const dateRangeFromStore = useFluxPath(sqReportStore, () => sqReportStore.bulkDateRange);
  const dateRange = !_.isUndefined(dateRangeFromStore) && dateRangeFromStore.name === KEEP_NAME ?
    keepDateRange : dateRangeFromStore;

  const setSize = (size, calculateShape = true) => {
    sqReportActions.setBulkSize(size);
    if (calculateShape) {
      if (size.key === KEEP_CURRENT_SIZE.key) {
        setShape(KEEP_CURRENT_SHAPE, false);
      } else if (size.key !== REPORT_CONTENT.SIZE.CUSTOM && bulkShape.key === KEEP_CURRENT_SHAPE.key) {
        setShape(REPORT_CONTENT.SHAPE.RECTANGLE, false);
      }
    }
  };

  const setShape = (shape, calculateSize = true) => {
    sqReportActions.setBulkShape(shape);
    if (calculateSize) {
      if (shape.key === KEEP_CURRENT_SHAPE.key) {
        setSize(KEEP_CURRENT_SIZE, false);
      } else if (bulkSize.key === KEEP_CURRENT_SIZE.key) {
        setSize(REPORT_CONTENT.SIZE.MEDIUM, false);
      }
    }
  };

  const setDateRange = (dateRange) => {
    sqReportActions.setBulkDateRange(dateRange);
  };

  return <div className="bulkEditProperties flexFillOverflow flexRowContainer">
    <div className="flexRowContainer pl10 pr10 pt10">
      {t('REPORT.CONTENT.UPDATE_WORKSTEP')}
      <div className="tn-group flexColumnContainer flexSpaceBetween ml10">
        <div className="cursorPointer m5 radio">
          <input type="radio" className="cursorPointer" id="updateWorkstepOff"
            checked={!shouldUpdateBulkWorkstep}
            onChange={() => sqReportActions.setShouldUpdateBulkWorkstep(false)} />
          <label htmlFor="updateWorkstepOff">
            {t('REPORT.CONFIG.UPDATE_WORKSTEP.NO')}
          </label>
        </div>
        <div className="cursorPointer m5 radio">
          <input type="radio" className="cursorPointer" id="updateWorkstepOn"
            checked={shouldUpdateBulkWorkstep}
            onChange={() => sqReportActions.setShouldUpdateBulkWorkstep(true)} />
          <label htmlFor="updateWorkstepOn">
            {t('REPORT.CONFIG.UPDATE_WORKSTEP.YES')}
          </label>
        </div>
      </div>
    </div>
    <ReportContentSize
      useSizeFromRender={false}
      isSizeCustom={bulkSize.key === REPORT_CONTENT.SIZE.CUSTOM.key}
      sizes={sizes}
      shapes={shapes}
      scales={scales}
      size={bulkSize}
      shape={bulkShape}
      scale={bulkScale}
      setShape={setShape}
      setSize={setSize}
      setScale={sqReportActions.setBulkScale}
      width={bulkWidth}
      height={bulkHeight}
      setHeight={sqReportActions.setBulkHeight}
      setWidth={sqReportActions.setBulkWidth}
    />
    <ReportContentDateRange
      dateRange={dateRange}
      dateRanges={dateRanges}
      setDateRange={setDateRange}
    />
    <ReportContentAssetSelection
      selection={bulkAssetSelection}
      assetSelections={assetSelections}
      setAssetSelection={sqReportActions.setBulkAssetSelection}
    />
    <ReportContentSummary
      summary={bulkSummary}
      summaries={summaries}
      setSummary={sqReportActions.setBulkSummary}
    />
  </div>;
};

