import _ from 'lodash';
import angular from 'angular';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { DateTimeService } from '@/datetime/dateTime.service';
import { UtilitiesService } from '@/services/utilities.service';
import { TrendStore } from '@/trendData/trend.store';
import { DETAILS_PANE_ITEM_TYPES, ITEM_TYPES } from '@/trendData/trendData.module';
import { STRING_UOM } from '@/main/app.constants';

angular.module('Sq.TrendViewer').service('sqItemDecorator', sqItemDecorator);

export type ItemDecoratorService = ReturnType<typeof sqItemDecorator>;

function sqItemDecorator(
  sqWorksheetStore: WorksheetStore,
  sqDateTime: DateTimeService,
  sqUtilities: UtilitiesService,
  sqTrendStore: TrendStore
) {
  const service = {
    decorate,
    sortAndDecorateItems
  };
  return service;

  /**
   * Decorates an item, or collection of items, with additional view-level properties. Item must be one of those
   * specified by DETAILS_PANE_ITEM_TYPES.
   *
   * @param {Object|Object[]} items - The item or collection of items being decorated
   * @param {Object} options - Options for the various decorators
   * @param {Boolean} [options.singleSelect=false] - True if single-select is enabled
   * @param {Boolean} [options.pickingMode=false] - True if plus and minus icons should be used
   * @returns {Object} The decorated item or collection of items.
   */
  function decorate(items, options = {}) {
    return _.isArray(items) ? _.map(items, _decorate) : _decorate(items);

    function _decorate(item) {

      if (_.includes(DETAILS_PANE_ITEM_TYPES, item.itemType)) {
        return decorateItem(item, options);
      } else if (item.itemType === ITEM_TYPES.CAPSULE) {
        return decorateCapsule(item, options);
      } else {
        throw new TypeError(item.itemType + ' is unsupported');
      }
    }
  }

  /**
   * Sorts the items with a sort column and order. Then decorates the items.
   *
   * @param {Object} sortParam - Container holding sorting parameters
   * @param {String} sortParam.sortBy - property to sort on
   * @param {Boolean} sortParam.sortAsc - if truthy the items should be sorted in ascending order
   * @param {Object[]} items - collection of items to be decorated and sorted
   * @param {Object} [options] - Options for the various decorators
   */
  function sortAndDecorateItems({ sortBy, sortAsc }: { sortBy: string, sortAsc: boolean }, items: any[], options?) {
    return _.chain(items)
      .thru(items => service.decorate(items, options))
      .orderBy([sortBy], [sortAsc ? 'asc' : 'desc'])
      .value();
  }

  /**
   * Decorates a capsule with view-level properties.
   *
   * @param {Object} item - The item being decorated
   * @param {Object} options - Options for customizing behavior
   * @returns {Object} The decorated item.
   */
  function decorateCapsule(item, options) {
    const formattedStartTime =
      item.startTime ? sqDateTime.formatTime(item.startTime, sqWorksheetStore.timezone) : undefined;
    const formattedEndTime = item.endTime ? sqDateTime.formatTime(item.endTime, sqWorksheetStore.timezone) : undefined;
    const formattedDuration = _.isNumber(item.duration) ? sqDateTime.formatDuration(item.duration) : undefined;
    const isUncertain = item.isUncertain;
    const decoratedItem = _.assign({}, decorateItem(item, options), {
      formattedStartTime,
      formattedEndTime,
      formattedDuration,
      isUncertain,
      name: formattedStartTime
    });
    return _.assign(decoratedItem, {
      selectTooltip: selectTooltip(decoratedItem, options),
      selectIcon: selectIcon(decoratedItem, options)
    });
  }

  /**
   * Base decorator for all items.
   *
   * @param {Object} item - The item being decorated
   * @param {Object} [options] - Options for customizing behavior
   * @param {Boolean} [options.singleSelect=false] - True if single-select is enabled
   * @param {Boolean} [options.pickingMode=false] - True if plus and minus icons should be used
   * @returns {Object} The decorated item.
   */
  function decorateItem(item, options) {
    const classes = ['cursorPointer'];
    if (item.selected) {
      classes.push(options.pickingMode ? 'info' : 'warning');
    }
    if (item.isUncertain) {
      classes.push('uncertain');
    }
    const displayUnitOfMeasure = _.cond([
      // In CRAB-8217, users found the 'string' unit of measure as confusing, so we hide it.
      [valueUom => valueUom === STRING_UOM, _.constant({ isRecognized: true, value: '' })],
      [_.isEmpty, _.constant({ isRecognized: false, value: item.sourceValueUnitOfMeasure })],
      [_.stubTrue, value => ({ isRecognized: true, value })]
    ])(item.valueUnitOfMeasure);

    return _.assign({}, item, {
      displayUnitOfMeasure,
      rowClasses: classes.join(' '),
      selectTooltip: selectTooltip(item, options),
      selectIcon: selectIcon(item, options),
      style: { color: item.color }
    });
  }

  /**
   * Returns the tooltip for the "select" checkbox for an item.
   *
   * @param {Object} item - The item being decorated
   * @param {Object} options - Options for customizing behavior
   * @param {Boolean} [options.pickingMode=false] - True if plus and minus icons should be used
   * @returns {string} translation key for the tooltip.
   */
  function selectTooltip(item, options) {
    let mode = ['DESELECT', 'SELECT'];
    if (item.autoDisabled) {
      mode = ['DISABLED', 'DISABLED'];
    } else if (item.notFullyVisible && sqTrendStore.isTrendViewCapsuleTime()) {
      const tooltip = 'CAPSULES_PANEL.ZOOM_OUT_TO_VIEW_MORE_CAPSULE_VIEW';
      mode = [tooltip, tooltip];
    } else if (item.notFullyVisible) {
      const tooltip = 'CAPSULES_PANEL.NOT_SELECTABLE_NOT_VISIBLE_TOOLTIP';
      mode = [tooltip, tooltip];
    } else if (options.pickingMode) {
      mode = ['REMOVE', 'ADD'];
    }
    const [selected, deselected] = mode;
    return item.selected ? selected : deselected;
  }

  /**
   * Returns the classes that are used for creating the "select" checkbox for an item.
   *
   * @param {Object} item - The item being decorated
   * @param {Object} [options] - Options for customizing behavior
   * @param {Boolean} [options.singleSelect=false] - True if single-select is enabled
   * @param {Boolean} [options.pickingMode=false] - True if plus and minus icons should be used
   * @returns {string} The classes that indicate a checked or unchecked select box.
   */
  function selectIcon(item, options) {
    let mode = ['fa-check-square', 'fa-square-o'];
    if (item.autoDisabled) {
      mode = ['fa-ban sq-fairly-dark-gray', 'fa-ban sq-fairly-dark-gray'];
    } else if (item.notFullyVisible && sqTrendStore.isTrendViewCapsuleTime()) {
      const classes = 'fa-info-circle sq-darkish-gray sq-text-warning';
      mode = [classes, classes];
    } else if (item.notFullyVisible) {
      mode = ['fa-ban sq-darkish-gray', 'fa-ban sq-darkish-gray'];
    } else if (options.singleSelect) {
      mode = ['fa-check-circle', 'fa-circle-thin'];
    } else if (options.pickingMode) {
      mode = ['fa-minus-circle', 'fa-plus-circle'];
    }
    const [selected, deselected] = mode;
    return `fa-fw showHideTrend fa ${item.selected ? selected : deselected}`;
  }
}
