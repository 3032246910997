import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { CELL_TYPES } from '@/hybrid/core/Table.atom';
import React, { useEffect, useState } from 'react';
import { AdministrationActions } from '@/administration/administration.actions';
import _ from 'lodash';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { ButtonWithManagedSpinner } from '@/hybrid/core/ButtonWithManagedSpinner.atom';
import { AdminTableWrapper } from '@/hybrid/core/AdminTableWrapper.molecule';

const accessKeyTableBindings = bindingsDefinition({
  sqAdministrationActions: injected<AdministrationActions>()
});

export const AccessKeyTable: SeeqComponent<typeof accessKeyTableBindings> = () => {
  const { sqAdministrationActions } = useInjectedBindings(accessKeyTableBindings);

  const [tokens, setTokens] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    refreshKeys();
  }, []);

  const columns = [
    {
      accessor: 'id',
      sortable: false,
      filterable: false,
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 40, maxWidth: 40 }
    }, {
      accessor: 'description',
      searchProperty: 'description',
      header: 'ACCESS_KEY.NAME'
    }, {
      accessor: 'userId',
      searchProperty: 'userId',
      header: 'ACCESS_KEY.OWNER'
    }, {
      accessor: 'name',
      searchProperty: 'name',
      header: 'ACCESS_KEY.KEY_NAME'
    }, {
      accessor: 'createdAt',
      searchProperty: 'createdAt',
      header: 'ACCESS_KEY.CREATED_AT',
      cellType: CELL_TYPES.DATE_TIME,
      filterable: false
    }, {
      accessor: 'lastLogin',
      searchProperty: 'lastLogin',
      header: 'ACCESS_KEY.LAST_USED',
      cellType: CELL_TYPES.DATE_TIME,
      filterable: false
    }, {
      accessor: 'isAdmin',
      searchProperty: 'isAdmin',
      header: 'ADMIN.USER.ADMINISTRATOR',
      cellStyle: { minWidth: 50 },
      cellType: CELL_TYPES.CHECKMARK
    }
  ];
  const sortableColumns = _.map(columns, column => _.assign({ sortable: true, filterable: true }, column));

  const refreshKeys = () => sqAdministrationActions.refreshKeys()
    .then(({ data }) => setTokens(data.keys))
    .finally(() => setSelectedItems([]));

  const deleteKeys = () => sqAdministrationActions.deleteKeys(_.map(selectedItems, 'name'))
    .then(refreshKeys)
    .then(() => setSelectedItems([]));

  return (
    <div className="height-maximum">
      <div className="flexColumnContainer flexSpaceBetween mb5">
        <HoverTooltip delay={500} placement="top"
          text={_.isEmpty(selectedItems) ? '' : 'ACCESS_KEY.DELETE_WARNING_SHORT'}>
          <ButtonWithManagedSpinner
            buttonProps={{ id: 'deleteSelectedKeys', disabled: _.isEmpty(selectedItems) }}
            action={deleteKeys}
            spinnerIconProps={{ type: 'text', large: true }}
            label="ACCESS_KEY.DELETE_SELECTED" />
        </HoverTooltip>

        <ButtonWithManagedSpinner
          buttonProps={{ id: 'refreshKeys' }}
          icon="fa-repeat"
          action={refreshKeys}
          spinnerIconProps={{ type: 'text', large: true }}
          label="ACCESS_KEY.REFRESH_LIST" />
      </div>

      <div className="overflowAuto width-maximum height-maximum pb70">
        <AdminTableWrapper
          testId="accessKeyAdministrationTable"
          defaultSort={{ property: 'description', asc: true }}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          columns={sortableColumns}
          items={tokens} />
      </div>
    </div>
  );
};
