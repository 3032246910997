import angular from 'angular';
import template from './fixedValueWithUnits.component.html';

angular.module('Sq.Report').component('sqFixedValueWithUnits', {
  template,
  controller: 'FixedValueWithUnitsCtrl',
  require: {
    ngModel: 'ngModel'
  }
});
