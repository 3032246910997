/**
 * Check mark or number used in the tool panels
 *
 * @param number (number): which number to show
 * @param showNumber (boolean): true to show the number, false to show the check mark
 */
import React from 'react';
import { Icon } from '@/hybrid/core/Icon.atom';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';

const checkMarkOrNumberBindings = bindingsDefinition({
  number: prop<number>(),
  showNumber: prop<boolean>()
});

export const CheckMarkOrNumber: SeeqComponent<typeof checkMarkOrNumberBindings> = (props) => {
  const { number, showNumber } = props;

  return (
    <div className="flexRowContainer">
      <div className="min-width-30">
        {showNumber
          ? <h4 className="mt0 mb0 sq-force-text-gray">{number}.</h4>
          : <Icon icon="fa-check" number={number} />}
      </div>
    </div>
  );
};

export const sqCheckMarkOrNumber = angularComponent(checkMarkOrNumberBindings, CheckMarkOrNumber);
