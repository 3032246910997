import _ from 'lodash';
import angular from 'angular';
import HttpCodes from 'http-status-codes';
import { WorkstepsService } from '@/worksteps/worksteps.service';
import { WorkstepsStore } from '@/worksteps/worksteps.store';
import { NotificationsService } from '@/services/notifications.service';
import { HttpHelpersService } from '@/services/httpHelpers.service';
import { WORKSTEP_PUSH_DISABLED } from '@/worksteps/worksteps.module';
import { PUSH_IGNORE } from '@/services/stateSynchronizer.service';

angular.module('Sq.Worksteps').service('sqWorkstepsActions', sqWorkstepsActions);
export type WorkstepsActions = ReturnType<typeof sqWorkstepsActions>;

/**
 * Flux worksteps actions
 */
function sqWorkstepsActions(
  flux: ng.IFluxService,
  $q: ng.IQService,
  sqWorksteps: WorkstepsService,
  sqWorkstepsStore: WorkstepsStore,
  sqNotifications: NotificationsService,
  sqHttpHelpers: HttpHelpersService
) {
  const service = {
    push,
    next,
    current,
    previous,
    clear,
    last,
    get: retrieve
  };

  return service;

  /**
   * Pushes worksheet state on the workstep queue
   *
   * @param {String} workbookId - The workbook id to push.
   * @param {String} worksheetId - The worksheet id to push.
   * @param {Object} worksheetState An object containing the current state of the worksheet
   * @returns {Promise} A promise that resolves once the worksheet state has been pushed as a workstep
   */
  function push(workbookId, worksheetId, worksheetState) {
    return sqWorksteps.push(workbookId, worksheetId, worksheetState)
      .then((response) => {
        flux.dispatch('WORKSTEPS_SET', _.pick(response, 'previous', 'current', 'next', 'last'), PUSH_IGNORE);
        return response;
      })
      .catch((error) => {
        if (error !== WORKSTEP_PUSH_DISABLED) {
          // Push failed, so clear store
          service.clear(!sqHttpHelpers.isCanceled(error), _.get(error, 'status'));
        }

        return $q.reject(error);
      });
  }

  /**
   * Clears the worksteps store, with an optional error flag. Displays a notification when first transitioning to an
   * error state and the status code is not 403. We don't display a notification when the response is 403 because
   * the HttpHelpers forbiddenInterceptor automatically routes to the unauthorized route.
   *
   * @param {Boolean} [isError] - True if worksteps are being cleared due to an error that occurred
   * @param {Number} [status=undefined] - an optional HTTP status code for the error
   */
  function clear(isError = false, status = undefined) {
    if (isError && !sqWorkstepsStore.isError && status !== HttpCodes.FORBIDDEN) {
      sqNotifications.errorTranslate('WORKSTEPS.ERROR');
    }

    flux.dispatch('WORKSTEPS_SET', {
      previous: undefined,
      current: {},
      next: undefined,
      last: undefined,
      isError
    }, PUSH_IGNORE);
  }

  /**
   * Gets the current workstep
   *
   * @param {String} workbookId - The workbook ID
   * @param {String} worksheetId - The worksheet ID
   * @returns {Object} The current workstep
   */
  function current(workbookId, worksheetId) {
    return service.get(workbookId, worksheetId);
  }

  /**
   * Gets the next workstep and set it as the current one.
   *
   * @param {String} workbookId - The workbook ID
   * @param {String} worksheetId - The worksheet ID
   * @returns {Promise} A promise that resolves with the next workstep
   */
  function next(workbookId, worksheetId) {
    return sqWorkstepsStore.next ? service.get(workbookId, worksheetId, sqWorkstepsStore.next, true) : $q.reject();
  }

  /**
   * Gets the last workstep and set it as the current one.
   *
   * @param {String} workbookId - The workbook ID
   * @param {String} worksheetId - The worksheet ID
   * @returns {Promise} A promise that resolves with the last workstep
   */
  function last(workbookId, worksheetId) {
    return sqWorkstepsStore.last ? service.get(workbookId, worksheetId, sqWorkstepsStore.last, true) : $q.reject();
  }

  /**
   * Gets the previous workstep and set it as the current one.
   *
   * @param {String} workbookId - The workbook ID
   * @param {String} worksheetId - The worksheet ID
   * @returns {Promise} A promise that resolves with the previous workstep
   */
  function previous(workbookId, worksheetId) {
    return sqWorkstepsStore.previous ? service.get(workbookId, worksheetId, sqWorkstepsStore.previous, true) :
      $q.reject();
  }

  /**
   * Gets the specified workstep and sets it in the store.
   *
   * @param {String} workbookId - The workbook ID
   * @param {String} worksheetId - The worksheet ID
   * @param {String} [workstepId] - The workstep ID. If undefined the current workstep will be found.
   * @param {Boolean} [setAsCurrent=false] - True if it should be set as the current workstep on the server. Only
   *   applicable if `workstepId` is set.
   * @return {Promise} A promise that resolves with the workstep information.
   */
  function retrieve(workbookId, worksheetId, workstepId?, setAsCurrent?) {
    return sqWorksteps.get(workbookId, worksheetId, workstepId, setAsCurrent)
      .then((response) => {
        // PUSH_IGNORE is important here because this method is called as part of the resolve when rehydrating a
        // worksheet. Even though the worksheet store does not dehydrate any of its state a push during the rehydrate
        // process can result in extra worksteps on the wrong worksheet. cloneDeep is used to ensure that the
        // "current" value isn't frozen because that state may be modified before being sent to the state
        // synchronizer
        flux.dispatch('WORKSTEPS_SET', _.cloneDeep(_.pick(response, 'previous', 'current', 'next', 'last')),
          PUSH_IGNORE);
        return response;
      });
  }
}
