import _ from 'lodash';
import angular from 'angular';
import { SystemWarningActions } from '@/systemWarning/systemWarning.actions';
import { SystemWarningStore } from '@/systemWarning/systemWarning.store';
import { Feature, LicenseManagementStore } from '@/licenseManagement/licenseManagement.store';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { UtilitiesService } from '@/services/utilities.service';
import { WorkbenchActions } from '@/workbench/workbench.actions';

/**
 * This controller sets flags and values that are used by the sq-system-warning component.
 *
 * Based on the user type different banners are displayed:
 * - users get a prompt to tell their admin to increase specs.
 * - admins get a prompt to increase specs, a link to the required system specs, and a link to the Administration
 * page to view the current specs.
 *
 * The warning about system specs can be dismissed, but not snoozed. The warning is triggered upon login, but
 * nowhere else.
 */
angular.module('Sq.SystemWarning')
  .controller('SystemWarningCtrl', SystemWarningCtrl);

function SystemWarningCtrl(
  $scope: ng.IScope,
  $translate: ng.translate.ITranslateService,
  sqSystemWarningActions: SystemWarningActions,
  sqSystemWarningStore: SystemWarningStore,
  sqWorkbenchStore: WorkbenchStore,
  sqWorkbenchActions: WorkbenchActions,
  sqLicenseManagementStore: LicenseManagementStore,
  sqSystemConfiguration: SystemConfigurationService,
  sqUtilities: UtilitiesService,
  IS_PROTRACTOR) {

  const vm = this;
  vm.closeSystemWarning = sqSystemWarningActions.disableSystemWarning;
  vm.closeSystemMessage = () => sqWorkbenchActions.setSystemMessageHash(sqSystemWarningStore.systemMessage);
  vm.warningComponents = [];
  vm.showSystemWarning = false;
  vm.isUserAdmin = false;
  vm.adminContactName = sqSystemConfiguration.adminContactName;
  vm.adminContactEmail = sqSystemConfiguration.adminContactEmail;
  vm.systemMessage = '';
  vm.showSystemMessage = false;
  vm.showAuditWarning = false;
  vm.closeUserLimitWarning = sqSystemWarningActions.disableUserLimitWarning;
  vm.showUserLimitWarning = false;
  vm.showUserLimitRestriction = false;
  vm.userCount = null;
  vm.userLimitRestriction = null;
  const contactEmail = sqUtilities.buildEmailLink(vm.adminContactEmail, vm.adminContactName,
    'SYSTEM_WARNINGS.INFO_TEXT_EMAIL_SUBJECT', 'SYSTEM_WARNINGS.INFO_TEXT_EMAIL_BODY');
  vm.INFO_TEXT_USER = $translate.instant('SYSTEM_WARNINGS.INFO_TEXT_USER', { CONTACT: contactEmail });

  /**
   * True if this is a production environment
   */
  vm.isProduction = process.env.NODE_ENV === 'production' && !IS_PROTRACTOR;

  // Exposed for testing
  vm.syncWorkbenchStore = syncWorkbenchStore;
  vm.syncLicenseManagementStore = syncLicenseManagementStore;

  $scope.$listenTo(sqSystemWarningStore, syncSystemWarningStore);
  $scope.$listenTo(sqWorkbenchStore, syncWorkbenchStore);
  $scope.$listenTo(sqLicenseManagementStore, syncLicenseManagementStore);

  /**
   * Syncs the SystemWarning Store.
   */
  function syncSystemWarningStore() {
    vm.warningComponents = sqSystemWarningStore.warningComponents;
    vm.showSystemWarning = !_.isEmpty(vm.warningComponents) && sqSystemWarningStore.showSystemWarning
      // TODO CRAB-12149 Should non-admins should get hardware warnings?
      && sqWorkbenchStore.currentUser.isAdmin;
    vm.systemMessage = sqSystemWarningStore.systemMessage;
    // Needs to update showSystemMessage
    syncWorkbenchStore();
    // Needs to update whether to show user limit warnings
    syncLicenseManagementStore();
  }

  /**
   * Syncs the Workbench Store.
   */
  function syncWorkbenchStore() {
    vm.isUserAdmin = sqWorkbenchStore.currentUser.isAdmin;
    vm.showSystemMessage = !!sqSystemWarningStore.systemMessage &&
      !sqWorkbenchStore.isSystemMessageDismissed(sqSystemWarningStore.systemMessage);
  }

  /**
   * Syncs the License Management Store
   */
  function syncLicenseManagementStore() {
    vm.showAuditWarning =
      !sqLicenseManagementStore.hasValidFeature(Feature.Audit_Trail) && sqSystemConfiguration.auditingEnabled;

    if (sqWorkbenchStore.currentUser.isAdmin) {
      const license = sqLicenseManagementStore.license;
      vm.showUserLimitRestriction = license.userLimitRestrict && license.userCount >= license.userLimitRestrict;
      vm.showUserLimitWarning = !vm.showUserLimitRestriction
        && license.userLimitWarn
        && license.userLimitRestrict
        && license.userCount >= license.userLimitWarn
        && !sqSystemWarningStore.suppressUserLimitWarning;
      vm.userCount = license.userCount;
      vm.userLimitRestriction = license.userLimitRestrict;
    } else {
      vm.showUserLimitRestriction = false;
      vm.showUserLimitWarning = false;
    }
  }
}
