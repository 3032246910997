/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class MonitorsApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Retrieve the current value of the specified monitor. All times are in milliseconds
   * @param {string} monitorPath - Path of the monitor to read
   */
  public getMonitor(
    {
      monitorPath
    } : {
      monitorPath: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(monitorPath)) {
      throw new Error("'monitorPath' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/system/monitors/{monitorPath}'
        .replace('{monitorPath}', encodeURIComponent(String(monitorPath))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.MonitorOutputV1>;
  }

  /**
   * 
   * @summary Retrieve the documentation for all available monitors
   */
  public getMonitorDocumentation(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/system/monitors/definitions',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.MonitorDefinitionsOutputV1>;
  }

  /**
   * 
   * @summary Retrieve the paths of all available monitors
   */
  public getMonitors(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/system/monitors',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.MonitorsOutputV1>;
  }

  /**
   * 
   * @summary Update a gauge
   * @param {models.GaugeDatumV1} [body] - current value of the gauge
   */
  public postGaugeData(
    body?: models.GaugeDatumV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/system/monitors/gauge',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Increment a meter
   * @param {models.MeterDatumV1} [body] - monitor name and arguments
   */
  public postMeterData(
    body?: models.MeterDatumV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/system/monitors/meter',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Post monitor data
   * @param {models.MonitorInputV1} body - Dump of current monitor statistics
   */
  public postMonitorData(
    body: models.MonitorInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/system/monitors',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Update a timer
   * @param {models.TimerDatumV1} body - name of this timer
   */
  public postTimerData(
    body: models.TimerDatumV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/system/monitors/timer',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

}
