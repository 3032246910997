/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class AccessKeysApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Creates a new Access Key associated with the current session.
   * @param {models.AccessKeyInputV1} body - Key information
   */
  public createKey(
    body: models.AccessKeyInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/accesskeys',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AccessKeyOutputV1>;
  }

  /**
   * 
   * @summary Delete an Access Key
   * @param {string} keyName - Key Information
   */
  public deleteKey(
    {
      keyName
    } : {
      keyName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(keyName)) {
      throw new Error("'keyName' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/accesskeys/{keyName}'
        .replace('{keyName}', encodeURIComponent(String(keyName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Gets the Access Keys associated with the current session's user.
   * @param {number} [offset=0] - The pagination offset, the index of the first collection key that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection keys that will be returned in this page of results
   * @param {boolean} [getAll=false] - Get all user keys if true, only the current user&#39;s keys if false.
   */
  public getKeys(
    {
      offset,
      limit,
      getAll
    } : {
      offset?: number,
      limit?: number,
      getAll?: boolean
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/accesskeys',
      params: omitBy({
        ['offset']: offset,
        ['limit']: limit,
        ['getAll']: getAll
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AccessKeyOutputListV1>;
  }

}
