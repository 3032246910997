import React from 'react';
import _ from 'lodash';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import {
  Ace,
  Acl,
  getAdditionalText,
  Identity,
  prettyIdentityType
} from '@/hybrid/accessControl/itemAclModal.utilities';
import classNames from 'classnames';
import { Icon } from '@/hybrid/core/Icon.atom';
import { PERMISSIONS } from '@/main/app.constants';
import { AceCheckbox } from '@/hybrid/accessControl/AceCheckbox.molecule';
import SelectIdentity from '@/hybrid/core/SelectIdentity.molecule';
import { IdentityPreviewV1 } from '@/sdk';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { AcePopover } from '@/hybrid/accessControl/AcePopover.molecule';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';

const aclModalMainTabBindings = bindingsDefinition({
  loading: prop<boolean>(),
  saving: prop<boolean>(),
  advancedOpen: prop<boolean>(),
  setAdvancedOpen: prop<(boolean) => void>(),
  displayAceEntries: prop<Ace[]>(),
  currentUserId: prop<string>(),
  isAsset: prop<boolean>(),
  setPermissions: prop<(permission: string, ace: Ace) => void>(),
  newAce: prop<Ace>(),
  isAclFromDatasource: prop<boolean>(),
  addItemAce: prop<() => void>(),
  togglePermissionsInheritance: prop<() => void>(),
  itemAcl: prop<Acl>(),
  setIdentity: prop<(Identity: Identity) => void>()
});

export const AclModalMainTab: SeeqComponent<typeof aclModalMainTabBindings> = (props) => {
  const {
    loading,
    saving,
    advancedOpen,
    setAdvancedOpen,
    displayAceEntries,
    currentUserId,
    setPermissions,
    isAsset,
    newAce,
    isAclFromDatasource,
    itemAcl,
    addItemAce,
    setIdentity,
    togglePermissionsInheritance
  } = props;
  const { t } = useTranslation();

  const validIdentitySelected = _.has(newAce, 'identity.id');

  const tableHeader = <div className="flexColumnContainer fakeTableHeader">
    <div className="min-width-390">
      {t('ACCESS_CONTROL.HEADER.NAME')}
    </div>
    <div className="min-width-70">
      {t('ACCESS_CONTROL.HEADER.TYPE')}
    </div>
    {_.map(
      ['ACCESS_CONTROL.ACCESS_LEVELS.READ', 'ACCESS_CONTROL.ACCESS_LEVELS.WRITE', 'ACCESS_CONTROL.ACCESS_LEVELS.MANAGE'],
      level => <div className="text-center width-75" key={level}>{t(level)}</div>)}
  </div>;

  const popover = ace => <Popover id={`${ace.id}_popover`} className="ml5">
    <Popover.Content>
      <AcePopover ace={ace} />
    </Popover.Content>
  </Popover>;

  const tableBody = <div className="flexRowContainer fakeTableWrapper">
    {_.map(displayAceEntries, (ace, index) => {
      return <div className="flexColumnContainer fakeTableRow fakeTableStriped flexNoGrowNoShrink"
        key={`fakeTableRow-${index}`}>
        <div className="specAceIdentity text-valign-middle width-390 sq-text-primary">
          {(ace.identity.isRedacted || !ace.identity.isEnabled)
          && <Icon icon="fa-exclamation-triangle" type="danger" testId="aceNotAccessible" />}
          <OverlayTrigger placement="right-end" key={`${ace.id}-${index}`} overlay={popover(ace)} trigger="hover"
            transition={false}>
            <span>{ace.identity.name} {getAdditionalText(ace, t, currentUserId)}</span>
          </OverlayTrigger>
        </div>
        <div className="text-valign-middle min-width-70">
          {prettyIdentityType(ace.identity.type, t)}
        </div>
        {_.map(PERMISSIONS, perm =>
          <div className="text-center min-width-75" key={`${perm}_${ace.id}`}>
            <AceCheckbox
              name={perm}
              ace={ace}
              setPermissions={setPermissions}
              isAclFromDatasource={isAclFromDatasource}
              isAsset={isAsset} />
          </div>)}
      </div>;
    })}
  </div>;

  const newAceRow = <div id="specNewEntryRow" className="flexColumnContainer mt5 mb20">
    <div className="width-370 min-width-370 mr20">
      <SelectIdentity
        idForLabel="identityName"
        setIdentity={setIdentity}
        allowGroups={true}
        identity={newAce.identity as IdentityPreviewV1}
        includeAllProperties={true}
        startEditable={true}
        placeholder="ACCESS_CONTROL.USER_PLACEHOLDER"
        clearIdentityWhenEmpty={true}
      />
    </div>
    <div className="text-valign-middle min-width-70">
      {prettyIdentityType(newAce.identity.type, t)}
    </div>
    {_.map(PERMISSIONS, perm =>
      <div key={`${perm}_new`} className="text-center text-valign-middle min-width-75 footerCheckbox">
        <AceCheckbox
          name={perm}
          ace={newAce}
          setPermissions={setPermissions}
          isAsset={false}
          isAclFromDatasource={false} />
      </div>)}
    <div className="text-right text-valign-middle min-width-50">
      <TextButton id="specAddPermissionButton" type="button" extraClassNames="mr-3" size="sm" variant="theme"
        disabled={!validIdentitySelected} onClick={addItemAce} label="ADD" />
    </div>
  </div>;

  const permissionsFooter = <div
    className={classNames('animateHeight250Linear', 'lightGreyBorder', 'border-radius-4', 'flexRowContainer',
      'flexJustifyCenter', 'flexFill', advancedOpen ? 'height-100 mb10' : 'height-0')}>
    <div className="checkbox m10 cursorPointer flexRowContainer" onClick={(e) => {
      e.preventDefault();
      togglePermissionsInheritance();
    }}
      id="specDisableInheritanceCheckbox">
      <div className="flexColumnContainer">
        <Checkbox
          id="togglePermissionsCheckbox"
          onChange={() => {}}
          isChecked={itemAcl.permissionsInheritanceDisabled}
          label="ITEM_ACL.ADVANCED.DISABLE_INHERITED_LABEL"
        />
      </div>
      <div className="ml21 mr10">
        {t('ITEM_ACL.ADVANCED.DISABLE_INHERITED_DESCRIPTION')}
      </div>
    </div>
  </div>;

  return <>
    {loading
    && <div className="flexRowContainer flexCenter mt30 mb15">
      <span className="mb5">
        {t('ITEM_ACL.LOADING')}
      </span>
      <IconWithSpinner spinning={true} />
    </div>}
    {saving
    && <div className="flexRowContainer flexCenter mb15">
      <span className="mb5">
       {t('ITEM_ACL.SAVING')}
      </span>
      <IconWithSpinner spinning={true} />
    </div>}

    {!saving && !loading
    && <div id="aclFormGroup" className="mt15">
      {t('ITEM_ACL.LABEL')}
      {itemAcl.permissionsInheritanceDisabled && !isAclFromDatasource
      && <span
        onClick={() => setAdvancedOpen(true)}
        className="ml5 sq-text-warning cursorPointer link-no-underline">
        {t('ITEM_ACL.ADVANCED.DISABLED_INDICATOR')}
      </span>}

      {isAclFromDatasource
      && <div className="text-italic sq-text-warning">
        {t('ITEM_ACL.FROM_DATASOURCE')}
      </div>}

      {isAsset && !isAclFromDatasource
      && <div className="text-italic sq-text-warning">
        {t('ITEM_ACL.ASSET')}
      </div>}

      <div className="flexRowContainer mt10">
        {tableHeader}
        {tableBody}
        {!isAclFromDatasource && !isAsset && newAceRow}
      </div>
      {advancedOpen && !isAclFromDatasource && !isAsset && permissionsFooter}
    </div>}
  </>;
};
