import angular from 'angular';
import template from './getLink.component.html';

angular.module('Sq.Header').component('sqGetLink', {
  template,
  controller: 'GetLinkCtrl',
  bindings: {
    openAclModal: '<'
  }
});
