import _ from 'lodash';
import angular from 'angular';
import { CalculationRunnerService } from '@/services/calculationRunner.service';
import { SignalFromConditionStore } from '@/hybrid/tools/signalFromCondition/signalFromCondition.store';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { ITEM_TYPES } from '@/trendData/trendData.module';
import { INTERPOLATION_METHODS, SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.module';

angular.module('Sq.Investigate').service('sqSignalFromConditionActions', sqSignalFromConditionActions);
export type SignalFromConditionActions = ReturnType<typeof sqSignalFromConditionActions>;

function sqSignalFromConditionActions(
  flux: ng.IFluxService,
  sqToolRunner: ToolRunnerService,
  sqCalculationRunner: CalculationRunnerService,
  sqSignalFromConditionStore: SignalFromConditionStore
) {
  const service = {
    generate,
    setParams,
    getFormula,
    getParameters
  };

  return service;

  /**
   * Generate a signal from condition
   *
   * @param {String} conditionId - The id of the condition
   * @param {String} signalId - The id of the signal
   * @param {String} [color] - Color of the new item
   * @param {Object} [maxInterpolation] - The max interpolation
   */
  function generate(conditionId, signalId, color, maxInterpolation) {
    return sqToolRunner.panelExecuteSignal(
      sqSignalFromConditionStore.name,
      service.getFormula(sqSignalFromConditionStore.params, maxInterpolation),
      service.getParameters(conditionId, signalId),
      sqSignalFromConditionStore.configParams,
      sqSignalFromConditionStore.id,
      color
    );
  }

  /**
   * Update the form parameters.
   *
   * @param {Object} params - The params from the
   */
  function setParams(params) {
    flux.dispatch('SIGNAL_FROM_CONDITION_SET_PARAMS', { params });
  }

  /**
   * Gets the formula for the given parameters
   *
   * @param {Object} params - The params from the form
   * @param {Object} [maxInterpolation] - The max interpolation (ignored if using discrete)
   * @return {String} The resulting formula
   */
  function getFormula(params, maxInterpolation) {
    let maxGapSnippet;
    let formula;
    const keyMethod = _.find(SAMPLE_FROM_SCALARS.KEY_METHODS, ['key', params.keyMethod]) as any;

    // We don't always need to set the max interpolation. We can omit it when the input capsule series has a
    // predictable spacing, for example weeks() or months().
    maxGapSnippet = _.isUndefined(maxInterpolation)
      ? ''
      : ', ' + maxInterpolation.value + maxInterpolation.units;

    // For Discrete output, explicitly provide it to aggregate instead of chaining it afterwards.
    if (params.interpolation === INTERPOLATION_METHODS.DISCRETE) {
      maxGapSnippet = ', 0s'; // Units don't matter since the value is zero
    }

    const step = params.interpolation === INTERPOLATION_METHODS.STEP ? '.toStep()' : '';
    const stat = sqCalculationRunner.getStatisticFragment(params.stat);

    let series = '$series';
    if (_.get(sqSignalFromConditionStore.inputItem, 'itemType') === ITEM_TYPES.CAPSULE_SET) {
      const inputMaximumDuration = _.get(sqSignalFromConditionStore, 'inputItem.conditionMetadata.maximumDuration');
      if (_.isUndefined(inputMaximumDuration)) {
        series += getMaximumDurationFragment(params, 'input');
      }
    }

    let capsules = '$capsules';
    const boundingMaximumDuration = _.get(sqSignalFromConditionStore, 'condition.conditionMetadata.maximumDuration');
    if (_.isUndefined(boundingMaximumDuration)) {
      capsules += getMaximumDurationFragment(params, 'bounding');
    }

    formula = `${series}.aggregate(${stat}, ${capsules}, ${keyMethod.stat}${maxGapSnippet})${step}`;

    return formula;
  }

  /**
   * Gets a formula snippet for the maximum duration override (e.g. '.setMaximumDuration(2d)')
   *
   * @param {Object} params - the params from the form
   * @param {string} name - the override name (either 'input' or 'bounding')
   * @returns {string} a maximum duration override snippet
   * @throws {Error} an error if value and/or units are not defined
   */
  function getMaximumDurationFragment(params, name) {
    const value = _.get(params, `maximumDuration.${name}Override.value`);
    const units = _.get(params, `maximumDuration.${name}Override.units`);
    if (!_.isUndefined(value) && !_.isUndefined(units)) {
      return `.setMaximumDuration(${value}${units})`;
    } else {
      throw new Error(`Signal from Condition ${name} Condition must have a value for maximumDuration`);
    }
  }

  /**
   * Gets the parameters
   *
   * @param {String} conditionId - The id of the condition
   * @param {String} signalId - The id of the signal
   * @return {Object} Describes the named parameters of the formula
   */
  function getParameters(conditionId, signalId) {
    return { capsules: conditionId, series: signalId };
  }
}
