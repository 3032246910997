import _ from 'lodash';
import angular from 'angular';
import jQuery from 'jquery';
import { ItemDecoratorService } from '@/trendViewer/itemDecorator.service';
import { TrendActions } from '@/trendData/trend.actions';
import { TrackService } from '@/track/track.service';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { DateTimeService } from '@/datetime/dateTime.service';
import { DISPLAY_NUMBER_LENGTH, NumberHelperService } from '@/core/numberHelper.service';
import { SearchActions } from '@/search/search.actions';
import { TrendStore } from '@/trendData/trend.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { TrendScalarStore } from '@/trendData/trendScalar.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { YAxisActions } from '@/trendData/yAxis.actions';
import { WorkbookStore } from '@/workbook/workbook.store';
import { TreemapStore } from '@/treemap/treemap.store';
import { TrendTableStore } from '@/trendData/trendTable.store';
import { UtilitiesService } from '@/services/utilities.service';
import { NotificationsService } from '@/services/notifications.service';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { CursorsService } from '@/trendViewer/cursors.service';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { TrendMetricStore } from '@/trendData/trendMetric.store';
import { AuthorizationService } from '@/services/authorization.service';
import { NUMBERS_ONLY_REGEX } from '@/core/core.module';
import {
  CAPSULE_TIME_COLUMNS,
  CUSTOMIZATIONS_COLUMNS,
  ITEM_CUSTOMIZATIONS,
  ITEM_TYPES,
  SERIES_PANEL_EXTRA_TREND_COLUMNS,
  SERIES_PANEL_EXTRA_TREND_CUSTOMIZATION_COLUMNS,
  SERIES_PANEL_REQUIRED_TREND_COLUMNS,
  TREND_COLOR_COLUMN,
  TREND_COLUMNS,
  TREND_CONDITION_STATS,
  TREND_PANELS,
  TREND_SIGNAL_STATS,
  TREND_STORES
} from '@/trendData/trendData.module';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.module';
import { SEARCH_PANES } from '@/search/search.module';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.module';
import { IS_PROTRACTOR, STRING_UOM } from '@/main/app.constants';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { RedactionService } from '@/services/redaction.service';
import { ColumnHelperService } from '@/trendViewer/columnHelper.service';
import { PluginStore } from '@/hybrid/plugin/plugin.store';
import { LoggerService } from '@/services/logger.service';
import { ProcessTypeEnum } from 'sdk/model/ThresholdMetricOutputV1';

angular.module('Sq.TrendViewer').controller('SeriesPanelCtrl', SeriesPanelCtrl);

function SeriesPanelCtrl(
  $scope: ng.IScope,
  $timeout: ng.ITimeoutService,
  sqItemDecorator: ItemDecoratorService,
  sqTrendActions: TrendActions,
  sqTrack: TrackService,
  sqInvestigateActions: InvestigateActions,
  sqDateTime: DateTimeService,
  sqNumberHelper: NumberHelperService,
  sqSearchActions: SearchActions,
  sqTrendStore: TrendStore,
  sqTrendSeriesStore: TrendSeriesStore,
  sqTrendCapsuleSetStore: TrendCapsuleSetStore,
  sqTrendScalarStore: TrendScalarStore,
  sqTrendMetricStore: TrendMetricStore,
  sqWorksheetStore: WorksheetStore,
  sqYAxisActions: YAxisActions,
  sqWorkbookStore: WorkbookStore,
  sqTreemapStore: TreemapStore,
  sqTrendTableStore: TrendTableStore,
  sqUtilities: UtilitiesService,
  sqNotifications: NotificationsService,
  sqWorkbenchStore: WorkbenchStore,
  sqWorkbenchActions: WorkbenchActions,
  sqTrendDataHelper: TrendDataHelperService,
  sqCursors: CursorsService,
  sqWorksheetActions: WorksheetActions,
  sqAuthorization: AuthorizationService,
  sqColumnHelper: ColumnHelperService,
  sqRedaction: RedactionService,
  sqPluginStore: PluginStore,
  sqLogger: LoggerService
) {

  const vm = this;

  // Exposing some constants for testing purposes
  vm.CUSTOMIZATIONS_COLUMNS = _.clone(CUSTOMIZATIONS_COLUMNS); // Cloned so that some options can be mutated
  vm.REQUIRED_COLUMNS = _.filter(TREND_COLUMNS, column => _.includes(SERIES_PANEL_REQUIRED_TREND_COLUMNS, column.key));
  vm.TREND_TOOLS = TREND_TOOLS;
  vm.EXTRA_COLUMNS = _.filter(TREND_COLUMNS,
    column => _.includes(SERIES_PANEL_EXTRA_TREND_COLUMNS, column.key));
  vm.EXTRA_CUSTOMIZATION_COLUMNS = _.filter(TREND_COLUMNS,
    column => _.includes(SERIES_PANEL_EXTRA_TREND_CUSTOMIZATION_COLUMNS, column.key));
  // Assumes that statistics with the same key share the same title, style, etc (but can have different formula)
  vm.STAT_COLUMNS = _.uniqBy(TREND_SIGNAL_STATS.concat(TREND_CONDITION_STATS), 'key');

  vm.clear = sqTrendActions.clear;
  vm.removeSelectedItems = sqTrendActions.removeSelectedItems;
  vm.removeSelectedRegion = sqTrendActions.removeSelectedRegion;
  vm.zoomToSelectedRegion = sqTrendActions.zoomToSelectedRegion;
  vm.toggleColumn = handleToggleColumn;
  vm.toggleSort = sortBy => sqTrendActions.togglePanelSort(TREND_PANELS.SERIES, sortBy);
  vm.toggleItemSelected = sqTrendActions.toggleItemSelected;
  vm.alignMeasuredItemWithMetric = sqTrendActions.alignMeasuredItemWithMetric;
  vm.isColumnEnabled = column => sqTrendStore.isColumnEnabled(TREND_PANELS.SERIES, column.key);
  vm.selectItems = sqTrendActions.selectItems;
  vm.removeItem = removeItem;
  vm.loadToolForEdit = sqInvestigateActions.loadToolForEdit;
  vm.getColumnValue = sqColumnHelper.getColumnValue;
  vm.getColumnUOM = getColumnUOM;
  vm.actionOnComponent = searchAsset;
  vm.hasAncillaries = hasAncillaries;
  vm.enabledColumns = enabledColumns;
  vm.setInvestigateItem = setInvestigateItem;
  vm.updateCustomization = updateCustomization;
  vm.ITEM_TYPES = ITEM_TYPES;
  vm.NUMBERS_ONLY_REGEX = NUMBERS_ONLY_REGEX;
  vm.addPropertiesColumn = property => sqTrendActions.addPropertiesColumn(TREND_PANELS.SERIES, property);
  vm.isPresentationMode = sqUtilities.isPresentationWorkbookMode;
  vm.toggleChartConfiguration = sqTrendActions.toggleChartConfiguration;
  vm.anySelected = anySelected;
  vm.isValueColumn = isValueColumn;
  vm.groupWithCondition = (itemId, conditionId) => sqWorksheetActions.groupSignalToCondition(itemId, conditionId);
  vm.getSelectOptions = getSelectOptions;
  vm.getSeriesPanelItem = getSeriesPanelItem;
  vm.customizationEnabled = customizationEnabled;
  vm.customizationDisabled = customizationDisabled;
  vm.updateCustomizationFromButton = updateCustomizationFromButton;
  vm.getStyleClass = getStyleClass;
  vm.formatPointValue = formatPointValue;
  vm.colorPickerColors = colorPickerColors;
  vm.colorPickerTitle = colorPickerTitle;
  vm.limitColorPicker = limitColorPicker;
  vm.getItemId = item => item.trackId || item.id;
  vm.applyTableBackground = applyTableBackground;
  vm.getAssignedSignalsForCondition = getAssignedSignalsForCondition;
  vm.canWriteItem = item => sqAuthorization.canWriteItem(item);
  vm.canReadItem = item => sqAuthorization.canReadItem(item);
  vm.isItemRedacted = item => sqRedaction.isItemRedacted(item);
  vm.hasAncillaryRedacted = hasAncillaryRedacted;
  vm.isCustomizationColumnEnabled = isCustomizationColumnEnabled;
  vm.updateDisplayItems = updateDisplayItems;

  // TODO: Update this once the details pane table is in React
  // While this panel uses an Angular table, we have to add this check to avoid having the row get (un)selected when
  // clicking the color picker swatch or the add to display icon (both of which are now React components).
  vm.selectRow = ($event, item) => {
    if ($event.target.getAttribute('data-stoppropagation') === null &&
      !(_.includes(['colorPickerButton', 'addToDisplay'], $event.target.getAttribute('data-testid'))) && (
        (vm.capsuleGroupingMode && item.itemType === vm.ITEM_TYPES.CAPSULE_SET) || !vm.capsuleGroupingMode)) {
      vm.selectItems(item, vm.items, $event);
    }
  };
  // Exposed for test
  vm.updateSelectedRegionDetails = updateSelectedRegionDetails;

  $scope.$listenTo(sqTrendStore, _.flow(setPanelVars, vm.updateSelectedRegionDetails));
  $scope.$listenTo(sqTrendSeriesStore, setPanelVars);
  $scope.$listenTo(sqTrendCapsuleSetStore, setPanelVars);
  $scope.$listenTo(sqTrendScalarStore, setPanelVars);
  $scope.$listenTo(sqWorksheetStore, setPanelVars);
  $scope.$listenTo(sqWorkbookStore, setPanelVars);
  $scope.$listenTo(sqTrendTableStore, setPanelVars);
  $scope.$listenTo(sqTrendMetricStore, setPanelVars);
  $scope.$listenTo(sqPluginStore, setPanelVars);

  /**
   * Syncs stores and view-model properties
   */
  function setPanelVars() {
    vm.sort = sqTrendStore.getPanelSort(TREND_PANELS.SERIES);
    vm.xAxisTimestamp = sqDateTime.formatTime(sqTrendStore.xValue, sqWorksheetStore.timezone);
    vm.originalItems = sqItemDecorator.sortAndDecorateItems(vm.sort, sqTrendDataHelper.getAllItems());
    // for use with ng-model (Custom Controls)
    vm.items = sqUtilities.cloneDeepOmit(vm.originalItems, ['data', 'samples']);

    vm.capsuleGroupingMode = sqWorksheetStore.capsuleGroupMode &&
      (sqTrendStore.isTrendViewCapsuleTime() || sqTrendStore.isTrendViewChainView());
    updateDisplayItems();

    if (vm.capsuleGroupingMode) {
      vm.conditions = _.filter(vm.items, { itemType: ITEM_TYPES.CAPSULE_SET });
      vm.signals = _.filter(vm.items, { itemType: ITEM_TYPES.SERIES });
      // assigned signals are part of the display items
      vm.unassignedSignals = _.filter(vm.signals, (signal: any) =>
        _.findIndex(vm.displayItems, { id: signal.id }) < 0);
      vm.hasUnassignedSignals = _.size(vm.unassignedSignals) > 0;
    }

    vm.isRegionSelected = sqTrendStore.isRegionSelected();
    vm.hideTimestamp = sqTrendStore.isTrendViewCapsuleTime();
    // In the simplified view there are no statistics, customize view, property columns
    vm.simplifiedView = _.includes([WORKSHEET_VIEW.SCATTER_PLOT, WORKSHEET_VIEW.TREEMAP], sqWorksheetStore.view.key);
    vm.allowStats = _.includes([WORKSHEET_VIEW.TREND, WORKSHEET_VIEW.TABLE], sqWorksheetStore.view.key);
    vm.isCapsuleTime = sqTrendStore.isTrendViewCapsuleTime();
    vm.showChartConfiguration = sqTrendStore.showChartConfiguration;
    vm.worksheetDisplayEdit = sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.EDIT;
    vm.pointValues = sqTrendStore.pointValues;
    _.find(vm.CUSTOMIZATIONS_COLUMNS, ['key', 'lane']).options = sqTrendStore.lanes;
    _.find(vm.CUSTOMIZATIONS_COLUMNS, ['key', 'axisAlign']).options = sqTrendStore.alignments;
    vm.propertyColumns = sqTrendStore.propertyColumns(TREND_PANELS.SERIES)
      .map(function(column) {
        return _.assign({
          style: 'string', // Indicates that no formatting is needed
          title: column.propertyName,
          shortTitle: column.propertyName
        }, column);
      });

    vm.isPluginShown = !!sqPluginStore.getPlugin(sqWorksheetStore.view.key);
    vm.allExtraColumns = vm.EXTRA_COLUMNS.concat(
      vm.EXTRA_CUSTOMIZATION_COLUMNS.filter(column => vm.isCustomizationColumnEnabled(column.key)));
    vm.statColumns = !vm.simplifiedView && vm.allowStats ? vm.STAT_COLUMNS : [];
  }

  /**
   * This function sets the displayItems property.
   * If Capsule Group Mode is enabled Conditions are displayed as the "Main" entities and grouped Signals are
   * displayed as such:
   * Condition Name
   *    -- Signal Name
   *    -- Signal Name
   */
  function updateDisplayItems() {
    vm.displayItems = vm.items;
    if (vm.capsuleGroupingMode) {
      let temp = [];
      _.forEach(vm.conditions, (condition: any) => {
        if (applyTableBackground(condition)) {
          _.assign(condition, { rowClasses: _.concat(condition.rowClasses, 'striped') });
        }
        temp.push(condition);
        const assignedSignals = [];
        _.forEach(sqWorksheetStore.conditionToSeriesGrouping[condition.id], (seriesId) => {

          const entry: any = _.assign({}, _.find(vm.items, { id: seriesId }),
            { groupStyle: condition.style, trackId: seriesId + '_' + condition.id, groupedTo: condition.id });
          if (applyTableBackground(entry)) {
            _.assign(entry, { rowClasses: _.concat(entry.rowClasses, 'striped') });
          }
          assignedSignals.push(entry);
        });
        temp = _.concat(temp, _.orderBy(assignedSignals, [vm.sort.sortBy], [vm.sort.sortAsc ? 'asc' : 'desc']));
      });

      vm.displayItems = temp;
    }

    vm.displayItems = _.map(vm.displayItems, item => (
      {
        ...item,
        translateKey: item.itemType !== ITEM_TYPES.METRIC ? item.translateKey : _.cond([
          [(metric: { definition?: { processType: ProcessTypeEnum } }) =>
            metric.definition?.processType === ProcessTypeEnum.Simple, () => 'METRIC_SIMPLE'],
          [(metric: { definition?: { processType: ProcessTypeEnum } }) =>
            metric.definition?.processType === ProcessTypeEnum.Condition, () => 'METRIC_CONDITION'],
          [(metric: { definition?: { processType: ProcessTypeEnum } }) =>
            metric.definition?.processType === ProcessTypeEnum.Continuous, () => 'METRIC_CONTINUOUS'],
          [_.stubTrue, () => 'METRIC']
        ])(item),
        // Add metric children to the items array so the display item and thresholds are taken into account when
        // determining the status. Although this could be generalized for now we're only handling metric children.
        itemAndMetricChildren: item.itemType === ITEM_TYPES.METRIC ?
          [item].concat(sqTrendDataHelper.findChildrenIn(TREND_STORES, item.id)) : [item]
      }
    ));
    vm.displayItemsAndMetricChildren = _.chain(vm.displayItems)
      .map('itemAndMetricChildren')
      .flatten()
      .value();
  }

  function handleToggleColumn(column) {
    // remove if custom property column. The user may easily add it back using properties dropdown
    if (sqTrendActions.isPropertyColumn(column)) {
      sqTrendActions.removePropertiesColumn(TREND_PANELS.SERIES, column);
    } else {
      sqTrendActions.toggleColumn(TREND_PANELS.SERIES, column.key);
    }
  }

  /**
   * Checks if item has ancillaries in scope
   */
  function hasAncillaries(item) {
    return _.some(item.allAncillaries, sqUtilities.isInScope);
  }

  /**
   * Checks if any of an item's ancillaries are redacted
   */
  function hasAncillaryRedacted(item) {
    return _.some(item.allAncillaries, function(ancillary) {
      return sqRedaction.isItemRedacted(ancillary);
    });
  }

  /**
   * Returns all enabled columns. When in Capsule Time do not return auto, min, or max.
   * If Capsule Group Mode is enabled the "Group" column is added.
   *
   * @return {Object[]} Array of enabled columns
   */
  function enabledColumns() {
    let columns = vm.REQUIRED_COLUMNS;

    if (!vm.simplifiedView && vm.showChartConfiguration) {
      columns = columns.concat(TREND_COLOR_COLUMN);
    }

    if (vm.capsuleGroupingMode) {
      columns = columns.concat(CAPSULE_TIME_COLUMNS);
    }

    if (vm.simplifiedView || !vm.showChartConfiguration) {
      // This is the default view that contains columns that can be added using the add column dropdown
      if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.TREND) {
        columns = columns.concat(TREND_COLOR_COLUMN);
      }

      let optionalColumns = vm.EXTRA_COLUMNS.concat(vm.EXTRA_CUSTOMIZATION_COLUMNS
        .filter(column => vm.isCustomizationColumnEnabled(column.key)));

      if (vm.allowStats) {
        optionalColumns = optionalColumns.concat(vm.STAT_COLUMNS);
      }

      if (!vm.simplifiedView) {
        optionalColumns = optionalColumns.concat(vm.propertyColumns);
      }

      columns = columns.concat(optionalColumns.filter(column => vm.isColumnEnabled(column)));
    } else {
      // This is the "Customize" view where in trend view the look of signals can be configured
      columns = columns.concat(vm.CUSTOMIZATIONS_COLUMNS
        .filter(column => vm.isCustomizationColumnEnabled(column.key)));
    }

    return columns;
  }

  /**
   * Searches for an asset in the sidebar.
   *
   * @param {String} id - The ID of the asset to search in the sidebar
   */
  function searchAsset(asset: any) {
    sqWorksheetActions.setBrowsePanelCollapsed(false);
    sqWorksheetActions.tabsetChangeTab('sidebar', 'search');
    sqSearchActions.exploreAsset(SEARCH_PANES.MAIN, asset.id);
  }

  /**
   * Get the unit of measure of a column for an item
   *
   * @param {Object} column - One of the columns from TREND_SIGNAL_STATS, TREND_CONDITION_STATS, or TREND_COLUMNS
   * @param {Object} item - Item for which to get the unit of measure
   * @returns {String} Unit of measure for the column. If UOM is 'string', an empty string is returned.
   */
  function getColumnUOM(column, item) {
    const uom = _.get(item, column.uomKey);
    return uom === STRING_UOM ? '' : uom;
  }

  /**
   * Determine if a customization column is enabled. A column is enabled if any item has the customization enabled
   *
   * @param property - the property to check
   */
  function isCustomizationColumnEnabled(property: string) {
    const pluginShownColumns = sqWorksheetStore.getPluginShownColumns(sqWorksheetStore.view.key, TREND_PANELS.SERIES);
    return (!vm.isPluginShown || pluginShownColumns.includes(property))
      && _.some(vm.items, item => customizationEnabled(item, property));
  }

  /**
   * Determine if a customization is enabled for an item. A control is enabled if it could be possible for a certain
   * type of item to be used.
   *
   * @param {Object} item - an item from the trend stores to check
   * @param {String} property - the property to check
   */
  function customizationEnabled(item, property) {
    const specialExclusions = [];
    if (item.itemType === ITEM_TYPES.SERIES && item.isStringSeries) {
      specialExclusions.push('axisAutoScale', 'yAxisMin', 'yAxisMax', 'yAxisType');
    }

    if (item.calculationType === TREND_TOOLS.FFT_TABLE) {
      specialExclusions.push('stack');
    }
    return !_.includes(specialExclusions, property) && _.includes(ITEM_CUSTOMIZATIONS[item.itemType], property);
  }

  /**
   * Determine if a customization should be shown as disabled for a control. A control should be disabled if it
   * could be used for the type of item, but not in this configuration
   *
   * @param {Object} column - one of CUSTOMIZATIONS_COLUMNS
   * @param {Object} item - an item from the trend stores to check
   */
  function customizationDisabled(column, item) {
    if (column.disabledIf) {
      return _.iteratee(column.disabledIf)(item);
    } else if (column.disabledIfNot) {
      return !_.iteratee(column.disabledIfNot)(item);
    } else {
      return false;
    }
  }

  /**
   * Sets a property on the specified item.
   * This function is called whenever an input field inside the details panel is changed.
   *
   * @param {String} itemId - if of the item
   * @param {String} property - property to set in the item
   */
  function updateCustomization(itemId, property) {
    const item = _.find(vm.items, ['id', itemId]) as any;
    const column = _.find(vm.enabledColumns(), ['key', property]) as any;
    const items = column.applyToAxis ? _.filter(vm.items, ['axisAlign', item.axisAlign]) : [item];
    const properties = _.pick(item, [property]) as any;
    let existing;
    if (column.doToggle) {
      properties[property] = !properties[property];
    }

    if (property === 'axisAlign') {
      // find the item(s) that area already one the same alignment, if the are not autoScaled set the autoScale
      // prop to false for the newly added item as well: vm.originalItems is used instead of vm.items so that the
      // item is not included
      existing = _.filter(vm.originalItems, ['axisAlign', item.axisAlign]);
      if ((item.isStringSeries || _.some(existing, 'isStringSeries')) && existing.length > 0) {
        sqNotifications.infoTranslate('NO_STRING_DATA_Y_AXIS_SHARING');
        item.axisAlign = _.find(vm.originalItems, ['id', item.id])['axisAlign'];
        return;
      } else {
        properties.axisAutoScale = _.get(existing[0], 'axisAutoScale', true);
      }
    }

    sqTrendActions.setCustomizationProps(_.map(items, function(item) {
      return _.assign({ id: item.id }, properties);
    }));

    sqYAxisActions.updateLaneDisplay();
  }

  /**
   * Sets a property on the specified item.
   * This function is called whenever an input field inside the details panel is changed.
   *
   * @param {String} id - if of the item
   * @param {String} property - property to set in the item
   * @param {String} value - value to set the property to
   */
  function updateCustomizationFromButton(id, property, value) {
    sqTrendActions.setCustomizationProps([{
      id,
      [property]: value
    }]);

    sqYAxisActions.updateLaneDisplay();
  }

  /**
   * Helper function that returns the assigned icon style class for an item.
   * Used to show the selected option.
   *
   * @param {String} column - the column that is being displayed.
   * @param {Object} item - the item for which the column options are retrieved for.
   * @returns {String} representing the icon class
   */
  function getStyleClass(column, item) {
    const options = vm.getSelectOptions(column, item) as any[];
    // By convention the first enabled option is the default option
    const value = !_.isNil(item[column.key]) ? item[column.key] : _.get(_.head(options), 'value');
    return _.get(_.find(options, ['value', value]), 'iconClass', '');
  }

  /**
   * Provides an Array of options. This is used by the selects in the series panel
   * that assign lanes and axis, as well as line width and line style.
   *
   * @param {String} column - the column that is being displayed.
   * @param {Object} item - the item for which the column options are retrieved for.
   * @returns {String[]|Object[]} of options.
   */
  function getSelectOptions(column, item) {
    return _.chain(column.options as any[])
      .filter((option) => {
        if (option.if) {
          return _.iteratee(option.if)(item);
        } else if (option.ifNot) {
          return !_.iteratee(option.ifNot)(item);
        } else {
          return true;
        }
      })
      .map(option => (!_.isUndefined(option.value) && column.style === 'select') ? option.value : option)
      .value();
  }

  /**
   * This function returns a cloned instance of series item. It is essential that the object is a clone so that
   * properties like lane, axisAlign and visibility can be set.
   *
   * @param {String} id - id of an item
   * @returns {Object} that is the clone of a series item
   */
  function getSeriesPanelItem(id) {
    return _.find(vm.items, { id });
  }

  /**
   * Limits the width of pointValue for string series by shortening strings longer than six characters.
   * (e.g. "ABCDEFG" --> "AB..EFG")
   *
   * @param {Object} item - an item
   * @param {Boolean} item.isStringSeries - true if string series, false otherwise
   * @param {Number|String} item.pointValue - a number or string value
   * @returns {String} the formatted pointValue
   */
  function formatPointValue(item) {
    let pointValue = item.itemType === ITEM_TYPES.SCALAR ? item.value :
      _.get(vm.pointValues, [item.id, 'pointValue']);

    if (item.isStringSeries) {
      if (_.get(pointValue, 'length') > (DISPLAY_NUMBER_LENGTH + 1)) {
        pointValue = pointValue.slice(0, 2) + '..' + pointValue.slice(3 - DISPLAY_NUMBER_LENGTH);
      }
    } else {
      pointValue = sqNumberHelper.formatNumber(pointValue, item.formatOptions);
    }

    return pointValue;
  }

  /**
   * Returns the colors for the color picker element.
   *
   * @param {Object} item - an item
   * @returns {String[]|undefined} If it is a condition and we are in treemap view then it is the list of prioritized
   *   colors, otherwise undefined so that default colors are used.
   */
  function colorPickerColors(item) {
    return item.itemType === ITEM_TYPES.CAPSULE_SET && sqWorksheetStore.view.key === WORKSHEET_VIEW.TREEMAP ?
      sqTreemapStore.priorityColors : undefined;
  }

  /**
   * Returns the title for the color picker pop-up.
   *
   * @param {Object} item - an item
   * @returns {String[]|undefined} If it is a condition and we are in treemap view then it is a custom message,
   *   otherwise undefined so that the default is used.
   */
  function colorPickerTitle(item) {
    return item.itemType === ITEM_TYPES.CAPSULE_SET && sqWorksheetStore.view.key === WORKSHEET_VIEW.TREEMAP ?
      'TREEMAP.PRIORITY_COLOR' : undefined;
  }

  /**
   * Returns a boolean that indicates if the color picker should display limited colors or not.
   * Currently only conditions in treemap view show a limited version of the color picker.
   *
   * @param {Object} item - an item
   * @returns {Boolean} true if the colors should be limited, false otherwise
   */
  function limitColorPicker(item) {
    return item.itemType === ITEM_TYPES.CAPSULE_SET && sqWorksheetStore.view.key === WORKSHEET_VIEW.TREEMAP;
  }

  /**
   * Sets the item to be investigated.
   *
   * @param {String} id - The ID of the item to investigate
   * @param {String} [tool] - One of TREND_TOOLS
   */
  function setInvestigateItem(id, tool = TREND_TOOLS.OVERVIEW) {
    sqInvestigateActions.setActiveTool(tool);
    sqInvestigateActions.setItem(id);
  }

  /**
   * Updates a string view model property that is used to display selected region start, end, and duration.
   */
  function updateSelectedRegionDetails() {
    const region = sqTrendStore.selectedRegion;
    if (vm.isRegionSelected) {
      vm.selectedRegionDetails = sqCursors.formatXLabel(region.min) + ' - ' + sqCursors.formatXLabel(region.max) +
        ' (' + sqDateTime.formatDuration(region.max - region.min, true) + ')';
    } else {
      vm.selectedRegionDetails = '';
    }
  }

  /**
   * Determine if any items are selected.
   * @return {Boolean} Whether any items are selected
   */
  function anySelected() {
    return _.some(vm.items, 'selected');
  }

  /**
   * @returns {boolean} whether the provided column is a value column
   */
  function isValueColumn(column): boolean {
    return column.style !== 'icon'
      && column.style !== 'select'
      && column.style !== 'input'
      && column.style !== 'iconSelect'
      && column.style !== 'assets'
      && column.style !== 'datasourceName'
      && column.key !== 'valueUnitOfMeasure';
  }

  /**
   * Removes an item from the series panel.
   * If the item is a capsule set all assigned signal groupings are removed.
   *
   * @param {object} item - the item to be deleted from the series panel.
   */
  function removeItem(item) {
    sqTrendActions.removeItem(item);

    if (item.itemType === ITEM_TYPES.CAPSULE_SET && sqWorksheetStore.conditionToSeriesGrouping[item.id]) {
      sqWorksheetActions.removeConditionToSeriesGrouping(item);
    } else {
      sqWorksheetActions.removeSeriesFromGrouping(item.id);
    }
  }

  /**
   * This function is used when the capsule grouping mode is enabled.
   * It returns the background color for the table so that all the signals grouped with a condition will receive the
   * same background color as that condition.
   * This logic is based on the index of the conditions as the driver for the background striping.
   *
   * @param {object} item - object defining an item that is displayed in the series panel
   */
  function applyTableBackground(item) {
    const lookUpId = item.itemType === ITEM_TYPES.CAPSULE_SET ? item.id : item.groupedTo;
    const index = _.findIndex(vm.conditions, { id: lookUpId });
    return index % 2;
  }

  /**
   * This function sets the assignedSignals and hasAssignedSignals properties. All the signals that have been
   * assigned to a condition other than the provided one (we don't want to be able to assign the same signal to the
   * same condition).
   *
   * @param {object} condition - Object representing a condition
   * @param {string} condition.id - id of the condition
   *
   */
  function getAssignedSignalsForCondition(condition) {
    vm.assignedSignals = _.filter(vm.signals, (signal: any) => {
      const items: any = _.filter(vm.displayItems, { id: signal.id });
      return _.size(items) > 0 && _.findIndex(items, { groupedTo: condition.id }) < 0;
    });

    vm.hasAssignedSignals = _.size(vm.assignedSignals) > 0;
  }
}
