import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ReportStore } from '@/reportEditor/report.store';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { ReportActions } from '@/reportEditor/report.actions';
import { Icon } from '@/hybrid/core/Icon.atom';
import _ from 'lodash';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { AssetSelection, ReportClickActions } from '@/reportEditor/report.module';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { TrackService } from '@/track/track.service';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { ItemsApi, TreesApi } from '@/sdk';
import { FormulaService } from '@/services/formula.service';
import SelectItemWrapper from '@/hybrid/core/SelectItemWrapper.organism';
import { UtilitiesService } from '@/services/utilities.service';

const reportConfigAssetSelectionBindings = bindingsDefinition({
  selection: prop<AssetSelection>(),
  onEdit: prop<(selection: AssetSelection) => void>(),
  actionWrapper: prop<(action: Function, actionType: ReportClickActions) => (...actionArgs) => any>(),
  reClickAction: prop<ReportClickActions>(),
  viewOnly: prop.optional<boolean>(),
  sqReportStore: injected<ReportStore>(),
  sqReportActions: injected<ReportActions>(),
  sqReportContent: injected<ReportContentService>(),
  sqTreesApi: injected<TreesApi>(),
  sqFormula: injected<FormulaService>(),
  sqItemsApi: injected<ItemsApi>(),
  sqTrack: injected<TrackService>(),
  sqUtilities: injected<UtilitiesService>()
});

export const ReportConfigAssetSelection: SeeqComponent<typeof reportConfigAssetSelectionBindings> = (props) => {
  const {
    sqReportStore,
    sqReportActions,
    sqReportContent,
    sqItemsApi,
    sqTrack,
    sqTreesApi,
    sqFormula,
    sqUtilities
  } = useInjectedBindings(reportConfigAssetSelectionBindings);
  const {
    selection,
    onEdit,
    actionWrapper,
    reClickAction,
    viewOnly = false
  } = props;
  const sandboxMode = useFluxPath(sqReportStore, () => sqReportStore.sandboxMode);
  const assetSelectionToContentMap = useFluxPath(sqReportStore, () => sqReportStore.assetSelectionToContentMap);

  useEffect(() => {
    let mounted = true;
    if (mounted && sandboxMode.enabled) {
      onReClick(reClickAction);
    }
    return () => mounted = false;
  }, [reClickAction]);

  const [siblings, setSiblings] = useState(null);
  const pathDepth = _.isNil(selection.assetPathDepth) ? undefined : selection.assetPathDepth;

  useEffect(() => {
    // Set up sibling options
    if (selection.asset.isRedacted) {
      setSiblings([selection.asset]);
    } else {
      sqTreesApi.getTree({ id: selection.asset.id })
        .then(({ data }) => _.last(data.item.ancestors))
        .then(parent => sqTreesApi.getTree({ id: parent.id })
          .then(output => _.chain(output.data.children)
            .filter(sibling => sqUtilities.isAsset(sibling))
            .map(sibling => sqFormula.getAssetPath(sibling, pathDepth)
              .then(path => ({ ...sibling, name: path })))
            .thru(promises => Promise.all(promises))
            .value()
            .then(siblingsWithPath => setSiblings(siblingsWithPath))
          ));
    }

  }, [selection.asset.id, pathDepth]);

  const changeSibling = (assetSelection: AssetSelection, asset: any) => {
    sqTrack.doTrack('Topic Asset Selection', `Selection Updated`);
    return sqReportActions.saveAssetSelection({ ...assetSelection, asset });
  };

  const onChangeSibling = (selectedAsset) => {
    actionWrapper(changeSibling, ReportClickActions.ChangeSibling)(selection, selectedAsset);
  };

  const isSelectionUsed = (assetSelectionId: string) =>
    !_.isEmpty(assetSelectionToContentMap[assetSelectionId]);

  const openBulkEditWithGivenAssetSelection = (assetSelection: AssetSelection) => {
    sqTrack.doTrack('Topic', 'Asset Selection Tool', 'edit - warning icon');
    sqReportActions.setShowBulkEditModal(true);
    sqReportActions.setBulkAssetSelection(assetSelection);
  };

  const refreshContentUsingAssetSelection = () => {
    sqTrack.doTrack('Topic', 'Asset Selection Tool', 'refresh content');
    sqReportContent.forceRefreshContentUsingAssetSelection(selection.selectionId);
  };

  const onClickWarning = () => {
    actionWrapper(openBulkEditWithGivenAssetSelection, ReportClickActions.Warning)(selection);
  };

  const onClickEdit = () => {
    actionWrapper((selection: AssetSelection) => {
      sqTrack.doTrack('Topic', 'Asset Selection Tool', 'edit - edit icon');
      onEdit(selection);
    }, ReportClickActions.Edit)(selection);
  };

  const onClickRefresh = () => {
    actionWrapper(refreshContentUsingAssetSelection, ReportClickActions.Refresh)(selection);
  };

  const onReClick = (action: ReportClickActions) => ({
    [ReportClickActions.None]: _.noop,
    [ReportClickActions.Edit]: onClickEdit,
    [ReportClickActions.Refresh]: onClickRefresh,
    [ReportClickActions.Warning]: onClickWarning,
    [ReportClickActions.ChangeSibling]: onChangeSibling
  }[action](action === ReportClickActions.ChangeSibling ? selection.asset : null));

  const displayThisSelection = viewOnly ? isSelectionUsed(selection.selectionId) : true;
  return displayThisSelection &&
    <div className="flexRowContainer mb5" data-testid={`assetSelection_${selection.selectionId}`}>
      <div className="flexColumnContainer mr5 pt5 pb5">
        <label className="mb0 flexFill">{selection.name}</label>
        <a className="ml15 link-no-focus link-no-underline flexColumnContainer flexCenter cursorPointer">
          {isSelectionUsed(selection.selectionId) &&
          <HoverTooltip text="REPORT.CONFIG.REFRESH_CONTENT_FOR_ASSET_SELECTION">
            <div data-testid={`assetSelectionRefreshIcon_${selection.selectionId}`}
              onClick={onClickRefresh}>
              <Icon
                icon="fc-redo fa-fw fa-sm"
                extraClassNames="cursorPointer ml5 mt2" />
            </div>
          </HoverTooltip>}
          {!isSelectionUsed(selection.selectionId) &&
          <HoverTooltip text="REPORT.CONFIG.ASSET_SELECTION_UNUSED">
            <div data-testid={`assetSelectionWarningIcon_${selection.selectionId}`}
              onClick={onClickWarning}>
              <Icon
                icon="fa-warning"
                extraClassNames="ml5 mt1"
                type="warning" />
            </div>
          </HoverTooltip>}
          <div>
            <Icon
              icon="fa-fw fa-edit"
              extraClassNames="ml6 mr2 mt2"
              testId={`assetSelectionEditIcon_${selection.selectionId}`}
              onClick={onClickEdit} />
          </div>
        </a>
      </div>
      <SelectItemWrapper
        key={`${selection.selectionId}_selectItemWrapper`}
        items={siblings}
        selected={selection.asset}
        allowClear={false}
        isMultipleSelect={false}
        loadingMetadata={null}
        dropdownWidth="50"
        cssClassName="react-select"
        noWrap={false}
        onChange={onChangeSibling}
        onRemove={_.noop}
        isGrouped={false} />
    </div>;
};
