import angular from 'angular';
import _ from 'lodash';
import { sqReportEditorFroala } from '@/reportEditor/reportEditorFroala.service';
import { sqReportEditorCKEditor } from '@/reportEditor/reportEditorCKEditor.service';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { ReportStore } from '@/reportEditor/report.store';
import { AnnotationsApi } from '@/sdk';
import { AnnotationStore } from '@/annotation/annotation.store';

angular.module('Sq.Report').factory('sqReportEditor', sqReportEditorService);

function sqReportEditorService(
  sqSystemConfiguration: SystemConfigurationService,
  sqReportStore: ReportStore,
  sqAnnotationStore: AnnotationStore
) {
  const editors = {
    ckEditor: sqReportEditorCKEditor(),
    froala: sqReportEditorFroala()
  };

  const isCkEditor = (): boolean => sqReportStore.isCkEnabled || sqAnnotationStore.isCkEnabled;

  const getEditorService = () => isCkEditor() ? editors.ckEditor : editors.froala;

  const canUpgradeToCk = () => sqSystemConfiguration.isCkEditorEnabled && !sqSystemConfiguration.isIeEnabled;

  return {
    saveReport: () => getEditorService().saveReport(),
    triggerDocumentChangedEvent: () => getEditorService().triggerDocumentChangedEvent(),
    getHtml: () => getEditorService().getHtml(),
    insertHtml: html => getEditorService().insertHtml(html),
    setHtml: (html, forceUpdate) => getEditorService().setHtml(html, forceUpdate),
    saveSelection: () => getEditorService().saveSelection(),
    restoreSelection: () => getEditorService().restoreSelection(),
    getScrollOffset: () => getEditorService().getScrollOffset(),
    setScrollOffset: offset => getEditorService().setScrollOffset(offset),
    setReportViewHtml: document => getEditorService().setReportViewHtml(document),
    setGlobalInstance: instance => getEditorService().setGlobalInstance(instance),
    getGlobalInstance: () => getEditorService().getGlobalInstance(),
    setCursorPosition: cursorPosition => getEditorService().setCursorPosition(cursorPosition),
    getCursorPosition: () => getEditorService().getCursorPosition(),
    focus: () => getEditorService().focus(),
    getPresetHtml: () => getEditorService().getPresetHtml(),
    executeCommand: options => getEditorService().executeCommand(options),
    isCkEditor,
    canUpgradeToCk
  };
}

interface ReportEditor {
  isCkEditor: () => boolean;
  canUpgradeToCk: () => boolean;
  setHtml: (html: string, forceUpdate: boolean) => void;
}

export type ReportEditorService = ReturnType<typeof sqReportEditorFroala> & ReportEditor;
