import _ from 'lodash';
import angular from 'angular';
import jQuery from 'jquery';
import { UtilitiesService } from '@/services/utilities.service';
import { NotificationsService } from '@/services/notifications.service';
import { WorkbookStore } from '@/workbook/workbook.store';
import { PERMISSIONS, APP_STATE } from '@/main/app.constants';
import { WorkbookActions } from '@/workbook/workbook.actions';

angular.module('Sq.Header')
  .controller('GetLinkCtrl', GetLinkCtrl);

function GetLinkCtrl(
  $scope: ng.IScope,
  $state: ng.ui.IStateService,
  $window: ng.IWindowService,
  $translate: ng.translate.ITranslateService,
  $timeout: ng.ITimeoutService,
  sqUtilities: UtilitiesService,
  sqNotifications: NotificationsService,
  sqWorkbookStore: WorkbookStore,
  sqWorkbookActions: WorkbookActions,
  SqACLService
) {
  const vm = this;
  vm.getWorksheetLink = getWorksheetLink;
  vm.getViewOnlyLink = getViewOnlyLink;
  vm.getPresentationLink = getPresentationLink;
  vm.copySuccessful = onCopySuccess;
  vm.previewLink = previewLink;
  // exposed for testing
  vm.syncWorkbookStore = syncWorkbookStore;
  vm.setACLForUI = setACLForUI;

  $scope.$listenTo(sqWorkbookStore, syncWorkbookStore);
  activate();

  function activate() {
    sqWorkbookActions.setWorkbookACL();
  }

  /**
   * Every time the "Get Link" link is clicked we fetch the ACL for the workbook. So we listen to ACL changes in the
   * store to ensure we always show the most up-to-date ones.
   *
   * @param {Object} e - Object containing the changes from the store
   */
  function syncWorkbookStore(e) {
    vm.effectivePermissions = sqWorkbookStore.effectivePermissions;

    if (sqUtilities.propertyChanged(e, 'acl')) {
      vm.aclEntries = _.get(sqWorkbookStore, 'acl.entries');
      setACLForUI();
    }
  }

  /**
   * This function exposes all the variables necessary for the UI display.
   * It fetches easy to read Strings for the display of who has what permission level.
   *
   * It also manges the "at least view permissions" aspect and populates the display variables accordingly.
   */
  function setACLForUI() {
    if (vm.aclEntries) {
      vm.readAccess = getDisplayString(getIdentitiesByPermission(PERMISSIONS.READ));
      vm.writeAccess = getDisplayString(getIdentitiesByPermission(PERMISSIONS.WRITE));
    }
  }

  /**
   * Returns a String for display by extracting the name property from the identity Object.
   * If no one has access to the given workbook the String "no one" is returned, if "Everyone" has access then
   * "Everyone" is returned. If more than 3 users have access it will show the names of the first 3 users as well as
   * how many others.
   *
   * @param {Object[]} identities - Object defining the identities that have access
   * @param {String} identities[].name - name of the user
   *
   * @returns {String} that represents the access for a given workbook
   */
  function getDisplayString(identities: { name: string }[]) {
    const maxDisplay = 3;
    const identityCount = identities.length;
    const remaining = identityCount - maxDisplay;
    if (identityCount === 0) {
      return $translate.instant('GET_LINK.NO_ONE');
    } else if (_.findIndex('EVERYONE', identities) > -1) {
      return $translate.instant('GET_LINK.EVERYONE');
    } else if (identityCount > maxDisplay) {
      return _.chain(identities)
        .slice(0, maxDisplay)
        .join(', ')
        .value() + ' ' + $translate.instant('GET_LINK.AND') + ' ' + remaining + ' ' + (remaining > 1 ?
        $translate.instant('GET_LINK.OTHERS') : $translate.instant('GET_LINK.OTHER'));
    } else {
      return identities.join(', ');
    }
  }

  /**
   * Returns an array of identity objects that match a given access level
   *
   * @param {String} permission - a permission
   * @returns {String[]} an array of identities that match the supplied permissions
   */
  function getIdentitiesByPermission(permission) {
    return _.chain(vm.aclEntries)
      .map(ace => _.get(ace, `permissions.${permission}`) ? ace.identity.name : '')
      .compact()
      .uniq()
      .value();
  }

  /**
   * Tiny helper to trigger a click on the "get link" to enable closing it via script.
   */
  function closeGetLink() {
    $timeout(function() {
      jQuery('#getLink').click();
    }, 1);
  }

  /**
   * Returns the worksheet link - this link gives the user whatever access they have as per ACL.
   *
   * @return {String} the link to the current worksheet.
   */
  function getWorksheetLink() {
    return $state.href($state.current.name, $state.params, { absolute: true });
  }

  /**
   * Returns the view only link. This link does not allow changes to the worksheet.
   *
   * @returns {String} the view-only link to the current worksheet.
   */
  function getViewOnlyLink() {
    return $state.href(APP_STATE.VIEW, {
      viewId: $state.params.worksheetId
    }, {
      absolute: true
    });
  }

  /**
   * Returns the presentation link. This link is also used for the display of a worksheet in the reports.
   *
   * @returns {String} the link to the current worksheet in presentation mode.
   */
  function getPresentationLink() {
    return $state.href(APP_STATE.PRESENT_WORKSHEET, {
      workbookId: $state.params.workbookId,
      worksheetId: $state.params.worksheetId
    }, {
      absolute: true
    });
  }

  /**
   * Helper function that opens a given link in a new tab. This enables the Seeq User to preview their links before
   * sharing them.
   *
   * @param {String} link - the link to open in a new tab.
   */
  function previewLink(link) {
    $window.open(link, '_blank');
  }

  /**
   * This function is called once the link has been successfully copied to the clipboard.
   * Closes the dropdown.
   */
  function onCopySuccess() {
    closeGetLink();
    sqNotifications.successTranslate('COPY_URL.SUCCESS');
  }
}
