import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import { useKey } from '@/hybrid/core/useKey.hook';
import { intelligentlyTruncate } from '@/hybrid/core/utilities';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';

interface EditableTextProps {
  value: string;
  onUpdate: (newText: string) => void;
  maxDisplayChars?: number;
  id?: string;
  testId?: string;
  forceEdit?: boolean;
  autoWidth?: boolean;
  allowEditing?: boolean;
  allowEmptyValue?: boolean;
  textClasses?: string; // extra classes to add to the text element (while not in editing mode)
  inputClasses?: string; // extra classes to add to the input element (while in editing mode)
}

/**
 * Input field that displays as text until clicked (see usage, e.g. DateTimeEntry)
 */
export const EditableText: React.FunctionComponent<EditableTextProps> = (props) => {
  const {
    value = '',
    autoWidth = false,
    onUpdate,
    textClasses,
    inputClasses,
    id,
    testId,
    allowEditing = true,
    allowEmptyValue = false,
    forceEdit = undefined,
    maxDisplayChars = -1
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const [width, setWidth] = useState(100);

  const enterKey = useKey(13);
  useEffect(() => {
    if (enterKey && newValue !== value) {
      updateInput();
    }
  }, [enterKey]);

  useEffect(() => {
    setNewValue(value);
    setWidth((document.querySelector(`p#${id}`) as HTMLElement)?.offsetWidth ?? 100);
  }, [value]);

  useEffect(() => {
    if (!_.isUndefined(forceEdit) && allowEditing) {
      setIsEditing(forceEdit);
    }
  }, [forceEdit]);

  const updateInput = () => {
    setIsEditing(false);
    if (value !== newValue && (!_.isEmpty(newValue) || (_.isEmpty(newValue) && allowEmptyValue))) {
      onUpdate(newValue);
      // If the update fails and the value stays the same, the useEffect won't trigger, so manually set it to the
      // previous value here. If the update is valid, the value will change triggering the useEffect overriding this.
      setNewValue(value);
    }
  };

  const renderValue = (
    <HoverTooltip text={maxDisplayChars !== -1 && value?.length > maxDisplayChars && value}>
      <p id={id} data-testid={`valueInput_${testId}`}
        className={classNames('mb0', 'specEditableText', { 'cursorText staticTextInput': allowEditing }, textClasses)}
        onClick={() => allowEditing && setIsEditing(true)}>
        {maxDisplayChars > -1 ? intelligentlyTruncate(value, maxDisplayChars) : value}
      </p>
    </HoverTooltip>
  );

  const getCustomWidth = () => {
    const newValueLength = newValue.length * 8;
    return newValueLength > 100 ? 100 : newValueLength;
  };

  const renderEditableText = (
    <Form.Control
      type="text"
      id={id}
      data-testid={`editableInput_${testId}`}
      className={classNames('specEditableText', inputClasses)}
      style={!autoWidth ? { width } : { width: getCustomWidth() }}
      bsPrefix="editableTextInput"
      value={newValue}
      size="sm"
      onFocus={e => e.target.select()}
      autoFocus={true}
      onChange={e => setNewValue(e.target.value)}
      onBlur={updateInput}
    />
  );

  return isEditing ? renderEditableText : renderValue;
};
