import _ from 'lodash';
import angular from 'angular';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { InvestigateStore } from '@/investigate/investigate.store';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { TrendActions } from '@/trendData/trend.actions';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { AncillariesPanelStore } from '@/investigate/ancillariesPanel.store';
import { AncillariesPanelActions } from '@/investigate/ancillariesPanel.actions';
import { UtilitiesService } from '@/services/utilities.service';
import { NotificationsService } from '@/services/notifications.service';
import { ITEM_CHILDREN_TYPES, ITEM_TYPES } from '@/trendData/trendData.module';
import { API_TYPES, DISPLAY_MODE, MAX_NAME_LENGTH } from '@/main/app.constants';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { ToolPanelHelperService } from '@/services/toolPanelHelper.service';
import { RedactionService } from '@/services/redaction.service';
import { AuthorizationService } from '@/services/authorization.service';
import { AncillariesApi } from '@/sdk';

export const ANCILLARY_TYPES = { PAIR: 'PAIR', SINGLE: 'SINGLE' };
export const ANCILLARY_PAIR_TYPES = { LOWER: 0, UPPER: 1 };

angular.module('Sq.Investigate')
  .controller('AncillariesPanelCtrl', AncillariesPanelCtrl);

function AncillariesPanelCtrl(
  $scope: ng.IScope,
  $state: ng.ui.IStateService,
  sqInvestigateActions: InvestigateActions,
  sqInvestigateStore: InvestigateStore,
  sqTrendDataHelper: TrendDataHelperService,
  sqTrendActions: TrendActions,
  sqTrendCapsuleSetStore: TrendCapsuleSetStore,
  sqAncillariesPanelStore: AncillariesPanelStore,
  sqAncillariesPanelActions: AncillariesPanelActions,
  sqUtilities: UtilitiesService,
  sqNotifications: NotificationsService,
  sqToolPanelHelper: ToolPanelHelperService,
  sqRedaction: RedactionService,
  sqAuthorization: AuthorizationService,
  sqAncillariesApi: AncillariesApi
) {
  const vm = this;
  vm.TYPES = ANCILLARY_TYPES;
  vm.MAX_NAME_LENGTH = MAX_NAME_LENGTH;
  vm.PAIR_TYPES = ANCILLARY_PAIR_TYPES;
  vm.TYPE_NAMES = {};
  vm.TYPE_NAMES[vm.TYPES.PAIR] = { select: 'ANCILLARIES.BOUNDARY', signal: 'ANCILLARIES.UPPER_SIGNAL' };
  vm.TYPE_NAMES[vm.TYPES.SINGLE] = { select: 'ANCILLARIES.OTHER', signal: 'ANCILLARIES.RELATED_ITEM' };
  vm.ITEM_TYPES = ITEM_TYPES;
  vm.PAIR_ITEM_TYPES = [ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR];
  vm.SINGLE_ITEM_TYPES = [ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR, ITEM_TYPES.CAPSULE_SET];
  vm.DISPLAY_MODE = DISPLAY_MODE;
  // Store the value of scopedTo as ancillaries aren't trend items, so they can't be stored in the normal manner
  vm.scopedTo = $state.params.workbookId;

  vm.close = sqInvestigateActions.close;
  vm.add = _.partial(sqInvestigateActions.setDisplayMode, DISPLAY_MODE.NEW);
  vm.closeForm = _.partial(sqInvestigateActions.setDisplayMode, DISPLAY_MODE.RESULTS);
  vm.setName = sqAncillariesPanelActions.setName;
  vm.setType = sqAncillariesPanelActions.setType;
  vm.setItem1 = sqAncillariesPanelActions.setItem1;
  vm.setItem2 = sqAncillariesPanelActions.setItem2;
  vm.gotoReferencePanel = sqAncillariesPanelActions.gotoReferencePanel;
  vm.workbookId = $state.params.workbookId;
  vm.negate = _.negate;
  vm.hasValidItemParameters = sqToolPanelHelper.hasValidItemParameters;
  vm.setInvestigateItem = setInvestigateItem;
  vm.openItemProperties = openItemProperties;
  vm.verifyUniqueName = verifyUniqueName;
  vm.toggleDisplay = toggleDisplay;
  vm.isDisplayed = isDisplayed;
  vm.hasCondition = hasCondition;
  vm.save = save;
  vm.remove = remove;
  vm.edit = edit;
  vm.isRedacted = item => sqRedaction.isItemRedacted(item);
  vm.canWriteItem = item => (sqAuthorization.canWriteItem(item) && !sqRedaction.isItemRedacted(item));

  $scope.$listenTo(sqAncillariesPanelStore, setAncillaryVars);
  $scope.$listenTo(sqInvestigateStore, setInvestigateVars);

  /**
   * Syncs the sqAncillariesPanelStore and view-model properties
   */
  function setAncillaryVars() {
    vm.allAncillaries = sqAncillariesPanelStore.ancillaries;
    vm.ancillaries = _.filter(vm.allAncillaries, 'isInScope');
    vm.ancillary = {
      id: sqAncillariesPanelStore.id,
      name: sqAncillariesPanelStore.name,
      type: sqAncillariesPanelStore.type,
      item1: sqAncillariesPanelStore.item1,
      item2: sqAncillariesPanelStore.item2,
      scopedTo: vm.scopedTo
    };

    vm.ancillaryItems = _.compact([_.get(vm.ancillary, 'item1'), _.get(vm.ancillary, 'item2')]);
  }

  /**
   * Syncs sqInvestigateStore and view-model properties
   *
   * @param {Object} e - The baobab change event
   */
  function setInvestigateVars(e) {
    vm.item = sqInvestigateStore.item;
    vm.itemPresent = !_.isEmpty(vm.item);
    vm.displayMode = sqInvestigateStore.displayMode;
    if (_.isEmpty(e) || sqUtilities.propertyChanged(e, 'item')) {
      if (!_.isEmpty(vm.item)) {
        sqAncillariesPanelActions.fetchAncillaries(vm.item.id);
      }
    }
  }

  /**
   * Sets the item being investigated. Only triggers if the item has changed to avoid it being triggered extra times
   * during rehydration.
   *
   * @param {Object} item - The item
   * @param {String} item.id - The id of the item
   */
  function setInvestigateItem(item) {
    if (sqInvestigateStore.item.id !== item.id) {
      sqInvestigateActions.setItem(item.id);
      vm.closeForm();
    }
  }

  /**
   * Sets the item being investigated and opens item properties
   *
   * @param {Object} item - The item to investigate in the properties panel
   */
  function openItemProperties(item) {
    sqInvestigateActions.setNonStoreItem(item);
    sqInvestigateActions.setActiveTool(TREND_TOOLS.PROPERTIES);
    vm.closeForm();
  }

  /**
   * Angular form validation that ensures the name of the ancillary is unique for the current item. If the ancillary
   * is in the global scope then the name must be unique across all ancillaries for the item, otherwise it is only
   * required to be unique for other items scoped to this workbook.
   *
   * @param {Object} ancillary - The ancillary being entered by the user
   */
  function verifyUniqueName(ancillary) {
    vm.form.name.$setValidity('unique', !_.chain(_.isEmpty(ancillary.scopedTo) ? vm.allAncillaries : vm.ancillaries)
      .reject(['id', vm.ancillary.id])
      .map('name')
      .map(_.toLower)
      .includes(_.toLower(ancillary.name))
      .value());
  }

  /**
   * Toggles the display of an ancillary on the chart.
   *
   * @param {string} itemId - id of the item that has ancillaries
   * @param {Object} ancillary - The ancillary to toggle on or off
   */
  function toggleDisplay(itemId: string, ancillary) {
    if (vm.isDisplayed(itemId, ancillary)) {
      if (hasCondition(ancillary)) {
        sqTrendActions.removeItem(sqTrendCapsuleSetStore.findItem(_.get(ancillary, 'item1.id')));
      } else {
        sqTrendActions.removeItems(displayedAncillaryStoreItems(itemId, ancillary));
      }
    } else {
      if (hasCondition(ancillary)) {
        sqTrendActions.addItem(ancillary.item1);
      } else {
        sqTrendActions.addAncillary(itemId, ancillary);

        // For consistency with past versions (before R21.0.40), remove the items from the details pane as the
        // ancillaries are enabled. Users can add them back if they'd like
        sqTrendActions.removeItems(_.filter(
          sqTrendDataHelper.getAllItems(),
          item => ancillaryIdsContain(ancillary, item.id)
        ));
      }
    }
  }

  /**
   * Get all the store items that represents ancillary items for an item
   *
   * @param {string} itemId - id of the item that has ancillaries
   * @param {Object} ancillary - ancillary item to check
   * @returns {boolean} true if the storeItem represents the ancillary
   */
  function displayedAncillaryStoreItems(itemId: string, ancillary) {
    return _.chain(sqTrendDataHelper.getAllChildItems({ itemChildrenTypes: [ITEM_CHILDREN_TYPES.ANCILLARY] }))
      .filter(['isChildOf', itemId])
      .filter(item => ancillaryIdsContain(ancillary, item.interestId))
      .value();
  }

  /**
   * Test if the ancillary id or one of the ancillary item ids matches the id
   *
   * @param {Object} ancillary - the ancillary with ids to check
   * @param {string} testId - the id to check against
   * @returns {boolean} true if the testId is related to the ancillary or ancillary items
   */
  function ancillaryIdsContain(ancillary, testId) {
    return _.chain([ancillary, ancillary.item1, ancillary.item2])
      .compact()
      .map('id')
      .some(id => id === testId)
      .value();
  }

  /**
   * Determines if an ancillary is displayed on the chart.
   *
   * @param {string} itemId - id of the item that has ancillaries
   * @param {Object} ancillary - The ancillary to check
   * @returns {Boolean} True if it is on the chart, false otherwise
   */
  function isDisplayed(itemId: string, ancillary) {
    if (hasCondition(ancillary)) {
      return !!sqTrendCapsuleSetStore.findItem(ancillary.item1.id);
    } else {
      return displayedAncillaryStoreItems(itemId, ancillary).length > 0;
    }
  }

  /**
   * Determines if the ancillary has a condition instead of a signal
   *
   * @param {Object} ancillary - The ancillary to check
   * @returns {Boolean} True if it's item is a condition, false otherwise
   */
  function hasCondition(ancillary) {
    return _.get(ancillary, 'item1.type') === API_TYPES.CALCULATED_CONDITION;
  }

  /**
   * Removes an existing ancillary.
   */
  function remove() {
    vm.ancillary.isRemoved = true;
    vm.save();
  }

  /**
   * Edits an existing ancillary
   *
   * @param {Object} ancillary - The ancillary to edit
   */
  function edit(ancillary) {
    vm.scopedTo = ancillary.scopedTo;
    sqAncillariesPanelActions.edit(ancillary);
  }

  /**
   * Creates, updates, or deletes the current ancillary via the API, refreshes the list of ancillaries, and turns
   * on the new ancillary in the chart.
   */
  function save() {
    let promise;
    const originalAncillary = _.find(vm.ancillaries, ['id', vm.ancillary.id]);
    const data = _.pick(vm.ancillary, ['name', 'scopedTo']) as any;
    data.itemId = vm.item.id;
    if (vm.ancillary.type === vm.TYPES.SINGLE) {
      data.ancillaries = [{ id: vm.ancillary.item1.id }];
    } else {
      data.ancillaries = _.chain(['item1', 'item2'])
        .map(function(property) {
          const item = _.get(vm.ancillary, property) as { id: string };
          const order = property === 'item1' ? vm.PAIR_TYPES.UPPER : vm.PAIR_TYPES.LOWER;
          if (item) {
            return { id: item.id, order };
          }
        })
        .compact()
        .value();
    }

    if (vm.ancillary.id) {
      if (vm.ancillary.isRemoved) {
        promise = sqAncillariesApi.archiveAncillary({ id: vm.ancillary.id })
          .then(() => vm.ancillary.id);
      } else {
        promise = sqAncillariesApi.updateAncillary(data, { id: vm.ancillary.id })
          .then(() => vm.ancillary.id);
      }
    } else {
      promise = sqAncillariesApi.createAncillary(data)
        .then(({ data }) => data.id);
    }

    promise
      .then(ancillaryId =>
        sqAncillariesPanelActions.fetchAncillaries(vm.item.id)
          .then(() => {
            const allAncillaries = sqAncillariesPanelStore.ancillaries;
            const newAncillary = _.find(allAncillaries, ['id', ancillaryId]);
            sqTrendActions.setTrendItemProps(vm.item.id, { allAncillaries });
            if (originalAncillary && vm.isDisplayed(vm.item.id, originalAncillary)) {
              vm.toggleDisplay(vm.item.id, originalAncillary);
            }

            if (newAncillary && !vm.isDisplayed(vm.item.id, newAncillary)) {
              vm.toggleDisplay(vm.item.id, newAncillary);
            }
          })
      )
      .then(vm.closeForm)
      .catch(sqNotifications.apiError);
  }
}
