import angular from 'angular';
import template from './maxCapsuleDurationRemoved.component.html';

angular.module('Sq.TrendViewer').component('sqMaxCapsuleDurationRemoved', {
  template,
  controller: 'MaxCapsuleDurationRemovedCtrl',
  bindings: {
    item: '<'
  }
});
