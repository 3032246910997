import angular from 'angular';

const dependencies = [
  'Sq.Workbench',
  'Sq.Services.Socket'
];

/**
 * The Sq.Footer module contains all functionality related to the workbench footer panel
 */
angular.module('Sq.Footer', dependencies);
