/**
 * Displays the active view and allows the user to choose the view that should be displayed from the list of available
 * views.
 */
import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Dropdown } from 'react-bootstrap';
import { Icon } from '@/hybrid/core/Icon.atom';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { WorksheetViewService } from '@/worksheet/worksheetView.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { TrackService } from '@/track/track.service';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { SVG_PATH } from '@/worksheet/worksheet.module';

const worksheetViewSelectorBindings = bindingsDefinition({
  sqTrack: injected<TrackService>(),
  sqWorksheetActions: injected<WorksheetActions>(),
  sqWorksheetView: injected<WorksheetViewService>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  extraClassNames: prop.optional<string>()
});

export const WorksheetViewSelector: SeeqComponent<typeof worksheetViewSelectorBindings> = (props) => {
  const {
    sqTrack,
    sqWorksheetActions,
    sqWorksheetView,
    sqWorksheetStore
  } = useInjectedBindings(worksheetViewSelectorBindings);
  const { extraClassNames } = props;
  const { view } = useFlux(sqWorksheetStore);
  const { t } = useTranslation();
  const setViewAndTrack = (view) => {
    sqTrack.doTrack('change view', t(view.name));
    sqWorksheetActions.setView(view.key);
  };
  const isSvgIcon = icon => _.startsWith(icon, SVG_PATH);
  const getSvgIconPath = icon => isSvgIcon(icon) ? icon.substr(SVG_PATH.length) : '';
  const renderIcon = (icon, extraIconClassNames, svgClassNames) => isSvgIcon(icon)
    ? <svg xmlns="http://www.w3.org/2000/svg" className={svgClassNames} viewBox="0 0 19 19">
      <path d={getSvgIconPath(icon)} />
    </svg>
    : <Icon icon={icon} extraClassNames={extraIconClassNames} type="inherit" />;

  const views = _.filter(sqWorksheetView.views(), view => !view.hideFromViewSelector);

  const dropdownItems = _.map(views, view => (
    <Dropdown.Item
      key={view.key}
      onClick={(e) => {
        e.preventDefault();
        setViewAndTrack(view);
      }}
      className="mb10"
    >
      {renderIcon(view.icon, 'fa-xlg mr10', 'viewSelectorSvg viewSelectorSvgDropdown')}
      {t(view.name)}
    </Dropdown.Item>
  ));

  return (
    <Dropdown
      className={classNames(extraClassNames, 'flexRowContainer worksheetViewSelector mr5')}
      onClick={e => e.stopPropagation()}
    >
      <Dropdown.Toggle
        data-testid="worksheetViewSelectorToggle"
        className="flexColumnContainer flexCenter"
        bsPrefix="sq-btn">
        {renderIcon(view.icon, 'icon fa-xlg flexNoGrowNoShrink', 'viewSelectorSvg')}
        <Icon icon="fa-chevron-down" extraClassNames="flexNoGrowNoShrink pl5" type="inherit" />
      </Dropdown.Toggle>
      <Dropdown.Menu>{dropdownItems}</Dropdown.Menu>
    </Dropdown>
  );
};

export const sqWorksheetViewSelector = angularComponent(worksheetViewSelectorBindings, WorksheetViewSelector);
