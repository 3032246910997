import _ from 'lodash';
import React, { MouseEvent } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import {
  ConditionTableData,
  ConditionTableHeader,
  TableBuilderColumnFilter,
  TableBuilderHeaders,
  TableBuilderSort
} from '@/hybrid/tableBuilder/tableBuilder.store';
import { TableBuilderColumnType, TableBuilderHeaderType } from '@/hybrid/tableBuilder/tableBuilder.module';
import { TableBuilderTextCell } from '@/hybrid/tableBuilder/tableComponents/TableBuilderTextCell.atom';
import { TableBuilderDataCell } from '@/hybrid/tableBuilder/tableComponents/TableBuilderDataCell.atom';
import { TableBuilderHelperService } from '@/hybrid/tableBuilder/tableBuilderHelper.service';
import { TableBuilderRow } from '@/hybrid/tableBuilder/tableComponents/TableBuilderRow.molecule';
import { TableBuilderTextHeader } from '@/hybrid/tableBuilder/tableComponents/TableBuilderTextHeader.atom';
import { TableBuilderDataHeader } from '@/hybrid/tableBuilder/tableComponents/TableBuilderDataHeader.atom';
import { TableBuilderHeaderRow } from '@/hybrid/tableBuilder/tableComponents/TableBuilderHeaderRow.molecule';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { TableTextFormatterIF } from '@/hybrid/tableBuilder/TableBuilder.page';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.module';
import { TextHeaderMenuActions } from '@/hybrid/tableBuilder/tableComponents/TableBuilderTextHeaderMenu.atom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TableBuilderNoResultsRow } from '@/hybrid/tableBuilder/TableBuilderNoResultsRow.atom';
import { SeeqNames } from '@/main/app.constants.seeqnames';

export const tableBuilderConditionTableBindings = bindingsDefinition({
  sqTableBuilderHelper: injected<TableBuilderHelperService>(),
  tableData: prop<ConditionTableData>(),
  isLoading: prop<boolean>(),
  headers: prop<TableBuilderHeaders>(),
  columns: prop<{ propertyAndStatColumns: any[], columns: any[] }>(),
  isTransposed: prop<boolean>(),
  isStriped: prop<boolean>(),
  isPresentationMode: prop<boolean>(),
  canEdit: prop<boolean>(),
  timezone: prop<{ name: string }>(),
  setCellText: prop<(key: string, value: string, id?: string) => void>(),
  setHeaderText: prop<(columnKey: string, text: string) => void>(),
  setColumnFilter: prop<(key: string, filter: TableBuilderColumnFilter) => void>(),
  itemFilters: prop<{ [id: string]: { filter: TableBuilderColumnFilter } }>(),
  columnToThresholds: prop<Object>(),
  distinctStringValueMap: prop<{ [columnKey: string]: string[] }>(),
  textFormatter: prop<TableTextFormatterIF>(),
  moveColumn: prop<(key: string, newKey: string) => void>(),
  canSort: prop<boolean>(),
  itemSorts: prop<{ [id: string]: TableBuilderSort }>(),
  maxSortLevel: prop<number>(),
  sortByColumn: prop<(key: string, direction: string) => void>(),
  removeColumn: prop<(key: string) => void>(),
  displayMetricOnTrend: prop<(metricId: string, formulaItemId: string, start: number, end: number,
    event: MouseEvent) => void>(),
  isContent: prop.optional<boolean>()
});

export const TableBuilderConditionTable: SeeqComponent<typeof tableBuilderConditionTableBindings> = (props) => {
  const {
    sqTableBuilderHelper
  } = useInjectedBindings(tableBuilderConditionTableBindings);

  const {
    tableData,
    isLoading,
    headers,
    columns: allColumns,
    isTransposed,
    isStriped,
    isPresentationMode,
    canEdit,
    timezone,
    setCellText,
    setHeaderText,
    setColumnFilter,
    itemFilters,
    columnToThresholds,
    distinctStringValueMap,
    textFormatter,
    itemSorts,
    canSort,
    maxSortLevel,
    sortByColumn,
    moveColumn,
    removeColumn,
    displayMetricOnTrend
  } = props;
  const { propertyAndStatColumns, columns } = allColumns;

  const { t } = useTranslation();

  const hasUnitColumn = _.some(columns, column => column.key === COLUMNS_AND_STATS.valueUnitOfMeasure.key);
  const showHeaders = (canEdit && columns.length > 0) || headers.type !== TableBuilderHeaderType.None;
  const isStartOrEndColumn = column => _.includes([COLUMNS_AND_STATS.startTime.key, COLUMNS_AND_STATS.endTime.key],
    column?.key);

  const renderTextHeader = (column, columnIndex, key) => (
    <TableBuilderTextHeader
      textValue={column.header ?? (column.key === COLUMNS_AND_STATS.name.key ? '' : t(column.shortTitle))}
      isInput={true}
      key={key}
      columnIndex={columnIndex}
      columnKey={column.key}
      onTextChange={value => setCellText(column.key, value)}
      columnBackgroundColor={column.backgroundColor}
      columnTextAlign={column.textAlign}
      columnTextColor={column.textColor}
      columnTextStyle={column.textStyle}
      headerBackgroundColor={column.headerBackgroundColor}
      headerTextAlign={column.headerTextAlign}
      headerTextColor={column.headerTextColor}
      headerTextStyle={column.headerTextStyle}
      canEdit={canEdit}
      isTransposed={isTransposed}
      menuActions={getMenuActionsForTextHeader()}
      textFormatter={textFormatter}
      sort={{
        canSort,
        maxSortLevel,
        sortDirection: column.sort?.direction,
        sortLevel: column.sort?.level,
        sortByColumn
      }}
      showMove={column.key !== COLUMNS_AND_STATS.name.key}
      moveColumn={moveColumn}
      removeColumn={removeColumn}
    />
  );

  const renderAlternateTextHeader = (header, column, statOrPropertyColumn, headerIndex, key) => (
    <TableBuilderTextHeader
      textValue={statOrPropertyColumn ? statOrPropertyColumn.header ?? header.name :
        getTextValueForHeader(header, column)}
      isInput={true}
      key={key}
      columnIndex={headerIndex}
      columnKey={statOrPropertyColumn?.key ?? header.key}
      onTextChange={value => statOrPropertyColumn && setHeaderText(statOrPropertyColumn.key, value)}
      headerBackgroundColor={column.backgroundColor}
      headerTextAlign={column.textAlign}
      headerTextColor={column.textColor}
      headerTextStyle={column.textStyle}
      canEdit={statOrPropertyColumn && canEdit}
      isTransposed={!isTransposed}
      isStringColumn={header.isStringColumn}
      isFilterDisabled={isPresentationMode}
      distinctStringValues={distinctStringValueMap[statOrPropertyColumn?.key ?? header.key]}
      filterHelpText={statOrPropertyColumn?.key === SeeqNames.Properties.Duration ?
        'TABLE_BUILDER.DURATION_IN_SECONDS' : undefined}
      thresholds={columnToThresholds[header.key]}
      menuActions={getMenuActionsForAlternativeHeader(statOrPropertyColumn)}
      sort={{
        canSort,
        maxSortLevel,
        sortDirection: statOrPropertyColumn ? statOrPropertyColumn?.sort?.direction :
          itemSorts[header.key]?.sort?.direction,
        sortLevel: statOrPropertyColumn ? statOrPropertyColumn?.sort?.level : itemSorts[header.key]?.sort?.level,
        sortByColumn
      }}
      setColumnFilter={setColumnFilter}
      columnFilter={statOrPropertyColumn ? statOrPropertyColumn.filter : itemFilters[header.key]?.filter}
      removeColumn={statOrPropertyColumn ? removeColumn : undefined}
      moveColumn={statOrPropertyColumn ? moveColumn : undefined}
      showMove={!isStartOrEndColumn(statOrPropertyColumn)}
    />);

  /**
   * Returns the TextHeaderMenuActions for standard text headers (for the Name/Unit of Measure columns)
   * Should include [Style, Rename, Remove] in edit mode OR an empty array (no actions) if not in edit mode
   */
  const getMenuActionsForTextHeader = () => canEdit ?
    [TextHeaderMenuActions.Style, TextHeaderMenuActions.Rename, TextHeaderMenuActions.Remove] : [];

  /**
   * Returns the TextHeaderMenuActions for alternative text headers (for the metric/stat/property rows)
   * For metrics, should include [Filter] if we're not in presentation mode, or [] in presentation mode.
   * For stats/properties, should include [Rename, Filter, Remove] in edit mode, or [Filter] in view-only mode, or
   * nothing in presentation mode.
   *
   * @param [statOrPropertyColumn] - the stat or property column for the table column (optional)
   */
  const getMenuActionsForAlternativeHeader = (statOrPropertyColumn?) => {
    const menuActions = [];
    if (!isPresentationMode) {
      menuActions.push(TextHeaderMenuActions.Sort);
      if (!isStartOrEndColumn(statOrPropertyColumn)) {
        menuActions.push(TextHeaderMenuActions.Filter);
      }
    }
    if (statOrPropertyColumn && canEdit) {
      menuActions.push(TextHeaderMenuActions.Rename, TextHeaderMenuActions.Remove);
    }
    return menuActions;
  };

  const getTextValueForHeader = (header: ConditionTableHeader, column) => _.cond([
    [_.matches({ type: TableBuilderColumnType.Text }), (column: any) => column.cells && column.cells[header.key]],
    [_.matches({ key: COLUMNS_AND_STATS.name.key }), () => header.name],
    [_.matches({ key: COLUMNS_AND_STATS.valueUnitOfMeasure.key }), () => header.units]
  ])(column);

  // Header row. One cell for each custom column and for each capsule
  const createHeaderRow = () => {
    let columnIndex = 0;
    const cells = _.map(columns, (column) => {
        const header = renderTextHeader(column, columnIndex, columnIndex);
        columnIndex++;
        return header;
      })
      .concat(_.map(tableData.capsules, capsule =>
        <TableBuilderDataHeader
          headerValue={sqTableBuilderHelper.formatHeader(headers, capsule.property, capsule.startTime, capsule.endTime,
            timezone)}
          key={columnIndex++}
        />));

    return <TableBuilderHeaderRow>{cells}</TableBuilderHeaderRow>;
  };

  // Header rows for transposed version. One row per "column" that is enabled.
  const createTransposedHeaderRows = () => _.map(columns, (row, headerIndex) => {
    let columnIndex = 0;
    const cells: JSX.Element[] = [];
    if (showHeaders) {
      cells.push(renderTextHeader(row, headerIndex, columnIndex));
      columnIndex++;
    }

    _.forEach(tableData.headers, (header) => {
      const propertyOrStatColumn = _.find(propertyAndStatColumns, { key: header.key });
      cells.push(row.key === COLUMNS_AND_STATS.name.key ?
        renderAlternateTextHeader(header, row, propertyOrStatColumn, columnIndex, columnIndex) :
        <TableBuilderTextCell
          key={columnIndex}
          columnIndex={columnIndex}
          textValue={getTextValueForHeader(header, row)}
          onTextChange={value => setCellText(row.key, value, header.key)}
          style={sqTableBuilderHelper.computeCellStyle(row.backgroundColor, row.textColor, row.textStyle,
            row.textAlign)}
          canEditCellText={canEdit && row.type === TableBuilderColumnType.Text}
        />);
      columnIndex++;
    });

    return <TableBuilderHeaderRow key={headerIndex}>{cells}</TableBuilderHeaderRow>;
  });

  const renderDataCell = (header, capsule, value, maybeStripedColor, key, columnIndex) => (
    <TableBuilderDataCell
      onClick={(event: MouseEvent) => value.formulaItemId &&
        displayMetricOnTrend(value.formulaItemId, value.itemId, capsule.startTime, capsule.endTime, event)}
      textValue={value.value}
      units={header.units}
      hasUnitColumn={hasUnitColumn}
      columnIndex={columnIndex}
      key={key}
      extraClassNames={value.formulaItemId ? 'cursorPointer text-underline-onhover' : ''}
      style={sqTableBuilderHelper.computeCellColors(
        _.isNil(value.priorityColor) || value.priorityColor === '#ffffff' ? maybeStripedColor : value.priorityColor)}
    />
  );

  const renderTimeCell = (header, headers, capsule, maybeStripedColor, key, columnIndex) => (
    <TableBuilderDataCell
      textValue={sqTableBuilderHelper.formatHeader({
          ...headers,
          type: header.key === COLUMNS_AND_STATS.startTime.key ? TableBuilderHeaderType.Start : TableBuilderHeaderType.End
        },
        capsule.property,
        capsule.startTime,
        capsule.endTime,
        timezone
      )}
      columnIndex={columnIndex}
      style={sqTableBuilderHelper.computeCellColors(maybeStripedColor)}
      key={key}
    />);

  // Main table rows. One row per statistic with values per capsule
  const createTableRows = () => {
    const rows = _.map(tableData.headers, (header, rowIndex) => {
      const maybeStripedColor = sqTableBuilderHelper.getStripedColor(isStriped, rowIndex);
      let columnIndex = 0;
      const propertyOrStatColumn = _.find(propertyAndStatColumns, { key: header.key });
      const cells: JSX.Element[] = _.map(columns, (column) => {
          const cell = column.key === COLUMNS_AND_STATS.name.key ?
            renderAlternateTextHeader(header, column, propertyOrStatColumn, rowIndex, columnIndex) :
            <TableBuilderTextCell
              key={columnIndex}
              columnIndex={columnIndex}
              textValue={getTextValueForHeader(header, column)}
              onTextChange={value => setCellText(column.key, value, header.key)}
              style={sqTableBuilderHelper.computeCellStyle(column.backgroundColor, column.textColor, column.textStyle,
                column.textAlign, undefined, maybeStripedColor)}
              canEditCellText={canEdit && column.type === TableBuilderColumnType.Text}
            />;
          columnIndex++;
          return cell;
        })
        .concat(_.map(tableData.capsules, (capsule) => {
          const cell = isStartOrEndColumn(header) ?
            renderTimeCell(header, headers, capsule, maybeStripedColor, columnIndex, columnIndex) :
            renderDataCell(header, capsule, capsule.values[rowIndex], maybeStripedColor, columnIndex, columnIndex);
          columnIndex++;
          return cell;
        }));

      return <TableBuilderRow key={rowIndex}>{cells}</TableBuilderRow>;
    });

    if (!isLoading && _.isEmpty(tableData.capsules)) {
      rows.push(<TableBuilderNoResultsRow key={tableData.headers.length} colspan={columns.length} />);
    }

    return rows;
  };

  // Main table rows for transposed table. One row per capsule.
  const createTransposedTableRows = () => {
    if (!isLoading && _.isEmpty(tableData.capsules)) {
      const colspan = (showHeaders ? 1 : 0) + tableData.headers.length;
      return <TableBuilderNoResultsRow colspan={colspan} />;
    }

    return _.map(tableData.capsules, (capsule, rowIndex) => {
      let columnIndex = 0;
      const maybeStripedColor = sqTableBuilderHelper.getStripedColor(isStriped, rowIndex);
      const cells: JSX.Element[] = [];
      // Cell for capsule start/end
      if (showHeaders) {
        cells.push(
          <TableBuilderDataCell
            textValue={sqTableBuilderHelper.formatHeader(headers, capsule.property, capsule.startTime, capsule.endTime,
              timezone)}
            columnIndex={rowIndex}
            style={sqTableBuilderHelper.computeCellColors(maybeStripedColor)}
            extraClassNames="text-bolder text-center"
            key={columnIndex++}
          />);
      }

      // Cells for each property or statistic for the capsule
      _.forEach(capsule.values, (value, valueIndex) => {
        const header = tableData.headers[valueIndex];
        if (isStartOrEndColumn(header)) {
          cells.push(renderTimeCell(header, headers, capsule, maybeStripedColor, columnIndex, rowIndex));
        } else {
          cells.push(renderDataCell(header, capsule, value, maybeStripedColor, columnIndex, rowIndex));
        }
        columnIndex++;
      });
      return <TableBuilderRow key={rowIndex}>{cells}</TableBuilderRow>;
    });
  };

  const renderTable = () => (
    <table data-testid="conditionTable"
      className="table table-bordered-first-column table-md-condensed fixedColumnTable width-auto screenshotSizeToContent">
      {showHeaders &&
      <thead>{createHeaderRow()}</thead>}
      <tbody>{createTableRows()}</tbody>
    </table>
  );

  const renderTransposedTable = () => (
    <table data-testid="conditionTableTransposed"
      className="table table-bordered table-md-condensed fixedHeaderTable width-auto screenshotSizeToContent">
      {columns.length > 0 &&
      <thead>{createTransposedHeaderRows()}</thead>}
      <tbody>{createTransposedTableRows()}</tbody>
    </table>
  );

  return <DndProvider backend={HTML5Backend}>
    {(isTransposed ? renderTransposedTable() : renderTable())}
  </DndProvider>;
};
