import React, { useCallback, useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { Overlay, Popover } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import { TableBuilderColumnFilter } from '@/hybrid/tableBuilder/tableBuilder.store';
import {
  COMPARISON_OPERATORS,
  COMPARISON_OPERATORS_SYMBOLS,
  PREDICATE_API,
  STRING_COMPARISON_OPERATORS
} from '@/investigate/investigate.module';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import _ from 'lodash';
import { FORM_ERROR, FormElement, FormGroup } from '@/hybrid/formbuilder/formBuilder.module';
import { FormBuilder } from '@/hybrid/formbuilder/FormBuilder.page';
import { ThresholdOutputV1 } from '@/sdk/model/ThresholdOutputV1';
import { TABLE_BUILDER } from '@/hybrid/tableBuilder/tableBuilder.module';
import { TrackService } from '@/track/track.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import { FormControlFormComponent } from '@/hybrid/formbuilder/FormControlFormComponent.atom';

const TableBuilderFilterPopoverBindings = bindingsDefinition({
  columnFilter: prop<TableBuilderColumnFilter>(),
  columnKey: prop<string>(),
  notifyOnClose: prop.optional<() => void>(),
  setColumnFilter: prop.optional<(key: string, filter: TableBuilderColumnFilter) => void>(),
  isStringColumn: prop.optional<boolean>(),
  distinctStringValues: prop.optional<string[]>(),
  placement: prop<Placement>(),
  thresholds: prop.optional<ThresholdOutputV1[]>(),
  show: prop.optional<boolean>(),
  helpText: prop.optional<string>(),
  target: prop<HTMLElement>(),
  sqTrack: injected<TrackService>()
});

export const TableBuilderFilterPopover: SeeqComponent<typeof TableBuilderFilterPopoverBindings> = (props) => {
  const {
    columnFilter,
    columnKey,
    notifyOnClose = () => {},
    setColumnFilter = () => {},
    isStringColumn = false,
    distinctStringValues = undefined,
    placement,
    show = false,
    helpText = undefined,
    thresholds = undefined,
    target
  } = props;
  const { sqTrack } = useInjectedBindings(TableBuilderFilterPopoverBindings);
  const { t } = useTranslation();
  const [showPopover, setShowPopover] = useState(show);
  const [operators, setOperators] = useState([]);
  const [displayThresholds, setDisplayThresholds] = useState([]);
  const [displayDistinctStringValues, setDisplayDistinctStringValues] = useState([]);
  const [selectedStringValues, setSelectedStringValues] = useState([]);
  const [filterOperator, setFilterOperator] = useState(_.findKey(PREDICATE_API,
    value => value === columnFilter?.operator));
  const [upperValue, setUpperValue] = useState(undefined);
  const [lowerValue, setLowerValue] = useState(undefined);
  const [advancedEntryCollapsed, setAdvancedEntryCollapsed] = useState(true);
  const [filterFormData, setFilterFormData] = useState([{
    component: 'FormGroup',
    name: 'simpleValueSearchGroup',
    testId: 'tableBuilderFilterForm',
    components: []
  }] as FormElement[]);
  const [threshold, setThreshold] = useState(undefined);
  const operatorKeys = _.invert(COMPARISON_OPERATORS_SYMBOLS);
  const formatOperators = operators => _.map(operators, operator => ({
    text: operator,
    value: operator,
    label:
      <>
        <span className="inlineBlock width-25 text-center text-bolder text-monospace" id={operator}>{operator}</span>
        <span className="pl5">{t(`VALUE_SEARCH.OPERATORS.${operatorKeys[operator]}`)}</span>
      </>
  }));

  useEffect(() => {
    // Update and format the operators given the type of column
    const operators = isStringColumn ? STRING_COMPARISON_OPERATORS : COMPARISON_OPERATORS;
    setOperators(formatOperators(operators));
    if (!isStringColumn) {
      setSelectedStringValues([]);
    }
  }, [isStringColumn]);

  const getSymbolFromPriority = (priorityLevel) => {
    if (isStringColumn) {
      return COMPARISON_OPERATORS_SYMBOLS.IS_MATCH;
    }
    return priorityLevel > 0 ? COMPARISON_OPERATORS_SYMBOLS.IS_GREATER_THAN : COMPARISON_OPERATORS_SYMBOLS.IS_LESS_THAN;
  };

  const formatThreshold = t => ({
    text: t.priority.name,
    level: t.priority.level,
    value: `${t.priority.level}_${t.value?.value}`,
    thresholdValue: t.value?.value,
    label:
      <>
        <span className="colorPickerSwatch mr10" onClick={() => {}} data-itemid={columnKey}
          style={{ backgroundColor: t.priority.color }} id="filterThresholdColor" />
        <span className="text-bolder text-monospace ">
          {t.priority.name}</span>
        <span className="pl5">{t.value?.value}</span>
      </>
  });

  const formatThresholds = (thresholds) => {
    return _.chain(thresholds)
      .reject(_.isUndefined)
      .map(formatThreshold)
      .reject(threshold => _.isUndefined(threshold.thresholdValue))
      .uniqBy('value')
      .sortBy('value')
      .reverse()
      .value();
  };

  useEffect(() => {
    setDisplayThresholds(formatThresholds(thresholds));
  }, [thresholds]);

  const formatDistinctStringValues = (stringValues) => {
    return _.chain(stringValues)
      .reject(_.isUndefined)
      .reject(_.isEmpty)
      .map(value => ({ text: value, value, id: value, name: value }))
      .sortBy('text')
      .value();
  };

  useEffect(() => {
    setDisplayDistinctStringValues(formatDistinctStringValues(distinctStringValues));
  }, [distinctStringValues]);

  useEffect(() => {
    setFilterOperator(_.findKey(PREDICATE_API, value => value === columnFilter?.operator));
  }, [columnFilter?.operator]);

  const setFilterValues = (filter) => {
    if (_.isEmpty(filter?.values)) {
      setLowerValue(undefined);
      setUpperValue(undefined);
      setSelectedStringValues([]);
    } else if (filter.operator && isOperatorBetween(
      _.findKey(PREDICATE_API, value => value === columnFilter?.operator))) {
      setLowerValue(filter.values[0]);
      setUpperValue(filter.values[1]);
    } else if (isStringColumn && filter.usingSelectedValues) {
      setSelectedStringValues(formatDistinctStringValues(filter.values));
    } else {
      setUpperValue(filter.values[0]);
    }
  };

  useEffect(() => {
    setFilterValues(columnFilter);
    // Keep the advanced section open if we're using the input there, otherwise close it
    if (isStringColumn && !_.isEmpty(columnFilter?.values) && !columnFilter.usingSelectedValues) {
      setAdvancedEntryCollapsed(false);
    } else if (!isStringColumn || _.isEmpty(columnFilter?.values)) {
      setAdvancedEntryCollapsed(true);
    }
  }, [columnFilter]);

  useEffect(() => {
    if (!columnFilter) {
      setThreshold(undefined);
    }
  }, [columnFilter]);

  useEffect(() => {
    if (threshold) {
      setFilterOperator(getSymbolFromPriority(threshold.level));
      setFilterValues({
        operator: getSymbolFromPriority(threshold.level),
        values: [threshold.thresholdValue?.toString(), undefined]
      });
    }
  }, [threshold]);

  useEffect(() => {
    if (!columnFilter || columnFilter.values.length !== 1 || !show) {
      return;
    }
    // Select a threshold when the popover opens only if it matches the existing column filter.
    if (!threshold && !_.isEmpty(thresholds)) {
      const matchingThreshold = _.find(thresholds, (thresh) => {
        const valueMatches = columnFilter.values[0] === thresh.value?.value;
        const operatorMatches = _.findKey(PREDICATE_API, value => value === columnFilter.operator)
          === getSymbolFromPriority(thresh.priority.level);
        return valueMatches && operatorMatches;
      });
      if (matchingThreshold) {
        setThreshold(formatThreshold(matchingThreshold));
      }
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      openPopover();
    }
  }, [show]);

  useEffect(() => {
    const handleClick = () => {
      closePopover();
    };

    // add when mounted
    document.querySelector('#mainView').addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.querySelector('#mainView')?.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const openPopover = () => {
    if (showPopover) {
      closePopover();
      return;
    }
  };

  const closePopover = () => {
    setShowPopover(false);
    notifyOnClose();
  };

  const isOperatorBetween = operator => _.includes(
    [COMPARISON_OPERATORS_SYMBOLS.IS_BETWEEN, COMPARISON_OPERATORS_SYMBOLS.IS_NOT_BETWEEN], operator);

  const isSimpleLowerBoundGreater = (lowerValue, upperValue) => {
    if (_.isUndefined(lowerValue) || _.isUndefined(upperValue)) {
      return false;
    }
    const parsedLower = parseFloat(lowerValue);
    const parsedUpper = parseFloat(upperValue);
    return _.isFinite(parsedLower) && _.isFinite(parsedUpper) && parsedUpper < parsedLower;
  };

  const isSameBetweenValues = (operator, lowerValue, upperValue) => {
    if (_.isUndefined(lowerValue) || _.isUndefined(lowerValue)) {
      return false;
    }
    const parsedLower = parseFloat(lowerValue);
    const parsedUpper = parseFloat(upperValue);
    return isOperatorBetween(operator) && _.isFinite(parsedLower) && _.isFinite(
      parsedUpper) && parsedUpper === parsedLower;
  };

  const isBadString = (input) => {
    if (!input) {
      return false;
    }
    try {
      ''.match(new RegExp(input[0]));
      return false;
    } catch {
      return true;
    }
  };

  const getColumnFilterToSubmit = useCallback(() => {
    const inputValues = _.reject([lowerValue, upperValue], _.isUndefined);
    let values;
    let usingSelectedValues;
    if (isStringColumn) {
      if (_.isEmpty(selectedStringValues)) {
        values = inputValues;
      } else {
        values = _.map(selectedStringValues, 'value');
        usingSelectedValues = true;
      }
    } else {
      values = _.chain(inputValues)
        .map((value) => {
          const parsedValue = parseFloat(value);
          if (_.isFinite(parsedValue)) {
            return parsedValue;
          }
          return undefined;
        })
        .reject(_.isUndefined)
        .value();
    }
    const operator = PREDICATE_API[filterOperator];
    return { values, operator, usingSelectedValues };
  }, [lowerValue, upperValue, filterOperator, selectedStringValues]);

  const selectAStringValue = useCallback((selectedValues) => {
    setSelectedStringValues(selectedValues);
  }, [selectedStringValues]);

  const removeAStringValue = useCallback((value) => {
    setSelectedStringValues(_.reject(selectedStringValues, { value: value.value }));
  }, [selectedStringValues]);

  useEffect(() => {
      setFilterFormData([{
        component: 'FormGroup',
        name: 'simpleValueSearchGroup',
        testId: 'tableBuilderFilterForm',
        components: [{
          component: 'FormGroup',
          name: 'thresholdGroup',
          components: [{
            component: 'IconSelectFormComponent',
            name: 'existingThresholdSelect',
            includeIf: !_.isEmpty(displayThresholds),
            value: threshold,
            onChange: thresh => setThreshold(thresh),
            validation: () => false,
            formattedOptions: true,
            placeholder: 'TABLE_BUILDER.FILTER_SELECT_THRESHOLD',
            testId: 'existingThresholdSelect',
            selectOptions: displayThresholds,
            wrapperClasses: 'min-width-195',
            skipMemo: true
          }, {
            component: 'LabelFormComponent',
            includeIf: !_.isEmpty(displayThresholds),
            value: '',
            name: 'tableBuilderFilterThresholdLine',
            extraClassNames: 'tableBuilderFilterThresholdLine'
          }, {
            component: 'IconSelectFormComponent',
            name: 'simpleFilterOperator',
            value: filterOperator,
            onChange: (operator) => {
              if (!isOperatorBetween(operator.value) && isOperatorBetween(filterOperator)) {
                setLowerValue(undefined);
              }
              setFilterOperator(operator.value);
              setThreshold(undefined);
            },
            formattedOptions: true,
            placeholder: 'VALUE_SEARCH.SELECT_OPERATOR',
            testId: 'simpleFilterOperator',
            selectOptions: operators,
            wrapperClasses: 'min-width-195'
          }]
        }, {
          component: 'FormGroup',
          name: 'upperLimitGroup',
          includeIf: !isStringColumn || _.isEmpty(displayDistinctStringValues),
          components: [{
            component: 'LabelFormComponent',
            includeIf: isOperatorBetween(filterOperator),
            value: 'VALUE_SEARCH.UPPER_LIMIT',
            name: 'upperFilterLimitLabel',
            extraClassNames: 'forceNoBottomMargin'
          }, {
            component: 'FormRow',
            name: 'upperValueRow',
            components: [{
              component: 'FormControlFormComponent',
              name: 'columnFilterValue',
              testId: 'columnFilterValue',
              type: 'text',
              validation: isStringColumn && !_.isEmpty(selectedStringValues) ? () => {} : undefined,
              value: upperValue,
              onChange: (value) => {
                setUpperValue(value);
                setThreshold(undefined);
              },
              placeholder: isOperatorBetween(filterOperator) ? 'VALUE_SEARCH.UPPER_ENTRY_VALUE' :
                'VALUE_SEARCH.THRESHOLD',
              skipMemo: true
            }]
          }]
        }, {
          component: 'FormGroup',
          name: 'lowerValueGroup',
          includeIf: isOperatorBetween(filterOperator),
          components: [{
            component: 'LabelFormComponent',
            includeIf: isOperatorBetween(filterOperator),
            value: 'VALUE_SEARCH.LOWER_LIMIT',
            name: 'lowerLimitLabel',
            extraClassNames: 'forceNoBottomMargin'
          }, {
            component: 'FormControlFormComponent',
            name: 'columnFilterLowerValue',
            testId: 'columnFilterLowerValue',
            type: 'text',
            value: lowerValue,
            onChange: (value) => {
              setLowerValue(value);
              setThreshold(undefined);
            },
            placeholder: 'VALUE_SEARCH.LOWER_ENTRY_VALUE',
            skipMemo: true
          }]
        }, {
          component: 'FormGroup',
          name: 'distinctStringValueGroup',
          components: [{
            component: 'IconSelectFormComponent',
            name: 'distinctStringValueSelect',
            isMultipleSelect: true,
            isSearchable: true,
            selectOptions: displayDistinctStringValues,
            includeIf: isStringColumn && !_.isEmpty(displayDistinctStringValues),
            value: selectedStringValues,
            onChange: selectAStringValue,
            onRemove: removeAStringValue,
            validation: isStringColumn && !_.isEmpty(displayDistinctStringValues)
              ? value => (_.isEmpty(value) && _.isEmpty(selectedStringValues) && _.isEmpty(upperValue))
              : undefined,
            formattedOptions: false,
            placeholder: 'TABLE_BUILDER.FILTER_STRING_SELECTION',
            testId: 'distinctStringValueSelect',
            wrapperClasses: 'min-width-195 mt10',
            skipMemo: true
          }]
        }, {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'advancedStringFilterEntry',
          includeIf: isStringColumn && !_.isEmpty(displayDistinctStringValues),
          children: <div className="flexFill flexRowContainer mt10 advancedFilterPopover toolOptions">
            <div className="cursorPointer pt5 pb5 sq-text-primary"
              onClick={() => setAdvancedEntryCollapsed(!advancedEntryCollapsed)}>
              <Icon icon={advancedEntryCollapsed ? 'fa-plus' : 'fa-minus'} testId="advancedParametersIcon" />
              <a className="ml10 link-no-underline">{t('ADVANCED')}</a>
            </div>
            {!advancedEntryCollapsed &&
            <div className="flexFill flexRowContainer">
              <div className="divider" />
              <span className="flexFill">{t('TABLE_BUILDER.FILTER_ADVANCED_STRING')}</span>
              <FormControlFormComponent
                className="flexFill"
                component="FormControlFormComponent"
                name="columnFilterValue"
                size="md"
                testId="columnFilterValueAdvanced"
                type="text"
                validation={isStringColumn && !_.isEmpty(selectedStringValues) ? () => false : undefined}
                value={upperValue}
                onChange={(value) => {
                  setUpperValue(value);
                  setThreshold(undefined);
                }}
                placeholder={isOperatorBetween(filterOperator) ? 'VALUE_SEARCH.UPPER_ENTRY_VALUE' :
                  isStringColumn
                    ? 'TABLE_BUILDER.FILTER_STRING_LIMIT'
                    : 'VALUE_SEARCH.THRESHOLD'}
                skipMemo={true} />
            </div>
            }
          </div>
        }, {
          component: 'ErrorMessageFormComponent',
          name: 'greaterLowerBoundError',
          includeIf: isSimpleLowerBoundGreater(lowerValue, upperValue),
          type: FORM_ERROR,
          failForm: true,
          value: 'VALUE_SEARCH.LIMIT_ERROR'
        }, {
          component: 'ErrorMessageFormComponent',
          name: 'sameBetweenValues',
          includeIf: isSameBetweenValues(filterOperator, lowerValue, upperValue),
          type: FORM_ERROR,
          failForm: true,
          value: 'VALUE_SEARCH.SAME_BETWEEN'
        }, {
          component: 'ErrorMessageFormComponent',
          name: 'badStringInput',
          includeIf: isStringColumn && isBadString(upperValue) && _.isEmpty(selectedStringValues),
          type: FORM_ERROR,
          failForm: true,
          value: 'TABLE_BUILDER.FILTER_BAD_STRING'
        }, {
          component: 'LabelFormComponent',
          includeIf: !!helpText,
          value: helpText,
          name: 'filterHelpText',
          extraClassNames: 'forceNoBottomMargin help-block'
        }]
      }] as FormElement[]);
    },
    [upperValue, lowerValue, filterOperator, operators, displayThresholds, threshold, displayDistinctStringValues, selectedStringValues, isStringColumn, advancedEntryCollapsed]
  );

  return (
    <Overlay target={target} show={show} placement={placement} transition={false}>
      <Popover id="tableBuilderFilterPopover" data-testid="tableBuilderFilterPopover">
        <div className="popover-header">{t('TABLE_BUILDER.FILTER')}</div>
        <div className="p10 flexRowContainer">
          <div className="flexColumnContainer flexFill">
            <FormBuilder
              formDefinition={filterFormData}
              hideCancel={true}
              submitFn={() => {
                setColumnFilter(columnKey, getColumnFilterToSubmit());
                sqTrack.doTrack(TABLE_BUILDER, 'filter', 'enabled');
                closePopover();
              }}
              closeFn={() => {
                setColumnFilter(columnKey, undefined);
                sqTrack.doTrack(TABLE_BUILDER, 'filter', 'disabled');
                closePopover();
              }}
              cancelBtnLabel="REMOVE"
              submitBtnLabel="APPLY"
              saveAndCancel={true}
              wrapInPanel={false}
            />
          </div>
        </div>
      </Popover>
    </Overlay>
  );
};
