import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import { DateTimeService } from '@/datetime/dateTime.service';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { StringCapsulePropertyColorsConfig } from '@/scatterPlot/scatterPlot.store';
import { SeeqNames } from '@/main/app.constants.seeqnames';

const scatterPlotLegendColorStringCapsulePropertyBindings = bindingsDefinition({
  sqWorksheetStore: injected<WorksheetStore>(),
  sqDateTime: injected<DateTimeService>(),
  propertyName: prop<string>(),
  colorsConfig: prop<StringCapsulePropertyColorsConfig | undefined>()
});

/** Displays info about scatter plot colors when coloring by a string capsule property (or start/end) */
export const ScatterPlotLegendColorRainbowCapsuleProperty: SeeqComponent<typeof scatterPlotLegendColorStringCapsulePropertyBindings> = (props) => {
  const { sqWorksheetStore, sqDateTime } = useInjectedBindings(scatterPlotLegendColorStringCapsulePropertyBindings);
  const { propertyName, colorsConfig } = props;

  if (!propertyName || !colorsConfig || !colorsConfig.isStringProperty) {
    return null;
  }

  const formatProperty = _.includes([SeeqNames.CapsuleProperties.Start, SeeqNames.CapsuleProperties.End], propertyName)
    ? value => sqDateTime.formatTime(parseInt(value), sqWorksheetStore.timezone)
    : _.identity;

  return <>
    {_.map(colorsConfig.valueColorMap, (value, key) =>
      <span className="mr10 text-nowrap specColorStringCapsulePropertyLegend"
        key={`${propertyName}.${key}`}
        data-testid="color-string-capsule-property-span">
          <Icon icon="fa-circle" type="color" color={value} extraClassNames="pr5" />
        {formatProperty(key)}
      </span>
    )}
  </>;
};
