import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { TrackService } from '@/track/track.service';
import { InvestigateStore } from '@/investigate/investigate.store';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { DEBOUNCE, DISPLAY_MODE, STRING_UOM } from '@/main/app.constants';
import { FORM_ERROR, FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { ManualSignalStore } from '@/hybrid/tools/manualSignal/manualSignal.store';
import { ManualSignalActions } from '@/hybrid/tools/manualSignal/manualSignal.actions';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { useDebounce } from '@/hybrid/core/useDebounce.hook';
import { PREVIEW_ID } from '@/trendData/trendData.module';
import { TrendActions } from '@/trendData/trend.actions';
import { ManualSignalUtilitiesService } from '@/hybrid/tools/manualSignal/manualSignal.utilities.service';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { INTERPOLATION_METHODS, INTERPOLATION_TYPES } from '@/hybrid/tools/manualSignal/manualSignal.module';
import { InputTableColumn } from '@/hybrid/formbuilder/InputTableFormComponent.molecule';

const manualSignalBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  sqManualSignalStore: injected<ManualSignalStore>(),
  sqManualSignalActions: injected<ManualSignalActions>(),
  sqManualSignalService: injected<ManualSignalUtilitiesService>(),
  sqInvestigateStore: injected<InvestigateStore>(),
  sqTrack: injected<TrackService>(),
  sqToolRunner: injected<ToolRunnerService>(),
  sqTrendActions: injected<TrendActions>(),
  sqWorksheetStore: injected<WorksheetStore>()
});

export const ManualSignal: SeeqComponent<typeof manualSignalBindings> = () => {
  const {
    sqInvestigateActions,
    sqTrendActions,
    sqManualSignalActions,
    sqManualSignalStore,
    sqManualSignalService,
    sqInvestigateStore,
    sqWorksheetStore,
    sqTrack,
    sqToolRunner
  } = useInjectedBindings(manualSignalBindings);

  const [color, setColor] = useState('');

  const displayMode = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.displayMode);
  const timezone = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.timezone);

  const [isFormValid, setIsFormValid] = useState(true);
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [hasValueTypeError, setHasValueTypeError] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const {
    name,
    id,
    sort,
    samples,
    maximumInterpolation,
    interpolationMethod,
    uom,
    editingSampleId
  } = useFlux(sqManualSignalStore);

  useEffect(() => {
    setHasValueTypeError(uom !== STRING_UOM && _.some(samples, sample => _.isNaN(_.toNumber(sample.value))));
  }, [samples, uom]);

  // Creates the preview
  const generatePreview = useDebounce(() => {
    if (!inProgress && isFormValid && samples.length > 0) {
      const formula = sqManualSignalService.generateFormula();
      return sqTrendActions.generatePreviewSeries(formula, {}, id ? id : PREVIEW_ID, color)
        .catch(() => sqTrendActions.removePreviewSeries());
    } else {
      sqTrendActions.removePreviewSeries();
    }
  }, DEBOUNCE.PREVIEW);

  useEffect(() => {
    const timer = setTimeout(generatePreview, DEBOUNCE.PREVIEW);
    return () => clearTimeout(timer);
  }, [samples, maximumInterpolation, uom, interpolationMethod, generatePreview]);

  const execute = () => {
    setInProgress(true);
    generatePreview.cancel();
    sqTrendActions.removePreviewSeries();

    const formula = sqManualSignalService.generateFormula();

    return sqToolRunner.panelExecuteSignal(
      sqManualSignalStore.name,
      formula,
      {},
      sqManualSignalStore.configParams,
      sqManualSignalStore.id,
      color,
      { notifyOnError: false })
      .then(() => sqTrack.doTrack('Workbench_Tool', 'Manual Signal', 'completed'))
      .catch((errorMessage) => {
        sqTrack.doTrack('Workbench_Tool', 'Manual Signal', 'error');
        setApiErrorMessage(_.replace(errorMessage, /\(.*\)/gm, ''));
      })
      .finally(() => setInProgress(false));
  };

  const columns: [InputTableColumn, InputTableColumn] = [{
    name: 'dateTime',
    type: 'dateTime',
    headerKey: 'MANUAL_SIGNAL.DATE_TIME'
  }, {
    name: 'value',
    type: 'value',
    headerKey: 'MANUAL_SIGNAL.VALUE'
  }];

  const formDataSetup: FormElement[] = [{
    component: 'SearchTitleFormComponent',
    name: 'manualSignalTitle',
    value: name,
    onChange: name => sqInvestigateActions.setSearchName(TREND_TOOLS.MANUAL_SIGNAL, name),
    id,
    onColorChange: setColor,
    searchIconClass: uom === STRING_UOM ? 'fa-font' : 'fc-series',
    defaultName: 'MANUAL_SIGNAL.HEADER'
  }, {
    component: 'FormGroup',
    name: 'manualEntryFormGroup',
    displayNumber: true,
    components: [{
      component: 'LabelFormComponent',
      name: 'manualEntryLabel',
      value: 'MANUAL_SIGNAL.ENTER_SAMPLES'
    }, {
      component: 'ErrorMessageFormComponent',
      name: 'valueTypeError',
      includeIf: hasValueTypeError,
      value: 'MANUAL_SIGNAL.VALUE_TYPE_ERROR_OVERALL',
      type: FORM_ERROR,
      failForm: true
    }, {
      component: 'InputTableFormComponent',
      name: 'manualData',
      value: samples,
      timezone,
      sort,
      columns,
      isStringSignal: uom === STRING_UOM,
      updateSort: sqManualSignalActions.setSort,
      setEditingId: sqManualSignalActions.setEditingSampleId,
      editingId: editingSampleId,
      removeRow: sqManualSignalActions.removeSample,
      hasValueTypeError,
      inputType: 'sample'
    }]
  }, {
    component: 'SelectUnitFormComponent',
    name: 'selectUomInput',
    value: uom,
    validation: () => false, // makes this field optional
    tooltip: 'MANUAL_SIGNAL.UOM_TOOLTIP',
    onChange: sqManualSignalActions.setUom,
    extraClassNames: 'flex-fill',
    label: 'IMPORTS.VALUE_UOM',
    displayNumber: true
  }, {
    component: 'RadioButtonGroupFormComponent',
    name: 'datafileInterpolationMethod',
    value: interpolationMethod,
    id: 'manualInterpolationMethod',
    gridLayout: true,
    displayNumber: true,
    label: 'IMPORTS.INTERPOLATION.METHOD',
    options: _.map(INTERPOLATION_METHODS, method => ({
      id: `manualInterpolationMethod${method.value}`,
      label: method.text,
      checked: interpolationMethod === method.value,
      onToggle: () => sqManualSignalActions.setInterpolationMethod(method.value)
    }))
  }, {
    component: 'ValueWithUnitsFormComponent',
    label: 'IMPORTS.INTERPOLATION.MAXGAP',
    includeIf: interpolationMethod !== INTERPOLATION_TYPES.DISCRETE,
    value: maximumInterpolation,
    onChange: sqManualSignalActions.setMaximumInterpolation,
    name: 'manualMaximumInterpolation',
    displayNumber: true,
    min: 0
  }, {
    component: 'ErrorMessageFormComponent',
    name: 'apiError',
    includeIf: !_.isEmpty(apiErrorMessage),
    value: apiErrorMessage,
    type: FORM_ERROR,
    title: 'MANUAL_SIGNAL.FAILURE',
    dismissible: true,
    failForm: false,
    onClose: () => setApiErrorMessage('')
  }];

  const manualSignal = <ToolPanelFormBuilder
    formDefinition={formDataSetup}
    submitFn={execute}
    closeFn={() => {
      sqTrendActions.removePreviewSeries();
      sqInvestigateActions.close();
    }}
    toolName={TREND_TOOLS.MANUAL_SIGNAL}
    setIsValid={setIsFormValid}
    toolId={TREND_TOOLS.MANUAL_SIGNAL}
    submitBtnId="manualSignalSubmitBtn" />;

  return (displayMode === DISPLAY_MODE.NEW || displayMode === DISPLAY_MODE.EDIT) ? manualSignal : null;
};

export const sqManualSignal = angularComponent(manualSignalBindings, ManualSignal);
