import _ from 'lodash';
import React, { useState } from 'react';
import { Icon } from '@/hybrid/core/Icon.atom';
import { TableBuilderFilterPopover } from '@/hybrid/tableBuilder/tableComponents/TableBuilderFilterPopover.organism';
import { TableBuilderColumnFilter } from '@/hybrid/tableBuilder/tableBuilder.store';
import { ThresholdOutputV1 } from '@/sdk/model/ThresholdOutputV1';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { TableBuilderHelperService } from '@/hybrid/tableBuilder/tableBuilderHelper.service';

interface FilterDropdownIF {
  columnKey: string;
  target: any;
  headerBackgroundColor: string | undefined;
  setColumnFilter?: (key: string, filter: TableBuilderColumnFilter) => void;
  columnFilter?: TableBuilderColumnFilter;
  isFilterDisabled?: boolean;
  isStringColumn?: boolean;
  distinctStringValues?: string[];
  helpText?: string;
  thresholds?: ThresholdOutputV1[] | undefined;
}

const tableBuilderFilterIconBindings = bindingsDefinition({
  sqTableBuilderHelper: injected<TableBuilderHelperService>()
});

export const TableBuilderFilterIcon: React.FunctionComponent<FilterDropdownIF> = (props) => {

  const { sqTableBuilderHelper } = useInjectedBindings(tableBuilderFilterIconBindings);

  const {
    columnKey,
    target,
    headerBackgroundColor,
    setColumnFilter,
    columnFilter = undefined,
    isFilterDisabled = false,
    isStringColumn = false,
    distinctStringValues = undefined,
    helpText = undefined,
    thresholds = undefined
  } = props;

  const isFiltered = !_.isUndefined(columnFilter);

  const [showColumnFilterPopover, setShowColumnFilterPopover] = useState(false);

  const renderFilterPopover = () => <TableBuilderFilterPopover
    columnFilter={columnFilter}
    columnKey={columnKey}
    notifyOnClose={() => setShowColumnFilterPopover(false)}
    setColumnFilter={(key, filter) => setColumnFilter(key, filter)}
    isStringColumn={isStringColumn}
    distinctStringValues={distinctStringValues}
    thresholds={thresholds}
    helpText={helpText}
    placement="bottom"
    target={target?.current}
    show={!isFilterDisabled && showColumnFilterPopover} />;

  const getFilterIconType = (hasFilter, background) => !hasFilter ? 'gray'
    : !!background ? sqTableBuilderHelper.getMostReadableIconType(background) : 'theme';

  return <>
    {!isFilterDisabled && isFiltered && <div
      className="sq-icon-hover cursorPointer"
      data-testid="columnFilterIconWrapper"
      id={`filterDropdown-${columnKey}`}>
      <Icon icon="fa-filter"
        extraClassNames="fa-fw mt2"
        testId="columnFilterIcon"
        onClick={() => setShowColumnFilterPopover(true)}
        type={getFilterIconType(isFiltered, headerBackgroundColor)} />
    </div>}
    {renderFilterPopover()}
    {isFilterDisabled && isFiltered &&
    <Icon icon="fa-filter"
      extraClassNames="fa-fw mt2 ml5"
      testId="columnFilterIcon"
      type={getFilterIconType(isFiltered, headerBackgroundColor)} />}
  </>;
};
