import { NUMBER_CONVERSIONS } from '@/main/app.constants';
import { ReportSummaryDayOutputV1 } from '@/sdk';
import { DayEnum } from 'sdk/model/ReportSummaryDayOutputV1';
import _ from 'lodash';

export const DEFAULT_TEXT_FILTERS = {
  name: '',
  owner: '',
  schedules: ''
};

export const DEFAULT_TYPE_FILTERS = {
  scheduled: true,
  live: true,
  static: false,
  enabled: true
};

export interface TextFilters {
  name: string;
  owner: string;
  schedules: string;
}

export enum ReportSort {
  AverageRunTime = 'AverageRunTime',
  TotalRunTime = 'TotalRunTime',
  Name = 'Name',
  Owner = 'Owner',
  ContentCount = 'ContentCount',
  LastViewedAt = 'LastViewedAt',
  NextRunTime = 'NextRunTime'
}

export enum ReportChartStat {
  AverageRunTime = 'AverageRunTime'
}

interface ColumnWithDrilldown {
  name: string;
  y: number;
  drilldown: DayEnum;
}

interface Drilldown {
  name: string;
  id: DayEnum;
  data: [string, number][];
}

export const getSeriesFromOutput = (stat: ReportChartStat, days: ReportSummaryDayOutputV1[],
  t: (s: string) => string): ColumnWithDrilldown[] => {
  const conversion = getConversionForStat(stat);
  return _.map(days,
    day => ({ name: t(`WEEKDAYS.${day.day}`), drilldown: day.day, y: conversion(_.sum(day.results)) }));
};

export const getDrilldownFromOutput = (stat: ReportChartStat, days: ReportSummaryDayOutputV1[],
  t: (s: string) => string): Drilldown[] => {
  const conversion = getConversionForStat(stat);
  return _.map(days,
    day => ({
      name: t(`WEEKDAYS.${day.day}`),
      id: day.day,
      data: _.map(day.results, (result, index) => [TimeIndexToHourRange[index], conversion(result)])
    }));
};

export const getAxisForStat = (stat: ReportChartStat, t: (s: string) => string): string => {
  if (stat === ReportChartStat.AverageRunTime) {
    return t('ADMIN.REPORT.AVERAGE_RUN_TIME');
  }

  return stat;
};

export const getTitleForStat = (stat: ReportChartStat, drilldown: boolean, t: (s: string) => string,
  day?: DayEnum): string => {
  const prefix = drilldown ? `${day ? t(`WEEKDAYS.${day}`) : ''} ${t('ADMIN.REPORT.CHARTS.RANGE.HOURLY')}`
    : t('ADMIN.REPORT.CHARTS.RANGE.DAILY');
  let suffix = '';
  if (stat === ReportChartStat.AverageRunTime) {
    suffix = `${t('ADMIN.REPORT.CHARTS.TITLE.AVERAGE_RUN_TIME')}`;
  }

  return `${prefix} ${suffix}`;
};

// Exposed for test
export const TimeIndexToHourRange = [
  '00-01', '01-02', '02-03', '03-04', '04-05', '05-06', '06-07', '07-08', '08-09', '09-10', '10-11', '11-12',
  '12-13', '13-14', '14-15', '15-16', '16-17', '17-18', '18-19', '19-20', '20-21', '21-22', '22-23', '23-00'
];

export const getConversionForStat = (stat: ReportChartStat) => {
  if (stat === ReportChartStat.AverageRunTime) {
    return x => x / NUMBER_CONVERSIONS.MILLISECONDS_PER_SECOND;
  }

  return x => x;
};
