import _ from 'lodash';
import React from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Capsule } from '../../../../plugin/sdk/seeq';
import { CustomPropertySelector, SuggestedPropertiesMode } from '@/hybrid/utilities/CustomPropertySelector.atom';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { PropertyColumn, StatisticColumn } from '@/trendData/trendData.module';

const itemPropertiesSelectorBindings = bindingsDefinition({
  suggestedPropertiesMode: prop.optional<SuggestedPropertiesMode>(),
  conditions: prop.optional<Capsule[]>(),
  itemSet: prop.optional<any[]>(),
  extraColumns: prop.optional<PropertyColumn[]>(),
  extraSelectorProperties: prop.optional<PropertyColumn[]>(),
  propertyColumns: prop.optional<PropertyColumn[]>(),
  statColumns: prop.optional<StatisticColumn[]>(),
  showPropertyColumns: prop.optional<boolean>(),
  isColumnEnabled: prop<(column: any) => boolean>(),
  toggleColumn: prop<(column: any) => void>(),
  addPropertyColumn: prop.optional<(column: any) => void>(),
  customContent: prop.optional<React.ReactNode>(),
  showCustomPropertySelector: prop.optional<boolean>(),
  maxHeightPropertyColumns: prop.optional<number>()
});

export const ItemPropertiesSelector: SeeqComponent<typeof itemPropertiesSelectorBindings> = (props) => {
  const {
    suggestedPropertiesMode,
    conditions = [],
    itemSet,
    extraColumns = [],
    extraSelectorProperties = [],
    propertyColumns = [],
    statColumns,
    showPropertyColumns = true,
    isColumnEnabled,
    toggleColumn,
    addPropertyColumn = _.noop,
    customContent,
    showCustomPropertySelector = true,
    maxHeightPropertyColumns = 65
  } = props;

  const { t } = useTranslation();

  const customStyles = {
    menu: provided => ({ ...provided, width: '160px' }),
    option: styles => ({ ...styles, padding: '2px' })
  };

  // Don't display property columns that we're already showing as extra columns
  const propertyColumnsToDisplay = _.reject(propertyColumns, column =>
    _.some(extraColumns, extraColumn => extraColumn.key === column.key));

  const mapColumnsToList = (columns: PropertyColumn[] | StatisticColumn[]) =>
    _.map(columns, (column: StatisticColumn | PropertyColumn) => (
      <Checkbox
        classes='textPrimaryMenuItem'
        id={column.key + '-checkbox'}
        key={column.key + '-checkbox'}
        label={t(column.title || column.shortTitle)}
        isChecked={isColumnEnabled(column)}
        onChange={() => toggleColumn(column)}
        skipMemo={true} />
    ));

  return (
    <div className="flexColumnContainer">
      {statColumns && statColumns.length > 0 &&
      <div>
        {mapColumnsToList(statColumns)}
      </div>
      }
      <div className='ml10' style={{ minWidth: '150px' }}>
        {mapColumnsToList(extraColumns)}
        {showPropertyColumns && <>
          {!_.isEmpty(extraColumns) &&
          <hr className="mt5 mb5" />}
          <div className="tableWrapper" style={{ maxHeight: `${maxHeightPropertyColumns}px` }}>
            {mapColumnsToList(propertyColumnsToDisplay)}
          </div>
          {showCustomPropertySelector &&
          <CustomPropertySelector
            excludedProperties={_.map(propertyColumns, propertyColumn => propertyColumn.shortTitle)}
            extraProperties={extraSelectorProperties}
            suggestedPropertiesMode={suggestedPropertiesMode}
            conditions={conditions}
            itemSet={itemSet}
            addPropertyColumn={addPropertyColumn}
            customStyles={customStyles}
            dropdownPlaceholder='CUSTOM_COLUMN_SELECTOR.CUSTOM_PROPERTY'
          />}
        </>}
        {customContent}
      </div>
    </div>
  );
};
