import _ from 'lodash';
import angular from 'angular';
import { InvestigateStore } from '@/investigate/investigate.store';
import { UtilitiesService } from '@/services/utilities.service';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';
import { ANCILLARY_PAIR_TYPES, ANCILLARY_TYPES } from '@/investigate/ancillariesPanel.controller';
import { DISPLAY_MODE } from '@/main/app.constants';
import { TREND_TOOLS } from '@/investigate/investigate.module';

angular.module('Sq.Investigate').store('sqAncillariesPanelStore', sqAncillariesPanelStore);

export type AncillariesPanelStore = ReturnType<typeof sqAncillariesPanelStore>['exports'];

function sqAncillariesPanelStore(
  $state: ng.ui.IStateService,
  sqInvestigateStore: InvestigateStore,
  sqUtilities: UtilitiesService
) {
  const store = {
    persistenceLevel: PERSISTENCE_LEVEL.WORKSHEET,

    initialize() {
      this.state = this.immutable({
        ancillaries: [],
        inProgress: false,
        inProgressItem: 'item1',
        id: undefined,
        name: '',
        type: '',
        item1: undefined,
        item2: undefined
      });
    },

    exports: {
      get ancillaries() {
        return this.state.get('ancillaries');
      },

      get inProgress() {
        return this.state.get('inProgress');
      },

      get inProgressItem() {
        return this.state.get('inProgressItem');
      },

      get id() {
        return this.state.get('id');
      },

      get name() {
        return this.state.get('name');
      },

      get type() {
        return this.state.get('type');
      },

      get item1() {
        return this.state.get('item1');
      },

      get item2() {
        return this.state.get('item2');
      }
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @return {Object} State for the store
     */
    dehydrate() {
      const state = _.omit(this.state.serialize(), ['ancillaries']) as any;
      state.item1 = _.pick(state.item1, ['id', 'name']);
      state.item2 = _.pick(state.item2, ['id', 'name']);
      return state;
    },

    /**
     * Sets the ancillaries panel state
     *
     * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      INVESTIGATE_SET_ITEM: 'reset',
      INVESTIGATE_CLEAR_ITEM: 'reset',
      ANCILLARIES_RESULTS: 'setAncillaries',
      ANCILLARIES_EDIT: 'loadAncillary',
      INVESTIGATE_SET_DISPLAY_MODE: 'clearAncillary',
      ANCILLARIES_SET_IN_PROGRESS: 'setInProgress',
      ANCILLARIES_SET_NAME: 'setName',
      ANCILLARIES_SET_TYPE: 'setType',
      ANCILLARIES_SET_ITEM_1: 'setItem1',
      ANCILLARIES_SET_ITEM_2: 'setItem2'
    },

    /**
     * Resets the state. This is primarily used as a way to reset the inProgress state if the user moves to
     * investigate something else, but also clears out the rest of state since it is obsolete when the investigate
     * item changes.
     */
    reset() {
      this.initialize();
    },

    /**
     * Set the list of the ancillaries for the item. Since pairs are stored as separate ancillaries with the same
     * name but different orders they are converted back into a single pair here.
     *
     * @param {Object} payload - Object container
     * @param {Object[]} payload.ancillaries - the list of ancillaries
     */
    setAncillaries(payload) {
      this.state.set('ancillaries', _.chain(payload.ancillaries)
        .map(function(ancillary) {
          ancillary.isInScope = sqUtilities.isInScope(ancillary);
          if (_.chain(ancillary.items).map('order').some(_.isNumber).value()) {
            ancillary.type = ANCILLARY_TYPES.PAIR;
            ancillary.item1 = _.find(ancillary.items, ['order', ANCILLARY_PAIR_TYPES.UPPER]);
            ancillary.item2 = _.find(ancillary.items, ['order', ANCILLARY_PAIR_TYPES.LOWER]);
          } else {
            ancillary.type = ANCILLARY_TYPES.SINGLE;
            ancillary.item1 = _.first(ancillary.items);
          }

          return ancillary;
        })
        .sortBy('name')
        .value());
    },

    /**
     * Loads the ancillary to be edited.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.ancillary - The ancillary to edit from the ancillaries list
     */
    loadAncillary(payload) {
      const ancillary = _.find(this.state.get('ancillaries'), ['id', payload.ancillary.id]) as any;
      this.state.set('id', ancillary.id);
      this.state.set('name', ancillary.name);
      this.state.set('type', ancillary.type);
      this.state.set('item1', ancillary.item1);
      this.state.set('item2', ancillary.item2);
    },

    /**
     * Clears the ancillary for the form if mode is NEW.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.mode - Mode, one of DISPLAY_MODE.
     */
    clearAncillary(payload) {
      if (sqInvestigateStore.activeTool === TREND_TOOLS.ANCILLARIES && payload.mode === DISPLAY_MODE.NEW) {
        this.state.merge({
          id: undefined,
          name: '',
          type: '',
          item1: undefined,
          item2: undefined
        });
      }
    },

    /**
     * Set the state of the ancillary form as in progress and records which item needs to be updated when a new item
     * is created by the panel the user is going to.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.inProgress - Either 'item1' or 'item2'
     */
    setInProgress(payload) {
      this.state.merge({ inProgress: true, inProgressItem: payload.inProgressItem });
    },

    /**
     * Set the name of the ancillary.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.name - the name of the ancillary
     */
    setName(payload) {
      this.state.set('name', payload.name);
    },

    /**
     * Set the type of the ancillary.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.type - the type, one of ANCILLARY_TYPES
     */
    setType(payload) {
      this.state.set('type', payload.type);
    },

    /**
     * Set the item id for the ancillary. This is the upper item for boundary pairs.
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.item - the item that is being related to the main item
     */
    setItem1(payload) {
      this.state.set('item1', payload.item);
    },

    /**
     * Set the 2nd item id for the ancillary. This is the lower item for boundary pairs.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.item - the 2nd item that is being related to the main item
     */
    setItem2(payload) {
      this.state.set('item2', payload.item);
    }
  };

  return store;
}
