import { DatasourcesStatusOutputV1 } from '@/sdk';

export type DatasourcesActions = ReturnType<typeof sqDatasourcesActions>;

export function sqDatasourcesActions(
  flux: ng.IFluxService
) {

  const service = {
    setDatasources(datasources: DatasourcesStatusOutputV1) {
      flux.dispatch('DATASOURCES_SET_DATASOURCES', { datasources });
    }
  };

  return service;
}
