import angular from 'angular';
import template from './journalEntry.component.html';

/**
 * Manages the display of a single journal entry.
 */
angular.module('Sq.Annotation').component('sqJournalEntry', {
  controller:'JournalEntryCtrl',
  template,
  bindings: {
    id: '<',
    document: '<',
    isDeleteAllowed: '<',
    isEditAllowed: '<',
    isEditing: '<',
    isSaving: '<',
    isSaved: '<',
    isError: '<',
    lastSave: '<',
    comments: '<',
    createdBy: '<',
    createdAt: '<',
    updatedAt: '<',
    isExpanded: '<',
    width: '<',
    height: '<',
    isCommentsExpanded: '<',
    isAnnotatesExpanded: '<',
    isCommentAllowed: '<',
    isCommenting: '<',
    isPresentationMode: '<',
    isDiscoverable: '<',
    hasMultipleJournalEntries: '<',
    commentText: '=',
    edit: '&',
    delete: '&',
    saveComment: '&',
    newComment: '&',
    customNewComment: '<',
    cancelComment: '&',
    customCancelComment: '<',
    updateComment: '<',
    deleteComment: '<',
    expand: '&',
    expandAnnotates: '&',
    expandComments: '&',
    customExpandComments: '<',
    close: '&',
    showOverview: '&',
    documentChanged: '<',
    widthChanged: '&',
    heightChanged: '&',
    canManage: '<',
    toggleDiscoverable: '&',
    beforeOnInit: '<',
    afterOnInit: '<',
    onDestroy: '<',
    toolbar: '<',
    plugins: '<',
    toggleMigrateModal: '<',
    canUpgradeToCk: '<',
    upgradingToCk: '<'
  }
});
