/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class SampleSeriesApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Archive a series and its samples
   * @param {string} id - ID of the series to archive
   */
  public archiveSeries(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/sample-series/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ArchiveOutputV1>;
  }

  /**
   * 
   * @summary Create or update multiple series at one time
   * @param {models.SeriesBatchInputV1} [body]
   */
  public batchUpdateSeries(
    body?: models.SeriesBatchInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/sample-series/batch',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ItemBatchOutputV1>;
  }

  /**
   * 
   * @summary Create a series
   * @param {models.SeriesInputV1} body - Series information
   */
  public createSeries(
    body: models.SeriesInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/sample-series',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.SeriesOutputV1>;
  }

  /**
   * Pagination is enabled for this query. Data is ordered by index, ascending.
   * @summary (Warning: this API is deprecated and planned for removal in a future release.  Use the GetSamples method in the Signals API instead.) Get data from a series for rendering purposes
   * @param {string} id - ID of the series from which to retrieve data
   * @param {string} start - A string representing the starting index of the data to be returned. The contents vary based on the series type. For time series: a ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm). For numeric (non-time) series: a double-precision number, optionally including units. For example: \&quot;2.5ft\&quot; or \&quot;10 °C\&quot;.
   * @param {string} end - A string representing the starting index of the data to be returned. The contents vary based on the series type. For time series: a ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm). For numeric (non-time) series: a double-precision number, optionally including units. For example: \&quot;2.5ft\&quot; or \&quot;10 °C\&quot;.
   * @param {string} [keyUnitOfMeasure] - The unit of measure in which to return the series keys. If none is supplied, the data is returned in the series&#39; default key unit of measure.
   * @param {string} [valueUnitOfMeasure] - The unit of measure in which to return the data. If none is supplied, the data is returned in the series&#39; default unit of measure.
   * @param {number} [horizontalPixels=0] - The integer number of horizontal pixels in the rendered chart that will consume this data. If there is more data available than will fit in a chart of the specified size, the data will be down sampled such that there are up to 5 samples per horizontal pixel. The data returned is the minimum set needed to display the shape of the curve accurately given the size of the chart. If not specified, this parameter defaults to 0 and the data is returned without any down sampling. Example: 1000. Ignored for numeric (non-time) series.
   * @param {boolean} [isoTimestampFormat=true] - The timestamp format, true for ISO-8601 timestamps or false for nanoseconds since the Unix Epoch
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=1000] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getPlotSamples(
    {
      id,
      start,
      end,
      keyUnitOfMeasure,
      valueUnitOfMeasure,
      horizontalPixels,
      isoTimestampFormat,
      offset,
      limit
    } : {
      id: string,
      start: string,
      end: string,
      keyUnitOfMeasure?: string,
      valueUnitOfMeasure?: string,
      horizontalPixels?: number,
      isoTimestampFormat?: boolean,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(start)) {
      throw new Error("'start' parameter required");
    }

    if (isNil(end)) {
      throw new Error("'end' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/sample-series/{id}/plot-samples'
        .replace('{id}', encodeURIComponent(String(id))),
      params: omitBy({
        ['start']: start,
        ['end']: end,
        ['keyUnitOfMeasure']: keyUnitOfMeasure,
        ['valueUnitOfMeasure']: valueUnitOfMeasure,
        ['horizontalPixels']: horizontalPixels,
        ['isoTimestampFormat']: isoTimestampFormat,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.SeriesSamplesOutputV1>;
  }

  /**
   * Pagination is enabled for this query. Data is ordered by index, ascending.
   * @summary (Warning: this API is deprecated and planned for removal in a future release.  Use the GetSamples method in the Signals API instead.) Get data from a series
   * @param {string} id - ID of the series from which to retrieve data
   * @param {string} start - A string representing the starting index of the data to be returned. The contents vary based on the series type. For time series: a ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm). For numeric (non-time) series: a double-precision number, optionally including units. For example: \&quot;2.5ft\&quot; or \&quot;10 °C\&quot;.
   * @param {string} end - A string representing the starting index of the data to be returned. The contents vary based on the series type. For time series: a ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm). For numeric (non-time) series: a double-precision number, optionally including units. For example: \&quot;2.5ft\&quot; or \&quot;10 °C\&quot;.
   * @param {string} [period] - The period at which the original data is to be sampled (and perhaps interpolated) to create the requested data set. Period is required to perform calculations on the series unless the calculation is an aggregation such as MIN. Examples: 5h, 2min, 3 days, 4 years. Ignored for numeric (non-time) series.
   * @param {string} [keyUnitOfMeasure] - The unit of measure in which to return the series keys. If none is supplied, the data is returned in the series&#39; default key unit of measure.
   * @param {string} [valueUnitOfMeasure] - The unit of measure in which to return the series values. If none is supplied, the data is returned in the series&#39; default value unit of measure.
   * @param {boolean} [isoTimestampFormat=true] - The timestamp format, true for ISO-8601 timestamps or false for nanoseconds since the Unix Epoch
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=1000] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getSamples(
    {
      id,
      start,
      end,
      period,
      keyUnitOfMeasure,
      valueUnitOfMeasure,
      isoTimestampFormat,
      offset,
      limit
    } : {
      id: string,
      start: string,
      end: string,
      period?: string,
      keyUnitOfMeasure?: string,
      valueUnitOfMeasure?: string,
      isoTimestampFormat?: boolean,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(start)) {
      throw new Error("'start' parameter required");
    }

    if (isNil(end)) {
      throw new Error("'end' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/sample-series/{id}/samples'
        .replace('{id}', encodeURIComponent(String(id))),
      params: omitBy({
        ['start']: start,
        ['end']: end,
        ['period']: period,
        ['keyUnitOfMeasure']: keyUnitOfMeasure,
        ['valueUnitOfMeasure']: valueUnitOfMeasure,
        ['isoTimestampFormat']: isoTimestampFormat,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.SeriesSamplesOutputV1>;
  }

  /**
   * Pagination is enabled for this query
   * @summary Get a collection of series
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getSeries(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/sample-series',
      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ItemPreviewListV1>;
  }

  /**
   * 
   * @summary Get a series
   * @param {string} id - ID of the series to retrieve
   */
  public getSeries_1(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/sample-series/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.SeriesOutputV1>;
  }

  /**
   * Samples cannot be added to a calculated series
   * @summary Add samples to a stored series
   * @param {string} id - ID of the series that will receive the samples
   * @param {models.SeriesSamplesInputV1} body - Data to be added to the series
   */
  public putSamples(
    body: models.SeriesSamplesInputV1,
    {
      id,
    } : {
      id: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/sample-series/{id}/samples'
        .replace('{id}', encodeURIComponent(String(id))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.SeriesOutputV1>;
  }

}
