import React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/sql/sql';
import 'codemirror/lib/codemirror.css';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';

const formulaDisplayOnlyBindings = bindingsDefinition({
  formula: prop<string>()
});

export const FormulaDisplayOnly: SeeqComponent<typeof formulaDisplayOnlyBindings> = ({ formula }) => (
  <CodeMirror
    value={formula}
    options={{
      readOnly: true,
      mode: { name: 'php', startOpen: true },
      theme: 'seeq',
      cursorHeight: 0,
      lineWrapping: true,
      lineNumbers: false
    }}
    onBeforeChange={() => {}}
  />
);

export const sqFormulaDisplayOnly = angularComponent(formulaDisplayOnlyBindings, FormulaDisplayOnly);
