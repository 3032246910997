import angular from 'angular';
import { LogTrackerService } from '@/hybrid/logTracker/LogTracker.service';

const dependencies = [
  'Sq.Core',
  'Sq.Workbook',
  'Sq.Worksheet',
  'Sq.Services.Modal'
];

export default () => angular.module('Sq.LogTracker', dependencies).service('sqLogTrackerService', LogTrackerService);
