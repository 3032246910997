/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class UserGroupsApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Add a user to a user group
   * @param {string} userGroupId - ID of the user group to add user or user group to
   * @param {string} identityId - ID of the user or user group to add.
   */
  public addIdentityToUserGroup(
    {
      userGroupId,
      identityId
    } : {
      userGroupId: string,
      identityId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(userGroupId)) {
      throw new Error("'userGroupId' parameter required");
    }

    if (isNil(identityId)) {
      throw new Error("'identityId' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/usergroups/{userGroupId}/{identityId}'
        .replace('{userGroupId}', encodeURIComponent(String(userGroupId)))
        .replace('{identityId}', encodeURIComponent(String(identityId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.UserGroupOutputV1>;
  }

  /**
   * 
   * @summary Archive a user group
   * @param {string} userGroupId - ID of user group to archive
   * @param {boolean} [removePermissions=false] - Whether to remove permissions associated with the group being archived
   */
  public archiveUserGroup(
    {
      userGroupId,
      removePermissions
    } : {
      userGroupId: string,
      removePermissions?: boolean
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(userGroupId)) {
      throw new Error("'userGroupId' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/usergroups/{userGroupId}/archive'
        .replace('{userGroupId}', encodeURIComponent(String(userGroupId))),
      params: omitBy({
        ['removePermissions']: removePermissions
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.UserGroupOutputV1>;
  }

  /**
   * 
   * @summary Create a user group hosted within Seeq
   * @param {models.UserGroupInputV1} body - User group information
   */
  public createUserGroup(
    body: models.UserGroupInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/usergroups',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.UserGroupOutputV1>;
  }

  /**
   * 
   * @summary Disable a user group
   * @param {string} userGroupId - ID of user group to disable
   * @param {boolean} [removePermissions=false] - Whether to remove permissions associated with the group being disabled
   */
  public disableUserGroup(
    {
      userGroupId,
      removePermissions
    } : {
      userGroupId: string,
      removePermissions?: boolean
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(userGroupId)) {
      throw new Error("'userGroupId' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/usergroups/{userGroupId}/disable'
        .replace('{userGroupId}', encodeURIComponent(String(userGroupId))),
      params: omitBy({
        ['removePermissions']: removePermissions
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.UserGroupOutputV1>;
  }

  /**
   * 
   * @summary Get a user group
   * @param {string} userGroupId - ID of the user group to get
   */
  public getUserGroup(
    {
      userGroupId
    } : {
      userGroupId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(userGroupId)) {
      throw new Error("'userGroupId' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/usergroups/{userGroupId}'
        .replace('{userGroupId}', encodeURIComponent(String(userGroupId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.UserGroupOutputV1>;
  }

  /**
   * Pagination is enabled for this query
   * @summary Get a collection of usergroups
   * @param {string} [nameSearch] - Search text by which to filter user groups&#39; names.
   * @param {string} [datasourceIdSearch] - Search text by which to filter user groups&#39; directories.
   * @param {boolean} [isEnabled] - Whether to filter users to only users who are enabled.
   * @param {string} [sortOrder=name asc] - A field by which to order the user groups followed by a space and &#39;asc&#39; or &#39;desc&#39;. Field name can be one of: name, directory
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination level, the total number of collection items that will be returned in this page of results
   */
  public getUserGroups(
    {
      nameSearch,
      datasourceIdSearch,
      isEnabled,
      sortOrder,
      offset,
      limit
    } : {
      nameSearch?: string,
      datasourceIdSearch?: string,
      isEnabled?: boolean,
      sortOrder?: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/usergroups',
      params: omitBy({
        ['nameSearch']: nameSearch,
        ['datasourceIdSearch']: datasourceIdSearch,
        ['isEnabled']: isEnabled,
        ['sortOrder']: sortOrder,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.IdentityPreviewListV1>;
  }

  /**
   * 
   * @summary Create or update multiple user groups
   * @param {models.PutUserGroupsInputV1} body
   */
  public putUserGroups(
    body: models.PutUserGroupsInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/usergroups/batch',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ItemBatchOutputV1>;
  }

  /**
   * 
   * @summary Remove a user from a user group
   * @param {string} userGroupId - ID of the user group to remove user from
   * @param {string} identityId - ID of the user or user group to remove.
   */
  public removeIdentityFromUserGroup(
    {
      userGroupId,
      identityId
    } : {
      userGroupId: string,
      identityId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(userGroupId)) {
      throw new Error("'userGroupId' parameter required");
    }

    if (isNil(identityId)) {
      throw new Error("'identityId' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/usergroups/{userGroupId}/{identityId}'
        .replace('{userGroupId}', encodeURIComponent(String(userGroupId)))
        .replace('{identityId}', encodeURIComponent(String(identityId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.UserGroupOutputV1>;
  }

  /**
   * 
   * @summary Remove a user group
   * @param {string} userGroupId - ID of the user group to remove
   */
  public removeUserGroup(
    {
      userGroupId
    } : {
      userGroupId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(userGroupId)) {
      throw new Error("'userGroupId' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/usergroups/{userGroupId}'
        .replace('{userGroupId}', encodeURIComponent(String(userGroupId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Update name or description of a user group
   * @param {string} userGroupId - ID of the user group to update
   * @param {models.UserGroupInputV1} body - New user group information
   */
  public updateUserGroup(
    body: models.UserGroupInputV1,
    {
      userGroupId,
    } : {
      userGroupId: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(userGroupId)) {
      throw new Error("'userGroupId' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/usergroups/{userGroupId}'
        .replace('{userGroupId}', encodeURIComponent(String(userGroupId))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.UserGroupOutputV1>;
  }

}
