import _ from 'lodash';
import angular from 'angular';
import moment from 'moment-timezone';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { LicenseManagementStore } from '@/licenseManagement/licenseManagement.store';
import { APP_STATE } from '@/main/app.constants';
import { LICENSE_STATUS } from '@/licenseManagement/licenseManagement.module';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { UtilitiesService } from '@/services/utilities.service';

/**
 * This controller sets flags that are used by the sq-license-expiration-warning component.
 *
 * Based on the user type different banners are displayed:
 * - users get a prompt to remind their admin to renew
 * - admins get a link to request a license.
 *
 * The warning that the license will expire soon can be dismissed.
 *
 */
angular.module('Sq.LicenseManagement')
  .controller('LicenseExpirationWarningCtrl', LicenseExpirationWarningCtrl);

function LicenseExpirationWarningCtrl(
  $scope: ng.IScope,
  $translate: ng.translate.ITranslateService,
  sqWorkbenchStore: WorkbenchStore,
  sqWorkbenchActions: WorkbenchActions,
  sqLicenseManagementStore: LicenseManagementStore,
  sqSystemConfiguration: SystemConfigurationService,
  sqUtilities: UtilitiesService
) {

  const vm = this;
  let license, licenseStatus;

  vm.snoozeLicense = snoozeLicense;
  vm.closeBanner = closeBanner;

  vm.adminContactName = sqSystemConfiguration.adminContactName;
  vm.adminContactEmail = sqSystemConfiguration.adminContactEmail;

  vm.APP_STATE = APP_STATE;
  const contactEmail = sqUtilities.buildEmailLink(vm.adminContactEmail, vm.adminContactName,
    'LICENSE.EMAIL_LICENSE_REQUEST.SUBJECT', 'LICENSE.EMAIL_LICENSE_REQUEST.RENEW');
  vm.USER_EXPIRATION_CALL_TO_ACTION = $translate.instant('LICENSE.USER_EXPIRATION_CALL_TO_ACTION',
    { CONTACT: contactEmail });
  $scope.$listenTo(sqLicenseManagementStore, syncLicenseStore);
  $scope.$listenTo(sqWorkbenchStore, ['currentUser', 'licenseExpirationSnooze'], syncWorkbenchStore);

  /**
   * Syncs the user and refreshes the warning flags.
   */
  function syncWorkbenchStore() {
    vm.currentUser = sqWorkbenchStore.currentUser;
    setWarningFlags();
  }

  /**
   * Syncs the LicenseManagement Store.
   */
  function syncLicenseStore() {
    licenseStatus = sqLicenseManagementStore.licenseStatus;
    license = sqLicenseManagementStore.license;

    vm.isTrialLicense = sqLicenseManagementStore.isTrial;
    if (!_.isEmpty(license)) {
      vm.daysToExpiration = license.daysToExpiration;
      vm.snoozeDays = Math.ceil(vm.daysToExpiration / 2);
    }

    setWarningFlags();
  }

  /**
   * This function sets the warning flags used by the html component to display the appropriate banner.
   * Based on the user type and the status of the license different banners will be displayed.
   *
   * This function also manages the display of the notification that informs the user that new workbook and worksheet
   * creation is not supported with an expired license.
   *
   * This function also supports the "snoozing" of the warning message by checking the snooze data against the
   * current date.
   */
  function setWarningFlags() {
    if (!_.isEmpty(vm.currentUser) && licenseStatus) {
      if (vm.currentUser.isAdmin && (licenseStatus === LICENSE_STATUS.SHOW_LICENSE_WARNING ||
        licenseStatus === LICENSE_STATUS.SHOW_ADMIN_LICENSE_WARNING)) {
        vm.showAdminExpirationWarning = true;
      } else if (licenseStatus === LICENSE_STATUS.SHOW_LICENSE_WARNING) {
        vm.showUserExpirationWarning = true;
      }
    }

    if ((vm.showAdminExpirationWarning || vm.showUserExpirationWarning) &&
      sqWorkbenchStore.isLicenseExpirationSnoozed()) {
      vm.showAdminExpirationWarning = false;
      vm.showUserExpirationWarning = false;
    }
  }

  /**
   * Snoozes the license warning.
   * Adds a Snooze Until entry to local storage that can be used to determine if warning messages are snoozed or not.
   *
   * The "snooze" duration is always half of the remaining days to license expiration.
   */
  function snoozeLicense() {
    const newWarningDisplayDate = moment().add(vm.snoozeDays, 'days').toISOString();
    sqWorkbenchActions.setLicenseExpirationSnooze(newWarningDisplayDate);
    vm.closeBanner();
  }

  /**
   * Dismisses the warning banner only until then page is reloaded.
   */
  function closeBanner() {
    vm.showAdminExpirationWarning = false;
    vm.showUserExpirationWarning = false;
  }
}
