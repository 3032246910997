import React, { useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { WorkbookActions } from '@/workbook/workbook.actions';
import { WorkbookStore } from '@/workbook/workbook.store';
import { WorksheetsService } from '@/worksheets/worksheets.service';
import { NotificationsService } from '@/services/notifications.service';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.module';
import { Icon } from '@/hybrid/core/Icon.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { TrackService } from '@/track/track.service';
import { APP_STATE } from '@/main/app.constants';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { ReadOnlyWorksheetPaneTile } from '@/hybrid/worksheets/ReadOnlyWorksheetPaneTile.molecule';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableWorksheetPaneTile from '@/hybrid/worksheets/DraggableWorksheetPaneTile.molecule';

const worksheetsPaneBindings = bindingsDefinition({
  activeWorkbookId: prop<string>(),
  activeWorksheetId: prop<string>(),
  sqWorkbookActions: injected<WorkbookActions>(),
  sqWorkbookStore: injected<WorkbookStore>(),
  sqWorksheets: injected<WorksheetsService>(),
  sqNotifications: injected<NotificationsService>(),
  sqTrack: injected<TrackService>(),
  $state: injected<ng.ui.IStateService>()
});

export const WorksheetsPane: SeeqComponent<typeof worksheetsPaneBindings> = (props) => {
  const { activeWorkbookId, activeWorksheetId } = props;
  const { sqWorkbookActions, sqWorkbookStore, sqTrack, $state }
    = useInjectedBindings(worksheetsPaneBindings);
  const { t } = useTranslation();

  const [disableAddWorksheet, setDisableAddWorksheet] = useState(false);
  const [goingToWorksheetId, setGoingToWorksheetId] = useState(undefined);

  const editMode = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.EDIT);
  const isReportBinder = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.isReportBinder);
  const worksheets = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.worksheets);

  const addActiveWorksheet = () => {
    const options = isReportBinder ? { prefix: t('ITEM_TYPES.DOCUMENT') } : {};
    setDisableAddWorksheet(true);

    sqTrack.doTrack('Workbench', `new Worksheet added to ${isReportBinder ? 'Topic' : 'Analysis'}`,
      'from within document');

    return sqWorkbookActions.addWorksheet(activeWorkbookId, options)
      .then(worksheet => gotoWorksheet(worksheet.worksheetId))
      .finally(() => setDisableAddWorksheet(false));
  };

  const gotoWorksheet = (worksheetId, viewOnly = false) => {
    // Prevent the user from commanding transition to the same worksheet while a transition to that worksheet
    // is already in progress (e.g. from a double click)
    if (goingToWorksheetId === worksheetId) {
      return Promise.resolve();
    }

    setGoingToWorksheetId(worksheetId);

    const targetState = viewOnly ? APP_STATE.VIEW_WORKSHEET : APP_STATE.WORKSHEET;
    return $state.go(targetState, { workbookId: activeWorkbookId, worksheetId, workstepId: null }, {
        // If we command a state transition to the current worksheet while another transition is in progress,
        // we MUST command a reload to guarantee that the ui-router will resolve and rehydrate the worksheet.
        // This prevents an edge condition where the selected worksheet won't display the correct state
        // because the user has clicked quickly on another worksheet and then back to the original worksheet
        // (CRAB-16564).
        reload: !!$state.transition && worksheetId === activeWorksheetId
      })
      .finally(() => setGoingToWorksheetId(undefined));
  };

  const moveWorksheet = (oldIndex, newIndex) => {
    if (oldIndex === newIndex) {
      return;
    }

    if (oldIndex < newIndex) {
      newIndex++;
    }

    return sqWorkbookActions.moveWorksheet(activeWorkbookId, sqWorkbookStore.worksheets[oldIndex],
      sqWorkbookStore.worksheets[newIndex]);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      {editMode &&
      <div className="flexColumnContainer flexNoGrowNoShrink flexSpaceBetween pl5" id="wsScrollBtns">
        <a className={classNames('p10', { 'disabledLook disabledBehavior': disableAddWorksheet })}
          onClick={addActiveWorksheet}>
          <HoverTooltip text={isReportBinder ? 'NEW_DOCUMENT' : 'NEW_WORKSHEET'} placement="right">
              <span>
                <Icon
                  icon="fa-plus"
                  large={true}
                  type="white"
                  extraClassNames="iconHover specAddWorksheetButton"
                  testId="addWorksheetButton" />
              </span>
          </HoverTooltip>
        </a>
      </div>}

      <div id="worksheetScrollArea" className="overflowAuto pl5 mt10 min-height-200"
        data-testid={editMode ? 'sortableWorksheetContainer' : 'nonSortableWorksheetContainer'}>
        {_.map(worksheets, (worksheet, index: number) => (
          editMode
            ? <DraggableWorksheetPaneTile
              key={worksheet.worksheetId}
              index={index}
              worksheet={worksheet}
              gotoWorksheet={gotoWorksheet}
              workbookId={activeWorkbookId}
              isActive={worksheet.worksheetId === activeWorksheetId}
              moveWorksheet={moveWorksheet}
            />
            : <ReadOnlyWorksheetPaneTile
              key={worksheet.worksheetId}
              worksheet={worksheet}
              workbookId={activeWorkbookId}
              gotoWorksheet={gotoWorksheet}
              isActive={worksheet.worksheetId === activeWorksheetId}
              index={index}
            />
        ))}
      </div>
    </DndProvider>
  );
};

export const sqWorksheetsPane = angularComponent(worksheetsPaneBindings, WorksheetsPane);
