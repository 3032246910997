import angular from 'angular';
import formulaToolModule from '@/hybrid/tools/formula/formulaTool.module';

const dependencies = [
  'Sq.AppConstants',
  'Sq.Services.Formula',
  'Sq.TrendData',
  formulaToolModule.name
];

/**
 * @file Module for service that facilitates creating a calculation and then using that
 * calculation to create results, such as generated signals and conditions.
 */
angular.module('Sq.Services.CalculationRunner', dependencies);

export const SAMPLE_FROM_SCALARS = {
  KEY_METHODS: [{
    key: 'start',
    stat: 'startKey()',
    title: 'STATISTICS.START.LONG',
    requiresSignal: false
  }, {
    key: 'middle',
    stat: 'middleKey()',
    title: 'STATISTICS.MIDDLE.LONG',
    requiresSignal: false
  }, {
    key: 'end',
    stat: 'endKey()',
    title: 'STATISTICS.END.LONG',
    requiresSignal: false
  }, {
    key: 'min',
    stat: 'minKey()',
    title: 'STATISTICS.MIN_KEY.LONG',
    requiresSignal: true
  }, {
    key: 'max',
    stat: 'maxKey()',
    title: 'STATISTICS.MAX_KEY.LONG',
    requiresSignal: true
  }, {
    key: 'duration',
    stat: 'durationKey()',
    title: 'STATISTICS.DURATION.LONG',
    requiresSignal: false
  }],
  // The "output" key is an array containing 1 or more of 'sample' or 'histogram'. These help control which stats are
  // available in which contexts. This is for histograms of signals where the stat might be happening over a group
  // of capsules. In that case, stats like rate or delta don't make sense.
  VALUE_METHODS: [{
    key: 'min',
    stat: 'minValue()',
    title: 'STATISTICS.MINIMUM.LONG',
    input: ['sample'],
    output: ['sample', 'histogram'],
    outputInTimeUnits: false,
    description: 'STATISTICS.MINIMUM.DESCRIPTION'
  }, {
    key: 'max',
    stat: 'maxValue()',
    title: 'STATISTICS.MAXIMUM.LONG',
    input: ['sample'],
    output: ['sample', 'histogram'],
    outputInTimeUnits: false,
    description: 'STATISTICS.MAXIMUM.DESCRIPTION'
  }, {
    key: 'average',
    stat: 'average()',
    title: 'STATISTICS.AVERAGE.LONG',
    input: ['sample'],
    output: ['sample', 'histogram'],
    outputInTimeUnits: false,
    description: 'STATISTICS.AVERAGE.DESCRIPTION'
  }, {
    key: 'standardDeviation',
    stat: 'stdDev()',
    title: 'STATISTICS.STANDARD_DEVIATION.LONG',
    input: ['sample'],
    output: ['sample', 'histogram'],
    outputInTimeUnits: false,
    description: 'STATISTICS.STANDARD_DEVIATION.DESCRIPTION'
  }, {
    key: 'totalized',
    stat: 'totalized($unit)',
    title: 'STATISTICS.TOTALIZED.LONG',
    input: ['sample'],
    output: ['sample', 'histogram'],
    outputInTimeUnits: true,
    description: 'STATISTICS.TOTALIZED.DESCRIPTION'
  }, {
    key: 'rate',
    stat: 'rate($unit)',
    title: 'STATISTICS.RATE.LONG',
    input: ['sample'],
    output: ['sample'],
    outputInTimeUnits: true,
    description: 'STATISTICS.RATE.DESCRIPTION'
  }, {
    key: 'valueStart',
    stat: 'startValue(true)',
    title: 'STATISTICS.VALUE_START.LONG',
    input: ['sample', 'string'],
    output: ['sample'],
    outputInTimeUnits: false,
    description: 'STATISTICS.VALUE_START.DESCRIPTION'
  }, {
    key: 'valueEnd',
    stat: 'endValue(true)',
    title: 'STATISTICS.VALUE_END.LONG',
    input: ['sample', 'string'],
    output: ['sample'],
    outputInTimeUnits: false,
    description: 'STATISTICS.VALUE_END.DESCRIPTION'
  }, {
    key: 'delta',
    stat: 'delta()',
    title: 'STATISTICS.DELTA.LONG',
    input: ['sample'],
    output: ['sample'],
    outputInTimeUnits: false,
    description: 'STATISTICS.DELTA.DESCRIPTION'
  }, {
    key: 'range',
    stat: 'range()',
    title: 'STATISTICS.RANGE.LONG',
    input: ['sample'],
    output: ['sample', 'histogram'],
    outputInTimeUnits: false,
    description: 'STATISTICS.RANGE.DESCRIPTION'
  }, {
    key: 'sum',
    stat: 'sum()',
    title: 'STATISTICS.SUM.LONG',
    input: ['sample'],
    output: ['sample', 'histogram'],
    outputInTimeUnits: false,
    description: 'STATISTICS.SUM.DESCRIPTION'
  }, {
    key: 'count',
    stat: 'count()',
    title: 'STATISTICS.COUNT.LONG',
    input: ['sample', 'capsule', 'string'],
    output: ['sample', 'histogram'],
    outputInTimeUnits: false,
    description: 'STATISTICS.COUNT.DESCRIPTION'
  }, {
    key: 'count.ends',
    stat: 'countEnds()',
    title: 'STATISTICS.COUNT_ENDS.LONG',
    input: ['capsule'],
    output: ['histogram', 'sample'],
    outputInTimeUnits: false,
    description: 'STATISTICS.COUNT_ENDS.DESCRIPTION'
  }, {
    key: 'count.starts',
    stat: 'countStarts()',
    title: 'STATISTICS.COUNT_STARTS.LONG',
    input: ['capsule'],
    output: ['histogram', 'sample'],
    outputInTimeUnits: false,
    description: 'STATISTICS.COUNT_STARTS.DESCRIPTION'
  }, {
    key: 'percentDuration',
    stat: 'percentDuration()',
    title: 'STATISTICS.PERCENT_DURATION.LONG',
    input: ['capsule'],
    output: ['sample', 'histogram'],
    outputInTimeUnits: false,
    description: 'STATISTICS.PERCENT_DURATION.DESCRIPTION'
  }, {
    key: 'totalDuration',
    stat: 'totalDuration($unit)',
    title: 'STATISTICS.TOTAL_DURATION.LONG',
    input: ['capsule'],
    output: ['sample', 'histogram'],
    outputInTimeUnits: true,
    description: 'STATISTICS.TOTAL_DURATION.DESCRIPTION'
  }, {
    key: 'median',
    stat: 'median()',
    title: 'STATISTICS.MEDIAN.LONG',
    input: ['sample'],
    output: ['sample', 'histogram'],
    outputInTimeUnits: false,
    description: 'STATISTICS.MEDIAN.DESCRIPTION'
  }, {
    key: 'percentile',
    stat: 'percentile($percentile)',
    title: 'STATISTICS.PERCENTILE.LONG',
    input: ['sample'],
    output: ['sample', 'histogram'],
    outputInTimeUnits: false,
    needsPercentile: true,
    description: 'STATISTICS.PERCENTILE.DESCRIPTION'
  }]
};
export const INTERPOLATION_METHODS = {
  STEP: 'STEP',
  LINEAR: 'LINEAR',
  DISCRETE: 'DISCRETE'
};
export const PREDICTION_OPERATION = {
  PREDICTION: 'prediction',
  PREDICTION_MODEL: 'regressionModel'
};
