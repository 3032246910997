import React, { useEffect, useState } from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { Field, useForm } from 'react-final-form';
import {
  getFormFieldProps,
  getValidationFunction
} from '@/hybrid/formbuilder/formbuilder.utilities';
import { FormError } from '@/hybrid/core/FormError.atom';
import classNames from 'classnames';
import _ from 'lodash';
import { IconWithPopoverTooltip } from '@/hybrid/core/IconWithPopoverTooltip.molecule';
import Select from 'react-select';

interface Option<T> {
  label: string;
  value: T;
}

export interface SelectIF extends ValidatingFormComponent<string> {
  component: 'SelectFormComponent';
  value: string;
  options: Option<any>[];
  placeholder?: string;
  label?: string;
  customErrorText?: string;
  required?: boolean;
  popoverContent?: React.ReactNode;
  insideModal?: boolean;
  disabled?: boolean;
}

/**
 * Native select form component with optional tool-tip/popover
 */
export const SelectFormComponent: React.FunctionComponent<SelectIF> = (
  props
) => {
  const {
    name,
    testId = 'select',
    label,
    options,
    validation,
    extendValidation,
    extraClassNames,
    customErrorText,
    placeholder,
    required,
    popoverContent,
    value,
    insideModal = false,
    disabled = false
  } = props;

  const defaultValidation = value => required && !value;

  const appliedValidation = getValidationFunction(
    defaultValidation,
    extendValidation,
    validation
  );

  const findSelectedLabel = () => _.find(options, option => option.value === value)?.label;

  const { t } = useTranslation();
  const formState = useForm().getState();
  const [showError, setShowError] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(findSelectedLabel());

  useEffect(() => {
    setSelectedLabel(findSelectedLabel() || selectedLabel);
  }, [options, value]);

  const getOptionLabel = (option: Option<any>) => `${t(option.label)}`;

  const getOptionValue = (option: Option<any>) => option.value;

  const handleChange = onChangeFn => (selected: Option<any>) => onChangeFn(selected.value);

  const menuPosition = insideModal ? 'absolute' : 'fixed';

  const portalStyle = { menuPortal: base => ({ ...base, zIndex: 9999 }) };

  return (
    <div className="flexRowContainer flexFill" id={name} data-testid={testId}>
      {label && (
        <div className="pb5">
          <span data-testid={`${testId}Label`}>{t(label)}</span>
        </div>
      )}
      <div
        className={classNames(
          'mb8',
          'flexColumnContainer',
          'flexAlignCenter',
          extraClassNames
        )}
        data-testid={`${testId}Field`}
      >
        <Field name={name} validate={appliedValidation}>
          {({ input, meta }) => {
            const formFieldProps = getFormFieldProps(
              formState,
              input,
              meta,
              props
            );
            setTimeout(() => setShowError(formFieldProps.showError), 0);

            return (
              <Select
                placeholder={t(placeholder)}
                className="reactSelectWrapper"
                classNamePrefix={classNames(formFieldProps.className, 'react-select')}
                value={formFieldProps.value ? { value: formFieldProps.value, label: selectedLabel } : undefined}
                options={options}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                onChange={handleChange(formFieldProps.onChange)}
                isSearchable={false}
                menuPortalTarget={document.body}
                menuPosition={menuPosition}
                isDisabled={disabled}
                styles={insideModal && portalStyle}
              />
            );
          }}
        </Field>
        {popoverContent && (
          <IconWithPopoverTooltip
            trigger="click"
            placement="top"
            rootClose={true}
            content={popoverContent}
            testId={`${testId}Popover`}
          />
        )}
      </div>
      {showError && customErrorText && (
        <FormError
          errorText={t(customErrorText)}
          dataTestId={`${testId}Error`}
        />
      )}
    </div>
  );
};
