import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Field, FormSpy } from 'react-final-form';
import { CheckMarkOrNumber } from '@/hybrid/core/CheckMarkOrNumber.atom';
import { AdvancedParameters } from '@/hybrid/core/AdvancedParameters.atom';
import { Icon } from '@/hybrid/core/Icon.atom';

export const FormCondition = ({ conditionName, children, show }) => (
  <Field name={conditionName} subscription={{ value: true }} key={`${conditionName}_field`}>
    {({ input: {} }) => show ? children : null}
  </Field>
);

export const ConditionalFormBlock = ({ isConditional, show, name, children }) => {
  if (isConditional) {
    return (<FormCondition conditionName={`${name}_condition`} show={show} key={`${name}_condition`}>
      {children}
    </FormCondition>);
  }
  return <React.Fragment key={`${name}_conditionBlock`}>{children}</React.Fragment>;
};

export const FormBlockWithCheckmarkAndNumber = ({ name, number, fieldNames, children, extraClassNames }) => {
  return (
    <div
      className={classNames('flexColumnContainer formElement mb10 mt15 ml15 mr15', extraClassNames)}
      key={name}
      id={`${name}-wrapper`}>
      <FormSpy subscription={{ errors: true, values: true }} key={`${name}_spy`}>
        {({ errors }) => {
          const valid = _.reduce(fieldNames, (acc, cur) => acc && !errors[cur], true);
          return (<CheckMarkOrNumber showNumber={!valid} number={number} />);
        }}
      </FormSpy>
      {children}
    </div>);
};

export const FormRowBlock = ({ name, testId, extraClassNames, children }) => (
  <div className={classNames('flexColumnContainer', extraClassNames)}
    key={`${name}_row`}
    id={name}
    data-testid={testId}>
    {children}
  </div>);

export const AdvancedFormBlock = ({ name, testId, toolName, toolId, toolStore, alternateName, children }) => (
  <div className="flexRowContainer advancedPanel toolOptions mb15" key={`${name}_wrapper`}
    data-testid={testId} id={name}>
    <AdvancedParameters
      toolName={toolName}
      toolId={toolId}
      toolStore={toolStore}
      alternateName={alternateName}>
      {children}
    </AdvancedParameters>
  </div>);

export const FormGroupBlock = ({ name, testId, extraClassNames, showBracket, children }) => (
  <div
    className={classNames('flexRowContainer', 'flexFill', extraClassNames, { leftBracket: showBracket })}
    key={`${name}_wrapper`}
    data-testid={testId}
    id={name}>
    {children}
  </div>);

export const RemovableFormBlock = ({ name, testId, children, iconAction, hideIcon }) => (
  <div className="removableBlockWrapper" data-testid={testId}>
    {!hideIcon &&
    <div className="closeIconWrapper cursorPointer" onClick={iconAction}>
      <Icon icon='fa-close' testId="deleteIcon" />
    </div>
    }
    <>
      {children}
    </>
  </div>
);

export const DisplayOnlyFormElementWrapper = ({ extraClassNames, testId, children }) => (
  <div className={classNames(extraClassNames)} data-testid={testId}>
    <>
      {children}
    </>
  </div>
);
