import _ from 'lodash';
import angular from 'angular';
import { AuthenticationService } from '@/services/authentication.service';
import { UtilitiesService } from '@/services/utilities.service';
import { StorageService } from '@/services/storage.service';
import { TrackService } from '@/track/track.service';
import { NotificationsService } from '@/services/notifications.service';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { DEBOUNCE, MAX_NAME_LENGTH, PASSWORD_MIN_LENGTH } from '@/main/app.constants';
import { SEEQ_VERSION } from '@/services/buildConstants.service';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { ModalService } from '@/services/modal.service';
import { AuthInputV1 } from 'sdk/model/AuthInputV1';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { UsersApi } from '@/sdk';

const dependencies = [
  'Sq.AppConstants',
  'Sq.Services.Authentication',
  'Sq.Services.Modal'
];

angular.module('Sq.Login', dependencies)
  .controller('LoginCtrl', LoginCtrl);

function LoginCtrl(
  $q: ng.IQService,
  availableDomains,
  $state: ng.ui.IStateService,
  $stateParams: ng.ui.IStateParamsService,
  $translate: ng.translate.ITranslateService,
  sqAuthentication: AuthenticationService,
  sqSystemConfiguration: SystemConfigurationService,
  sqUtilities: UtilitiesService,
  sqStorage: StorageService,
  sqTrack: TrackService,
  sqNotifications: NotificationsService,
  sqWorkbenchStore: WorkbenchStore,
  sqWorkbenchActions: WorkbenchActions,
  sqUsersApi: UsersApi,
  sqModal: ModalService
) {

  const vm = this;
  vm.MAX_NAME_LENGTH = MAX_NAME_LENGTH;
  vm.PASSWORD_MIN_LENGTH = PASSWORD_MIN_LENGTH;
  vm.PASSWORD_DEBOUNCE = DEBOUNCE.MEDIUM;
  vm.login = login;
  vm.register = register;
  vm.toggleDisplay = toggleDisplay;
  vm.availableDomains = availableDomains;
  vm.seeqDomain = _.find(vm.availableDomains, ['datasourceId', SeeqNames.LocalDatasources.Authentication.DatasourceId]);
  vm.domain = _.find(vm.availableDomains, ['datasourceId', sqStorage.store.getItem('sqDomain')]) ||
    (_.chain(vm.availableDomains)
      .reject(['datasourceId', SeeqNames.LocalDatasources.Authentication.DatasourceId]).first() as any).value() ||
    vm.seeqDomain;
  vm.isWindowsAuthEnabled = isWindowsAuthEnabled;
  vm.isSeeqAuthSelected = isSeeqAuthSelected;
  vm.isCompatibleWithUsernameAndPassword = isCompatibleWithUsernameAndPassword;
  vm.showEmailAndPassword = showEmailAndPassword;
  vm.shouldShowUsernameAndPassword = shouldShowUsernameAndPassword;
  vm.userRevealedUsernameAndPassword = false;
  vm.SEEQ_VERSION = SEEQ_VERSION;

  /**
   * Processing status flag. Set to true while the login attempt is in progress.
   */
  vm.processing = false;

  /**
   * Error message flag. Set to true to display an error message.
   */
  vm.showErrorMessage = false;

  /**
   * Error message returned from the server.
   */
  vm.serverMessage = undefined;

  /**
   * Display flag to determine whether the login or registration screen is displayed.
   */
  vm.displayLogin = true;

  /**
   * Flag indicating whether it's possible to register a new user from the Seeq login screen or not.
   */
  vm.registrationEnabled = sqSystemConfiguration.registrationEnabled;

  vm.atUserLimit = sqSystemConfiguration.atUserLimit;

  activate();

  function activate(): void {
    evaluateContinueOAuth2Login()
      .then((usedOAuth) => {
        if (!usedOAuth) {
          evaluateDirectorySelect();
          evaluateAutoLogin();
        }
      });
  }

  /**
   * Auto-chooses a directory if a directoryId was supplied via a login URL query parameter or from the
   * Authentication/DefaultProviderId config option. The query parameter takes precedence.
   */
  function evaluateDirectorySelect(): void {
    const directoryId = _.get($stateParams, 'directoryId') || sqSystemConfiguration.authDefaultProviderId;
    if (directoryId) {
      vm.domain = _.find(vm.availableDomains, ['datasourceId', directoryId]) || vm.domain;
    }
  }

  /**
   * Attempts auto-login if autoLogin was supplied as a login URL query parameter or from the
   * Authentication/AutoLogin config option. The query parameter takes precedence.
   * Supports auto-login for Windows Auth and OAuth providers.
   */
  function evaluateAutoLogin(): void {
    // Determine if the autoLogin query param is explicitly set to true or false
    const autoLoginQueryParam = _.toLower(_.get($stateParams, 'autoLogin'));
    const queryParamAutoLoginTrue = autoLoginQueryParam === 'true';
    const queryParamAutoLoginFalse = autoLoginQueryParam === 'false';

    // Determine if autoLogin is enabled for a Windows Auth  or OAuth datasource via the config system
    const settingsAutoLogin =
      (vm.domain.datasourceClass === SeeqNames.RemoteDatasourceClasses.WindowsAuth
        || vm.domain.datasourceClass === SeeqNames.RemoteDatasourceClasses.OAuth2)
      && sqSystemConfiguration.authAutoLogin;

    // The query parameter can override the settings file value to disable auto login if desired
    const doAutoLogin = queryParamAutoLoginTrue || (!queryParamAutoLoginFalse && settingsAutoLogin);

    if (doAutoLogin) {
      login();
    }
  }

  /**
   * Decides whether to continue with an OAuth 2.0 authentication attempt. If the right properties are there and the
   * right authentication Directory is selected, then continue with the authentication attempt.
   *
   * Returns a promise containing true if the OAuth 2.0 login was attempted, or false if it wasn't.
   */
  function evaluateContinueOAuth2Login(): ng.IPromise<boolean> {
    const authCredentials: AuthInputV1 = _.pick($stateParams, ['code', 'state']);
    if (authCredentials.code && authCredentials.state && isCompatibleWithCodeAndState()) {
      return login(authCredentials)
        .then(success => true, failure => true);
    }

    if ($stateParams.error === 'access_denied') {
      $translate('LOGIN_PANEL.ACCEPT_PERMISSIONS', { domain: vm.domain.name }).then(
        translation => vm.loginError = translation
      );
    }
    return $q.resolve(false);
  }

  /**
   * Toggles the display mode between login and registration form. Also resets any error messages as well
   * as the password.
   */
  function toggleDisplay() {
    vm.displayLogin = !vm.displayLogin;
    vm.showErrorMessage = false;
    vm.loginError = undefined;
    vm.serverMessage = undefined;
    vm.password = undefined;
  }

  /**
   * Submits the required login information to the server.
   */
  function login(authCredentials: AuthInputV1 = {}) {
    sqTrack.doTrack('System', 'Login Domain', vm.domain.datasourceClass + ' - ' + vm.domain.datasourceId);
    const credentials = { ...authCredentials, username: vm.username, password: vm.password };
    return doLogin(credentials, vm.domain.datasourceClass, vm.domain.datasourceId);
  }

  /**
   * Performs the actual login call and redirects to the proper location.
   * Also resets all form fields.
   *
   * @param {AuthInputV1} authCredentials - the auth credentials
   * @param {String} dataSourceClass - the dataSourceClass of the authentication provider selected
   * @param {String} dataSourceId - the dataSourceId of the authentication provider selected
   * @returns {Promise} that resolves when the login attempt is completed, either success or failure.
   * If the login fails this method also sets a member variable to show an error message.
   */
  function doLogin(authCredentials: AuthInputV1, dataSourceClass, dataSourceId) {
    vm.loginError = undefined;
    vm.showErrorMessage = false;
    vm.processing = true;
    let continueProcessing = false;
    sqStorage.store.setItem('sqDomain', vm.domain.datasourceId);

    // In some cases, params such as returnState may be lost during authentication with an outside identity provider
    if ($state.params.returnState) {
      sqStorage.store.setItem('stateParams', JSON.stringify($state.params));
    }

    return sqAuthentication.login(authCredentials, dataSourceClass, dataSourceId)
      .then(() => {
        doResetInput();
        return sqUtilities.returnToState()
          .then(() => {
            const user = sqWorkbenchStore.currentUser;
            const timeZone = sqWorkbenchStore.userTimeZone;
            if (!user.firstName || !user.lastName || !timeZone) {
              sqWorkbenchActions.setUserProfileDisplay(true);
            }
          });
      })
      .catch((e: any) => {
        if (_.get(e, 'status') === 401 && _.has(e, 'data.providerAuthURL')) {
          // 401 with a providerAuthURL means that we must be logging in with OAuth2 (or similar) and we need to
          // redirect the user in order for them to authenticate with the provider
          window.location = e.data.providerAuthURL;
          continueProcessing = true;
        } else if (!$state.transition) {
          // If the transition results in a redirect (see app.module's $stateChangeError handler)
          // then we could get a rejection from the first attempted transition

          // Resolves CRAB-15836 by never displaying the object containing the password
          vm.loginError = _.get(e, 'data.statusMessage', $translate.instant('LOGIN_PANEL.FRONTEND_ERROR'));
        }
      })
      .finally(function() {
        if (!continueProcessing) {
          vm.processing = false;
        }
        vm.password = undefined;
        vm.passwordCheck = undefined;
      });
  }

  /**
   * Returns whether Windows auth is enabled.
   */
  function isWindowsAuthEnabled() {
    return _.get(_.find(vm.domain.additionalProperties, ['name', SeeqNames.Properties.WindowsAuthMode]),
      'value') === 'Enabled';
  }

  /**
   * Returns whether Seeq Auth is selected.
   */
  function isSeeqAuthSelected() {
    return vm.domain.datasourceId === SeeqNames.LocalDatasources.Authentication.DatasourceId;
  }

  /**
   * Returns whether the selected domain is compatible with username and password authentication.
   */
  function isCompatibleWithUsernameAndPassword() {
    return _.get(
      _.find(vm.domain.additionalProperties, ['name', SeeqNames.Properties.UsernamePasswordCompatible]),
      'value'
    ) !== false;
  }

  /**
   * Returns whether the selected domain is compatible with code and state authentication.
   */
  function isCompatibleWithCodeAndState() {
    return !isCompatibleWithUsernameAndPassword();
  }

  /**
   * Returns whether the selected domain requires username and password authentication.
   */
  function requiresUsernameAndPassword() {
    return !isWindowsAuthEnabled() && isCompatibleWithUsernameAndPassword();
  }

  /**
   * Returns whether the username and password inputs should be displayed.
   */
  function shouldShowUsernameAndPassword() {
    return requiresUsernameAndPassword() || vm.userRevealedUsernameAndPassword && isCompatibleWithUsernameAndPassword();
  }

  /**
   * Forces display of the username and password inputs.
   */
  function showEmailAndPassword() {
    vm.userRevealedUsernameAndPassword = true;
  }

  /**
   * Helper function that resets all input fields. Password fields are always reset, but we reset them here, as
   * well, since otherwise they will not be reset until after the modals and intro have been closed.
   * This function is only called upon successful login or registration, but not if an error occurred so that the
   * user does not have to re-enter all their information.
   */
  function doResetInput() {
    vm.name = undefined;
    vm.firstName = undefined;
    vm.lastName = undefined;
    vm.username = undefined;
    vm.serverMessage = undefined;
    vm.password = undefined;
    vm.passwordCheck = undefined;
  }

  /**
   * Submits a request to generate a new user and executes a consecutive login.
   *
   * @returns {Promise} that resolves after the user was created and logged in
   */
  function register() {
    vm.showErrorMessage = false;
    vm.processing = true;

    return sqUsersApi.createUser({
        firstName: vm.firstName,
        lastName: vm.lastName,
        username: vm.username,
        email: vm.username,
        password: vm.password
      })
      .then(() => doLogin({ username: vm.username, password: vm.password },
        vm.seeqDomain.datasourceClass, vm.seeqDomain.datasourceId))
      .catch((response) => {
        const statusMessage = _.get(response, 'data.statusMessage');
        if (statusMessage) {
          vm.serverMessage = '(' + statusMessage + ')';
        }

        vm.showErrorMessage = true;
      })
      .finally(function() {
        vm.processing = false;
      });
  }
}
