import React from 'react';
import classNames from 'classnames';
import { bindingsDefinition, prop, injected } from "@/hybrid/core/bindings.util";
import { useFlux } from "@/hybrid/core/useFlux.hook";
import { useTranslation } from "@/hybrid/core/useTranslation.hook";
import { useInjectedBindings } from "@/hybrid/core/useInjectedBindings.hook";
import { DurationActions } from "@/trendData/duration.actions";
import { UtilitiesService } from "@/services/utilities.service";
import { DateTimeEntry } from "@/hybrid/core/DateTimeEntry.atom";
import { DurationStore } from "@/trendData/duration.store";
import { WorksheetStore } from "@/worksheet/worksheet.store";
import { angularComponent } from "@/hybrid/core/react2angular.util";
import { DurationEntry } from "@/hybrid/core/DurationEntry.atom";
import { AutoUpdate } from "@/hybrid/trend/AutoUpdate.molecule";
import { Icon } from "@/hybrid/core/Icon.atom";
import { HoverTooltip } from "@/hybrid/core/HoverTooltip.atom";

const displayRangeSelectorBindings = bindingsDefinition({
  sqDurationActions: injected<DurationActions>(),
  sqDurationStore: injected<DurationStore>(),
  sqUtilities: injected<UtilitiesService>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  formMode: prop.optional<boolean>(),
  autoUpdateDisabled: prop.optional<boolean>(),
  rangeEditingEnabled: prop.optional<boolean>(),
  durationEditingEnabled: prop.optional<boolean>(),
  investigateRangeCopyDisabled: prop.optional<boolean>(),
  timeZoneEditingEnabled: prop.optional<boolean>(),
  label: prop.optional<boolean>(),
  halfRangeButtonDisabled: prop.optional<boolean>(),
  trackCategory: prop.optional<string>(),
  trackAction: prop.optional<string>()
});

export const DisplayRangeSelector:SeeqComponent<typeof displayRangeSelectorBindings> = (props) => {
  const {
    sqDurationActions,
    sqUtilities,
    sqDurationStore,
    sqWorksheetStore
  } = useInjectedBindings(displayRangeSelectorBindings);

  const {
    label,
    formMode,
    autoUpdateDisabled,
    rangeEditingEnabled,
    durationEditingEnabled,
    timeZoneEditingEnabled = true,
    investigateRangeCopyDisabled,
    halfRangeButtonDisabled,
    trackCategory,
    trackAction
  } = props;

  const { t } = useTranslation();
  const {
    displayRange
  } = useFlux(sqDurationStore);

  const { timezone } = useFlux(sqWorksheetStore);

  const isPresentationMode = sqUtilities.isPresentationWorkbookMode;
  const displayRangeActions = sqDurationActions.displayRange;

  return (
    <div className="flexColumnContainer flexSpaceBetween displayRangeSelector pb2">
      <div className="flexRowContainer">
        {formMode &&
          <label className={classNames("ml15 font-size-smaller", { disabled: !rangeEditingEnabled })}>
            {t('START')}
          </label>
        }
        <div className="flexColumnContainer">
          {!isPresentationMode && !investigateRangeCopyDisabled &&
            <HoverTooltip text={t('RANGE_SELECTOR.COPY_TO_INVESTIGATE')} delay={1500} placement="bottom">
              <span onClick={sqDurationActions.investigateRange.copyFromDisplayRange} className="copyToInvestigateIcon">
                <Icon icon="fc fc-arrow-down btn btn-link btn-xs cursorPointer link-no-underline" />
              </span>
            </HoverTooltip>
          }
          {isPresentationMode && <div className="ml35" />}

          <div id="displayRangeStart" className={classNames(
            "displayRangeDateTime",
            { ml15: !isPresentationMode && investigateRangeCopyDisabled })}>
            <DateTimeEntry
              extraClassName={classNames("small", { readOnly: !rangeEditingEnabled })}
              testId="specDisplayRangeStart"
              date={displayRange.start}
              updateDate={date => setTimeout(() => displayRangeActions.updateStart(date))}
              inputExtraClassNames="'textAlignLeft'"
              updateBothDates={displayRangeActions.updateTimes}
              timezone={timezone}
              otherDate={displayRange.end}
              fieldIdentifier="'DISPLAY_RANGE_START'"
              readOnly={!rangeEditingEnabled}
              timeZoneReadOnly={!timeZoneEditingEnabled}
              trackCategory="'RangeSelector'"
              trackAction="'DateTimeEntry'"
              trackInformation="'Start Date set'"
            />
          </div>
        </div>
      </div>

      <div className="flexRowContainer">
        {label && <em>{label}</em>}
        {formMode &&
          <label className={classNames("flexSelfCenter font-size-smaller text-center mr20", { mr20: rangeEditingEnabled })}>
            {t('DURATION')}
          </label>
        }

        <div className={classNames("flexColumnContainer", { flexCenter: formMode })} id="displayRangeNavigation">
          {rangeEditingEnabled &&
            <HoverTooltip text={t('RANGE_SELECTOR.STEP_BACK_FULL')} delay={1500} placement="bottom">
              <span onClick={displayRangeActions.stepBack} data-testid="rangeSelectorStepBackFull" className="rangeSelectorStep">
                <Icon icon="fc fc-step-back-full btn btn-link btn-xs cursorPointer link-no-underline" />
              </span>
            </HoverTooltip>
          }
          {rangeEditingEnabled && !halfRangeButtonDisabled &&
            <HoverTooltip text={t('RANGE_SELECTOR.STEP_BACK_HALF')} delay={1500} placement="bottom">
              <span onClick={displayRangeActions.stepBackHalf} data-testid="rangeSelectorStepBackHalf" className="rangeSelectorStep">
                <Icon icon="fc fc-step-back-half btn btn-link btn-xs cursorPointer link-no-underline" />
              </span>
            </HoverTooltip>
          }

          {(rangeEditingEnabled || durationEditingEnabled) &&
            <DurationEntry
              extraClassName={classNames({ 'min-width-50': formMode })}
              startDate={displayRange.start}
              endDate={displayRange.end}
              updateDuration={displayRangeActions.updateDuration}
              updateDates={displayRangeActions.updateTimes}
              timezone={timezone}
              read-only={!rangeEditingEnabled && !durationEditingEnabled}
            />
          }
          {rangeEditingEnabled && !halfRangeButtonDisabled &&
            <HoverTooltip text={t('RANGE_SELECTOR.STEP_FORE_HALF')} delay={1500} placement="bottom">
              <span
                onClick={displayRangeActions.stepForwardHalf}
                data-testid="rangeSelectorStepForwardHalf"
                className="rangeSelectorStep">
                <Icon icon="fc fc-step-fore-half btn btn-link btn-xs cursorPointer link-no-underline" />
              </span>
            </HoverTooltip>
          }
          {rangeEditingEnabled &&
            <>
              <HoverTooltip text={t('RANGE_SELECTOR.STEP_FORE_FULL')} delay={1500} placement="bottom">
                <span
                  onClick={displayRangeActions.stepForward}
                  data-testid="rangeSelectorStepForward"
                  className="rangeSelectorStep">
                  <Icon icon="fc fc-step-fore-full btn btn-link btn-xs cursorPointer link-no-underline" />
                </span>
              </HoverTooltip>
              <HoverTooltip text={t('RANGE_SELECTOR.STEP_TO_END')} delay={1500} placement="bottom">
                <span
                  onClick={displayRangeActions.stepToEnd}
                  data-testid="rangeSelectorStepToEnd"
                  className="rangeSelectorStep">
                  <Icon icon="fc fc-arrow-right-right btn btn-link btn-xs cursorPointer link-no-underline" />
                </span>
              </HoverTooltip>
            </>
          }
          {!isPresentationMode && !autoUpdateDisabled && sqDurationActions.autoUpdate.allowed() && <AutoUpdate />}
        </div>
      </div>
      <div className="flexRowContainer">
        {formMode &&
          <label className={classNames("font-size-smaller mr10", { disabled: !rangeEditingEnabled })}>
            {t("END")}
          </label>
        }
        <div className={
          classNames("flexColumnContainer displayRangeDateTime",
            { 'min-width-195': !formMode })} id="displayRangeEnd">
          <DateTimeEntry
            testId="specDisplayRangeEnd"
            extraClassName={classNames(
              "small min-width-150 flexFill",
              { 'text-right': !formMode, mr15: !formMode, readOnly: !rangeEditingEnabled })}
            date={displayRange.end}
            updateDate={displayRangeActions.updateEnd}
            updateBothDates={displayRangeActions.updateTimes}
            timezone={timezone}
            otherDate={displayRange.start}
            fieldIdentifier="'DISPLAY_RANGE_END'"
            readOnly={!rangeEditingEnabled}
            timeZoneReadOnly={!timeZoneEditingEnabled}
            trackCategory={trackCategory}
            trackAction={trackAction}
            trackInformation="'End Date set'"
          />
        </div>
      </div>
    </div>
  );
};

export const sqDisplayRangeSelector = angularComponent(displayRangeSelectorBindings, DisplayRangeSelector);
