import angular from 'angular';

angular.module('Sq.Annotation').controller('JournalEntryCtrl', JournalEntryCtrl);

function JournalEntryCtrl() {
  const vm = this;
  vm.customExpandComments = customExpandCommentsFunc;
  vm.customSaveComment = customSaveCommentFunc;

  function customExpandCommentsFunc(expand) {
    vm.expandComments({ expand });
  }

  function customSaveCommentFunc(id, name) {
    vm.saveComment({ id, name });
  }
}
