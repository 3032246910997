import React, { useEffect } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ReportStore } from '@/reportEditor/report.store';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { ReportActions } from '@/reportEditor/report.actions';
import { Icon } from '@/hybrid/core/Icon.atom';
import _ from 'lodash';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { DateTimeService } from '@/datetime/dateTime.service';
import { DateRange, ReportClickActions } from '@/reportEditor/report.module';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { TrackService } from '@/track/track.service';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';

const reportConfigDateRangeBindings = bindingsDefinition({
  range: prop<DateRange>(),
  timezone: prop<string>(),
  openDateSelector: prop<(dateRange: DateRange, checkFixed: boolean) => void>(),
  isAutoUpdate: prop<boolean>(),
  viewOnly: prop.optional<boolean>(),
  areDateRangesReadOnly: prop<boolean>(),
  stepDateRangeToEnd: prop.optional<(dateRange: DateRange) => void>(),
  actionWrapper: prop<(action: Function, actionType: ReportClickActions) => (...actionArgs) => any>(),
  reClickAction: prop<ReportClickActions>(),
  sqReportStore: injected<ReportStore>(),
  sqReportActions: injected<ReportActions>(),
  sqReportContent: injected<ReportContentService>(),
  sqDateTime: injected<DateTimeService>(),
  sqTrack: injected<TrackService>()
});

export const ReportConfigDateRange: SeeqComponent<typeof reportConfigDateRangeBindings> = (props) => {
  const {
    sqReportStore,
    sqReportActions,
    sqReportContent,
    sqDateTime,
    sqTrack
  } = useInjectedBindings(reportConfigDateRangeBindings);
  const {
    range,
    timezone,
    openDateSelector,
    isAutoUpdate,
    stepDateRangeToEnd,
    areDateRangesReadOnly,
    reClickAction,
    actionWrapper,
    viewOnly = false
  } = props;

  const showStepToEnd = !isAutoUpdate && !_.isUndefined(stepDateRangeToEnd);
  const dateRangeToContentMap = useFluxPath(sqReportStore, () => sqReportStore.dateRangeToContentMap);

  const { t } = useTranslation();

  const formatDateRange = (dateRange: DateRange) => {
    return sqDateTime.formatTime(dateRange.range.start, { name: timezone }) + ' - '
      + sqDateTime.formatTime(dateRange.range.end, { name: timezone });
  };

  const isDateRangeUsed = (dateRangeId: string) => _.get(dateRangeToContentMap, dateRangeId, []).length > 0;

  const refreshContentUsingDate = (dateRange: DateRange) => {
    sqTrack.doTrack('Topic', 'Date Range Tool', 'refresh content');
    if (!areDateRangesReadOnly) {
      sqReportContent.forceRefreshContentUsingDate(dateRange.id);
    }
  };

  const onClickRefresh = () => {
    actionWrapper(refreshContentUsingDate, ReportClickActions.Refresh)(range);
  };

  const onClickStep = () =>
    actionWrapper(stepDateRangeToEnd, ReportClickActions.Step)(range);

  const onClickWarning = () => {
    actionWrapper(openBulkEditWithGivenRange, ReportClickActions.Warning)(range);
  };

  const openSelector = () => actionWrapper(openDateSelector, ReportClickActions.Edit)(range, !isAutoUpdate);

  const openBulkEditWithGivenRange = (dateRange: DateRange) => {
    sqTrack.doTrack('Topic', 'Date Range Tool', 'edit - warning icon');
    if (!areDateRangesReadOnly) {
      sqReportActions.setShowBulkEditModal(true);
      sqReportActions.setBulkDateRange(dateRange);
    }
  };

  const onReClick = (action: ReportClickActions) => ({
    [ReportClickActions.None]: _.noop,
    [ReportClickActions.Step]: onClickStep,
    [ReportClickActions.Edit]: openSelector,
    [ReportClickActions.Refresh]: onClickRefresh,
    [ReportClickActions.Warning]: onClickWarning
  }[action]());

  useEffect(() => {
    let mounted = true;
    if(mounted) {
      onReClick(reClickAction);
    }
    // Returning a cleanup function here that just disables the effect helps address "React state update on an
    // unmounted component" error. See https://dev.to/otamnitram/react-useeffect-cleanup-how-and-when-to-use-it-2hbm
    // for more details.
    return () => mounted = false;
  }, [reClickAction]);

  const displayThisRange = viewOnly ? isDateRangeUsed(range.id) : true;
  return displayThisRange ? <div className="flexRowContainer mb5" data-testid="dateRange">
    <div className="flexColumnContainer mr5 pt5">
      <label className="mb0 flexFill">{range.name}</label>
      {range.condition?.isRedacted &&
      <span>{t('ACCESS_CONTROL.REDACTED')}</span>}
      {!range.condition.isRedacted &&
      <a className="ml15 link-no-focus link-no-underline flexColumnContainer flexCenter cursorPointer">
        <div className="flexFill small text-italic text-right text-underline"
          onClick={() => {
            sqTrack.doTrack('Topic', 'Date Range Tool', 'edit - condition link');
            openSelector();
          }}
          data-testid={`dateRangeConditionName_${range.id}`}>
          {range.condition.name}
        </div>
        {range.condition.id &&
        <Icon
          icon="fc-capsule"
          extraClassNames="ml5"
          testId={`dateRangeCapsuleIcon_${range.id}`}
          onClick={() => {
            sqTrack.doTrack('Topic', 'Date Range Tool', 'edit - capsule icon');
            openSelector();
          }} />}

        {isDateRangeUsed(range.id) &&
        <HoverTooltip text="REPORT.CONFIG.REFRESH_CONTENT_FOR_DATE_RANGE">
          <div data-testid={`dateRangeRefreshIcon_${range.id}`}
            onClick={onClickRefresh}>
            <Icon
              icon="fc-redo fa-fw fa-sm"
              extraClassNames="cursorPointer ml5 mt2" />
          </div>
        </HoverTooltip>}
        {!isDateRangeUsed(range.id) &&
        <HoverTooltip text="REPORT.CONFIG.DATE_UNUSED">
          <div data-testid={`dateRangeWarningIcon_${range.id}`}
            onClick={onClickWarning}>
            <Icon
              icon="fa-warning"
              extraClassNames="ml5 mt1"
              type="warning" />
          </div>
        </HoverTooltip>}
        {showStepToEnd && !range.condition.isCapsuleFromTable &&
        <HoverTooltip text="REPORT.CONFIG.STEP_TO_END">
          <div data-testid={`stepFixedToNowIcon_${range.id}`}
            onClick={onClickStep}>
            <Icon
              icon="fc-arrow-right-right"
              extraClassNames="ml5" />
          </div>
        </HoverTooltip>}
        {showStepToEnd && range.condition.isCapsuleFromTable &&
        <HoverTooltip text="REPORT.CONFIG.STEP_TO_END_DISABLED">
          <div data-testid={`stepFixedToNowIconDisabled_${range.id}`}>
            <Icon
              icon="fc-arrow-right-right disabledLook"
              extraClassNames="ml5" />
          </div>
        </HoverTooltip>}
        {!showStepToEnd &&
        <Icon
          icon="fa-fw"
          extraClassNames="ml2" />}
        <div>
          <Icon
            icon="fa-fw fa-edit"
            extraClassNames="ml6 mr2 mt2"
            testId={`dateRangeEditIcon_${range.id}`}
            onClick={() => {
              sqTrack.doTrack('Topic', 'Date Range Tool', 'edit - edit icon');
              openSelector();
            }} />
        </div>
      </a>
      }
    </div>
    <div data-testid="dateRangeSubtext">
      {(!isAutoUpdate || !range.auto.noCapsuleFound)
      && <span>{formatDateRange(range)}</span>}
      {(isAutoUpdate && range.auto.noCapsuleFound)
      && <span>{t('REPORT.CONTENT.NO_CAPSULE_FOUND')}</span>}
    </div>
  </div>
    : null;
};
