import _ from 'lodash';
import React, { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { PriorityV1 } from 'sdk/model/PriorityV1';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ButtonWithPopoverDropdown } from '@/hybrid/core/ButtonWithPopoverDropdown.atom';
import { TextFormatterPopover } from '@/hybrid/core/TextFormatterPopover.molecule';
import { TableTextFormatterIF } from '@/hybrid/tableBuilder/TableBuilder.page';
import { TableBuilderColumnFilter } from '@/hybrid/tableBuilder/tableBuilder.store';
import { TableBuilderFilterPopover } from '@/hybrid/tableBuilder/tableComponents/TableBuilderFilterPopover.organism';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { TABLE_BUILDER } from '@/hybrid/tableBuilder/tableBuilder.module';
import { TrackService } from '@/track/track.service';

export enum TextHeaderMenuActions {
  Style = 'style',
  Rename = 'rename',
  Remove = 'remove',
  Filter = 'filter',
  Sort = 'sort'
}

export interface TextHeaderMenuIF {
  canRenameHeader: boolean;
  columnKey: string;
  columnBackgroundColor?: string;
  columnTextAlign?: string;
  columnTextColor?: string;
  columnTextStyle?: string[];
  headerBackgroundColor?: string;
  headerTextAlign?: string;
  headerTextColor?: string;
  headerTextStyle?: string[];
  isTransposed: boolean;
  target: any;
  textFormatter: TableTextFormatterIF;
  editHeaderValue: () => void;
  removeColumn: (key: string) => void;
  actions?: TextHeaderMenuActions[];
  setColumnFilter?: (key: string, filter: TableBuilderColumnFilter) => void;
  columnFilter?: TableBuilderColumnFilter;
  isFilterDisabled?: boolean;
  filterHelpText?: string;
  canSort?: boolean;
  canSortDisabledTooltip?: string;
  sortDirection?: string;
  sortByColumn: (key: string, direction: string) => void;
  isStringColumn?: boolean;
  distinctStringValues?: string[];
  thresholds?: any[] | undefined;
}

const tableBuilderTextHeaderMenuBindings = bindingsDefinition({
  sqSystemConfiguration: injected<SystemConfigurationService>(),
  sqTrack: injected<TrackService>()
});

export const TableBuilderTextHeaderMenu: React.FunctionComponent<TextHeaderMenuIF> = (props) => {
  const { sqSystemConfiguration, sqTrack } = useInjectedBindings(tableBuilderTextHeaderMenuBindings);

  const {
    canRenameHeader,
    columnKey,
    columnBackgroundColor,
    columnTextAlign,
    columnTextColor,
    columnTextStyle,
    headerBackgroundColor,
    headerTextAlign,
    headerTextColor,
    headerTextStyle,
    isTransposed,
    target,
    textFormatter,
    editHeaderValue,
    removeColumn,
    actions = _.values(TextHeaderMenuActions),
    setColumnFilter,
    columnFilter = undefined,
    isFilterDisabled = false,
    filterHelpText = undefined,
    canSort = false,
    canSortDisabledTooltip,
    sortByColumn,
    sortDirection = undefined,
    isStringColumn = false,
    distinctStringValues = undefined,
    thresholds = undefined
  } = props;

  const priorities: PriorityV1[] = sqSystemConfiguration.priorityColors;
  const colors: string[] = _.chain(priorities).map('color').uniq().value();

  const [show, setShow] = useState(new Map());
  const updateState = (key, value) => {
    setShow(prev => new Map(prev).set(key, value));
  };
  const showDropdown = key => updateState(key, !show.get(key));
  const hideDropdown = key => updateState(key, false);

  const { t } = useTranslation();
  const [showColumnFormatPopover, setShowColumnFormatPopover] = useState(false);
  const [showHeaderFormatPopover, setShowHeaderFormatPopover] = useState(false);
  const [showColumnFilterPopover, setShowColumnFilterPopover] = useState(false);
  const renderNavDropdownItem = (menuEntry, parentKey = undefined) => {
    return <NavDropdown.Item
      disabled={menuEntry.disabled}
      key={`dropdown_${menuEntry.translationKey}`}
      onClick={() => {
        menuEntry.action();
        if (!_.isUndefined(parentKey)) {
          hideDropdown(parentKey);
        }
      }}
      className="textPrimaryMenuItem"
      data-testid={`actions_${menuEntry.translationKey}`}>
      <Icon icon={menuEntry.iconClass} extraClassNames="pr10 fa-fw" type="inherit" />
      <span className="forceFont">
        {t(menuEntry.translationKey)}
      </span>
    </NavDropdown.Item>;
  };

  const renderSimpleMenuEntry = (menuEntry, parentKey = undefined) => {
    return !menuEntry.tooltip ? renderNavDropdownItem(menuEntry, parentKey) :
      <HoverTooltip
        key={`tooltipContainer_${menuEntry.translationKey}`}
        text={menuEntry.tooltip}>
        {/* the tooltip does not appear for disabled entries if we do not have the div*/}
        <div>
          {renderNavDropdownItem(menuEntry, parentKey)}
        </div>
      </HoverTooltip>;
  };

  const renderMenuEntry = (menuEntry) => {
    return <NavDropdown
      title={
        <>
          <Icon icon={menuEntry.iconClass} extraClassNames="pr10 fa-fw" type="inherit" />
          <span className="forceFont">
            {t(menuEntry.translationKey)}
          </span>
        </>
      }
      id={`dropdown_${menuEntry.translationKey}`}
      key={`dropdown_${menuEntry.translationKey}`}
      drop="right"
      className="textPrimaryMenuItem"
      data-testid={`actions_${menuEntry.translationKey}`}
      show={show.get(menuEntry.translationKey)}
      onMouseEnter={() => showDropdown(menuEntry.translationKey)}
      onMouseLeave={() => hideDropdown(menuEntry.translationKey)}>
      {_.map(menuEntry.subMenuItems, submenuItem => renderSimpleMenuEntry(submenuItem, menuEntry.translationKey))}
    </NavDropdown>;
  };
  const renderDropdownEntry = (menuEntry) => {
    return menuEntry.subMenuItems ?
      // workaround because the disable property of the NavDropdown does not work
      menuEntry.disabled ? renderSimpleMenuEntry(menuEntry) : renderMenuEntry(menuEntry)
      :
      renderSimpleMenuEntry(menuEntry);
  };

  const columnActions = _.filter([{
    iconClass: 'fa-filter',
    translationKey: 'TABLE_BUILDER.FILTER',
    actionKey: TextHeaderMenuActions.Filter,
    action: () => setShowColumnFilterPopover(true),
    disabled: isFilterDisabled,
    tooltip: !isFilterDisabled ? false : 'TABLE_BUILDER.ONLY_ON_TABLE_WITH_ITEMS_SAME_TYPE'
  }, {
    actionKey: TextHeaderMenuActions.Sort,
    iconClass: 'fa-sort',
    translationKey: 'TABLE_BUILDER.SORT',
    disabled: !canSort,
    tooltip: canSortDisabledTooltip,
    subMenuItems: [{
      disabled: 'asc' === sortDirection,
      iconClass: 'fa-sort-amount-asc',
      translationKey: 'TABLE_BUILDER.SORT_ASCENDING',
      action: () => {
        sortByColumn(columnKey, 'asc');
        sqTrack.doTrack(TABLE_BUILDER, 'sort', 'enabled');
      }
    }, {
      disabled: 'desc' === sortDirection,
      iconClass: 'fa-sort-amount-desc',
      translationKey: 'TABLE_BUILDER.SORT_DESCENDING',
      action: () => {
        sortByColumn(columnKey, 'desc');
        sqTrack.doTrack(TABLE_BUILDER, 'sort', 'enabled');
      }
    }, {
      disabled: _.isUndefined(sortDirection),
      iconClass: 'fa-times',
      translationKey: 'TABLE_BUILDER.SORT_REMOVE',
      action: () => {
        sortByColumn(columnKey, undefined);
        sqTrack.doTrack(TABLE_BUILDER, 'sort', 'disabled');
      }
    }]
  }, {
    iconClass: 'fa-list-alt',
    translationKey: 'TABLE_BUILDER.HEADER_PROPERTIES',
    actionKey: TextHeaderMenuActions.Style,
    action: () => setShowHeaderFormatPopover(true)
  }, {
    iconClass: 'fa-columns',
    translationKey: isTransposed ? 'TABLE_BUILDER.ROW_PROPERTIES' : 'TABLE_BUILDER.COLUMN_PROPERTIES',
    actionKey: TextHeaderMenuActions.Style,
    action: () => setShowColumnFormatPopover(true)
  }, {
    iconClass: 'fc-paintbrush',
    translationKey: 'TABLE_BUILDER.APPLY_STYLE_TO_ALL',
    actionKey: TextHeaderMenuActions.Style,
    subMenuItems: [{
      iconClass: 'fa-list-alt',
      translationKey: 'TABLE_BUILDER.HEADERS',
      action: () => textFormatter.setStyleToAllHeaders(columnKey)
    }, {
      iconClass: 'fa-columns',
      translationKey: isTransposed ? 'TABLE_BUILDER.ROWS' : 'TABLE_BUILDER.COLUMNS',
      action: () => textFormatter.setStyleToAllColumns(columnKey)
    }, {
      iconClass: 'fa-table',
      translationKey: isTransposed ? 'TABLE_BUILDER.ROWS_AND_HEADERS' : 'TABLE_BUILDER.COLUMNS_AND_HEADERS',
      action: () => textFormatter.setStyleToAllHeadersAndColumns(columnKey)
    }]
  }, {
    iconClass: 'fa-copy',
    translationKey: 'TABLE_BUILDER.COPY_STYLE',
    actionKey: TextHeaderMenuActions.Style,
    action: () => textFormatter.copyStyle(columnKey)
  }, {
    iconClass: 'fc-paintbrush',
    translationKey: 'TABLE_BUILDER.PASTE_STYLE_OF',
    actionKey: TextHeaderMenuActions.Style,
    subMenuItems: [{
      iconClass: 'fa-list-alt',
      translationKey: 'TABLE_BUILDER.HEADER',
      action: () => textFormatter.pasteStyleOnHeader(columnKey)
    }, {
      iconClass: 'fa-columns',
      translationKey: isTransposed ? 'TABLE_BUILDER.ROW' : 'TABLE_BUILDER.COLUMN',
      action: () => textFormatter.pasteStyleOnColumn(columnKey)
    }, {
      iconClass: 'fa-table',
      translationKey: isTransposed ? 'TABLE_BUILDER.ROW_AND_HEADER' : 'TABLE_BUILDER.COLUMN_AND_HEADER',
      action: () => textFormatter.pasteStyleOnHeaderAndColumn(columnKey)
    }]
  }, {
    iconClass: 'fa-edit',
    translationKey: 'TABLE_BUILDER.RENAME_HEADER',
    actionKey: TextHeaderMenuActions.Rename,
    action: () => editHeaderValue(),
    disabled: !canRenameHeader
  }, {
    iconClass: 'fa-times',
    translationKey: 'TABLE_BUILDER.REMOVE',
    actionKey: TextHeaderMenuActions.Remove,
    action: () => removeColumn(columnKey)
  }], ({ actionKey }) => _.includes(actions, actionKey));

  return <div>
    <ButtonWithPopoverDropdown
      icon={<Icon icon="fc-more" extraClassNames="fa-fw mt2" type="gray" />}
      iconWrapperClasses="sq-icon-hover cursorPointer"
      testId="columnMoreActions"
      id={`dropdown-${columnKey}`}>
      {_.map(columnActions, action => renderDropdownEntry(action))}
    </ButtonWithPopoverDropdown>
    <TextFormatterPopover
      backgroundColor={headerBackgroundColor}
      textAlign={headerTextAlign}
      textColor={headerTextColor}
      textStyle={headerTextStyle}
      colors={colors}
      columnKey={columnKey}
      onClose={() => setShowHeaderFormatPopover(false)}
      setTextAlign={(columnKey, alignment) => textFormatter.setHeaderTextAlign(columnKey, alignment)}
      setTextBackground={(columnKey, color) => textFormatter.setHeaderBackground(columnKey, color)}
      setTextColor={(columnKey, color) => textFormatter.setHeaderTextColor(columnKey, color)}
      setTextStyle={(columnKey, styles) => textFormatter.setHeaderTextStyle(columnKey, styles)}
      placement="bottom"
      target={target?.current}
      show={showHeaderFormatPopover}
      title='TABLE_BUILDER.HEADER_PROPERTIES' />
    <TextFormatterPopover
      backgroundColor={columnBackgroundColor}
      textAlign={columnTextAlign}
      textColor={columnTextColor}
      textStyle={columnTextStyle}
      colors={colors}
      columnKey={columnKey}
      onClose={() => setShowColumnFormatPopover(false)}
      setTextAlign={(columnKey, alignment) => textFormatter.setColumnTextAlign(columnKey, alignment)}
      setTextBackground={(columnKey, color) => textFormatter.setColumnBackground(columnKey, color)}
      setTextColor={(columnKey, color) => textFormatter.setColumnTextColor(columnKey, color)}
      setTextStyle={(columnKey, styles) => textFormatter.setColumnTextStyle(columnKey, styles)}
      placement="bottom"
      target={target?.current}
      show={showColumnFormatPopover}
      title={isTransposed ? 'TABLE_BUILDER.ROW_PROPERTIES' : 'TABLE_BUILDER.COLUMN_PROPERTIES'} />
    <TableBuilderFilterPopover
      columnFilter={columnFilter}
      columnKey={columnKey}
      notifyOnClose={() => setShowColumnFilterPopover(false)}
      setColumnFilter={(columnKey, filter) => setColumnFilter(columnKey, filter)}
      isStringColumn={isStringColumn}
      distinctStringValues={distinctStringValues}
      thresholds={thresholds}
      helpText={filterHelpText}
      placement="bottom"
      target={target?.current}
      show={_.includes(actions, TextHeaderMenuActions.Filter) && showColumnFilterPopover} />
  </div>;
};
