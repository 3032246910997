import _ from 'lodash';
import angular from 'angular';
import HttpCodes from 'http-status-codes';
import notificationDefaultTemplate from './notificationDefault.html';
import notificationWithButtonTemplate from './notificationWithButton.html';
import bind from 'class-autobind-decorator';
import { TrackService } from '@/track/track.service';
import { UtilitiesService } from '@/services/utilities.service';
import { RedactionService } from '@/services/redaction.service';
import { APP_STATE, DEBOUNCE } from '@/main/app.constants';

/**
 * @file Displays one-time notifications to the user.
 */

type Type = 'info' | 'warning' | 'error' | 'success';
const SCE_PARAMETERS = 'sceParameters';

@bind
export class NotificationsService {
  private readonly alerts = [];

  constructor(private sqLogger,
    private $rootScope: ng.IRootScopeService,
    private $state: ng.ui.IStateService,
    private $templateCache: ng.ITemplateCacheService,
    private $translate: ng.translate.ITranslateService,
    private Notification,
    private sqTrack: TrackService,
    private sqUtilities: UtilitiesService,
    private sqRedaction: RedactionService,
    private AUTO_DEFAULT_CLOSE_INTERVAL,
    private AUTO_ERROR_CLOSE_INTERVAL,
    private AUTO_UNDO_CLOSE_INTERVAL) {
  }

  infoTranslate = (key, params?, alertProps?) => this.translateNotification('info', key, params, alertProps);
  warnTranslate = (key, params?, alertProps?) => this.translateNotification('warn', key, params, alertProps);
  errorTranslate = (key, params?, alertProps?, options?) =>
    this.translateNotification('error', key, params, alertProps, options);
  successTranslate = (key, params?, alertProps?) => this.translateNotification('success', key, params, alertProps);

  /**
   * Close all alerts that match the specified message and/or type. If both are omitted, then all alerts are closed.
   *
   * @param  {String} [message] - Message string to match
   * @param  {String} [type] - Type to match
   * @return {Number} Number of alerts that were closed
   */
  closeAlerts(message?: string, type?: Type) {
    const alertsToClose = _.filter(this.alerts, function(alert) {
      return (_.isUndefined(message) || (alert.message === message)) &&
        (_.isUndefined(type) || (alert.type === type));
    });

    // We can't iterate directly over the alerts array because calling .killHard results in
    // {@link removeClosedAlert} being called, which removes elements from the alerts array, thus modifying
    // the collection over which we would be iterating.
    _.forEach(alertsToClose, function(alert) {
      alert.killHard?.();
    });

    return alertsToClose.length;
  }

  /**
   * Function to cleanup artifacts of an alert that has been closed
   *
   * @param  {Object} alert - Alert object to remove
   */
  private removeClosedAlert(alert) {
    _.remove(this.alerts, alert);
  }

  /**
   * Return a boolean indicating whether display of notifications should be suppressed.
   *
   * @return {Boolean} - True if notifications should be suppressed; if false, notifications can be displayed
   */
  private suppressNotificationDisplay() {
    return _.includes([APP_STATE.LOAD_ERROR, APP_STATE.LOGIN], this.$state.current.name) ||
      this.sqUtilities.headlessRenderMode();
  }

  /**
   * Creates a new notification.
   *
   * @param  {String} message - Plaintext or HTML string to display for this alert.
   * @param  {String} type - Type for this alert: info, warning, error, success.
   * @param  {Object} alertProps - an object containing properties that will be assigned to the alert object
   * @param  {Object} options - container for options regarding alerts
   * @param  {boolean} options.skipTracking - skips tracking the message in the alert
   */
  private createAlert(message: string, type: Type, alertProps, options = { skipTracking: false }) {
    if (!options.skipTracking) {
      this.sqTrack.doTrack('Warning', type + (this.suppressNotificationDisplay() ? '(suppressed)' : ''), message);
    }

    // If notifications are suppressed, just return
    if (this.suppressNotificationDisplay()) {
      return;
    }

    if (!this.$templateCache.get('notificationDefault.html')) {
      this.$templateCache.put('notificationDefault.html', notificationDefaultTemplate);
    }

    const alert = {
      message,
      type,
      templateUrl: 'notificationDefault.html',
      delay: this.AUTO_DEFAULT_CLOSE_INTERVAL,
      onClose: () => this.removeClosedAlert(alert),
      killHard: null
    };

    // Override the auto-close interval for warnings and errors
    if (type === 'warning' || type === 'error') {
      alert.delay = this.AUTO_ERROR_CLOSE_INTERVAL;
    }

    _.assign(alert, alertProps);

    this.closeAlerts(message, type);

    this.Notification(alert, type).then((scope) => {
      alert.killHard = _.partial(scope.kill, true);
    });

    this.alerts.push(alert);
  }

  /**
   * Display an informational message to the user, and log the message to the logger at the Info level.
   *
   * @param  {String} message - Message to display. This string may include HTML markup, such as links.
   * @param {Object} [alertProps] - Object containing the desired custom template and/or notification delay.
   */
  info(message, alertProps?) {
    this.sqLogger.info(message);
    this.createAlert(message, 'info', alertProps);
  }

  /**
   * Display a warning message to the user, and log the message to the logger at the Warn level.
   *
   * @param  {String} message - Message to display. This string may include HTML markup, such as links.
   * @param {Object} [alertProps] - Object containing the desired custom template and/or notification delay.
   */
  warn(message, alertProps?) {
    this.sqLogger.warn(message);
    this.createAlert(message, 'warning', alertProps);
  }

  warning = this.warn;

  /**
   * Display an error message to the user, and log the message to the logger at the Error level.
   *
   * @param  {String} message - Message to display. This string may include HTML markup, such as links.
   * @param {Object} [alertProps] - Object containing the desired custom template and/or notification delay.
   * @param {Object} options - Object containing options for notifications/alerts
   */
  error(message, alertProps?, options?) {
    this.sqLogger.error(message);
    this.createAlert(message, 'error', alertProps, options);
  }

  /**
   * Display a success message to the user, and log the message to the logger at the Info level.
   *
   * @param {String} message - Message to display. This string may include HTML markup, such as links.
   * @param {Object} [alertProps] - Object containing the desired custom template and/or notification delay.
   */
  success(message, alertProps?) {
    this.sqLogger.info(message);
    this.createAlert(message, 'success', alertProps);
  }

  /**
   * Display custom message box to the user defaulting to the undo template. (options are: delay and template)
   *
   * @param  {Function} handler - one of [success, warn, error, info] functions found in this service
   * @param  {String} translationKey - Translation key to display. This string may include HTML markup, such as
   *   links. Parameters for the translation can be specified in the options object.
   * @param  {Function} action - Function to assign to 'action' property on scope; used as click action when button
   *   template is used
   * @param {Object} options - Object containing the desired custom template, notification delay and any translation
   *   parameters.
   * @param {Object} [scopeVars] - Object containing properties that will be added to the scope
   */
  custom(handler, translationKey: string, action, options, scopeVars = {}) {
    const scope = this.$rootScope.$new();
    this.$translate(translationKey, options, null, null, null, SCE_PARAMETERS)
      .then((message) => {
        // Workaround for the fact that ui-notifications only supports templateUrl
        this.$templateCache.put('notificationWithButton.html', notificationWithButtonTemplate);
        _.assign(scope, {
          action,
          faIcon: 'fa-undo',
          buttonTranslateKey: 'UNDO',
          buttonTranslateValues: {}
        }, scopeVars);

        handler(message, _.assign({
          templateUrl: 'notificationWithButton.html',
          delay: this.AUTO_UNDO_CLOSE_INTERVAL,
          scope
        }, options));
      });
  }

  /**
   * Displays a worksheet reload notification. Used when a worksheet displayed in view mode has a workstep or
   * permissions changed. Debounced to prevent duplicate notifications if near-simultaneous display notification
   * calls are made.
   */
  displayReloadNotification = _.debounce(this.debouncedDisplayReloadNotification.bind(this), DEBOUNCE.MEDIUM);

  private debouncedDisplayReloadNotification() {
    const reloadMessage = 'RELOAD_MESSAGE';
    const notDisplayed = !_.some(this.alerts, alert => alert.message === reloadMessage);

    if (notDisplayed) {
      this.custom(this.info, reloadMessage, reloadSavingUrl.bind(this), {}, { buttonTranslateKey: 'RELOAD' });
    }

    function reloadSavingUrl() {
      // $state.reload() almost works but changes the shorter url into a longer url
      this.$state.go(this.$state.current, this.$state.params, { location: false, reload: true, notify: false });
    }
  }

  /**
   * Translate and display an message to the user, specifying the message by way of a translation ID
   * and set of translation parameters.
   *
   * @param {String} type - Type of notification
   * @param {String} translationId - ID to translate
   * @param {Object} [translationParameters] - Optional parameters to pass into the translation
   * @param {Object} [alertProps] - Object containing the desired custom template and/or notification delay.
   * @param {Object} options - Object containing options for the notifications/alerts
   * @return {Promise} that resolves when the string has been translated and displayed
   */
  private translateNotification(type, translationId: string, translationParameters?, alertProps?, options?) {
    return this.$translate(translationId, translationParameters, null, null, null, SCE_PARAMETERS)
      .then((message) => {
        this[type](message, alertProps, options);
      });
  }

  /**
   * Display an error message as a result of a failed API call with special handling for 403 Forbidden responses.
   * The default behavior for Forbidden responses is to set the redaction state to redacted and not display an error
   * toast notification.
   *
   * @param {Object} httpResponse - Response object from the call.
   * @param {Boolean} [options.skipRedaction] - option to not display redaction notification for 403 Forbidden responses
   * @param {Boolean} [options.displayForbidden] - option to display a toast notification for 403 Forbidden responses
   */
  apiError(httpResponse, options: { skipRedaction?: boolean, displayForbidden?: boolean } =
    { skipRedaction: false, displayForbidden: false }) {

    const displayFormattedApiError = (httpResponse) => {
      const statusMessage = this.sqUtilities.formatApiError(httpResponse);
      if (!_.isEmpty(statusMessage)) {
        this.error(statusMessage);
      }
    };

    if (_.get(httpResponse, 'status') === HttpCodes.FORBIDDEN) {
      if (!options.skipRedaction) {
        this.sqRedaction.handleForbidden(httpResponse);
      }
      if (options.displayForbidden) {
        displayFormattedApiError(httpResponse);
      }
    } else {
      displayFormattedApiError(httpResponse);
    }
  }
}

const dependencies = [
  'Sq.Vendor',
  'Sq.Services.Logger',
  'Sq.Services.Redaction'
];

angular.module('Sq.Services.Notifications', dependencies)
  .config(function(NotificationProvider) {
    NotificationProvider.setOptions({
      startTop: 40,
      startRight: 10,
      verticalSpacing: 8,
      horizontalSpacing: 8,
      positionX: 'right',
      positionY: 'top'
    });
  })
  .constant('AUTO_DEFAULT_CLOSE_INTERVAL', 4000)
  .constant('AUTO_UNDO_CLOSE_INTERVAL', 10000)
  .constant('AUTO_ERROR_CLOSE_INTERVAL', 10000)
  .service('sqNotifications', NotificationsService);
