import _ from 'lodash';
import bind from 'class-autobind-decorator';
import moment from 'moment-timezone';
import { StorageService } from '@/services/storage.service';
import { DetailsLevel } from '@/hybrid/administration/datasources/datasources.module';
import { DatasourcesActions } from '@/hybrid/administration/datasources/datasources.actions';
import { SocketService } from '@/services/socket.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import {
  AgentsApi,
  AgentStatusOutputV1,
  ConnectionOutputV1,
  ConnectionStatusOutputV1,
  ConnectorOutputV1,
  DatasourcesApi,
  DatasourceSummaryStatusOutputV1,
  ItemsApi,
  RequestsApi
} from '@/sdk';
import { LoggerService } from '@/services/logger.service';
import { IPromise } from 'angular';
import { NotificationsService } from '@/services/notifications.service';
import { NUMBER_CONVERSIONS } from '@/main/app.constants';
import * as models from 'sdk/model/models';

@bind
export class DatasourcesService {
  private readonly subscribers: { [key in DetailsLevel]: number };
  private readonly subscription: { detailsLevel: DetailsLevel, unsubscribe: () => void };

  constructor(
    private $q: ng.IQService,
    private $translate: ng.translate.ITranslateService,
    private sqStorage: StorageService,
    private sqSocket: SocketService,
    private sqDatasourcesActions: DatasourcesActions,
    private sqAgentsApi: AgentsApi,
    private sqLogger: LoggerService,
    private sqRequestsApi: RequestsApi,
    private sqNotifications: NotificationsService,
    private sqItemsApi: ItemsApi,
    private sqDatasourcesApi: DatasourcesApi
  ) {
    this.subscribers = { Counts: 0, Summary: 0, Complete: 0 };
    this.subscription = { detailsLevel: undefined, unsubscribe: undefined };
  }

  subscribe(detailsLevel: DetailsLevel) {
    this.verifyNotNil(detailsLevel);
    this.subscribers[detailsLevel] += 1;
    this.fetchDatasourcesImmediatelyIfNeeded(detailsLevel);
    this.updateSubscription();
  }

  unsubscribe(detailsLevel: DetailsLevel) {
    this.verifyNotNil(detailsLevel);
    if (this.subscribers[detailsLevel] === 0) {
      throw new RangeError(`DetailsLevel ${detailsLevel} has been unsubscribed more times than it was subscribed`);
    }
    this.subscribers[detailsLevel] -= 1;
    this.updateSubscription();
  }

  private updateSubscription() {
    let detailsLevel: DetailsLevel = undefined;

    if (this.subscribers.Complete > 0) {
      detailsLevel = DetailsLevel.Complete;
    } else if (this.subscribers.Summary > 0) {
      detailsLevel = DetailsLevel.Summary;
    } else if (this.subscribers.Counts > 0) {
      detailsLevel = DetailsLevel.Counts;
    }

    if (detailsLevel !== this.subscription.detailsLevel) {
      this.subscription.detailsLevel = detailsLevel;
      _.invoke(this.subscription, 'unsubscribe');
      if (!_.isUndefined(detailsLevel)) {
        this.subscription.unsubscribe = this.sqSocket.subscribe({
          channelId: [SeeqNames.Channels.DatasourcesStatus],
          subscriberParameters: { detailsLevel },
          onMessage: ({ datasourcesStatus }) => {
            this.sqDatasourcesActions.setDatasources(datasourcesStatus);
          }
        });
      } else {
        this.subscription.unsubscribe = undefined;
      }
    }
  }

  /**
   * Fetch datasources immediately if a higher level of details is requested. We do this because the channel
   * may not publish an initial response until up to 10 seconds after the subscription request.
   */
  private fetchDatasourcesImmediatelyIfNeeded(detailsLevel: DetailsLevel) {
    if (this.isHigherDetailsLevel(detailsLevel, this.subscription.detailsLevel)) {
      this.sqAgentsApi.getDatasourcesStatus({ detailsLevel })
        .then(({ data: datasourcesStatus }) => {
          if (this.isSameOrHigherDetailsLevel(detailsLevel, this.subscription.detailsLevel)) {
            this.sqDatasourcesActions.setDatasources(datasourcesStatus);
          }
        })
        .catch(error => this.sqLogger.error(error));
    }
  }

  private isSameOrHigherDetailsLevel(newDetailsLevel: DetailsLevel, currentDetailsLevel: DetailsLevel): boolean {
    return newDetailsLevel === currentDetailsLevel || this.isHigherDetailsLevel(newDetailsLevel, currentDetailsLevel);
  }

  private isHigherDetailsLevel(newDetailsLevel: DetailsLevel, currentDetailsLevel: DetailsLevel): boolean {
    const noCurrentLevel = _.isUndefined(currentDetailsLevel);
    const isHigherThanCounts = currentDetailsLevel === DetailsLevel.Counts &&
      _.includes([DetailsLevel.Summary, DetailsLevel.Complete], newDetailsLevel);
    const isHigherThanSummary = currentDetailsLevel === DetailsLevel.Summary && newDetailsLevel === DetailsLevel.Complete;
    return noCurrentLevel || isHigherThanCounts || isHigherThanSummary;
  }

  private verifyNotNil(detailsLevel: DetailsLevel) {
    if (_.isNil(detailsLevel)) {
      throw new TypeError(`Argument detailsLevel must be defined but it was ${detailsLevel}`);
    }
  }

  /**
   * Returns the number of DISCONNECTED agents
   */
  countDisconnectedAgents(agents: AgentStatusOutputV1[]) {
    if (_.isNil(agents)) {
      return 0;
    }
    return _.countBy(agents, agent => agent.status === SeeqNames.Connectors.Connections.Status.Disconnected).true || 0;
  }

  /**
   * Filters the list of datasources by the filter parameters provided and returns them in the severity order
   * (disconnected first, happy last)
   */
  filterAndSortDatasources(datasources: DatasourceSummaryStatusOutputV1[], filterParams: FilterParameters) {
    if (_.isNil(datasources)) {
      return null;
    }

    const containsIgnoreCase = (str1, str2) => _.toString(str1).toLowerCase().includes(_.toString(str2).toLowerCase());

    const hasValue = (fieldValue) => {
      return !_.isEmpty(fieldValue);
    };

    let filteredDatasources = datasources;

    if (hasValue(filterParams.name)) {
      filteredDatasources = _.filter(filteredDatasources, ds => containsIgnoreCase(ds.name, filterParams.name));
    }

    if (hasValue(filterParams.datasourceClass)) {
      filteredDatasources = _.filter(filteredDatasources,
        ds => containsIgnoreCase(ds.datasourceClass, filterParams.datasourceClass));
    }

    if (hasValue(filterParams.datasourceId)) {
      filteredDatasources = _.filter(filteredDatasources,
        ds => containsIgnoreCase(ds.datasourceId, filterParams.datasourceId));
    }

    if (hasValue(filterParams.agentName)) {
      filteredDatasources = _.filter(filteredDatasources,
        ds => _.countBy(ds.connections, conn => containsIgnoreCase(conn.agentName, filterParams.agentName)).true > 0);
    }

    if (hasValue(filterParams.status)) {
      filteredDatasources = _.filter(filteredDatasources,
        ds => _.countBy(ds.connections, conn => conn.status === filterParams.status).true > 0);
    }

    // sorting
    const errorDatasources = _.sortBy(_.filter(filteredDatasources, ds => this.isError(ds)), 'name');
    filteredDatasources = _.difference(filteredDatasources, errorDatasources);
    const indexingDatasources = _.sortBy(_.filter(filteredDatasources, ds => this.isIndexing(ds)), 'name');
    filteredDatasources = _.difference(filteredDatasources, indexingDatasources);
    const warningDatasources = _.sortBy(_.filter(filteredDatasources, ds => this.isWarning(ds)), 'name');
    filteredDatasources = _.difference(filteredDatasources, warningDatasources);
    const happyDatasources = _.sortBy(_.filter(filteredDatasources, ds => this.isHappy(ds)), 'name');
    filteredDatasources = _.difference(filteredDatasources, happyDatasources);
    const notConnectable = _.sortBy(_.filter(filteredDatasources, ds => this.isNotConnectable(ds)), 'name');
    filteredDatasources = _.sortBy(_.difference(filteredDatasources, notConnectable), 'name');

    return _.concat(errorDatasources, indexingDatasources, warningDatasources, happyDatasources, notConnectable,
      filteredDatasources);
  }

  getDatasourceStatus(datasource: DatasourceSummaryStatusOutputV1) {
    if (this.isError(datasource)) {
      return DatasourceStatus.Error;
    } else if (this.isIndexing(datasource)) {
      return DatasourceStatus.Indexing;
    } else if (this.isWarning(datasource)) {
      return DatasourceStatus.Warning;
    } else if (this.isHappy(datasource)) {
      return DatasourceStatus.Happy;
    } else if (this.isNotConnectable(datasource)) {
      return DatasourceStatus.NotConnectable;
    } else {
      return DatasourceStatus.Unknown;
    }
  }

  private isError(ds: DatasourceSummaryStatusOutputV1) {
    return ds.connectionsConnectedCount === 0 && ds.totalConnectionsCount > 0;
  }

  private isIndexing(ds: DatasourceSummaryStatusOutputV1) {
    return ds.datasourceIndexing;
  }

  private isWarning(ds: DatasourceSummaryStatusOutputV1) {
    return ds.connectionsConnectedCount > 0 && ds.connectionsConnectedCount < ds.totalConnectionsCount;
  }

  private isHappy(ds: DatasourceSummaryStatusOutputV1) {
    return ds.connectionsConnectedCount === ds.totalConnectionsCount && ds.totalConnectionsCount > 0;
  }

  private isNotConnectable(ds: DatasourceSummaryStatusOutputV1) {
    return ds.connectionsConnectedCount === 0 && ds.totalConnectionsCount === 0;
  }

  /**
   * Cancels all requests to the selected datasource
   */
  cancelAllRequests(datasource: DatasourceSummaryStatusOutputV1): IPromise<void> {
    const datasourceClass = datasource.datasourceClass;
    const datasourceId = datasource.datasourceId;
    return this.sqRequestsApi.cancelRequests({ datasourceClass, datasourceId })
      .then(({ data }) => {
        this.sqNotifications.success(_.get(data, 'statusMessage'));
      })
      .catch(({ data }) => {
        this.sqNotifications.error(_.get(data, 'statusMessage'));
      });
  }

  /**
   * Request indexing of the first non DISABLED connection or reports the error given as parameter.
   */
  requestIndex(datasource: DatasourceSummaryStatusOutputV1, notFoundError: String) {
    return this.sqAgentsApi.index({
        syncMode: 'FULL'
      },
      {
        datasourceClass: datasource.datasourceClass,
        datasourceId: datasource.datasourceId
      })
      .then(({ data }) => {
        this.sqNotifications.success(_.get(data, 'statusMessage'));
      })
      .catch(({ data }) => {
        this.sqNotifications.error(_.get(data, 'statusMessage'));
      });
  }

  setDatasourceAllowRequests(datasource: DatasourceSummaryStatusOutputV1, allowRequests: boolean): IPromise<void> {
    return this.sqItemsApi.setProperty({ value: allowRequests },
      { id: datasource.id, propertyName: SeeqNames.Properties.Enabled })
      .then(({ data }) => {
        const newStatus = this.$translate.instant(allowRequests
          ? 'ADMIN.DATASOURCES.ALLOWS_REQUESTS'
          : 'ADMIN.DATASOURCES.DOES_NOT_ALLOW_REQUESTS');
        const defaultMessage = `${datasource.name} (${datasource.datasourceId}) ${newStatus}`;
        this.sqNotifications.success(_.get(data, 'statusMessage', defaultMessage));
      })
      .catch(({ data }) => {
        this.sqNotifications.error(_.get(data, 'statusMessage'));
      });
  }

  setCacheEnabled(datasource: DatasourceSummaryStatusOutputV1, cacheEnabled: boolean): IPromise<void> {
    const messageParameters = {
      datasourceName: datasource.name
    };
    const infoMessage = cacheEnabled
      ? this.$translate.instant('ADMIN.DATASOURCES.CACHE_ENABLED_INFO_MESSAGE', messageParameters)
      : this.$translate.instant('ADMIN.DATASOURCES.CACHE_DISABLED_INFO_MESSAGE', messageParameters);
    this.sqNotifications.info(infoMessage);
    return this.sqItemsApi.setProperty({ value: cacheEnabled },
      { id: datasource.id, propertyName: SeeqNames.Properties.CacheEnabled })
      .then(() => { return; })
      .catch(({ data }) => {
        this.sqNotifications.error(_.get(data, 'statusMessage'));
      });
  }

  toggleConnectionEnable(connection: ConnectionStatusOutputV1): IPromise<void> {
    const connectorName = connection.connectorName;
    const connectionKey = {
      agentName: connection.agentName,
      connectionName: connection.name,
      connectorName
    };

    return this.sqAgentsApi.getConnection(connectionKey)
      .then(({ data }) => this.sqAgentsApi.createOrUpdateConnection({
        datasourceId: data.datasourceId,
        enabled: !data.enabled,
        json: data.json,
        maxConcurrentRequests: data.maxConcurrentRequests,
        maxResultsPerRequests: data.maxResultsPerRequests,
        transforms: data.transforms
      }, connectionKey))
      .then(({ data }) => {
        this.sqNotifications.successTranslate(data.enabled ?
          'ADMIN.DATASOURCES.CONNECTION_HAS_BEEN_ENABLED' :
          'ADMIN.DATASOURCES.CONNECTION_HAS_BEEN_DISABLED');
      })
      .catch(({ data }) => {
        this.sqNotifications.error(_.get(data, 'statusMessage'));
      });
  }

  getConnection(connection) {
    const { agentName, connectorName, name: connectionName } = connection;
    return this.sqAgentsApi.getConnection({ agentName, connectorName, connectionName })
      .then(({ data }) => data)
      .then((data) => {
        const { backups, effectivePermissions, createdAt, updatedAt, datasourceId, ...connection } = data;
        if (_.isNil(connection.transforms)) {
          connection.transforms = undefined;
        } else {
          connection.transforms = JSON.parse(connection.transforms);
        }
        connection.json = JSON.parse(connection.json);
        return connection;
      });
  }

  getConnectionNames(agentName: string, connectorName: string) {
    return this.sqAgentsApi.getConnector({ agentName, connectorName })
      .then(({ data }) => data)
      .then((data: ConnectorOutputV1) => {
        let jsonObj;
        try {
          jsonObj = JSON.parse(data.json);
        } catch (e) {
          return [];
        }

        if (jsonObj.DatasourceManaged) {
          return Promise.reject({
            data: {
              statusMessage: this.$translate.instant(
                'ADMIN.DATASOURCES.CONNECTION_MODAL.CONNECTOR_IS_DATASOURCE_MANAGED')
            }
          });
        }

        if (!_.isNil(jsonObj.Connections)) {
          return _.map(jsonObj.Connections, (connection) => {
            return ({ value: connection.Name, label: connection.Name });
          });
        }
        return [];
      })
      .catch(({ data }) => {
        return Promise.reject(_.get(data, 'statusMessage'));
      });
  }

  getConnectorNames(agentName: string) {
    return this.sqAgentsApi.getAgent({ agentName })
      .then(({ data }) => data)
      .then((data: ConnectionOutputV1) => {
        let jsonObj;
        try {
          jsonObj = JSON.parse(data.json);
        } catch (e) {
          return [];
        }
        if (!_.isNil(jsonObj.Connectors)) {
          return _.map(jsonObj.Connectors, (connector) => {
            return ({ value: connector.Name, label: connector.Name });
          });
        }
        return [];
      })
      .catch(({ data }) => {
        this.sqNotifications.error(_.get(data, 'statusMessage'));
      });
  }

  createOrUpdateConnection(isNew: boolean, agentName: string, datasourceId: string,
    connection: ConnectionOutputV1): IPromise<void> {
    let transforms = null;
    if (_.isArray(connection.transforms)) {
      if (_.keys(connection.transforms).length > 0) {
        transforms = JSON.stringify(connection.transforms);
      } else {
        transforms = '[]';
      }
    }
    const body = {
      maxConcurrentRequests: connection.maxConcurrentRequests,
      maxResultsPerRequests: connection.maxResultsPerRequests,
      transforms,
      enabled: connection.enabled,
      json: JSON.stringify(connection.json)
    };
    if (isNew) {
      body['datasourceId'] = datasourceId;
    }
    const connectorName = connection.connectorName;
    const connectionName = connection.name;

    return this.sqAgentsApi.createOrUpdateConnection(body,
      { agentName, connectorName, connectionName })
      .then(() => {
        if (isNew) {
          this.sqNotifications.successTranslate('ADMIN.DATASOURCES.CONNECTION_MODAL.CONNECTION_CREATED');
        }
      })
      .catch(({ data }) => {
        this.sqNotifications.error(_.get(data, 'statusMessage'));
        return Promise.reject();
      });
  }

  /**
   * Sorts the provided list of connections in the correct order the frontend needs:
   * - First disconnected
   * - Then connecting
   * - Then indexing
   * - Then connected
   * - Then disabled
   */
  sortConnections(connections: ConnectionStatusOutputV1[]): ConnectionStatusOutputV1[] {
    if (_.isNil(connections)) {
      return null;
    }

    let unprocessed = connections;

    const disconnected = _.sortBy(
      _.filter(unprocessed, c => this.getConnectionStatus(c) === ConnectionStatus.Disconnected), 'name');
    unprocessed = _.difference(unprocessed, disconnected);

    const connecting = _.sortBy(
      _.filter(unprocessed, c => this.getConnectionStatus(c) === ConnectionStatus.Connecting), 'name');
    unprocessed = _.difference(unprocessed, connecting);

    const indexing = _.sortBy(
      _.filter(unprocessed, c => this.getConnectionStatus(c) === ConnectionStatus.Indexing), 'name');
    unprocessed = _.difference(unprocessed, indexing);

    const connected = _.sortBy(
      _.filter(unprocessed, c => this.getConnectionStatus(c) === ConnectionStatus.Connected), 'name');
    unprocessed = _.difference(unprocessed, connected);

    const disabled = _.sortBy(
      _.filter(unprocessed, c => this.getConnectionStatus(c) === ConnectionStatus.Disabled), 'name');
    unprocessed = _.sortBy(_.difference(unprocessed, disabled), 'name');

    return _.concat(disconnected, connecting, indexing, connected, disabled, unprocessed);
  }

  getConnectionStatus(connection: ConnectionStatusOutputV1) {
    if (_.isNil(connection)) {
      return ConnectionStatus.Unknown;
    }

    if (connection.status === SeeqNames.Connectors.Connections.Status.Disconnected) {
      return ConnectionStatus.Disconnected;
    } else if (connection.status === SeeqNames.Connectors.Connections.Status.Disabled) {
      return ConnectionStatus.Disabled;
    } else if (connection.status === SeeqNames.Connectors.Connections.Status.Connecting) {
      return ConnectionStatus.Connecting;
    } else if (connection.status === SeeqNames.Connectors.Connections.Status.Connected) {
      if (connection.syncStatus === 'SYNC_IN_PROGRESS') {
        return ConnectionStatus.Indexing;
      } else {
        return ConnectionStatus.Connected;
      }
    } else {
      return ConnectionStatus.Unknown;
    }
  }

  /**
   * Computes the url where the logs of the agent can be retrieved.
   */
  computeLogUrl(agentName: string, agents: AgentStatusOutputV1[], connection?: ConnectionStatusOutputV1): string {
    const agent = _.find(agents, a => a.name === agentName);

    let logName;
    if (_.isNil(agent)) {
      logName = null;
    } else if (agent.remoteAgent) {
      // Keep this in sync with RemoteAgentLoggingService
      logName = agent.name.replace(/[\\/:*?\"<>|]/g, '').replace(/[.\s]/g, '_').replace(/(\d)$/g, '$1_');
    } else if (_.includes(agent.name, 'JVM Agent')) {
      logName = 'jvm-link';
    } else if (_.includes(agent.name, '.NET Agent')) {
      logName = 'net-link';
    } else {
      logName = null;
    }

    let url = '/logs';

    if (!_.isNil(logName)) {
      url += `?log=${encodeURIComponent(logName)}`;
    }

    if (!_.isNil(connection)) {
      if (_.isNil(logName)) {
        url += '?';
      } else {
        url += '&';
      }

      url += `threadContains=${encodeURIComponent(connection.connectionId)}`;
    }

    return url;
  }

  /**
   * Fetch parameters required by the ManageDatasourceModal in the format it expects
   *
   * @param id - the datasource ID
   */
  fetchManageDatasourceParams(id: string): IPromise<ManageDatasourceParams | void> {
    return this.sqDatasourcesApi.getDatasource({ id })
      .then(({ data: { name, indexingScheduleSupported, additionalProperties } }) => {
        const indexingFrequency = _.chain(additionalProperties)
          .filter(['name', SeeqNames.Properties.IndexingFrequency])
          .map(prop => ({ value: prop.value, units: prop.unitOfMeasure }))
          .first()
          .value();
        const nextScheduledIndexAt = _.chain(additionalProperties)
          .filter(['name', SeeqNames.Properties.NextScheduledIndexAt])
          .map(prop => moment.utc(prop.value / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND).toISOString())
          .first()
          .value();

        return {
          id,
          name,
          indexingScheduleSupported,
          indexingFrequency,
          nextScheduledIndexAt
        };
      })
      .catch(({ data }) => {
        this.sqNotifications.error(_.get(data, 'statusMessage'));
      });
  }

  /**
   * Update the datasource
   *
   * @param id - the datasource ID
   */
  updateDatasource({ id, name, indexingFrequency, nextScheduledIndexAt }: UpdateDatasourceParams) {
    const properties: models.ScalarPropertyV1[] = [{
      name: SeeqNames.Properties.Name,
      value: name
    }];

    if (!_.isUndefined(nextScheduledIndexAt)) {
      properties.push({
        name: SeeqNames.Properties.NextScheduledIndexAt,
        value: moment.utc(nextScheduledIndexAt).valueOf() * NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
        unitOfMeasure: 'ns'
      });
    }

    if (!_.isUndefined(indexingFrequency)) {
      properties.push({
        name: SeeqNames.Properties.IndexingFrequency,
        value: indexingFrequency.value,
        unitOfMeasure: indexingFrequency.units
      });
    }

    return this.sqItemsApi.setProperties(properties, { id });
  }
}

export interface UpdateDatasourceParams {
  id: string;
  name: string;
  indexingFrequency: { value: number; units: string };
  nextScheduledIndexAt: string; // ISO 8601 in UTC (e.g. '2021-07-10T00:24:00.000Z')
}

export interface ManageDatasourceParams extends UpdateDatasourceParams {
  indexingScheduleSupported: boolean;
}

export interface FilterParameters {
  name: string;
  datasourceClass: string;
  datasourceId: string;
  agentName: string;
  status: string;
}

export enum DatasourceStatus {
  Unknown = 'Unknown',
  Error = 'Error',
  Indexing = 'Indexing',
  Warning = 'Warning',
  Happy = 'Happy',
  NotConnectable = 'NotConnectable'
}

export enum ConnectionStatus {
  Unknown = 'Unknown',
  Disconnected = 'Disconnected',
  Connecting = 'Connecting',
  Connected = 'Connected',
  Indexing = 'Indexing',
  Disabled = 'Disabled'
}
