import _ from 'lodash';
import { TableBuilderHelperService } from '@/hybrid/tableBuilder/tableBuilderHelper.service';
import React, { useRef, useState } from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useDrag, useDrop } from 'react-dnd';
import { Icon } from '@/hybrid/core/Icon.atom';
import { EditableText } from '@/hybrid/core/EditableText.atom';
import { TableBuilderColumnFilter } from '@/hybrid/tableBuilder/tableBuilder.store';
import { TableSortIF, TableTextFormatterIF } from '@/hybrid/tableBuilder/TableBuilder.page';
import {
  TableBuilderTextHeaderMenu,
  TextHeaderMenuActions
} from '@/hybrid/tableBuilder/tableComponents/TableBuilderTextHeaderMenu.atom';
import { TableBuilderFilterIcon } from '@/hybrid/tableBuilder/tableComponents/TableBuilderFilterIcon.atom';
import { TableBuilderSortIcon } from '@/hybrid/tableBuilder/tableComponents/TableBuilderSortIcon.atom';

interface TextHeaderIF {
  canEdit: boolean;
  columnIndex: number;
  columnKey: string;
  columnBackgroundColor?: string;
  columnTextAlign?: string;
  columnTextColor?: string;
  columnTextStyle?: string[];
  headerBackgroundColor?: string;
  headerTextAlign?: string;
  headerTextColor?: string;
  headerTextStyle?: string[];
  isInput: boolean;
  isStatic?: boolean;
  isTransposed: boolean;
  key?: number;
  sort?: TableSortIF;
  textFormatter?: TableTextFormatterIF;
  textValue: string;
  menuActions?: TextHeaderMenuActions[];
  showMove?: boolean;
  moveColumn?: (key: string, newKey: string) => void;
  onTextChange: (value: string) => void;
  removeColumn?: (key: string) => void;
  setColumnFilter?: (key: string, filter: TableBuilderColumnFilter) => void;
  columnFilter?: TableBuilderColumnFilter;
  isFilterDisabled?: boolean;
  filterHelpText?: string;
  isStringColumn?: boolean;
  distinctStringValues?: string[];
  thresholds?: any[] | undefined;
}

const tableBuilderTextHeaderBindings = bindingsDefinition({
  sqTableBuilderHelper: injected<TableBuilderHelperService>()
});

const dndObjectType = 'TableBuilderDataHeader';
const dndDelimiterWidth = 8;
const dndDelimiterColor = '#007960';

export const TableBuilderTextHeader: React.FunctionComponent<TextHeaderIF> = (props) => {
  const { sqTableBuilderHelper } = useInjectedBindings(tableBuilderTextHeaderBindings);

  const {
    canEdit,
    columnIndex,
    columnKey,
    columnBackgroundColor,
    columnTextAlign,
    columnTextColor,
    columnTextStyle,
    headerBackgroundColor,
    headerTextAlign,
    headerTextColor,
    headerTextStyle,
    isInput,
    isStatic = false,
    isTransposed,
    sort,
    textFormatter,
    textValue,
    menuActions = _.values(TextHeaderMenuActions),
    showMove = true,
    moveColumn,
    removeColumn,
    onTextChange,
    setColumnFilter,
    columnFilter = undefined,
    isFilterDisabled = false,
    filterHelpText = undefined,
    isStringColumn = false,
    distinctStringValues = undefined,
    thresholds = undefined
  } = props;

  const [forceEdit, setForceEdit] = useState(false);

  // ref is used:
  // - for drag and drop
  // - to open the text formatter menu relative to this component
  const ref = useRef<HTMLTableDataCellElement>(null);

  // region drag and drop
  const findParentSize = (node: HTMLElement, type: string): { height: number, width: number } => {
    while (node?.parentElement && node.nodeName !== type) {
      node = node.parentElement;
    }
    return node ? { height: node.clientHeight, width: node.clientWidth } : undefined;
  };

  const findDelimiterPosition = (item: any): { top: number, left: number, width: number, height: number } => {
    return isTransposed ?
      {
        top: item?.columnIndex > columnIndex ? 0 :
          ref.current?.getBoundingClientRect().height - dndDelimiterWidth,
        left: 0,
        width: findParentSize(ref.current, 'TABLE')?.width,
        height: dndDelimiterWidth
      } : {
        top: 0,
        left: item?.columnIndex > columnIndex ? 0 :
          ref.current?.getBoundingClientRect().width - dndDelimiterWidth,
        width: dndDelimiterWidth,
        height: findParentSize(ref.current, 'TABLE')?.height
      };
  };

  const getOverDiv = () =>
    <div style={{
      position: 'absolute',
      top: delimiter.top,
      left: delimiter.left,
      height: delimiter.height,
      width: delimiter.width,
      zIndex: 1,
      opacity: 1,
      backgroundColor: dndDelimiterColor
    }}
    />;

  const getDraggingDivShadow = () =>
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 2,
        opacity: 0.5,
        backgroundColor: '#ffcccc'
      }} />;

  const [{ isDragging }, connectDrag] = useDrag({
    item: { type: dndObjectType, columnIndex, columnKey },
    collect: monitor => ({ isDragging: !!monitor.isDragging() })
  });

  const [{ isOver, delimiter }, connectDrop] = useDrop({
    accept: dndObjectType,
    drop: (item: any) => moveColumn(item.columnKey, columnKey),
    collect: (monitor) => {
      if (monitor.isOver()) {
        return {
          isOver: monitor.isOver() && monitor.getItem()?.columnIndex !== columnIndex,
          delimiter: findDelimiterPosition(monitor.getItem())
        };
      }
      return { isOver: false };
    }
  });
  connectDrag(connectDrop(ref));
  // endregion

  // use Object to avoid TS2322: Type 'string' is not assignable to type 'TextAlignProperty'.
  const style: Object = sqTableBuilderHelper.computeCellStyle(headerBackgroundColor, headerTextColor, headerTextStyle,
    headerTextAlign, undefined, undefined);

  const renderMenu = () => {
    return !_.isEmpty(menuActions) &&
      <TableBuilderTextHeaderMenu
        canRenameHeader={!isStatic}
        columnKey={columnKey}
        columnBackgroundColor={columnBackgroundColor}
        columnTextAlign={columnTextAlign}
        columnTextColor={columnTextColor}
        columnTextStyle={columnTextStyle}
        headerBackgroundColor={headerBackgroundColor}
        headerTextAlign={headerTextAlign}
        headerTextColor={headerTextColor}
        headerTextStyle={headerTextStyle}
        isTransposed={isTransposed}
        target={ref}
        textFormatter={textFormatter}
        editHeaderValue={() => setForceEdit(true)}
        removeColumn={removeColumn}
        canSort={sort?.canSort}
        canSortDisabledTooltip={sort?.canSortDisabledTooltip}
        sortByColumn={sort?.sortByColumn}
        sortDirection={sort?.sortDirection}
        setColumnFilter={setColumnFilter}
        columnFilter={columnFilter}
        actions={menuActions}
        isFilterDisabled={isFilterDisabled}
        filterHelpText={filterHelpText}
        isStringColumn={isStringColumn}
        distinctStringValues={distinctStringValues}
        thresholds={thresholds} />;
  };

  const renderDragAndDrop = () => {
    return showMove && moveColumn && <span className="sq-icon-hover" style={{ cursor: 'grab' }}>
            <Icon icon="fa-arrows" extraClassNames="fa-fw sq-icon-hover" type="gray" />
          </span>;
  };

  const renderFilter = () => {
    return <TableBuilderFilterIcon
      columnKey={columnKey}
      target={ref}
      headerBackgroundColor={headerBackgroundColor}
      setColumnFilter={setColumnFilter}
      columnFilter={columnFilter}
      isFilterDisabled={isFilterDisabled}
      isStringColumn={isStringColumn}
      distinctStringValues={distinctStringValues}
      helpText={filterHelpText}
      thresholds={thresholds} />;
  };

  const renderSort = () => {
    return <TableBuilderSortIcon
      active={sort?.canSort}
      direction={sort?.sortDirection}
      columnKey={columnKey}
      headerBackgroundColor={headerBackgroundColor}
      level={sort?.sortLevel}
      setDirection={sort?.sortByColumn}
      showLevel={sort?.maxSortLevel > 1} />;
  };

  if (canEdit && (isInput || isStatic)) {
    return <td
      className="forceVerticalAlignMiddle"
      data-testid="tableBuilderTextHeader"
      ref={ref}
      style={style}>
      <div className="flexColumnContainer">
        <div className="ml5 mr5 flexFillOverflow">
          {isInput ?
            <EditableText
              allowEditing={true}
              allowEmptyValue={true}
              forceEdit={forceEdit}
              inputClasses="flexFill"
              textClasses="mb0 nowrap"
              testId="columnName"
              value={textValue}
              onUpdate={(value) => {
                setForceEdit(false);
                onTextChange(value);
              }} /> :
            textValue}
        </div>

        <div className="flexColumnContainer">
          {renderFilter()}
          {renderSort()}
          {renderDragAndDrop()}
          {renderMenu()}
        </div>
        {isOver && getOverDiv()}
        {isDragging && getDraggingDivShadow()}
      </div>
    </td>;
  } else {
    return <td
      style={style}
      className="forceVerticalAlignMiddle"
      data-testid="tableBuilderTextHeader">
      <div className="max-width-200 flexColumnContainer" ref={ref}>
        <div className="ml5 mr5 flexFillOverflow">
          {textValue}
        </div>
        <div className="flexColumnContainer">
          {renderFilter()}
          {renderSort()}
          {renderMenu()}
        </div>
      </div>
    </td>;
  }
};
