import _ from 'lodash';
import angular from 'angular';
import scatterPlotFxLineModalTemplate from './scatterPlotFxLine.modal.html';
import bind from 'class-autobind-decorator';
import { TrendActions } from '@/trendData/trend.actions';
import { MIN_SAMPLES_FOR_BOOST, ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';
import { ScatterPlotStore } from '@/scatterPlot/scatterPlot.store';
import { DurationStore } from '@/trendData/duration.store';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { CapsuleBucketsService } from '@/trendViewer/capsuleBuckets.service';
import { PendingRequestsService } from '@/services/pendingRequests.service';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { UtilitiesService } from '@/services/utilities.service';
import { ScatterPlotFxLineModalController } from '@/scatterPlot/scatterPlotFxLineModalController';
import { SCATTER_PLOT_COLORS, SCATTER_PLOT_MODES, SCATTER_PLOT_VIEWS } from '@/scatterPlot/scatterPlot.module';
import { DEBOUNCE, SMALL_SIZE_SCREEN_LIMIT } from '@/main/app.constants';
import { SignalsApi } from 'sdk/api/SignalsApi';
import { getScatterPlotChartConfig } from '@/scatterPlot/scatterPlot.selectors';
import { XYRegion } from '@/services/chartHelper.service';
import { ITEM_TYPES } from '@/trendData/trendData.module';
import { RedactionService } from '@/services/redaction.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { TrendStore } from '@/trendData/trend.store';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { getDensityPlotChartConfig } from '@/scatterPlot/densityPlot.selectors';
import { NotificationsService } from '@/services/notifications.service';

@bind
export class ScatterPlotController {
  ITEM_TYPES = ITEM_TYPES;
  MAX_BINS = 100;
  cancellationGroup = 'scatterPlotRegions';

  binOptions = [...Array(20).keys()].map(key => (key + 1) * 5);

  // Debounces the fetching of the capsule bucket regions to display on the minimap
  debouncedUpdateCapsuleRegions = _.debounce(this.updateCapsuleRegions, DEBOUNCE.LONG);

  fxLines = this['fxLines'];
  colorSignalName = this['colorSignalName'];
  colorSignal = this['colorSignal'];
  colorConditions = this['colorConditions'];
  minimapSeries = this['minimapSeries'];
  minimapBackgroundColor = this['minimapBackgroundColor'];
  plotMode = this['plotMode'];
  isScatterPlotView = this['isScatterPlotView'];
  isDensityPlotView = this['isDensityPlotView'];
  capsulesMode = this['capsulesMode'];
  selectorLow = this['selectorLow'];
  selectorHigh = this['selectorHigh'];
  selectedRegion: XYRegion = this['selectedRegion'];
  viewRegion: XYRegion = this['viewRegion'];
  isViewRegionSet = this['isViewRegionSet'];
  connect: boolean = this['connect'];
  signals = this['signals'];
  conditionsExist = this['conditionsExist'];
  trendStart = this['trendStart'];
  trendEnd = this['trendEnd'];
  xSeries = this['xSeries'];
  ySeries = this['ySeries'];
  xValue = this['xValue'];
  chartConfig = this['chartConfig'];
  showTooltips = this['showTooltips'];

  numXBins = this['numXBins'];
  numYBins = this['numYBins'];

  constructor(
    public $scope: ng.IScope,
    public $element: JQuery,
    public $q: ng.IQService,
    public $uibModal: ng.ui.bootstrap.IModalService,
    public sqTrendActions: TrendActions,
    public sqScatterPlotActions: ScatterPlotActions,
    public sqScatterPlotStore: ScatterPlotStore,
    public sqDurationStore: DurationStore,
    public sqTrendSeriesStore: TrendSeriesStore,
    public sqTrendCapsuleStore: TrendCapsuleStore,
    public sqTrendCapsuleSetStore: TrendCapsuleSetStore,
    public sqTrendStore: TrendStore,
    public sqCapsuleBuckets: CapsuleBucketsService,
    public sqPendingRequests: PendingRequestsService,
    public sqWorksheetStore: WorksheetStore,
    public sqUtilities: UtilitiesService,
    public sqDateTime: DateTimeService,
    public sqSignalsApi: SignalsApi,
    public sqRedaction: RedactionService,
    public sqSystemConfiguration: SystemConfigurationService,
    public sqNotifications: NotificationsService
  ) {
    $scope.$listenTo(sqScatterPlotStore, this.syncWithScatterPlotStore);
    $scope.$listenTo(sqTrendSeriesStore, this.syncWithTrendSeriesStore);
    $scope.$listenTo(sqTrendCapsuleStore, sqScatterPlotActions.calculateData); // Capsules changed: data may filter out
    $scope.$listenTo(sqTrendCapsuleSetStore, this.syncWithCapsuleSetStore);
    $scope.$listenTo(sqWorksheetStore, ['timezone'], this.syncWithWorksheetStore);
    $scope.$listenTo(sqDurationStore, ['displayRange'], this.syncWithDurationStore);
    $scope.$listenTo(sqTrendStore, ['xValue'], this.syncWithTrendStore);

    $scope.$watch('$ctrl.signals', this.autoSelectXySignals);
  }

  isViewOnlyWorkbookMode = this.sqUtilities.isViewOnlyWorkbookMode;
  isPresentationMode = this.sqUtilities.isPresentationWorkbookMode;
  rangeEditingEnabled = !this.isPresentationMode;

  // Scatter plot chart
  updateMinimapWidth = this.sqScatterPlotActions.updateMinimapWidth;
  timezone = this.sqWorksheetStore.timezone;
  setPointerValues = this.sqTrendActions.setPointerValues;
  clearPointerValues = this.sqTrendActions.clearPointerValues;
  setConnect = this.sqScatterPlotActions.setConnect;

  // Selections and zoom
  isRegionSelected = this.sqScatterPlotStore.isRegionSelected;
  setSelectedRegion = this.sqScatterPlotActions.setSelectedRegion;
  clearSelectedRegion = this.sqScatterPlotActions.clearSelectedRegion;
  zoomToSelectedRegion = this.sqScatterPlotActions.zoomToSelectedRegion;
  zoomToRegion = this.sqScatterPlotActions.zoomToRegion;
  isCustomRegionSet = this.sqScatterPlotStore.isViewRegionSet;
  expandViewRegion = this.sqScatterPlotActions.expandViewRegion;

  // Toolbar
  flipXAndY = this.sqScatterPlotActions.flipXAndY;
  setXSeries = this.sqScatterPlotActions.setXSeries;
  setYSeries = this.sqScatterPlotActions.setYSeries;
  setPlotMode = this.sqScatterPlotActions.setPlotMode;
  setShowTooltips = this.sqScatterPlotActions.setShowTooltips;

  fetchScatterPlot = this.sqScatterPlotActions.fetchScatterPlot;
  switchToDensityPlot = this.sqScatterPlotActions.switchToDensityPlot;
  switchToScatterPlot = this.sqScatterPlotActions.switchToScatterPlot;

  setNumXBins = this.sqScatterPlotActions.setNumXBins;
  setNumYBins = this.sqScatterPlotActions.setNumYBins;

  smallToolbar = true;
  onResize = (height, width) => this.smallToolbar = width < SMALL_SIZE_SCREEN_LIMIT;

  // Region slider
  regions = [];
  colorRanges = [];
  minimapRegions = [];
  setSelectors = this.sqScatterPlotActions.setSelectors;
  useSelectors = true;

  syncWithScatterPlotStore(e) {
    this.fxLines = _.map(this.sqScatterPlotStore.fxLines, line => ({
      ...line,
      name: _.get(this.sqTrendSeriesStore.findItem(line.id), 'name')
    }));
    const colorSignal = this.sqTrendSeriesStore.findItem(this.sqScatterPlotStore.colorSignalId);
    this.colorSignalName = _.get(colorSignal, 'name');
    this.colorConditions = this.sqScatterPlotStore.colorConditionIds;
    this.minimapSeries = this.sqScatterPlotStore.minimapSeries;
    this.plotMode = this.sqScatterPlotStore.plotMode;
    this.isScatterPlotView = this.sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT;
    this.isDensityPlotView = this.sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT;
    this.capsulesMode = this.sqScatterPlotStore.plotMode === SCATTER_PLOT_MODES.CAPSULES;
    this.selectorLow = this.sqScatterPlotStore.selector.low;
    this.selectorHigh = this.sqScatterPlotStore.selector.high;
    this.selectedRegion = this.sqScatterPlotStore.selectedRegion;
    this.viewRegion = this.sqScatterPlotStore.viewRegion;
    this.isViewRegionSet = this.sqScatterPlotStore.isViewRegionSet;
    this.connect = this.sqScatterPlotStore.connect;
    this.showTooltips = this.sqScatterPlotStore.showTooltips;
    this.colorRanges = _.chain(this.sqScatterPlotStore.colorRanges)
      .filter(({ range }) => this.sqDateTime.overlaps(range,
        { startTime: this.sqDurationStore.displayRange.start, endTime: this.sqDurationStore.displayRange.end }))
      .map(colorRange => ({
        id: colorRange.id,
        color: colorRange.color,
        start: colorRange.range.startTime,
        end: colorRange.range.endTime
      }))
      .value();
    this.minimapRegions = this.colorRanges.concat(this.regions);
    this.minimapBackgroundColor = this.colorRanges.length ? SCATTER_PLOT_COLORS.LOW : undefined;
    this.useSelectors = !(this.colorSignalName || this.colorRanges.length);

    // Density plot-specific fields
    this.numXBins = this.sqScatterPlotStore.numXBins;
    this.numYBins = this.sqScatterPlotStore.numYBins;

    if (this.sqUtilities.propertyChanged(e, 'plotMode')) {
      this.debouncedUpdateCapsuleRegions();
    }

    this.updateSeriesAndChartConfig();
  }

  syncWithTrendSeriesStore() {
    this.signals = _.chain(this.sqTrendSeriesStore.primarySeries)
      .reject(item => this.sqUtilities.isStringSeries(item))
      .reject(signal => this.sqRedaction.isItemRedacted(signal))
      .value();
    this.updateSeriesAndChartConfig();
  }

  syncWithCapsuleSetStore(e) {
    this.conditionsExist = this.sqTrendCapsuleSetStore.items.length > 0;

    // When the last capsule set is removed, the capsules plot mode is no longer available,
    // so revert back to the display range plot mode.
    if (!this.conditionsExist && this.capsulesMode) {
      this.sqScatterPlotActions.setPlotMode(SCATTER_PLOT_MODES.DISPLAY_RANGE);
    }

    this.updateSeriesAndChartConfig();

    if (this.sqUtilities.itemPropertiesChanged(e, ['id', 'color', 'selected'])) {
      this.debouncedUpdateCapsuleRegions();
    }
  }

  syncWithWorksheetStore() {
    this.timezone = this.sqWorksheetStore.timezone;
    this.updateSeriesAndChartConfig();
  }

  syncWithDurationStore() {
    this.trendStart = this.sqDurationStore.displayRange.start;
    this.trendEnd = this.sqDurationStore.displayRange.end;
    this.debouncedUpdateCapsuleRegions();
  }

  syncWithTrendStore() {
    this.xValue = this.sqTrendStore.xValue;
  }

  updateSeriesAndChartConfig() {
    // Series selected for display on the scatter plot
    this.xSeries = this.sqTrendSeriesStore.findItem(_.get(this.sqScatterPlotStore.xSeries, 'id'));
    this.ySeries = this.sqTrendSeriesStore.findItem(_.get(this.sqScatterPlotStore.ySeries, 'id'));
    this.colorSignal = this.sqTrendSeriesStore.findItem(this.sqScatterPlotStore.colorSignalId);
    this.chartConfig = this.isScatterPlotView ? getScatterPlotChartConfig({
        state: this.sqScatterPlotStore.state,
        scatterData: this.sqScatterPlotStore.scatterData,
        xSignal: this.xSeries,
        ySignal: this.ySeries
      })
      : getDensityPlotChartConfig({
        state: this.sqScatterPlotStore.state,
        densityPlotData: this.sqScatterPlotStore.densityPlotData,
        xSignal: this.xSeries,
        ySignal: this.ySeries,
        colorAxisRange: this.sqScatterPlotStore.getColorAxisRange()
      });
  }

  /**
   * When in Capsules mode, fetches the capsule bucket regions to display on the minimap.
   */
  updateCapsuleRegions() {
    if (this.capsulesMode) {
      this.sqPendingRequests.cancelGroup(this.cancellationGroup);
      // Use the current width of the directive to determine how many buckets to request.
      const buckets = Math.floor(angular.element(this.$element.children()[0]).width() / 5);
      const bucketWidth = this.sqDurationStore.displayRange.duration.asMilliseconds() / buckets + 'ms';
      const selectedCapsuleSets = _.some(this.sqTrendCapsuleSetStore.items, 'selected');
      const trendCapsuleSets = this.sqTrendCapsuleSetStore.items;

      // Remove regions for capsule sets that are no longer in the details pane
      let updatedRegions = _.filter(this.regions,
        region => _.some(trendCapsuleSets, item => item.id === region.capsuleSetId));

      _.forEach(trendCapsuleSets, (item: any) => {
        updatedRegions = _.reject(updatedRegions, { capsuleSetId: item.id });

        if (!selectedCapsuleSets || (selectedCapsuleSets && item.selected)) {
          this.sqCapsuleBuckets.calculate(item, this.sqDurationStore.displayRange, bucketWidth, this.cancellationGroup)
            .then((regions) => {
              updatedRegions = updatedRegions.concat(regions);
            })
            .catch(() => {
              updatedRegions = _.reject(updatedRegions, { capsuleSetId: item.id });
            })
            .finally(() => {
              this.regions = updatedRegions;
              this.minimapRegions = this.colorRanges.concat(updatedRegions);
            });
        }
      });
    } else {
      this.regions = [];
      this.minimapRegions = this.colorRanges.concat(this.regions);
    }
  }

  /**
   * As a convenience, this loads the first two available time series for the scatter plot
   * so the user doesn't have to do it manually
   */
  autoSelectXySignals() {
    const xId = _.get(this.sqScatterPlotStore.xSeries, 'id');
    const yId = _.get(this.sqScatterPlotStore.ySeries, 'id');
    const availableSignals = _.chain(this.signals)
      .reject(['id', xId])
      .reject(['id', yId])
      .value();

    if (!xId && availableSignals.length) {
      this.sqScatterPlotActions.setXSeries(availableSignals.shift());
    }

    if (!yId && availableSignals.length) {
      this.sqScatterPlotActions.setYSeries(availableSignals.shift());
    }
  }

  hasManyPoints() {
    return this.sqScatterPlotStore.scatterData.length > MIN_SAMPLES_FOR_BOOST;
  }

  /**
   * Get the element height for use with the bottom panel
   *
   * @return {Number} - height of the scatterplot component
   */
  elementHeight() {
    return this.$element.height();
  }

  /**
   * Opens the modal for managing lines onto the chart.
   */
  openLinesModal() {
    this.$uibModal.open({
      animation: true,
      controller: ScatterPlotFxLineModalController,
      controllerAs: '$ctrl',
      template: scatterPlotFxLineModalTemplate,
      size: 'sm',
      resolve: {
        signalIdsToExclude: this.getFxSignalIdsToExclude
      }
    });
  }

  /**
   * Opens the modal for managing colors onto the chart.
   */
  openColorsModal() {
    this.sqScatterPlotActions.setShowColorModal(true);
  }

  /**
   * Gets the ids of signals that are not compatible with the ability to plot a function of x line on the scatter plot
   * chart. The only signals that are valid are described in #isValidFxSignal().
   *
   * @return {Promise<string[]>} Resolves with array of signal ids that are not valid for use with scatter plot line
   * functionality.
   */
  getFxSignalIdsToExclude() {
    const xId = _.get(this.xSeries, 'id');
    const yId = _.get(this.ySeries, 'id');
    return _.chain(this.signals)
      .reject(['id', xId])
      .reject(['id', yId]) // No need to fetch X and Y since they are always excluded
      .reject(signal => _.chain(this.fxLines).map('id').includes(signal.id).value())
      .filter('calculationType') // Only calculated signals are useful
      .map(({ id }) => this.sqSignalsApi.getSignal({ id }))
      .thru(promises => this.$q.all(promises))
      .value()
      .then(signals => _.chain(signals)
        .map('data')
        .filter(signal => this.sqScatterPlotStore.isValidFxSignal(signal.parameters, signal.formula))
        .map('id')
        .thru(validIds => _.chain(this.signals).map('id').difference(validIds).value())
        .value());
  }

  canShowPlot() {
    return this.signals.length >= 2;
  }
}
