import React from 'react';
import _ from 'lodash';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import SelectUnit from '@/hybrid/core/SelectUnit.molecule';
import { getFormFieldProps, getValidationFunction, canUseMemo } from '@/hybrid/formbuilder/formbuilder.utilities';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { HelpIcon } from '@/hybrid/core/Icon.atom';

export interface SelectUnitIF extends ValidatingFormComponent<string> {
  component: 'SelectUnitFormComponent';
  disabled?: boolean;
  label?: string;
}

const SelectUnitFormComponentUnwrapped: React.FunctionComponent<SelectUnitIF> = (props) => {
  const { name, validation, extendValidation, disabled, extraClassNames, label, tooltip } = props;
  const { t } = useTranslation();

  const defaultValidation = value => _.isEmpty(value);
  const formState = useForm().getState();

  return <Field name={name} validate={getValidationFunction(defaultValidation, extendValidation, validation)}>
    {({ input, meta }) => (
      <div className={extraClassNames}>
        {label && <div className="mb5">
          {t(label)}
          {tooltip && <HelpIcon tooltip={tooltip} extraClassNames="pl5" />}
        </div>}
        <SelectUnit
          {...getFormFieldProps(formState, input, meta, props)}
          isDisabled={disabled}
          appendToBody={true}
        />
      </div>
    )}
  </Field>;
}
;

export const SelectUnitFormComponent = React.memo(SelectUnitFormComponentUnwrapped, canUseMemo);
