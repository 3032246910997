import React, { useState } from 'react';
import _ from 'lodash';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { NotificationsService } from '@/services/notifications.service';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { HomeScreenStore } from '@/hybrid/homescreen/homescreen.store';

const pinnedActionIconBindings = bindingsDefinition({
  item: prop<{ isPinned: boolean, id: string }>(),
  onChange: prop.optional<(Boolean) => void>(),
  activeWorkbook: prop<boolean>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  sqHomeScreenStore: injected<HomeScreenStore>(),
  sqNotifications: injected<NotificationsService>()
});

export const PinnedActionIcon: SeeqComponent<typeof pinnedActionIconBindings> = (props) => {
    const { activeWorkbook } = props;
    const { item, onChange = () => {} } = props;
    const { sqHomeScreenUtilities, sqNotifications } = useInjectedBindings(pinnedActionIconBindings);
    const [isPinned, setIsPinned] = useState(item.isPinned);

    const toggle = () => {
      const originalState = isPinned;
      setIsPinned(!isPinned);
      // legacy code relies on a workbookId
      return sqHomeScreenUtilities.toggleIsPinned(_.assign({}, item, { workbookId: item.id }))
        .then(response => onChange(isPinned))
        .catch((e) => {
          onChange(originalState);
          setIsPinned(originalState);
          sqNotifications.apiError(e);
        });
    };

    const baseClasses = classNames('sq-text-primary', 'fa-fw', 'correctSpin');
    const pinnedIconClasses = isPinned
      ? classNames(baseClasses, 'fc', 'fc-pin', 'cursorPointer', 'mt12')
      : classNames(baseClasses, 'fc-pin-o', 'fc', 'cursorPointer', { folderActions: !activeWorkbook });

    return (
      <HoverTooltip text={item.isPinned ? 'WORKBENCH.UNMARK_FAVORITE' : 'WORKBENCH.MARK_FAVORITE'}
        placement='top' delay={500}>
        <span className="pt10 pb10 pr6 pl6 sq-icon-hover cursorPointer" onClick={toggle}>
          <i className={pinnedIconClasses}
            data-testid='homeScreenActionPin'
            id='favorite' />
        </span>
      </HoverTooltip>
    );
  }
;
