import angular from 'angular';
import { sqAssetGroupStore } from '@/hybrid/assetGroupEditor/assetGroup.store';
import { sqAssetGroupActions } from '@/hybrid/assetGroupEditor/assetGroup.actions';

const dependencies = [
  'Sq.AppConstants', 'Sq.Core'
];

/**
 * The Sq.AssetGroups module contains all functionality related to asset groups
 */
export default angular.module('Sq.AssetGroups', dependencies)
  .store('sqAssetGroupStore', sqAssetGroupStore)
  .service('sqAssetGroupActions', sqAssetGroupActions);
