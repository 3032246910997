import React from 'react';
import _ from 'lodash';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';

const logsNavigationBindings = bindingsDefinition({
  onFetchMoreLogs: prop<(boolean) => void>(),
  isFetching: prop<(boolean)>(),
  limit: prop<(number)>()
});

export const LogNavigation: SeeqComponent<typeof logsNavigationBindings> = ({
  isFetching, onFetchMoreLogs, limit
}) => {
  const buttonsProperties = [
    {
      tooltip: 'LOGS.FETCH_PREVIOUS_TOOLTIP',
      testId: 'fetch_more_up_button',
      className: 'fa-arrow-up',
      append: false
    },
    {
      tooltip: 'LOGS.FETCH_NEXT_TOOLTIP',
      testId: 'fetch_more_down_button',
      className: 'fa-arrow-down',
      append: true
    }
  ];

  return (
    <div data-testid="LogNavigationContainer" className="logTrackerNavigationButtons">
      {_.map(buttonsProperties, (buttonProp: any, index: number) => (
        <HoverTooltip key={index} delay={200} text={buttonProp.tooltip} translateParams={{ count: limit }}
          placement="left">
          <div className={`mt${10 * index}`}>
            <button
              type="button"
              className="btn btn-default"
              data-testid={buttonProp.testId}
              disabled={isFetching}
              onClick={() => onFetchMoreLogs(buttonProp.append)}>
              <span className={`fa ${buttonProp.className}`} />
            </button>
          </div>
        </HoverTooltip>
      ))}
    </div>
  );
};
