import React, { useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { Dropdown } from 'react-bootstrap';
import { AssetGroupActions } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { AssetColumnCalculationModal } from '@/hybrid/assetGroupEditor/AssetColumnCalculationModal.molecule';
import { ToolbarDropdownButton } from '@/hybrid/core/ToolbarDropdownButton.molecule';

const addAssetGroupColumnBtnBindings = bindingsDefinition({
  isSmall: prop<boolean>(),
  sqAssetGroupActions: injected<AssetGroupActions>()
});

export const AddAssetGroupColumnBtn: SeeqComponent<typeof addAssetGroupColumnBtnBindings> = ({ isSmall }) => {
  type ModalType = 'calculation' | 'none';
  const {
    sqAssetGroupActions
  } = useInjectedBindings(addAssetGroupColumnBtnBindings);
  const { t } = useTranslation();

  const [modalShown, setModalShown] = useState<ModalType>('none');

  const menuOptions = [{
    key: 'ADD_ATTRIBUTE',
    testId: 'addItemAttribute',
    action: () => sqAssetGroupActions.addNewColumn()
  }, {
    key: 'ADD_CALCULATION',
    testId: 'addCalculationAttribute',
    action: () => setModalShown('calculation')
  }];

  const dropdownItems = _.map(menuOptions, ({ action, key, testId }) => (
    <Dropdown.Item
      key={key}
      data-testid={testId}
      onClick={action}>
      <div className="flexColumnContainer flexFill">
        <span>{t(`ASSET_GROUP_EDITOR.${key}`)}</span>
      </div>
    </Dropdown.Item>
  ));

  return (<>
    <ToolbarDropdownButton
      label="ASSET_GROUP_EDITOR.ADD_ATTRIBUTE"
      icon="fc-add-column-2"
      isSmall={isSmall}
      tooltipText="ASSET_GROUP_EDITOR.ADD_ATTRIBUTE"
      testId="addNewColumn"
      dropdownItems={dropdownItems} />
    {
      modalShown === 'calculation' &&
      <AssetColumnCalculationModal
        onClose={() => setModalShown('none')}
      />
    }
  </>);
};
