import angular, { IPromise } from 'angular';
import _ from 'lodash';
import { DatasourcesApi } from '@/sdk/api/DatasourcesApi';
import { ItemsApi } from '@/sdk/api/ItemsApi';
import { RequestsApi } from '@/sdk/api/RequestsApi';
import { NotificationsService } from '@/services/notifications.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';

/**
 * The datasources administration service handles the data and methods needed for datasource administration
 */
angular.module('Sq.Administration')
  .service('sqDatasourcesAdmin', sqDatasourcesAdmin);

export type DatasourcesAdminService = ReturnType<typeof sqDatasourcesAdmin>;

function sqDatasourcesAdmin(
  $q: ng.IQService,
  $translate: ng.translate.ITranslateService,
  uiGridConstants,
  sqDatasourcesApi: DatasourcesApi,
  sqItemsApi: ItemsApi,
  sqRequestsApi: RequestsApi,
  sqNotifications: NotificationsService
) {

  return {
    loadDatasources,
    setDatasourceEnabled,
    cancelDatasource
  };

  function loadDatasources(): IPromise<any[]> {
    return sqDatasourcesApi.getStatus({ offset: 0, limit: 1000 })
      .then(({ data: { datasourceStatuses } }) => {
        return _.map(datasourceStatuses, (dsStatus) => {
          // Adjust the fields present in each datasource status so they match the expectation of the table
          const stats = dsStatus.statistics;
          return {
            id: dsStatus.datasourceGuid,
            datasourceGuid: dsStatus.datasourceGuid,
            datasourceName: dsStatus.datasourceName,
            seeqInternal: dsStatus.seeqInternal,
            datasourceType: dsStatus.seeqInternal ? 'ADMIN.DATASOURCE.SYSTEM_DATASOURCE' :
              dsStatus.storedInSeeq ? 'ADMIN.DATASOURCE.LOCAL_DATASOURCE'
                : 'ADMIN.DATASOURCE.EXTERNAL_DATASOURCE',
            datasourceClass: dsStatus.datasourceClass,
            datasourceId: dsStatus.datasourceId,
            enabled: dsStatus.enabled,
            lastQueriedDatetime: latestIsoDateTime(
              stats.mostRecentSuccess,
              stats.mostRecentFailure,
              stats.mostRecentTimeout,
              stats.mostRecentCancellation),
            medianQueuedSeconds: roundToReasonableDurationInSeconds(stats.medianQueueNanos),
            medianDatasourceSeconds: roundToReasonableDurationInSeconds(stats.medianDatasourceNanos),
            medianTotalSeconds: roundToReasonableDurationInSeconds(stats.medianTotalNanos),
            numSuccesses: stats.numSuccesses,
            numFailures: stats.numFailures,
            numTimeouts: stats.numTimeouts,
            numCancellations: stats.numCancellations,
            numTotal: stats.numSuccesses + stats.numFailures + stats.numTimeouts + stats.numCancellations,
            totalNumDatums: stats.totalNumDatums
          };
        });
      });
  }

  /**
   * Return the latest of several ISO-8601 date strings, which may or may not be undefined
   *
   * @param isoDates - The list of iso date string
   * @returns The latest of the date strings
   */
  function latestIsoDateTime(...isoDates: string[]): string {
    let latest = undefined;
    for (const isoDate of isoDates) {
      if (latest === undefined || isoDate && isoDate > latest) {
        latest = isoDate;
      }
    }
    return latest;
  }

  /**
   * Convert a number of nanoseconds to a number of seconds, with a "reasonable" number of digits
   *
   * @param numNanos - The duration in nanoseconds
   * @returns The number of seconds of the duration with 'reasonable' number of digits
   */
  function roundToReasonableDurationInSeconds(numNanos: number): string {
    const numSeconds = numNanos / 1000000000;
    if (numSeconds <= 0) return String(numSeconds);

    // when < 1 second: format like 0.123 (millisecond precision)
    // when >= 1 second: format like 12.3 (tenth of second precision)
    return numSeconds.toFixed(numSeconds < 1 ? 3 : 1);
  }

  /**
   * Sets the isEnabled property for a datasource
   *
   * @param datasource - the datasource object
   * @param value - the value to set the isEnabled property to
   * @returns A promise that resolves when the isEnabled property has been set
   */
  function setDatasourceEnabled(datasource: any, value: boolean): IPromise<void> {
    return sqItemsApi.setProperty({ value },
      { id: datasource.datasourceGuid, propertyName: SeeqNames.Properties.Enabled })
      .then(({ data }) => {
        const newStatus = value ? 'enabled' : 'disabled';
        const defaultMessage = `${datasource.datasourceName} (${datasource.datasourceId}) has been ${newStatus}`;
        sqNotifications.success(_.get(data, 'statusMessage', defaultMessage));
      })
      .catch(({ data }) => {
        sqNotifications.error(_.get(data, 'statusMessage'));
      });
  }

  /**
   * Cancels all requests to the selected datasource
   */
  function cancelDatasource(datasource): IPromise<void> {
    const datasourceClass = datasource.datasourceClass;
    const datasourceId = datasource.datasourceId;
    return sqRequestsApi.cancelRequests({ datasourceClass, datasourceId })
      .then(({ data }) => {
        sqNotifications.success(_.get(data, 'statusMessage'));
      })
      .catch(({ data }) => {
        sqNotifications.error(_.get(data, 'statusMessage'));
      });
  }
}
