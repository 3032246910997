/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class AnnotationsApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Archive an annotation
   * @param {string} id - ID of the annotation to archive
   */
  public archiveAnnotation(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/annotations/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * @param {string} imageUrl
   * @param {string} [accept]
   */
  public corsImageProxy(
    {
      imageUrl,
      accept
    } : {
      imageUrl: string,
      accept?: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(imageUrl)) {
      throw new Error("'imageUrl' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/annotations/image/cors/{imageUrl}'
        .replace('{imageUrl}', encodeURIComponent(String(imageUrl))),
      headers: {
        ['Accept']: accept
      },
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<{}>;
  }

  /**
   * 
   * @summary Create an annotation
   * @param {models.AnnotationInputV1} body - Annotation information
   */
  public createAnnotation(
    body: models.AnnotationInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/annotations',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AnnotationOutputV1>;
  }

  /**
   * 
   * @summary Delete an image from a journal entry
   * @param {string} id - The ID of the Annotation that uses the image
   * @param {string} fileName - The file name of the image
   */
  public deleteImage(
    {
      id,
      fileName
    } : {
      id: string,
      fileName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(fileName)) {
      throw new Error("'fileName' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/annotations/{id}/images/{fileName}'
        .replace('{id}', encodeURIComponent(String(id)))
        .replace('{fileName}', encodeURIComponent(String(fileName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Get an annotation
   * @param {string} id - ID of the annotation to retrieve
   */
  public getAnnotation(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/annotations/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AnnotationOutputV1>;
  }

  /**
   * Pagination is enabled for this query
   * @summary Get a collection of annotations
   * @param {Array<string>} [annotates] - A list of item IDs that will filter the annotations returned to only those that annotate the specified items.
   * @param {string} [type] - The type of annotations to return, either Report or Journal
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getAnnotations(
    {
      annotates,
      type,
      offset,
      limit
    } : {
      annotates?: Array<string>,
      type?: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/annotations',
      params: omitBy({
        ['annotates']: annotates,
        ['type']: type,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AnnotationListOutputV1>;
  }

  /**
   * 
   * @summary Get an image for a journal entry
   * @param {string} id - The ID of the Annotation that uses the image
   * @param {string} fileName - The file name of the image
   */
  public getImage(
    {
      id,
      fileName
    } : {
      id: string,
      fileName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(fileName)) {
      throw new Error("'fileName' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/annotations/{id}/images/{fileName}'
        .replace('{id}', encodeURIComponent(String(id)))
        .replace('{fileName}', encodeURIComponent(String(fileName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<{}>;
  }

  /**
   * 
   * @summary Publish a report so it cannot be changed
   * @param {string} id - ID of the report to publish
   */
  public publishReport(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/annotations/{id}/publish'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AnnotationOutputV1>;
  }

  /**
   * 
   * @summary Update an annotation
   * @param {string} id - ID of the annotation to update
   * @param {models.AnnotationInputV1} body - Annotation input
   */
  public updateAnnotation(
    body: models.AnnotationInputV1,
    {
      id,
    } : {
      id: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/annotations/{id}/update'
        .replace('{id}', encodeURIComponent(String(id))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AnnotationOutputV1>;
  }

  /**
   * 
   * @summary Upload an image for a journal entry
   * @param {string} id - The ID of the Annotation that will use the image
   * @param {any} file - File to upload
   */
  public uploadImage(
    {
      id,
      file
    } : {
      id: string,
      file: File
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(file)) {
      throw new Error("'file' parameter required");
    }

    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/annotations/{id}/images'
        .replace('{id}', encodeURIComponent(String(id))),
      data: formData,
      headers: {
        // Prevents the default Content-Type. Because FormData is used,
        // ends up being 'multipart/form-data' with the boundary specified.
        ['Content-Type']: undefined
      },
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AnnotationOutputV1>;
  }

}
