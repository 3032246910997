import React from 'react';
import _ from 'lodash';
import { Icon } from '@/hybrid/core/Icon.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

export interface ReportFilters {
  scheduled: boolean;
  live: boolean;
  static: boolean;
  enabled: boolean;
}

export interface ReportCounts {
  scheduled: number;
  live: number;
  static: number;
}

interface ReportFilterProps {
  filters: ReportFilters;
  setFilters: (ReportTypes) => void;
}

export const ReportFilter: React.FunctionComponent<ReportFilterProps> = (props) => {
  const { filters, setFilters } = props;
  const { t } = useTranslation();

  return (
    <div>
      {_.map(filters, (value, key) => <TextButton
        key={key}
        id={`reportFilter_${key}`}
        onClick={() => setFilters(_.set(filters, key, !value))}
        extraClassNames="mr-1"
        formattedLabel={
          <>
            {t(`ADMIN.REPORT.FILTER.${key.toUpperCase()}`)}
            <Icon
              icon={value ? 'fa-check-square' : 'fa-square-o'}
              extraClassNames="ml-2 mt-1" />
          </>} />
      )}
    </div>
  );
};
