import _ from 'lodash';
import angular from 'angular';
import { UtilitiesService } from '@/services/utilities.service';
import { NotificationsService } from '@/services/notifications.service';

angular.module('Sq.TrendViewer').controller('ExportODataModalCtrl', ExportODataModalCtrl);

function ExportODataModalCtrl(
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  sqUtilities: UtilitiesService,
  sqNotifications: NotificationsService,
  odataPath: string,
  odataCapsulePath: string) {
  const vm = this;
  vm.odataEndpoint = sqUtilities.getWorkbenchAddress() + odataPath;
  vm.odataCapsuleEndpoint = sqUtilities.getWorkbenchAddress() + odataCapsulePath;
  vm.close = $uibModalInstance.close;
  vm.serviceUrl = vm.odataEndpoint.match(/.*\.svc\//)[0];
  vm.exportName = odataPath.match(/[^\/]+$/)[0];
}
