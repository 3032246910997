import angular from 'angular';
import { browserIsIE, browserIsEdgeBeforeChromium } from '@/utilities/browserId';

const dependencies = [];

/**
 * Provides access to a form of browser storage.  localStorage if available, otherwise sessionStorage.  Allows support
 * for browsers with localStorage disabled and in a form that is easily mocked.
 */
angular.module('Sq.Services.Storage', dependencies).service('sqStorage', sqStorage);

export type StorageService = ReturnType<typeof sqStorage>;

function sqStorage($window: ng.IWindowService) {
  return {
    store: getStorageSafely()
  };

  /**
   * IE throws an error if you try and access localStorage and its disabled. #@$#%!!
   */
  function getStorageSafely() {
    try {
      if ($window.localStorage) {
        // IE and Edge before chromium doesn't seem to keep localStorage synchronized
        // across tabs unless an `onstorage` handler is assigned #@$#%!!
        // REF: https://stackoverflow.com/a/42377129
        if (browserIsIE || browserIsEdgeBeforeChromium) {
          $window.onstorage = function() { };
        }
        return $window.localStorage;
      } else {
        return $window.sessionStorage;
      }
    } catch (e) {
      return $window.sessionStorage;
    }
  }
}
