import angular from 'angular';
import _ from 'lodash';
import { sqAdministration } from '@/hybrid/administration/Administration.page';
import { sqAdminContactModal } from '@/hybrid/administration/AdminContactModal.molecule';
import { sqJournalOverview } from '@/hybrid/annotation/JournalOverview.molecule';
import { sqSortIcon } from '@/hybrid/core/SortIcon.atom';
import { sqHomeScreen } from '@/hybrid/homescreen/HomeScreen.page';
import { sqAddToDisplayPane } from '@/hybrid/workbooks/AddToDisplayPane.molecule';
import { sqColorPicker } from '@/hybrid/workbooks/ColorPicker.organism';
import { sqUnitOfMeasure } from '@/hybrid/core/UnitOfMeasure.atom';
import { sqSelectItem } from '@/hybrid/core/SelectItem.organism';
import { sqChartToolbar } from '@/hybrid/trend/ChartToolbar.organism';
import { sqCancelAndExecute } from '@/hybrid/core/CancelAndExecute.molecule';
import { sqCapsuleTimeLegend } from '@/hybrid/trend/CapsuleTimeLegend.organism';
import { sqSearchTitle } from '@/hybrid/workbooks/SearchTitle.organism';
import { sqValueWithUnits } from '@/hybrid/trend/ValueWithUnits.atom';
import { sqAccessKeyModal } from '@/hybrid/header/AccessKeyModal.organism';
import { sqFormulaParameters } from '@/hybrid/workbooks/FormulaParameters.molecule';
import { sqScatterPlotLegend } from '@/hybrid/scatterPlot/ScatterPlotLegend.organism';
import { sqDensityPlotLegend } from '@/hybrid/scatterPlot/DensityPlotLegend.organism';
import { sqTrendLabelsPopover } from '@/hybrid/trend/TrendLabelsPopover.organism';
import { sqReportContentWorkstep } from '@/hybrid/reportEditor/ReportContentWorkstep.molecule';
import { sqReportContentDateRange } from '@/hybrid/reportEditor/ReportContentDateRange.molecule';
import { sqReportContentSize } from '@/hybrid/reportEditor/ReportContentSize.molecule';
import { sqReportComments } from '@/hybrid/reportEditor/ReportComments.molecule';
import { sqScatterConditionToolCard } from '@/hybrid/tools/ScatterConditionToolCard.page';
import { sqSignalSmoothing } from '@/hybrid/tools/signalSmoothing/SignalSmoothing.organism';
import { sqScatterPlotConditionButton } from '@/hybrid/scatterPlot/ScatterPlotConditionButton.molecule';
import { sqReportContentBulkEditModal } from '@/hybrid/reportEditor/ReportContentBulkEditModal.molecule';
import { sqTimeZoneSelector } from '@/hybrid/core/TimeZoneSelector.molecule';
import { sqCheckMarkOrNumber } from '@/hybrid/core/CheckMarkOrNumber.atom';
import { sqStatisticSelector } from '@/hybrid/tools/StatisticSelector.molecule';
import { sqConfigureAutoUpdateModal } from '@/hybrid/reportEditor/ConfigureAutoUpdateModal.organism';
import { sqPluginHost } from '@/hybrid/plugin/PluginHost.atom';
import { sqValueSearch } from '@/hybrid/tools/ValueSearch.organism';
import { sqWorksheetViewSelector } from '@/hybrid/trend/WorksheetViewSelector.molecule';
import { sqPathComponents } from '@/hybrid/utilities/PathComponents.atom';
import { sqSummarizeData } from '@/hybrid/trend/SummarizeData.organism';
import { sqReportContentSummary } from '@/hybrid/reportEditor/ReportContentSummary.organism';
import { sqDigitalFilter } from '@/hybrid/tools/digitalFilter/DigitalFilter.organism';
import { sqItemAclModal } from '@/hybrid/accessControl/ItemAclModal.molecule';
import { sqWorksheetWatchers } from '@/hybrid/worksheets/WorksheetWatchers.atom';
import { sqWorksheetsPane } from '@/hybrid/worksheets/WorksheetsPane.organism';
import { sqEditUserProfile } from '@/hybrid/header/EditUserProfile.atom';
import { sqPeriodicCondition } from '@/hybrid/tools/periodicCondition/PeriodicCondition.organism';
import { sqOrganizerTourModal } from '@/hybrid/tour/OrganizerTourModal.organism';
import { sqWorkbenchTourModal } from '@/hybrid/tour/WorkbenchTourModal.organism';
import { sqScatterPlotMarkerSizePopoverButton } from '@/hybrid/scatterPlot/ScatterPlotMarkerSizePopoverButton.molecule';
import { sqTableBuilder } from '@/hybrid/tableBuilder/TableBuilder.page';
import { sqAssetGroupView } from '@/hybrid/assetGroupEditor/AssetGroupView.molecule';
import { sqSearchWidget } from '@/hybrid/search/SearchWidget.organism';
import { sqCompositeSearch } from '@/hybrid/tools/compositeSearch/CompositeSearch.organism';
import { sqProfileSearch } from '@/hybrid/tools/profileSearch/ProfileSearch.organism';
import { sqItemPropertiesSelectorButton } from '@/hybrid/utilities/ItemPropertiesSelectorButton.molecule';
import { sqCapsuleTable } from '@/hybrid/reportEditor/CapsuleTable.molecule';
import { sqFroalaEditor } from '@/hybrid/annotation/FroalaEditor.organism';
import { sqReportConfig } from '@/hybrid/reportEditor/ReportConfig.organism';
import { sqCopyButtonGivenText } from '@/hybrid/core/CopyButtonGivenText.atom';
import { sqCopyableInputField } from '@/hybrid/core/CopyableInputField.molecule';
import { sqEditor } from '@/hybrid/annotation/Editor.organism';
import { sqInsertContentDropdown } from '@/hybrid/annotation/InsertContentDropdown.molecule';
import { sqManualSignal } from '@/hybrid/tools/manualSignal/ManualSignal.organism';
import { sqScatterPlotChart } from '@/hybrid/scatterPlot/ScatterPlotChart.molecule';
import { sqImportDatafile } from '@/hybrid/tools/importDatafile/ImportDatafile.organism';
import { sqJournalCard } from '@/hybrid/annotation/JournalCard.molecule';
import { sqJournalReplyIcon } from '@/hybrid/annotation/JournalReplyIcon.atom';
import { sqJournalLink } from '@/hybrid/annotation/JournalLink.molecule';
import { sqJournalComment } from '@/hybrid/annotation/JournalComment.molecule';
import { sqJournalComments } from '@/hybrid/annotation/JournalComments.molecule';
import { sqJournalFooter } from '@/hybrid/annotation/JournalFooter.molecule';
import { sqAuditTrail } from '@/hybrid/auditTrail/AuditTrail.page';
import { sqDateTimeEntry } from '@/hybrid/core/DateTimeEntry.atom';
import { sqReportContentModal } from '@/hybrid/reportEditor/ReportContentModal.molecule';
import { sqReferencePanel } from '@/hybrid/tools/referencePanel/ReferencePanel.organism';
import { sqCapsuleInput } from '@/hybrid/investigate/customCondition/CapsuleInput.molecule';
import { sqCkMigrationModal } from '@/hybrid/annotation/CKMigrationModal.molecule';
import { sqDatasourcesTab } from '@/hybrid/administration/datasources/DatasourcesTab.page';
import { sqDatasourcesStatus } from '@/hybrid/header/DatasourcesStatus.organism';
import { sqLogTracker } from '@/hybrid/logTracker/LogTracker.page';
import { sqThresholdMetric } from '@/hybrid/tools/thresholdMetric/ThresholdMetric.organism';
import { sqViewModeWorksheetActions } from '@/hybrid/header/ViewModeWorksheetActions.molecule';
import { sqScatterPlotMinimap } from '@/hybrid/scatterPlot/minimap/Minimap.molecule';
import { sqTrendHelp } from '@/hybrid/trend/TrendHelp.atom';
import { sqTreeMapHelp } from '@/hybrid/treeMap/TreeMapHelp.atom';
import { sqPdfPreviewModal } from '@/hybrid/reportEditor/PdfPreviewModal.organism';
import { sqScatterPlotColorModal } from '@/hybrid/scatterPlot/ScatterPlotColorModal.organism';
import { sqDisplayRangeSelector } from '@/hybrid/trend/DisplayRangeSelector.molecule';
import { sqDurationEntry } from "@/hybrid/core/DurationEntry.atom";
import { sqAutoUpdate } from "@/hybrid/trend/AutoUpdate.molecule";
import { sqSignalFromCondition } from '@/hybrid/tools/signalFromCondition/SignalFromCondition.organism';
import { sqHeaderLogo } from '@/hybrid/header/HeaderLogo.atom';
import { sqFrequencyAnalysis } from '@/hybrid/tools/frequencyAnalysis/FrequencyAnalysis.organism';
import { sqHamburgerMenu } from '@/hybrid/header/HamburgerMenu.atom';
import { sqPrediction } from '@/hybrid/tools/prediction/Prediction.organism';
import { sqFormulaTool } from '@/hybrid/tools/formula/FormulaTool.organism';
import { sqFormulaDisplayOnly } from '@/hybrid/formula/FormulaDisplayOnly.atom';
// Provide all react components that should be accessible from angular templates here
// Use angularComponent to create an angular definition from a React definition
const exposedComponents: { [angularName: string]: ng.IComponentOptions } = {
  sqAdministration,
  sqAdminContactModal,
  sqJournalOverview,
  sqSortIcon,
  sqHomeScreen,
  sqAddToDisplayPane,
  sqColorPicker,
  sqSelectItem,
  sqUnitOfMeasure,
  sqChartToolbar,
  sqCapsuleTimeLegend,
  sqCancelAndExecute,
  sqTrendLabelsPopover,
  sqScatterConditionToolCard,
  sqScatterPlotConditionButton,
  sqScatterPlotMarkerSizePopoverButton,
  sqSearchTitle,
  sqSignalSmoothing,
  sqDateTimeEntry,
  sqAccessKeyModal,
  sqFormulaParameters,
  sqSummarizeData,
  sqReportContentSummary,
  sqScatterPlotLegend,
  sqDensityPlotLegend,
  sqReportContentWorkstep,
  sqReportContentDateRange,
  sqReportContentSize,
  sqReportContentBulkEditModal,
  sqReportComments,
  sqTimeZoneSelector,
  sqStatisticSelector,
  sqValueWithUnits,
  sqCheckMarkOrNumber,
  sqConfigureAutoUpdateModal,
  sqValueSearch,
  sqTableBuilder,
  sqWorksheetViewSelector,
  sqOrganizerTourModal,
  sqWorkbenchTourModal,
  sqCkMigrationModal,
  sqPathComponents,
  sqPluginHost,
  sqManualSignal,
  sqDigitalFilter,
  sqItemAclModal,
  sqFormulaTool,
  sqCompositeSearch,
  sqWorksheetWatchers,
  sqInsertContentDropdown,
  sqWorksheetsPane,
  sqPeriodicCondition,
  sqEditUserProfile,
  sqAssetGroupView,
  sqJournalLink,
  sqItemPropertiesSelectorButton,
  sqCapsuleTable,
  sqFroalaEditor,
  sqReportConfig,
  sqCopyButtonGivenText,
  sqCopyableInputField,
  sqJournalComment,
  sqJournalComments,
  sqJournalFooter,
  sqPdfPreviewModal,
  sqImportDatafile,
  sqJournalCard,
  sqJournalReplyIcon,
  sqScatterPlotChart,
  sqReportContentModal,
  sqViewModeWorksheetActions,
  sqEditor,
  sqAuditTrail,
  sqReferencePanel,
  sqCapsuleInput,
  sqDatasourcesTab,
  sqDatasourcesStatus,
  sqThresholdMetric,
  sqScatterPlotMinimap,
  sqTrendHelp,
  sqTreeMapHelp,
  sqProfileSearch,
  sqLogTracker,
  sqScatterPlotColorModal,
  sqDisplayRangeSelector,
  sqDurationEntry,
  sqAutoUpdate,
  sqSignalFromCondition,
  sqFrequencyAnalysis,
  sqPrediction,
  sqHeaderLogo,
  sqSearchWidget,
  sqFormulaDisplayOnly,
  sqHamburgerMenu
};

const angularDependencies = [];

export default _.reduce(
  exposedComponents,
  (module, component, angularName) => module.component(angularName, component),
  angular.module('Sq.ReactComponents', angularDependencies)
);
