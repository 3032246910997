import _ from 'lodash';
import angular from 'angular';
import { DurationActions } from '@/trendData/duration.actions';
import { DurationStore } from '@/trendData/duration.store';
import { TimebarStore } from '@/trendViewer/timebar.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { TrendActions } from '@/trendData/trend.actions';
import { TrendStore } from '@/trendData/trend.store';
import { DEBOUNCE } from '@/main/app.constants';

angular.module('Sq.TrendViewer').controller('TimebarCtrl', TimebarCtrl);

function TimebarCtrl(
  $scope: ng.IScope,
  sqDurationActions: DurationActions,
  sqDurationStore: DurationStore,
  sqTimebarStore: TimebarStore,
  sqWorksheetStore: WorksheetStore,
  sqTrendActions: TrendActions,
  sqTrendStore: TrendStore
) {
  const vm = this;

  // debounce region updating on resize, to avoid excessive requests
  vm.allRegionUpdateOnResize = _.debounce(sqTrendActions.fetchAllTimebarCapsules, DEBOUNCE.LONG);
  vm.updateDisplayRangeTimes = sqDurationActions.displayRange.updateTimes;
  vm.toggleCapsulePreview = sqTrendActions.setCapsulePreview;

  $scope.$listenTo(sqDurationStore, syncWithDurationStore);
  $scope.$listenTo(sqWorksheetStore, ['timezone'], syncWithWorksheetStore);
  $scope.$listenTo(sqTimebarStore, syncWithTimebarStore);
  $scope.$listenTo(sqTrendStore, ['capsulePreview'], syncWithTrendStore);

  /**
   * Updates view model with properties from the worksheet store
   */
  function syncWithWorksheetStore() {
    vm.timezone = sqWorksheetStore.timezone;
  }

  function syncWithTimebarStore() {
    vm.regions = sqTimebarStore.regions;
  }

  /**
   * Updates view model with properties from the duration store
   */
  function syncWithDurationStore() {
    vm.displayRange = sqDurationStore.displayRange;
    vm.investigateRange = sqDurationStore.investigateRange;
  }

  /**
   * Updates view model with properties from trend store
   */
  function syncWithTrendStore() {
    vm.capsulePreview = sqTrendStore.capsulePreview;
  }
}
