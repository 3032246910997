import angular from 'angular';
import template from './worksteps.directive.html';

angular.module('Sq.Worksteps').directive('sqWorksteps', sqWorksteps);

function sqWorksteps() {
  return {
    restrict: 'EA',
    controller: 'WorkstepsCtrl',
    controllerAs: 'ctrl',
    scope: {},
    template
  };
}
