import React, { useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { CELL_TYPES, Table, TableColumn } from '@/hybrid/core/Table.atom';
import { ReportActions } from '@/reportEditor/report.actions';
import { ReportStore } from '@/reportEditor/report.store';
import _ from 'lodash';
import classNames from 'classnames';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { APP_STATE } from '@/main/app.constants';

const reportContentBulkEditTableBindings = bindingsDefinition({
  allContent: prop<any[]>(),
  sqReportActions: injected<ReportActions>(),
  sqReportStore: injected<ReportStore>(),
  $state: injected<ng.ui.IStateService>()
});

export const ReportContentBulkEditTable: SeeqComponent<typeof reportContentBulkEditTableBindings> = (props) => {
  const {
    sqReportActions,
    sqReportStore,
    $state
  } = useInjectedBindings(reportContentBulkEditTableBindings);
  const SMALL_CELL_WIDTH = 90;
  const LARGE_CELL_WIDTH = 140;
  const POSITION_FIELD = 'position';

  const { allContent } = props;

  const selectedContent = useFluxPath(sqReportStore, () => sqReportStore.selectedBulkContent);
  const [selectAll, setSelectAll] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const [sortOptions, setSortOptions] = useState({ field: POSITION_FIELD, asc: true });

  let filteredAndSortedContent = _.chain(allContent)
    .reject(content =>
      _.some(
        _.keys(searchParams),
        field => !content[field].toString().toLowerCase().includes(searchParams[field].toString().toLowerCase())
      )
    )
    .sortBy(sortOptions.field)
    .value();

  if (!sortOptions.asc) {
    filteredAndSortedContent = _.reverse(filteredAndSortedContent);
  }

  const selectAllCallback = () => {
    sqReportActions.setSelectedBulkContent(selectAll ? [] : filteredAndSortedContent);
    setSelectAll(!selectAll);
  };

  const onRowSelectCallback = (content) => {
    sqReportActions.toggleSpecificSelectedContent(content);
  };

  const filterCallback = (option, field) => {
    if (option.value !== '') {
      searchParams[field] = option.value;
    } else {
      delete searchParams[field];
    }
    setSearchParams({ ...searchParams });
  };

  const sortCallback = (field) => {
    setSortOptions({ field, asc: field === sortOptions.field ? !sortOptions.asc : true });
  };

  const renderWorksheetCell = content => (
    <div className={classNames('cursorPointer', `min-width-${LARGE_CELL_WIDTH}`)}>
      <a target="_blank" data-testid="worksheetCell"
        href={$state.href(APP_STATE.WORKSHEET, {
          workbookId: content.workbookId,
          worksheetId: content.worksheetId
        })}
      >{content.worksheetName}</a>
    </div>
  );

  const columns: TableColumn[] = [
    {
      accessor: 'id',
      sortable: false,
      filterable: false,
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 40 }
    }, {
      accessor: 'workbookName',
      searchProperty: 'workbookName',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.WORKBOOK_NAME',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: LARGE_CELL_WIDTH, wordWrap: 'break-word' }
    }, {
      accessor: 'worksheetName',
      searchProperty: 'worksheetName',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.WORKSHEET_NAME',
      sortable: true,
      filterable: true,
      cellRenderFunction: renderWorksheetCell
    }, {
      accessor: 'viewName',
      searchProperty: 'viewName',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.WORKSHEET_TYPE',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: LARGE_CELL_WIDTH - 20, wordWrap: 'break-word' }
    }, {
      accessor: 'dateRangeName',
      searchProperty: 'dateRangeName',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.DATE_RANGE',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: LARGE_CELL_WIDTH - 20, wordWrap: 'break-word' }
    }, {
      accessor: POSITION_FIELD,
      searchProperty: POSITION_FIELD,
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.POSITION',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: SMALL_CELL_WIDTH + 5 }
    }, {
      accessor: 'workstepStatus',
      searchProperty: 'workstepStatus',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.WORKSTEP_STATUS',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: SMALL_CELL_WIDTH + 5 }
    }, {
      accessor: 'scaleName',
      searchProperty: 'scaleName',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.SCALE_NAME',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: SMALL_CELL_WIDTH }
    }, {
      accessor: 'sizeName',
      searchProperty: 'sizeName',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.SIZE_NAME',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: SMALL_CELL_WIDTH }
    }, {
      accessor: 'shapeName',
      searchProperty: 'shapeName',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.SHAPE_NAME',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: SMALL_CELL_WIDTH }
    }, {
      accessor: 'summaryAsString',
      searchProperty: 'summaryAsString',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.SUMMARY',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: LARGE_CELL_WIDTH }
    }, {
      accessor: 'assetSelectionName',
      searchProperty: 'assetSelectionName',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.ASSET_SELECTION',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: LARGE_CELL_WIDTH - 20, wordWrap: 'break-word' }
    }, {
      accessor: 'height',
      searchProperty: 'height',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.HEIGHT',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: SMALL_CELL_WIDTH }
    }, {
      accessor: 'width',
      searchProperty: 'width',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.WIDTH',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: SMALL_CELL_WIDTH }
    }];

  return <div className="bulkEditTable flexFill">
    <Table
      onRowSelectCallback={onRowSelectCallback}
      selectedIds={_.map(selectedContent, 'id')}
      columns={columns}
      items={filteredAndSortedContent}
      selectAllCallback={selectAllCallback}
      selectAll={selectAll}
      filterTableCallback={filterCallback}
      sortAscending={sortOptions.asc}
      sortTableCallback={sortCallback}
      sortProperty={sortOptions.field}
      searchParams={searchParams}
    />
  </div>;
};
