import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { ReportStore } from '@/reportEditor/report.store';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import _ from 'lodash';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import {
  KEEP_CURRENT_DATE_RANGE,
  KEEP_CURRENT_SCALE,
  KEEP_CURRENT_SHAPE,
  KEEP_CURRENT_SIZE
} from '@/reportEditor/report.module';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';

const reportContentModalButtonsBindings = bindingsDefinition({
  cancel: prop<any>(),
  update: prop<any>(),
  sqReportStore: injected<ReportStore>()
});

export const ReportContentModalButtons: SeeqComponent<typeof reportContentModalButtonsBindings> = (props) => {
  const { cancel, update } = props;
  const { sqReportStore } = useInjectedBindings(reportContentModalButtonsBindings);
  const { t } = useTranslation();

  const selectedContent = useFluxPath(sqReportStore, () => sqReportStore.selectedBulkContent);
  const isKeepWorkstep = useFluxPath(sqReportStore, () => !sqReportStore.shouldUpdateBulkWorkstep);
  const isKeepContentSize = useFluxPath(sqReportStore, () => sqReportStore.bulkSize.key === KEEP_CURRENT_SIZE.key);
  const isKeepContentShape = useFluxPath(sqReportStore, () => sqReportStore.bulkShape.key === KEEP_CURRENT_SHAPE.key);
  const isKeepFontSize = useFluxPath(sqReportStore, () => sqReportStore.bulkScale.key === KEEP_CURRENT_SCALE.key);
  const isKeepDateRange = useFluxPath(sqReportStore, () =>
    !_.isUndefined(sqReportStore.bulkDateRange) && sqReportStore.bulkDateRange.id === KEEP_CURRENT_DATE_RANGE.id);

  let updateTooltip = '';
  if (_.size(selectedContent) > 0) {
    updateTooltip = isKeepWorkstep && isKeepContentSize && isKeepContentShape && isKeepFontSize && isKeepDateRange ?
      'REPORT.MODAL.BULK_EDIT.REFRESH_CONTENT_TOOLTIP' : 'REPORT.MODAL.BULK_EDIT.UPDATE_CONTENT_TOOLTIP';
  }

  return <div className="flexColumnContainer">
    <div className="flexFill" />
    <TextButton
      onClick={cancel}
      label="CANCEL"
      extraClassNames="min-width-60"
      size="lg"
    />
    <HoverTooltip text={updateTooltip}>
      <span data-testid={updateTooltip}>
        <TextButton
          disabled={_.isEmpty(selectedContent)}
          onClick={update}
          formattedLabel={<>{t('UPDATE')} ({_.size(selectedContent)})</>}
          extraClassNames="min-width-60 ml15"
          size="lg"
          variant="theme"
          testId="bulkUpdateButton"
        />
      </span>
    </HoverTooltip>
  </div>;
};

export const sqReportContentDateRange = angularComponent(reportContentModalButtonsBindings, ReportContentModalButtons);
