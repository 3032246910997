import _ from 'lodash';
import { BaseToolStoreService } from '@/investigate/baseToolStore.service';
import { ItemDecoratorService } from '@/trendViewer/itemDecorator.service';
import { UtilitiesService } from '@/services/utilities.service';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { ITEM_DATA_STATUS, ITEM_TYPES, ITEM_TYPES_TO_API_TYPES } from '@/trendData/trendData.module';
import { DISPLAY_MODE } from '@/main/app.constants';
import { TREND_TOOLS } from '@/investigate/investigate.module';

export type FormulaToolStore = ReturnType<typeof sqFormulaToolStore>['exports'];

export function sqFormulaToolStore(
  sqBaseToolStore: BaseToolStoreService,
  sqItemDecorator: ItemDecoratorService,
  sqUtilities: UtilitiesService,
  sqTrendDataHelper: TrendDataHelperService
) {

  const store = {
    initialize() {
      this.state = this.immutable(_.assign({}, sqBaseToolStore.COMMON_PROPS, {
        formula: '',
        parameters: [],
        navigationStack: [],
        helpShown: true,
        formulaFilter: undefined
      }));
    },

    exports: {
      get formula() {
        return this.state.get('formula');
      },

      get parameters() {
        return this.state.get('parameters');
      },

      get navigationStack() {
        return this.state.get('navigationStack');
      },

      getNextIdentifier() {
        let identifier;
        let i = 0;
        const parameters = this.state.get('parameters');

        while (!identifier) {
          identifier = sqUtilities.getShortIdentifier(i++);
          if (_.some(parameters, ['identifier', identifier])) {
            identifier = undefined;
          }
        }

        return identifier;
      },

      get helpShown() {
        return this.state.get('helpShown');
      },

      get formulaFilter() {
        return this.state.get('formulaFilter');
      }
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @return {Object} State for the store
     */
    dehydrate() {
      return this.state.serialize();
    },

    /**
     * Sets the powerSearch
     *
     * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      FORMULA_SET_FORMULA: 'setFormula',
      FORMULA_ADD_PARAMETER: 'addParameter',
      FORMULA_ADD_DETAILS_PANE_PARAMETERS: 'addDetailsPaneParameters',
      FORMULA_UPDATE_PARAMETER: 'updateParameter',
      FORMULA_REMOVE_PARAMETER: 'removeParameter',
      FORMULA_REMOVE_ALL_PARAMETERS: 'removeAllParameters',
      FORMULA_SET_NAVIGATION_STACK: 'setNavigationStack',
      INVESTIGATE_SET_DISPLAY_MODE: 'initializeParameters',
      FORMULA_TOGGLE_HELP_SHOWN: 'toggleHelpShown',
      FORMULA_SET_FILTER: 'setFilter'
    },

    /**
     * Sets the formula
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.formula - the formula
     */
    setFormula(payload) {
      this.state.set('formula', payload.formula);
    },

    /**
     * Adds a parameter that will be used as input for the formula.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object} payload.parameter - Parameter to add
     * @param {String} payload.parameter.identifier - The symbolic identifier for the parameter
     * @param {Object} payload.parameter.item - Object containing item properties
     * @param {String} payload.parameter.item.id - The id of the item referenced by the parameter
     * @param {String} payload.parameter.item.name - The name of the item referenced by the parameter
     */
    addParameter(payload) {
      this.state.push('parameters', payload.parameter);
      this.addItemToOriginalParameters(payload.parameter.item);
    },

    /**
     * Adds all parameters from the details pane that don't already exist in the parameters list.
     */
    addDetailsPaneParameters() {
      this.state.set('parameters', parametersFromDetailsPane(this));
    },

    /**
     * Updates a parameter that will be used as input for the formula. If the parameter identifier changes it also
     * updates references in the formula.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Number} payload.index - Index number of the parameter being updated
     * @param {Object} payload.parameter - Parameter being updated
     * @param {String} payload.parameter.identifier - The symbolic identifier for the parameter
     * @param {Object} payload.parameter.item - Object containing item properties
     * @param {String} payload.parameter.item.id - The id of the item referenced by the parameter
     * @param {String} payload.parameter.item.name - The name of the item referenced by the parameter
     */
    updateParameter(payload) {
      const oldParameter = this.state.get(['parameters', payload.index]);
      this.state.set(['parameters', payload.index], payload.parameter);
      this.addItemToOriginalParameters(payload.parameter.item);
      if (oldParameter?.identifier !== payload.parameter.identifier) {
        const replaceRegex = new RegExp('\\$' + oldParameter?.identifier + '\\b', 'g');
        this.state.set('formula',
          this.state.get('formula').replace(replaceRegex, '$' + payload.parameter.identifier));
      }
    },

    /**
     * Removes a parameter based on its identifier.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Number} payload.identifier - Index number of the parameter being updated
     */
    removeParameter({ identifier }) {
      const index = _.findIndex(this.state.get('parameters'), { identifier });
      this.state.splice('parameters', [index, 1]);
    },

    /**
     * Removes all parameters.
     */
    removeAllParameters() {
      this.state.set('parameters', []);
    },

    /**
     * Sets the formula editor navigation stack
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.navigationStack - the navigation stack
     */
    setNavigationStack(payload) {
      this.state.set('navigationStack', payload.navigationStack);
    },

    /**
     * Resets state and sets the parameters to be all items in the details pane when in NEW mode.
     *
     * @param {Object} payload - Object containing state information
     * @param {String} payload.mode - The display mode being set, one of DISPLAY_MODE
     * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
     */
    initializeParameters(payload) {
      this.reset(payload);
      if (payload.mode === DISPLAY_MODE.NEW && payload.type === TREND_TOOLS.FORMULA) {
        this.addDetailsPaneParameters();
      }
    },

    toggleHelpShown() {
      this.state.set('helpShown', !this.state.get('helpShown'));
    },

    /**
     * Ensures that the list of original parameters contains the new item so that the sq-select-item list will have the
     * item in its list.
     *
     * @param {Object} item - The item to add
     */
    addItemToOriginalParameters(item) {
      if (!_.some(this.state.get('originalParameters'), ['id', item.id])) {
        this.state.push('originalParameters', item);
      }
    },

    /**
     * Adds the formula and parameters to the config as part of what gets rehydrated when the tool is loaded.
     * @param {Object} config - The UIConfig state to populate the form
     * @param {Object[]} parameters - The parameters used in the formula
     * @param {String} formula - The formula for the calculated item
     * @returns {Object} The updated config
     */
    migrateSavedConfig(config, parameters, formula) {
      config.formula = formula;
      config.parameters = _.map(parameters, (parameter: any) => ({ identifier: parameter.name, item: parameter.item }));
      return config;
    },

    /**
     * Removes properties from config which are stored as part of the formula.
     *
     * @param {Object} config - The state that will be saved to UIConfig
     * @return {Object} The modified config
     */
    modifyConfigParams(config) {
      return _.omit(config, ['formula', 'parameters', 'navigationStack']);
    },

    setFilter({ filter }) {
      this.state.set('formulaFilter', filter);
    }
  };

  /**
   * Transforms the items in the details pane into an array of simplified name parameters. Skips any details pane items
   * that already exist in a supplied array of existing parameters. Also skips the current formula.
   * Internally, it sorts the items by the length of their names before creating the simplified name in order to favor
   * keeping shorter names the same.
   *
   * @param {Object} store - Power search store object.
   */
  function parametersFromDetailsPane(store) {
    let paramLetterCount = 0;
    const existingParams = store.state.get('parameters');
    const existingParamNames = _.map(existingParams, 'identifier');
    const formula = store.state.get('formula');
    const currentFormulaId = store.state.get('id');

    return _.chain(sqTrendDataHelper.getAllItems({
        excludeDataStatus: [ITEM_DATA_STATUS.REDACTED],
        // Types that can be used as parameters
        itemTypes: [ITEM_TYPES.SERIES, ITEM_TYPES.CAPSULE_SET, ITEM_TYPES.SCALAR]
      }))
      .thru(sqItemDecorator.decorate)
      .concat(_.map(existingParams, 'item'))
      .sort((a: any, b: any) => a.name.length - b.name.length)
      .map((item: any) => {
        let paramName;
        const existingParam = _.find(existingParams, ['item.id', item.id]);

        // Skip item if it is the current formula
        if (item.id === currentFormulaId) {
          return;
        }

        // If the parameter already exists, then use it
        if (existingParam) {
          return existingParam;
        }

        // Otherwise, choose a parameter name
        const paramsInFormula = getParamsInFormula(formula);

        // First try, use a "friendly" name based on the actual text of the item
        const namesToAvoid = _.concat(paramsInFormula, existingParamNames);

        paramName = sqUtilities.getMediumIdentifier(item.name, namesToAvoid);
        if (_.isEmpty(paramName)) {
          // We couldn't come up with a simple identifier from the item name.
          // It may have been too long, or all numbers and symbols.
          // Fall back the old naming scheme for parameters as $a, $b, $c.
          do {
            paramName = sqUtilities.getShortIdentifier(paramLetterCount++);
          } while (_.includes(namesToAvoid, paramName));
        }

        // Remember this name so we don't use it again on a later parameter
        existingParamNames.push(paramName);

        // Get API type from item type
        item.type = ITEM_TYPES_TO_API_TYPES[item.itemType];

        return {
          identifier: paramName,
          item: _.pick(item, ['id', 'name', 'assets', 'type'])
        };
      })
      .orderBy(['item.name'], ['asc'])
      .compact()
      .uniq()
      .value();
  }

  /**
   * Get array of parameter names (without $ prepended) used in formula text
   */
  function getParamsInFormula(formula) {
    let param;
    const paramRegEx = /\$(\w+)/g;
    const paramsInFormula = [];

    while ((param = paramRegEx.exec(formula)) !== null) {
      paramsInFormula.push(param[1]);
    }

    return paramsInFormula;
  }

  return sqBaseToolStore.extend(store, TREND_TOOLS.FORMULA);
}
