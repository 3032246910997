import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { UtilitiesService } from '@/services/utilities.service';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { Modal } from 'react-bootstrap';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { FORM_ERROR, FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { TimezonesService } from '@/datetime/timezone.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { NotificationsService } from '@/services/notifications.service';
import { LOCALES, SUPPORTED_LANGUAGES } from '@/main/app.constants';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { UserInputV1, UsersApi } from '@/sdk';

const editUserProfileBindings = bindingsDefinition({
  closeFn: prop<() => void>(),
  sqTimezones: injected<TimezonesService>(),
  sqWorkbenchStore: injected<WorkbenchStore>(),
  sqUsersApi: injected<UsersApi>(),
  sqWorkbenchActions: injected<WorkbenchActions>(),
  sqNotifications: injected<NotificationsService>(),
  sqUtilities: injected<UtilitiesService>()
});

export const EditUserProfile: SeeqComponent<typeof editUserProfileBindings> = (props) => {
  const {
    sqTimezones,
    sqWorkbenchStore,
    sqUsersApi,
    sqWorkbenchActions,
    sqNotifications,
    sqUtilities
  } = useInjectedBindings(editUserProfileBindings);
  const { closeFn } = props;
  const { t } = useTranslation();

  const { currentUser: user, userTimeZone, userLanguage, preferNewTab } = useFlux(sqWorkbenchStore);

  const [editingPassword, setEditingPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMismatch, setPasswordsMismatch] = useState(false);
  const [formSaveInProcess, setFormSaveInProcess] = useState(false);

  const formattedLanguageOptions = _.map(SUPPORTED_LANGUAGES,
    language => ({ text: language.translationKey, value: language.key }));

  const validateMinLength = value => _.isEmpty(value) || _.size(value) < 8;

  useEffect(() => {
    setPasswordsMismatch(!_.isEmpty(newPassword) && !_.isEmpty(confirmPassword) && newPassword !== confirmPassword);
  }, [newPassword, confirmPassword]);

  const updateUser = (values) => {
    const { firstName, lastName, email, timezone, language, preferNewTab } = values;
    const userProps = _.pick(values, ['email', 'firstName', 'lastName']);
    const name = `${_.trim(firstName)} ${_.trim(lastName)}`;

    setFormSaveInProcess(true);
    _.assign(userProps, { name });

    /**
     * For SEEQ users we allow them to update their email, but their email is also their username. So, when they
     * update their email, then we have to also update their username (as email and username seem to be one to the end
     * user, at least right now). If a user is however an Active Directory user then they really just edit an email,
     * not their username. IsPasswordSettable is the flag that tells us if they can modify all their properties or not.
     */
    if (user.isPasswordSettable) {
      _.assign(userProps, { username: email });
    }

    return sqUsersApi.updateUser(userProps as UserInputV1, { id: user.id })
      .then(() => sqWorkbenchActions.setCurrentUser())
      .then(() => sqWorkbenchActions.setUserTimeZone(timezone))
      .then(() => sqWorkbenchActions.setUserLanguage(language?.value ?? LOCALES.EN))
      .then(() => sqWorkbenchActions.setPreferNewTab(preferNewTab))
      .then(() => sqUtilities.switchLanguage(language?.value ?? LOCALES.EN))
      .then(() => sqNotifications.successTranslate('USER.PROFILE_UPDATED'))
      .then(closeFn)
      .catch(sqNotifications.apiError)
      .finally(() => {
        setFormSaveInProcess(false);
      });
  };

  const updatePassword = () => {
    setFormSaveInProcess(true);
    return sqUsersApi.updatePassword({ currentPassword, newPassword }, { id: user.id })
      // We assume that authProvider is Seeq because only those users can change their password.
      .then(() => sqNotifications.successTranslate('USER.PASSWORD_CHANGED'))
      .then(closeFn)
      .catch(sqNotifications.apiError)
      .finally(() => {
        setFormSaveInProcess(false);
      });
  };

  const displayEditUser = () => setEditingPassword(false);

  const passwordChangeForm: FormElement = {
    component: 'FormGroup',
    name: 'editPassword',
    includeIf: editingPassword,
    components: [{
      component: 'LabelFormComponent',
      name: 'currentPasswordLabel',
      value: 'USER.CURRENT_PASSWORD',
      extraClassNames: 'text-bolder',
      includeIf: !user.isAdmin
    }, {
      component: 'FormControlFormComponent',
      name: 'currentPassword',
      value: currentPassword,
      onChange: (value: string) => setCurrentPassword(value),
      className: 'mb20',
      size: 'md',
      includeIf: !user.isAdmin,
      type: 'password',
      testId: 'currentPassword'
    }, {
      component: 'LabelFormComponent',
      name: 'newPasswordLabel',
      value: 'USER.NEW_PASSWORD',
      extraClassNames: 'text-bolder'
    }, {
      component: 'FormControlFormComponent',
      name: 'password',
      value: newPassword,
      onChange: (value: string) => setNewPassword(value),
      validation: validateMinLength,
      customError: 'USER.PASSWORD_MIN_LENGTH',
      customErrorParams: { min: '8' },
      size: 'md',
      type: 'password',
      testId: 'password'
    }, {
      component: 'LabelFormComponent',
      name: 'confirmPasswordLabel',
      value: 'USER.PASSWORD_CHECK',
      extraClassNames: 'text-bolder mt20'
    }, {
      component: 'FormControlFormComponent',
      name: 'confirmPassword',
      value: confirmPassword,
      onChange: (value: string) => setConfirmPassword(value),
      validation: validateMinLength,
      customError: 'USER.PASSWORD_MIN_LENGTH',
      customErrorParams: { min: '8' },
      size: 'md',
      type: 'password',
      testId: 'confirmPassword'
    }, {
      component: 'ErrorMessageFormComponent',
      name: 'mismatchedPasswords',
      type: FORM_ERROR,
      failForm: true,
      includeIf: passwordsMismatch,
      value: 'USER.PASSWORD_MISMATCH'
    }]
  };

  const formDefinition: FormElement[] = [{
    component: 'FormGroup',
    name: 'userProfile',
    includeIf: !editingPassword,
    components: [{
      component: 'LabelFormComponent',
      name: 'firstNameLabel',
      value: 'USER.FIRST_NAME',
      extraClassNames: 'text-bolder'
    }, {
      component: 'FormControlFormComponent',
      name: 'firstName',
      value: user.firstName,
      onChange: _.noop,
      className: 'mb20',
      size: 'md',
      testId: 'firstName'
    }, {
      component: 'LabelFormComponent',
      name: 'lastNameLabel',
      value: 'USER.LAST_NAME',
      extraClassNames: 'text-bolder mb5'
    }, {
      component: 'FormControlFormComponent',
      name: 'lastName',
      value: user.lastName,
      onChange: _.noop,
      className: 'mb20',
      size: 'md',
      testId: 'lastName'
    }, {
      component: 'LabelFormComponent',
      name: 'emailLabel',
      value: 'USER.EMAIL',
      extraClassNames: 'text-bolder mb5'
    }, {
      component: 'FormControlFormComponent',
      name: 'email',
      value: user.email,
      onChange: _.noop,
      className: 'mb20',
      size: 'md',
      testId: 'email'
    }, {
      component: 'LabelFormComponent',
      name: 'timezoneLabel',
      value: 'USER.TIME_ZONE',
      extraClassNames: 'text-bolder'
    }, {
      component: 'TimeZoneSelectorFormComponent',
      name: 'timezone',
      value: userTimeZone || sqTimezones.defaultTimezone,
      onChange: _.noop,
      defaultTimeZone: sqTimezones.defaultTimezone,
      skipStore: true,
      testId: 'timezone'
    }, {
      component: 'LabelFormComponent',
      name: 'languageLabel',
      value: 'USER.LANGUAGE.SELECTION',
      extraClassNames: 'text-bolder'
    }, {
      component: 'IconSelectFormComponent',
      name: 'language',
      value: userLanguage,
      onChange: _.noop,
      selectOptions: formattedLanguageOptions,
      insideModal: true,
      skipStore: true,
      testId: 'language'
    }, {
      component: 'LabelFormComponent',
      name: 'preferNewTabColumn',
      value: '',
      extraClassNames: 'text-bolder'
    }, {
      component: 'CheckboxFormComponent',
      id: 'userPreferNewTab',
      name: 'preferNewTab',
      onChange: _.noop,
      skipStore: true,
      label: 'USER.PREFER_NEW_TAB',
      value: preferNewTab,
      extraClassNames: 'width-100percent mb10'
    }, {
      component: 'LabelFormComponent',
      name: 'passwordReset',
      value: '',
      extraClassNames: 'text-bolder'
    }, {
      component: 'ClickableLinkFormComponent',
      name: 'setPassword',
      includeIf: user.isPasswordSettable,
      onChange: _.noop,
      value: 'USER.CHANGE_PASSWORD',
      icon: 'fa-lock',
      linkAction: () => setEditingPassword(true),
      extraClassNames: 'width-100percent'
    }]
  }, passwordChangeForm];

  return (
    <Modal show={true} onHide={closeFn} animation={false} data-testid="editUserProfile">
      <Modal.Header closeButton={true}>
        <h3>{editingPassword ? t('USER.CHANGE_PASSWORD') : t('EDIT_PROFILE')}</h3>
      </Modal.Header>
      <Modal.Body>
        <div data-testid="editUserProfile">
          <SimpleSaveFormBuilder
            formDefinition={formDefinition}
            formSaveProcessing={formSaveInProcess}
            submitFn={editingPassword ? updatePassword : updateUser}
            closeFn={editingPassword ? displayEditUser : closeFn}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const sqEditUserProfile = angularComponent(editUserProfileBindings, EditUserProfile);
