import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '../core/bindings.util';
import { angularComponent } from '../core/react2angular.util';
import { JournalMetadata } from '../annotation/JournalMetadata.atom';
import { DateTimeService } from '@/datetime/dateTime.service';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { TrackService } from '@/track/track.service';
import { AuthorizationService } from '@/services/authorization.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { CancelAndSave } from '@/hybrid/core/CancelAndSave.molecule';
import { EllipsisDropdownMenu } from '@/hybrid/core/EllipsisDropdownMenu.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';

export interface JournalCommentData {
  createdBy: {
    id: string,
    isArchived: Boolean;
    isRedacted: Boolean;
    name: string;
    type: string;
  };
  createdAt: string;
  name: string;
  id: string;
}

const journalCommentBindings = bindingsDefinition({
  comment: prop<JournalCommentData>(),
  isPresentationMode: prop<boolean>(),
  updateComment: prop<(id: string, name: string) => void>(),
  deleteComment: prop<(id: string) => void>(),
  canManage: prop<(commentData: object) => boolean>(),
  sqDateTime: injected<DateTimeService>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqAuthorization: injected<AuthorizationService>(),
  sqTrack: injected<TrackService>()
});

export const JournalComment: SeeqComponent<typeof journalCommentBindings> = (props) => {
  const {
    sqTrack,
    sqDateTime,
    sqWorksheetStore,
    sqAuthorization
  } = useInjectedBindings(journalCommentBindings);

  const {
    comment,
    isPresentationMode,
    updateComment,
    deleteComment,
    canManage
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [mutableName, setMutableName] = useState('');
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const { t } = useTranslation();

  const isCreator = comment => sqAuthorization.hasCurrentUserId(comment, 'createdBy.id');
  const formatTime = (date: number): string => sqDateTime.formatTime(date, sqWorksheetStore.timezone);
  const canEdit = (comment: object): boolean => sqAuthorization.isAdmin() || isCreator(comment);

  const handleSaveComment = (id: string, name: string) => {
    setIsEditing(false);
    sqTrack.doTrack('Topic', 'Comments', 'save');
    updateComment(id, name);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    sqTrack.doTrack('Topic', 'Comments', 'cancel');
  };

  const menuClass = canManage(comment) ? 'sq-text-primary' : 'sq-fairly-dark-gray-always';

  const manageMenuItems = [
    {
      classes: classNames({ cursorNotAllowed: !canEdit(comment) }),
      onClick: () => {
        if (canEdit(comment)) {
          setMutableName(comment.name);
          setIsEditing(true);
          setIsConfirmingDelete(false);
          sqTrack.doTrack('Topic', 'Comments', 'edit');
        }
      },
      icon: <Icon testId="specCommentEditMenuBtn" icon="fa-fw fa-pencil" type="inherit" />,
      label: 'EDIT',
      testid: 'commentEdit'
    },
    {
      classes: classNames({ cursorNotAllowed: !canEdit(comment) }),
      onClick: () => {
        if (canEdit(comment)) {
          setIsConfirmingDelete(true);
          setMutableName('');
          setIsEditing(false);
        }
      },
      icon: <Icon testId="specCommentDeleteBtn" icon="fa-fw fa-remove" type="inherit" />,
      label: 'DELETE',
      testid: 'commentDelete'
    }
  ];

  return (
    <div
      className="flexRowContainer panel panel-annotation panel-default mb10 pl5 pr5"
      data-testid="specJournalComment"
    >
      <div className="panel-heading pb0">
        <div className="flexColumnContainer flexSpaceBetween flexAlignCenter">
          <JournalMetadata
            name={comment.createdBy.name}
            date={formatTime(sqDateTime.parseISODate(comment.createdAt))} />
          {!isPresentationMode &&
          <div className="mt2">
            <EllipsisDropdownMenu itemsList={manageMenuItems} iconExtraClass={menuClass} />
          </div>
          }
        </div>
      </div>
      {!isEditing &&
      <div className="panel-body transitionNone breakLongWords pt0">{comment.name}</div>
      }
      {isConfirmingDelete && !isPresentationMode &&
      <div className="text-center pb10">
        <hr className="mt10 mb10" />
        <Button
          type="button"
          size="sm"
          variant={null}
          bsPrefix="sq-btn"
          id="specConfirmDeleteBtn"
          data-testid="specConfirmDeleteBtn"
          className="btn btn-sm btn-danger"
          onClick={(event) => {
            setMutableName('');
            deleteComment(comment.id);
            event.stopPropagation();
          }}>
          {t('DELETE')}
        </Button>
        <span
          data-testid="specCancelDeleteBtn"
          className="btn btn-sm btn-link"
          onClick={(event) => {
            setIsConfirmingDelete(false);
            setMutableName('');
            event.stopPropagation();
            event.preventDefault();
          }}>
            {t('CANCEL')}
          </span>
      </div>
      }
      {isEditing && !isPresentationMode &&
      <div className="commentNameEdit">
        <Form.Group data-testid="specCommentEditor" controlId="specCommentEditor" className="mb0">
          <Form.Control
            as="textarea"
            rows={3}
            className="form-control textAreaResizeNone"
            value={mutableName}
            onChange={event => setMutableName(event.currentTarget.value)} />
        </Form.Group>
        <div className="text-right">
          <div className="panel-body pt5 pr0 pb5 text-right">
            <CancelAndSave
              submitFn={() => Promise.resolve().then(() => handleSaveComment(comment.id, mutableName))}
              cancelFn={handleCancelEdit}
              values={[]}
              btnDisabled={!mutableName.length}
              cancelClassNames="btn-xs mr5 min-width-50 sq-btn-xs"
              submitClassNames="btn-xs min-width-50 sq-btn-xs"
              cancelBtnTestId="specCancelCommentBtn"
              submitBtnTestId="specSaveCommentBtn" />
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export const sqJournalComment = angularComponent(journalCommentBindings, JournalComment);
