/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class SubscriptionsApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * Pagination is enabled for this query
   * @summary List channels and their subscriptions
   * @param {number} [offset=0] - The pagination offset, the index of the first subscription that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of subscriptions that will be returned in this page of results
   */
  public getChannels(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/subscriptions',
      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.GetChannelsOutputV1>;
  }

  /**
   * 
   * @summary Subscribe to websocket messages published to the specified channel
   * @param {models.SubscriptionInputV1} body - Data to create the subscription
   */
  public subscribe(
    body: models.SubscriptionInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/subscriptions',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Unsubscribe from the specified channel
   * @param {string} channelId - URI that identifies the channel to delete
   */
  public unsubscribe(
    {
      channelId
    } : {
      channelId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(channelId)) {
      throw new Error("'channelId' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/subscriptions/{channelId}'
        .replace('{channelId}', encodeURIComponent(String(channelId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Update a subscription associated with the specified channel
   * @param {string} channelId - URI that identifies the channel the subscriber is updating
   * @param {models.SubscriptionUpdateInputV1} body - Data to update the subscription
   */
  public updateSubscription(
    body: models.SubscriptionUpdateInputV1,
    {
      channelId,
    } : {
      channelId: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(channelId)) {
      throw new Error("'channelId' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/subscriptions/{channelId}'
        .replace('{channelId}', encodeURIComponent(String(channelId))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

}
