import _ from 'lodash';
import moment from 'moment-timezone';
import { BaseToolStoreService } from '@/investigate/baseToolStore.service';
import { DurationStore } from '@/trendData/duration.store';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { WorkbookStore } from '@/workbook/workbook.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { ITEM_TYPES } from '@/trendData/trendData.module';
import { DISPLAY_MODE } from '@/main/app.constants';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { RedactionService } from '@/services/redaction.service';

export type ExportODataPanelStore = ReturnType<typeof sqExportODataPanelStore>['exports'];

export const GRID_OPTION = {
  AUTOMATIC: 'automatic',
  CUSTOM: 'custom'
};

export function sqExportODataPanelStore(
  sqBaseToolStore: BaseToolStoreService,
  sqDurationStore: DurationStore,
  sqTrendDataHelper: TrendDataHelperService,
  sqWorkbookStore: WorkbookStore,
  sqWorksheetStore: WorksheetStore,
  sqRedaction: RedactionService
) {

  const store = {
    initialize() {
      this.state = this.immutable(_.assign({}, sqBaseToolStore.COMMON_PROPS, {
        name: sqWorkbookStore.name,
        autoupdateTimeRange: false,
        timeRange: {
          start: sqDurationStore.displayRange.start.valueOf(),
          end: sqDurationStore.displayRange.end.valueOf()
        },
        exportTimeZone: sqWorksheetStore.timezone,
        gridOption: GRID_OPTION.AUTOMATIC,
        gridSize: { value: undefined, units: 'min' },
        gridOrigin: moment().tz(sqWorksheetStore.timezone.name).startOf('year').valueOf(),
        gridOriginEnabled: false
      }));
    },

    exports: {
      get autoupdateTimeRange() {
        return this.state.get('autoupdateTimeRange');
      },

      get exportSignals() {
        return this.state.get('exportSignals');
      },

      get exportConditions() {
        return this.state.get('exportConditions');
      },

      get timeRange() {
        const state = this.state;
        const start = state.get('timeRange', 'start');
        const end = state.get('timeRange', 'end');

        return {
          duration: moment.duration(end - start) as moment.duration,
          end: moment.utc(end) as moment,
          start: moment.utc(start) as moment
        };
      },

      get exportTimeZone() {
        return this.state.get('exportTimeZone');
      },

      get gridOption() {
        return this.state.get('gridOption');
      },

      get gridSize() {
        return this.state.get('gridSize');
      },

      get gridOrigin() {
        return moment(this.state.get('gridOrigin')).tz(sqWorksheetStore.timezone.name);
      },

      get gridOriginEnabled() {
        return this.state.get('gridOriginEnabled');
      }
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @return {Object} State for the store
     */
    dehydrate() {
      return this.state.serialize();
    },

    /**
     * Sets the prediction panel state
     *
     * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      INVESTIGATE_SET_DISPLAY_MODE: 'initializeItems',
      EXPORT_ODATA_AUTOUPDATE_TIME_RANGE: 'setAutoupdateTimeRange',
      EXPORT_ODATA_TIME_RANGE_START: 'setTimeRangeStart',
      EXPORT_ODATA_TIME_RANGE_END: 'setTimeRangeEnd',
      EXPORT_ODATA_DURATION: 'setDuration',
      EXPORT_ODATA_EXPORT_TIME_ZONE: 'setExportTimeZone',
      EXPORT_ODATA_GRID_OPTION: 'setGridOption',
      EXPORT_ODATA_GRID_SIZE: 'setGridSize',
      EXPORT_ODATA_GRID_ORIGIN: 'setGridOrigin',
      EXPORT_ODATA_GRID_ORIGIN_ENABLED: 'setGridOriginEnabled'
    },

    /**
     * Initializes the items to their default values. Done here so that this store doesn't dehydrate a bunch of
     * items when it is not in use.
     *
     * @param {Object} payload - Object containing state information
     * @param {String} payload.mode - The display mode being set, one of DISPLAY_MODE
     * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
     */
    initializeItems(payload) {
      this.reset(payload);
      if (payload.mode === DISPLAY_MODE.NEW && payload.type === TREND_TOOLS.EXPORT_ODATA) {
        this.state.set('exportSignals', getExportItems(ITEM_TYPES.SERIES));
        this.state.set('exportConditions', getExportItems(ITEM_TYPES.CAPSULE_SET));
      }

      function getExportItems(itemType) {
        return _.chain(sqTrendDataHelper.getAllItems({ workingSelection: true, itemTypes: [itemType] }))
          .reject(sqRedaction.isItemRedacted)
          .map(item => _.pick(item, sqBaseToolStore.TOOL_ITEM_PROPS))
          .value();
      }
    },

    /**
     * Sets whether autoupdate is enabled for the time range
     *
     * @param {Object} payload - Object container
     * @param {boolean} payload.autoupdateTimeRange - True to enable autoupdate
     */
    setAutoupdateTimeRange(payload) {
      this.state.set('autoupdateTimeRange', payload.autoupdateTimeRange);
    },

    /**
     * Set the time range end value that is used in the form. Ensures that start is not before end.
     *
     * @param {Object} payload - Object container
     * @param {moment} payload.start - The start time
     */
    setTimeRangeStart(payload) {
      const newStart = payload.start.valueOf();
      const end = this.state.get(['timeRange', 'end']);
      this.state.set(['timeRange', 'start'], newStart);

      if (newStart > end) {
        this.state.set(['timeRange', 'end'], newStart);
      }
    },

    /**
     * Set the time range end value that is used in the form. Ensures that end is not after start.
     *
     * @param {Object} payload - Object container
     * @param {moment} payload.end - The start time
     */
    setTimeRangeEnd(payload) {
      const newEnd = payload.end.valueOf();
      const start = this.state.get(['timeRange', 'start']);
      this.state.set(['timeRange', 'end'], newEnd);

      if (newEnd < start) {
        this.state.set(['timeRange', 'start'], newEnd);
      }
    },

    /**
     * Set the time zone that is used in the form.
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.exportTimeZone - The time zone object from Moment.JS
     * @param {String} payload.exportTimeZone.name - The time zone name to be used by the backend
     */
    setExportTimeZone(payload) {
      this.state.set(['exportTimeZone'], payload.exportTimeZone);
    },

    /**
     * Updates the duration of the time range by shifting the start date.
     *
     * @param {Object} payload - Object container
     * @param {moment.duration} payload.duration - The duration
     */
    setDuration(payload) {
      this.state.set(['timeRange', 'start'], this.state.get('timeRange', 'end') - payload.duration.valueOf());
    },

    /**
     * Set the selected option for gridding.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.gridOption - The grid option
     */
    setGridOption(payload) {
      this.state.set('gridOption', payload.gridOption);
    },

    /**
     * If GRID_OPTION.CUSTOM is chosen, the duration between samples to be used for gridding.
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.gridSize - The grid size, value and unit
     */
    setGridSize(payload) {
      this.state.set('gridSize', payload.gridSize);
    },

    /**
     * If GRID_OPTION.CUSTOM is chosen, the timestamp to originate the gridding through.
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.gridOrigin - The origin of the grid, in the form of a Moment
     */
    setGridOrigin(payload) {
      this.state.set('gridOrigin', payload.gridOrigin.valueOf());
    },

    /**
     * The boolean to indicate whether the gridOrigin will be used.
     *
     * @param {Object} payload - Object container
     * @param {boolean} payload.gridOriginEnabled - True if the gridOrigin should be used
     */
    setGridOriginEnabled(payload) {
      this.state.set('gridOriginEnabled', payload.gridOriginEnabled);
    }
  };

  return sqBaseToolStore.extend(store, TREND_TOOLS.EXPORT_ODATA, {
    exportSignals: {
      predicate: ['name', 'a'],
      multiple: true
    },
    exportConditions: {
      predicate: ['name', 'a'],
      multiple: true
    }
  });
}
