import _ from 'lodash';
import angular from 'angular';
import { PERSISTENCE_LEVEL } from '@/services/stateSynchronizer.service';

angular.module('Sq.Administration').store('sqPluginsAdminStore', sqPluginsAdminStore);

export type PluginsAdminStore = ReturnType<typeof sqPluginsAdminStore>['exports'];

export function sqPluginsAdminStore() {
  return {
    persistenceLevel: PERSISTENCE_LEVEL.NONE,

    initialize() {
      this.state = this.immutable({
        plugins: [],
        tableLoading: false,

        // state for for ServerSideFilteringAndPaginationTable
        searchParams: {},
        sortAscending: true,
        sortProperty: 'name',
        // state for for Pagination.organism
        pluginsTotal: 0,
        pageSize: 15,
        pageNumber: 1
      });
    },

    exports: {
      get plugins() {
        return this.state.get('plugins');
      },

      get isTableLoading() {
        return this.state.get('tableLoading');
      },

      // Interface for ServerSideFilteringAndPaginationTable.tsx

      get searchParams() {
        return this.state.get('searchParams');
      },

      get sortAscending() {
        return this.state.get('sortAscending');
      },

      get sortProperty() {
        return this.state.get('sortProperty');
      },

      // Interface for Pagination.organism.tsx

      getItemTotalForTable() {
        return this.state.get('pluginsTotal');
      },

      getPageSizeByTable() {
        return this.state.get('pageSize');
      },

      getPageNumberForTable() {
        return this.state.get('pageNumber');
      }
    },

    handlers: {
      PLUGINSADMIN_SET_PLUGINS: 'setPlugins',
      PLUGINSADMIN_SET_TABLE_LOADING: 'setTableLoading',
      PLUGINSADMIN_SET_SEARCH_PARAMS: 'setSearchParams',
      PLUGINSADMIN_SET_SORT: 'setSort',
      PLUGINSADMIN_SET_PLUGINS_TOTAL: 'setPluginsTotal',
      PLUGINSADMIN_SET_PAGE_SIZE: 'setPageSize',
      PLUGINSADMIN_SET_PAGE_NUMBER: 'setPageNumber',
      PLUGINSADMIN_REMOVE_PLUGINS: 'removePlugins'
    },

    setPlugins({ plugins }) {
      this.state.set('plugins', plugins);
    },

    setTableLoading({ isLoading }) {
      this.state.set('tableLoading', isLoading);
    },

    setSearchParams({ field, value }) {
      const searchParams = this.state.get('searchParams');

      if (value === '') {
        this.state.set('searchParams', _.omit(searchParams, field));
      } else {
        this.state.set('searchParams', _.assign({}, searchParams, { [field]: value }));
      }
    },

    setSort({ field, sortAscending }) {
      this.state.merge({ sortProperty: field, sortAscending });
    },

    setPluginsTotal({ count }) {
      this.state.set('pluginsTotal', count);
    },

    setPageSize({ size }) {
      this.state.set('pageSize', size);
    },

    setPageNumber({ pageNumber }) {
      this.state.set('pageNumber', pageNumber);
    },

    /**
     * Removes plugins from the array of plugins
     *
     * @param {String[]} ids - The ids of the plugins to be removed
     */
    removePlugins(ids) {
      _.forEach(ids, (id) => {
        const index = _.findIndex(this.state.get('plugins'), ['id', id]);

        if (index > -1) {
          this.state.splice('plugins', [index, 1]);
        }
      });
    }
  };
}

