import angular from 'angular';
// These are external JS libraries that are imported for the side-effect of loading them. This is the webpack
// equivalent of adding a <script> tag
import 'angular-bind-html-compile-ci-dev';
import 'angular-content-editable';
import 'other_components/angular-highlight.js';
import 'angular-loading-bar';
import 'angular-file-upload';
import 'angular-resizable';
import 'ng-sortable';
import 'easy-pie-chart/dist/easypiechart.js';
import 'easy-pie-chart/dist/angular.easypiechart.js';
import 'angular-fcsa-number';
import 'flux-angular';
import 'ng-focus-if';
import 'angular-keyboard';
import 'jquery-minicolors/jquery.minicolors.js';
import 'angular-animate';
import 'angular-cookies';
import 'angular-password';
import 'angular-sanitize';
import 'ngclipboard';
import 'clipboard';
import 'angular-translate';
import 'angular-translate-interpolation-messageformat';
import 'angular-translate-loader-static-files';
import 'angular-ui-notification/dist/angular-ui-notification.js';
import 'angular-ui-bootstrap';
import 'codemirror/lib/codemirror.js';
import 'codemirror/addon/display/placeholder.js';
import 'codemirror/addon/edit/matchbrackets.js';
import 'codemirror/addon/lint/lint.js';
import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/mode/clike/clike.js';
import 'codemirror/mode/css/css.js';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/php/php.js';
import 'codemirror/mode/xml/xml.js';
import 'angular-ui-grid';
import 'angular-ui-router';
// These are some other oddball modules that we need to make sure get loaded for their side effects
import 'moment';
import 'moment-duration-format';
import 'messageformat';
import 'other_components/jquery-ui-custom.js';
import 'other_components/jquery.insert-at-caret.js';
import 'other_components/piwik/piwik.js';

// These are all angular modules that are created by some of the above libraries
const dependencies = [
  'angular-bind-html-compile',
  'angular-content-editable',
  'angular-highlight',
  'angular-loading-bar',
  'angularFileUpload',
  'as.sortable',
  'angularResizable',
  'easypiechart',
  'fcsa-number',
  'flux',
  'focus-if',
  'keyboard',
  'ngAnimate',
  'ngCookies',
  'ngPassword',
  'ngSanitize',
  'ngclipboard',
  'pascalprecht.translate',
  'ui-notification',
  'ui.bootstrap',
  'ui.grid',
  'ui.grid.autoResize',
  'ui.grid.cellNav',
  'ui.grid.expandable',
  'ui.grid.pagination',
  'ui.grid.resizeColumns',
  'ui.grid.selection',
  'ui.router'
];

angular.module('Sq.Vendor', dependencies);

