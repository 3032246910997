import angular from 'angular';
import template from './trendViewer.directive.html';

angular.module('Sq.TrendViewer').directive('sqTrendViewer', sqTrendViewer);

function sqTrendViewer() {
  return {
    restrict: 'E',
    controller: 'TrendViewerCtrl',
    controllerAs: 'ctrl',
    scope: {},
    template
  };
}
