import bind from 'class-autobind-decorator';

@bind
export class AdministrationUtilities {
  constructor() {}

  getFriendlySystemSpec(systemSpecValue) {
    // Beginning with "Total=24 GiB"...
    // "Total=24 GB": Possibly swap "XiB" with "XB"
    const specValueWithoutIB = systemSpecValue.replace('iB', 'B');
    // "24 GB Total": remove equal symbol, swap sides
    const equalIndex = specValueWithoutIB.indexOf('=');
    if (equalIndex > 0) {
      const context = specValueWithoutIB.substring(0, equalIndex);
      const size = specValueWithoutIB.substring(equalIndex + 1, specValueWithoutIB.length);
      return `${size} ${context}`;
    } else {
      return specValueWithoutIB;
    }
  }

  getComponentIconProps(component) {
    let icon, type;
    if (component.error) {
      icon = 'fa-exclamation-triangle';
      type = 'danger';
    } else if (component.warning) {
      icon = 'fa-exclamation-circle';
      type = 'warning';
    } else {
      icon = 'fa-check-square';
      type = 'success';
    }

    return { icon, type, testId: `${component.componentName}-icon` };
  }

}
