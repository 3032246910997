import angular from 'angular';

const dependencies = [
  'Sq.Core',
  'Sq.AppConstants',
  'Sq.Services.Authorization',
  'Sq.TrendData',
  'Sq.Worksheet'
];

/**
 * @module The Annotation module provides the annotations directive
 */
angular.module('Sq.Annotation', dependencies);

// The ID that is assigned to a new journal entry or annotation before it has been created on the backend and
// assigned a real guid. The string is arbitrary. We just need something that is non-empty to set as the ID so
// that the editor ng-if will be satisfied and the editor will display.
export const ID_PLACEHOLDER = 'id-placeholder';
export const LINK_TYPE = {
  WORKSTEP: 'workstep',
  SIGNAL: 'signal',
  CONDITION: 'condition',
  RANGE: 'range',
  TABLE: 'table',
  METRIC: 'metric',
  CAPSULE: 'capsule'
};
export const ANNOTATION_TYPE = {
  REPORT: 'Report',
  JOURNAL: 'Journal'
};
