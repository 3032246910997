/**
 * Cancel and Execute buttons (used in tool panel)
 *
 * @param toolId (string): the id of the tool currently open
 * @param cancelAction (function): function to call when clicking the Cancel button (takes no parameters)
 * @param submitAction (function): function to call when clicking the Execute button (takes no parameters)
 * @param btnDisabled (boolean): true if the Execute button should be disabled
 * @param (optional) toolName (string): the name of the tool currently open (used for sqTrack only)
 * @param (optional) hideCancel (boolean): true to hide the cancel button
 * @param (optional) submitBtnLabel (string): the label for the button (Execute is used if nothing is specified)
 * @param (optional) submitBtnId (string): an id for the Execute button
 * @param (optional) extraFormatting (string): extra class names to be placed on the div around the buttons
 */
import React, { useState } from 'react';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { TrackService } from '@/track/track.service';
import { InvestigateStore } from '@/investigate/investigate.store';
import { TextButton } from '@/hybrid/core/TextButton.atom';

const cancelAndExecuteBindings = bindingsDefinition({
  toolId: prop<string>(),
  cancelAction: prop<() => any>(),
  submitAction: prop<() => any>(),
  btnDisabled: prop<boolean>(),
  toolName: prop.optional<string>(),
  hideCancel: prop.optional<boolean>(),
  cancelBtnLabel: prop.optional<string>(),
  submitBtnLabel: prop.optional<string>(),
  submitBtnId: prop.optional<string>(),
  extraFormatting: prop.optional<string>(),
  sqTrack: injected<TrackService>(),
  sqInvestigateStore: injected<InvestigateStore>()
});

export const CancelAndExecute: SeeqComponent<typeof cancelAndExecuteBindings> = (props) => {
  const {
    toolName,
    toolId,
    cancelAction,
    submitAction,
    hideCancel,
    cancelBtnLabel = 'CANCEL',
    submitBtnLabel = 'EXECUTE',
    btnDisabled,
    submitBtnId,
    extraFormatting
  } = props;
  const { sqTrack, sqInvestigateStore } = useInjectedBindings(cancelAndExecuteBindings);
  const [inProgress, setInProgress] = useState(false);

  const trackAndCancel = () => {
    sqTrack.doTrack('Workbench_Tool', toolName, 'cancelled', true);
    cancelAction();
  };

  const trackAndSubmit = () => {
    setInProgress(true);
    sqTrack.doTrack('Workbench_Tool', toolName, 'executed', true);
    submitAction()
      .then(() => setInProgress(sqInvestigateStore.activeTool !== toolId))
      .finally(() => setInProgress(false));
  };

  return (
    <div className={classNames('flexRowContainer', 'mt5', extraFormatting)}>
      <div className="text-center">
        {!hideCancel &&
        <TextButton
          onClick={trackAndCancel}
          label={cancelBtnLabel}
          size="sm"
          testId="cancelButton"
          extraClassNames="mr20 min-width 100 width-100 max-width-100" />}

        <TextButton
          onClick={trackAndSubmit}
          label={submitBtnLabel}
          disabled={inProgress || btnDisabled}
          size="sm"
          testId="executeButton"
          extraClassNames="min-width 100 width-100 max-width-100"
          id={submitBtnId}
          variant="theme" />
      </div>
    </div>
  );
};

export default CancelAndExecute;
export const sqCancelAndExecute = angularComponent(cancelAndExecuteBindings, CancelAndExecute);
