import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { Capsule } from '../../../../plugin/sdk/seeq';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { ItemPropertiesSelector } from '@/hybrid/utilities/ItemPropertiesSelector.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { SuggestedPropertiesMode } from '@/hybrid/utilities/CustomPropertySelector.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';

const itemPropertiesSelectorButtonBindings = bindingsDefinition({
  suggestedPropertiesMode: prop.optional<SuggestedPropertiesMode>(),
  conditions: prop.optional<Capsule[]>(),
  extraColumns: prop.optional<any[]>(),
  propertyColumns: prop<any>(),
  statColumns: prop.optional<any[]>(),
  showPropertyColumns: prop.optional<boolean>(),
  isColumnEnabled: prop<(columnKey: any) => boolean>(),
  toggleColumn: prop<(column: any) => void>(),
  addPropertyColumn: prop<(column: any) => void>(),
  iconClasses: prop.optional<string>()
});

export const ItemPropertiesSelectorButton: SeeqComponent<typeof itemPropertiesSelectorButtonBindings> = (props) => {
  const {
    suggestedPropertiesMode,
    conditions,
    extraColumns,
    propertyColumns,
    statColumns,
    showPropertyColumns,
    isColumnEnabled,
    toggleColumn,
    addPropertyColumn,
    iconClasses
  } = props;

  const { t } = useTranslation();
  const config = {
    // allow the popover to be displayed above details pane
    modifiers: {
      preventOverflow: {
        enabled: false
      }
    }
  };

  return (
    <OverlayTrigger
      trigger="click"
      rootClose={true}
      placement="top-end"
      transition={false}
      popperConfig={config as any}
      overlay={
        // marginRight because the popover is not completely shown when the button is placed near to the right
        // border
        // Re-test without marginRight when Bootstrap 3 is removed.
        <Popover id="item-properties-selector-popover"
          style={{ maxWidth: '400px', marginRight: '40px' }}>
          <Popover.Content>
            <ItemPropertiesSelector
              suggestedPropertiesMode={suggestedPropertiesMode}
              conditions={conditions}
              extraColumns={extraColumns}
              propertyColumns={propertyColumns}
              statColumns={statColumns}
              showPropertyColumns={showPropertyColumns}
              isColumnEnabled={isColumnEnabled}
              toggleColumn={toggleColumn}
              addPropertyColumn={addPropertyColumn}
            />
          </Popover.Content>
        </Popover>
      }>
      <div>
        <HoverTooltip delay={500} text={t('CAPSULES_PANEL.ADD_CUSTOM_COLUMN')} placement='top'>
          <div>
            <Icon
              extraClassNames={iconClasses}
              icon="fc fc-add-column"
              type="inherit"
              testId="itemPropertiesSelectorButton" />
          </div>
        </HoverTooltip>
      </div>
    </OverlayTrigger>

  );
};

export const sqItemPropertiesSelectorButton = angularComponent(itemPropertiesSelectorButtonBindings,
  ItemPropertiesSelectorButton);
