import angular from 'angular';
import _ from 'lodash';
import { CalculationRunnerService } from '@/services/calculationRunner.service';
import { ValueSearchStore } from '@/trendData/valueSearch.store';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { COMPARISON_OPERATORS_SYMBOLS } from '@/investigate/investigate.module';
import { UtilitiesService } from '@/services/utilities.service';

angular.module('Sq.TrendData').service('sqValueSearchActions', sqValueSearchActions);
export type ValueSearchActions = ReturnType<typeof sqValueSearchActions>;

function sqValueSearchActions(
  flux: ng.IFluxService,
  sqCalculationRunner: CalculationRunnerService,
  sqToolRunner: ToolRunnerService,
  sqUtilities: UtilitiesService,
  sqValueSearchStore: ValueSearchStore) {
  const service = {
    executeSimpleSearch,
    executeAdvancedSearch,
    setSearchType,
    toggleCleansing,
    toggleValidValues,
    setSimpleOperator,
    setSimpleValue,
    setSimpleUpperValueInclusivity,
    setSimpleLowerValue,
    setSimpleLowerValueInclusivity,
    setMinDuration,
    setMergeDuration,
    setAdvancedEntryOperator,
    setAdvancedEntryValue,
    setAdvancedEntryLowerValue,
    setAdvancedEntryDuration,
    setAdvancedExitOperator,
    setAdvancedExitValue,
    setAdvancedExitDuration,
    setIsMigratedDeviationSearch,
    sameBetweenValues,
    isOperatorBetween,
    isSimpleLowerBoundGreater,
  };

  return service;

  function executeAdvancedSearch(color?) {
    const isBetween = isOperatorBetween(sqValueSearchStore.advancedEntryOperator);
    const { parameters, formula } = sqCalculationRunner.advancedValueSearch({
      inputSignal: sqValueSearchStore.inputSignal,
      useValidValues: sqValueSearchStore.useValidValues,
      entry: {
        operator: sqValueSearchStore.advancedEntryOperator,
        value: sqValueSearchStore.advancedEntryValue,
        lowerValue: sqValueSearchStore.advancedEntryLowerValue,
        duration: sqValueSearchStore.advancedEntryDuration
      },
      exit: {
        operator: sqValueSearchStore.advancedExitOperator,
        // Use the upper value, lower value and duration from the entry criteria if the operator is between
        value: isBetween ? sqValueSearchStore.advancedEntryValue : sqValueSearchStore.advancedExitValue,
        lowerValue: sqValueSearchStore.advancedEntryLowerValue,
        duration: isBetween ? sqValueSearchStore.advancedEntryDuration : sqValueSearchStore.advancedExitDuration
      },
      maximumDuration: sqValueSearchStore.maximumDuration
    });

    return sqToolRunner.panelExecuteCondition(
      sqValueSearchStore.name,
      formula,
      parameters,
      sqValueSearchStore.configParams,
      sqValueSearchStore.id,
      color,
      { notifyOnError: false }
    );
  }

  function executeSimpleSearch(color?) {
    return sqCalculationRunner.simpleValueSearch({
      inputSignal: sqValueSearchStore.inputSignal,
      useValidValues: sqValueSearchStore.useValidValues,
      operator: sqValueSearchStore.simpleOperator,
      value: sqValueSearchStore.simpleValue,
      upperInclusivity: sqValueSearchStore.simpleUpperValueInclusivity,
      lowerValue: sqValueSearchStore.simpleLowerValue,
      lowerInclusivity: sqValueSearchStore.simpleLowerValueInclusivity,
      isCleansing: sqValueSearchStore.isCleansing,
      minDuration: sqValueSearchStore.minDuration,
      mergeDuration: sqValueSearchStore.mergeDuration
    }).then(({ formula, parameters }) => {
      return sqToolRunner.panelExecuteCondition(
        sqValueSearchStore.name,
        formula,
        parameters,
        sqValueSearchStore.configParams,
        sqValueSearchStore.id,
        color,
        { notifyOnError: false }
      );
    });
  }

  function setSimpleOperator(simpleOperator) {
    flux.dispatch('VALUE_SEARCH_SET_SIMPLE_OPERATOR', { simpleOperator });

    if (simpleOperator === COMPARISON_OPERATORS_SYMBOLS.IS_BETWEEN) {
      service.setSimpleUpperValueInclusivity(COMPARISON_OPERATORS_SYMBOLS.IS_LESS_THAN_OR_EQUAL_TO);
      service.setSimpleLowerValueInclusivity(COMPARISON_OPERATORS_SYMBOLS.IS_GREATER_THAN_OR_EQUAL_TO);
    }

    if (simpleOperator === COMPARISON_OPERATORS_SYMBOLS.IS_NOT_BETWEEN) {
      service.setSimpleUpperValueInclusivity(COMPARISON_OPERATORS_SYMBOLS.IS_GREATER_THAN);
      service.setSimpleLowerValueInclusivity(COMPARISON_OPERATORS_SYMBOLS.IS_LESS_THAN);
    }
  }

  function setSimpleValue(simpleValue) {
    flux.dispatch('VALUE_SEARCH_SET_SIMPLE_VALUE', { simpleValue });
  }

  function setSimpleUpperValueInclusivity(simpleUpperValueInclusivity) {
    flux.dispatch('VALUE_SEARCH_SET_SIMPLE_UPPER_VALUE_INCLUSIVITY', { simpleUpperValueInclusivity });
  }

  function setSimpleLowerValue(simpleLowerValue) {
    flux.dispatch('VALUE_SEARCH_SET_SIMPLE_LOWER_VALUE', { simpleLowerValue });
  }

  function setSimpleLowerValueInclusivity(simpleLowerValueInclusivity) {
    flux.dispatch('VALUE_SEARCH_SET_SIMPLE_LOWER_VALUE_INCLUSIVITY', { simpleLowerValueInclusivity });
  }

  function setMinDuration(minDuration) {
    flux.dispatch('VALUE_SEARCH_SET_MIN_DURATION', { minDuration });
  }

  function setMergeDuration(mergeDuration) {
    flux.dispatch('VALUE_SEARCH_SET_MERGE_DURATION', { mergeDuration });
  }

  function setAdvancedEntryOperator(advancedEntryOperator) {
    flux.dispatch('VALUE_SEARCH_SET_ADVANCED_ENTRY_OPERATOR', { advancedEntryOperator });
  }

  function setAdvancedEntryValue(advancedEntryValue) {
    flux.dispatch('VALUE_SEARCH_SET_ADVANCED_ENTRY_VALUE', { advancedEntryValue });
  }

  function setAdvancedEntryLowerValue(advancedEntryLowerValue) {
    flux.dispatch('VALUE_SEARCH_SET_ADVANCED_ENTRY_LOWER_VALUE', { advancedEntryLowerValue });
  }

  function setAdvancedEntryDuration(advancedEntryDuration) {
    flux.dispatch('VALUE_SEARCH_SET_ADVANCED_ENTRY_DURATION', { advancedEntryDuration });
  }

  function setAdvancedExitOperator(advancedExitOperator) {
    flux.dispatch('VALUE_SEARCH_SET_ADVANCED_EXIT_OPERATOR', { advancedExitOperator });
  }

  function setAdvancedExitValue(advancedExitValue) {
    flux.dispatch('VALUE_SEARCH_SET_ADVANCED_EXIT_VALUE', { advancedExitValue });
  }

  function setAdvancedExitDuration(advancedExitDuration) {
    flux.dispatch('VALUE_SEARCH_SET_ADVANCED_EXIT_DURATION', { advancedExitDuration });
  }

  function toggleCleansing() {
    flux.dispatch('VALUE_SEARCH_TOGGLE_CLEANSING');
  }

  function toggleValidValues() {
    flux.dispatch('VALUE_SEARCH_TOGGLE_VALID_VALUES');
  }

  function setSearchType(isSimple) {
    flux.dispatch('VALUE_SEARCH_SET_SEARCH_TYPE', { isSimple });
  }

  function setIsMigratedDeviationSearch(isMigratedDeviationSearch) {
    flux.dispatch('VALUE_SEARCH_SET_IS_MIGRATED_DEVIATION_SEARCH', { isMigratedDeviationSearch });
  }

  function isOperatorBetween(operator) {
    return _.includes([COMPARISON_OPERATORS_SYMBOLS.IS_BETWEEN, COMPARISON_OPERATORS_SYMBOLS.IS_NOT_BETWEEN], operator);
  }

  function sameBetweenValues(isSimple) {
    let upperValue, lowerValue, operator;

    if (isSimple) {
      upperValue = sqValueSearchStore.simpleValue;
      lowerValue = sqValueSearchStore.simpleLowerValue;
      operator = sqValueSearchStore.simpleOperator;
    } else {
      upperValue = sqValueSearchStore.advancedEntryValue;
      lowerValue = sqValueSearchStore.advancedEntryLowerValue;
      operator = sqValueSearchStore.advancedEntryOperator;
    }

    return isOperatorBetween(operator)
      && !!upperValue
      && !!lowerValue
      && upperValue === lowerValue;
  }

  function isSimpleLowerBoundGreater(isSimple) {
    if (!isSimple || !service.isOperatorBetween(sqValueSearchStore.simpleOperator)) return false;

    const upperValue = sqValueSearchStore.simpleValue;
    const lowerValue = sqValueSearchStore.simpleLowerValue;

    if (sqUtilities.validateGuid(upperValue) || sqUtilities.validateGuid(lowerValue)) {
      return false;
    }

    return !!upperValue && !!lowerValue && _.toNumber(upperValue) < _.toNumber(lowerValue);
  }
}
