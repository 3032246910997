import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { HoverTooltip } from './HoverTooltip.atom';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';

const unitOfMeasureBindings = bindingsDefinition({
  unitOfMeasure: prop<{ value: any, isRecognized: boolean }>(),
  isStandalone: prop.optional<boolean>()
});

/**
 * Renders the unit of measure if it is available. If the unit is rendered "standalone" no wrapping parenthesis
 * are included. If an unknown unit of measure is found it is displayed in italics and slightly transparent,
 * opaque on hover.
 */
export const UnitOfMeasure: SeeqComponent<typeof unitOfMeasureBindings> = (props) => {
  const { unitOfMeasure, isStandalone } = props;
  if (_.isEmpty(unitOfMeasure) || !unitOfMeasure.value) {
    return null;
  }

  const style = classNames('inline',
    {
      'text-italic': !unitOfMeasure.isRecognized,
      'text-opaque-onhover': !unitOfMeasure.isRecognized
    });

  return (
    <HoverTooltip text={unitOfMeasure.isRecognized ? null : 'UNRECOGNIZED_UNIT'} placement="top">
        <span className={style} data-testid="unitOfMeasure">
          {!isStandalone && '('}{unitOfMeasure.value}{!isStandalone && ')'}
        </span>
    </HoverTooltip>
  );
};

export default UnitOfMeasure;
export const sqUnitOfMeasure = angularComponent(unitOfMeasureBindings, UnitOfMeasure);
