import React from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { Modal } from 'react-bootstrap';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { TrackService } from '@/track/track.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';

const closeWhileSavingModalBindings = bindingsDefinition({
  onClose: prop<() => void>(),
  sqTrack: injected<TrackService>()
});

export const ClosedWhileSavingModal: SeeqComponent<typeof closeWhileSavingModalBindings> = (props) => {
  const { onClose } = props;
  const { sqTrack } = useInjectedBindings(closeWhileSavingModalBindings);
  const { t } = useTranslation();

  return <Modal animation={false} backdrop="static" show={true}>
    <Modal.Header>
      <Modal.Title>
        {t('ITEM_ACL.CLOSE_DURING_SAVE.HEADER')}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {t('ITEM_ACL.CLOSE_DURING_SAVE.MESSAGE')}
    </Modal.Body>
    <Modal.Footer>
      <TextButton
        label="ITEM_ACL.CLOSE_DURING_SAVE.CLOSE"
        variant="theme"
        onClick={() => {
          sqTrack.doTrack('Access Control', 'close during save', 'close');
          onClose();
        }}
      />
    </Modal.Footer>
  </Modal>;
};
