import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { LegendWrapper } from '@/hybrid/trend/LegendWrapper.atom';

const conditionGradientLegendItemsBindings = bindingsDefinition({
  capsules: prop<any[]>(),
  sqTrendCapsuleSetStore: injected<TrendCapsuleSetStore>()
});

export const ConditionGradientLegendItems: SeeqComponent<typeof conditionGradientLegendItemsBindings> = (props) => {
  const { capsules } = props;
  const { sqTrendCapsuleSetStore } = useInjectedBindings(conditionGradientLegendItemsBindings);

  return (
    <LegendWrapper>
      {_.chain(capsules)
        .uniqBy('isChildOf')
        .map(capsule => sqTrendCapsuleSetStore.findItem(capsule.isChildOf))
        .compact()
        .uniqBy('id')
        .map(condition => (
          <div className="mr10 text-nowrap" key={condition.id}>
            <i className="pr5 fa fa-circle" style={{ color: condition.color }}/>
            {condition.name}
          </div>
        ))
        .value()}
    </LegendWrapper>
  );
};
