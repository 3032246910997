import _ from 'lodash';
import React from 'react';
import { TableBuilderTextInput } from '@/hybrid/tableBuilder/tableComponents/TableBuilderTextInput.atom';

export interface TextCellIF {
  itemName?: string;
  itemUom?: string | undefined;
  textValue: string;
  columnIndex: number;
  onTextChange?: (value: string) => void;
  style: { [k: string]: string };
  canEditCellText?: boolean;
}

export const TableBuilderTextCell: React.FunctionComponent<TextCellIF> = (props) => {

  const {
    columnIndex,
    textValue,
    onTextChange = _.noop,
    style,
    canEditCellText
  } = props;

  return <td
    className="forceVerticalAlignMiddle max-width-200"
    data-testid="tableBuilderTextCell"
    style={style}>
    {canEditCellText &&
    <div className="tableBuilderTextInput ml5 mr5" data-testid="textInput">
      <TableBuilderTextInput
        value={textValue}
        onChange={value => onTextChange(value)}
        tabIndex={columnIndex + 1}
        style={style}
      />
    </div>}
    {!canEditCellText
    && <>{textValue}</>}
  </td>;
};
