import _ from 'lodash';
import moment from 'moment-timezone';
import bind from 'class-autobind-decorator';
import { CapsuleGroupCapsule } from '@/investigate/customCondition/customCondition.module';
import { ConditionFormulaService } from '@/investigate/customCondition/conditionFormula.service';
import { CapsuleGroupActions } from '@/investigate/customCondition/capsuleGroup.actions';
import { UtilitiesService } from '@/services/utilities.service';
import { CapsuleGroupStore } from '@/investigate/customCondition/capsuleGroup.store';
import { Capsule } from '@/datetime/datetime.module';
import { ConditionsApi } from '@/sdk';

@bind
export class CustomConditionActions {
  constructor(
    private $q: ng.IQService,
    private sqConditionFormula: ConditionFormulaService,
    private sqUtilities: UtilitiesService,
    private sqCapsuleGroupStore: CapsuleGroupStore,
    private sqCapsuleGroupActions: CapsuleGroupActions,
    private sqConditionsApi: ConditionsApi) {
  }

  /**
   * Requests capsule properties for the capsules in the custom condition store that have a propertiesSource
   * property. A call is made for each capsule set that encompases the range of all the capsules. The returned
   * capsules are then matched and the properties are copied
   *
   * @return {Promise} a promise that resolves with the formula
   */
  fetchPropertiesAndBuildFormula() {
    const groupCapsules = this.sqCapsuleGroupStore.capsules as CapsuleGroupCapsule[];
    return this.$q.resolve()
      .then(() => _.chain(groupCapsules)
        .filter(c => !_.isNil(c.propertiesSource))
        .groupBy(c => c.propertiesSource.capsuleSetId)
        .mapValues((capsules: CapsuleGroupCapsule[]) => ({
          capsules,
          startTime: _.minBy(capsules, c => c.propertiesSource.startTime).propertiesSource.startTime,
          endTime: _.maxBy(capsules, c => c.propertiesSource.endTime).propertiesSource.endTime
        }))
        .map(({ capsules, startTime, endTime }, capsuleSetId) =>
          (this.sqConditionsApi.getCapsules({
            id: capsuleSetId,
            start: moment(startTime).toISOString(),
            end: moment(endTime).toISOString()
          }))
            .catch(error => this.$q.reject(this.sqUtilities.formatApiError(error)))
            .then(
              ({ data }) => _.map(capsules as CapsuleGroupCapsule[],
                ({ id, startTime, endTime, propertiesSource }) => {
                  const properties = _.get(_.find(data.capsules as any[],
                    responseCapsule => responseCapsule.id === propertiesSource.capsuleId), 'properties', []);
                  return ({ id, startTime, endTime, properties }) as Capsule;
                }))
        )
        .thru(p => this.$q.all(p))
        .value()
      )
      .then(r => _.chain(r)
        .flatten()
        .concat(_.filter(groupCapsules, c => _.isNil(c.propertiesSource)) as Capsule[])
        .value()
      )
      .then(c => _.tap(c, this.sqCapsuleGroupActions.setCapsules))
      .then(c => this.sqConditionFormula.removeInvalidCapsules(c))
      .then(c => this.sqConditionFormula.makeCapsulesUnique(c))
      .then(c => this.sqConditionFormula.conditionFormula(c));
  }
}
