import _ from 'lodash';
import angular from 'angular';
import moment from 'moment-timezone';
import template from './chart.directive.html';
import tinycolor from 'tinycolor2';
import Highcharts from 'other_components/highcharts';
import { DomClassListService } from '@/services/domClasslist.service';
import { DurationActions } from '@/trendData/duration.actions';
import { UtilitiesService } from '@/services/utilities.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import { TrendActions } from '@/trendData/trend.actions';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { AnnotationActions } from '@/annotation/annotation.actions';
import { AnnotationStore } from '@/annotation/annotation.store';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { CapsuleTimeColorMode, TrendStore } from '@/trendData/trend.store';
import { CursorStore } from '@/trendData/cursor.store';
import { CursorsService } from '@/trendViewer/cursors.service';
import { DurationStore } from '@/trendData/duration.store';
import { LabelsService } from '@/trendViewer/label.service';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { ChartSelectionService } from '@/services/chartSelection.service';
import { ItemDecoratorService } from '@/trendViewer/itemDecorator.service';
import { AxisControlService, AxisExtremeChange } from '@/services/axisControl.service';
import {
  ALPHA_CAPSULE_UNSELECTED,
  ALPHA_UNSELECTED,
  AUTO_UPDATE,
  DASH_STYLES,
  ITEM_CHILDREN_TYPES,
  ITEM_TYPES,
  LABEL_LOCATIONS,
  PREVIEW_HIGHLIGHT_COLOR,
  PREVIEW_ID,
  SAMPLE_OPTIONS,
  SHADED_AREA_DIRECTION,
  TREND_STORES,
  TREND_TOP_Y_AXIS_ID,
  TREND_VIEWS
} from '@/trendData/trendData.module';
import { DEFAULT_AXIS_LABEL_COLOR, LANE_LABEL_CONFIG, PLOT_BAND, Z_INDEX } from '@/trendViewer/trendViewer.module';
import { DEBOUNCE } from '@/main/app.constants';
import { ChartRegionService } from '@/services/chartRegion.service';

angular.module('Sq.TrendViewer')
  .directive('sqChart', sqChart);

function sqChart(
  $interval: ng.IIntervalService,
  $sanitize: ng.sanitize.ISanitizeService,
  $translate: ng.translate.ITranslateService,
  sqDomClassList: DomClassListService,
  sqDurationActions: DurationActions,
  sqUtilities: UtilitiesService,
  sqDateTime: DateTimeService,
  sqTrendActions: TrendActions,
  sqInvestigateActions: InvestigateActions,
  sqAnnotationActions: AnnotationActions,
  sqAnnotationStore: AnnotationStore,
  sqTrendCapsuleStore: TrendCapsuleStore,
  sqTrendSeriesStore: TrendSeriesStore,
  sqTrendStore: TrendStore,
  sqCursors: CursorsService,
  sqCursorStore: CursorStore,
  sqDurationStore: DurationStore,
  sqLabels: LabelsService,
  sqTrendDataHelper: TrendDataHelperService,
  sqChartSelection: ChartSelectionService,
  sqChartRegion: ChartRegionService,
  sqItemDecorator: ItemDecoratorService,
  sqAxisControl: AxisControlService
) {
  return {
    restrict: 'E',
    template,
    scope: {
      items: '=',
      view: '=',
      isCapsuleTime: '=',
      isPickingMode: '=',
      capsuleAlignment: '=',
      selectedRegion: '=',
      capsuleTimeOffsets: '=',
      trendStart: '=',
      trendEnd: '=',
      selectedTimezone: '<',
      isDimmed: '<',
      selectItems: '&',
      breaks: '<',
      setYExtremes: '&',
      setPointerValues: '&',
      clearPointerValues: '&',
      setSelectedRegion: '&',
      removeSelectedRegion: '&',
      pickSelectedRegion: '&',
      annotationIcon: '&'
    },
    link: linker
  };

  function linker($scope, $element) {
    const FIELDS_TO_DIFF = [
      'id',
      'autoDisabled',
      'selected',
      'zones',
      'data',
      'yAxisConfig',
      'yAlignment',
      'stringEnum',
      'duration',
      'capsuleSegmentData',
      'color',
      'lineWidth',
      'dashStyle',
      'visible',
      'sampleDisplayOption',
      'shadedAreaLower',
      'shadedAreaUpper',
      'rightAxis',
      'axisVisibility',
      'axisAlign',
      'lane',
      'yAxisType'
    ];

    const ALPHA_DIMMED = 0.3;

    const Z_INDEX_BOOST = 1;
    Z_INDEX[ITEM_TYPES.SERIES] = 1;
    Z_INDEX[ITEM_TYPES.CAPSULE] = 3 + Z_INDEX_BOOST;
    Z_INDEX[ITEM_TYPES.UNCERTAIN_BOUNDED_CAPSULE] = 4 + Z_INDEX_BOOST;
    Z_INDEX[ITEM_TYPES.UNCERTAIN_UNBOUNDED_CAPSULE] = 4 + Z_INDEX_BOOST;

    const LINE_WIDTHS = {};
    LINE_WIDTHS[ITEM_TYPES.SERIES] = sqUtilities.headlessRenderMode() ? 2 : 1;
    LINE_WIDTHS[ITEM_TYPES.SCALAR] = sqUtilities.headlessRenderMode() ? 2 : 1;

    const SHADOW_AGGREGATE = {
      width: 4,
      opacity: 1,
      offsetX: 0,
      offsetY: 0
    };

    const barChartEssentials = {
      type: 'column',
      pointPlacement: 'on',
      minPointLength: 2
    };

    let editingId;
    const Y_AXIS_MIN_TOTAL_WIDTH = 30;

    const GRAY_LANE_COLOR = '#f9f9f9';
    const WHITE_LANE_COLOR = '#ffffff';

    const CAPSULE_TYPES = [ITEM_TYPES.CAPSULE, ITEM_TYPES.UNCERTAIN_BOUNDED_CAPSULE,
      ITEM_TYPES.UNCERTAIN_UNBOUNDED_CAPSULE];

    // Used by scroll and zoom operations
    let deactivateScrollZoom = _.noop;
    const axisControl = sqAxisControl.createAxisControl({
      x: {
        getExtremes: () =>
          $scope.isCapsuleTime ?
            {
              min: 0 + $scope.capsuleTimeOffsets.lower,
              max: sqTrendSeriesStore.longestCapsuleSeriesDuration + $scope.capsuleTimeOffsets.upper
            } :
            { min: $scope.trendStart, max: $scope.trendEnd },
        updateExtremes: (newExtremes) => {
          const newExtreme = _.first(newExtremes);
          updateXRange(newExtreme.changeInLow, newExtreme.changeInHigh);
        }
      },
      y: {
        getExtremes: (axis) => {
          const item = getAxisItem(axis);
          return {
            min: Number(item.yAxisMin),
            max: Number(item.yAxisMax),
            axisAlign: item.axisAlign
          };
        },
        updateExtremes: (axisExtremeChanges: AxisExtremeChange[]) => {
          $scope.setYExtremes()(_.map(axisExtremeChanges, changes => ({
            axisAlign: changes.oldExtremes.axisAlign,
            min: changes.oldExtremes.min + changes.changeInLow,
            max: changes.oldExtremes.max + changes.changeInHigh
          })));
        },
        getAxesUnderCursor: getYAxesUnderCursor
      }
    });

    const drawSelectedRegion = sqChartSelection.drawSelectedRegion(
      () => $scope.chart,
      {
        selection: Z_INDEX.SELECTED_REGION,
        button: Z_INDEX.SELECTED_REGION_REMOVE
      },
      {
        clearSelection: () => $scope.$apply($scope.removeSelectedRegion)
      },
      {
        x: undefined,
        y: (chart, minVal, maxVal) => ({
          minPixel: 0,
          maxPixel: getChartSeriesDisplayHeight()
        })
      },
      {
        isPickingMode: () => $scope.isPickingMode,
        pickSelection: () => $scope.$apply($scope.pickSelectedRegion)
      }
    );

    const drawCapsuleRegion = sqChartRegion.drawCapsuleRegion(
      () => $scope.chart,
      () => _.chain($scope.items)
        .filter(['itemType', ITEM_TYPES.CAPSULE])
        .flatMap((capsuleSet: any) => capsuleSet.capsules)
        .filter((capsule: any) => sqAnnotationStore.annotatedItemIds[capsule.id])
        .map(({ startTime, endTime, id }) => ({
          xMin: startTime,
          xMax: endTime,
          yMin: 0,
          yMax: 0,
          id,
          dateTime: moment(startTime).format('lll')
        }))
        .value(),
      {
        selection: Z_INDEX.CAPSULE_REGION,
        button: Z_INDEX.CAPSULE_REGION_REMOVE
      },
      {
        openAnnotation: (capsuleId) => {
          sqAnnotationActions.showEntry(sqAnnotationStore.annotatedItemIds[capsuleId]);
          return $scope.$apply($scope.annotationIcon);
        }
      },
      {
        x: undefined,
        y: (chart, minVal, maxVal) => ({
          minPixel: 0,
          maxPixel: getChartSeriesDisplayHeight()
        })
      },
      {
        isPickingMode: () => $scope.isPickingMode,
        pickSelection: () => $scope.$apply($scope.pickSelectedRegion)
      }
    );

    let lineBreaks = [];
    let capsuleIcons = [];

    // Used to cancel the selection when the mouse leaves the graph
    let mouseOverChart = false;

    const throttledChartMouseMove = _.throttle(chartMouseMove, 50);
    const debouncedDrawCursors = _.throttle(sqCursors.drawCursors, 75);

    let capsuleLaneHeight = 0;
    let processingItems = false;
    let createChartTimeout = null;

    const enabledMarker = { enabled: true, radius: 2, symbol: 'circle' };
    const disabledMarker = { enabled: false, radius: 0 };

    let showLabelsOnAxis = false;

    let laneClipRect = null;

    Highcharts.setOptions({
      time: {
        // In order to override the getTimezoneOffset method below, useUTC must be true
        useUTC: true,
        /**
         * Use moment-timezone.js to return the timezone offset for individual
         * timestamps, used in the X axis labels and the tooltip header.
         */
        getTimezoneOffset(timestamp) {
          if ($scope.selectedTimezone) {
            return -moment.tz(timestamp, $scope.selectedTimezone.name).utcOffset();
          } else {
            return -moment(timestamp).utcOffset();
          }
        }
      }
    });

    $scope.chartElement = $element.find('.chart');
    $scope.chart = null;

    $scope.$watch('selectedRegion', () => drawSelectedRegion({
      xMin: $scope.selectedRegion.min,
      xMax: $scope.selectedRegion.max,
      yMin: 0,
      yMax: 0
    }));
    $scope.$watch('isPickingMode', () => drawSelectedRegion({
      xMin: $scope.selectedRegion.min,
      xMax: $scope.selectedRegion.max,
      yMin: 0,
      yMax: 0
    }));
    $scope.$watch('selectedRegion', drawCapsuleRegion);
    $scope.$watch('isPickingMode', drawCapsuleRegion);
    $scope.$on('$destroy', destroyChart);

    // NOTE: chart must be destroyed before processItems is triggered because capsule time chart has different
    // chart events and zoom button
    const debouncedProcessItems = _.debounce(function() {
      // Running processItems at the end of the current digest cycle gives this cycle a chance for items to settle
      // before we commit to doing a highcharts render. Delaying the render of the chart can sometimes be faster
      // because it is computationally expensive to render the chart and multiple renders in a row are a waste
      // because the browsers UI thread doesn't have enough time to show the intermediate renders.
      if ((!_.isEmpty($scope.nextItems) && !$scope.chart) || $scope.chart) {
        processItems($scope.nextItems, $scope.lastItems);
        $scope.lastItems = $scope.nextItems;
      }
      processingItems = false;
    }, DEBOUNCE.SHORT);

    $scope.$watch('view', destroyChart);
    $scope.$watch('items', function(newItems, oldItems) {
      $scope.lastItems = $scope.lastItems || oldItems;
      $scope.nextItems = newItems;

      if (processingItems) {
        // there's already an update queued, so skip this one. $scope.lastItems and $scope.newItems are captured
        // appropriately so that no changes fall through the cracks.
        return;
      }

      processingItems = true;
      debouncedProcessItems();
    });

    $scope.$listenTo(sqAnnotationStore, ['annotatedItemIds'], updateAnnotationIcons);
    $scope.$listenTo(sqAnnotationStore, ['annotatedItemIds'], drawCapsuleRegion);
    $scope.$listenTo(sqCursorStore, syncCursors);
    $scope.$listenTo(sqDurationStore, syncAutoUpdate);
    $scope.$listenTo(sqTrendCapsuleStore, syncTrendCapsuleStore);
    $scope.$listenTo(sqTrendStore, syncTrendStore);

    $scope.$watchGroup(['trendStart', 'trendEnd'], updateXExtremes);
    $scope.$watchCollection('capsuleTimeOffsets', updateXExtremes);
    $scope.$watch('selectedTimezone', function() {
      if ($scope.chart) {
        $scope.chart.xAxis[0].update(undefined, false);
        chartRedraw();
        sqCursors.drawCursors($scope.chart, capsuleLaneHeight);
      }
    });

    $scope.$watch('breaks', function() {
      if ($scope.chart) {
        $scope.chart.xAxis[0].update({ breaks: $scope.breaks }, false);
        // ensure to redraw before attempting to draw the line breaks or the breaks won't be available on the axis.
        chartRedraw();
        updateLineBreaks();
      }
    });

    $scope.$watch('isDimmed', function() {
      if ($scope.chart) {
        updateSeries(getSeriesData($scope.items));
      }
    });

    /**
     * Syncs with the trendstore - currently we only listen to a changes related to label display.
     *
     * @param {Object} e - The Baobab event object containing paths that changed
     */
    function syncTrendStore(e) {
      const updateCapsuleLabels = sqUtilities.propertyChanged(e, 'showCapsuleLaneLabels');
      const updateLaneLabels = sqUtilities.propertyChanged(e, ['showChartConfiguration', 'labelDisplayConfiguration']);
      const updateGridlines = sqUtilities.propertyChanged(e, ['showGridlines']);

      showLabelsOnAxis = anyLabelsOnLocation(LABEL_LOCATIONS.AXIS);

      function anyLabelsOnLocation(location) {
        return sqTrendStore.labelDisplayConfiguration.unitOfMeasure === location ||
          sqTrendStore.labelDisplayConfiguration.asset === location ||
          sqTrendStore.labelDisplayConfiguration.name === location ||
          sqTrendStore.labelDisplayConfiguration.custom === location;
      }

      if ($scope.chart) {
        if (updateCapsuleLabels) {
          updateCapsuleAxis();
          createCapsuleIcons();
          manageYAxis();
        }

        if (updateLaneLabels || updateCapsuleLabels) {
          manageLaneAxisLabels();
          chartRedraw();
          updateChartSizing();
          clipSignalsToLanes();
        }

        if (updateGridlines) {
          updateGridlineWidth();
          chartRedraw();
        }
      }
    }

    /**
     * Update the cursors from sqCursorStore and redraw them on the chart
     */
    function syncCursors() {
      sqCursors.syncCursorsWithStore($scope.isCapsuleTime);
      sqCursors.drawCursors($scope.chart, capsuleLaneHeight);
    }

    function syncTrendCapsuleStore(e) {
      if (sqUtilities.propertyChanged(e, 'editingId')) {
        editingId = sqTrendCapsuleStore.editingId;
      }
    }

    /**
     * Redraws the now cursor when auto update is disabled so it disappears from the chart
     */
    function syncAutoUpdate() {
      if ($scope.chart && sqDurationStore.autoUpdate.mode === AUTO_UPDATE.MODES.OFF) {
        // Redraw now cursor when auto update is disabled so it disappears
        sqCursors.drawNowCursor($scope.chart);
      }
    }

    /**
     * Reflow the trend so that it correctly fills its container. Only needs to be called when the chart container
     * has its dimensions changed because another element on the page changed. Debounced to avoid repeated calls
     * during resizing.
     */
    $scope.reflowTrend = _.debounce(function() {
      if ($scope.chart) {
        $scope.chart.reflow();
        manageYAxis();
        chartRedraw();
        clipSignalsToLanes();
        updateChartSizing();
      }
    }, DEBOUNCE.MEDIUM);

    /**
     * Adjust all manually drawn elements.
     */
    function updateChartSizing() {
      // evalAsync in case we are already in a $digest cycle
      $scope.$evalAsync(function() {
        if (!$scope.chart) {
          return;
        }
        updateLineBreaks();
        drawSelectedRegion({
          xMin: $scope.selectedRegion.min,
          xMax: $scope.selectedRegion.max,
          yMin: 0,
          yMax: 0
        });
        positionCapsuleIcons();
        drawCapsuleRegion();
        sqCursors.clearHoverCursor();
        sqCursors.drawCursors($scope.chart, capsuleLaneHeight);
      });
    }

    /**
     * Updates the x and y axis gridline width settings on the chart
     */
    function updateGridlineWidth() {
      if (!$scope.chart) {
        return;
      }

      // Update y axes (there may be multiple)
      const yAxisValues = _.chain($scope.chart.series)
        .filter(series => _.includes([ITEM_TYPES.METRIC, ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR],
          series.userOptions.itemType))
        .filter('userOptions.yAxis')
        .map(series => ({ id: series.userOptions.yAxis, gridLineWidth: sqLabels.getGridlineWidth() }))
        .uniqBy('id')
        .value();

      $scope.chart.update({
        xAxis: {
          gridLineWidth: sqLabels.getGridlineWidth()
        },
        yAxis: yAxisValues
      }, false);
    }

    /**
     * Processes changes to the items array.
     *
     * @param {Object[]} newItems - The updated array of all items in the chart
     * @param {Object[]} oldItems - The array of all previous versions of all items in the chart
     */
    function processItems(newItems, oldItems) {
      let axis;
      const diff = sqUtilities.diffArrays(newItems, oldItems, FIELDS_TO_DIFF, 'id');
      const isNotEmpty = _.negate(_.isEmpty);
      const changedProperties = _.chain(diff.changes).pickBy(isNotEmpty).keys().value();
      const hasAnyPropertyChanged = _.flow(_.partial(_.intersection, changedProperties), isNotEmpty) as any;
      let doUpdateY = false;
      let doAdd = false;
      // If ID has changed swap has occurred so recreate the chart given that highcharts references IDs in places
      if (diff.changes.id.length) {
        destroyChart();
      }

      if (!$scope.chart) {
        // This is done AFTER the current digest cycle to prevent the chart from being resized immediately after it
        // is created. Several elements, such as the profile search steps, are still being hidden/shown/rendered
        // while this chart is being created and these take up space until they are fully rendered. Attempting to
        // create the chart in the current digest cycle (using $evalAsync, for example) causes a 'jump'.
        if (createChartTimeout) {
          $interval.cancel(createChartTimeout);
        }

        createChartTimeout = $interval(function() {
          // Note: the order of calls here is important.
          createChart(sqUtilities.cloneDeepOmit(newItems, ['data', 'samples']));
          updateSeriesYExtremes(newItems);
          updateXExtremes(false);
          updateCapsuleAxis();
          createCapsuleIcons();
          manageYAxis();
          updateYAxisAlignment();
          chartRedraw();
          clipSignalsToLanes();
          drawSelectedRegion({
            xMin: $scope.selectedRegion.min,
            xMax: $scope.selectedRegion.max,
            yMin: 0,
            yMax: 0
          });
          drawCapsuleRegion();
          updateLineBreaks();
          sqCursors.updateCursorItems($scope.chart, $scope.items, $scope.isCapsuleTime);
          createChartTimeout = null;
        }, 1, 1);
      } else if (!newItems.length) {
        destroyChart();
      } else {
        if (diff.removedItems.length) {
          removeSeries(diff.removedItems);
          removeCapsuleIcons(diff.removedItems);
          doUpdateY = true;
        }

        if (diff.addedItems.length) {
          addSeries(sqUtilities.cloneDeepOmit(diff.addedItems, ['data', 'samples']));
          createCapsuleIcons();
          drawCapsuleRegion();
          // do add will be true for formula results
          doAdd = _.some(diff.addedItems, (item: any) => !_.isEmpty(item.data));
          // if the result is from a formula we ned to set doUpdateY to true to ensure proper display
          if (doAdd) {
            updateSeriesYExtremes(diff.addedItems);
            doUpdateY = true;
          }
        }

        if (hasAnyPropertyChanged(
          ['selected', 'zones', 'visible', 'shadedAreaLower', 'shadedAreaUpper', 'color', 'lineWidth', 'dashStyle']) ||
          diff.addedItems.length || diff.removedItems.length) {
          updateSeries(getSeriesData(newItems));
          if (diff.changes.color.length) {
            doUpdateY = true;
          }
        }

        if (hasAnyPropertyChanged(['selected', 'zones']) || diff.removedItems.length) {
          // NOTE: This must happen after .getSeriesData() because it updates the colors of the items
          colorCapsuleIcons();
        }

        if (diff.changes.autoDisabled.length) {
          updateSeriesVisibility(diff.changes.autoDisabled);
        }

        if (diff.changes.data.length) {
          updateSeriesData(diff.changes.data);
          createCapsuleIcons();
          drawCapsuleRegion();
        }

        if (diff.changes.yAxisConfig.length) {
          updateSeriesYExtremes(diff.changes.yAxisConfig);
          doUpdateY = true;
        }

        // We need to ensure that the axis min and max are always properly set. If you swap a Signal with another
        // Signal that has the same min/max values then no yAxisConfig changes will be detected and the signal is
        // not displayed as expected as Highcharts is too helpful in setting min/max axis values on data update.
        if (diff.changes.data.length || diff.changes.yAxisType.length) {
          updateSeriesYExtremes(diff.changes.data);
          doUpdateY = true;
        }

        if (diff.changes.sampleDisplayOption.length) {
          setPointsOnly(diff.changes.sampleDisplayOption);
        }

        if (diff.changes.rightAxis.length || diff.changes.axisVisibility.length || diff.changes.axisAlign.length ||
          diff.changes.lane.length) {
          doUpdateY = true;
        }

        if (diff.changes.stringEnum.length) {
          // This is a hack required to set the string labels initially,
          // due to the fact that we add the item to the chart prior to getting data
          if (!_.isEqual(_.countBy(oldItems, 'isStringSeries'), _.countBy(newItems, 'isStringSeries'))) {
            removeSeries(diff.changes.stringEnum);
            addSeries(sqUtilities.cloneDeepOmit(diff.changes.stringEnum, ['data', 'samples']));
            updateSeriesYExtremes(diff.changes.stringEnum);
            doUpdateY = true;
            updateSeries(getSeriesData(newItems));
          } else {
            // This enables us to update the labels on the y axis for string series during pan and zoom
            // without removing and re-adding the series
            _.forEach(diff.changes.stringEnum, function(item: any) {
              axis = getItemYAxis(item);
              axis.update({
                tickPositions: _.map(item.stringEnum, 'key').sort()
              }, false);
            });
          }
        }

        if ($scope.isCapsuleTime) {
          if (hasAnyPropertyChanged(['duration', 'capsuleSegmentData']) ||
            _.filter(diff.addedItems, ['childType', ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE]).length ||
            _.filter(diff.removedItems, ['childType', ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE]).length) {
            updateXExtremes(false);
          }

          if (diff.changes.yAlignment.length) {
            updateYAxisAlignment();
          }
        }

        if (hasAnyPropertyChanged(['data', 'stringEnum']) || diff.addedItems.length || diff.removedItems.length ||
          diff.changes.lineWidth.length) {
          updateYAxisAlignment();

          if (diff.addedItems.length || diff.removedItems.length || diff.changes.lineWidth.length) {
            updateCapsuleAxis();
            doUpdateY = true;
          }

          drawSelectedRegion({
            xMin: $scope.selectedRegion.min,
            xMax: $scope.selectedRegion.max,
            yMin: 0,
            yMax: 0
          });
          drawCapsuleRegion();
        }

        if (doAdd || diff.removedItems.length || changedProperties.length || doUpdateY) {
          if (doUpdateY) {
            manageYAxis();
          }

          chartRedraw();

          // In chain view, an updateSeries call above can shift the axis's left position and the above chartRedraw
          // shifts it back. If we don't update the line breaks here, they could be in the wrong place.
          if ($scope.view === TREND_VIEWS.CHAIN) {
            updateLineBreaks();
          }

          clipSignalsToLanes();
          positionCapsuleIcons(); // This must happen after redraw because that is when capsule points are calculated
          drawCapsuleRegion();
          colorCapsuleIcons();
          sqCursors.updateCursorItems($scope.chart, $scope.items, $scope.isCapsuleTime);
        }

        if (hasAnyPropertyChanged(['data', 'stringEnum'])) {
          sqCursors.drawCursors($scope.chart, capsuleLaneHeight);
        }
      }
    }

    /**
     * Tiny helper to return the appropriate height for everything that should not overlap the capsule lane.
     *
     * @returns {Number} the height in pixel that is available
     */
    function getChartSeriesDisplayHeight() {
      return $scope.chart.plotHeight - capsuleLaneHeight;
    }

    /**
     * Tiny helper to return the appropriate height for the lanes.
     *
     * @returns {Number} the height in pixels of the lane
     */
    function getLaneHeight() {
      const numberOfLanes = getDisplayedLanes().length;
      const displayHeight = getChartSeriesDisplayHeight();

      if (numberOfLanes > 0) {
        const laneBuffersHeight = (numberOfLanes - 1) * sqUtilities.getLaneBuffer($scope.isCapsuleTime);
        return (displayHeight - laneBuffersHeight) / numberOfLanes;
      } else {
        return displayHeight;
      }
    }

    /**
     * Each capsule series has it's own y-axis. The y-axis are stacked by using the height and top parameters of the
     * axis. To ensure proper display the capsuleLaneHeight variable is updated with the current total height of all
     * capsule lane axis.
     *
     * If lane labels are shown the buffer for each axis is increased to ensure the label can be displayed without
     * interfering with the capsule display.
     * The lane labels are displayed using plotband labels, so plotbands are added for each capsule axis.
     *
     */
    function updateCapsuleAxis() {
      capsuleLaneHeight = 0;
      let nextTop = 0;
      let index = 0;
      const extraTopBuffer = sqTrendStore.showCapsuleLaneLabels ? 12 : 0;
      _.chain($scope.items)
        .filter({ itemType: ITEM_TYPES.CAPSULE })
        .groupBy('capsuleSetId')
        .forEach((capsuleRows, capsuleSetId) => {
          const axisForCapsuleRow = _.find($scope.chart.yAxis,
            { userOptions: { id: sqLabels.getCapsuleAxisId(capsuleSetId) } }) as any;

          if (axisForCapsuleRow) {
            const maxYValue = (_.chain(capsuleRows).map('yValue') as any).max().value();
            const minYValue = (_.chain(capsuleRows).map('yValue') as any).min().value();
            const lineWidth = (_.chain(capsuleRows).map('lineWidth') as any).max().value();
            const buffer = lineWidth / 2;
            const dataLabelTitles = _.chain(capsuleRows)
              .map('dataLabels.labelNames')
              .flatten()
              .compact()
              .uniq()
              .value();
            const laneColor = sqTrendStore.showCapsuleLaneLabels
              ? (index % 2 === 0 ? WHITE_LANE_COLOR : GRAY_LANE_COLOR)
              : WHITE_LANE_COLOR;
            const min = minYValue - buffer - extraTopBuffer;
            const max = maxYValue + buffer;
            const height = max - min;
            const plotBands = [{
              from: min,
              to: max,
              color: laneColor,
              label: {
                ...LANE_LABEL_CONFIG,
                text: sqLabels.getCapsuleLaneLabelDisplayText(capsuleSetId, dataLabelTitles)
              }
            }];

            axisForCapsuleRow.update({
              height,
              top: nextTop,
              min,
              max,
              plotBands
            }, false);

            if (!_.isFinite(height)) {
              throw new Error(`Capsule lane height of ${height} is not a finite number`);
            }

            nextTop += height;
            index++;
          }
        })
        .value();

      capsuleLaneHeight = nextTop;

      updatePlotBands();
      addCapsuleEditPlotBand();
    }

    /**
     * This function adds the background to the capsule lane that is currently being edited.
     * The boundaries for the plotband used to visualize the background are based of the yValues of the displayed
     * capsules (with some added buffer values).
     *
     * If no capsules are found an empty band is displayed.
     *
     * Plotlines are added to generate the border.
     *
     */
    function addCapsuleEditPlotBand() {
      const lookUpId = editingId || PREVIEW_ID;
      let axis, plotLines, plotBand;
      const axisId = sqLabels.getCapsuleAxisId(lookUpId);

      if ($scope.chart && $scope.chart.yAxis) {
        axis = _.find($scope.chart.yAxis, { userOptions: { id: axisId } });
      }

      if (!_.isUndefined(axis) && _.isFinite(axis.userOptions.min) && _.isFinite(axis.userOptions.max)) {
        // generate the "border" lines
        plotLines = [{
          value: axis.userOptions.min,
          color: '#ccc',
          width: 1
        }, {
          value: axis.userOptions.max,
          color: '#ccc',
          width: 1
        }];

        plotBand = {
          color: PREVIEW_HIGHLIGHT_COLOR,
          from: axis.userOptions.min,
          to: axis.userOptions.max
        };

        if (sqTrendStore.showCapsuleLaneLabels) {
          _.assign(plotBand, {
            label: {
              ...LANE_LABEL_CONFIG,
              text: '<span class="text-with-shadow">' + $translate.instant('PREVIEW') + '</span>'
            }
          });
        }

        axis.update({ plotBands: [plotBand], plotLines }, true);
      }
    }

    /**
     * Create icons for any annotations that annotate capsules or reference pattern capsules.
     * Note: Capsules with multiple icons are not handled, only one icon per capsule is supported.
     */
    function createCapsuleIcons() {
      if (!$scope.chart || $scope.isCapsuleTime) {
        return;
      }

      _.chain($scope.items)
        .filter(['itemType', ITEM_TYPES.CAPSULE])
        .forEach((capsuleSet: any) => {
          _.chain(capsuleSet.capsules)
            .filter((capsule: any) => capsule.isReferenceCapsule)
            .forEach((capsule: any) => {
              const icon = _.find(capsuleIcons, ['id', capsule.id]);
              const ANNOTATE_ICON = '\ue905';
              // If icon already exists just update its seriesId since its row position can move
              if (icon) {
                icon.seriesId = capsuleSet.id;
              } else {
                const xLocation = _.max([capsule.startTime, $scope.trendStart]);
                capsuleIcons.push({
                  seriesId: capsuleSet.id,
                  id: capsule.id,
                  x: xLocation,
                  icon: $scope.chart.renderer.text(ANNOTATE_ICON, 0, 0) // fc-annotate
                    .attr({
                      class: 'fc cursorPointer',
                      zIndex: Z_INDEX.CAPSULE_ICONS,
                      dateTime: moment(xLocation).format('lll') // To support test
                    })
                    .on('click', function() {
                      $scope.$apply(() => {
                        sqInvestigateActions.loadToolForEdit(capsuleSet.capsuleSetId);
                      });
                    })
                    .add()
                });
              }
            })
            .value();
        })
        .value();

      positionCapsuleIcons();
      colorCapsuleIcons();
    }

    /**
     * Remove icons for annotations that have been deleted and then create icons for new annotations.
     */
    function updateAnnotationIcons() {
      removeCapsuleIcons(_.chain(capsuleIcons)
        .reject(icon => sqAnnotationStore.annotatedItemIds[icon.id])
        .map(icon => ({ id: icon.seriesId }))
        .value());
      createCapsuleIcons();
    }

    /**
     * Updates the position of all of the capsule icons to be just to the left of the capsule to
     * which they are associated.
     */
    function positionCapsuleIcons() {
      _.forEach(capsuleIcons, function(icon) {
        let x, y, visibility;
        const series = findChartSeries(icon.seriesId);
        const point = _.find(_.get(findChartSeries(icon.seriesId), 'points'), ['x', icon.x]) as any;
        const box = icon.icon.getBBox();
        if (point && _.isFinite(point.plotX) && _.isFinite(point.plotY)) {
          x = point.plotX + $scope.chart.plotLeft - box.width - 3;
          y = series.yAxis.toPixels(((series.yAxis.max - series.yAxis.min) / 2) + series.yAxis.min,
            false) + box.height / 2;
          visibility = x < $scope.chart.plotLeft ? 'hidden' : 'visible';
          // Prevent occasional console error during initial render due to NaN being passed to Highcharts
          if (_.isFinite(x) && _.isFinite(y)) {
            icon.icon.attr({ x, y, visibility });
          }
        } else {
          icon.icon.attr({ visibility: 'hidden' });
        }
      });
    }

    /**
     * Updates the color of all annotation capsule icons to be the same as the color of the capsule they annotate.
     */
    function colorCapsuleIcons() {
      _.forEach(capsuleIcons, function(icon) {
        const point = _.find(_.get(findChartSeries(icon.seriesId), 'points'), ['x', icon.x]) as any;
        if (point) {
          icon.icon.css({ color: point.color });
        }
      });
    }

    /**
     * Removes the annotation and icons associated with any items that have also been removed.
     *
     * @param {Object[]} removedItems - The items that have been removed from the chart
     */
    function removeCapsuleIcons(removedItems) {
      _.forEach(removedItems, function(item: any) {
        const annotationIndex = _.findIndex(capsuleIcons, ['seriesId', item.id]);
        if (annotationIndex >= 0) {
          capsuleIcons[annotationIndex].icon.destroy();
          capsuleIcons.splice(annotationIndex, 1);
        }
      });
    }

    /** Creates a Right-Click event listener for a given DOM element from the HighCharts class
     *
     * @see: https://api.highcharts.com/class-reference/Highcharts#.HTMLDOMElement
     *
     * @param  {Highcharts.HTMLDOMElement} DOMelement - the HTML element to link the event listener to
     * @param callback - the function to execute when the event occurs
     */
    function createRightClickEventListener(DOMelement, callback) {
      Highcharts.addEvent(DOMelement, 'contextmenu', function(e) {
        e.preventDefault();
        callback(e);
      });
    }

    /**
     * Create chart from the items.
     *
     * @param {Array} items - The array of all items to plot on the chart.
     */
    function createChart(items) {
      let chartConfig;
      let yAxisValues;
      const dateTimeLabelFormats = {
        millisecond: '%l:%M:%S.%L %P',
        second: '%l:%M:%S %P',
        minute: '%l:%M %P',
        hour: '%l:%M %P',
        day: '%b %e',
        week: '%b %e',
        month: '%b \'%y',
        year: '%Y'
      };

      if (!sqDateTime.displayMonthDay()) {
        dateTimeLabelFormats.day = '%e. %b';
        dateTimeLabelFormats.week = '%e. %b';
      }

      if (!sqDateTime.display12HrClock()) {
        dateTimeLabelFormats.millisecond = '%H:%M:%S.%L';
        dateTimeLabelFormats.second = '%H:%M:%S';
        dateTimeLabelFormats.minute = '%H:%M';
        dateTimeLabelFormats.hour = '%H:%M';
      }

      yAxisValues = _.chain(items).map(createYAxisDefinition).compact().uniqBy('id').value();

      yAxisValues.push(sqLabels.createPlotBandAxisDefinition());

      chartConfig = {
        chart: {
          alignTicks: false,
          animation: false,
          ignoreHiddenSeries: false,
          spacing: [0, 5, 5, 0],
          zoomType: 'x',
          boost: {
            enabled: false
          },
          resetZoomButton: {
            theme: {
              display: 'none'
            }
          },
          events: {
            click: onChartClick,

            selection(e) {
              $scope.$apply(function() {
                $scope.setSelectedRegion()(e.xAxis[0].min, e.xAxis[0].max);
              });

              return false;
            },

            load(e) {
              // Activate scroll and zoom functionality. Use returned function to deactivate scroll and zoom.
              deactivateScrollZoom = axisControl.activateScrollZoom(this, $scope.chartElement);

              // Used for adding cursors to the chart
              createRightClickEventListener(e.target.container, onChartClick);
            }

          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        title: {
          text: null
        },
        xAxis: {
          ordinal: false,
          type: 'datetime',
          dateTimeLabelFormats,
          // If we don't set minRange, Highcharts will enforce its own computed minRange. This sets the smallest x
          // range to 1 ms.
          minRange: 1,
          // See https://github.com/highslide-software/highcharts.com/issues/4646 for why we set minTickInterval
          minTickInterval: 0,
          tickLength: 5,
          gridLineWidth: sqLabels.getGridlineWidth(),
          labels: {
            enabled: true,
            style: {
              color: DEFAULT_AXIS_LABEL_COLOR,
              fontSize: '12px',
              fontFamily: 'Helvetica, sans-serif',
              paddingTop: '0px'
            },
            formatter: !$scope.isCapsuleTime ? null : function() {
              return sqDateTime.formatDuration(this.value, true);
            }
          },
          breaks: $scope.breaks,
          events: {
            setExtremes(e) {
              let thisMin;
              let thisMax;

              // This callback happens a lot during trend scroll and zoom operations.
              // Due to this fact, we prevent unwanted digest cycles by only calling $scope.$apply when needed.
              if (e.trigger === 'zoom') {
                $scope.$apply(function() {
                  thisMin = Math.min(e.min, e.max);
                  thisMax = Math.max(e.min, e.max);
                  sqDurationActions.displayRange.updateTimes(thisMin, thisMax);
                });

                drawSelectedRegion({
                  xMin: $scope.selectedRegion.min,
                  xMax: $scope.selectedRegion.max,
                  yMin: 0,
                  yMax: 0
                });
                drawCapsuleRegion();
                debouncedDrawCursors($scope.chart, capsuleLaneHeight);
                positionCapsuleIcons();
                updateLineBreaks();
              }
            }
          }
        },
        yAxis: yAxisValues,
        plotOptions: {
          series: {
            turboThreshold: 0,
            boostThreshold: 0,
            allowPointSelect: false,
            animation: false,
            cursor: 'pointer',
            linecap: 'square',
            dataGrouping: {
              enabled: false
            },
            events: {
              click: onPointClick
            },
            line: {
              connectNulls: false
            },
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: false
                }
              }
            },
            dataLabels: {
              useHTML: true,
              align: 'left',
              verticalAlign: 'middle',
              style: {
                fontFamily: 'inherit',
                fontSize: '10px',
                fontWeight: 'normal'
              }
            },
            states: {
              hover: {
                halo: {
                  size: 0
                },
                lineWidthPlus: 0
              },
              inactive: {
                enabled: false
              }
            }
          }
        },
        tooltip: {
          enabled: false
        },
        series: getSeriesData(items)
      };

      $scope.chart = Highcharts.chart($scope.chartElement[0], chartConfig);

      // Make this function available on the chart object itself
      $scope.chart.getItemYAxis = getItemYAxis;

      // Set to true when chart is created so cursors will appear if mouse is already over the chart area
      mouseOverChart = true;

      $scope.chartElement.on('mouseenter', onChartMouseEnter);
      $scope.chartElement.on('mousemove', throttledChartMouseMove);
      $scope.chartElement.on('mouseleave', onChartMouseLeave);
    }

    /**
     * Removes the chart.
     */
    function destroyChart() {
      capsuleIcons = [];
      deactivateScrollZoom();
      $scope.clearPointerValues();
      if ($scope.chart && $scope.chart.destroy) {
        $scope.chart.destroy();
        $scope.chart = null;
      }

      if ($scope.chartElement) {
        $scope.chartElement.off();
        $scope.chartElement.html('');
      }
    }

    /**
     * Break the line where there is a point break
     */
    function updateLineBreaks() {
      let x;
      _.forEach(lineBreaks, function(lineBreak) {
        lineBreak.destroy();
      });

      lineBreaks = [];

      _.forEach($scope.breaks, function(brk: any) {
        // note: this check was necessary to avoid some underlying Highcharts issue.
        if ($scope.chart.xAxis[0].breakArray && _.isFinite(brk.from)) {
          x = $scope.chart.xAxis[0].toPixels(brk.from, false);
          if (_.isFinite(x)) {
            lineBreaks.push($scope.chart.renderer.rect(x, $scope.chart.plotBox.y, 3, $scope.chart.plotBox.height, 1)
              .attr({
                class: 'highcharts-cursor-crosshair',
                fill: '#ffffff',
                opacity: 1,
                zIndex: Z_INDEX.LINE_BREAKS
              })
              .add());
          }
        }
      });
    }

    /**
     * Handles the 'mouseenter' event on the Highcharts DOM element
     */
    function onChartMouseEnter() {
      mouseOverChart = true;
    }

    /**
     * Handles the 'mouseleave' event on the Highcharts DOM element
     */
    function onChartMouseLeave() {
      mouseOverChart = false;
      $scope.$evalAsync(function() {
        $scope.clearPointerValues();
        sqCursors.clearHoverCursor();
      });
    }

    /**
     * Handles the 'mousemove' event on the Highcharts DOM element
     *
     * @param {Object} e - An event object
     */
    function chartMouseMove(e) {
      if (!$scope.chart) {
        return;
      }

      // Translate the client X-value into the chart area. Note that using e.offsetX is not sufficient, since in
      // some browsers the frame for this value is different when hovering over a series or over the plot background.
      const chartRect = $scope.chart.container.getBoundingClientRect();
      let yValues, xAxis, xValue, xPixelPlotArea;

      // Mouse events are fired when axis is dragged. Updating point values during axis drag causes flickering.
      if (axisControl.isDragInProgress() || !mouseOverChart) {
        $scope.$evalAsync(function() {
          $scope.clearPointerValues();
          sqCursors.clearHoverCursor();
        });

        return;
      }

      // Calculate an x-value from the mouse event
      xAxis = $scope.chart.xAxis[0] as Highcharts.Axis;
      xValue = xAxis.toValue(e.clientX - chartRect.left, false);
      xPixelPlotArea = e.clientX - chartRect.left - $scope.chart.plotLeft;

      // Don't try to calculate pointerValues when the cursor is in the y-axis area
      if (xPixelPlotArea >= 0) {
        yValues = sqCursors.calculatePointerValues($scope.chart, xValue, $scope.items);
      }

      // This event is triggered when changing item visibility, hence the need for evalAsync
      $scope.$evalAsync(function() {
        if (yValues) {
          $scope.setPointerValues()(xValue, yValues);
        } else {
          $scope.clearPointerValues();
        }

        sqCursors.drawHoverCursor($scope.chart, xPixelPlotArea, xValue, yValues, capsuleLaneHeight);
      });
    }

    /**
     * This function renders the "Lane Label".
     */
    function manageLaneLabelDisplay() {
      const lanes = getDisplayedLanes();
      const labelAxis = _.find($scope.chart.yAxis, { userOptions: { id: 'yAxis-' + PLOT_BAND } }) as any;

      _.forEach(_.reverse(lanes), (lane, idx) => {
        // Set the lane display labels, odd plotLinesAndBands are spacers so multiply by 2
        const options = _.get(labelAxis, 'userOptions.plotBands', null);
        if (options && options[idx * 2] && options[idx * 2].label) {
          const labelText = sqLabels.getLaneDisplayText($scope.items, lane, $scope.chart.plotWidth);
          const userOptions = labelAxis.userOptions;
          userOptions.plotBands[idx * 2].label.text = labelText;
          labelAxis.update(userOptions, false);
        }
      });
    }

    /**
     * This function manages the axis offsets and, if addAxisTitle is true, the display of axis labels.
     * Conceptually this function:
     *  - iterates over all the displayed lane
     *  - then iterates over all the series assigned to each lane
     *  - adjust the offset for each series axis so that the labels do not overlap
     *  - keeps track of the biggest offset of any given axis so that the overall chart margins can be set accordingly.
     *
     * @param {Boolean} addAxisTitle - true if axis titles should be displayed false otherwise.
     */
    function manageAxisOffsets(addAxisTitle) {
      let largestOffsetLeft = 0;
      let largestOffsetRight = 0;
      const lanes = getDisplayedLanes();
      const laneCount = lanes.length;
      const laneHeight = getLaneHeight();

      _.forEach(lanes, (lane) => {
        // Find all the series that are in the current lane.
        const seriesInLane = _.filter($scope.items, (item: any) => {
          return (item.itemType === ITEM_TYPES.SERIES || item.itemType === ITEM_TYPES.SCALAR) && _.get(item,
            'lane') === lane;
        });
        // Find all the unique axis that belong to this lane:
        const uniqueAxisAssignmentInLane = _.chain(seriesInLane).map('axisAlign').uniq().value();
        let offsetLeft = 0;
        let offsetRight = 0;
        let opposite;
        const padding = addAxisTitle ? 30 : 10;

        _.forEach(_.sortBy(uniqueAxisAssignmentInLane), (assignment) => {
          const seriesSharingAnAxis = _.filter(seriesInLane, { axisAlign: assignment });
          _.forEach(seriesSharingAnAxis, (series) => {
            const axis = getItemYAxis(series);
            if (_.get(axis, 'visible', false)) {
              const tickPositions = sqLabels.getNumericTickPositions(axis.userMin,
                axis.userMax, series, laneHeight, laneCount);

              let axisUpdates = {
                title: {
                  enabled: false,
                  text: ''
                }
              };

              if (addAxisTitle) {
                const axisTitle = sqLabels.getAxisDisplayText(
                  $scope.items,
                  assignment,
                  seriesSharingAnAxis,
                  axis.height ? axis.height : laneHeight
                );

                axisUpdates = {
                  title: {
                    text: axisTitle,
                    enabled: true
                  }
                };
              }

              const labelLength = sqLabels.getLabelWidth(tickPositions, axis, laneHeight, padding,
                series.isStringSeries, series);

              opposite = axis.userOptions.opposite;
              setAxisProperties(axis,
                _.assign(axisUpdates, { offset: opposite ? offsetRight : offsetLeft }, { axisWidth: labelLength }));

              if (opposite) {
                offsetRight += labelLength;
                largestOffsetRight = _.max([offsetRight, largestOffsetRight]);
              } else {
                offsetLeft += labelLength;
                largestOffsetLeft = _.max([offsetLeft, largestOffsetLeft]);
              }
            }
          });
        });
      });

      manageCapsuleLaneLabels();
    }

    /**
     * Manages the display of lane, axis and capsule lane labels as well as the offset required for y-axis if there
     * is more than one y-axis assigned to a lane.
     */
    function manageLaneAxisLabels() {
      if (!$scope.chart) {
        return;
      }

      // this manages axes labels and titles
      manageAxisOffsets(showLabelsOnAxis);
      manageLaneLabelDisplay();
      manageCapsuleLaneLabels();
    }

    /**
     * Toggles the capsule lane labels on and off based on the showCapsuleLaneLabels flag in the trendStore.
     */
    function manageCapsuleLaneLabels() {
      _.chain($scope.items)
        .filter({ itemType: ITEM_TYPES.CAPSULE })
        .groupBy('capsuleSetId')
        .forEach((capsuleRows, capsuleSetId) => {
          const axis = _.find($scope.chart.yAxis, { userOptions: { capsuleSetId } }) as any;
          if (axis && axis.plotLinesAndBands && axis.plotLinesAndBands[0] && axis.plotLinesAndBands[0].label) {
            const dataLabelTitles = _.chain(capsuleRows)
              .map('dataLabels.labelNames')
              .flatten()
              .compact()
              .uniq()
              .value();
            axis.plotLinesAndBands[0].label.attr({
              text: sqLabels.getCapsuleLaneLabelDisplayText(capsuleSetId, dataLabelTitles)
            });
          }
        })
        .value();
    }

    /**
     * Updates the "lanes" background on the chart. The label object is defined so that the lane display can be
     * turned on/off based on the showChartConfig flag in the trendStore.
     *
     * @param {Object} [colors] - Map of lane number to custom color for that lane
     */
    function updatePlotBands(colors = {}) {
      // The plotbands are constructed from the bottom lane to the top lane
      const lanes = _.reverse(getDisplayedLanes());
      const laneCount = lanes.length;
      const capsuleSets = _.chain($scope.items)
        .filter({ itemType: ITEM_TYPES.CAPSULE })
        .map('capsuleSetId')
        .uniq()
        .value();
      const startWithColorOffset = !_.isEmpty(capsuleSets) && capsuleSets.length % 2 === 0 ? 1 : 0;
      const laneBuffer = sqUtilities.getLaneBuffer($scope.isCapsuleTime);
      const laneHeight = getLaneHeight();

      const plotBands = _.flatMap(lanes, (lane, idx) => {
        return [{
          color: colors[lane] ?? ((laneCount - idx + startWithColorOffset) % 2 === 0 ? WHITE_LANE_COLOR :
            GRAY_LANE_COLOR),
          from: laneHeight * idx + laneBuffer * idx,
          to: laneHeight * (idx + 1) + laneBuffer * idx,
          label: {
            ...LANE_LABEL_CONFIG,
            text: '' // Will be filled in by manageLaneLabelDisplay()
          }
        }, {
          color: WHITE_LANE_COLOR,
          from: laneHeight * (idx + 1) + laneBuffer * idx,
          to: laneHeight * (idx + 1) + laneBuffer * (idx + 1)
        }];
      });

      const axis = _.find($scope.chart.yAxis, { userOptions: { id: 'yAxis-' + PLOT_BAND } }) as any;
      const max = laneHeight * lanes.length + laneBuffer * (lanes.length - 1);
      // pbs = lanes.length > 1 ? _.reverse(pbs) : pbs;
      axis.update({
        plotBands,
        visible: true,
        min: 0,
        max,
        height: getChartSeriesDisplayHeight(),
        top: capsuleLaneHeight
      }, false);
    }

    /**
     * Create a y-axis definition for an item.
     *
     * This function is called on a per-item basis. This function also ensures that capsules that belong to the same
     * capsule set are placed on the same y-axis so that capsule selection and label display logic work as expected.
     *
     * @param {Object} item - The item for which to create a yAxis.
     * @return {Object} A yAxis definition object.
     */
    function createYAxisDefinition(item) {
      let yAxis;
      if (item.itemType === ITEM_TYPES.SERIES || item.itemType === ITEM_TYPES.SCALAR) {
        yAxis = sqLabels.createYAxisDefinition(item, yAxisStringFormatter, yAxisFormatter, getNumericTickPositions);
      } else {
        const axisId = sqLabels.getCapsuleAxisId(item.capsuleSetId);
        item.yAxis = axisId;
        if ($scope.chart && $scope.chart.yAxis) {
          yAxis = _.find($scope.chart.yAxis, { userOptions: { id: axisId } });
        }
        if (!yAxis) {
          yAxis = sqLabels.getCapsuleAxisDefinition(item, axisId);

        } else {
          if (item.yValue > yAxis.userOptions.customValue) {
            yAxis.update({ max: item.yValue }, false);
          }
          yAxis = null;
        }
      }

      return yAxis;
    }

    /**
     * Get the y-axis ticks for an axis based on the items min/max to ensure y-axis labels
     * are only shown within the axis' range
     *
     * @returns {[Number]} an Array of tick positions.
     */
    function getNumericTickPositions() {
      const item = getAxisItem(this);
      if (!$scope.chart || !item) {
        return;
      }

      const laneCount = _.get(getDisplayedLanes(), 'length', 1);
      const laneHeight = getLaneHeight();

      const min = item?.yAxisConfig?.min ?? this.min;
      const max = item?.yAxisConfig?.max ?? this.max;

      return sqLabels.getNumericTickPositions(min, max, item, laneHeight, laneCount);
    }

    /**
     * Formats the y-axis labels.
     */
    function yAxisFormatter() {
      if (!$scope.chart) {
        return;
      }

      return sqLabels.formatYAxisTick(this.value,
        sqLabels.fetchTickAttributes(this.axis.min, this.axis.max, this.axis.userOptions.formatOptions,
          getLaneHeight())
      );
    }

    /**
     * Formats the y-axis string labels.
     */
    function yAxisStringFormatter() {
      // this.axis.series[0].options.stringEnum does not get updated when the series updates, therefore get it
      // from items
      const series = _.find($scope.items, ['id', this.axis.series[0].options.id]) as any;
      return sqLabels.formatStringYAxisLabel(this.value, series);
    }

    /**
     * Returns the data to be assigned to the `series` property on the chart.
     *
     * Selected items are made to stand out by putting their z-index higher while unselected items are
     * de-emphasized by fading their color. The line width is set based on the type of item.  Selected capsules
     * have their associated series highlighted for the encapsulated region.
     *
     * @see http://api.highcharts.com/highcharts#plotOptions.series
     * @param {Array} items - All items being displayed on the chart.
     * @return {Array} The items with updated color, zIndex, and lineWidth
     */
    function getSeriesData(items) {
      const anySeriesSelected = _.some(items, {
        selected: true,
        itemType: ITEM_TYPES.SERIES
      });
      const anyCapsuleSeriesSelected = _.some(items, {
        selected: true,
        itemType: ITEM_TYPES.CAPSULE
      });
      const anyCapsulesSelected = _.some(items, {
        anyCapsulesSelected: true,
        selected: anyCapsuleSeriesSelected,
        itemType: ITEM_TYPES.CAPSULE
      });
      const anyScalarsSelected = _.some(items, {
        selected: true,
        itemType: ITEM_TYPES.SCALAR
      });

      return _.map(items, function(item: any) {
        let color = item.color;
        let fillColor = item.color;
        const data = item.data;
        let zones = item.zones;
        let lineWidth = !_.isUndefined(item.lineWidth) ? item.lineWidth : LINE_WIDTHS[item.itemType];
        let dashStyle = item.dashStyle || DASH_STYLES.SOLID;
        let zIndex = Z_INDEX[item.itemType];
        let shadow = false as any;
        let marker = disabledMarker;
        let graphType = 'line';
        let threshold = 0;
        let fillOpacity = 1.0;
        let customProperties = {};
        const selectedOrPreview = item.selected
          || _.startsWith(item.id, PREVIEW_ID)
          || _.startsWith(item.capsuleSetId, PREVIEW_ID);

        if (item.itemType === ITEM_TYPES.SERIES) {
          if (anySeriesSelected || anyScalarsSelected) {
            if (selectedOrPreview) {
              // Some of the lines can be very light in gradient mode so the darkest one is used for the selected item
              if ($scope.isCapsuleTime && _.includes(
                [CapsuleTimeColorMode.SignalGradient, CapsuleTimeColorMode.ConditionGradient],
                sqTrendStore.capsuleTimeColorMode)) {
                color = _.chain(items as any[])
                  .filter({ isChildOf: item.isChildOf })
                  .filter(i => _.intersection(i.otherChildrenOf, item.otherChildrenOf).length > 0)
                  .minBy(i => tinycolor(i.color).getBrightness())
                  .get('color', color)
                  .value();
              }
            } else {
              color = tinycolor(color).setAlpha(ALPHA_UNSELECTED).toString();
            }
          }

          if ($scope.isCapsuleTime) {
            zones = zonesForCapsuleTime(item, color);
          }

          if (selectedOrPreview) {
            zIndex += Z_INDEX_BOOST;
          }

          if (!_.includes([SAMPLE_OPTIONS.LINE, SAMPLE_OPTIONS.BAR],
            _.get(item, 'sampleDisplayOption', SAMPLE_OPTIONS.LINE))) {
            if (item.sampleDisplayOption === SAMPLE_OPTIONS.LINE_AND_SAMPLE) {
              lineWidth = 0.5;
            } else {
              lineWidth = 0;
            }

            marker = enabledMarker;
            dashStyle = null;
          }
        } else if (_.includes(CAPSULE_TYPES, item.itemType)) {
          let dim = false;
          if (_.isNil(item.capsuleSetId) || item.capsuleSetId === editingId) {
            // Previewed capsules should be highly visible, but the dim must be applied with a selection
            dim = item.anyCapsulesSelected;
          } else if (anyCapsuleSeriesSelected && !selectedOrPreview) {
            // If another capsule series is selected, dim the capsule series including any individually selected
            // capsules by removing the highlighted zones
            dim = true;
            zones = [];
          } else if (anyCapsulesSelected) {
            // This capsule row or another capsule row has a selected capsule so we dim all the rows. The zone
            // property will inform highcharts where to draw the capsules in full color
            dim = true;
          }

          // uncertain capsules are created by overlaying a series with slightly shorter segments - this series is
          // always white. If the alpha of that white series gets set the capsule no longer looks uncertain - so we
          // ensure the alpha does not get changed
          if (dim && color !== '#fff') {
            color = tinycolor(color).setAlpha(ALPHA_CAPSULE_UNSELECTED).toString();
          }

          if (selectedOrPreview || item.anyCapsulesSelected) {
            zIndex += Z_INDEX_BOOST;
          }

          if (item.isAggregated) {
            shadow = _.assign({}, SHADOW_AGGREGATE, { color });
            lineWidth -= 2;
          }
        } else if (item.itemType === ITEM_TYPES.SCALAR) {
          if ((anySeriesSelected || anyScalarsSelected) && !selectedOrPreview) {
            color = tinycolor(color).setAlpha(ALPHA_UNSELECTED).toString();
          }

          if (selectedOrPreview) {
            zIndex += Z_INDEX_BOOST;
          }
        } else {
          throw new TypeError('Invalid Item Type: ' + item.itemType);
        }

        // Handle items with shaded areas
        if ((item.shadedAreaLower && item.shadedAreaUpper) || item.shadedAreaDirection) {
          zIndex = 0;
          graphType = item.shadedAreaDirection ? 'areaspline' : 'arearange';
          fillOpacity = _.get(item, 'fillOpacity', 0.2);
          color = tinycolor('#ccc').setAlpha(0.5).toString();

          if (item.shadedAreaDirection) {
            threshold = item.shadedAreaDirection === SHADED_AREA_DIRECTION.UP ? Infinity : -Infinity;
          }

          if ((anySeriesSelected || anyScalarsSelected) && !selectedOrPreview) {
            color = tinycolor(color).setAlpha(0).toString();
            fillOpacity = 5 * fillOpacity * ALPHA_UNSELECTED; // Make it lighter to represent deselected
          }
        }

        if (item.sampleDisplayOption === SAMPLE_OPTIONS.BAR) {
          customProperties = _.assign({}, barChartEssentials);
          if (item.lineWidth) {
            _.assign(customProperties, { pointWidth: item.lineWidth });
          }
        }

        // Ensure that uncertain portions of signal are greyed out when others are selected
        if (item.itemType === ITEM_TYPES.SERIES && item.color !== color && _.some(item.zones, 'dashStyle')) {
          zones = _.map(zones, zone => _.has(zone, 'color') ? _.assign({}, zone, { color }) : zone);
        }

        fillColor = tinycolor(fillColor).setAlpha(fillOpacity).toString();

        return _.assign({
          cursor: 'pointer'
        }, item, {
          zoneAxis: 'x',
          boostThreshold: 0,
          zones,
          type: graphType,
          threshold,
          fillColor,
          data,
          shadow,
          color,
          dashStyle,
          zIndex,
          lineWidth,
          marker
        }, customProperties);

      });
    }

    /**
     * Create the zones for a series in capsule time such that the series will be highlighted for the range of time
     * of the capsule that is interested in it. This allows the user to see the range of the series defined by the
     * capsule while being able to see data prior to and after, particularly when scrolling and zooming
     *
     * Update the existing zone list, if one exists, with the capsule time zones so that both display correctly.
     * In order to ensure correct display, return an array where all objects with a value also have the properties
     * color and dashStyle.
     *
     * @see http://api.highcharts.com/highcharts#plotOptions.series.zones
     * @param {Object} capsuleSeries - The series from a capsule that will display on the chart.
     * @param {string} color - The color that is being used for the item
     * @return {Object[]} The zones for the item.
     */
    function zonesForCapsuleTime(capsuleSeries, color) {
      const colorObject = tinycolor(color);
      const dimHide = $scope.isDimmed ? Math.min(ALPHA_DIMMED, colorObject.getAlpha()) : 0;
      const secondColor = colorObject.setAlpha(dimHide).toString('rgb');
      // Clone this so that we can update it.  Typically this is [{ value: ###### }, {dashStyle: 'dot'}]
      const existingZones = _.cloneDeep(capsuleSeries.zones);
      // New zones to be applied
      let capsuleZones = [{
        value: 0,
        color: secondColor
      }, {
        value: capsuleSeries.endTime - capsuleSeries.startTime,
        color
      }, {
        color: secondColor
      }];

      // If there are existing zones, they are modifying the dashStyle to show uncertainty
      // Since the first of these will only contain the value that defines the end of the default dashStyle,
      // we need to add the capsuleSeries dashStyle so that it is correctly updated in all zone objects prior to it
      // otherwise the uncertain dash style will be added incorrectly
      if (existingZones && existingZones.length > 0 && _.isUndefined(existingZones[0].dashStyle)) {
        existingZones[0].dashStyle = capsuleSeries.dashStyle;
      }

      // Collapse duplicates into one object and concatenate others to the capsuleZones array so we have a complete
      // array
      _.forEach(existingZones, function(zone: any) {
        const dupe = _.find(capsuleZones, ['value', zone.value]);
        if (dupe) {
          _.merge(dupe, zone);
        } else {
          capsuleZones = _.concat(capsuleZones, [zone]);
        }
      });

      // Sort the array by time value for Highcharts, those without a value correctly fall at the end
      const sortedZones = _.sortBy(_.compact(capsuleZones), ['value']);

      // Get the zones with the specified properties so that we can iterate over them and add the properties, as
      // needed, to the zones to be returned so that all zones with values also have a color and dashStyle property.
      const coloredZones = _.filter(sortedZones, 'color');
      const dashStyledZones = _.filter(sortedZones, 'dashStyle');

      // The zone objects that will be returned from this function
      // Clone the sortedZones as we can't update it as we're iterating over it
      const returnZones = _.cloneDeep(sortedZones);

      // A generic function to add a property to any object in the array prior to the given one that does not already
      // have that property defined, otherwise Highcharts sets any property not defined back to it's default
      const adjustZones = function(array, property) {

        _.forEach(array, function(zone) {
          const sortedZoneIndex = _.findIndex(sortedZones, function(sortedZone) {
            return _.isEqual(sortedZone, zone);
          });

          _.forEach(sortedZones, function(sortedZone, index) {
            if (_.isUndefined(returnZones[index][property]) && index < sortedZoneIndex) {
              returnZones[index][property] = zone[property];
            }
          });
        });
      };

      adjustZones(coloredZones, 'color');
      adjustZones(dashStyledZones, 'dashStyle');

      return returnZones;
    }

    /**
     * Updates the corresponding series with new options. Does not redraw the chart.
     *
     * @param {Array} items - The items with updated properties.
     */
    function updateSeries(items) {
      // NOTE: Because of the fact that Highcharts completely removes a series when update is called and the fact that
      // Highcharts immediately destroys the series even when redraw is set to false this results in a very ugly
      // flicker.
      // So for the time being we have to redraw immediately after the iteration (if there's more than one item) to
      // avoid the flicker.
      const redraw = _.get(items, 'length', 0) > 1;
      _.forEach(items, function(item: any) {
        const series = findChartSeries(item.id);
        // We call setData() separately from the update() call because passing false as the  fourth argument to
        // setData() prevents Highcharts from trying to modify the data array we pass in. There's no corresponding
        // argument for update().
        series.setData(item.data, false, false, false);
        series.update(_.assign({}, _.omit(item, ['data'])), false);
      });

      if (redraw) {
        chartRedraw();
      }
    }

    /**
     * Updates the visibility of the corresponding series based on autoDisabled. Does not redraw the chart.
     *
     * @param {Array} items - The items with updated visible property.
     */
    function updateSeriesVisibility(items) {
      _.forEach(items, function(item: any) {
        const series = findChartSeries(item.id);
        if (series) {
          series.setVisible(!item.autoDisabled, false);
        }
      });
    }

    /**
     * Updates the data points of the corresponding series. Does not redraw the chart.
     *
     * @param {Object[]} items - The items with updated data property.
     */
    function updateSeriesData(items: any[]) {
      _.forEach(items, (item) => {
        const series = findChartSeries(item.id);

        if (series) {
          series.setData(item.data, false, false, false);
        }
      });
    }

    /**
     * Updates the y-axis extremes of the corresponding series. Does not redraw the chart.
     *
     * @param {Array} items - The items with updated yAxisConfig property.
     */
    function updateSeriesYExtremes(items) {
      _.chain(items)
        .filter('yAxisConfig')
        .forEach((item: any) => {
          const axis = getItemYAxis(item);
          if (axis.logarithmic) {
            axis.setExtremes(Number(item.yAxisMin), Number(item.yAxisMax), false, false);
          } else {
            axis.setExtremes(item.yAxisConfig.min, item.yAxisConfig.max, false, false);
          }
        })
        .value();
    }

    /**
     * Updates the X extremes on the chart
     *
     * @param {boolean} [redraw] - Set to false to prevent redrawing the chart when finished updating extremes
     */
    function updateXExtremes(redraw: boolean = true) {
      // NOTE: when being called from the zoom setExtremes callback, the xAxis.setExtremes function is removed by
      // highcharts to avoid an infinite loop.
      if ($scope.chart && _.isFunction($scope.chart.xAxis[0].setExtremes)) {
        if (!$scope.isCapsuleTime) {
          $scope.chart.xAxis[0].setExtremes($scope.trendStart, $scope.trendEnd, false, false);
          $scope.$evalAsync(() => debouncedDrawCursors($scope.chart, capsuleLaneHeight));
          drawSelectedRegion({
            xMin: $scope.selectedRegion.min,
            xMax: $scope.selectedRegion.max,
            yMin: 0,
            yMax: 0
          });
          drawCapsuleRegion();
          positionCapsuleIcons();
          if ($scope.view === TREND_VIEWS.CHAIN) {
            updateLineBreaks();
          }
        } else {
          $scope.chart.xAxis[0].setExtremes(0 + $scope.capsuleTimeOffsets.lower,
            sqTrendSeriesStore.longestCapsuleSeriesDuration + $scope.capsuleTimeOffsets.upper, false, false);
        }

        if (redraw) {
          chartRedraw();
        }
      }
    }

    function updateYExtremes(axisExtremeChanges: AxisExtremeChange[]) {
      const newExtremes = _.map(axisExtremeChanges, changes => ({
        axisAlign: changes.oldExtremes.axisAlign,
        min: changes.oldExtremes.min + changes.changeInLow,
        max: changes.oldExtremes.max + changes.changeInHigh
      }));
      $scope.setYExtremes()(newExtremes);
    }

    /**
     * Gets the axis associated with an item
     *
     * @param {Object} item - The item to find
     * @param {String} item.id - The id of the item to find
     * @returns {object} The requested axis; or undefined if not found.
     */
    function getItemYAxis(item): any {
      const id = _.get(item, 'itemType') === ITEM_TYPES.CAPSULE ? sqLabels.getCapsuleAxisId(item.capsuleSetId) :
        `yAxis-${_.get(item, 'id')}`;
      return _.find($scope.chart.yAxis, { userOptions: { id } }) as any;
    }

    /**
     * Gets the item associated with an axis
     *
     * @param {Object} axis - The axis
     * @returns {object} The requested item; or undefined if not found.
     */
    function getAxisItem(axis): any {
      return _.find($scope.items, (item: any) =>
        axis.series && axis.series[0] && axis.series[0].userOptions.id === item.id);
    }

    /**
     * Updates the capsule time y-axis tick positions
     */
    function updateYAxisAlignment() {
      if ($scope.isCapsuleTime) {
        // NOTE: Because of the fact that Highcharts completely removes a series when update is called and the fact
        // that Highcharts immediately destroys the series even when redraw is set to false this results in a very
        // ugly flicker. So for the time being we have to use redraw = true (if there's more than one item) to
        // avoid the flicker. If aligned, set min and max tick positions individually for each axis to achieve
        // y-alignment
        const redraw = _.get($scope.chart, 'yAxis.length', 0) > 1;
        // Iterate through and update all y axes
        _.forEach($scope.chart.yAxis, function(axis: any) {

          // We don't want to update the plot band here
          if (axis.options.id === 'yAxis-' + PLOT_BAND) {
            return;
          }

          const isYAligned = $scope.capsuleAlignment.length > 0;
          const item = getAxisItem(axis);
          if (isYAligned) {
            // When a capsule-from-series item is added by the user, the yAlignment property won't be defined
            // until the server returns with the data. So just skip the axis update and it will get called
            // when the data arrives.
            if (!_.isEmpty(_.get(item, 'yAlignment', {}))) {
              axis.update({
                tickPositions: [item.yAlignment.chartMinY, item.yAlignment.chartMaxY],
                labels: { enabled: false },
                startOnTick: true,
                endOnTick: true
              }, false);
            }
          } else {
            // If not y-aligned, set tick positions and interval the same for all axes
            axis.update({
              tickPositions: item.isStringSeries ? _.map(item.stringEnum, 'key').sort() : undefined,
              labels: { enabled: true },
              startOnTick: false,
              endOnTick: false
            }, false);
          }
        });

        if (redraw) {
          chartRedraw();
        }
      }
    }

    /**
     * Adds new series to the chart. Does not redraw the chart.
     *
     * @param {Object[]} items - The items to add.
     */
    function addSeries(items) {
      _.forEach(items, function(item) {
        const newAxis = createYAxisDefinition(item);
        if (newAxis) {
          $scope.chart.addAxis(newAxis, false, false, false);
        }

        $scope.chart.addSeries(item, false, false);
      });
    }

    /**
     * Removes the corresponding series from the chart.
     *
     * @param {Object[]} items - The items to remove.
     */
    function removeSeries(items) {
      _.forEach(items, function(item: any) {
        let yAxisToRemove;
        const series = findChartSeries(item.id);

        if (!series) {
          return;
        }

        // If the series is alone on it's y axis, note it so we can find the axes and remove them.
        if (series.yAxis.series.length === 1) {
          yAxisToRemove = _.find($scope.chart.yAxis, function(axis: any) {
            return axis.series[0] === series;
          });
        }

        series.remove(false);

        // Highcharts doesn't clean up empty axes, so handle this if the axes are now empty
        // We have to identify them prior to the series being removed, but remove them after
        if (yAxisToRemove) {
          yAxisToRemove.remove(false);
        }
      });
    }

    /**
     * Handles a click event on the chart object. Used to drop cursors.
     *
     * @param {Event} e - The click event
     */
    function onChartClick(e) {
      if (e.shiftKey || e.type === 'contextmenu') {
        sqCursors.createCursor($scope.isCapsuleTime);
        return;
      }
    }

    /**
     * Handles the click event on a point.
     *
     * @param {Event} e - The click event
     * @param {bool} e.metaKey - Whether the 'meta' key was depressed
     * @param {bool} e.ctrlKey - Whether the 'control' key was depressed
     * @param {Object} e.point.series.options - Object representing the series clicked
     */
    function onPointClick(e) {
      if (e.shiftKey) {
        sqCursors.createCursor($scope.isCapsuleTime);
        return;
      }

      let item = e.point.series.options;
      let items = _.filter($scope.items, ['itemType', item.itemType]);

      if (_.includes(CAPSULE_TYPES, item.itemType)) {
        item = findClosestCapsule(e.chartX, e.chartY);
      }

      // In capsuleTime the selection status of the series always corresponds to the capsule parent because that is
      // what the user sees in the capsules panel.
      if ($scope.isCapsuleTime && item.childType === ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE) {
        item = sqTrendCapsuleStore.findItem(item.capsuleId);
        items = _.filter($scope.items, ['itemType', ITEM_TYPES.CAPSULE]);
      }

      if (item && !item.notFullyVisible) {
        $scope.$apply(function() {
          $scope.selectItems()(item, items, e);
        });
      }
    }

    /**
     * Given chartX and chartY coordinates searches all capsule y-axis and finds the y-axis. Once the y-axis is
     * determined, the chartX value is used to whittle down to the capsule the user clicked.
     *
     * @param {Number} chartX - The X position of the mouse relative to the chart in pixels
     * @param {Number} chartY - The Y position of the mouse relative to the chart in pixels
     * @return {Object|undefined} The capsule if found, else undefined
     */
    function findClosestCapsule(chartX, chartY) {
      const xValue = $scope.chart.xAxis[0].toValue(chartX);
      let yValue = null;
      const axis = _.find($scope.chart.yAxis, function(axis: any) {
        yValue = axis.toValue(chartY);
        return _.startsWith(axis.userOptions.id, TREND_TOP_Y_AXIS_ID) && _.inRange(yValue, axis.min,
          axis.max + 0.1);
      });

      if (!axis || !axis.series) {
        return undefined;
      }

      const axisY = axis.toValue(chartY) - axis.min;

      return _.chain(axis.series)
        .map((chartItem) => {
          const capsule = sqTrendCapsuleStore.findChartCapsule(chartItem.userOptions.id, xValue);
          if (capsule) {
            return [capsule, Math.abs(chartItem.data[0].plotY - axisY)];
          }
        })
        .compact()
        .reduce((closest, capsuleAndDistance) =>
          capsuleAndDistance[1] < closest[1] ? capsuleAndDistance : closest, [undefined, Infinity])
        .head()
        .value();
    }

    /**
     * Returns an array of series organized by how their y-axes should be displayed.
     *
     * @return {Object[]} An array of series, organized by how they should be displayed
     *  {Object} .primarySeries - Primary series for this axis. The y-axis for this series is the one that is
     *    displayed for all items in .series.
     *  {Object[]} .series - All series that occupy the same axis area and should all be updated together
     *  {Boolean} .hide - If true, the axis shouldn't be displayed
     */
    function getSeriesForYAxisInteraction(lane?) {
      let series = _.filter($scope.items,
        (item: any) => item.itemType === ITEM_TYPES.SERIES || item.itemType === ITEM_TYPES.SCALAR);

      if (!_.isUndefined(lane)) {
        series = _.filter(series, { lane });
      }

      if (series.length === 0) {
        return [{
          primarySeries: undefined,
          series
        }];
      }

      // for multiple series which have the same yAxisAlignment, ensure that we only show one axis
      const skippedSeriesIds = [];
      return _.transform(series, function(accum, singleSeries: any, index) {
        const remainingSeries = _.slice(series, index + 1);
        const matches = _.chain(remainingSeries)
          .filter(other => shareSameAxis([singleSeries, other]) && shareSameLane([singleSeries, other]))
          .forEach((match: any) => skippedSeriesIds.push(match.id))
          .value();

        if (!_.includes(skippedSeriesIds, singleSeries.id)) {
          accum.push({
            primarySeries: singleSeries,
            series: _.concat([singleSeries], matches),
            hide: false
          });
        }
      }, []);
    }

    /**
     * Helper function to determine if all the provided Series share the same lane.
     *
     * @param {[Object]} seriesList - Array of series items.
     * @returns {Boolean} true if the series share the same lane, false if not.
     */
    function shareSameLane(seriesList) {
      return _.chain(seriesList)
        .map('lane')
        .uniq()
        .value()
        .length === 1;
    }

    /**
     * Helper function to determine if all the provided Series share the same axis.
     *
     * @param {[Object]} seriesList - Array of series items.
     * @returns {Boolean} true if the series share the same axis, false if not.
     */
    function shareSameAxis(seriesList) {
      return _.chain(seriesList)
        .map('axisAlign')
        .uniq()
        .value()
        .length === 1;
    }

    /**
     * Manages the Y-Axis as well as ensures the lanes background is drawn properly.
     * If series that are allocated to the same lane share the same axis alignment only one of those series is
     * flagged as the "primary" series. The other series are accessible using the ".series" property.
     *
     * The y-axis labels are drawn by Highcharts so the required offsets are set as part of this process as well.
     */
    function manageYAxis() {
      let labelColor;
      const plotBandColors = {};
      const laneBuffer = sqUtilities.getLaneBuffer($scope.isCapsuleTime);
      const lanes = getDisplayedLanes();
      const laneHeight = getLaneHeight();
      let opposite = false;
      const seriesByAxis = getSeriesForYAxisInteraction();

      _.forEach(lanes, function(lane, idx) {
        const seriesInLane = _.filter(seriesByAxis, s => _.get(s.primarySeries, 'lane') === lane);

        const top = laneHeight * idx + laneBuffer * idx + capsuleLaneHeight;

        _.forEach(seriesInLane, function(s) {
          const axis = getItemYAxis(s.primarySeries);
          let visible = s.primarySeries.axisVisibility;

          // When updating a series, the addition of its preview series causes an update before the preview has an
          // axis
          if (_.isUndefined(axis)) {
            return;
          }

          if (s.series.length > 1) {
            const visibleSeries = _.chain(s.series)
              .filter({ axisVisibility: true })
              .map((item: any) => item.childType ? sqTrendDataHelper.findItemIn(TREND_STORES, item.isChildOf) : item)
              .uniqBy('id')
              .value();

            const allColorsSame = visibleSeries.length > 0
              ? _.every(visibleSeries, ['color', visibleSeries[0]['color']])
              : false;
            const hasCapsuleTimeColors = $scope.isCapsuleTime && _.includes(
              [CapsuleTimeColorMode.Rainbow, CapsuleTimeColorMode.ConditionGradient],
              sqTrendStore.capsuleTimeColorMode);
            if (allColorsSame && !hasCapsuleTimeColors) {
              labelColor = visibleSeries[0]['color'];
            } else {
              labelColor = DEFAULT_AXIS_LABEL_COLOR;
            }

            const temp = _.omitBy(s.series, tempSeries => tempSeries.id === s.primarySeries.id);
            _.forEach(temp, function(t) {
              const tempAxis = getItemYAxis(t);
              setAxisProperties(tempAxis, {
                visible: false,
                height: laneHeight,
                top
              });
            });
          } else {
            labelColor = s.primarySeries.color;
            if (!_.isFinite(_.get(s.primarySeries, 'yAxisConfig.min')) && !_.isFinite(
              _.get(s.primarySeries, 'yAxisConfig.max'))) {
              visible = false;
            }
          }

          if (!_.isUndefined(_.find(s.series as any[], signal => _.startsWith(signal.id, PREVIEW_ID)))) {
            plotBandColors[lane] = PREVIEW_HIGHLIGHT_COLOR;
          }

          opposite = s.primarySeries.rightAxis;
          setAxisProperties(axis, {
            visible,
            lineColor: labelColor,
            lineWidth: 1,
            labels: {
              enabled: visible,
              style: {
                color: labelColor
              },
              align: opposite ? 'left' : 'right',
              x: opposite ? 5 : -5
            },
            height: laneHeight,
            formatOptions: s.primarySeries.formatOptions || {},
            top,
            opposite,
            type: s.primarySeries.yAxisType
          });
        });
      });
      updatePlotBands(plotBandColors);
      manageLaneAxisLabels();
      drawSelectedRegion({
        xMin: $scope.selectedRegion.min,
        xMax: $scope.selectedRegion.max,
        yMin: 0,
        yMax: 0
      });
      drawCapsuleRegion();
    }

    /**
     * Clip signals to their lane so they can't be dragged into other lanes (CRAB-8895)
     *
     * This works by using the plotBand highlighting to create a clipRect for the lane and using it for all signals
     * within the lane. The clipRects are actually clipPaths[1] in the svg. All the signals can share the same
     * clipRect because highcharts uses the transform attribute[2] to move the svg path into position and the
     * clipping path is transformed with the data
     *
     * [1]: https://developer.mozilla.org/en-US/docs/Web/SVG/Element/clipPath
     * [2]: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/transform
     */
    function clipSignalsToLanes() {
      laneClipRect?.destroy?.();
      laneClipRect = $scope.chart.renderer.clipRect(0, 0, getLaneWidth(), getLaneHeight());

      _.forEach($scope.chart.series, (chartSeries) => {
        if (chartSeries.visible && _.get(chartSeries, 'userOptions.lane', false)
          && !_.isUndefined(chartSeries.group)) {
          chartSeries.group.clip(laneClipRect);
          chartSeries.markerGroup.clip(laneClipRect);
        }
      });
    }

    /**
     * Toggles between line and point display.
     *
     * @param {[Object]} items - An Array of store items that have changed.
     */
    function setPointsOnly(items) {
      const chartSeries = $scope.chart.series;
      _.forEach(items, function(item: any) {
        const seriesArray = _.filter(chartSeries, function(series: any) {
          return $scope.isCapsuleTime ? series.userOptions.isChildOf === item.isChildOf :
            series.userOptions.id === item.id;
        }) as any[];

        _.forEach(seriesArray, (series) => {
          if (item.sampleDisplayOption === SAMPLE_OPTIONS.BAR) {
            _.assign(series.options, barChartEssentials, { pointWidth: item.lineWidth });
          } else {
            series.options.type = 'line';
            if (item.sampleDisplayOption !== SAMPLE_OPTIONS.LINE) {
              if (item.sampleDisplayOption === SAMPLE_OPTIONS.LINE_AND_SAMPLE) {
                series.options.lineWidth = 0.5;
              } else {
                series.options.lineWidth = 0;
              }

              series.options.marker = enabledMarker;
            } else {
              series.options.lineWidth = LINE_WIDTHS[item.itemType];
              series.options.marker = disabledMarker;
            }
          }
          series.update(series.options, false);
        });
      });
    }

    /**
     * Get the current lane width for the chart, chart width - yaxis width
     *
     * @return {Number|undefined} If a number, the pixels currently dedicated to plotting data; if undefined,
     *   chart is not created.
     */
    function getLaneWidth() {
      if ($scope.chart && $scope.chartElement) {
        return $scope.chart.clipBox.width;
      } else {
        return undefined;
      }
    }

    /**
     * Sets the label visibility and layout of a given axis. Does not redraw the chart.
     *
     * @param {Object} axis - The axis label to be updated
     * @param {Object} properties - Object containing the properties and their new values. Properties are merged
     *   with existing properties or the axis.
     */
    function setAxisProperties(axis, properties) {
      if (axis) {
        axis.update(properties, false);
      }
    }

    function updateXRange(changeInLeft, changeInRight) {
      if (!$scope.isCapsuleTime) {
        $scope.$apply(function() {
          sqDurationActions.displayRange.updateTimes($scope.trendStart + changeInLeft,
            $scope.trendEnd + changeInRight);
        });
      } else {
        $scope.$apply(function() {
          sqTrendActions.setCapsuleTimeOffsets($scope.capsuleTimeOffsets.lower + changeInLeft,
            $scope.capsuleTimeOffsets.upper + changeInRight);
        });
      }
    }

    function getYAxesUnderCursor(chartX, chartY) {
      const ymouse = chartY - capsuleLaneHeight;
      const lanes = getDisplayedLanes();
      const laneHeight = getLaneHeight() + sqUtilities.getLaneBuffer($scope.isCapsuleTime);
      // determine what the index of the lane for the item is so we can calculate the y-offset:
      const lane = lanes[Math.floor(ymouse / laneHeight)];
      const seriesByYAxis = getSeriesForYAxisInteraction(lane);
      const scalingRects = computeYAxisScaleRectangles(seriesByYAxis);

      return _.chain(scalingRects as any[])
        .filter(rect => sqUtilities.pointInRectangle(chartX, chartY, rect.x, rect.y, rect.x + rect.width,
          rect.y + rect.height))
        .flatMap(function(rect) {
          // for each rect, return all series axes
          const seriesForAxis = getAxisItem(rect.axis);
          const axisWithSeries = _.find(seriesByYAxis, function(singleSeriesAxis) {
            return _.some(singleSeriesAxis.series, ['id', seriesForAxis.id]);
          });

          return axisWithSeries.series;
        })
        .map(getItemYAxis)
        .reject('options.seeqDisallowZoom')
        .value();
    }

    /**
     * Computes the position and size of the y axis scale regions that allow scroll and zoom.
     * If more than one y-axis is visible, each is returned with its own bounding area.
     *
     * @param {Object} seriesByLane - Object representing the Series in a lane.
     * @param {Object} seriesByLane.primarySeries - the primary series Object (in case axis are on the same scale)
     * @param {[Object]} seriesByLane.series - Array of all Series objects assigned to that y-Axis
     * @return {Object[]} Returns an array of objects with id, x, y, width and height properties.
     */
    function computeYAxisScaleRectangles(seriesByLane) {
      let leftAxes, rightAxes;
      let boundaryBoxesLeft, boundaryBoxesRight;

      if (!$scope.chart || !$scope.chart.yAxis || !$scope.chart.yAxis.length) {
        return [];
      }

      // If every series is hidden, then return all axes with the area of the entire y-axis region
      if (_.every(seriesByLane, ['axisVisibility', false])) {
        return _.chain(seriesByLane)
          .map('primarySeries')
          .thru(_.flatten)
          .map(getItemYAxis)
          .map(function(yaxis) {
            return {
              x: 0,
              y: 0,
              width: $scope.chart.margin[3],
              height: $scope.chart.chartHeight,
              axis: yaxis
            };
          })
          .value();
      }

      /**
       * Returns all the chart axes that match the given opposite value. It's used to get all the axes on the
       * right or left.
       *
       * @param {Boolean} opposite - true if the axis should be on the right, false (or undefined) if they are on the
       *   left
       * @returns {Object [Array]} of Highcharts Axis Objects
       */
      function getAxis(opposite) {
        return _.chain(seriesByLane)
          .map('primarySeries')
          .map(getItemYAxis)
          .filter(axis =>
            _.get(axis, 'userOptions.visible') && (_.get(axis, 'userOptions.opposite') === opposite
            || _.get(axis, 'userOptions.opposite') === undefined))
          .orderBy(['offset'], [opposite ? 'asc' : 'desc'])
          .value();
      }

      // Get the y-axis for each series
      leftAxes = getAxis(false);
      rightAxes = getAxis(true);

      /**
       * Returns the axis boundary boxes for a given array of axis objects
       *
       * @param {Boolean} opposite - true if the axes array contains axis that are on the right, false if they are
       *   on the left
       * @param {Object[]} axes - Array of Highcharts Axis Objects
       * @returns {Object [Array]} defining a y-axis boundary
       */
      function getBoundaryBoxes(opposite, axes) {
        let xOffset = opposite ? $scope.chart.plotLeft + $scope.chart.plotWidth : $scope.chart.plotLeft;
        let prevWidth = 0;
        const laneHeight = getLaneHeight();
        const laneBufferHeight = sqUtilities.getLaneBuffer($scope.isCapsuleTime);
        return _.map(axes, function(yaxis: any) {
          const item = getAxisItem(yaxis);
          let width = yaxis.userOptions.axisWidth;
          const idx = getDisplayedLanes().indexOf(item.lane);
          const yOffset = idx * (laneHeight + laneBufferHeight) + capsuleLaneHeight;

          if (!_.isFinite(width)) {
            width = Y_AXIS_MIN_TOTAL_WIDTH;
          }

          if (opposite) {
            // for axis on the right side we need to keep track of the width of the previous axes to ensure we
            // calculate the correct starting point (the axis x position for the second axis on the right
            // corresponds to the left x position of the chart plotbox + the width of the first axis (that's what
            // prevWidth is keeping track of, the accumulated width of the axis before the current one)
            xOffset = xOffset + prevWidth;
            prevWidth = width;
          } else {
            xOffset = xOffset - width;
          }

          return {
            x: xOffset,
            y: yOffset,
            width,
            height: laneHeight,
            axis: yaxis
          };
        });
      }

      boundaryBoxesLeft = getBoundaryBoxes(false, leftAxes);
      boundaryBoxesRight = getBoundaryBoxes(true, rightAxes);

      return boundaryBoxesLeft.concat(boundaryBoxesRight);
    }

    /**
     * Gets the list of lanes ordered from top to bottom based on the items passed to the chart. This is used in
     * favor of sqTrendStore.uniqueLanes because the items may be filtered down before being passed to the chart -
     * we want to avoid rendering empty lanes.
     */
    function getDisplayedLanes(): number[] {
      return sqUtilities.getUniqueOrderedValuesByProperty($scope.items, 'lane', sqTrendStore.lanes) as number[];
    }

    /**
     * Find the chart series object that matches the id specified
     *
     * @param  {String} id - ID value for which to search
     * @return {Object} Chart series object; null if not found
     */
    function findChartSeries(id): Highcharts.Series {
      return _.find($scope.chart.series as Highcharts.Series[], {
        options: {
          id
        }
      });
    }

    /**
     * Trigger a chart redraw. This was pulled out to its own method so that it is easy to add logging and timing
     * around all of the redraws for the chart while debugging.
     */
    function chartRedraw() {
      if ($scope.chart) {
        $scope.chart.redraw();
      }
    }
  }
}

