import angular from 'angular';
import { ModalActions } from '@/services/modal.actions';
import { ModalStore } from '@/services/modal.store';
import { IModalInstanceService, IModalService, IModalSettings } from 'angular-ui-bootstrap';

export type ModalService = ReturnType<typeof sqModal>;
angular.module('Sq.Services.Modal', []).service('sqModal', sqModal);

/**
 * This service is for making sure that multiple modals are not open at the same time. When opening a modal, use the
 * {@link safeOpen} method. When registering an action to execute after the currently open one has closed, use
 * {@link execWhenModalClosed}.
 */
function sqModal(
  $uibModal: IModalService,
  $q: ng.IQService,
  sqModalActions: ModalActions,
  sqModalStore: ModalStore
) {
  const service = {
    open,
    execWhenModalClosed,
    safeOpen
  };

  return service;

  /**
   * This function currently assumes that there is only one modal open at a time. For that reason, use
   * {@code safeOpen} instead.
   *
   * @param options - settings that are passed into {@link $uibModal#open}
   * @return an instance of the bootstrap modal
   * @deprecated
   */
  function open(options: IModalSettings): IModalInstanceService {
    return execOpen(options);
  }

  /**
   * Open a modal after the open modal (if there is a modal open) is closed.
   *
   * @param options - settings that are passed into {@link $uibModal#open}
   */
  function safeOpen(options: IModalSettings) {
    return execWhenModalClosed(() => execOpen(options));
  }

  /**
   * Register a callback to run after the open modal (if there is a modal open) is closed.
   *
   * @param callback - the callback to run
   */
  function execWhenModalClosed<T>(callback: () => T): angular.IPromise<T> {
    if (sqModalStore.isModalOpen) {
      return sqModalStore.currentOpenModal.closed.finally(callback);
    } else {
      return $q.resolve(callback());
    }
  }

  /**
   * Perform the opening of the modal.
   *
   * @param options - settings that are passed into {@link $uibModal#open}
   */
  function execOpen(options: IModalSettings): IModalInstanceService {
    const modalInstance = $uibModal.open(options);
    sqModalActions.setOpenModal(modalInstance);
    modalInstance.closed.finally(sqModalActions.clearOpenModal);
    return modalInstance;
  }
}
