import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { getFormFieldProps } from '@/hybrid/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import classNames from 'classnames';
import { StatisticSelector, StoredStatistic } from '@/hybrid/tools/StatisticSelector.molecule';

export interface StatisticSelectorIF extends ValidatingFormComponent <any> {
  component: 'StatisticSelectorFormComponent';
  item: any;
  outputType: string[];
  value: StoredStatistic;
  onChange: (statistic: StoredStatistic) => void;
  onValidate?: (isValid: boolean) => void;
  onBlur?: any;
  formattedOptions?: boolean;
  className?: string;
  placeholder?: string;
  testId?: string;
  appendToBody?: boolean;
  disabled?: boolean;
  fixedWidth?: boolean;
  wrapperClasses?: string;
  isRequired: boolean;
}

export const StatisticSelectorFormComponent: React.FunctionComponent<StatisticSelectorIF> = (props) => {
  const { name, validation, value, onChange, testId, fixedWidth = true } = props;
  const defaultValidation = value => _.isEmpty(value);
  const formState = useForm().getState();
  return <div className={classNames('mb8', { 'width-100percent': fixedWidth })} data-testid={testId}>
    <Field name={name} validate={validation || defaultValidation}>
      {({ input, meta }) => (
        <StatisticSelector
          onSelectStatistic={onChange}
          statistic={value}
          {...getFormFieldProps(formState, input, meta, props)}
          insideModal={true}
        />
      )}
    </Field>
  </div>;
};

