import _ from 'lodash';
import angular from 'angular';
import moment from 'moment-timezone';
import HttpCodes from 'http-status-codes';
import { StateSynchronizerService } from '@/services/stateSynchronizer.service';
import { TrendActions } from '@/trendData/trend.actions';
import { DurationActions } from '@/trendData/duration.actions';
import { NotificationsService } from '@/services/notifications.service';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { DateTimeService } from '@/datetime/dateTime.service';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { WorkbookStore } from '@/workbook/workbook.store';
import { WorkstepsStore } from '@/worksteps/worksteps.store';
import { LoggerService } from '@/services/logger.service';
import { RedactionService } from '@/services/redaction.service';
import { ItemsApi } from 'sdk/api/ItemsApi';
import { ITEM_TYPES } from '@/trendData/trendData.module';
import { LINK_TYPE } from '@/annotation/annotation.module';
import { WorkstepsActions } from '@/worksteps/worksteps.actions';
import { JOURNAL_PREFIX_PATH } from "@/main/app.constants";

/**
 * Creates and executes Seeq journal links.
 */
angular.module('Sq.Annotation').factory('sqJournalLink', sqJournalLink);

export type JournalLinkService = ReturnType<typeof sqJournalLink>;

function sqJournalLink(
  $state: ng.ui.IStateService,
  $injector: ng.auto.IInjectorService,
  sqWorkstepsActions: WorkstepsActions,
  sqDurationActions: DurationActions,
  sqNotifications: NotificationsService,
  sqWorksheetStore: WorksheetStore,
  sqDateTime: DateTimeService,
  sqTrendCapsuleSetStore: TrendCapsuleSetStore,
  sqWorkbookStore: WorkbookStore,
  sqWorkstepsStore: WorkstepsStore,
  sqLogger: LoggerService,
  sqRedaction: RedactionService,
  sqItemsApi: ItemsApi
) {
  const service = {
    executeLinkAction,
    createItemLink,
    createCapsuleLink,
    createTimeRangeLink,
    createWorkstepLink,
    createItemLinkName,
    replaceLinkName
  };

  return service;

  /**
   * Executes a link action
   *
   * @param {Object} params - an object container
   * @param {String} [params.item] - an item ID
   * @param {String} [params.start] - a start time in UTC milliseconds
   * @param {String} [params.end] - an end time in UTC milliseconds
   * @param {String} [params.workbook] - a workbook ID
   * @param {String} [params.worksheet] - a worksheet ID
   * @param {String} [params.workstep] - a workstep ID
   */
  function executeLinkAction(params) {
    const sqStateSynchronizer = $injector.get<StateSynchronizerService>('sqStateSynchronizer');
    const sqTrendActions = $injector.get<TrendActions>('sqTrendActions');

    if (params.item) {
      sqItemsApi.getItemAndAllProperties({ id: params.item })
        .then(({ data: item }) => sqTrendActions.addItem(item))
        .catch((response) => {
          if (sqRedaction.isForbidden(response)) {
            // show warning notification so the user knows the action is not allowed because of insufficient permissions
            sqNotifications.warn(_.get(response, 'data.statusMessage'));
          } else {
            sqNotifications.apiError(response);
          }
        });
    }

    if (params.start && params.end) {
      sqDurationActions.displayRange.updateTimes(+params.start, +params.end);
    }

    if (params.workbook && params.worksheet && params.workstep) {
      sqStateSynchronizer.setLoadingWorksheet(params.workbook, params.worksheet);
      sqWorkstepsActions.get(params.workbook, params.worksheet, params.workstep, true)
        .catch((response) => {
          if (_.get(response, 'status') === HttpCodes.NOT_FOUND) {
            sqNotifications.warnTranslate('JOURNAL.ENTRY.LINKED_WORKSTEP_GONE');
          }
        })
        .then(workstep => sqStateSynchronizer.rehydrate(_.get(workstep, 'current.state'),
          { workbookId: params.workbook, worksheetId: params.worksheet }))
        .finally(() => {
          sqStateSynchronizer.unsetLoadingWorksheet();
        });
    }
  }

  /**
   * Creates an item link
   *
   * @param {Object} item - an item object container
   * @param {String} item.id - an ID
   * @param {String} item.name - a name
   * @param {String} item.itemType - an ITEM_TYPES
   * @param {String} [item.assets[0].name] - an optional asset name
   * @returns {String} an item link
   */
  function createItemLink(item) {
    const type = {
      [ITEM_TYPES.SERIES]: LINK_TYPE.SIGNAL,
      [ITEM_TYPES.SCALAR]: LINK_TYPE.SIGNAL,
      [ITEM_TYPES.CAPSULE_SET]: LINK_TYPE.CONDITION,
      [ITEM_TYPES.TABLE]: LINK_TYPE.TABLE,
      [ITEM_TYPES.METRIC]: LINK_TYPE.METRIC
    }[item.itemType];

    if (_.isNil(type)) {
      sqLogger.error(`itemType: ${item.itemType} has no matching LINK_TYPE`);
    }

    const name = createItemLinkName(item);
    return !type ? '' : `<a href="${JOURNAL_PREFIX_PATH}?type=${type}&item=${item.id}">${name}</a>`;
  }

  /**
   * Creates a condition link
   *
   * @param {String} conditionId - an ID
   * @param {Number} start - UTC Unix start time in milliseconds
   * @param {Number} end - UTC Unix end time in milliseconds
   * @returns {{name: string, link: string}} an object containing the link name and the link
   */
  function createCapsuleLink(conditionId, start, end) {
    const startLabel = sqDateTime.formatTime(start, sqWorksheetStore.timezone);
    const conditionName = sqTrendCapsuleSetStore.findItem(conditionId).name;
    const name = conditionName + ' (' + startLabel + ')';
    return {
      name,
      link: '<a href="'+ JOURNAL_PREFIX_PATH +
        '?type=' + LINK_TYPE.CAPSULE +
        '&item=' + conditionId +
        '&start=' + start +
        '&end=' + end + '">' + name + '</a>'
    };
  }

  /**
   * Creates a time range link
   *
   * @param {String} label - a label for time range display (e.g. "Display Range")
   * @param {Number} start - UTC Unix start time in milliseconds
   * @param {Number} end - UTC Unix end time in milliseconds
   * @returns {{name: string, link: string}} an object containing the link name and the link
   */
  function createTimeRangeLink(label, start, end) {
    const timeRangeLabel = sqDateTime.formatTime(start, sqWorksheetStore.timezone) + ' - ' +
      sqDateTime.formatTime(end, sqWorksheetStore.timezone);
    const name = label + ' (' + timeRangeLabel + ')';
    return {
      name,
      link: '<a href="'+ JOURNAL_PREFIX_PATH +
        '?type=' + LINK_TYPE.RANGE +
        '&start=' + start +
        '&end=' + end + '">' + timeRangeLabel + '</a>'
    };
  }

  /**
   * Creates a workstep link based on the current workstep
   *
   * @returns {{name: string, link: string}} an object containing the link name and the link
   */
  function createWorkstepLink() {
    const name = sqWorkbookStore.name + ' - ' + sqWorkbookStore.getWorksheetName($state.params.worksheetId) +
      ' (' + sqDateTime.formatTime(moment.utc().valueOf(), sqWorksheetStore.timezone) + ')';
    return {
      name,
      link: '<a href="'+ JOURNAL_PREFIX_PATH +
        '?type=' + LINK_TYPE.WORKSTEP +
        '&workbook=' + $state.params.workbookId +
        '&worksheet=' + $state.params.worksheetId +
        '&workstep=' + sqWorkstepsStore.current.id + '">' + name + '</a>'
    };
  }

  /**
   * Creates a name for an item link
   *
   * @param {Object} item - an item object container
   * @param {String} item.name - a name
   * @param {String} [item.assets[0].name] - an optional asset name
   * @returns {String} an item link name
   */
  function createItemLinkName(item) {
    return item.name + (item.assets && item.assets.length ? ' (' + item.assets[0].name + ')' : '');
  }

  /**
   * Replaces the current name of Seeq link with another name
   *
   * @param {String} link - a Seeq link
   * @param {String} name - the new name for the Seeq link
   * @returns {String} a Seeq link with the name replaced
   */
  function replaceLinkName(link, name) {
    return link.replace(/(^<.*?>)(.*)(<.*?>$)/g, '$1' + name + '$3');
  }
}
