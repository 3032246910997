import angular from 'angular';
import _ from 'lodash';
import bind from 'class-autobind-decorator';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { TREND_STORES } from '@/trendData/trendData.module';

/**
 * This class consolidates condition auto grouping logic into a service that can be injected and called where needed.
 * Auto grouping extends the existing Group capability by automatically grouping signals in the details pane with
 * conditions in the details pane if they share the same parent asset.
 */
@bind
export class AutoGroupService {
  constructor(
    private $injector: ng.auto.IInjectorService,
    private sqWorksheetStore: WorksheetStore,
    private sqTrendSeriesStore: TrendSeriesStore,
    private sqTrendCapsuleSetStore: TrendCapsuleSetStore,
    private sqTrendDataHelper: TrendDataHelperService
  ) { }

  /**
   * Auto groups signals under conditions when in capsule group mode
   */
  evaluate() {
    this.autoGroupSignals({});
  }

  /**
   * Adds a signal to a condition group when in capsule group mode
   */
  addSignal(id: string) {
    this.autoGroupSignals({
      signals: [this.sqTrendDataHelper.findItemIn(TREND_STORES, id)],
      allowAddToPopulatedGroup: true
    });
  }

  /**
   * Adds a condition group and groups existing signals when in capsule group mode
   */
  addCondition(id: string) {
    this.autoGroupSignals({
      conditions: [this.sqTrendDataHelper.findItemIn([this.sqTrendCapsuleSetStore], id)],
      allowAddToPopulatedGroup: true
    });
  }

  private autoGroupSignals({
    signals = this.sqTrendSeriesStore.nonCapsuleSeriesAndPreview,
    conditions = this.sqTrendCapsuleSetStore.items,
    allowAddToPopulatedGroup = false
  }: {
    signals?: { id: string, assets: { id: string }[] }[],
    conditions?: { id: string, assets: { id: string }[] }[],
    allowAddToPopulatedGroup?: boolean
  }) {
    if (this.sqWorksheetStore.capsuleGroupMode) {
      const sqWorksheetActions = this.$injector.get<WorksheetActions>('sqWorksheetActions');

      _.forEach(conditions, (condition) => {
        const groupHasNoSignals = _.isEmpty(this.sqWorksheetStore.conditionToSeriesGrouping[condition.id]);

        if (allowAddToPopulatedGroup || groupHasNoSignals) {
          _.forEach(signals, (signal) => {
            const signalAndGroupShareAsset = _.intersectionBy(signal?.assets, condition?.assets, 'id').length > 0;
            const notAlreadyAdded =
              !_.includes(this.sqWorksheetStore.conditionToSeriesGrouping[condition.id], signal.id);

            if (signalAndGroupShareAsset && notAlreadyAdded) {
              sqWorksheetActions.groupSignalToCondition(signal.id, condition.id);
            }
          });
        }
      });
    }
  }
}

angular.module('Sq.Services.Utilities').service('sqAutoGroup', AutoGroupService);
