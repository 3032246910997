/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class AgentsApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Archive an agent
   * @param {string} agentName - The name of the agent. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status/{detailsLevel}
   */
  public archiveAgent(
    {
      agentName
    } : {
      agentName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/agents/{agentName}'
        .replace('{agentName}', encodeURIComponent(String(agentName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Archive a connection
   * @param {string} agentName - The name of the agent. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status/{detailsLevel}
   * @param {string} connectorName - The name of the connector. The connector name can be obtained from the &#39;Connector&#39; array in the response of &#39;GET / agents / {agentName}&#39;
   * @param {string} connectionName - The name of the connection. The connection name can be obtained from the &#39;Connections&#39; array in the response of &#39;GET /agents/{agentName}/connectors/{connectorName}&#39;
   */
  public archiveConnection(
    {
      agentName,
      connectorName,
      connectionName
    } : {
      agentName: string,
      connectorName: string,
      connectionName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(connectorName)) {
      throw new Error("'connectorName' parameter required");
    }

    if (isNil(connectionName)) {
      throw new Error("'connectionName' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/agents/{agentName}/connectors/{connectorName}/connections/{connectionName}'
        .replace('{agentName}', encodeURIComponent(String(agentName)))
        .replace('{connectorName}', encodeURIComponent(String(connectorName)))
        .replace('{connectionName}', encodeURIComponent(String(connectionName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Archive a connector
   * @param {string} agentName - The name of the agent. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status/{detailsLevel}
   * @param {string} connectorName - The name of the connector. The connector name can be obtained from the &#39;Connector&#39; array in the response of &#39;GET / agents / {agentName}&#39;
   */
  public archiveConnector(
    {
      agentName,
      connectorName
    } : {
      agentName: string,
      connectorName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(connectorName)) {
      throw new Error("'connectorName' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/agents/{agentName}/connectors/{connectorName}'
        .replace('{agentName}', encodeURIComponent(String(agentName)))
        .replace('{connectorName}', encodeURIComponent(String(connectorName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Get a list of available installers on this Seeq
   */
  public configuredDirectives(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/agents/remote-updates/configuredDirectives',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ConfiguredDirectivesOutputV1>;
  }

  /**
   * 
   * @summary Create or update an agent
   * @param {string} agentName - Name of the agent to create or update. For update, the name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status/{detailsLevel}
   * @param {models.AgentInputV1} body - Agent information
   */
  public createOrUpdateAgent(
    body: models.AgentInputV1,
    {
      agentName,
    } : {
      agentName: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/agents/{agentName}'
        .replace('{agentName}', encodeURIComponent(String(agentName))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AgentOutputV1>;
  }

  /**
   * 
   * @summary Create or update a connection
   * @param {string} agentName - Name of the agent where the connector resides. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status/{detailsLevel}
   * @param {string} connectorName - Name of the connector where the connection resides. The connector name can be obtained from the &#39;Connector&#39; array in the response of &#39;GET / agents / {agentName}&#39;
   * @param {string} connectionName - Name of the connection to create or update. For update, the connection name can be obtained from the &#39;Connections&#39; array in the response of &#39;GET /agents/{agentName}/connectors/{connectorName}&#39;
   * @param {models.ConnectionInputV1} body - Connection information
   */
  public createOrUpdateConnection(
    body: models.ConnectionInputV1,
    {
      agentName,
      connectorName,
      connectionName,
    } : {
      agentName: string,
      connectorName: string,
      connectionName: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(connectorName)) {
      throw new Error("'connectorName' parameter required");
    }

    if (isNil(connectionName)) {
      throw new Error("'connectionName' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/agents/{agentName}/connectors/{connectorName}/connections/{connectionName}'
        .replace('{agentName}', encodeURIComponent(String(agentName)))
        .replace('{connectorName}', encodeURIComponent(String(connectorName)))
        .replace('{connectionName}', encodeURIComponent(String(connectionName))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ConnectionOutputV1>;
  }

  /**
   * 
   * @summary Create or update a connector
   * @param {string} agentName - Name of the agent where connector resides. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status/{detailsLevel}
   * @param {string} connectorName - Name of the connector to create or update. For update, the connector name can be obtained from the &#39;Connector&#39; array in the response of &#39;GET / agents / {agentName}&#39;
   * @param {models.ConnectorInputV1} body - Connector information
   */
  public createOrUpdateConnector(
    body: models.ConnectorInputV1,
    {
      agentName,
      connectorName,
    } : {
      agentName: string,
      connectorName: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(connectorName)) {
      throw new Error("'connectorName' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/agents/{agentName}/connectors/{connectorName}'
        .replace('{agentName}', encodeURIComponent(String(agentName)))
        .replace('{connectorName}', encodeURIComponent(String(connectorName))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ConnectorOutputV1>;
  }

  /**
   * 
   * @summary Records timestamp for updated directive
   * @param {models.DirectiveUpdatedInputV1} body - Directive status
   */
  public directiveUpdated(
    body: models.DirectiveUpdatedInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/agents/remote-updates/directiveUpdated',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<{}>;
  }

  /**
   * 
   * @summary Get an agent and its configuration
   * @param {string} agentName - Name of the agent to get. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status/{detailsLevel}
   */
  public getAgent(
    {
      agentName
    } : {
      agentName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/agents/{agentName}'
        .replace('{agentName}', encodeURIComponent(String(agentName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AgentOutputV1>;
  }

  /**
   * 
   * @summary Get the status of every connection in every agent that the user has access to
   */
  public getAgentStatus(
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/agents/status',
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<Array<models.AgentStatusV1>>;
  }

  /**
   * 
   * @summary Get a connection and its configuration
   * @param {string} agentName - Name of the agent. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status/{detailsLevel}
   * @param {string} connectorName - Name of the connector to get. The connector name can be obtained from the &#39;Connector&#39; array in the response of &#39;GET / agents / {agentName}&#39;
   * @param {string} connectionName - Name of the connection to get. The connection name can be obtained from the &#39;Connections&#39; array in the response of &#39;GET /agents/{agentName}/connectors/{connectorName}&#39;
   */
  public getConnection(
    {
      agentName,
      connectorName,
      connectionName
    } : {
      agentName: string,
      connectorName: string,
      connectionName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(connectorName)) {
      throw new Error("'connectorName' parameter required");
    }

    if (isNil(connectionName)) {
      throw new Error("'connectionName' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/agents/{agentName}/connectors/{connectorName}/connections/{connectionName}'
        .replace('{agentName}', encodeURIComponent(String(agentName)))
        .replace('{connectorName}', encodeURIComponent(String(connectorName)))
        .replace('{connectionName}', encodeURIComponent(String(connectionName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ConnectionOutputV1>;
  }

  /**
   * 
   * @summary Get a connector and its configuration
   * @param {string} agentName - Name of the agent. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status/{detailsLevel}
   * @param {string} connectorName - Name of the connector to get. The connector name can be obtained from the &#39;Connectors&#39; array in the response of &#39;GET / agents / {agentName}&#39;
   */
  public getConnector(
    {
      agentName,
      connectorName
    } : {
      agentName: string,
      connectorName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(connectorName)) {
      throw new Error("'connectorName' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/agents/{agentName}/connectors/{connectorName}'
        .replace('{agentName}', encodeURIComponent(String(agentName)))
        .replace('{connectorName}', encodeURIComponent(String(connectorName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ConnectorOutputV1>;
  }

  /**
   * 
   * @summary Get the status of every connection for every datasource that the user has access to
   * @param {string} detailsLevel - The level of details. One of &#39;Counts&#39;, &#39;Summary&#39; or &#39;Complete&#39;
   */
  public getDatasourcesStatus(
    {
      detailsLevel
    } : {
      detailsLevel: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(detailsLevel)) {
      throw new Error("'detailsLevel' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/agents/datasources-status/{detailsLevel}'
        .replace('{detailsLevel}', encodeURIComponent(String(detailsLevel))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.DatasourcesStatusOutputV1>;
  }

  /**
   * 
   * @summary Get update directives for the remote agent
   * @param {string} machineName - Name of the remote agent machine to get update directives
   */
  public getRemoteUpdateDirectives(
    {
      machineName
    } : {
      machineName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(machineName)) {
      throw new Error("'machineName' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/agents/remote-updates/{machineName}/directives'
        .replace('{machineName}', encodeURIComponent(String(machineName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.RemoteAgentDirectivesOutputV1>;
  }

  /**
   * 
   * @summary Request indexing of the given datasource.
   * @param {string} datasourceClass - Datasource class of the connection
   * @param {string} datasourceId - Datasource ID of the connection
   * @param {models.IndexingParametersInputV1} body - IndexingParameters
   */
  public index(
    body: models.IndexingParametersInputV1,
    {
      datasourceClass,
      datasourceId,
    } : {
      datasourceClass: string,
      datasourceId: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(datasourceClass)) {
      throw new Error("'datasourceClass' parameter required");
    }

    if (isNil(datasourceId)) {
      throw new Error("'datasourceId' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/agents/{datasourceClass}/{datasourceId}/index'
        .replace('{datasourceClass}', encodeURIComponent(String(datasourceClass)))
        .replace('{datasourceId}', encodeURIComponent(String(datasourceId))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * Should only be called by agents
   * @summary Post the status of every connection in an agent
   * @param {models.AgentStatusV1} body - Connection status from a single agent
   */
  public postAgentStatus(
    body: models.AgentStatusV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/agents/status',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<{}>;
  }

  /**
   * 
   * @summary Restart an agent
   * @param {string} agentName - Name of the agent to restart. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status/{detailsLevel}
   */
  public restartAgent(
    {
      agentName
    } : {
      agentName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/agents/{agentName}/restart'
        .replace('{agentName}', encodeURIComponent(String(agentName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Restart a connector
   * @param {string} agentName - Name of the agent. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status/{detailsLevel}
   * @param {string} connectorName - Name of the connector to restart. The connector name can be obtained from the &#39;Connector&#39; array in the response of &#39;GET / agents / {agentName}&#39;
   */
  public restartConnector(
    {
      agentName,
      connectorName
    } : {
      agentName: string,
      connectorName: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(connectorName)) {
      throw new Error("'connectorName' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/agents/{agentName}/connectors/{connectorName}/restart'
        .replace('{agentName}', encodeURIComponent(String(agentName)))
        .replace('{connectorName}', encodeURIComponent(String(connectorName))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.StatusMessageBase>;
  }

  /**
   * 
   * @summary Create or update status of an agent-machine for remote-updates
   * @param {string} machineName - Name of the remote agent machine to create or update with new status
   * @param {models.RemoteAgentStatusInputV1} body - Remote agent status
   */
  public updateRemoteAgentStatus(
    body: models.RemoteAgentStatusInputV1,
    {
      machineName,
    } : {
      machineName: string,
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(machineName)) {
      throw new Error("'machineName' parameter required");
    }

    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/agents/remote-updates/{machineName}/status'
        .replace('{machineName}', encodeURIComponent(String(machineName))),
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.RemoteAgentStatusOutputV1>;
  }

}
