import React, { useState } from 'react';
import { bindingsDefinition } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { AuditTable } from '@/hybrid/auditTrail/AuditTable.molecule';
import { AuditTableSearchPanel } from '@/hybrid/auditTrail/AuditTableSearchPanel.molecule';
import moment from 'moment-timezone';
import { ChangeTypeEnum } from 'sdk/model/AuditOutputV1';

const auditTrailBindings = bindingsDefinition({});

export type SearchParametersIF = {
  range: { start: number, end: number };
  itemIds: string[];
  userIds: string[];
  itemTypes: string[];
  changeTypes: ChangeTypeEnum[];
};

export const AuditTrail: SeeqComponent<typeof auditTrailBindings> = () => {

  const [searchParameters, setSearchParameters] = useState<SearchParametersIF>(
    {
      range: { start: moment().utc().subtract(1, 'hour').valueOf(), end: moment.utc().valueOf() },
      itemIds: [],
      userIds: [],
      itemTypes: [],
      changeTypes: []
    });

  return (
    <div className="m10 flexFill flexRowContainer">
      <AuditTableSearchPanel searchCallback={setSearchParameters} />
      <AuditTable
        range={{
          start: searchParameters.range.start,
          end: searchParameters.range.end
        }}
        itemIds={searchParameters.itemIds}
        userIds={searchParameters.userIds}
        itemTypes={searchParameters.itemTypes}
        changeTypes={searchParameters.changeTypes} />
    </div>
  );
};

export const sqAuditTrail = angularComponent(auditTrailBindings, AuditTrail);
