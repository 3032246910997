import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { bindingsDefinition, injected } from "@/hybrid/core/bindings.util";
import { HoverTooltip } from "@/hybrid/core/HoverTooltip.atom";
import { Icon } from "@/hybrid/core/Icon.atom";
import { angularComponent } from "@/hybrid/core/react2angular.util";
import { Overlay, Popover } from 'react-bootstrap';
import { useTranslation } from "@/hybrid/core/useTranslation.hook";
import { Checkbox } from "@/hybrid/core/Checkbox.atom";
import { useInjectedBindings } from "@/hybrid/core/useInjectedBindings.hook";
import { DurationStore } from "@/trendData/duration.store";
import { useFlux } from "@/hybrid/core/useFlux.hook";
import ValueWithUnits from "@/hybrid/trend/ValueWithUnits.atom";
import { AUTO_UPDATE } from "@/trendData/trendData.module";
import { DurationActions } from "@/trendData/duration.actions";
import { DateTimeService } from "@/datetime/dateTime.service";

const autoUpdateBindings = bindingsDefinition({
  sqDurationStore: injected<DurationStore>(),
  sqDurationActions: injected<DurationActions>(),
  sqDateTime: injected<DateTimeService>()
});

export const AutoUpdate: SeeqComponent<typeof autoUpdateBindings> = () => {
  const {
    sqDurationStore,
    sqDurationActions,
    sqDateTime
  } = useInjectedBindings(autoUpdateBindings);

  const { t } = useTranslation();

  const [showAutoUpdateModal, setShowAutoUpdateModal] = useState(false);
  const [target, setTarget] = useState(null);
  const [invalidManualInterval, setInvalidManualInterval] = useState(false);
  const timeoutInstance = useRef(null);
  const {
    autoUpdate
  } = useFlux(sqDurationStore);

  // intentionally added into setState in order to not call humanizeInterval on each rerender
  const [autoInterval, setAutoInterval] = useState(t('AUTO_UPDATE.AUTO_INTERVAL', {
    autoInterval: sqDateTime.humanizeInterval(autoUpdate.autoInterval)
  }));

  useEffect(() => {
    // handler used to close the picker on outside click
    const handleClick = (e) => {
      if (e.target.getAttribute('data-itemid') === 'autoUpdatePopover') {
        return;
      }
      closeAutoUpdateModal();
    };

    // add when mounted
    document.querySelector('#mainView')?.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.querySelector('#mainView')?.removeEventListener('mousedown', handleClick);
    };


  }, []);

  const closeAutoUpdateModal = () => setShowAutoUpdateModal(false);
  const openAutoUpdateModal = (event) => {
    setShowAutoUpdateModal(true);
    event.stopPropagation();
    setTarget(event.currentTarget);
  };

  const setMode = (event) => {
    const newValue = event.target.value;
    autoUpdate.mode !== newValue && sqDurationActions.autoUpdate.setMode(newValue);
  };

  const isInvalidManualInterval = (interval) => {
    return !_.isNumber(interval.value) || interval.value <= 0 ||
      (interval.units === 's' && interval.value < AUTO_UPDATE.MIN_INTERVAL / 1000);
  };

  const onManualIntervalChange = (interval) => {
    const isInvalid = isInvalidManualInterval(interval);
    autoUpdate.mode !== AUTO_UPDATE.MODES.MANUAL && sqDurationActions.autoUpdate.setMode(AUTO_UPDATE.MODES.MANUAL);
    timeoutInstance.current && clearTimeout(timeoutInstance.current);
    if (isInvalid) {
      // Delay setting error so user has opportunity to clear field and type new number without error message
      timeoutInstance.current = setTimeout(() => {
        setInvalidManualInterval(isInvalid);
      }, 1000);
      return;
    }
    setInvalidManualInterval(false);
    sqDurationActions.autoUpdate.setManualInterval(interval);
  };

  const toggleAutoUpdateModal = event => showAutoUpdateModal ? closeAutoUpdateModal() : openAutoUpdateModal(event);
  const getManualCustomDescription = () => (
    <span className="manualIntervalContainer pl10">
      <ValueWithUnits
        extraClassNames="mt0 mb5 ml0"
        onChange={onManualIntervalChange}
        min={1}
        defaultValue={autoUpdate.manualInterval}
        required={true}
        insideModal={true}
      />
    </span>
  );

  const isOffMode = autoUpdate.mode === AUTO_UPDATE.MODES.OFF;

  return (
    <div className="cursorPointer mt2 ml5">
      <HoverTooltip text={t('AUTO_UPDATE.HEADER')} delay={1500} placement="bottom">
        <div onClick={toggleAutoUpdateModal} data-testid="autoUpdateButton">
          <Icon testId="autoUpdateButtonIcon" icon={classNames("flexCenter", { "fc-refresh-off": isOffMode, "fc-refresh-on": !isOffMode })} />
        </div>
      </HoverTooltip>
      <Overlay target={target} show={showAutoUpdateModal} placement="top" transition={false}>
        <Popover id="autoUpdatePopover" data-testid="autoUpdatePopover" className="popover autoUpdatePopup"
          arrowProps={{ ref: null, style: { bottom: '-20px', left: '6px' } }}>
          <Popover.Title className="popover-title">
            <span>{t('AUTO_UPDATE.HEADER')}</span>
            <Icon icon="fa fa-lg fa-question-circle text-interactive pl5" tooltip={t('AUTO_UPDATE.EXPLANATION')}/>
          </Popover.Title>
          <Popover.Content>
            <div className="picker-close" onClick={closeAutoUpdateModal}>
              <span className="fa fa-close cursorPointer" />
            </div>
            <div className="flexRowContainer autoUpdateOptions">
              <Checkbox
                id="autoUpdate"
                label={t('AUTO_UPDATE.AUTO')}
                extraLabel={<span className="pl5">{autoInterval}</span>}
                classes="flexRowContainer mt5 mb10 ml10 width-auto flexAlignStart"
                descriptionClasses="width-auto ml10 mr10 mt5 small"
                type="radio"
                value={AUTO_UPDATE.MODES.AUTO}
                description={t('AUTO_UPDATE.AUTO_DESCRIPTION')}
                isChecked={autoUpdate.mode === AUTO_UPDATE.MODES.AUTO}
                onChange={setMode}
              />
              <span>
                <Checkbox
                  id="updateManual"
                  label={t('AUTO_UPDATE.MANUAL')}
                  extraLabel={getManualCustomDescription()}
                  classes="flexRowContainer mt5 mb0 ml10 flexAlignStart updateManualContainer"
                  descriptionClasses="width-auto ml10 mr10 mt5 small"
                  type="radio"
                  value={AUTO_UPDATE.MODES.MANUAL}
                  description={t('AUTO_UPDATE.MANUAL_DESCRIPTION')}
                  isChecked={autoUpdate.mode === AUTO_UPDATE.MODES.MANUAL}
                  onChange={setMode}
                />
                {invalidManualInterval && <div className="ml20 small sq-fairly-dark-gray text-italic errorMsg">
                  {t('AUTO_UPDATE.INVALID_MANUAL_INTERVAL')}
                </div>}
              </span>
              <Checkbox
                id="updateOff"
                label={t('AUTO_UPDATE.OFF')}
                classes="flexRowContainer mt15 mb10 ml10 width-auto flexAlignStart"
                descriptionClasses="width-auto ml10 mr10 mt5 small"
                type="radio"
                value={AUTO_UPDATE.MODES.OFF}
                description={t('AUTO_UPDATE.OFF_DESCRIPTION')}
                isChecked={isOffMode}
                onChange={setMode}
              />
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};

export const sqAutoUpdate = angularComponent(autoUpdateBindings, AutoUpdate);
