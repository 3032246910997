import _ from 'lodash';
import angular from 'angular';
import { SocketService } from '@/services/socket.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';

const dependencies = [
  'Sq.Services.Socket',
  'Sq.Services.Utilities'
];

angular.module('Sq.Services.AsyncResponses', dependencies)
  .service('sqAsyncResponses', sqAsyncResponses);

export type AsyncResponsesService = ReturnType<typeof sqAsyncResponses>;

export type RequestId = string;

export type AsyncResponseMessage = {
  status: number,
  statusText: string,
  requestId: string,
  requestUrl: string,
  headers: {[_: string]: string},
  body: any,
};

type AsyncResponseWaiter = {
  id: string,
  accepted: boolean,
  deferred: ng.IDeferred<AsyncResponseMessage>,
};

function sqAsyncResponses(
  $q: ng.IQService,
  sqSocket: SocketService) {

  const waiters: AsyncResponseWaiter[] = [];
  let unsubscribe: () => void = _.noop;
  const service = {
    subscribe,
    waitForResponse,
    accepted,
    cancelWait,
    isOutstandingRequest,
    get outstandingRequestIds() {
      return _.chain(waiters).filter('accepted').map('id').value();
    },
    get hasOutstandingRequests() {
      return waiters.length !== 0;
    }
  };

  return service;

  /**
   * Subscribes to async responses for this session. Also unsubscribes from the subscription to the previous
   * interactive session, if present.
   *
   * @param {String} sessionId - Interactive session ID that identifies this client connection.
   */
  function subscribe(sessionId) {
    unsubscribe();
    unsubscribe = sqSocket.subscribe({
      channelId: [
        SeeqNames.Channels.AsyncResponse,
        sessionId
      ],
      onMessage: onResponse,
      useSubscriptionsApi: false // Channel is auto-subscribed by the backend
    });
  }

  /**
   * Returns a promise that is resolved/rejected when the async response for the request ID specified is received.
   *
   * @param {String} requestId - ID of the HTTP request to wait for
   * @returns {Promise} that resolves with the response
   */
  function waitForResponse(requestId: RequestId): ng.IPromise<AsyncResponseMessage> {
    const waitHandler = {
      id: requestId,
      accepted: false,
      deferred: $q.defer<AsyncResponseMessage>(),
    };
    waiters.push(waitHandler);
    return waitHandler.deferred.promise;
  }

  /**
   * Should be called when the backend responds to a request with a 202 status indicating that the response will be
   * sent asynchronously. Sets an accepted flag on the waiter that is used to filter waiters from being in the
   * outstandingRequestsIds list until the backend has processed and accepted the async request.
   *
   * @param {String} requestId - Request ID to acknowledge
   */
  function accepted(requestId: RequestId) {
    const acceptedWaiters = _.filter(waiters, { id: requestId });
    _.forEach(acceptedWaiters, (waiter) => {
      waiter.accepted = true;
    });
  }

  /**
   * Cancels and removes any handlers waiting on the specified requestId
   *
   * @param {String} requestId - Request ID to cancel
   */
  function cancelWait(requestId: RequestId) {
    const waitersToCancel = _.filter(waiters, { id: requestId });
    _.forEach(waitersToCancel, (waiter) => {
      _.pull(waiters, waiter);

      // The following fields are mainly for type conformance: the `status: -1` is the only really-needed part.
      waiter.deferred.resolve({
        requestId,
        requestUrl: "about:blank",
        statusText: "canceled",
        body: '{"statusMessage": "canceled"}',
        headers: {},
        // See special handling in httpHelpers.response -> sqHttpHelpers.isCanceled
        status: -1,
      });
    });
  }

  /**
   * Internal handler for socket responses. Resolves the appropriate waiter promise(s) when responses are received.
   *
   * @param {Object} response - Data received from websocket
   */
  function onResponse(response) {
    const waitersToComplete = _.filter(waiters, { id: response.requestId });
    _.forEach(waitersToComplete, (waiter) => {
      _.pull(waiters, waiter);
      waiter.deferred.resolve(response);
    });
  }

  /**
   * Determines if the supplied request ID is outstanding.
   *
   * @param {String} requestId - a request ID
   * @returns {boolean} true if the request is outstanding, false otherwise
   */
  function isOutstandingRequest(requestId: RequestId) {
    return service.outstandingRequestIds.indexOf(requestId) >= 0;
  }
}
