import bind from 'class-autobind-decorator';
import _ from 'lodash';
import moment from 'moment';

@bind
export class ProgressCtrl {
  informationString: string = this['informationString'];
  existingColors: any[] = this['existingColors'];
  progressDisplay: any = this['progressDisplay'];
  displayTotal: any = this['displayTotal'];
  legend: any = this['legend'];
  displayTime: boolean = this['displayTime'];
  isReady: boolean = false;
  colorAssignment: any = this['colorAssignment'];
  tooltipLookup: any = this['tooltipLookup'];
  colors = ['#00B7C3', '#C30052', '#2B78C0', '#FB763A', '#6DAB51', '#FFB900', '#CA5010', '#9A0089', '#10893E',
    '#2D7D9A', '#ffbb00', '#7cbb00', '#b84592', '#ff9900', '#537b35', '#832561', '#b3dcff', '#205081', '#bf033b'];
  otherColor = '#CCCCCC';

  constructor(public sqNumberHelper) {
  }

  /**
   * Used to reflect progress data changes.
   */
  $onChanges() {
    this.doUpdateProgressForDisplay();
  }

  /**
   * This function takes the information provided by the backend (as a String that looks like this:
   * 'aa;a=1;desc="Alpha",bb;b=2;desc="Beta",cc;c=3;dec="Gamma",dd;d=4;dec="Delta"';) and updates the appropriate
   * variables for display.
   */
  doUpdateProgressForDisplay() {

    const getOthersTotal = (data) => {
      return getTotals(_.slice(data, 3));
    };

    const getTotals = (data) => {
      return _.reduce(data, (total, current: any) => {
        return total + _.get(current, 'value', 0);
      }, 0);
    };

    const splitString = input => _.isUndefined(input) || input === '' ? '' : input.substring(_.indexOf(input, '=') + 1);

    this.tooltipLookup = (<any>_.chain(this.informationString))
      .split(',').map((e) => {
        if (_.isUndefined(e) || e === '') {
          return;
        }
        e = e.substring(_.indexOf(e, ';') + 1);
        const valueAndSource = _.split(e, ';');
        const value = splitString(valueAndSource[0]);
        const source = _.replace(splitString(valueAndSource[1]), new RegExp('"', 'g'), '');
        // CRAB-17814: rename misleading labels for more clarity
        let label = _.replace(source, /^Request Queue$/, 'Queue');
        label = _.replace(label, /^Calc Engine Queue$/, 'Queue');
        if (label !== 'Seeq Database') {  // CRAB-23283 'Seeq Database' is more informative than simply 'Database'
          label = _.replace(label, / .*/, '');
        }

        return {
          source,
          label,
          value: parseFloat(value),
          displayValue: this.getDisplayText(value)
        };
      })
      .reject({ source: 'Total' })
      .reject({ source: 'Database Items Read' })
      .reject({ source: 'Database Relationships Read' })
      .value();

    // To keep the UI somewhat clean and simple we roll-up everything that starts with the same word into one entry
    const rolledUpByLabelSortedAndColored = _.chain(this.tooltipLookup)
      .reduce((result, current: any) => {
        const existing = _.find(result, { label: _.get(current, 'label') });
        if (existing) {
          // add up the values:
          existing.value += current.value;
        } else {
          result.push(current);
        }
        return result;
      }, [])
      // assign a color - things with the same name get assigned the same color (across both bars)
      .map((entry, idx) => {
        if (this.existingColors) {
          // see if we already assigned a color to that label:
          const existing = _.find(this.existingColors, { label: _.get(entry, 'label') });

          if (existing) {
            return _.assign(entry, { color: existing.color });
          } else {
            return _.assign(entry, { color: this.colors[idx + this.existingColors.length] });
          }
        } else {
          return _.assign(entry, { color: this.colors[idx] });
        }
      })
      // sort AFTER assigning a color to ensure that the same things always get the same color assigned!
      .sortBy('value')
      .reverse()
      .value();

    this.colorAssignment = _.map(rolledUpByLabelSortedAndColored, c => ({ label: c.label, color: c.color }));

    const total = getTotals(rolledUpByLabelSortedAndColored);
    this.isReady = _.isFinite(total);

    if (!this.isReady) {
      return;
    }

    // take the top 3, combine the rest into "others"
    this.progressDisplay = _.chain(rolledUpByLabelSortedAndColored)
      .slice(0, 3)
      .sortBy('label')
      .value();

    const timingOthersTotal = getOthersTotal(rolledUpByLabelSortedAndColored);
    this.progressDisplay.push({
      source: 'Other',
      label: 'Other',
      value: timingOthersTotal,
      color: this.otherColor,
      displayValue: this.getDisplayText(timingOthersTotal)
    });

    this.progressDisplay = _.map(this.progressDisplay, (e: any) => {
      const percent = total > 0 ? (e.value / total) * 100 : 0;
      return _.assign({}, e, {
        percent,
        displayValue: percent > 10 ? this.getDisplayText(e.value) : ''
      });
    });

    this.legend = _.chain(this.progressDisplay)
      .map((current) => {
        const c: any = _.pick(current, ['label', 'color', 'percent']);
        return !_.isFinite(c.percent) || c.percent < 1 ? null : c;
      })
      .compact()
      .value();
    this.displayTotal = this.getDisplayText(total);
  }

  /**
   * Returns the properly formatted display ready String.
   *
   * @param {String} value - the value to be displayed
   * @returns {String} properly formatted String (as time, or as number)
   */
  getDisplayText(value) {
    return this.displayTime ? this.getDisplayTime(parseFloat(value)) : (parseFloat(value)).toLocaleString();
  }

  /**
   * Returns duration formatted properly.
   * If the duration is sufficiently small, it will round to 2 digits. Otherwise, it'll return mm:ss or hh:mm:ss
   *
   * @param {Number} duration - duration to be formatted. In milliseconds.
   * @returns {string} properly formatted, display ready duration.
   */
  getDisplayTime(duration) {
    const MILLISECONDS_IN_SECOND = 1000;
    const SECONDS_IN_HOUR = 3600;
    const durationInSeconds = duration / MILLISECONDS_IN_SECOND;
    if (durationInSeconds >= SECONDS_IN_HOUR) {
      return moment.utc(duration).format('hh:mm:ss');
    } else if (durationInSeconds > 90) {
      return moment.utc(duration).format('mm:ss');
    } else {
      return this.sqNumberHelper.roundWithPrecision(durationInSeconds, 2) + 's';
    }
  }
}
