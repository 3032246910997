import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import Select from 'react-select';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { RedactionService } from '@/services/redaction.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { UtilitiesService } from '@/services/utilities.service';
import UnitOfMeasure from '@/hybrid/core/UnitOfMeasure.atom';

const selectItemWrapperBindings = bindingsDefinition({
  items: prop<object []>(),
  selected: prop<object | string[]>(),
  // for Asset Groups we need to compare the items to the group as well as the item ids will be the same for asset
  // column items
  selectByIdAndGroup: prop.optional<boolean>(),
  allowClear: prop<boolean>(),
  insideModal: prop.optional<boolean>(),
  selectPlaceholder: prop.optional<string>(),
  isMultipleSelect: prop<boolean>(),
  disableAutoSelect: prop.optional<boolean>(),
  loadingMetadata: prop<boolean>(),
  dropdownWidth: prop<string>(),
  cssClassName: prop<string>(),
  noWrap: prop<boolean>(),
  onRemove: prop<(item: object) => void>(),
  onChange: prop<(item: object) => void>(),
  isGrouped: prop.optional<boolean>(),
  sqRedaction: injected<RedactionService>(),
  sqUtilities: injected<UtilitiesService>()
});

export const SelectItemWrapper: SeeqComponent<typeof selectItemWrapperBindings> = (props) => {
  const { t } = useTranslation();

  const {
    sqRedaction,
    sqUtilities
  } = useInjectedBindings(selectItemWrapperBindings);

  const {
    items,
    selected,
    allowClear,
    onChange,
    isMultipleSelect,
    dropdownWidth,
    onRemove,
    loadingMetadata,
    cssClassName,
    disableAutoSelect,
    insideModal,
    selectPlaceholder,
    noWrap = false,
    isGrouped = true,
    selectByIdAndGroup = false
  } = props;

  const menuPosition = insideModal ? 'absolute' : 'fixed';
  const [focused, setFocused] = useState(false);

  useEffect(() => {
      if (!selected && items?.length === 1 && !allowClear && !disableAutoSelect) {
        onChange(items[0]);
      }
    }, [items]
  );

  const groupItems = (ungrouped) => {
    const groupItem = (item) => {
      switch (item?.selectGroup) {
        case 'assetGroupColumn':
          return 'ASSET_GROUP_EDITOR.ASSET_GROUP_COLUMNS';
        case 'original':
          return 'ASSET_GROUP_EDITOR.ORIGINAL_ASSIGNMENT';
        case 'pinned':
          return 'SEARCH_DATA.PINNED';
        case 'recentlyAccessed':
          return 'SEARCH_DATA.RECENTLY_ACCESSED';
        default:
          return 'DETAILS';
      }
    };
    return _.chain(ungrouped)
      .groupBy(groupItem)
      .map((obj) => {
        return { label: t(groupItem(obj[0])), options: obj };
      }).value();
  };

  const displayEntry = (item, innerProps, wrapperStyle) => {
    if (!item) {
      return null;
    }

    const style = { color: item?.color };
    const unitOfMeasure = item.displayUnitOfMeasure;
    const className = classNames('pr5', 'fa', 'sq-tool-description-color', sqUtilities.itemIconClass(item));

    if (sqRedaction.isItemRedacted(item)) {
      return (
        <div {...innerProps} className="flexColumnContainer pt8">
          <i className="fa fa-warning text-danger pr5" />
          <div id="specSelectedSignal" className="flexRowContainer flexFill pb1">
            <div className="flexColumnContainer specChoiceName font-size-smaller">
              {t('NO_ITEM_ACCESS')}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div {...innerProps} className={wrapperStyle}>
          <i className={className} style={style} />
          <div
            id="specSelectedSignal"
            className={classNames('flexRowContainer flexFill pb1',
              { 'text-nowrap': noWrap, aggressiveWordBreak: !noWrap })}>
            <div>
              <strong className="mr5 specChoiceName">{item?.name} </strong>
              <UnitOfMeasure unitOfMeasure={unitOfMeasure} />
            </div>

            <div className="xsmall itemAsset">
              {item?.assets?.[0]?.formattedName || item?.assets?.[0]?.datasource?.name}
            </div>
          </div>
        </div>);
    }
  };

  const SingleValue = (optionProps) => {
    const { innerProps, data } = optionProps;
    const currentItem: any = _.isObject(data) ? _.find(items, { id: data.id }) :
      _.find(items, { id: data });

    // ensure item is not pushed out of view inside the input field as the cursor is set onto a new line
    if (focused && !isMultipleSelect) {
      return <span />;
    }

    return displayEntry(currentItem, innerProps, 'flexColumnContainer flexCenter flexFill');
  };

  const Menu = (optionProps) => {
    const { innerProps, children } = optionProps;
    const extraStyles = insideModal ? { position: 'absolute', zIndex: 1 } : {};
    return (
      <div {...innerProps} className="react-select-menuList" style={{ ...extraStyles, width: dropdownWidth }}>
        {children}
      </div>
    );
  };

  const Option = (optionProps) => {
    const { innerProps } = optionProps;
    let { data } = optionProps;
    if (isMultipleSelect) {
      data = data.value;
    }
    let isSelected = _.includes(_.toArray(selected), data?.id);
    isSelected = selectByIdAndGroup ? (isSelected && data.selectGroup === (selected as any).selectGroup) : isSelected;
    const wrapperClassName = classNames('flexColumnContainer', 'flexCenter', 'cursorPointer', 'pl10', 'pr10', 'pt2',
      'pb2',
      isSelected ? 'selected-item-entry' : 'select-item-entry');

    return displayEntry(data, innerProps, wrapperClassName);
  };

  const formatGroupLabel = (data) => {
    const groupStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    };

    return (
      <div style={groupStyles}><span>{data.label}</span></div>
    );
  };

  const MultiValueRemove = (optionProps) => {
    return (
      <div {...optionProps.innerProps} onClick={() => {
        onRemove(_.find(items, { id: optionProps.data }));
      }}><i className="fa fa-close" />
      </div>
    );
  };

  const MultiValueLabel = SingleValue;

  const prepareItemsForDisplay = (items) => {
    let displayItems: any[] = _.reject(items, item => sqRedaction.isItemRedacted(item));
    if (isMultipleSelect) {
      displayItems = _.reject(displayItems, item => _.includes(selected, item.id));
      const toOptions = options => _.map(options, obj => ({ label: obj.name, value: obj }));

      return isGrouped ? _.map(groupItems(displayItems),
        ({ label, options }) => ({ label, options: toOptions(options) })) :
        toOptions(displayItems);
    } else {
      return isGrouped ? groupItems(displayItems) : displayItems;
    }
  };

  const matchesFilter = (option, input) => input === '' || _.includes(_.toLower(option.data.name), _.toLower(input));

  const displayItems = prepareItemsForDisplay(items);

  return (
    <div className={(isGrouped ? 'flexAlignCenter ' : '') + 'flexFillOverflow flexColumnContainer'}>
      <Select
        placeholder={selectPlaceholder ? t(selectPlaceholder) : t('SELECT_ITEM')}
        onMenuOpen={() => setFocused(true)}
        onMenuClose={() => setFocused(false)}
        className="reactSelectWrapper"
        isClearable={allowClear}
        classNamePrefix={cssClassName}
        value={selected}
        isMulti={isMultipleSelect}
        options={displayItems}
        formatGroupLabel={formatGroupLabel}
        onChange={onChange}
        components={{ Option, SingleValue, MultiValueLabel, MultiValueRemove, Menu }}
        menuPosition={menuPosition}
        menuPortalTarget={insideModal ? undefined : document.querySelector('#mainView')}
        menuPlacement="auto"
        isLoading={loadingMetadata}
        filterOption={matchesFilter}
        styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
      />
    </div>
  );
};

export default SelectItemWrapper;
