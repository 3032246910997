import _ from 'lodash';
import angular from 'angular';
import { AuthenticationService } from '@/services/authentication.service';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { WorkbenchActions } from '@/workbench/workbench.actions';

angular.module('Sq.TrendViewer').controller('TimezoneModalCtrl', TimezoneModalCtrl);

function TimezoneModalCtrl(
  options,
  $scope: ng.IScope,
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  sqAuthentication: AuthenticationService,
  sqWorksheetActions: WorksheetActions,
  sqWorksheetStore: WorksheetStore,
  sqWorkbenchActions: WorkbenchActions) {
  const vm = this;
  // `userFallback` is used to determine whether we are on a worksheet or a topic (worksheets have a 'user'
  // fallback for the time zone, while topics don't.
  vm.userFallback = options?.userFallback;
  vm.save = save;
  vm.close = close;
  vm.openUserProfile = openUserProfile;
  vm.showOpenUserProfile = options?.userFallback;
  vm.setWorksheetTimezone = zone => setTimezone(zone);
  vm.setFixed = setFixed;

  $scope.$listenTo(sqWorksheetStore, syncWithWorksheetStore);

  function syncWithWorksheetStore() {
    vm.timezoneFixed = sqWorksheetStore.timezoneFixed;
    // Don't update `vm.timezone` unless we have a fixed timezone, that way the user can switch back and forth
    // between the options without resetting the timezone in the drop down to the user timezone
    if (_.isUndefined(vm.timezone) || vm.timezoneFixed) {
      vm.timezone = sqWorksheetStore.timezone;
    }
  }

  function setTimezone(timezone) {
    vm.timezone = timezone;
    $scope.$evalAsync();
  }

  /**
   * Updates the timezone to use the timezone local to the user or one set in the worksheet
   *
   * @param {Boolean} timezoneFixed - true if the worksheet has the timezone set
   */
  function setFixed(timezoneFixed) {
    vm.timezoneFixed = timezoneFixed;
  }

  /**
   * Opens the User Profile Editor and closes this modal
   */
  function openUserProfile() {
    sqWorkbenchActions.setUserProfileDisplay(true);
    vm.close();
  }

  /**
   * Saves the user's timezone choice and closes the modal
   */
  function save() {
    if (vm.timezoneFixed !== sqWorksheetStore.timezoneFixed || vm.timezone !== sqWorksheetStore.timezone) {
      sqWorksheetActions.setTimezone(vm.timezoneFixed ? vm.timezone : undefined);
    }
    vm.close();
  }

  /**
   * Closes the modal
   */
  function close() {
    $uibModalInstance.close();
  }
}
