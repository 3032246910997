import bind from 'class-autobind-decorator';

@bind
export class ExportODataPanelActions {
  constructor(
    private flux: ng.IFluxService) {
  }

  /**
   * Sets whether autoupdate is enabled for the time range
   *
   * @param {boolean} autoupdateTimeRange - True to enable autoupdate
   */
  setAutoupdateTimeRange(autoupdateTimeRange) {
    this.flux.dispatch('EXPORT_ODATA_AUTOUPDATE_TIME_RANGE', { autoupdateTimeRange });
  }

  /**
   * Set time range start that is used in the export form.
   *
   * @param {moment} start - the start of the time range
   */
  setTimeRangeStart(start) {
    this.flux.dispatch('EXPORT_ODATA_TIME_RANGE_START', { start });
  }

  /**
   * Set time range end that is used in the export form.
   *
   * @param {moment} end - the end of the time range
   */
  setTimeRangeEnd(end) {
    this.flux.dispatch('EXPORT_ODATA_TIME_RANGE_END', { end });
  }

  /**
   * Sets the duration of the time range.
   *
   * @param {moment.duration} duration - The duration of the time range
   */
  setDuration(duration) {
    this.flux.dispatch('EXPORT_ODATA_DURATION', { duration });
  }

  setExportTimeZone(exportTimeZone) {
    this.flux.dispatch('EXPORT_ODATA_EXPORT_TIME_ZONE', { exportTimeZone });
  }

  setGridOption(gridOption) {
    this.flux.dispatch('EXPORT_ODATA_GRID_OPTION', { gridOption });
  }

  setGridSize(gridSize) {
    this.flux.dispatch('EXPORT_ODATA_GRID_SIZE', { gridSize });
  }

  setGridOrigin(gridOrigin) {
    this.flux.dispatch('EXPORT_ODATA_GRID_ORIGIN', { gridOrigin });
  }

  setGridOriginEnabled(gridOriginEnabled) {
    this.flux.dispatch('EXPORT_ODATA_GRID_ORIGIN_ENABLED', { gridOriginEnabled });
  }
}
