import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import _ from 'lodash';
import classNames from 'classnames';
import { Icon } from '@/hybrid/core/Icon.atom';
import { UtilitiesService } from '@/services/utilities.service';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import UnitOfMeasure from '@/hybrid/core/UnitOfMeasure.atom';
import { onEnterKeypress } from '@/hybrid/core/onEnterKeypress.util';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { AssetGroupStore } from '@/hybrid/assetGroupEditor/assetGroup.store';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';

const searchResultBindings = bindingsDefinition({
  icons: prop<JSX.Element>(),
  item: prop<any>(),
  customIconClasses: prop.optional<string>(),
  isSelected: prop.optional<boolean>(),
  onClickItem: prop<(item) => void>(),
  sqUtilities: injected<UtilitiesService>(),
  sqAssetGroupStore: injected<AssetGroupStore>(),
  isSelectingAsset: prop.optional<boolean>()
});

export const SearchResult: SeeqComponent<typeof searchResultBindings> = (props) => {
  const {
    sqUtilities,
    sqAssetGroupStore
  } = useInjectedBindings(searchResultBindings);
  const { icons, item, onClickItem, isSelected, customIconClasses, isSelectingAsset = false } = props;
  const itemIconClassNames = `${customIconClasses ? customIconClasses : 'sq-fairly-dark-gray'} pl5 pr10`;
  const arrowIconClassNames = `${customIconClasses ? customIconClasses : 'text-interactive'} mr5`;

  const isEditingAssetGroup = useFluxPath(sqAssetGroupStore, () => sqAssetGroupStore.id === item.id);
  const showNavigateCaret = sqUtilities.isAsset(item) || sqUtilities.isDatafile(item) || item?.hasChildren;
  const isUomRecognized = !_.isNil(item?.valueUnitOfMeasure);
  const uomToDisplay = sqUtilities.isStringSeries(item) ? null :
    isUomRecognized ? item.valueUnitOfMeasure : item.sourceValueUnitOfMeasure;

  const unfocusSearchResult = (e) => {
    e.target.blur();
  };

  return (
    <HoverTooltip text={isEditingAssetGroup && 'SEARCH_DATA.NO_ACTIONS_WHILE_EDITING'}>
      <div className={classNames('flexColumnContainer', 'flexNoGrowNoShrink', 'flexSpaceBetween', 'itemRow',
        'cursorPointer', 'pt3', 'pb6',
        { 'disabledItemRow text-italic  disabledLook cursorNotAllowed': isEditingAssetGroup },
        { itemRowSelected: isSelected }
      )}
        tabIndex={0}
        onMouseLeave={unfocusSearchResult}
        onKeyUp={onEnterKeypress(() => !isEditingAssetGroup && onClickItem(item))}
        onClick={() => !isEditingAssetGroup && onClickItem(item)}>
        <div className="flexColumnContainer flexCenter">
          {isSelectingAsset && icons}
          <Icon icon={sqUtilities.itemIconClass(item)}
            testId="searchItemIcon"
            type="inherit"
            extraClassNames={itemIconClassNames}
            large={true} />
        </div>

        <div className="aggressiveWordBreak flexFill">
          <div className="searchResultName">
            <span>{item.name} </span>
            <UnitOfMeasure unitOfMeasure={{ value: uomToDisplay, isRecognized: isUomRecognized }} />
          </div>
          {item.description &&
          <div className="xsmall pb5 truncatedLabel" data-testid="searchResultDescription">{item.description}</div>}
          <div className="xsmall pb2 sq-fairly-dark-gray text-italic">
            {item.assets?.[0]?.formattedName || item?.datasource?.name}
          </div>
        </div>

        <div className="flexRowContainer flexCenter mt2">
          <div className="flexColumnContainer flexCenter">
            {!isSelectingAsset && icons}

            {showNavigateCaret &&
            <Icon icon="fa-chevron-right" type="inherit"
              extraClassNames={arrowIconClassNames} testId="arrowIcon" />}
          </div>
        </div>
      </div>
    </HoverTooltip>
  );
};
