/**
 * SimpleSaveFormBuilder generates forms based on the provided form definition Array.
 * SimpleSaveFormBuilder is NOT TO BE USED for tools - use ToolFormBuilder for tools. SimpleSaveFormBuilder should
 * be used for forms that only display a save/cancel button, and do not need tool specific tracking.
 **/
import React from 'react';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { FormBuilder } from '@/hybrid/formbuilder/FormBuilder.page';

interface SimpleSaveFormBuilderProps {
  // the formDefinition Array of FormElements defines the form to be rendered
  formDefinition: FormElement[];
  // the function that is called when the form is submitted
  submitFn: (values: {}) => void;
  // the function that is called when the cancel button is clicked
  closeFn: () => any;
  // if debug is enabled then the form values and form errors are rendered below this form;
  debug?: boolean;
  // if true the form is saving and the submit button should be disabled
  formSaveProcessing?: boolean;
  // option to have the submit button say something other than 'Save'
  submitBtnLabel?: string;
}

export const SimpleSaveFormBuilder: React.FunctionComponent<SimpleSaveFormBuilderProps> = props => (
  <FormBuilder {...props} saveAndCancel={true} />
);


