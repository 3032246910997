/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class FoldersApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Archive a folder and all of its content
   * @param {string} folderId - ID of the folder to archive
   */
  public archiveFolder(
    {
      folderId
    } : {
      folderId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(folderId)) {
      throw new Error("'folderId' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/folders/{folderId}'
        .replace('{folderId}', encodeURIComponent(String(folderId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ArchiveOutputV1>;
  }

  /**
   * 
   * @summary Create a folder
   * @param {models.FolderInputV1} body - Folder information
   */
  public createFolder(
    body: models.FolderInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/folders',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.FolderOutputV1>;
  }

  /**
   * 
   * @summary Get navigation context for a folder. 
   * @param {string} folderId - ID of the folder to retrieve.
   * @param {string} [root] - What sharing category results should be returned for
   */
  public getAncestors(
    {
      folderId,
      root
    } : {
      folderId: string,
      root?: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(folderId)) {
      throw new Error("'folderId' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/folders/{folderId}/ancestors'
        .replace('{folderId}', encodeURIComponent(String(folderId))),
      params: omitBy({
        ['root']: root
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.FolderNavigationOutputV1>;
  }

  /**
   * 
   * @summary Get information about a folder
   * @param {string} folderId - ID of the folder to retrieve. Special values of &#39;mine&#39;, &#39;corporate&#39;, or &#39;users&#39; (admins only) fetch the authenticated user&#39;s home folder, the corporate folder, or the folder of all home folders, respectively.
   */
  public getFolder(
    {
      folderId
    } : {
      folderId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(folderId)) {
      throw new Error("'folderId' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/folders/{folderId}'
        .replace('{folderId}', encodeURIComponent(String(folderId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.FolderOutputV1>;
  }

  /**
   * 
   * @summary Get all root folders and workbooks, or folder content if a parent folder id is specified.
   * @param {string} [filter=all] - If set to &#39;mine&#39; or &#39;owner&#39;, only workbench items under the current user&#39;s home folder will be returned. If set to &#39;public&#39; only workbench items shared with the Everyone group will be returned, excluding items under the corporate folder. If set to &#39;sharedOrPublic&#39; only workbench items shared with the current user or a group they belong to are returned, excluding items under the corporate folder. If set to &#39;shared&#39; only workbench items shared with the currentuser or a group they belong to (except the Everyone group) are returned, excluding items under the corporate folder. If set to &#39;corporate&#39;, only workbench items under the corporate folder are returned. If set to &#39;users&#39;, only items under other user&#39;s home folders are returned. If set to &#39;all&#39; (or not specified), all workbench items the current user has access to will be returned.
   * @param {string} [userId] - The user ID to return folders for, defaults to the current user if not specified. Only an admin user is allowed to specify a user ID.
   * @param {boolean} [isArchived=false] - When true, returns only archived workbench items.
   * @param {string} [sortOrder=createdAt asc] - A field by which to order the folders followed by a space and &#39;asc&#39; or &#39;desc&#39;. Field name can be one of: createdAt, updatedAt, name, owner
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   * @param {boolean} [onlyPinned=false] - When true, returns only pinned workbench items are returned. The given folderId is not applied.
   * @param {string} [folderId] - The folder to return the content of. If not specified, only root folders are returned unless filtering to specific IDs, filtering to pinned or archived items, sorting by last opened timestamp, or a text search is provided.
   * @param {Array<string>} [ids] - The IDs of items to return.
   * @param {Array<string>} [types] - The types of items to return.
   * @param {string} [textSearch] - Search text by which to filter items&#39; names.
   * @param {boolean} [isExact=false] - If true, uses the exact query terms supplied by the user instead of stemming the query to its root words
   * @param {string} [root] - The highest ancestor that items should have, if possible
   */
  public getFolders(
    {
      filter,
      userId,
      isArchived,
      sortOrder,
      offset,
      limit,
      onlyPinned,
      folderId,
      ids,
      types,
      textSearch,
      isExact,
      root
    } : {
      filter?: string,
      userId?: string,
      isArchived?: boolean,
      sortOrder?: string,
      offset?: number,
      limit?: number,
      onlyPinned?: boolean,
      folderId?: string,
      ids?: Array<string>,
      types?: Array<string>,
      textSearch?: string,
      isExact?: boolean,
      root?: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/folders',
      params: omitBy({
        ['filter']: filter,
        ['userId']: userId,
        ['isArchived']: isArchived,
        ['sortOrder']: sortOrder,
        ['offset']: offset,
        ['limit']: limit,
        ['onlyPinned']: onlyPinned,
        ['folderId']: folderId,
        ['ids']: ids,
        ['types']: types,
        ['textSearch']: textSearch,
        ['isExact']: isExact,
        ['root']: root
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.WorkbenchItemOutputListV1>;
  }

  /**
   * 
   * @summary Get subfolders of a folder. 
   * @param {string} folderId - ID of the folder to retrieve. Special values of &#39;mine&#39;, &#39;corporate&#39;, &#39;sharedOrPublic&#39;, or &#39;users&#39; can be used to fetch the top level categories.
   * @param {string} [root] - What sharing category results should be returned for
   */
  public getSubfolders(
    {
      folderId,
      root
    } : {
      folderId: string,
      root?: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(folderId)) {
      throw new Error("'folderId' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/folders/{folderId}/subfolders'
        .replace('{folderId}', encodeURIComponent(String(folderId))),
      params: omitBy({
        ['root']: root
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.FolderNavigationOutputV1>;
  }

  /**
   * 
   * @summary Move specified item to specified folder
   * @param {string} folderId - ID of folder to move item to. Special values of &#39;mine&#39; or &#39;corporate&#39; move the item to the authenticated user&#39;s home folder or the corporate folder, respectively.
   * @param {string} itemId - ID of item to move to folder
   */
  public moveItemToFolder(
    {
      folderId,
      itemId
    } : {
      folderId: string,
      itemId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(folderId)) {
      throw new Error("'folderId' parameter required");
    }

    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/folders/{folderId}/{itemId}'
        .replace('{folderId}', encodeURIComponent(String(folderId)))
        .replace('{itemId}', encodeURIComponent(String(itemId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.FolderOutputV1>;
  }

  /**
   * 
   * @summary Remove specified item from specified folder. The item will be moved to the root level.
   * @param {string} folderId - ID of folder to remove item from
   * @param {string} itemId - ID of item to remove from folder
   */
  public removeItemFromFolder(
    {
      folderId,
      itemId
    } : {
      folderId: string,
      itemId: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(folderId)) {
      throw new Error("'folderId' parameter required");
    }

    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/folders/{folderId}/{itemId}'
        .replace('{folderId}', encodeURIComponent(String(folderId)))
        .replace('{itemId}', encodeURIComponent(String(itemId))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.FolderOutputV1>;
  }

}
