import _ from 'lodash';
import angular from 'angular';
import { WorkstepsActions } from '@/worksteps/worksteps.actions';
import { StateSynchronizerService } from '@/services/stateSynchronizer.service';
import { WorkstepsStore } from '@/worksteps/worksteps.store';
import { ScreenshotService } from '@/services/screenshot.service';
import { NotifierService } from '@/services/notifier.service';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { AnnotationActions } from '@/annotation/annotation.actions';

/**
 * The worksteps controller is the view model for the worksteps directive
 */
angular.module('Sq.Worksteps').controller('WorkstepsCtrl', WorkstepsCtrl);

function WorkstepsCtrl(
  $q: ng.IQService,
  $scope: ng.IScope,
  $state: ng.ui.IStateService,
  sqWorkstepsActions: WorkstepsActions,
  sqStateSynchronizer: StateSynchronizerService,
  sqWorkstepsStore: WorkstepsStore,
  sqScreenshot: ScreenshotService,
  sqNotifier: NotifierService,
  sqWorkbenchStore: WorkbenchStore,
  sqAnnotationActions: AnnotationActions) {
  const vm = this;
  let processing = false;

  $scope.$listenTo(sqWorkbenchStore, update);
  $scope.$listenTo(sqWorkstepsStore, update);

  /**
   * Navigates to the previous workstep
   *
   * @returns {Promise} A promise that resolves when the previous workstep
   * has been rehydrated or rejects if rehydration is already in progress.
   */
  vm.previousWorkstep = function() {
    return goToWorkstep('previous', vm.previousAvailable);
  };

  /**
   * Navigates to the next workstep
   *
   * @returns {Promise} A promise that resolves when the next workstep
   * has been rehydrated or rejects if rehydration is already in progress.
   */
  vm.nextWorkstep = function() {
    return goToWorkstep('next', vm.nextAvailable);
  };

  /**
   * Navigates to the last workstep
   *
   * @returns {Promise} A promise that resolves when the last workstep
   * has been rehydrated or rejects if rehydration is already in progress.
   */
  vm.lastWorkstep = function() {
    return goToWorkstep('last', vm.lastAvailable);
  };

  /**
   * Navigates to the specified workstep if it's available.
   *
   * @param {String} which - The identifier (previous, next, last) of the workstep to navigate to
   * @param {Boolean} isAvailable - A flag identifying if the desired workstep is available
   * @returns {Promise} A promise that resolves when the workstep
   * has been rehydrated or rejects if rehydration is already in progress.
   */
  function goToWorkstep(which, isAvailable) {
    let workstepAction;
    if (isAvailable) {
      workstepAction = _.partial(sqWorkstepsActions[which], $state.params.workbookId, $state.params.worksheetId);
      return rehydrateWorkstep(workstepAction)
        .then(sqAnnotationActions.fetchAnnotations);
    } else {
      return $q.reject();
    }
  }

  function update() {
    /**
     * @type {boolean} previousAvailable - True if a previous workstep is available
     */
    vm.previousAvailable = !_.isEmpty(sqWorkstepsStore.previous);
    /**
     * @type {boolean} nextAvailable - True if a next workstep is available
     */
    vm.nextAvailable = !_.isEmpty(sqWorkstepsStore.next);

    vm.lastAvailable = !_.isEmpty(sqWorkstepsStore.last) && sqWorkstepsStore.last !== sqWorkstepsStore.current.id;
    vm.isError = sqWorkstepsStore.isError;
  }

  /**
   * Rehydrates a workstep, unless it is already in the middle of rehydrating.
   *
   * @param {Function} workstepAction - The action to take that gets the workstep to rehydrate
   * @return {Promise} The rehydrate promise
   */
  function rehydrateWorkstep(workstepAction) {
    if (!processing) {
      processing = true;
      return workstepAction()
        .then(function(response) {
          const workbookId = $state.params.workbookId;
          const worksheetId = $state.params.worksheetId;
          sqStateSynchronizer.setLoadingWorksheet(workbookId, worksheetId);
          return sqStateSynchronizer.rehydrate(response.current.state, { workbookId, worksheetId })
            .finally(() => {
              sqStateSynchronizer.unsetLoadingWorksheet();
            });
        })
        .then(function() {
          sqScreenshot.generate($state.params.workbookId, $state.params.worksheetId);
        })
        .finally(function() {
          processing = false;
        });
    } else {
      return $q.reject();
    }
  }
}
