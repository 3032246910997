import angular from 'angular';
import moment from 'moment-timezone';
import { FrontendDuration } from '@/services/systemConfiguration.service';
import { SummaryTypeEnum } from 'sdk/model/ContentInputV1';

const dependencies = [
  'Sq.Core',
  'Sq.AppConstants',
  'Sq.Services.Socket',
  'Sq.TrendData',
  'Sq.Search',
  'Sq.Investigate',
  'Sq.Worksheet',
  'Sq.Explorer'
];

export interface Content {
  name: string;
  id: string;
  height: number;
  width: number;
  scale: number;
  timezone?: string;
  workbookId: string;
  worksheetId: string;
  workstepId: string;
  workbookName: string;
  worksheetName: string;
  useSizeFromRender?: boolean;
  dateRangeId?: string;
  assetSelectionId?: string;
  reportId?: string;
  isArchived?: boolean;
  summary?: ReportContentSummary;
  hashCode?: string;
  isReact?: boolean;
}

export interface AssetSelection {
  name: string;
  asset: any;
  selectionId: string | null;
  reportId: string;
  isArchived?: boolean;
  assetPathDepth: number | null;
}

export interface ContentDisplayMetadata {
  contentId: string;
  refresh?: { silently?: boolean, deferImageUpdate?: boolean };
  errorClass?: string;
  error?: string;
}

/**
 * Start/end times for a piece of content
 */
export interface Range {
  start: number;
  end: number;
}

/**
 * Condition information for a piece of content
 */
export interface DateRangeCondition {
  id: string;
  name: string;
  strategy: string;
  reference: string;
  offset: string;
  range: Range; // search range used to find a capsule
  isRedacted: boolean;
  columns?: string[]; // saved columns when the range created using a capsule
  isCapsuleFromTable?: boolean;
}

export type DateRangeAutoRate = { value: number, units: string };

/**
 * DateRange live or scheduled information as represented in the frontend, parsed from the API representation
 */
export interface DateRangeAuto {
  enabled: boolean;
  duration: FrontendDuration;
  offsetDirection: string;
  offset: { value: number, units: string };
  background: boolean;
  cronSchedule?: string[];
  noCapsuleFound: boolean;
}

/**
 * Schedule for a scheduled report, including whether the report runs in the background
 */
export interface ReportSchedule {
  cronSchedule?: string[];
  background: boolean;
  enabled: boolean;
}

/**
 * DateRange as represented in the frontend, parsed from the API representation
 */
export interface DateRange {
  id: string;
  name: string;
  description?: string;
  range: Range; // Effective range applied to the content
  enabled: boolean; // Whether this date range is enabled to run jobs
  auto: DateRangeAuto;
  condition: DateRangeCondition;
  reportId: string;
  isArchived?: boolean;
  irregularFormula?: string;
}

// A date in the distant future, useful for when the API expects a cron expression but
// there is no meaningful value until further user action
export const QUARTZ_CRON_PENDING_INPUT = '59 59 23 31 12 ? 2099';

export const REPORT_CONTENT = {
  SIZE: {
    SMALL: { key: 'small', name: 'REPORT.SIZE.SMALL', width: 350 },
    MEDIUM: { key: 'medium', name: 'REPORT.SIZE.MEDIUM', width: 700 },
    LARGE: { key: 'large', name: 'REPORT.SIZE.LARGE', width: 1050 },
    CUSTOM: { key: 'custom', name: 'REPORT.SIZE.CUSTOM', width: undefined }
  } as ReportContentSizes,
  SHAPE: {
    STRIP: { key: 'strip', name: 'REPORT.SHAPE.STRIP', width: 16, height: 4 },
    RECTANGLE: { key: 'rectangle', name: 'REPORT.SHAPE.RECTANGLE', width: 16, height: 9 },
    SQUARE: { key: 'square', name: 'REPORT.SHAPE.SQUARE', width: 15, height: 15 }
  } as ReportContentShapes,
  SCALE: {
    SMALL: { key: 0.7, name: 'REPORT.SCALE.SMALL' },
    NORMAL: { key: 1.0, name: 'REPORT.SCALE.NORMAL' },
    LARGE: { key: 1.3, name: 'REPORT.SCALE.LARGE' },
    XLARGE: { key: 2.0, name: 'REPORT.SCALE.XLARGE' },
    HUGE: { key: 3.0, name: 'REPORT.SCALE.HUGE' }
  } as ReportContentScales,
  SUMMARY: {
    DISCRETE: {
      key: SummaryTypeEnum.DISCRETE,
      name: 'REPORT.SUMMARY.DISCRETE',
      value: { value: 0, units: 'min' },
      variable: true
    },
    NONE: {
      key: SummaryTypeEnum.NONE,
      name: 'REPORT.SUMMARY.NONE',
      variable: false
    },
    AUTO: {
      key: SummaryTypeEnum.AUTO,
      name: 'TOOLBAR.SUMMARY.OPTIONS.AUTO',
      value: 0,
      variable: true
    }
  } as ReportContentSummaries,
  MIN_NON_CUSTOM_HEIGHT: 130,
  CONTENT_MIN_SIZE: 1,
  CONTENT_MAX_SIZE: 10000
};

export type SummaryValue = undefined | FrontendDuration | number;

export interface ReportContentSummary {
  key: SummaryTypeEnum;
  name: string;
  value: SummaryValue;
  variable: boolean;
}

export interface ReportContentSummaries {
  DISCRETE: ReportContentSummary;
  NONE: ReportContentSummary;
  AUTO: ReportContentSummary;
}

export interface ReportContentSize {
  key: string;
  name: string;
  width: number;
}

export interface ReportContentSizes {
  SMALL: ReportContentSize;
  MEDIUM: ReportContentSize;
  LARGE: ReportContentSize;
  CUSTOM: ReportContentSize;
}

export interface ReportContentShape {
  key: string;
  name: string;
  width: number;
  height: number;
}

export interface ReportContentShapes {
  STRIP: ReportContentShape;
  RECTANGLE: ReportContentShape;
  SQUARE: ReportContentShape;
}

export interface ReportContentScale {
  key: number;
  name: string;
}

export interface ReportContentScales {
  SMALL: ReportContentScale;
  NORMAL: ReportContentScale;
  LARGE: ReportContentScale;
  XLARGE: ReportContentScale;
  HUGE: ReportContentScale;
}

export const KEEP_NAME = 'REPORT.CONTENT.KEEP';
export const KEEP_CURRENT_KEY = 'keep';
export const KEEP_CURRENT_SIZE = { key: KEEP_CURRENT_KEY, name: KEEP_NAME, width: undefined } as ReportContentSize;
export const KEEP_CURRENT_SHAPE = {
  key: KEEP_CURRENT_KEY,
  name: KEEP_NAME,
  width: undefined,
  height: undefined
} as ReportContentShape;
export const KEEP_CURRENT_SCALE = { key: KEEP_CURRENT_KEY, name: KEEP_NAME } as any;
export const KEEP_CURRENT_DATE_RANGE = { name: KEEP_NAME, id: KEEP_CURRENT_KEY };
export const KEEP_CURRENT_SUMMARY = { key: KEEP_CURRENT_KEY, name: KEEP_NAME };
export const KEEP_CURRENT_ASSET_SELECTION = { name: KEEP_NAME, selectionId: KEEP_CURRENT_KEY };

export interface SandboxMode {
  enabled: boolean;
  originalWorksheetId: string | null;
  sandboxedWorksheetId: string | null;
  sandboxedWorkbookId: string | null;
  sandboxOriginalCreatorName?: string | null;
}

export const TEMP_TOPIC_NAME = 'SandBoxed Topic';

export enum ReportClickActions {
  None,
  /** Ranges only **/
  Step,
  Warning,
  /** Ranges only **/
  Refresh,
  Edit,
  /** Asset Selections Only **/
  ChangeSibling
}

export enum ReportUpdateMessageType {
  FULL_UPDATE,
  SPECIFIC_UPDATES_ONLY,
}

export type ReportUpdateMessage = {
  type: ReportUpdateMessageType,
  message?: ReportUpdateMessageWithSpecificUpdates,
};

export interface ReportUpdateMessageWithSpecificUpdates {
  contentIds?: {
    updated: string[],
  };
  dateRangeIds?: {
    inserted?: string[],
    updated?: string[],
    removed?: string[],
  };
  assetSelectionIds?: {
    inserted?: string[],
    updated?: string[],
    removed?: string[],
  };
  commentIds?: {
    inserted?: string[],
    updated?: string[],
    removed?: string[],
  };
}

//region LiveScreenshotMessage

export type LiveScreenshotMessage =
  | LiveScreenshotMessageContent
  | LiveScreenshotMessageDateRange
  | LiveScreenshotMessageError
  ;

// Matches LiveScreenshotMessage.kt and ItemErrorMessage.java
export interface ScreenshotMessageBase {
  channelId: string;

  /// HTTP status code
  status: number;

  /// HTTP status reason (e.g. "OK" or "Not Found")
  statusText?: string;
}

export type LiveScreenshotMessageContent = ScreenshotMessageBase & {
  status: 200;
  content: {
    contentId: string;
    url: string;
    hashCode: string;
  };
};

export const isContentMessage = (msg: LiveScreenshotMessage): msg is LiveScreenshotMessageContent =>
  msg.status === 200 && msg['content'] != null;

export type LiveScreenshotMessageDateRange = ScreenshotMessageBase & {
  status: 200;
  dateRange: {
    dateRangeId: string;
    start: string;
    end: string;
  };
};

export const isDateRangeMessage = (msg: LiveScreenshotMessage): msg is LiveScreenshotMessageDateRange =>
  msg.status === 200 && msg['dateRange'] != null;

export type LiveScreenshotMessageError = ScreenshotMessageBase & {
  statusMessage?: string;
  itemType?: string;
  itemId?: string;
};

export const isErrorMessage = (msg: LiveScreenshotMessage): msg is LiveScreenshotMessageError =>
  msg.status !== 200;

//endregion

export enum CONTENT_LOADING_CLASS {
  ERROR = 'contentLoadError',
  NO_CAPSULE_ERROR = 'contentNoCapsuleError',
  SPINNER = 'loadingSpinner',
  LOADED = 'contentLoaded',
  PENDING = 'contentPending'
}

export enum CONTENT_STATE {
  ANY = '*',
  NONE = 'none',
  WORKBOOK = 'workbook',
  LINK = 'link',
  WORKSHEET = 'worksheet',
  PROPERTIES = 'properties',
  INSERT = 'insert',
  DONE = 'done'
}

export const SCREENSHOT_SIZE_TO_CONTENT = {
  DEFAULT_WIDTH: 100,
  DEFAULT_HEIGHT: 100,
  SELECTOR: '.screenshotSizeToContent'
};

export const DOCUMENT_LAYOUT = [
  { text: 'PDF_PREVIEW.LAYOUT.PORTRAIT', value: 'Portrait' },
  { text: 'PDF_PREVIEW.LAYOUT.LANDSCAPE', value: 'Landscape' }
];

export const DOCUMENT_PAPER_SIZE = [
  { text: 'PDF_PREVIEW.PAPER_SIZE.LETTER', value: 'Letter', width: '8.5in', height: '11in' },
  { text: 'PDF_PREVIEW.PAPER_SIZE.LEGAL', value: 'Legal', width: '8.5in', height: '14in' },
  { text: 'PDF_PREVIEW.PAPER_SIZE.A3', value: 'A3', width: '11.7in', height: '16.5in' },
  { text: 'PDF_PREVIEW.PAPER_SIZE.A4', value: 'A4', width: '8.3in', height: '11.7in' },
  { text: 'PDF_PREVIEW.PAPER_SIZE.A5', value: 'A5', width: '5.8in', height: '8.3in' },
  { text: 'PDF_PREVIEW.PAPER_SIZE.TABLOID', value: 'Tabloid', width: '11in', height: '17in' }
];

export const DOCUMENT_MARGIN_UNITS = [
  { shortLabel: 'in', unit: ['in'] },
  { shortLabel: 'cm', unit: ['cm'] },
  { shortLabel: 'mm', unit: ['mm'] },
  { shortLabel: 'px', unit: ['px'] }
];

export enum OFFSET_DIRECTION {
  PAST = 'past',
  FUTURE = 'future'
}

export const CAPSULE_SELECTION = {
  STRATEGY: {
    CLOSEST_TO: 'closestTo',
    OFFSET_BY: 'offsetBy'
  },
  REFERENCE: {
    START: 'start',
    END: 'end'
  }
};

export const DEFAULT_DATE_RANGE = {
  auto: {
    enabled: false,
    duration: moment.duration(1, 'days').valueOf(),
    offset: { value: 0, units: 'min' },
    offsetDirection: OFFSET_DIRECTION.PAST,
    cronSchedule: [QUARTZ_CRON_PENDING_INPUT],
    noCapsuleFound: false
  },
  condition: {
    strategy: CAPSULE_SELECTION.STRATEGY.CLOSEST_TO,
    reference: CAPSULE_SELECTION.REFERENCE.END,
    offset: 1,
    range: {}
  },
  range: {},
  enabled: true
};

export const CONDITION_TOOLS = [{
  id: 'periodic',
  name: 'PERIODIC_CONDITION.HEADER',
  description: 'REPORT.CONFIG.PERIODIC_CONDITION_DESCRIPTION'
}, {
  id: 'existing',
  name: 'REPORT.CONFIG.EXISTING_CONDITION',
  description: 'REPORT.CONFIG.EXISTING_CONDITION_DESCRIPTION'
}];

export const IMAGE_BORDER_CLASS = 'report-image-border';

export const REACT_JSON_VIEWS = ['TABLE'];

/**
 * @module The ReportEditor module provides the report editor component
 */
angular.module('Sq.Report', dependencies);
