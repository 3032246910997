import React, { useState, useRef } from 'react';
import _ from 'lodash';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { LogSenderModal } from '@/hybrid/logSender/LogSenderModal';
import { LogTrackerFilters } from '@/hybrid/logTracker/LogTrackerFilters.molecule';
import { FetchLogsOptions, LogTrackerService } from '@/hybrid/logTracker/LogTracker.service';
import { LogTrackerTable } from '@/hybrid/logTracker/LogTrackerTable.molecule';
import { NotificationsService } from '@/services/notifications.service';
import { UtilitiesService } from '@/services/utilities.service';

const logTrackerBindings = bindingsDefinition({
  sqLogTrackerService: injected<LogTrackerService>(),
  sqNotifications: injected<NotificationsService>(),
  sqUtilities: injected<UtilitiesService>()
});

export const LogTracker: SeeqComponent<typeof logTrackerBindings> = () => {
  const messagesEndRef = useRef(null);

  const {
    sqLogTrackerService,
    sqNotifications,
    sqUtilities
  } = useInjectedBindings(logTrackerBindings);

  const urlArguments = sqUtilities.parseQueryString(location.search);

  const [isFetching, setIsFetching] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(
    _.has(urlArguments, 'limit') ? _.toNumber(decodeURIComponent(urlArguments.limit as string)) : 20);
  const [endTime, setEndTime] = useState(
    _.has(urlArguments, 'endTime') ? decodeURIComponent(urlArguments.endTime as string) : '');
  const [fetchOptions, setFetchOptions] = useState({});

  /**
   * Scroll to the latest message in table
   */
  const scrollToLastMessage = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  /**
   * Retrieves logs going back from the oldest log message last retrieved for the current log file being examined.
   *
   * @param {boolean} append - True to append more logs, false to prepend them
   */
  const fetchMoreLogs = (append: boolean) => {
    setIsFetching(true);
    const options = _.defaults(sqLogTrackerService.generateMoreLogsOptions(append, data), fetchOptions);
    setScrollToBottom(append);
    sqLogTrackerService.fetchLogs(options, data)
      .then(setData)
      .catch(error => sqNotifications.apiError(error, { skipRedaction: true, displayForbidden: true }))
      .finally(() => {
        setIsFetching(false);
        if (append) {
          scrollToLastMessage();
        }
      });
  };

  /**
   * Returns log messages based on the selected log file based and input filters. Ensures that no more than one
   * request is queued at a time.
   *
   * @param {FetchLogsOptions} options
   */
  const fetchLogs = (options: FetchLogsOptions) => {
    setFetchOptions(options);
    setIsFetching(true);
    sqLogTrackerService.fetchLogs(options, data)
      .then(setData)
      .catch(error => sqNotifications.apiError(error, { skipRedaction: true, displayForbidden: true }))
      .finally(() => {
        setIsFetching(false);
        if (scrollToBottom) {
          scrollToLastMessage();
        }
      });
  };

  return (
    <>
      <div className="logTracker flexRowContainer flexFill">
        <div className="flexRowContainer">
          <LogTrackerFilters
            onModifyFilters={fetchLogs}
            onShowModal={setShowModal}
            isFetching={isFetching}
            setFetching={setIsFetching}
            endTime={endTime}
            setEndTime={setEndTime}
            limit={limit}
            setLimit={setLimit}
          />
        </div>
        <LogTrackerTable
          messages={data}
          modifyEndTime={setEndTime}
          messagesEndRef={messagesEndRef}
          onFetchMoreLogs={fetchMoreLogs}
          limit={limit}
          isFetching={isFetching} />
      </div>
      {showModal && <LogSenderModal
        onClose={() => setShowModal(false)} />}
    </>
  );
};

export const sqLogTracker = angularComponent(logTrackerBindings, LogTracker);
