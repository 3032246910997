import _ from 'lodash';
import React from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { UtilitiesService } from '@/services/utilities.service';
import { TableBuilderStore } from '@/hybrid/tableBuilder/tableBuilder.store';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { TableBuilderToolbar } from '@/hybrid/tableBuilder/TableBuilderToolbar.organism';
import { TableBuilderHelperService } from '@/hybrid/tableBuilder/tableBuilderHelper.service';
import { ScorecardStore } from '@/investigate/scorecard/scorecard.store';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { TableBuilderActions } from '@/hybrid/tableBuilder/tableBuilder.actions';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { NotificationsService } from '@/services/notifications.service';
import { DurationStore } from '@/trendData/duration.store';
import { TableBuilderOldMetricTable } from '@/hybrid/tableBuilder/TableBuilderOldMetricTable.organism';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { ThresholdMetricActions } from '@/hybrid/tools/thresholdMetric/thresholdMetric.actions';
import { ITEM_DATA_STATUS, ITEM_TYPES } from '@/trendData/trendData.module';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { SystemConfigurationService } from '@/services/systemConfiguration.service';
import { TableBuilderPropsOnly } from '@/hybrid/tableBuilder/TableBuilderPropsOnly.organism';
import { TableBuilderMode } from '@/hybrid/tableBuilder/tableBuilder.module';

const tableBuilderBindings = bindingsDefinition({
  sqTableBuilderActions: injected<TableBuilderActions>(),
  sqTableBuilderStore: injected<TableBuilderStore>(),
  sqTableBuilderHelper: injected<TableBuilderHelperService>(),
  sqScorecardStore: injected<ScorecardStore>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqUtilities: injected<UtilitiesService>(),
  sqNotifications: injected<NotificationsService>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqDurationStore: injected<DurationStore>(),
  sqThresholdMetricActions: injected<ThresholdMetricActions>(),
  sqSystemConfiguration: injected<SystemConfigurationService>()
});

export interface TableTextFormatterIF {
  setColumnBackground: (key: string | number, color: string) => void;
  setColumnTextAlign: (key: string, alignment: string) => void;
  setColumnTextColor: (key: string | number, color: string) => void;
  setColumnTextStyle: (key: string, styles: string[]) => void;
  setHeaderBackground: (key: string | number, color: string) => void;
  setHeaderTextAlign: (key: string, alignment: string) => void;
  setHeaderTextColor: (key: string | number, color: string) => void;
  setHeaderTextStyle: (key: string, styles: string[]) => void;
  setStyleToAllHeaders: (key: string) => void;
  setStyleToAllColumns: (key: string) => void;
  setStyleToAllHeadersAndColumns: (key: string) => void;
  copyStyle: (key: string) => void;
  pasteStyleOnHeader: (key: string) => void;
  pasteStyleOnColumn: (key: string) => void;
  pasteStyleOnHeaderAndColumn: (key: string) => void;
}

export interface TableSortIF {
  canSort?: boolean;
  canSortDisabledTooltip?: string;
  sortDirection?: string;
  sortLevel?: number;
  maxSortLevel?: number;
  sortByColumn?: (key: string, direction: string) => void;
}

export const TableBuilder: SeeqComponent<typeof tableBuilderBindings> = () => {
  const {
    sqTableBuilderActions,
    sqTableBuilderStore,
    sqTableBuilderHelper,
    sqScorecardStore,
    sqInvestigateActions,
    sqUtilities,
    sqNotifications,
    sqWorksheetStore,
    sqDurationStore,
    sqSystemConfiguration
  } = useInjectedBindings(tableBuilderBindings);

  const {
    columns,
    conditionTableColumns,
    headers,
    conditionTableData,
    simpleTableData,
    isTransposed,
    isMigrating,
    isTableStriped,
    fetchFailedMessage,
    itemFilters,
    itemSorts,
    chartViewSettings,
    distinctStringValueMap
  } = useFlux(sqTableBuilderStore);

  const { displayRange } = useFlux(sqDurationStore);
  let tableBuilderRef = null;

  const { t } = useTranslation();

  const scorecardStore = useFlux(sqScorecardStore);
  const oldMetrics = scorecardStore.metrics;
  const pageUrl = new URL(window.location.href || '/');

  const isSimpleMode = useFluxPath(sqTableBuilderStore, () => sqTableBuilderStore.isSimpleMode());
  const showTableView = useFluxPath(sqTableBuilderStore, () => sqTableBuilderStore.showChartView);
  const isTablesChartViewEnabled = sqSystemConfiguration.isTablesChartViewEnabled;
  const isPresentationMode: boolean = sqUtilities.isPresentationWorkbookMode;
  const isViewOnlyMode: boolean = sqUtilities.isViewOnlyWorkbookMode;
  const isRenderer: string = pageUrl.searchParams.get('isRenderer');
  const canEdit = !isPresentationMode && !isViewOnlyMode;
  const { timezone } = useFlux(sqWorksheetStore);

  const isMigrationRequired: boolean = !_.isEmpty(oldMetrics);
  const allItems = sqTableBuilderStore.getTableItems();
  const startMode: boolean = allItems.length === 0;
  const isLoading: boolean = _.some(allItems, { dataStatus: ITEM_DATA_STATUS.LOADING });
  const hasFetchError = !_.isUndefined(fetchFailedMessage);
  const isBadRequestError = hasFetchError && _.includes(fetchFailedMessage, '400 Bad');
  const sanitizeBadRequestError = message => message.replace(/\(GET .*?\)/, '');

  const tableItems = sqTableBuilderStore.getTableItems();
  const canSort = sqTableBuilderStore.isUserSortAllowed() && !isPresentationMode;

  const hasOnlyStringSeries = _.chain(tableItems)
    .filter(item => item.itemType === ITEM_TYPES.SERIES)
    .every(signal => sqUtilities.isStringSeries(signal))
    .value();
  const hasNumericAndStringSeries = sqTableBuilderHelper.hasNumericAndStringItems(tableItems, ITEM_TYPES.SERIES);
  const hasOnlyStringMetrics = _.chain(tableItems)
    .filter(item => item.itemType === ITEM_TYPES.METRIC)
    .every(signal => sqUtilities.isStringSeries(signal))
    .value();
  const hasNumericAndStringMetrics = sqTableBuilderHelper.hasNumericAndStringItems(tableItems, ITEM_TYPES.METRIC);

  const copyToClipboard = () => {
    try {
      if (!tableBuilderRef) {
        sqNotifications.error(t('TABLE_BUILDER.COPY_TO_CLIPBOARD_EMPTY'));
      } else {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(tableBuilderRef);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        selection.removeAllRanges();
        sqNotifications.success(t('TABLE_BUILDER.COPY_TO_CLIPBOARD_SUCCESS'));
      }
    } catch (err) {
      sqNotifications.error(t('TABLE_BUILDER.COPY_TO_CLIPBOARD_ERROR'));
    }
  };

  const setActiveRefElement = el => tableBuilderRef = el;
  const isTableEmpty = (!isSimpleMode && !conditionTableData.capsules.length) ||
    (isSimpleMode && !simpleTableData.length);

  const textFormatter: TableTextFormatterIF = {
    setColumnBackground: sqTableBuilderActions.setColumnBackground,
    setColumnTextAlign: sqTableBuilderActions.setColumnTextAlign,
    setColumnTextColor: sqTableBuilderActions.setColumnTextColor,
    setColumnTextStyle: sqTableBuilderActions.setColumnTextStyle,
    setHeaderBackground: sqTableBuilderActions.setHeaderBackground,
    setHeaderTextAlign: sqTableBuilderActions.setHeaderTextAlign,
    setHeaderTextColor: sqTableBuilderActions.setHeaderTextColor,
    setHeaderTextStyle: sqTableBuilderActions.setHeaderTextStyle,
    setStyleToAllHeaders: sqTableBuilderActions.setStyleToAllHeaders,
    setStyleToAllColumns: sqTableBuilderActions.setStyleToAllColumns,
    setStyleToAllHeadersAndColumns: sqTableBuilderActions.setStyleToAllHeadersAndColumns,
    copyStyle: sqTableBuilderActions.copyStyle,
    pasteStyleOnHeader: sqTableBuilderActions.pasteStyleOnHeader,
    pasteStyleOnColumn: sqTableBuilderActions.pasteStyleOnColumn,
    pasteStyleOnHeaderAndColumn: sqTableBuilderActions.pasteStyleOnHeaderAndColumn
  };

  return <>
    <TableBuilderToolbar copyToClipboard={() => sqTableBuilderHelper.copyToClipboard(tableBuilderRef)}
      isTableEmpty={isTableEmpty} />
    {isMigrationRequired &&
    <>
      {canEdit &&
      <div className="flexRowContainer flexCenter">
        <div className="alert alert-info fs16 p25 mt20 max-width-500 text-center">
          <p>{t('TABLE_BUILDER.MIGRATE')}</p>
          <TextButton
            extraClassNames="btn btn-primary mt10"
            label="TABLE_BUILDER.MIGRATE_NOW"
            disabled={isMigrating}
            onClick={sqTableBuilderActions.migrate} />
        </div>
      </div>}
      <div className="overflowYAuto" ref={setActiveRefElement}>
        <TableBuilderOldMetricTable
          oldMetrics={oldMetrics}
        />
      </div>
    </>}
    {!isMigrationRequired &&
    <TableBuilderPropsOnly
      conditionTableData={conditionTableData}
      isLoading={isLoading}
      headers={headers}
      conditionColumns={conditionTableColumns}
      isTransposed={isTransposed}
      isTableStriped={isTableStriped}
      isPresentationMode={isPresentationMode}
      canEdit={canEdit}
      timezone={timezone}
      setCellText={sqTableBuilderActions.setCellText}
      setHeaderText={sqTableBuilderActions.setHeaderText}
      setColumnFilter={sqTableBuilderActions.setColumnFilter}
      itemFilters={itemFilters}
      distinctStringValueMap={distinctStringValueMap}
      columnToThresholdsForCondition={sqTableBuilderStore.getColumnToThresholdsForCondition()}
      textFormatter={textFormatter}
      canSort={canSort}
      itemSorts={itemSorts}
      maxSortLevel={sqTableBuilderStore.maxSortLevel}
      moveColumn={sqTableBuilderActions.moveColumn}
      removeColumn={sqTableBuilderActions.removeColumn}
      displayMetricOnTrend={sqTableBuilderActions.displayMetricOnTrend}
      sortByColumn={sqTableBuilderActions.sortByColumn}
      simpleTableData={simpleTableData}
      simpleColumns={columns}
      isViewOnlyMode={isViewOnlyMode}
      displayRange={displayRange}
      columnToThresholdsForSimple={sqTableBuilderStore.getColumnToThresholdsForSimple()}
      hasOnlyStringSeries={hasOnlyStringSeries}
      hasNumericAndStringSeries={hasNumericAndStringSeries}
      hasOnlyStringMetrics={hasOnlyStringMetrics}
      hasNumericAndStringMetrics={hasNumericAndStringMetrics}
      startMode={startMode}
      sanitizeBadRequestError={sanitizeBadRequestError}
      fetchFailedMessage={fetchFailedMessage}
      hasFetchError={hasFetchError}
      setActiveRefElement={setActiveRefElement}
      setActiveTool={sqInvestigateActions.setActiveTool}
      isSimpleMode={isSimpleMode}
      isBadRequestError={isBadRequestError}
      showChartView={isTablesChartViewEnabled && showTableView && !isRenderer}
      chartViewSettings={chartViewSettings}
      setChartViewSettings={sqTableBuilderActions.setChartViewSettings}
    />}
  </>;
};

export const sqTableBuilder = angularComponent(tableBuilderBindings, TableBuilder);
