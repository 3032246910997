/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class AuditApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Get a collection of Audit Trail entries
   * @param {string} [startTime] - The start time for the query, formatted as an ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm)
   * @param {string} [endTime] - The end time for the query, formatted as an ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm)
   * @param {Array<string>} [userID] - User ID(s) to filter on
   * @param {Array<string>} [itemID] - Item ID(s) to filter on
   * @param {Array<string>} [itemType] - Item type(s) to filter on
   * @param {Array<string>} [changeType] - Change type(s) to filter on (Create, Update, Delete)
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=100] - The pagination limit, the total number of collection items that will be returned in this page of results
   * @param {boolean} [sortAsc=true] - When true sorts by oldest entries first, when false sorts by newest entries first
   */
  public getAuditEntries(
    {
      startTime,
      endTime,
      userID,
      itemID,
      itemType,
      changeType,
      offset,
      limit,
      sortAsc
    } : {
      startTime?: string,
      endTime?: string,
      userID?: Array<string>,
      itemID?: Array<string>,
      itemType?: Array<string>,
      changeType?: Array<string>,
      offset?: number,
      limit?: number,
      sortAsc?: boolean
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/audit',
      params: omitBy({
        ['startTime']: startTime,
        ['endTime']: endTime,
        ['userID']: userID,
        ['itemID']: itemID,
        ['itemType']: itemType,
        ['changeType']: changeType,
        ['offset']: offset,
        ['limit']: limit,
        ['sortAsc']: sortAsc
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AuditOutputListV1>;
  }

}
