import angular from 'angular';
import template from './byCondition.component.html';

angular.module('Sq.Investigate').component('sqByCondition', {
  controller: 'ByConditionCtrl',
  template,
  bindings: {
    id: '<',
    startNumber: '<',
    originalParameters: '<'
  }
});
