import angular from 'angular';

angular.module('Sq.Directives.Onload', [])
  .directive('sqOnload', sqOnload);

function sqOnload() {
  interface IOnloadScope extends ng.IScope {
    sqOnload: Function;
  }
  return {
    restrict: 'A',
    scope: {
      sqOnload: '&'
    },
    link($scope: IOnloadScope, $element: JQuery) {
      $element.on('load', function($event) {
        $scope.$evalAsync(() => {
          $scope.sqOnload({ $element, $event });
        });
      });
    }
  };
}
