import React, { useCallback, useState } from 'react';
import { FormControl, Modal } from 'react-bootstrap';
import _ from 'lodash';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { TrackService } from '@/track/track.service';
import { WorkbookActions } from '@/workbook/workbook.actions';
import { NotificationsService } from '@/services/notifications.service';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { APP_STATE, DEBOUNCE, MAX_NAME_LENGTH } from '@/main/app.constants';
import { SelectIdentity } from '@/hybrid/core/SelectIdentity.molecule';
import { HomeScreenStore } from '@/hybrid/homescreen/homescreen.store';
import { ITEM_TYPES } from '@/hybrid/homescreen/homescreen.module';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';

const editWorkbookModalBindings = bindingsDefinition({
  onClose: prop<() => void>(),
  id: prop<string>(),
  show: prop<boolean>(),
  type: prop<string>(),
  name: prop<string>(),
  description: prop<string>(),
  parentFolderId: prop.optional<string>(),
  owner: prop.optional<{ name: string, id: string, isAdmin: boolean }>(),
  renderer: prop.optional<{ name: string, id: string }>(),
  isCorporate: prop.optional<boolean>(),
  sqWorkbookActions: injected<WorkbookActions>(),
  sqNotifications: injected<NotificationsService>(),
  sqHomeScreenStore: injected<HomeScreenStore>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  sqTrack: injected<TrackService>(),
  $state: injected<ng.ui.IStateService>()
});

export const EditWorkbookModal: SeeqComponent<typeof editWorkbookModalBindings> = (props) => {
  const {
    sqWorkbookActions,
    sqNotifications,
    sqTrack,
    $state,
    sqHomeScreenStore,
    sqHomeScreenActions
  } = useInjectedBindings(editWorkbookModalBindings);
  const { onClose, id, type, show, parentFolderId } = props;
  const { t } = useTranslation();

  const [name, setName] = useState(props.name);
  const [owner, setOwner] = useState(props.owner);
  const [renderer, setRenderer] = useState(props.renderer);
  const [description, setDescription] = useState(props.description);

  if (type === ITEM_TYPES.TOPIC && !renderer) {
    setRenderer(owner);
  }
  const btnEnabled = !_.isEmpty(name) && !_.isEmpty(owner?.id || null)
    && (!_.isEmpty(renderer?.id || null) || type !== ITEM_TYPES.TOPIC);
  const callbackRef = useCallback((titleElement) => {
    const delay = setTimeout(() => {
      if (titleElement) {
        titleElement.focus();
      }
    }, DEBOUNCE.SHORT);
    return () => clearTimeout(delay);
  }, []);

  const trackAndCallbackIfChanged = (newValue, previousValue, callback, trackCategory, trackAction,
    trackInformation) => {
    if (previousValue !== newValue) {
      return callback().then(() => sqTrack.doTrack(trackCategory, trackAction, trackInformation));
    } else {
      return Promise.resolve();
    }
  };

  const save = (e) => {
    e.preventDefault();
    let folderId = id;
    return Promise.resolve()
      .then(() => {
        if (!id) {
          const ownerId = props.owner.isAdmin ? owner.id : undefined;
          return sqHomeScreenActions.addFolder({ name, parentFolderId, ownerId }).then(folder => folderId = folder.id);
        } else {
          return trackAndCallbackIfChanged(name, props.name,
            () => sqWorkbookActions.setName(folderId, name, props.name),
            'Workbench_2.0', type, 'renamed');
        }
      })
      .then(() => trackAndCallbackIfChanged(description, props.description,
        () => sqWorkbookActions.setDescription(folderId, description),
        'Workbench_2.0', type, 'description changed'))
      .then(() => {
        if (id) {
          trackAndCallbackIfChanged(owner.id, props.owner.id,
            () => sqWorkbookActions.setItemOwner(id, owner),
            'Workbench_2.0', type, 'owner changed');
        }
      })
      .then(() => {
        if (renderer) {
          trackAndCallbackIfChanged(renderer.id, props.renderer?.id || null,
            () => sqWorkbookActions.setItemRenderer(folderId, renderer),
            'Workbench_2.0', type, 'renderer changed');
        }
      })
      .then(() => {
        onClose();
        sqNotifications.successTranslate('CHANGES_SAVED');
        $state.go(APP_STATE.FOLDER_EXPANDED, { currentFolderId: sqHomeScreenStore.currentFolderId },
          { reload: true });
      })
      .catch((error) => {
        sqTrack.doTrack('Workbench_2.0', type, 'side panel save failed');
        sqNotifications.apiError(error);
      })
      .then(() => {
        const folderId = sqHomeScreenStore?.currentFolderId ?? null;
      });
  };

  return (
    <Modal show={show} onHide={onClose} animation={false} data-testid="editWorkbookModal">
      <Modal.Header closeButton={true}>
        <h3>{t('WORKBOOK_EDIT')}</h3>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={e => save(e)}>
          <div className="form-group">
            <label>{t('NAME')}</label>
            <FormControl
              autoComplete="off"
              onFocus={e => e.target.select()}
              id="name"
              name="name"
              value={name}
              data-testid="name"
              onChange={e => setName(e.target.value)}
              maxLength={MAX_NAME_LENGTH.WORKBOOK}
              ref={callbackRef}
            />
          </div>
          {!name && <p className="help-block">{t('FORM.REQUIRED_FIELD')}</p>}
          <div className="form-group">
            <label htmlFor="description">{t('DESCRIPTION')}</label>
            <textarea className="form-control" id="description" name="description"
              onChange={e => setDescription(e.target.value)}
              rows={5} value={description} />
          </div>
          {props.isCorporate && // corporate items' owners can change and should always be viewable
          <div className="form-group">
            <label htmlFor="owner">{t('OWNER')}</label>
            <SelectIdentity idForLabel="owner" identity={owner} setIdentity={setOwner}
              allowGroups={false} placeholder="CHANGE_OWNER.PLACEHOLDER"
              tooltip="CHANGE_OWNER.TOOLTIP" unauthorizedTooltip="CHANGE_OWNER.NOT_PERMITTED_TO_CHANGE" />
          </div>}
          {type === ITEM_TYPES.TOPIC &&
          <div className="form-group">
            <label htmlFor="renderer">{t('RENDERER')}</label>
            <SelectIdentity idForLabel="renderer" identity={renderer}
              setIdentity={setRenderer} allowGroups={true} placeholder="CHANGE_RENDERER.PLACEHOLDER"
              tooltip="CHANGE_RENDERER.TOOLTIP" unauthorizedTooltip="CHANGE_RENDERER.NOT_PERMITTED_TO_CHANGE" />
          </div>
          }

          <div className="text-center">
            <button type="button" className="btn btn-default btn-sm mr20" onClick={onClose}>{t('CANCEL')}</button>
            <button type="submit" className="btn btn-primary btn-sm" disabled={!btnEnabled}>{t('SAVE')}</button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
