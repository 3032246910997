import _ from 'lodash';
import angular from 'angular';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';
import { ItemsApi } from '@/sdk';
import { DateTimeService } from '@/datetime/dateTime.service';
import { UtilitiesService } from '@/services/utilities.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';

/**
 * @file Gets the worksheets data.
 */
angular.module('Sq.Worksheets').factory('sqWorksheets', sqWorksheets);

export type WorksheetsService = ReturnType<typeof sqWorksheets>;

function sqWorksheets(
  $q: ng.IQService,
  $http: ng.IHttpService,
  sqItemsApi: ItemsApi,
  sqDateTime: DateTimeService,
  sqUtilities: UtilitiesService
) {
  const DEFAULT_WORKSHEET_NAME = 'worksheet';
  const service = {
    getWorksheets,
    getWorksheet,
    createWorksheet,
    branchWorksheet,
    setName,
    archiveWorksheet,
    restoreWorksheet,
    moveWorksheet
  };

  return service;

  /**
   * Get the Worksheets for a workbook.
   *
   * @param {String} workbookId - The GUID of the workbook to be fetched
   * @param {Boolean} [includeWorkstepId=false] - true to have worksheets include the current workstep ID
   * @param {Boolean} [includeArchivedWorksheets=false] - true to return the workbook's archived worksheets
   * @return {Promise} resolves with an array of worksheet objects
   */
  function getWorksheets(workbookId, includeWorkstepId = false, includeArchivedWorksheets = false) {
    // Would probably be best to integrate pagination with infinite worksheet scrolling.
    // For now, just make the limit so high that it won't be an issue.
    return $http.get(`${APPSERVER_API_PREFIX}/workbooks/${workbookId}/worksheets`,
      { params: { limit: 10000, isArchived: includeArchivedWorksheets } })
      .then(_.property('data.worksheets'))
      .then(worksheets => _.map(worksheets, worksheet => toWorksheet(worksheet, includeWorkstepId)));
  }

  /**
   * Gets a Worksheet.
   *
   * @param {String} workbookId - a workbook ID
   * @param {String} worksheetId - a worksheet ID
   * @param {boolean} [includeArchivedWorksheets=false] - true to return the workbook's archived worksheets
   *
   * @return {Promise} Promise that is resolved when the call completes.
   */
  function getWorksheet(workbookId, worksheetId, includeArchivedWorksheets = false) {
    return $http.get(`${APPSERVER_API_PREFIX}/workbooks/${workbookId}/worksheets/${worksheetId}`,
      { params: { isArchived: includeArchivedWorksheets } })
      .then(worksheet => toWorksheet(worksheet.data));
  }

  /**
   * Creates a Worksheet.
   *
   * @param {String} workbookId - ID of the workbook on which to create the worksheet
   * @param {String} name - Name for the new worksheet
   * @return {Promise} resolves when the worksheet has been created
   */
  function createWorksheet(workbookId, name) {
    name = name || DEFAULT_WORKSHEET_NAME;
    return $http.post(`${APPSERVER_API_PREFIX}/workbooks/${workbookId}/worksheets`, { name })
      .then(worksheet => toWorksheet(worksheet.data));
  }

  /**
   * Branches a Worksheet.
   *
   * @param {String} workbookId - The workbook unique ID
   * @param {String} worksheetId - ID of the worksheet to be branched
   * @param {String} name - Name for the newly branched worksheet
   * @returns {Object} object - A container object
   *          {String} object.name - The branched worksheet name
   *          {String} object.worksheetId - The unique ID of the branched worksheet
   */
  function branchWorksheet(workbookId, worksheetId, name) {
    name = name || DEFAULT_WORKSHEET_NAME;
    return $http.post(`${APPSERVER_API_PREFIX}/workbooks/${workbookId}/worksheets`, { name, branchFrom: worksheetId })
      .then(worksheet => toWorksheet(worksheet.data));
  }

  /**
   * Updates a worksheet's name.
   *
   * @param {String} worksheetId - GUID of the worksheet to be renamed
   * @param {String} name - The new name for the worksheet
   * @returns {Promise} - A promise that will resolve when the worksheet
   * has been successfully renamed; or reject if it was not renamed.
   */
  function setName(worksheetId, name) {
    return sqItemsApi.setProperty({ value: name }, { id: worksheetId, propertyName: SeeqNames.Properties.Name });
  }

  /**
   * Archives a Worksheet.
   *
   * @param {String} worksheetId - The GUID of the worksheet to be archived.
   *
   * @return {Promise} Promise that is resolved when the worksheet has been archived.
   * @example sqWorksheets.archiveWorksheet(worksheetId);
   */
  function archiveWorksheet(worksheetId) {
    return sqItemsApi.setProperty({ value: true }, { id: worksheetId, propertyName: SeeqNames.Properties.Archived });
  }

  /**
   * Restores (unarchives) a Worksheet.
   *
   * @param {String} worksheetId - The GUID of the worksheet to be restored.
   *
   * @return {Promise} Promise that is resolved when the worksheet has been restored.
   */
  function restoreWorksheet(worksheetId) {
    return sqItemsApi.setProperty({ value: false }, { id: worksheetId, propertyName: SeeqNames.Properties.Archived });
  }

  /**
   * Moves a Worksheet.
   *
   * @param  {String} workbookId - ID of the workbook on which the worksheet exists
   * @param  {String} worksheetId - ID of the worksheet to move
   * @param  {String} nextWorksheetId - ID of the worksheet marking the new location. If undefined, worksheet is moved
   *   to the end.
   * @return {Promise} Promise that is resolved when the call completes.
   */
  function moveWorksheet(workbookId, worksheetId, nextWorksheetId) {
    const queryParam = nextWorksheetId ? `?nextWorksheetId=${nextWorksheetId}` : '';
    return $http.post(
      `${APPSERVER_API_PREFIX}/workbooks/${workbookId}/worksheets/${worksheetId}/next${queryParam}`, {});
  }

  /**
   * Helper function that creates a worksheet object to be dispatched to a store
   *
   * @param {Object} worksheet - The worksheet from the API
   * @param {Boolean} [includeWorkstepId=false] - true to have worksheets include the current workstep ID
   * @returns {Object} Worksheet for a store
   */
  function toWorksheet(worksheet, includeWorkstepId = false) {
    return _.omitBy({
      name: worksheet.name,
      createdAt: sqDateTime.parseISODate(worksheet.createdAt).valueOf(),
      updatedAt: sqDateTime.parseISODate(worksheet.updatedAt).valueOf(),
      worksheetId: worksheet.id,
      reportId: _.get(worksheet, 'report.id'),
      workstepId: includeWorkstepId ? worksheet.workstep : undefined
    }, _.isNil);
  }
}
