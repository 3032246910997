/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as models from './models';

export interface ThresholdMetricOutputV1 {
    /**
     * Additional properties of the item
     */
    "additionalProperties"?: Array<models.ScalarPropertyV1>;
    /**
     * The ID of the aggregation condition representing metric value information
     */
    "aggregationConditionId"?: string;
    /**
     * Aggregation formula that aggregates the measured item
     */
    "aggregationFunction"?: string;
    /**
     * The condition that, if present, will be used to aggregate the measured item
     */
    "boundingCondition"?: models.ItemPreviewWithAssetsV1;
    /**
     * The maximum capsule duration that is applied to the bounding condition if it does not have one
     */
    "boundingConditionMaximumDuration"?: models.ScalarValueOutputV1;
    /**
     * The data ID of this asset. Note: This is not the Seeq ID, but the unique identifier that the remote datasource uses.
     */
    "dataId"?: string;
    /**
     * The datasource class, which is the type of system holding the item, such as OSIsoft PI
     */
    "datasourceClass"?: string;
    /**
     * The datasource identifier, which is how the datasource holding this item identifies itself
     */
    "datasourceId"?: string;
    /**
     * Clarifying information or other plain language description of this item
     */
    "description"?: string;
    /**
     * A signal or formula function that evaluates to a signal that can be used to visualize the metric
     */
    "displayItem": models.ItemPreviewV1;
    /**
     * The duration over which to calculate a moving aggregation
     */
    "duration"?: models.ScalarValueOutputV1;
    /**
     * The permissions the current user has to the item.
     */
    "effectivePermissions"?: models.PermissionsV1;
    /**
     * The ID that can be used to interact with the item
     */
    "id": string;
    /**
     * Whether item is archived
     */
    "isArchived"?: boolean;
    /**
     * Whether item is redacted
     */
    "isRedacted"?: boolean;
    /**
     * The input Signal or Condition to measure
     */
    "measuredItem": models.ItemPreviewWithAssetsV1;
    /**
     * The human readable name
     */
    "name": string;
    /**
     * Either the custom-set neutral color for this metric or the color of the neutral Priority
     */
    "neutralColor"?: string;
    /**
     * The format string used for numbers associated with this signal.
     */
    "numberFormat"?: string;
    /**
     * The period at which to sample when creating the moving aggregation
     */
    "period"?: models.ScalarValueOutputV1;
    /**
     * The process type of threshold metric. Will be Continuous if duration and period are specified, Condition if boundingCondition is specified, and otherwise Simple.
     */
    "processType": ProcessTypeEnum;
    /**
     * The ID of the workbook to which this item is scoped or null if it is in the global scope.
     */
    "scopedTo"?: string;
    /**
     * A plain language status message with information about any issues that may have been encountered during an operation
     */
    "statusMessage"?: string;
    /**
     * The list of thresholds that are scalars, signals, or conditions along with the associated priority. These thresholds are those that were used as inputs and which are used to generate the condition thresholds
     */
    "thresholds"?: Array<models.ThresholdOutputV1>;
    /**
     * The item's translation key, if any
     */
    "translationKey"?: string;
    /**
     * The type of the item
     */
    "type": string;
    /**
     * The unit of measure of the metric
     */
    "valueUnitOfMeasure"?: string;
}

export enum ProcessTypeEnum {
    Simple = 'Simple' as any,
    Condition = 'Condition' as any,
    Continuous = 'Continuous' as any
}
