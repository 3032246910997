import angular from 'angular';
import { TrendActions } from '@/trendData/trend.actions';

angular.module('Sq.Investigate').controller('ScopeModalCtrl', ScopeModalCtrl);

function ScopeModalCtrl(
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  sqTrendActions: TrendActions,
  itemId: string,
) {
  const vm = this;
  vm.close = $uibModalInstance.close;
  vm.setGlobalScope = setGlobalScope;
  vm.toggleMakeGlobal = toggleMakeGlobal;
  vm.makeGlobal = false;

  /**
   * Makes the item available outside this analysis
   */
  function setGlobalScope() {
    return sqTrendActions.setGlobalScope(itemId).then(() => {
      $uibModalInstance.close(true);
    });
  }

  /**
   * Toggles the makeGlobal property
   */
  function toggleMakeGlobal() {
    vm.makeGlobal = !vm.makeGlobal;
  }
}
