import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { ExplorerModalBody } from '@/hybrid/explorer/ExplorerModalBody.molecule';
import { ReportContentProperties } from '@/hybrid/reportEditor/ReportContentProperties.molecule';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { ReportContentStore } from '@/reportEditor/reportContent.store';
import { ReportContentActions } from '@/reportEditor/reportContent.actions';
import { ExploreWorkbookModalStore } from '@/hybrid/explorer/exploreWorkbookModal.store';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { ExploreWorkbookModalActions } from '@/hybrid/explorer/exploreWorkbookModal.actions';
import { TrackService } from '@/track/track.service';
import { NotificationsService } from '@/services/notifications.service';
import { ReportActions } from '@/reportEditor/report.actions';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { FroalaReportContentService } from '@/reportEditor/froalaReportContent.service';
import { SelectWorksheet } from '@/hybrid/worksheets/SelectWorksheet.molecule';
import { WizardModal } from '@/hybrid/core/WizardModal.molecule';
import { ReportContentUrl } from '@/hybrid/reportEditor/ReportContentUrl.molecule';
import { DEBOUNCE } from '@/main/app.constants';
import { useDebounce } from '@/hybrid/core/useDebounce.hook';

const reportContentModalBindings = bindingsDefinition({
  sqReportEditor: injected<ReportEditorService>(),
  sqReportContentStore: injected<ReportContentStore>(),
  sqExploreWorkbookModalStore: injected<ExploreWorkbookModalStore>(),
  sqExploreWorkbookModalActions: injected<ExploreWorkbookModalActions>(),
  sqTrack: injected<TrackService>(),
  sqReportContentActions: injected<ReportContentActions>(),
  sqFroalaReportContent: injected<FroalaReportContentService>(),
  sqNotifications: injected<NotificationsService>(),
  sqReportActions: injected<ReportActions>(),
  sqReportContent: injected<ReportContentService>()
});

export const ReportContentModal: SeeqComponent<typeof reportContentModalBindings> = () => {
  const {
    sqReportContentStore,
    sqExploreWorkbookModalStore,
    sqExploreWorkbookModalActions,
    sqReportEditor,
    sqReportContentActions,
    sqFroalaReportContent,
    sqNotifications,
    sqReportActions,
    sqReportContent,
    sqTrack
  } = useInjectedBindings(reportContentModalBindings);

  const { workbookId } = useFlux(sqExploreWorkbookModalStore);
  const {
    modalName,
    worksheetId,
    workstepId,
    sourceUrl
  } = useFlux(sqReportContentStore);

  const [evaluatingVisualizationOptions, setEvaluatingVisualizationOptions] = useState(false);
  const offset = sqReportEditor.getScrollOffset();
  const [allowedTypes] = useState([SeeqNames.Types.Folder, SeeqNames.Types.Analysis]);
  const [stepChanged, setStepChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (modalName) {
      sqReportEditor.saveSelection();
    }
  }, [modalName]);

  const onClose = () => {
    sqReportEditor.setScrollOffset(offset);
    sqReportEditor.restoreSelection();
    sqExploreWorkbookModalActions.clear();
    sqReportContentActions.clear();
    setIsSaving(false);
    sqReportContentActions.setModalName('');
  };

  /**
   * Inserts or replaces content in the report
   */
  const save = () => {
    setIsSaving(true);
    sqReportEditor.restoreSelection();

    return sqReportActions.saveContent({ ...sqReportContentStore, isArchived: false })
      .then((content) => {
        sqReportContent.insertOrReplaceContent(content.id);
        sqReportEditor.setScrollOffset(offset);
        onClose();
      })
      .catch((error) => {
        sqNotifications.apiError(error);
      });
  };

  const protectedSave = () => {
    setIsSaving(true);
    return save();
  };

  const selectWorksheet = (worksheetId: string, workstepId: string) => {
    sqReportContentActions.setWorksheetId(worksheetId);
    sqReportContentActions.setWorkstepId(workstepId);
    sqReportContentActions.setWorkbookId(workbookId);
  };

  const evaluateVisualizationOptions = () => {
    setEvaluatingVisualizationOptions(true);
    return sqReportContent.evaluateVisualizationOptions(workbookId, worksheetId, workstepId)
      .catch(sqNotifications.apiError)
      .finally(() => {
        setEvaluatingVisualizationOptions(false);
      });
  };

  const urlStepDefinitions = [
    {
      title: 'REPORT.CONTENT.LINK',
      component: <ReportContentUrl />,
      actionButtonLabel: 'USE',
      actionFn: () => {
        sqReportContentActions.setWorkbookId(sqExploreWorkbookModalStore.workbookId);
        sqTrack.doTrack('Topic', 'Seeq-Link - Worksheet', 'Analysis selected');
      },
      valid: !_.isEmpty(sourceUrl),
      modalClassName: 'report-content-modal'
    }
  ];

  const workbookStepDefinitions = [
    {
      title: 'REPORT.CONTENT.SELECT_WORKBOOK',
      component: <ExplorerModalBody
        show={true}
        next={() => sqTrack.doTrack('Topic', 'Seeq-Link - Worksheet', 'Analysis selected')}
        store={sqExploreWorkbookModalStore}
        actions={sqExploreWorkbookModalActions}
        allowedTypes={allowedTypes} />,
      shouldGoToNext: { canGoToNext: !!workbookId, showActionButton: false },
      modalClassName: 'report-content-modal'
    },
    {
      title: 'REPORT.CONTENT.SELECT_WORKSHEET',
      component: <SelectWorksheet
        workbookId={sqExploreWorkbookModalStore.workbookId}
        worksheetIds={[sqReportContentStore.worksheetId]}
        workstepIds={[sqReportContentStore.workstepId]}
        setSelectedWorksheet={selectWorksheet}
        next={() => evaluateVisualizationOptions()
          .then(() => sqTrack.doTrack('Topic', 'Seeq-Link - Worksheet', 'Worksheet selected'))}
        isLoading={evaluatingVisualizationOptions} />,
      actionButtonLabel: 'SELECT',
      shouldGoToNext: { canGoToNext: evaluatingVisualizationOptions, showActionButton: true },
      actionFn: () => evaluateVisualizationOptions()
        .then(() => sqTrack.doTrack('Topic', 'Seeq-Link - Worksheet', 'Worksheet selected')),
      valid: _.some([
        worksheetId,
        workstepId
      ]),
      backButtonFn: () => {
        sqExploreWorkbookModalActions.setWorkbookId(undefined);
        sqReportContentActions.setWorksheetId(undefined);
        sqReportContentActions.setWorkstepId(undefined);
        setStepChanged(!stepChanged);
      },
      modalClassName: 'report-content-modal'
    }
  ];

  const propertiesStepDefinitions =
    [{
      title: 'REPORT.CONTENT.SET_PROPERTIES',
      component: <ReportContentProperties />,
      actionButtonLabel: 'INSERT',
      actionFn: () => {
        sqTrack.doTrack('Topic', 'Seeq-Link - Worksheet', 'Worksheet inserted');
        return protectedSave();
      },
      valid: !isSaving,
      modalClassName: 'report-content-modal'
    }];

  const stepDefinitions = {
    workbook: [...workbookStepDefinitions, ...propertiesStepDefinitions],
    link: [...urlStepDefinitions, ...propertiesStepDefinitions],
    properties: propertiesStepDefinitions
  };

  return (
    modalName &&
    <WizardModal
      stepDefinitions={stepDefinitions[modalName]}
      onClose={() => {
        sqTrack.doTrack('Topic', `Seeq-Link }`, 'cancel');
        onClose();
      }}
      testId='report-content-modal'
    />
  );
};

export const sqReportContentModal = angularComponent(reportContentModalBindings,
  ReportContentModal);
