import _ from 'lodash';
import jQuery from 'jquery';
import angular from 'angular';
import CodeMirror from 'codemirror/lib/codemirror.js';
import badWords from 'badwords-list';
import { NotificationsService } from '@/services/notifications.service';
import { LoggerService } from '@/services/logger.service';
import { WorkbookStore } from '@/workbook/workbook.store';
import { HttpHelpersService } from '@/services/httpHelpers.service';
import { DurationStore } from '@/trendData/duration.store';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { PermissionsV1 } from 'sdk/model/PermissionsV1';
import {
  API_TYPES,
  APP_STATE,
  GUID_REGEX_PATTERN,
  HOME_SCREEN_TABS,
  ITEM_ICONS,
  ITEM_METADATA,
  LOCALES,
  NUMBER_CONVERSIONS,
  STRING_UOM,
  TRENDABLE_TYPES
} from '@/main/app.constants';
import { API_TYPES_TO_ITEM_TYPES, BAR_CHART_LINE_WIDTHS, ITEM_TYPES } from '@/trendData/trendData.module';
import { CAPSULE_TIME_LANE_BUFFER, LANE_BUFFER } from '@/trendViewer/trendViewer.module';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.module';
import tinycolor from 'tinycolor2';
import { ScreenshotService } from '@/services/screenshot.service';
import { StorageService } from '@/services/storage.service';
import { PluginStore } from '@/hybrid/plugin/plugin.store';
import { DEPRECATED_TOOL_NAMES } from '@/investigate/investigate.module';
import Highcharts from 'other_components/highcharts';
import juration from 'juration';
import { CREATED_BY_SEEQ_WORKBENCH } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';

export const IMG_SRC_DATA_REGEX = /(img.*?src=")(data:[^"]*|blob:[^"]*)(")/gi;
export const FROALA_IMG_LOADING_REGEX = /<img[^>]*?data-fr-image-pasted="true"[^>]*?\/?>/g;

const dependencies = [
  'Sq.AppConstants',
  'Sq.Workbook'
];

export function exportedBase64guid() {
  const cryptoObj = window.crypto || window.msCrypto; // for IE 11
  const array = new Uint8Array(16); // 128 bits of randomness
  cryptoObj.getRandomValues(array);
  return btoa(String.fromCharCode.apply(null, array))
    // Remove url unsafe characters and padding
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/\=+$/, '');
}

angular.module('Sq.Services.Utilities', dependencies)
  .service('sqUtilities', sqUtilities)
  .filter('prettyPermissions', sqUtilities => sqUtilities.prettyPermissions)
  .filter('prettyFormatIdentity', sqUtilities => sqUtilities.prettyFormatIdentity)
  .filter('multiLineFormatIdentity', sqUtilities => sqUtilities.multiLineFormatIdentity);

export type UtilitiesService = ReturnType<typeof sqUtilities>;

function sqUtilities(
  $window: ng.IWindowService,
  $injector: ng.auto.IInjectorService,
  sqWorkbookStore: WorkbookStore,
  sqStorage: StorageService) {

  const LETTERS = _.map(_.range('a'.charCodeAt(0), 'z'.charCodeAt(0) + 1), _.ary(String.fromCharCode, 1)) as string[];
  const service = {
    pointInRectangle,
    isTrendable,
    isCapsuleVisible,
    isTimestampVisible,
    getCapsuleDuration,
    isUserCreatedType,
    getToolType,
    isDatafile,
    isAsset,
    isAssetGroup,
    itemIconClass,
    getTypeTranslation,
    addItemType,
    getYValue,
    base64guid,
    generateRequestId,
    getShortIdentifier,
    getMediumIdentifier,
    isProfane,
    encodeParameters,
    validateGuid,
    extractGuids,
    diffArrays,
    isApplePlatform,
    replaceAll,
    headlessRenderMode,
    headlessRenderCategory,
    generateTabHash,
    getCurrentTabFromHash,
    get isViewOnlyWorkbookMode() {
      return sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.VIEW;
    },
    get isPresentationWorkbookMode() {
      return sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.PRESENT;
    },
    get isEditWorkbookMode() {
      return sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.EDIT;
    },
    workbookLoaded,
    collectionItemAdded,
    collectionItemRemoved,
    propertyChanged,
    collectionPropertyChanged,
    itemPropertiesChanged,
    cloneDeepOmit,
    getDefaultBarWidth,
    waitForAppQuiescence,
    waitForPluginQuiescence,
    getWorkbenchAddress,
    validateNumber,
    preventIframeMouseEventSwallow,
    restoreIframeMouseEventBehavior,
    preventIframeMouseEventSwallowForAngularResizable,
    prepareDragResize,
    parseQueryString,
    getURIArgument,
    isInScope,
    getCertainId,
    getUncertainId,
    generateTemporaryId,
    parseDataProperty,
    getLaneBuffer,
    getNextName,
    reload,
    getPointAsArray,
    removeEncodedImageData,
    removeLoadingPastedImages,
    hasLoadingPastedImages,
    hasCrossReferencedImages,
    handleModalOpenErrors,
    getReturnToParams,
    returnToState,
    formatApiError,
    areDateRangesSimilar,
    areAssetSelectionsSimilar,
    truncate,
    getUniqueOrderedValuesByProperty,
    isStringSeries,
    prettyFormatIdentity,
    multiLineFormatIdentity,
    readableIdentifier,
    decorateItemWithProperties,
    debounceAsync,
    getMSPerPixelWidth,
    prettyPermissions,
    equalsIgnoreCase,
    getDuplicateStringsInArray,
    computeLightestColor,
    getNamePrefix,
    headlessJobFormat,
    switchLanguage,
    checkLanguage,
    randomInt,
    buildEmailLink,
    getHref,
    getNextDefaultName
  };

  return service;

  /**
   * Finds the number in the array that is the closest to the provided target number.
   *
   * http://stackoverflow.com/questions/4811536/find-the-number-in-an-array-that-is-closest-to-a-given-number
   *
   * @param {Number[]} array  - Array of Numbers
   * @param  {Number} target - Number to find the closest array value for.
   * @returns {Number} the value in the array closest to the provided target value.
   */
  function getClosest(array: number[], target: number) {
    const tuples = _.map(array, function(val) {
      return [val, Math.abs(val - target)];
    });

    return _.reduce(tuples, function(memo, val) {
      return (memo[1] < val[1]) ? memo : val;
    }, [-1, 999])[0];
  }

  /**
   * Determines if a point is contained within a rectangle
   *
   * @param {Number} x - The x-coordinate of the point
   * @param {Number} y - The y-coordinate of the point
   * @param {Number} left - The coordinate of the rectangle's left side
   * @param {Number} top - The coordinate of the rectangle's top
   * @param {Number} right - The coordinate of the rectangle's right side
   * @param {Number} bottom - The coordinate of the rectangle's bottom
   * @return {Boolean} Returns true if the point is in the rectangle; false otherwise.
   */
  function pointInRectangle(x, y, left, top, right, bottom) {
    const x1 = Math.min(left, right);
    const x2 = Math.max(left, right);
    const y1 = Math.min(top, bottom);
    const y2 = Math.max(top, bottom);
    if ((x1 <= x) && (x <= x2) && (y1 <= y) && (y <= y2)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Determines if an item is an asset
   *
   * @param {Object} item - the item to check
   * @returns {Boolean} true if the item is an asset
   */
  function isAsset(item) {
    return _.get(item, 'type') === API_TYPES.ASSET;
  }

  /**
   * Determines if an item is an asset group
   *
   * @param {Object} item - the item to check
   * @returns {Boolean} true if the item is an asset group
   */
  function isAssetGroup(item) {
    return (_.find(item?.properties,
      { name: SeeqNames.Properties.TreeType }) as any)?.value === CREATED_BY_SEEQ_WORKBENCH;
  }

  /*
   * Determines if an item is a datafile
   *
   * @param {Object} item - the item to check
   * @returns {Boolean} true if the item is a datafile
   */
  function isDatafile(item) {
    return item?.type === API_TYPES.DATAFILE;
  }

  /**
   * Determines if the supplied item is trendable (e.g. displayable in the Trend) in the user interface
   *
   * @param {object} item - The item to check
   * @return {Boolean} Returns true if the item is trendable; false otherwise;
   */
  function isTrendable(item) {
    return _.includes(TRENDABLE_TYPES, _.get(item, 'type'));
  }

  /**
   * Evaluates whether a specified start is a valid timestamp (in milliseconds) and if the start falls within the
   * current display range
   *
   * @param {number} [capsuleStart] - Start time to evaluate, in milliseconds
   * @param {number} [capsuleEnd] - End time to evaluate, in milliseconds
   * @returns {boolean} true if the start is defined and the start falls within the current display range
   */
  function isCapsuleVisible(capsuleStart?, capsuleEnd?) {
    return service.isTimestampVisible(capsuleStart) && (capsuleEnd ? capsuleStart <= capsuleEnd : true);
  }

  /**
   * Evaluates whether a specified unix timestamp is a valid timestamps (in milliseconds) and is within the current
   * display range
   *
   * @param {number} [unixTimestamp] - time to evaluate, in milliseconds
   * @returns {boolean} true if unixTimestamp is defined and within the current display range
   */
  function isTimestampVisible(unixTimestamp?: number) {
    const sqDurationStore = $injector.get<DurationStore>('sqDurationStore');
    const displayStart = sqDurationStore.displayRange.start.valueOf();
    const displayEnd = sqDurationStore.displayRange.end.valueOf();
    return _.isFinite(unixTimestamp) && (unixTimestamp >= displayStart) && (unixTimestamp <= displayEnd);
  }

  /**
   * Calculates a duration given a start and end time. If either time is invalid, returns undefined.
   *
   * @param {number} [capsuleStart] - Start time to evaluate, in milliseconds
   * @param {number} [capsuleEnd] - End time to evaluate, in milliseconds
   * @returns {number|undefined} calculated duration, in milliseconds
   */
  function getCapsuleDuration(capsuleStart?, capsuleEnd?) {
    return (_.isFinite(capsuleStart) && _.isFinite(capsuleEnd)) ? capsuleEnd - capsuleStart : undefined;
  }

  /**
   * Determines if the supplied item type is one that can be created by a user with one of the tools as opposed to
   * those, like stored signals, that can only be created via the API.

   * @param {String} type - The type of item as reported via the REST API
   * @returns {boolean} true if the item is type that can be created by a user, false otherwise.
   */
  function isUserCreatedType(type) {
    return _.includes([
      API_TYPES.CALCULATED_CONDITION,
      API_TYPES.CALCULATED_SIGNAL,
      API_TYPES.CALCULATED_SCALAR,
      API_TYPES.TABLE,
      API_TYPES.THRESHOLD_METRIC,
      API_TYPES.ANCILLARY,
      API_TYPES.DATAFILE
    ], type);
  }

  /**
   * Determines is a signal is a string series based on the provided unit of measure. Handles both items that are
   * from a store and ones that are fetched and decorated with metadata.
   *
   * @param {object} signal - And object representing a signal item
   * @returns {boolean} true if the signal is a string series, false otherwise.
   */
  function isStringSeries(signal) {
    return signal?.signalMetadata?.valueUnitOfMeasure === STRING_UOM || signal?.valueUnitOfMeasure === STRING_UOM
      || signal?.sourceValueUnitOfMeasure === STRING_UOM;
  }

  /**
   * Returns the tool type of a user-created item. Defaults to FORMULA tool if it is a user-created item that
   * does not have a valid tool type encoded (e.g. items that are created via the REST API or the Tree File datasource
   * will not initially have a UIConfig).
   *
   * @param {Object} item - An item from the REST API
   * @returns {TREND_TOOLS|undefined} One of TREND_TOOLS for user-created items; otherwise undefined
   */
  function getToolType(item) {
    const TREND_TOOLS = $injector.get<any>('TREND_TOOLS');
    if (service.isUserCreatedType(item.type)) {
      const uiConfigProp = _.find(item.properties, ['name', SeeqNames.Properties.UIConfig]) as any;
      if (item.type === API_TYPES.THRESHOLD_METRIC) {
        return TREND_TOOLS.THRESHOLD_METRIC;
      } else if (uiConfigProp) {
        const config = JSON.parse(uiConfigProp.value);

        // If the tool name is deprecated, return it so it can be converted by the configUpgrader service
        if (_.includes(DEPRECATED_TOOL_NAMES, config.type)) {
          return config.type;
        }

        return _.includes(_.values(TREND_TOOLS), config.type) ? config.type : TREND_TOOLS.FORMULA;
      } else {
        return TREND_TOOLS.FORMULA;
      }
    } else if (_.get(_.find(item.properties, ['name', SeeqNames.Properties.DatasourceClass]),
      'value') === SeeqNames.LocalDatasources.Datafile) {
      return TREND_TOOLS.IMPORTDATAFILE;
    }
  }

  /**
   * Determines the icon used for an item.
   *
   * @param {object} item - The item being displayed.
   * @return {String} The fontawesome classes for the icon
   */
  function itemIconClass(item) {
    if (_.get(item, 'iconClass')) {
      return item.iconClass;
    }

    switch (_.get(item, 'type')) {
      case API_TYPES.STORED_SIGNAL:
      case API_TYPES.CALCULATED_SIGNAL:
        return service.isStringSeries(item) ? ITEM_ICONS.STRING_SERIES : ITEM_ICONS.SERIES;
      case API_TYPES.CAPSULE:
        return ITEM_ICONS.CAPSULE;
      case API_TYPES.CALCULATED_CONDITION:
      case API_TYPES.STORED_CONDITION:
        return ITEM_ICONS.CAPSULE_SET;
      case API_TYPES.CALCULATED_SCALAR:
        return ITEM_ICONS.SCALAR;
      case API_TYPES.TABLE:
        return ITEM_ICONS.TABLE;
      case API_TYPES.THRESHOLD_METRIC:
        return ITEM_ICONS.METRIC;
      case API_TYPES.ANCILLARY:
        return ITEM_ICONS.ANCILLARY;
      case API_TYPES.DATAFILE:
        return ITEM_ICONS.DATAFILE;
      default:
        return ITEM_ICONS.ASSET;
    }
  }

  /**
   * Returns the translation key for an Item's type
   *
   * @param {String} type - The item type that is returned by the API
   * @return {String} The translation key
   */
  function getTypeTranslation(type) {
    return 'ITEM_TYPES.' + _.snakeCase(type).toUpperCase();
  }

  /**
   * Adds itemType property from corresponding type property if not present.
   *
   * @param {Object} item - An item from one of the stores or from an API request
   * @return {Object} The item with the itemType property set
   */
  function addItemType(item) {
    return _.has(item, 'itemType') ? item : { ...item, itemType: API_TYPES_TO_ITEM_TYPES[item.type] || item.type };
  }

  /**
   * Determine a Y-value to use for a given X-value using the specified data array.
   * If the data contains a Y-value at the exact X-value, use it; otherwise, linearly interpolate a value.
   * Time Series Data is sorted so we can perform a binary search.
   *
   * @param {Object[]} seriesData - An Array containing the series data
   * @param {Number} targetX - The x-value for which to calculate a y-value
   * @param {Function} [getX] - function that determines how to get the x value from a sample in the seriesData
   * @param {Function} [getY] - function that determines how to get the y value from a sample in the seriesData
   * @return {Object} Object containing the results. Object contains three relevant properties:
   *   {Number} Object.yValue - the Y-value for the specified X-value or null if no values to interpolate are found
   *   {Number[]} Object.closestPoint - the closest point found in seriesData, or null if no valid point was found.
   *      Since this function can return an interpolated value, it can be useful to know which data point in
   *      seriesData is closest. For example, it is used to draw the point indicators on the trend when moving the
   *      mouse over the trend.
   *   {Number} Object.closestPoint[0] - X-value of the closest point in seriesData
   *   {Number} Object.closestPoint[1] - Y-value of the closest point in seriesData
   */
  function getYValue(seriesData: any[], targetX: number,
    getX?: (point: any) => number, getY?: (point: any) => number | null) {

    getX = getX || (point => getPointAsArray(point)[0]);
    getY = getY || (point => getPointAsArray(point)[1]);

    if (seriesData.length === 1) {
      return exactResult(seriesData[0]);
    }

    // Ensure that the given x-value is not smaller or bigger than values we have available:
    if (_.isEmpty(seriesData) || targetX < getX(seriesData[0]) || targetX > getX(seriesData[seriesData.length - 1])) {
      return {
        yValue: null,
        closestPoint: [null, null]
      };
    }

    if (seriesData.length === 2) {
      // Check to see if a y-value for the given x exists
      const point = _.find(seriesData, point => getX(point) === targetX);

      if (point) {
        return exactResult(point);
      } else {
        return interpolatedResult(seriesData[0], seriesData[1], targetX);
      }
    }

    // Common case - lots of data use binary search
    let hi = seriesData.length;
    let lo = -1;
    while (hi - lo > 1) {
      const mid = Math.floor((lo + hi) / 2);
      const midX = getX(seriesData[mid]);
      if (midX === targetX) {
        return exactResult(seriesData[mid]);
      } else if (midX < targetX) {
        lo = mid;
      } else {
        hi = mid;
      }
    }

    return interpolatedResult(seriesData[lo], seriesData[hi], targetX);

    function exactResult(point) {
      return {
        yValue: getY(point),
        closestPoint: [getX(point), getY(point)]
      };
    }

    function interpolatedResult(pointA, pointB, targetX: number) {
      const pointAx = getX(pointA);
      const pointAy = getY(pointA);
      const pointBx = getX(pointB);
      const pointBy = getY(pointB);

      let yValue = null;
      if (_.isFinite(pointAx) && _.isFinite(pointAy) && _.isFinite(pointBx) && _.isFinite(pointBy)) {
        yValue = pointAy + (pointBy - pointAy) * ((targetX - pointAx) / (pointBx - pointAx));
      }

      const closestPoint = (targetX - pointAx) < (pointBx - targetX) ? [pointAx, pointAy] : [pointBx, pointBy];

      return { yValue, closestPoint };
    }
  }

  /**
   * Validates a GUID string.
   *
   * @param {String} guid - The GUID to check.
   * @example af8a8416-6e18-a307-bd9c-f2c947bbb3aa
   * @return {Boolean} true if a valid GUID, false otherwise
   */
  function validateGuid(guid) {
    const re = new RegExp('^' + GUID_REGEX_PATTERN + '$');
    return _.isString(guid) && re.test(guid);
  }

  /**
   * Extracts all GUIDs from a string.
   *
   * @param {String} s - The string from which to extract.
   * @return {Object[]} Array of GUIDs
   */
  function extractGuids(s) {
    const re = new RegExp(GUID_REGEX_PATTERN, 'g');
    return (s + '').match(re) || [];
  }

  /**
   * Generates a GUID string using base64 encoding
   *
   * Note: By design, this function produces a guid in a different format from the GUIDs generated by
   * the backend. This is to avoid confusing a frontend-generated guid with a backend-generated one, and so that
   * regexes designed to find backend-generated guids will not unintentionally match frontend-generated guids.
   *
   * @example dcWyqxUedy-c5owG_HIlsA
   * @returns {string} The generated GUID (22 characters long)
   */
  function base64guid() {
    return exportedBase64guid();
  }

  /**
   * Generates a request ID by combining a GUID with a prefix grabbed from stateParams
   *
   * @returns (String) the requestId
   */
  function generateRequestId() {
    const baseRequestPrefix = 'R^';
    const uniqueId = baseRequestPrefix + service.base64guid();
    const sqWorkbenchStore = $injector.get<WorkbenchStore>('sqWorkbenchStore');
    const prefix = sqWorkbenchStore.stateParams.requestIdPrefix;
    return _.chain([prefix, uniqueId]).compact().join('').value();
  }

  /**
   * Generates base64 encoded hash for the current tab.
   *
   * @param {string} tab - the tab to be hashed
   * @returns (string) hash for the provided tab
   */
  function generateTabHash(tab) {
    return btoa(tab);
  }

  /**
   * Decodes the provided base64 encoded hash. Also validates the hash to ensure one of HOME_SCREEN_TABS is provided
   *
   * @param {string} hash - base64 encoded string
   * @returns (string) decoded hash.
   */
  function getCurrentTabFromHash(hash) {
    const decoded = atob(hash);
    return _.indexOf(_.values(HOME_SCREEN_TABS), decoded) > -1 ? decoded : HOME_SCREEN_TABS.HOME;
  }

  /**
   * Computes a short variable identifier given an index number.
   *
   * @param {Number} num - The sequential number of the variable, used to avoid duplicating identifiers.
   * @returns {String} The variable name
   */
  function getShortIdentifier(num: number): string {
    const index = num % LETTERS.length;
    const repeat = Math.floor(num / LETTERS.length) + 1;
    return _.repeat(LETTERS[index], repeat);
  }

  /**
   * Test whether a word is profane, by checking a list of bad words.
   */
  function isProfane(wordToTest: string): boolean {
    wordToTest = wordToTest.toLowerCase();
    return _.some(badWords.array, badWord => wordToTest.includes(badWord));
  }

  /**
   * Computes a short variable identifier for an item.
   *
   * In contrast to getShortIdentifier, this method will generally produce a slightly longer identifier, but if
   * possible it will be related to the actual name of the item.
   *
   * @param {String} name - A name to use as "inspiration" for the identifier. For example, a name of
   *                        "Temperature" may result in an identifier of "t" or "t2".
   * @param {String[]} namesToAvoid - names that already exist in the context
   * @returns {String} The variable name, or an empty string if one could not be generated
   */
  function getMediumIdentifier(name: string, namesToAvoid: string[]): string {
    // Set a soft limit on identifier length, it might be longer due to adding characters to make it unique
    const maxLength = 4;
    let candidateName = '';

    name = name.trim().toLowerCase();
    if (name.length <= maxLength && /^[a-z]+[a-z\d]*$/.test(name)) {
      // Case 1: the name already works as an identifier, so use it!
      candidateName = name;
    } else {
      // Case 2: try to make an acronym.
      // Find sequences of letters or digits.
      const words = name.match(/([a-z]+)|(\d+)/g) || [];
      for (const word of words) {
        if (candidateName.length >= maxLength) {
          // hit the length limit? stop adding words
          break;
        } else if (/^[a-z]+$/.test(word)) {
          // sequence of letters? take the first letter
          candidateName += word.charAt(0);
        } else if (/^\d+$/.test(word)
          && candidateName.length + word.length <= maxLength) {
          // sequence of digits? keep all the digits, if they fit
          if (candidateName.length > 0) { // but skip any leading group of digits
            candidateName += word;
          }
        } else {
          // else stop here
          break;
        }
      }
    }

    if (!_.isEmpty(candidateName)) {
      // We found a useful name prefix from the item name. We'll use it and add a number if necessary to
      // distinguish from other similarly-named parameters.
      let paramName = candidateName;

      let numericSuffix = 2;
      while (_.includes(namesToAvoid, paramName)) {
        paramName = candidateName + numericSuffix++;
      }

      // Avoid profane abbreviations
      if (!isProfane(paramName)) {
        return paramName;
      }
    }

    // Case 3: We couldn't come up with a simple identifier from the item name.
    // It may have been too long, or all numbers and symbols.
    return '';
  }

  /**
   * Encodes formula parameters for consumption by the API
   *
   * @param {Object} parameters - Where key is variable identifier and value is GUID that it references.
   * @returns {Object[]} An array of `identifier=GUID` parameters
   */
  function encodeParameters(parameters) {
    return _.map(parameters, (v, k) => `${k}=${v}`);
  }

  /**
   * Compare the new and old items to determine which were added, removed, or changed.
   *
   * Usually this is used to figure out the differences between new and old values that are passed to a
   * $scope.$watch() function.
   *
   * Because the new and old items are immutable we can do simple identity checks to
   * figure out which items are different between the two arrays.
   *
   * If an item has been changed it will show up in the changeset of both new and old items. Which ones are
   * actually changed can be detected by finding those items whose id is in both the "added" and "removed"
   * changesets. Those changed items can then be subtracted from the "added" and "removed" changesets to
   * give the actual added and removed items.
   *
   * @param {Object[]} newItems - An array of immutable objects with the new changes.
   * @param {Object[]} oldItems - An array of immutable objects with previous values.
   *                         The objects in each array must all contain an 'id' field that uniquely identifies it.
   * @param {String[]} propsToCheck - An array of properties to aggregate changes by. Defaults to empty array.
   * @param {String} idProp - The property that uniquely identifies an item in both newItems and oldItems.
   *                        Defaults to 'id'.
   *
   * @return {Object} An object with addedItems, removedItems, changedItems, changes
   */
  function diffArrays(newItems: any[], oldItems: any[], propsToCheck: string[] = [], idProp: string = 'id') {
    const removedOrChangedItems = _.difference(oldItems, newItems);
    const addedOrChangedItems = _.difference(newItems, oldItems);
    const changedIds = _.intersection(_.map(addedOrChangedItems, idProp), _.map(removedOrChangedItems, idProp));
    const isChangedItem = _.flow(_.property(idProp), _.partial(_.includes, changedIds));
    const changedItems = _.filter(addedOrChangedItems, isChangedItem);
    const removedItems = _.reject(removedOrChangedItems, isChangedItem);
    let addedItems = _.reject(addedOrChangedItems, isChangedItem);
    const changesByProperty = _.chain(propsToCheck)
      .keyBy(_.identity)
      .mapValues(prop =>
        _.filter(changedItems, function(item) {
          // Somewhere in the process we seem to be cloning the store items and that causes all objects to get new ids -
          // which causes the comparison with just !== to not be sufficient. So, we treat some of those properties
          // special and compare the actual properties of the object instead. We don't want to do this deep comparison
          // for every property as this could add another level of slowness. Ideally we'd find where we clone and fix
          // the problem before it comes to this.
          const objectComparisonProps = ['yAxisConfig', 'zones'];
          if (_.indexOf(objectComparisonProps, prop) > -1) {
            return !_.isEqual(item[prop], _.result(_.find(oldItems, [idProp, item[idProp]]), prop));
          } else {
            return item[prop] !== _.result(_.find(oldItems, [idProp, item[idProp]]), prop);
          }
        })
      )
      .value();

    if (process.env.NODE_ENV !== 'production' &&
      (_.some(newItems, _.negate(Object.isFrozen)) || _.some(oldItems, _.negate(Object.isFrozen)))) {
      throw new TypeError('Arrays must be immutable');
    }

    // A directive may not yet be instantiated when $watch is first fired which will result in
    // missing the initial setting of items to []. In that case all items are new.
    if (newItems === oldItems && !addedItems.length) {
      addedItems = newItems;
    }

    return {
      addedItems,
      removedItems,
      changedItems,
      changes: changesByProperty
    };
  }

  /**
   * Determines if the platform is from Apple
   *
   * @returns {boolean} True if the browser is running on a Mac, iPod, iPhone, or iPad; false otherwise.
   */
  function isApplePlatform() {
    return /Mac|iPod|iPhone|iPad/.test(navigator.platform);
  }

  /**
   * Replaces all occurrences of a given string within a string with the specified replacement.
   *
   * @param {String} input - The string that the occurrences of 'find' should be replaced in
   * @param {String} find - The string to find and replace.
   * @param {String} replace - The value that should replace param.find
   * @returns a string with all the occurrences of param.find replaced with param.replace
   */
  function replaceAll(input, find, replace) {
    return input.replace(new RegExp(_.escapeRegExp(find), 'g'), replace);
  }

  /**
   * Returns true if any items were added.
   *
   * @param {Object} e - Event object from Baobab
   * @param {String} collectionName - Name of the collection, e.g. 'items'
   * @return {Boolean} True if items were added, false otherwise
   */
  function collectionItemAdded(e, collectionName) {
    return e && e.data && e.data.currentData[collectionName] &&
      (e.data.currentData[collectionName].length > e.data.previousData[collectionName].length ||
        _.some(e.data.paths, function(path: any) {
          return path.length === 1 && path[0] === collectionName;
        }));
  }

  /**
   * Returns true if any items were removed.
   *
   * @param {Object} e - Event object from Baobab
   * @param {String} collectionName - Name of the collection, e.g. 'items'
   * @return {Boolean} True if items were removed, false otherwise
   */
  function collectionItemRemoved(e, collectionName) {
    return e && e.data && e.data.currentData[collectionName] &&
      (e.data.previousData[collectionName].length > e.data.currentData[collectionName].length ||
        _.some(e.data.paths, function(path: any) {
          return path.length === 1 && path[0] === collectionName;
        }));
  }

  /**
   * Returns true if any of the specified properties or their children changed by comparing previous and current
   * data and paths. It checks paths to allow for sub-properties to be checked and checks data to catch times when a
   * merge() was the cause of the data changing.
   *
   * @param {Object} e - Event object from Baobab
   * @param {String[][]} e.data.paths - Array of strings representing the elements which changed. For
   *   example, ['view'] would indicate that the view property changed.
   * @param {String[]|String} propertyNames - Names of the properties to test
   * @return {Boolean} True if property changed, otherwise false
   */
  function propertyChanged(e, propertyNames) {
    propertyNames = _.castArray(propertyNames);
    return e && e.data &&
      ((_.chain(e).get('data.paths', []) as any).flatten().intersection(propertyNames).value().length > 0 ||
        _.some(propertyNames, function(property: string) {
          return e.data.previousData[property] !== e.data.currentData[property];
        }));
  }

  /**
   * Returns true if any of the specified properties of a collection changed as determined by the Baobab event object.
   * If an item was completely added or removed from the collection it treats that action as having changed the
   * property and returns true. True is also returned if there was a merge transaction on one of the items since the
   * exact keys that changed are not known in that case.
   *
   * @param {Object} e - Event object from Baobab
   * @param {String[][]} e.data.paths - Array of strings representing the elements which changed. For
   *   example, ['items', '0'] would indicate that an entire item changed, ['items', '0', 'selected'] would
   *   indicate a specific property of an item changed.
   * @param {String} collectionName - Changes must be only those that occurred in this collection.
   * @param {String[]|String} propertyNames - Names of the properties to test
   * @return {Boolean} True if property changed, otherwise false
   */
  function collectionPropertyChanged(e, collectionName, propertyNames) {
    propertyNames = _.isArray(propertyNames) ? propertyNames : [propertyNames];
    return service.collectionItemAdded(e, collectionName) ||
      service.collectionItemRemoved(e, collectionName) ||
      _.some(_.get(e, 'data.transaction', []), function(transaction) {
        return transaction.path[0] === collectionName && (transaction.type === 'merge' ||
          _.intersection(transaction.path, propertyNames).length > 0);
      });
  }

  /**
   * Returns true if the properties of an items array have changed values. It assumes the items are in an 'items'
   * array and that all the items have 'id' properties. Unlike `collectionPropertyChanged(e, 'items', propertyNames)`
   * this function will ignore the `transaction` and `paths` properties provided by baobab and use only the
   * `previousData` and `currentData` properties. This insures that we will only return true if data is truly
   * different, whereas `collectionPropertyChanged` will return true if the item has been replaced by an identical item.
   *
   * @param {Object} e - Event object from Baobab
   * @param {String[]|String} propertyNames - Names of the properties to test
   * @returns true if the relevant properties of the item have not changed
   */
  function itemPropertiesChanged(e, propertyNames) {
    const props = _.concat(['id'], propertyNames);
    return !_.isEqual(
      _.map(_.get(e, 'data.previousData.items'), item => _.pick(item, props)),
      _.map(_.get(e, 'data.currentData.items'), item => _.pick(item, props))
    );
  }

  /**
   * Determines if the app is rendered by in the headless browser for screen capture
   *
   * @return {Boolean} whether or not the application is rendered in the headless browser
   */
  function headlessRenderMode(): boolean {
    return _.isFunction(window.seeqHeadlessCapture);
  }

  /**
   * Indicates what category of job this headless browser page is mapped to.
   *
   * @returns the category of the job for which this page is rendering. If this page is not rendering in a headless
   *   browser, then this function returns undefined
   */
  function headlessRenderCategory() {
    return $injector.get<ScreenshotService>('sqScreenshot').headlessCaptureMetadata().category;
  }

  /**
   * Indicates the format output (PDF, PNG) of the screenshot this headless browser is rendering.
   */
  function headlessJobFormat() {
    return window.seeqJobFormat();
  }

  /**
   * Determine if the current workbook is loaded
   *
   * @return {Boolean} - true if the workbook is in presentation mode
   */
  function workbookLoaded(): boolean {
    return sqWorkbookStore.isWorkbookLoaded;
  }

  /**
   * Call _.cloneDeep with a customizer that will not copy any properties whose key equals property.
   *
   * NOTE: The property will be omitted on all levels of the Object
   *
   * @param  {Object} obj - The array, object, etc that will be passed to _.cloneDeepWith
   * @param  {String[]} properties - The properties that should not be copied
   * @return {Object} - return copied object that may share the properties whose key equals property
   */
  function cloneDeepOmit(obj, properties) {
    return _.cloneDeepWith(obj, function(value, key) {
      // When the customizer does not return the default _.cloneDeep behavior is used
      if (_.includes(properties, key)) {
        return value;
      }
    });
  }

  /**
   * Returns the default bar chart bar width. By looking at the number of samples as well as the available chart
   * width a sensible default value is chosen.
   *
   * @param data {Number[]} - bar chart data
   * @param chartWidth {Number} - width of the chart in pixels
   * @returns {Number} calculated bar width in pixels
   */
  function getDefaultBarWidth(data, chartWidth) {
    if (_.isNil(chartWidth) || _.isEmpty(data)) {
      return 1;
    }

    const calculatedBarWidth = chartWidth / (data.length * 4);
    return getClosest(BAR_CHART_LINE_WIDTHS, calculatedBarWidth);
  }

  /**
   * Waits for Seeq to settle, i.e. AngularJS has no outstanding $http requests or browser $timeouts, and
   * all Topic Document content has finished loading (or error)
   *
   * @return {Promise} that resolves when the app has stabilized
   */
  function waitForAppQuiescence() {
    const $q = $injector.get<ng.IQService>('$q');
    const $$testability = $injector.get<any>('$$testability');
    const $interval = $injector.get<ng.IIntervalService>('$interval');
    const $http = $injector.get<ng.IHttpService>('$http');

    // !!! This is marked as an Angular private function, but it is used in
    // Protractor's waitForAngular for this same purpose.
    return $q(resolve => waitUntilSeeqSettled(resolve))
      .then(() => $q(resolve => (<any>$$testability).whenStable(resolve)));

    function waitUntilSeeqSettled(resolve) {
      const sqReportContent = $injector.get<ReportContentService>('sqReportContent');
      if ($http.pendingRequests.length === 0 && sqReportContent.isAllContentFinishedLoading()) {
        $interval(resolve, 1, 1);
        return;
      }
      $interval(() => waitUntilSeeqSettled(resolve), 200, 1);
    }
  }

  /**
   * Waits for any visible display pan plugins to complete their rendering
   *
   * @return {Promise} that resolves when all visible display pane plugins have completed their rendering
   */
  function waitForPluginQuiescence() {
    const $q = $injector.get<ng.IQService>('$q');
    const $interval = $injector.get<ng.IIntervalService>('$interval');
    const sqPluginStore = $injector.get<PluginStore>('sqPluginStore');

    return $q(resolve => waitUntilPluginRenderComplete(resolve));

    function waitUntilPluginRenderComplete(resolve) {
      const keepWaiting = () => $interval(_.partial(waitUntilPluginRenderComplete, resolve), 200, 1);

      if (sqPluginStore.displayPaneRenderComplete) {
        $interval(resolve, 1, 1);
      } else {
        keepWaiting();
      }
    }
  }

  /**
   * @returns {String} the protocol, address and port of the current Workbench URL
   */
  function getWorkbenchAddress() {
    const $location = $injector.get<ng.ILocationService>('$location');
    return $location.protocol() + '://' + $location.host() + ':' + $location.port();
  }

  /**
   * Helper to validate a Number input.
   * This is needed as _.isFinite converts undefined to 0, which is a valid number,
   * but if a text field is empty, it doesn't really contain 0 ...
   *
   * @param {String} input - the candiate to validate
   * @returns {Boolean} true if the input was actually a Number, false otherwise.
   */
  function validateNumber(input) {
    return _.trim(input) !== '' && _.isFinite(_.toNumber(input));
  }

  /**
   * Helper to fix mouse event swallowing when dragging over an iframe
   * Works in pair with restoreIframeMouseEventBehavior
   */
  function preventIframeMouseEventSwallow() {
    jQuery(document).find('iframe').css('pointer-events', 'none');
  }

  /**
   * Helper to restore mouse event behavior when finishing a drag operation.
   * Works in pair with preventIframeMouseEventSwallow
   */
  function restoreIframeMouseEventBehavior() {
    jQuery(document).find('iframe').css('pointer-events', 'auto');
  }

  /**
   * Helper to fix mouse event swallowing when dragging over an iframe when using the Angular Resizable directive
   * @param scope The Angular Scope
   */
  function preventIframeMouseEventSwallowForAngularResizable(scope: ng.IScope) {
    scope.$on('angular-resizable.resizeStart', function(event, args) {
      preventIframeMouseEventSwallow();
    });

    scope.$on('angular-resizable.resizeEnd', function(event, args) {
      restoreIframeMouseEventBehavior();
    });
  }

  /**
   * Helper to prepare a resize via mouse dragging. It also prevents mouse event swallowing when dragging goes over an
   * iframe.
   * @param e The drag event
   * @param resizeAction The callback for mousemove
   * @param onDragStart Additional config for drag start
   * @param onDragEnd Additional config for drag end
   */
  function prepareDragResize(e: Event, resizeAction: (eventObject: JQueryEventObject, ...args: any[]) => any,
    onDragStart: Function = null, onDragEnd: Function = null) {

    /**
     * Removes the event handlers that are part of the drag operation.
     */
    function endResizePanel() {
      jQuery(document).off('mousemove', resizeAction);
      jQuery(document).off('mouseup', endResizePanel);
      restoreIframeMouseEventBehavior();

      if (onDragEnd) {
        onDragEnd();
      }
    }

    // To prevent text from being selected
    e.stopPropagation();
    e.preventDefault();

    jQuery(document).on('mousemove', resizeAction);
    jQuery(document).on('mouseup', endResizePanel);
    this.preventIframeMouseEventSwallow();

    if (onDragStart) {
      onDragStart();
    }
  }

  /**
   * Parses a URL or query string and returns an object containing the result.
   * For example, 'https://test.com:443?a=foo&b=bar' results in { a: 'foo', b: 'bar' }
   *
   * @param {String} queryString - the URL string
   * @returns {Object} an object containing the query parameters
   */
  function parseQueryString(queryString) {
    if (_.isEmpty(queryString)) {
      return {};
    }
    // Clean off the protocol, host, and port if they're there
    let searchString = queryString;
    if (queryString.indexOf('?') > 0) {
      searchString = queryString.substring(queryString.indexOf('?'));
    }

    const params = searchString.replace('&amp;', '&').split('&');
    return _.transform(params, function(result, param: string) {
      const tokens = param.split('=');
      if (tokens.length === 2) {
        result[_.trimStart(tokens[0], '?')] = tokens[1];
      }
    }, {});
  }

  /**
   * A simple function for retrieving values from url arguments
   *
   * @param {string} url - the URL string
   * @param {string} urlArgument - an argument from url
   * @param {string} defaultValue - if passed default value use it instead of empty string
   * @return {string} return it, else return defaultValue
   */
  function getURIArgument(url: string, urlArgument: string, defaultValue: string = ''): string {
    const urlArguments = this.parseQueryString(url);

    return _.has(urlArguments, urlArgument) ? decodeURIComponent(urlArguments[urlArgument] as string) : defaultValue;
  }

  /**
   * Determines if the item is in the current scope, meaning it is either in the global scope (scopedTo is empty)
   * or it is scoped to the current workbook.
   *
   * @param {Object} item - The item to filter
   * @returns {Boolean} True if it is in the current or global scope, false otherwise
   */
  function isInScope(item) {
    const $state = $injector.get<ng.ui.IStateService>('$state');
    return _.isEmpty(item.scopedTo) || item.scopedTo === $state.params.workbookId;
  }

  /**
   * Helper function that returns the corresponding "certain" id for a given uncertain id.
   * If a certain id is provided the return of this function will not change it.
   *
   * @param {String} id - the uncertain id
   * @returns {String} the corresponding certain id.
   */
  function getCertainId(id) {
    return _.replace(id, '_uncertain', '');
  }

  /**
   * Helper function that returns the corresponding "uncertain" id for a given certain id.
   * If an uncertain id is provided the return of this function will not change it.
   *
   * @param {String} id - the certain id
   * @returns {String} the corresponding uncertain id.
   */
  function getUncertainId(id) {
    if (_.includes(id, '_uncertain_')) {
      return id;
    }

    return _.replace(id, '_', '_uncertain_');
  }

  /**
   * Returns a new ID that can be identified as a "temporary" ID that didn't come from the backend
   *
   * @returns {String} Generated ID that can be identified as "temporary"
   */
  function generateTemporaryId() {
    // @author Slavik Meltser (slavik@meltser.info).
    // @link http://slavik.meltser.info/?p=142
    function _p8(s?) {
      const p = (Math.random().toString(16) + '000000000').substr(2, 8);
      return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p;
    }

    return _p8() + _p8(true) + _p8(true) + _p8() + '_temp';
  }

  /**
   * Parses the data property string into an object and returns it. If data property is not JSON-formatted, an empty
   * object is returned.
   *
   * @param {String} payload - the data property string
   * @returns {Object} an object containing the data properties
   */
  function parseDataProperty(payload) {
    if (_.get(payload, 'data')) {
      const jsonMessage = _.attempt(JSON.parse, payload.data);

      if (_.isError(jsonMessage)) {
        const sqLogger = $injector.get<LoggerService>('sqLogger');
        sqLogger.error(`Failed to parse .data property as JSON. Ignoring value: ${payload.data}`);
        return;
      }

      return jsonMessage;
    }
  }

  /**
   * Tiny helper function to determine the correct lane buffer to use
   *
   * @param {Boolean} isCapsuleTime - Flag indicating if capsule time is displayed
   */
  function getLaneBuffer(isCapsuleTime) {
    return isCapsuleTime ? CAPSULE_TIME_LANE_BUFFER : LANE_BUFFER;
  }

  /**
   * Calculate a name to use given a set of existing names and a prefix. Finds the largest number at the end of the
   * existing names, adds 1, and prepends the prefix.
   *
   * @param {Array} existingNames - Existing names from which to extract the numeric suffixes
   * @param {String} [prefix] - Prefix to use for the resulting name
   * @param {Boolean} [prefixMatch=false] - When true, numbers in existing names are only extracted if the name
   *   matches the specified prefix
   * @returns {String} name to use
   */
  function getNextName(existingNames, prefix?, prefixMatch?) {
    return (_.chain(existingNames)
      .map(function(name) {
        if (prefix && prefixMatch) {
          return name.replace(new RegExp('^' + prefix + '.*?(\\d+)', 'i'), '$1');
        } else {
          const matches = name.match(/\d+$/);
          return matches ? matches[0] : 0;
        }
      })
      .map(_.toNumber)
      .filter(_.isFinite) as any)
      .max()
      .add(1)
      .toString()
      .thru(function(number) {
        return prefix ? prefix + ' ' + number : number;
      })
      .value();
  }

  /**
   * A wrapper around $window.location.reload() for easier testing
   *
   * @param {Boolean} [forceGet=false] - if true reloads the page from the server instead of the browser cache
   */
  function reload(forceGet = false) {
    $window.location.reload(forceGet);
  }

  /**
   * Returns a "point" as an Array. Series-data is an array of either Arrays of [xValue, yValue] or an Object with x
   * y, and marker properties
   * This function returns both of those points as an Array.
   *
   * ****************************************************************************************************************
   * Note: this means that the marker portion of the Object will get lost! So only call this function when you don't
   * need the marker portion!!!
   ******************************************************************************************************************
   *
   * @param {Object|[]} point - if it's an object it's expected to have x and y properties, the Array is expected to
   * be [x, y]
   * @returns {[any , any]} - the x and y values of the point as an Array
   */
  function getPointAsArray(point) {
    return _.isArray(point) ? point : [_.get(point, 'x'), _.get(point, 'y')];
  }

  /**
   * Replaces the image src tag attribute of images with data sources with an empty string
   *
   * @param {String} html - an html string
   * @returns {String} an html string where all image tag src properties that start with "data:" or "blob:" have
   *   been replaced with an empty string.
   * end times.
   */
  function removeEncodedImageData(html) {
    return html?.replace?.(IMG_SRC_DATA_REGEX, '$1$3');
  }

  /**
   * Remove loading pasted images from the document. Because of froala weirdness, we don't want to save images to
   * the document until they've fully loaded (CRAB-22106). Otherwise we can end up with a
   * cross-referenced image (from a different journal) in a backup. We do, however, want to save the rest of the
   * document, so we strip out those images that are still loading and leave the content in place.
   *
   * @param html - html string
   * @returns html string where all non-content images with the data-fr-image-pasted="true" attribute have been removed
   */
  function removeLoadingPastedImages(html: string): string {
    return html?.replace(FROALA_IMG_LOADING_REGEX,
      (match: string) => {
        if (match.includes(SeeqNames.TopicDocumentAttributes.DataSeeqContent)
          || match.includes(SeeqNames.TopicDocumentAttributes.DataSeeqContentPending)) {
          return match;
        } else {
          return '';
        }
      });
  }

  function hasCrossReferencedImages(document: string) {
    return document?.includes('crossReferencedImage');
  }

  /**
   * Returns true if the document has pasted non-content images that are still loading, false otherwise
   *
   * @param document - html string
   */
  function hasLoadingPastedImages(document: string): boolean {
    const pastedImages = document?.match(FROALA_IMG_LOADING_REGEX);
    return _.some(pastedImages, imgTag => !imgTag.includes(SeeqNames.TopicDocumentAttributes.DataSeeqContent)
      && !imgTag.includes(SeeqNames.TopicDocumentAttributes.DataSeeqContentPending));
  }

  /**
   * Ignore errors due to backdrop clicks or escape key presses as these are valid ways to close the modal
   *
   * @param e The event object
   */
  function handleModalOpenErrors(e) {
    if (e !== 'backdrop click' && e !== 'escape key press') {
      $injector.get<NotificationsService>('sqNotifications').apiError(e);
    }
  }

  /**
   * Creates parameters for the login page that allow the user to be sent back to where they were after logging back
   * in.
   *
   * @param {Object} returnState - if present then the provided state will be stored in the return parameters
   * @param {Object} returnParams - if present then the provided params will be stored in the return parameters
   * @return {Object} Parameters suitable for redirecting to the login page
   */
  function getReturnToParams(returnState, returnParams) {
    const params = {} as any;
    const invalidReturnStates = [APP_STATE.LOAD_ERROR, APP_STATE.LOGIN];

    if (!_.isEmpty(_.get(returnState, 'name')) && !_.includes(invalidReturnStates, returnState.name)) {
      params.returnState = returnState.name;
      if (!_.isEmpty(returnParams)) {
        params.returnParams = JSON.stringify(returnParams);
      }
    }

    return params;
  }

  /**
   * Returns to a serialized state stored in the current state as returnState and returnParams. This is used by the
   * login and load error pages to return to the previous page after the login completes or the load error is
   * resolved. Note: `$state.go` will be passed `location: 'replace'` to cause the current state to disappear from
   * the browser "back button" history
   *
   * @param [params] - if provided, use these params instead of $state.params - this can be useful during a transition
   */
  function returnToState(params?) {
    const $state = $injector.get<ng.ui.IStateService>('$state');
    let { returnState, returnParams } = params || $state.params;
    if (!returnState) {
      const storedParams = _.attempt(JSON.parse, sqStorage.store.getItem('stateParams'));
      returnState = storedParams?.returnState;
      returnParams = storedParams?.returnParams;
    }
    sqStorage.store.removeItem('stateParams');

    // Check for potential redirect loop (CRAB-11668)
    if ($state.current.name === returnState) {
      const sqLogger = $injector.get<LoggerService>('sqLogger');
      sqLogger.error('Attempting to return to the same state we are already in, going to the workbooks view instead');
      returnState = undefined;
      returnParams = undefined;
    }

    return $state.go(
      returnState || APP_STATE.WORKBOOKS,
      _.isString(returnParams) ? JSON.parse(returnParams) : returnParams,
      { location: 'replace' }
    );
  }

  /**
   * Format a http response object or error for display to the user. Formatted errors will not include call stacks.
   * Will return an empty string to indicate that nothing should be shown to the user.
   *
   * @param {Object} httpResponse - Response object from the HTTP call.
   * @returns {String} The formatted response
   */
  function formatApiError(httpResponse) {
    const sqHttpHelpers = $injector.get<HttpHelpersService>('sqHttpHelpers');
    if (!_.isNil(_.get(httpResponse, 'data', httpResponse)) && !sqHttpHelpers.isCanceled(httpResponse)) {
      const sqLogger = $injector.get<LoggerService>('sqLogger');
      return sqLogger.format`${_.isError(httpResponse) ? httpResponse.toString() : httpResponse}`;
    }

    return '';
  }

  /**
   * Formats name as 'text...text'
   *
   * @param {String} name - the name to format
   * @param {Number} maxLength - the maximum length string to display
   * @param {Number} characterCount - the number of characters to display on each side of the ellipses
   * @returns {String} formatted name (first 9 characters, ellipses, last 9 characters);
   */
  function truncate(name, maxLength, characterCount) {
    if (_.size(name) > maxLength) {
      return _.truncate(name, { length: characterCount + 3 }) + name.substring(name.length - characterCount);
    }
    return name;
  }

  /**
   * Helper function that returns an array of unique values for the provided property ordered in the same order of
   * the possible values provided. This is useful to get all the assigned lanes as well as axis assignments.
   *
   * @param {Object[]} items - list of items to check for properties
   * @param {String} property - the property to retrieve the unique assignments for.
   * @param {Object[]} possibleValues - the possible values the property could be
   * @returns {[Object]} Array of the unique values for the provided property.
   */
  function getUniqueOrderedValuesByProperty(items, property, possibleValues) {
    return _.chain(items)
      .map(property)
      .compact()
      .uniq()
      .orderBy(value => _.indexOf(possibleValues, value))
      .value();
  }

  /**
   * Formats an identity for display. For Users, formatted result includes .name and .username; for UserGroups,
   * result only includes .name
   *
   * @param {Object} identity - Identity to be formatted, either User or UserGroup.
   * @returns {string} formatted identity, either `name (username)` for Users, or `name` for UserGroups or empty
   * string if no identity is provided.
   */
  function prettyFormatIdentity(identity, options = {}): string {
    if (!identity) {
      return '';
    }
    const resolvedOptions = _.defaults(options, { multiLine: false });
    const args = resolvedOptions.multiLine ? ['<div>', '</div>'] : [' (', ')'];
    args.unshift(identity);
    return identity.type === 'User'
      ? `${identity.name}${identitySpecifier.apply(null, args)}`
      : identity.name;
  }

  /**
   * Formats an identity for display on two lines. For Users, formatted result includes .name and .username; for
   * UserGroups, result only includes .name
   *
   * @param {Object} identity - Identity to be formatted, either User or UserGroup.
   * @returns {string} formatted identity, either `name (username)` for Users, or `name` for UserGroups
   */
  function multiLineFormatIdentity(identity): string {
    if (!identity) {
      throw new TypeError('identity must be an object representing a User or UserGroup');
    }
    return prettyFormatIdentity(identity, { multiLine: true });
  }

  /**
   * Returns an additional piece of information for the identity that will help a user
   * differentiate between two accounts with the same full name.
   *
   * @param {Object} user - the user for which to return a specifier
   * @param {string} specifierBefore - optionally prepend a string to the specifier
   * @param {string} specifierAfter - optionally append a string to the specifier
   * @returns the specifier
   */
  function identitySpecifier(user, specifierBefore = '', specifierAfter = ''): string {
    const specifier = readableIdentifier(user) || user.datasource && user.datasource.name;
    if (specifier) {
      return `${specifierBefore}${specifier}${specifierAfter}`;
    }
    return '';
  }

  /**
   * Returns a human readable identifier for a user.
   *
   * @param {Object} user - the user
   * @returns a readable identifier for the user
   */
  function readableIdentifier(user): string {
    if (!user) {
      throw new TypeError('user must be an object representing a User');
    }
    return user.isUsernameReadable ? user.username : user.email;
  }

  /**
   * This function decorates the provided item with signalMetadata and conditionMetadata based on the item properties.
   *
   * @param {object} item - object defining an item as returned by the API
   * @returns {object} an item that has been decorated with signalMetadata and conditionMetadata based on the
   * provided properties.
   */
  function decorateItemWithProperties(item) {
    const decoratedItem = addItemType(item);

    const addComplexProperty = (metadata, property, propName) => {
      metadata[`${propName}`] = {
        uom: property.unitOfMeasure, value: _.toNumber(property.value)
      };
      return metadata;
    };

    const addSimpleProperty = (metadata, property, propName) => {
      metadata[`${propName}`] = property.value;
      return metadata;
    };

    if (decoratedItem.itemType !== ITEM_TYPES.CAPSULE_SET) {
      const signalMetadata = {};
      const signalMetadataProps = [
        { accessor: ITEM_METADATA.interpolationMethod, key: 'interpolationMethod' },
        { accessor: ITEM_METADATA.keyUnitOfMeasure, key: 'keyUnitOfMeasure' },
        { accessor: ITEM_METADATA.valueUnitOfMeasure, key: 'valueUnitOfMeasure' }];

      const complexSignalMetadataProps = [
        { accessor: 'Maximum Interpolation', key: 'maxInterpolation' }
      ];

      _.forEach(item.properties, (property) => {
        const importantSignalProp = _.find(signalMetadataProps, { accessor: property.name });
        if (importantSignalProp) {
          addSimpleProperty(signalMetadata, property, importantSignalProp.key);
        }
        const importantComplexSignalProp = _.find(complexSignalMetadataProps, { accessor: property.name });
        if (importantComplexSignalProp) {
          addComplexProperty(signalMetadata, property, importantComplexSignalProp.key);
        }
      });

      if (_.isEmpty(signalMetadata)) {
        return _.omit(decoratedItem, 'properties');
      }

      return _.chain(decoratedItem)
        .assign({ signalMetadata })
        .omit('properties')
        .value();
    } else {
      const conditionMetadata = {};
      const conditionMetadataProps = [
        { accessor: SeeqNames.Properties.MaximumDuration, key: 'maximumDuration' },
        { accessor: ITEM_METADATA.maxInterpolation, key: 'maxInterpolation' }];

      _.forEach(item.properties, (property) => {
        const importantConditionMetadataProp = _.find(conditionMetadataProps, { accessor: property.name });
        if (importantConditionMetadataProp) {
          addComplexProperty(conditionMetadata, property, importantConditionMetadataProp.key);
        }
      });

      if (_.isEmpty(conditionMetadata)) {
        return _.omit(decoratedItem, 'properties');
      }

      return _.chain(decoratedItem)
        .assign({ conditionMetadata })
        .omit('properties')
        .value();
    }
  }

  /**
   * Ensures that an async function which is invoked multiple times while the initial invocation is outstanding is
   * only run a total of two times: the initial invocation and once more when it finishes. If invoked multiple times
   * with different arguments the callback will be invoked with the most recent arguments after the first promise
   * resolves.
   *
   * @param {Function} callback - A callback that returns a promise
   * @return {Function} The callback wrapped in code that guards against multiple invocations while the promise is
   * resolving.
   */
  function debounceAsync(callback: (...args) => ng.IPromise<any>): (...args) => void {
    let isRunning = false;
    let queuedRequest = _.noop;
    const debouncedCallback = (...args) => {
      if (isRunning) {
        queuedRequest = () => debouncedCallback(...args);
      } else {
        isRunning = true;
        $injector.get<ng.IQService>('$q').resolve()
          .then(() => callback(...args))
          .finally(() => {
            isRunning = false;
            queuedRequest();
            queuedRequest = _.noop;
          })
          .catch((exception) => {
            const sqLogger = $injector.get<LoggerService>('sqLogger');
            sqLogger.error(sqLogger.format`Unhandled debounceAsync error ${exception}`);
          });
      }
    };

    return debouncedCallback;
  }

  /**
   * Returns the calculated lane width or the minimum when the calculated one is not valid in milliseconds.
   *
   * @param {Number} duration - The duration of the display range in milliseconds
   * @param {Number} numPixels - The number of pixels available to the chart
   * @returns {Number} laneWidth
   */
  function getMSPerPixelWidth(duration, numPixels) {
    const minLaneWidth = 1 / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND;
    const proposedLaneWidth = duration / numPixels;
    return proposedLaneWidth > minLaneWidth ? proposedLaneWidth : minLaneWidth;
  }

  /**
   * Converts a permissions object to a human readable string
   *
   * @param permissions - permissions object
   * @param [defaultMessage] - default message if no permissions are set
   */
  function prettyPermissions(permissions: PermissionsV1, defaultMessage?: string) {
    return _.chain(permissions)
      .map((value, key) => value ? $injector.get<ng.translate.ITranslateService>('$translate')
        .instant(`ACCESS_CONTROL.ACCESS_LEVELS.${_.toUpper(key)}`) : undefined)
      .compact()
      .join(', ')
      .value() || defaultMessage || '';
  }

  function equalsIgnoreCase(string1: string, string2: string) {
    return _.toLower(string1) === _.toLower(string2);
  }

  /**
   * Finds the duplicate values in a string array (case sensitive).
   *
   * @param stringArray - array in which to look for duplicates
   * @returns array of duplicates or an empty array if no duplicate values are found
   */
  function getDuplicateStringsInArray(stringArray: string[]): string[] {
    const counts = stringArray
      .reduce((a, b) => {
        a[b] = (a[b] || 0) + 1;
        return a;
      }, {});
    return Object.keys(counts).filter(a => counts[a] > 1);
  }

  /**
   * Compute a color so that it is as close to white (#fff) as possible.
   *
   * @param {string} color - The color for which we calculate the factor
   * @param {number} darkenFactor - A fractional number that darkens the color. Default of 0 means it does not
   * darken at all.
   * @param {number} prevFactor - previous value of the factor (used internally for recursive search)
   * @param {number} factor - factor value to check if we reached white color (used internally for recursive search)
   * @param {number} maxFactor - the maximum factor value (used internally for recursive search)
   * @return {string} the color, as a hex string, that is closest to white, but adjusted using the darkenFactor
   */
  function computeLightestColor(color, darkenFactor: number = 0, prevFactor: number = 0, factor: number = 50,
    maxFactor: number = 100) {
    if (factor >= maxFactor) {
      return tinycolor(color).lighten(prevFactor - prevFactor * darkenFactor).toString('rgb');
    }
    if (tinycolor(color).lighten(factor).toString() === '#ffffff') {
      return computeLightestColor(color, darkenFactor, factor, Math.round(factor / 2), factor);
    }
    if (tinycolor(color).lighten(factor).toString() !== '#ffffff') {
      return computeLightestColor(color, darkenFactor, factor, factor + Math.round((maxFactor - factor) / 2),
        maxFactor);
    }
  }

  /**
   * Checks the last element in an item name for an index value and removes the index value to get the name prefix
   *
   * @param {string} fullName - the name of the item to check the index value
   * @returns {string} the prefix of the name of the item
   */
  function getNamePrefix(fullName) {
    const match = /^(.*) \d+$/.exec(_.trim(fullName));
    return match ? match[1] : fullName;
  }

  /**
   * Switches to the provided language. This function also sets the moment locale and loads the appropriate
   * Highcharts translations file, and makes sure that the Tools are properly translated.
   *
   * @param {string} language - the language
   */
  function switchLanguage(language: string) {
    juration.setLanguage(checkLanguage(language));

    return $injector.get<ng.translate.ITranslateService>('$translate').use(language)
      .then(() => {
        // NOTE: we do not want to set a new locale for moment as users are used to having it use their browser
        // locale (CRAB-24568)
        $injector.get<ng.IFluxService>('flux').dispatch('INVESTIGATE_TRIGGER_INVESTIGATE_TOOL_TRANSLATION');
        $injector.get<ng.IHttpService>('$http').get(`resources/HC/${language}.json`)
          .then((result) => {
            Highcharts.setOptions(_.pick(result.data, 'lang'));
          });
      });

  }

  function checkLanguage(language: string) {
    switch (language) {
      case LOCALES.DE:
        return LOCALES.DE;
      default:
        return LOCALES.EN;
    }
  }

  /**
   *  Uses numbers that correspond to Number.MIN|MAX_SAFE_INTEGER (which IE11 does not support)
   */
  function randomInt(): number {
    return _.random(-9007199254740991, 9007199254740991);
  }

  /**
   * Helper to build an email link
   *
   * @param mailTo - the email address to mail to
   * @param contact - the contact name
   * @param subjectKey - optional email subject key
   * @param bodyKey - optional email body key
   */
  function buildEmailLink(mailTo, contact, subjectKey?, bodyKey?) {
    const email = '<a href="mailto:';
    const $translate = $injector.get<ng.translate.ITranslateService>('$translate');
    return subjectKey && bodyKey
      ? email.concat(mailTo, '?subject=', $translate.instant(subjectKey), '&body=', $translate.instant(bodyKey), '\">',
        contact, '</a>')
      : email.concat(mailTo, '\">', contact, '</a>');
  }

  function areDateRangesSimilar(dateRange1, dateRange2): boolean {
    return !dateRange2.isArchived
      && dateRange1.range.end - dateRange1.range.start === dateRange2.range.end - dateRange2.range.start
      && _.isEqual(dateRange1.condition, dateRange2.condition)
      && dateRange1.auto.enabled === dateRange2.auto.enabled;
  }

  function areAssetSelectionsSimilar(assetSelection1, assetSelection2): boolean {
    return !assetSelection2.isArchived && assetSelection1.asset.id === assetSelection2.asset.id;
  }

  function getHref(item) {
    switch (item.type) {
      case SeeqNames.Types.CalculatedSignal:
        return `/signals/${item.id}`;
      case SeeqNames.Types.CalculatedCondition:
        return `/conditions/${item.id}`;
      case SeeqNames.Types.CalculatedScalar:
        return `/scalars/${item.id}`;
      case SeeqNames.Types.ThresholdMetric:
        return `/metrics/${item.id}`;
      case SeeqNames.Types.Chart:
        return `/formulas/functions/${item.id}`;
      default:
        return `/items/${item.id}`;
    }
  }

  /**
   * Returns the "next" default name.
   * Default names are expected to follow the <defaultNameTranslationKey> <nextNumber> pattern.
   */
  function getNextDefaultName(existingNames: string[], defaultNameTranslationKey: string) {
    const $translate = $injector.get<ng.translate.ITranslateService>('$translate');
    const baseName = $translate.instant(defaultNameTranslationKey);
    const extractIncrementingNumber = (nameAndNumber: string) => _.toNumber(
        nameAndNumber.substring(_.lastIndexOf(nameAndNumber, ' ') + 1)) || 0;

    const nextSuggestedNumber = _.chain(existingNames)
      .filter(name => _.startsWith(name, baseName))
      .map(extractIncrementingNumber)
      .sortBy()
      .last()
      .value();

    const nextNumber = _.isFinite(nextSuggestedNumber) ? nextSuggestedNumber + 1 : 1;

    return `${baseName} ${nextNumber}`;
  }
}
