import { DEFAULT_PERMISSIONS, PERMISSIONS } from '@/main/app.constants';
import _ from 'lodash';
import { AceOutputV1, PermissionsV1 } from '@/sdk';

export const DEFAULT_ACE: Ace = {
  id: '',
  identity: {} as any,
  permissions: { ...DEFAULT_PERMISSIONS },
  isNew: true
};

export const BASIC_TAB_INDEX = '0';
export const ADVANCED_TAB_INDEX = '1';

export interface DetailTableAce {
  id: string;
  name: string;
  permissions: string;
  inheritedFrom: string;
}

export interface Ace {
  id: string;

  permissions?: PermissionsV1;
  identity?: Identity;
  isNew?: boolean;
  inheritedPermissions?: PermissionsV1;
  isOwner?: boolean;
  role?: string;
}

export interface BasicAce {
  identityId: string;
  permissions: PermissionsV1;
}

export interface Acl {
  permissionsInheritanceDisabled: boolean;
  entries: Ace[];
}

export interface OriginalAcl {
  permissionsInheritanceDisabled: boolean;
  entries: BasicAce[];
  localEntries: BasicAce[];
}

export interface Identity {
  id: string;
  name: string;
  isRedacted?: boolean;
  isEnabled?: boolean;
  type?: string;
  datasource?: { name: string };
  email?: string;
}

/**
 * If a user assigns "WRITE" permissions, then "READ" permissions are automatically checked (as that is what the
 * user presumably intends to happen as most write restrictions require you to read the item first)
 * If a user assigns "MANAGE" permissions then "READ" and "WRITE" permissions are automatically checked as well.
 *
 * If a user removes "WRITE" permissions, then "MANAGE" permissions are automatically removed too. If the user removes
 * "READ" permissions, then "WRITE" and "MANAGE" permissions are removed. This provides a behavior that is the inverse
 * of the behavior when adding permissions. More importantly, it prevents the UI user from accidentally removing
 * "READ" permissions from an item they can still manage (which would result in the user not being able to view
 * the ACL modal for an item for which they have manage permissions).
 *
 * @param permission - name of the permission to be set
 * @param permissions - object defining permissions; for example: {read: true, write: false, manage: false }
 */
export function determinePermissions(permission: string, permissions: PermissionsV1): PermissionsV1 {
  const newPermissions = { ...permissions };

  if (permission === PERMISSIONS.WRITE && !permissions.write) {
    newPermissions.read = true;
    newPermissions.write = true;
  } else if (permission === PERMISSIONS.MANAGE && !permissions.manage) {
    newPermissions.read = true;
    newPermissions.write = true;
    newPermissions.manage = true;
  } else if (permission === PERMISSIONS.WRITE && permissions.write) {
    newPermissions.write = false;
    newPermissions.manage = false;
  } else if (permission === PERMISSIONS.READ && permissions.read) {
    newPermissions.read = false;
    newPermissions.write = false;
    newPermissions.manage = false;
  } else {
    newPermissions[permission] = !permissions[permission];
  }

  return newPermissions;
}

/**
 * Helper to provide the "(you)", "(owner)", and "(you, owner)" text next to the name.
 *
 * @param ace - object representing an ace entry
 * @param t - translation function
 * @param currentUserId - the current user ID
 *
 * NOTE: the details tab data is not decorated with "isOwner" but has a role property so this function knows to
 * use both owner designations.
 */
export function getAdditionalText(ace: Ace, t: (string) => string, currentUserId: string): string {
  const isAceForOwner = ace?.role === 'OWNER' || ace.isOwner;
  const aceForCurrentUser = ace?.identity?.id === currentUserId;

  if (isAceForOwner && aceForCurrentUser) {
    return `(${t('ACCESS_CONTROL.OWNER')}, ${t('ACCESS_CONTROL.YOU')})`;
  } else if (isAceForOwner) {
    return `(${t('ACCESS_CONTROL.OWNER')})`;
  } else if (aceForCurrentUser) {
    return `(${t('ACCESS_CONTROL.YOU')})`;
  }
  return '';
}

/**
 * Returns a prettied identity type
 */
export function prettyIdentityType(input: string, t: (string) => string): string {
  if (input === 'UserGroup' || input === 'User') {
    return t('ACCESS_CONTROL.TYPES.' + _.toUpper(input));
  } else {
    return input;
  }
}

/**
 * De-dupes the ACL entries and ensures that we only show one line with consolidated permissions for an identity.
 * Moves inherited and owner permissions to an inheritedPermissions property.
 *
 * @param itemAceList - The aces to dedup
 * @return a deduped list of aces
 */
export function deDupeAceList(itemAceList: AceOutputV1[]): AceOutputV1[] {
  const deduped = [];

  _.reduce(itemAceList, (dedupedAces, value: any) => {
    value = _.clone(value);
    const isOwner = value?.role === 'OWNER';
    const inherited = _.has(value, 'origin');
    const entryUpdates = {};

    if (inherited || isOwner) {
      _.assign(entryUpdates, { inheritedPermissions: _.clone(value.permissions) });
      if (isOwner) {
        _.assign(entryUpdates, { isOwner });
      }
      value = _.omit(value, 'permissions');
    } else {
      _.assign(entryUpdates, { permissions: _.clone(value.permissions) });
    }

    const existingEntry = _.find(dedupedAces, ['identity.id', _.get(value, 'identity.id')]);
    if (existingEntry) {
      _.assign(existingEntry, entryUpdates);
    } else {
      _.assign(value, entryUpdates);
      dedupedAces.push(value);
    }

    return dedupedAces;
  }, deduped);

  return _.sortBy(deduped, 'identity.name');
}

/**
 * Determines if the ACL has been modified from the original version.
 *
 * @returns true if modified; false otherwise.
 */
export function aclIsModified(newItemAcl: Acl, originalItemAcl: OriginalAcl): boolean {
  const itemAclPid = _.get(newItemAcl, 'permissionsInheritanceDisabled');
  const originalItemAclPid = _.get(originalItemAcl, 'permissionsInheritanceDisabled');
  const itemAclEntries = _.map(_.get(newItemAcl, 'entries'),
    entry => ({ identityId: entry.identity.id, permissions: entry.permissions }));
  return itemAclPid !== originalItemAclPid || !_.isEqual(originalItemAcl?.entries, itemAclEntries);
}
