import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { Paginator } from '@/hybrid/core/Paginator.molecule';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { MIN_HOME_SCREEN_ITEM_COUNT } from '@/hybrid/homescreen/homescreen.module';

const paginationBindings = bindingsDefinition({
  loadTable: prop<(offset: number, limit: number, searchParams?) => void>(),
  store: prop<PaginationAndFilteringSupportingStore>(),
  actions: prop<PaginationAndFilteringSupportingActions>(),
  tableType: prop.optional<string>(),
  insideModal: prop.optional<boolean>()
});

export type PaginationAndFilteringSupportingStore = {
  getItemTotalForTable: (table?: string) => number,
  getPageSizeByTable: (table?: string) => number,
  getPageNumberForTable: (table?: string) => number,
  searchParams: object
};

export type PaginationAndFilteringSupportingActions = {
  setPageSizeForTable: ({ size: number, table: string }) => void,
  setPageNumberAndGo: (page: number, loadTable?: Function, table?: string) => void
};

export const Pagination: SeeqComponent<typeof paginationBindings> = (props) => {
  const { store, actions, tableType, loadTable, insideModal } = props;
  const totalResults = useFluxPath(store, () => store.getItemTotalForTable(tableType));
  const tablePageSize = useFluxPath(store, () => store.getPageSizeByTable(tableType));
  const pageNumber = useFluxPath(store, () => store.getPageNumberForTable(tableType));
  const pageCount = _.max([Math.ceil(totalResults / tablePageSize), 1]);

  const setPageSizeInStoreAndTable = (value) => {
    actions.setPageSizeForTable({ size: value, table: tableType });
    actions.setPageNumberAndGo(1, loadTable, tableType);
  };

  return (totalResults > MIN_HOME_SCREEN_ITEM_COUNT
    ? (
      <Paginator
        gotoPage={number => actions.setPageNumberAndGo(number, loadTable, tableType)}
        pageCount={pageCount}
        pageNumber={pageNumber}
        pageSize={tablePageSize}
        insideModal={insideModal}
        setPageSize={setPageSizeInStoreAndTable}
      />)
    : null);
};

export default Pagination;
