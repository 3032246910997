import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { TrackService } from '@/track/track.service';
import { InvestigateStore } from '@/investigate/investigate.store';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { DISPLAY_MODE, DURATION_TIME_UNITS_ALL } from '@/main/app.constants';
import { FORM_ERROR, FORM_WARNING, FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { PeriodicConditionStore } from '@/hybrid/tools/periodicCondition/periodicCondition.store';
import { PeriodicConditionActions } from '@/hybrid/tools/periodicCondition/periodicCondition.actions';
import {
  DAYS,
  FREQUENCY_CHOICES,
  MONTHS,
  STANDARD_DURATIONS
} from '@/hybrid/tools/periodicCondition/periodicCondition.module';
import { TimezonesService } from '@/datetime/timezone.service';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { FormulaService } from '@/services/formula.service';

const periodicConditionBindings = bindingsDefinition({
  isReport: prop.optional<boolean>(),
  onSave: prop.optional<(item: { id: string, name: string }) => void>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqPeriodicConditionActions: injected<PeriodicConditionActions>(),
  sqPeriodicConditionStore: injected<PeriodicConditionStore>(),
  sqInvestigateStore: injected<InvestigateStore>(),
  sqTrack: injected<TrackService>(),
  sqTimezones: injected<TimezonesService>(),
  sqWorkbenchActions: injected<WorkbenchActions>(),
  sqWorkbenchStore: injected<WorkbenchStore>(),
  sqToolRunner: injected<ToolRunnerService>(),
  sqTrendDataHelper: injected<TrendDataHelperService>(),
  sqFormula: injected<FormulaService>()
});

export const PeriodicCondition: SeeqComponent<typeof periodicConditionBindings> = (props) => {
  const { isReport = false, onSave = _.noop } = props;
  const {
    sqInvestigateActions,
    sqPeriodicConditionActions,
    sqPeriodicConditionStore,
    sqTimezones,
    sqInvestigateStore,
    sqTrack,
    sqWorkbenchActions,
    sqWorkbenchStore,
    sqToolRunner,
    sqTrendDataHelper,
    sqFormula
  } = useInjectedBindings(periodicConditionBindings);
  const { t } = useTranslation();

  const [color, setColor] = useState('');
  const [apiErrorMessage, setApiErrorMessage] = useState('');

  const displayMode = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.displayMode);
  const userTimeZone = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.userTimeZone);

  const {
    id,
    name,
    shift,
    duration,
    frequencies,
    offset,
    quarterStart,
    timezone,
    weekStart
  } = useFlux(sqPeriodicConditionStore);

  const timezones = sqTimezones.timezones;
  const shiftOffset = shift.offset;
  const shiftDuration = shift.duration;
  const selectedTimezone = _.find(timezones, ['name', timezone]);
  const monthOfFirstQuarter = _.find(MONTHS, ['key', quarterStart.month]);
  const quarterDayChoices = _.range(1, monthOfFirstQuarter?.days + 1);
  const dayOfFirstQuarter = _.get(quarterDayChoices, quarterStart.day - 1);

  useEffect(() => {
    if (_.isUndefined(selectedTimezone)) {
      setTimeZone(userTimeZone);
    }
  }, [userTimeZone]);

  const setTimeZone = (timeZone) => {
    sqPeriodicConditionActions.setTimezone({ name: timeZone?.name });
    if (isReport) {
      sqTrack.doTrack('Topic', 'Date Range Tool', 'timezone assigned');
    }
  };

  const updateDuration = (duration) => {
    const durationKeys = _.map(STANDARD_DURATIONS, duration => t(duration.display));
    const currentNamePrefix = name ? _.trim(name.replace(/\d*$/gm, '')) : undefined;
    if (_.includes(_.concat(durationKeys, t('PERIODIC_CONDITION.HEADER')), currentNamePrefix) || _.isUndefined(name)) {
      const prefix = _.find(STANDARD_DURATIONS, ['key', duration.value])?.display ?? 'PERIODIC_CONDITION.HEADER';
      sqFormula.getDefaultName(t(prefix),sqTrendDataHelper.getTrendItemScopedTo(id))
        .then(name => sqInvestigateActions.setSearchName(TREND_TOOLS.PERIODIC_CONDITION, name));
    }
    sqPeriodicConditionActions.setDuration(duration.value);
  };

  const execute = () => {
    const { formula, parameters } = sqPeriodicConditionStore.getFormula();
    return sqToolRunner.panelExecuteCondition(
      name,
      formula,
      parameters,
      sqPeriodicConditionStore.configParams,
      sqPeriodicConditionStore.id,
      color
      )
      .then((id) => {
        onSave({ id, name });
        sqTrack.doTrack('Workbench_Tool', 'Periodic Condition', 'completed');
      })
      .catch((errorMessage) => {
        sqTrack.doTrack('Workbench_Tool', 'Periodic Condition', 'error');
        setApiErrorMessage(_.replace(errorMessage, /\(.*\)/gm, ''));
      });
  };

  const formDataSetup: FormElement[] = [{
    component: 'SearchTitleFormComponent',
    name: 'periodicConditionTitle',
    value: name,
    includeIf: !isReport,
    onChange: name => sqInvestigateActions.setSearchName(TREND_TOOLS.PERIODIC_CONDITION, name),
    id,
    onColorChange: setColor,
    searchIconClass: 'fc-periodic-condition',
    defaultName: _.find(STANDARD_DURATIONS, ['key', duration])?.display ?? 'PERIODIC_CONDITION.HEADER',
    testId: 'periodicConditionTitle'
  }, {
    component: 'FormGroup',
    name: 'durationFormGroup',
    displayNumber: true,
    components: [{
      component: 'LabelFormComponent',
      name: 'durationLabel',
      value: 'DURATION'
    }, {
      component: 'IconSelectFormComponent',
      name: 'duration',
      value: duration,
      onChange: updateDuration,
      className: 'specDuration',
      insideModal: isReport,
      selectOptions: _.map(STANDARD_DURATIONS, duration => ({ text: duration.display, value: duration.key })),
      skipMemo: true
    }]
  }, {
    component: 'CheckboxTableFormComponent',
    name: 'frequenciesTable',
    includeIf: duration === 'days' || duration === 'months' || duration === 'quarters',
    displayNumber: true,
    extraClassNames: 'frequenciesTable',
    options: _.find(FREQUENCY_CHOICES, ['key', duration])?.choices ?? [],
    onChange: item => sqPeriodicConditionActions.toggleFrequency(item === 'all' ? undefined : item),
    value: frequencies,
    includeAll: true
  }, {
    component: 'FormGroup',
    name: 'shiftDurationFormGroup',
    includeIf: duration === 'shifts',
    displayNumber: true,
    components: [{
      component: 'LabelFormComponent',
      name: 'shiftDurationLabel',
      value: 'PERIODIC_CONDITION.SHIFT_DURATION'
    }, {
      component: 'IconSelectFormComponent',
      name: 'shiftDuration',
      value: shiftDuration,
      validation: shiftDuration => _.isUndefined(shiftDuration),
      onChange: shiftDuration => sqPeriodicConditionActions.setShift(shiftOffset, shiftDuration.value),
      className: 'specShiftDuration',
      insideModal: isReport,
      selectOptions: _.map(_.range(1, 24), hour => ({ text: hour.toString(), value: hour }))
    }]
  }, {
    component: 'FormGroup',
    name: 'shiftOffsetFormGroup',
    includeIf: duration === 'shifts',
    displayNumber: true,
    components: [{
      component: 'LabelFormComponent',
      name: 'shiftOffsetLabel',
      value: 'PERIODIC_CONDITION.SHIFT_OFFSET'
    }, {
      component: 'FormControlFormComponent',
      name: 'shiftOffset',
      value: shiftOffset,
      onChange: shiftOffset => sqPeriodicConditionActions.setShift(shiftOffset, shiftDuration),
      className: 'shiftOffset',
      type: 'number',
      size: 'lg',
      validation: value => value < 0 || value > 24,
      extendValidation: true,
      testId: 'shiftOffset',
      customError: 'PERIODIC_CONDITION.SHIFT_OFFSET_VALIDATE'
    }]
  }, {
    component: 'FormGroup',
    name: 'weekStartFormGroup',
    includeIf: duration === 'weeks',
    displayNumber: true,
    components: [{
      component: 'LabelFormComponent',
      name: 'weekStartLabel',
      value: 'PERIODIC_CONDITION.WEEK_START'
    }, {
      component: 'IconSelectFormComponent',
      name: 'weekStart',
      value: weekStart,
      onChange: weekStart => sqPeriodicConditionActions.setWeekStart(weekStart.value),
      className: 'specWeekStart',
      insideModal: isReport,
      selectOptions: _.map(DAYS, day => ({ text: day.display, value: day.key }))
    }]
  }, {
    component: 'FormGroup',
    name: 'fiscalYearStartDateGroup',
    includeIf: duration === 'quarters',
    displayNumber: true,
    components: [{
      component: 'LabelFormComponent',
      name: 'fiscalLabel',
      value: 'PERIODIC_CONDITION.QUARTER_START'
    }, {
      component: 'FormRow',
      name: 'fiscalYearStartDateRow',
      components: [{
        component: 'IconSelectFormComponent',
        name: 'monthOfFirstQuarter',
        value: monthOfFirstQuarter,
        onChange: month => sqPeriodicConditionActions.setQuarterStart(month.value.key,
          Math.min(dayOfFirstQuarter, month.value.days)),
        className: 'specMonthOfFirstQuarter width-50-percent',
        insideModal: isReport,
        selectOptions: _.map(MONTHS, month => ({ text: month.display, value: month })),
        skipMemo: true
      }, {
        component: 'IconSelectFormComponent',
        name: 'dayOfFirstQuarter',
        value: dayOfFirstQuarter,
        validation: dayOfFirstQuarter => _.isUndefined(dayOfFirstQuarter),
        onChange: day => sqPeriodicConditionActions.setQuarterStart(monthOfFirstQuarter.key, day.value),
        className: 'specDayOfFirstQuarter width-50-percent',
        insideModal: isReport,
        selectOptions: _.map(quarterDayChoices, day => ({ text: day.toString(), value: day })),
        skipMemo: true
      }]
    }]
  }, {
    component: 'FormGroup',
    name: 'timeZoneFormGroup',
    displayNumber: true,
    components: [{
      component: 'LabelFormComponent',
      name: 'timeZoneLabel',
      value: 'PERIODIC_CONDITION.SELECT_TIME_ZONE'
    }, {
      component: 'TimeZoneSelectorFormComponent',
      name: 'timeZoneSelector',
      value: selectedTimezone,
      defaultTimeZone: sqTimezones.defaultTimezone,
      optional: false,
      enableAutoDetect: false,
      onChange: setTimeZone
    }, {
      component: 'ErrorMessageFormComponent',
      name: 'timeZoneError',
      type: FORM_WARNING,
      failForm: true,
      extraClassNames: 'text-underline cursorPointer',
      linkAction: () => sqWorkbenchActions.setUserProfileDisplay(true),
      includeIf: _.isUndefined(selectedTimezone) || selectedTimezone === {},
      value: 'PERIODIC_CONDITION.TIME_ZONE_WARNING_LINK'
    }]
  }, {
    component: 'AdvancedFormGroup',
    name: 'advancedParametersFormGroup',
    toolName: isReport ? 'Topic' : TREND_TOOLS.PERIODIC_CONDITION,
    toolId: TREND_TOOLS.PERIODIC_CONDITION,
    toolStore: sqPeriodicConditionStore,
    components: [{
      component: 'FormGroup',
      name: 'nameFormGroup',
      includeIf: isReport,
      displayNumber: true,
      components: [{
        component: 'LabelFormComponent',
        name: 'reportNameLabel',
        value: 'NAME'
      }, {
        component: 'FormControlFormComponent',
        name: 'reportName',
        value: name,
        size: 'lg',
        testId: 'reportName',
        onChange: name => sqInvestigateActions.setSearchName(TREND_TOOLS.PERIODIC_CONDITION, name)
      }]
    }, {
      component: 'ValueWithUnitsFormComponent',
      name: 'offsetValueWithUnits',
      displayNumber: true,
      value: offset,
      insideModal: isReport,
      min: 0,
      validation: value => _.trim(value?.value?.toString()) === '',
      onChange: sqPeriodicConditionActions.setOffset,
      availableUnits: DURATION_TIME_UNITS_ALL,
      label: 'PERIODIC_CONDITION.CAPSULE_OFFSET',
      testId: 'offset'
    }]
  }, {
    component: 'ErrorMessageFormComponent',
    name: 'apiError',
    includeIf: apiErrorMessage !== '',
    value: apiErrorMessage,
    type: FORM_ERROR,
    title: 'PERIODIC_CONDITION.FAILURE',
    dismissible: true,
    failForm: false,
    onClose: () => setApiErrorMessage('')
  }];

  const periodicConditionBuilder = <ToolPanelFormBuilder
    formDefinition={formDataSetup}
    submitFn={execute}
    closeFn={sqInvestigateActions.close}
    toolName={isReport ? 'Topic' : TREND_TOOLS.PERIODIC_CONDITION}
    toolId={TREND_TOOLS.PERIODIC_CONDITION}
    submitBtnId="executePeriodicCondition"
    hideCancel={isReport}
    submitBtnLabel={isReport ? 'CONTINUE' : 'EXECUTE'} />;

  return (displayMode === DISPLAY_MODE.NEW || displayMode === DISPLAY_MODE.EDIT) ? periodicConditionBuilder : null;
};

export const sqPeriodicCondition = angularComponent(periodicConditionBindings, PeriodicCondition);
