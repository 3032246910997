import React, { useState, useEffect, useRef, useCallback } from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { TrackService } from '@/track/track.service';
import { DateTimeService } from '@/datetime/dateTime.service';
import timezoneModalTemplate from '@/trendViewer/timezoneModal.html';
import { ModalService } from '@/services/modal.service';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { EditableText } from '@/hybrid/core/EditableText.atom';
import { ControlledTooltip } from '@/hybrid/core/ControlledTooltip.atom';

const dateTimeEntryBindings = bindingsDefinition({
  testId: prop<string>(),
  date: prop<moment>(),
  otherDate: prop.optional<moment>(),
  updateDate: prop<(date: moment | number) => void>(),
  updateBothDates: prop.optional<(start: any, end: any, option?: string) => void>(),
  timezone: prop<{ name: string, displayName: string, offset: string, offsetMinutes: number }>(),
  readOnly: prop.optional<boolean>(),
  timeZoneReadOnly: prop.optional<boolean>(),
  hideTimezone: prop.optional<boolean>(),
  dateFormat: prop.optional<string>(),
  fieldIdentifier: prop<string>(),
  trackCategory: prop<string>(),
  trackInformation: prop<string>(),
  trackAction: prop<string>(),
  extraClassName: prop.optional<string>(),
  textExtraClassNames: prop.optional<string>(),
  inputExtraClassNames: prop.optional<string>(),
  sqTrack: injected<TrackService>(),
  sqDateTime: injected<DateTimeService>(),
  sqModal: injected<ModalService>()
});

/**
 * Entry and display of date/times, including time zones
 */
export const DateTimeEntry: SeeqComponent<typeof dateTimeEntryBindings> = (props) => {
  const {
    testId,
    date,
    otherDate,
    updateDate,
    updateBothDates,
    timezone,
    readOnly = false,
    timeZoneReadOnly,
    hideTimezone = false,
    dateFormat,
    fieldIdentifier,
    trackAction,
    trackCategory,
    trackInformation,
    extraClassName = '',
    textExtraClassNames,
    inputExtraClassNames
  } = props;

  const { sqTrack, sqDateTime, sqModal } = useInjectedBindings(dateTimeEntryBindings);
  const { t } = useTranslation();

  const [editText, setEditText] = useState('');
  const [tzAbbreviation, setTzAbbreviation] = useState('');
  const [isError, setIsError] = useState(false);
  const [parseError, setParseError] = useState(undefined);
  const target = useRef(null);

  const updateDisplayValue = useCallback(() => {
    if (date.isValid() && timezone) {
      setEditText(formatDate());
      setTzAbbreviation(formatTimezone());
    }
  }, [date, timezone]);

  useEffect(() => {
    updateDisplayValue();
  }, [timezone, date.valueOf(), updateDisplayValue]);

  const onDateChange = (newDate) => {
    sqTrack.doTrack(trackCategory, trackAction, trackInformation);

    if (newDate.length > 50) {
      showError(newDate.substring(0, 40) + '...');
      return;
    }

    const successfulMethod = sqDateTime.attemptDateParse(
      { date, otherDate, fieldIdentifier, newDate, timezone, updateBothDates, updateDate });
    successfulMethod ? clearError() : showError(_.trim(newDate));
  };

  const onTzClick = () => {
    sqModal.safeOpen({
      animation: true,
      controller: 'TimezoneModalCtrl',
      controllerAs: 'ctrl',
      template: timezoneModalTemplate,
      size: 'sm',
      resolve: {
        options: () => ({ userFallback: true })
      }
    });
  };

  const clearError = () => {
    setIsError(false);
    setParseError(undefined);
  };

  const showError = (input) => {
    updateDisplayValue();
    setIsError(true);
    setParseError(input);
    setTimeout(clearError, 3000);
  };

  const formatDate = () => date.tz(timezone.name).format(dateFormat ? dateFormat : 'l LT');
  const formatTimezone = () => date.tz(timezone.name).format('z');

  const errorTooltip = (
    <span>
      {t('PARSE_DATE_ERROR')}
      <span className="text-bolder">{parseError}</span>
    </span>
  );

  return (
    <div className={classNames('nowrap', extraClassName)}>
      {readOnly &&
      <span className="specDateTime readOnly">
        {editText}
      </span>}

      {!readOnly &&
      <>
        <ControlledTooltip
          extraClassNames="errorTooltip"
          target={target.current}
          show={isError}
          id={`error-tooltip-${fieldIdentifier}`}
          formattedText={errorTooltip} />
        <span ref={target}>
          <EditableText
            id={testId}
            testId={testId}
            textClasses={classNames('overflowHidden', 'inlineFlex', textExtraClassNames)}
            inputClasses={classNames('overflowHidden', 'inlineFlex', inputExtraClassNames)}
            value={editText}
            onUpdate={onDateChange} />
        </span>
      </>}

      {(!hideTimezone && (readOnly || timeZoneReadOnly)) &&
      <span className="specTimezone readOnly pl5">
        {tzAbbreviation}
      </span>}

      {(!hideTimezone && !readOnly && !timeZoneReadOnly) &&
      <a href="#" className="pl5" onClick={onTzClick}>
        {tzAbbreviation}
      </a>}
    </div>
  );
};

export const sqDateTimeEntry = angularComponent(dateTimeEntryBindings, DateTimeEntry);
