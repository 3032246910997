/* istanbul ignore file */

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { BasePluginDependencies } from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { PluginDependencies } from '@/hybrid/annotation/ckEditorPlugins/plugins/PluginDependencies';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import Command from '@ckeditor/ckeditor5-core/src/command';
import { PdfPreviewModalService } from '@/reportEditor/pdfPreviewModal.service';
import icon from '@/hybrid/reportEditor/ckIcons/ckeditor5-seeq-pdf-export.svg';

const PDF_EXPORT_COMMAND = 'seeqPdfExport';

/**
 * This adds a button which open the pdf export modal, allowing the user to export the current document as a pdf.
 */
export class PdfExport extends Plugin {
  static pluginName = 'PdfExport';
  static setup = {
    name: PdfExport.pluginName,
    plugin: PdfExport,
    toolbar: PdfExport.pluginName
  };

  init() {
    this.editor.commands.add(PDF_EXPORT_COMMAND, new PdfExportCommand(this.editor));

    this.defineToolbarButton();
  }

  defineToolbarButton() {
    const editor = this.editor;
    const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);

    editor.ui.componentFactory.add(PdfExport.pluginName, (locale) => {
      const command = editor.commands.get(PDF_EXPORT_COMMAND);
      const buttonView = new ButtonView(locale);

      buttonView.set({
        icon,
        tooltip: deps.$translate.instant('REPORT.EDITOR.SAVE_AS_PDF_BUTTON_TITLE'),
        tooltipPosition: 'w'
      });

      buttonView.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled');

      this.listenTo(buttonView, 'execute', () => editor.execute(PDF_EXPORT_COMMAND));

      return buttonView;
    });
  }
}

class PdfExportCommand extends Command {
  execute() {
    const deps: BasePluginDependencies = this.editor.config.get(PluginDependencies.pluginName);
    deps.$injector.get<PdfPreviewModalService>('sqPdfPreviewModal').showModal();
  }
}
