import React from 'react';
import _ from 'lodash';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { Field } from 'react-final-form';
import { getValidationFunction } from '@/hybrid/formbuilder/formbuilder.utilities';
import { FormulaEditorParam } from '@/hybrid/formula/FormulaParametersTable.molecule';
import { FormulaEditor, FormulaErrorInterface } from '@/hybrid/formula/FormulaEditor.molecule';
import { FormulaErrorMessage } from '@/hybrid/formula/FormulaErrorMessage';

export interface FormulaEditorIF extends ValidatingFormComponent<string> {
  component: 'FormulaEditorFormComponent';
  formula: string;
  constants: any[];
  operators: any[];
  showLineNumbers: boolean;
  exposeFormulaToParent: (formula: string) => void;
  exposeEditorToParent: (editor: any) => void;
  parameters: FormulaEditorParam[];
  onSave: (formula: string) => any;
  clearGeneralFormulaError: () => any;
  formulaErrors: FormulaErrorInterface[];
  generalFormulaError: string;
  setFormulaErrors?: (errors: any) => void;
  readOnly?: boolean;
}

/**
 * A wrapper around the `FormulaEditor` component for use with `FormBuilder`
 */
export const FormulaEditorFormComponent: React.FunctionComponent<FormulaEditorIF> = ({
  name,
  testId = 'formulaEditor',
  formula,
  constants,
  operators,
  showLineNumbers,
  exposeFormulaToParent,
  exposeEditorToParent,
  parameters,
  onSave,
  formulaErrors,
  generalFormulaError,
  clearGeneralFormulaError,
  setFormulaErrors,
  readOnly,
  extendValidation,
  validation
}) => {

  const defaultValidation = () => {
    return !_.isEmpty(formulaErrors);
  };

  const appliedValidation = getValidationFunction(
    defaultValidation,
    extendValidation,
    validation
  );

  return (
    <div className="flexRowContainer flexFill mb8" data-testid={testId}>
      <Field name={name} validate={appliedValidation}>
        {({ input }) => (
          <FormulaEditor
            formula={formula}
            constants={constants}
            operators={operators}
            showLineNumbers={showLineNumbers}
            exposeFormulaToParent={(formulaValue) => {
              if (formulaValue !== formula) {
                exposeFormulaToParent(formulaValue);
                input.onChange(formulaValue);
              }
            }}
            exposeEditorToParent={exposeEditorToParent}
            parameters={parameters}
            onSave={onSave}
            formulaErrors={formulaErrors}
            setFormulaErrors={setFormulaErrors}
            readOnly={readOnly}
          />
        )}
      </Field>
      {generalFormulaError &&
      <FormulaErrorMessage message={generalFormulaError} clearError={clearGeneralFormulaError} />}
    </div>
  );
};
