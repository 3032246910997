/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 54.1.1-v202110152006
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { isNil, omitBy } from 'lodash';
import * as models from '../model/models';

export class AssetsApi {
  static $inject = ['$http', '$httpParamSerializer', 'APPSERVER_API_PREFIX'];

  constructor(
    protected $http: ng.IHttpService, 
    protected $httpParamSerializer: ng.IHttpParamSerializer,
    protected APPSERVER_API_PREFIX: string
  ) {}

  /**
   * 
   * @summary Archive an asset
   * @param {string} id - ID of the asset to archived
   */
  public archiveAsset(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'DELETE',
      url: this.APPSERVER_API_PREFIX + '/assets/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ArchiveOutputV1>;
  }

  /**
   * 
   * @summary Create or update multiple assets at one time
   * @param {models.AssetBatchInputV1} [body]
   */
  public batchCreateAssets(
    body?: models.AssetBatchInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/assets/batch',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ItemBatchOutputV1>;
  }

  /**
   * 
   * @summary Create an asset
   * @param {models.AssetInputV1} body - Asset information
   */
  public createAsset(
    body: models.AssetInputV1,
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(body)) {
      throw new Error("'body' parameter required");
    }

    return this.$http({
      method: 'POST',
      url: this.APPSERVER_API_PREFIX + '/assets',
      data: body,
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AssetOutputV1>;
  }

  /**
   * 
   * @summary Get an asset
   * @param {string} id - ID of the asset to retrieve
   */
  public getAsset(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/assets/{id}'
        .replace('{id}', encodeURIComponent(String(id))),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.AssetOutputV1>;
  }

  /**
   * Pagination is enabled for this query
   * @summary Get a collection of assets
   * @param {string} [scopedTo] - The ID of the workbook to which the items are scoped&lt;br&gt;If the scopedTo is set to the Empty GUID then the scoped items are excluded
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getAssets(
    {
      scopedTo,
      offset,
      limit
    } : {
      scopedTo?: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: Partial<ng.IRequestConfig>
  ) {
    return this.$http({
      method: 'GET',
      url: this.APPSERVER_API_PREFIX + '/assets',
      params: omitBy({
        ['scopedTo']: scopedTo,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      ...extraHttpRequestConfig
    }) as ng.IHttpPromise<models.ItemPreviewListV1>;
  }

}
