import React, { useState } from 'react';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { Field, useForm } from 'react-final-form';
import {
  getFormFieldProps,
  getValidationFunction
} from '@/hybrid/formbuilder/formbuilder.utilities';
import classNames from 'classnames';
import { ButtonGroup, ButtonItem } from '@/hybrid/core/ButtonGroup.molecule';

export interface ButtonGroupIF extends ValidatingFormComponent<string> {
  component: 'ButtonGroupFormComponent';
  buttonItems: ButtonItem[];
  label?: string;
  required?: string;
}

/**
 * A wrapper around the `ButtonGroup` component for use with `FormBuilder`
 */
export const ButtonGroupFormComponent: React.FunctionComponent<ButtonGroupIF> = (
  props
) => {
  const {
    name,
    testId = 'buttonGroup',
    label,
    buttonItems,
    validation,
    extendValidation,
    extraClassNames,
    required,
  } = props;

  const defaultValidation = value => required && !value;

  const appliedValidation = getValidationFunction(
    defaultValidation,
    extendValidation,
    validation
  );

  const { t } = useTranslation();
  const formState = useForm().getState();

  return (
    <div className="flexRowContainer" data-testid={testId}>
      {label && (
        <div className="pb5">
          <span data-testid={`${testId}Label`}>{t(label)}</span>
        </div>
      )}
      <div
        className={classNames('mb8', 'flexColumnContainer', extraClassNames)}>
        <Field name={name} validate={appliedValidation}>
          {({ input, meta }) => {
            const { value, name, onChange } = getFormFieldProps(
              formState,
              input,
              meta,
              props
            );

            return (
              <ButtonGroup
                buttonItems={buttonItems}
                name={name}
                value={value}
                onChange={onChange}
                testId={`${testId}Input`}
              />
            );
          }}
        </Field>
      </div>
    </div>
  );
};
