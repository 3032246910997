import _ from 'lodash';
import angular from 'angular';
import { ExportApi } from 'sdk/api/ExportApi';
import { NotificationsService } from '@/services/notifications.service';
import { JobsApi } from 'sdk/api/JobsApi';
import { SystemApi } from 'sdk/api/SystemApi';
import { SubscriptionsApi } from 'sdk/api/SubscriptionsApi';
import { RequestsApi, UserGroupsApi, UserInputV1, UsersApi } from '@/sdk';
import { AdministrationStore } from '@/administration/administration.store';
import { AccessKeysApi } from 'sdk/api/AccessKeysApi';
import { PUSH_IGNORE } from '@/services/stateSynchronizer.service';
import { CONFIGURATION_TAB_INDEX, JOBS_TAB_INDEX } from '@/administration/administration.module';

/**
 * Exposes available actions that can be used when administering Seeq.
 */
angular.module('Sq.Administration').service('sqAdministrationActions', sqAdministrationActions);

export type AdministrationActions = ReturnType<typeof sqAdministrationActions>;

function sqAdministrationActions(
  $q: ng.IQService,
  flux: ng.IFluxService,
  sqNotifications: NotificationsService,
  sqExportApi: ExportApi,
  sqJobsApi: JobsApi,
  sqUsersApi: UsersApi,
  sqAdministrationStore: AdministrationStore,
  sqSystemApi: SystemApi,
  sqSubscriptionsApi: SubscriptionsApi,
  sqAccessKeysApi: AccessKeysApi,
  sqRequestsApi: RequestsApi,
  sqUserGroupsApi: UserGroupsApi
) {

  const service = {
    load,
    setActiveTabIndex,
    setSearchParams,
    resetSearchParams,

    saveNewUser,
    updateUser,
    enableUsers,
    removeUsers,
    setPageSizeForTable,
    setPageNumberAndGo,
    loadTable,
    resetTable,
    setFilter,
    setSort,

    saveNewGroup,
    updateGroup,
    removeGroups,

    refreshAdminContactInfo,
    updateAdminContactInfo,

    refreshRequests,
    cancelRequests,

    refreshSubscriptions,

    refreshJobs,
    stopJobs,

    refreshExports,
    archiveExports,

    refreshKeys,
    deleteKeys
  };

  return service;

  /**
   * Makes required asynchronous calls to load user groups state.
   *
   * @returns {Promise} that resolves when groups have loaded
   */
  function load() {
    flux.dispatch('ADMINISTRATION_CLEAR_GROUPS');

    return sqUserGroupsApi.getUserGroups({ limit: 5000 })
      .then(({ data }) => {
        _.forEach(data.items, service.saveNewGroup);
      });
  }

  /**
   * Sets the active tab in the administration store
   *
   * @param {Object} tab - The tab index
   * @param {boolean} setDefaultSearchParamsForTab - True to set search parameters for the tab
   */
  function setActiveTabIndex(tab, setDefaultSearchParamsForTab = false) {
    flux.dispatch('ADMINISTRATION_SET_ACTIVE_TAB_INDEX', tab);
    service.resetSearchParams();

    if (setDefaultSearchParamsForTab) {
      switch(_.toNumber(tab)) {
        case JOBS_TAB_INDEX:
          return service.setSearchParams({ field: 'group', value: 'ScreenshotCapture' });
        case CONFIGURATION_TAB_INDEX:
          return service.setSearchParams({ field: 'advanced', value: false });
        default:
          return;
      }
    }
  }

  function setSearchParams({ field, value }) {
    flux.dispatch('ADMINISTRATION_SET_SEARCH_PARAMS', { field, value });
  }

  function resetSearchParams() {
    flux.dispatch('ADMINISTRATION_RESET_SEARCH_PARAMS');
  }

  /**
   * Adds a user to the administration store
   *
   * @param {Object} user - The user object
   */
  function saveNewUser(user) {
    flux.dispatch('ADMINISTRATION_ADD_USER', user);
  }

  /**
   * Adds a groups to the administration store
   *
   * @param  {Object} group - The group object
   */
  function saveNewGroup(group) {
    flux.dispatch('ADMINISTRATION_ADD_GROUP', group);
  }

  /**
   * Updates a user
   *
   * @param {Object} user - The user object
   */
  function updateUser(user) {
    flux.dispatch('ADMINISTRATION_UPDATE_USER', user);
  }

  /**
   * Updates a group
   *
   * @param {Object} group - The group object
   */
  function updateGroup(group) {
    flux.dispatch('ADMINISTRATION_UPDATE_GROUP', group);
  }

  /**
   * Enables or disables users by setting their 'enabled' property
   * If no users are selected a warning to select users is displayed.
   *
   * @param {Boolean} enabled - Whether or not to enable the users
   * @param {String[]} ids - The ids of the users
   * @returns {Promise} that resolves when the update is complete
   */
  function enableUsers(enabled, ids) {
    if (_.isEmpty(ids)) {
      sqNotifications.warnTranslate('ADMIN.USER.SELECTION_REQUIRED');
      return $q.resolve();
    }

    const users = [];

    _.forEach(ids, function(id) {
      users.push(sqUsersApi.updateUser({ isEnabled: enabled } as UserInputV1, { id }));
    });

    return $q.all(users)
      .then(function() {
        const payload = {
          ids,
          enable: enabled
        };
        const msg = enabled ? 'ADMIN.USER.USERS_ENABLED' : 'ADMIN.USER.USERS_DISABLED';
        flux.dispatch('ADMINISTRATION_ENABLE_USERS', payload);
        sqNotifications.successTranslate(msg);
      })
      .catch(function(error) {
        sqNotifications.error(error.data.statusMessage);
        service.loadTable();
      });
  }

  /**
   * Remove users
   * If no users are selected a warning to select users is displayed.
   *
   * @param {String[]} ids - The ids of the users
   * @param {String} newOwnerId - Id of user to take over ownership of workbooks owned by the users to remove
   * @param {Boolean} transferAclAndGroupMembership - True if ACLs and group memberships should be transferred to
   * the new owner, false if ACLs should be removed and group memberships shouldn't be transferred
   * @returns {Promise} that resolves when the removal is complete
   */
  function removeUsers(ids, newOwnerId, transferAclAndGroupMembership) {
    const removed = [];

    _.forEach(ids, function(id) {
      removed.push(sqUsersApi.deleteUser({ id, newOwnerId, transferAclAndGroupMembership }));
    });

    return $q.all(removed)
      .then(function() {
        flux.dispatch('ADMINISTRATION_REMOVE_USERS', ids);
        sqNotifications.successTranslate('ADMIN.USER.USERS_REMOVED');
      })
      .catch(function(error) {
        sqNotifications.error(error.data.statusMessage);
        service.loadTable();
      });
  }

  /**
   * Remove groups
   * If no groups are selected a warning to select groups is displayed.
   *
   * @param {String[]} ids - The ids of the groups to remove
   * @returns {Promise} that resolves when the removal is complete
   */
  function removeGroups(ids) {
    const removed = [];
    if (_.isEmpty(ids)) {
      sqNotifications.warnTranslate('ADMIN.GROUP.SELECTION_REQUIRED');
      return $q.resolve();
    }

    _.forEach(ids, (userGroupId) => {
      removed.push(sqUserGroupsApi.removeUserGroup({ userGroupId }));
    });

    return $q.all(removed)
      .then(() => {
        flux.dispatch('ADMINISTRATION_REMOVE_GROUPS', ids);
        sqNotifications.successTranslate('ADMIN.GROUP.REMOVED');
      })
      .catch((error) => {
        sqNotifications.apiError(error, { displayForbidden: true });
        service.load();
      });
  }

  /**
   * Query the Seeq back-end to get the up-to-date administrator contact information.
   *
   * @return {Promise} that resolves when the system contact information refresh is complete
   */
  function refreshAdminContactInfo() {
    return sqSystemApi.getAdministratorContactInformation().then(({ data }) => {
      flux.dispatch('ADMINISTRATION_UPDATE_CONTACT_INFO', _.pick(data, ['name', 'email']));
    });
  }

  /**
   * Updates the administrator contact information with the passed values.
   *
   * @param  {Object} adminContactInfo the contact information of the customer's Seeq administrator
   * @param  {String} adminContactInfo.name the name of the customer's Seeq administrator
   * @param  {String} adminContactInfo.email the email of the customer's Seeq administrator
   * @return {Promise} that resolves when the system contact information update is complete
   */
  function updateAdminContactInfo(adminContactInfo) {
    return sqSystemApi.setAdministratorContactInformation(adminContactInfo).then(() => {
      flux.dispatch('ADMINISTRATION_UPDATE_CONTACT_INFO', adminContactInfo);
    });
  }

  /**
   * Updates the request list.
   *
   * @return {Promise} that resolves when the request update is complete
   */
  function refreshRequests() {
    return sqRequestsApi.getRequests({ limit: 10000 })
      .then(({ data }) => {
        flux.dispatch('ADMINISTRATION_SET_TOO_MANY_REQUESTS', !!data.next, PUSH_IGNORE);
        return data;
      });
  }

  /**
   * Cancels the given requests.
   *
   * @param {String[]} ids - the ids of the requests to cancel
   * @return {Promise} that resolves when the request update is complete
   */
  function cancelRequests(ids) {
    const cancelled = _.map(ids, requestId => sqRequestsApi.cancelRequest({ requestId }));

    return $q.all(cancelled);
  }

  /**
   * Updates the subscription list.
   *
   * @return {Promise} that resolves when the subscriptions are fetched
   */
  function refreshSubscriptions() {
    return sqSubscriptionsApi.getChannels({ limit: 1000 }).then(({ data }) => data);
  }

  /**
   * Updates the job list.
   *
   * @return {Promise} that resolves when the job update is complete
   */
  function refreshJobs() {
    return sqJobsApi.getJobs({ limit: 1000 });
  }

  /**
   * Stops the given jobs.
   *
   * @param {Object[]} jobs - the ids and groups of the jobs to stop
   * @return {Promise} that resolves when the job update is complete
   */
  function stopJobs(jobs) {
    const stopped = _.map(jobs, job => sqJobsApi.deleteJob(job));

    return $q.all(stopped);
  }

  /**
   * Updates the exports list.
   *
   * @return {Promise} that resolves when the exports are fetched
   */
  function refreshExports() {
    return sqExportApi.getExports({ limit: 5000 })
      .then(({ data }) => data)
      .catch((error) => {
        sqNotifications.apiError(error, { displayForbidden: true });
      });
  }

  /**
   * Archive the selected exports
   *
   * @param {String[]} ids - The ids of the exports to archive
   * @returns {Promise} that resolves when the archival is complete
   */
  function archiveExports(ids) {
    const archived = _.map(ids, id => sqExportApi.archiveExport({ id }));

    return $q.all(archived)
      .then(() => {
        sqNotifications.successTranslate('ADMIN.EXPORT.TRASHED');
      })
      .catch((error) => {
        sqNotifications.apiError(error, { displayForbidden: true });
        service.load();
      });
  }

  /**
   * Sets the filter used as searchParams in the store. Also resets the pageNumber to 1.
   *
   * @param {string} field - the field to search on
   * @param {string|boolean} value - the value to search on
   */
  function setFilter(field, value) {
    flux.dispatch('ADMINISTRATION_SET_PAGE_NUMBER', { pageNumber: 1 });
    flux.dispatch('ADMINISTRATION_SET_SEARCH_PARAMS', { field, value });
  }

  /**
   * Enables sorting. Also resets the pageNumber to 1.
   *
   * @param {string} field - the field to search on
   * @param {boolean} sortAscending - true to sort ascending, false to sort descending
   */
  function setSort(field, sortAscending) {
    if (sqAdministrationStore.sortProperty === field) {
      sortAscending = !sortAscending;
    }

    flux.dispatch('ADMINISTRATION_SET_PAGE_NUMBER', { pageNumber: 1 });
    flux.dispatch('ADMINISTRATION_SET_SORT', { field, sortAscending });
  }

  /**
   * Sets the number of items per page
   *
   * @param {number} size - the new page size
   */
  function setPageSizeForTable({ size }) {
    flux.dispatch('ADMINISTRATION_SET_PAGE_SIZE', { size });
  }

  /**
   * Sets the page number and loads the table.
   *
   * @param {number} pageNumber - the page number
   */
  function setPageNumberAndGo(pageNumber: number) {
    flux.dispatch('ADMINISTRATION_SET_PAGE_NUMBER', { pageNumber });
    service.loadTable();
  }

  /**
   * Loads the user table using the pageSize and currentPageNumber to fetch the proper page content.
   */
  function loadTable() {
    const limit = sqAdministrationStore.getPageSizeByTable();
    const currentPageNumber = sqAdministrationStore.getPageNumberForTable();
    const offset = (currentPageNumber - 1) * limit;
    const sortOrder = `${sqAdministrationStore.sortProperty} ${sqAdministrationStore.sortAscending ? 'asc' : 'desc'}`;
    flux.dispatch('ADMINISTRATION_SET_USER_TABLE_LOADING', { loading: true });

    return sqUsersApi.getUsers(
      _.assign({}, sqAdministrationStore.searchParams, { limit, offset, sortOrder, isCreated: true }))
      .then((result) => {
        flux.dispatch('ADMINISTRATION_SET_USERS', result.data.users);
        flux.dispatch('ADMINISTRATION_SET_TOTAL_USER_COUNT', { userCount: result.data.totalResults });
      })
      .finally(() => flux.dispatch('ADMINISTRATION_SET_USER_TABLE_LOADING', { loading: false }));
  }

  /**
   * Resets the page number to 1.
   */
  function resetTable() {
    flux.dispatch('ADMINISTRATION_SET_PAGE_NUMBER', { pageNumber: 1 });
  }

  /**
   * Updates the access keys list.
   *
   * @return {Promise} that resolves when the key update is complete
   */
  function refreshKeys() {
    return sqAccessKeysApi.getKeys({ offset: 0, limit: 1000, getAll: true });
  }

  /**
   * Deletes all the tokens in the tokenNames list.
   *
   * @param {String[]} name - the identifying name of all tokens to delete
   * @return {Promise} that resolves when all keys have been deleted from the list
   */
  function deleteKeys(name) {
    const deleted = _.map(name, name => sqAccessKeysApi.deleteKey({ keyName: name }));
    return $q.all(deleted);
  }
}
