import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import React from 'react';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { AuthorizationService } from '@/services/authorization.service';
import { WorkbookStore } from '@/workbook/workbook.store';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { APP_STATE } from '@/main/app.constants';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { SandboxModeBanner } from '@/hybrid/reportEditor/SandboxModeBanner.molecule';
import { PdfPreviewModalService } from '@/reportEditor/pdfPreviewModal.service';
import { useFlux } from '@/hybrid/core/useFlux.hook';

const viewModeWorksheetActionsBindings = bindingsDefinition({
  isViewMode: prop<boolean>(),
  isEditMode: prop<boolean>(),
  sqAuthorization: injected<AuthorizationService>(),
  sqWorkbookStore: injected<WorkbookStore>(),
  sqWorkbenchStore: injected<WorkbenchStore>(),
  $state: injected<ng.ui.IStateService>(),
  sqPdfPreviewModal: injected<PdfPreviewModalService>()
});

export const ViewModeWorksheetActions: SeeqComponent<typeof viewModeWorksheetActionsBindings> = (props) => {
  const { isViewMode } = props;
  const { sqAuthorization, sqWorkbookStore, $state, sqWorkbenchStore, sqPdfPreviewModal } = useInjectedBindings(
    viewModeWorksheetActionsBindings);

  const { t } = useTranslation();

  const { name, owner, effectivePermissions } = useFlux(sqWorkbookStore);
  const workbook = {
    name,
    owner,
    effectivePermissions
  };

  const canModifyWorkbook = sqAuthorization.canModifyWorkbook(workbook);
  const isReportBinder = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.isReportBinder);

  const onClickBadge = () => {
    // $state.go with { reload: true } doesn't fully reload the page here. So instead, we use href to force the page
    // to load. If we don't do this, state from view only mode can leak into edit mode, unintentionally changing a
    // workstep
    window.location.href = $state.href(APP_STATE.WORKSHEET, {
      workbookId: sqWorkbenchStore.stateParams.workbookId,
      worksheetId: sqWorkbenchStore.stateParams.worksheetId
    });
  };

  const badgeTooltip = canModifyWorkbook ? 'VIEWING_ONLY.TOOLTIP' : 'VIEWING_ONLY.TOOLTIP_NO_ACCESS';

  return isViewMode ?
    <div className="flexColumnContainer flexJustifyEnd ml10 flexNoGrowNoShrink flexSelfStretch">
      <div className="viewing-badge width-maximum height-maximum flexColumnContainer">
        <HoverTooltip text={badgeTooltip} placement="bottom">
          <span className="p3">{t('VIEWING_ONLY.HEADER')}</span>
        </HoverTooltip>
      </div>
      <SandboxModeBanner />
      {canModifyWorkbook &&
      <HoverTooltip text='VIEWING_ONLY.EDIT' placement="bottom">
        <span className="p3">
          <Icon onClick={onClickBadge} icon="fa-pencil-square-o" large={true} type="theme-light" testId="viewOnlyEditIcon"/>
        </span>
      </HoverTooltip>}
      {isReportBinder &&
      <HoverTooltip text='REPORT.EDITOR.SAVE_AS_PDF_BUTTON_TITLE' placement="bottom">
        <span className="cursorPointer sq-navbar-default link-no-underline p3">
          <Icon extraClassNames="mr7 mt3" icon="fc-pdf-export" type="theme-light" large={true}
            onClick={sqPdfPreviewModal.showModal} testId="pdfExportIcon"/>
        </span>
      </HoverTooltip>
      }
    </div>
    : null;

};

export const sqViewModeWorksheetActions = angularComponent(viewModeWorksheetActionsBindings, ViewModeWorksheetActions);
