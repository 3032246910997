import React from 'react';
import { DropdownButton } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import classNames from 'classnames';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { ToolbarDropdownTitle } from '@/hybrid/core/ToolbarDropdownTitle.molecule';

const toolbarDropdownButtonBindings = bindingsDefinition({
  /** icon class to be used with the dropdown (i.e. 'fc-zoom') */
  icon: prop<string>(),
  /** label for the toolbar item (shown only when toolbar is wide enough) */
  label: prop.optional<string>(),
  /** items for the dropdown (typically a _.map on <Dropdown.Item/>) */
  dropdownItems: prop<any>(),
  /** true to render an icon-only dropdown button */
  isSmall: prop.optional<boolean>(),
  id: prop.optional<string>(),
  /** extra class names to be placed on the DropdownButton component */
  extraClassNames: prop.optional<string>(),
  /** the text to be displayed on the icon's tooltip */
  tooltipText: prop.optional<string>(),
  tooltipPlacement: prop.optional<Placement>(),
  testId: prop.optional<string>(),
  disabled: prop.optional<boolean>()
});

/**
 * Toolbar button with dropdown list of items, e.g. trend capsule color
 */
export const ToolbarDropdownButton: SeeqComponent<typeof toolbarDropdownButtonBindings> = (props) => {
  const {
    isSmall = false,
    icon,
    label,
    tooltipText,
    tooltipPlacement = 'top',
    id,
    dropdownItems,
    extraClassNames,
    testId,
    disabled = false
  } = props;

  return (
    <DropdownButton
      disabled={disabled}
      title={<ToolbarDropdownTitle
        icon={icon}
        label={label}
        tooltipPlacement={tooltipPlacement}
        tooltipText={tooltipText}
        isSmall={isSmall}
        testId={testId}
      />}
      className={classNames(extraClassNames, 'sq-btn-toolbar-dropdown',
        isSmall ? 'sq-btn-toolbar-dropdown-sm' : 'btn-lg-text')}
      variant={null}
      bsPrefix="sq-btn"
      size="sm"
      id={id}
    >
      {dropdownItems}
    </DropdownButton>
  );
};
