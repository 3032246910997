import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { UtilitiesService } from '@/services/utilities.service';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { MAX_NAME_LENGTH } from '@/main/app.constants';
import { ButtonGroup, FormControl } from 'react-bootstrap';
import { AssetGroupActions } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { AssetGroupStore } from '@/hybrid/assetGroupEditor/assetGroup.store';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ToolbarButton } from '@/hybrid/core/ToolbarButton.molecule';
import { ConfirmDeleteModal } from '@/hybrid/core/ConfirmDeleteModal.molecule';
import { AssetPropertiesModal } from '@/hybrid/assetGroupEditor/AssetPropertiesModal.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { TrackService } from '@/track/track.service';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.module';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.module';
import { WorkbookStore } from '@/workbook/workbook.store';
import { AddAssetGroupColumnBtn } from '@/hybrid/assetGroupEditor/AddAssetGroupColumnBtn.atom';
import { WorksheetStore } from '@/worksheet/worksheet.store';

const assetGroupEditorToolbarBindings = bindingsDefinition({
  isSmall: prop<boolean>(),
  sqUtilities: injected<UtilitiesService>(),
  sqAssetGroupActions: injected<AssetGroupActions>(),
  sqAssetGroupStore: injected<AssetGroupStore>(),
  sqWorksheetActions: injected<WorksheetActions>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqTrack: injected<TrackService>(),
  sqWorkbookStore: injected<WorkbookStore>()
});

export const AssetGroupEditorToolbar: SeeqComponent<typeof assetGroupEditorToolbarBindings> = ({ isSmall }) => {
  type ModalType = 'deleteConfirmation' | 'properties' | 'calculation' | 'none';

  const {
    sqUtilities,
    sqAssetGroupActions,
    sqAssetGroupStore,
    sqWorksheetActions,
    sqWorksheetStore,
    sqTrack,
    sqWorkbookStore
  } = useInjectedBindings(assetGroupEditorToolbarBindings);

  const {
    id,
    description,
    nameError,
    name
  } = useFlux(sqAssetGroupStore);

  const { t } = useTranslation();

  const [modalShown, setModalShown] = useState<ModalType>('none');
  const [inProgress, setInProgress] = useState(false);

  const isPresentationMode = sqUtilities.isPresentationWorkbookMode;

  const updateName = e => sqAssetGroupActions.setName(e.target.value);
  const closeModal = () => setModalShown('none');

  useEffect(() => {
    if (_.isEmpty(name)) {
      sqAssetGroupActions.setName(`${t('ASSET_GROUP_EDITOR.ASSET_GROUP')} ${_.size(sqWorkbookStore.assetGroups) + 1}`);
    }
  }, []);

  const updatePropertiesInStore = ({ name, description }) => {
    sqAssetGroupActions.setName(name);
    sqAssetGroupActions.setDescription(description);
    closeModal();
  };

  return !isPresentationMode && (
    <>
      <div className="flexColumnContainer flexAlignCenter toolbar min-height-50" data-testid="assetGroupToolbar">
        <div className="flexColumnContainer width-maximum flexAlignCenter">
          <Icon icon="fa-cubes" extraClassNames="fa-xlg fa-fw mr10" testId="assetGroupIcon" />
          <FormControl
            id="name"
            size="lg"
            value={name}
            placeholder={t('ASSET_GROUP_EDITOR.NAME_PLACEHOLDER')}
            maxLength={MAX_NAME_LENGTH.TOOL}
            onChange={updateName}
            onFocus={e => e.target.select()}
            data-testid="assetGroupNameInput"
            className={classNames('width-250', 'mr5', { 'error-border': nameError })}
            required={true} />

          <ButtonGroup>
            <ToolbarButton
              onClick={() => setModalShown('properties')}
              label="ASSET_GROUP_EDITOR.EDIT_GROUP"
              tooltipText="ASSET_GROUP_EDITOR.EDIT_GROUP"
              icon="fa-edit"
              isSmall={isSmall}
              testId="assetGroupProperties" />
          </ButtonGroup>

          <ButtonGroup id="assetGroupBtns">
            <ToolbarButton
              onClick={sqAssetGroupActions.addNewAsset}
              label="ASSET_GROUP_EDITOR.ADD_ASSET"
              tooltipText="ASSET_GROUP_EDITOR.ADD_ASSET"
              icon="fc-add-row-2"
              isSmall={isSmall}
              testId="addNewAsset" />

            <AddAssetGroupColumnBtn isSmall={isSmall} />
          </ButtonGroup>
        </div>

        {id &&
        <HoverTooltip text={isSmall && 'ASSET_GROUP_EDITOR.DELETE_ASSET_GROUP'}>
          <div className="text-underline-onhover mr20 cursorPointer sq-text-primary nowrap"
            onClick={() => setModalShown('deleteConfirmation')}>
            <Icon testId="deleteAssetGroupBtn" icon="fa-trash" />
            {!isSmall && <span className="ml5">{t('ASSET_GROUP_EDITOR.DELETE_ASSET_GROUP')}</span>}
          </div>
        </HoverTooltip>
        }

        <TextButton
          onClick={() => {
            sqAssetGroupActions.reset();
            sqWorksheetActions.setView(sqWorksheetStore.returnView);
          }}
          label="CLOSE"
          size="sm"
          testId="cancelAssetGroupBtn"
          extraClassNames="min-width 100 width-100 max-width-100 mr20"
          id="cancelAssetGroupBtn"
          variant="outline" />

        <TextButton
          onClick={() => {
            setInProgress(true);
            sqTrack.doTrack('Asset Group Editor', 'Save', JSON.stringify(sqAssetGroupActions.getAssetGroupSummary()));
            sqAssetGroupActions.saveOrUpdateAssetGroup()
              .then(() => {
                sqAssetGroupActions.setHasUnsavedChanges(false);
                sqWorksheetActions.setView(sqWorksheetStore.returnView);
              })
              .catch(() => {})
              .finally(() => setInProgress(false));
          }}
          label="SAVE"
          disabled={inProgress || nameError || sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.VIEW}
          size="sm"
          testId="saveAssetGroupBtn"
          extraClassNames="min-width 100 width-100 max-width-100"
          id="saveAssetGroupBtn"
          variant="theme" />
      </div>

      {modalShown === 'deleteConfirmation' &&
      <ConfirmDeleteModal
        onClose={closeModal}
        bodyTranslationKey="ASSET_GROUP_EDITOR.DELETE_MODAL_BODY"
        action={sqAssetGroupActions.deleteAssetGroup} />}

      {modalShown === 'properties' &&
      <AssetPropertiesModal
        name={name}
        description={description}
        onClose={closeModal}
        onSave={updatePropertiesInStore} />}
    </>
  );
};
