import angular from 'angular';
import template from './bottomPanels.component.html';

angular.module('Sq.TrendViewer').component('sqBottomPanels', {
  template,
  controller: 'BottomPanelsCtrl',
  bindings: {
    parentHeight: '<',
    hideCapsulesPanel: '<?'
  }
});
