import _ from 'lodash';
import angular from 'angular';
import { InvestigateStore } from '@/investigate/investigate.store';
import { InvestigateActions } from '@/investigate/investigate.actions';
import { INVESTIGATE_TOOLS, TREND_TOOLS } from '@/investigate/investigate.module';
import { UtilitiesService } from '@/services/utilities.service';

angular.module('Sq.Investigate').controller('InvestigateCtrl', InvestigateCtrl);

function InvestigateCtrl(
  $scope: ng.IScope,
  sqInvestigateStore: InvestigateStore,
  sqInvestigateActions: InvestigateActions,
  sqUtilities: UtilitiesService
) {
  const vm = this;

  vm.TREND_TOOLS = TREND_TOOLS;
  vm.clearResults = _.partial(sqInvestigateActions.setActiveTool, TREND_TOOLS.OVERVIEW);
  vm.clearItem = sqInvestigateActions.clearItem;
  vm.setActiveTool = sqInvestigateActions.setActiveTool;
  vm.setToolFilter = sqInvestigateActions.setToolFilter;
  vm.setDerivedDataPanelOpen = sqInvestigateActions.setDerivedDataPanelOpen;
  vm.onBreadcrumbClick = onBreadcrumbClick;
  vm.onToolCardClick = onToolCardClick;
  vm.isViewMode = sqUtilities.isViewOnlyWorkbookMode;

  $scope.$listenTo(sqInvestigateStore, syncInvestigateVars);

  /**
   * Syncs sqInvestigateStore and view-model properties
   */
  function syncInvestigateVars() {
    vm.item = sqInvestigateStore.item;
    vm.activeTool = sqInvestigateStore.activeTool;
    vm.toolFilter = sqInvestigateStore.toolFilter;
    vm.filteredTools = sqInvestigateStore.filteredTools;
    vm.breadcrumbs = sqInvestigateStore.breadcrumbs;
    vm.derivedDataPanelOpen = sqInvestigateStore.derivedDataPanelOpen;
    vm.showToolcards = _.get(_.find(INVESTIGATE_TOOLS, ['id', vm.activeTool]), 'isGroup');
  }

  /**
   * Sets the active tool if it is a tool that is selected or, if it is an item, sets the investigate item.
   *
   * @param {Object} breadcrumb - The selected breadcrumb
   */
  function onBreadcrumbClick(breadcrumb) {
    sqInvestigateActions.setActiveTool(breadcrumb.id);
    if (breadcrumb.id === TREND_TOOLS.OVERVIEW) {
      sqInvestigateActions.clearItem();
    }
  }

  /**
   * Loads a tool for the item that is being investigated.
   *
   * @param {InvestigateTool} tool - The investigate tool to load
   */
  function onToolCardClick(tool) {
    sqInvestigateActions.setActiveTool(tool.id);
  }
}
