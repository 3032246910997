import React from 'react';
import { TrendActions } from '@/trendData/trend.actions';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { TrendStore } from '@/trendData/trend.store';
import { TrackService } from '@/track/track.service';
import { useFluxPath } from '@/hybrid/core/useFluxPath.hook';
import { ToolbarButton } from '@/hybrid/core/ToolbarButton.molecule';

const trendGridlinesButtonBindings = bindingsDefinition({
  isSmall: prop<boolean>(),
  sqTrendActions: injected<TrendActions>(),
  sqTrendStore: injected<TrendStore>(),
  sqTrack: injected<TrackService>()
});

export const TrendGridlinesButton: SeeqComponent<typeof trendGridlinesButtonBindings> = (props) => {
  const { isSmall } = props;
  const {
    sqTrendActions,
    sqTrendStore,
    sqTrack
  } = useInjectedBindings(trendGridlinesButtonBindings);

  const showGridlines = useFluxPath(sqTrendStore, () => sqTrendStore.showGridlines);

  const setGridlinesAndTrack = () => {
    sqTrack.doTrack('Trend', `Gridlines ${!showGridlines ? 'Enabled' : 'Disabled'}`);
    sqTrendActions.setGridlines(!showGridlines);
  };

  return (
    <ToolbarButton
      onClick={setGridlinesAndTrack}
      active={showGridlines}
      label="TOOLBAR.GRIDLINES"
      icon="fc-gridline"
      isSmall={isSmall}
      tooltipText="TOOLBAR.GRIDLINES_TOOLTIP"
      tooltipPlacement="top"
      extraClassNames="mb2"
    />
  );
};
