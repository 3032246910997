import React from 'react';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import { HoverTooltip } from './HoverTooltip.atom';
import { Icon } from './Icon.atom';
import moment from 'moment-timezone';

export interface DateFormatSelectorProps {
  label: string;
  predefinedFormatsLabel: string;
  value: string;
  id: string;
  onChange: (value: string) => void;
  onKeyUp: (e) => void;
  timezone: string;
}

export const DATE_FORMATS = ['l', 'lll', 'LT', 'lll z', 'M/D/YYYY h:mm a z'];

export const DateFormatSelector: React.FunctionComponent<DateFormatSelectorProps> = (
  { label, value, id, onChange, onKeyUp, predefinedFormatsLabel, timezone }
) => <div className="flexColumnContainer">
    <Form.Label htmlFor="headersFormat" className="text-nowrap">
      {label}
      <a href="https://telemetry.seeq.com/support-dates/docs/#/displaying/" target="_blank">
        <HoverTooltip text="TABLE_BUILDER.DATE_HELP" placement="top">
          <Icon icon="fa-question-circle" extraClassNames="btn-transparent" />
        </HoverTooltip>
      </a>
    </Form.Label>
    <div className="flexRowContainer">
      <Form.Control
        type="text"
        size="sm"
        className="text-monospace width-100 ml10 mb10"
        autoComplete="off"
        name="format"
        id={id}
        value={value}
        onChange={e => onChange(e.target.value)}
        onKeyUp={onKeyUp} />
      <strong>{predefinedFormatsLabel}</strong>
      <ul className="pl20 mb0" data-testid={`ul-${id}`}>
        {_.map(DATE_FORMATS, format => (<li key={format}>
          <a href="#" onClick={() => onChange(format)}>
            {moment().tz(timezone).format(format)}
          </a>
        </li>))}
      </ul>
    </div>
  </div>;
