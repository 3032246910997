import angular from 'angular';
import { sqAgentsStatus } from '@/header/agentsStatus.component';

const dependencies = [
  'Sq.AppConstants',
  'Sq.Workbench',
  'Sq.Core',
  'Sq.Directives.DoubleClick',
  'Sq.Worksheets',
  'Sq.Worksteps',
  'Sq.Vendor',
  'Sq.Worksheet',
  'Sq.LicenseManagement',
  'Sq.SystemWarning',
  'Sq.Services.Authentication',
  'Sq.Services.Authorization',
  'Sq.Services.PendingRequests',
  'Sq.Services.Notifications',
  'Sq.Services.ACLService',
  'Sq.Services.Utilities',
  'Sq.Services.Modal',
  'Sq.Administration.Datasources',
  'Sq.Administration.Agents'
];

/**
 * The Sq.Header module contains all functionality related to the workbench header panel
 */
angular.module('Sq.Header', dependencies)
  .component('sqAgentsStatus', sqAgentsStatus);
