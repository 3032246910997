import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { AgentsApi } from '@/sdk';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { NotificationsService } from '@/services/notifications.service';

const agentModalBindings = bindingsDefinition({
  sqAgentsApi: injected<AgentsApi>(),
  sqNotifications: injected<NotificationsService>(),
  agentName: prop<string>(),
  onClose: prop<() => void>()
});

export const ManageAgentModal: SeeqComponent<typeof agentModalBindings> = (props) => {
  const { sqAgentsApi, sqNotifications } = useInjectedBindings(agentModalBindings);
  const { agentName, onClose } = props;
  const { t } = useTranslation();
  const [agentConfig, setAgentConfig] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    sqAgentsApi.getAgent({ agentName }).then(({ data }) => {
      setAgentConfig(data.json);
    }).catch(sqNotifications.apiError)
      .finally(() => {
        setLoading(false);
      });
  }, [name]);

  const updateAgent = ({ agentName, config }) => {
    sqAgentsApi.createOrUpdateAgent({ json: config }, { agentName })
      .then(() => {
        sqNotifications.success(t('ADMIN.AGENTS.SUCCESSFULLY_UPDATED'));
        onClose();
      }).catch(sqNotifications.apiError);
  };

  const formDefinition: FormElement[] = [
    {
      key: 'nameLabel',
      component: 'LabelFormComponent',
      value: name,
      name: 'nameLabel',
      testId: 'labelAgentConfig'
    },
    {
      key: 'agentConfig',
      component: 'JsonTextAreaFormComponent',
      name: 'agentConfig',
      value: agentConfig,
      testId: 'jsonAgentConfig',
      disabled: loading,
      size: 'lg',
      onChange: config => setAgentConfig(config.toString()),
      rows: 7
    }];

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="manageAgentModal">
      <Modal.Header closeButton={true} className="flexColumnContainer flexSpaceBetween">
        <h3>{t('ADMIN.AGENTS.MANAGE_AGENT_MODAL')}</h3>
      </Modal.Header>
      <Modal.Body>
        <SimpleSaveFormBuilder
          formDefinition={formDefinition}
          submitFn={() => updateAgent({ agentName, config: agentConfig })}
          closeFn={onClose} />
      </Modal.Body>
    </Modal>);
};

