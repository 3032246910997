import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import _ from 'lodash';
import { ReportConfigFixedDateRangeTable } from '@/hybrid/reportEditor/ReportConfigFixedDateRangeTable.molecule';
import { ReportConfigAutoUpdateDateRangeTable } from '@/hybrid/reportEditor/ReportConfigAutoUpdateDateRangeTable.molecule';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { DateRange } from '@/reportEditor/report.module';
import { ReportStore } from '@/reportEditor/report.store';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';

const reportConfigDateRangeTableBindings = bindingsDefinition({
  backupPreview: prop<any>(),
  viewOnly: prop.optional<boolean>(),
  dateRanges: prop<DateRange[]>(),
  dateRangeUpdating: prop<boolean>(),
  doTrack: prop<(a: String, b: string, c?: string, d?: boolean) => void>(),
  hasAutoDateRanges: prop<boolean>(),
  hasFixedDateRanges: prop<boolean>(),
  openDateSelector: prop<(dateRange: DateRange) => void>(),
  timezone: prop<string>(),
  withDateRangeUpdating: prop<(action: Function) => void>(),
  isSandboxMode: prop.optional<boolean>(),
  sqReportStore: injected<ReportStore>()
});

export const ReportConfigDateRangeTable: SeeqComponent<typeof reportConfigDateRangeTableBindings> = (props) => {
  const {
    timezone,
    hasFixedDateRanges,
    hasAutoDateRanges,
    dateRanges,
    dateRangeUpdating,
    backupPreview,
    doTrack,
    withDateRangeUpdating,
    openDateSelector,
    isSandboxMode = false,
    viewOnly = false
  } = props;

  const { sqReportStore } = useInjectedBindings(reportConfigDateRangeTableBindings);

  const { t } = useTranslation();

  const areDateRangesReadOnly = dateRangeUpdating || backupPreview;

  /**
   * Opens the date selector for a date range. If no date range is provided, the checkFixed parameter
   * is used to determine which option (Fixed or Auto-Update) should initially be checked.
   *
   * @param {DateRange} dateRange - date range to open in date selector
   * @param {boolean} checkFixed - if no date range is provided, should we check Fixed (true) or Auto-Update (false)?
   */
  const openDateSelectorPreChecked = function(dateRange?, checkFixed?) {
    if (!areDateRangesReadOnly) {
      dateRange = dateRange ? _.cloneDeep(dateRange) :
        ((checkFixed || _.isNil(checkFixed)) ? {} : { auto: { enabled: true } });

      openDateSelector(dateRange);
    }
  };

  return (
    <div className={`flexRowContainer mb15 toolOptions ${areDateRangesReadOnly ? 'disabled' : ''}`}>
      <div
        className="flexColumnContainer flexSpaceBetween pt10 pb10 pl3">
        <div>
          <Icon
            icon="fa-calendar"
            extraClassNames=""
            type="text"
            large={true} />
          <span className="mb5 pl5">
            {t('REPORT.CONFIG.REPORTING_RANGES')}
          </span>
        </div>
        <div className="mr5 pr3 pl5 flexColumnContainer">
          {!viewOnly &&
          <HoverTooltip
            text="REPORT.CONFIG.CONFIGURE">
            <div data-testid="addDateRangeIcon"
              onClick={() => {
                doTrack('Topic', 'Date Range Tool', 'new - main plus icon');
                openDateSelectorPreChecked(undefined, true);
              }}>
              <Icon
                icon="fa-fw fa-plus"
                extraClassNames="cursorPointer ml5 mt2"
                large={true}
                type={areDateRangesReadOnly ? 'inherit' : 'theme'} />
            </div>
          </HoverTooltip>}
        </div>
      </div>
      <div className="divider" />
      {!dateRanges.length && !viewOnly &&
      <div className="pt10 pb10 pl3">
        <a className="specConfigureCustomDateRangeLink"
          href="#"
          onClick={() => {
            doTrack('Topic', 'Date Range Tool', 'new - text link');
            openDateSelector(undefined);
          }}>
          {t('REPORT.CONFIG.CONFIGURE')}
        </a>
      </div>}
      {viewOnly && !sqReportStore.hasInUseDateRanges() &&
        <span className='text-center'>{t("REPORT.CONFIG.DATES_NOT_USED")}</span>
      }
      {hasFixedDateRanges
      && <div>
        <div className="divider" />
        <ReportConfigFixedDateRangeTable
          areDateRangesReadOnly={areDateRangesReadOnly}
          dateRanges={dateRanges}
          viewOnly={viewOnly}
          timezone={timezone}
          openDateSelector={openDateSelectorPreChecked}
          withDateRangeUpdating={withDateRangeUpdating}
        />
      </div>}
      {hasAutoDateRanges
      && <div>
        <div className="divider" />
        <ReportConfigAutoUpdateDateRangeTable
          areDateRangesReadOnly={areDateRangesReadOnly}
          dateRanges={dateRanges}
          viewOnly={viewOnly}
          timezone={timezone}
          openDateSelector={openDateSelectorPreChecked}
          withDateRangeUpdating={withDateRangeUpdating} />
      </div>}
    </div>
  );
};
