import React from 'react';
import tinycolor from 'tinycolor2';
import { CustomPicker } from 'react-color';
import { Hue, Saturation } from 'react-color/lib/components/common';
import { Overlay, Popover } from 'react-bootstrap';
import { useState } from 'react';

interface HueSaturationProps {
  color: string;
  onChange: (color: object) => void;
}

/**
 * Component of ColorPicker to pick color based on Hue + Saturation
 */
const HueSaturation: React.FunctionComponent<HueSaturationProps> = (props) => {
  const color = tinycolor(props.color);
  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);

  const openHueSaturationPicker = (event) => {
    setShowPopover(true);
    setTarget(event.currentTarget);
  };

  const closeHueSaturationPicker = () => {
    setShowPopover(false);
  };

  return (
    <div>
      <div className="colorPickerSwatch hsSwatch" onClick={openHueSaturationPicker} data-testid="hsPickerButton"
        style={{ backgroundColor: color.toHexString() }} id="specHsPickerButton" />
      <Overlay target={target} show={showPopover} rootClose={true} onHide={closeHueSaturationPicker}
        transition={false} placement="bottom">
        <Popover id="specHSPickerPopover" data-testid="hueSaturationPickerPopover">
          <div className="backgroundColorWhite p10">
            <div className="width-150 height-150 mb5 positionRelative" id="specSaturationPicker">
              <Saturation hsv={color.toHsv()} hsl={color.toHsl()} onChange={e => props.onChange(e)} />
            </div>
            <div className="width-150 height-10 positionRelative" id="specHuePicker">
              <Hue hsl={color.toHsl()} onChange={e => props.onChange(e)} />
            </div>
          </div>
        </Popover>
      </Overlay>
    </div>
  );
};

export const HueSaturationPicker = CustomPicker(HueSaturation);
