import _ from 'lodash';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import { IS_PROTRACTOR } from '@/main/app.constants';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';

interface HoverTooltipProps {
  placement?: Placement;
  delay?: number;
  text: string;
  formattedText?: JSX.Element;
  translateParams?: object;
}

/**
 * Generic tooltip that appears after hover delay
 */
export const HoverTooltip: React.FunctionComponent<HoverTooltipProps> = (props) => {
  const { text, delay, placement, children, formattedText, translateParams } = props;
  const { t } = useTranslation();

  if (_.isEmpty(text)) {
    return <>{children}</>;
  }

  const tooltip = <Tooltip id={text}>{formattedText ? formattedText : t(text, translateParams ?? {})}</Tooltip>;
  return (
    <OverlayTrigger
      placement={placement}
      delay={IS_PROTRACTOR ? 0 : { show: delay, hide: 0 }}
      overlay={tooltip}
    >
      {props.children as any}
    </OverlayTrigger>
  );
};
