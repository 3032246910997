import _ from 'lodash';
import bind from 'class-autobind-decorator';
import HttpCodes from 'http-status-codes';
import { BuilderService } from '@/builder/builder.service';
import { ViewMode } from '@/builder/builder.module';
import { LoggerService } from '@/services/logger.service';
import { UtilitiesService } from '@/services/utilities.service';
import { APP_STATE } from '@/main/app.constants';

@bind
export class BuilderCtrl {
  buildError = this['buildError'];

  constructor(private $state,
    private $stateParams: ng.ui.IStateParamsService,
    private sqBuilder: BuilderService,
    private sqLogger: LoggerService,
    private sqUtilities: UtilitiesService) {
    const VIEW_STATES = {
      [ViewMode.View]: APP_STATE.VIEW_WORKSHEET,
      [ViewMode.Present]: APP_STATE.PRESENT_WORKSHEET,
      [ViewMode.Edit]: APP_STATE.WORKSHEET
    };
    // Sanitize the input
    const builderParams = {
      ...$stateParams,
      viewMode: _.isString($stateParams.viewMode) ? _.toLower($stateParams.viewMode) as ViewMode : ViewMode.Edit,
      startFresh: _.isString($stateParams.startFresh) ? _.toLower($stateParams.startFresh) === 'true' :
        $stateParams.startFresh
    };
    sqBuilder.run(builderParams)
      .then(params => $state.go(VIEW_STATES[builderParams.viewMode], params))
      .catch((e) => {
        if (_.get(e, 'status') === HttpCodes.UNAUTHORIZED) {
          $state.go(APP_STATE.LOGIN, { returnState: 'builder', returnParams: JSON.stringify($stateParams) });
        } else {
          this.sqLogger.error(this.sqUtilities.formatApiError(e));
          this.buildError = e;
        }
      });
  }
}
