import angular from 'angular';
import { LicenseManagementActions } from '@/licenseManagement/licenseManagement.actions';

const dependencies = [
  'Sq.Core',
  'Sq.AppConstants',
  'Sq.Workbench',
  'Sq.Services.Authorization',
  'Sq.Services.Notifications'
];

/**
 * @module The LicenseManagement module
 */
angular.module('Sq.LicenseManagement', dependencies)
  .service('sqLicenseManagementActions', LicenseManagementActions);

export const LICENSE_STATUS = {
  NONE: 'no license',
  VALID: 'valid license',
  SHOW_ADMIN_LICENSE_WARNING: 'display admin license warning',
  SHOW_LICENSE_WARNING: 'display license warning',
  EXPIRED: 'license expired',
  UNKNOWN: 'unknown status'
};
export const WARNING_FLAGS = {
  STANDARD: {
    SHOW_ADMIN_WARNING_DAYS_COUNT: 60,
    SHOW_WARNING_DAYS_COUNT: 30
  },
  TRIAL: {
    SHOW_ADMIN_WARNING_DAYS_COUNT: 30,
    SHOW_WARNING_DAYS_COUNT: 10
  }
};
