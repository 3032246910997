import React, { useState } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { CELL_TYPES, TableColumn } from '@/hybrid/core/Table.atom';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { AdminTableWrapper } from '@/hybrid/core/AdminTableWrapper.molecule';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { AgentStatusOutputV1 } from '@/sdk';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';

const agentsTableBindings = bindingsDefinition({
  setSelectedAgents: prop<(AgentStatusOutputV1) => void>(),
  selectedAgents: prop<AgentStatusOutputV1[]>(),
  agents: prop<AgentStatusOutputV1[]>()
});

export const AgentsTable: SeeqComponent<typeof agentsTableBindings> = (props) => {
  const { t } = useTranslation();
  const { setSelectedAgents, selectedAgents, agents } = props;

  const connectionStatusFilterOptions = [
    {
      value: SeeqNames.Connectors.Connections.Status.Connecting,
      text: 'ADMIN.DATASOURCES.FILTER.STATUS_CONNECTING'
    },
    {
      value: SeeqNames.Connectors.Connections.Status.Connected,
      text: 'ADMIN.DATASOURCES.FILTER.STATUS_CONNECTED'
    },
    {
      value: SeeqNames.Connectors.Connections.Status.Disconnected,
      text: 'ADMIN.DATASOURCES.FILTER.STATUS_DISCONNECTED'
    }
  ];

  const renderTooltip = (agent) => {
    return <HoverTooltip placement="top" text={agent.remoteUpdateError}>
      <span>{agent.remoteUpdateStatus}</span>
    </HoverTooltip>;
  };

  const columns: TableColumn[] = [
    {
      accessor: 'id',
      searchProperty: 'id',
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 40, maxWidth: 40 },
      sortable: false,
      filterable: false
    }, {
      accessor: 'name',
      searchProperty: 'name',
      header: 'ADMIN.AGENTS.NAME',
      cellStyle: { minWidth: 120, wordWrap: 'break-word' },
      sortable: true,
      filterable: true
    }, {
      accessor: 'status',
      searchProperty: 'status',
      header: 'ADMIN.AGENTS.CONNECTION_STATUS',
      cellType: CELL_TYPES.DROPDOWN,
      dropdownOptions: connectionStatusFilterOptions,
      sortable: true,
      filterable: true
    }, {
      accessor: 'version',
      searchProperty: 'version',
      header: 'ADMIN.AGENTS.VERSION',
      cellStyle: { wordWrap: 'break-word' },
      sortable: true,
      filterable: true
    }, {
      accessor: 'remoteUpdateStatus',
      searchProperty: 'remoteUpdateStatus',
      header: 'ADMIN.AGENTS.VERSION_UPDATE_STATUS',
      cellStyle: { wordWrap: 'break-word' },
      sortable: true,
      filterable: true,
      cellRenderFunction: renderTooltip,
    }
  ];

  return (
    <AdminTableWrapper
      testId="AgentsAdministrationTable"
      columns={columns}
      items={agents}
      defaultSort={{ property: 'status', asc: true }}
      selectedItems={selectedAgents}
      setSelectedItems={setSelectedAgents}
      noItemsComponent={<span>{t('ADMIN.AGENTS.NO_AGENTS_FOUND')}</span>}
    />
  );
};
