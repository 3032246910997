import React, { useState, useEffect } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { ReportActions } from '@/reportEditor/report.actions';
import { LoggerService } from '@/services/logger.service';
import { NotificationsService } from '@/services/notifications.service';
import _ from 'lodash';
import { DateRange } from '@/reportEditor/report.module';
import { TrackService } from '@/track/track.service';
import { ReportConfigDateRangeGroup } from '@/hybrid/reportEditor/ReportConfigDateRangeGroup.molecule';
import { ReportStore } from '@/reportEditor/report.store';

const reportConfigFixedDateRangeTableBindings = bindingsDefinition({
  timezone: prop<string>(),
  viewOnly: prop.optional<boolean>(),
  openDateSelector: prop<(dateRange: DateRange, checkFixed: boolean) => void>(),
  withDateRangeUpdating: prop<(action: Function) => void>(),
  sqReportActions: injected<ReportActions>(),
  sqNotifications: injected<NotificationsService>(),
  sqLogger: injected<LoggerService>(),
  sqTrack: injected<TrackService>(),
  dateRanges: prop<DateRange[]>(),
  areDateRangesReadOnly: prop<boolean>()
});

export const ReportConfigFixedDateRangeTable: SeeqComponent<typeof reportConfigFixedDateRangeTableBindings> = (props) => {
  const {
    sqReportActions,
    sqNotifications,
    sqLogger,
    sqTrack,
  } = useInjectedBindings(reportConfigFixedDateRangeTableBindings);

  const {
    timezone,
    openDateSelector,
    withDateRangeUpdating,
    viewOnly = false,
    dateRanges,
    areDateRangesReadOnly
  } = props;

  const fixedDateRanges = _.chain(dateRanges)
    .reject(dateRange => !dateRange.auto || dateRange.auto.enabled)
    .sortBy('name')
    .value();

  /**
   * Moves all fixed date ranges to the latest time
   */
  const stepAllFixedDateRangesToEnd = () => {
    sqTrack.doTrack('Topic', 'DateRange Tool', 'edit - step all fixed to end');
    withDateRangeUpdating(() =>
      Promise.all(_.chain(fixedDateRanges)
        .reject(range => range.condition.isCapsuleFromTable)
        .map(dateRange => stepDateRangeToEndWithErrorHandling(dateRange))
        .value()));
  };

  const decoratedStepAll = () => {
    const onAction = () => setTellChildToFireStep(false);
    const onSandbox = () => setTellChildToFireStep(true);
    sqReportActions.doActionElseActivateSandbox(stepAllFixedDateRangesToEnd, onAction, onSandbox)();
  };
  const [tellChildToFireStep, setTellChildToFireStep] = useState(false);

  /**
   * Moves a date range to the latest time
   *
   * @param {DateRange} dateRange - the date range to adjust
   */
  const stepDateRangeToEnd = (dateRange: DateRange) => {
    sqTrack.doTrack('Topic', 'Date Range Tool', 'edit - step individual fixed to end');
    withDateRangeUpdating(() =>
      stepDateRangeToEndWithErrorHandling(dateRange));
  };

  /**
   * Wrapper around `sqReportActions.stepDateRangeToEnd` to handle the special case of a capsule not being found
   *
   * @param {DateRange} dateRange - the date range to adjust
   */
  const stepDateRangeToEndWithErrorHandling = (dateRange: DateRange) => {
    return sqReportActions.stepDateRangeToEnd(dateRange.id)
      .catch((e) => {
        if (_.includes(sqLogger.format`${e}`, 'No Capsule found')) {
          sqNotifications.errorTranslate('REPORT.CONFIG.STEP_TO_END_CAPSULE_NOT_FOUND', {
            dateVariableName: dateRange.name
          });
        } else {
          sqNotifications.apiError(e);
          return Promise.reject(e);
        }
      });
  };

  const onClickAdd = () => {
    sqTrack.doTrack('Topic', 'Date Range Tool', 'new - fixed plus icon');
    openDateSelector(undefined, true);
  };

  return (
    <div className="flexRowContainer pt5 pb5" data-testid="fixedDateRangeTable">
      <ReportConfigDateRangeGroup
        triggerStepFunctionOnLoad={tellChildToFireStep}
        timezone={timezone}
        onClickStep={decoratedStepAll}
        onClickAdd={onClickAdd}
        dateRanges={fixedDateRanges}
        isAutoUpdate={false}
        viewOnly={viewOnly}
        areDateRangesReadOnly={areDateRangesReadOnly}
        openDateSelector={openDateSelector}
        stepDateRangeToEnd={stepDateRangeToEnd}
      />
    </div>
  );
};
