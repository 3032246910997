import { useEffect } from 'react';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { useInjectorGet } from '@/hybrid/core/useInjectorGet.hook';
import { DatasourcesStore } from '@/hybrid/administration/datasources/datasources.store';
import { DatasourcesStatusOutputV1 } from '@/sdk';
import { DetailsLevel } from '@/hybrid/administration/datasources/datasources.module';
import { DatasourcesService } from '@/hybrid/administration/datasources/datasources.service';

export function useDatasources(detailsLevel: DetailsLevel): DatasourcesStatusOutputV1 {
  const sqDatasourcesStore = useInjectorGet<DatasourcesStore>('sqDatasourcesStore');
  const sqDatasourcesService = useInjectorGet<DatasourcesService>('sqDatasourcesService');
  const storeExports = useFlux(sqDatasourcesStore);
  useEffect(() => {
    sqDatasourcesService.subscribe(detailsLevel);
    return () => sqDatasourcesService.unsubscribe(detailsLevel);
  }, []);

  return storeExports.datasources;
}
