import angular from 'angular';
import { InvestigateActions } from '@/investigate/investigate.actions';

angular.module('Sq.Investigate').controller('AdvancedParametersCtrl', AdvancedParametersCtrl);

function AdvancedParametersCtrl(
  $scope: ng.IScope,
  sqInvestigateActions: InvestigateActions) {
  const vm = this;
  vm.collapse = collapse;

  $scope.$listenTo(vm.toolStore, setStoreVars);

  function setStoreVars() {
    vm.collapsed = vm.toolStore.advancedParametersCollapsed;
  }

  function collapse() {
    vm.collapsed = !vm.collapsed;
    sqInvestigateActions.setAdvancedParametersCollapsedState(vm.toolId, vm.collapsed);
  }
}
