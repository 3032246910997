import angular from 'angular';
import template from './journalPanel.component.html';

/**
 * The sqJournalPanel component is the root component of a hierarchy of components that implements the Journal
 * feature. The structure of this component hierarchy is as follows:
 *
 * sqJournalPanel - Top level component. The associated JournalPanelCtrl is the primary controller for the feature.
 *   sqJournalOverview - Displays categories for worksheet, workbook, and annotation Journal entries.
 *    sqJournalCategory - Displays Journal entry cards for category. Three instances of this component are used.
 *      sqJournalCard - Implements an overview card for a single Journal entry.
 *        sqJournalMetadata - Displays user name and updatedAt date of journal entry.
 *        sqJournalReplyIcon - Displays number of comments and comment icon.
 *   sqJournalEntry - Displays a single Journal entry.
 *     sqResizablePanel - Provides resizability when the Journal entry view is expanded.
 *       sqJournalHeader - Implements the header across the top of the journal entry.
 *       sqJournalEditor - Implements the Journal editor (using the Froala editor)
 *         sqJournalLink - Implements the editor link menu dropdown component for inserting links to Seeq content.
 *       sqJournalFooter - Implements the area below the editor containing annotations.
 *         sqJournalReplyIcon - Displays number of comments and comment icon.
 *         sqJournalAnnotates - Implements UI for annotating items, capsules and time ranges.
 *       sqJournalComments - Implements UI for displaying and adding comments.
 *         sqJournalComment - Displays a single comment. Enabled modification and deletion.
 *         sqJournalReplyIcon - Displays number of comments and comment icon.
 */
angular.module('Sq.Annotation').component('sqJournalPanel', {
  controller: 'JournalPanelCtrl',
  template
});
