import React, { useEffect, useRef, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { DateRangeLabel } from './DateRangeLabel.molecule';
import { Form, Overlay, Popover } from 'react-bootstrap';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { ReportStore } from '@/reportEditor/report.store';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import _ from 'lodash';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { DATE_FORMATS, DateFormatSelector } from '@/hybrid/core/DateFormatSelector.molecule';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { ReportContentService } from '../../reportContent.service';
import { ReportActions } from '@/reportEditor/report.actions';
import { UtilitiesService } from '@/services/utilities.service';
import Select from 'react-select';
import { ConditionsApi } from '@/sdk';
import moment from 'moment';
import { InvestigateHelperService } from '@/investigate/investigateHelper.service';
import { HeaderOptionsSelector } from '@/hybrid/core/HeaderOptionsSelector.molecule';
import { Placement } from 'react-bootstrap/esm/Overlay';

const editDateRangeLabelBindings = bindingsDefinition({
  dateRangeId: prop<string>(),
  dateRangeContent: prop<string>(),
  dateRangeFormat: prop<string>(),
  updateId: prop.optional<(id: string) => void>(),
  updateContent: prop.optional<(content: string) => void>(),
  updateFormat: prop.optional<(format: string) => void>(),
  viewMode: prop.optional<boolean>(),
  sqReportStore: injected<ReportStore>(),
  sqWorksheetStore: injected<WorksheetStore>(),
  sqReportContent: injected<ReportContentService>(),
  sqReportActions: injected<ReportActions>(),
  sqUtilities: injected<UtilitiesService>(),
  sqConditionsApi: injected<ConditionsApi>(),
  sqInvestigateHelper: injected<InvestigateHelperService>()
});

export const CONTENT_TYPES = {
  START: 'start_only',
  END: 'end_only',
  START_END: 'start_end'
};

export const DEFAULT_RANGE_START = -1;

/**
 * Calculate the correct position of a tooltip for a specific element
 * based on the screen positioning (quadrant)
 * @param domElement Target HTMLElement that needs to be checked
 * @returns Placement
 */
const calculateOptimalTooltipPosition: (domElement: HTMLElement) => Placement = (domElement) => {
  let position: Placement = 'right';
  if (!domElement || !window || !window.innerWidth) return position;
  const domRect = domElement.getBoundingClientRect();
  if (window.innerWidth - domRect.x < 400) {
    position = 'left';
  }
  if (window.innerHeight - domRect.y < 400) {
    position = position === 'right' ? 'right-end' : 'left-end';
  } else {
    position = position === 'right' ? 'right-start' : 'left-start';
  }
  return position;
};

// this will be used with SCROLL events, so we should debounce it
const debouncedScrollHandler = _.debounce(calculateOptimalTooltipPosition, 100);

export const EditDateRangeLabel: SeeqComponent<typeof editDateRangeLabelBindings> = (props) => {
  const {
    dateRangeId,
    dateRangeContent,
    dateRangeFormat,
    viewMode = true,
    updateId = () => null,
    updateContent = () => null,
    updateFormat = () => null
  } = props;
  const {
    sqReportStore,
    sqWorksheetStore,
    sqReportContent,
    sqReportActions,
    sqUtilities,
    sqInvestigateHelper,
    sqConditionsApi
  } = useInjectedBindings(editDateRangeLabelBindings);
  const { t } = useTranslation();

  const [showMenu, setShowMenu] = useState(false);
  const [conditionProperties, setConditionProperties] = useState(null);
  const [capsuleData, setCapsuleData] = useState(null);
  const target = useRef(null);

  const { dateRangesNotArchived } = useFlux(sqReportStore);
  const selectedDateRangeId = dateRangeId;
  const format = dateRangeFormat || _.head(DATE_FORMATS);
  const contentSelected = dateRangeContent || CONTENT_TYPES.START;
  const dateRange = _.find(dateRangesNotArchived, ['id', selectedDateRangeId], null);
  const dateRangeStart = dateRange ? dateRange.range.start : DEFAULT_RANGE_START;
  const dateRangeEnd = dateRange ? dateRange.range.end : DEFAULT_RANGE_START;
  const ownId = useRef(null);

  const fetchCapsule = (id, start, end) => sqConditionsApi.getCapsules({
      id,
      start: moment.utc(start).toISOString(),
      end: moment.utc(end).toISOString()
    })
    .then((result) => {
      const capsule = result.data.capsules[0];
      if (capsule?.start || capsule?.end) {
        setCapsuleData(capsule);
      }
    });

  const fetchConditionProperties = id => sqInvestigateHelper.requestCapsuleProperties(id, [])
    .then(setConditionProperties);

  useEffect(() => {
    if (dateRange && dateRange.condition.id) {
      // when the date range has changed, fetch the new capsule data and then setup the condition properties
      fetchCapsule(dateRange.condition.id, dateRangeStart, dateRangeEnd)
        .then(() => fetchConditionProperties(dateRange.condition.id));
    } else {
      setCapsuleData(null);
      setConditionProperties(null);
    }
  }, [dateRangeStart, dateRangeEnd]);

  useEffect(() => {
    ownId.current = sqUtilities.base64guid();
    // I don't like this solution as it assumes that this will always be there and it doesn't actually
    // allow any custom container, but we can improve on it later
    if (document.querySelector('.ckEditorMain .editorContainer')) {
      document.querySelector('.ckEditorMain .editorContainer').addEventListener('scroll',
        () => debouncedScrollHandler(target.current));
    }

    if (!selectedDateRangeId && dateRangesNotArchived.length && dateRangesNotArchived[0].id) {
      updateId(dateRangesNotArchived[0].id);
    } else if (!dateRange && selectedDateRangeId) {
      sqReportActions.fetchDateRange(selectedDateRangeId, false)
        .then(({ dateRange }) => {
          if (viewMode) {
            let similarDateRange = sqReportStore.findSimilarDateRange(dateRange);
            if (_.isUndefined(similarDateRange)) {
              similarDateRange = sqReportStore.findDateRangeByName(dateRange.name);
            }
            updateId(similarDateRange?.id ?? selectedDateRangeId);
          } else {
            return sqReportContent.copyDateRangeForPendingContent(dateRange, Promise)
              .then(updateId);
          }
        });
    }
  }, []);

  let contentOptions = [
    {
      label: t('REPORT.DATE_RANGE_LABEL.START_TIME'),
      value: CONTENT_TYPES.START,
      id: `content-select-${ownId.current}-1`
    },
    {
      label: t('REPORT.DATE_RANGE_LABEL.END_TIME'),
      value: CONTENT_TYPES.END,
      id: `content-select-${ownId.current}-2`
    },
    {
      label: t('REPORT.DATE_RANGE_LABEL.START_END_TIME'),
      value: CONTENT_TYPES.START_END,
      id: `content-select-${ownId.current}-3`
    }
  ];
  if (conditionProperties && conditionProperties.length > 0) {
    // add the properties to the options
    contentOptions = _.concat(contentOptions, _.map(conditionProperties, ({ name }, index) => ({
      label: name,
      value: name,
      id: `content-select-custom-${index}`
    })));
  }

  return <>
    {!viewMode && <Overlay
      transition={false}
      placement={calculateOptimalTooltipPosition(target.current)}
      target={target.current}
      rootClose={true}
      show={showMenu}
      onHide={() => setShowMenu(false)}>
      <Popover id={`dateRangeMenu-${ownId.current}`} className="dateRangePopover">
        <Popover.Title>{t('REPORT.DATE_RANGE_LABEL.CONFIGURATION')}</Popover.Title>
        <Popover.Content className="ml10 mr10">
          <Form.Label>{t('REPORT.DATE_RANGE_LABEL.SELECT_DATE_RANGE')}</Form.Label>
          <Select
            value={dateRange ? { value: dateRange.id, label: dateRange.name } : null}
            options={_.map(dateRangesNotArchived, item => ({ value: item.id, label: item.name }))}
            onChange={selected => updateId(selected.value)}
          />
          <HeaderOptionsSelector
            options={contentOptions}
            onChange={updateContent}
            name={`dateRangeContent-${ownId.current}`}
            title={t('REPORT.DATE_RANGE_LABEL.LABEL_CONTENT')}
            defaultValue={contentSelected}
          />
          {
            !_.some(conditionProperties, ['name', dateRangeContent]) &&
            <DateFormatSelector
              label={t('REPORT.DATE_RANGE_LABEL.DATE_FORMAT')}
              predefinedFormatsLabel={t('TABLE_BUILDER.PREDEFINED_FORMATS')}
              value={format}
              id={`format-select-${ownId.current}`}
              onChange={updateFormat}
              onKeyUp={event => updateFormat(event.target.value)}
              timezone={sqWorksheetStore.timezone.name}
            />
          }
        </Popover.Content>
      </Popover>
    </Overlay>}
    <DateRangeLabel
      onClick={() => setShowMenu(true)}
      targetRef={target}
      dateRange={dateRange}
      contentToDisplay={contentSelected}
      format={format}
      timezone={sqWorksheetStore.timezone.name}
      capsuleData={capsuleData}
    />
  </>;
};
