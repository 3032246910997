import angular from 'angular';
import { APP_STATE } from '@/main/app.constants';

angular.module('sqApp').controller('UnauthorizedCtrl', UnauthorizedCtrl);

function UnauthorizedCtrl(
  $scope: ng.IScope,
  $state: ng.ui.IStateService,
  $stateParams: ng.ui.IStateParamsService
) {
  const vm = this;

  vm.APP_STATE = APP_STATE;
}
