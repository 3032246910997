import { Content } from '@/hybrid/annotation/ckEditorPlugins/plugins/content/Content';
import { PdfExport } from '@/hybrid/annotation/ckEditorPlugins/plugins/PdfExport';
import { FixedWidth } from '@/hybrid/annotation/ckEditorPlugins/plugins/FixedWidth';
import { HtmlPortalNode } from 'react-reverse-portal';
import { SeeqPageBreak } from '@/hybrid/annotation/ckEditorPlugins/plugins/SeeqPageBreak';
import { EDITABLE_CONTENT_MODEL_ATTRIBUTES } from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import { JournalLink } from '@/hybrid/annotation/ckEditorPlugins/plugins/JournalLink';
import { ContentBorder } from '@/hybrid/annotation/ckEditorPlugins/plugins/content/ContentBorder';

export enum CustomPlugin {
  JournalLink = 'JournalLink',
  FixedWidth = 'FixedWidth',
  Content = 'Content',
  PdfExport = 'PdfExport',
  SeeqPageBreak = 'SeeqPageBreak',
  ContentBorder = 'ContentBorder'
}

export const CustomPluginSetup: { [key in CustomPlugin]: PluginSetup } = {
  [CustomPlugin.JournalLink]: { ...JournalLink.setup },
  [CustomPlugin.FixedWidth]: { ...FixedWidth.setup },
  [CustomPlugin.Content]: { ...Content.setup },
  [CustomPlugin.PdfExport]: { ...PdfExport.setup },
  [CustomPlugin.SeeqPageBreak]: { ...SeeqPageBreak.setup },
  [CustomPlugin.ContentBorder]: { ...ContentBorder.setup }
};

export interface BasePluginDependencies {
  $injector: ng.auto.IInjectorService;
  $rootScope: ng.IRootScopeService;
  $translate: ng.translate.ITranslateService;
  annotationId: string;
  canModify: boolean;
  isPDF: boolean;
}

export interface PluginSetup {
  name: string;
  plugin: any;
  toolbar?: string;
  config?: string;
}

// Event listeners in CK have priorities which affect the order they run in. 2 events listening to the `copy` event
// would run in the order they're added unless one has a higher priority than the other. Most CK internal listeners
// have a priority of 0 or 1, so the levels here are in reference to that.
export const CK_PRIORITY = {
  HIGH: 2
};

export interface ContentCallbacks {
  updateContentModelAndNode: (editor: any, newContentId: string, modelElement: any, portalNode: HtmlPortalNode,
    contentIdChanged?: boolean) => void;
  updateContentAttributeById: (editor: any, attribute: string, value: string, contentId: string,
    save: boolean) => void;
  getCurrentModel: (contentId: string) => any | undefined;
  updateContentAttributeWithoutSaving: (attribute: string, value: string, modelElement: any) => void;
  setUseCkCopyLogic: (useCkCopyLogic: boolean) => void;
}

export interface ImageContentStyles {
  width?: number;
  height?: number;
  useWidthPercent?: boolean;
  border?: boolean;
  noMargin?: boolean;
}

export enum ImageContentListenerCommand {
  RESIZE = 'seeqContentResize',
  WIDTH = 'seeqContentWidth',
  HEIGHT = 'seeqContentHeight',
  BORDER = 'seeqContentBorder',
  NO_MARGIN = 'seeqContentNoMargin'
}

export const AttributeToListenTo: { [key in ImageContentListenerCommand]: string } = {
  [ImageContentListenerCommand.RESIZE]: EDITABLE_CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT,
  [ImageContentListenerCommand.WIDTH]: EDITABLE_CONTENT_MODEL_ATTRIBUTES.WIDTH,
  [ImageContentListenerCommand.HEIGHT]: EDITABLE_CONTENT_MODEL_ATTRIBUTES.HEIGHT,
  [ImageContentListenerCommand.BORDER]: EDITABLE_CONTENT_MODEL_ATTRIBUTES.BORDER,
  [ImageContentListenerCommand.NO_MARGIN]: EDITABLE_CONTENT_MODEL_ATTRIBUTES.NO_MARGIN
};

export enum ContentDisplayMode {
  PDF = 'PDF',
  VIEW_ONLY = 'VIEW_ONLY',
  NORMAL = 'NORMAL'
}
