import bind from 'class-autobind-decorator';
import { DatafilesApi } from 'sdk/api/DatafilesApi';
import { ImportDatafileStore } from '@/hybrid/tools/importDatafile/importDatafile.store';
import { InvestigateActions } from '@/investigate/investigate.actions';
import {
  VALIDATION_MODES,
  DELIMITERS,
  INTERPOLATION_METHODS,
  FILE_OUTPUT_TYPES,
  COLUMN_NAME,
  COLUMN_INDEX,
  SIGNAL_VALUE_COLUMN_OPTIONS,
  EMPTY_ROW_IDENTIFIER,
  DATAFILE_ITEM_TYPES,
  CONDITION_VALUE_COLUMN_OPTIONS, DAY_MONTH_FIRST_OPTIONS
} from '@/hybrid/tools/importDatafile/importDatafile.module';
import { TREND_TOOLS } from '@/investigate/investigate.module';
import _ from 'lodash';
import { DISPLAY_MODE } from '@/main/app.constants';
import { TimezonesService } from '@/datetime/timezone.service';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { TrendActions } from '@/trendData/trend.actions';
import { TrendDataHelperService } from '@/trendData/trendDataHelper.service';
import { ItemsApi } from '@/sdk';
import { ItemTypeEnum } from 'sdk/model/DatafileInputV1';

@bind
export class ImportDatafileActions {
  constructor(
    private $state: ng.ui.IStateService,
    private flux: ng.IFluxService,
    private sqDatafilesApi: DatafilesApi,
    private sqImportDatafileStore: ImportDatafileStore,
    private sqInvestigateActions: InvestigateActions,
    private sqWorksheetActions: WorksheetActions,
    private sqTimezones: TimezonesService,
    private sqTrendActions: TrendActions,
    private sqTrendDataHelper: TrendDataHelperService,
    private sqItemsApi: ItemsApi,
    private $q) {
  }

  /**
   * Sets the name of the file that the server returned after uploading it.
   *
   * @param {String} uploadFilename - The name of the file that the server returned after upload
   */
  setUploadFilename(uploadFilename) {
    this.flux.dispatch('IMPORTDATAFILE_SET_UPLOAD_FILE_NAME', { uploadFilename });
  }

  /**
   * Sets the name of the file on the client's computer. Only used to help them know what file they used when they
   * created/updated the datafile.
   *
   * @param {String} filename - The name of the file on the client
   */
  setFilename(filename) {
    this.flux.dispatch('IMPORTDATAFILE_SET_FILE_NAME', { filename });
  }

  /**
   * Sets the field delimiter for the file
   *
   * @param {String} fieldDelimiter - The character used as the field delimiter
   */
  setFieldDelimiter(fieldDelimiter) {
    this.flux.dispatch('IMPORTDATAFILE_SET_FIELD_DELIMITER', { fieldDelimiter });
  }

  /**
   * Sets the file output type (append or replace)
   *
   * @param fileOutputType - The type used for the file output
   */
  setFileOutputType(fileOutputType: { text: string, value: string }) {
    if (_.includes(FILE_OUTPUT_TYPES, fileOutputType)) {
      this.flux.dispatch('IMPORTDATAFILE_SET_FILE_OUTPUT_TYPE', { fileOutputType });
    }
  }

  /**
   * Sets the header row.
   *
   * @param {Number} nameRow - The row index to use for the name/header
   */
  setNameRow(nameRow) {
    this.flux.dispatch('IMPORTDATAFILE_SET_NAME_ROW', { nameRow });
  }

  /**
   * Sets the key column's index.
   *
   * @param {Number} keyColumnIndex - The column index to use for the key
   */
  setKeyColumnIndex(keyColumnIndex) {
    this.flux.dispatch('IMPORTDATAFILE_SET_KEY_COLUMN_INDEX', { keyColumnIndex });
  }

  /**
   * Sets the key column's name.
   *
   * @param {Number} keyColumnName - The column name to use for the key
   */
  setKeyColumnName(keyColumnName) {
    this.flux.dispatch('IMPORTDATAFILE_SET_KEY_COLUMN_NAME', { keyColumnName });
  }

  /**
   * Sets the interpolation method (linear or step).
   *
   * @param {Object} interpolationMethod - The interpolation method to use
   */
  setInterpolationMethod(interpolationMethod) {
    if (_.includes(INTERPOLATION_METHODS, interpolationMethod)) {
      this.flux.dispatch('IMPORTDATAFILE_SET_INTERPOLATION_METHOD', { interpolationMethod });
    }
  }

  /**
   * Sets the maximum interpolation.
   *
   * @param {Object} maxInterpolation - The max interpolations
   */
  setMaxInterpolation(maxInterpolation) {
    this.flux.dispatch('IMPORTDATAFILE_SET_MAX_INTERPOLATION', { maxInterpolation });
  }

  /**
   * Sets signal name prefix.
   *
   * @param {String} namePrefix - The prefix for the signal name
   */
  setNamePrefix(namePrefix) {
    this.flux.dispatch('IMPORTDATAFILE_SET_NAME_PREFIX', { namePrefix });
  }

  /**
   * Sets the signal name nameSuffix
   *
   * @param {String} nameSuffix - The nameSuffix for the signal name
   */
  setNameSuffix(nameSuffix) {
    this.flux.dispatch('IMPORTDATAFILE_SET_NAME_SUFFIX', { nameSuffix });
  }

  /**
   * Sets the first row with data.
   *
   * @param {Number} firstDataRow - The index of the first row with data
   */
  setFirstDataRow(firstDataRow) {
    this.flux.dispatch('IMPORTDATAFILE_SET_FIRST_DATA_ROW', { firstDataRow });
  }

  /**
   * Sets the max interpolation row.
   *
   * @param {Number} maxInterpolationRow - The index of the max interpolation maxInterpolationRow
   */
  setMaxInterpolationRow(maxInterpolationRow) {
    this.flux.dispatch('IMPORTDATAFILE_SET_MAX_INTERPOLATION_ROW', { maxInterpolationRow });
  }

  /**
   * Sets the interpolation method row
   *
   * @param {Number} interpolationMethodRow - The index of the interpolation method interpolationMethodRow
   */
  setInterpolationMethodRow(interpolationMethodRow) {
    this.flux.dispatch('IMPORTDATAFILE_SET_INTERPOLATION_METHOD_ROW', { interpolationMethodRow });
  }

  /**
   * Sets the value uom row.
   *
   * @param {Number} valueUomRow - The index of the uom row
   */
  setValueUomRow(valueUomRow) {
    this.flux.dispatch('IMPORTDATAFILE_SET_VALUE_UOM_ROW', { valueUomRow });
  }

  /**
   * Sets the timezone to use with the timestamps
   *
   * @param {Object} timezone - The timezone to use with the timestamps
   */
  setTimezone(timezone) {
    this.flux.dispatch('IMPORTDATAFILE_SET_TIMEZONE', { timezone });
  }

  /**
   * Sets how to handle invalid data in the CSV
   *
   * @param {Object} validationMode - Tells which way to handle invalid data
   */
  setValidationMode(validationMode) {
    if (_.includes(VALIDATION_MODES, validationMode)) {
      this.flux.dispatch('IMPORTDATAFILE_SET_VALIDATION_MODE', { validationMode });
    }
  }

  /**
   * Sets whether daylight savings time is treated leniently or not
   *
   * @param {boolean} lenientDaylightSavings - True to treat as lenient, false otherwise
   */
  setLenientDaylightSavings(lenientDaylightSavings) {
    this.flux.dispatch('IMPORTDATAFILE_SET_LENIENT_DAYLIGHT_SAVINGS', { lenientDaylightSavings });
  }

  /**
   * Sets the index of the description row
   *
   * @param {Number} descriptionRow- The index of the description row
   */
  setDescriptionRow(descriptionRow) {
    this.flux.dispatch('IMPORTDATAFILE_SET_DESCRIPTION_ROW', { descriptionRow });
  }

  /**
   * Sets the column indices which contain signal values
   *
   * @param {Number[]} valueColumnIndices- The array of column indexes which contain signal values. Starts from 1.
   */
  setValueColumnIndices(valueColumnIndices) {
    this.flux.dispatch('IMPORTDATAFILE_SET_VALUE_COLUMN_INDICES', { valueColumnIndices });
  }

  /**
   * Sets the column names which contain signal values
   *
   * @param {Number[]} valueColumnNames- The array of column names which contain signal values.
   */
  setValueColumnNames(valueColumnNames) {
    this.flux.dispatch('IMPORTDATAFILE_SET_VALUE_COLUMN_NAMES', { valueColumnNames });
  }

  /**
   * Sets the description for the datafile
   *
   * @param {string} description - description of the datafile
   */
  setDescription(description) {
    this.flux.dispatch('IMPORTDATAFILE_SET_DESCRIPTION', { description });
  }

  /**
   * Sets the data column option
   *
   * @param {Object} valueColumnOption - the data column option
   */
  setValueColumnOption(valueColumnOption) {
    let optionsToCheck;
    if (this.sqImportDatafileStore.isSignalSelected()) {
      optionsToCheck = SIGNAL_VALUE_COLUMN_OPTIONS;
    } else if (this.sqImportDatafileStore.isConditionSelected()) {
      optionsToCheck = CONDITION_VALUE_COLUMN_OPTIONS;
    }
    if (_.includes(optionsToCheck, valueColumnOption)) {
      this.flux.dispatch('IMPORTDATAFILE_SET_VALUE_COLUMN_OPTION', { valueColumnOption });
    }
  }

  /**
   * Sets the uom for the whole datafile
   *
   * @param {String} valueUom - the uom
   */
  setValueUom(valueUom) {
    this.flux.dispatch('IMPORTDATAFILE_SET_VALUE_UOM', { valueUom });
  }

  /**
   * Sets the maximum duration
   *
   * @param {object} maxDuration - the maximum duration
   */
  setMaxDuration(maxDuration) {
    this.flux.dispatch('IMPORTDATAFILE_SET_MAX_DURATION', { maxDuration });
  }

  /**
   * Sets the item type
   *
   * @param itemType - the type of the item (signal or condition). One of DATAFILE_ITEM_TYPES.
   */
  setItemType(itemType: { text: string, value: ItemTypeEnum }) {
    if (_.includes(DATAFILE_ITEM_TYPES, itemType)) {
      this.flux.dispatch('IMPORTDATAFILE_SET_ITEM_TYPE', { itemType });
    }
  }

  /**
   * Sets the end column index
   *
   * @param {number} endColumnIndex - the end column index
   */
  setEndColumnIndex(endColumnIndex) {
    this.flux.dispatch('IMPORTDATAFILE_SET_END_COLUMN_INDEX', { endColumnIndex });
  }

  /**
   * Sets the end column name
   *
   * @param {String} endColumnName - the end column name
   */
  setEndColumnName(endColumnName) {
    this.flux.dispatch('IMPORTDATAFILE_SET_END_COLUMN_NAME', { endColumnName });
  }

  /**
   * Sets the condition name
   *
   * @param {String} conditionName - the name of the condition that will be created by the import
   */
  setConditionName(conditionName) {
    this.flux.dispatch('IMPORTDATAFILE_SET_CONDITION_NAME', { conditionName });
  }

  /**
   * Sets the dayFirstDefault
   *
   * @param {Object} dayFirstDefault - one of DAY_MONTH_FIRST_OPTIONS
   */
  setDayFirstDefault(dayFirstDefault) {
    this.flux.dispatch('IMPORTDATAFILE_SET_DAY_FIRST_DEFAULT', { dayFirstDefault });
  }

  /**
   * Creates a datafile in the backend using the passed in parameters.
   *
   * @returns {Promise} - A promise for the response from creating a datafile
   */
  createDatafile() {
    return this.sqDatafilesApi.createDatafile(
      { ...this.sqImportDatafileStore.asApiObject(), scopedTo: this.$state.params.workbookId }
    );
  }

  /**
   * Updates a datafile in the backend using the passed in parameters.
   *
   * @return {Promise} - A promise for the response from editing a datafile
   */
  updateDatafile() {
    return this.sqDatafilesApi.updateDatafile(
      { ...this.sqImportDatafileStore.asApiObject(), scopedTo: this.$state.params.workbookId },
      { id: this.sqImportDatafileStore.id });
  }

  /**
   * Creates the payload for a rehydrateForEdit importdatafile store call
   *
   * @param {Object} data - the datafile output to turn into a rehydrate for edit payload
   * @returns {Object} - a rehydrate for edit payload
   */
  createRehydratePayload(data) {
    // Splits the max interpolation/duration string based on where the numbers are.
    const valueWithUnitsSplitter = valueWithUnits => _.chain(valueWithUnits)
      .split(/(\d+)/)
      .thru(([data, value, units]) => ({ value: parseInt(value), units }))
      .value();
    const maxInterpolation = valueWithUnitsSplitter(data.maximumInterpolation);
    const maxDuration = valueWithUnitsSplitter(data.maximumDuration);
    let valueColumnNames;
    let valueColumnIndices;
    let valueColumnOption;
    if (data.valueColumnNames !== '') {
      valueColumnNames = data.valueColumnNames;
      valueColumnIndices = undefined;
      valueColumnOption = COLUMN_NAME;
    } else if (data.valueColumnIndices !== '') {
      valueColumnNames = undefined;
      valueColumnIndices = data.valueColumnIndices;
      valueColumnOption = COLUMN_INDEX;
    } else {
      valueColumnNames = undefined;
      valueColumnIndices = undefined;
      valueColumnOption = _.first(SIGNAL_VALUE_COLUMN_OPTIONS);
    }

    return {
      filename: data.filename,
      description: data.description,
      endColumnIndex: data.endColumnIndex,
      endColumnName: data.endColumnName,
      fieldDelimiter: _.find(DELIMITERS, { value: data.fieldDelimiter }),
      fileOutputType: data.append === false ? _.first(FILE_OUTPUT_TYPES) : _.last(FILE_OUTPUT_TYPES),
      firstDataRow: data.firstDataRow,
      interpolationMethod: _.find(INTERPOLATION_METHODS, { value: data.interpolationMethod }),
      interpolationMethodRow: data.interpolationMethodRow === EMPTY_ROW_IDENTIFIER ? undefined :
        data.interpolationMethodRow,
      keyColumnIndex: data.keyColumnIndex,
      keyColumnName: data.keyColumnName,
      maxDuration,
      maxInterpolation,
      maxInterpolationRow: data.maximumInterpolationRow === EMPTY_ROW_IDENTIFIER ? undefined :
        data.maximumInterpolationRow,
      namePrefix: data.namePrefix,
      nameSuffix: data.nameSuffix,
      nameRow: data.nameRow,
      timezone: _.find(this.sqTimezones.timezones, { displayName: data.timeZone }),
      valueUomRow: data.valueUomRow === EMPTY_ROW_IDENTIFIER ? undefined : data.valueUomRow,
      validationMode: _.find(VALIDATION_MODES, { value: data.validationMode }),
      lenientDaylightSavings: data.lenientDaylightSavings,
      descriptionRow: data.descriptionRow === EMPTY_ROW_IDENTIFIER ? undefined : data.descriptionRow,
      valueColumnIndices,
      valueColumnNames,
      valueColumnOption,
      valueUom: data.valueUom,
      type: TREND_TOOLS.IMPORTDATAFILE,
      itemType: _.find(DATAFILE_ITEM_TYPES, { value: data.itemType }),
      conditionName: data.conditionName,
      dayFirstDefault: _.find(DAY_MONTH_FIRST_OPTIONS, { value: data.dayFirstDefault })
    };
  }

  /**
   * Load a datafile from appserver to edit its parameters, or optionally duplicate the file's settings
   *
   * @param {String} id - The id of the datafile to edit
   * @param {boolean} duplicate - Whether or not the file is being loaded to duplicate
   * @returns {Promise} - Promise from getting the datafile
   */
  loadDatafile(id, duplicate = false) {
    return this.sqDatafilesApi.getDatafile({ id }).then(({ data }) => {
      const payload: any = this.createRehydratePayload(data);
      if (!duplicate) {
        payload.id = data.id;
        payload.name = data.name;
      }
      this.flux.dispatch('TOOL_REHYDRATE_FOR_EDIT', payload);
      this.sqWorksheetActions.tabsetChangeTab('sidebar', 'investigate');
      this.sqInvestigateActions.setActiveTool(TREND_TOOLS.IMPORTDATAFILE, DISPLAY_MODE.EDIT);
    });
  }

  /**
   * Archives a datafile and all of its hosted items
   *
   * @param {String} id - The id of the datafile being archived
   * @returns {Promise} - a promise that contains the response from archiving the datafile
   */
  archiveDatafile(id) {
    return this.sqDatafilesApi.archiveDatafile({ id });
  }

  /**
   * Get the datafile via a promise
   *
   * @param {string} id - the id of the datafile
   * @returns {Promise} - a promise that returns the datafile
   */
  getDatafile(id) {
    return this.sqDatafilesApi.getDatafile({ id });
  }

  /**
   * Refreshes all items hosted by the datafile and all dependent items
   *
   * @param {string} id - the id of the datafile
   */
  refreshDatafileItems(id) {
    this.sqDatafilesApi.getItemsHostedByDatafile({ id })
      .then(({ data: { items } }) => {
        const trendItemsIds = _.map(this.sqTrendDataHelper.getAllItems(), 'id');
        const datafileItemIds = _.map(items, 'id');
        _.chain(trendItemsIds)
          .map(id => _.includes(datafileItemIds, id) ? this.$q.resolve(id) :
            this.sqItemsApi.getFormulaDependencies({ id })
              .then(({ data: { dependencies } }) => _.chain(dependencies)
                .map('id')
                .intersection(datafileItemIds)
                .thru(intersections => intersections.length ? id : null)
                .value()))
          .thru(promises => this.$q.all(promises))
          .value()
          .then(ids =>
            _.chain(ids)
              .compact()
              .uniq()
              .forEach(id => this.sqTrendActions.fetchItemAndDependents(id))
              .value()
          );
      });
  }
}
