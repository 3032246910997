import _ from 'lodash';
import angular from 'angular';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import juration from 'juration';
import { BackendDuration } from '@/services/systemConfiguration.service';
import { H_PER_DAY, MIN_PER_DAY, S_PER_DAY, S_PER_H, S_PER_MIN } from '@/datetime/datetime.module';
import { DURATION_TIME_UNITS_ALL } from '@/main/app.constants';
import { TrackService } from '@/track/track.service';

export const DOUBLE_CLICK_TIME = 300;

const TIME_24_HOUR_FORMAT = 'HH:mm'; // HTML time input values are always 24-hour

angular.module('Sq.DateTime').service('sqDateTime', sqDateTime);

export type DateTimeService = ReturnType<typeof sqDateTime>;

function sqDateTime($cacheFactory, $translate, sqTrack: TrackService) {
  // Create a size-limited LRU moment cache
  const momentCache = $cacheFactory('sqMomentCache', {
    capacity: 80000
  });

  const service = {
    getMoment,
    parseISODate,
    parseLocaleDate,
    parseDurationOffset,
    parseDuration,
    parseDurationIntoDates,
    convertDuration,
    parseRelativeDate,
    inflateTimes,
    formatDuration,
    formatSimpleDuration,
    displayMonthDay,
    formatTime,
    overlaps,
    getCapsuleFormula,
    splitDuration,
    display12HrClock,
    humanizeInterval,
    reformatZuluOffset,
    momentMeasurementStrings,
    getDurationTimeUnit,
    updateUnits,
    determineIdealUnits,
    isFrequency,
    convertToFrequencyPerDay,
    convertFrequencyToPeriod,
    convertPeriodToFrequency,
    convertFrequencyToHertz,
    convert24HourTimeToLocaleTime,
    adjust24HourTimeToTimezone,
    time24HourToMoment,
    attemptDateParse
  };

  // Set juration to parse months as 1/12 of a year, so that long durations better align with year lengths
  juration.setUnitValue('months', moment.duration(1, 'year').asSeconds() / 12);

  return service;

  /**
   * Returns a Moment object representing the input date provided. Any precision beyond milliseconds is
   * automatically removed, since moment.js only supports millisecond resolution.
   *
   * This function utilizes an internal cache to return an existing moment if one has already
   * been created from the provided input.
   *
   * @param {String|Number} dateInput - The input date to parse. This argument can be either an ISO-8601 formatted
   *                                  string or a number representing milliseconds since the Unix epoch.
   * @param {String} [timezoneName] - Timezone in which to parse the string if there is no offset information in the
   *   date string. If not provided, string is parsed in UTC.
   * @return {Moment} A Moment object, either created or retrieved from an internal cache.
   */
  function getMoment(dateInput, timezoneName?) {
    let returnMoment, parseMethods;

    // Use the cached value if it available
    const existing = momentCache.get(dateInput);
    if (existing) {
      return existing;
    }

    // If this is already a moment, just return it
    if (moment.isMoment(dateInput)) {
      return dateInput;
    }

    // If we were actually given a number, convert directly
    if (_.isFinite(dateInput)) {
      returnMoment = moment.utc(dateInput);
      return returnMoment;
    }

    // The sequence of parsing methods to use if the input is a string
    parseMethods = [
      _.partial(service.parseISODate, dateInput, timezoneName),
      _.partial(service.parseLocaleDate, dateInput, timezoneName)
    ];

    // Try each of the parsing methods in order until one succeeds
    _.forEach(parseMethods, function(method: Function) {
      returnMoment = method();
      if (returnMoment.isValid()) {
        // return false to exit the loop
        return false;
      }
    });

    // Store in the cache if the parsing was successful.
    // NOTE: It is important to evaluate .isValid() here since it is a lazily-loaded property.
    // Attempting to check it after the object is frozen will silently fail to set it, and
    // .isValid() will return undefined.
    if (returnMoment.isValid()) {
      // Creating moments from strings is very expensive, so we freeze and cache the moment
      returnMoment = Object.freeze(returnMoment);
      momentCache.put(dateInput, returnMoment);
    }

    return returnMoment;
  }

  /**
   * Parse an ISO-8601 formatted string into a moment object. Input string must have a fully specified date and time,
   * with fractional seconds and UTC offset as the only optional portions. Any fractional seconds below milliseconds
   * will be ignored. ISO-8601 is a locale-agnostic format.
   *
   * @example <caption>All of the following are valid strings</caption>
   * // December 20, 2015 at 1:20am UTC
   * 2015-12-20T01:20:00Z
   * // December 26, 2015 at 1:10:12pm, 2 hours ahead of UTC
   * 2015-12-26T13:10:12+0200
   * // January 1, 2015 at 8:01:15.332pm, 1 hour behind of UTC
   * 2015-01-01T20:01:15.332-0100
   *
   * @param {String} dateInput - Input string to parse
   * @param {String} [defaultTimezoneName] - Timezone to use if no offset information is found in the dateInput
   *   string. If the timezone is not provided and no offset is in the input string, date is parsed as UTC (Z).
   * @return {Moment} Moment object created. .isValid() on the returned object will be false if parsing failed.
   */
  function parseISODate(dateInput, defaultTimezoneName?) {
    let adjustedString;
    let tzBeginIndex, lastIndexofPeriod;
    const tz = defaultTimezoneName ? defaultTimezoneName : 'UTC';

    // Find seconds decimal place
    lastIndexofPeriod = dateInput.lastIndexOf('.');

    // Find beginning of timezone, making sure to skip the date portion (which may include dashes)
    tzBeginIndex = _.findLastIndex(dateInput, function(char) {
      return (char === 'Z' || char === '+' || char === '-');
    });

    // If no timezone found, or if it isn't found near the end of the string where the tiemzone should be,
    // set to the length of the string to indicate that no offset was found.
    if (tzBeginIndex === -1 || (dateInput.length - tzBeginIndex) > 6) {
      tzBeginIndex = dateInput.length;
    }

    // Find the number of digits in the fractional seconds portion, so that we can pad or shrink it to be
    // exactly 3.

    if (lastIndexofPeriod === -1) {
      // no period detected; add one and three 0s
      adjustedString = [
        dateInput.substring(0, tzBeginIndex),
        '.000',
        dateInput.substring(tzBeginIndex, dateInput.length)
      ].join('');
    } else if ((tzBeginIndex - lastIndexofPeriod) > 4) {
      // too many seconds decimal places; remove some
      adjustedString = [
        dateInput.substring(0, lastIndexofPeriod + 4),
        dateInput.substring(tzBeginIndex, dateInput.length)
      ].join('');
    } else {
      // not enough seconds decimal places; add some
      adjustedString = [
        dateInput.substring(0, tzBeginIndex),
        _.repeat('0', 4 - (tzBeginIndex - lastIndexofPeriod)),
        dateInput.substring(tzBeginIndex, dateInput.length)
      ].join('');
    }

    if (tzBeginIndex === dateInput.length) {
      return moment.tz(adjustedString, 'YYYY-MM-DDTHH:mm:ss.SSS', true, tz);
    } else {
      return moment.tz(adjustedString, 'YYYY-MM-DDTHH:mm:ss.SSSZZ', true, tz);
    }
  }

  /**
   * Parse a date/time string formatted in the current locale into a moment object.
   *
   * @example <caption>Examples for 'en' locale</caption>
   * "12/1/2015 1:00 pm" -> December 1, 2015 at 1pm
   * "12/1/2015" -> December 1, 2015 at the time of the reference (or 00:00 if no reference provided)
   *
   * @example <caption>Examples for 'fr' locale</caption>
   * "1/12/2015 13:00" -> December 1, 2015 at 1pm
   * "1/12/2015" -> December 1, 2015 at the time of the reference (or 00:00 if no reference provided)
   *
   * @param {String} input - Input string to parse
   * @param {String} timezoneName - Timezone to use when parsing the input string. If the timezone is not provided
   *   and no offset is in the input string, date is parsed as UTC (Z).
   * @param {Moment} reference - Reference date to use to fill in missing portions if the parsed string contains
   *   less than a completely specified date/time. The reference date will be used for the missing pieces.
   * @return {Moment} Moment object created. .isValid() on the returned object will be false if parsing failed.
   */
  function parseLocaleDate(input, timezoneName, reference) {
    const tz = timezoneName ? timezoneName : 'UTC';
    const ref = reference ? moment.tz(reference, tz) : undefined;
    const parseFormats = [];
    let result, successFormat;
    let offsetCorrection = 0;

    /* TODO: Localize the non-localized formats as needed [MKD, 22-Dec-2015] */
    const shortDate = moment.localeData().longDateFormat('l').replace('YYYY', 'YY');
    const dateFormats = ['l', 'L', 'll', 'LL', shortDate, 'D-MMM-YYYY', 'D-MMM-YY', 'D MMM YYYY', 'D MMM YY', 'DMMMYYYY', 'DMMMYY', 'MMM YYYY', 'MMMM YYYY'];
    const dateMissingYear = ['D-MMM', 'D MMM', 'MMM-D', 'MMM D', 'MMMM D', 'M/D'];
    const yearOnly = ['YYYY'];
    const timeFormats = ['LT', 'LTS', 'H:mm', 'HH:mm', 'H:mm:ss', 'H'];
    const timeFormatPrependRef = 'YYYY-MM-DD ';
    if (service.display12HrClock()) {
      timeFormats.push('h A', 'hA', 'h:mmA', 'h:mm:ssA'); // TODO: Localize these time formats [MKD, 7-Jan-2016]
    }

    // Combine date/time formats to all fully-formed formats
    _.forEach(dateFormats, function(date) {
      _.forEach(timeFormats, function(time) {
        parseFormats.push({
          format: date + ' ' + time,
          referenceValues: [],
          offsetAdjust: false
        });
      });
    });

    // Add date-only formats
    _.forEach(dateFormats, function(date) {
      parseFormats.push({
        format: date,
        referenceValues: 'hour_minute_second_millisecond'.split('_'),
        offsetAdjust: true
      });
    });

    // Add date-only formats without a year
    _.forEach(dateMissingYear, function(date) {
      parseFormats.push({
        format: date,
        referenceValues: 'year_hour_minute_second_millisecond'.split('_'),
        offsetAdjust: true
      });
    });

    // Add year-only formats
    _.forEach(yearOnly, function(year) {
      parseFormats.push({
        format: year,
        referenceValues: 'month_date_hour_minute_second_millisecond'.split('_'),
        offsetAdjust: true
      });
    });

    // Add time-only formats
    // For time-only formats, we prepend the reference year/month/date to the input string so that we
    // can let moment.timezone handle the offset and DST values properly
    _.forEach(timeFormats, function(time) {
      parseFormats.push({
        format: timeFormatPrependRef + time,
        referenceValues: [],
        prepend: ref ? ref.format(timeFormatPrependRef) : '',
        offsetAdjust: false
      });
    });

    // attempt to parse in each format in sequence
    _.forEach(parseFormats, function(formatObject) {
      result = moment.tz(_.get(formatObject, 'prepend', '') + input, formatObject.format, true, tz);
      if (result.isValid()) {
        // if one succeeds, return false to exit the forEach loop
        successFormat = formatObject;
        return false;
      }
    });

    // If we successfully parsed the input and have a reference, see if we need to use it
    if (ref && successFormat) {
      if (successFormat.offsetAdjust) {
        // If a portion of the input is implied, then we need to account for any difference in UTC offset between the
        // reference and result dates. We need to 'undo' the difference in time. It is easiest to see in an example:
        //  Reference: "12/1/2015 9:30PM", US/Eastern
        //  Input: "9/1/2015" (note that this is on the other side of the DST boundary in US/Eastern)
        //  Result should be: "9/1/2015 9:30PM", US/Eastern, NOT "9/1/2015 8:30PM"
        offsetCorrection += moment.tz.zone(tz).utcOffset(reference.valueOf())
          - moment.tz.zone(tz).utcOffset(result.valueOf());
      }

      _.forEach(successFormat.referenceValues, function(unit) {
        result[unit](reference[unit]());
      });

      result.add(offsetCorrection, 'minutes');
    }

    return result;
  }

  /**
   * Parses a duration offset string into a duration with magnitude and direction (+ or -).
   * Input string must begin with a + or - in order to be parsed successfully.
   *
   * @param {String} durationInput - Input string to parse
   * @return {Moment.Duration} Duration parsed from the provided input string. If parsing fails, .valueOf()
   *   will be 0. (There is no .isValid() function on moment.duration objects.)
   */
  function parseDurationOffset(durationInput) {
    const cleanedInput = durationInput.replace(/ /g, '');
    let direction, duration;

    if (cleanedInput[0] === '+') {
      direction = 1;
    } else if (cleanedInput[0] === '-') {
      direction = -1;
    } else {
      return moment.duration(0);
    }

    duration = service.parseDuration(cleanedInput.substring(1));
    if (duration.valueOf() === 0) {
      return duration;
    } else {
      return moment.duration(duration.asMilliseconds() * direction, 'milliseconds');
    }
  }

  /**
   * Parses a human-readable string representing a duration into a moment.duration object.
   * Input string must start with a number in order to be parsed successfully.
   * Months are handled specially in this routine. Each single month represents 1/12 of a year, or 30.416 days.
   * This improves the accuracy of large intervals when added to moment dates.
   *
   * @example parseDuration('3 days') === moment.duration(3, 'days')
   * @example parseDuration('6 months') === moment.duration(182.5, 'days')
   *
   * @param {String} durationInput - Input string to parse
   * @return {Moment.Duration} moment.duration object created. If parsing fails, .valueOf() will be 0. (There is no
   *   .isValid() function on moment.duration objects.)
   */
  function parseDuration(durationInput) {
    let parseResult;
    const invalidDuration = moment.duration(0);
    const trimmedInput = _.trim(durationInput);
    if (isNaN(Number(trimmedInput[0]))) {
      return invalidDuration;
    }

    parseResult = _.attempt(juration.parse, durationInput);
    if (_.isError(parseResult)) {
      return invalidDuration;
    } else {
      return moment.duration(parseResult, 'seconds');
    }
  }

  /**
   * Parses a string representing two dates into two moment objects. The input string must contain a reference to
   * an anchor and a positive or negative offset. Anchors: * (Now) or $ (Start|End). The anchor is resolved to either
   * Start or End based on whether the offset is positive or negative. If start or end references are invalid,
   * strings referencing $ are returned as invalid.
   *
   * @example '*-2mo': Start=(nowReference - 2 months), End=(nowReference)
   * @example '$+1d': Start=(startReference), End=(startReference + 1 day)
   * @example '$-1d': Start=(endReference - 1 day), End=(endReference)
   *
   * @param {String} input - Input string to be parsed
   * @param {Moment} nowReference - Reference to use if '*' starts the input string
   * @param {Moment} startReference - Reference to use for '$' if offset is positive
   * @param {Moment} endReference - Reference to use for '$' if offset is negative
   * @return {Object} Object containing start and end properties with the parsed Moment objects.
   */
  function parseDurationIntoDates(input, nowReference, startReference, endReference) {
    let anchorNow, duration;
    let remainingInput = input;
    const result = { start: moment.invalid(), end: moment.invalid() };

    // look at leading character to see if there is a reference anchor: * or $
    if (remainingInput[0] === '*') {
      anchorNow = true;
    } else if (remainingInput[0] === '$' &&
      moment.isMoment(startReference) && startReference.isValid() &&
      moment.isMoment(endReference) && endReference.isValid()) {
      anchorNow = false;
    } else {
      return result;
    }

    remainingInput = remainingInput.substring(1);

    duration = service.parseDurationOffset(remainingInput);
    if (duration.valueOf() === 0) {
      return result;
    }

    if (duration.asMilliseconds() < 0) {
      if (anchorNow) {
        result.start = moment.utc(nowReference).add(duration);
        result.end = moment.utc(nowReference);
      } else {
        result.start = moment.utc(endReference).add(duration);
        result.end = moment.utc(endReference);
      }
    } else {
      if (anchorNow) {
        result.start = moment.utc(nowReference);
        result.end = moment.utc(nowReference).add(duration);
      } else {
        result.start = moment.utc(startReference);
        result.end = moment.utc(startReference).add(duration);
      }
    }

    return result;
  }

  /**
   * Creates a frontend duration that has a "units" property in place of the  "uom" property supplied by the backend
   *
   * @param {BackendDuration} duration - a backend duration
   * @returns {FrontendDuration} a frontend duration
   */
  function convertDuration(duration: BackendDuration) {
    // If undefined is passed then return undefined instead of an object with undefined properties
    if (_.isUndefined(duration)) {
      return duration;
    }

    return {
      value: _.get(duration, 'value'),
      units: _.get(duration, 'uom')
    };
  }

  /**
   * Parses a string representing a relative date/time into a moment object. The string is a representation
   * of an offset from a reference, such as "+2mo" for adding two months to a reference date. Any duration
   * string supported by {@link parseDuration} can be used. Note that the first character of the string must
   * be one of the following: *$+-.
   *
   * If the first character is '*', then the reference date is nowReference.
   * If the first character is '$', then the reference date is otherReference.
   * If the first character is neither '*' nor '$', then the reference date is selfReference.
   *
   * @example '+2mo' === moment(selfReference).add(2, 'months')
   * @example '*-2d' === moment(nowReference).subtract(2, 'days')
   * @example '$+1.5h' === moment(otherReference).add(1.5, 'hours')
   * @example '*' === moment(nowReference)
   *
   * @param {String} input - Input string to be parsed
   * @param {Moment} nowReference - Reference to use if '*' starts the input string
   * @param {Moment} [selfReference] - Reference to use if neither '*' nor '$' start input string
   * @param {Moment} [otherReference] - Reference to use if '$' starts the input string
   * @return {Moment} The resulting Moment object.
   */
  function parseRelativeDate(input: string, nowReference: Moment, selfReference?: Moment, otherReference?: Moment) {
    let multiplier;
    let remainingInput = input.replace(/ /g, '');
    let valid = false;
    let reference = selfReference; // default to 'self'
    let duration = moment.duration(0);

    // look at leading character to see if there is a reference anchor: * or $
    if (remainingInput[0] === '*') {
      reference = nowReference;
      remainingInput = remainingInput.substring(1);
      valid = true;
    } else if (remainingInput[0] === '$') {
      reference = otherReference;
      remainingInput = remainingInput.substring(1);
      valid = true;
    }

    // look for leading + or -
    if (remainingInput[0] === '-') {
      multiplier = -1;
      remainingInput = remainingInput.substring(1);
      valid = true;
    } else if (remainingInput[0] === '+') {
      multiplier = 1;
      remainingInput = remainingInput.substring(1);
      valid = true;
    }

    if (!valid || _.isUndefined(reference) || !reference.isValid()) {
      return moment.invalid();
    }

    // Parse remaining string as duration
    if (remainingInput) {
      duration = service.parseDuration(remainingInput);
      if (duration.valueOf() === 0) {
        return moment.invalid();
      }
    }

    // Add to reference
    return moment(reference).add(duration.asMilliseconds() * multiplier, 'ms');
  }

  /**
   * Takes a start and end time and calculates new start/end times that inflate the time range by a specified
   * percentage.
   *
   * @param  {Moment} start - Start time. Can be either a moment object or Unix offset in milliseconds.
   * @param  {Moment} end - End time. Can be either a moment object or Unix offset in milliseconds.
   * @param  {Number} inflation - Amount to inflate the range, where 1.0 specifies a 100% inflation factor and
   *   the resulting start/end time range would be twice the size of the input start/end times.
   *   An inflation of 0.0 would return the same start/end times it was passed.
   * @return {Object} Returns an object containing the new start and end times. Object contains 'start' and 'end'
   *   properties, which are moment objects.
   */
  function inflateTimes(start, end, inflation) {
    // Calculate the duration between start and end, then determine the amount of inflation on either side
    const inflationMilliseconds = moment.utc(end).diff(moment.utc(start)) * (inflation / 2.0);
    return {
      start: moment(start).subtract(inflationMilliseconds, 'milliseconds'),
      end: moment(end).add(inflationMilliseconds, 'milliseconds')
    };
  }

  /**
   * Translates the duration into a human readable form.
   *
   * @example
   *  var duration = 60 *  60 * 1000; // One hour
   *  formatDuration(duration); // returns '01:00:00.000'
   *  formatDuration(duration, true); // returns '1h'
   * @param {Number} duration - The duration in milliseconds to be formatted to a human readable string
   * @param {Boolean) [simplify] - Minimize the footprint by removing unnecessary zeros, defaults to false
   * @return {String} Returns the formatted duration in `M d HH:mm:ss.SSS` (or simpler)
   */
  function formatDuration(duration, simplify?) {
    let returnValue;
    let tempReturnValue;
    let tempStringArray;
    let tempStringArrayLength;
    const second = 1000;
    const minute = 60 * second;
    const hour = 60 * minute;
    let positive = true;
    const negString = '-';

    if (!_.isNumber(duration)) {
      return '';
    }

    if (duration < 0) {
      positive = false;
      duration = Math.abs(duration);
    }

    tempReturnValue = moment.duration(duration).format('M d HH:mm:ss.SSS');
    tempStringArray = tempReturnValue.split(' ');
    tempStringArrayLength = tempStringArray.length;
    switch (tempStringArrayLength) {
      case 1:
        returnValue = tempStringArray[0];
        break;
      case 2:
        returnValue = tempStringArray[0] + $translate.instant('DURATIONS.DAYS') + ' ' + tempStringArray[1];
        break;
      case 3:
        returnValue = tempStringArray[0] + $translate.instant('DURATIONS.MONTHS') + ' ' + tempStringArray[1] +
          $translate.instant('DURATIONS.DAYS') + ' ' + tempStringArray[2];
        break;
      default:
        returnValue = tempReturnValue;
    }

    // Pad with zeros as needed so that the units are clear
    if (duration < second) {
      returnValue = '0.' + returnValue; // return '0.SSS'
    } else if (duration < 10 * second) {
      returnValue = '0' + returnValue; // return 'ss.SSS'
    } else if (duration >= minute && duration < 10 * minute) {
      returnValue = '00:0' + returnValue; // return 'HH:mm:ss.SSS'
    } else if (duration >= 10 * minute && duration < hour) {
      returnValue = '00:' + returnValue; // return 'HH:mm:ss.SSS'
    } else if (duration >= hour && duration < 10 * hour) {
      returnValue = '0' + returnValue; // return 'HH:mm:ss.SSS'
    }

    // Do simplifying, remove extra zeros to improve readability (used for formatting axes)
    if (!_.isUndefined(simplify) && simplify === true) {
      if (returnValue.substr(-4, 4) === '.000' && duration >= minute) {
        returnValue = returnValue.slice(0, -4);
      }

      if (returnValue.substr(-3, 3) === ':00') {
        returnValue = returnValue.slice(0, -3);
      }

      if (returnValue.substr(-3, 3) === ':00') {
        returnValue = returnValue.slice(0, -3) + $translate.instant('DURATIONS.HOURS');
      }

      if (returnValue.substr(-3, 3) === '00h') {
        returnValue = returnValue.slice(0, -4);
      }

      if (returnValue.substr(0, 1) === '0' && duration >= hour) {
        returnValue = returnValue.slice(1);
      }
    }

    if (!positive) {
      returnValue = negString.concat(returnValue);
    }

    return returnValue;
  }

  /**
   * Formats a duration as a string with the most relevant units based on the length of the duration.
   * The resulting string will be in the most relevant unit and have up to one decimal digit.
   *
   * (Some examples use ISO8601 Duration notation, for brevity. See: https://en.wikipedia.org/wiki/ISO_8601#Durations)
   *
   * @example
   * '00:00:30' -> '30 seconds'
   * '00:01:00' -> '1 minute'
   * 'P2DT22H' -> '2.9 days'
   * 'P1M15D' -> '1.5 months'
   * 'P11M28D' -> '11.9 months'
   * 'P1Y' -> '1 year'
   * 'P1Y7M' -> '1.6 years'
   *
   * @param {moment.duration} input - Duration to format
   * @return {String} Formatted string
   */
  function formatSimpleDuration(input) {
    const inputValue = input.valueOf();
    let formatUnitToken = 'seconds';
    let translationKey = 'UNITS.SECONDS';
    let formattedUnits;
    let formattedNumber;

    const breakpoints = [
      {
        value: moment.duration(0.95, 'minutes').valueOf(),
        token: 'minutes',
        translationKey: 'UNITS.MINUTES'
      }, {
        value: moment.duration(0.95, 'hours').valueOf(),
        token: 'hours',
        translationKey: 'UNITS.HOURS'
      }, {
        value: moment.duration(0.95, 'days').valueOf(),
        token: 'days',
        translationKey: 'UNITS.DAYS'
      }, {
        value: moment.duration(0.95, 'months').valueOf(),
        token: 'months',
        translationKey: 'UNITS.MONTHS'
      }, {
        value: moment.duration(11.95, 'months').valueOf(),
        token: 'years',
        translationKey: 'UNITS.YEARS'
      }
    ];

    _.forEach(breakpoints, function(breakpoint) {
      if (inputValue < breakpoint.value) {
        return false;
      } else if (inputValue === breakpoint.value) {
        formatUnitToken = breakpoint.token;
        translationKey = breakpoint.translationKey;
        return false;
      }

      formatUnitToken = breakpoint.token;
      translationKey = breakpoint.translationKey;
    });

    formattedNumber = moment.duration(input).as(formatUnitToken).toFixed(1);
    if (formattedNumber.slice(-2) === '.0') {
      formattedNumber = formattedNumber.substring(0, formattedNumber.length - 2);
    }

    formattedUnits = $translate.instant(translationKey, { NUM: formattedNumber });
    return formattedNumber + ' ' + formattedUnits;
  }

  /**
   * Call this function to determine if dates should be displayed with month before
   * day, or day before month.
   *
   * @returns {boolean} True if month should be displayed before day; false if day
   * should be displayed before month.
   */
  function displayMonthDay() {
    const localizedMedDateFormat = moment.localeData().longDateFormat('ll');
    const lowerCaseLDF = localizedMedDateFormat.toLowerCase();
    return (lowerCaseLDF.indexOf('d') !== 0);
  }

  /**
   * Formats time in a localized format with the selected timezone.
   *
   * @param {Number|Moment|Date} time - The time to be formatted, in milliseconds or as a moment.js object
   * @param {{ name: string }} selectedTimezone - The selected timezone.
   * @param {String} [format] - A magical moment.js formatting string
   * @returns {string} The localized date.
   */
  function formatTime(time: Number | Moment | Date, selectedTimezone: { name: string },
    format: string = 'lll'): string {
    if (_.isNil(time)) {
      return '';
    } else {
      return moment(time).tz(selectedTimezone.name).format(format);
    }
  }

  /**
   * Determines if two time ranges overlap one another, inclusive of start and ends.
   *
   * @param {Object} range1 - The first time range
   * @param {number} range1.startTime - The start of the range
   * @param {number} range1.endTime - The end of the range
   * @param {Object} range2 - The other time range
   * @param {number} range2.startTime - The start of the range
   * @param {number} range2.endTime - The end of the range
   */
  function overlaps(range1, range2) {
    return range1.startTime < range2.endTime && range1.endTime > range2.startTime;
  }

  /**
   * Creates a capsule formula from a time range
   *
   * @param {Object} range - Time range over which to request the data
   * @param {Moment|Number} range.start - Start of the range
   * @param {Moment|Number} range.end - End of the range
   */
  function getCapsuleFormula(range) {
    return 'capsule("' + moment.utc(range.start).toISOString() + '", "' + moment.utc(range.end).toISOString() + '")';
  }

  /**
   * Splits a duration string (e.g. '7h') into an object with value and units (e.g. { value: 7, units: 'h' })
   *
   * @param durationString - the string to be split
   * @returns { {value: Number, units: String} | undefined} - a value duration object or undefined if a value
   *   and units could not be determined from the supplied string.
   */
  function splitDuration(durationString): { value: number, units: string } {
    let value, units;

    if (_.isString(durationString)) {
      value = _.get(durationString.match(/[-+]?[0-9]*\.?[0-9]+/), '[0]');

      if (value) {
        units = _.get(durationString.match(/[a-zA-Z]+/), '[0]');

        if (units) {
          return { value: +value, units };
        }
      }
    }
  }

  /**
   * Determines if a 12 hour clock should be displayed, based on moment's locale.
   *
   * @return {Boolean} True if it should display a 12 hour clock, false otherwise
   */
  function display12HrClock() {
    return _.last(moment.localeData().longDateFormat('LT')) === 'A';
  }

  /**
   * Converts an interval into a human readable string using moment's .humanize() function for intervals
   * of one minute or greater. Intervals of less than 1 minute display the number of seconds (e.g. "5 seconds")
   * based on the supplied interval (truncated to whole seconds).
   *
   * @param {Number} interval - an interval in milliseconds
   * @returns {string} a human readable string
   */
  function humanizeInterval(interval) {
    const duration = moment.duration(interval);
    return duration.asSeconds() >= 60 ?
      duration.humanize() :
      duration.seconds() + ' ' + $translate.instant('UNITS.SECONDS', { NUM: duration.seconds() });
  }

  /**
   * Replaces the 'Z' at the end of a time string with '-0000'
   *
   * @param {String} time - a time string
   * @returns {String} - a time string with 'Z' replaced if it was present
   */
  function reformatZuluOffset(time) {
    return time.replace(/[zZ]$/, '-0000');
  }

  /**
   * Converts our units to moment time units
   *
   * @param {String} inputUnit - a unit from `timeUnits` (any of the aliases)
   * @param {Object[]} [timeUnits=DURATION_TIME_UNITS_ALL] - an array of units in the format of `DURATION_TIME_UNITS`
   * @returns {String} the moment measurement unit
   */
  function momentMeasurementStrings(inputUnit, timeUnits = DURATION_TIME_UNITS_ALL) {
    const momentUnit = _.get(service.getDurationTimeUnit(inputUnit, timeUnits), 'momentUnit');
    if (!_.isNil(momentUnit)) {
      return momentUnit;
    } else if (inputUnit === 'ms') {
      return inputUnit;
    } else if (!_.isNil(inputUnit)) { // inputUnit is sometimes null when the low pass filter panel is unloading
      throw new Error(`Unexpected unit, ${inputUnit}, without a moment duration equivalent`);
    }
  }

  /**
   * Finds the matching time unit from `timeUnits`.
   *
   * @param {String} inputUnit - a unit from `timeUnits` (any of the aliases)
   * @param {Object[]} [timeUnits] - an array of units in the format of `DURATION_TIME_UNITS`
   * @returns {Object} one of the `timeUnits` or undefined if a match was not found
   */
  function getDurationTimeUnit(inputUnit, timeUnits = DURATION_TIME_UNITS_ALL) {
    return _.find(timeUnits, ({ unit }) => _.includes(unit, inputUnit));
  }

  /**
   * Converts the period to the required units - does not convert frequencies
   *
   * @param {Number) period  The period to be converted
   * @param {String} toUnits The desired units
   * @param {String} fromUnits The current units, defaults to ms if not provided
   * @returns {Object} The period in the desired units
   */
  function updateUnits(period, toUnits, fromUnits) {
    let duration;

    if (_.isUndefined(fromUnits)) {
      fromUnits = 'ms';
    }

    if (service.isFrequency(fromUnits) || service.isFrequency(toUnits)) {
      return {
        value: period,
        units: fromUnits
      };
    }

    duration = moment.duration(period, service.momentMeasurementStrings(fromUnits));
    return {
      value: duration.as(service.momentMeasurementStrings(toUnits)),
      units: toUnits
    };
  }

  /**
   * Determine the ideal units for display purposes
   *
   * @param duration The period being displayed in milliseconds
   * @returns {Object} The value and units to display
   */
  function determineIdealUnits(duration) {
    if (!isFrequency(duration.units)) {
      const period = updateUnits(duration.value, 'ms', duration.units);

      if (period.value >= moment.duration(1, 'd').asMilliseconds()) {
        return {
          value: moment.duration(period.value).asDays(),
          units: 'day'
        };
      } else if (period.value >= moment.duration(1, 'h').asMilliseconds()) {
        return {
          value: moment.duration(period.value).asHours(),
          units: 'h'
        };
      } else if (period.value >= moment.duration(1, 'm').asMilliseconds()) {
        return {
          value: moment.duration(period.value).asMinutes(),
          units: 'min'
        };
      } else {
        return {
          value: moment.duration(period.value).asSeconds(),
          units: 's'
        };
      }
    } else {
      const frequencyDuration = convertToFrequencyPerDay(duration);

      if (frequencyDuration.value >= S_PER_DAY) {
        return {
          value: frequencyDuration.value / S_PER_DAY,
          units: 'Hz'
        };
      } else if (frequencyDuration.value >= MIN_PER_DAY) {
        return {
          value: frequencyDuration.value / MIN_PER_DAY,
          units: '/min'
        };
      } else if (frequencyDuration.value >= H_PER_DAY) {
        return {
          value: frequencyDuration.value / H_PER_DAY,
          units: '/h'
        };
      } else {
        return frequencyDuration;
      }
    }
  }

  /**
   * Determine if the unit is a frequency
   *
   * @param unit The unit to be tested
   * @returns {boolean} Whether or not the unit is a frequency
   */
  function isFrequency(unit) {
    return unit === 'Hz' || unit === '/min' || unit === '/h' || unit === '/day';
  }

  /**
   * Convert to frequency per day
   *
   * @param {Object} frequency - the frequency to convert
   * @returns {Object} the frequency per day
   */
  function convertToFrequencyPerDay(frequency) {
    let frequencyDuration;

    if (frequency.units === 'Hz') {
      frequencyDuration = {
        value: frequency.value * S_PER_DAY,
        units: '/day'
      };
    } else if (frequency.units === '/min') {
      frequencyDuration = {
        value: frequency.value * MIN_PER_DAY,
        units: '/day'
      };
    } else if (frequency.units === '/h') {
      frequencyDuration = {
        value: frequency.value * H_PER_DAY,
        units: '/day'
      };
    } else {
      frequencyDuration = frequency;
    }

    return frequencyDuration;
  }

  /**
   * Convert a frequency to a period
   * @param {Object} frequency - the frequency to convert
   * @returns {Object} the period object
   */
  function convertFrequencyToPeriod(frequency) {
    const newValue = _.isUndefined(frequency.value) ? undefined : 1 / frequency.value;
    let newUnits;
    if (_.isUndefined(frequency.units)) {
      newUnits = undefined;
    } else {
      newUnits = frequency.units === 'Hz' ? 's' : frequency.units.slice(1);
    }

    return {
      value: newValue,
      units: newUnits
    };
  }

  /**
   * Convert a frequency in /min, /h, /day to Hz
   * @param {Object} frequency - the frequency to convert
   * @returns {Object} the frequency object with units of Hz
   */
  function convertFrequencyToHertz(frequency) {
    if (_.isUndefined(frequency.units) || _.isUndefined(frequency.value)) {
      return frequency;
    }

    let value;
    let units = 'Hz';
    switch (frequency.units) {
      case '/min':
        value = frequency.value / S_PER_MIN;
        break;
      case '/h':
        value = frequency.value / S_PER_H;
        break;
      case '/day':
        value = frequency.value / S_PER_DAY;
        break;
      default:
        value = frequency.value;
        units = frequency.units;
        break;
    }
    return { value, units };
  }

  /**
   * Convert a period to a frequency
   * @param {Object} period - the period to convert
   * @returns {Object} the frequency object
   */
  function convertPeriodToFrequency(period) {
    const newValue = _.isUndefined(period.value) ? undefined : 1 / period.value;
    let newUnits;
    if (_.isUndefined(period.units)) {
      newUnits = undefined;
    } else {
      newUnits = period.units === 's' ? 'Hz' : '/' + period.units;
    }

    return {
      value: newValue,
      units: newUnits
    };
  }

  /**
   * Converts time from 24 hour to locale-based time
   *
   * @param {string} time - time value from time input (HH:mm, 24 hour)
   * @param {string} fromTimezone - from moment timezone
   * @param {string} toTimezone - to moment timezone
   * @param {boolean} [padHour] - if true, will ensure that the hour is 2 digits
   * @returns {string} the time in the locale of the specified timezone
   */
  function convert24HourTimeToLocaleTime(time: string, fromTimezone: string, toTimezone: string,
    padHour = false): string {
    const localeTime = service.formatTime(service.time24HourToMoment(time, fromTimezone), { name: toTimezone }, 'LT');
    if (!padHour || localeTime.match(/\d\d:\d\d/)) {
      return localeTime;
    }

    return '0' + localeTime;
  }

  /**
   * Adjust time input to selected time zone
   *
   * @param {string} time - time value from time input (HH:mm, 24 hour)
   * @param {string} fromTimezone - from moment timezone
   * @param {string} toTimezone - to moment timezone
   * @returns {string} the time input adjusted to specified timezone (HH:mm, 24 hour)
   */
  function adjust24HourTimeToTimezone(time: string, fromTimezone: string, toTimezone: string): string {
    return service.formatTime(service.time24HourToMoment(time, fromTimezone), { name: toTimezone },
      TIME_24_HOUR_FORMAT);
  }

  /**
   * Convert from time input to a Moment object
   *
   * @param {string} time - time value from time input (HH:mm, 24 hour)
   * @param {string} timezone - name of moment timezone
   * @returns {Moment} the moment object
   */
  function time24HourToMoment(time: string, timezone: string): Moment {
    return moment.tz(time, TIME_24_HOUR_FORMAT, timezone);
  }

  function attemptDateParse({ date, otherDate, fieldIdentifier, newDate, timezone, updateBothDates, updateDate }) {
    let parseResult, isStart;
    const origDate = date;
    const origPair = otherDate;
    let twoDateParseMethods;
    const singleDateParseMethods = [{
      name: 'parseISODate',
      method: input => service.parseISODate(input, timezone.name)
    }, {
      name: 'parseLocaleDate',
      method: input => service.parseLocaleDate(input, timezone.name, origDate)
    }, {
      name: 'parseRelativeDate',
      method: input => service.parseRelativeDate(input, moment.utc(), origDate, otherDate)
    }];

    let successfulMethod = '';
    const trimmedInput = _.trim(newDate);

    // If we have two dates, initiate the two-date parsing methods for testing below
    if (otherDate && otherDate.isValid()) {
      isStart = date.isBefore(otherDate);

      twoDateParseMethods = [{
        name: 'parseDurationIntoDates',
        method: input => service.parseDurationIntoDates(input, moment.utc(), isStart ? origDate : origPair,
          isStart ? origPair : origDate)
      }];
    }

    // Try the two-date parsing methods
    _.forEach(twoDateParseMethods, (parseAttempt: any) => {
      parseResult = parseAttempt.method(trimmedInput);
      if (parseResult?.start?.isValid() && parseResult?.end?.isValid()) {
        updateBothDates(parseResult.start, parseResult.end);
        successfulMethod = parseAttempt.name;
        sqTrack.dateParse(
          fieldIdentifier, origDate, origPair, newDate,
          isStart ? parseResult.start : parseResult.end,
          isStart ? parseResult.end : parseResult.start,
          successfulMethod, timezone
        );
        return false;
      }
    });

    // Try the single date parsing methods
    if (!successfulMethod) {
      _.forEach(singleDateParseMethods, (parseAttempt: any) => {
        parseResult = parseAttempt.method(trimmedInput);
        if (parseResult?.isValid()) {
          updateDate(parseResult);
          successfulMethod = parseAttempt.name;
          sqTrack.dateParse(
            fieldIdentifier, origDate, origPair, newDate, parseResult, undefined, successfulMethod, timezone
          );
          return false;
        }
      });
    }

    if (!successfulMethod) {
      sqTrack.dateParse(
        fieldIdentifier, origDate, origPair, newDate, parseResult, undefined, successfulMethod, timezone);
    }

    return successfulMethod;
  }
}
