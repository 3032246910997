import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useTranslation } from '@/hybrid/core/useTranslation.hook';
import { ReportContentActions } from '@/reportEditor/reportContent.actions';
import { ReportContentStore } from '@/reportEditor/reportContent.store';
import { useInjectedBindings } from '@/hybrid/core/useInjectedBindings.hook';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { useFlux } from '@/hybrid/core/useFlux.hook';
import { WorksheetsService } from '@/worksheets/worksheets.service';
import { WorkbookActions } from '@/workbook/workbook.actions';
import { ReportStore } from '@/reportEditor/report.store';
import { WorkstepsService } from '@/worksteps/worksteps.service';
import { AuthorizationService } from '@/services/authorization.service';
import { SocketService } from '@/services/socket.service';
import { UtilitiesService } from '@/services/utilities.service';
import { REPORT_CONTENT } from '@/reportEditor/report.module';
import HttpCodes from 'http-status-codes';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { ReportContentWorkstep } from '@/hybrid/reportEditor/ReportContentWorkstep.molecule';
import { ReportContentSize } from '@/hybrid/reportEditor/ReportContentSize.molecule';
import { ReportContentDateRange } from '@/hybrid/reportEditor/ReportContentDateRange.molecule';
import { ReportContentSummary } from '@/hybrid/reportEditor/ReportContentSummary.organism';
import { RequestsApi } from '@/sdk';
import { ReportContentAssetSelection } from '@/hybrid/reportEditor/ReportContentAssetSelection.molecule';
import { ReportContentVisualizationToggle } from '@/hybrid/reportEditor/ReportContentVisualizationToggle.molecule';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { NotificationsService } from '@/services/notifications.service';

const reportContentPropertiesBindings = bindingsDefinition({
  sqReportContentActions: injected<ReportContentActions>(),
  sqReportContentStore: injected<ReportContentStore>(),
  sqReportContent: injected<ReportContentService>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
  sqWorksheets: injected<WorksheetsService>(),
  sqWorkbookActions: injected<WorkbookActions>(),
  sqReportStore: injected<ReportStore>(),
  sqWorksteps: injected<WorkstepsService>(),
  sqAuthorization: injected<AuthorizationService>(),
  sqSocket: injected<SocketService>(),
  sqUtilities: injected<UtilitiesService>(),
  sqRequestsApi: injected<RequestsApi>(),
  sqNotifications: injected<NotificationsService>()
});

export const ReportContentProperties: SeeqComponent<typeof reportContentPropertiesBindings> = () => {
  const { t } = useTranslation();
  const {
    sqReportContentActions,
    sqReportContentStore,
    sqReportContent,
    sqUtilities,
    sqSocket,
    sqReportStore,
    sqHomeScreenUtilities,
    sqAuthorization,
    sqWorkbookActions,
    sqWorksheets,
    sqWorksteps,
    sqRequestsApi,
    sqNotifications
  } = useInjectedBindings(reportContentPropertiesBindings);

  const {
    workbookId,
    workstepId,
    worksheetId,
    width,
    height,
    useSizeFromRender,
    summary,
    sizeConstant,
    shapeConstant,
    scale,
    sizeKey,
    shapeKey,
    canUseReact,
    isReact,
    id
  } = useFlux(sqReportContentStore);

  const dateRange = sqReportStore.getDateRangeById(sqReportContentStore.dateRangeId);
  const assetSelection = sqReportStore.getAssetSelectionById(sqReportContentStore.assetSelectionId);
  const [owner, setOwner] = useState('');
  const [workbookName, setWorkbookName] = useState('');
  const [zoomPercentage, setZoomPercentage] = useState(0);
  const [canModifyWorkbook, setCanModifyWorkbook] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [worksheet, setWorksheet] = useState(null);
  const [sourceWorksheetWorkStepId, setSourceWorksheetWorkStepId] = useState(workstepId);
  const [contentDiffers, setContentDiffers] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [requestId, setRequestId] = useState('');

  useEffect(() => {
    updatePreviewImage();
  }, [width,
    height,
    summary,
    scale,
    sizeKey,
    shapeKey,
    dateRange
  ]);

  const sizes = REPORT_CONTENT.SIZE;
  const shapes = REPORT_CONTENT.SHAPE;
  const scales = REPORT_CONTENT.SCALE;
  const dateRanges = sqReportStore.dateRangesNotArchived;
  const assetSelections = sqReportStore.assetSelectionsNotArchived;
  const summaries = REPORT_CONTENT.SUMMARY;
  const scaleConstant = _.find(scales, ['key', sqReportContentStore.scale]);
  const isImageNoCapsuleFound = dateRange?.auto.noCapsuleFound;
  const isSizeCustom = sizeKey === sizes.CUSTOM.key;
  const setSize = size => sqReportContentActions.setSize(size.key);
  const setShape = shape => sqReportContentActions.setShape(shape.key);
  const setWidth = width => sqReportContentActions.setWidth(width);
  const setHeight = height => sqReportContentActions.setHeight(height);
  const setScale = scale => sqReportContentActions.setScale(scale.key);
  const setSummary = summary => sqReportContentActions.setSummary(summary);
  const setAssetSelectionId = assetSelection => sqReportContentActions.setAssetSelectionId(assetSelection?.selectionId);
  const setIsReact = isReact => sqReportContentActions.setIsReact(isReact);

  const syncWorkstepToReportModal = ({ workstepId: id }) => {
    setSourceWorksheetWorkStepId(id);
    setContentDiffers(workstepId !== id);
  };

  const getImageUrl = () => {
    return sqReportContentStore.paramsModified || sqReportContentStore.isReact
      ? sqReportContentStore.previewUrl
      : sqReportStore.getContentImageUrl(sqReportContentStore.id, false);
  };

  /*
  *Since the browser makes the img requests and therefore won't have a request ID in the header, we have to append
  * a request ID to the image Url as a query parameter so that appserver can then parse it and use it to cancel
  * the preview image request if it's not necessary anymore
  * */
  const appendRequestIdToUrl = (url) => {
    const reqId = sqUtilities.generateRequestId();
    setRequestId(reqId);
    return url + (url.includes('?') ? '&' : '?') + SeeqNames.API.QueryParams.requestId + '=' + reqId;
  };

  const ignoreForbidden = (error) => {
    if (error.status === HttpCodes.FORBIDDEN) {
      setAccessDenied(true);
    } else {
      throw error;
    }
  };

  const updatePreviewImage = () => {
    if (imageUrl !== undefined && stateAndStoreImageUrlsAreEqual()) {
      return;
    }

    if (requestId) {
      sqRequestsApi.cancelRequest({ requestId });
      setRequestId(undefined);
    }

    setIsError(false);
    setIsLoaded(false);

    // Remove the source before we show the preview so that there's no temporary broken image
    // and to allow the spinner to be visible
    setImageUrl(undefined);
    setTimeout(() => { setImageUrl(appendRequestIdToUrl(getImageUrl())); }, 1);
  };

  const stateAndStoreImageUrlsAreEqual = (): boolean => {
    const imageURL = new URL(imageUrl, window.location.href);
    const imageParams = imageURL.searchParams;
    imageParams.delete('requestId');
    imageParams.sort();
    const storeURL = new URL(getImageUrl(), window.location.href);
    const storeParams = storeURL.searchParams;
    storeParams.sort();
    return imageURL.pathname === storeURL.pathname && imageParams.toString() === storeParams.toString();
  };

  const updateFromSourceWorksheet = () => {
    sqReportContent.evaluateVisualizationOptions(workbookId, worksheetId, sourceWorksheetWorkStepId, { isReact })
      .then(() => {
        sqReportContentActions.setWorkstepId(sourceWorksheetWorkStepId);
        setContentDiffers(false);
        updatePreviewImage();
      })
      .catch(error => sqNotifications.errorTranslate(error.message));
  };

  const editSourceWorksheet = () => {
    const url = `/workbook/${workbookId}/worksheet/${worksheetId}`;
    window.open(url, '_blank');
  };

  const calculateImageZoom = (img: any) => {
    setZoomPercentage(_.round((img.width / img.naturalWidth) * 100, 2));
  };

  const setDateRange = (dateRange) => {
    sqReportContentActions.setDateRangeId(dateRange?.id);
  };

  const onImageLoaded = ({ target: img }) => {
    setIsLoaded(true);
    setIsError(false);
    calculateImageZoom(img);
    setRequestId(undefined);
  };

  const onImageError = () => {
    setImageUrl(undefined);
    setIsError(true);
    setIsLoaded(false);
    return true;
  };

  /**
   * Fetches workbook, worksheet, and workstep information to be used by the component. Also sets up a notifier for
   * new worksteps so that the user can be notified if the worksheet is out of date.
   */
  useEffect(() => {
    if (!!workbookId) {
      sqHomeScreenUtilities.getWorkbookOnly(workbookId)
        .then((workbook: any) => {
          setOwner(workbook?.owner?.name);
          setWorkbookName(workbook?.name);
          setCanModifyWorkbook(sqAuthorization.canModifyWorkbook(workbook, false));
        })
        .catch(ignoreForbidden);

      sqWorksheets.getWorksheet(workbookId, worksheetId)
        .then((worksheetArg) => {
          setWorksheet(_.pick(worksheetArg, ['name', 'createdAt', 'updatedAt']));
        })
        .catch(ignoreForbidden);

      sqWorksteps.getCurrentWorkstepId(workbookId, worksheetId)
        .then((workstepIdArg) => {
          setSourceWorksheetWorkStepId(workstepIdArg);
          setContentDiffers(workstepId !== workstepIdArg);
        })
        .catch(ignoreForbidden);
    }

    const offWorkstep = sqSocket.subscribe({
      channelId: [
        SeeqNames.Channels.WorkstepChannel,
        worksheetId
      ],
      onMessage: syncWorkstepToReportModal
    });

    return () => {
      if (requestId) {
        sqRequestsApi.cancelRequest({ requestId });
      }
      offWorkstep();
    };
  }, []);

  return <div className="flexFillOverflow flexColumnContainer reportContentProperties"
    data-testid="report-content-properties">
    <div className="flexDoubleFill flexRowContainer">
      <div className="flexRowContainer flexFill">
        {!isError
        && <div className="flexRowContainer flexFill image p5">
          <div className="flexRowContainer flexFillOverflow flexCenter">
            <img id="contentPreview" alt='' data-testid="previewImageId"
              className={classNames({
                loadingSpinnerNoBorder: !isLoaded,
                contentLoaded: isLoaded
              })}
              src={imageUrl}
              onLoad={onImageLoaded}
              onError={onImageError}
            />
          </div>
          {isLoaded && zoomPercentage && zoomPercentage < 100
          && <div className="flexCenter colorGray pt5">
            {t('REPORT.CONTENT.SHOWN_AT', { ZOOM: zoomPercentage })}
          </div>}
        </div>}
        {isError
        && <div className={classNames('placeholder flexRowContainer flexCenter flexFill',
          'text-center pt70 pr10 pl10 sq-text-danger',
          {
            contentNoCapsuleError: isImageNoCapsuleFound,
            contentLoadError: !isImageNoCapsuleFound
          })}>
          {isImageNoCapsuleFound ?
            t('REPORT.CONTENT.NO_CAPSULE_FOUND') : t('REPORT.CONTENT.FAILED')}
        </div>}
        {!accessDenied && worksheet
        && <div className="properties flexRowContainer flexJustifyCenter backgroundColorOffWhite p5">
          <div className="flexColumnContainer">
            <label className="width-80 text-right flexAlignStart">
              {t('REPORT.CONFIG.WORKSHEET')}
            </label>
            <label className="sq-fairly-dark-gray pl15">
              {worksheet.name}
            </label>
          </div>
          <div className="flexColumnContainer">
            <label className="width-80 text-right">
              {t('REPORT.CONFIG.WORKBOOK')}
            </label>
            <label className="sq-fairly-dark-gray pl15" data-testid="workbookNameId">
              {workbookName}
            </label>
          </div>
          <div className="flexColumnContainer">
            <label className="width-80 text-right">
              {t('REPORT.CONFIG.CREATED')}
            </label>
            <label className="sq-fairly-dark-gray pl15">
              {sqWorkbookActions.formatDate(worksheet.createdAt)}
            </label>
          </div>
          <div className="flexColumnContainer">
            <label className="width-80 text-right">
              {t('REPORT.CONFIG.UPDATED')}
            </label>
            <label className="sq-fairly-dark-gray pl15">
              {sqWorkbookActions.formatDate(worksheet.updatedAt)}
            </label>
          </div>
          <div className="flexColumnContainer">
            <label className="width-80 text-right">
              {t('REPORT.CONFIG.OWNER')}
            </label>
            <label className="sq-fairly-dark-gray pl15">
              {owner}
            </label>
          </div>
        </div>}
        {accessDenied
        && <div className="properties flexRowContainer flexJustifyCenter backgroundColorOffWhite height-120 p10">
          <div className="sq-dark-gray text-center">
            {t('REPORT.CONTENT.ACCESS_DENIED')}
          </div>
        </div>}
      </div>
    </div>
    <div className="settings flexFillOverflow flexRowContainer">
      <ReportContentWorkstep
        contentDiffers={contentDiffers}
        accessDenied={accessDenied}
        canModifyWorkbook={canModifyWorkbook}
        updateFromSourceWorksheet={updateFromSourceWorksheet}
        editSourceWorksheet={editSourceWorksheet} />

      {!id && canUseReact && sqReportStore.isCkEnabled &&
      // Don't allow toggling between visualization types if the content already exists.
      <ReportContentVisualizationToggle
        isReact={isReact}
        setIsReact={setIsReact}
      />}
      <ReportContentSize
        useSizeFromRender={useSizeFromRender}
        isReact={isReact}
        isSizeCustom={isSizeCustom}
        sizes={sizes}
        shapes={shapes}
        scales={scales}
        size={sizeConstant}
        shape={shapeConstant}
        scale={scaleConstant}
        setShape={setShape}
        setSize={setSize}
        setScale={setScale}
        width={width}
        height={height}
        setHeight={setHeight}
        setWidth={setWidth} />
      <ReportContentDateRange
        dateRange={dateRange}
        dateRanges={dateRanges}
        setDateRange={setDateRange} />
      <ReportContentAssetSelection
        selection={assetSelection}
        assetSelections={assetSelections}
        setAssetSelection={setAssetSelectionId} />
      <ReportContentSummary
        summary={summary}
        summaries={summaries}
        setSummary={setSummary} />
    </div>
  </div>;
};
